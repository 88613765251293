import { IApplicationState } from '../../../../../../../../types/types';
import { getBoard } from '../../../../../../../../store/model/selectors/getBoardById';
import { connect } from 'react-redux';
import { BoardEmail } from '../../components/BoardEmail/BoardEmail';
import { IBoardEmailHOCProps } from './types';
import { IBoardEmailEvents, IBoardEmailFields } from '../../components/BoardEmail/types';
import { getAuthUser } from '../../../../../../../../store/model/authUser/selectors/getAuthUser';
import { onClick } from './events/onClick';
import { onClickDisallow } from './events/onClickDisallow';
import { BOARD_EMAIL_DISALLOW, BOARD_EMAIL_READONLY } from '../../constants';
import { getPanelReadonly } from '../../../../selectors/getPanelReadonly';
import { onClickInstructions } from './events/onClickInstructions';

const mapStateToProps = (
    state: IApplicationState,
    ownProps: IBoardEmailHOCProps
): IBoardEmailFields => {
    const { boardId } = ownProps;
    const board = getBoard(state, boardId);
    const user = getAuthUser(state);
    const isDisallow = !user.allowCardFromEmail;
    const isReadonly = getPanelReadonly(state, boardId);

    const value = isReadonly
        ? BOARD_EMAIL_READONLY
        : isDisallow
            ? BOARD_EMAIL_DISALLOW
            : board && board.cardFromEmail
                ? board.cardFromEmail
                : '';
    return {
        isDisallow,
        isReadonly,
        value,
    }
};

const mapDispatchToProps = (
    dispatch: any,
    ownProps: IBoardEmailHOCProps
): IBoardEmailEvents => {
    return {
        onClick: () => dispatch(onClick()),
        onClickDisallow: () => dispatch(onClickDisallow()),
        onClickInstructions: () => dispatch(onClickInstructions()),
    }
};

export const BoardEmailHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(BoardEmail);

BoardEmailHOC.displayName = 'BoardEmailHOC';
