import { ETutorial } from '../../../../../types/rest/IUserMeta';

export const TUTORIALS_NAME_MAP = {
    [ETutorial.SIGN_IN_TUTORIAL]: 'signin',
    [ETutorial.CARD_DETAILS_TUTORIAL]: 'card',
    [ETutorial.GANTT_VIEW_TUTORIAL]: 'gantt',
    [ETutorial.LIST_VIEW_TUTORIAL]: 'list',
    [ETutorial.ARCHIVE_TUTORIAL]: 'archive',
    [ETutorial.SUBCARD_OR_CHECKLIST_TUTORIAL]: 'subcard/checklist',
    [ETutorial.FILTER_TUTORIAL]: 'filter',
    [ETutorial.TIME_TRACKER_TUTORIAL]: 'time',
};
