export const clearMarkdown = (
    text: string
): string => {
    if (!text || !text.replace) return text;
    return text.replace(/(==|#|---|__|`|~~|\*|\[|\])/g, '');
};

export const clearMarkdownInline = (
    text: string
): string => {
    if (!text || !text.replace) return text;
    return text.replace(/(__|`|~~|\*|\[|\])/g, '');
};
