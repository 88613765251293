import * as React from 'react';
import './_CardAssignees.scss';
import { CLASS_MY_WORK_CARD, CLASS_MY_WORK_CARD_CONTROL } from '../../../constants';
import { UsersTooltip } from '../../../../../base/components/UsersTooltip/UsersTooltip';
import { ICardAssigneesProps } from './types';
import { Button, ButtonDropdown, Icon } from 'kui';
import {
    AssigneesAdd
} from '../../../../../aside_panel/cardDetails/AssigneesSection/components/AssigneesAdd/AssigneesAdd';
import { SearchSelect } from '../../../../../base/components/SearchSelect/SearchSelect';

export function CardAssignees ({
    authUserPermissionId,
    boardUsers,
    elements,
    portalId,
    roles,
    users,
    onLoadCard,
    onSelect
}: ICardAssigneesProps) {
    const className = CLASS_MY_WORK_CARD + '__assignees';
    const classDropdown = className + '-dropdown';
    const [isOpened, setOpened] = React.useState(null);
    const onClose = () => setOpened(false);
    return (
        <ButtonDropdown
            className={`
                ${className}
                ${!users.length ? className + '--no-assignee' : ''}
                ${isOpened ? className + '--opened' : ''}
                ${CLASS_MY_WORK_CARD_CONTROL}
            `}
            dropdownClassName={`${classDropdown} ${CLASS_MY_WORK_CARD_CONTROL}`}
            onOpen={() => setOpened(true)}
            beforeOpen={onLoadCard}
            onClose={onClose}
            opened={isOpened}
            portal
            portalId={portalId}
            directionHorizontal={'right'}
            directionVertical={'auto'}
            isMoveToFit
        >
            <Button className={className + '-button'}>
                {users.length ?
                    <UsersTooltip
                        authUserPermissionId={authUserPermissionId}
                        boardUsers={boardUsers}
                        roles={roles}
                        users={users}
                        isBig
                        delayClose={1}
                        isNoEvents
                    />
                    : <Icon xlink={'user'} size={24} />
                }
            </Button>
            <SearchSelect
                autoFocus
                isCloseOnClick={false}
                multiple
                className={`${className + '-search-select'} ${CLASS_MY_WORK_CARD_CONTROL}`}
                dropdownClassName={CLASS_MY_WORK_CARD_CONTROL}
                isDropdownUplifted
                opened
                onClosed={onClose}
                options={elements}
                option={AssigneesAdd}
                portalId={portalId}
                directionVertical={'auto'}
                onSelect={onSelect}
            />
        </ButtonDropdown>
    );
};
