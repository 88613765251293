import { Dispatch, ThunkAction } from '../../../../types/actions';
import { root } from '../../../../store/constants';
import {
    SegmentBoardEvent,
    SegmentBoardSettingsSourceValue
} from '../../../../middlewares/segment/trackEntities/boardEvents';
import { segmentTrackAction } from '../../../../middlewares/segment';
import { IGetState } from '../../../../types/types';

export const openBoardSettings = (
    scrollElem: string = '',
    sourceValue: SegmentBoardSettingsSourceValue
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (!root.App.controller.isRelatedPanelNotOpened()) return;
        scrollElem = scrollElem ? '/' + scrollElem : '';
        root.App.router.navigate(root.App.router.getUrl('edit' + scrollElem), {trigger: true});
        dispatch(segmentTrackAction(SegmentBoardEvent.BOARD_DETAILS, {
            name: 'source',
            value: sourceValue
        }));
    };
    return action;
}
