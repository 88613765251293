import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { ActionCreator } from 'redux';
import { IGetState } from '../../../../../types/types';
import { getCookieScopesFromUser } from '../selectors/getCookieScopesFromUser';
import { showSet } from '../store/actions/showSet';
import { performanceAllowedSet } from '../store/actions/performanceAllowedSet';
import { processPerformanceAllow } from './processPerformanceAllow';
import { PERFORMANCE_TYPE } from '../constants';
import { checkNewValueExistsSelector } from '../selectors/checkNewValueExistsSelector';
import { isShowCookieDialogForUser } from '../selectors/isShowCookieDialogForUser';
import { reboot } from './reboot';
import { isInsertPerformance } from '../selectors/isInsertPerformance';
import { clearPerformanceScripts } from './clearPerformanceScripts';

export const authorizedUserCookieCheck: ActionCreator<ThunkAction> = () => {
    const action = (dispatch: Dispatch, getState: IGetState) => {
        const state = getState();
        const isNewValueExists = checkNewValueExistsSelector(state);

        if (isNewValueExists) {
            dispatch(processPerformanceAllow());
        } else{
            const userCookieSettings = getCookieScopesFromUser(state);
            const needShowCookieDialog = isShowCookieDialogForUser(state);

            if(!needShowCookieDialog) {
                const userPerformanceAllowed = Boolean(userCookieSettings && userCookieSettings.includes(PERFORMANCE_TYPE));
                const insertPerformance  = isInsertPerformance();
                dispatch(performanceAllowedSet(userPerformanceAllowed));
                if (insertPerformance && !userPerformanceAllowed) {
                    dispatch(clearPerformanceScripts());
                    dispatch(reboot());
                }
            }
            dispatch(showSet(needShowCookieDialog));
        }
    };
    return action;
};
