import { AT_CARD_ACTION_SET, ICardActionSetAction } from './types';
import { TCollaboratorsAction } from '../../collaborators/actions/types';

export const cardActionSet = (
    id: number,
    collaboratorsAction: TCollaboratorsAction
): ICardActionSetAction => {
    return {
        type: AT_CARD_ACTION_SET,
        id,
        collaboratorsAction
    }
};
