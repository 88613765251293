import {Dispatch, ThunkAction} from '../../../../../../../types/actions';
import {root} from '../../../../../../../store/constants';
import {
    boardImportBoardCSVClickedSegmentEvent,
} from '../../../../../main/navigationPanel/effects/segmentEvents';
import {isContinuedReadOnly} from '../../../../../base/selectors/isContinuedReadOnly';
import {snackbarUpgradeDefault} from '../../../../../snackbarsStack';
import {importCsv} from '../../../../openBoards/FooterImport/hocs/FooterImportHOC/effects/importCsv';
import {OPEN_BOARDS_DIALOG_CLOSE} from '../constants';

export const onClickCsv = (): ThunkAction => {
    const action = (dispatch: Dispatch) => {
        root.App.vent.trigger(root.App.vent[OPEN_BOARDS_DIALOG_CLOSE]);
        dispatch(boardImportBoardCSVClickedSegmentEvent());
        if (isContinuedReadOnly()) {
            dispatch(snackbarUpgradeDefault());
        } else {
            dispatch(importCsv());
        }
    };
    return action;
};
