import {ActionCreator} from 'redux';
import {Dispatch, ThunkAction} from '../../../../../../../types/actions';
import {IGetState} from '../../../../../../../types/types';
import {vent} from '../../../../../../../store/constants';
import {createBoardByDriveId} from '../../../../../base/effects/createBoardByDriveId';
import {DRIVE_ID_MY_DRIVE} from '../../../../../dialogs/openBoards/store/drives/constants';
import { boardCreateClickedSegmentEvent } from '../../../effects/segmentEvents';

export const onCreateBoard: ActionCreator<ThunkAction> = (
    isDropdownButton: boolean
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        vent.trigger(vent['dashboards:loading'], true);
        dispatch(createBoardByDriveId(DRIVE_ID_MY_DRIVE));
        if (isDropdownButton) dispatch(boardCreateClickedSegmentEvent());
    };
    return action;
};
