import {TAutoBackupAction} from '../../autoBackup/actions/types';
import {AT_AUTO_BACKUPS_UPDATE_ACTION} from './constants';
import {IAutoBackupsUpdateAction} from './types';

export const autoBackupsUpdate = (
    autoBackupId: number,
    autoBackupAction: TAutoBackupAction
): IAutoBackupsUpdateAction =>({
    type: AT_AUTO_BACKUPS_UPDATE_ACTION,
    autoBackupId,
    autoBackupAction
})
