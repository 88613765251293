import { IDriveDocs } from '../types';
import { TDriveDocsAction } from '../actions/types';
import { AT_DELETE, AT_SET, AT_UPDATE_DRIVE_DOC } from '../actions/constants';

export const driveDocsReducer = (
    state: IDriveDocs,
    action: TDriveDocsAction
): IDriveDocs => {
    switch (action.type) {
        case AT_SET: {
            const { driveDocs } = action;
            for (let docId in driveDocs) { // KNB-2796 возможно лучше на бэке поменять сразу
                if (driveDocs[docId].iconUrl) {
                    driveDocs[docId].iconUrl = driveDocs[docId].iconUrl.replace('16', '32');
                }
            }
            return {
                ...state,
                ...action.driveDocs
            }
        }
        case AT_DELETE: {
            const stateNew = {...state};
            const { driveDocs } = action;
            if (driveDocs) {
                for (let docId in driveDocs) {
                    delete stateNew[docId];
                }
            }
            return {
                ...stateNew
            };
        }
        case AT_UPDATE_DRIVE_DOC: {
            const { driveDocId, driveDoc } = action;
            return {
                ...state,
                [driveDocId]: {
                    ...state[driveDocId],
                    ...driveDoc
                }
            }
        }
        default: {
            return state
        }
    }
};
