import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { snackbarPromtDelete } from '../../../../../snackbarsStack';
import { SNACKBAR_BOARD_DELETE_ID, SNACKBAR_DELETE } from '../constants';
import { TSnackbarAction } from '../types';
import { deleteBoard } from './deleteBoard';
import { deleteBoardSnackbarSuccess } from './deleteBoardSnackbarSuccess';
import { IGetState, TBoardId } from '../../../../../../../types/types';
import { TBoardDeleteType } from '../../../store/boards/types';

export const deleteBoardSnackbarPromt: ActionCreator<ThunkAction> = (
    boardId: TBoardId,
    boardDeleteType: TBoardDeleteType,
    afterDelete: TSnackbarAction
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        const snackbarWordings = SNACKBAR_DELETE[boardDeleteType];
        const {
            text,
            title
        } = snackbarWordings;
        const onSuccess = () => {
            dispatch(deleteBoardSnackbarSuccess());
            if (afterDelete) afterDelete();
        };
        const actionApply = () => {
            dispatch(deleteBoard(boardId, onSuccess));
        };

        dispatch(snackbarPromtDelete({
            id: SNACKBAR_BOARD_DELETE_ID,
            text,
            title,
            actionApply
        }));
    };
    return action;
};
