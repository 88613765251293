import { Dispatch, ThunkAction } from '../../../types/actions';
import { IGetState } from '../../../types/types';
import { getAuthUser } from '../../../store/model/authUser/selectors/getAuthUser';
import { fetchHandler } from '../../../util/fetchHandler';
import { Util } from '../../../util/util';
import {getRestHeadersGet, getRestHeadersPost} from '../../helpers/getRestHeadersHelper';
import { authUserUpdateMeta } from './authUserUpdateMeta';
import {IPartActionResponse} from '../../types';

const API_URL = '/rest/users/cancelDeletion';

export const authUserCancelDelete = (): ThunkAction => {
    const postRequest = () => {
        const url = Util.getApiUrl(API_URL);
        return fetchHandler(url, {
                ...getRestHeadersPost()
            }
        );
    }
    const cancelBoardsDeletion = (): Promise<any> => {
        return new Promise<void>((resolve, reject) => {
            postRequest().then((response: IPartActionResponse) => {
                if (response.complete) {
                    resolve();
                } else {
                    return cancelBoardsDeletion()
                }
            })
                .catch(() => reject())
        })
    }

    const action = (
    ) => {
        return cancelBoardsDeletion();
    };
    return action;
};
