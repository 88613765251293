import { ThunkAction, Dispatch } from '../../../../types/actions';
import { THintKey } from '../types';
import { hintActionSetAction } from '../store/hints/actions/hintActionSetAction';
import { hintStatusSetAction } from '../store/hint/actions/hintStatusSetAction';
import { EHintStatus } from '../constants';
import { hintSave } from './hintSave';
import { IGetState } from '../../../../types/types';

export const hintHide = (
    key: THintKey
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        dispatch(hintActionSetAction(key, hintStatusSetAction(EHintStatus.SHOWN)));
        return dispatch(hintSave(key));
    };
    return action;
};
