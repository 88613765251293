import { AT_NBD_POPUP_SET, IPopupSetAction } from './types';

export const popupSetAction = (
    isOpen: boolean
): IPopupSetAction => {
    return {
        type: AT_NBD_POPUP_SET,
        isOpen
    }
};
