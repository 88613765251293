import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { onLoadBoardsFail } from './onLoadBoardsFail';
import { REST_LOAD_DRIVES } from '../constants';
import { IRestDrive } from '../rest/types';
import { getDrivesFromRest } from '../store/drives/helpers/getDrivesFromRest';
import { IGetState } from '../../../../../types/types';
import { drivesLoadingSet } from '../store/drives/actions/drivesLoadingSet';
import { drivesSet } from '../store/drives/actions/drivesSet';
import { getDrivesIsLoading } from '../store/drives/selectors/getDrivesIsLoading';
import Util from '../../../../../util/util';
import { fetchHandler } from '../../../../../util/fetchHandler';
import { getIsTabAllowedSupportedHasScopes } from '../selectors/getIsTabAllowedSupportedHasScopes';
import { TTabKey } from '../store/types';

export const loadDrivesFromBack: ActionCreator<ThunkAction> =
() => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(drivesLoadingSet(true));
        return fetchHandler<IRestDrive[]>(
            Util.getApiUrl(REST_LOAD_DRIVES)
        )
        .then(
            (restDrives) => {
                let drives = getDrivesFromRest(restDrives);
                dispatch(drivesSet(drives));
                dispatch(drivesLoadingSet(false));
            }
        )
        .catch(() => {
            dispatch(onLoadBoardsFail());
            return Promise.reject();
        });
    };
    return action;
};

export const loadDrives: ActionCreator<ThunkAction> =
() => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const isSharedDrives = getIsTabAllowedSupportedHasScopes(getState(), TTabKey.sharedDrives);
        if (!isSharedDrives) {
            return Promise.resolve();
        }

        const drivesIsLoading = getDrivesIsLoading(getState());
        if (drivesIsLoading === false) { // если ещё грузятся, но переходим в другой таб, придётся грузить опять, т.к. загрузка досок должна идти после загрузки драйвов, т.к. доски которым не нашлось драйва попадают в Shared with me
            return Promise.resolve();
        }

        return dispatch(loadDrivesFromBack());
    };
    return action;
};
