import { TTabKey } from 'app/view/react_components/dialogs/openBoards/store/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../types/types';
import store from '../../../../../../../../store/configureStore';
import OpenBoardsDialogView from 'app/view/main/openboards_dialog_view';

export const loadStarred = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const openBoardsDialogView = new OpenBoardsDialogView({
            store
        });
        openBoardsDialogView.initOpenBoardsStore()
            .then(() => {
                import(/* webpackChunkName: "openBoards" */ 'app/view/react_components/dialogs/openBoards/effects/loadBoardsByTabKey')
                    .then(module => dispatch(module.loadBoardsByTabKey(TTabKey.starred)))
                    .catch(e => console.error(e))
            });
    };
    return action;
};
