import { createRoot } from 'react-dom/client';
import {Provider} from "react-redux";
import React from "react";
import { removeAsidePanelPortal } from "../../helper/removeAsidePanelPortal";
import store, { dispatch } from 'app/store/configureStore';
import { LazyWrapper } from '../react_components/lazy/lazy_wrapper';

App.Views.BoardPanelView = App.Views.BaseView.extend({
    asideClassName: 'aside-panel__board',

    initialize: function (options) {
        this.root = createRoot(this.el);
        this.store = options.store;
        this.boardId = options.boardId;
        this.parent = options.parent;
        this.tabKey = options.tabKey;
    },

    initBoardDetailsStore: function() {
        return Promise.all([
            import(/* webpackChunkName: "boardDetails" */ '../react_components/aside_panel/boardDetails/backups/AutoBackups/store/autoBackupsBoard/reducers/autoBackupsBoardReducer'),
            import(/* webpackChunkName: "boardDetails" */ '../react_components/aside_panel/boardDetails/export/store/reducers/boardExportReducer'),
            import(/* webpackChunkName: "boardDetails" */ '../react_components/aside_panel/boardDetails/details/CardNumber/store/cardNumbers/reducers/cardNumbersReducer'),
        ])
        .then(([
            module1, module2, module3,
        ]) => {
            store.reducerManager.add('autobackups', module1.autoBackupsBoardReducer);
            store.reducerManager.add('boardsExport', module2.boardExportReducer);
            store.reducerManager.add('cardNumbers', module3.cardNumbersReducer);
            dispatch({type: 'async-init'}); // fake action to init new reducers
            App.controller.mainView.renderOpenBoardsDialog(); // boardDetails сильно зависит от openBoards
        })
        .catch(e => console.error(e))
    },

    render: function () {
        this.parent.$el.find('.aside-panel__hide').hide();
        const ImportElement = React.lazy(() =>
            this.initBoardDetailsStore()
                .then(() => import(/* webpackChunkName: "boardDetails" */ '../react_components/aside_panel/asidePanel/hocs/AsidePanelBoardHOC/AsidePanelBoardHOC'))
                .then(module => ({default: module.AsidePanelBoardHOC}))
                .catch(e => console.error(e))
        );
        this.root.render(
            <LazyWrapper>
                <Provider store={store}>
                    <ImportElement
                        boardId={this.boardId}
                        tabKey={this.tabKey}
                        closedRef={document.activeElement}
                    />
                </Provider>
            </LazyWrapper>
        );
        return this;
    },

    remove: function () {
        this.parent.$el.find('.aside-panel__hide').show();
        setTimeout(() => {
            setTimeout(() => {
                this.root.unmount(); // надо дождаться пока сработает react onblur, только потом отцеплять конпонент
                Backbone.View.prototype.remove.call(this);
            });
        });
        removeAsidePanelPortal(this.el);
    }
});

export default App.Views.BoardPanelView;
