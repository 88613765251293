import { ITabsActionSetAction, TABS_ACTION_SET } from './types';
import { TTabsModelAction } from '../tabs/actions/types';

export const tabsActionSet = (
    tabsAction: TTabsModelAction
): ITabsActionSetAction => {
    return {
        type: TABS_ACTION_SET,
        tabsAction
    }
};
