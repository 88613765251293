import { ICard } from '../../card/types';
import { ICardsComparator } from './types';
import { compareStrings } from './compareStrings';
import { clearMarkdownInline } from '../../../../view/react_components/base/helpers/clearMarkdownHelper';

const getCardValue = (
    card: ICard
): string => clearMarkdownInline(card.name);

export const title: ICardsComparator = {
    getCardValue,
    sort: compareStrings
}
