import { INavigationPanelDispatchProps, INavigationPanelStateProps } from '../../containers/NavigationPanel/types';
import { IApplicationState } from '../../../../../../types/types';
import { connect } from 'react-redux';
import { NavigationPanel } from '../../containers/NavigationPanel/NavigationPanel';
import { onPanelMouseOut } from './events/onPanelMouseOut';
import { onPanelMouseHold } from './events/onPanelMouseHold';
import { isOpenNavigationPanelSelector } from '../../store/selectors/isOpenNavigationPanelSelector';
import { hasOpenedBoardSelector } from '../../../../../../store/model/selectors/hasOpenedBoard';
import { setMobile } from './events/setMobile';
import { getCurrentNavigationPanelType } from '../../helpers/getCurrentNavigationPanelType';
import { ENavigationPanelTypes } from '../../constants';
import {
    getAsidePanelIsLeftPanelOpened
} from '../../../../aside_panel/asidePanel/store/selectors/getAsidePanelIsLeftPanelOpened';
import { onTypeChange } from './events/onTypeChange';
import { getIsAssignedToMeActive } from '../../../../../../store/router/selectors/getIsAssignedToMeActive';
import { isTimeTrackerTracking } from '../../../../aside_panel/cardDetails/TabTimingSection/TimeTracker/store/selectors/isTimeTrackerTracking';
import { getAuthUser } from '../../../../../../store/model/authUser/selectors/getAuthUser';
import { getNavigationPanelIsAsideOpened } from '../../store/selectors/getNavigationPanelIsAsideOpened';
import { getIsLinkCardActive } from '../../../../../../store/router/selectors/getIsLinkCardActive';

const mapStateToProps = (state: IApplicationState): INavigationPanelStateProps => {
    const navigationPanelType = getCurrentNavigationPanelType(state);
    const hasOpenedBoard = hasOpenedBoardSelector(state);
    const showCollapseButton = !getNavigationPanelIsAsideOpened(state) && (navigationPanelType !== ENavigationPanelTypes.NP_BIG_TYPE || hasOpenedBoard);
    const isShow = !getAsidePanelIsLeftPanelOpened(state) && !getIsLinkCardActive(state);
    const { inWorkCardId } = getAuthUser(state);
    return {
        hasOpenedBoard,
        navigationPanelType,
        isOpen: isOpenNavigationPanelSelector(state),
        showCollapseButton,
        isShow,
        isShowInWork: !!inWorkCardId,
        isShowTimeTracker: isTimeTrackerTracking(state),
        isAsideOpen: getNavigationPanelIsAsideOpened(state),
    }
};

const mapDispatchToProps = (
    dispatch: any
): INavigationPanelDispatchProps => ({
    setMobile: (isMobile) => dispatch(setMobile(isMobile)),
    onMouseHold: () => dispatch(onPanelMouseHold()),
    onMouseOut: () => dispatch(onPanelMouseOut()),
    onTypeChange: (type) => dispatch(onTypeChange(type))
});

export const NavigationPanelHOC = connect(mapStateToProps, mapDispatchToProps)(NavigationPanel);
