import { IGetState } from '../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { boardsActionSet } from '../../../../../store/model/actions/boardsActionSet';
import { boardActionSet } from '../../../../../store/model/boards/actions/boardActionSet';
import { IColor } from '../../../../../types/model';
import { sendRealTimeStoreActionByBoardId } from '../../../../../view/react_components/base/helpers/realTimeHelperTS';
import { postRest } from '../postRest';
import { addColorAction } from 'app/store/model/board/actions/addColorAction';
import { showColorLabelLongNameError } from './showColorLabelLongNameError';

/**
 * неоптимистичное добавление колортега на доску
 * оптимистичное невозможно, т.к. после нужно добавить его на карту
 */
export const boardColorPostRest = (
    colorTag: IColor,
    isRealtime: boolean = true, // send event to socket,
    isPatchStore: boolean = true // while board migration board is not in a store yet
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const { dashboardId } = colorTag;
        return dispatch(postRest(colorTag))
            .then((colorTag: IColor) => {
                const actionSet = boardsActionSet(boardActionSet(dashboardId, addColorAction(colorTag)));
                if (isPatchStore) {
                    dispatch(actionSet);
                }
                if (isRealtime) {
                    dispatch(sendRealTimeStoreActionByBoardId(dashboardId, actionSet));
                }
                return colorTag;
            }).catch((err: any) => {
                console.log(err);
                err.json().then((err: any) => {
                    if (err.code === 5) {
                        dispatch(showColorLabelLongNameError(err.message));
                    }
                });
            });
    };
    return action;
};
