import * as React from 'react';
import { AsidePanelContext } from '../../asidePanel/components/AsidePanel/constants';
import { ViewOnlyHOC } from 'app/view/react_components/base/hocs/ViewOnlyHOC/ViewOnlyHOC';

export function ViewOnlyContext() {
    const { boardId } = React.useContext(AsidePanelContext);

    return <ViewOnlyHOC
        boardId={boardId}
        isMini={true}
    />;
}
