import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../types/types';
import { IAsidePanelActionsHOCProps } from './types';
import { IAsidePanelActionsFields, IAsidePanelActionsEvents } from '../../components/AsidePanelActions/types';
import { AsidePanelActions } from '../../components/AsidePanelActions/AsidePanelActions';
import { getAsidePanelButtonsPanelIsMinimized } from '../../store/selectors/getAsidePanelButtonsPanelIsMinimized';

const mapStateToProps = (
    state: IApplicationState,
    props: IAsidePanelActionsHOCProps
): IAsidePanelActionsFields => {
    const isMinimized = getAsidePanelButtonsPanelIsMinimized(state);
    return {
        isMinimized,
    }
};

const mapDispatchToProps = (
    dispatch: any,
    ownProps: IAsidePanelActionsHOCProps
): IAsidePanelActionsEvents => {
    return {}
};

export const AsidePanelActionsHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(AsidePanelActions);

AsidePanelActionsHOC.displayName = 'AsidePanelActionsHOC';
