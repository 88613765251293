import { IApplicationState, TCardId } from '../../../../types/types';
import { getListCardsActiveAndArchive } from '../../selectors/getListCardsActiveAndArchive';
import { getOrderNumberByCards } from '../../helpers/getOrderNumberByCards';
import { getBoardByListId } from '../../selectors/getBoardByListId';
import { getList } from './getList';
import { ORDER_STEP } from '../../../../const';
import { sortCardsByOrderNumber } from '../../helpers/sortCardsByOrderNumber';

export const getNextCardOrderNumberAfterCardId = (
    state: IApplicationState,
    listId: TCardId,
    afterCardId: TCardId
): number => {
    const board = getBoardByListId(state, listId);
    if (board.noCards) {
        const list = getList(state, listId);
        return (list.cardAutoIncrement || 0) + ORDER_STEP;
    }

    const cards = getListCardsActiveAndArchive(state, listId);
    if (!cards.length) return 0;
    const sortedCards = [...cards];
    sortedCards.sort(sortCardsByOrderNumber);
    const cardIndex = sortedCards.findIndex(card => card.id === afterCardId);

    if (cardIndex === -1) return getOrderNumberByCards(cards,false);

    if (cardIndex === sortedCards.length - 1) { // last card
        return sortedCards[cardIndex].orderNumber + ORDER_STEP;
    } else {
        return (sortedCards[cardIndex + 1].orderNumber + sortedCards[cardIndex].orderNumber) / 2;
    }
};
