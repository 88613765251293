import { ClientAuthProvider } from 'app/helper/authorisation/microsoft/graph/ClientAuthProvider';
import { Client } from '@microsoft/microsoft-graph-client';
import { GraphError } from '@microsoft/microsoft-graph-client/src/GraphError';

/***
 * класс отвечает за общение с craph https://developer.microsoft.com/en-us/graph/graph-explorer
 */

const authProvider = new ClientAuthProvider();

export const graphClient = Client.initWithMiddleware({authProvider: authProvider});

export const errorHandler = (error: GraphError) => {
    return Promise.reject(JSON.parse(error.body));
}
