import { Dispatch, ThunkAction } from '../../../types/actions';
import { IGetState, TListId } from '../../../types/types';
import { TStatus } from '../../../types/model';
import { listPatchRest } from './api/helpers/listPatchRest';

export const listSetStatus = (
    listId: TListId,
    status: TStatus
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(listPatchRest(listId, {status}));
    };
    return action;
}
