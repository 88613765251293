import { Dispatch, ThunkAction } from 'app/types/actions';
import { isAsideOpenSetAction } from 'app/view/react_components/main/navigationPanel/store/navigationPanel/actions/isAsideOpenSetAction';
import { IGetState } from '../../../../../../types/types';
import { ENavigationPanelTypes } from '../../../../main/navigationPanel/constants';
import { getCurrentNavigationPanelType } from '../../../../main/navigationPanel/helpers/getCurrentNavigationPanelType';
import { isOpenSetAction } from '../../../../main/navigationPanel/store/navigationPanel/actions/isOpenSetAction';
import { panelTypeSetAction } from '../../../../main/navigationPanel/store/navigationPanel/actions/panelTypeSetAction';
import { historyPush } from 'app/view/react_components/base/helpers/historyHelper';
import { onClose } from './onClose';
import { authUserAddFeatureToSeenMeta } from 'app/rest/effects/authUser/authUserAddFeatureToSeenMeta';
import { EFeature } from 'app/types/rest/IUserMeta';

export function onOpen (
): ThunkAction {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        historyPush({
            state: {page: 'updates'},
            onpopstate: () => dispatch(onClose())
        });
        const navigationPanelType = getCurrentNavigationPanelType(getState());
        if (navigationPanelType === ENavigationPanelTypes.NP_COMPACT_TYPE) {
            dispatch(panelTypeSetAction(ENavigationPanelTypes.NP_COMPACT_BIG_TYPE));
            dispatch(isOpenSetAction(false));
        }
        dispatch(isAsideOpenSetAction(true));

        dispatch(authUserAddFeatureToSeenMeta(EFeature.TOPBAR_REDESIGN));
    };
    return action
};
