/*
* Матрица ответов.
* Ключ получается соединением ответов из user meta: Q6-Q2-Q7-Q3 most_important-how_many_people-new_team_plan_to_use-your_role
* см https://docs.google.com/spreadsheets/d/15g8nmUchl4S5UWfUj5shk0uwsggYeo2tCKhBt82b4Io/edit#gid=33911488
* */
export const MESSAGE_TYPE_TEAM = 'Team';
export const MESSAGE_TYPE_SKIPPED = 'Skipped';
export const MESSAGE_TYPE_NO_SPAM = 'No spam';

export const MESSAGE_TYPE_MAP: {[key: string]: string} = {
    'work_key_factor_depth_feature|work_role_team_lead': 'Features', // default value for all work_key_factor_depth_feature|work_role_team_lead|*
    //'work_key_factor_depth_feature|work_role_team_lead|work_team_plan_to_use_information_and_digital': 'Assets management', // rewrite default value above
    'work_key_factor_depth_feature|work_role_owner_manager': 'Features',
    'work_key_factor_depth_feature|work_role_startup_founder': 'Features',
    'work_key_factor_depth_feature|work_role_freelancer': 'Features',
    'work_key_factor_depth_feature|work_role_procurement': 'Features',
    'work_key_factor_depth_feature|work_role_other': 'Features',

    'work_key_factor_cost|work_role_team_lead': 'Gantt promo',
    'work_key_factor_cost|work_role_team_lead|work_team_plan_to_use_information_and_digital': 'Assets management',
    'work_key_factor_cost|work_role_owner_manager': 'Gantt promo',
    'work_key_factor_cost|work_role_owner_manager|work_team_plan_to_use_information_and_digital': 'Assets management',
    'work_key_factor_cost|work_role_startup_founder': 'Gantt promo',
    'work_key_factor_cost|work_role_startup_founder|work_team_plan_to_use_information_and_digital': 'Assets management',
    'work_key_factor_cost|work_role_freelancer': 'Freelancer',
    'work_key_factor_cost|work_role_freelancer|work_team_plan_to_use_information_and_digital': 'Assets management',
    'work_key_factor_cost|work_role_procurement': 'Procurement',
    'work_key_factor_cost|work_role_other': 'Gantt promo',
    'work_key_factor_cost|work_role_other|work_team_plan_to_use_information_and_digital': 'Assets management',

    'work_key_factor_ease_use|work_role_team_lead': 'Ease of use',
    'work_key_factor_ease_use|work_role_owner_manager': 'Ease of use',
    'work_key_factor_ease_use|work_role_startup_founder': 'Ease of use',
    'work_key_factor_ease_use|work_role_freelancer': 'Ease of use',
    'work_key_factor_ease_use|work_role_procurement': 'Ease of use',
    'work_key_factor_ease_use|work_role_other': 'Ease of use',

    'work_key_factor_onboarding|work_role_team_lead': 'Support',
    'work_key_factor_onboarding|work_role_owner_manager': 'Support',
    'work_key_factor_onboarding|work_role_startup_founder': 'Support',
    'work_key_factor_onboarding|work_role_freelancer': 'Support',
    'work_key_factor_onboarding|work_role_procurement': 'Support',
    'work_key_factor_onboarding|work_role_other': 'Support',

    'work_key_factor_other|work_role_team_lead': 'Gantt promo',
    'work_key_factor_other|work_role_team_lead|work_team_plan_to_use_information_and_digital': 'Assets management',
    'work_key_factor_other|work_role_team_lead|work_team_plan_to_use_other': 'Features',
    'work_key_factor_other|work_role_owner_manager': 'Gantt promo',
    'work_key_factor_other|work_role_owner_manager|work_team_plan_to_use_information_and_digital': 'Assets management',
    'work_key_factor_other|work_role_owner_manager|work_team_plan_to_use_other': 'Features',
    'work_key_factor_other|work_role_startup_founder': 'Gantt promo',
    'work_key_factor_other|work_role_startup_founder|work_team_plan_to_use_information_and_digital': 'Assets management',
    'work_key_factor_other|work_role_startup_founder|work_team_plan_to_use_other': 'Features',
    'work_key_factor_other|work_role_freelancer': 'Freelancer',
    'work_key_factor_other|work_role_freelancer|work_team_plan_to_use_information_and_digital': 'Assets management',
    'work_key_factor_other|work_role_procurement': 'Procurement',
    'work_key_factor_other|work_role_other': 'Gantt promo',
    'work_key_factor_other|work_role_other|work_team_plan_to_use_information_and_digital': 'Assets management',
    'work_key_factor_other|work_role_other|work_team_plan_to_use_other': 'Features',

    'work_key_factor_gsuite|work_role_team_lead': 'G Suite',
    'work_key_factor_gsuite|work_role_owner_manager': 'G Suite',
    'work_key_factor_gsuite|work_role_startup_founder': 'G Suite',
    'work_key_factor_gsuite|work_role_freelancer': 'G Suite',
    'work_key_factor_gsuite|work_role_procurement': 'G Suite',
    'work_key_factor_gsuite|work_role_other': 'G Suite',

    'work_key_factor_soft|work_role_team_lead': 'Gantt promo',
    'work_key_factor_soft|work_role_team_lead|work_team_plan_to_use_information_and_digital': 'Assets management',
    'work_key_factor_soft|work_role_owner_manager': 'Gantt promo',
    'work_key_factor_soft|work_role_owner_manager|work_team_plan_to_use_information_and_digital': 'Assets management',
    'work_key_factor_soft|work_role_startup_founder': 'Gantt promo',
    'work_key_factor_soft|work_role_startup_founder|work_team_plan_to_use_information_and_digital': 'Assets management',
    'work_key_factor_soft|work_role_freelancer': 'Freelancer',
    'work_key_factor_soft|work_role_freelancer|work_team_plan_to_use_information_and_digital': 'Assets management',
    'work_key_factor_soft|work_role_procurement': 'Procurement',
    'work_key_factor_soft|work_role_other': 'Gantt promo',
    'work_key_factor_soft|work_role_other|work_team_plan_to_use_information_and_digital': 'Assets management',

    'edu_key_factor_google_classroom|edu_role_teacher': 'Google classroom',
    'edu_key_factor_google_classroom|edu_role_admin': 'Google classroom',
    'edu_key_factor_google_classroom|edu_role_it_staff': 'Google classroom',
    'edu_key_factor_google_classroom|edu_role_procurement': 'Google classroom',
    'edu_key_factor_google_classroom|edu_role_enu_managment': 'Google classroom',
    'edu_key_factor_google_classroom|edu_role_other': 'Google classroom',

    'edu_key_factor_depth_feature|edu_role_teacher|edu_team_plan_to_use_teach_students' : 'Teach students',
    'edu_key_factor_depth_feature|edu_role_teacher|edu_team_plan_to_use_collaborate_class' : 'Class collaboration',
    'edu_key_factor_depth_feature|edu_role_teacher|edu_team_plan_to_use_manage_projects' : 'Edu management',
    'edu_key_factor_depth_feature|edu_role_teacher|edu_team_plan_to_use_manage_it_projects' : 'Edu IT',
    'edu_key_factor_depth_feature|edu_role_teacher|edu_team_plan_to_use_other' : 'Teach students',

    'edu_key_factor_depth_feature|edu_role_admin|edu_team_plan_to_use_teach_students' : 'Teach students',
    'edu_key_factor_depth_feature|edu_role_admin|edu_team_plan_to_use_collaborate_class' : 'Class collaboration',
    'edu_key_factor_depth_feature|edu_role_admin|edu_team_plan_to_use_manage_projects' : 'Edu management',
    'edu_key_factor_depth_feature|edu_role_admin|edu_team_plan_to_use_manage_it_projects' : 'Edu IT',
    'edu_key_factor_depth_feature|edu_role_admin|edu_team_plan_to_use_other' : 'Edu office',

    'edu_key_factor_depth_feature|edu_role_it_staff|edu_team_plan_to_use_teach_students' : 'Teach students',
    'edu_key_factor_depth_feature|edu_role_it_staff|edu_team_plan_to_use_collaborate_class' : 'Class collaboration',
    'edu_key_factor_depth_feature|edu_role_it_staff|edu_team_plan_to_use_manage_projects' : 'Edu IT',
    'edu_key_factor_depth_feature|edu_role_it_staff|edu_team_plan_to_use_manage_it_projects' : 'Edu IT',
    'edu_key_factor_depth_feature|edu_role_it_staff|edu_team_plan_to_use_other' : 'Edu IT',

    'edu_key_factor_depth_feature|edu_role_procurement' : 'Edu procurement',

    'edu_key_factor_depth_feature|edu_role_enu_managment|edu_team_plan_to_use_teach_students' : 'Teach students',
    'edu_key_factor_depth_feature|edu_role_enu_managment|edu_team_plan_to_use_collaborate_class' : 'Class collaboration',
    'edu_key_factor_depth_feature|edu_role_enu_managment|edu_team_plan_to_use_manage_projects' : 'Edu management',
    'edu_key_factor_depth_feature|edu_role_enu_managment|edu_team_plan_to_use_manage_it_projects' : 'Edu IT',
    'edu_key_factor_depth_feature|edu_role_enu_managment|edu_team_plan_to_use_other' : 'Edu management',

    'edu_key_factor_depth_feature|edu_role_other|edu_team_plan_to_use_teach_students' : 'Teach students',
    'edu_key_factor_depth_feature|edu_role_other|edu_team_plan_to_use_collaborate_class' : 'Class collaboration',
    'edu_key_factor_depth_feature|edu_role_other|edu_team_plan_to_use_manage_projects' : 'Edu management',
    'edu_key_factor_depth_feature|edu_role_other|edu_team_plan_to_use_manage_it_projects' : 'Edu IT',
    'edu_key_factor_depth_feature|edu_role_other|edu_team_plan_to_use_other' : 'Edu management',

    'edu_key_factor_ease_use|edu_role_teacher|edu_team_plan_to_use_teach_students' : 'Teach students',
    'edu_key_factor_ease_use|edu_role_teacher|edu_team_plan_to_use_collaborate_class' : 'Class collaboration',
    'edu_key_factor_ease_use|edu_role_teacher|edu_team_plan_to_use_manage_projects' : 'Edu management',
    'edu_key_factor_ease_use|edu_role_teacher|edu_team_plan_to_use_manage_it_projects' : 'Edu IT',
    'edu_key_factor_ease_use|edu_role_teacher|edu_team_plan_to_use_other' : 'Teach students',

    'edu_key_factor_ease_use|edu_role_admin|edu_team_plan_to_use_teach_students' : 'Teach students',
    'edu_key_factor_ease_use|edu_role_admin|edu_team_plan_to_use_collaborate_class' : 'Class collaboration',
    'edu_key_factor_ease_use|edu_role_admin|edu_team_plan_to_use_manage_projects' : 'Edu management',
    'edu_key_factor_ease_use|edu_role_admin|edu_team_plan_to_use_manage_it_projects' : 'Edu IT',
    'edu_key_factor_ease_use|edu_role_admin|edu_team_plan_to_use_other' : 'Edu office',

    'edu_key_factor_ease_use|edu_role_it_staff|edu_team_plan_to_use_teach_students' : 'Teach students',
    'edu_key_factor_ease_use|edu_role_it_staff|edu_team_plan_to_use_collaborate_class' : 'Class collaboration',
    'edu_key_factor_ease_use|edu_role_it_staff|edu_team_plan_to_use_manage_projects' : 'Edu IT',
    'edu_key_factor_ease_use|edu_role_it_staff|edu_team_plan_to_use_manage_it_projects' : 'Edu IT',
    'edu_key_factor_ease_use|edu_role_it_staff|edu_team_plan_to_use_other' : 'Edu IT',

    'edu_key_factor_ease_use|edu_role_procurement' : 'Edu procurement',

    'edu_key_factor_ease_use|edu_role_enu_managment|edu_team_plan_to_use_teach_students' : 'Teach students',
    'edu_key_factor_ease_use|edu_role_enu_managment|edu_team_plan_to_use_collaborate_class' : 'Class collaboration',
    'edu_key_factor_ease_use|edu_role_enu_managment|edu_team_plan_to_use_manage_projects' : 'Edu management',
    'edu_key_factor_ease_use|edu_role_enu_managment|edu_team_plan_to_use_manage_it_projects' : 'Edu IT',
    'edu_key_factor_ease_use|edu_role_enu_managment|edu_team_plan_to_use_other' : 'Edu management',

    'edu_key_factor_ease_use|edu_role_other|edu_team_plan_to_use_teach_students' : 'Teach students',
    'edu_key_factor_ease_use|edu_role_other|edu_team_plan_to_use_collaborate_class' : 'Class collaboration',
    'edu_key_factor_ease_use|edu_role_other|edu_team_plan_to_use_manage_projects' : 'Edu management',
    'edu_key_factor_ease_use|edu_role_other|edu_team_plan_to_use_manage_it_projects' : 'Edu IT',
    'edu_key_factor_ease_use|edu_role_other|edu_team_plan_to_use_other' : 'Edu management',

    'edu_key_factor_gsuite|edu_role_teacher|edu_team_plan_to_use_teach_students' : 'Teach students',
    'edu_key_factor_gsuite|edu_role_teacher|edu_team_plan_to_use_collaborate_class' : 'Class collaboration',
    'edu_key_factor_gsuite|edu_role_teacher|edu_team_plan_to_use_manage_projects' : 'Edu management',
    'edu_key_factor_gsuite|edu_role_teacher|edu_team_plan_to_use_manage_it_projects' : 'Edu IT',
    'edu_key_factor_gsuite|edu_role_teacher|edu_team_plan_to_use_other' : 'Teach students',

    'edu_key_factor_gsuite|edu_role_admin|edu_team_plan_to_use_teach_students' : 'Teach students',
    'edu_key_factor_gsuite|edu_role_admin|edu_team_plan_to_use_collaborate_class' : 'Class collaboration',
    'edu_key_factor_gsuite|edu_role_admin|edu_team_plan_to_use_manage_projects' : 'Edu management',
    'edu_key_factor_gsuite|edu_role_admin|edu_team_plan_to_use_manage_it_projects' : 'Edu IT',
    'edu_key_factor_gsuite|edu_role_admin|edu_team_plan_to_use_other' : 'Edu office',

    'edu_key_factor_gsuite|edu_role_it_staff|edu_team_plan_to_use_teach_students' : 'Teach students',
    'edu_key_factor_gsuite|edu_role_it_staff|edu_team_plan_to_use_collaborate_class' : 'Class collaboration',
    'edu_key_factor_gsuite|edu_role_it_staff|edu_team_plan_to_use_manage_projects' : 'Edu IT',
    'edu_key_factor_gsuite|edu_role_it_staff|edu_team_plan_to_use_manage_it_projects' : 'Edu IT',
    'edu_key_factor_gsuite|edu_role_it_staff|edu_team_plan_to_use_other' : 'Edu IT',

    'edu_key_factor_gsuite|edu_role_procurement' : 'Edu procurement',

    'edu_key_factor_gsuite|edu_role_enu_managment|edu_team_plan_to_use_teach_students' : 'Teach students',
    'edu_key_factor_gsuite|edu_role_enu_managment|edu_team_plan_to_use_collaborate_class' : 'Class collaboration',
    'edu_key_factor_gsuite|edu_role_enu_managment|edu_team_plan_to_use_manage_projects' : 'Edu management',
    'edu_key_factor_gsuite|edu_role_enu_managment|edu_team_plan_to_use_manage_it_projects' : 'Edu IT',
    'edu_key_factor_gsuite|edu_role_enu_managment|edu_team_plan_to_use_other' : 'Edu management',

    'edu_key_factor_gsuite|edu_role_other|edu_team_plan_to_use_teach_students' : 'Teach students',
    'edu_key_factor_gsuite|edu_role_other|edu_team_plan_to_use_collaborate_class' : 'Class collaboration',
    'edu_key_factor_gsuite|edu_role_other|edu_team_plan_to_use_manage_projects' : 'Edu management',
    'edu_key_factor_gsuite|edu_role_other|edu_team_plan_to_use_manage_it_projects' : 'Edu IT',
    'edu_key_factor_gsuite|edu_role_other|edu_team_plan_to_use_other' : 'Edu management',

    'edu_key_factor_cost|edu_role_teacher|edu_team_plan_to_use_teach_students' : 'Teach students',
    'edu_key_factor_cost|edu_role_teacher|edu_team_plan_to_use_collaborate_class' : 'Class collaboration',
    'edu_key_factor_cost|edu_role_teacher|edu_team_plan_to_use_manage_projects' : 'Edu management',
    'edu_key_factor_cost|edu_role_teacher|edu_team_plan_to_use_manage_it_projects' : 'Edu IT',
    'edu_key_factor_cost|edu_role_teacher|edu_team_plan_to_use_other' : 'Teach students',

    'edu_key_factor_cost|edu_role_admin|edu_team_plan_to_use_teach_students' : 'Teach students',
    'edu_key_factor_cost|edu_role_admin|edu_team_plan_to_use_collaborate_class' : 'Class collaboration',
    'edu_key_factor_cost|edu_role_admin|edu_team_plan_to_use_manage_projects' : 'Edu management',
    'edu_key_factor_cost|edu_role_admin|edu_team_plan_to_use_manage_it_projects' : 'Edu IT',
    'edu_key_factor_cost|edu_role_admin|edu_team_plan_to_use_other' : 'Edu office',

    'edu_key_factor_cost|edu_role_it_staff|edu_team_plan_to_use_teach_students' : 'Teach students',
    'edu_key_factor_cost|edu_role_it_staff|edu_team_plan_to_use_collaborate_class' : 'Class collaboration',
    'edu_key_factor_cost|edu_role_it_staff|edu_team_plan_to_use_manage_projects' : 'Edu IT',
    'edu_key_factor_cost|edu_role_it_staff|edu_team_plan_to_use_manage_it_projects' : 'Edu IT',
    'edu_key_factor_cost|edu_role_it_staff|edu_team_plan_to_use_other' : 'Edu IT',

    'edu_key_factor_cost|edu_role_procurement' : 'Edu procurement',

    'edu_key_factor_cost|edu_role_enu_managment|edu_team_plan_to_use_teach_students' : 'Teach students',
    'edu_key_factor_cost|edu_role_enu_managment|edu_team_plan_to_use_collaborate_class' : 'Class collaboration',
    'edu_key_factor_cost|edu_role_enu_managment|edu_team_plan_to_use_manage_projects' : 'Edu management',
    'edu_key_factor_cost|edu_role_enu_managment|edu_team_plan_to_use_manage_it_projects' : 'Edu IT',
    'edu_key_factor_cost|edu_role_enu_managment|edu_team_plan_to_use_other' : 'Edu management',

    'edu_key_factor_cost|edu_role_other|edu_team_plan_to_use_teach_students' : 'Teach students',
    'edu_key_factor_cost|edu_role_other|edu_team_plan_to_use_collaborate_class' : 'Class collaboration',
    'edu_key_factor_cost|edu_role_other|edu_team_plan_to_use_manage_projects' : 'Edu management',
    'edu_key_factor_cost|edu_role_other|edu_team_plan_to_use_manage_it_projects' : 'Edu IT',
    'edu_key_factor_cost|edu_role_other|edu_team_plan_to_use_other' : 'Edu management',

    'edu_key_factor_onboarding|edu_role_teacher|edu_team_plan_to_use_teach_students' : 'Teach students',
    'edu_key_factor_onboarding|edu_role_teacher|edu_team_plan_to_use_collaborate_class' : 'Class collaboration',
    'edu_key_factor_onboarding|edu_role_teacher|edu_team_plan_to_use_manage_projects' : 'Edu management',
    'edu_key_factor_onboarding|edu_role_teacher|edu_team_plan_to_use_manage_it_projects' : 'Edu IT',
    'edu_key_factor_onboarding|edu_role_teacher|edu_team_plan_to_use_other' : 'Teach students',

    'edu_key_factor_onboarding|edu_role_admin|edu_team_plan_to_use_teach_students' : 'Teach students',
    'edu_key_factor_onboarding|edu_role_admin|edu_team_plan_to_use_collaborate_class' : 'Class collaboration',
    'edu_key_factor_onboarding|edu_role_admin|edu_team_plan_to_use_manage_projects' : 'Edu management',
    'edu_key_factor_onboarding|edu_role_admin|edu_team_plan_to_use_manage_it_projects' : 'Edu IT',
    'edu_key_factor_onboarding|edu_role_admin|edu_team_plan_to_use_other' : 'Edu office',

    'edu_key_factor_onboarding|edu_role_it_staff|edu_team_plan_to_use_teach_students' : 'Teach students',
    'edu_key_factor_onboarding|edu_role_it_staff|edu_team_plan_to_use_collaborate_class' : 'Class collaboration',
    'edu_key_factor_onboarding|edu_role_it_staff|edu_team_plan_to_use_manage_projects' : 'Edu IT',
    'edu_key_factor_onboarding|edu_role_it_staff|edu_team_plan_to_use_manage_it_projects' : 'Edu IT',
    'edu_key_factor_onboarding|edu_role_it_staff|edu_team_plan_to_use_other' : 'Edu IT',

    'edu_key_factor_onboarding|edu_role_procurement' : 'Edu procurement',

    'edu_key_factor_onboarding|edu_role_enu_managment|edu_team_plan_to_use_teach_students' : 'Teach students',
    'edu_key_factor_onboarding|edu_role_enu_managment|edu_team_plan_to_use_collaborate_class' : 'Class collaboration',
    'edu_key_factor_onboarding|edu_role_enu_managment|edu_team_plan_to_use_manage_projects' : 'Edu management',
    'edu_key_factor_onboarding|edu_role_enu_managment|edu_team_plan_to_use_manage_it_projects' : 'Edu IT',
    'edu_key_factor_onboarding|edu_role_enu_managment|edu_team_plan_to_use_other' : 'Edu management',

    'edu_key_factor_onboarding|edu_role_other|edu_team_plan_to_use_teach_students' : 'Teach students',
    'edu_key_factor_onboarding|edu_role_other|edu_team_plan_to_use_collaborate_class' : 'Class collaboration',
    'edu_key_factor_onboarding|edu_role_other|edu_team_plan_to_use_manage_projects' : 'Edu management',
    'edu_key_factor_onboarding|edu_role_other|edu_team_plan_to_use_manage_it_projects' : 'Edu IT',
    'edu_key_factor_onboarding|edu_role_other|edu_team_plan_to_use_other' : 'Edu management',

    'edu_key_factor_soft|edu_role_teacher|edu_team_plan_to_use_teach_students' : 'Teach students',
    'edu_key_factor_soft|edu_role_teacher|edu_team_plan_to_use_collaborate_class' : 'Class collaboration',
    'edu_key_factor_soft|edu_role_teacher|edu_team_plan_to_use_manage_projects' : 'Edu management',
    'edu_key_factor_soft|edu_role_teacher|edu_team_plan_to_use_manage_it_projects' : 'Edu IT',
    'edu_key_factor_soft|edu_role_teacher|edu_team_plan_to_use_other' : 'Teach students',

    'edu_key_factor_soft|edu_role_admin|edu_team_plan_to_use_teach_students' : 'Teach students',
    'edu_key_factor_soft|edu_role_admin|edu_team_plan_to_use_collaborate_class' : 'Class collaboration',
    'edu_key_factor_soft|edu_role_admin|edu_team_plan_to_use_manage_projects' : 'Edu management',
    'edu_key_factor_soft|edu_role_admin|edu_team_plan_to_use_manage_it_projects' : 'Edu IT',
    'edu_key_factor_soft|edu_role_admin|edu_team_plan_to_use_other' : 'Edu office',

    'edu_key_factor_soft|edu_role_it_staff|edu_team_plan_to_use_teach_students' : 'Teach students',
    'edu_key_factor_soft|edu_role_it_staff|edu_team_plan_to_use_collaborate_class' : 'Class collaboration',
    'edu_key_factor_soft|edu_role_it_staff|edu_team_plan_to_use_manage_projects' : 'Edu IT',
    'edu_key_factor_soft|edu_role_it_staff|edu_team_plan_to_use_manage_it_projects' : 'Edu IT',
    'edu_key_factor_soft|edu_role_it_staff|edu_team_plan_to_use_other' : 'Edu IT',

    'edu_key_factor_soft|edu_role_procurement' : 'Edu procurement',

    'edu_key_factor_soft|edu_role_enu_managment|edu_team_plan_to_use_teach_students' : 'Teach students',
    'edu_key_factor_soft|edu_role_enu_managment|edu_team_plan_to_use_collaborate_class' : 'Class collaboration',
    'edu_key_factor_soft|edu_role_enu_managment|edu_team_plan_to_use_manage_projects' : 'Edu management',
    'edu_key_factor_soft|edu_role_enu_managment|edu_team_plan_to_use_manage_it_projects' : 'Edu IT',
    'edu_key_factor_soft|edu_role_enu_managment|edu_team_plan_to_use_other' : 'Edu management',

    'edu_key_factor_soft|edu_role_other|edu_team_plan_to_use_teach_students' : 'Teach students',
    'edu_key_factor_soft|edu_role_other|edu_team_plan_to_use_collaborate_class' : 'Class collaboration',
    'edu_key_factor_soft|edu_role_other|edu_team_plan_to_use_manage_projects' : 'Edu management',
    'edu_key_factor_soft|edu_role_other|edu_team_plan_to_use_manage_it_projects' : 'Edu IT',
    'edu_key_factor_soft|edu_role_other|edu_team_plan_to_use_other' : 'Edu management',

    'edu_key_factor_other|edu_role_teacher|edu_team_plan_to_use_teach_students' : 'Teach students',
    'edu_key_factor_other|edu_role_teacher|edu_team_plan_to_use_collaborate_class' : 'Class collaboration',
    'edu_key_factor_other|edu_role_teacher|edu_team_plan_to_use_manage_projects' : 'Edu management',
    'edu_key_factor_other|edu_role_teacher|edu_team_plan_to_use_manage_it_projects' : 'Edu IT',
    'edu_key_factor_other|edu_role_teacher|edu_team_plan_to_use_other' : 'Teach students',

    'edu_key_factor_other|edu_role_admin|edu_team_plan_to_use_teach_students' : 'Teach students',
    'edu_key_factor_other|edu_role_admin|edu_team_plan_to_use_collaborate_class' : 'Class collaboration',
    'edu_key_factor_other|edu_role_admin|edu_team_plan_to_use_manage_projects' : 'Edu management',
    'edu_key_factor_other|edu_role_admin|edu_team_plan_to_use_manage_it_projects' : 'Edu IT',
    'edu_key_factor_other|edu_role_admin|edu_team_plan_to_use_other' : 'Edu office',

    'edu_key_factor_other|edu_role_it_staff|edu_team_plan_to_use_teach_students' : 'Teach students',
    'edu_key_factor_other|edu_role_it_staff|edu_team_plan_to_use_collaborate_class' : 'Class collaboration',
    'edu_key_factor_other|edu_role_it_staff|edu_team_plan_to_use_manage_projects' : 'Edu IT',
    'edu_key_factor_other|edu_role_it_staff|edu_team_plan_to_use_manage_it_projects' : 'Edu IT',
    'edu_key_factor_other|edu_role_it_staff|edu_team_plan_to_use_other' : 'Edu IT',

    'edu_key_factor_other|edu_role_procurement' : 'Edu procurement',

    'edu_key_factor_other|edu_role_enu_managment|edu_team_plan_to_use_teach_students' : 'Teach students',
    'edu_key_factor_other|edu_role_enu_managment|edu_team_plan_to_use_collaborate_class' : 'Class collaboration',
    'edu_key_factor_other|edu_role_enu_managment|edu_team_plan_to_use_manage_projects' : 'Edu management',
    'edu_key_factor_other|edu_role_enu_managment|edu_team_plan_to_use_manage_it_projects' : 'Edu IT',
    'edu_key_factor_other|edu_role_enu_managment|edu_team_plan_to_use_other' : 'Edu management',

    'edu_key_factor_other|edu_role_other|edu_team_plan_to_use_teach_students' : 'Teach students',
    'edu_key_factor_other|edu_role_other|edu_team_plan_to_use_collaborate_class' : 'Class collaboration',
    'edu_key_factor_other|edu_role_other|edu_team_plan_to_use_manage_projects' : 'Edu management',
    'edu_key_factor_other|edu_role_other|edu_team_plan_to_use_manage_it_projects' : 'Edu IT',
    'edu_key_factor_other|edu_role_other|edu_team_plan_to_use_other' : 'Edu management'
}
