import './_TemplatesSearchSelect.scss';
import { ITemplatesSearchSelectProps } from './types';
import { SearchSelect } from '../../../../../base/components/SearchSelect/SearchSelect';
import * as React from 'react';
import { SearchedCard } from '../../../../../main/topBar/components/TopBarFilterButton/SearchedCard/SearchedCard';

export const TemplatesSearchSelect = ({
    cards,
    onSelect
}: ITemplatesSearchSelectProps) => {
    const className = 'create-template__search';

    let [value, _setValue] = React.useState('');
    const debounce = React.useRef(null);

    const setValue = (newValue: string) => {
        value = newValue;
        _setValue(newValue);
    };

    const onSelectCard = (value: string) => {
        onSelect(Number(value));
        setValue(null);
    };

    const [isMountDropdown, setIsMountDropdown] = React.useState(false);

    function handleSearchChange (val: string) {
        setValue(val);
        if (val) {
            if (!isMountDropdown) {
                if (debounce.current) clearTimeout(debounce.current);
                debounce.current = setTimeout(() => setIsMountDropdown(true), 800); // 300 анимация + 500 дебонс (не видеть дропдаун со всеми картами)
            }
        } else {
            setIsMountDropdown(false);
            if (debounce.current) clearTimeout(debounce.current);
        }
    };

    return (
        <SearchSelect
            className={className}
            options={cards}
            onSelect={onSelectCard}
            onInputChange={handleSearchChange}
            text={value}
            isMountDropdown={isMountDropdown}
            searchDebounce={500}
            isFitWindow
            isEnterEnabled
            option={SearchedCard}
            onBlur={() => setValue(null)}
        />
    )
}
