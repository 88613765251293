import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../../types/types';
import { showSnoozeBlocker } from '../../../../../../../base/effects/showSnoozeBlocker';

export const onClickDisallow = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(showSnoozeBlocker({
            allow: 'allowGantt'
        }));
    };
    return action;
};
