import './_NavigationPanelCreateBoardDropDown.scss';
import { INavigationPanelCreateBoardDropDownProps } from './types';
import { Button, ButtonDropdown, Icon, Loader, LoaderBlock, SelectListItem } from 'kui';
import { NAVIGATION_PANEL_ARROW_RIGHT_CLASS, NAVIGATION_PANEL_INNER_CLASS } from '../../constants';
import {
} from '../NavigationPanelCreateBoard/constants';
import { SearchSelect } from '../../../../base/components/SearchSelect/SearchSelect';
import * as React from 'react';
import {
    BOARDS_ADD_CREATE_SHARED_DRIVE,
    CREATE_BOARD_ON_SHARED_DRIVE_TIMER,
    CREATE_ON_SHARED_DRIVE_CLASS
} from './constants';
import { ISearchSelectOption } from '../../../../base/components/SearchSelect/types';
import { EmptyDummy } from '../../../../base/components/EmptyDummy/EmptyDummy';
import { getEmptyDummy } from '../../../../dialogs/openBoards/containers/TabBoards/constants';

export const NavigationPanelCreateBoardDropDown: React.FC<INavigationPanelCreateBoardDropDownProps> =
React.forwardRef(({
    isOpened,
    isMobile,
    planBlocker,
    scopesBlocker,
    onCreateBoard,
    onLoadOptions,
    onOpen,
    onClose,
    onClick
}, ref) => {
    const classButton = CREATE_ON_SHARED_DRIVE_CLASS + '__button';
    const classDropdown = CREATE_ON_SHARED_DRIVE_CLASS + '__dropdown';
    const classLoader = CREATE_ON_SHARED_DRIVE_CLASS + '__loader';
    const classEmpty = CREATE_ON_SHARED_DRIVE_CLASS + '--empty';
    const classSearchSelect = CREATE_ON_SHARED_DRIVE_CLASS + '__search-select';

    const [isActive, setActive] = React.useState(false);
    let [isAnimate, setAnimateHook] = React.useState(false);
    const [options, setOptions] = React.useState<ISearchSelectOption[]>([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const timer = React.useRef(null);

    function setAnimate (isAnimateHook: boolean) {
        isAnimate = isAnimateHook;
        setAnimateHook(isAnimate);
    }

    const onMouseEnterHandler = () => {
        onOpen();
        if (isMobile) return;
        if (timer.current) {
            clearTimeout(timer.current);
            timer.current = null;
        }
        timer.current = setTimeout(() => {
            timer.current = null;
            setActive(true);
            setAnimate(true);
            setTimeout(() => setAnimateHook(false), 500);
        }, CREATE_BOARD_ON_SHARED_DRIVE_TIMER);
    };

    const onMouseLeaveHandler = (e: any) => {
        if (isMobile) return;
        if (timer.current) {
            clearTimeout(timer.current);
            timer.current = null;
        }
        timer.current = setTimeout(() => {
            const popup = document.querySelector('.second-panel--pro-features-trial') as HTMLElement;
            if (popup) return;
            timer.current = null;
            setActive(false);
        }, CREATE_BOARD_ON_SHARED_DRIVE_TIMER);
    };

    const onHandleClick = () => {
        onOpen();
        onClick();
        if (isAnimate) return;
        if (timer.current) {
            clearTimeout(timer.current);
            timer.current = null;
        }
        setActive(!isActive);
    };

    const onCloseHandle = () => {
        setActive(false);
    };

    const onSelect = (driveId: string) => {
        onCreateBoard(driveId);
        onClose();
    };

    React.useEffect(() => {
        if (isLoading || !isOpened || planBlocker || scopesBlocker) return;

        setIsLoading(true);

        onLoadOptions().then((result) => {
            setOptions(result);
            setIsLoading(false)
        });
    }, [isOpened]);

    React.useEffect(() => {
        if (!isActive) {
            const dropdownButton = document.querySelector('.' + classButton) as HTMLElement;
            if (dropdownButton) dropdownButton.focus()
        } else {
            const searchSelect = document.querySelector('.' + classDropdown + ' .kui-input__item') as HTMLInputElement;
            if (searchSelect) {
                searchSelect.focus();
            }
        }
    }, [isActive]);

    return (
        <div
            key={'NavigationPanelCreateBoardDropDown'}
            ref={ref as any}
        >
            <SelectListItem
                className={CREATE_ON_SHARED_DRIVE_CLASS}
            >
                <div
                    tabIndex={-1}
                    onMouseEnter={onMouseEnterHandler}
                    onMouseLeave={onMouseLeaveHandler}
                >
                    <ButtonDropdown
                        portal
                        portalId={'navigation-panel-portal'}
                        portalSelector={'.' + NAVIGATION_PANEL_INNER_CLASS}
                        directionHorizontal={'right'}
                        dropdownClassName={`${classDropdown}`}
                        opened={isActive}
                        disabled={isAnimate}
                        onClose={onCloseHandle}
                    >
                        <Button
                            text={BOARDS_ADD_CREATE_SHARED_DRIVE}
                            className={classButton}
                            variant={'icon-text'}
                            onClick={onHandleClick}
                        >
                            <Icon
                                xlink={'share-specific'}
                                size={24}
                            />
                            {isLoading
                                ? <Loader className={classLoader}/>
                                : <Icon
                                    xlink={'arrow-s-right'}
                                    size={16}
                                    className={NAVIGATION_PANEL_ARROW_RIGHT_CLASS}
                                />
                            }
                        </Button>
                        {isLoading
                            ? <LoaderBlock className={classLoader}/>
                            : planBlocker
                                ? <EmptyDummy
                                    className={classEmpty}
                                    {...getEmptyDummy()[planBlocker]}
                                />
                                : scopesBlocker
                                    ? <EmptyDummy
                                        className={classEmpty}
                                        {...getEmptyDummy()[scopesBlocker]}
                                    />
                                    : <SearchSelect
                                        isDropdownUplifted
                                        className={classSearchSelect}
                                        options={options}
                                        onSelect={(driveId) => onSelect(driveId)}
                                        isCloseOnClick={false}
                                        opened
                                        onKeyDown={e => {
                                            if (e.key === 'Escape') {
                                                setActive(false);
                                            }
                                        }}
                                        autoFocus
                                    />
                        }
                    </ButtonDropdown>
                </div>
            </SelectListItem>
        </div>
    );
});
