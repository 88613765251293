import { SegmentProfileBoxEvent, segmentTrackAction } from '../../../../../../middlewares/segment';
import { root } from '../../../../../../store/constants';
import { Dispatch, ThunkAction } from '../../../../../../types/actions';

export const onClickUpgrade = (
): ThunkAction => {
    const action = (dispatch: Dispatch) => {
        dispatch(segmentTrackAction(SegmentProfileBoxEvent.PRICING_ROW));
        root.App.controller.showPayment();
    };
    return action;
};
