import { AT_CARD_IDS_SET } from './constants';
import { IMatchCardIdsSetAction } from './types';
import { TCardId } from '../../../../../../../types/types';

export const matchCardIdsSetAction = (
    cardIds: TCardId[]
): IMatchCardIdsSetAction => ({
        type: AT_CARD_IDS_SET,
        cardIds
    }
);
