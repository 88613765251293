import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { onDelete } from './events/onDelete';
import { IFilterEvents, IFilterFields } from '../../components/Filter/types';
import { savedFilterSelector } from '../../../../../aside_panel/filterPanelBoard/store/selectors/savedFilterSelector';
import { IFilterHOCProps } from './types';
import { onHide } from './events/onHide';
import { onToggle } from './events/onToggle';
import { onEdit } from './events/onEdit';
import { Filter } from '../../components/Filter/Filter';
import {
    getFilteredMyTaskCardsForAmendedFilter
} from '../../../../../../aside_panel/dashboard_filter/dashboard_filter_helper_card_filters';
import {
    getMyTaskCardsForFilter
} from '../../../../../../../store/model/middlewares/filters/selectors/getMyTaskCardsForFilter';
import { getEnabledFilters } from '../../../../../../../store/model/middlewares/filters/selectors/getEnabledFilters';
import { MY_WORK_FILTER_BOARD_ID } from '../../../../../aside_panel/filterPanelBoard/constants';
import {
    getFilterIgnoreCardIds
} from '../../../../../aside_panel/filterPanelBoard/store/selectors/getFilterIgnoreCardIds';
import {
    getDefaultInitialFilter
} from '../../../../../aside_panel/filterPanelBoard/store/filter/helpers/getDefaultInitialFilter';
import { BIG_BOARD_CARDS } from '../../../../../../../const';
import { onToggleAll } from '../events/onToggleAll';
import { getAllFiltersUnselected } from './selectors/getAllFiltersUnselected';
import { onAdd } from '../events/onAdd';
import { onToggleTitle } from './events/onToggleTitle';
import {
    getMyWorkSavedFilterHideTitle
} from '../../../../../aside_panel/filterPanelBoard/store/selectors/getMyWorkSavedFilterHideTitle';
import { onToggleCollapse } from '../events/onToggleCollapse';
import { onIconChange } from './events/onIconChange';
import { ERestCardAssigneeInfoType } from '../../../../../../../types/rest/IRestCardAssigneeInfo';
import { getAuthUser } from '../../../../../../../store/model/authUser/selectors/getAuthUser';
import { fixOldColors } from '../../../Logo/components/constants';

const mapStateToProps = (
    state: IApplicationState,
    { filterId, isCollapsed, isAllFiltersEnabled, isFilterEnabled }: IFilterHOCProps
): IFilterFields => {
    const { allowSavedFilters } = getAuthUser(state);
    const filteredCards = getMyTaskCardsForFilter(state);
    const filter = savedFilterSelector(state, filterId);
    const enabledFilters = [getDefaultInitialFilter(), ...getEnabledFilters(state, MY_WORK_FILTER_BOARD_ID)];
    const ignoredCardsIds = getFilterIgnoreCardIds(state);
    const { name, isEnabled, meta } = filter;
    const cardsCount = filteredCards.length < BIG_BOARD_CARDS ?
        getFilteredMyTaskCardsForAmendedFilter(filteredCards, { ...filter, isEnabled: true }, ignoredCardsIds, enabledFilters).length
        : null;
    const isAssignedToMe = meta.myWorkIcon &&
        !meta.myWorkIcon.icon &&
        meta.myWorkCards &&
        meta.myWorkCards[0] === ERestCardAssigneeInfoType.ASSIGNED_TO_ME;

    return {
        allowSavedFilters,
        name,
        filterId,
        icon: fixOldColors(meta.myWorkIcon),
        isAssignedToMe,
        isAllFiltersEnabled,
        isAllFiltersUnselected: getAllFiltersUnselected(state),
        isEnabled: isEnabled && isFilterEnabled,
        isHideTitle: isCollapsed || getMyWorkSavedFilterHideTitle(state, filterId),
        isReadonly: meta && meta.isMyWorkReadonly,
        isCollapsed,
        cardsCount
    }
};

const mapDispatchToProps = (
    dispatch: any,
    { filterId, setDragDisabled, setListWidth }: IFilterHOCProps
): IFilterEvents => ({
    setDragDisabled,
    setListWidth,
    onDelete: () => dispatch(onDelete(filterId)),
    onHide: () => dispatch(onHide(filterId)),
    onToggle: () => dispatch(onToggle(filterId)),
    onToggleAll: () => dispatch(onToggleAll(filterId)),
    onEdit: () => dispatch(onEdit(filterId)),
    onToggleTitle: () => dispatch(onToggleTitle(filterId)),
    onCreate: () => dispatch(onAdd()),
    onToggleCollapse: () => dispatch(onToggleCollapse()),
    onIconChange: (icon) => dispatch(onIconChange(filterId, icon))
});

export const FilterHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(Filter);

FilterHOC.displayName = 'FilterHOC';
