import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { ICardHOCProps } from '../CardHOC/types';
import { CardColorTags } from '../../components/CardColorTags/CardColorTags';
import { ICardColorTagsEvents, ICardColorTagsFields } from '../../components/CardColorTags/types';
import { onLoadCard } from '../events/onLoadCard';
import { onChange } from '../../../../../aside_panel/cardDetails/ColorTags/hocs/events/onChange';
import { getAuthUser } from '../../../../../../../store/model/authUser/selectors/getAuthUser';
import { onClose } from '../../../../../aside_panel/cardDetails/ColorTags/hocs/CardColorTagsHOC/events/onClose';

const mapStateToProps = (
    state: IApplicationState,
    { card, portalId }: ICardHOCProps
): ICardColorTagsFields => {
    const { cardId, dashboardId, colorTags } = card;
    const { allowColorTagCount } = getAuthUser(state);
    return {
        aloudCount: allowColorTagCount,
        boardId: dashboardId,
        cardId,
        colorTags,
        portalId
    }
}

const mapDispatchToProps = (
    dispatch: any,
    { card }: ICardHOCProps
): ICardColorTagsEvents => ({
    onLoadCard: () => dispatch(onLoadCard(card.cardId)),
    onChange: (colorId, source) => dispatch(onChange(card.cardId, colorId, source)),
    onModalClose: () => dispatch(onClose())
})

export const CardColorTagsHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardColorTags);
CardColorTagsHOC.displayName = 'CardColorTagsHOC';
