import { ICardsAddAction } from './types';
import { AT_CARDS_ADD } from './constants';
import { ICards } from '../types';

export const cardsAddAction = (
    cards: ICards
): ICardsAddAction => ({
    type: AT_CARDS_ADD,
    cards,
});
