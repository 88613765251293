import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../types/types';
import { SegmentBoardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment/index';
import { getFile } from 'app/helper/authorisation/microsoft/graph/api/getFile';
import { msBuildUrl } from 'app/helper/file_helper';
import { TDriveDocId } from 'app/types/rest/IRestDriveDoc';
import { getDriveDoc } from 'app/store/model/driveDocs/selectors/getDriveDoc';
import { EAuthUserPlatformType } from 'app/types/rest/IRestAuthUser';

export const onLinkClick = (
    event: React.SyntheticEvent,
    driveDocId: TDriveDocId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentBoardEvent.BOARD_ATTACHMENTS_LINK_CLICKED));
        const attachment = getDriveDoc(getState(), driveDocId);
        if (
            attachment.platformType === EAuthUserPlatformType.MICROSOFT &&
            !attachment.url.includes('sourcedoc=') // ссылка на доки правильная, т.к. не содержит имя папки
        ) {
            event.preventDefault(); // не открывать ссылку
            getFile(attachment.driveId, attachment.fileId)
                .then(item => {
                    return msBuildUrl(item);
                })
                .then(url => {
                    window.open(url, '_blank');
                });
        }
    };
    return action;
};
