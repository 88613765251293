import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState, TCardId } from '../../../../types/types';
import { getCardPredecessorsDependencies } from '../../../../store/model/dependencies/dependencies/selectors/getCardPredecessorsDependencies';
import { getCardSuccessorsDependencies } from '../../../../store/model/dependencies/dependencies/selectors/getCardSuccessorsDependencies';
import { IDependencies } from '../../../../store/model/dependencies/dependencies/types';
import { dependenciesDelete } from './api/helper/dependenciesDelete';

export const deleteAllDependencies = (
    cardId: TCardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const cardDependencies = [
            ...getCardPredecessorsDependencies(state, cardId),
            ...getCardSuccessorsDependencies(state, cardId)
        ];
        if (cardDependencies.length === 0) return Promise.resolve();
        const deps: IDependencies = {};
        cardDependencies.forEach(dep => {
            deps[dep.id] = dep
        });
        return dispatch(dependenciesDelete(deps, true));
    };
    return action;
};
