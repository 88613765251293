import { connect } from 'react-redux';
import { IAffiliateDialogEvents, IAffiliateDialogFields } from '../../components/AffiliateDialog/types';
import { onClose } from './events/onClose';
import { AffiliateDialog } from '../../components/AffiliateDialog/AffiliateDialog';
import { IAffiliateDialogHOCProps } from './types';
import { onCopy } from './events/onCopy';
import { onDidMount } from './events/onDidMount';
import { onFaqClicked } from './events/onFaqClicked';
import { IApplicationState } from '../../../../../../types/types';
import { getUserAffiliateId } from '../../../../../../store/model/selectors/getUserAffiliateId';

const mapStateToProps = (
    state: IApplicationState,
    { source }: IAffiliateDialogHOCProps
): IAffiliateDialogFields => {
    return {
        source,
        affiliateId: getUserAffiliateId(state)
    }
};

const mapDispatchToProps = (
    dispatch: any,
    ownProps: IAffiliateDialogHOCProps
): IAffiliateDialogEvents => {
    return {
        onClose: () => dispatch(onClose()),
        onCopy: (link: string) => dispatch(onCopy(link)),
        onDidMount: () => dispatch(onDidMount()),
        onFaqClicked: () => dispatch(onFaqClicked())
    }
};

export const AffiliateDialogHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(AffiliateDialog);

AffiliateDialogHOC.displayName = 'AffiliateDialogHOC';
