import * as deepFreeze from 'deep-freeze';
import { IDependencies } from '../types';
import {
    AT_DEPENDENCIES_UPDATE,
    AT_DEPENDENCY_ACTION_SET,
    AT_DEPENDENCY_DELETE,
    AT_DEPENDENCY_SET,
    TDependenciesAction
} from '../actions/types';
import { dependencyReducer } from '../../dependency/reducers/dependencyReducer';
import { updateAction } from '../../dependency/actions/updateAction';

const initialState: IDependencies = {};

const _dependenciesReducer = (
    state = initialState,
    action: TDependenciesAction
): IDependencies => {
    switch (action.type) {
        case AT_DEPENDENCY_ACTION_SET: {
            const {dependencyId, dependencyAction} = action;
            return {
                ...state,
                [dependencyId]: dependencyReducer(state[dependencyId], dependencyAction)
            }
        }
        case AT_DEPENDENCY_SET: {
            const {dependencyId, dependency} = action;
            return {
                ...state,
                [dependencyId]: dependency
            }
        }
        case AT_DEPENDENCY_DELETE: {
            const stateNew: IDependencies = {...state};
            const {dependencyId} =  action;
            delete stateNew[dependencyId];
            return stateNew;
        }
        case AT_DEPENDENCIES_UPDATE: {
            const { dependencies } = action;
            const stateUpdated: IDependencies = {};
            for (let dependencyId in dependencies) {
                stateUpdated[dependencyId] = dependencyReducer(state[dependencyId], updateAction(dependencies[dependencyId]));
            }
            return {
                ...state,
                ...stateUpdated
            };
        }
        default:
            return state;
    }
};

export const dependenciesReducer = (
    state = initialState,
    action: TDependenciesAction
): IDependencies => {
    // @ts-ignore
    if (window.Settings.development) {
        // @ts-ignore
        return deepFreeze(_dependenciesReducer(state, action));
    } else {
        return _dependenciesReducer(state, action);
    }
};
