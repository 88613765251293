import { IApplicationState } from '../../../../../../../types/types';
import { getAuthUser } from '../../../../../../../store/model/authUser/selectors/getAuthUser';
import { PWC_IMPORT_USERS } from '../constants';

export const isPwcImportEnabled = (
    state: IApplicationState
): boolean => {
    const user = getAuthUser(state);
    return user && PWC_IMPORT_USERS.includes(user.email);
};
