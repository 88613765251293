import { IApplicationState, TBoardId } from '../../../../types/types';
import { IBackup } from '../types';
import { getBoardBackupList } from './getBoardBackupList';

export const getBoardBackup = (
    state: IApplicationState,
    boardId: TBoardId,
    fileId: string,
): IBackup  => {
    const list = getBoardBackupList(state, boardId);

    return list.find(backup => backup.fileId === fileId);
}
