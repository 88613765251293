import { Dispatch, ThunkAction } from '../../../../../../types/actions';
import { getActiveCardId } from '../../../../../../store/router/selectors/getActiveCardId';
import { IGetState, TCardId } from '../../../../../../types/types';
import { getCard } from '../../../../../../store/model/selectors/getCard';
import { isTimeTrackerTracking } from '../../../../aside_panel/cardDetails/TabTimingSection/TimeTracker/store/selectors/isTimeTrackerTracking';
import { showTimeTrackerDialog } from '../effects/showTimeTrackerDialog';
import { getTimeTrackerState } from '../../../../aside_panel/cardDetails/TabTimingSection/TimeTracker/store/selectors/getTimeTrackerState';
import { showTimeTrackerActions } from '../effects/showTimeTrackerActions';
import { startTrackingCard } from '../effects/startTrackingCard';
import { segmentTrackAction } from '../../../../../../middlewares/segment';
import { SegmentTimeTrackerEvent } from '../../../../../../middlewares/segment/trackEntities/timeTrackerEvents';
import { FAKE_CARD_ID_FOR_WIDGET_PRESENTATION } from 'app/const';
import { timeTrackerDeleteAction } from 'app/view/react_components/aside_panel/cardDetails/TabTimingSection/TimeTracker/store/actions/timeTrackerDeleteAction';
import { root } from '../../../../../../store/constants';
import { ETutorial } from '../../../../../../types/rest/IUserMeta';
import { getAuthUser } from '../../../../../../store/model/authUser/selectors/getAuthUser';
import { getProFeaturesTrialActive } from '../../../../../../store/model/authUser/selectors/getProFeaturesTrialActive';

export const onButtonClick = (
    targetCardId: TCardId
): ThunkAction => {
    return (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const isTracking = isTimeTrackerTracking(state);

        if (isTracking) {
            dispatch(segmentTrackAction(SegmentTimeTrackerEvent.STOP));
            const {cardId} = getTimeTrackerState(state);

            if (cardId === FAKE_CARD_ID_FOR_WIDGET_PRESENTATION) {
                dispatch(timeTrackerDeleteAction());
                return;
            }

            if (targetCardId) {
                const theSameCard = cardId === targetCardId;
                if (theSameCard) return dispatch(showTimeTrackerDialog()); //details of tracking card

                const targetCard = getCard(state, targetCardId);
                if (targetCard) return dispatch(showTimeTrackerActions({targetCardId})); //target card loaded
            } else {
                const trackedCard = getCard(state, cardId);
                if (trackedCard) {
                    dispatch(showTimeTrackerDialog());
                } else {
                    dispatch(showTimeTrackerActions())
                }
            }

        } else {
            dispatch(segmentTrackAction(SegmentTimeTrackerEvent.START));
            const activeCardId = getActiveCardId(state);
            dispatch(startTrackingCard(targetCardId || activeCardId));
        }

        const user = getAuthUser(state);
        if (user['allowTimeTracker'] || getProFeaturesTrialActive(state)) {
            root.App.controller.showTutorial(ETutorial.TIME_TRACKER_TUTORIAL);
        }
    };
};
