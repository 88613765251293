import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../types/types';
import { ICookiesEvents, ICookiesFeilds } from '../../components/Cookies/types';
import { Cookies } from '../../components/Cookies/Cookies';
import { isPerformanceAllowedSelector } from '../../selectors/isPerformanceAllowedSelector';
import { isShowSelector } from '../../selectors/isShowSelector';
import { onCookieSettingsClick } from './events/onCookieSettingsClick';
import { onAcceptCookie } from './events/onAcceptCookie';
import { isSettingsOpenedSelector } from '../../selectors/isSettingsOpenedSelector';
import { getNewPerformanceAllowedValueSelector } from '../../selectors/getNewPerformanceAllowedValueSelector';
import { checkNewValueExistsSelector } from '../../selectors/checkNewValueExistsSelector';
import { onShow } from './events/onShow';
import { ICookiesHOCOwnProps } from './types';
import { onChangePerformance } from './events/onChangePerformance';

const mapStateToProps = (
    state: IApplicationState,
    { isUserSettings }: ICookiesHOCOwnProps
): ICookiesFeilds => {
    const isNewValueExists = checkNewValueExistsSelector(state);
    const props = {
        isShow: isShowSelector(state),
        isPerformanceAllowed: isNewValueExists? getNewPerformanceAllowedValueSelector(state) : isPerformanceAllowedSelector(state),
        isSettingsOpened: isSettingsOpenedSelector(state),
        isUserSettings
    };
    return props;
};

const mapDispatchToProps = (
    dispatch: any
): ICookiesEvents => ({
    onAccept: (isPerformanceAllowed) => dispatch(onAcceptCookie(isPerformanceAllowed)),
    onChangePerformance: (isPerformanceAllowed) => dispatch(onChangePerformance(isPerformanceAllowed)),
    onCookieSettingsClick: () => dispatch(onCookieSettingsClick()),
    onShow: () => dispatch(onShow())
});

export const CookiesHOC = connect(mapStateToProps, mapDispatchToProps)(Cookies);
