import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../../types/types';
import { IBoardCardAgingHOCProps } from './types';
import { CardAging } from '../../components/CardAging/CardAging';
import { ICardAgingEvents, ICardAgingFields } from '../../components/CardAging/types';
import { getPanelReadonly } from '../../../../selectors/getPanelReadonly';
import { onIndicatorTypeChange } from './events/onIndicatorTypeChange';
import { onToggleOpacity } from './events/onToggleOpacity';
import { onToggleIndicator } from './events/onToggleIndicator';
import { getBoardCardAgingOpacity } from '../../../../../../../../store/model/board/selectors/getBoardCardAgingOpacity';
import {
    getBoardCardAgingIndicatorType
} from '../../../../../../../../store/model/board/selectors/getBoardCardAgingIndicatorType';
import { getBoardCardAgingEnabled } from '../../../../../../../../store/model/board/selectors/getBoardCardAgingEnabled';
import {
    getBoardCardAgingIndicator
} from '../../../../../../../../store/model/board/selectors/getBoardCardAgingIndicator';
import { getAuthUser } from '../../../../../../../../store/model/authUser/selectors/getAuthUser';

const mapStateToProps = () => {
    return (
        state: IApplicationState,
        { boardId }: IBoardCardAgingHOCProps
    ): ICardAgingFields => {
        return {
            isDisabled: !getAuthUser(state).allowCardAgeing || getPanelReadonly(state, boardId),
            isEnabled: getBoardCardAgingEnabled(state, boardId),
            isOpacity: getBoardCardAgingOpacity(state, boardId),
            isIndicator: getBoardCardAgingIndicator(state, boardId),
            indicatorType: getBoardCardAgingIndicatorType(state, boardId)
        }
    };
};

const mapDispatchToProps = (
    dispatch: any,
    { boardId }: IBoardCardAgingHOCProps
): ICardAgingEvents => {
    return {
        onIndicatorChange: () => dispatch(onToggleIndicator(boardId)),
        onOpacityChange: () => dispatch(onToggleOpacity(boardId)),
        onIndicatorTypeChange: (value) => dispatch(onIndicatorTypeChange(boardId, value))
    }
};

export const CardAgingHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardAging);

CardAgingHOC.displayName = 'CardAgingHOC';
