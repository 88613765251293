import { IApplicationState, TBoardId } from '../../../../types/types';
import { getBoard } from '../../selectors/getBoardById';
import { IBoardTheme } from '../types';
import { MY_WORK_BOARD_ID } from '../../../../view/react_components/main/myWorkView/constants';
import { getMyWorkTheme } from '../../../../view/react_components/main/myWorkView/selectors/getMyWorkTheme';

export const getBoardTheme = (
    state: IApplicationState,
    boardId: TBoardId
): IBoardTheme => {
    if (boardId === MY_WORK_BOARD_ID) {
        return getMyWorkTheme(state);
    }
    return getBoard(state, boardId).theme;
};
