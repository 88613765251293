import { ThunkAction } from '../../../../../../../../types/actions';
import {
    getCardChecklistItemsNumber
} from '../../../../../../../../store/model/checklists/checklists/selectors/getCardChecklistItemsNumber';
import {
    cardRestGetSections,
    TCardRestGetSectionsRequest
} from '../../../../../../../../rest/effects/card/card/api/helper/cardRestGetSections';
import { TCardId } from '../../../../../../../../types/types';
import { getCard } from '../../../../../../../../store/model/selectors/getCard';

export const loadChecklist = (
    cardId: TCardId,
): ThunkAction => {
    return (
        dispatch,
        getState
    ) => {
        const state = getState();
        const card = getCard(state, cardId);
        const cardsRequest: TCardRestGetSectionsRequest[] = [];
        if (card.checklistStats.totalCount > 0 && !getCardChecklistItemsNumber(state, card.id)) {
            cardsRequest.push({
                cardId: card.id,
                sections: ['checklists']
            })
        }
        if (!cardsRequest.length) return Promise.resolve();

        return dispatch(cardRestGetSections(cardsRequest));
    }
}
