import { INotificationBeforeDueDateCardEditState } from '../../../../../../../types/model';
import { IApplicationState, TCardId } from '../../../../../../../types/types';

export const getNotificationBeforeDueDateEditsSelector = (
    state: IApplicationState,
    cardId: TCardId,
): INotificationBeforeDueDateCardEditState => {
    const { notificationBeforeDueDateEdits } = state;
    if (cardId && notificationBeforeDueDateEdits[cardId]) {
        return notificationBeforeDueDateEdits[cardId];
    }
};
