import { IThemesActionSetAction, THEMES_ACTION_SET } from './types';
import { TLibThemesAction } from '../libThemes/actions/types';

export const themesActionSet = (
    themesAction: TLibThemesAction
): IThemesActionSetAction => {
    return {
        type: THEMES_ACTION_SET,
        themesAction
    }
};
