import * as React from 'react';
import './_CommentsSectionPopup.scss';
import { ICommentsSectionPopupProps } from './types';
import { SettingsSection } from '../../../../../base/components/SettingsSection/SettingsSection';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { CLASS_CARD_DETAILS_SECTION } from '../../../constants';
import { CARD_SECTION_COMMENTS } from '../../constants';
import { CommentInputHOC } from '../../hocs/CommentsInputHOC/CommentsInputHOC';

export function CommentsSectionPopup({
    cardId,
    boardId,
    onClose,
}: ICommentsSectionPopupProps) {
    const className = CLASS_CARD_DETAILS_SECTION + ' ' + CLASS_CARD_DETAILS_SECTION + '--comments';

    return (
        <SettingsSection
            className={className}
            isOpened={true}
            title={CARD_SECTION_COMMENTS}
            sectionType={ESettingsSectionType.POPUP}
            onAddClosed={ onClose }
        >
            <CommentInputHOC
                boardId={boardId}
                cardId={cardId}
                sectionType={ESettingsSectionType.POPUP}
                onClose={ onClose }
            />
        </SettingsSection>
    );
}
