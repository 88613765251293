import {IGetState, TBoardId} from '../../../../../../../../../types/types';
import {Dispatch, ThunkAction} from '../../../../../../../../../types/actions';
import {setVersion} from '../../../../../../../../../rest/effects/board/setVersion';
import {EBoardVersion} from '../../../../../../../../../types/rest/IRestDashboard';
import {PWC_OPEN_FILE_MIME_FILTER} from '../../../../../../../dialogs/openBoards/FooterImport/hocs/FooterImportHOC/constants';
import {onDriveDocSelect} from '../effects/onDriveDocSelect';

export const onTemplatePwcImportSelect = (
    boardId: TBoardId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(setVersion(EBoardVersion.V4, boardId)).then(() => {

            App.Helpers.googleDriveOpenDialogue.showByMimeTypes(
                false,
                PWC_OPEN_FILE_MIME_FILTER,
                (docs: any) => {
                    console.log(docs[0]);
                    dispatch(onDriveDocSelect(docs[0].id, boardId))
                }
            );
        });
    };
    return action;
};
