import { SegmentListEvent } from '../../../../middlewares/segment';
import { ESortField } from '../../../../store/model/list/types';
import {getMessages} from '../../../../store/constants';

export const LD_HEADER_TITLE = 'List details';
export const LD_SWITCH_LIMIT_CARDS_NUMBER_TITLE = 'Limit cards number';
export const LD_ESTIMATE_TITLE = 'Total estimate';
export const LD_INPUT_ADD_DESCRIPTION_BUTTON_TEXT = 'Add description';
export const LD_CARDS_SORTING_TITLE = 'Cards sorting';
export const LD_DEFAULT_LIST_NAME = 'Untitled list';
export const LD_TEMPLATES_LIST_NAME = 'Templates list';
export const LD_SAVE_DESCRIPTION_SAVE_BUTTON_TEXT = 'Save';
export const LD_SAVE_DESCRIPTION_CANCEL_BUTTON_TEXT = 'Cancel';
export const LD_ESTIMATE_ZERO = '0 hours';

export const SNACKBAR_ID_LIST_DELETE_AUTOMATION =  'SNACKBAR_ID_LIST_DELETE_AUTOMATION';
export const SNACKBAR_ID_LIST_MOVE_AUTOMATION =  'SNACKBAR_ID_LIST_MOVE_AUTOMATION';
export const ERROR_LIST_AUTOMATION_SETTINGS = `<a id="${SNACKBAR_ID_LIST_DELETE_AUTOMATION + '-link'}" >${getMessages().getText('error.list.automation.settings')}</a>`;
export const ERROR_LIST_DELETE_AUTOMATION = getMessages().getText('error.list.delete.automation', null, ERROR_LIST_AUTOMATION_SETTINGS);
export const ERROR_LIST_ARCHIVE_AUTOMATION = getMessages().getText('error.list.archive.automation', null, ERROR_LIST_AUTOMATION_SETTINGS);
export const ERROR_LIST_MOVE_AUTOMATION = getMessages().getText('error.list.move.automation', null, ERROR_LIST_AUTOMATION_SETTINGS);

export const LD_CLASSNAME = 'list-details';
export const LD_SAVE_DESCRIPTION_BUTTON_CLASSNAME = 'list-details__save-description-button';

export const LD_SECTION = 'list-details-section';

export const LD_CARDS_LIMIT_DEFAULT = 1;

export const mapSortFieldToSegmentEvent = {
    [ESortField.sortByPriority]: SegmentListEvent.PRIORITY_SORTING_ON,
    [ESortField.sortByStartDate]: SegmentListEvent.START_DATE_SORTING_ON,
    [ESortField.sortByDueDate]: SegmentListEvent.DUE_DATE_SORTING_ON,
    [ESortField.sortByProgress]: SegmentListEvent.PROGRESS_SORTING_ON,
    [ESortField.sortByAssignedUser]: SegmentListEvent.ASSIGNED_USER_SORTING_ON,
    [ESortField.sortByEstimate]: SegmentListEvent.ESTIMATE_SORTING_ON,
    [ESortField.sortByTitle]: SegmentListEvent.TITLE_SORTING_ON,
    [ESortField.sortByPrefix]: SegmentListEvent.PREFIX_SORTING_ON,
    [ESortField.sortByCardAge]: SegmentListEvent.AGE_SORTING_ON,
    [ESortField.sortByColorLabel]: SegmentListEvent.COLOR_LABEL_SORTING_ON,
};

enum EListTooltips {
    priority = 'Cards sorting by priority',
    startDate = 'Cards sorting by start date',
    dueDate = 'Cards sorting by due date',
    progress = 'Cards sorting by progress',
    assignedTo = 'Cards sorting by assigned user',
    estimate = 'Cards sorting by estimate',
    title = 'Cards sorting by title',
    prefix = 'Cards sorting by prefix',
    age = 'Cards sorting by card’s age',
    colorLabel = 'Cards sorting by card’s color labels'
};

export const mapSortFieldToTooltip = {
    [ESortField.sortByPriority]: EListTooltips.priority,
    [ESortField.sortByStartDate]: EListTooltips.startDate,
    [ESortField.sortByDueDate]: EListTooltips.dueDate,
    [ESortField.sortByProgress]: EListTooltips.progress,
    [ESortField.sortByAssignedUser]: EListTooltips.assignedTo,
    [ESortField.sortByEstimate]: EListTooltips.estimate,
    [ESortField.sortByTitle]: EListTooltips.title,
    [ESortField.sortByPrefix]: EListTooltips.prefix,
    [ESortField.sortByCardAge]: EListTooltips.age,
    [ESortField.sortByColorLabel]: EListTooltips.colorLabel,
};

enum ECardComparators {
    priority = 'priority',
    startDate = 'startDate',
    dueDate = 'dueDate',
    progress = 'overallProgress',
    assignedTo ='assignedTo',
    estimate = 'estimate'
}

export const mapCardSortingToCardComparators = {
    [ESortField.sortByProgress]: ECardComparators.progress,
    [ESortField.sortByPriority]: ECardComparators.priority,
    [ESortField.sortByAssignedUser]: ECardComparators.assignedTo,
    [ESortField.sortByEstimate]: ECardComparators.estimate,
    [ESortField.sortByStartDate]: ECardComparators.startDate,
    [ESortField.sortByDueDate]: ECardComparators.dueDate,
};

export enum EOrderOptions {
    descending = 'Descending',
    ascending = 'Ascending'
}

export enum EListDetailsStatus {
    saving = 'saving',
    saved = 'saved'
}
