import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState, TBoardId } from '../../../../../../../types/types';
import { IColor, TStatus } from '../../../../../../../types/model';
import { boardColorAdd } from '../../../../../../../rest/effects/color/boardColorAdd';
import {
    getAuthUserAllowColorTagCount
} from '../../../../../../../store/model/authUser/selectors/getAuthUserAllowColorTagCount';
import { getBoardColorsByStatus } from '../../../../../../../store/model/board/selectors/getBoardColorsByStatus';
import { showSnoozeBlocker } from '../../../../../base/effects/showSnoozeBlocker';

export const onSave = (
    boardId: TBoardId,
    colorTag: IColor
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const count = getBoardColorsByStatus(state, boardId, TStatus.STATUS_ACTIVE).length;
        const maxCount = getAuthUserAllowColorTagCount(state);
        if (count >= maxCount) {
            return dispatch(showSnoozeBlocker({ allow: 'allowColorTagCount' }));
        } else {
            return dispatch(boardColorAdd(boardId, colorTag));
        }
    };
    return action;
};
