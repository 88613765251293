import * as React from 'react';
import { IAsidePanelActionsButtonProps } from './types';
import './_AsidePanelActionsButton.scss';
import { Button, ButtonDropdown, ButtonTitle, Icon} from 'kui';
import {
    CLASS_ASIDE_PANEL_ACTIONS_BUTTON,
    CLASS_ASIDE_PANEL_ACTIONS_BUTTON_CONTAINER,
    CLASS_ASIDE_PANEL_ACTIONS_POPUP,
    ID_ASIDE_PANEL_ACTIONS_PORTAL,
    ID_ASIDE_PANEL_ACTIONS_PORTAL_MOBILE
} from './constants';
import { CLASS_ASIDE_PANEL_ACTIONS_SCROLL } from '../AsidePanelActions/constants';
import { ASIDE_PANEL_ACTIONS_POPUP_MIN_HEIGHT } from '../../constants';
import { getParentsOffsetTop } from '../../../../helpers/getParentsOffsetTop';
import { CLASS_ASIDE_PANEL_ACTIONS_SECTION_TITLE } from '../AsidePanelActionsSection/constants';
import { AsidePanelContext } from '../AsidePanel/constants';
import { historyPush } from '../../../../base/helpers/historyHelper';
import { root } from 'app/store/constants';
import { EAsidePanelProperty } from '../AsidePanel/types';
import { scrollPanelToSection } from '../../helpers/scrollPanelToSection';
import { getAsidePanelActionsButtonTooltip } from '../../helpers/getAsidePanelActionsButtonTooltip';
import { ProFeatureIndicator } from '../../../../base/components/ProFeatureIndicator/ProFeatureIndicator';
import { NewFeatureIndicator } from '../../../../base/components/NewFeatureIndicator/NewFeatureIndicator';
import { BasicFeatureIndicator } from '../../../../base/components/BasicFeatureIndicator/BasicFeatureIndicator';
import { AnalyzeRender } from '../../../../helpers/memoizeHelper';

//@ts-ignore
const analyzeRender = window.Settings.development ? new AnalyzeRender(`AsidePanelActionsButton`) : null;

export function AsidePanelActionsButton ({
    children,
    className,
    dropdownClassName,
    indicatorNumber,
    indicatorBoolean,
    icon,
    isBasicFeature,
    isDisabled,
    isFitWindow,
    isIconHiddenOnFullSize,
    isProFeature,
    isOpened: isOpenedProp,
    isScrollUnlocked,
    isShow,
    notBlurClasses,
    openedProperty,
    sectionSelector,
    sectionElementSelector,
    title,
    isNewFeature = false,
    tooltipDirection,
    tooltipText,
    onClick,
    onClose: onCloseProp,
    onDidMount,
    onOpen: onOpenProp,
    dropdownKey,
    ...attributes
}: IAsidePanelActionsButtonProps) {
    if (analyzeRender && title) analyzeRender.call(`AsidePanelActionsButton ${title}`);
    if (isShow === false) return null;

    let classNameButton = CLASS_ASIDE_PANEL_ACTIONS_BUTTON_CONTAINER;
    if (className) classNameButton += ' ' + className;
    const classNameButtonActive = indicatorBoolean || indicatorNumber ? CLASS_ASIDE_PANEL_ACTIONS_BUTTON + '--active' : '';
    const classNameButtonDisabled = isDisabled ? CLASS_ASIDE_PANEL_ACTIONS_BUTTON + '--disabled' : '';
    const classNameButtonNoIcon = icon ? '' : CLASS_ASIDE_PANEL_ACTIONS_BUTTON + '--no-icon';
    const classNameButtonHideIconOnFullSize = isIconHiddenOnFullSize ? CLASS_ASIDE_PANEL_ACTIONS_BUTTON + '--hide-icon-on-full-size' : '';
    const { isMobile, openedProperty: openedPropertyContext, setIsScrollDisabled, setOpenedProperty } = React.useContext(AsidePanelContext);

    const [openedPropertyHook, setOpenedPropertyHook] = React.useState(openedPropertyContext);
    const [s] = React.useState<any>({});
    s.openedPropertyHook = openedPropertyHook; // геттер для openedPropertyContext вобщем сделал, а то не мог получить актуальный из isPopupOpened

    let [isOpened, _setIsOpened] = React.useState(!!isOpenedProp || null as boolean);
    const [isDropdownMount, _setIsDropdownMount] = React.useState(isOpenedProp);
    const buttonRef = React.useRef(null);
    const buttonsScrollRef = React.useRef(null);
    const activeSectionTimer = React.useRef(null);
    const scrollToSectionTimer = React.useRef(null);
    const mountTimer = React.useRef(null);
    const scrollDisabledTimer = React.useRef(null);
    const requestAnimation = React.useRef(null);

    let indicator: JSX.Element = null;
    const CLASS_INDICATOR = 'aside-panel__actions-button-indicator';
    let classNameIndicator = CLASS_INDICATOR;
    if (indicatorNumber !== undefined || indicatorBoolean !== undefined) {
        if (indicatorNumber === undefined) {
            classNameIndicator += ' ' + CLASS_INDICATOR + '--flag';
            if (!indicatorBoolean) classNameIndicator += ' ' + CLASS_INDICATOR + '--empty';
            indicator = <Icon
                className={'aside-panel__actions-button-flag'}
                xlink={'done'}
                size={16}
            />;
        } else {
            classNameIndicator += ' ' + CLASS_INDICATOR + '--counter';
            if (!indicatorNumber) classNameIndicator += ' ' + CLASS_INDICATOR + '--empty';
            indicator = <div
                className={'aside-panel__actions-button-counter'}
            >
                {indicatorNumber}
            </div>;
        }
    }

    function setIsOpened (isOpenedNew: boolean) {
        isOpened = isOpenedNew;
        _setIsOpened(isOpenedNew);
        setIsDropdownMount(isOpenedNew);
    }

    function setIsDropdownMount (isDropdownMount: boolean) {
        if (mountTimer.current) clearTimeout(mountTimer.current);
        if (isDropdownMount) {
            _setIsDropdownMount(true);
        } else {
            mountTimer.current = setTimeout(() => { // отмаунтить дропдаун через таймер, чтобы сохранить инпуты, которые срабатывают на блюр
                if (!isOpened) _setIsDropdownMount(false);
            }, 300);
        }
    }

    function initRefs () {
        buttonsScrollRef.current = document.querySelector('.' + CLASS_ASIDE_PANEL_ACTIONS_SCROLL) as HTMLElement;
    }

    function isPopupOpened (): boolean { // есть ли открытый попап
        return !!s.openedPropertyHook;
    }

    function isScrollDisabledSet (isScrollDisabled: boolean) {
        if (scrollDisabledTimer.current) clearTimeout(scrollDisabledTimer.current);
        if (isScrollDisabled && !isScrollUnlocked) {
            setIsScrollDisabled(true); // отключаем совместный скрол панелек
        } else {
            scrollDisabledTimer.current = setTimeout(() => { // подождать смену openedPropertyHook
                const isPopup = isPopupOpened();
                if (!isPopup) setIsScrollDisabled(false); // включаем совместный скрол панелек
            }, 300);
        }
    }

    function openedPropertySet (openedPropertyNew: EAsidePanelProperty) {
        if (openedPropertyNew) {
            setOpenedProperty(openedPropertyNew);
        } else {
            const popup = s.openedPropertyHook;
            if (popup === openedProperty) setOpenedProperty(null);
        }
    }

    /**
     * попап открывается всегда вниз, поэтому если от кнопки до низа экрана меньше, чем MIN_HEIGHT,
     * проскролим панель кнопок, чтобы кнопка стала сверху экрана
     */

    function scrollTop (): Promise<any> {
        if (isMobile) {
            return Promise.resolve();
        }

        return new Promise((res, rej) => {
            initRefs();
            isScrollDisabledSet(true); // отключаем скролл, чтобы при изменении высоты asidePanelBody при добавлении-удалении элементов не скакал попап

            const button = buttonRef.current.getBoundingClientRect();
            // @ts-ignore
            const bottom = window._innerHeight - button.bottom;

            if (bottom < ASIDE_PANEL_ACTIONS_POPUP_MIN_HEIGHT) {
                const actionsSectionTitle = buttonsScrollRef.current.querySelector('.' + CLASS_ASIDE_PANEL_ACTIONS_SECTION_TITLE); // sticky title сверху
                const actionsSectionTitleHeight = actionsSectionTitle ? actionsSectionTitle.offsetHeight : 0;

                const offsetTop = getParentsOffsetTop(
                    buttonRef.current,
                    CLASS_ASIDE_PANEL_ACTIONS_SCROLL
                );

                buttonsScrollRef.current.scrollTop = offsetTop - actionsSectionTitleHeight; // кнопки скролим, чтобы кнопка стала сверху
            }

            requestAnimation.current = requestAnimationFrame(res);
        });
    }

    function scrollToSection () {
        if (isPopupOpened()) return; // уже открыли другой попап

        scrollPanelToSection(
            sectionSelector,
            sectionElementSelector,
            activeSectionTimer,
            () => {
                isScrollDisabledSet(false);
            }
        )
    }

    function scrollToSectionWithTimer () {
        if (scrollToSectionTimer.current) clearTimeout(scrollToSectionTimer.current);
        scrollToSectionTimer.current = setTimeout(scrollToSection, 500); // надо подождать, т.к. в onClick происходит autosize инпута name
    }

    function onOpen () {
        if (onOpenProp) onOpenProp();
        setIsOpened(true);
        if (isMobile) {
            historyPush({
                state: { page: 'popup' },
                onpopstate: onClose
            });
        }
        if (openedProperty) {
            openedPropertySet(openedProperty);
        }
    }

    function onClose () {
        if (onCloseProp) onCloseProp();
        openedPropertySet(null);
        setIsOpened(false);
        if (!children || indicatorBoolean || indicatorNumber) {
            scrollToSectionWithTimer();
        } else {
            isScrollDisabledSet(false);
        }
    }

    function onClickHandler () {
        if (onClick) onClick();
        if (
            sectionSelector &&
            !children
        ) {
            scrollToSectionWithTimer();
        }
    }

    const button = (
        <Button
            className={`
                ${CLASS_ASIDE_PANEL_ACTIONS_BUTTON}
                ${classNameButtonActive}
                ${classNameButtonDisabled}
                ${classNameButtonNoIcon}
                ${classNameButtonHideIconOnFullSize}
                ${isNewFeature || isBasicFeature || isProFeature ? CLASS_ASIDE_PANEL_ACTIONS_BUTTON + '--indicator' : ''}
            `}
            variant={'secondary'}
            onClick={onClickHandler}
            tooltip={tooltipText && getAsidePanelActionsButtonTooltip({
                value: tooltipText,
                direction: tooltipDirection ? tooltipDirection : 'left'
            })}
            {...attributes}
        >
            {icon &&
                <Icon
                    className={'aside-panel__actions-button-icon'}
                    xlink={icon}
                    size={24}
                />
            }
            <div className={`aside-panel__actions-button-content ${isBasicFeature || isProFeature ? 'aside-panel__actions-button-content--feature-indicator' : ''}`}>
                <ButtonTitle
                    className={'aside-panel__actions-button-title'}
                >
                    {title}
                </ButtonTitle>
                {isNewFeature && <NewFeatureIndicator />}
                {isProFeature && <ProFeatureIndicator />}
                {isBasicFeature && <BasicFeatureIndicator />}
            </div>
            {indicator && !isNewFeature && !isProFeature && !isBasicFeature &&
                <div
                    className={classNameIndicator}
                >
                    {indicator}
                </div>
            }
        </Button>
    );

    React.useEffect(() => {
        setOpenedPropertyHook(openedPropertyContext);
        if (
            isOpened &&
            openedPropertyContext &&
            openedProperty &&
            openedPropertyContext !== openedProperty // другой попап открылся
        ) {
            onClose();
        }
    }, [openedPropertyContext]);

    React.useEffect(() => {
        function onEsc (e: any) {
            if (e.which === 27) {
                const activeElement = document.activeElement as HTMLElement;
                if (activeElement) activeElement.blur(); // чтобы сработали сохранения, которые слушают блюр инпута
                onClose(); // esc
            }
        }
        root.removeEventListener('keydown', onEsc);
        if (isOpened) { // если дропдаун открыт
            root.addEventListener('keydown', onEsc); // то подписываемся на Esc
        }

        return () => {
            root.removeEventListener('keydown', onEsc);
        }
    }, [isOpened]);

    React.useEffect(() => {
        if (isOpenedProp || isOpened !== null) {
            if (isOpenedProp) {
                onOpen();
            } else {
                onClose();
            }
        }
    }, [isOpenedProp]);

    /**
     * при создании дропдауна portalSelector должен быть в доме,
     * поэтому сначала дропдаун вставляется в дефолтный портал в body
     * после didMount перерисуем кнопку с дропдауном в нужный портал
     */

    React.useEffect(() => {
        initRefs();

        requestAnimation.current = requestAnimationFrame(() => {
            const elem = document.querySelector('body > #' + ID_ASIDE_PANEL_ACTIONS_PORTAL) || document.querySelector('body > #' + ID_ASIDE_PANEL_ACTIONS_PORTAL_MOBILE);
            if (elem) { // такого уже не должно быть, контейнер портала создаётся сразу в asidePanel
                root.App.Util.devDebug('portal in body');
                elem.remove();
            }
        });

        return () => {
            if (activeSectionTimer.current) clearTimeout(activeSectionTimer.current);
            if (scrollToSectionTimer.current) clearTimeout(scrollToSectionTimer.current);
            if (mountTimer.current) clearTimeout(mountTimer.current);
            if (requestAnimation.current) cancelAnimationFrame(requestAnimation.current);
            if (scrollDisabledTimer.current) clearTimeout(scrollDisabledTimer.current);
        }
    }, []);

    React.useEffect(() => {
        if (onDidMount) onDidMount();
    }, []);

    return (
        <div
            className={`
                ${classNameButton}
                ${isBasicFeature ? CLASS_ASIDE_PANEL_ACTIONS_BUTTON_CONTAINER + '--basic' : ''}
            `}
            ref={buttonRef}
        >
            {!!children
                ? (
                    <ButtonDropdown
                        directionVertical={'down'}
                        dropdownClassName={`
                            ${CLASS_ASIDE_PANEL_ACTIONS_POPUP}
                            ${dropdownClassName || ''}
                        `}
                        isFitWindow={isFitWindow}
                        key={dropdownKey}
                        notBlurClasses={notBlurClasses}
                        opened={isOpened}
                        portal
                        portalId={isMobile ? ID_ASIDE_PANEL_ACTIONS_PORTAL_MOBILE : ID_ASIDE_PANEL_ACTIONS_PORTAL}
                        beforeOpen={scrollTop}
                        onClick={onClickHandler}
                        onOpen={onOpen}
                        onClose={onClose}
                        {...attributes}
                    >
                        {button}
                        {isDropdownMount &&
                            children
                        }
                    </ButtonDropdown>
                )
                : button // action button, no dropdown
            }
        </div>
    );
}
