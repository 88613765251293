import * as React from 'react';
import {HELP_PANEL_LINKS_RELEASE} from '../../constants';
import {IHelpPanelReleaseNotesProps} from './types';

export const HelpPanelReleaseNotes = ({
    onClick
}: IHelpPanelReleaseNotesProps) => {
    const className = 'help-panel__link';
    const classWithNoIcon = className + '--with-no-icon';

    const clickHandler = (
        e: React.MouseEvent
    ) => {
        e.preventDefault();
        onClick();
    }

    return (
        <span className={className}>
            <a onClick={clickHandler} className={classWithNoIcon}>
               {HELP_PANEL_LINKS_RELEASE}
            </a>
        </span>
    );
};
