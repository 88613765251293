import { Dispatch, ThunkAction } from 'app/types/actions';
import { IGetState } from 'app/types/types';
import { snackbarUpgradeDefault } from 'app/view/react_components/snackbarsStack';

export const onClickDisallow = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(snackbarUpgradeDefault());
    };
    return action;
};
