import { getMessages } from '../../../../../../store/constants';

export const DRIVE_ID_MY_DRIVE = 'DRIVE_ID_MY_DRIVE';
export const DRIVE_ID_SHARED_WITH_ME = 'DRIVE_ID_SHARED_WITH_ME';
export const DRIVE_ID_RECENT = 'DRIVE_ID_RECENT';

export const getDrivesInitial = () => {
    return [
        {
            icon: 'board',
            id: DRIVE_ID_MY_DRIVE,
            name: getMessages().getText('open_boards.drive.my_drive'),
            allowedAction: {
                allowCreateBoard: true,
                allowMoveToThisDrive: true
            }
        },
        {
            icon: 'share-link',
            id: DRIVE_ID_SHARED_WITH_ME,
            name: getMessages().getText('open_boards.drive.shared_with_me'),
            allowedAction: {
                allowCreateBoard: false,
                allowMoveToThisDrive: false
            }
        },
        {
            icon: 'board',
            id: DRIVE_ID_RECENT,
            name: getMessages().getText('open_boards.drive.recent_boards'),
            allowedAction: {
                allowCreateBoard: true,
                allowMoveToThisDrive: false
            }
        }
    ];
};

export const ICON_SHARED_DRIVE = 'share-specific';
