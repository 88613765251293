import { root } from 'app/store/constants';
import { firstBoardLoadAndSave } from 'app/rest/effects/firstBoard/firstBoardLoadAndSave';

export const firstBoardPrepareAllFirstBoards = (
) => {
    firstBoardLoadAndSave(root.Settings.firstBoardIdMicrosoft)
        .then(console.log);
    firstBoardLoadAndSave(root.Settings.firstBoardPwcId)
        .then(console.log);
    firstBoardLoadAndSave(root.Settings.firstBoardId)
        .then(console.log);
    firstBoardLoadAndSave(root.Settings.tipsAndTricksBoardIdMicrosoft)
        .then(console.log);
    firstBoardLoadAndSave(root.Settings.tipsAndTricksBoardId)
        .then(console.log);
};
