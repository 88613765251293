import store, { dispatch, getAppState } from './../../store/configureStore';
import { SignInView } from './sign_in_view';
import SnackbarsStackView from './snackbars_stack_view';
import OpenBoardsDialogView from './openboards_dialog_view';
import {
    ASIDE_MIN_WIDTH,
    CLASS_ASIDE_PANEL,
    CLASS_HTML_SCROLL,
    CLASS_HTML_SCROLL_DISABLED,
    CLASS_LEFT_ASIDE_PANEL,
    CLASS_MAIN_PANEL,
    CLASS_MAIN_PANEL_SCROLL,
    CONFIG_URL,
    EViewTypes,
    LANDING_GANTTCHART_URL,
    LANDING_KANBAN_URL,
    LANDING_N2_URL,
    LANDING_TASKMANAGER_URL,
} from "../../const";
import NavigationPanelView from './navigation_panel_view';
import UpgradePlanButton from './upgrade_button_view'
import Blanker from './blancker_view';
import RegistrationDialogView from './registration_dialog_view';
import HelpPopupView from './help_popup_view';
import { CookiesDialogView } from './cookieDialogView';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { LoaderBlock } from 'kui';
import { FP_FILTER_INFO_PANEL_CLASS } from "../react_components/aside_panel/filterInfoPanel/components/constants";
import {
    ENavigationPanelTypes,
    NAVIGATION_PANEL_CLASS,
    NAVIGATION_PANEL_CONTAINER_CLASS,
} from "../react_components/main/navigationPanel/constants";
import { TOP_BAR_CLASS } from "../react_components/main/topBar/constants";
import AsidePanelRenderer from '../../view/aside_panel/aside_panel_renderer';
import { fetchHandler } from "../../util/fetchHandler";
import {
    SegmentCardEvent, SegmentCardOptions,
    SegmentFeedbackEvent,
    segmentTrackAction,
    SegmentUserEvent
} from "../../middlewares/segment";
import { EHintKey, hintsLoad } from '../react_components/hints';
import { signInIsShowSet, signInReset } from '../react_components/dialogs/signIn';
import { isUpgradeButtonShownSelector } from '../react_components/main/upgradeButton/hocs/selectors/isUpgradeButtonShownSelector';
import {
    SegmentFeedbackOption,
    SegmentFeedbackTargetValue
} from "../../middlewares/segment/trackEntities/feedbackEvents";
import { CARD_CLASS, LIST_CLASS_HEADER, LISTS_ASIDE_PLACEHOLDER_CLASS } from "../react_components/main/kanbanView";
import { LeftAsidePanelRenderer } from '../aside_panel/left_aside_panel_renderer';
import { RIGHT_ASIDE_PANEL_SELECTOR } from '../react_components/aside_panel/asidePanel/constants';
import { ID_ASIDE_PANEL_PORTAL } from '../react_components/aside_panel/asidePanel/components/AsidePanelActionsButton/constants';
import { DashboardWidgets } from '../react_components/reactToBackbone/DashboardWidgets';
import { TopBar } from '../react_components/reactToBackbone/TopBar';
import {
    getIsAssignedToMeActive,
    getIsAssignedToMeCardActive
} from '../../store/router/selectors/getIsAssignedToMeActive';
import { BOARDS_LAST_DAYS, CLASS_MY_WORK_CARD } from '../react_components/main/myWorkView/constants';
import { CLASS_ASIDE_PANEL_ACTIONS_SCROLL } from '../react_components/aside_panel/asidePanel/components/AsidePanelActions/constants';
import { SkipToMain } from '../react_components/reactToBackbone/SkipToMain/SkipToMain';
import { insertAnalytics } from "../../util/segment-insert";
import { asidePanelWidthSet } from '../react_components/aside_panel/asidePanel/store';
import { ERealtimeClassKeys } from '../react_components/base/helpers/realTimeHelperTS';
import { cardAssigneesActionSetAction } from '../../store/model/actions/cardAssigneesActionSetAction';
import { cardAssigneeUpdateAction } from '../../store/model/cardAssignees/actions/cardAssigneeUpdateAction';
import { cardAssigneeAddAction } from '../../store/model/cardAssignees/actions/cardAssigneeAddAction';
import { getUserAccountType } from '../../store/model/selectors/getUserAccountType';
import { EAuthUserAccountType } from '../../types/rest/IRestAuthUser';
import { AccessibilityStatementView } from '../react_components/reactToBackbone/AccessibilityStatement/AccessibilityStatementView';
import { OfflineBlocker } from '../react_components/reactToBackbone/OfflineBlocker/OfflineBlocker';
import { storeListener } from '../../store/helpers/storeListener';
import { getBoard } from '../../store/model/selectors/getBoardById';
import { getBoardPermissionsAllowEdit } from '../../store/model/selectors/getBoardPermissionsAllowEdit';
import { getActiveBoardId } from '../../store/model/selectors/getActiveBoardId';
import ThemeHelper from './../../helper/theme_helper';
import { isMwsUser } from "../../store/model/authUser/selectors/isMwsUser";
import { getAuthUser } from '../../store/model/authUser/selectors/getAuthUser';
import { getNumberKeys } from '../../helper/getNumberKeys';
import { boardFetchPermissions } from '../../rest/effects/board/boardFetchPermissions';
import { updateUser } from '../../store/model/authUser/effects/updateUser';
import { SegmentPWAEvent } from "../../middlewares/segment/trackEntities/pwaEvents";
import { QualificationDialogReactToBackbone } from '../react_components/reactToBackbone/QualificationDialogReactToBackbone';
import { isUserAuthorized } from '../../store/model/authUser/selectors/isUserAuthorized';
import { WebinarDialogReactToBackbone } from '../react_components/reactToBackbone/WebinarDialogReactToBackbone';
import { userBoardActivityTimesActionSetAction } from '../../store/model/actions/userBoardActivityTimesActionSetAction';
import { userBoardActivityTimesSetAction } from '../../store/model/boardUserActivityTimes/actions/userBoardActivityTimesSetAction';
import { getMyWorkShowBoards } from '../react_components/main/myWorkView/store/selectors/getMyWorkShowBoards';
import { myWorkShowBoardsSet } from '../react_components/main/myWorkView/store/actions/myWorkShowBoardsSet';
import { getAsidePanelEntityPanelWidth } from "../react_components/aside_panel/asidePanel/store/selectors/getAsidePanelEntityPanelWidth";
import { isPwc } from "../../store/model/authUser/selectors/isPwc";
import { Util } from "../../util/util";
import { RoomFactory } from "../../helper/realtime/ts/RoomFactory";
import { root } from "../../store/constants";
import { debounce, object, throttle } from 'underscore';
import { HintOpenedLastBoardReactToBackbone } from '../react_components/reactToBackbone/HintOpenedLastBoardReactToBackbone/HintOpenedLastBoardReactToBackbone';
import { getIsLinkCardActive } from "../../store/router/selectors/getIsLinkCardActive";
import { getAsidePanelWidth } from '../react_components/aside_panel/asidePanel/store/selectors/getAsidePanelWidth';
import { getMyWorkUnselectedBoards } from "../react_components/main/myWorkView/selectors/getMyWorkUnselectedBoards";
import { getMyWorkSelectedBoards } from "../react_components/main/myWorkView/selectors/getMyWorkSelectedBoards";
import { getCurrentNavigationPanelType } from '../react_components/main/navigationPanel/helpers/getCurrentNavigationPanelType';
import { FreeTrialForm } from 'app/view/react_components/reactToBackbone/FreeTrialForm';
import { authUserSetLandingPage } from 'app/rest/effects/authUser/authUserSetLandingPage';
import { getUserPaidSubscriptionFirst } from 'app/store/model/authUser/selectors/getUserPaidSubscriptionFirst';
import { setHintCanShow } from '../react_components/hints/effects/setHintCanShow';
import { snackbarRefreshDefault } from '../react_components/snackbarsStack/effects/variantRefresh/snackbarRefreshDefault';
import { getAppVersion } from 'app/middlewares/segment/selectors/getAppVersion';
import { CLASS_GANTT_CARD } from "../react_components/gantt/listCard/components/constants";
import { CLASS_LIST_VIEW_CARD } from "../react_components/listView/containers/Card/constants";
import { getMyWorkFilterBarCollapsed } from "../react_components/main/myWorkView/selectors/getMyWorkFilterBarCollapsed";
import { CLASS_ASIDE_PANEL_BODY } from '../react_components/aside_panel/asidePanel/components/AsidePanelBody/constants';
import { Provider } from "react-redux";
import { SharingDialog } from '../react_components/reactToBackbone/SharingDialog';
import { PermissionsFAQ } from "app/view/react_components/reactToBackbone/PermissionsFAQ";
import { CommonAuthManager } from "app/helper/authorisation/common/CommonAuthManager";
import { MicrosoftGroupPermissionView } from '../react_components/reactToBackbone/MicrosoftGroupPermissionView';
import {
    BrowserNotificationsRequestReactToBackbone
} from '../react_components/reactToBackbone/BrowserNotificationsRequestReactToBackbone';
import { FeatureRatePoll } from '../react_components/reactToBackbone/Polls/FeatureRatePoll';
import { BlockerModal } from '../react_components/blockerModal/BlockerModal';
import { KanbanchiRatePoll } from "../react_components/reactToBackbone/Polls/KanbanchiRatePoll";
import { MessengerPoll } from "../react_components/reactToBackbone/Polls/MessengerPoll";
import { loadUser } from "app/rest/effects/authUser/loadUser";
import { EGoogleFileThumbnailMode } from "app/helper/authorisation/google/storage/googleThumbnail";
import { IAuthUser } from "app/store/model/authUser/types";
import { SegmentCardDetailsCloseSourceValue } from "app/middlewares/segment/trackEntities/cardEvents";
import { getActiveCardId } from "app/store/router/selectors/getActiveCardId";
import { Intercom } from "../../helper/intercom";
import {
    BoardProgressLoaderHOC
} from "../react_components/boardProgressLoader/hocs/BoardProgressLoaderHOC/BoardProgressLoaderHOC";
import { PricingPoll } from "../react_components/reactToBackbone/Polls/PricingPoll";
import { MagicLinkReactToBackbone } from "../react_components/reactToBackbone/MagicLinkReactToBackbone";
import { resetSelectedCards } from "../react_components/clickManager/effects/resetSelectedCards";
import { getIsMultiselect } from "../react_components/clickManager/selectors/getIsMultiselect";
import { CLASS_COLOR_PALETTE } from "../react_components/base/components/ColorPalette/constants";
import { PromoCodePopup } from "../react_components/reactToBackbone/PromoCodePopup";
import { getUserOwnSubscriptions } from "../../store/model/authUser/selectors/getUserOwnSubscriptions";

var App = window.App;
var Backbone = window.Backbone;
var $ = window.$;
var Messages = window.Messages;

App.Views.MainView = Backbone.View.extend({
    el: 'body',

    events: {
        'click': 'handleClick',
        'keydown': 'onKeyDown',
        'click .bottombar__sc-icon': 'socialIconClicked',
        'mouseup': 'onMouseUp'
    },

    initialize: function (options) {
        App.vent.on(App.vent['aside-panel:closed'], this.onAsidePanelClosed, this);
        App.vent.on(App.vent['aside-panel:opened'], this.onAsidePanelOpened, this);
        App.vent.on(App.vent['dashboards:loading'], this.showLoader, this);
        App.vent.on(App.vent['dashboards:loaded'], this.hideLoaderWithTimeout, this);
        App.vent.on(App.vent['gapi:authorized'], debounce(this.onUserLogin, 500), this);
        App.vent.on(App.vent['gapi:authorized'], this.startControler, this);
        App.vent.on(App.vent['gapi:authorized'], debounce(this.startUserRealtime, 2000), this);
        App.vent.on(App.vent['gapi:authorized'], debounce(this.startSystemRealtime, 2000), this);
        App.vent.on(App.vent['gapi:authorized'], debounce(this.trackEvent, 2000), this);
        App.vent.on(App.vent['gapi:authorized'], debounce(this.updateConfig, 2000), this);

        App.vent.on(App.vent['secondPanel:show'], this.showSecondPanel, this);
        App.vent.on(App.vent['secondPanel:close'], this.closeSecondPanel, this);
        App.vent.on(App.vent['view:resized'], this.setPanelsWidth, this);

        this.listenTo(App.vent, App.vent['feedBack:close'], this.removeHelpPopup);
        this.listenTo(App.vent, App.vent['registration:unmount'], this.removeRegistrationDialog);

        this.listenTo(App.vent, App.vent['user:switch_account'], this.onUserSwitchAccount);
        this.parent = options.parent;
        this.helpPopup = null;
        this.panelOpened = false;
        this.relatedOpened = false;
        this.userRealtime = false;
        this.systemRealtime = false;
        this.signIn = null;
        this.registrationDialog = null;
        this.topBar = null;
        this.upgradeButton = null;
        this.navigationPanel = null;
        this.render();
        this.themeHelper = new ThemeHelper({$el: this.$el});
        this.notifyHelper = new App.Helpers.NotifyHelper();
        this.skipToMain = null;
        this.accessibilityStatement = null;
        this.offlineBlocker = null;
        this.isUMUXPollEnabled = false;
        this.isOnboardingTutorialsEnabled = false;
        this.isMessengerPollEnabled = false;
        this.isAsidePanelMouseDown = false;
        this.freeTrialForm = null;
        this.permissionsFAQModal = null;
        this.kanbanchiRatePoll = null;
        this.featureRatePoll = null;
        this.messengerPoll = null;
        this.assignedToMeView = null;
        this.promoCodePopup = null;
        this.magicLinks = {};

        App.vent.on(App.vent['dashboard:activated'], this.onDashboardActivated, this);
        App.vent.on(App.vent['dashboard:deactivated'], this.onDashboardDeactivated, this);

        this.onStartTipsUpgrade();

        window.addEventListener('appinstalled', () => {
            // Hide the app-provided install promotion
            //hideInstallPromotion();
            // Clear the deferredPrompt so it can be garbage collected
            //deferredPrompt = null;
            // Optionally, send analytics event to indicate successful install
            //console.log('PWA was installed');
            dispatch(segmentTrackAction(SegmentPWAEvent.INSTALLED));
        });
    },


    updateConfig: function () {
        fetchHandler(CONFIG_URL).then(result => {
            this.toggleFreshDesk(result && result.onlineChatEnabled);
            this.toggleUMUX(result && result.isUMUXPollEnabled);
            this.toggleOnboardingTutorials(result && result.isOnboardingTutorialsEnabled);
            this.toggleMessengerPoll(result && result.isMessengerPollEnabled);
            this.toggleGoogleThumbnailMode(result && result.isGoogleThumbnailApiMode);
        });
    },

    toggleFreshDesk: function(onlineChatEnabled) {
        const state = store.getState();
        let enabled = onlineChatEnabled
            && (
                (isUpgradeButtonShownSelector(state) && getUserAccountType(state) === EAuthUserAccountType.DOMAIN_ACCOUNT_TYPE)
                ||isMwsUser(state)
            )
        if (enabled) {
            this.insertFreshdesk();
        } else {
            this.removeFreshdesk();
        }
        this.$el.toggleClass('page--chat', enabled);
    },

    toggleOnboardingTutorials: function (isEnabled) {
        this.isOnboardingTutorialsEnabled = isEnabled;
    },

    getOnboardingTutorialsEnabled: function () {
        return this.isOnboardingTutorialsEnabled;
    },

    toggleUMUX: function(isEnabled) {
        this.isUMUXPollEnabled = isEnabled;
    },

    toggleGoogleThumbnailMode: function (isEnabled) {
        window.Settings.googleThumbnailMode = isEnabled ? EGoogleFileThumbnailMode.CACHE : EGoogleFileThumbnailMode.REAL_TIME;
    },

    getUMUXEnabled: function() {
        return this.isUMUXPollEnabled;
    },

    toggleMessengerPoll: function(isEnabled) {
        this.isMessengerPollEnabled = isEnabled;
    },

    getMessengerPollEnabled: function() {
        return this.isMessengerPollEnabled;
    },

    insertFreshdesk: function () {
        if (window.fcWidget && window.fcWidget.show) {
            window.fcWidget.show();
        } else {
            const freshDesk = $('<script />', {src: '/js/lib/fresh-desk-insert.js'});
            this.$el.append(freshDesk);
        }
    },

    removeFreshdesk: function () {
        if (window && window.fcWidget && window.fcWidget.hide) {
            window.fcWidget.hide();
        }
    },

    insertPerformance: function () {
        App.model.set('insertPerformance', true);
        this.insertSegment();
        this.insertIntercom();
    },

    insertSegment: function () {
        insertAnalytics()
    },

    insertIntercom: function () {
        App.Helpers.Intercom.init();
    },

    //TODO WHAT THE METHOD NAME ???
    trackEvent: function () {
        debounce(function () {
            const authUser = getAuthUser(getAppState());
            App.statHelper.sendUserStat(object([
                [App.Classes.StatHelper.TIME_ZONE, authUser.timezone],
                [App.Classes.StatHelper.FIRST_NAME, authUser.firstName],
                [App.Classes.StatHelper.LAST_NAME, authUser.lastName],
                [App.Classes.StatHelper.FULL_NAME, authUser.fullName],
                [App.Classes.StatHelper.INITIALS, authUser.initials],
                [App.Classes.StatHelper.EMAIL, authUser.email],
                [App.Classes.StatHelper.DOMAIN, App.Util.userDomain(authUser.email)],
                [App.Classes.StatHelper.ACCEPT_LANGUAGE, authUser.acceptLanguge],
                [App.Classes.StatHelper.REGION, authUser.region],
                [App.Classes.StatHelper.COUNTRY, authUser.country],
                [App.Classes.StatHelper.CREATE_DATE, authUser.createDate],
                [App.Classes.StatHelper.CREATE_DATE_TIMESTAMP, authUser.createDateTimeStamp],
                [App.Classes.StatHelper.CITY, authUser.city],
                [App.Classes.StatHelper.PHOTO_URL, authUser.photoUrl],
                [App.Classes.StatHelper.ALLOW_TIME_TRACKER, authUser.allowTimeTracker],
                [App.Classes.StatHelper.ALLOW_BRANDING, authUser.allowBranding],
                [App.Classes.StatHelper.ALLOW_GANTT, authUser.allowGantt],
                [App.Classes.StatHelper.ALLOW_CHAT_SUPPORT, authUser.allowChatSupport],
                [App.Classes.StatHelper.ALLOW_CARD_FROM_EMAIL, authUser.allowCardFromEmail],
                [App.Classes.StatHelper.ALLOW_GOOGLE_SHEET_EXPORT, authUser.allowGoogleSheetExport],
                [App.Classes.StatHelper.ALLOW_PRIORITY_AUTO_SORTING, authUser.allowPriorityAutoSorting],
                [App.Classes.StatHelper.ALLOW_DASHBOARD_BACKGROUND, authUser.allowDashboardBackground],
                [App.Classes.StatHelper.ALLOW_BACKUP_XML, authUser.allowBackupXml],
                [App.Classes.StatHelper.ALLOW_SWIMLANES, authUser.allowSwimlanes],
                [App.Classes.StatHelper.LAST_LOGIN_DATE, App.Util.nowTrimTimestampGMT()],
                [App.Classes.StatHelper.LOGIN_COUNT, '+1']
            ]));
        }, 2000)();
    },

    onUserLogin: function() {
        this.setGAuser();
        this.checkUnavailabilityAndBlock();

        const activeSubscriptionSelector = (state) => {
            return getAuthUser(state).activeSubscription;
        };
        const activeSubscriptionListener = (allow) => {
            this.checkUnavailabilityAndBlock();
        };
        this.unsubscribeActiveSubscriptionListener = storeListener(
            store,
            activeSubscriptionSelector,
            activeSubscriptionListener
        );

        if (!isPwc(getAppState())) {
            this.onFacebookPixelInsert();
            if (App.model.get('insertPerformance')) {
                this.onTwitterPixelInsert();
            }
        }

        Intercom.userLogin();

        dispatch(hintsLoad());
    },

    renderFreeTrialFormIfNeeded: function () {
        const state = store.getState();
        if (isPwc(state)) return;

        const subscription = getUserPaidSubscriptionFirst(state);
        if (subscription) return;

        const user = getAuthUser(state);
        if (!user.firstTrialCode) return;

        let landingPage = user.meta.landingPage;
        if (location.pathname === LANDING_N2_URL ||
            location.pathname === LANDING_TASKMANAGER_URL ||
            location.pathname === LANDING_KANBAN_URL ||
            location.pathname === LANDING_GANTTCHART_URL ||
            landingPage === LANDING_N2_URL ||
            landingPage === LANDING_TASKMANAGER_URL ||
            landingPage === LANDING_KANBAN_URL ||
            landingPage === LANDING_GANTTCHART_URL
        ) {
            if (location.pathname === LANDING_N2_URL ||
                location.pathname === LANDING_TASKMANAGER_URL ||
                location.pathname === LANDING_KANBAN_URL ||
                location.pathname === LANDING_GANTTCHART_URL) {
                dispatch(authUserSetLandingPage(location.pathname))
            }
            this.onHideStartTips();
            this.freeTrialForm = new FreeTrialForm();
        }
    },

    onFacebookPixelInsert: function() {
        Util.insertScript('/js/lib/facebook-pixel-insert.js');
    },

    onTwitterPixelInsert: function() {
        Util.insertScript('/js/lib/twitter-pixel-insert.js');
    },

    checkUnavailabilityAndBlock: function() {
        this.blockerClassToggle();
        App.controller.blockInterface();
        this.blockOrUnblockBoards();
    },

    blockOrUnblockBoards: function(){
        getNumberKeys(store.getState().model.dashboards).forEach(boardId => {
            dispatch(boardFetchPermissions(boardId));
        })
    },

    blockerClassToggle: function(isBlocked) {
        if (isBlocked === undefined) isBlocked = App.controller.isUserBlocked();
        this.$el.toggleClass('page--events', isBlocked);
    },

    setGAuser: function() {
        if (typeof (ga) != "undefined") {
            ga('set', 'userId', getAuthUser(getAppState()).id);
        }
    },

    setTimeoutRemoveStart: function() { // на случай если какой-то сценарий не скрыл стартовый экран
        setTimeout(() => App.controller.removeStart(), 10000); // 10 sec
    },

    render: function() {
        this.renderNavigationPanel();
        this.renderTopBar();
        this.renderBlanker();
        this.renderLoader();
        this.renderCookie();
        this.setTimeoutRemoveStart();
        this.renderSkipToMain();
        return this;
    },

    renderNavigationPanel: function () {
        this.navigationPanel = new NavigationPanelView({
            store: store,
            el: this.el.querySelector('.' + NAVIGATION_PANEL_CONTAINER_CLASS)
        }).render();
    },

    renderTopBar: function () {
        const topBar = this.el.querySelector(`.${TOP_BAR_CLASS}`);
        if (topBar) this.topBar = new TopBar(topBar, store);
    },

    renderUpgradeButton: function () {
        this.upgradeButton = new UpgradePlanButton(
            {
                el: this.$el.find('.upgrade').get(0),
                store
            }
        ).render();
        const isShown = isUpgradeButtonShownSelector(getAppState());
        this.$el.toggleClass('page--upgrade', isShown);
    },

    renderBlanker: function () {
        this.blancker = new Blanker(
            {
                el: this.$el.find('.main-panel__blanker').get(0),
                store
            }
        ).render()
    },

    renderMagicLink: function (options, selector) {
        const el = document.querySelector(selector);
        if (el) {
            this.magicLinks[selector] = new MagicLinkReactToBackbone(el, options, store);
        }
    },

    showMyWork: function () {
        const assignedToMe = this.el.querySelector('.main-panel__my-work');
        if (assignedToMe) {
            if (!this.assignedToMeView) {
                this.assignedToMeView = new DashboardWidgets(assignedToMe, store);
            }
            this.$el.addClass('page--my-work');
        }
        this.hideLoader();
    },


    myWorkFilterBarCollapseToggle: function () {
        const isBarCollapsed = getMyWorkFilterBarCollapsed(getAppState());
        const className = 'page--filter-bar-collapsed';
        if (isBarCollapsed) {
            this.$el.addClass(className);
        } else {
            this.$el.removeClass(className);
        }
    },

    hideAssignedToMe: function () {
        if (this.assignedToMeView) {
            this.assignedToMeView.remove();
            this.assignedToMeView = null;
            App.controller.mainView.themeHelper.clearTheme();
        }
        this.$el.removeClass('page--my-work');
    },

    showCardLink: function () {
        this.$el.addClass('page--card-link');
    },

    hideCardLink: function () {
        this.$el.removeClass('page--card-link');
    },

    showRegistration: function(callback) {
        if(!this.registrationDialog){
            this.registrationDialog = new RegistrationDialogView({
                model:{callback : callback},
                el: this.$el.find('.app-registration').get(0)
            });
            this.registrationDialog.render();
        }

        this.$el.find('.app-registration').removeClass('hidden');
        this.htmlScrollDisabledToggle(true);
    },

    renderSignIn: function () {
        let signInPlaceholder = document.querySelector('.signin-placeholder');
        if (signInPlaceholder) return;

        signInPlaceholder = document.createElement('div');
        signInPlaceholder.className = 'signin-placeholder';
        document.body.append(signInPlaceholder);

        if (this.signIn) {
            this.signIn.remove();
        }
        this.signIn = new SignInView({
            el: signInPlaceholder,
            store,
        });
        this.signIn.render();
        setTimeout(() => App.controller.removeStart(), 300); // KNB-3524
        //App.controller.removeStart();
        setTimeout(this.onHideStartTipsIfNeeded.bind(this), 100); // подождать, не появится ли новый лоадер;
    },

    showSplashScreen: function() {
        this.renderSignIn();
        dispatch(signInIsShowSet(true));
    },

    removeSplashScreen: function() {
        if (!isUserAuthorized(getAppState())) return;
        dispatch(signInReset());
        if (this.signIn) {
            this.signIn.remove();
            this.signIn = null;
        }
        let signInPlaceholder = document.querySelector('.signin-placeholder');
        if (signInPlaceholder) {
            signInPlaceholder.remove();
        }
    },

    setAsidePanelMouseDown: function(value) {
        this.isAsidePanelMouseDown = value;
    },

    handleClick: function(e) {
        if (document.querySelector('.aside-panel__resizer.react-draggable-dragged')) return; // aside-panel resizing

        const state = store.getState();
        if (getIsMultiselect(state, getActiveBoardId(state))) {
            const multiSelectIgnoreClick = `
                .aside-panel,
                .k-card,
                .handle-click-ignore,
                .${CLASS_COLOR_PALETTE}-color-row__picker-dropdown
            `;
            if (
                !$(e.target).closest(multiSelectIgnoreClick).length &&
                $(e.target).closest('body').length  // если элемент уже не в доме, то тут будет 0
            ) {
                App.router.navigate(App.router.getUrl(null), {trigger: true});
            }
            return;
        }

        var ignoreClick = `
            .aside-panel,
            .handle-click-ignore,
            .modal,
            .overlay,
            .print,
            .profile-box,
            .time-tracker,
            .ui-datepicker,
            .ui-datepicker-header,
            .${FP_FILTER_INFO_PANEL_CLASS},
            .${NAVIGATION_PANEL_CLASS},
            .${CARD_CLASS},
            .${LIST_CLASS_HEADER},
            #${ID_ASIDE_PANEL_PORTAL},
            .${CLASS_GANTT_CARD},
            .${CLASS_LIST_VIEW_CARD},
            .${CLASS_MY_WORK_CARD},
            .notification-group,
            .dialog-blocker,
            .page--hardblock,
            #topbar-portal,
            .picker-dialog-bg,
            .notifications__button, .notifications,
            .color-tags-dropdown__button
        `;

        if (this.isAsidePanelMouseDown) {
            this.setAsidePanelMouseDown(false);
            return;
        }
        if (
          !$(e.target).closest(ignoreClick).length &&
          $(e.target).closest('body').length && // если элемент уже не в доме, то тут будет 0
          App.controller.isRelatedPanelNotOpened()
        ) {
            if (getIsAssignedToMeActive(store.getState())) {
                if (getIsAssignedToMeCardActive(store.getState())) {
                    dispatch(segmentTrackAction(SegmentCardEvent.CARD_DETAILS_CLOSE_CLICKED, {
                        name: SegmentCardOptions.SOURCE,
                        value: SegmentCardDetailsCloseSourceValue.BLUR_CARD_IN_MY_WORK
                    }));
                }
                App.router.navigate(App.router.getAssignedToMeUrl(), {trigger: true});
            } else if (
              getActiveBoardId(store.getState()) &&
              !this.$el.find('.modal--tutorial-dialog').length
            ) {
                requestAnimationFrame(() => {
                    this.asidePanel.hide();
                    this.leftAsidePanel.hide();
                });
                if (getActiveCardId(store.getState())) {
                    dispatch(segmentTrackAction(SegmentCardEvent.CARD_DETAILS_CLOSE_CLICKED, {
                        name: SegmentCardOptions.SOURCE,
                        value: SegmentCardDetailsCloseSourceValue.BLUR_CARD
                    }));
                }
            }
        }
        App.vent.trigger(App.vent['body:clicked'], e);
    },

    onKeyDown: function(e){
        App.vent.trigger(App.vent["key:keydown"], e);
    },

    onMouseUp: function(e) {
        App.vent.trigger(App.vent['body:mouseUp'], e);
    },

    /**
     * События GA по клику на социальные кнопки
     */
    socialIconClicked: function(e) {
        var link = $(e.target).closest('.bottombar__sc-icon');
        if (link.hasClass('bottombar__sc-icon--facebook')) {
            App.controller.trackEvent(Messages.getText('ga.category.social'),
                Messages.getText('ga.action.social.facebook_footer'));
        }
        if (link.hasClass('bottombar__sc-icon--twitter')) {
            App.controller.trackEvent(Messages.getText('ga.category.social'),
                Messages.getText('ga.action.social.twitter_footer'));
        }
        if (link.hasClass('bottombar__sc-icon--gplus')) {
            App.controller.trackEvent(Messages.getText('ga.category.social'),
                Messages.getText('ga.action.social.google-plus_footer'));
        }
    },

    startControler: function() {
        App.controller.start();
    },

    startUserRealtime: function() {
        const authUser = getAuthUser(getAppState());
        if (this.userRealtime) {
            if (authUser.id != this.userRealtime.userId) {
                this.userRealtime.socketIO.removeListener(this);
            } else {
                return;
            }
        }

        this.userRealtime = {
            userId: authUser.id,
            socketIO: RoomFactory.getInstance().getUserRoom(authUser.id, this, authUser.cometToken)
        };

    },

    startSystemRealtime: function() {
        if (this.systemRealtime) {
            this.systemRealtime.socketIO.removeListener(this);
        }

        this.systemRealtime = {
            socketIO: RoomFactory.getInstance().getSystemRoom(this, getAuthUser(getAppState()).cometTokenToSystemRoom)
        };

    },

    getNotificationHelper: function() {
        return this.notifyHelper;
    },

    updateModel: function(modelWrapperStr) {
        var modelWrapper = JSON.parse(modelWrapperStr);
        this.updateModelByObj(modelWrapper);
        this.updateNewSocketModelByObj(modelWrapper);
    },

    //  App.Helpers.DashboardSocketIOFactory listener
    updateModelByObj: function(modelWrapper) {
        try {
            if (modelWrapper.classKey === 'user') {
                dispatch(loadUser()).then((user) => {
                    dispatch(updateUser(user))
                });
            } else if (modelWrapper.classKey === 'push_notification_history') {
                App.vent.trigger(App.vent["notify:send"], App.Models.Notification.findOrCreate(modelWrapper.model,{merge:true}));
            } else if (modelWrapper.classKey === 'demandRefresh') {
                this.demandRefresh(modelWrapper.model);
            } else if (modelWrapper.classKey === 'socket') {
                root.socketConnections = root.socketConnections || {};
                if (!root.socketConnections.isNewSocketMessageReceived) {
                    dispatch(segmentTrackAction(SegmentUserEvent.SOCKET_CONNECTION_ERROR));
                    console.log(SegmentUserEvent.SOCKET_CONNECTION_ERROR.event)
                } else {
                    console.log("sockets work!")
                }
                root.socketConnections.isNewSocketMessageReceived = false;
            } else if (modelWrapper.classKey === 'socket2') {
                root.socketConnections = root.socketConnections || {};
                root.socketConnections.isOldSocketMessageReceived = true;
            } else if (modelWrapper.classKey === 'user_switch_account') {
                document.location.reload();
            } else if (modelWrapper.classKey === 'user_logout') {
                CommonAuthManager.logout();
            } else if(modelWrapper.classKey === 'configUpdated') {
                this.toggleFreshDesk(modelWrapper.model.onlineChatEnabled);
                this.toggleUMUX(modelWrapper.model.isUMUXPollEnabled);
                this.toggleOnboardingTutorials(modelWrapper.model.isOnboardingTutorialsEnabled);
            } else if (modelWrapper.classKey === 'subscription') {
                App.vent.trigger(App.vent['subscriptionItems:' + modelWrapper.model.action], modelWrapper.model);
            } else if (modelWrapper.classKey === 'cardAssigneeAdd') {
                dispatch(cardAssigneesActionSetAction(cardAssigneeAddAction(modelWrapper.model.cardId, modelWrapper.model)));
            } else if (modelWrapper.classKey === 'cardAssigneeUpdate') {
                dispatch(cardAssigneesActionSetAction(cardAssigneeUpdateAction(modelWrapper.model.cardId, modelWrapper.model)));
            } else if (modelWrapper.classKey === ERealtimeClassKeys.STORE_ACTION) {
                dispatch(modelWrapper.action);
            } else if (modelWrapper.classKey === ERealtimeClassKeys.AUTH_USER) {
                dispatch(updateUser(modelWrapper.authUserUpdate));
            } else if (modelWrapper.classKey === ERealtimeClassKeys.USER_BOARD_ACTIVITY_TIME_UPDATE) {
                this.processBoardActivityTimes(modelWrapper.model)
            } else if (modelWrapper.classKey === ERealtimeClassKeys.STARRED) {
                window.App.controller.notificationsView.staredNotifyHelper.starredNotificationListener((modelWrapper.model));
            }
        } catch (e) {
            console.error(e);
        }
    },

    updateNewSocketModelByObj: function(modelWrapper) {
        try {
            if (modelWrapper.classKey === 'socket') {
                root.socketConnections = root.socketConnections || {};
                root.socketConnections.isNewSocketMessageReceived = true;
            } else if (modelWrapper.classKey === 'socket2') {
                root.socketConnections = root.socketConnections || {};
                if (!root.socketConnections.isOldSocketMessageReceived) {
                    dispatch(segmentTrackAction(SegmentUserEvent.SOCKET_CONNECTION_v2_ERROR));
                    console.log(SegmentUserEvent.SOCKET_CONNECTION_v2_ERROR.event)
                } else {
                    console.log("sockets work!")
                }
                root.socketConnections.isOldSocketMessageReceived = false;
            }
        } catch (e) {
            console.error(e);
        }
    },

    demandRefresh: function(model) {
        if (!model || !model.version) return;
        if (getAppVersion() === model.version) return;

        /**
         * чтобы не убить бэк, когда все разом обновятся
         * снекбар запускаем через рандомное время (макс 5 минут)
         */
        const refreshTimeout = Math.floor(Math.random() * 5 * 60 * 1000);
        setTimeout(() => {
            dispatch(snackbarRefreshDefault({
                actionApply: () => {
                    location.reload();
                }
            }));
        }, refreshTimeout);
    },

    processBoardActivityTimes: function(model) {
        dispatch(userBoardActivityTimesActionSetAction(userBoardActivityTimesSetAction({
            [model.id]: model
        })));
        const state = getAppState()
        const showBoardIds = getMyWorkShowBoards(state);
        if (!showBoardIds.includes(model.id)) {
            const unselectedBoards = getMyWorkUnselectedBoards(state);
            const selectedBoards = getMyWorkSelectedBoards(state);
            const diff = Date.now() - model.lastActivityTime;
            if (
                selectedBoards.includes(model.id) ||
                (diff / 1000 / 60 / 60 / 24) <= BOARDS_LAST_DAYS &&
                !unselectedBoards.includes(model.id)
            ) {
                dispatch(myWorkShowBoardsSet([...showBoardIds, model.id]));
            }
        }
    },

    onUserSwitchAccount: function() {
        if (this.userRealtime) {
            this.userRealtime.socketIO.emit({
                model: null,
                classKey: 'user_switch_account'
            });
        }
    },

    onUserLogout: function() {
        if (this.userRealtime) {
            this.userRealtime.socketIO.emit({
                model: null,
                classKey: 'user_logout'
            });
        }
    },

    // showInfoScreens: function () {
    //     const authUser = getAuthUser(getAppState());
    //     if (authUser.testMode) {
    //         return;
    //     }
    //     let lastUpdateShownTime = authUser.meta && authUser.meta.lastUpdateShownTime;
    //     if (!lastUpdateShownTime) {
    //         lastUpdateShownTime = authUser.firstVisit === true ? 0 : 1;
    //     }
    //     const timeFromCreateUser = Date.now() / 1000 - authUser.createDateTimeStamp;
    //     if (
    //         lastUpdateShownTime &&
    //         timeFromCreateUser > 60 * 60 * 24 // 1 day: dont show ficha popup for 1 day since user created
    //     ) {
    //         var updates = App.Views.Updates.getUpdates(lastUpdateShownTime);
    //         if (updates.length) {
    //             App.controller.showInfoScreens('features', updates.reverse());
    //         }
    //     }
    //     const lastUpdate = App.Views.Updates.getLastUpdate();
    //     if (lastUpdateShownTime !== lastUpdate) {
    //         dispatch(authUserSetLastUpdateShownTimeMeta(lastUpdate));
    //     }
    // },

    /**
     * Устанавливает в local storage значение поля последней авторизации
     * @param timestamp
     */
    setLoginStorageTime: function(timestamp) {
        var stringDate = timestamp;
        localStorage.setItem('lastLogin', stringDate);
    },

    getAsidePanelRight: function() {
        return this.$el.find(RIGHT_ASIDE_PANEL_SELECTOR);
    },

    onAsidePanelClosed: function() {
        var $mp = this.$el.find('.main-panel');
        var $ap = this.getAsidePanelRight();
        this.panelOpened = false;
        $mp.removeClass('main-panel--with-aside');
        this.$el.find('.' + LISTS_ASIDE_PLACEHOLDER_CLASS).removeClass(LISTS_ASIDE_PLACEHOLDER_CLASS + '--with-aside');
        $ap.removeClass('aside-panel--opened');
        document.documentElement.classList.remove('aside');
        this.setNotificationWrapperPos(0);
        this.setFeedbackBubblePos(0);

        dispatch(asidePanelWidthSet(0));
        this.setPanelsWidth();
    },

    onAsidePanelOpened: function() {
        this.panelOpened = true;
        var $mp = this.$el.find('.main-panel');
        var $ap = this.getAsidePanelRight();
        if (this.isMainPanelResizable() || getIsLinkCardActive(store.getState())) {
            $mp.addClass('main-panel--with-aside');
        }
        $ap.addClass('aside-panel--opened');

        if (window._innerWidth <= 864) { // aside breakpoint
            document.documentElement.classList.add('aside');
        }

        dispatch(asidePanelWidthSet(ASIDE_MIN_WIDTH));
        this.setPanelsWidth();
    },

    setMainPanelWidth: function(width) {
        const mp = this.el.querySelector('.' + CLASS_MAIN_PANEL);
        if (!mp) return;
        const mpScroll = this.el.querySelector('.' + CLASS_MAIN_PANEL_SCROLL);
        if (width) {
            if (this.isMainPanelResizable()) mp.style.width = width + 'px';
            if (mpScroll) mpScroll.style.width = width + 'px';
        } else {
            mp.style.width = '100%';
            if (mpScroll) mpScroll.style.width = mp.offsetWidth + 'px';

            const dashboardViewManager = App.controller.getDashboardViewManager();
            if (dashboardViewManager) {
                const boardView = dashboardViewManager.getView();
                if (boardView && boardView.scrollBar && boardView.scrollBar.scrollLeft && boardView.scrollElement) {
                    boardView.scrollElement.scrollLeft = boardView.scrollBar.scrollLeft; // sync scrolls
                }
            }
        }
    },

    setAsidePanelWidth: function(width) {
        if (width) this.getAsidePanelRight().css({width}); // dont reset aside width
        this.$el.find('.' + LISTS_ASIDE_PLACEHOLDER_CLASS).css({minWidth: width});
        if (!!width && !this.isMainPanelResizable()) {
            this.$el.find('.' + LISTS_ASIDE_PLACEHOLDER_CLASS).addClass(LISTS_ASIDE_PLACEHOLDER_CLASS + '--with-aside');
        }
        this.setNotificationWrapperPos(width);
        this.setFeedbackBubblePos(width);
        throttle(() => {
            const asidePanelWidth = getAsidePanelWidth(store.getState());
            if (asidePanelWidth !== parseInt(width)) { // dispatches optimization
                dispatch(asidePanelWidthSet(parseInt(width)));
            }
        }, 500, { trailing: false }).call(this);
    },

    /**
     * Расположение нотификаций напрямую зависит от положения и рамера правой панели, поэтому корелируем их позицию
     * от aside
     */
    setNotificationWrapperPos: function(offset) {
        this.$el.find(App.Helpers.NotifyHelper.notificationWrapper).css({right: offset});
    },

    setFeedbackBubblePos: function(offset) {
        this.$el.find('.feedback__container').css({right: offset});
    },

    /**
     * Возвращает размер боковой панели из localStorage, если не получается возвращает ширину из aside элемента.
     * @returns {*}
     */
    getAsidePanelWidth: function() {
        if (this.isReports()) return ASIDE_MIN_WIDTH;

        let width = getAsidePanelEntityPanelWidth(getAppState());

        const $asidePanelContainer = this.getAsidePanelRight().find('.aside-panel__wrapper > div').last();
        const $asidePanelActions = $asidePanelContainer.find('.' + CLASS_ASIDE_PANEL_ACTIONS_SCROLL);
        if (!$asidePanelActions.length) {
            const $asidePanelBody = $asidePanelContainer.find('.' + CLASS_ASIDE_PANEL_BODY);
            if (!$asidePanelBody.length) {
                return null; // панель ещё не готова
            }
        }

        const asidePanelActionsWidth = $asidePanelActions.length ? $asidePanelActions.width() : 0;
        width += asidePanelActionsWidth;

        const $asidePanelLeftOpened = this.$el.find('.left-aside-panel--opened');
        const $asidePanelLeft = this.$el.find('.left-aside-panel--opened .aside-panel');
        if ($asidePanelLeftOpened && $asidePanelLeft) { // если открыта левая панель, правая не должна с ней пересекаться
            const w = window._innerWidth - $asidePanelLeft.width();
            if (w < width) width = w;
        }

        return width;
    },

    isReports: function() {
        const dashboardViewManager = App.controller.getDashboardViewManager();
        const pageReports = dashboardViewManager ?
            dashboardViewManager.getViewType() === EViewTypes.REPORTS_VIEW_TYPE :
            false;
        return pageReports;
    },

    isMainPanelResizable: function() {
        const dashboardViewManager = App.controller.getDashboardViewManager();
        if (!dashboardViewManager) return false;

        const viewType = dashboardViewManager.getViewType();
        const isMainPanelResizable = (
            viewType === EViewTypes.LIST_VIEW_TYPE ||
            viewType === EViewTypes.GANTT_VIEW_TYPE
        );
        return isMainPanelResizable;
    },

    setPanelsWidth: function() {
        let $ap = this.getAsidePanelRight();
        let aw = this.getAsidePanelWidth();
        let placeholderWidth = 0;
        const placeholder = this.el.querySelector('.' + LISTS_ASIDE_PLACEHOLDER_CLASS);
        if (placeholder) placeholderWidth = placeholder.clientWidth
        if (aw && Math.round(aw) === Math.round($ap.width()) && placeholderWidth) {
            return;
        }
        if ((!$ap[0].style.width || $ap.width() <= ASIDE_MIN_WIDTH) && $ap.hasClass('aside-panel--aside-panel__card')) {
            $ap.addClass('pause'); // не показывать панель карты, пока она маленькая
        }
        if (this.setPanelsWidthDebounce) clearTimeout(this.setPanelsWidthDebounce);
        this.setPanelsWidthDebounce = setTimeout(() => {
            var w = $('.content__inner').width();
            var aw;

            if (
                this.panelOpened ||
                this.relatedOpened
            ) {
                if (this.panelOpened) {
                    aw = this.getAsidePanelWidth();
                }
                if (!aw) {
                    return this.setPanelsWidth();
                }
                if (w && w < aw) {
                    this.setAsidePanelWidth(w + 'px'); // чтобы правая панель не пересекалась с навПанель
                    $ap.removeClass('pause');
                    return;
                }
                this.setMainPanelWidth(w - aw);
                this.setAsidePanelWidth(aw + 'px');
                $ap.removeClass('pause');
            } else {
                this.setMainPanelWidth(false);
                this.setAsidePanelWidth(0);
            }
        }, 100);
    },

    onDashboardActivated: function(boardId) {
        this.openDialogElseTemplate(boardId);

        const boardThemeSelector = (state) => {
            return getBoard(state, boardId).theme;
        };

        var boardThemeListener = (theme) => {
            this.themeHelper.applyTheme(theme);
        };

        this.unsubscribeBoardThemeListener = storeListener(
            store,
            boardThemeSelector,
            boardThemeListener);

        this.hideAssignedToMe();
        requestAnimationFrame(() => { // если из openBoards, надо сначала закрыть диалог
            if (document.activeElement === document.body) {
                const main = this.el.querySelector('.' + CLASS_MAIN_PANEL);
                if (main) main.focus();
            }
        });
    },

    onDashboardDeactivated: function() {
        if (this.unsubscribeBoardThemeListener) this.unsubscribeBoardThemeListener();
        this.themeHelper.clearTheme();
    },

    hardBlockToggle: function(isBlocked) {
        this.$el.toggleClass('page--hardblock', isBlocked);
    },

    onShowStartTips: function() {
        const tipsEl = document.querySelector('.start-tips');
        if (tipsEl && tipsEl.classList.contains('hide')) {
            const tipEl = document.querySelector('.start-tips__tip');
            const tipTextEl = document.querySelector('.start-tips__text');
            window.tipsI++;
            if (window.tipsI >= tips.length) window.tipsI = 0;
            if (tipEl && tipTextEl) {
                tipTextEl.innerHTML = tips[window.tipsI];
                tipEl.classList.remove('hide');
                tipEl.classList.add('show');
                tipsEl.classList.remove('hide');
            }
        }
    },

    onStartTipsUpgrade: function() {
        const tipsInnerEl = document.querySelector('.start-tips__inner');
        const tipEl = document.querySelector('.start-tips__tip');
        let tipsTimer;
        if (tipsInnerEl && tipEl) {
            const onMouseLeave = () => {
                tipsInnerEl.classList.remove('hover');
                if (tipsTimer) clearTimeout(tipsTimer);
                tipsTimer = setTimeout(()=>{
                    this.onHideStartTipsIfNeeded.call(this);
                }, 500);
            }
            const onClick = () => {
                if (tipsTimer) clearTimeout(tipsTimer);
                dispatch(segmentTrackAction(SegmentUserEvent.TIP_CLICKED, {
                    name: 'tip',
                    value: tips[window.tipsI]
                }));
                setTimeout(() => { // delete onMouseLeave timer
                    if (tipsTimer) clearTimeout(tipsTimer);
                }, 300);
            };
            const onMouseEnter = () => {
                tipsInnerEl.classList.add('hover');
                if (tipsTimer) clearTimeout(tipsTimer);
                dispatch(segmentTrackAction(SegmentUserEvent.TIP_HOVERED, {
                    name: 'tip',
                    value: tips[window.tipsI]
                }));
            };
            tipsInnerEl.addEventListener('mouseleave', onMouseLeave);
            tipsInnerEl.addEventListener('mouseenter', onMouseEnter);
            tipEl.addEventListener('click', onClick);
        }
    },

    onHideStartTips: function () {
        const tipsEl = document.querySelector('.start-tips');
        if (tipsEl) {
            tipsEl.classList.add('hide');
            const tipEl = document.querySelector('.start-tips__tip');
            if (tipEl) {
                tipEl.classList.remove('hide');
            }
        };
    },

    onHideStartTipsIfNeeded: function () {
        if (
            !this.signIn &&
            this.$el.find('.loader--main').length &&
            !this.$el.find('.loader--main').hasClass('hidden')
        ) return;

        const tipsInnerEl = document.querySelector('.start-tips__inner:not(.hover):not(:hover)');
        if (tipsInnerEl) {
            this.onHideStartTips();
        }
    },

    showLoader: function(force, showTips, showProgress) {
        if (!getActiveBoardId(store.getState()) || force) {
            if (!this.$el.find('.loader--main').hasClass('hidden') &&
                this.$el.find('.loader--main').hasClass('loader--progress') === !!showProgress
            ) return;

            App.controller.boardLoaderInfo.resetBoardLoaderInfo();

            if (this.$el.find('.loader--main').length) {
                if (showTips) this.onShowStartTips();
                if (showProgress) {
                    this.$el.find('.loader--main').addClass('loader--progress');
                } else {
                    this.$el.find('.loader--main').removeClass('loader--progress');
                }
                this.$el.find('.loader--main').removeClass('hidden');
                $('body').addClass('page--loading');
            }
            this.htmlScrollDisabledToggle(true);
        }
    },

    hideLoader: function() {
        if (
            this.$el.find('.loader--main').length &&
            !this.$el.find('.loader--main').hasClass('hidden')
        ) {
            this.$el.find('.loader--main').addClass('hidden');
            $('body').removeClass('page--loading');
            this.htmlScrollDisabledToggle();
            setTimeout(() => {
                this.onHideStartTipsIfNeeded.call(this);
                if (!this.recentBoardsUpdate) {
                    this.recentBoardsUpdate = true;
                    App.controller.boardLoadManager.getRecentBoardsUpdate();
                }
            }, 100); // подождать, не появится ли новый лоадер

            App.controller.boardLoaderInfo.resetBoardLoaderInfo();
            App.controller.boardLoaderInfo.isFirstLoad = false;
        }
        this.hideOverlayCover();
    },

    hideLoaderWithTimeout: function() {
        setTimeout(() => this.hideLoader(), 500);
    },

    /**
     * @param callback - выплонить после того, как скроется лоадер
     * чтобы не мешать загрузке доски
     */
    doAfterLoader: function (callback) {
        const $loader = this.$el.find('.loader--main');
        if (!$loader.length) return;

        if ($loader.hasClass('hidden')) {
            callback();
        } else {
            const observer = new MutationObserver((mutations) => {
                if ($loader.hasClass('hidden')) {
                    callback();
                    observer.disconnect();
                }
            });
            observer.observe($loader[0], {attributes: true});
        }
    },

    renderLoader: function(){
        this.loaderRoot = createRoot(this.el.querySelector('.loader--main'));
        this.loaderRoot.render(
            <Provider store={store}>
                <LoaderBlock />
                <BoardProgressLoaderHOC />
            </Provider>
        );
    },

    renderCookie: function() {
        if (this.cookiesDialog) {
            this.cookiesDialog.remove()
        }
        this.cookiesDialog = new CookiesDialogView({store});
        this.$el.find('.cookies-dialog').html(this.cookiesDialog.render().el);
    },

    showOverlayCover: function() {
/*        if (this.$el.find('.overlay-cover').length) {
            this.$el.find('.overlay-cover').removeClass('hidden');
        }*/
    },

    hideOverlayCover: function() {
        if (this.$el.find('.overlay-cover').length) {
            this.$el.find('.overlay-cover').addClass('hidden');
        }
    },

    showSecondPanel: function() {
        this.$el.addClass(this.constructor.relatedClass);
        this.relatedOpened = true;
    },

    closeSecondPanel: function() {
        this.$el.removeClass(this.constructor.relatedClass);
        this.relatedOpened = false;
    },

    showHelpPopup: function(options = {}) {
        const {category} = options;
        App.controller.trackEvent(
            Messages.getText('ga.category.support'),
            Messages.getText('ga.action.helpUsPanel.opened')
        );
        dispatch(segmentTrackAction(SegmentFeedbackEvent.MORE_PROPERTIES_BUTTON_CLICKED,
            {name: SegmentFeedbackOption.TARGET, value: SegmentFeedbackTargetValue.MORE_PROPERTIES},
            {name: SegmentFeedbackOption.CATEGORY, value: category}));

        if (this.helpPopup) {
            this.helpPopup.show(options);
        } else {
           this.helpPopup = new HelpPopupView({store});
           this.$el.find('.modal--feedback').append(this.helpPopup.render(options).$el);
        }
        this.$el.find('.modal--feedback').addClass('modal--show');
    },

    htmlScrollDisabledToggle: function(flag = false) {
        const html = document.documentElement;

        if (flag) {
            if (html.classList.contains(CLASS_HTML_SCROLL)) {
                html.classList.add(CLASS_HTML_SCROLL_DISABLED);
            }
        } else {
            html.classList.remove(CLASS_HTML_SCROLL_DISABLED);
        }
    },

    renderKanbanPoll: function () {
        const createDate = Date.now() / 1000 - getAuthUser(store.getState()).createDateTimeStamp;
        const oneMonth = 24 * 60 * 60 * 30;
        if (createDate >= oneMonth) {
            this.kanbanchiRatePoll = new KanbanchiRatePoll();
        }
    },

    renderFeaturePoll: function () {
        const createDate = Date.now() / 1000 - getAuthUser(store.getState()).createDateTimeStamp;
        const oneDay = 24 * 60 * 60;
        if (createDate >= oneDay * 3) {
            this.featureRatePoll = new FeatureRatePoll();
        }
    },

    renderPricingPoll: function () {
        if (getAuthUser(store.getState()).firstVisit) {
            this.featureRatePoll = new PricingPoll();
        }
    },

    renderMessengerPoll: function () {
        const createDate = Date.now() / 1000 - getAuthUser(store.getState()).createDateTimeStamp;
        const oneDay = 24 * 60 * 60;
        if (createDate >= oneDay * 3) {
            this.messengerPoll = new MessengerPoll();
        }
    },

    renderPromoCodePopup: function () {
        if (getUserOwnSubscriptions(store.getState()).length) return;
        this.promoCodePopup = new PromoCodePopup();
    },

    hidePromoCodePopup: function () {
        if (this.promoCodePopup) {
            this.promoCodePopup.remove();
        }
    },

    renderBlockerModal: function (options) {
        if (!this.blockerModal) this.blockerModal = new BlockerModal(options);
        this.blockerModal.render();
    },

    removeBlockerModal: function () {
        if (this.blockerModal) {
            this.blockerModal.remove();
            this.blockerModal = null;
        }
    },

    remove: function () {
        App.vent.off(null, null, this);
        if (this.ganttZoomer) {
            this.ganttZoomer.remove();
        }
        if (this.userRealtime) {
            this.userRealtime.socketIO.removeListener(this);
        }
        if (this.systemRealtime) {
            this.systemRealtime.socketIO.removeListener(this);
        }
        if (this.helpPopup) {
            this.removeHelpPopup();
        }
        if(this.signIn){
            this.signIn.remove();
            this.signIn = null;
        }
        if(this.registrationDialog){
            this.registrationDialog.remove();
            this.registrationDialog = null;
        }
        if(this.snackbarsStackView){
            this.snackbarsStackView.remove();
            this.snackbarsStackView = null;
        }
        if(this.openBoardsDialogView){
            this.openBoardsDialogView.remove();
            this.openBoardsDialogView = null;
        }
        if (this.upgradeButton) {
            this.upgradeButton.remove();
            this.upgradeButton = null;
        }
        if (this.blancker) {
            this.blancker.remove();
            this.blancker = null;
        }
        if (this.qualificationDialog) {
            this.qualificationDialog.remove();
            this.qualificationDialog = null;
        }
        if (this.webinarDialog) {
            this.webinarDialog.remove();
            this.webinarDialog = null;
        }
        if (this.hintOpenedLastBoard) {
            this.hintOpenedLastBoard.remove();
            this.hintOpenedLastBoard = null;
        }
        if (this.skipToMain) {
            this.skipToMain.remove();
            this.skipToMain = null;
        }
        if (this.accessibilityStatement) {
            this.accessibilityStatement.remove();
            this.accessibilityStatement = null;
        }
        if (this.offlineBlocker) {
            this.offlineBlocker.remove();
            this.offlineBlocker = null;
        }
        if (this.unsubscribeBoardThemeListener) {
            this.unsubscribeBoardThemeListener();
        }
        if (this.freeTrialForm) {
            this.freeTrialForm.remove();
        }
        if (this.unsubscribeActiveSubscriptionListener) this.unsubscribeActiveSubscriptionListener();
        this.hideAssignedToMe();

        if (this.sharingDialog) {
            this.sharingDialog.remove();
            this.sharingDialog = null;
        }

        if (this.microsoftGroupPermission) {
            this.microsoftGroupPermission.remove();
            this.microsoftGroupPermission = null;
        }

        if (this.featureRatePoll){
            this.featureRatePoll.remove();
        }

        if (this.kanbanchiRatePoll){
            this.kanbanchiRatePoll.remove();
        }

        this.removeBlockerModal();

        this.closePermissionsFAQModal();

        return Backbone.View.prototype.remove.call(this);
    },

    showQualificationDialog: function () {
        this.qualificationDialog = new QualificationDialogReactToBackbone(store);
    },

    showWebinarDialog: function () {
        this.webinarDialog = new WebinarDialogReactToBackbone(store);
    },

    showNotificationsRequestDialog: function (onAllow, type = null) {
        if (this.notificationsRequestDialog) return;
        this.notificationsRequestDialog = new BrowserNotificationsRequestReactToBackbone(store, onAllow, type);
    },

    showPermissionsFAQModal: function () {
        if (this.permissionsFAQModal) return;
        dispatch(segmentTrackAction(SegmentUserEvent.NOT_ENOUGH_PERMISSIONS_SHOWN));
        this.permissionsFAQModal = new PermissionsFAQ();
    },

    closePermissionsFAQModal: function () {
        if (this.permissionsFAQModal){
            this.permissionsFAQModal.remove();
            this.permissionsFAQModal = null;
        }
    },

    showHintOpenedLastBoard: function () {
        const $loader = this.$el.find('.loader--main');
        if (!$loader.length) return;

        dispatch(setHintCanShow(EHintKey.OPENED_LAST_BOARD));

        const showHint = () => {
            const isNavPanelBig = getCurrentNavigationPanelType(store.getState()) === ENavigationPanelTypes.NP_BIG_TYPE;
            this.hintOpenedLastBoard = new HintOpenedLastBoardReactToBackbone(store, isNavPanelBig);
        }
        if ($loader.hasClass('hidden')) {
            showHint();
        } else { // подождать, когда доска загрузится
            const observer = new MutationObserver((mutations) => {
                if ($loader.hasClass('hidden')) {
                    showHint();
                    observer.disconnect();
                }
            });
            observer.observe($loader[0], {attributes: true});
        }
    },

    renderSnackbarsStack: function() {
        this.snackbarsStackView = new SnackbarsStackView({
            el: this.$el.find('.snackbars').get(0),
            store: store
        });
        this.snackbarsStackView.render();
    },

    renderOpenBoardsDialog: function(callback = null) {
        if (this.openBoardsDialogView) {
            if (callback) callback();
        } else {
            this.openBoardsDialogView = new OpenBoardsDialogView({
                el: this.$el.find('.open-boards-dialog__container').get(0),
                store: store
            });
            this.openBoardsDialogView.render(callback);
        }
    },

    renderAsidePanel: function() {
        this.asidePanel = new AsidePanelRenderer({
            el: this.$el.find('.' + CLASS_ASIDE_PANEL).get(0),
            store: store
        });
        this.asidePanel.render();
    },

    renderLeftPanel: function() {
        this.leftAsidePanel = new LeftAsidePanelRenderer({
            el: this.$el.find('.' + CLASS_LEFT_ASIDE_PANEL).get(0)
        });
        this.leftAsidePanel.render();
    },

    renderSkipToMain: function () {
        this.skipToMain = new SkipToMain();
    },

    openBoardsDialogShow: function(tabKey = null) {
        this.renderOpenBoardsDialog(
            () => this.openBoardsDialogView.show(tabKey)
        );
    },

    openDialogElseTemplate(boardId) {
        const board = getBoard(store.getState(), boardId);
        var templateInfo = board.templateInfo;
        const isCanEdit = getBoardPermissionsAllowEdit(store.getState(), boardId);
        if (templateInfo && templateInfo.template && isCanEdit) {
            App.controller.showInfo('All the changes will be applied to your template and saved automatically.', 'Template editing');
        }
    },

    removeRegistrationDialog() {
        if(this.registrationDialog){
            this.registrationDialog.remove();
            this.registrationDialog = null;
        }
    },
    removeHelpPopup() {
        if (this.helpPopup) this.helpPopup.remove();
        this.$el.find('.modal--feedback').removeClass('modal--show');
        this.helpPopup = null;
    },
    openAccessibilityStatement: function (){
        this.accessibilityStatement = new AccessibilityStatementView(store);
    },
    renderOfflineBlocker: function (){
        this.offlineBlocker = new OfflineBlocker();
    },

    showSharingDialog: function (boardId) {
        this.sharingDialog = new SharingDialog(boardId, store);
    },

    showMicrosoftGroupPermission: function() {
        this.microsoftGroupPermission = new MicrosoftGroupPermissionView(store);
    },

}, {
    relatedClass: 'page--related-panel',
    readOnlyString: ' (read-only)'
});
