import { getMessages } from '../../../../../../../../store/constants';
import { ISelectOptionsArray } from 'kui';
import { EMyWorkCardFetchOption } from '../../../../../../../../types/rest/IUserMeta';

export const MY_WORK_BOARD_REMOVE_BUTTON_TOOLTIP = getMessages().getText('common.remove');
export const MY_WORK_BOARD_SELECT_OPTION_TEXT = getMessages().getText('assigned_to_me.import.boards.select.option');
export const MY_WORK_BOARD_SELECT_ACTIVE_OPTION_TEXT = getMessages().getText('assigned_to_me.import.boards.select.option.active');

export const MY_WORK_BOARD_FETCH_CARDS_OPTIONS: ISelectOptionsArray = [
    {
        value: EMyWorkCardFetchOption.DAYS_7,
        text: MY_WORK_BOARD_SELECT_OPTION_TEXT + getMessages().getText('assigned_to_me.import.boards.select.days7')
    },
    {
        value: EMyWorkCardFetchOption.DAYS_30,
        text: MY_WORK_BOARD_SELECT_OPTION_TEXT + getMessages().getText('assigned_to_me.import.boards.select.days30')
    },
    {
        value: EMyWorkCardFetchOption.DAYS_90,
        text: MY_WORK_BOARD_SELECT_OPTION_TEXT + getMessages().getText('assigned_to_me.import.boards.select.days90')
    },
    {
        value: EMyWorkCardFetchOption.DAYS_180,
        text: MY_WORK_BOARD_SELECT_OPTION_TEXT + getMessages().getText('assigned_to_me.import.boards.select.days180')
    },
];
