import * as React from 'react';
import { Button, Divider, Modal } from 'kui';
import { IActionModalProps } from './types';
import './_ActionModal.scss';
import { ACTION_MODAL_CANCEL, CLASS_ACTION_MODAL, } from './constants';
import { Portal } from '../../../helpers/portal';
import { v4 as uuidv4 } from 'uuid';
import ReactHtmlParser from 'react-html-parser';

export const ActionModal = ({
    actions,
    className: classNameOwn,
    footer,
    isLoading,
    isShow,
    progress,
    title,
    text,
    onClose
}: IActionModalProps) => {
    if (!isShow) return null;
    const className = CLASS_ACTION_MODAL;
    const [classUnique] = React.useState(className + '--' + uuidv4());
    const classCancel = className + '__cancel';

    React.useEffect(() => { // фокус уходит на body
        const actionButton = document.querySelector(`.${classUnique} .kui-button--variant_primary`) as HTMLElement;
        if (actionButton) setTimeout(() => {
            actionButton.focus();
        }, 0);
    }, []);

    return <Portal selector={'body'} className={className + '__portal'}>
            <Modal
                title={''}
                onClose={onClose}
                className={`${className} ${classUnique} ${classNameOwn || ''}`}
            >
                <div className={className + '__title'}>{title}</div>
                <Divider />
                <span className={className + '__text'}>
                    {ReactHtmlParser(text)}
                </span>
                <Divider />
                {!isLoading && actions && !!actions.length &&
                    <div className={className + '__actions'}>
                        {actions.map((buttonProps, idx) => (
                            <Button key={idx} {...buttonProps}>{buttonProps.children}</Button>
                        ))}
                    </div>
                }
                {footer ? footer
                    : <Button
                        className={`${classCancel} ${!isLoading ? classCancel + '--secondary' : ''}`}
                        variant={'secondary'}
                        onClick={onClose}
                    >
                        {ACTION_MODAL_CANCEL}
                    </Button>
                }
                {isLoading &&
                    <div
                        className={className + '__loader'}
                        style={{ width:progress + '%' }}
                    />
                }
            </Modal>
    </Portal>;
};
