import { IGetState } from '../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { fetchHandler } from '../../../../../util/fetchHandler';
import Util from '../../../../../util/util';
import { REST_CARD, REST_CHECKLISTS } from '../../../../constants';
import { getRestHeadersPatch } from '../../../../helpers/getRestHeadersHelper';
import { IRestChecklist } from '../../../../../types/rest/IRestChecklist';

export const restPatch = (
    checklist: Partial<IRestChecklist>
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const { cardId, id, ...rest } = checklist;
        return fetchHandler<IRestChecklist>(
            Util.getApiUrl(REST_CARD + cardId + REST_CHECKLISTS + id), {
                ...getRestHeadersPatch(),
                body: JSON.stringify(rest)
            }
        )
    };
    return action;
};
