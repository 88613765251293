import { IApplicationState } from 'app/types/types';
import { IBoardNameHOCProps } from '../../../BoardName/hocs/BoardNameHOC/types';
import { getBoard } from 'app/store/model/selectors/getBoardById';
import { connect } from 'react-redux';
import { BoardDescription } from '../../components/BoardDescription/BoardDescription';
import { IBoardDescriptionEvents, IBoardDescriptionFields } from '../../components/BoardDescription/types';
import { blockMarkdown } from 'app/helper/markdownHelper';
import { onSave } from './events/onSave';
import { onCtrlEnter } from './events/onCtrlEnter';
import { onCancel } from './events/onCancel';
import { onEsc } from './events/onEsc';
import { onToggle } from './events/onToggle'
import { getBoardIdsOpenedDescription } from 'app/view/react_components/aside_panel/boardDetails/details/BoardDescription/helpers/appealLocalStorage';
import { LOCAL_STORAGE_KEY_BOARD_IDS_OPENED_DESCRIPTION } from 'app/view/react_components/aside_panel/boardDetails/details/BoardDescription/constants';

const mapStateToProps = (
    state: IApplicationState,
    ownProps: IBoardNameHOCProps
): IBoardDescriptionFields => {
    const { boardId } = ownProps;
    const board = getBoard(state, boardId);
    let description = board ? board.description : '';
    let descriptionMarkdown = description;
    if (description) {
        descriptionMarkdown = blockMarkdown(description);
    }

    let boardIdsOpenedDescription = getBoardIdsOpenedDescription(LOCAL_STORAGE_KEY_BOARD_IDS_OPENED_DESCRIPTION)
    const isStayOpened = boardIdsOpenedDescription.includes(boardId);

    return {
        description,
        descriptionMarkdown,
        isStayOpened
    }
};

const mapDispatchToProps = (
    dispatch: any,
    ownProps: IBoardNameHOCProps
): IBoardDescriptionEvents => {
    const { boardId } = ownProps;
    return {
        onSave: (description: string) => dispatch(onSave(boardId, description)),
        onCancel: () => dispatch(onCancel()),
        onCtrlEnter: (description: string) => dispatch(onCtrlEnter(boardId, description)),
        onEsc: () => dispatch(onEsc()),
        onToggle: (isCollapse) => dispatch(onToggle(isCollapse, boardId))
    }
};

export const BoardDescriptionHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(BoardDescription);

BoardDescriptionHOC.displayName = 'BoardDescriptionHOC';
