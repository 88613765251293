import {
    INavigationPanelCollapseDispatchProps,
    INavigationPanelCollapseStateProps
} from '../../components/NavigationPanelCollapse/types';
import { IApplicationState } from '../../../../../../types/types';
import { connect } from 'react-redux';
import { NavigationPanelCollapse } from '../../components/NavigationPanelCollapse/NavigationPanelCollapse';
import { onCollapseClicked } from './events/onCollapseClicked';
import { onCloseClicked } from './events/onCloseClicked';
import { getCurrentNavigationPanelType } from '../../helpers/getCurrentNavigationPanelType';
import { INavigationPanelCollapseHOCProps } from './types';
import { getAuthUser } from '../../../../../../store/model/authUser/selectors/getAuthUser';

const mapStateToProps = (
    state: IApplicationState,
    props: INavigationPanelCollapseHOCProps,
): INavigationPanelCollapseStateProps => {
    const { unseenNotificationCount } = getAuthUser(state);
    return {
        status: getCurrentNavigationPanelType(state),
        unseenNotificationCount
    };
};

const mapDispatchToProps = (
    dispatch: any,
    { updateIsNotificationsOpen }: INavigationPanelCollapseHOCProps
): INavigationPanelCollapseDispatchProps => ({
    onCloseClicked: () => dispatch(onCloseClicked()),
    onCollapseClicked: () => dispatch(onCollapseClicked()),
    updateIsNotificationsOpen
});

export const NavigationPanelCollapseHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(NavigationPanelCollapse);
NavigationPanelCollapseHOC.displayName = 'NavigationPanelCollapseHOC';
