import {IApplicationState} from '../../../../../../types/types';
import {getNavigationPanelInitialState} from '../navigationPanel/constants';
import {INavigationPanelState} from '../navigationPanel/types';

export const getNavigationPanelState = (
    state: IApplicationState
): INavigationPanelState => {
    const navigationPanel =
        state.navigationPanel ||
        getNavigationPanelInitialState();
    return navigationPanel;
};
