/*
List of all custom events:

*/
'use strict';

window.Vent = {
    'body:clicked': 'body:clicked',
    'key:keydown': 'key:keydown',
    'body:mouseUp': 'body:mouseUp',
    'view:resized': 'view:resized',
    'view:afterprint': 'view:afterprint',
    'view:boardNameChanged': 'view:boardNameChanged',
    'channel:token-expired': 'channel:token-expired',
    'channel:token-obtained': 'channel:token-obtained',
    'gapi:authorized': 'gapi:authorized',
    'gapi:logout': 'gapi:logout',
    'gapi:calendar_loaded': 'gapi:calendar_loaded',
    'user:switch_account':'user:switch_account',
    'ajax:send': 'ajax:send',
    'ajax:complete': 'ajax:complete',
    'ajax:error': 'ajax:error',
    'keydown:escape': 'keydown:escape',
    'keydown:delete': 'keydown:delete',
    'open-dashboard-dialog:hide': 'open-dashboard-dialog:hide',
    'aside-panel:opened': 'aside-panel:opened',
    'aside-panel:closed': 'aside-panel:closed',
    'aside-panel:hide': 'aside-panel:hide',
    'aside-panel:clicked': 'aside-panel:clicked',
    'balloon:hide': 'balloon:hide',
    'modal:closed': 'modal:closed',
    'modal:closing': 'modal:closing',
    'modal:toggle': 'modal:toggle',
    'dialog:closed': 'dialog:closed',
    'aside-panel:loaded': 'aside-panel:loaded',
    'aside-panel:resized': 'aside-panel:resized',
    'dashboard:deactivated': 'dashboard:deactivated',
    'dashboard:activated': 'dashboard:activated',
    'dashboard:logoChanged': 'dashboard:logoChanged',
    'dashboard:close': 'dashboard:close',
    'dashboard:afterClosed': 'dashboard:afterClosed',
    'dashboard:formOpened': 'dashboard:formOpened',
    'dashboard:formClosed': 'dashboard:formClosed',
    'dashboard:open': 'dashboard:open',
    'dashboard:copied': 'dashboard:copied',
    'dashboard:restored': 'dashboard:restored',
    'dashboard:localChange': 'dashboard:localChange',
    'dashboard:cardClick': 'dashboard:cardClick',
    'dashboard:listResized': 'dashboard:listResized',
    'dashboard:doFilter': 'dashboard:doFilter',
    'dashboard:filterToggle': 'dashboard:filterToggle',
    'dashboard:doSortAndFilter': 'dashboard:doSortAndFilter',
    'dashboard:viewTypeChanged': 'dashboard:viewTypeChanged',
    'dashboard:cardActivate': 'dashboard:cardActivate',
    'dashboard:userRemoved': 'dashboard:userRemoved',
    'dashboard:cards:updated': 'dashboard:cards:updated',
    'dashboards:loading': 'dashboards:loading',
    'dashboards:loaded': 'dashboards:loaded',
    'gannt:zoomed': 'gantt:zoomed',
    'gantt:dragged': 'gantt:dragged',
    'gantt:subCards:check': 'gantt:subCards:check',
    'gantt:card:dateChange': 'gantt:card:dateChange',
    'gantt:card:beforeDateChange': 'gantt:card:beforeDateChange',
    'card:change:startAndDueDate': 'card:change:startAndDueDate',
    'card:force:render': 'card:force:render',
    'card:formClosed': 'card:formClosed',
    'card:multiFormOpened': 'card:multiFormOpened',
    'card:multiFormClosed': 'card:multiFormClosed',
    'card:driveDocsSelected': 'card:driveDocsSelected',
    'card:deleted': 'card:deleted',
    'card:changed': 'card:changed',
    'card:updating': 'card:updating',
    'card:change:name': 'card:change:name',
    'card:remove:subCard': 'card:remove:subCard',
    'card:remove:epic': 'card:remove:epic',
    'card:add:epic': 'card:add:epic',
    'card:epic:hovered': 'card:epic:hovered',
    'card:commentChanged': 'card:commentChanged',
    'card:relatedCountChange': 'card:relatedCountChange',
    'card:rendered': 'card:rendered',
    'card:listChanged': 'card:listChanged',
    'card:moveListTo:selected': 'card:moveListTo:selected',
    'card:moveListTo:clicked': 'card:moveListTo:clicked',
    'cards_collection:deleted': 'cards_collection:deleted',
    'cards:changeSortStrategy': 'cards:changeSortStrategy',
    'list:formOpened': 'list:formOpened',
    'list:formClosed': 'list:formClosed',
    'list:cardCreating': 'list:cardCreating',
    'list:cardCreated': 'list:cardCreated',
    'list:domReordered': 'list:domReordered',
    'list:deleted': 'list:deleted',
    'list:cardNumberChanged': 'list:cardNumberChanged',
    'list:cardMovedOut': 'list:cardMovedOut',
    'list:clearCurrentCard': 'list:clearCurrentCard',
    'stat:cardCreated': 'stat:cardCreated',
    'stat:cardMoved': 'stat:cardMoved',
    'stat:listCreated': 'stat:listCreated',
    'stat:dashboardCreated': 'stat:dashboardCreated',
    'stat:dashboardCreatedFromDrive': 'stat:dashboardCreatedFromDrive',
    'timer:proceed': 'timer:proceed',
    'timer:cardNameChanged': 'timer:cardNameChanged',
    'timer:reset': 'timer:reset',
    'timer:delete': 'timer:delete',
    'timer:check': 'timer:check',
    'tab:close': 'tab:close',
    'feedback:show': 'feedback:show',
    'feedback:hide': 'feedback:hide',

    'secondPanel:show': 'secondPanel:show',
    'secondPanel:close': 'secondPanel:close',
    
    'related:cardClick': 'related:cardClick',
    'related:save': 'related:save',
    'related:markCard': 'related:markCard',

    'notify:send': 'notify:send',
    'notify:show': 'notify:show',
    'notify:update': 'notify:update',

    'suggester:hide': 'suggester:hide',
    'notificationFilter:active': 'notificationFilter:active',
    'init:epicCard': 'init:epicCard',

    'sign-in:hide': 'sign-in:hide',
    'sign-in:first': 'sign-in:first',
    'sign-in:trelled': 'sign-in:trelled',
    'sign-in:fail': 'sign-in:fail',
    'registration:unmount': 'registration:unmount',

    'react:state': 'react:state',
    'subscriptionChanged:new': 'subscriptionChanged:new',
    'subscriptionChanged:itemsChanged': 'subscriptionChanged:itemsChanged',
    'subscriptionChanged:statusChanged': 'subscriptionChanged:statusChanged',

    'user:timeFormatChanged': 'user:timeFormatChanged',
    'feedBack:close': 'feedBack:close',

    'scroller:click': 'scroller:click',
    'scroller:start': 'scroller:start',
    'stopPropagation': 'stopPropagation',

    'comment:created': 'comment:created',
    'comment:edit': 'comment:edit',
    'comment:keydown': 'comment:keydown',

    'staredNotifyHelper:reset': 'staredNotifyHelper:reset',
};
