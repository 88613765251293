import { IApplicationState, TCardId, TRecurringTaskId } from '../../../types/types';
import { IRestRecurringTask } from '../../../types/rest/recurringTask/IRestRecurringTask';
import { getCardRecurringTasks } from './getCardRecurringTasks';

export const getCardRecurringTask = (
    state: IApplicationState,
    cardId: TCardId,
    taskId: TRecurringTaskId
): IRestRecurringTask => {
    const recurringTasks = getCardRecurringTasks(state, cardId);
    return recurringTasks.find((task) => task.id === taskId);
}
