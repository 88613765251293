import * as React from 'react';
import { ERestActivityPropertyActionType } from '../../../../../../../types/rest/activity/IRestActivityBase';
import { IRestCardCommonActivity } from '../../../../../../../types/rest/activity/IRestCardCommonActivity';
import { IActivityProps } from '../../../../boardDetails/activity/TabActivity/types';
import { TabActivityContext } from '../../../../boardDetails/activity/TabActivity/components/TabActivity/constants';
import { TActivity } from '../../../../../../../store/activities/types';
import { CardProgress } from '../../../../../base/components/CardProgress/components/CardProgress';
import { ECardProgressSize } from '../../../../../base/components/CardProgress/components/types';
import {
    ActivityItemBody
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemBody/ActivityItemBody';
import { ActivityHeader } from '../../../../boardDetails/activity/TabActivity/components/ActivityHeader/ActivityHeader';
import { CLASS_ACTIVITY_ITEM } from '../../../../boardDetails/activity/TabActivity/components/constants';
import './_CardActivityProcessingStatus.scss';

export function CardActivityProcessingStatus({
    activity
}: IActivityProps<IRestCardCommonActivity>) {
    const { onOpenCard } = React.useContext(TabActivityContext);
    const { label, card } = activity as TActivity;
    return (
        <ActivityItemBody
            header={<ActivityHeader
                className={CLASS_ACTIVITY_ITEM + '--processing'}
                icon={<CardProgress
                    tooltip={null}
                    size={ECardProgressSize.BASE}
                    progress={activity.propertyActionType === ERestActivityPropertyActionType.DELETE ? 0 : 100}
                />}
                label={label}
            />}
            activity={activity}
            onClick={() => onOpenCard(card.id, activity.id)}
        />
    );
};
