import * as React from 'react';
import { AsidePanelContext } from '../../../../../asidePanel/components/AsidePanel/constants';
import { CLASS_BOARD_DETAILS_SECTION } from '../../../../constants';
import { SettingsSection } from '../../../../../../base/components/SettingsSection/SettingsSection';
import { StatusListsHOC } from '../../hocs/StatusListsHOC/StatusListsHOC';
import { IStatusListsSectionProps } from './types';
import { STATUS_LISTS_TITLE } from '../../constants';

export function StatusListsSection ({
    isBasicFeature
}: IStatusListsSectionProps) {
    const { boardId } = React.useContext(AsidePanelContext);
    const className = CLASS_BOARD_DETAILS_SECTION + ' ' + CLASS_BOARD_DETAILS_SECTION + '--statuslists';

    return (
        <SettingsSection
            className={className}
            title={STATUS_LISTS_TITLE}
            isBasicFeature={isBasicFeature}
        >
            <StatusListsHOC boardId={boardId} />
        </SettingsSection>
    );
};
