import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TBoardId, TCardId, TCustomPropertyId } from '../../../../../../../../types/types';
import { confirmDeleteProperty } from '../effects/confirmDeleteProperty';

export const onConfirmDelete = (
    boardId: TBoardId,
    cardId: TCardId,
    customPropertyId: TCustomPropertyId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(confirmDeleteProperty(boardId, cardId, customPropertyId));
    };
    return action;
};
