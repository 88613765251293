import { getMessages } from '../../../../../../../store/constants';

export const NOTIFY_TITLE_ALREADY_NOTIFIED = getMessages().getText('notify.title.already');
export const NOTIFY_TITLE_NOONE_NOTIFIED = getMessages().getText('notify.title.noone');
export const NOTIFY_TITLE_OTHER = getMessages().getText('notify.title.other');
export const NOTIFY_TITLE_OTHER_NOONE = getMessages().getText('notify.title.other.noone');
export const NOTIFY_BUTTON_KEYS = ['notify.users.button1', 'notify.users.button2'];
export const NOTIFY_PREVIOUS = getMessages().getText('notify.previous');
export const NOTIFY_PREVIOUS_ACTIVITY = getMessages().getText('notify.previous.activity');
export const NOTIFY_SUCCESS_TITLE = getMessages().getText('notify.success.title');
export const NOTIFY_REJECTED_TITLE = getMessages().getText('notify.rejected.title');
export const NOTIFY_REJECTED_TEXT = getMessages().getText('notify.rejected.text');

export const BUTTON_HOW_IT_WORKS = getMessages().getText('common.how');
export const BUTTON_HOW_IT_WORKS_LINK = 'https://www.kanbanchi.com/what-are-additional-notifications-in-kanbanchi';
