import { IGoogleDrive, TGoogleDriveId } from '../../types';

export const getDrivesIds = (
    drives: IGoogleDrive[]
): TGoogleDriveId[] => {
    const drivesIds = drives.map((drive) => {
        return drive.id;
    });
    return drivesIds;
};
