import { SelectListItem, Tooltip } from 'kui';
import * as React from 'react';
import { escape } from 'underscore';
import { LIST_LABEL } from 'app/const';
import { ISearchedCard } from './types';
import './_SearchedCard.scss';

export function SearchedCard ({
    className,
    img,
    text,
    textSub,
    value,
    parentValue = '',
}: ISearchedCard) {
    const classItem = 'top-bar__filter-item';
    const classText = 'top-bar__filter-item-text';
    const classSub = 'top-bar__filter-item-sub';

    return (
        <SelectListItem
            className={`
                ${className}
                ${classItem}
            `}
            key={parentValue + value}
            icon={img}
            listLabel={LIST_LABEL + textSub}
            value={value}
        >
            <Tooltip
                isNoWrap // иначе при горизонтальном скроле доски тултип скукоживается
                value={escape(textSub)}
            >
                <span className={classSub}>{textSub}</span>
            </Tooltip>
            <Tooltip
                isNoWrap // иначе при горизонтальном скроле доски тултип скукоживается
                value={escape(text)}
            >
                <span className={classText}>{text}</span>
            </Tooltip>
        </SelectListItem>
    );
}
