import { AT_IS_LOADING_SET } from './constants';
import { IIsLoadingSetAction } from './types';

export const isLoadingSetAction = (
    isLoading: boolean
): IIsLoadingSetAction => {
    return {
        type: AT_IS_LOADING_SET,
        isLoading,
    };
};
