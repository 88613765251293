import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../../types/types';
import { IDatesHOCProps } from './types';
import { IDatesEvents, IDatesFields } from '../../components/Dates/types';
import { ESettingsSectionType } from '../../../../../../base/components/SettingsSection/types';
import { getSectionType } from '../../../../selectors/getSectionType';
import { onDateChange } from './events/onDateChange';
import { Dates } from '../../components/Dates/Dates';
import {
    getBoardMetaDefaultDueDateTime
} from '../../../../../../../../store/model/selectors/getBoardMetaDefaultDueDateTime';
import {
    getBoardMetaDefaultStartDateTime
} from '../../../../../../../../store/model/selectors/getBoardMetaDefaultStartDateTime';
import { onAutoStartDateToggle } from './events/onAutoStartDateToggle';
import { getBoardMetaAutoStartDate } from '../../../../../../../../store/model/selectors/getBoardMetaAutoStartDate';
import {
    SegmentBoardAutoStartSourceValue
} from '../../../../../../../../middlewares/segment/trackEntities/boardEvents';

const mapStateToProps = (
    state: IApplicationState,
    { boardId }: IDatesHOCProps
): IDatesFields => {
    const sectionType = getSectionType(state, boardId, null, null, null);
    const isReadonly = sectionType === ESettingsSectionType.DISALLOW || sectionType === ESettingsSectionType.READONLY;
    return {
        startDate: getBoardMetaDefaultStartDateTime(state, boardId),
        dueDate: getBoardMetaDefaultDueDateTime(state, boardId),
        isReadonly,
        isAutoStartDate: getBoardMetaAutoStartDate(state, boardId)
    }
};

const mapDispatchToProps = (
    dispatch: any,
    { boardId }: IDatesHOCProps
): IDatesEvents => ({
    onDateChange: (value, type) => dispatch(onDateChange(boardId, value, type)),
    onAutoStartDateToggle: () => dispatch(onAutoStartDateToggle(boardId, SegmentBoardAutoStartSourceValue.BOARD_SETTINGS))
});

export const DatesHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(Dates);

DatesHOC.displayName = 'DatesHOC';
