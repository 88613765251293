import { IApplicationState, TCardId } from '../../../types/types';
import { getCard } from './getCard';
import { IComment } from '../card/types/IComment';

export const getCardComments = (
    state: IApplicationState,
    cardId: TCardId,
): IComment[] => {
    const card = getCard(state, cardId);
    if (
        !card ||
        !card.comments ||
        !card.comments.length
    ) return [];

    return card.comments;
};
