import { TBoardId } from '../../../../../../types/types';

export interface ICommon {
    currentBoardId: TBoardId;
    isBlocked: boolean;
    isOpened: boolean;
    loadStatus: {
        [tabKey: string]: TTabLoadStatus;
    };
}

export enum TTabLoadStatus {
    EMPTY = 'EMPTY',
    LOADING = 'LOADING',
    COMPLETED = 'COMPLETED'
}
