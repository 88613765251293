import { Dispatch } from '../../../../../types/actions';
import { SnackbarVariant, ISnackbarControlModel } from '../../store/types';
import { addUpdateSnackbarWithBubble } from '../addUpdateSnackbarWithBubble/addUpdateSnackbarWithBubble';
import { SNACKBAR_ID_REINSTALL } from '../constants';
import { getMessages } from '../../../../../store/constants';
import { IGetState } from 'app/types/types';

export const snackbarErrorReinstall =
() => {
    return (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        const text = getMessages().getText('error.button.reinstall.message');
        const link = getMessages().getText('error.button.reinstall.link');

        const controls: ISnackbarControlModel[] = [
            {
                action: () => {
                    location.href = link;
                },
                title: getMessages().getText('error.button.reinstall')
            }
        ];
        dispatch(addUpdateSnackbarWithBubble({
            id: SNACKBAR_ID_REINSTALL,
            controls: controls,
            text,
            title: null,
            variant: SnackbarVariant.ERROR
        }));
    }
};
