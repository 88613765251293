import { ICard } from '../../../../../../../../../store/model/card/types';
import { getListMetaCardAging } from '../../../../../../../../../store/model/selectors/getListMetaCardAging';
import { getListCardAgingOpacity } from '../../../../../../../../../store/model/list/selectors/getListCardAgingOpacity';
import {
    getBoardCardAgingOpacity
} from '../../../../../../../../../store/model/board/selectors/getBoardCardAgingOpacity';
import { IApplicationState } from '../../../../../../../../../types/types';
import { getBoardIdByCardId } from '../../../../../../../../../store/model/selectors/getBoardIdByCardId';
import { TStatus } from '../../../../../../../../../types/model';

export const getIsCardAgingOpacity = (
    state: IApplicationState,
    card: ICard
): boolean => {
    const { listId, status } = card;

    if (status === TStatus.STATUS_SERVICE_TEMPLATE) return;

    const boardId = getBoardIdByCardId(state, card.id);

    const listCardAging = getListMetaCardAging(state, listId);
    const isListCardAgingOpacity = getListCardAgingOpacity(state, listId);
    const isBoardCardAgingOpacity = getBoardCardAgingOpacity(state, boardId);

    return listCardAging ? isListCardAgingOpacity : isBoardCardAgingOpacity;
}
