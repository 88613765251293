import { root } from 'app/store/constants';
import {Dispatch, ThunkAction} from '../../../../../../../types/actions';
import { TBoardId } from '../../../../../../../types/types';
import { boardUpdatePermissions } from 'app/rest/effects/board/boardUpdatePermissions';

export const onClose = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        const sharingDialog = root.App.controller.mainView.sharingDialog;
        if (sharingDialog) sharingDialog.remove();

        dispatch(boardUpdatePermissions(boardId));
    };
    return action;
};
