import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState } from '../../../../../../../types/types';
import { quickStartGuideStepSegmentEvent } from '../../../../../../../middlewares/segment/trackEntities/userEvents';

export const onStepClick = (
    step: string,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(quickStartGuideStepSegmentEvent(step));
    };
    return action;
};
