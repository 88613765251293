import { connect } from 'react-redux';
import { IApplicationState, TThemeId } from '../../../../../../../../types/types';
import { BoardLibThemes } from '../../components/BoardLibThemes/BoardLibThemes';
import { IBoardLibThemesEvents, IBoardLibThemesFields } from '../../components/BoardLibThemes/types';
import { onDidMount } from './events/onDidMount';
import { IBoardLibThemesHOCProps } from './types';
import {
    getLibThemesWithDefault
} from '../../../../../../../../store/model/libThemes/selectors/getLibThemesWithDefault';
import { getBoard } from '../../../../../../../../store/model/selectors/getBoardById';
import { onThemeClick } from './events/onThemeClick';
import { onClickDisallow } from './events/onClickDisallow';
import { MY_WORK_BOARD_ID } from '../../../../../../main/myWorkView/constants';
import { getMyWorkTheme } from '../../../../../../main/myWorkView/selectors/getMyWorkTheme';
import {
    isViewTypeWithBackground
} from 'app/view/react_components/typeSwitcher/store/selectors/isViewTypeWithBackground';
import { ESettingsSectionType } from 'app/view/react_components/base/components/SettingsSection/types';
import { getSectionType } from '../../../../../../aside_panel/boardDetails/selectors/getSectionType';
import { onClickSwitchToKanban } from './events/onClickSwitchToKanban';

const mapStateToProps = (
    state: IApplicationState,
    ownProps: IBoardLibThemesHOCProps
): IBoardLibThemesFields => {
    const { boardId } = ownProps;
    const themes = getLibThemesWithDefault(state);
    let activeId;
    const isMyWork = boardId === MY_WORK_BOARD_ID;
    let sectionType = isMyWork ? null
        : getSectionType(state, boardId, null, null, 'allowChangeBackground');
    const isViewWithoutBackground = !isViewTypeWithBackground(state, boardId);
    if (sectionType === ESettingsSectionType.DEFAULT && isViewWithoutBackground) {
        sectionType = ESettingsSectionType.READONLY;
    }
    if (isMyWork) {
        const myWorkTheme = getMyWorkTheme(state);
        activeId = myWorkTheme && myWorkTheme.id;
    } else {
        const board = getBoard(state, boardId);
        activeId = board.theme && board.theme.id;
    }
    return {
        activeId: activeId || 0,
        isMyWork,
        isViewWithoutBackground,
        sectionType,
        themes,
    }
};

const mapDispatchToProps = (
    dispatch: any,
    ownProps: IBoardLibThemesHOCProps
): IBoardLibThemesEvents => {
    const { boardId } = ownProps;
    return {
        onClickDisallow: () => dispatch(onClickDisallow()),
        onClickSwitchToKanban: () => dispatch(onClickSwitchToKanban()),
        onDidMount: () => dispatch(onDidMount()),
        onThemeClick: (themeId: TThemeId) => dispatch(onThemeClick(boardId, themeId))
    }
};

export const BoardLibThemesHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(BoardLibThemes);

BoardLibThemesHOC.displayName = 'BoardLibThemesHOC';
