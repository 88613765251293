import * as React from 'react';
import { AsidePanelActionsButton } from '../../../..';
import { BOARD_BUTTON_MOVE_TO, CLASS_BOARD_MOVE_TO, DRIVES_COLUMN_TITLE, EMPTY_MOVE_TO } from './constants';
import { IMoveToButtonProps } from './types';
import { SettingsSection } from '../../../../../base/components/SettingsSection/SettingsSection';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import './_MoveToButton.scss';
import { MoveToColumn } from '../../../../../base/components/MoveTo/components/MoveToColumn/MoveToColumn';
import { LoaderBlock } from 'kui';
import { EmptyDummy } from '../../../../../base/components/EmptyDummy/EmptyDummy';
import { getEmptyDummy } from '../../../../../dialogs/openBoards/containers/TabBoards/constants';

export function MoveToButton ({
    isShow,
    planBlocker,
    scopesBlocker,
    onMoveTo,
    onLoadItems
}: IMoveToButtonProps) {
    if (!isShow) return null;

    const classDropdown = CLASS_BOARD_MOVE_TO + '--dropdown';
    const classSection = CLASS_BOARD_MOVE_TO + '--section';
    const classEmpty = CLASS_BOARD_MOVE_TO + '--empty';
    const classBlockLoader = CLASS_BOARD_MOVE_TO + '--block-loader';
    const classEmptyDummy = CLASS_BOARD_MOVE_TO + '__empty-dummy'

    const [isOpened, setIsOpened] = React.useState(null);
    const [items, setItems] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);

    const onOpen = () => setIsOpened(true);
    const onClose = () => setIsOpened(false);

    const onBoardClick = (index: number) => {
        const driveId = items[index].id;
        onMoveTo(driveId).then(() => {
            onClose();
        });
    };

    React.useEffect(() => {
        if (isLoading || !isOpened) return;

        setIsLoading(true);
        onLoadItems().then((result) => {
            setItems(result);
            setIsLoading(false);
        });
    }, [isOpened, scopesBlocker, planBlocker]);

    return (
        <AsidePanelActionsButton
            dropdownClassName={classDropdown}
            title={BOARD_BUTTON_MOVE_TO}
            isOpened={isOpened}
            onOpen={onOpen}
            onClose={onClose}
        >
            <SettingsSection
                className={classSection}
                title={BOARD_BUTTON_MOVE_TO}
                sectionType={ESettingsSectionType.POPUP}
                isOpened={true}
                onAddClosed={onClose}
            >
                {isLoading
                    ? <LoaderBlock className={classBlockLoader}/>
                    : items && items.length
                        ? <MoveToColumn
                            title={DRIVES_COLUMN_TITLE}
                            items={items}
                            typeColumn={DRIVES_COLUMN_TITLE}
                            onClick={onBoardClick}
                        />
                        : planBlocker ?
                            <EmptyDummy
                                className={classEmptyDummy}
                                {...getEmptyDummy()[planBlocker]}
                            />
                            : scopesBlocker ?
                                <EmptyDummy
                                    className={classEmptyDummy}
                                    {...getEmptyDummy()[scopesBlocker]}
                                />
                                :<p className={classEmpty}>
                                    {EMPTY_MOVE_TO}
                                </p>
                }
            </SettingsSection>
        </AsidePanelActionsButton>
    );
}
