export interface ISignIn {
    error: ISignInError;
    isLoading?: boolean;
    isShow?: boolean;
};

export enum ESignInErrorStatus {
    GOOGLE_SIDE = 'GOOGLE_SIDE',
    GAPI_CONNECT = 'GAPI_CONNECT',
    INTERNAL = 'INTERNAL',
};

export interface ISignInError {
    details?: string;
    status?: ESignInErrorStatus;
};
