import { TBoardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { fetchHandler } from '../../../../util/fetchHandler';
import { Util } from '../../../../util/util';
import { REST_BOARD_THEME } from '../../../constants';
import { getRestHeadersPatch } from '../../../helpers/getRestHeadersHelper';
import { IRestTheme } from '../../../../types/rest/IRestTheme';
import { IBoardTheme } from '../../../../store/model/board/types';

export const patchRest = (
    boardId: TBoardId,
    theme: IBoardTheme
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        return fetchHandler<IBoardTheme>(
            Util.getApiUrl(REST_BOARD_THEME + boardId), {
                ...getRestHeadersPatch(),
                body: JSON.stringify(theme)
            }
        ).then((response: IRestTheme) => {
            return response;
        });
    };
    return action;
};
