import { compareStrings } from 'app/store/model/helpers/comporators/compareStrings';
import { IRecentBoard } from 'app/types/rest/IUserMeta';
import { IApplicationState } from 'app/types/types';
import { clearMarkdownInline } from 'app/view/react_components/base/helpers/clearMarkdownHelper';
import { getBoardsByTabKey } from 'app/view/react_components/dialogs/openBoards/store/boards/selectors/getBoardsByTabKey';
import { IBoard } from 'app/view/react_components/dialogs/openBoards/store/boards/types';
import { TTabKey } from 'app/view/react_components/dialogs/openBoards/store/types';
import { getUserRecentBoards } from '../../../../../../../../store/model/authUser/selectors/getUserRecentBoards';
import { IBoards } from '../../../../../../../../store/model/boards/types';
import { getBoards } from '../../../../../../../../store/model/selectors/getBoards';
import { getUserStarredBoards } from 'app/store/model/authUser/selectors/getUserStarredBoards';
import { createDeepEqualSelector } from 'app/view/react_components/helpers/memoizeHelper';

type TgetStarredBoards = (
    state: IApplicationState,
) => IRecentBoard[];

const getStarredBoardsSelector = (
    openBoards: IBoard[],
    boards: IBoards,
    userRecentBoards: IRecentBoard[],
    userStarredBoards: IRecentBoard[],
): IRecentBoard[] => {
    let starredBoards: IRecentBoard[] = [];

    if (openBoards.length) starredBoards = openBoards.reduce((starredBoards, board) => {
        if (!!board.id && !boards[board.id]) { // отсюда берём только доски, которых нет в сторе
            let starredBoard = {...board};

            /**
             * редкий случай, когда юзер уже открывал доску, но logoColor не сохранён, т.к. у юзера нет прав (readonly)
             * тогда logoColor может быть в userRecentBoard
             */
            const userRecentBoard = userRecentBoards.find(item => item.id === board.id);
            if (userRecentBoard) {
                if (!board.logoColor) {
                    starredBoard.logoColor = userRecentBoard.logoColor;
                }
                if (!board.logoIcon) {
                    starredBoard.logoIcon = userRecentBoard.logoIcon;
                }
                if (!board.logo) {
                    starredBoard.logo = userRecentBoard.logo;
                }
            }

            starredBoards.push(starredBoard as IRecentBoard);
        }
        return starredBoards;
    }, []);

    for (let id in boards) { // все starred которые есть в сторе, возьмём из стора, там самая свежая инфа
        if (boards[id].starred) {
            starredBoards.push(boards[id] as IRecentBoard);
        }
    }

    starredBoards.sort((b1, b2) => {
        return compareStrings(clearMarkdownInline(b1.name), clearMarkdownInline(b2.name));
    });

    /**
     * дальше костыли из KNB-3601
     * В user.meta.starredBoards сохраняется только при сортировке.
     * т.е. там массив не актуальный, он нужен только чтоб отсортировать по нему реальный массив starredBoards.
     * Достаточно было бы сохранять массив айдишников.
     * Если хотим в юзере актуальный массив любимых досочек, нужно обновлять его при всех изменениях:
     * starred, name, logo, logoColor
     */
    if (userStarredBoards && userStarredBoards.length &&
        starredBoards && starredBoards.length // нет смысла показывать userStarredBoards, пока не загружены starredBoards
    ) {
        let starredBoardsSorted = starredBoards.filter( // сначала доски, которых нет в userStarredBoards
            a => !userStarredBoards.find(b => a.id === b.id)
        );

        userStarredBoards.forEach(userStarredBoard => { // потом доски, отсортированные по userStarredBoards
            let starredBoard = starredBoards.find(starredBoard => starredBoard.id === userStarredBoard.id);
            if (starredBoard) {
                starredBoardsSorted.push(starredBoard);
            }
        });
        starredBoards = starredBoardsSorted;
    }

    return starredBoards;
};

export const getStarredBoardsCreateSelector = (
): TgetStarredBoards => createDeepEqualSelector(
    (state: IApplicationState)=>getBoardsByTabKey(state, TTabKey.starred),
    getBoards,
    getUserRecentBoards,
    getUserStarredBoards,
    getStarredBoardsSelector
);
