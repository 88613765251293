import * as React from 'react';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { TimingTabHOC } from '../../hocs/TimingTabHOC/TimingTabHOC';

export function TabTimingContext() {
    const { boardId, cardId } = React.useContext(AsidePanelContext);

    return <TimingTabHOC
        boardId={boardId}
        cardId={cardId}
    />;
}
