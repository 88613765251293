import { createDeepEqualSelector } from '../../../../../helpers/memoizeHelper';
import { EMPTY_ARRAY, IApplicationState, TBoardId, TCardId, TCustomPropertyId } from '../../../../../../../types/types';
import {
    ECustomPropertyType,
    IBoardCardCustomProperties,
    ICardCustomProperties
} from '../../../../../../../store/customProperties/types';
import { getCardCustomProperties } from '../../../../../../../store/model/selectors/getCardCustomProperties';
import {
    getBoardCardCustomProperties
} from '../../../../../../../store/model/board/selectors/getBoardCardCustomProperties';
import { ICustomPropertiesElement } from '../../components/CustomPropertiesElements/types';
import {
    getCardPinnedCustomPropertiesIds
} from '../../../../../../../store/model/selectors/getCardPinnedCustomPropertiesIds';
import {
    getBoardCardRequiredCustomPropertyIds
} from '../../../../../../../store/model/board/selectors/getBoardCardRequiredCustomPropertyIds';

type TgetCustomProperties = (
    state: IApplicationState,
    boardId: TBoardId,
    cardId: TCardId,
    isKnbPinned?: boolean, // для канбан карты
) => ICustomPropertiesElement[];

const getCustomPropertiesSelector = (
    boardCustomProperties: IBoardCardCustomProperties,
    isRequiredCustomPropertyIds: Set<TCustomPropertyId>,
    cardCustomProperties: ICardCustomProperties,
    customPropertiesPinnedOnCard: TCustomPropertyId[],
    isKnbPinned?: boolean,
): ICustomPropertiesElement[] => {
    if (isKnbPinned && !customPropertiesPinnedOnCard.length) return EMPTY_ARRAY;

    const customProperties: ICustomPropertiesElement[] = [];

    for (let id in boardCustomProperties) {
        const property = boardCustomProperties[id];
        let value = cardCustomProperties[id] ? cardCustomProperties[id].value : null;
        const pinned = cardCustomProperties[id] && cardCustomProperties[id].pinned;
        const pinnedOnCard = customPropertiesPinnedOnCard.includes(id);
        const isRequired = isRequiredCustomPropertyIds.has(id);

        if (isKnbPinned && !pinnedOnCard) continue;

        if (value &&
            property.type === ECustomPropertyType.SELECT && (
                !property.options ||
                !property.options.find(option => option.id === value)
            )
        ) {
            value = null; // опция удалена
        }

        customProperties.push({
            ...property,
            orderNumber: property.orderNumber || 0,
            isRequired,
            value,
            pinned,
            pinnedOnCard
        });
    }
    customProperties.sort((prop1, prop2) => {
        return prop2.orderNumber - prop1.orderNumber; // больший order сверху
    });
    return customProperties;
};

export const getCustomPropertiesCreateSelector = (
): TgetCustomProperties => createDeepEqualSelector(
    getBoardCardCustomProperties,
    getBoardCardRequiredCustomPropertyIds,
    (state: IApplicationState, boardId: TBoardId, cardId: TCardId) => getCardCustomProperties(state, cardId),
    (state: IApplicationState, boardId: TBoardId, cardId: TCardId) => getCardPinnedCustomPropertiesIds(state, cardId),
    (state: IApplicationState, boardId: TBoardId, cardId: TCardId, isKnbPinned: boolean) => isKnbPinned,
    getCustomPropertiesSelector,
);

export const getCustomProperties: TgetCustomProperties = getCustomPropertiesCreateSelector();
