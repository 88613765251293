import React from "react";
import { Provider } from "react-redux";
import store from "../../store/configureStore";
import { ListGroupingDashboardView } from './team_workload_dashboard';
import { snackbarErrorReload } from "../react_components/snackbarsStack";
import { segmentTrackAction } from "app/middlewares/segment";
import { SegmentErrorEvent, SegmentErrorOptions } from "app/middlewares/segment/trackEntities/errorEvents";

export const CardColorsDashboardView = ListGroupingDashboardView.extend({
    onImportFail: function() {
        dispatch(snackbarErrorReload(false));
        dispatch(segmentTrackAction(SegmentErrorEvent.LAZY_IMPORT_ERROR, {
            name: SegmentErrorOptions.SOURCE,
            value: 'ListGroupCardColors'
        }));
    },

    render: function() {
        const ImportElement = React.lazy(() =>
            import(/* webpackChunkName: "kanban" */ '../react_components/teamWorkload/teamWorkloadView/hocs/CardColorsViewHOC/CardColorsViewHOC')
                .then(module => ({default: module.CardColorsViewHOC}))
                .catch(() => this.onImportFail())
        );
        this.root.render(
            <Provider store={store}>
                <ImportElement boardId={this.boardId} />
            </Provider>
        );
        return this;
    },

});
