import { REST_CARD } from '../../../../constants';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { fetchHandler } from '../../../../../util/fetchHandler';
import { IRestCard } from '../../../../../types/rest/IRestCard';
import Util from '../../../../../util/util';
import { getRestHeadersPost } from '../../../../helpers/getRestHeadersHelper';
import { IGetState, TCardId } from '../../../../../types/types';

export const generateCardNumberPostRest = (
    cardId: TCardId,
    abortController?: AbortController
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return fetchHandler<IRestCard>(
            Util.getApiUrl(REST_CARD + cardId + '/generateCardNumber'), {
                ...getRestHeadersPost(),
                signal: abortController && abortController.signal
            }
        );
    };
    return action;
};
