import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../types/types';
import { ITimeTrackerEvents, ITimeTrackerFields } from '../../components/TimeTracker/types';
import { TimeTracker } from '../../components/TimeTracker/TimeTracker';
import { onButtonClick } from './events/onButtonClick';
import { getTimeTrackerState } from '../../../aside_panel/cardDetails/TabTimingSection/TimeTracker/store/selectors/getTimeTrackerState';
import { onCardNameClick } from './events/onCardNameClick';
import { onChangeTime } from './events/onChangeTime';
import { ITimeTrackerHOCProps } from './types';
import { getBoardWorkHoursInDay } from '../../../../../store/model/selectors/getBoardWorkHoursInDay';
import { onDidMount } from './events/onDidMount';
import { FAKE_CARD_ID_FOR_WIDGET_PRESENTATION } from 'app/const';
import { onClickDisallow } from './events/onClickDisallow';

const mapStateToProps = (
    state: IApplicationState,
    { cardId }: ITimeTrackerHOCProps
): ITimeTrackerFields => {
    const timeTracker = getTimeTrackerState(state);
    if (!timeTracker) return {
        isEditCard: !!cardId,
    };

    const {
        boardName,
        cardName,
        start,
        cardId: ttCardId,
        boardId,
    } = getTimeTrackerState(state);
    let workHoursInDay = getBoardWorkHoursInDay(state, boardId);

    if (ttCardId === FAKE_CARD_ID_FOR_WIDGET_PRESENTATION) {
        workHoursInDay = 8;
    }

    if (!cardId) return { // виджет
        boardName: boardName,
        cardName: cardName,
        cardId: ttCardId,
        start,
        workHoursInDay,
    };

    const isEditTrackingCard = cardId === ttCardId;

    return { // cardDetails
        isEditCard: true,
        isEditTrackingCard,
        start: isEditTrackingCard ? start : null,
        workHoursInDay
    };
};

const mapDispatchToProps = (
    dispatch: any,
    { cardId }: ITimeTrackerHOCProps
): ITimeTrackerEvents => ({
    onClickButton: () => dispatch(onButtonClick(cardId)),
    onCardNameClick: () => dispatch(onCardNameClick()),
    onChange: (time) => dispatch(onChangeTime(time)),
    onDidMount: !cardId ? () => dispatch(onDidMount()) : null,
    onClickDisallow: () => dispatch(onClickDisallow())
})

export const TimeTrackerHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(TimeTracker);

TimeTrackerHOC.displayName = 'TimeTrackerHOC';
