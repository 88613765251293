import { TBoardId } from '../../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { updateBoardName } from '../../../../../../../../../rest/effects/board/updateBoardName';
import { renameBoardFromDetailsSegmentEvent } from '../../../../../../../dialogs/openBoards/effects/segmentEvents';

export const onChange = (
    boardId: TBoardId,
    name: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        dispatch(renameBoardFromDetailsSegmentEvent());
        return dispatch(updateBoardName(boardId, name));
    };
    return action;
};
