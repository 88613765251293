import { TBoardId } from 'app/types/types';

export const getBoardIdsOpenedDescription = (key: string) => {
    let localStorageDate: any = localStorage.getItem(key);
    localStorageDate = localStorageDate ? JSON.parse(localStorageDate) : [];
    return localStorageDate;
}

export const setBoardIdsOpenedDescription = (key: string, boardIdsOpenedDescription: TBoardId[]) => {
    localStorage.setItem(key, JSON.stringify(boardIdsOpenedDescription));
}
