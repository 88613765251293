import { IGetState, TCardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { uploadFiles } from '../effects/uploadFiles';
import { attachmentPastedSegmentEvent, attachmentPasteErrorSegmentEvent } from '../effects/segmentEffects';
import { SegmentCardPasteFileTarget } from '../../../../../../../../middlewares/segment/trackEntities/cardEvents';

export const onPasteFile = (
    cardId: TCardId,
    files: File[],
    onProgress: (percent: number) => void
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (!files) {
            return dispatch(attachmentPasteErrorSegmentEvent(SegmentCardPasteFileTarget.CARD));
        }

        dispatch(attachmentPastedSegmentEvent(SegmentCardPasteFileTarget.CARD));
        return dispatch(uploadFiles(cardId, files, onProgress, true));
    };
    return action;
};
