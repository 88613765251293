import { IApplicationState, TCardId } from '../../../../../../../../types/types';
import { getActiveCardId } from '../../../../../../../../store/router/selectors/getActiveCardId';
import { vent } from '../../../../../../../../store/constants';

export const closeAsidePanelIfNeeded = (
    state: IApplicationState,
    cardId: TCardId
) => {
    const currentCardId = getActiveCardId(state);
    if (currentCardId !== cardId) {
        vent.trigger(vent['aside-panel:hide']);
    }
}
