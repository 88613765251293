import * as React from 'react';
import { ICardChecklistCopyChecklistSelectProps } from './types';
import { CLASS_CARD_DETAILS } from '../../../constants';
import './_CardChecklistCopyChecklistSelect.scss';
import { Select } from 'kui';

export function CardChecklistCopyChecklistSelect({
    options,
    selectedChecklistId,
    onSelect,
    selectedCardId
}: ICardChecklistCopyChecklistSelectProps ) {
    const className = CLASS_CARD_DETAILS + '__checklist-copy-select';

    const [ selectedItemIds, setSelectedItemIds ] = React.useState([Number(options[0].value)]);
    const [ selectedItemName, setSelectedItemName ] = React.useState([options[0].text]);
    const [ selectedCard, setSelectedCard ] = React.useState(selectedCardId);

    const onSelectHandler = (event: any) => {
        if (event.target.checked) {
            setSelectedItemIds([...selectedItemIds, Number(event.target.value)]);
            setSelectedItemName([...selectedItemName, event.target.nextSibling.textContent]);
        } else {
            const newSelectedItemIds = selectedItemIds.filter(item => item !== Number(event.target.value))
            setSelectedItemIds(newSelectedItemIds)
            const newSelectedItemName = selectedItemName.filter(item => item !== event.target.nextSibling.textContent)
            setSelectedItemName(newSelectedItemName)
        }
    }

    const checkingSelectedItems = () => {
        selectedItemIds.forEach(item => {
            options.forEach(option => {
                if (item === option.value) {
                    option.active = true;
                }
            })
        })
    }

    const onClose = () => {
        onSelect(selectedItemIds)
    }

    React.useEffect(() => {
        if (selectedCard !== selectedCardId) {
            setSelectedItemIds([Number(options[0].value)])
            setSelectedItemName([options[0].text])
            setSelectedCard(selectedCardId)
        }
    },[selectedCardId])

    return (
        <Select
            active={options.findIndex(i => i.value === selectedChecklistId)}
            className={className}
            editable={false}
            icon={'checklist-circle'}
            options={options}
            variant={'arrow'}
            onChange={onSelectHandler}
            multiple
            placeholder={selectedItemName.join(', ')}
            onOpen={checkingSelectedItems}
            onClose={onClose}
        />
    );
};
