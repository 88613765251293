import * as React from 'react';
import { ERestActivityPropertyActionType } from '../../../../../../../../types/rest/activity/IRestActivityBase';
import {
    CLASS_CARD_ACTIVITY_ITEM_DESCRIPTION,
    CLASS_CARD_ACTIVITY_ITEM_DESCRIPTION_REMOVED
} from '../ActivityItemBody/constants';
import { IRestBoardCommonActivity } from '../../../../../../../../types/rest/activity/IRestBoardCommonActivity';
import { IActivityProps } from '../../types';
import { TActivity } from '../../../../../../../../store/activities/types';
import { ActivityItemBody } from '../ActivityItemBody/ActivityItemBody';
import { ActivityHeader } from '../ActivityHeader/ActivityHeader';
import { ActivityItemName } from '../ActivityItemName/ActivityItemName';

export function BoardActivityDescription({
    activity
}: IActivityProps<IRestBoardCommonActivity>) {
    const classNames = [CLASS_CARD_ACTIVITY_ITEM_DESCRIPTION];
    let description = activity.newValue;
    if (activity.propertyActionType === ERestActivityPropertyActionType.DELETE) {
        classNames.push(CLASS_CARD_ACTIVITY_ITEM_DESCRIPTION_REMOVED);
        description = activity.oldValue;
    }
    const { label } = activity as TActivity;
    return (
        <ActivityItemBody
            header={<ActivityHeader icon={'description'} label={label} />}
            activity={activity}
        >
            <ActivityItemName name={description} />
        </ActivityItemBody>
    );
}
