import * as React from 'react';
import { ICardSubcardsProps } from './types';
import { CLASS_CARD_DETAILS, CLASS_CARD_DETAILS_SECTION } from '../../../constants';
import {
    CARD_SECTION_SUBCARDS,
    CARD_SUBCARD_ADD,
    CARD_SUBCARDS_DONE,
    CARD_SUBCARDS_PIN,
    CARD_SUBCARDS_UNPIN
} from './constants';
import { CardSubcardsElements } from '../CardSubcardsElements/CardSubcardsElements';
import { SettingsSection } from '../../../../../base/components/SettingsSection/SettingsSection';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { SubcardsSearchSelectHOC } from '../../hocs/SubcardsSearchSelectHOC/SubcardsSearchSelectHOC';
import { SEARCH_SELECT_INPUT_SELECTOR } from '../../../../../base/components/SearchSelect/constants';
import { CLASS_SETTINGS_SECTION_EMPTY } from '../../../../../base/components/SettingsSection/constants';
import { Button, Icon } from 'kui';
import { getAsidePanelTooltip } from '../../../../asidePanel/helpers/getAsidePanelTooltip';
import './_CardSubcards.scss';
import { CardSubcardHideDoneButton } from '../CardSubcardHideDoneButton/CardSubcardHideDoneButton';
import { getMessages } from '../../../../../../../store/constants';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { EAsidePanelProperty } from '../../../../asidePanel/components/AsidePanel/types';
import { AnalyzeRender } from '../../../../../helpers/memoizeHelper';

//@ts-ignore
const analyzeRender = window.Settings.development ? new AnalyzeRender(`CardSubcardsSection`) : null;

export function CardSubcardsSection({
    boardId,
    boardUsers,
    boardRoles,
    cardId,
    elements,
    isDefaultProperty,
    isPinned,
    sectionType,
    onClose,
    onDelete,
    onGantt,
    onSort,
    onSubcardLink,
    onAddClicked,
    onClickDisallow,
    onPinToggle,
    onWeightChange,
    isHideDoneSubcards,
    onHideDoneChange,
    subcardsStats,
    ...attributes
    }: ICardSubcardsProps) {
    if (analyzeRender) analyzeRender.call(`CardSubcardsSection ${cardId}`);

    if (!elements && sectionType === ESettingsSectionType.READONLY) return;

    const { openedProperty, addShowedProperty } = React.useContext(AsidePanelContext);
    const isDefault = sectionType === ESettingsSectionType.DEFAULT;
    const isPopup = sectionType === ESettingsSectionType.POPUP;
    const className = CLASS_CARD_DETAILS_SECTION + '--subcards';
    const classNameEmpty = !elements || !elements.length ? CLASS_SETTINGS_SECTION_EMPTY : '';
    const classNameIsPinned = isPinned ? className + '-pinned' : '';
    const classAction = CLASS_CARD_DETAILS + '-subcards__action';
    const classActionPin = classAction + '--pin';
    const classSettings = CLASS_CARD_DETAILS_SECTION + '__subcards-settings';

    const [isOpenedHook, setIsOpened] = React.useState(isPopup)
    const [isShow, setShow] = React.useState(false);

    const onAddOpened = () => {
        setIsOpened(true);
        onAddClicked();
    }
    const onAddClosed = () => {
        setIsOpened(false);
        if (onClose) onClose();
    }

    React.useEffect(() => {
        if ((elements && elements.length) || isDefaultProperty) {
            setShow(true);
            addShowedProperty(EAsidePanelProperty.CARD_SUBCARDS);
        }
    }, [elements, isDefaultProperty]);

    if (
        !isShow &&
        !(isPopup && openedProperty === EAsidePanelProperty.CARD_SUBCARDS)
    ) {
        return;
    }

    return (
        <SettingsSection
            addTitle={CARD_SUBCARD_ADD}
            addComponent={
                isOpenedHook
                    ? <SubcardsSearchSelectHOC
                        cardId={cardId}
                        boardId={boardId}
                        isOpened={isOpenedHook}
                        isPopup={isPopup}
                        onAddClosed={onAddClosed}
                        sectionType={sectionType}
                    />
                    : <div></div>
            }
            className={`
                ${CLASS_CARD_DETAILS_SECTION}
                ${className}
                ${classNameEmpty}
                ${classNameIsPinned}
            `}
            focusSelector={SEARCH_SELECT_INPUT_SELECTOR}
            isAddWide
            onClickDisallow={onClickDisallow}
            isOpened={isOpenedHook}
            title={CARD_SECTION_SUBCARDS}
            titleSub={!!elements && elements.length > 0 &&
                getMessages().getText(CARD_SUBCARDS_DONE, null, subcardsStats.donePercent, subcardsStats.doneCount, subcardsStats.totalCount)
            }
            titleButton={isDefault && elements && elements.length > 0 &&
            <Button
                className={`
                        ${classAction}
                        ${classActionPin}
                    `}
                tooltip={getAsidePanelTooltip({
                    value: isPinned ? CARD_SUBCARDS_UNPIN : CARD_SUBCARDS_PIN
                })}
                variant={'icon'}
                onClick={onPinToggle}
            >
                <Icon size={24} xlink={'pin'}/>
            </Button>
            }
            sectionType={sectionType}
            onAddOpened={onAddOpened}
            onAddClosed={onAddClosed}
            {...attributes}
        >
            {!!elements &&
                <>
                    {elements.length > 0 &&
                    <div className={classSettings}>
                        <CardSubcardHideDoneButton
                            isHideDoneSubcards={isHideDoneSubcards}
                            onChange={onHideDoneChange}
                        />
                    </div>
                    }
                    <CardSubcardsElements
                        boardId={boardId}
                        elements={elements}
                        boardUsers={boardUsers}
                        boardRoles={boardRoles}
                        sectionType={sectionType}
                        onDelete={onDelete}
                        onGantt={onGantt}
                        onSort={onSort}
                        onSubcardLink={onSubcardLink}
                        onWeightChange={onWeightChange}
                        isHideDoneSubcards={isHideDoneSubcards}
                    />
                </>
            }
        </SettingsSection>
    );
};
