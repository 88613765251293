import * as React from 'react';
import './_BasicFeatureIndicator.scss';
import { IBasicFeatureIndicatorProps } from './types';

export const BasicFeatureIndicator = ({
}: IBasicFeatureIndicatorProps) => {
    const Icon = require('!!@svgr/webpack!./basic-feature.svg').default
    return (
        <div className={'basic-feature-indicator'}>
            <Icon />
        </div>
    );
}
