import { Dispatch, ThunkAction } from '../../../types/actions';
import { cardsDeleteAction } from '../cards/actions/cardsDeleteAction';
import { ICards } from '../cards/types';
import { cardsActionSetAction } from '../actions/cardsActionSetAction';

export const cardsDelete = (
    cards: ICards
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(cardsActionSetAction(cardsDeleteAction(cards)));
    };
    return action;
};
