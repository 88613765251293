import { SegmentCardSourceValue } from '../../../../../../../middlewares/segment/trackEntities/cardEvents';
import { connect } from 'react-redux';
import { ICardActionsTopMoreHOCProps } from './types';
import { ICardActionsTopMoreEvents, ICardActionsTopMoreFields } from '../../components/CardActionsTopMore/types';
import { onCardPrint } from './events/onCardPrint';
import { CardActionsTopMore } from '../../components/CardActionsTopMore/CardActionsTopMore';
import { IApplicationState } from '../../../../../../../types/types';
import { onClick as onSaveAsTemplate } from '../../../saveAsTemplateButton/hocs/SaveAsTemplateButtonHOC/events/onClick';
import { getPanelButtonShow } from '../../../selectors/getPanelButtonShow';
import { onCardMakeRecurring } from './events/onCardMakeRecurring';
import { onViewOnBoard } from './events/onViewOnBoard';
import { getActiveBoardId } from 'app/store/model/selectors/getActiveBoardId';
import { getIsUserBasicPlanOnly } from '../../../../../../../store/model/authUser/selectors/getIsUserBasicPlanOnly';
import { getCard } from '../../../../../../../store/model/selectors/getCard';
import { TStatus } from '../../../../../../../types/model';
import { onArchive } from './events/onArchive';
import { onStartWorking } from './events/onStartWorking';

const mapStateToProps = (
    state: IApplicationState,
    {boardId, cardId}: ICardActionsTopMoreHOCProps,
): ICardActionsTopMoreFields => {
    const isShow = getPanelButtonShow(state, boardId, cardId);
    const isBoard = !!getActiveBoardId(state);
    const { status} = getCard(state, cardId);
    return {
        isArchived: status === TStatus.STATUS_ARCHIVE,
        isBasicFeature: getIsUserBasicPlanOnly(state),
        boardId,
        cardId,
        isShow,
        isBoard
    }
};

const mapDispatchToProps = (
    dispatch: any,
    {cardId, boardId}: ICardActionsTopMoreHOCProps
): ICardActionsTopMoreEvents => {
    const source = SegmentCardSourceValue.DETAILS_TOP_DROPDOWN;
    return {
        onArchive: () => dispatch(onArchive()),
        onCardMakeRecurring: (onActive) => dispatch(onCardMakeRecurring(cardId, boardId, onActive)),
        onCardPrint: () => dispatch(onCardPrint(cardId, source)),
        onCardSaveAsTemplate: () => dispatch(onSaveAsTemplate(cardId, boardId)),
        onStartWorking: () => dispatch(onStartWorking()),
        onViewOnBoard: () => dispatch(onViewOnBoard(cardId, boardId))
    };
};
export const CardActionsTopMoreHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardActionsTopMore);

CardActionsTopMoreHOC.displayName = 'CardActionsTopMoreHOC';
