import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { IGetState } from '../../../../../types/types';
import { ENavigationPanelTypes, NAVIGATION_PANEL_CONTAINER_CLASS } from '../constants';
import { getCurrentNavigationPanelType } from '../helpers/getCurrentNavigationPanelType';
import { panelTypeSetAction } from '../store/navigationPanel/actions/panelTypeSetAction';

export const addNavPanelContainerClass = (
    type?: ENavigationPanelTypes
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        if (type) {
            dispatch(panelTypeSetAction(type));
        } else {
            type = getCurrentNavigationPanelType(state);
        }

        const container = document.querySelector('.' + NAVIGATION_PANEL_CONTAINER_CLASS) as HTMLElement;
        if (!container) return;

        container.className = `
            ${NAVIGATION_PANEL_CONTAINER_CLASS}
            ${NAVIGATION_PANEL_CONTAINER_CLASS}--${type}
        `;
    };
    return action;
};
