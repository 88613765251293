import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TBoardId } from '../../../../../../../../types/types';
import { ensureBoardIsFullLoaded } from '../../../../../../../../store/model/effects/ensureBoardIsFullLoaded';

export const onOpened = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(ensureBoardIsFullLoaded(boardId));
    };
    return action;
};
