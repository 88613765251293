import {Icon, Button} from 'kui';
import {INavigationPanelAnchorMobileProps} from './types';
import * as React from 'react';
import './_NavigationPanelAnchorMobile.scss'

export const NavigationPanelAnchorMobile: React.FC<INavigationPanelAnchorMobileProps> = ({
    isNewFeature,
    onClick
}) => {
    return (
        <Button
            className={'navigation-panel__anchor-mobile'}
            color={'white'}
            variant={'fab'}
            onClick={onClick}
        >
            <Icon size={24} xlink={'menu'} />
            {isNewFeature && <span className={'navigation-panel__anchor-mobile-new-feature'} />}
        </Button>
    )
};
