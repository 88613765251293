import { IGetState, TCardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { getCard } from '../../../../store/model/selectors/getCard';
import { getBoardByCardId } from '../../../../store/model/selectors/getBoardByCardId';
import { ICardBatch } from './api/types';
import { cardPatchRestBatch } from './api/helper/cardPatchRestBatch';
import { cardMoveToListBatch } from './move/cardMoveToList';
import { listUpdateMinMaxOrderNumber } from '../../list/listUpdateMinMaxOrderNumber';

export const cardMoveToInWorkListBatch = (
    getState: IGetState,
    dispatch: Dispatch,
    cardId: TCardId,
    cardBatch: ICardBatch = {card: {}, callbacks: []}
) => {
    const state = getState();
    const card = getCard(state, cardId);
    const board = getBoardByCardId(state, cardId);
    if (board.statusLists &&
        board.statusLists.inWorkId &&
        board.statusLists.inWorkId !== card.id) {
        cardMoveToListBatch(getState, dispatch, cardId, board.statusLists.inWorkId, undefined, cardBatch)
        cardBatch.callbacks.push((card) => {
            dispatch(listUpdateMinMaxOrderNumber(board.statusLists.inWorkId, [card.orderNumber]))
        })
    }
    return cardBatch;
};

export const cardMoveToInWorkList = (
    cardId: TCardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const cardBatch = cardMoveToInWorkListBatch(getState, dispatch, cardId);
        return cardPatchRestBatch(cardId, dispatch, cardBatch);
    };
    return action;
};
