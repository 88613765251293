import { TCardId } from './../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { IGetState, TBoardId } from '../../../../../types/types';
import { updateSelectedCards } from '../../../clickManager';

/**
 * когда в режиме рилэйтед переключаешься на другую доску - выделить на новой доске рилэйтед карты
 */

export const relatedCardsSelect = (
    currentBoardId: TBoardId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const { boardId, cardId, cardsMap } = getState().relatedCards;
        const selectedCardIds: TCardId[] = [];
        if (boardId === currentBoardId) {
            selectedCardIds.push(cardId);
        }
        for (let cardId in cardsMap) {
            if (cardsMap[cardId].dashboardId === currentBoardId) {
                selectedCardIds.push(Number(cardId));
            }
        }
        dispatch(updateSelectedCards(currentBoardId, selectedCardIds));
    };
    return action;
};
