import * as React from 'react';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { CardTabActivityHOC } from '../../hocs/TabActivityHOC/CardTabActivityHOC';

export function TabActivityContext() {
    const { cardId } = React.useContext(AsidePanelContext);

    return <CardTabActivityHOC
        cardId={cardId}
    />;
}
