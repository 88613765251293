import * as React from 'react';
import './_BoardExportSettings.scss';
import { IBoardExportSettingsProps } from './types';
import {
    BOARD_EXPORT_BUTTON_DESELECT_ALL,
    BOARD_EXPORT_BUTTON_SELECT_ALL,
    BOARD_EXPORT_TITLE_CHECKLISTS,
    BOARD_EXPORT_TITLE_CHECKLISTS_ROWS,
    BOARD_EXPORT_TITLE_COLUMNS,
    BOARD_EXPORT_TITLE_DATE_FORMAT,
    BOARD_EXPORT_TITLE_SETTINGS,
    BOARD_EXPORT_TITLE_GOOGLE_SETTINGS,
    BOARD_EXPORT_TITLE_TIME_FORMAT,
    BOARD_SETTINGS_DATE_FORMATS,
} from './constants';
import { CLASS_BOARD_EXPORT } from '../../../constants';
import { Button, Checkbox, Radio, SectionAccordion } from 'kui';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { ListItem } from '../../../../../base/components/ListItem/ListItem';
import { CLASS_LIST_ITEM_CONTAINER } from '../../../../../base/components/ListItem/constants';
import { SettingsSection } from '../../../../../base/components/SettingsSection/SettingsSection';
import { BOARD_EXPORT_TITLE_ARCHIVED } from './constants';

export function BoardExportSettings ({
    checklistsRows,
    columns,
    isAllColumnsSelected,
    dateFormat,
    is12hours,
    onTimeFormatChange,
    onColumnToggle,
    onAllColumnsToggle,
    onColumnsSort,
    onDateFormatChange,
    onChecklistsRowsToggle,
    archived,
    onArchivedChanged
}: IBoardExportSettingsProps) {
    const classSettings = CLASS_BOARD_EXPORT + '_settings';
    const classSettingLabel = classSettings + '-label';

    const onDragEnd = (result: DropResult) => {
        if (result.destination.index !== result.source.index) {
            onColumnsSort(result.draggableId, result.destination.index);
        }
    }

    return (
        <div className={classSettings}>
            <SettingsSection title={BOARD_EXPORT_TITLE_SETTINGS}>
                <Checkbox
                    checked={archived}
                    onChange={() => onArchivedChanged(!archived)}
                    direction={'left'}
                >
                    {BOARD_EXPORT_TITLE_ARCHIVED}
                </Checkbox>
            </SettingsSection>
            <SettingsSection title={BOARD_EXPORT_TITLE_GOOGLE_SETTINGS}>
                <div className={classSettingLabel}>{BOARD_EXPORT_TITLE_DATE_FORMAT}</div>
                <Radio
                    onChange={ e => onDateFormatChange(e.index) }
                    active={dateFormat || 0}
                    direction={'left'}
                >
                    {
                        BOARD_SETTINGS_DATE_FORMATS.map((timeFormat, index) => {
                            return <span key={index}>{timeFormat}</span>
                        })
                    }
                </Radio>
                <div className={classSettingLabel}>{BOARD_EXPORT_TITLE_TIME_FORMAT}</div>
                <Radio
                    onChange={ e => onTimeFormatChange(e.index === 0) }
                    active={is12hours ? 0 : 1}
                    direction={'left'}
                >
                    <span>12 hours</span>
                    <span>24 hours</span>
                </Radio>

                <div className={classSettingLabel}>{BOARD_EXPORT_TITLE_CHECKLISTS}</div>
                <Checkbox
                    checked={checklistsRows}
                    onChange={onChecklistsRowsToggle}
                    direction={'left'}
                >
                    {BOARD_EXPORT_TITLE_CHECKLISTS_ROWS}
                </Checkbox>

                <div className={classSettingLabel}>
                    <>
                        {BOARD_EXPORT_TITLE_COLUMNS}
                    </>
                    <Button
                        variant={'text'}
                        onClick={() => onAllColumnsToggle(!isAllColumnsSelected)}
                    >
                        {isAllColumnsSelected ? BOARD_EXPORT_BUTTON_DESELECT_ALL : BOARD_EXPORT_BUTTON_SELECT_ALL}
                    </Button>
                </div>
                <DragDropContext
                    onDragEnd={onDragEnd}
                >
                    <Droppable
                        droppableId={'board-export-settings'}
                    >
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {
                                    columns.map((column, index) => (
                                        <Draggable
                                            draggableId={column.id}
                                            index={index}
                                            key={column.id}
                                        >
                                            {(provided, snapshot) => {
                                                return (
                                                    <div
                                                        className={CLASS_LIST_ITEM_CONTAINER}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        ref={provided.innerRef}
                                                    >
                                                        <ListItem
                                                            actions={null}
                                                            img={null}
                                                            isDraggable={true}
                                                            key={column.id}
                                                            text={
                                                                <Checkbox
                                                                    checked={column.enabled}
                                                                    onChange={() => onColumnToggle(column.id, !column.enabled)}
                                                                    direction={'left'}
                                                                >
                                                                    {column.label}
                                                                </Checkbox>
                                                            }
                                                        />
                                                    </div>
                                                )
                                            }}
                                        </Draggable>
                                    ))
                                }
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </SettingsSection>
        </div>
    );
};
