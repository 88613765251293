import { IApplicationState } from '../../../types/types';
import { ICards } from '../cards/types';
import { IDependencies } from '../dependencies/dependencies/types';
import { getCardDependency } from '../dependencies/dependencies/selectors/getCardDependency';
import { IDependency } from '../dependencies/dependency/types';

export const getCardDependencesForRollback = (
    state: IApplicationState,
    patchDependencies: IDependencies
): ICards => {
    if (!patchDependencies) return;

    const depsForRollback: IDependencies = {};

    for (let depId in patchDependencies) {
        const dependence = getCardDependency(state, Number(depId));
        if (!dependence) continue;

        depsForRollback[depId] = {};
        for (const field in patchDependencies[depId]) {
            const fieldKey = field as keyof IDependency;
            depsForRollback[depId] = {
                ...depsForRollback[depId],
                [fieldKey]: dependence[fieldKey]
            };
        }
    };

    return JSON.parse(JSON.stringify(depsForRollback));
}
