import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../types/types';
import { IBoard } from '../../../../../store/model/board/types';
import { IList } from '../../../../../store/model/list/types';
import { root } from '../../../../../store/constants';
import { cardMove } from './cardMove';
import { getListUpdateMinMaxOrderNumber } from '../../../../../store/model/list/selectors/getListUpdateMinMaxOrderNumber';
import { listPatchRest } from '../../../list/api/helpers/listPatchRest';
import { sendRealTimeStoreAction } from '../../../../../view/react_components/base/helpers/realTimeHelperTS';
import { listsActionSet } from '../../../../../store/model';
import { listActionSet } from '../../../../../store/model/lists/actions/listActionSet';
import { listUpdate as listUpdateAction } from '../../../../../store/model/list/actions/listUpdate';
import { getCardSubcards } from '../../../../../store/model/selectors/getCardSubcards';
import { getCard } from '../../../../../store/model/selectors/getCard';
import { promiseSerial } from '../../../../../util/promiseSerial';
import { IBoardCardCustomProperties } from '../../../../../store/customProperties/types';
import { boardSetCardCustomProperties } from '../../../board/boardSetCardCustomProperties';
import { getBoardCardCustomProperties } from '../../../../../store/model/board/selectors/getBoardCardCustomProperties';

export const cardsMoveToAnotherBoard = (
    cardIds: TCardId[],
    destBoard: IBoard,
    destList: IList,
    cardIndex: number,
    showMovedNotification: boolean = true,
    saveEpicSubcards: boolean = false,
    saveCustomProperties: boolean = false
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        let orderNumber: number;
        let orderStep = root.App.Util.orderStep;
        const cardsInSelectedList = destList.cards || [];
        const afterCard = cardsInSelectedList[cardIndex];
        if (!afterCard) {
            if (cardsInSelectedList.length) {
                orderNumber = cardsInSelectedList[0].orderNumber - root.App.Util.orderStep * cardIds.length;
            } else {
                orderNumber = (destList.cardAutoDecrement || 0) - root.App.Util.orderStep;
            }
        } else {
            if (cardIndex === cardsInSelectedList.length - 1) { // last card
                orderNumber = cardsInSelectedList[cardIndex].orderNumber + root.App.Util.orderStep;
            } else {
                orderStep = (cardsInSelectedList[cardIndex + 1].orderNumber - cardsInSelectedList[cardIndex].orderNumber) / (cardIds.length + 1);
                orderNumber = cardsInSelectedList[cardIndex].orderNumber + orderStep;
            }
        }
        const promises: Function[] = [];
        const orderNumbers: number[] = [];
        const customPropertiesToAdd: IBoardCardCustomProperties= {};
        cardIds.forEach((cardId) => {
            const card = getCard(state, cardId);
            const saveSubcardsIds = saveEpicSubcards ?
                getCardSubcards(state, cardId).map(subcard => subcard.id).filter(subcardId => cardIds.includes(subcardId)) :
                null;
            const saveEpic = saveEpicSubcards && card.epicId && cardIds.includes(card.epicId);
            const orderNumberCopy = JSON.parse(JSON.stringify(orderNumber));
            promises.push(() => dispatch(cardMove(cardId, destList, destBoard, orderNumberCopy, showMovedNotification, saveSubcardsIds, saveEpic, saveCustomProperties, customPropertiesToAdd)));
            orderNumbers.push(orderNumber);
            orderNumber += orderStep;
        });
        return promiseSerial(promises)
            .then(() => {
                // добавляем custom properties которых нет на новой доске
                const destBoardCustomProperties= (destBoard.meta && destBoard.meta.cardCustomProperties) || {};
                dispatch(boardSetCardCustomProperties(destBoard.id, {
                    ...destBoardCustomProperties,
                    ...customPropertiesToAdd
                }));
                // обновляем у листа cardAutoDecrement и cardAutoIncrement и шлем в риалтайм другой доски
                const update = getListUpdateMinMaxOrderNumber(destList, orderNumbers);
                if (update) {
                    dispatch(listPatchRest(destList.id, update, false))
                        .then(() => {
                            sendRealTimeStoreAction(destBoard.id, destBoard.cometToken, listsActionSet(listActionSet(destList.id, listUpdateAction(update))));
                        })
                }
            });
    };
    return action;
};
