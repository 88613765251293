import { TFiltersPanelAction } from '../actions/types';
import { AT_FILTER_PANEL_ACTION_SET } from '../actions/constants';
import { filterPanelReducer } from '../../filterPanel/reducers/filterPanelReducer';
import { TFilterPanelBoards } from '../types';
import * as deepFreeze from 'deep-freeze';

const initialState: TFilterPanelBoards = {
};

const _filterPanelBoardsReducer = (
    state = initialState,
    action: TFiltersPanelAction
): TFilterPanelBoards => {
    switch (action.type) {
        case AT_FILTER_PANEL_ACTION_SET: {
            const { boardId, filterPanelAction } = action;
            return {
                ...state,
                [boardId]: filterPanelReducer(state[boardId], filterPanelAction)
            }
        }
        default:
            return state;
    }
};

export const filterPanelBoardsReducer = (
    state = initialState,
    action: TFiltersPanelAction
): TFilterPanelBoards => {
    // @ts-ignore
    if(window.Settings.development) {
        // @ts-ignore
        return deepFreeze(_filterPanelBoardsReducer(state, action))
    } else {
        return _filterPanelBoardsReducer(state, action)
    }
};
