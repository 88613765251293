import { IGetState, TBoardId } from '../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { IBoard } from '../../../../../store/model/board/types';
import { getBoardForRollback } from '../../../../../store/model/selectors/getBoardForRollback';
import { boardsActionSet } from '../../../../../store/model/actions/boardsActionSet';
import { boardActionSet } from '../../../../../store/model/boards/actions/boardActionSet';
import { updateAction } from '../../../../../store/model/board/actions/updateAction';
import { patchRest } from '../patchRest';
import { boardActiveRequestsUpdate } from '../../../../../store/requestsState/effects/boardActiveRequestsUpdate';
import { getBoard } from '../../../../../store/model/selectors/getBoardById';
import { sendRealTimeStoreAction } from '../../../../../view/react_components/base/helpers/realTimeHelperTS';
import { IRestDashboard } from 'app/types/rest/IRestDashboard';

export const boardPatchRest = (
    boardId: TBoardId,
    patch: IBoard,
    isRealtime: boolean = true, // send event to socket
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const boardForRollback = getBoardForRollback(getState(), boardId, patch);
        const action = boardsActionSet(boardActionSet(boardId, updateAction(patch)));
        dispatch(action);
        dispatch(boardActiveRequestsUpdate(boardId, 1));

        return dispatch(patchRest(boardId, patch))
            .then((response: IRestDashboard) => {
                storeRenameFolders(patch, response, dispatch);
                if (isRealtime) {
                    if (patch.userMeta) {
                        throw new Error('it is not possible to send user board meta to board channel');
                    }
                    const board = getBoard(getState(), boardId);
                    if (board && board.cometToken) {
                        sendRealTimeStoreAction(boardId, board.cometToken, action);
                    }
                }
                return response;
            })
            .catch((e: any) => {
                console.error(e);
                dispatch(boardsActionSet(boardActionSet(boardId, updateAction(boardForRollback))));
            })
            .finally(() => {
                dispatch(boardActiveRequestsUpdate(boardId, -1));
            });
    };
    return action;
};

const storeRenameFolders = (patch: IBoard, response: IRestDashboard, dispatch: Dispatch) => {
    if (patch.name) {
        const action = boardsActionSet(boardActionSet(response.id, updateAction({
            parentFolderInfo: response.parentFolderInfo,
            backupFolderInfo: response.backupFolderInfo
        })));
        dispatch(action);
    }
}
