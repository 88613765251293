import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { IGetState, IGoogleDriveOpenDialogueFile, TCardId } from '../../../../../types/types';
import {
    SegmentUserEvent,
    SegmentUserOption,
    SegmentUserOptionValue
} from '../../../../../middlewares/segment/trackEntities/userEvents';
import { segmentTrackAction } from '../../../../../middlewares/segment';
import { getMessages, root } from '../../../../../store/constants';
import { IDriveDoc } from '../../../../../store/model/card/types/IDriveDoc';
import { mapNewDriveDoc } from '../AttachmentsSection/hocs/CardAttachmentsSectionHOC/helpers/mapNewDriveDoc';
import { ERestDriveDocType } from '../../../../../types/rest/IRestDriveDoc';
import { snackbarErrorDefault } from '../../../snackbarsStack';
import * as _ from 'underscore';
import { authManagerInstance } from 'app/helper/authorisation/google/AuthManager';

export const showDocDriveWindow = (
    cardId: TCardId,
    currentDriveDocs: IDriveDoc[],
    type: ERestDriveDocType,
    onDriveDocsSelected: (docs: IDriveDoc[]) => void,
    onClose?: () => void,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const App = root.App;
        const eventOpt = {name: SegmentUserOption.TAGRET, value: SegmentUserOptionValue.ATTACHMENTS};
        const onShow = () => {
            dispatch(segmentTrackAction(SegmentUserEvent.PERMISSION_DRIVE_SCOPE_SHOWN, eventOpt));
        };
        const onRequest = () => {
            dispatch(segmentTrackAction(SegmentUserEvent.PERMISSION_DRIVE_SCOPE_REQUESTED, eventOpt));
        };
        const onCancel = () => {
            dispatch(segmentTrackAction(SegmentUserEvent.PERMISSION_DRIVE_SCOPE_CANCELED, eventOpt));
        };
        const onApprove = () => {
            dispatch(segmentTrackAction(SegmentUserEvent.PERMISSION_DRIVE_SCOPE_GRANTED, eventOpt));
            App.Helpers.googleDriveOpenDialogue.callback = (docs: IGoogleDriveOpenDialogueFile[]) => {
                dispatch(callback(docs, cardId, currentDriveDocs, type, onDriveDocsSelected));
                if (onClose) onClose();
            };
            App.Helpers.googleDriveOpenDialogue.cancelCallback = () => {
                if (onClose) onClose();
            };
            App.Helpers.googleDriveOpenDialogue.show();
        };
        authManagerInstance.checkAndRequestDrive(onApprove, onShow, onCancel, onRequest);
    };
    return action;
};

const callback = (
    docs: IGoogleDriveOpenDialogueFile[],
    cardId: TCardId,
    currentDriveDocs: IDriveDoc[],
    type: ERestDriveDocType,
    onDriveDocsSelected: (docs: IDriveDoc[]) => void,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const driveDocsNew: IDriveDoc[] = [];
        let orderNumber = 0;
        if (currentDriveDocs && currentDriveDocs.length) {
            currentDriveDocs.forEach(driveDoc => {
                if (driveDoc.orderNumber > orderNumber) orderNumber = driveDoc.orderNumber;
                orderNumber += 1;
            })
        }
        docs.forEach((doc) => {
            const isAlreadyExists = currentDriveDocs && !!currentDriveDocs.find((driveDoc) => driveDoc.fileId === doc.id);
            if (isAlreadyExists) {
                dispatch(snackbarErrorDefault({id: `attachment-error-${doc.id}`, text: `${getMessages().getText('popup.card.drive_doc.already_exists')} "${_.escape(doc.name)}"`}));
            } else {
                driveDocsNew.push(mapNewDriveDoc(doc, orderNumber++, cardId, type));
            }
        });
        onDriveDocsSelected(driveDocsNew)
    };
    return action;
};
