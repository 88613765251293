import { Dispatch, ThunkAction } from '../../../../../../types/actions';
import { IGetState } from '../../../../../../types/types';
import { timeTrackerSetAction } from '../../../../aside_panel/cardDetails/TabTimingSection/TimeTracker/store/actions/timeTrackerSetAction';
import { getBoardIdByCardId } from '../../../../../../store/model/selectors/getBoardIdByCardId';
import { getCard } from '../../../../../../store/model/selectors/getCard';
import { getBoard } from '../../../../../../store/model/selectors/getBoardById';

export const startTimeTracker = (
    cardId: number,
    start: number
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const card = getCard(state, cardId);
        if (!card) return;

        const boardId = getBoardIdByCardId(state, cardId);
        const board = getBoard(state, boardId);
        dispatch(timeTrackerSetAction({
            cardId,
            cardName: card.name,
            start,
            boardId: board.id,
            boardName: board.name
        }));
    };
    return action;
}
