export const compareNumbers = (
    numberA: number,
    numberB: number,
    isDesc: boolean
): number => {
    // пустая дата всегда внизу
    const defValue = isDesc ? Number.MIN_VALUE : Number.MAX_VALUE;
    numberA = numberA || defValue;
    numberB = numberB || defValue;
    return !isDesc ? numberA - numberB : numberB - numberA;
}
