import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../../../../types/types';
import { cardOpen } from '../../../../../../base/effects/cardOpen';
import {
    asidePanelActionButtonClick
} from '../../../../../../helpers/asidePanelActionButtonClick';
import {
    waitUntilCardIsOpenedAndLoaded
} from '../../../../../../helpers/waitUntilCardIsOpenedAndLoaded';
import { SegmentBoardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment/index';

export const onCardOpen = (
    cardId: TCardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(cardOpen(cardId));
        const onScroll = () => {
            setTimeout(() => {
                asidePanelActionButtonClick('attachments');
            }, 0);
        };

        waitUntilCardIsOpenedAndLoaded(getState, cardId, onScroll);
        dispatch(segmentTrackAction(SegmentBoardEvent.BOARD_ATTACHMENTS_OPEN_CARD_CLICKED));
    };
    return action;
};
