window.Settings = {
    restApiPrefix: process.env.restApiPrefix,
    hoursInDay: process.env.hoursInDay,
    dateFormat: process.env.dateFormat,
    paypal: JSON.parse(process.env.paypal),
    development: JSON.parse(process.env.development),
    statServerUrl: process.env.statServerUrl,
    cometServerUrl: process.env.cometServerUrl,
    cometServerChannelPref: process.env.cometServerChannelPref,
    appId : process.env.appId,
    clientId : process.env.clientId,
    buildTime: process.env.buildTime,
    chargebeeName: process.env.chargebeeSite,
    gaEnabled: JSON.parse(process.env.gaEnabled),
    firstBoardId: process.env.firstBoardId,
    firstBoardPwcId: process.env.firstBoardPwcId,
    firstBoardIdMicrosoft: process.env.firstBoardIdMicrosoft,
    tipsAndTricksBoardId: process.env.tipsAndTricksBoardId,
    tipsAndTricksBoardIdMicrosoft: process.env.tipsAndTricksBoardIdMicrosoft,
    abilitiesBoardId: process.env.abilitiesBoardId,
    amplitudeApiKey: process.env.amplitudeApiKey,
    customerIoApiKey: process.env.customerIoApiKey,
    gaTrackingId: process.env.gaTrackingId,
    ga4Id: process.env.ga4Id,
    ga4IdAds: process.env.ga4IdAds,
    freshchatToken: process.env.freshchatToken,
    facebookPixelId: process.env.facebookPixelId,
    intercomId: process.env.intercomId,
    fireBase: {
        apiKey: process.env.fireBase_apiKey,
        projectId: process.env.fireBase_projectId,
        messagingSenderId: process.env.fireBase_messagingSenderId,
        appId: process.env.fireBase_appId
    },
    twitterPixelId: process.env.twitterPixelId,
    microsoft: JSON.parse(process.env.microsoft),
};
