import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../../types/types';
import { onToggle } from './events/onToggle';
import {
    MyWorkSettingsFilterBarSection
} from '../../components/MyWorkSettingsFilterBarSection/MyWorkSettingsFilterBarSection';
import {
    IMyWorkSettingsFilterBarSectionEvents,
    IMyWorkSettingsFilterBarSectionFields
} from '../../components/MyWorkSettingsFilterBarSection/types';
import { getMyWorkFilterBarCollapsed } from '../../../../selectors/getMyWorkFilterBarCollapsed';

const mapStateToProps = (
    state: IApplicationState
): IMyWorkSettingsFilterBarSectionFields => {
    return {
        isCollapsed: getMyWorkFilterBarCollapsed(state)
    }
};

const mapDispatchToProps = (
    dispatch: any
): IMyWorkSettingsFilterBarSectionEvents => ({
    onToggle: () => dispatch(onToggle())
});

export const MyWorkSettingsFilterBarSectionHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(MyWorkSettingsFilterBarSection);
MyWorkSettingsFilterBarSectionHOC.displayName = 'MyWorkSettingsFilterBarSectionHOC';
