import * as React from 'react';
import { ICardRelatedProps } from './types';
import { CLASS_CARD_DETAILS_SECTION } from '../../../constants';
import { CARD_RELATED_ADD, CARD_SECTION_RELATED } from './constants';
import { SettingsSection } from '../../../../../base/components/SettingsSection/SettingsSection';
import { RelatedCardsList } from '../../../../relatedCards/components/RelatedCardsList/RelatedCardsList';
import './_CardRelated.scss';
import { CLASS_SETTINGS_SECTION_EMPTY } from '../../../../../base/components/SettingsSection/constants';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { EAsidePanelProperty } from '../../../../asidePanel/components/AsidePanel/types';
import { ESettingsSectionType } from 'app/view/react_components/base/components/SettingsSection/types';
import { AnalyzeRender } from '../../../../../helpers/memoizeHelper';

// @ts-ignore
const analyzeRender = window.Settings.development ? new AnalyzeRender(`CardRelatedSection`) : null;

export function CardRelatedSection({
    boardId,
    relatedCards,
    sectionType,
    isDisallowEdit,
    isDefaultProperty,
    onOpenDialog,
    onShowSnackbar,
    ...attributes
}: ICardRelatedProps) {
    const { addShowedProperty, cardId } = React.useContext(AsidePanelContext);

    if (analyzeRender) analyzeRender.call(`CardRelatedSection ${cardId}`);

    if (!relatedCards && sectionType === ESettingsSectionType.READONLY) return;

    const className = CLASS_CARD_DETAILS_SECTION + ' ' + CLASS_CARD_DETAILS_SECTION + '--related';
    const classNameEmpty = !relatedCards || !relatedCards.length ? CLASS_SETTINGS_SECTION_EMPTY : '';
    const [isShow, setShow] = React.useState(false);

    const onAddOpened = () => {
        if (isDisallowEdit) {
            onShowSnackbar();
        } else {
            onOpenDialog();
        }
    }

    React.useEffect(() => {
        if ((relatedCards && relatedCards.length) || isDefaultProperty) {
            setShow(true);
            addShowedProperty(EAsidePanelProperty.CARD_RELATED);
        }
    }, [relatedCards, isDefaultProperty]);

    if (!isShow) return;

    return (
        <SettingsSection
            addTitle={CARD_RELATED_ADD}
            addComponent={<div />}
            className={`
                ${className}
                ${classNameEmpty}
            `}
            isAddWide
            title={CARD_SECTION_RELATED}
            sectionType={sectionType}
            onAddOpened={onAddOpened}
            {...attributes}
        >
            {!!relatedCards &&
                <RelatedCardsList
                    boardId={boardId}
                    relatedCards={relatedCards}
                    sectionType={sectionType}
                />
            }
        </SettingsSection>
    );
};
