import { TBoardId } from '../../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { logoUpload } from '../effects/logoUpload';

export const onFileSelect = (
    boardId: TBoardId,
    file: File
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        return dispatch(logoUpload(boardId, file));
    };
    return action;
};
