import { IGetState, TBoardId, TCardId } from './../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { cardToggleMilestone } from '../../../../../../../../rest/effects/card/card/cardToggleMilestone';
import { getCard } from '../../../../../../../../store/model/selectors/getCard';
import { getCardSubcardsCount } from '../../../../../../../../store/model/selectors/getCardSubcardsCount';
import {
    SegmentCardEvent,
    SegmentCardSection,
    segmentTrackAction,
} from '../../../../../../../../middlewares/segment';
import {getSectionType} from '../../../../selectors/getSectionType';
import {ESettingsSectionType} from '../../../../../../base/components/SettingsSection/types';
import {showSnoozeBlocker} from '../../../../../../base/effects/showSnoozeBlocker';
import {disallowedSectionClickedEvent} from '../../../../effects/segmentEvents';
import { SegmentCardSourceValue } from 'app/middlewares/segment/trackEntities/cardEvents';
import { CLASS_CARD_DETAILS_SECTION } from 'app/view/react_components/aside_panel/cardDetails';
import { CLASS_DATEPICKER } from 'app/view/react_components/base/components/Datepicker/components/constants';
import { toggleCardDefaultProperty } from 'app/view/react_components/aside_panel/boardDetails/details/CardProperties/hocs/CardPropertiesHOC/effects/toggleCardDefaultProperty';
import { EAsidePanelProperty } from 'app/view/react_components/aside_panel/asidePanel/components/AsidePanel/types';
import { getBoardCardDefaultProperties } from 'app/store/model/board/selectors/getBoardCardDefaultProperties';

export const onClick = (
    boardId: TBoardId,
    cardId: TCardId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const { isMilestone } = getCard(state, cardId);
        const sectionType = getSectionType(state, boardId, cardId, null, 'allowGantt');
        const isDisallow = sectionType === ESettingsSectionType.DISALLOW;
        const classCardDetailsSectionDates = CLASS_CARD_DETAILS_SECTION + '__dates';
        const classDatePickerButton = CLASS_DATEPICKER + '__date-button-create--new'

        if (isDisallow) {
            dispatch(showSnoozeBlocker({
                allow: 'allowGantt',
                subAllow: 'allowMilestone'
            }));
            dispatch(disallowedSectionClickedEvent(SegmentCardSection.MILESTONE, SegmentCardSourceValue.BUTTONS_PANEL));
        } else {
            if (getCardSubcardsCount(state, cardId)) return;

            if (!isMilestone) {
                dispatch(segmentTrackAction(SegmentCardEvent.MILESTONE_ON));
                const defaultProperties = getBoardCardDefaultProperties(state, boardId);
                if (!defaultProperties.has(EAsidePanelProperty.CARD_START_DATE)) {
                    dispatch(toggleCardDefaultProperty(boardId, EAsidePanelProperty.CARD_START_DATE))
                }
                setTimeout(() => {
                    const button = document.querySelector(`.${classCardDetailsSectionDates} .${classDatePickerButton}`) as HTMLElement;
                    if (button) button.click();
                }, 200) //ожидание появления секции Date
            } else {
                dispatch(segmentTrackAction(SegmentCardEvent.MILESTONE_OFF));
            }
            dispatch(cardToggleMilestone(cardId));
        }
    };
    return action;
};
