import { getMessages } from '../../../../../../../store/constants';
import { THUMB_SIZE } from '../../../../../../../const';

export const ATTACHMENT_PIN = getMessages().getText('card_form_attachment_pin');
export const ATTACHMENT_UNPIN = getMessages().getText('card_form_attachment_unpin');
export const ATTACHMENT_DOWNLOAD = getMessages().getText('card_form_attachment_download');
export const ATTACHMENT_CARD_OPEN = getMessages().getText('card_form_attachment_open_card');
export const ATTACHMENT_DELETE = getMessages().getText('card_form_attachment_delete');
export const ATTACHMENT_RENAME = getMessages().getText('card_form_attachment_rename');
export const ATTACHMENT_ACCESS = getMessages().getText('card_form_attachment_access');
export const ATTACHMENT_COPY_LINK = getMessages().getText('card_form_attachment_copy_link');
export const ATTACHMENT_GOOGLE_THUMB_SIZE = `w${THUMB_SIZE}`;
