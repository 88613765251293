import { IApplicationState, TListId } from '../../../types/types';
import { getBoard } from './getBoardById';
import { IBoard } from '../board/types';
import { getBoardIdByListId } from './getBoardIdByListId';

export const getBoardByListId = (
    state: IApplicationState,
    listId: TListId
): IBoard => {
    const boardId = getBoardIdByListId(state, listId);
    if (!boardId) return null;

    return getBoard(state, boardId);
};
