import { IApplicationState } from '../../../../../../types/types';
import { SharingDialog } from '../../components/SharingDialog/SharingDialog';
import { ISharingDialogEvents, ISharingDialogFields } from '../../components/SharingDialog/types';
import { connect } from 'react-redux';
import { ISharingDialogHOCProps } from './types';
import { onClose } from './events/onClose';
import { onDelete } from './events/onDelete';
import { onAdd } from './events/onAdd';
import { onSearch } from './events/onSearch';
import { onUpdate } from './events/onUpdate';
import { loadPermissions } from './events/loadPermissions';
import { getBoardPermissions } from 'app/store/model/selectors/getBoardPermissions';

const mapStateToProps = (
    state: IApplicationState,
    props: ISharingDialogHOCProps,
): ISharingDialogFields => {
    const { boardId } = props;

    const boardPermissions = getBoardPermissions(state, boardId);

    const {
        msAllowEditFilePermissionRole,
        msAllowDeleteFilePermission,
    } = boardPermissions && boardPermissions.authPermissions;

    return {
        msAllowEditFilePermissionRole,
        msAllowDeleteFilePermission,
    };
};

const mapDispatchToProps = (
    dispatch: any,
    props: ISharingDialogHOCProps,
): ISharingDialogEvents => {
    const { boardId } = props;
    return {
        loadPermissions: () => dispatch(loadPermissions(boardId)),
        onAdd: (emails, role) => dispatch(onAdd(boardId, emails, role)),
        onClose: () => dispatch(onClose(boardId)),
        onDelete: (permId) => dispatch(onDelete(boardId, permId)),
        onSearch: (value) => dispatch(onSearch(value)),
        onUpdate: (permId, role) => dispatch(onUpdate(boardId, permId, role)),
    }
};

export const SharingDialogHOC = connect(mapStateToProps, mapDispatchToProps)(SharingDialog);
SharingDialogHOC.displayName = 'SharingDialogHOC';
