import { getBoardCards } from './../../../../../../../../store/model/selectors/getBoardCards';
import { getCardIsEpic } from './../../../../../../../../store/model/selectors/getCardIsEpic';
import { getBoardIdByCardId } from './../../../../../../../../store/model/selectors/getBoardIdByCardId';
import { IApplicationState, TCardId } from '../../../../../../../../types/types';
import { ISubCardsItem } from '../types';
import { ICard } from '../../../../../../../../store/model/card/types';
import { getCardIcon } from '../../../../../../helpers/getCardIcon';
import { getCardFullNameHelper } from '../../../../../../../../store/model/card/helpers/getCardFullNameHelper';
import { isCardInMyUpLevelViaEpic } from '../../../../selectors/isCardInMyUpLevelViaEpic';
import { createSelector } from 'reselect';
import { getCardListName } from '../../../../../../../../store/model/selectors/getCardListName';
import { stripHtml } from '../../../../../../base/helpers/stripHtmlHelper';
import { markdown } from '../../../../../../../../helper/markdownHelper';
import { TStatus } from '../../../../../../../../types/model';
import { getCard } from '../../../../../../../../store/model/selectors/getCard';

const getBoardCardsSelector = (
    state: IApplicationState,
    cardId: TCardId
): ICard[] => {
    const boardId = getBoardIdByCardId(state, cardId);
    if (!boardId) return [];

    const card = getCard(state, cardId);
    const cards = getBoardCards(state, boardId, card.status);
    return cards;
}

const getAvailableSubCardsSelector = (
    cards: ICard[],
    state: IApplicationState,
    cardId: TCardId
): ISubCardsItem[] => {
    const epicCard = getCard(state, cardId);
    const epicCardRecurring = epicCard.status === TStatus.STATUS_SERVICE_TEMPLATE && epicCard.recurringTasks.length > 0;
    const filteredCards = cards.filter((targetCard: ICard) => {
        const isSubCard = Boolean(targetCard.epicId);
        const theSameCard = targetCard.id === cardId;
        const isCardMyTopEpic = isCardInMyUpLevelViaEpic(state, cardId, targetCard.id);
        const recurringFilter = epicCard.status === TStatus.STATUS_ACTIVE ||
            (epicCard.status === TStatus.STATUS_SERVICE_TEMPLATE &&
                (epicCardRecurring && targetCard.recurringTasks.length || !epicCardRecurring && !targetCard.recurringTasks.length))
        const result = (
            !isSubCard && !theSameCard && !isCardMyTopEpic && recurringFilter
        );
        return result;
    });

    return filteredCards.map((card) => {
        const isEpic = getCardIsEpic(state, card.id);
        const img = getCardIcon(card.status, isEpic);
        const cardFullName = getCardFullNameHelper(card);
        const listName = getCardListName(state, card.id);
        return {
            img,
            text: stripHtml(markdown(cardFullName)), //todo O_O check why, we can use collectionIndex
            textSub: stripHtml(markdown(listName)),
            value: String(card.id)
        };
    });
};

export const getAvailableSubCards: (
    state: IApplicationState,
    cardId: TCardId
) => ISubCardsItem[] = createSelector([
        getBoardCardsSelector,
        (state: IApplicationState, cardId: TCardId) => state,
        (state: IApplicationState, cardId: TCardId) => cardId
    ],
    getAvailableSubCardsSelector,
);

export const getAvailableSubCardsCreateSelector = () => getAvailableSubCards;
