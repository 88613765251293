import * as React from 'react';
import './_BoardRole.scss';
import { IBoardRoleProps } from './types';
import { Checkbox, Icon, Input, Loader } from 'kui';
import { CLASS_BOARD_DETAILS } from '../../../../constants';
import { TStatus } from '../../../../../../../../types/model';
import { v4 as uuidv4 } from 'uuid';

export function BoardRole ({
    id,
    name,
    color,
    status,
    isReadonly,
    onCreate,
    onChange
}: IBoardRoleProps) {
    const [roleName, setRoleName] = React.useState(name);
    const [loading, setLoading] = React.useState(false);

    const className = CLASS_BOARD_DETAILS + '__role';
    const classIcon = CLASS_BOARD_DETAILS + '__role-icon';
    const classIconColor = classIcon + '--' + color;
    const classInput = CLASS_BOARD_DETAILS + '__role-input';
    const [uniqueClass] = React.useState(className + '--' + uuidv4());

    const handleInputBlur = (
        e: React.FocusEvent
    ) => {
        const { value } = e.target as HTMLInputElement;
        const { classList } = e.relatedTarget as HTMLInputElement;
        if (!classList.contains(uniqueClass)) {
            saveName(value);
        }
    };
    const handleInputChange = (
        e: React.SyntheticEvent
    ) => {
        const { value } = e.target as HTMLInputElement;
        setRoleName(value);
    };
    const handleKeyDown = (
        e: React.KeyboardEvent
    ) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const { parentElement, value } = e.target as HTMLInputElement;
            saveName(value);
            const checkbox = parentElement ? parentElement.closest('.kui-checkbox') as HTMLInputElement : null;
            if (checkbox) setTimeout(() => checkbox.focus(), 100); // закрыть инпут
        } else if (e.key === ' ') {
            e.stopPropagation();
        }
    };

    const createRole = (
        name: string,
        status: TStatus
    ) => {
        if (loading) return;
        setLoading(true);
        onCreate(color, name, status).then(() => {
            setLoading(false);
        })
    }

    const saveName = (value: string) => {
        setRoleName(value);
        if (value !== name){
            if (id) {
                onChange(id, value, status);
            } else { // Может вызваться дважды, один раз на enter, второй - на blur, поэтому запоминаем состояние, что уже создали роль
                createRole(value, status);
            }
        }
    };

    const onToggleStatusHandler = () => {
        if (id) {
            onChange(id, roleName, status === TStatus.STATUS_ACTIVE ? TStatus.STATUS_DELETED : TStatus.STATUS_ACTIVE);
        } else {
            createRole(roleName, TStatus.STATUS_ACTIVE);
        }
    }

    React.useEffect(() => {
        setRoleName(name);
    }, [name]);

    return (
        <Checkbox
            className={`
                ${className}
                ${uniqueClass}
            `}
            checked={status === TStatus.STATUS_ACTIVE}
            disabled={isReadonly || loading}
            onChange={onToggleStatusHandler}
        >
            <Icon
                className={`
                    ${classIcon}
                    ${classIconColor}
                `}
                size={24}
                xlink={'user'}
            />
            <Input
                autosize={false}
                className={classInput}
                readOnly={isReadonly || loading}
                value={roleName}
                onBlur={handleInputBlur}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
            />
            {loading && <Loader />}
        </Checkbox>
    );
};
