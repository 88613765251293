import {ITopBarSharingProps} from './types';
import * as React from 'react';
import {Button, ButtonsGroup, Icon, Modal} from 'kui';
import './_TopbarSharing.scss';
import { Portal } from '../../../../helpers/portal';
import { SHARE_HINT_BUTTON, SHARE_HINT_TEXT, SHARE_HINT_CLOSE } from './constants';
import ReactHtmlParser from 'react-html-parser';

export function TopBarSharing ({
    isShareHintShow,
    sharingType,
    onClick,
    onHintClose,
    onHintDontShow,
}: ITopBarSharingProps) {
    const classNameModal = 'modal_share_hint';
    const classNameModalButtons = classNameModal + '__buttons';

    return (
        <div className="top-bar__sharing">
            <Button className="topbar__sharing-button" color={'purple'} variant="primary" text={'Share'} onClick={onClick}>
                <Icon xlink={sharingType} size={24}/>
            </Button>

            {isShareHintShow &&
                <Portal
                    className={classNameModal}
                    selector={'body'}
                >
                    <Modal
                        title={null}
                        onClose={onHintClose}
                    >
                        <div>
                            {ReactHtmlParser(SHARE_HINT_TEXT)}
                        </div>

                        <div className={classNameModalButtons}>
                            <ButtonsGroup>
                                <Button
                                    variant={'secondary'}
                                    onClick={onHintDontShow}
                                >
                                    {SHARE_HINT_BUTTON}
                                </Button>
                                <Button
                                    autoFocus
                                    onClick={onHintClose}
                                >
                                    {SHARE_HINT_CLOSE}
                                </Button>
                            </ButtonsGroup>
                        </div>
                    </Modal>
                </Portal>
            }
        </div>
    )
};
