import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { authUserSetRateFeaturePoll } from '../../../../../../../rest/effects/authUser/authUserSetRateFeaturePoll';
import { getUserRateFeaturePoll } from '../../../../../../../store/model/authUser/selectors/getUserRateFeaturePoll';
import { IGetState } from '../../../../../../../types/types';
import { segmentTrackAction, SegmentUserEvent } from '../../../../../../../middlewares/segment';
import { SegmentUserOption } from '../../../../../../../middlewares/segment/trackEntities/userEvents';

export const onShow = (
    feature: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentUserEvent.FEATURE_POLL_SHOWED, {
            name: SegmentUserOption.FEATURE,
            value: feature
        }));
    };
    return action;
};
