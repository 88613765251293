import { getUserActiveSubscriptions } from '../../../../../store/model/authUser/selectors/getUserActiveSubscriptions';
import { IApplicationState } from '../../../../../types/types';

export const getPaymentExpDate = (
    state: IApplicationState
): number => {
    const subscriptions = getUserActiveSubscriptions(state);
    if (subscriptions) {
        for (let i = 0; i < subscriptions.length; i++) {
            if (subscriptions[i].isTrial) {
                return subscriptions[i].paymentExpDate;
            }
        }
    }
    return 0;
};
