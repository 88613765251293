import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState, TCardId } from '../../../../types/types';
import { getCard } from '../../../../store/model/selectors/getCard';
import { getCardEpicsForStartDateChange } from '../../../../store/model/selectors/getCardEpicsForStartDateChange';
import { cardsRestPatch } from './api/helper/cardsRestPatch';
import { ICards } from '../../../../store/model/cards/types';
import { ICardsDatesDelta } from 'app/types/ICardsDatesDelta';
//TODO implement
export const cardSetStartDateWithEpics = (
    cardId: TCardId,
    startDate: number,
    cardDatesUpdate: ICardsDatesDelta = {}
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const card = getCard(state, cardId);
        if (!card) return Promise.reject();

        if (card.startDate === startDate) return Promise.resolve();

        let cards: ICards = {
            [cardId]: {
                startDate
            }
        };
        cardDatesUpdate[cardId] = {
            deltaStartDate: startDate - card.startDate
        }
        cards = getCardEpicsForStartDateChange(state, card.epicId, cards, cardDatesUpdate);

        dispatch(cardsRestPatch(cards));
        return Promise.resolve(cards);
    };
    return action;
};
