import { EAsidePanelProperty, EAsidePanelType } from '../../../../../../asidePanel/components/AsidePanel/types';
import { CARD_PROPERTIES, CARD_TEMPLATE_PROPERTIES } from '../../../../../constants';
import { IApplicationState, TBoardId } from '../../../../../../../../../types/types';
import {
    getBoardCardDefaultProperties
} from '../../../../../../../../../store/model/board/selectors/getBoardCardDefaultProperties';
import {
    getBoardCardRequiredProperties
} from '../../../../../../../../../store/model/board/selectors/getBoardCardRequiredProperties';
import { ICardPropertyElement } from '../../../components/CardProperties/types';
import {
    getBoardCustomProperties
} from '../../../../CardCustomProperties/hocs/CardCustomPropertiesSectionHOC/selectors/getBoardCustomProperties';
import { getBoardCardProperties } from '../../../../../../../../../store/model/board/selectors/getBoardCardProperties';
import { createDeepEqualSelector } from '../../../../../../../helpers/memoizeHelper';
import { ICardProperty } from '../../../../../../../../../types/rest/IRestBoardMeta';
import {
    ICustomPropertiesElement
} from '../../../../../../cardDetails/CustomPropertiesSection/components/CustomPropertiesElements/types';

type TgetCardPropertiesForSettings = (
    state: IApplicationState,
    boardId: TBoardId,
    type: EAsidePanelType,
) => ICardPropertyElement[];

const getCardPropertiesForSettingsSelector = (
    boardProperties: ICardProperty[],
    boardDefaultProperties: Set<EAsidePanelProperty>,
    boardRequiredProperties: Set<EAsidePanelProperty>,
    boardCustomProperties: ICustomPropertiesElement[],
    type: EAsidePanelType,
): ICardPropertyElement[] => {
    const isTemplate = type === EAsidePanelType.CARD_TEMPLATE;
    const properties = isTemplate ? CARD_TEMPLATE_PROPERTIES : CARD_PROPERTIES;

    const cardProperties: ICardPropertyElement[] = properties.map(property => {
        if (property.key === EAsidePanelProperty.CARD_CUSTOM) {
            const customCardProperty = boardProperties.find(({ property }) => property === EAsidePanelProperty.CARD_CUSTOM);
            const customCardDefaultProperty = customCardProperty && customCardProperty.options &&
                customCardProperty.options.find(({ isDefault }) => isDefault);
            const customCardRequiredProperty = customCardDefaultProperty && customCardProperty.options &&
                customCardProperty.options.filter(({ isRequired }) => isRequired)
                    .map(item => item.option);
            return {
                ...property,
                options: boardCustomProperties.map((customProperty) => ({
                        option: customProperty.id,
                        name: customProperty.name,
                        isDefault: !!customCardDefaultProperty,
                        isRequired: customCardRequiredProperty && customCardRequiredProperty.includes(customProperty.id)
                    })
                )
            };
        }

        return {
            ...property,
            isDefault: boardDefaultProperties.has(property.key),
            isRequired: boardRequiredProperties.has(property.key)
        };
    });

    return cardProperties;
}

export const getCardPropertiesForSettingsCreateSelector = (
): TgetCardPropertiesForSettings => createDeepEqualSelector(
    getBoardCardProperties,
    getBoardCardDefaultProperties,
    getBoardCardRequiredProperties,
    getBoardCustomProperties,
    (state: IApplicationState, type: EAsidePanelType) => type,
    getCardPropertiesForSettingsSelector,
);
