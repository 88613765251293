import {IApplicationState, TBoardId} from '../../../../../../../../../types/types';
import {EBackupExportType} from '../../../../../../../../../types/rest/IRestBackup';
import {IAutoBackupState} from '../../autoBackupsBoard/types';
import {getAutoBackupInitial} from '../reducers/constants';

export const getAutoBackupsByBoardIdAndExportType = (
    state: IApplicationState,
    boardId: TBoardId,
    exportType: EBackupExportType
): IAutoBackupState => {
    return  state.autobackups && state.autobackups[boardId] && Object.values(state.autobackups[boardId])
        .filter(backup => backup.exportType === exportType)[0] || getAutoBackupInitial();
}
