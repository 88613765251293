import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId, TChecklistId, TChecklistItemId } from '../../../../../../../../types/types';

import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { getChecklistItemsSection } from '../../selectors/getChecklistItemsSection';
import { deleteChecklistItem } from '../../effects/deleteChecklistItem';
import { getChecklistItem } from '../../../../../../../../store/model/checklists/checklist/selectors/getChecklistItem';
import { sortChecklistItems } from '../effects/sortChecklistItems';

const getChecklistItems = getChecklistItemsSection();

export const onSort = (
    cardId: TCardId,
    itemId: TChecklistItemId,
    index: number,
    checklistId: TChecklistId,
    newChecklistId: TChecklistId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        dispatch(segmentTrackAction(SegmentCardEvent.MOVED_CHECKLIST_ELEMENT));
        const collection = getChecklistItems(state, cardId, checklistId).map(item => item.id);
        const i = collection.indexOf(itemId);
        if (i > -1) collection.splice(i, 1);
        if (checklistId === newChecklistId) {
            collection.splice(index, 0, itemId);
            dispatch(sortChecklistItems(cardId, checklistId, collection));
        } else {
            const newCollection = getChecklistItems(state, cardId, newChecklistId).map(item => item.id);
            const newItem = getChecklistItem(state, cardId, checklistId, itemId);
            newCollection.splice(index, 0, itemId);
            dispatch(sortChecklistItems(cardId, checklistId, collection));
            dispatch(deleteChecklistItem(cardId, checklistId, itemId));
            dispatch(sortChecklistItems(cardId, newChecklistId, newCollection, newItem))
        }
    };
    return action;
};
