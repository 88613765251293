import { IApplicationState } from '../../../../../types/types';
import { IDependency } from '../../dependency/types';

export const getCardDependencies = (
    state: IApplicationState,
    filter?: (dependency: IDependency) => boolean
): IDependency[] => {
    const dependencies = state.model.dependencies;
    if (!dependencies) {
        return [];
    }
    const cardDependencies: IDependency[] = [];
    for (let dependencyId in dependencies) {
        if (
            !filter ||
            filter(dependencies[dependencyId])
        ) {
            cardDependencies.push(dependencies[dependencyId]);
        }
    }
    return cardDependencies;
};
