import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { checkEditStateValid } from '../../../helpers/checkIsValid';
import { getAppState } from '../../../../../../../../../store/configureStore';
import { onSave } from './onSave';
import { TBoardId, TCardId } from '../../../../../../../../../types/types';

export const onEnter: ActionCreator<ThunkAction> = (
    boardId: TBoardId,
    cardId: TCardId,
) => {
    const action = (
        dispatch: Dispatch,
    ) => {
        if (checkEditStateValid(getAppState(), cardId)) {
            dispatch(onSave(boardId, cardId));
        }
    };
    return action;
};
