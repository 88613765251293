import {getCardDependency} from './getCardDependency';
import {EDependencyTypes, TDependencyId} from '../../dependency/types';
import {IApplicationState} from '../../../../../types/types';
import {getCard} from '../../../selectors/getCard';

export const isDependencyValid = (
    state: IApplicationState,
    dependencyId: TDependencyId
): boolean => {
    const dependency = getCardDependency(state, dependencyId);
    const predecessor = getCard(state, dependency.predecessorId);
    const card = getCard(state, dependency.successorId);
    if (!!card && !!predecessor) {
        switch (dependency.type) {
            case EDependencyTypes.FINISH_TO_START:
                return predecessor.dueDate < card.startDate;
            case EDependencyTypes.FINISH_TO_FINISH:
                return predecessor.dueDate > card.dueDate;
            case EDependencyTypes.START_TO_START:
                return predecessor.startDate > card.startDate;
            case EDependencyTypes.START_TO_FINISH:
                return predecessor.startDate > card.dueDate;
        }
    }
    return false;
};
