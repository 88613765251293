'use strict';
import * as _ from 'underscore';

window.App.MLTemplate = {
    langSettings: {
        interpolate: /\<\#\=(.+?)\#\>/gim,
        evaluate: /\<\#(.+?)\#\>/gim,
        escape: /\<\#\-(.+?)\#\>/gim
    },

	templatesCache: {},
	templatesLocalizationCache: {},


	/**
	 * return _.template(tmpl.html()) from cache with langSettings
     * @param templateId
	 * @param lngsArrayKey
	 * @param templateHtml template in html
	 */
	getUndescoreTemplate: function(templateId, templateHtml) {
		var template = this.templatesCache[templateId];
		if (_.isUndefined(template)) {
			var tmpl = templateHtml || $(templateId).html();
			if(!tmpl) {
				console.log('UPS template with id ' + templateId + ' not found');
				return null;
			}else{
				this.templatesCache[templateId] = template;
			}
			template = _.template(tmpl, this.langSettings);
		}
		return template;
    },

    /**
     * get localized template with lngsArrayKey from cache
     *
     * @param templateId
     * @param lngsArrayKey
     * @param templateHtml template in html
     * @returns {*}
     */
    getLocalizationUndescoreTemplate: function(templateId, lngsArrayKey, templateHtml) {
    	var lngsArrayKeyLocal = _.uniq(lngsArrayKey);
    	var key = templateId + '__' + lngsArrayKeyLocal.join();
		var template = this.templatesLocalizationCache[key];

		if (_.isUndefined(template)) {
			var undescoreTemplate = this.getUndescoreTemplate(templateId, templateHtml);
			var messages = Messages.getMessageData(lngsArrayKeyLocal);
			var templateTmp = undescoreTemplate(messages);
			template = _.template(templateTmp);
            this.templatesLocalizationCache[key] = template;
		}
		return template;
    },

	/**
	 * public function for geting localized template wiht lngsArrayKey from cache
	 *
	 * @param templateId
	 * @param lngsArrayKey
	 * @param templateHtml
	 * @returns {*}
	 */
	getTemplate: function(templateId, lngsArrayKey, templateHtml) {
		var lngsArrayKeyLocal = lngsArrayKey || [];
		lngsArrayKeyLocal[lngsArrayKeyLocal.length] = 'def';
    	return this.getLocalizationUndescoreTemplate(templateId, lngsArrayKeyLocal, templateHtml);
    }


};
