import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../../types/types';
import {
    IMyWorkSettingsColumnsSectionEvents,
    IMyWorkSettingsColumnsSectionFields
} from '../../components/MyWorkSettingsColumnsSection/types';
import {
    MyWorkSettingsColumnsSection
} from '../../components/MyWorkSettingsColumnsSection/MyWorkSettingsColumnsSection';
import { onSortColumns } from './events/onSortColumns';
import { onReset } from './events/onReset';
import { onToggle } from './events/onToggle';
import { getCardsColumns } from './selectors/getCardsColumns';

const mapStateToProps = (
    state: IApplicationState
): IMyWorkSettingsColumnsSectionFields => {
    return {
        columns: getCardsColumns(state)
    }
}

const mapDispatchToProps = (
    dispatch: any
): IMyWorkSettingsColumnsSectionEvents => ({
    onSort: (column, index, columns) => dispatch(onSortColumns(column, index, columns)),
    onReset: () => dispatch(onReset()),
    onToggle: (column) => dispatch(onToggle(column))
});

export const MyWorkSettingsColumnsSectionHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(MyWorkSettingsColumnsSection);
MyWorkSettingsColumnsSectionHOC.displayName = 'MyWorkSettingsColumnsSectionHOC';
