'use strict';

App.Views.PermissionsCalendar = App.Views.PermissionsDialog.extend({
    getData: function() {
        return {
            video: 'C4X3iQ0h3Es',
            icon: '/dist/img/google-calendar.svg',
            description: "Kanbanchi needs permission to view and manage your Google calendars for synchronization. You will be able to change the type of access later.",
            annotation: 'Kanbanchi will have access to your Google Calendar to push start and due dates of the selected cards to your calendars.',
            shortText: 'Manage your calendars',
            title: 'Google Calendars'
        }
    }
});
