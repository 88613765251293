import { IApplicationState, TBoardId } from '../../../types/types';
import { getUserRecentBoard } from '../authUser/selectors/getUserRecentBoard';
import { getBoard } from './getBoardById';
import { getTabBoard } from './getTabBoard';
import { getBoardShowIconAndColorLogo } from '../board/selectors/getBoardShowIconAndColorLogo';

export const getBoardLogo = (
    state: IApplicationState,
    boardId: TBoardId
): string => {
    if (getBoardShowIconAndColorLogo(state, boardId)) return;

    let board = getBoard(state, boardId);

    if (!board) {
        board = getTabBoard(state, boardId);
    }

    if (!board) {
        board = getUserRecentBoard(state, boardId);
    }

    return board && board.logo;
}
