import { connect } from 'react-redux';
import { BasicUpgradeButton } from '../../components/BasicUpgradeButton';
import { IBasicUpgradeButtonEvents } from '../../components/types';
import { onUpgrade } from './events/onUpgrade';
import { IBasicUpgradeButtonHOCProps } from './types';

const mapDispatchToProps = (
    dispatch: any,
    { onClick }: IBasicUpgradeButtonHOCProps
): IBasicUpgradeButtonEvents => {
    return {
        onUpgrade: () => dispatch(onUpgrade(onClick))
    }
};

export const BasicUpgradeButtonHOC = connect(
    null,
    mapDispatchToProps
)(BasicUpgradeButton);

BasicUpgradeButtonHOC.displayName = 'BasicUpgradeButtonHOC';
