import { IApplicationState, TCardId } from '../../../types/types';
import { getCard } from './getCard';
import { getCardDone } from './getCardDone';

export const getCardIsOverdue = (
    state: IApplicationState,
    cardId: TCardId
): boolean => {
    const card = getCard(state, cardId);
    if (!card) return false;

    const done = getCardDone(state, cardId);
    const date = card.isMilestone ? card.startDate : card.dueDate;
    return (
        !done &&
        date &&
        date * 1000 < Date.now()
    );
}
