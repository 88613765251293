import * as React from 'react';
import { AsidePanelActionsButton } from '../../../..';
import { BOARD_BUTTON_OPEN_IN_DRIVE } from './constants';
import { IOpenInDriveButtonProps } from './types';

export function OpenInDriveButton ({
    onGetLink,
    onClick,
    parentFolderInfo
}: IOpenInDriveButtonProps) {
    const [link, setLink] = React.useState(null);

    React.useEffect(() => {
        onGetLink().then(result => {
            setLink(result);
        })
    }, [parentFolderInfo]);

    return (
        <AsidePanelActionsButton
            title={BOARD_BUTTON_OPEN_IN_DRIVE}
            onClick={onClick}
            href={link}
            target={'_blank'}
            isDisabled={!link}
        />
    );
};
