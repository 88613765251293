import * as React from 'react';
import './_CardChecklistWeight.scss';
import { CLASS_CARD_DETAILS } from '../../../constants';
import { SelectList, SelectListItem } from 'kui';
import { ICardChecklistWeightProps } from './types';
import { CARD_CHECKLIST_WEIGHT_LABEL } from './constants';
import { CARD_CHECKLIST_WEIGHTS } from '../../../../../../../const';
import { getParentsClasses } from '../../../../../helpers/getParentsClasses';

export function CardChecklistWeight ({
    weight,
    onChange,
    onClose,
}: ICardChecklistWeightProps) {
    const className = CLASS_CARD_DETAILS + '__checklist-item-weight-dropdown';

    const onSelect = (newWeight: number) => {
        if (newWeight !== weight) {
            onChange(newWeight);
            onClose();
        }
    };

    const onEnter = (
        e: React.KeyboardEvent,
        newWeight: number
    ) => {
        if (e.key === 'Enter') {
            onSelect(newWeight);
        }
    };

    React.useEffect(() => {
        const selectedItem = document.querySelector('.' + className + ' .kui-select-list__item--active') as HTMLElement;
        if (selectedItem) {
            selectedItem.focus();
        }
    }, []);

    return (
        <SelectList
            fixActive
            active={weight}
            className={className}
        >
            <SelectListItem>
                {CARD_CHECKLIST_WEIGHT_LABEL}
            </SelectListItem>
            {[...Array(CARD_CHECKLIST_WEIGHTS)].map((weight, index) => (
                <SelectListItem
                    key={index}
                    value={String(index + 1)}
                    onClick={() => onSelect(index + 1)}
                    onKeyDown={(e) => onEnter(e, index + 1)}
                >
                    {index + 1}
                </SelectListItem>
            ))
            }
        </SelectList>
    );
};
