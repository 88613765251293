import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../../types/types';
import { PrefixesPanelButton } from '../../components/PrefixesPanelButton/PrefixesPanelButton';
import { IPrefixesPanelButtonEvents, IPrefixesPanelButtonFields } from '../../components/PrefixesPanelButton/types';
import { getCardNumberByBoardId } from '../../store/cardNumbers/selectors/getCardNumberByBoardId';
import { onClick } from './events/onClick';
import { IPrefixesPanelButtonHOCProps } from './types';
import { getIsUserBasicPlanOnly } from '../../../../../../../../store/model/authUser/selectors/getIsUserBasicPlanOnly';

const mapStateToProps = (
    state: IApplicationState,
    { boardId }: IPrefixesPanelButtonHOCProps
): IPrefixesPanelButtonFields => {
    const { enabled: indicatorBoolean } = getCardNumberByBoardId(state, boardId);
    return {
        indicatorBoolean,
        isBasicFeature: getIsUserBasicPlanOnly(state),
        tooltip: null,
    }
};

const mapDispatchToProps = (
    dispatch: any,
    {onClick: ownOnClick}: IPrefixesPanelButtonHOCProps
): IPrefixesPanelButtonEvents => {
    return {
        onClick: () => dispatch(onClick(ownOnClick))
    }
};

export const PrefixesPanelButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(PrefixesPanelButton);

PrefixesPanelButtonHOC.displayName = 'PrefixesPanelButtonHOC';
