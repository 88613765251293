import * as React from 'react';
import { IAsidePanelTab } from '../../../types';
import { IApplicationState, TBoardId } from '../../../../../../../types/types';
import { EBoardTab } from '../../../../boardDetails/types';
import {
    TabDetailsContext
} from 'app/view/react_components/aside_panel/boardDetails/details/TabDetails/components/TabDetailsContext/TabDetailsContext';
import {
    PanelActionsContext
} from 'app/view/react_components/aside_panel/boardDetails/panelActions/components/PanelActionsContext/PanelActionsContext';
import { EPanelRoute, ERoutes } from 'app/store/router/constants';
import { onTabDetailsActive } from '../events/onTabDetailsActive';
import { getMessages } from 'app/store/constants';
import {
    TabActivityBody
} from 'app/view/react_components/aside_panel/boardDetails/activity/TabActivity/components/TabActivityBody/TabActivityBody';
import { onTabActivityActive } from '../events/onTabActivityActive';
import {
    TabAttachmentsContext
} from 'app/view/react_components/aside_panel/boardDetails/attachments/TabAttachments/components/TabAttachmentsContext/TabAttachmentsContext';
import {
    TabBrandingContext
} from 'app/view/react_components/aside_panel/boardDetails/branding/TabBranding/components/TabBrandingContext/TabBrandingContext';
import { onTabBrandingActive } from '../events/onTabBrandingActive';
import {
    TabBackupsContext
} from 'app/view/react_components/aside_panel/boardDetails/backups/TabBackups/components/TabBackupsContext/TabBackupsContext';
import { onTabBackupsActive } from '../events/onTabBackupsActive';
import {
    TabExportContext
} from 'app/view/react_components/aside_panel/boardDetails/export/components/TabExportContext/TabExportContext';
import { onTabExportActive } from '../events/onTabExportActive';
import { getAuthUserSupport } from '../../../../../../../store/model/authUser/selectors/getAuthUserSupport';
import { tabActivityClick } from '../../../../cardDetails/TabActivity/helpers/tabActivityClick';
import { isAllowActivity } from '../../../../../../../store/model/authUser/selectors/isAllowActivity';
import { checkTabActivityPermission } from '../effects/checkTabActivityPermission';
import { checkTabBrandingPermission } from '../effects/checkTabBrandingPermission';
import { checkBrandingTabAllow } from './checkBrandingTabAllow';
import { checkTabExportPermission } from '../effects/checkTabExportPermission';
import { checkExportTabAllow } from './checkExportTabAllow';
import { checkBackupsTabAllow } from './checkBackupsTabAllow';
import { checkTabBackupsPermission } from '../effects/checkTabBackupsPermission';

export const getBoardDetailsTabs = (
    state: IApplicationState,
    boardId: TBoardId
): IAsidePanelTab[]=> {
    let tabs = [...BOARD_DETAILS_TABS_BASE];
    const isNewFeature = false;
    if (isNewFeature) {
        const idx = tabs.findIndex(tab => tab.key === EBoardTab.ATTACHMENTS);
        tabs[idx] = {
            ...tabs[idx],
            isNewFeature: true
        };
    }
    // if (getAuthUserSupport(state, 'supportBranding')) {
        tabs.push(BOARD_DETAILS_TAB_BRANDING);
    // }
    if (getAuthUserSupport(state, 'supportBackup')) {
        tabs.push(BOARD_DETAILS_TAB_BACKUPS);
    }
    if (getAuthUserSupport(state, 'supportExport')) {
        tabs.push(BOARD_DETAILS_TAB_EXPORT);
    }
    return tabs;
}

const BOARD_DETAILS_TABS_BASE: ReadonlyArray<Readonly<IAsidePanelTab>> = Object.freeze([
    Object.freeze({
        body: <TabDetailsContext />,
        key: EBoardTab.DETAILS,
        name: getMessages().getText('aside_panel.tab.board_details'),
        panelActions: <PanelActionsContext />,
        route: ERoutes.BOARD_EDIT,
        url: EPanelRoute.EDIT,
        onActive: onTabDetailsActive,
    }),
    Object.freeze({
        body: <TabActivityBody />,
        key: EBoardTab.ACTIVITY,
        name: getMessages().getText('aside_panel.tab.activity'),
        panelActions: <PanelActionsContext />,
        route: ERoutes.BOARD_ACTIVITY,
        url: EPanelRoute.ACTIVITY,
        onActive: onTabActivityActive,
        onClick: tabActivityClick,
        checkPermission: isAllowActivity,
        onPermissionDenied: checkTabActivityPermission
    }),
    Object.freeze({
        body: <TabAttachmentsContext />,
        key: EBoardTab.ATTACHMENTS,
        name: getMessages().getText('aside_panel.tab.attachments'),
        panelActions: <PanelActionsContext />,
        route: ERoutes.BOARD_ATTACHMENTS,
        url: EPanelRoute.ATTACHMENTS,
        onActive: null,
    }),
]);

const BOARD_DETAILS_TAB_BRANDING = Object.freeze({
    body: <TabBrandingContext />,
    key: EBoardTab.BRANDING,
    name: getMessages().getText('aside_panel.tab.branding'),
    panelActions: <PanelActionsContext />,
    route: ERoutes.BOARD_DESIGN,
    url: EPanelRoute.DESIGN,
    onActive: onTabBrandingActive,
    checkPermission: checkBrandingTabAllow,
    onPermissionDenied: checkTabBrandingPermission
});

const BOARD_DETAILS_TAB_BACKUPS = Object.freeze({
    body: <TabBackupsContext />,
    key: EBoardTab.BACKUPS,
    name: getMessages().getText('aside_panel.tab.backups'),
    panelActions: <PanelActionsContext />,
    route: ERoutes.BOARD_BACKUPS,
    url: EPanelRoute.BACKUPS,
    onActive: onTabBackupsActive,
    checkPermission: checkBackupsTabAllow,
    onPermissionDenied: checkTabBackupsPermission
});

const BOARD_DETAILS_TAB_EXPORT = Object.freeze({
    body: <TabExportContext />,
    key: EBoardTab.EXPORT,
    name: getMessages().getText('aside_panel.tab.export'),
    panelActions: <PanelActionsContext />,
    route: ERoutes.BOARD_EXPORT,
    url: EPanelRoute.EXPORT,
    onActive: onTabExportActive,
    checkPermission: checkExportTabAllow,
    onPermissionDenied: checkTabExportPermission
});

export const BOARD_DETAILS_TABS: ReadonlyArray<Readonly<IAsidePanelTab>> = Object.freeze([
    ...BOARD_DETAILS_TABS_BASE,
    BOARD_DETAILS_TAB_BRANDING,
    BOARD_DETAILS_TAB_BACKUPS,
    BOARD_DETAILS_TAB_EXPORT
]);
