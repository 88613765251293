import { createRoot } from 'react-dom/client';
import React from "react";
import {Provider} from "react-redux";
import { LazyWrapper } from '../react_components/lazy/lazy_wrapper';

App.Views.ListSettings = App.Views.BaseView.extend({
    className: 'list-details__react-in-backbone',

    initialize: function (options) {
        this.listId = options.listId;
        this.root = createRoot(this.el);
        this.store = options.store;
    },

    initStore: function() {
        return import(/* webpackChunkName: "listDetails" */ '../react_components/aside_panel/listDetails/store/reducers/listDetailsReducer')
        .then((module) => {
            this.store.reducerManager.add('listDetails', module.listDetailsReducer);
            this.store.dispatch({type: 'async-init'}); // fake action to init new reducers
        })
        .catch(e => console.error(e))
    },

    render: function () {
        const ImportElement = React.lazy(() =>
            this.initStore()
                .then(() => import(/* webpackChunkName: "listDetails" */ '../react_components/aside_panel/asidePanel/hocs/AsidePanelListHOC/AsidePanelListHOC'))
                .then(module => ({default: module.AsidePanelListHOC}))
                .catch(e => console.error(e))
        );
        this.root.render(
            <LazyWrapper>
                <Provider store={this.store}>
                    <ImportElement
                        closedRef={document.activeElement}
                        listId={this.listId}
                    />
                </Provider>
            </LazyWrapper>
        );
        return this;
    },

    remove: function () {
        setTimeout(() => {
            setTimeout(() => {
                this.root.unmount(); // надо дождаться пока сработает react onblur, только потом отцеплять конпонент
            });
        })
        Backbone.View.prototype.remove.call(this);
    }
});

export default App.Views.ListSettings;
