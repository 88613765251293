import { connect } from 'react-redux';
import { DeleteButton } from '../../components/DeleteButton/DeleteButton';
import { IApplicationState } from '../../../../../../../types/types';
import { IDeleteButtonEvents, IDeleteButtonFields } from '../../components/DeleteButton/types';
import { IDeleteActionButtonHOCProps } from './types';
import { DELETE_TEXT_TOOLTIP_VALUE } from './constants';
import { onClick } from './events/onClick';
import { onDelKey } from './events/onDelKey';
import { getPanelButtonShow } from '../../../selectors/getPanelButtonShow';
import { DELETE_BUTTON_TITLE } from '../../components/DeleteButton/constants';
import { onShowSnackbar } from './events/onShowSnackbar';

const mapStateToProps = (
        state: IApplicationState,
        { isShowTooltip, boardId, cardId, className, isShow }: IDeleteActionButtonHOCProps,
    ): IDeleteButtonFields => {
    const tooltip = isShowTooltip
        ? DELETE_TEXT_TOOLTIP_VALUE
        : null;
    isShow = isShow || getPanelButtonShow(state, boardId, cardId, null, null, null, false);

    return {
        className,
        isShow,
        tooltip,
        title: DELETE_BUTTON_TITLE
    };
};

const mapDispatchToProps = (
    dispatch: any,
    {cardId}: IDeleteActionButtonHOCProps
): IDeleteButtonEvents => ({
    onClick: () => dispatch(onClick(cardId)),
    onDelKey: ()  => dispatch(onDelKey(cardId)),
    onShowSnackbar: () => dispatch(onShowSnackbar()),
});

export const DeleteButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(DeleteButton);
