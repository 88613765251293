import { TBoardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { fetchHandler } from '../../../../util/fetchHandler';
import { Util } from '../../../../util/util';
import { REST_BOARD } from '../../../constants';
import { getRestHeadersPost } from '../../../helpers/getRestHeadersHelper';
import { IBoard } from '../../../../store/model/board/types';

export const copyRest = (
    boardId: TBoardId,
    prefix: string,
    template: boolean = false
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        return fetchHandler<IBoard>(
            Util.getApiUrl(`${REST_BOARD}${boardId}/copy?prefix=${prefix}&template=${template}`), {
                ...getRestHeadersPost(),
            }
        );
    };
    return action;
};
