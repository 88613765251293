import { ICard } from '../../card/types';
import { ICardsComparator } from './types';

const getCardValue = (
    card: ICard
): number => card.processingPercent || 0;

const sort = (
    processingPercentA: number,
    processingPercentB: number,
    isDesc: boolean
) => {
    return !isDesc ? processingPercentA - processingPercentB : processingPercentB - processingPercentA;
}

export const processingPercent: ICardsComparator = {
    getCardValue,
    sort
}
