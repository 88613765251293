import { TCondition } from '../types';
import { IApplicationState } from '../../../../types/types';
import { EHintKey, getHintConfig } from '../constants';
import { isHideTopBar } from '../../main/topBar/helpers/isHideTopBar';
import { getAsidePanel } from '../../aside_panel/asidePanel/store/selectors/getAsidePanel';
import { getRouterBoardId } from 'app/store/router/selectors/getRouterBoardId';

export const topBarFiltrationCondition: TCondition = (
    state: IApplicationState
) => {
    const hint = getHintConfig(EHintKey.TOP_BAR_FILTER_ICONS);
    if (!hint) return false;

    if (
        !getRouterBoardId(state) || // нет доски
        !!getAsidePanel(state).width // открыта панель
    ) return false;

    return !isHideTopBar();
};
