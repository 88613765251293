import {ActionCreator} from 'redux';
import {Dispatch, ThunkAction} from '../../../../../../../types/actions';
import {IGetState} from '../../../../../../../types/types';
import {isOpenNavigationPanelSelector} from '../../../store/selectors/isOpenNavigationPanelSelector';
import {isOpenSetAction} from '../../../store/navigationPanel/actions/isOpenSetAction';
import { navPanelOpenedMouseEnterSegmentEvent } from '../../../effects/segmentEvents';

export const onPanelMouseHold: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch,
    getState: IGetState
) => {
    const state = getState();
    const isOpen = isOpenNavigationPanelSelector(state);
    if(!isOpen){
        dispatch(isOpenSetAction(true));
        dispatch(navPanelOpenedMouseEnterSegmentEvent());
    }
};
