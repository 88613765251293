import { ONE_HOUR } from '../../../../view/react_components/main/kanbanView/components/CardAgingIndicator/constants';
import { commonAuthManagerInstance } from '../../common/CommonAuthManager';
import { root } from '../../../../store/constants';
import { Util } from '../../../../util/util';
import { THUMB_CARD_SIZE } from '../../../../const';

export enum EGoogleFileThumbnailMode {
    REAL_TIME = 0,
    CACHE = 1,
}

type TFileThumbnails = {
    [id: string]: IFileThumbnail;
};

interface IFileThumbnail {
    timestamp: number;
    thumbnail: Promise<TThumbnail>;
}

type TThumbnail = string;

class GoogleFileThumbnail {
    thumbnails: TFileThumbnails;

    init (
    ) {
        this.thumbnails = {};
    }

    is (
    ) {
        return !!this.thumbnails;
    }

    setSize (
        url: string,
        size: string
    ): string {
        return size ?
            url.replace(/=.\d+/g, '=' + size)
            : url.replace(/=.\d+/g, '');
    }

    reGetThumbnail (
        fileId: string,
        size: string,
        setThumbUrl: (thumbUrl: string) => void,
        onError?: () => void,
    ) {
        return this.getThumbnail(
            fileId,
            size,
            EGoogleFileThumbnailMode.CACHE
        ).then((thumb) => {
            setThumbUrl(thumb);
            // если успешно - значит сломался первый метод, переключаем на второй до конца сессии
            if (root.Settings.googleThumbnailMode !== EGoogleFileThumbnailMode.CACHE) {
                root.App.controller.toggleGoogleThumbnailMode(true);
            }
        }).catch(() => {
            if (onError) onError();
        });
    }

    getThumbnail (
        fileId: string,
        size: string,
        method: EGoogleFileThumbnailMode = root.Settings.googleThumbnailMode,
    ): Promise<TThumbnail> {
        if (!this.thumbnails) return null;

        if (method === EGoogleFileThumbnailMode.CACHE) {
            if(!this.thumbnails[fileId] || Date.now() - this.thumbnails[fileId].timestamp < ONE_HOUR) {
                this.thumbnails[fileId] = {
                    timestamp: Date.now(),
                    thumbnail: commonAuthManagerInstance.getAccessTokenOrShowLoginScreen().then((accessToken) => {
                        return new Promise((resolve, reject) => {
                            root.gapi.client.request({
                                path: 'drive/v2/files/' + fileId,
                                method: 'GET',
                                'params': {'supportsAllDrives': 'true'}
                            }).then((resp: any) => {
                                resolve(resp.result.thumbnailLink);
                            }).catch(() => {
                                reject();
                            });
                        });
                    })
                };
            }
            return this.thumbnails[fileId].thumbnail.then((url) => this.setSize(url, size));
        } else {
            return Promise.resolve(this.getThumbnailRealTime(fileId, size));
        }
    }

    getThumbnailRealTime(
        fileId: string,
        size: string
    ){
        return Promise.resolve(Util.getThumbUrl(`https://drive.google.com/thumbnail?authuser=0&sz=w28&id=${fileId}`, size));
    }
}

export const googleFileThumbnailStorage = new GoogleFileThumbnail();
