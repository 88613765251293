import _ from 'underscore';
import { getCardCalendarEvents } from '../../../../../../../store/model/selectors/getCardCalendarEvents';
import store from '../../../../../../../store/configureStore';

const filterCalendars = (selectedCardId, selectedActionType) => {
    const pushed = getCardCalendarEvents(store.getState(), selectedCardId);
    return _.where(pushed, {type: selectedActionType});
};

export default filterCalendars;
