import * as React from 'react';
import { CLASS_MY_WORK_CARD } from '../../../constants';
import './_CardList.scss';
import { clearMarkdown } from '../../../../../base/helpers/clearMarkdownHelper';
import { Button, Tooltip } from 'kui';
import { escape } from 'underscore';
import { TOOLTIP_LIST } from '../constants';
import { ICardListProps } from './types';
import ReactHtmlParser from 'react-html-parser';

export function CardList({
    listName,
    onOpenLists
}: ICardListProps) {
    const className = CLASS_MY_WORK_CARD + '__list-name';

    const [name, setName] = React.useState('');
    React.useEffect(() => {
        setName(escape(clearMarkdown(listName)));
    }, [listName]);

    return (
        <div className={className}>
            <Tooltip
                delayClose={1}
                value={TOOLTIP_LIST + name}
                isNoEvents
            >
                <Button
                    className={className + '--list'}
                    variant={'icon-text'}
                    onClick={onOpenLists}
                >
                    {ReactHtmlParser(name)}
                </Button>
            </Tooltip>
        </div>
    );
}
