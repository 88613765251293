import * as React from 'react';
import { IAsidePanelTab } from '../asidePanel/types';
import { ECardTab } from './types';
import { getMessages } from '../../../../store/constants';
import { TabDetailsContext } from './TabDetails/containers/TabDetailsContext/TabDetailsContext';
import { TabActivityContext } from './TabActivity/components/TabActivityContext/TabActivityContext';
import { PanelActionsContext } from './PanelActions/components/PanelActionsContext/PanelActionsContext';
import { TabTimingContext } from './TabTimingSection/components/TabTimingContext/TimingTabContext';
import { onTabDetailsActive } from '../asidePanel/hocs/AsidePanelCardHOC/events/onTabDetailsActive';
import { onTabActivityActive } from '../asidePanel/hocs/AsidePanelCardHOC/events/onTabActivityActive';
import { onTabTimingActive } from '../asidePanel/hocs/AsidePanelCardHOC/events/onTabTimingActive';
import { checkTabTimingPermission } from './TabTimingSection/constants';
import { isAllowTimeTracker } from '../../../../store/model/authUser/selectors/isAllowTimeTracker';
import { EPanelRoute, ERoutes } from '../../../../store/router/constants';
import { tabActivityClick } from './TabActivity/helpers/tabActivityClick';
import { ViewOnlyContext } from './ViewOnlyContext/ViewOnlyContext';
import { isAllowActivity } from '../../../../store/model/authUser/selectors/isAllowActivity';
import { checkTabActivityPermission } from './TabActivity/effects/checkTabActivityPermission';

export const CLASS_ASIDE_CARD = 'aside-panel--card';
export const CLASS_CARD_DETAILS = 'card-details';
export const CLASS_CARD_DETAILS_SECTION = 'card-details-section';
export const CLASS_CARD_DETAILS_READ_ONLY = CLASS_CARD_DETAILS + '--readonly';
export const CLASS_SECTION_ICON_PROGRESS_PRIORITY_WATCH = CLASS_CARD_DETAILS + '-icon-progress-priority-watch';
export const CLASS_SECTION_ICON_PROGRESS_PRIORITY = CLASS_CARD_DETAILS + '-icon-progress-priority';
export const CLASS_SECTION_WATCH = CLASS_CARD_DETAILS + '-watch';
export const CLASS_SECTION_TITLE = CLASS_CARD_DETAILS + '-section-title';
export const CLASS_CARD_SECTION_RECURRING = CLASS_CARD_DETAILS_SECTION + '-recurring'
export const CLASS_TIME_DROPDOWN = 'card-details__time-dropdown';

export const CD_DEFAULT_CARD_NAME = 'Untitled Card';

export const CARD_DETAILS_TABS: ReadonlyArray<Readonly<IAsidePanelTab>> = Object.freeze([
    Object.freeze({
        body: <TabDetailsContext />,
        button: <ViewOnlyContext />,
        key: ECardTab.DETAILS,
        name: getMessages().getText('aside_panel.tab.card_details'),
        panelActions: <PanelActionsContext />,
        route: ERoutes.EDIT_CARD,
        onActive: onTabDetailsActive,
    }),
//     {
//         body: 'comments' as any,
//         key: ECardTab.COMMENTS,
//         name: getMessages().getText('aside_panel.tab.comments'),
//         panelActions: <PanelActionsContext />,
//         onActive: onTabActive,
//         url: '/comments'
//     },
    Object.freeze({
        body: <TabActivityContext />,
        key: ECardTab.ACTIVITY,
        name: getMessages().getText('aside_panel.tab.activity'),
        panelActions: <PanelActionsContext />,
        route: ERoutes.CARD_ACTIVITY,
        url: EPanelRoute.ACTIVITY,
        onActive: onTabActivityActive,
        onClick: tabActivityClick,
        checkPermission: isAllowActivity,
        onPermissionDenied: checkTabActivityPermission
    }),
    Object.freeze({
        body: <TabTimingContext />,
        key: ECardTab.TIMING,
        name: getMessages().getText('aside_panel.tab.timing'),
        panelActions: <PanelActionsContext />,
        route: ERoutes.CARD_TIMING,
        url: EPanelRoute.TIMING,
        onActive: onTabTimingActive,
        checkPermission: isAllowTimeTracker,
        onPermissionDenied: checkTabTimingPermission,
        isProFeature: true
    })
]);
