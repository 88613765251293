import { IApplicationState } from '../../../../types/types';
import { getHint } from '../../../../view/react_components/hints/store/hints/selectors/getHint';
import { EHintKey, EHintStatus } from '../../../../view/react_components/hints';
import {
    getShowGanttContextHints
} from '../../../../view/react_components/hints/conditions/ganttContextConditions/getShowGanttContextHints';

export const getAuthUserIsShowGanttContextHints = (
    state: IApplicationState
): boolean => {
    return getHint(state, EHintKey.GANTT_CONTEXT_GANTT_VIEW_RESIZE).status !== EHintStatus.SHOWN &&
        getShowGanttContextHints(state);
};
