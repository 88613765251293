import { INavigationPanelProps } from './types';
import * as React from 'react';
import { NavigationPanelCollapseHOC } from '../../hocs/NavigationPanelCollapseHOC/NavigationPanelCollapseHOC';
import { NavigationPanelTabsHOC } from '../../hocs/NavigationPanelTabsHOC/NavigationPanelTabsHOC';
import {
    ENavigationPanelTypes,
    ENavigationPanelWidths,
    isWindowMobile,
    NAVIGATION_PANEL_ASIDE_CLASS,
    NAVIGATION_PANEL_CLASS,
    NAVIGATION_PANEL_CLOSE_MARKER,
    NAVIGATION_PANEL_CONTAINER_CLASS,
    NAVIGATION_PANEL_DROPDOWN_CLASS,
    NAVIGATION_PANEL_INNER_CLASS,
    NAVIGATION_PANEL_THRESHOLD,
    NP_AUTO_COLLAPSE_TIMER,
    NP_AUTO_EXPAND_TIMER,
    NP_EXPAND_TIMER
} from '../../constants';
import './_NavigationPanel.scss';
import { getParentsClasses } from '../../../../helpers/getParentsClasses';
import { root } from 'app/store/constants';
import { NavigationPanelCreateBoardHOC } from '../../hocs/NavigationPanelCreateBoardHOC/NavigationPanelCreateBoardHOC';
import { SegmentNavPanelEvent, segmentTrackAction } from '../../../../../../middlewares/segment';
import { dispatch } from '../../../../../../store/configureStore';
import { CLASS_ASIDE_PANEL, CLASS_MAIN_PANEL } from '../../../../../../const';
import { NavigationPanelAssignedToMeHOC } from '../../hocs/NavigationPanelAssignedToMeHOC/NavigationPanelAssignedToMeHOC';
import { NavigationPanelBrowseBoardsHOC } from '../../hocs/NavigationPanelBrowseBoardsHOC/NavigationPanelBrowseBoardsHOC';
import { NavigationPanelAnchorHOC } from '../../hocs/NavigationPanelAnchorHOC/NavigationPanelAnchorHOC';
import { NavigationPanelQualificationFormHOC } from '../../hocs/NavigationPanelQualificationFormHOC/NavigationPanelQualificationFormHOC';
import { CREATE_ON_SHARED_DRIVE_CLASS } from '../../components/NavigationPanelCreateBoardDropDown/constants';
import { ProfileHOC } from '../../../../profile/hocs/ProfileHOC/ProfileHOC';
import { NavPanelHelpHOC } from 'app/view/react_components/dialogs/feedback/hocs/NavPanelHelpHOC/NavPanelHelpHOC';
import { NotificationsHOC } from 'app/view/react_components/notifications/hocs/NotificationsHOC/NotificationsHOC';
import { TimeTrackerHOC } from '../../../../timeTracker/hocs/TimeTrackerHOC/TimeTrackerHOC';
import { InWorkCardHOC } from '../../../../inWorkCard/hocs/InWorkCardHOC/InWorkCardHOC';
import { PanelsBlur } from 'app/view/react_components/base/components/PanelsBlur/PanelsBlur';
import { EPanelsBlurType } from 'app/view/react_components/base/components/PanelsBlur/types';
import { NavigationPanelLogoHOC } from '../../hocs/NavigationPanelLogoHOC/NavigationPanelLogoHOC';
import {
    NavigationPanelQuickStartGuideHOC
} from '../../hocs/NavigationPanelQuickStartGuide/NavigationPanelQuickStartGuideHOC';
import { EProfileUserWhere } from '../../../../profile/constants';
import { Button, Icon } from 'kui';
import { NAVIGATION_PANEL_MIN_SCROLL_TOP } from './constants';
import { NAVIGATION_PANEL_DASHBOARD_ACTION_CLASS } from '../../components/NavigationPanelTabItem/constants';

export function NavigationPanel ({
    hasOpenedBoard,
    isOpen,
    navigationPanelType,
    showCollapseButton,
    onMouseHold,
    onMouseOut,
    setMobile,
    isShow,
    isShowInWork,
    isShowTimeTracker,
    isAsideOpen,
    onTypeChange
}: INavigationPanelProps) {
    let [isPanelOpened, setIsPanelOpened] = React.useState(isOpen);
    let [isMobileType, setIsMobileType] = React.useState(navigationPanelType === ENavigationPanelTypes.NP_MOBILE_TYPE);
    let [actionCount, setActionsCount] = React.useState(0);
    const [isNotificationsOpen, setIsNotificationsOpen] = React.useState(false);
    const [isShowScrollTop, setShowScrollTop] = React.useState(null);

    const typeClass = NAVIGATION_PANEL_CLASS + '--' + navigationPanelType;
    const openClass = isPanelOpened ? NAVIGATION_PANEL_CLASS + '--opened' : '';
    const classNoBoard = !hasOpenedBoard ? NAVIGATION_PANEL_CLASS + '--no-board' : '';
    const classWidgets = NAVIGATION_PANEL_CLASS + '__widgets';
    const classOverlay = NAVIGATION_PANEL_CLASS + '__overlay';
    const classBlock = NAVIGATION_PANEL_CLASS + '__block';
    const classBlockTop = classBlock + ' ' + classBlock + '--top';
    const classBlockBottom = classBlock + ' ' + classBlock + '--bottom';
    const classNotificationsLeft = 'notifications--left'
    const classScrollTop = NAVIGATION_PANEL_CLASS + '__scroll-top';

    const innerRef = React.useRef(null);
    const navRef = React.useRef(null);
    const timerRef = React.useRef(null);
    const debounce = React.useRef(null);
    const topRef = React.useRef(null);

    function onScrollEnd() {
        if (!navRef.current) return;

        navRef.current.classList.add('prevent_scroll'); // set overflow hidden to stop inertial scroll
        setTimeout(() => navRef.current.classList.remove('prevent_scroll'), 400);
        if (navRef.current.scrollLeft > NAVIGATION_PANEL_THRESHOLD) {
            setTimeout(onMouseOut, 100);
            navRef.current.scrollLeft = ENavigationPanelWidths.big;
        } else {
            navRef.current.scrollLeft = 0;
        }
    }

    function onTouchEnd() {
        setTimeout(onScrollEnd, 100);
    }

    const onMouseMoveHandler = () => {
        if (isMobileType) return;

        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(() => {
            const isNavPanelNotBig = navigationPanelType !== ENavigationPanelTypes.NP_BIG_TYPE;
            if (!isPanelOpened && isNavPanelNotBig) {
                onMouseHold();
            }
        }, NP_AUTO_EXPAND_TIMER);
    };

    const onMouseLeaveHandler = () => {
        if (isMobileType) return;
        if (navigationPanelType === ENavigationPanelTypes.NP_BIG_TYPE) return;

        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(closePanel, NP_AUTO_COLLAPSE_TIMER);
    };

    const onBlurHandler = (e: FocusEvent) => {
        if (navigationPanelType === ENavigationPanelTypes.NP_BIG_TYPE) return;

        const target = e.relatedTarget as HTMLElement;
        if (!target) return;

        if (timerRef.current) {
            clearTimeout(timerRef.current);
            timerRef.current = null;
        }
        const path = getParentsClasses(target, [
            NAVIGATION_PANEL_INNER_CLASS,
            NAVIGATION_PANEL_DROPDOWN_CLASS,
            NAVIGATION_PANEL_ASIDE_CLASS, // не закрывть navPanel в таблет режиме при открытии aside (notifications, profile)
        ]);
        if (
            !path.includes(NAVIGATION_PANEL_INNER_CLASS) &&
            !path.includes(NAVIGATION_PANEL_DROPDOWN_CLASS) &&
            !path.includes(NAVIGATION_PANEL_ASIDE_CLASS)
        ) {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
            timerRef.current = setTimeout(closePanel, NP_AUTO_EXPAND_TIMER);
        }
    };

    const isFocusContainer = () => {
        const activeElement = document.activeElement as HTMLElement;
        const path = getParentsClasses(activeElement, [
            NAVIGATION_PANEL_CONTAINER_CLASS
        ]);
        return path.includes(NAVIGATION_PANEL_CONTAINER_CLASS);
    };

    const isFocusAsidePanel = () => {
        const activeElement = document.activeElement as HTMLElement;
        const path = getParentsClasses(activeElement, [
            CLASS_ASIDE_PANEL
        ]);
        return path.includes(CLASS_ASIDE_PANEL);
    };

    /**
     * убирает фокус с панели, если фокус в панели
     */
    const mainFocus = () => {
        if (isFocusContainer()) {
            const main = document.querySelector('.' + CLASS_MAIN_PANEL) as HTMLElement;
            if (main) main.focus();
        }
    };

    const onClickHandler = (e: React.MouseEvent<HTMLElement>) => {
        if (
            navigationPanelType === ENavigationPanelTypes.NP_BIG_TYPE ||
            !e.clientX && !e.clientY // если нажали энтер, фокус остаётся в панели, панель не закрывается
        ) return;

        const element = e.target as HTMLElement;
        const path = getParentsClasses(element, [
            NAVIGATION_PANEL_CLOSE_MARKER,
            NAVIGATION_PANEL_INNER_CLASS,
            CREATE_ON_SHARED_DRIVE_CLASS
        ]);
        if (path.includes(NAVIGATION_PANEL_CLOSE_MARKER)) {
            closePanel();
            if (debounce.current) clearTimeout(debounce.current);
            debounce.current = setTimeout(mainFocus, NP_AUTO_COLLAPSE_TIMER);
        }
    };

    const onNavPanelClick = () => {
        const isCompactView = navigationPanelType === ENavigationPanelTypes.NP_COMPACT_TYPE;

        if (isCompactView) {
            setActionsCount(actionCount++);
        }
    };

    const closePanel = () => {
        timerRef.current = null;
        if (!actionCount && isPanelOpened && navigationPanelType !== ENavigationPanelTypes.NP_BIG_TYPE) {
            dispatch(segmentTrackAction(SegmentNavPanelEvent.NO_ACTIONS));
        } else {
            setActionsCount(0);
        }
        onMouseOut();
    };

    const checkBlockTopScroll = () => {
        if (!topRef.current) return;
        topRef.current.classList.toggle('scroll', topRef.current.scrollHeight > topRef.current.offsetHeight);
    };

    const updateIsNotificationsOpen = (value: boolean) => {
        setIsNotificationsOpen(value)
    }

    const onScrollBlockTop = () => {
        const scrollEl = topRef.current as HTMLElement;
        if (!scrollEl) return;
        setShowScrollTop(scrollEl.scrollTop > NAVIGATION_PANEL_MIN_SCROLL_TOP);
    };

    const onScrollTop = () => {
        const scrollEl = topRef.current as HTMLElement;
        if (!scrollEl) return;
        const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
        if (supportsNativeSmoothScroll) {
            scrollEl.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
            scrollEl.scrollTop = 0;
        }
    };

    React.useEffect(() => {
        function checkMobile() {
            const isMobile = isWindowMobile();
            if (isMobile && !isMobileType) {
                setMobile(true);
            } else if (!isMobile && isMobileType) {
                setMobile(false);
            }
        }

        function debounceCheck() {
            checkMobile();
            checkBlockTopScroll();
        }

        function onResizeWindow() {
            if (debounce.current) clearTimeout(debounce.current);
            debounce.current = setTimeout(debounceCheck, 500);
        }
        onResizeWindow();
        isMobileType = navigationPanelType === ENavigationPanelTypes.NP_MOBILE_TYPE;
        setIsMobileType(isMobileType);
        root.removeEventListener('resize', onResizeWindow);
        root.addEventListener('resize', onResizeWindow);
        onTypeChange();

        if (navigationPanelType === ENavigationPanelTypes.NP_COMPACT_TYPE) { // чтобы закрыть панель, надо убрать с неё фокус
            mainFocus();
        }

        function onBlurContainer (e: FocusEvent) {
            if (timerRef.current) clearTimeout(timerRef.current);
            timerRef.current = setTimeout(() => {
                if ((
                        !isFocusContainer() &&
                        // почему-то фокус на body когда переключаешься на нотификации или на экшены с другого дропдауна в #navigation-aside-portal
                        !document.querySelector('.notifications__dropdown--opened')
                    ) &&
                    navigationPanelType === ENavigationPanelTypes.NP_COMPACT_BIG_TYPE
                ) {
                    onTypeChange(ENavigationPanelTypes.NP_COMPACT_TYPE);
                } else {
                    onBlurHandler(e);
                }
            }, NP_EXPAND_TIMER);
        }
        const container = document.querySelector('.' + NAVIGATION_PANEL_CONTAINER_CLASS) as HTMLElement;
        if (container) container.addEventListener('focusout', onBlurContainer);

        return () => {
            root.removeEventListener('resize', onResizeWindow);
            if (container) container.removeEventListener('focusout', onBlurContainer);
        };
    }, [navigationPanelType]);

    React.useEffect(() => {
        if (navRef.current) navRef.current.scrollLeft = 0;
        isPanelOpened = isOpen;
        setIsPanelOpened(isPanelOpened);
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
    }, [isOpen]);

    React.useEffect(() => {
        const scrollObserver = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                const nav = navRef.current as HTMLElement;
                if (nav) checkBlockTopScroll();
            });
        });
        const nav = navRef.current;
        if (nav) scrollObserver.observe(nav, { childList: true, subtree: true });

        return () => {
            if (timerRef.current) clearTimeout(timerRef.current);
            if (debounce.current) clearTimeout(debounce.current);
        };
    }, []);

    React.useEffect(() => {
        if (!navRef.current) return;

        if (navRef.current.classList.contains('navigation-panel--opened')) {
            navRef.current.style.width = navRef.current.clientWidth + 'px';
        } else {
            navRef.current.style.removeProperty('width');
        }
    },[openClass])

    return (<>
        {isShow ? <React.Fragment>
            <section
                className={`
                    ${NAVIGATION_PANEL_CLASS}
                    ${typeClass}
                    ${openClass}
                    ${classNoBoard}
                    ${isAsideOpen ? NAVIGATION_PANEL_CLASS + '--aside' : ''}
                `}
                ref={navRef}
                onTouchEnd={onTouchEnd}
                onTouchCancel={onTouchEnd}
                onClick={onNavPanelClick}
            >
                <div className={NAVIGATION_PANEL_INNER_CLASS}
                     onMouseMove={onMouseMoveHandler}
                     onMouseLeave={onMouseLeaveHandler}
                     ref={innerRef}
                     tabIndex={-1}
                     aria-disabled // if element has 'aria-' focus stays here, doesn't back to button
                     onClick={onClickHandler}
                >
                    <Button
                        className={`${classScrollTop} ${isShowScrollTop ? classScrollTop + '--visible' : ''}`}
                        onClick={onScrollTop}
                        variant={'icon'}
                    >
                        <Icon xlink={'back'} size={24} />
                    </Button>
                    <div
                        className={classBlockTop}
                        ref={topRef}
                        onScroll={onScrollBlockTop}
                    >
                        <NavigationPanelLogoHOC />
                        {showCollapseButton &&
                            <NavigationPanelCollapseHOC
                                isInPanel={true}
                                updateIsNotificationsOpen={updateIsNotificationsOpen}
                            />
                        }
                        <NavigationPanelCreateBoardHOC/>
                        <NavigationPanelBrowseBoardsHOC />
                        <NavigationPanelAssignedToMeHOC />
                        <NotificationsHOC
                            isNotificationsOpen={isNotificationsOpen}
                            updateNotificationsOpen={updateIsNotificationsOpen}
                        />
                        <NavigationPanelTabsHOC/>
                    </div>

                    <div className={classBlockBottom}>
                        {(isShowInWork || isShowTimeTracker) &&
                            <div className={`
                                ${classWidgets}
                                ${isShowInWork && isShowTimeTracker ? classWidgets + '--2' : ''}
                            `}>
                                {isShowInWork &&
                                    <InWorkCardHOC />
                                }
                                {isShowTimeTracker &&
                                    <TimeTrackerHOC />
                                }
                            </div>
                        }
                        <NavigationPanelQualificationFormHOC />
                        <NavigationPanelQuickStartGuideHOC />
                        <div className="navigation-panel__user-help">
                            <ProfileHOC profileUserWhere={EProfileUserWhere.NAVPANEL} />
                            <NavPanelHelpHOC />
                        </div>
                    </div>
                </div>
            </section>
            <div
                className={`
                    ${classOverlay}
                    ${isAsideOpen ? classOverlay + '--aside' : ''}
                `}
                onClick={onMouseOut}
            />
            <div className={NAVIGATION_PANEL_CLASS + '--placeholder'}/>
            <NavigationPanelAnchorHOC/>
            <NavigationPanelCollapseHOC
                updateIsNotificationsOpen={updateIsNotificationsOpen}/>
        </React.Fragment> : null}
        <PanelsBlur type={EPanelsBlurType.LEFT} />
        <div id={'navigation-aside-portal'} className={isPanelOpened ? '' : classNotificationsLeft}/>
    </>);
}
