import { IApplicationState } from '../../../../../../../types/types';
import { getAuthUser } from '../../../../../../../store/model/authUser/selectors/getAuthUser';

export const getOpened = (
    state: IApplicationState
): boolean => {
    const user = getAuthUser(state);
    return (
        user &&
        !user.anonym &&
        state.openBoardsDialog &&
        state.openBoardsDialog.common &&
        state.openBoardsDialog.common.isOpened
    );
}
