import * as React from 'react';
import { IAssigneesProps } from './types';
import { CLASS_CARD_DETAILS_SECTION } from '../../../constants';
import { CARD_ASSIGNEE_ADD } from './constants';
import { AssigneesElements } from '../AssigneesElements/AssigneesElements';
import { SettingsSection } from '../../../../../base/components/SettingsSection/SettingsSection';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { CARD_SECTION_ASSIGNEES } from '../constants';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { EAsidePanelProperty } from '../../../../asidePanel/components/AsidePanel/types';
import { AssigneesSearchSelectHOC } from '../../hocs/AssigneesSearchSelectHOC/AssigneesSearchSelectHOC';
import { SEARCH_SELECT_INPUT_SELECTOR } from '../../../../../base/components/SearchSelect/constants';
import { CLASS_SETTINGS_SECTION_EMPTY } from '../../../../../base/components/SettingsSection/constants';
import { AnalyzeRender } from 'app/view/react_components/helpers/memoizeHelper';

//@ts-ignore
const analyzeRender = window.Settings.development ? new AnalyzeRender(`AssigneesSection`) : null;

export function AssigneesSection ({
    allowRoles,
    boardId,
    cardId,
    elements,
    inWorkUsers,
    isDefaultProperty,
    isRequired,
    sectionType,
    onClickDisallow,
    onClose,
    onDelete,
    onSort,
    onOpen,
    onToggleRole,
    onEditRoles,
    ...attributes
}: IAssigneesProps) {
    if (!elements && sectionType === ESettingsSectionType.READONLY) return;

    if (analyzeRender) analyzeRender.call(cardId);

    const isPopup = sectionType === ESettingsSectionType.POPUP;
    const className = CLASS_CARD_DETAILS_SECTION + ' ' + CLASS_CARD_DETAILS_SECTION + '--assignees';
    const classNameEmpty = !elements || !elements.length ? CLASS_SETTINGS_SECTION_EMPTY : '';
    const { openedProperty, addShowedProperty } = React.useContext(AsidePanelContext);
    const [isOpenedHook, setIsOpened] = React.useState(isPopup);
    const [isShow, setShow] = React.useState(false);
    const onAddOpened = () => {
        setIsOpened(true);
        onOpen();
    };
    const onAddClosed = () => {
        setIsOpened(false);
        if (onClose) onClose();
    };

    React.useEffect(() => {
        if ((elements && elements.length) || isDefaultProperty) {
            setShow(true);
            addShowedProperty(EAsidePanelProperty.CARD_ASSIGNEES);
        }
    }, [elements, isDefaultProperty]);

    if (
        (!isShow && !isPopup) ||
        (isShow && !isPopup && openedProperty === EAsidePanelProperty.CARD_ASSIGNEES)
    ) {
        return;
    }

    return (
        <SettingsSection
            addTitle={ CARD_ASSIGNEE_ADD }
            addComponent={
                isOpenedHook ? (
                    <AssigneesSearchSelectHOC
                        boardId={ boardId }
                        cardIds={ [cardId] }
                        isPopup={ isPopup }
                        isOpened={ isOpenedHook }
                        onAddClosed={ onAddClosed }
                    />
                ) : (
                    <div/>
                )
            }
            className={`
                ${className}
                ${classNameEmpty}
            `}
            focusSelector={SEARCH_SELECT_INPUT_SELECTOR}
            isAddWide
            isOpened={ isOpenedHook }
            title={ CARD_SECTION_ASSIGNEES }
            sectionType={ sectionType }
            onAddOpened={ onAddOpened }
            onAddClosed={ onAddClosed }
            isRequired={isRequired}
            isRequiredError={!elements || !elements.length}
            { ...attributes }
        >
            <AssigneesElements
                allowRoles={allowRoles}
                elements={ elements }
                inWorkUsers={ inWorkUsers }
                sectionType={ sectionType }
                onClickDisallow={onClickDisallow}
                onDelete={ onDelete }
                onSort={ onSort }
                onToggleRole={onToggleRole}
                onEditRoles={onEditRoles}
            />
        </SettingsSection>
    );
};
