import { connect } from 'react-redux';
import { IApplicationState } from 'app/types/types';
import { AsidePanelClose } from '../../components/AsidePanelClose/AsidePanelClose';
import { IAsidePanelCloseDispatchProps, IAsidePanelCloseStateProps } from '../../components/AsidePanelClose/types';
import { onClose } from './events/onClose';
import { IAsidePanelCloseHOCProps } from './types';
import { getLinkCardId } from 'app/store/router/selectors/getLinkCardId';
import { getCard } from 'app/store/model/selectors/getCard';
import { TStatus } from 'app/types/model';
import { SegmentCardDetailsCloseSourceValue } from '../../../../../../middlewares/segment/trackEntities/cardEvents';

const mapStateToProps = (
    state: IApplicationState,
    { isForceClosed }: IAsidePanelCloseHOCProps
): IAsidePanelCloseStateProps => {
    let isArchive: boolean;
    let isCardLink: boolean;
    const linkCardId = getLinkCardId(state.router);
    if (linkCardId) {
        isCardLink = true;
        const card = getCard(state, linkCardId);
        if (card && card.status === TStatus.STATUS_ARCHIVE) {
            isArchive = true;
        }
    }

    return {
        isArchive,
        isCardLink,
        isForceClosed,
    };
}

const mapDispatchToProps = (
    dispatch: any,
    props: IAsidePanelCloseHOCProps
): IAsidePanelCloseDispatchProps => {
    const { onClose: onCloseOwn, boardId } = props;
    return {
        onClose: (source: SegmentCardDetailsCloseSourceValue) => dispatch(onClose(boardId, onCloseOwn, source))
    }
};

export const AsidePanelCloseHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(AsidePanelClose);

AsidePanelCloseHOC.displayName = 'AsidePanelCloseHOC';
