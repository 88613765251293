import { connect } from 'react-redux';
import { IApplicationState } from 'app/types/types';
import { IAsidePanelBodyHOCProps } from './types';
import { AsidePanelBody } from '../../components/AsidePanelBody/AsidePanelBody';
import { IAsidePanelBodyFields } from '../../components/AsidePanelBody/types';
import { getAsidePanelButtonsPanelIsMinimized } from '../../store/selectors/getAsidePanelButtonsPanelIsMinimized';
import { EViewTypes } from 'app/const';
import { getLinkCardId } from 'app/store/router/selectors/getLinkCardId';
import { getCard } from 'app/store/model/selectors/getCard';
import { TStatus } from 'app/types/model';
import { getTypeSwitcherActiveType } from 'app/view/react_components/typeSwitcher/store/selectors/getTypeSwitcherActiveType';

const mapStateToProps = (
    state: IApplicationState,
    ownProps: IAsidePanelBodyHOCProps
): IAsidePanelBodyFields => {
    let { activeTabIndex, boardId, children, tabs, panelActions, isAssignedToMe, cardId: openedCardId } = ownProps;
    let isActionsMinimized = false;
    let tabKey = null;
    let isArchiveView = false;

    if (
        tabs &&
        tabs[activeTabIndex]
    ) {
        if (!children) {
            children = tabs[activeTabIndex].body;
        }
        panelActions = tabs[activeTabIndex].panelActions;
        tabKey = tabs[activeTabIndex].key;
    }
    if (panelActions) {
        isActionsMinimized = getAsidePanelButtonsPanelIsMinimized(state);
    }
    if(boardId){
        isArchiveView = getTypeSwitcherActiveType(state, boardId) === EViewTypes.ARCHIVE_VIEW_TYPE;
    }
    if(isAssignedToMe){
        if(openedCardId){
            const openedCard = getCard(state, openedCardId);
            if(openedCard){
                isArchiveView = openedCard.status === TStatus.STATUS_ARCHIVE;
            }
        }
    }
    const linkCardId = getLinkCardId(state.router);
    if (linkCardId) {
        const card = getCard(state, linkCardId);
        if (card && card.status === TStatus.STATUS_ARCHIVE) {
            isArchiveView = true;
        }
    }
    return {
        children,
        isActionsMinimized,
        isArchiveView,
        panelActions,
        tabKey,
    }
};

export const AsidePanelBodyHOC = connect(
    mapStateToProps,
    null
)(AsidePanelBody);

AsidePanelBodyHOC.displayName = 'AsidePanelBodyHOC';
