import { IGetState, TBoardId, TCardId, TPermissionId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { getCardAssignees } from '../../../../../../../../store/model/selectors/getCardAssignees';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { checklistsDeleteUser } from '../../../../../../../../rest/effects/card/checklist/checklistsDeleteUser';
import { cardCheckActivateNotifySubscription } from '../../../../../../../../rest/effects/card/card/cardCheckActivateNotifySubscription';
import { getAuthUser } from '../../../../../../../../store/model/authUser/selectors/getAuthUser';
import { EPushNotificationSubscribeCases } from '../../../../../../../../types/rest/IRestPushNotificationSettings';
import { getBoardUsers } from '../../../../../../../../store/model/selectors/getBoardUsers';
import { cardSetAssigneesBatch } from '../../../../../../../../rest/effects/card/card/cardSetAssignees';
import { cardPatchRestBatch } from '../../../../../../../../rest/effects/card/card/api/helper/cardPatchRestBatch';
import { checkMoveCardToOnHoldList } from './checkMoveCardToOnHoldList';
import { getCard } from '../../../../../../../../store/model/selectors/getCard';
import { IAssigneeRest } from '../../../../../../../../types/rest/IAssigneeRest';
import { ICardBatch } from '../../../../../../../../rest/effects/card/card/api/types';
import { resetHint } from 'app/view/react_components/hints/effects/resetHint';
import { EHintKey, EHintStatus } from 'app/view/react_components/hints';
import { setHintCanShow } from 'app/view/react_components/hints/effects/setHintCanShow';
import { getHint } from 'app/view/react_components/hints/store/hints/selectors/getHint';

export const cardToggleAssignee = (
    boardId: TBoardId,
    cardId: TCardId,
    permissionId: TPermissionId,
    type: EToggleAssigneeType,
    updateChecklists: boolean = true
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const assignees = [...getCardAssignees(state, cardId)];
        const index = assignees.findIndex(assignee => assignee.sharedUser.permissionId === permissionId);
        const boardUsers = getBoardUsers(state, boardId);
        let newAssignee: IAssigneeRest = null;
        const cardBatch: ICardBatch = {card: {}, callbacks: []};
        const cardLinkHintStatus = getHint(state, EHintKey.CARD_LINK).status

        if (type === EToggleAssigneeType.ADD && index < 0) {
            newAssignee = { sharedUser: boardUsers.find(assignee => assignee.permissionId === permissionId), roleIds: []};
            assignees.push(newAssignee);
            if (cardLinkHintStatus === EHintStatus.NOT_SHOWN) {
                dispatch(setHintCanShow(EHintKey.CARD_LINK));
            }
            dispatch(segmentTrackAction(SegmentCardEvent.ASSIGNED_USER));

            const user = getAuthUser(state);
            if (user.permissionId === newAssignee.sharedUser.permissionId) {
                dispatch(cardCheckActivateNotifySubscription(cardId, EPushNotificationSubscribeCases.ASSIGN));
            }
        } else if (type === EToggleAssigneeType.DELETE && index >= 0) {
            assignees.splice(index, 1);
            dispatch(segmentTrackAction(SegmentCardEvent.UNASSIGNED_USER));

            dispatch(checkMoveCardToOnHoldList(permissionId, boardId, getCard(state, cardId), cardBatch));
            if (updateChecklists) {
                dispatch(checklistsDeleteUser(cardId, permissionId));
            }
        }
        cardSetAssigneesBatch(dispatch, cardId, assignees, cardBatch);
        cardPatchRestBatch(cardId, dispatch, cardBatch);
    };
    return action;
};

export enum EToggleAssigneeType {
    ADD = 'add',
    DELETE = 'delete'
}
