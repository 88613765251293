import { ITopBarBoardStateLocalStorage, TOP_BAR_BOARD_LOCALSTORAGE } from '../types';

export const setLastUsedFilter = (
    topBardBoards: ITopBarBoardStateLocalStorage,
) => {
    const topBardBoardsLS: ITopBarBoardStateLocalStorage = {};
    for (let boardId in topBardBoards) {
        if (boardId) {
            topBardBoardsLS[boardId] = {...topBardBoards[boardId]};
            delete topBardBoardsLS[boardId].isShareHintShow; // не сохранять это в LS
        }
    }

    localStorage.setItem(TOP_BAR_BOARD_LOCALSTORAGE, JSON.stringify(topBardBoardsLS))
};
