import { ICardsUpdateAction } from './types';
import { AT_CARDS_UPDATE } from './constants';
import { ICards } from '../types';

export const cardsUpdateAction = (
    cards: ICards
): ICardsUpdateAction => ({
    type: AT_CARDS_UPDATE,
    cards,
});
