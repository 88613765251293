import { IGetState } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { setTitleAttachment } from '../../../../../../../../rest/effects/card/attacments/setTitleAttachment';
import { TDriveDocId } from '../../../../../../../../types/rest/IRestDriveDoc';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';

export const onSaveTitle = (
    driveDocId: TDriveDocId,
    title: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(setTitleAttachment(driveDocId, title))
            .then(() => {
                dispatch(segmentTrackAction(SegmentCardEvent.ATTACHMENT_RENAMED));
            });
    };
    return action;
};
