import { IApplicationState, TCardId } from '../../../types/types';
import { getList } from '../list/selectors/getList';

export const getCardListName = (
    state: IApplicationState,
    cardId: TCardId
): string => {
    const card = state.model.cards[cardId];
    if (!card || !card.listId) return null;

    const list = getList(state, card.listId);
    if (!list) return null;

    return list.name;
}
