import { IGoogleDrive } from '../../types';
import { IRestDrive } from '../../../rest/types';
import { ICON_SHARED_DRIVE, getDrivesInitial } from '../constants';

export const getDrivesFromRest = (
    restDrives: IRestDrive[]
): IGoogleDrive[] => {
    const drives = restDrives.map((restDrive) => {
        return buildGoogleDrive(restDrive);
    });
    const drivesSorted = drives.sort(sortDrives);
    return [
        ...getDrivesInitial(),
        ...drivesSorted
    ];
};

const buildGoogleDrive = (
    restDrives: IRestDrive
): IGoogleDrive => {
    const googleDrive = {
        icon: ICON_SHARED_DRIVE,
        id: restDrives.driveId,
        name: restDrives.name,
        allowedAction: restDrives.allowedAction
    };
    return googleDrive;
};

const sortDrives = (
    a: IGoogleDrive,
    b: IGoogleDrive
): number => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    return 0;
};
