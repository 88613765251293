import { root } from 'app/store/constants';

/**
 * Возвращает массив координат от контейнера вглубь по дереву до курсора.
 * @className контейнер
 */

export const getSelectionTree = (
    className: string,
): number[] => {
    let tree: number[] = [];
    const selection = root.getSelection();
    if (!selection.anchorNode) return tree;

    let target = selection.anchorNode.parentNode as HTMLElement;
    const children = Array.from(target.childNodes).filter(node => node.nodeName !== 'BR'); // br не считаем, т.к. в quill они заменятся на \n
    let offset = 0;
    let index = 0;
    for (let i=0; i<children.length; i++) {
        if (children[i].nodeName === '#text' &&
            i > 0 && children[i-1].nodeName === '#text'
        ) index--;

        if (children[i] === selection.anchorNode) break;

        index++;
        if (children[i].nodeName === '#text') {
            offset += children[i].nodeValue.length + 1; // 2 текстовые ноды, разделённые br, становятся одной
        } else {
            offset = 0;
        }
    }
    tree.push(selection.anchorOffset + offset, index);

    while (
        target &&
        (!target.classList || !target.classList.contains(className)) &&
        target.parentNode
    ) {
        const children = Array.from(target.parentNode.childNodes).filter(node =>
            node.textContent !== '\n' && node.nodeName !== 'BR');

        let index = 0;
        for (let i=0; i<children.length; i++) {
            if (children[i] === target) break;
            index++;
        }
        tree.push(index);
        target = target.parentNode as HTMLElement;
    }
    return tree;
}
