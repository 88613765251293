// При регистрации и при создании первой доски
export const USER_READ = 'https://graph.microsoft.com/User.Read'; // читать детали пользователя
export const FILES_READWRITE_ALL = 'https://graph.microsoft.com/Files.ReadWrite.All'; // доступ к файлам и к РАСШАРЕННЫМ с тобой
// когда пытаемся шарить доску
export const PEOPLE_READ = 'https://graph.microsoft.com/People.Read'; // поиск людей по организации
// когда получает колобораторов доски на беке
export const USER_BASIC_READ_ALL = 'https://graph.microsoft.com/User.ReadBasic.All'; // читать ФИО узеров группы
export const GROUP_MEMBER_READ_ALL = 'https://graph.microsoft.com/GroupMember.Read.All'; // читать список узеров группы
// TODO запрашивать права по мере необходимости

export const OFFLINE_ACCESS = 'offline_access'

export const INIT_SCOPE = `openid profile email ${USER_READ} ${FILES_READWRITE_ALL} ${PEOPLE_READ} ${USER_BASIC_READ_ALL}`;
