import {Dispatch, ThunkAction} from '../../../../../../../types/actions';
import {segmentTrackAction, SegmentUserEvent} from '../../../../../../../middlewares/segment';
import {openReleaseNotes} from '../../../../../base/effects/openReleaseNotes';
import { NAVIGATION_PANEL_INNER_CLASS } from 'app/view/react_components/main/navigationPanel/constants';

export const onClick = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        const navPanel = document.querySelector(`.${NAVIGATION_PANEL_INNER_CLASS}`) as HTMLElement;
        if (navPanel) navPanel.focus(); // закрыть help дропдаун
        setTimeout(() => { // передать фокус в панель после закрытия дропдауна
            dispatch(openReleaseNotes());
        }, 100);
        dispatch(segmentTrackAction(SegmentUserEvent.RELEASE_NOTES_CLICKED));
    };
    return action;
};
