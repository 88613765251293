import * as React from 'react';
import { ITimeZoneProps } from './types';
import { v4 as uuidv4 } from 'uuid';
import { TIMEZONE_MORE_INSTRUCTIONS, TIMEZONE_TEXT } from '../../constants';
import { BOARD_DETAILS_ONLY_OWNER, CLASS_BOARD_DETAILS } from '../../../../constants';
import './_TimeZone.scss';
import { TimeZoneOption } from '../TimeZoneOption/TimeZoneOption';
import { SearchSelect } from '../../../../../../base/components/SearchSelect/SearchSelect';

export function TimeZone ({
    text,
    value,
    options,
    isDisabled,
    onClickLink,
    onChange
}: ITimeZoneProps){
    const [key, setKey] = React.useState(uuidv4());
    const classText = CLASS_BOARD_DETAILS + '__timezone-text';
    const classDropdown = CLASS_BOARD_DETAILS + '__timezone-dropdown';

    function onClose() {
        setKey(uuidv4())
    }

    return (
        <div>
            <div className={classText}>
                {TIMEZONE_TEXT} <a onClick={onClickLink} href="https://www.kanbanchi.com/faqwd/kanbanchi-time-zones" target="_blank">{TIMEZONE_MORE_INSTRUCTIONS}</a>
                {isDisabled && <p>{BOARD_DETAILS_ONLY_OWNER}</p>}
            </div>
            <SearchSelect
                activeValue={value}
                directionVertical={'auto'}
                disabled={isDisabled}
                dropdownClassName={classDropdown}
                key={key}
                option={TimeZoneOption}
                options={options}
                text={text}
                variant={'arrow'}
                onClosed={onClose}
                onSelect={onChange}
            />
        </div>
    );
}
