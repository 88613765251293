import { root } from '../../../../../../../../store/constants';
import { EPanelRoute } from '../../../../../../../../store/router/constants';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../../../../types/types';

export const onClickActivity = (
    cardId: TCardId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return root.App.router.navigate(root.App.router.getUrl('c-' + cardId + '/' + EPanelRoute.ACTIVITY), {trigger: true});
    };
    return action;
};
