import { CLASS_ACTIVITY_ITEM } from '../../../../../boardDetails/activity/TabActivity/components/constants';
import * as React from 'react';
import { IDefaultContent } from './types';
import { blockMarkdown } from '../../../../../../../../helper/markdownHelper';
import ReactHtmlParser from 'react-html-parser';

export function DefaultContent({
    label
}: IDefaultContent) {
    return <div className={CLASS_ACTIVITY_ITEM}>{ReactHtmlParser(blockMarkdown(label))}</div>
}
