import { IApplicationState } from '../../../../types/types';
import { getAuthUser } from './getAuthUser';
import { IOneTimePoll, IRateAppPoll } from '../../../../types/rest/IUserMeta';
import { DEFAULT_POLL } from '../../../../const';

export const getUserMessengerPoll = (
    state: IApplicationState
): IOneTimePoll => {
    const user = getAuthUser(state);
    return user.meta && user.meta.messengerPoll || DEFAULT_POLL;
};
