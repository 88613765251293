import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { IGetState } from '../../../../../types/types';
import { getCardsStatistic } from '../selectors/getCardsStatistic';
import { ISegmentEventOption } from '../../../../../middlewares/segment/trackEntities/types';
import {
    SegmentAssignedToMeEvent,
    SegmentAssignedToMeOptions,
    SegmentAssignedToMeSortTypes
} from '../../../../../middlewares/segment/trackEntities/assignedToMeEvents';
import { EUserMetaAssignedToMeSortField, IUserMetaMyWorkSortType } from '../../../../../types/rest/IUserMeta';
import { segmentIdentifyAction, segmentTrackAction } from '../../../../../middlewares/segment';
import { ESegmentTraitKey } from '../../../../../middlewares/segment/actions/segmentIdentifyAction/types';

export const sendSegmentInitialEvents = (): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const statistic = getCardsStatistic(getState());
        const additionalOptions: ISegmentEventOption[] = [{
            name: SegmentAssignedToMeOptions.PINNED_CARDS,
            value: statistic.pinnedCards
        }, {
            name: SegmentAssignedToMeOptions.NEW_CARDS,
            value: statistic.newCards
        }];
        dispatch(segmentIdentifyAction({
            [ESegmentTraitKey.ATM_CARDS_TOTAL]: statistic.totalCards,
            [ESegmentTraitKey.ATM_CARDS_PINNED]: statistic.pinnedCards,
            [ESegmentTraitKey.ATM_CARDS_NEW]: statistic.newCards
        }));
        dispatch(segmentTrackAction(SegmentAssignedToMeEvent.ASSIGNED_TO_ME_OPENED, {
            name: SegmentAssignedToMeOptions.TOTAL_CARDS,
            value: statistic.totalCards
        }, additionalOptions));
    }
    return action;
}

export const onSortFieldClickEvent = (
    field: EUserMetaAssignedToMeSortField,
    newSortType: IUserMetaMyWorkSortType
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentAssignedToMeEvent.SORT_CLICKED, {
            name: SegmentAssignedToMeOptions.TARGET,
            value: field
        }, [{
            name: SegmentAssignedToMeOptions.TYPE,
            value: newSortType ? (newSortType.isDesc ? SegmentAssignedToMeSortTypes.DESC : SegmentAssignedToMeSortTypes.ASC) : SegmentAssignedToMeSortTypes.DISABLED
        }]));
    }
    return action;
}

export const onUpdatesClickEvent = (
    count: number,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentAssignedToMeEvent.UPDATES_CLICKED, {
            name: SegmentAssignedToMeOptions.COUNT,
            value: count
        }));
    }
    return action;
}
