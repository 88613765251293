import { IGetState } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { patchRestHelper } from './api/helper/patchRestHelper';
import { TDriveDocId } from '../../../../types/rest/IRestDriveDoc';

export const setTitleAttachment = (
    driveDocId: TDriveDocId,
    title: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(patchRestHelper(driveDocId, {
            title
        }));
    };
    return action;
};
