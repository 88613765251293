import { IApplicationState } from '../../../../../../../../types/types';
import { connect } from 'react-redux';
import { onClickDisallow } from './events/onClickDisallow';
import { IRolesSectionEvents, IRolesSectionFields } from '../../components/RolesSection/types';
import { getAuthUser } from '../../../../../../../../store/model/authUser/selectors/getAuthUser';
import { ESettingsSectionType } from '../../../../../../base/components/SettingsSection/types';
import { RolesSection } from '../../components/RolesSection/RolesSection';
import { getIsUserBasicPlanOnly } from '../../../../../../../../store/model/authUser/selectors/getIsUserBasicPlanOnly';

const mapStateToProps = (
    state: IApplicationState,
): IRolesSectionFields => {
    const { allowRoles } = getAuthUser(state);
    return {
        isBasicFeature: getIsUserBasicPlanOnly(state),
        sectionType: allowRoles ? null : ESettingsSectionType.DISALLOW
    }
};

const mapDispatchToProps = (
    dispatch: any,
): IRolesSectionEvents => {
    return {
        onClickDisallow: () => dispatch(onClickDisallow())
    }
};

export const RolesSectionHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(RolesSection);

RolesSectionHOC.displayName = 'RolesSectionHOC';
