import { Checkbox, Icon, Loader, Tooltip } from 'kui';
import * as React from 'react';
import { blockMarkdown } from '../../../../../../../../helper/markdownHelper';
import { getMessages } from '../../../../../../../../store/constants';
import { Util } from '../../../../../../../../util/util';
import { CARD_DUE_DATE, CARD_START_DATE } from '../../../../../../aside_panel/cardDetails/DatesSection/components/CardDatesSection/constants';
import { CARD_ESTIMATE_LABEL_TEXT } from '../../../../../../aside_panel/cardDetails/EstimateAndSpentTimeSection/components/CardEstimateAndSpentTimeSection/constants';
import { convertTimeNumberToString } from '../../../../../../aside_panel/cardDetails/helpers/convertTimeNumberToString';
import { UsersTooltip } from '../../../../../../base/components/UsersTooltip/UsersTooltip';
import { CARD_CLASS, CARD_CONTROL_CLASS } from '../../../../constants';
import { CHECKLIST_TOOLTIP } from '../../../../wording';
import { ICardChecklistItem, ICardChecklistsProps } from './types';
import './_CardChecklists.scss';
import {
    CARD_CHECKLIST_DONE
} from '../../../../../../aside_panel/cardDetails/ChecklistSection/components/CardChecklist/constants';
import ReactHtmlParser from 'react-html-parser';

export function CardChecklists ({
    authUserPermissionId,
    checklists,
    className,
    isReadonly,
    boardUsers,
    workHoursInDay,
    setCardHeight,
    onDidMount,
    onToggle,
}: ICardChecklistsProps) {
    if (checklists && !checklists.length) return null;

    const classElement = CARD_CLASS + '__checklists';
    const classChecklist = CARD_CLASS + '__checklist';
    const classTitle = classChecklist + '-title';
    const classTitleName = classChecklist + '-title-name';
    const classProgress = classChecklist + '-progress';
    const classDoneIcon = classChecklist + '-done-icon';
    const classItem = classChecklist + '-item';
    const classItemChecked = classItem + '--checked';
    const classIndicators = classItem + '-indicators';
    const classStartIndicator = classItem + '-startdate-indicator';
    const classDueIndicator = classItem + '-duedate-indicator';
    const classDueIndicatorOverdue = classDueIndicator + '--overdue';
    const classEstimateIndicator = classItem + '-estimate-indicator';

    function Item ({
        id,
        assignees,
        checked,
        checklistId,
        startDate,
        dueDate,
        estimate,
        isShowWeights,
        name,
        weight,
    }: ICardChecklistItem) {
        const assigneesTooltip = assignees && assignees.length
            ? <UsersTooltip
                authUserPermissionId={authUserPermissionId}
                users={assignees}
                boardUsers={boardUsers}
            />
            : null;

        const startDateIndicator = !startDate ? null : (
            <Tooltip
                isNoEvents
                isNoWrap
                value={CARD_START_DATE + ': ' + Util.formatDateMoment(startDate)}
            >
                <div className={classStartIndicator}>
                    <Icon size={16} xlink={'start-date'} />
                    {Util.formatTimestampToDayMonth(startDate)}
                </div>
            </Tooltip>
        );

        const isItemOverdue = (
            !!dueDate &&
            !checked &&
            Date.now() > dueDate * 1000
        );
        const dueDateIndicator = !dueDate ? null : (
            <Tooltip
                isNoEvents
                isNoWrap
                value={CARD_DUE_DATE + ': ' + Util.formatDateMoment(dueDate)}
            >
                <div className={`
                    ${classDueIndicator}
                    ${isItemOverdue ? classDueIndicatorOverdue : ''}
                `}>
                    <Icon size={16} xlink={isItemOverdue ? 'overdue' : 'due-date'} />
                    {Util.formatTimestampToDayMonth(dueDate)}
                </div>
            </Tooltip>
        );

        const estimateStr = !estimate ? null : convertTimeNumberToString(estimate, false, workHoursInDay);
        const estimateIndicator = !estimate ? null : (
            <Tooltip
                isNoEvents
                isNoWrap
                value={CARD_ESTIMATE_LABEL_TEXT + ': ' + estimateStr}
            >
                <div className={classEstimateIndicator}>
                    <Icon size={16} xlink={'estimate'} />
                    {estimateStr}
                </div>
            </Tooltip>
        );

        const weightButton = isShowWeights
            ? <div
                className={classItem + '-weight'}
            >
                <Icon size={16} xlink={'weight'} />
                {weight}
            </div>
            : null;

        return (
            <div
                className={`
                    ${classItem}
                    ${checked ? classItemChecked : ''}
                `}
            >
                <Checkbox
                    className={CARD_CONTROL_CLASS}
                    checked={checked}
                    direction={'left'}
                    disabled={isReadonly}
                    onChange={() => {onToggle(checklistId, id)}}
                />
                {(!!startDateIndicator || !!dueDateIndicator || !!estimateIndicator || !!assigneesTooltip || !!weightButton) &&
                    <div
                        className={classIndicators}
                    >
                        {startDateIndicator}
                        {dueDateIndicator}
                        {estimateIndicator}
                        {assigneesTooltip}
                        {weightButton}
                    </div>
                }
                <div className={classItem + '-name'}>
                    {ReactHtmlParser(blockMarkdown(name))}
                </div>
            </div>
        );
    }

    React.useEffect(() => {
        setCardHeight();
    }, [checklists]);

    React.useEffect(() => {
        onDidMount();
    }, []);

    return (
        <div
            className={`
                ${className}
                ${classElement}
            `}
        >
            {checklists
                ? checklists.map(checklist => {
                    const isDone = checklist.stats && checklist.stats.checkedCount === checklist.stats.totalCount;

                    return <div
                        className={classChecklist}
                        key={checklist.id}
                    >
                        <div className={classTitle}>
                            <Tooltip
                                direction={'up-right'}
                                isNoEvents
                                isNoWrap
                                value={CHECKLIST_TOOLTIP}
                            >
                                <div className={classTitleName}>
                                    {checklist.name}
                                </div>
                            </Tooltip>
                            {checklist.stats && !!checklist.stats.totalCount &&
                                <div className={classProgress}>
                                    {isDone
                                        ? <>
                                            <Icon
                                                className={classDoneIcon}
                                                size={16}
                                                xlink={'progress'}
                                            /> ({checklist.stats.checkedCount} / {checklist.stats.totalCount})
                                        </>
                                        : getMessages().getText(
                                            CARD_CHECKLIST_DONE,
                                            null,
                                            checklist.stats.checkedPercent,
                                            checklist.stats.checkedCount,
                                            checklist.stats.totalCount
                                        )
                                    }
                                </div>
                            }
                        </div>
                        {!!checklist.checkItems.length && checklist.checkItems.map(item => {
                            return <Item
                                {...item}
                                isShowWeights={checklist.isShowWeights}
                                key={item.id}
                            />
                        })}
                    </div>
                })
                : <Loader />
            }
        </div>
    );
}
