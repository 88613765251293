import { IGetState, TBoardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { getBoard } from '../../../../../../../../store/model/selectors/getBoardById';
import { deletedBoardSegmentEvent } from '../../../../../../dialogs/openBoards/effects/segmentEvents';
import { TBoardDeleteType } from '../../../../../../dialogs/openBoards/store/boards/types';
import { deleteBoardSnackbar } from 'app/view/react_components/dialogs/openBoards/hocs/BoardActionDeleteHOC/effects/deleteBoardSnackbar';

export const onClick = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const board = getBoard(getState(), boardId);
        const onDeletedEvents = () => {
            dispatch(deletedBoardSegmentEvent(board));
        }

        const boardDeleteType = !board.shared ?
            TBoardDeleteType.own :
            board.permissions.authPermissions.allowDelete ?
                TBoardDeleteType.shared :
                TBoardDeleteType.disallow;
        dispatch(deleteBoardSnackbar(
            board.id,
            boardDeleteType,
            () => onDeletedEvents()
        ));
    };
    return action;
};
