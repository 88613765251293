import { IApplicationState } from '../../../../../types/types';
import { getMyWorkCards } from './getMyWorkCards';

type TCardsStatistic = {
    totalCards: number;
    newCards: number;
    pinnedCards: number;
}
export const getCardsStatistic = (
    state: IApplicationState
): TCardsStatistic => {
    const cards = getMyWorkCards(state);
    const pinnedCards = cards.filter((card) => card.pinned).length;
    const newCards = cards.filter((card) => !card.firstSeenDate).length;
    return {
        newCards,
        pinnedCards,
        totalCards: cards.length
    }
}
