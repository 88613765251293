import { SEGMENT_IDENTIFY } from '../../constants';
import { ISegmentAction } from '../../types';
import { EAuthUserAccountTypeNames } from '../../../../types/rest/IRestAuthUser';
import { EAbandonedCartPlan, EUseType } from '../../../../types/rest/IUserMeta';

export interface ISegmentIdentifyAction extends ISegmentAction {
    case: typeof SEGMENT_IDENTIFY;
    traits?: any
}

export enum ESegmentTraitKey {
    COMMENT_SORT = 'comment_sort',
    ATM_CARDS_TOTAL = 'assigned_to_me_cards_total',
    ATM_CARDS_PINNED = 'assigned_to_me_cards_pinned',
    ATM_CARDS_NEW = 'assigned_to_me_cards_new'
}

export interface IUserCommentSortTrait {
    [ESegmentTraitKey.COMMENT_SORT]?: string;
}

export interface IUserIdentifyTraits extends IUserCommentSortTrait {
    id: any;
    email: string;
    name: string;
    firstName: string;
    lastName: string;
    accountType: EAuthUserAccountTypeNames;
    domain: string;
    domainWide: boolean;
    domainWide_end_date: string;
    createdAt: Date; //Date
    creation_date: string;
    plan_trial: boolean;
    trial_end_date: string;
    extended_trial_end_date: string;
    plan_premium: boolean;
    plan_professional: boolean;
    plan_enterprise: boolean;
    max_seats: number;
    subscriptions_active: number;
    subscriptions_own: number; //
    subscriptions_holders: string; // "int, int, int"
    subscriptions_ids: string; // "int, int, int"
    plan: EPaidGradationSteps;
    freePlan: boolean,
    refferal: string,
    industry: string,
    employeeCount: string
    landing_page: string,
    language: string,
    navPanel: string;
    useType: string;
    paidUser: boolean;
    resize_panel_text?: string;
    intercom?: boolean;
    abandonedCart?: EAbandonedCartPlan;
    dayInSystem?: string,
    eduV2?: boolean
    version: string;
    usersInDomainCount: number;
    country_code: string,
    utm_settings: string
}

export enum EPaidGradationSteps {
    OTHER = '',
    FREE = 'Free',
    PREMIUM = 'Premium',
    PROFESSIONAL = 'Professional',
    ENTERPRISE = 'Enterprise',
    FREEMIUM = 'Freemium'
}
