import { IGetState, TFilterId } from '../../../../../../../../types/types';
import { IRestFilterMetaMyWorkIcon } from '../../../../../../aside_panel/filterPanelBoard/rest/types';
import {
    savedFilterSelector
} from '../../../../../../aside_panel/filterPanelBoard/store/selectors/savedFilterSelector';
import { metaSet } from '../../../../../../aside_panel/filterPanelBoard/store/filter/actions/metaSet';
import {
    savedFilterActionsSet
} from '../../../../../../aside_panel/filterPanelBoard/store/savedFilters/actions/savedFilterActionsSet';
import { patchFilter } from '../../../../../../aside_panel/filterPanelBoard/effects/patchFilter';
import {
    savedFilterSet
} from '../../../../../../aside_panel/filterPanelBoard/store/savedFilters/actions/savedFilterSet';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { ERestCardAssigneeInfoType } from '../../../../../../../../types/rest/IRestCardAssigneeInfo';

export const onIconChange = (
    filterId: TFilterId,
    myWorkIcon: IRestFilterMetaMyWorkIcon
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const filter = savedFilterSelector(getState(), filterId);
        const icon = filter.meta.myWorkIcon;
        if (
            icon &&
            icon.icon === myWorkIcon.icon &&
            icon.color === myWorkIcon.color
        ) {
            return;
        }
        dispatch(savedFilterActionsSet(filterId, metaSet({ myWorkIcon })));
        const newFilter = { ...filter, meta: { ...filter.meta, myWorkIcon }};
        dispatch(patchFilter(filterId, newFilter)).then(() => {
            dispatch(savedFilterSet(filterId, newFilter));
        });
    }
    return action;
}
