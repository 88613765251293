import { Dispatch, ThunkAction } from 'app/types/actions';
import { IGetState, TCardId } from 'app/types/types';
import { authUserSetInWorkCardId } from '../authUserSetInWorkCardId';
import { checkAndMoveCardOnHoldList } from '../../card/card/checkAndMoveCardOnHoldList';
import { cardMoveToInWorkListBatch } from '../../card/card/cardMoveToInWorkList';
import { getBoardByCardId } from 'app/store/model/selectors/getBoardByCardId';
import { isCardAssignerExist } from 'app/store/model/selectors/isCardAssignerExist';
import { SegmentCardEvent, segmentTrackAction } from '../../../../middlewares/segment';
import { addCardAssignerBatch } from '../../card/assignee/addCardAssigner';
import { cardSetStartDateWithEpics } from '../../card/card/cardSetStartDateWithEpics';
import { getCard } from 'app/store/model/selectors/getCard';
import { ICardBatch } from '../../card/card/api/types';
import { cardPatchRestBatch } from '../../card/card/api/helper/cardPatchRestBatch';
import { getCardIsEpic } from 'app/store/model/selectors/getCardIsEpic';
import { cardSetStartDateBatch } from '../../card/card/cardSetStartDate';
import { cardSetInWorkUser } from './cardSetInWorkUser';
import { getAuthUser } from 'app/store/model/authUser/selectors/getAuthUser';

export const authUserSetInWorkCard = (
    cardId: TCardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const user = getAuthUser(state);
        const board = getBoardByCardId(state, cardId);
        const card = getCard(state, cardId);
        dispatch(checkAndMoveCardOnHoldList(cardId)).then(() => {
            return dispatch(authUserSetInWorkCardId(cardId))
        }).then(() => {
            dispatch(cardSetInWorkUser(user.permissionId, cardId));
        }).then(() => {
            const cardBatch: ICardBatch = cardMoveToInWorkListBatch(getState, dispatch, cardId);
            if (!isCardAssignerExist(getState(), cardId, user.permissionId)) {
                addCardAssignerBatch(getState, dispatch, cardId, board.id, user.permissionId, cardBatch);
                dispatch(segmentTrackAction(SegmentCardEvent.ASSIGNED_USER));
            }
            if (!card.startDate && !getCardIsEpic(state, card.id)) {
                const startDate = Math.round(new Date().getTime() / 1000);
                cardSetStartDateBatch(getState, cardId, startDate, cardBatch);
                return cardPatchRestBatch(cardId, dispatch, cardBatch).then(() => {
                    if (card.epicId) {
                        dispatch(cardSetStartDateWithEpics(card.epicId, startDate));
                    }
                });
            } else {
                return cardPatchRestBatch(cardId, dispatch, cardBatch);
            }
        })

    }
    return action;
};
