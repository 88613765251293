import * as React from 'react';
import { TabActivityHOC } from '../../hocs/TabActivityHOC/TabActivityHOC';
import { AsidePanelContext } from '../../../../../asidePanel/components/AsidePanel/constants';
import { REST_BOARD } from '../../../../../../../../rest/constants';

export function TabActivityBody() {
    const { boardId } = React.useContext(AsidePanelContext);

    return <TabActivityHOC
        objectId={boardId}
        url={REST_BOARD}
    />;
}
