import { TBoardId, IApplicationState } from '../../../types/types';
import { ERoutes } from '../constants';

export const getRouterBoardId = (
    state: IApplicationState
): TBoardId => {
    const { route, args } = state.router;
    if (route !== ERoutes.BOARD) return null;

    const boardId = args[1];
    if (!boardId) return null;

    return parseInt(boardId);
}
