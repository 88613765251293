import { IChecklistItemsUpdateAction } from './types';
import { AT_CHECKLIST_ITEMS_UPDATE } from './constants';
import { IChecklistItems } from '../types';

export const checklistItemsUpdateAction = (
    items: IChecklistItems
): IChecklistItemsUpdateAction => {
    return {
        type: AT_CHECKLIST_ITEMS_UPDATE,
        items
    }
};
