import { getMessages } from '../../../../../../../store/constants';

export const MY_WORK_FILTER_BAR_EDIT_FILTER = getMessages().getText('assigned_to_me.filter_bar.actions.edit');
export const MY_WORK_FILTER_BAR_DELETE_FILTER = getMessages().getText('assigned_to_me.filter_bar.actions.delete');
export const MY_WORK_FILTER_BAR_SELECT_OTHERS = getMessages().getText('assigned_to_me.filter_bar.actions.select');
export const MY_WORK_FILTER_BAR_UNSELECT_OTHERS = getMessages().getText('assigned_to_me.filter_bar.actions.unselect');
export const MY_WORK_FILTER_BAR_HIDE_FILTER = getMessages().getText('assigned_to_me.filter_bar.actions.hide');
export const MY_WORK_FILTER_BAR_ICON_FILTER = getMessages().getText('assigned_to_me.filter_bar.actions.icon');
export const MY_WORK_FILTER_BAR_TITLE_SHOW_FILTER = getMessages().getText('assigned_to_me.filter_bar.actions.title.show');
export const MY_WORK_FILTER_BAR_TITLE_HIDE_FILTER = getMessages().getText('assigned_to_me.filter_bar.actions.title.hide');
export const MY_WORK_FILTER_BAR_COLLAPSE = getMessages().getText('assigned_to_me.filter_bar.actions.collapse');
export const MY_WORK_FILTER_BAR_EXPAND = getMessages().getText('assigned_to_me.filter_bar.actions.expand');
export const MY_WORK_FILTER_HINT = getMessages().getText('assigned_to_me.filter_bar.hint');
