import { Dispatch, ThunkAction } from '../../../../../../../types/actions';

export const onTabImportActive = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
    };
    return action;
};
