import { IApplicationState, TFilterId } from '../../../../../../../../types/types';
import { createDeepEqualSelector } from '../../../../../../helpers/memoizeHelper';
import {
    getShowOnBarSortedSavedFilterIds
} from '../../../../../../aside_panel/filterPanelBoard/components/SavedSection/hocs/selectors/getShowOnBarSortedSavedFilterIds';

type TgetShowOnBarFilterIds= (
    state: IApplicationState,
) => TFilterId[];

export const getShowOnBarFilterIdsCreateSelector = (
): TgetShowOnBarFilterIds => createDeepEqualSelector(
    getShowOnBarSortedSavedFilterIds,
    filterIds => filterIds
);
