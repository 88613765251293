import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState, TBoardId } from '../../../../../../../types/types';
import {
    boardCloseClickedSegmentEvent,
    ESegmentBoardMenuLocation
} from '../../../../../../../middlewares/segment/trackEntities/boardMenuEvents';
import { boardClose } from '../../../../../base/effects/boardClose';

export const onClose: ActionCreator<ThunkAction> = (
    boardId: TBoardId
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(boardClose(boardId));
        dispatch(boardCloseClickedSegmentEvent(ESegmentBoardMenuLocation.topBar));
    };
    return action;
};
