import { IApplicationState, TBoardId } from '../../../types/types';
import { TStatus } from '../../../types/model';
import { getBoardCards } from './getBoardCards';

export const getBoardCardsCount = (
    state: IApplicationState,
    boardId: TBoardId,
    status: TStatus = TStatus.STATUS_ACTIVE,
): number => {
    return getBoardCards(state, boardId, status).length;
};
