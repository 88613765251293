import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId, TCustomPropertyId } from '../../../../../../../../types/types';
import {
    updateValue
} from '../../../../../../aside_panel/cardDetails/CustomPropertiesSection/hocs/CustomPropertiesSectionHOC/effects/updateValue';

export const onUpdateValue = (
    cardId: TCardId,
    customPropertyId: TCustomPropertyId,
    value: string,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(updateValue(cardId, customPropertyId, value));
    };
    return action;
};
