import * as React from 'react';

export function Table2 () {
    return (
        <tbody>
            <tr>
                            <th>
                                <p><strong>Criteria</strong></p>
                            </th>
                            <th>
                                <p><strong>Conformance Level</strong></p>
                            </th>
                            <th>
                                <p><strong>Remarks and Explanations</strong></p>
                            </th>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="http://www.w3.org/TR/WCAG20/#media-equiv-real-time-captions"><strong>1.2.4 Captions (Live)</strong></a> (Level AA)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.1.2.4 (Web)</li>
                                    <li>10.1.2.4 (Non-web document)</li>
                                    <li>11.1.2.4 (Open Functionality Software)</li>
                                    <li>11.1.2.4 (Closed Software)</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508</p>
                                <ul>
                                    <li>501 (Web)(Software)</li>
                                    <li>504.2 (Authoring Tool)</li>
                                    <li>602.3 (Support Docs)</li>
                                </ul>
                            </td>
                            <td>
                                <p><strong>Not Applicable</strong></p>
                            </td>
                            <td>
                                <p>There is no live multimedia content in the application.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="http://www.w3.org/TR/WCAG20/#media-equiv-audio-desc-only"><strong>1.2.5 Audio Description (Prerecorded)</strong></a> (Level AA)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.1.2.5 (Web)</li>
                                    <li>10.1.2.5 (Non-web document)</li>
                                    <li>11.1.2.5 (Open Functionality Software)</li>
                                    <li>11.1.2.5 (Closed Software)</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508</p>
                                <ul>
                                    <li>501 (Web)(Software)</li>
                                    <li>504.2 (Authoring Tool)</li>
                                    <li>602.3 (Support Docs)</li>
                                </ul>
                            </td>
                            <td>
                                <p><strong>Not Applicable</strong></p>
                                <br /></td>
                            <td>
                                <p>There is no multimedia content in the application.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="https://www.w3.org/TR/WCAG21/#orientation"><strong>1.3.4 Orientation</strong></a> (Level AA 2.1 only)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.1.3.4 (Web)</li>
                                    <li>10.1.3.4 (Non-web document)</li>
                                    <li>11.1.3.4 (Open Functionality Software)</li>
                                    <li>11.1.3.4 (Closed Software)</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508 &ndash; Does not apply</p>
                            </td>
                            <td>
                                <p><strong>Not Applicable</strong></p>
                                <br /></td>
                            <td>
                                <p>Testing on mobile devices was not in scope for this report.<br />Testing is planned to be performed by Q2 2022</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="https://www.w3.org/TR/WCAG21/#identify-input-purpose"><strong>1.3.5 Identify Input Purpose</strong></a> (Level AA 2.1 only)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.1.3.5 (Web)</li>
                                    <li>10.1.3.5 (Non-web document)</li>
                                    <li>11.1.3.5.1 (Open Functionality Software)</li>
                                    <li>11.1.3.5.2 (Closed Software)</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508 &ndash; Does not apply</p>
                            </td>
                            <td>
                                <p><strong>Supports</strong></p>
                                <br /></td>
                            <td>
                                <p>The application provides labels for all the instances associated with respective input fields, where the user needs to provide input information. Also, the application uses an autocomplete attribute which means the information does not need to be remembered by the user.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="http://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast"><strong>1.4.3 Contrast (Minimum)</strong></a> (Level AA)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.1.4.3 (Web)</li>
                                    <li>10.1.4.3 (Non-web document)</li>
                                    <li>11.1.4.3 (Open Functionality Software)</li>
                                    <li>11.1.4.3 (Closed Software)</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508</p>
                                <ul>
                                    <li>501 (Web)(Software)</li>
                                    <li>504.2 (Authoring Tool)</li>
                                    <li>602.3 (Support Docs)</li>
                                </ul>
                            </td>
                            <td>
                                <p><strong>Supports with Minor Exceptions</strong></p>
                                <br /></td>
                            <td>
                                <p>The application has sufficient contrast ratio (i.e. 4.5:1) for most of the elements present in the application. However, the contrast ratio is not sufficient for some elements.</p>
                                <p><strong>Exceptions:</strong></p>
                                <p>1. The color contrast ratio of text 'Give us 5' is less than the standard ratio 4.5:1.</p>
                                <p>2. The color contrast ratio of text 'Help and support, release notes' is less than the standard ratio 4.5:1.</p>
                                <p>3. The color contrast ratio of text 'To do, doing' etc is less than the standard ratio 4.5:1.</p>
                                <p><br />Planned to be fixed by Q2 2022</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="http://www.w3.org/TR/WCAG20/#visual-audio-contrast-scale"><strong>1.4.4 Resize text</strong></a> (Level AA)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.1.4.4 (Web)</li>
                                    <li>10.1.4.4 (Non-web document)</li>
                                    <li>11.1.4.4.1 (Open Functionality Software)</li>
                                    <li>11.1.4.4.2 (Closed Software)</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508</p>
                                <ul>
                                    <li>501 (Web)(Software)</li>
                                    <li>504.2 (Authoring Tool)</li>
                                    <li>602.3 (Support Docs)</li>
                                </ul>
                            </td>
                            <td>
                                <p><strong>Supports</strong></p>
                                <br /></td>
                            <td>
                                <p>The application is operable and readable while resizing the content using the browser zoom feature. No overlapping is observed on the page at 200% browser zoom.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="http://www.w3.org/TR/WCAG20/#visual-audio-contrast-text-presentation"><strong>1.4.5 Images of Text</strong></a> (Level AA)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.1.4.5 (Web)</li>
                                    <li>10.1.4.5 (Non-web document)</li>
                                    <li>11.1.4.5.1 (Open Functionality Software)</li>
                                    <li>11.1.4.5.2 (Closed Software) &ndash; Does not apply</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508</p>
                                <ul>
                                    <li>501 (Web)(Software)</li>
                                    <li>504.2 (Authoring Tool)</li>
                                    <li>602.3 (Support Docs)</li>
                                </ul>
                            </td>
                            <td>
                                <p><strong>Not Applicable</strong></p>
                                <br /></td>
                            <td>
                                <p>There is no information in the application in the form of image of text.&nbsp;&nbsp;</p>
                                <br /></td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="https://www.w3.org/TR/WCAG21/#reflow"><strong>1.4.10 Reflow</strong></a> (Level AA 2.1 only)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.1.4.10 (Web)</li>
                                    <li>10.1.4.10 (Non-web document)</li>
                                    <li>11.1.4.10 (Open Functionality Software)</li>
                                    <li>11.1.4.10 (Closed Software)</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508 &ndash; Does not apply</p>
                            </td>
                            <td>
                                <p><strong>Does Not Support</strong></p>
                                <br /></td>
                            <td>
                                <p>At 400% browser zoom, the application is not accessible.</p>
                                <p>Planned to be fixed by Q4 2021</p>
                                <br /></td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="https://www.w3.org/TR/WCAG21/#non-text-contrast"><strong>1.4.11 Non-text Contrast</strong></a> (Level AA 2.1 only)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.1.4.11 (Web)</li>
                                    <li>10.1.4.11 (Non-web document)</li>
                                    <li>11.1.4.11 (Open Functionality Software)</li>
                                    <li>11.1.4.11 (Closed Software)</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508 &ndash; Does not apply</p>
                            </td>
                            <td>
                                <p><strong>Supports with Minor Exceptions</strong></p>
                                <br /></td>
                            <td>
                                <p>The application has sufficient contrast ratio (i.e. 4.5:1) for most of the elements present in the application. However, the contrast ratio is not sufficient for some elements.</p>
                                <p><strong>Exceptions:</strong></p>
                                <p>1. The color contrast ratio of the bell icon is less than the standard ratio 3:1.</p>
                                <p>2. The color contrast ratio of the plus icon is less than the standard ratio 3:1.</p>
                                <p><br />Planned to be fixed by Q2 2022</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="https://www.w3.org/TR/WCAG21/#text-spacing"><strong>1.4.12 Text Spacing</strong></a> (Level AA 2.1 only)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.1.4.12 (Web)</li>
                                    <li>10.1.4.12 (Non-web document)</li>
                                    <li>11.1.4.12 (Open Functionality Software)</li>
                                    <li>11.1.4.12 (Closed Software)</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508 &ndash; Does not apply</p>
                            </td>
                            <td>
                                <p><strong>Supports with Minor Exceptions</strong></p>
                                <br /></td>
                            <td>
                                <p>The application supports people with low vision and cognitive disabilities as it allows users to view the application content in their desired text (letter, word, line and paragraph) spacing, however some exceptions are still there.</p>
                                <p><strong>Exception:</strong></p>
                                <p>1. When the text spacing is applied, the text 'Backups' overlaps with the 'x' close icon.</p>
                                <p>2. When the text spacing is applied, the button 'Action' truncates..</p>
                                <p>3. When the text spacing is applied, the text 'Filter' of 'Search &amp; Filter' overlaps with the down arrow icon.</p>
                                <p>Planned to be fixed by Q4 2021</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="https://www.w3.org/TR/WCAG21/#content-on-hover-or-focus"><strong>1.4.13 Content on Hover or Focus</strong></a> (Level AA 2.1 only)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.1.4.13 (Web)</li>
                                    <li>10.1.4.13 (Non-web document)</li>
                                    <li>11.1.4.13 (Open Functionality Software)</li>
                                    <li>11.1.4.13 (Closed Software)</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508 &ndash; Does not apply</p>
                            </td>
                            <td>
                                <p><strong>Supports</strong></p>
                                <br /></td>
                            <td>
                                <p>There is a web element available in the application which triggers the additional content after receiving pointer hover or keyboard focus and the additional content is hoverable and dismissible.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="http://www.w3.org/TR/WCAG20/#navigation-mechanisms-mult-loc"><strong>2.4.5 Multiple Ways</strong></a> (Level AA)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.2.4.5 (Web)</li>
                                    <li>10.2.4.5 (Non-web document) &ndash; Does not apply</li>
                                    <li>11.2.4.5 (Open Functionality Software) &ndash; Does not apply</li>
                                    <li>11.2.4.5 (Closed Software) &ndash; Does not apply</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508</p>
                                <ul>
                                    <li>501 (Web)(Software) &ndash; Does not apply to non-web software</li>
                                    <li>504.2 (Authoring Tool)</li>
                                    <li>602.3 (Support Docs) &ndash; Does not apply to non-web docs</li>
                                </ul>
                            </td>
                            <td>
                                <p><strong>Not Applicable</strong></p>
                            </td>
                            <td><p>The application design does not involve multiple ways.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="http://www.w3.org/TR/WCAG20/#navigation-mechanisms-descriptive"><strong>2.4.6 Headings and Labels</strong></a> (Level AA)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.2.4.6 (Web)</li>
                                    <li>10.2.4.6 (Non-web document)</li>
                                    <li>11.2.4.6 (Open Functionality Software)</li>
                                    <li>11.2.4.6 (Closed Software)</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508</p>
                                <ul>
                                    <li>501 (Web)(Software)</li>
                                    <li>504.2 (Authoring Tool)</li>
                                    <li>602.3 (Support Docs)</li>
                                </ul>
                            </td>
                            <td>
                                <p><strong>Supports</strong></p>
                                <br /></td>
                            <td><p>Headings in the application are descriptive enough to understand their purpose and a sufficient label is provided for each form field wherever a descriptive label is needed.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="http://www.w3.org/TR/WCAG20/#navigation-mechanisms-focus-visible"><strong>2.4.7 Focus Visible</strong></a> (Level AA)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.2.4.7 (Web)</li>
                                    <li>10.2.4.7 (Non-web document)</li>
                                    <li>11.2.4.7 (Open Functionality Software)</li>
                                    <li>11.2.4.7 (Closed Software)</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508</p>
                                <ul>
                                    <li>501 (Web)(Software)</li>
                                    <li>504.2 (Authoring Tool)</li>
                                    <li>602.3 (Support Docs)</li>
                                </ul>
                            </td>
                            <td>
                                <p><strong>Supports</strong></p>
                                <br /></td>
                            <td>
                                <p>Tab focus is visible on all interactive elements in the application.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="http://www.w3.org/TR/WCAG20/#meaning-other-lang-id"><strong>3.1.2 Language of Parts</strong></a> (Level AA)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.3.1.2 (Web)</li>
                                    <li>10.3.1.2 (Non-web document)</li>
                                    <li>11.3.1.2 (Open Functionality Software) &ndash; Does not apply</li>
                                    <li>11.3.1.2 (Closed Software) &ndash; Does not apply</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508</p>
                                <ul>
                                    <li>501 (Web)(Software)</li>
                                    <li>504.2 (Authoring Tool)</li>
                                    <li>602.3 (Support Docs)</li>
                                </ul>
                            </td>
                            <td>
                                <p><strong>Supports</strong></p>
                                <br /></td>
                            <td>
                                <p>The application content is available in English language only. There is no change of the language throughout the application.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="http://www.w3.org/TR/WCAG20/#consistent-behavior-consistent-locations"><strong>3.2.3 Consistent Navigation</strong></a> (Level AA)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.3.2.3 (Web)</li>
                                    <li>10.3.2.3 (Non-web document) &ndash; Does not apply</li>
                                    <li>11.3.2.3 (Open Functionality Software) &ndash; Does not apply</li>
                                    <li>11.3.2.3 (Closed Software) &ndash; Does not apply</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508</p>
                                <ul>
                                    <li>501 (Web)(Software) &ndash; Does not apply to non-web software</li>
                                    <li>504.2 (Authoring Tool)</li>
                                    <li>602.3 (Support Docs) &ndash; Does not apply to non-web docs</li>
                                </ul>
                            </td>
                            <td>
                                <p><strong>Supports</strong></p>
                                <br /></td>
                            <td><p>Repeated components are occurring in the same order on every page of the application, so it helps screen readers and cognitive users to predict the location of web elements on each page.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="http://www.w3.org/TR/WCAG20/#consistent-behavior-consistent-functionality"><strong>3.2.4 Consistent Identification</strong></a> (Level AA)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.3.2.4 (Web)</li>
                                    <li>10.3.2.4 (Non-web document) &ndash; Does not apply</li>
                                    <li>11.3.2.4 (Open Functionality Software) &ndash; Does not apply</li>
                                    <li>11.3.2.4 (Closed Software) &ndash; Does not apply</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508</p>
                                <ul>
                                    <li>501 (Web)(Software) &ndash; Does not apply to non-web software</li>
                                    <li>504.2 (Authoring Tool)</li>
                                    <li>602.3 (Support Docs) &ndash; Does not apply to non-web docs</li>
                                </ul>
                            </td>
                            <td>
                                <p><strong>Supports</strong></p>
                                <br /></td>
                            <td>
                                <p>Elements that have the same functionality across multiple pages in the application have the same labels or the same name.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="http://www.w3.org/TR/WCAG20/#minimize-error-suggestions"><strong>3.3.3 Error Suggestion</strong></a> (Level AA)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.3.3.3 (Web)</li>
                                    <li>10.3.3.3 (Non-web document)</li>
                                    <li>11.3.3.3 (Open Functionality Software)</li>
                                    <li>11.3.3.3 (Closed Software)</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508</p>
                                <ul>
                                    <li>501 (Web)(Software)</li>
                                    <li>504.2 (Authoring Tool)</li>
                                    <li>602.3 (Support Docs)</li>
                                </ul>
                            </td>
                            <td>
                                <p><strong>Supports</strong></p>
                                <br /></td>
                            <td>
                                <p>Descriptive error messages appear in the application.</p>
                                <br /></td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="http://www.w3.org/TR/WCAG20/#minimize-error-reversible"><strong>3.3.4 Error Prevention (Legal, Financial, Data)</strong></a> (Level AA)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.3.3.4 (Web)</li>
                                    <li>10.3.3.4 (Non-web document)</li>
                                    <li>11.3.3.4 (Open Functionality Software)</li>
                                    <li>11.3.3.4 (Closed Software)</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508</p>
                                <ul>
                                    <li>501 (Web)(Software)</li>
                                    <li>504.2 (Authoring Tool)</li>
                                    <li>602.3 (Support Docs)</li>
                                </ul>
                            </td>
                            <td>
                                <p><strong>Not Applicable</strong></p>
                                <br /></td>
                            <td>
                                <p>This success criterion is related to legal commitment, financial data, etc., that involves serious risk if no option is provided for users to review or revert the data before submission. However, the application does not have any forms associated with such risk.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="https://www.w3.org/TR/WCAG21/#status-messages"><strong>4.1.3 Status Messages</strong></a> (Level AA 2.1 only)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.4.1.3 (Web)</li>
                                    <li>10.4.1.3 (Non-web document)</li>
                                    <li>11.4.1.3 (Open Functionality Software)</li>
                                    <li>11.4.1.3 (Closed Software) &ndash; Does not apply</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508 &ndash; Does not apply</p>
                            </td>
                            <td>
                                <p><strong> Supports</strong></p>
                            </td>
                            <td>
                                <p>Screen readers announce the alert messages.</p>
                            </td>
                        </tr>
        </tbody>
    );
};
