import { IAssigneesSearchSelectProps } from './types';
import * as React from 'react';
import { AssigneesAdd } from '../AssigneesAdd/AssigneesAdd';
import { debounce } from 'underscore';
import { SearchSelect } from '../../../../../base/components/SearchSelect/SearchSelect';
import { CLASS_CARD_DETAILS } from '../../..';
import { SelectListItem } from 'kui';
import { ASSIGNEES_SHARE_BUTTON, ASSIGNEES_SHARE_ID } from './constants';
import './_AssigneesSearchSelect.scss';

const debouncedInputKeyDown = debounce((
    onInputKeyDown: () => void,
) => {
    onInputKeyDown();
}, 5000);

export function AssigneesSearchSelect ({
    availableElements,
    isMultiselect,
    isOpened,
    isPopup,
    isShowShareButton,
    onSelect,
    onInputKeyDown,
    onAddClosed,
}: IAssigneesSearchSelectProps) {
    const classNameShare = CLASS_CARD_DETAILS + '__assignees-share';
    return (
        <SearchSelect
            autoFocus
            isCloseOnClick={false}
            isFitWindow={ isPopup }
            multiple={ true }
            isDropdownUplifted={!isPopup || isMultiselect}
            isMultipleGroupToggle={false}
            opened={ isOpened }
            options={ availableElements }
            option={ AssigneesAdd }
            footer={isShowShareButton &&
                <SelectListItem
                    className={classNameShare}
                    icon={'share-link'}
                    iconSize={24}
                    key={ASSIGNEES_SHARE_ID}
                    value={ASSIGNEES_SHARE_ID}
                >
                    <span>{ASSIGNEES_SHARE_BUTTON}</span>
                </SelectListItem>
            }
            onBlur={ onAddClosed }
            onClosed={ onAddClosed }
            onSelect={ onSelect }
            onInputKeyDown={ () => debouncedInputKeyDown(onInputKeyDown) }
        />
    )
}
