import { IApplicationState } from '../../../../types/types';
import { getAuthUser } from './getAuthUser';
import { PRODUCTS_BY_PLAN } from '../../../../view/react_components/dialogs/price_page/constants';
import { EProductPlan } from '../../../../view/react_components/dialogs/price_page/types';

export const getIsUserBasicPlanOnly = (
    state: IApplicationState
): boolean => {
    const user = getAuthUser(state);
    let isBasicPlan = false;
    const subscriptions = user.activeSubscription;
    for (let i in subscriptions) {
        const { productId } = subscriptions[i];
        if (PRODUCTS_BY_PLAN[EProductPlan.BASIC].includes(productId)) {
            isBasicPlan = true;
        } else if ([
            ...PRODUCTS_BY_PLAN[EProductPlan.PREMIUM],
            ...PRODUCTS_BY_PLAN[EProductPlan.PROFESSIONAL],
            ...PRODUCTS_BY_PLAN[EProductPlan.ENTERPRISE]
        ].includes(productId)) {
            isBasicPlan = false;
            break;
        }
    }
    return isBasicPlan;
};
