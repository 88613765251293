import { connect } from 'react-redux';
import { FilterInfoPanel } from '../components/FilterInfoPanel';
import { IFilterInfoPanelDispatchProps, IFilterInfoPanelStateProps } from '../components/types';
import { IApplicationState } from '../../../../../types/types';
import { IFilterInfoOwnProps } from './types';
import { onClose } from './events/onClose';
import { filterPanelBoardsSelector } from '../../filterPanelBoard/store/selectors/filterPanelBoardsSelector';
import { onOpen } from './events/onOpen';
import { isRelatedCardsPanelOpened } from '../../relatedCards/store/selectors/isRelatedCardsPanelOpened';

const mapStateToProps = (
    state: IApplicationState,
    {boardId}: IFilterInfoOwnProps
): IFilterInfoPanelStateProps => {
    const { isOpened } = filterPanelBoardsSelector(state, boardId);
    const isRelatedCardsPanel = isRelatedCardsPanelOpened(state);
    return {
        isOpen: isOpened,
        isRelatedCardsPanel
    }
};

const mapDispatchToProps = (
    dispatch: any,
    {boardId}: IFilterInfoOwnProps
): IFilterInfoPanelDispatchProps => {
    return {
        onClose: () => dispatch(onClose(boardId)),
        onOpen: () => dispatch(onOpen(boardId))
    }
};

export const FilterInfoPanelHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(FilterInfoPanel);

FilterInfoPanelHOC.displayName = 'FilterInfoPanelHOC';
