import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState } from '../../../../../../../types/types';
import { processPerformanceAllow } from '../../../effects/processPerformanceAllow';
import { newPerformanceAllowedValueSet } from '../../../store/actions/newPerformanceAllowedValueSet';

export const onChangePerformance = (
    isPerformanceAllowed: boolean
): ThunkAction => {
    const action = (dispatch: Dispatch, getState: IGetState) => {
        dispatch(newPerformanceAllowedValueSet(isPerformanceAllowed));
        dispatch(processPerformanceAllow());
    };
    return action;
};
