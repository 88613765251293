import * as React from 'react';
import { IPanelActionsProps } from './types';
import { ASIDE_PANEL_ACTIONS_SECTION_TITLE_PROPERTIES, AsidePanelActionsButtonsGroup, AsidePanelActionsSection } from '../../../../index';
import { AssigneesPanelButtonHOC } from '../../../AssigneesSection/hocs/AssigneesPanelButtonHOC/AssigneesPanelButtonHOC';
import { CardTagsPanelButtonHOC } from '../../../TagsSection/hocs/CardTagsPanelButtonHOC/CardTagsPanelButtonHOC';
import { CardDatesPanelButtonHOC } from '../../../DatesSection/hocs/CardDatesPanelButtonHOC/CardDatesPanelButtonHOC';
import { CardChecklistPanelButtonHOC } from '../../../ChecklistSection/hocs/CardChecklistPanelButtonHOC/CardChecklistPanelButtonHOC';
import { CardAttachmentsPanelButtonHOC as CardAttachmentsSectionHOCgoogle } from '../../../AttachmentsSection/hocs/CardAttachmentsPanelButtonHOC/CardAttachmentsPanelButtonHOC';
import { CardAttachmentsPanelButtonHOC as CardAttachmentsSectionHOCmicrosoft } from '../../../AttachmentsSection/hocs/microsoft/CardAttachmentsPanelButtonHOC/CardAttachmentsPanelButtonHOC';
import { CardEstimateAndSpentTimePanelButtonHOC } from '../../../EstimateAndSpentTimeSection/hocs/CardEstimateAndSpentTimePanelButtonHOC/CardEstimateAndSpentTimePanelButtonHOC';
import { CardSubcardsPanelButtonHOC } from '../../../SubcardsSection/hocs/CardSubcardsPanelButtonHOC/CardSubcardsPanelButtonHOC';
import { DependenciesPanelButtonHOC } from '../../../DependenciesSection/hocs/DependenciesPanelButtonHOC/DependenciesPanelButtonHOC';
import { CommentsPanelButtonHOC } from '../../../CommentsSection/hocs/CommentsPanelButtonHOC/CommentsPanelButtonHOC';
import { CardRelatedPanelButtonHOC } from '../../../RelatedSection/hocs/CardRelatedPanelButtonHOC/CardRelatedPanelButtonHOC';
import { ShowOnGanttButtonHOC } from '../../../ShowOnGanttButton/hocs/ShowOnGanttButtonHOC/ShowOnGanttButtonHOC';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { ECardTab } from '../../../types';
import './_PanelActions.scss';
import { MilestoneButtonHOC } from '../../../MilestoneButton/hocs/MilestoneButtonHOC/MilestoneButtonHOC';
import { CardPriorityButtonHOC } from '../../../Priority/hocs/CardPriorityButtonHOC/CardPriorityButtonHOC';
import { CustomPropertiesPanelButtonHOC } from '../../../CustomPropertiesSection/hocs/CustomPropertiesPanelButtonHOC/CustomPropertiesPanelButtonHOC';
import {
    CLASS_ASIDE_PANEL_ACTIONS_SECTION_ADD_PROPERTIES,
    CLASS_ASIDE_PANEL_ACTIONS_SECTION_CONTAINER
} from '../../../../asidePanel/components/AsidePanelActionsSection/constants';
import { AsidePanelActionsButtonsGroupTitle } from '../../../../asidePanel/components/AsidePanelActionsButtonsGroupTitle/AsidePanelActionsButtonsGroupTitle';
import {
    ASIDE_PANEL_ACTIONS_SECTION_TITLE_COLLABORATE,
    ASIDE_PANEL_ACTIONS_SECTION_TITLE_ORGANISE,
    ASIDE_PANEL_ACTIONS_SECTION_TITLE_PLAN_PROJECT
} from '../../../../asidePanel/components/AsidePanelActions/constants';
import { CardColorTagsButtonHOC } from '../../../ColorTags/hocs/CardColorTagsButtonHOC/CardColorTagsButtonHOC';
import { EAuthUserPlatformType } from 'app/types/rest/IRestAuthUser';
import { CardPropertiesPanelButtonHOC } from '../../hocs/CardPropertiesPanelButtonHOC/CardPropertiesPanelButtonHOC';

export function PanelActions({
    boardId,
    cardId,
    isMinimized,
    isShow,
    platformType,
}: IPanelActionsProps) {
    if (!isShow) {
        return null;
    }

    let CardAttachmentsPanelButtonHOC;
    switch (platformType) {
        case EAuthUserPlatformType.MICROSOFT:
            CardAttachmentsPanelButtonHOC = CardAttachmentsSectionHOCmicrosoft;
            break;
        default:
            CardAttachmentsPanelButtonHOC = CardAttachmentsSectionHOCgoogle;
    }

    const { changeTab, isMobile } = React.useContext(AsidePanelContext);
    const onClickSwitchTabDetails = React.useCallback(() => {
        changeTab(ECardTab.DETAILS);
    }, []);
    const isShowTooltip = isMinimized && !isMobile;

    return (
        <>
            <AsidePanelActionsSection
                title={ASIDE_PANEL_ACTIONS_SECTION_TITLE_PROPERTIES}
                titleButton={<CardPropertiesPanelButtonHOC boardId={boardId} />}
            >
                <div className={CLASS_ASIDE_PANEL_ACTIONS_SECTION_CONTAINER}>
                    <div className={CLASS_ASIDE_PANEL_ACTIONS_SECTION_ADD_PROPERTIES}>
                        <AsidePanelActionsButtonsGroup>
                            {!isMinimized && <AsidePanelActionsButtonsGroupTitle
                                title={ASIDE_PANEL_ACTIONS_SECTION_TITLE_COLLABORATE}/>}

                            <AssigneesPanelButtonHOC
                                boardId={boardId}
                                cardId={cardId}
                                isShowTooltip={isShowTooltip}
                                onClick={onClickSwitchTabDetails}
                            />

                            <CommentsPanelButtonHOC
                                boardId={boardId}
                                cardId={cardId}
                                isShowTooltip={isShowTooltip}
                                onClick={onClickSwitchTabDetails}
                            />

                            <CardAttachmentsPanelButtonHOC
                                boardId={boardId}
                                cardId={cardId}
                                isShowTooltip={isShowTooltip}
                                onClick={onClickSwitchTabDetails}
                            />
                        </AsidePanelActionsButtonsGroup>

                        <AsidePanelActionsButtonsGroup>
                            {!isMinimized && <AsidePanelActionsButtonsGroupTitle
                                title={ASIDE_PANEL_ACTIONS_SECTION_TITLE_ORGANISE}/>}

                            <CardPriorityButtonHOC
                                boardId={boardId}
                                cardId={cardId}
                                isShowTooltip={isShowTooltip}
                                onClick={onClickSwitchTabDetails}
                            />

                            <CardColorTagsButtonHOC
                                boardId={boardId}
                                cardId={cardId}
                                isShowTooltip={isShowTooltip}
                                onClick={onClickSwitchTabDetails}
                            />

                            <CardTagsPanelButtonHOC
                                boardId={boardId}
                                cardId={cardId}
                                isShowTooltip={isShowTooltip}
                                onClick={onClickSwitchTabDetails}
                            />

                            <CardRelatedPanelButtonHOC
                                boardId={boardId}
                                cardId={cardId}
                                isShowTooltip={isShowTooltip}
                                onClick={onClickSwitchTabDetails}
                            />
                        </AsidePanelActionsButtonsGroup>

                        <AsidePanelActionsButtonsGroup>
                            <CardSubcardsPanelButtonHOC
                                boardId={boardId}
                                cardId={cardId}
                                isShowTooltip={isShowTooltip}
                                onClick={onClickSwitchTabDetails}
                            />

                            <CardChecklistPanelButtonHOC
                                boardId={boardId}
                                cardId={cardId}
                                isShowTooltip={isShowTooltip}
                                onClick={onClickSwitchTabDetails}
                            />

                            <CustomPropertiesPanelButtonHOC
                                boardId={boardId}
                                cardId={cardId}
                                isShowTooltip={isShowTooltip}
                            />
                        </AsidePanelActionsButtonsGroup>
                    </div>

                    <AsidePanelActionsButtonsGroup>
                        {!isMinimized && <AsidePanelActionsButtonsGroupTitle
                            title={ASIDE_PANEL_ACTIONS_SECTION_TITLE_PLAN_PROJECT}/>}

                        <CardDatesPanelButtonHOC
                            boardId={boardId}
                            cardId={cardId}
                            isShowTooltip={isShowTooltip}
                            onClick={onClickSwitchTabDetails}
                        />

                        <DependenciesPanelButtonHOC
                            boardId={boardId}
                            cardId={cardId}
                            isShowTooltip={isShowTooltip}
                            onClick={onClickSwitchTabDetails}
                        />

                        <ShowOnGanttButtonHOC
                            boardId={boardId}
                            cardId={cardId}
                            isShowTooltip={isShowTooltip}
                        />

                        <CardEstimateAndSpentTimePanelButtonHOC
                            boardId={boardId}
                            cardId={cardId}
                            isShowTooltip={isShowTooltip}
                            onClick={onClickSwitchTabDetails}
                        />

                        <MilestoneButtonHOC
                            boardId={boardId}
                            cardId={cardId}
                            isShowTooltip={isShowTooltip}
                        />
                    </AsidePanelActionsButtonsGroup>
                </div>
            </AsidePanelActionsSection>
        </>
    );
}
