import * as React from 'react';
import { Hint } from 'kui';
import { IErrorHintProps } from './types';

export const ErrorHint: React.FC<IErrorHintProps> =
({
    children,
    value,
    ...attributes
}) => {
    const [hintError, setHintError] = React.useState(null);
    const [hintShow, setHintShow] = React.useState(false);

    React.useEffect(() => {
        if (hintShow) {
            setHintShow(false);
            if (value) {
                setTimeout(() => {
                    setHintShow(true);
                    setHintError(value);
                }, 400);
            }
        } else {
            setHintShow(!!value);
            setHintError(value);
        }
    }, [value]);
    return (
        <Hint
            value={hintError}
            show={hintShow}
            isPortal={false}
            state="error"
            isHidable={false}
            { ...attributes }
        >
            {children}
        </Hint>
    )
};

ErrorHint.defaultProps = {
    value: null
};
