import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../types/types';

export const onClickEmpty = (
    isShow: boolean,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(isShow
            ? SegmentCardEvent.CUSTOM_PROPERTIES_SHOW_CLICKED
            : SegmentCardEvent.CUSTOM_PROPERTIES_HIDE_CLICKED
        ));
    };
    return action;
};
