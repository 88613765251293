import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import {
    LANDING_BRAZIL_URL,
    LANDING_GANTTCHART_URL,
    LANDING_KANBAN_URL,
    LANDING_N1_URL,
    LANDING_N2_URL,
    LANDING_TASKMANAGER_URL,
    LANDING_TM_URL,
} from '../../const';
import '../react_components/dialogs/signIn/components/_SignInCommon.scss';

export const SignInView = App.Views.BaseView.extend({
    initialize: function(options) {
        this.root = createRoot(this.el);
        this.store = options.store;
    },

    render: function () {
        let Component;

        const landingUrls = [
            LANDING_N1_URL,
            LANDING_N2_URL,
            LANDING_TASKMANAGER_URL,
            LANDING_KANBAN_URL,
            LANDING_GANTTCHART_URL,
            LANDING_BRAZIL_URL,
            LANDING_TM_URL,
        ];
        const landingUrlsSlashes = landingUrls.reduce((urls, url) => {
            urls.push(url + '/');
            return urls;
        }, landingUrls);
        const isLanding = landingUrlsSlashes.includes(location.pathname);

        if (isLanding) {
            switch (location.pathname) {
                case LANDING_N1_URL:
                case LANDING_N1_URL + '/': {
                    Component = React.lazy(() =>
                        import(/* webpackChunkName: "landingn1" */ '../react_components/dialogs/signIn/hocs/LandingN1HOC/LandingN1HOC')
                            .then(module => ({default: module.LandingN1HOC}))
                            .catch(e => console.error(e))
                    );
                    break;
                }
                case LANDING_N2_URL:
                case LANDING_N2_URL + '/': {
                    Component = React.lazy(() =>
                        import(/* webpackChunkName: "landingn2" */ '../react_components/dialogs/signIn/hocs/LandingN2HOC/LandingN2HOC')
                            .then(module => ({default: module.LandingN2HOC}))
                            .catch(e => console.error(e))
                    );
                    break;
                }
                case LANDING_TM_URL:
                case LANDING_TM_URL + '/': 
                case LANDING_TASKMANAGER_URL:
                case LANDING_TASKMANAGER_URL + '/': {
                    Component = React.lazy(() =>
                        import(/* webpackChunkName: "landingn2" */ '../react_components/dialogs/signIn/components/LandingTaskmanager/LandingTaskmanager')
                            .then(module => ({default: module.LandingTaskmanager}))
                            .catch(e => console.error(e))
                    );
                    break;
                }
                case LANDING_KANBAN_URL:
                case LANDING_KANBAN_URL + '/': {
                    Component = React.lazy(() =>
                        import(/* webpackChunkName: "landingn2" */ '../react_components/dialogs/signIn/components/LandingKanban/LandingKanban')
                            .then(module => ({default: module.LandingKanban}))
                            .catch(e => console.error(e))
                    );
                    break;
                }
                case LANDING_GANTTCHART_URL:
                case LANDING_GANTTCHART_URL + '/': {
                    Component = React.lazy(() =>
                        import(/* webpackChunkName: "landingn2" */ '../react_components/dialogs/signIn/components/LandingGanttchart/LandingGanttchart')
                            .then(module => ({default: module.LandingGanttchart}))
                            .catch(e => console.error(e))
                    );
                    break;
                }
                case LANDING_BRAZIL_URL:
                case LANDING_BRAZIL_URL + '/': {
                    Component = React.lazy(() =>
                        import(/* webpackChunkName: "landingn2" */ '../react_components/dialogs/signIn/hocs/LandingBrazilHOC/LandingBrazilHOC')
                            .then(module => ({default: module.LandingBrazilHOC}))
                            .catch(e => console.error(e))
                    );
                    break;
                }
            }
        } else {
            Component = React.lazy(() =>
                import(/* webpackChunkName: "signin" */ '../react_components/dialogs/signIn/hocs/SignInHOC/SignInHOC')
                    .then(module => ({default: module.SignInHOC}))
                    .catch(e => console.error(e))
            );
        }

        this.root.render(
            <Provider store={this.store}>
                <Component />
            </Provider>
        );
        return this;
    },

    remove: function() {
        this.root.unmount();
        Backbone.View.prototype.remove.call(this);
    }
});
