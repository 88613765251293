const category = 'Blockers';

export const SegmentBlockersEvent = {
    BLOCKER_SHOWN: { category, event: 'Blocker shown' },
    GOTO_PRICING_CLICKED: { category, event: 'Go to pricing clicked' },
    UPGRADE_NOW_CLICKED: { category, event: 'Upgrade now clicked' },
    GO_BACK_CLICKED: { category, event: 'Go back clicked' }, // its only on archive board opened, back could be any view
    BACK_TO_KANBAN_CLICKED: { category, event: 'Back to Kanban clicked' },

    // expired | deleted
    RENEW_SEATS_CLICKED: { category, event: 'Renew X seats clicked' },
    MANAGE_SUBSCRIPTIONS_CLICKED: { category, event: 'Manage subscriptions clicked' },
    CONTACT_US_CLICKED: { category, event: 'Contact us clicked' },
    EXTEND_TRIAL_FOR_14_DAYS_LINK_CLICKED: { category, event: 'Extend trial for 14 days clicked' },
    EXTEND_TRIAL_SUBMITTED: { category, event: 'Extend trial submitted' },
    READ_ONLY: {category, event: 'Continue read only'},
    FREE_VERSION: {category, event: 'Continue with free version'},
    SNOOZE_BLOCKER_SHOWN: { category, event: 'Snooze blocker shown' },
    SNOOZE_BLOCKER_UPGRADE_CLICKED: { category, event: 'Snooze blocker upgrade clicked' },
    SNOOZE_BLOCKER_TRY_CLICKED: { category, event: 'Snooze blocker try features clicked' },
    SNOOZE_BLOCKER_BACK_CLICKED: { category, event: 'Snooze back to Kanbanchi clicked' },
};

export enum ESegmentBlockerType {
    ARCHIVE = 'Arhive not allowed',
    GANTT = 'Gantt not allowed',
    LIST = 'List view not allowed',
    REPORTS = 'Reports not allowed',
    TEAMWORKLOAD = 'TeamWorkload not allowed',
    OWN_SUBSCRIPTION_EXPIRED = 'Own subscription expired',
    OWN_SUBSCRIPTION_DELETED = 'Own subscription deleted',
    ASK_MANAGER = 'Subscription expired & user has manager',
    AFTER_FIRST_TRIAL = 'After first trial',
    AFTER_SECOND_TRIAL = 'After second trial',
    ASSIGNED_TO_ME = 'Cards assigned to me',
    SHARED_DRIVES = 'Shared drives are not allowed',
    SWIMLANES = 'Swimlanes not allowed',
}

export enum ESegmentBlockerOptions {
    type = 'type',
    allow = 'allow',
    voice = 'voice'
}
