import { connect } from 'react-redux';
import { EMPTY_ARRAY, IApplicationState } from '../../../../../../../types/types';
import { ICardSubcardsHOCOwnProps } from './types';
import { ICardSubcardsEvents, ICardSubcardsFields } from '../../components/CardSubcardsSection/types';
import { CardSubcardsSection } from '../../components/CardSubcardsSection/CardSubcardsSection';
import { onDelete } from './events/onDelete';
import { onGantt } from './events/onGantt';
import { onSort } from './events/onSort';
import { onSubcardLink } from './events/onSubcardLink';
import { getCardDetailsSubCards } from '../../selectors/getCardDetailsSubCard';
import { getSectionType } from '../../../selectors/getSectionType';
import { onAddClicked } from './events/onAddClicked';
import { onClickDisallow } from './events/onClickDisallow';
import { getBoardIsCardDefaultProperty } from '../../../../../../../store/model/board/selectors/getBoardIsCardDefaultProperty';
import { EAsidePanelProperty } from '../../../../asidePanel/components/AsidePanel/types';
import { getCardPinnedSections } from '../../../../../../../store/model/selectors/getCardPinnedSections';
import { onPinToggle } from './events/onPinToggle';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { onWeightChange } from './events/onWeightChange';
import { getBoardUsers } from '../../../../../../../store/model/selectors/getBoardUsers';
import { getBoardRolesSorted } from '../../../../../../../store/model/board/selectors/getBoardRolesSorted';
import { getCard } from '../../../../../../../store/model/selectors/getCard';
import { getBoard } from '../../../../../../../store/model/selectors/getBoardById';
import { getAuthUserIsHideSubcards } from '../../../../../../../store/model/authUser/selectors/getAuthUserIsHideSubcards';
import { onHideDoneChange } from './events/onHideDoneChange';
import { getSubcardsStats } from '../../../../../../../store/model/selectors/calcCardProcessingPercent';
import { isEqual } from 'underscore';

// const analyze = new Analyze('CardSubcardsSectionHOC');
// 10 раз открыл-закрыл карту
// no reselect    average: 0.682 ms, calls: 120, total: 81.800 ms
// cache          average: 0.439 ms, calls: 120, total: 52.700 ms

const mapStateToProps = () => {
    interface ICaheProps extends ICardSubcardsFields{};
    let cache: ICaheProps = null;
    const getCache = (
        value: ICaheProps
    ): ICaheProps => {
        if (isEqual(value, cache)) {
            return cache;
        }
        cache = value;
        return value;
    };

    return (
        state: IApplicationState,
        ownProps: ICardSubcardsHOCOwnProps
    ): ICardSubcardsFields => {
        // analyze.start();
        const {boardId, cardId, sectionType: sectionTypeOwn} = ownProps;
        const elements = getCardDetailsSubCards(state, cardId, boardId);
        const isPinned = getCardPinnedSections(getCard(state, cardId)).subcards;
        const sectionType = getSectionType(state, boardId, cardId, sectionTypeOwn, 'allowSubCard');
        const isHideDoneSubcards = getAuthUserIsHideSubcards(state);
        const subcardsStats = getSubcardsStats(state, cardId, elements);

        let ret = getCache({
            boardId,
            cardId,
            elements: elements && elements.length
                ? elements
                : isPinned || sectionType !== ESettingsSectionType.READONLY
                    ? EMPTY_ARRAY
                    : null,
            isDefaultProperty: getBoardIsCardDefaultProperty(state, boardId, EAsidePanelProperty.CARD_SUBCARDS),
            isPinned,
            sectionType,
            boardUsers: getBoardUsers(state, boardId),
            boardRoles: getBoardRolesSorted(getBoard(state, boardId)),
            isHideDoneSubcards,
            subcardsStats
        });
        // analyze.finish();
        return ret;
    };
};

const mapDispatchToProps = (
    dispatch: any,
    ownProps: ICardSubcardsHOCOwnProps
): ICardSubcardsEvents => {
    const { boardId, cardId } = ownProps;
    return {
        onDelete: (subcardId) => dispatch(onDelete(subcardId)),
        onGantt: (subcardId) => dispatch(onGantt(subcardId)),
        onSort: (subcardId, index, subcardIds) => dispatch(onSort(subcardId, index, subcardIds)),
        onSubcardLink: (subcardId) => dispatch(onSubcardLink(subcardId, boardId)),
        onAddClicked: () => dispatch(onAddClicked(boardId)),
        onClickDisallow: () => dispatch(onClickDisallow()),
        onPinToggle: () => dispatch(onPinToggle(cardId)),
        onWeightChange: (subcardId, weight) => dispatch(onWeightChange(subcardId, weight)),
        onHideDoneChange: () => dispatch(onHideDoneChange())
    };
};

export const CardSubcardsSectionHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardSubcardsSection);

CardSubcardsSectionHOC.displayName = 'CardSubcardsSectionHOC';
