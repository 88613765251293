import { IGetState, TCardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { getCard } from '../../../../store/model/selectors/getCard';
import { IRestRelatedCard } from '../../../../types/rest/IRestRelatedCard';
import { patchRest } from './api/patchRest';
import { cardsUpdate } from '../../../../store/model/actionCreators/cardsUpdate';
import { ICards } from '../../../../store/model/cards/types';
import { getCardsForRollback } from '../../../../store/model/selectors/getCardsForRollback';
import { sendRealTimeStoreActionByCardId } from '../../../../view/react_components/base/helpers/realTimeHelperTS';
import { TStatus } from '../../../../types/model';
import { getCardRelatedCardsActive } from '../../../../store/model/selectors/getCardRelatedCardsActive';
import { cardsActiveRequestsUpdate } from '../../../../store/requestsState/effects/cardsActiveRequestsUpdate';
import * as _ from 'underscore';

export const cardUpdateRelatedCards = (
    cardId: TCardId,
    relatedCards: IRestRelatedCard[],
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const card = getCard(state, cardId);
        if (!card) return Promise.reject();
        const relatedCardsActive =  relatedCards.filter((related)=> related.status === TStatus.STATUS_ACTIVE);

        const cards: ICards = {
            [cardId]: {
                relatedCards,
                relatedCardCount: relatedCardsActive.length
            }
        };

        const cardsForRollback = getCardsForRollback(state, cards);
        dispatch(cardsActiveRequestsUpdate(cards, 1));
        dispatch(cardsUpdate(cards));
        return dispatch(patchRest(cardId, relatedCards))
            .then((result: IRestRelatedCard[]) => {
                updateRelatedCardsForCard(result, cardId, getState, dispatch);
                updateRelatedCardsForReverseCard(relatedCards, result, cardId, getState, dispatch);
                return result;
            }).catch((e: any) => {
                console.error(e);
                dispatch(cardsUpdate(cardsForRollback));
            }).finally(() => {
                dispatch(cardsActiveRequestsUpdate(cards, -1));
            });
    };
    return action;
};

const updateRelatedCardsForReverseCard = (
    relatedCards: IRestRelatedCard[],
    result: IRestRelatedCard[],
    cardId: TCardId,
    getState: IGetState,
    dispatch: Dispatch,
) => {
    const state = getState();
    const reverseCardIds = new Set<number>()
    relatedCards.forEach( relatedCard => {
        reverseCardIds.add(relatedCard.cardId)
    });
    const cards: ICards = {};
    reverseCardIds.forEach(
        reverseCardId => {
            const relations = getCardRelatedCardsActive(state, reverseCardId);
            let relatedCardsActive: IRestRelatedCard[] = [];
            if (relations){
                const relationsWithOtherCards = relations.filter( relation => relation.cardId !== cardId);
                const relationsWithCurrentCards = result.filter(relation => relation.epicCardId === reverseCardId);
                relatedCardsActive = [ ...relationsWithOtherCards, ...relationsWithCurrentCards ];
            }
            cards[reverseCardId] = {
                relatedCards: relatedCardsActive,
                relatedCardCount: relatedCardsActive.length
            };
            dispatch(sendRealTimeStoreActionByCardId(reverseCardId, cardsUpdate(cards)));
        }
    );
    if (!_.isEmpty(cards)){
        dispatch(cardsUpdate(cards));
    }
};

const updateRelatedCardsForCard = (
    result: IRestRelatedCard[],
    cardId: TCardId,
    getState: IGetState,
    dispatch: Dispatch,
) => {
    const state = getState();
    const relatedCardsActive = result.filter( relatedCard =>  relatedCard.cardId !== cardId)
    const card = {
        relatedCards: relatedCardsActive,
        relatedCardCount: relatedCardsActive.length
    }
    const cards: ICards = {
        [cardId]:card
    };
    dispatch(cardsUpdate(cards));
    dispatch(sendRealTimeStoreActionByCardId(cardId, cardsUpdate(cards)));
};
