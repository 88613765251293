import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { toggleCommentsSort } from '../effects/toggleCommentsSort';
import { resetActiveComment } from '../effects/resetActiveComment';
import { TCardId } from '../../../../../../../../types/types';

export const onChangeCommentsOrder = (
    cardId: TCardId
): ThunkAction => {
    return (dispatch: Dispatch) => {
        dispatch(resetActiveComment(cardId));
        dispatch(toggleCommentsSort());
    };
};
