import { IApplicationState, TBoardId } from '../../../../types/types';
import {
    EAsidePanelProperty
} from '../../../../view/react_components/aside_panel/asidePanel/components/AsidePanel/types';
import { getBoardCardProperties } from './getBoardCardProperties';

export const getBoardIsCardDefaultProperty = (
    state: IApplicationState,
    boardId: TBoardId,
    property: EAsidePanelProperty,
): boolean => {
    const boardCardProperties = getBoardCardProperties(state, boardId);
    const сardProperty = boardCardProperties.find(prop => prop.property === property);

    return сardProperty && сardProperty.isDefault;
};
