import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId, TTag } from '../../../../../../../../types/types';
import { deleteTag } from '../effects/deleteTag';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';

export const onDelete = (
    cardId: TCardId,
    tag: TTag
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.REMOVED_TAG));
        return dispatch(deleteTag(cardId, tag));
    };
    return action;
};
