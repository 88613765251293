import { IGetState, TCardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import {
    SegmentCardEvent,
    SegmentCardOptions,
    SegmentCardOptionSourceValue,
    segmentTrackAction
} from '../../../../../../../../middlewares/segment';
import { SegmentCardSourceValue } from '../../../../../../../../middlewares/segment/trackEntities/cardEvents';
import { cardDeleteOrArchive } from '../../../../../../base/effects/cardDeleteOrArchive';
import { getIsLinkCardActive } from '../../../../../../../../store/router/selectors/getIsLinkCardActive';
import { root } from '../../../../../../../../store/constants';

export const onDelKey = (
    cardId: TCardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (getIsLinkCardActive(getState())) {
            root.App.controller.mainView.hideCardLink();
        }
        let eventOpt = {
            name: SegmentCardOptions.VIA,
            value: SegmentCardOptionSourceValue.HOTKEY
        };
        dispatch(segmentTrackAction(SegmentCardEvent.DELETE_CARD, eventOpt, [{
            name: SegmentCardOptions.SOURCE,
            value: SegmentCardSourceValue.DETAILS
        }]));
        dispatch(cardDeleteOrArchive(cardId));
    };
    return action;
};
