import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { findEditedMention } from '../../../helpers/MentionsHelper';
import { IGetState } from '../../../../../../../../types/types';
import { getMentionSubstring } from '../../../selectors/getMentionSubstring';
import { commentUpdate } from '../../../store/reducers/actions/commentUpdate';

export const changeMentionSubstring = (cursorPosition: number): ThunkAction => {
    return (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const mentionSubstring = getMentionSubstring(state);
        const mention = findEditedMention(cursorPosition);

        if (mention && mention !== mentionSubstring) {
            dispatch(commentUpdate({ mentionSubstring: mention }));
        } else if (mentionSubstring && !mention) {
            dispatch(commentUpdate({ mentionSubstring: '' }));
        }
    };
};
