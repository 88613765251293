import { AsidePanelActionsButton } from '../../../..';
import * as React from 'react';
import { CARD_COLOR_TAGS_BUTTON } from './constants';
import {
    CLASS_ASIDE_PANEL_ACTIONS_BUTTON_CONTAINER,
    CLASS_ASIDE_PANEL_ACTIONS_POPUP
} from '../../../../asidePanel/components/AsidePanelActionsButton/constants';
import { ICardColorTagsButtonProps } from './types';
import { getAsidePanelActionsButtonTooltip } from '../../../../asidePanel/helpers/getAsidePanelActionsButtonTooltip';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { SettingsSection } from '../../../../../base/components/SettingsSection/SettingsSection';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { CLASS_COLOR_PALETTE } from '../../../../../base/components/ColorPalette/constants';
import {
    BOARD_SECTION_COLORS_MODAL_CANCEL,
    BOARD_SECTION_COLORS_MODAL_REMOVE
} from '../../../../boardDetails/details/Colors/components/BoardColors/constants';
import {
    BasicUpgradeButtonHOC
} from '../../../../../base/components/BasicUpgradeButton/hocs/BasicUpgradeButtonHOC/BasicUpgradeButtonHOC';
import { getMessages } from '../../../../../../../store/constants';
import { ActionModal } from '../../../../../base/components/ActionModal/ActionModal';
import { CLASS_ACTION_MODAL } from '../../../../../base/components/ActionModal/constants';
import {
    ColorTagsDropdownHOC
} from '../../../../../base/components/ColorTagsDropdown/hocs/ColorTagsDropdownHOC/ColorTagsDropdownHOC';

export function CardColorTagsButton ({
    aloudCount,
    colorTags,
    isShowTooltip,
    isShow,
    onChange,
    onOpenDropDown,
    onOpenSettings
}: ICardColorTagsButtonProps) {
    const { cardId, boardId } = React.useContext(AsidePanelContext);

    if (!isShow) return null;
    const tooltip = isShowTooltip ? getAsidePanelActionsButtonTooltip({value: CARD_COLOR_TAGS_BUTTON}) : null;
    const { isMobile } = React.useContext(AsidePanelContext);
    let [isOpened, setIsOpened] = React.useState(null);
    const [removingId, setRemovingId] = React.useState(null);
    const [removingSource, setRemovingSource] = React.useState(null);
    const onOpen = () => {
        if (isOpened) return;

        isOpened = true;
        setIsOpened(true);
    }
    const onClose = () => setIsOpened(false);
    const onCloseModal = () => {
        onClose();
        setRemovingId(null);
    };

    const body = <ColorTagsDropdownHOC
        boardId={boardId}
        cardId={cardId}
        onChange={onChange}
        onOpenSettings={onOpenSettings}
        onDropdownOpen={onOpenDropDown}
        onClose={() => setIsOpened(false)}
        setRemovingSource={setRemovingSource}
        setRemovingId={setRemovingId}
    />;

    const dropdown = isMobile
        ? <SettingsSection
            title={CARD_COLOR_TAGS_BUTTON}
            sectionType={ ESettingsSectionType.POPUP }
            onAddClosed={onClose}
        >
            {body}
        </SettingsSection>
        : body;

    return (
        <>
            <AsidePanelActionsButton
                className={CLASS_ASIDE_PANEL_ACTIONS_BUTTON_CONTAINER}
                dropdownClassName={`${CLASS_ASIDE_PANEL_ACTIONS_POPUP}--colortags`}
                isIconHiddenOnFullSize={true}
                icon={'color-tag'}
                title={CARD_COLOR_TAGS_BUTTON}
                tooltip={tooltip}
                indicatorNumber={colorTags.length}
                isFitWindow
                isOpened={isOpened}
                onOpen={onOpen}
                onClose={onClose}
                notBlurClasses={[CLASS_COLOR_PALETTE + '-color-row__picker-dropdown', CLASS_ACTION_MODAL]}
            >
                {CARD_COLOR_TAGS_BUTTON}
                {dropdown}
            </AsidePanelActionsButton>
            <ActionModal
                isShow={!!removingId}
                actions={[
                    {
                        variant: 'primary',
                        onClick: onCloseModal,
                        children: BOARD_SECTION_COLORS_MODAL_CANCEL
                    },
                    {
                        variant: 'secondary',
                        onClick: () => {
                            onCloseModal();
                            return onChange(removingId, removingSource);
                        },
                        children: BOARD_SECTION_COLORS_MODAL_REMOVE
                    },
                ]}
                footer={<BasicUpgradeButtonHOC onClick={onCloseModal} />}
                text={getMessages().getText('board_settings.colors.modal.text', null, aloudCount)}
                title={getMessages().getText('board_settings.colors.modal.title', null, colorTags.find(color => color.id === removingId)?.name)}
                onClose={onCloseModal}
            />
        </>
    );
}
