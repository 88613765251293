import * as React from 'react';
import './_comments-input.scss';
import { Button, ButtonDropdown, Icon, Input } from 'kui';
import { ICommentInputProps } from './types';
import {
    CARD_SECTION_COMMENTS_ADD_ATTACHMENT,
    CLASS_COMMENT,
    CLASS_COMMENT_MENTION_DROPDOWN,
    CLASS_COMMENTS_SECTION,
    CLASS_COMMENTS_SECTION_FORM
} from '../../constants';
import { debounce, isEqual } from 'underscore';
import { getParentsClasses } from '../../../../../helpers/getParentsClasses';
import { CommentsMentionsHOC } from '../../hocs/CommentsMentionsHOC/CommentsMentionsHOC';
import {
    CLASS_COMMENTS_SECTION_INPUT,
    COMMENT_EDIT_LABEL,
    COMMENT_LABEL,
    COMMENT_NOT_TITLE_LABEL,
    COMMENT_PLACEHOLDER,
    COMMENT_REPLY_ANIMATION_DURATION,
    DEFAULT_INPUT_HEIGHT,
    COMMENTS_INPUT_GOOGLE_THUMB_SIZE
} from './constants';
import { CLASS_ASIDE_PANEL_BODY } from '../../../../asidePanel/components/AsidePanelBody/constants';
import { GOOGLE_SPACING, THUMB_COMMENT_REPLY_SIZE } from 'app/const';
import { HtmlEditorHOC } from '../../../DescriptionSection/hocs/HtmlEditorHOC/HtmlEditorHOC';
import { blockMarkdown } from 'app/helper/markdownHelper';
import { EEditorType } from 'app/view/react_components/base/components/HtmlEditor/types';
import { EHTMLEditorType } from 'app/types/types';
import { TDriveDocId } from 'app/types/rest/IRestDriveDoc';
import {
    getBigPreviewIcon
} from '../../../AttachmentsSection/hocs/CardAttachmentsSectionHOC/helpers/getBigPreviewIcon';
import { CommentAttachments } from '../CommentAttachments/CommentAttachments';
import { msFileThumbnailStorage } from 'app/helper/authorisation/microsoft/storage/msThumbnail';
import { TCommentId } from 'app/store/model/card/types/IComment';
import { root } from 'app/store/constants';
import { COMMENT_ANIMATION_DURATION } from '../Comment/constants';
import { EScrollIntoViewPosition, scrollCommentIntoView } from '../../helpers/scrollCommentIntoView';
import { googleFileThumbnailStorage } from 'app/helper/authorisation/google/storage/googleThumbnail';
import { EAuthUserPlatformType } from 'app/types/rest/IRestAuthUser';
import { CardAttachmentAdd } from '../../../AttachmentsSection/components/CardAttacmentAdd/CardAttacmentAdd';
import { IDriveDoc } from 'app/store/model/card/types/IDriveDoc';
import {
    CLASS_ASIDE_PANEL,
    CLASS_ASIDE_PANEL_STATIC,
    CLASS_ASIDE_PANEL_STATIC_NO_HEADER
} from '../../../../asidePanel/constants';
import { AnalyzeRender } from '../../../../../helpers/memoizeHelper';

// @ts-ignore
const analyzeRender = window.Settings.development ? new AnalyzeRender(`CommentsInput`) : null;

//to prevent function clear when rerender
const debouncedCommentHelper = debounce((
    callback: any
) => {
    callback();
}, 200);

export function CommentsInput({
    autoFocus,
    boardId,
    cardId,
    HTMLEditorType,
    isMentionsOpened,
    driveDocs,
    isShow,
    isShowReplyAttachment,
    isShowSelectFile,
    platformType,
    editComment: _editComment,
    replyAttachment,
    replyComment,
    replyText,
    value,
    onAttachmentsChange,
    onCancel,
    onDidMount,
    onBlur,
    onChangeCommentValue,
    onCreate,
    onKeyDown,
    onChangeMention,
    onClose,
    onAddAttachments,
    onReplyClose,
    onSaveChanges,
    onRemoveAttachment,
    onSelectClick,
    onUploadClick,
}: ICommentInputProps) {
    if (analyzeRender) analyzeRender.call(`CommentsInput ${cardId}`);

    if (!isShow) return null;

    let [inputValue, _setInputValue] = React.useState(value);
    let [valueEditor, _setValueEditor] = React.useState(value);
    const [attachments, setAttachments] = React.useState(driveDocs);
    const [isChanged, setChanged] = React.useState(null);
    const [isSendDisabled, setSendDisabled] = React.useState(null);
    const [removeDriveDocIds, setRemoveDriveDocIds] = React.useState([]);
    const [isShowReply, setShowReply] = React.useState(null);
    const [isShowAttachments, setShowAttachments] = React.useState(null);
    const [editComment, setEditComment] = React.useState(null);
    const [isOpen, setOpen] = React.useState(null);
    const [isFloating, setFloating] = React.useState(null);

    const className = CLASS_COMMENTS_SECTION_FORM;
    const buttonClass = CLASS_COMMENTS_SECTION + '__send';
    const classAttachment = CLASS_COMMENTS_SECTION + '__add-attachment';
    const replyClass = CLASS_COMMENTS_SECTION + '__reply';

    const inputRef = React.useRef(null);

    const editorOnEnter= isMentionsOpened
        ? {onEnter: () => {}} // если в HtmlEditor передать onEnter, то у quill отключаётся все привязки на Enter
        : {onCtrlEnter: handleHtmlCtrlEnter};

    function handleHtmlCtrlEnter() {
        if (isSendDisabled) return;

        onChangeCommentValue(valueEditor);
        onCreate({ctrlKey: true} as any, attachments).then(id => {
            onScrollToComment(id);
        });
        closeHandle();
    }

    function handleHtmlChange(value: string) {
        if (valueEditor === value) return;

        setValueEditor(value);
        if (isMentionsOpened) {
            onChangeCommentValue(valueEditor);
        }
        setTimeout(() => {
            const w = window as any;
            if (w.mentionPasted) return;

            onChangeMention(null, null);
        }, 0);
    }

    const setInputValue = (value: string) => {
        inputValue = value;
        _setInputValue(value);
    };

    const setValueEditor = (value: string) => {
        valueEditor = value;
        _setValueEditor(value);
    };

    function changeHandler(e: any) {
        const {target, key} = e;
        const {value, selectionStart} = target;

        setInputValue(value);
        setValueEditor(value);

        if (!editComment) {
            const debouncedChange = () => {
                onChangeCommentValue(value || ''); // undefined on clear
                onChangeMention(key, selectionStart || 0); // undefined on clear
            };
            debouncedCommentHelper(debouncedChange);
        }
    }

    function enterHandle(event: React.KeyboardEvent<HTMLElement>) {
        if (event.ctrlKey || event.metaKey) {
            createHandle(event);
        }
    }

    function createHandle(event: React.KeyboardEvent<HTMLElement>) {
        const text = isMarkdown ? inputValue : valueEditor;
        if (isSendDisabled || editComment && !isChanged) {
            closeHandle();
            return;
        }
        reset();
        if (editComment) {
            const promises = removeDriveDocIds.map(id => {
                return onRemoveAttachment(id);
            });
            Promise.all(promises).then(() => {
                onSaveChanges(text, attachments).then(id => {
                    onScrollToComment(id);
                }).finally(() => {
                    closeHandle();
                });
            });
        } else {
            onCreate(event, attachments).then(id => {
                onScrollToComment(id);
            }).finally(() => {
                closeHandle();
            });
        }3
    }

    function closeHandle() {
        if (editComment) {
            onCancel();
        } else {
            if (inputRef && inputRef.current) {
                if( inputRef.current.getInput() && inputRef.current.getInput().closest(`.${CLASS_COMMENTS_SECTION}`))
                    inputRef.current.getInput().closest(`.${CLASS_COMMENTS_SECTION}`).focus();
            }
            onClose();
        }
        reset();
    }

    function reset() {
        setAttachments([]);
        setRemoveDriveDocIds([]);
        setInputValue('');
        setValueEditor('');
        setShowAttachments(false);
        setShowReply(false);
        setExtraHeight(0);
        setEditComment(null);
    }

    function keyDownHandle(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Escape' && !isMentionsOpened) {
            event.stopPropagation(); // to avoid the aside-panel closure on esc if input is in focus
            closeHandle();
        } else {
            onKeyDown(event);
        }
    }
    function onBlurHandle (event: React.FocusEvent) {
        const targetClasses = getParentsClasses(
            event.relatedTarget as HTMLElement,
            [ CLASS_COMMENT_MENTION_DROPDOWN, classAttachment ]
        );
        if (
            targetClasses.includes(CLASS_COMMENT_MENTION_DROPDOWN) ||
            targetClasses.includes(classAttachment)
        ) {
            event.stopPropagation();
            return;
        }

        onBlur();
    }

    const onDeleteAttachment = (id: TDriveDocId, fileId: string) => {
        const idx = driveDocs.findIndex(item => item.id === id);
        if (idx > -1) {
            setRemoveDriveDocIds([...removeDriveDocIds, id]);
        }
        const newAttachments = attachments.filter(item => item.fileId !== fileId);
        if (attachments.length === 1) {
            setShowAttachments(false);
            setTimeout(() => {
                setAttachments(newAttachments);
                onAttachmentsChange(newAttachments);
            }, COMMENT_REPLY_ANIMATION_DURATION);
        } else {
            setAttachments(newAttachments);
            onAttachmentsChange(newAttachments);
        }
        const input = inputRef.current && inputRef.current.getInput();
        if (input) input.focus();
    };

    const onScrollToComment = (id: TCommentId) => {
        const comment = document.querySelector('.' + CLASS_COMMENT + '--' + id) as HTMLElement;
        if (comment) {
            scrollCommentIntoView(comment, EScrollIntoViewPosition.CENTER);
            comment.classList.add(CLASS_COMMENT + '--active');
            setTimeout(() => {
                comment.classList.remove(CLASS_COMMENT + '--active');
            }, COMMENT_ANIMATION_DURATION);
        }
    };

    const onCommentClick = () => {
        const id = editComment ? editComment.id : replyComment.id;
        onScrollToComment(id);
    };

    const afterAttachmentAdd = (newAttachments: IDriveDoc[]) => {
        setAttachments([...attachments, ...newAttachments]);
        setTimeout(() => { // подождать когда отрисуются аттачменты
            const input = inputRef.current && inputRef.current.getInput();
            if (input.getBoundingClientRect().bottom + GOOGLE_SPACING >= root._innerHeight) { // + scroll
                const asidePanel = document.querySelector('.' + CLASS_ASIDE_PANEL_BODY) as HTMLElement;
                asidePanel.scrollTop = asidePanel.scrollHeight;
            }
        }, 0);
    };

    const onAttachmentAdd = () => {
        onAddAttachments(attachments, (newAttachments) => {
            setAttachments([...attachments, ...newAttachments]);
            onAttachmentsChange([...attachments, ...newAttachments]);
            setTimeout(() => { // подождать когда отрисуются аттачменты
                if (input.getBoundingClientRect().bottom + GOOGLE_SPACING >= root._innerHeight) { // + scroll
                    const asidePanel = document.querySelector('.' + CLASS_ASIDE_PANEL_BODY) as HTMLElement;
                    asidePanel.scrollTop = asidePanel.scrollHeight;
                }
            }, 0);
        });
        const input = inputRef.current && inputRef.current.getInput();
        if (!input) return;
        input.focus();
    };

    React.useEffect(() => {
        setEditComment(_editComment);
    }, [_editComment]);

    React.useEffect(() => {
        onDidMount();
        return onClose;
    }, []);

    const isMarkdown = !0 || HTMLEditorType === EHTMLEditorType.MARKDOWN;

    React.useEffect(() => {
        if (editComment || replyComment) {
            setShowReply(true);
            const input = inputRef.current && inputRef.current.getInput();
            if (input) {
                input.focus();
                if (editComment) {
                    setTimeout(() => {// подождать новый текст в инпуте
                        input.select();
                    }, 0);
                }
            }
        }
    }, [editComment, replyComment]);

    React.useEffect(() => {
        if (!editComment) {
            onChangeCommentValue(value);
        }
        setInputValue(value);
        setValueEditor(value);
    }, [value, editComment]);

    React.useEffect(() => {
        setAttachments(driveDocs);
    }, [driveDocs]);

    React.useEffect(() => {
        const input = inputRef.current && inputRef.current.getInput();
        if (value && value.length && input === document.activeElement) {
            if (input.getBoundingClientRect().bottom + GOOGLE_SPACING >= root._innerHeight) { // + scroll
                const scrollEl = document.querySelector('.' + CLASS_ASIDE_PANEL_BODY) as HTMLElement;
                scrollEl.scrollTo({ top: scrollEl.scrollTop + scrollEl.scrollHeight, behavior: 'smooth' });
            }
        }
    }, [value]);

    React.useEffect(() => {
        setSendDisabled(
            (!attachments || !attachments.length) &&
            (!inputValue || !inputValue.trim()) &&
            (!valueEditor || !valueEditor.trim())
        );
        setShowAttachments(attachments && attachments.length);
    }, [attachments, value, inputValue, valueEditor]);

    React.useEffect(() => {
        if (!editComment) return;
        const isValueChanged = isMarkdown ? inputValue !== editComment.text : valueEditor !== editComment.text;
        const isAttachmentChanged = !isEqual(attachments, driveDocs);
        setChanged(isValueChanged || isAttachmentChanged);
    }, [editComment, inputValue, attachments, replyComment, valueEditor]);

    const [replyAttachmentThumbUrl, setReplyAttachmentThumbUrl] = React.useState('');
    React.useEffect(() => {
        if (!replyAttachment || !isShowReplyAttachment) return;

        if (msFileThumbnailStorage.is()) {
            msFileThumbnailStorage.getThumbnail(replyAttachment.driveId, replyAttachment.fileId, `c${THUMB_COMMENT_REPLY_SIZE}x${THUMB_COMMENT_REPLY_SIZE}`)
                .then(thumb => setReplyAttachmentThumbUrl(thumb.url));
        } else if (googleFileThumbnailStorage.is()) {
            googleFileThumbnailStorage.getThumbnail(replyAttachment.fileId, COMMENTS_INPUT_GOOGLE_THUMB_SIZE)
                .then(thumb => setReplyAttachmentThumbUrl(thumb));
        }
    }, [replyAttachment]);

    const [extraHeight, setExtraHeight] = React.useState(0);
    const ref = React.useRef(null);
    const timerRef = React.useRef(null);
    React.useEffect(() => {
        const isNoAsideHeader = !!document.querySelector('.' + CLASS_ASIDE_PANEL + '--without-header');
        const asidePanel = document.querySelector('.' + CLASS_ASIDE_PANEL) as HTMLElement;
        if (!asidePanel) return;
        asidePanel.classList.add(isNoAsideHeader ? CLASS_ASIDE_PANEL_STATIC_NO_HEADER : CLASS_ASIDE_PANEL_STATIC);
        if (timerRef.current) clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => { // подождать окончания анимации
            const el = ref.current as HTMLElement;
            if (!el) return;
            const scrollEL = document.querySelector('.' + CLASS_ASIDE_PANEL_BODY) as HTMLElement;
            if (!scrollEL) return;
            if (replyComment || editComment || (attachments && attachments.length) || inputValue) {
                const scrollEL = document.querySelector('.' + CLASS_ASIDE_PANEL_BODY) as HTMLElement;
                if (!scrollEL) return;
                const height = -extraHeight + el.offsetHeight - DEFAULT_INPUT_HEIGHT;
                if (height) setExtraHeight(prev => prev + height);
                if (scrollEL.scrollTop < scrollEL.scrollHeight - scrollEL.offsetHeight - GOOGLE_SPACING * 1.5) {
                    scrollEL.scrollTo({ top: scrollEL.scrollTop + height, behavior: 'smooth' });
                }
            } else {
                if (scrollEL.scrollTop < scrollEL.scrollHeight - scrollEL.offsetHeight - GOOGLE_SPACING * 1.5) {
                    scrollEL.scrollTo({ top: scrollEL.scrollTop - extraHeight, behavior: 'smooth' });
                }
                setExtraHeight(null);
            }
            setTimeout(() => {
                const asidePanel = document.querySelector('.' + CLASS_ASIDE_PANEL) as HTMLElement;
                const asidePanelBody = document.querySelector('.' + CLASS_ASIDE_PANEL + '--card') as HTMLElement;
                if (!asidePanel || !asidePanelBody) return;
                if (isNoAsideHeader) {
                    asidePanelBody.classList.add(CLASS_ASIDE_PANEL + '--without-header');
                } else {
                    asidePanelBody.classList.remove(CLASS_ASIDE_PANEL + '--without-header');
                }
                asidePanel.classList.remove(isNoAsideHeader ? CLASS_ASIDE_PANEL_STATIC_NO_HEADER : CLASS_ASIDE_PANEL_STATIC);
            }, COMMENT_REPLY_ANIMATION_DURATION);
        }, COMMENT_REPLY_ANIMATION_DURATION + 50);
    }, [replyComment, editComment, attachments, inputValue]);

    React.useEffect(() => {
        setInputValue(value);
    }, [value]);

    React.useEffect(() => {
        if (!inputValue) onChangeCommentValue('');
    }, [inputValue]);

    const floatingTimerRef = React.useRef(null);
    React.useEffect(() => {
        const bodyEl = document.querySelector('.' + CLASS_ASIDE_PANEL_BODY) as HTMLElement;
        if (!bodyEl) return;
        const observer = new MutationObserver(() => {
            if (floatingTimerRef.current) clearTimeout(floatingTimerRef.current);
            floatingTimerRef.current = setTimeout(() => {
                const input = ref.current as HTMLElement;
                if (!input) return;
                const ASIDE_BOTTOM = GOOGLE_SPACING * 2 // padding + scroll
                setFloating(input.getBoundingClientRect().bottom + ASIDE_BOTTOM >= window.innerHeight);
            }, 200);
        });
        observer.observe(bodyEl, {attributes: false, childList: true, characterData: false, subtree: true});
        return () => observer.disconnect();
    }, []);

    const isShowDialog = platformType && platformType === EAuthUserPlatformType.MICROSOFT;

    const attachmentButton = <Button
        className={classAttachment}
        tooltip={{
            isNoEvents: true,
            value: CARD_SECTION_COMMENTS_ADD_ATTACHMENT
        }}
        variant={'icon'}
        onClick={onAttachmentAdd}
    >
        <Icon
            size={24}
            xlink={'attachment'}
        />
    </Button>;

    return (
        <div
            className={`
                ${className}
                ${!isMarkdown ? className + '--editor' : ''}
                ${isShowReply ? className + '--with-reply' : ''}
                ${isShowAttachments ? className + '--with-attachments' : ''}
                ${isFloating ? className + '--floating' : ''}
            `}
            ref={ref}
        >
            <div className={replyClass + '-wrapper'}>
                {(replyComment || editComment) &&
                    <div
                        className={`
                            ${replyClass}
                            ${editComment ? replyClass + '--edit' : ''}
                            ${replyAttachment ? replyClass + '--attachment' : ''}
                        `}
                        onClick={onCommentClick}
                    >
                        <div className={replyClass + '-content'}>
                            <Icon className={replyClass + '-icon'} xlink={editComment ? 'pen' : 'reply'} size={24} />
                            {replyAttachment &&
                                <div className={replyClass + '-attachment'}>
                                    <img
                                        className={replyClass + '-img'}
                                        onError={()=> {
                                            if (googleFileThumbnailStorage.is()) {
                                                googleFileThumbnailStorage.reGetThumbnail(
                                                    replyAttachment.fileId,
                                                    COMMENTS_INPUT_GOOGLE_THUMB_SIZE,
                                                    setReplyAttachmentThumbUrl,
                                                );
                                            }
                                        }}
                                        src={replyAttachment.isImage ? replyAttachmentThumbUrl : getBigPreviewIcon(replyAttachment.iconUrl)}
                                    />
                                </div>
                            }
                            <div className={replyClass + '-body'}>
                                {editComment && <div className={replyClass + '-edit'}>{COMMENT_EDIT_LABEL}</div>}
                                {replyComment && <div className={replyClass + '-author'}>{replyComment.author.fullName}</div>}
                                <div className={replyClass + '-text'}>
                                    {replyAttachment && !isShowReplyAttachment ? replyAttachment.title :
                                        editComment ?
                                            editComment.text || COMMENT_NOT_TITLE_LABEL
                                            : replyComment && replyComment.text ?
                                                replyText ?
                                                    replyText
                                                    : replyComment.text
                                                : COMMENT_NOT_TITLE_LABEL
                                    }
                                </div>
                            </div>
                        </div>
                        <Button
                            variant={'icon'}
                            onClick={e => {
                                e.stopPropagation();
                                setShowReply(false);
                                setTimeout(() => { // ждем окончания анимации
                                    if (editComment) {
                                        closeHandle();
                                    } else {
                                        onReplyClose();
                                    }
                                    const input = inputRef.current && inputRef.current.getInput();
                                    if (input) input.focus();
                                }, COMMENT_REPLY_ANIMATION_DURATION);
                            }}
                        >
                            <Icon xlink={'clear'} size={24} />
                        </Button>
                    </div>
                }
            </div>
            <div className={className + '-attachments-wrapper'}>
                {attachments && !!attachments.length &&
                    <CommentAttachments attachments={attachments} onDelete={onDeleteAttachment} />
                }
            </div>
            <div className={className + '-content'}>
                {isMarkdown ?
                    <Input
                        ref={inputRef}
                        autoFocus={autoFocus}
                        className={CLASS_COMMENTS_SECTION_INPUT}
                        label={COMMENT_LABEL}
                        placeholder={COMMENT_PLACEHOLDER}
                        value={inputValue}
                        onEnter={enterHandle}
                        onKeyDown={keyDownHandle}
                        onChange={changeHandler}
                        onBlur={onBlurHandle}
                    />
                    : <HtmlEditorHOC
                        cardId={cardId}
                        placeholder={COMMENT_PLACEHOLDER}
                        selectionTree={null}
                        type={EEditorType.NEW_COMMENT}
                        value={blockMarkdown(valueEditor)}
                        onChange={handleHtmlChange}
                        onKeyDown={keyDownHandle}
                        // todo autoFocus={autoFocus}
                        {...editorOnEnter}
                    />
                }
                <Button
                    className={`${buttonClass} ${editComment ? buttonClass + '--save' : ''}`}
                    variant={'icon'}
                    onClick={createHandle}
                    disabled={editComment && !isChanged || isSendDisabled}
                >
                    {editComment
                        ? <Icon className={buttonClass + '-done'} xlink={'done-circle'} size={16} />
                        : <Icon xlink={'send'} size={24} />
                    }
                </Button>
                <CommentsMentionsHOC
                    boardId={boardId}
                    cardId={cardId}/>
                {isShowDialog ?
                    <ButtonDropdown
                        className={classAttachment + '-wrapper'}
                        dropdownClassName={classAttachment + '-wrapper-dropdown'}
                        opened={isOpen}
                        onOpen={() => setOpen(true)}
                        onClose={() => setOpen(false)}
                    >
                        {attachmentButton}
                        <CardAttachmentAdd
                            onSelectClick={isShowSelectFile ? () => onSelectClick(() => setOpen(false), afterAttachmentAdd) : null}
                            onUploadClick={(onStart, onProgress, onFinish) => onUploadClick(onStart, onProgress, () => {
                                onFinish();
                                setOpen(false);
                            }, afterAttachmentAdd)}
                        />
                    </ButtonDropdown>
                    : attachmentButton
                }
            </div>
        </div>
    );
}
