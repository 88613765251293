import { REST_RECURRING_TASKS } from '../../../../constants';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { fetchHandler } from '../../../../../util/fetchHandler';
import Util from '../../../../../util/util';
import { getRestHeadersPatch } from '../../../../helpers/getRestHeadersHelper';
import { IGetState, TRecurringTaskId } from '../../../../../types/types';
import { IRestRecurringTask } from '../../../../../types/rest/recurringTask/IRestRecurringTask';

export const patchRest = (
    id: TRecurringTaskId,
    body: IRestRecurringTask
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return fetchHandler<IRestRecurringTask>(
            Util.getApiUrl(`${REST_RECURRING_TASKS}/${id}`), {
                ...getRestHeadersPatch(),
                body: JSON.stringify(body)
            }
        );
    };
    return action;
};
