import { IApplicationState } from '../../../../../../../../types/types';
import { connect } from 'react-redux';
import { StatusListsPanelButton } from '../../components/StatusListsPanelButton/StatusListsPanelButton';
import { IStatusListsPanelButtonHOCProps } from './types';
import { getAsidePanelIsMobile } from '../../../../../asidePanel/store/selectors/getAsidePanelIsMobile';
import { onClick } from './events/onClick';
import { STATUS_LISTS_TITLE } from '../../constants';
import {
    IStatusListsPanelButtonEvents,
    IStatusListsPanelButtonFields
} from '../../components/StatusListsPanelButton/types';
import { getBoard } from '../../../../../../../../store/model/selectors/getBoardById';
import { getIsUserBasicPlanOnly } from '../../../../../../../../store/model/authUser/selectors/getIsUserBasicPlanOnly';

const mapStateToProps = (
    state: IApplicationState,
    { boardId, isShowTooltip }: IStatusListsPanelButtonHOCProps
): IStatusListsPanelButtonFields => {
    const isMobile = getAsidePanelIsMobile(state);

    const { statusLists } = getBoard(state, boardId);
    const onDoneChecked = statusLists && !!statusLists.doneId;
    const onHoldInWorkChecked = statusLists && !!statusLists.onHoldId && !!statusLists.inWorkId;

    const tooltip = isMobile
        ? null
        : isShowTooltip
            ? STATUS_LISTS_TITLE
            : null;
    return {
        tooltip,
        indicatorBoolean: onDoneChecked || onHoldInWorkChecked,
        isBasicFeature: getIsUserBasicPlanOnly(state)
    }
};

const mapDispatchToProps = (
    dispatch: any,
    {onClick: ownOnClick}: IStatusListsPanelButtonHOCProps
): IStatusListsPanelButtonEvents => {
    return {
        onClick: () => dispatch(onClick(ownOnClick))
    }
};

export const StatusListsPanelButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(StatusListsPanelButton);
