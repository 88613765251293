import { IGetState, TBoardId } from '../../../types/types';
import {
    EPushNotificationSettingsScope,
    EPushNotificationSubscribeCases,
    EPushNotificationSettingsTypes
} from '../../../types/rest/IRestPushNotificationSettings';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { pushNotificationSettingsPatchRest } from './api/helpers/pushNotificationSettingsPatchRest';

export const pushNotificationSet = (
    boardId: TBoardId,
    scope: EPushNotificationSettingsScope[],
    subscribeCases: EPushNotificationSubscribeCases[],
    types: EPushNotificationSettingsTypes[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(pushNotificationSettingsPatchRest(boardId, {scope, subscribeCases, types}));
    };
    return action;
}
