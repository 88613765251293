import { IApplicationState } from 'app/types/types';
import { connect } from 'react-redux';
import {
    NavigationPanelQuickStartGuide
} from '../../components/NavigationPanelQuickStartGuide/NavigationPanelQuickStartGuide';
import {
    INavigationPanelQuickStartGuideEvents,
    INavigationPanelQuickStartGuideFields,
    IQuickStartGuideStepData
} from '../../components/NavigationPanelQuickStartGuide/types';
import {
    getAuthUserQuickStartGuides
} from 'app/store/model/authUser/selectors/getAuthUserQuickStartGuides';
import {
    getQuickStartGuides,
    NAV_PANEL_QUICK_START_GUIDE_EDU_SUPPORT_PHOTO_URL,
    NAV_PANEL_QUICK_START_GUIDE_ENGLISH_SUPPORT_COUNTRIES,
    NAV_PANEL_QUICK_START_GUIDE_ENGLISH_SUPPORT_PHOTO_URL,
    NAV_PANEL_QUICK_START_GUIDE_SPANISH_SUPPORT_COUNTRIES,
    NAV_PANEL_QUICK_START_GUIDE_SPANISH_SUPPORT_PHOTO_URL,
    NAV_PANEL_QUICK_START_GUIDE_SUPPORT_PHOTO_URL
} from '../../components/NavigationPanelQuickStartGuide/constants';
import { ENavigationPanelTypes } from '../../constants';
import { getCurrentNavigationPanelType } from '../../helpers/getCurrentNavigationPanelType';
import { onBook } from './events/onBook';
import { onLater } from './events/onLater';
import { onModalClose } from './events/onModalClose';
import { onDone } from './events/onDone';
import { onFinish } from './events/onFinish';
import { getAuthUser } from 'app/store/model/authUser/selectors/getAuthUser';
import { EAuthUserAccountType } from 'app/types/rest/IRestAuthUser';
import { getNavigationPanelQuickStartGuide } from '../../store/selectors/getNavigationPanelQuickStartGuide';
import { onClose } from './events/onClose';
import { QUICK_START_GUIDES_RELEASE } from 'app/types/rest/IUserMeta';
import { isUserAuthorized } from 'app/store/model/authUser/selectors/isUserAuthorized';
import { onOpen } from './events/onOpen';
import { NAVIGATION_PANEL_QUICK_START_GUIDE_LOCALSTORAGE } from './constants';
import { onStepClick } from './events/onStepClick';
import { onClick } from './events/onClick';

const mapStateToProps = (
    state: IApplicationState
): INavigationPanelQuickStartGuideFields => {
    const user = getAuthUser(state);
    const isShow = !isUserAuthorized(state) || user && user.createDateTimeStamp && user.createDateTimeStamp > QUICK_START_GUIDES_RELEASE;
    if (!isShow) return { isShow };
    const guides = getQuickStartGuides(state);
    const userGuides = getAuthUserQuickStartGuides(state);
    let guideKey, name, isCompleted = true, steps: IQuickStartGuideStepData[];
    const openGuideKey = getNavigationPanelQuickStartGuide(state);
    if (openGuideKey) {
        const guide = guides[openGuideKey];
        steps = guide.steps.map(step => {
            const userGuide = userGuides && userGuides[openGuideKey];
            const userStep = userGuide && userGuide.steps && userGuide.steps.find(item => item.key === step.key);
            const isCompleted = step.completed || userStep && userStep.completed;
            return {
                ...step,
                completed: !user.firstVisit || isCompleted,
            };
        });
        guideKey = openGuideKey;
        name = guide.name;
    } else {
        for (let key in guides) {
            const guide = guides[key];
            const userGuide = userGuides && userGuides[key];
            steps = guide.steps;
            for (let i = 0; i < steps.length; i++) {
                const stepKey = steps[i].key;
                const userStep = userGuide && userGuide.steps && userGuide.steps.find(step => step.key === stepKey);
                const isCompleted = steps[i].completed || userStep && userStep.completed;
                steps = steps.map(step => {
                    if (step.key === stepKey) {
                        return {
                            ...step,
                            completed: isCompleted
                        }
                    } else {
                        return step;
                    }
                });
            }
            if (!userGuide || !userGuide.isFinished) {
                isCompleted = false;
                guideKey = key as any;
                name = guide.name;
                break;
            }
        }
    }
    const navigationPanelType = getCurrentNavigationPanelType(state);
    const isReturnFocus = navigationPanelType !== ENavigationPanelTypes.NP_COMPACT_BIG_TYPE;
    const authUser = getAuthUser(state);
    let supportPhotoUrl = NAV_PANEL_QUICK_START_GUIDE_SUPPORT_PHOTO_URL;
    if (authUser.accountType === EAuthUserAccountType.EDU_ACCOUNT_TYPE) {
        supportPhotoUrl = NAV_PANEL_QUICK_START_GUIDE_EDU_SUPPORT_PHOTO_URL;
    } else if (NAV_PANEL_QUICK_START_GUIDE_SPANISH_SUPPORT_COUNTRIES.includes(authUser.country)) {
        supportPhotoUrl = NAV_PANEL_QUICK_START_GUIDE_SPANISH_SUPPORT_PHOTO_URL;
    } else if (NAV_PANEL_QUICK_START_GUIDE_ENGLISH_SUPPORT_COUNTRIES.includes(authUser.country)) {
        supportPhotoUrl = NAV_PANEL_QUICK_START_GUIDE_ENGLISH_SUPPORT_PHOTO_URL;
    }
    return {
        guideKey,
        isCompleted,
        isLast: Object.values(guides).length === Object.values((userGuides || {})).length,
        isNew: !localStorage.getItem(NAVIGATION_PANEL_QUICK_START_GUIDE_LOCALSTORAGE),
        isOpened: !!openGuideKey,
        isReturnFocus,
        isShow,
        name,
        steps,
        supportPhotoUrl
    }
};

const mapDispatchToProps = (
    dispatch: any
): INavigationPanelQuickStartGuideEvents => ({
    onBook: () => dispatch(onBook()),
    onClick: (guide) => dispatch(onClick(guide)),
    onClose: () => dispatch(onClose()),
    onDone: (guide, key, name) => dispatch(onDone(guide, key, name)),
    onFinish: (guide) => dispatch(onFinish(guide)),
    onLater: (name) => dispatch(onLater(name)),
    onModalClose: (name) => dispatch(onModalClose(name)),
    onOpen: () => dispatch(onOpen()),
    onStepClick: (step) => dispatch(onStepClick(step)),
});

export const NavigationPanelQuickStartGuideHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(NavigationPanelQuickStartGuide);

NavigationPanelQuickStartGuideHOC.displayName = 'NavigationPanelQuickStartGuideHOC';
