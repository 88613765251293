import { IGetState, TCardId } from '../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { IRestRelatedCard } from '../../../../../types/rest/IRestRelatedCard';
import { fetchHandler } from '../../../../../util/fetchHandler';
import Util from '../../../../../util/util';
import { REST_CARD, REST_CARD_RELATED_CARDS } from '../../../../constants';
import { getRestHeadersPatch } from '../../../../helpers/getRestHeadersHelper';

export const patchRest = (
    cardId: TCardId,
    relatedCards: IRestRelatedCard[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return fetchHandler<IRestRelatedCard[]>(
            Util.getApiUrl(REST_CARD + cardId + REST_CARD_RELATED_CARDS), {
                ...getRestHeadersPatch(),
                body: JSON.stringify(relatedCards)
            }
        )
    };
    return action;
};
