import * as React from 'react';

/**
 * боковую панель лучше печатать вертикально
 * добавляем нужные стили инлайном вместе с панелью
 */

export function AsidePanelPrintStyle () {
    return (
        <style>
            {`
                @media print {
                    @page {
                        size: A4 portrait;
                    }
                    .main-panel {
                        display: none !important;
                    }
                }
            `}
        </style>
    );
};
