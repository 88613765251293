import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../../../types/types';
import { IAsidePanelTab } from '../../../types';
import { ERoutes } from '../../../../../../../store/router/constants';
import {
    cardAssigneeRestPatch
} from '../../../../../../../rest/effects/cardAssignees/api/helpers/cardAssigneeRestPatch';

export const onChangeTab = (
    activeTabIndex: number,
    prevTabIndex: number,
    cardId: TCardId,
    onTabChange: (activeTabIndex: number) => void,
    tabs: ReadonlyArray<IAsidePanelTab>
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const tab = tabs[activeTabIndex];
        const prevTab = tabs[prevTabIndex];
        const checkPermission = tab && tab.checkPermission;
        if (checkPermission && !checkPermission(getState())) {
            const onPermissionDenied = tab && tab.onPermissionDenied;
            if (onPermissionDenied) dispatch(onPermissionDenied());
            return;
        }

        if (prevTab.route === ERoutes.CARD_ACTIVITY && tab.route !== ERoutes.CARD_ACTIVITY) { // сбрасываем счетчик, если пользователь посмотрел активити и ушел на другую табу
            dispatch(cardAssigneeRestPatch(cardId, { updatesCount: 0 }));
        }

        onTabChange(activeTabIndex);
        if (tab) {
            const onActive = tab.onActive;
            if (onActive) dispatch(onActive());
        }
    };
    return action;
};
