import { TStatus } from '../../types/model';
import Util from '../../util/util';
import { ILoadResult, PartLoader } from '../../util/part_loader';
import { EBoardVersion } from '../../types/rest/IRestDashboard';
import { IRestCard } from '../../types/rest/IRestCard';

export class ListLoader {
    listId: number;
    listStatus: number;
    loadEpic: boolean;
    version: EBoardVersion;
    migrate: boolean;

    constructor(listId: number, version: EBoardVersion,  listStatus: number = TStatus.STATUS_ACTIVE, loadEpic: boolean = false, migrate: boolean = false) {
        this.listId = listId;
        this.listStatus =  listStatus;
        this.loadEpic = loadEpic
        this.version = version;
        this.migrate = migrate;
    }

    loadProcessorHandler(loadResult: ILoadResult, processResult: Array<IRestCard>) {
        let cardMap = new Map<number, IRestCard>();
        loadResult.objects.forEach((card: IRestCard) => {
            if (card.lastChangeTimeStamp){
                card.lastActivityDateStamp = card.lastChangeTimeStamp;
            }
            cardMap.set(card.id, card);
        });
        if (loadResult.mainDriveDocSet) {
            loadResult.mainDriveDocSet.forEach(
                (driveDoc: any) => cardMap.get(driveDoc.cardId).mainDriveDocSet.push(driveDoc));

        }
        if (loadResult.predecessors) {
            loadResult.predecessors.forEach(
                (predecessors: any) => cardMap.get(predecessors.cardId).predecessors.push(predecessors));
        }
        if(loadResult.recurringTasks) {
            loadResult.recurringTasks.forEach(
                (task: any) => cardMap.get(task.cardId).recurringTasks.push(task));
        }
        processResult.push(...cardMap.values());

    }

    load (){
        const loader = new PartLoader<IRestCard>();
        return loader.loadPart(
            this.getUrl(),
            '',
            (count: number) => {
                //console.log('loaded', count);
            },
            this.loadProcessorHandler

        )
    }

    getUrl(): string {
        let query = '?';
        query += this.listStatus ? '&status=' + this.listStatus : '';
        query += this.loadEpic ? '&loadEpic=' + this.loadEpic : '';
        query += this.migrate ? '&migrateV3=' + this.migrate : '';
        const version = this.version >= EBoardVersion.V3 ? '/v3': '';
        return Util.getApiUrl('/rest/dashboards/lists/' + this.listId + '/part'+ version + query);
    }
}
