import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { givePermissionsSharedDrives } from '../effect/givePermissionsSharedDrives';

export const onClick = (
): ThunkAction => {
    const action = (dispatch: Dispatch) => {
        dispatch(givePermissionsSharedDrives());
    };
    return action;
};
