import { INotificationBeforeDueDate } from 'app/store/model/notificationBeforeDueDate/types';
import { TStatus } from 'app/types/model';

export const getLatestInterval = (
    notifications: INotificationBeforeDueDate[],
): number => {
    if(notifications && notifications.length > 0){
        let latestInterval = 0;
        notifications.forEach((item) => {
            if(item.status === TStatus.STATUS_ACTIVE && item.interval > latestInterval)
                latestInterval = item.interval;
        });
        return latestInterval;
    }
    return 0;
};
