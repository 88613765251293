import * as React from 'react';
import { IRestListCommonActivity } from '../../../../../../../../types/rest/activity/IRestListCommonActivity';
import { IActivityProps } from '../../types';
import { TActivity } from '../../../../../../../../store/activities/types';
import { ActivityItemBody } from '../ActivityItemBody/ActivityItemBody';
import { ActivityHeader } from '../ActivityHeader/ActivityHeader';

export function ListActivityArchive ({
    activity
}: IActivityProps<IRestListCommonActivity>) {
    const { label } = activity as TActivity;
    return (
        <ActivityItemBody
            header={<ActivityHeader icon={'archive'} label={label} />}
            activity={activity}
        />
    );
}
