import * as React from 'react';
import { IRestListCommonActivity } from '../../../../../../../../types/rest/activity/IRestListCommonActivity';
import { TabActivityContext } from '../TabActivity/constants';
import { IActivityProps } from '../../types';
import { TActivity } from '../../../../../../../../store/activities/types';
import { ActivityHeader } from '../ActivityHeader/ActivityHeader';
import { ActivityItemBody } from '../ActivityItemBody/ActivityItemBody';
import { ActivityItemName } from '../ActivityItemName/ActivityItemName';

export function ListActivityCreate ({
    activity
}: IActivityProps<IRestListCommonActivity>) {
    const { onScrollToList } = React.useContext(TabActivityContext);
    const { label, list } = activity as TActivity;
    return (
        <ActivityItemBody
            header={<ActivityHeader icon={'list'} label={label} />}
            activity={activity}
            onClick={() => onScrollToList(list.id)}
        >
            <ActivityItemName name={list.name} isMarkdown />
        </ActivityItemBody>
    );
}
