import { ICard } from '../../card/types';
import { ICardsComparator } from './types';
import { compareStrings } from './compareStrings';

const getCardValue = (
    card: ICard
): string => {
    const colorLabels = card.colorIds
    return colorLabels.sort().join(' ');
};

export const colorLabel: ICardsComparator = {
    getCardValue,
    sort: compareStrings
}
