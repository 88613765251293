import { Dispatch, ThunkAction } from 'app/types/actions';
import { IGetState, TBoardId, TCardId } from 'app/types/types';
import { cardsArchiveOrDelete } from 'app/view/react_components/base/effects/cardsArchiveOrDelete';
import { closePanel } from 'app/view/react_components/aside_panel/asidePanel/effects/closePanel';
import { getCardSubcardsTree } from 'app/store/model/selectors/getCardSubcardsTree';
import { cardsArchiveWithPrompts } from 'app/view/react_components/base/effects/cardsArchiveWithPrompts';
import { getIsLinkCardActive } from '../../router/selectors/getIsLinkCardActive';
import { SegmentCardEvent, SegmentCardOptions, segmentTrackAction } from '../../../middlewares/segment';
import { SegmentCardDetailsCloseSourceValue } from '../../../middlewares/segment/trackEntities/cardEvents';
import { getActiveCardId } from '../../router/selectors/getActiveCardId';

export const cardArchive = (
    boardId: TBoardId,
    cardId: TCardId,
    withSubcards: boolean = false
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const subcards = withSubcards ? getCardSubcardsTree(getState(), cardId) : [];
        const cardIds = [cardId, ...subcards.map(subcard => subcard.id)];
        if (getActiveCardId(getState()) || getIsLinkCardActive(getState())) {
            dispatch(segmentTrackAction(SegmentCardEvent.CARD_DETAILS_CLOSE_CLICKED , {
                name: SegmentCardOptions.SOURCE,
                value: SegmentCardDetailsCloseSourceValue.CARD_ARCHIVED
            }));
        }
        dispatch(closePanel());
        return dispatch(withSubcards ? cardsArchiveWithPrompts(cardIds) : cardsArchiveOrDelete(cardIds));
    };
    return action;
};
