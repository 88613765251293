import { SocketProvider } from './SocketProvider';

type ISocketProviders = {
    [serverUrl: string]: SocketProvider;
};

export class SocketFactory {
    private static _instance: SocketFactory;
    private socketProviders: ISocketProviders = {};

    private constructor() {
        if (!SocketFactory._instance) {
            SocketFactory._instance = this;
        }
        return SocketFactory._instance;
    }

    public static getInstance(){
        return new SocketFactory();
    }

    private _getSocketProvider(serverUrl: string, reload: boolean){
        if (!this.socketProviders[serverUrl]) {
            this.socketProviders[serverUrl] = new SocketProvider(serverUrl, reload);
        }
        return this.socketProviders[serverUrl];
    }

    public getSocketProvider(){
        // @ts-ignore
        return this._getSocketProvider(window.Settings.cometServerUrl, true);
    }

    public getSocketProviderId(){
        return this.getSocketProvider().socket.io.engine.id;
    }
}
