import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TBoardId, TCardId } from '../../../../../../../../types/types';
import { cardRestGetSections } from '../../../../../../../../rest/effects/card/card/api/helper/cardRestGetSections';
import { IRestPinnedSections } from '../../../../../../../../types/rest/IRestCardMeta';
import { getBoardCards } from '../../../../../../../../store/model/selectors/getBoardCards';
import { IDriveDoc } from '../../../../../../../../store/model/card/types/IDriveDoc';
import { driveDocsActionSetAction } from '../../../../../../../../store/model/actions/driveDocsActionSetAction';
import { driveDocsSet } from '../../../../../../../../store/model/driveDocs/actions/driveDocsSet';
import { IDriveDocs } from '../../../../../../../../store/model/driveDocs/types';
import { getDriveDocs } from '../../../../../../../../store/model/driveDocs/selectors/getDriveDocs';

export const onLoad = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const loadedDocs = getDriveDocs(state);
        const cards = getBoardCards(state, boardId).reduce((cards, card) => {
            const isDocs = card.driveDocCount;
            const docsIds = card.driveDocIds;
            if (isDocs) {
                let isNotLoaded = !docsIds || !docsIds.length;
                if (!isNotLoaded) {
                    for (let i = 0; i < docsIds.length; i++) {
                        if (!loadedDocs[docsIds[i]] || !loadedDocs[docsIds[i]].created) {
                            isNotLoaded = true;
                            break;
                        }
                    }
                }
                if (isNotLoaded) {
                    cards.push({
                        cardId: card.id,
                        sections: ['attachments'] as Array<keyof IRestPinnedSections>
                    });
                }
            }
            return cards;
        }, []);
        if (!Object.keys(cards).length) return Promise.resolve();

        return dispatch(cardRestGetSections(cards))
            .then((result: { id: TCardId, attachments: IDriveDoc[] }[]) => {
                const docs: IDriveDocs = {};
                result.forEach((card, index) => {
                    if (card.attachments) {
                        card.attachments.forEach(doc => {
                            docs[doc.id] = doc;
                        });
                    }
                });
                dispatch(driveDocsActionSetAction(driveDocsSet(docs)));
            });
    };
    return action;
};
