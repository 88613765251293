import { IApplicationState } from '../../../../../types/types';
import { getCardAssignees } from '../../../cardAssignees/selectors/getCardAssignees';
import { ICardAssignee } from '../../../cardAssignee/types';
import { getMyTaskCardFilter } from '../helpers/getMyTaskCardFilter';

export const getMyTaskCardsForFilter = (
    state: IApplicationState,
): ICardAssignee[] => {
    const cardAssignees = getCardAssignees(state);
    const isMatch = getMyTaskCardFilter(state);
    const result: ICardAssignee[] = [];
    for (let id in cardAssignees) {
        if (isMatch(cardAssignees[id])) {
            result.push(cardAssignees[id]);
        }
    }
    return result;
}
