import * as React from 'react';
import { SettingsSection } from '../../../../../../base/components/SettingsSection/SettingsSection';
import { AsidePanelContext } from '../../../../../asidePanel/components/AsidePanel/constants';
import { CLASS_BOARD_DETAILS_SECTION } from '../../../../constants';
import { ILinksToCardsSectionProps } from './types';
import { LinksToCardsTogglerHOC } from '../../hocs/LinksToCardsTogglerHOC/LinksToCardsTogglerHOC';
import { LINKS_TO_CARDS_TITLE } from '../../constants';
import { LinksToCardsHOC } from '../../hocs/LinksToCardsHOC/LinksToCardsHOC';

export function LinksToCardsSection ({
}: ILinksToCardsSectionProps) {
    const { boardId } = React.useContext(AsidePanelContext);
    const className = CLASS_BOARD_DETAILS_SECTION + ' ' + CLASS_BOARD_DETAILS_SECTION + '--open-graph';

    return (
        <SettingsSection
            className={className}
            title={LINKS_TO_CARDS_TITLE}
            titleButton={<LinksToCardsTogglerHOC boardId={boardId} />}
        >
            <LinksToCardsHOC boardId={boardId} />
        </SettingsSection>
    );
};
