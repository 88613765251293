import * as React from 'react';
import './_CardColorTags.scss';
import { Button, ButtonDropdown, Icon } from 'kui';
import { ICardColorTagsProps } from './types';
import { CARD_ADD_COLOR_TAGS, CARD_COLOR_TAGS } from './constants';
import { ColorTags } from '../../../../../base/components/ColorTags/ColorTags';
import { getParentsClasses } from '../../../../../helpers/getParentsClasses';
import {
    SegmentCardColorTagAddSourceValue,
    SegmentCardColorTagsSourceValue
} from 'app/middlewares/segment/trackEntities/cardEvents';
import { CLASS_COLOR_TAGS } from '../../../../../base/components/ColorTags/constants';
import { TColorId } from 'app/types/types';
import { CLASS_CARD_DETAILS } from '../../../constants';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { ActionModal } from '../../../../../base/components/ActionModal/ActionModal';
import {
    BOARD_SECTION_COLORS_MODAL_CANCEL,
    BOARD_SECTION_COLORS_MODAL_REMOVE
} from '../../../../boardDetails/details/Colors/components/BoardColors/constants';
import {
    BasicUpgradeButtonHOC
} from '../../../../../base/components/BasicUpgradeButton/hocs/BasicUpgradeButtonHOC/BasicUpgradeButtonHOC';
import { getMessages } from '../../../../../../../store/constants';
import { CLASS_ACTION_MODAL } from '../../../../../base/components/ActionModal/constants';
import {
    ColorTagsDropdownHOC
} from '../../../../../base/components/ColorTagsDropdown/hocs/ColorTagsDropdownHOC/ColorTagsDropdownHOC';
import { NewFeatureIndicator } from '../../../../../base/components/NewFeatureIndicator/NewFeatureIndicator';
import { CLASS_COLOR_PALETTE } from '../../../../../base/components/ColorPalette/constants';
import { AnalyzeRender } from '../../../../../helpers/memoizeHelper';

//@ts-ignore
const analyzeRender = window.Settings.development ? new AnalyzeRender(`CardColorTags`) : null;

export function CardColorTags({
    aloudCount,
    boardCardColors,
    boardColors,
    colorTags,
    onClose,
    onChange,
    onDoubleClick,
    onOpenDropDown,
    onOpenSettings,
}: ICardColorTagsProps) {
    const { cardId, boardId } = React.useContext(AsidePanelContext);

    if (analyzeRender) analyzeRender.call(`CardColorTags ${cardId}`);

    const className = CLASS_CARD_DETAILS + '__color-tags';
    const classNameButton = className + '-button';
    const classNameTags = className + '-tags';
    const classNameDropdown = className + '-dropdown';
    const classNamePlusBtn = className + '-plus';

    const [ opened, setOpened ] = React.useState(null);
    const [editingColorId, setEditingColorId] = React.useState(null);
    const [isEditing, setEditing] = React.useState(null);
    const [isReset, setReset] = React.useState(null);
    const [removingId, setRemovingId] = React.useState(null);
    const [removingSource, setRemovingSource] = React.useState(null);
    const timerRef = React.useRef(null);

    const onClickHandler = (
        e: React.MouseEvent
    ) => {
        if (colorTags.length && opened) {
            setOpened(false);
        }
        if (!opened) {
            let source = null;
            if (!e.clientX && !e.clientY) { // way to detect if clicked by enter key
                source = SegmentCardColorTagsSourceValue.KEYBOARD;
            } else if (colorTags.length) { // если нет колортегов, то source только PLUS
                const parentClasses = getParentsClasses(e.target as HTMLElement, [classNamePlusBtn, classNameButton, CLASS_COLOR_TAGS]);
                source = parentClasses.includes(CLASS_COLOR_TAGS) ? SegmentCardColorTagsSourceValue.TAG : SegmentCardColorTagsSourceValue.PLUS;
            }
            onOpenDropDown(boardCardColors.length, boardColors.length, source);
        }
    }

    const onDropdownOpen = () => setOpened(true);

    const onClick = () => {
        if (isEditing && opened) {
            setReset(true);
            setTimeout(() => {
                setReset(false);
            }, 0);
        } else {
            setOpened(!opened);
            onOpenDropDown(boardCardColors.length, boardColors.length, SegmentCardColorTagsSourceValue.TAG);
        }
    };

    const onOpen = (id: TColorId) => {
        if (!colorTags.length) return;
        if (timerRef.current) clearTimeout(timerRef.current);
        onDropdownOpen();
        setEditingColorId(id);
        onDoubleClick();
    };

    const onDropdownClose = () => {
        setOpened(false);
        setTimeout(() => { // wait for dropdown close
            setEditingColorId(null);
        }, 150);
        setEditing(false);
    };

    const onCloseModal = () => {
        onClose();
        setRemovingId(null);
        setRemovingSource(null);
    };

    const button = colorTags.length
        ? <Button
            className={classNameTags}
            aria-label={`${CARD_COLOR_TAGS}: ${colorTags.map(tag => tag.name).join(', ')}`}
        >
            <ColorTags
                colorTags={colorTags}
                onEdit={onOpen}
                onClick={onClick}
                onRemove={(id) => {
                    onChange(id, SegmentCardColorTagAddSourceValue.NOT_CHECKBOX).then((result) => {
                        if (!result) {
                            setRemovingId(id);
                            setRemovingSource(SegmentCardColorTagAddSourceValue.NOT_CHECKBOX);
                        }
                    });
                }}
            />
            <div className={classNamePlusBtn}>
                <Icon size={24} xlink={'plus'}/>
            </div>
        </Button>
        : <Button
            className={classNameButton}
            variant="add"
            onClick={onClickHandler}
        >
            {CARD_ADD_COLOR_TAGS}
        </Button>;

    return (
        <>
            <ButtonDropdown
                className={className}
                directionVertical={'down'}
                dropdownClassName={classNameDropdown}
                multiple
                single={false}
                onClose={onDropdownClose}
                onOpen={onDropdownOpen}
                onClick={onClickHandler}
                opened={opened}
                notBlurClasses={[CLASS_COLOR_PALETTE + '-color-row__picker-dropdown', CLASS_ACTION_MODAL]}
            >
                {button}
                <ColorTagsDropdownHOC
                    boardId={boardId}
                    cardId={cardId}
                    isReset={isReset}
                    editingColor={boardCardColors.find(color => color.id === editingColorId)}
                    onChange={onChange}
                    onOpenSettings={onOpenSettings}
                    onSetEditing={setEditing}
                    onClose={onDropdownClose}
                    setRemovingId={setRemovingId}
                    setRemovingSource={setRemovingSource}
                />
            </ButtonDropdown>
            <ActionModal
                isShow={!!removingId}
                actions={[
                    {
                        variant: 'primary',
                        onClick: onCloseModal,
                        children: BOARD_SECTION_COLORS_MODAL_CANCEL
                    },
                    {
                        variant: 'secondary',
                        onClick: () => {
                            onCloseModal();
                            return onChange(removingId, removingSource);
                        },
                        children: BOARD_SECTION_COLORS_MODAL_REMOVE
                    },
                ]}
                footer={<BasicUpgradeButtonHOC onClick={onCloseModal} />}
                text={getMessages().getText('board_settings.colors.modal.text', null, aloudCount)}
                title={getMessages().getText('board_settings.colors.modal.title', null, colorTags.find(color => color.id === removingId)?.name)}
                onClose={onCloseModal}
            />
        </>
    );
}
