import { TCardId } from '../../types/types';

export const getCardIdsFromUrl = (
    url: string
): TCardId[] => {
    const csIdx = url.indexOf('cs-');
    if (csIdx > -1) {
        const cardIdsString = url.substring(csIdx + 3);
        return cardIdsString.split('-').map(item => Number(item));
    }
}
