import { IGetState, TBoardId } from '../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { patchRest } from '../patchRest';
import { getBoard } from '../../../../../store/model/selectors/getBoardById';
import { sendRealTimeStoreAction } from '../../../../../view/react_components/base/helpers/realTimeHelperTS';
import { boardsActionSet } from '../../../../../store/model/actions/boardsActionSet';
import { boardActionSet } from '../../../../../store/model/boards/actions/boardActionSet';
import { getBoardTheme } from '../../../../../store/model/board/selectors/getBoardTheme';
import { IBoardTheme } from '../../../../../store/model/board/types';
import { updateThemeAction } from '../../../../../store/model/board/actions/updateThemeAction';

export const boardThemePatchRest = (
    boardId: TBoardId,
    boardTheme: IBoardTheme,
    isRealtime: boolean = true, // send event to socket
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const currentTheme = getBoardTheme(state, boardId);
        const action = boardsActionSet(boardActionSet(boardId, updateThemeAction(boardTheme)));
        dispatch(action);
        return dispatch(patchRest(boardId, boardTheme))
            .then(() => {
                if (isRealtime) {
                    const board = getBoard(state, boardId);
                    if (board && board.cometToken) {
                        sendRealTimeStoreAction(boardId, board.cometToken, action);
                    }
                }
                return boardTheme;
            })
            .catch((e: any) => {
                console.error(e);
                dispatch(boardsActionSet(boardActionSet(boardId, updateThemeAction(currentTheme))));
            });
    };
    return action;
};
