import { CommonAuthManager } from 'app/helper/authorisation/common/CommonAuthManager';
import { SegmentProfileBoxEvent, segmentTrackAction } from 'app/middlewares/segment';
import { root, vent } from 'app/store/constants';
import { Dispatch, ThunkAction } from 'app/types/actions';

export function onClickSwitch(
): ThunkAction {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(segmentTrackAction(SegmentProfileBoxEvent.SWITCH_ACCOUNT));
        CommonAuthManager.signInSwitchAccount().
            then(function() {
                root.App.controller.showSystemLoader(true);
                vent.trigger(vent['user:switch_account']);
                document.location.reload();
                root.App.controller.removeSplashScreen();
            });
    };
    return action
};
