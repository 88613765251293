import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../../types/types';
import { updateMyWorkMeta } from '../../../../../../../../../rest/effects/authUser/myWork/updateMyWorkMeta';
import { getHideMyWorkEmptyGroups } from '../../../../../selectors/getHideMyWorkEmptyGroups';

export const onEmptyGroupsToggle = (): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const isHideEmptyGroups = !getHideMyWorkEmptyGroups(state);
        dispatch(updateMyWorkMeta({ isHideEmptyGroups }));
    }
    return action;
}
