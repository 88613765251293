import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../types/types';
import { closeBoard } from '../effects/closeBoard';
import { recentBoardTabClosedSegmentEvent } from '../../../../effects/segmentEvents';

export const onCloseBoard: ActionCreator<ThunkAction> = (
    boardId: number
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(recentBoardTabClosedSegmentEvent());
        return dispatch(closeBoard(boardId));
    };
    return action;
};
