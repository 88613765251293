import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { givePermissionsSharedDrives } from '../effect/givePermissionsSharedDrives';

export const onClick: ActionCreator<ThunkAction> =
() => {
    const action = (dispatch: Dispatch) => {
        dispatch(givePermissionsSharedDrives());
    };
    return action;
};
