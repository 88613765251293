'use strict';

import { storeListener } from '../../store/helpers/storeListener';
import store from '../../store/configureStore';
import { getNumberKeys } from '../../helper/getNumberKeys';

App.Views.Dashboards = Backbone.View.extend({
    el: '.main-panel',

    initialize: function() {
        this.dashboardViewManagers = {};

        const boardsSelector = (state) =>{
            return state.model.dashboards;
        };

        const listener = (boards) => {
            const boardIds = getNumberKeys(boards);
            boardIds.forEach(boardId => {
                if (!this.getDashboardViewManager(boards[boardId])) {
                    this.addDashboardViewManager(boards[boardId]);
                }
            })

            getNumberKeys(this.dashboardViewManagers).forEach(boardId => {
                if (!boardIds.includes(boardId)) {
                    // TODO пока не удаляю, что бы работал риалтайм, так как данные в сторе остаются
                    //this.removeDashboardView(boardId);
                }
            })
        };

        this.unsubscribeBoardsListener = storeListener(
            store,
            boardsSelector,
            listener
        );
    },

    addDashboardViewManager: function(board) {
        if (!board) return;
        const viewManager = new App.Views.DashboardViewManager({
            board: board
        });
        this.dashboardViewManagers[board.id] = viewManager;
        this.$el.prepend(viewManager.el);
    },

    getDashboardViewManager: function(board) {
        return board && this.dashboardViewManagers[board.id];
    },

    removeDashboardView: function(boardId) {
        if (!this.dashboardViewManagers[boardId]) return;
        this.dashboardViewManagers[boardId].remove();
        delete this.dashboardViewManagers[boardId];
    }

});
