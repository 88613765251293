import { IFetchArgs, IRequestItem } from 'app/util/fetchHandler';
import { root } from 'app/store/constants';
import { segmentTrackAction, SegmentUserEvent } from 'app/middlewares/segment';
import { dispatch } from 'app/store/configureStore';

const MAX_RUN_COUNT = 10;

export class FetchQueue {
    addCount: number = 0;
    doneCount: number = 0;
    runCount: number = 0;
    queue: IRequestItem[] = [];

    private static _instance: FetchQueue;

    private constructor() {
        if (!FetchQueue._instance) {
            FetchQueue._instance = this;
        }
        return FetchQueue._instance;
    }

    public static getInstance(){
        return new FetchQueue();
    }

    add = (request: IFetchArgs) => {
        let requestItem: IRequestItem;
        return new Promise<Response>((resolve, reject) => {
            requestItem = {...request, resolveCallback: resolve, rejectCallback: reject};
            this.addToQueue(requestItem);
        });
    };

    addToQueue = (request: IRequestItem) => {
        this.addCount++;
        this.queue.push(request);
        this.queueWorker();
    };

    queueWorker = () => {
        if (this.runCount > MAX_RUN_COUNT) {
            return;
        }
        const request = this.queue.shift();
        if (!request){
            return;
        }
        this.runCount++;
        fetch(request.input, request.init).then((response) => {
            try {
                request.resolveCallback(response);
            } catch (e) {
                console.log(e)
            }
        }).catch((e) => {
            try {
                request.rejectCallback(e);
            } catch (e1) {
                console.log(e1);
            }
            let message = JSON.stringify( {input: request.input, init: request.init}) + ' !!! '+ (e.stack ? e.stack : (e.massage ? e.massage : ''));
            dispatch(segmentTrackAction(SegmentUserEvent.DEBUG, {
                name: 'queueWorker_fetch_catch',
                value: message,
            }));
        }).finally(() =>{
            this.runCount--;
            setTimeout(() => this.queueWorker(), 0);
            this.doneCount++;
        });
    };
}

// @ts-ignore
root._FetchQueue= FetchQueue.getInstance();
