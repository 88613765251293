import { segmentTrackAction, SegmentNotificationsEvent, SegmentNotificationsOptions, SegmentNotificationsOptionSource } from '../../../../../../../../middlewares/segment';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { NOTIFY_SELECT_GROUP } from '../../../constants';

export const onUserSelect = (
    group: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        const sources: Record<string, SegmentNotificationsOptionSource> = {
            [NOTIFY_SELECT_GROUP.BOARD]: SegmentNotificationsOptionSource.SELECT_GROUP_BOARD,
            [NOTIFY_SELECT_GROUP.OTHER]: SegmentNotificationsOptionSource.SELECT_GROUP_OTHER,
        }
        const source = sources[group] || group;

        dispatch(segmentTrackAction(SegmentNotificationsEvent.FORCE_USER_CHOSEN, {
            name: SegmentNotificationsOptions.SOURCE,
            value: source
        }));
    };
    return action;
};
