import * as React from 'react';
import './_CardUpdates.scss';
import { ICardUpdatesProps } from './types';
import { TOOLTIP_UPDATES } from '../constants';
import { UpdateCounter } from '../../../../../base/components/UpdatesCounter/UpdateCounter';
import { CLASS_MY_WORK_CARD_CONTROL } from '../../../constants';

export function CardUpdates({
    card,
    onClick
}: ICardUpdatesProps) {
    if (!card.updatesCount) return null;
    const onClickHandler = (event: React.MouseEvent) => {
        event.preventDefault();
        onClick();
    };
    return (
        <UpdateCounter
            className={CLASS_MY_WORK_CARD_CONTROL}
            onClick={onClickHandler}
            tooltip={TOOLTIP_UPDATES}
            updates={card.updatesCount}
        />
    )
}
