import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../types/types';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';

export const onInputKeyDown = (): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.PERFOMED_ASSIGNEES_SEARCH));
    };
    return action;
};
