'use strict';

App.Mixins.Print = {
    /** Contract:
     * General for Mixins:
     * 1. view must inherit from App.Views.BaseView
     * 2. there should be a line like that after view definition: App.Views.CardPrintPreview.mixin(App.Mixins.Print);
     *
     * Specific for Print:
     * 1. view must have close() method
     * 2. view html must have js-close and js-print links
     */

    events: {
        'click .js-close': 'onCloseClick',
        'click .js-print': 'onPrintClick'
    },

    // mixin
    initialize: function() {
        App.vent.on(App.vent['view:afterprint'], this.close, this);
        App.vent.on(App.vent['keydown:escape'], this.close, this);
    },

    onCloseClick: function(e) {
        e.stopPropagation();
        this.close();
        return false;
    },

    onPrintClick: function(e) {
        e.stopPropagation();
        this.print();
        return false;
    },

    // mixin
    //afterDOMRendering: function() {
    //    this.print();
    //},

    print: function() {
        window.print();
    }
};
