import * as React from 'react';
import { useEffect } from 'react';
import { ITopBarFilterButtonProps } from './types';
import { Button, Icon, Input } from 'kui';
import './_TopBarFilterButton.scss';
import { TOP_BAR_FILTER_SECTIONS, TOPBAR_FILTER_MOBILE, TOPBAR_FILTER_PLACEHOLDER } from './constants';
import { root } from '../../../../../../store/constants';
import { SearchedCard } from './SearchedCard/SearchedCard';
import { SearchSelect } from '../../../../base/components/SearchSelect/SearchSelect';
import { ISearchSelectOption } from '../../../../base/components/SearchSelect/types';
import { filterArrayByValue } from '../../../../helpers/search/filterArrayByValue';

export function TopBarFilterButton ({
    keyword,
    cards,
    isActive,
    isAsidePanelOpened,
    isRelatedCardsPanel,
    isShown,
    afterSearchCard,
    afterSearchFilter,
    onFilterChange,
    onClickButton,
    onBlur,
    onSelect,
    onClickInput,
}: ITopBarFilterButtonProps) {
    const className = 'top-bar__filter';
    const classNameActive = isActive ? className +'--active' : '';
    const classNameInput = 'top-bar__filter-input';
    const classNameSearch = 'top-bar__filter-search';
    const classFilter = className + '-info';
    const classNameOk = 'top-bar__filter-ok';
    const classNameIcon = 'top-bar__filter-icon';
    const classNameButtonMobile = 'top-bar__filter-button-mobile';
    const divRef = React.useRef(null);
    const inputRef = React.useRef(null);
    const debounce = React.useRef(null);
    const [isMountDropdown, setIsMountDropdown] = React.useState(false);
    let [isSearchFocused, _setIsSearchFocused] = React.useState(isActive && !!keyword);
    const classNameFocused = isSearchFocused ? className + '--focused' : '';
    const classNameAside = isAsidePanelOpened && !isRelatedCardsPanel ? className + '--aside' : '';
    let [searchValue, _setSearchValue] = React.useState('');
    let [inputValue, _setInputValue] = React.useState(keyword);
    const [cardsHook, setCardsHook] = React.useState(cards);

    function setSearchValue (val: string) {
        searchValue = val;
        _setSearchValue(val);
    };

    function setInputValue (val: string) {
        inputValue = val;
        _setInputValue(val);
    };

    function setIsSearchFocused (isSearchFocusedNew: boolean) {
        isSearchFocused = isSearchFocusedNew;
        _setIsSearchFocused(isSearchFocusedNew);
    };

    function closeSearch () {
        setSearchValue('');
        setIsSearchFocused(false);
        setIsMountDropdown(false);
        const input = divRef.current.querySelector('input'); // в handleSearchSelect blur почему-то срабатывает только на канбанВью
        if (input && input === document.activeElement) input.blur();
    };

    function applyFilterCloseSearch () {
        if (searchValue) {
            onFilterChange(searchValue);
            afterSearchFilter();
        }
        closeSearch();
    };

    function handleSearchChange (val: string) {
        setSearchValue(val);
        if (val) {
            if (isRelatedCardsPanel) {
                if (debounce.current) clearTimeout(debounce.current);
                debounce.current = setTimeout(() => {
                    applyFilterCloseSearch()
                    requestAnimationFrame(()=>{
                        const input = divRef.current.querySelector('input');
                        if (input && input !== document.activeElement) input.focus();
                    })
                }, 500)
            } else if (!isMountDropdown) {
                if (debounce.current) clearTimeout(debounce.current);
                debounce.current = setTimeout(() => setIsMountDropdown(true), 800); // 300 анимация + 500 дебонс (не видеть дропдаун со всеми картами)
            }
        } else {
            setIsMountDropdown(false);
            if (debounce.current) clearTimeout(debounce.current);
        }
    };

    function handleSearchKeyDown (event: React.KeyboardEvent) {
        const enterKey = event.keyCode === 13;
        const escKey = event.keyCode === 27;
        if (enterKey) {
            setTimeout(applyFilterCloseSearch, 100); // подождать handleSearchSelect
        } else if (escKey) {
            closeSearch();
        }
    }

    function handleSearchOpen () {
        setIsSearchFocused(true);
        setCardsHook(cards); // обновлять карточки для кейса между переключением досок
        onClickInput();
    };

    function handleSearchSelect (value: string) {
        onSelect(value);
        closeSearch();
        afterSearchCard();
    };

    function handleSearchBlur (e: React.SyntheticEvent) {
        setTimeout(applyFilterCloseSearch, 100); // подождать handleSearchSelect
    };

    function handleInputChange (e: React.SyntheticEvent) {
        const { value } = e.target as HTMLInputElement;
        if (debounce.current) clearTimeout(debounce.current);
        debounce.current = setTimeout(
            () => onFilterChange(value),
            500
        );
    };

    function handleFilterButtonClick () {
        setTimeout(() => {
            applyFilterCloseSearch();
            onClickButton();
        }, 100); // подождать handleSearchBlur
    };

    function handleMobileButtonClick (e: React.SyntheticEvent) {
        if (isAsidePanelOpened) { // KNB-4014 иногда не успевают сфокусироваться
            setTimeout(() => {
                const input = divRef.current.querySelector('input') as HTMLElement;
                if (input) input.focus();
            }, 0)
        } else {
            onClickButton();
        }
    };

    useEffect(() => {
        if (!isSearchFocused) setCardsHook(cards); // не обновлять карты, пока поиск в фокусе
    }, [cards]);

    useEffect(() => {
        setInputValue(keyword);
    }, [isActive, keyword]);

    useEffect(() => {
        function handleShortcut (event: any) {
            const e = event as React.KeyboardEvent;
            if ((e.ctrlKey || e.metaKey) && (e.key === 'f' || e.key === 'а')) {
                e.preventDefault();
                const input = divRef.current.querySelector('.kui-input');
                if (input) input.click();
            }
        };
        root.addEventListener('keydown', handleShortcut);

        return (() => {
            root.removeEventListener('keydown', handleShortcut);
            if (debounce.current) clearTimeout(debounce.current);
        });
    }, []);

    const onFilterOptions = (
        list: ISearchSelectOption[],
        searchQuery: string
    ): ISearchSelectOption[] => {
        const result: ISearchSelectOption[] = [];
        const filteredValues: string[] = [];
        TOP_BAR_FILTER_SECTIONS.forEach((section, index) => {
            let sectionOptions: ISearchSelectOption[] = filterArrayByValue(list, section.field, searchQuery);
            if (sectionOptions.length) {
                filteredValues.push(...sectionOptions.map(option => option.value));
                sectionOptions.sort((x, y) => y.lastActivity - x.lastActivity)
                if (sectionOptions.length) {
                    result.push({
                        text: section.name,
                        value: section.field,
                        options: sectionOptions
                    })
                }
            }
        });
        return result;
    }

    return isShown && (
        <div
            className={`
                ${className}
                ${classNameActive}
                ${classNameFocused}
                ${classNameAside}
            `}
            ref={divRef}
        >
            {isActive
                ? <Input
                    autosize={false}
                    className={classNameInput}
                    isClearable
                    ref={inputRef}
                    searchPlaceholder={TOPBAR_FILTER_PLACEHOLDER}
                    value={inputValue}
                    variant={'search'}
                    onBlur={onBlur}
                    onChange={handleInputChange}
                    onClick={onClickInput}
                />
                : <SearchSelect
                    className={classNameSearch}
                    isClearable
                    isCloseOnEnter={false}
                    isFitWindow
                    isEnterEnabled={true}
                    isMountDropdown={isMountDropdown}
                    isAcronymSearchEnabled={false}
                    option={SearchedCard}
                    options={cardsHook}
                    ref={inputRef}
                    searchDebounce={500}
                    searchPlaceholder={TOPBAR_FILTER_PLACEHOLDER}
                    text={searchValue}
                    onBlur={handleSearchBlur}
                    onInputChange={handleSearchChange}
                    onKeyDown={handleSearchKeyDown}
                    onOpened={handleSearchOpen}
                    onSelect={handleSearchSelect}
                    onFilterOptions={onFilterOptions}
                />
            }
            <div
                className={classNameOk}
                // onClick={} // можно повесить segment. Пока ничего не надо, т.к. работает blur
            />
            <Button
                className={`${classNameButtonMobile} ${!isAsidePanelOpened ? 'handle-click-ignore' : ''}`}
                variant={'fab'}
                color={'white'}
                tooltip={{
                    value: TOPBAR_FILTER_MOBILE,
                    isNoWrap: true,
                    direction: 'down'
                }}
                onClick={handleMobileButtonClick}
            >
                <Icon className={classNameIcon} size={24} xlink={'search'}/>
            </Button>
        </div>
    );
};
