import {
    INavigationPanelCreateBoardDispatchProps,
    INavigationPanelCreateBoardStateProps
} from '../../components/NavigationPanelCreateBoard/types';
import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../types/types';
import { NavigationPanelCreateBoard } from '../../components/NavigationPanelCreateBoard/NavigationPanelCreateBoard';
import { onAddClick } from './events/onAddClick';
import { onCreateBoard } from './events/onCreateBoard';
import { onCreateFromTemplate } from './events/onCreateFromTemplate';
import { isOpenNavigationPanelSelector } from '../../store/selectors/isOpenNavigationPanelSelector';
import { getAuthUser } from '../../../../../../store/model/authUser/selectors/getAuthUser';
import { getCurrentNavigationPanelType } from '../../helpers/getCurrentNavigationPanelType';
import { onImport } from './events/onImport';
import { getAuthUserSupport } from 'app/store/model/authUser/selectors/getAuthUserSupport';
import { getAuthUserPlatformType } from 'app/store/model/authUser/selectors/getAuthUserPlatformType';
import { getIsUserBasicPlanOnly } from '../../../../../../store/model/authUser/selectors/getIsUserBasicPlanOnly';
import { onClickDisallow } from './events/onClickDisallow';

const mapStateToProps = (
    state: IApplicationState
): INavigationPanelCreateBoardStateProps => {
    const allowCreateBoard = getAuthUser(state).allowCreateBoard;
    return {
        allowCreateBoard,
        isBasicFeature: getIsUserBasicPlanOnly(state),
        isNavigationPanelOpened: isOpenNavigationPanelSelector(state),
        navigationPanelType: getCurrentNavigationPanelType(state),
        supportSharedDrive: getAuthUserSupport(state, 'supportSharedDrive'),
        platformType: getAuthUserPlatformType(state),
    };
};

const mapDispatchToProps = (
    dispatch: any,
): INavigationPanelCreateBoardDispatchProps => {
    return {
        onClick: () => dispatch(onAddClick()),
        onClickDisallow: () => dispatch(onClickDisallow()),
        onCreate: (isDropdownButton) => dispatch(onCreateBoard(isDropdownButton)),
        onCreateFromTemplate: () => dispatch(onCreateFromTemplate()),
        onImport: () => dispatch(onImport())
    }
};

export const NavigationPanelCreateBoardHOC = connect(mapStateToProps,
    mapDispatchToProps
)(NavigationPanelCreateBoard);
NavigationPanelCreateBoardHOC.displayName = 'NavigationPanelCreateBoardHOC';
