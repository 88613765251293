import Util from '../../util/util';
import { fetchHandler } from '../../util/fetchHandler';
import { IRestBoardForOpenDialog } from '../../view/react_components/dialogs/openBoards/rest/types';
import { TBoardId } from 'app/types/types';

export class BoardTabsLoader {
    MAX_PORTION: number = 50;
    boardIds: TBoardId[];

    constructor(boardIds: TBoardId[]) {
        this.boardIds = boardIds;
    }

    load (
    ): Promise<IRestBoardForOpenDialog[]> {
        let index = 0;
        let result: IRestBoardForOpenDialog[] = [];
        let promises = [];
        while (index < this.boardIds.length) {
            let indexNew = index + this.MAX_PORTION;
            let part = this.boardIds.slice(index, indexNew);
            let partLoader = new BoardTabsLoaderPart(part);
            promises.push(partLoader.load()
                .then(boards => {
                    result = [
                        ...result,
                        ...boards,
                    ]
                }));
            index = indexNew;
        }
        return Promise.all(promises).then(() => result);
    }
}

class BoardTabsLoaderPart {
    boardIds: TBoardId[];

    constructor (
        boardIds: TBoardId[],
    ) {
        this.boardIds = boardIds;
    }

    load (
    ): Promise<IRestBoardForOpenDialog[]> {
        return fetchHandler<IRestBoardForOpenDialog[]>(
            this.getUrl()
        );
    }

    getUrl (
    ): string {
        return Util.getApiUrl('/rest/dashboards/openDialog/list?id=' + this.boardIds.join(','));
    }
}
