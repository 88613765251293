import {ISelectedCardIdsSet} from './types';
import {AT_SELECTED_CARD_IDS_SET} from './constatns';

export const selectedCardIdsSet = (
    selectedCardIds: number[],
    isMultiselect?: boolean,
): ISelectedCardIdsSet => ({
    type: AT_SELECTED_CARD_IDS_SET,
    isMultiselect,
    selectedCardIds
});
