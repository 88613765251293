import { IActivityProps } from '../../../../boardDetails/activity/TabActivity/types';
import * as React from 'react';
import { TabActivityContext } from '../../../../boardDetails/activity/TabActivity/components/TabActivity/constants';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { getDateFormatted } from '../../../../../helpers/dateService';
import { TActivity } from '../../../../../../../store/activities/types';
import { MESSAGE_DUE_DATE } from '../CardActivityDueDatePassed/constants';
import { IRestCardCheckListActivity } from '../../../../../../../types/rest/activity/IRestCardCheckListActivity';
import {
    ActivityItemBody
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemBody/ActivityItemBody';
import { ActivityHeader } from '../../../../boardDetails/activity/TabActivity/components/ActivityHeader/ActivityHeader';
import {
    ActivityItemName
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemName/ActivityItemName';
import { CLASS_ACTIVITY_ITEM } from '../../../../boardDetails/activity/TabActivity/components/constants';
import './_CardActivityCheckListDueDatePassed.scss';
import { blockMarkdown } from '../../../../../../../helper/markdownHelper';
import { msFileThumbnailStorage } from '../../../../../../../helper/authorisation/microsoft/storage/msThumbnail';

export function CardActivityCheckListDueDatePassed ({
    activity
}: IActivityProps<IRestCardCheckListActivity>) {
    const { onOpenCard } = React.useContext(TabActivityContext);
    const { isUserTimeFormat12 } = React.useContext(AsidePanelContext);
    const date = getDateFormatted(Number(activity.newValue), isUserTimeFormat12);
    const { label, card } = activity as TActivity;
    const className = CLASS_ACTIVITY_ITEM + '__checklist-group-due-date-passed';
    console.log('CardActivityCheckListDueDatePassed activity',activity);
    const [markdown, setMarkdown] = React.useState('');

    React.useEffect(() => {
        let name = activity.checkListItemName;

        setMarkdown(blockMarkdown(name));

        if (msFileThumbnailStorage.is()) {
            if (name) {
                msFileThumbnailStorage.getSrcThumbnails(name)
                    .then((text) => {
                        setMarkdown(blockMarkdown(text));
                    })
                    .catch(() => {});
            }
        }
    }, [activity]);

    return (
        <ActivityItemBody
            className={className}
            activity={activity}
            onClick={() => onOpenCard(card.id, activity.id, 'checklsits')}
            headerIcon={'notifications'}
            header={<ActivityHeader
                icon={'overdue'}
                label={label}
            />}
        >
            <ActivityItemName name={markdown} isMarkdown />
            <ActivityItemName name={`${MESSAGE_DUE_DATE} ${date}`} />
        </ActivityItemBody>
    );
}
