import * as React from 'react';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { KEY_DOWN, KEY_ENTER, KEY_UP } from '../../../constants';
import { IGetState, TBoardId, TCardId } from '../../../../../../../../types/types';
import { isMentionsDropDownShown } from '../../../selectors/isMentionsDropDownShown';
import { pasteCommentMention } from '../effects/pasteCommentMention';
import { highlightNextMention } from '../effects/highlightNextMention';

export const onKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    boardId: TBoardId,
    cardId: TCardId
): ThunkAction => {
    return (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const isMentionsShown = isMentionsDropDownShown(state);

        switch (e.key) {
            case KEY_UP:
                if (isMentionsShown) {
                    dispatch(highlightNextMention(boardId, cardId, -1));
                    e.preventDefault();
                }
                break;
            case KEY_DOWN:
                if (isMentionsShown) {
                    dispatch(highlightNextMention(boardId, cardId, 1));
                    e.preventDefault();
                }
                break;

            case KEY_ENTER:
                if (isMentionsShown) {
                    e.preventDefault();
                    e.stopPropagation();
                    const input = e.target as HTMLInputElement;
                    dispatch(pasteCommentMention(input, boardId, cardId));
                }
                break;
        }
    };
};
