import { INavigationPanelCreateBoardProps } from './types';
import * as React from 'react';
import './_NavigationPanelCreateBoard.scss';
import { BOARDS_ADD_BUTTON, BOARDS_ADD_CREATE, BOARDS_ADD_CREATE_FROM_TEMPLATE, BOARDS_ADD_IMPORT } from './constants';
import { OptionButton } from '../../../../base/components/OptionButton/OptionButton';
import { ENavigationPanelTypes, NAVIGATION_PANEL_CLASS } from '../../constants';
import {
    BOARDS_ADD_CREATE_SHARED_DRIVE,
    CREATE_ON_SHARED_DRIVE_CLASS
} from '../NavigationPanelCreateBoardDropDown/constants';
import {
    NavigationPanelCreateBoardDropDownHOC
} from '../../hocs/NavigationPanelCreateBoardDropDownHOC/NavigationPanelCreateBoardDropDownHOC';
import { IOptionButtonElement } from 'app/view/react_components/base/components/OptionButton/types';
import { EAuthUserPlatformType } from 'app/types/rest/IRestAuthUser';
import { BasicFeatureIndicator } from '../../../../base/components/BasicFeatureIndicator/BasicFeatureIndicator';

export const NavigationPanelCreateBoard: React.FC<INavigationPanelCreateBoardProps> = ({
    isBasicFeature,
    isNavigationPanelOpened,
    allowCreateBoard,
    navigationPanelType,
    supportSharedDrive,
    platformType,
    onClick,
    onClickDisallow,
    onCreate,
    onCreateFromTemplate,
    onImport
}) => {
    const className = NAVIGATION_PANEL_CLASS + '__create-board';
    const [isOpened, setOpened] = React.useState(false);

    const options: IOptionButtonElement[] = [
        {
            icon: platformType === EAuthUserPlatformType.MICROSOFT ? 'project' : 'drive',
            name: BOARDS_ADD_CREATE,
            onClick: () => onCreate(true)
        }
    ];
    if (isBasicFeature) {
        options.push({
            icon: 'share-specific',
            name: BOARDS_ADD_CREATE_SHARED_DRIVE,
            onClick: onClickDisallow,
            iconEnd: <BasicFeatureIndicator />
        });
    } else if (supportSharedDrive && isOpened) {
        options.push({
            element: (
                <NavigationPanelCreateBoardDropDownHOC
                    isOpened={isOpened}
                    key={'NavigationPanelCreateBoardDropDownHOC'}
                    onOpen={() => setOpened(true)}
                    onClose={() => setOpened(false)}
                />
            )
        });
    }
    options.push({
        icon: 'board-template',
        name: BOARDS_ADD_CREATE_FROM_TEMPLATE,
        onClick: onCreateFromTemplate
    });
    options.push({
        icon: 'import-document',
        name: BOARDS_ADD_IMPORT,
        onClick: onImport
    });

    const onOpenHandler = () => {
        setOpened(true);
    };

    React.useEffect(() => {
        if (
            isOpened &&
            !isNavigationPanelOpened &&
            (
                navigationPanelType === ENavigationPanelTypes.NP_COMPACT_TYPE ||
                navigationPanelType === ENavigationPanelTypes.NP_MOBILE_TYPE
            )
        ) {
            setOpened(false);
        }
    }, [isNavigationPanelOpened]);

    const onBoardAdd = () => {
        onClick();
        onCreate(false);
    };

    return (
        <div className={className}>
            <OptionButton
                icon={'plus-circle'}
                text={BOARDS_ADD_BUTTON}
                options={options}
                onClick={onBoardAdd}
                onDropdownOpen={onOpenHandler}
                dropdownOpened={isOpened}
                disabled={!allowCreateBoard}
                notBlurClasses={[
                    CREATE_ON_SHARED_DRIVE_CLASS,
                    CREATE_ON_SHARED_DRIVE_CLASS + '__dropdown',
                    CREATE_ON_SHARED_DRIVE_CLASS + '__button',
                    'second-panel--pro-features-trial'
                ]}
            />
        </div>
    );
};
