import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../../../../types/types';
import { checklistAdd } from '../../../../../../../../rest/effects/card/checklist/checklistAdd';
import { IChecklist } from '../../../../../../../../store/model/checklists/checklist/types';
import { setChecklistName } from '../../../effects/setChecklistName';
import { SegmentCardEvent, segmentTrackAction } from 'app/middlewares/segment';
import {
    SegmentCountValue
} from 'app/middlewares/segment/trackEntities/cardEvents';
import { getCardChecklists } from 'app/store/model/checklists/checklists/selectors/getCardChecklists';
import {
    getAuthUserAllowChecklistCount
} from '../../../../../../../../store/model/authUser/selectors/getAuthUserAllowChecklistCount';
import { showSnoozeBlocker } from '../../../../../../base/effects/showSnoozeBlocker';

export const onChecklistAdd = (
    cardId: TCardId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const maxCount = getAuthUserAllowChecklistCount(state);
        const count = Object.keys(getCardChecklists(state, cardId)).length;
        if (count >= maxCount) {
            return dispatch(showSnoozeBlocker({ allow: 'allowChecklistCount' }));
        } else {
            return dispatch(setChecklistName({ cardId })).then((checklist: IChecklist) => {
                return dispatch(checklistAdd(checklist))
                .then((checklist: IChecklist) => {
                        dispatch(segmentTrackAction(SegmentCardEvent.CREATED_CHECKLIST, {
                            name: SegmentCountValue.COUNT,
                            value: count + 1
                        }));
                    return checklist.id;
                });
            });
        }
    };
    return action;
};
