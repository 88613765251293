import {
    CLASS_ASIDE_PANEL_ACTIONS_BUTTON_CONTAINER
} from '../aside_panel/asidePanel/components/AsidePanelActionsButton/constants';

export const asidePanelActionButtonClick = (
    section: string
) => {
    const button = document.querySelector(
        '.' + CLASS_ASIDE_PANEL_ACTIONS_BUTTON_CONTAINER + `--${section}` + ' .kui-button'
    ) as HTMLElement;
    if (button) {
        button.focus();
        button.click();
    }
}
