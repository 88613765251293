import * as React from 'react';
import { AsidePanelActionsButton } from '../../../..';
import { CLASS_DELETE_BUTTON, DELETE_BUTTON_TITLE } from './constants';
import { IDeleteButtonProps } from './types';
import './_DeleteButton.scss';

export function DeleteButton ({
    isShow,
    tooltip,
    onClick,
}: IDeleteButtonProps) {
    return (
        <AsidePanelActionsButton
            title={DELETE_BUTTON_TITLE}
            className={CLASS_DELETE_BUTTON}
            tooltip={tooltip}
            isShow={isShow}
            onClick={onClick}
        />
    );
};
