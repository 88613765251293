import * as React from 'react';
import './_CardProgress.scss';
import { Done, Tooltip } from 'kui';
import { ICardCell } from '../../types';
import { CLASS_MY_WORK_CARD } from '../../../constants';
import { DONE_TITLE } from '../../../../../../../const';

export function CardProgress ({
    card
}: ICardCell) {
    const className = CLASS_MY_WORK_CARD + '__progress';
    return (
        <div className={className}>
            <Tooltip
                direction={'up'}
                value={card.processingPercent + '% ' + DONE_TITLE}
            >
                <Done
                    percent={card.processingPercent}
                    size={'small'}
                    titleDone={DONE_TITLE}
                    titlePercent={null}
                />
            </Tooltip>
        </div>
    );
};
