import { connect } from 'react-redux';
import { IKanbanTitleEvents, IKanbanTitleFields } from '../components/type';
import { IApplicationState } from '../../../../../types/types';
import { isTimeTrackerTracking } from '../../../aside_panel/cardDetails/TabTimingSection/TimeTracker/store/selectors/isTimeTrackerTracking';
import { onChange } from './events/onChange';
import { KanbanTitle } from '../components/KanbanTitle';
import { getKanbanStaticTitle } from './selectors/getKanbanStaticTitle';

const mapStateToProps = (
    state: IApplicationState
): IKanbanTitleFields => {
    const isTimeTracking = isTimeTrackerTracking(state);
    const staticTitle = getKanbanStaticTitle(state);
    return {
        isTimeTracking,
        staticTitle
    };
};

const mapDispatchToProps = (
    dispatch: any
): IKanbanTitleEvents => ({
    onChange: () => dispatch(onChange())
})

export const KanbanTitleHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(KanbanTitle);

KanbanTitleHOC.displayName = 'KanbanTitleHOC';
