import { Dispatch } from '../../../../../types/actions';
import Util from '../../../../../util/util';
import { blockerSet } from '../store/common/actions/blockerSet';
import {
    DashboardXmlImportManager
} from '../../../../../helper/xml_import_export/dashboard_xml_import_manager_no_backbone';
import { openBoard } from '../effects/openBoard';
import {
    deleteSnackbar,
    snackbarErrorContactUs,
    snackbarProgressAdd,
    snackbarProgressUpdate
} from '../../../snackbarsStack';
import { getController } from '../../../../../store/constants';
import { boardsListGoogleAnalyticsEvents } from '../effects/googleAnalyticsEvents';
import { TBoardId, TDriveId } from '../../../../../types/types';
import { getAuthUserPlatformType } from 'app/store/model/authUser/selectors/getAuthUserPlatformType';
import { EAuthUserPlatformType } from 'app/types/rest/IRestAuthUser';
import { TXMLRestoreDoc } from 'app/view/react_components/dialogs/openBoards/hocs/FooterRestoreXmlHOC/effects/types';
import {
    CSV_OPEN_FILE_MIME_FILTER,
    JITA_OPEN_FILE_MIME_FILTER,
    PWC_OPEN_FILE_MIME_FILTER,
    TRELLO_OPEN_FILE_MIME_FILTER
} from 'app/view/react_components/dialogs/openBoards/FooterImport/hocs/FooterImportHOC/constants';
import { dispatch, getAppState } from 'app/store/configureStore';
import { DriveItem } from '@microsoft/microsoft-graph-types';
import { getAuthUser } from 'app/store/model/authUser/selectors/getAuthUser';
import {
    selectFileFromLocalDrive
} from 'app/helper/authorisation/microsoft/selectFileFromLocalDrive/selectFileFromLocalDrive';
import { boardRestoreSharedUsersIfNeeded } from '../../../../../helper/loader/boardRestoreSharedUsersIfNeeded';

export interface IDashboardImportRouterSettings {
    gaStartEvent?: string,
    gaSuccessEvent?: string,
    importUrl: string,
    snackbarImportId: string,
    importProgressText: string,
    importErrorText: string,
    mimeTypesFilter: string,
    onSuccess?: () => void
}
export class DashboardImportRouter {
    private settings: IDashboardImportRouterSettings;
    private dispatch: Dispatch;
    constructor(
        settings: IDashboardImportRouterSettings,
        dispatch: Dispatch) {
        this.settings = settings;
        this.dispatch = dispatch;
    }
    public start() {
        if (this.settings.gaStartEvent) {
            this.dispatch(boardsListGoogleAnalyticsEvents(this.settings.gaStartEvent));
        }
        const platformType = getAuthUserPlatformType(getAppState());
        switch (platformType) {
            case EAuthUserPlatformType.MICROSOFT:
                const authUser = getAuthUser(getAppState());
                const onFinish = (driveItems: DriveItem[]): void => {
                    if (!driveItems || !driveItems.length) return null;

                    let item = driveItems[0];
                    let docs: TXMLRestoreDoc[] = [{
                        id: item.id,
                        driveId: item.parentReference.driveId,
                        name: item.name
                    }];
                    this.onGoogleDriveDocumentSelected(docs);
                }
                selectFileFromLocalDrive(
                    authUser.appFolderInfo.driveId,
                    authUser.appFolderInfo.folderId,
                    false,
                    [this.getMicrosoftFileFilter(this.settings.mimeTypesFilter)],
                    () => {},
                    percent => {}
                ).then(onFinish)
                break;
            default:
                App.Helpers.googleDriveOpenDialogue.showByMimeTypes(
                    false,
                    this.settings.mimeTypesFilter,
                    (docs: any) => {
                        this.onGoogleDriveDocumentSelected(docs);
                    }
                );
        }

    }

    private getMicrosoftFileFilter(mimeTypesFilter: string){
        let filter;
        switch (mimeTypesFilter) {
            case TRELLO_OPEN_FILE_MIME_FILTER:
                filter = '.json'
                break;
            case JITA_OPEN_FILE_MIME_FILTER:
                filter = '.xml'
                break;
            case CSV_OPEN_FILE_MIME_FILTER:
                filter = '.csv'
                break;
            case PWC_OPEN_FILE_MIME_FILTER:
                filter = '.csv'
                break;
        }
        return filter;
    }
    private onGoogleDriveDocumentSelected(docs: TXMLRestoreDoc[]) {
        if (
            !docs ||
            !docs.length
        ) return;

        this.dispatch(blockerSet(true));
        this.dispatch(snackbarProgressAdd({
            id: this.settings.snackbarImportId,
            text: this.settings.importProgressText,
            buttonProgress: 0
        }));
        const importManager = new DashboardXmlImportManager({
            onCancel: () => this.onCancel,
            onError: (xhr: any) => {
                this.onError(xhr);
            },
            onProgress: (progress: number) => {
                this.onProgress(progress);
            },
            onSuccess: ({destDashboardId, parentFolderInfo}) => {
                this.onSuccess(destDashboardId, parentFolderInfo.driveId, parentFolderInfo.folderId)
                if (this.settings.onSuccess) {
                    this.settings.onSuccess();
                }
            }
        });
        importManager.startImport({
            url: Util.getApiUrl(this.settings.importUrl),
            importFileId: docs[0].id,
            importDriveId: docs[0].driveId,
            dashboardName: docs[0].name
        });
    }

    private onCancel() {
        this.dispatch(blockerSet(false));
        this.dispatch(deleteSnackbar(this.settings.snackbarImportId));
    }

    private onError(xhr: any) {
        const request = Object.assign(
            {
                responseText: xhr.status + ' ' + xhr.statusText
            },
            xhr
        );
        this.dispatch(blockerSet(false));
        this.dispatch(deleteSnackbar(this.settings.snackbarImportId));
        this.dispatch(snackbarErrorContactUs({
            id: this.settings.snackbarImportId,
            text: this.settings.importErrorText,
            actionApply: () => getController().showHelpPopup({
                alert: true,
                request,
                url: this.settings.importUrl
            })
        }));
    }

    private onProgress(progress: number) {
        this.dispatch(snackbarProgressUpdate({
            id: this.settings.snackbarImportId,
            text: this.settings.importProgressText,
            buttonProgress: Math.ceil(progress)
        }));
    }

    private onSuccess(boardId: TBoardId, driveId: TDriveId, fileId: string) {
        this.dispatch(deleteSnackbar(this.settings.snackbarImportId));
        const processSuccess = () => {
            this.dispatch(openBoard(boardId));
            if (this.settings.gaSuccessEvent) {
                this.dispatch(boardsListGoogleAnalyticsEvents(this.settings.gaSuccessEvent));
            }
        };
        dispatch(boardRestoreSharedUsersIfNeeded(boardId, driveId, fileId, processSuccess));
    }
}
