import { Dispatch, ThunkAction } from 'app/types/actions';
import { IGetState, TBoardId } from 'app/types/types';
import { topBarNameStatusSet } from '../../../store/topBar/actions/topBarNameStatusSet';
import { topBarSetAction } from '../../../store/topBarBoard/actions/topBarSetAction';
import { EBoardNameStatus } from '../../../store/topBar/types';

export const onNameSuccess = (
    boardId: TBoardId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(topBarSetAction(boardId, topBarNameStatusSet(EBoardNameStatus.idle)));
    };
    return action;
};
