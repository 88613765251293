import { ITimingTabProps } from './types';
import * as React from 'react';
import { TotalTimingHOC } from '../../hocs/TotalTimingHOC/TotalTimingHOC';
import { UsersTimingHOC } from '../../hocs/UsersTimingHOC/UsersTimingHOC';
import { TabTimingCommentsListHOC } from '../../hocs/TabTimingCommentsListHOC/TabTimingCommentsListHOC';
import './_TimingTab.scss';
import { CLASS_CARD_DETAILS_TAB_TIMING } from './constants';
import { TimeTrackerHOC } from '../../../../../timeTracker/hocs/TimeTrackerHOC/TimeTrackerHOC';

export function TimingTab({
    isShow,
    cardId,
    boardId,
    isShowTimeTracker
}: ITimingTabProps) {
    if (!isShow) return null;
    const classNameComments = CLASS_CARD_DETAILS_TAB_TIMING + '__comments';
    return (
        <div>
            {isShowTimeTracker &&
                <div className={CLASS_CARD_DETAILS_TAB_TIMING + '__tracker'}>
                    <TimeTrackerHOC cardId={cardId} />
                </div>
            }
            <TotalTimingHOC cardId={cardId} boardId={boardId}/>
            <UsersTimingHOC cardId={cardId} boardId={boardId}/>
            <div className={classNameComments}>
                <TabTimingCommentsListHOC cardId={cardId}/>
            </div>
        </div>
    );
}
