import { ISnackbarError } from './types';
import { Dispatch } from '../../../../../types/actions';
import { SnackbarVariant } from '../../store/types';
import { SNACKBAR_BUTTON_CLOSE } from '../constants';
import { getDefaultActionEvent } from '../helpers/getDefaultActionEvent';
import { addUpdateSnackbarWithBubble } from '../addUpdateSnackbarWithBubble/addUpdateSnackbarWithBubble';
import { SNACKBAR_TIMER_ERROR } from '../../hocs/SnackbarHOC/constants';

export const snackbarErrorDefault = (
    options: ISnackbarError
) => {
    return (dispatch: Dispatch) => {
        const shouldUseControlsDefault = options.controls === undefined;
        // use controls=[] for snackbar without buttons
        if (shouldUseControlsDefault) {
            options.controls = [{
                action: () => dispatch(getDefaultActionEvent(options.id)),
                title: SNACKBAR_BUTTON_CLOSE
            }];
        }
        dispatch(addUpdateSnackbarWithBubble({
            id: options.id,
            controls: options.controls,
            text: options.text,
            title: null,
            variant: SnackbarVariant.ERROR,
            position: options.position,
            timer: options.timer || SNACKBAR_TIMER_ERROR,
        }));
    }
};
