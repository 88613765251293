import * as React from 'react';
import { IBoardBackupsSectionProps } from './types';
import { BoardBackupsHOC } from '../../hocs/BoardBackupsHOC/BoardBackupsHOC';

export function BoardBackupsSection ({
    boardId,
    onDidMount
}: IBoardBackupsSectionProps) {
    React.useEffect(() => {
        onDidMount();
    }, []);

    return <BoardBackupsHOC boardId={boardId}/>
};
