import {PTGC_SYNC_CALENDARS} from './../../constants';
import {
    ASYNC_SUCCESS_TYPE,
    ASYNC_FAILURE_TYPE,
    ASYNC_REQUEST_TYPE,
} from './../../../../../../../../store/constants';

export const syncGoogleCalendarsFailure = () => ({
    type: `${PTGC_SYNC_CALENDARS}${ASYNC_FAILURE_TYPE}`
});

export const syncGoogleCalendarsSuccess = googleCalendars => ({
    type: `${PTGC_SYNC_CALENDARS}${ASYNC_SUCCESS_TYPE}`,
    googleCalendars
});
export const syncGoogleCalendarsRequest = () => ({
    type: `${PTGC_SYNC_CALENDARS}${ASYNC_REQUEST_TYPE}`
});