import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState } from '../../../../../../../types/types';
import { root } from '../../../../../../../store/constants';

export const onGetStarted = (): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        root.App.controller.mainView.webinarDialog.remove();
    };
    return action;
};
