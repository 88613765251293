import * as React from 'react';
import './_ModalPage.scss';
import { IModalPageProps } from './types';
import { Body } from '../Body/Body';
import { Header } from '../Header/Header';
import { CLASS_MODAL_PAGE } from '../constants';
import { SELECTOR_MAIN_CONTENT } from '../../../../../../const';
import FocusLock from 'react-focus-lock';

export function ModalPage ({
    children,
    className,
    isLoading,
    title,
    whiteList,
    showBackButton = true,
    rightContent,
    onClickBack,
    onDidMount,
    onDidUnmount,
}: IModalPageProps) {
    const classNameModalPage = CLASS_MODAL_PAGE;

    React.useEffect(() => {
        if (onDidMount) onDidMount();
        const content = document.querySelector(SELECTOR_MAIN_CONTENT) as HTMLElement;
        if (content) content.setAttribute('aria-hidden', 'true');

        return () => {
            if (onDidUnmount) onDidUnmount();
            if (content) content.setAttribute('aria-hidden', 'false');
        }
    }, []);

    return (
        <FocusLock
            returnFocus
            whiteList={whiteList}
        >
            <div
                className={`
                    ${classNameModalPage}
                    ${className}
                `}
                role={'dialog'}
                aria-label={title}
                aria-modal={true}
            >
                <Header
                    showBackButton={showBackButton}
                    rightContent={rightContent}
                    onClickBack={onClickBack}
                >
                    {title}
                </Header>
                <Body isLoading={isLoading}>
                    {children}
                </Body>
            </div>
        </FocusLock>
    );
};
