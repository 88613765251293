import {Dispatch, ThunkAction} from '../../../../../../../../types/actions';
import {IGetState} from '../../../../../../../../types/types';
import {getAuthUser} from '../../../../../../../../store/model/authUser/selectors/getAuthUser';
import {SegmentCardEvent, segmentTrackAction} from '../../../../../../../../middlewares/segment';
import {getAuthUserIsAttachmentViewTypeGrid} from '../../../../../../../../store/model/authUser/selectors/getAuthUserIsAttachmentViewTypeGrid';
import {authUserSetIsAttachmentViewTypeGrid} from '../../../../../../../../rest/effects/authUser/authUserSetIsAttachmentViewTypeGrid';
import { isPaidUser } from '../../../../../../../../store/model/authUser/selectors/isPaidUser';

export const onToggleAttachmentViewType = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const user = getAuthUser(state);
        const isAttachmentViewTypeGrid = getAuthUserIsAttachmentViewTypeGrid(state);
        const event = isAttachmentViewTypeGrid ? SegmentCardEvent.ATTACHMENT_VIEW_TYPE_LIST : SegmentCardEvent.ATTACHMENT_VIEW_TYPE_GRID;
        dispatch(segmentTrackAction(event));
        if (isPaidUser(state)) {
            const event = isAttachmentViewTypeGrid ? SegmentCardEvent.ATTACHMENT_VIEW_TYPE_LIST_PAID : SegmentCardEvent.ATTACHMENT_VIEW_TYPE_GRID_PAID;
            dispatch(segmentTrackAction(event));
        }

        return dispatch(authUserSetIsAttachmentViewTypeGrid(user.id, !isAttachmentViewTypeGrid));
    };
    return action;
};
