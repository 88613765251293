import { ThunkAction } from '../../../../../../../../types/actions';
import { TBoardId, TCardId } from '../../../../../../../../types/types';
import { TCommentId } from '../../../../../../../../store/model/card/types/IComment';
import { commentUpdateMeta } from '../../../../../../../../rest/effects/card/comments/commentUpdateMeta';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { getCommentById } from '../../../selectors/getCommentById';
import { getAuthUser } from '../../../../../../../../store/model/authUser/selectors/getAuthUser';

export const onClick = (
    boardId: TBoardId,
    cardId: TCardId,
    commentId: TCommentId
): ThunkAction => {
    return (
        dispatch,
        getState
    ) => {
        const state = getState();
        const { permissionId } = getAuthUser(state);
        const comment = getCommentById(state, cardId, commentId);
        let likes = comment.meta && comment.meta.likes || [];
        const idx = likes.indexOf(permissionId);
        if (idx > -1) {
            likes = likes.filter(id => id !== permissionId)
        } else {
            likes = [...likes, permissionId];
        }
        dispatch(commentUpdateMeta({ likes }, boardId, cardId, commentId));
        dispatch(segmentTrackAction(SegmentCardEvent.COMMENT_LIKE_CLICKED));
    }
}
