import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { TCardId } from '../../../../../../../../../types/types';
import { popupStatusForCardUpdate } from '../../../state/effects/popupStatusForCardUpdate';

export const onCancel: ActionCreator<ThunkAction> = (
    cardId: TCardId,
) => {
    const action = (
        dispatch: Dispatch,
    ) => {
        dispatch(popupStatusForCardUpdate(cardId, false));
    };
    return action;
};
