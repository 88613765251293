import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { root } from 'app/store/constants';
import { startTransition } from 'react';

export const onClickBackToSite = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        startTransition(() => {
            root.App.controller.showSplashScreen()
        })
        //setTimeout добавлен, что бы не показывалось основное приложение
        setTimeout(()=> {
            const modal = document.querySelector('.kanbanchi-free-trial-form-placeholder') as HTMLElement;
            if (modal) {
                modal.parentNode.removeChild(modal);
            }
        }, 500)
    };
    return action;
};
