import * as React from 'react';
import {
    CARD_ESTIMATE_EXAMPLE,
    CARD_ESTIMATE_LABEL_TEXT,
    CARD_ESTIMATE_REMOVE_TOOLTIP,
} from '../CardEstimateAndSpentTimeSection/constants';
import { ICardEstimateProps } from './types';
import { Input } from 'kui';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { CLASS_CARD_DETAILS } from '../../../constants';
import { getAsidePanelTooltip } from '../../../../asidePanel/helpers/getAsidePanelTooltip';
import { parseEstimate } from '../CardEstimateAndSpentTimeSection/behaviour/parseEstimate';

export function CardEstimate ({
    estimateStr,
    isRequired,
    sectionType,
    workHoursInDay,
    onEstimateChange,
    onError
}: ICardEstimateProps) {
    const isPopup = sectionType === ESettingsSectionType.POPUP;
    const isReadonly = sectionType === ESettingsSectionType.READONLY;
    const CLASS_CARD_DETAILS_TIMES = CLASS_CARD_DETAILS + '-times';
    const classNameEstimate = CLASS_CARD_DETAILS_TIMES + '__estimate';
    const classNameRequired = classNameEstimate + '--required';
    const classNameError = classNameEstimate + '--error';
    const labelRef = React.useRef(null);
    const [inputState, setInputState] = React.useState(null);
    const [showValid, setShowValid] = React.useState(null);
    const [estimateHook, setEstimateHook] = React.useState(estimateStr);

    const getSyntheticEventTargetValue = (
        e: React.SyntheticEvent,
    ): string => {
        const target = e.target as HTMLInputElement;
        return target.value || ''
    };

    const isEventTargetValueValid = (
        e: React.SyntheticEvent,
    ): boolean => {
        const targetValue = getSyntheticEventTargetValue(e);
        const isValid = targetValue.trim().length === 0 || parseEstimate(targetValue, workHoursInDay) > 0;
        return isRequired ? targetValue.length > 0 && isValid : isValid;
    };

    const handleChange = (
        e: React.SyntheticEvent,
    ) => {
        setEstimateHook(getSyntheticEventTargetValue(e));
        if (showValid) {
            const isValid = isEventTargetValueValid(e);
            const inputStateStr = isValid ? 'success' : 'error';
            setInputState(inputStateStr);
        } else {
            setInputState(null);
        }
    };

    function onKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Escape') {
            event.stopPropagation(); // to avoid the aside-panel closure on esc if input is in focus
            setEstimateHook(estimateStr);
            blurHandle();
        }
    }

    function blurHandle() {
        if (!labelRef.current) return;

        const input = labelRef.current.getInput();
        if (!input) return;

        input.closest(`[tabindex]`).focus();
    }

    const handleEnter = (
        e: React.SyntheticEvent,
        blur?: any, // on Enter
    ) => {
        const targetValue = getSyntheticEventTargetValue(e);
        const isValid = isEventTargetValueValid(e);
        setShowValid(!isValid);
        if (!isValid) {
            setInputState('error');
        } else {
            setInputState(null);
            const estimateInMinutes = parseEstimate(targetValue, workHoursInDay);
            onEstimateChange(estimateInMinutes);
            if (blur) blur();
        }
    };

    React.useEffect(() => {
        if (inputState === 'error') {
            onError();
        }
    }, [inputState]);

    React.useEffect(() => {
        setEstimateHook(estimateStr);
    }, [estimateStr]);

    return (
        <Input
            autoFocus={isPopup}
            className={`
                ${classNameEstimate}
                ${isRequired ? classNameRequired : ''}
                ${!estimateStr || !estimateStr.length ? classNameError : ''}
            `}
            isClearable={true}
            iconTooltip={
                getAsidePanelTooltip({
                    direction: 'up-left',
                    value: CARD_ESTIMATE_REMOVE_TOOLTIP,
                })
            }
            label={ CARD_ESTIMATE_LABEL_TEXT }
            tooltip={
                getAsidePanelTooltip({
                    direction: 'up-right',
                    value: CARD_ESTIMATE_EXAMPLE,
                })
            }
            readOnly={ isReadonly }
            autosize={ false }
            state={ inputState }
            ref={ labelRef }
            placeholder={ CARD_ESTIMATE_EXAMPLE }
            onChange={ handleChange }
            onEnter={(e) => handleEnter(e, blurHandle) }
            onBlur={ handleEnter }
            value={ estimateHook }
            onKeyDown = {onKeyDown}
        />
    );
}
