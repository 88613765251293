import { EProcessingStatus } from '../../../../../types/rest/IUserMeta';
import { READY_TO_GO_SECS } from '../../../../../const';
import { TStatus } from '../../../../../types/model';

export const getMyTaskCardProcessingStatus = (
    startDate: number,
    dueDate: number,
    processingPercent: number
): EProcessingStatus => {
    const now = Math.floor(Date.now() / 1000) ;
    const done = processingPercent === 100;
    const startOrDueDate = startDate || dueDate;
    if (done) {
        return EProcessingStatus.done;
    } else if (dueDate && dueDate < now) {
        return EProcessingStatus.overdue
    } else if (
        (startDate && startDate < now) ||
        processingPercent > 0
    ) {
        return EProcessingStatus.inProgress
    } else if (
        startOrDueDate && (startOrDueDate - now) < READY_TO_GO_SECS
    ) {
        return EProcessingStatus.readyToGo
    } else if (!startOrDueDate) {
        return EProcessingStatus.noDates
    }
    return EProcessingStatus.upcoming;
};
