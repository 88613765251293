import {Dispatch, ThunkAction} from '../../../../../../../types/actions';
import {root} from '../../../../../../../store/constants';
import {boardImportBoardTrelloClickedSegmentEvent} from '../../../../../main/navigationPanel/effects/segmentEvents';
import {isContinuedReadOnly} from '../../../../../base/selectors/isContinuedReadOnly';
import {snackbarUpgradeDefault} from '../../../../../snackbarsStack';
import {importTrello} from '../../../../openBoards/FooterImport/hocs/FooterImportHOC/effects/importTrello';
import {OPEN_BOARDS_DIALOG_CLOSE} from '../constants';

export const onClickTrello = (): ThunkAction => {
    const action = (dispatch: Dispatch) => {
        root.App.vent.trigger(root.App.vent[OPEN_BOARDS_DIALOG_CLOSE]);
        dispatch(boardImportBoardTrelloClickedSegmentEvent());
        if (isContinuedReadOnly()) {
            dispatch(snackbarUpgradeDefault());
        } else {
            dispatch(importTrello());
        }
    };
    return action;
};
