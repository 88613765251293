import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { segmentTrackAction, SegmentUserEvent } from '../../../../../../../middlewares/segment';
import { ActionCreator } from 'redux';
import { IGetState } from '../../../../../../../types/types';
import { showSet } from '../../../store/actions/showSet';
import { processPerformanceAllow } from '../../../effects/processPerformanceAllow';
import { newPerformanceAllowedValueSet } from '../../../store/actions/newPerformanceAllowedValueSet';
import { root } from '../../../../../../../store/constants';
import { getUserPromoCode } from '../../../../../../../store/model/authUser/selectors/getUserPromoCode';
import { getUserPromoCodeShowed } from '../../../../../../../store/model/authUser/selectors/getUserPromoCodeShowed';
import {
    getUserManagedSubscriptions
} from '../../../../../../../store/model/authUser/selectors/getUserManagedSubscriptions';
import { getAuthUser } from '../../../../../../../store/model/authUser/selectors/getAuthUser';

export const onAcceptCookie: ActionCreator<ThunkAction> = (
    isPerformanceAllowed: boolean
) => {
    const action = (dispatch: Dispatch, getState: IGetState) => {
        const state = getState();

        dispatch(segmentTrackAction(SegmentUserEvent.COOKIE_ACCEPT));

        dispatch(newPerformanceAllowedValueSet(isPerformanceAllowed));
        dispatch(processPerformanceAllow());

        dispatch(showSet(false));

        if (!getAuthUser(state).anonym && !getUserPromoCodeShowed(state) && getUserPromoCode(state) && !getUserManagedSubscriptions(state).length) {
            root.App.controller.renderPromoCodePopup();
        }
    };
    return action;
};
