import { IGetState } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { EFeature } from '../../../../../../../../types/rest/IUserMeta';
import {
    authUserAddFeatureToSeenMeta
} from '../../../../../../../../rest/effects/authUser/authUserAddFeatureToSeenMeta';

export const onHideTips = (): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(authUserAddFeatureToSeenMeta(EFeature.PASTE_ATTACHMENT));
    };
    return action;
};
