import { IAutoBackupsActionSetAction } from './types';
import { AT_SET_ACTION } from './constants';
import { TBoardId } from '../../../../../../../../../types/types';
import { TAutoBackupAction } from '../../autoBackup/actions/types';
import {TAutoBackupsAction} from '../../autoBackups/actions/types';

export const autoBackupsBoardActionSet = (
    boardId: TBoardId,
    autoBackupsAction: TAutoBackupsAction
): IAutoBackupsActionSetAction => ({
    type: AT_SET_ACTION,
    boardId,
    autoBackupsAction
});
