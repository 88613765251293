import * as React from 'react';
import './_EmailNotifications.scss';
import { IEmailNotificationsProps } from './types';
import { Checkbox, ISelectActiveInheritedProps, Select } from 'kui';
import { BOARD_NOTIFICATION_UPDATES, periodSelectOptions } from './constants';
import {
    BOARD_NOTIFICATION_SCOPE_ALL,
    BOARD_NOTIFICATION_SCOPE_ASSIGNED,
    BOARD_NOTIFICATION_SCOPE_WATCH
} from '../constants';
import { updateScopeList } from '../helpers/updateScopeList';
import { ENotificationScope } from '../../../../../../../../types/rest/IRestNotificationSettings';
import { CLASS_BOARD_DETAILS } from '../../../../constants';

export function EmailNotifications ({
    email,
    isEnabled,
    isReadonly,
    period,
    scopes,
    onPeriodChange,
    onPeriodSelectOpen,
    onScopeChanges,
    onScopeClick,
}: IEmailNotificationsProps) {
    if (!isEnabled) return null;

    const [scopeList, setScopeList] = React.useState(scopes);

    const className = CLASS_BOARD_DETAILS + '__email-notifications';
    const classEmail = CLASS_BOARD_DETAILS  + '__email-email';
    const classUpdates = CLASS_BOARD_DETAILS  + '__email-upadtes';

    const onScopeChangeHandler = (scope: ENotificationScope) => {
        const list = updateScopeList<ENotificationScope>(scope, scopeList);
        onScopeClick(scope);
        onScopeChanges(list);
    }

    const onPeriodChangeHandler = (event: ISelectActiveInheritedProps) => {
        onPeriodChange(Number(event.item.value));
    }

    React.useEffect(() => {
        setScopeList(scopes);
    }, [scopes]);

    return (
        <div
            className={className}
        >
            <div
                className={classEmail}
            >
                {email}
            </div>
            <Select
                active={periodSelectOptions.findIndex(opt => opt.value === period)}
                disabled={isReadonly}
                options={periodSelectOptions}
                variant={'arrow'}
                onOpen={onPeriodSelectOpen}
                onChange={onPeriodChangeHandler}
            />
            <div
                className={classUpdates}
            >
                {BOARD_NOTIFICATION_UPDATES}
            </div>
            <Checkbox
                checked={scopeList.includes(ENotificationScope.ALL)}
                disabled={isReadonly}
                value={ENotificationScope.ALL}
                onChange={() => onScopeChangeHandler(ENotificationScope.ALL)}
            >
                {BOARD_NOTIFICATION_SCOPE_ALL}
            </Checkbox>
            <Checkbox
                checked={scopeList.includes(ENotificationScope.ASSIGN_TO_ME)}
                disabled={isReadonly}
                value={ENotificationScope.ASSIGN_TO_ME}
                onChange={() => onScopeChangeHandler(ENotificationScope.ASSIGN_TO_ME)}
            >
                {BOARD_NOTIFICATION_SCOPE_ASSIGNED}
            </Checkbox>
            <Checkbox
                checked={scopeList.includes(ENotificationScope.WATCH)}
                disabled={isReadonly}
                value={ENotificationScope.WATCH}
                onChange={() => onScopeChangeHandler(ENotificationScope.WATCH)}
            >
                {BOARD_NOTIFICATION_SCOPE_WATCH}
            </Checkbox>
        </div>
    );
};
