import { IApplicationState, TCardId } from '../../../types/types';
import { IChecklistStats } from '../../../types/model';
import { getCardChecklists } from '../checklists/checklists/selectors/getCardChecklists';
import {ICardChecklists} from 'app/store/model/checklists/checklists/types';

export const calcCardChecklistStatsByCardId = (
    state: IApplicationState,
    cardId: TCardId,
): IChecklistStats => {
    const checklists = getCardChecklists(state, cardId);
    return calculateChecklistsStats(checklists);
}

export const calculateChecklistsStats = (
    checklists: ICardChecklists
) => {
    const stats: IChecklistStats = {
        checkedCount: 0,
        checkedPercent: 0,
        totalCount: 0,
        estimate: 0,
    };
    let weightTotal = 0;
    let weightChecked = 0;
    for (let checklistId in checklists) {
        const checklist = checklists[checklistId];
        if (checklist.checkItems) for (let itemId in checklist.checkItems) {
            stats.totalCount++;
            const item = checklist.checkItems[itemId];
            weightTotal += item.weight;
            if (item.checked) {
                stats.checkedCount++;
                weightChecked += item.weight;
            }
            stats.estimate += item.estimate || 0;
        }
    }
    if (weightTotal) stats.checkedPercent = Math.round(weightChecked * 100 / weightTotal);

    return stats;
}
