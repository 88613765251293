import { connect } from 'react-redux';
import { ITabActivityHOCProps } from './types';
import { TabActivity } from '../../components/TabActivity/TabActivity';
import { ITabActivityEvents, ITabActivityFields } from '../../components/TabActivity/types';
import { fetchActivities } from './effects/fetchActivities';
import { IApplicationState } from '../../../../../../../../types/types';
import { onOpenCard } from './events/onOpenCard';
import { onOpenList } from './events/onOpenList';
import { getActivityGroupsByDayCreateSelector } from './selectors/getActivityGroupsByDay';
import { getAuthUser } from '../../../../../../../../store/model/authUser/selectors/getAuthUser';
import { onScrollToList } from './events/onScrollToList';
import { getAsidePanelWidth } from '../../../../../asidePanel/store/selectors/getAsidePanelWidth';
import {
    getCardLastClickedActivityId
} from '../../../../../cardDetails/TabActivity/store/selectors/getCardLastClickedActivityId';
import { resetLastClickedActivity } from './effects/resetLastClickedActivity';

const mapStateToProps = () => {
    const getActivityGroups = getActivityGroupsByDayCreateSelector();

    const _mapStateToProps = (
        state: IApplicationState,
        { objectId, fetchOptions }: ITabActivityHOCProps
    ): ITabActivityFields => {
        const { permissionId } = getAuthUser(state);
        return {
            activityGroups: getActivityGroups(state),
            asidePanelWidth: getAsidePanelWidth(state),
            lastClickedActivityId: getCardLastClickedActivityId(state),
            objectId,
            fetchOptions,
            userId: permissionId
        }
    };

    return _mapStateToProps;
};

const mapDispatchToProps = (
    dispatch: any,
    { objectId, url }: ITabActivityHOCProps
): ITabActivityEvents => {
    return {
        fetchActivities: (cursor, options, initActivities) => dispatch(fetchActivities(objectId, url, cursor, options, initActivities)),
        onOpenCard: (cardId, activityId, scrollSection) => dispatch(onOpenCard(cardId, activityId, scrollSection)),
        onOpenList: (listId) => dispatch(onOpenList(listId)),
        onScrollToList: (listId) => dispatch(onScrollToList(listId)),
        resetLastClickedActivity: () => dispatch(resetLastClickedActivity())
    }
};

export const TabActivityHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(TabActivity);

TabActivityHOC.displayName = 'TabActivityHOC';
