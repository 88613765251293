import { getKanbanTitle } from '../selectors/getKanbanTitle';
import { Dispatch } from '../../../../../../types/actions';
import { IGetState } from '../../../../../../types/types';

export const updateKanbanTitle = (
) => {
    return (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const kanbanTitle = getKanbanTitle(getState());
        document.title = kanbanTitle;
    };
};
