import * as React from 'react';
import './_FilterGroupBy.scss';
import { IFilterGroupByProps } from './types';
import { Button, ButtonDropdown, Icon, SelectList, SelectListItem } from 'kui';
import { MY_WORK_GROUP_BY_LABEL, MY_WORK_GROUP_BY_OPTIONS } from './constatns';
import { CLASS_MY_WORK } from '../../../../../main/myWorkView/constants';
import { ProFeatureIndicator } from '../../../ProFeatureIndicator/ProFeatureIndicator';
import { EMyWorkGroupType } from '../../../../../aside_panel/filterPanelBoard/store/filterPanel/types';
import { BasicFeatureIndicator } from '../../../BasicFeatureIndicator/BasicFeatureIndicator';

export function FilterGroupBy({
    isBasicFeature,
    isProFeature,
    value,
    onSelect
}: IFilterGroupByProps) {
    const className = CLASS_MY_WORK + '__filter-bar-filter-group-by';
    const classOpen = className + '--open';
    const classButton = className + '-button';
    const classArrow = classButton + '-arrow';

    const [isOpen, setOpen] = React.useState(false);

    const onOpen = () => setOpen(true);
    const onClose = () => setOpen(false);

    const active = MY_WORK_GROUP_BY_OPTIONS.findIndex(item => item.value === value);

    return (
        <ButtonDropdown
            opened={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            directionHorizontal={'right'}
            dropdownClassName={className}
        >
            <Button
                className={`
                    ${classButton}
                    ${isOpen ? classOpen : ''}
                `}
                variant={'icon-text'}
                text={MY_WORK_GROUP_BY_LABEL + MY_WORK_GROUP_BY_OPTIONS[active].value}
            >
                <Icon xlink={MY_WORK_GROUP_BY_OPTIONS[active].icon} size={24}/>
                <Icon xlink={'arrow-down'} size={24} className={classArrow}/>
            </Button>
            <SelectList active={active}>
                {
                    MY_WORK_GROUP_BY_OPTIONS.map(option => {
                        const { icon, value } = option;
                        return (
                            <SelectListItem
                                key={value}
                                icon={icon}
                                iconSize={24}
                                value={value}
                                onClick={() => onSelect(value)}
                            >
                                <>
                                    {value}
                                    {isProFeature && value !== EMyWorkGroupType.NONE && <ProFeatureIndicator />}
                                    {isBasicFeature && <BasicFeatureIndicator />}
                                </>
                            </SelectListItem>
                        );
                    })
                }
            </SelectList>
        </ButtonDropdown>
    );
}
