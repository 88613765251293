import { IGetState, TBoardId, TCardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { getBoard } from '../../../../store/model/selectors/getBoardById';
import { cardsDeleteWithPrompt } from './cardsDeleteWithPrompt';
import { closePanel } from '../../aside_panel/asidePanel/effects/closePanel';
import { IAuthUser } from '../../../../store/model/authUser/types';
import { getAuthUser } from '../../../../store/model/authUser/selectors/getAuthUser';
import { EViewTypes, VIEW_TYPES_OTHER } from '../../../../const';
import { getTypeSwitcherActiveType } from '../../typeSwitcher/store/selectors/getTypeSwitcherActiveType';
import { getCard } from '../../../../store/model/selectors/getCard';
import { TStatus } from '../../../../types/model';
import { cardsArchiveWithPrompts } from './cardsArchiveWithPrompts';

export const cardsDeleteOrArchive = (
    boardId: TBoardId,
    cardIds: TCardId[] = [],
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const board = getBoard(state, boardId);
        const user: IAuthUser = getAuthUser(state);
        const viewType: EViewTypes = getTypeSwitcherActiveType(state, board.id);
        const viewTypesAllowArchive = [
            EViewTypes.KANBAN_VIEW_TYPE,
            EViewTypes.LIST_VIEW_TYPE,
            EViewTypes.GANTT_VIEW_TYPE,
            ...VIEW_TYPES_OTHER
        ];
        let i = 0;
        let recurringTasksCount: number = null;
        while (
            recurringTasksCount === null &&
            i < cardIds.length
            ) {
            const card = getCard(state, cardIds[i]);
            if (card.status === TStatus.STATUS_SERVICE_TEMPLATE) {
                recurringTasksCount = card.recurringTasks.length;
            }
            i++;
        }

        if (
            !viewTypesAllowArchive.includes(viewType) ||
            !board ||
            !board.archiveCardsOnDelete ||
            !user.allowArchive ||
            recurringTasksCount !== null
        ) {
            return dispatch(cardsDeleteWithPrompt(cardIds, recurringTasksCount));
        }
        dispatch(closePanel());
        dispatch(cardsArchiveWithPrompts(cardIds));
    };
    return action;
};
