import * as React from 'react';
import './_ViewOnly.scss';
import { Icon, Tooltip } from 'kui';
import { IViewOnlyProps } from './types';
import { EShareMode } from 'app/types/types';
import {
    COMMENT_ONLY_TITLE,
    MIN_WIDTH_DISPLAY_COMMENT_TITLE,
    MIN_WIDTH_DISPLAY_VIEW_TITLE,
    VIEW_ONLY_TITLE
} from './constants';

export function ViewOnly ({
    isMini,
    asidePanelWidth,
    mode,
    onClick
}: IViewOnlyProps) {
    if (!mode || mode === EShareMode.EDIT) return null;

    const [isMiniView, setIsMiniView] = React.useState(isMini);

    const className = 'view-only';
    let icon = 'eye';
    let text = VIEW_ONLY_TITLE;

    if (mode === EShareMode.COMMENT) {
        icon = 'comment';
        text = COMMENT_ONLY_TITLE;
    }

    React.useEffect(() => {
        if (asidePanelWidth < MIN_WIDTH_DISPLAY_VIEW_TITLE && mode !== EShareMode.COMMENT) {
            setIsMiniView(true)
        } else if (asidePanelWidth < MIN_WIDTH_DISPLAY_COMMENT_TITLE && mode === EShareMode.COMMENT) {
            setIsMiniView(true)
        } else {
            setIsMiniView(false)
        }
    }, [asidePanelWidth]);

    return (
        <Tooltip
            className={`${className}__tooltip`}
            direction={'down'}
            isNoWrap
            value={isMiniView ? text : ''}
        >
            <div className={`${className} ${isMiniView ? className + '--mini' : ''}`}
                 onClick={onClick}>
                <Icon className={`${className}__icon`} size={24} xlink={icon} />
                <div className={`${className}__text`}>
                    {text}
                </div>
            </div>
        </Tooltip>
    )
};
