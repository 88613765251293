import {IUpgradePlanButtonProps} from './types';
import {Button, Hint, Icon, Tooltip} from 'kui';
import * as React from 'react';
import './_UpgradePlanButton.scss';
import {UPGRADE_BUTTON_SALE_HINT_VALUE, UPGRADE_BUTTON_SALE_TOOLTIP_VALUE} from '../../constants';
import { FreeVersionButtonHOC } from '../../hocs/FreeVersionButtonHOC/FreeVersionButtonHOC';
import { getProTimeLeft } from './helpers/getProTimeLeft';
import ReactHtmlParser from 'react-html-parser';

export function UpgradePlanButton({
    isFreeVersion,
    buttonText,
    timerStartDate,
    timerDuration,
    isShowSale,
    isShown,
    isShowPromoCode,
    promoCodeText,
    upgradeMessage,
    onClick,
    onTimerEnd,
    onClickGet,
    onClickSale,
}: IUpgradePlanButtonProps) {
    if (!isShown) return null;

    if (isFreeVersion) return (
        <FreeVersionButtonHOC />
    );

    let [time, setTime] = React.useState(getProTimeLeft(timerStartDate, timerDuration));
    const timerRef = React.useRef(null);

    React.useEffect(() => {
        timerRef.current = setInterval(() => {
            if (timerStartDate && Date.now() - timerStartDate >= timerDuration) {
                onTimerEnd();
                clearInterval(timerRef.current);
            } else {
                setTime(getProTimeLeft(timerStartDate, timerDuration));
            }
        }, 1000);

        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        }
    }, []);

    const infoIcon = (
        <Tooltip
            value={UPGRADE_BUTTON_SALE_TOOLTIP_VALUE}
            direction={'up-left'}
        >
            <Icon xlink={'info'} size={24}/>
        </Tooltip>
    );

    const button = (
        <Button
            className={`upgrade__button ${isShowPromoCode ? 'upgrade__button--purple' : ''}`}
            variant={'primary'}
            onClick={onClick}
        >
            {isShowPromoCode ? promoCodeText : buttonText}
        </Button>
    );
    let containerClass = 'upgrade__container';
    if (isShowSale) containerClass += ' ' + containerClass + '--sale';

    return (
        <Hint
            arrow={'left'}
            className={'upgrade-button-sale-hint'}
            direction={'right'}
            footer={infoIcon}
            isHidable={false}
            isPortal={false}
            onHide={onClick}
            show={isShowSale}
            state={'error'}
            value={UPGRADE_BUTTON_SALE_HINT_VALUE}
        >
            <div className={containerClass}>
                {(timerDuration || upgradeMessage) &&
                    <div className={'upgrade__left'}>
                        {timerDuration &&
                            <span className={'upgrade__timer'}>
                        {time}
                        </span>
                        }
                        {!!upgradeMessage &&
                            <span
                                className={`
                                    upgrade__text
                                    ${!timerDuration ? 'upgrade__text--hide' : ''}
                                `}
                            >
                                {ReactHtmlParser(upgradeMessage)}
                            </span>
                        }
                    </div>
                }
                {button}
            </div>
        </Hint>
    );
};
