import { DependencyAdd } from '../DependencyAdd/DependencyAdd';
import { DEPENDENCY_SEARCH_PLACEHOLDER } from './constants';
import * as React from 'react';
import { IDependenciesSearchSelectProps } from './types';
import { SearchSelect } from '../../../../../base/components/SearchSelect/SearchSelect';

export function DependenciesSearchSelect ({
    availableCards,
    isOpened,
    isPopup,
    onAdd,
    onAddClosed,
}: IDependenciesSearchSelectProps) {
    return (
        <SearchSelect
            isCloseOnClick={false}
            isCloseOnEnter={false}
            isFitWindow={ isPopup }
            isDropdownUplifted={!isPopup}
            opened={ isOpened }
            options={ availableCards }
            option={ DependencyAdd }
            placeholder={ DEPENDENCY_SEARCH_PLACEHOLDER }
            onBlur={ onAddClosed }
            onClosed={ onAddClosed }
            onSelect={ (cardId) => onAdd(Number(cardId)) }
        />
    )
}
