import { Switch } from 'kui';
import * as React from 'react';
import { CLASS_CARD_DETAILS } from '../../../constants';
import { CARD_SUBCARDS_HIDE_DONE } from './constants';
import { ICardSubcardsHideDoneButton } from './types';
import './_CardSubcardHideDoneButton.scss';

export function CardSubcardHideDoneButton({
    isHideDoneSubcards,
    onChange
}: ICardSubcardsHideDoneButton) {
    const className = CLASS_CARD_DETAILS + '__subcards-hide-done-button';

    return (
        <Switch
            className={className}
            checked={isHideDoneSubcards}
            onChange={onChange}
        >
            {CARD_SUBCARDS_HIDE_DONE}
        </Switch>
    );
}
