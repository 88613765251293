import { IGetState, TCardId, TChecklistId, TChecklistItemId, TPermissionId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { isCardChecklistUserExist } from '../../../../../../../../store/model/selectors/isCardChecklistUserExist';
import { checklistItemDeleteUser } from '../../../../../../../../rest/effects/card/checklist/checklistItemDeleteUser';
import { checklistItemAddUser } from '../../../../../../../../rest/effects/card/checklist/checklistItemAddUser';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { getChecklistItem } from '../../../../../../../../store/model/checklists/checklist/selectors/getChecklistItem';
import {
    cardToggleAssignee,
    EToggleAssigneeType
} from '../../../../AssigneesSection/hocs/AssigneesSectionHOC/effects/cardToggleAssignee';

export const toggleChecklistItemUser = (
    boardId: number,
    cardId: TCardId,
    checklistId: TChecklistId,
    itemId: TChecklistItemId,
    permissionId: TPermissionId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        let item = getChecklistItem(state, cardId, checklistId, itemId);
        if (!item) return Promise.reject();

        const index = (item.assignees || []).findIndex(user => user.sharedUser.permissionId === permissionId);
        if (index < 0) {
            dispatch(checklistItemAddUser(boardId, cardId, checklistId, itemId, permissionId));
            dispatch(cardToggleAssignee(boardId, cardId, permissionId, EToggleAssigneeType.ADD, false));
            dispatch(segmentTrackAction(SegmentCardEvent.ASSIGNED_USER));
            dispatch(segmentTrackAction(SegmentCardEvent.CHECKLIST_ITEM_USER_ASSIGNED));
        } else {
            dispatch(checklistItemDeleteUser(cardId, checklistId, itemId, permissionId));
            if (!isCardChecklistUserExist(state, cardId, permissionId, [itemId])) {
                dispatch(segmentTrackAction(SegmentCardEvent.UNASSIGNED_USER));
                dispatch(cardToggleAssignee(boardId, cardId, permissionId, EToggleAssigneeType.DELETE, false));
            }
            dispatch(segmentTrackAction(SegmentCardEvent.CHECKLIST_ITEM_USER_UNASSIGNED));
        }
    };
    return action;
};
