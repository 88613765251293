import {IApplicationState} from '../../../types/types';
import {getActiveBoardId} from './getActiveBoardId';
import { IBoard } from '../board/types';

export const getActiveBoard = (
    state: IApplicationState
): IBoard => {
    const activeBoardId = getActiveBoardId(state);
    return state.model.dashboards[activeBoardId];
};
