import { Dispatch, ThunkAction } from '../../../../../../types/actions';
import { IGetState } from '../../../../../../types/types';
import { copyRest } from '../copyRest';
import { IDependency } from '../../../../../../store/model/dependencies/dependency/types';

export const dependenciesCopy = (
    dependencies: IDependency[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(copyRest(dependencies))
    };
    return action;
};
