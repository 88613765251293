import * as React from 'react';
import { Input } from 'kui';
import { IQualificationDialogAnswersPhoneProps } from './types';
import { QUALIFICATION_QUESTIONS_PHONE_PROMPT } from './constants';

export function QualificationDialogAnswersPhone ({
    onChange
}: IQualificationDialogAnswersPhoneProps) {

    const [text, setText] = React.useState('');
    React.useEffect(() => {
        onChange('', false);
        return () => {
            onChange('', false);
        }
    }, []);

    const onInputChangeHandler = (e: React.SyntheticEvent) => {
        const { value } = (e.target as HTMLInputElement);
        setText(value);
        const showNextButton: boolean = /[0-9]/.test(value);
        if (onChange) {
            onChange(value, showNextButton);
        }
    }

    return (
        <div>
            <Input
                placeholder={QUALIFICATION_QUESTIONS_PHONE_PROMPT}
                multiple={false}
                className={'edit-saved-filter__input edit-saved-filter__input-phone'}
                autosize={ false }
                onChange={onInputChangeHandler}
                value={text}
            />
        </div>
    );
}
