import { INotificationBeforeDueDate } from '../../../../../../../../../store/model/notificationBeforeDueDate/types';

export const AT_NBD_POPUP_SET = 'NBD_POPUP_SET';
export const AT_NBD_NOTIFICATIONS_SET = 'NBD_NOTIFICATIONS_SET';

export interface IPopupSetAction {
    type: typeof AT_NBD_POPUP_SET;
    isOpen: boolean;
}

export interface INotificationSetAction {
    type: typeof AT_NBD_NOTIFICATIONS_SET;
    notifications: INotificationBeforeDueDate[];
}

export type TNotificationBeforeDueDateEditAction = (
    IPopupSetAction |
    INotificationSetAction
);
