import * as React from 'react';
import { ICardDetailsTag, ICardTagsElementsProps } from './types';
import './_CardTagsElements.scss';
import { Button, Icon, Tag, Tags } from 'kui';
import { CLASS_CARD_DETAILS } from '../../../constants';
import { Sortable } from '@shopify/draggable';
import { getSortable } from '../../../../../base/components/SettingsSection/helpers/sortableHelper';
import { TTag } from '../../../../../../../types/types';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { getAsidePanelTooltip } from '../../../../asidePanel/helpers/getAsidePanelTooltip';
import { TAG_EDIT_ICON_TOOLTIP, TAG_REMOVE_ICON_TOOLTIP } from './constants';
import { CardTagEdit } from '../CardTagEdit/CardTagEdit';
import { markdown } from '../../../../../../../helper/markdownHelper';
import ReactHtmlParser from 'react-html-parser';

export function CardTagsElements({
    tags,
    sectionType,
    onDelete,
    onSort,
    onRename,
    onRenameClicked,
}: ICardTagsElementsProps) {
    if (!tags || !tags.length) return null;

    const [editTagId, setEditTagId] = React.useState(null);

    const isReadonly = sectionType === ESettingsSectionType.READONLY;
    const CLASS_CARD_TAGS = CLASS_CARD_DETAILS + '-tags';
    const CLASS_CARD_TAGS_CONTAINER = CLASS_CARD_TAGS + ' .kui-tags__inner';
    const CLASS_CARD_TAGS_ITEM = CLASS_CARD_DETAILS + '-tags__item';

    let sortableElements: Sortable = null;
    function onSortHandler(tag: TTag, tags: TTag[]) {
        onSort(tag, tags);
    }
    function sortableInit() {
        sortableDestroy();
        requestAnimationFrame(() => {
            sortableElements = getSortable({
                container: CLASS_CARD_TAGS_CONTAINER,
                draggable: CLASS_CARD_TAGS_ITEM,
                onSort: onSortHandler,
                sortableInit,
                mirror: {
                    constrainDimensions: true
                }
            });
        });
    }
    function sortableDestroy() {
        if (sortableElements) sortableElements.destroy();
    }
    React.useEffect(() => {
        if (sectionType === ESettingsSectionType.READONLY) {
            sortableDestroy();
        } else {
            sortableInit();
        }

        return () => sortableDestroy();
    }, [sectionType]);

    const onRenameClickHandler = (
        id: TTag
    ) => {
        setEditTagId(id);
        onRenameClicked();
    }

    return (<Tags className={CLASS_CARD_TAGS}>
        {tags.map((element: ICardDetailsTag) => {
            const actions = isReadonly ? {} : {
                onClick: () => {},
                onClear: () => onDelete(element.id)
            }
            return (
                editTagId === element.id
                    ? <CardTagEdit
                        key={element.id}
                        tag={element}
                        onSave={onRename}
                        onClose={() => setEditTagId(null)}
                    />
                    : <Tag
                        className={element.isLoading ? '' : CLASS_CARD_TAGS_ITEM}
                        data-id={element.id}
                        key={element.id}
                        iconTooltip={
                            getAsidePanelTooltip({
                                direction: 'up-left',
                                value: TAG_REMOVE_ICON_TOOLTIP
                            })
                        }
                        {...actions}
                    >
                        <span
                            className={CLASS_CARD_TAGS_ITEM + '-name'}
                        >
                            {ReactHtmlParser('#' + markdown(element.name))}
                        </span>
                        {!isReadonly &&
                            <Button
                                tooltip={getAsidePanelTooltip({
                                    value: TAG_EDIT_ICON_TOOLTIP,
                                })}
                                variant={'icon'}
                                className={CLASS_CARD_TAGS_ITEM + '-edit'}
                                onClick={() => onRenameClickHandler(element.id)}
                            >
                                <Icon xlink={'pen'} size={16}/>
                            </Button>
                        }
                    </Tag>
            )
        })}
    </Tags>);
};
