import React from 'react';
import {SVG} from './../../../../../../../react-utils';
import getEventUrlProps from './../helpers/getEventUrl';

const GooglePushToElements = (props) => {
    return (
        <ul className="move-card__boards">
            {
                props.elements.map((el, i) => {
                    const isDisabled = props.disabledElements.indexOf(el.id) > -1;
                    const itemClass = `move-card__list ${isDisabled ? 'move-card__list--disabled' : ''}`;
                    const hrefProps = getEventUrlProps(el.id);

                    return (
                        <li className={itemClass} key={i} onClick={event => props.onClick(event, el)}>
                            <span className="move-card__list-name">{el[props.elementName]}</span>
                            {   props.loadingElement == el.id &&
                            <div className="loader js-loader" title="Loading">
                                <i className="loader__blink"/>
                            </div>
                            }
                            {
                                props.selectedElements.indexOf(el.id) > -1 &&
                                <a className="js-gc-link" {...hrefProps}>
                                    <span className="icon move-card__list-ico move-card__list-ico--green">
                                        <SVG xlink="svgicon--added-calendar" />
                                    </span>
                                </a>
                            }
                        </li>
                    );
                })
            }
            {
                props.elements.length === 0 && (
                    <li className="move-card__list">¯\_(ツ)_/¯</li>
                )
            }
        </ul>
    );
};

GooglePushToElements.defaultProps = {
    elementName: 'name',
    loadingElement: null,
    selectedElements: [],
    disabledElements: [],
    elements: [],
    onClick: () => {}
};

export default GooglePushToElements;