import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState } from '../../../../../../../types/types';
import { getActiveBoardId } from '../../../../../../../store/model/selectors/getActiveBoardId';
import { filterPanelBoardsSelector } from '../../../../../aside_panel/filterPanelBoard/store/selectors/filterPanelBoardsSelector';
import { getCurrentEditingFilterIdSelector } from '../../../../../aside_panel/filterPanelBoard/store/selectors/getCurrentEditingFilterIdSelector';
import { filterSelector } from '../../../../../aside_panel/filterPanelBoard/store/selectors/filterSelector';
import { getDefaultOrEditedFilterAction } from '../../../../../aside_panel/filterPanelBoard/helpers/getDefaultOrEditedFilterAction';
import { filterPanelActionSetAction } from '../../../../../aside_panel/filterPanelBoard/store/filterPanelBoards/actions/filterPanelActionSetAction';
import { keywordFiltrationEnabledSet } from '../../../../../aside_panel/filterPanelBoard/store/filterPanel/actions/keywordFiltrationEnabledSet';
import {
    DEFAULT_FILTER_ID,
    FP_EDITED_FILTER_INITIAL_VALUE,
    FP_KEY_HIDE_FILTER_EMPTY_LIST
} from '../../../../../aside_panel/filterPanelBoard/constants';
import { defaultFilterReset } from '../../../../../aside_panel/filterPanelBoard/store/filterPanel/actions/defaultFilterReset';
import { editedFilterSet } from '../../../../../aside_panel/filterPanelBoard/store/filterPanel/actions/editedFilterSet';
import { assignedToSet } from '../../../../../aside_panel/filterPanelBoard/store/filter/actions/assignedToSet';
import { ISegmentEventOption } from '../../../../../../../middlewares/segment/trackEntities/types';
import {
    SegmentFilterEvent,
    SegmentFilterOption
} from '../../../../../../../middlewares/segment/trackEntities/filterEvents';
import { segmentTrackAction } from '../../../../../../../middlewares/segment';
import { panelOpenedSet } from '../../../../../aside_panel/filterPanelBoard/store/filterPanel/actions/panelOpenedSet';
import { isBoardFilterPanelOpened } from '../../../../../../../store/router/selectors/isBoardFilterPanelOpened';
import { enabledSet } from '../../../../../aside_panel/filterPanelBoard/store/filterPanel/actions/enabledSet';
import { hideSet } from '../../../../../aside_panel/filterPanelBoard/store/filter/actions/hideSet';
import { getMyPermissionId } from '../../../../../../../store/model/selectors/getMyPermissionId';
import { root } from '../../../../../../../store/constants';
import { ETutorial } from '../../../../../../../types/rest/IUserMeta';

export const onMyCardCounterClick: ActionCreator<ThunkAction> = (
    isActive: boolean
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const boardId = getActiveBoardId(state);
        const permissionId = getMyPermissionId(state, boardId);
        const filterPanel = filterPanelBoardsSelector(state, boardId);
        const filterId = getCurrentEditingFilterIdSelector(filterPanelBoardsSelector(state, boardId));
        let assignedTo = [...filterSelector(state, boardId, filterId).assignees];
        const defaultOrEditedFilterAction = getDefaultOrEditedFilterAction(filterId);
        let status;
        if (isActive) {
            status = 'off';
            if (filterPanel.isEnabled) {
                assignedTo = assignedTo.filter(assignedItem => assignedItem !== permissionId);
            } else if (filterPanel.isTopBarFiltrationEnabled) {
                assignedTo = [];
                dispatch(filterPanelActionSetAction(boardId, keywordFiltrationEnabledSet(false)));
                dispatch(filterPanelActionSetAction(boardId, panelOpenedSet(false)));
            }
        } else {
            status = 'on';
            const currentFilterId = getCurrentEditingFilterIdSelector(filterPanel);
            if (currentFilterId === DEFAULT_FILTER_ID) {
                dispatch(filterPanelActionSetAction(boardId, defaultFilterReset()));
            } else {
                dispatch(filterPanelActionSetAction(boardId, editedFilterSet(FP_EDITED_FILTER_INITIAL_VALUE)));
            }
            assignedTo = [permissionId];
            dispatch(filterPanelActionSetAction(boardId, keywordFiltrationEnabledSet(true)));
            dispatch(filterPanelActionSetAction(boardId, panelOpenedSet(true)));
            root.App.controller.showTutorial(ETutorial.FILTER_TUTORIAL);
            if (!filterPanel.isEnabled && isBoardFilterPanelOpened(state)) {
                dispatch(filterPanelActionSetAction(boardId, enabledSet(true)));
            }
        }
        dispatch(filterPanelActionSetAction(boardId, defaultOrEditedFilterAction(assignedToSet(assignedTo))));
        dispatch(filterPanelActionSetAction(boardId, defaultOrEditedFilterAction(hideSet([FP_KEY_HIDE_FILTER_EMPTY_LIST]))));
        const options: ISegmentEventOption = {
            name: SegmentFilterOption.STATUS,
            value: status
        };
        dispatch(segmentTrackAction(SegmentFilterEvent.FILTER_TOP_BAR_ASSIGNED_TO_ME_CLICK, options));
    };
    return action;
};
