import { Dispatch } from '../../../../../../../../../../types/actions';
import { IGetState, TCardId, TNBDId } from '../../../../../../../../../../types/types';
import { notificationBeforeDueDateDelete } from '../../../../../../../../../../rest/effects/card/notificationBeforeDueDate/notificationBeforeDueDateDelete';

export const deleteNotificationBeforeDueDateById = (
    cardId: TCardId,
    notificationId: TNBDId,
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
            return dispatch(notificationBeforeDueDateDelete(cardId, notificationId))
    }
    return action;
};
