import { getMessages } from '../../../../../../../store/constants';

export const POLL_TITLE = getMessages().getText('poll.title');
export const POLL_QUESTION_FEEDBACK_START = getMessages().getText('poll.feedback.title.start');
export const POLL_QUESTION_FEEDBACK_END = getMessages().getText('poll.feedback.title.end');
export const POLL_BUTTON_LATER = getMessages().getText('poll.button.later');
export const POLL_BUTTON_BACK = getMessages().getText('poll.button.back');
export const POLL_BUTTON_CANCEL = getMessages().getText('poll.button.cancel');
export const POLL_BUTTON_SEND = getMessages().getText('poll.button.send');
export const POLL_BUTTON_ADD_OPINION = getMessages().getText('poll.button.opinion');
export const POLL_NOTIFICATION_TEXT = getMessages().getText('poll.notification.text');
export const POLL_PLACEHOLDER = getMessages().getText('poll.placeholder');
export const POLL_RATE = getMessages().getText('poll.rate');
export const POLL_MARKET_NAME_GOOGLE = getMessages().getText('poll.market_name_google');
export const POLL_MARKET_NAME_MICROSOFT = getMessages().getText('poll.market_name_ms');

export const POLL_MIN_POSITIVE_ANSWER = 3;

export const POLL_RATING = 4.6; // todo можно запарсить с бэка
export const POLL_RATING_COUNT = '2,323'; // todo можно запарсить с бэка
export const POLL_DOWNLOADS_COUNT = '3M+'; // todo можно запарсить с бэка

export const POLL_RATE_LINK_GOOGLE = 'https://workspace.google.com/marketplace/app/kanbanchi_task_and_project_management/631025100586';
export const POLL_RATE_LINK_MICROSOFT = 'https://appsource.microsoft.com/en-us/product/web-apps/kanbanchiltd1688983457774.app-kanbanchi-com?tab=Reviews';
