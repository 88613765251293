import { EViewTypes } from '../../../../const';
import { IApplicationState } from '../../../../types/types';
import { getUserDefaultView } from './getUserDefaultView';

export const getUserDefaultViewWithout = (
    state: IApplicationState,
    without: EViewTypes[] = []
): EViewTypes => {
    const defaultView = getUserDefaultView(state);
    return without.includes(defaultView) ? EViewTypes.KANBAN_VIEW_TYPE : defaultView;
};
