import { getAuthUser } from '../../../../../../../store/model/authUser/selectors/getAuthUser';
import store from '../../../../../../../store/configureStore';
import * as _ from 'underscore';
import { commonAuthManagerInstance } from "app/helper/authorisation/common/CommonAuthManager";

const getGoogleCalendars = (nextPageToken) => {
    const params = {
        'maxResults': '50',
        'minAccessRole': 'writer',
        'fields': 'items, nextPageToken'
    };
    if (nextPageToken){
        params.pageToken = nextPageToken;
    }
    return commonAuthManagerInstance.getAccessTokenOrShowLoginScreen().then((accessToken) =>{
        var request = gapi.client.calendar.calendarList.list(params);

        return new Promise(resolve => {
            request.execute(resp => {
                let result =[];
                const primary = _.findWhere(resp.items, { primary: true });
                const authUser = getAuthUser(store.getState());
                if (primary && primary.summary === authUser.email) {
                    primary.summary = Messages.getText('model.calendar.primary');
                }
                result = [...result, ...resp.items ];
                if (resp.nextPageToken){
                    getGoogleCalendars(resp.nextPageToken).then(calendars => {
                        result = [...result, ...calendars ];
                        resolve(result);
                    })
                }else {
                    resolve(result);
                }
            });
        });
    })


};

export default getGoogleCalendars;
