import * as React from 'react';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { TCardId } from '../../../../../../../../../types/types';
import { cardClick } from '../../../../../../../base/effects/cardClick';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../../middlewares/segment';

export const onCardClick = (
    e: React.MouseEvent,
    cardId: TCardId
): ThunkAction => {
    return (
        dispatch: Dispatch
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.CARD_CLICKED));
        dispatch(cardClick(e, cardId));
    };
};
