import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../types/types';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';

export const onClickEstimate = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.CHECKLIST_ITEM_ESTIMATE_CLICKED));
    };
    return action;
};
