import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { TCommentId } from '../../../../../../../../store/model/card/types/IComment';
import { commentUpdate } from '../../../store/reducers/actions/commentUpdate';
import { TDriveDocId } from '../../../../../../../../types/rest/IRestDriveDoc';

export const onAttachmentReply = (
    commentId: TCommentId,
    driveDocId: TDriveDocId
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(commentUpdate({
            replyCommentId: commentId,
            replyAttachmentId: driveDocId,
            replyText: null,
            editCommentId: null,
        }));
        dispatch(segmentTrackAction(SegmentCardEvent.COMMENT_REPLY_CLICKED));
    };

    return action;
};
