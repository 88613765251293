import {ActionCreator} from 'redux';
import {IGetState} from '../../../../../../../types/types';
import {isOpenSetAction} from '../../../store/navigationPanel/actions/isOpenSetAction';
import {Dispatch, ThunkAction} from '../../../../../../../types/actions';
import { navPanelOpenedMouseEnterSegmentEvent } from '../../../effects/segmentEvents';
import { isOpenNavigationPanelSelector } from '../../../store/selectors/isOpenNavigationPanelSelector';

export const onAnchorMouseHold: ActionCreator<ThunkAction> = (
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const isOpen = isOpenNavigationPanelSelector(state);

        if (!isOpen) {
            dispatch(isOpenSetAction(true));
            dispatch(navPanelOpenedMouseEnterSegmentEvent());
        }
    };
    return action;
};
