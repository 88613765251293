import { ICard } from './../../../../../../store/model/card/types';
import { IApplicationState, TBoardId } from '../../../../../../types/types';
import { ICardDetailsTag } from '../components/CardTagsElements/types';
import { getBoardCards } from '../../../../../../store/model/selectors/getBoardCards';
import { createSelector } from 'reselect';

const getBoardTagsSelector = (
    cards: ICard[],
): ICardDetailsTag[] => {
    const cardTags = cards.map((card) => card.tags || []).flat();
    const tags = [...new Set(cardTags)].map((tag) => {
        return {
            id: tag,
            name: tag
        };
    });
    return tags;
};

export const getBoardTags: (
    state: IApplicationState,
    boardId: TBoardId,
) => ICardDetailsTag[] = createSelector([
        getBoardCards,
    ],
    getBoardTagsSelector,
);
