import { connect } from 'react-redux';
import { ICardChecklistSortingHOCProps } from './types';
import { IApplicationState } from '../../../../../../../types/types';
import { ICardChecklistSortingEvents, ICardChecklistSortingFields } from '../../components/CardChecklistSorting/types';
import { CardChecklistSorting } from '../../components/CardChecklistSorting/CardChecklistSorting';
import { onOrderModeChange } from './events/onOrderModeChange';
import {
    getAuthUserChecklistOrderMode
} from '../../../../../../../store/model/authUser/selectors/getAuthUserChecklistOrderMode';

const mapStateToProps = (
    state: IApplicationState,
): ICardChecklistSortingFields => {
    return {
        selectedSorting: getAuthUserChecklistOrderMode(state)
    };
};

const mapDispatchToProps = (
    dispatch: any,
    { onClose, onBack }: ICardChecklistSortingHOCProps
): ICardChecklistSortingEvents => {
    return {
        onBack,
        onClose,
        onChange: (sorting) => dispatch(onOrderModeChange(sorting)),
    }
};

export const CardChecklistSortingHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardChecklistSorting);

CardChecklistSortingHOC.displayName = 'CardChecklistSortingHOC';
