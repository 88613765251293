import { AT_DEPENDENCIES_UPDATE, ITypeDependenciesUpdateAction } from './types';
import { IDependencies } from '../types';

export const dependenciesUpdateAction = (
    dependencies: IDependencies
): ITypeDependenciesUpdateAction => {
    return {
        type: AT_DEPENDENCIES_UPDATE,
        dependencies
    }
};
