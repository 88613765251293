import * as React from 'react';
import { createRoot, Root } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import { SharingDialogHOC } from '../main/topBar/hocs/SharingDialogHOC/SharingDialogHOC';
import { TBoardId } from 'app/types/types';

export class SharingDialog {
    boardId: TBoardId;
    el: Element;
    root: Root;
    store: Store;

    constructor (
        boardId: TBoardId,
        store: Store
    ) {
        this.boardId = boardId;
        this.store = store;
        if (this.el) this.remove();
        this.el = document.createElement('div');
        this.el.className = 'sharing-dialog-placeholder';
        document.body.append(this.el);
        this.root = createRoot(this.el!);
        this.render();
    }

    render () {
        this.root.render(
            <Provider store={this.store}>
                <SharingDialogHOC boardId={this.boardId} />
            </Provider>
        );
    }

    remove () {
        if (!this.el) return;

        this.root.unmount();
        this.el.remove();
        this.el = null;
    }
}
