import * as React from 'react';
import { ISetInWorkButtonProps } from './types';
import { SET_IN_WORK_CARD_TOOLTIP, UNSET_IN_WORK_CARD_TOOLTIP } from './constants';
import { getAsidePanelActionsButtonTooltip } from '../../../../asidePanel/helpers/getAsidePanelActionsButtonTooltip';
import { CLASS_ASIDE_PANEL_ACTIONS_BUTTON } from '../../../../asidePanel/components/AsidePanelActionsButton/constants';
import { Button, Icon } from 'kui';
import { MIN_WIDTH_DISPLAY_SET_IN_WORK } from '../../../ActionsInHeadSection/constants';
import './_SetInWorkButton.scss';
import { IN_WORK_CARD_ANIMATION_DURATION } from '../../../../../../../const';
import { BasicFeatureIndicator } from '../../../../../base/components/BasicFeatureIndicator/BasicFeatureIndicator';

export function SetInWorkButton({
    isBasicFeature,
    isLoading,
    isShow,
    inWork,
    panelWidth,
    onClick
}: ISetInWorkButtonProps) {
    if (!isShow) return;

    const className = CLASS_ASIDE_PANEL_ACTIONS_BUTTON + '--set-in-work';
    const classIcon = CLASS_ASIDE_PANEL_ACTIONS_BUTTON + '-set-in-work-icon';
    const classFilled = classIcon + '--filled';

    const title = inWork ? UNSET_IN_WORK_CARD_TOOLTIP : SET_IN_WORK_CARD_TOOLTIP;

    const [isHide, setHide] = React.useState(false);
    const [isShowTitle, setShowTitle] = React.useState(panelWidth < MIN_WIDTH_DISPLAY_SET_IN_WORK);

    const handleClick = () => {
        onClick();
        const button = document.activeElement as HTMLElement;
        if (button) {
            button.blur();
        }
    };

    React.useEffect(() => {
        if (isLoading || !inWork) return;

        setTimeout(() => {
            setHide(true)
        }, IN_WORK_CARD_ANIMATION_DURATION); // same animation length constant in _SetInWorkButton.scss
    }, [isLoading]);

    React.useEffect(() => {
        if (panelWidth < MIN_WIDTH_DISPLAY_SET_IN_WORK && isShowTitle) {
            setShowTitle(false);
        } else if (panelWidth > MIN_WIDTH_DISPLAY_SET_IN_WORK && !isShowTitle) {
            setShowTitle(true);
        }
    }, [panelWidth]);

    return (
        <Button
            className={`
                ${CLASS_ASIDE_PANEL_ACTIONS_BUTTON}
                ${inWork ? CLASS_ASIDE_PANEL_ACTIONS_BUTTON + '--active' : ''}
                ${className}
            `}
            variant={isShowTitle ? 'icon-text' : 'icon'}
            onClick={handleClick}
            text={isShowTitle && title}
            tooltip={!isShowTitle && getAsidePanelActionsButtonTooltip({
                direction: 'down',
                value: title
            })}
        >
            {
                inWork ?
                    <>
                        {isHide &&
                            <Icon
                                xlink={'pulse'}
                                size={24}
                                className={classIcon}
                            />
                        }
                        <Icon
                            xlink={'pulse'}
                            size={24}
                            className={classFilled}
                        />
                    </>
                    :
                    <Icon
                        xlink={'pulse'}
                        size={24}
                        className={classIcon}
                    />
            }
            {isBasicFeature && <BasicFeatureIndicator />}
        </Button>
    );
}
