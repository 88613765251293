import { IApplicationState, TListId } from '../../../../types/types';
import { getListCardAgingSettings } from './getListCardAgingSettings';

export const getListCardAgingIndicator = (
    state: IApplicationState,
    listId: TListId
): boolean => {
    const cardAgingSettings = getListCardAgingSettings(state, listId);

    return cardAgingSettings && cardAgingSettings.indicator && cardAgingSettings.indicator.enabled;
}
