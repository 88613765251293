import {Dispatch, ThunkAction} from 'app/types/actions';
import {IGetState} from 'app/types/types';
import { downloadFile } from 'app/helper/authorisation/microsoft/filePicker/downloadFile';
import { SegmentCardEvent, segmentTrackAction } from 'app/middlewares/segment';

export const onDownload = (
    driveId: string,
    fileId: string,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.ATTACHED_FILE_DOWNLOAD_CLICKED));
        if (driveId && fileId) downloadFile(driveId, fileId);
    };
    return action;
};
