import { IApplicationState } from '../../../../../../types/types';
import { connect } from 'react-redux';
import { QualificationDialog } from '../../components/QualificationDialog/QualificationDialog';
import { IQualificationDialogEvents, IQualificationDialogFields } from '../../components/QualificationDialog/types';
import { onDidMount } from './events/onDidMount';
import { getAuthUser } from '../../../../../../store/model/authUser/selectors/getAuthUser';
import { onAnswer } from './events/onAnswer';
import { getFirstQuestionKey } from './selectors/getFirstQuestionKey';
import { onCompleted } from './events/onCompleted';
import { onSkipped } from './events/onSkipped';
import { onGetStarted } from './events/onGetStarted';
import { onShowCalendar } from './events/onShowCalendar';

const mapStateToProps = (
    state: IApplicationState,
): IQualificationDialogFields => {
    const { fullName, initials, permissionId, photoUrl } = getAuthUser(state);
    return {
        firstQuestionKey: getFirstQuestionKey(state),
        fullName,
        initials,
        permissionId,
        photoUrl
    }
};

const mapDispatchToProps = (
    dispatch: any
): IQualificationDialogEvents => {
    return {
        onDidMount: () => dispatch(onDidMount()),
        onAnswer: (questionKey, answer) => dispatch(onAnswer(questionKey, answer)),
        onCompleted: () => dispatch(onCompleted()),
        onSkipped: () => dispatch(onSkipped()),
        onGetStarted: () => dispatch(onGetStarted()),
        onShowCalendar: () => dispatch(onShowCalendar())
    }
};

export const QualificationDialogHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(QualificationDialog);

QualificationDialogHOC.displayName = 'QualificationDialogHOC';
