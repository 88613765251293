import { ICard } from '../../card/types';
import { ICardsComparator } from './types';
import { NO_PRIORITY } from '../../../../view/react_components/helpers/constants';
import { compareNumbers } from './compareNumbers';

const getCardValue = (
    card: ICard
): number => Number(card.priority || NO_PRIORITY);

export const priority: ICardsComparator = {
    getCardValue,
    sort: compareNumbers
}
