import { TCardActivityAction } from './actions/types';
import { ICardActivityState } from './types';
import { AT_LAST_CLICKED_ACTIVITY_ID_SET, } from './actions/constants';

export const initialCardActivityState: ICardActivityState = {
    lastClickedActivityId: null
};

export const cardActivityReducer = (
    state: ICardActivityState = initialCardActivityState,
    action: TCardActivityAction
) => {
    switch (action.type) {

        case AT_LAST_CLICKED_ACTIVITY_ID_SET:
            return {
                ...state,
                lastClickedActivityId: action.activityId
            };

        default:
            return state;
    }
};
