import { IApplicationState } from '../../../../../../../types/types';
import { TGoogleDriveId } from '../../types';
import { IBoardActionMoveToDriveItem } from '../../../components/BoardActionMoveToDrive/types';
import { getDrives } from './getDrives';

export const getDrivesForMove = (
    state: IApplicationState,
    driveId: TGoogleDriveId
): IBoardActionMoveToDriveItem[] => {
    const drives = getDrives(state);
    const drivesAllowed = drives.filter((drive) => {
        return (
            drive.id !== driveId &&
            drive.allowedAction &&
            drive.allowedAction.allowMoveToThisDrive
        );
    });
    const moveItems = drivesAllowed.map((drive) => {
        return {
            id: drive.id,
            name: drive.name
        };
    });
    return moveItems;
}
