import { AT_UPDATE, IUpdateAction } from './types';
import { IAuthUser } from '../types';

export const updateAction = (
    authUser: IAuthUser
): IUpdateAction => {
    return {
        type: AT_UPDATE,
        authUser
    }
};
