import { ESubscriptionStatus } from '../../../../types/rest/IRestSubscription';
import { ISubscription } from '../../../../view/react_components/subscriptionAdmin/store/subscription/types';

export const isSubscriptionCanceled = (
    subscription: ISubscription,
): boolean => {
    return (
        subscription.status === ESubscriptionStatus.CANCELLED ||
        subscription.status === ESubscriptionStatus.CANCELLED_BY_USER
    );
};
