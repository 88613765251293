import { IDriveDocs } from '../types';
import { IDriveDocsDeleteAction } from './types';
import { AT_DELETE } from './constants';

export const driveDocsDelete = (
    driveDocs: IDriveDocs
): IDriveDocsDeleteAction => ({
    type: AT_DELETE,
    driveDocs
});
