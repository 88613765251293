import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { segmentTrackAction, SegmentAsidePanelEvent } from '../../../../../middlewares/segment';

export const buttonsPanelMinimizeClickedEvent = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        const event = SegmentAsidePanelEvent.BUTTONS_PANEL_MINIMIZE_CLICKED;
        dispatch(segmentTrackAction(event));
    };
    return action;
};

export const buttonsPanelExpandClickedEvent = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        const event = SegmentAsidePanelEvent.BUTTONS_PANEL_EXPAND_CLICKED;
        dispatch(segmentTrackAction(event));
    };
    return action;
};
