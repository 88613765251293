import { IApplicationState, TCardId } from '../../../types/types';
import { getCardDueDate } from './getCardDueDate';
import { getCardSubcards } from './getCardSubcards';
import { getCard } from './getCard';
import { ICards } from '../cards/types';

export const getSubcardsMinDate = (
    state: IApplicationState,
    epicId: TCardId,
    cards: ICards = {},
): number => {
    const subcards = getCardSubcards(state, epicId); // исходная карта со своими сёстрами

    let minStartDate = Infinity;
    subcards.forEach(card => {
        let startDate = cards[card.id] && cards[card.id].startDate !== undefined ? cards[card.id].startDate : card.startDate;
        startDate = startDate || Infinity;
        if (startDate < minStartDate) {
            minStartDate = startDate;
        }
    });
    return minStartDate === Infinity ? null : minStartDate;
}
