/**
 * Возвращает массив всех классов элемента и его родителей.
 * @param element HTMLElement
 * @param names Массив классов. Если найден любой из этих классов, обход завершается.
 * В массиве names можно передать только один класс. Обход родителей будет совершаться только в переделах этого элемента.
 * Когда нужны 2 элемента массива:
 * <div class="container">
 *      <div class="component1">
 *          <span class="span1"></span>
 *      </div>
 *      <div class="component2">
 *          <span class="span2"></span>
 *      </div>
 * </div>
 * Например нужно ловить клик на component1, тогда в names будет
 * ['component1', 'container']
 */
export const getParentsClasses = (
    element: HTMLElement,
    names: string[] = null
): string[] => {
    let target = element;
    let classes: string[] = [];
    let found = false;
    while (
        !found &&
        target &&
        target.parentNode
    ) {
        if (
            target.classList && // svg path doesnt have classList
            target.classList.length
        ) {
            classes = [
                ...classes,
                ...target.classList
            ];
            if (names) {
                for (
                    let i = 0;
                    i < names.length;
                    i++
                ) {
                    if (classes.includes(names[i])) {
                        found = true;
                        break;
                    }
                }
            }
        }
        target = target.parentNode as HTMLElement;
    }
    return classes;
}
