import {
    EQualificationAnswersType,
    TQualificationAnswer,
    TQualificationQuestionEvent
} from '../../../components/QualificationDialog/types';
import { QUALIFICATION_QUESTIONS } from '../../../constants/questions';
import { IUserMetaQualificationAnswer, IUserMetaQualificationAnswers } from '../../../../../../../types/rest/IUserMeta';
import { ESegmentQuestionEventOptions } from '../../../../../../../middlewares/segment/trackEntities/questionEvents';

export const getAnswersEvents = (
    answers: IUserMetaQualificationAnswers
): TQualificationQuestionEvent[] => {
    const questionEvents: TQualificationQuestionEvent[] = [];
    Object.keys(answers).forEach(questionKey => {
        const userAnswer = answers[questionKey];
        if (userAnswer && userAnswer.key) {
            const question = QUALIFICATION_QUESTIONS[questionKey];
            let anwserEvents: TQualificationQuestionEvent[] = [];
            if (question.answersType === EQualificationAnswersType.CHECKBOXES) {
                anwserEvents = getEventsByAnswer(questionKey, null, userAnswer);
            } else {
                const answer = question && question.answers[userAnswer.key];
                if (answer) {
                    anwserEvents = getEventsByAnswer(questionKey, answer, userAnswer);
                }
            }
            if (anwserEvents) {
                anwserEvents.forEach(event => {
                    if (event) {
                        questionEvents.push(event);
                    }
                })
            }
        }
    });
    return questionEvents;
}

const getEventsByAnswer = (
    questionKey: string,
    questionAnswer: TQualificationAnswer,
    userAnswer: IUserMetaQualificationAnswer
): TQualificationQuestionEvent[] => {
    const question = QUALIFICATION_QUESTIONS[questionKey];
    if (question.eventsCallback) {
        return question.eventsCallback(questionKey, userAnswer)
    }
    if (question.event) {
        let eventValue = '';
        if (questionAnswer.eventValueCallback) {
            eventValue = questionAnswer.eventValueCallback(userAnswer)
        } else if (questionAnswer.eventValue) {
            eventValue = questionAnswer.eventValue;
        } else {
            eventValue = questionAnswer.answer;
        }
        return [{
            event: question.event,
            option: {
                name: ESegmentQuestionEventOptions.answer,
                value: eventValue
            }
        }]
    }
}
