import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState } from '../../../../../../../types/types';
import { panelTypeSetAction } from '../../../store/navigationPanel/actions/panelTypeSetAction';
import { isOpenSetAction } from '../../../store/navigationPanel/actions/isOpenSetAction';
import { saveCollapseStatusToLS } from '../effects/saveCollapseStatusToLS';
import { resizeClickedSegmentEvent } from '../../../effects/segmentEvents';
import { ENavigationPanelTypes } from '../../../constants';
import { getCurrentNavigationPanelType } from '../../../helpers/getCurrentNavigationPanelType';

export const onCollapseClicked: ActionCreator<ThunkAction> = (
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const typePanel = getCurrentNavigationPanelType(state);
        let typePanelNew = ENavigationPanelTypes.NP_COMPACT_TYPE;
        switch (typePanel) {
            case ENavigationPanelTypes.NP_COMPACT_TYPE: {
                typePanelNew = ENavigationPanelTypes.NP_BIG_TYPE;
                break;
            }
        }
        dispatch(panelTypeSetAction(typePanelNew));

        dispatch(isOpenSetAction(false));
        dispatch(saveCollapseStatusToLS())
        dispatch(resizeClickedSegmentEvent(typePanelNew));
    };
    return action;
};
