import {Dispatch, ThunkAction} from '../../../../../../../../../types/actions';
import { TBoardId } from '../../../../../../../../../types/types';
import { topBarSetAction } from '../../../../../store/topBarBoard/actions/topBarSetAction';
import { isShareHintShowSetAction } from '../../../../../store/topBar/actions/isShareHintShowSetAction';

export const onHintClose = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(topBarSetAction(boardId, isShareHintShowSetAction(false)));
    };
    return action;
};
