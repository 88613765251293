import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState } from '../../../../../../../types/types';
import { openReports } from 'app/view/react_components/typeSwitcher/effects/openReports';

export const onClickReports = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(openReports());
    };
    return action;
};
