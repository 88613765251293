import { IApplicationState, TBoardId } from '../../../../../../../../../types/types';
import { ICardPropertyOption } from '../../../../../../../../../types/rest/IRestBoardMeta';
import {
    getBoardCardCustomPropertyOptions
} from '../../../../../../../../../store/model/board/selectors/getBoardCardCustomPropertyOptions';
import {
    getBoardCustomProperties
} from '../../../../CardCustomProperties/hocs/CardCustomPropertiesSectionHOC/selectors/getBoardCustomProperties';

export const toggleCardRequiredCustomPropertyOptions = (
    state: IApplicationState,
    boardId: TBoardId,
    option: string
): ICardPropertyOption[] => {
    let options: ICardPropertyOption[];
    const cardCustomProperties = getBoardCardCustomPropertyOptions(state, boardId);
    const index = cardCustomProperties.findIndex((item) => item.option === option && item.isRequired);
    if (index < 0) {
        options = getBoardCustomProperties(state, boardId).map((customProperty) => {
            const property = cardCustomProperties.find(({ option }) => option === customProperty.id);
            return {
                option: customProperty.id,
                name: customProperty.name,
                isDefault: true,
                isRequired: option === customProperty.id || property && property.isRequired
            };
        });
    } else {
        const newOption = {
            option: option,
            name: option,
            isDefault: true,
            isRequired: false
        };
        options = [
            ...cardCustomProperties.slice(0, index),
            newOption,
            ...cardCustomProperties.slice(index + 1)
        ];
    }

    return options;
};
