import { IApplicationState, TBoardId, TCardId } from '../../../../../../../../types/types';
import { getCardRelatedCardsActive } from '../../../../../../../../store/model/selectors/getCardRelatedCardsActive';
import { IRelatedCard } from '../../../../../relatedCards/components/RelatedCard/types';
import { buildRelatedCards } from '../../../../../relatedCards/helpers/buildRelatedCards';
import { createDeepEqualSelector } from '../../../../../../helpers/memoizeHelper';

type TgetRelatedCards = (
    state: IApplicationState,
    boardId: TBoardId,
    cardId: TCardId,
) => IRelatedCard[];

export const getRelatedCardsSelector = (
    boardId: TBoardId,
    relatedCards: IRelatedCard[],
): IRelatedCard[] => {
    return buildRelatedCards(boardId, relatedCards);
};

export const getRelatedCardsCreateSelector = (
): TgetRelatedCards => createDeepEqualSelector(
    (state: IApplicationState, boardId: TBoardId) => boardId,
    (state: IApplicationState, boardId: TBoardId, cardId: TCardId) => getCardRelatedCardsActive(state, cardId),
    getRelatedCardsSelector
);
