import { AsidePanelActionsButton } from '../../../..';
import * as React from 'react';
import { ICardAttachmentsPanelButton } from './types';
import { CARD_SECTION_ATTACHMENTS } from '../../constants';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { CLASS_CARD_DETAILS_SECTION } from '../../../constants';
import {
    CLASS_ASIDE_PANEL_ACTIONS_BUTTON_CONTAINER,
    CLASS_ASIDE_PANEL_ACTIONS_POPUP
} from '../../../../asidePanel/components/AsidePanelActionsButton/constants';
import { EAsidePanelProperty } from '../../../../asidePanel/components/AsidePanel/types';
import { EAuthUserPlatformType } from 'app/types/rest/IRestAuthUser';
import { CardAttachmentAdd } from '../CardAttacmentAdd/CardAttacmentAdd';
import { CardAttachmentGoogleFilesHOC } from 'app/view/react_components/aside_panel/cardDetails/AttachmentsSection/hocs/CardAttachmentGoogleFilesHOC/CardAttachmentGoogleFilesHOC';
import { getAsidePanelActionsButtonTooltip } from 'app/view/react_components/aside_panel/asidePanel/helpers/getAsidePanelActionsButtonTooltip';
import { AnalyzeRender } from 'app/view/react_components/helpers/memoizeHelper';

//@ts-ignore
const analyzeRender = window.Settings.development ? new AnalyzeRender(`CardAttachmentsPanelButton`) : null;

export function CardAttachmentsPanelButton({
    boardId,
    cardId,
    driveDocCount,
    isShow,
    isShowSelectFile,
    platformType,
    tooltip,
    onClick,
    onSelectClick,
    onUploadClick,
}: ICardAttachmentsPanelButton) {
    if (!isShow) return null;

    if (analyzeRender) analyzeRender.call(`${cardId}`);

    const className = CLASS_ASIDE_PANEL_ACTIONS_BUTTON_CONTAINER + '--attachments';

    const { openedProperty } = React.useContext(AsidePanelContext);
    const [isOpened, setIsOpened] = React.useState(null);
    const onOpened = () => setIsOpened(true);

    const isMicrosoft = platformType && platformType === EAuthUserPlatformType.MICROSOFT;

    const onClosed = () => setIsOpened(false);

    React.useEffect(() => {
        if (!openedProperty) onClosed();
    }, [openedProperty]);

    return (
        <AsidePanelActionsButton
            className={className}
            dropdownClassName={`${CLASS_ASIDE_PANEL_ACTIONS_POPUP}--attachments`}
            indicatorNumber={driveDocCount}
            icon={'attachment'}
            isOpened={isOpened}
            openedProperty={isMicrosoft ? EAsidePanelProperty.CARD_ATTACHMENTS : null}
            sectionSelector={`.${CLASS_CARD_DETAILS_SECTION}--attachments`}
            title={CARD_SECTION_ATTACHMENTS}
            tooltip={tooltip && getAsidePanelActionsButtonTooltip({ value: tooltip })}
            onClick={onClick}
            onOpen={onOpened}
            onClose={onClosed}
        >
            {!driveDocCount &&
                <>
                    {(!isMicrosoft && driveDocCount === 0) &&
                        <CardAttachmentGoogleFilesHOC
                            boardId={boardId}
                            cardId={cardId}
                            onChange={() => onClosed()}
                        />
                    }
                    {isMicrosoft && isOpened &&
                        <CardAttachmentAdd
                            onSelectClick={ isShowSelectFile ? () => onSelectClick(onClosed): null }
                            onUploadClick={(onStart, onProgress, onFinish) => onUploadClick(onStart, onProgress, () => {
                                onFinish();
                                onClosed();
                            })}
                        />
                    }
                </>
            }
        </AsidePanelActionsButton>
    );
}
