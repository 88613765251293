import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../../../../types/types';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment/index';
import { cardUpdatePinnedSection } from '../../../../../../../../rest/effects/card/card/cardUpdatePinnedSection';
import { getCardPinnedDescription } from '../../../../../../../../store/model/selectors/getCardPinnedDescription';

export const onTogglePin = (
    cardId: TCardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const isPinned = getCardPinnedDescription(getState(), cardId);
        if (isPinned) {
            dispatch(segmentTrackAction(SegmentCardEvent.DESCRIPTION_PIN_CLICKED));
        } else {
            dispatch(segmentTrackAction(SegmentCardEvent.DESCRIPTION_UNPIN_CLICKED));
        }
        return dispatch(cardUpdatePinnedSection(cardId, { description: !isPinned }));
    };
    return action;
};
