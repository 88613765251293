import { EThemeBackgroundOption } from 'app/types/rest/IRestTheme';

const category = 'Branding';

export const SegmentBrandingEvent = {
    BACKGROUND_CUSTOM_SELECTED: {category, event: 'BACKGROUND_CUSTOM_SELECTED'},
    BACKGROUND_FROM_LIBRARY_SELECTED: {category, event: 'BACKGROUND_FROM_LIBRARY_SELECTED'},
    BACKGROUND_EMPTY_SELECTED: {category, event: 'BACKGROUND_EMPTY_SELECTED'},
    OWN_IMAGE_TOGGLED: {category, event: 'Own image switcher clicked'},
    OWN_IMAGE_DEFAULT_POSITION: {category, event: 'Default position clicked'},
    OWN_IMAGE_TILE: {category, event: 'Tile image clicked'},
    OWN_IMAGE_STRETCH: {category, event: 'Stretch to full screen clicked'},
}

export const getOptionEvent = (option: string) => {
    switch (option) {
        case EThemeBackgroundOption.DEFAULT:
            return SegmentBrandingEvent.OWN_IMAGE_DEFAULT_POSITION;
        case EThemeBackgroundOption.REPEAT:
            return SegmentBrandingEvent.OWN_IMAGE_TILE;
        case  EThemeBackgroundOption.SCALE:
            return SegmentBrandingEvent.OWN_IMAGE_STRETCH;
        default:
            return SegmentBrandingEvent.OWN_IMAGE_DEFAULT_POSITION;
    }
}

export enum SegmentBrandingOption {
    THEME_ID = 'Theme Id'
}
