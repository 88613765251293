import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../types/types';
import { SegmentBoardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { TBoardAttachmentsTab } from '../../../TabAttachments/components/TabAttachments/types';

export const onTabClick = (
    tab: TBoardAttachmentsTab
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (tab === TBoardAttachmentsTab.ALL) {
            dispatch(segmentTrackAction(SegmentBoardEvent.BOARD_ATTACHMENTS_ALL_CLICKED));
        } else if (tab === TBoardAttachmentsTab.DOCUMENTS) {
            dispatch(segmentTrackAction(SegmentBoardEvent.BOARD_ATTACHMENTS_DOCUMENTS_CLICKED));
        } else {
            dispatch(segmentTrackAction(SegmentBoardEvent.BOARD_ATTACHMENTS_MEDIA_CLICKED));
        }
    };
    return action;
};
