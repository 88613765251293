import { TBoardId, TThemeId } from '../../../../../../../../../types/types';
import { EThemeBackgroundOption } from '../../../../../../../../../types/rest/IRestTheme';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { themeBackgroundOptionUpdate } from '../effects/themeBackgroundOptionUpdate';

export const onBackgroundOptionChange = (
    boardId: TBoardId,
    themeId: TThemeId,
    backgroundOption: EThemeBackgroundOption
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        dispatch(themeBackgroundOptionUpdate(boardId, themeId, backgroundOption));
    };
    return action;
};
