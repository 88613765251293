import { CLASS_ASIDE_PANEL_BODY } from '../../../asidePanel/components/AsidePanelBody/constants';
import { SegmentAsidePanelEvent, segmentTrackAction } from '../../../../../../middlewares/segment';
import store from '../../../../../../store/configureStore';

export const tabActivityClick = () => {
    const isActivityTab = document.querySelector('.' + CLASS_ASIDE_PANEL_BODY +  '--activity');
    if (!isActivityTab) return;
    const tab = document.querySelector('.' + CLASS_ASIDE_PANEL_BODY) as HTMLElement;
    if (tab) {
        tab.scroll({ behavior: 'smooth', top: 0 });
        store.dispatch(segmentTrackAction(SegmentAsidePanelEvent.SCROLL_TOP_BY_TAB_CLICK));
    }
}
