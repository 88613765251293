import { EMPTY_ARRAY, IApplicationState, TCardId } from '../../../types/types';
import { getCard } from './getCard';
import { IAssigneeRest } from '../../../types/rest/IAssigneeRest';

export const getCardAssignees = (
    state: IApplicationState,
    cardId: TCardId
): IAssigneeRest[] => {
    const card = getCard(state, cardId);
    if (
        !card ||
        !card.assignees ||
        !card.assignees.length
    ) return EMPTY_ARRAY;

    return card.assignees;
};
