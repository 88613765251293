import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../types/types';
import { openBoardSettings } from '../../../../../../base/effects/openBoardSettings';
import { openBoard } from '../../../../../../dialogs/openBoards/effects/openBoard';
import { getLinkCardBoardId } from '../../../../../../../../store/router/selectors/getLinkCardBoardId';
import { SegmentBoardSettingsSourceValue } from '../../../../../../../../middlewares/segment/trackEntities/boardEvents';

export const onClick = (
    ownOnClick?: () => void
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        // console.log('segment card properties button onClick');
        const linkCardBoardId = getLinkCardBoardId(getState().router);
        if (linkCardBoardId) {
            dispatch(openBoard(linkCardBoardId, 'edit/card-properties'));
        } else {
            dispatch(openBoardSettings('card-properties', SegmentBoardSettingsSourceValue.CARD_PROPERTIES_SETTINGS));
        }
        if (ownOnClick) ownOnClick();
    };
    return action;
};
