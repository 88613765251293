import { EAuthUserAccountType } from 'app/types/rest/IRestAuthUser';
import { IApplicationState } from '../../../../types/types';
import { getAuthUser } from './getAuthUser';

const EDUCATIONAL_EMAILS_PARTS = [
    '.edu',
    'edu.',
    'educat',
    'student',
    'school',
    'k-12',
    'k12',
    'district',
    'alumni',
    'college',
    'universit'
];

function checkEduEmail(email: string) {
    let isEdu = false;
    for (let i = 0; i < EDUCATIONAL_EMAILS_PARTS.length; i++) {
        if (email.includes(EDUCATIONAL_EMAILS_PARTS[i])) {
            isEdu = true;
            break;
        }
    }
    return isEdu;
}

export const isUserAccountTypeDomain = (
    state: IApplicationState
): boolean => {
    const user = getAuthUser(state);

    return user &&
        user.accountType &&
        user.accountType === EAuthUserAccountType.DOMAIN_ACCOUNT_TYPE &&
        !checkEduEmail(user.email);
};
