import { getSwimlanesLocalStorage } from '../localstorage/actions/getSwimlanesLocalStorage';
import { IKanbanViewState } from './types';
import {
    getNewCardLocalStorage
} from '../localstorage/actions/getNewCardLocalStorage';

export const getInitialState = (
): IKanbanViewState => {
    return {
        activityScrollListId: null,
        draggableCardId: null,
        moveToOpenedListId: null,
        snoozeBlockerClosed: false,
        highlightCardId: null,
        recentlyWatchCardId: null,
        swimlanes: getSwimlanesLocalStorage(),
        newCard: getNewCardLocalStorage()
    };
}
