import { IApplicationState } from '../../../../../../../types/types';
import { connect } from 'react-redux';
import { ICardCustomPropertiesHOCProps } from './types';
import {
    CustomPropertiesElements
} from '../../../../../aside_panel/cardDetails/CustomPropertiesSection/components/CustomPropertiesElements/CustomPropertiesElements';
import {
    ICustomPropertiesElementsEvents,
    ICustomPropertiesElementsFields
} from '../../../../../aside_panel/cardDetails/CustomPropertiesSection/components/CustomPropertiesElements/types';
import { onSave } from './events/onSave';
import { onUpdateValue } from './events/onUpdateValue';
import {
    getCustomPropertiesCreateSelector
} from '../../../../../aside_panel/cardDetails/CustomPropertiesSection/hocs/selectors/getCustomProperties';
import { getSectionType } from '../../../../../aside_panel/boardDetails/selectors/getSectionType';

// const analyze = new Analyze('CardCustomPropertiesHOC');
//createDeepEqualSelector: average: 0.094 ms, calls: 80, total: 7.500 ms
//createSelector: average: 0.109 ms, calls: 80, total: 8.700 ms

const mapStateToProps = () => {
    const getCustomProperties = getCustomPropertiesCreateSelector();

    return (
        state: IApplicationState,
        { cardId, boardId, className }: ICardCustomPropertiesHOCProps
    ): ICustomPropertiesElementsFields => {
        // analyze.start();
        const ret = {
            selectedCardId: cardId,
            elements: getCustomProperties(state, boardId, cardId, true),
            sectionType: getSectionType(state, boardId, null, 'allowCardCustomProperties'),
            isCard: true,
            classModule: className
        };
        // analyze.finish();

        return ret;
    };
};

const mapDispatchToProps = (
    dispatch: any,
    { cardId, boardId, setCardHeight }: ICardCustomPropertiesHOCProps
): ICustomPropertiesElementsEvents => {
    return {
        onUpdateValue: (customPropertyId, value) => dispatch(onUpdateValue(cardId, customPropertyId, value)),
        onSave: (elements) => dispatch(onSave(boardId, cardId, elements)),
        onChangeType: () => {},
        setCardHeight
    };
};
export const CardCustomPropertiesHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomPropertiesElements);

CardCustomPropertiesHOC.displayName = 'CardCustomPropertiesHOC';
