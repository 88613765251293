import { Button, Icon } from 'kui';
import { INotificationsProps } from './types';
import * as React from 'react';
import './_Notifications.scss'
import { TITLE_NOTIFICATIONS } from './constants';
import { root } from 'app/store/constants';
import { NotifyInformer } from '../../../../main/notify_informer';
import { Portal } from '../../../helpers/portal';
import {
    NAVIGATION_PANEL_CLASS,
    NAVIGATION_PANEL_CONTAINER_CLASS,
    NAVIGATION_PANEL_DROPDOWN_CLASS
} from '../../../main/navigationPanel/constants';
import { CLASS_MAIN_PANEL } from '../../../../../const';
import {
    NAVIGATION_PANEL_DASHBOARD_ACTION_CLASS
} from '../../../main/navigationPanel/components/NavigationPanelTabItem/constants';
import { getParentsClasses } from '../../../helpers/getParentsClasses';

export function Notifications ({
    isForceClose,
    isNotificationsOpen,
    isReturnFocus,
    unseenNotificationCount,
    userId,
    updateNotificationsOpen,
    onOpen,
    onClose,
}: INotificationsProps) {
    const [isOpened, setIsOpened] = React.useState(null);

    const isIgnoreClose = () => {
        const activeElement = document.activeElement as HTMLElement;
        const path = getParentsClasses(activeElement, [
            'navigation-panel__dashboard-menu'
        ]);
        return path.includes('navigation-panel__dashboard-menu');
    };

    const onCloseNavBarIfNeeded = () => {
        if (isOpened && (isReturnFocus || isIgnoreClose())) return;
        const main = document.querySelector('.' + CLASS_MAIN_PANEL) as HTMLElement;
        if (main) main.focus();
    };

    const onClick = () => {
        setIsOpened(!isOpened);
        if (isOpened) {
            onClose(true);
            setTimeout(onCloseNavBarIfNeeded, 0);
            updateNotificationsOpen(false)
        } else {
            onOpen(true);
        }
        root.App.controller.notificationsView.toggleHelp();
    };

    const className = 'notifications';
    const classButton = className + '__button';
    const classButtonCount = className + '__button-count';
    const classDropdown = className + '__dropdown';
    const classHeader = className + '__header';
    const classBody = className + '__body';

    const notificationsBackbone = React.useRef(null);
    React.useEffect(() => {
        if (!root.App.controller.notificationsView){
            setTimeout(()=>{
                const current = notificationsBackbone.current as HTMLElement;
                if (userId !=='0' && current) {
                    let  el = document.createElement('div');
                    el.className = `${classBody} userId${userId}`;
                    current.append(el);
                    root.App.controller.notificationsView = new NotifyInformer({el});
                    root.App.controller.notificationsView.render();
                }
            }, 500);
        }
        return () => {
            if (root.App.controller.notificationsView) {
                root.App.controller.notificationsView.remove();
                root.App.controller.notificationsView = null;
            }
        }
    }, [userId]);

    React.useEffect(() => {
        if (isOpened && isForceClose) onClick();
    }, [isForceClose]);

    React.useEffect(() => {
        if (isNotificationsOpen) {
            setIsOpened(true);
            onOpen(false);
        }
    }, [isNotificationsOpen])

    React.useEffect(() => {
        if (isReturnFocus && isOpened) return;
        const removeEvent = () => {
            const overlay = document.querySelector(`.${NAVIGATION_PANEL_CLASS}__overlay`) as HTMLElement;
            if (overlay) overlay.removeEventListener('click', onCloseNavBarIfNeeded);
        };

        const overlay = document.querySelector(`.${NAVIGATION_PANEL_CLASS}__overlay`) as HTMLElement;
        if (overlay) overlay.addEventListener('click', onCloseNavBarIfNeeded);

        return () => {
            removeEvent();
        };
    }, [isReturnFocus, isOpened]);

    React.useEffect(() => {
        function onEsc (e: KeyboardEvent) {
            if (e.key === 'Escape' && isOpened) {
                setIsOpened(false);
                onClose(false);
                setTimeout(onCloseNavBarIfNeeded, 0);
                updateNotificationsOpen(false)
                root.App.controller.notificationsView.toggleHelp();
            }
        };
        root.addEventListener('keydown', onEsc);

        return () => {
            root.removeEventListener('keydown', onEsc);
        }
    })

    return <>
        <Button
            className={`navigation-panel__row ${classButton} ${isOpened ? classButton + '--opened' : ''}`}
            text={TITLE_NOTIFICATIONS}
            variant={'icon-text'}
            onClick={onClick}
        >
            <Icon size={24} xlink={'notifications'} />
            {!!unseenNotificationCount &&
                <span
                    className={`
                        ${classButtonCount}
                        ${unseenNotificationCount > 99 ? classButtonCount + '--big' : ''}
                    `}
                >
                    {unseenNotificationCount < 100 ? unseenNotificationCount : '99+'}
                </span>
            }
        </Button>
        <Portal
            selector={'#navigation-aside-portal'}
            className={`
                ${classDropdown}
                ${NAVIGATION_PANEL_DROPDOWN_CLASS}
                ${isOpened ? classDropdown + '--opened' : ''}
           `}
        >
                <div
                    className={className}
                    tabIndex={0} // сюда FocusLock поставит фокус при открытии
                    ref={notificationsBackbone}
                >
                    <div className={classHeader}>
                        {TITLE_NOTIFICATIONS}
                        <Button
                            className={`${className}__close`}
                            variant={'icon'}
                            aria-label={'Close'}
                            onClick={onClick}
                        >
                            <Icon
                                size={24}
                                xlink="close"
                            />
                        </Button>
                    </div>
                </div>
        </Portal>
    </>;
};
