import { createDeepEqualSelector } from '../../../../../../../helpers/memoizeHelper';
import { IApplicationState } from '../../../../../../../../../types/types';
import { ISearchSelectOption } from '../../../../../../../base/components/SearchSelect/types';
import { getMyWorkShowBoards } from '../../../../../store/selectors/getMyWorkShowBoards';
import { getUserBoardActivityTimes } from '../../../../../../../../../store/model/boardUserActivityTimes/selectors/getUserBoardActivityTimes';
import { Util } from '../../../../../../../../../util/util';
import * as React from 'react';
import { UpdateCounter } from '../../../../../../../base/components/UpdatesCounter/UpdateCounter';
import { CLASS_MY_WORK } from '../../../../../constants';

type TgetBoardsSearchSelectOptions = (
    state: IApplicationState
) => ISearchSelectOption[];

export const getBoardsSearchSelectOptions: TgetBoardsSearchSelectOptions = createDeepEqualSelector(
    getUserBoardActivityTimes,
    getMyWorkShowBoards,
    (activityTimes, showBoards) => {
        const options: ISearchSelectOption[] = Object.values(activityTimes)
            .sort((item1, item2) => item2.lastActivityTime - item1.lastActivityTime)
            .map(item => {
                const { id, name, unseenUpdatesCounter, lastActivityTime } = item;
                const active = showBoards.includes(item.id);
                return {
                    value: id.toString(),
                    text: name,
                    textSub: <div className={CLASS_MY_WORK + '-import-boards__search-sub'}>
                        {!active && unseenUpdatesCounter ? <UpdateCounter updates={unseenUpdatesCounter} /> : null}
                        {Util.formatDateMoment(new Date(lastActivityTime), 'DD MMM YYYY')}
                    </div>,
                    active
                };
            });
        return options;
    }
)
