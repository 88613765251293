import { IGetState, TCardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { selectFileClickedSegmentEvent } from '../effects/segmentEffects';
import { showDocDriveWindow } from '../../../../effects/showDocDriveWindow';
import { getDriveDocsByCardId } from '../../../../../../../../store/model/driveDocs/selectors/getDriveDocsByCardId';
import { ERestDriveDocType } from '../../../../../../../../types/rest/IRestDriveDoc';
import { addAttachments } from '../../../../../../../../rest/effects/card/attacments/addAttachments';
import { setAttachmentAddedSessionStorage } from '../../../helpers/setAttachmentAddedSessionStorage';

export const onSelectClick = (
    cardId: TCardId,
    onClose?: () => void,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(selectFileClickedSegmentEvent());

        dispatch(showDocDriveWindow(
            cardId,
            getDriveDocsByCardId(getState(), cardId),
            ERestDriveDocType.CARD,
            (docs) => dispatch(addAttachments(cardId, docs))
                .then(() => setAttachmentAddedSessionStorage()),
            onClose
        ))
    };
    return action;
};
