import { IApplicationState, TBoardId } from '../../../types/types';
import { getPushNotificationSettingsByBoardId } from './getPushNotificationSettingsByBoardId';
import { IPushNotificationSettings } from '../pushNotificationSettings/types';

export const getPushNotificationSettingsForRollback = (
    state: IApplicationState,
    boardId: TBoardId,
    patchPushNotification: IPushNotificationSettings
): IPushNotificationSettings => {
    const pushNotification = getPushNotificationSettingsByBoardId(state, boardId);
    if (!patchPushNotification || !pushNotification) return;
    let pushNotificationForRollback: IPushNotificationSettings = {};
    for (const field in patchPushNotification) {
        const fieldKey = field as keyof IPushNotificationSettings;
        pushNotificationForRollback = {
            ...pushNotificationForRollback,
            [fieldKey]: pushNotification[fieldKey]
        };
    }

    return JSON.parse(JSON.stringify(pushNotificationForRollback));
}
