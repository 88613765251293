import { IApplicationState, TCardId } from '../../../types/types';
import { getCard } from './getCard';
import { TimeTrackerHelper } from '../../../view/react_components/dialogs/timeTrackerDialog/helpers/TimeTrackerHelper';

export const getTimeTrackerTotalSeconds = (
    state: IApplicationState,
    cardId: TCardId,
    workHoursInDay: number,
    userHash: string = null
): number => {
    const card = getCard(state, cardId);
    let result: number = 0;
    if (card) {
        (card.comments || []).filter((comment) => {
            if (userHash) {
                const commentUserHash = comment.author ? comment.author.hash : '';
                if (userHash !== commentUserHash)
                    return false;
            }
            return comment.timeTracker
        }).forEach((comment) => {
            result += TimeTrackerHelper.formatCommentToSecondsByText(comment.text, workHoursInDay) || 0;
        })
    }
    return result;
};
