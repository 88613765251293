import { IApplicationState } from '../../../../types/types';
import { getAuthUser } from './getAuthUser';

export const isUserDomainWide = (
    state: IApplicationState
): boolean => {
    const user = getAuthUser(state);
    return user && (user.domainWide ||
        user.domainWidePremium ||
        user.domainWideProfessional);
};
