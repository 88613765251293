import { Dispatch, ThunkAction } from '../../../types/actions';
import { IGetState, TBoardId } from '../../../types/types';
import { postRest } from './api/postRest';
import { IRestList } from '../../../types/rest/IRestList';
import { ILists } from '../../../store/model/lists/types';
import { listsActionSet } from '../../../store/model';
import { listsSet } from '../../../store/model/lists/actions/listsSet';
import { LD_TEMPLATES_LIST_NAME } from '../../../view/react_components/aside_panel/listDetails/constants';
import { IList } from '../../../store/model/list/types';
import { sendRealTimeStoreAction } from '../../../view/react_components/base/helpers/realTimeHelperTS';
import { getBoard } from '../../../store/model/selectors/getBoardById';

export const listTemplateAdd = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const list: IRestList = {
            name: LD_TEMPLATES_LIST_NAME
        };
        return dispatch(postRest(boardId, list, true))
            .then((response: IList) => {
                const listsRest: ILists = {
                    [response.id]: {
                        ...response,
                        dashboardId: boardId
                    }
                };
                const action = listsActionSet(listsSet(listsRest));
                const board = getBoard(getState(), boardId);
                dispatch(action);
                sendRealTimeStoreAction(board.id, board.cometToken, action);

                return response;
            });
    };
    return action;
};
