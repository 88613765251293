import { connect } from 'react-redux';
import { TabAttachments } from '../components/TabAttachments/TabAttachments';
import { ITabAttachmentsHOCProps } from './types';
import { ITabAttachmentsEvents, ITabAttachmentsFields } from '../components/TabAttachments/types';
import { IApplicationState } from '../../../../../../../types/types';
import { onLoad } from './events/onLoad';
import {
    getAuthUserIsAttachmentViewTypeGrid
} from '../../../../../../../store/model/authUser/selectors/getAuthUserIsAttachmentViewTypeGrid';
import { onDidMount } from './events/onDidMount';

const mapStateToProps = (
    state: IApplicationState,
    { boardId }: ITabAttachmentsHOCProps
): ITabAttachmentsFields => {
    return {
        boardId,
        isGridView: getAuthUserIsAttachmentViewTypeGrid(state)
    }
};

const mapDispatchToProps = (
    dispatch: any,
    { boardId }: ITabAttachmentsHOCProps
): ITabAttachmentsEvents => {
    return {
        onLoad: () => dispatch(onLoad(boardId)),
        onDidMount: () => dispatch(onDidMount(boardId))
    };
};

export const TabAttachmentsHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(TabAttachments);

TabAttachmentsHOC.displayName = 'TabAttachmentsHOC';
