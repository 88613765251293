import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState } from '../../../../../../../types/types';
import { authUserSetPricingPoll } from '../../../../../../../rest/effects/authUser/authUserSetPricingPoll';
import { segmentTrackAction, SegmentUserEvent } from '../../../../../../../middlewares/segment';
import {
    ESegmentQuestionEventOptions,
    SegmentQuestionEvent
} from '../../../../../../../middlewares/segment/trackEntities/questionEvents';

export const onSkip = (): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(authUserSetPricingPoll({ isShowed: true, isSkipped: false, lastTimeShowed: 0 }));
        dispatch(segmentTrackAction(SegmentUserEvent.PRICING_POLL_SKIPPED));
    };
    return action;
};
