import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { ICardAuthorHOCProps } from './types';
import { getCard } from '../../../../../../../store/model/selectors/getCard';
import { ICardAuthorEvents, ICardAuthorFields } from '../../components/CardAuthor/types';
import { CardAuthor } from '../../components/CardAuthor/CardAuthor';
import { getBoardIdByCardId } from '../../../../../../../store/model/selectors/getBoardIdByCardId';
import { getBoardUserByPermissionId } from '../../../../../../../store/model/selectors/getBoardUserByPermissionId';
import { getCardFullNameHelper } from 'app/store/model/card/helpers/getCardFullNameHelper';
import { onTemplateLinkClick } from 'app/view/react_components/aside_panel/cardDetails/Author/hocs/CardAuthorHOC/events/onTemplateLinkClick';
import { isEqual } from 'underscore';

const mapStateToProps = () => {
    interface ICaheProps extends ICardAuthorFields{};
    let cache: ICaheProps = null;
    const getCache = (
        value: ICaheProps
    ): ICaheProps => {
        if (isEqual(value, cache)) {
            return cache;
        }
        cache = value;
        return value;
    };

    return (
        state: IApplicationState,
        ownProps: ICardAuthorHOCProps
    ): ICardAuthorFields => {
        const { cardId } = ownProps;
        const card = getCard(state, cardId);
        const { author, created } = card;
        const boardUser = author && getBoardUserByPermissionId(state, getBoardIdByCardId(state, cardId), author.permissionId);
        let cardName = null;
        const copiedFromCard = card.templateId ? getCard(state, card.templateId) : null;
        const cardFullName = getCardFullNameHelper(copiedFromCard);
        cardName = cardFullName;

        return getCache({
            author,
            cardName,
            created,
            photoUrl: boardUser && boardUser.photoUrl || null,
        });
    };
};

const mapDispatchToProps = (
    dispatch: any,
    {boardId, cardId}: ICardAuthorHOCProps
): ICardAuthorEvents => ({
    onClick: () => dispatch(onTemplateLinkClick(boardId, cardId))
});

export const CardAuthorHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardAuthor);

CardAuthorHOC.displayName = 'CardAuthorHOC';
