import { ICard } from '../../../../store/model/card/types';

export const joinFullNames = (
    assignees: ICard['assignees']
): string => {
    if (assignees.length) {
        return assignees.map(item => item.sharedUser.fullName).join()
    }
    return '';
};
