import { AT_EDITED_FILTER_SET } from './constants';
import { IEditedFilterSetAction } from './types';
import { IFilter } from '../../filter/types';

export const editedFilterSet = (
    editedFilter: IFilter
): IEditedFilterSetAction => ({
        type: AT_EDITED_FILTER_SET,
        editedFilter
    }
);
