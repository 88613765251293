import { IApplicationState } from '../../../../types/types';
import { getAuthUser } from './getAuthUser';

export const getIsTrialOrFreemiumUser = (
    state: IApplicationState,
): boolean => {
    const { activeSubscription, domainWide } = getAuthUser(state);
    let isTrialOrFreemiumUser = (!activeSubscription || !activeSubscription.length) && !domainWide ; // freemium
    if (!isTrialOrFreemiumUser) {
        for (let i in activeSubscription) {
            const subscription = activeSubscription[i];
            if (subscription.isTrial) {
                isTrialOrFreemiumUser = true;
                break;
            }
        }
    }
    return isTrialOrFreemiumUser;
}
