import * as React from 'react';
import './_DateButton.scss';
import { IDateButtonProps } from './types';
import { Button, ButtonDropdown, Icon, Input } from 'kui';
import { CLASS_DATEPICKER } from '../constants';
import {
    DATEPICKER_DATE_PLACEHOLDER,
    DATEPICKER_FROM_PLACEHOLDER,
    DATEPICKER_TO_PLACEHOLDER,
    MAX_DAY,
    MAX_MONTH
} from './constants';
import { DateItem } from '../DateItem/DateItem';
import { getTwoDigitValue } from '../../helpers/getTwoDigitValue';
import { DateDropdown } from '../DateDropdown/DateDropdown';
import { getDaysInMonth } from './helpers/getDaysInMonth';
import { EDateType } from '../../../../../../../const';
import { EDatepickerDropdownDirectionX, EDatepickerDropdownDirectionY } from '../DatepickerItem/types';
import { DatepickerContext } from '../Datepicker/constants';
import { EDatepickerDirection } from '../Datepicker/types';
import * as moment from 'moment/moment';
import { getStringDate } from './helpers/getStringDate';
import { getParentsClasses } from '../../../../../helpers/getParentsClasses';
import { EUserDateformat } from '../../../../../../../types/rest/IRestAuthUser';

export const DateButton = ({
    date,
    dateType,
    defaultTime,
    fromDate,
    isHideDate,
    isOpened,
    isRequireError,
    maxFromDate,
    minToDate,
    placeholder,
    size,
    toDate,
    uniqueId,
    onBlur,
    onCalendarClosed,
    onCalendarOpened,
    onDateChange,
    onFocus,
    onKeyDown,
    onSetHoursType,
    onSetFocus,
    setTimeEditing
}: IDateButtonProps) => {
    const {
        dateFormat,
        isTime,
        direction,
        dropdownDirectionX = EDatepickerDropdownDirectionX.LEFT,
        dropdownDirectionY = EDatepickerDropdownDirectionY.AUTO,
        isNoDropdown,
        isCompact,
        isReadonly,
        translate,
        isSingle,
        maxDate,
        minDate,
        portalId,
        openedDateType,
        setCalendarProps
    } = React.useContext(DatepickerContext);

    const isAmericanDateFormat = dateFormat === EUserDateformat.FORMAT_AMERICAN;

    const className = CLASS_DATEPICKER + '__date-button';
    const classUnique = className + '--' + uniqueId;
    const classButton = className + '-create';
    const classItems = className + '-items';
    const classDropdown = className + '-dropdown';
    const classUniqueDropdown = classDropdown + '--' + uniqueId;
    const classDropdownType = classDropdown + '--' + (dateType === EDateType.DUE_DATE ? 'to' : 'from');
    const classInput = className + '-input';

    const [value, setValue] = React.useState('');
    const [isDateOpened, setDateOpened] = React.useState(isOpened);
    let [day, _setDay] = React.useState(null);
    let [month, _setMonth] = React.useState(null);
    let [year, _setYear] = React.useState(null);
    let [input, _setInput] = React.useState(null);
    let [isEditing, _setEditing] = React.useState(null);
    const [isInputChanged, setInputChanged] = React.useState(null);
    const [isItemEditing, setItemEditing] = React.useState(null);
    let [isSaveItemsOnEnter, _setSaveItemsOnEnter] = React.useState(null);

    const setEditing = (value: boolean) => {
        isEditing = value;
        _setEditing(value);
    };

    const setSaveItemsOnEnter = (value: boolean) => {
        isSaveItemsOnEnter = value;
        _setSaveItemsOnEnter(value);
    };

    const setDay = (value: string) => {
        day = value;
        _setDay(value);
    };

    const setMonth = (value: string) => {
        month = value;
        _setMonth(value);
    };

    const setYear = (value: string) => {
        year = value;
        _setYear(value);
    };

    const setInput = (value: string) => {
        input = value;
        _setInput(value);
    };

    const inputRef = React.useRef(null);
    const timerRef = React.useRef(null);
    const prevDateRef = React.useRef(null);
    const escRef = React.useRef(null);

    const setTime = (newDate: Date) => {
        if (!newDate) return;
        if (isTime) {
            if (!date) {
                if (defaultTime) {
                    newDate.setHours(defaultTime.getHours(), defaultTime.getMinutes());
                } else {
                    const now = new Date();
                    newDate.setHours(now.getHours(), now.getMinutes());
                }
            } else {
                newDate.setHours(date.getHours(), date.getMinutes());
            }
            onSetHoursType(newDate);
        }
        return newDate;
    }

    const getPossibleDate = (newDate: Date) => {
        if (
            dateType === EDateType.DUE_DATE && newDate <= fromDate ||
            dateType === EDateType.START_DATE && toDate && newDate >= toDate ||
            newDate.getTime() < 0
        ) {
            return prevDateRef.current;
        }
        return newDate;
    };

    const onInputChange = (e: React.SyntheticEvent) => {
        const target = e.target as HTMLInputElement;
        const value = target.value;
        setValue(value);
        let parsedDate = moment(value.trim(), isAmericanDateFormat ? 'M/D/YY hh:mm' : 'DD/MM/YY hh:mm');
        if (!parsedDate) return;
        if (!parsedDate.isValid()) {
            parsedDate = moment(value.trim(), isAmericanDateFormat ? 'M/D/YYYY hh:mm' : 'DD/MM/YYYY hh:mm');
        }
        if (parsedDate.isValid()) {
            if (getTwoDigitValue(new Date(parsedDate.valueOf()).getFullYear()) !== value.slice(-2)) {
                parsedDate = moment(value.trim(), isAmericanDateFormat ? 'M/D/YYYY hh:mm' : 'DD/MM/YYYY hh:mm');
            }
            let newDate = getPossibleDate(new Date(parsedDate.valueOf()));
            if (!newDate) return;
            const isOwnTime = value.includes(getTwoDigitValue(newDate.getHours())) && value.includes(getTwoDigitValue(newDate.getMinutes()));
            if (!isOwnTime) {
                newDate = setTime(newDate);
            }
            onDateChange(newDate, true);
            setInputChanged(true);
        }
    };

    const setItemsDate = (date: Date) => {
        setDay(getTwoDigitValue(date.getDate(), null, isAmericanDateFormat));
        setMonth(getTwoDigitValue(date.getMonth() + 1, null, isAmericanDateFormat));
        setYear(getTwoDigitValue(date.getFullYear()));
    };

    const onInputBlur = (e: React.FocusEvent) => {
        const targetClasses = getParentsClasses(
            e.relatedTarget as HTMLElement,
            [classUniqueDropdown]
        );
        if (!targetClasses.includes(classUniqueDropdown)) {
            onSetFocus(false);
        }
        if (e.relatedTarget.className.includes(CLASS_DATEPICKER + '__item-clear')) {
            const input = inputRef.current && inputRef.current.getInput();
            if (input) {
                input.focus();
                onSetFocus(true);
            }
        } else {
            setEditing(false);
        }
        if (isNoDropdown) {
            onSetFocus(false);
        }
    };

    const onInputFocus = (e: React.FocusEvent) => {
        onSetFocus(true);
        setDateOpened(true);
        if (onFocus) onFocus(e);
    };

    const onDateType = (e: React.KeyboardEvent) => {
        if (isEditing || isCompact) return;
        if (e.key === 'Escape') {
            e.preventDefault();
            const prevDate = prevDateRef.current;
            onDateChange(prevDate);
            escRef.current = true;
            if (prevDate) {
                setItemsDate(prevDate);
            }
        }
        if (e.key === 'Enter' && isSaveItemsOnEnter) {
            e.preventDefault();
            onSaveItems();
            onClose(false);
        }
        if (
            !isNoDropdown && !isDateOpened ||
            !/^\d+$/.test(e.key)
        ) {
            return;
        }
        const idx = input ? input.length : 0;
        if (idx === 5) {
            let newDate = new Date(date);
            newDate.setMonth(Number(month - 1));
            newDate.setFullYear(2000 + Number(input[input.length - 1] + e.key));
            let days = Number(day);
            const maxDaysInMonth = getDaysInMonth(newDate);
            if (days > maxDaysInMonth) {
                days = maxDaysInMonth;
            }
            newDate.setDate(days);
            onDateChange(newDate).then((isChanged) => {
                if (!isChanged) {
                    setItemsDate(date);
                }
                setInput(null);
                if (dateType === EDateType.DUE_DATE && !!fromDate || isSingle) {
                    onClose(false);
                }
            });
        } else {
            if (idx === 0) {
                if (isAmericanDateFormat) {
                    setMonth(e.key + month.slice(1));
                } else {
                    setDay(e.key + day.slice(1));
                }
            } else if (idx === 1) {
                if (isAmericanDateFormat) {
                    let newMonth = input + e.key;
                    if (Number(newMonth) > MAX_MONTH) {
                        newMonth = String(MAX_MONTH);
                    }
                    setMonth(newMonth);
                } else {
                    let newDay = input + e.key;
                    if (Number(newDay) > MAX_DAY) {
                        newDay = String(MAX_DAY);
                    }
                    setDay(newDay);
                }
            } else if (idx === 2) {
                if (isAmericanDateFormat) {
                    setDay(e.key + day.slice(1));
                } else {
                    setMonth(e.key + month.slice(1));
                }
            } else if (idx === 3) {
                if (isAmericanDateFormat) {
                    let newDay = input.slice(2) + e.key;
                    if (Number(newDay) > MAX_DAY) {
                        newDay = String(MAX_DAY);
                    }
                    setDay(newDay);
                } else {
                    let newMonth = input.slice(2) + e.key;
                    if (Number(newMonth) > MAX_MONTH) {
                        newMonth = String(MAX_MONTH);
                    }
                    setMonth(newMonth);
                }
            } else if (idx === 4) {
                setYear(e.key + year.slice(1));
            }
            setInput(input ? input + e.key : e.key);
        }
        setSaveItemsOnEnter(true);
    };

    const onInputKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === ' ') { // space closes dropdown
            e.preventDefault();
            setValue(value + ' ');
        }
        if (e.key === 'Escape') {
            onDateChange(prevDateRef.current);
            setDateOpened(false);
        }
        if (e.key === 'Tab' && date) {
            e.preventDefault();
            setEditing(false);
            setTimeEditing();
        }
    };

    const onInputEnter = () => {
        onSetFocus(false);
        setEditing(false);
        setDateOpened(false);
        onClose();
    };

    const onDateClick = (date: Date, isKeyDownEven?: boolean) => {
        if (isKeyDownEven && isSaveItemsOnEnter) return;
        onDateChange(setTime(date));
        setEditing(false);
        if (date) {
            onClose(false);
        }
    };

    const onClick = (e: React.SyntheticEvent) => {
        if (timerRef.current) clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            if (date) {
                const target = e.target as HTMLElement;
                const items = document.querySelector('.' + classUnique + ' .' + classItems);
                if (!items) return;
                const day = getTwoDigitValue(date.getDate(), null, isAmericanDateFormat);
                const month = getTwoDigitValue(date.getMonth() + 1, null, isAmericanDateFormat);
                if (items.children[0] === target) {
                    setInput('');
                }
                if (items.children[1] === target) {
                    if (isAmericanDateFormat) {
                        setInput(getTwoDigitValue(date.getMonth() + 1));
                        setMonth(month);
                    } else {
                        setInput(day);
                        setDay(day);
                    }
                } else if (items.children[2] === target) {
                    setInput(
                        isAmericanDateFormat ?
                            getTwoDigitValue(date.getMonth() + 1) + getTwoDigitValue(date.getDate())
                            : day + month
                    );
                    setDay(day);
                    setMonth(month);
                }
                setItemEditing(true);
                prevDateRef.current = date;
                onSelectEditItem();
            } else if (isNoDropdown) {
                setEditing(true);
            }
        }, 200);
    };

    const onDblClick = () => {
        if (timerRef.current) clearTimeout(timerRef.current);
        setEditing(true);
        setItemEditing(false);
        setDateOpened(true);
    };

    const onOpen = () => {
        if (onCalendarOpened) onCalendarOpened();
        setDateOpened(true);
        onSetFocus(true);
        if (!date) {
            setEditing(true);
        }
    };

    const onSaveItems = () => {
        if (!input || input.length === 6) return;
        const newDate = new Date(date);
        const newYear = Number(input[5] ? input[5] + year[1] : year);
        const monthInput = isAmericanDateFormat ? input.slice(0, 2) : input.slice(2, 4);
        let newMonth = Number(
            monthInput.length ?
                 monthInput.length === 1 && month.length > 1 ? monthInput + month[1] : monthInput
                : month
        );
        if (newMonth > MAX_MONTH) {
            newMonth = MAX_MONTH;
        }
        const dayInput = isAmericanDateFormat ? input.slice(2, 4) : input.slice(0, 2);
        let newDay = Number(
            dayInput.length ?
                dayInput.length === 1 && day.length > 1 ? dayInput + day[1] : dayInput
                : day
        );
        const maxDaysInMonth = getDaysInMonth(newDate);
        if (newDay > maxDaysInMonth) {
            newDay = maxDaysInMonth;
        }
        newDate.setDate(newDay);
        newDate.setMonth(Number(newMonth - 1));
        newDate.setFullYear(2000 + newYear);
        setInput('');
        onDateChange(newDate).then((isChanged) => {
            if (!isChanged) {
                setItemsDate(date);
            }
        });
    };

    const onSelectEditItem = () => {
        if (date) {
            setItemsDate(date);
            if (isItemEditing) {
                let newInput = '';
                if (input && input.length > 0) {
                    if (isAmericanDateFormat) {
                        const month = getTwoDigitValue(date.getMonth() + 1);
                        newInput += month;
                    } else {
                        const day = getTwoDigitValue(date.getDate());
                        newInput += day;
                    }
                }
                if (input && input.length > 2) {
                    if (isAmericanDateFormat) {
                        const day = getTwoDigitValue(date.getDate());
                        newInput += day;
                    } else {
                        const month = getTwoDigitValue(date.getMonth() + 1);
                        newInput += month;
                    }
                }
                if (input && input.length > 4) {
                    const year = getTwoDigitValue(date.getFullYear());
                    newInput += year[0];
                }
                setInput(newInput);
            }
        }
    };

    const onClose = (isChange = true) => {
        if (onCalendarClosed) onCalendarClosed();
        if (
            date &&
            !escRef.current &&
            isItemEditing &&
            isChange
        ) {
            onSaveItems();
        }
        if (isInputChanged) {
            onDateChange(date);
        }
        escRef.current = false;
        setInput(null);
        setDateOpened(false);
        setEditing(false);
        setItemEditing(false);
        setSaveItemsOnEnter(false);
        setInputChanged(false);
        if (!isNoDropdown) {
            onSetFocus(false);
        }
    };

    const setProps = () => {
        if (!isNoDropdown) return;
        setCalendarProps({
            date,
            fromDate,
            maxDate,
            maxFromDate,
            minDate,
            minToDate,
            isNoFocus: isEditing,
            toDate,
            onChange: onDateClick,
            onDateType,
            onBlur: onSaveItems,
            onArrowPress: () => setSaveItemsOnEnter(false)
        }, dateType);
        onSetFocus(true);
        setItemEditing(true);
    };

    const onButtonClick = (e: React.SyntheticEvent) => {
        if (!isNoDropdown) return;
        setProps();
        onClick(e);
    };

    const _date = date && date.getTime(); // Date перерендывается на каждый чих

    React.useEffect(() => {
        if (!isNoDropdown || openedDateType !== dateType) return;
        setProps();
    }, [_date]);

    React.useEffect(() => {
        if (!isNoDropdown) return;
        if (openedDateType !== dateType) {
            onSetFocus(false);
            setItemEditing(false);
        }
    }, [openedDateType]);

    React.useEffect(() => {
        if (!isDateOpened || !translate) return;
        const dropdown = document.querySelector('.' + classUniqueDropdown) as HTMLElement;
        if (dropdown) {
            if (dropdownDirectionX === EDatepickerDropdownDirectionX.LEFT) {
                dropdown.style.left = parseInt(dropdown.style.left) + translate + 'px';
            } else {
                dropdown.style.right = parseInt(dropdown.style.right) - translate + 'px';
            }
        }
        if (!date) {
            setEditing(true);
        } else {
            setItemEditing(true);
        }
    }, [isDateOpened]);

    React.useEffect(() => {
        onSelectEditItem();
    }, [_date, isItemEditing]);

    React.useEffect(() => {
        setDateOpened(isOpened);
        if (isOpened) {
            if (date) {
                setInput('');
                setItemEditing(true);
                prevDateRef.current = date;
                onSelectEditItem();
            } else {
                setEditing(true);
            }
        }
    }, [isOpened]);

    React.useEffect(() => {
        if (isEditing) {
            prevDateRef.current = date;
            setValue(date ? getStringDate(date, isAmericanDateFormat) : '');
            setTimeout(() => { //wait for input and dropdown in DOM
                const input = inputRef.current && inputRef.current.getInput();
                if (input) {
                    input.focus();
                    setInputChanged(false);
                }
            }, !date ? 100 : 150); // different time needed for dblclick
        }
    }, [isEditing]);

    React.useEffect(() => {
        if (!date) { //on clear
            setValue('');
            if (isDateOpened) {
                setEditing(true);
                const input = inputRef.current && inputRef.current.getInput();
                if (input) {
                    input.focus();
                }
            }
        }
    }, [_date]);

    React.useEffect(() => {
        const onClick = (e: MouseEvent) => {
            const target = e.target as HTMLElement
            const classDotButton = CLASS_DATEPICKER + '__calendar-header-dot-button'; // скрол происходит до, нет родителя
            if (target && target.classList && target.classList.contains(classDotButton)) return;
            const classDatepickerItem = CLASS_DATEPICKER + '__item--' + uniqueId;
            const classClear = CLASS_DATEPICKER + '__item-clear'; // может быть нажат в сосднем датапикере
            const classDropdown = 'kui-dropdown__item'; // дропдауны месяца, года
            const targetClasses = getParentsClasses(
                target,
                [
                    classUniqueDropdown,
                    classDatepickerItem,
                    classDropdown,
                    classUnique,
                    classClear
                ]
            );
            if (
                targetClasses.includes(classUniqueDropdown) ||
                targetClasses.includes(classDatepickerItem) ||
                targetClasses.includes(classDropdown) ||
                targetClasses.includes(classUnique) ||
                targetClasses.includes(classClear)
            ) {
                return;
            }
            onClose();
            document.removeEventListener('click', onClick);
        };

        if (isDateOpened) {
            setTimeout(() => { // to ignore initial click
                document.addEventListener('click', onClick);
            }, 0);
        } else {
            document.removeEventListener('click', onClick);
        }
    }, [isDateOpened]);

    React.useEffect(() => {
        if (!date) return;
        setItemsDate(date);
    }, [isDateOpened]);

    const button = !date && !isHideDate && !isEditing ?
        <Button
            className={`
                ${classButton}
                ${classButton + '--new'}
            `}
            variant={'icon-text'}
            text={placeholder ? placeholder
                : isSingle ?
                    DATEPICKER_DATE_PLACEHOLDER
                    : dateType === EDateType.DUE_DATE ?
                        DATEPICKER_TO_PLACEHOLDER
                        : DATEPICKER_FROM_PLACEHOLDER
            }
            disabled={isReadonly}
            onClick={isNoDropdown && onButtonClick}
        >
            <Icon  xlink={'plus'} size={24} />
        </Button>
        : <Button
            className={`${classButton} ${isNoDropdown ? classUnique : ''}`}
            variant={'text'}
            disabled={isReadonly}
            onClick={isNoDropdown && onButtonClick}
            onKeyDown={isNoDropdown && onDateType}
            onDoubleClick={isNoDropdown && onDblClick}
            onBlur={isNoDropdown && onSaveItems}
        >
            <>
                {isEditing ?
                    <Input
                        ref={inputRef}
                        autoFocus={isEditing}
                        className={classInput}
                        state={isRequireError ? 'error' : null}
                        value={value}
                        onFocus={onInputFocus}
                        onBlur={onInputBlur}
                        autosize={false}
                        readOnly={isReadonly}
                        onEnter={onInputEnter}
                        onKeyDown={onInputKeyDown}
                        onChange={onInputChange}
                        onClick={e => e.stopPropagation()} // чтобы дропдаун не закрывался
                    />
                    : <>
                        {(date || isHideDate) && !isEditing &&
                            <div className={classItems}>
                                {isCompact ?
                                    moment(date).format('DD MMM YY')
                                    : <>
                                        <DateItem
                                            value={isAmericanDateFormat ? month : day}
                                            isFocused={(!isNoDropdown || isOpened) && isItemEditing && (isDateOpened || (isNoDropdown && dateType === openedDateType)) && (!input || input.length <= 1)}
                                        />
                                        {day && '/'}
                                        <DateItem
                                            value={isAmericanDateFormat ? day : month}
                                            isFocused={(!isNoDropdown || isOpened) && isItemEditing && (isDateOpened || (isNoDropdown && dateType === openedDateType)) && input && (input.length >= 2 && input.length <= 3)}
                                        />
                                        {day && '/'}
                                        <DateItem
                                            value={year}
                                            isFocused={(!isNoDropdown || isOpened) && isItemEditing && (isDateOpened || (isNoDropdown && dateType === openedDateType)) && input && input.length >= 4}
                                        />
                                    </>
                                }
                            </div>
                        }
                    </>
                }
            </>
        </Button>
    ;

    if (isNoDropdown) {
        return button;
    }

    return (
        <ButtonDropdown
            className={`
                ${className}
                ${classUnique}
            `}
            dropdownClassName={`
                ${classDropdown}
                ${classUniqueDropdown}
                ${classDropdownType}
                ${isSingle ? classDropdown + '--single' : ''}
                ${isCompact ? classDropdown + '--compact' : ''}
                ${classDropdown + '--' + size}
                ${direction === EDatepickerDirection.COLUMN ? classDropdown + '--column' : ''}
                ${classDropdown + '--' + (dropdownDirectionX === EDatepickerDropdownDirectionX.LEFT ? 'left' : 'right')}
                ${date ? classDropdown + '--filled' : ''}
            `}
            opened={isDateOpened}
            onOpen={onOpen}
            onClose={onClose}
            disabled={isReadonly}
            directionVertical={dropdownDirectionY}
            directionHorizontal={dropdownDirectionX}
            isFitWindow
            dontChangeFocus
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            portal
            portalId={portalId}
            notBlurClasses={[
                CLASS_DATEPICKER + '__calendar-select-dropdown',
                CLASS_DATEPICKER + '__item-clear',
                classInput
            ]}
            onClick={onClick}
            onDoubleClick={onDblClick}
        >
            {button}
            <DateDropdown
                date={date}
                fromDate={fromDate}
                maxDate={maxDate}
                maxFromDate={maxFromDate}
                minDate={minDate}
                minToDate={minToDate}
                isNoFocus={isEditing}
                toDate={toDate}
                onChange={onDateClick}
                onDateType={onDateType}
                onArrowPress={() => setSaveItemsOnEnter(false)}
            />
        </ButtonDropdown>
    );
}
