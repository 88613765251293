import * as React from 'react';
import { ICardChecklistSortingProps } from './types';
import { CLASS_CARD_DETAILS } from '../../../constants';
import './_CardChecklistSorting.scss';
import { Button, Divider, Icon, SelectList, SelectListItem } from 'kui';
import { CARD_CHECKLIST_SORTING } from './constants';
import { CARD_CHECKLIST_ACTIONS_SORT } from '../CardChecklistActions/constants';
import { ECardChecklistOrderMode } from '../../../../../../../types/rest/IUserMeta';

export const CardChecklistSorting = ({
    selectedSorting,
    onBack,
    onChange,
    onClose
}: ICardChecklistSortingProps) => {
    const className = CLASS_CARD_DETAILS + '-checklist__sorting';
    const classItem = className + '-item';

    const onSelect = (value: ECardChecklistOrderMode) => {
        onChange(value);
        onClose();
    };

    const onKeyDown = (
        e: React.KeyboardEvent,
        value: ECardChecklistOrderMode
    ) => {
        if (e.key === 'Enter') {
            onSelect(value);
        }
    };

    React.useEffect(() => {
        const firstItem = document.querySelector('.' + className + ' .' + classItem) as HTMLElement;
        if (firstItem) {
            firstItem.focus();
        }
    }, []);

    return (
        <div className={className}>
            <div className={className + '-header'}>
                <div className={className + '-header-title'}>
                    <Icon xlink={'sort'} size={24} />
                    {CARD_CHECKLIST_ACTIONS_SORT}
                </div>
                <Button
                    variant={'icon'}
                    onClick={onBack}
                >
                    <Icon xlink={'arrow-left'} size={24} />
                </Button>
            </div>
            <Divider />
            <SelectList active={CARD_CHECKLIST_SORTING.findIndex(sorting => sorting.value === selectedSorting)}>
                {
                    CARD_CHECKLIST_SORTING.map(sorting => (
                        <SelectListItem
                            className={classItem}
                            onClick={() => onSelect(sorting.value)}
                            onKeyDown={e => onKeyDown(e, sorting.value)}
                        >
                            {sorting.name}
                        </SelectListItem>
                    ))
                }
            </SelectList>
        </div>
    );
};
