import { IGetState, TCardId } from 'app/types/types';
import { Dispatch, ThunkAction } from 'app/types/actions';
import { IDriveDoc } from 'app/store/model/card/types/IDriveDoc';
import { getBoardByCardId } from 'app/store/model/selectors/getBoardByCardId';
import * as moment from 'moment';
import { ERestDriveDocType } from 'app/types/rest/IRestDriveDoc';
import { msUploadLargeFile } from 'app/helper/authorisation/microsoft/filePicker/fileUploader';
import { DriveItem } from '@microsoft/microsoft-graph-types';
import { addAttachments } from 'app/rest/effects/card/attacments/addAttachments';
import { getDriveDocsByCardId } from 'app/store/model/driveDocs/selectors/getDriveDocsByCardId';
import { EAuthUserPlatformType } from 'app/types/rest/IRestAuthUser';
import { SegmentUserEvent, SegmentUserOption, SegmentUserOptionValue } from 'app/middlewares/segment/trackEntities/userEvents';
import { segmentTrackAction } from 'app/middlewares/segment';
import { msAuthManagerInstance } from 'app/helper/authorisation/microsoft/oauth/MSAuthManager';

export const uploadFiles = (
    cardId: TCardId,
    files: FileList | File[],
    progressCallback: (percent: number) => void,
    isPaste?: boolean,
    type: ERestDriveDocType = ERestDriveDocType.CARD,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(checkPermissions())
            .then(() => {
                const state = getState();
                const board = getBoardByCardId(state, cardId);
                const cardDriveDocs: IDriveDoc[] = getDriveDocsByCardId(state, cardId);
                let orderNumber = cardDriveDocs ? cardDriveDocs.length : 0;
                const parentFolderInfo = board && board.parentFolderInfo;
                const assetFolderId = board && board.assetFolderId;
                if (!parentFolderInfo || !assetFolderId) return;

                const filesArr = Array.from(files);
                const filesSizeTotal = getFilesTotalSize(filesArr);
                const filesSizeUploaded = filesArr.map(file => 0);

                const onProgress = (idx: number, uploaded: number) => {
                    filesSizeUploaded[idx] = uploaded;
                    let filesSizeUploadedSum = filesSizeUploaded.reduce((file, size) => size + file, 0);
                    let percent = Math.round(filesSizeUploadedSum / filesSizeTotal * 100);
                    if (percent > 100) percent = 100;
                    progressCallback(percent);
                }

                const fileUploadPromises = filesArr.map((_file, idx) => {
                    const file = isPaste
                        ? new File(
                            [_file],
                            'Image_' + moment(new Date).format('YYYY_MMM_DD_HH_mm_ss') + '.png',
                            {type: _file.type}
                        )
                        : _file;

                    return msUploadLargeFile(parentFolderInfo.driveId, assetFolderId, file, (uploaded) => onProgress(idx, uploaded));
                });

                return Promise.all(fileUploadPromises)
                    .then((files: DriveItem[]) => {
                        const driveDocs = files.map(file => createDriveDoc(file, cardId, orderNumber++, type));
                        return dispatch(addAttachments(cardId, driveDocs));
                    })
        });
    };
    return action;
};

const checkPermissions = (): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        return new Promise((resolve, reject) => {
            const eventOpt = {name: SegmentUserOption.TAGRET, value: SegmentUserOptionValue.ATTACHMENTS};
            const onShow = () => {
                dispatch(segmentTrackAction(SegmentUserEvent.PERMISSION_DRIVE_SCOPE_SHOWN, eventOpt));
            };
            const onRequest = () => {
                dispatch(segmentTrackAction(SegmentUserEvent.PERMISSION_DRIVE_SCOPE_REQUESTED, eventOpt));
            };
            const onCancel = () => {
                dispatch(segmentTrackAction(SegmentUserEvent.PERMISSION_DRIVE_SCOPE_CANCELED, eventOpt));
                reject();
            };
            const onApprove = () => {
                resolve(true);
            };
            msAuthManagerInstance.checkAndRequestUploadToDrive(onApprove, onShow, onCancel, onRequest);
        });
    };
    return action;
};

const createDriveDoc = (
    file: DriveItem,
    cardId: TCardId,
    orderNumber: number,
    type: ERestDriveDocType,
): IDriveDoc => {
    const mimeType = file.file && file.file.mimeType;
    const isImage = !!file.image || mimeType.includes('image/');

    const driveDoc: IDriveDoc = {
        cardId,
        orderNumber,
        type,

        driveId: file.parentReference && file.parentReference.driveId,
        fileId: file.id,
        title: file.name,
        isImage,
        mimeType,
        platformType: EAuthUserPlatformType.MICROSOFT,
        url: file.webUrl,
        //iconUrl,
    };
    return driveDoc;
}

const getFilesTotalSize = (files: File[]): number => {
    let result = 0;
    files.forEach(file => {
        result += file.size;
    });
    return result;
}
