const category = 'Pricing';

export const SegmentPricingEvent = {
    OPENED: { category, event: 'Pricing opened' },
    ANNUALLY_BUTTON: { category, event: 'Annually button clicked' },
    MONTHLY_BUTTON: { category, event: 'Monthly button clicked' },
    BASIC_MONTH: { category, event: 'Basic monthly pay button clicked' },
    BASIC_ANNUAL: { category, event: 'Basic annually pay button clicked' },
    PREMIUM_MONTH: { category, event: 'Premium monthly pay button clicked' },
    PREMIUM_ANNUAL: { category, event: 'Premium annually pay button clicked' },
    PROFESSIONAL_MONTH: { category, event: 'Professional monthly pay button clicked' },
    PROFESSIONAL_ANNUAL: { category, event: 'Professional annually pay button clicked' },
    ENTERPRISE_MONTH: { category, event: 'Enterprise monthly pay button clicked' },
    ENTERPRISE_ANNUAL: { category, event: 'Enterprise annually pay button clicked' },
    UPGRADE_BUTTON: { category, event: 'UPGRADE_BUTTON' },
    UPGRADE_BUTTON_MODAL: { category, event: 'Discount modal opened' },
    UPGRADE_BUTTON_PRICING_OPENED: { category, event: 'Pricing from discount modal opened' },
    BACK_BUTTON_CLICKED: { category, event: 'Back from Pricing clicked' },
    REQUEST_MODAL_SHOWN: { category, event: 'request modal shown' },
    BASIC_PLAN_SEND_REQUEST_CLICKED: { category, event:  'basic plan send request clicked'},
    MONTHLY_CLICKED: { category, event:  'monthly clicked'},
    ANNUALLY_CLICKED: { category, event:  'annually clicked'},

    CB_HOSTED_PAGE_LOADED: { category, event: 'CB Hosted page loaded' },
    CB_HOSTED_PAGE_ERROR: { category, event: 'CB Hosted page error' },
    CB_STEP: { category, event: 'CB step' },
    CB_SUCCESS: { category, event: 'CB success' },
    CB_CLOSE: { category, event: 'CB close' },
    ABANDONED_CART : { category, event: 'abandoned cart'}
}
export enum SegmentPricingOption {
    SOURCE = 'Source',
    STEP = 'Step',
    RESULT = 'result',
    PLAN = 'plan',
    LAST_STEP = 'step',
    REQUEST_MODAL_OPTION = 'Request option'
}

export enum SegmentPricingResultValue {
    SUCCESS = 'success',
    SKIPPED = 'skipped'
}

export enum SegmentPricingSourceValue {
    FREE_VERSION_MODAL = 'free version modal',
    FREE_VERSION_UPGRADE_BUTTON = 'free version upgrade button'
}

export enum SegmentBasicPlanSendRequestClickedValue {
    SECONDARY = 'secondary',
    DONT_USE = `don't use`,
    OTHER = 'other'
}
