export interface IHtmlEditorFields {
    placeholder?: string;
    selectionTree: number[];
    type?: EEditorType;
    value: string;
}

export interface IHtmlEditorEvents {
    onAddImage: (base64: string) => Promise<any>;
    onChange: (value: string) => void;
    onCtrlEnter?: () => void;
    onEnter?: () => void;
    onEscape?: () => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

export interface IHtmlEditorProps extends
    IHtmlEditorFields,
    IHtmlEditorEvents
{}

export enum EEditorType {
    NEW_COMMENT = 'NEW_COMMENT',
}
