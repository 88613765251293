import { IApplicationState, TCardId, TChecklistId } from '../../../../../types/types';
import { IChecklist } from '../../checklist/types';
import { getCardChecklists } from './getCardChecklists';

export const getChecklist = (
    state: IApplicationState,
    cardId: TCardId,
    checklistId: TChecklistId,
): IChecklist => {
    return getCardChecklists(state, cardId)[checklistId];
};
