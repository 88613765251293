import { EMPTY_ARRAY, IApplicationState, TBoardId } from '../../../../types/types';
import { getBoardMeta } from './getBoardMeta';
import { TColorTagColor } from '../../../../types/model';

export const getBoardCustomColors = (
    state: IApplicationState,
    boardId: TBoardId
): TColorTagColor[] => {
    const meta = getBoardMeta(state, boardId);
    return meta && meta.customColors || EMPTY_ARRAY;
};
