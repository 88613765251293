import { IRelatedCard } from '../../../components/RelatedCard/types';
import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { getCard } from '../../../../../../../store/model/selectors/getCard';
import { IGetState } from '../../../../../../../types/types';
import { cardsGetRest } from '../../../../../../../rest/effects/card/card/api/helper/cardsGetRest';
import { root } from '../../../../../../../store/constants';

export const onLoad = (
    relatedCard: IRelatedCard
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const cardId = relatedCard.cardId;
        const card = getCard(getState(), cardId);
        if (card) return;

        // сначала грузим карту, чтобы быстрее получить processingPercent
        dispatch(cardsGetRest([cardId])).then(() => {
            try {
                // затем грузим доску, чтобы подписаться на realTime
                root.App.controller.loadBoardForDashboardToStore(relatedCard.dashboardId, true, true, true, true, true);
            } catch(err) {}
        });
    };
    return action;
};
