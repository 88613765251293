'use strict';
import store, { dispatch } from '../store/configureStore';
import { reportsClickedStatisticEffect } from '../view/react_components/reports/statisticEffects/statisticEffects';
import ActiveRootView from './active_root_view';
import {
    AUTO_BACKUP_BOARD_BLOCKER_TEXT,
    AUTO_BACKUP_BOARD_BLOCKER_TITLE
} from '../view/react_components/aside_panel/boardDetails/backups/AutoBackups/components/AutoBackups/constants';
import { storeListener } from "../store/helpers/storeListener";
import {
    filterPanelActionSetAction
} from "../view/react_components/aside_panel/filterPanelBoard/store/filterPanelBoards/actions/filterPanelActionSetAction";
import { enabledSet } from "../view/react_components/aside_panel/filterPanelBoard/store/filterPanel/actions/enabledSet";
import {
    filterPanelBoardsSelector
} from "../view/react_components/aside_panel/filterPanelBoard/store/selectors/filterPanelBoardsSelector";
import { SegmentReportsEvent, segmentTrackAction } from "../middlewares/segment";
import { EViewTypes, VIEW_TYPES_OTHER, VIEW_TYPES_SWIM } from '../const';
import { getBoardPermissionsAllowEdit } from '../store/model/selectors/getBoardPermissionsAllowEdit';
import {
    panelOpenedSet
} from '../view/react_components/aside_panel/filterPanelBoard/store/filterPanel/actions/panelOpenedSet';
import { getUserDefaultViewWithout } from '../store/model/authUser/selectors/getUserDefaultViewWithout';
import { getBoard } from '../store/model/selectors/getBoardById';
import { getActiveBoardId } from '../store/model/selectors/getActiveBoardId';
import { KanbanDashboard } from '../view/kanban-view/kanban_dashboard';
import { ArchiveDashboard } from '../view/archive-view/archive_dashboard';
import { ReportsLazy } from '../view/react_components/reports/lazy';
import { ListViewDashboard } from '../view/list-view/list-view__dashboard';
import { UsersDashboardView } from '../view/list-grouping-view/users_dashboard';
import { ColorTagsDashboardView } from '../view/list-grouping-view/color_tags_dashboard';
import { CardColorsDashboardView } from '../view/list-grouping-view/card_colors_dashboard';
import { TagsDashboardView } from '../view/list-grouping-view/tags_dashboard';
import { PrioritiesDashboardView } from '../view/list-grouping-view/priorities_dashboard';
import {
    getTypeSwitcherActiveType
} from '../view/react_components/typeSwitcher/store/selectors/getTypeSwitcherActiveType';
import {
    getRealTypeSwitcherActiveType
} from '../view/react_components/typeSwitcher/store/selectors/getRealTypeSwitcherActiveType';
import { setSwitcherBoardType } from '../view/react_components/typeSwitcher/effects/setSwitcherBoardType';
import * as _ from 'underscore';
import { getProFeaturesTrialActive } from "../store/model/authUser/selectors/getProFeaturesTrialActive";
import { authUserSetUsedProFeatures } from "../rest/effects/authUser/authUserSetUsedProFeatures";
import { changeBackboneViewType } from 'app/view/react_components/typeSwitcher/effects/changeBackboneViewType';
import { GanttViewLazy } from 'app/view/react_components/gantt/ganttView/components/GanttViewLazy/GanttViewLazy';
import { BoardLoader } from './loader/boardLoader';
import { ETutorial } from "../types/rest/IUserMeta";
import { getAuthUser } from "../store/model/authUser/selectors/getAuthUser";

var App = window.App;

App.Views.DashboardViewManager = App.Views.BaseView.extend({
    className: 'board hidden',

    initialize: function(options) {

        this.board = options.board;
        this.typePrevious = null;
        this.createdTypes = {};
        this.realtimeView = new App.Classes.DashboardRealtime({board: this.board});
        this.boardId = this.board.id;
        this.storeListeners = [];
        this.initEventListner();
        _.bindAll(this, 'onChangeLockStatusFocusin');

        this.isInitiatorBoardUpdatePermissions = null;
    },

    /**
     * Функция используется только для логического разделения инициализации переменных и слушателей событий
     */
    initEventListner: function() {
        App.vent.on(App.vent['dashboard:viewTypeChanged'], this.onChangeBoardViewType, this);
        App.vent.on(App.vent['dashboard:activated'], this.activate, this);
        App.vent.on(App.vent['dashboard:deactivated'], this.deactivate, this);
        App.vent.on(App.vent['card:formClosed'], this.onCardFormClosed);
        //this.listenTo(this.model, App.vent['dashboard:doFilter'], this.doFilter);
        //this.listenTo(this.model, App.vent['dashboard:doSortAndFilter'], this.doSort);
        this.listenTo(App.vent, App.vent['user:timeFormatChanged'], this.onUserTimeFormatChanged);
        //this.listenTo(this.model, 'change:lockDate', this.onChangeLockStatus);

        if (this.isDevEnv()) {
            //this.listenTo(this.model, 'change:name', this.testDataUpdate);

            const boardNameSelector = (state) =>{
                return getBoard(state, this.boardId).name;
            };

            const boardNameListener = () =>{
                this.testDataUpdate()
            };

            this.storeListeners.push(storeListener(
                store,
                boardNameSelector,
                boardNameListener
            ));

        }

        const lockDateSelector = (state) =>{
            return getBoard(state, this.boardId).lockDate;
        };

        const lockDateListener = (lockDate) =>{
            this.onChangeLockStatus(lockDate)
        };

        this.storeListeners.push(storeListener(
            store,
            lockDateSelector,
            lockDateListener
        ));


        // TODO: check this
        /*const filterPanelSelector = (state) => {
            return filterPanelBoardsSelector(state, this.boardId)
        };

        const filterPanelListener = () => {
            this.doSort();
        };

        this.unsubscribeFilterPanelBoard = storeListener(
            store,
            filterPanelSelector,
            filterPanelListener);*/

        const infoPanelOpenedSelector = (state) =>{
            return filterPanelBoardsSelector(state, this.boardId).isOpened;
        };

        const infoPanelOpenedListener = (opened) =>{
            this.filterPanelToggled(opened);
        };

        this.storeListeners.push(storeListener(
            store,
            infoPanelOpenedSelector,
            infoPanelOpenedListener
        ));

        // TODO: check this
        /*const infoSavedFilterSelector = (state) =>{
            return savedFilterListSelector(state, this.boardId);
        };

        const infoSavedFilterListener = () =>{
            this.doSort();
        };

        this.unsubscribeSavedFilterListener = storeListener(
            store,
            infoSavedFilterSelector,
            infoSavedFilterListener,
            deepEqualityCheck
        );*/

        const filterPanelEnabledSelector = (state) => {
            const filterPanel = filterPanelBoardsSelector(state, this.boardId);
            const isEnabled = filterPanel.isEnabled;
            const isSearch = !!(
                !isEnabled &&
                filterPanel.defaultFilter.searchedKeyWord &&
                !filterPanel.isOpened
            );
            this.$el.toggleClass('board--with-filter-panel--search', isSearch);
            if (isSearch) {
                this.$el.removeClass('board--with-filter-panel--disabled');
            }
            return isEnabled;
        };

        const filterPanelEnabledListener = (isEnabled) => {
            if (!isEnabled){
                this.$el.addClass('board--with-filter-panel--disabled');
            } else {
                this.$el.addClass('board--with-filter-panel');
                this.$el.removeClass('board--with-filter-panel--disabled');
            }
        };

        this.storeListeners.push(storeListener(
            store,
            filterPanelEnabledSelector,
            filterPanelEnabledListener
        ))
    },

    /**
     * ночью застревает блокер
     * есть подозрения, что из-за режима гибернации
     * попробую запрашивать доску на document.focusin (таба браузера активна)
     * и если там уже нет lockDate, значит застряло
     */
    onChangeLockStatusFocusin: function () {
        new BoardLoader(this.boardId).load(null, false, true, true, true)
            .then((board) => {
                if (board && !board.lockDate) {
                    document.location.reload();
                }
            })
    },

    onChangeLockStatus: function (lockDate) {
        if (lockDate === undefined) {
            lockDate = getBoard(store.getState(), this.boardId).lockDate;
        }
        if (lockDate) {
            const activeBoardId = getActiveBoardId(store.getState());
            if (this.boardId === activeBoardId) {
                App.router.navigate(App.router.getUrl(null), {trigger: true});
            }
            App.controller.mainView.renderBlockerModal({
                classNameBlocked: 'page--board-blocked',
                container: document.querySelector('.content__inner'),
                text: AUTO_BACKUP_BOARD_BLOCKER_TEXT,
                title: AUTO_BACKUP_BOARD_BLOCKER_TITLE,
            });
            document.addEventListener('focusin', this.onChangeLockStatusFocusin, true);
        } else {
            App.controller.mainView.removeBlockerModal();
            document.removeEventListener('focusin', this.onChangeLockStatusFocusin, true);
        }
    },

    onUserTimeFormatChanged: function() {
        const activeBoardId = getActiveBoardId(store.getState());
        if(this.boardId === activeBoardId) {
            var delayed = _.bind(function () {
                this.delayRender();
            }, this);
            this.hideViews(delayed);
        }
    },

    render: function() {
        this.showView();
        this.onChangeLockStatus();
        return this;
    },

    afterDOMRendering: function() {
        this.createdTypes[this.getViewType()].afterDOMRendering();
    },

    /**
     * Скрывает все типы вьюшек
     */
    hideViews: function(callback) {
        for (var key in this.createdTypes) {

            const isReactView = !this.createdTypes[key].deactivate;

            if (!isReactView) {
                this.createdTypes[key].deactivate(this.createdTypes[key].boardId);
            } else {
                if(this.activeRoot){
                    this.activeRoot.remove();
                    this.activeRoot = null;
                }
            }
            delete this.createdTypes[key];
        }
        App.controller.showSystemLoader(true);
        if (callback && _.isFunction(callback)) {
            callback();
        }
    },

    /**
     * Деактивация всех типов отображения кроме указанного и Активация указанного.
     */
    showView: function() {
        var view = this.getView();
        const isReactView = !view.$el;
        if (isReactView) {
            this.activeRoot = new ActiveRootView({
                className: 'react-view',
                view: view,
                store: store,
                boardId: this.boardId,
            });
            this.$el.append(this.activeRoot.$el);
            this.activeRoot.render();
        } else {
            this.$el.append(view.$el);
            view.activate(view.boardId);
            this.getView().afterDOMRendering();
            this.$el.attr('id', 'dashboard' + this.boardId);
        }
        const viewType = this.getViewType();
        
        if (viewType === 'ReportsWorkspace') {
            dispatch(reportsClickedStatisticEffect());
            dispatch(segmentTrackAction(SegmentReportsEvent.OPEN));
        }
        if (App.controller.mainView.relatedOpened) {
            App.vent.trigger(App.vent['view:resized']); // to setPanelsWidth
        }
    },

    /**
     * Деактивация типа отображения
     * @param dashboard App.Views.AbstractDashboard
     */
    deactivate: function(boardId) {
        if (boardId === this.boardId) {
            const viewType = this.getViewType();
            if (!_.isUndefined(this.createdTypes[viewType])) {
                if (this.createdTypes[viewType].deactivate) {
                    this.createdTypes[viewType].deactivate(boardId);
                }  else {
                    if(this.activeRoot){
                        this.activeRoot.remove();
                        this.activeRoot = null;
                    }
                }
                delete this.createdTypes[viewType];
            }
            this.$el.addClass('hidden');
        }
    },

    delayRender: _.debounce(function () {

        this.render();
    }, 200),

    /**
     * Активация типа отображения
     * @param dashboard App.Views.AbstractDashboard
     */
    activate: function(boardId) {
        if (boardId === this.boardId) {
            this.$el.removeClass('hidden');
            this.delayRender();
        }
    },

    /**
     * Гетер для получения типа текущего отображения
     * @returns {string|*}
     */
    getViewType: function() {
        return getTypeSwitcherActiveType(store.getState(), this.boardId)
    },

    /**
     * Сеттер для типа текущего отображения
     * @returns {string|*}
     */
    setViewType: function(type) {
        const state = store.getState();
        if (getProFeaturesTrialActive(state)) {
            let newUsedFeature;
            if (type === EViewTypes.ARCHIVE_VIEW_TYPE) {
                newUsedFeature = 'allowArchive';
            } else if (type === EViewTypes.GANTT_VIEW_TYPE) {
                newUsedFeature = 'allowGantt';
            } else if (type === EViewTypes.REPORTS_VIEW_TYPE) {
                newUsedFeature = 'allowReports';
            } else if (VIEW_TYPES_OTHER.includes(type)) {
                newUsedFeature = 'allowTeamWorkload';
            } else if (VIEW_TYPES_SWIM.includes(type)) {
                newUsedFeature = 'allowSwimlanes';
            }
            if (newUsedFeature) {
                dispatch(authUserSetUsedProFeatures(newUsedFeature));
            }
        }
        var typePrevious = (type === EViewTypes.ARCHIVE_VIEW_TYPE)
            ? this.getViewType() : null; // set typePrevious only if new type is Archive
        this.setViewTypePrevious(typePrevious);
        const user = getAuthUser(state);
        if (
            type === EViewTypes.GANTT_VIEW_TYPE &&
            (user['allowGantt'] || getProFeaturesTrialActive(state))
        ) {
            App.controller.showTutorial(ETutorial.GANTT_VIEW_TUTORIAL);
        } else if (
            type === EViewTypes.LIST_VIEW_TYPE &&
            user['allowListView']
        ) {
            App.controller.showTutorial(ETutorial.LIST_VIEW_TUTORIAL);
        } else if (
            type === EViewTypes.ARCHIVE_VIEW_TYPE &&
            (user['allowArchive'] || getProFeaturesTrialActive(state))
        ) {
            App.controller.showTutorial(ETutorial.ARCHIVE_TUTORIAL);
        }
        dispatch(setSwitcherBoardType(this.boardId, type));
        return this;
    },

    getViewTypePrevious: function() {
        return this.typePrevious;
    },

    setViewTypePrevious: function(type) {
        this.typePrevious = type;
        return this;
    },

    /**
     * Геттер (Синглтон), который возвращает ранее созданный объект отображения доски (dashboard view), или же
     * сначала его создаёт и затем возвращает, если такового не было инициализированно на данный момент
     * @returns App.Views.AbstractDashboard
     */
    getView: function() {
        var type = this.getViewType();

        if (type === EViewTypes.USERS_VIEW_TYPE) {
            const allowEdit = getBoardPermissionsAllowEdit(store.getState(), this.boardId);
            if (!allowEdit) {
                type = getUserDefaultViewWithout(store.getState(), [EViewTypes.REPORTS_VIEW_TYPE, EViewTypes.USERS_VIEW_TYPE]);
                dispatch(changeBackboneViewType(type));
            }
        }
        if (_.isUndefined(this.createdTypes[type])) {
            this.createdTypes[type] = this.createView(type);
        }
        const savedViewType = getRealTypeSwitcherActiveType(store.getState(), this.boardId);
        if (!savedViewType) {
            dispatch(setSwitcherBoardType(this.boardId, type));
        }
        return this.createdTypes[type];
    },

    /**
     * Метод фабрика для создания App.Views.AbstractDashboard
     */
    createView: function(type) {
        const typeView = App.Views.DashboardViewManager.TYPE_VIEWS[type];
        const isReactView = typeView.isReactView;

        if (!isReactView) {
            return new typeView.constructor({
                boardId: this.boardId,
                parent: this,
                sessionId: this.sessionId,
                realtimeView: this.realtimeView
            });
        } else {
            return typeView.constructor;
        }
    },

    /**
     * Метод который вызывает отрисовку дешборд вью менеджера в случае если был сменен тип отображения у текущей доски
     * @param dashboard
     */
    onChangeBoardViewType: function(dashboard) {
        if (dashboard.id === this.boardId) {
            App.Util.clearTooltips();
            var delayedRender = _.bind(this.delayRender, this);
            this.hideViews(delayedRender);

            //this.doFilter();
        }
    },


    /**
     * Включение или выключение поиска у текущего отображения.
     * @param data
     */
    filterPanelToggled: function(opened) {
        if (opened){
            this.$el.addClass('board--with-filter-panel');
        } else {
            this.$el.removeClass('board--with-filter-panel');
            this.$el.removeClass('board--with-filter-panel--disabled');
        }
        const view = this.getView();
        if (view.filterPanelToggled) {
            view.filterPanelToggled();
        }
    },

    onCardFormClosed: function(model) {
        model.formOpened = false;
    },

    testAttributes: function() {
        return {
            type: 'view-manager',
            dashboard: this.board.name,
            hash: this.board.hash,
            view: this.getViewType()
        };
    },

    getIsInitiatorBoardUpdatePermissions: function() {
        return this.isInitiatorBoardUpdatePermissions;
    },
    setIsInitiatorBoardUpdatePermissions: function(value) {
        this.isInitiatorBoardUpdatePermissions = value;
    },

    remove: function() {
        for (var key in this.createdTypes) {
            if (this.createdTypes[key].remove) {
                this.createdTypes[key].remove();
            }
        }
        if(this.activeRoot){
            this.activeRoot.remove();
            this.activeRoot = null;
        }
        dispatch(filterPanelActionSetAction(this.boardId, panelOpenedSet(false)));
        dispatch(filterPanelActionSetAction(this.boardId, enabledSet(false)));
        if (this.realtimeView) {
            this.realtimeView.remove();
        }
        if (this.isInitiatorBoardUpdatePermissions) {
            this.isInitiatorBoardUpdatePermissions = null;
        }
        this.storeListeners.forEach(unsubscribe => unsubscribe());
        App.vent.off(null, null, this);
        return Backbone.View.prototype.remove.call(this);
    }
}, {
    TYPE_VIEWS: {
        [EViewTypes.GANTT_VIEW_TYPE]: {
            constructor: GanttViewLazy,
            isReactView: true
        },
        [EViewTypes.KANBAN_VIEW_TYPE]: {
            constructor: KanbanDashboard,
            isReactView: false
        },
        [EViewTypes.LIST_VIEW_TYPE]: {
            constructor: ListViewDashboard,
            isReactView: false
        },
        [EViewTypes.ARCHIVE_VIEW_TYPE]: {
            constructor: ArchiveDashboard,
            isReactView: false
        },
        [EViewTypes.REPORTS_VIEW_TYPE]: {
            constructor: ReportsLazy,
            isReactView: true
        },
        [EViewTypes.USERS_VIEW_TYPE]: {
            constructor: UsersDashboardView,
            isReactView: false
        },
        [EViewTypes.COLOR_TAGS_VIEW_TYPE]: {
            constructor: ColorTagsDashboardView,
            isReactView: false
        },
        [EViewTypes.CARD_COLORS_VIEW_TYPE]: {
            constructor: CardColorsDashboardView,
            isReactView: false
        },
        [EViewTypes.TAGS_VIEW_TYPE]: {
            constructor: TagsDashboardView,
            isReactView: false
        },
        [EViewTypes.PRIORITIES_VIEW_TYPE]: {
            constructor: PrioritiesDashboardView,
            isReactView: false
        },

        [EViewTypes.SWIM_COLOR_TAGS_VIEW_TYPE]: {
            constructor: KanbanDashboard,
            isReactView: false
        },
        [EViewTypes.SWIM_TAGS_VIEW_TYPE]: {
            constructor: KanbanDashboard,
            isReactView: false
        },
    }
});
App.Views.DashboardViewManager.mixin(App.Mixins.Test);
