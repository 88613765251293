import { getCardSubcardsCount } from './getCardSubcardsCount';
import { IApplicationState, TCardId } from '../../../types/types';

export const getCardIsEpic = (
    state: IApplicationState,
    cardId: TCardId,
): boolean => {
    const subcardsCount = getCardSubcardsCount(state, cardId);
    return subcardsCount > 0;
}
