import {ActionCreator} from 'redux';
import {Dispatch, ThunkAction} from '../../../../../../../types/actions';
import {IGetState} from '../../../../../../../types/types';
import { panelTypeSetAction } from '../../../store/navigationPanel/actions/panelTypeSetAction';
import { ENavigationPanelTypes, NP_DEFAULT_TYPE } from '../../../constants';

export const setMobile: ActionCreator<ThunkAction> = (
    isMobile: boolean
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const panelType = isMobile
            ? ENavigationPanelTypes.NP_MOBILE_TYPE
            : NP_DEFAULT_TYPE;
        dispatch(panelTypeSetAction(panelType));
    };
    return action;
};
