import { IGetState, TBoardId, TCardId } from './../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import {
    SegmentCardEvent,
    SegmentCardOptions,
    SegmentCardSection,
    segmentTrackAction
} from '../../../../../../../../middlewares/segment';
import { getSectionType } from '../../../../selectors/getSectionType';
import { ESettingsSectionType } from '../../../../../../base/components/SettingsSection/types';
import { disallowedSectionClickedEvent } from '../../../../effects/segmentEvents';
import { getCard } from '../../../../../../../../store/model/selectors/getCard';
import { cardToggleGantt } from './../../../../../../../../rest/effects/card/card/cardToggleGantt';
import { showSnoozeBlocker } from '../../../../../../base/effects/showSnoozeBlocker';
import { SegmentCardSourceValue } from 'app/middlewares/segment/trackEntities/cardEvents';
import { root } from '../../../../../../../../store/constants';
import { ETutorial } from '../../../../../../../../types/rest/IUserMeta';
import {
    getProFeaturesTrialActive
} from '../../../../../../../../store/model/authUser/selectors/getProFeaturesTrialActive';
import { authUserSetUsedProFeatures } from '../../../../../../../../rest/effects/authUser/authUserSetUsedProFeatures';
import {
    getAuthUserIsShowGanttContextHints
} from '../../../../../../../../store/model/authUser/selectors/getAuthUserIsShowGanttContextHints';
import { EHintKey } from '../../../../../../hints';
import { setHintCanShow } from '../../../../../../hints/effects/setHintCanShow';
import { GANTT_CONTEXT_TIMEOUT } from '../../../../../../hints/components/Hint/constants';
import { getAuthUser } from '../../../../../../../../store/model/authUser/selectors/getAuthUser';
import { hintHide } from '../../../../../../hints/effects/hintHide';
import {
    ganttContextHintsCardIdSetAction
} from '../../../../../asidePanel/store/actions/ganttContextHintsCardIdSetAction';

export const onClick = (
    boardId: TBoardId,
    cardId: TCardId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.BUTTONS_PANEL_BUTTON_CLICKED, {
            name: SegmentCardOptions.TARGET,
            value: SegmentCardSection.SHOW_ON_GANTT
        }));

        const state = getState();

        if (getAuthUserIsShowGanttContextHints(state)) {
            dispatch(ganttContextHintsCardIdSetAction(cardId));
            dispatch(hintHide(EHintKey.GANTT_CONTEXT_SHOW_ON_GANTT));
        }

        const sectionType = getSectionType(state, boardId, cardId, null, 'allowGantt');
        const isDisallow = sectionType === ESettingsSectionType.DISALLOW;

        if (isDisallow) {
            dispatch(showSnoozeBlocker({
                allow: 'allowGantt'
            }));
            dispatch(disallowedSectionClickedEvent(SegmentCardSection.SHOW_ON_GANTT, SegmentCardSourceValue.BUTTONS_PANEL));
        } else {
            const card = getCard(state, cardId);
            if (!card.ganttVisibility) {
                dispatch(segmentTrackAction(SegmentCardEvent.SHOW_ON_GANTT_ON));
                if (getAuthUserIsShowGanttContextHints(state)) {
                    setTimeout(() => {
                        if (card.startDate || card.dueDate) {
                            dispatch(setHintCanShow(EHintKey.GANTT_CONTEXT_GANTT_TYPE_SWITCHER));
                        } else {
                            dispatch(setHintCanShow(EHintKey.GANTT_CONTEXT_ADD_DATE));
                        }
                    }, GANTT_CONTEXT_TIMEOUT);
                }
            } else {
                dispatch(segmentTrackAction(SegmentCardEvent.SHOW_ON_GANTT_OFF));
            }
            dispatch(cardToggleGantt(cardId));
            if (getProFeaturesTrialActive(state)) {
                dispatch(authUserSetUsedProFeatures('allowGantt'));
            }
            const user = getAuthUser(state);
            if (user['allowGantt'] || getProFeaturesTrialActive(state)) {
                root.App.controller.showTutorial(ETutorial.GANTT_VIEW_TUTORIAL);
            }
        }
    };
    return action;
};
