import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { ICardColorTagsHOCOwnProps } from './types';
import { ICardColorTagsEvents, ICardColorTagsFields } from '../../components/CardColorTags/types';
import { CardColorTags } from '../../components/CardColorTags/CardColorTags';
import { onChange } from '../events/onChange';
import { getCardColorTags } from './../../../../../../../store/model/selectors/getCardColorTags';
import {
    getBoardColorsByStatus
} from '../../../../../../../store/model/board/selectors/getBoardColorsByStatus';
import { onOpenSettings } from '../events/onOpenSettings';
import { getBoardCardColorTags } from '../../../../../../../store/model/selectors/getBoardCardColorTags';
import { getCard } from '../../../../../../../store/model/selectors/getCard';
import { TStatus } from '../../../../../../../types/model';
import { onDropdownOpen } from './events/onDropdownOpen';
import { onDoubleClick } from './events/onDoubleClick';
import { onClose } from './events/onClose';
import { getAuthUser } from '../../../../../../../store/model/authUser/selectors/getAuthUser';
import { isEqual } from 'underscore';

// const analyze = new Analyze('CardColorTagsHOC');
// 10 раз открыл-закрыл карту
// reselect    average: 0.106 ms, calls: 120, total: 12.700 ms
// cache       average: 0.112 ms, calls: 120, total: 13.500 ms

const mapStateToProps = () => {
    interface ICaheProps extends ICardColorTagsFields{};
    let cache: ICaheProps = null;
    const getCache = (
        value: ICaheProps
    ): ICaheProps => {
        if (isEqual(value, cache)) {
            return cache;
        }
        cache = value;
        return value;
    };

    return (
        state: IApplicationState,
        ownProps: ICardColorTagsHOCOwnProps
    ): ICardColorTagsFields => {
        // analyze.start();
        const { boardId, cardId } = ownProps;
        const { allowColorTagCount } = getAuthUser(state);

        const isArchive = getCard(state, cardId).status === TStatus.STATUS_ARCHIVE;
        const boardCardColors = getBoardCardColorTags(state, boardId, isArchive);
        const colorTags = getCardColorTags(state, cardId);

        const ret = getCache({
            aloudCount: allowColorTagCount,
            boardColors: getBoardColorsByStatus(state, boardId),
            colorTags,
            boardCardColors,
        });
        // analyze.finish();
        return ret;
    };
}

const mapDispatchToProps = (
    dispatch: any,
    { cardId }: ICardColorTagsHOCOwnProps
): ICardColorTagsEvents => (
    {
        onClose: () => dispatch(onClose()),
        onChange: (colorId, source) => dispatch(onChange(cardId, colorId, source)),
        onDoubleClick: () => dispatch(onDoubleClick()),
        onOpenDropDown: (used, all, source) => dispatch(onDropdownOpen(used, all, source)),
        onOpenSettings: () => dispatch(onOpenSettings())
    }
);

export const CardColorTagsHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardColorTags);

CardColorTagsHOC.displayName = 'CardColorTagsHOC';
