'use strict';
import * as _ from 'underscore';
import { TStatus } from '../../types/model';
import { getAuthUser } from '../../store/model/authUser/selectors/getAuthUser';
import { getAppState } from '../../store/configureStore';

App.Classes.StatHelper = Backbone.View.extend({
    initialize: function() {

    },

    /**
     * Отправляет на сервер статистики данные по пользователю
     * @param data - объект данных для отправки на сервер
     * @param user - user от которого отпраивть данные
     */
    sendUserStat: function(data, user) {
    	if(!user){
    		user = getAuthUser(getAppState());
    	}
        var statUserModel = App.statHelper.getUserStatisticModel(user);
        statUserModel.save(data);
    },

    sendCardStatNonBackboneModels: function(board, list, cardCountDelta, cardDoneCountDelta, delay) {
        var allowReports = board.permissions.authPermissions.allowSendReportStatistics;
        if (allowReports) {
            cardCountDelta = cardCountDelta || 0;
            cardDoneCountDelta = cardDoneCountDelta || 0;
            var dashboardTime = board.timeZone;
            var timeStamp = new Date().getTime() - App.model.get('timeLag') || new Date().getTime();
            let data = {
                "date": App.Util.getTimeBasedOnDashboardTimeZone(dashboardTime),
                "dashboardId": board.hash,
                "listId": list.id,
                "cardCount": App.statHelper.getActiveCardCountNonBackbone(list.cards) + cardCountDelta,
                "cardDoneCount": App.statHelper.getCardDoneCountActiveCardsNoneBackbone(list.cards) + cardDoneCountDelta,
                "timeStamp": timeStamp,
            };
            let statListModel = App.statHelper.getCardStatisticModel();
            if (delay){
                setTimeout(() => {
                    statListModel.save(data);
                }, delay)
            } else{
                statListModel.save(data);
            }
        }
    },

    getCurrentTimeLag: function() {
        var serverTimeModel = new App.Models.DashboardStatisticLag();
        var timeSendRequest = new Date().getTime();
        serverTimeModel.fetch({
            success:  function(model) {
                var timeReceiveResponse = new Date().getTime();
                var serverTime = model.get('timeNow') + ((timeReceiveResponse - timeSendRequest)/4);
                var timeLag = Math.round(new Date().getTime() - serverTime);
                App.model.set('timeLag', timeLag);
            }
        });
    },

    getActiveCardCountNonBackbone: function(cards) {
        var result = 0;
        cards.forEach(function(card){
            if(card.status === TStatus.STATUS_ACTIVE){
                result++;
            }
        })
        return result;
    },

    /**
     * Получает модель типа ЮзерСтатистики
     * @param user
     * @returns {*}
     */
    getUserStatisticModel: function(user) {
        return this.getModelByType('user', {user: user.hash})
    },

    /**
     * Получает модель типа Статистики по количеству карточек
     * @returns {*}
     */
    getCardStatisticModel: function() {
        return this.getModelByType('list')
    },

    /**
     * Получает модель которая посылает запросы на свой рест на комет
     * @param type - тип модели комет сервера
     * @param options параметры модели
     * @returns {*}
     */
    getModelByType: function(type, options) {
        switch(type){
            case 'burnUp':
                // return new App.Models.CardByList({
                //     dashboard: options.dashboard
                // });
                break;
            case 'user':
                return new App.Models.StatUser(options);
                break;
            case 'list' :
                return new App.Models.StatReport();
                break;
        }
        return false;
    },
    
    getCardDoneCountActiveCardsNoneBackbone: function(cards){
        var result = 0;
        cards.forEach(function (card, i, arr) {
            if(card.processingStatus == 'done' && card.status ==  TStatus.STATUS_ACTIVE){
                result++;
            }
        });
        return result;
    },

    /**
     * Google Analytics events
     */
    trackEvent: function(category, action) {
        if (category === Messages.getText('ga.category.time_tracker')) {
            App.statHelper.sendUserStat(_.object([
                App.Classes.StatHelper.TIME_TRACKER_USED_COUNT,
                App.Classes.StatHelper.TIME_TRACKER_USED_FDATE,
                App.Classes.StatHelper.TIME_TRACKER_USED_LDATE
            ], ['+1', 'ifnull' + App.Util.nowTrimTimestampGMT(),
                App.Util.nowTrimTimestampGMT()
            ]));
        } else if (category === Messages.getText('ga.category.list_view')) {
            App.statHelper.sendUserStat(_.object([
                App.Classes.StatHelper.LIST_VIEW_USED_COUNT,
                App.Classes.StatHelper.LIST_VIEW_USED_FDATE,
                App.Classes.StatHelper.LIST_VIEW_USED_LDATE
            ], ['+1', 'ifnull' + App.Util.nowTrimTimestampGMT(),
                App.Util.nowTrimTimestampGMT()
            ]));

        }

    }
    }, {

    // PROFILE
    PAYMENT_COMPLETED_COUNT: "paymentCompletedCount",
    PAYMENT_COMPLETED_DATE: "paymentCompletedDate",
    PAYMENT_CANCELED_COUNT: "paymentCanceledCount",
    PAYMENT_CANCELED_DATE: "paymentCanceledDate",
    PAYMENT_INITIATED_COUNT: "paymentInitiatedCount",
    PAYMENT_INITIATED_DATE: "paymentInitiatedDate",
    PAYMENT_STARTED_COUNT: "paymentStartedCount",
    PAYMENT_STARTED_DATE: "paymentStartedDate",
    FIRST_VISIT_DASHBOARD_CREATED: "firstVisitDashboardCreated",
    CARD_CREATE_COUNT: "cardCreateCount",
    CARD_CREATE_DATE: "cardCreateDate",
    PROMOCODE_APPLIED_COUNT: "promoCodeAppliedCount",
    PROMOCODE_APPLIED_DATE: "promoCodeAppliedDate",
    DASHBOARD_PUSH_NOTIFICATION_ACTIVATION_DATE: "dashboardPushNotificationActivationDate",
    DASHBOARD_NOTIFICATION_ACTIVATION_DATE: "dashboardNotificationActivationDate",
    RATE_COUNT: "rateCount",
    RATE_DATE: "rateDate",
    TIME_ZONE: 'timezone',
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    FULL_NAME: 'fullName',
    INITIALS: 'initials',
    EMAIL: 'email',
    DOMAIN: 'domain',
    ACCEPT_LANGUAGE: 'acceptLanguage',
    REGION: 'region',
    COUNTRY: 'country',
    CREATE_DATE: 'createDate',
    CREATE_DATE_TIMESTAMP: 'createDateTimestamp',
    CITY: 'city',
    PHOTO_URL: 'photoUrl',
    ALLOW_TIME_TRACKER: 'allowTimeTracker',
    ALLOW_BRANDING: 'allowBranding',
    ALLOW_GANTT: 'allowGantt',
    ALLOW_CHAT_SUPPORT: 'allowChatSupport',
    ALLOW_CARD_FROM_EMAIL: 'allowCardFromEmail',
    ALLOW_GOOGLE_SHEET_EXPORT: 'allowGoogleSheetExport',
    ALLOW_PRIORITY_AUTO_SORTING: 'allowPriorityAutoSorting',
    ALLOW_DASHBOARD_BACKGROUND: 'allowDashboardBackground',
    ALLOW_BACKUP_XML: 'allowBackupXml',
    ALLOW_SWIMLANES: 'allowSwimlanes',
    LAST_LOGIN_DATE: 'lastLoginDate',
    LOGIN_COUNT: 'loginCount',
    GANTT_TOGGLE_CLICKED_COUNT: 'ganttToggleClickedCount',
    GANTT_TOGGLE_CLICKED_FDATE: "ganttToggleClickedFdate",
    GANTT_TOGGLE_CLICKED_LDATE: "ganttToggleClickedLdate",
    GANTT_OPEN_COUNT: 'ganttOpenCount',
    GANTT_OPEN_FDATE: "ganttOpenFdate",
    GANTT_OPEN_LDATE: "ganttOpenLdate",
    TIME_TRACKER_USED_COUNT: "timeTrackerUsedCount",
    TIME_TRACKER_USED_FDATE: "timeTrackerUsedFdate",
    TIME_TRACKER_USED_LDATE: "timeTrackerUsedLdate",
    LIST_VIEW_USED_COUNT: "listViewUsedCount",
    LIST_VIEW_USED_FDATE: "listViewUsedFdate",
    LIST_VIEW_USED_LDATE: "listViewUsedLdate"

});
