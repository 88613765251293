import {IApplicationState} from '../../../../../../../types/types';
import {getCard} from '../../../../../../../store/model/selectors/getCard';
import {getSectionType} from '../../../selectors/getSectionType';
import {ESettingsSectionType} from '../../../../../base/components/SettingsSection/types';
import {TStatus} from '../../../../../../../types/model';
import {connect} from 'react-redux';
import {IArchiveButtonEvents, IArchiveButtonFields} from '../../components/ArchiveButton/types';
import {IArchiveButtonHOCProps} from './types';
import {ArchiveButton} from '../../components/ArchiveButton/ArchiveButton';
import {onClick} from './events/onClick';
import {onUnarchive} from './events/onUnarchive';
import {CLASS_NAME_BUTTON_HIDE_TITLE, MIN_WIDTH_DISPLAY_ARCHIVE} from '../../../ActionsInHeadSection/constants';
import {getAsidePanelEntityPanelWidth} from '../../../../asidePanel/store/selectors/getAsidePanelEntityPanelWidth';
import { getTypeSwitcherActiveType } from '../../../../../typeSwitcher/store/selectors/getTypeSwitcherActiveType';
import { EViewTypes } from '../../../../../../../const';
import { getShowProFeaturesTrial } from '../../../../../../../store/model/authUser/selectors/getShowProFeaturesTrial';
import { getIsUserBasicPlanOnly } from '../../../../../../../store/model/authUser/selectors/getIsUserBasicPlanOnly';

const mapStateToProps = (
    state: IApplicationState,
    { cardId, boardId }: IArchiveButtonHOCProps
): IArchiveButtonFields => {
    const {status} = getCard(state, cardId);
    const sectionType = getSectionType(state, boardId, cardId, null, 'allowArchive', null, false);
    const isDisallow = sectionType === ESettingsSectionType.DISALLOW;
    const isShow = (
        sectionType === ESettingsSectionType.DEFAULT ||
        isDisallow
    );
    const isArchiveView = getTypeSwitcherActiveType(state, boardId) === EViewTypes.ARCHIVE_VIEW_TYPE;
    const hideArchiveButtonTitle = !isArchiveView && getAsidePanelEntityPanelWidth(state) < MIN_WIDTH_DISPLAY_ARCHIVE;

    return {
        isArchived: status === TStatus.STATUS_ARCHIVE,
        isBasicFeature: getIsUserBasicPlanOnly(state),
        isDisallow,
        isProFeature: getShowProFeaturesTrial(state),
        isShow,
        isShowTooltip: hideArchiveButtonTitle,
        className: hideArchiveButtonTitle ? CLASS_NAME_BUTTON_HIDE_TITLE : ''
    }
};
const mapDispatchToProps = (
    dispatch: any,
    { boardId, cardId }: IArchiveButtonHOCProps
): IArchiveButtonEvents => {
    return {
        onClick: () => dispatch(onClick(boardId, cardId)),
        onUnarchive: () => dispatch(onUnarchive(cardId))
    }
};
export const ArchiveButtonsInHeadHOC = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ArchiveButton);

ArchiveButtonsInHeadHOC.displayName = 'ArchiveButtonsInHeadHOC';
