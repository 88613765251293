import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { cardUpdatePinnedSection } from '../../../../../../../../rest/effects/card/card/cardUpdatePinnedSection';
import { getCardPinnedSections } from '../../../../../../../../store/model/selectors/getCardPinnedSections';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../../../../types/types';
import { getCard } from '../../../../../../../../store/model/selectors/getCard';

export const onPinToggle = (
    cardId: TCardId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const isPinned = getCardPinnedSections(getCard(getState(), cardId)).subcards;
        if (isPinned) {
            dispatch(segmentTrackAction(SegmentCardEvent.SUBCARDS_UNPIN_CLICKED));
        } else {
            dispatch(segmentTrackAction(SegmentCardEvent.SUBCARDS_PIN_CLICKED));
        }
        return dispatch(cardUpdatePinnedSection(cardId, { subcards: !isPinned }));
    };
    return action;
};
