import {IApplicationState} from '../../../../../../types/types';
import {NavigationPanelTabs} from '../../containers/NavigationPanelTabs/NavigationPanelTabs';
import {connect} from 'react-redux';
import {
    INavigationPanelTabsDispatchProps,
    INavigationPanelTabsStateProps
} from '../../containers/NavigationPanelTabs/types';
import { getTabs } from '../../store/selectors/getTabs';
import { getNumberKeys } from '../../../../../../helper/getNumberKeys';

const mapStateToProps = (
    state: IApplicationState
): INavigationPanelTabsStateProps => {
    const tabs = getTabs(state);
    let tab;
    if (tabs) {
        const tabIds = getNumberKeys(tabs);
        if (tabIds.length) tab = tabs[tabIds[0]];
    }

    return {
        tab
    }
};

const mapDispatchToProps = (): INavigationPanelTabsDispatchProps => ({});

export const NavigationPanelTabsHOC = connect(mapStateToProps, mapDispatchToProps)(NavigationPanelTabs);
NavigationPanelTabsHOC.displayName = 'NavigationPanelTabsHOC';
