import { IApplicationState } from '../../../../../../types/types';

export const getSavedEnabledFiltersCountSelector = (
    state: IApplicationState,
): number => {
    let result = 0;
    for(const filterId in state.filters.savedFilters) {
        if (state.filters.savedFilters[filterId].isEnabled) {
            result++;
        }
    }
    return result;
};
