import { ThunkAction } from '../../../types/actions';
import { fetchHandler } from '../../../util/fetchHandler';
import { getRestHeadersGet } from '../../helpers/getRestHeadersHelper';

export const authUserInWorkCardFixDuplicates = (): ThunkAction => {
    const action = () => {
        return fetchHandler('/rest/userInWorkCardRemoveDuplicates',
            {...getRestHeadersGet()})
    }
    return action;
}
