import { IFilter, IInterval } from '../../react_components/aside_panel/filterPanelBoard/store/filter/types';
import {
    FP_KEY_ANY,
    FP_KEY_COMMENTS_KEY_WITH_ATTACHMENTS,
    FP_KEY_COMMENTS_KEY_WITH_COMMENTS,
    FP_KEY_COMMENTS_KEY_WITHOUT_ATTACHMENTS,
    FP_KEY_COMMENTS_KEY_WITHOUT_COMMENTS,
    FP_KEY_HIDE_FILTER_DONE_CARDS,
    FP_KEY_HIDE_FILTER_EPICS,
    FP_KEY_HIDE_FILTER_SUBCARDS,
    FP_KEY_SHOW_FILTER_DONE_CARDS,
    FP_KEY_SHOW_FILTER_EPICS_CARDS,
    FP_KEY_SHOW_FILTER_OVERDUE_CARDS,
    FP_KEY_SHOW_FILTER_SUBCARDS,
    FP_KEY_SHOW_FILTER_UNDONE_CHECKLISTS,
    FP_NO_COLOR,
    FP_NO_COLOR_TAG,
    FP_NO_ROLE,
    FP_UNASSIGNED_USER,
    MY_WORK_SHOW_FILTERS_MAP,
} from '../../react_components/aside_panel/filterPanelBoard/constants';
import * as moment from 'moment';
import { ICard } from '../../../store/model/card/types';
import { isCardEpic } from '../../../store/model/card/selectors/isCardEpic';
import { getCardFullNameHelper } from '../../../store/model/card/helpers/getCardFullNameHelper';
import store from '../../../store/configureStore';
import { NO_PRIORITY } from '../../react_components/helpers/constants';
import { getCardAllUserRoleIdsByCard } from '../../../store/model/selectors/getCardAllUserRoles';
import { ICardAssignee } from '../../../store/model/cardAssignee/types';
import { IApplicationState } from '../../../types/types';
import { getAuthUser } from '../../../store/model/authUser/selectors/getAuthUser';
import { getCardChecklistItems } from '../../../store/model/checklists/checklists/selectors/getCardChecklistItems';
import {
    getMyTaskCardProcessingStatus
} from '../../react_components/main/myWorkView/helpers/getMyTaskCardProcessingStatus';

export const isCardMatchFilter = (
    state: IApplicationState,
    card: ICard,
    filter: IFilter
): boolean => {
    if (filter.isEnabled) {
        const isMatchName = isKeywordMatch(filter, getCardFullNameHelper(card), card.description);
        let isMatchCreated = isDateMatchInterval(card.created, filter.created);
        let isMatchStarted = isDateMatchInterval(card.startDate, filter.started);
        let isMatchDoneDate = isDateMatchInterval(card.doneDate, filter.doneDate);
        let isMatchDueDate = isDateMatchInterval(card.dueDate, filter.dueDate);
        const isMatchPriority = isPriorityMatch(filter, card.priority);
        const isMatchCardColor = isCardColorMatch(filter, card.backgroundColor);
        let isMatchAssignedTo = false;
        let isMatchTag = false;
        let isMatchColor = false;
        let isMatchRole = false;
        let isMatchComments = false;
        let isMatchAttachments = false;
        let isMatchNotSubcard = false;
        let isMatchNotEpic = false;
        let isMatchSubcard = false;
        let isMatchUndoneChecklistItems = false;
        let isMatchEpic = false;
        let isMatchNotDone = false;
        let isMatchDone = false;
        // let isMatchNotOverdue = false;
        let isMatchOverdue = false;
        let isMatchShowFilter = false;

        if (filter.assignees.length === 0) {
            isMatchAssignedTo = true;
        } else {
            if ((!card.assignees || !card.assignees.length) && filter.assignees.indexOf(FP_UNASSIGNED_USER.sharedUser.permissionId) > -1) {
                isMatchAssignedTo = true;
            } else {
                const assignees = card.assignees.map(assignees => assignees.sharedUser);
                assignees.forEach((user: any) => {
                    if (filter.assignees.indexOf(user.permissionId) > -1) {
                        isMatchAssignedTo = true
                    }
                })
            }
        }

        if (filter.tags.length === 0) {
            isMatchTag = true;
        } else {
            const tags = card.tags || [];
            tags.forEach((item: string) => {
                if (filter.tags.indexOf(item) > -1) {
                    isMatchTag = true;
                }
            })
        }

        if (filter.colorTags.length === 0) {
            isMatchColor = true
        } else {
            if (!card.colorIds.length && filter.colorTags.indexOf(FP_NO_COLOR_TAG.id) > -1) {
                isMatchColor = true;
            } else {
                card.colorIds.forEach((colorId) => {
                    if (filter.colorTags.indexOf(colorId) > -1) {
                        isMatchColor = true;
                    }
                })
            }
        }

        if (filter.roles.length === 0) {
            isMatchRole = true
        } else {
            const cardRoleIds = getCardAllUserRoleIdsByCard(card);
            if (!cardRoleIds.length && filter.roles.indexOf(FP_NO_ROLE.id) > -1) {
                isMatchRole = true;
            } else {
                cardRoleIds.forEach((roleId) => {
                    if (filter.roles.indexOf(roleId) > -1) {
                        isMatchRole = true;
                    }
                })
            }
        }

        if (!filter.comments) {
            isMatchComments = true;
        } else {
            switch (filter.comments) {
                case FP_KEY_ANY: {
                    isMatchComments = true;
                    break;
                }
                case FP_KEY_COMMENTS_KEY_WITHOUT_COMMENTS: {
                    if (!card.commentCount) {
                        isMatchComments = true;
                    } else {
                        isMatchComments = false
                    }
                    break;
                }
                case FP_KEY_COMMENTS_KEY_WITH_COMMENTS: {
                    if (card.commentCount) {
                        isMatchComments = true;
                    } else {
                        isMatchComments = false;
                    }
                    break;
                }
                default: {
                    isMatchComments = true;
                    break;
                }
            }
        }

        if (!filter.attachments) {
            isMatchAttachments = true;
        } else {
            switch (filter.attachments) {
                case FP_KEY_ANY: {
                    isMatchAttachments = true;
                    break;
                }
                case FP_KEY_COMMENTS_KEY_WITHOUT_ATTACHMENTS: {
                    if (!card.driveDocCount) {
                        isMatchAttachments = true;
                    } else {
                        isMatchAttachments = false
                    }
                    break;
                }
                case FP_KEY_COMMENTS_KEY_WITH_ATTACHMENTS: {
                    if (card.driveDocCount) {
                        isMatchAttachments = true;
                    } else {
                        isMatchAttachments = false;
                    }
                    break;
                }
                default: {
                    isMatchAttachments = false;
                    break;
                }
            }
        }
        if (filter.hide.length === 0) {
            isMatchNotDone = true;
            isMatchNotSubcard = true;
            isMatchNotEpic = true;
        } else {
            if (filter.hide.includes(FP_KEY_HIDE_FILTER_DONE_CARDS)) {
                if (card.processingStatus !== 'done') {
                    isMatchNotDone = true;
                }
            } else {
                isMatchNotDone = true;
            }
            if (filter.hide.includes(FP_KEY_HIDE_FILTER_SUBCARDS)) {
                if (!card.epicId) {
                    isMatchNotSubcard = true;
                }
            } else {
                isMatchNotSubcard = true;
            }
            if (filter.hide.includes(FP_KEY_HIDE_FILTER_EPICS)) {
                if (!isCardEpic(store.getState(), card)) {
                    isMatchNotEpic = true;
                }
            } else {
                isMatchNotEpic = true;
            }
        }
        if (filter.show.length === 0) {
            isMatchShowFilter = true;
        } else {
            if (filter.show.includes(FP_KEY_SHOW_FILTER_DONE_CARDS)) {
                if (card.processingStatus === 'done') {
                    isMatchDone = true;
                } // иначе осталась в false
            }
            if (filter.show.includes(FP_KEY_SHOW_FILTER_OVERDUE_CARDS)) {
                if (
                    card.processingStatus !== 'done' &&
                    card.dueDate &&
                    card.dueDate * 1000 < Date.now()
                ) {
                    isMatchOverdue = true;
                }
            }
            if (filter.show.includes(FP_KEY_SHOW_FILTER_EPICS_CARDS)) {
                if (isCardEpic(store.getState(),card)) {
                    isMatchEpic = true;
                }
            }
            if (filter.show.includes(FP_KEY_SHOW_FILTER_SUBCARDS)) {
                if (card.epicId) {
                    isMatchSubcard = true;
                }
            }
            if (
                state &&
                filter.show.includes(FP_KEY_SHOW_FILTER_UNDONE_CHECKLISTS)
            ) {
                const checkItems = getCardChecklistItems(state, card.id);
                const permissionId = getAuthUser(state).permissionId;

                for (let i = 0; i < checkItems.length; i++) {
                    if (isMatchUndoneChecklistItems) break;

                    const { checked, assignees } = checkItems[i];

                    if (!checked && assignees) {
                        for (let user of assignees) {
                            if (user.sharedUser.permissionId === permissionId) {
                                isMatchUndoneChecklistItems = true;
                                break;
                            }
                        }
                    }
                }
            }
            isMatchShowFilter = isMatchDone || isMatchOverdue || isMatchEpic || isMatchSubcard || isMatchUndoneChecklistItems;
        }
        return isMatchName &&
            isMatchAssignedTo &&
            isMatchTag &&
            isMatchCreated &&
            isMatchStarted &&
            isMatchDoneDate &&
            isMatchDueDate &&
            isMatchColor &&
            isMatchRole &&
            isMatchComments &&
            isMatchAttachments &&
            isMatchPriority &&
            isMatchNotDone &&
            isMatchNotSubcard &&
            isMatchNotEpic &&
            isMatchShowFilter &&
            isMatchCardColor
    }
    return true;
};

const isKeywordMatch = (
    filter: IFilter,
    name: string,
    description: string = null
): boolean => {
    let isMatch;
    if (filter.searchedKeyWord) {
        const fullName = name.toLowerCase();
        const keyword = filter.searchedKeyWord.toLowerCase();
        isMatch = fullName.indexOf(keyword) > -1;
        if (!isMatch) { // try description
            isMatch = !!description && description.toLowerCase().indexOf(keyword) > -1;
        }
    } else {
        isMatch = true;
    }
    return isMatch;
}

const isDateMatchInterval = (
    date: number,
    interval: IInterval
): boolean => {
    if (!interval || (!interval.from && !interval.to)) {
        return true;
    } else {
        const createdFromTimestamp = interval.from && Number(moment(interval.from).format('X'));
        const createdToTimestamp = interval.to && Number(moment(interval.to).add(24, 'hours').format('X'));
        if (date) {
            if ((!createdFromTimestamp || (date >= createdFromTimestamp)) &&
                (!createdToTimestamp || (date <= createdToTimestamp))) {
                return true;
            }
        }
    }
    return false;
}

const isPriorityMatch = (
    filter: IFilter,
    priority: string
): boolean => {
    if (filter.priority.length === 0) {
        return true;
    } else {
        if (!priority && filter.priority.indexOf(NO_PRIORITY) > -1) {
            return true;
        } else {
            if (filter.priority.indexOf(priority) > -1) {
                return true;
            }
        }
    }
    return false;
}

const isCardColorMatch = (
    filter: IFilter,
    backgroundColor: string
): boolean => {
    if (filter.cardColors.length === 0) {
        return true
    }
    return filter.cardColors.includes(backgroundColor || FP_NO_COLOR);
}

export const isMyTasksCardMatchFilter = (
    cardAssignee: ICardAssignee,
    filter: IFilter
): boolean => {
    if (filter.isEnabled) {
        const isMatchName = isKeywordMatch(filter, getCardFullNameHelper(cardAssignee));
        const isMatchDueDate = isDateMatchInterval(cardAssignee.dueDate, filter.dueDate);
        const isMatchPriority = isPriorityMatch(filter, cardAssignee.priority);
        const isMatchCardColor = isCardColorMatch(filter, cardAssignee.backgroundColor);
        let isMatchAssignedTo = false;
        let isMatchColor = false;
        let isMatchShowFilter = false;
        let isMatchCardsType = false;

        const myTasksCards = filter.meta && filter.meta.myWorkCards || [];
        const myTasksColorTags = filter.meta && filter.meta.myWorkColorTags || [];

        if (filter.assignees.length === 0) {
            isMatchAssignedTo = true;
        } else {
            if (!cardAssignee.assignees.length && filter.assignees.indexOf(FP_UNASSIGNED_USER.sharedUser.permissionId) > -1) {
                isMatchAssignedTo = true;
            } else {
                cardAssignee.assignees.forEach((user) => {
                    if (!isMatchAssignedTo && filter.assignees.findIndex(permissionId => user.sharedUser.permissionId.indexOf(permissionId) > -1) > -1) {
                        isMatchAssignedTo = true
                    }
                })
            }
        }

        if (!myTasksCards.length) {
            isMatchCardsType = true;
        } else {
            cardAssignee.types.forEach(type => {
                if (myTasksCards.includes(type)) {
                    isMatchCardsType = true;
                }
            })
        }

        if (myTasksColorTags.length === 0) {
            isMatchColor = true
        } else {
            if (!cardAssignee.colorTags.length && myTasksColorTags.findIndex(colorTag => !colorTag.color) > -1) {
                isMatchColor = true;
            } else {
                cardAssignee.colorTags.forEach((colorTag) => {
                    if (myTasksColorTags.findIndex(myTasksColorTag => myTasksColorTag.name === colorTag.name && myTasksColorTag.color === colorTag.color) > -1) {
                        isMatchColor = true;
                    }
                })
            }
        }

        if (filter.show.length === 0) {
            isMatchShowFilter = true;
        } else {
            const processingStatus = getMyTaskCardProcessingStatus(cardAssignee.startDate, cardAssignee.dueDate, cardAssignee.processingPercent);
            isMatchShowFilter = filter.show.reduce<boolean>((result, value) => {
                return result || MY_WORK_SHOW_FILTERS_MAP[value] === processingStatus;
            }, false);
        }
        return isMatchName &&
            isMatchAssignedTo &&
            isMatchDueDate &&
            isMatchColor &&
            isMatchPriority &&
            isMatchShowFilter &&
            isMatchCardColor &&
            isMatchCardsType
    }
    return true;
};
