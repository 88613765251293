import { TStatus } from '../../../../types/model';
import { IApplicationState, TBoardId } from '../../../../types/types';
import { getBoardCards } from 'app/store/model/selectors/getBoardCards';

export const getBoardBackgroundColors = (
    state: IApplicationState,
    boardId: TBoardId
): string[]  => {
    const cards = getBoardCards(state, boardId, TStatus.STATUS_ACTIVE);
    const backgroundColors = cards.reduce((colors, card) => {
        if (card.backgroundColor) {
            colors.push(card.backgroundColor);
        }
        return colors;
    }, []);
    return [...new Set(backgroundColors)];
}
