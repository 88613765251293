import {IClickManagerModel} from '../../types';
import {TClickManagerModelAction} from '../actions/types';
import {AT_CLICK_MANAGER_MODEL_INITIAL_STATE_SET, AT_SELECTED_CARD_IDS_SET} from '../actions/constatns';
import * as deepFreeze from 'deep-freeze';

const initialState: IClickManagerModel = {
    isMultiselect: false,
    selectedCardIds: []
};

const _clickManagerModelReducer = (
    state = initialState,
    action: TClickManagerModelAction
): IClickManagerModel => {
    switch (action.type) {
        case AT_SELECTED_CARD_IDS_SET: {
            //что лишний раз не обновлять стейт.
            if (state.selectedCardIds.length === 0 && action.selectedCardIds.length === 0)
                return {
                    ...state,
                    isMultiselect: false
                }
            return {
                ...state,
                isMultiselect: action.isMultiselect,
                selectedCardIds: [...action.selectedCardIds]
            }
        }
        case AT_CLICK_MANAGER_MODEL_INITIAL_STATE_SET: {
            return initialState;
        }
        default:
            return state;
    }
};

export const clickManagerModelReducer = (
    state = initialState,
    action: TClickManagerModelAction
): IClickManagerModel => {
    // @ts-ignore
    if(window.Settings.development) {
        // @ts-ignore
        return deepFreeze(_clickManagerModelReducer(state, action));
    } else {
        return _clickManagerModelReducer(state, action);
    }
};
