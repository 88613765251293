import { CommonAuthManager } from 'app/helper/authorisation/common/CommonAuthManager';
import { clearWebPushUser } from 'app/helper/webPushNotify';
import { SegmentProfileBoxEvent, segmentResetAction, segmentTrackAction } from 'app/middlewares/segment';
import { root } from 'app/store/constants';
import { Dispatch, ThunkAction } from 'app/types/actions';

export function onClickSignOut(
): ThunkAction {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(segmentTrackAction(SegmentProfileBoxEvent.LOGOUT));
        dispatch(segmentResetAction());
        root.App.controller.showSystemLoader(true);
        root.App.Helpers.Intercom.userLogout();
        clearWebPushUser();
        setTimeout(()=>{
            root.App.controller.mainView.onUserLogout();
            CommonAuthManager.logout();
        }, 2000);
    };
    return action;
};
