import { IGantContextHintsCardIdSetAction } from './types';
import { AT_GANTT_CONTEXT_HINTS_CARD_ID_SET } from './constants';
import { TCardId } from '../../../../../../types/types';

export const ganttContextHintsCardIdSetAction = (
    cardId: TCardId,
): IGantContextHintsCardIdSetAction => ({
    type: AT_GANTT_CONTEXT_HINTS_CARD_ID_SET,
    cardId
});
