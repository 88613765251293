import { IGetState, TCardId, TChecklistId, TChecklistItemId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { itemRestDelete } from './api/itemRestDelete';
import { checklistItemDeleteStore } from '../../../../store/model/checklists/actionCreators/checklistItemDelete';
import { sendRealTimeStoreActionByCardId } from '../../../../view/react_components/base/helpers/realTimeHelperTS';
import { cardChecklistStatsUpdate } from '../../../../store/model/effects/cardChecklistStatsUpdate';
import { cardsActiveRequestsUpdate } from '../../../../store/requestsState/effects/cardsActiveRequestsUpdate';
import { ICards } from '../../../../store/model/cards/types';
import { getChecklistItem } from '../../../../store/model/checklists/checklist/selectors/getChecklistItem';
import { checklistItemAddStore } from '../../../../store/model/checklists/actionCreators/checklistItemAdd';

export const checklistItemDelete = (
    cardId: TCardId,
    checklistId: TChecklistId,
    itemId: TChecklistItemId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(cardsActiveRequestsUpdate({ [cardId]: {} } as ICards, 1));
        const checklistItemForRollBack = getChecklistItem(getState(), cardId, checklistId, itemId);
        dispatch(checklistItemDeleteStore(cardId, checklistId, itemId));
        return dispatch(itemRestDelete(cardId, itemId))
            .then(() => {
                const action = checklistItemDeleteStore(cardId, checklistId, itemId);
                dispatch(action);
                dispatch(sendRealTimeStoreActionByCardId(cardId, action));
                dispatch(cardChecklistStatsUpdate(cardId));
            })
            .catch((e: any) => {
                console.error(e);
                dispatch(checklistItemAddStore(cardId, checklistItemForRollBack));
            })
            .finally(() => {
                dispatch(cardsActiveRequestsUpdate({ [cardId]: {} } as ICards, -1));
            });
    };
    return action;
};
