import { ICard } from '../../../../../store/model/card/types';
import { IBoard } from '../../../../../store/model/board/types';
import { IList } from '../../../../../store/model/list/types';
import { IAuthUser } from '../../../../../store/model/authUser/types';
import { root } from '../../../../../store/constants';

export const sendCardMovedNotification = (
    card: ICard,
    destBoard: IBoard,
    destList: IList,
    sourceList: IList,
    user: IAuthUser
) => {
    try {
        const model = new App.Models.NotificationMove({
            newDashboardId: destBoard.id,
            cardName: card.name,
            cardId: card.id,
            card: {
                id: card.id,
                name: card.name,
                listName: destList.name,
                listId: destList.id
            },
            dashboardId: destBoard.id,
            dashboardName: destBoard.name,
            newList: {
                id: destList.id,
                name: destList.name
            },
            oldList: {
                id: sourceList.id,
                name: sourceList.name
            },
            time: Math.floor(Date.now() / 1000),
            user: user
        });
        root.App.vent.trigger(root.App.vent['notify:show'], model);
    } catch (e) {
        console.error(e);
    }
};
