import { connect } from 'react-redux';
import { getNumberKeys } from '../../../../../../../../helper/getNumberKeys';
import { getBoardCardCustomProperties } from '../../../../../../../../store/model/board/selectors/getBoardCardCustomProperties';
import { IApplicationState } from '../../../../../../../../types/types';
import { CardCustomPropertiesPanelButton } from '../../components/CardCustomPropertiesPanelButton/CardCustomPropertiesPanelButton';
import {
    ICardCustomPropertiesPanelButtonEvents,
    ICardCustomPropertiesPanelButtonFields
} from '../../components/CardCustomPropertiesPanelButton/types';
import { onClick } from './events/onClick';
import { ICardCustomPropertiesPanelButtonHOCProps } from './types';
import { getIsUserBasicPlanOnly } from '../../../../../../../../store/model/authUser/selectors/getIsUserBasicPlanOnly';

const mapStateToProps = (
    state: IApplicationState,
    { boardId }: ICardCustomPropertiesPanelButtonHOCProps
): ICardCustomPropertiesPanelButtonFields => {
    const CardCustomProperties = getBoardCardCustomProperties(state, boardId);
    const indicatorNumber = getNumberKeys(CardCustomProperties).length;
    return {
        indicatorNumber,
        isBasicFeature: getIsUserBasicPlanOnly(state)
    }
};

const mapDispatchToProps = (
    dispatch: any,
    {onClick: ownOnClick}: ICardCustomPropertiesPanelButtonHOCProps
): ICardCustomPropertiesPanelButtonEvents => {
    return {
        onClick: () => dispatch(onClick(ownOnClick))
    }
};

export const CardCustomPropertiesPanelButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardCustomPropertiesPanelButton);

CardCustomPropertiesPanelButtonHOC.displayName = 'CardCustomPropertiesPanelButtonHOC';
