import * as React from 'react';
import './_Color.scss';
import { Button, ButtonDropdown, Icon } from 'kui';
import { IColorProps } from './types';
import { COLOR_LABEL, COLOR_MODAL_CANCEL, COLOR_MODAL_REMOVE } from './constants';
import { CLASS_COLOR } from '../../constants';
import { ActionModal } from '../../../ActionModal/ActionModal';
import { BasicUpgradeButtonHOC } from '../../../BasicUpgradeButton/hocs/BasicUpgradeButtonHOC/BasicUpgradeButtonHOC';
import { getMessages } from '../../../../../../../store/constants';
import { getAsidePanelTooltip } from '../../../../../aside_panel/asidePanel/helpers/getAsidePanelTooltip';
import { ColorDropdown } from '../ColorDropdown/ColorDropdown';

export function Color({
    aloudCount,
    allowTitle,
    boardColors,
    color,
    colors,
    onChange,
    onClose,
    onOpen,
}: IColorProps) {
    const className = CLASS_COLOR;

    const [removeColor, setRemoveColor] = React.useState(null);
    const onCloseModal = () => {
        onClose();
        setRemoveColor(null);
    };

    return (<>
            <ButtonDropdown
                className={className}
                directionHorizontal={'right'}
                dropdownClassName={className + '__dropdown'}
                multiple={true}
                onOpen={onOpen}
                isScaleAnimation
            >
                <Button
                    className={className + '__button'}
                    tooltip={getAsidePanelTooltip({
                        direction: 'left',
                        value: COLOR_LABEL,
                    })}
                    variant={'icon'}
                >
                    <Icon
                        size={24}
                        xlink={'card-color'}
                    />
                </Button>
                <ColorDropdown
                    boardColors={boardColors}
                    color={color}
                    colors={colors}
                    onChange={onChange}
                />
            </ButtonDropdown>
            <ActionModal
                isShow={!!removeColor}
                actions={[
                    {
                        variant: 'primary',
                        onClick: onCloseModal,
                        children: COLOR_MODAL_CANCEL
                    },
                    {
                        variant: 'secondary',
                        onClick: () => {
                            onCloseModal();
                            return onChange(removeColor);
                        },
                        children: COLOR_MODAL_REMOVE
                    },
                ]}
                footer={<BasicUpgradeButtonHOC onClick={onCloseModal} />}
                text={getMessages().getText('card_form_color_modal_text', null, aloudCount)}
                title={allowTitle}
                onClose={onCloseModal}
            />
        </>
    );
};
