import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../types/types';
import { showAsidePanelDeleteSnackbar } from '../../../../../effects/showAsidePanelDeleteSnackbar';

export function onShowSnackbar(): ThunkAction {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(showAsidePanelDeleteSnackbar());
    };
    return action;
}
