import { TBoardId } from '../../../types/types';
import { ERoutes } from '../constants';
import { IRouterState } from '../reducers/types';

export const getLinkCardBoardId = (
    routerState: IRouterState
): TBoardId => {
    const { route, args } = routerState;
    if (
        route !== ERoutes.LINK_CARD &&
        route !== ERoutes.LINK_CARD_TIMING &&
        route !== ERoutes.LINK_CARD_ACTIVITY
    ) return null;

    const boardId = args[0];
    if (!boardId) return null;

    return parseInt(boardId);
}
