import { IApplicationState } from '../../../../../../../types/types';
import { connect } from 'react-redux';
import { ICardMoveToHOCProps } from './types';
import { onLoadMore } from './events/onLoadMore';
import { getBoardsForMoveTo } from '../../selector/getBoardsForMoveTo';
import { onDidMount } from './events/onDidMount';
import { getCard } from '../../../../../../../store/model/selectors/getCard';
import { getBoardByCardId } from '../../../../../../../store/model/selectors/getBoardByCardId';
import { MoveTo } from '../../../../../base/components/MoveTo/components/MoveTo/MoveTo';
import { IMoveToEvents, IMoveToFields } from '../../../../../base/components/MoveTo/components/MoveTo/types';
import { onListSelected } from './events/onListSelected';
import { onCardSelected } from './events/onCardSelected';
import { onLoadBoardLists } from './events/onLoadBoardLists';

const mapStateToProps = (
    state: IApplicationState,
    { cardId,  boardId}: ICardMoveToHOCProps,
): IMoveToFields => {
    const card = getCard(state, cardId);
    const board = getBoardByCardId(state, cardId);
    const activeBoardId = boardId
    const boards = getBoardsForMoveTo(state);
    const activeListId = card.listId;
    return {
        activeListId,
        activeBoardId,
        boards,
        isLoading: !board || board.noCards
    }
};

const mapDispatchToProps = (
    dispatch: any,
    props: ICardMoveToHOCProps,
): IMoveToEvents => {
    const { boardId, onClose, cardId } = props;
    return {
        onDidMount: () => dispatch(onDidMount()),
        onLoadMore: () => dispatch(onLoadMore()),
        onListSelected: (board, listIndex) => dispatch(onListSelected(cardId, board, listIndex)),
        onCardSelected: (board, listId, cardIndex) => dispatch(onCardSelected(cardId, board, boardId, listId, cardIndex, onClose)),
        onLoadBoardLists: (board) => dispatch(onLoadBoardLists(board))
    }
};

export const CardMoveToHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(MoveTo);

CardMoveToHOC.displayName = 'CardMoveToHOC';
