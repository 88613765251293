import * as React from 'react';
import { CARD_CLASS } from '../../constants';
import { ICardDateTimeIndicatorProps } from './types';
import { Icon, Tooltip } from 'kui';
import './CardDateTimeIndicator.scss';

export function CardDateTimeIndicator({
    icon,
    text,
    tooltip,
    modifier
}: ICardDateTimeIndicatorProps) {
    let cardDateIndicator = CARD_CLASS + '__date-value';
    if (modifier) {
        cardDateIndicator+= ` ${cardDateIndicator}--${modifier}`;
    }
    const cardDateText = CARD_CLASS + '__date-text';
    return (
        <Tooltip
            isNoEvents
            isNoWrap
            value={tooltip}
        >
            <span className={cardDateIndicator}>
                {
                    icon &&
                    <Icon xlink={icon}/>
                }
                <span className={cardDateText}>{text}</span>
            </span>
        </Tooltip>
    );
}
