import { IGetState, TBoardId, TFilterId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { getSortedSavedFiltersIds } from '../selectors/getSortedSavedFiltersIds';
import { setSortedSavedFilterIds } from './setSortedSavedFilterIds';

export const addFilterToSortedSavedFilterIds = (
    boardId: TBoardId,
    filterId: TFilterId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const filterIds = [...getSortedSavedFiltersIds(state, boardId), filterId];
        dispatch(setSortedSavedFilterIds(boardId, filterIds));
    };
    return action;
};
