import { ID_ASIDE_PANEL_TOOLTIPS_PORTAL } from '../components/AsidePanelActionsButton/constants';
import { CLASS_ASIDE_PANEL_ACTIONS_TOOLTIP } from '../components/AsidePanelActions/constants';
import { ITooltipInheritedProps } from 'kui';
import { RIGHT_ASIDE_PANEL_SELECTOR } from '../constants';

export const getAsidePanelTooltip = (
    tooltip: ITooltipInheritedProps,
): ITooltipInheritedProps => {
    return {
        className: CLASS_ASIDE_PANEL_ACTIONS_TOOLTIP,
        isNoEvents: true, // скрывать тултип при наведении на него
        portalId: ID_ASIDE_PANEL_TOOLTIPS_PORTAL,
        portalSelector: RIGHT_ASIDE_PANEL_SELECTOR,
        ...tooltip
    } as ITooltipInheritedProps;
}
