import { TBoardId } from '../../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { boardSetLogo } from '../../../../../../../../../rest/effects/board/boardSetLogo';

export const logoDelete = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        return dispatch(boardSetLogo(boardId, null));
    };
    return action;
};
