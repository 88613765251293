import { ECardNumberStatus, ICardNumberState } from '../types';
import { AT_STATE_UPDATE, AT_STATUS_CHANGE } from './constants';

export enum ECardNumberChangeKey {
    prefix = 'prefix',
    nextValue = 'nextValue'
}

export interface ICardNumberStateUpdateAction {
    type: typeof AT_STATE_UPDATE,
    state: Partial<ICardNumberState>
}
export interface ICardNumberStatusChangeAction {
    type: typeof AT_STATUS_CHANGE,
    status: ECardNumberStatus
}

export type TCardNumberAction = ICardNumberStateUpdateAction |
    ICardNumberStatusChangeAction;
