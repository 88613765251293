import { TTabKey } from '../store/types';
import { IApplicationState } from '../../../../../types/types';
import { getLoadStatus } from '../store/common/selectors/getLoadStatus';
import { INITIAL_LOAD_STATUS } from '../store/constants';
import { getIsTabAllowedSupportedHasScopes } from '../selectors/getIsTabAllowedSupportedHasScopes';

export const allBoardsUrlPreparator = (
    state: IApplicationState,
    url: string
): string => {
    let query: string = '';
    if (getLoadStatus(state, TTabKey.sharedDrives) === INITIAL_LOAD_STATUS) {
        const isSharedDrives = getIsTabAllowedSupportedHasScopes(state, TTabKey.sharedDrives);
        if (isSharedDrives) {
            query += '&includeShared=true';
        }
    }
    if (getLoadStatus(state, TTabKey.starred) === INITIAL_LOAD_STATUS) {
        query += '&includeStarred=true';
    }
    if (query.length < 1) {
        return url;
    }
    return url + (url.indexOf('?') < 0 ? '?' : '') + query;
}
