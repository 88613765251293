import { ILogo } from './Logo/types';

export const CLASS_LOGO = 'logo';

export const LOGO_BG_COLORS: ReadonlyArray<string> = Object.freeze([
    '#ffa9ae',
    '#ffdcad',
    '#fde791',
    '#c8e5c2',
    '#bfd9fd',
    '#cbeaf1',
    '#dbd3fd',
    '#f9d2e8',
    '#dfd4cd',
    '#cacfd8',
    '#dfe2e7',

]);

export const LOGO_COLORS: Record<string, string> = Object.freeze({
    '#dfe2e7': '#8893a5',
    '#cacfd8': '#77859d',
    '#dfd4cd': '#a38470',
    '#ffdcad': '#e08000',
    '#fde791': '#be9803',
    '#c8e5c2': '#56a446',
    '#bfd9fd': '#438ff9',
    '#dbd3fd': '#8b72f9',
    '#f9d2e8': '#e260a9',
    '#ffa9ae': '#d76060',
    '#cbeaf1': '#35aac5e5',
});

export const getIconColor = (
    color: string,
): string => {
    return LOGO_COLORS[color] || '#8893a5';
};

export const COLOR_MATCH: Record<string, string> = Object.freeze({
    'vivid-red': '#ffa9ae',
    'vivid-orange': '#ffdcad',
    'vivid-yellow': '#fde791',
    'vivid-green': '#c8e5c2',
    'vivid-blue': '#bfd9fd',
    'vivid-purple': '#dbd3fd',
    'vivid-pink': '#f9d2e8',
    'vivid-brown': '#dfd4cd',
    'vivid-light-grey': '#dfe2e7',
    'vivid-grey': '#cacfd8',
});

export const fixOldColors = (
    logo: ILogo
): ILogo => {
    return {
        ...logo,
        color: LOGO_BG_COLORS.includes(logo.color) ? logo.color : COLOR_MATCH[logo.color]
    };
}

export const LOGO_COLOR_DEFAULT = '#dfe2e7';

export const LOGO_ICONS = [
    'assigned-by-me',
    'plus-circle',
    'subscribed',
    'contributed',
    'card-assigned',
    'card',
    'board',
    'overdue',
    'star',
    'list-view',
    'checklist',
    'account',
    'calendar-done',
    'start-date',
    'due-date',
    'activity',
    'priority-no',
    'color-tag',
    'card-color',
    'clear',
    'copy',
    'email',
    'flash',
    'arrow-circle',
    'play',
    'swimlanes',
    'done-circle',
    'related-card',
    'predecessor',
    'share-public',
    'share-specific',
    'pen',
    'replay',
    'workload',
    'pulse',
    'weight',
    'user',
    'tag',
    'spent-time',
    'visibility-off',
    'chart',
    'notifications',
    'pin',
    'subcard',
    'folder-account',
    'move-to',
    'milestone',
    'no',
    'monetization',
    'link',
    'archive',
    'attachment',
    'info',
    'epic-card',
    'emoji'
];
