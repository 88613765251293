import * as React from 'react';
import { IRestCardCopyActivity } from '../../../../../../../types/rest/activity/IRestCardCopyActivity';
import { IActivityProps } from '../../../../boardDetails/activity/TabActivity/types';
import { TActivity } from '../../../../../../../store/activities/types';
import {
    ActivityItemBody
} from 'app/view/react_components/aside_panel/boardDetails/activity/TabActivity/components/ActivityItemBody/ActivityItemBody';
import { ActivityHeader } from '../../../../boardDetails/activity/TabActivity/components/ActivityHeader/ActivityHeader';

export function CardActivityCopy ({
    activity
}: IActivityProps<IRestCardCopyActivity>) {
    const { label } = activity as TActivity;
    return (
        <ActivityItemBody
            header={<ActivityHeader icon={'copy'} label={label} />}
            activity={activity}
        />
    );
}
