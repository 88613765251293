import { Dispatch, ThunkAction } from 'app/types/actions';
import { isAsideOpenSetAction } from 'app/view/react_components/main/navigationPanel/store/navigationPanel/actions/isAsideOpenSetAction';
import { IGetState } from '../../../../../../types/types';
import { ENavigationPanelTypes } from '../../../../main/navigationPanel/constants';
import { getCurrentNavigationPanelType } from '../../../../main/navigationPanel/helpers/getCurrentNavigationPanelType';
import { isOpenSetAction } from '../../../../main/navigationPanel/store/navigationPanel/actions/isOpenSetAction';
import { panelTypeSetAction } from '../../../../main/navigationPanel/store/navigationPanel/actions/panelTypeSetAction';
import { dispatch } from 'app/store/configureStore';
import { inAppNotificationsIconClickedSegmentEvent } from 'app/view/react_components/main/topBar/effects/segmentEvents';
import { SegmentInAppNotificationEvent, segmentTrackAction } from 'app/middlewares/segment';

export function onOpen (
    openNavPanel: boolean
): ThunkAction {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        const navigationPanelType = getCurrentNavigationPanelType(getState());
        if (navigationPanelType === ENavigationPanelTypes.NP_COMPACT_TYPE && openNavPanel) {
            dispatch(panelTypeSetAction(ENavigationPanelTypes.NP_COMPACT_BIG_TYPE));
            dispatch(isOpenSetAction(false));
        }
        dispatch(isAsideOpenSetAction(true));
        dispatch(segmentTrackAction(SegmentInAppNotificationEvent.OPEN_NOTIFICATIONS_PANEL));
        dispatch(inAppNotificationsIconClickedSegmentEvent());
    };
    return action
};
