import React from 'react';
import { Provider } from "react-redux";
import { CardDetailsBaseView } from './base';
import { CLASS_ASIDE_PANEL_BOARD_SORTING } from '../../react_components/aside_panel/asidePanel/constants';
import { getBoardIdByCardId } from '../../../store/model/selectors/getBoardIdByCardId';
import { LazyWrapper } from 'app/view/react_components/lazy/lazy_wrapper';
import { getActiveBoardId } from 'app/store/model/selectors/getActiveBoardId';
import { FAKE_CARD_ID } from 'app/const';

export const KanbanCardDetailsView = CardDetailsBaseView.extend({

    initialize: function (options) {
        CardDetailsBaseView.prototype.initialize.call(this, options);
        this.cardId = options.cardId;
        this.isTemplate = options.isTemplate;
        this.boardId = this.cardId === FAKE_CARD_ID ? getActiveBoardId(this.store.getState()) : getBoardIdByCardId(this.store.getState(), this.cardId);
    },

    reactRender: function () {
        this.parent.el.classList.remove(CLASS_ASIDE_PANEL_BOARD_SORTING);

        const ImportElement = this.isTemplate
            ? React.lazy(() =>
                this.initStore()
                    .then(() => import(/* webpackChunkName: "cardDetails" */ 'app/view/react_components/aside_panel/asidePanel/hocs/AsidePanelCardTemplateHOC/AsidePanelCardTemplateHOC'))
                    .then(module => ({default: module.AsidePanelCardTemplateHOC}))
                    .catch(e => console.error(e))
            )
            : React.lazy(() =>
                this.initStore()
                    .then(() => import(/* webpackChunkName: "cardDetails" */ 'app/view/react_components/aside_panel/asidePanel/hocs/AsidePanelCardHOC/AsidePanelCardHOC'))
                    .then(module => ({default: module.AsidePanelCardHOC}))
                    .catch(e => console.error(e))
            )

        this.root.render(
            <LazyWrapper>
                <Provider store={this.store}>
                    <ImportElement
                        boardId={this.boardId}
                        cardId={this.cardId}
                        closedRef={document.activeElement}
                    />
                </Provider>
            </LazyWrapper>
        );
    }

});
