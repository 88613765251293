import { TClickManagerCardsSelector } from '../../../../clickManager/store/types';
import { getCardRecurringTasks } from '../../../../../../store/model/selectors/getCardRecurringTasks';
import { getBoardCardTemplateIdsSelector } from './getBoardCardTemplateIds';

export const getCardTemplateIdsForClickManager: TClickManagerCardsSelector = (
    state,
    boardId,
    cardId
) => {
    const recurringTasks = getCardRecurringTasks(state, cardId);
    return getBoardCardTemplateIdsSelector(state, {boardId, isRecurring: recurringTasks.length > 0 });
};
