import { connect } from 'react-redux';
import { EShareMode, IApplicationState } from 'app/types/types';
import { IViewOnlyHOCProps } from './types';
import { getBoardPermissions } from 'app/store/model/selectors/getBoardPermissions';
import { getBoardIsOnSharedDrive } from 'app/store/model/selectors/getBoardIsOnSharedDrive';
import { getAuthUser } from 'app/store/model/authUser/selectors/getAuthUser';
import { getBoard } from 'app/store/model/selectors/getBoardById';
import { isBoardCardsLimitReached } from 'app/store/model/selectors/isBoardCardsLimitReached';
import { IViewOnlyEvents, IViewOnlyFields } from '../../components/ViewOnly/types';
import { ViewOnly } from '../../components/ViewOnly/ViewOnly';
import { getAsidePanelWidth } from '../../../aside_panel/asidePanel/store/selectors/getAsidePanelWidth';
import { onClick } from './events/onClick';

const mapStateToProps = (
    state: IApplicationState,
    props: IViewOnlyHOCProps,
): IViewOnlyFields => {
    const { boardId, isMini } = props;
    let mode;
    const boardPermissions = getBoardPermissions(state, boardId);
    const authPermissions = boardPermissions && boardPermissions.authPermissions;
    const allowComment = authPermissions && authPermissions.allowComment;
    const allowEdit = authPermissions && authPermissions.allowEdit;
    const asidePanelWidth = getAsidePanelWidth(state);
    if (allowEdit) {
        if (
            getBoard(state, boardId).lockDate ||
            isBoardCardsLimitReached(state, boardId, 0) ||
            getBoardIsOnSharedDrive(state, boardId) && !getAuthUser(state).allowSharedDrive
        ) {
            mode = EShareMode.VIEW;
        } else {
            mode = EShareMode.EDIT;
        }
    } else {
        if (allowComment) {
            mode = EShareMode.COMMENT;
        } else {
            mode = EShareMode.VIEW;
        }
    }

    return {
        isMini,
        asidePanelWidth,
        mode,
    }
};

const mapDispatchToProps = (
    dispatch: any,
    { boardId }: IViewOnlyHOCProps,
): IViewOnlyEvents => {
    return {
        onClick: () => dispatch(onClick(boardId))
    }
};

export const ViewOnlyHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewOnly);
ViewOnlyHOC.displayName = 'ViewOnlyHOC';
