import { TStatus } from '../../../../../../../types/model';
import { isTemporaryId } from '../utils/notificationUtils';
import { deleteNotificationBeforeDueDateById } from '../hocs/NotificationEditingPopUp/effects/rest/deleteNotificationBeforeDueDateById';
import { saveNotificationBeforeDueDate } from '../hocs/NotificationEditingPopUp/effects/rest/saveNotificationBeforeDueDate';
import { patchNotificationBeforeDueDate } from '../hocs/NotificationEditingPopUp/effects/rest/patchNotificationBeforeDueDate';
import { notificationBeforeDueDateChanged, notificationBeforeDueDateSet } from './statisticHelper';
import { IGetState, TBoardId, TCardId } from '../../../../../../../types/types';
import { INotificationBeforeDueDate } from '../../../../../../../store/model/notificationBeforeDueDate/types';
import { Dispatch } from '../../../../../../../types/actions';

export const saveNotificationsBeforeDueDate = (
    boardId: TBoardId,
    cardId: TCardId,
    notifications: INotificationBeforeDueDate[],
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        return Promise.all(notifications.map((
            notification,
            index,
        ) => {
            if (notification.status && notification.status === TStatus.STATUS_DELETED) {
                if (isTemporaryId(notification.id)) {
                    return Promise.resolve('');
                } else {
                    notificationBeforeDueDateChanged();
                    return dispatch(deleteNotificationBeforeDueDateById(cardId, notification.id));
                }
            } else {
                if (isTemporaryId(notification.id)) {
                    notificationBeforeDueDateSet();
                    return dispatch(saveNotificationBeforeDueDate(boardId, cardId, notification, index));
                } else {
                    notificationBeforeDueDateChanged();
                    return dispatch(patchNotificationBeforeDueDate(notification, index));
                }
            }
        }));
    }
    return action;
};
