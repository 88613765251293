import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { IGetState, TCardId, TChecklistId, TChecklistItemId } from '../../../../../../../../../types/types';
import { SegmentCardEvent, SegmentCardOptions, segmentTrackAction } from '../../../../../../../../../middlewares/segment';
import { checklistItemUpdate } from '../../../../../../../../../rest/effects/card/checklist/checklistItemUpdate';
import { getChecklistItem } from '../../../../../../../../../store/model/checklists/checklist/selectors/getChecklistItem';
import { SegmentCardSourceValue } from '../../../../../../../../../middlewares/segment/trackEntities/cardEvents';

export const toggleChecklistItem = (
    cardId: TCardId,
    checklistId: TChecklistId,
    itemId: TChecklistItemId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const item = getChecklistItem(state, cardId, checklistId, itemId);
        if (!item) return Promise.reject();

        const checked = !item.checked;
        if (checked) {
            dispatch(segmentTrackAction(SegmentCardEvent.CHECKED_CHECKLIST_ELEMENT, {
                name: SegmentCardOptions.SOURCE,
                value: SegmentCardSourceValue.CARD
            }));
        } else {
            dispatch(segmentTrackAction(SegmentCardEvent.UNCHECKED_CHECKLIST_ELEMENT, {
                name: SegmentCardOptions.SOURCE,
                value: SegmentCardSourceValue.CARD
            }));
        }

        return dispatch(checklistItemUpdate(cardId, checklistId, itemId, {
            checked
        }));
    };
    return action;
};
