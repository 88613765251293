import * as React from 'react';
import { ITabDetailsProps } from './types';
import './_TabDetails.scss';
import {
    CLASS_CARD_DETAILS,
    CLASS_CARD_DETAILS_READ_ONLY,
    CLASS_SECTION_ICON_PROGRESS_PRIORITY,
    CLASS_SECTION_ICON_PROGRESS_PRIORITY_WATCH,
    CLASS_SECTION_TITLE,
    CLASS_SECTION_WATCH
} from '../../../constants';
import { CardAuthorHOC } from '../../../Author/hocs/CardAuthorHOC/CardAuthorHOC';
import { CardWatchHOC } from '../../../Watch/hocs/CardWatchHOC/CardWatchHOC';
import { CardIconHOC } from '../../../Icon/hocs/CardIconHOC/CardIconHOC';
import { CardColorTagsHOC } from '../../../ColorTags/hocs/CardColorTagsHOC/CardColorTagsHOC';
import { CardNameHOC } from '../../../Name/hocs/CardNameHOC/CardNameHOC';
import { CardDescriptionHOC } from '../../../DescriptionSection/hocs/CardDescriptionHOC/CardDescriptionHOC';
import { AssigneesSectionHOC } from '../../../AssigneesSection/hocs/AssigneesSectionHOC/AssigneesSectionHOC';
import { CardSubcardsSectionHOC } from '../../../SubcardsSection/hocs/CardSubcardsSectionHOC/CardSubcardsSectionHOC';
import { CardRelatedSectionHOC } from '../../../RelatedSection/hocs/CardRelatedSectionHOC/CardRelatedSectionHOC';
import { CardTagsSectionHOC } from '../../../TagsSection/hocs/CardTagsSectionHOC/CardTagsSectionHOC';
import { CommentsSectionHOC } from '../../../CommentsSection/hocs/CommentsSectionHOC/CommentsSectionHOC';
import {
    DependenciesSectionHOC
} from '../../../DependenciesSection/hocs/DependenciesSectionHOC/DependenciesSectionHOC';
import {
    CardAttachmentsSectionHOC as CardAttachmentsSectionHOCgoogle
} from '../../../AttachmentsSection/hocs/CardAttachmentsSectionHOC/CardAttachmentsSectionHOC';
import {
    CardAttachmentsSectionHOC as CardAttachmentsSectionHOCmicrosoft
} from '../../../AttachmentsSection/hocs/microsoft/CardAttachmentsSectionHOC/CardAttachmentsSectionHOC';
import {
    CardEstimateAndSpentTimeSectionHOC
} from '../../../EstimateAndSpentTimeSection/hocs/CardEstimateAndTimeHOC/CardEstimateAndSpentTimeSectionHOC';
import { ListSelectHOC } from '../../../ListSelect/hocs/ListSelectHOC/ListSelectHOC';
import { CardDatesSectionHOC } from '../../../DatesSection/hocs/CardDatesSectionHOC/CardDatesSectionHOC';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { CardChecklistsHOC } from '../../../ChecklistSection/hocs/CardChecklistsHOC/CardChecklistsHOC';
import {
    CardEpicInHeadSectionHOC
} from '../../../EpicInHeadSection/hocs/CardEpicInHeadSectionHOC/CardEpicInHeadSectionHOC';
import {
    DeleteButtonSectionHOC
} from '../../../DeleteButtonSection/hocs/DeleteButtonSectionHOC/DeleteButtonSectionHOC';
import {
    CustomPropertiesSectionHOC
} from '../../../CustomPropertiesSection/hocs/CustomPropertiesSectionHOC/CustomPropertiesSectionHOC';
import {
    ActionsInHeadSectionHOC
} from '../../../ActionsInHeadSection/hocs/ActionsInHeadSectionHOC/ActionsInHeadSectionHOC';
import { CardProgressHOC } from '../../../../../base/components/CardProgress/hocs/CardProgressHOC';
import { ECardProgressSize } from '../../../../../base/components/CardProgress/components/types';
import { EAuthUserPlatformType } from 'app/types/rest/IRestAuthUser';
import { CardBoardNameHOC } from '../../../CardBoardName/hocs/CardBoardNameHOC/CardBoardNameHOC';
import { CardPriorityHOC } from '../../../../../base/components/CardPriority/hocs/CardPriorityHOC/CardPriorityHOC';

export function TabDetails({
    boardId,
    cardId,
    isShow,
    platformType,
    sectionType
}: ITabDetailsProps) {
    if (!isShow) {
        return null;
    }

    let CardAttachmentsSectionHOC;
    switch (platformType) {
        case EAuthUserPlatformType.MICROSOFT:
            CardAttachmentsSectionHOC = CardAttachmentsSectionHOCmicrosoft;
            break;
        default:
            CardAttachmentsSectionHOC = CardAttachmentsSectionHOCgoogle;
    }

    const isReadonly = sectionType === ESettingsSectionType.READONLY;
    const className = CLASS_CARD_DETAILS;
    const classNameReadonly = isReadonly ? CLASS_CARD_DETAILS_READ_ONLY : '';

    const classNameSectionAuthorAndList = CLASS_CARD_DETAILS + '-author-and-list';

    return (
        <div className={`
            ${className}
            ${classNameReadonly}
        `}>
            <ActionsInHeadSectionHOC boardId={boardId} cardId={cardId}/>
            <CardBoardNameHOC cardId={cardId} boardId={boardId} />
            <CardEpicInHeadSectionHOC boardId={boardId} cardId={cardId}/>

            <div className={classNameSectionAuthorAndList}>
                <CardAuthorHOC boardId={boardId} cardId={cardId}/>
                <ListSelectHOC boardId={boardId} cardId={cardId}/>
            </div>

            <div className={CLASS_SECTION_ICON_PROGRESS_PRIORITY_WATCH}>
                <div className={CLASS_SECTION_ICON_PROGRESS_PRIORITY}>
                    <CardIconHOC cardId={cardId}/>
                    <CardPriorityHOC cardId={cardId}/>
                    <CardProgressHOC cardId={cardId} size={ECardProgressSize.LARGE} />
                </div>
                <div className={CLASS_SECTION_WATCH}>
                    <CardWatchHOC cardId={cardId} boardId={boardId}/>
                </div>
            </div>

            <CardColorTagsHOC
                boardId={boardId}
                cardId={cardId}
            />

            <div className={CLASS_SECTION_TITLE}>
                <CardNameHOC cardId={cardId} boardId={boardId}/>
                <CardDescriptionHOC cardId={cardId}/>
            </div>

            <AssigneesSectionHOC
                boardId={boardId}
                cardId={cardId}
            />

            <CardTagsSectionHOC
                boardId={boardId}
                cardId={cardId}
            />

            <CardDatesSectionHOC
                boardId={boardId}
                cardId={cardId}
            />

            <CardEstimateAndSpentTimeSectionHOC
                boardId={boardId}
                cardId={cardId}
            />

            <CardChecklistsHOC
                boardId={boardId}
                cardId={cardId}
            />

            <CardAttachmentsSectionHOC
                boardId={boardId}
                cardId={cardId}
            />

            <CardSubcardsSectionHOC
                boardId={boardId}
                cardId={cardId}
            />

            <CardRelatedSectionHOC
                boardId={boardId}
                cardId={cardId}
            />

            <DependenciesSectionHOC
                boardId={boardId}
                cardId={cardId}
            />

            <CustomPropertiesSectionHOC
                boardId={boardId}
                cardId={cardId}
            />

            <CommentsSectionHOC
                boardId={boardId}
                cardId={cardId}
            />

            <DeleteButtonSectionHOC
                boardId={boardId}
                cardId={cardId}
            />
        </div>
    );
}
