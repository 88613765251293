import { ICardAssigneeRole, TStatus } from '../../../../../../../../types/model';
import { IApplicationState, TBoardId, TCardId } from '../../../../../../../../types/types';
import { createDeepEqualSelector } from '../../../../../../helpers/memoizeHelper';
import { ICardAssigneeElement } from '../../../components/AssigneesElements/types';
import { getCardAssignees } from '../../../../../../../../store/model/selectors/getCardAssignees';
import { getBoardRolesSorted } from '../../../../../../../../store/model/board/selectors/getBoardRolesSorted';
import { getBoardUsers } from '../../../../../../../../store/model/selectors/getBoardUsers';
import { ISharedUser } from '../../../../../../../../types/rest/ISharedUser';
import { IAssigneeRest } from '../../../../../../../../types/rest/IAssigneeRest';
import { getBoard } from '../../../../../../../../store/model/selectors/getBoardById';

type TgetCardAssignees = (
    state: IApplicationState,
    boardId: TBoardId,
    cardId: TCardId,
) => ICardAssigneeElement[];

export const getCardAssigneesSelector = (
    assignees: IAssigneeRest[],
    boardRoles: ICardAssigneeRole[],
    boardUser: ISharedUser[]
): ICardAssigneeElement[] => {
    const cardAssignees = assignees.map(assignee => {
            const roles = boardRoles.reduce((roles, role) => {
                const isChecked = assignee.roleIds && assignee.roleIds.includes(role.id);
                if (isChecked || role.status === TStatus.STATUS_ACTIVE) {
                    roles.push({
                        ...role,
                        isChecked,
                    })
                }
                return roles;
            }, []);
            const boarUser = boardUser && boardUser.find(user => user.permissionId === assignee.sharedUser.permissionId);
            const photoUrl = boarUser && boarUser.photoUrl || assignee.sharedUser.photoUrl;
            return ({
                sharedUser: assignee.sharedUser,
                roles,
                photoUrl,
            });
        });
        return cardAssignees;
    };

export const getCardAssigneesCreateSelector = (
): TgetCardAssignees => createDeepEqualSelector(
    (state: IApplicationState, boardId: TBoardId, cardId: TCardId) => getCardAssignees(state, cardId),
    (state: IApplicationState, boardId: TBoardId, cardId: TCardId) => getBoardRolesSorted(getBoard(state,boardId)),
    getBoardUsers,
    getCardAssigneesSelector
);

export const getCardAssigneesNoReselect = (
    state: IApplicationState,
    boardId: TBoardId,
    cardId: TCardId,
): ICardAssigneeElement[] => getCardAssigneesSelector(
    getCardAssignees(state, cardId),
    getBoardRolesSorted(getBoard(state, boardId)),
    getBoardUsers(state, boardId),
);
