import { ECardField, IOrderType, TCardFieldSortFunction, IListViewCard } from '../store/listViewBoard/types';
import { joinFullNames } from './joinFullNames';
import { getCardField } from '../constants';
import { clearMarkdown, clearMarkdownInline } from '../../base/helpers/clearMarkdownHelper';
import { sortCardsByDate } from './sortCardsByDate';
import { IApplicationState, TBoardId } from '../../../../types/types';

export const sortCardsByStringValue: TCardFieldSortFunction = (
    state: IApplicationState,
    boardId: TBoardId,
    cardA: IListViewCard,
    cardB: IListViewCard,
    orderType: IOrderType,
): number => {
    const { field, isAsc } = orderType;
    const key = getCardField(field, state, boardId).sortField as keyof IListViewCard;

    let valueA: string;
    let valueB: string;

    switch (field) {
        case ECardField.ASSIGNEES: {
            valueA = cardA[key] ? joinFullNames(cardA[key] as IListViewCard['assignees']) : null;
            valueB = cardB[key] ? joinFullNames(cardB[key] as IListViewCard['assignees']) : null;
            break;
        }
        case ECardField.TITLE: {
            valueA = cardA[key] ? clearMarkdownInline(cardA[key] as string) : null;
            valueB = cardB[key] ? clearMarkdownInline(cardB[key] as string) : null;
            break;
        }
        case ECardField.DESCRIPTION: {
            valueA = cardA[key] ? clearMarkdown(cardA[key] as string) : null;
            valueB = cardB[key] ? clearMarkdown(cardB[key] as string) : null;
            break;
        }
        case ECardField.LIST: {
            valueA = cardA[key] ? clearMarkdownInline(cardA[key] as string) : null;
            valueB = cardB[key] ? clearMarkdownInline(cardB[key] as string) : null;
            break;
        }
        case ECardField.TAGS: {
            valueA = cardA[key] ? (cardA[key] as IListViewCard['tags']).join() : null;
            valueB = cardB[key] ? (cardB[key] as IListViewCard['tags']).join() : null;
            break;
        }
        default:
            valueA = cardA[key] as string;
            valueB = cardB[key] as string;
    }

    if (
        !valueA && !valueB ||
        valueA === valueB
    ) return sortCardsByDate(cardA, cardB);
    if (!valueA) return 1;
    if (!valueB) return -1;

    return isAsc ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
};
