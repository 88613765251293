import { IApplicationState } from '../../../../../../types/types';
import {IFilterPanel} from '../filterPanel/types';
import { initialState } from '../filterPanel/reducers/filterPanelReducer';

export const  filterPanelBoardsSelector = (
    state: IApplicationState,
    boardId: number
): IFilterPanel => {
    let filterPanelBoardStore = state.filters.filterPanelBoards[boardId];
    return filterPanelBoardStore? filterPanelBoardStore: initialState;
};
