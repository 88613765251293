import {Dispatch, ThunkAction} from 'app/types/actions';
import { SegmentBoardManagerEvent, segmentTrackAction } from 'app/middlewares/segment';

export const onEsc = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        dispatch(segmentTrackAction(SegmentBoardManagerEvent.ESCAPE_RENAME_BOARD));
    };
    return action;
};
