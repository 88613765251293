import { IGetState, TBoardId } from '../../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { loadBoardBackups } from '../../../../../../../../../rest/effects/backup/loadBoardBackups';
import {getSectionType} from '../../../../../selectors/getSectionType';
import {ESettingsSectionType} from '../../../../../../../base/components/SettingsSection/types';

export const onDidMount = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        const sectionType = getSectionType(getState(), boardId, null, 'allowBackupXml', 'allowViewBackUp');
        if (sectionType === ESettingsSectionType.DEFAULT || sectionType === ESettingsSectionType.READONLY) {
            dispatch(loadBoardBackups(boardId));
        }
    };
    return action;
};
