import * as React from 'react';
import { ILazyLoaderProps } from './types';
import { LoaderBlock } from 'kui';

export function LazyWrapper(
    {
        loader,
        children
    }: ILazyLoaderProps
) {
    return (
        <React.Suspense fallback={loader ? loader : <LoaderBlock />} children={children}/>
    )
}
