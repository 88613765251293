import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { root } from '../../../../../../../store/constants';

export const onClose = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        root.App.vent.trigger(root.App.vent['dialog:closed']);
    };
    return action;
}
