import { IABTest } from '../../types';
import { EUserSettingsCommentSort } from '../../../types/rest/IRestAuthUser';
import { IApplicationState } from '../../../types/types';
import { getAuthUser } from '../../../store/model/authUser/selectors/getAuthUser';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { authUserSetCommentsSortOrder } from '../../../rest/effects/authUser/authUserSetCommentsSortOrder';

export const AB_TEST_COMMENT_SORTING = 'AB_TEST_COMMENT_SORTING';

const isRelevantChecker = (
    state: IApplicationState
): boolean => {
    const user = getAuthUser(state);
    const currentDateTimeStamp = new Date().getTime() / 1000;
    // аккаунт создан сегодня
    return (currentDateTimeStamp - user.createDateTimeStamp) <= 24 * 60 * 60;
};

export enum EUserSettingsCommentSortLabel {
    ASC = 'oldest first',
    DESC = 'newest first'
}

const postProcessor = (
    value: EUserSettingsCommentSortLabel
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        const currentSort = value === EUserSettingsCommentSortLabel.DESC ?
            EUserSettingsCommentSort.DESC :
            EUserSettingsCommentSort.ASC;
        dispatch(authUserSetCommentsSortOrder(currentSort))
    }
    return action;
}

export const getCommentSortingConfig = (): IABTest => {
    return {
        name: AB_TEST_COMMENT_SORTING,
        defaultValue: EUserSettingsCommentSortLabel.ASC,
        size: 5000,
        groups: [
            {
                weight: 100,
                value: EUserSettingsCommentSortLabel.ASC
            },
            {
                weight: 100,
                value: EUserSettingsCommentSortLabel.DESC
            }
        ],
        isRelevant: isRelevantChecker,
        executeOnLoad: true,
        postProcessor: postProcessor
    }
};
