export const getPlanName = (
    productId: number
): string => {
    switch (productId) {
        case 13:
        case 14:
        case 22:
        case 23:
            return 'Premium';
        case 15:
        case 16:
        case 24:
        case 25:
            return 'Professional';
        case 17:
        case 18:
        case 26:
        case 27:
            return 'Enterprise';
        case 28:
        case 29:
            return 'Basic';

        default:
            return 'Unknown';
    }
};
