const category = 'Gantt chart';

export const SegmentGanttChartEvent = {
    GANTT_ACTIVATED: { category, event: 'Gantt toggle clicked (success)' },
    GANTT_DISALLOW: { category, event: 'Gantt toggle clicked (no success)' },
    GANTT_BY_DATE_SORTED: { category, event: 'Gantt type changed to By date' },
    GANTT_MANUAL_SORTED: { category, event: 'Gantt type changed to Manual' },
    GANTT_FIND_CARD_OPENED: { category, event: 'Cards search on Gantt opened' },
    GANTT_CARD_SELECTED: { category, event: 'Card in search on Gantt selected' },
    GANTT_CARD_CREATED_BY_CTRLENTER: { category, event: 'Card on Gantt created by CTRL ENTER' },
    GANTT_CARD_CREATED_BY_CLICK: { category, event: 'Card in search on Gantt created by click' },
    CANVAS_SET_DATE: { category, event: 'Card dates set from Gantt' },
    CANVAS_SET_DATE_EPIC: { category, event: 'Epic dates set from Gantt (no success)' },
    CANVAS_CHANGE_SD: { category, event: 'Card Start date changed by dragging on Gantt' },
    CANVAS_CHANGE_DD: { category, event: 'Card Due date changed by dragging on Gantt' },
    CANVAS_CHANGE_BOTH: { category, event: 'Card bar dragged on Gantt' },
    GANTT_PRESENT_DAY: { category, event: 'Go to present day clicked on Gantt' },
    GANTT_ZOOM_IN: { category, event: 'Gantt zoom in clicked' },
    GANTT_ZOOM_OUT: { category, event: 'Gantt zoom out clicked' },
    GANTT_PRINT_OPENED: { category, event: 'Print on Gantt opened' },
    GANTT_PRINT_CANCEL: { category, event: 'Print on Gantt canceled' },
    GANTT_PRINT_CLICKED: { category, event: 'Print from Gantt initiated' },
    GANTT_PRINT_CUT: { category, event: 'Gantt printing started - space cut' },
    GANTT_PRINT_FITTED: { category, event: 'Gantt printing started - fit to page' },
    GANTT_MOVE_DEP_SNACKBAR_SHOW: { category, event: 'Move dependency chain snackbar shown' },
    GANTT_MOVE_DEP_YES_CLICK: { category, event: 'Move dependency chain yes button clicked' },
    GANTT_MOVE_DEP_NO_CLICK: { category, event: 'Move dependency chain no button clicked' },
    GANTT_HIDE_CARDS_WITHOUT_DATES: { category, event: 'Hide card without dates clicked' },
    GANTT_ALL_DATES_CLICKED: { category, event: 'All dates clicked' },
    GANTT_CUSTOM_DATES_CLICKED: { category, event: 'Custom dates clicked' },
    GANTT_EXPAND_CARD_LIST_CLICKED: { category, event: 'Expand card list button clicked' },
    GANTT_COLLAPSE_CARD_LIST_CLICKED: { category, event: 'Collapse card list button clicked' },
    GANTT_PROPERTY_ADDED: { category, event: 'Property in dropdown added' },
    GANTT_PROPERTY_REMOVED: { category, event: 'Property in dropdown removed' },
    GANTT_ADD_CARDS_CLICKED: { category, event: 'Add all cards with dates clicked' },
}

export enum SegmentGanttOptions {
    cut = 'cut',
    fitted = 'fitted',
    enabled = 'enabled',
    field = 'field',
    mobile = 'mobile'
}
