import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { vent } from '../../../../../../../store/constants';
import { addNavPanelContainerClass } from '../../../effects/addNavPanelContainerClass';
import { ENavigationPanelTypes } from '../../../constants';

export const onTypeChange = (
    type?: ENavigationPanelTypes,
): ThunkAction => {
    return (dispatch: Dispatch) => {
        dispatch(addNavPanelContainerClass(type));
        setTimeout(() => vent.trigger(vent['view:resized']), 500); // setPanelsWidth after navPanel animation
    };
};
