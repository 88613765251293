import { AssigneesAdd } from '../../../AssigneesSection/components/AssigneesAdd/AssigneesAdd';
import { ICardChecklistAssignUserSelectProps } from './types';
import * as React from 'react';
import './_CardChecklistAssignUserSelect.scss';
import { CLASS_CARD_CHECKLIST_ASSIGN_SELECT } from './constants';
import { getParentsClasses } from '../../../../../helpers/getParentsClasses';
import { SearchSelect } from '../../../../../base/components/SearchSelect/SearchSelect';
import { AnalyzeRender } from '../../../../../helpers/memoizeHelper';

//@ts-ignore
const analyzeRender = window.Settings.development ? new AnalyzeRender(`CardChecklistAssignUserSelect`) : null;

export function CardChecklistAssignUserSelect ({
    availableAssignees,
    checklistId,
    onSelect,
    onClose
}: ICardChecklistAssignUserSelectProps) {
    if (analyzeRender && checklistId) {
        analyzeRender.call(`CardChecklistAssignUserSelect ${checklistId}`);
    }

    React.useEffect(() => {
        /**
         * dropdown перерисовывается вместе с чекайтемом, поэтому не работает обычное закрывание при клике на имя
         * продублировал сюда это закрывание без удаления таймера в willUnmount
         * from kui/src/ui/select/select.tsx
         */
        function onDropdownClick (e: MouseEvent) {
            const classes = getParentsClasses(
                e.target as HTMLElement,
                [CLASS_CARD_CHECKLIST_ASSIGN_SELECT, 'kui-select-list-item', 'kui-select-list']
            );
            if (classes.includes('kui-select-list-item')) {
                setTimeout(onClose, 100); // close after onToggle
            } else if (!classes.includes(CLASS_CARD_CHECKLIST_ASSIGN_SELECT) && !classes.includes('kui-checkbox')) {
                onClose();
            }
        }

        setTimeout(() => { //ignore initial
            document.addEventListener('click', onDropdownClick);
        }, 0);

        return () => {
            document.removeEventListener('click', onDropdownClick);
        }
    }, []);

    return (
        <SearchSelect
            autoFocus
            className={CLASS_CARD_CHECKLIST_ASSIGN_SELECT}
            isCloseOnClick={false}
            isDropdownUplifted
            opened
            multiple
            options={availableAssignees}
            option={AssigneesAdd}
            variant={null} // search долго перерисовывается когда теряет фокус
            onSelect={onSelect}
        />
    );
}
