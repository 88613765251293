import {IColor} from 'app/types/model';
import {Dispatch, ThunkAction} from 'app/types/actions';
import {fetchHandler} from 'app/util/fetchHandler';
import {Util} from 'app/util/util';
import {REST_COLOR} from 'app/rest/constants';
import {getRestHeadersPost} from 'app/rest/helpers/getRestHeadersHelper';

export const postRest = (
    color: IColor
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        return fetchHandler<IColor>(
            Util.getApiUrl(REST_COLOR), {
                ...getRestHeadersPost(),
                body: JSON.stringify(color)
            }
        );
    };
    return action;
};
