import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../../../../types/types';
import { SegmentCardEvent, SegmentCardOptions, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { SegmentCardSourceValue } from '../../../../../../../../middlewares/segment/trackEntities/cardEvents';
import {
    getBoardIsCardDefaultProperty
} from '../../../../../../../../store/model/board/selectors/getBoardIsCardDefaultProperty';
import { EAsidePanelProperty } from '../../../../../../aside_panel/asidePanel/components/AsidePanel/types';
import { getBoardIdByCardId } from '../../../../../../../../store/model/selectors/getBoardIdByCardId';
import { asidePanelActionButtonClick } from '../../../../../../helpers/asidePanelActionButtonClick';
import { waitUntilCardIsOpenedAndLoaded } from '../../../../../../helpers/waitUntilCardIsOpenedAndLoaded';
import { openCardIfNeeded } from '../effects/openCardIfNeeded';

export const onAssign = (
    cardId: TCardId,
    source: SegmentCardSourceValue
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const boardId = getBoardIdByCardId(state, cardId);
        const isDefault = getBoardIsCardDefaultProperty(state, boardId, EAsidePanelProperty.CARD_ASSIGNEES);

        dispatch(segmentTrackAction(SegmentCardEvent.ASSIGN_CARD_DROPDOWN_CLICKED, {
            name: SegmentCardOptions.SOURCE,
            value: source
        }));

        dispatch(openCardIfNeeded(cardId));

        const openCardAssignees = () => {
            setTimeout(() => {
                asidePanelActionButtonClick('assignees');

                setTimeout(() => {
                    if (isDefault) {
                        const addAssigneeButton = document.querySelector(
                            '.card-details-section--assignees' + ' .kui-button'
                        ) as HTMLInputElement;

                        if (addAssigneeButton) {
                            addAssigneeButton.focus();
                            addAssigneeButton.click();
                        }
                    }
                }, 0);
            }, 0);
        }

        waitUntilCardIsOpenedAndLoaded(
            getState,
            cardId,
            openCardAssignees
        );
    };

    return action;
};
