import {IApplicationState} from '../../../../../../types/types';
import {ITopBarDispatchProps, ITopBarStateProps} from '../../containers/TopBar/types';
import {connect} from 'react-redux';
import {TopBar} from '../../containers/TopBar/TopBar';
import {hasOpenedBoardSelector} from '../../../../../../store/model/selectors/hasOpenedBoard';
import {getActiveBoardId} from '../../../../../../store/model/selectors/getActiveBoardId';
import {getTypeSwitcherActiveType} from '../../../../typeSwitcher/store/selectors/getTypeSwitcherActiveType';
import {isShowMetrics} from '../../helpers/isShowMetrics';
import {isAllowShare} from '../../../../../../store/model/selectors/isAllowShare';
import { getIsAssignedToMeActive } from '../../../../../../store/router/selectors/getIsAssignedToMeActive';
import { filterPanelBoardsSelector } from 'app/view/react_components/aside_panel/filterPanelBoard/store/selectors/filterPanelBoardsSelector';
import { getIsLinkCardActive } from '../../../../../../store/router/selectors/getIsLinkCardActive';
import { getBoard } from 'app/store/model/selectors/getBoardById';
import { getBoardIsOnSharedDrive } from '../../../../../../store/model/selectors/getBoardIsOnSharedDrive';
import { getAuthUserPlatformType } from 'app/store/model/authUser/selectors/getAuthUserPlatformType';
import { getBoardPermissionsIsReadonly } from '../../../kanbanView/selectors/getBoardPermissionsIsReadonly';

const mapStateToProps = (state: IApplicationState): ITopBarStateProps => {
    const boardId = getActiveBoardId(state);
    const viewType = getTypeSwitcherActiveType(state, boardId);
    const { isOpened } = filterPanelBoardsSelector(state, boardId);
    const board = getBoard(state, boardId);
    const isOnShareDrive = getBoardIsOnSharedDrive(state, boardId);
    return {
        boardId,
        boardName: board.name,
        hasOpenedBoard: hasOpenedBoardSelector(state),
        isShowMetrics: isShowMetrics(viewType),
        isAllowShare: isAllowShare(state, boardId) && !isOnShareDrive,
        isAssignedToMe: getIsAssignedToMeActive(state),
        isFilterInfoPanel: isOpened,
        isReadonly: getBoardPermissionsIsReadonly(state, boardId),
        isShow: !getIsLinkCardActive(state),
        platformType: getAuthUserPlatformType(state),
    }
};

const mapDispatchToProps = (dispatch: any): ITopBarDispatchProps => {
    return {}
}

export const TopBarHOC = connect(mapStateToProps, mapDispatchToProps)(TopBar);

TopBarHOC.displayName = 'TopBarHOC';
