import React from "react";
import { createRoot } from 'react-dom/client';
import {Provider} from "react-redux";
import { LazyWrapper } from "../react_components/lazy/lazy_wrapper";
import { historyPush } from './../react_components/base/helpers/historyHelper';

class DeleteAccount extends App.Views.BaseView {

    constructor(opt) {
        super({
            el: '.delete-account-dialog',
        });
        this.root = createRoot(this.el);
        this.store = opt.store;
    }

    initAccountDeleteStore () {
        return import(/* webpackChunkName: "accountDelete" */ '../react_components/accountDelete/store/reducers/accountDeleteReducer')
        .then((module) => {
            this.store.reducerManager.add('accountDelete', module.accountDeleteReducer);
            this.store.dispatch({type: 'async-init'}); // fake action to init new reducers
        })
        .catch(e => console.error(e))
    }

    render() {
        this.$el.removeClass('hidden');
        
        const ImportElement = React.lazy(() =>
            this.initAccountDeleteStore()
                .then(() => import(/* webpackChunkName: "accountDelete" */ '../react_components/accountDelete/hocs/AccountDeleteHOC/AccountDeleteHOC'))
                .then(module => ({default: module.AccountDeleteHOC}))
                .catch(e => console.error(e))
        );
        this.root.render(
            <LazyWrapper>
                <Provider store={this.store}>
                    <ImportElement />
                </Provider>
            </LazyWrapper>
        );

        historyPush({
            state: { page: 'delete-account' },
            onpopstate: (e) => {
                e.preventDefault();
                this.hide();
            }
        });
    }

    hide() {
        this.$el.addClass('hidden');
        App.router.navigate('');
    }

    remove() {
        this.root.unmount();
        Backbone.View.prototype.remove.call(this);
    }
}

DeleteAccount.mixin(App.Mixins.Test);

export default DeleteAccount;
