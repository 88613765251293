import {PTGC_EVENT_DUE_DATE, PTGC_EVENT_START_AND_DUE_DATE, PTGC_EVENT_START_DATE} from "../constants";
import {SegmentCardEvent, segmentTrackAction} from "../../../../../../../middlewares/segment";

export const sendSelectedActionSegmentEvent = (
    selectedAction // one of list push to google calendar actions (start date, due date, entire period)
) => {
    return (dispatch) => {
        let segmentEvent;

        switch (selectedAction.id) {
        case PTGC_EVENT_START_DATE:
            segmentEvent = SegmentCardEvent.PUSH_START_DATE_CHOSEN;
            break;
        case PTGC_EVENT_DUE_DATE:
            segmentEvent = SegmentCardEvent.PUSH_DUE_DATE_CHOSEN;
            break;
        case PTGC_EVENT_START_AND_DUE_DATE:
            segmentEvent = SegmentCardEvent.PUSH_ENTIRE_PERIOD_CHOSEN;
            break;
        }
        dispatch(segmentTrackAction(segmentEvent));
    }
};
