import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TFilterId } from '../../../../../../../../types/types';
import { toggleFilter } from '../../../../../../main/topBar/hocs/TopBarFilterButtonHOC/effects/toggleFilter';
import {
    onEditFilter
} from '../../../../../../aside_panel/filterPanelBoard/components/SavedSection/hocs/SavedFiltersListItemHOC/events/onEditFilter';
import { MY_WORK_FILTER_BOARD_ID } from '../../../../../../aside_panel/filterPanelBoard/constants';

export const onEdit = (
    filterId: TFilterId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(toggleFilter());
        setTimeout(() => {
            dispatch(onEditFilter(MY_WORK_FILTER_BOARD_ID, filterId));
        }, 0);
    }
    return action;
}
