import { IGetState, TCardId } from '../../../../../../../../types/types';
import { Dispatch } from '../../../../../../../../types/actions';
import { cardsUnarchive } from '../../../../../../../../rest/effects/card/card/cardsUnarchive';
import { snackbarCardUnarchived } from '../../../../../../snackbarsStack';
import {
    SegmentArchiveEvent,
    SegmentCardOptions,
    segmentTrackAction
} from '../../../../../../../../middlewares/segment';
import { SegmentCardSourceValue } from '../../../../../../../../middlewares/segment/trackEntities/cardEvents';
import { isBoardCardsLimitReached } from '../../../../../../../../store/model/selectors/isBoardCardsLimitReached';
import { getBoardIdByCardId } from '../../../../../../../../store/model/selectors/getBoardIdByCardId';
import {
    showBoardLimitSnackbarAndEvent
} from '../../../../../../../../store/model/effects/showBoardLimitSnackbarAndEvent';
import { SegmentUserTypeOption } from '../../../../../../../../middlewares/segment/trackEntities/userEvents';

export const onUnarchive = (
    cardId: TCardId,
    source: SegmentCardSourceValue
) => {
    return (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (isBoardCardsLimitReached(getState(), getBoardIdByCardId(getState(), cardId) , 1)){
            dispatch(showBoardLimitSnackbarAndEvent(SegmentUserTypeOption.CARD_CREATION));
        } else {
            dispatch(cardsUnarchive([cardId])).then(() => {
                dispatch(segmentTrackAction(SegmentArchiveEvent.CARD_UNARCHIVED, {
                    name: SegmentCardOptions.SOURCE,
                    value: source
                }));
                dispatch(snackbarCardUnarchived());
            });
        }
    }
}
