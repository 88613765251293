import { IGetState, TBoardId } from '../../../types/types';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { boardPatchRest } from './api/helpers/boardPatchRest';
import { ILibTheme } from '../../../store/model/libThemes/types';
import { MY_WORK_BOARD_ID } from '../../../view/react_components/main/myWorkView/constants';
import { updateMyWorkMeta } from '../authUser/myWork/updateMyWorkMeta';

export const boardSetTheme = (
    boardId: TBoardId,
    theme: ILibTheme
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const newTheme = { ...theme, jsonRest: true };
        if (boardId === MY_WORK_BOARD_ID) {
            dispatch(updateMyWorkMeta({ theme: newTheme }));
        } else {
            return dispatch(boardPatchRest(boardId, { theme: newTheme }));
        }
    };
    return action;
}
