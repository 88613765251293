import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { segmentTrackAction, SegmentTopBarEvent } from '../../../../../middlewares/segment';
import { ESegmentClickType } from '../../../../../middlewares/segment/constants';
import {
    ESegmentFilterTarget,
    ESegmentTopBarOption, ESegmentTopBarPage
} from '../../../../../middlewares/segment/trackEntities/topBarEvents';
import { ISegmentEventOption } from '../../../../../middlewares/segment/trackEntities/types';
import { TBoardId } from '../../../../../types/types';
import { MY_WORK_FILTER_BOARD_ID } from '../../../aside_panel/filterPanelBoard/constants';

export const boardNameClickedSegmentEvent: ActionCreator<ThunkAction> = (
    clickType: ESegmentClickType
) => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentTopBarEvent.BOARD_NAME_CLICKED, {
        name: 'clickType',
        value: clickType
    }));
};

export const boardNameIconClickedSegmentEvent: ActionCreator<ThunkAction> = (
    clickType: ESegmentClickType
) => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentTopBarEvent.BOARD_NAME_ICON_CLICKED, {
        name: 'clickType',
        value: clickType
    }));
};

export const filterClickedSegmentEvent: ActionCreator<ThunkAction> = (
    target: ESegmentFilterTarget,
    boardId: number
) => (
    dispatch: Dispatch
) => {
    const options: ISegmentEventOption[] = [];
    if (boardId === MY_WORK_FILTER_BOARD_ID) {
        options.push({
            name: ESegmentTopBarOption.PAGE,
            value: ESegmentTopBarPage.MY_TASKS
        })
    }
    dispatch(segmentTrackAction(SegmentTopBarEvent.FILTER_CLICKED, {
        name: ESegmentTopBarOption.TARGET,
        value: target
    }, options));
};

export const inAppNotificationsIconClickedSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentTopBarEvent.IN_APP_NOTIFICATIONS_ICON_CLICKED));
};

export const inAppNotificationsEscButtonPressedSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentTopBarEvent.IN_APP_NOTIFICATIONS_ESC_BUTTON_PRESSED));
};

export const shareClickedSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentTopBarEvent.SHARE_CLICKED));
};

export const shareDontShowClickedSegmentEvent = (): ThunkAction => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentTopBarEvent.DONT_SHOW_HINT_CLICKED));
};

export const streamClickedSegmentEvent: ActionCreator<ThunkAction> = (
    timeMod: number,
    time: number
) => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentTopBarEvent.STREAM_CLICKED, {
            name: ESegmentTopBarOption.TIME_MOD15,
            value: timeMod
        },[{
            name: ESegmentTopBarOption.TIME,
            value: time
        }]
    ));
};

export const helpClickedSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentTopBarEvent.HELP_CLICKED));
};
