import { IGetState, TCardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { toggleUserSetInWorkCard } from '../effects/toggleUserSetInWorkCard';
import { getAuthUser } from '../../../../../../../../store/model/authUser/selectors/getAuthUser';
import { showSnoozeBlocker } from '../../../../../../base/effects/showSnoozeBlocker';

export const onClick = (
    cardId: TCardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const { allowStatusMapping } = getAuthUser(getState());
        if (allowStatusMapping) {
            dispatch(toggleUserSetInWorkCard(cardId));
        } else {
            dispatch(showSnoozeBlocker({ allow: 'allowStatusMapping' }));
        }
    };
    return action;
};
