import { IApplicationState } from '../../../../../../../../types/types';
import { connect } from 'react-redux';
import { EPwcImportTypes, IBoardPwcImportHOCProps } from './types';
import { IBoardPwcImportEvents, IBoardPwcImportFields } from '../../components/BoardPwcImport/types';
import { BoardPwcImport } from '../../components/BoardPwcImport/BoardPwcImport';
import { onSelect } from './events/onSelect';
import {
    BOARD_PWC_IMPORT_TYPE_CHECKLISTS,
    BOARD_PWC_IMPORT_TYPE_SUBCARDS,
    BOARD_PWC_IMPORT_TYPE_TEMPLATE_CHECKLISTS
} from '../../constants';
import {onTemplatePwcImportSelect} from './events/onTemplatePwcImportSelect';

const mapStateToProps = (
    state: IApplicationState,
    {importType}: IBoardPwcImportHOCProps
): IBoardPwcImportFields => {
    let title = ''

    if(importType === EPwcImportTypes.TEMPLATES_CHECKLISTS) {
        title = BOARD_PWC_IMPORT_TYPE_TEMPLATE_CHECKLISTS
    } else if (importType === EPwcImportTypes.SUBCARDS) {
        title = BOARD_PWC_IMPORT_TYPE_SUBCARDS
    } else if(importType === EPwcImportTypes.CHECKLISTS) {
        title = BOARD_PWC_IMPORT_TYPE_CHECKLISTS
    }
    return {
        title
    }
};

const mapDispatchToProps = (
    dispatch: any,
    {boardId, importType}: IBoardPwcImportHOCProps
): IBoardPwcImportEvents => {
    return {
        onSelect: () => dispatch(onSelect(boardId, importType))
    }
};

export const BoardPwcImportHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(BoardPwcImport);

BoardPwcImportHOC.displayName = 'BoardPwcImportHOC';
