import { IApplicationState } from '../../../../../../../../types/types';
import { getBoard } from '../../../../../../../../store/model/selectors/getBoardById';
import { connect } from 'react-redux';
import {
    IBoardStarredButtonEvents,
    IBoardStarredButtonFields
} from '../../components/BoardStarredButton/types';
import { onClick } from './events/onClick';
import { IBoardStarredButtonHOCProps } from './types';
import { BoardStarredButton } from '../../components/BoardStarredButton/BoardStarredButton';
import { getAuthUserSupport } from '../../../../../../../../store/model/authUser/selectors/getAuthUserSupport';

const mapStateToProps = (
    state: IApplicationState,
    ownProps: IBoardStarredButtonHOCProps
): IBoardStarredButtonFields => {
    const { boardId } = ownProps;
    const board = getBoard(state, boardId);
    const { starred } = board
    return {
        isShow: getAuthUserSupport(state, 'supportStarred'),
        starred
    }
};

const mapDispatchToProps = (
    dispatch: any,
    ownProps: IBoardStarredButtonHOCProps
): IBoardStarredButtonEvents => {
    const { boardId } = ownProps;
    return {
        onClick: () => dispatch(onClick(boardId))
    }
};

export const BoardStarredButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(BoardStarredButton);

BoardStarredButtonHOC.displayName = 'BoardStarredButtonHOC';
