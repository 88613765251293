import { ESortField, ISorting } from './list/types';
import { TStatus } from '../../types/model';
import { IBoard } from './board/types';
import { getDateFromTime } from './helpers/getDateFromTime';

export const DEFAULT_SORTING: ISorting = {
    sortField: ESortField.sortByPriority,
    isDesc: true,
    isEnabled: false
};

export const START_DATE_TIME = getDateFromTime(9);
export const DUE_DATE_TIME = getDateFromTime(18);

export const getBoardInitialState = () => ({...boardInitialState});

const boardInitialState: IBoard = {
    fileId: '',
    status: TStatus.STATUS_ACTIVE,
    users: null,
    template: false,
    cardFromEmail: '',
    id: 0,
    name: '',
    author: null,
    description: '',
    created: 0,
    permissions: null,
    shared: false,
    timeZone: '',
    hash: '',
    logo: '',
    starred: false,
    theme: null,
    collaborators: [],
    orderNumber: 0,
    rightPanel: false,
    listIds: [],
    cardNumberEnabled: false,
    cardNumberNextValue: 0,
    cardNumberPrefix: '',
    userMeta: {},
    meta: {
        cardCustomProperties: {},
        cardProperties: [],
        isMyTasksDone: false,
        defaultStartDateTime: START_DATE_TIME,
        defaultDueDateTime: DUE_DATE_TIME,
        cardAging: {},
        isAutoStartDate: false
    },
};

export const BOARD_RIGHT_PANEL_VALUE_CARD_DETAILS = 'card-form';

export const FREE_VERSION_CARDS_COUNT = 10;
