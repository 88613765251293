import * as moment from 'moment';
import { getMessages } from '../../../store/constants';

export const statisticsDateFormat = 'YYYY-MM-DD';

export const translateToStringDate =
(date: number) => {
    return moment(date).format(statisticsDateFormat);
}

export const getDefaultStartDate = (): number => {
    const startOfThisMonth = moment().startOf('month');
    const defaultStart = startOfThisMonth.subtract(1, 'month');
    return defaultStart.valueOf();
}

export const getDefaultEndDate = () => {
    const endOfPastMonth = moment().subtract(1, 'month').endOf('month');
    return endOfPastMonth.valueOf();
}

export const getDateAgo = (
    timestamp: number,
    isUserTimeFormat12: boolean = false
): string => {
    const date = new Date(timestamp * 1000);
    const now = Math.floor(new Date().getTime() / 1000);
    const diff = now - timestamp;
    const diffMinutes = Math.floor(diff / 60);
    const diffHours = Math.floor(diffMinutes / 60);

    if (diffMinutes < 60) {
        return (diffMinutes < 2 ?
            getMessages().getText('util.few_seconds_ago') :
            diffMinutes + ' ' + getMessages().getText('util.some_minutes_ago')
        );
    } else {
        if (diffHours < 24) {
            return (diffHours < 2 ?
                getMessages().getText('util.one_hour_ago') :
                diffHours + ' ' + getMessages().getText('util.some_hours_ago'));
        } else {
            const format = isUserTimeFormat12 ? 'hh:mm A' : 'HH:mm';
            return moment(date).format(format)
        }
    }
}

export const getDateFormatted = (
    timestamp: number,
    isUserTimeFormat12: boolean = false
): string => {
    const format = isUserTimeFormat12 ? 'DD MMM YYYY hh:mm A' : 'DD MMM YYYY HH:mm';
    return moment(timestamp * 1000).format(format)
}
