import { connect } from 'react-redux';
import { ICardProgressHOCProps } from './types';
import { onClick } from './events/onClick';
import { ICardProgressEvents, ICardProgressFields } from '../components/types';
import { DONE_PROCESSING_STATUS } from '../../../../main/kanbanView/components/Card/components/Card/constants';
import { getCard } from '../../../../../../store/model/selectors/getCard';
import { IApplicationState } from '../../../../../../types/types';
import { CardProgress } from '../components/CardProgress';
import { getCardCanBeSetDone } from '../../../../../../store/model/selectors/getCardCanBeSetDone';
import { CARD_PROGRESS_TOOLTIP_DONE, CARD_PROGRESS_TOOLTIP_EMPTY, CARD_PROGRESS_TOOLTIP_PROGRESS } from './constants';
import * as moment from 'moment/moment';
import { getSectionType } from '../../../../aside_panel/cardDetails/selectors/getSectionType';
import { ESettingsSectionType } from '../../SettingsSection/types';
import { getBoardIdByCardId } from '../../../../../../store/model/selectors/getBoardIdByCardId';

const mapStateToProps = (
    state: IApplicationState,
    { cardId, size, backgroundColor, isLightBgStroke }: ICardProgressHOCProps
): ICardProgressFields => {
    const { processingPercent, processingStatus, doneDate } = getCard(state, cardId);
    const progress = processingStatus === DONE_PROCESSING_STATUS ? 100 : processingPercent;
    const boardId = getBoardIdByCardId(state, cardId);
    const isReadonly = getSectionType(state, boardId, cardId) === ESettingsSectionType.READONLY;
    const canDone = !isReadonly && getCardCanBeSetDone(state, cardId);
    let tooltip;
    if (doneDate && progress === 100) {
        tooltip = CARD_PROGRESS_TOOLTIP_DONE + moment(doneDate * 1000).format('DD MMM');
    } else if (canDone) {
        tooltip = CARD_PROGRESS_TOOLTIP_EMPTY;
    } else {
        tooltip = CARD_PROGRESS_TOOLTIP_PROGRESS;
    }
    return {
        backgroundColor,
        canDone,
        isLightBgStroke,
        isReadonly,
        progress,
        size,
        tooltip
    };
};

const mapDispatchToProps = (
    dispatch: any,
    { cardId }: ICardProgressHOCProps
): ICardProgressEvents => {
    return {
        onClick: () => dispatch(onClick(cardId))
    };
};

export const CardProgressHOC = connect(
    mapStateToProps,
    mapDispatchToProps,
)(CardProgress);

CardProgressHOC.displayName = 'CardProgressHOC';
