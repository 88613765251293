import {ISharingDialogItemProps} from './types';
import * as React from 'react';
import { Button, Divider, Icon, Select, SelectList, SelectListItem } from 'kui';
import './_SharingDialogItem.scss';
import { EFilePermissionRole, EGrantedType, IGranted } from 'app/helper/authorisation/microsoft/graph/api/getFilePermissions';
import { CLASS_SHARE_DIALOG_ITEM, SHARE_DIALOG_USERPICS_MAX } from './constants';
import { UserpicStorage } from 'app/view/react_components/base/components/UserpicStorage/UserpicStorage';
import { PORTAL_ID_SHARING_DIALOG, SHARE_DIALOG_ROLE_ICONS, SHARE_DIALOG_ROLE_OPTIONS, SHARE_DIALOG_ROLE_REMOVE } from '../SharingDialog/constants';
import { ListItem } from 'app/view/react_components/base/components/ListItem/ListItem';

export function SharingDialogItem ({
    item,
    msAllowEditFilePermissionRole,
    msAllowDeleteFilePermission,
    onChangeRole,
}: ISharingDialogItemProps) {
    const [isOpened, setOpened] = React.useState(false);

    const className = CLASS_SHARE_DIALOG_ITEM;
    const portalId = PORTAL_ID_SHARING_DIALOG;
    const classButton = className + '__button';
    const classPlus = className + '__plus';

    const actions = (item.role === EFilePermissionRole.OWNER)
        ? (
            <div
                className={`${className}__people-with-access-item-owner`}
            >
                {EFilePermissionRole.OWNER}
            </div>
        )
        : (<>
            <Select
                active={SHARE_DIALOG_ROLE_OPTIONS.findIndex(role => role === item.role)}
                className={`${className}__people-with-access-item-roles`}
                readOnly={!item.sharedLink && !msAllowEditFilePermissionRole && !msAllowDeleteFilePermission}
                dropdownClassName={`${className}__people-with-access-item-roles-dd`}
                isFitWindow
                portal
                portalId={portalId}
                variant={'arrow'}
                onChange={(e) => onChangeRole(e)}
            >
                <SelectList fixActive={false}>
                    {SHARE_DIALOG_ROLE_OPTIONS.map(option => (
                        <SelectListItem
                            className={item.sharedLink || !msAllowEditFilePermissionRole ? 'disabled' : ''} // линки нельзя редактировать
                            icon={SHARE_DIALOG_ROLE_ICONS[option]}
                            key={option}
                            value={option}
                        >
                            {option}
                        </SelectListItem>
                    ))}
                    <Divider />
                    <SelectListItem
                        className={!msAllowDeleteFilePermission && !item.sharedLink ? 'disabled' : ''} // линки все могут удалять
                        icon={'close'}
                        value={''}
                    >
                        {SHARE_DIALOG_ROLE_REMOVE}
                    </SelectListItem>
                </SelectList>
            </Select>
            <Icon
                className={`${className}__people-with-access-item-roles-icon`}
                xlink={SHARE_DIALOG_ROLE_ICONS[item.role]}
            />
        </>);

    let img = null;
    let text = null;
    if (item.granted && item.granted.length) {
        if (item.granted.length === 1) {
            let granted = item.granted[0];
            if (granted && granted.type) {
                if (granted.type === EGrantedType.GROUP) {
                    img = <Icon xlink={'share-specific'} size={24} />;
                } else if (granted.type === EGrantedType.USER) {
                    img = User(granted);
                }
            }
            text = granted && (granted.displayName || granted.id) || item.id;
        } else {
            let users = item.granted;
            text = <>
                <div className={`${className}__text`}>
                    <Button
                        className={classButton}
                        variant={'icon-text'}
                        onClick={()=>setOpened(!isOpened)}
                    >
                        {users.slice(0, SHARE_DIALOG_USERPICS_MAX - 1).map(user => User(user))}
                        {users.length > SHARE_DIALOG_USERPICS_MAX &&
                            <div className={classPlus}>
                                <span>+{users.length - SHARE_DIALOG_USERPICS_MAX + 1}</span>
                            </div>
                        }
                        <Icon className={`${className}__arrow`} xlink="arrow-down" />
                    </Button>
                </div>
                <div className={`${className}__list`}>
                    {users.map(user => {
                        return <div className={`${className}__list-item`}>
                            {User(user, 16)} {user.displayName}
                        </div>
                    })}
                </div>
            </>;
        }

    }

    return <ListItem
        className={`${className}__people-with-access-item ${isOpened ? 'opened' : ''}`}
        actions={actions}
        img={img}
        isLoading={item.isLoading}
        key={item.id}
        text={text}
    />
};

function User (
    user: IGranted,
    size: 16 | 24 = 24,
) {
    const className = CLASS_SHARE_DIALOG_ITEM + '__user';
    let initials = user.displayName && user.displayName.substring(0, 2);

    return (
        <UserpicStorage
            className={className}
            initials={initials}
            key={user.id}
            size={size}
            tooltip={{
                isNoEvents: true,
                isNoWrap: true,
                value: user.displayName
            }}
            userId={user.id}
        />
    );
};
