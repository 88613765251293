import { IGetState, TBoardId } from '../../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { cardNumberSetAction } from '../../../store/cardNumbers/actions/cardNumberSetAction';
import { cardNumberStateUpdateAction } from '../../../store/cardNumber/actions/cardNumberUpdate';
import { getBoard } from '../../../../../../../../../store/model/selectors/getBoardById';
import { ICardNumberState } from '../../../store/cardNumber/types';
import {getPrefixFromBoardName} from '../../../helpers/getPrefixFromBoardName';

export const updateCardNumberFromBoard = (
    boardId: TBoardId
): ThunkAction => {
    const action = (dispatch: Dispatch, getState: IGetState) => {
        const board = getBoard(getState(), boardId);
        if (board) {
            const state: Partial<ICardNumberState> = {
                enabled: board.cardNumberEnabled,
                prefix: board.cardNumberPrefix || getPrefixFromBoardName(board.name)
            }
            if (board.cardNumberNextValue) {
                state.nextValue = ''+board.cardNumberNextValue
            }
            dispatch(cardNumberSetAction(boardId, cardNumberStateUpdateAction(state)));
        }
    }
    return action;
}
