import { IGetState, TCardId } from '../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { fetchHandler } from '../../../../../util/fetchHandler';
import { Util } from '../../../../../util/util';
import { getRestHeadersDelete } from '../../../../helpers/getRestHeadersHelper';
import { IDriveDoc } from '../../../../../store/model/card/types/IDriveDoc';
import { REST_ATTACHMENT } from '../../../../constants';
import { TDriveDocId } from '../../../../../types/rest/IRestDriveDoc';

export const deleteRest = (
    cardId: TCardId,
    driveDocId: TDriveDocId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return fetchHandler<IDriveDoc[]>(
            Util.getApiUrl(REST_ATTACHMENT + driveDocId),
            {
                ...getRestHeadersDelete()
            }
        );
    };
    return action;
};
