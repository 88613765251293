import { AsidePanelActionsButton } from '../../../..';
import * as React from 'react';
import { getPopupAttributes } from '../../../../../base/components/SettingsSection/constants';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { ICustomPropertiesPanelButtonProps } from './types';
import { CLASS_CARD_DETAILS_SECTION } from '../../../constants';
import { EAsidePanelProperty } from '../../../../asidePanel/components/AsidePanel/types';
import { CARD_SECTION_CUSTOM } from '../constants';
import { CustomPropertiesSectionHOC } from '../../hocs/CustomPropertiesSectionHOC/CustomPropertiesSectionHOC';
import { getAsidePanelActionsButtonTooltip } from '../../../../asidePanel/helpers/getAsidePanelActionsButtonTooltip';
import {
    CLASS_ASIDE_PANEL_ACTIONS_BUTTON_CONTAINER,
    CLASS_ASIDE_PANEL_ACTIONS_POPUP
} from '../../../../asidePanel/components/AsidePanelActionsButton/constants';
import { CLASS_ASIDE_PANEL_BODY } from '../../../../asidePanel/components/AsidePanelBody/constants';
import { v4 as uuidv4 } from 'uuid';
import { ITooltipInheritedProps } from 'kui';

export function CustomPropertiesPanelButton ({
    elements,
    indicator,
    isBasicFeature,
    isProFeature,
    isShowTooltip,
    isDisallow,
    isShow,
    onClick
}: ICustomPropertiesPanelButtonProps) {
    if (!isShow) return null;

    const { boardId, cardId, setIsScrollDisabled, showedProperties, openedProperty } = React.useContext(AsidePanelContext);
    const isShowed = showedProperties.has(EAsidePanelProperty.CARD_CUSTOM) && openedProperty !== EAsidePanelProperty.CARD_CUSTOM;
    const [isOpened, setIsOpened] = React.useState(null);
    const [key, setKey] = React.useState(uuidv4());
    const timer = React.useRef(null);

    const onOpened = () => {
        setIsOpened(true);
        timer.current = setTimeout(checkScroll, 1000);
    }
    const onClosed = () => setIsOpened(false);

    /**
     * Попапы обычно вписаны в экран и скрол панели отключается, чтобы ничего не скакало при добавлении-удалении свойств.
     * Но у CustomProperties нестандартный попап, в нём должны поместиться все 100500 кастомных полей доски.
     * Если попап не помещается, включить обратно скрол панели.
     */
    function checkScroll () {
        const body = document.querySelector(`.${CLASS_ASIDE_PANEL_BODY}`) as HTMLElement;
        const dropdown = document.querySelector(`.${CLASS_ASIDE_PANEL_ACTIONS_POPUP}--custom > .kui-dropdown__item`) as HTMLElement;
        if (!body || !dropdown) return;
        setIsScrollDisabled(dropdown.offsetHeight < body.offsetHeight);
    }

    React.useEffect(() => {
        timer.current = setTimeout(checkScroll, 100);
    }, [elements]);

    React.useEffect(() => {
        return () => {
            if (timer.current) clearTimeout(timer.current);
        }
    }, []);

    const onToggleEdit = (value: boolean) => {
        timer.current = setTimeout(() => {
            if (value) {
                setIsScrollDisabled(false);
            } else {
                checkScroll();
                setKey(uuidv4());
            }
        }, 100);
    }

    const tooltip: ITooltipInheritedProps = isShowTooltip ? getAsidePanelActionsButtonTooltip({ value: CARD_SECTION_CUSTOM }) : null;

    return (
        <AsidePanelActionsButton
            className={CLASS_ASIDE_PANEL_ACTIONS_BUTTON_CONTAINER + '--custom'}
            dropdownClassName={`${CLASS_ASIDE_PANEL_ACTIONS_POPUP}--custom`}
            indicatorNumber={indicator}
            icon={'plus'}
            isDisabled={isDisallow}
            isIconHiddenOnFullSize={true}
            isOpened={isOpened}
            openedProperty={EAsidePanelProperty.CARD_CUSTOM}
            sectionSelector={`.${CLASS_CARD_DETAILS_SECTION}--custom`}
            title={CARD_SECTION_CUSTOM}
            tooltip={tooltip}
            onClick={onClick}
            onOpen={onOpened}
            isBasicFeature={isBasicFeature}
            isProFeature={isProFeature}
            dropdownKey={key}
            notBlurClasses={['kui-snackbar--variant_promt', `card-details-custom__form-type-dropdown`]}
        >
            {!isDisallow && !isShowed &&
                <CustomPropertiesSectionHOC
                    boardId={boardId}
                    cardId={cardId}
                    onClose={onClosed}
                    onToggleEdit={onToggleEdit}
                    {...getPopupAttributes()}
                />
            }
        </AsidePanelActionsButton>
    );
}
