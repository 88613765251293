import * as React from 'react';
import './_CardDatesButton.scss';
import { ICardDatesButtonProps } from './types';
import { Button } from 'kui';
import { CLASS_CARD_DETAILS } from '../../../constants';

export const CardDatesButton: React.FC<ICardDatesButtonProps> =
React.forwardRef(({
    children,
    className,
    variant,
    ...attributes
}, ref) => {
    const CLASS_CARD_DATES = CLASS_CARD_DETAILS + '-dates';
    let classNameButton = CLASS_CARD_DATES + '__button';
    if (className) classNameButton += ' ' + className;

    variant = variant || 'icon-text';

    return (
        <Button
            className={classNameButton}
            ref={ref as any}
            variant={variant}
            {...attributes}
        >
            {children}
        </Button>
    );
});

CardDatesButton.displayName = 'Button';
