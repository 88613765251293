import { connect } from 'react-redux';
import { IBoardAttachmentsHeaderHOCProps } from './types';
import { IApplicationState } from '../../../../../../../types/types';
import { IBoardAttachmentsHeaderEvents, IBoardAttachmentsHeaderFields } from '../components/types';
import { BoardAttachmentsHeader } from '../components/BoardAttachmentsHeader';
import { onViewTypeChange } from './events/onViewTypeChange';
import { onSearchClick } from './events/onSearchClick';
import { onTabClick } from './events/onTabClick';

const mapStateToProps = (
    state: IApplicationState,
    { searchQuery, tab, isGridView }: IBoardAttachmentsHeaderHOCProps
): IBoardAttachmentsHeaderFields => {
    return {
        tab,
        searchQuery,
        isGridView
    }
};

const mapDispatchToProps = (
    dispatch: any,
    { onSearchChange, onTabChange }: IBoardAttachmentsHeaderHOCProps
): IBoardAttachmentsHeaderEvents => {
    return {
        onTabChange,
        onSearchChange,
        onViewTypeChange: (isGridView) => dispatch(onViewTypeChange(isGridView)),
        onSearchClick: () => dispatch(onSearchClick()),
        onTabClick: (tab) => dispatch(onTabClick(tab))
    };
};

export const BoardAttachmentsHeaderHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(BoardAttachmentsHeader);

BoardAttachmentsHeaderHOC.displayName = 'BoardAttachmentsHeaderHOC';
