import { IApplicationState } from '../../../../types/types';
import { getAuthUser } from './getAuthUser';
import { IAuthUser } from '../types';

export const getAuthUserSupport = (
    state: IApplicationState,
    support: keyof IAuthUser
): boolean => {
    return !!getAuthUser(state)[support];
};
