const category = 'Card';

export const SegmentCardEvent = {
    TAB_ACTIVITY: { category, event: 'Card activity tab opened' },
    TAB_DETAILS: { category, event: 'Card details tab opened' },
    TAB_TIMING: { category, event: 'Card timing tab opened' },
    CREATE: { category, event: 'Card created' },
    CREATE_DOMAIN: { category, event: 'Card_created_by_domain_user' },
    DETAILS: { category, event: 'Card form opened' },
    COLOR_TAGS: { category, event: 'Colour tags dropdown menu opened' },
    COLOUR_TAGS_DOUBLE_CLICK: { category, event: 'Colour tag double clicked' },
    COLOUR_TAGS_DOUBLE_CLICK_SAVE: { category, event: 'Colour tag edited' },
    COLOUR_TAGS_USED_CLICKED: { category, event: 'Colour tags used on this board clicked' },
    COLOUR_TAGS_ALL_CLICKED: { category, event: 'Colour tags all colour labels clicked' },
    COLOUR_TAGS_CUSTOM_COLOR_SELECTED: { category, event: 'Custom colour labels colour chosen' },
    COLOUR_TAGS_CREATE: { category, event: 'Create colour labels clicked' },
    ADD_COLOR_TAG: { category, event: 'Colour tag added' },
    REMOVE_COLOR_TAG: { category, event: 'Colour tag removed' },
    CLEAR_COLOR_TAGS: { category, event: 'No colour tags' },
    EDIT_COLORS_LINK: { category, event: 'Edit colour tags clicked, boards details opened' },
    DONE: { category, event: 'Done status changed' },
    WATCH_CARD: { category, event: 'Watch card selected' },
    WATCH_CARD_NOTIFICATION_ICON_CLICKED: { category, event: 'Watch card notification icon clicked' },
    ACTIONS_OPENED: { category, event: 'CustomActions menu opened' },
    ACTIONS_CLOSED: {category, event:'CustomActions closed without actions'},
    COPY: { category, event: 'Copy card chosen' },
    ARCHIVE_WITH_SUBCARDS_YES: { category, event: 'Archive with subcards yes button clicked' },
    ARCHIVE_WITH_SUBCARDS_NO: { category, event: 'Archive with subcards no buttons clicked' },
    COPY_WITH_SUBCARDS_YES: { category, event: 'Copy with subcards yes button clicked' },
    COPY_WITH_SUBCARDS_NO: { category, event: 'Copy with subcards no buttons clicked' },
    MOVE_WITH_SUBCARDS_YES: { category, event: 'Move with subcards yes button clicked' },
    MOVE_WITH_SUBCARDS_NO: { category, event: 'Move with subcards no buttons clicked' },
    MOVE_WITH_CUSTOM_PROPERTIES_YES: { category, event: 'Move with custom properties yes button clicked' },
    MOVE_WITH_CUSTOM_PROPERTIES_NO: { category, event: 'Move with custom properties no buttons clicked' },
    PRINT: { category, event: 'Print card chosen' },
    GET_LINK: { category, event: 'Get card link chosen' },
    DELETE_CARD: { category, event: 'Delete card chosen' },
    ARCHIVE_ON_DELETE_CARD_PROCEED: {category, event: 'Archive in delete snackbar clicked'},
    DELETE_CARD_PROCEED: {category, event: 'Delete in delete snackbar clicked'},
    CARD_DELETED: {category, event: 'Card deleted'},
    DELETE_CARD_CANCELED: {category, event: 'Cancel in delete snackbar clicked'},
    DELETE_CARD_TIMEOUT: {category, event: 'Delete snackbar time out '},
    ARCHIVE_CARD_CANCELED: {category, event: 'Cancel in archive snackbar clicked'},
    ARCHIVE_CARD_PROCEED: {category, event: 'Archive in archive snackbar clicked'},
    DELETE_ON_ARCHIVE_CARD_PROCEED: {category, event: 'Delete in archive snackbar clicked'},
    MOVE_TO_DROPDOWN_OPENED: { category, event: 'Open move to dropdown' },
    MOVE_CARD_ON_BOARD_CHOSEN: { category, event: 'Move card on current board chosen' },
    MOVE_CARD_TO_ANOTHER_BOARD_CHOSEN: { category, event: 'Move card to another board chosen' },
    MOVE_TO_MAKE_FIRST_CHOSEN: { category, event: 'Move card - Make it the first card chosen' },
    MOVE_TO_CARD_MOVED: { category, event: 'Move to - card moved' },
    MOVE_TO_LOAD_MORE_CLICKED: { category, event: 'Move to - load more boards' },
    BACKGROUND_COLOR_OPENED: { category, event: 'Cards colour dropdown menu opened' },
    BACKGROUND_CHANGED: { category, event: 'Cards colour changed' },
    CHANGE_NAME: { category, event: 'Cards name changed' },
    CHANGE_MULTIPLE_CARDS_NAMES: { category, event: 'Multiple cards names are changed' },
    CARD_NAME_CLICKED: { category, event: 'Card title edit clicked' },
    CARD_NUMBER_CLICKED: { category, event: 'Card number in card details clicked' },
    SAVE_DESCRIPTION: { category, event: 'Save changes button clicked in cards description' },
    CTRL_ENTER_DESCRIPTION: { category, event: 'CTRL_ENTER_DESCRIPTION' },
    CANCEL_DESCRIPTION: { category, event: 'Cancel button clicked in cards description' },
    ESCAPE_NAME_CHANGE: { category, event: 'Card name change escaped' },
    USE_UNSAVED_DESCRIPTION: { category, event: 'Unsaved description used' },
    CLEAR_UNSAVED_DESCRIPTION: { category, event: 'Unsaved description cleared' },
    ADD_RELATED_CARDS_BUTTON: { category, event: 'Add related Cards button clicked' },
    RELATED_CARD_ADD_ANOTHER_BOARD: { category, event: 'Related card selected from another board' },
    RELATED_CARD_ADD: { category, event: 'Related card added' },
    RELATED_CARD_REMOVE: { category, event: 'Related card removed' },
    RELATED_SAVE: { category, event: 'Click Save related Cards' },
    RELATED_CLOSED: { category, event: 'Related cards dialogue closed' },
    RELATED_LIST_CLICK: { category, event: 'Related card clicked in related list' },
    RELATED_BOARD_SELECTED: { category, event: 'Board selected from related card dropdown' },
    ADD_SUBCARD_BUTTON: { category, event: 'Add subcard button clicked' },
    CHOOSE_SUBCARD: { category, event: 'Subcard added from existing cards' },
    CREATED_SUBCARD_BY_CTRL_ENTER: { category, event: 'Subcard created via CTRL_ENTER' },
    CREATED_SUBCARD: { category, event: 'New subcard created' },
    MOVED_SUBCARD: { category, event: 'Subcards order changed' },
    CLICKED_SUBCARD_LINK: { category, event: 'Subcard within Epic clicked' },
    CLICKED_SHOW_SUBCARD_ON_GANTT: { category, event: 'Show subcard on Gantt clicked' },
    REMOVED_SUBCARD: { category, event: 'Subcard removed' },
    SUBCARD_WEIGHT_CHANGED: { category, event: 'Subcard weight changed' },
    SUBCARDS_PIN_CLICKED: { category, event: 'Subcards pin clicked' },
    SUBCARDS_UNPIN_CLICKED: { category, event: 'Subcards unpin clicked' },
    SUBCARDS_HIDE_DONE_BUTTON_CLICKED_ON: { category, event: 'Subcards hide done items clicked - on' },
    SUBCARDS_HIDE_DONE_BUTTON_CLICKED_OFF: { category, event: 'Subcards hide done items clicked - off' },
    OPEN_PRIORITY: { category, event: 'Priority dropdown opened' },
    ADDED_PRIORITY: { category, event: 'Priority applied' },
    REMOVED_PRIORITY: { category, event: 'Priority removed' },
    OPEN_ASSIGN_TO: { category, event: 'Assign to dropdown opened' },
    ASSIGNED_USER: { category, event: 'User assigned' },
    UNASSIGNED_USER: { category, event: 'User unassigned' },
    PERFOMED_ASSIGNEES_SEARCH: { category, event: 'Assigned users search preformed' },
    ASSIGNEES_SHARE_CLICKED: { category, event: 'Assignees share button clicked' },
    START_DATE_OPENED: { category, event: 'Start date dropdown opened' },
    DUE_DATE_OPENED: { category, event: 'Due date dropdown opened' },
    START_DATE_SELECTED: { category, event: 'Start date selected' },
    DUE_DATE_SELECTED: { category, event: 'Due date selected' },
    CHOOSE_DATE: { category, event: 'Date selected' },
    CLICKED_CLEAR_DATE: { category, event: 'Clear date clicked' },
    CLICKED_CLEAR_START_DATE: { category, event: 'Start date clear clicked' },
    CLICKED_CLEAR_DUE_DATE: { category, event: 'Due date clear clicked' },
    CLICKED_START_TIME: { category, event: 'Start date time dropdown opened' },
    CHOOSE_START_TIME: { category, event: 'Start date time selected' },
    CHOOSE_UNEVEN_START_TIME: { category, event: 'Uneven start date time applied' },
    CLICKED_DUE_TIME: { category, event: 'Due date time dropdown opened' },
    CHOOSE_DUE_TIME: { category, event: 'Due date time selected' },
    CHOOSE_UNEVEN_DUE_TIME: { category, event: 'Uneven due date time applied' },
    ADJUST_START_DATE_CLICKED: { category, event: 'Adjust start date clicked' },
    ADJUST_DUE_DATE_CLICKED: { category, event: 'Adjust due date clicked' },
    ADJUST_FORWARD_CHOSEN: { category, event: 'Adjust forward chosen' },
    ADJUST_BACKWARD_CHOSEN: { category, event: 'Adjust backward chosen' },
    ADJUST_OK_CLICKED: { category, event: 'Adjust ok clicked' },
    ADJUST_CLOSE_CLICKED: { category, event: 'Adjust close clicked' },
    PUSH_TO_GOOGLE_CALENDAR_BUTTON_CLICKED: { category, event: 'push to google calendar button clicked' },
    PUSH_TO_GOOGLE_CALENDAR_DROPDOWN_OPENED: { category, event: 'Push to google calendar dropdown opened' },
    PUSH_START_DATE_CHOSEN: { category, event: 'push start date chosen' },
    PUSH_DUE_DATE_CHOSEN: { category, event: 'push due date chosen' },
    PUSH_ENTIRE_PERIOD_CHOSEN: { category, event: 'push entire chosen' },
    PUSH_TO_GOOGLE_CALENDAR_PERFORMED: { category, event: 'push to google calendar performed' },
    DELETE_FROM_GOOGLE_CALENDAR: { category, event: 'push to google calendar reversed' },
    ADDED_ESTIMATE: { category, event: 'Estimate added' },
    ESTIMATE_INVALID: { category, event: 'estimate invalid value' },
    SHOW_ON_GANTT_ON: { category, event: 'Show on Gantt - on' },
    SHOW_ON_GANTT_OFF: { category, event: 'Show on Gantt - off' },
    DEFAULT_TEMPLATE: { category, event: 'Default template' },
    CLICKED_ADD_PREDECESSOR: { category, event: 'Add dependence clicked' },
    CLICKED_LEARN_MORE_PREDECESSOR: { category, event: 'Learn more about dependence clicked' },
    CHOOSE_PREDECESSOR: { category, event: 'Predecessor added' },
    REMOVED_PREDECESSOR: { category, event: 'Predecessor removed' },
    CHANGED_DEPENDENCY_TYPE: { category, event: 'Dependency type changed' },

    CHECKLIST_ITEM_ADD: { category, event: 'Add new checklist item button clicked' },
    CHECKLIST_ITEM_ADDED: { category, event: 'Checklist item added' },
    CHECKED_CHECKLIST_ELEMENT: { category, event: 'Checklist item done' },
    UNCHECKED_CHECKLIST_ELEMENT: { category, event: 'Checklist item undone' },
    REMOVED_CHECKLIST_ELEMENT: { category, event: 'Checklist item removed' },
    CHANGED_WEIGHT_OF_CHECKLIST_ELEMENT: { category, event: 'Checklist item weight changed' },
    MOVED_CHECKLIST_ELEMENT: { category, event: 'Checklist items order changed' },
    CHECKLIST_ITEM_ASSIGN_CLICKED: { category, event: 'Checklist item assign button clicked' },
    CHECKLIST_MASS_ASSIGN_CLICKED: { category, event: 'Checklist mass assign button clicked' },
    CHECKLIST_ITEM_USER_ASSIGNED: { category, event: 'Checklist item user assigned' },
    CHECKLIST_ITEM_USER_UNASSIGNED: { category, event: 'Checklist item user unasigned' },
    CHECKLIST_USER_ASSIGNED: { category, event: 'Checklist user assigned' },
    CHECKLIST_USER_UNASSIGNED: { category, event: 'Checklist user unasigned' },
    CHECKLIST_SORT_BUTTON_CLICKED: { category, event: 'Checklist items sorted' },
    CHECKLIST_ADD: { category, event: 'Add new checklist button clicked' },
    CHECKLIST_ADDED: { category, event: 'New checklist added' },
    CHECKLIST_WITH_ITEMS_DELETED: { category, event: 'Checklist with items deleted' },
    CHECKLIST_WITH_ITEMS_DELETE_CANCELLED: { category, event: 'Checklist with items deletion cancelled' },
    CHECKLISTS_HIDE_DONE_BUTTON_CLICKED_ON: { category, event: 'Checklists hide done items clicked - on' },
    CHECKLISTS_HIDE_DONE_BUTTON_CLICKED_OFF: { category, event: 'Checklists hide done items clicked - off' },
    CHECKLIST_ITEM_START_DATE_CLICKED: { category, event: 'Checklist item start date clicked' },
    CHECKLIST_ITEM_START_DATE_ADDED: { category, event: 'Checklist item start date added' },
    CHECKLIST_ITEM_START_DATE_CHANGED: { category, event: 'Checklist item start date changed' },
    CHECKLIST_ITEM_START_DATE_REMOVED: { category, event: 'Checklist item start date removed' },
    CHECKLIST_ITEM_DUE_DATE_CLICKED: { category, event: 'Checklist item due date clicked' },
    CHECKLIST_ITEM_DUE_DATE_ADDED: { category, event: 'Checklist item due date added' },
    CHECKLIST_ITEM_DUE_DATE_CHANGED: { category, event: 'Checklist item due date changed' },
    CHECKLIST_ITEM_DUE_DATE_REMOVED: { category, event: 'Checklist item due date removed' },
    CHECKLIST_PIN_CLICKED: { category, event: 'Checklist pin clicked' },
    CHECKLIST_UNPIN_CLICKED: { category, event: 'Checklist unpin clicked' },
    CHECKLIST_ITEM_ESTIMATE_CLICKED: { category, event: 'Checklist item estimate clicked' },
    CHECKLIST_ITEM_WEIGHT_CLICKED: { category, event: 'Checklist item weight clicked' },
    CHECKLIST_ITEM_ESTIMATE_ADDED: { category, event: 'Checklist item estimate added' },
    CHECKLIST_ITEM_ESTIMATE_CHANGED: { category, event: 'Checklist item estimate changed' },
    CHECKLIST_ITEM_ESTIMATE_REMOVED: { category, event: 'Checklist item estimate removed' },
    CHECKLIST_COPY_CLICKED: { category, event: 'Copy checklist from a card chosen' },
    CHECKLIST_COPY_ESC_CLICKED: { category, event: 'Add checklist manually clicked' },
    CHECKLIST_COPY_OK_CLICKED: { category, event: 'Copy checklist clicked' },
    CREATED_CHECKLIST: { category, event: 'new checklist created'},
    CHECKLIST_AUTHOR_SHOW: { category, event: 'show author checklists clicked' },
    CHECKLIST_AUTHOR_HIDE: { category, event: 'hide author checklists clicked' },

    CLICKED_ADD_TAG: { category, event: 'Tags dropdown opened' },
    CHOOSE_TAG: { category, event: 'Existing tag applied' },
    CREATED_TAG: { category, event: 'Newly created tag applied' },
    REMOVED_TAG: { category, event: 'Tag removed' },
    RENAME_TAG_CLICKED: { category, event: 'Rename tag clicked' },
    ONE_TAG_RENAMED: { category, event: 'One tag renamed' },
    SENT_COMMENT: { category, event: 'Comment added' },
    CLEARED_COMMENT_INPUT: { category, event: 'Comment cleared while adding comment' },
    CLICKED_ON_COMMENT: { category, event: 'Existing comment opened' },
    CLICKED_CANCEL_ON_COMMENT: { category, event: 'Clicked cancel while editing comment' },
    CLICKED_SAVE_SHANGES_ON_COMMENT: { category, event: 'Clicked save while editing comment' },
    USE_UNSAVED_COMMENT: { category, event: 'Clicked on Comment has unsaved edits' },//todo deprecated
    CLEAR_UNSAVED_COMMENT: { category, event: 'Clicked on Clear unsaved comment edits' },//todo deprecated
    COMMENT_REPLY_CLICKED: { category, event: 'Reply on comment clicked' },
    COMMENT_LIKE_CLICKED: { category, event: 'Comment like clicked' },
    ARCHIVE_CARD: { category, event: 'Archive card clicked' },
    ARCHIVE_EPIC: { category, event: 'Archive epic with its subcards clicked' },
    ATTACH_FILE_BUTTON_CLICKED: { category, event: 'attach file button clicked' },
    ATTACH_FILE_FAQ_CLICKED: { category, event: 'attach file faq clicked' },
    ATTACH_FILE_DROPPED: { category, event: 'Attachment dropped' },
    ATTACH_FILE_PASTED: { category, event: 'Attachment pasted from clipboard' },
    ATTACH_FILE_PASTE_FAIL: { category, event: 'Attachment pasted from clipboard (error)' },
    ATTACH_FILE_SELECT_CLICKED: { category, event: 'Attach Select file clicked' },
    ATTACHED_FILE_DOWNLOAD_CLICKED: { category, event: 'attached file download clicked' },
    ATTACHED_FILE_REMOVED: { category, event: 'attached file removed' },
    ATTACHED_FILE_PINNED: { category, event: 'attached file pinned' },
    ATTACHED_FILE_UNPINNED: { category, event: 'attached file unpinned' },
    ATTACHED_FILE_LINK_CLICKED: { category, event: 'attached file link clicked' },
    ATTACHED_FILE_COPY_LINK_CLICKED: { category, event: 'attached file copy link clicked' },
    ATTACHMENT_RENAMED: { category, event: 'attachment renamed' },
    ATTACHMENT_VIEW_TYPE_GRID: { category, event: 'Attachment view type clicked - grid' },
    ATTACHMENT_VIEW_TYPE_LIST: { category, event: 'Attachment view type clicked - list' },
    ATTACHMENT_VIEW_TYPE_GRID_PAID: { category, event: 'Paid user attachment view type clicked - grid' },
    ATTACHMENT_VIEW_TYPE_LIST_PAID: { category, event: 'Paid user attachment view type clicked - list' },
    ATTACHMENT_TAB_CLICKED: { category, event: 'attachment tab clicked' },
    COMMENT_ATTACHMENT_ADDED: { category, event: 'attachment added to comment' },
    COMMENT_ATTACHMENT_ADD_CLICKED: { category, event: 'add attachment to comment clicked' },
    COMMENT_ATTACHMENT_PASTE: { category, event: 'attachment pasted from clipboard to comment' },
    RELATED_CARDS_HELP_OPENED: { category, event: 'Related card help opened' },
    SUBCARD_HELP_OPENED: { category, event: 'Subcard help opened' },
    PRIORITY_HELP_OPENED: { category, event: 'Priority help opened' },
    ASSIGN_TO_HELP_OPENED: { category, event: 'Assign to help opened' },
    DATES_HELP_OPENED: { category, event: 'Period help opened' },
    PUSH_TO_GOOGLE_CALENDAR_HELP_OPENED: { category, event: 'Push to google calendar help opened' },
    PREDECESSOR_HELP_OPENED: { category, event: 'Predecessor help opened' },
    COLOUR_TAGS_HELP_OPENED: { category, event: 'Colour tags help opened' },
    CHECKLIST_HELP_OPENED: { category, event: 'Checklist help opened' },
    RELATED_CARDS_HELP_CLICKED_FAQ: { category, event: 'Related card help clicked FAQ' },
    SUBCARD_HELP_CLICKED_FAQ: { category, event: 'Subcard help clicked FAQ' },
    PRIORITY_HELP_CLICKED_FAQ: { category, event: 'Priority help clicked FAQ' },
    ASSIGN_TO_HELP_CLICKED_FAQ: { category, event: 'Assign to help clicked FAQ' },
    DATES_HELP_CLICKED_FAQ: { category, event: 'Period help clicked FAQ' },
    COLOUR_TAGS_HELP_CLICKED_FAQ: { category, event: 'Colour tags help clicked FAQ' },
    PUSH_TO_GOOGLE_CALENDAR_HELP_CLICKED_FAQ: { category, event: 'Push to google calendar help clicked FAQ' },
    PREDECESSOR_HELP_CLICKED_FAQ: { category, event: 'Predecessor help clicked FAQ' },
    CHECKLIST_HELP_CLICKED_FAQ: { category, event: 'Checklist help clicked FAQ' },
    DRAG_N_DROP_IN_CURRENT_LIST_PERFORMED: { category, event: 'drag n drop in current list performed' },
    DRAG_N_DROP_TO_ANOTHER_LIST_PERFORMED: { category, event: 'drag n drop to another list performed' },
    CARD_IN_LIST_DROPDOWN_OPENED: { category, event: 'card in list drop dropdown opened' },
    LIST_AT_CARD_IN_LIST_CHOSEN: { category, event: 'list at card in list chosen' },
    ON_CARD_ATTACHMENTS_CLICKED: { category, event: 'on card: attachments clicked'},
    ON_CARD_TAGS_CLICKED: { category, event: 'on card: tags clicked'},
    ON_CARD_COMMENTS_CLICKED: { category, event: 'on card: comments clicked'},//todo deprecated
    ON_CARD_ASSIGNEES_CLICKED: { category, event: 'on card: assignees clicked'},
    ON_CARD_PRIORITY_CLICKED: { category, event: 'on card: priority clicked'},
    ON_CARD_DATES_CLICKED: { category, event: 'on card: dates clicked'},
    ON_CARD_CHECKLIST_CLICKED: { category, event: 'on card: checklist clicked'},
    COMMENT_SORTING_CLICKED:  { category, event: 'comment sorting clicked'},
    SAVE_DESCRIPTION_WITHOUT_CHANGES_CLICKED: { category, event: 'save description without changes clicked'},
    DESCRIPTION_CANCEL_WITHOUT_CHANGES_CLICKED: { category, event: 'description cancel without changes clicked'},
    DESCRIPTION_ESCAPE_CLICKED: { category, event: 'Card description change escaped'},
    DESCRIPTION_PIN_CLICKED: { category, event: 'Description pin clicked' },
    DESCRIPTION_UNPIN_CLICKED: { category, event: 'Description unpin clicked' },
    NOTIFICATION_BEFORE_DUE_DATE_CLICKED: { category, event: 'notification before due date add clicked'},
    NOTIFICATION_BEFORE_DUE_DATE_SAVE_CLICKED: { category, event: 'notification before due date save clicked'},
    NOTIFICATION_BEFORE_DUE_DATE_SAVED: { category, event: 'notification before due date saved'},
    BUTTONS_PANEL_BUTTON_CLICKED: { category, event: 'buttons panel button clicked' },
    CARD_DETAILS_CLOSE_CLICKED: { category, event: 'Card details close clicked' },
    EPIC_LINK_CLICKED: { category, event: 'Epic link clicked' },
    DISALLOWED_SECTION_CLICKED: { category, event: 'Disallowed section clicked' },
    CARD_PREFIX_DELETED: { category, event: 'card prefix deleted' },
    CARD_PREFIX_GENERATED: { category, event: 'card prefix generated' },
    CARD_DROPDOWN_BUTTON_CLICKED: { category, event: 'card dropdown button clicked' },
    CREATE_SUBCARD_FROM_CHECKLIST_CLICKED: { category, event: 'create subcard from checklist clicked' },
    CREATE_SUBCARD_FROM_CHECKLIST_CANCEL: { category, event: 'create subcard from checklist clicked no button clicked' },
    CARD_CLICKED: { category, event: 'Card clicked' },
    CARD_CLICKED_WITH_SHIFT: { category, event: 'card clicked with shift' },
    CARD_CLICKED_WITH_CTRL: { category, event: 'card clicked with ctrl' },
    ADD_NEW_TEMPLATE_BUTTON_CLICKED: { category, event: 'Add new template button clicked' },
    ADD_NEW_RECURRING_BUTTON_CLICKED: { category, event: 'Add new recurring button clicked' },
    TEMPLATE_FAQ_CLICKED: { category, event: 'template FAQ link clicked' },
    RECURRING_FAQ_CLICKED: { category, event: 'recurring FAQ link clicked' },
    ADD_TO_BOARD_LIST_CLICKED: { category, event: 'add to board list clicked' },
    MAKE_RECURRING_SAVE_CLICKED: { category, event: 'make recurring save clicked' },
    MAKE_RECURRING_CANCEL_CLICKED: { category, event: 'make recurring cancel clicked' },
    RECURRING_PERIOD_CHOSEN: { category, event: 'recurring period chosen' },
    RECURRING_ENDS_CHOSEN: { category, event: 'recurring ends chosen' },
    CARD_COLOUR_SHOW_MORE_CLICKED: { category, event: 'Cards colour show more clicked' },
    MILESTONE_ON: { category, event: 'Milestone - on' },
    MILESTONE_OFF: { category, event: 'Milestone - off' },
    START_WORKING_CLICKED: { category, event: 'Start working clicked' },
    DELETE_CLICKED_ON_ARCHIVE_SNACKBAR: { category, event: 'delete clicked on archive snackbar' },
    EDIT_CARD_DROPDOWN_CLICKED: { category, event: 'edit card in dropdown clicked' },
    RENAME_CARD_DROPDOWN_CLICKED: { category, event: 'rename in dropdown clicked' },
    ASSIGN_CARD_DROPDOWN_CLICKED: { category, event: 'assign to in dropdown clicked' },
    DATE_CARD_DROPDOWN_CLICKED: { category, event: 'set date in dropdown clicked' },
    COLOR_CARD_DROPDOWN_CLICKED: { category, event: 'add color in dropdown clicked' },
    PRIORITY_CARD_DROPDOWN_CLICKED: { category, event: 'set priority card in dropdown clicked' },
    GET_LINK_CARD_DROPDOWN_CLICKED: { category, event: 'get link to card in dropdown clicked' },
    MORE_ACTIONS_DROPDOWN_CLICKED: { category, event: 'more actions in dropdown clicked' },
    ROLE_ASSIGNED: { category, event: 'role assigned' },
    ROLE_UNASSIGNED: { category, event: 'role unassigned' },
    EDIT_ROLES_LINK: { category, event: 'Edit roles clicked, boards details opened' },
    SELECT_MULTIPLE_CARD_DROPDOWN_CLICKED: { category, event: 'select multiple cards in dropdown clicked' },
    CLICKED_ON_MULTISELECT_CHECKBOX: { category, event: 'multiselect checkbox clicked' },
    CREATE_GOOGLE_FILE_CLICKED: { category, event: 'create google file clicked' },

    CUSTOM_PROPERTY_ADDED: { category, event: 'Custom property added' },
    CUSTOM_PROPERTY_TYPE_CHANGED: { category, event: 'Custom property type changed' },
    CUSTOM_PROPERTIES_EDIT_CLICKED: { category, event: 'Edit custom property clicked' },
    CUSTOM_PROPERTIES_EDIT_SAVE_CLICKED: { category, event: 'Save custom edits clicked' },
    CUSTOM_PROPERTIES_EDIT_CANCEL_CLICKED: { category, event: 'Cancel custom edits clicked' },
    CUSTOM_PROPERTY_ADD_CLICKED: { category, event: 'Add custom property clicked' },
    CUSTOM_PROPERTIES_HIDE_CLICKED: { category, event: 'Hide empty properies clicked' },
    CUSTOM_PROPERTIES_SHOW_CLICKED: { category, event: 'Show empty properties clicked' },
    CUSTOM_PROPERTY_FILLED: { category, event: 'Custom property filled' },
    CUSTOM_PROPERTY_DELETED: { category, event: 'Custom property deleted' },
    CUSTOM_PROPERTY_OPTION_ADDED: { category, event: 'Option added for custom property' },
    CUSTOM_PROPERTY_PINNED: { category, event: 'custom property pinned' },
    CUSTOM_PROPERTY_UNPINNED: { category, event: 'custom property unpinned' },
    CUSTOM_PROPERTY_UNPINNED_CARD: { category, event: 'custom property unpinned on card' },
    CUSTOM_PROPERTY_PINNED_CARD: { category, event: 'custom property pinned on card' },

    LINK_CARD_BOARD_OPEN_CLICKED: { category, event: 'Open board clicked'},
    ASSIGNED_TO_ME_BOARD_OPEN_CLICKED: { category, event: 'Open board clicked from my tasks'},
    MISSING_CARD_PROPERTIES_INFO_IN_ACTIVITY: {category, event: 'Missing card properties info in activity'},

    CARD_DELETE_SNACKBAR: { category, event: 'shift delete snackbar showed' },
};

export enum SegmentCardOptions {
    LOCATION = 'location',
    VIA = 'via',
    STATUS = 'status',
    COMMENT_ORDER = 'order',
    TARGET = 'target',
    SOURCE = 'source',
    TYPE = 'TYPE',
    COLOR = 'color',
    COUNT = 'count',
    USED = 'used',
    FIRST = 'first',
    RED_DOT = 'red dot',
    UPDATES = 'updates',
    MULTISELECT = 'multiselect',
    LOADING_TIME = 'loading time',
    COLOUR_TAGS_COUNT = 'colour tags count',
    TAGS_COUNT = 'tags count',
    CHECKLISTS_COUNT = 'checklists count',
    CHECKLISTS_ITEMS_COUNT = 'checklists items count',
    SUBCARDS_COUNT = 'subcards count',
    KEYS = 'keys',
}

export enum SegmentCardCommentSorting {
    ASC = 'oldest first',
    DESC = 'newest first'
}

export enum SegmentCardType {
    CARD_INPUT = 'card input'
}

export enum SegmentCardOptionLocationValue {
    CURRENT = 'current',
    OTHER_CLOSED = 'other closed',
    OTHER_OPENED = 'other opened'
}
export enum SegmentCardOptionSourceValue {
    HOTKEY = 'hotKey',
    ACTIONS = 'card actions'
}

export enum SegmentCardOptionCardTypeValue {
    GENERAL = 'general',
    TEMPLATE = 'template',
    RECURRING = 'recurring',
    WITHOUT_BOARD = 'without board'
}

export enum SegmentCardOptionRecurringPeriodTypeValue {
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month',
    YEAR = 'year'
}

export enum SegmentCardOptionRecurringEndsTypeValue {
    NEVER = 'never',
    ON = 'on',
    AFTER = 'after'
}

export enum SegmentCardSection {
    ARCHIVE = 'archive',
    ARCHIVE_WITH_SUBCARDS = 'archive with subcards',
    ASSIGNEES = 'assignees',
    ATTACHMENTS = 'attachments',
    CHECKLIST = 'checklist',
    COLOUR_TAGS = 'colour tags',
    COMMENTS = 'comments',
    COPY = 'copy',
    CUSTOM = 'custom',
    DELETE = 'delete',
    DEPENDENCIES = 'dependencies',
    DONE = 'done',
    DUE_START_DATE = 'due/start date',
    EPIC = 'epic',
    ESTIMATE = 'estimate',
    GET_LINK = 'get link',
    MILESTONE = 'milestone',
    MORE_PROPERTIES = 'more properties',
    MOVE_TO = 'move to',
    PRINT = 'print',
    PRIORITY = 'priority',
    REALTED_CARDS = 'related cards',
    SHOW_ON_GANTT = 'show on gantt',
    SUBCARDS = 'subcards',
    TAGS = 'tags',
    UNARCHIVE = 'unarchive',
    TEMPLATE_CREATE_CARD = 'create card from template',
    SAVE_AS_TEMPLATE = 'save as template',
    MAKE_AS_RECURRING = 'make recurring',
    VIEW_ON_BOARD = 'view on board',
    DEFAULT_TEMPLATE = 'default'
}

export enum SegmentCardOptionStatusValue {
    ON = 'on',
    OFF = 'off',
}

export enum SegmentCardOptionRedDotValue {
    YES = 'yes',
    NO = 'no',
}

export enum SegmentCardCommentEditTargetOptionValue {
    CARD_DETAILS = 'card details',
    TIMING = 'Tab timing'
}

export enum SegmentCardRelatedTargetValue {
    SELECT = 'select',
    BOARD = 'board'
}

export enum SegmentCardColorTargetValue {
    BOARD_COLORS = 'Board colors',
    ALL_COLORS = 'All colors'
}

export enum SegmentCardSourceValue {
    CARD = 'card',
    DROPDOWN = 'card dropdown',
    DETAILS = 'card details',
    DETAILS_TOP_DROPDOWN = 'card details top dropdown',
    DASHBOARD = 'dashboard',
    BOARD_SETTINGS = 'board settings',
    SUBCARDS_LIST  = 'subcards list',
    BUTTONS_PANEL = 'buttons panel',
    HOTKEYS = 'hotkeys',
    BUTTON = 'button'
}

export enum SegmentCardDetailsSourceValue {
    DETAILS = 'card details',
    POPUP = 'popup',
}

export enum SegmentCardColorTagsSourceValue {
    TAG = 'tag',
    PLUS = 'plus',
    KEYBOARD = 'keyboard',
    BUTTONS_PANEL = 'buttons panel'
}

export enum SegmentCardColorTagAddSourceValue {
    CHECKBOX = 'checkbox',
    NOT_CHECKBOX = 'not checkbox',
}

export enum SegmentCardPasteFileTarget {
    CARD = 'card',
    COMMENT = 'comment'
}

export enum SegmentCountValue {
    COUNT = 'count'
}

export enum SegmentCardDetailsCloseSourceValue {
    BLUR_CARD = 'blur card',
    BLUR_CARD_WITHOUT_BOARD = 'blur card without board',
    BLUR_CARD_IN_MY_WORK = 'blur card in my work',
    CLOSE_BUTTON = 'close button',
    ESC_BUTTON = 'escape button',
    CARD_DELETED = 'card deleted',
    CARD_ARCHIVED = 'card archived'
}

export enum SegmentCardCreatedByKeysSourceValue {
    ENTER = 'enter',
    CTRL_ENTER = 'ctrl+enter',
    SHIFT_ENTER = 'shift+enter',
}
