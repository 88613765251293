import { IGetState, TBoardId, TCardAssigneeRoleId } from '../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { boardsActionSet } from '../../../../../store/model/actions/boardsActionSet';
import { boardActionSet } from '../../../../../store/model/boards/actions/boardActionSet';
import { ICardAssigneeRole } from '../../../../../types/model';
import { patchRest } from '../patchRest';
import { getRoleForRollback } from '../../../../../store/model/selectors/getRoleForRollback';
import { updateRoleAction } from '../../../../../store/model/board/actions/updateRoleAction';
import { sendRealTimeStoreActionByBoardId } from '../../../../../view/react_components/base/helpers/realTimeHelperTS';

export const boardRolePatchRest = (
    boardId: TBoardId,
    roleId: TCardAssigneeRoleId,
    role: ICardAssigneeRole,
    isRealtime: boolean = true, // send event to socket
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const roleForRollback = getRoleForRollback(getState(), boardId, roleId, role);
        const action = boardsActionSet(boardActionSet(boardId, updateRoleAction(roleId, role)));
        dispatch(action);
        return dispatch(patchRest(roleId, role))
            .then(() => {
                if (isRealtime) {
                    dispatch(sendRealTimeStoreActionByBoardId(boardId, action));
                }
                return role;
            })
            .catch((e: any) => {
                console.error(e);
                dispatch(boardsActionSet(boardActionSet(boardId, updateRoleAction(roleId, roleForRollback))));
            });
    };
    return action;
};
