import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState } from '../../../../../../../types/types';
import { onClose } from '../../../../../aside_panel/filterInfoPanel/hocs/events/onClose';
import {
    FP_KEY_CARDS_FILTER_ASSIGNED_TO_ME,
    MY_WORK_FILTER_BOARD_ID
} from '../../../../../aside_panel/filterPanelBoard/constants';
import {
    filterPanelBoardsSelector
} from '../../../../../aside_panel/filterPanelBoard/store/selectors/filterPanelBoardsSelector';
import { updateMyWorkMeta } from '../../../../../../../rest/effects/authUser/myWork/updateMyWorkMeta';
import { getAuthUser } from '../../../../../../../store/model/authUser/selectors/getAuthUser';
import { savedFilterSelector } from '../../../../../aside_panel/filterPanelBoard/store/selectors/savedFilterSelector';
import {
    savedFilterActionsSet
} from '../../../../../aside_panel/filterPanelBoard/store/savedFilters/actions/savedFilterActionsSet';
import { enabledSet } from '../../../../../aside_panel/filterPanelBoard/store/filter/actions/enabledSet';
import { patchFilter } from '../../../../../aside_panel/filterPanelBoard/effects/patchFilter';
import { getIsUserBasicPlanOnly } from '../../../../../../../store/model/authUser/selectors/getIsUserBasicPlanOnly';

export const onFilterClose = (): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const isBasicPlanOnly = getIsUserBasicPlanOnly(state);
        const { allowSavedFilters } = getAuthUser(state);
        const { savedFilterIds } = filterPanelBoardsSelector(state, MY_WORK_FILTER_BOARD_ID);
        let assignedToMeFilterId;
        savedFilterIds.forEach(filterId => {
            let savedFilter = savedFilterSelector(getState(), filterId);
            const isAssignedToMe = savedFilter.meta.isMyWorkReadonly && savedFilter.name === FP_KEY_CARDS_FILTER_ASSIGNED_TO_ME;
            if ((!allowSavedFilters || !isBasicPlanOnly) && isAssignedToMe) {
                assignedToMeFilterId = savedFilter.id;
            }
            if (
                savedFilter.isEnabled && (
                    !isAssignedToMe ||
                    !(isAssignedToMe && !allowSavedFilters && isBasicPlanOnly)
                )
            ) {
                dispatch(savedFilterActionsSet(filterId, enabledSet(false)));
                savedFilter = savedFilterSelector(getState(), filterId);
                dispatch(patchFilter(filterId, savedFilter));
            }
        });
        dispatch(updateMyWorkMeta({ enabledFilters: assignedToMeFilterId ? [assignedToMeFilterId] : [] }));
        if (allowSavedFilters || isBasicPlanOnly) {
            dispatch(onClose(MY_WORK_FILTER_BOARD_ID));
        }
    };
    return action;
};
