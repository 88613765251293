import { Dispatch, ThunkAction } from '../../../../../../types/actions';
import { enabledSet } from '../../../filterPanelBoard/store/filterPanel/actions/enabledSet';
import { filterPanelActionSetAction } from '../../../filterPanelBoard/store/filterPanelBoards/actions/filterPanelActionSetAction';
import { filterPanelBoardsSelector } from '../../../filterPanelBoard/store/selectors/filterPanelBoardsSelector';
import { editedFilterSet } from '../../../filterPanelBoard/store/filterPanel/actions/editedFilterSet';
import { FP_EDITED_FILTER_INITIAL_VALUE } from '../../../filterPanelBoard/constants';
import { defaultFilterReset } from '../../../filterPanelBoard/store/filterPanel/actions/defaultFilterReset';
import { IGetState, TBoardId } from '../../../../../../types/types';
import { keywordFiltrationEnabledSet } from '../../../filterPanelBoard/store/filterPanel/actions/keywordFiltrationEnabledSet';
import { panelOpenedSet } from '../../../filterPanelBoard/store/filterPanel/actions/panelOpenedSet';

export const closeFilterInfoPanel = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const { editedFilter } = filterPanelBoardsSelector(getState(), boardId);
        if (editedFilter) {
            dispatch(filterPanelActionSetAction(boardId, editedFilterSet(FP_EDITED_FILTER_INITIAL_VALUE)));
        }
        dispatch(filterPanelActionSetAction(boardId, panelOpenedSet(false)));
        dispatch(filterPanelActionSetAction(boardId, enabledSet(false)));
        dispatch(filterPanelActionSetAction(boardId, keywordFiltrationEnabledSet(false)));
        dispatch(filterPanelActionSetAction(boardId, defaultFilterReset()));
    };
    return action
};
