import { IProduct } from '../../../../store/model/authUser/types';

export enum EProductPlan {
    BASIC = 'BASIC',
    PREMIUM = 'PREMIUM',
    PROFESSIONAL = 'PROFESSIONAL',
    ENTERPRISE = 'ENTERPRISE',
};

export enum EProductFrequency {
    ANNUALLY = 12,
    MONTHLY = 1,
};

export interface IPricingProducts {
    [frequency: number]: {
        [pack: number]: IProduct;
    };
};
