import { ThunkAction, Dispatch } from '../../../../types/actions';
import { THintKey } from '../types';
import { hintActionSetAction } from '../store/hints/actions/hintActionSetAction';
import { hintStepSetAction } from '../store/hint/actions/hintStepSetAction';

export const hintTutorialHide = (
    key: THintKey
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(hintActionSetAction(key, hintStepSetAction(-1)));
    };
    return action;
};
