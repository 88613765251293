import { buttonsPanelTypeSetAction } from '../asidePanel/store/actions/buttonsPanelTypeSetAction';
import { getAsidePanelButtonsPanelType } from '../asidePanel/store/selectors/getAsidePanelButtonsPanelType';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState } from '../../../../types/types';
import { EButtonsPanelType } from '../asidePanel/store/types';

export const buttonsPanelTypeSwitch = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const buttonsPanelType = getAsidePanelButtonsPanelType(state);
        const buttonsPanelTypeNew = buttonsPanelType === EButtonsPanelType.NORMAL
            ? EButtonsPanelType.MINIMIZED
            : EButtonsPanelType.NORMAL;
        dispatch(buttonsPanelTypeSetAction(buttonsPanelTypeNew));
    };
    return action;
};
