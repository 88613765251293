import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { IGetState, TBoardId } from '../../../../../../../../../types/types';
import { SegmentBoardEvent, segmentTrackAction } from '../../../../../../../../../middlewares/segment';
import { getBoard } from '../../../../../../../../../store/model/selectors/getBoardById';
import { boardSetEnableOpenGraphInfo } from '../../../../../../../../../rest/effects/board/boardSetEnableOpenGraphInfo';

export const onChange = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentBoardEvent.CLICKED_LINKS_TO_CARD_PREVIEW));
        const board = getBoard(getState(), boardId);
        dispatch(boardSetEnableOpenGraphInfo(boardId, !board.enableOpenGraphInfo));
    };
    return action;
};
