import { connect } from 'react-redux';
import { onChange } from './events/onChange';
import { IApplicationState } from '../../../../../../../../types/types';
import { IDriveDocsImgsHOCProps } from './types';
import { DriveDocsImgs } from '../../components/DriveDocsImgs/DriveDocsImgs';
import { IDriveDocsImgsEvents, IDriveDocsImgsFields } from '../../components/DriveDocsImgs/types';
import { getCard } from '../../../../../../../../store/model/selectors/getCard';

const mapStateToProps = (
    state: IApplicationState,
    { images, cardId }: IDriveDocsImgsHOCProps
): IDriveDocsImgsFields => {
    const card = getCard(state, cardId);
    return {
        images,
        pinnedImageIndex: card.pinnedImageIndex || 0
    }
};

const mapDispatchToProps = (
    dispatch: any,
    { cardId }: IDriveDocsImgsHOCProps
): IDriveDocsImgsEvents => {
    return {
        onChange: (idx) => dispatch(onChange(cardId, idx))
    };
};

export const DriveDocImgsHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(DriveDocsImgs);

DriveDocImgsHOC.displayName = 'DriveDocImgsHOC';
