import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TBoardId, TCardId, TPermissionId } from '../../../../../../../../types/types';
import { EToggleAssigneeType, cardToggleAssignee } from '../effects/cardToggleAssignee';

export const onDelete = (
    boardId: TBoardId,
    cardId: TCardId,
    permissionId: TPermissionId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(cardToggleAssignee(boardId, cardId, permissionId, EToggleAssigneeType.DELETE));
    };
    return action;
};
