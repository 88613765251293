import * as React from 'react';

export function Table1 () {
    return (
                    <tbody>
                        <tr>
                            <th>
                                <p><strong>Criteria</strong></p>
                            </th>
                            <th>
                                <p><strong>Conformance Level</strong></p>
                            </th>
                            <th>
                                <p><strong>Remarks, Exceptions and plans</strong></p>
                            </th>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="http://www.w3.org/TR/WCAG20/#text-equiv-all"><strong>1.1.1 Non-text Content</strong></a> (Level A)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.1.1.1 (Web)</li>
                                    <li>10.1.1.1 (Non-web document)</li>
                                    <li>11.1.1.1.1 (Open Functionality Software)</li>
                                    <li>11.1.1.1.2 (Closed Functionality Software)</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508</p>
                                <ul>
                                    <li>501 (Web)(Software)</li>
                                    <li>504.2 (Authoring Tool)</li>
                                    <li>602.3 (Support Docs)</li>
                                </ul>
                            </td>
                            <td>
                                <p><strong>Supports with Minor Exceptions</strong></p>
                            </td>
                            <td>
                                <p>Proper alt text is defined for meaningful images but there is an exception.</p>
                                <p><strong>Exception:</strong></p>
                                <p>Decorative images present are not hidden for screen reader users.</p>
                                <p>Planned to be fixed by Q4 2021</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="http://www.w3.org/TR/WCAG20/#media-equiv-av-only-alt"><strong>1.2.1 Audio-only and Video-only (Prerecorded)</strong></a> (Level A)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.1.2.1 (Web)</li>
                                    <li>10.1.2.1 (Non-web document)</li>
                                    <li>11.1.2.1.1 (Open Functionality Software)</li>
                                    <li>11.1.2.1.2.1 and 11.1.2.1.2.2 (Closed Software)</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508</p>
                                <ul>
                                    <li>501 (Web)(Software)</li>
                                    <li>504.2 (Authoring Tool)</li>
                                    <li>602.3 (Support Docs)</li>
                                </ul>
                            </td>
                            <td>
                                <p><strong>Not Applicable</strong></p>
                            </td>
                            <td>
                                <p>There is no audio only and video only content in the application.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="http://www.w3.org/TR/WCAG20/#media-equiv-captions"><strong>1.2.2 Captions (Prerecorded)</strong></a> (Level A)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.1.2.2 (Web)</li>
                                    <li>10.1.2.2 (Non-web document)</li>
                                    <li>11.1.2.2 (Open Functionality Software)</li>
                                    <li>11.1.2.2 (Closed Software)&nbsp;</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508</p>
                                <ul>
                                    <li>501 (Web)(Software)&nbsp;</li>
                                    <li>504.2 (Authoring Tool)</li>
                                    <li>602.3 (Support Docs)</li>
                                </ul>
                            </td>
                            <td>
                                <p><strong>Not Applicable</strong></p>
                            </td>
                            <td>
                                <p>There is no multimedia content in the application.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="http://www.w3.org/TR/WCAG20/#media-equiv-audio-desc"><strong>1.2.3 Audio Description or Media Alternative (Prerecorded)</strong></a> (Level A)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.1.2.3 (Web)</li>
                                    <li>10.1.2.3 (Non-web document)</li>
                                    <li>11.1.2.3.1 (Open Functionality Software)</li>
                                    <li>11.1.2.3.2 (Closed Software)</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508</p>
                                <ul>
                                    <li>501 (Web)(Software)&nbsp;</li>
                                    <li>504.2 (Authoring Tool)</li>
                                    <li>602.3 (Support Docs)</li>
                                </ul>
                            </td>
                            <td>
                                <p><strong>Not Applicable</strong></p>
                                <br /></td>
                            <td>
                                <p>There is no multimedia content in the application.</p>
                                <br /></td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="http://www.w3.org/TR/WCAG20/#content-structure-separation-programmatic"><strong>1.3.1 Info and Relationships</strong></a> (Level A)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.1.3.1 (Web)</li>
                                    <li>10.1.3.1 (Non-web document)</li>
                                    <li>11.1.3.1.1 (Open Functionality Software)</li>
                                    <li>11.1.3.1.2 (Closed Software)</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508</p>
                                <ul>
                                    <li>501 (Web)(Software)</li>
                                    <li>504.2 (Authoring Tool)</li>
                                    <li>602.3 (Support Docs)</li>
                                </ul>
                            </td>
                            <td>
                                <p><strong>Supports</strong></p>
                            </td>
                            <td>
                                <p>Screen reader properly identifies the information available in the application and the screen reader user is easily able to perceive the relationships between the elements and their roles<strong>.</strong></p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="http://www.w3.org/TR/WCAG20/#content-structure-separation-sequence"><strong>1.3.2 Meaningful Sequence</strong></a> (Level A)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.1.3.2 (Web)</li>
                                    <li>10.1.3.2 (Non-web document)</li>
                                    <li>11.1.3.2.1 (Open Functionality Software)</li>
                                    <li>11.1.3.2.2 (Closed Software)</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508</p>
                                <ul>
                                    <li>501 (Web)(Software)</li>
                                    <li>504.2 (Authoring Tool)</li>
                                    <li>602.3 (Support Docs)</li>
                                </ul>
                            </td>
                            <td>
                                <p><strong>Supports</strong></p>
                            </td>
                            <td><p>Screen reader reads the content in the application in a meaningful sequence with the help of the arrow keys.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="http://www.w3.org/TR/WCAG20/#content-structure-separation-understanding"><strong>1.3.3 Sensory Characteristics</strong></a> (Level A)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.1.3.3 (Web)</li>
                                    <li>10.1.3.3 (Non-web document)</li>
                                    <li>11.1.3.3 (Open Functionality Software)</li>
                                    <li>11.1.3.3 (Closed Software)</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508</p>
                                <ul>
                                    <li>501 (Web)(Software)</li>
                                    <li>504.2 (Authoring Tool)</li>
                                    <li>602.3 (Support Docs)</li>
                                </ul>
                            </td>
                            <td>
                                <p><strong>Not Applicable</strong></p>
                            </td>
                            <td>
                                <p>There is no information in the application that is presented in the form of sensory characteristics.</p>
                                <br /></td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="http://www.w3.org/TR/WCAG20/#visual-audio-contrast-without-color"><strong>1.4.1 Use of Color</strong></a> (Level A)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.1.4.1 (Web)</li>
                                    <li>10.1.4.1 (Non-web document)</li>
                                    <li>11.1.4.1 (Open Functionality Software)</li>
                                    <li>11.1.4.1 (Closed Software)</li>
                                      <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508</p>
                                <ul>
                                    <li>501 (Web)(Software)</li>
                                    <li>504.2 (Authoring Tool)</li>
                                    <li>602.3 (Support Docs)</li>
                                </ul>
                            </td>
                            <td>
                                <p><strong>Not Applicable</strong></p>
                            </td>
                            <td>
                                <p>No color is used to indicate any type of information in the application.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="http://www.w3.org/TR/WCAG20/#visual-audio-contrast-dis-audio"><strong>1.4.2 Audio Control</strong></a> (Level A)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.1.4.2 (Web)</li>
                                    <li>10.1.4.2 (Non-web document)</li>
                                    <li>11.1.4.2 (Open Functionality Software)</li>
                                    <li>11.1.4.2 (Closed Software)</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508</p>
                                <ul>
                                    <li>501 (Web)(Software)</li>
                                    <li>504.2 (Authoring Tool)</li>
                                    <li>602.3 (Support Docs)</li>
                                </ul>
                            </td>
                            <td>
                                <p><strong>Not Applicable</strong></p>
                            </td>
                            <td>
                                <p>There is no such audio available in the application that plays automatically as soon as the page is launched.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="http://www.w3.org/TR/WCAG20/#keyboard-operation-keyboard-operable"><strong>2.1.1 Keyboard</strong></a> (Level A)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.2.1.1 (Web)</li>
                                    <li>10.2.1.1 (Non-web document)</li>
                                    <li>11.2.1.1.1 (Open Functionality Software)</li>
                                    <li>11.2.1.1.2 (Closed Software)</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508</p>
                                <ul>
                                    <li>501 (Web)(Software)</li>
                                    <li>504.2 (Authoring Tool)</li>
                                    <li>602.3 (Support Docs)</li>
                                </ul>
                            </td>
                            <td>
                                <p><strong>Supports with Minor Exceptions</strong></p>
                            </td>
                            <td>
                                <p>The application supports keyboard interaction, as almost all the interactive elements are accessible using the keyboard commands. It works similarly for both screen reader and keyboard-only users.</p>
                                <p><strong>Exception:</strong></p>
                                <p>1. Account icon is not accessible with tab/arrow keys.</p>
                                <p>Planned to be fixed by Q4 2021</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="http://www.w3.org/TR/WCAG20/#keyboard-operation-trapping"><strong>2.1.2 No Keyboard Trap</strong></a> (Level A)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.2.1.2 (Web)</li>
                                    <li>10.2.1.2 (Non-web document)</li>
                                    <li>11.2.1.2 (Open Functionality Software)</li>
                                    <li>11.2.1.2 (Closed Software)</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508</p>
                                <ul>
                                    <li>501 (Web)(Software)</li>
                                    <li>504.2 (Authoring Tool)</li>
                                    <li>602.3 (Support Docs)</li>
                                </ul>
                            </td>
                            <td>
                                <p><strong>Supports</strong></p>
                            </td>
                            <td>
                                <p>The application has a good support of keyboard interaction and there is no keyboard trap issue throughout the application.</p>
                                <br /></td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="https://www.w3.org/TR/WCAG21/#character-key-shortcuts"><strong>2.1.4 Character Key Shortcuts</strong></a> (Level A 2.1 only)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.2.1.4 (Web)</li>
                                    <li>10.2.1.4 (Non-web document)</li>
                                    <li>11.2.1.4.1 (Open Functionality Software)</li>
                                    <li>11.2.1.4.2 (Closed Software)</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508 &ndash; Does not apply</p>
                            </td>
                            <td>
                                <p><strong>Not Applicable</strong></p>
                            </td>
                            <td>
                                <p>There are no such character key shortcuts required in the application to operate any feature, which clashes with the predefined screen reader shortcuts.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="http://www.w3.org/TR/WCAG20/#time-limits-required-behaviors"><strong>2.2.1 Timing Adjustable</strong></a> (Level A)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.2.2.1 (Web)</li>
                                    <li>10.2.2.1 (Non-web document)</li>
                                    <li>11.2.2.1 (Open Functionality Software)</li>
                                      <li>11.2.2.1 (Closed Software)</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508</p>
                                <ul>
                                    <li>501 (Web)(Software)</li>
                                    <li>504.2 (Authoring Tool)</li>
                                    <li>602.3 (Support Docs)</li>
                                </ul>
                            </td>
                            <td>
                                <p><strong>Not Applicable</strong></p>
                                <br /></td>
                            <td><p>There is no session timeout functionality in the application.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="http://www.w3.org/TR/WCAG20/#time-limits-pause"><strong>2.2.2 Pause, Stop, Hide</strong></a> (Level A)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.2.2.2 (Web)</li>
                                    <li>10.2.2.2 (Non-web document)</li>
                                    <li>11.2.2.2 (Open Functionality Software)</li>
                                    <li>11.2.2.2 (Closed Software)</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508</p>
                                <ul>
                                    <li>501 (Web)(Software)</li>
                                    <li>504.2 (Authoring Tool)</li>
                                    <li>602.3 (Support Docs)</li>
                                </ul>
                            </td>
                            <td>
                                <p><strong>Not Applicable</strong></p>
                                <br /></td>
                            <td>
                                <p>There is no such functionality/activity available in the application that requires to either Pause, Stop or Hide the content.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="http://www.w3.org/TR/WCAG20/#seizure-does-not-violate"><strong>2.3.1 Three Flashes or Below Threshold</strong></a> (Level A)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.2.3.1 (Web)</li>
                                    <li>10.2.3.1 (Non-web document)</li>
                                    <li>11.2.3.1 (Open Functionality Software)</li>
                                    <li>11.2.3.1 (Closed Software)</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508</p>
                                <ul>
                                    <li>501 (Web)(Software)</li>
                                    <li>504.2 (Authoring Tool)</li>
                                    <li>602.3 (Support Docs)</li>
                                </ul>
                            </td>
                            <td>
                                <p><strong>Not Applicable</strong></p>
                                <br /></td>
                            <td>
                                <p>There is no flashing/blinking content in the application.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="http://www.w3.org/TR/WCAG20/#navigation-mechanisms-skip"><strong>2.4.1 Bypass Blocks</strong></a> (Level A)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.2.4.1 (Web)</li>
                                    <li>10.2.4.1 (Non-web document) &ndash; Does not apply</li>
                                    <li>11.2.4.1 (Open Functionality Software) &ndash; Does not apply</li>
                                    <li>11.2.4.1 (Closed Software) &ndash; Does not apply</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508</p>
                                <ul>
                                    <li>501 (Web)(Software) &ndash; Does not apply to non-web software</li>
                                    <li>504.2 (Authoring Tool)</li>
                                    <li>602.3 (Support Docs) &ndash; Does not apply to non-web docs</li>
                                </ul>
                            </td>
                            <td>
                                <p><strong>Supports</strong></p>
                                <br /></td>
                            <td>
                                <p>Skip to the main content link is present on the page.</p>
                                <br /></td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="http://www.w3.org/TR/WCAG20/#navigation-mechanisms-title"><strong>2.4.2 Page Titled</strong></a> (Level A)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.2.4.2 (Web)</li>
                                    <li>10.2.4.2 (Non-web document)</li>
                                    <li>11.2.4.2 (Open Functionality Software) - Does not apply</li>
                                    <li>11.2.4.2 (Closed Software) &ndash; Does not apply</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508</p>
                                <ul>
                                    <li>501 (Web)(Software)</li>
                                    <li>504.2 (Authoring Tool)</li>
                                    <li>602.3 (Support Docs)</li>
                                </ul>
                            </td>
                            <td>
                                <p><strong>Supports</strong></p>
                                <br /></td>
                            <td>
                                <p>Descriptive and unique page titles are provided for all pages of the application.</p>
                                <br /></td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="http://www.w3.org/TR/WCAG20/#navigation-mechanisms-focus-order"><strong>2.4.3 Focus Order</strong></a> (Level A)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.2.4.3 (Web)</li>
                                    <li>10.2.4.3 (Non-web document)</li>
                                    <li>11.2.4.3 (Open Functionality Software)</li>
                                    <li>11.2.4.3 (Closed Software)</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508</p>
                                <ul>
                                    <li>501 (Web)(Software)</li>
                                    <li>504.2 (Authoring Tool)</li>
                                    <li>602.3 (Support Docs)</li>
                                </ul>
                            </td>
                            <td>
                                <p><strong>Supports with Minor Exceptions</strong></p>
                                <br /></td>
                            <td><p>Although the focus order is moving in a correct sequence for the majority of the instances, there is one exception where focus order is incorrect.</p>
                                <p><strong>Exception:&nbsp;</strong></p>
                                <p>1. Screen reader identifies the element inside the notification panel without expanding it.</p>
                                <p>Planned to be fixed by Q2 2022</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="http://www.w3.org/TR/WCAG20/#navigation-mechanisms-refs"><strong>2.4.4 Link Purpose (In Context)</strong></a> (Level A)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.2.4.4 (Web)</li>
                                    <li>10.2.4.4 (Non-web document)</li>
                                    <li>11.2.4.4 (Open Functionality Software)</li>
                                    <li>11.2.4.4 (Closed Software</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508</p>
                                <ul>
                                    <li>501 (Web)(Software)</li>
                                    <li>504.2 (Authoring Tool)</li>
                                    <li>602.3 (Support Docs)</li>
                                </ul>
                            </td>
                            <td>
                                <p><strong>Supports</strong></p>
                                <br /></td>
                            <td>
                                <p>Appropriate link text is provided for all the links available in the application.&nbsp;</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="https://www.w3.org/TR/WCAG21/#pointer-gestures"><strong>2.5.1 Pointer Gestures</strong></a> (Level A 2.1 only)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.2.5.1 (Web)</li>
                                    <li>10.2.5.1 (Non-web document)</li>
                                    <li>11.2.5.1 (Open Functionality Software)</li>
                                    <li>11.2.5.1 (Closed Software)</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508 &ndash; Does not apply</p>
                            </td>
                            <td>
                                <p><strong>Supports</strong></p>
                                <br /></td>
                            <td>
                                <p>All <a target="_blank" href="https://www.w3.org/WAI/WCAG21/Understanding/pointer-gestures.html#dfn-functionality">functionality</a> that uses multipoint or path-based gestures can be operated with a <a target="_blank" href="https://www.w3.org/WAI/WCAG21/Understanding/pointer-gestures.html#dfn-single-pointer">single pointer</a> without a path-based gesture, unless a multipoint or path-based gesture is <a target="_blank" href="https://www.w3.org/WAI/WCAG21/Understanding/pointer-gestures.html#dfn-essential">essential</a>.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="https://www.w3.org/TR/WCAG21/#pointer-cancellation"><strong>2.5.2 Pointer Cancellation</strong></a> (Level A 2.1 only)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.2.5.2 (Web)</li>
                                    <li>10.2.5.2 (Non-web document)</li>
                                    <li>11.2.5.2 (Open Functionality Software)</li>
                                    <li>11.2.5.2 (Closed Software)</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508 &ndash; Does not apply</p>
                            </td>
                            <td>
                                <p><strong>Supports</strong></p></td>
                            <td>
                                <p>The application supports pointer cancellation as the down-event of the pointer is not used to execute any part of the function, elements are triggered only by using the up-event.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="https://www.w3.org/TR/WCAG21/#label-in-name"><strong>2.5.3 Label in Name</strong></a> (Level A 2.1 only)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.2.5.3 (Web)</li>
                                    <li>10.2.5.3 (Non-web document)</li>
                                    <li>11.2.5.3.1 (Open Functionality Software)</li>
                                    <li>11.2.5.3.2 (Closed Software)</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508 &ndash; Does not apply</p>
                            </td>
                            <td>
                                <p><strong>Supports</strong></p>
                                <br /></td>
                            <td>
                                <p>Visual labels match with the accessible or programmatic name for the elements.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="https://www.w3.org/TR/WCAG21/#motion-actuation"><strong>2.5.4 Motion Actuation</strong></a> (Level A 2.1 only)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.2.5.4 (Web)</li>
                                    <li>10.2.5.4 (Non-web document)</li>
                                    <li>11.2.5.4 (Open Functionality Software)</li>
                                    <li>11.2.5.4 (Closed Software)</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508 &ndash; Does not apply</p>
                            </td>
                            <td>
                                <p><strong>Not Applicable</strong></p>
                            </td>
                            <td>
                                <p>There is no functionality in the application that should be operated by device motion or user motion.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="http://www.w3.org/TR/WCAG20/#meaning-doc-lang-id"><strong>3.1.1 Language of Page</strong></a> (Level A)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.3.1.1 (Web)</li>
                                    <li>10.3.1.1 (Non-web document)</li>
                                    <li>11.3.1.1.1 (Open Functionality Software)</li>
                                    <li>11.3.1.1.2 (Closed Software)</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508</p>
                                <ul>
                                    <li>501 (Web)(Software)</li>
                                    <li>504.2 (Authoring Tool)</li>
                                    <li>602.3 (Support Docs)</li>
                                </ul>
                            </td>
                            <td>
                                <p><strong>Supports</strong></p>
                                <br /></td>
                            <td><p>Lang attribute &lsquo;En&rsquo; is provided for all the pages in the application.</p>
                                <br /></td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="http://www.w3.org/TR/WCAG20/#consistent-behavior-receive-focus"><strong>3.2.1 On Focus</strong></a> (Level A)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.3.2.1 (Web)</li>
                                    <li>10.3.2.1 (Non-web document)</li>
                                    <li>11.3.2.1 (Open Functionality Software)</li>
                                    <li>11.3.2.1 (Closed Software)</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508</p>
                                <ul>
                                    <li>501 (Web)(Software)</li>
                                    <li>504.2 (Authoring Tool)</li>
                                    <li>602.3 (Support Docs)</li>
                                </ul>
                            </td>
                            <td>
                                <p><strong>Supports</strong></p>
                                <br /></td>
                            <td><p>The application does not have any functionality that is triggered automatically on receiving the focus.</p>
                                <br /></td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="http://www.w3.org/TR/WCAG20/#consistent-behavior-unpredictable-change"><strong>3.2.2 On Input</strong></a> (Level A)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.3.2.2 (Web)</li>
                                    <li>10.3.2.2 (Non-web document)</li>
                                    <li>11.3.2.2 (Open Functionality Software)</li>
                                    <li>11.3.2.2 (Closed Software)</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508</p>
                                <ul>
                                    <li>501 (Web)(Software)</li>
                                    <li>504.2 (Authoring Tool)</li>
                                    <li>602.3 (Support Docs)</li>
                                </ul>
                            </td>
                            <td>
                                <p><strong>Supports</strong></p>
                                <br /></td>
                            <td><p>Change of the context does not happen anywhere in the application when changing the setting of a UI component.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="http://www.w3.org/TR/WCAG20/#minimize-error-identified"><strong>3.3.1 Error Identification</strong></a> (Level A)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.3.3.1 (Web)</li>
                                    <li>10.3.3.1 (Non-web document)</li>
                                    <li>11.3.3.1.1 (Open Functionality Software)</li>
                                    <li>11.3.3.1.2 (Closed Software)</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508</p>
                                <ul>
                                    <li>501 (Web)(Software)</li>
                                    <li>504.2 (Authoring Tool)</li>
                                    <li>602.3 (Support Docs)</li>
                                </ul>
                            </td>
                            <td>
                                <p><strong>Supports</strong></p>
                                <br /></td>
                            <td>
                                <p>The appropriate error/alert/success messages are provided in the application.&nbsp;</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="http://www.w3.org/TR/WCAG20/#minimize-error-cues"><strong>3.3.2 Labels or Instructions</strong></a> (Level A)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.3.3.2 (Web)</li>
                                    <li>10.3.3.2 (Non-web document)</li>
                                    <li>11.3.3.2 (Open Functionality Software)</li>
                                    <li>11.3.3.2 (Closed Software)</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508</p>
                                <ul>
                                    <li>501 (Web)(Software)</li>
                                    <li>504.2 (Authoring Tool)</li>
                                    <li>602.3 (Support Docs)</li>
                                </ul>
                            </td>
                            <td>
                                <p><strong>Supports</strong></p>
                            </td>
                            <td>
                                <p>All the form fields in the application have appropriate labels.&nbsp;</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="http://www.w3.org/TR/WCAG20/#ensure-compat-parses"><strong>4.1.1 Parsing</strong></a> (Level A)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.4.1.1 (Web)</li>
                                    <li>10.4.1.1 (Non-web document)</li>
                                    <li>11.4.1.1.1 (Open Functionality Software)</li>
                                    <li>11.4.1.1.2 (Closed Software) &ndash; Does not apply</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508</p>
                                <ul>
                                    <li>501 (Web)(Software)</li>
                                    <li>504.2 (Authoring Tool)</li>
                                    <li>602.3 (Support Docs)</li>
                                </ul>
                            </td>
                            <td>
                                <p><strong>Supports</strong></p>
                                <br /></td>
                            <td>
                                <p>The application does not contain multiple elements with the same id attribute value.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="http://www.w3.org/TR/WCAG20/#ensure-compat-rsv"><strong>4.1.2 Name, Role, Value</strong></a> (Level A)</p>
                                <p>Also applies to:</p>
                                <p>EN 301 549 Criteria</p>
                                <ul>
                                    <li>9.4.1.2 (Web)</li>
                                    <li>10.4.1.2 (Non-web document)</li>
                                    <li>11.4.1.2.1 (Open Functionality Software)</li>
                                    <li>11.4.1.2.2 (Closed Software) &ndash; Not required</li>
                                    <li>11.8.2 (Authoring Tool)</li>
                                    <li>12.1.2 (Product Docs)</li>
                                    <li>12.2.4 (Support Docs)</li>
                                </ul>
                                <p>Revised Section 508</p>
                                <ul>
                                    <li>501 (Web)(Software)</li>
                                    <li>504.2 (Authoring Tool)</li>
                                    <li>602.3 (Support Docs)</li>
                                </ul>
                            </td>
                            <td>
                                <p><strong>Supports</strong></p>
                                <br /></td>
                            <td>
                                <p>All the components in the application have proper name/label, role/state and value that help screen reader users to understand the actual purpose and nature of these components.</p>
                            </td>
                        </tr>
                    </tbody>
    );
};
