import {ActionCreator} from 'redux';
import {Dispatch, ThunkAction} from '../../../../../../../types/actions';
import {IGetState} from '../../../../../../../types/types';
import { boardNameIconClickedSegmentEvent } from '../../../effects/segmentEvents';
import { ESegmentClickType } from '../../../../../../../middlewares/segment/constants';

export const onClickIcon: ActionCreator<ThunkAction> = () => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(boardNameIconClickedSegmentEvent(ESegmentClickType.single));
    };
    return action;
};
