import { IABTest } from '../../types';

export const AB_TEST_FEEDBACK_ICON = 'AB_TEST_FEEDBACK_ICON';

export enum EABFeedbackIconTestValues {
    HEAR = 'hearing',
    ONLINE_SUPPORT = 'online-support',
    MUZHIK = 'muzhchino.png'
}

export const getFeedbackIconConfig = (): IABTest => {
    return {
        name: AB_TEST_FEEDBACK_ICON,
        defaultValue: EABFeedbackIconTestValues.HEAR,
        size: 60000,
        groups: [
            {
                weight: 100,
                value: EABFeedbackIconTestValues.HEAR
            },
            {
                weight: 100,
                value: EABFeedbackIconTestValues.MUZHIK
            },
            {
                weight: 100,
                value: EABFeedbackIconTestValues.ONLINE_SUPPORT
            }
        ],
        isRelevant: () => true,
        executeOnLoad: true
    }
};
