import { IGetState, TCardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { ICards } from '../../../../store/model/cards/types';
import { cardsRestPatch } from './api/helper/cardsRestPatch';
import { TStatus } from '../../../../types/model';
import { cardStatusSendStatistic } from '../../../stat_helper/cardStatusSendStatistic';
import { CardsLoader } from '../../../../helper/loader/cardsLoader';
import { IRestCard } from '../../../../types/rest/IRestCard';
import { getBoardByCardId } from '../../../../store/model/selectors/getBoardByCardId';
import { sendRealTimeRestCard } from '../../../../view/react_components/base/helpers/realTimeHelperTS';

export const cardsSetStatus = (
    cardIds: TCardId[],
    status: TStatus
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const cards: ICards = cardIds.reduce((result: ICards, id) => {
            result[id] = {
                status: status
            }
            return result;
        }, {});

        return dispatch(cardsRestPatch(cards, undefined, false))
            .then(() => {
                const board = getBoardByCardId(getState(), cardIds[0]);
                new CardsLoader(cardIds).load()
                    .then((cardsFromResponses: Array<IRestCard>) => {
                        cardsFromResponses.forEach(card => {
                            sendRealTimeRestCard(board.id, board.cometToken, card);
                        })
                    })
                dispatch(cardStatusSendStatistic(cardIds, status));
            });
    };
    return action;
};
