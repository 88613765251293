import * as React from 'react';
import './_UpdatesTips.scss';
import { IUpdatesTipsProps } from './types';
import ReactHtmlParser from 'react-html-parser';
import { UPDATES_TIPS_OF } from './constants';
import { getParentsClasses } from 'app/view/react_components/helpers/getParentsClasses';

export function UpdatesTips ({
    isFirstShow,
    slides,
    onClose,
}: IUpdatesTipsProps) {
    const className = 'updates-tips';
    const classNameHighlight = 'highlight-updates-tips';

    const [index, setIndex] = React.useState(0);
    const [isHide, setHide] = React.useState(true);
    const [isBlur, setBlur] = React.useState(false);
    const divRef = React.useRef(null);
    const isSeen = React.useRef(!isFirstShow);

    const isFocus = React.useRef(null);
    const onFocus = () => {
        setTimeout(() => {
            divRef.current.focus();
            isFocus.current = true;
        }, 100); // вернуть фокус после закрытия панели Updates
    }

    const onBlur = (e: React.FocusEvent) => {
        if (!isFocus.current) return;
        if (document.activeElement && document.activeElement === divRef.current) return; // фокус ушёл из окна

        const target = e.relatedTarget as HTMLElement;
        const targetClasses = getParentsClasses(target, [classNameHighlight]);
        if (targetClasses.includes(classNameHighlight)) {
            isFocus.current = false;
            return;
        }

        if (isSeen.current) { // закрыть без анимации (просмотрено)
            onClose();
        } else { // закрыть с анимацией (не всё просмотрено)
            setBlur(true);
            setTimeout(() => {
                onClose();
            }, 1000);
        }
    }

    const onNext = () => {
        setHide(true);
        let i = index + 1;
        if (i >= slides.length) i = 0;
        if (isFirstShow && i === slides.length - 1) {
            isSeen.current = true;
        }
        setTimeout(() => {
            setIndex(i);
            setHide(false);
        }, 300); // animation length
    }

    React.useEffect(() => {
        const slide = slides[index];
        if (slide && slide.highlightSelector) {
            setTimeout(() => {
                const highlight = document.querySelector(slide.highlightSelector);
                if (highlight) highlight.classList.add(classNameHighlight);
            }, 500); // подсветка после анимации слайда
        }

        if (slide && slide.onOpen) slide.onOpen();

        return () => {
            const highlight = document.querySelector(`.${classNameHighlight}`);
            if (highlight) highlight.classList.remove(classNameHighlight);

            if (slide && slide.onClose) slide.onClose();
        }
    }, [index]);

    React.useEffect(() => {
        setHide(false);

        if (!isFirstShow && divRef.current) {
            divRef.current.focus();
        }

        return () => {
            const slide = slides && slides[0];
            if (slide && slide.onClose) slide.onClose();
        }
    }, []);

    return (
        <div
            className={`${className} ${isBlur ? className + '--blur' : ''}`}
            tabIndex={0}
            ref={divRef}
            onBlur={onBlur}
            onFocus={onFocus}
        >
            <div
                className={`${className}__inner`}
                onClick={onNext}
            >
                <div
                    className={`${className}__tip ${isHide ? 'hide' : ''}`}
                >
                    {!!slides[index].title &&
                        <div className={`${className}__title`}>
                            {ReactHtmlParser(slides[index].title)}
                        </div>
                    }
                    {!!slides[index].text &&
                        <div className={`${className}__text`}>
                            {ReactHtmlParser(slides[index].text)}
                        </div>
                    }
                    <div className={`${className}__nav`}>
                        {index + 1} {UPDATES_TIPS_OF} {slides.length}
                    </div>
                </div>
            </div>
        </div>
    );
};
