import { errorHandler, graphClient } from 'app/helper/authorisation/microsoft/graph/Graph';
import { DriveItem } from '@microsoft/microsoft-graph-types';

export const getFile = async (
    driveId: string,
    fileId: string
) => {
    try {
        let request = graphClient.api(`/drives/${driveId}/items/${fileId}`);
        let response = await request.get() as DriveItem;
        return response;
    } catch (e) {
        return errorHandler(e);
    }
}
