import * as React from 'react';
import './_BoardProgressLoader.scss';
import { IBoardProgressLoaderProps } from './types';
import {
    BOARD_PROGRESS_LOADER_BOARD,
    BOARD_PROGRESS_LOADER_LOADED,
    BOARD_PROGRESS_LOADER_OF,
    BOARD_PROGRESS_LOADER_QUICK_START_PROGRESS_TIMEOUT, getBoardProgressLoaderQuickStartProgressText
} from './constants';
import { root } from '../../../../../store/constants';

export function BoardProgressLoader({
    platformType
}: IBoardProgressLoaderProps) {
    const className = 'board-progress-loader';
    const classProgress = className + '__progress';

    const [isShow, setShow] = React.useState(false);
    const [isFirst, setFirst] = React.useState(false);
    const [boardName, setBoardName] = React.useState(BOARD_PROGRESS_LOADER_BOARD);
    const [cardsToLoadCount, setCardsToLoadCount] = React.useState(0);
    const [loadedCardsCount, setLoadedCardsCount] = React.useState(0);
    const [loadedCardsAnimationCount, setLoadedCardsAnimationCount] = React.useState(0);
    const [isFirstBoard, setFirstBoard] = React.useState(null);
    const [s] = React.useState<any>({});
    s.loadedCardsCount = loadedCardsCount; // сколько реально загружено - меняется порциями
    s.loadedCardsAnimationCount = loadedCardsAnimationCount; // для анимации - меняется плавнее по 1

    const nameTimer = React.useRef(null);
    const cardsToLoadTimer = React.useRef(null);
    const loadedCardsTimer = React.useRef(null);
    const loadedCardsAnimationTimer = React.useRef(null);

    React.useEffect(() => {
        setLoadedCardsCount(0);
        setLoadedCardsAnimationCount(0);

        if (!cardsToLoadCount) return;

        if (loadedCardsTimer.current) clearInterval(loadedCardsTimer.current);
        loadedCardsTimer.current = setInterval(() => {
            const count = root.App.controller.boardLoaderInfo.loadedCardsCount;
            if (count !== undefined && count !== null && count !== s.loadedCardsCount) {
                if (s.loadedCardsAnimationCount < s.loadedCardsCount) {
                    setLoadedCardsAnimationCount(s.loadedCardsCount);
                }
                setLoadedCardsCount(count);

                if (loadedCardsAnimationTimer.current) clearInterval(loadedCardsAnimationTimer.current);
                if (count >= cardsToLoadCount) {
                    clearInterval(loadedCardsTimer.current);
                    setLoadedCardsAnimationCount(cardsToLoadCount);
                } else {
                    loadedCardsAnimationTimer.current = setInterval(() => {
                        if (s.loadedCardsAnimationCount >= s.loadedCardsCount) {
                            if (loadedCardsAnimationTimer.current) clearInterval(loadedCardsAnimationTimer.current);
                        } else {
                            setLoadedCardsAnimationCount(++s.loadedCardsAnimationCount);
                        }
                    }, 100);
                }
            }
        }, 50);
    }, [cardsToLoadCount]);

    React.useEffect(() => {
        function clearIntervals () {
            clearInterval(nameTimer.current);
            clearInterval(cardsToLoadTimer.current);
            clearInterval(loadedCardsTimer.current);
            clearInterval(loadedCardsAnimationTimer.current);
        }

        const observer = new MutationObserver(mutations => {
            const mainLoader = document.querySelector('.loader--main');
            if (mainLoader) {
                if (!mainLoader.classList.contains('loader--progress') || // loader without progress
                    mainLoader.classList.contains('hidden') // loader hidden
                ) {
                    setShow(false);
                    clearIntervals();
                } else { // loader progress shown
                    setBoardName(BOARD_PROGRESS_LOADER_BOARD);
                    setCardsToLoadCount(0);
                    setFirst(root.App.controller.boardLoaderInfo.isFirstLoad);
                    setShow(true);

                    if (nameTimer.current) clearInterval(nameTimer.current);
                    nameTimer.current = setInterval(() => {
                        const boardName = root.App.controller.boardLoaderInfo.boardName;
                        if (boardName !== undefined && boardName !== null) {
                            setBoardName(boardName);
                            setFirstBoard(root.App.controller.boardLoaderInfo.isFirstBoard);
                            clearInterval(nameTimer.current);
                        }
                    }, 50);

                    if (cardsToLoadTimer.current) clearInterval(cardsToLoadTimer.current);
                    cardsToLoadTimer.current = setInterval(() => {
                        const count = root.App.controller.boardLoaderInfo.cardsToLoadCount;
                        if (count !== undefined && count !== null) {
                            setCardsToLoadCount(count);
                            clearInterval(cardsToLoadTimer.current);
                        }
                    }, 50);
                }
            }
        });
        const mainLoader = document.querySelector('.loader--main');
        if (mainLoader) {
            observer.observe(mainLoader, { attributes: true });
        }

        return () => {
            clearIntervals();
        }
    }, []);

    const [quickStartProgress, setQuickStartProgress] = React.useState(
        [getBoardProgressLoaderQuickStartProgressText(platformType)[0]]
    );
    const timerRef = React.useRef(null);
    React.useEffect(() => {
        if (!isFirstBoard) return;
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(() => {
            const newEl = getBoardProgressLoaderQuickStartProgressText(platformType)[quickStartProgress.length];
            if (!newEl) return;
            setQuickStartProgress([...quickStartProgress, newEl]);
        }, BOARD_PROGRESS_LOADER_QUICK_START_PROGRESS_TIMEOUT);
        root.App.controller.mainView.doAfterLoader(() => {
            root.App.controller.boardLoaderInfo.isFirstBoard = false;
            setFirstBoard(false);
        });
    }, [quickStartProgress, isFirstBoard]);

    return isShow && (
        <div
            className={`
                ${className}
                ${className + '--' + (isFirst ? 'dark' : 'light')}
            `}
        >
            <div className={className + '__logo'}/>
            <div className={classProgress}>
                <div
                    className={classProgress + '-inner'}
                    style={isFirstBoard ? {} : { width: `${cardsToLoadCount ? loadedCardsAnimationCount / cardsToLoadCount * 100 : 0}%` }}
                />
            </div>
            <div className={className + '__text'}>
                <div className={className + '__board'}>
                    {boardName}
                </div>
                {isFirstBoard &&
                    <div className={className + '__quick-start'}>
                        {quickStartProgress.map(item => (
                            <span key={item} className={className + '__quick-start-item'}>{item}</span>
                        ))}
                    </div>
                }
                {!isFirstBoard && !!cardsToLoadCount &&
                    <div>
                        {/* KNB-3112 cardsToLoadCount может быть меньше загружаемых карточек */}
                        {loadedCardsCount > cardsToLoadCount ? cardsToLoadCount : loadedCardsAnimationCount}
                        {BOARD_PROGRESS_LOADER_OF}
                        {cardsToLoadCount}
                        {BOARD_PROGRESS_LOADER_LOADED}
                    </div>
                }
            </div>
        </div>
    );
}
