'use strict';

App.Views.CardAddAttachmentsNotification = App.Views.BaseNotification.extend({

    getActions: function() {
        var action = {
            action: Messages.getText('noty.card_attachments'),
            actionData: {
                icon: 'attach'
            },
            badge: {},
            body: '',
        };
        if (this.notificationModel.driveDocs && this.notificationModel.driveDocs[0]) {
            if (this.notificationModel.driveDocs.length > 1) {
                action.badge.text = this.notificationModel.driveDocs.length + ' ' + Messages.getText('noty.files');
                action.badge.icon = 'attach';
            } else {
                action.action = Messages.getText('noty.card_attachment');
                action.badge = this.notificationModel.driveDocs[0];
                action.badge.text = action.badge.title;
            }
        }
        return action;
    },

    onClick: function(e, notification) {
        var dashboardId = this.notificationModel.dashboardId;
        var cardId = this.notificationModel.card.id;
        App.controller.cleverOpenCard(dashboardId, cardId);
        this.closeNotification(notification);
        e.stopPropagation();
    }
});
