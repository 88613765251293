import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IApplicationState, IGetState, TCardId } from '../../../../types/types';
import { getCard } from '../../../../store/model/selectors/getCard';
import { ICards } from '../../../../store/model/cards/types';
import { cardsRestPatch } from './api/helper/cardsRestPatch';
import { ORDER_STEP } from '../../../../const';
import { TCardOrderNumberField } from '../../../../store/model/card/types';
import { getCardOrderNumberByField } from '../../../../store/model/helpers/getCardOrderNumberByField';
import { isObjectEmpty } from '../../../../view/react_components/helpers/isObjectEmpty';

export const cardUpdateOrderField = (
    cardId: TCardId,
    sortedCardIds: TCardId[],
    field: TCardOrderNumberField,
    asc: boolean = true
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const card = getCard(state, cardId);
        if (!card) return Promise.reject();

        const index = sortedCardIds.indexOf(cardId);
        if (index < 0) return Promise.resolve();

        let orderNumber = 0;
        const cards: ICards = {};

        const prevCard = getCard(state, sortedCardIds[index - 1]);
        const nextCard = getCard(state, sortedCardIds[index + 1]);

        if (prevCard && nextCard) {
            const prevOrderNumber = getCardOrderNumberByField(prevCard, field);
            const nextOrderNumber = getCardOrderNumberByField(nextCard, field);
            orderNumber = (prevOrderNumber + nextOrderNumber) / 2;
            if (orderNumber === prevOrderNumber) { // поставили карту между двумя с одинакоым orderNumber
                updateCardsOrderNumber(state, cards, sortedCardIds, field, index, orderNumber, asc);
            }
        } else if (prevCard) {
            orderNumber = getCardOrderNumberByField(prevCard, field) + (asc ? ORDER_STEP : -ORDER_STEP);
        } else if (nextCard) {
            orderNumber = getCardOrderNumberByField(nextCard, field) + (asc ? -ORDER_STEP : ORDER_STEP);
        }

        if (card[field] !== orderNumber && !cards[cardId]) {
            cards[cardId] = {
                [field]: orderNumber
            }
        }
        if (isObjectEmpty(cards)) return Promise.resolve();
        return dispatch(cardsRestPatch(cards));
    };
    return action;
};

const updateCardsOrderNumber = (
    state: IApplicationState,
    cards: ICards,
    sortedCardIds: TCardId[],
    field: TCardOrderNumberField,
    index: number,
    currentOrderNumber: number,
    asc: boolean
) => {
    let nextOrderNumber: number = null, lastIndex: number = null;
    for (let i = index + 2; i < sortedCardIds.length; i++) { // index + 2 - следующая карта, после nextCard
        const orderNumber = getCardOrderNumberByField(getCard(state, sortedCardIds[i]), field);
        if (orderNumber !== currentOrderNumber) {
            nextOrderNumber = orderNumber;
            lastIndex = i;
            break;
        }
    }
    if (nextOrderNumber === null) {
        nextOrderNumber = getCardOrderNumberByField(getCard(state, sortedCardIds[sortedCardIds.length - 1]), field) + (asc ? ORDER_STEP : -ORDER_STEP); // берем послеюнюю карту и добавляем ORDER_STEP
        lastIndex = sortedCardIds.length;
    }
    const step = (nextOrderNumber - currentOrderNumber) / (lastIndex - index + 1);
    for (let cardIndex = index, i = 1; cardIndex < lastIndex; cardIndex++, i++) {
        cards[sortedCardIds[cardIndex]] = {
            [field]: currentOrderNumber + step * i
        };
    }
}
