import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { openBoardSettings } from '../../../../../../base/effects/openBoardSettings';
import { scrollPanelToSection } from '../../../../../asidePanel/helpers/scrollPanelToSection';
import { CLASS_BOARD_DETAILS_SECTION } from '../../../../../boardDetails/constants';
import { SegmentBoardSettingsSourceValue } from '../../../../../../../../middlewares/segment/trackEntities/boardEvents';

export const onOpenBoardSettings = (): ThunkAction => {
    return (dispatch: Dispatch) => {
        dispatch(openBoardSettings('notifications', SegmentBoardSettingsSourceValue.CARD_WATCH_SETTINGS));
        //wait until board settings are opened
        setTimeout(() => {
            scrollPanelToSection(
                `.${CLASS_BOARD_DETAILS_SECTION}--notifications`,
                null,
                {current: null});
        }, 0);
    };
};
