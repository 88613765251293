import { connect } from 'react-redux';
import { IDriveDocEvents } from '../../components/DriveDoc/types';
import { DriveDoc } from '../../components/DriveDoc/DriveDoc';
import { onDownload } from './events/onDownload';
import { onLinkClick } from './events/onLinkClick';

const mapDispatchToProps = (
    dispatch: any
): IDriveDocEvents => {
    return {
        onDownload: (driveId, fileId) => dispatch(onDownload(driveId, fileId)),
        onLinkClick: (event, driveDocId) => dispatch(onLinkClick(event, driveDocId)),
    };
};

export const DriveDocHOC = connect(
    null,
    mapDispatchToProps
)(DriveDoc);

DriveDocHOC.displayName = 'DriveDocHOC';
