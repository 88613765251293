import * as React from 'react';
import { ICardAgingProps } from './types';
import './_CardAging.scss';
import {
    BOARD_DETAILS_SECTION_CARD_AGING_PROPERTIES_TEXT_AFTER,
    BOARD_DETAILS_SECTION_CARD_AGING_PROPERTIES_TEXT_BEFORE,
    CLASS_CARD_AGING,
    DETAILS_SECTION_CARD_AGING_PROPERTIES_INDICATOR,
    DETAILS_SECTION_CARD_AGING_PROPERTIES_INDICATOR_ACTIVITY,
    DETAILS_SECTION_CARD_AGING_PROPERTIES_INDICATOR_LIST,
    DETAILS_SECTION_CARD_AGING_PROPERTIES_OPACITY,
    LIST_DETAILS_SECTION_CARD_AGING_PROPERTIES_TEXT_AFTER,
    LIST_DETAILS_SECTION_CARD_AGING_PROPERTIES_TEXT_BEFORE,
    LIST_DETAILS_SECTION_CARD_AGING_PROPERTIES_TEXT_LINK,
    LIST_DETAILS_SECTION_CARD_AGING_PROPERTIES_TEXT_MAIN
} from '../../constants';
import { Radio, Switch } from 'kui';
import { ECardAgingIndicatorType } from '../../../../../../../../types/rest/ICardAging';

export function CardAging ({
    isEnabled,
    isDisabled,
    isOpacity,
    isIndicator,
    indicatorType,
    isListDetails,
    onOpacityChange,
    onIndicatorChange,
    onIndicatorTypeChange,
    onOpenBoardSettings
}: ICardAgingProps) {
    const classDescription = CLASS_CARD_AGING + '__description';
    const classSwitch = CLASS_CARD_AGING + '__switch';

    return (
        <div className={CLASS_CARD_AGING}>
            <span className={classDescription}>
                {isListDetails ? LIST_DETAILS_SECTION_CARD_AGING_PROPERTIES_TEXT_MAIN : BOARD_DETAILS_SECTION_CARD_AGING_PROPERTIES_TEXT_BEFORE}
                {isEnabled && (isListDetails ?
                    <>
                        {' ' + LIST_DETAILS_SECTION_CARD_AGING_PROPERTIES_TEXT_BEFORE}
                        <a onClick={onOpenBoardSettings}>
                            {LIST_DETAILS_SECTION_CARD_AGING_PROPERTIES_TEXT_LINK}
                        </a>
                        {LIST_DETAILS_SECTION_CARD_AGING_PROPERTIES_TEXT_AFTER}
                    </>
                    : BOARD_DETAILS_SECTION_CARD_AGING_PROPERTIES_TEXT_AFTER)
                }
            </span>
            {isEnabled &&
                <>
                    <span className={classSwitch}>
                        {DETAILS_SECTION_CARD_AGING_PROPERTIES_OPACITY}
                        <Switch
                            checked={isOpacity}
                            disabled={isDisabled}
                            onChange={onOpacityChange}
                        />
                    </span>
                    <span className={classSwitch}>
                        {DETAILS_SECTION_CARD_AGING_PROPERTIES_INDICATOR}
                        <Switch
                            checked={isIndicator}
                            disabled={isDisabled}
                            onChange={onIndicatorChange}
                        />
                    </span>
                    {isIndicator &&
                        <Radio
                            onChange={(e) => {
                                onIndicatorTypeChange(
                                    e.index === 0 ?
                                        ECardAgingIndicatorType.BY_ACTIVITY
                                        : ECardAgingIndicatorType.BY_LIST
                                );
                            }}
                            active={indicatorType === ECardAgingIndicatorType.BY_LIST ? 1 : 0}
                            direction={'right'}
                        >
                            <span>{DETAILS_SECTION_CARD_AGING_PROPERTIES_INDICATOR_ACTIVITY}</span>
                            <span>{DETAILS_SECTION_CARD_AGING_PROPERTIES_INDICATOR_LIST}</span>
                        </Radio>
                    }
                </>
            }
        </div>
    );
};
