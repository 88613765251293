import { IApplicationState, TCardId } from '../../../types/types';
import { IRestRecurringTask } from '../../../types/rest/recurringTask/IRestRecurringTask';
import { getCard } from './getCard';

export const getCardRecurringTasks = (
    state: IApplicationState,
    cardId: TCardId
): IRestRecurringTask[] => {
    const card = getCard(state, cardId);
    if (!card) return [];
    return card.recurringTasks || [];
}
