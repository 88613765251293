import { connect } from 'react-redux';
import { IApplicationState } from 'app/types/types';
import { ICardDatesHOCProps } from './types';
import { getCard } from '../../../../../../../store/model/selectors/getCard';
import { ICardDatesEvents, ICardDatesFields } from '../../components/CardDatesSection/types';
import { CardDatesSection } from '../../components/CardDatesSection/CardDatesSection';
import { getSectionType } from '../../../selectors/getSectionType';
import {
    getBoardIsCardDefaultProperty
} from '../../../../../../../store/model/board/selectors/getBoardIsCardDefaultProperty';
import { EAsidePanelProperty } from '../../../../asidePanel/components/AsidePanel/types';
import { onChange } from './events/onChange';
import {
    getBoardMetaDefaultDueDateTime
} from '../../../../../../../store/model/selectors/getBoardMetaDefaultDueDateTime';
import {
    getBoardMetaDefaultStartDateTime
} from '../../../../../../../store/model/selectors/getBoardMetaDefaultStartDateTime';
import { onTimeSelected } from './events/onTimeSelected';
import { onDateSelected } from './events/onDateSelected';
import { onUnevenTimeSelected } from './events/onUnevenTimeSelected';
import { onDateClear } from './events/onDateClear';
import { onDateOpened } from './events/onDateOpened';
import { onTimeOpened } from './events/onTimeOpened';
import {
    getBoardIsCardRequiredProperty
} from '../../../../../../../store/model/board/selectors/getBoardIsCardRequiredProperty';
import { getCardIsEpic } from '../../../../../../../store/model/selectors/getCardIsEpic';
import { getCardIsOverdue } from '../../../../../../../store/model/selectors/getCardIsOverdue';
import { onAutoStartDateHide } from './events/onAutoStartDateHide';
import {
    SegmentBoardAutoStartSourceValue,
    SegmentBoardSettingsSourceValue
} from '../../../../../../../middlewares/segment/trackEntities/boardEvents';
import {
    onAutoStartDateToggle
} from '../../../../boardDetails/details/Dates/hocs/DatesHOC/events/onAutoStartDateToggle';
import { openBoardSettings } from '../../../../../base/effects/openBoardSettings';
import { getAuthUserSupport } from '../../../../../../../store/model/authUser/selectors/getAuthUserSupport';
import { getActiveHint } from '../../../../../hints/store/hints/selectors/getActiveHint';
import { EHintKey } from '../../../../../hints';
import { isEqual } from 'underscore';
import { getCurrentRoute } from '../../../../../../../store/router/selectors/getCurrentRoute';
import { ERoutes } from '../../../../../../../store/router/constants';

const mapStateToProps = () => {
    interface ICaheProps extends ICardDatesFields{};
    let cache: ICaheProps = null;
    const getCache = (
        value: ICaheProps
    ): ICaheProps => {
        if (isEqual(value, cache)) return cache;
        cache = value; return value;
    };

    return (
        state: IApplicationState,
        ownProps: ICardDatesHOCProps
    ): ICardDatesFields => {
        const { boardId, cardId, sectionType: sectionTypeOwn } = ownProps;
        const sectionType = getSectionType(state, boardId, cardId, sectionTypeOwn);
        const { dueDate, startDate, isMilestone, recurringTasks } = getCard(state, cardId);

        const activeHint = getActiveHint(state);

        return getCache({
            boardId,
            cardId,
            dateDue: dueDate && new Date(dueDate * 1000),
            dateStart: startDate && new Date(startDate * 1000),
            defaultDueTime: getBoardMetaDefaultDueDateTime(state, boardId),
            defaultStartTime: getBoardMetaDefaultStartDateTime(state, boardId),
            sectionType,
            isDefaultProperty: getBoardIsCardDefaultProperty(state, boardId, EAsidePanelProperty.CARD_START_DATE),
            isOverdue: getCardIsOverdue(state, cardId),
            isMilestone,
            isRecurring: recurringTasks && !!recurringTasks.length,
            isDisabled: getCardIsEpic(state, cardId),
            isDueRequired: getBoardIsCardRequiredProperty(state, boardId, EAsidePanelProperty.CARD_DUE_DATE),
            isShowHint: getCurrentRoute(state) === ERoutes.EDIT_CARD && activeHint && activeHint.key === EHintKey.GANTT_CONTEXT_ADD_DATE,
            isStartRequired: getBoardIsCardRequiredProperty(state, boardId, EAsidePanelProperty.CARD_START_DATE),
            isSupportCalendarPush: getAuthUserSupport(state,'supportCalendarPush'),
        });
    };
};

const mapDispatchToProps = (
    dispatch: any,
    ownProps: ICardDatesHOCProps
): ICardDatesEvents => {
    const { cardId, boardId } = ownProps;
    return {
        onAutoStartDateHide: () => dispatch(onAutoStartDateHide()),
        onAutoStartDateToggle: () => dispatch(onAutoStartDateToggle(boardId, SegmentBoardAutoStartSourceValue.HINT)),
        onChange: (date, dateType) => dispatch(onChange(cardId, date, dateType)),
        onTimeSelected: (dateType) => dispatch(onTimeSelected(dateType)),
        onDateSelected: (dateType) => dispatch(onDateSelected(dateType)),
        onUnevenTimeSelected: (dateType) => dispatch(onUnevenTimeSelected(dateType)),
        onDateClear: (dateType) => dispatch(onDateClear(dateType)),
        onDateOpened: (dateType) => dispatch(onDateOpened(dateType)),
        onOpenSettings: () => dispatch(openBoardSettings('dates', SegmentBoardSettingsSourceValue.CARD_DATES_DATEPICKER_HINT)),
        onTimeOpened: (dateType) => dispatch(onTimeOpened(dateType))
    }
};

export const CardDatesSectionHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardDatesSection);

CardDatesSectionHOC.displayName = 'CardDatesSectionHOC';
