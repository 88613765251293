import * as React from 'react';
import { IAsidePanelCloseProps } from './types';
import './_AsidePanelClose.scss';
import { Button, Icon } from 'kui';
import { CLASS_ASIDE_PANEL_CLOSE, LINK_CARD_BUTTON_TEXT } from './constants';
import { SegmentCardDetailsCloseSourceValue } from '../../../../../../middlewares/segment/trackEntities/cardEvents';

export function AsidePanelClose ({
    isArchive,
    isCardLink,
    isForceClosed,
    onClose
}: IAsidePanelCloseProps) {
    React.useEffect(() => {
        if (isForceClosed) {
            onClose(SegmentCardDetailsCloseSourceValue.CARD_DELETED);
        }
    }, [isForceClosed]);
    return (
        <>
            <Button
                className={`
                    ${CLASS_ASIDE_PANEL_CLOSE}
                    ${isCardLink ? CLASS_ASIDE_PANEL_CLOSE + '--link' : ''}
                    ${isArchive ? CLASS_ASIDE_PANEL_CLOSE + '--archive' : ''}
                `}
                variant={isCardLink ? 'primary' : 'icon'}
                aria-label={'Close'}
                onClick={() => onClose(SegmentCardDetailsCloseSourceValue.CLOSE_BUTTON)}
            >
                {isCardLink ?
                    <>
                        <Icon xlink={'board'} size={24} />
                        <div className={CLASS_ASIDE_PANEL_CLOSE + '-text'}>{LINK_CARD_BUTTON_TEXT}</div>
                    </>
                    : <Icon
                        size={24}
                        xlink="close"
                    />
                }
            </Button>
        </>
    );
}
