import { ORDER_STEP } from '../../../const';
import { ICard } from '../card/types';
import * as _ from 'underscore';

export const getOrderNumberByCards = (
    cards: ICard[],
    first: boolean = true,
): number => {
    if (!cards.length) return 0;

    const compareFunction = first ? _.min : _.max;
    const targetCard = compareFunction(cards, (card: ICard) => card.orderNumber) as ICard;

    return first ? targetCard.orderNumber - ORDER_STEP : targetCard.orderNumber + ORDER_STEP;
};
