import { IApplicationState } from '../../../../../../../types/types';
import { TTabKey } from '../../types';
import { TTabLoadStatus } from '../types';
import { INITIAL_LOAD_STATUS } from '../../constants';

export const getLoadStatus = (
    state: IApplicationState,
    tabKey: TTabKey
): TTabLoadStatus => {
    return state.openBoardsDialog && state.openBoardsDialog.common && state.openBoardsDialog.common.loadStatus && state.openBoardsDialog.common.loadStatus[tabKey] ?
        state.openBoardsDialog.common.loadStatus[tabKey] :
        INITIAL_LOAD_STATUS;
}
