import { IApplicationState, TCardId } from '../../../../../../../../types/types';
import { ISelectOptionsArray } from 'kui';
import {
    getCardChecklistsSorted
} from '../../../../../../../../store/model/checklists/checklists/selectors/getCardChecklistsSorted';

export const getAvailableChecklists = (
    state: IApplicationState,
    cardId: TCardId
): ISelectOptionsArray => {
    const checklists = getCardChecklistsSorted(state, cardId);

    return checklists.map(checklist => {
        return ({
            value: checklist.id,
            text: checklist.name
        });
    });
};
