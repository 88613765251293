import { IAsidePanelState } from '../types';
import { TAsidePanelAction } from '../actions/types';
import {
    AT_BUTTONS_PANEL_TYPE_SET,
    AT_ENTITY_PANEL_WIDTH_SET,
    AT_IS_MOBILE_SET,
    AT_IS_PRINT_SET,
    AT_LEFT_PANEL_OPENED_SET,
    AT_TAB_KEY_SET,
    AT_WIDTH_SET,
    AT_ACTIVITYID_SET,
    AT_IS_GOOGLE_FILE_CREATING, AT_GANTT_CONTEXT_HINTS_CARD_ID_SET,
} from '../actions/constants';
import { setAsidePanelLocalStorage } from '../localstorage/actions/setAsidePanelLocalStorage';
import { getAsidePanelInitialState } from '../constants';

const initialState = getAsidePanelInitialState();

const _asidePanelReducer = (
    state: IAsidePanelState = initialState,
    action: TAsidePanelAction
): IAsidePanelState => {
    switch (action.type) {
        case AT_BUTTONS_PANEL_TYPE_SET: {
            return {
                ...state,
                buttonsPanelType: action.buttonsPanelType
            }
        }

        case AT_ENTITY_PANEL_WIDTH_SET: {
            return {
                ...state,
                entityPanelWidth: action.entityPanelWidth
            }
        }

        case AT_IS_MOBILE_SET: {
            return {
                ...state,
                isMobile: action.isMobile
            }
        }

        case AT_IS_PRINT_SET: {
            return {
                ...state,
                isPrint: action.isPrint
            }
        }

        case AT_TAB_KEY_SET: {
            return {
                ...state,
                tabKey: action.tabKey
            }
        }

        case AT_LEFT_PANEL_OPENED_SET: {
            return {
                ...state,
                leftPanelOpened: action.opened
            }
        }

        case AT_WIDTH_SET: {
            return {
                ...state,
                width: action.width
            }
        }

        case AT_ACTIVITYID_SET: {
            return {
                ...state,
                activityId: action.activityId
            }
        }

        case AT_IS_GOOGLE_FILE_CREATING: {
            return {
                ...state,
                isGoogleFileCreating : action.isGoogleFileCreating
            }
        }

        case AT_GANTT_CONTEXT_HINTS_CARD_ID_SET: {
            return {
                ...state,
                ganttContextCardId: action.cardId,
            }
        }

        default: {
            return state;
        }
    }
};

export const asidePanelReducer = (
    state: IAsidePanelState = initialState,
    action: TAsidePanelAction
): IAsidePanelState => {
    const newState = _asidePanelReducer(state, action);

    const stateForLocalStorage: IAsidePanelState = {
        ...newState,
        activityId: null,
        isPrint: false, // не сохраняем это в ls
    };

    if (stateForLocalStorage !== state) {
        setAsidePanelLocalStorage(stateForLocalStorage);
    }

    return newState;
};
