'use strict';

import { getAppState } from '../../store/configureStore';
import { getList } from '../../store/model/list/selectors/getList';
import { getCard } from '../../store/model/selectors/getCard';
import printListTemplate from './templates/printList.html';
import printListCardTemplate from './templates/printListCard.html';
import { getCardInitial } from '../../store/model/card/constants';
import { getBoardIdByCardId } from '../../store/model/selectors/getBoardIdByCardId';
import { getBoardCardColorTagsCreateSelector } from '../../store/model/selectors/getBoardCardColorTags';
import { getCardFullNameHelper } from '../../store/model/card/helpers/getCardFullNameHelper';
import { getSortedCardIds } from '../react_components/main/kanbanView/components/Cards/HOCS/selectors/getSortedCardsIds';
import cardFormReadonlyTagTemplate from './templates/cardFormReadonlyTag.html';
import cardFormReadonlyChecklistTemplate from './templates/cardFormReadonlyChecklist.html';
import cardFormReadonlyUserTemplate from './templates/cardFormReadonlyUser.html';
import * as _ from 'underscore';
import { getInitials } from "../react_components/helpers/userPhotoHelper";
import { getChecklist } from "../../store/model/checklists/checklists/selectors/getChecklist";
import {
    ICardChecklist
} from "../react_components/main/kanbanView/components/CardChecklists/components/CardChecklists/types";
import {
    getPinnedCheckLists
} from "../react_components/main/kanbanView/components/CardChecklists/hocs/CardChecklistsHOC/helpers/getPinnedCheckLists";
import { getCardChecklists } from "../../store/model/checklists/checklists/selectors/getCardChecklists";
import { getAuthUserChecklistOrderMode } from "../../store/model/authUser/selectors/getAuthUserChecklistOrderMode";

App.Views.ListPrintPreview = App.Views.BaseView.extend({
    className: 'print__inner',
    
    template: _.template( printListTemplate ),

    initialize: function(options) {
        this.cardViews = [];
        this.listId = options.listId;
    },

    render: function() {
        const state = getAppState();
        const list = getList(state, this.listId);
        if (!list) return null;

        this.$el.html(this.template({
            description: '',
            ...list
        }));
        const cardIds = getSortedCardIds(state, { list });
        if (cardIds && cardIds.length) {
            cardIds.forEach(cardId => this.addCardView(cardId));
        }

        return this;
    },

    addCardView: function(cardId) {
        const cardView = new App.Views.ListCardPrintPreview({ cardId, listId: this.listId }).render();
        if (cardView) {
            this.cardViews.push(cardView);
            this.$el.find('.print__list-preview').append(cardView.el);
        }
    },

    close: function() {
        history.back();
        App.vent.trigger(App.vent['dialog:hide']); 
    },
    
    remove: function() {
        App.vent.off(null, null, this);
        this.removeViews(this.cardViews);
        return Backbone.View.prototype.remove.call(this);
    }

});

App.Views.ListPrintPreview.mixin(App.Mixins.Print);

App.Views.CardFormReadonlyTag = Backbone.View.extend({
    tagName: 'span',

    className: 'tags__item',

    template: _.template( cardFormReadonlyTagTemplate ),

    render: function(options) {
        this.$el.html(this.template(this.model));
        return this;
    }

});

App.Views.CardFormReadonlyChecklist = Backbone.View.extend({
    tagName: 'li',

    className: 'checklists__item',

    template: _.template( cardFormReadonlyChecklistTemplate ),

    render: function(options) {
        this.$el.html(this.template(this.model));
        return this;
    }

});

App.Views.CardFormReadonlyUser = Backbone.View.extend({
    tagName: 'li',

    className: 'users__item',

    template: _.template( cardFormReadonlyUserTemplate ),

    render: function(options) {
        this.$el.html(this.template(this.model));
        return this;
    }

});

App.Views.ListCardPrintPreview = App.Views.BaseView.extend({
    className: 'print__card-item',

    template: _.template( printListCardTemplate ),

    initialize: function(options) {
        this.cardId = options.cardId;
        this.listId = options.listId;
        this.userViews = [];
        this.tagViews = [];
        this.checklistViews = [];
    },

    render: function() {
        const state = getAppState();
        const card = getCard(state, this.cardId);
        if (!card || card.status) return null;

        let colors = [];
        if (card.colorIds && card.colorIds.length) {
            const getBoardCardColorTags = getBoardCardColorTagsCreateSelector();
            const boardCardColorTags = getBoardCardColorTags(state, getBoardIdByCardId(state, this.cardId));
            colors = card.colorIds.map(colorId => {
                return boardCardColorTags.find(color => color.id === colorId);
            });
        }

        const pinnedChecklistIds = card.meta && card.meta.pinnedSections && card.meta.pinnedSections.checklists;

        this.$el.html(this.template({
            ...getCardInitial(),
            ...card,
            colors,
            fullCardName: getCardFullNameHelper(card),
            checklists: pinnedChecklistIds
        }));
        this.removeViews(this.userViews);
        this.removeViews(this.tagViews);
        // this.paintColor();
        if (card.assignees && card.assignees.length) {
            card.assignees.forEach(assignee => this.addUserView(assignee.sharedUser));
        }
        if (card.tags && card.tags.length) {
            card.tags.forEach(tag => this.addTagView(tag));
        }

        if (pinnedChecklistIds && pinnedChecklistIds.length) {
            const pinnedChecklists = getPinnedCheckLists(
                getCardChecklists(state, this.cardId),
                pinnedChecklistIds,
                false,
                getAuthUserChecklistOrderMode(state)
            );
            pinnedChecklists.forEach(checklist => this.addChecklistView(checklist));
        }

        return this;
    },

    // paintColor: function() {
    //     const state = getAppState();
    //     const card = getCard(state, this.cardId);
    //     if (!card && !card.backgroundColor) return null;

    //     if (this.model.get('color')) {
    //         var classSuffix = 'base';
    //         var colorName = '&nbsp;';
    //         classSuffix = this.model.get('color').get('color');
    //         if (this.model.get('color').get('name')) {
    //             colorName = this.model.get('color').get('name');
    //         }
    //         this.$el.find('.colour-tags__tag').html(App.Util.unescapeHTML(colorName)).addClass('colour-tags__tag--' + classSuffix);
    //     }
    // },

    addUserView: function(model) {
        this.userViews[this.userViews.length] = new App.Views.CardFormReadonlyUser({
            model: {
                photoUrl: null, // KNG-3246 на майкрософте photoUrl нет и cardFromReadonlyUser падает
                initials: getInitials(model), // KNG-3246 на майкрософте initials нет и cardFromReadonlyUser падает
                ...model
            }
        });
        this.$el.find('.users').append(this.userViews[this.userViews.length-1].render().el);
    },

    addTagView: function(tag) {
        this.tagViews[this.tagViews.length] = new App.Views.CardFormReadonlyTag({ model: {name: tag} });
        this.$el.find('.tags').append(this.tagViews[this.tagViews.length-1].render().el);
    },

    addChecklistView: function(checklist) {
        this.checklistViews[this.checklistViews.length] = new App.Views.CardFormReadonlyChecklist({
            model: {
                checklistName: checklist.name,
                checklistItems: checklist.checkItems
            }
        });
        this.$el.find('.checklists').append(this.checklistViews[this.checklistViews.length-1].render().el);
    },

    close: function() {
        App.router.navigate(App.router.getUrl('l-' + this.listId), { trigger: true });
        App.vent.trigger(App.vent['dialog:hide']);
    },

    remove: function() {
        App.vent.off(null, null, this);
        this.removeViews(this.userViews);
        this.removeViews(this.tagViews);
        this.removeViews(this.checklistViews);
        return Backbone.View.prototype.remove.call(this);
    }

});
