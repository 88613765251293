import { IGetState, TCardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { saveDescription } from '../effects/saveDescription';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';

export const onCtrlEnter = (
    cardId: TCardId,
    description: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(saveDescription(cardId, description));
        dispatch(segmentTrackAction(SegmentCardEvent.CTRL_ENTER_DESCRIPTION));
    };
    return action;
};
