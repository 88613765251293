import * as React from 'react';
import './_AsidePanelActionsResize.scss';
import {Button, Icon} from 'kui';
import { IAsidePanelActionsResizeProps } from './types';
import { ASIDE_PANEL_ACTIONS_MINIMIZE, ASIDE_PANEL_ACTIONS_EXPAND } from './constants';
import { getAsidePanelActionsButtonTooltip } from '../../helpers/getAsidePanelActionsButtonTooltip';
import {CLASS_ASIDE_PANEL_ACTIONS} from '../AsidePanelActions/constants';

export function AsidePanelActionsResize ({
    isMinimized,
    onClick
}: IAsidePanelActionsResizeProps) {
    const className = CLASS_ASIDE_PANEL_ACTIONS + '-resize';
    const classNameMinimized = className + '--minimized';
    const icon = isMinimized ? 'arrow-left' : 'arrow-right';
    const tooltip = getAsidePanelActionsButtonTooltip({
                value: isMinimized ? ASIDE_PANEL_ACTIONS_EXPAND : ASIDE_PANEL_ACTIONS_MINIMIZE,
            });
    return (
        <Button
            className={`${className} ${isMinimized ? classNameMinimized : ''}`}
            variant="icon"
            tooltip={tooltip}
            onClick={onClick}
        >
            <Icon xlink={icon} size={16}/>
        </Button>
    );
};
