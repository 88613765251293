import { AT_BOARD_ACTION_SET, IBoardActionSetAction } from './types';
import { TCollaboratorsAction } from '../../collaborators/actions/types';

export const boardActionSet = (
    id: number,
    collaboratorsAction: TCollaboratorsAction
): IBoardActionSetAction => {
    return {
        type: AT_BOARD_ACTION_SET,
        id,
        collaboratorsAction
    }
};
