import { IGetState, TBoardId } from '../../../types/types';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { pushNotificationSettingsPatchRest } from './api/helpers/pushNotificationSettingsPatchRest';

export const pushNotificationSetEnabled = (
    boardId: TBoardId,
    enabled: boolean
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(pushNotificationSettingsPatchRest(boardId, {enabled}));
    };
    return action;
}
