import { connect } from 'react-redux';
import { ICardChecklistMassAssignHOCProps } from './types';
import { IApplicationState } from '../../../../../../../types/types';
import {
    CardChecklistAssignUserSelect
} from '../../components/CardChecklistAssignUserSelect/CardChecklistAssignUserSelect';
import {
    ICardChecklistAssignUserSelectEvents,
    ICardChecklistAssignUserSelectFields
} from '../../components/CardChecklistAssignUserSelect/types';
import { onMassAssign } from './events/onMassAssign';
import { getChecklistAssigneesSearchSelectOptionsCreateSelector } from './selectors/getAssigneesSearchSelectOptions';
import { getBoardIdByCardId } from '../../../../../../../store/model/selectors/getBoardIdByCardId';

const mapStateToProps = () => {
    const getUsersSearchSelectOptions = getChecklistAssigneesSearchSelectOptionsCreateSelector();

    const _mapStateToProps = (
        state: IApplicationState,
        { cardId, checklistId }: ICardChecklistMassAssignHOCProps
    ): ICardChecklistAssignUserSelectFields => {
        const boardId = getBoardIdByCardId(state, cardId);
        const avaliableAssignees = getUsersSearchSelectOptions(state, boardId, cardId, checklistId);
        return {
            availableAssignees: avaliableAssignees,
        };
    };

    return _mapStateToProps;
};

const mapDispatchToProps = (
    dispatch: any,
    { onClose, cardId, checklistId }: ICardChecklistMassAssignHOCProps
): ICardChecklistAssignUserSelectEvents => {
    return {
        onClose,
        onSelect: (permissionId) => dispatch(onMassAssign(cardId, checklistId, permissionId)),
    };
};

export const CardChecklistMassAssignHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardChecklistAssignUserSelect);

CardChecklistMassAssignHOC.displayName = 'CardChecklistMassAssignHOC';
