import { isLoadingSet } from './isLoadingSet';
import { errorSetAction } from '../store/signIn/actions/errorSetAction';
import { ISignInError } from '../store/signIn/types';
import { ThunkAction, Dispatch } from '../../../../../types/actions';

export const errorSet = (
    error: ISignInError
): ThunkAction => {
    const action = (dispatch: Dispatch) => {
        dispatch(errorSetAction(error));
        dispatch(isLoadingSet(false));
    };
    return action;
};
