import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { ICardHOCProps } from '../CardHOC/types';
import { onChange } from './events/onChange';
import { ICardPriorityEvents, ICardPriorityFields } from '../../components/CardPriority/types';
import { CardPriority } from '../../components/CardPriority/CardPriority';
import { onLoadCard } from '../events/onLoadCard';

const mapStateToProps = (
    state: IApplicationState,
    { card, portalId }: ICardHOCProps
): ICardPriorityFields => {
    return {
        portalId,
        priority: Number(card.priority) || 0
    }
}

const mapDispatchToProps = (
    dispatch: any,
    { card }: ICardHOCProps
): ICardPriorityEvents => ({
    onChange: (priority) => dispatch(onChange(card.cardId, priority)),
    onLoadCard: () => dispatch(onLoadCard(card.cardId))
})

export const CardPriorityHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardPriority);
CardPriorityHOC.displayName = 'CardPriorityHOC';
