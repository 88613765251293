import { ISnoozeBlockerClosedSetAction } from './types';
import { AT_SNOOZE_BLOCKER_CLOSED_SET } from './constants';

export const snoozeBlockerClosedSet = (
    snoozeBlockerClosed: boolean
): ISnoozeBlockerClosedSetAction => {
    return {
        type: AT_SNOOZE_BLOCKER_CLOSED_SET,
        snoozeBlockerClosed
    }
};
