import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../types/types';

export const onRename = (
    onRenameOwn?: () => void
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (onRenameOwn) onRenameOwn();
    };
    return action;
};
