import { IApplicationState, TBoardId, TCustomPropertyId } from '../../../../types/types';
import { getBoardCardCustomPropertyOptions } from './getBoardCardCustomPropertyOptions';

export const getBoardCardRequiredCustomPropertyIds = (
    state: IApplicationState,
    boardId: TBoardId
): Set<TCustomPropertyId> => {
    const requiredCustomPropertyIds: Set<TCustomPropertyId> = new Set;
    const customProperties = getBoardCardCustomPropertyOptions(state, boardId);
    customProperties.forEach((property) => {
        if (property.isRequired) requiredCustomPropertyIds.add(property.option);
    });
    return requiredCustomPropertyIds;
};
