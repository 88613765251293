import { IApplicationState, TBoardId } from '../../../../types/types';
import { getBoardCardAgingSettings } from './getBoardCardAgingSettings';

export const getBoardCardAgingIndicator = (
    state: IApplicationState,
    boardId: TBoardId
): boolean => {
    const cardAgingSettings = getBoardCardAgingSettings(state, boardId);

    return cardAgingSettings && cardAgingSettings.indicator && cardAgingSettings.indicator.enabled;
}
