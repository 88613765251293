import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { ICardWatchHOCOwnProps } from './types';
import { getCard } from '../../../../../../../store/model/selectors/getCard';
import { ICardWatchDispatchProps, ICardWatchStateProps } from '../../components/CardWatch/types';
import { CardWatch } from '../../components/CardWatch/CardWatch';
import { onToggle } from './events/onToggle';
import {
    getPushNotificationSettingsByBoardId
} from '../../../../../../../store/model/selectors/getPushNotificationSettingsByBoardId';
import { EPushNotificationSettingsScope } from '../../../../../../../types/rest/IRestPushNotificationSettings';
import { onClickIcon } from './events/onClickIcon';
import { onDidMount } from './events/onDidMount';
import { onOpenBoardSettings } from './events/onOpenBoardSettings';

const mapStateToProps = (
    state: IApplicationState,
    ownProps: ICardWatchHOCOwnProps
): ICardWatchStateProps => {
    const { cardId, boardId } = ownProps;
    const card = getCard(state, cardId);
    const isWatch = card ? card.wtch : null;
    const pushNotification = getPushNotificationSettingsByBoardId(state, boardId);
    const scopes = pushNotification && pushNotification.scope ? pushNotification.scope : [];
    const cardsImSubscribedChecked = scopes.includes(EPushNotificationSettingsScope.WATCH);
    const isAllCardsWatched = scopes.includes(EPushNotificationSettingsScope.ALL);
    const pushNotificationsEnabled = pushNotification && pushNotification.enabled;

    return {
        isInAppCardNotificationsOn: cardsImSubscribedChecked && pushNotificationsEnabled,
        isWatch,
        isAllCardsWatched
    }
};

const mapDispatchToProps = (
    dispatch: any,
    ownProps: ICardWatchHOCOwnProps
): ICardWatchDispatchProps => {
    const {cardId, boardId} = ownProps;
    return {
        onClickIcon: () => dispatch(onClickIcon()),
        onDidMount: () => dispatch(onDidMount(boardId)),
        onToggle: () => dispatch(onToggle(cardId)),
        onOpenBoardSettings: () => dispatch(onOpenBoardSettings())
    }
};

export const CardWatchHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardWatch);

CardWatchHOC.displayName = 'CardWatchHOC';
