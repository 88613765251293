import { Button, Tooltip } from 'kui';
import * as React from 'react';
import { TOPBAR_COLLABORATORS_MAX } from 'app/const';
import { ICollaborator } from 'app/store/collaborators/collaborators/types';
import { root } from 'app/store/constants';
import { CLASS_TOPBAR_COLLABORATORS } from './constants';
import {ITopBarCollaboratorsProps} from './types';
import './_TopBarCollaboarators.scss';
import { UserpicStorage } from 'app/view/react_components/base/components/UserpicStorage/UserpicStorage';
import { AnalyzeRender } from 'app/view/react_components/helpers/memoizeHelper';

// @ts-ignore
const analyzeRender = window.Settings.development ? new AnalyzeRender(`TopBarCollaborators`) : null;

export function TopBarCollaborators ({
    collaborators,
    onClick
}: ITopBarCollaboratorsProps) {
    if (!collaborators.length) return null;

    if (analyzeRender) analyzeRender.call(`TopBarCollaborators`);

    const [isSmall, setSmall] = React.useState(true);
    const max = isSmall ? 2 : TOPBAR_COLLABORATORS_MAX;
    const width = collaborators.length < max ? collaborators.length : max;

    const className = CLASS_TOPBAR_COLLABORATORS;
    const classNameCount = className + '--width' + width;
    const classButton = className + '-button';
    const classPlus = className + '-plus';
    const classDropdown = className + '-dropdown';

    let elements;
    if (collaborators.length <= max) {
        elements = collaborators.map(collaborator => Collaborator(collaborator, onClick));
    } else {
        elements = (
            <Button className={classButton} variant={'icon-text'}>
                {collaborators.slice(0, max - 1).map(collaborator => Collaborator(collaborator, onClick))}
                <Tooltip
                    className={classDropdown}
                    delay={100}
                    direction={'down-left'}
                    footer={collaborators.map(collaborator => Collaborator(collaborator, onClick))}
                    isPortal={false}
                    value={'Collaborators'} // пустой value не показывает tooltip
                >
                    <div className={classPlus}>
                        <span>+{collaborators.length - max + 1}</span>
                    </div>
                </Tooltip>
            </Button>
        );
    }

    React.useEffect(() => {
        const onResizeWindow = () => {
            setSmall(root.innerWidth < 1500);
        };
        onResizeWindow();
        root.addEventListener('resize', onResizeWindow);

        return () => {
            root.removeEventListener('resize', onResizeWindow);
        }
    }, []);

    return (
        <div
            className={`
                ${className}
                ${classNameCount}
            `}
        >
            {elements}
        </div>
    );
}

function Collaborator (
    collaborator: ICollaborator,
    onClick: (collaborator: ICollaborator) => void
) {
    const className = CLASS_TOPBAR_COLLABORATORS;
    const classItem = className + '-item';

    let initials = collaborator.initials;
    if (!initials && collaborator.displayName) {
        initials = collaborator.displayName.substring(0, 2);
    }
    return (
        <UserpicStorage
            background={collaborator.color}
            className={classItem}
            initials={collaborator.initials}
            key={collaborator.id}
            size={40}
            src={collaborator.photoUrl}
            tooltip={{
                direction: 'down',
                isNoEvents: true,
                isNoWrap: true,
                value: collaborator.displayName
            }}
            userId={collaborator.userId}
            onClick={() => onClick(collaborator)}
        />
    );
}
