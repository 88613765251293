import { getMessages } from 'app/store/constants';
import { AuthController } from 'app/helper/authorisation/microsoft/oauth/AuthController';
import { isMicrosoftPersonalAccount } from 'app/store/model/authUser/selectors/isMicrosoftPersonalAccount';
import { dispatch, getAppState } from 'app/store/configureStore';
import { getAuthUser } from 'app/store/model/authUser/selectors/getAuthUser';
import { getToken } from 'app/helper/authorisation/microsoft/filePicker/auth';
import { snackbarInfoDefault } from 'app/view/react_components/snackbarsStack';
import { CommonAuthManager, commonAuthManagerInstance } from 'app/helper/authorisation/common/CommonAuthManager';
import { GROUP_MEMBER_READ_ALL } from 'app/helper/authorisation/microsoft/oauth/Scopes';
import { EAuthUserPlatformType } from 'app/types/rest/IRestAuthUser';

/***
 * класс позволяет фронту общаться с  AuthController и управляет процессов авторизации
 */
export class MSAuthManager {

    logout(){
        AuthController.logout().then( () => {
            document.location.href = document.location.origin + '/logout';
        });
    }

    checkAndRequestFilePickerDrive(onApprove: ()=> void, onShow?: ()=> void, onCancel?: ()=> void, onRequest?: ()=> void) {
        // this.checkAndRequestPermission([FILES_READWRITE_ALL], onApprove, FILES_READWRITE_ALL, onShow, onCancel, onRequest);
        const state = getAppState();
        let user = getAuthUser(state);
        const microsoftPersonalAccount = isMicrosoftPersonalAccount(state);
        if (microsoftPersonalAccount){
            getToken({command: 'authenticate', resource: '', type: 'Graph'}, user.email, true)
                .then((res)=> {
                    console.log(res)
                    onApprove();
                })
                .catch((e) => {
                    getToken({command: 'authenticate', resource: '', type: 'Graph'}, user.email, null, true)
                        .then((res)=> {
                            console.log(res)
                            dispatch(snackbarInfoDefault({
                                id: 'SNACK_BAR_REQUEST_PERSONAL_FILE_PICKER_ID',
                                text: getMessages().getText('snackbar.card.personal.request_file_picker'),
                                timer: -1
                            }));
                        })
                })
        } else {
            onApprove();
        }
    }

    checkAndRequestUploadToDrive(onApprove: ()=> void, onShow?: ()=> void, onCancel?: ()=> void, onRequest?: ()=> void) {
        // this.checkAndRequestPermission([FILES_READWRITE_ALL], onApprove, FILES_READWRITE_ALL, onShow, onCancel, onRequest);
        console.info('checkAndRequestUploadToDrive Not Implemented')
        onApprove();
    }

    // check происходит раньше, по кнопке надо уже только request
    // checkAndRequestSharedDrives(onApprove: ()=> void, onShow?: ()=> void, onCancel?: ()=> void, onRequest?: ()=> void) {
    //     commonAuthManagerInstance.checkAndRequestPermission(GROUP_MEMBER_READ_ALL, onApprove, GROUP_MEMBER_READ_ALL, onShow, onCancel, onRequest);
    // }

    requestSharedDrives(onSuccess: ()=> void, onError: ()=> void, onCancel?: ()=> void) {
        CommonAuthManager.requestScopes(EAuthUserPlatformType.MICROSOFT, [GROUP_MEMBER_READ_ALL], onSuccess, onError, onCancel);
    }

}

export const msAuthManagerInstance = new MSAuthManager();
