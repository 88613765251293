import * as React from 'react';
import { ICardNumberProps } from './types';
import { CARD_CLASS } from '../../constants';
import './CardNumber.scss';

export function CardNumber({
    number
}: ICardNumberProps) {
    const cardNumberClass = CARD_CLASS + '__c-number';
    return (
        <span className={cardNumberClass}>{number}</span>
    );
}
