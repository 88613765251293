import * as React from 'react';
import { ICardRecurringFormProps } from './types';
import './_CardRecurringForm.scss';
import { Button, ButtonsGroup, ButtonTitle, Icon, Input, Loader } from 'kui';
import { GOOGLE_SPACING } from '../../../../../../../const';
import { CLASS_CARD_SECTION_RECURRING } from '../../../constants';
import { ErrorHint } from '../../../../../base/components/ErrorHint/ErrorHint';
import { v4 as uuidv4 } from 'uuid';
import { RepeatTypeSelect } from '../RepeatTypeSelect/RepeatTypeSelect';
import { ERecurringRepeatMonthType, ERecurringRepeatType } from '../../constants';
import { EndType } from '../EndType/EndType';
import { WeekDays } from '../WeekDays/WeekDays';
import { MonthDay } from '../MonthDay/MonthDay';
import { recurringReducer } from './recurringReducer';
import {
    BUTTON_CANCEL,
    BUTTON_SAVE,
    ERROR_DUE_DATE,
    ERROR_LAST_REPEAT_START_DATE,
    ERROR_START_DATE,
    LABEL_CREATE_IN_LIST,
    LABEL_LAST_RUN,
    LABEL_NEXT_RUN,
    LABEL_REPEAT_EVERY,
    LABEL_START,
    LABEL_TIME
} from '../wording';
import { getMonthDayData } from '../../helpers/getMonthDayData';
import {
    ERecurringTaskEndType,
    ERecurringTaskStatus
} from '../../../../../../../types/rest/recurringTask/IRestRecurringTask';
import { IRecurringTaskForm } from '../../types';
import { CardRecurringFormContext } from './constants';
import * as moment from 'moment';
import { CARD_RECURRING_FAQ_LINK } from '../../../../cardTemplate/constants';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { SearchSelect } from '../../../../../base/components/SearchSelect/SearchSelect';
import { AsidePanelTimeSelect } from '../../../../asidePanel/components/AsidePanelTimeSelect/AsidePanelTimeSelect';
import { ID_ASIDE_PANEL_DATEPICKER_PORTAL } from '../../../../asidePanel/components/AsidePanelActionsButton/constants';
import { DatepickerHOC } from '../../../../../base/components/Datepicker/hocs/DatepickerHOC/DatepickerHOC';

export function CardRecurringForm({
    recurringTaskForm,
    lists,
    sectionType,
    status,
    cardId,
    onAdd,
    onUpdate,
    onCancel,
    onFaqClick,
    startHours,
    startMinutes
}: ICardRecurringFormProps) {
    const isPopup = sectionType === ESettingsSectionType.POPUP;
    const isReadonly = sectionType === ESettingsSectionType.READONLY;
    const [errorTime, setErrorTime] = React.useState(null as string);
    const [errorTimeKey, setErrorTimeKey] = React.useState(uuidv4());
    const [errorDueDate, setErrorDueDate] = React.useState(null as string);
    const [isSaving,setIsSaving] = React.useState(false);
    const footerRef = React.useRef(null);

    let [form, updateFormState] = React.useReducer(recurringReducer, {
        listId: lists[0] ? Number(lists[0].value) : null,
        repeatEvery: 1,
        repeatType: ERecurringRepeatType.DAY,
        endType: ERecurringTaskEndType.NEVER,
        weekDays: [],
        monthDay: ERecurringRepeatMonthType.DAY_OF_MONTH,
        repeats: 1
    });

    const classNameRow = CLASS_CARD_SECTION_RECURRING + '__row';
    const classNameCardId = CLASS_CARD_SECTION_RECURRING + '__card-' + cardId;
    const classNameStart = CLASS_CARD_SECTION_RECURRING + '__start';
    const classNameStartItem = CLASS_CARD_SECTION_RECURRING + '__start-item';
    const classNameRepeats = CLASS_CARD_SECTION_RECURRING + '__repeats';
    const classNameRepeatType = CLASS_CARD_SECTION_RECURRING + '__repeat-type';
    const classNameTime = CLASS_CARD_SECTION_RECURRING + '__time';
    const classNameFooter = CLASS_CARD_SECTION_RECURRING + '__footer';
    const classStartDate = CLASS_CARD_SECTION_RECURRING + '__start-date';
    const classNameList = CLASS_CARD_SECTION_RECURRING + '__list';

    const updateForm = (update: IRecurringTaskForm) => {
        updateFormState(update);
        if (!isPopup && !isReadonly) {
            onUpdate({...form, ...update})
        }
    }

    const validateDates = (
        startDate: Date,
        dueDate: Date
    ) => {
        if (isReadonly) return;
        if (startDate && startDate <= new Date()) {
            setErrorTimeKey(uuidv4()); // перерисовать хинт
            setErrorTime(status === ERecurringTaskStatus.CLOSED ? ERROR_LAST_REPEAT_START_DATE : ERROR_START_DATE);
        } else {
            setErrorTime(null);
        }
        if (startDate && dueDate && startDate >= dueDate) {
            setErrorDueDate(ERROR_DUE_DATE);
        } else {
            setErrorDueDate(null);
        }
    }

    React.useEffect(() => {
        updateFormState(recurringTaskForm);
        validateDates(recurringTaskForm.startDate, recurringTaskForm.dueDate);
    }, [recurringTaskForm]);

    const selectedList = lists.find(list => list.value === String(form.listId));

    const isFormInvalid = () => Boolean(
        errorDueDate ||
        errorTime ||
        !form.startDate ||
        !form.repeatEvery ||
        (form.endType === ERecurringTaskEndType.REPEATS && !form.repeats) ||
        (form.endType === ERecurringTaskEndType.DATE && !form.dueDate) ||
        (form.repeatType === ERecurringRepeatType.WEEK && !form.weekDays.length)
    );

    //Handlers
    const onStartDataChange = (
        useDefaultTime = true
    ) => (
        startDate: Date
    ) => {
        if (startDate && !form.startDate && useDefaultTime) {
            startDate.setHours(startHours, startMinutes);
            if (startDate < new Date())
                startDate.setHours(new Date().getHours() + 1)
        }
        if (startDate && form.startDate && startDate.getTime() === form.startDate.getTime()) return;
        updateForm( {
            startDate,
            monthDayData: getMonthDayData(startDate)
        });

        validateDates(startDate, form.dueDate);
    }

    const getRepeatEvery = (
        target: HTMLInputElement
    ): number => {
        if (!target.value) return null;
        const repeatEvery = Number(target.value);
        return repeatEvery;
    }

    const onRepeatEveryChange = (
        event: React.SyntheticEvent
    ) => {
        updateFormState({ repeatEvery: getRepeatEvery(event.target as HTMLInputElement) });
    }

    const onRepeatEveryBlur = (
        event: React.FocusEvent
    ) => {
        const value = getRepeatEvery(event.target as HTMLInputElement);
        if (value >= 1) {
            updateForm({ repeatEvery: value });
        } else {
            updateForm({ repeatEvery: 1 });
        }
    }

    const onDueDateChange = (
        dueDate: Date
    ) => {
        updateForm({ dueDate });
        validateDates(form.startDate, dueDate);
    }

    const onAddHandler = () => {
        setIsSaving(true);
        onAdd(form);
    }
    let label = LABEL_START;
    if (status === ERecurringTaskStatus.PROCESSED) {
        label = LABEL_NEXT_RUN;
    } else if (status === ERecurringTaskStatus.CLOSED) {
        label = LABEL_LAST_RUN;
    }

    const isDateError = form.startDate && moment(form.startDate).endOf('day').toDate() < new Date();
    const classStartDateError = errorTime && isDateError ? classStartDate + '--error' : '';

    React.useEffect(() => {
        if (isPopup && footerRef.current) footerRef.current.scrollIntoView({ block: 'nearest' }); // доскролить до кнопок, если они пропали из вида
    }, [form.repeatType]);

    return (
        <CardRecurringFormContext.Provider value={{
            updateFormState,
            updateForm,
            form,
            isReadonly
        }}>
            <div className={`
                ${classNameRow}
                ${classNameStart}
                ${classNameCardId}
            `}>
                <DatepickerHOC
                    className={classStartDateError}
                    fromLabel={label}
                    selectedFromDate={form.startDate}
                    onChange={onStartDataChange()}
                    minDate={new Date()}
                    isReadonly={isReadonly}
                    portalId={isPopup ? ID_ASIDE_PANEL_DATEPICKER_PORTAL : null}
                    isCompact
                    isSingle
                />
                <Input
                    className={`
                        ${classNameStartItem}
                        ${classNameRepeats}
                    `}
                    label={LABEL_REPEAT_EVERY}
                    value={String(form.repeatEvery)}
                    autosize={false}
                    onChange={onRepeatEveryChange}
                    onBlur={onRepeatEveryBlur}
                    type={'number'}
                    readOnly={isReadonly}
                />
                <RepeatTypeSelect
                    className={`
                        ${classNameStartItem}
                        ${classNameRepeatType}
                    `}
                />
                <AsidePanelTimeSelect
                    className={`
                        ${classNameStartItem}
                        ${classNameTime}
                    `}
                    date={form.startDate}
                    label={LABEL_TIME}
                    onChange={onStartDataChange(false)}
                    isReadonly={isReadonly}
                    isNotValid={!isDateError && !!errorTime}
                />
                {!isDateError && <ErrorHint
                    arrowTranslate={{ right: GOOGLE_SPACING * 4 }}
                    direction={isPopup ? 'up-left' : 'down-left'}
                    key={errorTimeKey}
                    portalSelector={`.${classNameTime} .kui-label`}
                    selector={`.${classNameCardId} .${classNameTime} .kui-input__item`}
                    value={errorTime}
                    arrow={isPopup ? 'down' : 'up'}
                    translate={{ top: isPopup ? 0 : GOOGLE_SPACING * 2 }}
                />}
                {isDateError && <ErrorHint
                    arrowTranslate={{ left: GOOGLE_SPACING * 4 }}
                    direction={'up-right'}
                    key={errorTimeKey}
                    portalSelector={`.${classStartDateError} .kui-button-dropdown__item`}
                    selector={`.${classNameCardId} .${classStartDateError} .kui-button`}
                    value={errorTime}
                    arrow={'down'}
                />}
            </div>
            <div className={classNameRow}>
                {
                    form.repeatType === ERecurringRepeatType.WEEK &&
                    <WeekDays />
                }
                {
                    form.repeatType === ERecurringRepeatType.MONTH && form.startDate &&
                    <MonthDay />
                }
            </div>
            <div className={classNameRow}>
                <SearchSelect
                    activeValue={selectedList && selectedList.value}
                    className={classNameList}
                    isFitWindow={true}
                    opened={false}
                    options={lists}
                    text={selectedList && selectedList.text}
                    variant={'arrow'}
                    onSelect={(listId) => updateForm({ listId: Number(listId) })}
                    label={LABEL_CREATE_IN_LIST}
                    readOnly={isReadonly}
                />
            </div>
            <div className={classNameRow}>
                <EndType
                    errorDueDate={errorDueDate}
                    onDueDateChange={onDueDateChange}
                    sectionType={sectionType}
                />
            </div>
            {
                isPopup &&
                <div
                    className={classNameFooter}
                    ref={footerRef}
                >
                    <Button
                        variant="icon"
                        href={CARD_RECURRING_FAQ_LINK}
                        target={'_blank'}
                        onClick={onFaqClick}
                    >
                        <Icon xlink="help" size={ 24 } />
                    </Button>
                    {
                        !isSaving &&
                        <ButtonsGroup>
                            <Button variant={'secondary'} onClick={onCancel}>
                                <ButtonTitle>{BUTTON_CANCEL}</ButtonTitle>
                            </Button>
                            <Button
                                variant={'primary'}
                                disabled={isFormInvalid()}
                                onClick={onAddHandler}
                            >
                                <ButtonTitle>{BUTTON_SAVE}</ButtonTitle>
                            </Button>
                        </ButtonsGroup>
                    }
                    {isSaving && <Loader size={'large'} />}
                </div>
            }
        </CardRecurringFormContext.Provider>
    );
}
