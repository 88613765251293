import React from "react";
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import { TimeTrackerActionsDialogHOC } from "../react_components/dialogs/timeTrackerDialog/hocs/TimeTrackerActionsDialogHOC/TimeTrackerActionsDialogHOC";


export const TimeTrackerActionsView = App.Views.BaseView.extend({

    initialize: function(options) {
        this.root = createRoot(this.el);
        this.store = options.store;
        this.targetCardId = options.targetCardId
        this.render();
    },

    render: function() {
        this.root.render(
            <Provider store={this.store}>
                <TimeTrackerActionsDialogHOC targetCardId={this.targetCardId}/>
            </Provider>
        );
        return this;
    },

    remove: function() {
        this.root.unmount();
        Backbone.View.prototype.remove.call(this);
    }
});
