import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { IGetState } from '../../../../../types/types';
import { INITIAL_EDITED_FILTER_ID, MY_WORK_FILTER_BOARD_ID } from '../constants';
import { cloneFilterObject } from '../helpers/cloneFilterObject';
import { editedFilterSet } from '../store/filterPanel/actions/editedFilterSet';
import { filterPanelActionSetAction } from '../store/filterPanelBoards/actions/filterPanelActionSetAction';
import { defaultFilterSelector } from '../store/selectors/defaultFilterSelector';
import { filterPanelBoardsSelector } from '../store/selectors/filterPanelBoardsSelector';
import { authUserSetUsedProFeatures } from '../../../../../rest/effects/authUser/authUserSetUsedProFeatures';

export const addNewFilter = (
    boardId: number,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        let { editedFilter } = filterPanelBoardsSelector(getState(), boardId);
        if (editedFilter) return;

        const defaultFilter = cloneFilterObject(defaultFilterSelector(getState(), boardId));
        editedFilter = cloneFilterObject({...defaultFilter, id: INITIAL_EDITED_FILTER_ID});
        dispatch(filterPanelActionSetAction(boardId, editedFilterSet(editedFilter)));
    };
    return action;
};
