const category = 'In App notifications';

export const SegmentInAppNotificationEvent = {
    OPEN_NOTIFICATIONS_PANEL: { category, event: 'In-app notifications panel opened' },
    CLICKED_HOW_IT_WORKS_BUTTON: { category, event: 'In-app notifications instructions clicked (notifications present)' },
    CLICKED_HOW_IT_WORKS_LINK: { category, event: 'In-app notifications instructions clicked (no notifications)' },
    CLICKED_MARK_ALL_AS_READ: { category, event: 'in-app notifications Mark all as read clicked' },
    CLICKED_DELETE_ALL_READ: { category, event: 'in-app notifications Delete all read clicked' },
    CLICKED_ALL: { category, event: 'In-app notifications for all boards chosen' },
    CLICKED_CURRENT_BOARD: { category, event: 'In-app notifications for current board chosen' },
    CLICKED_UNREAD: { category, event: 'In-app notifications unread chosen' },
    CLICKED_READ: { category, event: 'In-app notifications read chosen' },
    CLICKED_NOTIFICATION: { category, event: 'In-app popup notification clicked' },
    CLOSED_NOTIFICATION: { category, event: 'In-app popup notification closed' },
    CLICKED_NOTIFICATION_PLATE: { category, event: 'In-app notification clicked' },
    CLICKED_SHOW_MORE_ON_NOTIFICATIONS_GROUP: { category, event: 'Show more on In-app notifications group clicked' },
    CLICKED_SHOW_LESS_ON_NOTIFICATIONS_GROUP: { category, event: 'Show less on In-app notifications group clicked' },
    CLICKED_NOTIFICATION_SETTINGS: { category, event: 'In-app notifications settings link clicked' },
    UNDELIVERED_NOTIFICATIONS_SHOWN: { category, event: 'Undelivered notifications shown' },
    STARRED_TAB_CLICKED: { category, event: 'Starred tab clicked' },
    STAR_NOTIFICATION: { category, event: 'Star notification clicked' },
    UNSTAR_NOTIFICATION: { category, event: 'Unstar notification clicked' },
    UNSTAR_GROUP: { category, event: 'Unstar group clicked' },
    UNSTAR_ALL: { category, event: 'Unstar all clicked' },
    GROUP_STARRED: { category, event: 'Group starred clicked' },
    UNGROUP_STARRED: { category, event: 'Ungroup starred clicked' },
}
