import { ICard } from '../card/types';
import { ESortField, ISorting } from '../list/types';
import { sortCardsByOrderNumber } from './sortCardsByOrderNumber';
import { CARDS_COMPARATORS, getCardsComparator } from './comporators/constants';
import {
    FP_KEY_SORT_CARDS_BY_FILTER_KEY_DUE_DATE_EARLIEST_FIRST,
    FP_KEY_SORT_CARDS_BY_FILTER_KEY_DUE_DATE_LATEST_FIRST
} from '../../../view/react_components/aside_panel/filterPanelBoard/constants';
import { ICardsComparator } from './comporators/types';
import { IFilterPanel } from '../../../view/react_components/aside_panel/filterPanelBoard/store/filterPanel/types';
import { ECardAgingIndicatorType } from '../../../types/rest/ICardAging';

export const sortCards = (
    sorting: ISorting,
    filterPanel: IFilterPanel,
    cardAgingType?: ECardAgingIndicatorType
) => {
    return (cardA: ICard, cardB: ICard) => {
        let isDesc = false;
        let comparator: ICardsComparator = null;
        const defaultFilterSortings = [FP_KEY_SORT_CARDS_BY_FILTER_KEY_DUE_DATE_EARLIEST_FIRST, FP_KEY_SORT_CARDS_BY_FILTER_KEY_DUE_DATE_LATEST_FIRST];
        if (
            filterPanel.isEnabled &&
            defaultFilterSortings.includes(filterPanel.defaultFilter.sortCardsBy)
        ) {
            comparator = CARDS_COMPARATORS[ESortField.sortByDueDate];
            isDesc = filterPanel.defaultFilter.sortCardsBy === FP_KEY_SORT_CARDS_BY_FILTER_KEY_DUE_DATE_LATEST_FIRST;
        } else if (sorting && sorting.isEnabled) {
            comparator = getCardsComparator(sorting);
            isDesc = sorting.isDesc;
        }
        if (comparator && comparator.sort) {
            const value = comparator.sort(comparator.getCardValue(cardA, cardAgingType), comparator.getCardValue(cardB, cardAgingType), isDesc);
            if (value !== 0) return value;
        }
        return sortCardsByOrderNumber(cardA, cardB);
    }
}
