import { IBoardExportItemAdd } from './types';
import { AT_BOARD_EXPORT_ITEM_ADD } from './constants';
import { IBoardExportItem } from '../types';

export const boardExportItemAdd = (
    boardId: number,
    item: IBoardExportItem
): IBoardExportItemAdd => ({
        type: AT_BOARD_EXPORT_ITEM_ADD,
        boardId,
        item
    }
);
