import { Dispatch, ThunkAction } from 'app/types/actions';
import { openReleaseNotes } from 'app/view/react_components/base/effects/openReleaseNotes';

export function onClickRelease (
    closeProfile: () => void
): ThunkAction {
    const action = (
        dispatch: Dispatch
    ) => {
        closeProfile();
        setTimeout(() => { // передать фокус в панель после закрытия дропдауна
            dispatch(openReleaseNotes());
        }, 100);
    };
    return action
};
