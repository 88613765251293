import {TClickManagerAction} from '../actions/types';
import {IClickManager} from '../../types';
import { AT_CLICK_MANAGER_SET, AT_INITIAL_STATE_SET } from '../actions/constants';
import {clickManagerModelReducer} from '../../clickManagerModel/reducers/clickManagerModelReducer';
import * as deepFreeze from 'deep-freeze';

const initialState: IClickManager = {};

const _clickManagerReducer = (
    state = initialState,
    action: TClickManagerAction
): IClickManager => {
    switch (action.type) {
        case AT_CLICK_MANAGER_SET: {
            const {boardId, clickManagerModelAction} = action;
            return {
                ...state,
                [boardId]: clickManagerModelReducer(state[boardId], clickManagerModelAction)
            }
        }
        case AT_INITIAL_STATE_SET: {
            return {
                ...initialState
            }
        }
        default:
            return state;
    }
};

export const clickManagerReducer = (
    state = initialState,
    action: TClickManagerAction
): IClickManager => {
    // @ts-ignore
    if (window.Settings.development) {
        // @ts-ignore
        return deepFreeze(_clickManagerReducer(state, action));
    } else {
        return _clickManagerReducer(state, action);
    }
};
