import { ICards } from '../cards/types';
import { IApplicationState, TCardId } from '../../../types/types';
import { getCard } from './getCard';
import { getSubcardsMaxDate } from './getSubcardsMaxDate';
import { ICardsDatesDelta } from 'app/types/ICardsDatesDelta';

export const getCardEpicsForDueDateChange = (
    state: IApplicationState,
    epicId: TCardId,
    cards: ICards = {},
    cardDatesUpdate: ICardsDatesDelta = {}
): ICards => {
    if (!epicId){
        return  cards;
    }
    const epic = getCard(state, epicId);
    if (!epic) return cards;
    const dueDate = getSubcardsMaxDate(state, epicId, cards);
    const epicDueDate = cards[epicId] && cards[epicId].dueDate !== undefined ? cards[epicId].dueDate : epic.dueDate;
    if (epicDueDate !== dueDate){
        cards[epicId] = {
            ...cards[epicId],
            dueDate
        };
        if (dueDate && epic.dueDate) {
            cardDatesUpdate[epicId] = {...cardDatesUpdate[epicId], ...{deltaDueDate: dueDate - epic.dueDate}};
        }
        return getCardEpicsForDueDateChange(state, epic.epicId, cards, cardDatesUpdate);
    }
    return cards;
}
