import { IApplicationState, TBoardId, TCardId } from '../../../types/types';
import { convertTimeNumberToString } from '../../../view/react_components/aside_panel/cardDetails/helpers/convertTimeNumberToString';
import { getBoardWorkHoursInDay } from './getBoardWorkHoursInDay';
import { getCardEstimate } from './getCardEstimate';

export const getCardEstimateStr = (
    state: IApplicationState,
    boardId: TBoardId,
    cardId: TCardId,
    includeChecklists: boolean = true,
    seconds: boolean = false,
    includeDayDigit: boolean = true
): string => {
    const estimate = getCardEstimate(state, cardId, includeChecklists);
    if (!estimate) return null;

    const workHoursInDay = getBoardWorkHoursInDay(state, boardId);
    const estimateStr = convertTimeNumberToString(estimate, seconds, workHoursInDay, includeDayDigit);
    return estimateStr;
};
