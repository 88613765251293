import { IGetState, TBoardId } from '../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { getBoardForRollback } from '../../../../../store/model/selectors/getBoardForRollback';
import { boardsActionSet } from '../../../../../store/model/actions/boardsActionSet';
import { boardActionSet } from '../../../../../store/model/boards/actions/boardActionSet';
import { updateAction } from '../../../../../store/model/board/actions/updateAction';
import { patchRest } from '../patchRest';
import { sendAuthUserRealTimeStoreAction } from '../../../../../view/react_components/base/helpers/realTimeHelperTS';
import { IRestUserBoardMeta } from '../../../../../types/rest/IRestUserBoardMeta';

export const boardUserMetaPatchRest = (
    boardId: TBoardId,
    update: IRestUserBoardMeta,
    isRealtime: boolean = true
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const boardForRollback = getBoardForRollback(getState(), boardId, { userMeta: update });
        const userMeta = {
            ...boardForRollback.userMeta,
            ...update
        }
        const action = boardsActionSet(boardActionSet(boardId, updateAction({ userMeta })));
        dispatch(action);
        return dispatch(patchRest(boardId, { userMeta: update }))
            .then(() => {
                if (isRealtime) {
                    dispatch(sendAuthUserRealTimeStoreAction(action));
                }
            })
            .catch((e: any) => {
                console.error(e);
                dispatch(boardsActionSet(boardActionSet(boardId, updateAction(boardForRollback))));
            });
    };
    return action;
};
