import {ActionCreator} from 'redux';
import {Dispatch, ThunkAction} from '../../../../../../../types/actions';
import {IGetState, TBoardId} from '../../../../../../../types/types';
import {openBoardSettings} from '../effects/openBoardSettings';
import { boardSettingsClickedSegmentEvent, ESegmentBoardMenuLocation } from '../../../../../../../middlewares/segment/trackEntities/boardMenuEvents';
import { SegmentBoardSettingsSourceValue } from '../../../../../../../middlewares/segment/trackEntities/boardEvents';

export const onBoardSettingsClicked: ActionCreator<ThunkAction> = (
    boardId: TBoardId
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(openBoardSettings(boardId, SegmentBoardSettingsSourceValue.NAVIGATION_PANEL));
        dispatch(boardSettingsClickedSegmentEvent(ESegmentBoardMenuLocation.leftPanel));
    };
    return action;
};
