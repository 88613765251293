import { IGetState, TBoardId } from '../../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { getSelectedCards } from '../../../../../../../clickManager/selectors/getSelectedCards';
import { segmentTrackAction } from '../../../../../../../../../middlewares/segment';
import { SegmentMultiSelectEvent } from '../../../../../../../../../middlewares/segment/trackEntities/multiselectEvents';
import { cardsDeleteOrArchive } from '../../../../../../../base/effects/cardsDeleteOrArchive';

export function onDelete (boardId: TBoardId): ThunkAction {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        dispatch(segmentTrackAction(SegmentMultiSelectEvent.DELETE_CARDS_CHOSEN));
        const cardIds = getSelectedCards(state, boardId);
        dispatch(cardsDeleteOrArchive(boardId, cardIds))
    };
    return action;
}
