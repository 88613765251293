import { IApplicationState, TBoardId, TFilterId } from '../../../../../../../../types/types';
import { savedFilterSelector } from '../../../../store/selectors/savedFilterSelector';
import { getSortedSavedFiltersIds } from './getSortedSavedFiltersIds';

export const getOtherSortedSavedFiltersIds = (
    state: IApplicationState,
    boardId: TBoardId
): TFilterId[] => {
    const sortedSavedFiltersIds = getSortedSavedFiltersIds(state, boardId);
    const showOnBarFilterIds = sortedSavedFiltersIds.reduce((ids, filterId) => {
        const filter = savedFilterSelector(state, filterId);
        if (filter && filter.meta && !filter.meta.isMyWorkShowOnBar) ids.push(filterId);
        return ids;
    }, []);
    return showOnBarFilterIds;
}
