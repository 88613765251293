import { INotificationBeforeDueDateDeleteAction } from './types';
import { AT_NOTIFICATION_BEFORE_DUEDATE_DELETE } from './constants';
import { TNBDId } from '../../../../types/types';

export const notificationBeforeDueDateDeleteAction = (
    notificationBeforeDueDateId: TNBDId
): INotificationBeforeDueDateDeleteAction => {
    return {
        type: AT_NOTIFICATION_BEFORE_DUEDATE_DELETE,
        notificationBeforeDueDateId
    }
};
