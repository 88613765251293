import { Dispatch } from '../../../../../types/actions';
import { IGetState } from '../../../../../types/types';
import { processPerformanceAllowForAuthUser } from './processPerformanceAllowForAuthUser';
import { processPerformanceAllowForUnAuthUser } from './processPerformanceAllowForUnAuthUser';
import { isUserAuthorized } from '../../../../../store/model/authUser/selectors/isUserAuthorized';

export const processPerformanceAllow = (
) => {
    const action = (dispatch: Dispatch, getState: IGetState) => {
        const isAuth = isUserAuthorized(getState());
        if (isAuth){
            dispatch(processPerformanceAllowForAuthUser());
        }else{
            dispatch(processPerformanceAllowForUnAuthUser());
        }
    };
    return action;
};
