import {connect} from 'react-redux';
import {onClick} from './events/onClick';
import { SharedDrivesScopeBlocker } from '../../components/SharedDrivesScopeBlocker/SharedDrivesScopeBlocker';
import { ISharedDrivesScopeBlockerEvents, ISharedDrivesScopeBlockerFields } from '../../components/SharedDrivesScopeBlocker/types';
import { IApplicationState } from 'app/types/types';
import { CommonAuthManager } from 'app/helper/authorisation/common/CommonAuthManager';
import { GROUP_MEMBER_READ_ALL } from 'app/helper/authorisation/microsoft/oauth/Scopes';

const mapStateToProps = (
    state: IApplicationState,
): ISharedDrivesScopeBlockerFields => {
    const isPermission = CommonAuthManager.checkOneOfScopes(GROUP_MEMBER_READ_ALL);
    return {
        isPermission,
    }
};

const mapDispatchToProps = (
    dispatch: any
): ISharedDrivesScopeBlockerEvents => {
    return {
        onClick: () => dispatch(onClick()),
    }
};

export const SharedDrivesScopeBlockerHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(SharedDrivesScopeBlocker);

SharedDrivesScopeBlockerHOC.displayName = 'SharedDrivesScopeBlockerHOC';
