import { Store } from 'redux';
import { IApplicationState } from '../../../../types/types';
import { AT_ROUTER_SET } from '../../../router/actions/constants';
import { TRouterAction } from '../../../router/actions/types';
import { getActiveCardId } from '../../../router/selectors/getActiveCardId';
import { sendRealTimeVoinOccupy } from '../../../../view/react_components/base/helpers/realTimeHelperTS';
import { dispatch } from '../../../configureStore';
import { getActiveBoardId } from '../../selectors/getActiveBoardId';

export const cardCollaboratorsMiddleware  = (store: Store<IApplicationState>) => (next: any) => (action: TRouterAction) => {
    const boardId = getActiveBoardId(store.getState());
    if (action.type === AT_ROUTER_SET && boardId){
        const prevActiveCardId = getActiveCardId(store.getState());
        const result = next(action);
        const newActiveCardId = getActiveCardId(store.getState());
        if (prevActiveCardId !== newActiveCardId) {
            dispatch(sendRealTimeVoinOccupy(boardId, newActiveCardId, prevActiveCardId));
        }
        return result;
    }
    return next(action)
}
