import { AsidePanelActionsButton } from '../../../../..';
import * as React from 'react';
import { IGanttPanelButtonProps } from './types';
import { CLASS_BOARD_DETAILS_SECTION } from '../../../../constants';
import { BOARD_SECTION_GANTT_BUTTON } from '../../constants';

export function GanttPanelButton ({
    indicatorBoolean,
    isBasicFeature,
    isDisabled,
    tooltip,
    onClick
}: IGanttPanelButtonProps) {

    return (
        <AsidePanelActionsButton
            children={null}
            indicatorBoolean={indicatorBoolean}
            isDisabled={isDisabled}
            isBasicFeature={isBasicFeature}
            sectionSelector={`.${CLASS_BOARD_DETAILS_SECTION}--gantt`}
            title={BOARD_SECTION_GANTT_BUTTON}
            tooltip={tooltip}
            onClick={onClick}
        />
    );
}
