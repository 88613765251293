'use strict';

import * as React from 'react';
import { renderToStaticMarkup } from "react-dom/server";
import { blockMarkdown } from '../../helper/markdownHelper';
import { ERestActivityPropertyActionType } from '../../types/rest/activity/IRestActivityBase';
import { CLASS_CARD_ACTIVITY_ITEM_ADD_VALUE, CLASS_CARD_ACTIVITY_ITEM_REMOVE_VALUE } from '../react_components/aside_panel/boardDetails/activity/TabActivity/components/ActivityItemBody/constants';
import { CLASS_ACTIVITY_ITEM } from '../react_components/aside_panel/boardDetails/activity/TabActivity/components/constants';
import { getActivityItem } from "../react_components/aside_panel/boardDetails/activity/TabActivity/helpers/aсtivityHelper";
import { DefaultContent } from '../react_components/aside_panel/cardDetails/TabActivity/components/CardActivityCheckList/DefaultContent/DefaultContent';
import { RenameContent } from '../react_components/aside_panel/cardDetails/TabActivity/components/CardActivityCheckList/RenameContent/RenameContent';
import ReactHtmlParser from "react-html-parser";

App.Views.CardUpdateChecklistNotification = App.Views.BaseNotification.extend({

    getActions: function() {
        const item = getActivityItem(this.notificationModel);
        const { oldValue, newValue, propertyActionType, checkListProperty, checkListName } = this.notificationModel;
        let content = <DefaultContent label={checkListName || newValue || oldValue} />;

        if (propertyActionType === ERestActivityPropertyActionType.UPDATE) {
            if (checkListProperty === 'name') {
                content = <RenameContent oldValue={oldValue} newValue={newValue}/>
            } else if (checkListProperty === 'weight') {
                content = <>
                    <span className={CLASS_CARD_ACTIVITY_ITEM_REMOVE_VALUE}>{ oldValue }</span>
                    <span className={CLASS_CARD_ACTIVITY_ITEM_ADD_VALUE}>{ newValue }</span>
                    <span className={CLASS_ACTIVITY_ITEM + '__checklist-name'}>
                        {ReactHtmlParser(blockMarkdown(checkListName))}
                    </span>
                </>
            }
        } 
        content = renderToStaticMarkup(content);
        if (propertyActionType === ERestActivityPropertyActionType.DELETE) {
            content = '<strike>' + content + '</strike>';
        }
        
        return {
            bodyRaw: content,
            action: item.label,
            actionData: {
                icon: 'checklist'
            },
        };
    },

    onClick: function(e, notification) {
        var dashboardId = this.notificationModel.dashboardId;
        var cardId = this.notificationModel.card.id;
        App.controller.cleverOpenCard(dashboardId, cardId);
        this.closeNotification(notification);
        e.stopPropagation();
    }
});
