import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Modal } from 'kui';
import { KeyboardShortcuts } from '../../components/keyboardShortcuts/KeyboardShortcuts';
import { KEYBOARD_SHORTCUTS_CLASS, KEYBOARD_SHORTCUTS_SHORTCUT, KEYBOARD_SHORTCUTS_TITLE } from '../../components/keyboardShortcuts/constants';
import { renderToStaticMarkup } from 'react-dom/server';
import { Shortcut } from '../../components/shortcut/Shortcut';

export class KeyboardShortcutsView {
    el: Element;

    constructor () {
        if (this.el) this.remove();
        this.el = document.createElement('div');
        this.el.className = 'keyboard-shortcuts-placeholder';
        document.body.append(this.el);
        this.render();
    }

    render () {
        const titleShortcut = renderToStaticMarkup(
            <div className={KEYBOARD_SHORTCUTS_CLASS + '__shortcut-in-title'}>
                <Shortcut name={''} buttons={KEYBOARD_SHORTCUTS_SHORTCUT}/>
            </div>
        );
        ReactDOM.render(
            <Modal
                title={KEYBOARD_SHORTCUTS_TITLE + ' ' + titleShortcut}
                onClose={() => this.remove()}
            >
                <KeyboardShortcuts />
            </Modal>,
        this.el
    );
    }

    remove () {
        if (!this.el) return;

        ReactDOM.unmountComponentAtNode(this.el);
        this.el.remove();
        this.el = null;
    }
}
