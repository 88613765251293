import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { initCardNotificationsEditedStateEffect } from '../../NotificationEditingPopUp/effects/editingStateEffects';
import { focusNotificationById } from '../../NotificationEditingPopUp/effects/editingPopupStateEffects';
import { popupStatusForCardUpdate } from '../../../state/effects/popupStatusForCardUpdate';

export const onOpenEditingPopup: ActionCreator<ThunkAction> = (
    cardId: number,
    notificationId: number,
) => {
    const action = (
        dispatch: Dispatch
    ) => {
        if (notificationId) {
            dispatch(initCardNotificationsEditedStateEffect(cardId));
            // open editing popup
            dispatch(popupStatusForCardUpdate(cardId, true));
            // focus edited notification
            dispatch(focusNotificationById(notificationId));
        }
    };
    return action;
};
