import { IApplicationState } from '../../../types/types';
import { TDependencyId } from '../../model/dependencies/dependency/types';
import { getRequestsState } from './getRequestsState';

export const getDependencyActiveRequests = (
    state: IApplicationState,
    dependencyId: TDependencyId
): number => {
    const dependencies = getRequestsState(state).dependencies;
    if (!dependencies[dependencyId]) return 0;

    return dependencies[dependencyId].activeRequests;
}
