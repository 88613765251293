import { IApplicationState } from '../../../../../../../types/types';
import { QUALIFICATION_FIRST_QUESTION } from '../../../constants';
import { getNextQuestionKey } from '../../../helpers/getNextQuestionKey';
import { getUserQualificationFormAnswers } from '../../../../../../../store/model/authUser/selectors/getUserQualificationFormAnswers';

export const getFirstQuestionKey = (
    state: IApplicationState
): string => {
    let questionKey = QUALIFICATION_FIRST_QUESTION;
    const answers = getUserQualificationFormAnswers(state);
    if (answers) {
        while (!!answers[questionKey]) {
            const answerKey = answers[questionKey] && answers[questionKey].key ? answers[questionKey].key : '';
            if (!answerKey) {
                break;
            }
            questionKey = getNextQuestionKey(questionKey, answerKey);
        }
    }
    return questionKey;
}
