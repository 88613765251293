import { ISnackbarInfo } from './types';
import { Dispatch } from '../../../../../types/actions';
import { SnackbarVariant } from '../../store/types';
import { SNACKBAR_BUTTON_OK } from '../constants';
import { getDefaultActionEvent } from '../helpers/getDefaultActionEvent';
import { addUpdateSnackbarWithBubble } from '../addUpdateSnackbarWithBubble/addUpdateSnackbarWithBubble';

export const snackbarInfoDefault = (
    options: ISnackbarInfo
) => {
    return (dispatch: Dispatch) => {
        const shouldUseControlsDefault = options.controls === undefined;
        // use controls=[] for snackbar without buttons
        if (shouldUseControlsDefault) {
            options.controls = [{
                action: () => dispatch(getDefaultActionEvent(options.id)),
                title: SNACKBAR_BUTTON_OK
            }];
        }
        dispatch(addUpdateSnackbarWithBubble({
            id: options.id,
            controls: options.controls,
            className: options.className,
            text: options.text,
            title: options.title,
            timer: options.timer,
            variant: SnackbarVariant.INFO
        }));
    }
};
