import { IApplicationState, TActivityId } from '../../../types/types';
import { TActivity } from '../types';

export const getActivity = (
    state: IApplicationState,
    activityId: TActivityId
): TActivity => {
    const activity = state.activities && state.activities[activityId]
    return activity;
}
