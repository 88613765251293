import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../../types/types';
import { updateMyWorkMeta } from '../../../../../../../../../rest/effects/authUser/myWork/updateMyWorkMeta';

export const onResetCounter = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        const boardsUpdatesLastSeen = Date.now();
        dispatch(updateMyWorkMeta({ boardsUpdatesLastSeen }));
    };
    return action;
};
