import { IApplicationState } from '../../../../../../types/types';
import { getBoardPermissionsAllowEdit } from '../../../../../../store/model/selectors/getBoardPermissionsAllowEdit';
import { getMessages } from '../../../../../../store/constants';
import { getActiveBoardId } from '../../../../../../store/model/selectors/getActiveBoardId';
import { getBoard } from '../../../../../../store/model/selectors/getBoardById';
import { getIsAssignedToMeActive } from '../../../../../../store/router/selectors/getIsAssignedToMeActive';
import { clearMarkdownInline } from '../../../../base/helpers/clearMarkdownHelper';
import { getLinkCardId } from '../../../../../../store/router/selectors/getLinkCardId';
import { getCard } from '../../../../../../store/model/selectors/getCard';

export const getKanbanStaticTitle = (
    state: IApplicationState,
): string => {
    const pageTitle = getMessages().getText('page.title');
    const readOnlyString =  ' (read-only)';
    const activeBoardId = getActiveBoardId(state);
    const linkCardId = getLinkCardId(state.router);
    const linkCard = getCard(state, linkCardId);
    let title = pageTitle;
    if (linkCard) {
        title = clearMarkdownInline(linkCard.name) +  ' | ' + title;
    } else if (activeBoardId) { // Если открыли борду, то уже не нужна подсказка
        const canEdit = getBoardPermissionsAllowEdit(state, activeBoardId);
        const board =  getBoard(state, activeBoardId);
        title = clearMarkdownInline(board.name) + (canEdit ? '' : readOnlyString) + ' | ' + title;
    } else if (getIsAssignedToMeActive(state)) {
        title = getMessages().getText('assigned_to_me.title') + ' | ' + title;
    }
    return title;
}
