import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { TTabKey } from '../store/types';
import { IGetState } from '../../../../../types/types';
import { loadDrives } from './loadDrives';
import { PartLoader } from '../../../../../util/part_loader';
import { IRestBoardForOpenDialog } from '../rest/types';
import Util from '../../../../../util/util';
import { TABS } from '../store/constants';
import { getBoardsFromRest } from '../store/boards/helpers/getBoardsFromRest';
import { boardsSet } from '../store/boards/actions/boardsSet';
import { getIsCompleted } from '../store/common/selectors/getIsCompleted';
import { tabLoadStatusSet } from '../store/common/actions/tabLoadstatusSet';
import { processLoadedBoards } from '../helpers/processLoadedBoards';
import { TTabLoadStatus } from '../store/common/types';
import { onLoadBoardsFail } from './onLoadBoardsFail';

export const checkBoardsLoadedByTabKey: ActionCreator<ThunkAction> = (
    tabKey: TTabKey
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (tabKey === TTabKey.templates || tabKey === TTabKey.recent || getIsCompleted(getState(), tabKey)) {
            return Promise.resolve();
        }
        return dispatch(loadDrives())
            .then(() => {
                const loader = new PartLoader<IRestBoardForOpenDialog>();
                const url = TABS[tabKey].urlPreparator ?
                    TABS[tabKey].urlPreparator(getState(), TABS[tabKey].loadUrl)
                    : TABS[tabKey].loadUrl;
                return loader.loadPart(
                    Util.getApiUrl(url)
                );
            })
            .then((restBoards: IRestBoardForOpenDialog[]) => {
                const boards = getBoardsFromRest(restBoards);
                const boardsFilledOpened = processLoadedBoards(getState, boards);
                dispatch(boardsSet(boardsFilledOpened));
                if (TABS[tabKey].onLoadListener) {
                    TABS[tabKey].onLoadListener(getState());
                }
                dispatch(tabLoadStatusSet(tabKey, TTabLoadStatus.COMPLETED));
            })
            .catch(() => {
                dispatch(onLoadBoardsFail())
            });
    };
    return action;
};
