import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { toggleEnabledPushNotifications } from '../effects/toggleEnabledPushNotifications';
import { TBoardId } from '../../../../../../../../../types/types';

export const onChange = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(toggleEnabledPushNotifications(boardId));
    };
    return action;
};
