import { IApplicationState, TBoardId, TPermissionId } from '../../../types/types';
import { getBoardUsers } from './getBoardUsers';
import { ISharedUser } from '../../../types/rest/ISharedUser';

export const getBoardUserByPermissionId = (
    state: IApplicationState,
    boardId: TBoardId,
    permissionId: TPermissionId
): ISharedUser => {
    return getBoardUsers(state, boardId).find(user => user.permissionId === permissionId);
};
