import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../../../../types/types';
import { cardRemoveCardNumber } from '../../../../../../../../rest/effects/card/card/cardRemoveCardNumber';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { getAuthUser } from '../../../../../../../../store/model/authUser/selectors/getAuthUser';
import { showSnoozeBlocker } from '../../../../../../base/effects/showSnoozeBlocker';
import { root } from '../../../../../../../../store/constants';

export const onCardNumberRemove = (
    cardId: TCardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const { allowCardNumber } = getAuthUser(getState());
        if (allowCardNumber) {
            const cardPrefixRemoveCount = root.App.controller.boardCardPrefixInfo.getCardPrefixRemoveCount();
            if (cardPrefixRemoveCount < 3) root.App.controller.boardCardPrefixInfo.increaseCardPrefixRemoveCount();
            dispatch(cardRemoveCardNumber(cardId));
            dispatch(segmentTrackAction(SegmentCardEvent.CARD_PREFIX_DELETED));
        } else {
            dispatch(showSnoozeBlocker({ allow: 'allowCardNumber' }));
        }
    };
    return action;
};
