import { TActivityId, TPermissionId } from '../../../../../../../../types/types';
import { getBoardUsers } from '../../../../../../../../store/model/selectors/getBoardUsers';
import { IApplicationState, TBoardId, TCardId } from '../../../../../../../../types/types';
import { INotifySearchSelectOption } from '../types';
import { createDeepEqualSelector } from '../../../../../../helpers/memoizeHelper';
import { getAuthUser } from '../../../../../../../../store/model/authUser/selectors/getAuthUser';
import { NOTIFY_SELECT_GROUP_BOARD, NOTIFY_SELECT_GROUP_OTHER } from '../constants';
import { IAssignee } from '../../../../../../../../store/model/card/types/IAssignee';
import { getBoardCards } from '../../../../../../../../store/model/selectors/getBoardCards';
import { NOTIFY_SELECT_GROUP } from '../../../constants';
import { dispatch } from '../../../../../../../../store/configureStore';
import { onGroupSelect } from '../events/onGroupSelect';
import { getCardAssignees } from '../../../../../../../../store/model/selectors/getCardAssignees';
import { ISharedUser } from '../../../../../../../../types/rest/ISharedUser';
import { getInitials } from 'app/view/react_components/helpers/userPhotoHelper';

type TgetNotifySearchSelectOptions = (
    state: IApplicationState,
    boardId: TBoardId,
    cardId: TCardId,
    activityId: TActivityId,
) => INotifySearchSelectOption[];

const getBoardUsersSelector = (
    state: IApplicationState,
    boardId: TBoardId,
): ISharedUser[] => {
    const boardUsers = getBoardUsers(state, boardId);
    if (!boardUsers.length) return [];

    const { permissionId: authUserPermissionId } = getAuthUser(state);
    const users: ISharedUser[] = boardUsers
        .reduce((users, user) => {
            if (user.permissionId === authUserPermissionId) return users;
            users.push(user);
            return users;
        }, []) // убрать себя
        .sort((userA, userB) => {
            const getUserName = (user: ISharedUser) => user.fullName ? user.fullName : '';
            return getUserName(userA).localeCompare(getUserName(userB))
        })
    ;

    return users;
};

const getAssigneePermissionIdsSelector = (
    state: IApplicationState,
    boardId: TBoardId,
): TPermissionId[] => {
    const assigneeIds = new Set<TPermissionId>();
    const cards = getBoardCards(state, boardId);
    cards.forEach(card => {
        const assignees = getCardAssignees(state, card.id);
        assignees.forEach(assignee => {
            assigneeIds.add(assignee.sharedUser.permissionId);
        });
    });
    return Array.from(assigneeIds);
};

// const getActivityUserIds = (
//     state: IApplicationState,
//     boardId: TBoardId,
//     cardId: TCardId,
//     activityId: TActivityId,
// ): TPermissionId[] => {
//     const activity = getLoadedCards(state, activityId);
//     if (
//         !activity ||
//         !activity.usersPushNotified ||
//         !activity.usersPushNotified.length
//     ) return [];

//     const userIds = activity.usersPushNotified.map(item => item.permissionId);
//     return userIds;
// };

const mapOptions = (
    users: ISharedUser[],
    group: string = null,
): INotifySearchSelectOption[] => {
    return users.map(user => ({
        img: user.photoUrl,
        initials: getInitials(user),
        text: user.fullName,
        value: user.permissionId,
        description: group, // пусть в description полежит value группы, пока это надо только для сегмента
    }));
};

const getNotifySearchSelectOptionsSelector = (
    boardUsers: ISharedUser[],
    assigneePermissionIds: TPermissionId[],
    // userIds: TPermissionId[], // в первой версии юзеры, которые уже получали, исключались из выбора
): INotifySearchSelectOption[] => {
    let options: INotifySearchSelectOption[] = [];
    const assigneesUserToSelect: ISharedUser[] = [];
    // const assigneeIdsToSelect = userIds.length
    //     ? assigneeIds.filter(item => !userIds.includes(item))
    //     : assigneeIds;

    // const boardUsersToSelect = userIds.length || assigneeIdsToSelect.length
    //     ? boardUsers.filter(item => {
    //         if (userIds.includes(item.permissionId)) return false;
    //         if (assigneeIdsToSelect.includes(item.permissionId)) {
    //             assigneesToSelect.push(item);
    //             return false;
    //         }
    //         return true;
    //     })
    //     : boardUsers;
    const boardUsersToSelect = assigneePermissionIds.length
        ? boardUsers.filter(boardUser => {
            if (assigneePermissionIds.includes(boardUser.permissionId)) {
                assigneesUserToSelect.push(boardUser);
                return false;
            }
            return true;
        })
        : boardUsers;

    if (assigneesUserToSelect.length && boardUsersToSelect.length) { // разбить на группы
        options.push({
            text: NOTIFY_SELECT_GROUP_BOARD,
            options: mapOptions(assigneesUserToSelect, NOTIFY_SELECT_GROUP.BOARD),
            value: NOTIFY_SELECT_GROUP.BOARD,
            onChange: () => dispatch(onGroupSelect(NOTIFY_SELECT_GROUP.BOARD)),
        });
        options.push({
            text: NOTIFY_SELECT_GROUP_OTHER,
            options: mapOptions(boardUsersToSelect, NOTIFY_SELECT_GROUP.OTHER),
            value: NOTIFY_SELECT_GROUP.OTHER,
            onChange: () => dispatch(onGroupSelect(NOTIFY_SELECT_GROUP.OTHER)),
        });
    } else { // без групп
        const toSelect = assigneesUserToSelect.length ? assigneesUserToSelect : boardUsersToSelect;
        options = mapOptions(toSelect);
    }

    return options;
};

export const getNotifySearchSelectOptionsCreateSelector = (
): TgetNotifySearchSelectOptions => createDeepEqualSelector(
    getBoardUsersSelector,
    getAssigneePermissionIdsSelector,
    // getActivityUserIds, // оставляем в поиске и тех, кто уже получил
    getNotifySearchSelectOptionsSelector,
);

export const getNotifySearchSelectOptions: TgetNotifySearchSelectOptions = getNotifySearchSelectOptionsCreateSelector();
