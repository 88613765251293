const category = 'List view';

export const SegmentListViewEvent = {
    OPEN: { category, event: 'List view opened' },
    LIST_VIEW_DISALLOW: { category, event: 'List view toggle clicked (no success)' },
    CHRONOLOGICAL_SORT_TYPE: { category, event: 'Chronological sorting applied in List view' },
    MANUAL_SORT_TYPE: { category, event: 'Manual sorting applied in List view' },
    GROUP_SUB_CARDS_WITH_EPICS_ON: { category, event: 'Group subcards with Epics in List view - on' },
    GROUP_SUB_CARDS_WITH_EPICS_OFF: { category, event: 'Group subcards with Epics in List view - off' },
    COLLAPSE_ALL_GROUPS: { category, event: 'Collapse all groups in List view clicked' },
    EXPAND_ALL_GROUPS: { category, event: 'Expand all groups in List view clicked' },
    PRINT_CLICKED: { category, event: 'Print button clicked on List view' },
    SETTINGS_CLICKED: { category, event: 'Settings button on List view clicked' },
    RESET_SETTINGS_CLICKED: { category, event: 'Reset settings on List view clicked' },
    SORTING_ENABLED: { category, event: 'Sorting on List view applied' },
    SORTING_DISABLED: { category, event: 'Sorting on List view turned off' },
    SORTING_TYPE_ADDED_IN_SETTINGS: { category, event: 'Sorting type added to List view' },
    SORTING_TYPE_REMOVED_FROM_SETTINGS: { category, event: 'Sorting type removed from List view' },
    CARD_ORDER_CHANGED: { category, event: 'Cards order changed in manual type of List view' },
    CARD_CLICKED: { category, event: 'Card opened via List view' },
    ADD_CARD_CLICKED: { category, event: 'Add card button clicked' },
    CARD_CREATED: { category, event: 'Card created' },
    CARD_CREATE_BY_ENTER: { category, event: 'CARD_CREATE_BY_ENTER' },
    CARD_CREATE_BY_CTRL_ENTER: { category, event: 'CARD_CREATE_BY_CTRL_ENTER' },
    CARD_CREATE_BY_ADD_BUTTON: { category, event: 'CARD_CREATE_BY_ADD_BUTTON' },
}

export enum SegmentListViewOption {
    TYPE = 'Type'
}
