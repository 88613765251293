import * as React from 'react';
import { Icon, Tooltip } from 'kui';
import { ECardProgressSize, ICardProgressProps } from './types';
import './_CardProgress.scss';
import { CARD_CONTROL_CLASS } from '../../../../main/kanbanView';
import {
    CARD_PROGRESS_COLOR_BG,
    CARD_PROGRESS_COLOR_BG_HOVER,
    CARD_PROGRESS_COLOR_BG_LIGHT,
    CARD_PROGRESS_COLOR_BG_LIGHT_HOVER,
    CARD_PROGRESS_COLOR_SUCCESS,
    CARD_PROGRESS_COLOR_SUCCESS_DARK,
    CARD_PROGRESS_COLOR_SUCCESS_LIGHT,
    CARD_PROGRESS_DARK_BACKGROUND_COLORS,
    CARD_PROGRESS_LIGHT_BACKGROUND_COLORS,
    CARD_PROGRESS_LIGHT_COLOR_BG,
    CARD_PROGRESS_LIGHT_COLOR_BG_HOVER,
} from './constants';
import { CLASS_GANTT_CARD_CONTROL } from '../../../../gantt/listCard/components/constants';
import { CLASS_LIST_VIEW_CARD_CONTROL } from '../../../../listView/containers/Card/constants';
import { CardProgressLargeCheckIcon } from './CardProgressLargeCheckIcon';

export function CardProgress({
    backgroundColor,
    canDone,
    isLightBgStroke,
    isReadonly,
    progress,
    size = ECardProgressSize.BASE,
    tooltip,
    onClick
}: ICardProgressProps) {
    const className = 'card-progress';
    const classText = className + '__text';

    const [isHover, setHover] = React.useState(null);
    const [isFocus, setFocus] = React.useState(null);
    const [isChange, setChange] = React.useState(null);
    const [value, setValue] = React.useState(progress);

    const initialRef = React.useRef(null);

    const isDone = progress === 100;
    const isEmpty = !progress;
    const isAction = !!onClick && !isReadonly && canDone;

    if (isEmpty) progress = 0;

    const handleClick = () => {
        setFocus(false);
        if (!isReadonly) {
            onClick();
        }
    };

    const circleSize = size === ECardProgressSize.LARGE ? 32 : 24;
    const strokeWidth = 2;
    const radius = (circleSize - strokeWidth) / 2;
    const cx = circleSize / 2;
    const cy = circleSize / 2
    const circumference = radius * Math.PI * 2;
    const dash = (progress * circumference) / 100;

    const isLightBackground = CARD_PROGRESS_LIGHT_BACKGROUND_COLORS.includes(backgroundColor);
    const isDarkBackground = CARD_PROGRESS_DARK_BACKGROUND_COLORS.includes(backgroundColor);

    let bgStroke = isLightBgStroke ? CARD_PROGRESS_LIGHT_COLOR_BG : CARD_PROGRESS_COLOR_BG;
    if (isAction && !isDone && !progress && size === ECardProgressSize.LARGE && (isHover || isFocus)) {
        if (isDarkBackground) {
            bgStroke = CARD_PROGRESS_COLOR_BG_LIGHT_HOVER;
        } else if (isLightBackground) {
            bgStroke = CARD_PROGRESS_LIGHT_COLOR_BG_HOVER;
        } else {
            bgStroke = CARD_PROGRESS_COLOR_BG_HOVER;
        }
    } else {
        if (isDarkBackground) {
            bgStroke = CARD_PROGRESS_COLOR_BG_LIGHT;
        } else {
            bgStroke = CARD_PROGRESS_LIGHT_COLOR_BG;
        }
    }

    let stroke = CARD_PROGRESS_COLOR_SUCCESS;
    if (isDarkBackground) {
        stroke = CARD_PROGRESS_COLOR_SUCCESS_LIGHT;
    } else if (isLightBackground) {
        stroke = CARD_PROGRESS_COLOR_SUCCESS_DARK;
    }

    React.useEffect(() => {
        // не показывать анимацию в первый рендер
        if (initialRef.current) {
            setChange(true);
            setTimeout(() => {
                setValue(progress);
                setChange(false);
            }, 300); // transition length
        }

        initialRef.current = true;
    }, [progress]);

    return (
        <Tooltip
           isNoWrap
            isNoEvents
            value={tooltip}
        >
            <div
                tabIndex={0}
                className={`
                    ${className}
                    ${CARD_CONTROL_CLASS}
                    ${CLASS_GANTT_CARD_CONTROL}
                    ${CLASS_LIST_VIEW_CARD_CONTROL}
                    ${className + '--' + size}
                    ${isLightBackground ? className + '--light-bg' : ''}
                    ${isDarkBackground ? className + '--dark-bg' : ''}
                    ${isEmpty ? className + '--empty' : ''}
                    ${isDone ? className + '--done' : ''}
                    ${isAction ? className + '--action' : ''}
                `}
                onClick={handleClick}
                onMouseOver={() => setHover(true)}
                onMouseOut={() =>setHover(false)}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                onKeyDown={e => {
                    if (e.key === 'Enter') {
                        e.stopPropagation();
                        handleClick();
                    }
                }}
            >
                <svg
                    width={circleSize}
                    height={circleSize}
                    viewBox={`0 0 ${circleSize} ${circleSize}`}
                >
                    <circle
                        fill={'none'}
                        stroke={bgStroke}
                        strokeWidth={`${strokeWidth}px`}
                        cx={cx}
                        cy={cy}
                        r={radius}
                    />
                    <circle
                        fill={'none'}
                        stroke={stroke}
                        cx={cx}
                        cy={cy}
                        r={radius}
                        strokeWidth={`${strokeWidth}px`}
                        transform={`rotate(-90 ${circleSize / 2} ${circleSize / 2})`}
                        //@ts-ignore
                        strokeDasharray={[dash, circumference - dash]}
                    />
                </svg>
                <div
                    className={`
                        ${classText}
                        ${isChange ? classText + '--change' : ''}
                    `}
                >
                    {size === ECardProgressSize.LARGE ?
                        <CardProgressLargeCheckIcon />
                        : <Icon xlink={'done'} size={16} />
                    }
                    <div className={className + '__percent'}>
                        {value}
                    </div>
                </div>
            </div>
        </Tooltip>
    );
}
