import { connect } from 'react-redux';
import { IBoardAttachmentsListHOCProps } from './types';
import { IApplicationState } from '../../../../../../../types/types';
import { IBoardAttachmentsListEvents, IBoardAttachmentsListFields } from '../components/types';
import { BoardAttachmentsList } from '../components/BoardAttachmentsList';
import { getBoardAttachmentsByMonthCreateSelector } from './selectors/getBoardAttachmentsByMonth';
import { onCardOpen } from './events/onCardOpen';
import { getAsidePanelWidth } from '../../../../asidePanel/store/selectors/getAsidePanelWidth';
import { onLinkClick } from './events/onLinkClick';
import { onDownload } from './events/onDownload';
import { onLinkCopy } from './events/onLinkCopy';

const mapStateToProps = () => {
    const getBoardAttachmentsByMonth = getBoardAttachmentsByMonthCreateSelector();

    const _mapStateToProps = (
        state: IApplicationState,
        { boardId, searchQuery, tab, isGridView }: IBoardAttachmentsListHOCProps
    ): IBoardAttachmentsListFields => {
        return {
            asidePanelWidth: getAsidePanelWidth(state),
            attachments: getBoardAttachmentsByMonth(state, boardId, tab, searchQuery),
            isSearch: !!searchQuery,
            isGridView,
            tab
        }
    };

    return _mapStateToProps;
};

const mapDispatchToProps = (
    dispatch: any
): IBoardAttachmentsListEvents => {
    return {
        onCardOpen: (cardId) => dispatch(onCardOpen(cardId)),
        onDownload: (driveId, fileId) => dispatch(onDownload(driveId, fileId)),
        onLinkClick: (event, driveDocId) => dispatch(onLinkClick(event, driveDocId)),
        onLinkCopy: (url) => dispatch(onLinkCopy(url))
    };
};

export const BoardAttachmentsListHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(BoardAttachmentsList);

BoardAttachmentsListHOC.displayName = 'BoardAttachmentsListHOC';
