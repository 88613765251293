import * as React from 'react';
import {
    CLASS_LIST_ITEM,
    CLASS_LIST_ITEM_DRAGGABLE
} from '../../../../../base/components/ListItem/constants';
import {ICardAttachmentGridPreviewProps} from './types';
import './_CardAttachmentGridPreview.scss'
import {CLASS_CARD_DETAILS} from '../../../constants';

export const CardAttachmentGridPreview: React.FC<ICardAttachmentGridPreviewProps> = ({
    actions,
    headerImg,
    img,
    isDoc,
    isDraggable,
    text,
    url,
    ...attributes
}) => {
    const CLASS_CARD_ATTACHMENT = CLASS_CARD_DETAILS + '-attachment';
    let classNameItem = CLASS_LIST_ITEM + ' ' + CLASS_CARD_ATTACHMENT + '--grid';
    if (isDraggable) classNameItem += ' ' + CLASS_LIST_ITEM_DRAGGABLE;
    const classNameColumn = CLASS_LIST_ITEM + '__col';
    const classNameColumnImg = classNameColumn + ' ' + classNameColumn + '--img';
    const classNameColumnText = classNameColumn + ' ' + classNameColumn + '--text';
    const classNameColumnActions = classNameColumn + ' ' + classNameColumn + '--actions';
    const classNameHeader = CLASS_CARD_ATTACHMENT + '__header';
    const classNameHeaderDoc = CLASS_CARD_ATTACHMENT + '__header--doc';
    const classNameFooter = CLASS_CARD_ATTACHMENT + '__footer';

    return (
        <div className={classNameItem} {...attributes}>
            <a
                target="_blank"
                href={url}
                className={`
                    ${classNameHeader}
                    ${isDoc ? classNameHeaderDoc : ''}
                `}
            >
                {headerImg}
            </a>
            <div className={classNameFooter}>
                {!!img &&
                <div className={classNameColumnImg}>
                    {img}
                </div>
                }
                <div className={classNameColumnText}>
                    {text}
                </div>
                <div className={classNameColumnActions}>
                    {actions}
                </div>
            </div>
        </div>
    );
};
