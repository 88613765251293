import { TFilterResultsAction } from '../actions/types';
import {
    AT_CARD_ID_ADD,
    AT_CARD_ID_REMOVE,
    AT_CARD_IDS_SET,
    AT_IGNORE_CARD_ID_ADD,
    AT_IGNORE_CARD_IDS_CLEAR,
    AT_LISTS_SET
} from '../actions/constants';
import { TFilterMatch } from '../types';
import * as deepFreeze from 'deep-freeze';

const initialState: TFilterMatch = {
    cardIds: [],
    lists: {},
    ignoreCardIds: []
};

const _filterMatchReducer = (
    state = initialState,
    action: TFilterResultsAction
): TFilterMatch => {
    switch (action.type) {
        case AT_CARD_IDS_SET: {
            const { cardIds } = action;
            return {
                ...state,
                cardIds
            }
        }
        case AT_CARD_ID_ADD: {
            const { cardId } = action;
            if (state.cardIds.includes(cardId)) return state;

            const cardIds = [...state.cardIds, cardId];
            return {
                ...state,
                cardIds
            }
        }
        case AT_CARD_ID_REMOVE: {
            const { cardId } = action;
            const cardIds = [...state.cardIds];
            const index = cardIds.indexOf(cardId);
            if (index < 0) return state;

            cardIds.splice(index, 1);
            return {
                ...state,
                cardIds
            }
        }
        case AT_LISTS_SET: {
            const { lists } = action;
            return {
                ...state,
                lists
            }
        }
        case AT_IGNORE_CARD_ID_ADD: {
            const { cardId } = action;
            if (state.ignoreCardIds.includes(cardId)) return state;
            return {
                ...state,
                ignoreCardIds: [
                    ...state.ignoreCardIds,
                    cardId
                ]
            }
        }
        case AT_IGNORE_CARD_IDS_CLEAR: {
            return {
                ...state,
                ignoreCardIds: []
            };
        }
        default:
            return state;
    }
};

export const filterMatchReducer = (
    state = initialState,
    action: TFilterResultsAction
): TFilterMatch => {
    // @ts-ignore
    if(window.Settings.development) {
        // @ts-ignore
        return deepFreeze(_filterMatchReducer(state, action))
    } else {
        return _filterMatchReducer(state, action)
    }
};
