import { connect } from 'react-redux';
import { getCard } from '../../../../../../../store/model/selectors/getCard';
import { IApplicationState } from '../../../../../../../types/types';
import { ICardTagsPanelButtonHOCProps } from './types';
import { CardTagsPanelButton } from '../../components/CardTagsPanelButton/CardTagsPanelButton';
import { ICardTagsPanelButtonEvents, ICardTagsPanelButtonFields } from '../../components/CardTagsPanelButton/types';
import { CARD_SECTION_TAGS } from '../../constants';
import { getPanelButtonShow } from '../../../selectors/getPanelButtonShow';
import { onClick } from './events/onClick';

const mapStateToProps = (
    state: IApplicationState,
    { boardId, cardId, isShowTooltip }: ICardTagsPanelButtonHOCProps
): ICardTagsPanelButtonFields => {
    const card = getCard(state, cardId);
    const tagsCount = card.tags ? card.tags.length : 0;
    const isShow = getPanelButtonShow(state, boardId, cardId);
    const tooltip = isShowTooltip
        ? CARD_SECTION_TAGS
        : null;
    return {
        isShow,
        tagsCount,
        tooltip,
    }
};

const mapDispatchToProps = (
    dispatch: any,
    {boardId, cardId, onClick: ownOnClick}: ICardTagsPanelButtonHOCProps
): ICardTagsPanelButtonEvents => {
    return {
        onClick: () => dispatch(onClick(boardId, cardId, ownOnClick))
    }
};

export const CardTagsPanelButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardTagsPanelButton);

CardTagsPanelButtonHOC.displayName = 'CardTagsPanelButtonHOC';
