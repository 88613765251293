import { Dispatch, ThunkAction } from 'app/types/actions';
import { isAsideOpenSetAction } from 'app/view/react_components/main/navigationPanel/store/navigationPanel/actions/isAsideOpenSetAction';
import { IGetState } from '../../../../../../types/types';
import { ENavigationPanelTypes } from '../../../../main/navigationPanel/constants';
import { getCurrentNavigationPanelType } from '../../../../main/navigationPanel/helpers/getCurrentNavigationPanelType';
import { isOpenSetAction } from '../../../../main/navigationPanel/store/navigationPanel/actions/isOpenSetAction';
import { panelTypeSetAction } from '../../../../main/navigationPanel/store/navigationPanel/actions/panelTypeSetAction';
import { addFeatureTrigger } from '../../../../polls/featurePoll/effects/addFeatureTrigger';
import { ERateFeature } from '../../../../../../types/rest/IUserMeta';
import { closePanel } from 'app/view/react_components/aside_panel/asidePanel/effects/closePanel';
import { SegmentProfileBoxEvent, segmentTrackAction } from '../../../../../../middlewares/segment';

export function onOpen (
): ThunkAction {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        dispatch(segmentTrackAction(SegmentProfileBoxEvent.OPEN));
        dispatch(closePanel());
        dispatch(addFeatureTrigger(ERateFeature.NEW_NAV_PANEL));
        const navigationPanelType = getCurrentNavigationPanelType(getState());
        if (navigationPanelType === ENavigationPanelTypes.NP_COMPACT_TYPE) {
            dispatch(panelTypeSetAction(ENavigationPanelTypes.NP_COMPACT_BIG_TYPE));
            dispatch(isOpenSetAction(false));
        }
        dispatch(isAsideOpenSetAction(true));
    };
    return action
};
