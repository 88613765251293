import {Dispatch, ThunkAction} from '../../../../../../../../../types/actions';
import { shareClickedSegmentEvent } from '../../../../../effects/segmentEvents';
import { TBoardId } from '../../../../../../../../../types/types';
import { root } from 'app/store/constants';

export const onClick = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        root.App.controller.mainView.showSharingDialog(boardId);
        dispatch(shareClickedSegmentEvent());
    };
    return action;
};
