import { ThunkAction } from '../../../../../../../../types/actions';
import { deleteAttachment } from '../../../../../../../../rest/effects/card/attacments/deleteAttachment';
import { TDriveDocId } from '../../../../../../../../types/rest/IRestDriveDoc';
import { TCardId } from '../../../../../../../../types/types';

export const onAttachmentDelete = (
    cardId: TCardId,
    driveDocId: TDriveDocId,
): ThunkAction => {
    return (
        dispatch,
    ) => {
        return dispatch(deleteAttachment(cardId, driveDocId));
    }
}
