import { getCard } from '../../../../../../store/model/selectors/getCard';
import { IBoard } from '../../../../../../store/model/board/types';
import { IApplicationState } from '../../../../../../types/types';
import { IList } from '../../../../../../store/model/list/types';
import { getBoardListsByStatus } from '../../../../../../store/model/selectors/getBoardListsByStatus';
import { getListCardIdsByStatus } from '../../../../../../store/model/selectors/getListCardIdsByStatus';
import { getUserRecentBoards } from '../../../../../../store/model/authUser/selectors/getUserRecentBoards';
import { getFullLoadedBoards } from 'app/store/model/selectors/getFullLoadedBoards';
import { NOT_EDITABLE_BOARD_IDS } from '../../../../../../const';

export const getBoardsForMoveTo = (
    state: IApplicationState
): IBoard[] => {
    const boards: IBoard[] = JSON.parse(JSON.stringify(Object.values(getFullLoadedBoards(state))))
        .filter((board: IBoard) => {
            return !NOT_EDITABLE_BOARD_IDS.includes(board.id) && !NOT_EDITABLE_BOARD_IDS.includes(board.copiedFromId);
        });
    boards.forEach((board: IBoard) => {
        board.lists = getBoardListsByStatus(state, Number(board.id))
            .sort((aList, bList) => aList.orderNumber - bList.orderNumber );
        board.lists.forEach((list: IList) => {
            const cardIds = getListCardIdsByStatus(state, list.id);
            list.cards = cardIds.map(cardId => getCard(state, cardId))
                .sort((aCard, bCard) => aCard.orderNumber - bCard.orderNumber );
        })
    });
    getUserRecentBoards(state).forEach(recentBoard => {
        const index = boards.findIndex(b => b.id === recentBoard.id);
        if (index < 0) {
            boards.push({
                ...recentBoard,
                lists: [],
            });
        }
    })
    return boards;
};
