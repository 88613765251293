import { IGetState, TBoardId } from '../../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { updateIndicator } from '../effectts/updateIndicator';
import {
    getBoardCardAgingIndicatorType
} from '../../../../../../../../../store/model/board/selectors/getBoardCardAgingIndicatorType';
import {
    getBoardCardAgingIndicator
} from '../../../../../../../../../store/model/board/selectors/getBoardCardAgingIndicator';
import { SegmentBoardEvent, segmentTrackAction } from '../../../../../../../../../middlewares/segment';
import {
    SegmentBoardOptionStatusValue
} from '../../../../../../../../../middlewares/segment/trackEntities/boardEvents';

export const onToggleIndicator = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const enabled = !getBoardCardAgingIndicator(state, boardId);
        dispatch(updateIndicator(boardId, {
                enabled,
                indicatorType: getBoardCardAgingIndicatorType(getState(), boardId)
        }));
        dispatch(segmentTrackAction(SegmentBoardEvent.CARD_AGING_INDICATOR_CLICKED, {
            name: 'status',
            value: enabled ? SegmentBoardOptionStatusValue.ON : SegmentBoardOptionStatusValue.OFF
        }));
    };
    return action;
};
