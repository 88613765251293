import { IApplicationState, TCardId } from '../../../../../../../types/types';
import { getFilterMatchCardIds } from './getFilterMatchCardIds';

export const getIsCardMatchFilters = (
    state: IApplicationState,
    cardId: TCardId
): boolean => {
    const matchCardIds = getFilterMatchCardIds(state);
    return matchCardIds.includes(cardId);
};
