import {ActionCreator} from 'redux';
import {Dispatch, ThunkAction} from '../../../../../../../types/actions';
import {root} from '../../../../../../../store/constants';

export const openBoardActivity: ActionCreator<ThunkAction> = (
) => {
    const action = (
        dispatch: Dispatch,
    ) => {
        if (!root.App.controller.isRelatedPanelNotOpened()) return;

        root.App.router.navigate(root.App.router.getUrl('activity'), {trigger: true});
    };
    return action;
};
