import { Dispatch, ThunkAction } from '../../../types/actions';
import { IGetState, TBoardId } from '../../../types/types';
import { getBoardLoadForMoveUrl } from '../../constants';
import { Util } from '../../../util/util';
import { IBoard } from '../../../store/model/board/types';
import { fetchHandler } from '../../../util/fetchHandler';
import { getRestHeadersGet } from '../../helpers/getRestHeadersHelper';

export const moveToLoadBoard = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const url = getBoardLoadForMoveUrl(boardId);
        return fetchHandler<IBoard>(
            Util.getApiUrl(url),
            getRestHeadersGet()
        )
    };
    return action;
}
