import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState } from '../../../../../../../types/types';
import { SegmentCardEvent } from '../../../../../../../middlewares/segment/trackEntities/cardEvents';
import { segmentTrackAction } from '../../../../../../../middlewares/segment';
import { openBoardSettings } from '../../../../../base/effects/openBoardSettings';
import { SegmentBoardSettingsSourceValue } from '../../../../../../../middlewares/segment/trackEntities/boardEvents';

export const onOpenSettings = (
    ): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(openBoardSettings('colors', SegmentBoardSettingsSourceValue.CARD_COLOR_TAGS));
        dispatch(segmentTrackAction(SegmentCardEvent.EDIT_COLORS_LINK));
    };
    return action;
};
