import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { TBoardId } from '../../../../../../../../../types/types';
import { removePrefixes } from '../effects/removePrefixes';

export const onRemove = (
    boardId: TBoardId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        return dispatch(removePrefixes(boardId));
    };
    return action;
};
