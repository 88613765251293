import {IFreeVersionButtonProps} from './types';
import {Button, Icon, Modal} from 'kui';
import * as React from 'react';
import './_FreeVersionButton.scss';
import {
    CLASS_FREE_VERSION_BUTTON,
    FREE_VERSION_BUTTON_BUTTON_REQUEST_LICENSE,
    FREE_VERSION_BUTTON_BUTTON_START_TRIAL,
    FREE_VERSION_BUTTON_BUTTON_UPGRADE,
    FREE_VERSION_BUTTON_TEXT,
    FREE_VERSION_MODAL,
} from './constants';
import { Portal } from '../../../../helpers/portal';

export function FreeVersionButton ({
    isLicense,
    startTrial,
    onClickBuy,
    onClickBuyFromModal,
    onClickFreeHelp,
    onClickStartTrial,
    onClickStartTrialModal,
}: IFreeVersionButtonProps) {
    const className = CLASS_FREE_VERSION_BUTTON;
    const classNameHelp = className + '__help';
    const classNameText = className + '__text';
    const classNameButton = className + '__button';
    const classNameModal = className + '__modal';
    const classNameModalHeader = className + '__modal-header';
    const classNameModalBody = className + '__modal-body';
    const classNameModalList = className + '__modal-list';
    const classNameModalLiText = className + '__modal-li-text';
    const classNameModalListTrial = classNameModalList + '--trial';
    const classNameModalListBuy = classNameModalList + '--buy';

    let [modalShow, setModalShow] = React.useState(false);

    function onClickFreeHelpHandler () {
        onClickFreeHelp();
        setModalShow(true);
    }

    function onClickStartTrialModalHandler () {
        onClickStartTrialModal();
        setModalShow(false);
    }

    function onClickBuyHandler () {
        onClickBuyFromModal();
        setModalShow(false);
    }

    return (
        <div className={className}>
            <Button
                className={classNameHelp}
                variant={'icon'}
                onClick={onClickFreeHelpHandler}
            >
                <Icon
                    size={24}
                    xlink={'help'}
                />
            </Button>
            <span
                className={classNameText}
            >
                {FREE_VERSION_BUTTON_TEXT}
            </span>
            {startTrial
                ? <Button
                    className={classNameButton}
                    size={'large'}
                    variant={'primary'}
                    onClick={onClickStartTrial}
                >
                    {FREE_VERSION_BUTTON_BUTTON_START_TRIAL}
                </Button>
                : <Button
                    className={classNameButton}
                    size={'large'}
                    variant={'primary'}
                    onClick={onClickBuy}
                >
                    {isLicense
                        ? FREE_VERSION_BUTTON_BUTTON_REQUEST_LICENSE
                        : FREE_VERSION_BUTTON_BUTTON_UPGRADE
                    }
                </Button>
            }
            {modalShow &&
                <Portal
                    className={classNameModal}
                    selector={'body'}
                >
                    <Modal
                        title={FREE_VERSION_MODAL.title}
                        onClose={() => setModalShow(false)}
                    >
                        <ul className={classNameModalHeader}>
                            {FREE_VERSION_MODAL.header.map(li => (
                                <li key={li}>
                                    <Icon
                                        size={24}
                                        xlink={'status'}
                                    />
                                    <span className={classNameModalLiText}>{li}</span>
                                </li>
                            ))}
                        </ul>
                        <div className={classNameModalBody} >
                            {startTrial &&
                                <div
                                    className={`
                                        ${classNameModalList}
                                        ${classNameModalListTrial}
                                    `}
                                >
                                    <ul>
                                        {FREE_VERSION_MODAL.trial.map(li => (
                                            <li key={li}>
                                                <Icon
                                                    size={24}
                                                    xlink={'progress'}
                                                />
                                                <span className={classNameModalLiText}>{li}</span>
                                            </li>
                                        ))}
                                    </ul>
                                    <Button
                                        size={'large'}
                                        variant={'primary'}
                                        onClick={onClickStartTrialModalHandler}
                                    >
                                        {FREE_VERSION_MODAL.trialButton}
                                    </Button>
                                </div>
                            }
                            <div
                                className={`
                                    ${classNameModalList}
                                    ${classNameModalListBuy}
                                `}
                            >
                                <ul>
                                    {!startTrial && FREE_VERSION_MODAL.buy_after_trial.map(li => (
                                        <li key={li}>
                                            <Icon
                                                size={24}
                                                xlink={'progress'}
                                            />
                                            <span className={classNameModalLiText}>{li}</span>
                                        </li>
                                    ))}
                                    {FREE_VERSION_MODAL.buy.map(li => (
                                        <li key={li}>
                                            <Icon
                                                size={24}
                                                xlink={'progress'}
                                            />
                                            <span className={classNameModalLiText}>{li}</span>
                                        </li>
                                    ))}
                                </ul>
                                <Button
                                    size={'large'}
                                    variant={'primary'}
                                    onClick={onClickBuyHandler}
                                >
                                    {isLicense
                                        ? FREE_VERSION_BUTTON_BUTTON_REQUEST_LICENSE
                                        : FREE_VERSION_MODAL.buyButton
                                    }
                                </Button>
                            </div>
                        </div>
                    </Modal>
                </Portal>
            }
        </div>
    );
};
