import { IGetState, TBoardId } from 'app/types/types';
import { Dispatch, ThunkAction } from 'app/types/actions';
import { boardBackupCreate } from '../effects/boardBackupCreate';
import { IBackupListener } from '../../../components/BoardBackups/types';
import { SegmentBoardEvent, segmentTrackAction } from 'app/middlewares/segment';

export const onBackupCreate = (
    boardId: TBoardId,
    eventListener: IBackupListener
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentBoardEvent.CREATE_NEW_BACKUP_CLICKED));
        dispatch(boardBackupCreate(boardId, eventListener));
    };
    return action;
};
