import * as React from 'react';
import { createRoot, Root } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import { UpdatesTipsHOC } from '../../hocs/UpdatesTipsHOC/UpdatesTipsHOC';
import { IUpdatesItemSlide } from 'app/view/react_components/updates/types';

export class UpdatesTipsView {
    el: Element;
    root: Root;
    store: Store;
    slides: IUpdatesItemSlide[];
    isFirstShow?: boolean;

    constructor (
        store: Store,
        slides: IUpdatesItemSlide[],
        isFirstShow?: boolean,
    ) {
        this.store = store;
        this.slides = slides;
        this.isFirstShow = isFirstShow;
        if (this.el) this.remove();
        this.el = document.createElement('div');
        this.el.className = 'updates-tips-placeholder';
        document.body.append(this.el);
        this.root = createRoot(this.el!);
        this.render();
    }

    render () {
        this.root.render(
            <Provider store={this.store}>
                <UpdatesTipsHOC slides={this.slides} isFirstShow={this.isFirstShow} />
            </Provider>
        );
    }

    remove () {
        if (!this.el) return;

        this.root.unmount();
        this.el.remove();
        this.el = null;
    }
}
