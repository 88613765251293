import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../../types/types';
import { INotificationsListProps } from '../../components/NotificationsList/types';
import { getCardNotificationsBeforeDueDateSelector } from '../../selectors/getCardNotificationsBeforeDueDateSelector';
import { NotificationsList } from '../../components/NotificationsList/NotificationsList';
import { INotificationsListOwnProps } from './types';
import { getNotificationBeforeDueDateEditsPopUpSettingSelector } from '../../selectors/getNotificationBeforeDueDateEditsPopUpSettingSelector';

const mapStateToProps = (
    state: IApplicationState,
    { cardId }: INotificationsListOwnProps
): INotificationsListProps => {
    return {
        notifications: getCardNotificationsBeforeDueDateSelector(state, cardId),
        isPopupOpen: getNotificationBeforeDueDateEditsPopUpSettingSelector(state, cardId),
    };
};

export const NotificationsListHOC = connect(
    mapStateToProps,
)(NotificationsList);

NotificationsListHOC.displayName = 'NotificationsListHOC';
