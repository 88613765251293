import {EMPTY_ARRAY, IApplicationState, TCardId} from '../../../../types/types';
import {IDriveDoc} from '../../card/types/IDriveDoc';
import {ERestDriveDocType} from '../../../../types/rest/IRestDriveDoc';
import {getCard} from '../../selectors/getCard';
import {createSelector} from 'reselect';
import {ICard} from '../../card/types';
import {IDriveDocs} from '../types';
import {getDriveDocs} from './getDriveDocs';
import {root} from '../../../constants';

export const getDriveDocsByCardId = (
    state: IApplicationState,
    cardId: TCardId,
    type: ERestDriveDocType = ERestDriveDocType.CARD,
    main: boolean = undefined,
    isSort: boolean = false
): IDriveDoc[] => {
    return getDriveDocsByCardIdSelector(getCard(state, cardId), getDriveDocs(state), type, main, isSort);
};

export const getDriveDocsByCardIdSelector = (
    card: ICard,
    driveDocs: IDriveDocs,
    type: ERestDriveDocType = ERestDriveDocType.CARD,
    main: boolean,
    isSort: boolean = false
): IDriveDoc[] => {
    if (root._debug.selector) {
        console.log('getDriveDocsByCardIdSelector', card.id);
    }
    if (
        !card ||
        !card.driveDocIds ||
        !card.driveDocIds.length
    ) return EMPTY_ARRAY;

    const driveDocsByCardId = card.driveDocIds.reduce((arr, driveDocId) => {
        const driveDoc = driveDocs[driveDocId];
        if (driveDoc && (!Boolean(type) || !Boolean(driveDoc.type) || driveDoc.type === type)) {
            if (main === undefined || driveDoc.main === main) {
                arr.push(driveDoc);
            }
        }

        if (isSort) {
            return arr.sort((attach1, attach2) => attach1.orderNumber - attach2.orderNumber);
        }
        return arr;
    }, []);
    return driveDocsByCardId.length ? driveDocsByCardId : EMPTY_ARRAY;
};

export const getDriveDocsByCardIdCreateSelector = () => createSelector(
    getCard,
    getDriveDocs,
    (state: IApplicationState, cardId: TCardId, type: ERestDriveDocType) => type,
    (state: IApplicationState, cardId: TCardId, type: ERestDriveDocType, main: boolean) => main,
    (state: IApplicationState, cardId: TCardId, type: ERestDriveDocType, main: boolean, isSort: boolean) => isSort,
    getDriveDocsByCardIdSelector
);
