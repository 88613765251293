import { ISnackbarProgress } from './types';
import { SnackbarVariant, ISnackbarControlModel } from '../../store/types';

export const getSnackbarProgress = (
    options: ISnackbarProgress
) => {
    const controls: ISnackbarControlModel[] = [{
        action: () => undefined,
        progress: options.buttonProgress,
        title: options.buttonTitle ?
            options.buttonTitle :
            options.buttonProgress + '%'
    }];
    return {
        id: options.id,
        controls: controls,
        text: options.text,
        title: options.title,
        variant: SnackbarVariant.PROGRESS
    }
};
