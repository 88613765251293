import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { ICardHOCProps } from '../CardHOC/types';
import { CardBoard } from '../../components/CardBoard/CardBoard';
import { ICardBoardEvents, ICardBoardFields } from '../../components/CardBoard/types';
import { onBoardClick } from './events/onBoardClick';

const mapStateToProps = (
    state: IApplicationState,
    { card }: ICardHOCProps
): ICardBoardFields => {
    return {
        boardName: card.boardName,
    }
}

const mapDispatchToProps = (
    dispatch: any,
    { card }: ICardHOCProps
): ICardBoardEvents => ({
    onBoardClick: () => dispatch(onBoardClick(card.dashboardId)),
});

export const CardBoardHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardBoard);
CardBoardHOC.displayName = 'CardBoardHOC';
