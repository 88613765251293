import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { ICommentHOCProps } from './types';
import { getAuthUser } from '../../../../../../../store/model/authUser/selectors/getAuthUser';
import { ICommentLikesEvents, ICommentLikesFields } from '../../components/CommentLikes/types';
import { onClick } from './events/onClick';
import { CommentLikes } from '../../components/CommentLikes/CommentLikes';
import { getCommentByIdCreateSelector } from './selectors/getCommentLikes';

const mapStateToProps = () => {
    const getCommentLikes = getCommentByIdCreateSelector();

    const _mapStateToProps = (
        state: IApplicationState,
        { boardId, cardId, commentId, isReadonly }: ICommentHOCProps
    ): ICommentLikesFields => {
        return  {
            authUserPermissionId: getAuthUser(state).permissionId,
            commentId,
            likes: getCommentLikes(state, boardId, cardId, commentId),
            isReadonly
        };
    };

    return _mapStateToProps;
};

const mapDispatchToProps = (
    dispatch: any,
    { boardId, cardId, commentId }: ICommentHOCProps
): ICommentLikesEvents => {
    return {
        onClick: () => dispatch(onClick(boardId, cardId, commentId))
    };
};

export const CommentLikesHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CommentLikes);

CommentLikesHOC.displayName = 'CommentLikesHOC';
