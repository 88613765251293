import _ from 'underscore';
import selectAction from './../store/actions/selectAction';
import setCalendars from './setCalendars';

const setFirstAvailable = () => {
    return (dispatch, getState) => {
        const state = getState();
        const disabledActions = state.pushToGoogleCalendar.disabledCategories;
        const availableActions = _.pluck(state.pushToGoogleCalendar.categories, 'id');
        const difference = _.difference(availableActions, disabledActions);
        const firstAvailableAction = difference[0];

        if (firstAvailableAction) {
            dispatch(selectAction(firstAvailableAction));
            dispatch(setCalendars());
        }
    };
};

export default setFirstAvailable;