import * as React from 'react';
import { EQualificationAnswersType, IQualificationDialogProps } from './types';
import './_QualificationDialog.scss';
import {
    QUALIFICATION_BACK,
    QUALIFICATION_COMPLETED_SUBTITLE,
    QUALIFICATION_COMPLETED_TITLE,
    QUALIFICATION_FIRST_QUESTION,
    QUALIFICATION_GET_STARTED,
    QUALIFICATION_HIDE_TIMEOUT, QUALIFICATION_MORE_DETAILS_PROMPT,
    QUALIFICATION_QUESTIONS_SUBTITLE,
    QUALIFICATION_QUESTIONS_TITLE,
    QUALIFICATION_SKIP,
    QUALIFICATION_SKIPPED_SUBTITLE,
    QUALIFICATION_SKIPPED_TITLE
} from '../../constants';
import { ModalPage } from '../../../../base/components/ModalPage';
import { getMessages } from '../../../../../../store/constants';
import { Button, LoaderBlock } from 'kui';
import { getNextQuestionKey } from '../../helpers/getNextQuestionKey';
import { QUALIFICATION_QUESTIONS } from '../../constants/questions';
import { QualificationDialogAnswersRadio } from '../QualificationDialogAnswersRadio/QualificationDialogAnswersRadio';
import { IUserMetaQualificationAnswer } from '../../../../../../types/rest/IUserMeta';
import { QualificationDialogAnswersText } from '../QualificationDialogAnswersText/QualificationDialogAnswersText';
import { QualificationDialogAnswersCheckbox } from '../QualificationDialogAnswersCheckbox/QualificationDialogAnswersCheckbox';
import ReactHtmlParser from 'react-html-parser';
import { UserpicStorage } from 'app/view/react_components/base/components/UserpicStorage/UserpicStorage';

export function QualificationDialog ({
    fullName,
    initials,
    permissionId,
    photoUrl,
    firstQuestionKey,
    onDidMount,
    onAnswer,
    onCompleted,
    onSkipped,
    onGetStarted,
    onShowCalendar
}: IQualificationDialogProps) {
    const className = 'qualification-dialog';
    const classBody = className + '__body';
    const classBodyItem = classBody + '-item';
    const classSection = className + '__section';
    const classSectionHeader = classSection + '-header';
    const classSectionBody = classSection + '-body';
    const classSubtitle = className + '__sub-title';

    const [questionKey, setQuestionKey] = React.useState(firstQuestionKey || QUALIFICATION_FIRST_QUESTION);
    const [prevQuestionKey, setPrevQuestionKey] = React.useState(null); // Только для эффекта перехода к следующему вопросу, рисуется поверх текущего и скрывается постепенно
    const [skipped, setSkipped] = React.useState(false);
    const [completed, setCompleted] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);

    const goToQuestion = (
        key: string
    ) => {
        setPrevQuestionKey(questionKey);
        setQuestionKey(key);
        setTimeout(() => {
            setPrevQuestionKey(null);
        }, QUALIFICATION_HIDE_TIMEOUT)
    }

    const onSkipHandler = () => {
        onSkipped();
        setSkipped(true);
        setTimeout(() => {
            setQuestionKey(null);
        }, QUALIFICATION_HIDE_TIMEOUT)
    }

    const questionsScreen = !completed && !skipped;

    const getBackQuestionKey = (): string => {
        let result = null;
        Object.keys(QUALIFICATION_QUESTIONS).forEach(qKey => {
            const question = QUALIFICATION_QUESTIONS[qKey];
            const possibleNextQuestions = [];
            if (question.nextQuestion) possibleNextQuestions.push(question.nextQuestion);
            Object.values(question.answers).forEach(answer => {
                if (answer.nextQuestion) possibleNextQuestions.push(answer.nextQuestion);
            })
            if (possibleNextQuestions.includes(questionKey)) {
                result = qKey;
            }
        })
        return result;
    }
    const backKey = questionsScreen && getBackQuestionKey();

    const onBack = () => {
        if (backKey) {
            onAnswer(backKey, null);
            goToQuestion(backKey);
        }
    }

    const answerHandler = (answer: IUserMetaQualificationAnswer) => {
        setLoading(true);
        onAnswer(questionKey, answer)
            .then(() => {
                const nextQuestion = getNextQuestionKey(questionKey, answer.key);
                if (nextQuestion) {
                    setTimeout(() => {
                        goToQuestion(nextQuestion);
                        setLoading(false);
                    }, 500);
                } else {
                    setLoading(false);
                    setTimeout(() => {
                        onCompleted();
                    }, 1000);
                    setCompleted(true);
                    setTimeout(() => {
                        setQuestionKey(null);
                    }, QUALIFICATION_HIDE_TIMEOUT)
                }
            });
    }
    const onFreeTextSave = (text: string) => {
        const question = QUALIFICATION_QUESTIONS[questionKey];
        const answerKeys = question.answers ? Object.keys(question.answers) : [];
        if (answerKeys.length) {
            const answer: IUserMetaQualificationAnswer = {
                key: answerKeys[0],
                text
            }
            answerHandler(answer);
        }
    }

    return (
        <ModalPage
            title={getMessages().getText('qualification.welcome', null, fullName)}
            onDidMount={onDidMount}
            className={className}
            showBackButton={false}
            rightContent={
                <UserpicStorage
                    initials={initials}
                    src={photoUrl}
                    size={40}
                    userId={permissionId}
                />
            }
        >
            <div className={classBody}>
                {isLoading && <LoaderBlock/>}
                {!questionsScreen &&
                    <div className={classBodyItem}>
                        <div className={className + '__title'}>{skipped ? QUALIFICATION_SKIPPED_TITLE : QUALIFICATION_COMPLETED_TITLE}</div>
                        <div className={classSubtitle}>
                            {ReactHtmlParser(skipped ? QUALIFICATION_SKIPPED_SUBTITLE : QUALIFICATION_COMPLETED_SUBTITLE)}
                        </div>
                        <Button
                            size={'large'}
                            className={className + '__get-started'}
                            onClick={onGetStarted}
                        >
                            {QUALIFICATION_GET_STARTED}
                        </Button>
                    </div>
                }
                <div className={`
                    ${classBodyItem}
                    ${!questionsScreen ? classBodyItem + '--prev-screen' : ''}
                `}>
                    <div className={className + '__title'}>{QUALIFICATION_QUESTIONS_TITLE}</div>
                    <div className={`
                        ${classSubtitle}
                        ${classSubtitle + '--double-trial'}
                    `}
                    >
                        {QUALIFICATION_QUESTIONS_SUBTITLE}
                    </div>
                    <div className={className + '__questions'}>
                        {
                            [questionKey, prevQuestionKey].map((questionKeyToRender, index) => {
                                if (!questionKeyToRender) return null;
                                const question = QUALIFICATION_QUESTIONS[questionKeyToRender];
                                return (
                                    <div
                                        className={`
                                            ${classSection}
                                            ${index === 1 ? classSection + '--prev-screen' : ''}
                                        `}
                                        key={questionKeyToRender + index}
                                    >
                                        <div className={classSectionHeader}>
                                            {question.question}
                                        </div>
                                        <div className={classSectionBody}>
                                            {question.answersType === EQualificationAnswersType.FREE_TEXT &&
                                            <QualificationDialogAnswersText
                                                questionKey={questionKeyToRender}
                                                showSaveButton={true}
                                                onSave={ onFreeTextSave }
                                            />
                                            }
                                            {question.answersType === EQualificationAnswersType.CHECKBOXES &&
                                            <QualificationDialogAnswersCheckbox
                                                questionKey={questionKeyToRender}
                                                onAnswer={answerHandler}
                                                onShowCalendar={onShowCalendar}
                                            />
                                            }
                                            {(!question.answersType || question.answersType === EQualificationAnswersType.RADIO_SELECT) &&
                                            <QualificationDialogAnswersRadio
                                                key={questionKeyToRender + index}
                                                questionKey={questionKeyToRender}
                                                onAnswer={answerHandler}
                                            />
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className={className + '__footer'}>
                        <Button
                            variant={'icon-text'}
                            text={QUALIFICATION_SKIP}
                            onClick={onSkipHandler}
                        />
                        {backKey &&
                        <Button
                            variant={'icon-text'}
                            text={QUALIFICATION_BACK}
                            onClick={onBack}
                        />
                        }
                    </div>
                </div>
            </div>
        </ModalPage>
    );
}
