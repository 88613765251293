import { IApplicationState, TBoardId, TCardAssigneeRoleId } from '../../../types/types';
import { IBoard } from '../board/types';
import { ICardAssigneeRole } from '../../../types/model';
import { getBoardRoleById } from '../board/selectors/getBoardRoleById';

export const getRoleForRollback = (
    state: IApplicationState,
    boardId: TBoardId,
    roleId: TCardAssigneeRoleId,
    patchRole: ICardAssigneeRole
): ICardAssigneeRole => {
    const role = getBoardRoleById(state, boardId, roleId);
    if (!patchRole || !role) return;
    let colorForRollback: IBoard = {};

    for (const field in patchRole) {
        const fieldKey = field as keyof ICardAssigneeRole;
        colorForRollback = {
            ...colorForRollback,
            [fieldKey]: role[fieldKey]
        };
    }

    return JSON.parse(JSON.stringify(colorForRollback));
}
