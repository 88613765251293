import { ISegmentEventOption, TSegmentTrackPayload } from '../../trackEntities/types';
import { SEGMENT_ACTION, SEGMENT_TRACK } from '../../constants';
import { ISegmentTrackAction } from './types';

export const segmentTrackAction = (
    track: TSegmentTrackPayload,
    option?: ISegmentEventOption,
    additionalOptions?: ISegmentEventOption[]
): ISegmentTrackAction => ({
    type: SEGMENT_ACTION,
    case: SEGMENT_TRACK,
    track,
    option,
    additionalOptions
});
