import * as React from 'react';
import { ICardChecklistProps } from './types';
import { CLASS_CARD_DETAILS, CLASS_CARD_DETAILS_SECTION } from '../../../constants';
import './_CardChecklist.scss';
import { IChecklistStats } from '../../../../../../../types/model';
import { CardChecklistAddHOC } from '../../hocs/CardChecklistAddHOC/CardChecklistAddHOC';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { Droppable } from 'react-beautiful-dnd';
import { CardChecklistItems } from '../CardChecklistItems/CardChecklistItems';
import { Button, Icon, Input, LoaderBlock, Tooltip } from 'kui';
import { getMessages } from '../../../../../../../store/constants';
import {
    CARD_CHECKLIST_DONE, CARD_CHECKLIST_HIDE_DONE,
    CARD_CHECKLIST_ITEM_ADD_TOOLTIP,
    CARD_CHECKLIST_SAVE_BUTTON,
    CARD_CHECKLIST_SHOW_DONE
} from './constants';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { CardChecklistActionsHOC } from '../../hocs/CardChecklistActionsHOC/CardChecklistActionsHOC';
import { escape } from 'underscore';
import {
    CHECKLIST_DEFAULT_ID,
    CHECKLIST_DEFAULT_NAME
} from '../../../../../../../store/model/checklists/checklists/constants';
import { CARD_SECTION_CHECKLIST } from '../CardChecklists/constants';
import { getAsidePanelTooltip } from '../../../../asidePanel/helpers/getAsidePanelTooltip';
import { AnalyzeRender } from '../../../../../helpers/memoizeHelper';

//@ts-ignore
const analyzeRender = window.Settings.development ? new AnalyzeRender(`CardChecklist`) : null;

export function CardChecklist({
    checklist,
    checklistItems,
    isArchive,
    isHideDoneChecklistItems,
    isOpened,
    isSingle,
    sectionType,
    onAddOpen,
    onAddClose,
    onDelete,
    onNameChange
}: ICardChecklistProps) {
    const { boardId, cardId } = React.useContext(AsidePanelContext);
    const { id, name } = checklist;

    if (analyzeRender) analyzeRender.call(`CardChecklist ${id}`);

    const isReadonly = sectionType === ESettingsSectionType.READONLY;

    const stats: IChecklistStats = {
        checkedCount: 0,
        checkedPercent: 0,
        totalCount: 0,
        estimate: 0,
    };

    if (checklistItems) {
        stats.totalCount = checklistItems.length;
        let weightTotal = 0;
        let weightChecked = 0;
        checklistItems.forEach(item => {
            weightTotal += item.weight;
            if (item.checked) {
                stats.checkedCount++;
                weightChecked += item.weight;
            }
            stats.estimate += item.estimate || 0;
        });
        if (weightTotal) stats.checkedPercent = Math.round(weightChecked * 100 / weightTotal);
    }

    const className = CLASS_CARD_DETAILS + '__checklist';
    const classHeader = className + '-header';
    const classTitle = classHeader + '-title';

    const [isLoading, setLoading] = React.useState(null);
    const [isAddOpened, setAddOpened] = React.useState(null);
    const [isEditing, setEditing] = React.useState(null);
    const [value, setValue] = React.useState(name);
    const [isHideDone, setHideDone] = React.useState(null);

    const inputRef = React.useRef(null);

    const onCancel = () => {
        if (isSingle || checklistItems && checklistItems.length) {
            return;
        }
        if (isOpened) {
            onDelete();
        }
    };

    const onAddClick = () => {
        setAddOpened(!isAddOpened);
    };

    const onClick = () => {
        if (isEditing || isReadonly) return;
        onRename();
    };

    const onChange = (e: React.SyntheticEvent) => {
        const { value } = e.target as HTMLInputElement;
        setValue(value);
    };

    const onSave = () => {
        setEditing(false);
        if (value && value.trim()) {
            if (id === CHECKLIST_DEFAULT_ID) {
                setLoading(true);
            }
            onNameChange(value).finally(() => {
                if (id === CHECKLIST_DEFAULT_ID) {
                    setLoading(false);
                }
            });
        }
    };

    const onRename = () => {
        setEditing(true);
        if (name.includes(CHECKLIST_DEFAULT_NAME) || name === CARD_SECTION_CHECKLIST) {
            setTimeout(() => {
                inputRef.current.getInput().select();
            }, 0);
        }
    };

    const onEscape = (e: React.KeyboardEvent) => {
        if (e.key === 'Escape') {
            e.preventDefault();
            e.stopPropagation();
            setEditing(false);
        }
    };

    const isDone = stats && stats.checkedCount === stats.totalCount;

    React.useEffect(() => {
        setAddOpened(isOpened);
    }, [isOpened]);

    React.useEffect(() => {
        setHideDone(isHideDoneChecklistItems);
    }, [isHideDoneChecklistItems]);

    const nameEl = <div
        className={classTitle}
        onClick={onClick}
    >
        {isEditing ?
            <>
                <Input
                    className={classTitle + '-input'}
                    value={value}
                    ref={inputRef}
                    autosize={false}
                    autoFocus
                    onChange={onChange}
                    onKeyDown={onEscape}
                    onEnter={onSave}
                    onBlur={onSave}
                />
                {value &&
                    <Button
                        className={classTitle + '-save'}
                        onClick={onSave}
                    >
                        {CARD_CHECKLIST_SAVE_BUTTON}
                    </Button>
                }
            </>
            : <>
                <div className={classTitle + '-name'}>
                    {name}
                </div>
                {stats && !!stats.totalCount &&
                    <div className={classTitle + '-counter'}>
                        {isDone
                            ? <>
                                <Icon
                                    className={classTitle + '-counter-done'}
                                    size={16}
                                    xlink={'done-circle'}
                                /> ({stats.checkedCount} / {stats.totalCount})
                            </>
                            : getMessages().getText(CARD_CHECKLIST_DONE, null, stats.checkedPercent, stats.checkedCount, stats.totalCount)
                        }
                    </div>
                }
            </>
        }
    </div>;

    return (
        <Droppable droppableId={String(id)}>
            {(provided, snapshot) => (
                <div
                    className={`
                        ${className}
                        ${isReadonly ? className + '--readonly' : ''}
                        ${CLASS_CARD_DETAILS_SECTION + '--checklist'}
                        ${CLASS_CARD_DETAILS_SECTION + '--checklist' + id}
                    `}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                >
                    {isLoading && <LoaderBlock />}
                    <div className={CLASS_CARD_DETAILS_SECTION + '__placeholder'} />
                    {!isSingle &&
                        <div className={classHeader}>
                            {isEditing ? nameEl
                                : <Tooltip {...getAsidePanelTooltip({ value: escape(name) })}>{nameEl}</Tooltip>}
                            {!isReadonly &&
                                <div className={classHeader + '-actions'}>
                                    <CardChecklistActionsHOC
                                        cardId={cardId}
                                        checklistId={id}
                                        onRename={onRename}
                                        onDelete={onDelete}
                                    />
                                    <Button
                                        variant={'icon'}
                                        onClick={onAddClick}
                                        tooltip={getAsidePanelTooltip({ value: CARD_CHECKLIST_ITEM_ADD_TOOLTIP })}
                                    >
                                        <Icon xlink={'plus'} size={24} />
                                    </Button>
                                </div>
                            }
                        </div>
                    }
                    {checklistItems && !!checklistItems.length &&
                        <CardChecklistItems
                            checklistItems={checklistItems}
                            sectionType={sectionType}
                            placeholder={provided.placeholder}
                            checklistId={id}
                            isArchive={isArchive}
                            isDraggingOver={snapshot.isDraggingOver}
                            isHideDoneChecklistItems={isHideDone}
                        />
                    }
                    {!checklistItems && snapshot.isDraggingOver ? provided.placeholder : null}
                    {!isReadonly && isHideDoneChecklistItems && checklistItems && !!checklistItems.length && !!checklistItems.find(item => item.checked) &&
                        <Button
                            className={className + '-done-button'}
                            onClick={() => setHideDone(!isHideDone)}
                            variant={'icon-text'}
                            text={isHideDone ? CARD_CHECKLIST_SHOW_DONE : CARD_CHECKLIST_HIDE_DONE}
                        >
                            <Icon xlink={isHideDone ? 'arrow-s-down' : 'arrow-s-up'} size={16} />
                        </Button>
                    }
                    {!isReadonly &&
                        <CardChecklistAddHOC
                            boardId={boardId}
                            cardId={cardId}
                            checklistId={id}
                            isOpened={isAddOpened}
                            onAddOpen={onAddOpen}
                            onAddClose={() => {
                                setAddOpened(false);
                                onAddClose();
                            }}
                            onCancel={onCancel}
                        />
                    }
                </div>
            )}
        </Droppable>
    );
};
