import { createRoot } from 'react-dom/client';
import {Provider} from "react-redux";
import React from "react";
import {UpgradePlanButtonHOC} from "../react_components/main/upgradeButton/hocs/UpgradePlanButtonHOC/UpgradePlanButtonHOC";

App.Views.UpgradePlanButton = App.Views.BaseView.extend({

    initialize: function (options) {
        this.root = createRoot(this.el);
        this.store = options.store;
    },

    render: function () {
        this.root.render(
            <Provider store={this.store}>
                <UpgradePlanButtonHOC/>
            </Provider>
        );

        return this;
    },

    remove: function () {
        this.root.unmount();
        Backbone.View.prototype.remove.call(this);
    }
});

export default App.Views.UpgradePlanButton;
