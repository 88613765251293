export interface ICardPriorityFields {
    priority: number;
    isReadonly?: boolean;
    isTemplates?: boolean;
    listId?: string;
    portal?: boolean;
    variant?: ECardPriorityVariant;
}

export interface ICardPriorityEvents {
    onChange: (priority: string) => void;
    onDropDownOpen: () => void;
}

export interface ICardPriorityProps extends
    ICardPriorityFields,
    ICardPriorityEvents
{}

export enum ECardPriorityVariant {
    SMALL = 'small',
    BASE = 'base'
}
