import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../../../../types/types';
import { getCard } from '../../../../../../../../store/model/selectors/getCard';
import { cardToggleWatch } from '../../../../../../../../rest/effects/card/card/cardToggleWatch';
import {
    pushNotificationAddScope
} from '../../../../../../../../rest/effects/pushNotificationSettings/pushNotificationAddScope';
import { getBoardIdByCardId } from '../../../../../../../../store/model/selectors/getBoardIdByCardId';
import { EPushNotificationSettingsScope } from '../../../../../../../../types/rest/IRestPushNotificationSettings';

export const toggleCardWatch: ActionCreator<ThunkAction> = (
    cardId: TCardId
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const card = getCard(state, cardId);
        if (!card) return;
        if (!card.wtch) {
            dispatch(pushNotificationAddScope(getBoardIdByCardId(state, cardId), EPushNotificationSettingsScope.WATCH));
        }
        dispatch(cardToggleWatch(cardId));
    };
    return action;
};
