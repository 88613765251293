'use strict';
import store, { dispatch, getAppState } from "../../store/configureStore";
import {
    SegmentInAppNotificationEvent,
    SegmentNotificationsEvent,
    segmentTrackAction
} from "../../middlewares/segment";

import notifyInformerTemplate from './templates/notifyInformer.html';
import { getActiveBoardId } from '../../store/model/selectors/getActiveBoardId';
import { getAuthUser } from '../../store/model/authUser/selectors/getAuthUser';
import { openBoardSettings } from '../react_components/base/effects/openBoardSettings';
import {
    openBoardActivity
} from "../react_components/main/navigationPanel/hocs/NavigationPanelTabItemHOC/effects/openBoardActivity";
import * as _ from 'underscore';
import { pushNotificationMarkAllRead } from "app/rest/effects/pushNotification/pushNotificationMarkAllRead";
import { GOOGLE_SPACING } from "../../const";
import { updateUser } from "app/store/model/authUser/effects/updateUser";
import {
    getNavigationPanelIsAsideOpened
} from "app/view/react_components/main/navigationPanel/store/selectors/getNavigationPanelIsAsideOpened";
import { NAVIGATION_PANEL_CONTAINER_CLASS } from "app/view/react_components/main/navigationPanel/constants";
import { SegmentBoardSettingsSourceValue } from "app/middlewares/segment/trackEntities/boardEvents";
import { StaredNotifyHelper } from "app/view/main/stared_notify_helper";
import {
    getUserStarredNotificationsGrouped
} from "../../store/model/authUser/selectors/getUserStarredNotificationsGrouped";
import {
    authUserSetStarredNotificationsGrouped
} from "../../rest/effects/authUser/authUserSetStarredNotificationsGrouped";

const NotificationType = Object.freeze({
    ALL_BOARD:   0,
    CURRENT_BOARD:  1,
    STARRED_CARD: 2
});

export const NotifyInformer = App.Views.NotifyInformer = Backbone.View.extend({
    className: 'topbar-notifications hidden',
    events: {
        'click .notifications-informer': 'panelClick',
        'click .notification-list__item': 'plateClick',
        'click .topbar-notifications__tabs-tab': 'onToggleFilter',
        'click .topbar-notifications__filter-switch-item': 'onToggleReadFilter',
        'click .topbar-notifications__filter-button--read': 'markAllRead',
        'click .topbar-notifications__help': 'onClickSettingsButton',
        'click .notification-empty__faq a': 'onClickHowToLink',
        'click .topbar-notifications__actions-button': 'onToggleActions',
        'click .topbar-notifications__actions-action--group': 'onToggleGroup',
        'click .topbar-notifications__actions-action--unstar': 'onUnstarAll',
    },

    refreshNotifyTimerInterval: 1000 * 60 * 15, // 15 min
    timeFilterFromAge: 60 * 15,
    timeFilterToAge: 60 * 1,
    openGroupId: null,
    fetchLoading: false,
    scrollTopWhenNewNotificationHidden: 40,
    newUnread:0, // колво новых сообщений, полученных реалтайм, котрые изза скрола ты не видишь.

    notificationsViews : [],
    notificationsGroupViews : [],
    currentNotificationType : NotificationType.ALL_BOARD,
    readFilterActive : false,
    staredNotifyHelper: false,

    template: _.template( notifyInformerTemplate ),

    initialize: function(options) {
        if (getAuthUser(getAppState()).anonym){
            return;
        }
        _.bindAll(this, 'onScroll');
        window._testt = this.staredNotifyHelper = new StaredNotifyHelper();
        this.collection = new App.Collections.Notifications([], {isFilterActive: false, isReadFilterActive: this.readFilterActive});
        App.vent.on(App.vent['body:clicked'], this.bodyClicked, this);
        //App.vent.on(App.vent['aside-panel:opened'], this.closePanel, this);//TODO hO_ock
        App.vent.on(App.vent["notify:send"], this.onNotifySend, this);
        this.listenTo(this.collection, 'reset', _.debounce(this.onResetCollection, 200));
        this.listenTo(this.staredNotifyHelper, App.vent["staredNotifyHelper:reset"],this.onStarredResetCollection);
        this.listenTo(this.collection, 'remove', _.debounce(this.onRemoveFromCollection, 200));
        this.listenTo(this.collection, 'change:seen', _.debounce(this.updateView, 100));
        this.listenTo(this.collection, 'change:seen', _.debounce(this.decreaseCounter, 100));
        this.loadDeliveredNotificationIds();
        this.checkUndeliveredNotificationsTimer = this.startCheckUndeliveredNotificationsTimer();
        this.deliveredNotificationIds = new Set();

    },

    render: function() {
        if (getAuthUser(getAppState()).anonym){
            return;
        }
        this.toggleBell();
        this.$el.html(this.template({
            title: Messages.getText('noty.title'),
            mark: Messages.getText('noty.mark.all_as_read'),
            deleteText: Messages.getText('noty.mark.delete_read'),
            settingsTitle: Messages.getText('noty.settings'),
            filter: {
                all: Messages.getText('noty.filter_all'),
                board: Messages.getText('noty.filter_board'),
                starred: Messages.getText('noty.filter_starred'),
                read: Messages.getText('noty.filter_read'),
                unread: Messages.getText('noty.filter_unread'),
            },
            readFilterActive: this.readFilterActive,
            count: this.getCounter() < 100 ? this.getCounter() : '99+',
            currentNotificationType: this.currentNotificationType,
            NotificationType
        }));
        this.$el.find('.dropdown__body').on('scroll', this.onScroll);
        this.panelClick();
        return this;
    },

    onHideCurrentTabIfNeeded: function() {
        if (!this.isStarredActive()) {
            this.collection.refreshFilterActive(this.isCurrenBoardActive());
        }
        const isNoActiveBoard = !getActiveBoardId(getAppState());
        if (this.isCurrenBoardActive() && !getActiveBoardId(getAppState())) {
            this.currentNotificationType = NotificationType.ALL_BOARD;
            this.$el.find('.topbar-notifications__tabs-slider').addClass('topbar-notifications__tabs-slider--0');
        }
        this.$el.find('.topbar-notifications__tabs-tab--all').text(Messages.getText(isNoActiveBoard ? 'noty.filter_all.notifications' : 'noty.filter_all'));
        this.$el.find('.topbar-notifications__tabs').toggleClass('topbar-notifications__tabs--no-current', !getActiveBoardId(getAppState()));
    },

    bodyClicked: function(e){
        if (
            !$(e.target).hasClass('topbar-notifications__actions-dropdown') &&
            !$(e.target).hasClass('topbar-notifications__actions-button')
        ) {
            this.$el.find('.topbar-notifications__actions').removeClass('topbar-notifications__actions--opened');
        }
        if (getNavigationPanelIsAsideOpened(getAppState())) {
            this.onHideCurrentTabIfNeeded();
            const ignore = `.notifications__button, .notifications `;
            if ($(e.target).closest(ignore).length) {
                return;
            }
            if ($(e.target).closest('.'+NAVIGATION_PANEL_CONTAINER_CLASS).length) {
                const el = document.querySelector("#navigation-aside-portal > div.portal.notifications__dropdown.notifications__dropdown--opened > div > div.notifications__header > button");
                if (el) {
                    el.click();
                }
            }
        }

    },

    onClickSettingsButton(e) {
        e.preventDefault();
        this.closePanel();


        dispatch(segmentTrackAction(SegmentInAppNotificationEvent.CLICKED_NOTIFICATION_SETTINGS));
        setTimeout(() => { // подождём main_view handleClick надо с ним разобраться :/
            dispatch(openBoardSettings('notifications', SegmentBoardSettingsSourceValue.NOTIFICATIONS));
        }, 100);
    },

    onClickHowToLink() {
       dispatch(segmentTrackAction(SegmentInAppNotificationEvent.CLICKED_HOW_IT_WORKS_LINK))
    },

    onToggleActions() {
        this.updateActions();
        this.updateGroupClasses()
        this.$el.find('.topbar-notifications__actions').toggleClass('topbar-notifications__actions--opened');
        if (this.$el.find('.topbar-notifications__actions').hasClass('topbar-notifications__actions--opened')) {
            this.$el.find('.topbar-notifications__actions-dropdown').trigger('focus');
        }
    },

    updateActions() {
        const isGrouped = getUserStarredNotificationsGrouped(getAppState());
        this.$el.find('.topbar-notifications__actions-action--group use').attr('href', isGrouped ? '#svgicon--columns' : '#svgicon--kui-card');
        this.$el.find('.topbar-notifications__actions-action--group .topbar-notifications__actions-action-text').text(Messages.getText(isGrouped ? 'noty.ungroup' : 'noty.group'));
    },

    onToggleGroup() {
        if (getUserStarredNotificationsGrouped(getAppState())) {
            dispatch(segmentTrackAction(SegmentInAppNotificationEvent.UNGROUP_STARRED));
        } else {
            dispatch(segmentTrackAction(SegmentInAppNotificationEvent.GROUP_STARRED));
        }
        dispatch(authUserSetStarredNotificationsGrouped(!getUserStarredNotificationsGrouped(getAppState())));
        // надо перерендерить, чтобы отделить группы
        this.clearNotifications();
        this.renderNotifications();
    },

    onUnstarAll() {
        this.staredNotifyHelper.unStarredAllNotifications().then(()=> {
            this.clearNotifications();
            this.updateView();
            dispatch(segmentTrackAction(SegmentInAppNotificationEvent.UNSTAR_ALL));
        });
    },

    updateHeader: function() {
        this.toggleFiller();
        this.toggleHeaderFilterRead();
        this.toggleHeaderFilterDelete();
        this.updateGroupsClasses();
    },

    panelClick: function(e) {
        this.hideFiller();
        var flag = !this.getPanelOpen();
        this.togglePanel(flag);
        if (flag) {
            this.loadNew(this.$el.find('.dropdown__body'));
        }
    },

    onToggleReadFilter: function(e) {
        var target = this.$el.find(e.target);
        if (!target.hasClass('topbar-notifications__filter-switch-item') || target.hasClass('active')) return;
        this.$el.find('.topbar-notifications__filter-switch-item.active').removeClass('active').attr('aria-selected', false);
        target.addClass('active').attr('aria-selected', true);
        this.$el.find('.topbar-notifications__filter-button-container').toggleClass('active');

        this.readFilterActive = !this.readFilterActive;
        this.collection.refreshReadFilterActive(this.readFilterActive);

        this.clearNotifications(); // пытаюсь починить: после AssigneTo где-то обнуляется notificationsViews
        this.loadNew(this.$el.find('.dropdown__body'));

        this.$el.find('.notification-list').toggleClass('active');
        this.dispatchReadFilterSegmentEvents(target);
        this.openGroupId = null;

        this.toggleFiller();
    },

    onToggleFilter: function(e) {
        var target = this.$el.find(e.target);
        if (!target.hasClass('topbar-notifications__tabs-tab') || target.hasClass('active')) return;
        this.$el.find('.topbar-notifications__tabs-tab.active').removeClass('active').attr('aria-selected', false);
        this.$el.find('.topbar-notifications__tabs-slider').removeClass('topbar-notifications__tabs-slider--0');
        this.$el.find('.topbar-notifications__tabs-slider').removeClass('topbar-notifications__tabs-slider--1');
        this.$el.find('.topbar-notifications__tabs-slider').removeClass('topbar-notifications__tabs-slider--2');
        target.addClass('active').attr('aria-selected', true);
        if (this.$el.find('.topbar-notifications__tabs-tab--starred').hasClass('active')) {
            this.currentNotificationType = NotificationType.STARRED_CARD;
            this.$el.find('.topbar-notifications__tabs-slider').addClass('topbar-notifications__tabs-slider--2');
        } else if (this.$el.find('.topbar-notifications__tabs-tab--board').hasClass('active')) {
            this.currentNotificationType = NotificationType.CURRENT_BOARD;
            this.$el.find('.topbar-notifications__tabs-slider').addClass('topbar-notifications__tabs-slider--1');
        } else {
            this.currentNotificationType = NotificationType.ALL_BOARD;
            this.$el.find('.topbar-notifications__tabs-slider').addClass('topbar-notifications__tabs-slider--0');
        }

        this.hideReadUnreadFilterIfNeeded();

        this.collection.refreshFilterActive(this.isCurrenBoardActive());

        this.clearNotifications(); // пытаюсь починить: после AssigneTo где-то обнуляется notificationsViews
        this.updateNotifications();

        this.loadNew(this.$el.find('.dropdown__body'));
        this.dispatchFilterSegmentEvents(target);
        this.openGroupId = null;
    },

    hideReadUnreadFilterIfNeeded() {
        const isStarred = this.currentNotificationType === NotificationType.STARRED_CARD;
        this.$el.find('.topbar-notifications__header').toggleClass('topbar-notifications__header--starred', isStarred);
        this.$el.find('.dropdown__body').toggleClass('dropdown__body--starred', isStarred);
    },

    dispatchFilterSegmentEvents(target) {
        let segmentEvent = SegmentInAppNotificationEvent.CLICKED_ALL;
        if (this.currentNotificationType === NotificationType.CURRENT_BOARD) {
            segmentEvent = SegmentInAppNotificationEvent.CLICKED_CURRENT_BOARD
        } else if (this.currentNotificationType === NotificationType.STARRED_CARD) {
            segmentEvent = SegmentInAppNotificationEvent.STARRED_TAB_CLICKED;
        }
        dispatch(segmentTrackAction(segmentEvent));
    },

    dispatchReadFilterSegmentEvents(target) {
        let segmentEvent = SegmentInAppNotificationEvent.CLICKED_UNREAD;
        if (target[0].title === Messages.getText('noty.filter_read')) {
            segmentEvent = SegmentInAppNotificationEvent.CLICKED_READ
        }
        dispatch(segmentTrackAction(segmentEvent));
    },

    markAllRead: function() {
        if (!this.$el.find('.topbar-notifications__filter-button--read').hasClass('disabled')) {
            const ids =[];
            this.collection.each( item => {
                if (!item.get('seen')) {
                    ids.push(item.get('id'));
                }
            })
            dispatch(pushNotificationMarkAllRead(ids))
                .then(() => {
                    this.readAllNotifications();
                 //   this.resetCounter();
                })
                .then(()=>{
                    this.clearNotifications();
                    this.loadMore(this.$el.find('.dropdown__body'))
                })
                .then(()=>{
                    this.toggleHeaderFilterRead();
                })
                .then(()=>{
                    this.setUnseenNotificationCount(this.getCounter() - ids.length);
                })

            ;

            App.controller.trackEvent(
                Messages.getText('ga.category.notifications'),
                Messages.getText('ga.action.notifications.mark_all_read')
            );
            dispatch(segmentTrackAction(SegmentInAppNotificationEvent.CLICKED_MARK_ALL_AS_READ));
        }
    },

    readAllNotifications: function() {
        this.collection.invoke('set', {seen: true});
        this.updateView();
    },

    updateNotifications: function() {
        App.controller.trackEvent(
            Messages.getText('ga.category.notifications'),
            Messages.getText('ga.action.notifications.filter_active'),
            "Toggle state (enabled/disabled)",
            +this.isCurrenBoardActive() // +true = 1
        );
        App.controller.trackEvent(
            Messages.getText('ga.category.notifications'),
            Messages.getText('ga.action.notifications.filter_active'),
            "Toggle state (enabled/disabled)",
            +this.isCurrenBoardActive()
        );
    },

    toggleBell: function() {
        this.$el.toggleClass('hidden', getAuthUser(getAppState()).anonym);
    },

    isAllBoardActive: function(){
        return this.currentNotificationType === NotificationType.ALL_BOARD;
    },

    isCurrenBoardActive: function() {
        return this.currentNotificationType === NotificationType.CURRENT_BOARD;
    },

    isStarredActive: function() {
        return this.currentNotificationType === NotificationType.STARRED_CARD;
    },

    clearNotifications: function() {
        this.$el.find('.dropdown__body').removeClass('dropdown__body--full');
        this.$el.find('.notification-list').html(' ');

        this.notificationsViews.forEach((view) => view.remove());
        this.notificationsViews = [];

        this.notificationsGroupViews.forEach((view) => view.remove());
        this.notificationsGroupViews = [];

        if (this.collection) {
            this.collection.each(function (model) {
                model.set('rendered', false);
            }, this);
            this.collection.reset(null,{silent:true});
        }
        this.staredNotifyHelper.collection.each((model) => {
            model.set('rendered', false);
        })

    },

    getVisibleCount: function() {
        if (this.isStarredActive()) {
            return this.staredNotifyHelper.collection.length;
        } else if (this.readFilterActive) {
            return this.collection.getReadNotificationsCount();
        } else {
            return this.getUnreadNotificationsViewCount();
        }
    },

    hideFiller: function () {
        this.$el.find('.notification-empty:not(.hidden)').addClass('hidden');
        this.$el.find('.dropdown__body').removeClass('empty');
    },

    toggleFiller: function () {
        this.hideFiller();
        this.$el.find('.dropdown__body').toggleClass('empty', !Boolean(this.getVisibleCount()));
        if (this.isStarredActive()) {
            $('.notification-empty--starred').toggleClass('hidden', Boolean(this.getVisibleCount()));
        } else if (this.isCurrenBoardActive()) {
            $('.notification-empty--board').toggleClass('hidden', Boolean(this.getVisibleCount()));
            const link = this.$el.find('.notification-empty__link');
            if (link.length) {
                link[0].onclick = function onClick() {
                    dispatch(openBoardSettings('notifications', SegmentBoardSettingsSourceValue.NOTIFICATIONS));
                    dispatch(segmentTrackAction(SegmentNotificationsEvent.APP_NOTIFICATION_CHANGE_SETTINGS_CLICKED));
                }
            }
            const button = this.$el.find('.notification-empty__button');
            if (button.length) {
                button[0].onclick = function onClick() {
                    const boardId = getActiveBoardId(store.getState());
                    dispatch(openBoardActivity(boardId));
                    dispatch(segmentTrackAction(SegmentNotificationsEvent.APP_NOTIFICATION_VIEW_ACTIVITY_CLICKED));
                }
            }
        } else {
            if (this.readFilterActive) {
                this.$el.find('.notification-empty--read').toggleClass('hidden', Boolean(this.getVisibleCount()));
            } else {
                this.$el.find('.notification-empty--unread').toggleClass('hidden', Boolean(this.getVisibleCount()));
            }
        }
    },

    toggleHeaderFilterRead: function() {
        if (!this.$el.find('.topbar-notifications__filter-button--read')) return;
        this.$el.find('.topbar-notifications__filter-button--read').toggleClass('disabled', Boolean(this.collection.length == this.collection.where({seen: true}).length));
    },

    toggleHeaderFilterDelete: function() {
        if (!this.$el.find('.topbar-notifications__filter-button--delete')) return;
        this.$el.find('.topbar-notifications__filter-button--delete').toggleClass('disabled', Boolean(!this.collection.where({seen: true}).length));
    },

    onRemoveFromCollection: function (e) {
        if (!this.getVisibleCount()) {
            this.clearNotifications();
            this.loadMore(this.$el.find('.dropdown__body'))
        }
    },

    onStarredResetCollection: function(e) {
        if (this.isStarredActive()) {
            this.clearNotifications();
            this.renderNotifications();
        }
        this.updateHeader();
    },

    onResetCollection: function(e) {
        this.renderNotifications();
        this.updateHeader();
    },

    renderNotifications: function() {
        this.$el.find('.dropdown__body').removeClass('dropdown__body--loading');
        if (this.isStarredActive()){
            return this.renderStarredNotifications();
        }
        let oldModelsMaxTime = 0;
        this.collection.each(function(model) {
            if (model.get('rendered')) {
                const time = this.getNotifyTime(model);
                if (time > oldModelsMaxTime) {
                    oldModelsMaxTime = time;
                }
            }
        }, this);

        let newModels = [];
        this.collection.each(function(model) {
            if (!model.get('rendered')) {
                if (this.getNotifyTime(model) <= oldModelsMaxTime) {
                    this.addNotification(model);
                } else {
                    newModels.push(model);
                }
            }
        }, this);
        newModels.sort((model1, model2) => {
            return this.getNotifyTime(model1) - this.getNotifyTime(model2);
        })
        newModels.forEach((model) => {
            this.addNotification(model, true);
        })

    },

    renderStarredNotifications: function() {
        this.staredNotifyHelper.collection.each((model) => {
            this.addNotification(model, true);
        })
    },


    getNotifyTime: function(notify) {
        var json = notify.get ? notify.get('modelHistoryJson') : false;
        if (!json) return 0;
        const model = notify.getNotificationModel(0);
        return model.time;
    },

    getGroupView: function(options) {
        return new App.Views.NotificationGroup({
            ...options,
            isOpened: this.isStarredActive() &&  getUserStarredNotificationsGrouped(getAppState()),
            // setOpenedGroup: (groupId) => this.openGroupId = groupId,
            staredNotifyHelper: this.staredNotifyHelper,
            notifyInformer: this,
        });
    },

    /***
     * Показываем ли нотификации в зависимости от выбранных фильтров
     * @param modelBoardId
     * @param modelId
     * @returns {boolean}
     */
    isShowGroup: function(modelBoardId, modelId){
        const boardId = getActiveBoardId(store.getState());
        if (this.isCurrenBoardActive() && (!boardId || boardId && boardId !== modelBoardId)) {
            return false;
        }
        return true;
    },


    isMathWithFilters: function(model){
        const boardId = getActiveBoardId(store.getState());
        if (this.isCurrenBoardActive() && (!boardId || boardId && boardId !== model.getNotificationModel(0).dashboardId)) {
            return false;
        }
        if (this.readFilterActive){
            if (!model.get('seen')){
                return false;
            }
        }  else {
            if (model.get('seen')){
                return false;
            }
        }
        return true;
    },


    updateGroupClasses: function(group) {
        group = $(group);
        if (!this.isShowGroup(group.data('board'), group.data('card'))) {
            group.addClass('notification-group--no');
            return;
        }
        var count = group.find('.notification-list__item.animation_height_in').length;
        if (!count) {
            group.addClass('notification-group--no');
            return;
        }
        group.removeClass('notification-group--no');
        var unread = group.find('.notification-list__item--unread').length;
        this.updateGroupStarred(group);
        if (!unread) {
            group.addClass('notification-group--read');
        } else {
            group.removeClass('notification-group--read');
        }
        if (count > 1) {
            group.removeClass('notification-group--one');
        } else {
            group.addClass('notification-group--one');
        }
        const isGrouped = this.isStarredActive() &&  getUserStarredNotificationsGrouped(getAppState());
        if (isGrouped) {
            group.addClass('notification-group--opened');
        } else {
            group.removeClass('notification-group--opened');
        }
        group.find('.notification-group__star-icon use').attr('href', isGrouped && count > 1 ? '#svgicon--multi-star' : '#svgicon--kui-star');
        if (isGrouped && count > 1) {
            group.find('.notification-group__star-tooltip').html(Messages.getText('noty.groups.unstar.all'));
        }
    },

    updateGroupStarred: function(group) {
        group = $(group);
        const starred = this.staredNotifyHelper.isStarredNotification(group.data('notificationid'));
        if (starred) {
            group.addClass('notification-group--starred');
        } else {
            group.removeClass('notification-group--starred');
        }
    },

    updateGroupsClasses: function() {
        var self = this;
        this.$el.find('.notification-group').each(function () {
            self.updateGroupClasses(this);
        });
    },

    updateGroupsStarred: function() {
        var self = this;
        this.$el.find('.notification-group').each(function () {
            self.updateGroupStarred(this);
        });
    },

    hideSeen: function(){
        if (this.readFilterActive){
            return;
        }
        this.$el.find('.notification-group').each( function(){
            let e =  $(this);
            if (e.hasClass('notification-group--read')) {
                e.addClass('notification-group--read--hide');
            }
        });
        this.toggleFiller();
    },

    getUnreadNotificationsViewCount: function(){
        let count = this.$el.find('.notification-group:not(.notification-group--read--hide)').length;
        return count;
    },




    addNotification: function(model, isNew) {
        if (model.get('rendered')) {
            return;
        }
        if (!this.isShowGroup(model.getNotificationModel(0).dashboardId))
            return;
        var factory = App.controller.getNotificationHelper().getNotificationFactory();
        var view = factory.getInstance(model);
        if (!view) {
            return;
        }
        this.notificationsViews.push(view);
        var boardId = model.getNotificationModel(0).dashboardId,
            cardId = model.getNotificationModel(0).modelId,
            list = this.$el.find('.notification-list'),
            groupView = this.getGroupView({model: model}),
            portion = model.attributes.portion,
            filterGroup = '[data-card="' + cardId + '"]';
        filterGroup += (portion) ? '[data-portion="' + portion + '"]' : '';
        let groupEnabled  = this.isStarredActive() &&  getUserStarredNotificationsGrouped(getAppState());
        let group = groupEnabled ? list.find(filterGroup) : []; // группы вкл тоько для старед табы
        if (!group.length ) {
            group = $(groupView.render().el);
            this.notificationsGroupViews.push(groupView);
            if (isNew) {
                list.prepend(group);
            } else {
                list.append(group);
            }
            let notificationId = model.getNotificationId();
            group.attr('data-card', cardId).attr('data-board', boardId).attr('data-notificationid', notificationId);
            if (portion) group.attr('data-portion', portion);
        } else {
            group = $(group).first();
            if (isNew) { // проверим, изменилось ли имя доски, листа, карты
                var groupContent = groupView.getContent(),
                    groupBoardName = group.find('.notification__board_name').first(),
                    groupListName = group.find('.notification__list_name').first(),
                    groupCardName = group.find('.notification__card_name').first();
                group.data('card', cardId);
                if (group.data('board') !== boardId) {
                    group.data('board', boardId);
                }
                if (groupBoardName.text() !== groupContent.boardName) {
                    groupBoardName.text(groupContent.boardName).attr('title', groupContent.boardName);
                }
                if (groupListName.text() !== groupContent.listName) {
                    groupListName.text(groupContent.listName).attr('title', groupContent.listName);
                }
                if (groupCardName.text() !== groupContent.cardName) {
                    groupCardName.text(groupContent.cardName).attr('title', groupContent.cardName);
                }
            }
        }
        var groupList = $(group).find('.notification-group__list'),
            item = view.renderPlate().$elPlate;
        if (isNew) {
            groupList.prepend(item);
            group.prependTo(list);
            this.updateGroupClasses(group);
        } else {
            groupList.append(item);
        }
        model.set('rendered', true);
    },

    setNewUnread: function(newUnread){
        this.newUnread = newUnread;

    },
    newNotification: function(model) {
        if (this.scrollTop > this.scrollTopWhenNewNotificationHidden){
            this.setNewUnread(this.newUnread+1);
        }
        this.collection.add(model);
        this.addNotification(model, true);
        this.toggleFiller();
        this.toggleHeaderFilterRead();
        this.toggleHeaderFilterDelete();
        if (!this.getPanelOpen()) {
            this.$el.find('.dropdown__body').scrollTop(0);
        }
    },

    isNotificationExist: function(model) {
        return this.collection.findWhere({id: model.get('id')});
    },

    /**
     * Получает количество не просмотренных
     */
    getCounter: function() {
        return getAuthUser(getAppState()).unseenNotificationCount || 0;
    },

    decreaseCounter: function(){
        let count = this.getCounter() - 1;
        count = count< 0 ? 0: count;
        this.setUnseenNotificationCount(count);
    },

    increaseCounter: function(){
        this.setUnseenNotificationCount(this.getCounter() + 1);
    },


    getPanelOpen: function() {
        return this.isPanelOpen;
    },

    updateView: function(model, flag) {
        this.notificationsViews.forEach(function(view) {
            if (view.model == model) {
                view.renderPlate();
            }
        }, this);
        this.updateStarredTab();
        this.updateGroupsClasses();
        this.toggleFiller();
    },

    updateStarredTab: function() {
        this.$el.find('.topbar-notifications__tabs-tab--starred').toggleClass('.topbar-notifications__tabs-tab--starred-w-actions', this.staredNotifyHelper.collection.length > 1);
    },

    updateUnreadAllCounter: function() {
        if (this.isCurrenBoardActive() || this.readFilterActive || this.isStarredActive()){
            return;
        }
        this.setUnseenNotificationCount( this.collection.getUnreadNotificationsCount() + (this.collection.hasMore()? 1 : 0));
    },

    /***
     * обновляем только в сторе, на бек ничего не шлем
     * @param count
     */
    setUnseenNotificationCount: function (count){
        dispatch(updateUser({
            unseenNotificationCount: count > 0 ? count : 0
        }));
    },

    plateClick: function(e) {
        if (e) {
            var view = $(e.currentTarget).data('backbone-view');
            view.trigger('panelClick', e);
            this.togglePanel(false);
        }
    },

    onScroll: function(e) {
        const readFilterHeight = GOOGLE_SPACING * 7;
        var $db = this.$el.find('.dropdown__body');
        var $nl = this.$el.find('.dropdown__body .notification-list');
        const currentScroll = $db.scrollTop();
        const isScrollUp = this.scrollTop > currentScroll;
        if (isScrollUp) {
            this.$el.find('.topbar-notifications__filter').toggleClass('topbar-notifications__filter--hidden', false);
        } else {
            this.$el.find('.topbar-notifications__filter').toggleClass('topbar-notifications__filter--hidden', $db.scrollTop() >= readFilterHeight);
        }
        this.scrollTop = $db.scrollTop();
        const paddingY = GOOGLE_SPACING * 12.25; // switch read/unread + loader + padding
        if (Math.floor($db.scrollTop() + $db.outerHeight() - paddingY) >= Math.floor($nl.height()) && !$db.hasClass('dropdown__body--loading')) {
            this.loadMore($db);
        }
        if (this.scrollTop < this.scrollTopWhenNewNotificationHidden){
            this.setNewUnread(0);
        }
    },

    loadNew: function($db) {
        return this.load($db, false)
            .then(()=>{
                this.updateUnreadAllCounter();

            });
    },

    loadMore: function($db) {
        return this.load($db, true);
    },

    load: function($db, more) {
        if (this.isStarredActive()){
            return this.loadStarred($db, more);

        }
        if (more ) {
            if (this.fetchLoading) {
                return new Promise(()=>{});
            }
            if (!this.collection.hasMore()) {
                $db.addClass('dropdown__body--full');
                return new Promise(()=>{});
            }
        }
        this.fetchLoading = true;
        $db.addClass('dropdown__body--loading');
        return new Promise( (resolve, reject) =>{
            this.collection.fetch({
                success: _.bind(function() {
                    this.updateHeader();
                    setTimeout(() => {
                        this.fetchLoading = false;
                        resolve();
                    }, 200);
                }, this)
            });
        })
    },

    loadStarred: function($db, more){
        if(more){
            $db.addClass('dropdown__body--full');
            return new Promise(()=>{});
        }
        this.fetchLoading = true;
        $db.addClass('dropdown__body--loading');
        return this.staredNotifyHelper.load().then(
            () => {
                this.updateHeader();
                this.fetchLoading = false;
                this.onResetCollection();
            }
        );
    },

    closePanel: function(e) {
        if (e) {
            var target = $(e.target);
            if (!target.closest('.topbar-notifications').length && !target.hasClass('topbar-notifications')) {
                this.togglePanel(false);
            }
        } else if (typeof (e) == "undefined") {//TODO hO_ock
            this.togglePanel(false);
        }
    },

    /**
     * Инверсивное значение для исчезноввения надо прислать тру
     * @param flag
     */
    togglePanel: function(flag) {
        if (!this.getPanelOpen() && !flag) return;

        this.$el.find('.dropdown--notification').toggleClass('dropdown--open', flag);
        this.$el.find('.notifications-informer').toggleClass('notifications-informer--open', flag);
        this.$el.find('.dropdown__body').toggleClass('empty', !Boolean(this.getVisibleCount()));
        if (flag) {
            this.isPanelOpen = true;
        } else {
            this.isPanelOpen = false;
            this.openGroupId = null;
        }
    },

    toggleHelp: function() {
        const activeBoard = getActiveBoardId(store.getState());
        if (!activeBoard) {
            this.$el.find('.topbar-notifications__help').addClass('hidden');
        } else {
            this.$el.find('.topbar-notifications__help').removeClass('hidden');
        }
    },

    remove: function() {
        App.vent.off(null, null, this);
        this.$el.find('.dropdown__body').off('scroll');
        this.clearNotifications();
        if (this.checkUndeliveredNotificationsTimer) {
            clearInterval(this.checkUndeliveredNotificationsTimer);
            this.checkUndeliveredNotificationsTimer = null;
        }

        return Backbone.View.prototype.remove.call(this);
    },

    startCheckUndeliveredNotificationsTimer: function() {
        return setInterval(() => {
            this.checkUndeliveredNotifications();
        }, this.refreshNotifyTimerInterval);
    },

    loadDeliveredNotificationIds: function() {
        let lastNotifyCollection = new App.Collections.NotificationsTimeFilter([], {
            timeFilter: {
                fromTimeAge: this.timeFilterFromAge,
                toTimeAge: this.timeFilterToAge
            }
        });
        lastNotifyCollection.fetch({success: () => {
                lastNotifyCollection.forEach((item) => {
                    this.deliveredNotificationIds.add(item.get('id'));
                })
        }});
    },

    checkUndeliveredNotifications: function() {
        let lastNotifyCollection = new App.Collections.NotificationsTimeFilter([], {
            timeFilter: {
                fromTimeAge: this.timeFilterFromAge,
                toTimeAge: this.timeFilterToAge
            }
        });
        let hasUndelivered = false;
        lastNotifyCollection.fetch({success: () => {
                lastNotifyCollection.comparator = 'time';
                lastNotifyCollection.sort();
                lastNotifyCollection.forEach((item) => {
                    const undelivered = this.onNotifySend(item);
                    hasUndelivered = hasUndelivered || undelivered;
                })
            }});
        if (hasUndelivered){
            dispatch(segmentTrackAction(SegmentInAppNotificationEvent.UNDELIVERED_NOTIFICATIONS_SHOWN));
        }
    },

    onNotifySend: function(item) {
        const factory = App.controller.getNotificationHelper().getNotificationFactory();
        // check if we can render this Notification
        let view = factory.getInstance(item);
        if (!view) {
            return;
        }

        if (!this.isNotificationExist(item )){
            if (this.isMathWithFilters(item)) {
                this.newNotification(item);
            }
        } else {
            this.collection.add(item, {merge: true});
        }
        if (!this.deliveredNotificationIds.has(item.get('id'))) {
            this.deliveredNotificationIds.add(item.get('id'));
            this.increaseCounter();
            return true;
        }
        return false;
    }
});
