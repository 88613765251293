import { IChecklistAddAction } from './types';
import { AT_CHECKLIST_ADD } from './constants';
import { IChecklist } from '../../checklist/types';

export const checklistAddAction = (
    checklist: IChecklist
): IChecklistAddAction => {
    return {
        type: AT_CHECKLIST_ADD,
        checklist
    }
};
