import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId, TCustomPropertyId } from '../../../../../../../../types/types';
import { togglePin } from '../effects/togglePin';

export const onTogglePin = (
    cardId: TCardId,
    customPropertyId: TCustomPropertyId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(togglePin(cardId, customPropertyId));
    };
    return action;
};
