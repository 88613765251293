import { TActivityId, TCardId } from '../../../../../types/types';

export interface IAsidePanelState {
    activityId?: TActivityId; // last activity for force notifications
    entityPanelWidth: number,
    buttonsPanelType?: EButtonsPanelType,
    ganttContextCardId: TCardId; // запихал сюда, чтобы не создавать под него отедльный редюсер
    isGoogleFileCreating?: boolean;
    isMobile?: boolean;
    isPrint?: boolean;
    tabKey?: string;
    leftPanelOpened?: boolean;
    width?: number;
    //commentsPanelType?: 'none' | 'normal' // пока не делаем
}

export enum EButtonsPanelType {
    MINIMIZED = 'MINIMIZED',
    NORMAL = 'NORMAL'
}
