import {
    IAssigneesSearchSelectEvents,
    IAssigneesSearchSelectFields,
} from '../../components/AssigneesSearchSelect/types';
import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { IAssigneesSearchSelectHOCOwnProps } from './types';
import { onToggle } from './events/onToggle';
import { onInputKeyDown } from './events/onInputKeyDown';
import { AssigneesSearchSelect } from '../../components/AssigneesSearchSelect/AssigneesSearchSelect';
import { getAssigneesSearchSelectOptionsCreateSelector } from './selectors/getAssigneesSearchSelectOptions';
import { isAllowShare } from '../../../../../../../store/model/selectors/isAllowShare';

const mapStateToProps = () => {
    const getAssigneesSearchSelectOptions = getAssigneesSearchSelectOptionsCreateSelector();

    const _mapStateToProps = (
        state: IApplicationState,
        {
            boardId,
            cardIds,
            isMultiselect,
            isPopup,
            isOpened,
        }: IAssigneesSearchSelectHOCOwnProps,
    ): IAssigneesSearchSelectFields => {
        const availableElements = getAssigneesSearchSelectOptions(state, boardId, cardIds);
        const isShowShareButton = isAllowShare(state, boardId);

        return {
            availableElements,
            isMultiselect,
            isOpened,
            isPopup,
            isShowShareButton,
        }
    };
    return _mapStateToProps;
};

const mapDispatchToProps = (
    dispatch: any,
    { boardId, cardIds, onAddClosed }: IAssigneesSearchSelectHOCOwnProps,
): IAssigneesSearchSelectEvents => {
    return {
        onAddClosed,
        onSelect: (permissionId) => dispatch(onToggle(boardId, cardIds, permissionId)),
        onInputKeyDown: () => dispatch(onInputKeyDown()),
    }
};

export const AssigneesSearchSelectHOC = connect(
    mapStateToProps,
    mapDispatchToProps,
)(AssigneesSearchSelect);

AssigneesSearchSelectHOC.displayName = 'AssigneesSearchSelectHOC';
