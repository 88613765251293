import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState, TCardId, TTag } from '../../../../types/types';
import { ICards } from '../../../../store/model/cards/types';
import { cardsRestPatch } from './api/helper/cardsRestPatch';

export const cardSetTags = (
    cardId: TCardId,
    tags: TTag[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const cards: ICards = {
            [cardId]: {
                tags
            }
        };

        return dispatch(cardsRestPatch(cards));
    };
    return action;
};
