import * as React from 'react';
import { createRoot, Root } from 'react-dom/client';
import {
    BLOCKER_MODAL_CONTAINER,
    CLASS_BLOCKER_MODAL,
    CLASS_CONTAINER_BLOCKED,
} from './constants';
import './_BlockerModal.scss';
import { IBlockerModalProps } from './types';
import { Modal } from 'kui';

export class BlockerModal {
    constructor ({
        classNameBlocked = CLASS_CONTAINER_BLOCKED,
        container = null,
        text,
        title,
    }: IBlockerModalProps) {
        this.classNameBlocked = classNameBlocked;
        this.page = document.querySelector(BLOCKER_MODAL_CONTAINER);
        this.container = container || this.page;
        this.text = text;
        this.title = title;

        if (this.element) this.remove();
        this.element = document.createElement('div');
        this.element.classList.add(CLASS_BLOCKER_MODAL);
        if (this.container) this.container.append(this.element);
        if (this.page) this.page.classList.add(this.classNameBlocked);
        this.root = createRoot(this.element!);
    }

    classNameBlocked: string;
    container: Element;
    element: Element;
    page: Element;
    root: Root;
    text: string;
    title: string;

    render () {
        return this.root.render(
            <Blocker
                text={this.text}
                title={this.title}
            />
        );
    }

    update ({
        text,
        title,
    }: IBlockerModalProps) {
        this.text = text;
        this.title = title;
        return this.root.render(
            <Blocker
                text={this.text}
                title={this.title}
            />
        );
    }

    remove () {
        if (this.page) this.page.classList.remove(this.classNameBlocked);
        if (!this.element) return;

        this.root.unmount();
        this.element.remove();
        this.element = null;
    }
}

function Blocker ({
    text,
    title,
}: IBlockerModalProps) {
    return (
        <Modal
            tabIndex={0}
            title={title}
            onClose={() => {}}
        >
            {text}
        </Modal>
    );
}
