import { getCookieDialogLocalStorage } from './localstorage/actions/getCookieDialogLocalStorage';

export interface ICookieDialogState {
    isShow: boolean;
    isSettingsOpened: boolean;
    isPerformanceAllowed: boolean;
    newPerformanceAllowedValue?: boolean;
}

const initialCookieDialogState: ICookieDialogState = {
    isShow: true,
    isSettingsOpened: false,
    isPerformanceAllowed: true,
};

export const getCookieDialogLocalStorageInitial = (): ICookieDialogState => {
    return {...initialCookieDialogState};
};

export const getCookieDialogInitialState = (): ICookieDialogState => {
    return { ...initialCookieDialogState, ... getCookieDialogLocalStorage()}
};
