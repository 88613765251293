import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { ICardHOCProps } from '../CardHOC/types';
import { CardAssignees } from '../../components/CardAssignees/CardAssignees';
import { ICardAssigneesEvents, ICardAssigneesFields } from '../../components/CardAssignees/types';
import { getBoardUsers } from '../../../../../../../store/model/selectors/getBoardUsers';
import { getAuthUser } from '../../../../../../../store/model/authUser/selectors/getAuthUser';
import { getBoardRoles } from '../../../../../../../store/model/board/selectors/getBoardRoles';
import { getBoard } from '../../../../../../../store/model/selectors/getBoardById';
import { onToggle } from './events/onToggle';
import { getMyWorkAssigneesSearchSelectOptionsCreateSelector } from './selectors/getMyWorkAssigneesSearchSelectOptions';
import { onLoadCard } from '../events/onLoadCard';

const mapStateToProps = () => {
    const getAssigneesSearchSelectOptions = getMyWorkAssigneesSearchSelectOptionsCreateSelector();
    const _mapStateToProps = (
        state: IApplicationState,
        { card, portalId }: ICardHOCProps
    ): ICardAssigneesFields => {
        const { assignees, cardId, dashboardId } = card;
        return {
            authUserPermissionId: getAuthUser(state).permissionId,
            boardUsers: getBoardUsers(state, dashboardId),
            elements: getAssigneesSearchSelectOptions(state, dashboardId, cardId),
            portalId,
            roles: getBoardRoles(getBoard(state, dashboardId)),
            users: assignees
        }
    }
    return _mapStateToProps;
}

const mapDispatchToProps = (
    dispatch: any,
    { card }: ICardHOCProps
): ICardAssigneesEvents => ({
    onLoadCard: () => dispatch(onLoadCard(card.cardId)),
    onSelect: (permissionId) => dispatch(onToggle(card.cardId, permissionId)),
})

export const CardAssigneesHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardAssignees);
CardAssigneesHOC.displayName = 'CardAssigneesHOC';
