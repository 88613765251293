import * as React from 'react';
import './_AssigneesElements.scss';
import { ListItem } from '../../../../../base/components/ListItem/ListItem';
import { Button, Icon, Userpic } from 'kui';
import { CLASS_CARD_DETAILS } from '../../../constants';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { IAssigneesElementsProps, ICardAssigneeElement } from './types';
import { getAsidePanelTooltip } from '../../../../asidePanel/helpers/getAsidePanelTooltip';
import { DragDropContext, Draggable, DraggableProvided, Droppable, DropResult } from 'react-beautiful-dnd';
import { ASSIGNEE_IN_WORK } from './constants';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { CLASS_LIST_ITEM_CONTAINER } from '../../../../../base/components/ListItem/constants';
import { AssigneeRolesSelect } from '../AssigneeRolesSelect/AssigneeRolesSelect';
import { TCardAssigneeRoleId, TPermissionId } from '../../../../../../../types/types';
import { ASSIGNEE_REMOVE_BUTTON_TOOLTIP, CLASS_CARD_ASSIGNEES } from '../constants';
import { UserpicStorage } from 'app/view/react_components/base/components/UserpicStorage/UserpicStorage';
import { getInitials } from 'app/view/react_components/helpers/userPhotoHelper';

export function AssigneesElements({
    allowRoles,
    elements,
    inWorkUsers,
    sectionType,
    onDelete,
    onSort,
    onClickDisallow,
    onToggleRole,
    onEditRoles
}: IAssigneesElementsProps) {
    const { cardId } = React.useContext(AsidePanelContext);
    const isPopup = sectionType === ESettingsSectionType.POPUP;
    const isReadonly = sectionType === ESettingsSectionType.READONLY;
    const [rolesOpened, setRolesOpened] = React.useState(null);

    /**
     * rbdnd перерисовывает всё на каждый чих,
     * поэтому перед открытием дропдауна надо прибить rbdnd
     */

    const onRolesOpen = React.useCallback((
        permissionId: TPermissionId
    ): Promise<any> => {
        return new Promise((res, rej) => {
            setRolesOpened(permissionId);
            setTimeout(res, 100);
        });
    }, [rolesOpened]);

    const onRolesClose = React.useCallback((
        permissionId: TPermissionId
    ) => {
        if (rolesOpened === permissionId) {
            setRolesOpened(null);
        }
    }, [rolesOpened]);

    function onDragEnd (result: DropResult) {
        if (
            !result.destination ||
            result.destination.index === result.source.index
        ) {
            // do nothing
        } else {
            onSort(result.draggableId, result.destination.index, elements.map(element => element.sharedUser.permissionId));
        }
    }

    if (isPopup || isReadonly) return (
        <div
            className={CLASS_CARD_ASSIGNEES}
        >
            {elements && elements.map(element => {
                return <Item
                    allowRoles={allowRoles}
                    element={element}
                    inWorkUsers={inWorkUsers}
                    isReadonly={isReadonly}
                    key={String(element.sharedUser.permissionId)}
                    onClickDisallow={onClickDisallow}
                    onDelete={onDelete}
                    onToggleRole={onToggleRole}
                    onRolesOpen={onRolesOpen}
                    onRolesClose={onRolesClose}
                    onEditRoles={onEditRoles}
                />;
            })}
        </div>
    );

    return (
        <DragDropContext
            onDragEnd={onDragEnd}
        >
            <Droppable
                droppableId={String(cardId)}
            >
                {(provided, snapshot) => (
                    <div
                        className={CLASS_CARD_ASSIGNEES}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        {elements.map((element, index) => {
                            return (
                                <Draggable
                                    draggableId={String(element.sharedUser.permissionId)}
                                    index={index}
                                    isDragDisabled={!!rolesOpened}
                                    key={String(element.sharedUser.permissionId)}
                                >
                                    {(provided, snapshot) => {
                                        return <Item
                                            allowRoles={allowRoles}
                                            element={element}
                                            inWorkUsers={inWorkUsers}
                                            draggableProvided={provided}
                                            isDraggable={!rolesOpened}
                                            isReadonly={isReadonly}
                                            onClickDisallow={onClickDisallow}
                                            onDelete={onDelete}
                                            onToggleRole={onToggleRole}
                                            onRolesOpen={onRolesOpen}
                                            onRolesClose={onRolesClose}
                                            onEditRoles={onEditRoles}
                                        />;
                                    }}
                                </Draggable>
                            );
                        })}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

interface IItem {
    allowRoles: boolean
    element: ICardAssigneeElement;
    inWorkUsers: string[],
    draggableProvided?: DraggableProvided;
    isReadonly?: boolean;
    isDraggable?: boolean;
    onClickDisallow: () => void;
    onDelete: (permissionId: TPermissionId) => void;
    onToggleRole: (permissionId: TPermissionId, roleId: TCardAssigneeRoleId) => void;
    onRolesOpen: (permissionId: TPermissionId) => Promise<any>;
    onRolesClose: (permissionId: TPermissionId) => void;
    onEditRoles: () => void;
}

function Item ({
    allowRoles,
    element,
    inWorkUsers,
    draggableProvided = null,
    isReadonly,
    isDraggable,
    onClickDisallow,
    onDelete,
    onToggleRole,
    onRolesOpen,
    onRolesClose,
    onEditRoles
}: IItem) {
    const CLASS_CARD_ASSIGNEES_ITEM = CLASS_CARD_DETAILS + '-assignees__item';
    const classText = CLASS_CARD_ASSIGNEES_ITEM + '-text';
    const classRole = CLASS_CARD_ASSIGNEES_ITEM + '-role';

    const draggable = draggableProvided
        ? {
            ...draggableProvided.draggableProps,
            ...draggableProvided.dragHandleProps,
            ref: draggableProvided.innerRef,
        }
        : {};

    const actions = isReadonly ? null
        : <>
            <Button
                variant={'icon'}
                tooltip={ getAsidePanelTooltip({
                    direction: 'up-left',
                    value: ASSIGNEE_REMOVE_BUTTON_TOOLTIP,
                })
                }
                onClick={() => onDelete(element.sharedUser.permissionId)}
            >
                <Icon size={24} xlink={'clear'} />
            </Button>
        </>;

    const img = (
        <UserpicStorage
            initials={getInitials(element.sharedUser)}
            size={24}
            src={element.sharedUser.photoUrl}
            userId={element.sharedUser.permissionId}
        />
    );
    const text = <>
        <div
            className={classText}
        >
            {element.sharedUser.fullName + (inWorkUsers.includes(element.sharedUser.permissionId) ? ' ' + ASSIGNEE_IN_WORK : '')}
        </div>
        <div
            className={`${classRole} ${!allowRoles ? classRole + '--disallow' : ''}`}
            onClick={allowRoles ? null : onClickDisallow}
        >
            <AssigneeRolesSelect
                isReadonly={isReadonly}
                roles={element.roles}
                onToggle={(roleId) => onToggleRole(element.sharedUser.permissionId, roleId)}
                beforeOpen={() => onRolesOpen(element.sharedUser.permissionId)}
                onClose={() => onRolesClose(element.sharedUser.permissionId)}
                onEdit={onEditRoles}
            />
        </div>
    </>

    return (
        <div
            className={CLASS_LIST_ITEM_CONTAINER}
            {...draggable}
        >
            <ListItem
                actions={actions}
                className={CLASS_CARD_ASSIGNEES_ITEM}
                img={img}
                isDraggable={isDraggable}
                key={element.sharedUser.permissionId}
                text={text}
            />
        </div>
    );
};
