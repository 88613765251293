import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId, TChecklistId } from '../../../../../../../../types/types';
import { checklistUpdate } from '../../../../../../../../rest/effects/card/checklist/checklistUpdate';
import { CHECKLIST_DEFAULT_ID } from '../../../../../../../../store/model/checklists/checklists/constants';
import { updateDefaultChecklistWithItems } from '../../CardChecklistActionsHOC/effects/updateDefaultChecklistWithItems';

export const onNameChange = (
    cardId: TCardId,
    checklistId: TChecklistId,
    name: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (checklistId === CHECKLIST_DEFAULT_ID) {
            return dispatch(updateDefaultChecklistWithItems({
                cardId,
                id: checklistId,
                name
            }));
        } else {
            return dispatch(checklistUpdate({
                cardId,
                id: checklistId,
                name
            }));
        }
    };
    return action;
};
