import { TBoardId, TCardId, TListId } from '../types/types';

interface ILastActivity {
    [boardId: number]: ILstActivity;
}

interface ILstActivity {
    listId?: TListId;
    cardId?: TCardId;
    recentlyScrolledListId?: TListId;
    recentlyScrolledCardIds?: IRecentlyScrolledCardIds;
}

interface IRecentlyScrolledCardIds {
    [listId: number]: TCardId;
}

export class LastActivityHelper {
    lastActivity: ILastActivity = {};

    setRecentlyScrolledListId(
        boardId: TBoardId,
        listId: TListId
    ) {
        this.lastActivity = {
            ...this.lastActivity,
            [boardId]: {
                ...this.lastActivity[boardId],
                recentlyScrolledListId: listId,
            }
        }
    }

    setRecentlyScrolledCardId(
        boardId: TBoardId,
        listId: TListId,
        cardId: TCardId,
        clear?: boolean
    ) {
        this.lastActivity = {
            ...this.lastActivity,
            [boardId]: {
                ...this.lastActivity[boardId],
                recentlyScrolledCardIds: {
                    ...(!clear && this.lastActivity[boardId] && this.lastActivity[boardId].recentlyScrolledCardIds),
                    [listId]: cardId
                },
            }
        }
    }

    setLastOpenedCardId(
        boardId: TBoardId,
        cardId: TCardId
    ) {
        this.lastActivity = {
            ...this.lastActivity,
            [boardId]: {
                ...this.lastActivity[boardId],
                cardId
            }
        };
    }

    setLastOpenedListId(
        boardId: TBoardId,
        listId: TListId
    ) {
        this.lastActivity = {
            ...this.lastActivity,
            [boardId]: {
                ...this.lastActivity[boardId],
                listId
            }
        };
    }

    getRecentlyScrolledListId(boardId: TBoardId) {
        return this.lastActivity[boardId] && this.lastActivity[boardId].recentlyScrolledListId;
    }

    getRecentlyScrolledCardId(boardId: TBoardId, listId: TListId) {
        return this.lastActivity[boardId] &&
            this.lastActivity[boardId].recentlyScrolledCardIds &&
            this.lastActivity[boardId].recentlyScrolledCardIds[listId];
    }

    getOpenedCardId(boardId: TBoardId) {
        return this.lastActivity[boardId] && this.lastActivity[boardId].cardId;
    }

    getOpenedListId(boardId: TBoardId) {
        return this.lastActivity[boardId] && this.lastActivity[boardId].listId;
    }
}
