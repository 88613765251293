import * as React from 'react';
import { Icon } from 'kui';
import {
    HELP_PANEL_LINKS_INVITE,
} from '../../constants';
import { IHelpPanelInviteLinkProps } from './types';

export const HelpPanelInviteLinkComponent = ({
    onClick
}: IHelpPanelInviteLinkProps) => {

    const clickHandler = (
        e: React.MouseEvent
    ) => {
        e.preventDefault();
        onClick();
    }

    return (
        <span className={'help-panel__link'}>
            <a onClick={clickHandler}>
                <Icon xlink="mail" size={24} /> {HELP_PANEL_LINKS_INVITE}
            </a>
        </span>
    );
};
