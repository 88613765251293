import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState } from '../../../../../../../types/types';
import { root } from '../../../../../../../store/constants';
import {
    POLL_RATE_LINK_GOOGLE,
    POLL_RATE_LINK_MICROSOFT
} from '../../../../../base/components/Poll/components/Poll/constants';
import { getAuthUserPlatformType } from '../../../../../../../store/model/authUser/selectors/getAuthUserPlatformType';
import { EAuthUserPlatformType } from '../../../../../../../types/rest/IRestAuthUser';

export const onRate = (): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const platformType = getAuthUserPlatformType(getState());
        switch (platformType) {
            case EAuthUserPlatformType.GOOGLE: {
                root.window.open(POLL_RATE_LINK_GOOGLE, '_blank');
                break;
            }
            case EAuthUserPlatformType.MICROSOFT: {
                root.window.open(POLL_RATE_LINK_MICROSOFT, '_blank');
                break;
            }
        }
    };
    return action;
};
