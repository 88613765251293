import { IGetState, TCardId } from '../../../../../../types/types';
import { IDriveDoc } from '../../../../../../store/model/card/types/IDriveDoc';
import { Dispatch, ThunkAction } from '../../../../../../types/actions';
import { IDriveDocs } from '../../../../../../store/model/driveDocs/types';
import { getDriveDocs } from '../../../../../../store/model/driveDocs/selectors/getDriveDocs';
import { driveDocsActionSetAction } from '../../../../../../store/model/actions/driveDocsActionSetAction';
import { driveDocsSet } from '../../../../../../store/model/driveDocs/actions/driveDocsSet';
import { patchDriveDocsRest } from '../patchDriveDocsRest';
import { sendRealTimeStoreActionByCardId } from '../../../../../../view/react_components/base/helpers/realTimeHelperTS';

export const patchDriveDocsHelper = (
    cardId: TCardId,
    driveDocs: IDriveDoc[],
    isOptimistic: boolean = true,
    isRealtime: boolean = true // send event to socket
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (!driveDocs) Promise.reject();

        const driveDocIds: number[] = [];
        const driveDocsMap: IDriveDocs = {};
        driveDocs.forEach((doc) => {
            driveDocIds.push(doc.id);
            driveDocsMap[doc.id] = doc;
        });
        if (!driveDocIds.length) Promise.reject();

        const state = getState();
        const driveDocsForRollbackMap: IDriveDocs = {};
        if (isOptimistic) {
            const driveDocsState = getDriveDocs(state);
            for (let docId in driveDocsState) {
                const doc = driveDocsState[docId];
                if (driveDocIds.includes(doc.id)) {
                    driveDocsForRollbackMap[doc.id] = doc;
                }
            }
            dispatch(driveDocsActionSetAction(driveDocsSet(driveDocsMap)));
        }
        return dispatch(patchDriveDocsRest(cardId, driveDocs))
            .then((responseDocs: IDriveDoc[]) => {
                const driveDocsMapResponse: IDriveDocs = {};
                responseDocs.forEach((doc) => {
                    driveDocsMapResponse[doc.id] = doc;
                });
                if (!isOptimistic) {
                    dispatch(driveDocsActionSetAction(driveDocsSet(driveDocsMapResponse)));
                }
                if (isRealtime) {
                    dispatch(sendRealTimeStoreActionByCardId(cardId, driveDocsActionSetAction(driveDocsSet(driveDocsMapResponse))));
                }
                return responseDocs;
            })
            .catch((e: any) => {
                console.error(e);
                if (isOptimistic) {
                    dispatch(driveDocsActionSetAction(driveDocsSet(driveDocsForRollbackMap)));
                }
            });
    };
    return action;
};
