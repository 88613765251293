import * as React from 'react';
import { ICardTitleProps } from './types';
import { CARD_CLASS } from '../../constants';
import './CardTitle.scss';
import { markdown } from '../../../../../../helper/markdownHelper';
import ReactHtmlParser from 'react-html-parser';

export function CardTitle({
    text
}: ICardTitleProps) {
    const cardTitleClass = CARD_CLASS + '__title';
    const [textMarked, setTextMarked] = React.useState(markdown(text));

    React.useEffect(() => {
        setTextMarked(markdown(text));
    }, [text]);

    return (
        <span className={cardTitleClass}>{ReactHtmlParser(textMarked)}</span>
    );
}
