import * as _ from 'underscore';

export const TimeTrackerHelper = {
    fillZero(val: number): string {
        const str = val + '';
        if (str.length < 2) {
            return '0' + str;
        } else {
            return str;
        }
    },

    /**
     * @param seconds
     * @param workHoursInDay
     * @param isTrim при больших значениях обрезать секунды, минуты
     * @param isShort убрать нулевые значения
     * @returns
     */
    formatSecondsToString(
        seconds: number,
        workHoursInDay: number,
        isTrim?: boolean,
        isShort?: boolean,
    ): string {
        let res = [];
        const dot = (isTrim && !isShort) ? ':' : ' ';
        const s = seconds % 60;
        const m = Math.floor(seconds / 60) % 60;
        const h = Math.floor(seconds / (60 * 60)) % workHoursInDay;
        const d = Math.floor(seconds / (60 * 60 * workHoursInDay));

        let d_ = isShort ? (d || '') : this.fillZero(d);
        let h_ = isShort ? (h || '') : this.fillZero(h);
        let m_ = isShort ? (m || '') : this.fillZero(m);
        let s_ = isShort ? (s || '') : this.fillZero(s);

        if (d_) d_ += 'd';
        if (h_) h_ += 'h';
        if (m_) m_ += 'm';
        if (s_) s_ += 's';

        if (d && d_) res.push(d_);
        if (h && h_) res.push(h_);
        if (!isTrim || d <= 99 || res.length < 2) {
            if (m_) res.push(m_);
            if ((!isTrim || res.length < 3) && s_) res.push(s_);
        }

        return res.join(dot);
    },

    getCommentMarkDown: function(seconds: number, textComment: string, workHoursInDay: number): string {
        return '[' + this.formatSecondsToRecord(seconds, workHoursInDay) + ']' + ( textComment.length ? ' ' : '' ) + textComment;
    },

    formatSecondsToRecord: function(seconds: number,  workHoursInDay: number): string {
        const s = Math.floor(seconds % 60);
        let m = Math.floor((seconds / 60) % 60);
        let h = Math.floor((seconds / (60 * 60)) % workHoursInDay);
        let d = Math.floor(seconds / (60 * 60 * workHoursInDay));
        let res;

        m = ( s > 0 ? m + 1 : m ); // округление в большую сторону
        if (m === 60) {
            m = 0;
            h = h + 1;
        }
        if (h === workHoursInDay) {
            h = 0;
            d = d + 1;
        }

        res = ( m ? m + 'm' : '' );

        if (h) {
            res = h + 'h ' + res;
        }
        if (d) {
            res = d + 'd ' + res;
        }
        return res.trim();
    },

    formatDisplayToInput: function(val: string) {
        let res;
        let raw;
        if (val.indexOf(' sec') > -1) {
            raw = val.split(':');
            if (parseInt(raw[0]) > 0) {
                // 04:20 sec -> 04:20 min -> 04m 20s
                res = val.replace(/ sec/g,'');
                raw = res.split(':');
                res = parseInt(raw[0]) + 'm ' + parseInt(raw[1]) + 's';
            } else {
                // 00:55 sec -> 55s
                res = val.replace(/00:/g,'');
                res = res.replace(/ sec/g,'s');
                res = parseInt(res) + 's';
            }
        } else if (val.indexOf(' min') > -1) {
            // 04:25 min -> 04m 25s
            res = val.replace(/ min/g,'');
            raw = res.split(':');
            res = parseInt(raw[0]) + 'm ' + parseInt(raw[1]) + 's';
            // 04:25 min -> 4m
            // 04:35 min -> 5m
            // res = ( parseInt(raw[1]) > 29 ? parseInt(raw[0]) + 1 : raw[0] ) + 'm';
        } else {
            raw = val.split(':');
            if (raw.length === 3) {
                // must be like 02:35:03 -> 2h 35m 03s
                res = parseInt(raw[0]) + 'h ' + parseInt(raw[1]) + 'm ' + parseInt(raw[2]) + 's';
            } else {
                // or 03:30 -> 3h 30m
                res = parseInt(raw[0]) + 'h ' + parseInt(raw[1]) + 'm';
            }
            res = parseInt(raw[0]) + 'h ' + parseInt(raw[1]) + 'm ' + parseInt(raw[2]) + 's';
            // must be like 02:35:03 -> 2h 35m
            // res = raw[0] + 'h ' + ( parseInt(raw[2]) > 29 ? parseInt(raw[1]) + 1 : raw[1] ) + 'm';
        }
        return res;
    },

    formatInputToSeconds: function(val: string, workHoursInDay: number) {
        if (_.isEmpty(val)) {
            return null;
        }

        val = val.trim().toLowerCase();
        val = val.replace(/( seconds|seconds| second|second| sec|sec| s)/g,'s');
        val = val.replace(/( minutes|minutes| minute|minute| mins|mins| min|min| m)/g,'m');
        val = val.replace(/( hours|hours| hour|hour| h)/g,'h');
        val = val.replace(/( days|days| day|day| d)/g,'d');
        if (val.indexOf('d') === -1 && val.indexOf('h') === -1 &&
            val.indexOf('m') === -1 && val.indexOf('s') === -1) {
            val += 'm';
        }

        let parts = {};
        let timeElements = {
            'd': true,
            'h': true,
            'm': true,
            's': true
        };
        let durationInSeconds = {
            'd': workHoursInDay * 60 *60,
            'h': 3600,
            'm': 60,
            's': 1
        };

        let raw = val.split(' ');
        let data, patt = /(\d{1,5})(.*)/i;
        for (let i = 0; i<raw.length; i++) {
            data = raw[i].match(patt);
            if (!data) continue;
            if (!data || data.length < 2) continue;
            // @ts-ignore
            if (timeElements[data[2]]) {
                // @ts-ignore
                parts[data[2]] = data[1];
            }
        }

        let totalSeconds = 0;
        for (let key in timeElements) {
            if (!timeElements.hasOwnProperty(key)) continue;
            // @ts-ignore
            if (parts[key]) {
                // @ts-ignore
                totalSeconds += durationInSeconds[key] * parts[key];
            }
        }
        return totalSeconds > 0 ? totalSeconds : null;
    },

    formatStringToSeconds: function(str: string, workHoursInDay: number) {
        let seconds;
        if (str.includes('s') || str.includes('m') || str.includes('h')) { // 01d:02h:03m
            if (str.includes(':')) {
                str = str.replaceAll(':', ' ');
            }
        } else { // 01:02:03 (h:m:s)
            if (!str.includes(':')) { // 01 02 03 (h m s)
                str = str.replaceAll(' ', ':');
            }
            str = this.formatDisplayToInput(str);
        }

        seconds = this.formatInputToSeconds(str, workHoursInDay);
        return seconds > 0 ? seconds : null;
    },

    calcTotalSeconds(startTime: number) {
        return Math.floor((Date.now() - startTime) / 1000);
    },

    formatCommentToSecondsByText: function(commentText: string, workHoursInDay: number) {
        let text: string = '';
        if (!commentText.indexOf('](')) {
            text = commentText.replace(/(\[|\])/g,'`');
        } else {
            let isTime = false;
            for (let i = 0; i < commentText.length; i++) {
                if (commentText[i] === '[') {
                    text += '`';
                    isTime = true;
                    continue;
                } else if (isTime &&
                    commentText[i] === ']'
                ) {
                    const lastQuotationMark = text.lastIndexOf('`');
                    const isTimer = text.slice(lastQuotationMark + 1).match(/^(\d+[dhms]\s?)+$/);//выражение для точного соответвия типу 1d 1h 1m 1s: ^(?!.*\s\s)(?!.* $)(?!(?:\d[dhms]){2})(\d+d)?\s?(\d+h)?\s?(\d+m)?\s?(\d+s)?$
                    if (commentText[i+1] === '(' || !isTimer) {  //если ссылка [](link) или не соответствует выражению, меняем предыдущюю '`' на '['
                        text = text.substring(0, lastQuotationMark) + '[' + text.substring(lastQuotationMark + 1) + commentText[i];
                        isTime = false;
                        continue
                    } else {
                        text += '`';
                        isTime = false;
                        continue;
                    }
                }
                text += commentText[i];
            }
        }
        const ar = text.split('`');
        let str;
        if (ar.length < 3) {
            return false;
        }
        str = ar[1];
        if (_.isEmpty(str)) {
            return false;
        }
        return this.formatInputToSeconds(str, workHoursInDay);
    }
}
