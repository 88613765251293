import {
    LABEL_DAY,
    LABEL_MONTH,
    LABEL_WEEK,
    LABEL_YEAR
} from '../../../../cardDetails/recurringSection/components/wording';
import { TExportRepeat } from './types';
import { getMessages } from '../../../../../../../store/constants';

export const LABEL_REPEAT_EVERY = getMessages().getText('board_settings.export.auto.repeat');
export const LABEL_START_ON = getMessages().getText('board_settings.export.auto.start');
export const LABEL_NEXT = getMessages().getText('board_settings.export.auto.next');
export const LABEL_AT = getMessages().getText('board_settings.export.auto.at');

export enum EExportRepeatType {
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month',
    YEAR = 'year',
}

export enum EExportRepeatMonthType {
    DAY_OF_MONTH = 'dayOfMonth',
    DAY_OF_WEEK_IN_MONTH = 'dayOfWeekInMonth',
}

export const REPEAT_TYPES: TExportRepeat[] = [
    {
        type: EExportRepeatType.DAY,
        title: LABEL_DAY
    }, {
        type: EExportRepeatType.WEEK,
        title: LABEL_WEEK
    }, {
        type: EExportRepeatType.MONTH,
        title: LABEL_MONTH
    }, {
        type: EExportRepeatType.YEAR,
        title: LABEL_YEAR
    }
]
