import * as React from 'react';
import { TRange } from '../types';
import { root } from '../../../../../../../../store/constants';

export const getRange = (
    e: React.MouseEvent,
    cardName: string,
    classNameText: string
): TRange => {
    const defaultResult = {
        startOffset: cardName.length,
        endOffset: cardName.length,
        startNode: e ? e.target as HTMLElement : null
    };
    if (!root.getSelection) {
        return defaultResult;
    }
    const selection = root.getSelection();
    if (!selection.rangeCount) return defaultResult;

    const range = selection.getRangeAt(0);
    const startNode = range.startContainer.parentNode as HTMLElement;
    const endNode = range.endContainer.parentNode as HTMLElement;

    const startOffset = startNode.classList.contains(classNameText) ? range.startOffset : 0;
    const endOffset = endNode.classList.contains(classNameText) ? range.endOffset : 0;

    return { startOffset, endOffset, startNode };
}
