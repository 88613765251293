import {ActionCreator} from 'redux';
import {Dispatch, ThunkAction} from '../../../../../../../types/actions';
import {segmentTrackAction, SegmentUserEvent} from '../../../../../../../middlewares/segment';
import {
    SegmentUserOption,
    SegmentUserOptionValue
} from '../../../../../../../middlewares/segment/trackEntities/userEvents';

export const onDidMount: ActionCreator<ThunkAction> =
    () => {
        const action = (dispatch: Dispatch) => {
            dispatch(segmentTrackAction(SegmentUserEvent.PERMISSION_DRIVE_SCOPE_SHOWN, {
                name: SegmentUserOption.TAGRET,
                value: SegmentUserOptionValue.SHARED_DRIVE
            }));
        };
        return action;
    };
