import * as React from 'react';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { DeleteButtonHOC } from '../../hocs/DeleteButtonHOC/DeleteButtonHOC';
import { IDeleteButtonSectionProps } from './types';
import { CLASS_CARD_DETAILS_SECTION } from '../../../constants';
import './_DeleteButtonSection.scss'

export function DeleteButtonSection({
    boardId,
    cardId,
    sectionType,
    allowEdit
}: IDeleteButtonSectionProps) {
    const isReadonly = sectionType === ESettingsSectionType.READONLY;
    const className = CLASS_CARD_DETAILS_SECTION + ' ' + CLASS_CARD_DETAILS_SECTION + '--delete-button';
    return (
        isReadonly && allowEdit // limited free version
            ?
            <div className={className}>
                <DeleteButtonHOC
                boardId={boardId}
                cardId={cardId}
                isShowTooltip={false}
                isShow={true}
                />
            </div>
            : null
    );
};
