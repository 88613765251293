import { TBoardId } from '../../../../../../../../../types/types';
import {
    EPushNotificationSettingsScope,
    EPushNotificationSubscribeCases,
    EPushNotificationSettingsTypes
} from '../../../../../../../../../types/rest/IRestPushNotificationSettings';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { pushNotificationSet } from '../../../../../../../../../rest/effects/pushNotificationSettings/pushNotificationSet';

export const onChange = (
    boardId: TBoardId,
    scopes: EPushNotificationSettingsScope[],
    subscribeCases: EPushNotificationSubscribeCases[],
    types: EPushNotificationSettingsTypes[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        dispatch(pushNotificationSet(boardId, scopes, subscribeCases, types));
    };
    return action;
};
