import { connect } from 'react-redux';
import * as React from 'react';
import { ICommentInputEvents, ICommentInputFields } from '../../components/CommentsInput/types';
import { EMPTY_ARRAY, IApplicationState } from '../../../../../../../types/types';
import { ICommentsInputHOCFields } from './types';
import { CommentsInput } from '../../components/CommentsInput/CommentsInput';
import { onCreate } from './events/onCreate';
import { onChangeCommentValue } from './events/onChangeCommentValue';
import { onChangeMention } from './events/onChangeMention';
import { onKeyDown } from './events/onKeyDown';
import { onBlur } from './events/onBlur';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { getSectionType } from '../../../selectors/getSectionType';
import { onClose } from './events/onClose';
import { onAddAttachments } from './events/onAddAttachments';
import { onDidMount } from './events/onDidMount';
import { isMentionsDropDownShown } from '../../selectors/isMentionsDropDownShown';
import { getUserHTMLEditorType } from 'app/store/model/authUser/selectors/getUserHTMLEditorType';
import { getCommentById } from '../../selectors/getCommentById';
import { getReplyCommentId } from '../../selectors/getReplyCommentId';
import { onReplyClose } from './events/onReplyClose';
import { getEditCommentId } from '../../selectors/getEditCommentId';
import { onCancel } from './events/onCancel';
import { onSaveChanges } from './events/onSaveChanges';
import { onAttachmentDelete } from './events/onAttachmentDelete';
import { getDriveDoc } from '../../../../../../../store/model/driveDocs/selectors/getDriveDoc';
import { getReplyAttachmentId } from '../../selectors/getReplyAttachmentId';
import { getCommentDriveDocsByIds } from '../../selectors/getCommentDriveDocsByIds';
import { getAuthUserPlatformType } from '../../../../../../../store/model/authUser/selectors/getAuthUserPlatformType';
import {
    isMicrosoftPersonalAccount
} from '../../../../../../../store/model/authUser/selectors/isMicrosoftPersonalAccount';
import { onUploadClick } from './events/onUploadClick';
import { onSelectClick } from './events/onSelectClick';
import { getReplyText } from '../../selectors/getReplyText';
import { isEqual } from 'underscore';

// const analyze = new Analyze('CommentInputHOC');
// 10 раз открыл-закрыл карту
// no reselect  average: 1.383 ms, calls: 120, total: 166.000 ms
// cache        average: 1.418 ms, calls: 118, total: 167.300 ms
import { root } from '../../../../../../../store/constants';
import { onAttachmentsChange } from './events/onAttachmentsChange';
import { getCommentValue } from '../../selectors/getCommentValue';

const mapStateToProps = () => {
    interface ICaheProps extends ICommentInputFields{};
    let cache: ICaheProps = null;
    const getCache = (
        value: ICaheProps
    ): ICaheProps => {
        if (isEqual(value, cache)) {
            return cache;
        }
        cache = value;
        return value;
    };

    return (
        state: IApplicationState,
        props: ICommentsInputHOCFields
    ): ICommentInputFields => {
        // analyze.start();
        const { boardId, cardId, sectionType: ownSectionType } = props;
        const sectionType = getSectionType(state, boardId, cardId, ownSectionType, null, 'allowComment');
        const isShow = sectionType !== ESettingsSectionType.READONLY;
        const autoFocus = sectionType === ESettingsSectionType.POPUP;
        const replyCommentId = getReplyCommentId(state);
        const replyComment = replyCommentId && getCommentById(state, cardId, replyCommentId);
        const replyAttachmentId = getReplyAttachmentId(state);
        const editCommentId = getEditCommentId(state);
        const editComment = editCommentId && getCommentById(state, cardId, editCommentId);
        const isMentionsOpened = isMentionsDropDownShown(state);
        let isShowReplyAttachment = false;
        if (replyComment && replyComment.driveDocIds && replyComment.driveDocIds.length === 1) {
            const driveDocs = getCommentDriveDocsByIds(state, replyComment.id, replyComment.driveDocIds);
            if (driveDocs[0].isImage) {
                isShowReplyAttachment = true;
            }
        }

        const HTMLEditorType = getUserHTMLEditorType(state);
        const driveDocs = editComment
            ? editComment.driveDocIds && editComment.driveDocIds.map((id) => getDriveDoc(state, id))
            : root.App.controller.cardsCommentsInfo.getAttachments(cardId);
        const isShowSelectFile = !isMicrosoftPersonalAccount(state);
        const platformType = getAuthUserPlatformType(state);
        const replyAttachment = replyComment && replyAttachmentId && getDriveDoc(state, replyAttachmentId);
        const value = editComment ? editComment.text
            : getCommentValue(state) ? getCommentValue(state) : root.App.controller.cardsCommentsInfo.getValue(cardId);
        const replyText = getReplyText(state);

        const ret = getCache({
            autoFocus,
            boardId,
            cardId,
            driveDocs,
            editComment,
            HTMLEditorType,
            isMentionsOpened,
            isShow,
            isShowReplyAttachment,
            isShowSelectFile,
            platformType,
            replyAttachment,
            replyComment,
            replyText,
            value,
        });
        // analyze.finish();
        return ret;
    };
};

const mapDispatchToProps = (
    dispatch: any,
    props: ICommentsInputHOCFields
): ICommentInputEvents => {
    const { boardId, cardId, onClose: onCloseOwn } = props;
    return {
        onAttachmentsChange: (attachments) => dispatch(onAttachmentsChange(cardId, attachments)),
        onCancel: () => dispatch(onCancel()),
        onDidMount: () => dispatch(onDidMount()),
        onChangeCommentValue: (value: string) => dispatch(onChangeCommentValue(cardId, value)),
        onCreate: (e: React.KeyboardEvent<HTMLElement>, attachments) => dispatch(onCreate(boardId, cardId, e, attachments)),
        onChangeMention: (key: string, selectionStart: number) => dispatch(onChangeMention(key, selectionStart)),
        onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => dispatch(onKeyDown(e, boardId, cardId)),
        onBlur: () => dispatch(onBlur()),
        onClose: () => dispatch(onClose(onCloseOwn)), // onCloseOwn - закрыть попап
        onAddAttachments: (attachments, setAttachments) => dispatch(onAddAttachments(cardId, attachments, setAttachments)),
        onReplyClose: () => dispatch(onReplyClose()),
        onSaveChanges: (text, attachments) => dispatch(onSaveChanges(boardId, cardId, text, attachments)),
        onRemoveAttachment: (id) => dispatch(onAttachmentDelete(cardId, id)),
        onSelectClick: (onClose, setAttachments) => dispatch(onSelectClick(cardId, onClose, setAttachments)),
        onUploadClick: (onStart, onProgress, onFinish, setAttachments) => dispatch(onUploadClick(cardId, onStart, onProgress, onFinish, setAttachments)),
    };
};

export const CommentInputHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CommentsInput);

CommentInputHOC.displayName = 'CommentInputHOC';
