export enum SnackbarVariant {
    ERROR =     'ERROR',
    INFO =      'INFO',
    PROGRESS =  'PROGRESS',
    PROMT =     'PROMT',
    REFRESH =   'REFRESH',
    RELOAD =    'RELOAD',
    SUCCESS =   'SUCCESS',
    TIMER =     'TIMER',
    UPGRADE =   'UPGRADE',
    UNDO =      'UNDO'
}

export enum ESnackbarPosition {
    center = 'center',
    left = 'left'
}

export interface ISnackbarsStackState {
    // we do not show snackbars in order to give em some time to rerender
    isSuspended: boolean;
    snackbars: ISnackbarModel[];
}

export type SnackbarId = string;

export interface ISnackbarModel {
    className?: string;
    id: SnackbarId;
    controls: ISnackbarControlModel[];
    text: string;
    title: string;
    timer?: number;
    variant: SnackbarVariant;
    position?: ESnackbarPosition;
    onTimer?: () => void;
    onHide?: () => void; // вызывается, когда открывается снекбар с таким же id, изначально сделано для undo, что бы вызвать удаление, когда показывается новый снекбар
}

export interface ISnackbarControlModel {
    action: () => void;
    isPrimary?: boolean; // false by default - to mark primary button with color in set of buttons
    // percent typeof number
    // 0 -> 0% | 100 - done | null - no progress bar
    // only for progress bar background width, to show percentage in title - use title
    progress?: number;
    title: string;
    icon?: string;
}
