import { IRestBoardForOpenDialog } from '../../../rest/types';
import { IBoard } from '../types';
import { getDrives } from '../../drives/selectors/getDrives';
import { getAppState } from '../../../../../../../store/configureStore';
import { DRIVE_ID_SHARED_WITH_ME } from '../../drives/constants';

export const getBoardsFromRest = (
    restBoards: IRestBoardForOpenDialog[]
): IBoard[] => {
    const drives = getDrives(getAppState());
    const drivesIds = drives.map(drive => drive.id);
    const boards = restBoards.map((restBoard) => {
        let board = buildBoard(restBoard);
        if (!drivesIds.includes(board.driveId)) {
            board.driveId = DRIVE_ID_SHARED_WITH_ME; // if board from shared drive, whitch i don`t have access to. But board is shared with me from google drive
        }
        return board;
    });
    const boardsSorted = boards.sort(sortBoards);
    return boardsSorted;
};

const buildBoard = (
    restBoard: IRestBoardForOpenDialog
): IBoard => {
    const board: IBoard = {
        driveId: restBoard.driveId,
        id: restBoard.id,
        name: restBoard.name,
        logo: restBoard.logo,
        logoColor: restBoard.logoColor,
        isArchived: restBoard.isArchived,
        isShared: restBoard.isShared,
        isStarred: restBoard.isStarred,
        isOpened: false,
        allowedAction: restBoard.allowedAction
    };
    return board;
};

const sortBoards = (
    a: IBoard,
    b: IBoard
): number => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    return 0;
};
