import { ITabsModelSetAction } from './types';
import { AT_SET_ACTION } from './constants';
import { TTabsModelState } from '../types';

export const tabsModelSetAction = (
    tabs: TTabsModelState
): ITabsModelSetAction => ({
    type: AT_SET_ACTION,
    tabs
});
