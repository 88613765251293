import { getMessages } from '../../../../../store/constants';

export const FP_STATUS_ON = 'On';
export const FP_STATUS_OFF = 'Off';
export const FP_FILTER_INFO_PANEL_TITLE_FILTER = getMessages().getText('fp.info_panel.title.filter');
export const FP_FILTER_INFO_PANEL_TITLE_APPLIED = getMessages().getText('fp.info_panel.title.applied');
export const FP_FILTER_INFO_PANEL_CLASS = 'filter-info-panel';
export const FP_FILTER_INFO_PANEL_CONTAINER_CLASS = 'filter-info-panel__container';
export const FP_FILTER_INFO_PANEL_CARDS = [
    getMessages().getText('fp.info_panel.card'),
    getMessages().getText('fp.info_panel.cards')
];
export const FP_FILTER_INFO_PANEL_FOUND = getMessages().getText('fp.info_panel.found');
