import { TCardAssigneeRoleId } from '../../../../types/types';
import { ThunkAction } from '../../../../types/actions';
import { fetchHandler } from '../../../../util/fetchHandler';
import { Util } from '../../../../util/util';
import { REST_ROLE } from '../../../constants';
import { getRestHeadersPut } from '../../../helpers/getRestHeadersHelper';
import { IColor, ICardAssigneeRole } from '../../../../types/model';

export const patchRest = (
    roleId: TCardAssigneeRoleId,
    role: ICardAssigneeRole
): ThunkAction => {
    const action = () => {
        return fetchHandler<IColor>(
            Util.getApiUrl(REST_ROLE + roleId), {
                ...getRestHeadersPut(),
                body: JSON.stringify(role)
            }
        );
    };
    return action;
};
