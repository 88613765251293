import { IGetState, TCardId } from '../../../../types/types';
import { INotificationBeforeDueDate } from '../../../../store/model/notificationBeforeDueDate/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { postRest } from './api/postRest';
import { IRestNotificationBeforeDueDate } from '../../../../types/rest/IRestNotificationBeforeDueDate';
import { notificationBeforeDueDateAdd as notificationBeforeDueDateAddStoreAction } from '../../../../store/model/actionCreators/notificationBeforeDueDate/notificationBeforeDueDateAdd';
import { sendRealTimeStoreActionByCardId } from '../../../../view/react_components/base/helpers/realTimeHelperTS';

export const notificationBeforeDueDateAdd = (
    cardId: TCardId,
    notification: INotificationBeforeDueDate
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(postRest(cardId, notification)).then((
            result: IRestNotificationBeforeDueDate
        ) => {
            dispatch(notificationBeforeDueDateAddStoreAction(cardId, result));

            dispatch(sendRealTimeStoreActionByCardId(cardId, notificationBeforeDueDateAddStoreAction(cardId, result)));
        });
    };
    return action;
};
