import { INotificationBeforeDueDateUpdateAction } from './types';
import { AT_NOTIFICATION_BEFORE_DUEDATE_UPDATE } from './constants';
import { INotificationBeforeDueDate } from '../../notificationBeforeDueDate/types';

export const notificationBeforeDueDateUpdateAction = (
    notificationBeforeDueDate: INotificationBeforeDueDate
): INotificationBeforeDueDateUpdateAction => {
    return {
        type: AT_NOTIFICATION_BEFORE_DUEDATE_UPDATE,
        notificationBeforeDueDate
    }
};
