const category = 'Swimlanes';

export const SegmentSwimlanesEvent = {
    SWIMLANE_COLLAPSED: { category, event: 'swimlane collapsed' },
    SWIMLANE_EXPANDED: { category, event: 'swimlane expanded' },
    GOTO_CLICKED: { category, event: 'go to swimlane clicked' },
    COLLAPSE_ALL: { category, event: 'collapse all clicked' },
    EXPAND_ALL: { category, event: 'expand all clicked' },
    HEIGHT_MANUALLY: { category, event: 'adjust height manually clicked' },
    HEIGHT_AUTO: { category, event: 'set height to fit content clicked' },
    HEIGHT_CHANGED: { category, event: 'swimlane height changed' },
    SWIMLANE_DND: { category, event: 'swimlane drag n dropped' },
}
