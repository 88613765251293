import * as React from 'react';
import { ICardBoardNameProps } from './types';
import { CLASS_CARD_DETAILS } from '../../../constants';
import './_CardBoardName.scss';
import { Button, Icon } from 'kui';
import * as _ from 'underscore';

export function CardBoardName({
    boardName,
    isShow,
    onClick
}: ICardBoardNameProps) {
    if (!isShow) return null;

    const className = CLASS_CARD_DETAILS + '-board-name';

    const onClickLinkHandler = (event: React.MouseEvent) => {
        event.preventDefault();
        onClick();
    };

    return (
        <Button
            className={className}
            variant={'icon-text'}
            text={boardName}
            onClick={onClickLinkHandler}
        >
            <Icon xlink={'board'} size={16} />
        </Button>
    );
};
