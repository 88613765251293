import { connect } from 'react-redux';
import { CardAttachmentsSection } from '../../components/CardAttachmentsSection/CardAttachmentsSection';
import {
    ICardAttachmentsDispatchProps,
    ICardAttachmentsStateProps
} from '../../components/CardAttachmentsSection/types';
import { ICardAttachmentsHOCOwnProps } from './types';
import { EMPTY_ARRAY, IApplicationState } from '../../../../../../../types/types';
import { onTogglePin } from './events/onTogglePin';
import { onDelete } from './events/onDelete';
import { onDownload } from './events/onDownload';
import { getSectionType } from '../../../selectors/getSectionType';
import { onAddClicked } from './events/onAddClicked';
import { onSort } from './events/onSort';
import { onLinkClick } from './events/onLinkClick';
import { getCardActiveRequests } from '../../../../../../../store/requestsState/selectors/getCardActiveRequests';
import { onDropFiles } from './events/onDropFiles';
import { onPasteFile } from './events/onPasteFile';
import { onSelectClick } from './events/onSelectClick';
import { getAuthUser } from '../../../../../../../store/model/authUser/selectors/getAuthUser';
import { getBoardIsCardDefaultProperty } from '../../../../../../../store/model/board/selectors/getBoardIsCardDefaultProperty';
import { EAsidePanelProperty } from '../../../../asidePanel/components/AsidePanel/types';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { onPasteFileAsComment } from './events/onPasteFileAsComment';
import {getAuthUserIsAttachmentViewTypeGrid} from '../../../../../../../store/model/authUser/selectors/getAuthUserIsAttachmentViewTypeGrid';
import {onToggleAttachmentViewType} from './events/onToggleAttachmentViewType';
import {getDriveDocsByCardId} from '../../../../../../../store/model/driveDocs/selectors/getDriveDocsByCardId';
import {ERestDriveDocType} from '../../../../../../../types/rest/IRestDriveDoc';
import {
    getBoardIsCardRequiredProperty
} from '../../../../../../../store/model/board/selectors/getBoardIsCardRequiredProperty';
import { getCardIsLoading } from 'app/store/requestsState/selectors/getCardIsLoading';
import { onShowAttachmentViewHint } from './events/onShowAttachmentViewHint';
import { onOpenAttachmentsTab } from './events/onOpenAttachmentsTab';
import { onShowAttachmentListHint } from './events/onShowAttachmentListHint';
import { getHint } from 'app/view/react_components/hints/store/hints/selectors/getHint';
import { EHintKey } from 'app/view/react_components/hints';
import {
    getIsNewFeaturePasteAttachment
} from '../../../../../../../store/model/authUser/selectors/featuresSeen/getIsNewFeaturePasteAttachment';
import { onHideTips } from './events/onHideTips';
import { updateIsGoogleFileCreating } from 'app/view/react_components/aside_panel/cardDetails/AttachmentsSection/hocs/CardAttachmentsSectionHOC/events/updateIsGoogleFileCreating';
import { getAsidePanelIsGoogleFileCreating } from 'app/view/react_components/aside_panel/asidePanel/store/selectors/getAsidePanelIsGoogleFileCreating';
import { isMicrosoftPersonalAccount } from 'app/store/model/authUser/selectors/isMicrosoftPersonalAccount';
import { isEqual } from 'underscore';
import { onLinkCopy } from './events/onLinkCopy';

// const analyze = new Analyze('CardAttachmentsSectionHOC');
// reselect average: 1.372 ms, calls: 122, total: 167.400 ms // открыл-закрыл 10 карт
// cache    average: 1.470 ms, calls: 122, total: 179.300 ms

const mapStateToProps = () => {
    interface ICaheProps extends ICardAttachmentsStateProps{};
    let cache: ICaheProps = null;
    const getCache = (
        value: ICaheProps
    ): ICaheProps => {
        if (isEqual(value, cache)) {
            return cache;
        }
        cache = value;
        return value;
    };

    return (
        state: IApplicationState,
        ownProps: ICardAttachmentsHOCOwnProps
    ): ICardAttachmentsStateProps => {
        // analyze.start();
        const {cardId, boardId,  sectionType: sectionTypeOwn} = ownProps;
        const sectionType = getSectionType(state, boardId, cardId, sectionTypeOwn);
        const attachments = getDriveDocsByCardId(state, cardId, ERestDriveDocType.CARD, undefined, true);
        const activeRequests = getCardActiveRequests(state, cardId);
        const isAttachmentViewTypeGrid = getAuthUserIsAttachmentViewTypeGrid(state);
        const isLoading = getCardIsLoading(state, cardId);
        const attachmentsListHintStatus = getHint(state, EHintKey.ATTACHMENTS_LIST).status;
        const isGoogleFileCreating = getAsidePanelIsGoogleFileCreating(state);
        const microsoftPersonalAccount = isMicrosoftPersonalAccount(state);

        const ret = getCache({
            attachments: attachments && attachments.length
                ? attachments
                : sectionType !== ESettingsSectionType.READONLY &&
                getBoardIsCardDefaultProperty(state, boardId, EAsidePanelProperty.CARD_ATTACHMENTS)
                    ? EMPTY_ARRAY
                    :  null,
            cardId,
            boardId,
            isAttachmentViewTypeGrid,
            isDefaultProperty: getBoardIsCardDefaultProperty(state, boardId, EAsidePanelProperty.CARD_ATTACHMENTS),
            isGoogleFileCreating,
            isLoading,
            isRequired: getBoardIsCardRequiredProperty(state, boardId, EAsidePanelProperty.CARD_ATTACHMENTS),
            isShowTips: getIsNewFeaturePasteAttachment(state),
            isShowSelectFile: !microsoftPersonalAccount,
            sectionType,
            activeRequests,
            userHash: getAuthUser(state).hash,
            attachmentsListHintStatus
        });
        // analyze.finish();
        return ret;
    };
};

const mapDispatchToProps = (
    dispatch: any,
    ownProps: ICardAttachmentsHOCOwnProps
): ICardAttachmentsDispatchProps => {
    const {cardId, onClose: onCloseOwn, boardId} = ownProps;
    return {
        onToggleAttachmentViewType: () => dispatch(onToggleAttachmentViewType()),
        onTogglePin: (driveDocId) => dispatch(onTogglePin(driveDocId)),
        onDownload: () => dispatch(onDownload()),
        onDelete: (driveDocId) => dispatch(onDelete(cardId, driveDocId)),
        onAddClick: (isDialog, onClose) => dispatch(onAddClicked(cardId, isDialog, onClose || onCloseOwn)),
        onSort: (docId, index, docIds) => dispatch(onSort(cardId, docId, index, docIds)),
        onHideTips: () => dispatch(onHideTips()),
        onLinkClick: (event, driveDocId) => dispatch(onLinkClick(event, driveDocId)),
        onLinkCopy: (url) => dispatch(onLinkCopy(url)),
        onDropFiles: (files, isPopup, onProgress) => dispatch(onDropFiles(cardId, files, isPopup, onProgress)),
        onPasteFile: (files, onProgress) => dispatch(onPasteFile(cardId, files, onProgress)),
        onSelectClick: () => dispatch(onSelectClick(cardId)),
        onPasteFileAsComment: (files, onProgress) => dispatch(onPasteFileAsComment(boardId, cardId, files, onProgress)),
        onShowAttachmentViewHint: () => dispatch(onShowAttachmentViewHint()),
        onShowAttachmentListHint: () => dispatch(onShowAttachmentListHint()),
        onOpenAttachmentsTab: () => dispatch(onOpenAttachmentsTab(boardId)),
        updateIsGoogleFileCreating: () => dispatch(updateIsGoogleFileCreating())
    }
};

export const CardAttachmentsSectionHOC = connect(mapStateToProps, mapDispatchToProps)(CardAttachmentsSection);
CardAttachmentsSectionHOC.displayName = 'CardAttachmentsSectionHOC';
