import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { resetTimeTracker } from '../../../effects/resetTimeTracker';
import { closeTimeTrackerDialog } from '../../../effects/closeTimeTrackerDialog';

export const onReset = (): ThunkAction => {
    return (
        dispatch: Dispatch
    ) => {
        dispatch(closeTimeTrackerDialog());
        dispatch(resetTimeTracker());
    };
};
