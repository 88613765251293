import React from "react";
import { CLASS_PAGE_SHOW_LISTS_BG, SNACKBAR_ID_CARD_AGEING, } from "../../const";
import { Provider } from "react-redux";
import store, { dispatch } from "../../store/configureStore";
import { getBoard } from '../../store/model/selectors/getBoardById';
import { getIsSwimlanesView } from "../react_components/main/kanbanView/store/selectors/getIsSwimlanesView";
import { snackbarErrorReload, snackbarInfoDefault } from "../react_components/snackbarsStack/index";
import { getMessages } from "../../store/constants";
import { openBoardSettings } from "../react_components/base/effects/openBoardSettings";
import { getDefaultActionEvent } from "../react_components/snackbarsStack/effects/helpers/getDefaultActionEvent";
import { SNACKBAR_BUTTON_OK } from "../react_components/snackbarsStack/effects/constants";
import { getBoardCardAgingEnabled } from "../../store/model/board/selectors/getBoardCardAgingEnabled";
import { getBoardHideCardAgeingSnackbar } from "../../store/model/board/selectors/getBoardHideCardAgeingSnackbar";
import { boardSetHideCardAgeingSnackbar } from "../../rest/effects/board/boardSetHideCardAgeingSnackbar";
import { segmentTrackAction } from "app/middlewares/segment";
import { SegmentErrorEvent, SegmentErrorOptions } from "app/middlewares/segment/trackEntities/errorEvents";
import { LazyWrapper } from "../react_components/lazy/lazy_wrapper";
import { SegmentBoardSettingsSourceValue } from "app/middlewares/segment/trackEntities/boardEvents";

export const KanbanDashboard = App.Views.KanbanDashboard = App.Views.AbstractDashboard.extend({
    onImportFail: function() {
        dispatch(snackbarErrorReload(false));
        dispatch(segmentTrackAction(SegmentErrorEvent.LAZY_IMPORT_ERROR, {
            name: SegmentErrorOptions.SOURCE,
            value: 'kanbanView'
        }));
    },

    render: function() {
        import(/* webpackChunkName: "kanban" */ '../react_components/aside_panel/listDetails/listMoveToButton/hocs/ListMoveToButtonHOC/ListMoveToButtonHOC');
        const ImportElement = React.lazy(() =>
            import(/* webpackChunkName: "kanban" */ '../react_components/main/kanbanView/components/lists/HOCS/ListsHOC')
                .then(module => ({default: module.ListsHOC}))
                .catch(() => this.onImportFail())
        );
        this.root.render(
            <LazyWrapper>
                <Provider store={store}>
                    <ImportElement boardId={this.boardId} />
                </Provider>
            </LazyWrapper>
        );
        return this;
    },

    mouseScroll: function(event) {
        App.Views.AbstractDashboard.prototype.kanbanLikeScroll.call(this, event);
    },

    /**
     * Метод, который производит измеение размеров элементов доски (карт, листов и т.п.)
     * Выполняется после события view:resized, вызвание по $(window).resize(
     */
    resizeBoard: function() {
        this.onScrollSwimlanes();
        App.Views.AbstractDashboard.prototype.resizeBoard.call(this);
    },

    filterPanelToggled: function() {
    },

    showCardAgeingSnackbar: function() {
        store.dispatch(snackbarInfoDefault({
            id: SNACKBAR_ID_CARD_AGEING,
            text: getMessages().getText('board_settings.card_aging.snackbar.text'),
            timer: -1,
            controls: [
                {
                    action: () => {
                        store.dispatch(getDefaultActionEvent(SNACKBAR_ID_CARD_AGEING));
                        store.dispatch(openBoardSettings('card-aging', SegmentBoardSettingsSourceValue.CARD_AGING_SNACKBAR));
                    },
                    title: getMessages().getText('common.settings')
                },
                {
                    action: () => {
                        store.dispatch(boardSetHideCardAgeingSnackbar(this.boardId));
                        store.dispatch(getDefaultActionEvent(SNACKBAR_ID_CARD_AGEING));
                    },
                    title: SNACKBAR_BUTTON_OK,
                }
            ]
        }));
    },

    showCardAgeingSnackbarIfNeeded: function() {
        const state = store.getState();
        const boardId = this.boardId;
        const isCardAging = getBoardCardAgingEnabled(state, boardId);
        const isHide = getBoardHideCardAgeingSnackbar(state, boardId);
        if (isCardAging && !isHide) {
            this.showCardAgeingSnackbar();
        }
    },

    onActivated: function() {
        const board = getBoard(store.getState(), this.boardId);
        this.isSwimlanesView = getIsSwimlanesView(store.getState(), this.boardId);
        if (this.isSwimlanesView) {
            this.$el.addClass('board-view--swimlanes');
            App.controller.mainView.themeHelper.clearTheme();
            this.mouseScroll = null;
        } else {
            App.controller.mainView.themeHelper.applyTheme(board.theme);
        }
        App.Views.AbstractDashboard.prototype.onActivated.call(this);
        $('body')
            .addClass('page--classic')
            .addClass(CLASS_PAGE_SHOW_LISTS_BG);
        this.showCardAgeingSnackbarIfNeeded();
    },

    remove: function() {
        store.dispatch(getDefaultActionEvent(SNACKBAR_ID_CARD_AGEING));
        this.scrollRemove();

        App.Views.AbstractDashboard.prototype.remove.call(this);
        $('body')
            .removeClass('page--classic')
            .removeClass(CLASS_PAGE_SHOW_LISTS_BG);
    }
});
