import { TPushNotificationsSettingsAction } from '../pushNotificationsSettings/actions/types';
import { IPushNotificationsSettingsActionSetAction, PUSH_NOTIFICATIONS_SETTINGS_ACTION_SET } from './types';

export const pushNotificationsSettingsActionSet = (
    pushNotificationsAction: TPushNotificationsSettingsAction
): IPushNotificationsSettingsActionSetAction => {
    return {
        type: PUSH_NOTIFICATIONS_SETTINGS_ACTION_SET,
        pushNotificationsAction
    }
};
