import * as React from 'react';
import './_BoardBackups.scss';
import { IBackupListener, IBoardBackupsProps } from './types';
import { Button, ButtonDropdown, Icon, Loader, LoaderBlock, Tooltip } from 'kui';
import { BACKUP_CREATE_TEXT, BACKUP_PROGRESS_TEXT, BACKUP_RESTORE_TEXT, BACKUP_RESTORE_TEXT_SHARED } from './constants';
import { CLASS_BOARD_BACKUPS } from '../../../../constants';
import { ListItem } from '../../../../../../base/components/ListItem/ListItem';
import { getAsidePanelTooltip } from '../../../../../asidePanel/helpers/getAsidePanelTooltip';
import Util from '../../../../../../../../util/util';
import { escape } from 'underscore';
import { EmptyDummy } from '../../../../../../base/components/EmptyDummy/EmptyDummy';
import { getEmptyDummy } from '../../../../../../dialogs/openBoards/containers/TabBoards/constants';
import { SearchSelect } from '../../../../../../base/components/SearchSelect/SearchSelect';
import { EAuthUserPlatformType } from 'app/types/rest/IRestAuthUser';

export function BoardBackups ({
    backups,
    isCreateDisabled,
    isRestoreDisabled,
    planBlocker,
    platformType,
    scopesBlocker,
    onBackupRestore,
    onBackupCreate,
    onLoadSharedDrives,
    isShowRestoreToSharedDrive
}: IBoardBackupsProps) {
    const [progress, setProgress] = React.useState(null);
    const [isOpened, setOpened] = React.useState(null);
    const [isLoading, setLoading] = React.useState(null);
    const [options, setOptions] = React.useState(null);

    const className = CLASS_BOARD_BACKUPS + '__backups';
    const classButtons = CLASS_BOARD_BACKUPS + '__buttons';
    const classButton = CLASS_BOARD_BACKUPS + '__button';
    const classLoader = CLASS_BOARD_BACKUPS + '__loader';
    const classProgress = CLASS_BOARD_BACKUPS + '__progress';
    const classItem = CLASS_BOARD_BACKUPS + '__backup';
    const classEmpty = CLASS_BOARD_BACKUPS + '__empty';
    const classActions = CLASS_BOARD_BACKUPS + '__actions';
    const classDropdown = CLASS_BOARD_BACKUPS + '__dropdown';

    const eventListener: IBackupListener = {
        onSuccess: () => {
            setProgress(null);
        },
        onProgress: (percent: number) => {
            setProgress(Math.round(percent));
        },
        onError: (errorMessage: string) => {
            setProgress(null);
        },
        onCancel: () => {
            setProgress(null);
        }
    }
    const onNewBackupClick = () => {
        setProgress(0);
        onBackupCreate(eventListener);
    }

    React.useEffect(() => {
        if (planBlocker || scopesBlocker || isLoading || !isOpened) return;

        setLoading(true);

        onLoadSharedDrives().then((result) => {
            setOptions(result);
            setLoading(false)
        });
    }, [isOpened]);

    const icon =  platformType === EAuthUserPlatformType.MICROSOFT ? 'project' : 'drive';

    return <div className={className}>
        {!isCreateDisabled &&
            <div className={classButtons}>
                {progress === null
                    ? <Button
                        className={classButton}
                        onClick={onNewBackupClick}
                    >
                        {BACKUP_CREATE_TEXT}
                    </Button>
                    : <div className={classProgress}>
                        <Loader className={classLoader} />
                        {BACKUP_PROGRESS_TEXT}: {progress}%
                    </div>
                }
            </div>
        }
        {backups.map((backup) => {
            const actions = isRestoreDisabled ? null : (
                <div className={classActions}>
                    {isOpened && <>
                        <Button
                            variant={'icon'}
                            tooltip={ getAsidePanelTooltip({
                                direction: 'up-left',
                                value: BACKUP_RESTORE_TEXT,
                            })
                            }
                            onClick={() => {
                                onBackupRestore(backup.fileId);
                                setOpened(false);
                            }}
                        >
                            <Icon size={24} xlink={icon} />
                        </Button>
                        {isShowRestoreToSharedDrive &&
                            <ButtonDropdown
                                className={`${classDropdown} ${isLoading ? classDropdown + '--loading' : ''}`}
                                dropdownClassName={`${classDropdown}-item`}
                                directionHorizontal={'right'}
                                onOpen={() => setOpened(true)}
                                onClose={() => setOpened(false)}
                            >
                                <Button
                                    variant={'icon'}
                                    tooltip={getAsidePanelTooltip({
                                        direction: 'up-left',
                                        value: BACKUP_RESTORE_TEXT_SHARED,
                                    })
                                    }
                                >
                                    <Icon size={24} xlink={'share-specific'}/>
                                </Button>
                                {isLoading
                                    ? <LoaderBlock className={classEmpty + '-loader'}/>
                                    : planBlocker ?
                                        <EmptyDummy
                                            className={classEmpty}
                                            {...getEmptyDummy()[planBlocker]}
                                        />
                                        : scopesBlocker ?
                                            <EmptyDummy
                                                className={classEmpty}
                                                {...getEmptyDummy()[scopesBlocker]}
                                            />
                                            : <SearchSelect
                                                className={CLASS_BOARD_BACKUPS + '__search'}
                                                options={options}
                                                onSelect={(driveId) => {
                                                    onBackupRestore(backup.fileId, driveId);
                                                    setOpened(false);
                                                }}
                                                isCloseOnClick={false}
                                                opened
                                                autoFocus
                                            />
                                }
                            </ButtonDropdown>
                        }
                    </>
                    }
                    <Button
                        variant={'icon'}
                        onClick={() => setOpened(!isOpened)}
                    >
                        <Icon size={24} xlink={isOpened ? 'close' : 'arrow-circle'} />
                    </Button>
                </div>
            );

            const img = (
                <Icon
                    size={24}
                    xlink={'board'}
                />
            );

            const created = Math.floor(backup.created / 1000);

            const text = (
                <Tooltip
                    isNoWrap={true}
                    value={escape(backup.title)}
                >
                    <span>
                        {Util.formatDateMoment(created) + ' ' + Util.formatTime(created)}
                    </span>
                </Tooltip>
            );

            return <ListItem
                actions={actions}
                img={img}
                key={backup.fileId}
                className={`${classItem} ${isOpened ? classItem + '--opened' : ''}`}
                text={text}
            />
        })}
    </div>
};
