import * as React from 'react';
import { Snackbar as SnackbarKUI, ISnackbarProps } from 'kui';
import { ISnackbarsStackProps } from '../../hocs/SnackbarsStackHOC/types';
import './_Snackbar.scss';
import FocusLock from 'react-focus-lock';
import { getParentsClasses } from '../../../helpers/getParentsClasses';

export const Snackbar = (props: ISnackbarProps & ISnackbarsStackProps) => {
    const {
        snackbarId,
        ...snackbar
    } = props;

    // FocusLock нужен, если 2 кнопки, т.е. требуется действие пользователя
    const [isFocusLockDisabled, setFocusLockDisabled] = React.useState(!snackbar.buttons || snackbar.buttons.length < 2);
    const timer = React.useRef(null);

    function onBlur (event: React.FocusEvent) {
        if (timer.current) return;

        const targetClasses = getParentsClasses(
            event.relatedTarget as HTMLElement,
            ['kui-snackbar']
        );
        if (targetClasses.includes('kui-snackbar')) return;
        setFocusLockDisabled(true); // если кликнули вне снекбара, отключаем FocusLock
    }

    function onKeyDown (event: React.KeyboardEvent) {
        if (event.key === 'Tab') { // если нажали Tab, то не ловим onBlur
            if (timer.current) clearTimeout(timer.current);
            timer.current = setTimeout(() => {
                timer.current = null;
            }, 200);
        }
    }

    return (
        <FocusLock
            disabled={isFocusLockDisabled}
            returnFocus
        >
            <SnackbarKUI
                {...snackbar}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
            />
        </FocusLock>
    );
};
