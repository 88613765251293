import { REST_LIST } from '../../../../constants';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { fetchHandler } from '../../../../../util/fetchHandler';
import { IRestCard } from '../../../../../types/rest/IRestCard';
import Util from '../../../../../util/util';
import { ICard } from '../../../../../store/model/card/types';
import { getRestHeadersPost } from '../../../../helpers/getRestHeadersHelper';
import { IGetState } from '../../../../../types/types';
import { TPostRestOptions } from './types';

export const postRest = (
    body: ICard,
    options: TPostRestOptions
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const url = options.url ? options.url : REST_LIST + body.listId + '/cards';
        return fetchHandler<IRestCard>(
            Util.getApiUrl(url), {
                ...getRestHeadersPost(),
                body: JSON.stringify(body)
            }
        );
    };
    return action;
};
