import { IApplicationState } from '../../../../types/types';
import { IDriveDoc } from '../../card/types/IDriveDoc';
import { TDriveDocId } from '../../../../types/rest/IRestDriveDoc';

export const getDriveDoc = (
    state: IApplicationState,
    driveDocId: TDriveDocId
): IDriveDoc => {
    return state.model.driveDocs && state.model.driveDocs[driveDocId];
};
