import { IDropdownPublicProps, IInputPublicProps } from 'kui';

export enum ESearchSelectCreationSource {
    KEYBOARD = 'KEYBOARD',
    MOUSE = 'MOUSE'
}

export interface ISearchSelectOption {
    active?: boolean;
    className?: string;
    fields?: Record<string, string>; // все дополнительные поля для поиска: Description, Custom properties, Checklists, Attachments, Comments
    img?: string;
    options?: ISearchSelectOption[];
    text: string;
    textSub?: any;
    value: string;
    disabled?: boolean;
    parentValue?: string; // для уникального key, теперь результаты могут дублироваться для разных fields
    initials?: string; // для Userpic
    onChange?: () => void;
    description?: string;
    lastActivity?: number;
}

export interface ISearchSelectOptionFields extends ISearchSelectOption {
    className?: string;
    multiple?: boolean;
    isIndeterminate?: boolean; // nested options
    option?: (element: ISearchSelectOptionProps) => JSX.Element; // function component for option
    isCollapsed?: boolean; // nested options
    level?: number;
    isMultipleGroupToggle?: boolean; // включить/отключить чекбоксы на группах в multiple режиме
    checkboxDirection?: 'left' | 'right';
}

export interface ISearchSelectOptionEvents {
    onLevelToggle?: (value: string) => void; // nested options expand-collapse
}

export interface ISearchSelectOptionProps extends
    ISearchSelectOptionFields,
    ISearchSelectOptionEvents
{}

export interface ISearchSelectFields {
    options: ISearchSelectOption[]; // all available options
    option?: (element: ISearchSelectOptionProps) => JSX.Element; // function component for option
    activeValue?: string;
    autoFocus?: boolean;
    placeholder?: string;
    text?: string; // input value for closed state
    multiple?: boolean; // checkboxes in dropdown
    single?: boolean; // close dropdown on click on name
    isMountDropdown?: boolean;
    isCloseOnClick?: boolean;
    isAcronymSearchEnabled?: boolean;
    isDropdownUplifted?: boolean;
    isKeepValue?: boolean;
    searchDebounce?: number;
    create?: string; // label 'Create new card'. if empty, doesn't show
    createHintIcon?: boolean;
    createHintList?: boolean;
    createHints?: boolean;
    isCloseOnEnter?: boolean;
    placeholderNotFound?: string; // когда что-то ввели, и ничего не нашлось
    placeholderEmpty?: string; // когда поиск пустой, и нет элементов
    isEnterEnabled?: boolean;
    isMultipleGroupToggle?: boolean; // включить/отключить чекбоксы на группах в multiple режиме
    checkboxDirection?: 'left' | 'right';
    stickyOption?: JSX.Element;
    footer?: JSX.Element;
}

export interface ISearchSelectEvents {
    onActiveChange?: (value: string) => void;
    onClosed?: (isSelected?: boolean) => void;
    onCreate?: (text: string, source: ESearchSelectCreationSource) => void;
    onOpened?: () => void;
    onSelect: (value: string) => void;
    onInputChange?: (value: string) => void;
    onInputKeyDown?: (keyCode: number) => void;
    onFilterOptions?: (list: ISearchSelectOption[], searchQuery: string, isAcronymSearchEnabled: boolean) => ISearchSelectOption[];
}

export type IConflictFreeHTMLAttributes<E> =
    Pick<React.HTMLAttributes<E>, Exclude<keyof React.HTMLAttributes<E>,
        'color' |
        'onSelect'
    >>;

export interface ISearchSelectProps extends
    IInputPublicProps,
    IDropdownPublicProps,
    ISearchSelectFields,
    ISearchSelectEvents,
    IConflictFreeHTMLAttributes<HTMLElement>
{}
