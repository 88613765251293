import * as React from 'react';
import { SelectListItem } from 'kui';
import { IAssigneesAddProps } from './types';
import { UserpicStorage } from 'app/view/react_components/base/components/UserpicStorage/UserpicStorage';

export function AssigneesAdd({
    img,
    initials,
    text,
    value,
}: IAssigneesAddProps) {
    return (
        <SelectListItem value={value}>
            <UserpicStorage
                initials={initials}
                size={24}
                src={img}
                userId={value}
            />
            {text}
        </SelectListItem>
    );
};
