import { createRoot } from 'react-dom/client';
import {Provider} from "react-redux";
import React from "react";
import { LazyWrapper } from '../react_components/lazy/lazy_wrapper';

App.Views.MultiCardFormNew = App.Views.BaseView.extend({
    asideClassName: 'aside-panel__card',

    initialize: function (options) {
        this.root = createRoot(this.el);
        this.store = options.store;
        this.boardId = options.boardId;
        this.parent = options.parent;
    },

    render: function () {
        this.parent.$el.find('.aside-panel__hide').hide();

        const ImportElement = React.lazy(() =>
            import(/* webpackChunkName: "cardDetails" */ '../react_components/aside_panel/asidePanel/hocs/AsidePanelMultiselectHOC/AsidePanelMultiCardFormHOC')
                .then(module => ({default: module.AsidePanelMultiCardFormHOC}))
                .catch(e => console.error(e))
        );

        this.root.render(
            <LazyWrapper>
                <Provider store={this.store}>
                    <ImportElement
                        boardId={this.boardId}
                        closedRef={document.activeElement}
                    />
                </Provider>
            </LazyWrapper>
        );

        return this;
    },

    remove: function () {
        this.parent.$el.find('.aside-panel__hide').show();
        setTimeout(() => {
            setTimeout(() => {
                this.root.unmount(); // надо дождаться пока сработает react onblur, только потом отцеплять конпонент
            });
        });
        Backbone.View.prototype.remove.call(this);
    }
});

export default App.Views.MultiCardFormNew;
