import { IUserBoardPermissionsBackboneModel } from './../../../../../types/backbone/IUserBoardPermissionsBackboneModel';
import { TBoardId, TCardId } from '../../../../../types/types';
import { IApplicationState } from '../../../../../types/types';
import { ESettingsSectionType } from '../../../base/components/SettingsSection/types';
import { getSectionType } from './getSectionType';
import { IAuthUser } from '../../../../../store/model/authUser/types';

export const getPanelButtonShow = (
    state: IApplicationState,
    boardId: TBoardId,
    cardId: TCardId,
    typeSection?: ESettingsSectionType,
    allowSection?: keyof IAuthUser, // todo achtung kill backbone
    boardAllow?: keyof IUserBoardPermissionsBackboneModel, // todo achtung kill backbone
    isCheckArchive: boolean = true,
): boolean => {
    const sectionType = getSectionType(state, boardId, cardId, typeSection, allowSection, boardAllow, isCheckArchive);
    const isShow = (
        sectionType === ESettingsSectionType.DEFAULT ||
        sectionType === ESettingsSectionType.DISALLOW
    );

    return isShow;
}
