import { IGetState, TCardId, TListId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { SegmentCardEvent, SegmentCardSection, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { cardMoveToList } from '../../../../../../../../rest/effects/card/card/move/cardMoveToList';
import { listUpdateMinMaxOrderNumber } from '../../../../../../../../rest/effects/list/listUpdateMinMaxOrderNumber';
import { getListViewCard } from '../../../../../../listView/selectors/getListViewCard';
import { getList } from '../../../../../../../../store/model/list/selectors/getList';
import { getBoardByCardId } from '../../../../../../../../store/model/selectors/getBoardByCardId';

export const onSelect = (
    cardId: TCardId,
    listId: TListId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const { name } = getList(state, listId);
        const board = getBoardByCardId(state, cardId);
        const doneListId = board && board.statusLists && board.statusLists.doneId
        const moveToDoneList = doneListId === listId;

        dispatch(segmentTrackAction(SegmentCardEvent.LIST_AT_CARD_IN_LIST_CHOSEN, {
            name: SegmentCardSection.DONE,
            value: String(doneListId ? moveToDoneList : name === 'Done')
        }));
        return dispatch(cardMoveToList(cardId, listId))
            .then(() => {
                const { orderNumber } = getListViewCard(getState(), cardId);
                dispatch(listUpdateMinMaxOrderNumber(listId, [orderNumber]))
            })
    };
    return action;
};
