import { PageCollection } from '@microsoft/microsoft-graph-client';
import { Person } from '@microsoft/microsoft-graph-types';
import { errorHandler, graphClient } from 'app/helper/authorisation/microsoft/graph/Graph';

export interface IGraphPeopleSearchItem {
    id: string;
    displayName: string;
    email: string;
}

export const peopleSearch = async (
    search: string
): Promise<IGraphPeopleSearchItem[]> => {
    try {
        let request = graphClient.api('/me/people').select(['scoredEmailAddresses', 'displayName', 'id']);
        if (search) {
            request.search(`"${search}"`)
        }
        let response = await request.get() as PageCollection;
        let people = response.value as Person[];
        return people.map((val) => {
            return {
                id: val.id,
                displayName: val.displayName,
                email: val.scoredEmailAddresses ? val.scoredEmailAddresses[0].address : null
            }
        })
    } catch (e) {
        return errorHandler(e);
    }
}
