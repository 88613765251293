import { ECardField, IOrderType, IListViewCard } from '../store/listViewBoard/types';
import { getCardField } from '../constants';
import { sortCardsByDate } from './sortCardsByDate';
import { IApplicationState, TBoardId } from '../../../../types/types';

export const sortCardsByNumericValue = (
    state: IApplicationState,
    boardId: TBoardId,
    cardA: IListViewCard,
    cardB: IListViewCard,
    orderType: IOrderType
): number => {
    const { field, isAsc } = orderType;
    const key = getCardField(field, state, boardId).sortField as keyof IListViewCard;

    let valueA: number;
    let valueB: number;

    switch (field) {
        case ECardField.CHECKLIST: {
            valueA = cardA[key] ? (cardA[key] as IListViewCard['checklistStats']).totalCount : null;
            valueB = cardB[key] ? (cardB[key] as IListViewCard['checklistStats']).totalCount : null;
            break;
        }
        case ECardField.ESTIMATE:
        case ECardField.SPENT_TIME: {
            valueA = cardA[key] ? Number(cardA[key]) : null;
            valueB = cardB[key] ? Number(cardB[key]) : null;
            break;
        }
        case ECardField.PRIORITY: {
            valueA = Number(cardA[key]);
            valueB = Number(cardB[key]);
            break;
        }
        default:
            valueA = Number(cardA[key]);
            valueB = Number(cardB[key]);
    }

    if (
        !valueA && !valueB ||
        valueA === valueB
    ) return sortCardsByDate(cardA, cardB);

    if (field !== ECardField.PROGRESS) { // для прогресса все карты участвуют в сортировке, для остальных сортировок пустые поля всегда в конце
        if (!valueA) return 1;
        if (!valueB) return -1;
    }

    return isAsc ? (valueA - valueB) : (valueB - valueA);
};
