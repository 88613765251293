import { Dispatch, ThunkAction } from '../../../types/actions';
import {
    SegmentUserEvent,
    SegmentUserLandingSourceValue,
    SegmentUserOption,
    SegmentUserOptionValue
} from '../trackEntities/userEvents';
import { segmentTrackAction } from '..';
import { root } from '../../../store/constants';
import { VirtualPage } from '../trackEntities/virtualPages';
import {
    LANDING_N1_URL,
    LANDING_N2_URL,
    LANDING_TASKMANAGER_URL,
    LANDING_KANBAN_URL,
    LANDING_GANTTCHART_URL,
    LANDING_BRAZIL_URL,
    LANDING_TM_URL
} from '../../../const';
import { ISegmentEventOption } from '../trackEntities/types';
import {FacebookStandardEvent} from '../trackEntities/facebookEvents';
import { TwitterStandardEvent } from 'app/middlewares/segment/trackEntities/twitterEvents';
import { IGetState } from 'app/types/types';
import { isUserAccountTypeDomain } from 'app/store/model/authUser/selectors/isUserAccountTypeDomain';
import { EAuthUserPlatformType } from 'app/types/rest/IRestAuthUser';

export const permissionDriveScopeRequestedEvent = (
    targetValue: SegmentUserOptionValue
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        const event = SegmentUserEvent.PERMISSION_DRIVE_SCOPE_REQUESTED;
        dispatch(segmentTrackAction(event, {
            name: SegmentUserOption.TAGRET,
            value: targetValue
        }));
    };
    return action;
};

export const permissionDriveScopeGrantedEvent = (
    granted: boolean,
    targetValue: SegmentUserOptionValue
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        const event = SegmentUserEvent.PERMISSION_DRIVE_SCOPE_GRANTED;
        dispatch(segmentTrackAction(event, {
            name: SegmentUserOption.TAGRET,
            value: targetValue
        }));
    };
    return action;
};

export const permissionsButtonClickedEvent = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        const event = SegmentUserEvent.PERMISSIONS_BUTTON_CLICKED;
        dispatch(segmentTrackAction(event));
        root.App.controller.trackPage(VirtualPage.PERMISSIONS_BUTTON_CLICKED);
        root.App.controller.trackFacebookCustomEvent(VirtualPage.PERMISSIONS_BUTTON_CLICKED);
    };
    return action;
};

export const permissionScreenEvent = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        const event = SegmentUserEvent.PERMISSION_SCREEN;
        dispatch(segmentTrackAction(event));
        root.App.controller.trackPage(VirtualPage.PERMISSION_SCREEN);
        root.App.controller.trackFacebookCustomEvent(VirtualPage.PERMISSION_SCREEN);
    };
    return action;
};

export const signedUpEvent = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        dispatch(segmentTrackAction(SegmentUserEvent.SIGNED_UP));
        root.App.controller.trackPage(VirtualPage.SIGNED_UP);
        root.App.controller.trackFacebookCustomEvent(VirtualPage.SIGNED_UP);
        root.App.controller.trackFacebookStandardEvent(FacebookStandardEvent.SIGNED_UP);

        if (isUserAccountTypeDomain(getState())) {
            dispatch(segmentTrackAction(SegmentUserEvent.SIGNED_UP_DOMAIN));
            //@ts-ignore
            gtag('event', 'conversion', {'send_to': 'AW-753015000/YeYFCIKh-u4BENixiOcC'});
        }
    };
    return action;
};

const getSignButtonSourceOption = (): ISegmentEventOption => {
    let source: SegmentUserLandingSourceValue = null;
    if (location.pathname === LANDING_N1_URL) {
        source = SegmentUserLandingSourceValue.GOOGLE_WORKSPACE_PM
    } else if (location.pathname === LANDING_N2_URL) {
        source = SegmentUserLandingSourceValue.GOOGLE_WORKSPACE_PM_V2;
    } else if (location.pathname === LANDING_TASKMANAGER_URL) {
        source = SegmentUserLandingSourceValue.TASKMANAGER;
    } else if (location.pathname === LANDING_KANBAN_URL) {
        source = SegmentUserLandingSourceValue.KANBAN;
    } else if (location.pathname === LANDING_GANTTCHART_URL) {
        source = SegmentUserLandingSourceValue.GANTTCHART;
    } else if (location.pathname === LANDING_BRAZIL_URL) {
        source = SegmentUserLandingSourceValue.BRAZIL;
    } else if (location.pathname === LANDING_TM_URL) {
        source = SegmentUserLandingSourceValue.TM;
    }
    return source ? {name: SegmentUserOption.SOURCE, value: source} : null;
}

export const signInShowedEvent = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        const event = SegmentUserEvent.SIGN_IN_SHOWED;
        dispatch(segmentTrackAction(event, getSignButtonSourceOption()));
        root.App.controller.trackPage(VirtualPage.SIGN_IN_SHOWED);
        root.App.controller.trackFacebookCustomEvent(VirtualPage.SIGN_IN_SHOWED);
    };
    return action;
};

export const signInClickedEvent = (
    platformType: EAuthUserPlatformType,
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        const event = SegmentUserEvent.LOGIN_PRESSED;
        dispatch(segmentTrackAction(event, getSignButtonSourceOption(), [{
            name: 'type',
            value: platformType,
        }]));
        root.App.controller.trackPage(VirtualPage.LOGIN_PRESSED);
        root.App.controller.trackFacebookCustomEvent(VirtualPage.LOGIN_PRESSED);
        if (location.pathname === LANDING_TASKMANAGER_URL ||
            location.pathname === LANDING_BRAZIL_URL ||
            location.pathname === LANDING_TM_URL) {
            root.App.controller.trackTwitterStandardEvent(TwitterStandardEvent.SIGNED_UP);
        }
    };
    return action;
};

export const signInErrorEvent = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        const event = SegmentUserEvent.TROUBLES_LOGIN;
        dispatch(segmentTrackAction(event));
    };
    return action;
};

export const tryAgainClickedEvent = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        const event = SegmentUserEvent.TROUBLES_TRY;
        dispatch(segmentTrackAction(event));
    };
    return action;
};

export const faqClickedEvent = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        const event = SegmentUserEvent.TROUBLES_FAQ;
        dispatch(segmentTrackAction(event));
    };
    return action;
};

export const reloadClickedEvent = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        const event = SegmentUserEvent.TROUBLES_RELOAD;
        dispatch(segmentTrackAction(event));
    };
    return action;
};

export const contactClickedEvent = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        const event = SegmentUserEvent.TROUBLES_HELP;
        dispatch(segmentTrackAction(event));
    };
    return action;
};
