import { root } from '../../../../../store/constants';
import { TStatus } from '../../../../../types/model';
import { TBoardId } from '../../../../../types/types';
import { getCardRelatedIcon } from '../../../helpers/getCardIcon';
import { stripHtml } from '../../../base/helpers/stripHtmlHelper';
import { markdown } from '../../../../../helper/markdownHelper';
import { IRelatedCard } from '../components/RelatedCard/types';

const getRelatedCardsSortFunction = (
    boardId: TBoardId
) => (
    a: IRelatedCard,
    b: IRelatedCard
) => {
    // доска редактируемой карты всегда должна быть выше любой другой, поэтому ей ставим наименьший ID = 0
    const dashboardIdA = a.dashboardId === boardId ? 0 : a.dashboardId;
    const dashboardIdB = b.dashboardId === boardId ? 0 : a.dashboardId;
    if (dashboardIdA === dashboardIdB) {
        return a.cardName.localeCompare(b.cardName);
    } else {
        return dashboardIdA - dashboardIdB;
    }
}

export const buildRelatedCards = (
    boardId: TBoardId,
    relatedCards: IRelatedCard[],
): IRelatedCard[] => {
    const cards = relatedCards.reduce((cardsFiltered, card) => {
        if (card.status === TStatus.STATUS_ACTIVE) {
            cardsFiltered.push({
                ...card,
                cardName: stripHtml(markdown(card.cardName)),
                icon: getCardRelatedIcon(card.cardStatus),
                link: root.App.router.getCardPageUrl(card.dashboardId, card.cardId),
            });
        }
        return cardsFiltered;
    }, []);
    cards.sort(getRelatedCardsSortFunction(boardId));
    return cards;
}
