import { Dispatch } from '../../../../../../../types/actions';
import { TCardId } from '../../../../../../../types/types';
import { resetTimeTracker } from '../../../effects/resetTimeTracker';
import { closeTimeTrackerDialog } from '../../../effects/closeTimeTrackerDialog';
import { startTrackingCard } from '../../../../../timeTracker/hocs/TimeTrackerHOC/effects/startTrackingCard';

export const onDiscard = (
    targetCardId: TCardId
) => {
    return (dispatch: Dispatch) => {
        dispatch(resetTimeTracker());
        dispatch(closeTimeTrackerDialog());
        if (targetCardId) {
            dispatch(startTrackingCard(targetCardId));
        }
    };
};
