import { IGetState } from './../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { root } from '../../../../../../../store/constants';
import {
    SegmentPricingOption,
    SegmentPricingSourceValue
} from '../../../../../../../middlewares/segment/trackEntities/pricingEvents';

export const onClickBuyFromModal = (
): ThunkAction =>  {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const eventOptions = {
            name: SegmentPricingOption.SOURCE,
            value: SegmentPricingSourceValue.FREE_VERSION_MODAL
        };
        root.App.controller.showPayment(eventOptions);
    };
    return action;
};
