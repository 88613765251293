import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../types/types';
import { clickBoard } from '../effects/clickBoard';
import { recentBoardTabClickedSegmentEvent } from '../../../../effects/segmentEvents';
import { getUserRecentBoards } from '../../../../../../../../store/model/authUser/selectors/getUserRecentBoards';
import { getTab } from '../../../../store/selectors/getTab';
import { addFeatureTrigger } from '../../../../../../polls/featurePoll/effects/addFeatureTrigger';
import { ERateFeature } from '../../../../../../../../types/rest/IUserMeta';

export const onClickBoard: ActionCreator<ThunkAction> = (
    boardId: number
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const recentBoards = getUserRecentBoards(state).filter(board => !getTab(state, board.id));
        const idx = recentBoards.findIndex(({ id }) => id === boardId);
        dispatch(recentBoardTabClickedSegmentEvent(idx + 1));
        dispatch(clickBoard(boardId));
        dispatch(addFeatureTrigger(ERateFeature.NEW_NAV_PANEL));
    };
    return action;
};
