import * as React from 'react';
import { IAsidePanelClButtonProps } from './types';
import './_AsidePanelClButton.scss';
import { Button, Icon} from 'kui';
import { CLASS_ASIDE_PANEL_CL_BUTTON, LINK_CARD_BUTTON_TEXT } from './constants';
import { getAsidePanelTooltip } from '../../helpers/getAsidePanelTooltip';

export function AsidePanelClButton({
    onClick
}: IAsidePanelClButtonProps) {
    return (
        <>
            <Button
                className={`
                    ${CLASS_ASIDE_PANEL_CL_BUTTON}
                    ${CLASS_ASIDE_PANEL_CL_BUTTON}--text
                `}
                variant={'primary'}
                aria-label={LINK_CARD_BUTTON_TEXT}
                onClick={onClick}
            >
                {LINK_CARD_BUTTON_TEXT}
            </Button>
            <Button
                className={`
                    ${CLASS_ASIDE_PANEL_CL_BUTTON}
                    ${CLASS_ASIDE_PANEL_CL_BUTTON}--icon
                `}
                tooltip={getAsidePanelTooltip({
                    direction: 'down',
                    value: LINK_CARD_BUTTON_TEXT
                })}
                variant={'icon'}
                aria-label={LINK_CARD_BUTTON_TEXT}
                onClick={onClick}
            >
                <Icon size={24} xlink={'board'} />
            </Button>
        </>
    );
}
