import * as React from 'react';
import { Button } from 'kui';
import {CREATE_NEW_TEXT} from '../../constants';
import './createNew.scss';
import { ICreateNewProps } from './types';

export function CreateNew({
    onAdd
}: ICreateNewProps) {
    return (
        <div className={'create-template'}>
            <Button
                 className={'create-template__button'}
                variant={'add'}
                onClick={onAdd}
            >
                {CREATE_NEW_TEXT}
            </Button>
        </div>
    )
}
