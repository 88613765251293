import { getBoardMetaCardAging } from '../../selectors/getBoardMetaCardAging';
import { IApplicationState, TBoardId } from '../../../../types/types';
import { ICardAgingSettings } from '../../../../types/rest/ICardAging';
import { getBoardCardAgingEnabled } from './getBoardCardAgingEnabled';

export const getBoardCardAgingSettings = (
    state: IApplicationState,
    boardId: TBoardId
): ICardAgingSettings => {
    const boardAging = getBoardMetaCardAging(state, boardId);
    const isEnabled = getBoardCardAgingEnabled(state, boardId);

    return boardAging && isEnabled && boardAging.cardAgingSettings;
}
