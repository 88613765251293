import { IApplicationState, TBoardId } from '../../../types/types';
import { IBoard } from '../board/types';
import { getBoard } from './getBoardById';

export const getBoardForRollback = (
    state: IApplicationState,
    boardId: TBoardId,
    patchBoard: IBoard
): IBoard => {
    const board = getBoard(state, boardId);
    if (!patchBoard || !board) return;
    let boardForRollback: IBoard = {
        meta: board.meta // это для syncBackbone, потом удалить
    };

    for (const field in patchBoard) {
        const fieldKey = field as keyof IBoard;
        boardForRollback = {
            ...boardForRollback,
            [fieldKey]: board[fieldKey]
        };
    }

    return JSON.parse(JSON.stringify(boardForRollback));
}
