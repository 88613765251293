import {ActionCreator} from 'redux';
import {Dispatch, ThunkAction} from '../../../../../../../types/actions';

export const onCloseTrialExtend: ActionCreator<ThunkAction> = (
) => {
    const action = (
        dispatch: Dispatch
    ) => {
        // no event
    };
    return action;
};
