import { IGetState, TCardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import {
    addUpdateSnackbarWithBubble
} from '../../../../../../snackbarsStack/effects/addUpdateSnackbarWithBubble/addUpdateSnackbarWithBubble';
import { ESnackbarPosition, SnackbarVariant } from '../../../../../../snackbarsStack/store/types';
import {
    SNACKBAR_BUTTON_CLOSE,
    SNACKBAR_ID_CARD_ADD_TO_MY_WORK
} from '../../../../../../snackbarsStack/effects/constants';
import { getCard } from '../../../../../../../../store/model/selectors/getCard';
import { ADD_TO_MY_WORK_SNACKBAR_ADD, ADD_TO_MY_WORK_SNACKBAR_REMOVE } from '../constants';
import { getDefaultActionEvent } from '../../../../../../snackbarsStack/effects/helpers/getDefaultActionEvent';
import { escape } from 'underscore';
import { clearMarkdown } from '../../../../../../base/helpers/clearMarkdownHelper';
import { getIsCardInMyWork } from '../selectors/getIsCardInMyWork';
import { addOrRemoveCardAssignee } from '../effects/addOrRemoveCardAssignee';

export const onClick = (
    cardId: TCardId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(addOrRemoveCardAssignee(cardId));
        const state = getState();
        const { name } = getCard(state, cardId);
        const isAddedToMyWork = getIsCardInMyWork(state, cardId);
        dispatch(addUpdateSnackbarWithBubble({
            id: SNACKBAR_ID_CARD_ADD_TO_MY_WORK,
            controls: [{
                action: () => dispatch(getDefaultActionEvent(SNACKBAR_ID_CARD_ADD_TO_MY_WORK)),
                title: SNACKBAR_BUTTON_CLOSE,
                icon: 'close'
            }],
            text: !isAddedToMyWork ? ADD_TO_MY_WORK_SNACKBAR_ADD : ADD_TO_MY_WORK_SNACKBAR_REMOVE,
            title: escape(clearMarkdown(name)),
            variant: SnackbarVariant.UNDO,
            position: ESnackbarPosition.left
        }));
    };
    return action;
};
