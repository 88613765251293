import { ICards } from '../../../../../store/model/cards/types';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { IGetState } from '../../../../../types/types';
import { cardAssigneesActionSetAction } from '../../../../../store/model/actions/cardAssigneesActionSetAction';
import { cardAssigneeUpdateAction } from '../../../../../store/model/cardAssignees/actions/cardAssigneeUpdateAction';
import { getCardMyWorkCard } from '../selectors/getCardMyWorkCard';
import { getCard } from '../../../../../store/model/selectors/getCard';

export const updateCardAssigneesFromCards = (
    cards: ICards
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        for (let cardId in cards) {
            const card = getCard(getState(), Number(cardId));
            dispatch(cardAssigneesActionSetAction(cardAssigneeUpdateAction(card.id, getCardMyWorkCard(getState(), card))));
        }
    }
    return action;
}
