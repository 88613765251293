import * as React from 'react';
import { IDriveDocsImgsProps } from './types';
import { CARD_CLASS, CARD_CONTROL_CLASS } from 'app/view/react_components/main/kanbanView';
import './_DriveDocsImgs.scss';
import { LoaderBlock, Tooltip } from 'kui';
import { THUMB_CARD_HEIGHT, THUMB_CARD_SIZE } from 'app/const';
import { CARD_IMG_WIDTH, DRIVE_DOCS_IMGS_GOOGLE_THUMB_SIZE } from './constants';
import { EAuthUserPlatformType } from 'app/types/rest/IRestAuthUser';
import { msFileThumbnailStorage } from 'app/helper/authorisation/microsoft/storage/msThumbnail';
import { escape } from 'underscore';
import { googleFileThumbnailStorage } from 'app/helper/authorisation/google/storage/googleThumbnail';
import { IDriveDoc } from '../../../../../../../../store/model/card/types/IDriveDoc';

export function DriveDocsImgs({
                                  images,
                                  pinnedImageIndex,
                                  onChange,
                              }: IDriveDocsImgsProps) {
    const className = CARD_CLASS + '__drive-docs-imgs';
    const classDot = className + '-dot';

    const [isLoading, setLoading] = React.useState(true);
    const [isError, setError] = React.useState(null);
    const [imgIdx, setImgIdx] = React.useState(0);
    const [height, setHeight] = React.useState(0);

    const imagesRef = React.useRef(null);

    function onError (driveDoc: IDriveDoc) {
        if (googleFileThumbnailStorage.is()) {
            googleFileThumbnailStorage.reGetThumbnail(
                driveDoc.fileId,
                DRIVE_DOCS_IMGS_GOOGLE_THUMB_SIZE,
                (thumb) => {
                    setImagesThumbUrl({
                        ...imagesThumbUrl,
                        [driveDoc.id]: thumb
                    });
                },
                () => setError(true),
            ).finally(() => {
                setLoading(false);
            });
        }
    }

    function onScroll() {
        setImgIdx(Math.ceil(Math.max(imagesRef.current.scrollLeft - CARD_IMG_WIDTH / 2, 0) / CARD_IMG_WIDTH));
    }

    React.useEffect(() => {
        function onScrollEnd() {
            onChange(Math.ceil(Math.max(imagesRef.current.scrollLeft - CARD_IMG_WIDTH / 2, 0) / CARD_IMG_WIDTH));
        }

        const el = imagesRef.current as HTMLElement;
        if (!el) return;
        el.addEventListener('scrollend', onScrollEnd);

        return () => {
            const el = imagesRef.current as HTMLElement;
            if (!el) return;
            el.removeEventListener('scrollend', onScrollEnd);
        }
    }, []);

    React.useEffect(() => {
        const idx = pinnedImageIndex > images.length - 1 // если отпинили последнею картинку
            ? images.length - 1
            : pinnedImageIndex || 0;
        setImgIdx(idx);

        if (imagesRef.current) {
            imagesRef.current.scrollLeft = CARD_IMG_WIDTH * idx;
        }
    }, [pinnedImageIndex]);

    let [imagesThumbUrl, _setImagesThumbUrl] = React.useState<Record<string, string>>({});
    const setImagesThumbUrl = (images: any) => {
        imagesThumbUrl = images;
        _setImagesThumbUrl(images);
    }
    React.useEffect(() => {
        if (images && images.length) {
            let height = 0;
            images.map(driveDoc => {
                if (driveDoc.platformType === EAuthUserPlatformType.MICROSOFT ){
                    if (msFileThumbnailStorage.is()) {
                        if (!imagesThumbUrl[driveDoc.id]) {
                            const thumbSize = `c${THUMB_CARD_SIZE}x${THUMB_CARD_SIZE}`;
                            msFileThumbnailStorage.getThumbnail(driveDoc.driveId, driveDoc.fileId, thumbSize)
                                .then(thumb => setImagesThumbUrl({
                                    ...imagesThumbUrl,
                                    [driveDoc.id]: thumb.url,
                                }));
                        }
                    }
                } else if (googleFileThumbnailStorage.is()) {
                    googleFileThumbnailStorage.getThumbnail(driveDoc.fileId,DRIVE_DOCS_IMGS_GOOGLE_THUMB_SIZE)
                        .then(thumb => setImagesThumbUrl({
                            ...imagesThumbUrl,
                            [driveDoc.id]: thumb
                        }));
                }
                if (height < THUMB_CARD_HEIGHT) {
                    let h = driveDoc.thumbHeight;
                    if (h > THUMB_CARD_HEIGHT) h = THUMB_CARD_HEIGHT;
                    if (h > height) height = h;
                }
                setHeight(height);
            })
        } else {
            setImagesThumbUrl({});
        }
    }, [images]);

    return (
        <div
            className={`
                ${className}
                ${images.length > 1 ? className + '--multiple' : ''}
            `}
        >
            {!isError &&
            <>
                <div
                    className={className + '-images'}
                    ref={imagesRef}
                    style={{
                        height
                    }}
                    onScroll={onScroll}
                >
                    {isLoading && <LoaderBlock/>}
                    {images.map(driveDoc => (
                        <div className={className + '-image'} key={driveDoc.id}>
                            <Tooltip
                                isNoWrap={true}
                                value={escape(driveDoc.title)}
                            >
                                <img
                                    src={imagesThumbUrl[driveDoc.id]}
                                    onLoad={() => setLoading(false)}
                                    onError={() => onError(driveDoc)}
                                />
                            </Tooltip>
                        </div>
                    ))
                    }
                </div>
                {images.length > 1 &&
                <div className={className + '-dots'}>
                    {images.map((driveDoc, index) => (
                        <Tooltip
                            isNoWrap={true}
                            key={driveDoc.id}
                            value={escape(driveDoc.title)}
                        >
                            <div
                                className={`
                                            ${CARD_CONTROL_CLASS}
                                            ${classDot}
                                            ${index === imgIdx ? classDot + '--selected' : ''}
                                        `}
                                onClick={() => onChange(index)}
                            />
                        </Tooltip>
                    ))
                    }
                </div>
                }
            </>
            }
        </div>
    );
}
