import {Dispatch, ThunkAction} from 'app/types/actions';
import { SegmentCardEvent, segmentTrackAction } from 'app/middlewares/segment';

export const onEsc = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.DESCRIPTION_ESCAPE_CLICKED));
    };
    return action;
};
