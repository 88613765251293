import { IApplicationState, TBoardId } from '../../../../types/types';
import { getBoard } from '../../selectors/getBoardById';

export const getBoardShowIconAndColorLogo = (
    state: IApplicationState,
    boardId: TBoardId,
): boolean => {
    const board = getBoard(state, boardId);
    return board && board.isShowIconAndColorLogo;
}
