import { getByIdRest } from '../../../../../../../../rest/effects/activity/api/getByIdRest';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TActivityId } from '../../../../../../../../types/types';
import { TRestActivity } from '../../../../../../../../types/rest/activity/IRestActivities';
import { updateActivities } from '../../../../../../../../rest/effects/activity/updateActivities';

export const onDidMount = (
    activityId: TActivityId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(getByIdRest(activityId))
        .then((response: TRestActivity) => {
            dispatch(updateActivities([response]));
            return response;
        });
    };
    return action;
};
