import { IApplicationState } from '../../../../../../types/types';
import { TDriveDocId } from '../../../../../../types/rest/IRestDriveDoc';
import { getDriveDocs } from '../../../../../../store/model/driveDocs/selectors/getDriveDocs';
import { IDriveDoc } from '../../../../../../store/model/card/types/IDriveDoc';
import { TCommentId } from '../../../../../../store/model/card/types/IComment';

export const getCommentDriveDocsByIds = (
    state: IApplicationState,
    commentId: TCommentId,
    ids: TDriveDocId[]
): IDriveDoc[] => {
    const driveDocs = getDriveDocs(state);
    const result: IDriveDoc[] = [];
    if (!ids || !ids.length) return result;

    for (let docId in driveDocs) {
        const driveDoc = driveDocs[docId];
        if (ids.includes(driveDoc.id)) {
            result.push(driveDocs[docId]);
        }
    }
    return result;
};
