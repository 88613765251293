import {IGetState, TBoardId} from '../../../../../../../../types/types';
import {Dispatch, ThunkAction} from '../../../../../../../../types/actions';
import {EBackupExportType} from '../../../../../../../../types/rest/IRestBackup';
import {getSectionType} from 'app/view/react_components/aside_panel/boardDetails/selectors/getSectionType';
import {ESettingsSectionType} from 'app/view/react_components/base/components/SettingsSection/types';
import {loadBoardBackups} from 'app/rest/effects/backup/loadBoardBackups';
import { loadAutoBackups } from '../../../../backups/AutoBackups/effects/loadAutoBackups';

export const onLoad = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const sectionType = getSectionType(getState(), boardId, null, 'allowBackupXml', 'allowViewBackUp');
        const autoBackupsSectionType = getSectionType(getState(), boardId, null, 'allowAutoBackupXml', 'allowViewBackUp');
        if (sectionType === ESettingsSectionType.DEFAULT || sectionType === ESettingsSectionType.READONLY) {
            dispatch(loadBoardBackups(boardId));
        }
        if (
            autoBackupsSectionType !== ESettingsSectionType.READONLY &&
            autoBackupsSectionType !== ESettingsSectionType.DISALLOW
        ) {
            dispatch(loadAutoBackups(boardId, EBackupExportType.GOOGLE_SHEET));
        }
    };
    return action;
};
