import * as React from 'react';
import './_BoardTags.scss';
import { IBoardTagsProps } from './types';
import { BoardTag } from '../BoardTag/BoardTag';
import { CLASS_BOARD_DETAILS } from '../../../../constants';
import { BOARD_SECTION_TAGS_EMPTY } from '../../constants';

export function BoardTags ({
    tags,
    isReadonly,
    onChangeName,
    onDelete
}: IBoardTagsProps) {
    const className = CLASS_BOARD_DETAILS + '__tags';
    return (
        <div className={className}>
            {tags.map((tag) =>
                <BoardTag
                    tag={tag}
                    isReadonly={isReadonly}
                    key={tag}
                    onChangeName={(name) => onChangeName(tag, name)}
                    onDelete={() => onDelete(tag)}
                />)
            }
            {!tags.length &&
                <div className={className + '-text'}>{BOARD_SECTION_TAGS_EMPTY}</div>
            }
        </div>
    );
};
