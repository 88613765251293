import { isLoadingSetAction } from './../store/signIn/actions/isLoadingSetAction';
import { ThunkAction, Dispatch } from '../../../../../types/actions';

export const isLoadingSet = (
    isLoading: boolean
): ThunkAction => {
    const action = (dispatch: Dispatch) => {
        dispatch(isLoadingSetAction(isLoading));
    };
    return action;
};
