'use strict';

import { getAuthUser } from '../../store/model/authUser/selectors/getAuthUser';
import { getAppState } from '../../store/configureStore';
import {
    getActivityItem
} from '../react_components/aside_panel/boardDetails/activity/TabActivity/helpers/aсtivityHelper';

App.Views.CardAddAssignerNotification = App.Views.BaseNotification.extend({

    getActions: function() {    
        const item = getActivityItem(this.notificationModel);    
        var actions = {
            action: item.label,
            actionData: {
                icon: 'assign'
            },
            body: '',
        };
                
        if (this.notificationModel.users && this.notificationModel.users.length) {
            actions.assigned = this.notificationModel.users;
            if (this.isMe()) {
                actions.action = Messages.getText('noty.card_assigned_me');
            }
        }
        return actions;
    },

    isMe: function () {
        return (this.notificationModel.newUser && this.notificationModel.newUser == getAuthUser(getAppState()).permissionId);
    }
});
