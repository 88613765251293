import {IApplicationState} from '../../../../../../../types/types';
import {getCard} from '../../../../../../../store/model/selectors/getCard';
import {connect} from 'react-redux';
import {IActionsInHeadSectionHOCProps} from './types';
import {IActionsInHeadSectionFields} from '../../components/ActionsInHeadSection/types';
import {ActionsInHeadSection} from '../../components/ActionsInHeadSection/ActionsInHeadSection';
import { getIsUserBasicPlanOnly } from '../../../../../../../store/model/authUser/selectors/getIsUserBasicPlanOnly';

const mapStateToProps = (
    state: IApplicationState,
    {boardId, cardId}: IActionsInHeadSectionHOCProps
): IActionsInHeadSectionFields => {
    let isShow = false;
    const card = getCard(state, cardId);

    if (card) {
        isShow = true;
    }

    return {
        boardId,
        cardId,
        isBasicFeature: getIsUserBasicPlanOnly(state),
        isShow,
    }
};

export const ActionsInHeadSectionHOC = connect(
    mapStateToProps,
    null
)(ActionsInHeadSection);

ActionsInHeadSectionHOC.displayName = 'ActionsInHeadSectionHOC';
