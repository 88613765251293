import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../types/types';
import { SegmentBoardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment/index';
import { downloadFile } from 'app/helper/authorisation/microsoft/filePicker/downloadFile';

export const onDownload = (
    driveId?: string,
    fileId?: string,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentBoardEvent.BOARD_ATTACHMENTS_DOWNLOAD_CLICKED));
        if (driveId && fileId) downloadFile(driveId, fileId); // ms
    };
    return action;
};
