import { THintsAction } from '../actions/types';
import { IHints } from '../types';
import { AT_HINTS_SET, AT_HINT_SET, AT_HINT_ACTION_SET } from '../actions/constants';
import { HINTS_INITIAL } from '../../../constants';
import { hintReducer } from '../../hint/reducers/hint';

const initialState: IHints = HINTS_INITIAL;

export const hintsReducer = (
    state = initialState,
    action: THintsAction
): IHints => {
    switch (action.type) {
        case AT_HINTS_SET: {
            const { hints } = action;
            return {
                ...state,
                ...hints
            }
        }

        case AT_HINT_SET: {
            const { key, hint } = action;
            return {
                ...state,
                [key]: hint
            }
        }

        case AT_HINT_ACTION_SET: {
            const { key, hintAction } = action;
            return {
                ...state,
                [key]: hintReducer(state[key], hintAction)
            }
        }

        default:
            return state;
    }
};
