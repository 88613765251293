import * as React from 'react';
import './_BoardSheetBackups.scss';
import { Button, Icon } from 'kui';
import { ListItem } from '../../../../../base/components/ListItem/ListItem';
import Util from '../../../../../../../util/util';
import { IBoardSheetBackupsProps } from './types';
import { SettingsSection } from '../../../../../base/components/SettingsSection/SettingsSection';
import { BOARD_EXPORT_BACKUPS_GOOGLE_SHEET } from './constants';
import { makeFileUrlByFileId } from '../../../backups/Backups/hocs/BoardSheetBackupsHOC/helpers/makeFileUrlByFileId';

export function BoardSheetBackups ({
    backups
}: IBoardSheetBackupsProps) {
    const img = (
        <Icon
            size={24}
            xlink={'board'}
        />
    );
    if (!backups || !backups.length) return null;
    return <SettingsSection title={BOARD_EXPORT_BACKUPS_GOOGLE_SHEET}>
        <div>
            {backups.map((backup) => {
                const created = Math.floor(backup.created / 1000);
                const backupTitle = backup.title + ' (' + Util.formatDateMoment(created) + ' ' + Util.formatTime(created) + ')';
                return <ListItem
                    actions={null}
                    img={img}
                    text={<Button
                        href={makeFileUrlByFileId(backup.fileId)}
                        target={'blank'}
                        text={backupTitle}
                        variant={'icon-text'}
                    />
                    }
                    key={backup.fileId}
                />
            })}
        </div>
    </SettingsSection>
};
