import { IApplicationState, TBoardId } from '../../../../types/types';
import { getBoard } from '../../selectors/getBoardById';

export const getBoardMetaStartDateSetCount = (
    state: IApplicationState,
    boardId: TBoardId
): number => {
    const { userMeta } = getBoard(state, boardId);
    return userMeta && userMeta.startDateSetCount || 0;
};
