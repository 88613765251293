import { root } from '../../../../../../../store/constants';
import { IGetState } from '../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { historyPush } from '../../../../../base/helpers/historyHelper';

export const onDidMount = (
    onClickBack: () => void,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        historyPush({
            state: {page: 'accessibility'},
            onpopstate: onClickBack,
        });
        root.App.controller.hideSystemLoader();
        root.App.router.navigate('/accessibility-statement', {trigger: true});
    };
    return action;
};
