import {
    PTGC_EVENT_START_DATE,
    PTGC_EVENT_DUE_DATE,
    PTGC_EVENT_START_AND_DUE_DATE
} from './../constants';
import { IApplicationState, TCardId } from '../../../../../../../../types/types';
import { getCard } from '../../../../../../../../store/model/selectors/getCard';

export const getDisabledActions = (
    cardId: TCardId,
    state: IApplicationState
)=> {
    const card = getCard(state, cardId);
    const res = [];
    if (!card.startDate) {
        res.push(PTGC_EVENT_START_DATE);
    }

    if (!card.dueDate) {
        res.push(PTGC_EVENT_DUE_DATE);
    }

    if (res.length) {
        res.push(PTGC_EVENT_START_AND_DUE_DATE);
    }
    return res;
};
