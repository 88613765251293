import * as React from 'react';
import { ICardStartDateProps } from './types';
import { CardDateTimeIndicator } from '../CardDateTimeIndicator/CardDateTimeIndicator';
import * as moment from 'moment';
import {
    DATE_FORMAT_INDICATOR,
    DATE_FORMAT_TOOLTIP,
    START_DATE_ICON
} from '../CardDateTimeIndicator/constants';
import { START_DATE_TOOLTIP } from '../../wording';

export function CardStartDate({
    date
}: ICardStartDateProps) {
    const momentDate = moment(date * 1000);
    const text = momentDate.format(DATE_FORMAT_INDICATOR);
    const tooltip = START_DATE_TOOLTIP + momentDate.format(DATE_FORMAT_TOOLTIP);
    return (
        <CardDateTimeIndicator tooltip={tooltip} text={text} icon={START_DATE_ICON} />
    );
}
