import './_InWorkCard.scss';
import { IInWorkCardProps } from './types';
import { Button, Icon, Tooltip } from 'kui';
import * as React from 'react';
import { IN_WORK_CARD_TOOLTIP } from './constants';
import { getShortName } from '../../../helpers/getShortName';
import { NAVIGATION_PANEL_CLOSE_MARKER } from 'app/view/react_components/main/navigationPanel/constants';
import ReactHtmlParser from 'react-html-parser';
import { FAKE_CARD_ID_FOR_WIDGET_PRESENTATION } from 'app/const';

export const InWorkCard =({
    id,
    isBoardLoading,
    boardName,
    name,
    cardNumber,
    onOpenInWorkCard,
    onInWorkCardUpdate,
}: IInWorkCardProps) => {
    const className = 'in-work-card';
    const classIcon = className + '__icon';
    const classFilled = classIcon + '--filled';

    const [isHide, setHide] = React.useState(false);

    React.useEffect(() => {
        if (!isBoardLoading) {
            setHide(false);
            setTimeout(() => setHide(true), 0); // reset animation
        }
    }, [id, isBoardLoading]);

    React.useEffect(() => {
        onInWorkCardUpdate();
    }, [id]);

    const [boardNameMarkdown, setBoardNameMarkdown] = React.useState('');
    const [cardNameMarkdown, setCardNameMarkdown] = React.useState('');
    React.useEffect(() => {
        if (boardName) setBoardNameMarkdown(getShortName(boardName));
        if (name) setCardNameMarkdown((cardNumber ? cardNumber + ': ' : '') + getShortName(name));
    }, [boardName, name]);

    return (id && !isBoardLoading
        ? <Button
            className={`${className} ${NAVIGATION_PANEL_CLOSE_MARKER}
                ${id === FAKE_CARD_ID_FOR_WIDGET_PRESENTATION ? 'fake' : ''}
            `}
            variant={'icon'}
            onClick={onOpenInWorkCard}
        >
            <Tooltip
                value={IN_WORK_CARD_TOOLTIP}
            >
                <div
                    className={'in-work-card__icons'}
                >
                    <Icon
                        xlink={'pulse'}
                        size={24}
                        className={classIcon}
                    />
                    {isHide &&
                        <Icon
                            xlink={'pulse'}
                            size={24}
                            className={classFilled}
                        />
                    }
                </div>
            </Tooltip>
            <div
                className={'in-work-card__card'}
            >
                <Tooltip
                    value={cardNameMarkdown}
                >
                    <div className={'in-work-card__card-name'}>
                        {ReactHtmlParser(cardNameMarkdown)}
                    </div>
                </Tooltip>
                {!!boardNameMarkdown &&
                    <Tooltip
                        value={boardNameMarkdown}
                    >
                        <div className={'in-work-card__board-name'}>
                            {ReactHtmlParser(boardNameMarkdown)}
                        </div>
                    </Tooltip>
                }
            </div>
        </Button>
        : <div/>
    );
}
