import { IGetState, TBoardId, TCardId } from '../../../../../../types/types';
import { ICardBatch } from '../types';
import { Dispatch, ThunkAction } from '../../../../../../types/actions';
import { getBoard } from '../../../../../../store/model/selectors/getBoardById';
import { getList } from '../../../../../../store/model/list/selectors/getList';
import { getCard } from '../../../../../../store/model/selectors/getCard';
import { cardMoveToListBatch } from '../../move/cardMoveToList';
import { getAuthUser } from '../../../../../../store/model/authUser/selectors/getAuthUser';
import { cardsUpdate } from '../../../../../../store/model/actionCreators/cardsUpdate';
import { sendRealTimeStoreActionByCardId } from '../../../../../../view/react_components/base/helpers/realTimeHelperTS';
import { authUserSetInWorkCardId } from '../../../../authUser/authUserSetInWorkCardId';
import { listUpdateMinMaxOrderNumber } from '../../../../list/listUpdateMinMaxOrderNumber';
import { showMoveInfo } from './processCardUpdate';

export const processDoneCardUpdate = (
    cardId: TCardId,
    boardId: TBoardId,
    cardBatches: { [cardId: number]: ICardBatch },
    showMoveInfoPopup: boolean = true,
    calcOrderNumbers: boolean = true,
    orderNumbers: number[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const cardFromStore = getCard(state, Number(cardId));
        const board = getBoard(getState(), boardId);
        const doneList = board.statusLists && board.statusLists.doneId && getList(state, board.statusLists.doneId);
        if (doneList && cardFromStore.listId !== doneList.id) {
            const orderNumber = calcOrderNumbers ? undefined : cardBatches[cardId].card.orderNumber;
            cardBatches[cardId] = cardMoveToListBatch(getState, dispatch, cardFromStore.id, board.statusLists.doneId, orderNumber, cardBatches[cardId]);
            orderNumbers.push(cardBatches[cardId].card.orderNumber);
        }
        cardBatches[cardId].callbacks.push(() => {
            const user = getAuthUser(state);
            if (user.inWorkCardId === Number(cardId)) {
                dispatch(cardsUpdate({
                    [cardId]: {
                        inWorkUsers: []
                    }
                }));
                dispatch(sendRealTimeStoreActionByCardId(Number(cardId), cardsUpdate({
                    [cardId]: {
                        inWorkUsers: []
                    }
                })));
                dispatch(authUserSetInWorkCardId(0));
            }
            if (showMoveInfoPopup && doneList && cardFromStore.listId !== doneList.id) {
                showMoveInfo(dispatch, [cardFromStore], doneList);
            }
        });
    };
    return action;
};
