import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../../middlewares/segment';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { ActionCreator } from 'redux';
import { INotificationBeforeDueDate } from '../../../../../../../../../store/model/notificationBeforeDueDate/types';

export const nbdAddClickedSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch,
) => {
    dispatch(segmentTrackAction(SegmentCardEvent.NOTIFICATION_BEFORE_DUE_DATE_CLICKED));
};

export const nbdSaveClickedSegmentEvent: ActionCreator<ThunkAction> = (
    notifications: INotificationBeforeDueDate[],
) => (
    dispatch: Dispatch,
) => {
    dispatch(segmentTrackAction(SegmentCardEvent.NOTIFICATION_BEFORE_DUE_DATE_SAVE_CLICKED, {
            name: 'count',
            value: String(notifications.length),
        },
    ))
};

export const nbdSavedSegmentEvent: ActionCreator<ThunkAction> = (
    notifications: INotificationBeforeDueDate[],
) => (
    dispatch: Dispatch,
) => {
    notifications
        .filter(item => item !== undefined)
        .forEach((item: INotificationBeforeDueDate) => {
            dispatch(segmentTrackAction(SegmentCardEvent.NOTIFICATION_BEFORE_DUE_DATE_SAVED, {
                name: 'period',
                value: item.unit
            }, [{
                name: 'interval',
                value: String(item.interval)
            }]))
        })
};
