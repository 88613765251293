import { IApplicationState } from '../../../../../../../types/types';
import { getAuthUser } from '../../../../../../../store/model/authUser/selectors/getAuthUser';
import { getIsUserBasicPlanOnly } from '../../../../../../../store/model/authUser/selectors/getIsUserBasicPlanOnly';

export const checkExportTabAllow = (
    state: IApplicationState,
): boolean => {
    if (getIsUserBasicPlanOnly(state)) {
        const { allowGoogleSheetExport } = getAuthUser(state);
        return allowGoogleSheetExport;
    }
    return true;
}
