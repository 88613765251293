import { CLASS_LIST_VIEW } from '../../constants';

export const CLASS_LIST_VIEW_CARD = CLASS_LIST_VIEW + '__card';
export const CLASS_LIST_VIEW_CARD_CONTROL = CLASS_LIST_VIEW_CARD + '-control';
export const CLASS_LIST_VIEW_CARD_ARROW = CLASS_LIST_VIEW + '__card-arrow';
export const CLASS_LIST_VIEW_CARD_COLUMN = CLASS_LIST_VIEW + '__card-column';
export const CLASS_LIST_VIEW_CARD_NAME = CLASS_LIST_VIEW + '__card-column--name';

export const EPIC_ICON = 'epic';
export const EPIC_ICON_ARCHIVE = 'acrhive-epic';
