import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from 'app/types/actions';
import { IGetState, TCardId } from 'app/types/types';
import { getAnEmptyNotification } from '../../../utils/notificationUtils';
import { notificationsBeforeDueDatesForCardUpdate } from '../../../state/effects/notificationsBeforeDueDatesForCardUpdate';
import { getLatestInterval } from 'app/view/react_components/aside_panel/cardDetails/TimingSection/notificationsBeforeDueDate/utils/getLatestInterval';

export const onAddNotification: ActionCreator<ThunkAction> = (
    cardId: TCardId,
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        const cardEditedState = getState().notificationBeforeDueDateEdits[cardId];
        if (cardEditedState) {
            const actualEditedState = cardEditedState.notifications;
            const latestInterval = getLatestInterval(actualEditedState);
            const emptyNotification = getAnEmptyNotification(latestInterval);
            const newEditedState = [
                ...actualEditedState,
                emptyNotification,
            ];
            dispatch(notificationsBeforeDueDatesForCardUpdate(cardId, newEditedState))
        }
    };
    return action;
};
