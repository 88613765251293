import {
    AT_ERROR_SET,
    AT_IS_LOADING_SET,
    AT_IS_SHOW_SET,
    AT_RESET
} from '../actions/constants';
import { ISignIn, ESignInErrorStatus } from '../types';
import { TSignInAction } from '../actions/types';
import { getSignInInitialState } from '../constants';

const initialState: ISignIn = getSignInInitialState();

export const signInReducer = (
    state = initialState,
    action: TSignInAction
): ISignIn => {
    switch (action.type) {
        case AT_ERROR_SET: {
            const { error } = action;
            return {
                ...state,
                error
            }
        }

        case AT_IS_LOADING_SET: {
            const { isLoading } = action;
            return {
                ...state,
                isLoading
            }
        }

        case AT_IS_SHOW_SET: {
            const { isShow } = action;
            return {
                ...state,
                isShow
            }
        }

        case AT_RESET: {
            return {
                ...initialState
            }
        }

        default:
            return state;
    }
};
