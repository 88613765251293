import * as React from 'react';
import { CardCountIndicator } from '../CardCountIndicator/CardCountIndicator';
import { IAttachmentsCountIndicatorProps } from './types';
import { ATTACHMENTS_TOOLTIP } from '../../wording';

export function AttachmentsCountIndicator({
    attachmentsCount
}: IAttachmentsCountIndicatorProps) {
    return (
        <CardCountIndicator icon={'attachment'} tooltip={ATTACHMENTS_TOOLTIP}>
            <span>{attachmentsCount}</span>
        </CardCountIndicator>
    );
}
