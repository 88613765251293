import { Button } from 'kui';
import * as React from 'react';
import { ModalPage } from '../../../base/components/ModalPage';
import { Table1 } from './Table1';
import { Table2 } from './Table2';
import { IAccessibilityStatementProps } from './types';
import './_AccessibilityStatement.scss';

export function AccessibilityStatement ({
    onClickBack,
    onClickContact,
    onDidMount,
}: IAccessibilityStatementProps) {
    const className = 'accessibility-statement';
    const classTable = className + '__table';
    const classTable1 = classTable + '--t1';
    const classForm = className + '__form';

    return (
        <ModalPage
            onClickBack={onClickBack}
            onDidMount={() => onDidMount(onClickBack)}
        >
            <div className={className}>
                <h1>Accessibility Statement</h1>
                <p>Kanbanchi Ltd. is committed to making the Kanbanchi app accessible to all users, including those with disabilities. Our goal is to provide an accessible app that conforms to Section 508 Guidelines and the World Wide Web Consortium&rsquo;s Accessibility Guidelines (WCAG) 2.1 Level AA. Some existing content may not be in compliance, and some content may meet only the minimum required compliance standards. This will be resolved as we continue our efforts to monitor and maintain acceptable compliance levels.</p>
                <p>We continue to make every effort to test the app and to remove barriers that prevent persons with disabilities from interacting with it. If we become aware of content that does not conform to
                the WCAG 2.0 Level AA standards, we will make reasonable good faith efforts to make the content conform to the standards.</p>
                <p>While we strive to adhere to the accepted guidelines and standards for accessibility and usability, it is not always possible to do so in all areas of the website, particularly as it relates to content by third parties. Kanbanchi Ltd. is not responsible for ensuring that third-party content, software/tools, widgets, Add-ins, APIs, etc., that are not owned by Kanbanchi Ltd. but are either located on or linked to Kanbanchi app, conform to WCAG 2.0 Level AA, and shall not be liable if they are inaccessible to individuals with disabilities.</p>
                <p>While this policy does provide the standard for individuals with disabilities to access Web-based information, this policy cannot anticipate every accessibility need, due to known limitations of
                existing technology. There may be other circumstances, independent of technology that may limit the accessibility of the Kanbanchi Ltd. ongoing efforts to ensure accessible content.</p>
                <p>We use the Web Content Accessibility Guidelines (WCAG) version 2.1 as our guiding principle. As we develop new functionality, the principles of accessible design and development are an integral part of conception and realization. We continually test content and features for WCAG 2.1 Level AA compliance and remediate any issues to ensure we meet or exceed the standards. Testing of our digital content is performed by our accessibility experts using automated testing, screen readers, a color contrast analyzer, keyboard-only navigation techniques, and a variety of bookmarklets.</p>

                <h2><strong>Accessibility Conformance Report</strong></h2>
                <p><strong>Report Date: 10 Mar, 2021</strong></p>
                <h3><strong>Product Description:</strong></h3>
                <p>Kanbanchi is the online project management software. Kanbanchi needs no installation because you can access it in your browser. It is also widely used for team collaboration and task management because it provides a platform that all team members can access at one time. Kanbanchi complements Google apps and is integrated into Google&rsquo;s infrastructure.</p>

                <h3><strong>Evaluation Methods Used:</strong></h3>
                <p>The product was tested against all of the applicable 50 success criteria within the Web Content Accessibility Guidelines (WCAG) 2.1 Conformance Level A and AA. The testing methodology included a combination of automated testing, manual testing conducted via paired testing methodology involving two testers, one of them is a non-sighted and the other is a sighted accessibility expert.&nbsp;This testing methodology ensures that every aspect related to accessibility and user experience is included in our analysis. Below is a list of some of the technologies and environments used:</p>
                <ul>
                    <li>Assistive technologies &ndash; Screen readers NVDA, Jaws, Keyboard only interaction, Browser zoom</li>
                    <li>Automated tools &ndash; aXe, Color Contrast Analyzer</li>
                    <li>Browsers &ndash; Chrome, Edge, Firefox, Safari</li>
                    <li>Accessibility plugins &ndash; aXe</li>
                    <li>Desktop - Windows 10</li>
                </ul>
                <p>The combinations of technologies used were the following:&nbsp;</p>
                <ul>
                    <li>Windows with Firefox and NVDA</li>
                    <li>Windows with Chrome and Jaws</li>
                    <li>Windows with Edge and Jaws</li>
                    <li>Windows with Chrome and aXe</li>
                    <li>Windows with Google Chrome and Zoom</li>
                    <li>Windows with Google Chrome and Color Contrast Analyzer</li>
                </ul>

                <h2><strong>Applicable Standards/Guidelines</strong></h2>
                <p>This report covers the degree of conformance for the following accessibility standard/guidelines:</p>
                <table className={`${classTable} ${classTable1}`}>
                    <tbody>
                        <tr>
                            <th>
                                <strong>Standard/Guideline</strong>
                            </th>
                            <th>
                                <strong>Included In Report</strong>
                            </th>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="http://www.w3.org/TR/2008/REC-WCAG20-20081211">Web Content Accessibility Guidelines 2.0</a>&nbsp;</p>
                            </td>
                            <td>
                                <p>Level A (Yes)
                                    <br/>
                                Level AA (Yes)
                                <br/>
                                Level AAA (No )</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="https://www.w3.org/TR/WCAG21">Web Content Accessibility Guidelines 2.1</a>&nbsp;</p>
                            </td>
                            <td>
                                <p>Level A (Yes)
                                <br/>
                                Level AA (Yes )
                                <br/>
                                Level AAA (No )</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="https://www.access-board.gov/guidelines-and-standards/communications-and-it/about-the-ict-refresh/final-rule/text-of-the-standards-and-guidelines">Revised Section 508 standards published January 18, 2017 and corrected January 22, 2018</a>&nbsp;&nbsp;</p>
                            </td>
                            <td>
                                <p>(Yes)</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="https://www.etsi.org/deliver/etsi_en/301500_301599/301549/03.01.01_60/en_301549v030101p.pdf">EN 301 549 Accessibility requirements suitable for public procurement of ICT products and services in Europe</a>, - V3.1.1 (2019-11)<em>&nbsp;</em></p>
                            </td>
                            <td>
                                <p>(No )</p>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <h2><strong><br /></strong><strong>Terms</strong></h2>
                <p>The terms used in the Conformance Level information are defined as follows:</p>
                <ul>
                    <li><strong>Supports</strong>: The functionality of the product has at least one method that meets the criterion without known defects or meets with equivalent facilitation.</li>
                    <li><strong>Supports with Minor Exceptions</strong>: Some functionality of the product does not meet the criterion.</li>
                    <li><strong>Does Not Support</strong>: The majority of product functionality does not meet the criterion.</li>
                    <li><strong>Not Applicable</strong>: The criterion is not relevant to the product.</li>
                </ul>

                <h2><strong>WCAG 2.1 Report</strong></h2>
                <p>Tables 1 and 2 also document conformance with:</p>
                <ul>
                    <li>EN 301 549:&nbsp; Chapter 9 - Web, Sections 10.1-10.4 of Chapter 10 - Non-Web documents, and Sections 11.1-11.4 and 11.8.2 of Chapter 11 - Non-Web Software (open and closed functionality), and Sections 12.1.2 and 12.2.4 of Chapter 12 &ndash; Documentation</li>
                    <li>Revised Section 508: Chapter 5 &ndash; 501.1 Scope, 504.2 Content Creation or Editing, and Chapter 6 &ndash; 602.3 Electronic Support Documentation.</li>
                </ul>

                <h3><strong>Table 1: Success Criteria, Level A</strong></h3>
                <p>Notes:</p>
                <table className={classTable}>
                    <Table1 />
                </table>

                <h3><strong>Table 2: Success Criteria, Level AA</strong></h3>
                <p>Notes:</p>
                <table className={classTable}>
                    <Table2 />
                </table>

                <h2><strong>Revised Section 508 Report</strong></h2>
                <h3><strong>Chapter 3: </strong><a target="_blank" href="https://www.access-board.gov/guidelines-and-standards/communications-and-it/about-the-ict-refresh/final-rule/text-of-the-standards-and-guidelines#302-functional-performance-criteria"><strong>Functional Performance Criteria</strong></a><strong> (FPC)</strong></h3>
                <p>Notes:</p>
                <table className={classTable}>
                    <tbody>
                        <tr>
                            <th>
                                <p><strong>Criteria</strong></p>
                            </th>
                            <th>
                                <p><strong>Conformance Level</strong></p>
                            </th>
                            <th>
                                <p><strong>Remarks and Explanations</strong></p>
                            </th>
                        </tr>
                        <tr>
                            <td>
                                <p>302.1 Without Vision</p>
                            </td>
                            <td>
                                <p><strong>Supports</strong></p>
                            </td>
                            <td>
                                <p><strong>Remark:</strong></p>
                                <p>NVDA and Jaws screen reader equally support the application. Users using these screen readers can easily operate the application.&nbsp;</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>302.2 With Limited Vision</p>
                            </td>
                            <td>
                                <p><strong>Supports with Minor Exceptions</strong></p>
                            </td>
                            <td>
                                <p>Color scheme is appropriate and the color contrast ratio of most of the interactive elements is equal to or greater than the standard ratio of 4.5:1. However, there are a few instances where low vision users will find it difficult to recognize web elements as their color contrast ratio is less than the standard ratio.</p>
                                <p><strong>Exceptions:</strong></p>
                                <p>1. The color contrast ratio of text 'Give us 5' is less than the standard ratio 4.5:1.</p>
                                <p>2. The color contrast ratio of text 'Help and support, release notes' is less than the standard ratio 4.5:1.</p>
                                <p>3. The color contrast ratio of the bell icon is less than the standard ratio 3:1.</p>
                                <p>Consult the guidelines 1.4.3 and 1.4.11 for the date of the fix.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>302.3 Without Perception of Color</p>
                            </td>
                            <td>
                                <p><strong>Not Applicable</strong></p>
                            </td>
                            <td>
                                <p>No color is used to indicate any type of information on the page such as bulleted point, section headings, link, button, etc.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>302.4 Without Hearing</p>
                            </td>
                            <td>
                                <p><strong>Not Applicable</strong></p>
                                <br /></td>
                            <td>
                                <p>There are no pre-recorded videos in the application.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>302.5 With Limited Hearing</p>
                            </td>
                            <td>
                                <p><strong>Not Applicable</strong></p>
                            </td>
                            <td>
                                <p>There are no pre-recorded videos in the application.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>302.6 Without Speech</p>
                            </td>
                            <td>
                                <p><strong>Not Applicable</strong></p>
                                <br /></td>
                            <td>
                                <p>Speech is not required to complete any task or activity.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>302.7 With Limited Manipulation</p>
                            </td>
                            <td>
                                <p><strong>Not Applicable</strong></p>
                                <br /></td>
                            <td>
                                <p>There are no such complicated keyboard actions required in the application to perform any task that require simultaneous actions or fine motor control.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>302.8 With Limited Reach and Strength</p>
                            </td>
                            <td>
                                <p><strong>Supports with Minor Exceptions</strong></p>
                            </td>
                            <td>
                                <p>The application has a good support of keyboard interaction as most of the interactive elements are accessible using the keyboard commands. It works similarly for both screen reader and keyboard-only users. Except for one case.</p>
                                <p><strong>Exception:</strong></p>
                                <p>1. Screen reader identifies the element inside the notification panel without expanding it.</p>
                                <p>Consult the guideline 2.4.3 for the date of the fix.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>302.9 With Limited Language, Cognitive, and Learning Abilities</p>
                            </td>
                            <td>
                                <p><strong>Supports</strong></p>
                            </td>
                            <td>
                                <p>Many features in the application have good support for users with cognitive disabilities due to following reasons:</p>
                                <p>1. The application does not have any moving text/images/animations that disrupt reading.</p>
                                <p>2. The application does not have any multimedia that plays automatically and confuses the user.</p>
                                <p>3. The application does not have any flashing objects that cause seizure.</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <h3><strong>Chapter 4: </strong><a target="_blank" href="https://www.access-board.gov/guidelines-and-standards/communications-and-it/about-the-ict-refresh/final-rule/text-of-the-standards-and-guidelines#401-general"><strong>Hardware</strong></a></h3>
                <p>Notes: This website is a web software application and is not subjected to the requirements of this section</p>

                <h3><strong>Chapter 5: </strong><a target="_blank" href="https://www.access-board.gov/guidelines-and-standards/communications-and-it/about-the-ict-refresh/final-rule/text-of-the-standards-and-guidelines#501-general"><strong>Software</strong></a></h3>
                <p>Notes:</p>
                <table className={classTable}>
                    <tbody>
                        <tr>
                            <th>
                                <p><strong>Criteria</strong></p>
                            </th>
                            <th>
                                <p><strong>Conformance Level</strong></p>
                            </th>
                            <th>
                                <p><strong>Remarks and Explanations</strong></p>
                            </th>
                        </tr>
                        <tr>
                            <td>
                                <p>501.1 Scope &ndash; Incorporation of WCAG 2.0 AA</p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="https://www.access-board.gov/guidelines-and-standards/communications-and-it/about-the-ict-refresh/final-rule/text-of-the-standards-and-guidelines#502-interoperability-assistive-technology"><strong><em>502 Interoperability with Assistive Technology</em></strong></a></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p>502.2.1 User Control of Accessibility Features</p>
                            </td>
                            <td><p><strong>Not Applicable</strong></p>
                            </td>
                            <td>
                                <p>The application is not considered a platform software as defined by Section 508.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>502.2.2 No Disruption of Accessibility Features</p>
                            </td>
                            <td><p><strong>Supports</strong></p>
                            </td>
                            <td>
                                <p>The application does not prohibit users from using any built-in accessibility features provided by the Operating System itself. It means users can use features such as zoom, High Contrast, etc., along with the application without any problem. Users can activate features like sticky keys in Windows etc., while working in application.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><strong><em>502.3 Accessibility Services</em></strong></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p>502.3.1 Object Information</p>
                            </td>
                            <td><p><strong>Supports</strong></p>
                            </td>
                            <td>
                                <p>All components of the application have proper name/label, role/state and value that help screen reader users to understand the actual purpose and nature of these components.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>502.3.2 Modification of Object Information</p>
                            </td>
                            <td>
                                <p><strong>Supports</strong></p>
                            </td>
                            <td>
                                <p>Current state and a change of any object state is programmatically recognized by Assistive Technology.&nbsp;</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>502.3.3 Row, Column, and Headers</p>
                            </td>
                            <td>
                                <p><strong>Not Applicable</strong></p>
                            </td>
                            <td>
                                <p>No tables are provided in the application.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>502.3.4 Values</p>
                            </td>
                            <td><p><strong>Supports</strong></p>
                            </td>
                            <td>
                                <p>Current value associated with the object is perceived programmatically by the Assistive Technology.&nbsp;</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>502.3.5 Modification of Values</p>
                            </td>
                            <td><p><strong>Supports</strong></p>
                            </td>
                            <td>
                                <p>Value that can be set by the user is perceived programmatically by the Assistive Technology.&nbsp;</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>502.3.6 Label Relationships</p>
                            </td>
                            <td><p><strong>Supports</strong></p>
                                <br /></td>
                            <td>
                                <p>The application uses standard HTML and WAI-ARIA attributes to describe label relationships of user interface elements to Assistive Technologies.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>502.3.7 Hierarchical Relationships</p>
                            </td>
                            <td><p><strong>Supports</strong></p>
                            </td>
                            <td>
                                <p>Heading hierarchy is correct.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>502.3.8 Text</p>
                            </td>
                            <td><p><strong>Supports</strong></p>
                            </td>
                            <td>
                                <p>The application renders strings as plain text values in HTML for maximum compatibility with Assistive Technologies.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>502.3.9 Modification of Text</p>
                            </td>
                            <td><p><strong>Supports</strong></p>
                            </td>
                            <td>
                                <p>The application renders strings as plain text values in HTML for maximum compatibility with Assistive Technologies. Text entered by the user is recognised by the screen reader easily.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>502.3.10 List of Actions</p>
                            </td>
                            <td>
                                <p><strong>Supports</strong></p>
                            </td>
                            <td>
                                <p>The application programmatically exposes all actions that can be executed on an object.</p>
                                <br /></td>
                        </tr>
                        <tr>
                            <td>
                                <p>502.3.11 Actions on Objects</p>
                            </td>
                            <td><p><strong>Supports with Minor Exceptions</strong></p>
                            </td>
                            <td>
                                <p>The application allows Assistive Technologies to programmatically execute available actions on the majority of objects.&nbsp;</p>
                                <p><strong>Exception:</strong></p>
                                <p>1. Account icon is not accessible with tab/arrow keys.</p>
                                <p>Consult the guideline 2.1.1 for the date of the fix.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>502.3.12 Focus Cursor</p>
                            </td>
                            <td><p><strong>Supports</strong></p>
                            </td>
                            <td>
                                <p>The application uses a border outliner to indicate the current position of the focus indicator</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>502.3.13 Modification of Focus Cursor</p>
                            </td>
                            <td><p><strong>Not Applicable</strong></p>
                            </td>
                            <td>
                                <p>The application is not considered platform software as defined by Section 508.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>502.3.14 Event Notification</p>
                            </td>
                            <td><p><strong>Supports</strong></p>
                            </td>
                            <td>
                                <p>Status messages and notifications on the application are announced to the user.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>502.4 Platform Accessibility Features</p>
                            </td>
                            <td><p><strong>Not Applicable</strong></p>
                            </td>
                            <td>
                                <p>The application is not considered platform software as defined by Section 508.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="https://www.access-board.gov/guidelines-and-standards/communications-and-it/about-the-ict-refresh/final-rule/text-of-the-standards-and-guidelines#503-applications"><strong><em>503 Applications</em></strong></a></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p>503.2 User Preferences</p>
                            </td>
                            <td><p><strong>Supports</strong></p>
                            </td>
                            <td><p>The application respects user preferences from OS settings. For example, if a lower vision user enables high contrast mode, user interfaces will be displayed in high contrast.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>503.3 Alternative User Interfaces</p>
                            </td>
                            <td><p><strong>Not Applicable</strong></p>
                            </td>
                            <td>
                                <p>The application does not provide any alternative user interfaces that function as the Assistive Technology.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><strong><em>503.4 User Controls for Captions and Audio Description</em></strong></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p>503.4.1 Caption Controls</p>
                            </td>
                            <td><p><strong>Not Applicable</strong></p>
                            </td>
                            <td>
                                <p>There is no audio only and video only content in the application.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>503.4.2 Audio Description Controls</p>
                            </td>
                            <td><p><strong>Not Applicable</strong></p>
                            </td>
                            <td>
                                <p>There is no audio only and video only content in the application.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="https://www.access-board.gov/guidelines-and-standards/communications-and-it/about-the-ict-refresh/final-rule/text-of-the-standards-and-guidelines#504-authoring-tools"><strong><em>504 Authoring Tools</em></strong></a></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p>504.2 Content Creation or Editing (if not authoring tool, enter &ldquo;not applicable&rdquo;)</p>
                            </td>
                            <td><p><strong>Not Applicable</strong></p>
                            </td>
                            <td>
                                <p>Not an autoring tool.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>504.2.1 Preservation of Information Provided for Accessibility in Format Conversion</p>
                            </td>
                            <td><p><strong>Not Applicable</strong></p>
                            </td>
                            <td>
                                <p>There is no feature to convert content from one format to another in the application.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>504.2.2 PDF Export</p>
                            </td>
                            <td><p><strong>Not Applicable</strong></p>
                            </td>
                            <td>
                                <p>There is no PDF export feature in the application.&nbsp;</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>504.3 Prompts</p>
                            </td>
                            <td><p><strong>Not Applicable</strong></p>
                                <br /></td>
                            <td>
                                <p>There is no prompt in the application to create content.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>504.4 Templates</p>
                            </td>
                            <td><p><strong>Not Applicable</strong></p>
                                <br /></td>
                            <td>
                                <p>There are no templates provided in the application to create content.</p>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <h3><strong>Chapter 6: </strong><a target="_blank" href="https://www.access-board.gov/guidelines-and-standards/communications-and-it/about-the-ict-refresh/final-rule/text-of-the-standards-and-guidelines#601-general"><strong>Support Documentation and Services</strong></a></h3>
                <p>Notes:</p>
                <table className={classTable}>
                    <tbody>
                        <tr>
                            <th>
                                <p><strong>Criteria</strong></p>
                            </th>
                            <th>
                                <p><strong>Conformance Level</strong></p>
                            </th>
                            <th>
                                <p><strong>Remarks and Explanations</strong></p>
                            </th>
                        </tr>
                        <tr>
                            <td>
                                <p><strong><em>601.1 Scope</em></strong></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="https://www.access-board.gov/guidelines-and-standards/communications-and-it/about-the-ict-refresh/final-rule/text-of-the-standards-and-guidelines#602-support-documentation"><strong><em>602 Support Documentation</em></strong></a></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p>602.2 Accessibility and Compatibility Features</p>
                            </td>
                            <td>
                                <p><strong>Supports</strong></p>
                            </td>
                            <td><p>There is a section available in the application that describes the information regarding accessibility and its compatibility with different assistive technologies.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>602.3 Electronic Support Documentation</p>
                            </td>
                            <td>
                                <p><strong>Supports</strong></p>
                            </td>
                            <td>
                                <p>The application provides help pages with the information accessible electronically.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>602.4 Alternate Formats for Non-Electronic Support Documentation</p>
                            </td>
                            <td>
                                <p><strong>Not Applicable</strong></p>
                            </td>
                            <td>
                                <p>The application provides information through the digital medium and it allows users to access the &lsquo;Help&rsquo; feature for further help regarding the digital content and documentation. There is no such requirement of alternate formats for non-electronic support documentation as support services are available electronically.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><a target="_blank" href="https://www.access-board.gov/guidelines-and-standards/communications-and-it/about-the-ict-refresh/final-rule/text-of-the-standards-and-guidelines#603-support-services"><strong><em>603 Support Services</em></strong></a></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p>603.2 Information on Accessibility and Compatibility Features</p>
                            </td>
                            <td>
                                <p><strong>Supports</strong></p>
                            </td>
                            <td>
                                <p>This is a section in the application, that provides the information regarding accessibility and compatibility features.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>603.3 Accommodation of Communication Needs</p>
                            </td>
                            <td>
                                <p><strong>Supports</strong></p>
                            </td>
                            <td>
                                <p>There is a support section that users can use to contact tech support.</p>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <br />
                <p>We welcome any comments, questions, or feedback on our site. If you notice aspects of our site that aren&rsquo;t working for you or your assistive technology, please let us know.</p>

                <Button
                    className={classForm}
                    onClick={onClickContact}
                >
                    Contact us
                </Button>
            </div>
        </ModalPage>
    );
};
