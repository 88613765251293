import { IApplicationState, TBoardId, TCardId } from '../../../../../../../../types/types';
import { ICard } from '../../../../../../../../store/model/card/types';
import { getCardTemplateIcon } from '../../../../../../helpers/getCardIcon';
import { getCardFullNameHelper } from '../../../../../../../../store/model/card/helpers/getCardFullNameHelper';
import { createSelector } from 'reselect';
import { stripHtml } from '../../../../../../base/helpers/stripHtmlHelper';
import { markdown } from '../../../../../../../../helper/markdownHelper';
import { TStatus } from '../../../../../../../../types/model';
import { getBoardCardsByStatuses } from '../../../../../../../../store/model/selectors/getBoardCardsByStatuses';
import { getLists } from '../../../../../../../../store/model/lists/selectors/getLists';
import { ILists } from '../../../../../../../../store/model/lists/types';
import { ISearchSelectOption } from '../../../../../../base/components/SearchSelect/types';

const getBoardCardsSelector = (
    state: IApplicationState,
    boardId: TBoardId
): ICard[] => {
    const cards = getBoardCardsByStatuses(state, boardId, [TStatus.STATUS_ACTIVE, TStatus.STATUS_SERVICE_TEMPLATE]);
    return cards;
}

const getAvailableCardsSelector = (
    cards: ICard[],
    lists: ILists,
    cardId: TCardId
): ISearchSelectOption[] => {
    const filteredCards = cards.filter((targetCard: ICard) => {
        const theSameCard = targetCard.id === cardId;
        const hasChecklists = targetCard.checklistStats.totalCount;
        return !theSameCard && hasChecklists;
    });

    return filteredCards.map((card) => {
        const img = getCardTemplateIcon(card.status);
        const cardFullName = getCardFullNameHelper(card);
        const listName = lists[card.listId] ? lists[card.listId].name : '';
        return {
            img,
            text: stripHtml(markdown(cardFullName)),
            textSub: stripHtml(markdown(listName)),
            value: String(card.id)
        };
    });
};

export const getAvailableCards: (
    state: IApplicationState,
    boardId: TBoardId,
    cardId: TCardId
) => ISearchSelectOption[] = createSelector([
        getBoardCardsSelector,
        getLists,
        (state: IApplicationState, boardId: TBoardId, cardId: TCardId) => cardId
    ],
    getAvailableCardsSelector,
);

export const getAvailableCardsCreateSelector = () => getAvailableCards;
