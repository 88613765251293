import * as React from 'react';
import { Icon } from 'kui';
import { IMagicLinkProps } from './types';
import './_MagicLink.scss';
import { clearMarkdown } from '../../../../helpers/clearMarkdownHelper';
import { CLASS_MAGIC_LINK } from './constants';
import ReactHtmlParser from 'react-html-parser';

export const MagicLink = ({
    icon,
    label,
    url,
    onLoad,
}: IMagicLinkProps) => {
    const className = CLASS_MAGIC_LINK;

    const [isLoading, setLoading] = React.useState(null);
    React.useEffect(() => {
        setLoading(true);
        onLoad().finally(() => {
            setLoading(false);
        });
    }, []);

    return <span className={`${className} ${isLoading ? className + '--loading' : ''}`}>
        <Icon xlink={icon} size={16} />
        <span className={className + '__label'}>{isLoading ? url : ReactHtmlParser(clearMarkdown(label))}</span>
    </span>;
};
