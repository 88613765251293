import {
    INavigationPanelBrowseBoardsDispatchProps
} from '../../components/NavigationPanelBrowseBoards/types';
import { connect } from 'react-redux';
import { NavigationPanelBrowseBoards } from '../../components/NavigationPanelBrowseBoards/NavigationPanelBrowseBoards';
import { onClick } from './events/onClick';

const mapDispatchToProps = (
    dispatch: any,
): INavigationPanelBrowseBoardsDispatchProps => {
    return {
        onClick: () => dispatch(onClick()),
    }
};

export const NavigationPanelBrowseBoardsHOC = connect(null,
    mapDispatchToProps
)(NavigationPanelBrowseBoards);
NavigationPanelBrowseBoardsHOC.displayName = 'NavigationPanelBrowseBoardsHOC';
