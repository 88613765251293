import { TBoardId, TCardId } from '../../../types/types';
import { EGanttDraggingType, EGanttZoom } from './constants';
import { RefObject } from 'react';

export interface IGanttTreeCard {
    id: TCardId;
    isCollapsed: boolean;
    subCards?: IGanttTreeCard[];
    isValid: boolean;
    isHide?: boolean; // hide not valid epics with filter
}

export type TGanttZoom = {
    zoom: EGanttZoom;
    deltaDays: number;
    groupByWeeks?: boolean;
}

export interface IGanttViewContext {
    boardId: TBoardId;
    isTouch: boolean;
    isUserTimeFormat12: boolean;
    maxLevel: number;
    pageFirstOffset: number;
    pageWidth: number;
    userCountry: 'uk' | 'us';
    onScrollChart: (e: any) => void;
    onScrollInfo: (e: any) => void;
    scrollToToday: (force?: boolean) => void;
    scrollToDate: (date: number, force?: boolean) => void;
    setDraggingType: (type: EGanttDraggingType) => void;
    ganttViewRef: RefObject<any>;
    isReadonly: boolean;
    isScrollChartDisabled: boolean;
    setScrollChartDisabled: (disabled: boolean) => void;
}

export interface IGanttViewPageContext {
    page: number;
}

export type TGanttDates = {
    fromDate: number;
    toDate: number;
    daysCount: number;
}

export interface IGanttCardDimensions {
    left: number;
    right: number;
}

export enum ECardField { // справа свойства ICard
    DESCRIPTION = 'description',
    PRIORITY = 'priority',
    PROGRESS = 'processingPercent',
    LIST = 'listId',
    TAGS = 'tags',
    // START_DATE = 'startDate',
    // DUE_DATE = 'dueDate',
    ESTIMATE = 'estimate',
    SPENT_TIME = 'totalSpentTime',
    // RELATED_CARDS = 'relatedCardCount',
    // CHECKLIST = 'checklistStats',
    // ATTACHMENTS = 'driveDocCount',
    // COMMENTS = 'comments',
    ASSIGNEES = 'assignees'
};

export interface ICardField {
    isShown?: boolean;
    title: string;
    width: number;
};

export type TCardFields = {
    [cardField: string]: ICardField;
};
