import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { SnackbarId } from '../../store/types';
import { deleteSnackbar as deleteSnackbarAction } from '../../store/actions/deleteSnackbar';
import { getParentsClasses } from '../../../helpers/getParentsClasses';

export const onBlur: ActionCreator<ThunkAction> = (
    event: React.FocusEvent<HTMLDivElement>,
    snackbarId: SnackbarId
) => {
    const action = (dispatch: Dispatch) => {
        const targetClasses = getParentsClasses(
            event.relatedTarget as HTMLElement,
            ['snackbars']
        );
        if (!targetClasses.includes('snackbars')) {
            dispatch(deleteSnackbarAction(snackbarId));
        }
    };
    return action;
};
