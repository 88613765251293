import {ActionCreator} from 'redux';
import {Dispatch, ThunkAction} from '../../../../types/actions';
import {IGetState, TCardId} from '../../../../types/types';
import {getSelectedCards} from '../selectors/getSelectedCards';
import {clickManagerActionSet} from '../store/clickManager/actions/clickManagerActionSet';
import {selectedCardIdsSet} from '../store/clickManagerModel/actions/selectedCardIdsSet';
import { getIsMultiselect } from 'app/view/react_components/clickManager/selectors/getIsMultiselect';

export const deselectCard: ActionCreator<ThunkAction> = (
    boardId: number,
    cardId: TCardId,
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const selectedCards = getSelectedCards(state, boardId);
        const isMultiselect = getIsMultiselect(state, boardId)
        const newSelection = selectedCards.filter(selectedCardId => selectedCardId !== cardId);
        dispatch(clickManagerActionSet(boardId, selectedCardIdsSet(newSelection, isMultiselect)))
    };
    return action;
};
