import { EProductFrequency, EProductPlan } from './types';

export const PRODUCTS_BY_PLAN = {
    [EProductPlan.BASIC]: [28, 29],
    [EProductPlan.PREMIUM]: [13, 14, 22, 23],
    [EProductPlan.PROFESSIONAL]: [15, 16, 24, 25],
    [EProductPlan.ENTERPRISE]: [17, 18, 26, 27],
};

export const PRODUCTS_BY_FREQUENCY = {
    [EProductFrequency.MONTHLY]: [13, 15, 17, 22, 24, 26, 28],
    [EProductFrequency.ANNUALLY]: [14, 16, 18, 23, 25, 27, 29],
};

export const PRODUCT_PACK = {
    [EProductPlan.BASIC]: 10,
    [EProductPlan.PREMIUM]: 6,
    [EProductPlan.PROFESSIONAL]: 7,
    [EProductPlan.ENTERPRISE]: 8,
};

export const PLANS_FEATURES = {
    [EProductPlan.BASIC]: [
        { text: 'Unlimited kanban boards & cards' },
        { text: 'Unlimited collaborators' },
        { text: 'Boards templates' },
        { text: 'Email & in-app notifications' },
        { text: 'Drive and Shared Drives attachments' },
        { text: 'Google Calendar integration' },
        { text: 'Color labels', tooltip: '3 colour labels per card'},
        { text: 'Tags', tooltip: '3 tags per board'},
        { text: 'Checklists', tooltip: '1 checklist per card'},
        { text: 'Card colors', tooltip: '3 card colors per board'},
        { text: 'Search & filter', tooltip: 'Saved filters are not included' }
    ],
    [EProductPlan.PREMIUM]: [
        { text: 'Multiple board views' },
        { text: 'Card templates' },
        { text: 'Subcards' },
        { text: 'Saved filters' },
        { text: 'Roles' },
        { text: 'Activity feed' },
        { text: 'Export to Google Sheets' },
        { text: 'Retrieving data from Google Forms' },
        { text: 'Subscription management' },
        { text: 'Backups' }
    ],
    [EProductPlan.PROFESSIONAL]: [
        { text: 'Gantt chart' },
        { text: 'Task dependencies' },
        { text: 'Swimlanes' },
        { text: 'Notifications before due date' },
        { text: 'Archive' },
        { text: 'Data reports' },
        { text: 'Team workload monitoring' },
        { text: 'Time tracker ' },
        { text: 'Time tracker data export to Google Sheets' },
        { text: 'Company logo' },
        { text: 'Priority support' }
    ],
    [EProductPlan.ENTERPRISE]: [
        { text: 'Multi-level admin' },
        { text: 'Sharing with Google groups' },
        { text: 'Shared Drives integration' },
        { text: 'Google Vault integration' },
        { text: 'Designated Account Manager' },
        { text: 'Onboarding assistance' },
        { text: 'Customer Success webinars' },
        { text: 'Custom features & integrations (at the additional cost)' },
        { text: 'Stats on Kanbanchi usage' },
        { text: 'Custom integrations with your existing software'}
    ]
};
