import { IListViewCard } from '../store/listViewBoard/types';

export const sortCardsByListViewOrderNumber = (
    cardA: IListViewCard,
    cardB: IListViewCard
): number => {
    const valueA = cardA.listViewOrderNumber;
    const valueB = cardB.listViewOrderNumber;
    return valueB - valueA;
};
