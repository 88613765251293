import { ISharedUser } from '../../../types/rest/ISharedUser';
import { getBoardUsers } from './getBoardUsers';
import { createSelector } from 'reselect';

const getBoardOwnerSelector = (
    users: ISharedUser[],
): ISharedUser => {
    return users.find(user => user.role === 'owner'); // todo Enum
};

export const getBoardOwnerCreateSelector = () => createSelector(
    getBoardUsers,
    getBoardOwnerSelector
);

// так как автор не меняется, можем сделать кеш для getBoardOwner
export const getBoardOwner = getBoardOwnerCreateSelector();
