import { ITabSetAction } from './types';
import { TTabKey } from '../../types';
import { AT_TAB_SET } from './constants';

export const tabSet = (
    tabKey: TTabKey
): ITabSetAction => ({
    type: AT_TAB_SET,
    tabKey
});
