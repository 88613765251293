import { Button, Icon } from 'kui';
import { BACK_TEXT } from '../ModalPage/Header/constants';
import * as React from 'react';
import './_BackButton.scss';
import { CLASS_BACK_BUTTON } from './constants';
import { IBackButtonProps } from './types';

export const BackButton = ({
    tooltipDirection = 'right',
    ...attributes
}: IBackButtonProps) => {
    return (
        <Button
            className={CLASS_BACK_BUTTON}
            tooltip={{
                direction: tooltipDirection,
                value: BACK_TEXT
            }}
            variant={'icon'}
            {...attributes}
        >
            <Icon
                size={24}
                xlink={'back'}
            />
        </Button>
    );
}
