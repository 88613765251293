import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../../types/types';
import { ICardSubcardsHOCProps } from './types';
import {
    ICardSubcardsEvents,
    ICardSubcardsFields
} from '../../components/CardSubcards/types';
import { CardSubcards } from '../../components/CardSubcards/CardSubcards';
import { isEqual } from 'underscore';
import {
    getKnbCardHideDoneFilteredSubcards,
    getKnbCardSubcards
} from './selectors/getKnbCardSubcards';
import { onSubcardLink } from './events/onSubcardLink';
import { getSubcardsStats } from '../../../../../../../../store/model/selectors/calcCardProcessingPercent';

const mapStateToProps = () => {

    let cache: ICardSubcardsFields = null;
    const CardSubcardsSelector = (
        cardSubcards: ICardSubcardsFields
    ): ICardSubcardsFields => {
        if (isEqual(cardSubcards, cache)) {
            return cache;
        }
        cache = cardSubcards;
        return cardSubcards;
    };

    return (
        state: IApplicationState,
        props: ICardSubcardsHOCProps
    ): ICardSubcardsFields => {
        const { cardId } = props;
        const elements = getKnbCardSubcards(state, cardId);
        const subcardsStats = getSubcardsStats(state, cardId, elements);//should be counted using ALL the subcards, even hidden

        const CardSubcards = CardSubcardsSelector({
            elements: getKnbCardHideDoneFilteredSubcards(state, elements),
        });

        return {
            ...CardSubcards,
            subcardsStats
        };
    };
};

const mapDispatchToProps = (
    dispatch: any,
    { cardId }: ICardSubcardsHOCProps
): ICardSubcardsEvents => {
    return {
        onSubcardLink: (subcardId) => dispatch(onSubcardLink(subcardId)),
    };
};

export const CardSubcardsHOC = connect(
    mapStateToProps,
    mapDispatchToProps,
)(CardSubcards);

CardSubcardsHOC.displayName = 'CardSubcardsHOC';
