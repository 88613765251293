import { getMessages } from '../../../../../store/constants';
import { EAuthUserPlatformType } from '../../../../../types/rest/IRestAuthUser';

export const BOARD_PROGRESS_LOADER_OF = getMessages().getText('board-progress-loader.of');
export const BOARD_PROGRESS_LOADER_LOADED = getMessages().getText('board-progress-loader.loaded');
export const BOARD_PROGRESS_LOADER_BOARD = getMessages().getText('board-progress-loader.board');
export const BOARD_PROGRESS_LOADER_QUICK_START_PROGRESS_GOOGLE = [
    getMessages().getText('board-progress-loader.quick-start.2.google'),
    getMessages().getText('board-progress-loader.quick-start.3.google'),
];
export const BOARD_PROGRESS_LOADER_QUICK_START_PROGRESS_MS = [
    getMessages().getText('board-progress-loader.quick-start.2.ms'),
    getMessages().getText('board-progress-loader.quick-start.3.ms'),
];
export const getBoardProgressLoaderQuickStartProgressText = (platformType: EAuthUserPlatformType) => {
    let platformText;
    switch (platformType) {
        case EAuthUserPlatformType.MICROSOFT:
            platformText = BOARD_PROGRESS_LOADER_QUICK_START_PROGRESS_MS;
            break;
        default:
            platformText = BOARD_PROGRESS_LOADER_QUICK_START_PROGRESS_GOOGLE;
    }
    return [
        getMessages().getText('board-progress-loader.quick-start.1'),
        ...platformText,
        getMessages().getText('board-progress-loader.quick-start.4'),
        getMessages().getText('board-progress-loader.quick-start.5'),
        getMessages().getText('board-progress-loader.quick-start.6'),
        getMessages().getText('board-progress-loader.quick-start.7'),
    ];
}
export const BOARD_PROGRESS_LOADER_QUICK_START_PROGRESS_TIMEOUT = 5000;
