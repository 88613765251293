import { IGetState, TBoardId, TCardId, TCustomPropertyId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { snackbarPromtDelete } from '../../../../../../snackbarsStack';
import { getMessages, root } from '../../../../../../../../store/constants';
import { getBoardCardCustomProperty } from '../../../../../../../../store/model/board/selectors/getBoardCardCustomProperty';
import { getBoardCardsMap } from '../../../../../../../../store/model/selectors/getBoardCardsMap';
import { getCardCustomProperties } from '../../../../../../../../store/model/selectors/getCardCustomProperties';
import { SegmentCardEvent, SegmentCardOptions, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { SegmentCardSourceValue } from '../../../../../../../../middlewares/segment/trackEntities/cardEvents';
import {
    getCardPinnedCustomPropertiesIds
} from '../../../../../../../../store/model/selectors/getCardPinnedCustomPropertiesIds';
import { getLists } from '../../../../../../../../store/model/lists/selectors/getLists';
import { getBoard } from '../../../../../../../../store/model/selectors/getBoardById';
import { getList } from '../../../../../../../../store/model/list/selectors/getList';
import { cardUpdatePinnedSection } from '../../../../../../../../rest/effects/card/card/cardUpdatePinnedSection';

export const confirmDeleteProperty = (
    boardId: TBoardId,
    cardId: TCardId,
    customPropertyId: TCustomPropertyId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return new Promise((resolve, reject) => {
            const boardCardCustomProperty = getBoardCardCustomProperty(getState(), boardId, customPropertyId);
            if (!boardCardCustomProperty) return reject();

            const resolvePromise = (result: boolean) => {
                if (result) {
                    dispatch(segmentTrackAction(SegmentCardEvent.CUSTOM_PROPERTY_DELETED, {
                        name: SegmentCardOptions.SOURCE,
                        value: !!cardId ? SegmentCardSourceValue.DETAILS : SegmentCardSourceValue.BOARD_SETTINGS
                    }));
                }
                const listIds = getBoard(getState(), boardId).listIds;
                listIds.forEach((listId) => {
                    const list = getList(getState(), listId);
                    if (list.cardIds) {
                        list.cardIds.forEach((cardId) => {
                            const customProperties = [...getCardPinnedCustomPropertiesIds(getState(), cardId)];
                            const index = customProperties.indexOf(customPropertyId);
                            if (index > -1) {
                                customProperties.splice(index, 1);
                                dispatch(cardUpdatePinnedSection(cardId, { customProperties }));
                            }
                        });
                    }
                })
                resolve(result);
            }

            root.App.controller.showSystemLoader(true);
            root.App.controller.loadBoardArchiveToStore(boardId)
                .then(() => { // подгрузить архив
                    const boardCards = getBoardCardsMap(getState(), boardId, null);
                    let cardsCount = 0;
                    for (let cardId in boardCards) { // перебрать карты доски
                        const customProperties = getCardCustomProperties(getState(), Number(cardId));
                        if (
                            customProperties &&
                            customProperties[customPropertyId]
                        ) {
                            cardsCount++;
                        }
                    }
                    if (cardsCount) { // если на картах есть заполненное поле, снекбар с подтверждением
                        const textCards = getMessages().getPluralByKeys(
                            cardsCount,
                            ['snackbar.card.custom.delete.card', 'snackbar.card.custom.delete.cards']
                        );
                        dispatch(snackbarPromtDelete({
                            id: 'SNACKBAR_PROPERTY_DELETE_ID',
                            text: getMessages().getText('snackbar.card.custom.delete.text', null, boardCardCustomProperty.name, cardsCount, textCards),
                            actionApply: () => {
                                resolvePromise(true);
                            },
                            actionCancel: () => {
                                resolvePromise(false);
                            }
                        }));
                    } else {
                        resolvePromise(true);
                    }
                })
                .finally(() => {
                    root.App.controller.hideSystemLoader();
                });
        })
    };
    return action;
};
