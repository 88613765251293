import { getMessages } from '../constants';
import { ECustomPropertyType } from './types';

export const CUSTOM_PROPERTY_TEXT_TYPES: Readonly<Record<string, string>> = Object.freeze({
    [ECustomPropertyType.TEXT]: getMessages().getText('common.text')
});

export const CUSTOM_PROPERTY_TO_TEXT_TYPES: Readonly<Record<string, string>> = Object.freeze({
    [ECustomPropertyType.NUMBER]: getMessages().getText('common.number'),
    [ECustomPropertyType.EMAIL]: getMessages().getText('common.email'),
    [ECustomPropertyType.PHONE]: getMessages().getText('common.phone'),
});

export const CUSTOM_PROPERTY_TYPES: Readonly<Record<string, string>> = Object.freeze({
    ...CUSTOM_PROPERTY_TEXT_TYPES,
    ...CUSTOM_PROPERTY_TO_TEXT_TYPES,
    [ECustomPropertyType.DATE]: getMessages().getText('common.date'),
    [ECustomPropertyType.SELECT]: getMessages().getText('common.dropdown'),
});
