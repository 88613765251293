import { root } from './../../../../../../store/constants';
import { IApplicationState, TBoardId, TCardId } from '../../../../../../types/types';
import { getCardPredecessorsDependencies } from '../../../../../../store/model/dependencies/dependencies/selectors/getCardPredecessorsDependencies';
import { EDependencyNames } from '../constans';
import { IDependencyItem } from '../components/DependenciesSection/types';
import { isDependencyValid } from '../../../../../../store/model/dependencies/dependencies/selectors/isDependencyValid';
import { getCardFullName } from '../../../../../../store/model/card/selectors/getCardFullName';
import { stripHtml } from '../../../../base/helpers/stripHtmlHelper';
import { markdown } from '../../../../../../helper/markdownHelper';

export const getCardPredecessors = (
    state: IApplicationState,
    cardId: TCardId,
    boardId: TBoardId
): IDependencyItem[] => {
    const predecessors = getCardPredecessorsDependencies(state, cardId);
    return predecessors.map((dependency) => {
        const cardFullName = getCardFullName(state, dependency.predecessorId);
        return {
            cardName: stripHtml(markdown(cardFullName)),
            cardId: dependency.predecessorId,
            dependencyId: dependency.id,
            dependencyType: EDependencyNames[dependency.type],
            link: root.App.router.getCardPageUrl(boardId, dependency.predecessorId),
            isValid: isDependencyValid(state, dependency.id)
        };
    }).filter((dep: IDependencyItem) => dep.cardName != null); //for archived predecessors ???
};
