import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../../../../types/types';
import { cardSetSubcardWeight } from '../../../../../../../../rest/effects/card/card/cardSetSubcardWeight';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { getSubcardWeight } from '../../../../../../../../store/model/selectors/getSubcardWeight';

export const onWeightChange = (
    subcardId: TCardId,
    weight: number
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const currentWeight = getSubcardWeight(getState(), subcardId);
        if (currentWeight !== weight) {
            dispatch(segmentTrackAction(SegmentCardEvent.SUBCARD_WEIGHT_CHANGED));
            dispatch(cardSetSubcardWeight(subcardId, weight));
        }
    };
    return action;
};
