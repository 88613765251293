import * as React from 'react';
import { INotifyButtonProps } from './types';
import './_NotifyButton.scss';
import { CLASS_CARD_DETAILS } from '../../../constants';
import { CLASS_ASIDE_PANEL_ACTIONS_POPUP } from '../../../../asidePanel/components/AsidePanelActionsButton/constants';
import { AsidePanelActionsButton } from '../../../..';
import { EAsidePanelProperty } from '../../../../asidePanel/components/AsidePanel/types';
import { getAsidePanelActionsButtonTooltip } from '../../../../asidePanel/helpers/getAsidePanelActionsButtonTooltip';
import { NOTIFY_BUTTON } from '../../constants';
import { NotifySectionHOC } from '../../hocs/NotifySectionHOC/NotifySectionHOC';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';

export function NotifyButton ({
    activityId,
    isNotActivity,
    isShow,
    onClick,
}: INotifyButtonProps) {
    if (!isShow) return null;

    const { boardId, cardId } = React.useContext(AsidePanelContext);
    const className = CLASS_CARD_DETAILS + '__notify-button';

    const [isOpened, setIsOpened] = React.useState(null);
    const onOpened = () => setIsOpened(true);
    const onClosed = () => setIsOpened(false);

    return (
        <AsidePanelActionsButton
            className={className}
            dropdownClassName={`${CLASS_ASIDE_PANEL_ACTIONS_POPUP}--notify`}
            icon={'notifications'}
            isScrollUnlocked
            isOpened={isOpened}
            openedProperty={EAsidePanelProperty.CARD_ESTIMATE}
            title={null}
            tooltip={getAsidePanelActionsButtonTooltip({ value: NOTIFY_BUTTON })}
            onClick={onClick}
            onOpen={onOpened}
        >
            <NotifySectionHOC
                boardId={boardId}
                cardId={cardId}
                activityId={activityId}
                isNotActivity={isNotActivity}
                onClose={onClosed}
            />
        </AsidePanelActionsButton>
    );
};
