import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../types/types';
import { INavigationPanelCreateBoardDropDownHOCOwnProps } from './types';
import {
    INavigationPanelCreateBoardDropDownDispatchProps,
    INavigationPanelCreateBoardDropDownStateProps
} from '../../components/NavigationPanelCreateBoardDropDown/types';
import { onCreateOnSharedDrive } from './events/onCreateOnSharedDrive';
import { NavigationPanelCreateBoardDropDown } from '../../components/NavigationPanelCreateBoardDropDown/NavigationPanelCreateBoardDropDown';
import { onLoadOptions } from './events/onLoadOptions';
import { TABS } from '../../../../dialogs/openBoards/store/constants';
import { getAuthUser } from '../../../../../../store/model/authUser/selectors/getAuthUser';
import { TTabKey } from '../../../../dialogs/openBoards/store/types';
import { onClick } from './events/onClick';
import { getCurrentNavigationPanelType } from '../../helpers/getCurrentNavigationPanelType';
import { ENavigationPanelTypes } from '../../constants';
import { getAuthUserPlatformType } from 'app/store/model/authUser/selectors/getAuthUserPlatformType';
import { CommonAuthManager } from 'app/helper/authorisation/common/CommonAuthManager';

const mapStateToProps = (
    state: IApplicationState,
    { isOpened }: INavigationPanelCreateBoardDropDownHOCOwnProps
): INavigationPanelCreateBoardDropDownStateProps => {
    const platformType = getAuthUserPlatformType(state);
    let planBlocker = null;
    let scopesBlocker = null;
    const tab = TABS[TTabKey.sharedDrives];
    const allow = tab.allow;
    if (allow) {
        const user = getAuthUser(state);
        if (
            user &&
            !user[allow] &&
            tab.showPlanBlocker
        ) {
            planBlocker = tab.showPlanBlocker;
        }
    }

    if (!planBlocker && tab.scopes) {
        const scopes = tab.scopes[platformType];
        if (scopes &&
            !CommonAuthManager.checkOneOfScopes(scopes.success)
        ) {
            scopesBlocker = scopes.emptyDummyId;
        }
    }

    return {
        isMobile: getCurrentNavigationPanelType(state) === ENavigationPanelTypes.NP_MOBILE_TYPE,
        isOpened,
        planBlocker,
        scopesBlocker
    }
};

const mapDispatchToProps = (
    dispatch: any,
    { onClose, onOpen }: INavigationPanelCreateBoardDropDownHOCOwnProps
): INavigationPanelCreateBoardDropDownDispatchProps => {
    return {
        onCreateBoard: (driveId) => dispatch(onCreateOnSharedDrive(driveId)),
        onLoadOptions: () => dispatch(onLoadOptions()),
        onClick: () => dispatch(onClick()),
        onClose,
        onOpen
    }
};

export const NavigationPanelCreateBoardDropDownHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(NavigationPanelCreateBoardDropDown);
NavigationPanelCreateBoardDropDownHOC.displayName = 'NavigationPanelCreateBoardDropDownHOC';
