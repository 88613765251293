import { IApplicationState, TBoardId, TCardId } from '../../../../../types/types';
import { root } from '../../../../../store/constants';
import { ICardDetailsData } from '../cardDetails/components/CardDetails/types';
import { IBoard } from '../../../../../store/model/board/types';
import { showRecordNotFoundError } from './showRecordNotFoundError';

export const loadData = (
    state: IApplicationState,
    boardId: TBoardId,
    cardId: TCardId,
): Promise<ICardDetailsData> => {
    return root.App.controller.loadBoardForDashboardToStore(boardId, true, true)
        .then((board: IBoard) => {
            if (!board) {
                showRecordNotFoundError();
                return null;
            }
            return { boardId, cardId };
        }).catch(() => {
            showRecordNotFoundError();
        });
}
