import { Dispatch, ThunkAction } from 'app/types/actions';
import { TBoardId } from 'app/types/types';
import { segmentTrackAction } from 'app/middlewares/segment';
import { SegmentBoardOption} from 'app/middlewares/segment/trackEntities/boardEvents';
import { EThemeBackgroundOption } from 'app/types/rest/IRestTheme';
import { ThemeBackgroundOptionList } from 'app/view/react_components/base/components/Branding/CustomTheme/components/BoardCustomTheme/constants';
import { getOptionEvent } from 'app/middlewares/segment/trackEntities/brandingEvents';

export const onOptionChange = (
    boardId: TBoardId,
    option: EThemeBackgroundOption
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        let idx = ThemeBackgroundOptionList.findIndex((item) => item.key === option);
        if(idx < 0)
            idx = 0;
        dispatch(segmentTrackAction(getOptionEvent(ThemeBackgroundOptionList[idx].key), {
            name: SegmentBoardOption.TYPE,
            value: ThemeBackgroundOptionList[idx].value
        }));
    };
    return action;
};
