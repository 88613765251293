import { IGetState, TBoardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { closePanel } from '../../../../../asidePanel/effects/closePanel';
import { getAuthUser } from '../../../../../../../../store/model/authUser/selectors/getAuthUser';
import { snackbarBoardUnarchived } from '../../../../../../snackbarsStack';
import { boardSetStatus } from '../../../../../../../../rest/effects/board/boardSetStatus';
import { TStatus } from '../../../../../../../../types/model';
import { showSnoozeBlocker } from '../../../../../../base/effects/showSnoozeBlocker';

export const onClick = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const isDisallow = !getAuthUser(getState()).allowArchive;

        if (isDisallow) {
            dispatch(showSnoozeBlocker({
                allow: 'allowArchive'
            }));
        } else {
            dispatch(boardSetStatus(boardId, TStatus.STATUS_ACTIVE)).then(() => {
                dispatch(closePanel());
                dispatch(snackbarBoardUnarchived());
            });
        }
    };
    return action;
};
