import { IApplicationState, TCardId } from '../../../types/types';
import { ERoutes } from '../constants';

export const getEditCardId = (
    state: IApplicationState
): TCardId => {
    const { route, args } = state.router;
    if (route !== ERoutes.EDIT_CARD) return null;

    const cardId = args[2];
    if (!cardId) return null;

    return parseInt(cardId);
}
