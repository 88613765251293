import * as React from 'react';
import { AsidePanelContext } from '../../../../../asidePanel/components/AsidePanel/constants';
import { CLASS_BOARD_DETAILS_SECTION } from '../../../../constants';
import { SettingsSection } from '../../../../../../base/components/SettingsSection/SettingsSection';
import { IArchiveOnDeleteSectionProps } from './types';
import { ArchiveOnDeleteTogglerHOC } from '../../hocs/ArchiveOnDeleteTogglerHOC/ArchiveOnDeleteTogglerHOC';
import { BOARD_SECTION_ARCHIVE_ON_DELETE_SECTION } from '../../constants';
import { ArchiveOnDeleteDescription } from '../ArchiveOnDeleteDescription/ArchiveOnDeleteDescription';

export function ArchiveOnDeletePanelSection ({
    isBasicFeature,
    isProFeature
}: IArchiveOnDeleteSectionProps) {
    const { boardId } = React.useContext(AsidePanelContext);
    const className = CLASS_BOARD_DETAILS_SECTION + ' ' + CLASS_BOARD_DETAILS_SECTION + '--archive-on-del';

    return (
        <SettingsSection
            className={className}
            title={BOARD_SECTION_ARCHIVE_ON_DELETE_SECTION}
            titleButton={<ArchiveOnDeleteTogglerHOC boardId={boardId} />}
            isBasicFeature={isBasicFeature}
            isProFeature={isProFeature}
        >
            <ArchiveOnDeleteDescription />
        </SettingsSection>
    );
};
