import { updateKanbanTitle } from '../effects/updateKanbanTitle';
import { IGetState } from '../../../../../../types/types';
import { Dispatch } from '../../../../../../types/actions';

export const onChange = (
) => {
    return (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(updateKanbanTitle());
    };
};
