import { IColor, TStatus } from '../../../../types/model';
import { getBoardColors } from './getBoardColors';
import { IApplicationState, TBoardId } from '../../../../types/types';
import { createDeepEqualSelector } from '../../../../view/react_components/helpers/memoizeHelper';

type TgetBoardCardColorTags = (
    state: IApplicationState,
    boardId: TBoardId,
    status?: TStatus,
) => IColor[];

const getBoardColorsByStatusSelector = (
    boardColors: IColor[],
    status: TStatus,
): IColor[] => {
    return boardColors.filter(color => status === color.status);
};

export const getBoardColorsByStatusCreateSelector = (): TgetBoardCardColorTags => createDeepEqualSelector(
    getBoardColors,
    (state: IApplicationState, boardId: TBoardId, status: TStatus = TStatus.STATUS_ACTIVE) => status,
    getBoardColorsByStatusSelector,
);

export const getBoardColorsByStatus = (
    state: IApplicationState,
    boardId: TBoardId,
    status?: TStatus,
): IColor[] => {
    return getBoardColorsByStatusSelector(
        getBoardColors(state, boardId),
        status || TStatus.STATUS_ACTIVE,
    );
};
