import { IGetState, TCardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { cardsSetStatus } from './cardsSetStatus';
import { TColorTagColor, IColor, TStatus } from '../../../../types/model';
import { getCard } from '../../../../store/model/selectors/getCard';
import { getList } from '../../../../store/model/list/selectors/getList';
import { listSetStatus } from '../../list/listSetStatus';
import { getBoardColors } from '../../../../store/model/board/selectors/getBoardColors';
import { boardColorEdit } from '../../color/boardColorEdit';

export const cardsUnarchive = (
    cardIds: TCardId[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(cardsSetStatus(cardIds, TStatus.STATUS_ACTIVE))
            .then(() => {
                const listsToUpdate: number[] = [];
                cardIds.forEach(cardId => {
                    const card = getCard(getState(), cardId);
                    const list = getList(getState(), card.listId);
                    const boardId = list.dashboardId;
                    const colors = card.colorIds;
                    if (colors) {
                        const boardColors = getBoardColors(getState(), boardId, true);
                        colors.forEach(id => {
                            const colorTag = boardColors.find(color => color.id === id);
                            if (colorTag && colorTag.status === TStatus.STATUS_ARCHIVE) {
                                dispatch(boardColorEdit(boardId, {
                                    id,
                                    status: TStatus.STATUS_DELETED,
                                    name: getColorName(
                                        boardColors.filter(color => color.status !== TStatus.STATUS_ARCHIVE),
                                        colorTag.name,
                                        colorTag.color
                                    )
                                }));
                            }
                        });
                    }
                    if (
                        list.status !== TStatus.STATUS_ACTIVE &&
                        !listsToUpdate.includes(list.id)
                    ) {
                        listsToUpdate.push(list.id);
                    }
                })
                const promises = listsToUpdate.map((listId) => {
                    return dispatch(listSetStatus(listId, TStatus.STATUS_ACTIVE));
                });
                return Promise.all(promises);
            });
    };
    return action;
};

const getColorName = ( // to avoid dublicates
    colors: IColor[],
    name: string,
    color: TColorTagColor
): string => {
    if (colors.find(colorTag => colorTag.name.toLowerCase() === name.toLowerCase() && colorTag.color === color)) {
        return getColorName(colors, 'Copy of ' + name, color);
    } else {
        return name;
    }
}
