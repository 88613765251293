import { ICollaborator } from '../../../../../store/collaborators/collaborators/types';

export const getCollaboratorShortDisplayName = (
    { displayName }: ICollaborator
): string => {
    return displayName
        .split(' ')
        .map((word, index) => {
            if (index === 0) {
                return word;
            } else {
                return word[0];
            }
        })
        .join(' ');
}
