import { IGetState, TBoardId } from '../../../types/types';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { IBackup } from '../../../store/model/boardBackups/types';
import { patchRest } from './api/postRest';
import { IBoard } from '../../../store/model/board/types';
import { backupsActionSet } from '../../../store/model/actions/backupsActionSet';
import { backupActionSet } from '../../../store/model/backups/actions/backupActionSet';
import { addToListAction } from '../../../store/model/boardBackups/actions/addToListAction';

export const backupCreate = (
    boardId: TBoardId,
    backup: IBackup
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(patchRest(boardId, backup)).then((board: IBoard) => {
            dispatch(backupsActionSet(backupActionSet(boardId, addToListAction(backup))));
        })
    };
    return action;
};
