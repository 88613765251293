'use strict';
import { historyPush } from './../../react_components/base/helpers/historyHelper';

import featuresTemplate from './templates/features.html';
import singlePopupTemplate from './templates/singlePopup.html';
import * as _ from 'underscore';

App.Views.InfoScreens = App.Views.BaseView.extend({
    className: 'second-panel__inner',
    featuresTpl: _.template( featuresTemplate ),
    singlePopupTpl: _.template( singlePopupTemplate ),
    // welcomeTpl: _.template($('#welcome').html()),
    slidesCount: 1,
    scrollTreshold: 50,
    scrollLeft: 0,
    scrollTimer: null,

    events: {
        'click .second-panel__close': 'onCloseClick',
        'click .js-feedback': 'showFeedback',
        'click .profile-box__menu-link--rate-app': 'onRateAppClick',
        'click .features-slider__link': 'onChangeSlide',
        'click .promote-us__social-link': 'socialIconClicked',
    },

    initialize: function(options) {
        this.type = options.type;
        this.updates = options.updates;
        App.vent.on(App.vent['view:resized'], this.resizeSlider, this);
    },

    render: function() {
        historyPush({
            state: { page: 'info-screen' },
            onpopstate: (e) => this.onCloseClick(e)
        });

        switch (this.type) {
            case 'features':
                this.$el.html(this.featuresTpl({pills: this.updates.length}));
                this.updates.forEach(function(update, index) {
                    this.$el.find('.features-slider__items').prepend(update.render().el);
                    this.slidesCount++;
                }, this);
                this.$el.find('.features-slider__item:first-child').addClass('features-slider__item--active');
                this.initSlider();
                this.setSliderHeight();
                break;
            case 'videoIntro':
            case 'videoTutorial':
                this.$el.html(this.singlePopupTpl({ title: this.updates.title }));
                this.$el.find('.features-slider').append(this.updates.render().el);
                break;
            default:
                break;
        }
        return this;
    },

    showFeedback: function() {
        App.controller.showHelpPopup()
        return false;
    },

    onRateAppClick: function() {
        App.controller.trackEvent(Messages.getText('ga.category.new_features'), Messages.getText('ga.action.new_features.rate'));
    },

    onCloseClick: function(e) {
        e.stopPropagation();
        this.hide();
    },

    scrollToSlide: function(delta) {
        delta = (delta > 0) ? 1 : ((delta < 0) ? -1 : 0);
        this.selectSlide(this.getActiveSlide() + delta);
    },

    onScrollSlder: function(e) {
        if (this.$el.find('.features-slider__viewport').hasClass('scrolling')) return false;
        var scrollDelta = e.currentTarget.scrollLeft - this.scrollLeft,
            scrollDeltaTreshold = Math.abs(scrollDelta) - this.scrollTreshold;

        clearTimeout( this.scrollTimer );
        if (scrollDeltaTreshold > 0) {
            this.scrollToSlide(scrollDelta);
        }
        this.scrollTimer = setTimeout(() => {
            if (scrollDeltaTreshold < 0) {
                this.selectSlide(this.getActiveSlide());
                return;
            }
            this.scrollToSlide(scrollDelta);
        }, 66);
    },

    hide: function() {
        App.vent.trigger(App.vent['secondPanel:close']);
        return this;
    },

    /**
     * Получает размеры слайда
     * @returns {Number}
     */
    getSlideWidth: function() {
        return this.$el.find('.features-slider__item').width();
    },

    getActiveSlide: function() {
        return this.$el.find('.features-slider__item--active').index() || 0;
    },

    /**
     * Делаем слайдер рабочим
     */
    initSlider: function() {
        //Начинаем следить переключение по пилам слайдера
        this.$el.find('.features-slider__pill').on('click', _.bind(function(e) {
            var pill = $(e.target).index();
            this.selectSlide(pill);
        }, this));

        this.$el.find('.features-slider__viewport').on('scroll', _.bind(function(e) {
            this.onScrollSlder(e);
        }, this));

        //Обновляем состояние кнопочек влево и вправо. меняем имена слайдов на необходимые
        this.toggleSlideNavButtons();
        this.updateSlideNames();
    },

    /**
     * Функция которая выполняется при смене слайда
     */
    onChangeSlide: function(e) {
        var activeSlide = this.getActiveSlide();
        if (e && e.resize) {
            this.selectSlide(activeSlide);
            return
        }
        var nextSlide = $(e.currentTarget).hasClass('features-slider__link--next') ? activeSlide + 1 : activeSlide - 1;
        this.selectSlide(nextSlide);
    },

    resizeSlider: function() {
        this.initSlider();
        this.onChangeSlide({ resize: true });
    },

    /**
     * Метод который переключает слайдер на текущий(параметр slideIndex) слайд
     * @param slideIndex
     */
    selectSlide: function(slideIndex) {
        if (this.$el.find('.features-slider__viewport').hasClass('scrolling')) return;
        if (this.$el.find('.features-slider__item').length > slideIndex) {
            this.$el.find('.features-slider__viewport').addClass('scrolling');
            //Ставим пометку активному пилу снимая с остальных активность
            var newPill = this.$el
                              .find('.features-slider__pill')
                              .removeClass('features-slider__pill--active')
                              .eq(slideIndex);
            newPill.addClass('features-slider__pill--active');

            //Ставим пометку активному слайду снимая с остальных активность
            var newActiveSlide = this.$el
                                     .find('.features-slider__item')
                                     .removeClass('features-slider__item--active')
                                     .show()
                                     .eq(slideIndex);
            newActiveSlide.addClass('features-slider__item--active');

            //Находим на сколько всё скролить, скроллим
            var scrollSize = parseInt(newActiveSlide.index() * this.getSlideWidth());
            this.$el.find('.features-slider__items').css('width', this.getSlideWidth() * this.slidesCount);
            this.$el.find('.features-slider__viewport').stop().scrollLeft(scrollSize);
            this.scrollLeft = scrollSize;
            this.scrollTimer = setTimeout(() => {
                this.$el.find('.features-slider__viewport').removeClass('scrolling');
            }, 1000);

            //Обновляем состояние кнопочек влево и вправо. меняем имена слайдов на необходимые
            this.toggleSlideNavButtons(slideIndex);
            this.updateSlideNames(slideIndex);
            //Меняем высоты области слайдера как область контента слайда
            this.setSliderHeight(slideIndex);
        }
    },

    /**
     * Устанавливает высоты слайдера как у целевого слайда
     * @param slideIndex целевой слайд
     */
    setSliderHeight: function(slideIndex = 0) {
        var target = this.$el.find('.features-slider__items .features-slider__item').eq(slideIndex);
        var heightInterval = setInterval(() => {
            if (target.height()) {
                this.$el.find('.features-slider__items').stop().animate({height: target.height()}, 700);
                const targetHeight = target.find('.features-slider__item-content').height() - 40;
                this.$el.find('.features-slider__pills').css('top', targetHeight);
                clearInterval(heightInterval);
            }
        }, 500);
    },

    /**
     * Метод который устанавливает название кнопок и тайтл всплывающего окна как у целевого слайда.
     * @param slide Целевой слайд
     */
    updateSlideNames: function(slide) {
        slide = slide || 0;
        var currentSlide = this.$el.find('.features-slider__item').eq(slide);
        if (currentSlide) {
            this.$el.find('.second-panel__title').text(currentSlide.data('title'));
        } else {
            this.$el.find('.second-panel__title').text("Features");
        }
    },
    /**
     * Меняем отображение кнопок слайдера "дальше" и "предыдущая"
     * @param slide Номер целевого слайда
     */
    toggleSlideNavButtons: function(slide) {
        slide = slide || 0;
        this.$el.find('.features-slider__link--prev').css("visibility", slide > 0 ? 'visible' : 'hidden');
        this.$el.find('.features-slider__link--next')
            .css("visibility", this.$el.find('.features-slider__item').length - 1 > slide ? 'visible' : 'hidden');
    },

    /**
     * События GA по клику на социальные кнопки
     */
    socialIconClicked: function(e) {
        var link = $(e.target).closest('.promote-us__social-link');
        if (link.hasClass('promote-us__social-link--facebook')) {
            App.controller.trackEvent(
                Messages.getText('ga.category.new_features'),
                Messages.getText('ga.action.new_features.facebook_new_features')
            );
        }if (link.hasClass('promote-us__social-link--linkedin')) {
            App.controller.trackEvent(
                Messages.getText('ga.category.new_features'),
                Messages.getText('ga.action.new_features.linkedin_new_features')
            );
        }
        if (link.hasClass('promote-us__social-link--twitter')) {
            App.controller.trackEvent(
                Messages.getText('ga.category.new_features'),
                Messages.getText('ga.action.new_features.twitter_new_features')
            );
        }
        if (link.hasClass('promote-us__social-link--youtube')) {
            App.controller.trackEvent(
                Messages.getText('ga.category.new_features'),
                Messages.getText('ga.action.new_features.youtube_new_features')
            );
        }
    },

    testAttributes: function() {
        return {
            type: 'feature-popup'
        }
    },

    remove: function() {
        App.vent.off(null, null, this);
        return Backbone.View.prototype.remove.call(this);
    }
}, {
    slideStep: 640
});

App.Views.InfoScreens.mixin(App.Mixins.Test);
