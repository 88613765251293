import { connect } from 'react-redux';
import { onClick } from './events/onClick';
import { HelpPanelAffiliateLinkComponent } from '../../components/HelpPanelAffiliateLink/HelpPanelAffiliateLinkComponent';
import { IHelpPanelAffiliateLinkEvents } from '../../components/HelpPanelAffiliateLink/types';

const mapDispatchToProps = (
    dispatch: any
): IHelpPanelAffiliateLinkEvents => {
    return {
        onClick: () => dispatch(onClick())
    }
};

export const HelpPanelAffiliateLinkHOC = connect(
    null,
    mapDispatchToProps
)(HelpPanelAffiliateLinkComponent);

HelpPanelAffiliateLinkHOC.displayName = 'HelpPanelAffiliateLinkComponent';
