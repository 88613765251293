import { ISnackbarPromt } from './types';
import { Dispatch } from '../../../../../types/actions';
import { SNACKBAR_BUTTON_CANCEL, SNACKBAR_BUTTON_DELETE } from '../constants';
import { getDefaultActionEvent } from '../helpers/getDefaultActionEvent';
import { snackbarPromtDefault } from './promtDefault';

export const snackbarPromtDelete = (
    options: ISnackbarPromt
) => {
    return (dispatch: Dispatch) => {
        options.controls = [
            {
                action: () => dispatch(getDefaultActionEvent(options.id, options.actionCancel)),
                title: SNACKBAR_BUTTON_CANCEL
            },
            {
                action: () => dispatch(getDefaultActionEvent(options.id, options.actionApply)),
                isPrimary: true,
                title: SNACKBAR_BUTTON_DELETE
            },
        ];
        dispatch(snackbarPromtDefault(options));
    }
};
