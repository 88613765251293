import { Dispatch, ThunkAction } from 'app/types/actions';
import { IGetState } from 'app/types/types';
import openPushToGoogleCalendarDropDown
    from '../../../../TimingSection/pushToGoogleCalendar/store/actions/openPushToGoogleCalendarDropDown';
import syncGoogleCalendarsList from '../../../../TimingSection/pushToGoogleCalendar/effects/syncGoogleCalendarsList';
import { getDisabledActions } from '../selectors/getDisabledActions';
import setDisabledActions from '../../../../TimingSection/pushToGoogleCalendar/store/actions/setDisabledActions';
import { SegmentCardEvent, segmentTrackAction } from 'app/middlewares/segment';

export const showGoogleCalendarsList = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const selectedCardId = state.pushToGoogleCalendar.cardId;
        const disabledActions = getDisabledActions(selectedCardId, state);
        dispatch(segmentTrackAction(SegmentCardEvent.PUSH_TO_GOOGLE_CALENDAR_DROPDOWN_OPENED));
        dispatch(openPushToGoogleCalendarDropDown());
        dispatch(setDisabledActions(disabledActions));
        dispatch(syncGoogleCalendarsList());
    };
    return action;
};
