import { IGetState } from 'app/types/types';
import { Dispatch, ThunkAction } from 'app/types/actions';
import { patchRestHelper } from './api/helper/patchRestHelper';
import { TDriveDocId } from 'app/types/rest/IRestDriveDoc';
import { getDriveDoc } from 'app/store/model/driveDocs/selectors/getDriveDoc';
import { SegmentCardEvent, segmentTrackAction } from 'app/middlewares/segment';

export const togglePinAttachment = (
    driveDocId: TDriveDocId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const driveDoc = getDriveDoc(state, driveDocId);
        if(driveDoc.main){
            dispatch(segmentTrackAction(SegmentCardEvent.ATTACHED_FILE_UNPINNED));
        }else{
            dispatch(segmentTrackAction(SegmentCardEvent.ATTACHED_FILE_PINNED));
        }
        return dispatch(patchRestHelper(driveDocId, {
            main: !driveDoc.main
        }));
    };
    return action;
};
