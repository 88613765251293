import { patchRestBulk } from '../patchRestBulk';
import { IRestCard } from '../../../../../../types/rest/IRestCard';
import { Dispatch, ThunkAction } from '../../../../../../types/actions';
import { IGetState } from '../../../../../../types/types';

export const cardsRestPatchBulk = (
    cards: IRestCard[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(patchRestBulk(cards))
    };
    return action;
};
