import { TBoardId, TCardId, TFilterId, TListId } from '../../../../../../types/types';
import { IAsidePanelTab } from '../../types';
import { SegmentCardDetailsCloseSourceValue } from '../../../../../../middlewares/segment/trackEntities/cardEvents';

export enum EAsidePanelProperty {
    CARD_DESCRIPTION = 'CARD_DESCRIPTION',
    CARD_ASSIGNEES = 'CARD_ASSIGNEES',
    CARD_ATTACHMENTS = 'CARD_ATTACHMENTS',
    CARD_CHECKLIST = 'CARD_CHECKLIST',
    CARD_COMMENTS = 'CARD_COMMENTS',
    CARD_CUSTOM = 'CARD_CUSTOM',
    CARD_START_DATE = 'CARD_START_DATE',
    CARD_DUE_DATE = 'CARD_DUE_DATE',
    CARD_DATES = 'CARD_DATES', // old name for migrating
    CARD_DEPENDENCIES = 'CARD_DEPENDENCIES',
    CARD_ESTIMATE = 'CARD_ESTIMATE',
    CARD_MOVETO = 'CARD_MOVETO',
    CARD_NOTIFY = 'CARD_NOTIFY',
    CARD_PRIORITY = 'CARD_PRIORITY',
    CARD_RELATED = 'CARD_RELATED',
    CARD_SUBCARDS = 'CARD_SUBCARDS',
    CARD_TAGS = 'CARD_TAGS',
    CARD_RECURRING = 'CARD_RECURRING'
};

export enum EAsidePanelType {
    CARD_TEMPLATE = 'CARD_TEMPLATE',
};

export interface IAsidePanelContext {
    boardId?: TBoardId;
    cardId?: TCardId;
    filterId?: TFilterId;
    getContext?: () => IAsidePanelContext;
    isButtonsFixed?: boolean; // в board settings панель кнопок всегда раскрыта KNB-1098
    isMobile?: boolean;
    isScrollDisabled?: boolean; // отключает скролл панелек (для открытого попапа проперти, чтобы ничего не прыгало)
    isUserTimeFormat12?: boolean;
    mobileButton?: string;
    listId?: TListId;
    tabs?: ReadonlyArray<IAsidePanelTab>;
    changeTab?: (tabKey: string) => void;
    openedProperty?: EAsidePanelProperty;
    setIsScrollDisabled?: (isScrollDisabled: boolean) => void;
    setOpenedProperty?: (openedProperty: EAsidePanelProperty) => void;
    tabKey?: string;
    onLoaded?: (key: string, isLoading: boolean) => void;
    type?: EAsidePanelType;
    showedProperties?: Set<EAsidePanelProperty>;
    addShowedProperty?: (property: EAsidePanelProperty) => void;
    asidePanelWidth?: number;
};

export interface IAsidePanelFields {
    activeTabIndex?: number;
    boardId?: TBoardId;
    cardId?: TCardId;
    children?: any;
    closedRef?: HTMLElement; // куда вернуть фокус перед закрытием
    panelActions?: JSX.Element;
    className?: string;
    filterId?: TFilterId;
    footer?: JSX.Element;
    isActionsScticky?: boolean; // actions on top, hide header on scroll
    isActionsMinimized?: boolean;
    isButtonsFixed?: boolean;
    isFakeRequests?: boolean; // для тестов
    isLeft?: boolean;
    isLoading?: boolean;
    isMobile?: boolean;
    isPrint?: boolean;
    isRequests?: boolean;
    isUserTimeFormat12?: boolean;
    mobileButton?: string;
    listId?: TListId;
    tabs?: ReadonlyArray<IAsidePanelTab>;
    title?: string | JSX.Element;
    titleAction?: JSX.Element;
    type?: EAsidePanelType;
    isForceClosed?: boolean;
    isAssignedToMe?: boolean;
    isCardFromNotification?: boolean;
    asidePanelWidth?: number;
}

export interface IAsidePanelEvents {
    onClose?: (source?: SegmentCardDetailsCloseSourceValue) => void;
    onTabActive?: (activeTabIndex: number) => void;
    onDidMount?: () => Promise<void>;
    onDidUnmount?: () => void;
    onResize?: (width: number) => void;
    onLoaded?: (key: string) => void;
    onResized?: (width: number) => void;
}

export interface IAsidePanelProps extends
    IAsidePanelFields,
    IAsidePanelEvents
{}
