'use strict';

import dialogPermissionsGSuiteTemplate from './templates/dialogPermissionsGSuite.html';
import * as _ from 'underscore';

App.Views.PermissionsGsuite = App.Views.PermissionsDialog.extend({
    className: App.Views.PermissionsDialog.prototype.className + ' app-permissions--gsuite',
    template: _.template( dialogPermissionsGSuiteTemplate ),

    getData: function() {
        return {
            title:          Messages.getText('google-groups-sharing.permissions-title'),
            description:    Messages.getText('google-groups-sharing.permissions-description'),
            button:         Messages.getText('permissions.give'),
            gdriveAll:      Messages.getText('permissions.gdrive-all'),
            gdriveSelf:     Messages.getText('permissions.gdrive-self'),
            gdriveApp:      Messages.getText('permissions.gdrive-app'),
            domainGroups:   Messages.getText('permissions.domain-groups'),
            sheetsAll:      Messages.getText('permissions.sheets-all'),
            gcalAll:        Messages.getText('permissions.gcal-all'),
        }
    }
});
