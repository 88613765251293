import { EColorTagColor, TColorTagColor } from '../../../../../../../../../types/model';

const COLORS_MATCH: {[key: string]: EColorTagColor} = {
    [EColorTagColor.RED]: EColorTagColor.VIVID_RED,
    [EColorTagColor.PERSIMMON]: EColorTagColor.VIVID_RED,
    [EColorTagColor.ORANGE]: EColorTagColor.VIVID_ORANGE,
    [EColorTagColor.YELLOW]: EColorTagColor.VIVID_YELLOW,
    [EColorTagColor.PARIS_DAISY]: EColorTagColor.PASTEL_YELLOW,
    [EColorTagColor.MANZ]: EColorTagColor.PASTEL_GREEN,
    [EColorTagColor.LUCKY]: EColorTagColor.VIVID_BROWN,
    [EColorTagColor.LAWN_GREEN]: EColorTagColor.PASTEL_GREEN,
    [EColorTagColor.SPRING_GREEN]: EColorTagColor.PASTEL_GREEN,
    [EColorTagColor.LIME]: EColorTagColor.PASTEL_GREEN,
    [EColorTagColor.GREEN]: EColorTagColor.VIVID_GREEN,
    [EColorTagColor.BLUE]: EColorTagColor.VIVID_BLUE,
    [EColorTagColor.CYAN]: EColorTagColor.VIVID_BLUE,
    [EColorTagColor.SKY]: EColorTagColor.PASTEL_BLUE,
    [EColorTagColor.AQUA]: EColorTagColor.PASTEL_BLUE,
    [EColorTagColor.AQUAMARINE]: EColorTagColor.PASTEL_GREEN,
    [EColorTagColor.LIGHT_SKY_BLUE]: EColorTagColor.PASTEL_BLUE,
    [EColorTagColor.NEON_BLUE]: EColorTagColor.VIVID_BLUE,
    [EColorTagColor.INDIGO]: EColorTagColor.VIVID_PURPLE,
    [EColorTagColor.PURPLE]: EColorTagColor.VIVID_PURPLE,
    [EColorTagColor.ELECTRIC_PURPLE]: EColorTagColor.VIVID_PURPLE,
    [EColorTagColor.BRIGHT_PURPLE]: EColorTagColor.PASTEL_PURPLE,
    [EColorTagColor.JAZZBERRY_JAM]: EColorTagColor.VIVID_RED,
    [EColorTagColor.PINK]: EColorTagColor.VIVID_PINK,
    [EColorTagColor.SWISS_COFFEE]: EColorTagColor.PASTEL_BROWN,
    [EColorTagColor.BISMARK]: EColorTagColor.VIVID_GREY,
    [EColorTagColor.BLACK]: EColorTagColor.VIVID_GREY
};

export const getColorTagColor = (
    color: TColorTagColor
): TColorTagColor => {
    return COLORS_MATCH[color] || color;
}
