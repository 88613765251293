import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { ICardNameProps } from './types';
import './_CardName.scss';
import { CD_DEFAULT_CARD_NAME, CLASS_CARD_DETAILS } from '../../../constants';
import { CardColorHOC } from '../../../Color/hocs/CardColorHOC/CardColorHOC';
import { Button, Icon, Input } from 'kui';
import {
    CARD_DEFAULT_TEMPLATE,
    CARD_NAME,
    CARD_NUMBER_GENERATE,
    CARD_NUMBER_REMOVE,
    CARD_NUMBER_REMOVE_MODAL_ALL_CARDS,
    CARD_NUMBER_REMOVE_MODAL_TEXT,
    CARD_NUMBER_REMOVE_MODAL_TEXT_LOADING,
    CARD_NUMBER_REMOVE_MODAL_THIS_CARD,
    CARD_NUMBER_REMOVE_MODAL_TITLE,
    CARD_NUMBER_REMOVE_MODAL_TITLE_LOADING
} from './constants';
import { getAsidePanelTooltip } from '../../../../asidePanel/helpers/getAsidePanelTooltip';
import { markdown } from '../../../../../../../helper/markdownHelper';
import { getParentsClasses } from '../../../../../helpers/getParentsClasses';
import { getRange } from './helpers/getRange';
import { root } from 'app/store/constants';
import ReactHtmlParser from 'react-html-parser';
import {
    ActionModal
} from '../../../../../base/components/ActionModal/ActionModal';
import {
    CARD_NUMBER_MIN_LOADING_CARDS_COUNT
} from '../../../../boardDetails/details/CardNumber/components/BoardCardNumber/constants';

export function CardName({
    boardId,
    cardId,
    cardNumber,
    color,
    isReadonly,
    isShowColorButton,
    name,
    panelWidth,
    onSave,
    onCardNumberClick,
    onCardNumberRemove,
    onCardNumberRemoveAll,
    onCardNumberGenerate,
    cardNumberEnabled,
    isDefault,
    isDefaultTemplate,
    isMarkdown,
    isShowRemoveModal,
    onClick,
    onEsc,
    onRenameEnd,
    onShowSnackbar
}: ICardNameProps) {
    const componentRef = React.useRef(null);
    const inputRef = React.useRef(null);
    const cardExtraRef = useRef(null);
    const requestAnimation = React.useRef(null);

    const [cardName, setCardName] = useState(name);
    const [numberWidth, setNumberWidth] = useState(null);
    const [editing, setEditing] = React.useState(false);
    const [selectedRange, setSelectedRange] = React.useState(0);
    const [isShow, setShow] = useState(null);
    const [isLoading, setLoading] = useState(null);
    const [activeEl, setActiveEl] = React.useState(null);
    const data = React.useRef({ cardName });
    data.current = { cardName };

    let className = CLASS_CARD_DETAILS + '__name';
    const classNameInput = CLASS_CARD_DETAILS + '__name-input';
    const classNameExtra = CLASS_CARD_DETAILS + '__name-extra';
    const classNameExtraText = CLASS_CARD_DETAILS + '__name-extra-text';
    const classNameExtraNumber = CLASS_CARD_DETAILS + '__name-extra--number';
    const classNameSpace = CLASS_CARD_DETAILS + '__name-space';
    const classNameText = CLASS_CARD_DETAILS + '__name-text';
    const classMarkdown = classNameText + '--markdown';
    const classNameWrapper = CLASS_CARD_DETAILS + '__name-wrapper';
    const classExtraWrapper = CLASS_CARD_DETAILS + '__extra-wrapper';
    const classRemoveCardNumber = CLASS_CARD_DETAILS + '__remove-card-number';
    const classGenerateCardNumber = CLASS_CARD_DETAILS + '__generate-card-number';

    const inputStyle = numberWidth !== null ? {textIndent: numberWidth} : null;

    let styleBG = {};
    if (color) {
        className += ' ' + className + '--bgcolor-' + color.slice(1);
        styleBG = {
            backgroundColor: color // old non-material colors
        };
    } else {
        className += ' ' + className + '--no';
    }

    useEffect(() => {
        setCardName(name);
    }, [name]);

    useEffect(() => {
        requestAnimation.current = requestAnimationFrame(() => {
            if (cardExtraRef.current) {
                setNumberWidth(cardExtraRef.current.offsetWidth);
            } else {
                setNumberWidth(0);
            }
        });
    }, [cardNumber, isDefaultTemplate]);

    useEffect(() => {
        const component = componentRef.current;
        if (isDefault) {
            setTimeout(() => { // KNB-3383 подождать открытие панели
                setEditing(true);
                setTimeout(() => { // был баг: открывается панель, потом закрывается дропдаун и забирает фокус с имени
                    const textarea = component.querySelector('textarea');
                    if (textarea) {
                        textarea.focus();
                        textarea.select();
                    }
                }, 100);
            }, 100);
        }
        return () => {
            saveName(data.current.cardName);
            if (requestAnimation.current) cancelAnimationFrame(requestAnimation.current);
        }
    }, [isDefault]);

    const handleInputBlur = (
        e: React.SyntheticEvent
    ) => {
        setEditing(false);
        let { value } = e.target as HTMLInputElement;
        saveName(value);
        onRenameEnd();
    };
    const handleInputChange = (
        e: React.SyntheticEvent
    ) => {
        const { value } = e.target as HTMLInputElement;
        setCardName(value);
    };
    const handleKeyDown = (
        e: React.KeyboardEvent
    ) => {
        if (e.key === 'Escape') {
            e.stopPropagation();
            setCardName(name);
            setEditing(false);
            onEsc();
            return;
        }
        if (e.key === 'Enter') {
            if (e.shiftKey) return;
            e.preventDefault();
            let { value } = e.target as HTMLInputElement;
            saveName(value);
            onRenameEnd();
        }
    };

    const saveName = (value: string) => {
        if (!value){
            value = CD_DEFAULT_CARD_NAME;
        }
        setCardName(value);
        if (value !== name){
            onSave(value);
        }
    };

    const onHandleClick = (
        e: React.MouseEvent
    ) => {
        if (isReadonly) return;

        if (isMarkdown) {
            setEditing(true);
            requestAnimationFrame(() => {
                const textarea = componentRef.current.querySelector('textarea');
                if (textarea) {
                    textarea.focus();
                }
            });
            return;
        }

        const { startOffset, endOffset, startNode } = getRange(e, cardName, classNameText);
        const parentClasses = getParentsClasses(e.target as HTMLElement, [classExtraWrapper]);
        if (
            startNode &&
            startNode.classList.contains(classNameWrapper) ||
            parentClasses.includes(classExtraWrapper)
        ) {
            return;
        }

        setEditing(true);
        requestAnimationFrame(() => {
            const textarea = componentRef.current.querySelector('textarea');
            if (textarea) {
                textarea.focus();
                textarea.setSelectionRange(startOffset, endOffset);
            }
        });
    }

    const onRemove = (
        e: React.MouseEvent
    ) => {
        e.stopPropagation();
        onCardNumberRemove();
        if (isShowRemoveModal) {
            setActiveEl(document.activeElement);
            setShow(true);
        }
    }

    const onClose = () => {
        setShow(false);
        setLoading(false);
        if (activeEl) activeEl.focus();
    };

    const onRemoveAll = () => {
        onCardNumberRemoveAll().then(() => {
            onShowSnackbar();
        }).finally(onClose);
    };

    const onGenerate = (
        e: React.MouseEvent
    ) => {
        e.stopPropagation();
        onCardNumberGenerate();
    }

    const handleCardNumberClick = (
        e: React.MouseEvent
    ) => {
        const target = e.target as HTMLElement;
        if(!target)
            return;
        let range = new Range();
        range.setStart(target, 0);
        range.setEnd(target, 1);
        root.getSelection().removeAllRanges()
        root.getSelection().addRange(range);
        if (selectedRange <= 3) {
            onCardNumberClick()
        }
    }

    let extraElement = cardNumber || isDefaultTemplate
        ? <>
            <span
                className={`
                    ${classNameExtra}
                    ${!isDefaultTemplate ? classNameExtraNumber : ''}
                `}
            >
                 {!isDefaultTemplate &&
                     <Button
                         className={classRemoveCardNumber}
                         variant={'icon'}
                         tooltip={getAsidePanelTooltip({
                             value: CARD_NUMBER_REMOVE
                         })}
                         aria-label={`${cardNumber}: ${CARD_NUMBER_REMOVE}`}
                         onClick={onRemove}
                     >
                         <Icon size={24} xlink={'clear'} />
                     </Button>
                 }
                <span
                  className={classNameExtraText}
                  onClick={cardNumber ? handleCardNumberClick : null}
                >
                    {isDefaultTemplate ? CARD_DEFAULT_TEMPLATE : cardNumber}
                </span>
            </span>
            <span className={classNameSpace}>:&nbsp;</span>
        </>
        : null;

    if (!extraElement && cardNumberEnabled) {
        extraElement = <Button
            className={classGenerateCardNumber}
            variant={'icon'}
            tooltip={getAsidePanelTooltip({
                value: CARD_NUMBER_GENERATE
            })}
            onClick={onGenerate}
        >
            <Icon size={16} xlink={'plus'} />
        </Button>
    }

    React.useEffect(() => {
        const target = document.querySelector(`.${classNameExtraText}`) as HTMLElement;
        if (!target) return;
        let down = 0;
        let up = 0;
        const mouseDownListener = (event: any) => {
            down = event.clientX;
        }
        const mouseUpListener = (event: any) => {
            up = event.clientX;
            let range = Math.abs(down - up);
            setSelectedRange(range)
        }

        target.addEventListener('mousedown', mouseDownListener)
        target.addEventListener('mouseup', mouseUpListener)

        return () => {
            target.removeEventListener('mousedown', mouseDownListener)
            target.removeEventListener('mouseup', mouseUpListener)
        }
    },[cardNumber])

    const [progress, setProgress] = React.useState(null);
    const timerRef = React.useRef(null);
    React.useEffect(() => {
        if (timerRef.current) clearInterval(timerRef.current);
        if (isLoading) {
            if (root.App.controller.boardCardPrefixInfo.totalCardsCount < CARD_NUMBER_MIN_LOADING_CARDS_COUNT) return; // нет смысла показывать, если мало карт
            timerRef.current = setInterval(() => {
                setProgress(root.App.controller.boardCardPrefixInfo.getAddedCardsPercentage());
            }, 50);
        } else {
            setProgress(null);
        }
    }, [isLoading]);

    return (
        <div
            className={!editing ? className :
                `${className} editing` }
            key={panelWidth} // rerender on width change
            ref={componentRef}
            style={styleBG}
        >
            <div className={classNameWrapper} onClick={onHandleClick}>
                {extraElement &&
                    <span
                        className={classExtraWrapper}
                        ref={cardExtraRef}
                    >
                        {extraElement}
                    </span>

                }
                {!editing && inputStyle !== null &&
                    <div
                        className={`
                            ${classNameText}
                            ${isMarkdown ? classMarkdown : ''}
                        `}
                        style={inputStyle}
                    >
                        {ReactHtmlParser(markdown(cardName))}
                    </div>
                }
                {editing && !isReadonly && inputStyle !== null &&
                    <Input
                        className={classNameInput}
                        value={cardName}
                        onBlur={handleInputBlur}
                        onChange={handleInputChange}
                        onFocus={onClick}
                        onKeyDown={handleKeyDown}
                        style={inputStyle}
                        label={CARD_NAME}
                        ref={inputRef}
                    />
                }
            </div>
            {isShowColorButton && <CardColorHOC cardId={cardId} boardId={boardId}/>}
            <ActionModal
                isShow={isShow}
                actions={[
                    {
                        variant: 'primary',
                        onClick: () => {
                            onClose();
                            onCardNumberRemove();
                        },
                        children: CARD_NUMBER_REMOVE_MODAL_THIS_CARD
                    },
                    {
                        variant: 'secondary',
                        onClick: onRemoveAll,
                        children: CARD_NUMBER_REMOVE_MODAL_ALL_CARDS
                    }
                ]}
                isLoading={isLoading}
                progress={progress}
                text={isLoading ? CARD_NUMBER_REMOVE_MODAL_TEXT_LOADING : CARD_NUMBER_REMOVE_MODAL_TEXT}
                title={isLoading ? CARD_NUMBER_REMOVE_MODAL_TITLE_LOADING : CARD_NUMBER_REMOVE_MODAL_TITLE}
                onClose={onClose}
            />
        </div>
    );
}
