import { IGetState, TBoardId, TCardId } from 'app/types/types';
import { Dispatch, ThunkAction } from 'app/types/actions';
import { getBoard } from 'app/store/model/selectors/getBoardById';
import { commonAuthManagerInstance } from 'app/helper/authorisation/common/CommonAuthManager';
import { root } from 'app/store/constants';
import { getCard } from 'app/store/model/selectors/getCard';
import { addAttachments } from 'app/rest/effects/card/attacments/addAttachments';
import { EFileType } from 'app/view/react_components/aside_panel/cardDetails/AttachmentsSection/components/CardAttachmentGoogleFiles/constants';
import { cardUpdateAttachmentsCount } from 'app/rest/effects/card/cardUpdateAttachmentsCount';
import { ERestDriveDocType } from '../../../../../../../../types/rest/IRestDriveDoc';

export const createGoogleFile = (
    boardId: TBoardId,
    cardId: TCardId,
    fileType: EFileType
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const board = getBoard(getState(), boardId);
        const card = getCard(getState(), cardId);
        const createFile = (resp: any) => {
            dispatch(addAttachments(cardId, [{
                fileId: `${resp.id}`,
                type: ERestDriveDocType.CARD
            }])).then(() => {
                dispatch(cardUpdateAttachmentsCount(cardId))
            })
            if (fileType === EFileType.SHEETS || fileType === EFileType.FORMS) {
                window.open(`https://docs.google.com/${fileType}s/d/${resp.id}/edit`, '_blank');
            } else {
                window.open(`https://docs.google.com/${fileType}/d/${resp.id}/edit`, '_blank');
            }
        }

        return commonAuthManagerInstance.getAccessTokenOrShowLoginScreen().then((accessToken) =>{
            return new Promise((resolve, reject) => {
                let parents = [board.assetFolderId];
                const request = root.gapi.client.request({
                    path: '/drive/v3/files/?supportsAllDrives=true',
                    method: 'POST',
                    body:{
                        name : card.name,
                        mimeType : `application/vnd.google-apps.${fileType}`,
                        parents: parents
                    },
                });

                request.execute((resp: any) => {
                    if (resp.error && resp.error.code === 403) {
                        parents = ['root']
                        request.execute((newResp: any) => {
                            if (newResp.error) {
                                reject(newResp.error);
                            } else {
                                createFile(newResp)
                            }
                        })
                    } else {
                        createFile(resp)
                    }
                })
            })
        })
    };
    return action;
};
