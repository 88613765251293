import { IListViewCard } from '../store/listViewBoard/types';

export const sortCardsByDate = (
    cardA: IListViewCard,
    cardB: IListViewCard
): number => {
    const valueA = cardA? cardA.created : 0;
    const valueB = cardB? cardB.created : 0;
    return valueB - valueA;
};
