import { IGetState, TBoardId, TListId } from '../../../../../../../../../types/types';
import { EDashboardStatusListType, IDashboardStatusLists } from '../../../../../../../../../types/rest/IRestDashboard';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { boardSetStatusLists } from '../../../../../../../../../rest/effects/board/boardSetStatusLists';
import { getBoard } from '../../../../../../../../../store/model/selectors/getBoardById';

export const saveBoardStatusList = (
    boardId: TBoardId,
    listType: EDashboardStatusListType,
    listId: TListId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const board = getBoard(getState(), boardId);
        let statusLists: IDashboardStatusLists = {...board.statusLists};
        if (checkDublicates(listType, listId, statusLists)) {
            statusLists[listType] = listId;
        }
        dispatch(boardSetStatusLists(boardId, statusLists));
    };
    return action;
};

const checkDublicates = (typeIgnore: EDashboardStatusListType, listId: TListId, statusLists: IDashboardStatusLists): boolean => {
    const listTypes: EDashboardStatusListType[] = Object.values(EDashboardStatusListType);
    let result: boolean = true;
    listTypes.forEach((type) => {
        if (type !== typeIgnore && statusLists[type] === listId) {
            result = false;
        }
    })
    return result;
}

/*

const checkDublicates = (typeIgnore: EDashboardStatusListType, listId: TListId, statusLists: IDashboardStatusLists) => {
    const listTypes: EDashboardStatusListType[] = Object.values(EDashboardStatusListType);
    listTypes.forEach((type) => {
        if (type !== typeIgnore && statusLists[type] === listId) {
            statusLists[type] = 0;
        }
    })
}
*/
