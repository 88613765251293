import * as React from 'react';
import { SettingsSection } from '../../../../../../base/components/SettingsSection/SettingsSection';
import { AsidePanelContext } from '../../../../../asidePanel/components/AsidePanel/constants';
import { CLASS_BOARD_DETAILS_SECTION } from '../../../../constants';
import { IBoardEmailSectionProps } from './types';
import { BoardEmailHOC } from '../../hocs/BoardEmailHOC/BoardEmailHOC';
import { BOARD_SECTION_EMAIL } from '../../constants';

export function BoardEmailSection ({
}: IBoardEmailSectionProps) {
    const { boardId } = React.useContext(AsidePanelContext);
    const className = CLASS_BOARD_DETAILS_SECTION + ' ' + CLASS_BOARD_DETAILS_SECTION + '--email';

    return (
        <SettingsSection
            className={className}
            title={BOARD_SECTION_EMAIL}
        >
            <BoardEmailHOC boardId={boardId} />
        </SettingsSection>
    );
};
