import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { changeHideDone } from '../effects/changeHideDone';

export const onHideDoneChange = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        return dispatch(changeHideDone());
    };
    return action;
};
