import { Dispatch, ThunkAction } from 'app/types/actions';
import { IGetState } from '../../../../../../types/types';
import { root } from 'app/store/constants';
import { onClose } from './onClose';

export function onClickFeedback (
): ThunkAction {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        dispatch(onClose());
        root.App.controller.showHelpPopup();
    };
    return action
};
