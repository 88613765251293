/** @module utils */
import { dispatch, getAppState } from '../store/configureStore';
import moment from 'moment-timezone';
import { getUserTimeFormat12 } from '../store/model/authUser/selectors/getUserTimeFormat12';
import { getAuthUser } from '../store/model/authUser/selectors/getAuthUser';
import * as _ from 'underscore';
import { EUserDateformat } from 'app/types/rest/IRestAuthUser';

var App = window.App || {};
var Messages = window.Messages;
var $ = window.$;

export const Util = {

    oldColorsCount: 11,

    isDigit: function(event) {
        var char = (event.which) ? event.which : event.keyCode;
        // Handle the left/right arrow keys, backspace, enter, end, home, delete:
        if (char === 37 || char === 39 || char === 8 || char === 13 || char === 35 || char === 36 || char === 46) {
            return true;
        }
        // If not a number, don't do anything:
        if (char > 31 && (char < 48 || char > 57)) {
            return false;
        }
        return true;
    },

    filterOutLineBreak: function(event) {
        if (event.keyCode === 13) {
            event.preventDefault();
        }
    },

    filterOutLineBreaksOnPaste: function(element, afterPaste) {
        setTimeout(function() {
            element.val(element.val().replace(/(^|\r\n|\n)([^*]|$)/g, '$2'));
            if (afterPaste && typeof afterPaste === 'function') {
                afterPaste();
            }
        }, 100);
    },

    // todo deprecated;
    formatDate: function(date, format) {
        return this.formatDateMoment(date, format);
    },

    getDateInstanceofDate(date) {
        if (date instanceof Date) return date;
        if (isNaN(date))  return '';
        return new Date(date * 1000);
    },

    getDateMomentFromString(date, stringFormat) {
        return moment(date, stringFormat);
    },

    getLongDateFromString(date, stringFormat, userFormat) {
        let format = userFormat === EUserDateformat.FORMAT_AMERICAN
            ? 'MMMM Do YYYY'
            : 'Do MMMM YYYY';
        return this.getDateMomentFromString(date, stringFormat).format(format);
    },

    formatDateMoment: function(date, format) {
        return moment(this.getDateInstanceofDate(date)).format(format ? format : 'DD MMM YYYY');
    },

    formatTimestamp: function(date) {
        var format = getUserTimeFormat12(getAppState()) ? 'DD MMM YYYY hh:mm A' : 'DD MMM YYYY HH:mm';
        return this.formatDateMoment(date, format);
    },

    formatTimestampForPopover: function(date) {
        var format = getUserTimeFormat12(getAppState()) ? '<i>DD.MM.YY</i> аt <i>hh:mm A</i>' : '<i>DD.MM.YY</i> аt <i>HH:mm</i>';
        return this.formatDateMoment(date, format);
    },

    formatTime: function(date) {
        var format = getUserTimeFormat12(getAppState()) ? 'hh:mm A' : 'HH:mm';
        return this.formatDateMoment(date, format);
    },

    formatTimestampSeconds: function(date) {
        var format = getUserTimeFormat12(getAppState()) ? 'dd MMM YYYY hh:mm:ss A' : 'dd MMM YYYY HH:mm:ss';
        return this.formatDate(date, format);
    },

    formatDateAgo: function(timestamp) {
        var createdDate = new Date(timestamp * 1000);
        var now = Math.floor(new Date().getTime() / 1000);
        var diff = now - timestamp;
        var dm = Math.floor(diff / 60);
        var dh = Math.floor(dm / 60);
        var format;

        if (dm < 60) {
            return (dm < 2 ? Messages.getText('util.few_seconds_ago') : dm + ' ' + Messages.getText('util.some_minutes_ago'));
        } else {
            if (dh < 24) {
                return (dh < 2 ? Messages.getText('util.one_hour_ago') : dh + ' ' + Messages.getText('util.some_hours_ago'));
            } else {
                if (createdDate.getFullYear() === new Date().getFullYear()) {
                    format = getUserTimeFormat12(getAppState()) ? 'DD MMM, hh:mm A' : 'DD MMM, HH:mm';
                } else {
                    format = getUserTimeFormat12(getAppState()) ?  'DD MMM YYYY, hh:mm A' : 'DD MMM YYYY, HH:mm';
                }
                return this.formatDateMoment(createdDate, format);
            }
        }
    },

    formatShortDate: function(date) {
        return this.formatDateMoment(date, 'DD MMM');
    },

    formatShortDateFromTimestamp: function(timestamp) {
        const date = new Date(timestamp * 1000);
        return this.formatDateMoment(date, 'DD MMM');
    },

    formatTimestampToDayMonth: function(timestamp) {
        const date = new Date(timestamp * 1000);
        return this.formatDateMoment(date, 'D MMM');
    },

    formatShortDateWithTime: function(date) {
        var format = getUserTimeFormat12(getAppState()) ? 'DD MMM, hh:mm A' : 'DD MMM, HH:mm';
        return this.formatDateMoment(date, format);
    },

    devDebug: function(message, alertMessage){
        if (this.userInDomain("magicwebsolutions.co.uk") || this.userInDomain("mail.magicwebsolutions.co.uk")){
            let stack= {};
            if (Error.captureStackTrace) {
                Error.captureStackTrace(stack, this.devDebug);
            } else {
                stack.stack = (new Error()).stack;
            }
            console.log(message, stack.stack);
            if (alertMessage){
                alert(message);
            }
        }
    },

    userInDomain: function(domain) {
        return getAuthUser(getAppState()).email.indexOf(domain) != -1;
    },

    userDomain: function(email) {
        return email.replace(/.*@/, '');
    },

    formatRegularDate: function(date) {
        return this.formatDate(date, 'dd.MM.yyyy');
    },

    formatLongDate: function(date) {
        return this.formatDate(date, 'd MMMM yyyy');
    },

    formatLongDateWithTime: function(date) {
        var format = getUserTimeFormat12(getAppState()) ? 'D MMMM YYYY, hh:mm A' : 'D MMMM YYYY, HH:mm';
        return this.formatDateMoment(date, format);
    },

    formatBackEndDate: function(date) {
        var format = getUserTimeFormat12(getAppState()) ? 'YYYY-MM-DD hh:mm A' : 'YYYY-MM-DD HH:mm';
        return this.formatDateMoment(date, format);
    },

    formatDay: function(date) {
        return this.formatDate(date, 'd');
    },

    formatWeek: function(date) {
        return this.formatDate(date, 'E');
    },

    isMonday: function(date) {
        return this.formatWeek(date) === 'Mon';
    },

    formatMonth: function(date) {
        return this.formatDate(date, 'MMMM');
    },

    formatShortMonth: function(date) {
        return this.formatDate(date, 'MMM');
    },

    formatMonthYear: function(date) {
        return this.formatDate(date, 'MMMM yyyy');
    },

    formatShortMonthYear: function(date) {
        return this.formatDate(date, 'MMM yy');
    },

    daysInMonth: function(d) {
        var date = new Date(d * 1000);
        return 32 - new Date(date.getFullYear(), date.getMonth(), 32).getDate();
    },

    formatDashboardLoadingTime: function(diff) {
        var diffInSeconds = diff / 1000;
        if (diffInSeconds < 2) {
            return '< 2 seconds';
        }
        if (diffInSeconds < 5) {
            return '2 - 5 seconds';
        }
        if (diffInSeconds < 10) {
            return '5 - 10 seconds';
        }
        if (diffInSeconds < 15) {
            return '10 - 15 seconds';
        }
        return '> 15 seconds';
    },

    formatPrice: function(price) {
        if (!price) {
            price = 0;
        }
        return '$' + price.toFixed(2);
    },

    formatIntegerPrice: function(price) {
        if (!price) {
            price = 0;
        }
        return '$' + Math.round(price);
    },

    unescapeHTML: function(html) {
    	if (html) {
    		if (html.replace(/ /g, '').length == 0) {
    			return html.replace(/ /g, '&nbsp;');
    		}
    	}
    	return $('<div/>').text(html).html();
    },

    validateEmail: function(email) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    },

    getHttpStatusMessage: function(status) {
        var res = '';
        switch (status) {
            case 200:
                res += 'Server returned empty response.';
                break;
            case 400:
                res += 'The server rejected procesisng your changes due to illegal data.';
                break;
            case 401:
                res += 'Your session has expired.';
                break;
            case 403:
                res += 'You don\'t have access permission to this dashboard.';
                break;
            case 404:
                res += 'This dashboard is not available anymore or internet connection is lost.';
                break;
            case 415:
                res += 'Invalid File Format';
                break;
            case 500:
                res += 'An error occurred while connecting to Google Drive. Please try again.'; // 'Internal server
                // error.';
                break;
            case 502:
                res += 'Proxy error.';
                break;
            case 503:
                res += 'Service Temporarily Unavailable.\r\nPlease try again in a couple of minutes.';
                break;
            case 422:
            default: {
                res += 'Unknown reason';
            }
        }
        return res;
    },

    logError: function(code, jqxhr) {
    	var message = JSON.stringify(jqxhr);
    	 $.ajax({
    		 beforeSend: App.vent.beforeSend,
            method: 'POST',
            url: this.getApiUrl('/rest/error'),
            data: JSON.stringify({code: code, message: message}),
            dataType: 'json',
            contentType: 'application/json',
            processData: false
        });
    	 console.log(message);
    },

    clearTooltips: function() {
        $('body > .tooltip').remove();
    },

    clearPopover: function() {
        $('body > .popover').remove();
    },

    clearGanttPopover: function() {
        $('.gantt__chart .popover').remove();
    },

    popupWindow: function(url, w, h) {
        w = w || 550;
        h = h || 440;
        if (w === 9999) {
            window.open(url, '_blank');
        } else {
            if (w > screen.width) w = screen.width;
            if (h > screen.height) h = screen.height;
            var left = (screen.width / 2) - (w / 2);


            var top = (screen.height / 2) - (h / 2);
            window.open(url, '_blank', 'resizable=1, toolbar=0, location=0, directories=0, status=0, menubar=0, scrollbars=1, copyhistory=0, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);
        }
        return false;
    },

    trimDate: function(date) {
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        return date;
    },

    nowTrimTimestampGMT: function() {
        return this.trimDate(new Date()).getTime() - new Date().getTimezoneOffset() * 60 * 1000;
    },

    trimTimestamp: function(timestamp) {
        // trim hours, minutes from timestamp
        return this.trimDate(new Date(timestamp * 1000)).getTime() / 1000;
    },

    trimMilliseconds: function(milliseconds) {
        return this.trimDate(new Date(milliseconds)).getTime();
    },

    // return with GMT =0
    // if now Thu Nov 17 2016 12:47:00 GMT+0300 (RTZ 2 (зима)) return Thu Nov 17 2016 12:47:00 GMT+0
    nowTimestampWithOffset: function() {
        return new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000).getTime();
    },

    //почему не unix()????? ()=>  ПОТОМУ ЧТО ВОТ ПОЧЕМУ
    //A Unix Timestamp is always UTC based. It is the same timestamp everywhere on the planet simultaneously
    //because we calculate offset and correct unix time value
    getTimeBasedOnDashboardTimeZone: function(dashboardTime) {
        var dashboardTimeMoment = moment.tz(dashboardTime);
        var offSet = dashboardTimeMoment.utcOffset()*60*1000;
        return ((moment()).valueOf()) + offSet
    },

    getTimeZone: function() {
    	return moment.tz.guess(true);
    },

    getDefaultTimeZone: function() {
        return 'Europe/London';
    },

    hackDatepickerZIndex: function() {
        setTimeout(function() {
            $('.ui-datepicker').css('z-index', 1000); // z-dropdown
        }, 0);
    },

    viewMixin: function(from) {
        var to = this.prototype;
        _.defaults(to, from);
        _.defaults(to.events, from.events);
        App.Util.extendMethod(to, from, 'initialize');
        App.Util.extendMethod(to, from, 'render');
        App.Util.extendMethod(to, from, 'remove', true);
    },

    extendMethod: function(to, from, methodName, applyFirst) {
        if (!_.isUndefined(from[methodName])) {
            var old = to[methodName];
            to[methodName] = function() {
                if (applyFirst) {
                    from[methodName].apply(this, arguments);
                    return old.apply(this, arguments);
                }
                var oldReturn = old.apply(this, arguments);
                from[methodName].apply(this, arguments);
                return oldReturn;
            };
        }
    },

    getRandomInt: function(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    },

    setSelectionRange: function(input, selectionStart, selectionEnd) {
        if (input.setSelectionRange) {
            input.focus();
            input.setSelectionRange(selectionStart, selectionEnd);
        } else if (input.createTextRange) {
            var range = input.createTextRange();
            range.collapse(true);
            range.moveEnd('character', selectionEnd);
            range.moveStart('character', selectionStart);
            range.select();
        }
    },

    setCaretToPos: function(input, pos) {
        App.Util.setSelectionRange(input, pos, pos);
    },

    getRandomColor: function() {
        var hue = this.getRandomInt(0, 360);
        var saturation = this.getRandomInt(30, 80);
        var lightness = this.getRandomInt(30, 50);
        return 'hsl(' + hue + ', ' + saturation + '%, ' + lightness + '%)';
    },

    fileIsImage: function(mimetype) {
        var imageMimeTypes = [
            'image/gif',
            'image/bmp',
            'image/x-windows-bmp',
            'image/jpeg',
            'image/pjpeg',
            'image/png',
            'image/svg+xml',
            'image/tiff',
            'image/x-tiff',
            'image/vnd.djvu'
        ];
        return _.indexOf(imageMimeTypes, mimetype) > -1 ? true : false;
    },

    fileIsVideo: function(mimetype) {
        const videoMimeTypes = [
            'video/avi',
            'video/mpeg',
            'video/mp4',
            'video/ogg',
            'video/quicktime',
            'video/webm',
            'video/x-matroska',
            'video/x-ms-wmv',
            'video/x-flv',
        ];
        return _.indexOf(videoMimeTypes, mimetype) > -1 ? true : false;
    },

    fileHasAvailableMimeType: function(mimetype) {
        var availableMimeTypes = [
            'application/atom+xml',
            'application/json',
            'application/javascript',
            'application/ogg',
            'application/pdf',
            'application/postscript',
            'application/font-woff',
            'application/xhtml+xml',
            'application/xml',
            'application/xml-dtd',
            'application/zip',
            'application/gzip',
            'application/msword',

            'image/gif',
            'image/jpeg',
            'image/pjpeg',
            'image/png',
            'image/svg+xml',
            'image/tiff',
            'image/vnd.djvu',

            'text/css',
            'text/csv',
            'text/html',
            'text/plain',
            'text/rtf',

            'audio/basic',
            'audio/L24',
            'audio/mp4',
            'audio/mpeg',
            'audio/ogg',
            'audio/flac',
            'audio/opus',
            'audio/vorbis',
            'audio/vnd.rn-realaudio',
            'audio/vnd.wave',
            'audio/webm',

            'video/avi',
            'video/mpeg',
            'video/mp4',
            'video/ogg',
            'video/quicktime',
            'video/webm',
            'video/x-matroska',
            'video/x-ms-wmv',
            'video/x-flv',

            'application/vnd.debian.binary-package',
            'application/vnd.oasis.opendocument.text',
            'application/vnd.oasis.opendocument.spreadsheet',
            'application/vnd.oasis.opendocument.presentation',
            'application/vnd.oasis.opendocument.graphics',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.mozilla.xul+xml',
            'application/vnd.google-earth.kml+xml',
            'application/vnd.google-earth.kmz',
            'application/vnd.android.package-archive',
            'application/vnd.ms-xpsdocument',
            'application/x-msdos-program'
        ];
        return _.indexOf(availableMimeTypes, mimetype) > -1 ? true : false;
    },

    createDownLoadLink: function(fileId) {
        return 'https://drive.google.com/u/' + window.google.authuser + '/uc?id=' + fileId +  '&export=download';
    },

    strHexEncode: function(str) {
        var hex;
        var i;

        var result = '';
        for (i = 0; i < str.length; i++) {
            hex = str.charCodeAt(i).toString(16);
            result += ('000' + hex).slice(-4);
        }

        return result;
    },

    /**
     * @function strHexDecode
     * @description ...
     * @param hex
     * @return {string}
     */
    strHexDecode: function(hex) {
        var j;
        var hexes = hex.match(/.{1,4}/g) || [];
        var back = '';
        for (j = 0; j < hexes.length; j++) {
            back += String.fromCharCode(parseInt(hexes[j], 16));
        }

        return back;
    },

    /**
     * @function tagEncode
     * @description ...
     * @param tag
     * @return {*}
     */
    tagEncode: function(tag) {
        var hex = this.strHexEncode(tag);
        if (hex.length > 10) {
            return hex.substr(0, 5) + hex.substr(-5, 5);
        }
        return hex;
    },

    getSelection: function() {
        var selection = '';
        if (window.getSelection) {
            selection = window.getSelection();
        } else if (document.selection) {
            selection = document.selection.createRange();
        }
        return selection.toString() !== '';
    },

    getScrollBarWidth: function() {
        var inner = document.createElement('p');
        inner.style.width = '100%';
        inner.style.height = '200px';
        var outer = document.createElement('div');
        outer.style.position = 'absolute';
        outer.style.top = '0px';
        outer.style.left = '0px';
        outer.style.visibility = 'hidden';
        outer.style.width = '200px';
        outer.style.height = '150px';
        outer.style.overflow = 'hidden';
        outer.appendChild(inner);
        document.body.appendChild(outer);
        var w1 = inner.offsetWidth;
        outer.style.overflow = 'scroll';
        var w2 = inner.offsetWidth;
        if (w1 === w2) {
            w2 = outer.clientWidth;
        }
        document.body.removeChild(outer);
        return (w1 - w2);
    },

    /**
     * Преабразует hex в ргба канал
     * @param hex   #FFFFFF;
     * @param opacity 0.01 - 1
     * @param def   ???
     * @returns {string}
     */
    hexToRgbA: function(hex, opacity, def) {
        opacity = _.isUndefined(opacity) ? 1 : opacity;
        var c;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length == 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
            return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')';
        }
        if (def) {
        	return App.Util.hexToRgbA(def, opacity);
        }
        console.log('Bad Hex:' + hex);
        return false;
    },

    getCroppedPicFromSource: function(sourceLink, targetSizePx, currentSizePx) {
        var resultLink = sourceLink;
        currentSizePx = currentSizePx || 64;

        if (sourceLink.indexOf('/s' + currentSizePx + '/') >= 0) {
            resultLink = sourceLink.replace('/s' + currentSizePx + '/', '/s' + targetSizePx + '/');
        }
        return resultLink;
    },

    orderStep: Math.pow(2, 16),
    TIME: {
        SECOND: 1,
        MINUTE: 60,
        HOUR: 60 * 60,
        DAY: 60 * 60 * 24,
        WEEK: 60 * 60 * 24 * 7,
        MONTH: 60 * 60 * 24 * 30,
        YEAR: 60 * 60 * 24 * 365,
        TIME_FOR_START_DATE_H: 9,
        TIME_FOR_DUE_DATE_H: 18
    },

    getThumbUrl: function(thumbUrl, size) {
    	if (thumbUrl && size) {
            thumbUrl = thumbUrl.replace(/sz=w\d*/g, 'sz=' + size);
    	}
    	return this.fixAuthuser(thumbUrl);
    },

    fixAuthuser: function(thumbUrl) {
        if (thumbUrl && window.google.authuser) {
            return thumbUrl.replace(/authuser=0/g, 'authuser=' + window.google.authuser);
        }
        return thumbUrl;
    },

    setAuthuser0: function(thumbUrl) {
        if (thumbUrl) {
            return thumbUrl.replace(/authuser=\d+/g, 'authuser=0');
        }
        return thumbUrl;
    },

    /**
     * бывает, загрузил под authuser 0, а под 1 не скачивается,
     * тогда надо наоборот поменять authuser на 0
     */
    toggleAuthuser: function(thumbUrl) {
        if (thumbUrl && thumbUrl.match(/authuser=/g)) {
            if (thumbUrl.match(/authuser=0/g) && window.google.authuser) {
                return this.fixAuthuser(thumbUrl);
            } else {
                return this.setAuthuser0(thumbUrl);
            }
        }
        return thumbUrl;
    },

    addAuthUserToUrl: function(url) {
        return url ? url.replace(`/d/`, `/u/${window.google.authuser}/d/`) : url;
    },

    getCalendarGoogleUrl: function(){
        return "https://calendar.google.com/calendar/b/"+ (window.google.authuser? window.google.authuser : 0);
    },

    sendUpdateMessage: function() {
        $.post('/utils/demandRefresh/Huiw783G');
    },

    getCookie: function(name) {
        var matches = document.cookie.match(new RegExp(
            '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    },

    setCookie: function(name, value, days, domain) {
        var expires = '';
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = '; expires=' + date.toGMTString();
        }
        document.cookie = name + '=' + value + expires + (domain ? ';domain=' + domain : '') + '; path=/';
    },

    eraseCookie: function(name, domain) {
        this.setCookie(name, null, -1, domain);
    },

    /**
     * Deleting cookies
     * @param {(string|Array)} names - names or name cookies
     * @param {string} domain - domain cookies
     * @return {undefined}
     */
    eraseCookies: function(names, domain) {
        if (Array.isArray(names)) {
            names.forEach(name => this.setCookie(name, null, -1, domain) );
        } else {
            this.setCookie(names, null, -1, domain);
        }
    },

    getApiUrl: function(url) {
      return window.Settings.restApiPrefix + url;
    },

    /**
     * если выносим функционал для всех то написание документации и тестов обязательно
     *
     * @function truncateText
     * @description обарботка строки по длине если не вмещается то обрезается по длине и добавляется ...
     * @param {string} str string для обрезки
     * @param {number} maxLength максимальная возможная длина
     * @return {string} обработаная строка с добавлением ... если она обрезаная
     *
     * @example
     * str1 = 'Lorem ipsum dolor sit amet, consectetur adipisicingelit.'
     * @example
     * // retuns 'testOneWord'
     * Util.truncateText('testOneWord', 15)
     * @example
     * expect(Util.truncateText('testOneWord', 7)).toBe('test...');
     * expect(Util.truncateText(str1, 1)).toBe('...');
     * expect(Util.truncateText(str1, 7)).toBe('Lo...');
     *
     * @example
     * expect(Util.truncateText(str1, 999)).toBe(str1);
     *
     * @example
     * expect(Util.truncateText(str1, 39)).toBe('Lorem ipsum dolor sit amet, consecte...');
     * expect(Util.truncateText(str1, 42)).toBe('Lorem ipsum dolor sit amet, consectetur...');
     * expect(Util.truncateText(str1, 43)).toBe('Lorem ipsum dolor sit amet, consectetur...');
     */
    truncateText: function(str, maxLength) {
        const plug = '...';
        const plutLength = plug.length;

        if (str.length < maxLength) {
            return str;
        }

        const posLastSpace = str.lastIndexOf(' ', maxLength);

        if (posLastSpace > -1) {
            const cutStr = str.substring(0, posLastSpace);
            if (cutStr.length > maxLength - plutLength) {
                return cutStr.substring(0, posLastSpace - plutLength) + plug;
            }
            return cutStr.substring(0, posLastSpace) + plug;
        }

        return str.substring(0, maxLength - plutLength) + plug;
    },

    encodePromo: function(promoCode, productId) {
        const Json = JSON.stringify({code: promoCode, productId: productId});
        const queryString = btoa(Json);
        const splitString = queryString.split('=');
        const add = splitString.length - 1;
        return {splitString: splitString[0], add};
    },

    decodePromo: function(hash, add) {
        return JSON.parse(atob(hash + new Array(parseInt(add)).fill('=').join('')));
    },

    insertScript: function (src) {
        if (!document.querySelector(`[src$="${src}"]`)) {
            $('body').append($('<script>', {src}));
        }
    }
};

// https://support.microsoft.com/ru-ru/contact/chat/28/?visitId=4c52cbc0-9d22-4650-b639-d4b6bf37b657&rejoin=true

//export default App.Util;
App.Util = Util;
export default App.Util;

export const devDebugTime = (message, reset = false) => {
    const time = new Date().getTime();
    if (reset){
        window.oldTime = time;
        window.resetTime = time;
    }
    const oldTime = window.oldTime || time;
    console.log(time - oldTime, time - window.resetTime, message);
    window.oldTime = time;
}
