import { IGetState, TBoardId } from '../../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { ICardAgingIndicator } from '../../../../../../../../../types/rest/ICardAging';
import { getBoardCardAgingOpacity } from '../../../../../../../../../store/model/board/selectors/getBoardCardAgingOpacity';
import { updateSettings } from './updateSettings';

export const updateIndicator = (
    boardId: TBoardId,
    indicator: ICardAgingIndicator
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const updatedSettings = {
            opacity: getBoardCardAgingOpacity(getState(), boardId),
            indicator
        }
        dispatch(updateSettings(boardId, updatedSettings));
    };
    return action;
};
