import { IGetState, TCardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { EDefaultAttachmentType } from 'app/types/rest/IUserMeta';
import { authUserSetDefaultAttachmentType } from 'app/rest/effects/authUser/authUserSetDefaultAttachmentType';
import { showDocDriveWindow } from 'app/view/react_components/aside_panel/cardDetails/effects/showDocDriveWindow';
import { getDriveDocsByCardId } from 'app/store/model/driveDocs/selectors/getDriveDocsByCardId';
import { ERestDriveDocType } from 'app/types/rest/IRestDriveDoc';
import { addAttachments } from 'app/rest/effects/card/attacments/addAttachments';
import { setAttachmentAddedSessionStorage } from 'app/view/react_components/aside_panel/cardDetails/AttachmentsSection/helpers/setAttachmentAddedSessionStorage';

export const onAttachFileType = (
    attachmentType: EDefaultAttachmentType,
    cardId: TCardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(authUserSetDefaultAttachmentType(attachmentType));
        dispatch(showDocDriveWindow(
            cardId,
            getDriveDocsByCardId(getState(), cardId),
            ERestDriveDocType.CARD,
            (docs) => dispatch(addAttachments(cardId, docs))
                .then(() => setAttachmentAddedSessionStorage())
        ))
    };
    return action;
};
