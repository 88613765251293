import { TCardsAction } from '../actions/types';
import { AT_CARD_ACTION_SET, AT_CARDS_ADD, AT_CARDS_DELETE, AT_CARDS_UPDATE, } from '../actions/constants';
import { cardReducer } from '../../card/reducers/card';
import { ICards } from '../types';
import { updateAction } from '../../card/actions/updateAction';

const initialState: ICards = {};

export const cardsReducer = (
    state = initialState,
    action: TCardsAction
): ICards  => {
    switch (action.type) {
        case AT_CARDS_ADD: {
            return {
                ...state,
                ...action.cards
            }
        }

        case AT_CARDS_DELETE: {
            const stateNew = {...state};
            const { cards } = action;
            if (cards) {
                for (let cardId in cards) {
                    delete stateNew[cardId];
                }
            }
            return {
                ...stateNew
            };
        }

        case AT_CARDS_UPDATE: {
            const { cards } = action;
            const stateUpdated: ICards = {};
            for (let cardId in cards) {
                stateUpdated[cardId] = cardReducer(state[cardId], updateAction(cards[cardId]));
            }
            return {
                ...state,
                ...stateUpdated
            };
        }

        case AT_CARD_ACTION_SET: {
            const {cardId, cardAction} = action;
            return {
                ...state,
                [cardId]: cardReducer(state[cardId], cardAction)
            }
        }

        default: {
            return state;
        }
    }
};
