import { ThunkAction } from '../../../../../../../types/actions';
import { root } from '../../../../../../../store/constants';
import { META_WARNING_AUTOBCKUPS_FAILED } from '../constants';

export function onClick(
    warningCode: string
): ThunkAction {
    const action = () => {
        if(warningCode === META_WARNING_AUTOBCKUPS_FAILED) {
            requestAnimationFrame(() => { // был баг: открывается пустая панель
                root.App.router.navigate(root.App.router.getUrl('backups'), {trigger: true});
            });
        }
    };
    return action
};
