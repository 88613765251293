import * as React from 'react';
import { Icon } from 'kui';
import { getDateFormatted } from '../../../../../helpers/dateService';
import { IRestCardCommonActivity } from '../../../../../../../types/rest/activity/IRestCardCommonActivity';
import { ERestActivityPropertyName } from '../../../../../../../types/rest/activity/IRestActivityBase';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { IActivityProps } from '../../../../boardDetails/activity/TabActivity/types';
import { TabActivityContext } from '../../../../boardDetails/activity/TabActivity/components/TabActivity/constants';
import { TActivity } from '../../../../../../../store/activities/types';
import {
    ActivityItemBody
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemBody/ActivityItemBody';
import { ActivityHeader } from '../../../../boardDetails/activity/TabActivity/components/ActivityHeader/ActivityHeader';
import {
    ActivityItemName
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemName/ActivityItemName';
import { SMALL_ACTIVITY_MIN_ASIDE_WIDTH } from '../../../../boardDetails/activity/TabActivity/components/constants';

export function CardActivityDate({
    activity,
    asidePanelWidth
}: IActivityProps<IRestCardCommonActivity>) {
    const { onOpenCard } = React.useContext(TabActivityContext);
    let icon;
    if (activity.propertyName === ERestActivityPropertyName.START_DATE) {
        icon = 'start-date';
    } else {
        icon = 'due-date';
    }
    const { label, card } = activity as TActivity;
    const { isUserTimeFormat12 } = React.useContext(AsidePanelContext);
    const oldValue = !!activity.oldValue &&
        <ActivityItemName name={getDateFormatted(parseInt(activity.oldValue), isUserTimeFormat12)} isRemove={!!activity.newValue} />;
    const newValue = !!activity.newValue &&
        <ActivityItemName name={getDateFormatted(parseInt(activity.newValue), isUserTimeFormat12)} />;
    return (
        <ActivityItemBody
            header={<ActivityHeader
                icon={icon}
                label={label}
                content={asidePanelWidth <= SMALL_ACTIVITY_MIN_ASIDE_WIDTH && <>
                    {oldValue}
                    {newValue}
                </>}
            />}
            activity={activity}
            onClick={() => onOpenCard(card.id, activity.id, 'dates')}
        >
            {asidePanelWidth > SMALL_ACTIVITY_MIN_ASIDE_WIDTH &&
                <>
                    {oldValue}
                    {oldValue && newValue && <Icon xlink={'forward'} size={24}/>}
                    {newValue}
                </>
            }
        </ActivityItemBody>
    );
};
