import { IChecklistItemDeleteAction } from './types';
import { AT_CHECKLIST_ITEM_DELETE } from './constants';
import { TChecklistItemId } from '../../../../../types/types';

export const checklistItemDeleteAction = (
    itemId: TChecklistItemId
): IChecklistItemDeleteAction => {
    return {
        type: AT_CHECKLIST_ITEM_DELETE,
        itemId
    }
};
