import * as React from 'react';
import { SettingsSection } from '../../../../../../base/components/SettingsSection/SettingsSection';
import { AsidePanelContext } from '../../../../../asidePanel/components/AsidePanel/constants';
import { CLASS_BOARD_DETAILS_SECTION } from '../../../../constants';
import { ITimeZoneSectionProps } from './types';
import { TIMEZONE_TITLE } from '../../constants';
import { TimeZoneHOC } from '../../hocs/TimeZoneHOC/TimeZoneHOC';

export function TimeZoneSection ({
}: ITimeZoneSectionProps) {
    const { boardId } = React.useContext(AsidePanelContext);
    const className = CLASS_BOARD_DETAILS_SECTION + ' ' + CLASS_BOARD_DETAILS_SECTION + '--timezone';

    return (
        <SettingsSection
            className={className}
            title={TIMEZONE_TITLE}
        >
            <TimeZoneHOC boardId={boardId} />
        </SettingsSection>
    );
};
