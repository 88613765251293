export const fetchAsDataUrl = (url: string): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        fetch(url)
            .then(response => response.blob()
            .then(blob => {
                const reader = new FileReader();
                reader.onload = (e) => {
                    resolve(e.target.result as string);
                };
                reader.readAsDataURL(blob);
                })
                .catch((e) => {
                    reject(e);
                }))
    })
}
