import { IApplicationState, TBoardId } from '../../../types/types';
import { INotificationSettings } from '../notificationSettings/types';
import { getNotificationSettingsByBoardId } from './getNotificationSettingsByBoardId';

export const getNotificationSettingsForRollback = (
    state: IApplicationState,
    boardId: TBoardId,
    patchNotification: INotificationSettings
): INotificationSettings => {
    const notification = getNotificationSettingsByBoardId(state, boardId);
    if (!patchNotification || !notification) return;
    let notificationForRollback: INotificationSettings = {};
    for (const field in patchNotification) {
        const fieldKey = field as keyof INotificationSettings;
        notificationForRollback = {
            ...notificationForRollback,
            [fieldKey]: notification[fieldKey]
        };
    }

    return JSON.parse(JSON.stringify(notificationForRollback));
}
