import { TClickManagerCardsSelector } from '../../../clickManager/store/types';
import { TCardId } from '../../../../../types/types';
import { getSortedCardIds } from '../components/Cards/HOCS/selectors/getSortedCardsIds';
import { getList } from '../../../../../store/model/list/selectors/getList';
import { getCard } from '../../../../../store/model/selectors/getCard';

export const getCardIdsForClickManager: TClickManagerCardsSelector = (
    state,
    boardId,
    cardId
): TCardId[] => {
    //for selecting cards in right sort we have to use this memoized selector;
    const card = getCard(state, cardId);
    const list = getList(state, card.listId);
    const status = card.status;
    return getSortedCardIds(state, {list, status});
};
