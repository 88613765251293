import * as React from 'react';
import './_CardAttachmentForm.scss';
import { ICardAttachmentFormProps } from './type';
import { Button, Input } from 'kui';
import { CLASS_CARD_DETAILS } from '../../../constants';

export function CardAttachmentForm ({
    attachment,
    onSaveTitle,
    onClose
}: ICardAttachmentFormProps) {
    const className = CLASS_CARD_DETAILS + '-attachment-form';
    const classSaveButton = className + '__save-button';

    const ref = React.useRef(null);
    const [title, setTitle] = React.useState(attachment && attachment.title || '');

    const onKeyDown = (
        e: React.KeyboardEvent<HTMLInputElement>
    ) => {
        if (e) {
            if (e.which === 13) {
                onSaveClick();
            }
            if (e.which === 27) {
                onClose();
                e.stopPropagation();
            }
        }
    };

    const onTitleChange = (
        event: React.FormEvent
    ) => {
        const target = event.target as HTMLInputElement;
        const title = target.value;
        setTitle(title);
    }

    const onSaveClick = () => {
        if (
            title &&
            title.trim()
        ) {
            onSaveTitle(title);
            onClose();
        }
    };

    React.useEffect(() => {
        const lastPointIndex = title.lastIndexOf('.');
        ref.current.querySelector('input').setSelectionRange(0, lastPointIndex !== -1 ? lastPointIndex : title.length);
    }, [])

    const showSaveBtn = !!title && title.trim() && attachment.title !== title;
    return (
        <div className={className} ref={ref}>
            <Input
                autoFocus={true}
                value={title}
                onBlur={onClose}
                onChange={onTitleChange}
                onKeyDown={onKeyDown}
                autosize={false}
            />
            <Button
                onClick={onSaveClick}
                className={`
                        ${classSaveButton}
                        ${showSaveBtn ? '' : classSaveButton + '--hidden'}
                    `}
            >
                {'Save'}
            </Button>
        </div>
    );
}
