import { IApplicationState, TCardId } from '../../../../../types/types';
import { getCardChecklists } from './getCardChecklists';

export const getCardChecklistItemsNumber = (
    state: IApplicationState,
    cardId: TCardId,
): number => {
    const checklists = getCardChecklists(state, cardId);
    if (!checklists) return 0;

    let itemsNumber = 0;

    for (let checklistId in checklists) {
        const checklist = checklists[checklistId];
        if (checklist.checkItems) itemsNumber += Object.keys(checklist.checkItems).length;
    }

    return itemsNumber;
};
