import { IRouterState } from './types';
import {
    AT_ROUTER_SET,
    AT_ROUTE_SET
} from '../actions/constants';
import { TRouterAction } from '../actions/types';

const routerInitialState: IRouterState = {
    route: null,
    args: []
};

export const routerReducer = (
    state = routerInitialState,
    action: TRouterAction
): IRouterState => {
    switch(action.type) {
        case AT_ROUTER_SET:
            return {
                ...state,
                args: action.args,
                route: action.route
            };

        case AT_ROUTE_SET:
            return {
                ...state,
                route: action.route
            };

        default:
            return state;
    }
};
