import * as React from 'react';
import { Icon, Tooltip } from 'kui';
import { CARD_CLASS } from '../../../../constants';
import { IPriorityIndicatorProps } from './types';
import './PriorityIndicator.scss';
import { PRIORITY_TOOLTIP_TEXT_START } from './constants';

export function PriorityIndicator({
    priorityIcon,
    priorityLabel,
    isShort
}: IPriorityIndicatorProps) {
    const priorityClass = CARD_CLASS + '__priority';
    const priorityIconClass = CARD_CLASS + '__priority-icon';
    return (
        <Tooltip
            isNoWrap={true}
            value={(isShort ? PRIORITY_TOOLTIP_TEXT_START : '') +  priorityLabel}
        >
            <div className={priorityClass}>
                <Icon className={priorityIconClass} size={16} xlink={priorityIcon} />
            </div>
        </Tooltip>
    );
}
