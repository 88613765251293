import { IGetState, TCardId } from '../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { fetchHandler } from '../../../../../util/fetchHandler';
import Util from '../../../../../util/util';
import { REST_CARD, REST_CHECKLISTS } from '../../../../constants';
import { getRestHeadersPost } from '../../../../helpers/getRestHeadersHelper';
import { IRestChecklist } from '../../../../../types/rest/IRestChecklist';

export const restPost = (
    cardId: TCardId,
    checklist: IRestChecklist
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return fetchHandler<IRestChecklist>(
            Util.getApiUrl(REST_CARD + cardId + REST_CHECKLISTS), {
                ...getRestHeadersPost(),
                body: JSON.stringify(checklist)
            }
        )
    };
    return action;
};
