import { IApplicationState } from '../../../../../../../types/types';
import { getBoardBackupListByExportType } from '../../../../../../../store/model/boardBackups/selectors/getBoardBackupListByExportType';
import { connect } from 'react-redux';
import { BoardSheetBackups } from '../../components/BoardSheetBackups/BoardSheetBackups';
import { IBoardSheetBackupsHOCProps } from './types';
import { EBackupExportType } from '../../../../../../../types/rest/IRestBackup';
import { IBoardSheetBackupsFields } from '../../components/BoardSheetBackups/types';

const mapStateToProps = (
    state: IApplicationState,
    ownProps: IBoardSheetBackupsHOCProps
): IBoardSheetBackupsFields => {
    const { boardId } = ownProps;
    const backups = getBoardBackupListByExportType(state, boardId, EBackupExportType.GOOGLE_SHEET);
    return {
        backups
    }
};

export const BoardSheetBackupsHOC = connect(
    mapStateToProps,
    null
)(BoardSheetBackups);

BoardSheetBackupsHOC.displayName = 'BoardSheetBackupsHOC';
