import {
    ECardColumn,
    ECardField,
    ICardField,
    TCardColumns,
    TCardField,
    TCardFields
} from './store/listViewBoard/types';
import { getMessages } from '../../../store/constants';
import { sortCardsByStringValue } from './helpers/sortCardsByStringValue';
import { sortCardsByNumericValue } from './helpers/sortCardsByNumericValue';
import { IApplicationState, TBoardId } from '../../../types/types';
import { ECustomPropertyType } from '../../../store/customProperties/types';
import { sortCardsByDate } from './helpers/sortCardsByDate';
import { getBoardCardCustomProperties } from '../../../store/model/board/selectors/getBoardCardCustomProperties';

export const CLASS_LIST_VIEW = 'list-view';
export const CLASS_LIST_VIEW_DRAGGABLE = CLASS_LIST_VIEW + '__draggable';
export const CLASS_LIST_VIEW_DRAGGABLE_DRAGGING = CLASS_LIST_VIEW_DRAGGABLE + '--dragging';

export const LIST_VIEW_ASC_DEFAULT = true;
export const LIST_VIEW_CARD_HEIGHT = 46; // px
export const LIST_VIEW_CARD_MINWIDTH = 160; // px
export const LIST_VIEW_LEVEL_WIDTH = 22; // px
export const LIST_VIEW_SCROLL_THRESHOLD = 200; // px
export const LIST_VIEW_MOBILE_WIDTH = 1024; // px

export const LIST_VIEW_LOCALSTORAGE_ITEM_NAME = 'listViewSettings';

const CARD_FIELDS: TCardFields = {
    [ECardField.TITLE]: {
        isHideable: false,
        isMobile: true,
        isShownByDefault: true,
        title: getMessages().getText('list_view.card_field.title'),
        sortFunction: sortCardsByStringValue,
        sortField: 'name'
    },
    [ECardField.DESCRIPTION]: {
        isHideable: true,
        isMobile: false,
        isShownByDefault: false,
        title: getMessages().getText('list_view.card_field.description'),
        sortFunction: sortCardsByStringValue,
        sortField: 'description'
    },
    [ECardField.PRIORITY]: {
        isHideable: true,
        isMobile: false,
        isShownByDefault: true,
        title: getMessages().getText('list_view.card_field.priority'),
        sortFunction: sortCardsByNumericValue,
        sortField: 'priority'
    },
    [ECardField.PROGRESS]: {
        isHideable: true,
        isMobile: false,
        isShownByDefault: true,
        title: getMessages().getText('list_view.card_field.progress'),
        sortFunction: sortCardsByNumericValue,
        sortField: 'processingPercent'
    },
    [ECardField.LIST]: {
        isHideable: true,
        isMobile: false,
        isShownByDefault: true,
        title: getMessages().getText('list_view.card_field.list'),
        sortFunction: sortCardsByStringValue,
        sortField: 'listName'
    },
    [ECardField.TAGS]: {
        isHideable: true,
        isMobile: false,
        isShownByDefault: true,
        title: getMessages().getText('list_view.card_field.tags'),
        sortFunction: sortCardsByStringValue,
        sortField: 'tags'
    },
    [ECardField.START_DATE]: {
        isHideable: true,
        isMobile: false,
        isShownByDefault: true,
        title: getMessages().getText('list_view.card_field.start_date'),
        sortFunction: sortCardsByNumericValue,
        sortField: 'startDate'
    },
    [ECardField.DUE_DATE]: {
        isHideable: true,
        isMobile: false,
        isShownByDefault: true,
        title: getMessages().getText('list_view.card_field.due_date'),
        sortFunction: sortCardsByNumericValue,
        sortField: 'dueDate'
    },
    [ECardField.ESTIMATE]: {
        isHideable: true,
        isMobile: false,
        isShownByDefault: true,
        title: getMessages().getText('list_view.card_field.estimate'),
        sortFunction: sortCardsByNumericValue,
        sortField: 'estimate'
    },
    [ECardField.SPENT_TIME]: {
        isHideable: true,
        isMobile: false,
        isShownByDefault: true,
        title: getMessages().getText('list_view.card_field.spent_time'),
        sortFunction: sortCardsByNumericValue,
        sortField: 'totalSpentTime'
    },
    [ECardField.RELATED_CARDS]: {
        isHideable: true,
        isMobile: false,
        isShownByDefault: true,
        title: getMessages().getText('list_view.card_field.related_cards'),
        sortFunction: sortCardsByNumericValue,
        sortField: 'relatedCardCount'
    },
    [ECardField.CHECKLIST]: {
        isHideable: true,
        isMobile: false,
        isShownByDefault: true,
        title: getMessages().getText('list_view.card_field.checklist'),
        sortFunction: sortCardsByNumericValue,
        sortField: 'checklistStats'
    },
    [ECardField.ATTACHMENTS]: {
        isHideable: true,
        isMobile: false,
        isShownByDefault: true,
        title: getMessages().getText('list_view.card_field.attachments'),
        sortFunction: sortCardsByNumericValue,
        sortField: 'driveDocCount'
    },
    [ECardField.COMMENTS]: {
        isHideable: true,
        isMobile: false,
        isShownByDefault: true,
        title: getMessages().getText('list_view.card_field.comments'),
        sortFunction: sortCardsByNumericValue,
        sortField: 'commentCount'
    },
    [ECardField.ASSIGNEES]: {
        isHideable: true,
        isMobile: false,
        isShownByDefault: true,
        title: getMessages().getText('list_view.card_field.assignees'),
        sortFunction: sortCardsByStringValue,
        sortField: 'assignees'
    },
};

const CARD_COLUMNS: TCardColumns = {
    [ECardColumn.NAME]: [
        ECardField.TITLE,
        ECardField.DESCRIPTION
    ],
    [ECardColumn.PRIORITY]: [
        ECardField.PRIORITY
    ],
    [ECardColumn.PROGRESS]: [
        ECardField.PROGRESS
    ],
    [ECardColumn.LIST]: [
        ECardField.LIST
    ],
    [ECardColumn.TAGS]: [
        ECardField.TAGS
    ],
    [ECardColumn.DATES]: [
        ECardField.START_DATE,
        ECardField.DUE_DATE,
    ],
    [ECardColumn.TIME]: [
        ECardField.ESTIMATE,
        ECardField.SPENT_TIME,
    ],
    [ECardColumn.INFO]: [
        ECardField.RELATED_CARDS,
        ECardField.CHECKLIST,
        ECardField.ATTACHMENTS,
        ECardField.COMMENTS,
        ECardField.ASSIGNEES,
    ],
};

const SHOWN_FIELDS_INITIAL: ECardField[] = [
    ECardField.PRIORITY,
    ECardField.PROGRESS,
    ECardField.LIST,
    ECardField.TAGS,
    ECardField.START_DATE,
    ECardField.DUE_DATE,
    ECardField.ESTIMATE,
    ECardField.SPENT_TIME,
    ECardField.RELATED_CARDS,
    ECardField.CHECKLIST,
    ECardField.ATTACHMENTS,
    ECardField.COMMENTS,
    ECardField.ASSIGNEES
];

export const getCardFieldsConf = (
    state: IApplicationState,
    boardId: TBoardId
): TCardFields => {
    const cardFields: TCardFields = {};
    for (let cardField in CARD_FIELDS) {
        cardFields[cardField] = getCardField(cardField, state, boardId);
    }
    const customProperties = getBoardCardCustomProperties(state, boardId);
    for (let customPropertyId in customProperties) {
        cardFields[customPropertyId] = getCardField(customPropertyId, state, boardId);
    }
    return cardFields;
};

export const getCardField = (
    cardField: TCardField | string,
    state?: IApplicationState,
    boardId?: TBoardId,
): ICardField => {
    let cardFieldItem: ICardField;
    if (CARD_FIELDS[cardField]) {
        cardFieldItem = {...CARD_FIELDS[cardField]};
    } else if (state && boardId) {
        const customProperties = getBoardCardCustomProperties(state, boardId);
        const customProperty = customProperties[cardField];
        if (customProperty) {
            const { id, type, name: title } = customProperties[cardField];
            let sortFunction;
            if (type === ECustomPropertyType.NUMBER || type === ECustomPropertyType.DATE) {
                sortFunction = sortCardsByNumericValue;
            } else {
                sortFunction = sortCardsByStringValue;
            }
            cardFieldItem = {
                isHideable: true,
                isMobile: false,
                isShownByDefault: false,
                title,
                sortFunction,
                sortField: id
            };
        }
    }
    return cardFieldItem;
};

export const getCardColumnsConf = (
    state: IApplicationState,
    boardId: TBoardId
): TCardColumns => {
    const cardColumns: TCardColumns = {};
    for (let cardColumn in CARD_COLUMNS) {
        cardColumns[cardColumn] = [...CARD_COLUMNS[cardColumn]];
    }
    const customProperties = getBoardCardCustomProperties(state, boardId);
    if (Object.values(customProperties).length) {
        const customPropertiesIds = [];
        for (let customPropertyId in customProperties) {
            customPropertiesIds.push(customPropertyId);
        }
        cardColumns[ECardColumn.CUSTOM] = [...customPropertiesIds];
    }
    return cardColumns;
};

export const getShownFieldsInitial =
(): ECardField[] => {
    return [...SHOWN_FIELDS_INITIAL];
};
