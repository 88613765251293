import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { root } from '../../../../../../../store/constants';
import { segmentTrackAction, SegmentUserEvent } from '../../../../../../../middlewares/segment';
import { EAffiliateDialogSource } from '../../../../../dialogs/affiliate/components/AffiliateDialog/types';

export const onClick = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        root.App.controller.showAffiliate(EAffiliateDialogSource.HELP);
        dispatch(segmentTrackAction(SegmentUserEvent.AFFILIATE_HELP_LINK_CLICKED));
    };
    return action;
};
