import * as React from 'react';
import { Button, ButtonDropdown, Divider, Icon, ITooltipInheritedProps, SelectList, SelectListItem } from 'kui';
import { ICardActionsProps } from './types';
import './CardActions.scss';
import {
    ARCHIVE_CARD_ACTION,
    ASSIGN_CARD_ACTION,
    COLOR_LABEL_CARD_ACTION,
    COPY_CARD_ACTION,
    DATES_CARD_ACTION,
    DELETE_CARD_ACTION,
    DONE_CARD_ACTION,
    EDIT_CARD_ACTION,
    GET_LINK_CARD_ACTION,
    MORE_ACTIONS_CARD_ACTION,
    PRIORITY_CARD_ACTION,
    RENAME_CARD_ACTION,
    TOOLTIP_ACTIONS_CARD,
    UNARCHIVE_CARD_ACTION
} from '../../../wording';
import { CARD_ACTIONS_CLASS, CARD_CONTROL_CLASS, CARDS_DROPDOWN_SCROLL_CLASS } from '../../..';
import { CLASS_CARD_DETAILS_ACTIONS } from '../../../../../../../const';
import { ProFeatureIndicator } from '../../../../../base/components/ProFeatureIndicator/ProFeatureIndicator';
import { v4 as uuidv4 } from 'uuid';
import { BasicFeatureIndicator } from '../../../../../base/components/BasicFeatureIndicator/BasicFeatureIndicator';

export function CardActions({
    isArchive,
    allowArchive,
    portal,
    portalId,
    canDone,
    isReadonly,
    isEpic,
    listId,
    listOrder,
    isBasicFeature,
    isProFeature,
    onCardCopy,
    onCardArchive,
    onCardDelete,
    onClick,
    onUnarchive,
    onDone,
    onEdit,
    onMoreActions,
    onRename,
    onAssign,
    onSetDate,
    onSetPriority,
    onAddColor,
    onGetLinkCard
}: ICardActionsProps) {
    const classNameButton = CARD_ACTIONS_CLASS + '-button';
    const classMore = CARD_ACTIONS_CLASS + '--more';
    const [classUnique] = React.useState(classNameButton + uuidv4());
    const [dropdownOpened, setDropdownOpened] = React.useState(null);

    function onMoreActionsHandler () {
        onMoreActions();
        setTimeout(() => { // доскролить до кнопок действий
            const actions = document.querySelector('.' + CLASS_CARD_DETAILS_ACTIONS) as HTMLElement;
            if (actions) actions.scrollIntoView();
        }, 1000);
    };

    const onActionClick = (
        func: () => void
    ) => {
        const button = document.querySelector('.' + classUnique) as HTMLElement;
        if (button) {
            button.focus();
        }
        if (func) func();
    };

    React.useEffect(() => {
        if (dropdownOpened) {
            onActionClick(null); // хз почему фокус уходит на body
        }
    }, [dropdownOpened]);

    return (
        <ButtonDropdown
            className={`
                ${CARD_ACTIONS_CLASS}
                ${CARD_CONTROL_CLASS}
            `}
            directionHorizontal={'right'}
            dropdownClassName={`${CARDS_DROPDOWN_SCROLL_CLASS} ${CARD_CONTROL_CLASS} handle-click-ignore`}
            isFitWindow
            isMoveToFit
            isScaleAnimation
            onClick={onClick}
            onClose={() => setDropdownOpened(false)}
            onOpen={() => {
                setDropdownOpened(true)
                setTimeout(()=>{
                    const button = document.querySelector('.' + classUnique) as HTMLElement;
                    if (button) button.focus();
                }, 100);
            }}
            portal={portal}
            portalId={portalId}
        >
            <Button
                className={`${classNameButton} ${classUnique}`}
                tooltip={ dropdownOpened ?
                        null : {
                        isNoEvents: true,
                        isNoWrap: true,
                        value: TOOLTIP_ACTIONS_CARD
                    } as ITooltipInheritedProps
                }
                variant={'icon'}
            >
                <Icon xlink={'more'} size={24}/>
            </Button>
            <SelectList fixActive={false}>
                {!isArchive && !isReadonly &&
                    <SelectListItem
                        icon={'rename'}
                        onClick={() => onActionClick(onRename)}
                        iconSize={24}
                    >
                        {RENAME_CARD_ACTION}
                    </SelectListItem>
                }
                {!isArchive && !isReadonly &&
                    <SelectListItem
                        icon={'pen'}
                        onClick={() => onActionClick(onEdit)}
                        iconSize={24}
                    >
                        {EDIT_CARD_ACTION}
                    </SelectListItem>
                }

                {!isArchive && !isReadonly &&
                    <Divider />
                }

                {canDone && !isArchive && !isReadonly &&
                    <SelectListItem
                        icon={'progress'}
                        onClick={onDone}
                        iconSize={24}
                    >
                        {DONE_CARD_ACTION}
                    </SelectListItem>
                }
                {!isArchive && !isReadonly &&
                    <SelectListItem
                        icon={'user'}
                        onClick={() => onActionClick(onAssign)}
                        iconSize={24}
                    >
                        {ASSIGN_CARD_ACTION}
                    </SelectListItem>
                }
                {!isArchive && !isReadonly && !isEpic &&
                    <SelectListItem
                        icon={'calendar'}
                        onClick={() => onActionClick(onSetDate)}
                        iconSize={24}
                    >
                        {DATES_CARD_ACTION}
                    </SelectListItem>
                }
                {!isArchive && !isReadonly &&
                    <SelectListItem
                        icon={'color-tag'}
                        onClick={() => onActionClick(onAddColor)}
                        iconSize={24}
                    >
                        {COLOR_LABEL_CARD_ACTION}
                    </SelectListItem>
                }
                {!isArchive && !isReadonly &&
                    <SelectListItem
                        icon={'priority-no'}
                        onClick={() => onActionClick(onSetPriority)}
                        iconSize={24}
                    >
                        {PRIORITY_CARD_ACTION}
                    </SelectListItem>
                }
                {!isArchive && !isReadonly &&
                    <SelectListItem
                        icon={'link'}
                        onClick={() => onActionClick(onGetLinkCard)}
                        iconSize={24}
                    >
                        {GET_LINK_CARD_ACTION}
                    </SelectListItem>
                }

                {!isArchive && !isReadonly &&
                    <Divider />
                }

                {!isArchive && !isReadonly &&
                    <SelectListItem
                        icon={'copy'}
                        onClick={onCardCopy}
                        iconSize={24}
                    >
                        {COPY_CARD_ACTION}
                    </SelectListItem>
                }
                {isArchive && !isReadonly &&
                    <SelectListItem
                        icon={'archive'}
                        onClick={onUnarchive}
                        iconSize={24}
                    >
                        {UNARCHIVE_CARD_ACTION}
                    </SelectListItem>
                }
                {!isArchive && !isReadonly &&
                    <SelectListItem
                        icon={'archive'}
                        onClick={() => {
                            if (isBasicFeature || isBasicFeature) {
                                setTimeout(() => setDropdownOpened(false), 0);
                            }
                            onCardArchive();
                        }}
                        iconSize={24}
                        className={`
                                ${!allowArchive && !isProFeature && !isBasicFeature ? 'disabled' : ''}
                                ${isProFeature || isBasicFeature ? CARD_ACTIONS_CLASS + '-item--indicator' : ''}
                            `}
                    >
                        <>
                            {ARCHIVE_CARD_ACTION}
                            {isProFeature && <ProFeatureIndicator />}
                            {isBasicFeature && <BasicFeatureIndicator />}
                        </>
                    </SelectListItem>
                }
                {!isReadonly &&
                    <SelectListItem
                        icon={'delete'}
                        onClick={onCardDelete}
                        iconSize={24}
                    >
                        {DELETE_CARD_ACTION}
                    </SelectListItem>
                }

                {!isArchive && !isReadonly &&
                    <Divider />
                }

                {!isArchive && !isReadonly &&
                    <SelectListItem
                        icon={'more'}
                        className={classMore}
                        onClick={() => onActionClick(onMoreActionsHandler)}
                        iconSize={24}
                    >
                        {MORE_ACTIONS_CARD_ACTION}
                    </SelectListItem>
                }
            </SelectList>
        </ButtonDropdown>
    );
}
