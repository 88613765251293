import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { ICardPriorityHOCOwnProps } from './types';
import { getCard } from '../../../../../../../store/model/selectors/getCard';
import { ICardPriorityEvents, ICardPriorityFields } from '../../components/CardPriority/types';
import { CardPriority } from '../../components/CardPriority/CardPriority';
import { onChange } from './events/onChange';
import { onDropDownOpen } from './events/onDropDownOpen';
import { getBoardPermissions } from '../../../../../../../store/model/selectors/getBoardPermissions';
import { TStatus } from 'app/types/model';
import { getActiveBoardId } from '../../../../../../../store/model/selectors/getActiveBoardId';

const mapStateToProps = (
    state: IApplicationState,
    { cardId, variant, portal, priority: priorityOwn, listId }: ICardPriorityHOCOwnProps
): ICardPriorityFields => {
    const boardId = getActiveBoardId(state);
    if (cardId) {
        const { priority, status } = getCard(state, cardId);
        const isTemplates = status === TStatus.STATUS_SERVICE_TEMPLATE;
        return {
            priority: Number(priority),
            isReadonly: getBoardPermissions(state, boardId) && !getBoardPermissions(state, boardId).authPermissions.allowEdit,
            isTemplates,
            listId: listId,
            portal,
            variant,
        };
    } else {
        return {
            listId,
            portal,
            priority: Number(priorityOwn),
            isReadonly: getBoardPermissions(state, boardId) && !getBoardPermissions(state, boardId).authPermissions.allowEdit,
            variant,
        };
    }
};

const mapDispatchToProps = (
    dispatch: any,
    { cardId, onChange: onOwnChange, source }: ICardPriorityHOCOwnProps
): ICardPriorityEvents => {
    return {
        onChange: (priority: string) => dispatch(onChange(cardId, priority, onOwnChange, source)),
        onDropDownOpen: () => dispatch(onDropDownOpen(source))
    }
};

export const CardPriorityHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardPriority);

CardPriorityHOC.displayName = 'CardPriorityHOC';
