import { getActiveCardId } from '../../../../../store/router/selectors/getActiveCardId';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../types/types';
import { closePanel } from './closePanel';

export const closeCardPanel = (
    cardId: TCardId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const activeCardId = getActiveCardId(getState());
        if (activeCardId === cardId) dispatch(closePanel());
    };
    return action;
};
