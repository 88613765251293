import { IApplicationState, TCardId } from '../../../types/types';
import { getCardActiveRequests } from './getCardActiveRequests';

export const getCardActiveRequestsWithDelta = (
    state: IApplicationState,
    cardId: TCardId,
    delta: number,
): number => {
    let cardActiveRequests = getCardActiveRequests(state, cardId);
    cardActiveRequests += delta;
    if (cardActiveRequests < 0) {
        console.error(`activeRequests < 0 for card:${cardId}`);
        cardActiveRequests = 0;
    }
    return cardActiveRequests;
};
