import * as React from 'react';
import './_CardDatesSection.scss';
import { ICardDatesProps } from './types';
import { CLASS_CARD_DETAILS_SECTION } from '../../../constants';
import { SettingsSection } from '../../../../../base/components/SettingsSection/SettingsSection';
import { CARD_SECTION_DATES, CARD_SECTION_DATES_MILESTONE } from '../../constants';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import {
    AddNotificationHOC
} from '../../../TimingSection/notificationsBeforeDueDate/hocs/AddNotification/AddNotificationHOC';
import {
    NotificationEditingPopUpHOC
} from '../../../TimingSection/notificationsBeforeDueDate/hocs/NotificationEditingPopUp/NotificationEditingPopUpHOC';
import {
    NotificationsListHOC
} from '../../../TimingSection/notificationsBeforeDueDate/hocs/NotificationsList/NotificationsListHOC';
import { PushToGoogleCalendarHOC } from '../../hocs/PushToGoogleCalendarHOC/PushToGoogleCalendarHOC';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { EAsidePanelProperty } from '../../../../asidePanel/components/AsidePanel/types';
import {
    CARD_DATE_BOARD_LINK,
    CARD_DATE_GANTT_CONTEXT_HINT_ADD_DATE,
    CARD_DATE_HINT,
    CARD_DATE_HINT_BUTTON_NO,
    CARD_DATE_HINT_BUTTON_YES,
    CARD_DATE_LEARN_LINK,
    CARD_DATE_TIME_HINT,
    CARD_DUE_DATE,
    CARD_START_DATE,
    CARD_START_DATE_MILESTONE
} from './constants';
import {
    CLASS_ASIDE_PANEL_ACTIONS_BUTTON_CONTAINER,
    ID_ASIDE_PANEL_ACTIONS_PORTAL,
    ID_ASIDE_PANEL_ACTIONS_PORTAL_MOBILE,
    ID_ASIDE_PANEL_DATEPICKER_PORTAL
} from '../../../../asidePanel/components/AsidePanelActionsButton/constants';
import { EHintKey, HintHOC } from '../../../../../hints';
import { Button } from 'kui';
import { renderToStaticMarkup } from 'react-dom/server';
import { GOOGLE_SPACING } from '../../../../../../../const';
import { DatepickerHOC } from '../../../../../base/components/Datepicker/hocs/DatepickerHOC/DatepickerHOC';
import { CLASS_GANTT_CONTEXT_HINT } from '../../../../../hints/components/Hint/constants';

export function CardDatesSection({
    boardId,
    cardId,
    dateDue,
    dateStart,
    defaultDueTime,
    defaultStartTime,
    sectionType,
    isDefaultProperty,
    isMilestone,
    isOverdue,
    isDisabled,
    isDueRequired,
    isRecurring,
    isShowHint,
    isStartRequired,
    onAutoStartDateHide,
    onAutoStartDateToggle,
    onChange,
    isSupportCalendarPush,
    onClose,
    onDateClear,
    onDateOpened,
    onDateSelected,
    onOpenSettings,
    onTimeOpened,
    onTimeSelected,
    onUnevenTimeSelected
}: ICardDatesProps) {
    if (!dateDue && !dateStart && sectionType === ESettingsSectionType.READONLY) return;

    const { openedProperty, addShowedProperty, isUserTimeFormat12, isMobile } = React.useContext(AsidePanelContext);
    const isPopup = sectionType === ESettingsSectionType.POPUP;
    const isReadonly = sectionType === ESettingsSectionType.READONLY;
    const [isShow, setShow] = React.useState(false);

    const currentDateTimeStamp = new Date().getTime();
    const isShowButtonNotify = !isReadonly && (currentDateTimeStamp < (dateDue && dateDue.getTime()));

    const className = CLASS_CARD_DETAILS_SECTION + '__dates';
    const classHint = className + '-hint';

    React.useEffect(() => {
        if (dateStart || dateDue || isDefaultProperty) {
            setShow(true);
            addShowedProperty(EAsidePanelProperty.CARD_DATES)
        }
    }, [dateDue, dateStart, isDefaultProperty]);

    const isOpenedProperty = openedProperty === EAsidePanelProperty.CARD_START_DATE || openedProperty === EAsidePanelProperty.CARD_DUE_DATE;
    if (
        (!isShow && !isPopup) ||
        (isShow && !isPopup && isOpenedProperty)
    ) {
        return;
    }

    const onShowAutoStartHint = () => {
        console.log(123123123)
        const link = document.querySelector('.' + classHint + '-link') as HTMLElement;
        if (link) link.addEventListener('click', onOpenSettings);
    };

    return (
        <SettingsSection
            className={`
                ${className + ' ' + CLASS_CARD_DETAILS_SECTION + '--dates'}
                ${isUserTimeFormat12 ? className + '--12-hour-format' : ''}
                ${isShowHint ? className + '--highlight' : ''}
            `}
            isOpened={isPopup}
            title={isMilestone ? CARD_SECTION_DATES_MILESTONE : CARD_SECTION_DATES}
            titleButton={!isSupportCalendarPush || !dateDue && !dateStart ? null : <PushToGoogleCalendarHOC cardId={cardId} key={cardId}/>}
            sectionType={sectionType}
            onAddClosed={onClose}
        >
            <div className={className + '-datepicker'}>
                <DatepickerHOC
                    selectedToDate={dateDue}
                    selectedFromDate={dateStart}
                    isTime
                    isFromRequired={isStartRequired}
                    isToRequired={isDueRequired}
                    onChange={(date, dateType) => {
                        if (isPopup) {
                            setTimeout(() => {
                                if (document.activeElement !== document.body) return;
                                const button = document.querySelector(`
                                        .${CLASS_ASIDE_PANEL_ACTIONS_BUTTON_CONTAINER}--dates .kui-button
                                    `) as HTMLElement;
                                if (button) button.focus();
                            }, 600);
                        }
                        onChange(date, dateType);
                    }}
                    isReadonly={isReadonly}
                    isToDisabled={isDisabled}
                    isFromDisabled={isDisabled}
                    isSingle={isMilestone}
                    defaultFromTime={defaultStartTime}
                    defaultToTime={defaultDueTime}
                    translate={GOOGLE_SPACING * (isPopup ? -0.5 : 0.25)}
                    fromDateHint={{
                        value: CARD_DATE_HINT
                            + renderToStaticMarkup(<a className={classHint + '-link'}>{CARD_DATE_BOARD_LINK}</a>)
                            + CARD_DATE_LEARN_LINK,
                        hintKey: EHintKey.AUTO_START_DATE,
                        onShow: onShowAutoStartHint,
                        footer:
                            <div className={classHint + '-buttons'}>
                                <Button
                                    color={'white'}
                                    onClick={onAutoStartDateToggle}
                                >
                                    {CARD_DATE_HINT_BUTTON_YES}
                                </Button>
                                <Button
                                    className={classHint + '-button'}
                                    onClick={onAutoStartDateHide}
                                    variant={'text'}
                                >
                                    {CARD_DATE_HINT_BUTTON_NO}
                                </Button>
                            </div>
                    }}
                    timeHint={{
                        value: CARD_DATE_TIME_HINT,
                        hintKey: EHintKey.DEFAULT_CARD_TIME
                    }}
                    fromPlaceholder={isMilestone ? CARD_START_DATE_MILESTONE : CARD_START_DATE}
                    toPlaceholder={CARD_DUE_DATE}
                    onTimeOpened={onTimeOpened}
                    onCalendarOpened={onDateOpened}
                    onClear={onDateClear}
                    onUnevenTimeSelected={onUnevenTimeSelected}
                    onDateSelected={onDateSelected}
                    onTimeSelected={onTimeSelected}
                    isOverdue={isOverdue}
                    portalId={isRecurring ? null : ID_ASIDE_PANEL_DATEPICKER_PORTAL}
                />
                {!isMilestone && isShowButtonNotify &&
                    <AddNotificationHOC
                        cardId={ cardId }
                        isShowTitle={!dateDue && !dateStart || isReadonly}
                    />
                }
            </div>
            {!isMilestone &&
                <>
                    <NotificationEditingPopUpHOC
                        boardId={ boardId }
                        cardId={ cardId }
                    />
                    <NotificationsListHOC
                        cardId={ cardId }
                        isReadonly={isReadonly}
                    />
                </>
            }
            {isShowHint &&
                <HintHOC
                    className={CLASS_GANTT_CONTEXT_HINT}
                    key={EHintKey.GANTT_CONTEXT_ADD_DATE}
                    isHidable={false}
                    hintKey={EHintKey.GANTT_CONTEXT_ADD_DATE}
                    isHideOnHintClick
                    show
                    direction={'up-right'}
                    value={CARD_DATE_GANTT_CONTEXT_HINT_ADD_DATE}
                    translate={{ bottom: GOOGLE_SPACING * -7.75, left: GOOGLE_SPACING * 8.75 }}
                    selector={`.${className}`}
                    isPortal
                    portalId={isMobile ? ID_ASIDE_PANEL_ACTIONS_PORTAL_MOBILE : ID_ASIDE_PANEL_ACTIONS_PORTAL}
                />
            }
        </SettingsSection>
    );
};
