/*
Use for lightweight message alerts or simple Yes/No dialogues
*/
import AffiliateView from './affiliate';
import CopyLinkView from './copyLink';
import store from '../../store/configureStore';

import { InviteView } from './invite';
import { ImportView } from "./import";

App.Views.MessageDialogRenderer = Backbone.View.extend({
    el: '.modal--sticky',
    
    initialize: function(options) {
        App.vent.on(App.vent['dialog:closed'], this.hide, this);
        this.currentView = null;
    },
    
    render: function() {
        return this;
    },
    
    show: function(view) {
        this.clear();
        this.$el.addClass('modal--show');
        this.currentView = view;
        this.showCurrentView();
    },
    
    showCurrentView: function() {
        this.$el.append(this.currentView.render().el);
    },
    
    showDeleteRecord: function(record, onDeleteCallback) {
        this.show(new App.Views.ConfirmDeleteRecord({
            model: record,
            onDeleteCallback: onDeleteCallback 
        }));
    },
    
    showAlert: function(options) {
        this.show(new App.Views.Alert(options));
    },

    showAffiliate: function(source) {
      this.show(new AffiliateView({
          store: store,
          source: source
      }))
    },

    showCopyLinkDialog: function(source) {
        this.show(new CopyLinkView({
            store: store,
        }))
    },

    showImport: function() {
        this.$el.addClass('modal--import');
        this.show(new ImportView({
            store: store
        }));
    },

    showInvite: function() {
        this.show(new InviteView({
            store: store
        }))
    },
    
    hide: function() {
        if (this.currentView && this.currentView.onBeforeCloseDialog) {
            this.currentView.onBeforeCloseDialog();
        }
        this.clear();
        this.$el.removeClass('modal--show');
        this.$el.removeClass('modal--import');
    },
    
    clear: function() {
        if (this.currentView) {
            this.currentView.remove();
            this.currentView = null;
        }
    },
    
    remove: function() {
        App.vent.off(null, null, this);
        return Backbone.View.prototype.remove.call(this);
    }
    
});
