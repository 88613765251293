import { MONTH_LIST_MIDDLE } from '../constants';

export const getInitialDate = (
    date: Date,
    index: number
): Date => {
    const idx = Math.floor(index - MONTH_LIST_MIDDLE);
    const initialDate = new Date(date) || new Date();
    const initialMonth = initialDate.getMonth();
    initialDate.setDate(1);
    initialDate.setMonth(initialMonth + idx);
    return initialDate;
}
