import { IApplicationState, TListId } from '../../../types/types';
import { ICardAging } from '../../../types/rest/ICardAging';
import { getList } from '../list/selectors/getList';
import { getBoardIdByListId } from './getBoardIdByListId';
import { getBoardMetaCardAging } from './getBoardMetaCardAging';

export const getListMetaCardAging = (
    state: IApplicationState,
    listId: TListId
): ICardAging => {
    const list = getList(state, listId);
    const boardId = getBoardIdByListId(state, listId);

    return list && list.meta && list.meta.cardAging || getBoardMetaCardAging(state, boardId);
}
