import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../../types/types';
import { PushNotificationsHelpButton } from '../../components/PushNotificationsHelpButton/PushNotificationsHelpButton';
import { IPushNotificationsHelpButtonEvents, IPushNotificationsHelpButtonFields } from '../../components/PushNotificationsHelpButton/types';
import { onClick } from './events/onClick';

const mapStateToProps = (
    state: IApplicationState,
): IPushNotificationsHelpButtonFields => {
    return {}
};

const mapDispatchToProps = (
    dispatch: any,
): IPushNotificationsHelpButtonEvents => {
    return {
        onClick: () => dispatch(onClick())
    }
};

export const PushNotificationsHelpButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(PushNotificationsHelpButton);

PushNotificationsHelpButtonHOC.displayName = 'PushNotificationsHelpButtonHOC';
