import { IApplicationState } from '../../../../types/types';
import { getAuthUser } from './getAuthUser';

export const getUserIsOpenLastBoard = (
    state: IApplicationState
): boolean => {
    const user = getAuthUser(state);
    const off = !user.meta || user.meta.isOpenLastBoard === false; // по-умолчанию включено
    return !off;
};
