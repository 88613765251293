import * as React from 'react';
import { ICardEstimateProps } from './types';
import { CardDateTimeIndicator } from '../CardDateTimeIndicator/CardDateTimeIndicator';
import { ESTIMATE_TOOLTIP_PREFIX } from '../../wording';

export function CardEstimate({
    estimateStr
}: ICardEstimateProps) {
    const tooltip = ESTIMATE_TOOLTIP_PREFIX + estimateStr;
    return (
        <CardDateTimeIndicator tooltip={tooltip} text={estimateStr}/>
    );
}
