import * as deepFreeze from 'deep-freeze';
import { INotificationsSettings } from '../types';
import { AT_NOTIFICATION_SETTINGS_ACTION_SET, TNotificationsSettingsAction } from '../actions/types';
import { notificationSettingsReducer } from '../../notificationSettings/reducers/notificationSettingsReducer';

const initialState: INotificationsSettings = {};

const _notificationsSettingsReducer = (
    state = initialState,
    action: TNotificationsSettingsAction
): INotificationsSettings  => {
    switch (action.type) {
        case AT_NOTIFICATION_SETTINGS_ACTION_SET:{
            const {boardId, notificationAction} = action;
            return {
                ...state,
                [boardId]: notificationSettingsReducer(state[boardId], notificationAction)
            }
        }
        default: {
            return state;
        }
    }
};

export const notificationsSettingsReducer = (
    state = initialState,
    action: TNotificationsSettingsAction
): INotificationsSettings  => {
    // @ts-ignore
    if(window.Settings.development) {
        // @ts-ignore
        return deepFreeze(_notificationsSettingsReducer(state, action));
    } else {
        return _notificationsSettingsReducer(state, action);
    }
};
