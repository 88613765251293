import { SegmentCardEvent } from './../../../../../../../../middlewares/segment/trackEntities/cardEvents';
import { segmentTrackAction } from './../../../../../../../../middlewares/segment/actions/segmentTrackAction/segmentTrackAction';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';

export const onOpened = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.CARD_IN_LIST_DROPDOWN_OPENED));
    };
    return action;
};
