export interface ILogo {
    color: string;
    icon?: string;
}

export enum ELogoSize {
    SMALL = 16,
    LARGE = 24
}

export interface ILogoFields {
    className?: string;
    isChangeColorOnReset?: boolean;
    isSelect?: boolean;
    name: string;
    logo: ILogo;
    size?: ELogoSize;
}

export interface ILogoEvents {
    onBlur?: (e: React.FocusEvent) => void;
    onChange?: (icon: ILogo) => void;
}

export interface ILogoProps extends
    ILogoFields,
    ILogoEvents
{}
