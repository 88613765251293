import { TBoardId } from '../../../../../../types/types';
import { ThunkAction } from '../../../../../../types/actions';
import { SegmentNavPanelEvent, segmentTrackAction } from '../../../../../../middlewares/segment';
import { boardOpen } from 'app/view/react_components/base/effects/boardOpen';

export const onBoardOpen = (
    boardId: TBoardId
): ThunkAction => {
    return (
        dispatch
    ) => {
        dispatch(segmentTrackAction(SegmentNavPanelEvent.OPEN_RECENT_BOARD_CLICKED));
        dispatch(boardOpen(boardId));
    }
}
