import { IGetState } from '../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { segmentTrackAction, SegmentCardOptions, SegmentCardRelatedTargetValue, SegmentCardEvent } from '../../../../../middlewares/segment';
import { IRelatedCard } from '../components/RelatedCard/types';

export const relatedCardAddedEvent = (
    relatedCard: IRelatedCard,
    target: SegmentCardRelatedTargetValue,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        const targetObject = {
            name: SegmentCardOptions.TARGET,
            value: target
        }
        const editedBoardId = getState().relatedCards.boardId
        if (editedBoardId === relatedCard.dashboardId) {
            dispatch(segmentTrackAction(SegmentCardEvent.RELATED_CARD_ADD, targetObject));
        } else {
            dispatch(segmentTrackAction(SegmentCardEvent.RELATED_CARD_ADD_ANOTHER_BOARD, targetObject));
        }
    };
    return action;
};

export const relatedCardAddedTargetBoardEvent = (
    relatedCard: IRelatedCard
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(relatedCardAddedEvent(relatedCard, SegmentCardRelatedTargetValue.BOARD));
    };
    return action;
};

export const relatedCardAddedTargetSelectEvent = (
    relatedCard: IRelatedCard
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(relatedCardAddedEvent(relatedCard, SegmentCardRelatedTargetValue.SELECT));
    };
    return action;
};

export const relatedBoardSelectedEvent = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.RELATED_BOARD_SELECTED));
    };
    return action;
};
