import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { ICards } from '../../../../../store/model/cards/types';
import { cardsUpdate } from '../../../../../store/model/actionCreators/cardsUpdate';
import { sendRealTimeStoreActionByCardId } from '../../../base/helpers/realTimeHelperTS';
import { IGetState } from '../../../../../types/types';

export const updateCardAgingIndicator = (
    response: any
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        if (!response || !response.headers || !response.headers.get('card-last-activity')) return;

        const cardAging = JSON.parse(response.headers.get('card-last-activity'));
        let cards: ICards = {};
        let cardId;
        for (let id in cardAging) {
            cardId = Number(id);
            cards = {
                ...cards,
                [cardId]: {
                    lastActivityDateStamp: cardAging[id]
                }
            }
        }

        dispatch(cardsUpdate(cards));
        dispatch(sendRealTimeStoreActionByCardId(cardId, cardsUpdate(cards)));
    };
    return action;
};
