import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TBoardId } from '../../../../../../../../types/types';
import { SegmentBoardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';

export const onDidMount = (
    boardId: TBoardId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentBoardEvent.BOARD_ATTACHMENTS_TAB_CLICKED));
    };
    return action;
};
