import { AT_TAB_LOAD_STATUS_SET } from './constants';
import { TTabKey } from '../../types';
import { ITabLoadStatusSetAction} from './types';
import { TTabLoadStatus } from '../types';

export const tabLoadStatusSet = (
    tabKey: TTabKey,
    loadStatus: TTabLoadStatus
): ITabLoadStatusSetAction => ({
    type: AT_TAB_LOAD_STATUS_SET,
    tabKey,
    loadStatus
});
