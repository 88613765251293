import { SegmentCardEvent, SegmentCardOptions, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { SegmentCardSourceValue } from '../../../../../../../../middlewares/segment/trackEntities/cardEvents';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../../../../types/types';

export const onClickEdit = (
    cardId: TCardId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.CUSTOM_PROPERTIES_EDIT_CLICKED, {
            name: SegmentCardOptions.SOURCE,
            value: !!cardId ? SegmentCardSourceValue.DETAILS : SegmentCardSourceValue.BOARD_SETTINGS
        }));
    };
    return action;
};
