import * as React from 'react';
import './_MyWorkTopBarTitle.scss';
import { IMyWorkTopBarTitleProps } from './types';
import { Button, ButtonDropdown, Divider, Icon, SelectList, SelectListItem } from 'kui';
import {
    MY_WORK_SETTINGS_BACKGROUND,
    MY_WORK_SETTINGS_IMPORT,
    MY_WORK_SETTINGS_OPEN,
    MY_WORK_TITLE
} from './constants';

export function MyWorkTopBarTitle({
    newUpdates,
    onOpenBackground,
    onOpenImportCards,
    onOpenSettings
}: IMyWorkTopBarTitleProps) {
    const className = 'top-bar-my-work-title';

    return (
        <ButtonDropdown
            className={className}
            directionHorizontal={'right'}
        >
            <Button className={className + '-button'}>
                <div className={className + '-name'}>{MY_WORK_TITLE}</div>
                <Icon xlink={'more'} size={24} />
            </Button>
            <SelectList fixActive={false} className={'handle-click-ignore'}>
                <SelectListItem
                    icon={'card'}
                    onClick={onOpenImportCards}
                    iconSize={24}
                >
                    {MY_WORK_SETTINGS_IMPORT}
                    <>{newUpdates && <div className={className + '-indicator'} />}</>
                </SelectListItem>
                <Divider />
                <SelectListItem
                    icon={'settings'}
                    onClick={onOpenSettings}
                    iconSize={24}
                >
                    {MY_WORK_SETTINGS_OPEN}
                </SelectListItem>
                <SelectListItem
                    icon={'image'}
                    onClick={onOpenBackground}
                    iconSize={24}
                >
                    {MY_WORK_SETTINGS_BACKGROUND}
                </SelectListItem>
            </SelectList>
        </ButtonDropdown>
    );
}
