import { IGetState, TBoardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { getBoard } from '../../../../../../../../store/model/selectors/getBoardById';
import { EAuthUserPlatformType } from 'app/types/rest/IRestAuthUser';
import { commonAuthManagerInstance } from 'app/helper/authorisation/common/CommonAuthManager';
import { root } from 'app/store/constants';

export const onGetLink = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const board = getBoard(getState(), boardId);
        if (board.platformType === EAuthUserPlatformType.MICROSOFT){
            return Promise.resolve(board.parentFolderInfo.webUrl);
        } else {
            return dispatch(onGetLinkForGoogle(boardId));
        }
    };
    return action;
};

const onGetLinkForGoogle = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const board = getBoard(getState(), boardId);
        return commonAuthManagerInstance.getAccessTokenOrShowLoginScreen().then((accessToken) =>{
            return new Promise((resolve) => {
                const request = root.gapi.client.request({
                    path: 'drive/v2/files/' + board.fileId,
                    method: 'GET',
                    'params': {'supportsAllDrives': 'true'}
                });

                request.execute((resp: any) => {
                    const parent = resp.parents && resp.parents[0];
                    if (parent) {
                        const { google } = window as any;
                        resolve(`https://drive.google.com/drive/u/${google.authuser}/folders/${parent.id}`);
                    }
                })
            })
        })
    };
    return action;
};
