import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { authUserSetRateAppPoll } from '../../../../../../../rest/effects/authUser/authUserSetRateAppPoll';

export const onSkip = (): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        const date = Date.now();
        dispatch(authUserSetRateAppPoll(date, true));
    };
    return action;
};
