'use strict';

import {
    EUserProductivityEventCardStatus,
    EUserProductivityEventStatus
} from '../../view/react_components/reports/UserProductivity/UserProductivityReport/rest/types';
import { IBackboneCardModel, TStatus } from '../../types/model';
import { IBoard } from '../../store/model/board/types';
import { ICard } from '../../store/model/card/types';
import { IList } from '../../store/model/list/types';
import { root } from '../../store/constants';
import { IErrorReport, IStatReportModel } from './types';
import { fetchHandler } from '../../util/fetchHandler';
import { getList } from '../../store/model/list/selectors/getList';
import { getAuthUser } from '../../store/model/authUser/selectors/getAuthUser';
import { Util } from '../../util/util';
import { REST_BOARD } from '../../rest/constants';
import { getBoard } from '../../store/model/selectors/getBoardById';
import { UPDATE_CARD_COUNT_URL } from './constants';
import { Dispatch, ThunkAction } from '../../types/actions';
import { IGetState, TCardId, TListId } from '../../types/types';
import { getRestHeadersPost } from '../../rest/helpers/getRestHeadersHelper';
import { getStatServerUrl } from '../../util/getStatServerUrl';
import { getCard } from '../../store/model/selectors/getCard';
import * as _ from 'underscore';

const getCurrentDate = (): number => {
    return new Date().getTime();
};

export function sendUserProductivityStatsTS(board: IBoard, card: ICard, list: IList, eventName: EUserProductivityEventStatus) {
    try {
        if (board.permissions.authPermissions.allowSendReportStatistics &&
            card.status !== TStatus.STATUS_SERVICE_TEMPLATE) {
            const currentTime = getCurrentDate();
            const data = {
                boardId: board.hash,
                date: currentTime,
                eventName: eventName,
                cardInfo: {
                    id: card.id,
                    startDate: card.startDate,
                    dueDate: card.dueDate,
                    doneDate: card.doneDate,
                    assignees: card.assignees.map(assignee => {
                        if(root.Settings.development) {
                            if(_.isEmpty(assignee.sharedUser)) {
                                console.error(assignee);
                                alert('JOPA: try to save empty assignee')
                            }
                        }
                        return assignee.sharedUser
                    }),
                    processingPercent: card.processingPercent,
                    statuses: getCardStatuses(card, eventName),
                    name: card.name
                },
                listInfo: {
                    id: list.id,
                    name: list.name
                }
            };
            const statModel = new App.Models.UserProductivityStat(data, {syncRedux: false});
            statModel.save(data, {syncRedux: false});
        }
    } catch (e) {
        console.error(e);
    }
};

export const sendUserProductivityStatsByCardId = (
    cardId: TCardId,
    eventName: EUserProductivityEventStatus
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const card = getCard(state, cardId);
        const list = card && getList(state, card.listId);
        const board = list && getBoard(state, list.dashboardId);
        if (board && board.id) {
            sendUserProductivityStatsTS(board, card, list, eventName);
        }
    };
    return action
};

/***
 * функция выдает массив состояний карточки на момент вызова данной функции
 * @param card
 * @param eventName
 */
function getCardStatuses(card: ICard, eventName: EUserProductivityEventStatus): EUserProductivityEventCardStatus[] {
    const statuses: EUserProductivityEventCardStatus[] = [];
    const dueDate = card.dueDate;
    const doneDate = card.doneDate;
    const startDate = card.startDate;
    const now = Math.floor(new Date().getTime() / 1000);

    if (eventName === EUserProductivityEventStatus.CARD_MOVED_OUT || eventName === EUserProductivityEventStatus.CARD_DELETED) {
        return statuses; //карта больше не должна учитываться, так как её нет на доске
    }
    // DONE_DATE != null
    if (doneDate) {
        statuses.push(EUserProductivityEventCardStatus.DONE);
    }
    // DONE_DATE <= DUE_DATE || DUE_DATE == null
    if (doneDate && (!dueDate || doneDate <= dueDate)) {
        statuses.push(EUserProductivityEventCardStatus.DONE_ON_TIME);
    }
    // DUE_DATE < DONE_DATE
    if (doneDate && (dueDate && dueDate < doneDate)) {
        statuses.push(EUserProductivityEventCardStatus.DONE_PAST_DUE);
    }
    // DONE_DATE == null
    if (!doneDate) {
        statuses.push(EUserProductivityEventCardStatus.UNDONE);
    }
    // DUE_DATE <= NOW  && DONE_DATE == null
    if (dueDate && dueDate <= now && !doneDate) {
        statuses.push(EUserProductivityEventCardStatus.OVERDUE);
    }
    // START_DATE <= NOW
    if (startDate && startDate <= now) {
        statuses.push(EUserProductivityEventCardStatus.START);
    }
    // DUE_DATE < NOW
    if (dueDate && dueDate < now) {
        statuses.push(EUserProductivityEventCardStatus.DUE);
    }
    /*
        DONE_ON_TIME = 'DONE_ON_TIME', // DONE_DATE <= DUE_DATE || DUE_DATE == null
        DONE_PAST_DUE = 'DONE_PAST_DUE', // DUE_DATE < DONE_DATE
        DONE = 'DONE', // DONE_DATE != null
        UNDONE = 'UNDONE', // DONE_DATE == null
        START = 'START', // START_DATE >= NOW
        OVERDUE = 'OVERDUE', // DUE_DATE <= NOW  && DONE_DATE == null
        DUE = 'DUE', // DUE_DATE < NOW
    */
    return statuses;
}

export const sendCardStatWithIncrement = (
    list: IList,
    cardCountDelta: number,
    cardDoneCountDelta: number
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (!list) return;
        const state = getState();
        const board = getBoard(state, list.dashboardId);
        const allowReports = board.permissions
            && board.permissions.authPermissions
            && board.permissions.authPermissions.allowSendReportStatistics;
        if (allowReports) {
            return dispatch(sendCardStatWithIncrementForBoard(board, list.id, cardCountDelta, cardDoneCountDelta));
        }
    };
    return action
};

export const sendCardStatWithIncrementForBoard = (
    board: IBoard,
    listId: TListId,
    cardCountDelta: number,
    cardDoneCountDelta: number): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        try {
            const data = prepareStatReportModel(listId, board, cardCountDelta, cardDoneCountDelta);
            return dispatch(sendCardStatisticModel(data));
        } catch (e) {
            console.error('send stat error', e);
        }
    };
    return action
};

const prepareStatReportModel = (
    listId: number,
    board: IBoard,
    cardCountDelta: number,
    cardDoneCountDelta: number
): IStatReportModel => {
    const timeStamp = new Date().getTime() - root.App.model.get('timeLag') || new Date().getTime();
    return {
        'date': root.App.Util.getTimeBasedOnDashboardTimeZone(board.timeZone),
        'dashboardId': board.hash,
        'listId': listId,
        'cardCount': cardCountDelta >= 0 ? '+' + cardCountDelta : ''+cardCountDelta,
        'cardDoneCount': cardDoneCountDelta >= 0 ? '+' + cardDoneCountDelta : ''+cardDoneCountDelta,
        'timeStamp': timeStamp,
    };
}

export const sendCardStatisticModel = (data: IStatReportModel): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        return fetchHandler(getStatServerUrl(UPDATE_CARD_COUNT_URL), {
            method: 'POST',
            body: JSON.stringify(data)
        })
            .catch(error => {
                const list = getList(state, data.listId);
                const user = getAuthUser(state);
                const dashboardId = getBoard(state, list.dashboardId).id;
                const options = {
                    userId: user.id,
                    dashboardId: dashboardId,
                };
                const errorReport: IErrorReport = {
                    userInfo: user.fullName,
                    errCode: error.status,
                    message: error.statusText,
                    responseText: error.responseJSON,
                    lastErrDate: root.App.Util.nowTimestampWithOffset()
                };
                fetchHandler('/rest/dashboard/' + dashboardId + '/user/' + user.id + '/report/info',
                    {
                        ...getRestHeadersPost(),
                        body: JSON.stringify(errorReport)
                    })
            })
    };
    return action
};
