import * as React from 'react';
import './_Datepicker.scss';
import { EDatepickerDirection, IDatepickerContext, IDatepickerProps } from './types';
import { CLASS_DATEPICKER } from '../constants';
import { DatepickerItem } from '../DatepickerItem/DatepickerItem';
import { EDateType } from '../../../../../../../const';
import { DatepickerContext } from './constants';
import { CARD_CONTROL_CLASS } from '../../../../../main/kanbanView';
import { DateDropdown } from '../DateDropdown/DateDropdown';
import { IDateDropdownProps } from '../DateDropdown/types';

export const Datepicker = ({
    className,
    dateFormat,
    defaultFromTime,
    defaultToTime,
    direction,
    dropdownDirectionX,
    dropdownDirectionY,
    fromDateHint,
    fromLabel,
    fromPlaceholder,
    isAllowSameDate,
    isClearable = true,
    isCompact,
    isFromDisabled,
    isFromRequired,
    isHideFromDate,
    isHideToDate,
    isFromOpened,
    isNoDropdown,
    isOverdue,
    isReadonly,
    isSingle,
    isCalendarStartsSunday,
    isTime,
    isToDisabled,
    isToOpened,
    isToRequired,
    is12Hour,
    maxDate,
    maxFromDate,
    minDate,
    minToDate,
    portalId,
    selectedToDate,
    selectedFromDate,
    toLabel,
    timeHint,
    toPlaceholder,
    translate,
    onBlur,
    onCalendarClosed,
    onCalendarOpened,
    onChange,
    onClear,
    onClick,
    onDateSelected,
    onFocus,
    onFromClick,
    onKeyDown,
    onTimeOpened,
    onTimeSelected,
    onToClick,
    onUnevenTimeSelected
}: IDatepickerProps) => {
    const classDatepicker = CLASS_DATEPICKER;

    const [openedDateType, setOpenedDateType] = React.useState(isToOpened ? EDateType.DUE_DATE : EDateType.START_DATE);
    const [props, setProps] = React.useState<IDateDropdownProps>({
        date: isToOpened ? selectedToDate : selectedFromDate,
        fromDate: isToOpened && selectedFromDate,
        toDate: isFromOpened && selectedFromDate,
        isNoFocus: null,
        maxDate: null,
        maxFromDate: null,
        minDate: null,
        minToDate: null,
        onArrowPress: null,
        onChange: null,
        onDateType: null,
    });

    const setCalendarProps = React.useCallback((
        props: IDateDropdownProps,
        dateType: EDateType
    ) => {
        setProps(props);
        setOpenedDateType(dateType);
    }, []);

    const [context, setContext] = React.useState<IDatepickerContext>({
        dateFormat,
        direction,
        dropdownDirectionX,
        dropdownDirectionY,
        isAllowSameDate,
        isClearable,
        isCompact,
        isNoDropdown,
        isOverdue,
        isReadonly,
        isSingle,
        isCalendarStartsSunday,
        isTime,
        is12Hour,
        maxDate,
        minDate,
        portalId,
        translate,
        openedDateType,
        setCalendarProps,
    });

    React.useEffect(() => {
        setContext({
            ...context,
            isOverdue,
            maxDate,
            minDate,
            openedDateType,
        });
    }, [
        isOverdue,
        maxDate,
        minDate,
        openedDateType,
    ]);

    return (
        <DatepickerContext.Provider value={context}>
            <div
                className={`
                    ${className || ''}
                    ${CARD_CONTROL_CLASS}
                    ${classDatepicker}
                    ${classDatepicker + '--' + (direction === EDatepickerDirection.COLUMN ? 'column' : 'row')}
                    ${isReadonly ? classDatepicker + '--readonly' : ''}
                    ${isTime ? classDatepicker + '--with-time' : ''}
                    ${is12Hour ? classDatepicker + '--12-hours' : ''}
                    ${isCompact ? classDatepicker + '--compact' : ''}
                    ${isNoDropdown ? classDatepicker + '--no-dropdown' : ''}
                `}
                onClick={e => onClick && onClick(e)}
            >
                <DatepickerItem
                    dateHint={fromDateHint}
                    dateType={EDateType.START_DATE}
                    toDate={!isSingle && selectedToDate}
                    isRequired={isFromRequired}
                    selectedDate={selectedFromDate}
                    defaultTime={defaultFromTime}
                    onDateChange={(date) => onChange(date, EDateType.START_DATE)}
                    label={fromLabel}
                    placeholder={fromPlaceholder}
                    isOpened={isFromOpened}
                    isDisabled={isFromDisabled}
                    isHideDate={isHideFromDate}
                    onCalendarClosed={() => onCalendarClosed && onCalendarClosed(EDateType.START_DATE)}
                    onCalendarOpened={() => onCalendarOpened && onCalendarOpened(EDateType.START_DATE)}
                    onClear={() => onClear && onClear(EDateType.START_DATE)}
                    onTimeSelected={() => onTimeSelected && onTimeSelected(EDateType.START_DATE)}
                    onDateSelected={() => onDateSelected && onDateSelected(EDateType.START_DATE)}
                    onTimeOpened={() => onTimeOpened && onTimeOpened(EDateType.START_DATE)}
                    minToDate={minToDate}
                    onUnevenTimeSelected={() => onUnevenTimeSelected && onUnevenTimeSelected(EDateType.START_DATE)}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onKeyDown={onKeyDown}
                    onClick={onFromClick}
                    timeHint={timeHint}
                />
                {isNoDropdown && !isSingle && <span className={classDatepicker + '__divider'}>—</span>}
                {!isSingle &&
                    <DatepickerItem
                        isDisabled={isToDisabled}
                        dateType={EDateType.DUE_DATE}
                        isRequired={isToRequired}
                        isHideDate={isHideToDate}
                        selectedDate={selectedToDate}
                        defaultTime={defaultToTime}
                        onDateChange={(date) => onChange(date, EDateType.DUE_DATE)}
                        fromDate={selectedFromDate}
                        placeholder={toPlaceholder}
                        label={toLabel}
                        isOpened={isToOpened}
                        maxFromDate={maxFromDate}
                        onCalendarClosed={() => onCalendarClosed && onCalendarClosed(EDateType.DUE_DATE)}
                        onCalendarOpened={() => onCalendarOpened && onCalendarOpened(EDateType.DUE_DATE)}
                        onClear={() => onClear && onClear(EDateType.DUE_DATE)}
                        onTimeSelected={() => onTimeSelected && onTimeSelected(EDateType.DUE_DATE)}
                        onDateSelected={() => onDateSelected && onDateSelected(EDateType.DUE_DATE)}
                        onTimeOpened={() => onTimeOpened && onTimeOpened(EDateType.DUE_DATE)}
                        onUnevenTimeSelected={() => onUnevenTimeSelected && onUnevenTimeSelected(EDateType.DUE_DATE)}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onKeyDown={onKeyDown}
                        onClick={onToClick}
                        timeHint={timeHint}
                    />
                }
                {isNoDropdown && <DateDropdown {...props} />}
            </div>
        </DatepickerContext.Provider>
    );
}
