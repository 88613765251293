import { IApplicationState } from '../../../../types/types';
import { getAuthUser } from './getAuthUser';
import { getShowProFeaturesTrial } from './getShowProFeaturesTrial';

export const isAllowTimeTracker = (
    state: IApplicationState,
): boolean => {
    const user = getAuthUser(state);
    const isProFeatures = getShowProFeaturesTrial(state); // to show tab while modal
    return isProFeatures || user.allowTimeTracker;
};
