import { TBoardId } from '../../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { ECardAgingIndicatorType } from '../../../../../../../../../types/rest/ICardAging';
import { updateIndicator } from '../effectts/updateIndicator';
import { SegmentBoardEvent, segmentTrackAction } from '../../../../../../../../../middlewares/segment';

export const onIndicatorTypeChange= (
    boardId: TBoardId,
    value: ECardAgingIndicatorType
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(updateIndicator(boardId, {
            enabled: true,
            indicatorType: value
        }));
        if (value === ECardAgingIndicatorType.BY_LIST) {
            dispatch(segmentTrackAction(SegmentBoardEvent.CARD_AGING_IN_LIST_CLICKED));
        } else {
            dispatch(segmentTrackAction(SegmentBoardEvent.CARD_AGING_WITHOUT_ACTIVITY_CLICKED));
        }
    };
    return action;
};
