import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { ICustomPropertiesHOCOwnProps } from './types';
import { ICustomPropertiesEvents, ICustomPropertiesFields } from '../../components/CustomPropertiesSection/types';
import { CustomPropertiesSection } from '../../components/CustomPropertiesSection/CustomPropertiesSection';
import { onSort } from './events/onSort';
import { getSectionType } from '../../../selectors/getSectionType';
import { onOpen } from './events/onOpen';
import { getCustomPropertiesCreateSelector } from '../selectors/getCustomProperties';
import { onAdd } from './events/onAdd';
import { onUpdateValue } from './events/onUpdateValue';
import { onSave } from './events/onSave';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { onClickDisallow } from './events/onClickDisallow';
import { onChangeType } from './events/onChangeType';
import { onClickCancel } from './events/onClickCancel';
import { onClickEdit } from './events/onClickEdit';
import { onClickEmpty } from './events/onClickEmpty';
import { onOptionAdd } from './events/onOptionAdd';
import { onConfirmDelete } from './events/onConfirmDelete';
import { onTogglePin } from './events/onTogglePin';
import { onTogglePinOnCard } from './events/onTogglePinOnCard';
import {
    getBoardCardCustomPropertyOptions
} from '../../../../../../../store/model/board/selectors/getBoardCardCustomPropertyOptions';
import { getShowProFeaturesTrial } from '../../../../../../../store/model/authUser/selectors/getShowProFeaturesTrial';
import { getIsUserBasicPlanOnly } from '../../../../../../../store/model/authUser/selectors/getIsUserBasicPlanOnly';

const mapStateToProps = () => {
    const getCustomProperties = getCustomPropertiesCreateSelector();

    return (
        state: IApplicationState,
        ownProps: ICustomPropertiesHOCOwnProps
    ): ICustomPropertiesFields => {
        const { boardId, cardId, sectionType: sectionTypeOwn } = ownProps;
        const sectionType = getSectionType(state, boardId, cardId, sectionTypeOwn, 'allowCardCustomProperties');
        const elements = getCustomProperties(state, boardId, cardId);
        const filledCount = sectionType === ESettingsSectionType.POPUP // показать элементы в попапе
            ? 1
            : elements && elements.length
                ? elements.reduce((filledCount, element) => {
                    if (element.value !== null && element.value !== undefined) filledCount++;
                    return filledCount;
                }, 0)
                : 0;
        const hasPinnedProperty = !!elements.filter(item => item.pinned).length;
        const cardCustomPropertyOptions = getBoardCardCustomPropertyOptions(state, boardId);
        const isDefault = !!cardCustomPropertyOptions && !!cardCustomPropertyOptions.length && cardCustomPropertyOptions[0].isDefault;

        return {
            isBasicFeature: getIsUserBasicPlanOnly(state),
            isProFeature: getShowProFeaturesTrial(state),
            isShow: !!filledCount || hasPinnedProperty || sectionType !== ESettingsSectionType.READONLY && isDefault,
            elements: elements,
            isDefaultProperty: isDefault,
            sectionType,
        }
    };
};

const mapDispatchToProps = (
    dispatch: any,
    { boardId, cardId }: ICustomPropertiesHOCOwnProps
): ICustomPropertiesEvents => {
    return {
        onChange: (name, type, options) => dispatch(onAdd(boardId, cardId, name, type, options)),
        onChangeType: (type) => dispatch(onChangeType(cardId, type)),
        onClickCancel: () => dispatch(onClickCancel(cardId)),
        onClickDisallow: () => dispatch(onClickDisallow()),
        onClickEdit: () => dispatch(onClickEdit(cardId)),
        onClickEmpty: (isShow) => dispatch(onClickEmpty(isShow)),
        onConfirmDelete: (customPropertyId) => dispatch(onConfirmDelete(boardId, cardId, customPropertyId)),
        onSort: () => dispatch(onSort()),
        onOpen: () => dispatch(onOpen(cardId)),
        onOptionAdd: (customPropertyId, element) => dispatch(onOptionAdd(boardId, cardId, customPropertyId, element)),
        onUpdateValue: (customPropertyId, value) => dispatch(onUpdateValue(cardId, customPropertyId, value)),
        onSave: (elements) => dispatch(onSave(boardId, cardId, elements)),
        onTogglePin: (customPropertyId) => dispatch(onTogglePin(cardId, customPropertyId)),
        onTogglePinOnCard: (customPropertyId) => dispatch(onTogglePinOnCard(cardId, customPropertyId))
    }
};

export const CustomPropertiesSectionHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomPropertiesSection);

CustomPropertiesSectionHOC.displayName = 'CustomPropertiesSectionHOC';
