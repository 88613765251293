import { vent } from './../../../../../../../store/constants';
import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { buttonsPanelTypeSwitch } from '../../../../effects/buttonsPanelTypeSwitch';
import { IGetState } from '../../../../../../../types/types';
import { getAsidePanelButtonsPanelIsMinimized } from '../../../store/selectors/getAsidePanelButtonsPanelIsMinimized';
import { buttonsPanelExpandClickedEvent, buttonsPanelMinimizeClickedEvent } from '../../../effects/segmentEvents';

export const onClick = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const isMinimized = getAsidePanelButtonsPanelIsMinimized(state);
        if (isMinimized) {
            dispatch(buttonsPanelExpandClickedEvent());
        } else {
            dispatch(buttonsPanelMinimizeClickedEvent());
        }

        dispatch(buttonsPanelTypeSwitch());
        requestAnimationFrame(() => vent.trigger(vent['aside-panel:opened'])); // main-view onAsidePanelOpened
    };
    return action;
};
