import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState, TCardId } from '../../../../types/types';
import { getCardEpicsForProcessingPercentChange } from '../../../../store/model/selectors/getCardEpicsForProcessingPercentChange';
import { getCard } from '../../../../store/model/selectors/getCard';
import { cardsRestPatch } from './api/helper/cardsRestPatch';
import { ICards } from '../../../../store/model/cards/types';

export const cardSetSubcardWeight = (
    cardId: TCardId,
    subcardWeight: number,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const card = getCard(getState(), cardId);
        let cards: ICards = {
            [cardId]: {
                meta: {
                    subcardWeight
                }
            }
        }
        cards = getCardEpicsForProcessingPercentChange(getState(), card.epicId, cards);
        return dispatch(cardsRestPatch(cards));
    };
    return action;
};
