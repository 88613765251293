import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { ICardColorTagsButtonHOCOwnProps } from './types';
import { CardColorTagsButton } from '../../components/CardColorTagsButton/CardColorTagsButton';
import { onChange } from '../events/onChange';
import { onDropdownOpen } from './events/onDropdownOpen';
import { getCardColorTagsCreateSelector } from './../../../../../../../store/model/selectors/getCardColorTags';
import { ICardColorTagsButtonEvents, ICardColorTagsButtonFields } from '../../components/CardColorTagsButton/types';
import { getPanelButtonShow } from '../../../selectors/getPanelButtonShow';
import { onOpenSettings } from '../events/onOpenSettings';
import { onClose } from '../CardColorTagsHOC/events/onClose';
import { getAuthUser } from '../../../../../../../store/model/authUser/selectors/getAuthUser';

const mapStateToProps = () => {
    const getCardColorTags = getCardColorTagsCreateSelector();

    return (
        state: IApplicationState,
        { boardId, cardId, isShowTooltip }: ICardColorTagsButtonHOCOwnProps
    ): ICardColorTagsButtonFields => {
        const { allowColorTagCount } = getAuthUser(state);
        const isShow = getPanelButtonShow(state, boardId, cardId);
        const colorTags = getCardColorTags(state, cardId);

        return {
            aloudCount: allowColorTagCount,
            colorTags,
            isShow,
            isShowTooltip,
        }
    };
}

const mapDispatchToProps = (
    dispatch: any,
    { cardId }: ICardColorTagsButtonHOCOwnProps
): ICardColorTagsButtonEvents => (
    {
        onClose: () => dispatch(onClose()),
        onChange: (colorId, source) => dispatch(onChange(cardId, colorId, source)),
        onOpenDropDown: (used, all) => dispatch(onDropdownOpen(used, all)),
        onOpenSettings: () => dispatch(onOpenSettings())
    }
);

export const CardColorTagsButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardColorTagsButton);

CardColorTagsButtonHOC.displayName = 'CardColorTagsButtonHOC';
