import { REST_CARD } from '../../../../constants';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { fetchHandler } from '../../../../../util/fetchHandler';
import Util from '../../../../../util/util';
import { getRestHeadersPost } from '../../../../helpers/getRestHeadersHelper';
import { IGetState, TCardId } from '../../../../../types/types';
import { INotificationBeforeDueDate } from '../../../../../store/model/notificationBeforeDueDate/types';
import { IRestNotificationBeforeDueDate } from '../../../../../types/rest/IRestNotificationBeforeDueDate';

export const postRest = (
    cardId: TCardId,
    body: INotificationBeforeDueDate
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        body.cardId = cardId;
        const url = REST_CARD + cardId + '/notificationBeforeDueDates';
        return fetchHandler<IRestNotificationBeforeDueDate>(
            Util.getApiUrl(url), {
                ...getRestHeadersPost(),
                body: JSON.stringify(body)
            }
        );
    };
    return action;
};
