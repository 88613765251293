import { Dispatch } from '../../../../../../types/actions';
import { IGetState } from '../../../../../../types/types';
import { getTimeTrackerState } from '../../../../aside_panel/cardDetails/TabTimingSection/TimeTracker/store/selectors/getTimeTrackerState';
import { root } from '../../../../../../store/constants';
import { segmentTrackAction } from '../../../../../../middlewares/segment';
import { SegmentTimeTrackerEvent } from '../../../../../../middlewares/segment/trackEntities/timeTrackerEvents';
import { FAKE_CARD_ID_FOR_WIDGET_PRESENTATION } from 'app/const';

export const onCardNameClick = () => {
    return (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const {cardId, boardId} = getTimeTrackerState(state);

        if (cardId === FAKE_CARD_ID_FOR_WIDGET_PRESENTATION) return;

        dispatch(segmentTrackAction(SegmentTimeTrackerEvent.CARD_CLICK));
        root.App.controller.cleverOpenCard(boardId, cardId);
    };
};
