import { IApplicationState, TBoardId, TCardId, TCustomPropertyId } from '../../../../../types/types';
import { ICard } from '../../../../../store/model/card/types';
import { TColorTagColor } from '../../../../../types/model';

export interface IListViewBoard {
    collapsedGroups: TCardId[];
    isBlocked: boolean;
    isGroupByEpics: boolean;
    isMobile: boolean;
    orderType: IOrderType | null;
    printMode: boolean;
    shownFields: TCardField[];
    sortType: ESortType;
    isAddCardShow: boolean;
}

export interface ICardOrder {
    cardId: TCardId;
    nextId?: TCardId;
    prevId?: TCardId;
}

export interface ITreeFlat {
    cardIds: TCardId[];
    maxLevel: number;
}

export interface ICardsTree {
    [cardId: string]: ICardsTree;
}

export enum ESortType {
    CHRONOLOGICAL = 0,
    MANUAL = 1,
}

export interface IOrderType {
    field: TCardField;
    isAsc: boolean;
    sortFunction?: TCardFieldSortFunction;
}

export enum ECardField {
    TITLE = 'title',
    DESCRIPTION = 'description',
    PRIORITY = 'priority',
    PROGRESS = 'progress',
    LIST = 'list',
    TAGS = 'tags',
    START_DATE = 'startDate',
    DUE_DATE = 'dueDate',
    ESTIMATE = 'estimate',
    SPENT_TIME = 'spentTime',
    RELATED_CARDS = 'relatedCards',
    CHECKLIST = 'checklist',
    ATTACHMENTS = 'attachments',
    COMMENTS = 'comments',
    ASSIGNEES = 'assignees',
}

export type TCardField = ECardField | TCustomPropertyId;

export type TCardFieldSortFunction = (
    state: IApplicationState,
    boardId: TBoardId,
    cardA: IListViewCard,
    cardB: IListViewCard,
    orderType: IOrderType,
) => number;

export interface ICardField {
    isHideable: boolean;
    isMobile: boolean;
    isShownByDefault: boolean;
    isShown?: boolean;
    title: string;
    sortField: string;
    sortFunction: TCardFieldSortFunction;
}

export type TCardFields = {
    [cardField: string]: ICardField;
};

export enum ECardColumn {
    NAME = 'NAME',
    PRIORITY = 'PRIORITY',
    PROGRESS = 'PROGRESS',
    LIST = 'LIST',
    TAGS = 'TAGS',
    DATES = 'DATES',
    TIME = 'TIME',
    INFO = 'INFO',
    CUSTOM = 'CUSTOM'
}

export type TCardColumns = {
    [cardColumn: string]: TCardField[];
};

export interface IColorTag {
    id?: any;
    color?: TColorTagColor;
    name?: string;
};

export interface IListViewCard extends ICard {
    listName?: string;
};
