import { root } from '../store/constants';
import { IGDriveFile } from '../types/types';
import { commonAuthManagerInstance } from 'app/helper/authorisation/common/CommonAuthManager';

const BOUNDARY = '-------314159265358979323846';
const DELIMITER = '\r\n--' + BOUNDARY + '\r\n';
const CLOSE_DELIMITER = '\r\n--' + BOUNDARY + '--';
const DEFAULT_CONTENT_TYPE = 'application/octet-stream';
const NEW_LINE = '\r\n';

export const gDriveUploadFile = (
    file: File,
    parentFolderId: string
): Promise<IGDriveFile> => {
    return new Promise<IGDriveFile>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onerror = (e) => {
            reject(e);
        }
        reader.onabort = () => {
            reject();
        }
        reader.onload = (e) => {
            const contentType = file.type || DEFAULT_CONTENT_TYPE;
            let metadata: any = {
                title: file.name,
                mimeType: contentType
            };
            if (parentFolderId) {
                metadata['parents'] = [
                    {
                        kind: 'drive#parentReference',
                        id: parentFolderId
                    }
                ];
            }
            const base64Data = btoa(reader.result as string);
            const multipartRequestBody =
                DELIMITER +
                'Content-Type: application/json' + NEW_LINE + NEW_LINE +
                JSON.stringify(metadata) +
                DELIMITER +
                'Content-Type: ' + contentType + NEW_LINE +
                'Content-Transfer-Encoding: base64' + NEW_LINE +
                NEW_LINE +
                base64Data +
                CLOSE_DELIMITER;

            commonAuthManagerInstance.getAccessTokenOrShowLoginScreen().then((accessToken) =>{
                const request = root.gapi.client.request({
                    path: '/upload/drive/v2/files',
                    method: 'POST',
                    params: {'uploadType': 'multipart', 'supportsAllDrives': 'true'},
                    headers: {
                        'Content-Type': 'multipart/mixed; boundary="' + BOUNDARY + '"'
                    },
                    body: multipartRequestBody
                });

                request.execute((resp: any) => {
                    if (resp.error) {
                        reject(resp.error);
                    } else {
                        resolve(resp as IGDriveFile);
                    }
                })
            })

        }
    })
}
