import { IGetState, TBoardId, TFilterId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { setSortedSavedFilterIds } from './setSortedSavedFilterIds';
import { getOtherSortedSavedFiltersIds } from '../selectors/getOtherSortedSavedFiltersIds';
import { getShowOnBarSortedSavedFilterIds } from '../selectors/getShowOnBarSortedSavedFilterIds';
import { MY_WORK_FILTER_BOARD_ID } from '../../../../constants';

export const reorderSortedSavedFilterIds = (
    boardId: TBoardId,
    filterId: TFilterId,
    index: number,
    isShowOnBar: boolean
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        let result: TFilterId[];
        if (boardId === MY_WORK_FILTER_BOARD_ID) {
            const showOnBarFilterIds = getShowOnBarSortedSavedFilterIds(state);
            const otherFilterIds = getOtherSortedSavedFiltersIds(state, boardId);

            const otherFilterIdx = otherFilterIds.indexOf(filterId);
            const showOnBarFilterIdx = showOnBarFilterIds.indexOf(filterId);
            if (otherFilterIdx > -1) otherFilterIds.splice(otherFilterIdx, 1);
            if (showOnBarFilterIdx > -1) showOnBarFilterIds.splice(showOnBarFilterIdx, 1);

            const filterIds = isShowOnBar ? showOnBarFilterIds : otherFilterIds;
            filterIds.splice(index, 0, filterId);
            result = [
                ...isShowOnBar ? filterIds : showOnBarFilterIds,
                ...isShowOnBar ? otherFilterIds : filterIds
            ];
        } else {
            result = getOtherSortedSavedFiltersIds(state, boardId);
            const idx = result.indexOf(filterId);
            if (idx > -1) result.splice(idx, 1);
            result.splice(index, 0, filterId);
        }
        dispatch(setSortedSavedFilterIds(boardId, result));
    };
    return action;
};
