import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { onCreate } from './events/onCreate';
import { onToggle } from '../CardTagsSectionHOC/events/onToggle';
import { onAddClicked } from './events/onAddClicked';
import { CardTagsSearchSelect } from '../../components/CardTagsSearchSelect/CardTagsSearchSelect';
import { ICardTagsSearchSelectEvents, ICardTagsSearchSelectFields } from '../../components/CardTagsSearchSelect/types';
import { ICardTagsSearchSelectHOCProps } from './types';
import { getCardTagsSearchSelectOptionsCreateSelector } from './selectors/getCardTagsSearchSelectOptions';

const mapStateToProps = () => {
    const getCardTagsSearchSelectOptions = getCardTagsSearchSelectOptionsCreateSelector();
    return (
        state: IApplicationState,
        {
            boardId,
            cardId,
            isOpened,
            isPopup,
        }: ICardTagsSearchSelectHOCProps,
    ): ICardTagsSearchSelectFields => {
        const availableTags = getCardTagsSearchSelectOptions(state, boardId, cardId);
        return {
            availableTags,
            isOpened,
            isPopup,
        };
    };
};

const mapDispatchToProps = (
    dispatch: any,
    { cardId, onAddClosed, onSelect }: ICardTagsSearchSelectHOCProps,
): ICardTagsSearchSelectEvents => {
    return {
        onSelect,
        onCreate: (tag) => dispatch(onCreate(cardId, tag)),
        onAddClicked: () => dispatch(onAddClicked()),
        onAddClosed,
    };
};

export const CardTagsSearchSelectHOC = connect(
    mapStateToProps,
    mapDispatchToProps,
)(CardTagsSearchSelect);

CardTagsSearchSelectHOC.displayName = 'CardTagsSearchSelectHOC';
