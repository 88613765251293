import { IApplicationState, TBoardId } from 'app/types/types';
import { getBoard } from './getBoardById';
import { EAuthUserPlatformType } from 'app/types/rest/IRestAuthUser';

export const getBoardPlatformType = (
    state: IApplicationState,
    boardId: TBoardId,
): EAuthUserPlatformType => {
    return getBoard(state, boardId).platformType || EAuthUserPlatformType.GOOGLE;
}
