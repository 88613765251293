import getGoogleCalendars from './../helpers/getGoogleCalendars';
import setFirstAvailableAction from './../effects/setFirstAvailableAction';

import {
    syncGoogleCalendarsRequest,
    syncGoogleCalendarsSuccess,
    syncGoogleCalendarsFailure
} from './../store/actions/syncGoogleCalendars';

const syncGoogleCalendarsList = () => {
    return (dispatch, getState) => {
        dispatch(syncGoogleCalendarsRequest());
        getGoogleCalendars().then(googleCalendars => {
            dispatch(syncGoogleCalendarsSuccess(googleCalendars));
            dispatch(setFirstAvailableAction());
        }).catch(e => {
            dispatch(syncGoogleCalendarsFailure(e));
        });
    };
};

export default syncGoogleCalendarsList;