import { Dispatch, ThunkAction } from 'app/types/actions';
import { isAsideOpenSetAction } from 'app/view/react_components/main/navigationPanel/store/navigationPanel/actions/isAsideOpenSetAction';
import { IGetState } from '../../../../../../types/types';

export function onClose (
): ThunkAction {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        dispatch(isAsideOpenSetAction(false));
    };
    return action
};
