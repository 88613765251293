import { ICardColorsActions } from '../actions/types';
import { AT_SET_CARD_COLORS_SHOW_EDIT_TOOLTIP } from '../actions/constants';
import { ICardColorsState } from './types';
import { getInitialState } from './constants';
import { setShowColorsEditTooltipLocalStorage } from '../localstorage/actions/setShowColorsEditTooltipLocalStorage';

const initialState = getInitialState();

export const _cardColorReducer = (
    state: ICardColorsState = initialState,
    action: ICardColorsActions
): ICardColorsState => {
    switch (action.type) {
        case AT_SET_CARD_COLORS_SHOW_EDIT_TOOLTIP: {
            return {
                ...state,
                isShowEditTooltip: action.isShow
            }
        }
        default: {
            return state;
        }
    }
};

export const cardColorReducer = (
    state = initialState,
    action: ICardColorsActions
): ICardColorsState => {
    const newState = _cardColorReducer(state, action);
    if (newState !== state) {
        if (action.type === AT_SET_CARD_COLORS_SHOW_EDIT_TOOLTIP) {
            setShowColorsEditTooltipLocalStorage(action.isShow);
        }
    }
    return newState;
}
