import * as React from 'react';
import { IRestBoardCopyActivity } from '../../../../../../../../types/rest/activity/IRestBoardCopyActivity';
import { IActivityProps } from '../../types';
import { TActivity } from '../../../../../../../../store/activities/types';
import { ActivityItemBody } from '../ActivityItemBody/ActivityItemBody';
import { ActivityHeader } from '../ActivityHeader/ActivityHeader';

export function BoardActivityCopy ({
    activity
}: IActivityProps<IRestBoardCopyActivity>) {
    const copiedFrom: IRestBoardCopyActivity = {
        ...activity,
        dashboard: {
            id: Number(activity.copiedFromId),
            name: activity.oldValue
        }
    }
    const { label } = activity as TActivity;
    return (
        <ActivityItemBody
            header={<ActivityHeader icon={'copy'} label={label} />}
            activity={copiedFrom}
        />
    );
}
