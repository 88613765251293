import { IApplicationState, TBoardId } from '../../../types/types';
import { getBoardPermissions } from './getBoardPermissions';
import { isBoardCardsLimitReached } from './isBoardCardsLimitReached';
import { getBoard } from './getBoardById';
import { getAuthUser } from '../authUser/selectors/getAuthUser';
import { getBoardIsOnSharedDrive } from './getBoardIsOnSharedDrive';

export const getBoardPermissionsAllowEdit = (
    state: IApplicationState,
    boardId: TBoardId,
): boolean => {
    const boardPermissions = getBoardPermissions(state, boardId);
    const board = getBoard(state, boardId);
    const allowEdit = (
        boardPermissions &&
        boardPermissions.authPermissions &&
        boardPermissions.authPermissions.allowEdit
    );
    const authUser = getAuthUser(state);
    const isOnShareDrive = getBoardIsOnSharedDrive(state, boardId);
    return (
        allowEdit
        && !board.lockDate
        && !isBoardCardsLimitReached(state, boardId, 0)
        && (!isOnShareDrive || authUser.allowSharedDrive)
    );
};
