import { Button } from 'kui';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import { getMessages, root } from '../../../../store/constants';
import { EHintKey, HintHOC } from '../../hints';
import './_HintOpenedLastBoardReactToBackbone.scss';

export class HintOpenedLastBoardReactToBackbone {
    el: Element;
    store: Store;
    isNavPanelBig: boolean; // если нет, то некуда указывать

    constructor (
        store: Store,
        isNavPanelBig: boolean,
    ) {
        this.store = store;
        this.isNavPanelBig = isNavPanelBig;
        if (this.el) this.remove();
        this.el = document.createElement('div');
        this.el.className = 'hint-opened-last-board-placeholder';
        document.body.append(this.el);
        this.render();
    }

    render () {
        const className = 'hint-opened-last-board';
        ReactDOM.render(
            <Provider store={this.store}>
                <HintHOC
                    arrow={'down'}
                    arrowTranslate={{left: 64}}
                    direction={'up'}
                    className={`${className} ${this.isNavPanelBig ? '' : className + '--noarrow'}`}
                    show={true}
                    value={getMessages().getText('hint.opened_last_board')}
                    footer={
                        <Button
                            color={'white'}
                            onClick={()=>root.App.controller.openUserSettings()}
                        >
                            {getMessages().getText('hint.opened_last_board.button_settings')}
                        </Button>
                    }
                    hintKey={EHintKey.OPENED_LAST_BOARD}
                    selector={'.navigation-panel__user-help'}
                />
            </Provider>,
            this.el
        );
    }

    remove () {
        if (!this.el) return;

        ReactDOM.unmountComponentAtNode(this.el);
        this.el.remove();
        this.el = null;
    }
}
