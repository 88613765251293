import { EFeature } from 'app/types/rest/IUserMeta';
import { IApplicationState } from '../../../../../types/types';
import { isNewFeatureForUser } from '../isNewFeatureForUser';
import {
    ATTACHMENT_ADDED
} from '../../../../../view/react_components/aside_panel/cardDetails/AttachmentsSection/helpers/setAttachmentAddedSessionStorage';

export const getIsNewFeaturePasteAttachment = (
    state: IApplicationState
): boolean => {
    const isNewFeature = isNewFeatureForUser(state, EFeature.PASTE_ATTACHMENT);
    if (
        !isNewFeature ||
        !sessionStorage.getItem(ATTACHMENT_ADDED)
    ) {
        return false;
    }

    return true;
};
