import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { ICollaborator } from '../../../../../../../store/collaborators/collaborators/types';
import { cardActionSet } from '../../../../../../../store/collaborators/modelsCollaborators/actions/cardActionSet';
import { setFirstAction } from '../../../../../../../store/collaborators/collaborators/actions/setFirstAction';
import { highlightCardIdSet } from '../../../../kanbanView/store/actions/highlightCardIdSet';

export const onClick = (
    collaborator: ICollaborator,
): ThunkAction  => {
    const action = (
        dispatch: Dispatch,
    ) => {
        const { activeCardId } = collaborator;
        dispatch(cardActionSet(activeCardId, setFirstAction(collaborator))); // if more than one collaborator - view the one that was clicked
        dispatch(highlightCardIdSet(activeCardId));
    };
    return action;
}
