import { IGetState } from '../../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import {
    lastClickedActivityIdSet
} from '../../../../../../cardDetails/TabActivity/store/reducers/actions/lastClickedActivityIdSet';

export const resetLastClickedActivity = (): ThunkAction => {
    const action: ThunkAction = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(lastClickedActivityIdSet(null));
    };
    return action;
}
