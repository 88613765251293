import * as React from 'react';
import { IActivityTagsProps } from './types';
import {
    ActivityItemName
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemName/ActivityItemName';
import {
    ActivityItemMore
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemMore/ActivityItemMore';
import {
    CLASS_ACTIVITY_ITEM,
    CLASS_ACTIVITY_ITEM_TEMP
} from '../../../../boardDetails/activity/TabActivity/components/constants';
import './_ActivityTags.scss';
import { GOOGLE_SPACING } from '../../../../../../../const';

export function ActivityTags({
    asidePanelWidth,
    tags
}: IActivityTagsProps) {
    const className = CLASS_ACTIVITY_ITEM + '__tags';

    const [isNeedToCalculate, setNeedToCalculate] = React.useState(true);
    const [visibleElements, setVisibleElements] = React.useState([]);
    const [tooltip, setTooltip] = React.useState(null);

    const ref = React.useRef(null);
    const tempRef = React.useRef(null);

    React.useEffect(() => {
        setNeedToCalculate(true);
    }, [tags, asidePanelWidth]);

    React.useEffect(() => {
        if (!isNeedToCalculate) return;

        const newElements: string[] = [];

        const container = ref.current as HTMLElement;
        const tempContainer = tempRef.current as HTMLElement;
        if (!container || !tempContainer) return;

        const maxWidth = container.offsetWidth - GOOGLE_SPACING * 2 // место для плашки с плюсом
        let currentWidth = 0;

        for (let i = 0; i < tags.length; i++) {
            const tagEl = tempContainer.children[i] as HTMLElement;
            if (!tagEl) return;

            const tagWidth = tagEl.offsetWidth + GOOGLE_SPACING; // + gap
            currentWidth += tagWidth;
            if (currentWidth >= maxWidth) {
                break;
            }
            newElements.push(tags[i]);
        }

        setVisibleElements(newElements);

        const dif = tags.length - newElements.length;
        if (dif) {
            setTooltip(
                tags.slice(newElements.length).reduce((tooltip, tag) => {
                    tooltip += '\n' + '#' + tag;
                    return tooltip;
                }, '')
            );
        }

        setNeedToCalculate(false);
    }, [isNeedToCalculate, tags, asidePanelWidth]);

    return <>
        {!!tags &&
            <div className={className} ref={ref}>
                {visibleElements.map(tag => <ActivityItemName name={'#' + tag} key={tag} />)}
                {visibleElements.length !== tags.length && <ActivityItemMore count={tags.length - visibleElements.length} tooltip={tooltip} />}
            </div>
        }
        {isNeedToCalculate &&
            <div
                className={`${className} ${CLASS_ACTIVITY_ITEM_TEMP}`}
                ref={tempRef}
            >
                {tags.map(tag => <ActivityItemName name={'#' + tag} key={tag} />)}
            </div>
        }
    </>;
};
