import * as React from 'react';
import { ICardChecklistActionsProps } from './types';
import { CLASS_CARD_DETAILS } from '../../../constants';
import './_CardChecklistActions.scss';
import { Button, ButtonDropdown, Divider, Icon, ITooltipInheritedProps, SelectList, SelectListItem } from 'kui';
import {
    CARD_CHECKLIST_ACTIONS_ASSIGN,
    CARD_CHECKLIST_ACTIONS_AUTHOR_HIDE,
    CARD_CHECKLIST_ACTIONS_AUTHOR_SHOW,
    CARD_CHECKLIST_ACTIONS_COPY,
    CARD_CHECKLIST_ACTIONS_DELETE,
    CARD_CHECKLIST_ACTIONS_DONE,
    CARD_CHECKLIST_ACTIONS_HIDE,
    CARD_CHECKLIST_ACTIONS_PIN,
    CARD_CHECKLIST_ACTIONS_RENAME,
    CARD_CHECKLIST_ACTIONS_SHOW,
    CARD_CHECKLIST_ACTIONS_SORT,
    CARD_CHECKLIST_ACTIONS_TOOLTIP,
    CARD_CHECKLIST_ACTIONS_UNPIN
} from './constants';
import { CardChecklistSortingHOC } from '../../hocs/CardChecklistSortingHOC/CardChecklistSortingHOC';
import { CardChecklistMassAssignHOC } from '../../hocs/CardChecklistMassAssignHOC/CardChecklistMassAssignHOC';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import {
    CardChecklistCopySearchSelectHOC
} from '../../hocs/CardChecklistCopySearchSelectHOC/CardChecklistCopySearchSelectHOC';
import { ID_ASIDE_PANEL_ACTIONS_PORTAL } from '../../../../asidePanel/components/AsidePanelActionsButton/constants';

export const CardChecklistActions = ({
    checklistId,
    copyCardId,
    isEmptyChecklists,
    isItems,
    isMultiple,
    isMultipleUsers,
    isPinned,
    isShowAuthor,
    isShowDone,
    onCopy,
    onDelete,
    onDone,
    onHide,
    onPin,
    onRename,
    onAuthorToggle
}: ICardChecklistActionsProps) => {
    const { cardId, boardId } = React.useContext(AsidePanelContext);

    const className = CLASS_CARD_DETAILS + '-checklist__actions';
    const classDropdown = className + '-dropdown';

    const [isOpened, setOpened] = React.useState(null);
    const [isSort, setSort] = React.useState(null);
    const [isAssign, setAssign] = React.useState(null);
    const [isCopy, setCopy] = React.useState(null);

    const onClose = () => {
        setOpened(false);
    };

    const onOpen = () => {
        setOpened(true);
        setSort(false);
        setAssign(false);
        setCopy(false);
    };

    const onSelect = (action: () => void) => {
        action();
        onClose();
    };

    const onBack = () => {
        setSort(false)
        const dropdown = document.querySelector(`.${classDropdown}`) as HTMLElement;
        if (dropdown) dropdown.focus();
    }

    return (
        <ButtonDropdown
            className={className}
            dropdownClassName={`
                ${classDropdown}
                ${isAssign ? classDropdown + '--assign' : ''}
                ${isCopy ? classDropdown + '--copy' : ''}
            `}
            isMoveToFit
            directionHorizontal={'right'}
            directionVertical={'down'}
            opened={isOpened}
            onClose={onClose}
            onOpen={onOpen}
            portal
            portalId={ID_ASIDE_PANEL_ACTIONS_PORTAL}
        >
            <Button
                className={className + '-button'}
                tooltip={{
                    isNoEvents: true,
                    isNoWrap: true,
                    value: CARD_CHECKLIST_ACTIONS_TOOLTIP,
                } as ITooltipInheritedProps}
                variant={'icon'}
            >
                <Icon xlink={'more'} size={24}/>
            </Button>
            <>
                {isSort && <CardChecklistSortingHOC onClose={onClose} onBack={onBack} />}
                {isAssign &&
                    <CardChecklistMassAssignHOC
                        cardId={cardId}
                        checklistId={checklistId}
                        onClose={onClose}
                    />
                }
                {isCopy &&
                    <CardChecklistCopySearchSelectHOC
                        boardId={boardId}
                        cardId={cardId}
                        selectedCardId={copyCardId}
                        isAutoFocus
                        isClose
                        onSelect={cardId => {
                            onCopy(cardId);
                            onClose();
                        }}
                    />
                }
                {!isSort && !isAssign && !isCopy &&
                    <SelectList fixActive={false}>
                        {isMultiple && !isShowDone &&
                            <SelectListItem
                                className={className + '-item--arrow'}
                                icon={'sort'}
                                onClick={() => setSort(true)}
                                iconSize={24}
                            >
                                {CARD_CHECKLIST_ACTIONS_SORT}
                                <Icon xlink={'arrow-right'} size={16} />
                            </SelectListItem>
                        }
                        {isMultiple && !isShowDone &&  <Divider />}
                        {!isEmptyChecklists &&
                            <SelectListItem
                                icon={'pin'}
                                onClick={() => onSelect(onPin)}
                                iconSize={24}
                            >
                                {isPinned ? CARD_CHECKLIST_ACTIONS_UNPIN : CARD_CHECKLIST_ACTIONS_PIN}
                            </SelectListItem>
                        }
                        {isMultiple &&
                            <SelectListItem
                                icon={isShowDone ? 'eye' : 'visibility-off'}
                                onClick={() => onSelect(onHide)}
                                iconSize={24}
                            >
                                {isShowDone ? CARD_CHECKLIST_ACTIONS_SHOW : CARD_CHECKLIST_ACTIONS_HIDE}
                            </SelectListItem>
                        }
                        {isMultiple && isMultipleUsers &&
                            <SelectListItem
                                icon={'author'}
                                onClick={() => onSelect(onAuthorToggle)}
                                iconSize={24}
                            >
                                {isShowAuthor ? CARD_CHECKLIST_ACTIONS_AUTHOR_HIDE : CARD_CHECKLIST_ACTIONS_AUTHOR_SHOW}
                            </SelectListItem>
                        }
                        {!isEmptyChecklists && <Divider />}
                        {isItems && !isEmptyChecklists &&
                            <SelectListItem
                                icon={'account'}
                                onClick={() => setAssign(true)}
                                iconSize={24}
                            >
                                {CARD_CHECKLIST_ACTIONS_ASSIGN}
                            </SelectListItem>
                        }
                        {isItems && !isEmptyChecklists &&
                            <SelectListItem
                                icon={'check-multi'}
                                onClick={() => onSelect(onDone)}
                                iconSize={24}
                            >
                                {CARD_CHECKLIST_ACTIONS_DONE}
                            </SelectListItem>
                        }
                        {isMultiple &&
                            <SelectListItem
                                icon={'copy'}
                                onClick={() => {
                                    if (copyCardId) {
                                        const copySection = document.querySelector(
                                            '.' + CLASS_CARD_DETAILS + '__checklist-copy-search-select .kui-input__item'
                                        ) as HTMLElement;
                                        onClose();
                                        if (copySection) copySection.focus();
                                    } else {
                                        setCopy(true);
                                    }
                                }}
                                iconSize={24}
                            >
                                {CARD_CHECKLIST_ACTIONS_COPY}
                            </SelectListItem>
                        }
                        {!isEmptyChecklists && (isItems || isMultiple) && <Divider />}
                        {!isEmptyChecklists &&
                            <SelectListItem
                                icon={'rename'}
                                onClick={() => onSelect(onRename)}
                                iconSize={24}
                            >
                                {CARD_CHECKLIST_ACTIONS_RENAME}
                            </SelectListItem>
                        }
                        {!isEmptyChecklists &&
                            <SelectListItem
                                icon={'delete'}
                                onClick={() => onSelect(onDelete)}
                                iconSize={24}
                            >
                                {CARD_CHECKLIST_ACTIONS_DELETE}
                            </SelectListItem>
                        }
                    </SelectList>
                }
            </>
        </ButtonDropdown>
    );
};
