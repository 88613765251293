import * as React from 'react';
import { ICardIconProps } from './types';
import './_CardIcon.scss';
import { Icon, Tooltip } from 'kui';
import { CLASS_CARD_DETAILS } from '../../../constants';
import { CARD_ICON_ARCHIVED } from './constants';

export function CardIcon({
    isArchive,
}: ICardIconProps) {
    if (!isArchive) return null;

    const containerClassName = CLASS_CARD_DETAILS + '__icon-container';
    let leftIconClassName = CLASS_CARD_DETAILS + '__icon';
    const classNameIcon = CLASS_CARD_DETAILS + '__icon-icon';
    let rightClassName = CLASS_CARD_DETAILS + '__icon';

    let leftIconSrc = null;
    let leftIconTooltip = null;
    let rightIconSrc = null;
    let rightIconTooltip = null;

    if (isArchive) {
        leftIconSrc = 'archive';
        leftIconTooltip = CARD_ICON_ARCHIVED;
        leftIconClassName += ' ' + leftIconClassName + '--archive';
    }

    const containerRightClassName = rightIconSrc ? containerClassName + '--right' : '';

    return (
        <div className={`
            ${containerClassName}
            ${containerRightClassName}
        `}>
            <div className={leftIconClassName}>
                <Tooltip
                    direction={'left'}
                    value={leftIconTooltip}
                >
                    <Icon
                        className={classNameIcon}
                        size={24}
                        xlink={leftIconSrc}
                    />
                </Tooltip>
            </div>
            {
                rightIconSrc &&
                <div className={rightClassName + ' card-details__icon--right'}>
                    <Tooltip
                        direction={'left'}
                        value={rightIconTooltip}
                    >
                        <Icon
                            className={classNameIcon}
                            size={24}
                            xlink={rightIconSrc}
                        />
                    </Tooltip>
                </div>}
        </div>
    );
};
