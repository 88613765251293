import { getAsidePanelLocalStorage } from './localstorage/actions/getAsidePanelLocalStorage';
import { EButtonsPanelType, IAsidePanelState } from './types';
import { ASIDE_DEFAULT_WIDTH } from '../../../../../const';

const asidePanelInitial: IAsidePanelState = {
    activityId: null,
    entityPanelWidth: ASIDE_DEFAULT_WIDTH,
    buttonsPanelType: EButtonsPanelType.NORMAL,
    ganttContextCardId: null,
    isMobile: false,
    isPrint: false,
    isGoogleFileCreating: false,
};

export const getAsidePanelInitialState = (
): IAsidePanelState => ({
    ...asidePanelInitial,
    ...getAsidePanelLocalStorage(),
    leftPanelOpened: false
});
