import { IGetState, TBoardId, TTag } from '../../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { SegmentBoardEvent, segmentTrackAction } from '../../../../../../../../../middlewares/segment';
import { getBoardCardsToTagUpdate } from '../../../../../../../../../store/model/selectors/getBoardCardsToTagUpdate';
import { cardsRestPatch } from '../../../../../../../../../rest/effects/card/card/api/helper/cardsRestPatch';

export const onChangeName = (
    boardId: TBoardId,
    tag: TTag,
    name: TTag
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentBoardEvent.RENAMED_TAG));

        const cards = getBoardCardsToTagUpdate(getState(), boardId, tag, name);
        dispatch(cardsRestPatch(cards));
    };
    return action;
};
