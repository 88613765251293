import { getMessages } from '../../../../store/constants';
import { FREE_VERSION_CARDS_COUNT } from '../../../../store/model/constants';

export const SNACKBAR_BUTTON_CANCEL =   getMessages().getText('snackbar.button.cancel');
export const SNACKBAR_BUTTON_CLOSE =    getMessages().getText('snackbar.button.close');
export const SNACKBAR_BUTTON_CONTACT =  getMessages().getText('snackbar.button.contact_us');
export const SNACKBAR_BUTTON_DELETE =   getMessages().getText('snackbar.button.delete');
export const SNACKBAR_BUTTON_OK =       getMessages().getText('snackbar.button.ok');
export const SNACKBAR_BUTTON_RELOAD =   getMessages().getText('snackbar.button.reload');
export const SNACKBAR_BUTTON_START =    getMessages().getText('snackbar.button.start');
export const SNACKBAR_BUTTON_UPGRADE =  getMessages().getText('snackbar.button.upgrade');
export const SNACKBAR_BUTTON_YES =      getMessages().getText('snackbar.button.yes');
export const SNACKBAR_BUTTON_NO =       getMessages().getText('snackbar.button.no');
export const SNACKBAR_BUTTON_UNDO =     getMessages().getText('snackbar.button.undo');
export const SNACKBAR_BUTTON_ARCHIVE =  getMessages().getText('snackbar.button.archive');

export const SNACKBAR_VARIANTS_MINOR = [ // show 1 time, delete from stack on adding new snackbar
    'INFO',
    'SUCCESS',
    'UPGRADE',
];

export const SNACKBAR_ID_ARCHIVE =          'SNACKBAR_ID_ARCHIVE';
export const SNACKBAR_ID_ARCHIVE_WITH_DELETE = 'SNACKBAR_ID_ARCHIVE_WITH_DELETE';
export const SNACKBAR_ID_BOARD_NOT_FOUND =  'SNACKBAR_ID_BOARD_NOT_FOUND';
export const SNACKBAR_ID_REINSTALL =        'SNACKBAR_ID_REINSTALL';
export const SNACKBAR_ID_RELOAD =           'SNACKBAR_ID_RELOAD';
export const SNACKBAR_ID_UPGRADE =          'SNACKBAR_ID_UPGRADE';
export const SNACKBAR_ID_WORKLOAD_NO_LIST = 'SNACKBAR_ID_WORKLOAD_NO_LIST';
export const SNACKBAR_ID_FREE_VERSION_LIMIT_CARD = 'SNACKBAR_ID_FREE_VERSION_LIMIT_CARD';
export const SNACKBAR_ID_BOARD_ERROR = 'SNACKBAR_ID_BOARD_ERROR';
export const SNACKBAR_ID_CARD_MOVIED = 'SNACKBAR_ID_CARD_MOVIED';
export const SNACKBAR_ID_CARD_ADD_TO_MY_WORK = 'SNACKBAR_ID_CARD_ADD_TO_MY_WORK';
export const SNACKBAR_ID_CARD_DELETED = 'SNACKBAR_ID_CARD_DELETED';
export const SNACKBAR_ID_REFRESH = 'SNACKBAR_ID_REFRESH';

export const FREE_VERSION_CREATE_EXTRA_CARD_TITLE = getMessages().getText('snackbar.free.version.limit.try.create.extra.card.title', null ,FREE_VERSION_CARDS_COUNT);
export const FREE_VERSION_CREATE_EXTRA_CARD_TEXT = getMessages().getText('snackbar.free.version.limit.try.create.extra.card.text');

export const FREE_VERSION_OPEN_BOARD_TITLE = getMessages().getText('snackbar.free.version.limit.open.board.title', null ,FREE_VERSION_CARDS_COUNT);
export const FREE_VERSION_OPEN_BOARD_TEXT = getMessages().getText('snackbar.free.version.limit.open.board.text');
export const FREE_VERSION_TIMER = 10;
export const VIEW_ONLY_TIMER = 3;
