import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState, TBoardId } from '../../../../types/types';
import { addRecentOpenBoard } from '../../../../rest/effects/authUser/recentBoards/addRecentOpenBoard';
import { getTabIds } from '../../../../store/model/selectors/getTabIds';
import { root } from '../../../../store/constants';

export const boardClose = (
    boardId: TBoardId,
    addToRecent: boolean = true
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const promise: Promise<any> = addToRecent ? dispatch(addRecentOpenBoard(boardId, true)) : Promise.resolve();
        const tabIds = getTabIds(getState());
        const index = tabIds.indexOf(boardId);
        if (index > -1) {
            tabIds.splice(index, 1);
            root.App.router.setBaseUrl(tabIds);
            root.App.router.navigate(root.App.router.getBaseUrl(), { trigger: true });
        }
        return promise;
    };
    return action;
};
