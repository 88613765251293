import {connect} from 'react-redux';
import {IApplicationState} from '../../../../../../types/types';
import { onClickFreeHelp } from './events/onClickFreeHelp';
import { onClickStartTrial } from './events/onClickStartTrial';
import { FreeVersionButton } from '../../components/freeVersionButton/FreeVersionButton';
import { IFreeVersionButtonEvents, IFreeVersionButtonFields } from '../../components/freeVersionButton/types';
import { onClickBuy } from './events/onClickBuy';
import { onClickStartTrialModal } from './events/onClickStartTrialModal';
import * as _ from 'underscore';
import { onClickBuyFromModal } from './events/onClickBuyFromModal';
import { isPwc } from '../../../../../../store/model/authUser/selectors/isPwc';
import { getAuthUser } from '../../../../../../store/model/authUser/selectors/getAuthUser';

const mapStateToProps = (
    state: IApplicationState
): IFreeVersionButtonFields => {
    const user = getAuthUser(state);
    const startTrial = !_.isEmpty(user.firstTrialCode);
    const isLicense = isPwc(state);
    return {
        isLicense,
        startTrial,
    }
};

const mapDispatchToProps = (
    dispatch: any
): IFreeVersionButtonEvents => {
    return {
        onClickBuy: () => dispatch(onClickBuy()),
        onClickBuyFromModal: () => dispatch(onClickBuyFromModal()),
        onClickFreeHelp: () => dispatch(onClickFreeHelp()),
        onClickStartTrial: () => dispatch(onClickStartTrial()),
        onClickStartTrialModal: () => dispatch(onClickStartTrialModal()),
    }
};

export const FreeVersionButtonHOC = connect(mapStateToProps, mapDispatchToProps)(FreeVersionButton);
FreeVersionButtonHOC.displayName = 'FreeVersionButtonHOC';
