import { IApplicationState } from '../../../../../../../types/types';
import { connect } from 'react-redux';
import { IBoardExportSettingsHOCProps } from './types';
import { BoardExportSettings } from '../../components/BoardExportSettings/BoardExportSettings';
import { IBoardExportSettingsEvents, IBoardExportSettingsFields } from '../../components/BoardExportSettings/types';
import { getBoardExportSettings } from '../../../../../../../store/model/board/selectors/getBoardExportSettings';
import { onColumnToggle } from './events/onColumnToggle';
import { onColumnsSort } from './events/onColumnsSort';
import { onDateFormatChange } from './events/onDateFormatChange';
import { getExportSortedColumns } from './selectors/getExportSortedColumns';
import { onTimeFormatChange } from './events/onTimeFormatChange';
import { onChecklistsRowsToggle } from './events/onChecklistsRowsToggle';
import { onAllColumnsSet } from './events/onAllColumnsSet';
import { onArchivedChange } from './events/onArchivedChange';

const mapStateToProps = (
    state: IApplicationState,
    { boardId }: IBoardExportSettingsHOCProps
): IBoardExportSettingsFields => {
    const settings = getBoardExportSettings(state, boardId);
    const columns = getExportSortedColumns(state, boardId);
    const isAllColumnsSelected = !columns.filter((column) => column.enabled === false).length;
    return {
        checklistsRows: settings && settings.checklistsRows,
        columns,
        isAllColumnsSelected,
        dateFormat: settings && settings.dateFormat,
        is12hours: settings && settings.is12hours,
        archived: settings && settings.isIncludeArchive
    }
};

const mapDispatchToProps = (
    dispatch: any,
    { boardId, isAuto }: IBoardExportSettingsHOCProps
): IBoardExportSettingsEvents => {
    return {
        onChecklistsRowsToggle: () => dispatch(onChecklistsRowsToggle(boardId)),
        onColumnToggle: (columnId, checked) => dispatch(onColumnToggle(boardId, columnId, checked)),
        onAllColumnsToggle: (checked) => dispatch(onAllColumnsSet(boardId, checked)),
        onColumnsSort: (columnId, newIndex) => dispatch(onColumnsSort(boardId, columnId, newIndex)),
        onDateFormatChange: (dateFormat) => dispatch(onDateFormatChange(boardId, dateFormat)),
        onTimeFormatChange: (is12hours) => dispatch(onTimeFormatChange(boardId, is12hours)),
        onArchivedChanged: (archived) => dispatch(onArchivedChange(boardId, archived, isAuto))
    }
};

export const BoardExportSettingsHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(BoardExportSettings);

BoardExportSettingsHOC.displayName = 'BoardExportSettingsHOC';
