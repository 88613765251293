import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { loadThemes } from '../../../../../../../../../rest/effects/themes/loadThemes';

export const onDidMount = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        dispatch(loadThemes());
    };
    return action;
};
