import { connect } from 'react-redux';
import { TimingTab } from '../../components/TimingTab/TimingTab';
import { ITimingTabFields } from '../../components/TimingTab/types';
import { IApplicationState } from '../../../../../../../types/types';
import { getCard } from '../../../../../../../store/model/selectors/getCard';
import { ITabTimingHOCProps } from './types';
import {getBoardPermissionsAllowEdit} from '../../../../../../../store/model/selectors/getBoardPermissionsAllowEdit';
import {TStatus} from '../../../../../../../types/model';

const mapStateToProps = (
    state: IApplicationState,
    ownProps: ITabTimingHOCProps
): ITimingTabFields => {
    const { boardId, cardId } = ownProps;
    let isShow = false;
    const card = getCard(state, cardId);
    let isShowTimeTracker = true;

    if (card) {
        isShow = true;
    }

    if (
        card.status === TStatus.STATUS_ARCHIVE
        || !getBoardPermissionsAllowEdit(state, boardId)
    ) {
        isShowTimeTracker = false;
    }

    return {
        isShow,
        cardId,
        boardId,
        isShowTimeTracker
    }
};

export const TimingTabHOC = connect(mapStateToProps, null)(TimingTab);
