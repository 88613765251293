import { ITopBarProps } from './types';
import * as React from 'react';
import { TopBarDashboardNameHOC } from '../../hocs/TopBarDashboardNameHOC/TopBarDashboardNameHOC';
import { TopBarFilterButtonHOC } from '../../hocs/TopBarFilterButtonHOC/TopBarFilterButtonHOC';
import { TopBarInfoBlockHOC } from '../../hocs/TopBarInfoBlockHOC/TopBarInfoBlockHOC';
import {
    TopBarSharingHOC as TopBarSharingHOCGoogle
} from '../../hocs/sharing/google/TopBarSharingHOC/TopBarSharingHOC';
import {
    TopBarSharingHOC as TopBarSharingHOCMicrosoft
} from '../../hocs/sharing/microsoft/TopBarSharingHOC/TopBarSharingHOC';
import './_TopBar.scss';
import {
    NavigationPanelAnchorMobileHOC
} from '../../../navigationPanel/hocs/NavigationPanelAnchorMobileHOC/NavigationPanelAnchorMobileHOC';
import { FilterInfoPanelHOC } from '../../../../aside_panel/filterInfoPanel/hocs/FilterInfoPanelHOC';
import { TopBarCollaboratorsHOC } from '../../hocs/TopBarCollaboratorsHOC/TopBarCollaboratorsHOC';
import { MyWorkTopBarHOC } from '../../hocs/MyWorkTopBarHOC/MyWorkTopBarHOC';
import { KanbanTitleHOC } from '../../../kanbanTitle/hocs/KanbanTitleHOC';
// @ts-ignore
import Tappify from 'tappify';
import { TypeSwitcherHOC } from '../../../../typeSwitcher/hocs/TypeSwitcherHOC/TypeSwitcherHOC';
import {
    KeyboardShortcutsOpenerHOC
} from '../../../../keyboardShortcuts/hocs/KeyboardShortcutsOpenerHOC/KeyboardShortcutsOpenerHOC';
import { TOP_BAR_CLASS } from '../../constants';
import { EAuthUserPlatformType } from 'app/types/rest/IRestAuthUser';
import { ViewOnlyHOC } from 'app/view/react_components/base/hocs/ViewOnlyHOC/ViewOnlyHOC';
import { ProfileHOC } from '../../../../profile/hocs/ProfileHOC/ProfileHOC';
import { EProfileUserWhere } from '../../../../profile/constants';

export function TopBar ({
    boardId,
    boardName,
    hasOpenedBoard,
    isShowMetrics,
    isAllowShare,
    isAssignedToMe,
    isFilterInfoPanel,
    isReadonly,
    isShow,
    platformType,
}: ITopBarProps) {
    let TopBarSharingHOC;
    switch (platformType) {
        case EAuthUserPlatformType.MICROSOFT:
            TopBarSharingHOC = TopBarSharingHOCMicrosoft;
            break;
        default:
            TopBarSharingHOC = TopBarSharingHOCGoogle;
    }

    const className = 'topbar__inner';
    const classNameNoboard = !hasOpenedBoard && !isAssignedToMe ? className + '--noboard' : '';
    const classNameIsFilter = isFilterInfoPanel ? className + '--filter' : '';

    React.useEffect(() => {
        const unsubscribeTappify = Tappify.subscribe((isCursor: boolean) => {
            if (isCursor) {
                document.documentElement.classList.remove('touch');
                document.documentElement.classList.add('no-touch');
            } else {
                document.documentElement.classList.add('touch');
                document.documentElement.classList.remove('no-touch');
            }
        });

        return () => {
            unsubscribeTappify();
        }
    }, []);

    React.useEffect(() => {
        const topBar = document.querySelector('.' + TOP_BAR_CLASS) as HTMLElement;
        if (topBar) {
            topBar.style.display = isShow ? 'flex' : 'none';
        }
    }, [isShow]);

    return <>
        <div className={`${className} ${classNameNoboard} ${classNameIsFilter}`}>
            <KanbanTitleHOC />
            <div
                className="topbar__widgets-block topbar__widgets-block--left"
                key={`topbar__widgets-block--left__${boardId}__${boardName}`}
            >
                <div
                    className="topbar__widgets-block-content"
                >
                    {!isAssignedToMe &&
                        <NavigationPanelAnchorMobileHOC/>
                    }
                    {hasOpenedBoard
                        ? <React.Fragment>
                            <TopBarDashboardNameHOC key={boardId} boardId={boardId}/>
                            <TypeSwitcherHOC boardId={boardId} />
                            <TopBarFilterButtonHOC boardId={boardId}/>
                            <FilterInfoPanelHOC boardId={boardId}/>
                            {isShowMetrics &&
                                <TopBarInfoBlockHOC  boardId={boardId}/>
                            }
                        </React.Fragment>
                        : isAssignedToMe
                            ? <MyWorkTopBarHOC />
                            : null
                    }
                </div>
            </div>
            <div
                className="topbar__widgets-block topbar__widgets-block--right"
            >
                {isAssignedToMe && <ProfileHOC profileUserWhere={EProfileUserWhere.PROFILE} tooltipDirection={'down-right'} />}
                {hasOpenedBoard &&
                    <React.Fragment>
                        <TopBarCollaboratorsHOC boardId={boardId} />
                        {isAllowShare
                            ? <TopBarSharingHOC boardId={boardId}/>
                            : <ViewOnlyHOC boardId={boardId} />
                        }
                    </React.Fragment>
                }
                <Tappify.Finger />
                <Tappify.Cursor />

                <KeyboardShortcutsOpenerHOC />
            </div>
        </div>
    </>;
};
