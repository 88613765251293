import * as React from 'react';
import {ITabBackupsProps} from './types';
import {AutoBackupsHOC} from '../../../AutoBackups/hocs/AutoBackupsHOC/AutoBackupsHOC';
import {BackupsTextHOC} from '../../../BackupsText/hocs/BackupsTextHOC/BackupsTextHOC';
import {BoardBackupsSectionHOC} from '../../../Backups/hocs/BoardBackupsSectionHOC/BoardBackupsSectionHOC';

export function TabBackups({
    boardId
}: ITabBackupsProps) {
    return (<div>
        <BackupsTextHOC boardId={boardId} />
        <AutoBackupsHOC boardId={boardId} />
        <BoardBackupsSectionHOC boardId={boardId}/>
    </div>)
}
