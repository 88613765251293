import { AT_DEPENDENCY_SET, ITypeDependencySetAction } from './types';
import { IDependency } from '../../dependency/types';

export const dependencySetAction = (
    dependencyId: number,
    dependency: IDependency
): ITypeDependencySetAction => {
    return {
        type: AT_DEPENDENCY_SET,
        dependencyId,
        dependency
    }
};
