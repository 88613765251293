import { connect } from 'react-redux';
import { PromoCodePopup } from '../../components/PromoCodePopup/PromoCodePopup';
import { IPromoCodePopupEvents, IPromoCodePopupFields } from '../../components/PromoCodePopup/types';
import { IApplicationState } from '../../../../../types/types';
import { getUserPromoCodeDiscount } from '../../../../../store/model/authUser/selectors/getUserPromoCodeDiscount';
import { onPurchase } from './events/onPurchase';
import { onLater } from './events/onLater';

const mapStateToProps = (
    state: IApplicationState,
): IPromoCodePopupFields => {
    return {
        discount: getUserPromoCodeDiscount(state),
    };
}

const mapDispatchToProps = (
    dispatch: any,
): IPromoCodePopupEvents => {
    return {
        onLater: () => dispatch(onLater()),
        onPurchase: () => dispatch(onPurchase()),
    };
}

export const PromoCodePopupHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(PromoCodePopup);

PromoCodePopupHOC.displayName = 'PromoCodePopupHOC';
