import { EPushNotificationSettingsTypes } from '../../../../../../../../../types/rest/IRestPushNotificationSettings';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { notifyTypeValueMap } from '../constants';
import { ISegmentEventOption } from '../../../../../../../../../middlewares/segment/trackEntities/types';
import {
    SegmentBoardEvent,
    SegmentBoardOption,
    SegmentBoardOptionStatusValue
} from '../../../../../../../../../middlewares/segment/trackEntities/boardEvents';
import { segmentTrackAction } from '../../../../../../../../../middlewares/segment';

export const onNotifyTypeClick = (
    scope: EPushNotificationSettingsTypes,
    checked: boolean
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        if (notifyTypeValueMap[scope]) {
            const option: ISegmentEventOption = {
                name: SegmentBoardOption.STATUS,
                value: checked ? SegmentBoardOptionStatusValue.ON: SegmentBoardOptionStatusValue.OFF
            }
            dispatch(segmentTrackAction(SegmentBoardEvent.NOTIFY_ME_WHEN_CLICKED, option,
                [{name: SegmentBoardOption.TYPE, value: notifyTypeValueMap[scope]}]));
        }
    };
    return action;
};
