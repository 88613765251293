import { IApplicationState, TBoardId } from '../../../types/types';
import { TStatus } from '../../../types/model';
import { ICard } from '../card/types';
import { getBoardCardsByStatuses } from './getBoardCardsByStatuses';

export const getBoardCards = (
    state: IApplicationState,
    boardId: TBoardId,
    status: TStatus = TStatus.STATUS_ACTIVE,
): ICard[] => {
    return getBoardCardsByStatuses(state, boardId, [status])
};
