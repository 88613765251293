import { IGetState, TBoardId } from '../../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { getBoardCardAgingSettings } from '../../../../../../../../../store/model/board/selectors/getBoardCardAgingSettings';
import { updateSettings } from '../effectts/updateSettings';
import {
    getBoardCardAgingOpacity
} from '../../../../../../../../../store/model/board/selectors/getBoardCardAgingOpacity';
import { SegmentBoardEvent, segmentTrackAction } from '../../../../../../../../../middlewares/segment';
import {
    SegmentBoardOptionStatusValue
} from '../../../../../../../../../middlewares/segment/trackEntities/boardEvents';

export const onToggleOpacity = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const cardAgingSettings = getBoardCardAgingSettings(state, boardId);
        const opacity = !getBoardCardAgingOpacity(state, boardId);
        dispatch(updateSettings(boardId, {
            ...cardAgingSettings,
            opacity
        }));
        dispatch(segmentTrackAction(SegmentBoardEvent.CARD_AGING_OPACITY_CLICKED, {
            name: 'status',
            value: opacity ? SegmentBoardOptionStatusValue.ON : SegmentBoardOptionStatusValue.OFF
        }));
    };
    return action;
};
