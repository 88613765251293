import { IGetState, TBoardId } from '../../types/types';
import { Dispatch } from '../../types/actions';
import { getBoard } from '../../store/model/selectors/getBoardById';
import { IBoard } from '../../store/model/board/types';
import { EPhase, IExportListener, IStartResult } from './types';
import { continueExport, finishExport, startExport } from './helpers';

const CARDS_MAX_HANDLE_TIME = 30000; // 30 sec
const REQUEST_HANDLE_CARD_COUNT_DEFAULT = 10;
const REQUEST_HANDLE_CARD_COUNT_MIN = 5;

export const exportToSpreadsheet = (
    boardId: TBoardId,
    withArchive: boolean,
    listener: IExportListener
) => {
    return (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const board = getBoard(state, boardId);
        startExport(
            state,
            board,
            listener,
            '/rest/dashboard/export/start/',
            withArchive ? 'Active and archived data' : 'Active data',
            {
                withArchive: withArchive
            }
        ).then((result) => {
            if (!result) return;
            listener.onProgress(Math.round((1 / (result.listIds.length + 2)) * 100.0));

            exportCards(
                dispatch,
                board,
                result,
                withArchive,
                listener
            )
        })
    };
}

const exportCards = (
    dispatch: Dispatch,
    board: IBoard,
    startResult: IStartResult,
    withArchive: boolean,
    listener: IExportListener,
): void => {
    let requestHandleCardCount = REQUEST_HANDLE_CARD_COUNT_DEFAULT;
    const doExport = (
        phase: EPhase,
        progress: number = 0,
        currentListIndex: number = 0,
        cursor: string = ''
    ) => {
        const archive = phase === EPhase.ARCHIVE_CARDS;
        const requestStartTime = new Date().getTime();
        continueExport(
            startResult,
            listener,
            '/rest/dashboard/export/cards/',
            JSON.stringify(board.colors),
            {
                listId: startResult.listIds[currentListIndex],
                cursor: encodeURI(cursor),
                archive,
                handleCardCount: requestHandleCardCount
            }
        ).then((result) => {
            if (!result) return;
            // requestHandleCardCount = getRequestHandleCardCount(requestStartTime, result.saveCardCount, requestHandleCardCount);
            if (result.completed) {
                cursor = '';
                currentListIndex += 1;
                progress += 1;
                const summaryIteration = withArchive ? startResult.listIds.length * 2 : startResult.listIds.length;
                listener.onProgress(Math.round(((progress) / (summaryIteration)) * 100.0));

                if (currentListIndex >= startResult.listIds.length) {
                    if (phase === EPhase.ACTIVE_CARDS) {
                        return finishExport(startResult, '/rest/dashboard/export/finish', listener, board.id, dispatch);
                    } else {
                        phase = EPhase.ACTIVE_CARDS;
                        currentListIndex = 0;
                    }
                }
            } else {
                cursor = result.cursor;
            }
            doExport(
                phase,
                progress,
                currentListIndex,
                cursor
            )
        })
    }
    doExport(withArchive ? EPhase.ARCHIVE_CARDS : EPhase.ACTIVE_CARDS);
}

const getRequestHandleCardCount = (
    requestStartTime: number,
    saveCardCount: number,
    previousResult: number
) => {
    if (saveCardCount < REQUEST_HANDLE_CARD_COUNT_MIN) {
        return previousResult; // использовать прошлое значение если карт было слишком мало обработано
    }
    const requestEndTime = new Date().getTime();
    const requestTotalTime = requestEndTime - requestStartTime;
    const cardHandleTime = requestTotalTime / saveCardCount;
    const result = Math.round(CARDS_MAX_HANDLE_TIME / cardHandleTime);
    return result < REQUEST_HANDLE_CARD_COUNT_MIN ? REQUEST_HANDLE_CARD_COUNT_MIN : result;
}
