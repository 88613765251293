import {IApplicationState} from 'app/types/types';
import {connect} from 'react-redux';
import {BoardImport} from 'app/view/react_components/aside_panel/boardDetails/details/ImportBoard/components/BoardImport/BoardImport';
import {
    IBoardImportEvents,
    IBoardImportFields
} from 'app/view/react_components/aside_panel/boardDetails/details/ImportBoard/components/BoardImport/types';
import {IBoardImportHOCProps} from 'app/view/react_components/aside_panel/boardDetails/details/ImportBoard/hocs/BoardImportHOC/types';
import {onClick} from './events/onClick';
import { getPanelReadonly } from 'app/view/react_components/aside_panel/boardDetails/selectors/getPanelReadonly';

const mapStateToProps = (
    state: IApplicationState,
    ownProps: IBoardImportHOCProps
): IBoardImportFields => {
    let title = '';
    const { boardId } = ownProps;
    const isReadonly = getPanelReadonly(state, boardId);
    return {
        title,
        isReadOnly: isReadonly
    }
};

const mapDispatchToProps = (
    dispatch: any,
    {boardId}: IBoardImportHOCProps
): IBoardImportEvents => {
    return {
        onClick: () => dispatch(onClick(boardId))
    }
};

export const BoardImportHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(BoardImport);

BoardImportHOC.displayName = 'BoardImportHOC';
