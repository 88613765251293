import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { boardsListGoogleAnalyticsEvents, BOARDS_LIST_LOAD_FAIL } from './googleAnalyticsEvents';
import { snackbarErrorReload } from '../../../snackbarsStack';

export const onLoadBoardsFail: ActionCreator<ThunkAction> =
() => {
    const action = (dispatch: Dispatch) => {
        dispatch(snackbarErrorReload());
        dispatch(boardsListGoogleAnalyticsEvents(BOARDS_LIST_LOAD_FAIL));
    };
    return action;
};
