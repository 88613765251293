import * as React from 'react';
import { IBlankerProps } from './types';
import './_Blanker.scss';
import { getMessages } from '../../../../../store/constants';
import { Button, Icon, TButtonVariant } from 'kui';
import { getCardFullNameHelper } from '../../../../../store/model/card/helpers/getCardFullNameHelper';

export const Blanker = ({
    isShow,
    recentBoard,
    inWorkCard,
    onBoardOpen,
    onCardOpen
}: IBlankerProps) => {
    if (!isShow) return;

    const className = 'blanker';
    const openBoardVariant: TButtonVariant = !!inWorkCard ? 'secondary' : 'primary';

    return (
        <div className={className}>
            <div className={`${className}__content`}>
                {!recentBoard && !inWorkCard &&
                    <Icon
                        className={`${className}__icon`}
                        size={96}
                        xlink={'e-board'}
                    />
                }
                <div className={`${className}__title`}>
                    {getMessages().getText('nav_panel.no_boards.title')}
                </div>
                <div className={`${className}__buttons`}>
                    {!!inWorkCard &&
                        <Button
                            onClick={() => onCardOpen(inWorkCard)}
                            size={'large'}
                        >
                            {getMessages().getText('nav_panel.no_boards.open_card', null, getCardFullNameHelper(inWorkCard))}
                        </Button>
                    }
                    {!!inWorkCard && !!recentBoard && <div className={`${className}__buttons-or`}>or</div>}
                    {!!recentBoard &&
                        <Button
                            size={'large'}
                            variant={openBoardVariant}
                            onClick={() => onBoardOpen(recentBoard.id)}
                        >
                            {getMessages().getText('nav_panel.no_boards.open_board', null, recentBoard.name)}
                        </Button>
                    }
                </div>
                <div className={`${className}__text`}>{getMessages().getText('nav_panel.no_boards.text')}</div>
            </div>
        </div>
    )
};
