import { AT_IGNORE_CARD_ID_ADD } from './constants';
import { IIgnoreCardIdAddAction } from './types';
import { TCardId } from '../../../../../../../types/types';

export const ignoreCardIdAddAction = (
    cardId: TCardId
): IIgnoreCardIdAddAction => ({
        type: AT_IGNORE_CARD_ID_ADD,
        cardId
    }
);
