import { IGetState, TBoardId, TListId } from '../../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { EDashboardStatusListType } from '../../../../../../../../../types/rest/IRestDashboard';
import { saveBoardStatusList } from '../effects/saveBoardStatusList';
import { SegmentBoardEvent, segmentTrackAction } from '../../../../../../../../../middlewares/segment';
import { TSegmentTrackPayload } from '../../../../../../../../../middlewares/segment/trackEntities/types';
import { getAuthUser } from '../../../../../../../../../store/model/authUser/selectors/getAuthUser';
import { showSnoozeBlocker } from '../../../../../../../base/effects/showSnoozeBlocker';
import { STATUS_LIST_ARCHIVE_LIST_ID } from '../../../../../../../../../const';
import { SegmentBoardDoneListType } from '../../../../../../../../../middlewares/segment/trackEntities/boardEvents';

export const onListChange = (
    boardId: TBoardId,
    listType: EDashboardStatusListType,
    listId: TListId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const { allowArchive } = getAuthUser(getState());
        if (!allowArchive && listId === STATUS_LIST_ARCHIVE_LIST_ID) {
            dispatch(showSnoozeBlocker({
                allow: 'allowArchive'
            }));
        } else {
            if (listTypeEventMap.get(listType)) {
                dispatch(segmentTrackAction(
                    listTypeEventMap.get(listType),
                    listType === EDashboardStatusListType.DONE ?
                        {
                            name: 'type',
                            value: listId === STATUS_LIST_ARCHIVE_LIST_ID ?
                                SegmentBoardDoneListType.ARCHIVE
                                : SegmentBoardDoneListType.LIST
                        } : null
                ));
            }
            dispatch(saveBoardStatusList(boardId, listType, listId));
        }
    };
    return action;
};

const listTypeEventMap: Map<EDashboardStatusListType, TSegmentTrackPayload> =
    new Map<EDashboardStatusListType, TSegmentTrackPayload>([
        [EDashboardStatusListType.ON_HOLD, SegmentBoardEvent.ON_HOLD_LIST_CHANGED],
        [EDashboardStatusListType.IN_WORK, SegmentBoardEvent.WORKING_LIST_CHANGED],
        [EDashboardStatusListType.DONE, SegmentBoardEvent.DONE_LIST_CHANGED]
    ]);
