import * as React from 'react';
import { IUsersTimingProps } from './types';
import { CLASS_CARD_DETAILS_TAB_TIMING } from '../TimingTab/constants';
import './_UsersTiming.scss';
import { UserpicStorage } from 'app/view/react_components/base/components/UserpicStorage/UserpicStorage';
import { AnalyzeRender } from '../../../../../helpers/memoizeHelper';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';

//@ts-ignore
const analyzeRender = window.Settings.development ? new AnalyzeRender(`UsersTiming`) : null;

export function UsersTiming({
    usersSpentTime
}: IUsersTimingProps) {
    const { cardId } = React.useContext(AsidePanelContext);

    if (analyzeRender) analyzeRender.call(`UsersTiming ${cardId}`);

    const className = CLASS_CARD_DETAILS_TAB_TIMING + '__users';
    const classNameUser = CLASS_CARD_DETAILS_TAB_TIMING + '__user';
    const classNameUserPic = CLASS_CARD_DETAILS_TAB_TIMING + '__user-pic';
    const classNameUserName = CLASS_CARD_DETAILS_TAB_TIMING + '__user-name';
    const classNameUserTime = CLASS_CARD_DETAILS_TAB_TIMING + '__user-time';

    return (
        <div className={className}>
            {usersSpentTime.map((user) => {
                    return <div
                        className={classNameUser}
                        key={user.hash}
                    >
                        <UserpicStorage
                            className={classNameUserPic}
                            initials={user.initials}
                            src={user.userPicUrl}
                            size={32}
                            userId={user.userId}
                        />
                        <div className={classNameUserName}>
                            {user.fullName}
                        </div>
                        <div className={classNameUserTime}>
                            {user.spentTime}
                        </div>
                    </div>
            })}
        </div>
    );
}
