import { IGetState, TBoardId } from '../types/types';
import { Dispatch, ThunkAction } from '../types/actions';
import { getBoard } from '../store/model/selectors/getBoardById';
import { getMessages, root } from '../store/constants';

export const openBoardForm = (
    boardId: TBoardId,
    scrollElem: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const board = getBoard(getState(), boardId);
        if (board) {
            root.App.controller.asidePanel.showBoard(boardId, scrollElem);
        } else {
            root.App.controller.showError(getMessages().getText('error.record.not_found'));
        }
    };
    return action;
}
