import { IAuthenticateCommand, IFilePickerOptions, IPickData, } from './types.js';

import { isMicrosoftPersonalAccount } from 'app/store/model/authUser/selectors/isMicrosoftPersonalAccount';
import { getAuthUser } from 'app/store/model/authUser/selectors/getAuthUser';
import { getAppState } from 'app/store/configureStore';
import { combine } from 'app/helper/authorisation/microsoft/filePicker/utils';
import { getToken } from 'app/helper/authorisation/microsoft/filePicker/auth';
import './_filePicker.scss';
import { DriveItem } from '@microsoft/microsoft-graph-types';

export type TokenFactory = (command: IAuthenticateCommand) => Promise<string>;

export interface IBasePickerInit {
    tokenFactory: TokenFactory,
    options: IFilePickerOptions,
}

export interface IOneDriveConsumerInit extends IBasePickerInit {
    type: 'Consumer',
}

export interface IODSPInit extends IBasePickerInit {
    type: 'ODSP',
    baseUrl: string,
}

/**
 * Initializes and loads a new file picker into the provided window using the supplied init
 *
 * @param win The window (ifram/pop-up) into which the file picker will be loaded
 * @param init The initialization used to create the file picker
 * @param onClose
 */
export function Picker(
    win: Window,
    init: IOneDriveConsumerInit | IODSPInit,
    onClose: ()=>void,
) {
    return new Promise<DriveItem[]>((resolve) => {
        // this is the port we'll use to communicate with the picker
        let port: MessagePort;

        // we default to the consumer values since they are fixed
        const baseUrl = init.type === 'ODSP' ? init.baseUrl : 'https://onedrive.live.com';
        const pickerPath = combine(baseUrl, init.type === 'ODSP' ? '_layouts/15/FilePicker.aspx' : 'picker');

        // grab the things we need from the init
        const {tokenFactory, options} = init;

        // define the message listener to process the various messages from the window
        async function messageListener(message: MessageEvent): Promise<void> {

            switch (message.data.type) {

                case 'notification':

                    break;

                case 'command':

                    port.postMessage({
                        type: 'acknowledge',
                        id: message.data.id,
                    });

                    const command = message.data.data;

                    switch (command.command) {

                        case 'authenticate':

                            tokenFactory(command).then(token => {
                                if (typeof token !== 'undefined') {

                                    port.postMessage({
                                        type: 'result',
                                        id: message.data.id,
                                        data: {
                                            result: 'token',
                                            token,
                                        },
                                    });
                                }
                            });

                            break;

                        case 'close':

                            win.close();
                            onClose();
                            resolve(null as DriveItem[]);

                            port.postMessage({
                                type: 'result',
                                id: message.data.id,
                                data: {
                                    result: 'success',
                                },
                            });

                            break;

                        case 'pick':

                            win.close();
                            onClose();
                            resolve(message && message.data && message.data.data &&  message.data.data.items as DriveItem[]);

                            port.postMessage({
                                type: 'result',
                                id: message.data.id,
                                data: {
                                    result: 'success',
                                },
                            });

                            break;

                        default:

                            console.warn(`Unsupported picker command: ${JSON.stringify(command)}`);

                            // let the picker know we don't support whatever command it sent
                            port.postMessage({
                                result: 'error',
                                error: {
                                    code: 'unsupportedCommand',
                                    message: command.command
                                },
                                isExpected: true,
                            });
                            break;
                    }

                    break;
            }
        }

        // attach a listener for the message event to setup our channel
        const listener = (event: MessageEvent<any>) => {
            if (event.source && event.source === win) {
                const message = event.data;
                if (message.type === 'initialize' && message.channelId === options.messaging.channelId) {
                    window.removeEventListener('message', listener);
                    port = event.ports[0];
                    port.addEventListener('message', messageListener);
                    port.start();
                    port.postMessage({
                        type: 'activate',
                    });
                }
            }
        };
        window.addEventListener('message', listener);

        const queryString = new URLSearchParams({
            filePicker: JSON.stringify(options),
        });

        const url = `${pickerPath}?${queryString}`;

        // now we post a form into the window to load the picker with the options
        const form = win.document.createElement('form');
        form.setAttribute('action', url);
        form.setAttribute('method', 'POST');
        win.document.body.append(form);
        init.tokenFactory({
            command: 'authenticate',
            resource: baseUrl,
        }).then(authToken => {
            if (authToken !== null) {

                const input = win.document.createElement('input');
                input.setAttribute('type', 'hidden')
                input.setAttribute('name', 'access_token');
                input.setAttribute('value', authToken);
                form.appendChild(input);
            }

            // this will load the picker into the window
            form.submit();
        })
    });
}

const options: IFilePickerOptions = {
    sdk: '8.0',
    entry: {
        oneDrive: {},
        sharePoint: {}
    },
    authentication: {},
    messaging: {
        origin: window.location.origin,
        channelId: '27'
    },
    selection: {
        mode: 'multiple',
    },
    //@ts-ignore
    search: {
        enabled: true
    },
    typesAndSources: {
        // filters: ['.docx'],
        mode: 'files',
        // pivots: {
        //   recent: false,
        //   oneDrive: false,
        //   sharedLibraries: false,
        //   shared: false,
        //   search: false,
        // }
    },
};

function getOneDriveInit(
    microsoftPersonalAccount: boolean,
    baseUrl: string,
    loginHint: string,
    multiple: boolean = true,
    filters?: string[]
): IOneDriveConsumerInit | IODSPInit {
    let opt = {...options};
    if (filters){
        opt.typesAndSources.filters=filters
    }
    if (!multiple){
        opt.selection = {mode:'single'};
    }
    if (microsoftPersonalAccount) {
        const init: IOneDriveConsumerInit = {
            type: 'Consumer',
            options: opt,
            tokenFactory: (command: IAuthenticateCommand) => getToken({
                ...command,
                type: 'Graph'
            }, loginHint),
        };

        return init;

    } else {
        const init: IODSPInit = {
            type: 'ODSP',
            baseUrl,
            options: opt,
            tokenFactory: (command: IAuthenticateCommand) => getToken({
                ...command,
                type: 'SharePoint'
            }, loginHint),
        };
        return init;
    }

}

export class MSFilePicker {
    picker: Promise<DriveItem[]>;
    el: Window;

    /***
     * init filePicker
     * @param multiple
     * @param filters -  for example ['.docx']
     */
    async init (
        multiple: boolean = true,
        filters?: string[]
    ) {
        if (this.el) this.remove();
        this.el = window.open('', 'Picker', 'width=800,height=600');
        // this.el = document.createElement('div');
        // this.el.className = 'ms-file-picker';
        // let ifrm = document.createElement('iframe');
        // this.el.appendChild(ifrm);
        // document.body.append(this.el);

        const state = getAppState();
        let user = getAuthUser(state);
        const microsoftPersonalAccount = isMicrosoftPersonalAccount(state);
        const baseUrl = `https://${user.driveDomain}`;
        const init = getOneDriveInit(microsoftPersonalAccount, baseUrl, user.email, multiple, filters);

        // return Picker(window.open('', 'Picker', 'width=800,height=600'), init);

        this.picker = Picker(this.el, init, ()=>this.remove());

        return this.picker;
    }

    remove () {
        if (!this.el) return;

        this.el.close();
        this.el = null;
    }
}

export const msFilePickerInstance = new MSFilePicker();
