import { root } from '../../../../../../../store/constants';
import { IGetState } from '../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../types/actions';

export const onClickBack = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        root.App.controller.mainView.accessibilityStatement.remove();
        root.App.router.navigate(root.App.router.getUrl(null), {trigger: true});
    };
    return action;
};
