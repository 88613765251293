import { Action, Store } from 'redux';
import { Dispatch, ISubscriptionAdminState } from '../types';
import { getNumberKeys } from '../../../../../../helper/getNumberKeys';
import { getSubscriptions } from '../../subscriptions/selectors/getSubscriptions';
import { getSubscription } from '../../subscription/selectors/getSubscription';
import { ERealtimeClassKeys, RAT_REALTIME_ACTION_SEND } from '../../../../base/helpers/realTimeHelperTS';
import { subscriptionsActionSetAction } from '../actions/subscriptionsActionSetAction';
import { subscriptionsUpdateAction } from '../../subscriptions/actions/subscriptionsUpdateAction';
import { IRoomSubscriber } from 'app/helper/realtime/ts/Room';
import { RoomFactory } from 'app/helper/realtime/ts/RoomFactory';
import { dispatch } from '../../../../../../store/configureStore';
import { loadUser } from '../../../../../../rest/effects/authUser/loadUser';
import { IAuthUser } from '../../../../../../store/model/authUser/types';

let listener: SubscriptionListener = null;
const getListener = (dispatch: Dispatch): SubscriptionListener => {
    if (!listener) listener = new SubscriptionListener(dispatch);
    return listener;
}

type TMessage = {
    classKey: string;
    action: Action;
    model: any
}
class SubscriptionListener implements   IRoomSubscriber{
    dispatch: Dispatch;

    constructor (dispatch: Dispatch) {
        this.dispatch = dispatch;
    }
    updateModelByObj(message: TMessage) {
        if (message.classKey === 'user') {
            dispatch(loadUser()).then((user: IAuthUser) => {
                user.managedSubscription.forEach(subscription => { // обновляем данные подписки (название и т.д.)
                    this.dispatch(subscriptionsActionSetAction(subscriptionsUpdateAction({
                        [subscription.id]: subscription
                    })));
                });
            });
        }
        if (message.classKey === RAT_REALTIME_ACTION_SEND) {
            this.dispatch(message.action);
        }
        if (message.classKey === ERealtimeClassKeys.SUBSCRIPTION) { // нужен ли? тоже самое вроде в 'user' приходит
            const subscription =  message.model.subscription;
            this.dispatch( subscriptionsActionSetAction(subscriptionsUpdateAction({
                [subscription.id]: subscription
            })));
        }
    }
}

export const realtimeMiddleware  = (store: Store<ISubscriptionAdminState>) => (next: any) => (action: any) => {
    const oldIds = getNumberKeys(getSubscriptions(store.getState()));
    const result = next(action);
    const newIds = getNumberKeys(getSubscriptions(store.getState()));

    // подписаться на новые
    newIds.forEach(subscriptionId => {
        if (!oldIds.includes(subscriptionId)) {
            RoomFactory.getInstance().getSubscriptionRoom(subscriptionId, getListener(store.dispatch), getSubscription(store.getState(), subscriptionId).cometToken);
        }
    });

    //закрыть старые
    oldIds.forEach(subscriptionId => {
        if (!newIds.includes(subscriptionId)) {
            const socket = RoomFactory.getInstance().getCachedSubscriptionRoom(subscriptionId);
            if (socket) socket.removeListener(getListener(store.dispatch));
        }
    })
    return result;
}
