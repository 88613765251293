import * as React from 'react';
import { IPanelActionsProps } from './types';
import {
    ASIDE_PANEL_ACTIONS_SECTION_TITLE_ACTIONS,
    AsidePanelActionsButtonsGroup,
    AsidePanelActionsSection
} from '../../../../index';
import './_PanelActions.scss';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { EBoardTab } from '../../../types';
import {
    ASIDE_PANEL_ACTIONS_SECTION_TITLE_SETTINGS
} from '../../../../asidePanel/components/AsidePanelActions/constants';
import { DeleteButtonHOC } from '../../../actions/hocs/DeleteButtonHOC/DeleteButtonHOC';
import { PrefixesPanelButtonHOC } from '../../../details/CardNumber/hocs/PrefixesPanelButtonHOC/PrefixesPanelButtonHOC';
import {
    EmailNotificationsPanelButtonHOC
} from '../../../details/Notifications/hocs/EmailNotificationsPanelButtonHOC/EmailNotificationsPanelButtonHOC';
import {
    PushNotificationsPanelButtonHOC
} from '../../../details/Notifications/hocs/PushNotificationsPanelButtonHOC/PushNotificationsPanelButtonHOC';
import { ColorsPanelButtonHOC } from '../../../details/Colors/hocs/ColorsPanelButtonHOC/ColorsPanelButtonHOC';
import { GanttPanelButtonHOC } from '../../../details/ShowOnGantt/hocs/GanttPanelButtonHOC/GanttPanelButtonHOC';
import {
    LinksToCardsPanelButtonHOC
} from '../../../details/LinksToCards/hocs/LinksToCardsPanelButtonHOC/LinksToCardsPanelButtonHOC';
import { TimeZonePanelButtonHOC } from '../../../details/TimeZone/hocs/TimeZonePanelButtonHOC/TimeZonePanelButtonHOC';
import {
    BoardEmailPanelButtonHOC
} from '../../../details/Email/hocs/BoardEmailPanelButtonHOC/BoardEmailPanelButtonHOC';
import { CopyButtonHOC } from '../../../actions/hocs/CopyButtonHOC/CopyButtonHOC';
import { ArchiveButtonHOC } from '../../../actions/hocs/ArchiveButtonHOC/ArchiveButtonHOC';
import { UnarchiveButtonHOC } from '../../../actions/hocs/UnarchiveButtonHOC/UnarchiveButtonHOC';
import { CreateTemplateButtonHOC } from '../../../actions/hocs/CreateTemplateButtonHOC/CreateTemplateButtonHOC';
import {
    StatusListsPanelButtonHOC
} from '../../../details/StatusLists/hocs/StatusListsPanelButtonHOC/StatusListsPanelButtonHOC';
import {
    ArchiveOnDeletePanelButtonHOC
} from '../../../details/ArchiveOnDelete/hocs/ArchiveOnDeletePanelButtonHOC/ArchiveOnDeletePanelButtonHOC';
import {
    CardPropertiesPanelButtonHOC
} from '../../../details/CardProperties/hocs/CardPropertiesPanelButtonHOC/CardPropertiesPanelButtonHOC';
import { OpenInDriveButtonHOC } from '../../../actions/hocs/OpenInDriveButtonHOC/OpenInDriveButtonHOC';
import {
    CardCustomPropertiesPanelButtonHOC
} from '../../../details/CardCustomProperties/hocs/CardCustomPropertiesPanelButtonHOC/CardCustomPropertiesPanelButtonHOC';
import { RolesPanelButtonHOC } from '../../../details/Roles/hocs/RolesPanelButtonHOC/RolesPanelButtonHOC';
import {
    EstimateTimePanelButtonHOC
} from '../../../details/EstimateTime/hocs/EstimateTimePanelButtonHOC/EstimateTimePanelButtonHOC';
import { TagsPanelButtonHOC } from '../../../details/Tags/hocs/TagsPanelButtonHOC/TagsPanelButtonHOC';
import {
    CLASS_ASIDE_PANEL_ACTIONS_SECTION_ACTIONS,
    CLASS_ASIDE_PANEL_ACTIONS_SECTION_ADD_PROPERTIES,
    CLASS_ASIDE_PANEL_ACTIONS_SECTION_CONTAINER,
    CLASS_ASIDE_PANEL_ACTIONS_SECTION_TITLE,
    CLASS_ASIDE_PANEL_ACTIONS_SECTION_TITLE_SECOND
} from '../../../../asidePanel/components/AsidePanelActionsSection/constants';
import {
    PwcImportPanelButtonHOC
} from '../../../details/PwcImport/hocs/PwcImportPanelButtonHOC/PwcImportPanelButtonHOC';
import {
    BoardImportPanelButtonHOC
} from '../../../details/ImportBoard/hocs/BoardImportPanelButtonHOC/BoardImportPanelButtonHOC';
import { MoveToButtonHOC } from '../../../actions/hocs/MoveToButtonHOC/MoveToButtonHOC';
import { DatesPanelButtonHOC } from '../../../details/Dates/hocs/DatesPanelButtonHOC/DatesPanelButtonHOC';
import {
    CardAgingPanelButtonHOC
} from '../../../details/CardAging/hocs/CardAgingPanelButtonHOC/CardAgingPanelButtonHOC';

export function PanelActions({
    boardId,
    isShow,
}: IPanelActionsProps) {
    if (!isShow) {
        return null;
    }

    const { changeTab } = React.useContext(AsidePanelContext);
    const onClickSwitchTabDetails = () => changeTab(EBoardTab.DETAILS);

    return (
        <>
            <AsidePanelActionsSection
                title={ASIDE_PANEL_ACTIONS_SECTION_TITLE_SETTINGS}
            >
                <div className={CLASS_ASIDE_PANEL_ACTIONS_SECTION_CONTAINER}>
                    <div className={CLASS_ASIDE_PANEL_ACTIONS_SECTION_ADD_PROPERTIES}>
                        <AsidePanelActionsButtonsGroup>
                            <EmailNotificationsPanelButtonHOC
                                boardId={boardId}
                                onClick={onClickSwitchTabDetails}
                            />
                            <PushNotificationsPanelButtonHOC
                                boardId={boardId}
                                onClick={onClickSwitchTabDetails}
                            />
                        </AsidePanelActionsButtonsGroup>

                        <AsidePanelActionsButtonsGroup>
                            <PrefixesPanelButtonHOC
                                boardId={boardId}
                                onClick={onClickSwitchTabDetails}
                            />
                            <CardPropertiesPanelButtonHOC
                                boardId={boardId}
                                onClick={onClickSwitchTabDetails}
                            />
                            <CardCustomPropertiesPanelButtonHOC
                                boardId={boardId}
                                onClick={onClickSwitchTabDetails}
                            />
                            <CardAgingPanelButtonHOC
                                boardId={boardId}
                                onClick={onClickSwitchTabDetails}
                            />
                            <ColorsPanelButtonHOC
                                boardId={boardId}
                                onClick={onClickSwitchTabDetails}
                            />
                            <TagsPanelButtonHOC
                                boardId={boardId}
                                onClick={onClickSwitchTabDetails}
                            />
                            <RolesPanelButtonHOC
                                boardId={boardId}
                                onClick={onClickSwitchTabDetails}
                            />
                            <GanttPanelButtonHOC
                                boardId={boardId}
                                onClick={onClickSwitchTabDetails}
                            />
                            <ArchiveOnDeletePanelButtonHOC
                                boardId={boardId}
                                onClick={onClickSwitchTabDetails}
                            />
                            <LinksToCardsPanelButtonHOC
                                boardId={boardId}
                                onClick={onClickSwitchTabDetails}
                            />
                            <StatusListsPanelButtonHOC
                                boardId={boardId}
                                onClick={onClickSwitchTabDetails}
                            />
                        </AsidePanelActionsButtonsGroup>

                        <AsidePanelActionsButtonsGroup>
                            <DatesPanelButtonHOC
                                boardId={boardId}
                                onClick={onClickSwitchTabDetails}
                            />
                            <EstimateTimePanelButtonHOC
                                boardId={boardId}
                                onClick={onClickSwitchTabDetails}
                            />
                            <TimeZonePanelButtonHOC
                                boardId={boardId}
                                onClick={onClickSwitchTabDetails}
                            />
                            <BoardEmailPanelButtonHOC
                                boardId={boardId}
                                onClick={onClickSwitchTabDetails}
                            />
                            <PwcImportPanelButtonHOC
                                boardId={boardId}
                            />
                            <BoardImportPanelButtonHOC
                                boardId={boardId}
                            />
                        </AsidePanelActionsButtonsGroup>
                    </div>
                    <div className={`${CLASS_ASIDE_PANEL_ACTIONS_SECTION_TITLE}
                                     ${CLASS_ASIDE_PANEL_ACTIONS_SECTION_TITLE_SECOND}`}>
                        {ASIDE_PANEL_ACTIONS_SECTION_TITLE_ACTIONS}
                    </div>
                    <div className={CLASS_ASIDE_PANEL_ACTIONS_SECTION_ACTIONS}>
                        <AsidePanelActionsButtonsGroup>
                            <MoveToButtonHOC
                                boardId={boardId}
                            />
                            <CopyButtonHOC
                                boardId={boardId}
                            />
                            <CreateTemplateButtonHOC
                                boardId={boardId}
                            />
                            <OpenInDriveButtonHOC
                                boardId={boardId}
                            />
                        </AsidePanelActionsButtonsGroup>
                        <AsidePanelActionsButtonsGroup>
                            <ArchiveButtonHOC
                                boardId={boardId}
                            />
                            <UnarchiveButtonHOC
                                boardId={boardId}
                            />
                            <DeleteButtonHOC
                                boardId={boardId}
                            />
                        </AsidePanelActionsButtonsGroup>
                    </div>
                </div>
            </AsidePanelActionsSection>
        </>
    );
}
