import { Dispatch, ThunkAction } from 'app/types/actions';
import { IGetState, TBoardId } from 'app/types/types';
import { closePanel } from 'app/view/react_components/aside_panel/asidePanel/effects/closePanel';
import { hideAside } from 'app/view/react_components/reports/store/actions/reportsActions';
import { isAsideOpened } from 'app/view/react_components/reports/selectors/isAsideOpened';
import { SegmentCardEvent, segmentTrackAction } from 'app/middlewares/segment';
import { cardOpen } from 'app/view/react_components/base/effects/cardOpen';
import { getLinkCardId } from 'app/store/router/selectors/getLinkCardId';
import { SegmentCardDetailsCloseSourceValue } from 'app/middlewares/segment/trackEntities/cardEvents';

export const onClose = (
    boardId: TBoardId,
    onCloseOwn?: (source: SegmentCardDetailsCloseSourceValue) => void,
    source?: SegmentCardDetailsCloseSourceValue
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const linkCardId = getLinkCardId(state.router);
        if (linkCardId) {
            dispatch(dispatch(segmentTrackAction(SegmentCardEvent.LINK_CARD_BOARD_OPEN_CLICKED)));
            dispatch(cardOpen(linkCardId));
        } else {
            if (onCloseOwn) {
                onCloseOwn(source);
            } else {
                dispatch(closePanel());
            }
            if (isAsideOpened(getState(), boardId)) {
                dispatch(hideAside(boardId));
            }
        }
    };
    return action;
};
