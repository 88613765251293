import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId, TChecklistId, TChecklistItemId } from '../../../../../../../../types/types';
import { toggleChecklistItem } from '../effects/toggleChecklistItem';
import { addFeatureTrigger } from '../../../../../../polls/featurePoll/effects/addFeatureTrigger';
import { ERateFeature } from '../../../../../../../../types/rest/IUserMeta';

export const onToggle = (
    cardId: TCardId,
    checklistId: TChecklistId,
    itemId: TChecklistItemId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(addFeatureTrigger(ERateFeature.NEW_CHECKLISTS));
        return dispatch(toggleChecklistItem(cardId, checklistId, itemId));
    };
    return action;
};
