import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { root } from '../../../../../../../../store/constants';
import { TCardId } from '../../../../../../../../types/types';
import { IDriveDoc } from '../../../../../../../../store/model/card/types/IDriveDoc';

export const onAttachmentsChange = (
    cardId: TCardId,
    attachments: IDriveDoc[]
): ThunkAction => {
    return (dispatch: Dispatch) => {
        root.App.controller.cardsCommentsInfo.setAttachments(cardId, attachments);
    };
};
