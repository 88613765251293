import { ICards } from './../cards/types';
import { getBoard } from './getBoardById';
import { IApplicationState, TBoardId } from '../../../types/types';
import { TStatus } from '../../../types/model';
import { getList } from '../list/selectors/getList';
import { getCard } from './getCard';

/**
 * @param status по-умолчанию STATUS_ACTIVE. если null, то статус не учитывается
 */
export const getBoardCardsMap = (
    state: IApplicationState,
    boardId: TBoardId,
    status: TStatus = TStatus.STATUS_ACTIVE,
): ICards => {
    const board = getBoard(state, boardId);
    if (
        !board ||
        !board.listIds ||
        !board.listIds.length
    ) return [];

    const cards: ICards = {};
    board.listIds.forEach((listId) => {
        const list = getList(state, listId);
        if (
            list &&
            list.cardIds &&
            list.cardIds.length
        ) {
            list.cardIds.forEach((cardId) => {
                const card = getCard(state, cardId);
                if (
                    card && (
                        status === null ||
                        card.status === status
                    )
                ) {
                    cards[cardId] = card;
                }
            });
        }
    });
    return cards;
};
