import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { vent } from '../../../../../../../store/constants';
import { createBoardByDriveId } from '../../../../../base/effects/createBoardByDriveId';
import { boardCreateSharedDriveChosenSegmentEvent } from '../../../effects/segmentEvents';
import { TGoogleDriveId } from '../../../../../dialogs/openBoards/store/types';

export const onCreateOnSharedDrive: ActionCreator<ThunkAction> = (
    driveId: TGoogleDriveId
) => {
    const action = (
        dispatch: Dispatch,
    ) => {
        vent.trigger(vent['dashboards:loading'], true);
        dispatch(createBoardByDriveId(driveId));
        dispatch(boardCreateSharedDriveChosenSegmentEvent());
    };
    return action;
};
