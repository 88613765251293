import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { toggleEnabledCardNumber } from '../effects/toggleEnabledCardNumber';
import { TBoardId } from '../../../../../../../../../types/types';

export const onChange = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        return dispatch(toggleEnabledCardNumber(boardId));
    };
    return action;
};
