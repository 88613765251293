import { IGetState, TCardId } from '../../../types/types';
import { ICard } from '../../../store/model/card/types';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { sendUserProductivityStatsTS } from '../../../helper/comet/stat_helper_ts';
import { getBoardByCardId } from '../../../store/model/selectors/getBoardByCardId';
import { getList } from '../../../store/model/list/selectors/getList';
import { EUserProductivityEventStatus } from '../../../view/react_components/reports/UserProductivity/UserProductivityReport/rest/types';
import { getCard } from 'app/store/model/selectors/getCard';

export function onDueDateChanged (cardId: TCardId, updatedCard: ICard, oldCard: ICard): ThunkAction {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const card = getCard(state, cardId);
        const cardMerged = {...card, ...updatedCard };
        sendUserProductivityStatsTS(getBoardByCardId(getState(), card.id),
            cardMerged,
            getList(getState(), cardMerged.listId),
            EUserProductivityEventStatus.DUE_DATE_CHANGED);
    };
    return action;
}
