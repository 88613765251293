import { IApplicationState, TBoardId } from '../../../../../../../../types/types';
import { getBoardUsers } from '../../../../../../../../store/model/selectors/getBoardUsers';
import { userToMention } from '../../../helpers/MentionsHelper';
import { ISharedUser } from '../../../../../../../../types/rest/ISharedUser';
import { ICommentMention } from '../../../../../../../../store/model/card/types/IComment';
import { getMentionSubstring } from '../../../selectors/getMentionSubstring';

export const getBoardMentions = (
    state: IApplicationState,
    boardId: TBoardId
): ICommentMention[] => {
    const users = getBoardUsers(state, boardId).filter(user => !!user.firstName || !!user.lastName);
    const mentions = users.map((user: ISharedUser) => userToMention(user));
    const mentionSubstring = getMentionSubstring(state);

    if (mentionSubstring.length > 0) {
        const nameSubstring = mentionSubstring.substring(1).toLowerCase().replace('.', ' ');

        return mentions.filter((mention: ICommentMention) => {
            return mention && mention.name.toLowerCase().includes(nameSubstring);
        });
    }

    return [];
};
