import { AT_RENAME } from './constants';
import { ICardNameRenameAction } from './types';
import { TCardId } from '../../../../../../../../types/types';

export const cardNameRenameAction = (
    cardId: TCardId
): ICardNameRenameAction => ({
    type: AT_RENAME,
    cardId
});
