import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { root } from '../../../../../../../store/constants';
import { TBoardId } from '../../../../../../../types/types';
import { boardOpen } from '../../../../../base/effects/boardOpen';

export const openBoardSettings: ActionCreator<ThunkAction> = (
    boardId: TBoardId
) => {
    const action = (
        dispatch: Dispatch,
    ) => {
        if (!root.App.controller.isRelatedPanelNotOpened()) return;
        dispatch(boardOpen(boardId, 'edit'));
    };
    return action;
};
