import { IAuthUser } from '../store/model/authUser/types';

export class AllowCounterSnackbarHelper {
    allowColorTagCount?: boolean;

    isShowSnackbar(allow: keyof IAuthUser) {
        //@ts-ignore
       return !this[allow];
    }

    setSnackbarShown(allow: keyof IAuthUser) {
        //@ts-ignore
        this[allow] = true;
    }
}
