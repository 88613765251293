import { connect } from 'react-redux';
import {
    ICardAttachmentGoogleFilesHOCProps
} from './types';
import { CardAttachmentGoogleFiles } from 'app/view/react_components/aside_panel/cardDetails/AttachmentsSection/components/CardAttachmentGoogleFiles/CardAttachmentGoogleFiles';
import { EDefaultAttachmentType } from 'app/types/rest/IUserMeta';
import { IApplicationState } from 'app/types/types';
import { getUserDefaultAttachmentType } from 'app/store/model/authUser/selectors/getUserDefaultAttachmentType';
import { EFileType } from 'app/view/react_components/aside_panel/cardDetails/AttachmentsSection/components/CardAttachmentGoogleFiles/constants';
import { onCreate } from 'app/view/react_components/aside_panel/cardDetails/AttachmentsSection/hocs/CardAttachmentGoogleFilesHOC/events/onCreate';
import { onAttachFileType } from 'app/view/react_components/aside_panel/cardDetails/AttachmentsSection/hocs/CardAttachmentGoogleFilesHOC/events/onAttachFileType';
import {
    ICardAttachmentGoogleFilesEvents,
    ICardAttachmentGoogleFilesFields
} from 'app/view/react_components/aside_panel/cardDetails/AttachmentsSection/components/CardAttachmentGoogleFiles/types';

const mapStateToProps = (
    state: IApplicationState,
    ownProps: ICardAttachmentGoogleFilesHOCProps
): ICardAttachmentGoogleFilesFields => {
    const { isOnlyCreate } = ownProps;
    const defaultAttachmentType = getUserDefaultAttachmentType(state);
    return {
        defaultAttachmentType,
        isOnlyCreate
    }
};

const mapDispatchToProps = (
    dispatch: any,
    ownProps: ICardAttachmentGoogleFilesHOCProps
): ICardAttachmentGoogleFilesEvents => {
    const { boardId, cardId, onChange, onSelect } = ownProps;
    return {
        onCreate: (fileType: EFileType ) => dispatch(onCreate(boardId, cardId, fileType)),
        onAttachFileType: (attachmentType: EDefaultAttachmentType) => dispatch(onAttachFileType(attachmentType, cardId)),
        onChange,
        onSelect
    }
};

export const CardAttachmentGoogleFilesHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardAttachmentGoogleFiles);
CardAttachmentGoogleFilesHOC.displayName = 'CardAttachmentGoogleFilesHOC';
