import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { saveCardNumber } from '../effects/saveCardNumber';
import { TBoardId } from '../../../../../../../../../types/types';

export const onSaveCardNumber = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        return dispatch(saveCardNumber(boardId))
    };
    return action;
};
