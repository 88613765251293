import { ICardIdsSetAction } from './types';
import { AT_CARD_IDS_SET } from './constants';
import { TCardId } from '../../../../types/types';

export const cardIdsSet = (
    cardIds: TCardId[]
): ICardIdsSetAction => {
    return {
        type: AT_CARD_IDS_SET,
        cardIds
    }
};
