import { ICardAttachmentAddProps } from './types';
import * as React from 'react';
import { CLASS_CARD_DETAILS } from '../../../constants';
import './_CardAttachmentAdd.scss';
import { Button, LoaderBlock } from 'kui';
import {
    CARD_SECTION_ATTACHMENTS_BUTTON_SELECT,
    CARD_SECTION_ATTACHMENTS_BUTTON_UPLOAD,
    CARD_SECTION_ATTACHMENTS_BUTTON_UPLOAD_TOUCH,
    CARD_SECTION_ATTACHMENTS_NOTE_DROP
} from './constants';
import { getMessages } from 'app/store/constants';

export function CardAttachmentAdd ({
    onSelectClick,
    onUploadClick,
}: ICardAttachmentAddProps) {
    const isTouch = document.documentElement.classList.contains('touch');

    const className = CLASS_CARD_DETAILS + '-attachment__add';

    const [progress, setProgress] = React.useState(null);
    const onStart = () => {
        setProgress(0);
    }
    const onProgress = (percent: number) => {
        setProgress(Math.round(percent));
    }
    const onFinish = () => {
        setProgress(null);
    }

    return (
        <div className={className}>
            {onSelectClick &&
                <Button
                    className={`${className}-select`}
                    size={'large'}
                    variant={'icon-text'}
                    onClick={onSelectClick}
                >
                    {getMessages().getText(CARD_SECTION_ATTACHMENTS_BUTTON_SELECT)}
                </Button>
            }
            <Button
                className={`${className}-select`}
                size={'large'}
                variant={'icon-text'}
                onClick={()=> onUploadClick(onStart, onProgress, onFinish)}
            >
                {isTouch ? CARD_SECTION_ATTACHMENTS_BUTTON_UPLOAD_TOUCH : CARD_SECTION_ATTACHMENTS_BUTTON_UPLOAD}
            </Button>
            <div className={`${className}-drag`}>
                {CARD_SECTION_ATTACHMENTS_NOTE_DROP}
            </div>
            {progress !== null && <>
                <LoaderBlock />
                <div className={`${className}-progress`} style={{width: `${progress * 1.5}%`}} />
            </>}
        </div>
    );
}
