import { TBoardId } from '../../../../../../../../types/types';
import { ThunkAction } from '../../../../../../../../types/actions';
import { getBoardExportSettings } from '../../../../../../../../store/model/board/selectors/getBoardExportSettings';
import { boardSetExportSettings } from '../../../../../../../../rest/effects/board/boardSetExportSettings';
import { SegmentBoardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';

export const onChecklistsRowsToggle = (
    boardId: TBoardId
): ThunkAction => {
    return (
        dispatch,
        getState
    ) => {
        const settings = getBoardExportSettings(getState(), boardId);
        const checklistsRows = settings ? !settings.checklistsRows : true;
        dispatch(segmentTrackAction(SegmentBoardEvent.EXPORT_CHECKLISTS_ROWS_CLICKED));
        return dispatch(boardSetExportSettings(boardId, {
            ...settings,
            checklistsRows
        }))
    }
}
