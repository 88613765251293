import {
    Provider as gProvider,
    providerInstance as googleProviderInstance
} from 'app/helper/authorisation/google/Provider';
import { EAuthUserPlatformType, IAuthToken, IRestAuthUser } from 'app/types/rest/IRestAuthUser';
import { fetchHandler } from 'app/util/fetchHandler';
import { getRestHeadersPost } from 'app/rest/helpers/getRestHeadersHelper';
import { Provider as msProvider } from 'app/helper/authorisation/microsoft/oauth/Provider';
import { getAuthUser } from 'app/store/model/authUser/selectors/getAuthUser';
import { getAppState } from 'app/store/configureStore';
import { root } from 'app/store/constants';

const REMEMBER_ME_KYE='RememberMe';

/***
 * класс овечает за общение с  Goolge Provider и бекендом
 */
export class CommonAuthController {
    /***
     * should override on the child AuthController
     * @param scopes
     * @param onSuccess
     * @param onError
     * @param onCancel
     * @param hint
     */
    static authorizeWithCode(
        scopes: string[],
        onSuccess: (authUser: IRestAuthUser)=> void,
        onError: (authUser: IRestAuthUser) => void,
        onCancel: () => void,
        hint?: string) {
        console.error('Not Implemented');
    }

    protected static processServerAuthResponse(authUser: IRestAuthUser) {
        if (authUser.needLogin){
            return Promise.reject(authUser);
        }
        return Promise.resolve(authUser);
    }

    protected static getRememberMeHash(): string {
        return localStorage.getItem(REMEMBER_ME_KYE);
    }

    public static saveRememberMeHash(hash: string) {
        localStorage.setItem(REMEMBER_ME_KYE, hash);
    }

    static authorizeRememberMeHash(idToken?: string){
        let hash = this.getRememberMeHash()
        if (hash) {
            return this.serverAuthorizeWithHash(hash, idToken);
        }
        let user = {
            needLogin: true,
            tryRememberMe: false
        }
        return Promise.reject(user);
    }

    protected static serverAuthorizeWithHash(hash: string, idToken: string): Promise<IRestAuthUser> {
        let url = '/rest/users/authorizeWithHash';
        url = url + (idToken ? `?idToken=${idToken}` : '');
        return fetchHandler(url, {
            ...getRestHeadersPost(),
            body: hash
        }).then(this.processServerAuthResponse);
    }

    public static refreshAccessToken() {
        return fetchHandler('/rest/users/token')
            .then(this.processServerAuthResponse);
    }

    public static resetToken(){
        const authUser = getAuthUser(getAppState());
        if (EAuthUserPlatformType.GOOGLE ===  authUser.platformType) {
            googleProviderInstance.then(provider => provider.resetToken());
        }
        if (EAuthUserPlatformType.MICROSOFT ===  authUser.platformType) {
            msProvider.resetActiveAccount();
        }
    }

    public static setToken(token: IAuthToken){
        const authUser = getAuthUser(getAppState());
        if (EAuthUserPlatformType.GOOGLE ===  authUser.platformType){
            googleProviderInstance.then(provider => provider.setToken(token));
        }
        if (EAuthUserPlatformType.MICROSOFT ===  authUser.platformType) {
            msProvider.resetActiveAccount();
        }
    }

    public static signInFinished(){
        const authUser = getAuthUser(getAppState());
        if (EAuthUserPlatformType.MICROSOFT ===  authUser.platformType) {
            msProvider.signInFinished();
        }
    }

    static getInitScopes(lastAuthUserPlatformType: EAuthUserPlatformType){
        if (EAuthUserPlatformType.MICROSOFT ===  lastAuthUserPlatformType){
            return msProvider.getInitScopes().split(' ');
        } else {
            return gProvider.getInitScopes().split(' ');
        }
    }
}
