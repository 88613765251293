import { IMoveToColumnProps } from './types';
import * as React from 'react';
import { MoveToItem } from '../MoveToItem/MoveToItem';
import {
    IDK_CHUWAK_ITEM,
    MAKE_FIRST_ITEM,
    MORE_BOARDS_ITEM
} from './constants';
import './_MoveToColumn.scss';
import { CLASS_MOVE_TO } from '../MoveTo/constants';
import { MAKE_FIRST_ITEM_INDEX, MORE_BOARDS_ITEM_INDEX } from '../constants';

export function MoveToColumn({
    title,
    typeColumn,
    items,
    activeIndex,
    onClick,
    loadMore,
    insertFirst,
    insertAfterTitle,
    onClickLoadMore
}: IMoveToColumnProps) {
    const className = CLASS_MOVE_TO + '__column';
    const classNameTypeColumn = className + '-' + (typeColumn ? typeColumn : '');
    const classNameTitle = CLASS_MOVE_TO + '__column-title';
    const classNameTitleTypeColumn = classNameTitle + '-' + (typeColumn ? typeColumn : '');
    const classNameItems = CLASS_MOVE_TO + '__column-items';
    const classNameItemLoadMore = className + '-item-load-more-item';
    return (<div className={className + ' ' + classNameTypeColumn}>
        <div className={classNameTitle + ' ' + classNameTitleTypeColumn}><span>{title}{insertFirst ? insertAfterTitle : ''}</span></div>
        <div className={classNameItems}>
            {
                insertFirst &&
                <MoveToItem
                    name={MAKE_FIRST_ITEM}
                    key={MAKE_FIRST_ITEM_INDEX}
                    index={MAKE_FIRST_ITEM_INDEX}
                    onClick={onClick}/>
            }
            {
                (!insertFirst && items.length === 0) &&
                <MoveToItem
                    name={IDK_CHUWAK_ITEM}
                    key={MAKE_FIRST_ITEM_INDEX}
                    index={MAKE_FIRST_ITEM_INDEX}
                    onClick={() => {}}/>
            }
            {
                items.map((item, index) => {
                    return (<MoveToItem
                        name={item.name}
                        key={item.id}
                        index={index}
                        isActive={index === activeIndex}
                        onClick={onClick}/>);
                })
            }
            {
                loadMore &&
                <MoveToItem
                    name={MORE_BOARDS_ITEM}
                    key={MORE_BOARDS_ITEM_INDEX}
                    index={MORE_BOARDS_ITEM_INDEX}
                    icon={'arrow-down'}
                    className={classNameItemLoadMore}
                    onClick={onClickLoadMore}/>
            }
        </div>
    </div>);
}
