import { Dispatch, ThunkAction } from 'app/types/actions';
import { IGetState } from '../../../../../../types/types';
import { IUpdate } from 'app/view/react_components/dialogs/updatesModal/components/UpdatesModal/types';
import { EUpdateSlideType, IUpdatesItemSlide } from '../../../types';
import { root } from 'app/store/constants';

export function onShowPopup (
    slides: IUpdatesItemSlide[],
): ThunkAction {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        if (!slides || !slides.length) return;

        let updates: IUpdate[] = slides.map((slide: IUpdatesItemSlide) => {
            return {
                media: slide.media,
                text: slide.text,
                title: slide.title,
                type: slide.type === EUpdateSlideType.VIDEO ? 'video' : 'image',
            };
        });
        root.App.controller.showUpdatesModal(updates);
    };
    return action
};
