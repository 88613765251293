'use strict';

import { ERestActivityType } from '../../types/rest/activity/IRestActivityBase';

App.Classes.NotificationFabrika = Backbone.View.extend({
    getInstance: function(model) {
        this.model = model;
        var activityItem = this.model.getNotificationModel(0);
        return this.getActivityItemView(activityItem);
    },

    getActivityItemView: function(activityItem) {
        switch (activityItem.modelType) {
            case 'card':
                return this.getCardActivityItemView(activityItem);
            case 'list':
                return this.getListActivityItemView(activityItem);
            case 'dashboard':
                return this.getDashboardActivityItemView(activityItem);
            case 'system':
                return this.getSystemItemView(activityItem);
            default:
                console.log('getActivityItemView not found:'+ activityItem.modelType, activityItem);
        }
    },

    /** Card */
    getCardActivityItemView: function(activityItem) {
        switch (activityItem.actionType) {
            case 'create':
            case 'copy':
            case 'copyFromTemplate':
            case 'copyFromRecurring':
                return new App.Views.CardCreateNotification({model: this.model});
            case 'update':
                return this.getCardUpdateActivityItemView(activityItem);
            case 'delete':
                return new App.Views.CardDeleteNotification({model: this.model});
            case 'move':
                return new App.Views.CardUpdateListNotification({model: this.model});
            case 'archive':
                return new App.Views.CardArchiveNotification({model: this.model});
            case 'restore':
                return new App.Views.CardUnarchiveNotification({model: this.model});
            case 'datePassed':
                return new App.Views.CardOverdueNotification({model: this.model});
            case 'checkLitsItemDatePassed':
                return new App.Views.CheckListItemOverdueNotification({model: this.model});
            case 'notificationBeforeDueDate':
                return new App.Views.CardNotificationBeforeDueDate({model: this.model});
            default:
                console.log('getCardActivityItemView not found:'+ activityItem.actionType, activityItem);
        }
    },

    getCardUpdateActivityItemView: function(activityItem) {
        if (activityItem.type === ERestActivityType.TYPE_CARD_CUSTOM_PROPERTIES)
            return new App.Views.CardUpdateCustomNotification({model: this.model});

        switch (activityItem.propertyName) {
            case 'name':
                return new App.Views.CardUpdateNameNotification({model: this.model});                
            case 'description':
                return new App.Views.CardUpdateDescriptionNotification({model: this.model});
            case 'dueDate':
                //TODO не могу поймать
                return new App.Views.CardUpdateDateNotification({model: this.model});
            case 'startDate':
                //TODO не могу поймать
                return new App.Views.CardUpdateDateNotification({model: this.model});
            case 'estimate':
                //TODO не могу поймать
                return new App.Views.CardUpdateEstimateNotification({model: this.model});
            case 'tags':
                //TODO не могу поймать
                return new App.Views.CardUpdateTagNotification({model: this.model});
            case 'processingStatus':
                //TODO не могу поймать
                return new App.Views.CardUpdateDoneNotification({model: this.model});
            case 'color':
                //TODO не могу поймать
                return new App.Views.BaseNotification({model: this.model});
            case 'comment':
                //TODO edit comment
                return new App.Views.CardAddCommentNotification({model: this.model});
            case 'driveDoc':
                return new App.Views.CardAddAttachmentsNotification({model: this.model});
            case 'assignedTo':
                //TODO работает не правильно только при добавлении первого асайнера
                return new App.Views.CardAddAssignerNotification({model: this.model});
            case 'assignees':
                //TODO работает не правильно только при добавлении первого асайнера
                return new App.Views.CardAddAssignerNotification({model: this.model});
            case 'list':
                return new App.Views.CardUpdateListNotification({model: this.model});
            case 'priority':
                //TODO работает не правильно только при добавлении приоритета
                return new App.Views.CardAddPriorityNotification({model: this.model});
            case 'checkList':
                return new App.Views.CardUpdateChecklistNotification({model: this.model});
            case 'checkListGroup':
                return new App.Views.CardUpdateChecklistGroupNotification({model: this.model});
            case 'colorId':
                return new App.Views.CardUpdateColorTagNotification({model: this.model});
            case 'commentReacted':
                return new App.Views.CardAddReactionNotification({model: this.model});
            default:
                console.log('getCardUpdateActivityItemView not found:'+ activityItem.propertyName, activityItem);
                
        }
    },

    /** List */
    getListActivityItemView: function(activityItem) {
        switch (activityItem.actionType) {
            case 'create':
                //TODO не могу поймать
                return new App.Views.BaseNotification({model: this.model});
            case 'update':
                return this.getListUpdateActivityItemView(activityItem);
            case 'delete':
                //TODO не могу поймать
                return new App.Views.BaseNotification({model: this.model});
            case 'restore':
                //TODO не могу поймать
                return new App.Views.BaseNotification({model: this.model});
            case 'copy':
                //TODO не могу поймать
                return new App.Views.BaseNotification({model: this.model});
            default:
                console.log('getListActivityItemView not found:'+ activityItem.actionType, activityItem);
        }
    },

    /***
     * Далее все //TODO не могу поймать
     */

    getListUpdateActivityItemView: function(activityItem) {
        switch (activityItem.propertyName) {
            case 'name':
                return new App.Views.BaseNotification({model: this.model});
            case 'description':
                return new App.Views.BaseNotification({model: this.model});
            case 'maxCardNumber':
                return new App.Views.BaseNotification({model: this.model});
            case 'cardOrder':
                return new App.Views.BaseNotification({model: this.model});
            default:
                console.log('getListUpdateActivityItemView not found:'+ activityItem.propertyName, activityItem);
        }
    },

    /** Board */
    getDashboardActivityItemView: function(activityItem) {
        switch (activityItem.actionType) {
            case 'create':
                return new App.Views.BaseNotification({model: this.model});
            case 'copy':
                return new App.Views.BaseNotification({model: this.model});
            case 'update':
                return this.getDashboardUpdateActivityItemView(activityItem);
            default:
                console.log('getDashboardActivityItemView not found:'+ activityItem.actionType, activityItem);
        }
    },

    getDashboardUpdateActivityItemView: function(activityItem) {
        switch (activityItem.propertyName) {
            case 'name':
                return new App.Views.BaseNotification({model: this.model});
            case 'description':
                return new App.Views.BaseNotification({model: this.model});
            default:
                console.log('getDashboardUpdateActivityItemView not found:'+ activityItem.propertyName, activityItem);
        }
    },

    getSystemItemView: function(activityItem) {
        switch (activityItem.actionType) {
            case 'access':
                /* оставлю пока тут, постоянно нужно для тестов
                this.model.attributes = {
                    modelType: 'card',
                    actionType: 'move',
                    seen: false,                    
                    card: {
                        name: 'Fake card name very long Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                        listName: 'Fake list name very long Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
                    },
                    dashboardName: 'Fake dashboard name very long Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                    newList: {
                        name: 'New list name'
                    },
                    oldList: {
                        name: 'Old list name'
                    },
                    newValue: Math.floor(Date.now() / 1000),
                    time: Math.floor(Date.now() / 1000),
                    user: getAuthUser(getAppState())
                };
                return new App.Views.CardOverdueNotification({model: this.model});
                */
                return new App.Views.SystemAccessNotification({model: this.model});
            case 'exportProcess':
                return this.getExportItemView(activityItem);
        }
    },

    getExportItemView: function(activityItem) {
        switch (activityItem.type) {
            case 'all':
                return new App.Views.SystemExportDataNotification({model: this.model});
            case 'tt':
                return new App.Views.SystemExportTTNotification({model: this.model});
        }
    }
});
