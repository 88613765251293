import * as React from 'react';
import { Input } from 'kui';
import { IBoardEmailProps } from './types';
import './_BoardEmail.scss';
import {
    BOARD_EMAIL_DESCIPTION,
    BOARD_EMAIL_INSTRUCTION_URL,
    BOARD_EMAIL_MORE_INSTRUCTIONS,
} from '../../constants';
import { CLASS_BOARD_DETAILS } from '../../../../constants';

export function BoardEmail ({
    isDisallow,
    isReadonly,
    value,
    onClick,
    onClickDisallow,
    onClickInstructions,
}: IBoardEmailProps) {
    const className = CLASS_BOARD_DETAILS + '__email';
    const classText = CLASS_BOARD_DETAILS + '__email-text';

    const inputRef = React.useRef(null);
    const onClickHandler = () => {
        if (isDisallow) {
            onClickDisallow();
        } else {
            if (inputRef.current) inputRef.current.getInput().select();
            if (navigator.clipboard) navigator.clipboard.writeText(value);
            onClick();
        }
    }
    return (
        <div className={className}>
            <div className={classText}>
                {BOARD_EMAIL_DESCIPTION}
                {' '}
                <a
                    href={BOARD_EMAIL_INSTRUCTION_URL}
                    target="_blank"
                    onClick={onClickInstructions}
                >{BOARD_EMAIL_MORE_INSTRUCTIONS}</a>
            </div>
            <Input
                autosize={!isReadonly}
                readOnly={true}
                ref={inputRef}
                value={value}
                onClick={onClickHandler}
            />
        </div>
    );
}
