import { getMessages } from '../../../../../../../../store/constants';

export const BOARD_DESCRIPTION_ADD = getMessages().getText('board_settings.description.add');
export const BOARD_DESCRIPTION_SHOW_MORE = getMessages().getText('board_settings.description.show_more');
export const BOARD_DESCRIPTION_SHOW_LESS = getMessages().getText('board_settings.description.show_less');
export const BOARD_DESCRIPTION_SAVE = getMessages().getText('board_settings.save');
export const BOARD_DESCRIPTION_CANCEL = getMessages().getText('board_settings.cancel');
export const BOARD_DESCRIPTION_FORMATTING = getMessages().getText('board_settings.formatting');
export const BOARD_DESCRIPTION_HOW_TO_URL = 'http://www.kanbanchi.com/faqwd/how-do-i-format-the-content';

export const ESC_WHICH_KEY_NUMBER = 27;
export const ENTER_WHICH_KEY_NUMBER = 13;
