import { PageCollection } from '@microsoft/microsoft-graph-client';
import { Permission, SharePointIdentitySet } from '@microsoft/microsoft-graph-types';
import { errorHandler, graphClient } from 'app/helper/authorisation/microsoft/graph/Graph';

export enum EGrantedType {
    UNKNOWN = 'unknown',
    GROUP = 'group',
    USER = 'user'
}

export interface IGranted {
    type: string;
    displayName: string;
    id: string;
}

export enum EFilePermissionRole {
    WRITE = 'write',
    OWNER = 'owner',
    READ = 'read'
}

export interface IFilePermission {
    id: string;
    role: EFilePermissionRole,
    granted: IGranted[],
    sharedLink?: boolean //true если мы через api  править не можем https://learn.microsoft.com/en-us/answers/questions/986279/why-is-update-permission-graph-api-for-files-not-w
}

export const getFilePermissions = async (
    driveId: string,
    fileId: string
) => {
    try {
        let request = graphClient.api(`/drives/${driveId}/items/${fileId}/permissions`);
        let response = await request.get() as PageCollection;
        let permissions = response.value as Permission[];
        return permissions.map((val) => {
            return {
                id: val.id,
                role: val.roles[0],
                granted: getGrantedFromPermissions(val).filter(item => item), // убиваем все undefined
                sharedLink: val.grantedToIdentitiesV2? true: undefined
            } as IFilePermission
        }).filter(per => per.granted.length > 0 );
    } catch (e) {
        return errorHandler(e);
    }
}

export const getGrantedFromPermissions = (
    perm: Permission
) => {
    if (perm.grantedToIdentitiesV2) {
        return perm.grantedToIdentitiesV2.map(identity => {
            return getGrantedFromIdentity(identity);
        });
    }

    if (perm.grantedToV2) {
        return [getGrantedFromIdentity(perm.grantedToV2)];
    }

    if (perm.grantedTo) {
        return [getGrantedFromIdentity(perm.grantedTo)];
    }
}

const getGrantedFromIdentity = (
    identity: SharePointIdentitySet
) => {
    let type = EGrantedType.UNKNOWN;
    let entity;
    if (identity.siteGroup) {
        type = EGrantedType.GROUP
        entity = identity.siteGroup;
    }
    if (identity.group) {
        type = EGrantedType.GROUP
        entity = identity.group;
    }
    if (identity.user) {
        type = EGrantedType.USER;
        entity = identity.user;
    }
    if (type === EGrantedType.UNKNOWN) {
        console.error('perm.grantedToV2 IGrantedType.UNKNOWN for ', identity);
        return undefined;
    }
    return {
        type,
        displayName: entity.displayName,
        id: entity.id
    } as IGranted
}
