import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState } from '../../../../types/types';
import { changeBackboneViewType } from './changeBackboneViewType';
import { viewTypeChangedSegmentEvent } from '../../main/navigationPanel/effects/segmentEvents';
import { CLASS_MAIN_PANEL, EViewTypes } from '../../../../const';
import { sendUserActivityStatEventByViewType } from '../../../../rest/effects/userStatAdminReport/helpers/sendUserActivityStatEventByViewType';

export const openReports = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const viewType = EViewTypes.REPORTS_VIEW_TYPE;
        dispatch(changeBackboneViewType(viewType));
        dispatch(viewTypeChangedSegmentEvent(viewType));

        const main = document.querySelector('.' + CLASS_MAIN_PANEL) as HTMLElement;
        if (main) main.focus();

        dispatch(sendUserActivityStatEventByViewType(viewType));
    };
    return action;
};
