import { getRestHeadersPost } from './../../rest/helpers/getRestHeadersHelper';
import Util from '../../util/util';
import { fetchHandler } from '../../util/fetchHandler';
import { ICard } from '../../store/model/card/types';
import { IRestCard } from '../../types/rest/IRestCard';

export class CardsLoader {
    MAX_PORTION: number = 50;
    cardsId: number[];

    constructor(cardsId: number[]) {
        this.cardsId = cardsId;
    }

    load(): Promise<Array<IRestCard>> {
        let part = this.getPart(this.cardsId);
        if (part.length){
            return new CardsLoaderPart(part).loadPart().then( (cards) => {
                let otherPart = this.getLast(this.cardsId);
                return new CardsLoader(otherPart).load().then((otherCards)=>{
                    return [...cards, ...otherCards];
                })
            });
        }else{
            return Promise.resolve([]);
        }
    }

    getPart(cardsId: number[]) {
        return cardsId.slice(0, this.MAX_PORTION);
    }

    getLast(cardsId: number[]) {
        return cardsId.slice(this.MAX_PORTION);
    }

}

class CardsLoaderPart {
    cardsId: number[];

    constructor(cardsId: number[]) {
        this.cardsId = cardsId;
    }

    loadPart(): Promise<Array<ICard>> {
        if(!this.cardsId){
            return Promise.resolve([]);
        }
        const init: RequestInit = {
            ...getRestHeadersPost(),
            body: JSON.stringify(this.cardsId)
        };
        return fetchHandler<Array<ICard>>(this.getUrl(), init);
    }

    getUrl(): string {
        return Util.getApiUrl('/rest/dashboards/lists/cards/cardsByIds');
    }
}
