import { AT_REMOVE, IRemoveAction } from './types';
import { ICollaborator } from '../types';

export const removeAction = (
    collaborator: ICollaborator
): IRemoveAction => {
    return {
        type: AT_REMOVE,
        collaborator
    }
};
