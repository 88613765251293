import { IGetState, TBoardId } from '../../../types/types';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { boardRestCopy } from './api/helpers/boardRestCopy';
import { getMessages, root } from '../../../store/constants';
import { getBoard } from '../../../store/model/selectors/getBoardById';

export const boardCopy = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const board = getBoard(getState(), boardId);
        if (!board) return Promise.reject(getMessages().getText('error.record.not_found'));

        root.App.controller.mainView.renderBlockerModal({
            text: getMessages().getText('popup.dashboard.copying'),
            title: getMessages().getText('popup.dashboard.copying.header'),
        });

        return dispatch(boardRestCopy(boardId))
            .finally(() => root.App.controller.mainView.removeBlockerModal());
    };
    return action;
};
