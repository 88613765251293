import { TBoardId } from '../../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { boardClose } from '../../../../../../../base/effects/boardClose';
import { boardsActionSet } from '../../../../../../../../../store/model/actions/boardsActionSet';
import { boardActionDelete } from '../../../../../../../../../store/model/boards/actions/boardActionDelete';
import { openBoard } from '../../../../../../../dialogs/openBoards/effects/openBoard';

export const reopenBoard = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(boardClose(boardId)).then(() => {
            dispatch(boardsActionSet(boardActionDelete(boardId)));
            dispatch(openBoard(boardId));
        })
    };
    return action;
};
