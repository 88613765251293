import * as React from 'react';
import { ICardTagsProps } from './types';
import { CLASS_CARD_DETAILS_SECTION } from '../../../constants';
import { CardTagsElements } from '../CardTagsElements/CardTagsElements';
import { SettingsSection } from '../../../../../base/components/SettingsSection/SettingsSection';
import { CARD_TAG_ADD, CARD_TAG_MODAL_CANCEL, CARD_TAG_MODAL_REMOVE } from './constants';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { CARD_SECTION_TAGS } from '../../constants';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { EAsidePanelProperty } from '../../../../asidePanel/components/AsidePanel/types';
import { CardTagsSearchSelectHOC } from '../../hocs/CardTagsSearchSelectHOC/CardTagsSearchSelectHOC';
import { SEARCH_SELECT_INPUT_SELECTOR } from '../../../../../base/components/SearchSelect/constants';
import { CLASS_SETTINGS_SECTION_EMPTY } from '../../../../../base/components/SettingsSection/constants';
import './_CardTagsSection.scss';
import { ActionModal } from '../../../../../base/components/ActionModal/ActionModal';
import {
    BasicUpgradeButtonHOC
} from '../../../../../base/components/BasicUpgradeButton/hocs/BasicUpgradeButtonHOC/BasicUpgradeButtonHOC';
import { getMessages } from '../../../../../../../store/constants';
import { AnalyzeRender } from '../../../../../helpers/memoizeHelper';

//@ts-ignore
const analyzeRender = window.Settings.development ? new AnalyzeRender(`CardTagsSection`) : null;

export function CardTagsSection ({
    aloudCount,
    isDefaultProperty,
    isRequired,
    sectionType,
    tags,
    onClose,
    onDelete,
    onModalClose,
    onToggle,
    onSort,
    onAddClicked,
    boardId,
    cardId,
    onRename,
    onRenameClicked,
    ...attributes
}: ICardTagsProps) {
    if (analyzeRender) analyzeRender.call(`CardTagsSection ${cardId}`);

    if (!tags && sectionType === ESettingsSectionType.READONLY) return;

    const isPopup = sectionType === ESettingsSectionType.POPUP;
    const className = CLASS_CARD_DETAILS_SECTION + ' ' + CLASS_CARD_DETAILS_SECTION + '--tags';
    const classNameEmpty = !tags || !tags.length ? CLASS_SETTINGS_SECTION_EMPTY : '';
    const { openedProperty, addShowedProperty } = React.useContext(AsidePanelContext);

    const [isShow, setShow] = React.useState(false);
    const [isOpenedHook, setIsOpened] = React.useState(isPopup);
    const [removeTag, setRemoveTag] = React.useState(null);
    const onCloseModal = () => {
        onModalClose();
        setRemoveTag(null);
    };
    const onAddOpened = () => {
        setIsOpened(true);
        onAddClicked();
    };
    const onAddClosed = () => {
        setIsOpened(false);
        if (onClose) onClose();
    }

    React.useEffect(() => {
        if ((tags && tags.length) || isDefaultProperty) {
            setShow(true);
            addShowedProperty(EAsidePanelProperty.CARD_TAGS)
        }
    }, [tags, isDefaultProperty]);

    if (
        !isShow &&
        !(isPopup && openedProperty === EAsidePanelProperty.CARD_TAGS)
    ) {
        return;
    }

    return (
        <SettingsSection
            addTitle={ CARD_TAG_ADD }
            addComponent={
                isOpenedHook ? (
                    <CardTagsSearchSelectHOC
                        boardId={ boardId }
                        cardId={ cardId }
                        isPopup={ isPopup }
                        isOpened={ isOpenedHook }
                        onAddClosed={ onAddClosed }
                        onSelect={tag => onToggle(tag).then(result => {
                            if (!result) setRemoveTag(tag);
                        })}
                    />
                ) : (
                    <div/>
                )
            }
            className={`
                ${className}
                ${classNameEmpty}
            `}
            focusSelector={SEARCH_SELECT_INPUT_SELECTOR}
            isAddWide
            isRequired={isRequired}
            isRequiredError={!tags || !tags.length}
            isOpened={ isOpenedHook || isPopup }
            title={ CARD_SECTION_TAGS }
            sectionType={ sectionType }
            onAddOpened={ onAddOpened }
            onAddClosed={ onAddClosed }
            { ...attributes }
        >
            <CardTagsElements
                tags={ tags }
                sectionType={ sectionType }
                onDelete={tag => onDelete(tag).then(result => {
                    if (!result) setRemoveTag(tag);
                }) }
                onSort={ onSort }
                onRename={ onRename }
                onRenameClicked={onRenameClicked}
            />
            <ActionModal
                isShow={!!removeTag}
                actions={[
                    {
                        variant: 'primary',
                        onClick: onCloseModal,
                        children: CARD_TAG_MODAL_CANCEL
                    },
                    {
                        variant: 'secondary',
                        onClick: () => {
                            onCloseModal();
                            return onToggle(removeTag);
                        },
                        children: CARD_TAG_MODAL_REMOVE
                    },
                ]}
                footer={<BasicUpgradeButtonHOC onClick={onCloseModal} />}
                text={getMessages().getText('card_form_tag_modal-text', null, aloudCount)}
                title={getMessages().getText('card_form_tag_modal-title', null, removeTag)}
                onClose={onCloseModal}
            />
        </SettingsSection>
    );
};
