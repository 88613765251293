import { IBoardsLoadingSetAction } from './types';
import { AT_BOARDS_LOADING_SET } from './constants';
import { TTabKey } from '../../types';

export const boardsLoadingSet = (
    tabKey: TTabKey,
    isLoading: boolean
): IBoardsLoadingSetAction => ({
    type: AT_BOARDS_LOADING_SET,
    tabKey,
    isLoading
});
