import { IApplicationState } from '../../../../../../../../types/types';
import { connect } from 'react-redux';
import { ILinksToCardsTogglerHOCProps } from './types';
import { onChange } from './events/onChange';
import { getBoard } from '../../../../../../../../store/model/selectors/getBoardById';
import { ISectionTogglerEvents, ISectionTogglerFields } from '../../../../../../base/components/SectionToggler/types';
import { SectionToggler } from '../../../../../../base/components/SectionToggler/SectionToggler';
import { getSectionType } from '../../../../selectors/getSectionType';
import { ESettingsSectionType } from '../../../../../../base/components/SettingsSection/types';

const mapStateToProps = (
    state: IApplicationState,
    { boardId }: ILinksToCardsTogglerHOCProps
): ISectionTogglerFields => {
    const { enableOpenGraphInfo: checked } = getBoard(state, boardId);
    const sectionType = getSectionType(state, boardId, null, null, 'allowEnableOpenGraphInfo');
    const isDisabled = sectionType === ESettingsSectionType.READONLY;
    const isDisallowed = sectionType === ESettingsSectionType.DISALLOW;
    return {
        checked,
        isDisabled,
        isDisallowed,
    };
};

const mapDispatchToProps = (
    dispatch: any,
    { boardId }: ILinksToCardsTogglerHOCProps
): ISectionTogglerEvents => {
    return {
        onChange: () => dispatch(onChange(boardId))
    }
};

export const LinksToCardsTogglerHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(SectionToggler);

LinksToCardsTogglerHOC.displayName = 'LinksToCardsTogglerHOC';
