import { getCardAssignees } from '../../../../../../../../store/model/selectors/getCardAssignees';
import { IGetState, TCardId, TPermissionId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { cardSetAssignees } from '../../../../../../../../rest/effects/card/card/cardSetAssignees';

export const sortAssignees = (
    cardId: TCardId,
    permissionIds: TPermissionId[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const assignees = [...getCardAssignees(state, cardId)];

        assignees.sort((assigneeA, assigneeB) => {
            const indexA = permissionIds.indexOf(assigneeA.sharedUser.permissionId);
            const indexB = permissionIds.indexOf(assigneeB.sharedUser.permissionId);
            return indexA - indexB;
        });

        return dispatch(cardSetAssignees(cardId, assignees));
    };
    return action;
};
