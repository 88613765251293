import * as _ from 'underscore';
App.Classes.DashboardXmlExportManager = Backbone.View.extend({

    initialize: function(options) {
        this.url = App.Util.getApiUrl('/rest/dashboard/xml/export');
        this.dashboardId = options.boardId;
        this.workContext = null;
    },

    /**
     * Запуск экспорта из xml файла
     * @returns true или false в зависимости от наличия workContext
     */
    startExport: function(options) {
        this.exportFileName = options.exportFileName;
        if (this.workContext) return false;
        this.workContext = {};
        this.doExport();
    },

    doExport: function() {
        var request = {
            dashboardId: this.dashboardId,
            exportFileName: this.exportFileName,
            workContext: this.workContext.serverWorkContext
        };
        $.ajax({
        	beforeSend: App.vent.beforeSend,
            method: "POST",
            url: this.url,
            data: JSON.stringify(request),
            dataType : "json",
            contentType: "application/json",
            processData : false
        }).done(_.bind(function(result) {
            if (!this.checkResponseError(result) || !this.checkCancelStatus()) return;
            this.workContext.serverWorkContext = result.workContext;
            this.callbackHandler('onProgress', result);
            if (result.completed) {
                this.onSuccess();
                this.callbackHandler('onSuccess', result);
            } else {
                this.doExport();
            }
        }, this));
    },

    /**
     * Отмена экспорта
     * @returns true или false в зависимости от наличия workContext
     */
    cancelExport: function() {
        if (!this.workContext) return false;
        this.workContext.cancelStatus = true;
        return true;
    },

    /**
     * Проверка возвращаемого значения на error
     * @param result
     * @returns boolean
     */
    checkResponseError: function(result) {
        if (!result.success) {
            this.workContext = null;
            this.callbackHandler('onError', result.errorMessage);
            return false;
        }
        return true;
    },

    /**
     * Проверка workContext на cancelStatus
     * @returns boolean
     */
    checkCancelStatus: function() {
        if (this.workContext.cancelStatus) {
            this.workContext = null;
            this.callbackHandler('onCancel');
            return false;
        }
        return true;
    },

    /**
     * Функция которая вызывает на себя тригер события
     * @param name Имя события
     * @param options дополнительные параметры события
     */
    callbackHandler: function(name, options) {
        this.trigger(name, options);
    },

    onSuccess: function() {
        this.workContext = null;
    },

    isReady: function() {
        return this.workContext == null
    }
});
