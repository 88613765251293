import { IGetState } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { hintHide } from '../../../../../../hints/effects/hintHide';
import { EHintKey } from '../../../../../../hints';

export const onAutoStartDateHide = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(hintHide(EHintKey.AUTO_START_DATE));
    };
    return action;
};
