import { Dispatch, ThunkAction } from '../../../types/actions';
import { TRestActivity } from '../../../types/rest/activity/IRestActivities';
import { IGetState, TPermissionId } from '../../../types/types';
import { getActivityItem } from '../../../view/react_components/aside_panel/boardDetails/activity/TabActivity/helpers/aсtivityHelper';
import { activitiesUpdateAction } from '../../../store/activities/actions/activitiesUpdateAction';
import { IActivities, TActivity } from '../../../store/activities/types';
import { IHistoryUserRest } from '../../../types/rest/IHistoryUserRest';
import { getAuthUser } from '../../../store/model/authUser/selectors/getAuthUser';
import { ERestActivityModelType, ERestActivityPropertyName } from '../../../types/rest/activity/IRestActivityBase';
import { getBoard } from '../../../store/model/selectors/getBoardById';

export const updateActivities = (
    activities: TRestActivity[]
): ThunkAction => {
    const action: ThunkAction = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const activitiesState = activities.reduce((activities: IActivities, activity: TActivity) => {
            let usersPushNotified: IHistoryUserRest[] = [];
            if (activity.usersPushNotified && activity.usersPushNotified.length) {
                const userIds: TPermissionId[] = [];
                activity.usersPushNotified.forEach(user => {
                    if (!userIds.includes(user.permissionId)) {
                        userIds.push(user.permissionId);
                        usersPushNotified.push(user);
                    }
                });
            }

            const state = getState();
            const { permissionId } = getAuthUser(state);
            // @ts-ignore
            const isUser = activity.users && activity.users.find(user => user.permissionId === permissionId);

            if (activity.propertyName && activity.propertyName === ERestActivityPropertyName.LIST) {
                //@ts-ignore
                const newBoard = activity.newBoard;
                if (newBoard) {
                    const { logo, logoColor, logoIcon} = getBoard(state, newBoard.id);
                    //@ts-ignore
                    activity.newBoard = { ...newBoard, logo, logoColor, logoIcon }
                }
                //@ts-ignore
                const oldBoard = activity.oldBoard;
                if (oldBoard) {
                    const { logo, logoColor, logoIcon} = getBoard(state, oldBoard.id);
                    //@ts-ignore
                    activity.oldBoard = { ...oldBoard, logo, logoColor, logoIcon }
                }
            }

            activities[activity.id] = {
                ...activity,
                usersPushNotified, // TODO deduplicate on backend
                ...getActivityItem(activity, isUser) // generate Label & Component
            };
            return activities;
        }, {});
        dispatch(activitiesUpdateAction(activitiesState)); // add or update activities store
    };
    return action;
}
