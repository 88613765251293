import { createDeepEqualSelector } from '../../../../../../helpers/memoizeHelper';
import { getBoardExportSettings } from '../../../../../../../../store/model/board/selectors/getBoardExportSettings';
import { IApplicationState, TBoardId } from '../../../../../../../../types/types';
import {
    BOARD_EXPORT_SETTINGS_COLUMNS,
    DEFAULT_UNENABLED_BOARD_EXPORT_SETTINGS_COLUMNS
} from '../../../components/BoardExportSettings/constants';
import { getBoardCardCustomProperties } from '../../../../../../../../store/model/board/selectors/getBoardCardCustomProperties';
import { IBoardExportSettingsColumn } from '../../../components/BoardExportSettings/types';

type TgetExportSortedColumns = (
    state: IApplicationState,
    boardId: TBoardId
) => IBoardExportSettingsColumn[];

export const getExportSortedColumns: TgetExportSortedColumns = createDeepEqualSelector(
    getBoardExportSettings,
    getBoardCardCustomProperties,
    (settings, customProperties) => {
        const columns = settings && settings.columns;
        let result: IBoardExportSettingsColumn[] = BOARD_EXPORT_SETTINGS_COLUMNS.map(label => ({
            id: label,
            label,
            enabled: !DEFAULT_UNENABLED_BOARD_EXPORT_SETTINGS_COLUMNS.includes(label)
        }));
        Object.values(customProperties).forEach(customProperty => {
            result.push({
                id: customProperty.id,
                label: customProperty.name,
                enabled: true
            })
        })
        if (columns) {
            result = result.map(column => {
                const currentColumn = columns.find(currentColumn => currentColumn.id === column.id);
                return {
                    ...column,
                    enabled: !currentColumn || currentColumn.enabled
                }
            })
            result.sort((column1, column2) => {
                const c1Index = columns.findIndex(column => column.id === column1.id);
                const c2Index = columns.findIndex(column => column.id === column2.id);
                if (c1Index === c2Index) return 0;
                if (c1Index === -1) return 1;
                if (c2Index === -1) return -1;
                return c1Index - c2Index;
            })
        }
        return result;
    }
)
