import {Dispatch, ThunkAction} from '../../../../../../../../../types/actions';
import {shareClick} from '../effect/shareClick';
import { shareClickedSegmentEvent } from '../../../../../effects/segmentEvents';
import { TBoardId } from '../../../../../../../../../types/types';
import { addFeatureTrigger } from '../../../../../../../polls/featurePoll/effects/addFeatureTrigger';
import { ERateFeature } from '../../../../../../../../../types/rest/IUserMeta';
import { SHARING_ERROR_MESSAGE } from '../../../../../constants';
import { segmentTrackAction, SegmentUserEvent } from '../../../../../../../../../middlewares/segment';

export const onShareClicked = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(shareClick(boardId));
        dispatch(addFeatureTrigger(ERateFeature.NEW_TOP_BAR));
        dispatch(shareClickedSegmentEvent());
        const elem = document.querySelector('.shr-bb-shr-cb.shr-c-shr-d-shr-kc-shr-cb') as HTMLElement;//привязка по классам Google, из-за невозможности привязаться по другому
        const observer = new MutationObserver(mutationRecords => {
            mutationRecords.forEach((record) => {
                if (record.attributeName === 'aria-hidden') {
                    const message = document.querySelector('.shr-bb-shr-cb.shr-c-shr-d-shr-ib-shr-cb') as HTMLElement;
                    if (message) {
                        const messageText = message.querySelector('.shr-bb-shr-cb-shr-he').textContent;
                        if (message && messageText === SHARING_ERROR_MESSAGE) {
                            dispatch(segmentTrackAction(SegmentUserEvent.SHARING_ERROR));
                        }
                    }
                }
            })
            observer.disconnect()
        });

        observer.observe(elem, { attributes : true });
    };
    return action;
};
