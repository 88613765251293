import { IGetState, TBoardId } from '../../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { getBoard } from '../../../../../../../../../store/model/selectors/getBoardById';
import { boardSetArchiveCardsOnDelete } from '../../../../../../../../../rest/effects/board/boardSetArchiveCardsOnDelete';
import { SegmentBoardEvent, segmentTrackAction } from '../../../../../../../../../middlewares/segment';
import {
    SegmentBoardOption,
    SegmentBoardOptionStatusValue
} from '../../../../../../../../../middlewares/segment/trackEntities/boardEvents';

export const toggleArchiveCardsOnDelete = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const board = getBoard(getState(), boardId);
        const archiveCardsOnDelete = !board.archiveCardsOnDelete;
        dispatch(segmentTrackAction(SegmentBoardEvent.ARCHIVE_ON_DELETE_TOGGLED, {
            name: SegmentBoardOption.STATUS,
            value: archiveCardsOnDelete ? SegmentBoardOptionStatusValue.ON: SegmentBoardOptionStatusValue.OFF}));
        dispatch(boardSetArchiveCardsOnDelete(boardId, archiveCardsOnDelete));
    }
    return action;
}
