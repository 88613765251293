import {IGetState, TBoardId} from '../../../types/types';
import {Dispatch, ThunkAction} from '../../../types/actions';
import {fetchHandler} from '../../../util/fetchHandler';
import Util from '../../../util/util';
import {REST_BOARD, REST_BOARD_UPDATE_PERMISSIONS} from '../../constants';
import {getRestHeadersGet} from '../../helpers/getRestHeadersHelper';
import {root} from 'app/store/constants';
import {isMicrosoftAccount} from 'app/store/model/authUser/selectors/isMicrosoftAccount';
import {PermissionSynchronizer} from 'app/helper/PermissionSynchronizer';
import {getBoard} from 'app/store/model/selectors/getBoardById';

export const boardUpdatePermissions = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        if(isMicrosoftAccount(state)){
            new PermissionSynchronizer(getBoard(state, boardId)).synchronize()
        } else {
            return fetchHandler(
                Util.getApiUrl(REST_BOARD + boardId + REST_BOARD_UPDATE_PERMISSIONS),
                getRestHeadersGet()
            ).then(() => {
                root.App.controller.getDashboardViewManager().setIsInitiatorBoardUpdatePermissions(true);
            });
        }
    };
    return action;
}
