import { ICards } from '../cards/types';
import { IApplicationState, TCardId } from '../../../types/types';
import { getCard } from './getCard';
import { getCardEpicsForProcessingPercentChange } from './getCardEpicsForProcessingPercentChange';
import { CARD_DONE_PROCESSING_STATUS } from '../../../view/react_components/main/topBar/constants';

export const getCardProcessingUpdates = (
    state: IApplicationState,
    cardId: TCardId,
    processingPercent: number,
    cards: ICards = {},
): ICards => {
    if (!cardId){
        return  cards;
    }
    const card = getCard(state, cardId);
    if (!card) return cards;

    const processingStatus = processingPercent === 100 ? CARD_DONE_PROCESSING_STATUS : '';
    let doneDate = null;

    if (processingPercent === 100) {
        doneDate = card.doneDate ? card.doneDate : Math.floor(new Date().getTime() / 1000);
    }
    cards[cardId] = {
        ...cards[cardId],
        processingStatus,
        processingPercent,
        doneDate
    };

    // пересчитываем ProcessingPercent вверх по дереву
    cards = getCardEpicsForProcessingPercentChange(state, card.epicId, cards);
    return cards;
};
