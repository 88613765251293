import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { loadDrivesFromBack } from '../../../../../../dialogs/openBoards/effects/loadDrives';
import { getBoard } from '../../../../../../../../store/model/selectors/getBoardById';
import { getDrivesForMove } from '../../../../../../dialogs/openBoards/store/drives/selectors/getDrivesForMove';
import { IGetState, TBoardId } from '../../../../../../../../types/types';

export const onLoadItems = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(loadDrivesFromBack())
            .then(() => {
                const state = getState();
                const board = getBoard(state, boardId);
                const driveId = board.driveId;
                return getDrivesForMove(state, driveId);
            });
    };
    return action;
}
