import { IGetState, TCardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { root } from '../../../../store/constants';
import { isPrintSetAction } from '../../aside_panel/asidePanel/store/actions/isPrintSetAction';
import { getAsidePanelIsPrint } from '../../aside_panel/asidePanel/store/selectors/getAsidePanelIsPrint';

export const openPrintCardTab = (
    cardId: TCardId,
    trigger: boolean = false
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (trigger) {
            return root.App.router.navigate(root.App.router.getUrl('c-' + cardId + '/print'), {trigger});
        }
        const state = getState();
        const isPrint = getAsidePanelIsPrint(state);
        if (isPrint) return;

        dispatch(isPrintSetAction(true));
        root.App.router.navigate(root.App.router.getUrl('c-' + cardId + '/print'), {trigger: false});

        setTimeout(() => { // лучше подождать, чтоб всё перерисовалось в readonly
            root.print();

            dispatch(isPrintSetAction(false));
            root.history.back();
        }, 500);
    };
    return action;
};
