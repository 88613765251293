import { IGetState, TCardId } from '../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../types/actions';
import { IDriveDocs } from '../../../../../../store/model/driveDocs/types';
import { driveDocsActionSetAction } from '../../../../../../store/model/actions/driveDocsActionSetAction';
import { driveDocsSet } from '../../../../../../store/model/driveDocs/actions/driveDocsSet';
import { driveDocsDelete } from '../../../../../../store/model/driveDocs/actions/driveDocsDelete';
import { deleteRest } from '../deleteRest';
import { cardUpdateAttachmentsCount } from '../../../cardUpdateAttachmentsCount';
import { TDriveDocId } from '../../../../../../types/rest/IRestDriveDoc';
import { sendRealTimeStoreActionByCardId } from '../../../../../../view/react_components/base/helpers/realTimeHelperTS';
import { getDriveDocsByCardId } from '../../../../../../store/model/driveDocs/selectors/getDriveDocsByCardId';

export const deleteRestHelper = (
    cardId: TCardId,
    driveDocId: TDriveDocId,
    isOptimistic: boolean = true,
    isRealtime: boolean = true // send event to socket
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();

        let oldDriveDocs = getDriveDocsByCardId(state, cardId, null);
        let oldDriveDocsMap: IDriveDocs = {};
        oldDriveDocs.forEach((doc) => {
            oldDriveDocsMap[doc.id] = doc;
        });

        const driveDocForDelete = oldDriveDocs.find(driveDoc => driveDoc.id === driveDocId);
        if (!driveDocForDelete) return Promise.resolve();

        const newDriveDocsMap: IDriveDocs = {};
        newDriveDocsMap[driveDocForDelete.id] = driveDocForDelete;

        let driveDocsForRollback: IDriveDocs;
        if (isOptimistic) {
            driveDocsForRollback = oldDriveDocsMap;
            dispatch(driveDocsActionSetAction(driveDocsDelete(newDriveDocsMap)));
        }
        return dispatch(deleteRest(cardId, driveDocId))
            .then(() => {
                if (isRealtime) {
                    dispatch(sendRealTimeStoreActionByCardId(cardId, driveDocsActionSetAction(driveDocsDelete(newDriveDocsMap))));
                }
            })
            .catch((e: any) => {
                console.error(e);
                if (isOptimistic) {
                    dispatch(driveDocsActionSetAction(driveDocsSet(driveDocsForRollback)));
                }
            });
    };
    return action;
};
