import { Dispatch } from '../../../../../types/actions';
import { IGetState } from '../../../../../types/types';
import { isPerformanceAllowedSelector } from '../selectors/isPerformanceAllowedSelector';
import { reboot } from './reboot';
import { getNewPerformanceAllowedValueSelector } from '../selectors/getNewPerformanceAllowedValueSelector';
import { clearPerformanceScripts } from './clearPerformanceScripts';

export const processPerformanceAllowForUnAuthUser = (
) => {
    const action = (dispatch: Dispatch, getState: IGetState) => {
        const state = getState();
        const dialogNewPerformanceAllowedValue = getNewPerformanceAllowedValueSelector(state);
        const dialogIsPerformanceAllowed = isPerformanceAllowedSelector(state);
        if (dialogIsPerformanceAllowed !== dialogNewPerformanceAllowedValue) {
            dispatch(clearPerformanceScripts());
            dispatch(reboot());
        }
    };
    return action;
};
