import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../../types/types';
import { onClick } from './events/onClick';
import { ITimeZonePanelButtonHOCProps } from './types';
import { TimeZonePanelButton } from '../../components/TimeZonePanelButton/TimeZonePanelButton';
import { ITimeZonePanelButtonEvents, ITimeZonePanelButtonFields } from '../../components/TimeZonePanelButton/types';

const mapStateToProps = (
    state: IApplicationState,
    { }: ITimeZonePanelButtonHOCProps
): ITimeZonePanelButtonFields => {
    return {
        tooltip: null
    }
};

const mapDispatchToProps = (
    dispatch: any,
    {onClick: ownOnClick}: ITimeZonePanelButtonHOCProps
): ITimeZonePanelButtonEvents => {
    return {
        onClick: () => dispatch(onClick(ownOnClick))
    }
};

export const TimeZonePanelButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(TimeZonePanelButton);

TimeZonePanelButtonHOC.displayName = 'TimeZonePanelButtonHOC';
