import { IGetState, TBoardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { boardCopy } from '../../../../../../../../rest/effects/board/boardCopy';
import { getAuthUser } from '../../../../../../../../store/model/authUser/selectors/getAuthUser';
import { showSnoozeBlocker } from '../../../../../../base/effects/showSnoozeBlocker';

export const onClick = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const isDisallow = !getAuthUser(getState()).allowCreateBoard;

        if (isDisallow) {
            dispatch(showSnoozeBlocker({
                allow: 'allowCreateBoard'
            }));
        } else {
            dispatch(boardCopy(boardId));
        }
    };
    return action;
};
