import * as React from 'react';
import BaseMigrateView from './view/base_migrate_view';
import { intersection, isEqual, keys } from 'underscore';
import ReactHtmlParser from "react-html-parser";

const App = window.App;

export const OldIcon = (props) => ((
    <span className={`icon icon--g ${props.iconClass || ''}`}>
        <SVG {...props} xlink={`svgicon--${props.xlink}`}/>
    </span>
));

OldIcon.defaultProps = {
    iconClass: ''
};

const SVG = (props) => {
    let string = '<use xlink="http://www.w3.org/1999/xlink" href="#' + props.xlink + '"></use>';
    return <svg className="svg-icon">{ReactHtmlParser(string, { decodeEntities: false })}</svg>;
};

class AbstractReactComponent extends React.Component {
    constructor(props) {
        super(props);
        this.initialize(props);
    }
    /**
     * @returns {Array}
     */
    getActions() {
        return [];
    }

    /**
     * Метод который прокидывает событие через гетевей в реакт вьюшку
     * @param where - в чем слушаем событие
     * @param what - какое событие слушаем
     * @param callback - метод который будет выполнен после события
     */
    listenTo(where, what, callback) {
        this.backbone.listenTo(where, what, this.eventGateway.bind(this, callback));
    }

    eventGateway(callback, ...arg) {
        callback.apply(this, arg);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
    }

    componentDidMount() {
        this.backbone = new BaseMigrateView(this);
        this.initEventListener();
        this.initStateUpdate();
    }

    initStateUpdate() {
        this.listenTo(App.vent, App.vent['react:state'], this.onReactStateChange.bind(this));
    }

    onReactStateChange(actions, state) {
        // Мы можем отправлять actions как константу если хотим обновить только 1 стейт
        // todo: нельзя изменять параметры
        actions = Array.isArray(actions) ? actions : [actions];

        if (intersection(this.getActions(), actions).length) {
            let componentStateKeys = keys(this.state);
            let stateUpdateProps = keys(state);

            let intersections = intersection(componentStateKeys, stateUpdateProps);
            let newStatePart = {};

            intersections.forEach(key => {
                // строгое сравнение лучше чем привидение
                if (componentStateKeys[key] !== state[key]) {
                    newStatePart[key] = state[key];
                }
            });
            if (newStatePart) {
                this.onStateUpdate(newStatePart);
            }
        }
    }

    onStateUpdate(newState) {
        this.setState(newState);
    }

    removeBackboneEvents() {
        this.backbone.remove();
        delete this.backbone;
    }

    componentWillUnmount() {
        this.removeBackboneEvents();
    }

    isMounted() {
        return Boolean(this.backbone);
    }

    // unmount() {
    //     try {
    //         return ReactDom.unmountComponentAtNode(ReactDom.findDOMNode(this).parentNode);
    //     } catch (e) {
    //         console.error('react unmount error');
    //     }
    //     // функция ожидает return
    //     return false;
    // }

    initialize(props) {

    }

    initEventListener() {

    }

    beforeRender() {
        this.props = Object.assign({}, this.props, this.state);
    }

    _render() {

    }

    render() {
        this.beforeRender();
        return this._render();
    }
}
export {SVG, AbstractReactComponent};
