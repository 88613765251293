import { IGetState, TBoardId, TCardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { cardSetBackgroundColor } from '../../../../../../../../rest/effects/card/card/cardSetBackgroundColor';
import { getBoardCardColors } from '../../../../../../../../store/model/selectors/getBoardCardColors';
import { SegmentCardEvent, SegmentCardOptions, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { SegmentCardColorTargetValue } from '../../../../../../../../middlewares/segment/trackEntities/cardEvents';
import { getCard } from '../../../../../../../../store/model/selectors/getCard';
import {
    getAuthUserAllowCardColorCount
} from '../../../../../../../../store/model/authUser/selectors/getAuthUserAllowCardColorCount';
import { showSnoozeBlocker } from '../../../../../../base/effects/showSnoozeBlocker';
import { root } from '../../../../../../../../store/constants';
import {
    getFilteredCardsForAmendedFilter
} from '../../../../../../../aside_panel/dashboard_filter/dashboard_filter_helper_card_filters';
import { getBoardCards } from '../../../../../../../../store/model/selectors/getBoardCards';

export const toggleCardColor = (
    cardId: TCardId,
    boardId: TBoardId,
    color: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const maxCount = getAuthUserAllowCardColorCount(state);
        const boardColors = getBoardCardColors(state, boardId);
        const count = boardColors.length;
        const card = getCard(state, cardId);
        const setColor = card.backgroundColor === color ? '' : color;
        if (
            !setColor &&
            count > maxCount &&
            root.App.controller.allowCounterSnackbarHelper.isShowSnackbar('allowCardColorCount') &&
            getFilteredCardsForAmendedFilter(
                getBoardCards(state, boardId),
                { cardColors: [color] },
                null
            ).length === 1
        ) {
           return Promise.resolve(false);
        } else if (setColor && count >= maxCount && !boardColors.includes(setColor)) {
            dispatch(showSnoozeBlocker({
                allow: 'allowCardColorCount'
            }));
        } else {
            return dispatch(cardSetBackgroundColor(cardId, setColor)).then(() => {
                const boardColors = getBoardCardColors(state, boardId);
                const option = {
                    name: SegmentCardOptions.TARGET,
                    value: boardColors.includes(color) ?
                        SegmentCardColorTargetValue.BOARD_COLORS :
                        SegmentCardColorTargetValue.ALL_COLORS
                };
                const addOption = {
                    name: SegmentCardOptions.COLOR,
                    value: setColor
                }
                dispatch(segmentTrackAction(SegmentCardEvent.BACKGROUND_CHANGED, option, [addOption]));
                return Promise.resolve(true);
            });
        }
    };
    return action;
};
