import { IApplicationState } from '../../../../../../../../types/types';
import { connect } from 'react-redux';
import { IPrefixesTogglerHOCProps } from './types';
import { getCardNumberByBoardId } from '../../store/cardNumbers/selectors/getCardNumberByBoardId';
import { SectionToggler } from '../../../../../../base/components/SectionToggler/SectionToggler';
import { ISectionTogglerFields } from '../../../../../../base/components/SectionToggler/types';
import { getCardNumberLoading } from '../../store/cardNumbers/selectors/getCardNumberLoading';
import { getPanelReadonly } from '../../../../selectors/getPanelReadonly';

const mapStateToProps = (
    state: IApplicationState,
    { boardId }: IPrefixesTogglerHOCProps
): ISectionTogglerFields => {
    const { enabled: checked } = getCardNumberByBoardId(state, boardId);
    const isDisabled = getPanelReadonly(state, boardId, null, null, 'allowChangeCardNumber') ||
        getCardNumberLoading(state, boardId);
    return {
        checked,
        isDisabled,
    };
};

export const PrefixesTogglerHOC = connect(
    mapStateToProps,
    null
)(SectionToggler);

PrefixesTogglerHOC.displayName = 'PrefixesTogglerHOC';
