import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState, TBoardId, TCardId } from '../../../../types/types';
import { clickManagerActionSet } from '../store/clickManager/actions/clickManagerActionSet';
import { selectedCardIdsSet } from '../store/clickManagerModel/actions/selectedCardIdsSet';
import { getSelectedCards } from '../selectors/getSelectedCards';
import { changeRoute } from './changeRoute';
import { getCardsIdsSelector } from '../selectors/getCardsIdsSelector';
import * as React from 'react';
import { getTypeSwitcherActiveType } from '../../typeSwitcher/store/selectors/getTypeSwitcherActiveType';
import {
    cardClickedWithCtrlSegmentEvent,
    cardClickedWithShiftSegmentEvent
} from '../../main/kanbanView/components/Card/hocs/CardHOC/events/cardSegmentEvents';
import { getCard } from '../../../../store/model/selectors/getCard';
import { segmentTrackAction } from '../../../../middlewares/segment';
import { SegmentMultiSelectEvent } from '../../../../middlewares/segment/trackEntities/multiselectEvents';

export const selectCard = (
    event: React.MouseEvent,
    boardId: TBoardId,
    cardId: TCardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const selectedCards = getSelectedCards(state, boardId);

        let newSelection: number[] = [];
        let isMultiselect = false;
        if (event.ctrlKey || event.metaKey) { // metaKey = command on Mac (win on Windows)
            if (selectedCards.length !== 1 || cardId !== selectedCards[0]) {
                isMultiselect = true;
                if (selectedCards.includes(cardId)) {
                    newSelection = selectedCards.filter(item => {
                        if (item !== cardId) {
                            return item;
                        }
                    });
                } else {
                    newSelection = [...selectedCards, cardId];
                }
                if (selectedCards.length === 1) {
                    dispatch(segmentTrackAction(SegmentMultiSelectEvent.OPENED));
                }

                dispatch(cardClickedWithCtrlSegmentEvent());
                dispatch(clickManagerActionSet(boardId, selectedCardIdsSet(newSelection, isMultiselect)));
            }
        } else if (event.shiftKey) {
            isMultiselect = true;
            const viewType = getTypeSwitcherActiveType(state, boardId);
            const card = getCard(state, cardId);
            const cardIdsSelector = getCardsIdsSelector(viewType, card);
            if (cardIdsSelector) {
                const cardIds = cardIdsSelector(state, boardId, cardId);
                if (selectedCards[selectedCards.length - 1] >= 0) {
                    const startCard = selectedCards[selectedCards.length - 1];
                    let startSelection = cardIds.indexOf(startCard);
                    let endSelection = cardIds.indexOf(cardId);
                    if (endSelection < startSelection) {
                        const temp = startSelection;
                        startSelection = endSelection;
                        endSelection = temp;
                    }
                    newSelection = cardIds.slice(startSelection, endSelection + 1);
                    if (selectedCards.length === 1 && newSelection.length > 1) {
                        dispatch(segmentTrackAction(SegmentMultiSelectEvent.OPENED));
                    }
                } else {
                    newSelection = [cardId];
                }
                dispatch(clickManagerActionSet(boardId, selectedCardIdsSet(newSelection, isMultiselect)));
                dispatch(cardClickedWithShiftSegmentEvent());
            }
        } else {
            dispatch(clickManagerActionSet(boardId, selectedCardIdsSet([cardId])));
        }
        dispatch(changeRoute(boardId));

    };
    return action;
};
