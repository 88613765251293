import {
    INavigationPanelAnchorDispatchProps,
    INavigationPanelAnchorStateProps
} from '../../components/NavigationPanelAnchor/types';
import { connect } from 'react-redux';
import { NavigationPanelAnchor } from '../../components/NavigationPanelAnchor/NavigationPanelAnchor';
import { onAnchorMouseHold } from './events/onAnchorMouseHold';
import { onAnchorMouseOut } from './events/onAnchorMouseOut';
import { onAnchorClick } from './events/onAnchorClick';
import { IApplicationState } from '../../../../../../types/types';
import { ENavigationPanelTypes } from '../../constants';
import { getCurrentNavigationPanelType } from '../../helpers/getCurrentNavigationPanelType';

const mapStateToProps = (
    state: IApplicationState,
): INavigationPanelAnchorStateProps => {
    const navigationPanelType = getCurrentNavigationPanelType(state);
    const isShown = navigationPanelType === ENavigationPanelTypes.NP_COMPACT_TYPE;
    return {
        isShown,
    }
};

const mapDispatchToProps = (
    dispatch: any,
): INavigationPanelAnchorDispatchProps => ({
    onMouseHold: () => dispatch(onAnchorMouseHold()),
    onMouseOut: () => dispatch(onAnchorMouseOut()),
    onClick: () => dispatch(onAnchorClick())
});

export const NavigationPanelAnchorHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(NavigationPanelAnchor);
NavigationPanelAnchorHOC.displayName = 'NavigationPanelAnchorHOC';
