export class MagicLinksFakeLinksHelper {
    links: {[key: string]: boolean} = {};

    addLink(link: string) {
        this.links[link] = true;
    }

    getIsFakeLink(link: string) {
        return this.links[link];
    }
}
