import * as React from 'react';
import { IActivityItemBodyProps } from './types';
import './_ActivityItemBody.scss';
import { CLASS_ACTIVITY_ITEM } from '../constants';
import { AsidePanelContext } from '../../../../../asidePanel/components/AsidePanel/constants';
import { NotifyButtonHOC } from '../../../../../cardDetails/notify/hocs/NotifyButtonHOC/NotifyButtonHOC';
import { UserpicStorage } from '../../../../../../base/components/UserpicStorage/UserpicStorage';
import { getInitials } from '../../../../../../helpers/userPhotoHelper';
import { getAsidePanelTooltip } from '../../../../../asidePanel/helpers/getAsidePanelTooltip';
import { Icon, Tooltip } from 'kui';
import { getDateAgo, getDateFormatted } from '../../../../../../helpers/dateService';
import { ERestActivityModelType } from '../../../../../../../../types/rest/activity/IRestActivityBase';
import { getParentsClasses } from '../../../../../../helpers/getParentsClasses';
import { CLASS_CARD_DETAILS } from '../../../../../cardDetails';
import { root } from '../../../../../../../../store/constants';
import { stripHtml } from '../../../../../../base/helpers/stripHtmlHelper';
import { escape } from 'underscore';
import { markdown } from '../../../../../../../../helper/markdownHelper';

export function ActivityItemBody({
    activity,
    children,
    className: classNameOwn,
    header,
    headerIcon,
    onClick
}: IActivityItemBodyProps) {
    const [isShowTooltip, setIsShowTooltip] = React.useState(false);
    const textRef = React.useRef(null);
    const { user, modelType, list, card, dashboardName } = activity as any;

    const className = CLASS_ACTIVITY_ITEM + '-body';
    const classHeader = className + '__header';
    const classFooter = className + '__footer';

    const { boardId, cardId, isUserTimeFormat12, asidePanelWidth } = React.useContext(AsidePanelContext);

    let footerIcon;
    let footerLabel;
    if (modelType === ERestActivityModelType.BOARD) {
        footerIcon = 'board';
        footerLabel = dashboardName;
    } else if (modelType === ERestActivityModelType.LIST) {
        footerIcon = 'list';
        footerLabel = list.name;
    } else if (modelType === ERestActivityModelType.CARD) {
        footerIcon = 'card';
        footerLabel = card.name;
    }

    const handleClick = (e: React.SyntheticEvent) => {
        if (!onClick) return;

        const className = CLASS_CARD_DETAILS + '__notify-button';
        const parentClasses = getParentsClasses(e.target as HTMLElement, [
            className,
            'aside-panel-actions-popup--notify',
        ]);
        if (parentClasses.includes(className)) return;
        if (parentClasses.includes('aside-panel-actions-popup--notify')) return;

        onClick();
    };

    React.useEffect(() => {
        function onResize() {
            if (textRef && textRef.current && textRef.current.scrollWidth > textRef.current.clientWidth) {
                setIsShowTooltip(true);
            } else {
                setIsShowTooltip(false);
            }
        }

        onResize();

        root.addEventListener('resize', onResize);
        return () => root.removeEventListener('resize', onResize);
    },[asidePanelWidth])

    return <div className={`${className} ${onClick ? className + '--action' : ''} ${classNameOwn || ''}`} onClick={handleClick}>
        <div className={classHeader}>
            {headerIcon ?
                <Icon className={classHeader + '-icon'} xlink={headerIcon} size={16} />
                : <UserpicStorage
                    initials={getInitials(user)}
                    size={24}
                    src={user.photoUrl}
                    tooltip={getAsidePanelTooltip({
                        direction: 'up-right',
                        value: user.fullName
                    })
                    }
                    userId={user.permissionId}
                />
            }
            <div className={classHeader + '-content'}>
                {header}
            </div>
            {!!boardId && !!cardId && // cardPanel
                <NotifyButtonHOC
                    boardId={boardId}
                    cardId={null}
                    activityId={activity.id}
                />
            }
        </div>
        {children &&
            <div className={className + '__content'}>
                {children}
            </div>
        }
        <div className={classFooter}>
            <div className={classFooter + '-label'}>
                <Icon xlink={footerIcon} size={16} />
                <Tooltip
                    {...getAsidePanelTooltip({
                        value: isShowTooltip ? escape(stripHtml(markdown(footerLabel))) : null,
                    })}
                >
                    <div
                        className={classFooter + '-text'}
                        ref={textRef}
                    >
                        {footerLabel}
                    </div>
                </Tooltip>
            </div>
            <Tooltip
                {...getAsidePanelTooltip({
                    value: getDateFormatted(activity.time, isUserTimeFormat12),
                })}
            >
                <div className={classFooter + '-time'}>
                    {getDateAgo(activity.time, isUserTimeFormat12)}
                </div>
            </Tooltip>
        </div>
    </div>;
};
