'use strict';

import moment from 'moment';
import {ITimeUnits} from '../../types/model';
import { getUserTimeFormat12 } from '../../store/model/authUser/selectors/getUserTimeFormat12';
import { getAppState } from '../../store/configureStore';

App.Views.CardNotificationBeforeDueDate = App.Views.BaseNotification.extend({
    getActions() {
        const {interval: timeValue, unit: timeType, dueDate} = this.notificationModel;
        const format = getUserTimeFormat12(getAppState()) ? 'DD MMM hh:mm A' : 'DD MMM HH:mm';
        const formattedDueDate = moment(new Date(dueDate * 1000)).format(format);
        const bodyMessage = Messages.getText('noty.card_notification_before_due_date');
        const titleMessage = Messages.getText('noty.card_notification_before_due_date_title');
        let actions = {
            action: titleMessage,
            actionData: {icon: 'deadline'},
            timeType,
            timeValue,
        };
        actions.actionData.title = actions.action  + ' ' + formattedDueDate;
        actions.bodyRaw = actions.timeValue + ' ' + this.unitToString(actions.timeType) + `${parseInt(actions.timeValue) === 1 ? ' ' : 's '}` + bodyMessage + '<br>' + formattedDueDate;
        return actions;
    },

    unitToString(unit){
        switch (unit) {
            case ITimeUnits.MINUTES: {
                return 'minute';
            }
            case ITimeUnits.HOURS: {
                return 'hour';
            }
            case ITimeUnits.DAYS: {
                return 'day'
            }
            case ITimeUnits.WEEKS: {
                return 'week'
            }
            case ITimeUnits.MONTHS: {
                return 'month'
            }
        }

    },

    onClick(e, notification) {
        const {dashboardId, card: {id: cardId}} = this.notificationModel;
        App.controller.cleverOpenCard(dashboardId, cardId);
        this.closeNotification(notification);
        e.stopPropagation();
    }
});
