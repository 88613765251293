import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState } from '../../../../../../../types/types';
import { searchFilter } from '../effects/searchFilter';
import { SegmentFilterEvent } from '../../../../../../../middlewares/segment/trackEntities/filterEvents';
import { filterSegmentTrackAction } from '../../../../../aside_panel/filterPanelBoard/effects/filterSegmentTrackAction';
import { root } from '../../../../../../../store/constants';
import { ETutorial } from '../../../../../../../types/rest/IUserMeta';

export const onFilterChange: ActionCreator<ThunkAction> = (
    key: string,
    boardId: number
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(searchFilter(key, boardId));
        if (key) dispatch(filterSegmentTrackAction(SegmentFilterEvent.SEARCH_FILTER, boardId));
        root.App.controller.showTutorial(ETutorial.FILTER_TUTORIAL);
    };
    return action;
};
