import {connect} from 'react-redux';
import {CreateTemplateButton} from '../../components/CreateTemplateButton/CreateTemplateButton';
import {IApplicationState} from '../../../../../../../types/types';
import {ICreateTemplateButtonEvents, ICreateTemplateButtonFields} from '../../components/CreateTemplateButton/types';
import {ICreateTemplateButtonHOCProps} from './types';
import {onClick} from './events/onClick';
import { getSectionType } from '../../../selectors/getSectionType';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { getIsUserBasicPlanOnly } from '../../../../../../../store/model/authUser/selectors/getIsUserBasicPlanOnly';

const mapStateToProps = (
    state: IApplicationState,
    props: ICreateTemplateButtonHOCProps,
): ICreateTemplateButtonFields => {
    let { boardId } = props;
    const sectionType = getSectionType(state, boardId, null, 'allowCreateBoard');
    const isDisallow = sectionType === ESettingsSectionType.DISALLOW;
    const isShow = (sectionType === ESettingsSectionType.DEFAULT || isDisallow);
    return {
        isBasicFeature: getIsUserBasicPlanOnly(state),
        isDisallow,
        isShow,
        tooltip: null,
    }
};
const mapDispatchToProps = (
    dispatch: any,
    { boardId }: ICreateTemplateButtonHOCProps
): ICreateTemplateButtonEvents => ({
    onClick: () => dispatch(onClick(boardId)),
});

export const CreateTemplateButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateTemplateButton);

CreateTemplateButtonHOC.displayName = 'CreateTemplateButtonHOC';
