import * as React from 'react';
import { IAsidePanelActionsButtonsGroupInheritedProps } from './types';

export function AsidePanelActionsButtonsGroup ({
    children
}: IAsidePanelActionsButtonsGroupInheritedProps) {
    return (
        <div className={'aside-panel__actions-buttons-group'}>
            {children}
        </div>
    );
};
