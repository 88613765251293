import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../types/types';
import { getAuthUserSettingsCommentsSort } from '../../../../../../../../store/model/authUser/selectors/getAuthUserSettingsCommentsSort';
import {
    SegmentCardCommentSorting,
    SegmentCardEvent,
    SegmentCardOptions,
    segmentTrackAction
} from '../../../../../../../../middlewares/segment';
import { authUserSetCommentsSortOrder } from '../../../../../../../../rest/effects/authUser/authUserSetCommentsSortOrder';
import { EUserSettingsCommentSort } from '../../../../../../../../types/rest/IRestAuthUser';

export const toggleCommentsSort = (): ThunkAction => {
    return (dispatch: Dispatch, getState: IGetState) => {
        const state = getState();
        const currentOrder = getAuthUserSettingsCommentsSort(state);
        dispatch(authUserSetCommentsSortOrder(currentOrder * -1));
        dispatch(segmentTrack());
    };
};

export const segmentTrack = (): ThunkAction => {
    return (dispatch: Dispatch, getState: IGetState) => {
        const state = getState();
        const currentOrder = getAuthUserSettingsCommentsSort(state);

        const direction = EUserSettingsCommentSort.ASC === currentOrder
            ? SegmentCardCommentSorting.ASC
            : SegmentCardCommentSorting.DESC;
        const eventOption = {
            name: SegmentCardOptions.COMMENT_ORDER,
            value: direction
        };
        dispatch(segmentTrackAction(SegmentCardEvent.COMMENT_SORTING_CLICKED, eventOption));
    };
};
