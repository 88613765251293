import { getMessages } from '../../../../../../../store/constants';

export const CARD_SECTION_ATTACHMENTS = getMessages().getText('card_form_section_attachments');
export const CARD_ATTACHMENTS_ADD = getMessages().getText('card_form_attachments_add');
export const CARD_ATTACHMENTS_LIST = getMessages().getText('card_form_attachments_tooltip_list');
export const CARD_ATTACHMENTS_GRID = getMessages().getText('card_form_attachments_tooltip_grid');
export const CARD_ATTACHMENTS_HINT = getMessages().getText('card_form_attachments_grid_hint');
export const CARD_ATTACHMENTS_LIST_HINT = getMessages().getText('card_form_attachments_list_hint');
export const CARD_ATTACHMENTS_LIST_TOOLTIP = getMessages().getText('card_form_attachments_list_tooltip');
export const CARD_ATTACHMENTS_FILE_CREATION = getMessages().getText('card_form_attachment_file_creation');
export const CARD_ATTACHMENTS_CREATE_SHARED_FILE = getMessages().getText('card_form_attachment_create_shared_file');

export const ATTACHMENTS_TIPS = [
    getMessages().getText('card_form_attachments_tip.1'),
    getMessages().getText('card_form_attachments_tip.2'),
    getMessages().getText('card_form_attachments_tip.3'),
];
