import { IGetState, TListId } from '../../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { editList } from '../../../../../../../main/kanbanView/effects/editList';

export const onOpenList = (
    listId: TListId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(editList(listId, true))
    };
    return action;
};
