import { IGetState, TBoardId } from '../../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { boardUpdateMeta } from '../../../../../../../../../rest/effects/board/boardUpdateMeta';
import { getBoardWorkListMove } from '../../../../../../../../../store/model/board/selectors/getBoardWorkListMove';

export const onWorkListMoveToggle = (
    boardId: TBoardId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(boardUpdateMeta(boardId, { isWorkListMove: !getBoardWorkListMove(getState(), boardId) }));
    };
    return action;
};
