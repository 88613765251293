import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState } from '../../../../../../../types/types';
import { root } from '../../../../../../../store/constants';
import { dispatch } from '../../../../../../../store/configureStore';
import { updateQualificationFormMeta } from '../../../../../../../rest/effects/authUser/qualificationForm/updateQualificationFormMeta';
import { getUserQualificationFormStatus } from '../../../../../../../store/model/authUser/selectors/getUserQualificationFormStatus';
import { EUserMetaQualificationFormStatus } from '../../../../../../../types/rest/IUserMeta';
import { segmentTrackAction } from '../../../../../../../middlewares/segment';
import { SegmentQuestionEvent } from '../../../../../../../middlewares/segment/trackEntities/questionEvents';

export const onDidMount = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        root.App.controller.htmlScrollDisabledToggle(true);
        const status = getUserQualificationFormStatus(getState());
        if (status === EUserMetaQualificationFormStatus.FIRST_TIME) {
            dispatch(updateQualificationFormMeta({
                status: EUserMetaQualificationFormStatus.ACTIVE
            }))
        } else {
            dispatch(segmentTrackAction(SegmentQuestionEvent.REOPENED));
        }
    };
    return action;
};

// @ts-ignore
window.resetForm = () => {
    dispatch(updateQualificationFormMeta({answers: {}, status: null}))
}
