import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { IGetState, TBoardId } from '../../../../../types/types';
import { fetchHandler } from '../../../../../util/fetchHandler';
import { getRestHeadersDelete } from '../../../../../rest/helpers/getRestHeadersHelper';

export const deleteMSUsersForRestoredBoard = (
    boardId: TBoardId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return fetchHandler(
            `/rest/dashboards/${boardId}/backupSharedUsers`,
            { ...getRestHeadersDelete(), }
        );
    };
    return action;
};
