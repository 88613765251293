import {
    IGetState,
    TCardId,
    TChecklistId,
    TChecklistItemId,
    TPermissionId
} from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { getBoardUsers } from '../../../../store/model/selectors/getBoardUsers';
import { checklistItemUpdate } from './checklistItemUpdate';
import { getChecklistItem } from '../../../../store/model/checklists/checklist/selectors/getChecklistItem';
import { IAssigneeRest } from '../../../../types/rest/IAssigneeRest';
import { addFeatureTrigger } from '../../../../view/react_components/polls/featurePoll/effects/addFeatureTrigger';
import { ERateFeature } from '../../../../types/rest/IUserMeta';

export const checklistItemAddUser = (
    boardId: number,
    cardId: TCardId,
    checklistId: TChecklistId,
    itemId: TChecklistItemId,
    permissionId: TPermissionId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const item = getChecklistItem(state, cardId, checklistId, itemId);
        if (!item) return Promise.reject();

        const assignees: IAssigneeRest[] = [...(item.assignees || [])];
        if (assignees.find(assignee => assignee.sharedUser.permissionId === permissionId)) return Promise.resolve();
        const user: IAssigneeRest =
            {
                sharedUser: getBoardUsers(state, boardId).find(user => user.permissionId === permissionId),
                roleIds: []
            };
        assignees.push(user);
        dispatch(addFeatureTrigger(ERateFeature.NEW_CHECKLISTS));
        return dispatch(checklistItemUpdate(cardId, checklistId, itemId, {
            assignees
        }));
    };
    return action;
};
