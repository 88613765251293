import * as React from 'react';
import { IRestCardCommentActivity } from '../../../../../../../types/rest/activity/IRestCardCommentActivity';
import { ERestActivityPropertyActionType } from '../../../../../../../types/rest/activity/IRestActivityBase';
import { IActivityProps } from '../../../../boardDetails/activity/TabActivity/types';
import { TabActivityContext } from '../../../../boardDetails/activity/TabActivity/components/TabActivity/constants';
import { TActivity } from '../../../../../../../store/activities/types';
import {
    ActivityItemBody
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemBody/ActivityItemBody';
import { ActivityHeader } from '../../../../boardDetails/activity/TabActivity/components/ActivityHeader/ActivityHeader';
import {
    ActivityItemName
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemName/ActivityItemName';
import { msFileThumbnailStorage } from '../../../../../../../helper/authorisation/microsoft/storage/msThumbnail';
import { blockMarkdown } from '../../../../../../../helper/markdownHelper';

export function CardActivityComment({
    activity
}: IActivityProps<IRestCardCommentActivity>) {
    const { onOpenCard } = React.useContext(TabActivityContext);
    const { label, card } = activity as TActivity;

    const [markdown, setMarkdown] = React.useState('');

    React.useEffect(() => {
        let comment = activity.newValue;
        if (activity.propertyActionType === ERestActivityPropertyActionType.DELETE) {
            comment = activity.oldValue;
        }

        setMarkdown(blockMarkdown(comment));

        if (comment && msFileThumbnailStorage.is()) {
            msFileThumbnailStorage.getSrcThumbnails(comment)
                .then((text) => {
                    setMarkdown(blockMarkdown(text));
                })
                .catch(() => {})
        }
    }, [activity]);

    return (
        <ActivityItemBody
            header={<ActivityHeader
                icon={'comment'}
                label={label}
            />}
            activity={activity}
            onClick={() => onOpenCard(card.id, activity.id, 'comments')}
        >
            <ActivityItemName
                name={markdown}
                isMarkdown
                tooltip={activity.propertyActionType === ERestActivityPropertyActionType.DELETE ? activity.oldValue : activity.newValue}
            />
        </ActivityItemBody>
    );
};
