import { IGetState, TBoardId } from 'app/types/types';
import { Dispatch, ThunkAction } from 'app/types/actions';
import { getBoard } from 'app/store/model/selectors/getBoardById';
import { EAuthUserPlatformType } from 'app/types/rest/IRestAuthUser';

export const getBackupLink = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const board = getBoard(getState(), boardId);
        if (board.platformType === EAuthUserPlatformType.MICROSOFT){
            return Promise.resolve(board.backupFolderInfo.webUrl);
        } else {
            const {google} = window as any;
            return Promise.resolve(`https://drive.google.com/drive/u/${google.authuser}/folders/${board.backupFolderInfo.folderId}`);
        }
    };
    return action;
};
