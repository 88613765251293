'use strict';

import { getActiveBoardId } from '../store/model/selectors/getActiveBoardId';
import { getAppState } from '../store/configureStore';
import * as _ from 'underscore';

App.Collections.Notifications = App.Collections.BaseCollection.extend({
    classKey: 'notifications',
    model: App.Models.Notification,
    url: function() {
        const params = {};
        const activeBoardId = getActiveBoardId(getAppState());
        if (this.options.isFilterActive) {
            params.dashboardId = (activeBoardId === 0? -1 :activeBoardId);
        }
        if (this.options.isReadFilterActive) {
            params.seen = true;
        }
        if (this.cursor) {
            params.cursor = this.cursor;
        }

        const query = new URLSearchParams(params).toString();
        return App.Util.getApiUrl('/rest/pushnotification/list' + (query? "?"+ query: ""));
    },
    initialize: function(models, options) {
        this.options = options || {};
        this.portion = 0; // помечаем, в какой порции приехала нотификация, чтобы для разных порций делать разные группы
        // this.on('change:orderNumber', this.sort, this);
    },

    refreshFilterActive: function(isFilterActive) {
        this.options.isFilterActive = isFilterActive;
        this.cursor='';
    },

    refreshReadFilterActive: function(isReadFilterActive) {
        this.options.isReadFilterActive = isReadFilterActive;
        this.cursor='';
    },

    fetch: function(options) {
        const startLoading = new Date().getTime();
        this.startLoading = startLoading;
        options = options || {};
        options.originalSuccess = options.success;
        options.startLoading = startLoading;
        options.success = _.bind(this.fetchSuccess, this);
        this.backUp = this.clone();
        Backbone.Collection.prototype.fetch.call(this, options);
    },

    fetchSuccess: function(collection, response, options) {
        if (this.startLoading !== options.startLoading){
            return;
        }
        var portion = ++this.portion;
        var backUpModels = this.backUp.filter(function(model) {
            return !model.get('cursor');
        });
        var uniqueModelsIds = [];
        var fetchedModels = _.filter(response.objects, function(obj) {
            var modelHistoryId = JSON.parse(obj.modelHistoryJson)[0].id;
            var same = _.find(backUpModels, function(model) {
                return model.getNotificationModel(0).id === modelHistoryId;
            });
            if (same || uniqueModelsIds.indexOf(modelHistoryId) > -1) return false;
            obj.portion = portion;
            uniqueModelsIds.push(modelHistoryId);
            return true;
        });
        var models = _.union(backUpModels, fetchedModels);
        this.reset(models);
        this.cursor = response.cursor;
        if (_.isFunction(options.originalSuccess)) {
            options.originalSuccess();
        }
    },

    hasMore: function() {
        return this.cursor && this.cursor != '';
    },

    getReadNotificationsCount: function() {
        return this.filter(item => item.get('seen')).length;
    },

    getUnreadNotificationsCount: function() {
        const factory = App.controller.getNotificationHelper().getNotificationFactory();
        // check if we can render this Notification
        return this.filter(item => !item.get('seen') && factory.getInstance(item)).length;
    },

});
