import { IApplicationState, IGetState, TCardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { EDependencyTypes, IDependency } from '../../../../store/model/dependencies/dependency/types';
import { dependenciesActionSetAction } from '../../../../store/model/actions/dependenciesActionSetAction';
import { dependencySetAction } from '../../../../store/model/dependencies/dependencies/actions/dependencySetAction';
import { getBoardIdByCardId } from '../../../../store/model/selectors/getBoardIdByCardId';
import { dependencyDeleteAction } from '../../../../store/model/dependencies/dependencies/actions/dependencyDeleteAction';
import { getCard } from '../../../../store/model/selectors/getCard';
import { ICards } from '../../../../store/model/cards/types';
import { dispatch } from '../../../../store/configureStore';
import { cardsRestPatch } from '../card/api/helper/cardsRestPatch';
import { postListRest } from './api/postListRest';
import { IRestPredecessor } from '../../../../types/rest/IRestPredecessor';
import { sendRealTimeStoreActionByBoardId } from '../../../../view/react_components/base/helpers/realTimeHelperTS';

export const addPredecessorList = (
    predecessors: IRestPredecessor[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        let state = getState();
        const dependencyList: IDependency[] = [];
        const boardIdList = new Set<number>();
        predecessors.forEach((predecessor) => {
            const tmpDependency: IDependency = {
                id: Date.now(),
                successorId: predecessor.cardId,
                type: EDependencyTypes.FINISH_TO_START,
                predecessorId: predecessor.predecessorId
            };
            dependencyList.push(tmpDependency);
            boardIdList.add(getBoardIdByCardId(state, tmpDependency.successorId));
            dispatch(dependenciesActionSetAction(dependencySetAction(tmpDependency.id, tmpDependency))); // optimistic set
        });
        return dispatch(postListRest(dependencyList))
            .then((respDependencies: IDependency[]) => {
                state = getState();
                respDependencies.forEach((dependency) => {
                    const actionSet = dependenciesActionSetAction(dependencySetAction(dependency.id, dependency));
                    dispatch(actionSet);
                    checkCardGanttVisibility(state, dependency.successorId);
                    checkCardGanttVisibility(state, dependency.predecessorId);
                    const boardId = getBoardIdByCardId(state, dependency.successorId);
                    dispatch(sendRealTimeStoreActionByBoardId(boardId, actionSet))
                })
            })
            .finally(() => {
                dependencyList.forEach((dependency) => {
                    dispatch(dependenciesActionSetAction(dependencyDeleteAction(dependency.id)));
                })
            });
    };
    return action;
};

const checkCardGanttVisibility = (state: IApplicationState, cardId: TCardId) => {
    const card = getCard(state, cardId);
    if (!card) {
        return Promise.reject();
    }
    if (card.ganttVisibility) {
        return Promise.resolve();
    }
    const cards: ICards = {
        [cardId]: {
            ganttVisibility: true,
        }
    };
    return dispatch(cardsRestPatch(cards));
}
