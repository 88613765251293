import { IGetState, TCardId, TChecklistId, TPermissionId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { toggleChecklistItemsUser } from '../effects/toggleChecklistItemsUser';
import { getBoardIdByCardId } from '../../../../../../../../store/model/selectors/getBoardIdByCardId';

export const onMassAssign = (
    cardId: TCardId,
    checklistId: TChecklistId,
    permissionId: TPermissionId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        const boardId = getBoardIdByCardId(getState(), cardId);
        return dispatch(toggleChecklistItemsUser(boardId, cardId, checklistId, permissionId));
    };
    return action;
};
