import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { onClose } from './events/onClose';
import { ICardColorTagsTooltipEvents, ICardColorTagsTooltipFields } from '../../components/CardColorTagsTooltip/types';
import { CardColorTagsTooltip } from '../../components/CardColorTagsTooltip/CardColorTagsTooltip';
import { getShowColorsEditTooltip } from '../../store/selectors/getShowColorsEditTooltip';

const mapStateToProps = (
    state: IApplicationState,
): ICardColorTagsTooltipFields => {
    return {
        isShow: getShowColorsEditTooltip(state)
    };
}

const mapDispatchToProps = (
    dispatch: any
): ICardColorTagsTooltipEvents => ({
    onClose: () => dispatch(onClose()),
});

export const CardColorTagsTooltipHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardColorTagsTooltip);

CardColorTagsTooltipHOC.displayName = 'CardColorTagsTooltipHOC';
