import { Dispatch, ThunkAction } from '../../../../../types/actions';
import {
    SegmentCardEvent,
    SegmentCardOptions,
    SegmentCardSection,
    segmentTrackAction,
} from '../../../../../middlewares/segment/';
import { SegmentCardSourceValue } from 'app/middlewares/segment/trackEntities/cardEvents';

export const disallowedSectionClickedEvent = (
    target: SegmentCardSection,
    location: SegmentCardSourceValue,
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(segmentTrackAction(
            SegmentCardEvent.DISALLOWED_SECTION_CLICKED,
            {
                name: SegmentCardOptions.TARGET,
                value: target
            },
            [{
                name: SegmentCardOptions.LOCATION,
                value: location
            }],
        ));
    };
    return action;
};
