import { TBoardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { boardOpen } from '../../../../../../base/effects/boardOpen';

export const onBoardClick = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(boardOpen(boardId));
    }
    return action;
}
