import { IGetState, TCardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { getCard } from '../../../../../../../../store/model/selectors/getCard';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { getDescriptionUnsaved } from '../../../store/cardDescriptionUnsaved/selectors/getDescriptionUnsaved';
import { cardDescriptionUnsavedDeleteAction } from '../../../store/cardDescriptionUnsaved/actions/cardDescriptionUnsavedDeleteAction';
import { cardSetDescription } from '../../../../../../../../rest/effects/cardSetDescription';
import { dispatch } from '../../../../../../../../store/configureStore';
import Util from 'app/util/util';

export const saveDescription = (
    cardId: TCardId,
    description: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const oldDescription = getCard(state, cardId).description;

        description = Util.setAuthuser0(description).trim();

        if (oldDescription === description) {
            dispatch(segmentTrackAction(SegmentCardEvent.SAVE_DESCRIPTION_WITHOUT_CHANGES_CLICKED));
            return Promise.resolve();
        } else {
            if (getDescriptionUnsaved(state, cardId)) {
                return dispatch(cardDescriptionUnsavedDeleteAction(cardId));
            }
            return dispatch(cardSetDescription(cardId, description));
        }
    };
    return action;
};
