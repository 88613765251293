import { IApplicationState } from '../../../../../../../types/types';
import { connect } from 'react-redux';
import { FilterGroupBy } from '../../components/FilterGroupBy/FilterGroupBy';
import { IFilterGroupByEvents, IFilterGroupByFields } from '../../components/FilterGroupBy/types';
import { onSelect } from './events/onSelect';
import { getMyWorkGroupBy } from '../../../../../main/myWorkView/selectors/getMyWorkGroupBy';
import { getShowProFeaturesTrial } from '../../../../../../../store/model/authUser/selectors/getShowProFeaturesTrial';
import { getIsUserBasicPlanOnly } from '../../../../../../../store/model/authUser/selectors/getIsUserBasicPlanOnly';

const mapStateToProps = (
    state: IApplicationState
): IFilterGroupByFields => {
    return {
        isBasicFeature: getIsUserBasicPlanOnly(state),
        isProFeature: getShowProFeaturesTrial(state),
        value: getMyWorkGroupBy(state)
    };
};

const mapDispatchToProps = (
    dispatch: any
): IFilterGroupByEvents => ({
    onSelect: (value) => dispatch(onSelect(value))
});

export const FilterGroupByHOC = connect(
    mapStateToProps, mapDispatchToProps
)(FilterGroupBy);

FilterGroupByHOC.displayName = 'FilterGroupByHOC';
