import { ICardAssigneeRole } from '../../../../types/model';
import { IApplicationState, TBoardId, TCardAssigneeRoleId } from '../../../../types/types';
import { getBoardRoles } from './getBoardRoles';
import { getBoard } from '../../selectors/getBoardById';

export const getBoardRoleById = (
    state: IApplicationState,
    boardId: TBoardId,
    roleId: TCardAssigneeRoleId,
): ICardAssigneeRole  => {
    return getBoardRoles(getBoard(state, boardId)).find((role) => role.id === roleId);
}
