import { IGetState, TBoardId, TCardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { copyLink } from '../../../../../../base/effects/copyLink';
import { SegmentCardEvent, SegmentCardOptions, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { SegmentCardSourceValue } from '../../../../../../../../middlewares/segment/trackEntities/cardEvents';

export const onGetLinkCard = (
    cardId: TCardId,
    boardId: TBoardId,
    source: SegmentCardSourceValue
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.GET_LINK_CARD_DROPDOWN_CLICKED, {
            name: SegmentCardOptions.SOURCE,
            value: source
        }));
        dispatch(copyLink(cardId, boardId));
    };
    return action;
};
