import { AsidePanelActionsButton } from '../../../../..';
import * as React from 'react';
import { IDatesPanelButtonProps } from './types';
import { CLASS_BOARD_DETAILS_SECTION } from '../../../../constants';
import { DATES_TITLE } from '../../constants';

export function DatesPanelButton ({
    onClick
}: IDatesPanelButtonProps) {

    return (
        <AsidePanelActionsButton
            children={null}
            sectionSelector={`.${CLASS_BOARD_DETAILS_SECTION}--dates`}
            title={DATES_TITLE}
            onClick={onClick}
        />
    );
}
