import {TABS} from '../store/constants';
import { TTabKey } from '../store/types';
import { getAuthUser } from '../../../../../store/model/authUser/selectors/getAuthUser';
import { IApplicationState } from '../../../../../types/types';
import { getAuthUserPlatformType } from 'app/store/model/authUser/selectors/getAuthUserPlatformType';
import { CommonAuthManager } from 'app/helper/authorisation/common/CommonAuthManager';

export const getIsTabAllowedSupportedHasScopes = (
    state: IApplicationState,
    key: TTabKey,
): boolean => {
    const user = getAuthUser(state);
    if (!user) return false;

    const tab = TABS[key];
    if (!tab) return false;

    if (tab.support && !user[tab.support]) return false;

    if (tab.allow && !user[tab.allow]) return false;

    if (tab.scopes) {
        const platformType = getAuthUserPlatformType(state);
        const scopes = tab.scopes[platformType];
        if (scopes && !CommonAuthManager.checkOneOfScopes(scopes.success)) {
            return false;
        }
    }

    return true;
};
