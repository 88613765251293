import { IPayloadIdAction, IPayloadAction, IPayloadIdKeyAction } from '../../constants';
import { ActionCreator } from 'redux';
import { IReportSettings, IReportRepresentableData } from '../../types/types';

export enum ReportsActionTypes {
    SET_ACTIVE_REPORT_TYPE = 'SET_ACTIVE_REPORT_TYPE',
    UNSET_ACTIVE_REPORT_TYPE = 'UNSET_ACTIVE_REPORT_TYPE',
    SET_ASIDE_SHOWN = 'SET_ASIDE_SHOWN',
    HIDE_ASIDE = 'HIDE_ASIDE',
    START_SYNC = 'START_SYNC',
    END_SYNC = 'END_SYNC',
}

export enum SingleReportActionTypes {
    SET_SETTINGS = 'SET_SETTINGS',
    SET_DATA = 'SET_DATA',
    SET_CASHE_SETTINGS = 'SET_CASHE_SETTINGS',
}

// this contains a dashboardId property
export type IReportModelAction = IPayloadIdAction<any>;

export const setActiveReportType: ActionCreator<IPayloadIdAction<number>> =
(
    dashboardId: number,
    reportType: number,
) => ({
    type: ReportsActionTypes.SET_ACTIVE_REPORT_TYPE,
    id: dashboardId,
    payload: reportType,
});

export const unsetActiveReportType: ActionCreator<IPayloadIdAction<undefined>> =
(
    dashboardId: number,
) => ({
    type: ReportsActionTypes.UNSET_ACTIVE_REPORT_TYPE,
    id: dashboardId,
    payload: undefined,
});

export const setAsideShown: ActionCreator<IPayloadIdAction<undefined>> =
(
    dashboardId: number,
) => ({
    type: ReportsActionTypes.SET_ASIDE_SHOWN,
    id: dashboardId,
    payload: undefined,
});

export const hideAside: ActionCreator<IPayloadIdAction<undefined>> =
(
    dashboardId: number,
) => ({
    type: ReportsActionTypes.HIDE_ASIDE,
    id: dashboardId,
    payload: undefined,
});

export const startSync: ActionCreator<IPayloadIdAction<undefined>> =
(
    dashboardId: number,
) => ({
    type: ReportsActionTypes.START_SYNC,
    id: dashboardId,
    payload: undefined,
});

export const endSync: ActionCreator<IPayloadIdAction<undefined>> =
(
    dashboardId: number,
) => ({
    type: ReportsActionTypes.END_SYNC,
    id: dashboardId,
    payload: undefined,
});

export const setReportSettings: ActionCreator<IPayloadIdKeyAction<IReportSettings>> =
(
    dashboardId: number,
    reportType: number,
    settings: IReportSettings,
) => ({
    type: SingleReportActionTypes.SET_SETTINGS,
    id: dashboardId,
    key: reportType,
    payload: settings,
})

export const setReportData: ActionCreator<IPayloadIdKeyAction<IReportRepresentableData>> =
(
    dashboardId: number,
    reportType: number,
    data: IReportRepresentableData,
) => ({
    type: SingleReportActionTypes.SET_DATA,
    id: dashboardId,
    key: reportType,
    payload: data,
})

export const setReportCasheData: ActionCreator<IPayloadIdKeyAction<IReportSettings>> =
(
    dashboardId: number,
    reportType: number,
    data: IReportSettings,
) => ({
    type: SingleReportActionTypes.SET_CASHE_SETTINGS,
    id: dashboardId,
    key: reportType,
    payload: data,
})
