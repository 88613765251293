import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { IGetState } from '../../../../../types/types';
import { isInitialSetAction } from '../store/filterPanel/actions/isInitialSetAction';
import { filterPanelActionSetAction } from '../store/filterPanelBoards/actions/filterPanelActionSetAction';
import { getIsDefaultFilterChanged } from '../store/selectors/getIsDefaultFilterChanged';
import { getSavedEnabledFiltersCountSelector } from '../store/selectors/getSavedEnabledFiltersCountSelector';

export const setFilterPanelIsInitial = (
    boardId: number,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        if (!getIsDefaultFilterChanged(getState(), boardId) &&
            !getSavedEnabledFiltersCountSelector(getState())
        ) {
            dispatch(filterPanelActionSetAction(boardId, isInitialSetAction(true)));
        }
    }
    return action;
};
