import { EFeature, LOGIN_SPECIFIC_BOARD_RELEASE } from 'app/types/rest/IUserMeta';
import { IApplicationState } from '../../../../../types/types';
import { getAuthUser } from '../getAuthUser';
import { isNewFeatureForUser } from '../isNewFeatureForUser';

export const getIsNewFeatureLoginSpecificBoard = (
    state: IApplicationState
): boolean => {
    const isNewFeature = isNewFeatureForUser(state, EFeature.LOGIN_SPECIFIC_BOARD);
    if (!isNewFeature) return false;

    const user = getAuthUser(state);
    if (user && user.createDateTimeStamp && user.createDateTimeStamp > LOGIN_SPECIFIC_BOARD_RELEASE) return false;

    return true;
};
