import { IApplicationState, TBoardId } from '../../../types/types';
import { getBoardPermissions } from './getBoardPermissions';
import { getBoardCardsCount } from './getBoardCardsCount';
import { TStatus } from '../../../types/model';
import { FREE_VERSION_CARDS_COUNT } from '../constants';
import { createSelector } from 'reselect';
import { dispatch, getAppState } from '../../configureStore';
import { segmentTrackAction, SegmentUserEvent } from '../../../middlewares/segment';
import Util from '../../../util/util';
import { getBoard } from './getBoardById';
import { IBoard } from '../board/types';

const isBoardCardsLimitReachedSelector = (
    board: IBoard,
    willTryCreateNewSome: number = 0
): boolean => {
    const state: IApplicationState = getAppState();
    const boardPermissions = getBoardPermissions(state, board.id);

    const allowEdit =
        boardPermissions &&
        boardPermissions.authPermissions &&
        boardPermissions.authPermissions.allowEdit;
    if (!allowEdit) {
        return false;
    }
    let allowUnlimitedCards = boardPermissions.authPermissions.allowUnlimitedCards;
    if (allowUnlimitedCards === undefined){
        dispatch(segmentTrackAction(SegmentUserEvent.ALLOW_UNLIMITED_CARDS_NOT_PRESENT));
        Util.devDebug(SegmentUserEvent.ALLOW_UNLIMITED_CARDS_NOT_PRESENT.event, true);
        allowUnlimitedCards = true;
    }
    if (allowUnlimitedCards){
        return false;
    }
    let boardCount = getBoardCardsCount(state, board.id, TStatus.STATUS_ACTIVE);
    boardCount = boardCount + willTryCreateNewSome;
    const result  = !allowUnlimitedCards &&  boardCount > FREE_VERSION_CARDS_COUNT;
    return result;
};

export const isBoardCardsLimitReached: (
    state: IApplicationState,
    boardId: TBoardId,
    willTryCreateNewSome: number
) => boolean = createSelector([
        (state: IApplicationState, boardId: TBoardId) => getBoard(state, boardId),
        (state: IApplicationState, boardId: TBoardId, willTryCreateNewSome: number) => willTryCreateNewSome ,
        (state: IApplicationState) => state.model.cards,
        (state: IApplicationState) => state.model.lists
    ],
    isBoardCardsLimitReachedSelector,
);

export const isBoardCardsLimitReachedCreateSelector = () => isBoardCardsLimitReached;
