import {INavigationPanelIsOpenSet} from './types';
import {AT_NAVIGATION_PANEL_IS_OPEN_SET} from './constants';

export const isOpenSetAction = (
    isOpen: boolean
): INavigationPanelIsOpenSet => ({
        type: AT_NAVIGATION_PANEL_IS_OPEN_SET,
        isOpen
    }
);
