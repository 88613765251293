import {AT_ADD_TO_LIST, AT_LIST_UPDATE, TBoardBackupsAction} from '../actions/types';
import {IBoardBackups, TBoardBackupList} from '../types';

const initialState: IBoardBackups = {
    list: []
};

export const boardBackupsReducer = (
    state = initialState,
    action: TBoardBackupsAction
): IBoardBackups => {
    switch (action.type) {
        case AT_LIST_UPDATE: {
            return {
                ...state,
                ... {
                    list: action.list
                }
            };
        }
        case AT_ADD_TO_LIST: {
            const list: TBoardBackupList = [...state.list];
            list.unshift(action.backup);
            return {
                ...state,
                ...{
                    list
                }
            };
        }
        default:
            return state;
    }
};
