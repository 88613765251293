import { getAuthUserPlatformType } from '../../store/model/authUser/selectors/getAuthUserPlatformType';
import { EAuthUserPlatformType } from '../../types/rest/IRestAuthUser';
import {
    getMSUsersForRestoredBoard
} from '../../view/react_components/dialogs/openBoards/effects/getMSUsersForRestoredBoard';
import { IMSRestoreUsers } from '../../view/react_components/dialogs/openBoards/store/types';
import { addFilePermissions } from '../authorisation/microsoft/graph/api/addFilePermissions';
import { getMessages, root } from '../../store/constants';
import { promiseSerial } from '../../util/promiseSerial';
import {
    deleteMSUsersForRestoredBoard
} from '../../view/react_components/dialogs/openBoards/effects/deleteMSUsersForRestoredBoard';
import * as _ from 'underscore';
import { IGetState, TBoardId, TDriveId } from '../../types/types';
import { Dispatch, ThunkAction } from '../../types/actions';
import { getAuthUser } from '../../store/model/authUser/selectors/getAuthUser';
import { EFilePermissionRole } from '../authorisation/microsoft/graph/api/getFilePermissions';
import { getBoard } from '../../store/model/selectors/getBoardById';

export const boardRestoreSharedUsersIfNeeded = (
    boardId: TBoardId,
    driveId: TDriveId,
    fileId: string,
    onSuccess: () => void,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const platformType = getAuthUserPlatformType(state);
        if (platformType === EAuthUserPlatformType.MICROSOFT) {
            dispatch(getMSUsersForRestoredBoard(boardId)).then((users: IMSRestoreUsers) => {
                if (!_.isEmpty(users)) {
                    const onShare = () => {
                        let count = 0;
                        root.App.controller.mainView.renderBlockerModal({
                            text: getMessages().getText('popup.dashboard.restoring.shared-users.text', null, count, Object.keys(users).length),
                            title: getMessages().getText('popup.dashboard.restoring.shared-users.header'),
                        });
                        const promises: Function[] = [];
                        const notAddedUsers: string[] = [];
                        const { email: userEmail } = getAuthUser(state);
                        for (let email in users) {
                            if (!users.hasOwnProperty(email) || email === userEmail) continue;
                            promises.push(() => {
                                return addFilePermissions(driveId, fileId, [email], users[email])
                                    .catch(() => {
                                        notAddedUsers.push(email);
                                    }).finally(() => {
                                        count += 1;
                                        if (root.App.controller.mainView.blockerModal) root.App.controller.mainView.blockerModal.update({
                                            text: getMessages().getText('popup.dashboard.restoring.shared-users.text', null, count, Object.keys(users).length),
                                            title: getMessages().getText('popup.dashboard.restoring.shared-users.header'),
                                        });
                                    });
                            });
                        }
                        promiseSerial(promises).then(() => {
                            const onFinish = () => {
                                root.App.controller.mainView.removeBlockerModal();
                                dispatch(deleteMSUsersForRestoredBoard(boardId));
                                onSuccess();
                            };
                            if (notAddedUsers.length) {
                                root.App.controller.showAlert({
                                    message: notAddedUsers.join(', '),
                                    header: getMessages().getText('popup.dashboard.restore-users.error.title'),
                                    subHeader: getMessages().getText('popup.dashboard.restore-users.error.subtitle'),
                                    type: 'question',
                                    buttons: [
                                        {
                                            text: getMessages().getText('popup.dashboard.restore-users.button.ok'),
                                            click: onFinish,
                                        },
                                    ],
                                    onClose: onFinish,
                                });
                            } else {
                                onFinish();
                            }
                        })
                    };
                    root.App.controller.showAlert({
                        message: getMessages().getText('popup.dashboard.restore-users.text'),
                        header: getMessages().getText('popup.dashboard.restore-users.title'),
                        type: 'question',
                        buttons: [
                            {
                                text: getMessages().getText('popup.dashboard.restore-users.button.close'),
                                click: onSuccess,
                            },
                            {
                                text: getMessages().getText('popup.dashboard.restore-users.button.share'),
                                click: onShare,
                            },
                        ],
                        onClose: onSuccess,
                    });
                } else {
                    onSuccess();
                }
            });
        } else {
            onSuccess();
        }

    };
    return action;
};
