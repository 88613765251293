const category = 'SignUp';

export const SegmentSignUpEvent = {
    CLICK_LIST: { category, event: 'List clicked on first screen select' },
    CLICK_BOARD: { category, event: 'Board clicked on first screen select' },
    CLICK_TEAM: { category, event: 'With my team clicked' },
    CLICK_PERSONAL: { category, event: 'For my personal usage clicked' },
};

export enum SegmentSignUpTarget {
    BUTTON = 'button',
    IMAGE = 'image',
}
