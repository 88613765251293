import { IGetState, TCardId, TListId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { ICardCopyRestOptions } from '../copy/api/types';
import { TStatus } from '../../../../types/model';
import { cardCopy } from '../copy/cardCopy';
import { IRestCard } from '../../../../types/rest/IRestCard';
import { cardOpen } from '../../../../view/react_components/base/effects/cardOpen';
import { sendUserProductivityStatsByCardId } from '../../../../helper/comet/stat_helper_ts';
import {
    EUserProductivityEventStatus
} from '../../../../view/react_components/reports/UserProductivity/UserProductivityReport/rest/types';
import { getNextCardOrderNumber } from '../../../../store/model/list/selectors/getNextCardOrderNumber';
import { cardsCopy } from '../copy/cardsCopy';
import { ICard } from '../../../../store/model/card/types';
import { getCard } from 'app/store/model/selectors/getCard';
import { root } from 'app/store/constants';
import { FAKE_CARD_ID } from 'app/const';
import { cardAddEpic } from '../card/cardAddEpic';
import { cardSetAssignees } from '../card/cardSetAssignees';
import { cardSetDueDate } from '../card/cardSetDueDate';
import { cardSetStartDate } from '../card/cardSetStartDate';
import { cardSetEstimate } from '../card/cardSetEstimate';
import { cardSetName } from '../card/cardSetName';
import { cardSetPriority } from '../card/cardSetPriority';
import { cardSetStartDateWithEpics } from '../card/cardSetStartDateWithEpics';
import { cardSetDueDateWithEpics } from '../card/cardSetDueDateWithEpics';
import { cardSaveColorTags } from '../card/cardSaveColorTags';
import {
    saveDescription
} from '../../../../view/react_components/aside_panel/cardDetails/DescriptionSection/hocs/CardDescriptionHOC/effects/saveDescription';

export const createCardFromTemplate = (
    templateCardId: TCardId,
    listId: TListId,
    copySubCards: boolean = false,
    cardData?: Partial<ICard>,
    isOpen?: boolean,
    isFirst?: boolean,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        let templateCard = getCard(getState(), templateCardId);
        if (cardData) {
            templateCard = {
                ...templateCard,
                ...cardData
            };
        }
        if (isOpen && open) {
            root.App.controller.asidePanel.showCard(FAKE_CARD_ID);
        }
        return runCopy(dispatch, getState, templateCard, listId, copySubCards, isFirst).then((cardId: TCardId) => {
            const card = getCard(getState(), cardId);
            if (card && card.assignees && card.assignees.length) {
                dispatch(sendUserProductivityStatsByCardId(card.id, EUserProductivityEventStatus.ASSIGNED_TO_CHANGED));
            }
            const promises = [];
            if (cardData) {
                if (cardData.assignees && cardData.assignees.length) {
                    promises.push(dispatch(cardSetAssignees(cardId, cardData.assignees)));
                }
                if (cardData.epicId) promises.push(dispatch(cardAddEpic(cardId, cardData.epicId)));
                if (cardData.startDate) promises.push(dispatch(cardSetStartDate(cardId, cardData.startDate)));
                if (cardData.dueDate) promises.push(dispatch(cardSetDueDate(cardId, cardData.dueDate)));
                if (cardData.estimate) promises.push(dispatch(cardSetEstimate(cardId, cardData.estimate)));
                if (cardData.name) promises.push(dispatch(cardSetName(cardId, cardData.name)));
            }
            Promise.all(promises).then(() => {
                if (isOpen) {
                    dispatch(cardOpen(cardId));
                }
            });
        })
    };
    return action;
};

const runCopy = (
    dispatch: Dispatch,
    getState: IGetState,
    templateCard: ICard,
    listId: TListId,
    copySubCards: boolean,
    isFirst?: boolean
) => {
    return new Promise((resolve) => {
        const options: ICardCopyRestOptions = {
            listId: listId,
            resetAssignees: false,
            status: TStatus.STATUS_ACTIVE,
            useSourceCardName: true,
            setAuthUserToCardAuthor: true
        }
        if (copySubCards) {
            return dispatch(cardsCopy(templateCard.id, true, options)).then((copyCardId: TCardId) => {
                return resolve(copyCardId);
            });
        } else {
            options.orderNumber = getNextCardOrderNumber(getState(), listId, isFirst)
            options.name = templateCard.name;
            return dispatch(cardCopy(templateCard.id, true, options)).then((cardResponse: IRestCard)=> {
                const cardId = cardResponse.id;

                if (templateCard.priority !== cardResponse.priority) {
                    return dispatch(cardSetPriority(cardId, templateCard.priority)).then(() => cardResponse);
                } else {
                    return cardResponse;
                }
            }).then((cardResponse: IRestCard) => {
                const cardId = cardResponse.id;
                if (templateCard.startDate !== cardResponse.startDate) {
                    return dispatch(cardSetStartDateWithEpics(cardId, templateCard.startDate)).then(() => cardResponse);
                } else {
                    return cardResponse;
                }
            }).then((cardResponse: IRestCard) => {
                const cardId = cardResponse.id;
                if (templateCard.dueDate !== cardResponse.dueDate) {
                    return dispatch(cardSetDueDateWithEpics(cardId, templateCard.dueDate)).then(() => cardResponse);
                } else {
                    return cardResponse;
                }
            }).then((cardResponse: IRestCard) => {
                const cardId = cardResponse.id;
                if (templateCard.description !== cardResponse.description) {
                    return dispatch(saveDescription(cardId, templateCard.description)).then(() => cardResponse);
                } else {
                    return cardResponse;
                }
            }).then((cardResponse: IRestCard) => {
                const cardId = cardResponse.id;
                let isChangeAssignees = templateCard.assignees.length !== (cardResponse.assignees || []).length;
                for (let i = 0; i < templateCard.assignees.length; i++) {
                    if (isChangeAssignees) break;
                    const assignee = templateCard.assignees[i];
                    if (
                        !(cardResponse.assignees || []).find(
                            item => item.sharedUser.permissionId === assignee.sharedUser.permissionId
                        )
                    ) {
                        isChangeAssignees = true;
                    }
                }
                if (isChangeAssignees) {
                    return dispatch(cardSetAssignees(cardId, templateCard.assignees)).then(() => cardResponse);
                } else {
                    return cardResponse;
                }
            }).then((cardResponse: IRestCard) => {
                const cardId = cardResponse.id;
                let isChangeColorTags = templateCard.colorIds.length !== (cardResponse.colorIds || []).length;
                for (let i = 0; i < templateCard.colorIds.length; i++) {
                    if (isChangeColorTags) break;
                    const colorTag = templateCard.colorIds[i];
                    if ((cardResponse.colorIds || []).indexOf(colorTag) < 0) {
                        isChangeColorTags = true;
                    }
                }
                if (isChangeColorTags) {
                    return dispatch(cardSaveColorTags(cardId, templateCard.colorIds)).then(() => resolve(cardId));
                } else {
                    return resolve(cardId);
                }
            });
        }
    })
}
