import * as React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import './_ColorsTooltip.scss';
import { IColorsTooltipProps } from './types';
import { Icon, Tooltip } from 'kui';
import { markdown } from '../../../../../helper/markdownHelper';
import { GOOGLE_SPACING } from '../../../../../const';
import { getAsidePanelTooltip } from '../../../aside_panel/asidePanel/helpers/getAsidePanelTooltip';
import ReactHtmlParser from 'react-html-parser';

export function ColorsTooltip ({
    colors,
    isNoPortal,
    onClick,
    isAsidePanel,
    ...attributes
}: IColorsTooltipProps) {
    if (!colors.length) return null;

    const itemRef = React.useRef(null);

    const className = 'colors-tooltip';
    const classItem = className + '__item';
    const classTooltipItem = className + '__tooltip-item';
    const classTooltipItemName = className + '__tooltip-item-name';

    const itemsTooltip = colors.reduce((tooltip, color) => {
        return tooltip + (`${renderToStaticMarkup(
            <div className={`${classTooltipItem} ${classTooltipItem}--${color.color}`}>
                <Icon size={24} xlink={'color-tag'} style={{ color: color.color }} />
                <div className={classTooltipItemName}>{ReactHtmlParser(markdown(color.name))}</div>
            </div>
        )}`);
    }, '');

    /**
     * 10 колортегов = высокий тултип, может уйти ниже или выше экрана
     */
    const onShow = () => {
        if (!itemRef.current) return;

        const rect = itemRef.current.getBoundingClientRect();
        if (rect.top < 0) {
            itemRef.current.style.marginTop = (GOOGLE_SPACING - rect.top) + 'px';
            // @ts-ignore
        } else if (rect.bottom > window._innerHeight) {
            // @ts-ignore
            itemRef.current.style.marginTop = (window._innerHeight - rect.bottom - GOOGLE_SPACING) + 'px';
        }
    }

    return (
       <Tooltip
           {...(isAsidePanel ? getAsidePanelTooltip({
                   direction: 'left',
                   isNoWrap: true,
                   ref: itemRef,
                   value: itemsTooltip,
                   onShow,
               })
               : {
                   direction:'left',
                   isNoWrap: true,
                   ref: itemRef,
                   value: itemsTooltip,
                   onShow: onShow
               })}
           {...attributes}
        >
            <div className={className} >
                {colors.map(color => {
                    return (
                        <div
                            onClick={onClick}
                            className={`${classItem} ${classItem}--${color.color}`}
                            key={color.id}
                            style={{ background: color.color }}
                        />
                    );
                })}
            </div>
        </Tooltip>
    );
};
