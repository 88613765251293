import { IApplicationState } from '../../../../../types/types';
import { TypeSwitcher } from '../../components/TypeSwitcher/TypeSwitcher';
import { connect } from 'react-redux';
import { ITypeSwitcherEvents, ITypeSwitcherFields } from '../../components/TypeSwitcher/types';
import { getTypeSwitcherActiveType } from '../../store/selectors/getTypeSwitcherActiveType';
import { ITypeSwitcherHOCProps } from './types';
import { onTypeChanged } from './events/onTypeChanged';
import { getTypeSwitcherLastKanbanView } from '../../store/selectors/getTypeSwitcherLastKanbanView';
import {
    filterPanelBoardsSelector
} from 'app/view/react_components/aside_panel/filterPanelBoard/store/selectors/filterPanelBoardsSelector';
import { getAsidePanel } from 'app/view/react_components/aside_panel/asidePanel/store/selectors/getAsidePanel';
import { getShowProFeaturesTrial } from '../../../../../store/model/authUser/selectors/getShowProFeaturesTrial';
import { EHintKey } from '../../../hints';
import { getActiveHint } from '../../../hints/store/hints/selectors/getActiveHint';
import { getIsUserBasicPlanOnly } from '../../../../../store/model/authUser/selectors/getIsUserBasicPlanOnly';
import { onHideHint } from './events/onHideHint';

const mapStateToProps = (
    state: IApplicationState,
    { boardId }: ITypeSwitcherHOCProps,
): ITypeSwitcherFields => {
    const active = getTypeSwitcherActiveType(state, boardId);
    const lastKanbanView = getTypeSwitcherLastKanbanView(state, boardId);
    const { isOpened } = filterPanelBoardsSelector(state, boardId);

    const activeHint = getActiveHint(state);

    return {
        active,
        lastKanbanView,
        isAsidePanelOpened: !!getAsidePanel(state).width,
        isBasicFeature: getIsUserBasicPlanOnly(state),
        isFilterInfoPanel: isOpened,
        isShowHint: activeHint && activeHint.key === EHintKey.GANTT_CONTEXT_GANTT_TYPE_SWITCHER,
        isProFeature: getShowProFeaturesTrial(state),
    }
};

const mapDispatchToProps = (
    dispatch: any,
): ITypeSwitcherEvents => {
    return {
        onHideHint: () => dispatch(onHideHint()),
        onTypeSelected: (viewType) => dispatch(onTypeChanged(viewType)),
    }
};

export const TypeSwitcherHOC = connect(
    mapStateToProps,
    mapDispatchToProps,
)(TypeSwitcher);

TypeSwitcherHOC.displayName = 'TypeSwitcherHOC';
