import { IGetState, TBoardId, TCardId } from 'app/types/types';
import { Dispatch, ThunkAction } from 'app/types/actions';
import { selectFiles } from '../effects/selectFiles';
import { addAttachmentSegmentEvent } from '../../../CardAttachmentsSectionHOC/effects/segmentEffects';
import { addAttachments } from 'app/rest/effects/card/attacments/addAttachments';
import { IDriveDoc } from 'app/store/model/card/types/IDriveDoc';
import { setAttachmentAddedSessionStorage } from '../../../../helpers/setAttachmentAddedSessionStorage';

export const onAddClicked = (
    boardId: TBoardId,
    cardId: TCardId,
    isDialog?: boolean, // показать диалог Select, drag, paste
    onClose?: () => void,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(addAttachmentSegmentEvent());
        if (!isDialog) {
            return dispatch(selectFiles(cardId))
                .then((driveDocs: IDriveDoc[]) => {
                    return dispatch(addAttachments(cardId, driveDocs));
                })
                .then((driveDocs: IDriveDoc[]) => {
                    setAttachmentAddedSessionStorage();
                    return driveDocs;
                });
        }
    };
    return action;
};
