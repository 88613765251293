import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState } from '../../../../types/types';
import { root } from '../../../../store/constants';
import { ASSIGNED_TO_ME_BACK_BOARD_LOCALSTORAGE } from 'app/const';
import { getActiveBoardId } from 'app/store/model/selectors/getActiveBoardId';
import { openAssignedToMeClickedSegmentEvent } from '../../main/navigationPanel/effects/segmentEvents';

export const openATM = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        root.App.router.setCurrentDashboard(null, false);
        localStorage.setItem(ASSIGNED_TO_ME_BACK_BOARD_LOCALSTORAGE, String(getActiveBoardId(getState())));
        root.App.router.navigate(root.App.router.getAssignedToMeUrl(), {trigger: true});
        dispatch(openAssignedToMeClickedSegmentEvent());
    };
    return action;
};
