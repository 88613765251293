import {IGetState, TCardId} from '../../../../types/types';
import {Dispatch, ThunkAction} from '../../../../types/actions';
import {getCard} from '../../../../store/model/selectors/getCard';
import {ICards} from '../../../../store/model/cards/types';
import {cardsRestPatch} from './api/helper/cardsRestPatch';

export const cardToggleWatch = (
    cardId: TCardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const card = getCard(state, cardId);
        if (!card) return Promise.reject();

        const wtch = !!card.wtch;

        const cards: ICards = {
            [cardId]: {
                wtch: !wtch,
            }
        };
        return dispatch(cardsRestPatch(cards));
    };
    return action;
};
