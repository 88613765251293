import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IApplicationState, IGetState, TBoardId } from '../../../../types/types';
import { IAuthUser } from '../../../../store/model/authUser/types';
import { getAuthUser } from '../../../../store/model/authUser/selectors/getAuthUser';
import { IRecentBoard } from '../../../../types/rest/IUserMeta';
import { getUserRecentBoards } from '../../../../store/model/authUser/selectors/getUserRecentBoards';
import { getBoard } from '../../../../store/model/selectors/getBoardById';
import { getTab } from '../../../../view/react_components/main/navigationPanel/store/selectors/getTab';
import { getBoard as getOpenBoard } from '../../../../view/react_components/dialogs/openBoards/store/boards/selectors/getBoard';
import { getUserRecentBoard } from '../../../../store/model/authUser/selectors/getUserRecentBoard';
import { IBoard } from '../../../../store/model/board/types';
import { USER_RECENT_BOARDS_MAX_SIZE } from './constants';
import { authUserRecentBoardsPatch } from './helper/authUserRecentBoardsPatch';
import { isEqual } from 'underscore';
import { getBoardLogoColor } from '../../../../store/model/selectors/getBoardLogoColor';
import { getBoardLogoColorNew } from '../../../../store/model/selectors/getBoardLogoColorNew';

export const addRecentOpenBoards: ActionCreator<ThunkAction> = (
    boardIdList: number[],
    placeToTop: boolean = false
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (!boardIdList || boardIdList.length < 1)
            return Promise.resolve();
        const state = getState();
        const user: IAuthUser = getAuthUser(state);
        let recentBoards: IRecentBoard[] = [...getUserRecentBoards(state)];
        let promises: Promise<any>[] = [];
        boardIdList.forEach((boardId) => {
            const newBoard: IRecentBoard = createRecentBoard(state, boardId);
            if (newBoard && newBoard.id) {
                const indexPos = recentBoards.findIndex((board) => board.id === boardId);
                let needUpdate = placeToTop // надо ли обновить позицию
                    ? indexPos !== 0 || boardIdList.length > 1
                    : indexPos < 0;
                if (!needUpdate) {
                    needUpdate = !isEqual(newBoard, recentBoards[indexPos]); // надо ли обновить доску
                }
                if (needUpdate) {
                    recentBoards = putBoardToTopList(newBoard, recentBoards);
                    promises.push(dispatch(authUserRecentBoardsPatch(user.id, recentBoards)));
                }
            }
        })
        return Promise.all(promises);
    };
    return action;
};

const putBoardToTopList = (newBoard: IRecentBoard, list: IRecentBoard[]): IRecentBoard[] => {
    let result = list.filter((board) => board.id !== newBoard.id);
    result = [newBoard, ...result];
    if (result.length > USER_RECENT_BOARDS_MAX_SIZE) {
        result.splice(USER_RECENT_BOARDS_MAX_SIZE);
    }
    return result;
}

const createRecentBoard = (
    state: IApplicationState,
    boardId: TBoardId,
): IRecentBoard => {
    let ret: IRecentBoard;

    const recentBoard: IRecentBoard = getUserRecentBoard(state, boardId);
    ret = recentBoard ? {...recentBoard} : { // существующий recentBoard или новый
        id: null,
        name: null,
    }

    let board: IBoard = getBoard(state, boardId);
    if (!board || !board.id) {
        board = getTab(state, boardId) || getOpenBoard(state, boardId);
    }

    if (board && board.id) { // обновить данными из доски
        for (let k in ret) {
            const key = k as keyof IRecentBoard;
            if (board[key] !== undefined) ret = {
                ...ret,
                [key]: board[key]
            };
        }
        ret.logo = board.logo;
    }

    if (!ret.logoColor) {
        ret.logoColor = getBoardLogoColor(state, boardId) || getBoardLogoColorNew();
    }

    return ret;
}
