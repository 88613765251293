import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../../types/types';
import { onClick } from './events/onClick';
import { IBoardEmailPanelButtonHOCProps } from './types';
import { BoardEmailPanelButton } from '../../components/BoardEmailPanelButton/BoardEmailPanelButton';
import { IBoardEmailPanelButtonEvents, IBoardEmailPanelButtonFields } from '../../components/BoardEmailPanelButton/types';

const mapStateToProps = (
    state: IApplicationState,
    { }: IBoardEmailPanelButtonHOCProps
): IBoardEmailPanelButtonFields => {
    return {
        tooltip: null
    }
};

const mapDispatchToProps = (
    dispatch: any,
    {onClick: ownOnClick}: IBoardEmailPanelButtonHOCProps
): IBoardEmailPanelButtonEvents => {
    return {
        onClick: () => dispatch(onClick(ownOnClick))
    }
};

export const BoardEmailPanelButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(BoardEmailPanelButton);

BoardEmailPanelButtonHOC.displayName = 'BoardEmailPanelButtonHOC';
