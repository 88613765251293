import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState } from '../../../../../../../types/types';
import { segmentTrackAction, SegmentUserEvent } from '../../../../../../../middlewares/segment';
import { SegmentUserOption } from '../../../../../../../middlewares/segment/trackEntities/userEvents';
import { EQuickStartGuideType } from '../../../constants';

export const onClick = (
    key: EQuickStartGuideType,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentUserEvent.QUICK_START_GUIDE_OPENED, {
            name: SegmentUserOption.VALUE,
            value: key
        }));
    };
    return action;
};
