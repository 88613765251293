import {IGetState, TBoardId} from '../../../../../../../../../types/types';
import {Dispatch, ThunkAction} from '../../../../../../../../../types/actions';
import {PwcChecklistTemplatesImport} from './checklistTemplateImport';
import {root} from 'app/store/constants';
import {reopenBoard} from 'app/view/react_components/aside_panel/boardDetails/details/PwcImport/hocs/BoardPwcImportHOC/effects/reopenBoard';
import {deleteSnackbar, snackbarProgressAdd, snackbarProgressUpdate} from 'app/view/react_components/snackbarsStack';
import {
    SNACKBAR_BOARD_IMPORT_ID,
    SNACKBAR_PWC_TEMPLATE_CHECKLISTS_IMPORT_PROGRESS_TEXT
} from 'app/view/react_components/dialogs/openBoards/FooterImport/hocs/FooterImportHOC/constants';

export const onDriveDocSelect = (
    fileId: any,
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(snackbarProgressAdd({
            id: SNACKBAR_BOARD_IMPORT_ID,
            text: SNACKBAR_PWC_TEMPLATE_CHECKLISTS_IMPORT_PROGRESS_TEXT,
            buttonProgress: 0
        }));
        new PwcChecklistTemplatesImport(
            boardId,
            fileId,
            dispatch,
            getState,
            (warnings) => {
                dispatch(deleteSnackbar(SNACKBAR_BOARD_IMPORT_ID));
                const message = warnings.map((e) => e.name + ' ')
                if (message.length) {
                    root.App.controller.showWarning(message.join('<br>'),
                        undefined,
                        () => {
                            dispatch(reopenBoard(boardId))
                        },
                        () => {
                            dispatch(reopenBoard(boardId))
                        });
                } else {
                    dispatch(reopenBoard(boardId))
                }
            },
            (errors) => {
                root.App.controller.showError(errors.map((e) => e.name + ' <br>'));
                dispatch(deleteSnackbar(SNACKBAR_BOARD_IMPORT_ID));
            },
            (progressPercent => {
                dispatch(snackbarProgressUpdate({
                    id: SNACKBAR_BOARD_IMPORT_ID,
                    text: SNACKBAR_PWC_TEMPLATE_CHECKLISTS_IMPORT_PROGRESS_TEXT,
                    buttonProgress: progressPercent
                }));
            })
        ).import().catch(e => console.log(e));
    }
    return action;
}
