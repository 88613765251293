'use strict';

App.Views.CardUpdateListNotification = App.Views.BaseNotification.extend({

    getActions: function() {
        var actions = {
            action: Messages.getText('noty.list_change'),
            actionData: {
                icon: 'move'
            },
            badge: {
                text: this.notificationModel.oldList.name,
                textAfter: this.notificationModel.newList.name
            },
            bodyRaw: Messages.getText('noty.moved_to').replace(/<[^>]+>/g, '')
        };
        actions.body = actions.badge.text + ' ' + actions.bodyRaw + ' ' + actions.badge.textAfter;
        return actions;
    },

    onClick: function(e, notification) {
        var dashboardId = this.notificationModel.dashboardId;
        var cardId = this.notificationModel.card.id;
        App.controller.cleverOpenCard(dashboardId, cardId);
        this.closeNotification(notification);
        e.stopPropagation();
    }
});
