import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { highlightCardIdSet } from '../../../../../store/actions/highlightCardIdSet';
import { TCardId } from '../../../../../../../../../types/types';
import { getHighlightCardId } from '../../../../../store/selectors/getHighlightCardId';

export const onAnimationEnd = (
    cardId: TCardId
): ThunkAction => {
    return (
        dispatch: Dispatch,
        getState
    ) => {
        const animatedCardId = getHighlightCardId(getState());
        if (cardId === animatedCardId) {
            dispatch(highlightCardIdSet(null));
        }
    };
};
