import { AsidePanelActionsButton } from '../../../..';
import * as React from 'react';
import { ICommentsPanelButton } from './types';
import { CLASS_ASIDE_PANEL_ACTIONS_POPUP } from '../../../../asidePanel/components/AsidePanelActionsButton/constants';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { CARD_SECTION_COMMENTS, CLASS_COMMENTS_SECTION, CLASS_COMMENTS_SECTION_FORM } from '../../constants';
import { EAsidePanelProperty } from '../../../../asidePanel/components/AsidePanel/types';
import { CommentsSectionPopup } from '../CommentsSectionPopup/CommentsSectionPopup';
import { getAsidePanelActionsButtonTooltip } from 'app/view/react_components/aside_panel/asidePanel/helpers/getAsidePanelActionsButtonTooltip';

export function CommentsPanelButton({
    commentCount,
    isShow,
    tooltip,
    onClick
}: ICommentsPanelButton) {
    if (!isShow) return null;

    const { boardId, cardId, showedProperties } = React.useContext(AsidePanelContext);
    const isShowed = showedProperties.has(EAsidePanelProperty.CARD_COMMENTS);
    const [isOpened, setIsOpened] = React.useState(null);
    const onOpened = () => setIsOpened(true);
    const onClosed = () => {
        setIsOpened(false);
    }

    return (
        <AsidePanelActionsButton
            dropdownClassName={`${CLASS_ASIDE_PANEL_ACTIONS_POPUP}--comments`}
            indicatorNumber={commentCount}
            icon={'support'}
            isOpened={isOpened}
            openedProperty={EAsidePanelProperty.CARD_COMMENTS}
            sectionSelector={`.${CLASS_COMMENTS_SECTION}`}
            sectionElementSelector={`.${CLASS_COMMENTS_SECTION_FORM}`}
            title={CARD_SECTION_COMMENTS}
            tooltip={tooltip && getAsidePanelActionsButtonTooltip({ value: tooltip })}
            onClick={onClick}
            onOpen={onOpened}
            notBlurClasses={['picker-dialog', 'kui-snackbar']}
        >
            {!isShowed &&
                <CommentsSectionPopup
                    boardId={boardId}
                    cardId={cardId}
                    onClose={onClosed}
                />
            }
        </AsidePanelActionsButton>
    );
}
