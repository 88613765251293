import * as React from 'react';
import { SelectListItem } from 'kui';
import { IStatusListAddProps } from './types';
import { CLASS_BOARD_DETAILS } from '../../../../constants';
import './_StatusListAdd.scss';

export function StatusListAdd ({
    img,
    text,
    value,
}: IStatusListAddProps) {
    const className = CLASS_BOARD_DETAILS + '__statuslist-add';
    const classNoIcon = className + '--noicon';

    return (
        <SelectListItem
            className={`
                ${className}
                ${img ? 'divider' : classNoIcon}
            `}
            key={value}
            icon={img}
            iconSize={24}
            value={value}
        >
            <span>{text}</span>
        </SelectListItem>
    );
};
