import {ActionCreator} from 'redux';
import { isEqual } from 'underscore';
import {Dispatch, ThunkAction} from '../../../../types/actions';
import {IGetState, TBoardId} from '../../../../types/types';
import { getSelectedCards } from '../selectors/getSelectedCards';
import {clickManagerActionSet} from '../store/clickManager/actions/clickManagerActionSet';
import {selectedCardIdsSet} from '../store/clickManagerModel/actions/selectedCardIdsSet';
import { getIsMultiselect } from 'app/view/react_components/clickManager/selectors/getIsMultiselect';
import { isRelatedCardsPanelOpened } from 'app/view/react_components/aside_panel/relatedCards/store/selectors/isRelatedCardsPanelOpened';

export const updateSelectedCards: ActionCreator<ThunkAction> = (
    boardId: TBoardId,
    selectedCards: number[]
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const isRelatedPanel = isRelatedCardsPanelOpened(state)
        const isMultiselect = getIsMultiselect(state, boardId) || selectedCards.length > 1 && !isRelatedPanel;
        const selectedCardsState = getSelectedCards(getState(), boardId);
        if (!isEqual(selectedCards, selectedCardsState)) { // dispatches optimization
            dispatch(clickManagerActionSet(boardId, selectedCardIdsSet(selectedCards, isMultiselect)));
        }
    };
    return action;
};
