import { IGetState, TBoardId } from '../../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { TColorTagColor, TStatus } from '../../../../../../../../../types/model';
import { boardRoleCreate } from '../../../../../../../../../rest/effects/role/boardRoleCreate';
import { SegmentBoardEvent, segmentTrackAction } from '../../../../../../../../../middlewares/segment';
import {
    SegmentBoardOption,
    SegmentBoardOptionStatusValue
} from '../../../../../../../../../middlewares/segment/trackEntities/boardEvents';

export const onCreate = (
    boardId: TBoardId,
    color: TColorTagColor,
    name: string,
    status: TStatus
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (status === TStatus.STATUS_ACTIVE) {
            dispatch(segmentTrackAction(SegmentBoardEvent.ROLE_CHECKED, {
                name: SegmentBoardOption.STATUS,
                value: SegmentBoardOptionStatusValue.ON
            }))
        }
        return dispatch(boardRoleCreate(boardId, {
            color,
            name,
            status
        }));
    };
    return action;
};
