import { AuthController } from 'app/helper/authorisation/google/AuthController';
import {
    CALENDAR_SCOPE, DRIVE_FILE_SCOPE,
    DRIVE_FULL_SCOPE,
    DRIVE_SCOPE,
    GOOGLE_SHEET_SCOPE,
    GSUITE_ADMIN_GROUP
} from 'app/helper/authorisation/google/Scopes';
import { commonAuthManagerInstance } from 'app/helper/authorisation/common/CommonAuthManager';
import { isKanbanchiUser } from 'app/store/model/authUser/selectors/isKanbanchiUser';
import { getAppState } from 'app/store/configureStore';

/***
 * класс позволяет фронту общаться с  AuthController и управляет процессов авторизации
 */
export class AuthManager {

    /*     done     */
    static logout(){
        AuthController.setToken({});
        document.location.href = document.location.origin + '/logout';
    }

    checkAndRequestCalendar(callback: ()=> void, onShowDialog?: ()=> void, onCancel?: ()=> void, onRequestScope?: ()=> void, onApprove?: ()=> void) {
        commonAuthManagerInstance.checkAndRequestPermission(CALENDAR_SCOPE, callback, CALENDAR_SCOPE, onShowDialog, onCancel, onRequestScope, onApprove);
    }

    checkAndRequestDrive(onApprove: ()=> void, onShow?: ()=> void, onCancel?: ()=> void, onRequest?: ()=> void) {
        commonAuthManagerInstance.checkAndRequestPermission([DRIVE_FILE_SCOPE, DRIVE_FULL_SCOPE], onApprove, DRIVE_FILE_SCOPE, onShow, onCancel, onRequest);
    }

    checkAndRequestSharedDrive(onApprove: ()=> void, onShow?: ()=> void, onCancel?: ()=> void, onRequest?: ()=> void) {
            commonAuthManagerInstance.checkAndRequestPermission([DRIVE_SCOPE, DRIVE_FULL_SCOPE], onApprove, DRIVE_SCOPE, onShow, onCancel, onRequest);
    }

    checkAndRequestGoogleSheet(onApprove: ()=> void, onShow?: ()=> void, onCancel?: ()=> void, onRequest?: ()=> void) {
        commonAuthManagerInstance.checkAndRequestPermission(GOOGLE_SHEET_SCOPE, onApprove, GOOGLE_SHEET_SCOPE, onShow, onCancel, onRequest);
    }

    checkAndRequestGsuite(callback: ()=> void) {
        commonAuthManagerInstance.checkAndRequestPermission(GSUITE_ADMIN_GROUP, callback, GSUITE_ADMIN_GROUP);
    }
}

export const authManagerInstance = new AuthManager();
