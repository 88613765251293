import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { SegmentCardEvent, SegmentCardOptions, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { SegmentCardColorTagsSourceValue } from '../../../../../../../../middlewares/segment/trackEntities/cardEvents';

export const onDropdownOpen = (
    used: number,
    all: number,
    source: SegmentCardColorTagsSourceValue,
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.COLOR_TAGS, {
            name: SegmentCardOptions.SOURCE,
            value: source
        }, [{
            name: 'used',
            value: used
        }, {
            name: 'all',
            value: all
        }]));
    };
    return action;
};
