import { ThunkAction, Dispatch } from '../../../../../../../types/actions';
import { IGetState } from '../../../../../../../types/types';
import { SegmentBoardEvent, segmentTrackAction } from '../../../../../../../middlewares/segment';
import { entityPanelWidthSetAction } from '../../../store/actions/entityPanelWidthSetAction';
import { vent } from 'app/store/constants';

export const onResized = (
    width: number
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        localStorage.setItem('asidePanelWidth', String(width));
        dispatch(segmentTrackAction(SegmentBoardEvent.ASIDE_PANEL_RESIZED, {
            name: 'width',
            value: width
        }));
        dispatch(entityPanelWidthSetAction(width));
        requestAnimationFrame(() => {
            vent.trigger(vent['view:resized']); // to setPanelsWidth
        });
    };
    return action;
};
