import { getMessages } from '../../../../../../store/constants';
import { IPollQuestionOption } from '../../../../base/components/Poll/components/Poll/types';

export const MESSENGER_POLL_OPTIONS: IPollQuestionOption[] = [
    {
        label: getMessages().getText('messenger-poll.google'),
        icon: 'google',
        isSkipReview: true,
    },
    {
        label: getMessages().getText('messenger-poll.slack'),
        icon: 'slack',
        isSkipReview: true,
    },
    {
        label: getMessages().getText('messenger-poll.microsoft'),
        icon: 'microsoft',
        isSkipReview: true,
    },
    {
        label: getMessages().getText('messenger-poll.telegram'),
        icon: 'telegram',
        isSkipReview: true,
    },
    {
        label: getMessages().getText('messenger-poll.none')
    }
];
