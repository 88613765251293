import { isObjectEmpty } from './../../../../../../view/react_components/helpers/isObjectEmpty';
import { Dispatch, ThunkAction } from '../../../../../../types/actions';
import { IGetState } from '../../../../../../types/types';
import { ICards } from '../../../../../../store/model/cards/types';
import { getCardsForRollback } from '../../../../../../store/model/selectors/getCardsForRollback';
import { cardsUpdate } from '../../../../../../store/model/actionCreators/cardsUpdate';
import { cardsActiveRequestsUpdate } from '../../../../../../store/requestsState/effects/cardsActiveRequestsUpdate';
import { cardsPatchRest } from '../cardsPatchRest';
import { sendCardStats } from '../../../../../../helper/comet/sendCardStats';
import { updateCardAssigneesFromCards } from '../../../../../../view/react_components/main/myWorkView/effects/updateCardAssigneesFromCards';
import { sendRealTimeStoreActionByBoardId } from '../../../../../../view/react_components/base/helpers/realTimeHelperTS';
import { getNumberKeys } from '../../../../../../helper/getNumberKeys';
import { getBoardIdByCardId } from '../../../../../../store/model/selectors/getBoardIdByCardId';
import { patchRest } from '../patchRest';
import {IRestCard} from '../../../../../../types/rest/IRestCard';

export const cardsRestPatch = (
    cards: ICards,
    isOptimistic: boolean = true, // true = store -> rest -> rollback on error
    isRealtime: boolean = true, // send event to socket
    generateCardNumber: boolean = false,
    disableHistory: boolean = false
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (
            !cards
        ) return Promise.reject();

        if (
            isObjectEmpty(cards)
        ) return Promise.resolve();

        const state = getState();
        let cardsForRollback: ICards;
        if (isOptimistic) {
            cardsForRollback = getCardsForRollback(state, cards);
            dispatch(cardsUpdate(cards));
        }
        dispatch(cardsActiveRequestsUpdate(cards, 1));

        let cardsPatchPromise: Promise<any> = Promise.resolve();
        let ids = Object.keys(cards);
        if (ids.length === 1) {
            let card;
            ids.forEach( (id) => {
                card = cards[parseInt(id)];
                cardsPatchPromise = dispatch(patchRest(parseInt(id), card, generateCardNumber, disableHistory));
            })
        } else {
            cardsPatchPromise = dispatch(cardsPatchRest(cards, generateCardNumber, disableHistory));
        }

        return cardsPatchPromise
            .then((cardRests) => {

                if(!Array.isArray(cardRests)) {
                    cardRests = [cardRests]
                }

                cardRests.forEach((cardRest: IRestCard) => {
                    cards[cardRest.id] = {...cards[cardRest.id], ...cardRest};
                })
                dispatch(cardsUpdate(cards));
                dispatch(sendCardStats(cards, cardsForRollback));
                dispatch(updateCardAssigneesFromCards(cards));
                if (isRealtime) {
                    const boardId = getBoardIdByCardId(getState(), getNumberKeys(cards)[0]);
                    dispatch(sendRealTimeStoreActionByBoardId(boardId, cardsUpdate(cards)));
                }
            })
            .catch((e: any) => {
                console.error(e);
                if (isOptimistic) {
                    dispatch(cardsUpdate(cardsForRollback));
                }
            })
            .finally(() => {
                dispatch(cardsActiveRequestsUpdate(cards, -1));
            });
    };
    return action;
};
