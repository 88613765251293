import { IGetState, TCardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { addPredecessor } from '../../../../../../../../rest/effects/card/dependence/addPredecessor';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';

export const onAddPredecessor = (
    cardId: TCardId,
    predecessorCardId: TCardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.CHOOSE_PREDECESSOR));
        return dispatch(addPredecessor(cardId, predecessorCardId));
    };
    return action;
};
