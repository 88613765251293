import { LIST_VIEW_MOBILE_WIDTH, getShownFieldsInitial } from '../constants';
import { ECardField, IListViewBoard } from './listViewBoard/types';
import { SegmentListViewEvent } from '../../../../middlewares/segment';

export const getListViewBoardInitialState =
(): IListViewBoard => {
    const listViewBoardInitialState: IListViewBoard = {
        collapsedGroups: [],
        isBlocked: false,
        isGroupByEpics: true,
        // @ts-ignore
        isMobile: window._innerWidth < LIST_VIEW_MOBILE_WIDTH,
        orderType: null,
        printMode: false,
        shownFields: getShownFieldsInitial(),
        sortType: 0,
        isAddCardShow: false
    };
    return listViewBoardInitialState;
};
