import { SegmentCardEvent, SegmentCardOptions, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { SegmentCardSourceValue } from '../../../../../../../../middlewares/segment/trackEntities/cardEvents';
import { ECustomPropertyType, IBoardCardCustomOption } from '../../../../../../../../store/customProperties/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TBoardId, TCardId } from '../../../../../../../../types/types';
import { addProperty } from '../effects/addProperty';
import {
    getProFeaturesTrialActive
} from '../../../../../../../../store/model/authUser/selectors/getProFeaturesTrialActive';
import { authUserSetUsedProFeatures } from '../../../../../../../../rest/effects/authUser/authUserSetUsedProFeatures';

export const onAdd = (
    boardId: TBoardId,
    cardId: TCardId,
    name: string,
    type: ECustomPropertyType,
    options: IBoardCardCustomOption[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (getProFeaturesTrialActive(getState())) {
            dispatch(authUserSetUsedProFeatures('allowCardCustomProperties'));
        }
        dispatch(segmentTrackAction(SegmentCardEvent.CUSTOM_PROPERTY_ADDED, {
            name: SegmentCardOptions.SOURCE,
            value: !!cardId ? SegmentCardSourceValue.DETAILS : SegmentCardSourceValue.BOARD_SETTINGS
        }, [{
            name: SegmentCardOptions.TYPE,
            value: type
        }]));
        return dispatch(addProperty(boardId, name, type, options));
    };
    return action;
};
