import { IDescriptionUnsaved } from '../../../components/CardDescription/types';
import { CARD_DESCRIPTION_DATE_FORMAT } from '../constants';
import * as moment from 'moment';
import { IDescriptionUnsavedState } from '../../../store/cardDescriptionUnsaved/types';
import { getDateFormatted } from '../../../../../../helpers/dateService';

export const formatDescriptionUnsaved = (
    descriptionUnsavedState: IDescriptionUnsavedState,
    isUserTimeFormat12: boolean
): IDescriptionUnsaved => {
    let formattedDescriptionUnsaved: IDescriptionUnsaved;
    if (descriptionUnsavedState && descriptionUnsavedState.datetime) {
        formattedDescriptionUnsaved = {
            descriptionUnsaved: descriptionUnsavedState.descriptionUnsaved,
            datetime: getDateFormatted(descriptionUnsavedState.datetime, isUserTimeFormat12)
        }
    }
    return formattedDescriptionUnsaved;
};
