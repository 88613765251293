import { IApplicationState, TCardAssigneeRoleId, TCardId } from '../../../types/types';
import { getCard } from './getCard';
import { ICard } from '../card/types';

export const getCardAllUserRoleIdsByCardId = (
    state: IApplicationState,
    cardId: TCardId
): TCardAssigneeRoleId[] => {
    const card = getCard(state, cardId);
    return getCardAllUserRoleIdsByCard(card);
}

export const getCardAllUserRoleIdsByCard = (
    card: ICard
): TCardAssigneeRoleId[] => {
    const roleIdSet: Set<TCardAssigneeRoleId> = new Set<TCardAssigneeRoleId>();
    if (card && card.assignees) {
        card.assignees.forEach(user => {
            if (user.roleIds) {
                user.roleIds.forEach(roleId => {
                    roleIdSet.add(roleId);
                })
            }
        })
    }
    return Array.from(roleIdSet);
}
