import { SettingsSection } from '../../../../../base/components/SettingsSection/SettingsSection';
import * as React from 'react';
import { ICardRecurringProps } from './types';
import './_CardRecurringSection.scss';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { CLASS_CARD_DETAILS_SECTION, CLASS_CARD_SECTION_RECURRING } from '../../../constants';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { EAsidePanelProperty } from '../../../../asidePanel/components/AsidePanel/types';
import { CardRecurringFormHOC } from '../../hocs/CardRecurringFormHOC/CardRecurringFormHOC';
import { CARD_SECTION_RECURRING } from '../wording';

export function CardRecurringSection({
    sectionType,
    hasRecurring,
    onClose,
}: ICardRecurringProps) {
    const isPopup = sectionType === ESettingsSectionType.POPUP;
    const { openedProperty, cardId, boardId } = React.useContext(AsidePanelContext);
    if (!hasRecurring && !isPopup) return null;
    return (
        <SettingsSection
            className={`
                ${CLASS_CARD_DETAILS_SECTION}
                ${CLASS_CARD_SECTION_RECURRING}
            `}
            sectionType={ sectionType }
            title={ CARD_SECTION_RECURRING }
            titleButton={ null }
            onAddClosed={ onClose }
        >
            {(isPopup || openedProperty !== EAsidePanelProperty.CARD_RECURRING) &&
                <CardRecurringFormHOC
                    cardId={cardId}
                    boardId={boardId}
                    onCancel={onClose}
                    sectionType={sectionType}
                />
            }
        </SettingsSection>
    );
}
