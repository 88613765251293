import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../../../../types/types';
import { SegmentCardEvent, SegmentCardOptions, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { SegmentCardSourceValue } from '../../../../../../../../middlewares/segment/trackEntities/cardEvents';
import { asidePanelActionButtonClick } from '../../../../../../helpers/asidePanelActionButtonClick';
import { waitUntilCardIsOpenedAndLoaded } from '../../../../../../helpers/waitUntilCardIsOpenedAndLoaded';
import { openCardIfNeeded } from '../effects/openCardIfNeeded';

export const onSetPriority = (
    cardId: TCardId,
    source: SegmentCardSourceValue
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.PRIORITY_CARD_DROPDOWN_CLICKED, {
            name: SegmentCardOptions.SOURCE,
            value: source
        }));

        dispatch(openCardIfNeeded(cardId));

        waitUntilCardIsOpenedAndLoaded(
            getState,
            cardId,
            () => setTimeout(() => {
                asidePanelActionButtonClick('priority');
            }, 0)
        );
    };

    return action;
};
