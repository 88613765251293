import { IApplicationState, TCardId } from '../../../../types/types';
import { getCard } from '../../selectors/getCard';

export const isCardInWorkUserExist = (
    state: IApplicationState,
    cardId: TCardId,
    userPermissionId: string
): boolean => {
    const card = getCard(state, cardId);
    return card && card.inWorkUsers && card.inWorkUsers.includes(userPermissionId);
}
