import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { segmentTrackAction, SegmentUserEvent } from '../../../../../../../middlewares/segment';
import { SegmentUserOption } from '../../../../../../../middlewares/segment/trackEntities/userEvents';

export const onModalClose = (
    name: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        dispatch(segmentTrackAction(SegmentUserEvent.QUICK_START_GUIDE_CLOSED, {
            name: SegmentUserOption.VALUE,
            value: name
        }));
    };
    return action;
};
