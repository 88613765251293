import * as React from 'react';
import './_ColorRow.scss';
import { IColorRowProps } from './types';
import { CLASS_COLOR_PALETTE } from '../constants';
import { Color } from '../Color/Color';
import { Button, ButtonDropdown } from 'kui';
import {
    COLOR_ROW_PICKER_BUTTON_CANCEL,
    COLOR_ROW_PICKER_BUTTON_SAVE,
    COLOR_ROW_PICKER_DEFAULT_COLOR
} from './constants';
import { NEW_COLORS } from '../../../../../../store/model/board/selectors/getBoardColors';
import { ColorPicker, useColor } from 'react-color-palette';
import 'react-color-palette/css';
import {
    ID_ASIDE_PANEL_BODY_PORTAL
} from '../../../../aside_panel/asidePanel/components/AsidePanelActionsButton/constants';
import { GOOGLE_SPACING } from '../../../../../../const';
import { CLASS_COLOR_TAGS_DROPDOWN } from '../../ColorTagsDropdown/constants';

export const ColorRow = ({
    allColors,
    colors,
    isAutoFocus,
    isColorPicker,
    selectedColor,
    variant,
    onSelect,
    setColorPickerOpen,
}: IColorRowProps) => {
    const className = CLASS_COLOR_PALETTE + '-color-row';
    const classPicker = className + '__picker';

    const [isOpen, setOpen] = React.useState(null);
    const [color, setColor] = useColor(COLOR_ROW_PICKER_DEFAULT_COLOR);

    const onSave = () => {
        onSelect(color.hex, true);
        setOpen(false);
    };

    const isDisabled = !!NEW_COLORS.find(item => item.hex === color.hex) || !!allColors.find(item => item === color.hex);

    const ColorPickerIcon = require('!!@svgr/webpack!./color-picker.svg').default;

    return (
        <div className={className}>
            {colors.map(colorLabel => (
                <Color
                    key={colorLabel}
                    color={colorLabel}
                    isAutoFocus={isAutoFocus}
                    isSelected={colorLabel === selectedColor}
                    variant={variant}
                    onSelect={() => onSelect(colorLabel)}
                />
            ))}
            {isColorPicker &&
                <ButtonDropdown
                    className={classPicker + '-button'}
                    opened={isOpen}
                    onOpen={() => {
                        setColorPickerOpen(true);
                        setOpen(true);
                        setTimeout(() => {
                            const dropdown = document.querySelector(`.${classPicker}-dropdown .kui-dropdown__item`) as HTMLElement;
                            const container = document.querySelector(`.${CLASS_COLOR_TAGS_DROPDOWN}`) as HTMLElement;
                            const btn = document.querySelector(`.${classPicker}-button`) as HTMLElement;
                            if (!dropdown || !container || !btn) return;
                            dropdown.style.marginBottom = `${-(container.offsetHeight + GOOGLE_SPACING) + btn.offsetHeight}px`; // + padding-top
                            setTimeout(() => { // ждем конца анимации
                                dropdown.style.opacity = '1';
                            }, 200)
                        }, 200);
                    }}
                    onClose={() => {
                        setColorPickerOpen(false);
                        setOpen(false);
                    }}
                    dropdownClassName={`
                        ${classPicker + '-dropdown'}
                        ${colors.length > 3 && colors.length < 7 ? classPicker + '-dropdown--translate' : ''}
                    `}
                    portal
                    directionVertical={'up'}
                    directionHorizontal={colors.length > 6 ? 'right' : 'left'}
                    portalId={ID_ASIDE_PANEL_BODY_PORTAL}
                >
                    <Button variant={'icon'}>
                        <ColorPickerIcon />
                    </Button>
                    <div className={classPicker} >
                        <div className={classPicker + '-color'} style={{ backgroundColor: color.hex }} />
                        <ColorPicker color={color} onChange={setColor} hideInput={['rgb', 'hsv']} height={140} />
                        <div className={classPicker + '-actions'}>
                            <Button
                                onClick={() => setOpen(false)}
                                variant={'secondary'}
                            >
                                {COLOR_ROW_PICKER_BUTTON_CANCEL}
                            </Button>
                            <Button
                                onClick={onSave}
                                variant={'primary'}
                                disabled={isDisabled}
                            >
                                {COLOR_ROW_PICKER_BUTTON_SAVE}
                            </Button>
                        </div>
                    </div>
                </ButtonDropdown>
            }
        </div>
    );
}
