import { IGetState, TCardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IRestChecklistItem } from '../../../../types/rest/IRestChecklistItem';
import { itemRestPost } from './api/itemRestPost';
import { checklistItemAddStore } from '../../../../store/model/checklists/actionCreators/checklistItemAdd';
import { sendRealTimeStoreActionByCardId } from '../../../../view/react_components/base/helpers/realTimeHelperTS';
import { cardChecklistStatsUpdate } from '../../../../store/model/effects/cardChecklistStatsUpdate';
import { CHECKLIST_DEFAULT, CHECKLIST_DEFAULT_ID } from '../../../../store/model/checklists/checklists/constants';
import { getChecklist } from '../../../../store/model/checklists/checklists/selectors/getChecklist';
import { checklistAddStore } from '../../../../store/model/checklists/actionCreators/checklistAdd';
import { cardsActiveRequestsUpdate } from '../../../../store/requestsState/effects/cardsActiveRequestsUpdate';
import { ICards } from '../../../../store/model/cards/types';
import { checklistItemDeleteStore } from '../../../../store/model/checklists/actionCreators/checklistItemDelete';
import { addFeatureTrigger } from '../../../../view/react_components/polls/featurePoll/effects/addFeatureTrigger';
import { ERateFeature } from '../../../../types/rest/IUserMeta';

export const checklistItemAdd = (
    cardId: TCardId,
    item: IRestChecklistItem
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (item.checklistId === CHECKLIST_DEFAULT_ID) { // дефолтный могли удалить
            const checklistDefault = getChecklist(getState(), cardId, CHECKLIST_DEFAULT_ID);
            if (!checklistDefault || !checklistDefault.cardId) {
                dispatch(checklistAddStore({
                    ...CHECKLIST_DEFAULT,
                    cardId,
                }));
            }
        }
        dispatch(cardsActiveRequestsUpdate({ [cardId]: {} } as ICards, 1));
        const checklistItemForRollBack = { ...item, id: Date.now(), temp: true };
        const action = checklistItemAddStore(cardId, checklistItemForRollBack);
        dispatch(action);
        return dispatch(itemRestPost(cardId, item))
            .then((
                result: IRestChecklistItem
            ) => {
                const action = checklistItemAddStore(cardId, result);
                dispatch(addFeatureTrigger(ERateFeature.NEW_CHECKLISTS));
                dispatch(action);
                dispatch(sendRealTimeStoreActionByCardId(cardId, action));
                dispatch(cardChecklistStatsUpdate(cardId));
                return result;
            })
            .catch((e: any) => {
                console.error(e);
            })
            .finally(() => {
                dispatch(checklistItemDeleteStore(cardId, checklistItemForRollBack.checklistId , checklistItemForRollBack.id));
                dispatch(cardsActiveRequestsUpdate({ [cardId]: {} } as ICards, -1));
            });
    };
    return action;
};
