import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { deleteSnackbar } from '../../../../../../../snackbarsStack';

export const onDidUnmount: ActionCreator<ThunkAction> = (
    uuid: string,
) => {
    const action = (dispatch: Dispatch) => {
        dispatch(deleteSnackbar(uuid));
    };
    return action;
};
