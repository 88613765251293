import { TCondition } from '../types';
import { IApplicationState } from '../../../../types/types';
import { EHintKey, getHintConfig } from '../constants';
import { getMyWorkImportTab } from '../../../../store/router/selectors/getMyWorkImportTab';

export const myWorkImportCardsUpdatesCondition: TCondition = (
    state: IApplicationState
) => {
    const hint = getHintConfig(EHintKey.MY_WORK_IMPORT_CARDS_UPDATES);
    if (!hint) return false;

    return getMyWorkImportTab(state);
};
