import {INavigationPanelBrowseBoardsProps} from './types';
import {Icon, Button} from 'kui';
import * as React from 'react';
import './_NavigationPanelBrowseBoards.scss';
import { BOARDS_ADD_OPEN } from './constants';

export function NavigationPanelBrowseBoards ({
    onClick,
}: INavigationPanelBrowseBoardsProps) {

    return (
        <div className={`
            navigation-panel__row
            navigation-panel__browse-boards
        `}>
            <Button
                text={BOARDS_ADD_OPEN}
                variant={'icon-text'}
                onClick={onClick}
            >
                <Icon size={24} xlink={'board'} />
            </Button>
        </div>
    );
};
