import * as React from 'react';
import { IKanbanTitleProps } from './type';
import { ReactElement } from 'react';

export function KanbanTitle({
    isTimeTracking,
    staticTitle,
    onChange
}: IKanbanTitleProps): ReactElement {
    let timer = React.useRef(null);

    React.useEffect(() => {
        function tick () {
            if (timer.current) clearTimeout(timer.current);
            if (isTimeTracking) {
                timer.current = setTimeout(tick, 500);
            }
            onChange();
        }
        tick();

        return () => {
            if (timer.current) clearTimeout(timer.current);
            onChange();
        };
    }, [isTimeTracking]);

    React.useEffect(() => {
        onChange();
    }, [staticTitle]);

    return null;
};
