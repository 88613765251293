import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState, TCardId } from '../../../../types/types';
import { ICard } from '../../../../store/model/card/types';
import { getCardSubcardsTree } from '../../../../store/model/selectors/getCardSubcardsTree';
import { snackbarPromtYesNo } from '../../../../view/react_components/snackbarsStack/effects/variantPromt/snackbarPromtYesNo';
import { getMessages } from '../../../../store/constants';

export const checkCopyCardWithSubcards = (
    cardId: TCardId,
    promptMessageKey: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ): Promise<boolean> => {
        const subCards: ICard[] = getCardSubcardsTree(getState(), cardId);
        if (!subCards || subCards.length < 1)
            return Promise.resolve(false);
        const subCardIds: TCardId[] = subCards.map((card) => card.id);
        return new Promise<boolean>((resolve) => {
            dispatch(snackbarPromtYesNo({
                id: 'SNACKBAR_COPY_SUBCARDS',
                text: getMessages().getText(promptMessageKey),
                actionApply: () => {
                    resolve(true);
                },
                actionCancel: () => {
                    resolve(false);
                }
            }));
        });
    }
    return action;
}
