import { TBoardId } from '../../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { ENotificationScope } from '../../../../../../../../../types/rest/IRestNotificationSettings';
import { notificationSetScope } from '../../../../../../../../../rest/effects/notificationSettings/notificationSetScope';

export const onScopeChanges = (
    boardId: TBoardId,
    scopes: ENotificationScope[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        dispatch(notificationSetScope(boardId, scopes));
    };
    return action;
};
