import { CARD_ASSIGNEES_ACTION_SET, ICardAssigneesActionSetAction } from './types';
import { TCardAssigneesAction } from '../cardAssignees/actions/types';

export const cardAssigneesActionSetAction = (
    cardAssigneesAction: TCardAssigneesAction
): ICardAssigneesActionSetAction => {
    return {
        type: CARD_ASSIGNEES_ACTION_SET,
        cardAssigneesAction
    }
};
