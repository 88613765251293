import {connect} from 'react-redux';
import {CopyButton} from '../../components/CopyButton/CopyButton';
import {IApplicationState} from '../../../../../../../types/types';
import {ICopyButtonEvents, ICopyButtonFields} from '../../components/CopyButton/types';
import {ICopyButtonHOCProps} from './types';
import {onClick} from './events/onClick';
import { getSectionType } from '../../../selectors/getSectionType';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';

const mapStateToProps = (
    state: IApplicationState,
    props: ICopyButtonHOCProps,
): ICopyButtonFields => {
    let { boardId } = props;
    const sectionType = getSectionType(state, boardId, null, 'allowCreateBoard');
    const isDisallow = sectionType === ESettingsSectionType.DISALLOW;
    return {
        isDisallow,
        tooltip: null,
    }
};
const mapDispatchToProps = (
    dispatch: any,
    { boardId }: ICopyButtonHOCProps
): ICopyButtonEvents => ({
    onClick: () => dispatch(onClick(boardId)),
});

export const CopyButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CopyButton);

CopyButtonHOC.displayName = 'CopyButtonHOC';
