import * as React from 'react';
import './_CardColorTags.scss';
import { CLASS_MY_WORK_CARD, CLASS_MY_WORK_CARD_CONTROL } from '../../../constants';
import { ICardColorTagsProps } from './types';
import { ColorsTooltip } from '../../../../../base/components/ColorsTooltip/ColorsTooltip';
import { Button, ButtonDropdown } from 'kui';
import {
    ColorTagsDropdownHOC
} from '../../../../../base/components/ColorTagsDropdown/hocs/ColorTagsDropdownHOC/ColorTagsDropdownHOC';
import {
    BOARD_SECTION_COLORS_MODAL_CANCEL,
    BOARD_SECTION_COLORS_MODAL_REMOVE
} from '../../../../../aside_panel/boardDetails/details/Colors/components/BoardColors/constants';
import {
    BasicUpgradeButtonHOC
} from '../../../../../base/components/BasicUpgradeButton/hocs/BasicUpgradeButtonHOC/BasicUpgradeButtonHOC';
import { getMessages } from '../../../../../../../store/constants';
import { ActionModal } from '../../../../../base/components/ActionModal/ActionModal';
import { CLASS_ACTION_MODAL } from '../../../../../base/components/ActionModal/constants';

export function CardColorTags ({
    aloudCount,
    boardId,
    cardId,
    colorTags,
    portalId,
    onChange,
    onModalClose,
    onLoadCard,
}: ICardColorTagsProps) {
    const className = CLASS_MY_WORK_CARD + '__color-tags';

    const [isOpened, setOpened] = React.useState(null);
    const [removingId, setRemovingId] = React.useState(null);
    const [removingSource, setRemovingSource] = React.useState(null);
    const onClose = () => setOpened(false);

    const onCloseModal = () => {
        onModalClose();
        setRemovingId(null);
        setRemovingSource(null);
    };

    return (
        <>
            <ButtonDropdown
                className={`
                ${className}
                ${!colorTags.length ? className + '--no-tag' : ''}
                ${isOpened ? className + '--opened' : ''}
                ${CLASS_MY_WORK_CARD_CONTROL}
            `}
                dropdownClassName={`${className + '-dropdown'} ${CLASS_MY_WORK_CARD_CONTROL}`}
                onOpen={() => setOpened(true)}
                beforeOpen={onLoadCard}
                onClose={onClose}
                opened={isOpened}
                portal
                portalId={portalId}
                directionHorizontal={'right'}
                notBlurClasses={[CLASS_ACTION_MODAL]}
            >
                <Button className={className + '-button'}>
                    {!colorTags.length && <div className={className + '-empty'} />}
                    <ColorsTooltip
                        colors={colorTags}
                        isNoPortal
                        delayClose={1}
                        isNoEvents
                    />
                </Button>
                <ColorTagsDropdownHOC
                    boardId={boardId}
                    cardId={cardId}
                    onClose={onClose}
                    onChange={onChange}
                    setRemovingId={setRemovingId}
                    setRemovingSource={setRemovingSource}
                />
            </ButtonDropdown>
            <ActionModal
                isShow={!!removingId}
                actions={[
                    {
                        variant: 'primary',
                        onClick: onCloseModal,
                        children: BOARD_SECTION_COLORS_MODAL_CANCEL
                    },
                    {
                        variant: 'secondary',
                        onClick: () => {
                            onCloseModal();
                            return onChange(removingId, removingSource);
                        },
                        children: BOARD_SECTION_COLORS_MODAL_REMOVE
                    },
                ]}
                footer={<BasicUpgradeButtonHOC onClick={onCloseModal} />}
                text={getMessages().getText('board_settings.colors.modal.text', null, aloudCount)}
                title={getMessages().getText('board_settings.colors.modal.title', null, colorTags.find(color => color.id === removingId)?.name)}
                onClose={onCloseModal}
            />
        </>
    );
};
