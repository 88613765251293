import { ThunkAction } from '../../../../../../../../types/actions';
import { ERestDriveDocType, TDriveDocId } from '../../../../../../../../types/rest/IRestDriveDoc';
import { TBoardId, TCardId } from '../../../../../../../../types/types';
import { getDriveDocsByCardId } from '../../../../../../../../store/model/driveDocs/selectors/getDriveDocsByCardId';
import { boardOpen } from '../../../../../../base/effects/boardOpen';
import { getIsLinkCardActive } from '../../../../../../../../store/router/selectors/getIsLinkCardActive';
import { root } from '../../../../../../../../store/constants';
import { getLinkCardBoardId } from '../../../../../../../../store/router/selectors/getLinkCardBoardId';
import { getBoardIdByCardId } from '../../../../../../../../store/model/selectors/getBoardIdByCardId';
import { routerChange } from '../../../../../../../../store/router/actions/routerChange';
import { ERoutes } from '../../../../../../../../store/router/constants';
import { getIsAssignedToMeActive } from '../../../../../../../../store/router/selectors/getIsAssignedToMeActive';

export const resetActiveComment = (
    cardId: TCardId
): ThunkAction => {
    return (
        dispatch,
        getState
    ) => {
        const state = getState();
        const isLinkCard = getIsLinkCardActive(state);
        const boardId = isLinkCard ? getLinkCardBoardId(state.router) : getBoardIdByCardId(state, cardId);
        const isCardFromAnotherBoardOpenedFromNotifications = state.router.route === ERoutes.ASSIGNED_TO_ME_NOTIFICATION;//карта с другой доски открыта из нофикаций
        const url = isLinkCard ? root.App.router.getLinkCardUrl(boardId, cardId)
            : root.App.router.getCardPageUrl(boardId, cardId);
        root.App.router.navigate(url);
        let route = isLinkCard ? ERoutes.LINK_CARD : ERoutes.EDIT_CARD;
        const isMyWork = getIsAssignedToMeActive(state);
        if (isMyWork) route = ERoutes.ASSIGNED_TO_ME_CARD;
        if (isCardFromAnotherBoardOpenedFromNotifications) route = ERoutes.ASSIGNED_TO_ME_NOTIFICATION;
        dispatch(routerChange(route, [boardId, cardId]));
    }
}
