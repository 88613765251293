import { connect } from 'react-redux';
import { getCard } from '../../../../../../../store/model/selectors/getCard';
import { IApplicationState } from '../../../../../../../types/types';
import { ICommentsPanelButtonHOCProps } from './types';
import { CommentsPanelButton } from '../../components/CommentsPanelButton/CommentsPanelButton';
import { ICommentsPanelButtonEvents, ICommentsPanelButtonFields } from '../../components/CommentsPanelButton/types';
import { CARD_SECTION_COMMENTS } from '../../constants';
import { getPanelButtonShow } from '../../../selectors/getPanelButtonShow';
import { onClick } from './events/onClick';

const mapStateToProps = (
    state: IApplicationState,
    { boardId, cardId, isShowTooltip }: ICommentsPanelButtonHOCProps
): ICommentsPanelButtonFields => {
    const card = getCard(state, cardId);
    const isShow = getPanelButtonShow(state, boardId, cardId, null, null, 'allowComment');
    const tooltip = isShowTooltip
        ? CARD_SECTION_COMMENTS
        : null;
    return {
        commentCount: card.commentCount,
        isShow,
        tooltip,
    }
};

const mapDispatchToProps = (
    dispatch: any,
    {boardId, cardId, onClick: ownOnClick}: ICommentsPanelButtonHOCProps
): ICommentsPanelButtonEvents => {
    return {
        onClick: () => dispatch(onClick(boardId, cardId, ownOnClick))
    }
};

export const CommentsPanelButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CommentsPanelButton);

CommentsPanelButtonHOC.displayName = 'CommentsPanelButtonHOC';
