import { IApplicationState, TBoardId } from '../../../types/types';
import { TStatus } from '../../../types/model';
import { getBoardCards } from 'app/store/model/selectors/getBoardCards';

export const getBoardSubcardsCount = (
    state: IApplicationState,
    boardId: TBoardId,
    status: TStatus = TStatus.STATUS_ACTIVE,
): number => {
    let count: number = 0;
    const cards = getBoardCards(state, boardId, status);
    cards.forEach(card => {
        if (card.subCardIds && card.subCardIds.length) {
            count += card.subCardIds.length
        }
    })
    return count
};
