const category = 'Top bar';

export const SegmentTopBarEvent = {
    BOARD_NAME_CLICKED: { category, event: 'Board name clicked' },
    BOARD_NAME_ICON_CLICKED: { category, event: 'Board name icon clicked' },
    FILTER_CLICKED: { category, event: 'Filter clicked in TopBar' },
    IN_APP_NOTIFICATIONS_ICON_CLICKED: { category, event: 'In-app notifications icon clicked' },
    IN_APP_NOTIFICATIONS_ESC_BUTTON_PRESSED: { category, event: 'In-app notifications esc button pressed' },
    SHARE_CLICKED: { category, event: 'Share clicked in TopBar' },
    STREAM_CLICKED: { category, event: 'Live webinar icon clicked' },
    HELP_CLICKED: { category, event: 'Help clicked in TopBar' },
    DONT_SHOW_HINT_CLICKED: { category, event: 'Do not show again in share hint clicked' },
    WORKING_ON_CLICKED: { category, event: 'Card link clicked' },
}

export enum ESegmentTopBarOption {
    TARGET = 'target',
    TIME_MOD15 = 'timeMod15',
    TIME = 'time',
    PAGE = 'page'
}

export enum ESegmentFilterTarget {
    input = 'input',
    options = 'options',
}

export enum ESegmentTopBarPage {
    MY_TASKS = 'my-tasks'
}
