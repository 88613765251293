import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { IGetState, TBoardId } from '../../../../../../../../../types/types';
import { boardsActionSet } from '../../../../../../../../../store/model/actions/boardsActionSet';
import { boardActionSet } from '../../../../../../../../../store/model/boards/actions/boardActionSet';
import { updateAction } from '../../../../../../../../../store/model/board/actions/updateAction';

export const onToggleShowIconAndColorLogo = (
    boardId: TBoardId,
    isShow: boolean,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(boardsActionSet(boardActionSet(boardId, updateAction({ isShowIconAndColorLogo: isShow }))));
    };
    return action;
};
