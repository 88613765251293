import * as React from 'react';
import { MESSAGE_LIST_MAX_CARD_NUMBER_NEW, MESSAGE_LIST_MAX_CARD_NUMBER_OLD } from './constants';
import { IRestListCommonActivity } from '../../../../../../../../types/rest/activity/IRestListCommonActivity';
import { getMessages } from '../../../../../../../../store/constants';
import { TabActivityContext } from '../TabActivity/constants';
import { IActivityProps } from '../../types';
import { TActivity } from '../../../../../../../../store/activities/types';
import { ActivityItemBody } from '../ActivityItemBody/ActivityItemBody';
import { ActivityHeader } from '../ActivityHeader/ActivityHeader';
import { ActivityItemName } from '../ActivityItemName/ActivityItemName';
import { Icon } from 'kui';

export function ListActivityMaxCardNumber ({
    activity
}: IActivityProps<IRestListCommonActivity>) {
    const { onOpenList } = React.useContext(TabActivityContext);
    const { label, list } = activity as TActivity;
    return (
        <ActivityItemBody
            header={<ActivityHeader icon={'list'} label={label} />}
            activity={activity}
            onClick={() => onOpenList(list.id)}
        >
            {!!Number(activity.oldValue) &&
                <ActivityItemName
                    name={getMessages().getText(MESSAGE_LIST_MAX_CARD_NUMBER_OLD, null, activity.oldValue)}
                    isRemove={!!Number(activity.newValue)}
                />
            }
            {!!Number(activity.newValue) && !!Number(activity.oldValue) && <Icon xlink={'forward'} size={24} />}
            {!!Number(activity.newValue) &&
                <ActivityItemName name={getMessages().getText(MESSAGE_LIST_MAX_CARD_NUMBER_NEW, null, activity.newValue)} />
            }
        </ActivityItemBody>
    );
}
