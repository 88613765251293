import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId, TCustomPropertyId } from '../../../../../../../../types/types';
import { cardUpdatePinnedSection } from '../../../../../../../../rest/effects/card/card/cardUpdatePinnedSection';
import {
    getCardPinnedCustomPropertiesIds
} from '../../../../../../../../store/model/selectors/getCardPinnedCustomPropertiesIds';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';

export const onTogglePinOnCard = (
    cardId: TCardId,
    customPropertyId: TCustomPropertyId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const customProperties = [...getCardPinnedCustomPropertiesIds(getState(), cardId)];
        const index = customProperties.indexOf(customPropertyId);
        if (index < 0) {
            customProperties.push(customPropertyId);
            dispatch(segmentTrackAction(SegmentCardEvent.CUSTOM_PROPERTY_PINNED_CARD));
        } else {
            customProperties.splice(index, 1);
            dispatch(segmentTrackAction(SegmentCardEvent.CUSTOM_PROPERTY_UNPINNED_CARD));
        }

        return dispatch(cardUpdatePinnedSection(cardId, { customProperties }));
    };
    return action;
};
