import * as React from 'react';
import { ICardChecklistsProps } from './types';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { Button, Icon, Input, LoaderBlock } from 'kui';
import {
    CARD_CHECKLISTS_ADD,
    CARD_CHECKLISTS_ADD_NEW,
    CARD_SECTION_CHECKLIST,
    CARD_SECTION_CHECKLISTS,
    CARD_SECTION_CHECKLISTS_MODAL_CANCEL,
    CARD_SECTION_CHECKLISTS_MODAL_REMOVE
} from './constants';
import { CLASS_CARD_DETAILS, CLASS_CARD_DETAILS_SECTION } from '../../../constants';
import './_CardChecklists.scss';
import { SettingsSection } from '../../../../../base/components/SettingsSection/SettingsSection';
import { EAsidePanelProperty } from '../../../../asidePanel/components/AsidePanel/types';
import { CardChecklistHOC } from '../../hocs/CardChecklistHOC/CardChecklistHOC';
import {
    CHECKLIST_DEFAULT_ID,
    CHECKLIST_DEFAULT_NAME
} from '../../../../../../../store/model/checklists/checklists/constants';
import { TChecklistId } from '../../../../../../../types/types';
import { IChecklist } from '../../../../../../../store/model/checklists/checklist/types';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { CardChecklistActionsHOC } from '../../hocs/CardChecklistActionsHOC/CardChecklistActionsHOC';
import { CARD_CHECKLIST_DONE, CARD_CHECKLIST_SAVE_BUTTON } from '../CardChecklist/constants';
import { IChecklistStats } from '../../../../../../../types/model';
import { getMessages } from '../../../../../../../store/constants';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { CardChecklistCopySection } from '../CardChecklistCopySection/CardChecklistCopySection';
import { getAsidePanelTooltip } from '../../../../asidePanel/helpers/getAsidePanelTooltip';
import {
    ACTIVE_SECTION_TIMER,
    CLASS_ASIDE_PANEL_ACTIONS_BUTTON_HIGHLIGHT
} from '../../../../asidePanel/components/AsidePanelActionsButton/constants';
import {
    BasicUpgradeButtonHOC
} from '../../../../../base/components/BasicUpgradeButton/hocs/BasicUpgradeButtonHOC/BasicUpgradeButtonHOC';
import { ActionModal } from '../../../../../base/components/ActionModal/ActionModal';
import { AnalyzeRender } from '../../../../../helpers/memoizeHelper';

// @ts-ignore
const analyzeRender = window.Settings.development ? new AnalyzeRender(`CardChecklists asidePanel`) : null;

export function CardChecklists ({
    aloudCount,
    checklists,
    checklistItems,
    isEmpty,
    isDefaultProperty,
    isRequired,
    isShow,
    sectionType,
    onChecklistAdd,
    onClose,
    onDelete,
    onNameChange,
    onSort
}: ICardChecklistsProps) {
    const { showedProperties, boardId, cardId, addShowedProperty } = React.useContext(AsidePanelContext);

    if (analyzeRender) analyzeRender.call(`CardChecklists asidePanel ${cardId}`);

    if (isEmpty && sectionType === ESettingsSectionType.READONLY) return;

    const isReadonly = sectionType === ESettingsSectionType.READONLY;

    const stats: IChecklistStats = {
        checkedCount: 0,
        checkedPercent: 0,
        totalCount: 0,
        estimate: 0,
    };

    const [isLoading, setLoading] = React.useState(null);
    const [isAdding, setAdding] = React.useState(null);

    const isMultiple = checklists && checklists.length > 1 &&
        !(isLoading && !!checklists.find(({ id }) => id === CHECKLIST_DEFAULT_ID));

    if (!isMultiple && checklistItems) {
        stats.totalCount = checklistItems.length;
        let weightTotal = 0;
        let weightChecked = 0;
        checklistItems.forEach(item => {
            weightTotal += item.weight;
            if (item.checked) {
                stats.checkedCount++;
                weightChecked += item.weight;
            }
            stats.estimate += item.estimate || 0;
        });
        if (weightTotal) stats.checkedPercent = Math.round(weightChecked * 100 / weightTotal);
    }

    const className = CLASS_CARD_DETAILS + '__checklists';

    const [isShowSection, setShowSection] = React.useState(false);
    let [newChecklistId, _setNewChecklistId] = React.useState(null);
    const [elements, setElements] = React.useState(checklists);
    const [isShowAdd, setShowAdd] = React.useState(null);
    const [isDragging, setDragging] = React.useState(null);
    const [isShowDefaultChecklist, setShowDefaultChecklist] = React.useState(null);
    const [isEditing, setEditing] = React.useState(null);
    const [name, setName] = React.useState(null);
    const [copyCardId, setCopyCardId] = React.useState(null);
    const [isCopy, setCopy] = React.useState(null);
    const [removingId, setRemovingId] = React.useState(null);

    const inputRef = React.useRef(null);
    const copyRef = React.useRef(null); // прятать дефолтный чеклист, если открыли пустую секцию и скопировали чеклист
    const highLightTimer = React.useRef(null);

    const setNewChecklistId = (checklistId: TChecklistId) => {
        newChecklistId = checklistId;
        _setNewChecklistId(checklistId);
    };

    const onCloseModal = () => {
        onClose();
        setRemovingId(null);
    };

    const onAdd = () => {
        if (isCopy) {
            onCopyClose();
        }
        setAdding(true);
        onChecklistAdd().then(checklistId => {
            if (checklistId) {
                setNewChecklistId(checklistId);
                setTimeout(() => {
                    const selector = '.' + CLASS_CARD_DETAILS_SECTION + '--checklist' + checklistId + ' .' + CLASS_CARD_DETAILS_SECTION + '__placeholder';
                    const section = document.querySelector(selector) as HTMLElement;
                    const highlighted = document.querySelector('.' + CLASS_ASIDE_PANEL_ACTIONS_BUTTON_HIGHLIGHT);
                    if (highlighted) highlighted.classList.remove(CLASS_ASIDE_PANEL_ACTIONS_BUTTON_HIGHLIGHT); // если ещё предыдущая секция подсвечивается
                    section.classList.add(CLASS_ASIDE_PANEL_ACTIONS_BUTTON_HIGHLIGHT); // подсветить секцию
                    section.scrollIntoView({ block: 'center' });

                    if (highLightTimer.current) clearTimeout(highLightTimer.current);
                    highLightTimer.current = setTimeout(() => {
                        section.classList.remove(CLASS_ASIDE_PANEL_ACTIONS_BUTTON_HIGHLIGHT); // убрать подсветку
                    }, ACTIVE_SECTION_TIMER * 2);
                }, 0);
            }
        }).finally(() => setAdding(false));
    };

    function onDragEnd(result: DropResult) {
        setDragging(false);
        if (
            !result.destination ||
            (
                result.source.droppableId === result.destination.droppableId &&
                result.destination.index === result.source.index
            )
        ) {
            // do nothing
        } else {
            onSort(
                Number(result.draggableId),
                result.destination.index,
                Number(result.source.droppableId),
                Number(result.destination.droppableId)
            );
        }
    }

    const onDragStart = () => {
        setDragging(true);
    };

    const onPlusClick = () => {
        if (
            !isShowAdd &&
            isEmpty &&
            !newChecklistId &&
            elements &&
            elements.length &&
            elements.length === 1 &&
            elements[0].id === CHECKLIST_DEFAULT_ID
        ) {
            setNewChecklistId(CHECKLIST_DEFAULT_ID);
            setShowAdd(true);
            setShowDefaultChecklist(true);
        } else {
            onAdd();
        }
    };

    const onNameClick = () => {
        if (isEditing || isMultiple || isEmpty || isReadonly) return;
        onRename();
    };

    const onChange = (e: React.SyntheticEvent) => {
        const { value } = e.target as HTMLInputElement;
        setName(value);
    };

    const onSave = () => {
        setEditing(false);
        if (name && name.trim()) {
            if (!isMultiple) {
                setLoading(true);
            }
            onNameChange(name).finally(() => {
                if (!isMultiple) {
                    setLoading(false);
                }
            });
        }
    };

    const onEscape = (e: React.KeyboardEvent) => {
        if (e.key === 'Escape') {
            e.preventDefault();
            e.stopPropagation();
            setEditing(false);
        }
    };

    const onCopyClose = () => {
        if (copyRef.current) {
            setShowDefaultChecklist(false);
        }
        setCopy(false);
        setCopyCardId(null);
    };

    const onRename = () => {
        setEditing(true);
        if (name.includes(CHECKLIST_DEFAULT_NAME) || name === CARD_SECTION_CHECKLIST) {
            setTimeout(() => {
                inputRef.current.getInput().select();
            }, 0);
        }
    };

    React.useEffect(() => { // добавлять новые чеклисты вниз списка
        const newElements: IChecklist[] = [];
        const newChecklists = [...checklists];
        elements.forEach(checklist => {
            const idx = newChecklists.findIndex(({ id }) => id === checklist.id);
            if (idx > -1) {
                newElements.push(checklist);
                newChecklists.splice(idx, 1);
            }
        });
        newElements.push(...newChecklists);
        setElements(newElements);
    }, [checklists]);

    React.useEffect(() => {
        if (!isShowSection || isLoading) return;
        setName(checklists && checklists[0] && checklists[0].name || CHECKLIST_DEFAULT_NAME);
    }, [isShowSection, checklists]);

    React.useEffect(() => {
        if (isShow || isDefaultProperty) {
            setShowSection(true);
            addShowedProperty(EAsidePanelProperty.CARD_CHECKLIST);
        }
    }, [isShow, isDefaultProperty]);

    React.useEffect(() => {
        setCopyCardId(copyCardId);
        setCopy(!!copyCardId);
        setTimeout(() => {
            const copySection = document.querySelector('.' + CLASS_CARD_DETAILS + '__checklist-copy') as HTMLElement;
            if (copySection) {
                copySection.scrollIntoView({ block: 'center' });
            }
        }, 0);
    }, [copyCardId]);

    React.useEffect(() => {
        copyRef.current = isCopy && isEmpty;
    }, [isCopy, isEmpty]);

    if (!isShowSection && !showedProperties.has(EAsidePanelProperty.CARD_CHECKLIST)) {
        return;
    }

    return (
        <SettingsSection
            className={`
                ${CLASS_CARD_DETAILS_SECTION}
                ${CLASS_CARD_DETAILS_SECTION + '--checklists'}
                ${className}
                ${isEmpty && !isShowAdd ? className + '--empty' : ''}
                ${isReadonly ? className + '--readonly' : ''}
                ${isDragging ? className + '--drag' : ''}
                ${isEditing ? className + '--editing' : ''}
                ${isMultiple ? className + '--multiple' : ''}
            `}
            isRequired={isRequired}
            isRequiredError={isEmpty}
            sectionType={sectionType}
            titleSub={!isEditing && stats && !!stats.totalCount &&
                getMessages().getText(CARD_CHECKLIST_DONE, null, stats.checkedPercent, stats.checkedCount, stats.totalCount)
            }
            onClickTitle={onNameClick}
            titleButton={
                <>
                    {isEditing && !isMultiple &&
                        <div className={className + '-name'}>
                            <Input
                                className={className + '-input'}
                                value={name}
                                autosize={false}
                                autoFocus
                                onChange={onChange}
                                onKeyDown={onEscape}
                                onEnter={onSave}
                                onBlur={onSave}
                                ref={inputRef}
                            />
                            {name &&
                                <Button
                                    className={className + '-save'}
                                    onClick={onSave}
                                >
                                    {CARD_CHECKLIST_SAVE_BUTTON}
                                </Button>
                            }
                        </div>
                    }
                    {!isReadonly &&
                        <>
                            <CardChecklistActionsHOC
                                copyCardId={copyCardId}
                                cardId={cardId}
                                checklistId={checklists && checklists[0] && checklists[0].id}
                                isMultiple
                                isEmptyChecklists={isEmpty || isMultiple}
                                onRename={onRename}
                                onDelete={() => onDelete(checklists && checklists[0] && checklists[0].id).then((result) => {
                                    if (!result) setRemovingId(checklists[0].id);
                                })}
                                onCopy={setCopyCardId}
                            />
                            <Button
                                className={className + '-title-add'}
                                variant={'icon'}
                                onClick={onPlusClick}
                                tooltip={getAsidePanelTooltip({ value: CARD_CHECKLISTS_ADD })}
                            >
                                <Icon size={24} xlink={'plus-circle'}/>
                            </Button>
                        </>
                    }
                </>
            }
            title={isMultiple ? CARD_SECTION_CHECKLISTS
                : isEmpty ? CARD_SECTION_CHECKLIST
                    : checklists[0] || checklists && checklists.length > 1 &&
                    isLoading && !!checklists.find(({ id }) => id === CHECKLIST_DEFAULT_ID) ? name
                        : CARD_SECTION_CHECKLIST
            }
            isTitleTooltip={!isEditing}
        >
            <DragDropContext
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
            >
                {isLoading && <LoaderBlock />}
                {
                    elements.map((checklist, index) => {
                        const { id } = checklist;
                        if (
                            id === CHECKLIST_DEFAULT_ID &&
                            (
                                !isShowDefaultChecklist &&
                                !isEmpty &&
                                checklists.length > 1 &&
                                !(
                                    checklistItems &&
                                    checklistItems[0] &&
                                    checklistItems[0].checklistId === CHECKLIST_DEFAULT_ID
                                ) ||
                                isLoading ||
                                (checklists.length > 1 && isAdding)
                            )
                        ) {
                            return;
                        }
                        return (
                            <CardChecklistHOC
                                onDelete={() => onDelete(id).then((result) => {
                                    if (!result) setRemovingId(id);
                                })}
                                onAddOpen={setShowAdd}
                                isSingle={!isMultiple}
                                isOpened={newChecklistId === id}
                                boardId={boardId}
                                cardId={cardId}
                                checklistId={id}
                                onAddClose={() => setNewChecklistId(null)}
                                key={`${id}i${index}`} // checklist.id может не быть в сторе, пока не загрузится карта, если чеклист прилетел из риалтайм
                            />
                        );
                    })
                }
            </DragDropContext>
            {isCopy &&
                <CardChecklistCopySection
                    onClose={onCopyClose}
                    onCancel={onAdd}
                    selectedCardId={copyCardId}
                    setSelectedCardId={setCopyCardId}
                />
            }
            {(!isEmpty || isShowAdd) && !isReadonly &&
                <Button
                    className={className + '-add'}
                    variant={'icon-text'}
                    text={CARD_CHECKLISTS_ADD_NEW}
                    onClick={onAdd}
                >
                    <Icon xlink={'plus-circle'} size={24} />
                </Button>
            }
            <ActionModal
                isShow={!!removingId}
                actions={[
                    {
                        variant: 'primary',
                        onClick: onCloseModal,
                        children: CARD_SECTION_CHECKLISTS_MODAL_CANCEL
                    },
                    {
                        variant: 'secondary',
                        onClick: () => {
                            onCloseModal();
                            return onDelete(removingId);
                        },
                        children: CARD_SECTION_CHECKLISTS_MODAL_REMOVE
                    },
                ]}
                footer={<BasicUpgradeButtonHOC onClick={onCloseModal} />}
                text={getMessages().getText('card_form_section_checklists_modal_text', null, aloudCount)}
                title={getMessages().getText('card_form_section_checklists_modal_title', null, checklists?.find(checklist => checklist.id === removingId)?.name)}
                onClose={onCloseModal}
            />
        </SettingsSection>
    );
};
