import { IApplicationState, TFilterId } from '../../../../../../types/types';
import { savedFilterSelector } from './savedFilterSelector';

export const getMyWorkSavedFilterHideTitle = (
    state: IApplicationState,
    filterId: TFilterId
): boolean => {
    const filter = savedFilterSelector(state, filterId);
    return filter.meta && filter.meta.isMyWorkHideTitle;
};
