import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../../../../../types/types';
import { INotificationBeforeDueDate } from '../../../../../../../../../store/model/notificationBeforeDueDate/types';
import { notificationsBeforeDueDatesForCardUpdate } from '../../../state/effects/notificationsBeforeDueDatesForCardUpdate';

export const onChangeInterval: ActionCreator<ThunkAction> = (
    cardId: TCardId,
    notificationId: number,
    interval: number,
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        const cardEditedState = getState().notificationBeforeDueDateEdits[cardId];
        if (cardEditedState) {
            const targetNotifications = cardEditedState.notifications;
            const newNotificationsState: INotificationBeforeDueDate[] =
                targetNotifications.map(
                    (item) => {
                        if (item.id === notificationId) {
                            return {
                                ...item,
                                interval
                            }
                        }
                        return item;
                    },
                );
            dispatch(notificationsBeforeDueDatesForCardUpdate(cardId, newNotificationsState));
        }
    };
    return action;
};
