import { EPaidGradationSteps } from '../actions/segmentIdentifyAction/types';
import { IPaymentData } from '../../../types/backbone';
import { isFreeUser } from '../../../store/model/authUser/selectors/isFreeUser';
import { IApplicationState } from '../../../types/types';
import { FIRST_TRIAL_PROMO_CODE, SECOND_TRIAL_PROMO_CODE } from '../constants';
import { IAuthUser } from '../../../store/model/authUser/types';
import { isFreeVersionSelector } from '../../../view/react_components/base/selectors/isFreeVersionSelector';
import { ISubscription } from '../../../view/react_components/subscriptionAdmin/store/subscription/types';
import * as moment from 'moment';
import { getAuthUser } from '../../../store/model/authUser/selectors/getAuthUser';

export const planSelector = (state: IApplicationState, paymentData: IPaymentData): EPaidGradationSteps => {
    if (getPlanEnterprise(state, paymentData.subscriptionEnterprise)) return  EPaidGradationSteps.ENTERPRISE;
    if (getPlanProfessional(state, paymentData.subscriptionProfessional)) return EPaidGradationSteps.PROFESSIONAL;
    if (getPlanPremium(state, paymentData.subscriptionPremium)) return EPaidGradationSteps.PREMIUM;
    if (isFreeVersionSelector()) return EPaidGradationSteps.FREEMIUM;
    if (isFreeUser(state)) return EPaidGradationSteps.FREE;
    return EPaidGradationSteps.OTHER;
};

export const getPlanPremium = (state: IApplicationState, subscriptionPremium: boolean): boolean => {
    return (subscriptionPremium || (getAuthUser(state).domainWidePremium));
};

export const getPlanProfessional = (state: IApplicationState, subscriptionProfessional: boolean): boolean => {
    return (subscriptionProfessional || (getAuthUser(state).domainWideProfessional));
};

export const getPlanEnterprise = (state: IApplicationState, subscriptionEnterprise: boolean): boolean => {
    const authUser = getAuthUser(state);
    return (subscriptionEnterprise ||
        (authUser.domainWide &&
            !authUser.domainWideProfessional &&
            !authUser.domainWidePremium));
};

export const getFirstTrialEndDate = (trialSubscription: ISubscription): string =>  {
    if (trialSubscription && trialSubscription.promoCode === FIRST_TRIAL_PROMO_CODE) {
        return moment(trialSubscription.paymentExpDate * 1000).format('D MMM YYYY');
    }
    else {
        return ''
    }
};

export const getSecondTrialEndDate = (trialSubscription: ISubscription): string =>  {
    if (trialSubscription && trialSubscription.promoCode === SECOND_TRIAL_PROMO_CODE) {
        return moment(trialSubscription.paymentExpDate * 1000).format('D MMM YYYY');
    } else {
        return ''
    }
};

export const getDomainWideEndDate = (user: IAuthUser): string => {
    if (user.domainWideEndDate && user.domainWideEndDate !== 0) {
        return moment(user.domainWideEndDate).format('D MMM YYYY');
    } else {
        return 'none';
    }
};
