import { ICommentAuthor } from '../../store/model/card/types/IComment';
import { TCardId } from '../../types/types';

export interface IExportListener {
    onStart: () => void;
    onSuccess: () => void;
    onProgress: (percent: number) => void;
    onError: (errorMessage: string) => void;
}

export interface IBaseResult {
    success: boolean;
    errorMessage: string;
}

export interface IStartResult extends IBaseResult {
    listIds: number[];
    fileId: string;
    docUrl: string;
    fileName: string;
}

export interface ISaveCardResult extends IBaseResult {
    cursor: string;
    completed: boolean;
    saveCardCount: number;
}

export enum EPhase {
    ARCHIVE_CARDS,
    ACTIVE_CARDS
}

export interface ICommentDurationItem {
    author: ICommentAuthor;
    date: string;
    minutes: number;
}

export interface ICardReportRow extends ICommentDurationItem {
    cardId: TCardId
    epic: string;
    cardName: string;
    color: string;
    spentTime: string;
}
