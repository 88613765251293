import { AT_REMOVE, AT_SET_FIRST, AT_UPDATE, TCollaboratorsAction } from '../actions/types';
import { ICollaborators } from '../types';

const initialState: ICollaborators = [];

/**
 * добавляем сюда всех с одинаковым userId
 * уникальный collaborator.id
 */

export const collaboratorsReducer = (
    state = initialState,
    action: TCollaboratorsAction
): ICollaborators => {
    switch (action.type) {
        case AT_REMOVE: {
            return state.filter((collaborator) => collaborator.id !== action.collaborator.id );
        }

        case AT_UPDATE: {
            return [
                ...state.filter((collaborator) => collaborator.id !== action.collaborator.id ),
                action.collaborator
            ]
        }
        case AT_SET_FIRST: {
            return [
                action.collaborator,
                ...state.filter((collaborator) => collaborator.id !== action.collaborator.id )
            ]
        }

        default:
            return state;
    }
};
