import { Dispatch, ThunkAction } from 'app/types/actions';
import { IGetState, TBoardId, TCardId } from 'app/types/types';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../middlewares/segment';
import { cardOpen } from '../../../../../base/effects/cardOpen';

export const onClick = (
    cardId: TCardId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.ASSIGNED_TO_ME_BOARD_OPEN_CLICKED));
        dispatch(cardOpen(cardId));
    };
    return action;
};
