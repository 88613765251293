import { IApplicationState, TListId } from '../../../../types/types';
import { getListCardAgingSettings } from './getListCardAgingSettings';
import { ECardAgingIndicatorType } from '../../../../types/rest/ICardAging';

export const getListCardAgingIndicatorType = (
    state: IApplicationState,
    listId: TListId
): ECardAgingIndicatorType => {
    const cardAgingSettings = getListCardAgingSettings(state, listId);

    return cardAgingSettings && cardAgingSettings.indicator && cardAgingSettings.indicator.indicatorType || ECardAgingIndicatorType.BY_ACTIVITY;
}
