import { isObjectEmpty } from './../../../../../../view/react_components/helpers/isObjectEmpty';
import { IApplicationState, IGetState } from '../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../types/actions';
import { IDependencies } from '../../../../../../store/model/dependencies/dependencies/types';
import { getCardDependencesForRollback } from '../../../../../../store/model/selectors/getCardDependencesForRollback';
import { dependenciesActionSetAction } from '../../../../../../store/model/actions/dependenciesActionSetAction';
import { dependencySetAction } from '../../../../../../store/model/dependencies/dependencies/actions/dependencySetAction';
import { dependencyDeleteAction } from '../../../../../../store/model/dependencies/dependencies/actions/dependencyDeleteAction';
import { deleteRest } from '../deleteRest';
import { getBoardIdByCardId } from '../../../../../../store/model/selectors/getBoardIdByCardId';
import { dependenciesActiveRequestsUpdate } from '../../../../../../store/requestsState/effects/dependenciesActiveRequestsUpdate';
import { sendRealTimeStoreActionByBoardId } from '../../../../../../view/react_components/base/helpers/realTimeHelperTS';

export const dependenciesDelete = (
    dependencies: IDependencies,
    isOptimistic: boolean = false, // true = store -> rest -> rollback on error
    isRealtime: boolean = true, // send event to socket
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (
            !dependencies ||
            isObjectEmpty(dependencies)
        ) Promise.reject();

        const state = getState();
        let depsForRollback: IDependencies;
        if (isOptimistic) {
            depsForRollback = getCardDependencesForRollback(state, dependencies);
            deleteDependences(dispatch, dependencies);
        }
        dispatch(dependenciesActiveRequestsUpdate(dependencies, 1));

        const depPatchPromises: Promise<any>[] = [];
        for (let depId in dependencies) {
            depPatchPromises.push(dispatch(deleteRest(Number(depId))));
        }

        return Promise.all(depPatchPromises)
            .then(() => {
                if (!isOptimistic) {
                    deleteDependences(dispatch, dependencies);
                }
                if (isRealtime) {
                    sendToRealtimeDependencies(getState(), dispatch, dependencies);
                }
            })
            .catch((e: any) => {
                console.error(e);
                if (isOptimistic) {
                    restoreDependences(dispatch, depsForRollback);
                }
            })
            .finally(() => {
                dispatch(dependenciesActiveRequestsUpdate(dependencies, -1));
            });
    };
    return action;
};

const sendToRealtimeDependencies = (
    state: IApplicationState,
    dispatch: Dispatch,
    dependencies: IDependencies
) => {
    for (let id in dependencies) {
        const boardId = getBoardIdByCardId(state, dependencies[id].successorId);
        dispatch(sendRealTimeStoreActionByBoardId(boardId, dependenciesActionSetAction(dependencyDeleteAction(Number(id)))))
    }
}

const deleteDependences = (
    dispatch: Dispatch,
    dependencies: IDependencies
) => {
    for (let id in dependencies) {
        dispatch(dependenciesActionSetAction(dependencyDeleteAction(Number(id))));
    }
}

const restoreDependences = (
    dispatch: Dispatch,
    dependencies: IDependencies
) => {
    for (let id in dependencies) {
        dispatch(dependenciesActionSetAction(dependencySetAction(Number(id), dependencies[id])));
    }
}
