import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId, TTag } from '../../../../../../../../types/types';
import { toggleTag } from '../effects/toggleTag';

export const onToggle = (
    cardId: TCardId,
    tag: TTag
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(toggleTag(cardId, tag));
    };
    return action;
};
