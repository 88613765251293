import { Dispatch, ThunkAction } from '../../../types/actions';
import { EUserSettingsCommentSort } from '../../../types/rest/IRestAuthUser';
import { IGetState } from '../../../types/types';
import { getAuthUserSettings } from '../../../store/model/authUser/selectors/getAuthUserSettings';
import { authUserPatch } from './api/helper/authUserPatch';
import { getAuthUser } from '../../../store/model/authUser/selectors/getAuthUser';

export const authUserSetCommentsSortOrder = (
    commentSort: EUserSettingsCommentSort
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const authUser = getAuthUser(state);
        const userPatch = {
            id: authUser.id,
            settings: {
                ...authUser.settings,
                commentSort
    }
    };
        return dispatch(authUserPatch(userPatch));
    };
    return action;
};
