import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { getMessages, root } from '../../../../../../../store/constants';
import { sendInvitations } from '../effects/sendInvitations';
import { segmentTrackAction, SegmentUserEvent } from '../../../../../../../middlewares/segment';
import { SegmentUserOption } from '../../../../../../../middlewares/segment/trackEntities/userEvents';
import { snackbarSuccessDefault } from '../../../../../snackbarsStack';
import { MESSAGE_SUCCESS_PLURAL, MESSAGE_SUCCESS_SINGULAR, SNACKBAR_INVITE_SUCCESS_ID } from '../constants';

export const onInvite = (
    emails: string[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(segmentTrackAction(SegmentUserEvent.INVITE_INVITE_CLICKED, {
            name: SegmentUserOption.QUANTITY,
            value: emails.length
        }));
        dispatch(sendInvitations(emails)).then(() => {
            root.App.vent.trigger(root.App.vent['dialog:closed']);
            dispatch(snackbarSuccessDefault({
                id: SNACKBAR_INVITE_SUCCESS_ID,
                text: getMessages().getPlural(emails.length, [MESSAGE_SUCCESS_SINGULAR, MESSAGE_SUCCESS_PLURAL]),
                controls: []
            }))
        })
    };
    return action;
}
