import { AsidePanelActionsButton } from '../../../../..';
import * as React from 'react';
import { IPwcPanelButtonProps } from './types';
import { CLASS_BOARD_DETAILS_SECTION } from '../../../../constants';
import { BOARD_SECTION_PWC_IMPORT } from '../../constants';

export function PwcPanelButton ({
    show,
    tooltip,
    onClick
}: IPwcPanelButtonProps) {
    if (!show) {
        return null;
    }
    return (
        <AsidePanelActionsButton
            children={null}
            sectionSelector={`.${CLASS_BOARD_DETAILS_SECTION}--pwc-import`}
            title={BOARD_SECTION_PWC_IMPORT}
            tooltip={tooltip}
            onClick={onClick}
        />
    );
}
