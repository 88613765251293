import { ICardAssigneeAddAction } from './types';
import { AT_CARD_ASSIGNEE_ADD_ACTION } from './constants';
import { ICardAssignee } from '../../cardAssignee/types';
import { TCardId } from '../../../../types/types';

export const cardAssigneeAddAction = (
    cardId: TCardId,
    cardAssignee: ICardAssignee
): ICardAssigneeAddAction => ({
    type: AT_CARD_ASSIGNEE_ADD_ACTION,
    cardId,
    cardAssignee
});
