import { IApplicationState } from '../../../../types/types';
import { getAuthUser } from './getAuthUser';
import { IRateAppPoll } from '../../../../types/rest/IUserMeta';
import { DEFAULT_POLL } from '../../../../const';

export const getUserRateAppPoll = (
    state: IApplicationState
): IRateAppPoll => {
    const user = getAuthUser(state);
    return user.meta && user.meta.rateAppPoll || DEFAULT_POLL;
};
