import { IGetState, TBoardId } from 'app/types/types';
import { Dispatch, ThunkAction } from 'app/types/actions';
import { SegmentBrandingEvent, segmentTrackAction } from 'app/middlewares/segment';
import { SegmentBoardOption, SegmentBoardOptionStatusValue } from 'app/middlewares/segment/trackEntities/boardEvents';

export const onChangeSwitch = (
    boardId: TBoardId,
    enabled: boolean = false
): ThunkAction=> {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentBrandingEvent.OWN_IMAGE_TOGGLED, {
            name: SegmentBoardOption.STATUS,
            value: enabled ? SegmentBoardOptionStatusValue.ON: SegmentBoardOptionStatusValue.OFF}));
    };
    return action;
};
