import { IApplicationState } from '../../../../types/types';
import { EUserMetaQualificationFormStatus } from '../../../../types/rest/IUserMeta';
import { getUserQualificationFormMeta } from './getUserQualificationFormMeta';

export const getUserQualificationFormStatus = (
    state: IApplicationState
): EUserMetaQualificationFormStatus => {
    const meta = getUserQualificationFormMeta(state);
    return meta && meta.status;
};
