import { IApplicationState, TListId } from 'app/types/types';
import { connect } from 'react-redux';
import { StatusLists } from '../../components/StatusLists/StatusLists';
import { IStatusListsEvents, IStatusListsFields } from '../../components/StatusLists/types';
import { IStatusListsHOCProps } from './types';
import { getBoard } from 'app/store/model/selectors/getBoardById';
import { EDashboardStatusListType, IDashboardStatusLists } from 'app/types/rest/IRestDashboard';
import { getListSelectSearchSelectOptions } from '../../../../../cardDetails/ListSelect/hocs/ListSelectHOC/selectors/getListSelectSearchSelectOptions';
import { onListChange } from './events/onListChange';
import { StatusListsTogglerType } from '../StatusListsTogglerHOC/types';
import { onToggleListStatusMapping } from './events/onToggleListStatusMapping';
import { getListSelectSearchSelectOptionsByListType } from './selectors/getListSelectSearchSelectOptionsByListType';
import { onClose } from './events/onClose';
import { onWorkListMoveToggle } from './events/onWorkListMoveToggle';
import { getBoardWorkListMove } from '../../../../../../../../store/model/board/selectors/getBoardWorkListMove';
import { getPanelReadonly } from 'app/view/react_components/aside_panel/boardDetails/selectors/getPanelReadonly';

const mapStateToProps =
    (
        state: IApplicationState,
        ownProps: IStatusListsHOCProps
    ): IStatusListsFields => {
        const {boardId} = ownProps;
        const board = getBoard(state, boardId);
        const statusLists: IDashboardStatusLists = board.statusLists;
        const listsOptions = getListSelectSearchSelectOptions(state, boardId);
        const onHoldId = statusLists ? statusLists.onHoldId : 0;
        const inWorkId = statusLists ? statusLists.inWorkId : 0;
        const doneId = statusLists ? statusLists.doneId : 0;
        const onHoldInWorkTogleChecked = !!(onHoldId && inWorkId);
        const doneTogleChecked = !!doneId;
        const onHoldListsOptions = getListSelectSearchSelectOptionsByListType(listsOptions, EDashboardStatusListType.ON_HOLD, statusLists);
        const inWorkListsOptions = getListSelectSearchSelectOptionsByListType(listsOptions, EDashboardStatusListType.IN_WORK, statusLists);
        const doneListsOptions = getListSelectSearchSelectOptionsByListType(listsOptions, EDashboardStatusListType.DONE, statusLists, true);
        const isDisabled = getPanelReadonly(state, boardId);
        return {
            boardId,
            holdInWorkTogleChecked: onHoldInWorkTogleChecked,
            doneTogleChecked,
            onHoldId,
            inWorkId,
            doneId,
            onHoldListsOptions,
            inWorkListsOptions,
            isWorkListsMove: getBoardWorkListMove(state, boardId),
            doneListsOptions,
            isDisabled
        }
    };

const mapDispatchToProps = (
    dispatch: any,
    { boardId }: IStatusListsHOCProps
): IStatusListsEvents => ({
    onClose: () => dispatch(onClose()),
    onListSelected: (listType: EDashboardStatusListType, listId: TListId) => dispatch(onListChange(boardId, listType, listId)),
    onToggleListStatusMapping: (togleType: StatusListsTogglerType) => dispatch(onToggleListStatusMapping(boardId, togleType)),
    onWorkListMoveToggle: () => dispatch(onWorkListMoveToggle(boardId)),
});

export const StatusListsHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(StatusLists);
