import { IApplicationState, TBoardId } from '../../../types/types';
import { getBoard } from './getBoardById';
import { DUE_DATE_TIME } from '../constants';

export const getBoardMetaDefaultDueDateTime = (
    state: IApplicationState,
    boardId: TBoardId
): Date => {
    const board = getBoard(state, boardId);
    return board && board.meta && board.meta.defaultDueDateTime && new Date(board.meta.defaultDueDateTime) || DUE_DATE_TIME;
}
