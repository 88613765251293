import { IGetState } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { getAuthUserIsHideSubcards } from '../../../../../../../../store/model/authUser/selectors/getAuthUserIsHideSubcards';
import { authUserSetHideDoneSubcards } from '../../../../../../../../rest/effects/authUser/authUserSetHideDoneSubcards';

export const changeHideDone = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const isHideDoneSubcards = getAuthUserIsHideSubcards(state);

        const event = isHideDoneSubcards ? SegmentCardEvent.SUBCARDS_HIDE_DONE_BUTTON_CLICKED_OFF : SegmentCardEvent.SUBCARDS_HIDE_DONE_BUTTON_CLICKED_ON;
        dispatch(segmentTrackAction(event));

        return dispatch(authUserSetHideDoneSubcards(!isHideDoneSubcards));
    };
    return action;
};
