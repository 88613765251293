import { ICardAssignee } from '../../../cardAssignee/types';
import { TStatus } from '../../../../../types/model';
import { IApplicationState } from '../../../../../types/types';
import {
    getMyWorkShowBoards
} from '../../../../../view/react_components/main/myWorkView/store/selectors/getMyWorkShowBoards';
import {
    getMyWorkArchivedCards
} from '../../../../../view/react_components/main/myWorkView/selectors/getMyWorkArchivedCards';
import {
    getMyWorkBoardFetchCardsOption
} from '../../../../../view/react_components/main/myWorkView/selectors/getMyWorkBoardFetchCardsOption';
import { ONE_DAY_LENGTH } from '../../../../../view/react_components/aside_panel/filterPanelBoard/constants';

export const getMyTaskCardFilter = (
    state: IApplicationState
) => (
    cardAssignee: ICardAssignee
): boolean => {
    const {
        dashboardId,
        status,
        cardId,
        archived,
        lastActionDate
    } = cardAssignee;
    const showArchived = getMyWorkArchivedCards(state);
    const showBoards = getMyWorkShowBoards(state);
    const fetchCardsOptionTimeStamp = getMyWorkBoardFetchCardsOption(state, dashboardId) * ONE_DAY_LENGTH;
    return (
        status === TStatus.STATUS_ACTIVE &&
        showBoards.includes(dashboardId) &&
        cardId &&
        (!archived || showArchived) &&
        Date.now() - lastActionDate <= fetchCardsOptionTimeStamp
    );
}
