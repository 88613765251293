import * as React from 'react';
import './_CardDueDate.scss';
import { CLASS_MY_WORK_CARD, CLASS_MY_WORK_CARD_CONTROL } from '../../../constants';
import { Button, ButtonDropdown, Icon, Tooltip } from 'kui';
import { TOOLTIP_DUE_DATE } from './constants';
import { ICardDueDateProps } from './types';
import { getFormattedDate } from '../../../helpers/getFormattedDate';
import { DatepickerHOC } from '../../../../../base/components/Datepicker/hocs/DatepickerHOC/DatepickerHOC';
import {
    CARD_DUE_DATE,
    CARD_START_DATE,
    CARD_START_DATE_MILESTONE
} from '../../../../../aside_panel/cardDetails/DatesSection/components/CardDatesSection/constants';

export function CardDueDate ({
    defaultDueTime,
    defaultStartTime,
    dueDate,
    isDisabled,
    isDueRequired,
    isMilestone,
    isOverdue,
    isReadonly,
    isStartRequired,
    isUserTimeFormat12,
    portalId,
    startDate,
    onChange,
    onLoadCard
}: ICardDueDateProps) {
    const className = CLASS_MY_WORK_CARD + '__date';
    const [isOpened, setOpened] = React.useState(null);

    const onClose = () => setOpened(false);

    React.useEffect(() => {
        if (isMilestone || isDisabled) {
            setOpened(false);
        }
    }, [isMilestone, isDisabled]);

    return (
        <Tooltip
            delayClose={1}
            value={dueDate ? TOOLTIP_DUE_DATE + getFormattedDate(dueDate, false, isUserTimeFormat12) : null}
            isNoEvents
        >
            <ButtonDropdown
                className={`
                    ${className}
                    ${!dueDate ? className + '--no-date' : ''}
                    ${isOpened ? className + '--opened' : ''}
                    ${CLASS_MY_WORK_CARD_CONTROL}
                `}
                dropdownClassName={`
                    ${className + '-dropdown'}
                    ${!dueDate ? className + '-dropdown--no-date' : ''}
                    ${CLASS_MY_WORK_CARD_CONTROL}
                `}
                onOpen={() => {
                    onLoadCard();
                    setOpened(true);
                }}
                onClose={onClose}
                opened={isOpened}
                portal
                portalId={portalId}
                directionHorizontal={'right'}
                disabled={isMilestone || isDisabled}
            >
                <Button className={className + '-button'}>
                    <Icon
                        size={24}
                        xlink={isOverdue ? 'overdue' : 'due-date'}
                    />
                    {!!dueDate && <>
                        {getFormattedDate(dueDate)}
                    </>}
                </Button>
                <DatepickerHOC
                    selectedToDate={dueDate && new Date(dueDate * 1000)}
                    selectedFromDate={startDate && new Date(startDate * 1000)}
                    isTime
                    isFromRequired={isStartRequired}
                    isToRequired={isDueRequired}
                    onChange={(date, dateType) => {
                        onChange(date, dateType);
                        onClose();
                    }}
                    isReadonly={isReadonly}
                    isToDisabled={isDisabled}
                    isFromDisabled={isDisabled}
                    isSingle={isMilestone}
                    defaultFromTime={defaultStartTime}
                    defaultToTime={defaultDueTime}
                    fromPlaceholder={isMilestone ? CARD_START_DATE_MILESTONE : CARD_START_DATE}
                    toPlaceholder={CARD_DUE_DATE}
                    isOverdue={isOverdue}
                    isToOpened
                    isNoDropdown
                />
            </ButtonDropdown>
        </Tooltip>
    );
};
