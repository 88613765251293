import { IApplicationState, TBoardId } from '../../../../types/types';
import { IBackup } from '../types';
import { getBoardBackupList } from './getBoardBackupList';
import { EBackupExportType } from '../../../../types/rest/IRestBackup';

export const getBoardBackupListByExportType = (
    state: IApplicationState,
    boardId: TBoardId,
    exportType: EBackupExportType,
): IBackup[]  => {
    const list = getBoardBackupList(state, boardId);
    return list.filter(backup => backup.exportType === exportType);
}
