import { ITypeSwitcherLocalStorage, TYPE_SWITCHER_LOCALSTORAGE } from '../types';
import { EViewTypes } from '../../../../../../const';
import { VIEW_TYPES } from '../../../../typeSwitcher/constants';

export const getTypeSwitcherLocalStorage = (
): ITypeSwitcherLocalStorage => {
    const data = localStorage.getItem(TYPE_SWITCHER_LOCALSTORAGE);
    const typeSwitcherLocalStorage: ITypeSwitcherLocalStorage = data ? JSON.parse(data) : {};
    // из-за того, что поменяли ключи, нужно убедиться, что в localstorage лежат валидные типы, невалидные заменяем на канбан
    Object.values(typeSwitcherLocalStorage).forEach(boardState => {
        const activeType: EViewTypes = boardState.activeType;
        if (!VIEW_TYPES[activeType]) {
            boardState.activeType = EViewTypes.KANBAN_VIEW_TYPE;
        }
    })
    return typeSwitcherLocalStorage;
}
