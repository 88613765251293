import { IGetState, TBoardId, TCardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IDriveDoc } from '../../../../../../../../store/model/card/types/IDriveDoc';
import { IComment } from '../../../../../../../../store/model/card/types/IComment';
import { createComment } from './createComment';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';

export const createAttachmentComments = (
    driveDocs: IDriveDoc[],
    boardId: TBoardId,
    cardId: TCardId
): ThunkAction => {
    return (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        const comment: IComment = {
            cardId
        }
        return Promise.all(driveDocs.map(driveDoc => {
            return dispatch(createComment(boardId, {
                ...comment,
                driveDocIds: [driveDoc.id]
            })).then(() => {
                dispatch(segmentTrackAction(SegmentCardEvent.COMMENT_ATTACHMENT_ADDED));
            })
        }))
    }
};
