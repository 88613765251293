'use strict';

App.Models.DashboardStatisticLag = App.Models.StatBase.extend({

    rest: '/time',

    onError: function(xhr, textStatus, errorThrown){
        App.model.set('timeLag', 0);
    }

});
