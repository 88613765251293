import { IGetState, TBoardId } from '../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { IPushNotificationSettings } from '../../../../../store/model/pushNotificationSettings/types';
import { getPushNotificationSettingsForRollback } from '../../../../../store/model/selectors/getPushNotificationSettingsForRollback';
import { updateAction } from '../../../../../store/model/pushNotificationSettings/actions/updateAction';
import { patchRest } from '../patchRest';
import { pushNotificationSettingsActionSet } from '../../../../../store/model/pushNotificationsSettings/actions/pushNotificationSettingsActionSet';
import { pushNotificationsSettingsActionSet } from '../../../../../store/model/actions/pushNotificationsSettingsActionSet';
import { sendAuthUserRealTimeStoreAction } from '../../../../../view/react_components/base/helpers/realTimeHelperTS';

export const pushNotificationSettingsPatchRest = (
    boardId: TBoardId,
    pushNotification: IPushNotificationSettings,
    isRealtime: boolean = true, // send event to socket
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const pushNotificationForRollback = getPushNotificationSettingsForRollback(getState(), boardId, pushNotification);
        dispatch(pushNotificationsSettingsActionSet(pushNotificationSettingsActionSet(boardId, updateAction(pushNotification))));
        return dispatch(patchRest(boardId, pushNotification))
            .then(() => {
                const update = pushNotificationsSettingsActionSet(pushNotificationSettingsActionSet(boardId, updateAction(pushNotification)));
                dispatch(update);
                if (isRealtime) {
                    dispatch(sendAuthUserRealTimeStoreAction(update))
                }
                return pushNotification;
            })
            .catch((e: any) => {
                console.error(e);
                dispatch(pushNotificationsSettingsActionSet(pushNotificationSettingsActionSet(boardId, updateAction(pushNotificationForRollback))));
            });
    };
    return action;
};
