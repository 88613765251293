import { IGetState, TBoardId, TCardId } from '../types/types';
import { Dispatch, ThunkAction } from '../types/actions';
import { getBoardPermissionsAllowEdit } from '../store/model/selectors/getBoardPermissionsAllowEdit';
import { root } from '../store/constants';
import { getCard } from '../store/model/selectors/getCard';
import { updateSelectedCards } from '../view/react_components/clickManager';
import { TStatus } from '../types/model';
import { getCardRecurringTasks } from '../store/model/selectors/getCardRecurringTasks';

export const openMultiCardForm = (
    boardId: TBoardId,
    cardIds: TCardId[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const isMultiselectPanel = document.querySelector('.multiselect__list') as HTMLElement;

        if (!getBoardPermissionsAllowEdit(state, boardId)) {
            return root.App.router.navigate(root.App.router.getUrl(null), { trigger: true });
        }
        const cards = cardIds.map(cardId => getCard(state, cardId)).filter(card => !!card);
        dispatch(updateSelectedCards(boardId, cards.map(card => card.id)));
        if (!cards.length) { // ссылка на удалённые карты - сбросить ссылку
            return root.App.router.navigate(root.App.router.getUrl(null), { trigger: true });
        }
        if (!isMultiselectPanel) {
            root.App.controller.asidePanel.showMultiCard(boardId);
        }

        const templateCard = cards.find(card => card.status === TStatus.STATUS_SERVICE_TEMPLATE);
        if (templateCard && !root.App.controller.leftAsidePanel.isCardTemplatesOpened() && !root.App.controller.leftAsidePanel.isRecurringTasksOpened()) {
            if (getCardRecurringTasks(state, templateCard.id).length) {
                root.App.controller.leftAsidePanel.showRecurringTasks(boardId);
            } else {
                root.App.controller.leftAsidePanel.showCardTemplates(boardId);
            }
        }
    };
    return action;
}
