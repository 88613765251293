import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { SegmentBoardEvent, segmentTrackAction } from '../../../../../../../../../middlewares/segment';
import { boardSetTimeZone } from '../../../../../../../../../rest/effects/board/boardSetTimeZone';
import { TBoardId } from '../../../../../../../../../types/types';

export const onChange = (
    boardId: TBoardId,
    timeZone: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(boardSetTimeZone(boardId, timeZone));
        dispatch(segmentTrackAction(SegmentBoardEvent.CHANGED_TIME_ZONE));
    };
    return action;
};
