import { EDateType } from '../../../../../../../const';
import * as React from 'react';
import { IHintHOCOwnProps } from '../../../../../hints/hocs/HintHOC/types';

export enum E12HoursType {
    AM = 'am',
    PM = 'pm'
}

export enum EDatepickerDropdownDirectionY {
    AUTO = 'auto',
    UP = 'up',
    DOWN = 'down',
}

export enum EDatepickerDropdownDirectionX {
    LEFT = 'left',
    RIGHT = 'right'
}

export interface IDatepickerItemFields {
    dateHint?: IHintHOCOwnProps;
    dateType: EDateType;
    defaultTime: Date;
    fromDate?: Date;
    isDisabled: boolean;
    isHideDate: boolean;
    isOpened?: boolean;
    isRequired: boolean;
    maxFromDate?: Date;
    minToDate?: Date;
    label: string;
    placeholder: string;
    selectedDate: Date;
    timeHint: IHintHOCOwnProps;
    toDate?: Date;
}

export interface IDatepickerItemEvents {
    onBlur: (e: React.FocusEvent) => void;
    onCalendarClosed: () => void;
    onCalendarOpened: () => void;
    onClear: () => void;
    onClick: (e: React.MouseEvent) => void;
    onDateChange: (date: Date) => void;
    onDateSelected: () => void;
    onFocus: (e: React.FocusEvent) => void;
    onKeyDown: (e: React.KeyboardEvent) => void;
    onTimeOpened: () => void;
    onTimeSelected: () => void;
    onUnevenTimeSelected: () => void;
}

export interface IDatepickerItemProps extends
    IDatepickerItemFields,
    IDatepickerItemEvents
{}
