import { IApplicationState, TBoardId } from '../../../../types/types';
import {
    EAsidePanelProperty
} from '../../../../view/react_components/aside_panel/asidePanel/components/AsidePanel/types';
import { getBoardMeta } from './getBoardMeta';

export const getBoardCardDefaultPropertiesForMigration = (
    state: IApplicationState,
    boardId: TBoardId
): EAsidePanelProperty[] => {
    const meta = getBoardMeta(state, boardId);
    return meta && meta.cardDefaultProperties;
};
