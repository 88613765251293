import { IApplicationState } from '../../../../../../../../types/types';
import { connect } from 'react-redux';
import { BoardBackupsSection } from '../../components/BoardBackupsSection/BoardBackupsSection';
import { IBoardBackupsSectionHOCProps } from './types';
import { IBoardBackupsSectionEvents, IBoardBackupsSectionFields } from '../../components/BoardBackupsSection/types';
import { onDidMount } from './events/onDidMount';
import {getSectionType} from '../../../../selectors/getSectionType';

const mapStateToProps = (
    state: IApplicationState,
    ownProps: IBoardBackupsSectionHOCProps
): IBoardBackupsSectionFields => {
    const { boardId } = ownProps;
    const sectionType = getSectionType(state, boardId, null, 'allowBackupXml', 'allowCreateBackUp');
    return {
        sectionType,
        boardId
    }
};

const mapDispatchToProps = (
    dispatch: any,
    ownProps: IBoardBackupsSectionHOCProps
): IBoardBackupsSectionEvents => {
    const { boardId } = ownProps;
    return {
        onDidMount: () => dispatch(onDidMount(boardId))
    }
};

export const BoardBackupsSectionHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(BoardBackupsSection);

BoardBackupsSectionHOC.displayName = 'BoardBackupsSectionHOC';
