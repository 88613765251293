import { IGetState, TCardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { cardSetDueDateWithEpics } from '../../../../../../../../rest/effects/card/card/cardSetDueDateWithEpics';
import { cardSetStartDateWithEpics } from '../../../../../../../../rest/effects/card/card/cardSetStartDateWithEpics';
import { EDateType } from '../../../../../../../../const';
import { setHintCanShow } from '../../../../../../hints/effects/setHintCanShow';
import { EHintKey } from '../../../../../../hints';
import {
    getAuthUserIsShowGanttContextHints
} from '../../../../../../../../store/model/authUser/selectors/getAuthUserIsShowGanttContextHints';
import { getCard } from '../../../../../../../../store/model/selectors/getCard';
import { GANTT_CONTEXT_TIMEOUT } from '../../../../../../hints/components/Hint/constants';
import { hintHide } from '../../../../../../hints/effects/hintHide';
import {
    ganttContextHintsCardIdSetAction
} from '../../../../../asidePanel/store/actions/ganttContextHintsCardIdSetAction';

export const onChange = (
    cardId: TCardId,
    date: Date,
    dateType: EDateType
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const newDate = date
            ? Math.round(date.getTime() / 1000)
            : null;
        if (getAuthUserIsShowGanttContextHints(state)) {
            dispatch(ganttContextHintsCardIdSetAction(cardId));
            dispatch(hintHide(EHintKey.GANTT_CONTEXT_ADD_DATE));
            const card = getCard(state, cardId);
            const currentDate = dateType === EDateType.DUE_DATE ? card.dueDate : card.startDate;
            let action: ThunkAction;
            if (newDate && newDate !== currentDate) {
               if (card.ganttVisibility) { // если гант уже включен - показать хинт включить гант
                    action = setHintCanShow(EHintKey.GANTT_CONTEXT_GANTT_TYPE_SWITCHER);
                } else { // показать хинт добавить на гант
                    action = setHintCanShow(EHintKey.GANTT_CONTEXT_SHOW_ON_GANTT);
                }

                setTimeout(() => {
                    dispatch(action);
                }, GANTT_CONTEXT_TIMEOUT);
            }
        }
        return dateType === EDateType.DUE_DATE ?
            dispatch(cardSetDueDateWithEpics(cardId, newDate))
            : dispatch(cardSetStartDateWithEpics(cardId, newDate));
    };
    return action;
};
