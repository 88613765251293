import { createRoot } from 'react-dom/client';
import {Provider} from "react-redux";
import React from "react";
import {AsidePanelBoardHOC} from "../react_components/aside_panel/asidePanel/hocs/AsidePanelBoardHOC/AsidePanelBoardHOC";
import { removeAsidePanelPortal } from "../../helper/removeAsidePanelPortal";
import {
    AsidePanelMyWorkSettingsHOC
} from "../react_components/aside_panel/asidePanel/hocs/AsidePanelMyWorkSettingsHOC/AsidePanelMyWorkSettingsHOC";

App.Views.MyWorkSettingsView = App.Views.BaseView.extend({
    asideClassName: 'aside-panel__my-work-settings',

    initialize: function (options) {
        this.root = createRoot(this.el);
        this.store = options.store;
        this.parent = options.parent;
        this.tabKey = options.tabKey;
    },

    render: function () {
        this.parent.$el.find('.aside-panel__hide').hide();
        this.root.render(
            <Provider store={this.store}>
                <AsidePanelMyWorkSettingsHOC
                    tabKey={this.tabKey}
                    closedRef={document.activeElement}
                />
            </Provider>
        );
        return this;
    },

    remove: function () {
        this.parent.$el.find('.aside-panel__hide').show();
        setTimeout(() => {
            setTimeout(() => {
                this.root.unmount(); // надо дождаться пока сработает react onblur, только потом отцеплять конпонент
                Backbone.View.prototype.remove.call(this);
            });
        });
        removeAsidePanelPortal(this.el);
    }
});

export default App.Views.MyWorkSettingsView;
