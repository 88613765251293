import { IUserIdentifyTraits } from '../actions/segmentIdentifyAction/types';
import { userIdentifyAccountTypeNameSelector } from './userIdentifyAccountTypeNameSelector';
import { IPaymentData } from '../../../types/backbone';
import { PaypalHelper } from '../../../helper/paypal_helper';
import { IActiveSubscription } from '../../../types/model';
import { subscriptionAccountHolderSelector } from './subscriptionAccountHolderSelector';
import {
    getDomainWideEndDate,
    getFirstTrialEndDate,
    getPlanEnterprise,
    getPlanProfessional,
    getPlanPremium,
    getSecondTrialEndDate,
    planSelector
} from './planSelector';
import { userIdentifyCommentSortTraitSelector } from './userIdentifyCommentSortTraitSelector';
import * as moment from 'moment';
import { isFreeUser } from '../../../store/model/authUser/selectors/isFreeUser';
import store from '../../../store/configureStore';
import { getTrialSubscription } from '../../../store/model/authUser/selectors/getTrialSubscription';
import { navigationPanelTypeSelector } from '../../../view/react_components/main/navigationPanel/store/selectors/navigationPanelTypeSelector';
import { getAuthUser } from '../../../store/model/authUser/selectors/getAuthUser';
import { getUserDomain } from '../../../store/model/authUser/selectors/getUserDomain';
import { isPaidUser } from '../../../store/model/authUser/selectors/isPaidUser';
import { Intercom } from '../../../helper/intercom';
import { max } from 'underscore';
import { getAppVersion } from './getAppVersion';
import { EAuthUserAccountTypeNames } from 'app/types/rest/IRestAuthUser';
import { subscriptionIdsSelector } from 'app/middlewares/segment/selectors/subscriptionIdsSelector';
import {root} from 'app/store/constants';

export const userIdentifyTraitsSelector = (): IUserIdentifyTraits => {
    const state = store.getState();
    const user = getAuthUser(state);
    const paymentData = <IPaymentData>PaypalHelper.paymentData();
    const activeSubscriptions = PaypalHelper.getSubscriptions() as Array<IActiveSubscription>;
    const activeSubscriptionsLength = activeSubscriptions.length;
    const ownSubscriptionsLength = PaypalHelper.getOwnSubscriptions().length;
    const maxSeats = activeSubscriptions.length === 0 ? 0 : (max(activeSubscriptions, sub => sub.seatsCount) as IActiveSubscription).seatsCount;
    const commentSortTrait = userIdentifyCommentSortTraitSelector(state);
    const navPanel = navigationPanelTypeSelector(state);
    const language = navigator.language.split('-')[0];
    return {
        id: user.id,
        email: user.email,
        createdAt: new Date(user.createDateTimeStamp * 1000),
        creation_date: moment(user.createDateTimeStamp * 1000).format('D MMM YYYY'),
        name: user.fullName,
        firstName: user.firstName,
        lastName: user.lastName,
        domainWide_end_date: getDomainWideEndDate(user),
        domainWide: user.domainWide || user.domainWidePremium || user.domainWideProfessional,
        accountType: user.eduV2?  EAuthUserAccountTypeNames.EDU_2023 : userIdentifyAccountTypeNameSelector(user),
        domain: getUserDomain(state),
        plan_trial: Boolean(paymentData.isTrialActive),
        trial_end_date: getFirstTrialEndDate(getTrialSubscription(state)),
        extended_trial_end_date: getSecondTrialEndDate(getTrialSubscription(state)),
        plan_premium: getPlanPremium(state, Boolean(paymentData.subscriptionPremium)),
        plan_professional: getPlanProfessional(state, Boolean(paymentData.subscriptionProfessional)) && !Boolean(paymentData.isTrialActive),
        plan_enterprise: getPlanEnterprise(state, Boolean(paymentData.subscriptionEnterprise)),
        subscriptions_active: activeSubscriptionsLength,
        subscriptions_own: ownSubscriptionsLength,
        max_seats: maxSeats,
        subscriptions_holders: subscriptionAccountHolderSelector(),
        subscriptions_ids: subscriptionIdsSelector(),
        freePlan: isFreeUser(state),
        plan: planSelector(state, paymentData),
        refferal: user.meta && user.meta.refferal || '',
        industry: user.domainInfo ? user.domainInfo.industry : '',
        employeeCount: user.domainInfo ? user.domainInfo.employeeCount : '',
        landing_page: user.meta && user.meta.landingPage || '',
        language: language,
        navPanel,
        useType: user.meta && user.meta.useType || '',
        paidUser: isPaidUser(state),
        intercom: Intercom.isChatEnabled(),
        abandonedCart: user.meta && user.meta.abandonedCart,
        dayInSystem: user.meta && user.meta.dayInSystem,
        eduV2: user.eduV2,
        version: getAppVersion(),
        usersInDomainCount: user.usersInDomainCount,
        country_code: user.country ? user.country : root.App.controller.country,
        utm_settings: new URLSearchParams(window.location.search).get('utm_settings'),
        ...commentSortTrait
    };
};
