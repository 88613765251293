import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { toggleEnabledEmailNotifications } from '../effects/toggleEnabledEmailNotifications';
import { TBoardId } from '../../../../../../../../../types/types';

export const onChange = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(toggleEnabledEmailNotifications(boardId));
    };
    return action;
};
