import { connect } from 'react-redux';
import { ICardAttachmentFormHOCProps } from './types';
import { ICardAttachmentFormEvents } from '../../components/CardAttachmentForm/type';
import { onSaveTitle } from './events/onSaveTitle';
import { CardAttachmentForm } from '../../components/CardAttachmentForm/CardAttachmentForm';

const mapDispatchToProps = (
    dispatch: any,
    { attachment }: ICardAttachmentFormHOCProps
): ICardAttachmentFormEvents => {
    return {
        onSaveTitle: (title) => dispatch(onSaveTitle(attachment.id, title))
    }
};

export const CardAttachmentFormHOC = connect(
    null,
    mapDispatchToProps
)(CardAttachmentForm);
CardAttachmentFormHOC.displayName = 'CardAttachmentFormHOC';
