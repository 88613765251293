import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { IArchiveButtonHOCProps } from './types';
import { onClick } from './events/onClick';
import { IOpenInDriveButtonEvents, IOpenInDriveButtonFields } from '../../components/OpenInDriveButton/types';
import { OpenInDriveButton } from '../../components/OpenInDriveButton/OpenInDriveButton';
import { onGetLink } from './events/onGetLink';
import { getBoard } from 'app/store/model/selectors/getBoardById';

const mapStateToProps = (
    state: IApplicationState,
    { boardId }: IArchiveButtonHOCProps,
): IOpenInDriveButtonFields => {
    const board = getBoard(state, boardId);
    return {
        parentFolderInfo: board.parentFolderInfo
    };
};
const mapDispatchToProps = (
    dispatch: any,
    { boardId }: IArchiveButtonHOCProps
): IOpenInDriveButtonEvents => ({
    onClick: () => dispatch(onClick()),
    onGetLink: () => dispatch(onGetLink(boardId))
});

export const OpenInDriveButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(OpenInDriveButton);

OpenInDriveButtonHOC.displayName = 'OpenInDriveButtonHOC';
