import { IGetState, TCardId } from 'app/types/types';
import { Dispatch, ThunkAction } from 'app/types/actions';
import { selectFiles } from '../effects/selectFiles';
import { IDriveDoc } from 'app/store/model/card/types/IDriveDoc';
import { addAttachments } from 'app/rest/effects/card/attacments/addAttachments';
import {
    setAttachmentAddedSessionStorage
} from '../../../../AttachmentsSection/helpers/setAttachmentAddedSessionStorage';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';

export const onSelectClick = (
    cardId: TCardId,
    onClose: () => void,
    setAttachments: (docs: IDriveDoc[]) => void,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.COMMENT_ATTACHMENT_ADD_CLICKED));

        return dispatch(selectFiles(cardId))
            .then((driveDocs: IDriveDoc[]) => {
                setAttachmentAddedSessionStorage();
                if (onClose) onClose();
                setAttachments(driveDocs);
                return driveDocs;
            });
    };
    return action;
};
