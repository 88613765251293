import * as React from 'react';
import { IMyWorkBoardsProps } from './types';
import './_MyWorkBoards.scss';
import { MyWorkBoardsSearchSelectHOC } from '../../hocs/MyWorkBoardsSearchSelectHOC/MyWorkBoardsSearchSelectHOC';
import { CLASS_MY_WORK } from '../../../../constants';
import { MyWorkBoard } from '../MyWorkBoard/MyWorkBoard';

export function MyWorkBoards ({
    showBoards,
    onFetchChange,
    onRemoveBoard
}: IMyWorkBoardsProps) {
    const className = CLASS_MY_WORK + '-import-boards';

    return (
        <div className={className}>
            <MyWorkBoardsSearchSelectHOC />
            {!!showBoards.length &&
                <div className={className + '__list'}>
                    {showBoards.map(board => (
                        <MyWorkBoard
                            key={board.id}
                            board={board}
                            onRemove={() => onRemoveBoard(board.id)}
                            onFetchChange={(option) => onFetchChange(board.id, option)}
                        />))
                    }
                </div>
            }
        </div>
    );
};
