import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { IDatepickerFields } from '../../components/Datepicker/types';
import { Datepicker } from '../../components/Datepicker/Datepicker';
import { getUserDateFormat } from '../../../../../../../store/model/authUser/selectors/getUserDateFormat';
import { getUserTimeFormat12 } from '../../../../../../../store/model/authUser/selectors/getUserTimeFormat12';
import {
    getUserCalendarStartsSunday
} from '../../../../../../../store/model/authUser/selectors/getUserCalendarStartsSunday';
import { isEqual } from 'underscore';

const mapStateToProps = () => {
    interface ICaheProps extends IDatepickerFields{};
    let cache: ICaheProps = null;
    const getCache = (
        value: ICaheProps
    ): ICaheProps => {
        if (isEqual(value, cache)) return cache;
        cache = value; return value;
    };

    return (
        state: IApplicationState,
        ownProps: IDatepickerFields
    ): IDatepickerFields => {
        return getCache({
            ...ownProps,
            dateFormat: getUserDateFormat(state),
            isCalendarStartsSunday: getUserCalendarStartsSunday(state),
            is12Hour: getUserTimeFormat12(state)
        });
    };
};

export const DatepickerHOC = connect(
    mapStateToProps,
    null
)(Datepicker);

DatepickerHOC.displayName = 'DatepickerHOC';
