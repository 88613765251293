import { TCardId, TChecklistId } from '../../../../types/types';
import { checklistItemsUpdateAction } from '../checklist/actions/checklistItemsUpdateAction';
import { IChecklistItems } from '../checklist/types';
import { checklistsActionSetAction } from '../../actions/checklistsActionSetAction';
import { checklistActionSetAction } from '../checklists/actions/checklistActionSetAction';

export const checklistItemsUpdateStore = (
    cardId: TCardId,
    checklistId: TChecklistId,
    items: IChecklistItems
) => {
    return checklistsActionSetAction(checklistActionSetAction(cardId, checklistId, checklistItemsUpdateAction(items)));
};
