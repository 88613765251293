import {IAutoBackupBackupScheduleAction} from './types';
import {AT_AUTO_BACKUP_BACKUP_SCHEDULE_SET} from './constants';
import {IBackupSchedule} from '../../../../../../../../../store/model/boardBackups/types';

export const autoBackupBackupSchedule = (
    backupSchedule: IBackupSchedule
): IAutoBackupBackupScheduleAction => ({
    type: AT_AUTO_BACKUP_BACKUP_SCHEDULE_SET,
    backupSchedule
});
