import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TBoardId, TCardId } from '../../../../../../../../types/types';
import { findEditedMention } from '../../../helpers/MentionsHelper';
import { getHighlightedMentionIndex } from '../../../selectors/getHighlightedMentionIndex';
import { getCardMentionOptions } from '../../../selectors/getCardMentionOptions';
import { root } from 'app/store/constants';
import { commentUpdate } from '../../../store/reducers/actions/commentUpdate';

export const pasteCommentMention = (
    input: HTMLInputElement,
    boardId: TBoardId,
    cardId: TCardId
): ThunkAction => {
    return (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        let {selectionStart, value} = input;
        const state = getState();

        const mentions = getCardMentionOptions(state, boardId, cardId);
        const highlightedMentionIndex = getHighlightedMentionIndex(state);
        let user = mentions[highlightedMentionIndex];
        if (!highlightedMentionIndex && (!user || !user.mention)) { // если есть группы, то быстро нажав энетр можно получить тайтл группы
            user = mentions[1];
        }
        const userMention = user && user.mention ? user.mention : '';

        const mention = findEditedMention(selectionStart);

        if (selectionStart === undefined) { // html
            const selection = root.getSelection();
            selectionStart = selection.anchorOffset;
            let node = selection.anchorNode as HTMLElement;
            if (node.innerHTML === '<br>') { // случился перенос на Enter
                node = node.previousSibling as HTMLElement;
                selectionStart = node.lastChild.nodeValue.length - 2; // конец предыдущей строки
            }
            value = node.nodeValue || node.innerHTML;
            const textStart = value.substr(0, selectionStart - mention.length);
            const textEnd = value.substr(selectionStart);
            const extraSpace = !!textStart.length && textStart[textStart.length - 1] !== ' ' && user ? ' ' : '';
            const text1 = `${textStart}${extraSpace}${userMention}`;
            let newValue = `${text1} ${textEnd}`;
            node.nodeValue = newValue;
            node.innerHTML = newValue;

            try {
                const range = document.createRange();
                range.setStart(node, text1.length);
                selection.removeAllRanges();
                selection.addRange(range);
            } catch (e) {}
        } else {
            const textStart = value.substr(0, selectionStart - mention.length);
            const textEnd = value.substr(selectionStart);
            const extraSpace = !!textStart.length && textStart[textStart.length - 1] !== ' ' && user ? ' ' : '';
            const text = `${textStart}${extraSpace}${userMention} ${textEnd}`;
            dispatch(commentUpdate({ value: text }));

            setTimeout(() => {
                const cursorPosition = selectionStart + userMention.length;
                input.focus();
                input.setSelectionRange(cursorPosition, cursorPosition);
            }, 0);
        }

        dispatch(commentUpdate({ mentionSubstring: '' }));

        const w = window as any;
        w.mentionPasted = true;
        setTimeout(() => {
            w.mentionPasted = false;
        }, 200);
    };
};
