import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { ICommentsHeaderEvents, ICommentsHeaderFields } from '../../components/CommentsHeader/types';
import { onChangeCommentsOrder } from './events/onChangeCommentsOrder';
import { CommentsHeader } from '../../components/CommentsHeader/CommentsHeader';
import {
    getAuthUserSettingsCommentsSort
} from '../../../../../../../store/model/authUser/selectors/getAuthUserSettingsCommentsSort';
import { ICommentsHeaderHOCProps } from './types';
import { getCardComments } from '../../../../../../../store/model/selectors/getCardComments';

const mapStateToProps = (
    state: IApplicationState,
    props: ICommentsHeaderHOCProps
): ICommentsHeaderFields => {
    const { cardId } = props;
    const comments = getCardComments(state, cardId); // спрятать сортировку, пока комментариев < 2

    return {
        order: comments.length > 1 ? getAuthUserSettingsCommentsSort(state) : null
    };
};
const mapDispatchToProps = (
    dispatch: any,
    { cardId }: ICommentsHeaderHOCProps
): ICommentsHeaderEvents => {
    return {
        onChangeOrder: () => dispatch(onChangeCommentsOrder(cardId))
    };
};

export const CommentsHeaderHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CommentsHeader);

CommentsHeaderHOC.displayName = 'CommentsHeaderHOC';
