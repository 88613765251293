import * as React from 'react';
import './_MyWorkSettingsColumnsSection.scss';
import { IMyWorkSettingsColumnsSectionProps } from './types';
import { CLASS_MY_WORK } from '../../../../constants';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { Button, Checkbox, Divider, Icon } from 'kui';
import { ListItem } from '../../../../../../base/components/ListItem/ListItem';
import { SettingsSection } from '../../../../../../base/components/SettingsSection/SettingsSection';
import { MY_WORK_SETTINGS_COLUMNS_RESET, MY_WORK_SETTINGS_COLUMNS_TITLE } from './constants';

export const MyWorkSettingsColumnsSection = ({
    columns,
    onSort,
    onToggle,
    onReset
}: IMyWorkSettingsColumnsSectionProps) => {
    const className = CLASS_MY_WORK + '-settings-columns-section';

    function onDragEnd(result: DropResult) {
        if (
            !result.destination ||
            result.destination.index === result.source.index
        ) {
            // do nothing
        } else {
            onSort(result.draggableId, result.destination.index, columns.map(column => column.title));
        }
    }

    return (
        <SettingsSection
            className={className}
            title={MY_WORK_SETTINGS_COLUMNS_TITLE}
        >
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={className} direction={'vertical'}>
                    {
                        (provided, snapshot) => {
                            return (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    {
                                        columns.map((column, index) => {
                                            return (
                                                <Draggable
                                                    draggableId={column.title}
                                                    index={index}
                                                    key={column.title}
                                                    isDragDisabled={column.isNotDraggable}
                                                >
                                                    {
                                                        (provided, snapshot) => {
                                                            return (
                                                                <div
                                                                    key={column.title}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    ref={provided.innerRef}
                                                                >
                                                                    <ListItem
                                                                        actions={null}
                                                                        img={null}
                                                                        isDraggable={!column.isNotDraggable}
                                                                        text={
                                                                            <Checkbox
                                                                                checked={!column.isHidden}
                                                                                onChange={() => onToggle(column.title)}
                                                                                direction={'left'}
                                                                                disabled={column.isAlwaysShown}
                                                                            >
                                                                                {column.titleLong ? column.titleLong : column.title}
                                                                            </Checkbox>
                                                                        }
                                                                    />
                                                                </div>
                                                            );
                                                        }
                                                    }
                                                </Draggable>
                                            )
                                        })
                                    }
                                    {provided.placeholder}
                                </div>
                            );
                        }
                    }
                </Droppable>
            </DragDropContext>
            <Divider />
            <Button
                variant={'icon-text'}
                className={className + '__reset'}
                onClick={onReset}
                text={MY_WORK_SETTINGS_COLUMNS_RESET}
            >
                <Icon xlink={'arrow-circle'} size={24} />
            </Button>
        </SettingsSection>
    );
}
