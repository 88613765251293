import { TListId } from '../../../../../../../../../types/types';
import { IListSelectSearchSelectOptions } from '../../../../../../cardDetails/ListSelect/hocs/ListSelectHOC/types';
import { EDashboardStatusListType, IDashboardStatusLists } from '../../../../../../../../../types/rest/IRestDashboard';
import { STATUS_LIST_ARCHIVE } from '../../../constants';
import { STATUS_LIST_ARCHIVE_LIST_ID } from '../../../../../../../../../const';

export const getListSelectSearchSelectOptionsByListType = (
    options: IListSelectSearchSelectOptions[],
    statusListType: EDashboardStatusListType,
    statusLists: IDashboardStatusLists,
    isArchiveButton?: boolean
): IListSelectSearchSelectOptions[] => {
    let result: IListSelectSearchSelectOptions[] = [];
    const listTypes: EDashboardStatusListType[] = Object.values(EDashboardStatusListType);
    const archiveOption = {
        text: STATUS_LIST_ARCHIVE,
        value: STATUS_LIST_ARCHIVE_LIST_ID.toString(),
        img: 'archive',
        active: true
    };
    options.forEach((option, idx) => {
        const resultOption = {...option};
        const listId: TListId = Number(option.value);
        let isDisabled = false;
        listTypes.forEach((type) => {
            if (statusLists && type !== statusListType && statusLists[type] === listId) {
                isDisabled = true;
            }
        });

        if (!isDisabled) {
            result.push(resultOption);
        }
    });
    return isArchiveButton ? [
        archiveOption,
        ...result
    ] : result;
}
