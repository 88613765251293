import { ICard } from '../types';

export const getCardFullNameHelper = (
    card: ICard
): string => {
    if (!card) return '';
    let fullCardName = card.name;
    if (card.cardNumber) fullCardName = `${card.cardNumber}: ${card.name}`;
    return fullCardName;
}
