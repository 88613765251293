import { connect } from 'react-redux';
import { IApplicationState } from 'app/types/types';
import { ICardChecklistCopyHOCProps } from './types';
import { ICardChecklistCopyEvents, ICardChecklistCopyFields } from '../../components/CardChecklistCopy/types';
import { getAvailableChecklists } from './selectors/getAvailableChecklists';
import { CardChecklistCopy } from '../../components/CardChecklistCopy/CardChecklistCopy';
import { loadChecklists } from './effects/loadChecklists';
import { onCopy } from './events/onCopy';
import { onClickCancel } from './events/onClickCancel';
import { loadChecklist } from './effects/loadChecklist';

const mapStateToProps = (
    state: IApplicationState,
    props: ICardChecklistCopyHOCProps
): ICardChecklistCopyFields => {
    const { isMultipleCards, selectedCardId, selectedChecklistId } = props;
    const checklistOptions = getAvailableChecklists(state, selectedCardId);

    return {
        isMultipleCards,
        checklistOptions,
        selectedCardId,
        selectedChecklistId,
    };
};

const mapDispatchToProps = (
    dispatch: any,
    props: ICardChecklistCopyHOCProps
): ICardChecklistCopyEvents => {
    const { boardId, cardId, onSelectCard, onSelectChecklists, onCancel, onClose } = props;
    return {
        onClose,
        onLoadChecklist: (cardId) => dispatch(loadChecklist(cardId)),
        onSelectCard,
        onSelectChecklists,
        onCancel: () => dispatch(onClickCancel(onCancel)),
        onCopy: (srcCardId, srcChecklistId) => dispatch(onCopy(boardId, cardId, srcCardId, srcChecklistId)),
        onDidMount: () => dispatch(loadChecklists(boardId)),
    };
};

export const CardChecklistCopyHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardChecklistCopy);

CardChecklistCopyHOC.displayName = 'CardChecklistCopyHOC';
