import { connect } from 'react-redux';
import { onClick } from './events/onClick';
import { IHelpPanelVideoLinkDispatchProps } from '../../components/HelpPanelVideoLink/types';
import { HelpPanelVideoLinkComponent } from '../../components/HelpPanelVideoLink/HelpPanelVideoLinkComponent';

const mapDispatchToProps = (
    dispatch: any
): IHelpPanelVideoLinkDispatchProps => {
    return {
        onClick: () => dispatch(onClick())
    }
};

export const HelpPanelVideoLinkHOC = connect(
    null,
    mapDispatchToProps
)(HelpPanelVideoLinkComponent);

HelpPanelVideoLinkHOC.displayName = 'HelpPanelVideoLinkHOC';
