export const getBigPreviewIcon = (
    iconUrl: string
): string => {
    if (!iconUrl) return iconUrl;

    const smallIconSize = '16';
    const mediumIconSize = '32';
    const bigIconSize = '64';
    iconUrl = iconUrl.replace(smallIconSize, bigIconSize);
    iconUrl = iconUrl.replace(mediumIconSize, bigIconSize);
    return iconUrl;
}
