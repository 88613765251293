import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { TCommentId } from '../../../../../../../../store/model/card/types/IComment';
import { commentUpdate } from '../../../store/reducers/actions/commentUpdate';
import { getCommentById } from '../../../selectors/getCommentById';
import { IGetState, TCardId } from '../../../../../../../../types/types';
import { getCommentDriveDocsByIds } from '../../../selectors/getCommentDriveDocsByIds';
import { getCommentValue } from '../../../selectors/getCommentValue';
import { getAuthUser } from '../../../../../../../../store/model/authUser/selectors/getAuthUser';
import { makeMentionByName } from '../../../helpers/MentionsHelper';

export const onReply = (
    cardId: TCardId,
    commentId: TCommentId,
    text: string,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(commentUpdate({
            editCommentId: null,
            replyAttachmentId: null,
            replyCommentId: commentId,
            replyText: text,
        }));
        const state = getState();
        const comment = getCommentById(state, cardId, commentId);
        const { permissionId } = getAuthUser(state);
        if ( // подставляем меншен только когда инпут пуст и это чужой комментарий
            !getCommentValue(state) && permissionId !== comment.author.permissionId
        ) {
            dispatch(commentUpdate({ value: (makeMentionByName(comment.author.fullName) + ' ')}));
        }
        if (comment.driveDocIds && comment.driveDocIds.length === 1) {
            const driveDocs = getCommentDriveDocsByIds(state, commentId, comment.driveDocIds);
            if (driveDocs[0].isImage) {
                dispatch(commentUpdate({
                    replyAttachmentId: comment.driveDocIds[0],
                }));
            }
        }
        dispatch(segmentTrackAction(SegmentCardEvent.COMMENT_REPLY_CLICKED));
    };

    return action;
};
