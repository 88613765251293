import { ICards } from '../../../../../../store/model/cards/types';
import { cardsRestPatch } from './cardsRestPatch';
import { TBoardId, TCardId } from '../../../../../../types/types';
import { ICardBatch, ICardBatches } from '../types';
import { Dispatch } from '../../../../../../types/actions';
import * as _ from 'underscore';
import { processCardUpdate } from '../restCardMiddleware/processCardUpdate';

export const cardPatchRestBatch = (
    cardId: TCardId,
    dispatch: Dispatch,
    cardBatch: ICardBatch,
    isRealtime: boolean = true,
    generateCardNumber: boolean = false
) => {
    if (_.isEmpty(cardBatch.card)) {
        // Если нет ничего то и не надо запускать цепочку постобработчиков resolve() и возвращать Then
        // последнего постобработчика не надо (return cardPromise.promise;)
        return Promise.resolve();
    }
    const cards: ICards = {
        [cardId]: cardBatch.card
    };
    return dispatch(cardsRestPatch(cards, undefined, isRealtime, generateCardNumber)).then(() => {
        cardBatch.callbacks.forEach(callback => callback(cardBatch.card));
    });
};

export const cardsPatchRestBatch = (
    dispatch: Dispatch,
    boardId: TBoardId,
    cardBatches: ICardBatches = {},
    showMoveInfoPopup: boolean = true,
    calcOrderNumbers: boolean = true
) => {
    dispatch(processCardUpdate(boardId, cardBatches, showMoveInfoPopup, calcOrderNumbers));
    let promises: Promise<any>[] = [];
    for (let cardId in cardBatches) {
        promises.push(cardPatchRestBatch(Number(cardId), dispatch, cardBatches[cardId]));
    }
    return Promise.all(promises);
};
