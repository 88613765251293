import { TCardAssigneeRoleId, TCardId, TPermissionId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { cardToggleAssigneeRole } from '../../../../../../../../rest/effects/card/card/cardToggleAssigneeRole';

export const onToggleRole = (
    cardId: TCardId,
    permissionId: TPermissionId,
    roleId: TCardAssigneeRoleId
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        return dispatch(cardToggleAssigneeRole(cardId, permissionId, roleId));
    }
    return action;
}
