import * as React from 'react';
import { ITabAttachmentsProps, TBoardAttachmentsTab } from './types';
import { BoardAttachmentsListHOC } from '../../../BoardAttachmentsList/hocs/BoardAttachmentsListHOC';
import { BoardAttachmentsHeaderHOC } from '../../../BoardAttachmentsHeader/hocs/BoardAttachmentsHeaderHOC';
import { CLASS_BOARD_ATTACHMENTS } from './constants';
import './_TabAttachments.scss';
import { LoaderBlock } from 'kui';

export function TabAttachments({
    boardId,
    isGridView,
    onDidMount,
    onLoad
}: ITabAttachmentsProps) {
    const [isLoading, setLoading] = React.useState(true);
    const [searchQuery, setSearchQuery] = React.useState('');
    const [tab, setTab] = React.useState(TBoardAttachmentsTab.ALL);

    React.useEffect(() => {
        onDidMount();

        onLoad().then(() => {
            setLoading(false);
        });
    }, []);

    return (
        <div className={CLASS_BOARD_ATTACHMENTS}>
            {isLoading ?
                <LoaderBlock />
                :
                <>
                    <BoardAttachmentsHeaderHOC
                        searchQuery={searchQuery}
                        tab={tab}
                        isGridView={isGridView}
                        onSearchChange={setSearchQuery}
                        onTabChange={setTab}
                    />
                    <BoardAttachmentsListHOC
                        boardId={boardId}
                        searchQuery={searchQuery}
                        tab={tab}
                        isGridView={isGridView}
                    />
                </>
            }
        </div>
    );
}
