import { TBoardId } from '../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { resetSelectedCards } from '../../../../../clickManager/effects/resetSelectedCards';

export const onDidMount = (
    boardId: TBoardId,
): ThunkAction => {
    return (dispatch: Dispatch) => {
        dispatch(resetSelectedCards());
        return Promise.resolve();
    };
};
