import * as React from 'react';
import { ITabBrandingProps } from './types';
import { BoardLibThemesHOC } from '../../../../../../base/components/Branding/LibThemes/hocs/BoardLibThemesHOC/BoardLibThemesHOC';
import { BoardCustomThemeHOC } from '../../../../../../base/components/Branding/CustomTheme/hocs/BoardCustomThemeHOC/BoardCustomThemeHOC';
import { BoardLogoHOC } from '../../../Logo/hocs/BoardLogoHOC/BoardLogoHOC';

export function TabBranding ({
    boardId,
    isShowCustomTheme,
}: ITabBrandingProps) {
    return (<div>
        <BoardLogoHOC boardId={boardId}/>
        <BoardLibThemesHOC boardId={boardId}/>
        {isShowCustomTheme &&
            <BoardCustomThemeHOC boardId={boardId}/>
        }
    </div>);
}
