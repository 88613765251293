import React, {useEffect} from 'react';
import {connect} from 'react-redux';

import MoveTo from './../../../../../base/components/move_to/move_to';
import GooglePushToElements from './../components/googlePushToElements';

import onElementClicked from '../events/onElementClicked';
import onActionSelected from '../events/onActionSelected';

const GoogleCalendarMoveTo = (props) => {
    return (
        <MoveTo
            isLoading={props.isLoading}
            isLoaded={props.isLoaded}
            elementsHeader={props.elementsHeader}
            categoryHeader={props.categoryHeader}
            categories={props.categories}
            elements={props.elements}
            elementName={props.elementName}
            selectedCat={props.selectedCat}
            selectedElements={props.selectedElements}
            disabledCategories={props.disabledCategories}
            elementsLoader={props.elementsLoader}
            loadingElement={props.loadingElement}
            onElementSelected={props.onElementSelected}
            getElementsForCategory={props.getElementsForCategory}
            elementView={props.elementView}
        />
    );
};

GoogleCalendarMoveTo.defaultProps = {    
    isLoaded: true,
    isLoading: false,
    elementsHeader: 'Calendar',
    categoryHeader: 'Add',
    elementName: 'summary',
    elementView: GooglePushToElements
};

const mapStateToProps = (state) => ({
    categories: state.pushToGoogleCalendar.categories,
    selectedCat: state.pushToGoogleCalendar.selectedAction,
    selectedElements: state.pushToGoogleCalendar.selectedElements,
    elementsLoader: state.pushToGoogleCalendar.elementsLoader,
    elements: state.pushToGoogleCalendar.elements,
    loadingElement: state.pushToGoogleCalendar.loadingElement,
    disabledCategories: state.pushToGoogleCalendar.disabledCategories
});

const mapDispatchToProps = (dispatch) => {
    return {
        onElementSelected: (event, el) => dispatch(onElementClicked(event, el)),
        getElementsForCategory: selectedAction => dispatch(onActionSelected(selectedAction))
    }
};

const connectedGoogleCalendarMoveTo = connect(mapStateToProps, mapDispatchToProps)(GoogleCalendarMoveTo);
connectedGoogleCalendarMoveTo.displayName = 'GoogleCalendarMoveToHoc';

export default connectedGoogleCalendarMoveTo;






