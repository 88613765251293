import { IApplicationState } from '../../../../types/types'
import { ICollaborator } from '../../collaborators/types';

export const getBoardCollaboratorBySessionId = (
    state: IApplicationState,
    boardId: number,
    sessionId: string
): ICollaborator => {
    const boardCollaborators = state.modelsCollaborators.boards[boardId] || [];
    return boardCollaborators.find( (collaborator) => collaborator.sessionId === sessionId);
};
