import { ThunkAction } from '../../../../../../../types/actions';
import { completeRequest } from '../effects/completeRequest';
import { segmentTrackAction, SegmentUserEvent } from '../../../../../../../middlewares/segment';

export const onAllow = (
    onAllowOwn: () => void
): ThunkAction => {
    return (
        dispatch
    ) => {
        dispatch(segmentTrackAction(SegmentUserEvent.BROWSER_NOTIFICATIONS_ALLOW_CLICKED));
        if (onAllowOwn) onAllowOwn();
        dispatch(completeRequest());
    }
}
