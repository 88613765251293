import { IApplicationState } from '../../../../../types/types';
import { TCondition } from '../../types';
import { EHintKey, getHintConfig } from '../../constants';
import { getShowGanttContextHints } from './getShowGanttContextHints';
import { getTypeSwitcherActiveType } from '../../../typeSwitcher/store/selectors/getTypeSwitcherActiveType';
import { getActiveBoardId } from '../../../../../store/model/selectors/getActiveBoardId';
import { EViewTypes } from '../../../../../const';

export const ganttContextGanttViewResize: TCondition = (
    state: IApplicationState
) => {
    const hint = getHintConfig(EHintKey.GANTT_CONTEXT_GANTT_VIEW_RESIZE);
    if (!hint) return false;

    if (!getShowGanttContextHints(state)) return false;

    const boardId = getActiveBoardId(state);
    return getTypeSwitcherActiveType(state, boardId) === EViewTypes.GANTT_VIEW_TYPE;
};
