import { connect } from 'react-redux';
import { onClose } from './events/onClose';
import { Tutorial } from '../../components/Tutorial/Tutorial';
import { ITutorialEvents, ITutorialFields } from '../../components/Tutorial/types';
import { IApplicationState } from '../../../../../types/types';
import { ITutorialHOCProps } from './types';
import { getAsidePanelWidth } from '../../../aside_panel/asidePanel/store/selectors/getAsidePanelWidth';
import { onFullscreen } from './events/onFullscreen';
import { onMute } from './events/onMute';
import { onPlay } from './events/onPlay';

const mapStateToProps = (
    state: IApplicationState,
    { tutorial }: ITutorialHOCProps
): ITutorialFields => {
    return {
        asidePanelWidth: getAsidePanelWidth(state),
        tutorial,
    };
}

const mapDispatchToProps = (
    dispatch: any
): ITutorialEvents => {
    return {
        onClose: (currentTime, durationTime, tutorial, userClose) => dispatch(onClose(currentTime, durationTime, tutorial, userClose)),
        onFullscreen: (tutorial, isFullscreen) => dispatch(onFullscreen(tutorial, isFullscreen)),
        onPlay: (tutorial, isPlaying) => dispatch(onPlay(tutorial, isPlaying)),
        onMute: (tutorial, isMuted) => dispatch(onMute(tutorial, isMuted)),
    }
}

export const TutorialHOC = connect(
    mapStateToProps,
    mapDispatchToProps)
(Tutorial);

TutorialHOC.displayName = 'TutorialHOC';
