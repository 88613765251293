import * as _ from 'underscore';
import { getAppVersion } from "./middlewares/segment/selectors/getAppVersion";

if(!Object.values) {
  window.Object.values = function (obj) {
	  return Object.keys(obj).map(function(key) {
		    return obj[key];
		});
  };
}

export function CSRF(
){
    let code = App.Util.getCookie('X-CSRF-Header');
    if (!code) {
        code = Math.random().toString(36).slice(-8);
        Settings.CSRF = code;
        document.cookie = 'X-CSRF-Header=' + Settings.CSRF + ';path=/; SameSite=None; Secure';
    }
}

(function($) {
    'use strict';

    if (window.Settings.development) {
        let eventStopPropagation = Event.prototype.stopPropagation;
        Event.prototype.stopPropagation = function() {
            console.log('event propagation stopped');
            eventStopPropagation.apply(this, arguments);
            window.App.vent.trigger(App.vent['stopPropagation'], this);
        };
    }

    _.templateSettings.interpolate = /\<\@\=(.+?)\@\>/gim;
    _.templateSettings.evaluate = /\<\@(.+?)\@\>/gim;
    _.templateSettings.escape= /\<\@\-(.+?)\@\>/gim;

    Backbone.emulateHTTP = true;

    window.App = {
        Models: {},
        Classes: {},
        Collections: {},
        UI: {Views:{}},
        Views: {},
        Router: {},
        Mixins: {},
        Helpers: {}
    };

    App.settings = Settings;

    //CSRF();

    App.vent = _.extend(Vent, Backbone.Events);

    (function() {
        // listen to this event on a certain collection e.g. to display loader
        var oldCollectionFetch = Backbone.Collection.prototype.fetch;
        Backbone.Collection.prototype.fetch = function(options) {
            if (!options || !options.silent) {
                this.trigger('fetch', this, options);
            }
            oldCollectionFetch.call(this, options);
        };

        // http://stackoverflow.com/questions/13144494/backbone-relational-set-method-not-updating-related-models
        // fix all tigered event 'relational:change:
        Backbone.RelationalModel.prototype.updateRelations = function( changedAttrs, options ) {
            if ( this._isInitialized && !this.isLocked() ) {
                _.each( this._relations || [], function( rel ) {
                    if ( !changedAttrs || ( rel.keySource in changedAttrs || rel.key in changedAttrs ) ) {
                        if (this.attributes[ rel.keySource ] === null) {
                            this.attributes[ rel.key ] = null;// hackish code to allow setting relation to null
                                                              // (resetting color)
                        }
                        var val = this.attributes[ rel.keySource ] || this.attributes[ rel.key ];
                        if ( rel.related !== val ) {
                            this.trigger( 'relational:change:' + rel.key, this, val, options || {} );
                            // automatically update related models
                            _.each(val, function (data) {
                                if (data) {
                                    var model = rel.related.get(data.id);
                                    if (model) {
                                        model.set(data, options);
                                    }
                                }
                            });
                        }
                    }

                    // Explicitly clear 'keySource', to prevent a leaky abstraction if 'keySource' differs from 'key'.
                    //todo try to uncomment 3 row below
                    //if ( rel.keySource !== rel.key ) {
                    //    delete this.attributes[ rel.keySource ];
                    //}
                }, this );
            }
        };

        Backbone.RelationalModel.prototype.set = function( key, value, options ) {
			Backbone.Relational.eventQueue.block();

			// Duplicate backbone's behavior to allow separate key/value parameters, instead of a single 'attributes'
            // object
			var attributes,
				result;

			if ( _.isObject( key ) || key == null ) {
				attributes = key;
				options = value;
			}
			else {
				attributes = {};
				attributes[ key ] = value;
			}

			try {
				var id = this.id,
					newId = attributes && this.idAttribute in attributes && attributes[ this.idAttribute ];

				// Check if we're not setting a duplicate id before actually calling `set`.
				Backbone.Relational.store.checkId( this, newId );

				result = Backbone.Model.prototype.set.apply( this, arguments );

				// Ideal place to set up relations, if this is the first time we're here for this model
				if ( !this._isInitialized && !this.isLocked() ) {
					this.constructor.initializeModelHierarchy();

					// Only register models that have an id. A model will be registered when/if it gets an id later on.
					if ( newId || newId === 0 ) {
						Backbone.Relational.store.register( this,options );
					}

					this.initializeRelations( options );
				}
				// The store should know about an `id` update asap
				else if ( newId && newId !== id ) {
					Backbone.Relational.store.update( this , options);
				}

				if ( attributes ) {
					this.updateRelations( attributes, options );
				}
			}
			finally {
				// Try to run the global queue holding external events
				Backbone.Relational.eventQueue.unblock();
			}

			return result;
		};

		/**
		 * Add a 'model' to its appropriate collection. Retain the original contents of 'model.collection'.
		 * @param {Backbone.RelationalModel} model
		 */
		Backbone.Store.prototype.register =  function( model, options ) {
			var coll = this.getCollection( model );

			if ( coll ) {
				var modelColl = model.collection;
				coll.add( model ,options);
				model.collection = modelColl;
			}
		};

		/**
		 * Explicitly update a model's id in its store collection
		 * @param {Backbone.RelationalModel} model
		 */
		Backbone.Store.prototype.update =  function( model, options ) {
			var coll = this.getCollection( model );

			// Register a model if it isn't yet (which happens if it was created without an id).
			if ( !coll.contains( model ) ) {
				this.register( model,options );
			}

			// This triggers updating the lookup indices kept in a collection
			coll._onModelEvent( 'change:' + model.idAttribute, model, coll ,options);

			// Trigger an event on model so related models (having the model's new id in their keyContents) can add it.
			model.trigger( 'relational:change:id', model, coll ,options);
		};

        /**
         * produces JSON including relational attributes
         * @param options: if includeRelated=true, relational attributes are included; otherwise works as standard toJSON method
         * @return {json} json
         */
        Backbone.RelationalModel.prototype.toViewJSON = function( options ) {
            var json;
            if (options && options.includeRelated) {
                json = Backbone.Model.prototype.toJSON.call( this, options );
                var value;
                _.each( this._relations, function( rel ) {
                    var related = json[ rel.key ];
                    if (related) {
                        if ( related instanceof Backbone.Collection ) {
                            value = [];
                            related.each( function( model ) {
                                var curJson = model.toViewJSON();
                                value.push( curJson );
                            });
                            json[ rel.key ] = value;
                        }
                        else if ( related instanceof Backbone.Model ) {
                            json[ rel.key ] = related.toViewJSON();
                        }
                    }
                }, this);
            } else {
                json = this.toJSON();
            }
            return json;
        };

        //to prevent context menu open on mac for card multiselect using CTRL key
        function preventMenuOpen (e) {
            if (e.ctrlKey) {
                e.preventDefault();
                const clickEvent = new MouseEvent('click', { bubbles: true, ctrlKey: e.ctrlKey });
                e.target.dispatchEvent(clickEvent);
            }
        }
        document.addEventListener('contextmenu', preventMenuOpen);

        //open kanbanchi links in a same tab
        function handleKanbanchiLinks (e) {
            let target = e.target;
            function openLink() {
                const linkBoard = target.href.match(/d-\d{16}/);
                const isLinkCurrentBoard = window.location.href.includes(linkBoard);
                if (isLinkCurrentBoard && App.router.openLink(target.href)) {
                    e.preventDefault();
                }
            }
            if (target.tagName === 'A' && target.href) {
                openLink();
            } else if (target.parentElement) { // проверяем на один уровень выше
                target = target.parentElement;
                if (target.tagName === 'A' && target.href) {
                    openLink();
                }
            }
        }

        window.addEventListener('click', handleKanbanchiLinks);

        App.vent.beforeSend= function(xhr, info){
            CSRF();
            let code = App.Util.getCookie('X-CSRF-Header');
            let version = getAppVersion();
            xhr.setRequestHeader('X-CSRF-Header', code);
            if (info && (info.url.startsWith("/") || info.url.startsWith(window.location.origin))) {
                xhr.setRequestHeader('X-App-Version', version);
            }
        };

        Backbone.sync_ = Backbone.sync;
        Backbone.sync = function(method, model, options) {
            options = options || {};
            options.beforeSend = App.vent.beforeSend;
            return Backbone.sync_.call(model, method, model, options);
        };

        Array.prototype.remove = function(from, to) {
            var rest = this.slice((to || from) + 1 || this.length);
            this.length = from < 0 ? this.length + from : from;
            return this.push.apply(this, rest);
        };

        String.prototype.trunc = function(n,useWordBoundary) {
            var toLong = this.length>n,
                s_ = toLong ? this.substr(0,n-1) : this;
            s_ = useWordBoundary && toLong ? s_.substr(0,s_.lastIndexOf(' ')) : s_;
            return  toLong ? s_ + '\u2026' : s_;
        };

    } ());

})(jQuery);

if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        if (window.Settings.development) {
            navigator.serviceWorker.getRegistrations().then(function (registrations) {
                for (let registration of registrations) {
                    registration.unregister()
                }
            })
        } else {
            navigator.serviceWorker.register('/sw.js')
                .then(registration => {
                    console.log('SW registered: ', registration);
                })
                .catch(registrationError => {
                    console.log('SW registration failed: ', registrationError);
                });
        }
    });

    // чтоб заглушка не выскакивала при коротких обрывах, повесим её через таймер
    let offlineTimer = null;
    window.addEventListener('offline', () => {
        offlineTimer = setTimeout(() => {
            App.controller.mainView.renderOfflineBlocker();
            const html = document.documentElement;
            html.classList.add('offline');
        }, 1000);
    });
    window.addEventListener('online', () => {
        if (offlineTimer) {
            clearTimeout(offlineTimer);
            offlineTimer = null;
        }
        const html = document.documentElement;
        html.classList.remove('offline');
        // если приложение было открыто offline, а потом стало online, то всё перезагрузить для начала
        if (App.controller.mainView.signIn) {
            location.reload();
        }
    });
}
