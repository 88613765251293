import { getMessages } from '../../../../store/constants';

export const QUALIFICATION_FIRST_QUESTION = 'work_tool';
export const QUALIFICATION_HIDE_TIMEOUT = 1000;

export const QUALIFICATION_QUESTIONS_TITLE = getMessages().getText('qualification.questions.title');
export const QUALIFICATION_QUESTIONS_SUBTITLE = getMessages().getText('qualification.questions.subTitle');
export const QUALIFICATION_SKIPPED_TITLE = getMessages().getText('qualification.skipped.title');
export const QUALIFICATION_SKIPPED_SUBTITLE = getMessages().getText('qualification.skipped.subTitle');
export const QUALIFICATION_COMPLETED_TITLE = getMessages().getText('qualification.completed.title');
export const QUALIFICATION_COMPLETED_SUBTITLE = getMessages().getText('qualification.completed.subTitle');
export const QUALIFICATION_GET_STARTED = getMessages().getText('qualification.getStarted');
export const QUALIFICATION_BACK = getMessages().getText('qualification.back');
export const QUALIFICATION_SKIP = getMessages().getText('qualification.skip');
export const QUALIFICATION_NEXT = getMessages().getText('qualification.next');
export const QUALIFICATION_MORE_DETAILS_PROMPT = getMessages().getText('qualification.questions.moredetails.prompt');

export const SEGMENT_IDENTIFY_PREFIX = 'Q_';

export const QUALIFICATION_QUESTIONS_CALENDAR_URL = 'https://calendly.com/kanbanchi/kanbanchi-introduction?month={date}';
export const QUALIFICATION_QUESTIONS_EDU_CALENDAR_URL = 'https://calendly.com/kanbanchi/kanbanchi-introduction-for-edu?month={date}';
