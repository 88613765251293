import {
    AT_BOARD_ACTION_SET,
    AT_CARD_ACTION_SET,
    TModelsCollaboratorsAction
} from '../actions/types';
import { collaboratorsReducer } from '../../collaborators/reducers/collaboratorsReducer';
import { IModelCollaborators } from '../types';

const initialState: IModelCollaborators = {
    cards: {},
    boards: {}
};

export const modelsCollaboratorsReducer = (
    state = initialState,
    action: TModelsCollaboratorsAction
): IModelCollaborators => {
    switch (action.type) {
        case AT_CARD_ACTION_SET: {
            return {
                ...state,
                cards: {
                    ...state.cards,
                    [action.id]: collaboratorsReducer(state.cards[action.id], action.collaboratorsAction)
                }
            }
        }
        case AT_BOARD_ACTION_SET: {
            return {
                ...state,
                boards: {
                    ...state.boards,
                    [action.id]: collaboratorsReducer(state.boards[action.id], action.collaboratorsAction)
                }
            }
        }

        default:
            return state;
    }
};
