'use strict';

App.Views.CardArchiveNotification = App.Views.BaseNotification.extend({

    getActions: function() {
        return {
            action: Messages.getText('noty.card_archive'),
            actionData: {
                icon: 'archive'
            }
        };
    }
});
