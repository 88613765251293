import {
    CLASS_ASIDE_PANEL_CONTAINER_ACTIONS_BODY
} from '../view/react_components/aside_panel/asidePanel/components/AsidePanelBody/constants';
import {
    ID_ASIDE_PANEL_ACTIONS_PORTAL, ID_ASIDE_PANEL_ACTIONS_PORTAL_MOBILE
} from '../view/react_components/aside_panel/asidePanel/components/AsidePanelActionsButton/constants';
import { CLASS_ASIDE_DROP_PORTAL } from '../view/react_components/aside_panel/asidePanel/constants';

export const removeAsidePanelPortal = (
    el: HTMLElement
) => {
    const body = el.querySelector('.' + CLASS_ASIDE_PANEL_CONTAINER_ACTIONS_BODY);
    if (body) body.className = ''; // это для портала ID_ASIDE_PANEL_ACTIONS_PORTAL, если переключили из одной панели в другую
    const portalActions = el.querySelector('#' + ID_ASIDE_PANEL_ACTIONS_PORTAL);
    if (portalActions) portalActions.remove();
    const portalActionsMobile = el.querySelector('#' + ID_ASIDE_PANEL_ACTIONS_PORTAL_MOBILE);
    if (portalActionsMobile) portalActionsMobile.remove();
    const portalDrop = el.querySelector('.' + CLASS_ASIDE_DROP_PORTAL);
    if (portalDrop) portalDrop.remove();
}
