import { IApplicationState, TListId } from '../../../../types/types';
import { ICardAgingSettings } from '../../../../types/rest/ICardAging';
import { getListCardAgingEnabled } from './getListCardAgingEnabled';
import { getListMetaCardAging } from '../../selectors/getListMetaCardAging';

export const getListCardAgingSettings = (
    state: IApplicationState,
    listId: TListId
): ICardAgingSettings => {
    const listAging = getListMetaCardAging(state, listId);
    const isEnabled = getListCardAgingEnabled(state, listId);

    return listAging && isEnabled && listAging.cardAgingSettings;
}
