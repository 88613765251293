import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { authUserSetAllowedCookies } from '../../../../../rest/effects/authUser/authUserSetAllowedCookies';

export const saveAllowedCookiesUser = (
    scopes: string[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(authUserSetAllowedCookies(JSON.stringify(scopes)))
    };
    return action;
};
