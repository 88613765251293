import { createDeepEqualSelector } from '../../../../../../../helpers/memoizeHelper';
import { IApplicationState } from '../../../../../../../../../types/types';
import { getMyWorkShowBoards } from '../../../../../store/selectors/getMyWorkShowBoards';
import {
    getUserBoardActivityTimes
} from '../../../../../../../../../store/model/boardUserActivityTimes/selectors/getUserBoardActivityTimes';
import { getMyWorkShowBoardsUpdates } from '../../../../../selectors/getMyWorkShowBoardsUpdates';

type TgetBoardsUpdateCount= (
    state: IApplicationState
) => number;

export const getBoardsUpdateCount: TgetBoardsUpdateCount = createDeepEqualSelector(
    getMyWorkShowBoardsUpdates,
    getUserBoardActivityTimes,
    getMyWorkShowBoards,
    (isShow, activityTimes, showBoards) => {
        if (isShow) {
            return Object.values(activityTimes)
                .reduce((count, board) => {
                    if (!showBoards.includes(board.id)) {
                        count += board.unseenUpdatesCounter;
                    }
                    return count;
                }, 0);
        }
    }
)
