import { getBoard } from './getBoardById';
import { IApplicationState, TBoardId } from '../../../types/types';
import { TStatus } from '../../../types/model';
import { ICard } from '../card/types';
import { getList } from '../list/selectors/getList';
import { getCard } from './getCard';

export const getBoardCardsByStatuses = (
    state: IApplicationState,
    boardId: TBoardId,
    statuses: TStatus[] = [TStatus.STATUS_ACTIVE],
): ICard[] => {
    const board = getBoard(state, boardId);
    if (
        !board ||
        !board.listIds ||
        !board.listIds.length
    ) return [];

    const cards = board.listIds.reduce((arr, listId) => {
        const list = getList(state, listId);
        if (
            !list ||
            !list.cardIds ||
            !list.cardIds.length
        ) return arr;

        list.cardIds.forEach((cardId) => {
            const card = getCard(state, cardId);
            if (
                card &&
                (statuses && statuses.includes(card.status))
            ) {
                arr.push(card);
            }
        });
        return arr;
    }, []);
    return cards;
};
