import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TBoardId, TCardId } from '../../../../../../../../types/types';
import { SegmentCardEvent, SegmentCardOptions, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { SegmentCardSection } from '../../../../../../../../middlewares/segment/trackEntities/cardEvents';
import {
    getBoardIsCardDefaultProperty
} from '../../../../../../../../store/model/board/selectors/getBoardIsCardDefaultProperty';
import { EAsidePanelProperty } from '../../../../../asidePanel/components/AsidePanel/types';
import { CLASS_CARD_DETAILS } from '../../../../constants';
import {
    getCardChecklistsSorted
} from '../../../../../../../../store/model/checklists/checklists/selectors/getCardChecklistsSorted';
import { CHECKLIST_DEFAULT_ID } from '../../../../../../../../store/model/checklists/checklists/constants';
import { checkIfDefaultChecklistIsEmpty } from '../../CardChecklistsHOC/selectors/checkIfDefaultChecklistIsEmpty';

export const onClick = (
    boardId: TBoardId,
    cardId: TCardId,
    isOpened: boolean,
    ownOnClick?: () => void,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const checklists = getCardChecklistsSorted(getState(), cardId);
        const isDefaultChecklistEmpty = checkIfDefaultChecklistIsEmpty(getState(), cardId);
        const isEmpty = checklists.length === 0 || (
            checklists.length === 1 &&
            checklists[0].id === CHECKLIST_DEFAULT_ID &&
            isDefaultChecklistEmpty
        );
        const isDefaultProperty = getBoardIsCardDefaultProperty(getState(), boardId, EAsidePanelProperty.CARD_CHECKLIST);
        const isFirst = isEmpty && !isDefaultProperty && !isOpened;
        dispatch(segmentTrackAction(SegmentCardEvent.BUTTONS_PANEL_BUTTON_CLICKED, {
            name: SegmentCardOptions.TARGET,
            value: SegmentCardSection.CHECKLIST
        }, [{
            name: SegmentCardOptions.FIRST,
            value: String(isFirst)
        }]));
        if (ownOnClick) ownOnClick();
        if (isFirst) {
            setTimeout(() => {
                const button = document.querySelector('.' + CLASS_CARD_DETAILS + '-checklist__add-button') as HTMLButtonElement;
                if (button) {
                    button.focus();
                    button.click();
                }
            }, 0);
        }
    };
    return action;
};
