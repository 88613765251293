import { TColorId, TCardId, IApplicationState, EMPTY_ARRAY } from './../../../types/types';
import { getCard } from './getCard';

export const getCardColorIds = (
    state: IApplicationState,
    cardId: TCardId,
): TColorId[] => {
    const card = getCard(state, cardId);
    if (
        !card ||
        !card.colorIds ||
        !card.colorIds.length
    ) return EMPTY_ARRAY;

    return card.colorIds;
};
