import * as React from 'react';
import { ITimeTrackerActionsDialogProps } from './types';
import { Icon, Modal } from 'kui';
import { MODAL_PROGRESS } from '../../wording';
import './TimeTrackerActionsDialog.scss';

export function TimeTrackerActionsDialog ({
    boardName,
    cardName,
    onClose,
    onDiscard,
    onOpen
}: ITimeTrackerActionsDialogProps) {
    const classNameBoard = 'timetracker-dialog__board';
    const classNameCard = 'timetracker-dialog__card';

    return (
        <Modal
            className={'time-dialog'}
            title={'What should we do?'}
            variant={'actions'}
            onClose={onClose}
            buttons={[
                {
                    text: 'Discard timer',
                    onClick: onDiscard
                },
                {
                    text: 'Open tracking card',
                    isPrimary: true,
                    onClick: onOpen
                }
            ]}
        >
            {MODAL_PROGRESS}
            <div className={classNameBoard}>
                <Icon xlink={'board'} size={16} />
                {boardName}
            </div>
            <div className={classNameCard}>
                <Icon xlink={'card'} size={16} />
                {cardName}
            </div>
        </Modal>
    );
}
