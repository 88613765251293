import { TListsAction } from '../actions/types';
import { AT_DELETE, AT_LIST_ACTION_SET, AT_LISTS_UPDATE, AT_SET } from '../actions/constants';
import { listReducer } from '../../list/reducers/list';
import * as deepFreeze from 'deep-freeze';
import { ILists } from '../types';
import { listUpdate } from '../../list/actions/listUpdate';

const initialState: ILists = {};

const _listsReducer = (
    state = initialState,
    action: TListsAction
): ILists  => {
    switch (action.type) {
        case AT_LIST_ACTION_SET:{
            const {listId, listAction} = action;
            return {
                ...state,
                [listId]: listReducer(state[listId], listAction)
            }
        }
        case AT_LISTS_UPDATE: {
            const { lists } = action;
            const stateUpdated: ILists = {};
            for (let listId in lists) {
                stateUpdated[listId] = listReducer(state[listId], listUpdate(lists[listId]));
            }
            return {
                ...state,
                ...stateUpdated
            }
        }
        case AT_SET: {
            return {
                ...state,
                ...action.lists
            }
        }
        case AT_DELETE: {
            const stateNew = {...state};
            const { lists } = action;
            if (lists) {
                for (let listId in lists) {
                    delete stateNew[listId];
                }
            }
            return {
                ...stateNew
            };
        }
        default: {
            return state;
        }
    }
};

export const listsReducer = (
    state = initialState,
    action: TListsAction
): ILists  => {
    // @ts-ignore
    if(window.Settings.development && false) {
        // @ts-ignore
        return deepFreeze(_listsReducer(state, action));
    } else {
        return _listsReducer(state, action);
    }
};
