import { changeViewType } from 'app/view/react_components/typeSwitcher/effects/changeViewType';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../../types/types';
import { EViewTypes } from 'app/const';

export const onClickSwitchToKanban = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(changeViewType(EViewTypes.KANBAN_VIEW_TYPE));
    };
    return action;
};
