import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../../types/types';
import { onClick } from './events/onClick';
import { IDatesPanelButtonHOCProps } from './types';
import { DatesPanelButton } from '../../components/DatesPanelButton/DatesPanelButton';
import { IDatesPanelButtonEvents, IDatesPanelButtonFields } from '../../components/DatesPanelButton/types';

const mapStateToProps = (
    state: IApplicationState,
    { }: IDatesPanelButtonHOCProps
): IDatesPanelButtonFields => {
    return {
        tooltip: null
    }
};

const mapDispatchToProps = (
    dispatch: any,
    { onClick: ownOnClick }: IDatesPanelButtonHOCProps
): IDatesPanelButtonEvents => {
    return {
        onClick: () => dispatch(onClick(ownOnClick))
    }
};

export const DatesPanelButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(DatesPanelButton);

DatesPanelButtonHOC.displayName = 'DatesPanelButtonHOC';
