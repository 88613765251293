import { ICardsDeleteAction } from './types';
import { AT_CARDS_DELETE } from './constants';
import { ICards } from '../types';

export const cardsDeleteAction = (
    cards: ICards
): ICardsDeleteAction => ({
    type: AT_CARDS_DELETE,
    cards,
});
