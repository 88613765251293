import * as React from 'react';
import { IFeedbackContainerOwnProps } from './containers/feedbackContainer/types';
import { LazyWrapper } from '../../lazy/lazy_wrapper';
import { AsidePanelContext } from '../../aside_panel/asidePanel/components/AsidePanel/constants';
import { EFeedbackFormMode } from './constants';
import { LazyLoader } from './components/LazyLoader/LazyLoader';
import { snackbarErrorReload } from '../../snackbarsStack';
import { segmentTrackAction } from '../../../../middlewares/segment';
import { SegmentErrorEvent, SegmentErrorOptions } from '../../../../middlewares/segment/trackEntities/errorEvents';
import { ISegmentEventOption } from '../../../../middlewares/segment/trackEntities/types';
import store from 'app/store/configureStore';

export const FeedbackContainerHocLazy = (props: IFeedbackContainerOwnProps) => {
    const [Hoc, setHoc] = React.useState(null);
    const {mode} = props;
    const context = React.useContext(AsidePanelContext);
    const onLoaded = context && context.onLoaded;

    const FeedbackContainerHocLoader = React.useCallback(() => {
        const ImportElement = React.lazy(() => {
            return import(/* webpackChunkName: "feedback" */ './store/reducers/feedBackFormReducer')
                .then((module) => {
                    store.reducerManager.add('feedBackForm', module.feedBackFormReducer);
                    store.dispatch({type: 'async-init'}); // fake action to init new reducers
                })
                .then(() => {
                    return Promise.all([
                        import(/* webpackChunkName: "feedback" */ './effects/createFeedBackSession'),
                    ])
                })
                .then(([
                    {createFeedBackSession},
                ]) => {
                    store.dispatch(createFeedBackSession() as any)
                })
                .then(() => import(/* webpackChunkName: "feedback" */'./hocs/feedbackFormContainerHoc/feedbackFormContainerHoc'))
                .then((module: any) => {
                    setTimeout(()=>{ // isLoading иногда застревал без таймаута
                        if (onLoaded) onLoaded('feedbackForm', true);
                    }, 100);
                    setHoc(module.FeedbackContainerHoc); // import почему-то вызывался постоянно, поэтому прервал его через useState
                    return {default: module.FeedbackContainerHoc};
                })
                .catch(() => {
                    // @ts-ignore
                    store.dispatch(snackbarErrorReload());
                    const options: ISegmentEventOption = {
                        name: SegmentErrorOptions.SOURCE,
                        value: 'feedback'
                    };
                    store.dispatch(segmentTrackAction(SegmentErrorEvent.LAZY_IMPORT_ERROR, options));
                    return {default: () =>(<div/>)}
                })
        });
        return <ImportElement/>;
    }, []);

    React.useEffect(() => {
        setTimeout(()=>{ // isLoading иногда застревал без таймаута
            if (onLoaded && !Hoc) onLoaded('feedbackForm', false);
        }, 50);
    }, []);

    return (
        <LazyWrapper
            loader={mode === EFeedbackFormMode.INLINE ? <div/> : <LazyLoader/>}
        >
            {!!Hoc
                ? <Hoc {...props} />
                : <FeedbackContainerHocLoader/>
            }
        </LazyWrapper>
    );
};
