import { root } from './../../../../../../store/constants';
import { Sortable, SortableStopEvent, MirrorOptions, SortableStartEvent, SortableEvent } from '@shopify/draggable';

export interface IGetSortableProps {
    container: string;
    draggable: string;
    mirror?: MirrorOptions;
    onSort: (id: string, ids: string[]) => void;
    sortableInit: () => void;
};

/**
 * Sortable часто застревает
 * если находим два сорса, удаляем всё что насоздавал Sortable, и перезапускаем его
 */

function deleteStacked(
    event: SortableEvent,
    sortableProps: IGetSortableProps
) {
    requestAnimationFrame(() => {
        const sourceContainer = event.dragEvent.sourceContainer;
        const sources = sourceContainer.querySelectorAll('.draggable-source--is-dragging');
        const mirrors = sourceContainer.querySelectorAll('.draggable-mirror');
        if (
            sources.length < 2 &&
            mirrors.length < 2
        ) return;

        for (let i = 0; i < sources.length; i++) { sources[i].remove(); }
        for (let i = 0; i < mirrors.length; i++) { mirrors[i].remove(); }

        const original = event.dragEvent.sourceContainer.querySelector('.draggable--original') as HTMLElement;
        if (original) {
            original.classList.remove('draggable--original');
            original.style.removeProperty('display'); // original спрятан, нужно показать
        }

        sourceContainer.classList.remove('draggable-container--is-dragging');

        const draggableBody = document.querySelector('.draggable--is-dragging') as HTMLElement;
        if (draggableBody) {
            draggableBody.classList.remove('draggable--is-dragging');
        }
        sortableProps.sortableInit();
    });
};

function onSortingStart(
    event: SortableStartEvent,
    sortableProps: IGetSortableProps
) {
    deleteStacked(event, sortableProps);
    const kuiPortal = document.getElementById('kui-portal');
    if (kuiPortal) kuiPortal.innerHTML = ''; // clear tooltips
    if (
        root.getSelection &&
        root.getSelection().empty
    ) {
        root.getSelection().empty();
    }
};

function onSortingStop(
    event: SortableStopEvent,
    sortableElements: Sortable,
    sortableProps: IGetSortableProps
) {
    deleteStacked(event, sortableProps);
    if (event.newIndex === event.oldIndex) return;

    const container = event.dragEvent.sourceContainer;
    const elements = sortableElements.getDraggableElementsForContainer(container);
    const newOrders = [];
    for (let i = 0; i < elements.length; i++) {
        newOrders.push(elements[i].dataset.id);
    }
    sortableProps.onSort(event.dragEvent.originalSource.dataset.id, newOrders);
};

export function getSortable(
    sortableProps: IGetSortableProps
): Sortable {
    const containerSelector = document.querySelectorAll('.' + sortableProps.container);
    const sortableElements = new Sortable(
        containerSelector, {
            delay: 300,
            ...sortableProps,
            draggable: '.' + sortableProps.draggable
        }
    );
    sortableElements.on('sortable:start',
        (event: SortableStartEvent) => onSortingStart(event, sortableProps));
    sortableElements.on('sortable:stop',
        (event: SortableStopEvent) => onSortingStop(event, sortableElements, sortableProps));
    return sortableElements;
};
