import {Dispatch, ThunkAction} from 'app/types/actions';
import {IGetState} from 'app/types/types';
import {SegmentCardEvent, segmentTrackAction} from 'app/middlewares/segment';

export const onCancelWithoutChanges = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.DESCRIPTION_CANCEL_WITHOUT_CHANGES_CLICKED));
    };
    return action;
};
