import { IAuthUser } from '../../../../../store/model/authUser/types';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { IGetState } from '../../../../../types/types';
import { getAuthUser } from '../../../../../store/model/authUser/selectors/getAuthUser';
import { getCommentModel } from '../../../aside_panel/cardDetails/CommentsSection/helpers/commentModelHelper';
import { IComment } from '../../../../../store/model/card/types/IComment';
import { createComment } from '../../../aside_panel/cardDetails/CommentsSection/hocs/CommentsInputHOC/effects/createComment';
import { TimeTrackerHelper } from '../helpers/TimeTrackerHelper';
import { getBoardWorkHoursInDay } from '../../../../../store/model/selectors/getBoardWorkHoursInDay';

export const createTimeTrackerComment = (
    description: string,
    seconds: number,
    cardId: number,
    boardId: number,
    user: IAuthUser = null
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (seconds === 0) {
            return;
        }
        const state = getState();
        if (!user) {
            user = getAuthUser(state);
        }
        const workHoursInDay = getBoardWorkHoursInDay(state, boardId);
        const text = TimeTrackerHelper.getCommentMarkDown(seconds, description, workHoursInDay);
        let comment = getCommentModel(state, boardId, text) as IComment;
        comment.timeTracker = true;
        comment.cardId = cardId;
        dispatch(createComment(boardId, comment));
    };
    return action;
};
