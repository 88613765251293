import { SegmentCardEvent } from '../../../../../../../middlewares/segment/trackEntities/cardEvents';
import {
    segmentTrackAction
} from '../../../../../../../middlewares/segment/actions/segmentTrackAction/segmentTrackAction';
import { Dispatch, ThunkAction } from '../../../../../../../types/actions';

export const onTabTimingActive = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.TAB_TIMING));
    };
    return action;
};
