import { Dispatch, ThunkAction } from '../../../types/actions';
import { IAuthUser } from '../../../store/model/authUser/types';
import { authUserPatch } from './api/helper/authUserPatch';
import { TUserId } from '../../../types/rest/IRestAuthUser';
import { IGetState } from '../../../types/types';
import { getAuthUser } from '../../../store/model/authUser/selectors/getAuthUser';

export const authUserSetAllowedCookies = (
    allowedCookies: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const authUser = getAuthUser(getState());
        const userPatch: IAuthUser = {
            id: authUser.id,
            allowedCookies
        };
        return dispatch(authUserPatch(userPatch));
    };
    return action;
};
