import { IGetState, TBoardId } from '../../../types/types';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { boardSetExportSettings } from './boardSetExportSettings';
import { getBoardExportSettings } from '../../../store/model/board/selectors/getBoardExportSettings';
import { IBoardMetaExportSettingsColumn } from '../../../types/rest/IRestUserBoardMeta';

export const boardSetExportSettingsColumns = (
    boardId: TBoardId,
    columns: IBoardMetaExportSettingsColumn[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const settings = getBoardExportSettings(getState(), boardId);
        return dispatch(boardSetExportSettings(boardId, {
            ...settings,
            columns
        }))
    };
    return action;
}
