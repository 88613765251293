import * as React from 'react';
import { IDriveDocsProps } from './types';
import { CARD_CLASS } from '../../../../constants';
import './_DriveDocs.scss';
import { DriveDocHOC } from '../../hocs/DriveDocHOC/DriveDocHOC';

export function DriveDocs({
    mainDriveDocSet,
}: IDriveDocsProps) {
    const className = CARD_CLASS + '__drive-docs';

    return (
        <div className={className}>
            {mainDriveDocSet.map((driveDoc) => (<DriveDocHOC driveDoc={driveDoc} key={driveDoc.id}/>))}
        </div>
    );
}
