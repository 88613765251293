import { Dispatch } from '../../../../../types/actions';
import { SnackbarId } from '../../store/types';
import { deleteSnackbar as deleteSnackbarAction } from '../../store/actions/deleteSnackbar';

export const deleteSnackbar = (
    snackbarId: SnackbarId
) => {
    return (dispatch: Dispatch) => {
        dispatch(deleteSnackbarAction(snackbarId));
    }
};
