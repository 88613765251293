import store from 'app/store/configureStore';
import * as React from 'react';
import { createRoot, Root } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import { LazyWrapper } from '../lazy/lazy_wrapper';

export class DashboardWidgets {
    root: Root;
    store: Store;

    constructor (el: HTMLElement, store: Store) {
        this.root = createRoot(el!);
        this.store = store;
        this.render();
    }

    initATMStore () {
        return import(/* webpackChunkName: "myWork" */ '../main/myWorkView/store/reducers/myWorkReducer')
        .then((module) => {
            store.reducerManager.add('assignedToMe', module.myWorkReducer);
            store.dispatch({type: 'async-init'}); // fake action to init new reducers
        })
        .catch(e => console.error(e))
    }

    render () {
        const MyWork = React.lazy(() =>
            this.initATMStore()
                .then(() => import(/* webpackChunkName: "assignedToMe" */ '../main/myWorkView/myWork/hocs/MyWorkHOC/MyWorkHOC'))
                .then(module => ({default: module.MyWorkHOC}) as any)
                .catch(e => console.error(e))
        );

        this.root.render(
            <LazyWrapper>
                <Provider store={this.store}>
                    <MyWork />
                </Provider>
            </LazyWrapper>
        );
    }

    remove () {
        this.root.unmount();
    }
}
