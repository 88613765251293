import * as React from 'react';
import './_DateItem.scss';
import { IDateItemProps } from './types';
import { CLASS_DATEPICKER } from '../constants';

export const DateItem = ({
    isFocused,
    value,
}: IDateItemProps) => {
    const className = CLASS_DATEPICKER + '__date-item';

    return (
        <div
            className={`
                ${className}
                ${isFocused ? className + '--focused' : ''}
            `}
        >
            {value}
        </div>
    );
}
