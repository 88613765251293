import { cardTogglePinnedChecklist } from '../../../../../../../../rest/effects/card/card/cardTogglePinnedChecklist';
import { getCardChecklistIsPinned } from '../../../../../../../../store/model/selectors/getCardChecklistIsPinned';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId, TChecklistId } from '../../../../../../../../types/types';
import { deleteChecklist } from '../../effects/deleteChecklist';
import {
    getCardChecklists
} from '../../../../../../../../store/model/checklists/checklists/selectors/getCardChecklists';
import { checklistAddStore } from '../../../../../../../../store/model/checklists/actionCreators/checklistAdd';
import { CHECKLIST_DEFAULT } from '../../../../../../../../store/model/checklists/checklists/constants';
import {
    getAuthUserAllowChecklistCount
} from '../../../../../../../../store/model/authUser/selectors/getAuthUserAllowChecklistCount';
import { root } from '../../../../../../../../store/constants';

export const onDelete = (
    cardId: TCardId,
    checklistId: TChecklistId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const maxCount = getAuthUserAllowChecklistCount(state);
        const count = Object.keys(getCardChecklists(state, cardId)).length;
        if (
            root.App.controller.allowCounterSnackbarHelper.isShowSnackbar('allowChecklistCount') &&
            count > maxCount
        ) {
            return Promise.resolve(false);
        } else {
            return dispatch(deleteChecklist(cardId, checklistId))
                .then(() => {
                    const checklists = getCardChecklists(getState(), cardId);
                    if (!checklists || !Object.keys(checklists).length) {
                        dispatch(checklistAddStore({
                            ...CHECKLIST_DEFAULT,
                            cardId,
                        }));
                    }
                    const isPinned = getCardChecklistIsPinned(getState(), cardId, checklistId);
                    if (isPinned) {
                        dispatch(cardTogglePinnedChecklist(cardId, checklistId));
                    }
                    return Promise.resolve(true);
                });
        }
    };
    return action;
};
