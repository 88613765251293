import { connect } from 'react-redux';
import { Updates } from '../../components/Updates/Updates';
import { IApplicationState } from '../../../../../types/types';
import { IUpdatesEvents, IUpdatesFields } from '../../components/Updates/types';
import { onOpen } from './events/onOpen';
import { onClose } from './events/onClose';
import { onClickFeedback } from './events/onClickFeedback';
import { getUpdates } from '../../updates';
import { onShowPopup } from './events/onShowPopup';
import { onShowTips } from './events/onShowTips';
import { getUserDateFormat } from 'app/store/model/authUser/selectors/getUserDateFormat';

const mapStateToProps = (
    state: IApplicationState
): IUpdatesFields => {

    return {
        dateFormat: getUserDateFormat(state),
        updates: getUpdates(),
    }
}

const mapDispatchToProps = (
    dispatch: any,
): IUpdatesEvents => ({
    onClickFeedback: () => dispatch(onClickFeedback()),
    onOpen: () => dispatch(onOpen()),
    onClose: () => dispatch(onClose()),
    onShowPopup: (slides) => dispatch(onShowPopup(slides)),
    onShowTips: (slides) => dispatch(onShowTips(slides)),
});

export const UpdatesHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(Updates);
UpdatesHOC.displayName = 'UpdatesHOC';
