import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId, TChecklistId, TChecklistItemId } from '../../../../../../../../types/types';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { checklistItemUpdate } from '../../../../../../../../rest/effects/card/checklist/checklistItemUpdate';
import { getChecklistItem } from '../../../../../../../../store/model/checklists/checklist/selectors/getChecklistItem';
import { getCard } from '../../../../../../../../store/model/selectors/getCard';
import { cardSetStartDateWithEpics } from '../../../../../../../../rest/effects/card/card/cardSetStartDateWithEpics';
import {
    getBoardMetaDefaultStartDateTime
} from '../../../../../../../../store/model/selectors/getBoardMetaDefaultStartDateTime';
import { getBoardIdByCardId } from '../../../../../../../../store/model/selectors/getBoardIdByCardId';

export const onChangeStartDate = (
    cardId: TCardId,
    checklistId: TChecklistId,
    itemId: TChecklistItemId,
    date: number,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (date === 0) {
            date = null
        }
        if (date) {
            const state = getState();
            const boardId = getBoardIdByCardId(state, cardId);
            const startHours = getBoardMetaDefaultStartDateTime(state, boardId).getHours();
            date += startHours * 3600;
            const { startDate = null } = getChecklistItem(state, cardId, checklistId, itemId) || {};
            if (startDate) {
                dispatch(segmentTrackAction(SegmentCardEvent.CHECKLIST_ITEM_START_DATE_CHANGED));
            } else {
                dispatch(segmentTrackAction(SegmentCardEvent.CHECKLIST_ITEM_START_DATE_ADDED));
            }
        } else {
            dispatch(segmentTrackAction(SegmentCardEvent.CHECKLIST_ITEM_START_DATE_REMOVED));
        }
        return dispatch(checklistItemUpdate(cardId, checklistId, itemId, {
            startDate: date,
        }))
            .then(() => {
                if (date) {
                    const {
                        dueDate = null,
                        isMilestone = false,
                        startDate = null,
                    } = getCard(getState(), cardId) || {};
                    if (
                        isMilestone ||
                        dueDate && dueDate <= date ||
                        startDate && startDate <= date
                    ) return;

                    return dispatch(cardSetStartDateWithEpics(cardId, date));
                }
            });
    };
    return action;
};
