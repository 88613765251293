import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from '../../../store/configureStore';
import { PermissionsFAQModalHOC } from 'app/view/react_components/dialogs/permissionsFAQModal/hocs/PermissionsFAQModalHOC/PermissionsFAQModalHOC';

export class PermissionsFAQ {
    el: HTMLElement;

    constructor () {
        if (this.el) this.remove();
        this.el = document.createElement('div');
        this.el.className = 'kanbanchi-permissions-faq-placeholder';
        document.body.append(this.el);
        this.render();
    }

    render () {
        ReactDOM.render(
            <Provider store={store}>
                <PermissionsFAQModalHOC />
            </Provider>
            , this.el);
    }

    remove () {
        if (!this.el) return;

        ReactDOM.unmountComponentAtNode(this.el);
        this.el.remove();
        this.el = null;
    }
}
