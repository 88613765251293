import { root } from '../../../../../../../store/constants';
import { IGetState } from '../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../types/actions';

export const onClickContact = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        root.App.controller.showHelpPopup();
    };
    return action;
};
