import { connect } from 'react-redux';
import { EMPTY_ARRAY, IApplicationState, TCardId } from '../../../../../../../types/types';
import { DependenciesSection } from '../../components/DependenciesSection/DependenciesSection';
import { IDependenciesHOCOwnProps } from './types';
import {
    IDependenciesSectionDispatchProps,
    IDependenciesSectionStateProps,
} from '../../components/DependenciesSection/types';
import { onDeleteDependency } from './events/onDeleteDependency';
import { getSectionType } from '../../../selectors/getSectionType';
import { EDependencyNames } from '../../constans';
import { onDependencyTypeChanged } from './events/onDependencyTypeChanged';
import { getCardPredecessors } from '../../selectors/getPredecessors';
import { getCardSuccessors } from '../../selectors/getSuccessors';
import { onClickDisallow } from './events/onClickDisallow';
import { ITypeItem } from '../../components/Dependency/types';
import { onDependencyCardLink } from './events/onDependencyCardLink';
import { onAddClicked } from './events/onAddClicked';
import { getCardFullName } from '../../../../../../../store/model/card/selectors/getCardFullName';
import { stripHtml } from '../../../../../base/helpers/stripHtmlHelper';
import { markdown } from '../../../../../../../helper/markdownHelper';
import {
    getBoardIsCardDefaultProperty
} from '../../../../../../../store/model/board/selectors/getBoardIsCardDefaultProperty';
import { EAsidePanelProperty } from '../../../../asidePanel/components/AsidePanel/types';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { getShowProFeaturesTrial } from '../../../../../../../store/model/authUser/selectors/getShowProFeaturesTrial';
import { getIsUserBasicPlanOnly } from '../../../../../../../store/model/authUser/selectors/getIsUserBasicPlanOnly';
import { isEqual } from 'underscore';

// const analyze = new Analyze('DependenciesSectionHOC');
// 10 раз открыл-закрыл карту
// no reselect  average: 0.273 ms, calls: 120, total: 32.800 ms
// cache        average: 0.277 ms, calls: 120, total: 33.200 ms

const mapStateToProps = () => {
    interface ICaheProps extends IDependenciesSectionStateProps{};
    let cache: ICaheProps = null;
    const getCache = (
        value: ICaheProps
    ): ICaheProps => {
        if (isEqual(value, cache)) {
            return cache;
        }
        cache = value;
        return value;
    };

    return (
        state: IApplicationState,
        ownProps: IDependenciesHOCOwnProps
    ): IDependenciesSectionStateProps => {
        // analyze.start();
        let {boardId, cardId, sectionType: sectionTypeOwn} = ownProps;
        const typesList = Object.entries(EDependencyNames).map(([key, value]): ITypeItem => {
            return {
                key,
                value
            }
        });
        const cardFullName = getCardFullName(state, cardId);
        const predecessors = getCardPredecessors(state, cardId, boardId);
        const successors = getCardSuccessors(state, cardId, boardId);
        const sectionType = getSectionType(state, boardId, cardId, sectionTypeOwn, 'allowDependencies');

        const ret = getCache({
            boardId,
            cardId,
            cardName: stripHtml(markdown(cardFullName)),
            predecessors: predecessors && predecessors.length
                ? predecessors
                : sectionType !== ESettingsSectionType.READONLY
                    ? EMPTY_ARRAY
                    :  null,
            sectionType,
            isBasicFeature: getIsUserBasicPlanOnly(state),
            isDefaultProperty: getBoardIsCardDefaultProperty(state, boardId, EAsidePanelProperty.CARD_DEPENDENCIES),
            isProFeature: getShowProFeaturesTrial(state),
            successors: successors && successors.length
                ? successors
                : sectionType !== ESettingsSectionType.READONLY
                    ? EMPTY_ARRAY
                    :  null,
            typesList,
        });
        // analyze.finish();
        return ret;
    };
};

const mapDispatchToProps = (
    dispatch: any,
    { boardId }: IDependenciesHOCOwnProps
): IDependenciesSectionDispatchProps => {
    return {
        onClickDisallow: () => dispatch(onClickDisallow()),
        onDeleteDependence: (dependencyId: number) => dispatch(onDeleteDependency(dependencyId)),
        onDependencyTypeChanged: (dependencyId, type) => dispatch(onDependencyTypeChanged(dependencyId, type)),
        onDependencyCardLink: ( dependencyCardId: TCardId) => dispatch(onDependencyCardLink(dependencyCardId, boardId)),
        onAddClicked: () => dispatch(onAddClicked(boardId))
    };
};

export const DependenciesSectionHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(DependenciesSection);

DependenciesSectionHOC.displayName = 'DependenciesSectionHOC';
