import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { segmentTrackAction, SegmentUserEvent } from '../../../../../../../middlewares/segment';
import { ActionCreator } from 'redux';
import { IGetState } from '../../../../../../../types/types';
import { isSettingsOpenedSelector } from '../../../selectors/isSettingsOpenedSelector';
import { settingsOpenSet } from '../../../store/actions/settingsOpenSet';

export const onCookieSettingsClick: ActionCreator<ThunkAction> = () => {
    const action = (dispatch: Dispatch, getState: IGetState) => {
        const state = getState();
        const isOpened = isSettingsOpenedSelector(state);
        dispatch(settingsOpenSet(!isOpened));
        if(!isOpened) {
            dispatch(segmentTrackAction(SegmentUserEvent.COOKIE_SETTINGS));
        }
    };
    return action;
};
