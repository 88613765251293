import { REST_COMMENTS_EDIT } from '../../../../constants';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { fetchHandler } from '../../../../../util/fetchHandler';
import { Util } from '../../../../../util/util';
import { getRestHeadersDelete } from '../../../../helpers/getRestHeadersHelper';
import { IComment } from '../../../../../store/model/card/types/IComment';

export const deleteRest = (
    comment: IComment
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        const cardId = comment.cardId;
        return fetchHandler(
            Util.getApiUrl(REST_COMMENTS_EDIT + comment.id), {
                ...getRestHeadersDelete()
            }
        );
    };
    return action;
};
