import { onShareClicked } from './events/onShareClicked';
import { IApplicationState } from '../../../../../../../../types/types';
import { TopBarSharing } from '../../../../components/TopBarSharing/TopBarSharing';
import { ITopBarSharingEvents, ITopBarSharingFields } from '../../../../components/TopBarSharing/types';
import { connect } from 'react-redux';
import { getActiveBoardSharingType } from '../../../../../../../../store/model/selectors/getActiveBoardSharingType';
import { ITopBarSharingHOCProps } from './types';
import { onHintClose } from './events/onHintClose';
import { getIsShareHintShow } from '../../../../store/selectors/getIsShareHintShow';
import { onHintDontShow } from './events/onHintDontShow';

const mapStateToProps = (
    state: IApplicationState,
    props: ITopBarSharingHOCProps,
): ITopBarSharingFields => {
    const { boardId } = props;
    return {
        isShareHintShow: getIsShareHintShow(state, boardId),
        sharingType: getActiveBoardSharingType(state),
    };
};

const mapDispatchToProps = (
    dispatch: any,
    props: ITopBarSharingHOCProps,
): ITopBarSharingEvents => {
    const { boardId } = props;
    return {
        onClick: () => dispatch(onShareClicked(boardId)),
        onHintClose: () => dispatch(onHintClose(boardId)),
        onHintDontShow: () => dispatch(onHintDontShow(boardId)),
    }
};

export const TopBarSharingHOC = connect(mapStateToProps, mapDispatchToProps)(TopBarSharing);
TopBarSharingHOC.displayName = 'TopBarSharingHOC';
