import { getUserMetaMyWorkColumns } from '../../../../../selectors/getUserMetaMyWorkColumns';
import { updateMyWorkMeta } from '../../../../../../../../../rest/effects/authUser/myWork/updateMyWorkMeta';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../../types/types';

export const onToggle = (
     column: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const columns = getUserMetaMyWorkColumns(getState());
        const idx = columns.findIndex(item => item.title === column);
        columns[idx].isHidden = !columns[idx].isHidden;
        dispatch(updateMyWorkMeta({ columns }));
    };
    return action;
};
