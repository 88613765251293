import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TBoardId, TCardId } from '../../../../../../../../types/types';
import { root } from '../../../../../../../../store/constants';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';

export const onOpenDialog = (
    cardId: TCardId,
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.ADD_RELATED_CARDS_BUTTON));
        root.App.controller.showRelatedCardsPanel(cardId, boardId);
    };
    return action;
};
