import { IGetState, TCardId, TChecklistId, TChecklistItemId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { convertChecklistItemToSubcard } from '../effects/convertChecklistItemToSubcard';
import { EConvertToSubcardWorkStatus } from '../../../components/CardChecklistItems/types';

export const onConvertToSubcard = (
    cardId: TCardId,
    checklistId: TChecklistId,
    itemId: TChecklistItemId,
    onWork: (status: EConvertToSubcardWorkStatus) => void
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(convertChecklistItemToSubcard(cardId, checklistId, itemId, onWork));
    };
    return action;
};
