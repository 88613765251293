import * as React from 'react';
import './_CardChecklistItemActions.scss';
import { ICardChecklistItemActionsProps } from './types';
import { EAsidePanelType } from '../../../../asidePanel/components/AsidePanel/types';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { EConvertToSubcardWorkStatus } from '../CardChecklistItems/types';
import { CardChecklistEstimate } from '../CardChecklistEstimate/CardChecklistEstimate';
import { CLASS_CARD_DETAILS } from '../../../constants';
import { CardChecklistWeight } from '../CardChecklistWeight/CardChecklistWeight';
import { CardChecklistAssignUserSelect } from '../CardChecklistAssignUserSelect/CardChecklistAssignUserSelect';
import { CardChecklistItemActionButton } from '../CardChecklistItemActionButton/CardChecklistItemActionButton';
import { getAsidePanelTooltip } from '../../../../asidePanel/helpers/getAsidePanelTooltip';
import { convertTimeNumberToString } from '../../../helpers/convertTimeNumberToString';
import { UsersTooltip } from '../../../../../base/components/UsersTooltip/UsersTooltip';
import {
    CARD_CHECKLIST_ASSIGN_USER,
    CARD_CHECKLIST_CONVERT_TO_SUBCARD,
    CARD_CHECKLIST_CONVERT_TO_SUBCARD_MILESTONE,
    CARD_CHECKLIST_ESTIMATE,
    CARD_CHECKLIST_WEIGHT,
    CHECKLIST_DELETE_BUTTON_TOOLTIP_TEXT
} from './constants';
import { CardChecklistDateDatepicker } from '../CardChecklistDateDatepicker/CardChecklistDateDatepicker';
import { CARD_DUE_DATE, CARD_START_DATE } from '../../../DatesSection/components/CardDatesSection/constants';
import { Util } from '../../../../../../../util/util';
import { Icon, Tooltip } from 'kui';
import { EDateType } from '../../../../../../../const';
import { getParentsClasses } from '../../../../../helpers/getParentsClasses';
import { CLASS_DATEPICKER } from '../../../../../base/components/Datepicker/components/constants';
import { ActionModal } from '../../../../../base/components/ActionModal/ActionModal';
import {
    CARD_SECTION_CHECKLISTS_MODAL_CANCEL,
    CARD_SECTION_CHECKLISTS_MODAL_REMOVE
} from '../CardChecklists/constants';
import {
    BasicUpgradeButtonHOC
} from '../../../../../base/components/BasicUpgradeButton/hocs/BasicUpgradeButtonHOC/BasicUpgradeButtonHOC';
import { getMessages } from '../../../../../../../store/constants';
import { AnalyzeRender } from '../../../../../helpers/memoizeHelper';

//@ts-ignore
const analyzeRender = window.Settings.development ? new AnalyzeRender(`CardChecklistItemActions`) : null;

export function CardChecklistItemActions ({
    aloudCount,
    availableAssignees,
    authUserPermissionId,
    boardUsers,
    isEditing,
    isMilestone,
    isShowWeights,
    item,
    sectionType,
    workHoursInDay,
    onAssignUserClick,
    onChangeAssignees,
    onChangeEstimate,
    onChangeStartDate,
    onChangeDueDate,
    onClickEstimate,
    onClickStartDate,
    onClickDueDate,
    onClickWeight,
    onClose,
    onConvertToSubcard,
    onDelete,
    onChangeWeight,
    setLoading,
    setDropdownOpen
}: ICardChecklistItemActionsProps) {
    const { type } = React.useContext(AsidePanelContext);
    const { id, checked, startDate, dueDate, estimate, weight, assignees, name } = item;

    if (analyzeRender) analyzeRender.call(`CardChecklistItemActions ${id}`);

    const isTemplate = type === EAsidePanelType.CARD_TEMPLATE; // в темплэйте нельзя конверт в сабкарту
    const isReadonly = sectionType === ESettingsSectionType.READONLY;

    const estimateStr = !estimate ? null : convertTimeNumberToString(estimate, false, workHoursInDay);

    const isItemOverdue = (
        !!dueDate &&
        !checked &&
        Date.now() > dueDate * 1000
    );

    const className = CLASS_CARD_DETAILS + '-checklist-item__actions';
    const classIndicator = className + '-indicator';

    const [openedProperty, setOpenedProperty] = React.useState(null);
    const [isDatesOpen, setDatesOpen] = React.useState(null);
    const [isAssignOpen, setAssignOpen] = React.useState(null);
    const [isEstimateOpen, setEstimateOpen] = React.useState(null);
    const [isWeightOpen, setWeightOpen] = React.useState(null);
    const [newEstimate, setNewEstimate] = React.useState(null);
    const [isModalOpened, setModalOpened] = React.useState(null);
    const onConvertToSubcardHandler = () => {
        if (isMilestone) return;
        onConvertToSubcard((status: EConvertToSubcardWorkStatus) => {
            setLoading(status === EConvertToSubcardWorkStatus.STARTED);
        });
    };

    const onCloseModal = () => {
        onClose();
        setModalOpened(null);
    };

    const onDatesClick = (e: React.SyntheticEvent) => {
        const classDates = className + '-dates--' + id;
        const parentClasses = getParentsClasses(e.target as HTMLElement, [
            classDates
        ]);
        if (parentClasses.includes(classIndicator + '--start')) {
            if (openedProperty === EDateType.START_DATE) {
                setDatesOpen(false);
            } else {
                setOpenedProperty(EDateType.START_DATE);
                setTimeout(() => {
                    setDatesOpen(true);
                }, 120);
            }
        } else if (parentClasses.includes(classIndicator + '--due')) {
            if (openedProperty === EDateType.DUE_DATE) {
                setDatesOpen(false);
            } else {
                setOpenedProperty(EDateType.DUE_DATE);
                setTimeout(() => {
                    setDatesOpen(true);
                }, 120);
            }
        } else {
            setDatesOpen(true);
            setOpenedProperty(EDateType.DUE_DATE);
        }
    };

    const onEnter = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') { // чтобы не переходить в режим редактирования айтема
            e.stopPropagation();
        }
    };

    React.useEffect(() => {
        setDropdownOpen(isDatesOpen || isWeightOpen || isAssignOpen || isEstimateOpen);
    }, [isDatesOpen, isWeightOpen, isAssignOpen, isEstimateOpen]);

    if (!isEditing && (!assignees || !assignees.length) && !startDate && !dueDate && !estimate && !isShowWeights) return;

    return (
        <div
            className={`
                ${className}
                ${
                    !isEditing &&
                    !startDate &&
                    !dueDate &&
                    !estimate &&
                    (assignees && assignees.length || isShowWeights) ?
                        className + '--float' : ''
                }
                ${!isEditing && (assignees && assignees.length || isShowWeights) ? className + '--right-visible' : ''}
            `}
            onKeyDown={onEnter}
        >
            <div className={className + '-left'}>
                {(isEditing || !!dueDate || !!startDate) &&
                    <CardChecklistItemActionButton
                        className={`
                            ${className + '-dates'}
                            ${className + '-dates--' + id}
                            ${(!!startDate || !!dueDate) && className + '-dates-button'}
                            ${isDatesOpen ? className + '-dates--' + (openedProperty === EDateType.DUE_DATE ? 'due' : 'start') : ''}
                        `}
                        disabled={isReadonly}
                        icon={!startDate && !dueDate ? 'calendar' : ''}
                        label={!dueDate && !startDate ? null
                            : <>
                                {!!startDate ? <Tooltip
                                        {...getAsidePanelTooltip({
                                            value: CARD_START_DATE + ': ' + Util.formatDateMoment(startDate),
                                        })}
                                    >
                                        <div tabIndex={0} className={`${classIndicator} ${classIndicator + '--start'}`}>
                                            <Icon size={16} xlink={'start-date'} />
                                            {Util.formatDateMoment(startDate, 'DD MMM')}
                                        </div>
                                    </Tooltip>
                                    : !!dueDate && isEditing ? <div
                                        tabIndex={0}
                                        className={`
                                            ${classIndicator}
                                            ${classIndicator + '--start'}
                                            ${classIndicator}--empty
                                            ${classIndicator + '--icon'}
                                        `}
                                    >
                                        <Icon size={16} xlink={'start-date'} />
                                    </div> : null
                                }
                                {!!dueDate ? <Tooltip
                                        {...getAsidePanelTooltip({
                                            value: CARD_DUE_DATE + ': ' + Util.formatDateMoment(dueDate),
                                        })}
                                    >
                                        <div
                                            tabIndex={0}
                                            className={`
                                                ${classIndicator}
                                                ${classIndicator + '--due'}
                                                ${isItemOverdue ? classIndicator + '--overdue' : ''}
                                            `}
                                        >
                                            <Icon size={16} xlink={isItemOverdue ? 'overdue' : 'due-date'} />
                                            {Util.formatDateMoment(dueDate, 'DD MMM')}
                                        </div>
                                    </Tooltip>
                                    : !!startDate && isEditing  ? <div
                                        tabIndex={0}
                                        className={`
                                            ${classIndicator}
                                            ${classIndicator + '--due'}
                                            ${classIndicator}--empty
                                            ${classIndicator + '--icon'}
                                        `}
                                    >
                                        <Icon size={16} xlink={isItemOverdue ? 'overdue' : 'due-date'} />
                                    </div> : null
                                }
                            </>
                        }
                        isEmpty={!dueDate && !startDate}
                        onClick={onDatesClick}
                        dropdownClassName={`${className + '-dates-dropdown'} ${className + '-dates-dropdown--' + id}`}
                        isOpen={isDatesOpen}
                        setOpen={setDatesOpen}
                        notBlurClasses={[CLASS_DATEPICKER + '__calendar-select-dropdown']}
                        dropdown={<CardChecklistDateDatepicker
                            dateType={openedProperty}
                            startDate={startDate}
                            dueDate={dueDate}
                            onChangeStartDate={onChangeStartDate}
                            onChangeDueDate={onChangeDueDate}
                            setOpen={open => {
                                setDatesOpen(open);
                                if (!open) {
                                    setOpenedProperty(null);
                                }
                            }}
                            onClickStartDate={() => {
                                setOpenedProperty(EDateType.START_DATE);
                                onClickStartDate();
                            }}
                            onClickDueDate={() => {
                                setOpenedProperty(EDateType.DUE_DATE);
                                onClickDueDate();
                            }}
                        />}
                    />
                }
                {(isEditing || !!estimate) && <CardChecklistItemActionButton
                    tooltip={estimate ? getAsidePanelTooltip({
                        value: CARD_CHECKLIST_ESTIMATE + ': ' + estimateStr,
                    }) : null}
                    disabled={isReadonly}
                    className={className + '-estimate'}
                    buttonClassName={className + '-estimate-button'}
                    dropdownClassName={className + '-estimate-dropdown'}
                    icon={estimate ? null : 'estimate'}
                    label={estimate && estimateStr}
                    isEmpty={!estimate}
                    onClick={onClickEstimate}
                    isOpen={isEstimateOpen}
                    setOpen={(open) => {
                        if (!open && estimate !== newEstimate) {
                            onChangeEstimate(newEstimate);
                        }
                        setEstimateOpen(open);
                    }}
                    dropdown={<CardChecklistEstimate
                        estimate={estimate}
                        workHoursInDay={workHoursInDay}
                        onSave={onChangeEstimate}
                        onChange={setNewEstimate}
                        onClose={() => setEstimateOpen(false)}
                    />}
                />}
                {isEditing &&
                    <>
                        <CardChecklistItemActionButton
                            className={className + '-subcard'}
                            icon={'subcard'}
                            id={id}
                            onClick={onConvertToSubcardHandler}
                            tooltip={isTemplate ? null : getAsidePanelTooltip({
                                value: isMilestone ? CARD_CHECKLIST_CONVERT_TO_SUBCARD_MILESTONE : CARD_CHECKLIST_CONVERT_TO_SUBCARD,
                            })}
                            isEmpty
                            disabled={isReadonly || isTemplate}
                        />
                        <CardChecklistItemActionButton
                            className={className + '-delete'}
                            icon={'delete'}
                            tooltip={ getAsidePanelTooltip({
                                value: CHECKLIST_DELETE_BUTTON_TOOLTIP_TEXT,
                            })}
                            isEmpty
                            disabled={isReadonly}
                            onClick={() => onDelete().then(result => {
                                if (!result) setModalOpened(true);
                            })}
                        />
                    </>
                }
            </div>
            <div className={className + '-right'}>
                {(isEditing || assignees && !!assignees.length) &&
                    <CardChecklistItemActionButton
                        directionHorizontal={'right'}
                        className={className + '-assign'}
                        tooltip={getAsidePanelTooltip({
                            value: !assignees || !assignees.length ? CARD_CHECKLIST_ASSIGN_USER : null,
                        })}
                        icon={!assignees || !assignees.length ? 'account' : null}
                        disabled={isReadonly}
                        label={assignees && !!assignees.length &&
                            <UsersTooltip
                                authUserPermissionId={authUserPermissionId}
                                users={assignees}
                                boardUsers={boardUsers}
                                isNoEvents={true}
                            />
                        }
                        isEmpty={!assignees || !assignees.length}
                        id={id}
                        onClick={onAssignUserClick}
                        isOpen={isAssignOpen}
                        setOpen={setAssignOpen}
                        dropdown={<CardChecklistAssignUserSelect
                            availableAssignees={availableAssignees}
                            onSelect={onChangeAssignees}
                            onClose={() => setAssignOpen(false)}
                        />}
                    />
                }
                {(isShowWeights || isEditing) && <CardChecklistItemActionButton
                    className={`${className + '-weight'} ${!isShowWeights ? className + '--empty' : ''}`}
                    tooltip={getAsidePanelTooltip({
                        value: CARD_CHECKLIST_WEIGHT + ': ' + weight,
                    })}
                    icon={'weight'}
                    directionHorizontal={'right'}
                    disabled={isReadonly}
                    label={String(weight)}
                    id={id}
                    onClick={onClickWeight}
                    isOpen={isWeightOpen}
                    setOpen={setWeightOpen}
                    dropdown={<CardChecklistWeight
                        weight={weight}
                        onChange={onChangeWeight}
                        onClose={() => setWeightOpen(false)}
                    />}
                />}
            </div>
            <ActionModal
                isShow={isModalOpened}
                actions={[
                    {
                        variant: 'primary',
                        onClick: onCloseModal,
                        children: CARD_SECTION_CHECKLISTS_MODAL_CANCEL
                    },
                    {
                        variant: 'secondary',
                        onClick: () => {
                            onCloseModal();
                            return onDelete();
                        },
                        children: CARD_SECTION_CHECKLISTS_MODAL_REMOVE
                    },
                ]}
                footer={<BasicUpgradeButtonHOC onClick={onCloseModal} />}
                text={getMessages().getText('card_form_section_checklist_item_modal_text', null, aloudCount)}
                title={getMessages().getText('card_form_section_checklist_item_modal_title', null, name)}
                onClose={onCloseModal}
            />
        </div>
    );
}
