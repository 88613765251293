import { getFile } from 'app/helper/authorisation/microsoft/graph/api/getFile';
import { openUrl } from 'app/helper/file_helper';

export const downloadFile = (
    driveId: string,
    fileId: string,
) => {
    return getFile(driveId, fileId)
        .then(item => {
            //@ts-ignore
            return item && item['@microsoft.graph.downloadUrl']
        })
        .then(url => {
            openUrl(url);
        });
};
