import * as React from 'react';
import './_InviteDialog.scss';
import { IInviteDialogProps, IUserEmail } from './types';
import { Button, ButtonsGroup, Input } from 'kui';
import { ErrorHint } from '../../../../base/components/ErrorHint/ErrorHint';
import {
    getNewUserEmail,
    MESSAGE_CANCEL_BTN,
    MESSAGE_EMAIL_LABEL,
    MESSAGE_HEADER,
    MESSAGE_INVALID_EMAIL,
    MESSAGE_INVITE_BTN
} from './constants';

export const InviteDialog = ({
    onInvite,
    onCancel
}: IInviteDialogProps) => {
    const [userEmails, setUserEmails] = React.useState<IUserEmail[]>([getNewUserEmail()]);
    const className = 'invite-dialog';
    const headerClassName = className + '__header';
    const bodyClassName = className + '__body';
    const buttonsClassName = className + '__buttons';
    const bodyRef = React.useRef(null);
    const [disabled, setDisabled] = React.useState(true);

    React.useEffect(() => {
        bodyRef.current.scrollTop = bodyRef.current.scrollHeight;
    }, [userEmails.length])

    const onAdd = () => {
        setUserEmails([...userEmails, getNewUserEmail()]);
    }

    const updateHandler = (index: number) => (
        e: React.SyntheticEvent
    ) => {
        const target = e.target as HTMLInputElement;
        const { value } = target;
        const newEmails = [...userEmails];
        newEmails[index].email = value;
        newEmails[index].isValid = !value || target.checkValidity();
        setUserEmails(newEmails);
        setDisabled(!newEmails[0].email || !newEmails.every(userEmail => userEmail.isValid));
    }

    const inviteHandler = () => {
        setDisabled(true);
        const emails = userEmails
            .filter(userEmail => userEmail.email && userEmail.isValid)
            .map(userEmail => userEmail.email);
        onInvite([...new Set(emails)]);
    }

    const allEmailsAreFilledAndValid = userEmails.every(userEmail => !!userEmail.email && userEmail.isValid);

    return <div className={className}>
        <div className={headerClassName}>
            {MESSAGE_HEADER}
        </div>
        <div className={bodyClassName} ref={bodyRef}>
            {userEmails.map((userEmail, index) => {
                return <ErrorHint
                    value={!userEmail.isValid ? MESSAGE_INVALID_EMAIL : null}
                    direction="up"
                    key={userEmail.email}
                >
                    <Input
                        autoFocus={true}
                        autosize={false}
                        label={MESSAGE_EMAIL_LABEL}
                        value={userEmail.email}
                        onChange={updateHandler(index)}
                        type={'email'}
                        state={!userEmail.isValid ? 'error': undefined}
                    />
                </ErrorHint>
            })}
            <Button variant="add" onClick={onAdd} disabled={!allEmailsAreFilledAndValid}>Add email</Button>
        </div>
        <ButtonsGroup size={'large'} className={buttonsClassName}>
            <Button onClick={onCancel} variant="secondary" size="large">{MESSAGE_CANCEL_BTN}</Button>
            <Button onClick={inviteHandler} size="large" disabled={disabled}>{MESSAGE_INVITE_BTN}</Button>
        </ButtonsGroup>
    </div>
}
