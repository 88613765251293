import * as React from 'react';
import { ICardChecklistCopySearchSelectProps } from './types';
import { SearchSelect } from '../../../../../base/components/SearchSelect/SearchSelect';
import {
    CardChecklistCopySearchSelectOption
} from '../CardChecklistCopySearchSelectOption/CardChecklistCopySearchSelectOption';
import { CLASS_CARD_DETAILS } from '../../../constants';
import './_CardChecklistCopySearchSelect.scss';
import { v4 as uuidv4 } from 'uuid';

export function CardChecklistCopySearchSelect({
    availableElements,
    isAutoFocus,
    isClearable,
    isClose,
    selectedCardId,
    onSelect,
}: ICardChecklistCopySearchSelectProps ) {
    const className = CLASS_CARD_DETAILS + '__checklist-copy-search';

    const [selectedCardText, setSelectedCardText] = React.useState('');
    const [key, setKey] = React.useState(null);

    const selectedCard = selectedCardId ? availableElements.find(i => i.value === String(selectedCardId)) : null;

    const onSelectHandler = (value: string) => {
        onSelect(Number(value));
    };

    const onClose = () => {
        onSelect(null);
    };

    const onEsc = (e: React.KeyboardEvent) => {
        if (!isClose) return;
        if (e.key === 'Escape') {
            onClose();
        }
    };

    React.useEffect(() => {
        if (!selectedCard) return;
        const card = availableElements.find(i => i.value === selectedCard.value);
        if (card) setSelectedCardText(card.text);
        setKey(uuidv4());
    }, [selectedCardId]);

    const searchSelect = <SearchSelect
        isDropdownUplifted
        activeValue={String(selectedCardId)}
        autoFocus={isAutoFocus}
        isCloseOnClick={false}
        className={className + '-select'}
        icon={selectedCard ? selectedCard.img : null}
        isClearable={isClearable}
        isFitWindow
        isKeepValue
        onKeyDown={onEsc}
        key={key}
        opened={!key}
        options={availableElements}
        option={CardChecklistCopySearchSelectOption}
        text={selectedCardText}
        onSelect={onSelectHandler}
    />;

    if (!isClose) {
        return searchSelect;
    }

    return (
        <div className={className}>
            {searchSelect}
        </div>
    );
};
