import { IChecklistItem, IChecklistItems } from '../../../../../../../store/model/checklists/checklist/types';
import {
    getAuthUserChecklistOrderMode
} from '../../../../../../../store/model/authUser/selectors/getAuthUserChecklistOrderMode';
import { IApplicationState, TCardId, TChecklistId } from '../../../../../../../types/types';
import { getChecklistItems } from '../../../../../../../store/model/checklists/checklist/selectors/getChecklistItems';
import { createDeepEqualSelector } from '../../../../../helpers/memoizeHelper';
import { ECardChecklistOrderMode } from '../../../../../../../types/rest/IUserMeta';

type TgetChecklistItemsSection = (
    state: IApplicationState,
    cardId: TCardId,
    checklistId: TChecklistId,
) => IChecklistItem[];

const sortChecklistItems = (
    checklistItems: IChecklistItem[],
    orderMode: ECardChecklistOrderMode,
): IChecklistItem[] => {
    checklistItems.sort((item1, item2) => (item1.orderNumber - item2.orderNumber));
    if (orderMode === ECardChecklistOrderMode.MANUAL) return checklistItems;

    return checklistItems.sort((item1, item2) => {
        let compareResult = Number(item2.checked) - Number(item1.checked);
        if (orderMode === ECardChecklistOrderMode.DONE_LAST) compareResult = -compareResult;
        return compareResult;
    });
}

const getChecklistItemsSectionSelector = (
    checklistItems: IChecklistItems,
    checklistOrderMode: ECardChecklistOrderMode,
): IChecklistItem[] => {
    if (!checklistItems) return [];

    return sortChecklistItems(Object.values(checklistItems), checklistOrderMode);
};

export const getChecklistItemsSection = (
): TgetChecklistItemsSection => createDeepEqualSelector(
    getChecklistItems,
    getAuthUserChecklistOrderMode,
    getChecklistItemsSectionSelector,
);

export const getChecklistItemsNoReselect = (
    state: IApplicationState,
    cardId: TCardId,
    checklistId: TChecklistId,
): IChecklistItem[] => getChecklistItemsSectionSelector(
    getChecklistItems(state, cardId, checklistId),
    getAuthUserChecklistOrderMode(state),
);
