import { IBoardSetAction } from './types';
import { IBoard } from '../types';
import { AT_BOARD_SET } from './constants';

export const boardSet = (
    board: IBoard
): IBoardSetAction => ({
    type: AT_BOARD_SET,
    board
});
