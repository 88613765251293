import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { TBoardId, TCardId, IGetState } from '../../../../../../../../types/types';
import { pasteCommentMention } from '../../CommentsInputHOC/effects/pasteCommentMention';
import { CLASS_COMMENTS_SECTION_INPUT } from '../../../components/CommentsInput/constants';
import { CLASS_COMMENTS_SECTION_FORM } from '../../../constants';
import { commentUpdate } from '../../../store/reducers/actions/commentUpdate';

export const onMention = (
    mentionIndex: number,
    boardId: TBoardId,
    cardId: TCardId
): ThunkAction => {
    return (dispatch: Dispatch) => {
        const input = document.querySelector('.' + CLASS_COMMENTS_SECTION_INPUT + ' textarea') as HTMLInputElement ||
            document.querySelector(`.${CLASS_COMMENTS_SECTION_FORM} .ql-editor`) as HTMLInputElement;

        dispatch(commentUpdate({ highlightedMention: mentionIndex }));
        setTimeout(() => {
            dispatch(pasteCommentMention(input, boardId, cardId));
        }, 100);
        input.focus();
    };
};
