import { BACKUPS_ACTION_SET, IBackupsActionSetAction } from './types';
import { TBackupsAction } from '../backups/actions/types';

export const backupsActionSet = (
    backupsAction: TBackupsAction
): IBackupsActionSetAction => {
    return {
        type: BACKUPS_ACTION_SET,
        backupsAction
    }
};
