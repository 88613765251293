import { IGetState, TBoardId } from '../types/types';
import { Dispatch, ThunkAction } from '../types/actions';
import { getBoard } from '../store/model/selectors/getBoardById';
import store from '../store/configureStore';
import { boardCopy } from '../rest/effects/board/boardCopy';
import { getMessages, root } from '../store/constants';

export const openCopyBoard = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const board = getBoard(store.getState(), boardId);
        if (board) {
            dispatch(boardCopy(boardId));
        } else {
            root.App.controller.showError(getMessages().getText('error.record.not_found'));
        }
    };
    return action;
}
