import * as React from 'react';
import { CLASS_ACTIVITY_ITEM } from '../../../../boardDetails/activity/TabActivity/components/constants';
import './_ActivityPriority.scss';
import { Icon } from 'kui';
import { PRIORITY_ICONS } from '../../../../../helpers/constants';
import { IActivityPriorityProps } from './types';

export function ActivityPriority({
    priority,
    removed
}: IActivityPriorityProps) {
    const className = CLASS_ACTIVITY_ITEM + '__priority';
    const priorityObj = PRIORITY_ICONS.find(item => item.priority === String(priority) || !priority && !Number(item.priority));
    return (
        <div
            className={`
                ${className}
                ${removed ? className + '--removed' : ''}
            `}
        >
            <Icon
                size={24}
                xlink={priorityObj.icon}
            />
            <div className={className + '-label'}>
                {priorityObj.text}
            </div>
        </div>
    )
};
