import * as React from 'react';
import { useEffect } from 'react';
import { ICardDescriptionProps } from './types';
import './_CardDescription.scss';
import { Button, ButtonsGroup, ButtonTitle, Icon, Input, Switch } from 'kui';
import {
    CARD_DESCRIPTION,
    CARD_DESCRIPTION_ADD,
    CARD_DESCRIPTION_CANCEL,
    CARD_DESCRIPTION_SAVE,
    CARD_DESCRIPTION_SHOW_LESS,
    CARD_DESCRIPTION_SHOW_MORE,
    CARD_DESCRIPTION_TOOLTIP_PIN,
    CARD_DESCRIPTION_TOOLTIP_UNPIN,
    CARD_DESCRIPTION_UNSAVED,
    CARD_DESCRIPTION_UNSAVED_DELETE_TOOLTIP,
    ENTER_WHICH_KEY_NUMBER,
} from './constants';
import { CLASS_CARD_DETAILS } from '../../../constants';
import { CLASS_ASIDE_PANEL, GOOGLE_SPACING, TEXTAREA_MAX_HEIGHT } from 'app/const';
import { root } from 'app/store/constants';
import { getAsidePanelTooltip } from 'app/view/react_components/aside_panel/asidePanel/helpers/getAsidePanelTooltip';
import { blockMarkdown } from 'app/helper/markdownHelper';
import { getSelectionTree } from 'app/view/react_components/helpers/getSelectionTree';
import { HtmlEditorHOC } from '../../hocs/HtmlEditorHOC/HtmlEditorHOC';
import { AsidePanelContext } from 'app/view/react_components/aside_panel/asidePanel/components/AsidePanel/constants';
import ReactHtmlParser from 'react-html-parser';
import { EHTMLEditorType } from 'app/types/types';
import { msFileThumbnailStorage } from '../../../../../../../helper/authorisation/microsoft/storage/msThumbnail';
import { getCaretCoordinates, MIRROR_DIV_ID } from './helpers/getCaretCoordinates';
import { getCaretPosition } from 'app/view/react_components/helpers/getCaretPosition';

export function CardDescription({
    description,
    descriptionUnsaved,
    HTMLEditorType,
    isPinned,
    isReadonly,
    isRequired,
    onCancel,
    onCancelWithoutChanges,
    onEsc,
    onSave,
    onCtrlEnter,
    onSaveUnsavedDescription,
    onDeleteUnsavedDescription,
    onShowUnsavedDescription,
    onTogglePin
}: ICardDescriptionProps) {
    const { cardId, isMobile } = React.useContext(AsidePanelContext);

    const className = CLASS_CARD_DETAILS + '__description';
    const classNameInput = CLASS_CARD_DETAILS + '__description-input';
    const classNameHtml = CLASS_CARD_DETAILS + '__description-html';
    const classNameHtmlContainer = CLASS_CARD_DETAILS + '__description-html-container';
    const classNameRequired = className + '--required';
    const classNameButton = CLASS_CARD_DETAILS + '__description-button';
    const classNameButtonMore = CLASS_CARD_DETAILS + '__description-button-more';
    const classNameButtonLess = CLASS_CARD_DETAILS + '__description-button-less';
    const classNameButtonFormatting = CLASS_CARD_DETAILS + '__description-button-formatting';
    const classNameButtonContainer = CLASS_CARD_DETAILS + '__description-button-container';
    const classNameUnsavedEditsButtonContainer = CLASS_CARD_DETAILS + '__description-unsaved-edits-button-container';
    const classNameUnsavedEditsButtonTitle = CLASS_CARD_DETAILS + '__description-unsaved-edits-button-title';
    const classNameButtonSave = CLASS_CARD_DETAILS + '__description-button-save';
    const classNameError = className + '--error';
    const classNamePin = className + '-pin';
    const classNamePinActive = classNamePin + '--active';

    const [markdown, setMarkdown] = React.useState('');
    const [isError, setError] = React.useState(false);
    const [lastState] = React.useState<any>({});
    lastState.saveUnsavedDescription = saveUnsavedDescription;
    const [isInput, setIsInput] = React.useState(false);
    let [isCollapse, setCollapse] = React.useState(null);
    let [needCollapse, setNeedCollapse] = React.useState(false);
    const [selectionTree, setSelectionTree] = React.useState([]);

    const classEmpty = !description ? classNameHtmlContainer + '--empty' : '';
    const classCollapse = isCollapse ? classNameHtmlContainer + '--collapsed' : '';
    const classExpanded = needCollapse && !isCollapse ? classNameHtmlContainer + '--expanded' : '';

    const containerRef = React.useRef(null);
    const inputRef = React.useRef(null);
    const htmlRef = React.useRef(null);
    const requestAnimation = React.useRef(null);
    const descriptionEditor = React.useRef(null);
    const controlsRef = React.useRef(null);

    function handleHtmlChange(value: string) {
        setTimeout(() => { // fix: на umount quill почему-то возвращает исходное value
            if (isInput) {
                descriptionEditor.current = value;
            }
        }, 100);
    }

    function handleCtrlEnter(event?: React.KeyboardEvent) {
        if (event) event.preventDefault();
        setIsInput(false);
        setErrorIfEmpty();
        onCtrlEnter(descriptionEditor.current);
    }

    useEffect(() => {
        descriptionEditor.current = description;
    }, [description]);

    const setErrorIfEmpty = () => {
        if (isRequired && !descriptionEditor.current) {
            setError(true);
        }
    }

    function inputShow(event: React.MouseEvent) {
        const { tagName } = event.target as HTMLElement;
        if (tagName && tagName.toLowerCase() === 'a') return;

        const selection = root.getSelection();
        if (!selection.isCollapsed) return;

        if (!descriptionEditor.current) {
            setSelectionTree([0, 0]);
        } else {
            setSelectionTree(getSelectionTree(classNameHtml));
        }
        setIsInput(true);
        focusOnInput();
    }

    function handleAddDescriptionButtonCLick(event: React.MouseEvent) {
        setIsInput(true);
        setErrorIfEmpty();
    }

    function focusOnInput() {
        const caret = getCaretPosition();
        if (!caret) return;
        let { position, container, selectedParagraphPosition } = caret;
        requestAnimation.current = requestAnimationFrame(() => {
            if (inputRef && inputRef.current) {
                inputRef.current.setFocus();
                const input = document.querySelector(`.${classNameInput} .kui-input__item`) as HTMLInputElement;
                const lines = input.value.split(/\r|\r\n|\n/);
                let lineCount = 0;
                let paragraphsCount = 0;
                let prevLines = null;
                for (let i = 0; i < lines.length; i++) {
                    if (lines[i] === '' && prevLines !== '' && prevLines !== null) paragraphsCount += 1
                    prevLines = lines[i]
                    if (lines[i] === container.nodeValue && selectedParagraphPosition === paragraphsCount) break
                    if (lines[i] === '') lineCount += 1
                }
                position += lineCount;
                input.setSelectionRange(position, position);
            }
        });
    }

    const toggleShowMore = (e: React.MouseEvent) => {
        setCollapse(!isCollapse);
    }

    function onInputKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Escape') {
            event.stopPropagation();//to avoid aside-panel closure on esc if description is in focus
            descriptionEditor.current = description;
            setIsInput(false);
            setErrorIfEmpty();
            onEsc();
        } else if (event.which === ENTER_WHICH_KEY_NUMBER && (event.ctrlKey || event.metaKey)) {
            handleCtrlEnter(event);
        }
    }

    function handlerSaveClicked(e: React.SyntheticEvent) {
        setIsInput(false);
        setErrorIfEmpty();
        onSave(descriptionEditor.current);
        setMarkdown(blockMarkdown(description));
    }

    function handleCancelClicked() {
        if (descriptionEditor.current === description) {
            onCancelWithoutChanges();
        } else {
            onCancel();
            descriptionEditor.current = description;
        }
        setIsInput(false);
        setErrorIfEmpty();
        setMarkdown(blockMarkdown(description));
    }

    function saveUnsavedDescription() {
        setIsInput(false);
        if (descriptionEditor.current !== description) {
            onSaveUnsavedDescription(descriptionEditor.current);
        }
    }

    function handleInputChange(e: React.SyntheticEvent) {
        let { value } = e.target as HTMLInputElement;
        descriptionEditor.current = value;
    }

    function handleUnsavedDataClicked() {
        setMarkdown(blockMarkdown(descriptionUnsaved.descriptionUnsaved));
        descriptionEditor.current = descriptionUnsaved.descriptionUnsaved;
        setIsInput(true);
        onShowUnsavedDescription();
    }
    function onCloseUnsavedDescription() {
        descriptionEditor.current = description;
        onDeleteUnsavedDescription();
    }

    function checkCollapse () {
        if (!isInput && htmlRef.current) {
            needCollapse = !!htmlRef.current.innerText &&
                htmlRef.current.offsetHeight > TEXTAREA_MAX_HEIGHT;
            setNeedCollapse(needCollapse);
            if (isCollapse === null && needCollapse || // auto collapse only on open
                isCollapse && !needCollapse
                ) {
                setCollapse(needCollapse);
            }
        }
    }

    const onHtmlKeyDown = (e: React.KeyboardEvent) => {
        if (!e) return;
        if (e.key === 'Enter') {
            inputShow(e as any);
        }
    }

    React.useEffect(() => {
        if (!isInput) {
            const mirrorDiv = document.querySelector(`#${MIRROR_DIV_ID}`) as HTMLElement;
            if (mirrorDiv) mirrorDiv.remove();
            return;
        }

        function onKeyUp() {
            const coordinates = getCaretCoordinates(textarea, textarea.selectionEnd);
            const topElementCoords = textarea.getBoundingClientRect().top;
            const topCaredCoords = coordinates.top + topElementCoords;
            const windowHeight = document.documentElement.clientHeight;
            if (windowHeight - topCaredCoords < (isMobile ? GOOGLE_SPACING * 13.75 : GOOGLE_SPACING * 8.75)) {
                const body = document.querySelector(`.aside-panel__body`);
                if (windowHeight - topCaredCoords < (isMobile ? GOOGLE_SPACING * 8.75 : GOOGLE_SPACING * 3.75)) {
                    body.scrollBy({
                        top: isMobile ? GOOGLE_SPACING * 12 : GOOGLE_SPACING * 6,
                        behavior: 'smooth',
                    });
                }
                else {
                    body.scrollBy({
                        top: GOOGLE_SPACING * 3,
                        behavior: 'smooth',
                    });
                }
            }
        }

        const textarea = document.querySelector(`.${classNameInput} .kui-input__item`) as any;
        if (textarea) textarea.addEventListener('keyup', onKeyUp);
    }, [isInput]);

    React.useEffect(() => {
        if (
            isRequired &&
            descriptionEditor.current &&
            descriptionEditor.current.length
        ) {
            setError(false);
        }
    }, [descriptionEditor.current, isInput]);

    const [isControlsSticky, setControlsSticky] = React.useState(false);
    React.useEffect(() => {
        function createObserver() {
            if (!controlsRef.current) return;
            const observer = new IntersectionObserver(([e]) => {
                    setControlsSticky(e.intersectionRatio < 1);
                }, {
                    threshold: 1
                }
            );
            observer.observe(controlsRef.current);
        }
        requestAnimationFrame(createObserver); // когда элементы появятся в доме
    }, [isInput]);

    React.useEffect(() => {
        setMarkdown(blockMarkdown(description));

        if (description && msFileThumbnailStorage.is()) {
            msFileThumbnailStorage.getSrcThumbnails(description)
                .then((text) => {
                    setMarkdown(blockMarkdown(text));
                })
                .catch(() => {})
        }
    }, [description]); //, isInput

    React.useEffect(() => {
        setTimeout(checkCollapse, 100);
    }, [markdown]);

    React.useEffect(() => {
        /**
         * при открытии панели надо дождаться отрисовки текста, потом запускать checkCollapse
         */
        if (description) {
            const observer = new MutationObserver(() => { // wait classNameHtml innerText
                checkCollapse();
                if (htmlRef.current && htmlRef.current.innerText) observer.disconnect();
            });
            const aside = containerRef.current.closest('.' + CLASS_ASIDE_PANEL) as HTMLElement;
            if (aside) {
                observer.observe(aside, {childList: true, subtree: true});
            }
        }

        return () => {
            setIsInput(false);
            lastState.saveUnsavedDescription();
            if (requestAnimation.current) cancelAnimationFrame(requestAnimation.current);
        }
    }, []);

    const cardDescriptionUnsavedDate = !!descriptionUnsaved && (
        <div
            className={ classNameUnsavedEditsButtonContainer }
        >
            <Button
                variant={ 'icon-text' }
                onClick={ handleUnsavedDataClicked }
            >
                <Icon xlink={ 'info' }/>
                <ButtonTitle>
                    <span className={classNameUnsavedEditsButtonTitle}>
                        {CARD_DESCRIPTION_UNSAVED}
                    </span>
                    { descriptionUnsaved.datetime }
                </ButtonTitle>
            </Button>
            <Button
                variant={ 'icon' }
                tooltip={ getAsidePanelTooltip({
                        direction: 'up-left',
                        value: CARD_DESCRIPTION_UNSAVED_DELETE_TOOLTIP
                    })
                }
                onClick={ onCloseUnsavedDescription }
            >
                <Icon xlink={ 'close' } size={24}/>
            </Button>
        </div>
    );

    return (
        <div className={`
                ${className}
                ${isRequired ? classNameRequired : ''}
                ${isError && !isInput ? classNameError : ''}
            `}
            ref={containerRef}
        >
            {isInput
                ? <>
                    {!0 || HTMLEditorType === EHTMLEditorType.MARKDOWN
                        ? <Input
                            autoFocus
                            className={classNameInput}
                            ref={ inputRef }
                            value={ descriptionEditor.current }
                            onKeyDown={ onInputKeyDown }
                            onChange={ handleInputChange }
                        />
                        : <HtmlEditorHOC
                            cardId={cardId}
                            selectionTree={selectionTree}
                            value={markdown}
                            onChange={handleHtmlChange}
                            onCtrlEnter={handleCtrlEnter}
                            onEscape={handleCancelClicked}
                        />
                    }
                    <div className={`${classNameButtonContainer} ${isControlsSticky ? 'sticky' : ''}`}>
                        <ButtonsGroup>
                            <Button
                                variant={'secondary'}
                                text={ CARD_DESCRIPTION_CANCEL }
                                onClick={ handleCancelClicked }
                            />
                            <Button
                                className={classNameButtonSave}
                                text={ CARD_DESCRIPTION_SAVE }
                                onClick={ handlerSaveClicked }
                            />
                        </ButtonsGroup>
                    </div>
                    <div ref={controlsRef} />
                </>
                : <>
                    <div
                        className={`
                            ${classNameHtmlContainer}
                            ${classCollapse}
                            ${classEmpty}
                            ${classExpanded}
                        `}
                    >
                        {!!description
                            ? <>
                                {!isReadonly &&
                                    <Button
                                        className={`
                                            ${classNamePin}
                                            ${isPinned ? classNamePinActive : ''}
                                        `}
                                        variant={'icon'}
                                        tooltip={getAsidePanelTooltip({
                                            value: isPinned ? CARD_DESCRIPTION_TOOLTIP_UNPIN : CARD_DESCRIPTION_TOOLTIP_PIN
                                        })}
                                        onClick={onTogglePin}
                                    >
                                        <Icon xlink={'pin'} size={24}/>
                                    </Button>
                                }
                                <div
                                    className={ classNameHtml }
                                    ref={ htmlRef }
                                    role={'button'}
                                    tabIndex={0}
                                    aria-label={`${CARD_DESCRIPTION}: ${descriptionEditor.current}`}
                                    onClick={ inputShow }
                                    onKeyDown={onHtmlKeyDown}
                                >
                                    {ReactHtmlParser(markdown)}
                                </div>
                                {(needCollapse || isCollapse) &&
                                    <div
                                        className={!!isCollapse ? classNameButtonMore : classNameButtonLess}
                                        onClick={toggleShowMore}
                                    >
                                        <Button
                                            variant={'icon-text'}
                                            text={!!isCollapse ? CARD_DESCRIPTION_SHOW_MORE : CARD_DESCRIPTION_SHOW_LESS}
                                            onClick={toggleShowMore}
                                        >
                                            <Icon
                                                xlink={!!isCollapse ? 'arrow-s-drop-open' : 'arrow-s-drop-close'}
                                                size={24}
                                            />
                                        </Button>
                                    </div>
                                }
                            </>
                            : <Button
                                className={ classNameButton }
                                variant={ 'icon-text' }
                                text={ CARD_DESCRIPTION_ADD }
                                onClick={ handleAddDescriptionButtonCLick }
                            >
                                <Icon
                                    xlink={ 'description' }
                                    size={ 24 }
                                />
                            </Button>
                        }
                    </div>
                    {cardDescriptionUnsavedDate}
                </>
            }
        </div>
    );
}
