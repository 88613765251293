import { Dispatch, ThunkAction } from '../../../../../../types/actions';
import { segmentTrackAction, SegmentUserEvent } from '../../../../../../middlewares/segment';
import { SegmentUserOption } from '../../../../../../middlewares/segment/trackEntities/userEvents';
import { root } from '../../../../../../store/constants';
import { ETutorial } from '../../../../../../types/rest/IUserMeta';
import { TUTORIALS_NAME_MAP } from '../constants';

export const onClose = (
    currentTime: number,
    duration: number,
    tutorial: ETutorial,
    userClose: boolean
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(segmentTrackAction(SegmentUserEvent.TUTORIAL_WATCHED, {
            name: SegmentUserOption.TUTORIAL_SHOW_TIME,
            value: duration
        }, [
            { name: SegmentUserOption.TUTORIAL_CURRENT_TIME, value: currentTime },
            { name: SegmentUserOption.TYPE, value: TUTORIALS_NAME_MAP[tutorial] }
        ]));
        if (userClose) {
            dispatch(segmentTrackAction(SegmentUserEvent.TUTORIAL_CLOSED));
            root.App.controller.hideTutorial();
        }
    }
    return action;
}
