import { ThunkAction } from '../../../../../../../types/actions';
import { segmentTrackAction, SegmentUserEvent } from '../../../../../../../middlewares/segment';

export const onDidMount = (): ThunkAction => {
    return (
        dispatch
    ) => {
        dispatch(segmentTrackAction(SegmentUserEvent.BROWSER_NOTIFICATIONS_MODAL_SHOWN));
    }
}
