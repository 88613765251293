import * as React from 'react';
import './_WeekDays.scss';
import { CLASS_CARD_SECTION_RECURRING } from '../../../constants';
import { WEEK_DAYS } from './constants';
import { Button, ButtonTitle } from 'kui';
import * as moment from 'moment';
import { CardRecurringFormContext } from '../CardRecurringForm/constants';
import { LABEL_WEEK_DAYS } from '../wording';

export function WeekDays() {
    const {form, updateForm, isReadonly} = React.useContext(CardRecurringFormContext);

    const onWeekDayToggle = (
        dayNumber: number
    ) => {
        const weekDays = [...form.weekDays];
        const index = form.weekDays.indexOf(dayNumber);

        if (index !== -1) {
            weekDays.splice(index, 1);
        } else {
            weekDays.push(dayNumber)
        }
        updateForm({ weekDays });
    }

    const className = CLASS_CARD_SECTION_RECURRING + '__week-days';
    const classNameButton = CLASS_CARD_SECTION_RECURRING + '__week-days-button';

    return (
        <div className={className}>
            <span className={ 'kui-label__item' }>
                {LABEL_WEEK_DAYS}
            </span>
            <div className={className + '-list'}>
                {WEEK_DAYS.map((day) => {
                    return <Button
                        className={classNameButton}
                        key={day}
                        variant={form.weekDays.includes(day) ? 'primary' : 'secondary'}
                        onClick={() => onWeekDayToggle(day)}
                        disabled={isReadonly}
                    >
                        <ButtonTitle>{moment.weekdaysMin(day - 1)}</ButtonTitle>
                    </Button>
                })}
            </div>
        </div>
    );
}
