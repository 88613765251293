import { IMyWorkState } from '../types';
import { TMyWorkAction } from '../actions/types';
import * as deepFreeze from 'deep-freeze';
import {
    AT_LOADED_BOARDS_ADD,
    AT_LOADED_SET,
    AT_NEW_CARD_ID_ADD,
    AT_NEW_CARD_ID_REMOVE,
    AT_CLOSED_GROUP_SET,
    AT_SHOW_ARCHIVED_SET,
    AT_SHOW_BOARDS_SET
} from '../actions/constants';
import {
    setClosedGroupsLocalStorage
} from '../localStorage/actions/setClosedGroupsLocalStorage';
import { getClosedGroupsLocalStorage } from '../localStorage/actions/getClosedGroupsLocalStorage';

const initialState: IMyWorkState = {
    newCardIds: [],
    loaded: false,
    showArchived: false,
    showBoards: [],
    loadedBoards: [],
    closedGroups: getClosedGroupsLocalStorage()
};

const _myWork = (
    state: IMyWorkState = initialState,
    action: TMyWorkAction
): IMyWorkState => {
    switch (action.type) {
        case AT_LOADED_SET: {
            const { loaded } = action;
            return {
                ...state,
                loaded
            }
        }
        case AT_NEW_CARD_ID_ADD: {
            if (!state.newCardIds.includes(action.cardId)) {
                return {
                    ...state,
                    newCardIds: [
                        ...state.newCardIds,
                        action.cardId
                    ]
                }
            }
            return state
        }
        case AT_NEW_CARD_ID_REMOVE: {
            const index = state.newCardIds.indexOf(action.cardId);
            if (index > -1) {
                const newCardIds = [...state.newCardIds];
                newCardIds.splice(index, 1);
                return {
                    ...state,
                    newCardIds
                }
            }
            return state;
        }
        case AT_SHOW_ARCHIVED_SET: {
            return {
                ...state,
                showArchived: action.showArchived
            }
        }
        case AT_SHOW_BOARDS_SET: {
            return {
                ...state,
                showBoards: action.boardIds
            }
        }
        case AT_LOADED_BOARDS_ADD: {
            return {
                ...state,
                loadedBoards: [...state.loadedBoards, action.boardId]
            }
        }
        case AT_CLOSED_GROUP_SET: {
            const closedGroups = { ...state.closedGroups };
            const newClosedGroups = [...closedGroups[action.sortType]];
            const idx = newClosedGroups.findIndex(group => group === action.group);
            if (idx > -1) {
                newClosedGroups.splice(idx, 1);
            } else {
                newClosedGroups.push(action.group);
            }
            closedGroups[action.sortType] = newClosedGroups;
            return {
                ...state,
                closedGroups
            }
        }
        default: {
            return state;
        }
    }
};

export const myWorkReducer = (
    state = initialState,
    action: TMyWorkAction
): IMyWorkState => {
    let newState: IMyWorkState;
    // @ts-ignore
    if (window.Settings.development){
        // @ts-ignore
        newState =deepFreeze(_myWork(state, action));
    } else {
        newState = _myWork(state, action);
    }
    if (newState !== state) {
        if (action.type === AT_CLOSED_GROUP_SET) {
            setClosedGroupsLocalStorage(newState.closedGroups);
        }
    }
    return newState;
};
