import { msUploadLargeFile } from 'app/helper/authorisation/microsoft/filePicker/fileUploader';

export const uploadFiles = (
    driveId: string,
    folderId: string,
    files: FileList | File[],
    progressCallback: (percent: number) => void,
) => {
        const filesArr = Array.from(files);
        const filesSizeTotal = getFilesTotalSize(filesArr);
        const filesSizeUploaded = filesArr.map(file => 0);

        const onProgress = (idx: number, uploaded: number) => {
            filesSizeUploaded[idx] = uploaded;
            let filesSizeUploadedSum = filesSizeUploaded.reduce((file, size) => size + file, 0);
            let percent = Math.round(filesSizeUploadedSum / filesSizeTotal * 100);
            if (percent > 100) percent = 100;
            progressCallback(percent);
        }

        const fileUploadPromises = filesArr.map((_file, idx) => {
            return msUploadLargeFile(driveId, folderId, _file, (uploaded) => onProgress(idx, uploaded));
        });

        return Promise.all(fileUploadPromises);
};
const getFilesTotalSize = (files: File[]): number => {
    let result = 0;
    files.forEach(file => {
        result += file.size;
    });
    return result;
}
