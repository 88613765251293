import { IUserEmail } from './types';
import { getMessages } from '../../../../../../store/constants';

export const getNewUserEmail = (): IUserEmail => ({
    email: '',
    isValid: true
});

export const MESSAGE_HEADER = getMessages().getText('invite.header');
export const MESSAGE_EMAIL_LABEL = getMessages().getText('invite.email_label');
export const MESSAGE_INVALID_EMAIL = getMessages().getText('invite.invalid_email');
export const MESSAGE_CANCEL_BTN = getMessages().getText('invite.cancel_btn');
export const MESSAGE_INVITE_BTN = getMessages().getText('invite.invite_btn');
