import { TCardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IDriveDoc } from '../../../../store/model/card/types/IDriveDoc';
import { postRestHelper } from './api/helper/postRestHelper';
import { cardUpdateAttachmentsCount } from '../cardUpdateAttachmentsCount';
import { ERestDriveDocType } from '../../../../types/rest/IRestDriveDoc';

export const addAttachments = (
    cardId: TCardId,
    driveDocs: IDriveDoc[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        return dispatch(postRestHelper(cardId, driveDocs))
            .then((driveDocs: IDriveDoc[]) => {
                if (driveDocs && driveDocs[0] && driveDocs[0].type === ERestDriveDocType.CARD) {
                    dispatch(cardUpdateAttachmentsCount(cardId));
                }
                return driveDocs;
            });
    };
    return action;
};
