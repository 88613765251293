import { Dispatch, ThunkAction } from 'app/types/actions';
import { segmentTrackAction } from 'app/middlewares/segment';

const category = 'User';

export const SegmentUserEvent = {
    SIGN_IN: { category, event: 'User logged in' },
    SIGNED_UP: { category, event: 'New user signed up' },
    SIGNED_UP_DOMAIN: { category, event: 'New_domain_user_signed_up' },
    GET_STARTED_CLICKED: { category, event: 'Get started clicked' },
    OLD_USER_REGIVEN_PERMISSIONS: { category, event: 'Old user re-given permissions' },
    CURRENT_USER_REGIVEN_PERMISSIONS: { category, event: 'Current user re-given permissions' },
    PERMISSION_SCREEN: { category, event: 'Permissions screen showed' },
    LOGIN_PRESSED: { category, event: 'Sign in button clicked' },
    COOKIE_SCREEN: { category, event: 'Cookie screen showed' },
    COOKIE_SETTINGS: { category, event: 'Cookie settings link clicked' },
    COOKIE_ACCEPT: { category, event: 'Cookies accepted' },
    COOKIE_SUPRESSED: { category, event: 'Performance cookies denied after previous acceptance' },
    COOKIE_UNSUPRESSED: { category, event: 'Performance cookies accepted after previous denial' },
    COOKIE_UNCHECK_PERFORMANCE: { category, event: 'Performance cookie denied' },
    COOKIE_CHECK_PERFORMANCE: { category, event: 'Performance cookie accepted' },
    COOKIE_UNCHECK_FUNCTIONALITY: { category, event: 'Functionality cookie denied' },
    COOKIE_CHECK_FUNCTIONALITY: { category, event: 'Functionality cookie accepted' },
    TROUBLES_LOGIN: { category, event: 'Troubles login screen showed' },
    TROUBLES_RELOAD: { category, event: 'Reload button clicked on Troubles login screen' },
    TROUBLES_TRY: { category, event: 'Try again button clicked on Troubles login screen' },
    TROUBLES_HELP: { category, event: 'Contact us button clicked on Troubles login screen' },
    TROUBLES_FAQ: { category, event: 'FAQ button clicked on Troubles login screen' },
    TUTORIAL_WATCHED: { category, event: 'Tutorial watched' },
    TUTORIAL_CLOSED: { category, event: 'Tutorial closed' },
    TUTORIAL_PLAY: { category, event: 'Tutorial play' },
    TUTORIAL_MUTE: { category, event: 'Tutorial mute' },
    TUTORIAL_FULLSCREEN: { category, event: 'Tutorial fullscreen' },
    ACCOUNT_DELETED: { category, event: 'Account deleted' },
    SIGN_IN_SHOWED: { category, event: 'Sign in button showed' },
    PERMISSIONS_BUTTON_CLICKED: { category, event: 'Permissions button clicked' },
    PERMISSION_DRIVE_SCOPE_SHOWN: { category, event: 'Permissions screen DRIVE_SCOPE shown'},
    PERMISSION_DRIVE_SCOPE_CANCELED: { category, event: 'Permissions screen DRIVE_SCOPE canceled'},
    PERMISSION_DRIVE_SCOPE_REQUESTED: { category, event: 'Permissions screen DRIVE_SCOPE requested'},
    PERMISSION_DRIVE_SCOPE_GRANTED: { category, event: 'Permissions screen DRIVE_SCOPE granted'},
    PERMISSION_CALENDAR_SCOPE_SHOWN: { category, event: 'Permissions screen CALENDAR_SCOPE shown'},
    PERMISSION_CALENDAR_SCOPE_REQUESTED: { category, event: 'Permissions screen CALENDAR_SCOPE requested'},
    PERMISSION_CALENDAR_SCOPE_GRANTED: { category, event: 'Permissions screen CALENDAR_SCOPE granted'},
    PERMISSION_CALENDAR_SCOPE_CANCELED: { category, event: 'Permissions screen CALENDAR_SCOPE canceled'},
    PERMISSION_SHEETS_SCOPE_SHOWN: { category, event: 'Permissions screen SHEETS_SCOPE shown'},
    PERMISSION_SHEETS_SCOPE_REQUESTED: { category, event: 'Permissions screen SHEETS_SCOPE requested'},
    PERMISSION_SHEETS_SCOPE_GRANTED: { category, event: 'Permissions screen SHEETS_SCOPE granted'},
    PERMISSION_SHEETS_SCOPE_CANCELED: { category, event: 'Permissions screen SHEETS_SCOPE canceled'},
    AFFILIATE_SHOWN: { category, event: 'Affiliate dialog shown'},
    AFFILIATE_HELP_LINK_CLICKED: { category, event: 'Affiliate help link clicked'},
    AFFILIATE_CLOSED: { category, event: 'Affiliate dialog closed'},
    AFFILIATE_LINK_COPIED: { category, event: 'Affiliate link copied'},
    AFFILIATE_FAQ_LINK_CLICKED: { category, event: 'Affiliate faq link clicked' },
    HELP_INVITE_CLICKED: { category, event: 'Invite in help clicked' },
    RELEASE_NOTES_CLICKED: {category, event: 'Release notes in help clicked'},
    INVITE_CANCEL_CLICKED: { category, event: 'Invite cancel clicked' },
    INVITE_INVITE_CLICKED: { category, event: 'Invite send button clicked' },
    EMAIL_SEND_CLICKED: { category, event: 'Send emails button clicked' },
    EMAIL_SEND_CANCELED: { category, event: 'Send emails cancel button clicked' },
    LANDING_SITE_LINK_CLICKED: {category, event: 'GPM landing website link clicked'},
    START_TRIAL_BUTTON_CLICKED: {category, event: 'Start trial button clicked'},
    FREE_VERSION_FAQ_CLICKED: {category, event: 'free version faq clicked'},
    ALLOW_UNLIMITED_CARDS_NOT_PRESENT: {category, event: 'allowUnlimitedCards not present'},
    FREE_LIMIT_SNACKBAR: {category, event: 'more than 10 cards snackbar shown'},
    DEFAULT_VIEW_CHANGED: { category, event: 'default view changed' },
    BROWSER_NOTIFICATIONS_MODAL_SHOWN: { category, event: 'browser notifications modal shown' },
    BROWSER_NOTIFICATIONS_ALLOW_CLICKED: { category, event: 'browser notifications allow clikcked' },
    BROWSER_NOTIFICATIONS_NOT_NOW_CLICKED: { category, event: 'browser notifications not now clicked' },
    CHROME_NOTIFICATIONS_PLUGIN_MODAL_SHOWN: { category, event: 'chrome notifications plugin modal shown' },
    CHROME_NOTIFICATIONS_PLUGIN_ALLOW_CLICKED: { category, event: 'chrome notifications plugin allow clikcked' },
    CHROME_NOTIFICATIONS_PLUGIN_NOT_NOW_CLICKED: { category, event: 'chrome notifications plugin not now clicked' },
    SOCKET_CONNECTION_ERROR: { category, event: 'missed comet messages' },
    SOCKET_CONNECTION_v2_ERROR: { category, event: 'missed comet messages v2' },
    TIP_HOVERED: { category, event: 'Tip hovered' },
    TIP_CLICKED: { category, event: 'Tip clicked' },
    OPEN_LAST_BOARD_ON: { category, event: 'open last board on' },
    OPEN_LAST_BOARD_OFF: { category, event: 'open last board off' },
    OPEN_SPECIFIC_BOARD_CLICKED: { category, event: 'open specific board clicked' },
    MY_FIRST_BOARD_TIME: { category, event: 'my first board loading time' },
    CARD_FORM_START_TRIAL_CLICKED: { category, event: 'Card form start trial clicked' },
    LANGUAGE_MODAL_SHOWN: { category, event: 'Language modal shown' },
    LANGUAGE_MODAL_CLOSED: { category, event: 'Language modal closed' },
    SHARE_MODAL_SHOWED: { category, event: '5 min share modal shown' },
    SHARE_MODAL_CLOSED: { category, event: '5 min share modal closed' },
    SHARE_MODAL_SHARE_CLICKED: { category, event: '5 min share modal share this board clicked' },
    FEATURE_POLL_SHOWED: { category, event: 'csat showed' },
    FEATURE_POLL_SKIPPED: { category, event: 'csat ask me later clicked' },
    FEATURE_POLL_RATED: { category, event: 'csat rated' },
    RELOAD_FROM_COMET: { category, event: 'reload from comet' },
    DEBUG: { category, event: 'debug' },
    PRICING_POLL_SKIPPED: { category, event: 'back from pricing survey ask me later chosen' },
    PRICING_POLL_OWN_OPINION_CLICK: { category, event: 'back from pricing add my own opinion clicked' },
    PRICING_POLL_REVIEW: { category, event: 'back from pricing survey own answer' },
    QUICK_START_GUIDE_LATER: { category, event: 'Explore later clicked' },
    QUICK_START_GUIDE_DONE: { category, event: 'I`ve got it clicked' },
    QUICK_START_CALL_BOOKED: { category, event: 'Quick start book a call clicked' },
    QUICK_START_GUIDE_CLOSED: { category, event: 'Closed clicked' },
    QUICK_START_GUIDE_OPENED: { category, event: 'Quick guide button clicked' },
    QUICK_START_GUIDE_FINISHED: { category, event: 'Quick guide finished' },
    SHARING_ERROR: { category, event: 'Sharing error' },
    NOT_ENOUGH_PERMISSIONS_SHOWN: { category, event: 'Not enough permissions shown' },
    SCROLL_HINT_SHOWN: { category, event: 'Scroll hint shown' },
};

export enum SegmentUserOption {
    CAUSE = 'Cause',
    TAGRET = 'Target',
    QUANTITY = 'Quantity',
    SOURCE = 'Source',
    TYPE = 'Type',
    NEW_ASSIGNED_CARDS = 'New assigned cards',
    UNSEEN_NOTIFICATION_COUNT = 'Unseen notification count',
    TIME = 'time',
    FEATURE = 'Feature',
    RATING = 'Rating',
    TEXT = 'Text',
    TUTORIAL_SHOW_TIME = 'Tutorial show time',
    TUTORIAL_CURRENT_TIME = 'Tutorial current time',
    STATUS = 'Status',
    VALUE = 'Value'
}

export enum SegmentUserOptionValue {
    SHARED_DRIVE = 'shared drive',
    ATTACHMENTS = 'attachments'
}

export enum SegmentUserPlayerValue {
    PLAYING = 'playing',
    PAUSED = 'paused',
    MUTED = 'muted',
    UNMUTED = 'unmuted',
    FULLSCREEN_ON = 'fullscreen on',
    FULLSCREEN_OFF = 'fullscreen off',
}

export enum SegmentUserSourceValue {
    FREE_VERSION_MODAL = 'free version modal',
    START_TRIAL_BUTTON = 'start trial button',
    CARD_FORM_TRIAL_BUTTON ='card form trial button',
}

export enum SegmentUserTypeOption {
    BOARD_OPENING = 'board opening',
    CARD_CREATION = 'card creation',
    VIEW_ONLY_CLICK = 'view only click'
}

export enum SegmentUserLandingSourceValue {
    GOOGLE_WORKSPACE_PM = 'google-workspace-project-management',
    GOOGLE_WORKSPACE_PM_V2 = '/dashboard/google-workspace-project-management-v2',
    TASKMANAGER = '/dashboard/taskmanager',
    KANBAN = '/dashboard/kanban',
    GANTTCHART = '/dashboard/ganttchart',
    BRAZIL = '/dashboard/brazil',
    TM = '/dashboard/tm',
}

export const quickStartGuideStepSegmentEvent = (
    value: string
): ThunkAction => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction({ category, event: `quick guide ${value} clicked` }));
}

export const pricingAnswerSegmentEvent = (
    value: string
): ThunkAction => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction({ category, event: `back from pricing survey ${value} chosen` }));
}
