import { IGetState, TBoardId } from 'app/types/types';
import { Dispatch, ThunkAction } from 'app/types/actions';
import { getRest } from 'app/rest/effects/firstBoard/api/getRest';
import { IFirstBoard } from 'app/rest/effects/firstBoard/types';
import { firstBoardLoadAndSave } from 'app/rest/effects/firstBoard/firstBoardLoadAndSave';
import { firstBoardPrepareAllFirstBoards } from 'app/rest/effects/firstBoard/firstBoardPrepareAllFirstBoards';

export const firstBoardGet = (
    boardId: TBoardId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(getRest(boardId))
            .then((board: IFirstBoard)=>{
                return JSON.parse(board.firstBoardJSON);
            })
    };
    return action;
};

// @ts-ignore
window._firstBoardLoadAndSave = firstBoardLoadAndSave;
// @ts-ignore
window._firstBoardPrepareAllFirstBoards = firstBoardPrepareAllFirstBoards
