import { EAuthUserPlatformType } from 'app/types/rest/IRestAuthUser';

export interface IRestBackup {
    title?: string;
    fileId?: string;
    driveId?: string;
    platformType?: EAuthUserPlatformType;
    type: EBackupType;
    duration?: number,
    created?: number,
    exportType: EBackupExportType
}

export enum EBackupType {
    MANUALLY = 'manually'
}

export enum EBackupExportType {
    XML = 0,
    GOOGLE_SHEET = 1
}

export enum EBackupRepeatType {
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month',
    YEAR = 'year'
}
