import { IGetState, TBoardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { ensureBoardIsFullLoaded } from '../../../../../../../../store/model/effects/ensureBoardIsFullLoaded';
import {
    getProFeaturesTrialActive
} from '../../../../../../../../store/model/authUser/selectors/getProFeaturesTrialActive';
import { authUserSetUsedProFeatures } from '../../../../../../../../rest/effects/authUser/authUserSetUsedProFeatures';

export const onAddClicked = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (getProFeaturesTrialActive(getState())) {
            dispatch(authUserSetUsedProFeatures('allowDependencies'));
        }
        dispatch(segmentTrackAction(SegmentCardEvent.CLICKED_ADD_PREDECESSOR));
        dispatch(ensureBoardIsFullLoaded(boardId));
    };
    return action;
};
