import { IApplicationState } from '../../../../../../types/types';
import { getHintInitial } from '../../hint/selectors/getHintInitial';
import { THintKey } from '../../../types';
import { IHint } from '../../hint/types';

export const getHint = (
    state: IApplicationState,
    key: THintKey
): IHint => {
    const hint = state.hints && state.hints[key] || getHintInitial();
    return hint;
}
