import { IGetState, TCardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { fetchHandler } from '../../../../../../../../util/fetchHandler';
import { REST_CARDS_ASSIGNED_TO_ME, REST_MY_WORK } from '../../../../../../../../rest/constants';
import { getRestHeadersDelete, getRestHeadersPatch } from '../../../../../../../../rest/helpers/getRestHeadersHelper';
import { Util } from '../../../../../../../../util/util';

export const restDelete = (
    cardId: TCardId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return fetchHandler<any>(
            Util.getApiUrl(REST_CARDS_ASSIGNED_TO_ME + '/' + cardId + '/' + REST_MY_WORK), {
                ...getRestHeadersDelete()
            }
        );
    };
    return action;
};
