import { IGetState, TBoardId, TPermissionId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { cardResetInWorkUser } from '../../../../../../../../rest/effects/authUser/inWorkCard/cardResetInWorkUser';
import { getBoard } from '../../../../../../../../store/model/selectors/getBoardById';
import { cardSetListBatch } from '../../../../../../../../rest/effects/card/card/cardSetList';
import { ICard } from '../../../../../../../../store/model/card/types';
import { IAssignee } from '../../../../../../../../store/model/card/types/IAssignee';
import { ICardBatch } from '../../../../../../../../rest/effects/card/card/api/types';
import { authUserSetInWorkCardId } from '../../../../../../../../rest/effects/authUser/authUserSetInWorkCardId';
import { getAuthUser } from '../../../../../../../../store/model/authUser/selectors/getAuthUser';
import { listUpdateMinMaxOrderNumber } from '../../../../../../../../rest/effects/list/listUpdateMinMaxOrderNumber';
import { IAssigneeRest } from '../../../../../../../../types/rest/IAssigneeRest';

export const checkMoveCardToOnHoldList = (
    delAssignerPermissionId: TPermissionId,
    boardId: TBoardId,
    card: ICard,
    cardBatch: ICardBatch
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        if (!delAssignerPermissionId) return;
        if (card.inWorkUsers && card.inWorkUsers.includes(delAssignerPermissionId)) {
            dispatch(cardResetInWorkUser(delAssignerPermissionId, card.id));
            if(getAuthUser(state).permissionId === delAssignerPermissionId) {
                dispatch(authUserSetInWorkCardId(0))
            }
            if (!isInwWorkAssignerExist(card, delAssignerPermissionId)) {
                const board = getBoard(state, boardId);
                if (board.statusLists && board.statusLists.onHoldId) {
                    cardSetListBatch(getState, card.id, board.statusLists.onHoldId, undefined, cardBatch);
                    cardBatch.callbacks.push((card) => {
                        dispatch(listUpdateMinMaxOrderNumber(board.statusLists.onHoldId, [card.orderNumber]));
                    })
                }
            }
        }
    };
    return action;
};

const isInwWorkAssignerExist = (
    card: ICard,
    deletedAssignerPermissionId: TPermissionId
) => {
    if (!card.inWorkUsers) return false;
    let result = false;
    const assignees = [...card.assignees];
    assignees.forEach((user) => {
        if (user.sharedUser.permissionId === deletedAssignerPermissionId) return;
        if (card.inWorkUsers.includes(user.sharedUser.permissionId)) {
            result = true;
        }
    });
    return result;
};
