import { connect } from 'react-redux';
import { ICardActivityEstimateProps } from '../../components/CardActivityEstimate/types';
import { IActivityProps } from '../../../../boardDetails/activity/TabActivity/types';
import { IRestCardCommonActivity } from '../../../../../../../types/rest/activity/IRestCardCommonActivity';
import { IApplicationState } from '../../../../../../../types/types';
import { CardActivityEstimate } from '../../components/CardActivityEstimate/CardActivityEstimate';
import { getBoardWorkHoursInDay } from '../../../../../../../store/model/selectors/getBoardWorkHoursInDay';
import { getBoardIdByCardId } from '../../../../../../../store/model/selectors/getBoardIdByCardId';

const mapStateToProps = (
    state: IApplicationState,
    { activity }: IActivityProps<IRestCardCommonActivity>
): ICardActivityEstimateProps => {
    const boardId = getBoardIdByCardId(state, activity.card.id);
    return {
        activity,
        workHoursInDay: getBoardWorkHoursInDay(state, boardId)
    }
};

export const CardActivityEstimateHOC = connect(
    mapStateToProps,
    null
)(CardActivityEstimate);

CardActivityEstimateHOC.displayName = 'CardActivityEstimateHOC';
