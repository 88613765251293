import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState, TBoardId } from '../../../../types/types';
import { fetchHandler } from '../../../../util/fetchHandler';
import { Util } from '../../../../util/util';
import { getBoardBackupsRestUrl } from '../../../constants';
import { getRestHeadersGet } from '../../../helpers/getRestHeadersHelper';
import { TBoardBackupList } from '../../../../store/model/boardBackups/types';

export const getRest = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return fetchHandler<TBoardBackupList[]>(
            Util.getApiUrl(getBoardBackupsRestUrl(boardId)),
            getRestHeadersGet()
        ).then(response => {
            return response
        });
    };
    return action;
};
