import { ICardDeleteAction } from './types';
import { TCardId } from '../../../../../../types/types';
import { AT_CARD_DELETE } from './constants';

export const cardDeleteAction = (
    cardId: TCardId
): ICardDeleteAction => {
    return {
        type: AT_CARD_DELETE,
        cardId
    }
};
