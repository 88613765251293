import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../../types/types';
import { IArchiveFields } from '../../components/Archive/types';
import { Archive } from '../../components/Archive/Archive';
import { TStatus } from '../../../../../../../../types/model';
import { IArchiveHOCProps } from './types';
import { getBoard } from '../../../../../../../../store/model/selectors/getBoardById';

const mapStateToProps = (
    state: IApplicationState,
    ownProps: IArchiveHOCProps
): IArchiveFields => {
    const { boardId } = ownProps;
    const card = getBoard(state, boardId);
    const isShow = card.status === TStatus.STATUS_ARCHIVE;
    return {
        isShow,
    }
};

export const ArchiveHOC = connect(
    mapStateToProps,
    null
)(Archive);

ArchiveHOC.displayName = 'ArchiveHOC';
