import { IApplicationState, TBoardId } from '../../../../../../../../../types/types';
import { ECardNumberStatus } from '../../cardNumber/types';
import { getCardNumberByBoardId } from './getCardNumberByBoardId';

export const getCardNumberLoading = (
    state: IApplicationState,
    boardId: TBoardId
): boolean => {
    const { status } = getCardNumberByBoardId(state, boardId);
    return status !== ECardNumberStatus.LOADED;
}
