import { TRoomId, TToken } from './SocketProvider';
import { IRoomSubscriber, Room } from './Room';
import { ICloseProvider, RoomProvider } from './RoomProvider';
import { TBoardId } from '../../../types/types';
import { TUserId } from '../../../types/rest/IRestAuthUser';
import { TSubscriptionId } from '../../../types/rest/IRestSubscription';

type IRoomProviders = {
    [roomId: string]: RoomProvider;
};

export class RoomFactory implements ICloseProvider{
    private static _instance: RoomFactory;
    private roomProviders: IRoomProviders = {};

    private constructor() {
        if (!RoomFactory._instance) {
            RoomFactory._instance = this;
        }
        return RoomFactory._instance;
    }

    public static getInstance(){
        return new RoomFactory();
    }

    public closeRoom(roomId: TRoomId): void {
        delete this.roomProviders[roomId];
    }

    public getRoomAndSubscribe(roomId: TRoomId, token: TToken, isBoardRoom: boolean, listener: IRoomSubscriber): Room{
        if (!this.roomProviders[roomId]) {
            this.roomProviders[roomId] = new RoomProvider(roomId, token, isBoardRoom, this);
        }
        return this.roomProviders[roomId].getRoom().addListener(listener);
    }

    getDashboardRoom(dashboardId: TBoardId, listener: IRoomSubscriber, token: TToken) {
        if (dashboardId) {
            // @ts-ignore
            let channelId = window.Settings.cometServerChannelPref + dashboardId;
            return this.getRoomAndSubscribe(channelId, token, true, listener);
        }
    }

    getUserRoom(userId: TUserId, listener: IRoomSubscriber, token: TToken) {
        if (userId) {
            // @ts-ignore
            let channelId = window.Settings.cometServerChannelPref + 'User' + userId;
            return this.getRoomAndSubscribe(channelId, token, false, listener);
        }
    }

    getSystemRoom(listener: IRoomSubscriber, token: TToken) {
        // @ts-ignore
        let channelId = window.Settings.cometServerChannelPref + 'System';
        return this.getRoomAndSubscribe(channelId, token, false, listener);
    }

    getSubscriptionRoom(subscriptionId: TSubscriptionId, listener: IRoomSubscriber, token: TToken) {
        if(subscriptionId){
            // @ts-ignore
            let channelId = window.Settings.cometServerChannelPref + 'Subscription' + subscriptionId;
            return this.getRoomAndSubscribe(channelId, token, false, listener);
        }
    }

    getCachedSubscriptionRoom(subscriptionId: TSubscriptionId) {
        if(subscriptionId){
            // @ts-ignore
            let channelId = window.Settings.cometServerChannelPref + 'Subscription' + subscriptionId;
            return this.roomProviders[subscriptionId]? this.roomProviders[subscriptionId].getRoom() : null ;
        }
    }
}
