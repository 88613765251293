import { root } from 'app/store/constants';
import { Dispatch, ThunkAction } from 'app/types/actions';

export function onClickSubscriptions(
): ThunkAction {
    const action = () => {
        root.App.controller.openSubscriptionManagement();
    };
    return action
};
