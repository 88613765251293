import { IApplicationState, TBoardId } from '../../../../types/types';
import {
    EAsidePanelProperty
} from '../../../../view/react_components/aside_panel/asidePanel/components/AsidePanel/types';
import { getBoardCardProperties } from './getBoardCardProperties';

export const getBoardCardRequiredProperties = (
    state: IApplicationState,
    boardId: TBoardId
): Set<EAsidePanelProperty> => {
    const requiredProperties: Set<EAsidePanelProperty> = new Set;
    const cardProperties = getBoardCardProperties(state, boardId);

    if (cardProperties) {
        cardProperties.forEach((item) => {
            if (item.isRequired) {
                requiredProperties.add(item.property);
            }
        });
    }

    return requiredProperties;
};
