import { IGetState } from '../../../types/types';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { getRest } from './api/getRest';
import { themesActionSet } from '../../../store/model/actions/themesActionSet';
import { libThemesListActionSet } from '../../../store/model/libThemes/actions/libThemesListActionSet';
import { ILibTheme } from '../../../store/model/libThemes/types';

export const loadThemes = (): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(getRest()).then((list: ILibTheme[]) => {
            dispatch(themesActionSet(libThemesListActionSet(list)));
        });
    };
    return action;
};
