import { dispatch, getAppState } from 'app/store/configureStore';
import { getCurrentNavigationPanelType } from '../../main/navigationPanel/helpers/getCurrentNavigationPanelType';
import { ENavigationPanelTypes } from '../../main/navigationPanel/constants';
import { panelTypeSetAction } from '../../main/navigationPanel/store/navigationPanel/actions/panelTypeSetAction';
import { isOpenSetAction } from '../../main/navigationPanel/store/navigationPanel/actions/isOpenSetAction';
import { getTimeTrackerState } from '../../aside_panel/cardDetails/TabTimingSection/TimeTracker/store/selectors/getTimeTrackerState';
import { timeTrackerDeleteAction } from '../../aside_panel/cardDetails/TabTimingSection/TimeTracker/store/actions/timeTrackerDeleteAction';
import { timeTrackerSetAction } from '../../aside_panel/cardDetails/TabTimingSection/TimeTracker/store/actions/timeTrackerSetAction';
import { FAKE_CARD_ID_FOR_WIDGET_PRESENTATION } from 'app/const';
import { getMessages } from 'app/store/constants';
import { getAuthUser } from 'app/store/model/authUser/selectors/getAuthUser';
import { inWorkCardActionSet } from 'app/store/model/actions/inWorkCardActionSet';
import { authUserActionSet } from 'app/store/model/actions/authUserActionSet';
import { updateAction } from 'app/store/model/authUser/actions/updateAction';
import { authUserAddFeatureToSeenMeta } from 'app/rest/effects/authUser/authUserAddFeatureToSeenMeta';
import { EFeature } from 'app/types/rest/IUserMeta';
import { getIsNewFeatureTopbarRedesignTipsSeen } from 'app/store/model/authUser/selectors/featuresSeen/getIsNewFeatureTopbarRedesignTipsSeen';

let navigationPanelType: ENavigationPanelTypes;
let classNavigationPanel: string;

let classNamePage = 'page--knb2502';

/**
 * если navpanel не развернута, развернуть
 */
export const onOpenTipsKNB2502TopbarNavpanel = () => {
    dispatch(authUserAddFeatureToSeenMeta(EFeature.TOPBAR_REDESIGN_TIPS)); // показать один раз

    document.body.classList.add(classNamePage);

    const navigationPanelTypeCurrent = getCurrentNavigationPanelType(getAppState());
    if (navigationPanelTypeCurrent === ENavigationPanelTypes.NP_COMPACT_TYPE) {
        navigationPanelType = navigationPanelTypeCurrent;
        dispatch(panelTypeSetAction(ENavigationPanelTypes.NP_BIG_TYPE));
    }
    dispatch(isOpenSetAction(false));
};

export const onOpenTipsKNB2502TopbarNavpanelMobile = () => {
    let nav = document.querySelector('.navigation-panel-container--mobile');
    if (nav) {
        classNavigationPanel = '--mobile';
        nav.classList.remove('navigation-panel-container' + classNavigationPanel);
        nav.classList.add('navigation-panel-container--compact_big');
        nav = document.querySelector('.navigation-panel' + classNavigationPanel);
        if (nav) {
            nav.classList.remove('navigation-panel' + classNavigationPanel);
            nav.classList.add('navigation-panel--compact_big');
        }
    }
};

export const onOpenTipsKNB2502TopbarNavpanelWidgets = () => {
    const { inWorkCardId } = getAuthUser(getAppState());
    if (!inWorkCardId) {
        dispatch(authUserActionSet(updateAction({
            inWorkCardId: FAKE_CARD_ID_FOR_WIDGET_PRESENTATION,
        })));
        dispatch(inWorkCardActionSet({
            id: FAKE_CARD_ID_FOR_WIDGET_PRESENTATION,
            name: getMessages().getText('updates_tips.fake_widget.working_on'),
        }));
    }

    const timeTrackerState = getTimeTrackerState(getAppState());
    if (!timeTrackerState || !timeTrackerState.cardId) {
        dispatch(timeTrackerSetAction({
            cardId: FAKE_CARD_ID_FOR_WIDGET_PRESENTATION,
            cardName: getMessages().getText('updates_tips.fake_widget.time_tracker'),
            start: Date.now(),
        }));
    }
};

export const onCloseTipsKNB2502TopbarNavpanelWidgets = () => {
    const { inWorkCardId } = getAuthUser(getAppState());
    if (inWorkCardId === FAKE_CARD_ID_FOR_WIDGET_PRESENTATION) {
        dispatch(authUserActionSet(updateAction({
            inWorkCardId: null,
        })));
        dispatch(inWorkCardActionSet(null));
    }

    const timeTrackerState = getTimeTrackerState(getAppState());
    if (timeTrackerState && timeTrackerState.cardId === FAKE_CARD_ID_FOR_WIDGET_PRESENTATION) {
        dispatch(timeTrackerDeleteAction());
    }
};

export const onSeenTipsKNB2502TopbarNavpanel = () => {
    dispatch(authUserAddFeatureToSeenMeta(EFeature.TOPBAR_REDESIGN));
    dispatch(authUserAddFeatureToSeenMeta(EFeature.TOPBAR_REDESIGN_TIPS_SEEN));
}

export const onCloseTipsKNB2502TopbarNavpanel = () => {
    document.body.classList.remove(classNamePage);

    if (navigationPanelType) {
        let nav = document.querySelector('.navigation-panel-container--big');
        if (nav) {
            dispatch(panelTypeSetAction(navigationPanelType));
        }
        navigationPanelType = null;
    }
    if (classNavigationPanel) {
        let nav = document.querySelector('.navigation-panel-container--compact_big');
        if (nav) {
            nav.classList.remove('navigation-panel-container--compact_big');
            nav.classList.add('navigation-panel-container' + classNavigationPanel);
            nav = document.querySelector('.navigation-panel--compact_big');
            if (nav) {
                nav.classList.remove('navigation-panel--compact_big');
                nav.classList.add('navigation-panel' + classNavigationPanel);
            }
        }
        classNavigationPanel = null;
    }
};

export const isNewKNB2502TopbarNavpanel = () => {
    return getIsNewFeatureTopbarRedesignTipsSeen(getAppState());
}
