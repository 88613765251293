import { IGetState, TCardId } from './../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { getCard } from '../../../../../../../../store/model/selectors/getCard';
import {
    getAuthUserIsShowGanttContextHints
} from '../../../../../../../../store/model/authUser/selectors/getAuthUserIsShowGanttContextHints';
import { EHintKey, EHintStatus } from '../../../../../../hints';
import { setHintCanShow } from '../../../../../../hints/effects/setHintCanShow';
import { getHint } from '../../../../../../hints/store/hints/selectors/getHint';

export const onShowHint = (
    cardId: TCardId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        if (
            !getAuthUserIsShowGanttContextHints(state) &&
            (
                getHint(state, EHintKey.GANTT_CONTEXT_SHOW_ON_GANTT).status === EHintStatus.SHOWN ||
                getHint(state, EHintKey.GANTT_CONTEXT_ADD_DATE).status === EHintStatus.SHOWN
            )
        ) {
            return;
        }
        const { dueDate, startDate, ganttVisibility } = getCard(state, cardId);
        if (!ganttVisibility && (startDate || dueDate)) {
            dispatch(setHintCanShow(EHintKey.GANTT_CONTEXT_SHOW_ON_GANTT));
        } else if (!startDate && !dueDate) {
            dispatch(setHintCanShow(EHintKey.GANTT_CONTEXT_ADD_DATE));
        }
    };
    return action;
};
