import { IGetState, TCardId, TPermissionId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { checklistItemDeleteUser } from './checklistItemDeleteUser';
import { getCardChecklists } from '../../../../store/model/checklists/checklists/selectors/getCardChecklists';

export const checklistsDeleteUser = (
    cardId: TCardId,
    permissionId: TPermissionId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const checklists = getCardChecklists(state, cardId);
        if (!checklists) return Promise.reject();

        let promiseList: Promise<any>[] = [];
        for (let checklistId in checklists) {
            const checklist = checklists[checklistId];
            if (checklist.checkItems) for (let itemId in checklist.checkItems) {
                promiseList.push(dispatch(checklistItemDeleteUser(cardId, Number(checklistId), Number(itemId), permissionId)));
            }
        }
        return Promise.all(promiseList);
    };
    return action;
};
