import { stripHtml } from '../base/helpers/stripHtmlHelper';

export const getInitialsHelper = (
    name: string
): string => {
    if (!name || !name.length) return '';
    name = stripHtml(name.trim());
    if (!name.length) return '';
    if (name.length < 3) return name;

    name = name.toLowerCase();

    let letters = name.replace(/[^a-z0-9]/gi, '');
    if (letters.length < 3) return name.substring(0, 1);

    let initials = letters[0]; // первая буква или цифра
    let index = name.indexOf(initials); // находим этот символ в исходном имени

    for (let i=1; i < letters.length; i++) {
        ++index;
        if (letters[i] !== name[index]) { // пока совпадает - значит идем по первому слову, надо найти второе слово
            initials += letters[i];
            return initials;
        }
    }
    if (initials.length < 2) { // если в исходном имени одно слово
        initials += letters[1];
    }

    return initials;
}
