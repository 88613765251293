import { connect } from 'react-redux';
import { IInviteDialogEvents } from '../../components/InviteDialog/types';
import { InviteDialog } from '../../components/InviteDialog/InviteDialog';
import { onCancel } from './events/onCancel';
import { onInvite } from './events/onInvite';

const mapDispatchToProps = (
    dispatch: any
): IInviteDialogEvents => {
    return {
        onCancel: () => dispatch(onCancel()),
        onInvite: (emails) => dispatch(onInvite(emails))
    }
};

export const InviteDialogHOC = connect(
    null,
    mapDispatchToProps
)(InviteDialog);

InviteDialogHOC.displayName = 'InviteDialogHOC';
