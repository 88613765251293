import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../types/types';
import { disallowedSectionClickedEvent } from '../../../../effects/segmentEvents';
import { SegmentCardSection } from '../../../../../../../../middlewares/segment/';
import { showSnoozeBlocker } from '../../../../../../base/effects/showSnoozeBlocker';
import { SegmentCardSourceValue } from 'app/middlewares/segment/trackEntities/cardEvents';

export const onClickDisallow = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(showSnoozeBlocker({
            allow: 'allowSubCard'
        }));
        dispatch(disallowedSectionClickedEvent(SegmentCardSection.SUBCARDS, SegmentCardSourceValue.DETAILS));
    };
    return action;
};
