import { AsidePanelActionsButton } from '../../../..';
import * as React from 'react';
import { IRelatedCardsPanelButton } from './types';
import { CLASS_CARD_DETAILS_SECTION } from '../../../constants';
import { CARD_SECTION_RELATED } from '../CardRelatedSection/constants';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import './_RelatedCardsPanelButton.scss';
import { CLASS_ASIDE_PANEL_ACTIONS_POPUP } from '../../../../asidePanel/components/AsidePanelActionsButton/constants';
import { EAsidePanelProperty } from '../../../../asidePanel/components/AsidePanel/types';
import { getAsidePanelActionsButtonTooltip } from 'app/view/react_components/aside_panel/asidePanel/helpers/getAsidePanelActionsButtonTooltip';

export function RelatedCardsPanelButton({
    isShow,
    relatedCardsCount,
    tooltip,
    onClick
}: IRelatedCardsPanelButton) {
    if (!isShow) return null;

    const { showedProperties } = React.useContext(AsidePanelContext);
    const isShowed = showedProperties.has(EAsidePanelProperty.CARD_RELATED);

    return (
        <AsidePanelActionsButton
            dropdownClassName={`${CLASS_ASIDE_PANEL_ACTIONS_POPUP}--related`}
            indicatorNumber={relatedCardsCount}
            icon={'related-card'}
            isIconHiddenOnFullSize={true}
            sectionSelector={`.${CLASS_CARD_DETAILS_SECTION}--related`}
            title={CARD_SECTION_RELATED}
            tooltip={tooltip && getAsidePanelActionsButtonTooltip({ value: tooltip })}
            onClick={() => onClick(isShowed)}
        >
        </AsidePanelActionsButton>
    );
}
