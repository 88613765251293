import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { SegmentBoardEvent, segmentTrackAction } from '../../../../../../../../../middlewares/segment';
import { TBoardId } from '../../../../../../../../../types/types';
import { SegmentBoardOption } from '../../../../../../../../../middlewares/segment/trackEntities/boardEvents';
import {
    boardSetDefaultStartDateTimeMeta
} from '../../../../../../../../../rest/effects/board/boardSetDefaultStartDateTimeMeta';
import {
    boardSetDefaultDueDateTimeMeta
} from '../../../../../../../../../rest/effects/board/boardSetDefaultDueDateTimeMeta';
import { EDateType } from '../../../../../../../../../const';

export const onDateChange = (
    boardId: TBoardId,
    value: Date,
    type: EDateType
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        const hours = value.getHours();
        const minutes = value.getMinutes();
        const time = `${hours}:${minutes}`;
        if (type === EDateType.START_DATE) {
            dispatch(boardSetDefaultStartDateTimeMeta(boardId, value));
            dispatch(segmentTrackAction(SegmentBoardEvent.START_DATE, {
                name: SegmentBoardOption.VALUE,
                value: time
            }));
        } else {
            dispatch(boardSetDefaultDueDateTimeMeta(boardId, value));
            dispatch(segmentTrackAction(SegmentBoardEvent.DUE_DATE, {
                name: SegmentBoardOption.VALUE,
                value: time
            }));
        }
    };
    return action;
};
