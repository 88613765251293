import * as React from 'react';
import { SettingsSection } from '../../../../../../base/components/SettingsSection/SettingsSection';
import { AsidePanelContext } from '../../../../../asidePanel/components/AsidePanel/constants';
import { CLASS_BOARD_DETAILS_SECTION } from '../../../../constants';
import { ICardPropertiesSectionProps } from './types';
import { BOARD_SECTION_CARD_PROPERTIES } from '../../constants';
import { CardPropertiesHOC } from '../../hocs/CardPropertiesHOC/CardPropertiesHOC';

export function CardPropertiesSection ({
}: ICardPropertiesSectionProps) {
    const { boardId, type } = React.useContext(AsidePanelContext);
    const className = CLASS_BOARD_DETAILS_SECTION + ' ' + CLASS_BOARD_DETAILS_SECTION + '--card-properties';
    return (
        <SettingsSection
            className={className}
            title={BOARD_SECTION_CARD_PROPERTIES}
        >
            <CardPropertiesHOC
                boardId={boardId}
                type={type}
            />
        </SettingsSection>
    );
};
