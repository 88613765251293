import { IApplicationState, TCardId } from '../../../types/types';
import { getCardPinnedSections } from './getCardPinnedSections';
import { getCard } from './getCard';

export const getCardPinnedDescription = (
    state: IApplicationState,
    cardId: TCardId,
): boolean => {
    const cardPinnedSections = getCardPinnedSections(getCard(state, cardId));

    return cardPinnedSections && cardPinnedSections.description || false;
};
