import { AT_COLOR_DELETE, IColorDeleteAction } from './types';
import { TColorId } from '../../../../types/types';

export const deleteColorAction = (
    colorId: TColorId
): IColorDeleteAction => {
    return {
        type: AT_COLOR_DELETE,
        colorId
    }
};
