import { IGetState, TCardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { getCard } from '../../../../store/model/selectors/getCard';
import { getBoardByCardId } from '../../../../store/model/selectors/getBoardByCardId';
import { IBoard } from '../../../../store/model/board/types';
import { ICards } from '../../../../store/model/cards/types';
import { TStatus } from '../../../../types/model';
import { ICard } from '../../../../store/model/card/types';
import { fetchHandler } from '../../../../util/fetchHandler';
import { IRestCard } from '../../../../types/rest/IRestCard';
import { getRestHeadersPost } from '../../../helpers/getRestHeadersHelper';
import { cardsUpdate } from '../../../../store/model/actionCreators/cardsUpdate';
import { cardsActionSetAction } from '../../../../store/model/actions/cardsActionSetAction';
import { cardActionSetAction } from '../../../../store/model/cards/actions/cardActionSetAction';
import { inWorkUsersUpdateAction } from '../../../../store/model/card/actions/inWorkUsersUpdateAction';
import { sendRealTimeStoreActionByCardId } from '../../../../view/react_components/base/helpers/realTimeHelperTS';
import { getList } from '../../../../store/model/list/selectors/getList';
import { getAuthUser } from '../../../../store/model/authUser/selectors/getAuthUser';
import { getListUpdateMinMaxOrderNumber } from '../../../../store/model/list/selectors/getListUpdateMinMaxOrderNumber';
import { listsActionSet } from '../../../../store/model';
import { listActionSet } from '../../../../store/model/lists/actions/listActionSet';
import { listUpdate as listUpdateAction } from '../../../../store/model/list/actions/listUpdate';
import { authUserSetInWorkCardId } from '../../authUser/authUserSetInWorkCardId';

export const checkAndMoveCardOnHoldList = (
    nextInWorkCardId: TCardId = 0
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const user = getAuthUser(state);
        const cardId = user.inWorkCardId;

        if(!cardId) return Promise.resolve();

        const card = getCard(state, cardId);
        const board = getBoardByCardId(state, cardId);
        if (card && board && card.status !== TStatus.STATUS_ACTIVE || isCardInPlace(board, card)) return Promise.resolve();
        const cards: ICards = {
            [cardId]: {
                id: cardId
            }
        };
        return fetchHandler<IRestCard>(
            '/rest/dashboards/cards/' + cardId + '/move-to-onhold-list?nextInWorkCardId=' + nextInWorkCardId, {
                ...getRestHeadersPost(),
            }
        ).then((resp: IRestCard) => {
            if (resp.id && cards[resp.id] && resp.listId) {
                cards[resp.id] = resp;
                const action = cardsUpdate(cards);
                dispatch(action);
                const list = getList(state, cards[resp.id].listId);
                if(list) {
                    dispatch(sendRealTimeStoreActionByCardId(resp.id, action));

                    const update = getListUpdateMinMaxOrderNumber(list, [resp.orderNumber]);
                    if (update) {
                        // т.к. cardAutoDecrement и cardAutoIncrement обновятся в листе на бекенде после запроса,
                        // обновляем их в сторе и отправляем в риалтайм
                        const action = listsActionSet(listActionSet(list.id, listUpdateAction(update)));
                        dispatch(action);
                        dispatch(sendRealTimeStoreActionByCardId(resp.id, action));
                    }
                }
            }
            dispatch(cardsActionSetAction(cardActionSetAction(cardId, inWorkUsersUpdateAction(user.permissionId, false))));
        })
    };
    return action;
};

const isCardInPlace = (board: IBoard, card: ICard) => {
    return board && card && (!board.statusLists || !board.statusLists.onHoldId || board.statusLists.onHoldId === card.listId);
};
