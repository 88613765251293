import { IApplicationState, TBoardId } from '../../../../types/types';
import { TStatus } from '../../../../types/model';
import { ESortType, ICardsTree, IListViewCard } from '../store/listViewBoard/types';
import { getOrderType } from './getOrderType';
import { getCardField } from '../constants';
import { sortCardsByDate } from '../helpers/sortCardsByDate';
import { getSortType } from './getSortType';
import { sortCardsByListViewOrderNumber } from '../helpers/sortCardsByListViewOrderNumber';
import { getIsCardMatchFilters } from '../../aside_panel/filterPanelBoard/store/filterMatch/selectors/getIsCardMatchFilters';
import { getListCardIds } from '../../../../store/model/selectors/getListCardIds';
import { createDeepEqualSelector } from '../../helpers/memoizeHelper';
import { getBoardListIds } from '../../../../store/model/selectors/getBoardListIds';
import { getCard } from '../../../../store/model/selectors/getCard';
import { getListName } from '../../../../store/model/selectors/getListName';

const getBoardListViewCards = (
    state: IApplicationState,
    boardId: TBoardId,
): IListViewCard[] => {
    const cards: IListViewCard[] = [];
    getBoardListIds(state, boardId).forEach((
        listIdIndex
    ) => {
        getListCardIds(state, listIdIndex).forEach((
            cardId
        ) => {
            const card = getCard(state, cardId);
            if(card) {
                const filter = (
                    card.status === TStatus.STATUS_ACTIVE &&
                    getIsCardMatchFilters(state, card.id)
                );
                if (filter) {
                    cards.push({
                        ...card,
                        listName: getListName(state, card.listId)
                    });
                }
            }
        });
    });
    return cards;
}

type TgetCardsFlat = (
    state: IApplicationState,
    boardId: TBoardId,
) => ICardsTree;

/**
 * отдаёт плоский массив всех cardIds для доски,
 * отсортированный по ListViewOrderNumber в режиме Manual или по выбранному полю или по-умолчанию по created
 */
export const getCardsFlat: TgetCardsFlat = createDeepEqualSelector(
    (state: IApplicationState) => state,
    (state: IApplicationState, boardId: TBoardId) => boardId,
    getBoardListViewCards,
    getOrderType,
    getSortType,
    (state, boardId, cards, orderType, sortType) => {
        let cardsSorted: ICardsTree = {};
        const isManual = sortType === ESortType.MANUAL;
        let sortFunction;
        if (isManual) {
            sortFunction = (cardA: IListViewCard, cardB: IListViewCard) =>
                sortCardsByListViewOrderNumber(cardA, cardB);
        } else if (orderType && orderType.field) {
            sortFunction = (cardA: IListViewCard, cardB: IListViewCard) =>
                getCardField(orderType.field).sortFunction(state, boardId, cardA, cardB, orderType);
        } else {
            sortFunction = (cardA: IListViewCard, cardB: IListViewCard) =>
                sortCardsByDate(cardA, cardB);
        }

        Object.values(cards)
            .sort(sortFunction)
            .forEach(card => {
                cardsSorted[card.id] = {};
            });

        return cardsSorted;
    }
)
