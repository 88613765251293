import { IABTest } from '../types';
import { IABTestAvailableRest } from '../rest/types';

// заполняем поле collected из REST
export const fillABTestsCollectedValue = (
    abTests: IABTest[],
    available: IABTestAvailableRest[]
): IABTest[] => {
    return abTests.map((test) => {
        const availableTest = available.find((availableTest) => availableTest.name === test.name);
        test.collected = availableTest ? availableTest.collected : 0;
        return test;
    });
};
