import { getMessages } from '../../../../../../../store/constants';

export const CARD_CHECKLIST_CONVERT_TO_SUBCARD = getMessages().getText('card_form_checklist_convert_to_subcard');
export const CARD_CHECKLIST_CONVERT_TO_SUBCARD_MILESTONE = getMessages().getText('card_form_checklist_convert_to_subcard_milestone');
export const CARD_CHECKLIST_WEIGHT = getMessages().getText('card_form_checklist_weight');
export const CARD_CHECKLIST_ESTIMATE = getMessages().getText('card_form_section_estimate_label_text');
export const CARD_CHECKLIST_ASSIGN_USER = getMessages().getText('card_form_checklist_assign_user');
export const CARD_CHECKLIST_SET_DATES = getMessages().getText('card_form_checklist_set_dates');
export const CARD_CHECKLIST_SET_DATES_START = getMessages().getText('card_form_checklist_set_dates_start');
export const CARD_CHECKLIST_SET_DATES_DUE = getMessages().getText('card_form_checklist_set_dates_due');
export const CHECKLIST_DELETE_BUTTON_TOOLTIP_TEXT = getMessages().getText('card_form_checklist_delete');
