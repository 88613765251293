import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { SegmentBoardEvent, segmentTrackAction } from '../../../../../../../middlewares/segment';
import { closePanel } from '../../../effects/closePanel';

export const onClose = (): ThunkAction => {
    const action = (dispatch: Dispatch) => {
        dispatch(segmentTrackAction(SegmentBoardEvent.BOARD_DETAILS_CLOSE_CLICKED));
        dispatch(closePanel());
    };
    return action;
};
