import { IApplicationState } from '../../../../../../../../types/types';
import { ISectionTogglerEvents, ISectionTogglerFields } from '../../../../../../base/components/SectionToggler/types';
import { getBoard } from '../../../../../../../../store/model/selectors/getBoardById';
import { getSectionType } from '../../../../selectors/getSectionType';
import { ESettingsSectionType } from '../../../../../../base/components/SettingsSection/types';
import { connect } from 'react-redux';
import { SectionToggler } from '../../../../../../base/components/SectionToggler/SectionToggler';
import { IArchiveOnDeleteTogglerHOCProps } from './types';
import { onChange } from './events/onChange';
import { onClickDisallow } from './events/onClickDisallow';

const mapStateToProps = (
    state: IApplicationState,
    { boardId }: IArchiveOnDeleteTogglerHOCProps
): ISectionTogglerFields => {
    const { archiveCardsOnDelete: checked } = getBoard(state, boardId);
    const sectionType = getSectionType(state, boardId, null, 'allowArchive');
    const isDisabled = sectionType === ESettingsSectionType.READONLY;
    const isDisallowed = !checked && sectionType === ESettingsSectionType.DISALLOW;
    return {
        checked,
        isDisabled,
        isDisallowed,
    };
};

const mapDispatchToProps = (
    dispatch: any,
    { boardId }: IArchiveOnDeleteTogglerHOCProps
): ISectionTogglerEvents => {
    return {
        onChange: () => dispatch(onChange(boardId)),
        onClickDisallow: () => dispatch(onClickDisallow())
    }
};

export const ArchiveOnDeleteTogglerHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(SectionToggler);

ArchiveOnDeleteTogglerHOC.displayName = 'ArchiveOnDeleteTogglerHOC';
