import { combineReducers } from 'redux';
import { ICardPanelState } from './types';
import { cardCommentsReducer } from '../../CommentsSection/store/reducers/cardCommentsReducer';
import { cardDescriptionUnsavedReducer } from '../../DescriptionSection/store/cardDescriptionUnsaved/reducers/cardDescriptionUnsavedReducer';
import { notFilledCardsReducer } from './notFilledCardsReducer';
import { cardNameReducer } from '../../Name/store/cardNameReducer/reducers/cardNameReducer';
import { cardColorReducer } from '../../ColorTags/store/reducers/cardColorsReducer';
import { cardActivityReducer } from '../../TabActivity/store/reducers/cardActivityReducer';

export const cardPanelReducer = combineReducers<ICardPanelState>({
    comments: cardCommentsReducer,
    cardDescriptionUnsaved: cardDescriptionUnsavedReducer,
    notFilledCards: notFilledCardsReducer,
    cardName: cardNameReducer,
    cardColors: cardColorReducer,
    cardActivity: cardActivityReducer
});
