import { ThunkAction, Dispatch } from '../../../../types/actions';
import { THintKey } from '../types';
import { hintActionSetAction } from '../store/hints/actions/hintActionSetAction';
import { hintStepSetAction } from '../store/hint/actions/hintStepSetAction';
import { hintSave } from './hintSave';

export const hintTutorialStep = (
    key: THintKey,
    step: number
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(hintActionSetAction(key, hintStepSetAction(step)));
        return dispatch(hintSave(key));
    };
    return action;
};
