import { IApplicationState } from '../../../../../../../types/types';
import { connect } from 'react-redux';
import { ICardMoveToButtonHOCOwnProps } from './types';
import { getPanelButtonShow } from '../../../selectors/getPanelButtonShow';
import { CardMoveToButton } from '../../components/CardMoveToButton/CardMoveToButton';
import { ICardMoveToButtonEvents, ICardMoveToButtonFields } from '../../components/CardMoveToButton/types';
import { onOpened } from './events/onOpened';
import { MOVE_TO_TOOLTIP } from '../../components/CardMoveToButton/constants';
import { CLASS_NAME_BUTTON_HIDE_TITLE, MIN_WIDTH_DISPLAY_MOVE_TO } from '../../../ActionsInHeadSection/constants';
import { getAsidePanelEntityPanelWidth } from '../../../../asidePanel/store/selectors/getAsidePanelEntityPanelWidth';
import * as _ from 'underscore';

const mapStateToProps = (
    state: IApplicationState,
    { cardId, boardId }: ICardMoveToButtonHOCOwnProps,
): ICardMoveToButtonFields => {
    const isShow = getPanelButtonShow(state, boardId, cardId);
    const panelWidth = getAsidePanelEntityPanelWidth(state);
    const isHideTitle = panelWidth < MIN_WIDTH_DISPLAY_MOVE_TO;
    const tooltip = isHideTitle
        ? MOVE_TO_TOOLTIP
        : null;

    return {
        isShow,
        tooltip,
        className: isHideTitle ? CLASS_NAME_BUTTON_HIDE_TITLE : ''
    };
};

const mapDispatchToProps = (
    dispatch: any,
    { boardId }: ICardMoveToButtonHOCOwnProps,
): ICardMoveToButtonEvents => {
    return {
        onOpened: () => dispatch(onOpened(boardId))
    }
};

export const CardMoveToButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardMoveToButton);
