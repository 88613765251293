import {cardToggleAssignee, EToggleAssigneeType} from '../../AssigneesSectionHOC/effects/cardToggleAssignee';
import {Dispatch, ThunkAction} from '../../../../../../../../types/actions';
import {IGetState, TBoardId, TCardId, TPermissionId} from '../../../../../../../../types/types';
import {getCardAssignees} from '../../../../../../../../store/model/selectors/getCardAssignees';
import {ASSIGNEES_SHARE_ID} from '../../../components/AssigneesSearchSelect/constants';
import {shareClick} from '../../../../../../main/topBar/hocs/sharing/google/TopBarSharingHOC/effect/shareClick';
import {SegmentCardEvent, segmentTrackAction} from '../../../../../../../../middlewares/segment';
import {deselectUser} from '../effects/deselectUser';
import {selectUser} from '../effects/selectUser';
import {getAssigneesSearchSelectOptions} from '../selectors/getAssigneesSearchSelectOptions';
import { root } from 'app/store/constants';
import { getAuthUserPlatformType } from 'app/store/model/authUser/selectors/getAuthUserPlatformType';
import { EAuthUserPlatformType } from 'app/types/rest/IRestAuthUser';

export const onToggle = (
    boardId: TBoardId,
    cardIds: TCardId[],
    permissionId: TPermissionId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        if (permissionId === ASSIGNEES_SHARE_ID) {
            dispatch(segmentTrackAction(SegmentCardEvent.ASSIGNEES_SHARE_CLICKED));
            const platformType = getAuthUserPlatformType(getState());
            switch (platformType) {
                case EAuthUserPlatformType.MICROSOFT:
                    return root.App.controller.mainView.showSharingDialog(boardId);
                default:
                    return dispatch(shareClick(boardId));
            }
        } else {
            if (cardIds.length === 1) {
                const assignees = [...getCardAssignees(getState(), cardIds[0])];
                const index = assignees.findIndex(assignee => assignee.sharedUser.permissionId === permissionId);
                return dispatch(cardToggleAssignee(boardId, cardIds[0], permissionId, index >= 0 ? EToggleAssigneeType.DELETE : EToggleAssigneeType.ADD));
            } else {
                let availableElements = getAssigneesSearchSelectOptions(getState(), boardId, cardIds);
                if (availableElements[0] && availableElements[0].options) {
                    availableElements = availableElements.reduce((availableElements, group) => {
                        return [...availableElements, ...group.options];
                    }, []);
                }

                const item = availableElements.find(item => item.value === permissionId);
                if (item) {
                    if (item.active) {
                        dispatch(deselectUser(boardId, permissionId));
                    } else {
                        dispatch(selectUser(boardId, permissionId));
                    }
                }
            }
        }
    };
    return action;
};
