import { IAssignee } from '../../../../../store/model/card/types/IAssignee';
import { ICardAssigneeRole } from '../../../../../types/model';
import { ISharedUser } from '../../../../../types/rest/ISharedUser';
import { ITooltipInheritedProps } from 'kui';

export interface IUsersTooltipFields {
    authUserPermissionId?: string;
    isBig?: boolean; // 1-2 юзерпика, когда больше 2 -> 1 юзерпик и +
    isTooltip?: boolean;
    roles?: ICardAssigneeRole[];
    boardUsers?: ISharedUser[];
    users: IAssignee[];
    naming?: TUserTooltipNaming;
}

export interface IUsersTooltipEvents {}

export interface IUsersTooltipProps extends
    Omit<ITooltipInheritedProps, 'value'>,
    IUsersTooltipFields,
    IUsersTooltipEvents
{}

export enum TUserTooltipNaming {
    AUTHOR = 'author',
    ASSIGNEE = 'assignee'
}
