import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TBoardId, TCardId } from '../../../../../../../../types/types';
import { root } from '../../../../../../../../store/constants';
import { SegmentCardEvent, SegmentCardOptions, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { SegmentCardSection } from '../../../../../../../../middlewares/segment/trackEntities/cardEvents';
import { getCard } from '../../../../../../../../store/model/selectors/getCard';
import { getCardRelatedCardsActive } from '../../../../../../../../store/model/selectors/getCardRelatedCardsActive';
import { getBoardIsCardDefaultProperty } from '../../../../../../../../store/model/board/selectors/getBoardIsCardDefaultProperty';
import { EAsidePanelProperty } from '../../../../../asidePanel/components/AsidePanel/types';
import { getIsAssignedToMeActive } from '../../../../../../../../store/router/selectors/getIsAssignedToMeActive';
import { onShowSnackbar } from '../../CardRelatedSectionHOC/events/onShowSnackbar';
import { getIsLinkCardActive } from '../../../../../../../../store/router/selectors/getIsLinkCardActive';

export const onClick = (
    cardId: TCardId,
    boardId: TBoardId,
    isShowed: boolean,
    ownOnClick?: () => void,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const card = getCard(getState(), cardId);
        const isFirst = !card.relatedCardCount &&
            !getBoardIsCardDefaultProperty(getState(), boardId, EAsidePanelProperty.CARD_RELATED);
        dispatch(segmentTrackAction(SegmentCardEvent.BUTTONS_PANEL_BUTTON_CLICKED, {
            name: SegmentCardOptions.TARGET,
            value: SegmentCardSection.REALTED_CARDS
        }, [{
            name: SegmentCardOptions.FIRST,
            value: String(isFirst)
        }]));

        if (ownOnClick) ownOnClick();

        if (!card.relatedCards.length && getIsAssignedToMeActive(state) || getIsLinkCardActive(state)) {
            dispatch(onShowSnackbar(cardId, boardId));
        } else if (!isShowed) {
            const cardRelatedsActive = getCardRelatedCardsActive(state, cardId);
            if (!cardRelatedsActive || !cardRelatedsActive.length) {
                root.App.controller.showRelatedCardsPanel(cardId, boardId);
            }
        }
    };
    return action;
};
