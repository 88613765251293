import { IApplicationState } from '../../../../types/types';
import { IRecentBoard } from '../../../../types/rest/IUserMeta';
import { getUserRecentBoards } from './getUserRecentBoards';

export const getUserRecentBoard = (
    state: IApplicationState,
    boardId: number,
): IRecentBoard => {
    return getUserRecentBoards(state).find(board => board.id === boardId);
};
