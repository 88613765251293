import { SettingsSection } from '../../../../../base/components/SettingsSection/SettingsSection';
import * as React from 'react';
import {
    CARD_ESTIMATE_AND_SPENT_TIME,
    CARD_SPENT_TIME_LABEL_TEXT,
} from './constants';
import { ICardEstimateAndSpentTimeProps } from './types';
import { Input } from 'kui';
import './_CardEstimateAndSpentTime.scss';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { CLASS_CARD_DETAILS_SECTION, CLASS_CARD_DETAILS } from '../../../constants';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { EAsidePanelProperty } from '../../../../asidePanel/components/AsidePanel/types';
import { CardEstimate } from '../CardEstimate/CardEstimate';

export function CardEstimateAndSpentTimeSection({
    estimateStr,
    isDefaultProperty,
    isRequired,
    spentTime,
    onClose,
    onEstimateChange,
    sectionType,
    workHoursInDay,
    onEstimateError
}: ICardEstimateAndSpentTimeProps) {
    if (!estimateStr && !spentTime && sectionType === ESettingsSectionType.READONLY) return;

    const isPopup = sectionType === ESettingsSectionType.POPUP;
    const className = CLASS_CARD_DETAILS_SECTION + ' ' + CLASS_CARD_DETAILS_SECTION + '--estimate';
    const CLASS_CARD_DETAILS_TIMES = CLASS_CARD_DETAILS + '-times';
    const classNameEstimate = CLASS_CARD_DETAILS_TIMES + '__estimate';
    const { openedProperty, addShowedProperty } = React.useContext(AsidePanelContext);
    const [isShow, setShow] = React.useState(false);

    React.useEffect(() => {
        if (estimateStr || spentTime || isDefaultProperty) {
            setShow(true);
            addShowedProperty(EAsidePanelProperty.CARD_ESTIMATE);
        }
    }, [estimateStr, spentTime, isDefaultProperty]);

    if (
        !isShow &&
        !(isPopup && openedProperty === EAsidePanelProperty.CARD_ESTIMATE)
    ) {
        return;
    }

    return (
        <SettingsSection
            className={ className }
            focusSelector={'.' + classNameEstimate}
            sectionType={ sectionType }
            title={ CARD_ESTIMATE_AND_SPENT_TIME }
            titleButton={ null }
            onAddClosed={ onClose }
        >
            <div className={ 'card-details-times' }>
                <CardEstimate
                    estimateStr={estimateStr}
                    sectionType={sectionType}
                    workHoursInDay={workHoursInDay}
                    onEstimateChange={onEstimateChange}
                    onError={onEstimateError}
                    isRequired={isRequired}
                />
                <Input
                    className={ 'card-details-times__spent' }
                    label={ CARD_SPENT_TIME_LABEL_TEXT }
                    placeholder={ '0' }
                    readOnly={ true }
                    autosize={ false }
                    value={ spentTime }
                />
            </div>
        </SettingsSection>
    );
}
