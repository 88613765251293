import * as React from 'react';
import './_CardAttachmentDropPanel.scss';
import { ICardAttachmentDropPanelProps } from './types';
import { CLASS_CARD_DETAILS, CLASS_CARD_DETAILS_SECTION } from '../../../constants';
import { CLASS_ASIDE_DROP_PORTAL } from '../../../../asidePanel/constants';
import { getParentsClasses } from '../../../../../helpers/getParentsClasses';
import { ACTIVE_SECTION_TIMER, CLASS_ASIDE_PANEL_ACTIONS_BUTTON_HIGHLIGHT } from '../../../../asidePanel/components/AsidePanelActionsButton/constants';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { EAsidePanelProperty } from '../../../../asidePanel/components/AsidePanel/types';
import { CLASS_COMMENTS_SECTION_INPUT } from '../../../CommentsSection/components/CommentsInput/constants';
import { TonUpload } from '../../types';
import { CLASS_COMMENTS_SECTION_FORM } from '../../../CommentsSection/constants';

export function CardAttachmentDropPanel ({
    onDropFiles,
    onPasteFile,
    onPasteFileAsComment
}: ICardAttachmentDropPanelProps) {
    const [progress, setProgress] = React.useState(null);
    const [s] = React.useState<any>({});
    s.progress = progress; // для addEventListener onDragEnd

    const { openedProperty: openedPropertyContext, setOpenedProperty: setOpenedPropertyContext, cardId } = React.useContext(AsidePanelContext);
    const [openedProperty, setOpenedProperty] = React.useState(openedPropertyContext);
    s.openedProperty = openedProperty; // геттер для openedPropertyContext для onDropFiles
    React.useEffect(() => {
        setOpenedProperty(openedPropertyContext);
    }, [openedPropertyContext]);

    const [isDroppable, setDroppable] = React.useState(false);
    const highlightTimer = React.useRef(null);

    const className = CLASS_CARD_DETAILS + '-attachment__droppable';
    const classNameDragging = CLASS_ASIDE_DROP_PORTAL + '--dragging';
    const classNameDroppable = isDroppable ? className + '--droppable' : '';
    const classNameProgress = CLASS_CARD_DETAILS + '-attachment__droppable-progress';
    const classNameHint = CLASS_CARD_DETAILS + '-attachment__droppable-hint';
    const classAttachmentSectionId = CLASS_CARD_DETAILS_SECTION + `-attachments--${cardId}`;

    const onDragOver = (event: any) => {
        event.preventDefault();
        setDroppable(true);
    }
    const onDragLeave = (event: any) => {
        if (progress !== null) return;

        const targetClasses = getParentsClasses(
            event.relatedTarget as HTMLElement,
            [className]
        );
        if (targetClasses.includes(className)) return;

        setDroppable(false);
    }
    const onDrop = (event: any) => {
        event.preventDefault();
        if (progress !== null) return;

        const files = event.dataTransfer.files;
        if (files && files.length) {
            setProgress(0);
            const isPopup = s.openedProperty === EAsidePanelProperty.CARD_ATTACHMENTS;
            onDropFiles(files, isPopup, onProgress)
                .finally(onFinish);
        }
    }
    const onProgress = (percent: number) => {
        setProgress(Math.round(percent));
    }
    const onFinish = (asComment: boolean = false) => {
        const container = getContainer();
        if (container) container.classList.remove(classNameDragging);
        setDroppable(false);
        setProgress(null);

        if (asComment) {
            const activeElement = document.activeElement as HTMLElement;
            if (activeElement) activeElement.blur();
        } else {
            // scrollToSection from AsidePanelActionsButton
            const selector = `.${CLASS_CARD_DETAILS_SECTION}--attachments.${classAttachmentSectionId}`;
            const section = document.querySelector(selector) as HTMLElement;
            if (!section) return;
                const highlighted = document.querySelector('.' + CLASS_ASIDE_PANEL_ACTIONS_BUTTON_HIGHLIGHT);
                if (highlighted) highlighted.classList.remove(CLASS_ASIDE_PANEL_ACTIONS_BUTTON_HIGHLIGHT); // если ещё предыдущая секция подсвечивается
                section.classList.add(CLASS_ASIDE_PANEL_ACTIONS_BUTTON_HIGHLIGHT); // подсветить секцию
                section.scrollIntoView();

                if (highlightTimer.current) clearTimeout(highlightTimer.current);
                highlightTimer.current = setTimeout(() => {
                    section.classList.remove(CLASS_ASIDE_PANEL_ACTIONS_BUTTON_HIGHLIGHT); // убрать подсветку
                }, ACTIVE_SECTION_TIMER * 2);
            }
        const isPopup = s.openedProperty === EAsidePanelProperty.CARD_ATTACHMENTS;
        if (isPopup) setOpenedPropertyContext(null);
    }

    const getContainer = () => {
        return document.querySelector('.' + CLASS_ASIDE_DROP_PORTAL) as HTMLElement;
    }

    React.useEffect(() => {

        const onDrag = (event: any) => {
            if (
                !event ||
                !event.dataTransfer ||
                !event.dataTransfer.types ||
                !event.dataTransfer.types.length ||
                !event.dataTransfer.types.includes('Files')
            ) return;

            const container = getContainer();
            if (container) container.classList.add(classNameDragging);
        }
        const onDragEnd = (event: any) => {
            if (s.progress !== null) return;

            const targetClasses = getParentsClasses(
                event.relatedTarget as HTMLElement,
                ['page']
            );
            if (targetClasses.includes('page')) return;

            const container = getContainer();
            if (container) container.classList.remove(classNameDragging);
            setDroppable(false);
        }
        const onPaste = (event: any) => {
            if (
                !event.clipboardData ||
                !event.clipboardData.items ||
                !event.clipboardData.items.length
            ) return;

            const items = event.clipboardData.items;
            for (let i = 0; i < items.length; i++) {
                if (items[i].type.indexOf('image') > -1) {
                    event.preventDefault();
                    const file = items[i].getAsFile();
                    if (file) {
                        const container = getContainer();
                        if (container) container.classList.add(classNameDragging);
                        setProgress(0);
                        const activeDocument = document.activeElement;
                        const commentInput = document.querySelector(`.${CLASS_COMMENTS_SECTION_INPUT} textarea`) ||
                            document.querySelector(`.${CLASS_COMMENTS_SECTION_FORM} .ql-clipboard`);
                        const asComment = activeDocument === commentInput;
                        const onPasteFunc: TonUpload = asComment ? onPasteFileAsComment : onPasteFile;
                        return onPasteFunc([file], onProgress)
                            .finally(() => onFinish(asComment));
                    }
                }
            }
        }

        document.addEventListener('dragover', onDrag);
        document.addEventListener('dragleave', onDragEnd);
        document.addEventListener('dragend', onDragEnd);
        document.addEventListener('paste', onPaste);

        return () => {
            document.removeEventListener('dragover', onDrag);
            document.removeEventListener('dragleave', onDragEnd);
            document.removeEventListener('dragend', onDragEnd);
            document.removeEventListener('paste', onPaste);
            if (highlightTimer.current) clearTimeout(highlightTimer.current);
        }
    }, []);

    return (
        <div
            className={`
                ${className}
                ${classNameDroppable}
            `}
            onDragEnter={onDragOver}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
        >
            {progress === null
                ? <div className={classNameHint}>
                    {isDroppable
                        ? 'Drop it!'
                        : 'Drag here...'
                    }
                </div>
                : progress
                    ? <div className={classNameProgress}>
                        {progress} %
                    </div>
                    : <div className={classNameHint}>
                        Uploading...
                    </div>
            }
        </div>
    )
}
