import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { changeMentionSubstring } from '../effects/changeMentionSubstring';

export const onChangeMention = (
    key: string,
    selectionStart: number
): ThunkAction => {
    return (dispatch: Dispatch) => {
        dispatch(changeMentionSubstring(selectionStart));
    };
};
