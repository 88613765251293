import { TSegmentTrackPayload } from './types';

const category = 'Question';

export const SegmentQuestionEvent = {
    SKIPPED: { category, event: 'Customer evaluation form skipped' },
    SUBMITTED: { category, event: 'Customer evaluation form submitted' },
    REOPENED: { category, event: 'Customer evaluation form re-opened' },
    WORK_APP_SEARCH: { category, event: 'work app search' },
    DECISION_MAKER: { category, event: 'decision-maker'},
    NEED_HELP: { category, event: 'need help'},
    TEAMMATES: { category, event: 'teammates'},
    ROLE: { category, event: 'role'},
    IMPORTANT_FACTOR: { category, event: 'important factor'},
    USE_CASE: { category, event: 'use case'},
    INTERVIEW: { category, event: 'interview'},
    ORG_TYPE: { category, event: 'org type' },
    HELP_NEED: { category, event: 'need help' },
    HELP_FORM_SALES_EMAILS: { category, event: 'help form sales emails' },
    HELP_FORM_HELP_TO_USE: { category, event: 'help form use' },
    HELP_FORM_VALUE_SHOW: { category, event: 'help form value show' },
    HELP_FORM_CHECK_HOW_I_DO: { category, event: 'help form check how I\'m doing' },
    HELP_FORM_DEMO_CALL: { category, event: 'help form demo call' },
    HELP_FORM_PHONE_CALL: { category, event: 'help form phone call' },
    HELP_FORM_PHONE_NUMBER: { category, event: 'help form phone number' },
    RATE_KANBANCHI_REQUIREMENTS: { category, event: 'Kanbanchi capabilities meet my requirements' },
    RATE_KANBANCHI_USAGE: { category, event: 'Kanbanchi is easy to use' },
    RATE_KANBANCHI_ACCEPTABILITY: { category, event: 'Kanbanchi is aesthetically attractive and acceptable to me' },
    RATE_KANBANCHI_REASON: { category, event: 'Why do you rate Kanbanchi this way' },
    MESSENGER_POLL_SUBMIT: { category, event: 'Messenger poll submitted' },
};

export enum ESegmentQuestionEventOptions {
    questions = 'questions',
    value = 'value',
    answer = 'answer'
}

export const getQuestionImportantFactorEvent = (level: number): TSegmentTrackPayload => {
    return { category, event: 'important factor_' + level};
}

export const getQuestionCompetitorEvent = (number: number): TSegmentTrackPayload => {
    return { category, event: 'competitor ' + number};
}
