import { TCondition } from '../types';
import { IApplicationState } from '../../../../types/types';
import { EHintKey, getHintConfig } from '../constants';
import { getAsidePanelIsMobile } from 'app/view/react_components/aside_panel/asidePanel/store/selectors/getAsidePanelIsMobile';
import { getNavigationPanelState } from 'app/view/react_components/main/navigationPanel/store/selectors/getNavigationPanelState';
import { ENavigationPanelTypes } from 'app/view/react_components/main/navigationPanel/constants';

export const boardScrollCondition: TCondition = (
    state: IApplicationState
) => {
    const hint = getHintConfig(EHintKey.BOARD_SCROLL);
    if (!hint) return false;

    if (getAsidePanelIsMobile(state)) return false;

    const navPanel = getNavigationPanelState(state);

    return navPanel && navPanel.panelType === ENavigationPanelTypes.NP_BIG_TYPE;
};
