import { IGetState } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IRestChecklist } from '../../../../types/rest/IRestChecklist';
import { ICards } from '../../../../store/model/cards/types';
import { cardsActiveRequestsUpdate } from '../../../../store/requestsState/effects/cardsActiveRequestsUpdate';
import { restPatch } from './api/restPatch';
import { getChecklist } from '../../../../store/model/checklists/checklists/selectors/getChecklist';
import { checklistUpdateStore } from '../../../../store/model/checklists/actionCreators/checklistUpdate';
import { IChecklist } from '../../../../store/model/checklists/checklist/types';
import { sendRealTimeStoreActionByCardId } from '../../../../view/react_components/base/helpers/realTimeHelperTS';

export const checklistUpdate = (
    checklist: Partial<IChecklist>
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (!checklist) return Promise.reject();

        const { cardId, id: checklistId }  = checklist;
        const state = getState();
        const cards: ICards = {
            [cardId]: null
        };

        const checklistForRollback = {...getChecklist(state, cardId, checklistId)};
        const { checkItems, ...rest } = checklist;

        dispatch(checklistUpdateStore(cardId, rest)); // optimistic set
        dispatch(cardsActiveRequestsUpdate(cards, 1));

        return dispatch(restPatch(rest as Partial<IRestChecklist>))
            .then((response: IRestChecklist) => {
                dispatch(sendRealTimeStoreActionByCardId(cardId, checklistUpdateStore(cardId, response)));
                return response;
            })
            .catch((e: any) => {
                console.error(e);
                dispatch(checklistUpdateStore(cardId, checklistForRollback));
            })
            .finally(() => {
                dispatch(cardsActiveRequestsUpdate(cards, -1));
            });
    };
    return action;
};
