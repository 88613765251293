import { getMessages } from '../../../../../../../store/constants';

export const CALENDAR_YEARS_LENGTH = new Date().getFullYear() * 2;

export const CALENDAR_MONTH_OPTIONS = [
    getMessages().getText('datepicker.calendar.january'),
    getMessages().getText('datepicker.calendar.february'),
    getMessages().getText('datepicker.calendar.march'),
    getMessages().getText('datepicker.calendar.april'),
    getMessages().getText('datepicker.calendar.may'),
    getMessages().getText('datepicker.calendar.june'),
    getMessages().getText('datepicker.calendar.july'),
    getMessages().getText('datepicker.calendar.august'),
    getMessages().getText('datepicker.calendar.september'),
    getMessages().getText('datepicker.calendar.october'),
    getMessages().getText('datepicker.calendar.november'),
    getMessages().getText('datepicker.calendar.december')
]
