import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { SegmentBoardEvent, segmentTrackAction } from '../../../../../../../../../middlewares/segment';
import { TBoardId } from '../../../../../../../../../types/types';
import { boardSetWorkHoursInDay } from '../../../../../../../../../rest/effects/board/boardSetWorkHoursInDay';
import { SegmentBoardOption } from '../../../../../../../../../middlewares/segment/trackEntities/boardEvents';

export const onChange = (
    boardId: TBoardId,
    value: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        const workHoursInDay = Number(value);
        dispatch(boardSetWorkHoursInDay(boardId, workHoursInDay));
        dispatch(segmentTrackAction(SegmentBoardEvent.ESTIMATE_TIME_ZONE, {
            name: SegmentBoardOption.VALUE,
            value: workHoursInDay
        }));
    };
    return action;
};
