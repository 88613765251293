import { createRoot } from 'react-dom/client';
import {Provider} from "react-redux";
import * as React from "react";
import { SubscriptionAdminContext, SubscriptionAdminStore } from "../../store/configureStore";
import { snackbarErrorReload } from '../react_components/snackbarsStack';
import { segmentTrackAction } from 'app/middlewares/segment';
import { SegmentErrorEvent, SegmentErrorOptions } from 'app/middlewares/segment/trackEntities/errorEvents';
import { LazyWrapper } from 'app/view/react_components/lazy/lazy_wrapper';

App.Views.SubscriptionAdminView = App.Views.BaseView.extend({

    initialize: function(options){
        this.root = createRoot(this.el);
        this.store = options.store;
        this.subscriptionId = options.subscriptionId;
    },

    initSubscriptionAdminStore: function() {
        return Promise.all([
            import(/* webpackChunkName: "subscriptionAdmin" */ '../react_components/subscriptionAdmin/store/subscriptionAdmin/reducers/subscriptionAdmin'),
        ])
        .then(([module1]) => {
            SubscriptionAdminStore.replaceReducer(module1.subscriptionAdminReducer);
            SubscriptionAdminStore.dispatch({type: 'async-init'}); // fake action to init new reducers
        })
        .catch(() => {
            this.onImportFail();
            return {
                default: () =>(<div/>)
            }
        });
    },
    
    onImportFail: function() {
        dispatch(snackbarErrorReload(false));
        dispatch(segmentTrackAction(SegmentErrorEvent.LAZY_IMPORT_ERROR, {
            name: SegmentErrorOptions.SOURCE,
            value: 'subscriptionAdmin'
        }));
    },

    render: function() {
        const ImportElement = React.lazy(() =>
            this.initSubscriptionAdminStore()
                .then(() => import(/* webpackChunkName: "subscriptionAdmin" */ '../react_components/subscriptionAdmin/components/SubscriptionAdmin/hocs/SubscriptionAdminHOC/SubscriptionAdminHOC'))
                .then(module => ({default: module.SubscriptionAdminHOC}))
                .catch(() => this.onImportFail())
        );
        this.root.render(
            <LazyWrapper> 
                <Provider
                    store={this.store}
                >
                    <Provider
                        context={SubscriptionAdminContext}
                        store={SubscriptionAdminStore}
                    >
                        <ImportElement
                            context={SubscriptionAdminContext}
                            subscriptionId={this.subscriptionId}
                        />
                    </Provider>
                </Provider>
            </LazyWrapper>
        );
        return this;

    },

    remove: function () {
        this.root.unmount();
        Backbone.View.prototype.remove.call(this);
    }
});

export default App.Views.SubscriptionAdminView;
