import { getMessages } from '../../../../../store/constants';

export const CLASS_COMMENTS_SECTION = 'comments-section';
export const CLASS_COMMENTS_SECTION_TITLE = CLASS_COMMENTS_SECTION + '__title';
export const CLASS_COMMENTS_ACTION_BUTTON = 'comment-actions-button';
export const CLASS_COMMENT = 'comment';
export const CLASS_COMMENTS_SECTION_FORM = CLASS_COMMENTS_SECTION + '__form';
export const CLASS_COMMENT_MENTION_DROPDOWN = CLASS_COMMENTS_SECTION + '__user-drop';
export const CLASS_COMMENTS_REACTIONS = CLASS_COMMENT + '__reactions';

export const CARD_SECTION_COMMENTS = getMessages().getText('card_form_section_comments');
export const CARD_SECTION_COMMENTS_ADD_ATTACHMENT = getMessages().getText('card_form_comment_add_attachment');

export const KEY_CODE_UP = 38;
export const KEY_CODE_DOWN = 40;
export const KEY_CODE_ENTER = 13;

export const KEY_UP = 'ArrowUp';
export const KEY_DOWN = 'ArrowDown';
export const KEY_ENTER = 'Enter';
