import { Dispatch } from '../../../../../../../../types/actions';
import { IGetState, TBoardId, TCardId } from '../../../../../../../../types/types';
import { getCardEstimate } from '../../../../../../../../store/model/selectors/getCardEstimate';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { cardSetEstimate } from '../../../../../../../../rest/effects/card/card/cardSetEstimate';

export const onEstimateChange = (
    boardId: TBoardId,
    cardId: TCardId,
    estimateInMinutes: number
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const cardEstimate = getCardEstimate(getState(), cardId);
        if (estimateInMinutes !== cardEstimate) {
            dispatch(cardSetEstimate(cardId, estimateInMinutes));
            dispatch(segmentTrackAction(SegmentCardEvent.ADDED_ESTIMATE));
        }
    };
    return action;
};
