import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../../types/types';
import { myWorkShowArchivedSet } from '../../../../../store/actions/myWorkShowArchivedSet';
import { updateMyWorkMeta } from '../../../../../../../../../rest/effects/authUser/myWork/updateMyWorkMeta';
import { getMyWorkArchivedCards } from '../../../../../selectors/getMyWorkArchivedCards';

export const onToggleArchived = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const isShowArchivedCards = !getMyWorkArchivedCards(state);
        dispatch(updateMyWorkMeta({ isShowArchivedCards }));
        dispatch(myWorkShowArchivedSet(isShowArchivedCards));
    };
    return action;
};
