import { root } from './../../../../../../store/constants';
import { IApplicationState, TBoardId, TCardId } from '../../../../../../types/types';
import { EDependencyNames } from '../constans';
import { IDependencyItem } from '../components/DependenciesSection/types';
import { getCardSuccessorsDependencies } from '../../../../../../store/model/dependencies/dependencies/selectors/getCardSuccessorsDependencies';
import { isDependencyValid } from '../../../../../../store/model/dependencies/dependencies/selectors/isDependencyValid';
import { getCardFullName } from '../../../../../../store/model/card/selectors/getCardFullName';
import { stripHtml } from '../../../../base/helpers/stripHtmlHelper';
import { markdown } from '../../../../../../helper/markdownHelper';

export const getCardSuccessors = (
    state: IApplicationState,
    cardId: TCardId,
    boardId: TBoardId
): IDependencyItem[] => {
    const successors = getCardSuccessorsDependencies(state, cardId);
    return successors.map((dependency) => {
        const cardFullName = getCardFullName(state, dependency.successorId);
        return {
            cardName: stripHtml(markdown(cardFullName)),
            cardId: dependency.successorId,
            dependencyId: dependency.id,
            dependencyType: EDependencyNames[dependency.type],
            link: root.App.router.getCardPageUrl(boardId, dependency.successorId),
            isValid: isDependencyValid(state, dependency.id)
        };
    }).filter((dep: IDependencyItem) => dep.cardName != null); //for archived predecessors ???
};
