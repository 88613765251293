import { IApplicationState } from '../../../../../../types/types';
import { IFilter } from '../filter/types';
import { filterPanelBoardsSelector } from './filterPanelBoardsSelector';
import { defaultOrEditedFilterSelectorById } from './defaultOrEditedFilterSelectorById';

export const filterSelector = (
    state: IApplicationState,
    boardId: number,
    filterId: number
): IFilter => {
    const filterPanelBoards = filterPanelBoardsSelector(state, boardId);
    const filter = defaultOrEditedFilterSelectorById(filterPanelBoards, filterId);
    return {...filter}
};
