import { IGetState, TCardId } from '../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { fetchHandler } from '../../../../../util/fetchHandler';
import { Util } from '../../../../../util/util';
import { getRestHeadersPatch } from '../../../../helpers/getRestHeadersHelper';
import { IDriveDoc } from '../../../../../store/model/card/types/IDriveDoc';
import { REST_CARD } from '../../../../constants';
import { IRestDriveDoc, TDriveDocId } from '../../../../../types/rest/IRestDriveDoc';

export const patchRest = (
    cardId: TCardId,
    driveDocId: TDriveDocId,
    update: IRestDriveDoc
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const body = {
            id: driveDocId,
            ...update
        };
        return fetchHandler<IDriveDoc>(
            Util.getApiUrl(`${REST_CARD}${cardId}/drivedocs/${driveDocId}`),
            {
                ...getRestHeadersPatch(),
                body: JSON.stringify(body)
            }
        );
    };
    return action;
};
