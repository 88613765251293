import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from '../../../../store/configureStore';
import { PricingPollHOC } from '../../polls/pricingPoll/hocs/PricingPoll/PricingPollHOC';

export class PricingPoll {
    el: HTMLElement;

    constructor () {
        if (this.el) this.remove();
        this.el = document.createElement('div');
        this.el.className = 'poll-placeholder poll-placeholder--pricing';
        document.body.append(this.el);
        this.render();
    }

    render () {
        ReactDOM.render(
            <Provider store={store}>
                <PricingPollHOC />
            </Provider>
            , this.el);
    }

    remove () {
        if (!this.el) return;

        ReactDOM.unmountComponentAtNode(this.el);
        this.el.remove();
        this.el = null;
    }
}
