import { Dispatch, ThunkAction } from 'app/types/actions';
import { closeDialog } from './closeDialog';
import { IGetState, TBoardId } from '../../../../../types/types';
import { BOARDS_LIST_BOARDS_OPENED, boardsListGoogleAnalyticsEvents } from './googleAnalyticsEvents';
import { boardOpen } from '../../../base/effects/boardOpen';

export const openBoard = (
    boardId: TBoardId,
    routeAction: string = ''
): ThunkAction => {
    const action = (dispatch: Dispatch, getState: IGetState) => {
        dispatch(closeDialog()); // TODO check need this
        dispatch(boardsListGoogleAnalyticsEvents(BOARDS_LIST_BOARDS_OPENED));

        dispatch(boardOpen(boardId, routeAction));
    };
    return action;
};
