import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState } from '../../../../../../../types/types';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../middlewares/segment';
import { snackbarSuccessDefault } from '../../../../../snackbarsStack';
import { getMessages } from '../../../../../../../store/constants';

export const onShowSnackbar = (): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.PUSH_TO_GOOGLE_CALENDAR_BUTTON_CLICKED));
        dispatch(snackbarSuccessDefault({
            id: 'SNACKBAR_ID_TEMPLATE_PUSH_TO_GOOGLE_CALENDAR',
            text: getMessages().getText('card_form_dates_calendar_snackbar')
        }));
    };
    return action;
};
