import setSelectedCalendars from './../store/actions/setSelectedCalendars';
import filterCalendars from './../helpers/filterCalendars';

import { sendPushToCalendarGaEvents } from './sendPushToCalendarGaEvents';

import {
    pushCardEventToGoogleCalendarFail,
    pushCardEventToGoogleCalendarRequest,
    pushCardEventToGoogleCalendarSuccess
} from './../store/actions/pushCardEventToGoogleCalendar'
import { addCalendarEvent } from '../../../../../../../rest/effects/card/calendar/addCalendarEvent';
import * as _ from 'underscore';

const pushCardEventToGoogleCalendar = calendar => {
    return (dispatch, getState) => {

        const event = getState().pushToGoogleCalendar.selectedAction;
        const selectedCardId = getState().pushToGoogleCalendar.cardId;
        const selectedActionType = getState().pushToGoogleCalendar.selectedAction;

        const filteredCalendars = filterCalendars(selectedCardId, selectedActionType);
        const pushedIds = _.pluck(filteredCalendars, 'calendarId');

        dispatch(pushCardEventToGoogleCalendarRequest(calendar.id));

        const calendarEvent = {
            calendarId: calendar.id,
            calendarName: calendar.summary,
            type: event
        }
        dispatch(addCalendarEvent(selectedCardId, calendarEvent)).then(() => {
            dispatch(pushCardEventToGoogleCalendarSuccess());
            dispatch(setSelectedCalendars([...pushedIds, calendar.id]));
            sendPushToCalendarGaEvents(event);
        }).catch(e => {
            dispatch(pushCardEventToGoogleCalendarFail());
        });
    }    
};

export default pushCardEventToGoogleCalendar;
