import { EDateType } from '../../../../../../../const';
import { EDatepickerDropdownDirectionX, EDatepickerDropdownDirectionY } from '../DatepickerItem/types';
import * as React from 'react';
import { IHintHOCOwnProps } from '../../../../../hints/hocs/HintHOC/types';
import { EUserDateformat } from '../../../../../../../types/rest/IRestAuthUser';
import { IDateDropdownProps } from '../DateDropdown/types';

export enum EDatepickerDirection {
    ROW = 'row',
    COLUMN = 'column'
}

export interface IDatepickerContext {
    dateFormat: EUserDateformat;
    direction?: EDatepickerDirection;
    dropdownDirectionX?: EDatepickerDropdownDirectionX;
    dropdownDirectionY?: EDatepickerDropdownDirectionY;
    isAllowSameDate?: boolean;
    isClearable: boolean;
    isCompact?: boolean;
    isNoDropdown?: boolean;
    isOverdue?: boolean;
    isReadonly?: boolean;
    isSingle?: boolean;
    isCalendarStartsSunday?: boolean;
    isTime?: boolean;
    is12Hour?: boolean;
    openedDateType: EDateType;
    maxDate?: Date;
    minDate?: Date;
    portalId?: string
    translate?: number;
    setCalendarProps: (props: IDateDropdownProps, type: EDateType) => void;
}

export interface IDatepickerFields {
    className?: string;
    dateFormat?: EUserDateformat;
    defaultFromTime?: Date;
    defaultToTime?: Date;
    direction?: EDatepickerDirection;
    dropdownDirectionX?: EDatepickerDropdownDirectionX;
    dropdownDirectionY?: EDatepickerDropdownDirectionY;
    fromDateHint?: IHintHOCOwnProps;
    fromLabel?: string;
    fromPlaceholder?: string;
    isAllowSameDate?: boolean;
    isClearable?: boolean;
    isCompact?: boolean;
    isFromDisabled?: boolean;
    isFromRequired?: boolean;
    isHideFromDate?: boolean;
    isHideToDate?: boolean;
    isFromOpened?: boolean;
    isNoDropdown?: boolean;
    isOverdue?: boolean;
    isReadonly?: boolean;
    isSingle?: boolean;
    isCalendarStartsSunday?: boolean;
    isTime?: boolean;
    isToOpened?: boolean;
    isToDisabled?: boolean;
    isToRequired?: boolean;
    is12Hour?: boolean;
    maxDate?: Date;
    maxFromDate?: Date; // for multiple card dates
    minDate?: Date;
    minToDate?: Date; // for multiple card dates
    portalId?: string;
    selectedFromDate?: Date;
    selectedToDate?: Date;
    timeHint?: IHintHOCOwnProps
    toLabel?: string;
    toPlaceholder?: string;
    translate?: number;
}

export interface IDatepickerEvents {
    onBlur?: (e: React.FocusEvent) => void;
    onCalendarClosed?: (dateType: EDateType) => void;
    onCalendarOpened?: (dateType: EDateType) => void;
    onChange: (date: Date, dateType?: EDateType) => void;
    onClear?: (dateType: EDateType) => void;
    onClick?: (e: React.MouseEvent) => void;
    onDateSelected?: (dateType: EDateType) => void;
    onFocus?: (e: React.FocusEvent) => void;
    onFromClick?: (e: React.MouseEvent) => void;
    onKeyDown?: (e: React.KeyboardEvent) => void;
    onTimeOpened?: (dateType: EDateType) => void;
    onTimeSelected?: (dateType: EDateType) => void;
    onToClick?: (e: React.MouseEvent) => void;
    onUnevenTimeSelected?: (dateType: EDateType) => void;
}

export interface IDatepickerProps extends
    IDatepickerFields,
    IDatepickerEvents
{}
