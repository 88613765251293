import { getMessages } from '../../../../../../store/constants';

export const TOOLTIP_CLEAR_DATE = getMessages().getText('recurring.tooltip.clear_date');

export const CARD_SECTION_RECURRING = getMessages().getText('card_form_section_recurring');

export const LABEL_START = getMessages().getText('recurring.label.start');
export const LABEL_NEXT_RUN = getMessages().getText('recurring.label.next_run');
export const LABEL_LAST_RUN = getMessages().getText('recurring.label.last_run');
export const LABEL_DAY = getMessages().getText('recurring.label.day');
export const LABEL_WEEK = getMessages().getText('recurring.label.week');
export const LABEL_MONTH = getMessages().getText('recurring.label.month');
export const LABEL_YEAR = getMessages().getText('recurring.label.year');
export const LABEL_TIME = getMessages().getText('recurring.label.time');
export const LABEL_WEEK_DAYS = getMessages().getText('recurring.label.week_days');
export const LABEL_REPEAT_EVERY = getMessages().getText('recurring.label.repeat_every');
export const LABEL_CREATE_IN_LIST = getMessages().getText('recurring.label.create_in_list');
export const LABEL_NEVER = getMessages().getText('recurring.label.never');
export const LABEL_ON = getMessages().getText('recurring.label.on');
export const LABEL_AFTER = getMessages().getText('recurring.label.after');
export const LABEL_OCCURRENCES = getMessages().getText('recurring.label.occurrences');
export const LABEL_MONTHLY_ON_DAY = getMessages().getText('recurring.label.monthly_on_day');
export const LABEL_MONTHLY_ON_WEEKDAY = getMessages().getText('recurring.label.monthly_on_weekday');
export const LABEL_WEEKDAY_FIRST = getMessages().getText('recurring.label.weekday.first');
export const LABEL_WEEKDAY_SECOND = getMessages().getText('recurring.label.weekday.second');
export const LABEL_WEEKDAY_THIRD = getMessages().getText('recurring.label.weekday.third');
export const LABEL_WEEKDAY_FOURTH = getMessages().getText('recurring.label.weekday.fourth');
export const LABEL_WEEKDAY_LAST = getMessages().getText('recurring.label.weekday.last');

export const LABEL_FREQUENCY = getMessages().getText('recurring.label.frequency');

export const BUTTON_SAVE = getMessages().getText('recurring.button.save');
export const BUTTON_CANCEL = getMessages().getText('recurring.button.cancel');

export const ERROR_START_DATE = getMessages().getText('recurring.error.start_date');
export const ERROR_LAST_REPEAT_START_DATE = getMessages().getText('recurring.error.last_repeat_start_date');
export const ERROR_DUE_DATE = getMessages().getText('recurring.error.due_date');
