import * as React from 'react';
import { IFiltersOwnProps, IInsightsOwnProps, IReportsWorkspaceProps } from './types/components';
import { LazyWrapper } from '../lazy/lazy_wrapper';
import { snackbarErrorReload } from '../snackbarsStack';
import { segmentTrackAction } from '../../../middlewares/segment';
import { SegmentErrorEvent, SegmentErrorOptions } from '../../../middlewares/segment/trackEntities/errorEvents';
import { ISegmentEventOption } from '../../../middlewares/segment/trackEntities/types';
import store, { dispatch } from 'app/store/configureStore';

const initReportsStore = () => Promise.all([
    import(/* webpackChunkName: "reports" */ './store/reducers/reportsReducer'),
    import(/* webpackChunkName: "reports" */ './UserProductivity/UserProductivityReport/store/reportsBoard/reducers/reportsBoardReducer')
])
    .then(([module1, module2]) => {
        const reportsReducer = module1.reportsReducer;
        const reportsBoardReducer = module2.reportsBoardReducer;
        store.reducerManager.add('reports', reportsReducer);
        store.reducerManager.add('reportsNew', reportsBoardReducer);
        dispatch({type: 'async-init'}); // fake action to init new reducers
    })
    .catch(() => {
        onImportFail();
        return {
            default: () =>(<div/>)
        }
    });

const onImportFail = () => {
    dispatch(snackbarErrorReload(false));
    const options: ISegmentEventOption = {
        name: SegmentErrorOptions.SOURCE,
        value: 'reports'
    };
    dispatch(segmentTrackAction(SegmentErrorEvent.LAZY_IMPORT_ERROR, options));
}

const Reports = React.lazy(() =>
    initReportsStore()
        .then(() => import(/* webpackChunkName: "reports" */ './hoc/Reports/Reports'))
        .then(module => ({default: module.connectedReportsWorkspace}))
);

export const ReportsLazy = (props: IReportsWorkspaceProps) => {
    React.useEffect(() => {
        document.body.classList.add('page--reports');

        return () => {
            document.body.classList.remove('page--reports');
        };
    }, []);
    // лоадер не нужен, т.к. при смене view висит systemLoader
    return <LazyWrapper loader={<div/>}>
        <Reports {...props}/>
    </LazyWrapper>;
};

const ReportFiltersHOC = React.lazy(() =>
    initReportsStore()
        .then(() => import(/* webpackChunkName: "reports" */ './hoc/Reports/ReportFilters/ReportFilters'))
        .then(module => ({default: module.ReportFiltersHOC}))
);

export const ReportFiltersHOCLazy = (props: IFiltersOwnProps) => (
    <LazyWrapper>
        <ReportFiltersHOC {...props}/>
    </LazyWrapper>
);

const ReportsInsightsHOC = React.lazy(() =>
    initReportsStore()
        .then(() => import(/* webpackChunkName: "reports" */ './hoc/ReportsInsights/ReportsInsights'))
        .then((module) => ({default: module.ReportsInsightsHOC}))
);

export const ReportsInsightsHOCLazy = (props: IInsightsOwnProps) => (
    <LazyWrapper>
        <ReportsInsightsHOC {...props}/>
    </LazyWrapper>
);
