import { IHintCanShowSetAction } from './types';
import { AT_CAN_SHOW_SET } from './constants';
import { THintCanShow } from '../types';

export const hintCanShowSetAction = (
    canShow: THintCanShow
): IHintCanShowSetAction => ({
        type: AT_CAN_SHOW_SET,
        canShow
    }
);
