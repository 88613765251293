import { IGetState, TCardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { cardsArchive } from '../../../../rest/effects/card/card/cardsArchive';
import { SegmentArchiveEvent, SegmentCardEvent, segmentTrackAction } from '../../../../middlewares/segment';
import { cardDelete } from '../../../../rest/effects/card/card/cardDelete';
import { cardsUpdate } from '../../../../store/model/actionCreators/cardsUpdate';
import { ICard } from '../../../../store/model/card/types';
import { getCard } from '../../../../store/model/selectors/getCard';
import {
    snackbarUndoWithAlternativeAction
} from '../../snackbarsStack/effects/variantUndo/snackbarUndoWithAlternativeAction';
import {
    SNACKBAR_CARD_ARCHIVE_ID
} from '../../aside_panel/cardDetails/DeleteButtonSection/hocs/DeleteButtonHOC/constants';
import { getMessages } from '../../../../store/constants';
import { escape } from 'underscore';
import { clearMarkdownInline } from '../helpers/clearMarkdownHelper';
import { getCardFullNameHelper } from '../../../../store/model/card/helpers/getCardFullNameHelper';
import { SNACKBAR_BUTTON_DELETE } from '../../snackbarsStack/effects/constants';
import { TStatus } from '../../../../types/model';
import { updateCardAssigneesFromCards } from '../../main/myWorkView/effects/updateCardAssigneesFromCards';
import { ICards } from '../../../../store/model/cards/types';
import { closeCardPanel } from '../../aside_panel/asidePanel/effects/closeCardPanel';

export const cardsArchiveOrDelete = (
    cardIds: TCardId[],
    positionLeft: boolean = true,
    isDone?: boolean,
    isEpic?: boolean
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(closeCardPanel(cardIds[0]));

        const update: ICards = {};
        const cards = cardIds.map(cardId => getCard(getState(), cardId));

        cardIds.forEach((cardId) => {
            const card: ICard = getCard(getState(), cardId);

            if (!card) {
                throw new Error('Unknown cardId');
            }

            update[cardId] = {
                status: TStatus.STATUS_ARCHIVE // mark card as archived in store
            };
        });

        dispatch(cardsUpdate(update));
        dispatch(updateCardAssigneesFromCards(update));

        const actionApply = () => {
            dispatch(cardsArchive(cardIds));
            dispatch(segmentTrackAction(SegmentCardEvent.ARCHIVE_CARD_PROCEED));
        };

        const actionAlternative = () => {
            cardIds.forEach(cardId => dispatch(cardDelete(cardId)));
            dispatch(segmentTrackAction(SegmentCardEvent.DELETE_ON_ARCHIVE_CARD_PROCEED));
        };

        const actionUndo = () => {
            const update: ICards = {};

            cardIds.forEach((cardId) => {
                update[cardId] = {
                    status: cards.filter(card => card.id === cardId)[0].status // revert card status
                };
            });

            dispatch(cardsUpdate(update));
            dispatch(updateCardAssigneesFromCards(update));
            dispatch(segmentTrackAction(SegmentCardEvent.ARCHIVE_CARD_CANCELED));
        }

        dispatch(segmentTrackAction(SegmentArchiveEvent.CARD_ARCHIVED));
        dispatch(snackbarUndoWithAlternativeAction({
            id: SNACKBAR_CARD_ARCHIVE_ID,
            text: isDone ? isEpic && cardIds.length > 1 ?
                getMessages().getText('snackbar.archive.card.done.text.epic')
                : getMessages().getPluralByKeys(
                    cardIds.length,
                    ['snackbar.archive.card.done.text', 'snackbar.archive.card.done.text.multiple'],
                    null,
                    cardIds.length
                )
                : getMessages().getText(
                    'snackbar.archive.card.text',
                    null,
                    escape(clearMarkdownInline(getCardFullNameHelper(getCard(getState(), cardIds[0]))))
                ),
            actionApply,
            actionUndo,
            actionAlternative,
            alternativeButton: SNACKBAR_BUTTON_DELETE
        }, positionLeft));
    };

    return action;
};
