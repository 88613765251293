import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TBoardId, TCardId } from '../../../../../../../../types/types';
import { getMessages } from '../../../../../../../../store/constants';
import { snackbarInfoDefault } from '../../../../../../snackbarsStack';
import { SNACKBAR_BUTTON_OK } from '../../../../../../snackbarsStack/effects/constants';
import { getBoard } from '../../../../../../../../store/model/selectors/getBoardById';
import { getDefaultActionEvent } from '../../../../../../snackbarsStack/effects/helpers/getDefaultActionEvent';
import { cardOpen } from '../../../../../../base/effects/cardOpen';

export const onShowSnackbar = (
    cardId: TCardId,
    boardId: TBoardId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const snackbarId = 'SNACKBAR_ID_ASSIGNED_TO_ME_RELATED_ADD';
        const onCloseSnackbar = () => dispatch(getDefaultActionEvent(snackbarId));
        const { name } = getBoard(getState(), boardId);
        dispatch(snackbarInfoDefault({
            id: 'SNACKBAR_ID_ASSIGNED_TO_ME_RELATED_ADD',
            text: getMessages().getText('assigned_to_me.related.snackbar.text') + name,
            timer: -1,
            controls: [
                {
                    action: () => {
                        onCloseSnackbar();
                        dispatch(cardOpen(cardId));
                    },
                    title: getMessages().getText('assigned_to_me.related.snackbar.button')
                },
                {
                    action: onCloseSnackbar,
                    title: SNACKBAR_BUTTON_OK,
                }
            ]
        }))
    };
    return action;
};
