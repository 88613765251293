import * as React from 'react';
import {AsidePanelContext} from '../../../../../asidePanel/components/AsidePanel/constants';
import {CLASS_BOARD_DETAILS_SECTION} from '../../../../constants';
import {SettingsSection} from '../../../../../../base/components/SettingsSection/SettingsSection';
import './_BoardImportSection.scss';
import {IBoardImportSectionProps} from 'app/view/react_components/aside_panel/boardDetails/details/ImportBoard/components/BoardImportSection/types';
import {BoardImportHOC} from '../../hocs/BoardImportHOC/BoardImportHOC';

export function BoardImportSection ({
}: IBoardImportSectionProps) {
    const { boardId } = React.useContext(AsidePanelContext);
    const className = CLASS_BOARD_DETAILS_SECTION + ' ' + CLASS_BOARD_DETAILS_SECTION + '--board-import';

    return (
        <SettingsSection
            className={className}
            title={'Import to board'}
        >
            <BoardImportHOC
                boardId={boardId}
            />
        </SettingsSection>
    );
};
