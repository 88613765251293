import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState } from '../../../../../../../types/types';
import { extendTrial } from '../../../../../dialogs/trialExtend/effects/extendTrial';
import { startFirstTrialEvent } from './segmentEvents';
import { SegmentUserSourceValue } from '../../../../../../../middlewares/segment/trackEntities/userEvents';
import { snackbarSuccessDefault } from '../../../../../snackbarsStack';
import { SNACKBAR_ID_START_FIRST_TRIAL, START_FIRST_TRIAL_SNACKBAR_TEXT } from '../constants';
import { root } from '../../../../../../../store/constants';
import { isPwc } from '../../../../../../../store/model/authUser/selectors/isPwc';
import { getAuthUser } from '../../../../../../../store/model/authUser/selectors/getAuthUser';

export const startFirstTrial = (
    source: SegmentUserSourceValue
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const user = getAuthUser(getState());
        if (!user || !user.firstTrialCode) return Promise.reject();

        dispatch(extendTrial('', user.firstTrialCode))
            .then(() => {
                const pwc = isPwc(getState());
                if (pwc) {
                    root.App.controller.blockerHelper.showBlockerPWCTrial();
                } else {
                    dispatch(snackbarSuccessDefault({
                        id: SNACKBAR_ID_START_FIRST_TRIAL,
                        text: START_FIRST_TRIAL_SNACKBAR_TEXT
                    }));
                }
            });
        dispatch(startFirstTrialEvent(source));
    };
    return action;
};
