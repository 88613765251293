import { IChecklistItem } from '../types';
import { IChecklistItemAddAction } from './types';
import { AT_CHECKLIST_ITEM_ADD } from './constants';

export const checklistItemAddAction = (
    item: IChecklistItem
): IChecklistItemAddAction => {
    return {
        type: AT_CHECKLIST_ITEM_ADD,
        item
    }
};
