import { boardsActionSet } from '../../../store/model/actions/boardsActionSet';
import { updateAction } from '../../../store/model/board/actions/updateAction';
import { boardActionSet } from '../../../store/model/boards/actions/boardActionSet';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { TBoardId } from '../../../types/types';

/**
 * для readonly доски logoColor записываем только в стор и в userRecentBoards
 */
export const boardSetLogoColorRedux = (
    boardId: TBoardId,
    logoColor: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        return dispatch(boardsActionSet(boardActionSet(boardId, updateAction({ logoColor }))));
    };
    return action;
};
