import { IGetState, TBoardId, TColorId, TCardAssigneeRoleId } from '../../../types/types';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { boardRolePatchRest } from './api/helpers/boardRolePatchRest';
import { TStatus } from '../../../types/model';

export const boardRoleSetNameStatus = (
    boardId: TBoardId,
    roleId: TCardAssigneeRoleId,
    name: string,
    status: TStatus
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(boardRolePatchRest(boardId, roleId, {name, status}));
    };
    return action;
}
