import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import Util from '../../../../../util/util';
import { TBoardId } from '../../../../../types/types';
import { REST_LOAD_BOARD } from '../constants';
import { IRestBoardForOpenDialog } from '../rest/types';
import { getBoardsFromRest } from '../store/boards/helpers/getBoardsFromRest';
import { boardSet } from '../store/boards/actions/boardSet';
import { onLoadBoardsFail } from './onLoadBoardsFail';
import { IGetState } from '../../../../../types/types';
import { processLoadedBoards } from '../helpers/processLoadedBoards';
import { fetchHandler } from '../../../../../util/fetchHandler';
import { boardActionSet } from '../../../../../store/model/boards/actions/boardActionSet';
import { boardsActionSet } from '../../../../../store/model/actions/boardsActionSet';
import { updateAction } from '../../../../../store/model/board/actions/updateAction';

export const loadBoard: ActionCreator<ThunkAction> = (
    boardId: TBoardId
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return fetchHandler<IRestBoardForOpenDialog>(
            Util.getApiUrl(REST_LOAD_BOARD + boardId)
        )
        .then(
            (restBoard) => { // update openBoards
                const boards = getBoardsFromRest([restBoard]);
                const boardsFilledOpened = processLoadedBoards(getState, boards);
                dispatch(boardSet(boardsFilledOpened[0]));
                dispatch(boardsActionSet(boardActionSet(boardId, updateAction({ // update model boards
                    driveId: restBoard.driveId
                }))));
            }
        )
        .catch(
            () => dispatch(onLoadBoardsFail())
        );
    };
    return action;
};
