import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../types/types';
import { root } from '../../../../../../../../store/constants';
import { getChecklistItems } from '../../../../../../../../store/model/checklists/checklist/selectors/getChecklistItems';
import { IRestChecklist } from '../../../../../../../../types/rest/IRestChecklist';
import { PORTION_SIZE } from '../../../../../../../../const';
import { checklistItemsUpdate } from '../../../../../../../../rest/effects/card/checklist/checklistItemsUpdate';
import { IChecklist } from '../../../../../../../../store/model/checklists/checklist/types';
import { IRestChecklistItem } from '../../../../../../../../types/rest/IRestChecklistItem';
import { checklistAdd } from '../../../../../../../../rest/effects/card/checklist/checklistAdd';
import { checklistsUpdateStore } from '../../../../../../../../store/model/checklists/actionCreators/checklistsUpdate';
import { ICardChecklists } from '../../../../../../../../store/model/checklists/checklists/types';
import { sendRealTimeStoreActionByCardId } from '../../../../../../base/helpers/realTimeHelperTS';
import { checklistDelete } from '../../../../../../../../rest/effects/card/checklist/checklistDelete';

export const updateDefaultChecklistWithItems = (
    checklist: IChecklist,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const { cardId, id: checklistId }  = checklist;
        const { checkItems, id, ...rest } = checklist;
        return dispatch(checklistAdd(rest)) // добавляем новый чеклист
            .then((response: IRestChecklist) => {
                const checklistItems = getChecklistItems(state, cardId, checklistId);
                let i = 0;
                let items: IRestChecklistItem[] = [];
                let promiseItems: Promise<any>[] = [];
                for (let itemId in checklistItems) { // патчим дефолтные checkItems порциями
                    items.push({
                        id: Number(itemId),
                        checklistId: response.id
                    });
                    i++;
                    if (i >= PORTION_SIZE) {
                        promiseItems.push(dispatch(checklistItemsUpdate(cardId, checklistId, items)));
                        i = 0;
                        items = [];
                    }
                }
                if (items.length) {
                    promiseItems.push(dispatch(checklistItemsUpdate(cardId, checklistId, items)));
                }

                return Promise.all(promiseItems)
                    .then(() => { // переносим в сторе checkItems в новый checklist
                        const checklists: ICardChecklists = {
                            [response.id]: {
                                checkItems: getChecklistItems(getState(), cardId, checklistId),
                            },
                            [checklistId]: {
                                checkItems: null,
                            }
                        }
                        const action = checklistsUpdateStore(cardId, checklists);
                        dispatch(sendRealTimeStoreActionByCardId(cardId, action));
                        return dispatch(action);
                    })
                    .then(() => {
                        return dispatch(checklistDelete(cardId, checklistId));
                    })
                    .then(() => {
                        return response;
                    });
            })
            .catch((e: any) => {
                console.error(e);
            })
    };
    return action;
};
