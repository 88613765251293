import { IApplicationState } from '../../../../types/types';
import { IAuthUser } from '../types';
import { getInitialStateAuthUser } from '../constants';
import { createSelector } from 'reselect';
import { root } from '../../../constants';
import { PRODUCTS_BY_PLAN } from '../../../../view/react_components/dialogs/price_page/constants';
import { EProductPlan } from '../../../../view/react_components/dialogs/price_page/types';
import { getBoardIsOnSharedDrive } from '../../selectors/getBoardIsOnSharedDrive';
import { getActiveBoardId } from '../../selectors/getActiveBoardId';

export const SNOOZE_BLOCKER_MINUTES = 30;
export const SNOOZE_BLOCKER_ALLOWS_BASIC_FEATURES_UNLIMITED_COUNT = 100;
export const SNOOZE_BLOCKER_ALLOWS_BASIC_FEATURES_WITH_COUNTER = [
    'allowColorTagCount',
    'allowCardColorCount',
    // 'allowSubCardCount',
    'allowCardTagCount',
    'allowChecklistCount',
    'allowChecklistItemCount',
];
export const SNOOZE_BLOCKER_LEGACY_BLOCKER = [
    'allowRequiredProperties'
];
const SNOOZE_BLOCKER_ALLOWS_PRO_FEATURES = [
    ...SNOOZE_BLOCKER_LEGACY_BLOCKER,
    'allowArchive',
    'allowAutoBackupXml',
    'allowBranding',
    'allowGantt',
    'allowReports',
    'allowSwimlanes',
    'allowTeamWorkload',
    'allowTimeTracker',
    'allowTimeTrackerExport',
    'allowNotificationBeforeDueDates',
    'allowDependencies',
    'allowCardCustomProperties',
    'allowMyWorkCustomFilters',
    'allowMyWorkTaskGrouping',
];
const SNOOZE_BLOCKER_ALLOWS_ALL_FEATURE = [
    ...SNOOZE_BLOCKER_ALLOWS_PRO_FEATURES,
    'allowGoogleSheetExport',
    'allowCardFromEmail',
    'allowCardsAssignedToMe',
    'allowCreateBoard',
    'allowPriorityAutoSorting',
    'allowRecurringTask',
    'allowSharedDrive',
    'allowSubCard',
    'allowSavedFilters',
    'allowPublicFilter',
    'allowCardAgeing',
    'allowActivity',
];

// фичи реализованы на бэке, нет возможности или нет смысла давать на 30 минут
export const SNOOZE_BLOCKER_ALLOWS_PRO_FEATURES_NOT_AVAILABLE = [
    'allowAutoBackupXml',
    'allowBranding',
];

export const SNOOZE_BLOCKER_PRO_FEATURES = [
    ...SNOOZE_BLOCKER_ALLOWS_PRO_FEATURES_NOT_AVAILABLE,
    ...SNOOZE_BLOCKER_ALLOWS_PRO_FEATURES
]

export const getAuthUserSelector = (
    authUser: IAuthUser,
    isBoardOnSharedDrive: boolean,
): IAuthUser => {
    if (root._debug && root._debug.selector) {
        console.log('getAuthUserSelector');
    }

    authUser = authUser || getInitialStateAuthUser();
    return new Proxy(authUser, {
        get: function(target, key) {
            const currentValue = target[key as keyof IAuthUser];
            let isStarterPlanOnly = false;
            const subscriptions = target.activeSubscription;
            if (// KNB-3493 сейчас показываем новый дизайн и плашки pro и стартеру и без подписки легаси юзерам
                !subscriptions.length &&
                !isBoardOnSharedDrive &&
                !target[SNOOZE_BLOCKER_PRO_FEATURES[0] as keyof IAuthUser] // проверяем, что это не фримиум
            ) {
                isStarterPlanOnly = true;
            } else {
                for (let i in subscriptions) {
                    const { productId } = subscriptions[i];
                    if (PRODUCTS_BY_PLAN[EProductPlan.PREMIUM].includes(productId)) {
                        isStarterPlanOnly = true;
                    } else if ([...PRODUCTS_BY_PLAN[EProductPlan.PROFESSIONAL], ...PRODUCTS_BY_PLAN[EProductPlan.ENTERPRISE]].includes(productId)) {
                        isStarterPlanOnly = false;
                        break;
                    }
                }
            }
            const features = isStarterPlanOnly ? SNOOZE_BLOCKER_ALLOWS_PRO_FEATURES : SNOOZE_BLOCKER_ALLOWS_ALL_FEATURE;
            if (
                features.includes(key.toString()) &&
                isStarterPlanOnly && !SNOOZE_BLOCKER_ALLOWS_PRO_FEATURES_NOT_AVAILABLE.includes(key.toString()) &&
                !currentValue
            ) {
                 const snoozeBlockerTime = target.meta.snoozeBlockerTime;
                 // @ts-ignore
                 const limit = window.SNOOZE_BLOCKER_MINUTES >= 0 ? window.SNOOZE_BLOCKER_MINUTES : SNOOZE_BLOCKER_MINUTES; // TODO for testing
                 if (snoozeBlockerTime && (Date.now() - snoozeBlockerTime) / 1000 / 60 < limit) {
                     if (SNOOZE_BLOCKER_ALLOWS_BASIC_FEATURES_WITH_COUNTER.includes(key.toString())) {
                         return SNOOZE_BLOCKER_ALLOWS_BASIC_FEATURES_UNLIMITED_COUNT;
                     }
                     return true;
                 }
            }
            if (isStarterPlanOnly && SNOOZE_BLOCKER_ALLOWS_PRO_FEATURES.includes(key.toString())) return false
            return currentValue;
        }
    })
};

export const getAuthUserCreateSelector = () => createSelector(
    (state: IApplicationState) => state.model.authUser,
    (state: IApplicationState) => getBoardIsOnSharedDrive(state, getActiveBoardId(state)),
    getAuthUserSelector
);

// так как юзер один и тот же, можем сделать кеш для getAuthUser
export const getAuthUser = getAuthUserCreateSelector();
