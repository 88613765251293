import { IApplicationState, TBoardId } from '../../../../../../../../types/types';
import { IMonthAttachments } from '../../../BoardAttachmentsList/components/types';
import { getDriveDocs } from '../../../../../../../../store/model/driveDocs/selectors/getDriveDocs';
import { Util } from '../../../../../../../../util/util';
import { getBoardIdByCardId } from '../../../../../../../../store/model/selectors/getBoardIdByCardId';
import { TBoardAttachmentsTab } from '../../../TabAttachments/components/TabAttachments/types';
import { getCard } from '../../../../../../../../store/model/selectors/getCard';
import { createDeepEqualSelector } from '../../../../../../helpers/memoizeHelper';

export const getBoardAttachmentsByMonth = (
    state: IApplicationState,
    boardId: TBoardId,
    tab: TBoardAttachmentsTab,
    searchQuery: string = ''
): IMonthAttachments[] => {
    const attachmentsByMonth: IMonthAttachments[] = [];
    const attachments = getDriveDocs(state);
    for (let key in attachments) {
        const attachment = attachments[key];
        const cardBoardId = getBoardIdByCardId(state, attachment.cardId);
        if (
            cardBoardId !== boardId ||
            !attachment.title.toLowerCase().includes(searchQuery.toLowerCase())
        ) {
            continue;
        }

        if (tab !== TBoardAttachmentsTab.ALL && !searchQuery) {
            const type = attachment.mimeType;
            const isMedia = type.includes('image') || type.includes('video') || type.includes('audio');
            if (
                (tab === TBoardAttachmentsTab.MEDIA && !isMedia) ||
                (tab === TBoardAttachmentsTab.DOCUMENTS && isMedia)
            ) {
                continue;
            }
        }

        const date = new Date(attachment.created * 1000);
        const now = new Date(Date.now());
        let title: string;
        if (
            now.getFullYear() === date.getFullYear() &&
            now.getMonth() === date.getMonth()
        ) {
            title = 'This month';
        } else {
            title = Util.formatDateMoment(date, 'MMMM YYYY');
        }
        const idx = attachmentsByMonth.findIndex(item => item.title === title);
        const cardName = getCard(state, attachment.cardId).name;
        if (idx >= 0) {
            attachmentsByMonth[idx].attachments.push({ ...attachment, cardName });
        } else {
            attachmentsByMonth.push({
                title,
                date: date.getTime(),
                attachments: [{ ...attachment, cardName }]
            });
        }
    }
    return attachmentsByMonth.sort((a, b) => b.date - a.date);
}

type TgetBoardAttachmentsByMonth = (
    state: IApplicationState,
    boardId: TBoardId,
    tab: TBoardAttachmentsTab,
    searchQuery: string,
) => IMonthAttachments[];

export const getBoardAttachmentsByMonthCreateSelector = (): TgetBoardAttachmentsByMonth => createDeepEqualSelector(
    getBoardAttachmentsByMonth,
    monthlyAttachments => monthlyAttachments
);
