import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { IShowOnGanttButtonHOCProps } from './types';
import { onClick } from './events/onClick';
import { getCard } from '../../../../../../../store/model/selectors/getCard';
import { IShowOnGanttButtonEvents, IShowOnGanttButtonFields } from '../../components/ShowOnGanttButton/types';
import { ShowOnGanttButton } from '../../components/ShowOnGanttButton/ShowOnGanttButton';
import { getSectionType } from '../../../selectors/getSectionType';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { TStatus } from '../../../../../../../types/model';
import { getShowProFeaturesTrial } from '../../../../../../../store/model/authUser/selectors/getShowProFeaturesTrial';
import { getActiveHint } from '../../../../../hints/store/hints/selectors/getActiveHint';
import { EHintKey } from '../../../../../hints';
import { onShowHint } from './events/onShowHint';
import { getIsUserBasicPlanOnly } from '../../../../../../../store/model/authUser/selectors/getIsUserBasicPlanOnly';
import { getHintCanShow } from '../../../../../hints/store/hint/selectors/getHintCanShow';
import { getCurrentRoute } from '../../../../../../../store/router/selectors/getCurrentRoute';
import { ERoutes } from '../../../../../../../store/router/constants';

const mapStateToProps = (
    state: IApplicationState,
    { boardId, cardId, isShowTooltip }: IShowOnGanttButtonHOCProps
): IShowOnGanttButtonFields => {
    const card = getCard(state, cardId);
    const sectionType = getSectionType(state, boardId, cardId, null, 'allowGantt');
    const isDisallow = sectionType === ESettingsSectionType.DISALLOW;
    const isShow = (
        [
            TStatus.STATUS_ACTIVE,
            TStatus.STATUS_SERVICE_TEMPLATE
        ].includes(card.status) && (
            sectionType === ESettingsSectionType.DEFAULT ||
            isDisallow
        )
    );

    const activeHint = getActiveHint(state);

    return {
        ganttVisibility: card.ganttVisibility,
        isBasicFeature: getIsUserBasicPlanOnly(state),
        isDisallow,
        isProFeature: getShowProFeaturesTrial(state),
        isShow,
        isShowHint: getCurrentRoute(state) === ERoutes.EDIT_CARD && activeHint && activeHint.key === EHintKey.GANTT_CONTEXT_SHOW_ON_GANTT && !!getHintCanShow(state, EHintKey.GANTT_CONTEXT_SHOW_ON_GANTT),
        isShowTooltip
    }
};
const mapDispatchToProps = (
    dispatch: any,
    props: IShowOnGanttButtonHOCProps
): IShowOnGanttButtonEvents => {
    const {boardId, cardId} = props;
    return {
        onClick: () => dispatch(onClick(boardId, cardId)),
        onShowHint: () => dispatch(onShowHint(cardId))
    }
};
export const ShowOnGanttButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(ShowOnGanttButton);

ShowOnGanttButtonHOC.displayName = 'ShowOnGanttButtonHOC';
