import * as React from 'react';
import {
    UpgradePlanButton
} from '../../../../../view/react_components/main/upgradeButton/components/upgradePlanButton/UpgradePlanButton';
import { IABUpgradePlanButtonProps } from './types';
import './_abUpgradeButton.scss';
import {
    IUpgradePlanButtonProps
} from '../../../../../view/react_components/main/upgradeButton/components/upgradePlanButton/types';

export const ABUpgradePlanButton: React.FC<IABUpgradePlanButtonProps> = ({
    color,
    init,
    ...props
}) => {
    const ownProps = props as IUpgradePlanButtonProps;
    return (
        init && <div className={`ab_color ab_color--${color}`}>
            <UpgradePlanButton {...ownProps} />
        </div>
    );
};

ABUpgradePlanButton.displayName = 'ABUpgradePlanButton';
