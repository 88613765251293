import { IApplicationState } from '../../../../types/types';
import { getAuthUser } from './getAuthUser';
import { ETutorial } from '../../../../types/rest/IUserMeta';

export const getAuthUserWatchedTutorials = (
    state: IApplicationState
): ETutorial[] => {
    const user = getAuthUser(state);
    return user.meta && user.meta.watchedTutorials || [];
};
