import { patchRest } from 'app/rest/autobackups/api/patchRest';
import { postRest } from 'app/rest/autobackups/api/postRest';
import { IRestAutoBackup } from 'app/rest/autobackups/types';
import { Dispatch, ThunkAction } from 'app/types/actions';
import { IGetState, TBoardId } from 'app/types/types';
import { autoBackupIsLoadingSet } from '../store/autoBackups/actions/autoBackupIsLoadingSet';
import { autoBackupsAdd } from '../store/autoBackups/actions/autoBackupsAdd';
import { autoBackupsBoardActionSet } from '../store/autoBackupsBoard/actions/autoBackupsBoardActionSet';
import { IAutoBackupState } from '../store/autoBackupsBoard/types';

export const editAutoBackup = (
    boardId: TBoardId,
    body: IRestAutoBackup
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if(body.id) {
            dispatch(autoBackupsBoardActionSet(boardId, autoBackupIsLoadingSet(true)));
            return dispatch(patchRest(body)).then((autoBackup: IRestAutoBackup) => {
                dispatch(autoBackupsBoardActionSet(boardId,autoBackupsAdd(autoBackup as IAutoBackupState)));
                dispatch(autoBackupsBoardActionSet(boardId,autoBackupIsLoadingSet(false)));
            });
        } else {
            dispatch(autoBackupsBoardActionSet(boardId, autoBackupIsLoadingSet(true)));
            return dispatch(postRest(boardId, body)).then((autoBackup: IRestAutoBackup) => {
                dispatch(autoBackupsBoardActionSet(boardId,autoBackupsAdd(autoBackup as IAutoBackupState)));
                dispatch(autoBackupsBoardActionSet(boardId,autoBackupIsLoadingSet(false)));
            });
        }
    };
    return action;
};
