import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { ICommentsListEvents, ICommentsListFields } from '../../components/CommentsList/types';
import { ICommentsListHOCFields } from './types';
import { CommentsList } from '../../components/CommentsList/CommentsList';
import { onEdit } from './events/onEdit';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { getSectionType } from '../../../selectors/getSectionType';
import { getSortedCommentsCreateSelector } from './selectors/getSortedComments';
import { getAuthUser } from '../../../../../../../store/model/authUser/selectors/getAuthUser';

// const analyze = new Analyze('CommentsListHOC');
// 10 раз открыл-закрыл карту
// no reselect  average: 0.014 ms, calls: 118, total: 1.700 ms
// reselect     average: 0.009 ms, calls: 118, total: 1.100 ms
// cache        average: 0.034 ms, calls: 120, total: 4.100 ms

const mapStateToProps = () => {
    const getSortedComments = getSortedCommentsCreateSelector();

    return (
        state: IApplicationState,
        props: ICommentsListHOCFields
    ): ICommentsListFields => {
        // analyze.start();
        const {boardId, cardId} = props;

        const authUser = getAuthUser(state);
        const sortedComments = getSortedComments(state, cardId);

        const sectionType = getSectionType(state, boardId, cardId, null, null, 'allowComment');
        const isReadonly = sectionType === ESettingsSectionType.READONLY;

        const ret = {
            authUserHash: authUser.hash,
            comments: sortedComments,
            cardId,
            isReadonly
        };
        // analyze.finish();
        return ret;
    };
};

const mapDispatchToProps = (
    dispatch: any
): ICommentsListEvents => {
    return {
        onEdit: () => dispatch(onEdit())
    };
};

export const CommentsListHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CommentsList);

CommentsListHOC.displayName = 'CommentsListHOC';
