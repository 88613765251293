import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { authUserSetMessengerPoll } from '../../../../../../../rest/effects/authUser/authUserSetMessengerPoll';

export const onSkip = (): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        const date = Date.now();
        dispatch(authUserSetMessengerPoll({ lastTimeShowed: date, isSkipped: true }));
    };
    return action;
};
