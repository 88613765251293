import { IGetState, TCardId } from '../../../../../types/types';
import { ICardCopyRestOptions, ICardCopyRestOrderNumbersMap, ICardIdMap, ICardsCopyRestResult } from './types';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { fetchHandler } from '../../../../../util/fetchHandler';
import Util from '../../../../../util/util';
import { REST_CARD } from '../../../../constants';
import { getRestHeadersPost } from '../../../../helpers/getRestHeadersHelper';

export const copyCardsRest = (
    cardIdList: TCardId[],
    cardsIdMap: ICardIdMap,
    orderNumbersMap: ICardCopyRestOrderNumbersMap,
    options: ICardCopyRestOptions = {}
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const body: ICardCopyRestOptions = {
            ...{cardIdList},
            ...{cardsIdMap},
            ...{orderNumbersMap},
            ...options
        };
        return fetchHandler<ICardsCopyRestResult>(
            Util.getApiUrl(`${REST_CARD}/copy/`), {
                ...getRestHeadersPost(),
                body: JSON.stringify(body)
            }
        )
    };
    return action;
};
