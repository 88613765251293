import { TBoardId } from '../../../types/types';
import { postRest } from './api/postRest';
import { IBoard } from 'app/store/model/board/types';
import { BoardLoader } from 'app/helper/loader/boardLoader';
import { IFirstBoard } from 'app/rest/effects/firstBoard/types';
import { dispatch } from 'app/store/configureStore';

export const firstBoardLoadAndSave = (
    boardId: TBoardId
) => {
    const boardLoader = new BoardLoader(boardId);
    return boardLoader
        .load(null, false)
        .then((board: IBoard) => {
            const firstBoard: IFirstBoard = {
                id: boardId,
                firstBoardJSON: JSON.stringify(board)
            }
            return dispatch(postRest(boardId, firstBoard))
        })
};
