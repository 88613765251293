import { Dispatch, ThunkAction } from '../../../../../../../types/actions';

export const onClose = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        const modal = document.querySelector('.kanbanchi-free-trial-form-placeholder') as HTMLElement;
        if (modal) {
            modal.parentNode.removeChild(modal);
        }
    };
    return action;
};
