import * as React from 'react';
import { ERestActivityPropertyActionType } from '../../../../../../../types/rest/activity/IRestActivityBase';
import { IRestCardCheckListActivity } from '../../../../../../../types/rest/activity/IRestCardCheckListActivity';
import './_CardActivityCheckList.scss';
import { IActivityProps } from '../../../../boardDetails/activity/TabActivity/types';
import { TabActivityContext } from '../../../../boardDetails/activity/TabActivity/components/TabActivity/constants';
import { TActivity } from '../../../../../../../store/activities/types';
import {
    ActivityItemBody
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemBody/ActivityItemBody';
import { ActivityHeader } from '../../../../boardDetails/activity/TabActivity/components/ActivityHeader/ActivityHeader';
import {
    ActivityItemName
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemName/ActivityItemName';
import { Icon } from 'kui';
import {
    CLASS_ACTIVITY_ITEM,
    SMALL_ACTIVITY_MIN_ASIDE_WIDTH
} from '../../../../boardDetails/activity/TabActivity/components/constants';
import { getDateFormatted } from '../../../../../helpers/dateService';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { ECardProgressSize } from '../../../../../base/components/CardProgress/components/types';
import { CardProgress } from '../../../../../base/components/CardProgress/components/CardProgress';
import { v4 as uuidv4 } from 'uuid';
import { markdown } from '../../../../../../../helper/markdownHelper';
import { msFileThumbnailStorage } from '../../../../../../../helper/authorisation/microsoft/storage/msThumbnail';
import { GOOGLE_SPACING } from '../../../../../../../const';

export function CardActivityCheckList({
    activity,
    asidePanelWidth
}: IActivityProps<IRestCardCheckListActivity>) {
    const { onOpenCard } = React.useContext(TabActivityContext);
    const { label, card } = activity as TActivity;
    const { oldValue, newValue, checkListName, checkListProperty } = activity;
    const { isUserTimeFormat12 } = React.useContext(AsidePanelContext);
    const className = CLASS_ACTIVITY_ITEM + '__checklist';
    const [classUnique] = React.useState(className + '--' + uuidv4());
    if (activity.propertyActionType === ERestActivityPropertyActionType.DELETE) {
        // подменяем реального юзера на фейкового, так как был баг с удалением чеклистов в этот период
        const date = new Date(activity.time * 1000);
        if (date > new Date(2020, 11, 24) && date < new Date(2020, 11, 30)) {
            activity = {
                ...activity,
                user: {
                    fullName: 'System'
                }
            }
        }
    }
    const [newMarkdown, setNewMarkdown] = React.useState('');
    const [oldMarkdown, setOldMarkdown] = React.useState('');
    const [checklistMarkdown, setChecklistMarkdown] = React.useState('');
    React.useEffect(() => {
        let newMarkdown = activity.newValue;
        let oldMarkdown = activity.oldValue;
        let checklistMarkdown = activity.checkListName;

        setNewMarkdown(markdown(newMarkdown));
        setOldMarkdown(markdown(oldMarkdown));
        setChecklistMarkdown(markdown(checklistMarkdown));

        if (msFileThumbnailStorage.is()) {
            if (newMarkdown) {
                msFileThumbnailStorage.getSrcThumbnails(newMarkdown)
                    .then((text) => {
                        setNewMarkdown(markdown(text));
                    })
                    .catch(() => {});
            }
            if (oldMarkdown) {
                msFileThumbnailStorage.getSrcThumbnails(oldMarkdown)
                    .then((text) => {
                        setOldMarkdown(markdown(text));
                    })
                    .catch(() => {});
            }
            if (checklistMarkdown) {
                msFileThumbnailStorage.getSrcThumbnails(checklistMarkdown)
                    .then((text) => {
                        setChecklistMarkdown(markdown(text));
                    })
                    .catch(() => {});
            }
        }
    }, [activity]);
    return (
        <ActivityItemBody
            className={`
                ${className}
                ${classUnique}
                ${oldValue && newValue ? className + '--edit' : ''}
                ${!checkListName ? (!oldValue || !newValue) ? className + '--100' : '--50' : ''}
            `}
            header={<ActivityHeader
                icon={checkListProperty === 'checked' ?
                    <CardProgress
                        tooltip={null}
                        size={ECardProgressSize.BASE}
                        progress={100}
                    />
                    : 'done'}
                label={label}
                content={checkListProperty === 'checked' && asidePanelWidth <= SMALL_ACTIVITY_MIN_ASIDE_WIDTH && <>
                    {checkListName && <ActivityItemName isMarkdown name={checklistMarkdown} />}
                    <CardProgress
                        tooltip={null}
                        size={ECardProgressSize.BASE}
                        progress={100}
                    />
                </>}
            />}
            activity={activity}
            onClick={() => onOpenCard(card.id, activity.id, 'checklists')}
        >
            {(checkListProperty !== 'checked' || asidePanelWidth > SMALL_ACTIVITY_MIN_ASIDE_WIDTH) &&
                <>
                    {checkListProperty === 'checked' ?
                        <>{checkListName && <ActivityItemName isMarkdown name={checklistMarkdown} />}</>
                        : <>
                            {!!oldValue &&
                                <>
                                    {checkListName &&
                                        <ActivityItemName
                                            name={checklistMarkdown}
                                            isRemove={!!newValue}
                                            isMarkdown
                                        />
                                    }
                                    <ActivityItemName
                                        name={checkListProperty === 'dueDate' ? getDateFormatted(parseInt(oldValue), isUserTimeFormat12) : oldMarkdown}
                                        isRemove={!!newValue}
                                        style={{ height: GOOGLE_SPACING * 3 }}
                                        isMarkdown
                                        tooltip={checkListProperty === 'dueDate' ? getDateFormatted(parseInt(oldValue), isUserTimeFormat12) : activity.oldValue}
                                    />
                                </>
                            }
                            {!!oldValue && !!newValue && <Icon xlink={'forward'} size={24} />}
                            {!!newValue &&
                                <>
                                    {checkListName && <ActivityItemName name={checklistMarkdown} isMarkdown />}
                                    <ActivityItemName
                                        isMarkdown
                                        style={{ height: GOOGLE_SPACING * 3 }}
                                        name={checkListProperty === 'dueDate' ? getDateFormatted(parseInt(newValue), isUserTimeFormat12) : newMarkdown}
                                        tooltip={checkListProperty === 'dueDate' ? getDateFormatted(parseInt(newValue), isUserTimeFormat12) : activity.newValue}
                                    />
                                </>
                            }
                        </>
                    }
                </>
            }
        </ActivityItemBody>
    );
};
