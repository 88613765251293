import { Dispatch, ThunkAction } from '../../../types/actions';
import { TBoardId } from '../../../types/types';
import { boardPatchRest } from './api/helpers/boardPatchRest';

export const boardSetLogoColor = (
    boardId: TBoardId,
    logoColor: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        return dispatch(boardPatchRest(boardId, { logoColor }));
    };
    return action;
};
