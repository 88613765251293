import { connect } from 'react-redux';
import { onBackClick } from './events/onBackClick';
import { onDidMount } from './events/onDidMount';
import { IMicrosoftGroupPermissionEvents } from '../../components/MicrosoftGroupPermission/types';
import { MicrosoftGroupPermission } from '../../components/MicrosoftGroupPermission/MicrosoftGroupPermission';

const mapDispatchToProps = (
    dispatch: any
): IMicrosoftGroupPermissionEvents => {
    return {
        onDidMount: () => dispatch(onDidMount()),
        onBackClick: () => dispatch(onBackClick()),
    }
};

export const MicrosoftGroupPermissionHOC = connect(
    null,
    mapDispatchToProps
)(MicrosoftGroupPermission);

MicrosoftGroupPermissionHOC.displayName = 'MicrosoftGroupPermissionHOC';
