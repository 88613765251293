import { EThemeBackgroundOption } from '../../../../../../../../types/rest/IRestTheme';
import { getMessages } from '../../../../../../../../store/constants';

export const THEME_CHANGE_BACKGROUND = getMessages().getText('board_settings.theme.change_background');
export const THEME_WRONG_FILE_SIZE_TEXT = getMessages().getText('board_settings.theme.max_file_size');
export const THEME_USE_YOUR_OWN_TEXT = getMessages().getText('board_settings.theme.use_your_own');

export const ThemeBackgroundOptionList = [
    {
        key: EThemeBackgroundOption.DEFAULT,
        value: 'Default position',
    },
    {
        key: EThemeBackgroundOption.REPEAT,
        value: 'Tile image',
    },
    {
        key: EThemeBackgroundOption.SCALE,
        value: 'Stretch to full screen'
    }
];
