import * as React from 'react';
import { IPushNotificationsHelpButtonProps } from './types';
import { Button, Icon } from 'kui';
import { BOARD_PUSH_NOTIFICATION_HELP, BOARD_PUSH_NOTIFICATION_HELP_URL } from '../PushNotifications/constants';

export function PushNotificationsHelpButton ({
    onClick
}: IPushNotificationsHelpButtonProps) {
    return (
        <Button
            href={BOARD_PUSH_NOTIFICATION_HELP_URL}
            target="_blank"
            tooltip={BOARD_PUSH_NOTIFICATION_HELP}
            variant={'icon'}
            onClick={onClick}
        >
            <Icon
                size={16}
                xlink={'help'}
            />
        </Button>
    );
}
