import { getMessages } from '../../../../../../../store/constants';
import { TBoardAttachmentsTab } from '../../TabAttachments/components/TabAttachments/types';

export const BOARD_ATTACHMENTS_LIST_VIEW_TOOLTIP = getMessages().getText('card_form_attachments_tooltip_list');
export const BOARD_ATTACHMENTS_GRID_VIEW_TOOLTIP = getMessages().getText('card_form_attachments_tooltip_grid');

export const BOARD_ATTACHMENTS_TABS = [
    {
        title: getMessages().getText('board_settings.attachments.tabs.all'),
        value: TBoardAttachmentsTab.ALL
    },
    {
        title: getMessages().getText('board_settings.attachments.tabs.media'),
        value: TBoardAttachmentsTab.MEDIA
    },
    {
        title: getMessages().getText('board_settings.attachments.tabs.documents'),
        value: TBoardAttachmentsTab.DOCUMENTS
    }
];
