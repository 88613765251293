import * as React from 'react';
import { IAutoBackupsProps } from './types';
import { ISelectActiveInheritedProps, ISelectOptionsArray, Select } from 'kui';
import { AUTO_BACKUPS_TITLE, TEXT } from './constants';
import * as moment from 'moment';
import './_AutoBackups.scss';
import { AutoBackupsWarningDescriptionHOC } from '../../hocs/AutoBackupsWarningDescriptionHOC/AutoBackupsWarningDescriptionHOC';
import { AsidePanelContext } from '../../../../../asidePanel/components/AsidePanel/constants';
import { CLASS_BOARD_BACKUPS } from '../../../../constants';
import { ESettingsSectionType } from '../../../../../../base/components/SettingsSection/types';
import { SettingsSection } from '../../../../../../base/components/SettingsSection/SettingsSection';
import { AutoBackupsTogglerHOC } from '../../hocs/AutoBackupsTogglerHOC/AutoBackupsTogglerHOC';

export function AutoBackups ({
    isEnabled,
    isLoading,
    isProFeature,
    sectionType,
    time,
    onTimeChange,
    onDidMount,
    timezone
}: IAutoBackupsProps) {
    const { boardId } = React.useContext(AsidePanelContext);
    const isReadonly = sectionType === ESettingsSectionType.READONLY || isLoading;

    const className = CLASS_BOARD_BACKUPS + '__auto';
    const classText = CLASS_BOARD_BACKUPS + '__auto-text';
    const classSelect = CLASS_BOARD_BACKUPS + '__auto-select';

    let [timeOptions, setTimeOptions] = React.useState([] as ISelectOptionsArray);
    let [timeActive, setTimeActive] = React.useState(null as number);
    let [timeValue, setTimeValue] = React.useState(null as string);
    const selectRef = React.useRef(null);
    const timeRef = React.useRef(null);

    const formatTime = 'HH:mm';
    let timeString: string;

    if (time !== undefined && time !== null) {
        timeString = moment(time).tz(timezone).format(formatTime);
    }
    if (time === 0) {
        timeString = moment().tz(timezone).set('hours', 0).set('minutes', 0).format(formatTime);
    }

    React.useEffect(() => {
        buildTimeOptions();
    }, [time]);

    React.useEffect(() => {
        if (!timeRef.current && selectRef.current) {
            timeRef.current = selectRef.current.querySelector('input');
        }
        onDidMount();
    }, []);

    const buildTimeOptions = () => {

        timeOptions = [];
        timeActive = null;

        for (let i = 0; i < 24; i++) {
            const currValue = i * 60 * 60 * 1000;
            if (timeActive === null) {
                if (currValue === time) {
                    timeActive = i;
                } else if (currValue > time) {
                    timeActive = i;
                    timeOptions.push({
                        text: timeString,
                        value: time
                    });
                }
            }
            timeOptions.push({
                text: moment(currValue).utc().format(formatTime),
                value: currValue
            });
        }

        setTimeOptions(timeOptions);
        setTimeActive(timeActive);
        setTimeValue(timeString);
    };

    const onChangeHandler = (e: any) => {
        if (e.type ==='keydown') {
            const event = e as React.KeyboardEvent;
            if(event.key === 'Enter') return;
        }
        const event = e as ISelectActiveInheritedProps;
        if (event.item) {
            const time = Number(event.item.value);
            onTimeChange(time);
        } else {
            const target = event.target as HTMLInputElement;
            timeRef.current = selectRef.current.querySelector('input');
            if (target && target.value && timeRef.current) {
                const m = moment(target.value, formatTime);
                if (isNaN(m.hours()) || isNaN(m.minutes())) {
                    timeRef.current.value = timeValue;
                    setTimeValue(timeValue);
                } else {
                    setTimeValue(target.value);
                }
            }
        }

    };

    const timeChanged = (event: React.SyntheticEvent) => {
        const target = event.target as HTMLInputElement;
        if (target && target.value) {
            updateTime(target.value);
        }
    };

    const updateTime = (timeStr: string) => {

        const m = moment(timeStr, formatTime);
        if (isNaN(m.hours()) || isNaN(m.minutes())) {
            timeRef.current = selectRef.current.querySelector('input');
            if (timeRef.current) {
                timeRef.current.value = timeValue;
            }
            return;
        }

        const timeUnix = m.hours() * 60 * 60 + m.minutes() * 60;
        const time = timeUnix * 1000;

        onTimeChange(time);
        timeValue = moment(time).tz(timezone).format(formatTime);
        if (timeRef.current) {
            timeRef.current.value = timeValue;
        }
        setTimeValue(timeValue);
    };

    return (
        <SettingsSection
            className={className}
            title={AUTO_BACKUPS_TITLE}
            isProFeature={isProFeature}
            titleButton={<AutoBackupsTogglerHOC boardId={boardId} />}
        >
            <AutoBackupsWarningDescriptionHOC boardId={boardId} />
            <div className={classText}>
                {TEXT}
            </div>
            {isEnabled &&
                <Select
                    active={timeActive}
                    className={classSelect}
                    directionVertical={'down'}
                    disabled={isReadonly}
                    editable={!isReadonly}
                    label={'Time'}
                    options={timeOptions}
                    ref={selectRef}
                    value={timeValue}
                    onBlur={timeChanged}
                    onChange={onChangeHandler}
                    onEnter={timeChanged}
                />
            }
        </SettingsSection>
    );
}
