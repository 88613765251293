import { IApplicationState, TCardId, TChecklistId } from '../../../../../types/types'
import { getChecklist } from '../../checklists/selectors/getChecklist';
import { IChecklistItems } from '../types';

export const getChecklistItems = (
    state: IApplicationState,
    cardId: TCardId,
    checklistId: TChecklistId,
): IChecklistItems => {
    const checklist = getChecklist(state, cardId, checklistId) || {};
    return checklist.checkItems;
};
