import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { IGetState } from '../../../../../types/types';
import { extendTrial } from './extendTrial';
import { getAuthUser } from '../../../../../store/model/authUser/selectors/getAuthUser';

export const extendSecondTrial = (
    feedback: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const user = getAuthUser(getState());
        if (!user || !user.secondTrialCode) return Promise.reject();
        return dispatch(extendTrial(feedback, user.secondTrialCode));
    };
    return action;
};
