import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { ICardColorHOCOwnProps } from './types';
import { getCard } from '../../../../../../../store/model/selectors/getCard';
import { onChange } from './events/onChange';
import { getCardBackgroundColors } from '../../../../../../../const';
import { getBoardCardColors } from '../../../../../../../store/model/selectors/getBoardCardColors';
import { onOpen } from './events/onOpen';
import { Color } from '../../../../../base/components/Color/components/Color/Color';
import { IColorEvents, IColorFields } from '../../../../../base/components/Color/components/Color/types';
import { getAuthUser } from '../../../../../../../store/model/authUser/selectors/getAuthUser';
import { onClose } from './events/onClose';
import { COLOR_MODAL_TITLE_CARD } from '../../../../../base/components/Color/components/Color/constants';
import * as _ from 'underscore';

const mapStateToProps = () => {
    let cache: IColorFields = null;
    const propsCache = (
        props: IColorFields
    ): IColorFields => {
        if (_.isEqual(props, cache)) {
            return cache;
        }
        cache = props;
        return props;
    };

    const _mapStateToProps = (
        state: IApplicationState,
        ownProps: ICardColorHOCOwnProps
    ): IColorFields => {
        const { cardId, boardId } = ownProps;
        const card = getCard(state, cardId);
        const color = card.backgroundColor;
        const colors = getCardBackgroundColors(false);
        const boardColors = getBoardCardColors(state, boardId);
        const { allowCardColorCount } = getAuthUser(state);

        const props = propsCache({
            aloudCount: allowCardColorCount,
            allowTitle: COLOR_MODAL_TITLE_CARD,
            boardColors,
            color,
            colors,
        });

        return props;
    };
    return _mapStateToProps;
};

const mapDispatchToProps = (
    dispatch: any,
    ownProps: ICardColorHOCOwnProps
): IColorEvents => {
    const {cardId, boardId} = ownProps;
    return {
        onChange: (color: string) => dispatch(onChange(cardId, boardId, color)),
        onClose: () => dispatch(onClose()),
        onOpen: () => dispatch(onOpen(boardId)),
    }
};

export const CardColorHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(Color);

CardColorHOC.displayName = 'CardColorHOC';
