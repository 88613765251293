import * as React from 'react';
import { IRestListCommonActivity } from '../../../../../../../../types/rest/activity/IRestListCommonActivity';
import { IRestListActivityBase } from '../../../../../../../../types/rest/activity/IRestListActivityBase';
import { TabActivityContext } from '../TabActivity/constants';
import { IActivityProps } from '../../types';
import { TActivity } from '../../../../../../../../store/activities/types';
import { ActivityItemName } from '../ActivityItemName/ActivityItemName';
import { ActivityHeader } from '../ActivityHeader/ActivityHeader';
import { ActivityItemBody } from '../ActivityItemBody/ActivityItemBody';
import { Icon } from 'kui';
import { blockMarkdown } from '../../../../../../../../helper/markdownHelper';
import { msFileThumbnailStorage } from '../../../../../../../../helper/authorisation/microsoft/storage/msThumbnail';

export function ListActivityName ({
    activity
}: IActivityProps<IRestListCommonActivity>) {
    const { onOpenList } = React.useContext(TabActivityContext);
    const oldListActivity: IRestListActivityBase = {
        ...activity,
        list: {
            ...activity.list,
            name: activity.oldValue
        }
    };
    const { label, list } = activity as TActivity;

    const [newNameMarkdown, setNewNameMarkdown] = React.useState('');
    const [oldNameMarkdown, setOldNameMarkdown] = React.useState('');

    React.useEffect(() => {
        let newName = activity.newValue;
        let oldName = activity.oldValue;

        setNewNameMarkdown(blockMarkdown(newName));
        setOldNameMarkdown(blockMarkdown(oldName));

        if (msFileThumbnailStorage.is()) {
            if (newName) {
                msFileThumbnailStorage.getSrcThumbnails(newName)
                    .then((text) => {
                        setNewNameMarkdown(blockMarkdown(text));
                    })
                    .catch(() => {});
            }
            if (oldName) {
                msFileThumbnailStorage.getSrcThumbnails(oldName)
                    .then((text) => {
                        setOldNameMarkdown(blockMarkdown(text));
                    })
                    .catch(() => {});
            }
        }
    }, [activity]);

    return (
        <ActivityItemBody
            header={<ActivityHeader icon={'rename'} label={label} />}
            activity={oldListActivity}
            onClick={() => onOpenList(list.id)}
        >
            {activity.oldValue && <ActivityItemName name={oldNameMarkdown} isRemove isMarkdown tooltip={activity.oldValue} />}
            {activity.oldValue && <Icon xlink={'forward'} size={24} />}
            <ActivityItemName name={newNameMarkdown} isMarkdown tooltip={activity.newValue} />
        </ActivityItemBody>
    );

}
