import { getCard } from 'app/store/model/selectors/getCard';
import { IApplicationState } from 'app/types/types';
import { connect } from 'react-redux';
import { ICardActionsEvents, ICardActionsFields } from '../components/types';
import { ICardActionsHOCProps } from './types';
import { CardActions } from '../components/CardActions';
import { onCardArchive } from './events/onCardArchive';
import { onCardCopy } from './events/onCardCopy';
import { onCardDelete } from './events/onCardDelete';
import { onClick } from './events/onClick';
import { onUnarchive } from './events/onUnarchive';
import { getCardDone } from 'app/store/model/selectors/getCardDone';
import { onDone } from './events/onDone';
import { onMoreActions } from './events/onMoreActions';
import { onEdit } from './events/onEdit';
import { getCardCanBeSetDone } from 'app/store/model/selectors/getCardCanBeSetDone';
import { getAuthUser } from 'app/store/model/authUser/selectors/getAuthUser';
import { SegmentCardSourceValue } from 'app/middlewares/segment/trackEntities/cardEvents';
import { getBoardPermissions } from 'app/store/model/selectors/getBoardPermissions';
import { onRename } from './events/onRename';
import { onSetPriority } from './events/onSetPriority';
import { onAddColor } from './events/onAddColor';
import { onSetDate } from './events/onSetDate';
import { onAssign } from './events/onAssign';
import { getCardIsEpic } from 'app/store/model/selectors/getCardIsEpic';
import { TStatus } from 'app/types/model';
import { getShowProFeaturesTrial } from 'app/store/model/authUser/selectors/getShowProFeaturesTrial';
import { getList } from 'app/store/model/list/selectors/getList';
import { onGetLinkCard } from 'app/view/react_components/main/kanbanView/components/CardActions/hocs/events/onGetLinkCard';
import { getIsUserBasicPlanOnly } from '../../../../../../../store/model/authUser/selectors/getIsUserBasicPlanOnly';

const mapStateToProps = (
    state: IApplicationState,
    { cardId, boardId, portalId }: ICardActionsHOCProps
): ICardActionsFields => {
    const { allowArchive } = getAuthUser(state);
    const card = getCard(state, cardId);
    const { listId, status } = card;
    const isArchive = status === TStatus.STATUS_ARCHIVE;
    const list = getList(state, listId);

    return {
        isArchive,
        canDone: !getCardDone(state, cardId) && getCardCanBeSetDone(state, cardId),
        portalId,
        allowArchive,
        isReadonly: !getBoardPermissions(state, boardId).authPermissions.allowEdit,
        isEpic: getCardIsEpic(state, cardId),
        listId,
        listOrder: list.orderNumber,
        isBasicFeature: getIsUserBasicPlanOnly(state),
        isProFeature: getShowProFeaturesTrial(state)
    };
};

const mapDispatchToProps = (
    dispatch: any,
    ownProps: ICardActionsHOCProps
): ICardActionsEvents => {
    const {boardId, cardId, source = SegmentCardSourceValue.DROPDOWN, closePanelOnDelete } = ownProps;
    return {
        onCardArchive: () => dispatch(onCardArchive(boardId, cardId)),
        onCardCopy: () => dispatch(onCardCopy(cardId, source)),
        onCardDelete: () => dispatch(onCardDelete(cardId, source, closePanelOnDelete)),
        onClick: () => dispatch(onClick(source, cardId)),
        onUnarchive: () => dispatch(onUnarchive(cardId, source)),
        onDone: () => dispatch(onDone(cardId, source)),
        onMoreActions: () => dispatch(onMoreActions(cardId, source)),
        onEdit: () => dispatch(onEdit(cardId, source)),
        onRename: () => dispatch(onRename(cardId, source)),
        onSetPriority: () => dispatch(onSetPriority(cardId, source)),
        onSetDate: () => dispatch(onSetDate(cardId, source)),
        onAddColor: () => dispatch(onAddColor(cardId, source)),
        onAssign: () => dispatch(onAssign(cardId, source)),
        onGetLinkCard: () => dispatch(onGetLinkCard(cardId, boardId, source))
    };
};
export const CardActionsHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardActions);

CardActionsHOC.displayName = 'CardActionsHOC';
