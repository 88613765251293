import { CLASS_PAGE_SHOW_LISTS_BG } from "../../const";
import store, { dispatch, getAppState } from "../../store/configureStore";
import { ESegmentBlockerType } from '../../middlewares/segment/trackEntities/blockersEvents';
import { SegmentTeamWorkloadEvent, segmentTrackAction } from '../../middlewares/segment';
import { CLASS_TEAM_WORKLOAD_CARDS_SCROLL } from '../react_components/teamWorkload/constants';
import { getBoard } from '../../store/model/selectors/getBoardById';
import { getAuthUser } from '../../store/model/authUser/selectors/getAuthUser';
import { showSnoozeBlocker } from '../react_components/base/effects/showSnoozeBlocker';
import { storeListener } from '../../store/helpers/storeListener';

export const ListGroupingDashboardView = App.Views.AbstractDashboard.extend({
    initialize: function(options) {
        App.Views.AbstractDashboard.prototype.initialize.call(this, options);

        this.unsubscribeAllowListener = storeListener(
            store,
            state => getAuthUser(state).allowTeamWorkload,
            this.checkPermission.bind(this)
        );
    },

    render: function() {
        return this;
    },

    mouseScroll: function(event) {
        App.Views.AbstractDashboard.prototype.kanbanLikeScroll.call(this, event, CLASS_TEAM_WORKLOAD_CARDS_SCROLL);
    },

    onScroll: function () {
        App.Views.AbstractDashboard.prototype.onScroll.call(this, event);
    },

    checkPermission: function() {
        const isBlocked = !getAuthUser(getAppState()).allowTeamWorkload;
        const segmentEvent = isBlocked ? SegmentTeamWorkloadEvent.DISALLOW : SegmentTeamWorkloadEvent.OPEN;
        dispatch(segmentTrackAction(segmentEvent));
        if (isBlocked) {
            dispatch(showSnoozeBlocker({
                blockerType: ESegmentBlockerType.TEAMWORKLOAD,
                allow: 'allowTeamWorkload'
            }));
        }
        App.controller.mainView.blockerClassToggle(isBlocked);
    },

    onActivated: function() {
        App.Views.AbstractDashboard.prototype.onActivated.call(this);
        const board = getBoard(store.getState(), this.boardId);
        App.controller.mainView.themeHelper.applyTheme(board.theme);
        $('body')
            .addClass('page--classic')
            .addClass('page--teamworkload')
            .addClass(CLASS_PAGE_SHOW_LISTS_BG);
    },

    remove: function() {
        if (this.unsubscribeAllowListener) this.unsubscribeAllowListener();

        App.Views.AbstractDashboard.prototype.remove.call(this);
        $('body')
            .removeClass('page--classic')
            .removeClass('page--teamworkload')
            .removeClass(CLASS_PAGE_SHOW_LISTS_BG);
    }
});
