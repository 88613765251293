'use strict';

import * as React from 'react';
import { renderToStaticMarkup } from "react-dom/server";
import { getActivityItem } from "../react_components/aside_panel/boardDetails/activity/TabActivity/helpers/aсtivityHelper";
import { ActivityColorTags } from '../react_components/aside_panel/cardDetails/TabActivity/components/ActivityColorTags/ActivityColorTags';

App.Views.CardUpdateColorTagNotification = App.Views.BaseNotification.extend({

    getActions: function() {
        const item = getActivityItem(this.notificationModel);
        const { newColors = [], oldColors = [] } = this.notificationModel;
        return {
            bodyRaw: renderToStaticMarkup(<>
                {!!oldColors.length && <ActivityColorTags colors={oldColors} removed={true} />}
                {!!newColors.length && <ActivityColorTags colors={newColors} />}
            </>),
            action: item.label,
        };
    },

    onClick: function(e, notification) {
        var dashboardId = this.notificationModel.dashboardId;
        var cardId = this.notificationModel.card.id;
        App.controller.cleverOpenCard(dashboardId, cardId);
        this.closeNotification(notification);
        e.stopPropagation();
    }
});
