import * as React from 'react';
import './_CardAuthor.scss';
import { CLASS_MY_WORK_CARD } from '../../../constants';
import { UsersTooltip } from '../../../../../base/components/UsersTooltip/UsersTooltip';
import { ICardAuthorProps } from './types';
import { TUserTooltipNaming } from '../../../../../base/components/UsersTooltip/types';

export function CardAuthor ({
    ...props
}: ICardAuthorProps) {
    const className = CLASS_MY_WORK_CARD + '__author';
    return (
        <div className={className}>
            <UsersTooltip
                {...props}
                isBig
                naming={TUserTooltipNaming.AUTHOR}
                delayClose={1}
                isNoEvents
            />
        </div>
    );
};
