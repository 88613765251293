import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../../types/types';
import { TagsPanelButton } from '../../components/TagsPanelButton/TagsPanelButton';
import { ITagsPanelButtonEvents, ITagsPanelButtonFields } from '../../components/TagsPanelButton/types';
import { onClick } from './events/onClick';
import { ITagsPanelButtonHOCProps } from './types';
import { getBoardTags } from '../../../../../../../../store/model/board/selectors/getBoardTags';

const mapStateToProps = (
    state: IApplicationState,
    { boardId }: ITagsPanelButtonHOCProps
): ITagsPanelButtonFields => {
    const tags = getBoardTags(state, boardId);
    return {
        indicatorNumber: tags.length,
        tooltip: null,
    }
};

const mapDispatchToProps = (
    dispatch: any,
    {onClick: ownOnClick}: ITagsPanelButtonHOCProps
): ITagsPanelButtonEvents => {
    return {
        onClick: () => dispatch(onClick(ownOnClick))
    }
};

export const TagsPanelButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(TagsPanelButton);

TagsPanelButtonHOC.displayName = 'TagsPanelButtonHOC';
