import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../types/types';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { snackbarSuccessDefault } from '../../../../../../snackbarsStack';
import { getMessages, root } from '../../../../../../../../store/constants';

export const onLinkCopy = (
    url: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.ATTACHED_FILE_COPY_LINK_CLICKED));
        if (navigator.clipboard) {
            navigator.clipboard.writeText(url).then(() => {
                dispatch(snackbarSuccessDefault({
                    id: 'SNACKBAR_ID_LINK_COPIED',
                    text: getMessages().getText('card_form_getlink_copied')
                }));
            });
        } else {
            let copyText = '<p>Press <strong>Ctrl+C</strong> to copy link to clipboard and press OK.</p>' +
                '<p style="margin-bottom: 0;"><textarea id="js-card-link" class="textarea-info autoSelect" autofocus>' +
                url + '</textarea></p>';
            root.App.controller.showInfo(copyText, getMessages().getText('dialog__get_link_to_card'));
            $('#js-card-link').select().on('click', function() {
                // @ts-ignore
                this.select();
            });
        }
    };
    return action;
};
