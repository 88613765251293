import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../types/types';
import { showSnoozeBlocker } from '../../../../../../base/effects/showSnoozeBlocker';

export const onClickDisallowGoogleSheetExport = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(showSnoozeBlocker({
            allow: 'allowGoogleSheetExport'
        }));
    };
    return action;
};
