import {
    SHOW_SECOND_PANEL_CLASS,
    SHOW_MODAL_CLASS
} from './../../const';
import { historyPush } from './../react_components/base/helpers/historyHelper';

import BaseView from './../base_view';

let BaseDialogRenderer = BaseView.extend({
    initialize(options) {
        this.modifiers = [];
        App.vent.on(App.vent['modal:closed'], this.hide, this);
        this.currentView = null;
    },

    render() {
        return this;
    },

    show(view, modifier) {
        this.beforeShow(view, modifier);
        this.showCurrentView();
        this.$el.addClass(SHOW_MODAL_CLASS);
        historyPush({
            state: { page: 'BaseDialogRenderer' },
            onpopstate: () => this.hide()
        });
    },

    beforeShow(view, modifier) {
        this.clear();
        this.resetModifiers();
        this.currentView = view;
        if (modifier) {
            this.$el.addClass(modifier);
        }
    },

    showCurrentView() {
        this.$el.append(this.currentView.render().el);
        if (this.currentView.afterDOMRendering) {
            this.currentView.afterDOMRendering();
        }
    },

    hide() {
        if (this.currentView && this.currentView.onBeforeCloseDialog) {
            this.currentView.onBeforeCloseDialog();
        }
        this.clear();
        this.$el.removeClass(`${SHOW_MODAL_CLASS} ${SHOW_SECOND_PANEL_CLASS}`);
        this.resetModifiers();
    },

    clear() {
        if (this.currentView) {
            this.currentView.remove();
            this.currentView = null;
        }
    },

    resetModifiers() {
        this.modifiers.forEach((modifier) => {
            this.$el.removeClass(modifier);
        });
    },

    showLoader() {
        this.$el.find('.loader').removeClass('hidden');
    },

    hideLoader() {
        this.$el.find('.loader').addClass('hidden');
    },

    remove() {
        this.clear();
        App.vent.off(null, null, this);
        return Backbone.View.prototype.remove.call(this);
    },

    testAttributes() {
        return {
            type: "dialog"
        };
    }
});
App.Views.BaseDialogRenderer = BaseDialogRenderer;
App.Views.BaseDialogRenderer.mixin(App.Mixins.Test);

export default BaseDialogRenderer;
