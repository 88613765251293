import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { SegmentCardEvent, SegmentCardOptions, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { SegmentCardType } from '../../../../../../../../middlewares/segment/trackEntities/cardEvents';
import { ISegmentEventOption } from '../../../../../../../../middlewares/segment/trackEntities/types';

export const onDropDownOpen: ActionCreator<ThunkAction> = (
    source: SegmentCardType,
) => {
    const action = (
        dispatch: Dispatch,
    ) => {
        const option: ISegmentEventOption = source && {
            name: SegmentCardOptions.TYPE,
            value: SegmentCardType.CARD_INPUT
        };
        dispatch(segmentTrackAction(SegmentCardEvent.OPEN_PRIORITY, option));
    };
    return action;
};
