import { Dispatch, ThunkAction } from '../../../../types/actions';
import { THintKey } from '../types';
import { hintActionSetAction } from '../store/hints/actions/hintActionSetAction';
import { hintCanShowSetAction } from '../store/hint/actions/hintCanShowSetAction';
import { THintCanShow } from '../store/hint/types';

export const setHintCanShow = (
    key: THintKey,
    canShow: THintCanShow = true,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        dispatch(hintActionSetAction(key, hintCanShowSetAction(canShow)));
    };
    return action;
};
