import { combineReducers } from 'redux';
import { savedFiltersReducer } from './savedFilters/reducers/savedFiltersReducer';
import { TFiltersState } from './types';
import { filterPanelBoardsReducer } from './filterPanelBoards/reducers/filterPanelBoardsReducer';
import { filterMatchReducer } from './filterMatch/reducers/filterMatchReducer';

export const filtersReducer = combineReducers<TFiltersState>({
    filterPanelBoards: filterPanelBoardsReducer,
    savedFilters: savedFiltersReducer,
    filterMatch: filterMatchReducer
});
