import { ERoutes } from './../constants';
import { IApplicationState } from '../../../types/types';
import { EPanelRoute } from '../constants';

export const getPanelTab = (
    state: IApplicationState
): EPanelRoute => {
    const { route } = state.router;
    if (!route) return null;

    switch (route) {
        case ERoutes.CARD_ACTIVITY: {
            return EPanelRoute.ACTIVITY;
        }
        case ERoutes.CARD_TIMING: {
            return EPanelRoute.TIMING;
        }
        default: {
            return null;
        }
    }
}
