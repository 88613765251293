import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { IGetState, TBoardId } from '../../../../../../../../../types/types';
import { getCardNumberByBoardId } from '../../../store/cardNumbers/selectors/getCardNumberByBoardId';
import { cardNumberSetAction } from '../../../store/cardNumbers/actions/cardNumberSetAction';
import { statusChange } from '../../../store/cardNumber/actions/statusChange';
import { ECardNumberStatus } from '../../../store/cardNumber/types';
import { toggleClickedSegmentEvent } from '../../effects/segmentEvents';
import { cardNumberStateUpdateAction } from '../../../store/cardNumber/actions/cardNumberUpdate';
import { boardSetCardNumberEnabled } from '../../../../../../../../../rest/effects/board/boardSetCardNumberEnabled';
import { getAuthUser } from '../../../../../../../../../store/model/authUser/selectors/getAuthUser';
import { root } from '../../../../../../../../../store/constants';
import { showSnoozeBlocker } from '../../../../../../../base/effects/showSnoozeBlocker';

export const toggleEnabledCardNumber = (
    boardId: TBoardId
): ThunkAction => {
    const action = (dispatch: Dispatch, getState: IGetState) => {
        const state = getState();
        const { allowCardNumber } = getAuthUser(state);
        const { enabled } = getCardNumberByBoardId(state, boardId);
        const newValue = !enabled;
        if (!allowCardNumber && newValue) {
            dispatch(showSnoozeBlocker({ allow: 'allowCardNumber' }));
        } else if (
            !allowCardNumber &&
            root.App.controller.allowCounterSnackbarHelper.isShowSnackbar('allowCardNumber')
        ) {
           return Promise.resolve(false);
        } else {
            dispatch(toggleClickedSegmentEvent(newValue));
            dispatch(cardNumberSetAction(boardId, cardNumberStateUpdateAction({
                enabled: newValue,
                status: ECardNumberStatus.ENABLING
            })));
            return dispatch(boardSetCardNumberEnabled(boardId, newValue)).then(() => {
                dispatch(cardNumberSetAction(boardId, statusChange(ECardNumberStatus.LOADED)));
                return Promise.resolve(true);
            });
        }
    }
    return action;
}
