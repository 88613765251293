import {ActionCreator} from 'redux';
import {Dispatch, ThunkAction} from '../../../../../../../types/actions';
import {toggleFilter} from '../effects/toggleFilter';
import { filterClickedSegmentEvent } from '../../../effects/segmentEvents';
import { ESegmentFilterTarget } from '../../../../../../../middlewares/segment/trackEntities/topBarEvents';

export const onFilterButtonClicked: ActionCreator<ThunkAction> = (
    boardId: number
) => {
    const action = (
        dispatch: Dispatch,
    ) => {
        dispatch(toggleFilter());
        dispatch(filterClickedSegmentEvent(ESegmentFilterTarget.options, boardId));
    };
    return action;
};
