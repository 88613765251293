import { AsidePanelActionsButton } from '../../../../..';
import * as React from 'react';
import { IPrefixesPanelButtonProps } from './types';
import { CLASS_BOARD_DETAILS_SECTION } from '../../../../constants';
import { BOARD_SECTION_PREFIXES } from '../../constants';

export function PrefixesPanelButton ({
    indicatorBoolean,
    isDisabled,
    isBasicFeature,
    tooltip,
    onClick
}: IPrefixesPanelButtonProps) {

    return (
        <AsidePanelActionsButton
            children={null}
            indicatorBoolean={indicatorBoolean}
            isDisabled={isDisabled}
            isBasicFeature={isBasicFeature}
            sectionSelector={`.${CLASS_BOARD_DETAILS_SECTION}--prefixes`}
            title={BOARD_SECTION_PREFIXES}
            tooltip={tooltip}
            onClick={onClick}
        />
    );
}
