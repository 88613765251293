import { IGetState, TCardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { saveDescriptionLocal } from '../effects/saveDescriptionLocal';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { getCard } from '../../../../../../../../store/model/selectors/getCard';

export const onSaveUnsavedDescription = (
    cardId: TCardId,
    description: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(saveDescriptionLocal(cardId, description));
        const state = getState();
        const oldDescription = getCard(state, cardId).description;
        if (oldDescription === description.trim()){
            dispatch(segmentTrackAction(SegmentCardEvent.DESCRIPTION_CANCEL_WITHOUT_CHANGES_CLICKED));
        }
    };
    return action;
};
