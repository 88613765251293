import { IApplicationState } from '../../../../../../../types/types';
import { getAuthUser } from '../../../../../../../store/model/authUser/selectors/getAuthUser';
import { getIsUserBasicPlanOnly } from '../../../../../../../store/model/authUser/selectors/getIsUserBasicPlanOnly';

export const checkBrandingTabAllow = (
    state: IApplicationState,
): boolean => {
    if (getIsUserBasicPlanOnly(state)) {
        const { allowBranding } = getAuthUser(state);
        return allowBranding;
    }
    return true;
}
