import { getCard } from './getCard';
import { TStatus } from '../../../types/model';
import {IApplicationState} from '../../../types/types';
import { getListCardIds } from './getListCardIds';

export const getListCardIdsByStatus = (
    state: IApplicationState,
    listId: number,
    status: TStatus = TStatus.STATUS_ACTIVE,
): number[] => {
    const cardIds = getListCardIds(state, listId);
    const cardIdsByStatus = cardIds.reduce((arr, cardId) => {
        const card = getCard(state, cardId);
        if (
            card &&
            card.status === status
        ) {
            arr.push(cardId);
        }
        return arr;
    }, []);
    return cardIdsByStatus;
};
