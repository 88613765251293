import { ISharedUser } from './rest/ISharedUser';
import { IUserBoardPermissionsBackboneModel } from './backbone/IUserBoardPermissionsBackboneModel';
import { TColorId } from './types';
import { ICard } from '../store/model/card/types';
import { IDriveDoc } from '../store/model/card/types/IDriveDoc';
import { IList } from '../store/model/list/types';
import { IBoard } from '../store/model/board/types';
import { IAuthUser } from '../store/model/authUser/types';
import { IRestRelatedCard } from './rest/IRestRelatedCard';
import { IRestColor } from './rest/IRestColor';
import { ISubscription } from '../view/react_components/subscriptionAdmin/store/subscription/types';
import { INotificationBeforeDueDate } from '../store/model/notificationBeforeDueDate/types';
import { IRestCardAssigneeRole } from './rest/IRestCardAssigneeRole';
import { IAppTab } from '../store/model/tabs/types';

export interface IBackboneModel {
    toReduxJSON?: () => IBoard | IList | ICard | IAuthUser;
    toJSON?: () => IBoard | IList | IBackboneCardModel | IAppTab;
    get?: (property: string) => any;
    destroy?(param: { success: (value?: (PromiseLike<any> | any)) => void; url?: string }): void;
    save?: (param: {}, param2: IBackboneSync) => void;
    set?(property: any, b?: any, options?: any): void;
    triggerLocalChange?: (options?: any) => void;
    trigger?: (name: string, ...params: any) => void;
    isEdu?: () => boolean;
    saveUserSettings?: (settings: any, options?: any) => void;
    unloadFromMemory?: (options?: any) => void;
    triggerStatusLocalChange?: () => void;
    previous?: (property: string) => any;
}

export interface IBackboneSync {
    patch?: boolean;
    success?: (value?: (PromiseLike<any> | any)) => void;
    error?: (value?: (PromiseLike<any> | any)) => void;
}

/***
 * Dashboard and relations
 */

export interface IColor extends IRestColor {
    cardsCount?: number;
}

export interface ICardAssigneeRole extends IRestCardAssigneeRole {}

export interface IAuthorRest {
    permissionId?: string;
    fullName?: string;
    firstName?: string;
    lastName?: string;
    initials?: string;
    photoUrl?: string;
    hash?: string;
}

export interface IPublicPermissions {
    type: string;
    access: string;
    accessType: number;
}

export interface IPermissions {
    authPermissions: IUserBoardPermissionsBackboneModel;
    publicPermissions: IPublicPermissions;
    dashboardId: number;
}

export interface IFilteredListCardNumbers {
    [id: number]: number;
}

export interface ITag {
    name: string;
    count: number;
}

export interface ILockStatus {
    locked: boolean,
    blockedBy: string,
    lockDate: number
}

export interface IReportsUser extends ISharedUser {
    isSelected: boolean;
    color: string;
    syntheticKey: string;
    initials: string;
    authUser: boolean;
    online: boolean;
    hash: string;
    dashboardPermissionId?: string
}

/***
 * Card and relations
 */

export interface IChecklistStats {
    checkedCount: number;
    totalCount: number;
    checkedPercent: number;
    estimate: number; // items total estimate
}

export interface ISubcardsStats {
    totalCount: number;
    doneCount: number;
    donePercent: number;
}

export interface ISuccessor {
    type: string;
    status: number;
    id: number;
    cardId: number;
    predecessorId: number;
}

export interface ITimetracker {
    total: string;
}

export interface IBackboneDashboardModel extends  IBackboneModel {
    initNotifications: () => void;
}

export interface IBackboneCardModel extends  IBackboneModel{
    assignedToDetails: any[];
    author: IAuthorRest;
    backgroundColor: string;
    calendarEvents: any[];
    checklistStats: IChecklistStats;
    colorIds: TColorId[];
    commentCount: number;
    comments: any[];
    created: number;
    customFields: any[];
    description: string;
    done: boolean;
    driveDocCount: number;
    driveDocs: IDriveDoc[];
    dueDate?: any;
    dueDateExpired?: any;
    epicId?: any;
    estimate: number;
    estimateStr: string;
    ganttOrderNumber?: number;
    ganttVisibility: boolean;
    id: number;
    isEpic: boolean;
    isGanttCard: boolean;
    isLoading?: boolean;
    lastChangeTimeStamp: number;
    listId: number;
    listName: string;
    listViewOrderNumber: number;
    loadedEpicId?: number;
    mainDriveDocSet: any[];
    // matchDashboardFilter: boolean;
    moved: boolean;
    name: string;
    notificationBeforeDueDateIds: number[];
    orderNumber: number;
    predecessors: ISuccessor[];
    priority: string;
    processingPercent: number;
    processingStatus: string;
    relatedCardCount: number;
    relatedCards: IRestRelatedCard[];
    selected: boolean;
    startDate?: any;
    status: number;
    subCardOrderNumber: number;
    subCardType: string;
    subCardsCount: number;
    subCardsRelationIds: number[];
    successors: ISuccessor[];
    tags: string[];
    timetracker: ITimetracker;
    totalSpentTimeStr: string;
    visible: boolean;
    wtch: boolean;
    cardNumber: string;
}

/***
 * NotificationBeforeDueDate and relations
 */

export enum ITimeUnits {
    MINUTES = 'MINUTES',
    HOURS = 'HOURS',
    DAYS = 'DAYS',
    WEEKS = 'WEEKS',
    MONTHS = 'MONTHS'
}

export interface INotificationBeforeDueDateSetting {
    isPopupOpened: boolean;
}
export interface INotificationBeforeDueDateCardEditState {
    notifications: INotificationBeforeDueDate[],
    settings: INotificationBeforeDueDateSetting
}

export enum TStatus {
    STATUS_ACTIVE = 0,
    STATUS_DELETED = 1,
    STATUS_ARCHIVE = 2,
    STATUS_FREEZED= 3,
    STATUS_SERVICE_TEMPLATE = 30,
}
export interface ILastManager {
    fullName: string;
    initials: string;
    photoUrl: string;
    userId: string;
    allowReports: boolean;
}

export interface IActiveSubscription extends ISubscription {
    isTrial: boolean;
    isPromo: boolean;
}

export interface IOwnSubscription extends ISubscription {

}

export enum EColorTagColor {
    RED = 'red',
    GREEN = 'green',
    BLUE = 'blue',
    YELLOW = 'yellow',
    ORANGE = 'orange',
    PINK = 'pink',
    PURPLE = 'purple',
    CYAN = 'cyan',
    SKY = 'sky',
    LIME = 'lime',
    BLACK = 'black',
    JAZZBERRY_JAM = 'jazzberry-jam',
    SWISS_COFFEE = 'swiss-coffee',
    PERSIMMON = 'persimmon',
    PARIS_DAISY = 'paris-daisy',
    LAWN_GREEN = 'lawn-green',
    SPRING_GREEN = 'spring-green',
    MANZ = 'manz',
    LUCKY = 'lucky',
    AQUA = 'aqua',
    AQUAMARINE = 'aquamarine',
    LIGHT_SKY_BLUE = 'light-sky-blue',
    NEON_BLUE = 'neon-blue',
    ELECTRIC_PURPLE = 'electric-purple',
    BRIGHT_PURPLE = 'bright-purple',
    BISMARK = 'bismark',
    INDIGO = 'indigo',
    PASTEL_RED = 'pastel-red',
    PASTEL_ORANGE = 'pastel-orange',
    PASTEL_YELLOW = 'pastel-yellow',
    PASTEL_GREEN = 'pastel-green',
    PASTEL_BLUE = 'pastel-blue',
    PASTEL_PURPLE = 'pastel-purple',
    PASTEL_PINK = 'pastel-pink',
    PASTEL_BROWN = 'pastel-brown',
    PASTEL_LIGHT_GREY = 'pastel-light-grey',
    PASTEL_GREY = 'pastel-grey',
    VIVID_RED = 'vivid-red',
    VIVID_ORANGE = 'vivid-orange',
    VIVID_YELLOW = 'vivid-yellow',
    VIVID_GREEN = 'vivid-green',
    VIVID_BLUE = 'vivid-blue',
    VIVID_PURPLE = 'vivid-purple',
    VIVID_PINK = 'vivid-pink',
    VIVID_BROWN = 'vivid-brown',
    VIVID_LIGHT_GREY = 'vivid-light-grey',
    VIVID_GREY = 'vivid-grey',
}

export type TColorTagColor = EColorTagColor | string;
