import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { segmentTrackAction } from '../actions/segmentTrackAction/segmentTrackAction';

const category = 'Board menu';

export const SegmentBoardMenuEvent = {
    ACTIVITY_CLICKED: { category, event: 'Activity clicked' },
    CLOSE_CLICKED: { category, event: 'Close clicked' },
    TEMPLATE_CLICKED: { category, event: 'Card templates clicked' },
    RECURRING_CLICKED: { category, event: 'Recurring tasks clicked' },
    RENAME_CLICKED: { category, event: 'Rename clicked' },
    SETTINGS_CLICKED: { category, event: 'Settings clicked' },
    STARRED_CLICKED: { category, event: 'Starred clicked' },
}

export enum ESegmentBoardMenuOptions {
    location = 'location',
    success = 'success'
}

export enum ESegmentBoardMenuLocation {
    topBar = 'top bar',
    leftPanel = 'left panel',
    navPanel = 'nav panel',
    navPanelFavorite = 'nav panel favorite',
}

export const boardSettingsClickedSegmentEvent: ActionCreator<ThunkAction> = (
    location: ESegmentBoardMenuLocation
) => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentBoardMenuEvent.SETTINGS_CLICKED, {
        name: ESegmentBoardMenuOptions.location,
        value: location
    }))
};

export const boardActivityClickedSegmentEvent: ActionCreator<ThunkAction> = (
    location: ESegmentBoardMenuLocation
) => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentBoardMenuEvent.ACTIVITY_CLICKED, {
        name: ESegmentBoardMenuOptions.location,
        value: location
    }))
};

export const boardRenameClickedSegmentEvent: ActionCreator<ThunkAction> = (
    location: ESegmentBoardMenuLocation
) => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentBoardMenuEvent.RENAME_CLICKED, {
        name: ESegmentBoardMenuOptions.location,
        value: location
    }))
};

export const boardStarredClickedSegmentEvent: ActionCreator<ThunkAction> = (
    location: ESegmentBoardMenuLocation
) => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentBoardMenuEvent.STARRED_CLICKED, {
        name: ESegmentBoardMenuOptions.location,
        value: location
    }))
};

export const boardCloseClickedSegmentEvent: ActionCreator<ThunkAction> = (
    location: ESegmentBoardMenuLocation
) => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentBoardMenuEvent.CLOSE_CLICKED, {
        name: ESegmentBoardMenuOptions.location,
        value: location
    }))
};

export const boardTemplatesSegmentEvent = (
    location: ESegmentBoardMenuLocation,
    success: boolean
): ThunkAction => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentBoardMenuEvent.TEMPLATE_CLICKED, {
        name: ESegmentBoardMenuOptions.location,
        value: location
    }, [{
        name: ESegmentBoardMenuOptions.success,
        value: String(success)
    }]))
};

export const boardRecurringCardsSegmentEvent = (
    location: ESegmentBoardMenuLocation,
    success: boolean
): ThunkAction => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentBoardMenuEvent.RECURRING_CLICKED, {
        name: ESegmentBoardMenuOptions.location,
        value: location
    }, [{
        name: ESegmentBoardMenuOptions.success,
        value: String(success)
    }]))
};
