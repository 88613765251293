import { msalConfig } from 'app/helper/authorisation/microsoft/oauth/AuthConfig';
import { PopupRequest, PublicClientApplication, SilentRequest } from '@azure/msal-browser';
import { INIT_SCOPE, OFFLINE_ACCESS } from 'app/helper/authorisation/microsoft/oauth/Scopes';
import { root } from 'app/store/constants';
import { IRestAuthUser } from 'app/types/rest/IRestAuthUser';
import { dispatch } from 'app/store/configureStore';
import { synchronizeUserDrives } from 'app/rest/effects/utils/synchronizeUserDrives';

const myMSALObj = new PublicClientApplication(msalConfig );
const channelId=56; //see  ms-login.jsp
/***
 * класс отвечает за общение с microsoft
 */
export class Provider {

    authorizeWithCode (scopes: string[], hint?: string  ) {

        scopes.push(OFFLINE_ACCESS);
        return new Promise<IRestAuthUser>((resolve,reject) =>{
            const authUrl = new URL('https://login.microsoftonline.com/common/oauth2/v2.0/authorize');

            authUrl.searchParams.append('client_id', root.Settings.microsoft.clientId);
            authUrl.searchParams.append('response_type', 'code');
            authUrl.searchParams.append('redirect_uri', window.location.origin + '/rest/ms-login');
            authUrl.searchParams.append('response_mode', 'query');
            authUrl.searchParams.append('scope', scopes.join(' '));
            if (hint) {
                authUrl.searchParams.append('login_hint', hint);
            } else {
                authUrl.searchParams.append('prompt', 'select_account');
            }
            // attach a listener for the message event to setup our channel
            const listener = (event: MessageEvent<any>) => {
                if (event.source && event.source === win) {
                    const message = event.data;
                    console.warn(message);
                    if (message.channelId === channelId) {
                        window.removeEventListener('message', listener);
                        if (message.user) {
                            resolve(message.user as IRestAuthUser);
                            win.close();
                        } else {
                            reject({} as IRestAuthUser);
                        }
                    }
                }
            };
            window.addEventListener('message', listener);
            console.warn(authUrl.href);

            let win = window.open(authUrl.href);

            let timer = setInterval(function() {
                if(win.closed) {
                    clearInterval(timer);
                    window.removeEventListener('message', listener);
                    console.warn('close by timer');
                    reject({} as IRestAuthUser);
                }
            }, 500);
        })

    }

    acquireToken(
        request: PopupRequest,
        loginHint: string,
        silentOnly = false,
        popupOnly = false
    ) {
        if (popupOnly) {
            return this.acquireTokenPopup(request, loginHint).then(result => result.accessToken);
        }
        return this.getActiveAccount(loginHint)
            .then((account)=>{
                let silentRequest: SilentRequest;
                silentRequest = {
                    ...request,
                    account,
                    forceRefresh: false
                };

                return myMSALObj.acquireTokenSilent(silentRequest)
                    .then(tokenResponse => {
                        console.warn(tokenResponse);
                        return tokenResponse;
                    })
            }).catch( (error) =>{

                console.warn('silent token acquisition fails. acquiring token using popup', error);
                if (silentOnly) {
                    console.warn('silentOnly');
                    return Promise.reject(error);
                }

                return this.acquireTokenPopup(request, loginHint);
            }).then(result => result.accessToken);

    }

    acquireTokenPopup(
        request: PopupRequest,
        loginHint: string,
    ) {
        let popupRequest: PopupRequest;
        popupRequest = {
            ...request,
            loginHint,
        };
        return myMSALObj.acquireTokenPopup(popupRequest)
            .then(tokenResponse => {
                console.warn(tokenResponse);
                myMSALObj.setActiveAccount(tokenResponse.account);
                return tokenResponse;
            }).catch(error => {
                console.error(error);
                return Promise.reject(error);
            })

    }

    getActiveAccount(loginHint: string){
        let account = myMSALObj.getActiveAccount();
        if (account) {
            return Promise.resolve(account);
        } else {
            return myMSALObj.ssoSilent({scopes:[], loginHint})
                .then((authResult)=> {
                    myMSALObj.setActiveAccount(authResult.account);
                    return authResult.account;
                });
        }
    }

    logout(){
        myMSALObj.setActiveAccount(null);
        return Promise.resolve();
    }

    static resetActiveAccount(){
        myMSALObj.setActiveAccount(null);
    }

    static getInitScopes(){
        return INIT_SCOPE;
    }

    static signInFinished() {
        dispatch(synchronizeUserDrives())
    }
}

export const providerInstance= new Provider();
