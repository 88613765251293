import { getMessages } from '../../../../../../../store/constants';

export const CARD_DESCRIPTION_ADD = getMessages().getText('card_form_description_add');
export const CARD_DESCRIPTION_SHOW_MORE = getMessages().getText('card_form_description_show_more');
export const CARD_DESCRIPTION_SHOW_LESS = getMessages().getText('card_form_description_show_less');
export const CARD_DESCRIPTION_UNSAVED = getMessages().getText('card_form_description_unsaved');
export const CARD_DESCRIPTION_UNSAVED_DELETE_TOOLTIP = getMessages().getText('card_from_description_unsaved_delete');
export const CARD_DESCRIPTION_SAVE = getMessages().getText('card_form_description_save');
export const CARD_DESCRIPTION_CANCEL = getMessages().getText('card_form_description_cancel');
export const CARD_DESCRIPTION_MARKDOWN = getMessages().getText('card_form_description_markdown');
export const CARD_DESCRIPTION = getMessages().getText('common.card_description');
export const CARD_DESCRIPTION_TOOLTIP_PIN = getMessages().getText('common.pin_to_card');
export const CARD_DESCRIPTION_TOOLTIP_UNPIN = getMessages().getText('common.unpin_from_card');

export const ESC_WHICH_KEY_NUMBER = 27;
export const ENTER_WHICH_KEY_NUMBER = 13;
