import { IApplicationState, TBoardId, TColorId } from '../../../../../../../types/types';
import { IColor, TStatus } from '../../../../../../../types/model';
import { createDeepEqualSelector } from '../../../../../helpers/memoizeHelper';
import { getBoardColors } from '../../../../../../../store/model/board/selectors/getBoardColors';
import { getBoardCards } from '../../../../../../../store/model/selectors/getBoardCards';
import { ICard } from '../../../../../../../store/model/card/types';

const getBoardCardColorTagsSelector = (
    boardColors: IColor[],
    cards: ICard[]
): TColorId[] => {
    const cardColorIds = cards.reduce<TColorId[]>((colorIds, card) => {
        if (card.colorIds) {
            card.colorIds.forEach(colorId => {
                if (!colorIds.includes(colorId)) {
                    colorIds.push(colorId);
                }
            })
        }
        return colorIds;
    }, []);

    return boardColors.reduce<TColorId[]>((result, boardColor) => {
        if (cardColorIds.includes(boardColor.id) || boardColor.status === TStatus.STATUS_ACTIVE) {
            result.push(boardColor.id);
        }
        return result;
    }, []);

};

export const getBoardCardColorTagIds: (
    state: IApplicationState,
    boardId: TBoardId,
) => TColorId[] = createDeepEqualSelector(
    getBoardColors,
    getBoardCards,
    getBoardCardColorTagsSelector,
);
