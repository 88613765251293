'use strict';

import { storeListener } from "../../store/helpers/storeListener";
import store, { dispatch } from "../../store/configureStore";
import {
    filterPanelIsEnabledSelector
} from "../react_components/aside_panel/filterPanelBoard/store/selectors/filterPanelIsEnabledSelector";
import * as blockerSegmentEvents from '../../middlewares/segment/helpers/blockersSegmentEvents';
import { ESegmentBlockerType } from '../../middlewares/segment/trackEntities/blockersEvents';
import {
    CARD_CLASS,
    CARDS_DROPDOWN_SCROLL_CLASS,
    CARDS_SCROLL_CLASS,
    LIST_CLASS,
    LIST_CLASS_HEADER
} from "../react_components/main/kanbanView";
import { CLASS_HTML_SCROLL, CLASS_MAIN_PANEL_SCROLL, EViewTypes } from "../../const";
import { getBoardPermissionsAllowEdit } from '../../store/model/selectors/getBoardPermissionsAllowEdit';
import { getBoardPermissions } from '../../store/model/selectors/getBoardPermissions';
import { getBoard } from '../../store/model/selectors/getBoardById';
import { getAuthUser } from '../../store/model/authUser/selectors/getAuthUser';
import { TStatus } from '../../types/model';
import { showSnoozeBlocker } from '../react_components/base/effects/showSnoozeBlocker';
import { BLOCKER_TYPES, CLASS_ABSTRACT_DASHBOARD_SCROLL_PREVENT } from './constants';
import { getSnoozeBlockerClosed } from '../react_components/main/kanbanView/store/selectors/getSnoozeBlockerClosed';
import { getBoardIsOnSharedDrive } from '../../store/model/selectors/getBoardIsOnSharedDrive';
import * as _ from 'underscore';
import { createRoot } from 'react-dom/client';
import { getParentsClasses } from "../react_components/helpers/getParentsClasses";
import { getTypeSwitcherActiveType } from "../react_components/typeSwitcher/store/selectors/getTypeSwitcherActiveType";
import { getIsSwimlanesView } from "../react_components/main/kanbanView/store/selectors/getIsSwimlanesView";
import { boardClose } from "../react_components/base/effects/boardClose";

App.Views.AbstractDashboard = Backbone.View.extend({
    tagName: 'div',
    className: 'board-view board-view--invisible',

    initialize: function(options) {
        this.boardId = options.boardId;
        this.scrollElement = null;
        _.bindAll(this, 'render', 'createCopyDashboardHelp', 'mouseScroll', 'onScroll', 'scrollOnDrag');
        this.parent = options.parent;
        this.realtimeView = options.realtimeView;
        this.helpView = null;
        this.filterInfoPanelView = null;
        this.initListView();
        if (getBoardPermissionsAllowEdit(store.getState(), this.boardId)) {
        //if (this.model.isAllowEdit()) {
            var filterPanelBoardSelector = (state) => {
                return filterPanelIsEnabledSelector(state, this.boardId);
            };

            var filterPanelBoardListener = () => {
                this.resizeDashboardAfterFilter()
            };

            this.unsubscribeFilterPanelBoard = storeListener(
                store,
                filterPanelBoardSelector,
                filterPanelBoardListener);

        }

        this.unsubscribeAllowListener = storeListener(
            store,
            state => this.getBlockerType(state),
            this.checkPermission.bind(this)
        );

        this.root = createRoot(this.el);
        this.scrollCurrent = null;
        this.scrollDebounce = null;
        this.scrollBar = null;
        this.scrollInterval = null;

        this.initEventListner();
    },

    /**
     * Метод создан для привязки всех листнеров
     */
    initEventListner: function() {
        const permissionsBoardSelector = (state) => {
            return getBoardPermissions(state, this.boardId);
        };
        const permissionsBoardListener = () => {
            this.render()
        };
        this.unsubscribePermissionsBoardListener = storeListener(
            store,
            permissionsBoardSelector,
            permissionsBoardListener);


        App.vent.on(App.vent['view:resized'], this.resizeBoard, this);
    },

    /**
     * Абстрактный метод инициализация листов доски в переменную,
     * используется для возможности инициализации листов определённого типа
     */
    initListView: function() {
    },

    /**
     * Абстрактный метод, который используется для задания максимальной допустимой высоты элементов (карт, листов, доски)
     */
    setElementsMaxSize: function() {
    },

    /**
     * Абстрактный метод, который перехватывает прокрутку колёсика мыши,
     * создан для возможного разделения реакции на прокрутку колеса в разных типах доскок
     * @param event Объект события прокрутки
     */
    mouseScroll: function(event) {
    },

    kanbanLikeScroll: function(event, listClass = CARDS_SCROLL_CLASS) {
        //  считаем, что в дефолтном портале kui-portal нет ничего важного, его можно удалять
        //  хинты и другие важные тултипы надо складывать в другой портал
        const tooltip = document.querySelector('#kui-portal .kui-tooltip--show');
        if (tooltip) tooltip.remove();
        // this.$el.closest('.board').stop(); //todo stop currentScrolling to card
        const isMacLike = navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i) ? true : false;

        const hoveredElements = document.querySelectorAll( ':hover');
        if (hoveredElements) {
            const isIgnoreScroll = !!Array.from(hoveredElements).find(item => {
                const isString = typeof item.className === 'string';//проверка на строку (при наведении на svg получаем object)
                return isString ? item.className.includes(CLASS_ABSTRACT_DASHBOARD_SCROLL_PREVENT) : false;
            });
            if (isIgnoreScroll) return;
        }

        if (!isMacLike && event.target && event.deltaX) { // горизонтально по тачпаду + возможно второе колесо мыши
            this.scrollElement.scrollLeft += event.deltaX;
        }

        let isInnerScroll = false;
        if (!event.shiftKey) {
            const list = $(event.target).closest(`.${listClass}`);

            if (list.find(`.${CARD_CLASS}`).length) {
                const el = list[0];
                isInnerScroll = el.scrollHeight > el.clientHeight;
            }
        }

        if (!isInnerScroll) {
            const moveTo = $(event.target).closest('.move-to__column-items');
            const el = moveTo[0];
            isInnerScroll = moveTo.length && el.scrollHeight > el.clientHeight;
        }

        if (!isInnerScroll) {
            const dropDown = $(event.target).closest('.' + CARDS_DROPDOWN_SCROLL_CLASS);
            const dropDownItem = dropDown.length && dropDown.find('.kui-dropdown__item');
            const el = dropDownItem[0];
            isInnerScroll = dropDownItem.length && el.scrollHeight > el.clientHeight;
        }

        if (!isMacLike && !isInnerScroll && event.target) {
            const left = event.deltaX || event.deltaY;
            this.scrollElement.scrollLeft += left;
        }
    },

    onScrollSwimlanes: function() {
        if (!this.listsContainer) this.listsContainer = document.querySelector('.lists-container');
        const boardWidth = Math.min(this.el.clientWidth || 0, this.listsContainer && this.listsContainer.clientWidth || 0);
        const swimlanesFull = document.querySelector('.list-component__swimlane--top');
        const swimlanesFullWidth = swimlanesFull && swimlanesFull.clientWidth || 0;
        const scroll = document.documentElement.scrollLeft || (
            this.el.scrollWidth > boardWidth
                ? this.el.scrollLeft
                : 0
            );
        const swimlanesTitle = document.querySelectorAll('.list-component__swimlane-scroll');
        if (swimlanesTitle) {
            swimlanesTitle.forEach((swimlane) => {
                const swimlaneTitle = swimlane.querySelector('.list-component__swimlane-title');
                const titleWidth = swimlaneTitle && swimlaneTitle.clientWidth || 0;
                const scrollFixed = scroll + titleWidth > swimlanesFullWidth
                    ? swimlanesFullWidth - titleWidth
                    : scroll;
                swimlane.style.marginLeft = scrollFixed + 'px';
                const rightWidth = swimlanesFullWidth - scroll;
                swimlane.style.width = (rightWidth > boardWidth ? boardWidth : rightWidth) + 'px';
            });
        }
    },

    onScroll: function(event) {
        if (this.scrollCurrent !== 'html' &&
            event && event.target && event.target.classList &&
            event.target.classList.contains(CLASS_MAIN_PANEL_SCROLL)
        ) {
            if (this.scrollDebounce) clearTimeout(this.scrollDebounce);
            this.scrollDebounce = setTimeout(() => this.scrollCurrent = null, 300);
            this.scrollCurrent = 'bar';
            this.scrollElement.scrollLeft = event.target.scrollLeft;
        } else if (this.scrollCurrent !== 'bar'){
            if (this.scrollDebounce) clearTimeout(this.scrollDebounce);
            this.scrollDebounce = setTimeout(() => this.scrollCurrent = null, 300);
            this.scrollCurrent = 'html';
            this.scrollBar.scrollLeft = this.scrollElement.scrollLeft;
        }
        if (this.isSwimlanesView) {
            const isMacLike = navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i) ? true : false;
            if (isMacLike) { // KNB-3756 на маке слишком сильно дергается
                if (this.scrollDebounce) clearTimeout(this.scrollDebounce);
                this.scrollDebounce = setTimeout(() => this.onScrollSwimlanes(), 20);
            } else {
                this.onScrollSwimlanes()
            }
        }
    },

    scrollInitialize: function() {
        const viewType = getTypeSwitcherActiveType(store.getState(), this.boardId);
        if (viewType === EViewTypes.KANBAN_VIEW_TYPE) {
            this.scrollElement = document.documentElement;
        } else {
            this.scrollElement = document.querySelector('.board-view');
            this.isSwimlanesView = getIsSwimlanesView(store.getState(), this.boardId);
        }
        document.documentElement.classList.add(CLASS_HTML_SCROLL);
        this.scrollElement.addEventListener('mousedown', this.scrollOnDrag);
        if (this.mouseScroll) this.el.addEventListener('wheel', this.mouseScroll);

        this.scrollInterval = setInterval(() => { // wait main panel scroll
            this.scrollBar = document.querySelector('.' + CLASS_MAIN_PANEL_SCROLL);
            if (this.scrollBar) {
                clearInterval(this.scrollInterval);
                document.addEventListener('scroll', this.onScroll, true); // bind scroll with fake scrollBar
                this.onScroll(); // fix scrollBar if board opened with recentlyScrolledListId
            }
        }, 500);
    },

    scrollOnDrag: function (e) {
        const target = e.target;

        const parentClasses = getParentsClasses(target, [
            CARD_CLASS,
            LIST_CLASS_HEADER,
            LIST_CLASS + '__new-card',
            LIST_CLASS + '__new-card-input',
            'team-workload__click-catcher',
            'team-workload__add-card-buttons',
            'list-component__swimlane-scroll',
            'board'
        ]);

        if (!parentClasses.includes('board')) return;

        const scrollElement = this.scrollElement;

        const currentScrollX = scrollElement.scrollLeft;
        const currentScrollY = scrollElement.scrollTop;
        const x = e.clientX;
        const y = e.clientY;

        const userSelect = target.style.userSelect;
        target.style.cursor = 'grabbing';
        target.style.userSelect = 'none';

        const onMouseMove = function (e) {
            const moveX = e.clientX - x;
            const moveY = e.clientY - y;
            scrollElement.scrollLeft = currentScrollX - moveX;
            scrollElement.scrollTop = currentScrollY - moveY;
        };

        const onMouseUp = function () {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);

            target.style.cursor = 'unset';
            target.style.removeProperty(userSelect);
        };

        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
    },

    scrollRemove: function() {
        document.documentElement.classList.remove(CLASS_HTML_SCROLL);
        this.scrollElement.scrollLeft = 0;
        document.removeEventListener('scroll', this.onScroll, true);
        this.scrollElement.removeEventListener('mousedown', this.scrollOnDrag);
    },

    render: function () {
        this.$el
            .attr('id', this.parent.type + '-' + this.boardId);
        if (!getBoardPermissionsAllowEdit(store.getState(), this.boardId)) {
            this.$el.addClass('board--perm-readonly');
            App.controller.trackEvent(
                Messages.getText('ga.category.dashboard'),
                Messages.getText('ga.action.openReadonly')
            );
        }
        return this;
    },

    /**
     * Абстрактный метод который выполняется сразу после this.render
     */
    afterDOMRendering: function() {
        var self = this;
        if (this.lists && this.lists.afterDOMRendering) {
            this.lists.afterDOMRendering();
        }
        setTimeout(function() {
            self.resizeBoard();
            self.bindListsScroll();
        }, 30);
        this.checkPermission();
        if (!App.controller.isRelatedPanelNotOpened()) {
            App.vent.trigger(App.vent['secondPanel:show']);
        }
    },

    getBlockerType: function(state) {
        const board = getBoard(state, this.boardId);
        const user = getAuthUser(state);
        if (board.status === TStatus.STATUS_ARCHIVE && !user.allowArchive) {
            return BLOCKER_TYPES.ARCHIVE
        }
        if (getBoardIsOnSharedDrive(state, this.boardId) && !user.allowSharedDrive && !getSnoozeBlockerClosed(state)) {
            return BLOCKER_TYPES.SHARED_DRIVES;
        }
        return null;
    },

    checkPermission: function(){
        //TODO мозможно стоит проаверять именно на открытое окно блокеров.
        if(App.controller.isRelatedPanelNotOpened()) {
            App.controller.secondPanel.hide();
        }
        const blocker = this.getBlockerType(store.getState());
        if (blocker) {
            const isBlockerTypeArchive = blocker === BLOCKER_TYPES.ARCHIVE;
            const blockerType = isBlockerTypeArchive ? ESegmentBlockerType.ARCHIVE : ESegmentBlockerType.SHARED_DRIVES;
            dispatch(showSnoozeBlocker({
                blockerType,
                onClose: () => {
                    if (isBlockerTypeArchive) {
                        dispatch(boardClose(this.boardId));
                    } else {
                        App.controller.mainView.blockerClassToggle(false);
                        App.vent.trigger(root.App.vent['secondPanel:close']);
                    }
                },
                allow: isBlockerTypeArchive ? 'allowArchive' : 'allowSharedDrive'
            }));
            dispatch(blockerSegmentEvents.blockerShownSegmentEvent(blockerType));
        }
        App.controller.mainView.blockerClassToggle(!!blocker);
    },

    //TODO зачем так, проверить посмотреть
    resizeDashboardAfterFilter: function() {
        var self = this;
        setTimeout(function() {
            self.resizeBoard();
        }, 0);
    },

    /**
     * Абстрактный метод, который производит измеение размеров элементов доски (карт, листов и т.п.)
     * //TODAY
     * Выполняется после события view:resized, вызвание по $(window).resize(
     */
    resizeBoard: function() {
        this.setElementsMaxSize();
    },

    /**
     * Метод активации доски её отображение и привязка отслеживание к колёски мышки
     * @param dashboard Объект доски
     */
    activate: function(boardId) {
        if (this.boardId === boardId) {
            this.render();
            this.$el.removeClass('board-view--invisible');
            this.showHelp();
            this.onActivated();
        }
    },

    onActivated: function () {
        App.vent.trigger(App.vent['dashboards:loaded']);
        this.scrollInitialize();
        App.controller.showUpdatesTipsKNB2502Topbar();
    },

    onDeactivated: function () {

    },

    /**
     * Метод деактивации доски её отображение и привязка отслеживание к колёски мышки
     * @param dashboard Объект доски
     */
    deactivate: function(boardId) {
        if (this.boardId === boardId) {
            this.onDeactivated();
            this.remove();
        }
    },

    /**
     * Метод привязки события скролла колеса мышки к определённой функции.
     */
    bindListsScroll: function() {
        this.$el.find('.js-cards')
            .addClass('cards--scroll-top')
            .on('scroll', _.throttle(this.listsScrolled, 50));
    },

    /**
     * Метод который вызывается через 50 мсекунд после прокрутки колеса мышки
     * Метод используется для визуального сопровождения события прокрутки колеса мышки
     */
    listsScrolled: function() {
        if (!$(this).scrollTop()) {
            $(this).addClass('cards--scroll-top');
        } else {
            $(this).removeClass('cards--scroll-top');
        }
        if ($(this).scrollTop() + $(this).innerHeight() >= $(this)[0].scrollHeight) {
            $(this).addClass('cards--scroll-bottom');
        } else {
            $(this).removeClass('cards--scroll-bottom');
        }
    },

    /**
     * Метод который предлагает копировать текущую доску себе.
     * Выполняется один раз для одного браузера, при условии что доска помечена как Шаблон.
     */
    showHelp: function() {
        const board = getBoard(store.getState(), this.boardId);
        if (board.template) {
            var seen = false;
            if (localStorage.seenDashboards) {
                var seenDashboardIds = localStorage.seenDashboards.split('|');
                seen = _.indexOf(seenDashboardIds, this.boardId + '') > -1;
                if (!seen)
                    localStorage.seenDashboards += '|' + this.boardId;
            } else {
                localStorage.seenDashboards = this.boardId;
            }
            if (!seen) {
                setTimeout(this.createCopyDashboardHelp, 5000);
            }
        }
    },

    /**
     * Метод который открывает хелпер для копирования доски
     */
    createCopyDashboardHelp: function() {
        this.removeHelpView();
        this.helpView = new App.Views.BalloonRenderer();
        this.helpView.showCopyDashboard(this.boardId);
        this.$el.append(this.helpView.el);
    },


    /**
     * Закрытие хелпера
     */
    removeHelpView: function() {
        if (this.helpView) {
            this.helpView.forceRemove();
            this.helpView.remove();
        }
        this.helpView = null;
    },

    /**
     * Абстрактный метод который выполняется после открытия доски поиска/фильтрации
     */
    filterPanelToggled: function() {
        this.setElementsMaxSize();
    },

    /**
     * Вешать на доску класс, если нет карт
     */
    toggleEmptyClass: function() {
        
    },

    /**
     * Абстрактный метод деструктор.
     * @returns {*}
     */
    //TODO как-то вынести отвязку мыши отсюда в класик доску
    remove: function() {
        if (this.lists) {
            this.lists.remove();
        }
        if (this.helpView) {
            this.helpView.remove();
        }
        if (this.filterInfoPanelView) {
            this.filterInfoPanelView.remove();
        }
        App.vent.off(null, null, this);
        if(this.unsubscribeFilterPanelBoard){
            this.unsubscribeFilterPanelBoard();
        }
        if (this.unsubscribePermissionsBoardListener) {
            this.unsubscribePermissionsBoardListener();
        }
        if (this.unsubscribeAllowListener) this.unsubscribeAllowListener();
        if (this.mouseScroll) this.el.removeEventListener('wheel', this.mouseScroll);
        if (this.scrollInterval) clearInterval(this.scrollInterval);
        this.root.unmount();
        return Backbone.View.prototype.remove.call(this);
    }
});
