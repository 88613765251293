import * as React from 'react';
import { ICardDetailsSubcard, ICardSubcardsElementsProps } from './types';
import { ListItem } from '../../../../../base/components/ListItem/ListItem';
import './_CardSubcardsElements.scss';
import { Button, ButtonDropdown, Done, Icon, SelectList, SelectListItem, Switch, Tooltip } from 'kui';
import { CLASS_CARD_DETAILS } from '../../../constants';
import { TCardId } from '../../../../../../../types/types';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import {
    HIDE_FROM_GANTT_TOOLTIP,
    SHOW_ON_GANTT,
    SHOW_ON_GANTT_TOOLTIP
} from '../../../ShowOnGanttButton/components/ShowOnGanttButton/constants';
import { LIST_TOOLTIP, SUBCARD_DELETE_BUTTON_TOOLTIP } from './constants';
import { getAsidePanelTooltip } from '../../../../asidePanel/helpers/getAsidePanelTooltip';
import * as _ from 'underscore';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { CLASS_LIST_ITEM_CONTAINER } from '../../../../../base/components/ListItem/constants';
import { UsersTooltip } from '../../../../../base/components/UsersTooltip/UsersTooltip';
import { ColorsTooltip } from '../../../../../base/components/ColorsTooltip/ColorsTooltip';
import { markdown } from '../../../../../../../helper/markdownHelper';
import { CARD_SUBCARD_WEIGHTS } from '../../../../../../../const';
import { CardActionsHOC } from '../../../../../main/kanbanView/components/CardActions/hocs/CardActionsHOC';
import { SegmentCardSourceValue } from '../../../../../../../middlewares/segment/trackEntities/cardEvents';
import { CardProgressHOC } from '../../../../../base/components/CardProgress/hocs/CardProgressHOC';
import ReactHtmlParser from 'react-html-parser';

export function CardSubcardsElements({
    boardId,
    boardUsers,
    boardRoles,
    elements,
    sectionType,
    onDelete,
    onGantt,
    onSort,
    onSubcardLink,
    onWeightChange,
    isHideDoneSubcards
}: ICardSubcardsElementsProps) {
    const isShowWeights = elements.find(el => el.weight && el.weight > 1)
    const isReadonly = sectionType === ESettingsSectionType.READONLY;
    const { cardId, isMobile } = React.useContext(AsidePanelContext);
    const CLASS_CARD_SUBCARD = CLASS_CARD_DETAILS + '-subcard';
    const CLASS_CARD_SUBCARDS = CLASS_CARD_DETAILS + '-subcards';
    const CLASS_CARD_SUBCARDS_ITEM = CLASS_CARD_DETAILS + '-subcards__item';
    const className = CLASS_CARD_SUBCARD;
    const classNameHideDone = CLASS_LIST_ITEM_CONTAINER + '--hide';
    const classHighlight = CLASS_CARD_SUBCARDS_ITEM + '--highlight';
    const classShowWeights = isShowWeights ? CLASS_CARD_SUBCARDS + '--weights-visible' : '';
    const classNameLine = CLASS_CARD_SUBCARD + '__line';
    const classNameLine1 = classNameLine + ' ' + classNameLine + '--1';
    const classNameLine2 = classNameLine + ' ' + classNameLine + '--2';
    const classNameColumn = CLASS_CARD_SUBCARD + '__col';
    const classNameColumnName = classNameColumn + ' ' + classNameColumn + '--name';
    const classNameColumnGantt = classNameColumn + ' ' + classNameColumn + '--gantt';
    const classNameColumnList = classNameColumn + ' ' + classNameColumn + '--list';
    const classNameColumnAssignees = classNameColumn + ' ' + classNameColumn + '--assignees';
    const classNameName = CLASS_CARD_SUBCARD + '__name';
    const classNameGantt = CLASS_CARD_SUBCARD + '__gantt';
    const classNameColorTags = CLASS_CARD_SUBCARD + '__color-tags';
    const classNameNumber = CLASS_CARD_SUBCARD + '__number';
    const classWeight = CLASS_CARD_SUBCARD + '__weight';
    const classWeightList = CLASS_CARD_SUBCARD + '__weights-list';

    const [weights] = React.useState([...Array(CARD_SUBCARD_WEIGHTS)]);
    const [weightsOpened, setWeightsOpened] = React.useState(false);
    const [actionsOpened, setActionsOpened] = React.useState(false);

    const elementsRef = React.useRef(null);
    const highlightTimer = React.useRef(null);
    const subcardIds = React.useRef(elements.map(element => element.id));
    const highlightRef = React.useRef(null);
    const [highlightSubcardId, setHighlightSubcardId] = React.useState(null);

    function onSubcardLinkHandler(event: React.MouseEvent, cardId: TCardId) {
        event.preventDefault();
        onSubcardLink(cardId);
    }

    function onDragEnd(result: DropResult) {
        if (
            !result.destination ||
            result.destination.index === result.source.index
        ) {
            // do nothing
        } else {
            onSort(Number(result.draggableId), result.destination.index, elements.map(element => element.id));
        }
    }

    React.useEffect(() => {
        const newCard = elements.find(element => !subcardIds.current.includes(element.id));
        if (newCard) {
            elementsRef.current.querySelector(`.${CLASS_CARD_SUBCARDS_ITEM}--id-${newCard.id}`).scrollIntoView();
            if (!newCard.isNew) {
                highlightRef.current = newCard.id;
                setHighlight();
            }
        }
        subcardIds.current = elements.map(element => element.id);
    }, [elements.length]);

    React.useEffect(() => {
        return () => {
            if (highlightTimer.current) clearTimeout(highlightTimer.current);
        }
    }, []);

    function setHighlight() {
        if (highlightTimer.current) clearTimeout(highlightTimer.current);
        highlightTimer.current = setTimeout(() => {
            setHighlightSubcardId(highlightRef.current);
            highlightTimer.current = setTimeout(() => { // убрать подсветку
                highlightRef.current = null;
                setHighlightSubcardId(null);
            }, 2000)
        }, 100)
    }

    if (highlightRef.current) {
        setHighlight();
    }

    return (
        <div ref={elementsRef}>
            <DragDropContext
                onDragEnd={onDragEnd}
            >
                <Droppable
                    droppableId={String(cardId)}
                >
                    {(provided, snapshot) => (
                        <div
                            className={`
                            ${CLASS_CARD_SUBCARDS}
                            ${classShowWeights}
                        `}
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {elements.map((element: ICardDetailsSubcard, index) => {
                                const isColorTags = !!element.colorTags && !!element.colorTags.length;

                                const img = (
                                    <Icon
                                        size={24}
                                        xlink={element.icon}
                                    />
                                );

                                let classNameGanttIcon = classNameGantt;
                                if (element.ganttVisibility) classNameGanttIcon = classNameGanttIcon + ' ' + classNameGanttIcon + '--active';
                                const isSubcardDone = Math.abs(element.processingPercent - 100) < Math.exp(-8) || element.processingStatus ==='done';// element.processingPercent = 100

                                const body = (<>
                                    <div className={className}>
                                        <div className={classNameLine1}>
                                            <div className={classNameColumnName}>
                                                <Button
                                                    className={classNameName}
                                                    href={element.link}
                                                    tooltip={{
                                                        isNoEvents: true,
                                                        isNoWrap: true,
                                                        value: isMobile ? null : markdown(element.fullName)
                                                    }}
                                                    variant={'icon-text'}
                                                    onClick={(event: React.MouseEvent) => onSubcardLinkHandler(event, element.id)}
                                                >
                                                    {!!element.cardNumber &&
                                                    <span className={classNameNumber}>
                                                        {element.cardNumber}
                                                    </span>
                                                    }
                                                    <span>
                                                        {ReactHtmlParser(markdown(element.name))}
                                                    </span>
                                                </Button>
                                            </div>
                                            {!isReadonly &&
                                            <>
                                                <ButtonDropdown
                                                    className={classWeight}
                                                    dropdownClassName={classWeightList}
                                                    directionHorizontal={'right'}
                                                    onOpen={() => setWeightsOpened(true)}
                                                    onClose={() => setWeightsOpened(false)}
                                                >
                                                    <Button
                                                        variant={'icon-text'}
                                                        text={String(element.weight)}
                                                    >
                                                        <Icon size={16} xlink={'weight'} />
                                                    </Button>
                                                    <SelectList
                                                        active={element.weight - 1}
                                                    >
                                                        {
                                                            weights.map((el, i) => {
                                                                const weight = i + 1;
                                                                return (
                                                                    <SelectListItem
                                                                        value={String(weight)}
                                                                        key={i}
                                                                        onClick={() => onWeightChange(element.id, weight)}
                                                                    >
                                                                        {weight}
                                                                    </SelectListItem>
                                                                )
                                                            })
                                                        }
                                                    </SelectList>
                                                </ButtonDropdown>
                                                <Button
                                                    className={CLASS_CARD_SUBCARD + '__delete'}
                                                    tooltip={
                                                        getAsidePanelTooltip({
                                                            value: SUBCARD_DELETE_BUTTON_TOOLTIP,
                                                        })
                                                    }
                                                    variant={'icon'}
                                                    onClick={() => onDelete(element.id)}
                                                >
                                                    <Icon size={24} xlink={'clear'} />
                                                </Button>
                                                <CardActionsHOC
                                                    cardId={element.id}
                                                    source={SegmentCardSourceValue.SUBCARDS_LIST}
                                                    closePanelOnDelete={false}
                                                    onOpen={() => setActionsOpened(true)}
                                                    onClose={() => setActionsOpened(false)}
                                                    boardId={boardId}
                                                    portal={true}
                                                />
                                            </>
                                            }
                                            {isColorTags &&
                                            <div className={classNameColorTags}>
                                                <ColorsTooltip
                                                    colors={element.colorTags}
                                                    isAsidePanel
                                                />
                                            </div>
                                            }
                                        </div>
                                        <div className={classNameLine2}>
                                            <div className={classNameColumnGantt}>
                                                <Button
                                                    disabled={isReadonly}
                                                    tooltip={getAsidePanelTooltip({
                                                        value: isMobile ? null : element.ganttVisibility ? HIDE_FROM_GANTT_TOOLTIP : SHOW_ON_GANTT_TOOLTIP
                                                    })}
                                                    text={SHOW_ON_GANTT}
                                                    variant={'icon-text'}
                                                    onClick={() => onGantt(element.id)}
                                                >
                                                    <Icon
                                                        className={classNameGanttIcon}
                                                        size={16}
                                                        xlink={'gantt-chart'}
                                                    />
                                                </Button>
                                                <Switch
                                                    checked={element.ganttVisibility}
                                                    disabled={isReadonly}
                                                    tabIndex={-1}
                                                    onChange={()=>{}}
                                                />
                                            </div>
                                            <Tooltip
                                                direction={'up-right'}
                                                isNoEvents
                                                isNoWrap
                                                value={isMobile ? null : `${LIST_TOOLTIP} ${_.escape(element.listName)}`}
                                            >
                                                <div className={classNameColumnList}>
                                                    {element.listName}
                                                </div>
                                            </Tooltip>
                                            <CardProgressHOC cardId={element.id} />
                                            <div className={classNameColumnAssignees}>
                                                <UsersTooltip
                                                    isBig
                                                    users={element.assignees}
                                                    boardUsers={boardUsers}
                                                    roles={boardRoles}
                                                    isNoEvents={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>);
                                return (
                                    <Draggable
                                        draggableId={String(element.id)}
                                        index={index}
                                        isDragDisabled={isReadonly || weightsOpened || actionsOpened}
                                        key={String(element.id)}
                                    >
                                        {(provided, snapshot) => {
                                            return (
                                                <div
                                                    className={`${CLASS_LIST_ITEM_CONTAINER}
                                                    ${isHideDoneSubcards && isSubcardDone ? classNameHideDone : ''}`}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    ref={provided.innerRef}
                                                >
                                                    <ListItem
                                                        actions={null}
                                                        className={`
                                                            ${CLASS_CARD_SUBCARDS_ITEM}
                                                            ${CLASS_CARD_SUBCARDS_ITEM}--id-${element.id}
                                                            ${highlightSubcardId === element.id ? classHighlight : ''}
                                                        `}
                                                        img={img}
                                                        isDraggable={!isReadonly}
                                                        isLoading={!!element.activeRequests && highlightSubcardId !== element.id}
                                                        key={element.id}
                                                        text={body}
                                                    />
                                                </div>
                                            );
                                        }}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
};
