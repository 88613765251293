import { Dispatch, ThunkAction } from '../../../types/actions';
import { IGetState } from '../../../types/types';
import { getAuthUser } from '../../../store/model/authUser/selectors/getAuthUser';
import { fetchHandler } from 'app/util/fetchHandler';
import { IRestPushNotificationItem } from 'app/types/rest/IRestPushNotificationItem';
import Util from 'app/util/util';
import { getRestHeadersGet } from 'app/rest/helpers/getRestHeadersHelper';
import { authUserSetMarkAllReadLastDate } from 'app/rest/effects/authUser/authUserSetMarkAllReadLastDate';

export const pushNotificationFixSeen = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        let date = getAuthUser(getState()).markAllReadLastDate;
        date = date || 1;
        if (date < 1) {
            return Promise.resolve();
        }
        return fetchHandler<IRestPushNotificationItem>(
            Util.getApiUrl('/rest/pushnotification/fixSeen'), {
                ...getRestHeadersGet(),
            }
        ).then( res => {
            console.log(res);
            dispatch(authUserSetMarkAllReadLastDate(-date));
        })
    };
    return action;
};
