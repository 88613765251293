import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId, TChecklistId, TChecklistItemId } from '../../../../../../../../types/types';
import { checklistItemsUpdate } from '../../../../../../../../rest/effects/card/checklist/checklistItemsUpdate';
import { IChecklistItem } from '../../../../../../../../store/model/checklists/checklist/types';
import { addChecklistItem } from '../../effects/addChecklistItem';

export const sortChecklistItems = (
    cardId: TCardId,
    checklistId: TChecklistId,
    itemIds: TChecklistItemId[],
    newItem?: IChecklistItem
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const items: IChecklistItem[] = itemIds.map((itemId, index) => {
            if (newItem && newItem.id === itemId) {
                dispatch(addChecklistItem(cardId, checklistId, { ...newItem, orderNumber: index }, true));
            }
            return {
                id: itemId,
                orderNumber: index,
            };
        }).filter(item => !newItem || item.id !== newItem.id);
        return dispatch(checklistItemsUpdate(cardId, checklistId, items));
    };
    return action;
};
