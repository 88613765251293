import { getTemplateListPromise } from '../../../helpers/templates/getTemplateListPromise';
import { INewCard } from '../../../../store/model/card/types';
import { NEW_TEMPLATE_NAME } from '../../../../view/react_components/aside_panel/cardTemplates/CreateNew/constants';
import { TStatus } from '../../../../types/model';
import { cardAdd } from '../card/cardAdd';
import { Dispatch } from '../../../../types/actions';
import { IGetState, TBoardId } from '../../../../types/types';
import { getListCardsByStatus } from '../../../../store/model/selectors/getListCardsByStatus';
import { getOrderNumberByCards } from '../../../../store/model/helpers/getOrderNumberByCards';

export const createTemplate = (boardId: TBoardId) => {
    return (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const templateList = getTemplateListPromise(state, boardId);
        return templateList.then((list) => {
            const templateCards = getListCardsByStatus(state, list.id, TStatus.STATUS_SERVICE_TEMPLATE);
            const newCard: INewCard = {
                name: NEW_TEMPLATE_NAME,
                listId: list.id,
                status: TStatus.STATUS_SERVICE_TEMPLATE,
                cardNumber: '',
                orderNumber: getOrderNumberByCards(templateCards, true)
            };
            return dispatch(cardAdd(newCard));
        });
    }
}
