import { IGetState, TCardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { getCardRelatedCardsActive } from '../../../../store/model/selectors/getCardRelatedCardsActive';
import { TStatus } from '../../../../types/model';
import { cardUpdateRelatedCards } from './cardUpdateRelatedCards';

export const deleteCardRelations = (
    cardId: TCardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const relatedCards = getCardRelatedCardsActive(getState(), cardId).map((mapRelatedCard) => {
            const newRelatedCard = { ...mapRelatedCard };
            newRelatedCard.status = TStatus.STATUS_DELETED;
            return newRelatedCard;
        });
        return dispatch(cardUpdateRelatedCards(cardId, relatedCards))
    };
    return action;
};
