import { IButtonInheritedProps } from 'kui';

export interface IUpdatesItemSlide {
    highlightSelector?: string;
    media?: string;
    text?: string;
    title: string;
    type?: EUpdateSlideType;
    onOpen?: () => void;
    onClose?: () => void;
}

export interface IUpdatesItem {
    button?: IButtonInheritedProps;
    id?: string; // for auto show tips
    isNew?: () => boolean;
    slides?: IUpdatesItemSlide[];
    text?: string;
    title: string;
    type: EUpdateType;
}

export interface IUpdates {
    [date: string]: IUpdatesItem[];
}

export enum EUpdateType {
    ADD = 'ADD',
    FIX= 'FIX',
    IMPROVE = 'IMPROVE',
}

export enum EUpdateSlideType {
    IMAGE = 'IMAGE',
    TIPS = 'TIPS',
    VIDEO = 'VIDEO',
}
