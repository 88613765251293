import { createDeepEqualSelector } from '../../../../../../helpers/memoizeHelper';
import { IApplicationState, TBoardId, TCardId } from '../../../../../../../../types/types';
import { IRestMoveToBoard } from '../../../../../../../../types/rest/IRestCardMeta';
import { ICardActivityFetchOptions } from '../../../components/CardTabActivity/types';
import { getCardMetaMoveToBoards } from '../../../../../../../../store/model/selectors/getCardMetaMoveToBoards';
import { getBoardIdByCardId } from '../../../../../../../../store/model/selectors/getBoardIdByCardId';

export const getActivityFetchOptions = createDeepEqualSelector(
    getCardMetaMoveToBoards,
    getBoardIdByCardId,
    (moveToBoards, boardId) => {
        if (!moveToBoards.length) return null;
        const result: ICardActivityFetchOptions[] = [{
            boardId: boardId,
            timeFrom: moveToBoards[0].time
        }]
        for (let i = 0; i < moveToBoards.length; i++) {
            const options: ICardActivityFetchOptions = {
                boardId: moveToBoards[i].boardId,
                timeTo: moveToBoards[i].time
            }
            if (i < moveToBoards.length - 1) {
                options.timeFrom = moveToBoards[i + 1].time;
            }
            result.push(options)
        }
        return result;
    }
);
