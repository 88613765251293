import { IApplicationState } from '../../../../../../../types/types';
import { POLL_DELAY_AFTER } from '../../../../../base/components/Poll/components/constants';
import { root } from '../../../../../../../store/constants';
import { getUserMessengerPoll } from '../../../../../../../store/model/authUser/selectors/getUserMessengerPoll';

export const getShowMessengerPoll = (state: IApplicationState): boolean => {
    let isShow = false;

    const { lastTimeShowed, isSkipped, isShowed } = getUserMessengerPoll(state);

    if (isShowed) return isShow;

    const isUMUXEnabled = root.App.controller && root.App.controller.getMessengerPollEnabled();
    if (!isUMUXEnabled) return isShow;

    isShow = !lastTimeShowed;

    if (lastTimeShowed && isSkipped) {
        const timeSinceLastShowed = Date.now() - lastTimeShowed;
        const oneDay = 1000 * 60 * 60 * 24;
        const oneWeek = oneDay * 7;
        if (
            (!isSkipped && timeSinceLastShowed < POLL_DELAY_AFTER) || // только что ответил, показать Thank you
            timeSinceLastShowed > oneWeek // пора отвечать опять
        ) {
            isShow = true;
        }
    }

    return isShow;
}
