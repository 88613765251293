import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState } from '../../../../../../../types/types';
import { segmentTrackAction } from '../../../../../../../middlewares/segment';
import { SegmentQuestionEvent } from '../../../../../../../middlewares/segment/trackEntities/questionEvents';
import { authUserSetRateAppPoll } from '../../../../../../../rest/effects/authUser/authUserSetRateAppPoll';
import { authUserPostUmux } from '../../../../../../../rest/effects/umux/authUserPostUmux';
import { getAuthUser } from '../../../../../../../store/model/authUser/selectors/getAuthUser';
import { IPoll } from '../../../../../../../rest/effects/umux/types';
import { IQuestion } from '../../../../../base/components/Poll/components/Poll/types';
import { getMessages } from '../../../../../../../store/constants';

export const onSubmit = (
    results: IQuestion[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const date = Date.now();
        const poll: IPoll = {};
        const canceled = !!results.filter(({ question, answer }) => {
            const feedbackTitle = getMessages().getText('poll.feedback.start')
                + getMessages().getText('kanban-poll.feedback.title')
                + getMessages().getText('poll.feedback.end');
            if (
                question !== feedbackTitle ||
                (question === feedbackTitle && !(answer && answer.toString().length))
            ) {
                return true;
            }
        }).length ? [{ name: 'canceled', value: 'true' }] : null
        results.forEach(({ question, answer }) => {
            poll[String(question)] = String(answer);
            if (question === getMessages().getText('kanban-poll.question.requirements')) {
                dispatch(segmentTrackAction(SegmentQuestionEvent.RATE_KANBANCHI_REQUIREMENTS, {
                    name: 'rating',
                    value: Number(answer) + 1
                }, canceled));
            } else if (question === getMessages().getText('kanban-poll.question.usage')) {
                dispatch(segmentTrackAction(SegmentQuestionEvent.RATE_KANBANCHI_USAGE, {
                    name: 'rating',
                    value: Number(answer) + 1
                }, canceled));
            } else if (question ===  getMessages().getText('kanban-poll.question.acceptability')) {
                dispatch(segmentTrackAction(SegmentQuestionEvent.RATE_KANBANCHI_ACCEPTABILITY, {
                    name: 'rating',
                    value: Number(answer) + 1
                }, canceled));
            } else if (question === getMessages().getText('kanban-poll.feedback.title')) {
                if (answer && answer.toString().length) {
                    dispatch(segmentTrackAction(SegmentQuestionEvent.RATE_KANBANCHI_REASON, {
                        name: 'answer',
                        value: answer as string
                    }));
                }
            }
        });
        dispatch(authUserSetRateAppPoll(date, false));
        dispatch(authUserPostUmux( {
            userId: getAuthUser(getState()).id,
            date,
            poll
        }))
    };
    return action;
};
