'use strict';
import * as _ from 'underscore';
import { getAuthUser } from '../store/model/authUser/selectors/getAuthUser';
import { getAppState } from '../store/configureStore';

App.Models.Notification = App.Models.BaseRelationalModel.extend({
    classKey: 'notification',
    defaults: {
        seen: false
    },

    url: function() {
        return App.Util.getApiUrl('/rest/pushnotification/' + this.id);
    },

    initialize: function() {

    },

    getNotificationModel: function(shift) {
        if (!this._notificationModel){
            var json = this.get('modelHistoryJson').value || this.get('modelHistoryJson');
            this._notificationModel = JSON.parse(json);
        }
        shift = shift || 0;
        return this._notificationModel[shift];
    },

    getNotificationId: function() {
        return this.get('id');
    },

});
