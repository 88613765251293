import * as React from 'react';
import { ICardDueDateProps } from './types';
import { CardDateTimeIndicator } from '../CardDateTimeIndicator/CardDateTimeIndicator';
import * as moment from 'moment';
import {
    DATE_FORMAT_INDICATOR,
    DATE_FORMAT_TOOLTIP,
    DUE_DATE_ICON,
    OVERDUE_ICON,
    OVERDUE_MODIFIER
} from '../CardDateTimeIndicator/constants';
import { DUE_DATE_TOOLTIP_PREFIX } from '../../wording';

export function CardDueDate({
    date,
    highlightOverdue = true
}: ICardDueDateProps) {
    const momentDate = moment(date * 1000);
    const text = momentDate.format(DATE_FORMAT_INDICATOR);
    const tooltip = DUE_DATE_TOOLTIP_PREFIX + momentDate.format(DATE_FORMAT_TOOLTIP);
    const isOverdue = moment() > momentDate;
    const icon = isOverdue ? OVERDUE_ICON : DUE_DATE_ICON;
    const modifier = isOverdue && highlightOverdue ? OVERDUE_MODIFIER : '';

    return (
        <CardDateTimeIndicator tooltip={tooltip} text={text} icon={icon} modifier={modifier}/>
    );
}
