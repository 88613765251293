import { TBoardId } from '../../../types/types';
import { ERoutes } from '../constants';
import { IRouterState } from '../reducers/types';

export const getLinkCardId = (
    routerState: IRouterState
): TBoardId => {
    const { route, args } = routerState;
    if (
        route !== ERoutes.LINK_CARD &&
        route !== ERoutes.LINK_CARD_TIMING &&
        route !== ERoutes.LINK_CARD_ACTIVITY
    ) return null;

    const cardId = args[1];
    if (!cardId) return null;

    return parseInt(cardId);
}
