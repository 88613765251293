import { IGetState, TBoardId } from '../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { boardsActionSet } from '../../../../../store/model/actions/boardsActionSet';
import { boardActionSet } from '../../../../../store/model/boards/actions/boardActionSet';
import { ICardAssigneeRole } from '../../../../../types/model';
import { addRoleAction } from '../../../../../store/model/board/actions/addRoleAction';
import { IRestCardAssigneeRole } from '../../../../../types/rest/IRestCardAssigneeRole';
import { postRest } from '../postRest';
import { sendRealTimeStoreActionByBoardId } from '../../../../../view/react_components/base/helpers/realTimeHelperTS';

export const boardRolePostRest = (
    boardId: TBoardId,
    role: ICardAssigneeRole,
    isRealtime: boolean = true, // send event to socket
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(postRest(boardId, role))
            .then((role: IRestCardAssigneeRole) => {
                const action = boardsActionSet(boardActionSet(boardId, addRoleAction(role)));
                dispatch(action);
                if (isRealtime) {
                    dispatch(sendRealTimeStoreActionByBoardId(boardId, action));
                }
                return role;
            })
            .catch((e: any) => {
                console.error(e);
            });
    };
    return action;
};
