import { IAuthUser } from '../types';
import {AT_SET, AT_UPDATE, TAuthUserAction} from '../actions/types';
import {IUserMeta} from '../../../../types/rest/IUserMeta';

const initialState: IAuthUser = {};

export const authUserReducer = (
    state = initialState,
    action: TAuthUserAction
): IAuthUser => {
    switch (action.type) {
        case AT_UPDATE: {
            const stateMeta = state && state.meta || {};
            return {
                ...state,
                ...action.authUser,
                meta: {
                    ...stateMeta,
                    ...reformatRecentBoards(action.authUser.meta),
                },
            };
        }

        case AT_SET: {
            return {
                ...action.authUser,
                meta: {
                    ...action.authUser.meta,
                    ...reformatRecentBoards(action.authUser.meta),
                },
            };
        }

        default:
            return state;
    }
};

// Так как в беке рисент бордс хранятся в строках, мы должны преобразовать их в намберы
const reformatRecentBoards = (
    meta: IUserMeta
) => {
    if (meta && meta.recentBoards) {
        meta = {
            ...meta,
            recentBoards: meta.recentBoards.map((board) => {
                return {
                    ...board,
                    id: Number(board.id)
                }
            })
        }
    }
    return meta;
}
