import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../types/types';
import { onClickBackToSite } from './events/onClickBackToSite';
import { onClose } from './events/onClose';
import { FreeTrialModal} from 'app/view/react_components/dialogs/freeTrialModal/components/FreeTrialModal/FreeTrialModal';
import { onClickStartFreeTrial } from 'app/view/react_components/dialogs/freeTrialModal/hocs/FreeTrialModalHOC/events/onClickStartFreeTrial';
import { IFreeTrialModalEvents } from 'app/view/react_components/dialogs/freeTrialModal/components/FreeTrialModal/types';

const mapStateToProps = (
    state: IApplicationState
) => {
    return {
    };
};

const mapDispatchToProps = (
    dispatch: any,
): IFreeTrialModalEvents => {
    return {
        onClickBackToSite: () => dispatch(onClickBackToSite()),
        onClickStartFreeTrial: () => dispatch(onClickStartFreeTrial()),
        onClose: () => dispatch(onClose()),
    }
};

export const FreeTrialModalHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(FreeTrialModal);

FreeTrialModalHOC.displayName = 'FreeTrialModalHOC';
