import { fetchHandler } from '../../../../../util/fetchHandler';
import { Util } from '../../../../../util/util';
import { getRestHeadersPost } from '../../../../../rest/helpers/getRestHeadersHelper';
import { IRestSubscription, TRestSubscriptionsGetByIds } from '../../../../../types/rest/IRestSubscription';
import { REST_SUBSCRIPTIONS } from './constants';
import { Dispatch, ThunkAction } from '../../store/subscriptionAdmin/types';

export const getByIdsRest = (
    body: TRestSubscriptionsGetByIds,
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        return fetchHandler<IRestSubscription[]>(
            Util.getApiUrl(REST_SUBSCRIPTIONS + 'getByIds'),
            {
                ...getRestHeadersPost(),
                body: JSON.stringify(body)
            }
        );
    };
    return action;
};
