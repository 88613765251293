import { IGetState, TBoardId } from '../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { INotificationSettings } from '../../../../../store/model/notificationSettings/types';
import { notificationSettingsActionSet } from '../../../../../store/model/notificationsSettings/actions/notificationSettingsActionSet';
import { updateAction } from '../../../../../store/model/notificationSettings/actions/updateAction';
import { notificationsSettingsActionSet } from '../../../../../store/model/actions/notificationsSettingsActionSet';
import { getRest } from '../getRest';

export const loadNotificationSettings = (
    boardId: TBoardId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(getRest(boardId))
            .then((notification: INotificationSettings) => {
                dispatch(notificationsSettingsActionSet(notificationSettingsActionSet(boardId, updateAction(notification))));
            })
    };
    return action;
};
