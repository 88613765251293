import { IApplicationState } from '../../../types/types';
import { ERoutes } from '../constants';

export const getIsAssignedToMeActive = (
    state: IApplicationState
): boolean => {
    const { route } = state.router;
    return (
        route === ERoutes.ASSIGNED_TO_ME ||
        route === ERoutes.ASSIGNED_TO_ME_CARD ||
        route === ERoutes.ASSIGNED_TO_ME_CARD_ACTIVITY ||
        route === ERoutes.ASSIGNED_TO_ME_CARD_TIMING ||
        route === ERoutes.ASSIGNED_TO_ME_FILTER ||
        route === ERoutes.ASSIGNED_TO_ME_IMPORT ||
        route === ERoutes.ASSIGNED_TO_ME_SETTINGS ||
        route === ERoutes.ASSIGNED_TO_ME_BACKGROUND
    );
}

export const getIsAssignedToMeCardActive = (
    state: IApplicationState
): boolean => {
    const { route } = state.router;
    return (
        route === ERoutes.ASSIGNED_TO_ME_CARD ||
        route === ERoutes.ASSIGNED_TO_ME_CARD_ACTIVITY ||
        route === ERoutes.ASSIGNED_TO_ME_CARD_TIMING
    );
}
