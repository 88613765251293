import { IApplicationState } from '../../../../types/types';
import { getAuthUser } from './getAuthUser';
import { PERSONAL_BLOCK_DATE } from '../../../../helper/blocker_helper';
import { USER_DOMAIN_ACCOUNT_TYPE, USER_EDU_ACCOUNT_TYPE } from '../../../../view/react_components/dialogs/feedback';
import { getUserAccountType } from '../../selectors/getUserAccountType';
import { getUserPaidSubscriptions } from './getUserPaidSubscriptions';

export const isFreeUser = (state: IApplicationState): boolean => {
    const userAccountType = getUserAccountType(state);

    return getUserPaidSubscriptions(state).length === 0 &&
        (getAuthUser(state).createDateTimeStamp < Math.abs(PERSONAL_BLOCK_DATE / 1000) || userAccountType === USER_EDU_ACCOUNT_TYPE) &&
        userAccountType !== USER_DOMAIN_ACCOUNT_TYPE;
};
