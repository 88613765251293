import {Dispatch, ThunkAction} from '../../../../../../../types/actions';
import { IGetState, TBoardId } from '../../../../../../../types/types';
import { updateFilePermissions } from 'app/helper/authorisation/microsoft/graph/api/updateFilePermissions';
import { EFilePermissionRole } from 'app/helper/authorisation/microsoft/graph/api/getFilePermissions';
import { getBoardParentFolderInfo } from 'app/store/model/selectors/getBoardParentFolderInfo';
import { snackbarErrorDefault } from 'app/view/react_components/snackbarsStack';
import { SNACKBAR_ID_BOARD_ERROR } from 'app/view/react_components/snackbarsStack/effects/constants';

export const onUpdate = (
    boardId: TBoardId,
    permId: string,
    role: EFilePermissionRole,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        const parentFolderInfo = getBoardParentFolderInfo(getState(), boardId);
        if (!parentFolderInfo) return;

        return updateFilePermissions(parentFolderInfo.driveId, parentFolderInfo.folderId, permId, role)
            .catch((error) => {
                dispatch(snackbarErrorDefault({
                    id: SNACKBAR_ID_BOARD_ERROR,
                    text: error && error.message,
                }));
            });
    };
    return action;
};
