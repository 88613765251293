import { EHintKey } from 'app/view/react_components/hints';
import { setHintCanShow } from 'app/view/react_components/hints/effects/setHintCanShow';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../types/types';

export const onShowAttachmentListHint = (): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(setHintCanShow(EHintKey.ATTACHMENTS_LIST));
    };
    return action;
};
