import { connect } from 'react-redux';
import { onClose } from './events/onClose';
import { LanguageFeatureModal } from '../../components/LanguageFeatureModal/LanguageFeatureModal';
import { ILanguageFeatureModalEvents } from '../../components/LanguageFeatureModal/types';
import { onDidMount } from './events/onDidMount';

const mapDispatchToProps = (
    dispatch: any
): ILanguageFeatureModalEvents => {
    return {
        onClose: () => dispatch(onClose()),
        onDidMount: () => dispatch(onDidMount())
    }
};

export const LanguageFeatureModalHOC = connect(
    null,
    mapDispatchToProps
)(LanguageFeatureModal);

LanguageFeatureModalHOC.displayName = 'LanguageFeatureModalHOC';
