import {
    ASYNC_SUCCESS_TYPE,
    ASYNC_FAILURE_TYPE,
    ASYNC_REQUEST_TYPE,
} from './../../../../../../../../store/constants';

import {PTGC_PUSH_EVENT} from './../../constants';

export const pushCardEventToGoogleCalendarRequest = (calendarId) => ({
    type: `${PTGC_PUSH_EVENT}${ASYNC_REQUEST_TYPE}`,
    calendarId
});

export const pushCardEventToGoogleCalendarSuccess = () => ({
    type: `${PTGC_PUSH_EVENT}${ASYNC_SUCCESS_TYPE}`
});

export const pushCardEventToGoogleCalendarFail = () => ({
    type: `${PTGC_PUSH_EVENT}${ASYNC_FAILURE_TYPE}`
});