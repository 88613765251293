import {
    TB_SHARE_ANYONE_TYPE,
    TB_SHARE_ANYONE_WLINK_TYPE,
    TB_SHARE_DOMAIN_TYPE,
    TB_SHARE_DOMAIN_WLINK__TYPE,
    TB_SHARE_ICON,
    TB_SHARING_TYPE_DOMAIN,
    TB_SHARING_TYPE_DOMAIN_LINK,
    TB_SHARING_TYPE_LINK,
    TB_SHARING_TYPE_LOCK,
    TB_SHARING_TYPE_PUBLIC,
    TB_SHARING_TYPE_SPECIFIC
} from '../../../view/react_components/main/topBar/constants';
import { IBoard } from '../board/types';

export const getBoardSharingType = (dashboard: IBoard): TB_SHARE_ICON | '' => {
    try {
        const type = dashboard.permissions.publicPermissions.type;
        if (type === '') {
            if (dashboard.users.length > 1) {
                return TB_SHARING_TYPE_SPECIFIC;
            } else {
                return TB_SHARING_TYPE_LOCK;
            }
        } else {
            if (type === TB_SHARE_ANYONE_TYPE) {
                return TB_SHARING_TYPE_PUBLIC;
            }
            if (type === TB_SHARE_ANYONE_WLINK_TYPE) {
                return TB_SHARING_TYPE_LINK;
            }
            if (type === TB_SHARE_DOMAIN_TYPE) {
                return TB_SHARING_TYPE_DOMAIN;
            }
            if (type === TB_SHARE_DOMAIN_WLINK__TYPE) {
                return TB_SHARING_TYPE_DOMAIN_LINK;
            }
        }
    } catch (e) {
        // throw error when active board closed
        // console.error(e);
        return TB_SHARING_TYPE_LOCK;
    }
};
