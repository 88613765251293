import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState, TBoardId, TCardId } from '../../../../types/types';
import { getCardSpentTime } from '../../../../view/react_components/aside_panel/cardDetails/CommentsSection/selectors/getCardSpentTime';
import { ICards } from '../../../../store/model/cards/types';
import { cardsRestPatch } from './api/helper/cardsRestPatch';
import { getBoardWorkHoursInDay } from '../../../../store/model/selectors/getBoardWorkHoursInDay';

export const cardTotalSpentTimeUpdate = (
    boardId: TBoardId,
    cardId: TCardId,
): ThunkAction => {
    return (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const workHoursInDay = getBoardWorkHoursInDay(state, boardId);
        const totalSpentTime = getCardSpentTime(state, cardId, workHoursInDay) || null; // back-end doesn't accept 0;
        const cards: ICards = {
            [cardId]: {
                totalSpentTime
            }
        };

        return dispatch(cardsRestPatch(cards));
    };
};
