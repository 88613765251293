import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../types/types';
import { TDriveDocId } from '../../../../../../../../types/rest/IRestDriveDoc';
import { boardOpen } from '../../../../../../base/effects/boardOpen';
import { EAuthUserPlatformType } from 'app/types/rest/IRestAuthUser';
import { getFile } from 'app/helper/authorisation/microsoft/graph/api/getFile';
import { msBuildUrl } from 'app/helper/file_helper';
import { getDriveDoc } from 'app/store/model/driveDocs/selectors/getDriveDoc';
import Util from 'app/util/util';

export const onAttachmentClick = (
    driveDocId: TDriveDocId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();

        const attachment = getDriveDoc(state, driveDocId);
        if (attachment.dashboardId) {
            return dispatch(boardOpen(attachment.dashboardId));
        }

        if (attachment.platformType === EAuthUserPlatformType.GOOGLE) {
            window.open(Util.addAuthUserToUrl(attachment.url), '_blank');
        } else if (
            attachment.platformType === EAuthUserPlatformType.MICROSOFT
        ) {
            if (attachment.url.includes('sourcedoc=')) { // ссылка на доки правильная, т.к. не содержит имя папки
                window.open(attachment.url, '_blank');
            } else {
                getFile(attachment.driveId, attachment.fileId)
                    .then(item => {
                        return msBuildUrl(item);
                    })
                    .then(url => {
                        window.open(url, '_blank');
                    });
            }
        }
    };
    return action;
};
