import {IAssignedToSetAction} from './types';
import {AT_ASSIGNED_TO_SET} from './constants';

export const assignedToSet = (
    assignedTo: string[]
): IAssignedToSetAction => ({
        type: AT_ASSIGNED_TO_SET,
        assignedTo
    }
);
