import { IGetState, TBoardId, TCardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { createTemplateFromCard } from '../../../../../../../../rest/effects/card/template/createTemplateFromCard';
import { cardOpen } from '../../../../../../base/effects/cardOpen';
import { IRestCard } from '../../../../../../../../types/rest/IRestCard';
import { getSectionType } from '../../../../selectors/getSectionType';
import { snackbarSuccessDefault } from '../../../../../../snackbarsStack';
import { ESettingsSectionType } from '../../../../../../base/components/SettingsSection/types';
import { disallowedSectionClickedEvent } from '../../../../effects/segmentEvents';
import {
    SegmentCardEvent,
    SegmentCardOptions,
    SegmentCardSection,
    segmentTrackAction
} from '../../../../../../../../middlewares/segment';
import { SNACKBAR_ID_TEMPLATE_CREATED, SNACKBAR_TEMPLATE_CREATED } from '../constants';
import { showSnoozeBlocker } from '../../../../../../base/effects/showSnoozeBlocker';
import { checkCopyCardWithSubcards } from '../../../../../../../../rest/effects/card/template/checkCopyCardWithSubcards';
import { SegmentCardSourceValue } from 'app/middlewares/segment/trackEntities/cardEvents';

export const onClick = (
    cardId: TCardId,
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const sectionType = getSectionType(getState(), boardId, cardId, null, 'allowRecurringTask');
        const isDisallow = sectionType === ESettingsSectionType.DISALLOW;

        if (isDisallow) {
            dispatch(showSnoozeBlocker({
                allow: 'allowRecurringTask',
                subAllow: 'allowTemplates'
            }));
            dispatch(disallowedSectionClickedEvent(SegmentCardSection.SAVE_AS_TEMPLATE, SegmentCardSourceValue.BUTTONS_PANEL));
        } else {
            dispatch(segmentTrackAction(SegmentCardEvent.BUTTONS_PANEL_BUTTON_CLICKED, {
                name: SegmentCardOptions.TARGET,
                value: SegmentCardSection.SAVE_AS_TEMPLATE
            }))
            dispatch(checkCopyCardWithSubcards(cardId, 'snackbar.save_template.with_subcards.text')).then((copySubCards: boolean) => {
                dispatch(createTemplateFromCard(cardId, boardId, copySubCards))
                    .then((cardTemplate: IRestCard) => {
                        dispatch(cardOpen(cardTemplate.id));
                        dispatch(snackbarSuccessDefault({
                            id: SNACKBAR_ID_TEMPLATE_CREATED,
                            text: SNACKBAR_TEMPLATE_CREATED,
                        }));
                    });
            });
        }
    };
    return action;
};
