import { TNotificationBeforeDueDateEditAction } from '../../notificationBeforeDueDateCardEditState/actions/types';
import { TCardId } from '../../../../../../../../../types/types';

export const AT_NBD_ACTION_SET = 'NBD_ACTION_SET';

export interface INotificationBeforeDueDateEditActionSetAction {
    type: typeof AT_NBD_ACTION_SET;
    cardId: TCardId;
    notificationBeforeDueDateEditAction: TNotificationBeforeDueDateEditAction
}

export type TNotificationBeforeDueDateEditsStateAction = (
    INotificationBeforeDueDateEditActionSetAction
);
