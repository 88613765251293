import {getByParentIdRest} from "../view/react_components/subscriptionAdmin/rest/api/getByParentIdRest";
import {getByIdsRest} from "../view/react_components/subscriptionAdmin/rest/api/getByIdsRest";
import {patchRest} from "../view/react_components/subscriptionAdmin/rest/api/patchRest";

function SubscriptionsCsvLoader(exportUrl, listener) {
    
    this.exportUrl = exportUrl;
    this.listener = listener;
    
    this.startLoad = function() {
        this.loadNextPart('');
    };
    
    this.loadNextPart = function(cursor) {
        var url = this.exportUrl + '?rand='+Math.random();
        if (cursor != '') url += '&cursor=' + cursor;
        
        $.ajax({
            beforeSend: App.vent.beforeSend,
            dataType: "json",
            url: url,
            success: $.proxy(function(result){
                if (!result.completed) {
                    if (this.listener)
                        this.listener.onLoadPart(result.csv);
                    this.loadNextPart(result.cursor);
                } else {
                    if (this.listener){
                    	this.listener.onLoadPart(result.csv);
                        this.listener.onComplete();
                    }
                }
            }, this)
        });
        
    };
}

function subscriptionsToCsv() {
    var loadingTitle = 'Loading ...';
    var loader = new SubscriptionsCsvLoader('/utils/subscriptions/csv/export', {
        onLoadPart: function(csv) {
            var ta = $('#csvTa');
            if (ta.val() == loadingTitle)
                ta.val('');
            ta.val(ta.val() + csv);
        },
        onComplete: function() {
        	$('body').html('CSV export completed');
        }
    });
    $('body').removeAttr('class');
    $('body').html('<textarea id="csvTa" style="width:100%; height:100%;">' + loadingTitle + '</textarea>');
    loader.startLoad();
}

function backToVersion3() {
    var loader = new SubscriptionsCsvLoader('/utils/backToVersion3', {
        onLoadPart: function(csv) {
            console.log('norm.wait');
        },
        onComplete: function() {
            console.log('norm.gotovo');
        }
    });
    loader.startLoad();
}
App.backToVersion3 = backToVersion3;
function paymentToCsv() {
    var loadingTitle = 'Loading ...';
    var loader = new SubscriptionsCsvLoader('/utils/subscriptions/payment/export', {
        onLoadPart: function(csv) {
            var ta = $('#csvTa');
            if (ta.val() == loadingTitle)
                ta.val('');
            ta.val(ta.val() + csv);
        },
        onComplete: function() {
        	$('body').html('CSV export completed');
        }
    });
    $('body').removeAttr('class');
    $('body').html('<textarea id="csvTa" style="width:100%; height:100%;">' + loadingTitle + '</textarea>');
    loader.startLoad();
}


function initDashboardPushNotification() {
    var loadingTitle = 'Loading ...';
    var loader = new SubscriptionsCsvLoader('/utils/initDashboardPushNotification', {
        onLoadPart: function(csv) {
            var ta = $('#csvTa');
            if (ta.val() == loadingTitle)
                ta.val('');
            ta.val(ta.val() + csv);
        },
        onComplete: function() {
        	$('body').html('DashboardPushNotification export completed');
        }
    });
    $('body').removeAttr('class');
    $('body').html('<textarea id="csvTa" style="width:100%; height:100%;">' + loadingTitle + '</textarea>');
    loader.startLoad();
}

const dispatch = store.dispatch;

App.enableReportToPwc = enableReportToPwc;
function enableReportToPwc (rootSubscriptionId) {
    const loadedSubscriptions = {};
    dispatch(getByIdsRest([rootSubscriptionId]))
        .then((subscriptions) => {
            return dispatch(loadSubscriptions(subscriptions, loadedSubscriptions));
        })
        .then(() => {
            Object.values(loadedSubscriptions).forEach(subscription => {
                subscription.managersObjects.forEach(manager => {
                    manager.reportEnabled = true;
                });
                dispatch(patchRest(subscription));
            })
        })
}

const loadSubscriptions = (
    subscriptionsForLoadChild,
    allLoadedSubscriptions
) => {
    const action = (
        dispatch
    ) => {
        const unitPromises = [];
        if (subscriptionsForLoadChild.length) {
            subscriptionsForLoadChild.forEach(subscription => {
                allLoadedSubscriptions[subscription.id] = subscription;
                unitPromises.push(
                    dispatch(getByParentIdRest(subscription.id))
                        .then((results) => {
                            return dispatch(loadSubscriptions(results, allLoadedSubscriptions))
                        })
                );
            });
        }
        return Promise.all(unitPromises);
    };
    return action;
};

const loader = (url, listener) => {
    const loadNext = (cursor) => {
        fetch(url + '?cursor=' + cursor)
            .then(resp => resp.json())
            .then((result) => {
            if(result.cursor) {
                listener.onLoadPart();
                loadNext(result.cursor);
            } else {
                listener.onLoadPart();
                listener.onComplete();
            }
        });
    };
   loadNext('');
};

function migrateUserInWorkCard() {
    loader('/utils/migrateUserInworkCardHiw111', {
        onLoadPart: function(csv) {
            console.log('loading');
        },
        onComplete: function() {
            console.log('complete');
        }
    });
}

function fixSendingFirstEmail() {
    loader('/rest/fixSendingEmail', {
        onLoadPart: function(cursor) {
            console.log('loading', cursor);
        },
        onComplete: function() {
            console.log('complete');
        }
    });
}

App.fixSendingFirstEmail = fixSendingFirstEmail;

App.migrateUserInWorkCard = migrateUserInWorkCard;
