import { getCardAssignee } from '../../../../../../../../store/model/cardAssignees/selectors/getCardAssignee';
import { IApplicationState, TBoardId, TCardId } from '../../../../../../../../types/types';
import { getBoardPermissions } from '../../../../../../../../store/model/selectors/getBoardPermissions';
import { isBoardCardsLimitReached } from '../../../../../../../../store/model/selectors/isBoardCardsLimitReached';

export const getCardDateReadonly = (
    state: IApplicationState,
    boardId: TBoardId,
    cardId: TCardId,
): boolean => {
    const boardPermissions = getBoardPermissions(state, boardId);
    const card = getCardAssignee(state, cardId);
    return (!boardPermissions || !boardPermissions.authPermissions) ||
        !card ||
        !boardPermissions.authPermissions.allowEdit ||
        isBoardCardsLimitReached(state, boardId, 0);
};
