import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { IGetState, TCardId, TListId } from '../../../../../types/types';
import { IBoard } from '../../../../../store/model/board/types';
import { root } from '../../../../../store/constants';
import { cardMoveToList } from './cardMoveToList';
import { listUpdateMinMaxOrderNumber } from '../../../list/listUpdateMinMaxOrderNumber';

export const cardsMoveOnCurrentBoard = (
    cardIds: TCardId[],
    board: IBoard,
    targetListId: TListId,
    cardIndex: number
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const selectedList = board.lists.find(list => list.id === targetListId);
        const cardsInSelectedList = selectedList.cards || [];
        const afterCard = cardsInSelectedList[cardIndex];
        let promises: Promise<any>[] = [];
        let orderNumber = 0;
        let orderStep = root.App.Util.orderStep;
        if (!afterCard) {
            if (cardsInSelectedList.length) {
                orderNumber = cardsInSelectedList[0].orderNumber - root.App.Util.orderStep * cardIds.length;
            }
        } else {
            if (cardIndex === cardsInSelectedList.length - 1) { // last card
                orderNumber = cardsInSelectedList[cardIndex].orderNumber + root.App.Util.orderStep;
            } else {
                orderStep = (cardsInSelectedList[cardIndex + 1].orderNumber - cardsInSelectedList[cardIndex].orderNumber) / (cardIds.length + 1);
                orderNumber = cardsInSelectedList[cardIndex].orderNumber + orderStep;
            }
        }
        const orderNumbers: number[] = [];
        cardIds.forEach((cardId) => {
            promises.push(dispatch(cardMoveToList(cardId, targetListId, orderNumber)));
            orderNumbers.push(orderNumber);
            orderNumber += orderStep;
        });
        return Promise.all(promises)
            .then(() => {
                dispatch(listUpdateMinMaxOrderNumber(targetListId, orderNumbers));
            })
    };
    return action;
};
