import { connect } from 'react-redux';
import { Profile } from '../../components/Profile/Profile';
import { IApplicationState } from '../../../../../types/types';
import { IProfileEvents, IProfileFields } from '../../components/Profile/types';
import { getAuthUser } from 'app/store/model/authUser/selectors/getAuthUser';
import { ESubscriptionStatus } from 'app/types/rest/IRestSubscription';
import { onClickSubscriptions } from './events/onClickSubscriptions';
import { onClickPricing } from './events/onClickPricing';
import { onClickSettings } from './events/onClickSettings';
import { onClickSwitch } from './events/onClickSwitch';
import { onClickSignOut } from './events/onClickSignOut';
import { onClickHelp } from './events/onClickHelp';
import { onClickRelease } from './events/onClickRelease';
import { onClickMarketplace } from './events/onClickMarketplace';
import { onClickShortcuts } from './events/onClickShortcuts';
import { onOpen } from './events/onOpen';
import { getCurrentNavigationPanelType } from '../../../main/navigationPanel/helpers/getCurrentNavigationPanelType';
import { ENavigationPanelTypes } from '../../../main/navigationPanel/constants';
import { onClose } from './events/onClose';
import { isMicrosoftAccount } from 'app/store/model/authUser/selectors/isMicrosoftAccount';
import {
    getIsNewFeatureTopbarRedesign
} from 'app/store/model/authUser/selectors/featuresSeen/getIsNewFeatureTopbarRedesign';
import { getIsNewFeatureWYSIWYG } from 'app/store/model/authUser/selectors/featuresSeen/getIsNewFeatureWYSIWYG';
import {
    getIsNewFeatureLoginSpecificBoard
} from 'app/store/model/authUser/selectors/featuresSeen/getIsNewFeatureLoginSpecificBoard';
import { onClickGuide } from './events/onClickGuide';
import { getAuthUserQuickStartGuides } from '../../../../../store/model/authUser/selectors/getAuthUserQuickStartGuides';
import { IProfileHOCProps } from './types';
import { getQuickStartGuides } from '../../../main/navigationPanel/components/NavigationPanelQuickStartGuide/constants';
import { getUserPromoCodeDiscount } from '../../../../../store/model/authUser/selectors/getUserPromoCodeDiscount';
import { getUserManagedSubscriptions } from '../../../../../store/model/authUser/selectors/getUserManagedSubscriptions';

const mapStateToProps = (
    state: IApplicationState,
    { profileUserWhere, tooltipDirection }: IProfileHOCProps
): IProfileFields => {
    const { managedSubscription } = getAuthUser(state);
    const activeManagedSubscriptionsCount = managedSubscription.filter(subscription => subscription.status === ESubscriptionStatus.ACTIVE).length;
    const isShownManagementButton = !!managedSubscription && !!managedSubscription.length;
    const navigationPanelType = getCurrentNavigationPanelType(state);
    const isReturnFocus = navigationPanelType !== ENavigationPanelTypes.NP_COMPACT_BIG_TYPE;
    const isMicrosoft = isMicrosoftAccount(state);
    const userGuides = getAuthUserQuickStartGuides(state);
    let isShowGuides = !!userGuides;
    if (userGuides) {
        if (Object.values(getQuickStartGuides(state)).length > Object.values((userGuides)).length) {
            isShowGuides = false;
        } else {
            for (let guide in userGuides) {
                if (!userGuides[guide].isFinished) {
                    isShowGuides = false;
                    break;
                }
            }
        }
    }

    return {
        activeManagedSubscriptionsCount,
        discount: !getUserManagedSubscriptions(state).length && getUserPromoCodeDiscount(state),
        isShowGuides,
        isMicrosoft,
        isShownManagementButton,
        isReturnFocus,
        isNewFeatureRelease: getIsNewFeatureTopbarRedesign(state),
        isNewFeatureSettings: getIsNewFeatureWYSIWYG(state) || getIsNewFeatureLoginSpecificBoard(state),
        profileUserWhere,
        tooltipDirection,
    }
}

const mapDispatchToProps = (
    dispatch: any,
): IProfileEvents => ({
    onClickGuide: (guide, closeProfile) => dispatch(onClickGuide(guide, closeProfile)),
    onClickHelp: (closeProfile) => dispatch(onClickHelp(closeProfile)),
    onClickMarketplace: () => dispatch(onClickMarketplace()),
    onClickPricing: () => dispatch(onClickPricing()),
    onClickRelease: (closeProfile) => dispatch(onClickRelease(closeProfile)),
    onClickSettings: () => dispatch(onClickSettings()),
    onClickShortcuts: () => dispatch(onClickShortcuts()),
    onClickSignOut: () => dispatch(onClickSignOut()),
    onClickSubscriptions: () => dispatch(onClickSubscriptions()),
    onClickSwitch: () => dispatch(onClickSwitch()),
    onOpen: () => dispatch(onOpen()),
    onClose: () => dispatch(onClose()),
});

export const ProfileHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(Profile);
ProfileHOC.displayName = 'ProfileHOC';
