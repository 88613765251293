import {IBackup, TBoardBackupList} from '../types';

export const AT_LIST_UPDATE = 'BOARD_LIST_UPDATE';
export const AT_ADD_TO_LIST = 'BOARD_ADD_TO_LIST';

export interface IListUpdateAction {
    type: typeof AT_LIST_UPDATE;
    list: TBoardBackupList;
}

export interface IAddToListAction {
    type: typeof AT_ADD_TO_LIST;
    backup: IBackup;
}

export type TBoardBackupsAction = (
    IListUpdateAction |
    IAddToListAction);
