import { ThunkAction } from '../../../types/actions';
import { getRest } from './api/getRest';

export const loadUser = (): ThunkAction => {
    return (
        dispatch
    ) => {
        return dispatch(getRest())
    }
}
