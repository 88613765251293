import { getMessages } from '../../../../../../store/constants';

export const CLASS_CARD_CUSTOM = 'card-details-custom';
export const CLASS_CARD_CUSTOM_ITEM = CLASS_CARD_CUSTOM + '__item';

export const CARD_SECTION_CUSTOM = getMessages().getText('card_form_section_custom');
export const CARD_SECTION_CUSTOM_FIRST_TEXT = getMessages().getText('board_settings.card_custom_properties.text');
export const CARD_SECTION_CUSTOM_SECTION_NEW = getMessages().getText('card_form_custom_section_new');
export const CARD_SECTION_CUSTOM_SECTION_EXISTING = getMessages().getText('card_form_custom_section_existing');
export const CARD_SECTION_CUSTOM_NEW_FEATURE_TOOLTIP = getMessages().getText('card_form_custom_new_feature_tooltip');
