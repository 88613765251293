import { Dispatch, ThunkAction } from '../../../../../../types/actions';
import { IGetState } from '../../../../../../types/types';
import { hintHide } from '../../../../hints/effects/hintHide';
import { EHintKey, EHintStatus } from '../../../../hints';
import { getHint } from '../../../../hints/store/hints/selectors/getHint';

export const onHideHint = (): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        if (
            getHint(state, EHintKey.GANTT_CONTEXT_ADD_DATE).status === EHintStatus.SHOWN ||
            getHint(state, EHintKey.GANTT_CONTEXT_SHOW_ON_GANTT).status === EHintStatus.SHOWN
        ) {
            return dispatch(hintHide(EHintKey.GANTT_CONTEXT_GANTT_TYPE_SWITCHER));
        } else {
            return Promise.resolve();
        }
    };
    return action;
};
