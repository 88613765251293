import { Dispatch, ThunkAction } from 'app/types/actions';
import { root } from 'app/store/constants';

export const onBackClick = (): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        root.App.controller.htmlScrollDisabledToggle();
        root.App.controller.mainView.microsoftGroupPermission.remove();
        root.App.router.navigate(root.App.router.getUrl(null), {trigger: true});
    }
    return action;
}
