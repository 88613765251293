import { IABTest, IABTestGroup, IABTestValue } from '../types';
import { IApplicationState } from '../../types/types';

// определяем рандомно группу
const getRandomTestGroup = (
    abTest: IABTest
): IABTestGroup => {
    const weightSum = abTest.groups.reduce((sum: number, group) => sum + group.weight, 0);
    const randomWeight = Math.floor(Math.random() * weightSum);
    let sum = 0;
    return abTest.groups.find((group, index) => {
        sum += group.weight;
        return sum > randomWeight || index === abTest.groups.length - 1; // check random weight is relevant for particular group or group is last
    });
};

// конвертируем в формат { имя теста: значение } и определяем значение рандомно или используем дефолтное, если тест не подходит для юзера (или нет мест в тесте)
export const defineNewABTestsValue = (
    state: IApplicationState,
    abTests: IABTest[]
): IABTestValue[] => {
    const convertedValue = abTests.map((
        test
    ) => {
        const isRelevant = test.collected < test.size && test.isRelevant(state);
        const property = isRelevant ? getRandomTestGroup(test).value : test.defaultValue;
        return {
            name: test.name,
            property,
            isRelevant
        }
    });
    return convertedValue;
};
