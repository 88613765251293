import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../../../../types/types';
import { createSubcard } from '../../../../../../../../rest/effects/card/card/createSubcard';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { getBoardIdByCardId } from '../../../../../../../../store/model/selectors/getBoardIdByCardId';
import { isBoardCardsLimitReached } from '../../../../../../../../store/model/selectors/isBoardCardsLimitReached';
import { SegmentUserTypeOption } from '../../../../../../../../middlewares/segment/trackEntities/userEvents';
import {
    showBoardLimitSnackbarAndEvent
} from '../../../../../../../../store/model/effects/showBoardLimitSnackbarAndEvent';
import { ESearchSelectCreationSource } from '../../../../../../base/components/SearchSelect/types';
import { getCard } from '../../../../../../../../store/model/selectors/getCard';
import { createRecurring } from '../../../../../../../../rest/effects/card/recurringTask/createRecurring';
import { root } from '../../../../../../../../store/constants';
import { ETutorial } from '../../../../../../../../types/rest/IUserMeta';

export const onCreate = (
    cardId: TCardId,
    name: string,
    source: ESearchSelectCreationSource
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const track = source === ESearchSelectCreationSource.KEYBOARD
            ? SegmentCardEvent.CREATED_SUBCARD_BY_CTRL_ENTER
            : SegmentCardEvent.CREATED_SUBCARD;
        dispatch(segmentTrackAction(track));
        const boardId = getBoardIdByCardId(state, cardId);
        if (isBoardCardsLimitReached(state, boardId, 1)){
            dispatch(showBoardLimitSnackbarAndEvent(SegmentUserTypeOption.CARD_CREATION));
        } else {
            root.App.controller.showTutorial(ETutorial.SUBCARD_OR_CHECKLIST_TUTORIAL);
            const card = getCard(state, cardId);
            if (card.recurringTasks.length) {
                return dispatch(createRecurring(boardId, name, cardId));
            } else {
                return dispatch(createSubcard(cardId, name, card.status));
            }
        }
    };
    return action;
};
