import { TCondition } from '../types';
import { IApplicationState } from '../../../../types/types';
import { EHintKey, getHintConfig } from '../constants';
import { getEditCardId } from '../../../../store/router/selectors/getEditCardId';
import { getHintCanShow } from '../store/hint/selectors/getHintCanShow';

export const attachmentsListCondition: TCondition = (
    state: IApplicationState
) => {
    const hint = getHintConfig(EHintKey.ATTACHMENTS_LIST);
    if (!hint) return false;

    if (!getEditCardId(state)) return false;

    return !!getHintCanShow(state, EHintKey.ATTACHMENTS_LIST);
};
