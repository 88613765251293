import { IGetState, TCardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { uploadFiles } from '../effects/uploadFiles';
import { attachmentDroppedSourceSegmentEvent, attachmentDroppedSegmentEvent } from '../effects/segmentEffects';
import { getDriveDocsByCardId } from '../../../../../../../../store/model/driveDocs/selectors/getDriveDocsByCardId';

export const onDropFiles = (
    cardId: TCardId,
    files: File[],
    isPopup: boolean,
    onProgress: (percent: number) => void
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const docs = getDriveDocsByCardId(getState(), cardId);
        // если в карте ещё нет файлов, то шлём source куда закинули: сразу в cardPanel или сначала открыли попап
        if (docs && docs.length) {
            dispatch(attachmentDroppedSegmentEvent());
        } else {
            dispatch(attachmentDroppedSourceSegmentEvent(isPopup));
        }
        return dispatch(uploadFiles(cardId, files, onProgress));
    };
    return action;
};
