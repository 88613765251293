import { EMPTY_ARRAY, IApplicationState, TCardId, TCustomPropertyId } from '../../../types/types';
import { getCardPinnedSections } from './getCardPinnedSections';
import { getCard } from './getCard';

export const getCardPinnedCustomPropertiesIds = (
    state: IApplicationState,
    cardId: TCardId,
): TCustomPropertyId[] => {
    const cardPinnedSections = getCardPinnedSections(getCard(state, cardId));

    return cardPinnedSections.customProperties || EMPTY_ARRAY;
};
