import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { DashboardImportRouter } from '../../../../helpers/importBoard';
import { REST_CSV_IMPORT } from '../../../../constants';
import {
    CSV_OPEN_FILE_MIME_FILTER,
    SNACKBAR_BOARD_IMPORT_ID,
    SNACKBAR_CSV_IMPORT_ERROR_TEXT,
    SNACKBAR_CSV_IMPORT_PROGRESS_TEXT
} from '../constants';

export const importCsv = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        new DashboardImportRouter({
            importUrl: REST_CSV_IMPORT,
            snackbarImportId: SNACKBAR_BOARD_IMPORT_ID,
            importProgressText: SNACKBAR_CSV_IMPORT_PROGRESS_TEXT,
            importErrorText: SNACKBAR_CSV_IMPORT_ERROR_TEXT,
            mimeTypesFilter: CSV_OPEN_FILE_MIME_FILTER
        }, dispatch).start();
    };
    return action;
};
