import {IBackupSchedule} from '../../../../store/model/boardBackups/types';
import {Dispatch, ThunkAction} from '../../../../types/actions';
import {fetchHandler} from '../../../../util/fetchHandler';
import {IBoard} from '../../../../store/model/board/types';
import {Util} from '../../../../util/util';
import {getAutoBackupScheduleRestUrl} from '../../../constants';
import {getRestHeadersPatch} from '../../../helpers/getRestHeadersHelper';

export const patchRest = (
    autoBackupId: number,
    backupRecurring: IBackupSchedule
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        return fetchHandler<IBoard>(
            Util.getApiUrl(getAutoBackupScheduleRestUrl(autoBackupId)), {
                ...getRestHeadersPatch(),
                body: JSON.stringify(backupRecurring)
            }
        );
    };
    return action;
};
