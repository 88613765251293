import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../../types/types';
import { IBoardCardAgingSectionEvents, IBoardCardAgingSectionFields } from '../../components/CardAgingSection/types';
import { getIsUserBasicPlanOnly } from '../../../../../../../../store/model/authUser/selectors/getIsUserBasicPlanOnly';
import { CardAgingSection } from '../../components/CardAgingSection/CardAgingSection';
import { onChange } from './events/onChange';
import { IBoardCardAgingSectionHOCProps } from './types';
import { onClose } from './events/onClose';

const mapStateToProps = (
    state: IApplicationState
): IBoardCardAgingSectionFields => {
    return {
        isBasicFeature: getIsUserBasicPlanOnly(state)
    };
};

const mapDispatchToProps = (
    dispatch: any,
    { boardId }: IBoardCardAgingSectionHOCProps
): IBoardCardAgingSectionEvents => {
    return {
        onChange: () => dispatch(onChange(boardId)),
        onClose: () => dispatch(onClose())
    }
};

export const CardAgingSectionHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardAgingSection);

CardAgingSectionHOC.displayName = 'CardAgingSectionHOC';
