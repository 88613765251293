export interface IRestPushNotificationSettings {
    id?: number;
    enabled?: boolean,
    scope?: EPushNotificationSettingsScope[],
    types?: EPushNotificationSettingsTypes[],
    subscribeCases?: EPushNotificationSubscribeCases[]
}

export enum EPushNotificationSettingsScope {
    ALL = 'all',
    ASSIGN_TO_ME = 'assignedToMe',
    WATCH = 'watch',
    EVENT_ASSIGN_TO_ME = 'eventAssignedMe',
    MENTION = 'mention',
    CARD_CREATE = 'cardCreate',
    LIKE_MY_COMMENT = 'likeMyComment'
}

export enum EPushNotificationSubscribeCases {
    CARD_CREATE = 'cardCreate',
    COMMENT_MENTION = 'commentMention',
    ASSIGN = 'assign',
    COMMENT_ADD = 'commentAdd',
}

export enum EPushNotificationSettingsTypes {
    NAME_CHANGED = 'nameChanged',
    DESC_CHANGED = 'descChanged',
    USER_ASSIGNED = 'userAssigned',
    PRIORITY_CHANGED = 'priorityChanged',
    COMMENT_ADDED = 'commentAdded',
    ATTACH_ADDED = 'attachAdded',
    DELETED = 'deleted',
    ARCHIVED = 'archived',
    LIST_CHANGE = 'listChange',
    OVERDUE = 'overdue'
}
