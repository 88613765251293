import { IGetState, TBoardId } from '../../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { boardSetCardProperties } from '../../../../../../../../../rest/effects/board/boardSetCardProperties';
import { SegmentBoardEvent, segmentTrackAction } from '../../../../../../../../../middlewares/segment';
import {
    SegmentBoardCardPropertiesType,
    SegmentBoardOption,
    SegmentBoardOptionSourceValue,
    SegmentBoardOptionStatusValue
} from '../../../../../../../../../middlewares/segment/trackEntities/boardEvents';
import { EAsidePanelProperty } from '../../../../../../asidePanel/components/AsidePanel/types';
import { ICardProperty } from '../../../../../../../../../types/rest/IRestBoardMeta';
import { getBoardCardProperties } from '../../../../../../../../../store/model/board/selectors/getBoardCardProperties';
import { toggleCardRequiredCustomPropertyOptions } from './toggleCardRequiredCustomPropertyOptions';

export const toggleCardRequiredProperty = (
    boardId: TBoardId,
    property: EAsidePanelProperty,
    option?: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const cardProperties = getBoardCardProperties(getState(), boardId);
        const index = cardProperties.findIndex(item => item.property === property);
        const isDefault = cardProperties && cardProperties[index] && cardProperties[index].isDefault;
        let eventStatus = SegmentBoardOptionStatusValue.OFF;
        const cardProperty: ICardProperty = {
            property,
            isDefault
        };
        if (property === EAsidePanelProperty.CARD_CUSTOM) {
            cardProperty.options = toggleCardRequiredCustomPropertyOptions(getState(), boardId, option);
        }
        if (
            index < 0 ||
            !cardProperties[index].isRequired
        ) {
            cardProperty.isDefault = true;
            cardProperty.isRequired = true;
            eventStatus = SegmentBoardOptionStatusValue.ON;
        }
        dispatch(boardSetCardProperties(boardId, cardProperty));
        dispatch(segmentTrackAction(SegmentBoardEvent.CARD_REQUIRED_PROPERTY_CLICKED, {
            name: SegmentBoardOption.SOURCE,
            value: SegmentBoardOptionSourceValue.BOARD,
        }, [{
            name: SegmentBoardOption.TARGET,
            value: property.toLocaleLowerCase()
        }, {
            name: SegmentBoardOption.STATUS,
            value: eventStatus
        }, {
            name: SegmentBoardOption.TYPE,
            value: SegmentBoardCardPropertiesType.REQUIRED
        }]));
    };
    return action;
};
