import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { TCommentId } from '../../../../../../../../store/model/card/types/IComment';
import { commentUpdate } from '../../../store/reducers/actions/commentUpdate';
import { IGetState, TCardId } from '../../../../../../../../types/types';

export const onEdit = (
    cardId: TCardId,
    commentId: TCommentId,
    onOwnEdit: () => void
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        dispatch(commentUpdate({
            replyCommentId: null,
            replyAttachmentId: null,
            replyText: null,
            editCommentId: commentId,
        }));
        if (onOwnEdit) {
            onOwnEdit();
        }
    };

    return action;
};
