import { getMessages } from '../../../../../../store/constants';
import { ISearchSelectOption } from '../../../../base/components/SearchSelect/types';

export const ESTIMATE_TIME_TITLE =  getMessages().getText('board_settings.estimate_time.title');
export const ESTIMATE_TIME_TEXT = getMessages().getText('board_settings.estimate_time.text');

export const ESTIMATE_OPTIONS: ISearchSelectOption[] = [
    {
        text: '8',
        value: '8'
    }, {
        text: '24',
        value: '24'
    }
]
