'use strict';
import * as _ from 'underscore';
App.Views.SystemExportDataNotification = App.Views.SystemExportNotification.extend({
    
    getActions: function () {
        var done = '';        
        if (this.model.get('process') == 'done') {
            done = '_done';
        }
        return _.extend(
            App.Views.SystemExportNotification.prototype.getActions.call(this),
            {                
                action: Messages.getText('noty.system_export' + done + '.action'),
                bodyRaw: Messages.getText('noty.system_export' + done + '.body'),
                body: App.Helpers.MarkdownHelper.stripTags(Messages.getText('noty.system_export' + done + '.body'))             
            }
        )
    }
});
