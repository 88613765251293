import * as React from 'react';
import { createRoot, Root } from 'react-dom/client';
import { Button } from 'kui';
import { SKIP_TO_MAIN_TEXT } from './constants';
import './_SkipToMain.scss';

export class SkipToMain {
    root: Root;

    constructor () {
        let el = document.querySelector('.skip-to-main-placeholder');
        if (!el) {
            el = document.createElement('div');
            el.className = 'skip-to-main-placeholder';
            document.body.prepend(el);
        }
        this.root = createRoot(el!);
        this.render();
    }

    render () {
        this.root.render(
            <Button
                className={'skip-to-main'}
                href={'#main-panel'}
                variant={'primary'}
            >
                {SKIP_TO_MAIN_TEXT}
            </Button>
        );
    }

    remove () {
        this.root.unmount();
    }
}
