import { connect } from 'react-redux';
import { EMPTY_ARRAY, IApplicationState } from '../../../../../../../types/types';
import { INotifySectionHOCProps } from './types';
import {
    INotifySectionEvents,
    INotifySectionFields
} from '../../components/NotifySection/types';
import { NotifySection } from '../../components/NotifySection/NotifySection';
import { getNotifySearchSelectOptionsCreateSelector } from './selectors/getNotifySearchSelectOptions';
import { onNotify } from './events/onNotify';
import { onClickActivity } from './events/onClickActivity';
import { getActivity } from '../../../../../../../store/activities/selectors/getActivity';
import { ISearchSelectOption } from '../../../../../base/components/SearchSelect/types';
import { IAssignee } from '../../../../../../../store/model/card/types/IAssignee';
import { onDidMount } from './events/onDidMount';
import { getActivities } from '../../../../../../../store/activities/selectors/getActivities';
import { onUserSelect } from './events/onUserSelect';
import { onSuccess } from './events/onSuccess';
import { onWarning } from './events/onWarning';
import { onClickHow } from './events/onClickHow';
import { IHistoryUserRest } from '../../../../../../../types/rest/IHistoryUserRest';

const mapStateToProps = () => {
    const getNotifySearchSelectOptions = getNotifySearchSelectOptionsCreateSelector();

    const _mapStateToProps = (
        state: IApplicationState,
        {
            boardId,
            cardId,
            activityId,
        }: INotifySectionHOCProps,
    ): INotifySectionFields => {
        let availableElements: ISearchSelectOption[] = [];
        let label = null;
        let usersNotified: IHistoryUserRest[] = [];

        if (activityId) {
            let activity = getActivity(state, activityId);
            if (!activity) {
                const activities = getActivities(state);
                if (activities && activities.length) activity = activities[0];
            }
            if (activity) {
                availableElements = getNotifySearchSelectOptions(state, boardId, cardId, activityId);
                label = activity.label;
                usersNotified = activity.usersPushNotified || EMPTY_ARRAY;
            }
        }

        return {
            availableElements,
            label,
            usersNotified,
        }
    };
    return _mapStateToProps;
};

const mapDispatchToProps = (
    dispatch: any,
    {cardId, activityId, isNotActivity}: INotifySectionHOCProps
): INotifySectionEvents => {
    const ret: INotifySectionEvents = {
        onClickHow: () => dispatch(onClickHow()),
        onNotify: (ids) => dispatch(onNotify(activityId, ids)),
        onUserSelect: (group) => dispatch(onUserSelect(group)),
        onSuccess: () => dispatch(onSuccess()),
        onWarning: () => dispatch(onWarning()),
    };
    if (isNotActivity) {
        ret.onClickActivity = () => dispatch(onClickActivity(cardId));
        ret.onDidMount = () => dispatch(onDidMount(activityId));
    }
    return ret;
};

export const NotifySectionHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(NotifySection);

NotifySectionHOC.displayName = 'NotifySectionHOC';
