import {ITopBarNameStatusSetAction} from './types';
import {AT_TOP_BAR_NAME_STATUS_SET} from './constants';
import {EBoardNameStatus} from '../types';

export const topBarNameStatusSet = (
    boardNameStatus: EBoardNameStatus
): ITopBarNameStatusSetAction => ({
    type: AT_TOP_BAR_NAME_STATUS_SET,
    boardNameStatus
});
