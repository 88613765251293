import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../../types/types';
import { MyWorkSettingsShowSection } from '../../components/MyWorkSettingsShowSection/MyWorkSettingsShowSection';
import {
    IMyWorkSettingsShowSectionEvents,
    IMyWorkSettingsShowSectionFields
} from '../../components/MyWorkSettingsShowSection/types';
import { onToggleArchived } from './events/onToggleArchived';
import { onEmptyGroupsToggle } from './events/onEmptyGroupsToggle';
import { onTitlesToggle } from './events/onTitlesToggle';
import { getMyWorkArchivedCards } from '../../../../selectors/getMyWorkArchivedCards';
import { getHideMyWorkColumnsTitle } from '../../../../selectors/getHideMyWorkColumnsTitle';
import { getHideMyWorkEmptyGroups } from '../../../../selectors/getHideMyWorkEmptyGroups';

const mapStateToProps = (
    state: IApplicationState
): IMyWorkSettingsShowSectionFields => {
    return {
        isShowArchivedCards: getMyWorkArchivedCards(state),
        isShowColumnsTitles: !getHideMyWorkColumnsTitle(state),
        isShowEmptyGroups: !getHideMyWorkEmptyGroups(state)
    }
};

const mapDispatchToProps = (
    dispatch: any
): IMyWorkSettingsShowSectionEvents => ({
    onArchivedToggle: () => dispatch(onToggleArchived()),
    onTitlesToggle: () => dispatch(onTitlesToggle()),
    onEmptyGroupsToggle: () => dispatch(onEmptyGroupsToggle())
});

export const MyWorkSettingsShowSectionHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(MyWorkSettingsShowSection);
MyWorkSettingsShowSectionHOC.displayName = 'MyWorkSettingsShowSectionHOC';
