import { IGetState, TBoardId } from '../../../types/types';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { boardPatchRest } from './api/helpers/boardPatchRest';
import { TStatus } from '../../../types/model';

export const boardSetStatus = (
    boardId: TBoardId,
    status: TStatus
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(boardPatchRest(boardId, {status}));
    };
    return action;
}
