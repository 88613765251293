import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { commentDelete } from '../../../../../../../../rest/effects/card/comments/commentDelete';
import { IGetState, TBoardId, TCardId } from '../../../../../../../../types/types';
import { TCommentId } from '../../../../../../../../store/model/card/types/IComment';
import { getCommentById } from '../../../selectors/getCommentById';
import { getBoardIdByCardId } from '../../../../../../../../store/model/selectors/getBoardIdByCardId';

export const onRemove = (
    cardId: TCardId,
    id: TCommentId,
): ThunkAction => {
    return (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const comment = getCommentById(state, cardId, id);
        const boardId = getBoardIdByCardId(state, cardId);

        dispatch(commentDelete(comment, boardId));
    };
};
