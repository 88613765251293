import { IUserBoardPermissionsBackboneModel } from './../../../../../types/backbone/IUserBoardPermissionsBackboneModel';
import { getBoardPermissions } from './../../../../../store/model/selectors/getBoardPermissions';
import { TBoardId } from './../../../../../types/types';
import { getAuthUser } from '../../../../../store/model/authUser/selectors/getAuthUser';
import { IApplicationState } from '../../../../../types/types';
import { ESettingsSectionType } from '../../../base/components/SettingsSection/types';
import { IAuthUser } from '../../../../../store/model/authUser/types';
import { getBoard } from '../../../../../store/model/selectors/getBoardById';
import { isBoardCardsLimitReached } from '../../../../../store/model/selectors/isBoardCardsLimitReached';

/**
 * основная задача селектора, проверить, если секция Readonly
 * если нет, вернёт переданный тип или DEFAULT
 * @allowSection нужно специальное право юзера по подписке
 * @boardAllow проверить специальное право юзера на доске (кроме allowEdit)
 */
export const getSectionType = (
    state: IApplicationState,
    boardId: TBoardId,
    typeSection?: ESettingsSectionType,
    allowSection?: keyof IAuthUser,
    boardAllow?: keyof IUserBoardPermissionsBackboneModel, // todo achtung kill backbone
): ESettingsSectionType => {
    const type = typeSection || ESettingsSectionType.DEFAULT;
    const typeReadonly = ESettingsSectionType.READONLY;

    if (type === typeReadonly) {
        return type;
    }
    const boardPermissions = getBoardPermissions(state, boardId);
    if (
        !boardPermissions ||
        !boardPermissions.authPermissions
    ) {
        return typeReadonly;
    }

    if (
        !boardPermissions.authPermissions.allowEdit ||
        boardAllow &&
        !boardPermissions.authPermissions[boardAllow]
    ) {
        return typeReadonly;
    }

    // const isPrint = getAsidePanelIsPrint(state);
    // if (isPrint) {
    //     return typeReadonly;
    // }

    if (allowSection) {
        const user = getAuthUser(state);
        if (!user[allowSection]) {
            return ESettingsSectionType.DISALLOW;
        }
    }

    const board = getBoard(state, boardId);
    if (!board.id) {
        return typeReadonly;
    }

    if (isBoardCardsLimitReached(state, boardId, 0)) {
        return typeReadonly;
    }
    return type;
};
