import { IGetState, TBoardId, TThemeId } from '../../../types/types';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { EThemeBackgroundOption } from '../../../types/rest/IRestTheme';
import { boardThemePatchRest } from './api/helpers/boardThemePatchRest';
import { MY_WORK_BOARD_ID } from '../../../view/react_components/main/myWorkView/constants';
import { updateMyWorkMeta } from '../authUser/myWork/updateMyWorkMeta';
import { getMyWorkTheme } from '../../../view/react_components/main/myWorkView/selectors/getMyWorkTheme';

export const boardThemeSetBackgroundOption = (
    boardId: TBoardId,
    themeId: TThemeId,
    backgroundOption: EThemeBackgroundOption
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (boardId === MY_WORK_BOARD_ID) {
            const theme = getMyWorkTheme(getState());
            return dispatch(updateMyWorkMeta({ theme: { ...theme, backgroundOption } }));
        } else {
            return dispatch(boardThemePatchRest(boardId, {
                id: themeId,
                backgroundOption
            }));
        }
    };
    return action;
}
