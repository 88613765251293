import { AsidePanelActionsButton } from '../../../..';
import * as React from 'react';
import { ICardTagsPanelButton } from './types';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { CardTagsSectionHOC } from '../../hocs/CardTagsSectionHOC/CardTagsSectionHOC';
import { getPopupAttributes } from '../../../../../base/components/SettingsSection/constants';
import { CARD_SECTION_TAGS } from '../../constants';
import { CLASS_CARD_DETAILS_SECTION } from '../../../constants';
import { EAsidePanelProperty } from '../../../../asidePanel/components/AsidePanel/types';
import { getAsidePanelActionsButtonTooltip } from 'app/view/react_components/aside_panel/asidePanel/helpers/getAsidePanelActionsButtonTooltip';

export function CardTagsPanelButton({
    isShow,
    tagsCount,
    tooltip,
    onClick
}: ICardTagsPanelButton) {
    if (!isShow) return null;

    const { boardId, cardId, showedProperties, openedProperty } = React.useContext(AsidePanelContext);
    const isShowed = showedProperties.has(EAsidePanelProperty.CARD_TAGS) && openedProperty !== EAsidePanelProperty.CARD_TAGS;
    const [isOpened, setIsOpened] = React.useState(null);
    const onOpened = () => setIsOpened(true);
    const onClosed = () => setIsOpened(false);

    return (
        <AsidePanelActionsButton
            indicatorNumber={tagsCount}
            icon={'tag'}
            isOpened={isOpened}
            openedProperty={EAsidePanelProperty.CARD_TAGS}
            sectionSelector={`.${CLASS_CARD_DETAILS_SECTION}--tags`}
            title={CARD_SECTION_TAGS}
            tooltip={tooltip && getAsidePanelActionsButtonTooltip({ value: tooltip })}
            onClick={onClick}
            onOpen={onOpened}
        >
            {!isShowed &&
                <CardTagsSectionHOC
                    boardId={boardId}
                    cardId={cardId}
                    onClose={onClosed}
                    {...getPopupAttributes()}
                />
            }
        </AsidePanelActionsButton>
    );
}
