'use strict';

App.Views.PermissionsDrive = App.Views.PermissionsDialog.extend({
    getData: function() {
        return {
            video: 'DT8Ndu6UFO4',
            icon: '/dist/img/drive-colored.svg',
            description: "Kanbanchi needs permissions to see the list of your Shared Drives. You will be able to change the type of access later.",
            annotation: 'Kanbanchi will have access to your Google Drive/Shared Drives to attach files to the cards on your boards.',
            shortText: 'View the files in your Google Drive',
            title: 'Google Drive'
        }
    }
});
