import {
    getCardChecklistsSorted
} from '../../../../../../store/model/checklists/checklists/selectors/getCardChecklistsSorted';
import {
    updateDefaultChecklistWithItems
} from '../hocs/CardChecklistActionsHOC/effects/updateDefaultChecklistWithItems';
import { checklistUpdate } from '../../../../../../rest/effects/card/checklist/checklistUpdate';
import {
    CHECKLIST_DEFAULT_ID,
    CHECKLIST_DEFAULT_NAME
} from '../../../../../../store/model/checklists/checklists/constants';
import { IChecklist } from '../../../../../../store/model/checklists/checklist/types';
import { Dispatch, ThunkAction } from '../../../../../../types/actions';
import { IGetState } from '../../../../../../types/types';

export const setChecklistName = (
    checklist: IChecklist,
    ignoreDefaultChecklist?: boolean
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const { name, cardId } = checklist;
        let newName = name ? name : CHECKLIST_DEFAULT_NAME;
        const checklists = getCardChecklistsSorted(getState(), cardId);
        if (!ignoreDefaultChecklist && checklists && checklists.length === 1 && checklists[0].name === CHECKLIST_DEFAULT_NAME) {
            const checklist = {
                id: checklists[0].id,
                name: newName + ' ' + 1,
                cardId
            };
            if (checklists[0].id === CHECKLIST_DEFAULT_ID) {
                dispatch(updateDefaultChecklistWithItems(checklist));
            } else {
                dispatch(checklistUpdate(checklist));
            }
            newName += ' ' + 2;
        } else {
            let count = 0;
            const regex = new RegExp(CHECKLIST_DEFAULT_NAME + ' ');
            const numbers = checklists.map(checklist => checklist.name)
                .filter(name => regex.test(name))
                .map(name => parseInt(name.match(/\d+/)[0]));
            if (numbers.length) {
                count = Math.max(...numbers) + 1;
            }
            if (count) {
                newName += ' ' + count;
            }
        }
        return Promise.resolve({ ...checklist, name: newName });
    };
    return action;
}
