import { ETutorial } from '../../../../../types/rest/IUserMeta';

export const CLASS_TUTORIAL = 'tutorial';

export const TUTORIAL_WIDTH = 422;
export const TUTORIAL_HEIGHT = 237;

export const TUTORIALS_VIDEO_URL_MAP = {
    [ETutorial.SIGN_IN_TUTORIAL]: 'i08XBR0CieI',
    [ETutorial.CARD_DETAILS_TUTORIAL]: 'YJRA2dc8-WI',
    [ETutorial.GANTT_VIEW_TUTORIAL]: 'nOYKIOYAgxM',
    [ETutorial.LIST_VIEW_TUTORIAL]: 'OJmGIopeHZ4',
    [ETutorial.ARCHIVE_TUTORIAL]: 'ISQuI7wXUY4',
    [ETutorial.SUBCARD_OR_CHECKLIST_TUTORIAL]: 'VMQ1UqzQfUs',
    [ETutorial.FILTER_TUTORIAL]: 'z5BoW9VEuw8',
    [ETutorial.TIME_TRACKER_TUTORIAL]: 'lMCyrknrGQk',
};
