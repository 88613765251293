import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../types/types';
import { IProfileUserFields } from '../../components/ProfileUser/types';
import { ProfileUser } from '../../components/ProfileUser/ProfileUser';
import { getAuthUser } from '../../../../../store/model/authUser/selectors/getAuthUser';
import { getIsNewFeatureTopbarRedesign } from 'app/store/model/authUser/selectors/featuresSeen/getIsNewFeatureTopbarRedesign';
import { IProfileUserHOCProps } from './types';
import { EProfileUserWhere } from '../../constants';
import { getIsNewFeatureWYSIWYG } from 'app/store/model/authUser/selectors/featuresSeen/getIsNewFeatureWYSIWYG';

const mapStateToProps = (
    state: IApplicationState,
    { where, tooltipDirection }: IProfileUserHOCProps,
): IProfileUserFields => {
    const {
        permissionId,
        email,
        fullName,
        initials,
        photoUrl,
        platformType,
    } = getAuthUser(state);

    const isNewFeature = where === EProfileUserWhere.NAVPANEL &&
        (getIsNewFeatureTopbarRedesign(state) || getIsNewFeatureWYSIWYG(state));

    return {
        id: permissionId,
        isNewFeature,
        email,
        fullName,
        initials,
        photoUrl,
        platformType,
        tooltipDirection
    }
};

export const ProfileUserHOC = connect(
    mapStateToProps,
    null
)(ProfileUser);

ProfileUserHOC.displayName = 'ProfileUserHOC';
