import { TStatus } from '../../../types/model';

export const getCardIcon = (
    status: TStatus,
    isEpic: boolean
): string => {
    let icon = 'card';
    const isArchive = status === TStatus.STATUS_ARCHIVE;
    if (isArchive || isEpic) {
        if (isArchive && isEpic) {
            icon = 'acrhive-epic';
        } else if (isArchive) {
            icon = 'archive';
        } else if (isEpic) {
            icon = 'epic-card';
        }
    }
    return icon;
}

export const getCardRelatedIcon = (
    status: TStatus
): string => {
    let icon = 'related-card';
    const isArchive = status === TStatus.STATUS_ARCHIVE;
    if (isArchive) {
        icon = 'archive';
    }
    return icon;
}

export const getCardEpicIcon = (
    status: TStatus
): string => {
    let icon = 'epic-card';
    const isArchive = status === TStatus.STATUS_ARCHIVE;
    if (isArchive) {
        icon = 'acrhive-epic';
    }
    return icon;
}

export const getCardTemplateIcon = (
    status: TStatus
): string => {
    let icon = 'card';
    const isTemplate = status === TStatus.STATUS_SERVICE_TEMPLATE;
    if (isTemplate) {
        icon = 'board-template';
    }
    return icon;
}
