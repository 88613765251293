import { IGetState, TBoardId, TCardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { cardSetBackgroundColor } from '../../../../../../../../rest/effects/card/card/cardSetBackgroundColor';
import { getBoardCardColors } from '../../../../../../../../store/model/selectors/getBoardCardColors';
import { SegmentCardEvent, SegmentCardOptions, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { SegmentCardColorTargetValue } from '../../../../../../../../middlewares/segment/trackEntities/cardEvents';
import { getCard } from '../../../../../../../../store/model/selectors/getCard';
import { getCardAssignee } from '../../../../../../../../store/model/cardAssignees/selectors/getCardAssignee';

export const toggleCardColor = (
    cardId: TCardId,
    boardId: TBoardId,
    color: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const card = getCardAssignee(state, cardId);
        const setColor = card.backgroundColor === color ? '' : color;
        dispatch(cardSetBackgroundColor(cardId, setColor));
        const boardColors = getBoardCardColors(state, boardId);
        const option = {
            name: SegmentCardOptions.TARGET,
            value: boardColors.includes(color) ?
                SegmentCardColorTargetValue.BOARD_COLORS :
                SegmentCardColorTargetValue.ALL_COLORS
        };
        const addOption = {
            name: SegmentCardOptions.COLOR,
            value: setColor
        }
        dispatch(segmentTrackAction(SegmentCardEvent.BACKGROUND_CHANGED, option, [addOption]));
    };
    return action;
};
