import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { openedSet } from '../store/common/actions/openedSet';

/**
 * returnFocus нужен, когда закрыли диалог кнопкой Back.
 * По идее нужно где-то хранить, куда возвращать фокус.
 * Пока считаем, что диалог открывается только из нав панели.
 */
export const closeDialog = (
    returnFocus: boolean = false,
): ThunkAction => {
    const action = (dispatch: Dispatch) => {
        dispatch(openedSet(false));

        if (returnFocus) {
            requestAnimationFrame(() => { // диалог закрылся
                const element = document.querySelector('.boards-manager__button') as HTMLElement;
                if (element) element.focus();
            });
        }
    };
    return action;
};
