import { ISnackbarPromt } from './types';
import { Dispatch } from '../../../../../types/actions';
import { ESnackbarPosition, SnackbarVariant } from '../../store/types';
import { SNACKBAR_BUTTON_OK, SNACKBAR_BUTTON_CANCEL } from '../constants';
import { getDefaultActionEvent } from '../helpers/getDefaultActionEvent';
import { addUpdateSnackbarWithBubble } from '../addUpdateSnackbarWithBubble/addUpdateSnackbarWithBubble';

export const snackbarPromtDefault = (
    options: ISnackbarPromt,
    position?: ESnackbarPosition,
) => {
    return (dispatch: Dispatch) => {
        const shouldUseControlsDefault = options.controls === undefined;
        if (shouldUseControlsDefault) {
            options.controls = [
                {
                    action: () => dispatch(getDefaultActionEvent(options.id, options.actionCancel)),
                    title: options.cancelBtnTitle || SNACKBAR_BUTTON_CANCEL
                },
                {
                    action: () => dispatch(getDefaultActionEvent(options.id, options.actionApply)),
                    isPrimary: true,
                    title: options.applyBtnTitle || SNACKBAR_BUTTON_OK
                },
            ];
        }
        dispatch(addUpdateSnackbarWithBubble({
            id: options.id,
            controls: options.controls,
            position,
            text: options.text,
            title: options.title,
            variant: SnackbarVariant.PROMT
        }));
    }
};
