import { IApplicationState, TCardId, TPermissionId } from 'app/types/types';
import { getCardAssignees } from './getCardAssignees';

export const isCardAssignerExist = (
    state: IApplicationState,
    cardId: TCardId,
    permissionId: TPermissionId
): boolean => {
    const assignees = getCardAssignees(state, cardId);
    return assignees.findIndex(assignee => assignee.sharedUser.permissionId === permissionId) >= 0;
};
