import { TBoardId } from '../../../../../../types/types';
import { ISharedUser } from '../../../../../../types/rest/ISharedUser';
import { IRestUser } from '../../../../../../types/rest/IRestUser';

export interface IRestUserProductivityStat {
    id?: number,
    boardId: TBoardId,
    eventName: EUserProductivityEventStatus,
    date: number,
    cardInfo: ICardInfo,
    listInfo: IListInfo
}

export interface ICardInfo {
    id: number,
    startDate: number,
    dueDate: number,
    doneDate: number,
    /***
     * IRestUser used in old events before migrate to ISharedUser
     */
    assignees: ISharedUser[] | IRestUser[],
    statuses: EUserProductivityEventCardStatus[],
    processingPercent: number,
    name: string
}

export interface IListInfo {
    id: number,
    name: string
}

export enum EUserProductivityEventStatus {
    PROCESSING_STATUS_CHANGED = 'PROCESSING_STATUS_CHANGED',
    START = 'START',
    DUE = 'DUE', // DUE_DATE
    DUE_DATE_CHANGED = 'DUE_DATE_CHANGED',
    ASSIGNED_TO_CHANGED = 'ASSIGNED_TO_CHANGED',
    CARD_MOVED_OUT = 'CARD_MOVED_OUT',
    CARD_MOVED_HERE = 'CARD_MOVED_HERE',
    CARD_DELETED = 'CARD_DELETED'
}

/***
 * Статус карточки на МОМЕНТ совершения Event
 */
export enum EUserProductivityEventCardStatus {
    DONE_ON_TIME = 'DONE_ON_TIME', // DATE_OF_DONE <= DUE_DATE || DUE_DATE == null
    DONE_PAST_DUE = 'DONE_PAST_DUE', // DUE_DATE < DATE_OF_DONE
    UNDONE = 'UNDONE', // DATE_OF_DONE == null
    OVERDUE = 'OVERDUE', // DUE_DATE <= NOW  && DATE_OF_DONE == null
    DONE = 'DONE', // DATE_OF_DONE != null
    START = 'START', // START_DATE >= NOW
    DUE = 'DUE', // DUE_DATE
}
