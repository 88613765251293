export enum ECardNumberStatus {
    LOADED = 'LOADED',
    ENABLING = 'ENABLING',
    VALIDATING = 'VALIDATING',
    SAVING = 'SAVING',
    INITIAL = 'INITIAL'
}

export interface ICardNumberInitialValue {
    prefix: string;
    nextValue: string;
}

export interface ICardNumberState {
    prefix: string;
    nextValue: string;
    enabled: boolean;
    errorMessage?: string;
    status: ECardNumberStatus;
    edited: boolean;
    initialValue?: ICardNumberInitialValue;
}
