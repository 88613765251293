import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { ICardHOCProps } from '../CardHOC/types';
import { CardDueDate } from '../../components/CardDueDate/CardDueDate';
import { ICardDueDateEvents, ICardDueDateFields } from '../../components/CardDueDate/types';
import { getUserTimeFormat12 } from '../../../../../../../store/model/authUser/selectors/getUserTimeFormat12';
import { onDateChange } from '../events/onDateChange';
import { getCard } from '../../../../../../../store/model/selectors/getCard';
import {
    getBoardMetaDefaultDueDateTime
} from '../../../../../../../store/model/selectors/getBoardMetaDefaultDueDateTime';
import {
    getBoardMetaDefaultStartDateTime
} from '../../../../../../../store/model/selectors/getBoardMetaDefaultStartDateTime';
import { getCardIsEpic } from '../../../../../../../store/model/selectors/getCardIsEpic';
import {
    getBoardIsCardRequiredProperty
} from '../../../../../../../store/model/board/selectors/getBoardIsCardRequiredProperty';
import { EAsidePanelProperty } from '../../../../../aside_panel/asidePanel/components/AsidePanel/types';
import { onLoadCard } from '../events/onLoadCard';
import { getCardDateReadonly } from './selectors/getCardDateReadonly';

const mapStateToProps = (
    state: IApplicationState,
    { card, portalId }: ICardHOCProps
): ICardDueDateFields => {
    const { dueDate, startDate, processingPercent, cardId, dashboardId } = card;
    const cardFull = getCard(state, cardId);
    const isOverdue = dueDate && dueDate * 1000 < Date.now() && processingPercent !== 100;

    return {
        defaultDueTime: getBoardMetaDefaultDueDateTime(state, dashboardId),
        defaultStartTime: getBoardMetaDefaultStartDateTime(state, dashboardId),
        dueDate,
        isMilestone: cardFull && cardFull.isMilestone,
        startDate,
        isOverdue,
        isDisabled: getCardIsEpic(state, cardId),
        isDueRequired: getBoardIsCardRequiredProperty(state, dashboardId, EAsidePanelProperty.CARD_DUE_DATE),
        isReadonly: getCardDateReadonly(state, dashboardId, cardId),
        isStartRequired: getBoardIsCardRequiredProperty(state, dashboardId, EAsidePanelProperty.CARD_START_DATE),
        portalId,
        isUserTimeFormat12: getUserTimeFormat12(state)
    }
}

const mapDispatchToProps = (
    dispatch: any,
    { card }: ICardHOCProps
): ICardDueDateEvents => ({
    onLoadCard: () => dispatch(onLoadCard(card.cardId)),
    onChange: (date, dateType) => dispatch(onDateChange(card.cardId, date, dateType)),
})

export const CardDueDateHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardDueDate);
CardDueDateHOC.displayName = 'CardDueDateHOC';
