import { IApplicationState } from '../../../../../../../../types/types';
import { connect } from 'react-redux';
import { IStatusListsSectionFields } from '../../components/StatusListsSection/types';
import { getIsUserBasicPlanOnly } from '../../../../../../../../store/model/authUser/selectors/getIsUserBasicPlanOnly';
import { StatusListsSection } from '../../components/StatusListsSection/StatusListsSection';

const mapStateToProps = (
    state: IApplicationState,
): IStatusListsSectionFields => {
    return {
        isBasicFeature: getIsUserBasicPlanOnly(state),
    }
};

export const StatusListsSectionsHOC = connect(
    mapStateToProps,
    null
)(StatusListsSection);
