import { IGetState, TCardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { getCardAssignee } from '../../../../../../../../store/model/cardAssignees/selectors/getCardAssignee';
import { getRest } from '../../../../../../../../rest/effects/cardAssignees/api/getRest';
import { IRestCardAssigneeInfo } from '../../../../../../../../types/rest/IRestCardAssigneeInfo';
import { cardAssigneesActionSetAction } from '../../../../../../../../store/model/actions/cardAssigneesActionSetAction';
import { cardAssigneeAddAction } from '../../../../../../../../store/model/cardAssignees/actions/cardAssigneeAddAction';
import { TStatus } from '../../../../../../../../types/model';
import {
    cardAssigneeUpdateAction
} from '../../../../../../../../store/model/cardAssignees/actions/cardAssigneeUpdateAction';
import { sendAuthUserRealTimeStoreAction } from '../../../../../../base/helpers/realTimeHelperTS';

export const onLoad = (
    cardId: TCardId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const card = getCardAssignee(getState(), cardId);
        if (card) {
            if (card.status === TStatus.STATUS_DELETED) {
                const updateAction = dispatch(cardAssigneesActionSetAction(cardAssigneeUpdateAction(cardId, { status: TStatus.STATUS_DELETED })));
                dispatch(sendAuthUserRealTimeStoreAction(updateAction));
            }
            return;
        }
        dispatch(getRest(cardId)).then((cardAssignee: IRestCardAssigneeInfo) => {
            dispatch(cardAssigneesActionSetAction(cardAssigneeAddAction(cardId, cardAssignee)));
        }).catch( (e: any) =>{
        });
    };
    return action;
};
