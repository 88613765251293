export const getStringDate = (
    date: Date,
    isAmericanDate: boolean
): string => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = String(date.getFullYear()).slice(-2);
    return isAmericanDate ?
        `${month}/${day}/${year}`
        : `${day > 9 ? day : '0' + day}/${month > 9 ? month : '0' + month}/${year}`;
}
