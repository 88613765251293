import * as React from 'react';
import { AsidePanelActionsButton } from '../../../..';
import { BOARD_BUTTON_COPY } from './constants';
import { ICopyButtonProps } from './types';

export function CopyButton ({
    isDisallow,
    tooltip,
    onClick,
}: ICopyButtonProps) {
    return (
        <AsidePanelActionsButton
            isDisabled={isDisallow}
            title={BOARD_BUTTON_COPY}
            tooltip={tooltip}
            onClick={onClick}
        />
    );
};
