import { TGoogleDriveId } from '../types';

export interface ITemplates {
    templates: ITemplate[];
    templatePreviewId: TTemplateId;
}

export interface ITemplateInfo {
    bigImageUrl: string;
    createdDate: number;
    orderNumber: number;
    smallImageUrl: string;
    template: boolean;
    type: ETemplateType;
}

export type TTemplateId = number;

export interface ITemplate {
    description: string;
    driveId: TGoogleDriveId;
    id: TTemplateId;
    name: string;
    templateInfo: ITemplateInfo;
}

export enum ETemplateType {
    custom = 'custom',
    default = 'default',
    edu = 'edu'
}
