/***
 * default prefix "ASIDE_PANEL"
 */

export const AT_ENTITY_PANEL_WIDTH_SET = 'ASIDE_PANEL_ENTITY_PANEL_WIDTH_SET';
export const AT_BUTTONS_PANEL_TYPE_SET = 'ASIDE_PANEL_BUTTONS_PANEL_TYPE_SET';
export const AT_IS_MOBILE_SET = 'ASIDE_PANEL_IS_MOBILE_SET';
export const AT_IS_PRINT_SET = 'ASIDE_PANEL_IS_PRINT_SET';
export const AT_TAB_KEY_SET = 'ASIDE_PANEL_TAB_KEY_SET';
export const AT_LEFT_PANEL_OPENED_SET = 'ASIDE_PANEL_LEFT_PANEL_OPENED_SET';
export const AT_WIDTH_SET = 'ASIDE_PANEL_WIDTH_SET';
export const AT_ACTIVITYID_SET = 'ASIDE_PANEL_ACTIVITYID_SET';
export const AT_IS_GOOGLE_FILE_CREATING = 'ASIDE_PANEL_IS_GOOGLE_FILE_CREATING';
export const AT_GANTT_CONTEXT_HINTS_CARD_ID_SET = 'ASIDE_PANEL_GANTT_CONTEXT_HINTS_CARD_ID_SET';
