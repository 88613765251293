'use strict';

import { ERestActivityPropertyActionType } from "../../types/rest/activity/IRestActivityBase";
import { getActivityItem } from "../react_components/aside_panel/boardDetails/activity/TabActivity/helpers/aсtivityHelper";

App.Views.CardUpdateDoneNotification = App.Views.BaseNotification.extend({

    getActions: function() {
        const item = getActivityItem(this.notificationModel);
        let content = 'Done';
        if (this.notificationModel.propertyActionType === ERestActivityPropertyActionType.DELETE) {
            content = '<strike>' + content + '</strike>';
        }
        return {
            bodyRaw: content,
            action: item.label,
        };
    },

    onClick: function(e, notification) {
        var dashboardId = this.notificationModel.dashboardId;
        var cardId = this.notificationModel.card.id;
        App.controller.cleverOpenCard(dashboardId, cardId);
        this.closeNotification(notification);
        e.stopPropagation();
    }
});
