import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { TCardId } from '../../../../../../../../types/types';
import { cardSetName } from '../../../../../../../../rest/effects/card/card/cardSetName';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';

export const onSave = (
    cardId: TCardId,
    name: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        dispatch(cardSetName(cardId, name));
        dispatch(segmentTrackAction(SegmentCardEvent.CHANGE_NAME));
    };
    return action;
};
