import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState, TCardId } from '../../../../types/types';
import { cardAdd } from './cardAdd';
import { getCard } from '../../../../store/model/selectors/getCard';
import { getNextSubCardOrderNumber } from '../../../../store/model/selectors/getNextSubCardOrderNumber';
import { INewCard } from '../../../../store/model/card/types';
import { getCardEpicsForProcessingPercentChange } from '../../../../store/model/selectors/getCardEpicsForProcessingPercentChange';
import { cardsRestPatch } from './api/helper/cardsRestPatch';
import { sendCardStatWithIncrement } from '../../../../helper/comet/stat_helper_ts';
import { getList } from '../../../../store/model/list/selectors/getList';
import { getNextCardOrderNumberAfterCardId } from '../../../../store/model/list/selectors/getNextCardOrderNumberAfterCardId';
import { TStatus } from '../../../../types/model';

export const createSubcard = (
    epicId: TCardId,
    name: string,
    status: TStatus = TStatus.STATUS_ACTIVE
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const card = getCard(state, epicId);
        if (!card) return Promise.reject();

        const subCard: INewCard = {
            epicId: epicId,
            listId: card.listId,
            name,
            orderNumber: getNextCardOrderNumberAfterCardId(state, card.listId, epicId),
            subCardOrderNumber: getNextSubCardOrderNumber(state, epicId),
            status
        };

        return dispatch(cardAdd(subCard)).then(() => {
            dispatch(sendCardStatWithIncrement(getList(getState(), card.listId), 1, 0));
        }).then(() => {
            const cards = getCardEpicsForProcessingPercentChange(getState(), epicId);
            return dispatch(cardsRestPatch(cards));
        });
    };
    return action;
};
