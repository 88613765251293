import { Dispatch, ThunkAction } from '../../../types/actions';
import { TBoardId } from '../../../types/types';
import { boardUpdateMeta } from './boardUpdateMeta';

export const boardSetDefaultDueDateTimeMeta = (
    boardId: TBoardId,
    defaultDueDateTime: Date
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        return dispatch(boardUpdateMeta(boardId, { defaultDueDateTime }));
    };
    return action;
};
