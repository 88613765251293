import { IGoogleDrive } from '../../types';
import { IDrivesSetAction } from './types';
import { AT_DRIVES_SET } from './constants';

export const drivesSet = (
    drives: IGoogleDrive[]
): IDrivesSetAction => ({
    type: AT_DRIVES_SET,
    drives
});
