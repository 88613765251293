import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../types/types';
import { IPermissionFAQModalEvents } from 'app/view/react_components/dialogs/permissionsFAQModal/components/PermissionsFAQModal/types';
import { PermissionsFAQModal } from '../../components/PermissionsFAQModal/PermissionsFAQModal';
import { onClose } from './events/onClose';

const mapDispatchToProps = (
    dispatch: any,
): IPermissionFAQModalEvents => {
    return {
        onClose: () => dispatch(onClose()),
    }
};

export const PermissionsFAQModalHOC = connect(
    null,
    mapDispatchToProps
)(PermissionsFAQModal);

PermissionsFAQModalHOC.displayName = 'PermissionsFAQModalHOC';
