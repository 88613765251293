import {IApplicationState} from '../../../../types/types';
import {getAuthUser} from './getAuthUser';

export const getAuthUserIsAttachmentViewTypeGrid = (
    state: IApplicationState
): boolean => {
    const user = getAuthUser(state);

    return user.meta && user.meta.isAttachmentViewTypeGrid;
};
