import { TCondition } from '../types';
import { IApplicationState } from '../../../../types/types';
import { EHintKey, getHintConfig } from '../constants';
import {
    getAsidePanelIsMobile
} from 'app/view/react_components/aside_panel/asidePanel/store/selectors/getAsidePanelIsMobile';
import {
    getNavigationPanelState
} from 'app/view/react_components/main/navigationPanel/store/selectors/getNavigationPanelState';
import { ENavigationPanelTypes } from 'app/view/react_components/main/navigationPanel/constants';
import { getAuthUserQuickStartGuides } from '../../../../store/model/authUser/selectors/getAuthUserQuickStartGuides';
import { getQuickStartGuides } from '../../main/navigationPanel/components/NavigationPanelQuickStartGuide/constants';

export const quickStartGuideCondition: TCondition = (
    state: IApplicationState
) => {
    const hint = getHintConfig(EHintKey.QUICK_START_GUIDE);
    if (!hint) return false;

    const navPanel = getNavigationPanelState(state);
    if (!navPanel || navPanel.panelType !== ENavigationPanelTypes.NP_BIG_TYPE) return false;

    const userGuides = getAuthUserQuickStartGuides(state);
    let isGuidesCompleted = !!userGuides;
    if (userGuides) {
        if (Object.values(getQuickStartGuides(state)).length > Object.values((userGuides)).length) {
            isGuidesCompleted = false;
        } else {
            for (let guide in userGuides) {
                if (!userGuides[guide].isFinished) {
                    isGuidesCompleted = false;
                    break;
                }
            }
        }
    }

    return isGuidesCompleted;
};
