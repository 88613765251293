import { IGetState, TBoardId, TCardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { ICards } from '../../../../store/model/cards/types';
import { getCardCanBeSetDone } from '../../../../store/model/selectors/getCardCanBeSetDone';
import { getCardProcessingUpdates } from '../../../../store/model/selectors/getCardProcessingUpdates';
import { ICardBatch, ICardBatches } from './api/types';
import { cardsPatchRestBatch } from './api/helper/cardPatchRestBatch';
import { getBoard } from '../../../../store/model/selectors/getBoardById';
import { STATUS_LIST_ARCHIVE_LIST_ID } from '../../../../const';
import { cardsArchiveOrDelete } from '../../../../view/react_components/base/effects/cardsArchiveOrDelete';

export const cardsSetDoneProcessing = (
    boardId: TBoardId,
    cardIds: TCardId[],
    done: boolean,
    showMoveInfoPopup: boolean = true
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const cardBatches = cardsSetDoneProcessingBatches(getState, cardIds, done);
        return cardsPatchRestBatch(dispatch, boardId, cardBatches, showMoveInfoPopup)
            .then(() => {
                if (done) {
                    const { statusLists } = getBoard(getState(), boardId);
                    const isArchiveOnDone = statusLists && statusLists.doneId === STATUS_LIST_ARCHIVE_LIST_ID;
                    if (isArchiveOnDone) {
                        let cardIds: TCardId[] = [];
                        for (let cardId in cardBatches) {
                            const { card } = cardBatches[cardId];
                            if (card.doneDate) {
                                cardIds.push(Number(cardId));
                            };
                        }
                        const isSingle = cardIds.length < 2;
                        let isEpic = isSingle && cardIds.length > 1;
                        dispatch(cardsArchiveOrDelete(cardIds, true, true, isEpic));
                    }
                }
            });
    };
    return action;
};

export const cardsSetDoneProcessingBatches = (
    getState: IGetState,
    cardIds: TCardId[],
    done: boolean
): ICardBatches => {
    const state = getState();
    const cards: ICards = cardIds.reduce((result: ICards, cardId) => {
        const cardCanBeSetDone = getCardCanBeSetDone(state, cardId);
        if (!cardCanBeSetDone) return result;
        result = getCardProcessingUpdates(state, cardId, done ? 100 : 0, result);
        return result;
    }, {});
    const cardBatches: ICardBatches = {};
    for (let cardId in cards) {
        const cardBatch: ICardBatch = {card: cards[cardId], callbacks: []};
        cardBatches[cardId] = cardBatch;
    }

    return cardBatches;
};
