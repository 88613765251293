import { Button, Icon } from 'kui';
import * as React from 'react';
import './_BoardStarredButton.scss';
import { IBoardStarredButtonProps } from './types';
import { CLASS_BOARD_DETAILS } from '../../../../constants';
import { getAsidePanelTooltip } from 'app/view/react_components/aside_panel/asidePanel/helpers/getAsidePanelTooltip';
import { TOPBAR_BOARD_ADD_TO_STARRED, TOPBAR_BOARD_REMOVE_FROM_STARRED } from 'app/view/react_components/main/topBar/components/TopBarDashboardName/constants';

export function BoardStarredButton ({
    isShow,
    starred,
    onClick
}: IBoardStarredButtonProps) {
    if (!isShow) return;

    const className = CLASS_BOARD_DETAILS + '__starred';
    const classNameStarred = starred ? className + '--starred' : '';

    return (
        <Button
            className={`
                ${className}
                ${classNameStarred}
            `}
            tooltip={getAsidePanelTooltip({
                value: starred ? TOPBAR_BOARD_REMOVE_FROM_STARRED : TOPBAR_BOARD_ADD_TO_STARRED
            })}
            variant={'icon-text'}
            onClick={onClick}
        >
            <Icon
                size={24}
                xlink={'star'}
            />
        </Button>
    );
};
