import { IApplicationState } from '../../../../types/types';
import { getUserActiveSubscriptions } from './getUserActiveSubscriptions';

export const isPaidUser = (state: IApplicationState) => {
    let paid = false;
    const activeSubscription = getUserActiveSubscriptions(state);
    activeSubscription.forEach(sub => {
        if(!sub.isTrial){
            paid = true;
        }
    });
    return paid;
};
