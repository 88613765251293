import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState } from '../../../../types/types';
import { postRest } from './postRest';
import { REST_AUTH_USER_DELETE_PUSH_TOKEN } from '../../../constants';

export const userDeleteWebPushNotifyToken = (
    token: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(postRest(REST_AUTH_USER_DELETE_PUSH_TOKEN, {token}))
    };
    return action;
};
