import { IListUpdateAction } from './types';
import { AT_UPDATE } from './constants';
import { IList } from '../types';

export const listUpdate = (
    list: IList
): IListUpdateAction => {
    return {
        type: AT_UPDATE,
        list
    }
};
