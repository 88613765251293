import { fetchHandler } from 'app/util/fetchHandler';
import Util from 'app/util/util';
import { getRestHeadersDelete } from 'app/rest/helpers/getRestHeadersHelper';

export const staredNotificationsDelete = (
    ids: number[]
) => {
    return fetchHandler(
        Util.getApiUrl('/rest/staredPushnotification/list'), {
            ...getRestHeadersDelete(),
            body: JSON.stringify(ids)
        }
    );
};
