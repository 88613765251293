import { IApplicationState, TBoardId } from '../../../types/types';
import { getBoard } from './getBoardById';
import { ICardAging } from '../../../types/rest/ICardAging';

export const getBoardMetaCardAging = (
    state: IApplicationState,
    boardId: TBoardId
): ICardAging => {
    const board = getBoard(state, boardId);
    return board && board.meta && board.meta.cardAging || {};
}
