App.Classes.DashboardXmlImportManager = Backbone.View.extend({

    initialize: function(options) {
        this.url = App.Util.getApiUrl('/rest/dashboard/xml/import');
        this.workContext = null;
        this.on('onSuccess', this.onSuccess, this);
    },

    /**
     * Запуск импорта из xml файла
     * @returns true или false в зависимости от наличия workContext
     */
    startImport: function(options) {
        if (this.workContext) return false;
        this.importFileId = options.importFileId;
        this.importDriveId = options.importDriveId;
        this.dashboardName = options.dashboardName || '';
        this.url = options.url ? options.url : this.url;
        this.workContext = {};
        this.doImport();
    },

    doImport: function() {
        var request = {
            importFileId: this.importFileId,
            importDriveId: this.importDriveId,
            destDashboardName: this.dashboardName,
            workContext: this.workContext.serverWorkContext
        };
        $.ajax({
        	beforeSend: App.vent.beforeSend,
            method: "POST",
            url: this.url,
            data: JSON.stringify(request),
            dataType: "json",
            contentType: "application/json",
            processData: false
        }).done(_.bind(function(result) {
            if (!this.checkResponseError(result) || !this.checkCancelStatus()) return;
            this.workContext.serverWorkContext = result.workContext;
            this.callbackHandler('onProgress', result.percentProgress);
            if (result.completed)
                this.callbackHandler('onSuccess', result.destDashboardId);
            else
                this.doImport();
        }, this)).fail(_.bind(function(result) {
            this.workContext = null;
            this.callbackHandler('onError', result.statusText);
        }, this));
    },

    /**
     * Отмена импорта
     * @returns true или false в зависимости от наличия workContext
     */
    cancelImport: function() {
        if (!this.workContext) return false;
        this.workContext.cancelStatus = true;
        return true;
    },

    /**
     * Проверка возвращаемого значения на error
     * @param result
     * @returns boolean
     */
    checkResponseError: function(result) {
        if (!result.success) {
            this.workContext = null;
            this.callbackHandler('onError', result.errorMessage);
            return false;
        }
        return true;
    },

    /**
     * Проверка workContext на cancelStatus
     * @returns boolean
     */
    checkCancelStatus: function() {
        if (this.workContext.cancelStatus) {
            this.workContext = null;
            this.callbackHandler('onCancel');
            return false;
        }
        return true;
    },

    /**
     * Функция которая вызывает на себя тригер события
     * @param name Имя события
     * @param options дополнительные параметры события
     */
    callbackHandler: function(name, options) {
        this.trigger(name, options);
    },

    onSuccess: function() {
        this.workContext = null;
    },

    isReady: function() {
        return this.workContext == null
    }
});

export default App.Classes.DashboardXmlImportManager;
