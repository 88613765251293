import * as React from 'react';
import { IBoardActivityProps } from './types';
import { CLASS_ACTIVITY_ITEM } from '../constants';
import './_ActivityItem.scss';
import { TActivity } from '../../../../../../../../store/activities/types';

export function ActivityItem({
    activity,
    asidePanelWidth,
    isHighlight,
    userId
}: IBoardActivityProps) {
    const className = CLASS_ACTIVITY_ITEM;
    const classHighlight = isHighlight ? className + '--highlight' : '';
    const { Component } = activity as TActivity;
    return (
        <div className={`
            ${className}
            ${classHighlight}
            ${className}--${activity.id}
        `}>
            {Component
                ? <Component activity={activity} userId={userId} asidePanelWidth={asidePanelWidth} />
                : <span>
                    activity item component not found: {JSON.stringify(activity)}
                </span>
            }
        </div>
    );
};
