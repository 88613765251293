import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { showSnoozeBlocker } from '../../../../../../base/effects/showSnoozeBlocker';
import { ESegmentBlockerType } from '../../../../../../../../middlewares/segment/trackEntities/blockersEvents';

export const onArchive = (): ThunkAction => {
    return (dispatch: Dispatch) => {
        dispatch(showSnoozeBlocker({
            blockerType: ESegmentBlockerType.ARCHIVE,
            allow: 'allowArchive'
        }));
    };
};
