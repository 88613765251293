import {connect} from 'react-redux';
import {onCancel} from './events/onCancel';
import {onClickCsv} from './events/onClickCsv';
import {onClickJira} from './events/onClickJira';
import {onClickTrello} from './events/onClickTrello';
import {IImportDialogEvents} from '../../components/ImportDialog/types';
import {ImportDialog} from '../../components/ImportDialog/ImportDialog';
import {onClickCsvLearnMore} from './events/onClickCsvLearnMore';
import {onClickJiraLearnMore} from './events/onClickJiraLearnMore';
import {onClickTrelloLearnMore} from './events/onClickTrelloLearnMore';

const mapDispatchToProps = (
    dispatch: any
): IImportDialogEvents => {
    return {
        onCancel: () => dispatch(onCancel()),
        onClickCsv: () => dispatch(onClickCsv()),
        onClickCsvLearnMore: () => dispatch(onClickCsvLearnMore()),
        onClickJira: () => dispatch(onClickJira()),
        onClickJiraLearnMore: () => dispatch(onClickJiraLearnMore()),
        onClickTrello: () => dispatch(onClickTrello()),
        onClickTrelloLearnMore: () => dispatch(onClickTrelloLearnMore())
    }
};

export const ImportDialogHOC = connect(
    null,
    mapDispatchToProps
)(ImportDialog);

ImportDialogHOC.displayName = 'ImportDialogHOC';
