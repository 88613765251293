export const CLASS_ASIDE_PANEL = 'aside-panel';
export const RIGHT_ASIDE_PANEL_SELECTOR = `aside.${CLASS_ASIDE_PANEL}`;
export const CLASS_ASIDE_PANEL_BOARD_SORTING = 'aside-panel--board-sorting';
export const CLASS_ASIDE_PANEL_SELECT = 'aside-panel-select';
export const CLASS_ASIDE_PANEL_OPENED = 'aside-panel--opened';
export const ASIDE_PANEL_ACTIONS_POPUP_MIN_HEIGHT = 800; // px
export const CLASS_ASIDE_DROP_PORTAL = CLASS_ASIDE_PANEL + '-drop-portal';
export const CLASS_ASIDE_PANEL_STATIC = CLASS_ASIDE_PANEL + '--static';
export const CLASS_ASIDE_PANEL_STATIC_NO_HEADER = CLASS_ASIDE_PANEL + '--static-no-header';
export const CLASS_ASIDE_POPUP_PORTAL = CLASS_ASIDE_PANEL + '-popup-portal';
