import { getMessages } from '../../../../../../../store/constants';

export const BOARD_EXPORT_TITLE_GOOGLE_SETTINGS = getMessages().getText('board_settings.export.google_sheet_settings');
export const BOARD_EXPORT_TITLE_DATE_FORMAT = getMessages().getText('board_settings.export.date_format');
export const BOARD_EXPORT_TITLE_TIME_FORMAT = getMessages().getText('board_settings.export.time_format');
export const BOARD_EXPORT_TITLE_COLUMNS = getMessages().getText('board_settings.export.columns');
export const BOARD_EXPORT_TITLE_CHECKLISTS = getMessages().getText('board_settings.export.checklists');
export const BOARD_EXPORT_TITLE_CHECKLISTS_ROWS = getMessages().getText('board_settings.export.checklists.rows');
export const BOARD_EXPORT_TITLE_SETTINGS = getMessages().getText('board_settings.export.settings');
export const BOARD_EXPORT_TITLE_ARCHIVED = getMessages().getText('board_settings.export.export_archived');
export const BOARD_EXPORT_BUTTON_SELECT_ALL = getMessages().getText('board_settings.export.columns.select_all');
export const BOARD_EXPORT_BUTTON_DESELECT_ALL = getMessages().getText('board_settings.export.columns.deselect_all');

export const BOARD_EXPORT_SETTINGS_COLUMNS: string[] = [
    'Card title',
    'Prefix',
    'Description',
    'Card status',
    'List name',
    'List status',
    'Author',
    'Created',
    'Epic',
    'Start date',
    'Due date',
    'Estimate',
    'Spent time',
    'Assigned users',
    'Tags',
    'Priority',
    'Colour labels',
    'Processing status',
    '% Complete',
    'Done date',
    'Attachments',
    'Comments',
    'Checklists',
    'Related cards',
    'Card link',
    'Date of occurring in the list',
    'Last change date',
];

export const DEFAULT_UNENABLED_BOARD_EXPORT_SETTINGS_COLUMNS = [
    'Date of occurring in the list',
    'Last change date',
];

export const BOARD_SETTINGS_DATE_FORMATS: string[] = [
    'yyyy-mm-dd hh:mm',
    'dd/mm/yyyy hh:mm'
]
