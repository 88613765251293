import * as React from 'react';
import { ICustomPropertiesProps } from './types';
import { CLASS_CARD_DETAILS_SECTION } from '../../../constants';
import { CARD_CUSTOM_ADD, CARD_CUSTOM_EDIT } from './constants';
import { CustomPropertiesElements } from '../CustomPropertiesElements/CustomPropertiesElements';
import { SettingsSection } from '../../../../../base/components/SettingsSection/SettingsSection';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { CARD_SECTION_CUSTOM, CARD_SECTION_CUSTOM_FIRST_TEXT, CARD_SECTION_CUSTOM_SECTION_EXISTING, CARD_SECTION_CUSTOM_SECTION_NEW, CLASS_CARD_CUSTOM } from '../constants';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { EAsidePanelProperty } from '../../../../asidePanel/components/AsidePanel/types';
import { CLASS_SETTINGS_SECTION_EMPTY } from '../../../../../base/components/SettingsSection/constants';
import { CustomPropertiesForm } from '../CustomPropertiesForm/CustomPropertiesForm';
import './_CustomPropertiesSection.scss';
import { Button, Icon } from 'kui';
import { getAsidePanelTooltip } from '../../../../asidePanel/helpers/getAsidePanelTooltip';
import { IBoardCardCustomProperty } from 'app/store/customProperties/types';
import { CUSTOM_BUTTON_CANCEL } from '../CustomPropertiesElements/constants';
import { v4 as uuidv4 } from 'uuid';
import { BOARD_SECTION_CARD_CUSTOM_PROPERTIES_TEXT } from '../../../../boardDetails/details/CardCustomProperties/constants';
import { CardCustomProperties } from '../../../../boardDetails/details/CardCustomProperties/components/CardCustomProperties/CardCustomProperties';
import ReactHtmlParser from 'react-html-parser';
import { AnalyzeRender } from '../../../../../helpers/memoizeHelper';

//@ts-ignore
const analyzeRender = window.Settings.development ? new AnalyzeRender(`CustomPropertiesSection`) : null;

export function CustomPropertiesSection ({
    className,
    elements,
    isBasicFeature,
    isDefaultProperty,
    isProFeature,
    isShow,
    sectionType,
    title,
    onChange: onAdd,
    onChangeType,
    onClickCancel,
    onClickEdit,
    onClickEmpty,
    onClose,
    onOpen,
    onOptionAdd,
    onSave,
    onSort,
    onUpdateValue,
    onToggleEdit,
    onConfirmDelete,
    onTogglePin,
    onTogglePinOnCard,
    ...attributes
}: ICustomPropertiesProps) {
    const { cardId, openedProperty, addShowedProperty, boardId } = React.useContext(AsidePanelContext);
    const isBoardSettings = !cardId;

    if (analyzeRender) analyzeRender.call(`CustomPropertiesSection ${isBoardSettings ? boardId : cardId}`);

    const isDefault = sectionType === ESettingsSectionType.DEFAULT;
    const isPopup = sectionType === ESettingsSectionType.POPUP;
    const isReadonly = sectionType === ESettingsSectionType.READONLY;
    className = className || CLASS_CARD_DETAILS_SECTION + ' ' + CLASS_CARD_DETAILS_SECTION + '--custom';
    const classNameEmpty = !elements.length ? CLASS_SETTINGS_SECTION_EMPTY : '';
    const classNameEdit = CLASS_CARD_CUSTOM + '__button-edit';
    const classFormText = CLASS_CARD_CUSTOM + '__form-text';
    const classTitle = CLASS_CARD_CUSTOM + '__title';
    const [isShowSecion, setShowSection] = React.useState(false);
    const [isOpened, setIsOpened] = React.useState(isPopup);
    const [isEdit, _setEdit] = React.useState(false);
    const [editKey, setEditKey] = React.useState(uuidv4());
    const [isHideEmpty, setHideEmpty] = React.useState(
        isPopup || isReadonly || isBoardSettings || !elements || !elements.length
            ? null // null = не рисовать HideButton
            : getEmptyCount() ? true : null
    );

    const onAddOpened = () => {
        setIsOpened(true);
        if (onOpen) onOpen();
        onCancel();
        if (isHideEmpty) setHideEmpty(false);
    };
    const onAddClosed = (
        force: boolean = false // for x in popup
    ) => {
        setIsOpened(false);
        if ((force || !isPopup) && onClose) onClose();
    };

    const setEdit = (value: boolean) => {
        _setEdit(value);
        if (onToggleEdit && value !== isEdit) {
            onToggleEdit(value);
        }
    }

    const onEditHandler = () => {
        setEdit(true);
        if (!isPopup) setIsOpened(false);
        onClickEdit();
    };

    const onSaveHandler = (elements: IBoardCardCustomProperty[]) => {
        onSave(elements);
        setEdit(false);
    };

    const onCancel = () => {
        setEdit(false);
        setEditKey(uuidv4());
    };

    const onClickCancelHandler = () => {
        onCancel();
        onClickCancel();
    };

    const onClickEmptyHandler = () => {
        onClickEmpty(isHideEmpty);
        setHideEmpty(!isHideEmpty);
    };

    function getEmptyCount () {
        if (!elements.length) return 0;

        return elements.reduce((empty, element) => {
            if (!element.pinned && (element.value === null || element.value === undefined)) empty++;
            return empty;
        }, 0);
    }

    React.useEffect(() => {
        if (isDefault && !isBoardSettings
        ) {
            if (isHideEmpty === null) { // после добавления первого поля и закрытия попапа показать кнопку
                if (getEmptyCount()) setHideEmpty(false);
            } else { // после заполнения всех полей спрятать кнопку
                if (!getEmptyCount()) setHideEmpty(null);
            }
        }
    }, [elements]);

    React.useEffect(() => {
        if (classNameEmpty) { // при удалени последнего
            onCancel(); // закрыть Edit
            setHideEmpty(null); // скрыть кнопку
        }
    }, [classNameEmpty]);

    React.useEffect(() => {
        if (isShow && !isPopup || isDefaultProperty) {
            setShowSection(true);
            addShowedProperty(EAsidePanelProperty.CARD_CUSTOM);
        }
    }, [elements, isDefaultProperty]);

    if (
        !isBoardSettings && (
            (!isShowSecion && !isPopup) ||
            (isShowSecion && !isPopup && openedProperty === EAsidePanelProperty.CARD_CUSTOM)
        )
    ) {
        return;
    }

    return (
        <SettingsSection
            addTitle={ CARD_CUSTOM_ADD }
            isProFeature={isProFeature}
            isBasicFeature={isBasicFeature}
            addComponent={
                /**
                 * если на доске нет кастом полей, то в попапе рисуем обычный addComponent
                 * если есть кастом поля, то мы не знаем, чего хочет юзер, добавить новое поле или заполнить существующее
                 */
                (!isPopup || classNameEmpty) &&
                isOpened ? <>
                    {classNameEmpty && !isBoardSettings
                        ? <div
                            className={classFormText}
                        >{ReactHtmlParser(CARD_SECTION_CUSTOM_FIRST_TEXT)}</div>
                        : <div className={classTitle}>
                            {CARD_SECTION_CUSTOM_SECTION_NEW}
                        </div>
                    }
                    <CustomPropertiesForm
                        autofocus
                        isCloseOnAdd={!isPopup}
                        names={elements.map(element => element.name)}
                        isPopup={isPopup}
                        onChange={onAdd}
                        onChangeType={onChangeType}
                        onClose={onAddClosed}
                    />
                </> : <div/>
            }
            className={`
                ${className}
                ${isOpened ? classNameEmpty + '-opened' : classNameEmpty}
            `}
            isAddWide
            isOpened={ isOpened }
            title={title || CARD_SECTION_CUSTOM}
            titleButton={isReadonly ||
                classNameEmpty ||
                isPopup && isEdit // спрятать крест в попапе, чтоб не было 2 креста рядом
                ? null
                : <Button
                    className={classNameEdit}
                    tooltip={getAsidePanelTooltip({
                        value: isEdit ? CUSTOM_BUTTON_CANCEL : CARD_CUSTOM_EDIT
                    })}
                    variant={'icon'}
                    onClick={() => isEdit ? onClickCancelHandler() : onEditHandler()}
                >
                    <Icon size={24} xlink={isEdit ? 'close' : 'pen'} />
                </Button>
            }
            sectionType={ sectionType }
            onAddOpened={ onAddOpened }
            onAddClosed={() => onAddClosed(true)}
            { ...attributes }
        >
            <>
                {isPopup && !classNameEmpty && <>
                    <div className={classTitle}>
                        {CARD_SECTION_CUSTOM_SECTION_NEW}
                    </div>
                    <CustomPropertiesForm
                        names={elements.map(element => element.name)}
                        isPopup={isPopup}
                        onChange={onAdd}
                        onChangeType={onChangeType}
                    />
                </>}
                {(isPopup || isOpened) && !classNameEmpty &&
                    <div className={classTitle}>
                        {CARD_SECTION_CUSTOM_SECTION_EXISTING}
                    </div>
                }
                {isBoardSettings && !isOpened && !isEdit &&
                    <div
                        className={classFormText}
                    >{ReactHtmlParser(BOARD_SECTION_CARD_CUSTOM_PROPERTIES_TEXT)}</div>
                }
                {isBoardSettings && !isEdit
                    ? <CardCustomProperties
                        elements={elements}
                        onEdit={isDefault ? onEditHandler : null}
                    />
                    : <CustomPropertiesElements
                        elements={ elements }
                        isEdit={isEdit}
                        isHideEmpty={isHideEmpty}
                        key={editKey}
                        sectionType={ sectionType }
                        onCancel={onClickCancelHandler}
                        onChangeType={onChangeType}
                        onConfirmDelete={onConfirmDelete}
                        onEdit={onEditHandler}
                        onOptionAdd={onOptionAdd}
                        onSave={onSaveHandler}
                        onSort={onSort}
                        onToggleHideEmpty={onClickEmptyHandler}
                        onUpdateValue={onUpdateValue}
                        onTogglePin={onTogglePin}
                        onTogglePinOnCard={onTogglePinOnCard}
                    />
                }
            </>
        </SettingsSection>
    );
}
