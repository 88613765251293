import { CLASS_BOARD_DETAILS } from '../../../../constants';

import * as React from 'react';
import { IArchiveOnDeleteDescriptionProps } from './types';
import { MESSAGE_DESCRIPTION } from './constants';
import './_ArchiveOnDeleteDescription.scss';

export function ArchiveOnDeleteDescription ({
}: IArchiveOnDeleteDescriptionProps) {
    const className = CLASS_BOARD_DETAILS + '__archive_on_delete';
    const classText = CLASS_BOARD_DETAILS + '__archive_on_delete-text';

    return <div className={className}>
        <div className={classText}>
            { MESSAGE_DESCRIPTION }
        </div>
    </div>
}
