import { uploadFiles } from 'app/helper/authorisation/microsoft/selectFileFromLocalDrive/uploadFiles';
import { DriveItem } from '@microsoft/microsoft-graph-types';

export const selectFileFromLocalDrive = (
    driveId: string,
    folderId: string,
    multiple: boolean = true,
    filters: string[],
    onStart: () => void,
    onProgress: (percent: number) => void
) => {
    return new Promise<DriveItem[]>((resolve, reject) => {
        const input = document.createElement('input');
        input.type = 'file';
        if (multiple) {
            input.multiple = true;
        }
        if (filters && filters.length) {
            input.accept = filters.join(',');
        }

        input.onchange = (e: InputEvent) => {
            const target = e.target as HTMLInputElement;
            onStart();

            return uploadFiles(driveId, folderId, Array.from(target.files), onProgress)
                .then(resolve).catch(reject);
        };
        input.click();
    });
}
