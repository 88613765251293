import { IGetState } from '../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { fetchHandler } from '../../../../../util/fetchHandler';
import { Util } from '../../../../../util/util';
import { getRestHeadersDelete } from '../../../../helpers/getRestHeadersHelper';
import { IDriveDoc } from '../../../../../store/model/card/types/IDriveDoc';
import { REST_CALENDAR_EVENTS, REST_CARDS } from '../../../../constants';

export const deleteRest = (
    calendarEventId: number
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return fetchHandler<IDriveDoc[]>(
            Util.getApiUrl(`${REST_CARDS}${REST_CALENDAR_EVENTS}/${calendarEventId}`),
            {
                ...getRestHeadersDelete()
            }
        );
    };
    return action;
};
