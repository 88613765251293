import { cardsActionSetAction } from '../actions/cardsActionSetAction';
import { ICards } from '../cards/types';
import { cardsUpdateAction } from '../cards/actions/cardsUpdateAction';
import { ICardsActionSetAction } from '../actions/types';

export const cardsUpdate = (
    cards: ICards,
): ICardsActionSetAction => {
    return cardsActionSetAction(cardsUpdateAction(cards));
};
