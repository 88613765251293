import * as React from 'react';
import { IFilterInfoPanelProps } from './types';
import {
    FP_FILTER_INFO_PANEL_CLASS
} from './constants';
import { Icon, Button } from 'kui';
import './_FilterInfoPanel.scss';
import { TOPBAR_FILTER_TOOLTIP } from '../../../main/topBar/components/TopBarFilterButton/constants';

export function FilterInfoPanel ({
    isOpen,
    isRelatedCardsPanel,
    onClose,
    onOpen
}: IFilterInfoPanelProps) {
    const className = FP_FILTER_INFO_PANEL_CLASS;

    return (
        <div
            className={`
                ${className}
                ${isOpen ? className + '--opened' : ''}
                handle-click-ignore
            `}
        >
            <Button
                className={className + '-button'}
                variant={'fab'}
                color={'white'}
                tooltip={{
                    value: TOPBAR_FILTER_TOOLTIP,
                    isNoWrap: true,
                    direction: 'down'
                }}
                onClick={onOpen}
                disabled={isRelatedCardsPanel}
            >
                <Icon className={className + '-icon'} size={24} xlink={'filters'}/>
            </Button>
            {!isRelatedCardsPanel &&
                <Button
                    className={className + '-close'}
                    tooltip={{
                        direction: 'down',
                        value: 'Close filter'
                    }}
                    variant={'icon'}
                    onClick={onClose}
                >
                    <Icon xlink={'close'} size={24}/>
                </Button>
            }
        </div>
    );
};
