import { IPushNotificationSettings } from './types';

export const getPushNotificationSettingsInitial =
    (): IPushNotificationSettings => {
        const PUSH_NOTIFICATION_SETTINGS_INITIAL: IPushNotificationSettings = {
            enabled: false,
            scope: []
        }
        return PUSH_NOTIFICATION_SETTINGS_INITIAL;
    };
