import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState } from 'app/types/types';
import { msAuthManagerInstance } from 'app/helper/authorisation/microsoft/oauth/MSAuthManager';
import { drivesLoadingSet } from '../../../store/drives/actions/drivesLoadingSet';
import { INITIAL } from '../../../store/constants';
import { TTabKey } from '../../../store/types';
import { loadBoardsByTabKey } from '../../../effects/loadBoardsByTabKey';

export const givePermissionsSharedDrives = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        return new Promise((resolve, reject) => {
            const onSuccess = () => {
                dispatch(drivesLoadingSet(INITIAL));
                resolve(dispatch(loadBoardsByTabKey(TTabKey.sharedDrives)));
            };
            const onError = () => {
                reject();
            };
            const onCancel = () => {
                reject();
            };

            msAuthManagerInstance.requestSharedDrives(onSuccess, onError, onCancel);
        });
    };
    return action;
};
