import * as React from 'react';
import './_List.scss';
import { IListProps } from './types';
import { CLASS_NAME_CARD_TEMPLATES } from '../../../../asidePanel/hocs/AsidePanelCardTemplatesHOC/constants';
import { CardHOC } from '../../../../../main/kanbanView/components/Card/hocs/CardHOC/CardHOC';
import { EmptyDummy } from '../../../../../base/components/EmptyDummy/EmptyDummy';
import { Button } from 'kui';
import { CARD_RECURRING_FAQ_LINK, CARD_TEMPLATE_FAQ_LINK } from '../../../../cardTemplate/constants';
import { LEARN_MORE_RECURRING, LEARN_MORE_TEMPLATES, NO_CARDS_YET } from './constants';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';

export function List({
    cardIds,
    isRecurring,
    onCardSort
}: IListProps) {
    const className = CLASS_NAME_CARD_TEMPLATES + '__list';
    const classCard = className + '-card';

    const emptyButton = <Button
        href={isRecurring ? CARD_RECURRING_FAQ_LINK : CARD_TEMPLATE_FAQ_LINK}
        target={'_blank'}
        variant={'icon-text'}
    >
        {isRecurring ? LEARN_MORE_RECURRING : LEARN_MORE_TEMPLATES}
    </Button>;

    const onDragEnd = (result: DropResult) => {
        if (result.destination.index !== result.source.index) {
            onCardSort(Number(result.draggableId), result.destination.index);
        }
    };

    return (
        <div className={className}>
            {
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId={'card-templates-list'}>
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {
                                    cardIds.map((cardId, index) => (
                                        <Draggable
                                            draggableId={String(cardId)}
                                            index={index}
                                            key={cardId}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    className={classCard}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    ref={provided.innerRef}
                                                >
                                                    <CardHOC
                                                        key={cardId}
                                                        cardId={cardId}
                                                        hideActions={true}
                                                        isRecurring={isRecurring}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    ))
                                }
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            }
            {
                !cardIds.length &&
                <EmptyDummy
                    className={className + '__empty'}
                    buttons={emptyButton}
                    icon="announcement"
                    title={NO_CARDS_YET}
                    text={''}
                />
            }
        </div>
    );
}
