import { IApplicationState } from '../../../../../../../types/types';
import { TTabKey, TGoogleDriveId } from '../../types';
import { getDrivesWithBoardsByTabKey } from '../../drives/selectors/getDrivesWithBoardsByTabKey';
import { IBoard } from '../types';
import { getBoards } from './getBoards';
import { getDrivesIds } from '../../drives/helpers/getDrivesIds';

export const getBoardsByTabKey = (
    state: IApplicationState,
    tabKey: TTabKey,
    driveId: TGoogleDriveId = null
): IBoard[] => {
    let drivesIds: TGoogleDriveId[];
    if (!driveId) {
        const drives = getDrivesWithBoardsByTabKey(state, tabKey, false); // without Search apply for tab isEmpty
        drivesIds = getDrivesIds(drives);
    }

    const boards = getBoards(state);
    const starred = tabKey === TTabKey.starred;
    const archive = tabKey === TTabKey.archive;

    const boardsByTabKey = boards.filter((board) => {
        const drivesFilter = driveId ?
            board.driveId === driveId :
            drivesIds.includes(board.driveId);

        const starredFilter = starred ?
            board.isStarred :
            true;

        const archiveFilter = archive ?
            board.isArchived :
            !board.isArchived;

        return (
            drivesFilter &&
            starredFilter &&
            archiveFilter
        );
    });

    return boardsByTabKey;
}
