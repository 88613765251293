import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { closeTemplates } from '../../../effects/closeTemplates';
import { closeNavPanel } from '../../../../../main/navigationPanel/store/navigationPanel/effects/closeNavPanel';

export const onClose = (): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(closeNavPanel());
        dispatch(closeTemplates());
    };
    return action;
};
