import { ICardAssigneesSetAction } from './types';
import { AT_SET_ACTION } from './constants';
import { ICardAssignees } from '../types';

export const cardAssigneesSetAction = (
    cardAssignees: ICardAssignees
): ICardAssigneesSetAction => ({
    type: AT_SET_ACTION,
    cardAssignees
});
