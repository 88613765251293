import gsuiteAdminTemplate from './templates/gsuiteAdmin.html';
import store, { dispatch } from '../../../store/configureStore';
import { authUserSetGsuiteAdmin } from '../../../rest/effects/authUser/authUserSetGsuiteAdmin';
import { getAuthUser } from '../../../store/model/authUser/selectors/getAuthUser';
import * as _ from 'underscore';
import { authManagerInstance } from "app/helper/authorisation/google/AuthManager";

App.Views.GsuiteAdminDialog = App.Views.BaseView.extend({
    tagName: 'div',
    template: _.template( gsuiteAdminTemplate ),
    className: 'google-groups-sharing',

    events: {
        'click .js-show-permissions': 'onShowPermissions',
        'click .js-copy': 'onCopy'
    },

    onShowPermissions: function(e) {
        e.stopPropagation();
        authManagerInstance.checkAndRequestGsuite(() => {
            dispatch(authUserSetGsuiteAdmin(true))
                .then(() => {
                    const user = getAuthUser(store.getState());
                    const page = user.gsuiteAdmin ? 'done' : 'sorry';
                    this.showPage(page);
                })
        });
    },

    showPage: function(options) {
        var page = options || 'default';
        this.$el.find('.animation_page_show:not(.google-groups-sharing__page--' + page + ')').removeClass('animation_page_show').addClass('animation_page_hide');
        this.$el.find('.animation_page_hide.google-groups-sharing__page--' + page).removeClass('animation_page_hide').addClass('animation_page_show');
    },

    onCopy: function(e) {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(window.location.href).then(function() {
                $(e.target).closest('.js-copy').html(Messages.getText('google-groups-sharing.copied-link'));
            });
        }
    },

    getContent: function() {
        return {
            title: Messages.getText('google-groups-sharing.title'),
            text: Messages.getText('google-groups-sharing.bait'),
            button: Messages.getText('google-groups-sharing.button-enable'),
            text2: Messages.getText('google-groups-sharing.for-enterprise'),
            copy: Messages.getText('google-groups-sharing.copy-link'),
            canCopy: Boolean(navigator.clipboard),
            titleSorry: Messages.getText('google-groups-sharing.title-sorry'),
            textSorry: Messages.getText('google-groups-sharing.text-sorry'),
            titleDone: Messages.getText('google-groups-sharing.title-done'),
            textDone: Messages.getText('google-groups-sharing.text-done')
        };
    },

    render: function() {
        this.$el.html( this.template( this.getContent() ) );
        return this;
    }
});
