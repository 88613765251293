import * as React from 'react';
import { Button, Input } from 'kui';
import './_CardTagEdit.scss';
import { CARD_TAG_SAVE } from './constants';
import { ICardTagEditProps } from './types';
import { CLASS_CARD_DETAILS } from '../../../constants';
import { getParentsClasses } from '../../../../../helpers/getParentsClasses';

export function CardTagEdit({
    tag,
    onSave,
    onClose
}: ICardTagEditProps) {
    const ref = React.useRef(null);
    const [name, setName] = React.useState(tag.name);
    const className = CLASS_CARD_DETAILS + '__tag-edit';
    const classBtnHidden = className + '-btn-hidden';

    const onSaveHandler = () => {
        if (name && name.trim()) {
            onSave(tag.id, name);
            onClose();
        }
    }

    const onNameChange = (
        event: React.FormEvent
    ) => {
        const target = event.target as HTMLInputElement;
        setName(target.value);
    }

    const onKeyDown = (
        e: React.KeyboardEvent<HTMLInputElement>
    ) => {
        if (e) {
            if (e.which === 13) {
                onSaveHandler();
            }
            if (e.which === 27) {
                onClose();
                e.stopPropagation();//to avoid aside-panel closure on esc if tag-input is in focus
            }
        }
    };

    function onBlurHandler (event: React.FocusEvent) {
        const targetClasses = getParentsClasses(
            event.relatedTarget as HTMLElement,
            [className]
        );
        if (targetClasses.includes(className)) return;

        onClose();
    }

    React.useEffect(() => {
        ref.current.querySelector('input').setSelectionRange(0, name.length);
    }, [])

    const showBtn = !!name.trim() && tag.name !== name;
    return (
        <div className={className} ref={ref}>
            <Input
                onBlur={onBlurHandler}
                onKeyDown={onKeyDown}
                autoFocus={true}
                onChange={onNameChange}
                value={name}
                autosize={false}
            />
            <Button
                onClick={onSaveHandler}
                className={showBtn ? '' : classBtnHidden}
            >
                {CARD_TAG_SAVE}
            </Button>
        </div>
    );
};
