import { IApplicationState, IGetState, TCardId } from '../../../types/types';
import { Dispatch, ThunkAction } from '../../../types/actions';
import {
    SegmentCardEvent,
    SegmentCardOptions,
    SegmentCardSection,
    segmentTrackAction
} from '../../../middlewares/segment';
import { isBoardCardsLimitReached } from '../../../store/model/selectors/isBoardCardsLimitReached';
import { getBoardIdByCardId } from '../../../store/model/selectors/getBoardIdByCardId';
import { showBoardLimitSnackbarAndEvent } from '../../../store/model/effects/showBoardLimitSnackbarAndEvent';
import { SegmentUserTypeOption } from '../../../middlewares/segment/trackEntities/userEvents';
import { IRestCard } from '../../../types/rest/IRestCard';
import { SegmentCardSourceValue } from '../../../middlewares/segment/trackEntities/cardEvents';
import { cardOpen } from '../base/effects/cardOpen';
import { ICard } from '../../../store/model/card/types';
import { getCardSubcardsTree } from '../../../store/model/selectors/getCardSubcardsTree';
import { dispatch } from '../../../store/configureStore';
import { snackbarPromtYesNo } from '../snackbarsStack/effects/variantPromt/snackbarPromtYesNo';
import { getMessages } from '../../../store/constants';
import { cardsCopy } from '../../../rest/effects/card/copy/cardsCopy';
import { cardCopy } from '../../../rest/effects/card/copy/cardCopy';
import { getCard } from '../../../store/model/selectors/getCard';
import { getNextCardOrderNumber } from '../../../store/model/list/selectors/getNextCardOrderNumber';

export const cardCopyWithSubcardsPrompt = (
    cardId: TCardId,
    source: SegmentCardSourceValue
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        dispatch(segmentTrackAction(SegmentCardEvent.BUTTONS_PANEL_BUTTON_CLICKED, {
            name: SegmentCardOptions.TARGET,
            value: SegmentCardSection.COPY
        }));
        getCardsToCopy(state, cardId).then((cardToCopyIds) => {
            if (isBoardCardsLimitReached(state, getBoardIdByCardId(state, cardId), cardToCopyIds.length)){
                dispatch(showBoardLimitSnackbarAndEvent(SegmentUserTypeOption.CARD_CREATION));
            } else {
                const copySubcards: boolean = cardToCopyIds.length > 1;
                runCopy(state, dispatch, cardId, copySubcards).then((copyCardId) => {
                    dispatch(segmentTrackAction(SegmentCardEvent.COPY, {
                        name: SegmentCardOptions.SOURCE,
                        value: source
                    }));
                    if (copyCardId) {
                        dispatch(cardOpen(copyCardId));
                    }
                })
            }
        })
    };
    return action;
}

const runCopy = (
    state: IApplicationState,
    dispatch: Dispatch,
    cardId: TCardId,
    copySubcards: boolean
): Promise<TCardId> => {
    if (copySubcards) {
        return dispatch(cardsCopy(cardId, true, {
            setAuthUserToCardAuthor: true
        })).then((copyCardId: TCardId) => {
            return copyCardId;
        });
    } else {
        const card = getCard(state, cardId);
        return dispatch(cardCopy(cardId, true, {
            setAuthUserToCardAuthor: true,
            orderNumber: getNextCardOrderNumber(state, card.listId, false)
        })).then((cardResponse: IRestCard) => {
            return cardResponse ? cardResponse.id : 0;
        })
    }
}

const getCardsToCopy = (
    state: IApplicationState,
    cardId: TCardId
): Promise<TCardId[]> => {
    const subCards: ICard[] = getCardSubcardsTree(state, cardId);
    if (!subCards || subCards.length < 1)
        return Promise.resolve([cardId]);
    const subCardIds: TCardId[] = subCards.map((card) => card.id);
    return new Promise<TCardId[]>((resolve, reject) => {
        dispatch(snackbarPromtYesNo({
            id: 'SNACKBAR_COPY_SUBCARDS',
            text: getMessages().getText('snackbar.copy.with_subcards.text'),
            actionApply: () => {
                dispatch(segmentTrackAction(SegmentCardEvent.COPY_WITH_SUBCARDS_YES));
                resolve([...[cardId], ...subCardIds]);
            },
            actionCancel: () => {
                dispatch(segmentTrackAction(SegmentCardEvent.COPY_WITH_SUBCARDS_NO));
                resolve([cardId]);
            }
        }));
    });

}
