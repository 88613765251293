import { connect } from 'react-redux';
import { AutoBackups } from '../../components/AutoBackups/AutoBackups';
import { IAutoBackupsEvents, IAutoBackupsFields } from '../../components/AutoBackups/types';
import { onTimeChange } from './events/onTimeChange';
import { IAutoBackupsHOCProps } from './types';
import { IApplicationState } from '../../../../../../../../types/types';
import { onDidMount } from './events/onDidMount';
import {
    getAutoBackupsByBoardIdAndExportType
} from '../../store/autoBackup/selectors/getAutoBackupsByBoardIdAndExportType';
import { isAutobackupsLoading } from '../../store/autoBackups/selectors/isAutobackupsLoading';
import { getSectionType } from '../../../../selectors/getSectionType';
import {
    getShowProFeaturesTrial
} from '../../../../../../../../store/model/authUser/selectors/getShowProFeaturesTrial';
import { EBackupExportType } from '../../../../../../../../types/rest/IRestBackup';
import { getBoard } from 'app/store/model/selectors/getBoardById';

const mapStateToProps = (
    state: IApplicationState,
    ownProps: IAutoBackupsHOCProps
): IAutoBackupsFields => {
    const { boardId } = ownProps;
    const isLoading = isAutobackupsLoading(state, boardId);
    const autoBackup = getAutoBackupsByBoardIdAndExportType(state, boardId, EBackupExportType.XML);
    const time = autoBackup && autoBackup.autoBackupSchedule && autoBackup.autoBackupSchedule.startDate;
    const sectionType = getSectionType(state, boardId, null, 'allowAutoBackupXml', 'allowCreateBackUp');

    return {
        isEnabled: autoBackup.enabled,
        isLoading,
        isProFeature: getShowProFeaturesTrial(state),
        sectionType,
        time,
        timezone: getBoard(state, boardId) && getBoard(state, boardId).timeZone
    }
};

const mapDispatchToProps = (
    dispatch: any,
    ownProps: IAutoBackupsHOCProps
): IAutoBackupsEvents => {
    const {boardId} = ownProps;
    return {
        onTimeChange: (time: number) => dispatch(onTimeChange(boardId, time)),
        onDidMount: () => dispatch(onDidMount(boardId)),
    }
};

export const AutoBackupsHOC = connect(mapStateToProps, mapDispatchToProps)(AutoBackups);
