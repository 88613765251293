import { connect } from 'react-redux';
import { PushToGoogleCalendar } from '../../components/PushToGoogleCalendar/PushToGoogleCalendar';
import { IPushToGoogleCalendarEvents, IPushToGoogleCalendarFields } from '../../components/PushToGoogleCalendar/types';
import { onOpen } from './events/onOpen';
import { onDidMount } from './events/onDidMount';
import { IPushToGoogleCalendarHOCProps } from './types';
import { getCardCalendarEvents } from 'app/store/model/selectors/getCardCalendarEvents';
import { getAppState } from 'app/store/configureStore';
import { IApplicationState } from 'app/types/types';
import { getSectionType } from '../../../selectors/getSectionType';
import { getBoardIdByCardId } from '../../../../../../../store/model/selectors/getBoardIdByCardId';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';

const mapStateToProps = (
    state: IApplicationState,
    ownProps: IPushToGoogleCalendarHOCProps
): IPushToGoogleCalendarFields => {
    const {cardId } = ownProps;
    const calendarEvents = getCardCalendarEvents(getAppState(), cardId);
    const sectionType = getSectionType(state, getBoardIdByCardId(state, cardId), cardId);
    return {
        isDatePushed: calendarEvents.length > 0,
        isReadonly: sectionType === ESettingsSectionType.READONLY,
    }
};
const mapDispatchToProps = (
    dispatch: any,
    {cardId}: IPushToGoogleCalendarHOCProps
): IPushToGoogleCalendarEvents => {
    return {
        onOpen: () => dispatch(onOpen()),
        onDidMount: () => dispatch(onDidMount(cardId))
    }
};

export const PushToGoogleCalendarHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(PushToGoogleCalendar);

PushToGoogleCalendarHOC.displayName = 'PushToGoogleCalendarHOC';
