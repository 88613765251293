import * as React from 'react';
import { Button, ButtonDropdown, Icon, Tooltip } from 'kui';
import { ITypeSwitcherProps } from './types';
import './_TypeSwitcher.scss';
import { CLASS_TYPE_SWITCHER, LABEL_KANBAN, VIEW_TYPES } from '../../constants';
import { TypeSwitcherDropUpHOC } from '../../hocs/TypeSwitcherDropUpHOC/TypeSwitcherDropUpHOC';
import { TypeSwitcherItem } from '../TypeSwitcherItem/TypeSwitcherItem';
import { EViewTypes } from 'app/const';
import { isExpandedSwitcher } from '../../selectors/isExpandedSwitcher';
import { root } from 'app/store/constants';
import { isKanbanGroup } from '../../selectors/isKanbanGroup';
import { isSwitcherCollapsedByAside } from '../../selectors/isSwitcherCollapsedByAside';
import { isSwitcherMinimizedByAside } from '../../selectors/isSwitcherMinimizedByAside';
import { EHintKey, HintHOC } from '../../../hints';
import { CLASS_GANTT_CONTEXT_HINT } from '../../../hints/components/Hint/constants';
import { TYPE_SWITCHER_GANTT_CONTEXT_HINT, TYPE_SWITCHER_GANTT_CONTEXT_HINT_LARGE } from './constants';

export function TypeSwitcher ({
    active,
    lastKanbanView,
    isAsidePanelOpened,
    isBasicFeature,
    isFilterInfoPanel,
    isShowHint,
    isProFeature,
    onHideHint,
    onTypeSelected,
}: ITypeSwitcherProps) {
    let className = CLASS_TYPE_SWITCHER;
    const [isExpanded, setExpanded] = React.useState(true);
    const [isMinimized, setMinimized] = React.useState(false);
    let [isFilter, _setFilter] = React.useState(false);

    const setFilter = (value: boolean) => {
        isFilter = value;
        _setFilter(value);
    }

    const icon = isExpanded ? VIEW_TYPES[lastKanbanView].icon : VIEW_TYPES[active].icon;
    const text = isExpanded || active === EViewTypes.KANBAN_VIEW_TYPE
        ? LABEL_KANBAN
        : VIEW_TYPES[active].name;
    const isActiveKanbanGroup = isKanbanGroup(active);

    React.useEffect(() => {
        const onResizeWindow = () => {
            setExpanded(
                !isBasicFeature &&
                (!isAsidePanelOpened || !isSwitcherCollapsedByAside()) &&
                isExpandedSwitcher()
            );

            setMinimized(isAsidePanelOpened && isSwitcherMinimizedByAside());

            /**
             * если ширина сёрча минимальная, включаем режим isFilter, который сжимает свитчер
             * включается режим isFilter только при выключении фильтра, чтоб не скакало
             */
            if (!isAsidePanelOpened) {
                setTimeout(() => {
                    if (!isFilterInfoPanel) {
                        setFilter(false);
                    } else if (!isFilter) {
                        const search = document.querySelector('.top-bar__filter') as HTMLElement;
                        if (search) {
                            setFilter(search.clientWidth <= 200); // минимальная ширина в css
                        }
                    }
                }, 0);
            }
        };

        onResizeWindow();
        root.addEventListener('resize', onResizeWindow);

        return () => {
            root.removeEventListener('resize', onResizeWindow);
        }
    }, [isAsidePanelOpened, isFilterInfoPanel]);

    return (
        <div
            className={`
                ${className}
                ${isExpanded ? className + '--expanded' : ''}
                ${isMinimized ? className + '--minimized' : ''}
                ${isFilter ? className + '--filter' : ''}
                ${isShowHint && !isExpanded ? className + '--highlighted' : ''}
                handle-click-ignore
            `}
        >
            <Tooltip
                className={`${className}__tooltip`}
                direction={'down'}
                value={isExpanded ? text : null}
            >
                <div className={`${className}__buttondropdown`}>
                    {isExpanded && !isActiveKanbanGroup && // если развёрнуто и активный вид не из группы Канбан, то клик сразу переключает, а дропдаун только на стрелке
                        <div
                            className={`${className}__force`}
                            onClick={()=>onTypeSelected(lastKanbanView)}
                        />
                    }
                    <ButtonDropdown
                        dropdownClassName={`${className}__dd`}
                        onOpen={() => {
                            setTimeout(() => {
                                const button = document.querySelector(`.${className}__button`) as HTMLElement;
                                if (button) button.focus();
                            }, 100);
                        }}
                        portal
                        beforeOpen={isShowHint ? () => {
                            return onHideHint();
                        } : null}
                        portalId={'topbar-portal'}
                    >
                        <Button
                            className={`
                                ${className}__button
                                ${isExpanded && isActiveKanbanGroup ? className + '__button--active' : ''}
                            `}
                            text={text}
                            variant={'icon-text'}
                        >
                            <Icon className={`${className}__icon`} xlink={icon} size={24}/>
                            <Icon
                                className={`${className}__arrow`}
                                size={24}
                                xlink={'arrow-down'}
                            />
                            {isExpanded && isActiveKanbanGroup &&
                                <div className={`${className}__active`} />
                            }
                        </Button>
                        <TypeSwitcherDropUpHOC
                            active={active}
                            isExpanded={isExpanded}
                            onTypeSelected={onTypeSelected}
                        />
                    </ButtonDropdown>
                    {!isExpanded &&
                        <HintHOC
                            className={CLASS_GANTT_CONTEXT_HINT}
                            key={EHintKey.GANTT_CONTEXT_GANTT_TYPE_SWITCHER}
                            hintKey={EHintKey.GANTT_CONTEXT_GANTT_TYPE_SWITCHER}
                            isHideOnHintClick
                            isHidable={false}
                            show
                            direction={'down'}
                            arrow={'up'}
                            value={TYPE_SWITCHER_GANTT_CONTEXT_HINT}
                            selector={`.${className}__buttondropdown`}
                        />
                    }
                </div>
            </Tooltip>
            <TypeSwitcherItem
                className={`${className}__item--gantt ${isExpanded && isShowHint ? className + '__item--highlighted' : ''}`}
                isActive={active === EViewTypes.GANTT_VIEW_TYPE}
                type={EViewTypes.GANTT_VIEW_TYPE}
                onTypeSelected={onTypeSelected}
                isProFeature={isProFeature}
            />
            {isExpanded &&
                <HintHOC
                    className={CLASS_GANTT_CONTEXT_HINT}
                    key={EHintKey.GANTT_CONTEXT_GANTT_TYPE_SWITCHER}
                    hintKey={EHintKey.GANTT_CONTEXT_GANTT_TYPE_SWITCHER}
                    isHideOnHintClick
                    isHidable={false}
                    show
                    direction={'down'}
                    arrow={'up'}
                    value={TYPE_SWITCHER_GANTT_CONTEXT_HINT_LARGE}
                    selector={`.${className}__item--gantt`}
                />
            }
            <TypeSwitcherItem
                isActive={active === EViewTypes.LIST_VIEW_TYPE}
                type={EViewTypes.LIST_VIEW_TYPE}
                onTypeSelected={onTypeSelected}
            />
            <TypeSwitcherItem
                isActive={active === EViewTypes.ARCHIVE_VIEW_TYPE}
                type={EViewTypes.ARCHIVE_VIEW_TYPE}
                onTypeSelected={onTypeSelected}
                isProFeature={isProFeature}
            />
        </div>
    );
};
