import { root } from 'app/store/constants';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TBoardId } from '../../../../../../../../types/types';
import { SegmentCardEvent, segmentTrackAction } from 'app/middlewares/segment';

export const onOpenAttachmentsTab = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.ATTACHMENT_TAB_CLICKED));
        root.App.router.navigate(root.App.router.getBoardTabUrl(boardId, 'attachments'), {trigger: true});
    };
    return action;
};
