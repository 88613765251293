import {NP_LOCAL_STORAGE_FIELD} from '../constants';
import { INavigationPanelState } from '../store/navigationPanel/types';

export const getLocalStorageNavigationPanel = (): INavigationPanelState => {
    if (localStorage) {
        const initialStateJson = localStorage.getItem(NP_LOCAL_STORAGE_FIELD);
        if (initialStateJson) {
            let decoded = atob(initialStateJson);
            const parsedStore = JSON.parse(decoded);
            if (parsedStore.panelStatus) return null; // на 100-dot panelStatus вместо panelType
            return  {
                ...parsedStore,
                isOpen: false
            }
        }
    }
};
