import { ICards } from '../../model/cards/types';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { IGetState } from '../../../types/types';
import { cardsRequestsStateSetAction } from '../actions/cardsRequestsStateSetAction';
import { getCardActiveRequestsWithDelta } from '../selectors/getCardActiveRequestsWithDelta';
import { ICardsRequestsState } from '../reducers/types';

export const cardsActiveRequestsUpdate = (
    cards: ICards,
    activeRequestsDelta: number
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {

        const cardsRequestsState: ICardsRequestsState = {};

        for (let cardId in cards) {
            const activeRequests = getCardActiveRequestsWithDelta(getState(), Number(cardId), activeRequestsDelta);
            cardsRequestsState[cardId] = {
                activeRequests
            };
        }
        dispatch(cardsRequestsStateSetAction(cardsRequestsState));
    };
    return action;
};
