import { getMessages } from '../../../../../../store/constants';

export const CARD_SECTION_ASSIGNEES = getMessages().getText('card_form_section_assignees');
export const ASSIGNEE_REMOVE_BUTTON_TOOLTIP = getMessages().getText('card_form_assignee_remove');
export const ASSIGNEE_ADD_ROLE_BUTTON_TOOLTIP = getMessages().getText('card_form_assignee_add_role');
export const ASSIGNEE_EDIT_ROLES = getMessages().getText('card_form_assignee_edit_roles');
export const CLASS_CARD_ASSIGNEES = 'card-details-assignees';
export const ASSIGNEE_GROUP_TITLE = 'assignee-title';
export const ASSIGNEE_GROUP_DIVIDER = 'assignee-divider';
export const TITLE_ASSIGNED_ON_BOARD = getMessages().getText('card_form_assigned_on_board');
export const TITLE_ASSIGNED_OTHER = getMessages().getText('card_form_assigned_other');
export const TITLE_ASSIGNED_ON_CARD = getMessages().getText('card_form_assigned_on_card');
export const ASSIGNEE_GROUP_ID = '-1';
