import { IGetState, TCardId } from 'app/types/types';
import { Dispatch, PromiseAction, ThunkAction } from 'app/types/actions';
import { getCard } from 'app/store/model/selectors/getCard';
import { TStatus } from 'app/types/model';
import { deleteAllDependencies } from '../dependence/deleteAllDependencies';
import { cardDeleteEpic, cardDeleteEpicBatch } from './cardDeleteEpic';
import { ICard } from 'app/store/model/card/types';
import { deleteCardRelations } from '../relatedCards/deleteCardRelations';
import { ICardBatch } from './api/types';
import { cardPatchRestBatch } from './api/helper/cardPatchRestBatch';
import { SegmentCardEvent, segmentTrackAction } from 'app/middlewares/segment';

export const cardDelete = (
    cardId: TCardId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const card = getCard(state, cardId);
        if (!card) return Promise.reject();

        let cardBatch: ICardBatch = {
            card: {
                status: TStatus.STATUS_DELETED
            }, callbacks: []
        };
        return dispatch(deleteAllDependencies(cardId))
            .then(() => {
                return dispatch(deleteCardRelations(cardId));
            })
            .then(() => {
                return updateSubcards(card, dispatch);
            })
            .then(() => {
                cardBatch = cardDeleteEpicBatch(getState, dispatch, cardId, cardBatch);
                return cardPatchRestBatch(cardId, dispatch, cardBatch);
            })
            .then(() => {
                dispatch(segmentTrackAction(SegmentCardEvent.CARD_DELETED));
            })
            .catch((e: any) => {
                console.error(e);
            })
    };
    return action;
};

const updateSubcards = (
    card: ICard,
    dispatch: Dispatch,
) => {
    const subCardIds = card.subCardIds || [];
    const deleteEpicPromises: PromiseAction<any>[] = [];
    subCardIds.forEach((id) => {
        deleteEpicPromises.push(dispatch(cardDeleteEpic(id, false)));
    });
    return Promise.all(deleteEpicPromises);
};
