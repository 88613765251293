import { IApplicationState } from '../../../../types/types';
import { ISnackbarModel, SnackbarId } from '../store/types';

export const snackbarSelector = (
    state: IApplicationState,
    snackbarId: SnackbarId
): ISnackbarModel => {
    const snackbar = state.snackbarsStack.snackbars.filter(item => {
        return item.id === snackbarId;
    });
    return snackbar[0];
}
