import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState } from '../../../../types/types';
import { fetchHandler } from '../../../../util/fetchHandler';
import { REST_ACTIVITY } from '../../../constants';
import { getRestHeadersGet } from '../../../helpers/getRestHeadersHelper';
import { Util } from '../../../../util/util';
import { IRestActivities } from '../../../../types/rest/activity/IRestActivities';

export type TGetRestActivityOptions = {
    [key: string]: string | number
}

export const getRest = (
    objectId: number,
    url: string,
    cursor: string,
    options: TGetRestActivityOptions
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const params: TGetRestActivityOptions = {
            ...(options || {}),
            cursor: cursor || ''
        }
        const urlParams = Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
        return fetchHandler<IRestActivities>(
            Util.getApiUrl(
                url + objectId + REST_ACTIVITY) + (urlParams ? '?' + urlParams : ''),
                getRestHeadersGet()
        )
    };
    return action;
};
