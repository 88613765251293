import * as React from 'react';
import { IRestCardCommonActivity } from '../../../../../../../types/rest/activity/IRestCardCommonActivity';
import { IRestCardActivityBase } from '../../../../../../../types/rest/activity/IRestCardActivityBase';
import { IActivityProps } from '../../../../boardDetails/activity/TabActivity/types';
import { TabActivityContext } from '../../../../boardDetails/activity/TabActivity/components/TabActivity/constants';
import { TActivity } from '../../../../../../../store/activities/types';
import {
    ActivityItemName
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemName/ActivityItemName';
import {
    ActivityItemBody
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemBody/ActivityItemBody';
import { ActivityHeader } from '../../../../boardDetails/activity/TabActivity/components/ActivityHeader/ActivityHeader';
import { Icon } from 'kui';
import { CLASS_ACTIVITY_ITEM } from '../../../../boardDetails/activity/TabActivity/components/constants';
import './_CardActivityName.scss';
import { blockMarkdown } from '../../../../../../../helper/markdownHelper';
import { msFileThumbnailStorage } from '../../../../../../../helper/authorisation/microsoft/storage/msThumbnail';
import { v4 as uuidv4 } from 'uuid';

export function CardActivityName ({
    activity
}: IActivityProps<IRestCardCommonActivity>) {
    const className = CLASS_ACTIVITY_ITEM + '--name';
    const [classUnique] = React.useState(className + '--' + uuidv4());

    const { onOpenCard } = React.useContext(TabActivityContext);
    const oldCardActivity: IRestCardActivityBase = {
        ...activity,
        card: {
            ...activity.card,
            name: activity.oldValue
        }
    };
    const { label, card } = activity as TActivity;

    const [newNameMarkdown, setNewNameMarkdown] = React.useState('');
    const [oldNameMarkdown, setOldNameMarkdown] = React.useState('');

    React.useEffect(() => {
        let newName = activity.newValue;
        let oldName = activity.oldValue;

        setNewNameMarkdown(blockMarkdown(newName));
        setOldNameMarkdown(blockMarkdown(oldName));

        if (msFileThumbnailStorage.is()) {
            if (newName) {
                msFileThumbnailStorage.getSrcThumbnails(newName)
                    .then((text) => {
                        setNewNameMarkdown(blockMarkdown(text));
                    })
                    .catch(() => {});
            }
            if (oldName) {
                msFileThumbnailStorage.getSrcThumbnails(oldName)
                    .then((text) => {
                        setOldNameMarkdown(blockMarkdown(text));
                    })
                    .catch(() => {});
            }
        }
    }, [activity]);

    return (
        <ActivityItemBody
            className={`${className} ${classUnique}`}
            header={<ActivityHeader icon={'rename'} label={label} />}
            activity={oldCardActivity}
            onClick={() => onOpenCard(card.id, activity.id)}
        >
            <ActivityItemName name={oldNameMarkdown} isRemove isMarkdown tooltip={activity.oldValue} />
            <Icon xlink={'forward'} size={24} />
            <ActivityItemName name={newNameMarkdown} isMarkdown tooltip={activity.newValue} />
        </ActivityItemBody>
    );
}
