import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { SnackbarId } from '../../store/types';
import { deleteSnackbar } from '../deleteSnackbar/deleteSnackbar';
import { suspendSnackbarsStack } from '../suspendSnackbarsStack/suspendSnackbarsStack';
import { customAction as customActionType, ECustomActionType } from '../types';

export const getDefaultActionEvent: ActionCreator<ThunkAction> = (
    snackbarId: SnackbarId,
    customAction: customActionType = null,
    actionType?: ECustomActionType
) => {
    const action = (dispatch: Dispatch) => {
        dispatch(suspendSnackbarsStack())
        .then(() => dispatch(deleteSnackbar(snackbarId)));
        if (customAction) {
            customAction(actionType);
        }
    };
    return action;
};
