import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../../types/types';
import { getBoardsSearchSelectOptions } from './selectors/getBoardsSearchSelectOptions';
import { onSelect } from './events/onSelect';
import { MyWorkBoardsSearchSelect } from '../../components/MyWorkBoardsSearchSelect/MyWorkBoardsSearchSelect';
import { IMyWorkBoardsSearchSelectEvents, IMyWorkBoardsSearchSelectFields } from '../../components/MyWorkBoardsSearchSelect/types';
import { onResetCounter } from './events/onResetCounter';
import { getBoardsUpdateCount } from './selectors/getBoardsUpdateCount';

const mapStateToProps = (
    state: IApplicationState,
): IMyWorkBoardsSearchSelectFields => {
    return {
        availableElements: getBoardsSearchSelectOptions(state),
        updatesCount: getBoardsUpdateCount(state)
    }
};

const mapDispatchToProps = (
    dispatch: any,
): IMyWorkBoardsSearchSelectEvents => {
    return {
        onResetCounter: () => dispatch(onResetCounter()),
        onSelect: (value) => dispatch(onSelect(value))
    }
};

export const MyWorkBoardsSearchSelectHOC = connect(
    mapStateToProps,
    mapDispatchToProps,
)(MyWorkBoardsSearchSelect);

MyWorkBoardsSearchSelectHOC.displayName = 'MyWorkBoardsSearchSelectHOC';
