import * as React from 'react';
import { IRestCardCommonActivity } from '../../../../../../../types/rest/activity/IRestCardCommonActivity';
import { IActivityProps } from '../../../../boardDetails/activity/TabActivity/types';
import { TActivity } from '../../../../../../../store/activities/types';
import {
    ActivityItemBody
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemBody/ActivityItemBody';
import { ActivityHeader } from '../../../../boardDetails/activity/TabActivity/components/ActivityHeader/ActivityHeader';

export function CardActivityRestore ({
    activity
}: IActivityProps<IRestCardCommonActivity>) {
    const { label } = activity as TActivity;
    return (
        <ActivityItemBody
            header={<ActivityHeader icon={'archive'} label={label} />}
            activity={activity}
        >
        </ActivityItemBody>
    );
}
