import { ThunkAction } from '../../../../../../../types/actions';
import { root } from '../../../../../../../store/constants';
import { dispatch } from '../../../../../../../store/configureStore';
import { SegmentPricingEvent, segmentTrackAction } from '../../../../../../../middlewares/segment';

export const onClickPlanUpgrade = (
): ThunkAction => () => {
    dispatch(segmentTrackAction(SegmentPricingEvent.UPGRADE_BUTTON));
    root.App.controller.showPayment();
};
