import { escape } from 'underscore';
import { markdown } from '../../../helper/markdownHelper';
import { clearMarkdownInline } from '../base/helpers/clearMarkdownHelper';
import { NAVIGATION_PANEL_BOARD_SHORT_NAME } from '../main/navigationPanel/constants';
import { stripHtml } from '../base/helpers/stripHtmlHelper';

export const getShortName = (
    name: string
): string => {
    if (!name) return name;

    const shortName: string = name.length > NAVIGATION_PANEL_BOARD_SHORT_NAME * 1.5
        ? escape(clearMarkdownInline(name).slice(0, NAVIGATION_PANEL_BOARD_SHORT_NAME)) + '...'
        : markdown(escape(name));
    return shortName;
}

export const getShortNameFromHtml = (
    html: string
): string => {
    if (!html) return html;

    if (html.length <= NAVIGATION_PANEL_BOARD_SHORT_NAME * 1.5) return html;

    let short = stripHtml(html).trim();
    return escape(short.slice(0, NAVIGATION_PANEL_BOARD_SHORT_NAME)) + '...';
}
