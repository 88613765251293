import { Dispatch, ThunkAction } from 'app/types/actions';
import { IGetState } from 'app/types/types';
import { getAuthUser } from 'app/store/model/authUser/selectors/getAuthUser';
import { EAuthUserAccountType } from 'app/types/rest/IRestAuthUser';
import {
    NAV_PANEL_QUICK_START_GUIDE_EDU_SUPPORT_URL,
    NAV_PANEL_QUICK_START_GUIDE_ENGLISH_SUPPORT_COUNTRIES, NAV_PANEL_QUICK_START_GUIDE_ENGLISH_SUPPORT_URL,
    NAV_PANEL_QUICK_START_GUIDE_SPANISH_SUPPORT_COUNTRIES,
    NAV_PANEL_QUICK_START_GUIDE_SPANISH_SUPPORT_URL,
    NAV_PANEL_QUICK_START_GUIDE_SUPPORT_URL
} from '../../../components/NavigationPanelQuickStartGuide/constants';
import { Util } from 'app/util/util';
import { segmentTrackAction, SegmentUserEvent } from 'app/middlewares/segment';

export const onBook = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const authUser = getAuthUser(state);
        let url = NAV_PANEL_QUICK_START_GUIDE_SUPPORT_URL;
        if (authUser.accountType === EAuthUserAccountType.EDU_ACCOUNT_TYPE) {
            url = NAV_PANEL_QUICK_START_GUIDE_EDU_SUPPORT_URL;
        } else if (NAV_PANEL_QUICK_START_GUIDE_SPANISH_SUPPORT_COUNTRIES.includes(authUser.country)) {
            url = NAV_PANEL_QUICK_START_GUIDE_SPANISH_SUPPORT_URL;
        } else if (NAV_PANEL_QUICK_START_GUIDE_ENGLISH_SUPPORT_COUNTRIES.includes(authUser.country)) {
            url = NAV_PANEL_QUICK_START_GUIDE_ENGLISH_SUPPORT_URL;
        }
        const date = new Date();
        const month = date.getMonth() + 1;
        const dateParam = date.getFullYear() + '-' + (month < 10 ? '0' : '') + month;
        url = url.replace('{date}', dateParam);
        Util.popupWindow(url, 1000, 750);
        dispatch(segmentTrackAction(SegmentUserEvent.QUICK_START_CALL_BOOKED));
    };
    return action;
};
