import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { isOpenSetAction } from '../../../store/navigationPanel/actions/isOpenSetAction';
import { closeClickedSegmentEvent } from '../../../effects/segmentEvents';

export const onCloseClicked = (): ThunkAction => {
    return (dispatch: Dispatch) => {
        dispatch(isOpenSetAction(false));
        dispatch(closeClickedSegmentEvent());
    };
};
