import {Dispatch, ThunkAction} from '../../../../types/actions';
import {postRestCheckDuplicates} from '../api/postRestCheckDuplicates';

export const deleteDuplicates = (): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        // random from 1 sec to 5sec with step 0,1 sec
        const FROM = 10;
        const TO = 51;
        let randomTimeMillisecond = Math.floor(Math.random() * TO) + FROM;
        randomTimeMillisecond = randomTimeMillisecond * 100;
        setTimeout(() => {
            dispatch(postRestCheckDuplicates())
        }, randomTimeMillisecond);

    };
    return action;
};
