import {
    EQualificationAnswersType,
    EQualificationAnswerType,
    TQualificationQuestions
} from '../components/QualificationDialog/types';
import { SegmentQuestionEvent } from '../../../../../middlewares/segment/trackEntities/questionEvents';
import { IUserMetaQualificationAnswer } from '../../../../../types/rest/IUserMeta';
import {
    questionHandHoldingEventCallback,
    questionKeyFactorEventCallback,
    toolsCompareEventCallback
} from '../helpers/questionEventCallbacks';

export const QUALIFICATION_QUESTIONS: TQualificationQuestions = {
    work_tool: { // Q1
        question: 'Are you searching for a work tool?',
        answers: {
            work_tool_yes: {
                answer: 'Yes, I need the app for work',
                nextQuestion: 'organization',
                eventValue: 'Work'
            },
            work_tool_edu: {
                answer: 'I need the app for education',
                nextQuestion: 'edu_choosing_project',
                eventValue: 'Edu'
            },
            work_tool_no: {
                answer: 'No, I need the app for my personal needs',
                nextQuestion: 'personal_plan_to_use',
                eventValue: 'Personal'
            }
        },
        event: SegmentQuestionEvent.WORK_APP_SEARCH
    },
    organization: {
        question: 'What is your organization?',
        answers: {
            organization_business: {
                answer: 'Business',
                nextQuestion: 'choosing_project',
                eventValue: 'Business'
            },
            organization_edu: {
                answer: 'Educational organization',
                nextQuestion: 'edu_choosing_project',
                eventValue: 'Edu',
            },
            organization_no_profit: {
                answer: 'Non-profit organization',
                nextQuestion: 'choosing_project',
                eventValue: 'Non-profit'
            },
            organization_other: {
                answer: 'Other',
                type: EQualificationAnswerType.FREE_TEXT,
                nextQuestion: 'choosing_project',
                eventValueCallback: (answer: IUserMetaQualificationAnswer) => `Other (${answer.text})`
            }
        },
        event: SegmentQuestionEvent.ORG_TYPE
    },
    choosing_project: {
        question: 'Are you choosing a project management software in your organization?',
        answers: {
            choosing_project_yes: {
                answer: 'Yes, I’m part of a decision-making process about the app',
                nextQuestion: 'work_tools_compare',
                eventValue: 'yes'
            },
            choosing_project_no: {
                answer: 'No, I’m just joining an existing team',
                nextQuestion: '',
                eventValue: 'no',
            }
        },
        event: SegmentQuestionEvent.DECISION_MAKER
    },
    edu_choosing_project: {
        question: 'Are you choosing a project management software in your educational organization?',
        answers: {
            edu_choosing_project_yes: {
                answer: 'Yes, I’m part of a decision-making process about the app',
                nextQuestion: 'edu_role',
                eventValue: 'yes'
            },
            edu_choosing_project_no: {
                answer: 'No, I’m just joining an existing team/study process',
                nextQuestion: '',
                eventValue: 'no',
            }
        },
        event: SegmentQuestionEvent.DECISION_MAKER
    },
    work_tools_compare: {
        question: 'Which tools are you comparing Kanbanchi to?',
        answers: {
            work_tools_compare_default: {
                answer: '',
                eventValue: 'Yes'
            }
        },
        nextQuestion: 'work_hand_holding',
        answersType: EQualificationAnswersType.FREE_TEXT,
        eventsCallback: toolsCompareEventCallback
    },
    work_hand_holding: {
        question: 'Do you need some hand holding during your exploration of Kanbanchi? Choose how you would prefer us to help you:',
        answers: {
            work_hand_holding_emails: {
                answer: 'I want to get useful emails:',
            },
            work_hand_holding_sales_email: {
                answer: 'helping me to make decision about purchasing Kanbanchi (sales emails)',
                parent: 'work_hand_holding_emails'
            },
            work_hand_holding_customer_tutorials: {
                answer: 'helping me to use Kanbanchi (tutorials, tips & tricks)',
                parent: 'work_hand_holding_emails'
            },
            work_hand_holding_customer_show: {
                answer: 'helping me to show Kanbanchi value to decision-makers/budget holders',
                parent: 'work_hand_holding_emails'
            },
            work_hand_holding_customer: {
                answer: 'I want customer support team to check in from time to time to ask how I am doing'
            },
            work_hand_holding_book: {
                answer: 'I want to book a video call with a demo (booking will happen at the next step)',
                type: EQualificationAnswerType.BOOK_VIDEO_CALL
            },
            work_hand_holding_phone_call: {
                answer: 'I want to receive a phone call from Kanbanchi representative',
                type: EQualificationAnswerType.PHONE_INPUT
            },
            work_hand_holding_none: {
                answer: 'I want none of that spam and prefer to figure out the app on my own',
                singleSelect: true
            },
        },
        answersType: EQualificationAnswersType.CHECKBOXES,
        nextQuestion: 'work_role',
        eventsCallback: (questionKey: string, answer: IUserMetaQualificationAnswer) => questionHandHoldingEventCallback(questionKey, answer, 'work_')
    },
    work_role: {
        question: 'What is your role in a team? Choose whatever describes your position better.',
        answers: {
            work_role_team_lead: { answer: 'Projects and team lead', eventValue: 'Projects/team lead' },
            work_role_owner_manager: { answer: 'Owner-manager of an established business', eventValue: 'Owner-manager' },
            work_role_startup_founder: { answer: 'Startup founder' },
            work_role_freelancer: { answer: 'Freelancer or contractor', eventValue: 'Freelancer/contractor' },
            work_role_procurement: { answer: 'Procurement' },
            work_role_other: {
                answer: 'Other',
                type: EQualificationAnswerType.FREE_TEXT,
                eventValueCallback: (answer: IUserMetaQualificationAnswer) => `Other (${answer.text})`
            }
        },
        nextQuestion: 'work_team_plan_to_use',
        event: SegmentQuestionEvent.ROLE
    },
    work_team_plan_to_use: {
        question: 'How does your team plan to use Kanbanchi?',
        answers: {
            work_team_plan_to_use_lightweight: { answer: 'Lightweight task management and workflow planning', eventValue: 'Task management' },
            work_team_plan_to_use_complex: { answer: 'Complex project management', eventValue: 'Project management' },
            work_team_plan_to_use_information_and_digital: { answer: 'Information and digital assets management', eventValue: 'Assets management'},
            work_team_plan_to_use_other: {
                answer: 'Other',
                type: EQualificationAnswerType.FREE_TEXT,
                eventValueCallback: (answer: IUserMetaQualificationAnswer) => `Other (${answer.text})`
            }
        },
        nextQuestion: 'work_key_factor',
        event: SegmentQuestionEvent.USE_CASE
    },
    work_key_factor: {
        question: 'What’s the key factor for you when choosing software? Select three factors from the most important to the least.',
        answers: {
            work_key_factor_depth_feature: { answer: 'Depth of features', eventValue: 'Features', onBoardMessagePriority: 0 },
            work_key_factor_ease_use: { answer: 'Ease of use', eventValue: 'Ease of use', onBoardMessagePriority: 2 },
            work_key_factor_gsuite: { answer: 'Integration with Google Workspace (G Suite)', eventValue: 'G Suite', onBoardMessagePriority: 5 },
            work_key_factor_cost: { answer: 'Cost', eventValue: 'Cost', onBoardMessagePriority: 1 },
            work_key_factor_onboarding: { answer: 'Support & onboarding', eventValue: 'Support', onBoardMessagePriority: 3 },
            work_key_factor_soft: { answer: 'Integrations with other software we use', eventValue: 'Integrations', onBoardMessagePriority: 6 },
            work_key_factor_other: {
                answer: 'Other', type: EQualificationAnswerType.FREE_TEXT, onBoardMessagePriority: 4,
                eventValueCallback: (answer: IUserMetaQualificationAnswer) => `Other (${answer.text})`
            },
        },
        answersType: EQualificationAnswersType.CHECKBOXES,
        eventsCallback: questionKeyFactorEventCallback
    },
    personal_plan_to_use: {
        question: 'How do you plan to use Kanbanchi?',
        answers: {
            personal_plan_to_use_my_schedule: { answer: 'Plan my schedule', eventValue: 'Personal schedule' },
            personal_plan_to_use_todo_list: { answer: 'Have a personal to-do list', eventValue: 'Personal to-do list' },
            personal_plan_to_use_significant_event: { answer: 'Plan a significant event', eventValue: 'Event planning (personal)' },
            personal_plan_to_use_collaborate_with_friends: { answer: 'Collaborate with friends', eventValue: 'Friends collaboration' },
            personal_plan_to_use_other: {
                answer: 'Other', type: EQualificationAnswerType.FREE_TEXT,
                eventValueCallback: (answer: IUserMetaQualificationAnswer) => `Other (${answer.text})`
            }
        },
        event: SegmentQuestionEvent.USE_CASE
    },
    edu_role: {
        question: 'What is your role in a team? Choose whatever describes your position better.',
        answers: {
            edu_role_student: { answer: 'Student', nextQuestion: '', eventValue: 'Student' },
            edu_role_teacher: { answer: 'Teacher', eventValue: 'Teacher'},
            edu_role_admin: { answer: 'Administration worker', eventValue: 'Administration worker'},
            edu_role_it_staff: { answer: 'IT staff', eventValue: 'IT staff'},
            edu_role_procurement: { answer: 'Procurement', eventValue: 'Procurement'},
            edu_role_enu_managment: { answer: 'Edu Management', eventValue: 'Edu Management'},
            edu_role_other: {
                answer: 'Other',
                type: EQualificationAnswerType.FREE_TEXT,
                eventValueCallback: (answer: IUserMetaQualificationAnswer) => 'Other (' + answer.text + ')'
            }
        },
        nextQuestion: 'edu_tools_compare',
        event: SegmentQuestionEvent.ROLE
    },
    edu_tools_compare: {
        question: 'Which tools are you comparing Kanbanchi to?',
        answers: {
            edu_tools_compare_default: {
                answer: '',
                eventValue: 'Yes'
            }
        },
        nextQuestion: 'edu_team_plan_to_use',
        answersType: EQualificationAnswersType.FREE_TEXT,
        eventsCallback: toolsCompareEventCallback
    },
    edu_team_plan_to_use: {
        question: 'How does your team plan to use Kanbanchi?',
        answers: {
            edu_team_plan_to_use_teach_students: { answer: 'Teach students the basics of project management' },
            edu_team_plan_to_use_collaborate_class: { answer: 'Collaborate with my class', eventValue: 'Friends collaboration' },
            edu_team_plan_to_use_manage_projects: { answer: 'Manage projects of our educational institution' },
            edu_team_plan_to_use_manage_it_projects: { answer: 'Manage our IT projects' },
            edu_team_plan_to_use_other: { answer: 'Other', type: EQualificationAnswerType.FREE_TEXT }
        },
        nextQuestion: 'edu_key_factor',
        event: SegmentQuestionEvent.USE_CASE
    },
    edu_key_factor: {
        question: 'What’s the key factor for you when choosing software? Select three factors from the most important to the least.',
        answers: {
            edu_key_factor_depth_feature: { answer: 'Depth of features', onBoardMessagePriority: 1, eventValue: 'Features'},
            edu_key_factor_ease_use: { answer: 'Ease of use', onBoardMessagePriority: 2, eventValue: 'Ease of use'},
            edu_key_factor_gsuite: { answer: 'Integration with Google Workspace (G Suite)', onBoardMessagePriority: 3, eventValue: 'G Suite'},
            edu_key_factor_cost: { answer: 'Cost', onBoardMessagePriority: 4, eventValue: 'Cost'},
            edu_key_factor_google_classroom: { answer: 'Integration with Google Classroom', onBoardMessagePriority: 0},
            edu_key_factor_onboarding: { answer: 'Support & onboarding', onBoardMessagePriority: 5, eventValue: 'Support'},
            edu_key_factor_soft: { answer: 'Integrations with other software we use', onBoardMessagePriority: 6},
            edu_key_factor_other: {
                answer: 'Other',
                type: EQualificationAnswerType.FREE_TEXT,
                eventValueCallback: (answer: IUserMetaQualificationAnswer) => `Other (${answer.text})`,
                onBoardMessagePriority: 7
            },
        },
        nextQuestion: 'edu_hand_holding',
        answersType: EQualificationAnswersType.CHECKBOXES,
        eventsCallback: questionKeyFactorEventCallback
    },
    edu_hand_holding: {
        question: 'Do you need some hand holding during your exploration of Kanbanchi? Choose how you would prefer us to help you:',
        answers: {
            edu_hand_holding_emails: {
                answer: 'I want to get useful emails:',
            },
            edu_hand_holding_sales_email: {
                answer: 'helping me to make decision about purchasing Kanbanchi (sales emails)',
                parent: 'edu_hand_holding_emails'
            },
            edu_hand_holding_customer_tutorials: {
                answer: 'helping me to use Kanbanchi (tutorials, tips & tricks)',
                parent: 'edu_hand_holding_emails'
            },
            edu_hand_holding_customer_show: {
                answer: 'helping me to show Kanbanchi value to decision-makers/budget holders',
                parent: 'edu_hand_holding_emails'
            },
            edu_hand_holding_customer: {
                answer: 'I want customer support team to check in from time to time to ask how I am doing'
            },
            edu_hand_holding_book: {
                answer: 'I want to book a video call with a demo (booking will happen at the next step)',
                type: EQualificationAnswerType.BOOK_VIDEO_CALL
            },
            edu_hand_holding_phone_call: {
                answer: 'I want to receive a phone call from Kanbanchi representative',
                type: EQualificationAnswerType.PHONE_INPUT
            },
            edu_hand_holding_none: {
                answer: 'I want none of that spam and prefer to figure out the app on my own',
                singleSelect: true
            },
        },
        answersType: EQualificationAnswersType.CHECKBOXES,
        eventsCallback: (questionKey: string, answer: IUserMetaQualificationAnswer) => questionHandHoldingEventCallback(questionKey, answer, 'edu_')
    },
}
