import { IApplicationState } from '../../../../../../types/types';
import { connect } from 'react-redux';
import { onSubmit } from './events/onSubmit';
import { onSkip } from './events/onSkip';
import { getAsidePanelWidth } from '../../../../aside_panel/asidePanel/store/selectors/getAsidePanelWidth';
import { getMessages, root } from '../../../../../../store/constants';
import { Poll } from '../../../../base/components/Poll/components/Poll/Poll';
import { IPollEvents, IPollFields } from '../../../../base/components/Poll/components/Poll/types';
import { POLL_DELAY_AFTER } from '../../../../base/components/Poll/components/constants';
import { onRate } from './events/onRate';
import { getUserMessengerPoll } from '../../../../../../store/model/authUser/selectors/getUserMessengerPoll';
import { MESSENGER_POLL_OPTIONS } from './constants';
import { getShowFeaturePoll } from '../../../featurePoll/hocs/FeaturePoll/selectors/getShowFeaturePoll';
import { getShowPricingPoll } from '../../../pricingPoll/hocs/PricingPoll/selectors/getShowPricingPoll';

const mapStateToProps = (
    state: IApplicationState,
): IPollFields => {
    const { lastTimeShowed, isSkipped, isShowed } = getUserMessengerPoll(state);
    if (isShowed) return;

    let isShow = null;
    const isMessengerPollEnabled = root.App.controller && root.App.controller.getMessengerPollEnabled();
    if (!isMessengerPollEnabled) return { isShow };

    const isTrialExtendOpen = !!document.querySelector('.dialog-blocker__trial-dialog');
    isShow = !getAsidePanelWidth(state) && !isTrialExtendOpen && !getShowFeaturePoll(state) && !getShowPricingPoll(state);
    if (!isShow) return { isShow };

    isShow = !lastTimeShowed;

    if (lastTimeShowed || isSkipped) {
        const timeSinceLastShowed = Date.now() - lastTimeShowed;
        const oneDay = 1000 * 60 * 60 * 24;
        const oneWeek = oneDay * 7;
        if (
            (!isSkipped && timeSinceLastShowed < POLL_DELAY_AFTER) || // только что ответил, показать Thank you
            timeSinceLastShowed > oneWeek // пора отвечать опять
        ) {
            isShow = true;
        }
    }

    return {
        isShow,
        backButtonLabel: getMessages().getText('messenger-poll.back'),
        title: getMessages().getText('messenger-poll.title'),
        reviewPlaceholder: getMessages().getText('messenger-poll.placeholder'),
        reviewTitle: getMessages().getText('messenger-poll.review'),
        questionsList: [{ question: '', options: MESSENGER_POLL_OPTIONS }],
    };
}

const mapDispatchToProps = (
    dispatch: any,
): IPollEvents => {
    return {
        onRate: () => dispatch(onRate()),
        onSkip: () => dispatch(onSkip()),
        onSubmit: (results) => dispatch(onSubmit(results))
    };
}

export const MessengerPollHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(Poll);

MessengerPollHOC.displayName = 'MessengerPollHOC';
