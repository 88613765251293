import { ISearchSelectOption, ISearchSelectOptionProps } from '../types';
import { isElementHasOptions } from './isElementHasOptions';

export const flatOptions = (
    options: ISearchSelectOption[],
    level: number = 0
): ISearchSelectOptionProps[] => {
    const result: ISearchSelectOptionProps[] = [];
    options.forEach((element) => {
        result.push({
            ...element,
            level
        });
        if (isElementHasOptions(element)) {
            result.push(...flatOptions(element.options, level + 1));
        }
    });
    return result;
}
