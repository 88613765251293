import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../types/types';
import { IAsidePanelActionsResizeFields, IAsidePanelActionsResizeEvents } from '../../components/AsidePanelActionsResize/types';
import { onClick } from './events/onClick';
import { AsidePanelActionsResize } from '../../components/AsidePanelActionsResize/AsidePanelActionsResize';
import { getAsidePanelButtonsPanelIsMinimized } from '../../store/selectors/getAsidePanelButtonsPanelIsMinimized';

const mapStateToProps = (
    state: IApplicationState
): IAsidePanelActionsResizeFields => {
    const isMinimized = getAsidePanelButtonsPanelIsMinimized(state);

    return {
        isMinimized,
    }
};

const mapDispatchToProps = (
    dispatch: any
): IAsidePanelActionsResizeEvents => {
    return {
        onClick: () => dispatch(onClick()),
    }
};

export const AsidePanelActionsResizeHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(AsidePanelActionsResize);

AsidePanelActionsResizeHOC.displayName = 'AsidePanelActionsResizeHOC';
