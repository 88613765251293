import { ITypeSwitcherState } from '../types';
import { getTypeSwitcherLocalStorage } from '../localstorage/actions/getTypeSwitcherLocalStorage';
import { isWindowMobile } from '../../../main/navigationPanel/constants';
import { EViewTypes } from '../../../../../const';

export const getInitialState = (
): ITypeSwitcherState => {
    const localStorageState = getTypeSwitcherLocalStorage();
    if (isWindowMobile()) {
        Object.values(localStorageState).forEach(boardState => {
            if (boardState.activeType === EViewTypes.REPORTS_VIEW_TYPE) {
                boardState.activeType = EViewTypes.KANBAN_VIEW_TYPE;
            }
        })
    }
    return localStorageState;
}
