import { IApplicationState } from '../../../../../types/types';
import { IAssignedToMeCard } from '../myWork/components/MyWork/types';
import { createDeepEqualSelector } from '../../../helpers/memoizeHelper';
import { getCardAssignees } from '../../../../../store/model/cardAssignees/selectors/getCardAssignees';
import { TStatus } from '../../../../../types/model';
import { getMyTaskCardProcessingStatus } from '../helpers/getMyTaskCardProcessingStatus';

type TgetAssignedToMeCardsSelector = (
    state: IApplicationState,
) => IAssignedToMeCard[];

export const getMyWorkCards: TgetAssignedToMeCardsSelector = createDeepEqualSelector(
    getCardAssignees,
    (cardAssignees) => {
        const result: IAssignedToMeCard[] = [];
        for (let id in cardAssignees) {
            if (cardAssignees[id].status === TStatus.STATUS_ACTIVE && cardAssignees[id].cardId) {
                const { startDate, dueDate, processingPercent } = cardAssignees[id];
                result.push({
                    ...cardAssignees[id],
                    processingStatus: getMyTaskCardProcessingStatus(startDate, dueDate, processingPercent)
                });
            }
        }
        return result;
    }
)
