'use strict';
import {marked} from 'marked';
import { difference } from 'underscore';

App.Helpers.MarkdownHelper = {

    allowedTags: [
        'em', 'i', 'strong', 'b', 'a',
        'code', 'pre',
        'ul', 'ol', 'li',
        'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
        'p', 'pre', 'blockquote',
        'hr', 'br'
    ],

    blockTags: [
        'pre', //todo return `code` to block if it needed
        'ul', 'ol', 'li',
        'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
        'p', 'pre', 'blockquote',
        'hr', 'br'
    ],

    /**
     * Функция
     * @param options
     *      options.text                - markdown код
     *      options.foldLinks           - нужно ли обрезать ссылку?
     *      options.clearBlockElements  - удалять ли теги блочных элементов?
     *      options.appendText          - дополнительный текст
     *      options.removeTags          - таги для удаления принудительно
     * @returns форматированный html
     */
    markdown: function(options) {
        var text = options.text || '';
        var foldLinks = options.foldLinks;
        var clearBlockElements = options.clearBlockElements;
        var appendText = options.appendText;
        var markedText;

        if (this.renderer == null) {
            this.renderer = new marked.Renderer();
            this.renderer.link = App.Helpers.MarkdownHelper.rendererLink;
            this.foldedLinksRenderer = new marked.Renderer();
            this.foldedLinksRenderer.link = App.Helpers.MarkdownHelper.rendererLinkFolded;
        }

        markedText = marked(text, {
            gfm: true,
            breaks: true,
            tables: false,
            sanitize: true,
            renderer: (foldLinks ? this.foldedLinksRenderer : this.renderer)
        });

        if (clearBlockElements && options.allowedTags) {
            markedText = App.Helpers.MarkdownHelper.clearElements(markedText, options.allowedTags);
        } else if(clearBlockElements) {
            markedText = App.Helpers.MarkdownHelper.clearElements(markedText, difference(this.allowedTags, this.blockTags));
        }

        if (appendText && typeof appendText == 'string') {
            markedText = appendText + markedText;
        }
        return markedText;
    },

    stripTags: function (input, allowed) {
        allowed = (((allowed || '') + '').toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join('');
        var tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi
        var commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi
        return input.replace(commentsAndPhpTags, '').replace(tags, function ($0, $1) {
            return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '';
        });
    },

    /**
     * обрезка длинных ссылок
     */
    shorternUrl: function(url) {
        var r = /:\/\/(.+)/;
        if (url.match(r)){
	        var domain = url.match(r)[1], query = url.match(r)[2];
	        return domain + (query ? '/...' : '');
        }else{
        	return url;
        }
    },

    /**
     * удаление тегов
     */
    clearElements: function(html, allowedTags) {
        return this.stripTags(html, '<' + allowedTags.join('><') + '>');
    },

    rendererLink: function(href, title, text) {
        var out = '<a href="' + href + '" target="_blank"';
        if (title) {
          out += ' title="' + title + '"';
        }
        out += '>' + text + '</a>';
        return out;
    },

    rendererLinkFolded: function(href, title, text) {
        var out = '<a href="' + href + '" target="_blank"';
        if (text) {
            if (title) {
                out += ' title="' + title + '"';
            }
            out += '>' + text + '</a>';
        } else {
            out += '>' + App.Helpers.MarkdownHelper.shorternUrl(href) + '</a>';
        }
        return out;
    }

};
