import { IABTestState } from '../types';
import { getABTests } from '../../abTests/constants';
import { IABTest } from '../../types';

export const getInitialState = (): IABTestState => {
    return getABTests().reduce((
        memo: IABTestState,
        test: IABTest
    ) => {
        memo[test.name] = {
            value: test.defaultValue,
            init: false
        };
        return memo;
    }, {});
};
