import { IApplicationState } from '../../../../types/types';
import { ECardChecklistOrderMode } from '../../../../types/rest/IUserMeta';
import { getAuthUser } from './getAuthUser';

export const getAuthUserChecklistOrderMode = (
    state: IApplicationState
): ECardChecklistOrderMode => {
    const user = getAuthUser(state);
    if (
        !user.meta ||
        !user.meta.checklistOrderMode
    ) return ECardChecklistOrderMode.MANUAL;

    return user.meta.checklistOrderMode;
};
