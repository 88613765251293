import { IApplicationState } from '../../../types/types';
import { IBoard } from '../board/types';
import { getBoardInitialState } from '../constants';

export const getBoard = (
    state: IApplicationState,
    boardId: number
): IBoard => {
    return state.model.dashboards[boardId] || getBoardInitialState();
};
