import { TBoardId } from '../../../../../../../../types/types';
import { ThunkAction } from '../../../../../../../../types/actions';
import { getBoardExportSettings } from '../../../../../../../../store/model/board/selectors/getBoardExportSettings';
import { boardSetExportSettings } from '../../../../../../../../rest/effects/board/boardSetExportSettings';
import { SegmentBoardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import {
    SegmentBoardOption,
    SegmentBoardOptionTimeFormatValue
} from '../../../../../../../../middlewares/segment/trackEntities/boardEvents';

export const onTimeFormatChange = (
    boardId: TBoardId,
    is12hours: boolean
): ThunkAction => {
    return (
        dispatch,
        getState
    ) => {
        const settings = getBoardExportSettings(getState(), boardId);
        dispatch(segmentTrackAction(SegmentBoardEvent.EXPORT_TIME_FORMAT_CHANGED, {
            name: SegmentBoardOption.VALUE,
            value: is12hours ? SegmentBoardOptionTimeFormatValue.HOURS_12 : SegmentBoardOptionTimeFormatValue.HOURS_24
        }));
        return dispatch(boardSetExportSettings(boardId, {
            ...settings,
            is12hours
        }))
    }
}
