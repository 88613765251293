import { AsidePanelActionsButton } from '../../../../..';
import * as React from 'react';
import { IBoardCardAgingPanelButtonProps } from './types';
import { CLASS_BOARD_DETAILS_SECTION } from '../../../../constants';
import { DETAILS_SECTION_CARD_AGING_PROPERTIES_BUTTON } from '../../constants';

export function CardAgingPanelButton ({
    indicatorBoolean,
    isBasicFeature,
    onClick
}: IBoardCardAgingPanelButtonProps) {
    return (
        <AsidePanelActionsButton
            children={null}
            sectionSelector={`.${CLASS_BOARD_DETAILS_SECTION}--card-aging`}
            title={DETAILS_SECTION_CARD_AGING_PROPERTIES_BUTTON}
            indicatorBoolean={indicatorBoolean}
            isBasicFeature={isBasicFeature}
            onClick={onClick}
        />
    );
}
