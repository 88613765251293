import { EDateType } from '../../../../../../../const';
import * as React from 'react';

export enum EDatepickerItemSize {
    BASE = 'base',
    LARGE = 'large',
    EXTRA_LARGE = 'extra-large'
}

export interface IDateButtonFields {
    dateType: EDateType;
    date: Date;
    defaultTime: Date;
    fromDate?: Date;
    isHideDate: boolean;
    isOpened: boolean;
    isRequireError: boolean;
    maxFromDate: Date;
    minToDate: Date;
    placeholder: string;
    size: EDatepickerItemSize;
    toDate?: Date;
    uniqueId: string;
}

export interface IDateButtonEvents {
    onBlur: (e: React.FocusEvent) => void;
    onCalendarClosed: () => void;
    onCalendarOpened: () => void;
    onDateChange: (date: Date, ignoreChange?: boolean) => Promise<boolean>;
    onFocus: (e: React.FocusEvent) => void;
    onKeyDown: (e: React.KeyboardEvent) => void;
    onSetFocus: (isFocus: boolean) => void;
    onSetHoursType: (date: Date) => void;
    setTimeEditing: () => void;
}

export interface IDateButtonProps extends
    IDateButtonFields,
    IDateButtonEvents
{}
