import { IApplicationState } from '../../../../../../types/types';
import { connect } from 'react-redux';
import { IUpdatesModalEvents, IUpdatesModalFields } from '../../components/UpdatesModal/types';
import { UpdatesModal } from '../../components/UpdatesModal/UpdatesModal';
import { onClose } from './events/onClose';
import { IUpdatesModalHOCProps } from './types';

const mapStateToProps = (
    state: IApplicationState,
    { updates }: IUpdatesModalHOCProps
): IUpdatesModalFields => {

    return {
        updates,
    }
};

const mapDispatchToProps = (
    dispatch: any
): IUpdatesModalEvents => {
    return {
        onClose: () => dispatch(onClose()),
    }
};

export const UpdatesModalHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(UpdatesModal);

UpdatesModalHOC.displayName = 'UpdatesModalHOC';
