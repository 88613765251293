import { IEditedFilterActionsSetAction } from './types';
import { AT_EDITED_FILTER_ACTION_SET } from './constants';
import { TFilterAction } from '../../filter/actions/types';

export const editedFilterActionSet = (
    filterAction: TFilterAction
): IEditedFilterActionsSetAction => ({
    type: AT_EDITED_FILTER_ACTION_SET,
    editedFilterAction: filterAction
});
