import { ThunkAction } from '../../../../../../../../types/actions';
import { getCardChecklistItemsNumber } from '../../../../../../../../store/model/checklists/checklists/selectors/getCardChecklistItemsNumber';
import {
    cardRestGetSections,
    TCardRestGetSectionsRequest
} from '../../../../../../../../rest/effects/card/card/api/helper/cardRestGetSections';
import { IApplicationState, TBoardId } from '../../../../../../../../types/types';
import { ICard } from '../../../../../../../../store/model/card/types';
import { getBoardCardsByStatuses } from '../../../../../../../../store/model/selectors/getBoardCardsByStatuses';
import { TStatus } from '../../../../../../../../types/model';

export const loadChecklists = (
    boardId: TBoardId,
): ThunkAction => {
    return (
        dispatch,
        getState
    ) => {
        const state = getState();
        const cards = getBoardCardsByStatuses(state, boardId, [TStatus.STATUS_ACTIVE, TStatus.STATUS_SERVICE_TEMPLATE]);
        const cardsRequest = getCardsRequest(state, cards);
        if (!cardsRequest.length) return Promise.resolve();

        return dispatch(cardRestGetSections(cardsRequest));
    }
}

const getCardsRequest = (
    state: IApplicationState,
    cards: ICard[]
): TCardRestGetSectionsRequest[] => {
    const cardRequest: TCardRestGetSectionsRequest[] = [];
    cards.forEach(card => {
        if (card.checklistStats.totalCount > 0 && !getCardChecklistItemsNumber(state, card.id)) {
            cardRequest.push({
                cardId: card.id,
                sections: ['checklists']
            })
        }
    })
    return cardRequest;
}
