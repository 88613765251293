import { IGetState, TBoardId, TColorId } from '../../../types/types';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { sendRealTimeStoreActionByBoardId } from '../../../view/react_components/base/helpers/realTimeHelperTS';
import { deleteRest } from './api/deleteRest';
import { deleteColorAction } from '../../../store/model/board/actions/deleteColorAction';
import { boardsActionSet } from '../../../store/model/actions/boardsActionSet';
import { boardActionSet } from '../../../store/model/boards/actions/boardActionSet';
import { getActiveCardId } from '../../../store/router/selectors/getActiveCardId';
import { cardsActiveRequestsUpdate } from '../../../store/requestsState/effects/cardsActiveRequestsUpdate';
import { ICards } from '../../../store/model/cards/types';
import { boardActiveRequestsUpdate } from '../../../store/requestsState/effects/boardActiveRequestsUpdate';

export const boardColorDelete = (
    boardId: TBoardId,
    colorId: TColorId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const cardId = getActiveCardId(getState());
        if (cardId) {
            dispatch(cardsActiveRequestsUpdate({ [cardId]: {} } as ICards, 1));
        } else {
            dispatch(boardActiveRequestsUpdate(boardId, 1));
        }
        return dispatch(deleteRest(colorId))
            .then(() => {
                const actionSet = boardsActionSet(boardActionSet(boardId, deleteColorAction(colorId)));
                dispatch(actionSet);
                dispatch(sendRealTimeStoreActionByBoardId(boardId, actionSet));
            })
            .finally(() => {
                if (cardId) {
                    dispatch(cardsActiveRequestsUpdate({ [cardId]: {} } as ICards, -1));
                } else {
                    dispatch(boardActiveRequestsUpdate(boardId, -1));
                }
            });
    };
    return action;
}
