'use strict';
import { CLASS_PANELS_BLUR } from 'app/const';
import { EThemeBackgroundOption } from "../types/rest/IRestTheme";

var ThemeHelper = function(options) {
    this.$el = options.$el;
};

ThemeHelper.prototype.applyTheme = function(theme) {
    this.clearTheme();
    if (theme && theme.id) {
        this.$el.addClass('page--theme-decorated');
        if (theme.libraryCssClass) {
            this.$el.addClass('page--' + theme.libraryCssClass);
            let css = {'background-image': `linear-gradient(
                to bottom,
                 rgba(38 ,50, 56, .5),
                 rgba(38, 50, 56, 0) 150px,
                 rgba(38, 50, 56, 0), 70%,
                 rgba(38, 50, 56, 0), 90%,
                 rgba(38, 50, 56,.2)
            ), url("/dist/img/themes/${theme.libraryCssClass}.jpg")`};
            this.$el.css(css);
            const panelsBlur = document.querySelectorAll('.' + CLASS_PANELS_BLUR);
            if (panelsBlur) panelsBlur.forEach(panelBlur => $(panelBlur).css(css));
        } else {
            this.applyCustomTheme(theme);
        }
    }
};

ThemeHelper.prototype.applyCustomTheme = function(theme) {
    if (theme.backgroundImage) {
        let css = {
            'background-image': `linear-gradient(
                to bottom,
                rgba(38 ,50, 56, .5),
                 rgba(38, 50, 56, 0) 150px,
                 rgba(38, 50, 56, 0), 70%,
                 rgba(38, 50, 56, 0), 90%,
                 rgba(38, 50, 56,.2)
            ), url("${theme.backgroundImage}")`,
            'background-repeat': theme.backgroundOption === EThemeBackgroundOption.REPEAT ? 'repeat' : 'no-repeat',
            'background-size': theme.backgroundOption === EThemeBackgroundOption.SCALE ? 'cover' : 'unset'
        };
        this.$el.css(css);
        const panelsBlur = document.querySelectorAll('.' + CLASS_PANELS_BLUR);
        if (panelsBlur) panelsBlur.forEach(panelBlur => $(panelBlur).css(css));
    }
};

ThemeHelper.prototype.clearTheme = function() {
    this.clearThemeClasses();
    this.clearInlineStyles();
};

ThemeHelper.prototype.clearThemeClasses = function() {
    var classList = this.$el[0].className.split(/\s+/);
    for (var i = 0, len = classList.length; i < len; i++) {
        if (classList[i].indexOf('page--theme') > -1) {
            this.$el.removeClass(classList[i]);
        }
    }
};

ThemeHelper.prototype.clearInlineStyles = function() {
    this.$el.css({
        'background': ''
    });
};

export default ThemeHelper;
