'use strict';

import template from './template/notification-system.html';
import { getActiveBoardLngs } from '../../store/model/selectors/getActiveBoardLngs';
import { getAppState } from '../../store/configureStore';

App.Views.SystemNotification = App.Views.BaseNotification.extend({
    className: 'notifire notifire--system',
        
    initialize: function(options) {        
        App.Views.BaseNotification.prototype.initialize.call(this, options);
        var lngs = getActiveBoardLngs(getAppState());
        this.template = App.MLTemplate.getTemplate('#notification-system', lngs, template);
    },

    getActions: function() {
        return {
            path: null,
            icon: 'lock',
            action: '',
            body: ''
        };
    },

    onClick: function(e, notification) {
        this.closeNotification(notification);
        e.stopPropagation();
    }
});
