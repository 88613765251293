import { Dispatch, ThunkAction } from '../../../../types/actions';
import { root } from '../../../../store/constants';
import { IGetState } from 'app/types/types';
import { getCurrentNavigationPanelType } from '../../main/navigationPanel/helpers/getCurrentNavigationPanelType';
import { ENavigationPanelTypes } from '../../main/navigationPanel/constants';
import { panelTypeSetAction } from '../../main/navigationPanel/store/navigationPanel/actions/panelTypeSetAction';
import { isOpenSetAction } from '../../main/navigationPanel/store/navigationPanel/actions/isOpenSetAction';
import { isAsideOpenSetAction } from '../../main/navigationPanel/store/navigationPanel/actions/isAsideOpenSetAction';

export const openHelpPanel = (): ThunkAction => {
    return (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        const navigationPanelType = getCurrentNavigationPanelType(getState());
        if (navigationPanelType === ENavigationPanelTypes.NP_COMPACT_TYPE) {
            dispatch(panelTypeSetAction(ENavigationPanelTypes.NP_COMPACT_BIG_TYPE));
            dispatch(isOpenSetAction(false));
        }
        dispatch(isAsideOpenSetAction(true));
    }
};
