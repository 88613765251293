/**
 *  default prefix "DEPENDENCY_"
 */
import { EDependencyTypes, IDependency } from '../types';

export const AT_TYPE_SET = 'DEPENDENCY_TYPE_SET';
export const AT_UPDATE = 'DEPENDENCY_UPDATE';

export interface ITypeSetAction {
    type: typeof AT_TYPE_SET;
    dependencyType: EDependencyTypes;
}

export interface IUpdateAction {
    type: typeof AT_UPDATE;
    dependency: IDependency;
}

export type TDependencyAction = (
    ITypeSetAction |
    IUpdateAction
);
