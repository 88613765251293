import { IGetState, TBoardId } from '../../../types/types';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { getMessages, root } from '../../../store/constants';
import { boardRestCopy } from './api/helpers/boardRestCopy';
import { COPY_BOARD_TEMPLATE_PREFIX } from '../../../const';
import { getBoard } from '../../../store/model/selectors/getBoardById';

export const boardCreateTemplate = (
    boardId: TBoardId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const board = getBoard(getState(), boardId);
        if (!board) return Promise.reject(getMessages().getText('error.record.not_found'));

        root.App.controller.mainView.renderBlockerModal({
            text: getMessages().getText('popup.dashboard.template.copying'),
            title: getMessages().getText('popup.dashboard.template.copying.header'),
        });

        return dispatch(boardRestCopy(boardId, COPY_BOARD_TEMPLATE_PREFIX, true))
            .finally(() => root.App.controller.mainView.removeBlockerModal());

    };
    return action;
};
