import { IAssigneeRolesProps } from './types';
import * as React from 'react';
import './_AssigneeRoles.scss';
import { CLASS_CARD_DETAILS } from '../../../constants';
import { Icon, Tooltip } from 'kui';
import { getAsidePanelTooltip } from '../../../../asidePanel/helpers/getAsidePanelTooltip';
import { escape } from 'underscore';

export function AssigneeRoles ({
    roles,
}: IAssigneeRolesProps) {
    if (!roles.length) return null;
    const className = CLASS_CARD_DETAILS + '__assignees-roles';
    const classItem = className + '-item';

    return (<>
            {roles.map(role => (
                <Tooltip
                    {...getAsidePanelTooltip({
                        isNoWrap: true,
                        value: escape(role.name),
                    })}
                    key={role.id}
                >
                    <Icon
                        className={`
                            ${classItem}
                            ${classItem}--${role.color}
                        `}
                        size={24}
                        xlink={'user'}
                    />
                </Tooltip>
            ))}
    </>);
}
