import { Dispatch, ThunkAction } from '../../../types/actions';
import { IGetState, TCardId } from '../../../types/types';
import { getRest } from './api/getRest';
import { cardAssigneesActionSetAction } from '../../../store/model/actions/cardAssigneesActionSetAction';
import { cardAssigneesSetAction } from '../../../store/model/cardAssignees/actions/cardAssigneesSetAction';
import { IRestCardAssigneeInfo } from '../../../types/rest/IRestCardAssigneeInfo';

export const cardAssigneeLoad = (
    cardId: TCardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(getRest(cardId))
            .then((result: IRestCardAssigneeInfo) => {
                dispatch(cardAssigneesActionSetAction(cardAssigneesSetAction({
                    [result.cardId]: result
                })));
                return result;
            })
    };
    return action;
}
