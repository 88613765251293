import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { SegmentCardEvent, SegmentCardOptions, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { SegmentCardSourceValue } from 'app/middlewares/segment/trackEntities/cardEvents';
import * as React from 'react';

export const trackCommentSent = (event: React.KeyboardEvent<HTMLElement>): ThunkAction => {
    return (dispatch: Dispatch) => {
        if (event.ctrlKey || event.metaKey) {
            dispatch(segmentTrackAction(SegmentCardEvent.SENT_COMMENT , {
                name: SegmentCardOptions.SOURCE,
                value: SegmentCardSourceValue.HOTKEYS
            }));
        } else {
            dispatch(segmentTrackAction(SegmentCardEvent.SENT_COMMENT, {
                name: SegmentCardOptions.SOURCE,
                value: SegmentCardSourceValue.BUTTON
            }));
        }
    };
};
