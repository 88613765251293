import { connect } from 'react-redux';
import { ABUpgradePlanButton } from '../../components/ABUpgradeButton/ABUpgradeButton';
import { IApplicationState } from '../../../../../types/types';
import { IABUpgradePlanButtonStateProps } from '../../components/ABUpgradeButton/types';
import { getABTestValue } from '../../../../store/selectors/getABTestValue';
import { isABTestInit } from '../../../../store/selectors/isABTestInit';
import { AB_TEST_UPGRADE_BUTTON_COLOR } from '../../constants';
import { getProFeaturesTrialActive } from '../../../../../store/model/authUser/selectors/getProFeaturesTrialActive';

const mapStateToProps = (
    state: IApplicationState,
): IABUpgradePlanButtonStateProps => {
    const isProFeature = getProFeaturesTrialActive(state);
    return {
        color: isProFeature ? 'blue' : getABTestValue(state, AB_TEST_UPGRADE_BUTTON_COLOR),
        init: isABTestInit(state, AB_TEST_UPGRADE_BUTTON_COLOR)
    }
};

export const ABUpgradePlanButtonHOC = connect(
    mapStateToProps,
    null
)(ABUpgradePlanButton);
ABUpgradePlanButtonHOC.displayName = 'ABUpgradePlanButtonHOC';
