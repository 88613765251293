import { IGetState, TBoardId, TCardId, TColorId } from '../../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { TStatus } from '../../../../../../../../../types/model';
import { getCard } from '../../../../../../../../../store/model/selectors/getCard';
import { cardSaveColorTags } from '../../../../../../../../../rest/effects/card/card/cardSaveColorTags';
import { getBoardCardsMap } from '../../../../../../../../../store/model/selectors/getBoardCardsMap';
import { getMessages, root } from '../../../../../../../../../store/constants';
import { getDefaultActionEvent } from '../../../../../../../snackbarsStack/effects/helpers/getDefaultActionEvent';
import { boardColorDelete } from '../../../../../../../../../rest/effects/color/boardColorDelete';
import { boardColorEdit } from '../../../../../../../../../rest/effects/color/boardColorEdit';
import { snackbarPromtDelete } from '../../../../../../../snackbarsStack';
import { getColorTagColor } from '../helpers/getColorTagColor';
import { getBoardColorById } from '../../../../../../../../../store/model/board/selectors/getBoardColorById';
import { getBoardColors } from '../../../../../../../../../store/model/board/selectors/getBoardColors';

export const onDelete = (
    boardId: TBoardId,
    colorId: TColorId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return new Promise((resolve) => {
            let isArchiveOnly = true;

            const resolvePromise = (
                isDelete: boolean,
                cardIds?: TCardId[]
            ) => {
                if (isDelete) {
                    if (cardIds && !isArchiveOnly) {
                        cardIds.forEach(cardId => {
                            const colorIds = [...getCard(getState(), cardId).colorIds];
                            dispatch(cardSaveColorTags(cardId, colorIds.filter(id => id !== colorId)));
                        });
                    }
                    if (cardIds && isArchiveOnly) {
                        const { color } = getBoardColorById(getState(), boardId, colorId);
                        dispatch(boardColorEdit(boardId, {
                            id: colorId,
                            status: TStatus.STATUS_ARCHIVE,
                            color: getColorTagColor(color)
                        }));
                    } else {
                        dispatch(boardColorDelete(boardId, colorId));
                    }
                    resolve(true);
                }
            }

            root.App.controller.showSystemLoader(true);
            root.App.controller.loadBoardArchiveToStore(boardId)
                .then(() => {
                    const cardIdsWithTag: TCardId[] = [];
                    let cardsCount = 0;

                    const boardCards = getBoardCardsMap(getState(), boardId, null);
                    for (let id in boardCards) { // перебрать карты доски
                        const cardId = Number(id);
                        const card = getCard(getState(), cardId);
                        const colorTags = card && card.colorIds || [];
                        if (colorTags.includes(colorId)) {
                            if (card.status !== TStatus.STATUS_ARCHIVE) {
                                cardsCount++;
                                isArchiveOnly = false;
                            }
                            cardIdsWithTag.push(cardId);
                        }
                    }

                    if (cardsCount && !isArchiveOnly) { // если на картах есть колорТэг, снекбар с подтверждением
                        const boardColors = getBoardColors(getState(), boardId, true)
                        const colorTag = boardColors.find(colorTag => colorTag.id === colorId);
                        const textCards = getMessages().getPluralByKeys(
                            cardsCount,
                            ['snackbar.card.color_tags.delete.card', 'snackbar.card.color_tags.delete.cards'],
                            null,
                            cardsCount
                        );
                        const snackbarId = 'SNACKBAR_COLOR_DELETE_ID';
                        dispatch(snackbarPromtDelete({
                            id: snackbarId,
                            text: textCards,
                            actionApply: () => dispatch(getDefaultActionEvent(snackbarId, resolvePromise(true, cardIdsWithTag))),
                            actionCancel: () => dispatch(getDefaultActionEvent(snackbarId, resolvePromise(false)))
                        }));
                    } else {
                        resolvePromise(true, cardIdsWithTag);
                    }
                })
                .finally(() => root.App.controller.hideSystemLoader());
        });
    };
    return action;
};
