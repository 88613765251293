import { IGanttViewBoardState } from './types';
import { EDatesFilterType, EGanttSortType, EGanttZoom } from '../../constants';
import { ECardField } from '../../types';

const CARD_FIELDS_DEFAULT: ECardField[] = [
    ECardField.DESCRIPTION,
    ECardField.ASSIGNEES
];

export const getGanttViewBoardInitialState =
(): IGanttViewBoardState => {
    const ganttViewBoardInitialState: IGanttViewBoardState = {
        cardFields: [...CARD_FIELDS_DEFAULT],
        collapsedCards: [],
        datesFilter: {
            type: EDatesFilterType.ALL,
            from: null,
            to: null
        },
        hideEmptyDates: false,
        highlightId: null,
        isCollapsed: false,
        isLoading: false,
        isMobile: false,
        isPrint: false,
        isPrintCut: true,
        isPrintFit: false,
        sortType: EGanttSortType.BY_DATE,
        zoom: EGanttZoom.ZOOM_3,
    };
    return ganttViewBoardInitialState;
};
