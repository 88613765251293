import { IGetState, TListId } from '../types/types';
import { Dispatch, ThunkAction } from '../types/actions';
import { getBoardByListId } from '../store/model/selectors/getBoardByListId';
import { getList } from '../store/model/list/selectors/getList';
import { TStatus } from '../types/model';
import { getTypeSwitcherActiveType } from '../view/react_components/typeSwitcher/store/selectors/getTypeSwitcherActiveType';
import { EViewTypes } from '../const';
import { getMessages, root } from '../store/constants';
import { changeBackboneViewType } from 'app/view/react_components/typeSwitcher/effects/changeBackboneViewType';

export const openListDetails = (
    listId: TListId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const board = getBoardByListId(state, listId);
        const list = getList(state, listId);
        if (list) {
            if (list.status === TStatus.STATUS_ARCHIVE && getTypeSwitcherActiveType(state, board.id) !== EViewTypes.ARCHIVE_VIEW_TYPE) {
                dispatch(changeBackboneViewType(EViewTypes.ARCHIVE_VIEW_TYPE));
            }
            root.App.controller.asidePanel.showList(listId);
        } else {
            root.App.controller.showError(getMessages().getText('error.record.not_found'));
        }
    }
    return action;
}
