import { TBoardId } from '../../../../types/types';
import { ThunkAction } from '../../../../types/actions';
import { fetchHandler } from '../../../../util/fetchHandler';
import { Util } from '../../../../util/util';
import { getBoardRolesUrl } from '../../../constants';
import { getRestHeadersPost } from '../../../helpers/getRestHeadersHelper';
import { ICardAssigneeRole, IColor } from '../../../../types/model';

export const postRest = (
    boardId: TBoardId,
    role: ICardAssigneeRole
): ThunkAction => {
    const action = () => {
        return fetchHandler<IColor>(
            Util.getApiUrl(getBoardRolesUrl(boardId)), {
                ...getRestHeadersPost(),
                body: JSON.stringify(role)
            }
        );
    };
    return action;
};
