import { connect } from 'react-redux';
import { TopBarWarning } from '../../components/TopBarWarning/TopBarWarning';
import { ITopBarWarningEvents, ITopBarWarningFields } from '../../components/TopBarWarning/types';
import { IApplicationState } from '../../../../../../types/types';
import { onClick } from './events/onClick';
import { getActiveBoardId } from '../../../../../../store/model/selectors/getActiveBoardId';
import { hasBoardWarning } from '../../../../../../store/model/board/selectors/hasBoardWarning';
import { mapWarningCodeToWarningTitle } from './constants';
import { getBoardWarning } from '../../../../../../store/model/board/selectors/getBoardWarning';

const mapStateToProps = (
    state: IApplicationState,
): ITopBarWarningFields => {
    const boardId = getActiveBoardId(state);
    const warning = getBoardWarning(state, boardId);
    const hasWarning =  hasBoardWarning(state, boardId);
    return {
        hasWarning,
        warningCode: warning,
        warningTitle: mapWarningCodeToWarningTitle[warning]
    }
};

const mapDispatchToProps = (
    dispatch: any
): ITopBarWarningEvents => {
    return {
        onClick: (warningCode) => dispatch(onClick(warningCode))
    }
};

export const TopBarWarningHOC = connect(mapStateToProps, mapDispatchToProps)(TopBarWarning);

TopBarWarningHOC.displayName = 'TopBarWarningHOC';
