import {INavPanelHelpEvents, INavPanelHelpFields} from '../../components/NavPanelHelp/types';
import {NavPanelHelp} from '../../components/NavPanelHelp/NavPanelHelp';
import { connect } from 'react-redux';
import { onOpen } from './events/onOpen';
import { onClose } from './events/onClose';
import { IApplicationState } from 'app/types/types';
import { getCurrentNavigationPanelType } from 'app/view/react_components/main/navigationPanel/helpers/getCurrentNavigationPanelType';
import { ENavigationPanelTypes } from 'app/view/react_components/main/navigationPanel/constants';
import { onDidMount } from './events/onDidMount';
import { isRouterHelp } from 'app/store/router/selectors/isRouterHelp';

const mapStateToProps = (
    state: IApplicationState
): INavPanelHelpFields => {
    const navigationPanelType = getCurrentNavigationPanelType(state);
    const isReturnFocus = navigationPanelType !== ENavigationPanelTypes.NP_COMPACT_BIG_TYPE;

    return {
        isOpened: isRouterHelp(state),
        isReturnFocus,
    }
}

const mapDispatchToProps = (
    dispatch: any
): INavPanelHelpEvents => ({
    onDidMount: () => dispatch(onDidMount()),
    onOpen: () => dispatch(onOpen()),
    onClose: () => dispatch(onClose()),
});

export const NavPanelHelpHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(NavPanelHelp);
NavPanelHelpHOC.displayName = 'NavPanelHelpHOC';
