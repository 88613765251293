import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TFilterId } from '../../../../../../../../types/types';
import {
    reorderSortedSavedFilterIds
} from '../../../../../../aside_panel/filterPanelBoard/components/SavedSection/hocs/effects/reorderSortedSavedFilterIds';
import { MY_WORK_FILTER_BOARD_ID } from '../../../../../../aside_panel/filterPanelBoard/constants';

export const onSort = (
    filterId: TFilterId,
    index: number
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(reorderSortedSavedFilterIds(MY_WORK_FILTER_BOARD_ID, filterId, index, true));
    }
    return action;
}
