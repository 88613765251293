import { IGetState, TCardId } from '../../../../../../types/types';
import { Dispatch } from '../../../../../../types/actions';
import { startTimeTracker } from './startTimeTracker';

export const startTrackingCard = (cardId: TCardId) => {
    return (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(startTimeTracker(cardId, Date.now()));
    };
};
