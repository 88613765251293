import { IApplicationState, TBoardId, TCardId } from '../../../../../../../../types/types';
import { formatCommentToSeconds } from '../../../../helpers/formatCommentToSeconds';
import { IUserSpentTime } from '../../../components/UsersTiming/types';
import { convertTimeNumberToString } from '../../../../helpers/convertTimeNumberToString';
import { getBoardWorkHoursInDay } from '../../../../../../../../store/model/selectors/getBoardWorkHoursInDay';

export const getUserTimetrackers = (
    state: IApplicationState,
    cardId: TCardId,
    boardId: TBoardId
): IUserSpentTime[] => {
    const comments = state.model.cards[cardId].comments || [];
    const timeComments = comments.filter(comment => comment.timeTracker);
    const workHoursInDay = getBoardWorkHoursInDay(state, boardId);
    const mapHashToTime: {[hash: string]: IUserSpentTime} = {};
    if (timeComments.length !== 0){
        timeComments.forEach((comment) => {
            if (comment.author && comment.author.hash) { // на проде в новом комменте в author нет поля hash :/
                let userSpentTime = mapHashToTime[comment.author.hash];
                if (!userSpentTime) {
                    mapHashToTime[comment.author.hash] = userSpentTime = {
                        hash: comment.author.hash,
                        fullName: comment.author.fullName,
                        userPicUrl: comment.author.photoUrl,
                        seconds: 0,
                        spentTime: '',
                        initials: comment.author.initials,
                        userId: comment.author.permissionId,
                    }
                }
                userSpentTime.seconds += formatCommentToSeconds(comment.text, workHoursInDay);
                userSpentTime.spentTime = convertTimeNumberToString(userSpentTime.seconds, true, workHoursInDay)
            }
        })
    }
    return Object.values(mapHashToTime);
};
