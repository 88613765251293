import * as React from 'react';
import { IRestCardCreateActivity } from '../../../../../../../types/rest/activity/IRestCardCreateActivity';
import { IActivityProps } from '../../../../boardDetails/activity/TabActivity/types';
import { TActivity } from '../../../../../../../store/activities/types';
import {
    ActivityItemBody
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemBody/ActivityItemBody';
import { ActivityHeader } from '../../../../boardDetails/activity/TabActivity/components/ActivityHeader/ActivityHeader';

export function CardActivityDelete ({
    activity
}: IActivityProps<IRestCardCreateActivity>) {
    const { label } = activity as TActivity;
    return (
        <ActivityItemBody
            header={<ActivityHeader icon={'delete'} label={label} />}
            activity={activity}
        />
    );
}
