import * as React from 'react';
import './_PanelsBlur.scss';
import { IPanelsBlurProps } from './types';
import { CLASS_PANELS_BLUR } from 'app/const';

export function PanelsBlur ({
    type,
}: IPanelsBlurProps) {
    return <>
        <div className={`${CLASS_PANELS_BLUR} ${CLASS_PANELS_BLUR}--${type}`} />
        <div className={`${CLASS_PANELS_BLUR} ${CLASS_PANELS_BLUR}--${type}`} />
    </>;
};
