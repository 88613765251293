import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { IGetState, TBoardId } from '../../../../../../../../../types/types';
import { cardNumberSetAction } from '../../../store/cardNumbers/actions/cardNumberSetAction';
import { cardNumberStateUpdateAction } from '../../../store/cardNumber/actions/cardNumberUpdate';
import { getInitialState } from '../../../store/cardNumber/constants';
import { ECardNumberStatus, ICardNumberState } from '../../../store/cardNumber/types';
import { IRestBoardCardNumber } from '../types';
import { getPrefixFromBoardName } from '../../../helpers/getPrefixFromBoardName';
import { getBoard } from '../../../../../../../../../store/model/selectors/getBoardById';
import { loadNumberNextValue } from './loadNumberNextValue';

export const loadInitialData = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const board = getBoard(state, boardId);
        if (board.id) {
            const initialState = getInitialState();
            const state: ICardNumberState = {
                ...initialState,
                prefix: board.cardNumberPrefix || initialState.prefix,
                enabled: board.cardNumberEnabled,
                status: ECardNumberStatus.LOADED
            };
            if (!state.prefix) {
                state.prefix = getPrefixFromBoardName(board.name);
                state.edited = true;
            }
            dispatch(loadNumberNextValue(boardId)).then((result: IRestBoardCardNumber) => {
                if (result.cardNumberNextValue) {
                    state.nextValue = result.cardNumberNextValue.toString();
                }
                state.initialValue = {
                    prefix: state.prefix,
                    nextValue: state.nextValue
                };
                dispatch(cardNumberSetAction(boardId, cardNumberStateUpdateAction(state)));
            })
        }

    };
    return action;
}
