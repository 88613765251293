import { TBoardId, TCardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import {
    SegmentCardEvent,
    SegmentCardOptions, SegmentCardSection,
    SegmentCardSourceValue
} from 'app/middlewares/segment/trackEntities/cardEvents';
import { cardArchiveGeneral } from 'app/store/model/effects/cardArchiveGeneral';
import { segmentTrackAction } from 'app/middlewares/segment';
import { cardArchive } from 'app/store/model/effects/cardArchive';
import { disallowedSectionClickedEvent } from 'app/view/react_components/aside_panel/cardDetails/effects/segmentEvents';

export const onClick = (
    boardId: TBoardId,
    cardId: TCardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {

        dispatch(segmentTrackAction(SegmentCardEvent.BUTTONS_PANEL_BUTTON_CLICKED, {
            name: SegmentCardOptions.TARGET,
            value: SegmentCardSourceValue.DETAILS,
        }));

        dispatch(cardArchiveGeneral(boardId, cardId))
            .then((result: boolean) => {
                dispatch(segmentTrackAction(result ? SegmentCardEvent.ARCHIVE_WITH_SUBCARDS_YES :
                    SegmentCardEvent.ARCHIVE_WITH_SUBCARDS_NO, {
                    name: SegmentCardOptions.SOURCE,
                    value: SegmentCardSourceValue.DETAILS
                }));
                dispatch(cardArchive(boardId, cardId, result));
            }, () => {
                dispatch(disallowedSectionClickedEvent(SegmentCardSection.ARCHIVE, SegmentCardSourceValue.BUTTONS_PANEL));
             });
    };
    return action;
};
