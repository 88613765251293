import { IGetState, TBoardId, TCardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { toggleCardColor } from '../effects/toggleCardColor';
import { ERateFeature } from '../../../../../../../../types/rest/IUserMeta';
import { getBoardIdByCardId } from '../../../../../../../../store/model/selectors/getBoardIdByCardId';
import { addFeatureTrigger } from '../../../../../../polls/featurePoll/effects/addFeatureTrigger';

export const onChange = (
    cardId: TCardId,
    color: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const boardId = getBoardIdByCardId(getState(), cardId);
        dispatch(addFeatureTrigger(ERateFeature.CARD_COLORS));
        dispatch(toggleCardColor(cardId, boardId, color));
    };
    return action;
};
