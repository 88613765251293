import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../types/actions';
import { closeFilterInfoPanel } from '../effects/closeFilterInfoPanel';
import { SegmentFilterEvent } from '../../../../../../middlewares/segment/trackEntities/filterEvents';
import { closePanel } from '../../../asidePanel/effects/closePanel';
import { filterSegmentTrackAction } from '../../../filterPanelBoard/effects/filterSegmentTrackAction';

export const onClose: ActionCreator<ThunkAction> = (
    boardId
) => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(closePanel());
        dispatch(closeFilterInfoPanel(boardId));
        dispatch(filterSegmentTrackAction(SegmentFilterEvent.FILTER_INFO_PANEL_CLOSED, boardId));
    };
    return action
};
