import { IGDriveFile, IGetState, TCardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { getMessages, root } from '../../../../../../../../store/constants';
import { IDriveDoc } from '../../../../../../../../store/model/card/types/IDriveDoc';
import { segmentTrackAction, SegmentUserEvent } from '../../../../../../../../middlewares/segment';
import {
    SegmentUserOption,
    SegmentUserOptionValue
} from '../../../../../../../../middlewares/segment/trackEntities/userEvents';
import { gDriveUploadFileByChunks } from '../../../../../../../../util/gDriveUploadFileByChunks';
import { gDriveUploadFile } from '../../../../../../../../util/gDriveUploadFile';
import { getBoardByCardId } from '../../../../../../../../store/model/selectors/getBoardByCardId';
import * as moment from 'moment';
import { ERestDriveDocType } from '../../../../../../../../types/rest/IRestDriveDoc';
import { getDriveDocsByCardId } from '../../../../../../../../store/model/driveDocs/selectors/getDriveDocsByCardId';
import { addAttachments } from '../../../../../../../../rest/effects/card/attacments/addAttachments';
import { authManagerInstance } from 'app/helper/authorisation/google/AuthManager';
import { commonAuthManagerInstance } from 'app/helper/authorisation/common/CommonAuthManager';

const CHUNK_FILE_UPLOAD_MIN_SIZE = 1024 * 100; // 100 KB

export const uploadFiles = (
    cardId: TCardId,
    files: FileList | File[],
    progressCallback: (percent: number) => void,
    isPaste?: boolean,
    type: ERestDriveDocType = ERestDriveDocType.CARD,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return checkPermissions(dispatch).then(() => {
            const state = getState();
            return commonAuthManagerInstance.getAccessTokenOrShowLoginScreen().then((accessToken: string) => {
                const board = getBoardByCardId(state, cardId);
                const driveDocs: IDriveDoc[] = getDriveDocsByCardId(state, cardId);
                let orderNumber = driveDocs ? driveDocs.length : 0;
                const filesTotalSize = getFilesTotalSize(Array.from(files));
                let totalUploaded = 0;

                const fileUploadPromises = Array.from(files).map(_file => {
                    const file = isPaste
                        ? new File([_file], 'Image_' + moment(new Date).format('YYYY_MMM_DD_HH_mm_ss') + '.png')
                        : _file;
                    const fileUploadPromise = file.size > CHUNK_FILE_UPLOAD_MIN_SIZE ?
                        uploadLargeFile(file, accessToken, board.assetFolderId, (uploaded: number) => {
                            totalUploaded += uploaded;
                            callOnProgress(progressCallback, totalUploaded, filesTotalSize);
                        }) : uploadCommonFile(file, board.assetFolderId).then((gDriveFile: IGDriveFile) => {
                            totalUploaded += file.size;
                            callOnProgress(progressCallback, totalUploaded, filesTotalSize);
                            return Promise.resolve(gDriveFile);
                        })
                    return fileUploadPromise;
                });

                return Promise.all(fileUploadPromises)
                    .then((gDriveFiles: IGDriveFile[]) => {
                        const driveDocs = gDriveFiles.map(gDriveFile => createDriveDoc(gDriveFile, cardId, orderNumber++, type));
                        return dispatch(addAttachments(cardId, driveDocs));
                    })
            });
        })
    };
    return action;
};

const checkPermissions = (dispatch: Dispatch) => {
    return new Promise((resolve, reject) => {
        const eventOpt = {name: SegmentUserOption.TAGRET, value: SegmentUserOptionValue.ATTACHMENTS};
        const onShow = () => {
            dispatch(segmentTrackAction(SegmentUserEvent.PERMISSION_DRIVE_SCOPE_SHOWN, eventOpt));
        };
        const onRequest = () => {
            dispatch(segmentTrackAction(SegmentUserEvent.PERMISSION_DRIVE_SCOPE_REQUESTED, eventOpt));
        };
        const onCancel = () => {
            dispatch(segmentTrackAction(SegmentUserEvent.PERMISSION_DRIVE_SCOPE_CANCELED, eventOpt));
            reject();
        };
        const onApprove = () => {
            resolve(true);
        };
        authManagerInstance.checkAndRequestDrive(onApprove, onShow, onCancel, onRequest);
    });
}

const createDriveDoc = (
    gDriveFile: IGDriveFile,
    cardId: TCardId,
    orderNumber: number,
    type: ERestDriveDocType
): IDriveDoc => {
    const driveDoc: IDriveDoc = {
        fileId: gDriveFile.id,
        title: gDriveFile.title,
        mimeType: gDriveFile.mimeType,
        url: gDriveFile.alternateLink,
        iconUrl: gDriveFile.iconLink,
        type,
        orderNumber,
        cardId
    };
    root.App.controller.trackEvent(getMessages().getText('ga.category.drive_doc'),
        getMessages().getText('ga.action.drive_doc.uploaded_via_drive'));
    return driveDoc;
}

const getFilesTotalSize = (files: File[]): number => {
    let result = 0;
    files.forEach(file => {
        result += file.size;
    });
    return result;
}

const callOnProgress = (progressCallback: (percent: number) => void, uploaded: number, total: number) => {
    if (progressCallback) {
        let percent = Math.round(uploaded / total * 100);
        if (percent > 100)
            percent = 100;
        progressCallback(percent);
    }
}

const uploadCommonFile = (file: File, folderId: string) => {
    return new Promise((resolve, reject) => {
        gDriveUploadFile(file, folderId).then((gDriveFile: IGDriveFile) => {
            resolve(gDriveFile);
        }).catch((error) => {
            if (error.code === 404) {
                gDriveUploadFile(file, null).then((gDriveFile: IGDriveFile) => {
                    resolve(gDriveFile);
                }).catch((error) => {
                    reject(error);
                })
            } else {
                reject(error);
            }
        })
    });
}

const uploadLargeFile = (file: File, accessToken: string, folderId: string, progressCallback: (uploaded: number) => void) => {
    return new Promise((resolve, reject) => {
        gDriveUploadFileByChunks(file, accessToken, folderId, progressCallback).then((file: IGDriveFile) => {
            resolve(file);
        }).catch(() => {
            gDriveUploadFileByChunks(file, accessToken, null, progressCallback).then((file: IGDriveFile) => {
                resolve(file);
            }).catch(() => {
                reject();
            });
        });
    })

}
