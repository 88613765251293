import * as React from 'react';
import { ICardEpicFormProps } from './types';
import { CLASS_CARD_DETAILS } from '../../../constants';
import './CardInHeadLinkSection.scss';
import { Button } from 'kui';
import * as _ from 'underscore';
import { getAsidePanelTooltip } from '../../../../asidePanel/helpers/getAsidePanelTooltip';

export function CardInHeadLinkSection({
    label,
    tooltip,
    archived,
    cardName,
    cardUrl,
    isArchived,
    isShow,
    onClick
}: ICardEpicFormProps) {
    if (!isShow) return null;
    const CLASS_CARD_DETAILS_EPIC = CLASS_CARD_DETAILS + '-epic';
    const className = CLASS_CARD_DETAILS_EPIC;
    const classNameLabel = CLASS_CARD_DETAILS_EPIC + '__label';
    const classNameName = CLASS_CARD_DETAILS_EPIC + '__name';
    const classNameArchived = CLASS_CARD_DETAILS_EPIC + '__archived';

    const onClickLinkHandler = (event: React.MouseEvent) => {
        event.preventDefault();
        onClick();
    };

    return (
        <div className={className}>
            <span className={classNameLabel}>
                {label}
            </span>
            <Button
                className={classNameName}
                href={cardUrl}
                tooltip={{
                    direction: 'down-right',
                    isNoEvents: true,
                    isNoWrap: true,
                    value: `${tooltip} ${_.escape(cardName)}`
                }}
                variant={'icon-text'}
                onClick={onClickLinkHandler}
            >
                {cardName}
            </Button>
            {
                isArchived &&
                <span className={classNameArchived}>
                    {archived}
                </span>
            }
        </div>
    );
};
