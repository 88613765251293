import { IApplicationState } from '../../../../../../types/types';
import { connect } from 'react-redux';
import { onSubmit } from './events/onSubmit';
import { onSkip } from './events/onSkip';
import { getUserRateAppPoll } from '../../../../../../store/model/authUser/selectors/getUserRateAppPoll';
import { getAsidePanelWidth } from '../../../../aside_panel/asidePanel/store/selectors/getAsidePanelWidth';
import { getMessages, root } from '../../../../../../store/constants';
import { Poll } from '../../../../base/components/Poll/components/Poll/Poll';
import { IPollEvents, IPollFields } from '../../../../base/components/Poll/components/Poll/types';
import { POLL_DELAY_AFTER } from '../../../../base/components/Poll/components/constants';
import { onRate } from './events/onRate';
import { getAuthUserPlatformType } from '../../../../../../store/model/authUser/selectors/getAuthUserPlatformType';
import {
    POLL_QUESTION_FEEDBACK_END,
    POLL_QUESTION_FEEDBACK_START,
    POLL_TITLE
} from '../../../../base/components/Poll/components/Poll/constants';
import { KANBAN_POLL_QUESTIONS } from './constants';
import { getShowMessengerPoll } from '../../../messengerPoll/hocs/MessengerPoll/selectors/getShowMessengerPoll';
import { getShowFeaturePoll } from '../../../featurePoll/hocs/FeaturePoll/selectors/getShowFeaturePoll';
import { getShowPricingPoll } from '../../../pricingPoll/hocs/PricingPoll/selectors/getShowPricingPoll';

const mapStateToProps = (
    state: IApplicationState,
): IPollFields => {
    const { lastTimeShowed, isSkipped } = getUserRateAppPoll(state);
    let isShow = null;
    const isUMUXEnabled = root.App.controller && root.App.controller.getUMUXEnabled();
    if (!isUMUXEnabled) return { isShow };

    const isTrialExtendOpen = !!document.querySelector('.dialog-blocker__trial-dialog');
    isShow = !getShowPricingPoll(state) && !getAsidePanelWidth(state) && !isTrialExtendOpen && !getShowMessengerPoll(state) && !getShowFeaturePoll(state);
    if (!isShow) return { isShow };

    isShow = !lastTimeShowed;

    if (lastTimeShowed) {
        const timeSinceLastShowed = Date.now() - lastTimeShowed;
        const oneDay = 1000 * 60 * 60 * 24;
        const oneYear = oneDay * 365;
        const oneWeek = oneDay * 7;
        const waitTime = isSkipped ? oneWeek : oneYear;
        if (
            (!isSkipped && timeSinceLastShowed < POLL_DELAY_AFTER) || // только что ответил, показать Thank you
            timeSinceLastShowed > waitTime // пора отвечать опять
        ) {
            isShow = true;
        }
    }

    const title = getMessages().getText('kanban-poll.title');

    return {
        platformType: getAuthUserPlatformType(state),
        isShow,
        title: POLL_TITLE + title,
        reviewTitle: POLL_QUESTION_FEEDBACK_START + title + POLL_QUESTION_FEEDBACK_END,
        questionsList: KANBAN_POLL_QUESTIONS
    };
}

const mapDispatchToProps = (
    dispatch: any,
): IPollEvents => {
    return {
        onRate: () => dispatch(onRate()),
        onSkip: () => dispatch(onSkip()),
        onSubmit: (results) => dispatch(onSubmit(results))
    };
}

export const KanbanPollHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(Poll);

KanbanPollHOC.displayName = 'KanbanPollHOC';
