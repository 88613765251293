import { ICardDescriptionUnsavedSetAction } from './types';
import { AT_SET } from './constants';
import { TCardId } from '../../../../../../../../types/types';
import { IDescriptionUnsavedState } from '../types';

export const cardDescriptionUnsavedSetAction =
(
    cardId: TCardId,
    description: IDescriptionUnsavedState
): ICardDescriptionUnsavedSetAction => ({
    type: AT_SET,
    cardId,
    description
});
