import {IApplicationState, TCardId, TPermissionId} from '../../../types/types';
import {getCardAssignees} from './getCardAssignees';

export const isCardAssigneeExistByBoardPermissionId = (
    state: IApplicationState,
    cardId: TCardId,
    permissionId: TPermissionId
): boolean => {
    const assignees = getCardAssignees(state, cardId);
    for (let i=0; i<assignees.length; i++) {
        if (assignees[i].sharedUser.permissionId === permissionId) return true;
    }
    return false;
}
