import {
    ASYNC_SUCCESS_TYPE,
    ASYNC_FAILURE_TYPE,
    ASYNC_REQUEST_TYPE,
} from './../../../../../../../../store/constants';

import {PTGC_REMOVE_EVENT} from './../../constants';

export const removeCardEventFromGoogleCalendarRequest = (calendarId) => ({
    type: `${PTGC_REMOVE_EVENT}${ASYNC_REQUEST_TYPE}`,
    calendarId
});

export const removeCardEventFromGoogleCalendarSuccess = () => ({
    type: `${PTGC_REMOVE_EVENT}${ASYNC_SUCCESS_TYPE}`
});

export const removeCardEventFromGoogleCalendarFail = () => ({
    type: `${PTGC_REMOVE_EVENT}${ASYNC_FAILURE_TYPE}`
});