import { IValidator } from './types';
import {
    ERROR_MESSAGE_MAX_VALUE,
    ERROR_MESSAGE_NOT_NUMBER,
    ERROR_MESSAGE_TOO_LONG,
    MAX_NUMBER_LENGTH
} from './constants';
import { ICard } from '../../../../../../../store/model/card/types';

const getNextValueError: IValidator = (cards, prefix, nextValue) => {
    if (nextValue && !nextValue.match(/^\d+$/)) {
        return ERROR_MESSAGE_NOT_NUMBER;
    } else if (nextValue.length > MAX_NUMBER_LENGTH) {
        return ERROR_MESSAGE_TOO_LONG;
    }
    return null;
};

const getCardNumberIsNotLessError: IValidator = (cards, prefix, nextValue) => {
    if (!prefix) return null;
    const cardsWithPrefix = cards.filter(card => {
        return card.cardNumber && card.cardNumber.includes(`${prefix}-`)
    });
    const cardNumberValues: number[] = cardsWithPrefix.map(card => {
        const prefixValue = card.cardNumber.split(/\-(?=[\d]+$)/); // разбиваем по последнему тире
        return parseInt(prefixValue[1]);
    });
    const maxValue = Math.max(...cardNumberValues);
    if (cardNumberValues.length && parseInt(nextValue) <= maxValue) {
        return ERROR_MESSAGE_MAX_VALUE + (maxValue + 1);
    }
    return null;
};

export const getValidationError = (cards: ICard[], prefix: string, nextValue: string): string => {
    const validators = [getNextValueError, getCardNumberIsNotLessError];
    return validators.reduce((error: string, validator) => {
        if (!error) return validator(cards, prefix, nextValue);
        return error;
    }, null)
}
