import { connect } from 'react-redux';
import { onClick } from './events/onClick';
import { COPY_CARD_BUTTON_TITLE, COPY_CARD_BUTTON_TOOLTIP } from './constants';
import { ICopyCardHOCProps } from './types';
import { IAsidePanelActionsButtonProps } from '../../../asidePanel/components/AsidePanelActionsButton/types';
import {
    AsidePanelActionsButton
} from '../../../../aside_panel/asidePanel/components/AsidePanelActionsButton/AsidePanelActionsButton';
import { getPanelButtonShow } from '../../selectors/getPanelButtonShow';
import { IApplicationState } from '../../../../../../types/types';
import { getAsidePanelEntityPanelWidth } from '../../../asidePanel/store/selectors/getAsidePanelEntityPanelWidth';
import { CLASS_NAME_BUTTON_HIDE_TITLE, MIN_WIDTH_DISPLAY_COPE_CARD } from '../../ActionsInHeadSection/constants';

const mapStateToProps = (
    state: IApplicationState,
    { cardId, boardId }: ICopyCardHOCProps
): IAsidePanelActionsButtonProps => {
    const isShow = getPanelButtonShow(state, boardId, cardId);
    const panelWidth = getAsidePanelEntityPanelWidth(state);
    const isHideTitle = panelWidth < MIN_WIDTH_DISPLAY_COPE_CARD;

    return {
        icon: 'copy',
        title: COPY_CARD_BUTTON_TITLE,
        isShow,
        tooltipText: isHideTitle ? COPY_CARD_BUTTON_TOOLTIP : null,
        tooltipDirection: 'down',
        className: isHideTitle ? CLASS_NAME_BUTTON_HIDE_TITLE : ''
    };
};

const mapDispatchToProps = (
    dispatch: any,
    props: ICopyCardHOCProps
): IAsidePanelActionsButtonProps => {
    const {cardId} = props;
    return {
        onClick: () => dispatch(onClick(cardId))
    }
};

export const CopyCardHOC = connect(
    mapStateToProps,
    mapDispatchToProps,
    (mapStateToProps, mapDispatchToProps) => ({...mapStateToProps, ...mapDispatchToProps})
    ) (AsidePanelActionsButton);
