import { IKanbanViewState } from './types';
import {
    AT_ACTIVITY_SCROLL_LIST_ID,
    AT_DRAGGABLE_CARD_ID,
    AT_HIGHLIGHT_CARD_ID,
    AT_MOVE_TO_OPENED_LIST_ID,
    AT_NEW_CARD_SET,
    AT_RECENTLY_WATCHED_CARD_ID,
    AT_SNOOZE_BLOCKER_CLOSED_SET,
    AT_SWIMLANES_UPDATE,
} from '../actions/constants';
import { TKanbanViewAction } from '../actions/types';
import { setSwimlanesLocalStorage } from '../localstorage/actions/setSwimlanesLocalStorage';
import { getInitialState } from './constants';
import { setNewCardLocalStorage } from '../localstorage/actions/setNewCardLocalStorage';

const initialState = getInitialState();

const _kanbanViewReducer = (
    state: IKanbanViewState,
    action: TKanbanViewAction
): IKanbanViewState => {
    switch (action.type) {
        case AT_MOVE_TO_OPENED_LIST_ID: {
            return {
                ...state,
                moveToOpenedListId: action.listId
            }
        }
        case AT_ACTIVITY_SCROLL_LIST_ID: {
            return {
                ...state,
                activityScrollListId: action.listId
            }
        }
        case AT_DRAGGABLE_CARD_ID: {
            return {
                ...state,
                draggableCardId: action.cardId
            }
        }
        case AT_SNOOZE_BLOCKER_CLOSED_SET: {
            return {
                ...state,
                snoozeBlockerClosed: action.snoozeBlockerClosed
            }
        }
        case AT_HIGHLIGHT_CARD_ID: {
            return {
                ...state,
                highlightCardId: action.cardId
            }
        }
        case AT_RECENTLY_WATCHED_CARD_ID: {
            return {
                ...state,
                recentlyWatchCardId: action.cardId
            }
        }
        case AT_SWIMLANES_UPDATE: {
            return {
                ...state,
                swimlanes: {
                    ...state.swimlanes,
                    ...action.swimlanes,
                }
            }
        }
        case AT_NEW_CARD_SET: {
            return {
                ...state,
                newCard: action.newCard
            }
        }
        default:
            return state;
    }
};

export const kanbanViewReducer = (
    state = initialState,
    action: TKanbanViewAction
) => {
    const newState = _kanbanViewReducer(state, action);
    if (newState !== state) {
        if (action.type === AT_SWIMLANES_UPDATE) {
            setSwimlanesLocalStorage(newState.swimlanes);
        }
        if (action.type === AT_NEW_CARD_SET) {
            setNewCardLocalStorage(newState.newCard);
        }
    }
    return  newState;
};
