import { Button, Icon } from 'kui';
import * as React from 'react';
import { markdown } from '../../../../../../../../helper/markdownHelper';
import { TCardId } from '../../../../../../../../types/types';
import {
    CARD_SECTION_SUBCARDS,
    CARD_SUBCARDS_DONE
} from '../../../../../../aside_panel/cardDetails/SubcardsSection/components/CardSubcardsSection/constants';
import { CARD_CLASS, CARD_CONTROL_CLASS } from '../../../../constants';
import { ICardSubcard, ICardSubcardsProps} from './types';
import './_CardSubcards.scss';
import { getMessages } from '../../../../../../../../store/constants';
import ReactHtmlParser from 'react-html-parser';

export function CardSubcards ({
    className,
    elements,
    isReadonly,
    setCardHeight,
    onSubcardLink,
    subcardsStats
}: ICardSubcardsProps) {
    const classElement = CARD_CLASS + '__subcards';
    const classTitle = classElement + '-title';
    const classItem = CARD_CLASS + '-subcard';
    const classColumn = classItem + '__col';
    const classColumnImg = classColumn + ' ' + classColumn + '--img';
    const classColumnName = classColumn + ' ' + classColumn + '--name';
    const classColumnProgress = classColumn + ' ' + classColumn + '--progress';
    const classItemNumber = classItem + '__number';
    const classProgress = classElement + '-progress';
    const classDoneIcon = classElement + '-done-icon';

    function Item ({
        cardNumber,
        fullName,
        icon,
        id,
        link,
        name,
        processingPercent,
    }: ICardSubcard) {
        function onSubcardLinkHandler(event: React.MouseEvent, cardId: TCardId) {
            event.preventDefault();
            onSubcardLink(cardId);
        }

        return (
            <Button
                className={`
                    ${classItem}
                    ${CARD_CONTROL_CLASS}
                `}
                href={link}
                tooltip={{
                    isNoEvents: true,
                    isNoWrap: true,
                    value: markdown(fullName)
                }}
                variant={'icon-text'}
                onClick={(event: React.MouseEvent) => onSubcardLinkHandler(event, id)}
            >
                <div className={classColumnImg}>
                    <Icon size={16} xlink={icon} />
                </div>
                <div className={classColumnName}>
                    {!!cardNumber &&
                        <span className={classItemNumber}>
                            {cardNumber}
                        </span>
                    }
                    <span>{ReactHtmlParser(markdown(name))}</span>
                </div>
                <div className={classColumnProgress}>
                    {processingPercent}%
                </div>
            </Button>
        );
    }

    React.useEffect(() => {
        setCardHeight();
    }, [elements]);

    const isDone = subcardsStats && subcardsStats.doneCount === subcardsStats.totalCount;

    return (
        <div
            className={`
                ${className}
                ${classElement}
            `}
        >
            <div className={classTitle}>
                <div className={classTitle}>
                    {CARD_SECTION_SUBCARDS}
                </div>
                {subcardsStats && !!subcardsStats.totalCount &&
                <div className={classProgress}>
                    {isDone
                        ? <>
                            <Icon
                                className={classDoneIcon}
                                size={16}
                                xlink={'progress'}
                            /> ({subcardsStats.doneCount} / {subcardsStats.totalCount})
                        </>
                        : getMessages().getText(
                            CARD_SUBCARDS_DONE,
                            null,
                            subcardsStats.donePercent,
                            subcardsStats.doneCount,
                            subcardsStats.totalCount
                        )
                    }
                </div>
                }
            </div>
            {elements.map(subcard => {
                return <Item
                    {...subcard}
                    key={subcard.id}
                />
            })}
        </div>
    );
}
