import * as React from 'react';
import { ESettingsSectionType, ISettingsSectionProps } from './types';
import './_SettingsSection.scss';
import { Button, Icon, Tooltip } from 'kui';
import { getParentsClasses } from '../../../helpers/getParentsClasses';
import { v4 as uuidv4 } from 'uuid';
import { CLASS_ASIDE_PANEL_ACTIONS_POPUP } from '../../../aside_panel/asidePanel/components/AsidePanelActionsButton/constants';
import { getAsidePanelTooltip } from '../../../aside_panel/asidePanel/helpers/getAsidePanelTooltip';
import { AsidePanelContext } from '../../../aside_panel/asidePanel/components/AsidePanel/constants';
import { CLASS_SETTINGS_SECTION } from './constants';
import { useCombinedRefs } from '../../../helpers/useCombinedRefs';
import { escape } from 'underscore';
import { ProFeatureIndicator } from '../ProFeatureIndicator/ProFeatureIndicator';
import { NewFeatureIndicator } from '../NewFeatureIndicator/NewFeatureIndicator';
import { BasicFeatureIndicator } from '../BasicFeatureIndicator/BasicFeatureIndicator';

export const SettingsSection = React.forwardRef(({
    addButton,
    addComponent,
    addIcon,
    addTitle,
    children,
    className,
    closeIcon,
    focusSelector,
    isAddWide,
    isBasicFeature,
    isNewFeature,
    isOpened,
    isProFeature,
    isRequired,
    isRequiredError,
    sectionType,
    title,
    titleButton,
    titleSub,
    onAddClosed,
    onAddOpened,
    onClickDisallow,
    onClickTitle,
    isTitleTooltip
}: ISettingsSectionProps, ref) => {
    const isCollapse = sectionType === ESettingsSectionType.COLLAPSE;
    const isDefault = sectionType === ESettingsSectionType.DEFAULT;
    const isDisallow = sectionType === ESettingsSectionType.DISALLOW;
    const isPopup = sectionType === ESettingsSectionType.POPUP;
    const isReadonly = sectionType === ESettingsSectionType.READONLY;

    if (!addIcon) addIcon = isCollapse ? 'arrow-down' : 'plus-circle';
    if (!closeIcon) closeIcon = isCollapse ? 'arrow-up' : 'close';

    if (!children && !isPopup) {
        return null;
    }

    const {isMobile} = React.useContext(AsidePanelContext);

    const [classUnique] = React.useState(CLASS_SETTINGS_SECTION + '--' + uuidv4());
    const classDisallow = isDisallow ? CLASS_SETTINGS_SECTION + '--disallow' : '';
    const classNameTitle = CLASS_SETTINGS_SECTION + '__title';
    const classNameRequired = CLASS_SETTINGS_SECTION + '--required';
    const classNameError = CLASS_SETTINGS_SECTION + '--error';
    const classNameTitleColumn = CLASS_SETTINGS_SECTION + '__title-column';
    const classNameTitleColumnText = classNameTitleColumn + ' ' + classNameTitleColumn + '--text';
    const classNameTitleColumnSub = classNameTitleColumn + ' ' + classNameTitleColumn + '--subtitle';
    const classNameTitleColumnActions = classNameTitleColumn + ' ' + classNameTitleColumn + '--actions';
    const classNameTitleAdd = CLASS_SETTINGS_SECTION + '__title-add';
    const classNameClose = CLASS_SETTINGS_SECTION + '__close';
    const classNameAddClose = CLASS_SETTINGS_SECTION + '__add-close';
    const classNameAddWide = CLASS_SETTINGS_SECTION + '__add-wide';
    const classNameAddWideBg = classNameAddWide + '--bg';
    const classNameBody = CLASS_SETTINGS_SECTION + '__body';
    const classNameAdd = CLASS_SETTINGS_SECTION + '__add';
    const classNameDisallow = CLASS_SETTINGS_SECTION + '__disallow';

    const [isOpenedHook, setIsOpened] = React.useState(!!isOpened);
    const [isOpenedAnimation, setIsOpenedAnimation] = React.useState(!!isOpened);
    const sectionRef = React.useRef(null);
    const animationTimer = React.useRef(null);

    React.useEffect(() => {
        if (isOpenedHook) {
            openAddComponent();
        }
    }, []);

    const classNameOpened = isOpenedHook ? CLASS_SETTINGS_SECTION + '--opened' : '';
    const classNameEmpty = !children ? CLASS_SETTINGS_SECTION + '--empty' : '';
    const classNameReadonly = isReadonly ? CLASS_SETTINGS_SECTION + '--readonly' : '';
    const classNameCollapse = isCollapse ? CLASS_SETTINGS_SECTION + '--collapse' : '';

    function openAddComponent() {
        if (isDisallow) return;

        setIsOpened(true);
        if (onAddOpened) onAddOpened();
        if (isMobile && sectionRef.current) {
            sectionRef.current.scrollIntoView({block: 'start'});
        }
        requestAnimationFrame(() => {
            if (focusSelector && sectionRef.current) {
                const focus = sectionRef.current.querySelector(focusSelector);
                if (focus) focus.focus();
            }
        });
    }

    function closeAddComponent() {
        if (onAddClosed) onAddClosed();
        setIsOpened(false);
    }

    function onBlur(event: React.FocusEvent) {
        if (
            !isOpenedHook ||
            !document.hasFocus()
        ) return;

        const targetClasses = getParentsClasses(
            event.relatedTarget as HTMLElement,
            [
                CLASS_SETTINGS_SECTION,
                CLASS_ASIDE_PANEL_ACTIONS_POPUP
            ]
        );
        if (
            targetClasses.includes(classUnique) ||
            targetClasses.includes(CLASS_ASIDE_PANEL_ACTIONS_POPUP)
        ) {
            return;
        }

        closeAddComponent();
    }

    const blurAttributes = isDefault ? {
        onBlur
    } : {};

    const isShowTitlePlus = !!addComponent && (isCollapse || isDefault || isDisallow);
    const addTooltip = isMobile
        ? null
        : getAsidePanelTooltip({
            value: addTitle,
        });

    React.useEffect(() => {
        if (isOpened) {
            if (!isOpenedHook) {
                openAddComponent();
            }
        } else if (isOpened === false) {
            closeAddComponent();
        }
        animationTimer.current = setTimeout(() => setIsOpenedAnimation(isOpened), 300);
    }, [isOpened]);

    React.useEffect(() => {
        return () => {
            if (animationTimer.current) clearTimeout(animationTimer.current);
        }
    }, []);

    return (
        <div
            className={`
                ${CLASS_SETTINGS_SECTION}
                ${classDisallow}
                ${classUnique}
                ${classNameEmpty}
                ${classNameOpened}
                ${classNameReadonly}
                ${classNameCollapse}
                ${className}
                ${isRequired ? classNameRequired : ''}
                ${isRequiredError ? classNameError : ''}
            `}
            ref={useCombinedRefs(ref, sectionRef)}
            tabIndex={-1}
            {...blurAttributes}
        >
            <div className={classNameTitle}>
                <Tooltip
                    value={isTitleTooltip ? escape(title) : null}
                    isNoEvents={true}>
                    <div className={classNameTitleColumn + '-text-wrapper'}>
                        <div
                            className={classNameTitleColumnText}
                            onClick={onClickTitle}>
                            <div className={classNameTitleColumn + '-text'}>{title}</div>
                            {isNewFeature && <NewFeatureIndicator />}
                        </div>
                        {isProFeature && <ProFeatureIndicator/>}
                        {isBasicFeature && <BasicFeatureIndicator />}
                    </div>
                </Tooltip>
                {!!titleSub &&
                <div className={classNameTitleColumnSub}>
                    {titleSub}
                </div>
                }
                <div className={classNameTitleColumnActions}>
                    {titleButton}
                    {(!!addComponent || isPopup) && // в попапе нужен close
                    <>
                        <div
                            className={classNameAddClose}
                        >
                            {!isPopup && isShowTitlePlus && (!isOpenedHook || !isOpenedAnimation) &&
                            <Button
                                className={classNameTitleAdd}
                                variant={'icon'}
                                onClick={openAddComponent}
                                tooltip={addTooltip}
                            >
                                {addButton ||
                                <Icon size={24} xlink={addIcon}/>
                                }
                            </Button>
                            }
                            {(isPopup || isOpenedHook || isOpenedAnimation) &&
                            <Button
                                className={classNameClose}
                                variant={'icon'}
                                onClick={closeAddComponent}
                            >
                                <Icon size={24} xlink={closeIcon}/>
                            </Button>
                            }
                        </div>
                        {!isReadonly && isAddWide &&
                            <>
                                <div
                                    className={classNameAddWide}
                                    onClick={openAddComponent}
                                />
                                <div className={classNameAddWideBg}/>
                            </>
                        }
                        {isDisallow &&
                            <div
                                className={classNameDisallow}
                                onClick={onClickDisallow}
                            />
                        }
                    </>
                    }
                </div>
            </div>
            <div className={classNameBody}>
                {!!addComponent && !isReadonly && isOpenedHook &&
                <div
                    className={classNameAdd}
                >
                    {addComponent}
                </div>
                }
                {children}
                {isDisallow &&
                    <div
                        className={classNameDisallow}
                        onClick={onClickDisallow}
                    />
                }
            </div>
        </div>
    );
});
SettingsSection.displayName = 'SettingsSection';
