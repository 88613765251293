import { AT_NBD_ACTION_SET, INotificationBeforeDueDateEditActionSetAction } from './types';
import { TNotificationBeforeDueDateEditAction } from '../../notificationBeforeDueDateCardEditState/actions/types';
import { TCardId } from '../../../../../../../../../types/types';

export const notificationBeforeDueDateEditActionSetAction = (
    cardId: TCardId,
    notificationBeforeDueDateEditAction: TNotificationBeforeDueDateEditAction
): INotificationBeforeDueDateEditActionSetAction => {
    return {
        type: AT_NBD_ACTION_SET,
        cardId,
        notificationBeforeDueDateEditAction
    }
};
