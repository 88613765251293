import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState } from '../../../../../../../types/types';
import { root } from '../../../../../../../store/constants';
import { segmentTrackAction } from '../../../../../../../middlewares/segment';
import { WebinarQuestionEvent } from '../../../../../../../middlewares/segment/trackEntities/webinarQuestionEvent';

export const onDidMount = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        root.App.controller.htmlScrollDisabledToggle(true);
        dispatch(segmentTrackAction(WebinarQuestionEvent.WEBINAR_SHOWN));
    };
    return action;
};
