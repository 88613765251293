import { IApplicationState } from '../../../../../types/types';
import { ENavigationPanelTypes } from '../constants';
import { navigationPanelTypeSelector } from '../store/selectors/navigationPanelTypeSelector';
import { hasOpenedBoardSelector } from '../../../../../store/model/selectors/hasOpenedBoard';

export const getCurrentNavigationPanelType = (
    state: IApplicationState
): ENavigationPanelTypes => {
    const hasOpenBoard = hasOpenedBoardSelector(state);
    let navigationPanelType = navigationPanelTypeSelector(state);
    if (!hasOpenBoard &&
        navigationPanelType === ENavigationPanelTypes.NP_COMPACT_TYPE
    ) {
        return ENavigationPanelTypes.NP_BIG_TYPE;
    }
    if (navigationPanelType === 'small' as keyof ENavigationPanelTypes) { // delete small
        return ENavigationPanelTypes.NP_BIG_TYPE;
    }
    return navigationPanelType;
};
