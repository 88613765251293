import { IApplicationState } from '../../../../../types/types';
import { TCondition } from '../../types';
import { EHintKey, EHintStatus, getHintConfig } from '../../constants';
import { getHintCanShow } from '../../store/hint/selectors/getHintCanShow';
import { getShowGanttContextHints } from './getShowGanttContextHints';
import { getHint } from '../../store/hints/selectors/getHint';

export const ganttContextAddDate: TCondition = (
    state: IApplicationState
) => {
    const hint = getHintConfig(EHintKey.GANTT_CONTEXT_ADD_DATE);
    if (!hint) return false;

    if (
        !getShowGanttContextHints(state) ||
        getHint(state, EHintKey.GANTT_CONTEXT_GANTT_TYPE_SWITCHER).status === EHintStatus.SHOWN ||
        getHint(state, EHintKey.GANTT_CONTEXT_GANTT_VIEW).status === EHintStatus.SHOWN ||
        getHint(state, EHintKey.GANTT_CONTEXT_GANTT_VIEW_RESIZE).status === EHintStatus.SHOWN ||
        getHint(state, EHintKey.GANTT_CONTEXT_SHOW_ON_GANTT).status === EHintStatus.SHOWN
    ) return false;

    return !!getHintCanShow(state, EHintKey.GANTT_CONTEXT_ADD_DATE);
};
