import { IGetState, TBoardId, TCardId } from '../../../../types/types';
import { Action } from 'redux';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { getBoardByCardId } from '../../../../store/model/selectors/getBoardByCardId';
import { getBoard } from '../../../../store/model/selectors/getBoardById';
import { getAuthUser } from '../../../../store/model/authUser/selectors/getAuthUser';
import { Util } from '../../../../util/util';
import { updateUser } from '../../../../store/model/authUser/effects/updateUser';
import { IRestCard } from '../../../../types/rest/IRestCard';
import { IAuthUser } from '../../../../store/model/authUser/types';
import { SocketFactory } from '../../../../helper/realtime/ts/SocketFactory';
import { RoomFactory } from '../../../../helper/realtime/ts/RoomFactory';

export const RAT_REALTIME_ACTION_SEND = 'REALTIME_ACTION_SEND';

export enum ERealtimeClassKeys {
    STORE_ACTION = 'store_action',
    CARD = 'card',
    AUTH_USER = 'AUTH_USER',
    VOIN_OCCUPY = 'voinOccupy',
    USER_BOARD_ACTIVITY_TIME_UPDATE = 'userDashboardActivityTimeUpdate',
    SUBSCRIPTION = 'subscriptionUpdate',
    STARRED = 'STARRED',
}

const sendToBoardSocket = (
    boardId: TBoardId,
    cometToken: string,
    classKey: ERealtimeClassKeys,
    data: object
) => {
    if (!boardId || !cometToken) return;
    const listener = {};
    const newDashboardSocket = RoomFactory.getInstance().getDashboardRoom(boardId, listener, cometToken);
    newDashboardSocket.emit({
        classKey,
        ...data
    });
    newDashboardSocket.removeListener(listener)
}

const sendToAuthUserSocket = (
    classKey: ERealtimeClassKeys,
    data: object
): ThunkAction => {
    return (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const authUser = getAuthUser(getState());
        const listener = {};
        const newDashboardSocket = RoomFactory.getInstance().getUserRoom(authUser.id, listener, authUser.cometToken);
        newDashboardSocket.emit({
            classKey,
            ...data
        });
        newDashboardSocket.removeListener(listener)
    }
}

export const sendRealTimeStoreAction = (
    boardId: TBoardId,
    cometToken: string,
    action: Action
) => {
    sendToBoardSocket(boardId, cometToken, ERealtimeClassKeys.STORE_ACTION, { action });
};

export const sendRealTimeStoreActionByBoardId = (
    boardId: TBoardId,
    action: Action
): ThunkAction => {
    return (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const board = getBoard(getState(), boardId);
        sendRealTimeStoreAction(board.id, board.cometToken, action);
    };
};

export const sendRealTimeStoreActionByCardId = (
    cardId: TCardId,
    action: Action
): ThunkAction => {
    return (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const board = getBoardByCardId(getState(), cardId);
        if (!board) return;
        sendRealTimeStoreAction(board.id, board.cometToken, action);
    };
};

export const sendRealTimeVoinOccupy = (
    boardId: TBoardId,
    cardId: TCardId,
    prevCardId: TCardId = null
): ThunkAction => {
    return (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const board = getBoard(getState(), boardId);
        const authUser = getAuthUser(getState());
        let collaboratorColor = authUser.collaboratorColor;
        if (!collaboratorColor) { // TODO probably move this to init auth user in store
            collaboratorColor = Util.getRandomColor();
            dispatch(updateUser({ collaboratorColor }))
        }
        const sessionId = SocketFactory.getInstance().getSocketProviderId();
        sendToBoardSocket(boardId, board.cometToken, ERealtimeClassKeys.VOIN_OCCUPY, {
            model: {
                cardId,
                prevCardId,
                collaborator: {
                    id: sessionId + '_' + authUser.id,
                    sessionId,
                    userId: authUser.id,
                    displayName: authUser.fullName,
                    color: collaboratorColor,
                    photoUrl: authUser.photoUrl
                }
            }
        });
    }
}

export const sendRealTimeRestCard = (
    boardId: TBoardId,
    cometToken: string,
    card: IRestCard
) => {
    sendToBoardSocket(boardId, cometToken, ERealtimeClassKeys.CARD, { model: card })
};

export const sendAuthUserRealTimeStoreAction = (
    action: Action
): ThunkAction => {
    return (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (!action.type) {
            console.error('Wrong realtime action type');
            return;
        }
        dispatch(sendToAuthUserSocket(ERealtimeClassKeys.STORE_ACTION, { action }));
    };
};

export const sendRealTimeAuthUserUpdate = (
    authUserUpdate: IAuthUser
): ThunkAction => {
    return (
        dispatch: Dispatch,
    ) => {
        dispatch(sendToAuthUserSocket(ERealtimeClassKeys.AUTH_USER, { authUserUpdate }));
    }
};

export const sendRealTimeStarredUpdate = (
    model: any
): ThunkAction => {
    return (
        dispatch: Dispatch,
    ) => {
        dispatch(sendToAuthUserSocket(ERealtimeClassKeys.STARRED, { model }));
    }
};
