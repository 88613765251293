import { IGetState, TCardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { cardDelete } from '../../../../rest/effects/card/card/cardDelete';
import { getMessages } from '../../../../store/constants';
import { getCard } from '../../../../store/model/selectors/getCard';
import { SNACKBAR_CARDS_DELETE_TEXT } from '../../aside_panel/multipleCards/Actions/ActionsSection/hocs/ActionsHOC/constants';
import { SNACKBAR_CARD_DELETE_ID } from '../../aside_panel/cardDetails/DeleteButtonSection/hocs/DeleteButtonHOC/constants';
import { SNACKBAR_BUTTON_ARCHIVE } from '../../snackbarsStack/effects/constants';
import { snackbarUndoDefault } from '../../snackbarsStack/effects/variantUndo/snackbarUndoDefault';
import { snackbarUndoWithAlternativeAction } from '../../snackbarsStack/effects/variantUndo/snackbarUndoWithAlternativeAction';
import { cardsUpdate } from '../../../../store/model/actionCreators/cardsUpdate';
import { ICards } from '../../../../store/model/cards/types';
import { TStatus } from '../../../../types/model';
import { closePanel } from '../../aside_panel/asidePanel/effects/closePanel';
import { recurringTasksOpen } from './recurringTasksOpen';
import { cardTemplatesOpen } from './cardTemplatesOpen';
import { getAuthUser } from '../../../../store/model/authUser/selectors/getAuthUser';
import { cardsArchive } from '../../../../rest/effects/card/card/cardsArchive';
import { SegmentCardEvent, segmentTrackAction } from '../../../../middlewares/segment';

export const cardsDeleteWithPrompt = (
    cardIds: TCardId[] = [],
    recurringTasksCount: number = null,
    positionLeft: boolean = true
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        return new Promise<void>((resolve, reject) => {
            if (recurringTasksCount === null) {
                dispatch(closePanel());
            } else {
                recurringTasksCount
                    ? dispatch(recurringTasksOpen())
                    : dispatch(cardTemplatesOpen());
            }

            const cardForUndo: ICards = {};
            const cardForUpdate: ICards = {};
            let isAnyCardArchived: boolean = false;
            cardIds.forEach(cardId => {
                const card = getCard(state, cardId);
                if (!card) throw new Error('Unknown cardId');
                cardForUndo[cardId] = {
                    status: card.status
                };
                cardForUpdate[cardId] = {
                    status: TStatus.STATUS_DELETED
                }
                if(card.status === TStatus.STATUS_ARCHIVE)
                    isAnyCardArchived = true;
            })
            dispatch(cardsUpdate(cardForUpdate));

            const actionApply = () => {
                try {
                    cardIds.forEach((cardId) => {
                        dispatch(cardDelete(cardId));
                        dispatch(segmentTrackAction(SegmentCardEvent.DELETE_CARD_PROCEED));
                    })
                } catch (e) {
                    return reject(e);
                }
                resolve();
            };

            const actionUndo = () => {
                dispatch(cardsUpdate(cardForUndo));
                dispatch(segmentTrackAction(SegmentCardEvent.DELETE_CARD_CANCELED));
            }

            const actionAlternative = () => {
                dispatch(cardsArchive(cardIds));
                dispatch(segmentTrackAction(SegmentCardEvent.ARCHIVE_ON_DELETE_CARD_PROCEED));
            };

            const {allowArchive} = getAuthUser(getState());
            const actionTimer = () => {
                dispatch(segmentTrackAction(SegmentCardEvent.DELETE_CARD_TIMEOUT));
            };
            if (allowArchive && !isAnyCardArchived) {
                dispatch(snackbarUndoWithAlternativeAction({
                    id: SNACKBAR_CARD_DELETE_ID,
                    text: getMessages().getText(SNACKBAR_CARDS_DELETE_TEXT, null, cardIds.length),
                    actionApply,
                    actionUndo,
                    actionAlternative,
                    actionTimer,
                    alternativeButton: SNACKBAR_BUTTON_ARCHIVE
                }, positionLeft))
            } else {
                dispatch(snackbarUndoDefault({
                    id: SNACKBAR_CARD_DELETE_ID,
                    text: getMessages().getText(SNACKBAR_CARDS_DELETE_TEXT, null, cardIds.length),
                    actionApply,
                    actionUndo,
                    actionTimer
                }, positionLeft))
            }
        });
    };
    return action;
};
