import { EVENT_KEY_BACKSPACE, EVENT_KEY_DELETE } from '../../../../const';
import { CLASS_HTML_EDITOR } from '../components/HtmlEditor/constants';
import { getParentsClasses } from '../../helpers/getParentsClasses';

export const isKeyShiftDelete = (
    e: KeyboardEvent
): boolean => {
    const isMac = navigator.platform.indexOf('Mac') > -1;

    if (
        e.altKey ||
        e.ctrlKey ||
        isMac ? !e.metaKey : !e.shiftKey
    ) return false;

    const targetElement = e.target as HTMLElement;
    const targetTagName = targetElement && targetElement.tagName
        ? targetElement.tagName.toLowerCase() : '';
    const parentClasses = getParentsClasses(targetElement, [
        CLASS_HTML_EDITOR
    ]);
    if (
        'textarea' === targetTagName ||
        'input' === targetTagName ||
        parentClasses.includes(CLASS_HTML_EDITOR)
    ) return false;

    return isMac ? EVENT_KEY_BACKSPACE === e.key : EVENT_KEY_DELETE === e.key;
}
