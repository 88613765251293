import * as React from 'react';
import { ITrialExtendButtonProps } from './types';
import { Button } from 'kui';
import './_TrialExtendButton.scss';
import { TRIAL_EXTEND_BUTTON } from './constants';

export function TrialExtendButton ({
    onClick
}: ITrialExtendButtonProps) {
    return (
        <Button
            className={'trial-extend__button'}
            variant={'text'}
            onClick={onClick}
        >
            {TRIAL_EXTEND_BUTTON}
        </Button>
    );
}
