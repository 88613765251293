'use strict';
import * as _ from 'underscore';
App.Mixins.Test = {
    isDevEnv: function() {
        return window.Settings.development && Boolean(App.Util.getCookie('TEST_ENV'));
    },

    render: function() {
        if (this.isDevEnv()) {
            this.testDataUpdate();
        }
    },

    testDataUpdate: function() {
        var data = this.testAttributes();
        if (this.isDevEnv() && _.keys(data).length > 0) {
            _.keys(data).forEach(_.bind(function(key) {
                if(_.isFunction(data[key])){
                    this.$el.attr('data-' + key, data[key]());
                } else {
                    this.$el.attr('data-' + key, data[key]);
                }
            }, this));
        }
    }
};
export default App.Mixins.Test;
