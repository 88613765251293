import { root } from '../../../../../../../store/constants';
import {
    NBD_GA_NOTIFICATION_CATEGORY,
    NBD_GA_NOTIFICATION_CHANGED,
    NBD_GA_NOTIFICATION_SET,
} from '../constants/constants';

export const notificationBeforeDueDateSet = (
) => {
    root.App.controller.trackEvent(
        root.Messages.getText(NBD_GA_NOTIFICATION_CATEGORY), root.Messages.getText(NBD_GA_NOTIFICATION_SET)
    );
};

export const notificationBeforeDueDateChanged = (
) => {
    root.App.controller.trackEvent(
        root.Messages.getText(NBD_GA_NOTIFICATION_CATEGORY), root.Messages.getText(NBD_GA_NOTIFICATION_CHANGED)
    );
};
