import { authUserSetRateFeaturePoll } from '../../../../../rest/effects/authUser/authUserSetRateFeaturePoll';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { IGetState } from '../../../../../types/types';
import { getUserRateFeaturePoll } from '../../../../../store/model/authUser/selectors/getUserRateFeaturePoll';
import { ERateFeature } from '../../../../../types/rest/IUserMeta';

export const addFeatureTrigger = (
    triggeredFeatureId: ERateFeature,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const featurePoll = getUserRateFeaturePoll(getState());
        if (
            featurePoll.answeredFeaturePolls &&
            featurePoll.answeredFeaturePolls.includes(triggeredFeatureId)
        ) {
            return;
        }
        dispatch(authUserSetRateFeaturePoll({
            ...featurePoll,
            triggeredFeatureId
        }));
    };
    return action;
};
