import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TFilterId } from '../../../../../../../../types/types';
import {
    onToggleFilter
} from '../../../../../../aside_panel/filterPanelBoard/components/SavedSection/hocs/SavedFiltersListItemHOC/events/onToggleFilter';
import { MY_WORK_FILTER_BOARD_ID } from '../../../../../../aside_panel/filterPanelBoard/constants';
import {
    filterPanelActionSetAction
} from '../../../../../../aside_panel/filterPanelBoard/store/filterPanelBoards/actions/filterPanelActionSetAction';
import { enabledSet } from '../../../../../../aside_panel/filterPanelBoard/store/filterPanel/actions/enabledSet';

export const onToggle = (
    filterId: TFilterId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(filterPanelActionSetAction(MY_WORK_FILTER_BOARD_ID, enabledSet(true)));
        dispatch(onToggleFilter(MY_WORK_FILTER_BOARD_ID, filterId));
    }
    return action;
}
