import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { root } from '../../../../../../../store/constants';
import { isThereFilterInRouteSelector } from '../../../../../aside_panel/filterPanelBoard/store/selectors/isThereFilterInRouteSelector';
import { getIsAssignedToMeActive } from '../../../../../../../store/router/selectors/getIsAssignedToMeActive';
import { IGetState } from '../../../../../../../types/types';

export const toggleFilter = (): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (root.App.controller.isRelatedPanelNotOpened()) {
            if (isThereFilterInRouteSelector()) {
                root.App.router.navigate(root.App.router.getBaseUrl(), {trigger: true});
            } else {
                if (getIsAssignedToMeActive(getState())) {
                    root.App.router.navigate(root.App.router.getAssignedToMeUrl('filter'), { trigger: true });
                } else {
                    root.App.router.navigate(root.App.router.getUrl('filter'), { trigger: true });
                }
            }
        }
    };
    return action;
};
