import {IApplicationState, TBoardId} from '../../../../types/types';
import {IList} from '../../list/types';
import {getListsByBoardId} from './getListsByBoardId';
import {TStatus} from 'app/types/model';

export const getFirstListInBoard = (
    state: IApplicationState,
    boardId: TBoardId
): IList => {
    const boardLists = getListsByBoardId(state, boardId);
    let firstList: IList = null;
    boardLists.forEach(list => {
        if(list.status !== TStatus.STATUS_SERVICE_TEMPLATE) {
            if(firstList === null || list.orderNumber < firstList.orderNumber) {
                firstList = list;
            }
        }
    })
    return firstList;
}
