import { dispatch } from 'app/store/configureStore';
import { root } from '../../../store/constants';
import { SegmentUserEvent, segmentTrackAction } from 'app/middlewares/segment';

export type TRoomId = string;
export type TToken = string;
export type TMessage = any;

export interface IMessageData {
    room: TRoomId;
    message: TMessage;
    time: number;
}

export type TMessageDataList = Array<IMessageData>;

export interface IMessageStartMessageTime {
    room: TRoomId;
    lastMessageTime: number;
}

export type TMessagesStartMessageTime  = Array<IMessageStartMessageTime>;

export interface IMessageIncorrectToken {
    room: TRoomId;
}

type IRoomsListeners = {
    [roomId: string]: IRoomProviderSubscriber;
};

export class SocketProvider {
    socket: any;
    serverUrl: string;
    private roomsListeners: IRoomsListeners = {};
    reload: boolean;

    constructor( serverUrl: string, reload: boolean) {
        this.reload = reload;
        this.serverUrl = serverUrl;
        if (root._debug.websocket) {
            console.log(`constructor for ${this.serverUrl}`);
        }
        // @ts-ignore
        this.socket = io.connect(serverUrl, {forceNew: true});
        this.socket.on('connect', () => {
            if (root._debug.websocket) {
                console.log(`connect for ${this.serverUrl}`);
            }
            Object.values(this.roomsListeners).forEach( (listener) => listener.onSocketConnect());
        });

        this.socket.on('setStartMessageTime', (messages: TMessagesStartMessageTime) => {
            if (root._debug.websocket) {
                console.log(`setStartMessageTime for ${this.serverUrl}`, messages);
            }
            messages.forEach( message => {
                this._sendStartMessageTime(message);
            })
        });

        this.socket.on('message', (message: IMessageData) => {
            if (root._debug.websocket) {
                console.log(`message for ${this.serverUrl}`, message);
            }
            this._sendMessage(message);
        });

        this.socket.on('missedMessages', (messages: TMessageDataList) => {
            if (root._debug.websocket) {
                console.log(`missedMessages for ${this.serverUrl}`, messages);
            }
            messages.forEach( message => {
                this._sendMessage(message);
            });
        });

        this.socket.on('hasDeletedMissedMessages', (rooms: Array<TRoomId>) => {
            console.log('hasDeletedMissedMessages :', rooms);
            if (this.reload) {
                dispatch(segmentTrackAction(SegmentUserEvent.RELOAD_FROM_COMET));
                setTimeout(() => {
                    location.reload();
                }, 3000);
            }
        });

        this.socket.on('incorrectToken', (message: IMessageIncorrectToken) => {
            if (root._debug.websocket) {
                console.log(`incorrectToken for ${this.serverUrl}`, message);
            }
            this._sendIncorrectToken(message);
        });
    }

    private _sendIncorrectToken(message: IMessageIncorrectToken) {
        if (this.roomsListeners[message.room]){
            this.roomsListeners[message.room].onIncorrectToken();
        } else {
            console.trace(`message for unknown room ${message.room}`, message);
            this.exitFromRoom(message.room);
        }
    }

    private _sendStartMessageTime(message: IMessageStartMessageTime) {
        if (this.roomsListeners[message.room]){
            this.roomsListeners[message.room].setStartMessageTime(message.lastMessageTime);
        } else {
            console.trace(`message for unknown room ${message.room}`, message);
            this.exitFromRoom(message.room);
        }
    }

    private _sendMessage(message: IMessageData) {
        if (this.roomsListeners[message.room]){
            this.roomsListeners[message.room].onMessage(message.message);
            this.roomsListeners[message.room].setStartMessageTime(message.time);
        } else {
            console.trace(`message for unknown room ${message.room}`, message);
            this.exitFromRoom(message.room);
        }
    }

    private _addRoomProviderListener (roomId: TRoomId, listener: IRoomProviderSubscriber) {
        if (listener ){
            if (this.roomsListeners[roomId] && this.roomsListeners[roomId] !== listener){
                console.log(`Other listener for  ${roomId} already registered for${this.serverUrl}`, this.roomsListeners[roomId],  listener );
                throw `Other listener for  ${roomId} already registered for${this.serverUrl}`;
            } else {
                this.roomsListeners[roomId] = listener;
            }
        }
        return this;
    }

    private _removeRoomProviderListener (roomId: TRoomId) {
        delete this.roomsListeners[roomId];
    }

    emitToRoom(roomId: TRoomId, data: any) {
        if (root._debug.websocket) {
            console.log(`emitToRoom for ${this.serverUrl} for room: ${roomId}`, data);
        }
        this.socket.emit('send', {/*onlyLog: true, */room: roomId, message: data});
    };

    exitFromRoom(roomId: TRoomId) {
        if (root._debug.websocket) {
            console.log(`exitFromRoom for ${this.serverUrl} for room: ${roomId}`);
        }
        this._removeRoomProviderListener(roomId);
        this.socket.emit('unsubscribe', roomId);
    };

    subscribeToRoom(roomId: TRoomId, token: TToken, lastMessageTime: number, isBoardRoom: boolean, listener: IRoomProviderSubscriber) {
        if (root._debug.websocket) {
            console.log(`subscribeToRoom for ${this.serverUrl} for room: ${roomId}`);
        }
        this._addRoomProviderListener(roomId, listener);
        this.socket.emit('subscribe', {
            room: roomId,
            lastMessageTime: lastMessageTime,
            inArmy: isBoardRoom,
            token: token
        });
    };

}

export interface IRoomProviderSubscriber{
    onMessage: (message: IMessageData) => void;
    onSocketConnect: () => void;
    setStartMessageTime: (time: number) => void
    onIncorrectToken: () => void;
}
