import { getTabsStatePropsCreateSelector } from './../../helpers/tabsHelper';
import { connect } from 'react-redux';
import { onChangeTab } from './events/onChangeTab';
import { IApplicationState } from '../../../../../../types/types';
import { AsidePanelTabs } from '../../components/AsidePanelTabs/AsidePanelTabs';
import { IAsidePanelTabsDispatchProps, IAsidePanelTabsStateProps } from '../../components/AsidePanelTabs/types';
import { IAsidePanelTabsHOCOwnProps } from './types';

const mapStateToProps = () => {
    const getTabsStateProps = getTabsStatePropsCreateSelector();

    return (
        state: IApplicationState,
        props: IAsidePanelTabsHOCOwnProps
    ): IAsidePanelTabsStateProps => {
        const { activeTabIndex, tabs: tabsProps } = props;
        const tabs = getTabsStateProps(tabsProps);

        return {
            activeTabIndex,
            tabs
        }
    };
};

const mapDispatchToProps = (
    dispatch: any,
    ownProps: IAsidePanelTabsHOCOwnProps
): IAsidePanelTabsDispatchProps => {
    const { tabs, onTabChange } = ownProps;
    return {
        onChange: (
            index,
            prevIndex,
            cardId
        ) => dispatch(onChangeTab(index, prevIndex, cardId, onTabChange, tabs))
    }
};

export const AsidePanelTabsHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(AsidePanelTabs);

AsidePanelTabsHOC.displayName = 'AsidePanelTabsHOC';
