import { IHintConfig } from './types';
import { IHints } from './store/hints/types';
import { topBarFiltrationCondition } from './conditions/topBarFiltrationCondition';
import { newCardsCountCondition } from './conditions/newCardsCountCondition';
import { defaultCardTimeCondition } from './conditions/defaultCardTimeCondition';
import { autoStartDateCondition } from './conditions/autoStartDateCondition';
import { attachmentViewGridCondition } from './conditions/attachmentViewGridCondition';
import { myWorkImportCardsUpdatesCondition } from './conditions/myWorkImportCardsUpdatesCondition';
import { myWorkFilterBarCondition } from './conditions/myWorkFilterBarCondition';
import { openedLastBoardCondition } from './conditions/openedLastBoardCondition';
import { attachmentsListCondition } from './conditions/attachmentsListCondition'
import { cardLinkCondition } from 'app/view/react_components/hints/conditions/cardLinkCondition';
import { ganttContextAddDate } from './conditions/ganttContextConditions/ganttContextAddDate';
import { ganttContextShowOnGantt } from './conditions/ganttContextConditions/ganttContextShowOnGantt';
import { ganttContextGanttTypeSwitcher } from './conditions/ganttContextConditions/ganttContextGanttTypeSwitcher';
import { ganttContextGanttView } from './conditions/ganttContextConditions/ganttContextGanttView';
import { boardScrollCondition } from './conditions/boardScrollCondition';
import { quickStartGuideCondition } from './conditions/quickStartGuideCondition';
import { ganttContextGanttViewResize } from './conditions/ganttContextConditions/ganttContextGanttViewResize';

export const HINTS_PORTAL_ID = 'hints-portal';
export const REST_HINTS = '/rest/hints';
export const HINTS_INITIAL: IHints = null;
export const TUTORIAL_STEP_DELAY = 200; // ms

export enum EHintKey {
    BOARD_SETTINGS = 0,
    TUTORIAL_FIRST = 1,
    TOP_BAR_FILTER_ICONS = 2,
    NEW_CARD_COUNT = 3,
    DEFAULT_CARD_TIME = 4,
    OPENED_LAST_BOARD = 5,
    AUTO_START_DATE = 6,
    ATTACHMENT_GRID_VIEW = 7,
    NEW_COLOR_TAGS = 8,
    ATTACHMENTS_LIST = 9,
    MY_WORK_IMPORT_CARDS_UPDATES = 10,
    MY_WORK_FILTER_BAR = 11,
    CARD_LINK = 12,
    GANTT_CONTEXT_SHOW_ON_GANTT = 13,
    GANTT_CONTEXT_ADD_DATE = 14,
    GANTT_CONTEXT_GANTT_TYPE_SWITCHER = 15,
    GANTT_CONTEXT_GANTT_VIEW = 16,
    GANTT_CONTEXT_GANTT_VIEW_RESIZE = 17,
    QUICK_START_GUIDE = 18,
    BOARD_SCROLL = 19,
};

export enum EHintStatus {
    NOT_SHOWN = 0,
    SHOWN = 1,
    SKIPED = 2,
};

/**
 * ВАЖНО
 * селектор getActiveHint выбирает хинт для показа по condition и status
 * если у HintHOC show всегда будет false, то этот хинт застрянет и остановит очередь
 */

const HINTS: IHintConfig[] = [ // массив задаёт очередь
    // { // шаги туториала пока не утверждены, не показываем
    //     condition: tutorialFirstCondition,
    //     key: EHintKey.TUTORIAL_FIRST,
    //     tutorial: [
    //         {
    //             condition: tutorialFirstCardPlusCondition,
    //         },
    //         {},
    //         {},
    //     ],
    // },
    // { // пример from-to
    //     condition: boardSettingsCondition,
    //     from: 1582588800, // date of release 20200225
    //     key: EHintKey.BOARD_SETTINGS,
    //     to: 1583798400 // 2 weeks 20200310
    // },
    {
        condition: openedLastBoardCondition, // index, user.meta.isOpenLastBoard, есть recent
        key: EHintKey.OPENED_LAST_BOARD,
    },
    {
        condition: newCardsCountCondition, // есть новые карты в myTasks и навПанель открыта
        key: EHintKey.NEW_CARD_COUNT,
    },
    { // перед DEFAULT_CARD_TIME, т.к. сначала меняется дата, потом время
        condition: autoStartDateCondition, // два раза подряд руками вбивает текущую дату Start date
        key: EHintKey.AUTO_START_DATE,
    },
    {
        condition: defaultCardTimeCondition, // после изменения времени
        key: EHintKey.DEFAULT_CARD_TIME,
    },
    {
        condition: attachmentViewGridCondition, // после добавления аттача, если дефолтный вид
        key: EHintKey.ATTACHMENT_GRID_VIEW,
    },
    {
        condition: myWorkImportCardsUpdatesCondition,
        key: EHintKey.MY_WORK_IMPORT_CARDS_UPDATES,
    },
    {
        condition: myWorkFilterBarCondition,
        key: EHintKey.MY_WORK_FILTER_BAR
    },
    {
        condition: attachmentsListCondition, // если есть аттач
        key: EHintKey.ATTACHMENTS_LIST,
    },
    {
        condition: cardLinkCondition, // после добавления ответственного
        key: EHintKey.CARD_LINK,
    },
    {
        condition: ganttContextAddDate,
        key: EHintKey.GANTT_CONTEXT_ADD_DATE,
        isAllowFirstVisit: true
    },
    {
        condition: ganttContextShowOnGantt,
        key: EHintKey.GANTT_CONTEXT_SHOW_ON_GANTT,
        isAllowFirstVisit: true
    },
    {
        condition: ganttContextGanttTypeSwitcher,
        key: EHintKey.GANTT_CONTEXT_GANTT_TYPE_SWITCHER,
        isAllowFirstVisit: true
    },
    {
        condition: ganttContextGanttView,
        key: EHintKey.GANTT_CONTEXT_GANTT_VIEW,
        isAllowFirstVisit: true
    },
    {
        condition: ganttContextGanttViewResize,
        key: EHintKey.GANTT_CONTEXT_GANTT_VIEW_RESIZE,
        isAllowFirstVisit: true,
    },
    {
        condition: boardScrollCondition,
        key: EHintKey.BOARD_SCROLL,
    },
    {
        condition: quickStartGuideCondition,
        key: EHintKey.QUICK_START_GUIDE,
    },
    { // можно исправить condition по аналогии с getShowAttachmentViewHint
        condition: topBarFiltrationCondition, // condition пока частично задаётся в компоненте, поэтому этот хинт должен быть в конце очереди, чтоб остальные не застревали
        key: EHintKey.TOP_BAR_FILTER_ICONS,
    },
];

export const getHintConfig = (
    key: EHintKey
): IHintConfig => {
    const hint = HINTS.find((item) => {
        return item.key === key;
    });
    return hint ? {...hint} : null;
};

export const getHintsConfig = (
): IHintConfig[] => {
    const hints = HINTS.map((hint) => {
        return {...hint};
    });
    return hints;
};
