import { getCardListName } from './../../../../../../../store/model/selectors/getCardListName';
import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { IListSelectDispatchProps, IListSelectStateProps } from '../../components/ListSelect/types';
import { IListSelectHOCOwnProps } from './types';
import { getCard } from '../../../../../../../store/model/selectors/getCard';
import { onSelect } from './events/onSelect';
import { ListSelect } from '../../components/ListSelect/ListSelect';
import { onOpened } from './events/onOpened';
import { getListSelectSearchSelectOptions } from './selectors/getListSelectSearchSelectOptions';
import { stripHtml } from '../../../../../base/helpers/stripHtmlHelper';
import { markdown } from '../../../../../../../helper/markdownHelper';

const mapStateToProps = (
    state: IApplicationState,
    ownProps: IListSelectHOCOwnProps
): IListSelectStateProps => {
    let {cardId, boardId} = ownProps;
    const card = getCard(state, cardId);
    const options = getListSelectSearchSelectOptions(state, boardId);
    const listName = getCardListName(state, cardId);
    return {
        options,
        text: stripHtml(markdown(listName)),
        value: String(card.listId),
    }
};

const mapDispatchToProps = (
    dispatch: any,
    ownProps: IListSelectHOCOwnProps
): IListSelectDispatchProps => {
    let {cardId} = ownProps;
    return {
        onOpened: () => dispatch(onOpened()),
        onSelect: (listId) => dispatch(onSelect(cardId, listId))
    }
};

export const ListSelectHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(ListSelect);

ListSelectHOC.displayName = 'ListSelectHOC';
