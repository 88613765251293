import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { root } from '../../../../../../../store/constants';
import { segmentTrackAction, SegmentUserEvent } from '../../../../../../../middlewares/segment';

export const onClick: ActionCreator<ThunkAction> = () => {
    const action = (
        dispatch: Dispatch
    ) => {
        root.App.controller.showInvite();
        dispatch(segmentTrackAction(SegmentUserEvent.HELP_INVITE_CLICKED));
    };
    return action;
};
