import { connect } from 'react-redux';
import { ICardTabActivityHOCProps } from './types';
import { IApplicationState } from '../../../../../../../types/types';
import { CardTabActivity } from '../../components/CardTabActivity/CardTabActivity';
import { ICardTabActivityFields } from '../../components/CardTabActivity/types';
import { getActivityFetchOptions } from './selectors/getActivityFetchOptions';

const mapStateToProps = (
    state: IApplicationState,
    { cardId }: ICardTabActivityHOCProps
): ICardTabActivityFields => {
    return {
        fetchOptions: getActivityFetchOptions(state, cardId)
    }
};

export const CardTabActivityHOC = connect(
    mapStateToProps,
    null
)(CardTabActivity);

CardTabActivityHOC.displayName = 'CardTabActivityHOC';
