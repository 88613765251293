export const parseEstimate = (
    value: string,
    workHoursInDay: number
): number => {
    if (!value) {
        return 0;
    }

    const parts: {
        [key: string]: string
    } = {};

    const replaces: {
        [key: string]: string
    } = {
        'day': 'd',
        'days': 'd',
        'hour': 'h',
        'hours': 'h',
        'min': 'm',
        'mins': 'm',
        'minute': 'm',
        'minutes': 'm',
        '': 'm'
    };

    const timeElements: {
        [key: string]: boolean
    } = {
        'd': true,
        'h': true,
        'm': true
    };

    let durationInMinutes: {
        [key: string]: number
    } = {
        'd': workHoursInDay * 60,
        'h': 60,
        'm': 1
    };

    const raw = value.toLowerCase().split(' ');
    let data: string[];
    const pattern = /(\d{1,4})(.*)/i;
    for (let i = 0; i < raw.length; i++) {
        data = raw[i].match(pattern);
        if (!data) {
            continue;
        }
        if (!data || data.length < 2) {
            continue;
        }
        if (replaces[data[2]]) {
            data[2] = replaces[data[2]];
        }
        if (timeElements[data[2]]) {
            parts[data[2]] = data[1];
        }
    }

    let minutes = 0;
    for (let key in timeElements) {
        if (!timeElements.hasOwnProperty(key)) {
            continue;
        }
        if (parts[key]) {
            minutes += (durationInMinutes[key] * parseInt(parts[key]));
        }
    }

    return minutes > 0 ? minutes : null;
};
