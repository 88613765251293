import { AT_ERROR_SET } from './constants';
import { IErrorSetAction } from './types';
import { ISignInError } from '../types';

export const errorSetAction = (
    error: ISignInError
): IErrorSetAction => {
    return {
        type: AT_ERROR_SET,
        error,
    };
};
