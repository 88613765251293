import { IGetState, TBoardId, TCardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { TStatus } from '../../../../types/model';
import { ICardCopyRestOptions } from '../copy/api/types';
import { getTemplateListPromise } from '../../../helpers/templates/getTemplateListPromise';
import { cardCopy } from '../copy/cardCopy';
import { getOrderNumberByCards } from '../../../../store/model/helpers/getOrderNumberByCards';
import { getListCardsByStatus } from '../../../../store/model/selectors/getListCardsByStatus';
import { cardsCopy } from '../copy/cardsCopy';
import { getCard } from 'app/store/model/selectors/getCard';

export const createTemplateFromCard = (
    cardId: TCardId,
    boardId: TBoardId,
    copySubCards: boolean = false
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const card = getCard(state, cardId);
        const templateListPromise = getTemplateListPromise(state, boardId);
        return templateListPromise.then(list => {
            const options: ICardCopyRestOptions = {
                listId: list.id,
                status: TStatus.STATUS_SERVICE_TEMPLATE,
                resetAssignees: false,
                resetCardNumber: true,
                setAuthUserToCardAuthor: true,
                useSourceCardName: true
            }
            if (copySubCards) {
                return dispatch(cardsCopy(card.id, true, options)).then((copyCardId: TCardId) => {
                    return getCard(getState(), copyCardId);
                });
            }
            const cards = getListCardsByStatus(state, list.id, TStatus.STATUS_SERVICE_TEMPLATE);
            options.name = card.name;
            options.orderNumber = getOrderNumberByCards(cards, true);
            return dispatch(cardCopy(cardId, false, options));
        })
    };
    return action;
};
