import { IApplicationState } from '../../../../../../types/types';
import { IFilter } from '../filter/types';
import {filterPanelBoardsSelector} from './filterPanelBoardsSelector';

export const defaultFilterSelector = (
    state: IApplicationState,
    boardId: number
): IFilter => {
    return filterPanelBoardsSelector(state, boardId).defaultFilter
};
