import { ILastClickedActivityIdSetAction } from './types';
import { AT_LAST_CLICKED_ACTIVITY_ID_SET } from './constants';
import { TActivityId } from '../../../../../../../../types/types';

export const lastClickedActivityIdSet = (
    activityId: TActivityId
): ILastClickedActivityIdSetAction => ({
    type: AT_LAST_CLICKED_ACTIVITY_ID_SET,
    activityId
});
