import { connect } from 'react-redux';
import { getCard } from '../../../../../../../store/model/selectors/getCard';
import { IApplicationState } from '../../../../../../../types/types';
import { CardDatesPanelButton } from '../../components/CardDatesPanelButton/CardDatesPanelButton';
import { ICardDatesPanelButtonHOCProps } from './types';
import { ICardDatesPanelButtonEvents, ICardDatesPanelButtonFields } from '../../components/CardDatesPanelButton/types';
import { CARD_DATES_EPIC_TOOLTIP, CARD_SECTION_DATES, CARD_SECTION_DATES_MILESTONE } from '../../constants';
import { getPanelButtonShow } from '../../../selectors/getPanelButtonShow';
import { onClick } from './events/onClick';
import { getCardIsEpic } from '../../../../../../../store/model/selectors/getCardIsEpic';
import { getAsidePanelIsMobile } from '../../../../asidePanel/store/selectors/getAsidePanelIsMobile';

const mapStateToProps = (
    state: IApplicationState,
    { boardId, cardId, isShowTooltip }: ICardDatesPanelButtonHOCProps
): ICardDatesPanelButtonFields => {
    const card = getCard(state, cardId);
    const dueStartFlag = !!(card.dueDate || card.startDate);
    const isShow = getPanelButtonShow(state, boardId, cardId);
    const isDisabled = getCardIsEpic(state, cardId);
    const isMobile = getAsidePanelIsMobile(state);
    const tooltip = isMobile
        ? null
        : isDisabled
            ? CARD_DATES_EPIC_TOOLTIP
            : isShowTooltip
                ? card.isMilestone ? CARD_SECTION_DATES_MILESTONE : CARD_SECTION_DATES
                : null;
    return {
        dueStartFlag,
        isDisabled,
        isMilestone: card.isMilestone,
        isShow,
        tooltip,
    }
};

const mapDispatchToProps = (
    dispatch: any,
    {boardId, cardId, onClick: ownOnClick}: ICardDatesPanelButtonHOCProps
): ICardDatesPanelButtonEvents => {
    return {
        onClick: () => dispatch(onClick(boardId, cardId, ownOnClick))
    }
};

export const CardDatesPanelButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardDatesPanelButton);

CardDatesPanelButtonHOC.displayName = 'CardDatesPanelButtonHOC';
