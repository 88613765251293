import { IGetState } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IRecentBoard } from 'app/types/rest/IUserMeta';
import { authUserSetStarredBoards } from 'app/rest/effects/authUser/authUserSetStarredBoards';

export const onSort = (
    sourceIndex: number,
    destinationIndex: number,
    boards: IRecentBoard[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const sourceBoard = boards[sourceIndex]
        boards.splice(sourceIndex, 1);
        boards.splice(destinationIndex, 0, sourceBoard)
        dispatch(authUserSetStarredBoards(boards))
    };
    return action;
};
