import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState } from '../../../../types/types';
import { root } from '../../../../store/constants';
import { isAsideOpenSetAction } from '../../main/navigationPanel/store/navigationPanel/actions/isAsideOpenSetAction';
import { getIsAssignedToMeActive } from '../../../../store/router/selectors/getIsAssignedToMeActive';

export const closeUpdatesPanel = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const isATM = getIsAssignedToMeActive(getState());
        root.App.controller.updatesView.remove();
        if (isATM) {
            root.App.router.navigate(root.App.router.getAssignedToMeUrl(), { trigger: true });
        } else {
            root.App.router.navigate(root.App.router.getUrl(null), { trigger: true });
        }
        dispatch(isAsideOpenSetAction(false));
    };
    return action;
};
