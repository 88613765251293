import {IHideSetAction} from './types';
import {AT_HIDE_SET} from './constants';

export const hideSet = (
    hide: string[]
): IHideSetAction => ({
        type: AT_HIDE_SET,
        hide
    }
);
