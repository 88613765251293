import {IDefaultFilterActionsSetAction} from './types';
import {AT_DEFAULT_FILTER_ACTION_SET} from './constants';
import {TFilterAction} from '../../filter/actions/types';

export const defaultFilterActionSet = (
    filterAction: TFilterAction
): IDefaultFilterActionsSetAction => ({
    type: AT_DEFAULT_FILTER_ACTION_SET,
    defaultFilterAction:  filterAction
});
