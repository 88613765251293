import { IExportForm } from '../components/ExportDate/types';
import { IBackupSchedule } from '../../../../../../store/model/boardBackups/types';
import { EBackupRepeatType } from '../../../../../../types/rest/IRestBackup';
import { EExportRepeatType } from '../components/ExportDate/constants';

export const mapFormRecurringToRestBackup = (
    form: IExportForm
): IBackupSchedule => {
    const result: IBackupSchedule = {
        startDate: form.startDate ? form.startDate.getTime() : null,
        repeatEvery: form.repeatEvery,
        repeatType: getRestBackupRepeatType(form.repeatType)
    }
    return result;
}

export const mapRestBackupToFormRecurring = (
    backupRecurring: IBackupSchedule
): IExportForm  => {
    const result: IExportForm = {
        startDate: backupRecurring.startDate ? new Date(backupRecurring.startDate) : null,
        repeatEvery: backupRecurring.repeatEvery || 1,
        repeatType: getExportBackupType(backupRecurring.repeatType) || EExportRepeatType.DAY,
    }
    return result;
}

const repeatTypeMap = new Map<EExportRepeatType, EBackupRepeatType>([
    [EExportRepeatType.DAY, EBackupRepeatType.DAY],
    [EExportRepeatType.MONTH, EBackupRepeatType.MONTH],
    [EExportRepeatType.WEEK, EBackupRepeatType.WEEK],
    [EExportRepeatType.YEAR, EBackupRepeatType.YEAR]
])

const getRestBackupRepeatType = (repeatType: EExportRepeatType): EBackupRepeatType => {
    return repeatTypeMap.get(repeatType);
}

const getExportBackupType = (repeatType: EBackupRepeatType): EExportRepeatType => {
    for (let [key, value] of repeatTypeMap.entries()) {
        if (value === repeatType)
            return key;
    }
    return null;
}
