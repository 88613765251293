import { IApplicationState, TBoardId } from '../../../types/types';
import { getRequestsState } from './getRequestsState';

export const getBoardIsLoading = (
    state: IApplicationState,
    boardId: TBoardId
): boolean => {
    const boards = getRequestsState(state).boards;
    if (!boards[boardId]) return false;

    return boards[boardId].isLoading;
}
