import { IGDriveFile } from '../types/types';

const UPLOAD_URL = 'https://www.googleapis.com/upload/drive/v3/files?uploadType=resumable&supportsAllDrives=true';
const FILE_CHUNK_SIZE = 262144;

export const gDriveUploadFileByChunks = (
    file: File,
    accessToken: string,
    parentFolderId: string,
    progressCallback: (uploaded: number) => void
): Promise<IGDriveFile> => {
    return getUploadUrl(file, accessToken, parentFolderId).then((url) => {
        return uploadFile(url, file, progressCallback)
    });
}

const getUploadUrl = (
    file: File,
    accessToken: string,
    parentFolderId: string
) => {
    return new Promise<string>((resolve, reject) => {
        let meta: any = {
            name: file.name,
            mimeType: file.type
        }
        if (parentFolderId) {
            meta.parents = [parentFolderId];
        }
        fetch(UPLOAD_URL, {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                'Authorization': 'Bearer ' + accessToken,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(meta)
        }).then((resp) => {
            if (resp.status !== 200) {
                return reject();
            }
            let location = null
            resp.headers.forEach((value: string, key: string) => {
                if (key === 'location') {
                    location = value;
                }
            })
            if (location) {
                resolve(location);
            } else {
                reject();
            }
        }).catch((e) =>{
            reject(e);
        })
    })
}

const uploadFile = (
    url: string,
    file: File,
    progressCallback: (uploaded: number) => void
) => {
    return new Promise<IGDriveFile>((resolve, reject) => {
        const _callback = (error: string, uploaded: number, driveFile: IGDriveFile) => {
            if (error) {
                return reject(error);
            }
            filePosition += FILE_CHUNK_SIZE;
            if (progressCallback) {
                progressCallback(uploaded);
            }
            if (filePosition >= file.size) {
                return resolve(driveFile);
            }
            uploadFileChunk(url, file, filePosition, FILE_CHUNK_SIZE, _callback);
        }
        let filePosition = 0;
        uploadFileChunk(url, file, filePosition, FILE_CHUNK_SIZE, _callback);
    });
}

const uploadFileChunk = (
    url: string,
    file: File,
    position: number,
    chunkSize: number,
    callback: (error: string, uploaded: number, driveFile: IGDriveFile) => void
) => {
    const fileSize = file.size;
    if (position + chunkSize > fileSize) {
        chunkSize = fileSize - position;
    }
    const chunkFile = file.slice(position, position + chunkSize);
    const rangeHeader = `bytes ${position}-${position + chunkSize - 1}/${fileSize}`;
    const reader = new FileReader();
    reader.onload = (e) => {
        fetch(url, {
            method: 'PUT',
            cache: 'no-cache',
            headers: { 'Content-Range': rangeHeader },
            body: reader.result
        }).then((resp) => {
            resp.text().then((text) => {
                let driveFile: IGDriveFile = null;
                if (text && text.indexOf('{') >= 0) {
                    driveFile = JSON.parse(text);
                }
                callback(null, chunkSize, driveFile);
            });
        }).catch((e) => {
            callback(e, 0, null);
        })
    };
    reader.readAsArrayBuffer(chunkFile);
}
