'use strict';

import { getAuthUser } from '../../store/model/authUser/selectors/getAuthUser';
import { getAppState } from '../../store/configureStore';

App.Views.CardCreateAssignedNotification = App.Views.BaseNotification.extend({

    getActions: function() {
        var actions = {
            action: Messages.getText('noty.card_create'),
            actionData: {
                icon: 'assign'
            }
        };

        if (this.notificationModel.users && this.notificationModel.users.length) {
            actions.assigned = this.notificationModel.users[this.notificationModel.users.length - 1];
            if (this.isAssigned()) {
                actions.action = Messages.getText('noty.card_create_assigned');
            }
            if (this.isMe()) {
                actions.action = Messages.getText('noty.card_create_assigned_me');
            }
        }
        return actions;
    },

    isAssigned: function () {
        return this.notificationModel.newUser;
    },

    isMe: function () {
        return (this.isAssigned() && this.notificationModel.newUser == getAuthUser(getAppState()).permissionId);
    }
});

export default App.Views.CardCreateAssignedNotification;
