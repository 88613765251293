import {
    AT_BOARDS_REQUESTS_STATE_SET,
    AT_CARDS_REQUESTS_STATE_SET,
    AT_DEPENDENCIES_REQUESTS_STATE_SET,
    TRequestsStateAction
} from '../actions/types';
import { IBoardsRequestsState, ICardsRequestsState, IRequestsState } from './types';
import { getRequestsStateInitial } from './constants';

const initialState = getRequestsStateInitial();

export const requestsStateReducer = (
    state: IRequestsState = initialState,
    action: TRequestsStateAction
): IRequestsState => {
    switch (action.type) {
        case AT_BOARDS_REQUESTS_STATE_SET: {
            const boards: IBoardsRequestsState = {};
            for (let boardId in action.boards) {
                boards[boardId] = {
                    ...state.boards[boardId],
                    ...action.boards[boardId]
                }
            }
            return {
                ...state,
                boards: {
                    ...state.boards,
                    ...boards
                }
            };
        }

        case AT_CARDS_REQUESTS_STATE_SET: {
            const cards: ICardsRequestsState = {};
            for (let cardId in action.cards) {
                cards[cardId] = {
                    ...state.cards[cardId],
                    ...action.cards[cardId]
                }
            }
            return {
                ...state,
                cards: {
                    ...state.cards,
                    ...cards
                }
            };
        }

        case AT_DEPENDENCIES_REQUESTS_STATE_SET: {
            return {
                ...state,
                dependencies: {
                    ...state.dependencies,
                    ...action.dependencies
                }
            };
        }

        default:
            return state
    }
};
