import * as React from 'react';
import { Button } from 'kui';
import { IButtonLargeProps } from './types';
import {useEffect} from 'react';

export const ButtonLarge: React.FunctionComponent<IButtonLargeProps> = ({
    text,
    onClick,
    onDidMount
}) => {
    useEffect(() => {
        if (onDidMount) {
            onDidMount();
        }
    }, []);

    return (
        <Button
            size={'large'}
            onClick={onClick}
        >
            {text}
        </Button>
    )
};
