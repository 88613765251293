import { getBoard } from './getBoardById';
import { IApplicationState, TBoardId, TListId } from '../../../types/types';

export const getBoardListIds = (
    state: IApplicationState,
    boardId: TBoardId
): TListId[] => {
    const board = getBoard(state, boardId);
    if (
        !board ||
        !board.listIds ||
        !board.listIds.length
    ) return [];

    return board.listIds;
};
