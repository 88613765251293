import * as React from 'react';
import { CARD_CLASS } from '../../constants';
import { ICardCountIndicatorProps } from './types';
import { Icon, Tooltip } from 'kui';
import './CardCountIndicator.scss';

export function CardCountIndicator({
    children,
    icon,
    modificator,
    tooltip,
}: ICardCountIndicatorProps) {
    const cardCountIndicator = CARD_CLASS + '__count-indicator';
    const cardCountIconClass = CARD_CLASS + '__count-icon';
    return (
        <Tooltip
            isNoEvents
            isNoWrap
            value={tooltip}
        >
            <span className={`
                ${cardCountIndicator}
                ${modificator ? cardCountIndicator + '--' + modificator : ''}
            `}>
                <Icon
                    className={cardCountIconClass}
                    size={16}
                    xlink={icon}
                />
                {children}
            </span>
        </Tooltip>
    );
}
