import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState } from '../../../../../../../types/types';
import { updateQualificationFormMeta } from '../../../../../../../rest/effects/authUser/qualificationForm/updateQualificationFormMeta';
import { getUserQualificationFormAnswers } from '../../../../../../../store/model/authUser/selectors/getUserQualificationFormAnswers';
import { IUserMetaQualificationAnswer } from '../../../../../../../types/rest/IUserMeta';

export const onAnswer = (
    questionKey: string,
    answer: IUserMetaQualificationAnswer
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const currentAnswers = getUserQualificationFormAnswers(getState()) || {};

        return dispatch(updateQualificationFormMeta({
            answers: {
                ...currentAnswers,
                [questionKey]: answer
            }
        }))
    };
    return action;
};
