import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import {
    createFirstCardNotificationsEditedStateEffect,
    initCardNotificationsEditedStateEffect,
} from '../../NotificationEditingPopUp/effects/editingStateEffects';
import { focusNewlyCreatedNotification } from '../../NotificationEditingPopUp/effects/editingPopupStateEffects';
import { nbdAddClickedSegmentEvent } from '../../NotificationEditingPopUp/effects/segmentEvents';
import { popupStatusForCardUpdate } from '../../../state/effects/popupStatusForCardUpdate';
import { IGetState } from '../../../../../../../../../types/types';
import { showSnoozeBlocker } from '../../../../../../../base/effects/showSnoozeBlocker';
import { getAuthUser } from '../../../../../../../../../store/model/authUser/selectors/getAuthUser';
import {
    getProFeaturesTrialActive
} from '../../../../../../../../../store/model/authUser/selectors/getProFeaturesTrialActive';
import {
    authUserSetUsedProFeatures
} from '../../../../../../../../../rest/effects/authUser/authUserSetUsedProFeatures';

export const onAddBellClicked: ActionCreator<ThunkAction> = (
    cardId: number,
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const isAllow = getAuthUser(getState()).allowNotificationBeforeDueDates;
        if (isAllow) {
            dispatch(initCardNotificationsEditedStateEffect(cardId));
            dispatch(popupStatusForCardUpdate(cardId, true));
            dispatch(createFirstCardNotificationsEditedStateEffect(cardId));
            dispatch(focusNewlyCreatedNotification());
            dispatch(nbdAddClickedSegmentEvent());
            if (getProFeaturesTrialActive(getState())) {
                dispatch(authUserSetUsedProFeatures('allowNotificationBeforeDueDates'));
            }
        } else {
            dispatch(showSnoozeBlocker({
                allow: 'allowNotificationBeforeDueDates'
            }));
        }
    };
    return action;
};
