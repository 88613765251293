import { Dispatch, ThunkAction } from '../../../types/actions';
import { IUserStatAdminReportEventRest, postRest } from './api/postRest';
import { IGetState } from '../../../types/types';
import { getAuthUser } from '../../../store/model/authUser/selectors/getAuthUser';
import { root } from '../../../store/constants';

export const sendUserStatAdminEvent = (
    event: IUserStatAdminReportEventRest
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const user = getAuthUser(getState());
        event = {
            ...event,
            fullName: user.fullName,
            email: user.email,
            permissionId: user.permissionId,
            createDate: user.createDateTimeStamp *1000,
            country: root.App.controller.country
        };
        dispatch(pushEventsToQueue(event));
    };
    return action;
};

export const DELAY_TIME_SEND_EVENTS = 3000;

export const eventsQueue: IUserStatAdminReportEventRest[] = [];

export let queueIsOnProcessing: boolean = null;

export const pushEventsToQueue = (
    event: IUserStatAdminReportEventRest
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        if (!queueIsOnProcessing) {
            eventsQueue.push(event);
            dispatch(sendEvent());
        } else {
            eventsQueue.push(event);
        }
    };
    return action;
};

export const sendEvent = (): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        queueIsOnProcessing = true;
        const event = eventsQueue.shift();
        const thenCallback = () => {
            setTimeout(() => {
                if (eventsQueue.length > 0) {
                    dispatch(sendEvent());
                } else {
                    queueIsOnProcessing = false;
                }
            }, DELAY_TIME_SEND_EVENTS);
        }
        dispatch(postRest(event)).then(thenCallback).catch(thenCallback);
    };
    return action;
};
