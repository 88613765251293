import { IApplicationState } from '../../../../../../types/types';
import { EButtonsPanelType } from '../types';
import { getAsidePanelButtonsPanelType } from './getAsidePanelButtonsPanelType';
import { getIsLinkCardActive } from '../../../../../../store/router/selectors/getIsLinkCardActive';

export const getAsidePanelButtonsPanelIsMinimized = (
    state: IApplicationState
): boolean => {
    if (getIsLinkCardActive(state)) {
        return false;
    }
    const buttonsPanelType = getAsidePanelButtonsPanelType(state);
    return buttonsPanelType === EButtonsPanelType.MINIMIZED;
};
