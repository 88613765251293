import { Dispatch, ThunkAction } from '../../../../../../types/actions';
import { segmentTrackAction, SegmentUserEvent } from '../../../../../../middlewares/segment';
import {
    SegmentUserOption,
    SegmentUserPlayerValue
} from '../../../../../../middlewares/segment/trackEntities/userEvents';
import { ETutorial } from '../../../../../../types/rest/IUserMeta';
import { TUTORIALS_NAME_MAP } from '../constants';

export const onMute = (
    tutorial: ETutorial,
    isMuted: boolean
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(segmentTrackAction(SegmentUserEvent.TUTORIAL_MUTE, {
            name: SegmentUserOption.TYPE,
            value: isMuted ? SegmentUserPlayerValue.MUTED : SegmentUserPlayerValue.UNMUTED
        }, [
            { name: SegmentUserOption.STATUS, value: TUTORIALS_NAME_MAP[tutorial] }
        ]));
    }
    return action;
}
