import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../../../../types/types';
import { cardGenerateCardNumber } from '../../../../../../../../rest/effects/card/card/cardGenerateCardNumber';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { getAuthUser } from '../../../../../../../../store/model/authUser/selectors/getAuthUser';
import { showSnoozeBlocker } from '../../../../../../base/effects/showSnoozeBlocker';

export const onCardNumberGenerate = (
    cardId: TCardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const { allowCardNumber } = getAuthUser(getState());
        if (allowCardNumber) {
            dispatch(cardGenerateCardNumber(cardId));
            dispatch(segmentTrackAction(SegmentCardEvent.CARD_PREFIX_GENERATED));
        } else {
            dispatch(showSnoozeBlocker({ allow: 'allowCardNumber' }));
        }
    };
    return action;
};
