import { root } from '../store/constants';
import { dispatch } from '../store/configureStore';
import { snackbarErrorReinstall } from '../view/react_components/snackbarsStack';
import { getParamFromUrl, putParamToUrl } from './part_loader';
import { QueueManager } from './fetchUtils/patterQueue/QueueManager';
import { showForceNotifications } from '../view/react_components/aside_panel/cardDetails/notify/effects/showForceNotifications';
import { updateCardAgingIndicator } from '../view/react_components/main/kanbanView/effects/updateCardAgingIndicator';
import { CSRF as generateCSRF } from '../app';
import { getAppVersion } from '../middlewares/segment/selectors/getAppVersion';
import {getAuthUser} from 'app/store/model/authUser/selectors/getAuthUser';
import { FetchQueue } from 'app/util/fetchUtils/FetchQueue';

const _QueueManager = new QueueManager();

function CSRF(
    init?: RequestInit
) {
    init = init || {};
    init.headers = init.headers || {};
    generateCSRF();
    let code = root.App.Util.getCookie('X-CSRF-Header');
    // @ts-ignore
    init.headers['X-CSRF-Header'] = code;
    return init;
}

function addAppVersionToHeader(
    init?: RequestInit
) {
    init = init || {};
    init.headers = init.headers || {};
    // @ts-ignore
    init.headers['X-App-Version'] =  getAppVersion();
    return init;
}

export function fetchHandler<T>(
    input: RequestInfo,
    init?: RequestInit,
    cors: boolean = false,
    noError: boolean = false
): Promise<T> {
    init = init || {};
    if (!init.credentials) {
        init.credentials = INIT_CREDENTIALS;
    }
    if (!cors && typeof input === 'string' && !input.startsWith('/')){
        if (!input.startsWith(root.location.origin)) {
            cors = true;
        }
    }
    if(input && typeof input === 'string') {
        const userId = getAuthUser(root.store.getState()) && getAuthUser(root.store.getState()).id;
        let userIdParam;
        if(input.includes('?')) {
            userIdParam = '&userId=' + userId
        } else {
            userIdParam ='?userId=' + userId
        }
        input += userIdParam;
    }

    if(!cors) {
        init = CSRF(init);
        init = addAppVersionToHeader(init);
    }
    return sendRequest(input, init)
    .then(
        (response) => {
            if (response.ok) {
                dispatch(showForceNotifications(response));
                dispatch(updateCardAgingIndicator(response));
                return Promise.resolve(response.json());
            } else if (noError) {
                return Promise.resolve(null)
            }
            switch (response.status) {
                case 400:
                    return new Promise((res, rej) => dispatch(snackbarErrorReinstall())); // return new Promise to brake chain
                case 401:
                case 403:
                    return Promise.reject(response);
                    //return AuthManager.loginWithRememberMeOrShowLoginScreen().then(() => fetchHandler(input, init));
                case 409:
                    return resendRequest(response, init, CODE_409_PARAMS, CODE_409_TRIES_DELAY, CODE_409_TRIES_LIMIT);
                case 500:
                    return resendRequest(response, init, CODE_500_PARAMS, CODE_500_TRIES_DELAY, CODE_500_TRIES_LIMIT);
                default:
                    return Promise.reject(response);
            }
        }
    );
};

const resendRequest = (
    response: Response,
    init: RequestInit,
    param: string,
    delay: number,
    triesLimit: number
) => {
    return new Promise((res, rej) => {
        let tries = parseInt(getParamFromUrl(response.url, param), 10) || 0;
        tries++;
        if (tries < triesLimit) {
            window.setTimeout(() => {
                return res(fetchHandler(putParamToUrl(response.url, param, String(tries)), init));
            }, delay);
        } else {
            console.log('try ' + triesLimit + ' but fail: ', response);
            return rej(response);
        }
    });
}

export interface IFetchArgs {
    input?: string | RequestInfo,
    init?: RequestInit,
}

export interface IRequestItem extends  IFetchArgs{
    resolveCallback?: any
    rejectCallback?: any
}

const INIT_CREDENTIALS = 'same-origin';

const CODE_409_PARAMS = 'try409';
const CODE_409_TRIES_DELAY = 2000;
const CODE_409_TRIES_LIMIT = 15;

const CODE_500_PARAMS = 'try500';
const CODE_500_TRIES_DELAY = 5000;
const CODE_500_TRIES_LIMIT = 10;

export const sendRequest = (input: RequestInfo, init?: RequestInit) => {
    let result = _QueueManager.add({input, init});
    if (result)
        return result;
    else {
        return FetchQueue.getInstance().add({input, init});
    }
};
