import { authUserSetMyWorkMeta } from '../authUserSetMyWorkMeta';
import { getAuthUserMetaMyWork } from '../../../../store/model/selectors/getAuthUserMetaMyWork';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState } from '../../../../types/types';
import { IUserMetaMyWork } from '../../../../types/rest/IUserMeta';
import { getAuthUser } from '../../../../store/model/authUser/selectors/getAuthUser';

export const updateMyWorkMeta = (
    options: Partial<IUserMetaMyWork>
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const current = getAuthUserMetaMyWork(state);
        return dispatch(authUserSetMyWorkMeta(getAuthUser(state).id, {
            ...current,
            ...options
        }));
    };
    return action;
};
