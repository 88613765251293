import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { ICardChecklistElementsHOCProps } from './types';
import { getSectionType } from '../../../selectors/getSectionType';
import { getBoardUsers } from '../../../../../../../store/model/selectors/getBoardUsers';
import { getCard } from '../../../../../../../store/model/selectors/getCard';
import { getAuthUser } from '../../../../../../../store/model/authUser/selectors/getAuthUser';
import { getBoardWorkHoursInDay } from '../../../../../../../store/model/selectors/getBoardWorkHoursInDay';
import {
    ICardChecklistItemActionsEvents,
    ICardChecklistItemActionsFields
} from '../../components/CardChecklistItemActions/types';
import { CardChecklistItemActions } from '../../components/CardChecklistItemActions/CardChecklistItemActions';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { onClickWeight } from './events/onClickWeight';
import { onChangeWeight } from './events/onChangeWeight';
import { getChecklistUsersSearchSelectOptionsNoReselect } from '../selectors/getChecklistUsersSearchSelectOptions';
import { onChangeAssignees } from './events/onChangeAssignees';
import { onAssignUserClick } from './events/onAssignUserClick';
import { onChangeEstimate } from './events/onChangeEstimate';
import { onChangeStartDate } from './events/onChangeStartDate';
import { onChangeDueDate } from './events/onChangeDueDate';
import { onClickEstimate } from './events/onClickEstimate';
import { onClickStartDate } from './events/onClickStartDate';
import { onClickDueDate } from './events/onClickDueDate';
import { onConvertToSubcard } from './events/onConvertToSubcard';
import { onDelete } from './events/onDelete';
import { getChecklistItemsNoReselect } from '../selectors/getChecklistItemsSection';
import { onClose } from './events/onClose';
import { isEqual } from 'underscore';

// const analyze = new Analyze('CardChecklistItemActionsHOC');
// 10 раз открыл-закрыл карту
// no reselect  average: 0.029 ms, calls: 3600, total: 104.300 ms
// reselect     average: 0.033 ms, calls: 3450, total: 114.000 ms // analyzeRender: 10
// cache        average: 0.036 ms, calls: 2160, total: 77.700 ms // analyzeRender OK

const mapStateToProps = () => {
    // const getChecklistItems = getChecklistItemsSection();
    // const getUsersSearchSelectOptions = getChecklistUsersSearchSelectOptionsCreateSelector();

    interface ICaheProps extends ICardChecklistItemActionsFields{};
    let cache: ICaheProps = null;
    const getCache = (
        value: ICaheProps
    ): ICaheProps => {
        if (isEqual(value, cache)) return cache;
        cache = value; return value;
    };

    return (
        state: IApplicationState,
        { boardId, cardId, checklistId, item, isEditing }: ICardChecklistElementsHOCProps
    ): ICardChecklistItemActionsFields => {
        // analyze.start();
        const sectionType = getSectionType(state, boardId, cardId);
        const { isMilestone = false } = getCard(state, cardId) || {};
        const { permissionId: authUserPermissionId } = getAuthUser(state);
        const checklistItems = getChecklistItemsNoReselect(state, cardId, checklistId);
        const avaliableAssignees = getChecklistUsersSearchSelectOptionsNoReselect(state, boardId, cardId, checklistId, item.id);

        const isReadonly = sectionType === ESettingsSectionType.READONLY;
        let isShowWeights = isReadonly;
        if (!isReadonly && checklistItems) {
            checklistItems.map((item) => {
                if (item.weight > 1) {
                    isShowWeights = true;
                }
            });
        }
        const { allowChecklistItemCount } = getAuthUser(state);

        const ret = getCache({
            aloudCount: allowChecklistItemCount,
            availableAssignees: avaliableAssignees,
            authUserPermissionId,
            boardUsers: getBoardUsers(state, boardId),
            item,
            isEditing,
            isMilestone,
            isShowWeights,
            sectionType,
            workHoursInDay: getBoardWorkHoursInDay(state, boardId)
        });
        // analyze.finish();
        return ret;
    };
};

const mapDispatchToProps = (
    dispatch: any,
    { boardId, cardId, checklistId, item, setLoading, setDropdownOpen }: ICardChecklistElementsHOCProps
): ICardChecklistItemActionsEvents => {
    const { id } = item;
    return {
        onAssignUserClick: () => dispatch(onAssignUserClick()),
        onChangeAssignees: (permitionId) => dispatch(onChangeAssignees(boardId, cardId, checklistId, id, permitionId)),
        onChangeEstimate: (estimate) => dispatch(onChangeEstimate(cardId, checklistId, id, estimate)),
        onChangeStartDate: (date) => dispatch(onChangeStartDate(cardId, checklistId, id, date)),
        onChangeDueDate: (date) => dispatch(onChangeDueDate(cardId, checklistId, id, date)),
        onClickEstimate: () => dispatch(onClickEstimate()),
        onClickStartDate: () => dispatch(onClickStartDate()),
        onClickDueDate: () => dispatch(onClickDueDate()),
        onClose: () => dispatch(onClose()),
        onConvertToSubcard: (onWork) => dispatch(onConvertToSubcard(cardId, checklistId, id, onWork)),
        onDelete: () => dispatch(onDelete(cardId, checklistId, id)),
        onChangeWeight: (weight) => dispatch(onChangeWeight(cardId, checklistId, id, weight)),
        onClickWeight: () => dispatch(onClickWeight()),
        setLoading,
        setDropdownOpen
    };
};

export const CardChecklistItemActionsHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardChecklistItemActions);

CardChecklistItemActionsHOC.displayName = 'CardChecklistItemActionsHOC';
