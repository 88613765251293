import { getMessages } from '../../../../../store/constants';

export const CARD_SECTION_DEPENDENCIES = getMessages().getText('card_form_section_dependencies');
export const CARD_PREDECESSOR_ADD = getMessages().getText('card_form_predecessor_add');

export enum ECardDependencyType {
    PREDECESSOR = 'Predecessor',
    SUCCESSOR = 'Successor'
}

export enum EDependencyNames {
    STS = 'Start to start',
    STF = 'Start to finish',
    FTS = 'Finish to start',
    FTF = 'Finish to finish'
}
