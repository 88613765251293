import * as React from 'react';
import { IColorTagsProps } from './types';
import './ColorTags.scss';
import { Icon, Tooltip } from 'kui';
import { CLASS_COLOR_TAGS } from './constants';
import { markdown } from 'app/helper/markdownHelper';
import { TColorId } from 'app/types/types';
import { getParentsClasses } from 'app/view/react_components/helpers/getParentsClasses';
import ReactHtmlParser from 'react-html-parser';
import { OLD_AND_NEW_COLORS } from '../../../../../store/model/board/selectors/getBoardColors';
import { isTextColorLight } from '../../../helpers/getTextColor';

export function ColorTags({
    colorTags,
    size = 32,
    onEdit,
    onRemove,
    onClick
}: IColorTagsProps) {
    if (colorTags.length === 0) return null;

    const classNameLength = colorTags.length > 5 ? CLASS_COLOR_TAGS + '--5plus' : '';
    const classItem = CLASS_COLOR_TAGS + '__item';
    const classClose = classItem + '-close';

    const timerRef = React.useRef(null);

    const onTouchStart = () => {
        if (timerRef.current) clearTimeout(timerRef.current);
        timerRef.current = setTimeout(handleEdit ,500);
    };

    const onTouchEnd = () => {
        if (timerRef.current) clearTimeout(timerRef.current);
    };

    const handleEdit = (id: TColorId) => {
        if (timerRef.current) clearTimeout(timerRef.current);
        onEdit(id);
    };

    const handleClick = (e: React.SyntheticEvent) => {
        if (onClick) {
            e.stopPropagation();
            const parentClasses = getParentsClasses(e.target as HTMLElement, [
                classClose
            ]);
            if (parentClasses.includes(classClose)) return;
            if (timerRef.current) clearTimeout(timerRef.current);
            timerRef.current = setTimeout(onClick ,300);
        }
    };

    return (
        <div className={`
            ${CLASS_COLOR_TAGS}
            ${CLASS_COLOR_TAGS}--${size}
            ${classNameLength}
        `}>
            {
                colorTags.map(tag => {
                    const name = markdown(tag.name);
                    const isCustom = !OLD_AND_NEW_COLORS.find((item: any) => tag.color === item || (item.color && tag.color === item.color));

                    return <Tooltip
                        isNoWrap={true}
                        key={tag.id}
                        value={name}
                    >
                        <div
                            className={`${classItem} ${classItem}--${tag.color}
                                ${isCustom ? classItem + (isTextColorLight(tag.color) ? '--dark' : '--light') : ''}
                            `}
                            key={tag.id}
                            style={isCustom ? { background: tag.color } : null}
                            onDoubleClick={() => handleEdit(tag.id)}
                            onTouchStart={onTouchStart}
                            onTouchEnd={onTouchEnd}
                            onClick={handleClick}
                        >
                            <span>{ReactHtmlParser(name)}</span>
                            {!!onRemove &&
                                <div
                                    className={classClose}
                                    onClick={(e) => onRemove(tag.id, e)}
                                >
                                    <Icon
                                        xlink={'close'}
                                        size={16}
                                    />
                                </div>
                            }
                        </div>
                    </Tooltip>
                })
            }
        </div>
    );
}
