import { getRestHeadersPost } from './../../rest/helpers/getRestHeadersHelper';
import Util from '../../util/util';
import { fetchHandler } from '../../util/fetchHandler';
import { TBoardId, TDriveId } from '../../types/types';
import { IFolderInfo } from 'app/types/rest/IRestDashboard';

type IServerWorkContext = any;

interface IWorkContext {
    cancelStatus: boolean;
    serverWorkContext: IServerWorkContext;
}

export interface IImportOptions {
    onProgress: (percentProgress: number) => void;
    onSuccess: (opt: {destDashboardId: TBoardId; parentFolderInfo: IFolderInfo}) => void;
    onError: (xhr: any) => void;
    onCancel: () => void;
}

export interface IStartOptions {
    importFileId: string;
    importDriveId?: string;
    dashboardName: string;
    destDriveId?: TDriveId;
    url?: string;
}

interface IImportResponse {
    destDashboardId: TBoardId;
    completed: boolean;
    percentProgress: number;
    workContext: IServerWorkContext;
}

export class DashboardXmlImportManager {
    private url: string;
    private workContext: IWorkContext;
    private importFileId: string;
    private importDriveId: string;
    private dashboardName: string;
    private destinationDriveId: TDriveId;
    private options: IImportOptions;

    constructor(options: IImportOptions) {
        this.url = Util.getApiUrl('/rest/dashboard/xml/import');
        this.workContext = null;
        this.options = options;
    }

    public startImport(options: IStartOptions) {
        if (this.workContext) return false;
        this.importFileId = options.importFileId;
        this.importDriveId = options.importDriveId;
        this.dashboardName = options.dashboardName || '';
        this.destinationDriveId = options.destDriveId;
        this.url = options.url ? options.url : this.url;
        this.workContext = {cancelStatus: false, serverWorkContext: {}};
        this.doImport();
    }

    private doImport() {
        setTimeout(() => {
            const request = {
                importFileId: this.importFileId,
                importDriveId: this.importDriveId,
                destDashboardName: this.dashboardName,
                workContext: this.workContext.serverWorkContext,
                destinationDriveId: this.destinationDriveId
            };
            fetchHandler(
                Util.getApiUrl(this.url), {
                    ...getRestHeadersPost(),
                    body: JSON.stringify(request)
                }
            )
                .then(this.onDone)
                .catch(this.onError);
        }, 0);
    }

    private onDone = (result: IImportResponse) => {
        if (
            !this.checkResponseError(result) ||
            !this.checkCancelStatus()
        ) return;

        this.workContext.serverWorkContext = result.workContext;
        this.callbackHandler('onProgress', result.percentProgress);

        if (result.completed) {
            this.callbackHandler('onSuccess', {
                destDashboardId: result.destDashboardId,
                parentFolderInfo: result.workContext.handlerContext.parentFolderInfo
            });
            this.onSuccess();
        } else {
            this.doImport();
        }
    }

    private onError = (xhr: any) => {
        this.workContext = null;
        this.callbackHandler('onError', xhr);
    }

    /**
     * Отмена импорта
     * @returns true или false в зависимости от наличия workContext
     */
    private cancelImport() {
        if (!this.workContext) return false;
        this.workContext.cancelStatus = true;
        return true;
    }

    /**
     * Проверка возвращаемого значения на error
     * @param result
     * @returns boolean
     */
    private checkResponseError(result: any) {
        if (!result.success) {
            this.workContext = null;
            this.callbackHandler('onError', result.errorMessage);
            return false;
        }
        return true;
    }

    /**
     * Проверка workContext на cancelStatus
     * @returns boolean
     */
    private checkCancelStatus() {
        if (this.workContext.cancelStatus) {
            this.workContext = null;
            this.callbackHandler('onCancel');
            return false;
        }
        return true;
    }

    /**
     * Функция которая вызывает на себя тригер события
     * @param name Имя события
     * @param options дополнительные параметры события
     */
    private callbackHandler(name: keyof IImportOptions, options?: any) {
        this.options[name](options);
    }

    private onSuccess() {
        this.workContext = null;
    }

    public isReady() {
        return this.workContext == null
    }
}
