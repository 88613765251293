import { IApplicationState } from '../../../../../types/types';
import { getAuthUserMetaMyWork } from '../../../../../store/model/selectors/getAuthUserMetaMyWork';
import { CARDS_COLUMNS } from '../cards/constants';
import { IMyWorkColumn } from '../../../../../types/rest/IUserMeta';

export const getUserMetaMyWorkColumns = (
    state: IApplicationState
): IMyWorkColumn[] => {
    const savedColumns = getAuthUserMetaMyWork(state).columns || [];
    return CARDS_COLUMNS.reduce((columns, { title, isHidden }) => {
        const idx = savedColumns.findIndex(column => column.title === title);
        if (idx === -1) {
            columns.push({ title, isHidden });
        }
        return columns;
    }, savedColumns);
}
