import { IGetState, TCardId } from 'app/types/types';
import { Dispatch, ThunkAction } from 'app/types/actions';
import { selectFiles } from '../effects/selectFiles';
import { selectFileClickedSegmentEvent } from '../../../CardAttachmentsSectionHOC/effects/segmentEffects';
import { IDriveDoc } from 'app/store/model/card/types/IDriveDoc';
import { addAttachments } from 'app/rest/effects/card/attacments/addAttachments';
import { setAttachmentAddedSessionStorage } from '../../../../helpers/setAttachmentAddedSessionStorage';

export const onSelectClick = (
    cardId: TCardId,
    onClose?: () => void,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(selectFileClickedSegmentEvent());

        return dispatch(selectFiles(cardId))
            .then((driveDocs: IDriveDoc[]) => {
                return dispatch(addAttachments(cardId, driveDocs));
            })
            .then((driveDocs: IDriveDoc[]) => {
                setAttachmentAddedSessionStorage();
                if (onClose) onClose();
                return driveDocs;
            });
    };
    return action;
};
