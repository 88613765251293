import { TNotificationSettingsAction } from '../../notificationSettings/actions/types';
import { AT_NOTIFICATION_SETTINGS_ACTION_SET, INotificationSettingsActionSetAction } from './types';

export const notificationSettingsActionSet = (
    boardId: number,
    notificationAction: TNotificationSettingsAction
): INotificationSettingsActionSetAction => ({
    type: AT_NOTIFICATION_SETTINGS_ACTION_SET,
    boardId,
    notificationAction
});
