import {Dispatch, ThunkAction} from '../../../../types/actions';
import {typeSwitcherBoardTypeSet} from '../store/actions/typeSwitcherBoardTypeSet';
import { EViewTypes } from '../../../../const';
import { isKanbanGroup } from '../selectors/isKanbanGroup';
import { typeSwitcherLastKanbanViewSet } from '../store/actions/typeSwitcherLastKanbanViewSet';

export const setSwitcherBoardType = (
    boardId: number,
    viewType: EViewTypes
): ThunkAction => {
    const action = (dispatch: Dispatch) => {
        dispatch(typeSwitcherBoardTypeSet(boardId, viewType));
        if (isKanbanGroup(viewType)) dispatch(typeSwitcherLastKanbanViewSet(boardId, viewType));
    };
    return action;
};
