import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../types/actions';
import { EHintKey, EHintStatus } from '../../../constants';
import { hintSave } from '../../../effects/hintSave';
import { hintActionSetAction } from '../../../store/hints/actions/hintActionSetAction';
import { hintStatusSetAction } from '../../../store/hint/actions/hintStatusSetAction';

export const onTutorialSkip: ActionCreator<ThunkAction> = (
    key: EHintKey,
    ownOnTutorialSkip?: () => void
) => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(hintActionSetAction(key, hintStatusSetAction(EHintStatus.SKIPED)));
        dispatch(hintSave(key));
        if (ownOnTutorialSkip) ownOnTutorialSkip();
    };
    return action;
};
