import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { IGetState } from '../../../../../types/types';
import { vent } from '../../../../../store/constants';

export const closePanel = (): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        vent.trigger(vent['aside-panel:hide']);
    };
    return action;
};
