import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { IGetState, TCardId, TChecklistId, TChecklistItemId } from '../../../../../../../../../types/types';
import { toggleChecklistItem } from '../effects/toggleChecklistItem';

export const onToggle = (
    cardId: TCardId,
    checklistId: TChecklistId,
    itemId: TChecklistItemId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(toggleChecklistItem(cardId, checklistId, itemId));
    };
    return action;
};
