import { TBoardId } from '../../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { updateCardNumberFromBoard } from '../effects/updateCardNumberFromBoard';

export const onBoardChanges = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(updateCardNumberFromBoard(boardId));
    };
    return action;
};
