import { REST_CARDS } from '../../../../constants';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { IRestCard } from '../../../../../types/rest/IRestCard';
import { getRestHeadersPatch } from '../../../../helpers/getRestHeadersHelper';
import { PartUploader } from '../../../../../util/part_uploader';
import { PORTION_SIZE } from '../../../../../const';

export const patchRestBulk = (
    cards: IRestCard[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        const partUploader = new PartUploader<IRestCard>(
            REST_CARDS,
            cards,
            PORTION_SIZE,
            getRestHeadersPatch()
        );
        return partUploader.uploadPart()
    };
    return action;
};
