import { IApplicationState, TBoardId } from '../../../../types/types';
import {
    EAsidePanelProperty
} from '../../../../view/react_components/aside_panel/asidePanel/components/AsidePanel/types';
import { getBoardCardProperties } from './getBoardCardProperties';

export const getBoardCardDefaultProperties = (
    state: IApplicationState,
    boardId: TBoardId
): Set<EAsidePanelProperty> => {
    const defaultProperties: Set<EAsidePanelProperty> = new Set();
    const cardProperties = getBoardCardProperties(state, boardId);

    if (cardProperties) {
        cardProperties.forEach((item) => {
            if (
                item.isDefault ||
                (item.options && item.options.length && item.options[0].isDefault)
            ) {
                defaultProperties.add(item.property);
            }
        });
    }

    return defaultProperties;
};
