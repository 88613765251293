import { IGetState, TBoardId } from '../../../types/types';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { notificationSettingsPatchRest } from './api/helpers/notificationSettingsPatchRest';

export const notificationSetPeriod = (
    boardId: TBoardId,
    period: number
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(notificationSettingsPatchRest(boardId, {period}));
    };
    return action;
}
