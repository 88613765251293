import { IGetState, TCardId } from '../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { cardSetListBatch } from '../cardSetList';
import { getCard } from '../../../../../store/model/selectors/getCard';
import { sendCardStatWithIncrement } from '../../../../../helper/comet/stat_helper_ts';
import { getList } from '../../../../../store/model/list/selectors/getList';
import { getCardDone } from '../../../../../store/model/selectors/getCardDone';
import { ICardBatch, ICardBatches } from '../api/types';
import { ICard } from '../../../../../store/model/card/types';
import { cardPatchRestBatch, cardsPatchRestBatch } from '../api/helper/cardPatchRestBatch';
import { getBoardByCardId } from '../../../../../store/model/selectors/getBoardByCardId';
import { cardsSetDoneProcessingBatches } from '../cardsSetDoneProcessing';
import { getCardCanBeSetDone } from '../../../../../store/model/selectors/getCardCanBeSetDone';

export const cardMoveToListBatch = (
    getState: IGetState,
    dispatch: Dispatch,
    cardId: TCardId,
    listId: number,
    orderNumber?: number,
    cardBatch: ICardBatch = {card: {}, callbacks: []}
) => {
    const oldListId = getCard(getState(), cardId).listId;
    if (oldListId === listId && !orderNumber) return cardBatch;
    cardSetListBatch(getState, cardId, listId, orderNumber, cardBatch);
    cardBatch.callbacks.push((card: ICard) => {
        const cardDoneCountDelta = getCardDone(getState(), cardId) ? 1 : 0;
        dispatch(sendCardStatWithIncrement(getList(getState(), listId), 1, cardDoneCountDelta));
        dispatch(sendCardStatWithIncrement(getList(getState(), oldListId), -1, -cardDoneCountDelta));
    });
    return cardBatch;
};

export const cardMoveToList = (
    cardId: TCardId,
    listId: number,
    orderNumber?: number
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const board = getBoardByCardId(state, cardId);
        const moveToDoneList = board && board.statusLists && board.statusLists.doneId === listId;
        const cardBatch = cardMoveToListBatch(getState, dispatch, cardId, listId, orderNumber);
        const cardBatches: ICardBatches = {[cardId]: cardBatch};
        if (moveToDoneList && getCardCanBeSetDone(state, cardId)) {
            const cardSetDoneBatches: ICardBatches = cardsSetDoneProcessingBatches(getState, [cardId], true);
            for(let cardId in cardSetDoneBatches) {
                if (cardBatches[cardId]) {
                    cardBatches[cardId].card = {...cardBatches[cardId].card, ...cardSetDoneBatches[cardId].card}
                } else {
                    cardBatches[cardId] = {...cardSetDoneBatches[cardId]}
                }
            }
        }
        return cardsPatchRestBatch(dispatch, board.id, cardBatches, false);
    };
    return action;
};
