import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import {Dispatch, ThunkAction} from 'app/types/actions';
import { IGetState } from 'app/types/types';

export const onCancel = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.CANCEL_DESCRIPTION));
    };
    return action;
};
