import {ActionCreator} from 'redux';
import {Dispatch, ThunkAction} from '../../../../../../../types/actions';
import {IGetState} from '../../../../../../../types/types';
import { boardSettingsClickedSegmentEvent, ESegmentBoardMenuLocation } from '../../../../../../../middlewares/segment/trackEntities/boardMenuEvents';
import { openBoardSettings } from '../../../../../base/effects/openBoardSettings';
import { SegmentBoardSettingsSourceValue } from '../../../../../../../middlewares/segment/trackEntities/boardEvents';

export const onBoardsSettingsClicked: ActionCreator<ThunkAction> = (
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(openBoardSettings('', SegmentBoardSettingsSourceValue.TOP_BAR_DROPDOWN));
        dispatch(boardSettingsClickedSegmentEvent(ESegmentBoardMenuLocation.topBar));
    };
    return action;
};
