'use strict';
import * as _ from 'underscore';
/**
 * Inherit from this class when a collection needs to be updated via realtime;
 * add the following properties to the successor class: classKey
 */
App.Collections.BaseCollection = Backbone.Collection.extend({

    constructor: function(attributes, options) {
        Backbone.Collection.apply(this, arguments);

        if (this.initEvents){
            this.initEvents(options);
        }
    },

    save: function(options) {
        options = options || {};
        var method = (options.patch) ? 'patch' : 'update';
        Backbone.sync(method, this, options);
    },
    
    minifyForUpdate: function(fields) {
        return this.map(function(model) {
            return _.pick(model.toJSON(), fields);  
        });
    },

    /**
     * method for realtime;
    */
    updateFromJson: function(data, options) {
        if (_.isObject(data)) {
            options = options ? _.clone(options) : {};
            if (options.parse === void 0) options.parse = true;       
            this.set(this.parse(data, options), options);
        }
    }
    
});
