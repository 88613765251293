import { toggleFilter } from '../../../../main/topBar/hocs/TopBarFilterButtonHOC/effects/toggleFilter';
import { filterClickedSegmentEvent } from '../../../../main/topBar/effects/segmentEvents';
import { ESegmentFilterTarget } from '../../../../../../middlewares/segment/trackEntities/topBarEvents';
import { Dispatch, ThunkAction } from '../../../../../../types/actions';

export const onOpen = (
    boardId: number
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        dispatch(toggleFilter());
        dispatch(filterClickedSegmentEvent(ESegmentFilterTarget.options, boardId));
    };
    return action;
};
