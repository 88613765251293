import { connect } from 'react-redux';
import {
    getBoardCardDefaultProperties
} from '../../../../../../../../store/model/board/selectors/getBoardCardDefaultProperties';
import { IApplicationState } from '../../../../../../../../types/types';
import { CardPropertiesPanelButton } from '../../components/CardPropertiesPanelButton/CardPropertiesPanelButton';
import {
    ICardPropertiesPanelButtonEvents,
    ICardPropertiesPanelButtonFields
} from '../../components/CardPropertiesPanelButton/types';
import { onClick } from './events/onClick';
import { ICardPropertiesPanelButtonHOCProps } from './types';
import {
    getBoardCardCustomPropertyOptions
} from '../../../../../../../../store/model/board/selectors/getBoardCardCustomPropertyOptions';
import { EAsidePanelProperty } from '../../../../../asidePanel/components/AsidePanel/types';

const mapStateToProps = (
    state: IApplicationState,
    { boardId }: ICardPropertiesPanelButtonHOCProps
): ICardPropertiesPanelButtonFields => {
    const cardProperties = getBoardCardDefaultProperties(state, boardId);
    let indicatorNumber = cardProperties.size;
    if (cardProperties.has(EAsidePanelProperty.CARD_CUSTOM)) {
        const cardCustomProperties = getBoardCardCustomPropertyOptions(state, boardId);
        indicatorNumber += cardCustomProperties.length;
    }
    return {
        indicatorNumber,
    }
};

const mapDispatchToProps = (
    dispatch: any,
    {onClick: ownOnClick}: ICardPropertiesPanelButtonHOCProps
): ICardPropertiesPanelButtonEvents => {
    return {
        onClick: () => dispatch(onClick(ownOnClick))
    }
};

export const CardPropertiesPanelButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardPropertiesPanelButton);

CardPropertiesPanelButtonHOC.displayName = 'CardPropertiesPanelButtonHOC';
