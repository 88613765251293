import { Dispatch, ThunkAction } from '../../../types/actions';
import { IAuthUser } from '../../../store/model/authUser/types';
import { authUserPatch } from './api/helper/authUserPatch';
import { EFeature } from '../../../types/rest/IUserMeta';
import { IGetState } from '../../../types/types';
import { isNewFeatureForUser } from '../../../store/model/authUser/selectors/isNewFeatureForUser';
import { getAuthUser } from '../../../store/model/authUser/selectors/getAuthUser';

export const authUserAddFeatureToSeenMeta = (
    feature: EFeature
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (!isNewFeatureForUser(getState(), feature)) return;

        const authUser = getAuthUser(getState());
        const featuresSeenOld = authUser.meta && authUser.meta.featuresSeen || [];
        const actualFeatures = Object.keys(EFeature); // в мете сохраняются только актуальные фичи из EFeature
        const featuresSeen = featuresSeenOld.filter(feature => {
            return actualFeatures.includes(feature);
        });
        const userPatch: IAuthUser = {
            id: authUser.id,
            meta: {
                featuresSeen: [
                    ...featuresSeen,
                    feature
                ]
            }
        };
        return dispatch(authUserPatch(userPatch));
    };
    return action;
};
