import { getBoardInitial } from '../constants';
import { IBoard } from '../types';
import {
    AT_AUTH_PERMISSIONS_UPDATE,
    AT_COLOR_ADD,
    AT_COLOR_DELETE,
    AT_COLOR_UPDATE,
    AT_ROLE_ADD,
    AT_ROLE_UPDATE,
    AT_THEME_UPDATE,
    AT_UPDATE,
    TBoardAction
} from '../actions/types';

const initialState: IBoard = getBoardInitial();

export const boardReducer = (
    state = initialState,
    action: TBoardAction
): IBoard => {
    switch (action.type) {
        case AT_UPDATE: {
                return {
                    ...state,
                    ...action.board,
                    meta: {
                        ...state.meta,
                        ...action.board.meta,
                    },
                };
        }
        case AT_COLOR_UPDATE: {
            const colorIndex = state.colors && state.colors.findIndex(color => color.id === action.colorId);
            if (colorIndex < 0) return state;
            const board = {...state};
            const colors = [...board.colors];
            colors[colorIndex] = {
                ...colors[colorIndex],
                ...action.color
            }
            board.colors = colors;
            return board;
        }
        case AT_ROLE_UPDATE: {
            const index = state.cardAssigneeRoles.findIndex(role => role.id === action.roleId);
            if (index < 0) return state;
            const cardAssigneeRoles = [...state.cardAssigneeRoles];
            cardAssigneeRoles[index] = {
                ...state.cardAssigneeRoles[index],
                ...action.role
            }
            return {
                ...state,
                cardAssigneeRoles
            }
        }
        case AT_ROLE_ADD: {
            return {
                ...state,
                cardAssigneeRoles: [
                    ...state.cardAssigneeRoles,
                    action.role
                ]
            }
        }
        case AT_THEME_UPDATE: {
            return {
                ...state,
                theme: {
                    ...state.theme,
                    ...action.theme
                }
            }
        }
        case AT_AUTH_PERMISSIONS_UPDATE: {
            const boardAuthPermissions = state.permissions && state.permissions.authPermissions || {};
            return {
                ...state,
                permissions: {
                    ...state.permissions,
                    authPermissions: {
                        ...boardAuthPermissions,
                        ...action.authPermissions
                    }
                }
            }
        }
        case AT_COLOR_ADD: {
            return {
                ...state,
                colors: [
                    ...state.colors,
                    action.color
                ]
            }
        }
        case AT_COLOR_DELETE: {
            return {
                ...state,
                colors: state.colors.filter(color => color.id !== action.colorId)
            }
        }

        default:
            return state;
    }
};
