import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { editingPopupSaveEffect } from '../effects/editingPopupStateEffects';
import { TBoardId, TCardId } from '../../../../../../../../../types/types';

export const onSave: ActionCreator<ThunkAction> = (
    boardId: TBoardId,
    cardId: TCardId,
) => {
    const action = (
        dispatch: Dispatch,
    ) => {
        dispatch(editingPopupSaveEffect(boardId, cardId));
    };
    return action;
};
