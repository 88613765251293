import { createDeepEqualSelector } from './../../../../helpers/memoizeHelper';
import { IApplicationState, TCardId } from '../../../../../../types/types';
import { ICardDetailsTag } from '../components/CardTagsElements/types';
import { getCardTags } from '../../../../../../store/model/selectors/getCardTags';

type TgetCardTags = (
    state: IApplicationState,
    cardId: TCardId,
) => ICardDetailsTag[];

const getCardTagsSelector = (
    state: IApplicationState,
    cardId: TCardId
): ICardDetailsTag[] => {
    const cardTags = getCardTags(state, cardId);

    const tags = cardTags.map((tag) => {
        return {
            id: tag,
            name: tag
        };
    });
    return tags;
};

export const getCardDetailsTagsCreateSelector = (
): TgetCardTags => createDeepEqualSelector(
    getCardTagsSelector,
    tags => tags
);

export const getCardDetailsTags: TgetCardTags = getCardDetailsTagsCreateSelector();
