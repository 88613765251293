import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { SegmentCardEvent, SegmentCardOptions, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import {
    SegmentCardDetailsSourceValue,
    SegmentCardPasteFileTarget
} from '../../../../../../../../middlewares/segment/trackEntities/cardEvents';

export const addAttachmentSegmentEvent = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.ATTACH_FILE_BUTTON_CLICKED));
    };
    return action;
}

export const selectFileClickedSegmentEvent = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.ATTACH_FILE_SELECT_CLICKED));
    };
    return action;
}

export const attachmentDroppedSegmentEvent = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.ATTACH_FILE_DROPPED));
    };
    return action;
}

export const attachmentDroppedSourceSegmentEvent = (
    isPopup: boolean
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.ATTACH_FILE_DROPPED, {
            name: SegmentCardOptions.SOURCE,
            value: isPopup ? SegmentCardDetailsSourceValue.POPUP : SegmentCardDetailsSourceValue.DETAILS
        }));
    };
    return action;
}

export const attachmentPastedSegmentEvent = (
    target: SegmentCardPasteFileTarget
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.ATTACH_FILE_PASTED, {
            name: SegmentCardOptions.TARGET,
            value: target
        }));
    };
    return action;
}

export const attachmentPasteErrorSegmentEvent = (
    target: SegmentCardPasteFileTarget
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.ATTACH_FILE_PASTE_FAIL,  {
            name: SegmentCardOptions.TARGET,
            value: target
        }));
    };
    return action;
}
