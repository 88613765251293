import { EMPTY_ARRAY, IApplicationState } from '../../../../types/types';
import { getAuthUser } from './getAuthUser';
import { IRecentBoard } from '../../../../types/rest/IUserMeta';

export const getUserRecentBoards = (
    state: IApplicationState
): IRecentBoard[] => {
    const user = getAuthUser(state);
    return user.meta && user.meta.recentBoards ? user.meta.recentBoards.filter(board => board.id) : EMPTY_ARRAY;
};
