import { EHintKey } from 'app/view/react_components/hints';
import { Dispatch, ThunkAction } from 'app/types/actions';
import { IGetState } from 'app/types/types';
import { hintHide } from 'app/view/react_components/hints/effects/hintHide';

export const onCloseHint= (): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(hintHide(EHintKey.BOARD_SCROLL));
    };
    return action;
};
