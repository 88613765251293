import { getMessages } from '../../../../../../../../store/constants';
import { ISelectOptionsArray } from 'kui';
import { ENotificationPeriod } from '../../../../../../../../types/rest/IRestNotificationSettings';

export const BOARD_NOTIFICATION_UPDATES = getMessages().getText('board_settings.notification.updates');

export const periodSelectOptions: ISelectOptionsArray = [{
    value: ENotificationPeriod.SOON,
    text: getMessages().getText('board_settings.notification.send_email.soon')
}, {
    value: ENotificationPeriod.HOUR,
    text: getMessages().getText('board_settings.notification.send_email.hour')
}, {
    value: ENotificationPeriod.DAY,
    text: getMessages().getText('board_settings.notification.send_email.day')
}]
