import { TColorTagColor, ICardAssigneeRole } from '../../../../types/model';
import { IApplicationState, TBoardId } from '../../../../types/types';
import { getBoardRoles } from './getBoardRoles';
import { getBoard } from '../../selectors/getBoardById';

export const getBoardRoleByColor = (
    state: IApplicationState,
    boardId: TBoardId,
    color: TColorTagColor,
): ICardAssigneeRole  => {
    return getBoardRoles(getBoard(state, boardId)).find((role) => role.color === color);
}
