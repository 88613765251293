import { THintAction } from './../actions/types';
import { getHintInitial } from '../selectors/getHintInitial';
import { IHint } from '../types';
import { AT_STATUS_SET, AT_STEP_SET, AT_CAN_SHOW_SET } from '../actions/constants';

const initialState: IHint = getHintInitial();

export const hintReducer = (
    state = initialState,
    action: THintAction
): IHint => {
    switch (action.type) {
        case AT_STATUS_SET: {
            const { status } = action;
            return {
                ...state,
                status
            }
        }
        case AT_STEP_SET: {
            const { step } = action;
            return {
                ...state,
                step
            }
        }
        case AT_CAN_SHOW_SET: {
            const { canShow } = action;
            return {
                ...state,
                canShow
            }
        }
        default:
            return state;
    }
};
