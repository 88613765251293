import { getRest } from 'app/rest/autobackups/api/getRest';
import { IRestAutoBackup } from 'app/rest/autobackups/types';
import { Dispatch, ThunkAction } from 'app/types/actions';
import { EBackupExportType } from 'app/types/rest/IRestBackup';
import { IGetState, TBoardId } from 'app/types/types';
import { autoBackupIsLoadingSet } from '../store/autoBackups/actions/autoBackupIsLoadingSet';
import { autoBackupsAdd } from '../store/autoBackups/actions/autoBackupsAdd';
import { autoBackupsBoardActionSet } from '../store/autoBackupsBoard/actions/autoBackupsBoardActionSet';
import { IAutoBackupState } from '../store/autoBackupsBoard/types';

export const loadAutoBackups = (
    boardId: TBoardId,
    exportType: EBackupExportType
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(autoBackupsBoardActionSet(boardId, autoBackupIsLoadingSet(true)));
        dispatch(getRest(boardId, exportType)).then((autoBackup: IRestAutoBackup) => {
            if(autoBackup.id) {
                dispatch(autoBackupsBoardActionSet(boardId,autoBackupsAdd(autoBackup as IAutoBackupState)));
            }
            dispatch(autoBackupsBoardActionSet(boardId,autoBackupIsLoadingSet(false)));
        })
    };
    return action;
};
