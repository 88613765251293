import * as React from 'react';
import './_QualificationDialogAnswersCheckbox.scss';
import { QUALIFICATION_QUESTIONS } from '../../constants/questions';
import { Button, Checkbox } from 'kui';
import { IQualificationDialogAnswersCheckboxProps } from './types';
import { QualificationDialogAnswersText } from '../QualificationDialogAnswersText/QualificationDialogAnswersText';
import { EQualificationAnswerType, TQualificationQuestion } from '../QualificationDialog/types';
import {
    IUserMetaQualificationAnswer,
    IUserMetaQualificationAnswerParameters
} from '../../../../../../types/rest/IUserMeta';
import { getQuestionAnswerChildAnswerKeys } from '../../helpers/getQuestionAnswerChildAnswers';
import { QualificationDialogAnswersPhone } from '../QualificationDialogAnswersPhone/QualificationDialogAnswersPhone';
import { QUALIFICATION_MORE_DETAILS_PROMPT, QUALIFICATION_NEXT } from '../../constants';

export function QualificationDialogAnswersCheckbox ({
    questionKey,
    onAnswer,
    onShowCalendar
}: IQualificationDialogAnswersCheckboxProps) {
    const checkboxClassName = 'qualification-dialog-checkbox';
    const checkboxImportantClassName = 'qualification-dialog-checkbox-important';
    const childCheckboxClassName = 'qualification-dialog-child-checkbox';
    const checkboxTextClassName = 'qualification-dialog-checkbox-text';
    const [visibleFreeTextPanels, setVisibleFreeTextPanels] = React.useState([]);
    const [visiblePhoneInputPanels, setVisiblePhoneInputPanels] = React.useState([]);
    const [otherText, setOtherText] = React.useState('');
    const [otherChecked, setOtherChecked] = React.useState(false);
    const [phoneReceiveChecked, setPhoneReceiveChecked] = React.useState(false);
    const checkedAnswerKeysInit: string[] = [];
    const [checkedAnswerKeys, setCheckedAnswerKeys] = React.useState(checkedAnswerKeysInit);
    const answerParametersInit: IUserMetaQualificationAnswerParameters = {}
    const [answerParameters, setAnswerParameters] = React.useState(answerParametersInit);
    const [showPhoneNextButton, setShowPhoneNextButton] = React.useState(false);

    const isElementIndeterminate = (
        answerKeys: string[],
    ): boolean => {
        if (!answerKeys.length) return false;
        return answerKeys.some(answerKey => {
            return checkedAnswerKeys.includes(answerKey);
        });
    }

    const onCheckboxChangeHandler = (answerKey: string) => {
        let list = [...checkedAnswerKeys];
        const index = list.indexOf(answerKey);
        const checked = index < 0;
        if(question.answers[answerKey].type === EQualificationAnswerType.FREE_TEXT){
            setOtherChecked(checked);
            if(!checked)
                setOtherText('');
        }
        if (question.answers[answerKey].singleSelect) {
            setCheckedAnswerKeys(checked ? [answerKey] : []);
            if (checked) {
                setVisiblePhoneInputPanels([]);
            }
        } else {
            const childAnswerKeys = getQuestionAnswerChildAnswerKeys(questionKey, answerKey);
            const parent = question.answers[answerKey].parent;
            if (index >= 0) {
                list.splice(index, 1);
                if (parent) { // отчекать родителя
                    list = list.filter(key => key !== parent);
                } else if (childAnswerKeys.length) { // отчекать вложенные
                    list = list.filter(key => !childAnswerKeys.includes(key));
                }
            } else {
                if (childAnswerKeys.length) {
                    if (isElementIndeterminate(childAnswerKeys)) { // отчекать вложенные
                        list = list.filter(key => !childAnswerKeys.includes(key));
                    } else { // зачекать этот + вложенные
                        list.push(answerKey, ...childAnswerKeys);
                    }
                } else {
                    list.push(answerKey);
                    if (parent) {
                        const childAnswerKeys = getQuestionAnswerChildAnswerKeys(questionKey, parent);
                        if (childAnswerKeys.length && childAnswerKeys.every(key => { // если зачекали все вложенные => зачекать родителя
                            return list.includes(key);
                        })) {
                            list.push(parent);
                        }
                    }
                }
            }
            list = uncheckSingleSelectOptions(question, list);
            setCheckedAnswerKeys(list);
        }
        if (question.answers[answerKey].type === EQualificationAnswerType.FREE_TEXT) {
            const list = [...visibleFreeTextPanels];
            const index = list.indexOf(answerKey);
            if (index >= 0) {
                list.splice(index, 1);
            } else {
                list.push(answerKey);
            }
            setVisibleFreeTextPanels(list);
        }
        if (question.answers[answerKey].type === EQualificationAnswerType.PHONE_INPUT) {
            const list = [...visiblePhoneInputPanels];
            setPhoneReceiveChecked(checked);
            const index = list.indexOf(answerKey);
            if (index >= 0) {
                list.splice(index, 1);
            } else {
                list.push(answerKey);
            }
            setVisiblePhoneInputPanels(list);
        }
    }
    const uncheckSingleSelectOptions = (
        question: TQualificationQuestion,
        checkedAnswerKeys: string[]): string[] => {
        const result = [...checkedAnswerKeys]
        Object.keys(question.answers).forEach(answerKey => {
            if (question.answers[answerKey].singleSelect && result.includes(answerKey)) {
                const index = result.indexOf(answerKey);
                if (index >= 0) {
                    result.splice(index, 1);
                }
            }
        })
        return result;
    }
    const onPhoneNumberChange = (phone: string, showNextButton: boolean) => {
        setAnswerParameters({...answerParameters, ...{phone}});
        setShowPhoneNextButton(showNextButton);
    }
    const onSaveBtnHandler = () => {
        const answer: IUserMetaQualificationAnswer = {
            key: checkedAnswerKeys.join(','),
            checkboxKeys: checkedAnswerKeys,
            text: otherText,
            parameters: answerParameters
        }

        const answers = QUALIFICATION_QUESTIONS[questionKey].answers;
        const showCalendar = checkedAnswerKeys.some(answerKey => {
            return (answers[answerKey] && answers[answerKey].type === EQualificationAnswerType.BOOK_VIDEO_CALL)
        });
        if (showCalendar) {
            onShowCalendar();
        }
        onAnswer(answer);
    }

    const onSaveOtherText = (text: string) => {
        setOtherText(text);
    }

    const getImportant = (index: number) => {
        const text = [
            'Most important',
            'Important',
            'Least important'
        ];
        return (
            <div className={`
                ${checkboxImportantClassName}
                ${checkboxImportantClassName + '--important_' + index}
            `}>
                {text[index]}
            </div>
        );
    }

    const question = QUALIFICATION_QUESTIONS[questionKey];
    const answerKeys = Object.keys(question.answers);
    const showNext = (!otherChecked || (otherChecked && !!otherText && otherText.length > 0) ) &&
        (!phoneReceiveChecked || (phoneReceiveChecked && !!showPhoneNextButton));
    const buttonClassName = !otherChecked ? 'qualification-dialog__next-button' :
        'qualification-dialog__answers-text-next-button' +
        (!showNext ? ' qualification-dialog__answers-text-next-button--inactive' : '');

    return (
        <div>
            {answerKeys.map(answerKey => {
                const parentAnswerKey = question.answers[answerKey].parent;
                if (parentAnswerKey) return null;
                const childAnswerKeys: string[] = getQuestionAnswerChildAnswerKeys(questionKey, answerKey);
                const index = checkedAnswerKeys.indexOf(answerKey);
                const checked = index >= 0;
                const isKeyFactor = questionKey === 'work_key_factor' || questionKey === 'edu_key_factor';
                const disabled = !checked && isKeyFactor && checkedAnswerKeys.length >= 3;

                return <div
                    className={checkboxClassName}
                    key={questionKey + '-' + answerKey}
                >
                    <Checkbox
                        direction={'left'}
                        onChange={() => onCheckboxChangeHandler(answerKey)}
                        checked={checked}
                        disabled={disabled}
                        isIndeterminate={checked ? false : isElementIndeterminate(childAnswerKeys)}
                        isStateless={true}
                    >
                        {question.answers[answerKey].answer}
                        {isKeyFactor && checked && getImportant(index)}
                    </Checkbox>
                    {childAnswerKeys.map(childAnswerKey => {
                        return (<div key={questionKey + '-' + answerKey + '-' + childAnswerKey} className={childCheckboxClassName}>
                            <Checkbox
                            direction={'left'}
                            onChange={() => onCheckboxChangeHandler(childAnswerKey)}
                            checked={checkedAnswerKeys.includes(childAnswerKey)}
                        >
                            {question.answers[childAnswerKey].answer}
                        </Checkbox>
                        </div>)
                    })}
                    {question.answers[answerKey].type === EQualificationAnswerType.FREE_TEXT && visibleFreeTextPanels.includes(answerKey) &&
                        <div className={checkboxTextClassName}>
                            <QualificationDialogAnswersText
                                showSaveButton={false}
                                placeholder={QUALIFICATION_MORE_DETAILS_PROMPT}
                                onChange={(text) => onSaveOtherText(text)}
                            />
                        </div>
                    }
                    {question.answers[answerKey].type === EQualificationAnswerType.PHONE_INPUT && visiblePhoneInputPanels.includes(answerKey) &&
                    <div className={checkboxTextClassName}>
                        <QualificationDialogAnswersPhone
                            onChange={onPhoneNumberChange}
                        />
                    </div>
                    }
                </div>
            })}
            <Button
                className={buttonClassName}
                disabled={checkedAnswerKeys.length === 0 || !showNext}
                onClick={onSaveBtnHandler}
            >
                {QUALIFICATION_NEXT}
            </Button>
        </div>
    );
}
