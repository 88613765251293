import { Dispatch, ThunkAction } from '../../../types/actions';
import { TCardId } from '../../../types/types';
import { checklistsActionSetAction } from '../actions/checklistsActionSetAction';
import { checklistsSetAction } from '../checklists/checklists/actions/checklistsSetAction';
import { ICardChecklists } from '../checklists/checklists/types';

export const checklistsSetStore = (
    cardId: TCardId,
    checklists: ICardChecklists
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(checklistsActionSetAction(checklistsSetAction(cardId, checklists)));
    };
    return action;
};
