import { filterReducer, initialState as initialStateFilter } from '../../filter/reducers/filterReducer';
import { TFilterPanelAction } from '../actions/types';
import {
    AT_BLOCKED_SET,
    AT_DEFAULT_FILTER_ACTION_SET,
    AT_DEFAULT_FILTER_RESET,
    AT_EDITED_FILTER_ACTION_SET,
    AT_EDITED_FILTER_SET,
    AT_ENABLED_SET,
    AT_INITIAL_STATE_SET,
    AT_IS_INITIAL_SET,
    AT_KEY_WORD_FILTRATION_ENABLED_SET,
    AT_LOADING_SET,
    AT_PANEL_OPENED_SET,
    AT_SAVED_FILTER_IDS_SET,
} from '../actions/constants';
import { FP_EDITED_FILTER_INITIAL_VALUE, INITIAL, } from '../../../constants';
import { cloneFilterObject } from '../../../helpers/cloneFilterObject';
import { EMyWorkGroupType, IFilterPanel } from '../types';
import * as deepFreeze from 'deep-freeze';

export const initialState: IFilterPanel = {
    isLoading: INITIAL,
    isBlocked: false,
    isEnabled: false,
    isTopBarFiltrationEnabled: false,
    defaultFilter: cloneFilterObject(initialStateFilter),
    editedFilter: FP_EDITED_FILTER_INITIAL_VALUE,
    savedFilterIds: [],
    isOpened: false,
    isInitial: true,
};

const _filterPanelReducer = (
    state = initialState,
    action: TFilterPanelAction
): IFilterPanel => {
    switch (action.type) {
        case AT_LOADING_SET: {
            return {
                ...state,
                isLoading: action.isLoading
            };
        }
        case AT_BLOCKED_SET: {
            return {
                ...state,
                isBlocked: action.isBlocked,
            };
        }
        case AT_ENABLED_SET: {
            return {
                ...state,
                isEnabled: action.isEnabled
            }
        }
        case AT_KEY_WORD_FILTRATION_ENABLED_SET: {
            return {
                ...state,
                isTopBarFiltrationEnabled: action.isKeywordFiltrationEnabled,
                isInitial: false,
            }
        }
        case AT_DEFAULT_FILTER_ACTION_SET: {
            return {
                ...state,
                defaultFilter: filterReducer(state.defaultFilter, action.defaultFilterAction),
                isInitial: false,
            };
        }
        case AT_EDITED_FILTER_ACTION_SET: {
            return {
                ...state,
                editedFilter: filterReducer(state.editedFilter, action.editedFilterAction),
                isInitial: false,
            };
        }
        case AT_EDITED_FILTER_SET: {
            return {
                ...state,
                editedFilter: action.editedFilter,
                isInitial: false,
            };
        }
        case AT_DEFAULT_FILTER_RESET: {
            return {
                ...state,
                defaultFilter: cloneFilterObject(initialStateFilter),
                isInitial: false,
            }
        }
        case AT_SAVED_FILTER_IDS_SET: {
            return {
                ...state,
                savedFilterIds: action.savedFilterIds,
            }
        }
        case AT_PANEL_OPENED_SET: {
            return {
                ...state,
                isOpened: action.isOpened
            }
        }
        case AT_IS_INITIAL_SET: {
            return {
                ...state,
                isInitial: action.isInitial
            }
        }
        case AT_INITIAL_STATE_SET:
            return initialState;

        default:
            return state;
    }
};

export const filterPanelReducer = (
    state = initialState,
    action: TFilterPanelAction
): IFilterPanel => {
    // @ts-ignore
    if (window.Settings.development) {
        // @ts-ignore
        return deepFreeze(_filterPanelReducer(state, action));
    } else {
        return _filterPanelReducer(state, action);
    }
};
