import { ICardPropertyElement } from '../../../components/CardProperties/types';

export const checkIfAllCardPropertiesAreDefault = (
    cardProperties: ICardPropertyElement[]
): boolean => {
    let isAllPropertiesAreDefault = true;
    for (let i = 0; i < cardProperties.length; i++) {
        if (cardProperties[i].options) {
            for (let j = 0; j < cardProperties[i].options.length; j++) {
                if (!cardProperties[i].options[j].isDefault) {
                    isAllPropertiesAreDefault = false;
                    break;
                }
            }
        } else if (!cardProperties[i].isDefault) {
            isAllPropertiesAreDefault = false;
        }

        if (!isAllPropertiesAreDefault) break;
    }
    return isAllPropertiesAreDefault;
}
