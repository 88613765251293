import * as React from 'react';
import './_CalendarMonth.scss';
import { ICalendarDay, ICalendarMonthProps } from './types';
import { CLASS_DATEPICKER } from '../constants';
import { Button } from 'kui';
import { checkDate } from './helpers/checkDate';
import { isDateInBetween } from './helpers/isDateInBetween';
import { CALENDAR_MONTH_OPTIONS } from './constants';
import { getYearOptions } from './helpers/getYearOptions';
import { getDeepDateCheck } from '../../helpers/getDeepDateCheck';
import { GOOGLE_SPACING } from '../../../../../../../const';
import { CalendarSelect } from '../CalendarSelect/CalendarSelect';
import { DatepickerContext } from '../Datepicker/constants';

export const CalendarMonth = ({
    date,
    fromDate,
    isAbove,
    isHead,
    isNoDate,
    maxDate,
    maxFromDate,
    minDate,
    minToDate,
    selectedDate,
    toDate,
    onChange,
    onMonthChange,
    onYearChange
}: ICalendarMonthProps) => {
    const {
        isTime,
        isAllowSameDate,
        isCalendarStartsSunday
    } = React.useContext(DatepickerContext);

    const yearOptions = getYearOptions();

    const className = CLASS_DATEPICKER + '__calendar-month';
    const classHeader = className + '-header';
    const classHeaderItem = classHeader + '-item';
    const classDay = className + '-day';

    const days: ICalendarDay[][] = [[]];
    const month = date.getMonth();
    const year = date.getFullYear();

    const onMonthSelect = (idx: number) => {
        onMonthChange(idx)
        onFocusCalendar();
    };

    const onYearSelect = (idx: number) => {
        onYearChange(yearOptions[idx]);
        onFocusCalendar();
    };

    const onEnter = (
        e: React.KeyboardEvent,
        date: Date
    ) => {
        if (e.key === 'Enter') {
            onChange(date, true);
        }
    };

    const onFocusCalendar = () => {
        const calendar = document.querySelector('.' + CLASS_DATEPICKER + '__calendar') as HTMLElement;
        if (calendar) calendar.focus();
    };

    const getDay = (date: Date) => { // mo - 0 ... su - 6
        let day = date.getDay();

        if (isCalendarStartsSunday) return day;

        if (day === 0) day = 7;
        return day - 1;
    };

    for (let i = 0; i < getDay(date); i++) { // fill empty days before
        days[0].push(null);
    }

    let j = 0;
    for (;;) { // fill days
        if (date.getMonth() !== month) break;
        const day = date.getDate();
        days[j].push({
            title: day,
            value: new Date(date)
        });
        date.setDate(day + 1);
        if (days[j].length === 7) {
            days.push([]);
            j++;
        }
    }

    for (;;) { // fill empty days after
        if (
            !days[j] ||
            !days[j][0] ||
            (days[j][0] &&
            days[j].length === 7)
        ) {
            if (days[j] && !days[j][0]) days.pop();
            break;
        }
        days[j].push(null);
    }

    const secondDate = toDate ? toDate : fromDate;

    return (
        <div className={className}>
            <div
                className={`
                    ${classHeader}
                    ${isHead || isAbove ? classHeader + '--sticky' : ''}
                `}
            >
                {
                    isHead ?
                        <CalendarSelect
                            options={CALENDAR_MONTH_OPTIONS}
                            selectedIdx={month}
                            width={GOOGLE_SPACING * 11}
                            onSelect={onMonthSelect}
                        />
                        : <span className={classHeaderItem}>
                            {CALENDAR_MONTH_OPTIONS[month]}
                        </span>
                }
                {isHead &&
                    <CalendarSelect
                        options={yearOptions}
                        selectedIdx={yearOptions.indexOf(year)}
                        width={GOOGLE_SPACING * 7}
                        onSelect={onYearSelect}
                    />
                }
            </div>
            {
                days.map((week, index) => (
                    <div className={className + '-week'} key={index}>
                        {
                            week.map((day, index) => {
                                if (!day) return <div key={index} className={classDay + '--empty'} />;
                                const { value, title } = day;
                                const valueWithNoTime = new Date(value);
                                valueWithNoTime.setHours(0, 0, 0, 0);
                                return (
                                    <Button
                                        key={index}
                                        className={`
                                            ${classDay}
                                            ${classDay + '--' + valueWithNoTime.getTime()}
                                            ${checkDate(new Date(), value) ? classDay + '--today' : ''}
                                            ${!isNoDate && checkDate(selectedDate, value) ? classDay + '--selected' : ''}
                                            ${checkDate(toDate || fromDate, value) ? classDay + '--interval-end' : ''}
                                            ${!isNoDate && isDateInBetween(value, selectedDate, secondDate) ? classDay + '--interval' : ''}
                                        `}
                                        disabled={
                                            (minDate && value < minDate) ||
                                            (maxDate && value > maxDate) ||
                                            !getDeepDateCheck(value, maxFromDate, minToDate, isTime || isAllowSameDate) ||
                                            !getDeepDateCheck(value, fromDate, toDate, isTime || isAllowSameDate)
                                        }
                                        onClick={() => onChange(value)}
                                        onKeyDown={(e) => onEnter(e, value)}
                                        variant={'secondary'}
                                    >
                                        {title}
                                    </Button>
                                );
                            })
                        }
                    </div>
                ))
            }
        </div>
    );
}
