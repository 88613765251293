import { IApplicationState } from '../../../../types/types';
import { getAuthUser } from './getAuthUser';

export const isPwc = (
    state: IApplicationState
): boolean => {
    const pwcReg = /(@pwc.com|@pwc.ru|kanbanchi.autotester2@gmail.com|kanbanchi.autotester6@gmail.com)$/;
    //const pwcReg = /magicwebsolutions.co.uk$/;

    const email = getAuthUser(state).email;
    return pwcReg.test(email);
};
