import { EViewTypes } from '../../const';
import { IAuthUser } from '../../store/model/authUser/types';
import { EHTMLEditorType, TBoardId, TFilterId } from '../types';
import { IBoardTheme } from '../../store/model/board/types';
import { EMyWorkGroupType } from '../../view/react_components/aside_panel/filterPanelBoard/store/filterPanel/types';
import { EUserDateformat } from './IRestAuthUser';
import { EQuickStartGuideType } from '../../view/react_components/main/navigationPanel/constants';

export interface IUserMeta {
    checklistOrderMode?: ECardChecklistOrderMode;
    defaultView?: EViewTypes;
    freeVersionAfterFirstTrial?: boolean;
    refferal?: string;
    landingPage?: string;
    isHideDoneChecklistItems?: boolean;
    isShowChecklistItemsAuthor?: boolean;
    isHideDoneSubcards?: boolean;
    recentBoards?: IRecentBoards;
    useType?: EUseType;
    myWork?: IUserMetaMyWork;
    newCardsCount?: number;
    featuresSeen?: EFeature[];
    affiliateDialogShownDate?: number;
    qualificationForm?: IUserMetaQualificationForm;
    abandonedCart?: EAbandonedCartPlan,
    lastLogin?: string,
    dayInSystem?: string,
    webinarFinished?: boolean;
    snoozeBlockerTime?: number;
    haveSharedBoardWith2Other?: boolean;
    isAttachmentViewTypeGrid?: boolean;
    isDontShowShareHint?: boolean; // KNB-2097
    ganttOpened?: boolean;
    noGanttUsageSent?: boolean;
    lastUpdateShownTime?: number;
    isOpenLastBoard?: boolean; // KNB-2500 on start up if no board - auto open last used
    sortedSavedFiltersIds?: TSortedSavedFiltersIds;
    rateAppPoll?: IRateAppPoll;
    pricingPoll?: IOneTimePoll;
    loginSpecificBoard?: ILoginSpecificBoard;
    rateFeaturePoll?: IRateFeaturePoll;
    messengerPoll?: IOneTimePoll;
    dateFormat?: EUserDateformat;
    defaultAttachmentType?: EDefaultAttachmentType;
    HTMLEditorType?: EHTMLEditorType;
    proFeaturesUsage?: IProFeaturesUsage;
    isCalendarStartSunday?: boolean;
    recentAccordion?: ERecentAccordion;
    watchedTutorials?: ETutorial[];
    quickStartGuides?: IQuickStartGuides;
    starredBoards?: IRecentBoards;
    isStarredNotificationsGrouped?: boolean;
    promoCode?: IPromoCode;
}

export interface IQuickStartGuides {
    [key: string]: IQuickStartGuide
}

export interface IQuickStartGuide {
    key: EQuickStartGuideType;
    isFinished?: boolean;
    steps: IQuickStartGuideStep[];
}

export interface IQuickStartGuideStep {
    completed?: boolean;
    key: number;
}

export interface IPromoCode {
    code?: string;
    discount: number;
    isShowed?: boolean;
}

export enum ERecentAccordion {
    RECENT = 'RECENT',
    STARRED = 'STARRED',
};

export interface IRateFeaturePoll {
    triggeredFeatureId?: ERateFeature; // сбрасывать после показа
    answeredFeaturePolls?: ERateFeature[]; // id пройденных опросов
    skipDate?: number; // общий скип, чтобы не доставать юзера
}

export enum ETutorial {
    SIGN_IN_TUTORIAL = 0,
    CARD_DETAILS_TUTORIAL = 1,
    GANTT_VIEW_TUTORIAL = 2,
    LIST_VIEW_TUTORIAL = 3,
    ARCHIVE_TUTORIAL = 4,
    SUBCARD_OR_CHECKLIST_TUTORIAL = 5,
    FILTER_TUTORIAL = 6,
    TIME_TRACKER_TUTORIAL = 7,
}

export type TSortedSavedFiltersIds = {
    [boardId: number]: TFilterId[];
}

export interface IProFeaturesUsage {
    time: number;
    usedFeatures: Array<keyof IAuthUser>;
}

interface IPoll {
    lastTimeShowed: number;
    isSkipped: boolean;
}

export interface IRateAppPoll extends IPoll {}
export interface IOneTimePoll extends IPoll {
    isShowed?: boolean;
    isTriggered?: boolean;
}

export enum EAbandonedCartPlan {
    BASIC = 'Basic',
    PREMIUM = 'Premium',
    PROFESSIONAL = 'Professional',
    ENTERPRISE = 'Enterprise'
}

export interface ILoginSpecificBoard {
    id: number;
    name: string;
}

export type IRecentBoards = IRecentBoard[];

export enum ERecentBoardKeys { // sync with IRecentBoard
    id,
    name,
    logo,
    logoColor,
    logoIcon,
}

export interface IRecentBoard { // sync with ERecentBoardKeys
    id: number;
    name: string;
    logo?: string;
    logoColor?: string;
    logoIcon?: string;
}

export enum ECardChecklistOrderMode {
    MANUAL = 'MANUAL',
    DONE_FIRST = 'DONE_FIRST',
    DONE_LAST = 'DONE_LAST'
}

export const CHECKLIST_ORDERS: ReadonlyArray<ECardChecklistOrderMode> = Object.freeze([
    ECardChecklistOrderMode.MANUAL,
    ECardChecklistOrderMode.DONE_FIRST,
    ECardChecklistOrderMode.DONE_LAST,
]);

export enum EUseType {
    TEAM = 'team',
    PERSONAL = 'personal',
};

export enum EUserMetaAssignedToMeSortField {
    name = 'cardFullName',
    author = 'author',
    list = 'listName',
    priority = 'cardPriority',
    progress = 'cardProgress',
    startDate = 'cardStartDate',
    dueDate = 'cardDueDate',
    board = 'boardName',
    assign = 'cardAssignDate',
    updates = 'updates',
    assignees = 'assignees',
    colorLabels = 'cardColorLabels',
    color = 'cardColor',
    created = 'cardCreatedDate',
}

export interface IUserMetaMyWorkSortType {
    field: EUserMetaAssignedToMeSortField;
    isDesc: boolean;
}

export enum EProcessingStatus {
    inProgress = 'inProgress',
    upcoming = 'upcoming',
    done = 'done',
    overdue = 'overdue',
    readyToGo = 'readyToGo',
    noDates = 'noDates'
}

export enum EMyWorkCardFetchOption {
    DAYS_7 = 7,
    DAYS_30 = 30,
    DAYS_90 = 90,
    DAYS_180 = 180
}

export interface IBoardsFetchCard {
    [boardId: number]: EMyWorkCardFetchOption;
}

export interface IUserMetaMyWork {
    boardsFetchCards?: IBoardsFetchCard;
    boardsUpdatesLastSeen?: number;
    columns?: IMyWorkColumn[];
    unselectedBoards?: TBoardId[]; // for unselected new boards
    selectedBoards?: TBoardId[]; // for selected old boards
    isHideEmptyGroups?: boolean;
    isHideColumnsTitle?: boolean;
    isShowArchivedCards?: boolean;
    isFilterBarCollapsed?: boolean;
    sortType?: IUserMetaMyWorkSortType;
    theme?: IBoardTheme;
    groupBy?: EMyWorkGroupType;
    isHideMoreBoardsSnackbar?: boolean;
    enabledFilters?: TFilterId[];
}

export interface IMyWorkColumn {
    title: string;
    isHidden: boolean;
}

// Новые фичи для юзера, складываются в юзера. см. authUserAddFeatureToSeenMeta и isNewFeatureForUser
export enum EFeature {
    PASTE_ATTACHMENT = 'PASTE_ATTACHMENT', // getIsNewFeaturePasteAttachment
    WYSIWYG = 'WYSIWYG', // getIsNewFeatureWYSIWYG
    TOPBAR_REDESIGN = 'TOPBAR_REDESIGN', // getIsNewFeatureTopbarRedesign - точка, ведущая в панель релизов
    TOPBAR_REDESIGN_TIPS = 'TOPBAR_REDESIGN_TIPS', // getIsNewFeatureTopbarRedesignTips - фиче-типсы показывались
    TOPBAR_REDESIGN_TIPS_SEEN = 'TOPBAR_REDESIGN_TIPS_SEEN', // getIsNewFeatureTopbarRedesignTipsSeen - фиче-типсы просмотрены полностью
    MY_WORK = 'MY_WORK', // getIsNewFeatureMyWork
    LOGIN_SPECIFIC_BOARD = 'LOGIN_SPECIFIC_BOARD', // getIsNewFeatureLoginSpecificBoard
}

export const TOPBAR_REDESIGN_RELEASE = 1696291200; // 2023-10-03
export const WYSIWYG_RELEASE = 1696968000; // 2023-10-11
export const LOGIN_SPECIFIC_BOARD_RELEASE = 1701648000; // 2023-12-04
export const QUICK_START_GUIDES_RELEASE = 1713916800; // 2024-04-24

export type IUserMetaQualificationAnswers = {
    [key: string]: IUserMetaQualificationAnswer
};

export type IUserMetaQualificationAnswerParameters = {
    [key: string]: string
};

export type IUserMetaQualificationAnswer = {
    key: string,
    text?: string,
    checkboxKeys?: string[],
    parameters?: IUserMetaQualificationAnswerParameters
}

export enum EUserMetaQualificationFormStatus {
    FIRST_TIME = 'FIRST_TIME',
    ACTIVE = 'ACTIVE',
    SKIPPED = 'SKIPPED',
    COMPLETE = 'COMPLETE'
}
export interface IUserMetaQualificationForm {
    answers?: IUserMetaQualificationAnswers;
    status: EUserMetaQualificationFormStatus;
    intercomMessageType?: string;
    intercomHelpAsked?: boolean;
}

export enum ERateFeature {
    CHECKLISTS = 1,
    COLOR_TAGS = 2,
    CARD_COLORS = 3,
    NAV_PANEL = 4,
    TOP_BAR = 5,
    NEW_CHECKLISTS = 6,
    NEW_NAV_PANEL = 7,
    NEW_TOP_BAR = 8,
    MY_TASKS = 9,
    NOTIFICATIONS = 10,
}

export enum EDefaultAttachmentType {
    DEVICE = 'DEVICE',
    DRIVE = 'DRIVE',
}
