import { TCardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { ICards } from '../../../../store/model/cards/types';
import { cardsRestPatch } from './api/helper/cardsRestPatch';

export const cardSetEstimate = (
    cardId: TCardId,
    estimate: number
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        const cards: ICards = {
            [cardId]: {
                estimate: estimate ? estimate : null
            }
        };
        return dispatch(cardsRestPatch(cards));
    };
    return action;
};
