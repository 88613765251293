import { getMessages } from '../../../../store/constants';

export const ADD_NEW_LIST = getMessages().getText('kanban.add_new_list');
export const ATTACHMENTS_TOOLTIP = getMessages().getText('kanban.attachments_tooltip');
export const CHECKLISTS_TOOLTIP = getMessages().getText('kanban.checklists_tooltip');
export const COMMENTS_TOOLTIP = getMessages().getText('kanban.comments_tooltip');
export const SUBCARDS_TOOLTIP = getMessages().getText('kanban.subcards_tooltip');
export const DESCRIPTION_TOOLTIP = getMessages().getText('kanban.description_tooltip');
export const DRIVE_DOC_TOOLTIP = getMessages().getText('kanban.drive_doc_download_tooltip');

export const COPY_CARD_ACTION = getMessages().getText('kanban.card.copy_action');
export const GET_CARD_LINK_ACTION = getMessages().getText('kanban.card.get_link_action');
export const PRINT_CARD_ACTION = getMessages().getText('kanban.card.print_action');
export const ARCHIVE_CARD_ACTION = getMessages().getText('kanban.card.archive_action');
export const UNARCHIVE_CARD_ACTION = getMessages().getText('kanban.card.unarchive_action');
export const DELETE_CARD_ACTION = getMessages().getText('kanban.card.delete_action');
export const DONE_CARD_ACTION = getMessages().getText('kanban.card.done_action');
export const MORE_ACTIONS_CARD_ACTION = getMessages().getText('kanban.card.more_action');
export const EDIT_CARD_ACTION = getMessages().getText('kanban.card.edit_action');
export const RENAME_CARD_ACTION = getMessages().getText('kanban.card.rename_action');
export const ASSIGN_CARD_ACTION = getMessages().getText('kanban.card.assign_action');
export const DATES_CARD_ACTION = getMessages().getText('kanban.card.dates_action');
export const COLOR_LABEL_CARD_ACTION = getMessages().getText('kanban.card.color_label_action');
export const PRIORITY_CARD_ACTION = getMessages().getText('kanban.card.priority_action');
export const VIEW_ON_BOARD = getMessages().getText('kanban.card.view_on_board_action');
export const GET_LINK_CARD_ACTION = getMessages().getText('kanban.card.get_link_to_card_action');
export const COLOR_TAGS_LABEL = getMessages().getText('kanban.card.color_tags_action');

export const COLOR_LIST_ACTION = getMessages().getText('kanban.list.color_action');
export const EDIT_LIST_ACTION = getMessages().getText('kanban.list.edit_action');
export const UNARCHIVE_LIST_ACTION = getMessages().getText('kanban.list.unarchive_action');
export const ARCHIVE_LIST_ACTION = getMessages().getText('kanban.list.archive_action');
export const FOLD_LIST_ACTION = getMessages().getText('kanban.list.fold_action');
export const FOLD_ALL_OTHER_LISTS_ACTION = getMessages().getText('kanban.list.fold_other_lists_action');
export const MOVE_LIST_ACTION = getMessages().getText('kanban.list.move_to_action');
export const SELECT_CARDS_ACTION = getMessages().getText('kanban.list.select_all_action');
export const GET_LINK_TO_LIST = getMessages().getText('kanban.list.get_link_to_list_action');
export const COPY_ACTION = getMessages().getText('kanban.list.copy_action');
export const COPY_WITH_CARDS_ACTION = getMessages().getText('kanban.list.copy_with_cards_action');
export const PRINT_LIST_ACTION = getMessages().getText('kanban.list.print_action');
export const DELETE_LIST_ACTION = getMessages().getText('kanban.list.delete_action');
export const DELETE_LIST_WITH_CARDS_ACTION = getMessages().getText('kanban.list.delete_with_cards_action');
export const CREATE_LIST_RIGHT = getMessages().getText('kanban.list.create_right');

export const START_DATE_TOOLTIP = getMessages().getText('kanban.start_date_tooltip');
export const DUE_DATE_TOOLTIP_PREFIX = getMessages().getText('kanban.due_date_tooltip');
export const ESTIMATE_TOOLTIP_PREFIX = getMessages().getText('kanban.estimate_tooltip');

export const TOOLTIP_ACTIONS_LIST = getMessages().getText('kanban.list_header_action_tooltip');
export const TOOLTIP_ACTIONS_CARD = getMessages().getText('kanban.card_action_tooltip');
export const TOOLTIP_FOLD_LIST = getMessages().getText('list_header_fold_list');
export const TOOLTIP_UNFOLD_LIST = getMessages().getText('list_header_unfold_list');
export const TOOLTIP_CREATE_CARD = getMessages().getText('list_header_create_card');
export const TOOLTIP_CANCEL = getMessages().getText('list_header_cancel');
export const TOOLTIP_LIST_DESCRIPTION = getMessages().getText('kanban.list.description_tooltip');
export const NEW_CARD_INPUT_DESCRIPTION_PLACEHOLDER = getMessages().getText('list_new_card_form_placeholder.description');
export const NEW_CARD_INPUT_NAME_PLACEHOLDER = getMessages().getText('list_new_card_form_placeholder');
export const NEW_CARD_INPUT_NAME_PLACEHOLDER_MOBILE = getMessages().getText('list_new_card_form_placeholder.mobile');
export const NEW_CARD_INPUT_NAME_PLACEHOLDER_MAC = getMessages().getText('list_new_card_form_placeholder.mac');
export const NEW_CARD_INPUT_ADD = getMessages().getText('list_new_card_form_add');

export const DEFAULT_CARD_NAME = getMessages().getText('kanban.default.card.name');
export const TOOLTIP_CARD_FROM_TEMPLATE = getMessages().getText('kanban.list_card_templates_tooltip');

export const TITLE_OVERALL_PROGRESS = getMessages().getText('kanban.card.overall_progress');
export const TITLE_SUBCARD = getMessages().getText('kanban.card.subcard')
export const TITLE_SORT_CARDS = getMessages().getText('kanban.list_header_sort_cards');
export const CHECKLIST_TOOLTIP = getMessages().getText('common.checklist');

export const getEpicTitle = ( // todo plural
    subCardsCount: number
) => getMessages().getText('kanban.card.epic-title', null, subCardsCount, subCardsCount > 1 ? 's' : '');
