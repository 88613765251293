import { TBoardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { fetchHandler } from '../../../../util/fetchHandler';
import { Util } from '../../../../util/util';
import { REST_BOARD, REST_NOTIFICATIONS_SETTINGS } from '../../../constants';
import { getRestHeadersPatch } from '../../../helpers/getRestHeadersHelper';
import { INotificationSettings } from '../../../../store/model/notificationSettings/types';

export const patchRest = (
    boardId: TBoardId,
    notification: INotificationSettings
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        return fetchHandler<INotificationSettings>(
            Util.getApiUrl(REST_BOARD + boardId + REST_NOTIFICATIONS_SETTINGS), {
                ...getRestHeadersPatch(),
                body: JSON.stringify(notification)
            }
        );
    };
    return action;
};
