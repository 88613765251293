import {Dispatch, ThunkAction} from '../../../types/actions';
import {fetchHandler} from '../../../util/fetchHandler';
import Util from '../../../util/util';
import {REST_AUTH_USER, REST_UMUX} from '../../constants';
import {getRestHeadersPatch, getRestHeadersPost} from '../../helpers/getRestHeadersHelper';
import {IUMUXPollRest} from './types';

export const authUserPostUmux = (
    umuxPoll: IUMUXPollRest
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        return fetchHandler(Util.getApiUrl(REST_UMUX), {
            ...getRestHeadersPost(),
            body: JSON.stringify(umuxPoll)
        })
    }
    return action;
};
