import {
    LABEL_WEEKDAY_FIRST,
    LABEL_WEEKDAY_FOURTH,
    LABEL_WEEKDAY_LAST,
    LABEL_WEEKDAY_SECOND,
    LABEL_WEEKDAY_THIRD
} from '../wording';

export const WEEK_POSITIONS = [
    LABEL_WEEKDAY_FIRST,
    LABEL_WEEKDAY_SECOND,
    LABEL_WEEKDAY_THIRD,
    LABEL_WEEKDAY_FOURTH,
    LABEL_WEEKDAY_LAST
];
