import {ActionCreator} from 'redux';
import {Dispatch, ThunkAction} from '../../../../../../../types/actions';
import {IGetState} from '../../../../../../../types/types';
import {boardImportBoardClickedSegmentEvent} from '../../../effects/segmentEvents';
import {root} from '../../../../../../../store/constants';

export const onImport: ActionCreator<ThunkAction> = () => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        root.App.controller.showImport();
        dispatch(boardImportBoardClickedSegmentEvent());
    };
    return action;
};
