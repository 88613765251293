import { IGetState } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IRestChecklistItem } from '../../../../../../../../types/rest/IRestChecklistItem';
import { ICard, INewCard } from '../../../../../../../../store/model/card/types';
import { TStatus } from '../../../../../../../../types/model';
import { IRestCard } from '../../../../../../../../types/rest/IRestCard';
import { cardAdd } from '../../../../../../../../rest/effects/card/card/cardAdd';
import { root } from '../../../../../../../../store/constants';
import { object } from 'underscore';
import {
    EUserProductivityEventStatus
} from '../../../../../../reports/UserProductivity/UserProductivityReport/rest/types';
import {
    sendCardStatWithIncrement,
    sendUserProductivityStatsByCardId
} from '../../../../../../../../helper/comet/stat_helper_ts';
import { getList } from '../../../../../../../../store/model/list/selectors/getList';
import { ICards } from '../../../../../../../../store/model/cards/types';
import {
    getCardEpicsForDueDateChange
} from '../../../../../../../../store/model/selectors/getCardEpicsForDueDateChange';
import { cardsRestPatch } from '../../../../../../../../rest/effects/card/card/api/helper/cardsRestPatch';
import { createCardEvent } from '../../../../../../../../middlewares/segment/segmentEvents/cardSegmentEvents';
import { getNextSubCardOrderNumber } from '../../../../../../../../store/model/selectors/getNextSubCardOrderNumber';
import {
    getNextCardOrderNumberAfterCardId
} from '../../../../../../../../store/model/list/selectors/getNextCardOrderNumberAfterCardId';
import {
    getCardEpicsForStartDateChange
} from '../../../../../../../../store/model/selectors/getCardEpicsForStartDateChange';
import { getBoardMetaDefaultTemplateCardId } from 'app/store/model/selectors/getBoardMetaDefaultTemplateCardId';
import { getActiveBoardId } from 'app/store/model/selectors/getActiveBoardId';
import { getCard } from 'app/store/model/selectors/getCard';
import { cardFullGetRest } from '../../../../../../../../rest/effects/card/card/api/helper/cardFullGetRest';
import { createCardFromTemplate } from '../../../../../../../../rest/effects/card/template/createCardFromTemplate';
import { IAssigneeRest } from '../../../../../../../../types/rest/IAssigneeRest';
import { checkCopyCardWithSubcards } from '../../../../../../../../rest/effects/card/template/checkCopyCardWithSubcards';

export const createSubcardByChecklistItem = (
    epicCard: ICard,
    checklistItem: IRestChecklistItem
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const boardId = getActiveBoardId(state);
        const defaultTemplateCardId = getBoardMetaDefaultTemplateCardId(state, boardId);

        if (defaultTemplateCardId) {
            dispatch(cardFullGetRest(defaultTemplateCardId))
            const templateCard = getCard(getState(), defaultTemplateCardId);
            const assigneesArr = checklistItem.assignees.concat(templateCard.assignees)
            const uniqueArr: IAssigneeRest[] = Object.values(
                assigneesArr.reduce((acc, obj) => ({ ...acc, [obj.sharedUser.permissionId]: obj }), {})
            );
            const assignees = uniqueArr || [];
            const options: Partial<ICard> = {
                assignees: assignees,
                epicId: epicCard.id,
                startDate: checklistItem.startDate ? checklistItem.startDate : templateCard.startDate,
                dueDate: checklistItem.dueDate ? checklistItem.dueDate : templateCard.dueDate,
                estimate: checklistItem.estimate ? checklistItem.estimate : templateCard.estimate,
                name: checklistItem.name? checklistItem.name : templateCard.name,
            }
            dispatch(checkCopyCardWithSubcards(defaultTemplateCardId, 'snackbar.copy.with_subcards.text')).then((copySubCards: boolean) => {
                dispatch(createCardFromTemplate(defaultTemplateCardId, epicCard.listId, copySubCards, options, true, null))
            });
            return
        }

        let subCard: INewCard = {
            name: checklistItem.name,
            listId: epicCard.listId,
            orderNumber: getNextCardOrderNumberAfterCardId(state, epicCard.listId, epicCard.id),
            subCardOrderNumber: getNextSubCardOrderNumber(state, epicCard.id),
            status: TStatus.STATUS_ACTIVE,
            epicId: epicCard.id,
            assignees: [...(checklistItem.assignees || [])],
        };

        if (checklistItem.checked) {
            subCard.processingStatus = 'done'; // somebody kill this
            subCard.processingPercent = 100;
        }

        if (checklistItem.startDate) {
            subCard.startDate = checklistItem.startDate;
        }

        if (checklistItem.dueDate) {
            subCard.dueDate = checklistItem.dueDate;
        }

        if (checklistItem.estimate) {
            subCard.estimate = checklistItem.estimate;
        }

        dispatch(createCardEvent());
        return dispatch(cardAdd(subCard))
            .then((result: IRestCard) => {
                if (!checklistItem.startDate) return result;

                const state = getState();
                let cards: ICards = {};
                cards = getCardEpicsForStartDateChange(state, epicCard.id, cards);
                return dispatch(cardsRestPatch(cards))
                    .then(() => {
                        return result;
                    });
            })
            .then((result: IRestCard) => {
                if (!checklistItem.dueDate) return result;

                const state = getState();
                let cards: ICards = {};
                cards = getCardEpicsForDueDateChange(state, epicCard.id, cards);
                return dispatch(cardsRestPatch(cards))
                    .then(() => {
                        return result;
                    });
            })
            .then((result: IRestCard) => {
                const list = getList(getState(), epicCard.listId);
                dispatch(sendCardStatWithIncrement(list, 1, Number(checklistItem.checked)));
                root.App.statHelper.sendUserStat(object([
                    root.App.Classes.StatHelper.CARD_CREATE_COUNT,
                    root.App.Classes.StatHelper.CARD_CREATE_DATE
                ],[
                    '+1',
                    root.App.Util.nowTrimTimestampGMT()
                ]));
                if (result && result.id && result.assignees && result.assignees.length > 0) {
                    dispatch(sendUserProductivityStatsByCardId(result.id, EUserProductivityEventStatus.ASSIGNED_TO_CHANGED))
                }
                if (
                    result && result.id && checklistItem.dueDate
                ) {
                    dispatch(sendUserProductivityStatsByCardId(result.id, EUserProductivityEventStatus.DUE_DATE_CHANGED))
                }
                return result;
            })
    };
    return action;
};
