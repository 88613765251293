import { IApplicationState, TCardId } from '../../../../../../../../types/types';
import { TCommentId } from '../../../../../../../../store/model/card/types/IComment';
import { getCommentById } from '../../../selectors/getCommentById';

export const getCommentReplyAttachment = (
    state: IApplicationState,
    cardId: TCardId,
    commentId: TCommentId
) => {
    const comment = getCommentById(state, cardId, commentId);
    return comment && comment.meta && comment.meta.replyCommentId && comment.meta.replyAttachmentId;
}
