import { IApplicationState, TBoardId } from '../../../../types/types';
import { IBoardCardCustomProperties } from '../../../customProperties/types';
import { getBoardMeta } from './getBoardMeta';
import { unescape } from 'underscore';

export const getBoardCardCustomProperties = (
    state: IApplicationState,
    boardId: TBoardId
): IBoardCardCustomProperties => {
    const meta = getBoardMeta(state, boardId);
    return meta.cardCustomProperties || {};
};
