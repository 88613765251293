import * as React from 'react';
import { Icon } from 'kui';
import { HELP_PANEL_LINKS_AFFILIATE, } from '../../constants';
import { IHelpPanelAffiliateLinkProps } from './types';

export const HelpPanelAffiliateLinkComponent = ({
    onClick
}: IHelpPanelAffiliateLinkProps) => {

    const clickHandler = (
        e: React.MouseEvent
    ) => {
        e.preventDefault();
        onClick();
    }
    return (
        <span className={'help-panel__link'}>
            <a onClick={clickHandler}>
                <Icon xlink="monetization" size={24} /> {HELP_PANEL_LINKS_AFFILIATE}
            </a>
        </span>
    );
};
