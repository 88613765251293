import { IApplicationState } from '../../../../types/types';
import { EFeature } from '../../../../types/rest/IUserMeta';
import { getAuthUser } from './getAuthUser';

export const isNewFeatureForUser = (
    state: IApplicationState,
    feature: EFeature
): boolean => {
    const { meta } = getAuthUser(state);
    const featuresSeen = meta && meta.featuresSeen;
    return !featuresSeen || !featuresSeen.includes(feature);
};
