import { SegmentBoardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import {
    SegmentBoardOption,
    SegmentBoardOptionStatusValue,
    SegmentBoardOptionCardPrefixesTypeValue, SegmentBoardOptionCardPrefixesVariant
} from '../../../../../../../../middlewares/segment/trackEntities/boardEvents';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';

export const saveClickedSegmentEvent = (): ThunkAction => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentBoardEvent.CARD_PREFIXES_SAVE_CLICKED));
};

export const toggleClickedSegmentEvent = (
    value: boolean
): ThunkAction => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentBoardEvent.CARD_PREFIXES_TOGGLE_CLICKED, {
        name: SegmentBoardOption.STATUS,
        value: value ? SegmentBoardOptionStatusValue.ON :
            SegmentBoardOptionStatusValue.OFF
    }));
};

export const linkClickedSegmentEvent = (): ThunkAction => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentBoardEvent.CARD_PREFIXES_FAQ_CLICKED));
};

export const valueChangedSegmentEvent = (
    value: SegmentBoardOptionCardPrefixesTypeValue
): ThunkAction => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentBoardEvent.CARD_PREFIXES_VALUE_CHANGED, {
        name: SegmentBoardOption.TYPE,
        value
    }));
};

export const generateClickedSegmentEvent = (
    value: SegmentBoardOptionCardPrefixesVariant
): ThunkAction => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentBoardEvent.CARD_PREFIXES_GENERATE_CLICKED, {
        name: SegmentBoardOption.TYPE,
        value
    }));
};
