import { IApplicationState, TCardId } from '../../../../../types/types';
import { IDependency } from '../../dependency/types';
import { getCardDependencies } from './getCardDependencies';

export const getCardSuccessorsDependencies = (
    state: IApplicationState,
    cardId: TCardId
): IDependency[] => {
    const filter = (dependency: IDependency): boolean => {
        return dependency.predecessorId === cardId;
    };
    return getCardDependencies(state, filter);
};
