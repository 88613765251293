import { afterSearchCard } from './events/afterSearchCard';
import { IApplicationState } from '../../../../../../types/types';
import {
    ITopBarFilterButtonDispatchProps,
    ITopBarFilterButtonStateProps
} from '../../components/TopBarFilterButton/types';
import { connect } from 'react-redux';
import { TopBarFilterButton } from '../../components/TopBarFilterButton/TopBarFilterButton';
import { onFilterButtonClicked } from './events/onFilterButtonClicked';
import { getTypeSwitcherActiveType } from '../../../../typeSwitcher/store/selectors/getTypeSwitcherActiveType';
import { onFilterChange } from './events/onFilterChange';
import { filterPanelBoardsSelector } from '../../../../aside_panel/filterPanelBoard/store/selectors/filterPanelBoardsSelector';
import { getCurrentEditingFilterIdSelector } from '../../../../aside_panel/filterPanelBoard/store/selectors/getCurrentEditingFilterIdSelector';
import { defaultOrEditedFilterSelectorById } from '../../../../aside_panel/filterPanelBoard/store/selectors/defaultOrEditedFilterSelectorById';
import { onSearchClicked } from './events/onSearchClicked';
import { onSelect } from './events/onSelect';
import { EViewTypes } from '../../../../../../const';
import { afterSearchFilter } from './events/afterSearchFilter';
import { TStatus } from '../../../../../../types/model';
import { getSearchSelectCardOptions } from './selectors/getSearchSelectCardOptions';
import { ITopBarFilterButtonHOC } from './types';
import { getAsidePanel } from 'app/view/react_components/aside_panel/asidePanel/store/selectors/getAsidePanel';
import { isRelatedCardsPanelOpened } from 'app/view/react_components/aside_panel/relatedCards/store/selectors/isRelatedCardsPanelOpened';

const mapStateToProps = (
    state: IApplicationState,
    { boardId }: ITopBarFilterButtonHOC
): ITopBarFilterButtonStateProps => {
    const activeType = getTypeSwitcherActiveType(state, boardId);
    const filterPanel = filterPanelBoardsSelector(state, boardId);
    const isShown = activeType !== EViewTypes.REPORTS_VIEW_TYPE;
    const defaultOrEditedFilterId = getCurrentEditingFilterIdSelector(filterPanel);
    const defaultOrEditedFilter = defaultOrEditedFilterSelectorById(filterPanel, defaultOrEditedFilterId);
    const cardStatus = activeType === EViewTypes.ARCHIVE_VIEW_TYPE
        ? TStatus.STATUS_ARCHIVE
        : TStatus.STATUS_ACTIVE;
    const isRelatedCardsPanel = isRelatedCardsPanelOpened(state);
    return {
        keyword: defaultOrEditedFilter.searchedKeyWord || '',
        isActive: filterPanel.isOpened,
        isAsidePanelOpened: !!getAsidePanel(state).width,
        isRelatedCardsPanel,
        isShown,
        cards: isShown ? getSearchSelectCardOptions(state, boardId, cardStatus) : null
    };
};

const mapDispatchToProps = (
    dispatch: any,
    { boardId }: ITopBarFilterButtonHOC
): ITopBarFilterButtonDispatchProps => ({
    afterSearchCard: () => dispatch(afterSearchCard(boardId)),
    afterSearchFilter: () => dispatch(afterSearchFilter(boardId)),
    onClickButton: () => dispatch(onFilterButtonClicked(boardId)),
    onClickInput: () => dispatch(onSearchClicked(boardId)),
    onFilterChange: (key: string) => dispatch(onFilterChange(key, boardId)),
    onBlur: () => {},
    onSelect: (cardId: string) => dispatch(onSelect(cardId))
});

export const TopBarFilterButtonHOC = connect(mapStateToProps, mapDispatchToProps)(TopBarFilterButton);
TopBarFilterButtonHOC.displayName = 'TopBarFilterButtonHoc';
