import {
    FileUpload,
    OneDriveLargeFileUploadOptions,
    OneDriveLargeFileUploadTask,
    UploadResult
} from '@microsoft/microsoft-graph-client';
import { graphClient } from '../graph/Graph';
import { DriveItem } from '@microsoft/microsoft-graph-types';

/**
 *
 * @param driveId - драйв доски
 * @param folderId - id папки assets
 * @param file
 */
export async function msUploadLargeFile (
    driveId: string,
    folderId: string,
    file: File,
    onProgress: (uploaded: number) => void,
) {
    let fileName = file.name;

    const options: OneDriveLargeFileUploadOptions = {
        path: '/', // корень, потому что uploadSessionURL уже для папки assets
        fileName,
        rangeSize: 1024 * 1024,
        uploadEventHandlers: {
            progress: (range) => onProgress(range && range.minValue),
        },
        uploadSessionURL: `/drives/${driveId}/items/${folderId}:/${fileName}:/createUploadSession`, // https://github.com/OneDrive/onedrive-api-docs/issues/1236#issuecomment-603213503
    };

    const fileUpload = new FileUpload(file, fileName, file.size);
    const uploadTask = await OneDriveLargeFileUploadTask.createTaskWithFileObject(
        graphClient,
        fileUpload,
        options
    );

    const uploadResult: UploadResult = await uploadTask.upload();

    const driveItem = uploadResult.responseBody as DriveItem;

    return driveItem;
}
