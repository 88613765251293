import { IApplicationState, TBoardId } from '../../../../types/types';
import { getBoardCardAgingSettings } from './getBoardCardAgingSettings';

export const getBoardCardAgingOpacity = (
    state: IApplicationState,
    boardId: TBoardId
): boolean => {
    const cardAgingSettings = getBoardCardAgingSettings(state, boardId);

    return cardAgingSettings && cardAgingSettings.opacity;
}
