import { Queue } from './Queue';
import { IFetchArgs, IRequestItem } from '../../fetchHandler';
import { IRequestPattern } from './QueueManager';
import { ERestMethod } from '../../../rest/types';

export class PatternQueue {
    constructor(patterns: IRequestPattern[]) {
        this.patterns = patterns;
        this.queue = {};
    }

    patterns: IRequestPattern[];
    private queue: {[k: string]: Queue} = {};

    checkAndAdd = (request: IFetchArgs) => {
        let requestItem: IRequestItem;
        for (let pattern of this.patterns) {
            let group = this.getGroupMatchRequestPattern(request, pattern);
            if (group) {
                this.queue[group] = this.queue[group] || new Queue();
                let promise = new Promise<Response>((resolve, reject)=> {
                    requestItem = {...request, resolveCallback: resolve, rejectCallback: reject};
                    this.queue[group].add(requestItem);
                });
                return promise;
            }
        }
        return null;
    };

    getGroupMatchRequestPattern = (request: IRequestItem, pattern: IRequestPattern): string => {
        let url = request.input instanceof Request ? request.input.url : request.input;
        let match = url.match(pattern.urlRegExp);
        if  (pattern.methods.includes(request.init.method as ERestMethod) && match) {
            let group = match[1];
            return group || '';
        }
        return null;
    }
}
