import { IApplicationState, TBoardId } from '../../../types/types';
import { IPermissions } from '../../../types/model';
import { getBoard } from './getBoardById';
import { createDeepEqualSelector } from '../../../view/react_components/helpers/memoizeHelper';

const getBoardPermissionsSelector = (
    state: IApplicationState,
    boardId: TBoardId,
): IPermissions => {
    const board = getBoard(state, boardId);
    return board ? board.permissions : null;
};

export const getBoardPermissions: (
    state: IApplicationState,
    boardId: TBoardId,
) => IPermissions = createDeepEqualSelector(
        getBoardPermissionsSelector,
        permissions => permissions
);
