import { root } from 'app/store/constants';
import { IAuthToken } from 'app/types/rest/IRestAuthUser';
import { INIT_SCOPE } from 'app/helper/authorisation/google/Scopes';

// @ts-ignore
export const providerInstance: Promise<Provider> = window.gisLoaded.then(()=>{
    console.debug('new Google Provider()');
    return new Provider();
})

export interface IGoogleCodeResponce{
    code: string;
    authuser: string;
    error: string;
}

/***
 * класс отвечает за общение с Google
 */

export class Provider {
    authorizeWithCode (scopes: string[], hint?: string  ) {
        return new Promise<IGoogleCodeResponce>((resolve, reject) =>{
            let client_id = root.App.clientId;
            // @ts-ignore
            let codeClient = google.accounts.oauth2.initCodeClient({
                client_id,
                scope: scopes.join(' '),
                ux_mode: 'popup',
                hint,
                callback: (response: IGoogleCodeResponce) => {
                    //console.debug(response);
                    resolve(response);
                },
                error_callback: (err: {type: string}) => {
                    console.debug(err);
                    reject(err);
                    // нам сообщать что пользователь закрыл окно нет смысла, тк это тоже самое,
                    // что он не нащал на конопку ЗАЛОГИНЬСЯ. reject будем вызывать когда что-то пошло не так
                }
            });

            codeClient.requestCode();
            }
        )
    }

    resetToken(){
        // @ts-ignore
        gapi.client.setToken({});
        // @ts-ignore
        window.google.authuser = undefined;
    }

    setToken(token: IAuthToken){
        // @ts-ignore
        gapi.client.setToken(token);
        // @ts-ignore
        window.google.authuser = token.authuser;
    }

    static getInitScopes(){
        return INIT_SCOPE;
    }
}
