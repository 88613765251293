import { IGetState } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { isGoogleFileCreatingSetAction } from 'app/view/react_components/aside_panel/asidePanel/store/actions/isGoogleFileCreatingSetAction';

export const updateIsGoogleFileCreating = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(isGoogleFileCreatingSetAction(false))
    };
    return action;
};
