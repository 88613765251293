import { IApplicationState } from '../../../../../../../types/types';
import { TGoogleDriveId, TTabKey } from '../../types';
import { getSearchQuery } from '../../header/selectors/getSearchQuery';
import { getBoardsByTabKey } from './getBoardsByTabKey';
import { filterBoardsForSearch } from '../../../helpers/filterBoardsForSearch';

export const getBoardsIds = (
    state: IApplicationState,
    tabKey: TTabKey,
    driveId: TGoogleDriveId,
    enableSearch: boolean = true
): number[] => {
    const boards = getBoardsByTabKey(state, tabKey, driveId);
    const searchQuery = enableSearch ?
        getSearchQuery(state) :
        false;
    const boardsFiltered = searchQuery ?
        filterBoardsForSearch(boards, searchQuery) :
        boards;
    return boardsFiltered.map((board) => board.id);
};
