import { IGetState, TBoardId } from '../../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { boardSetCardAgingMeta } from '../../../../../../../../../rest/effects/board/boardSetCardAgingMeta';
import { ICardAgingSettings } from '../../../../../../../../../types/rest/ICardAging';

export const updateSettings = (
    boardId: TBoardId,
    cardAgingSettings: ICardAgingSettings
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        const updatedCardAging = {
            enabled: true,
            cardAgingSettings
        }
        dispatch(boardSetCardAgingMeta(boardId, updatedCardAging));
    };
    return action;
};
