import { IApplicationState, TBoardId } from '../../../../../../types/types';
import { getBoardCards } from '../../../../../../store/model/selectors/getBoardCards';
import { TStatus } from '../../../../../../types/model';
import { ICard } from '../../../../../../store/model/card/types';

export const getBoardTemplatesCards = (
    state: IApplicationState,
    boardId: TBoardId,
    isRecurring: boolean
): ICard[] => {
    const cards = getBoardCards(state, boardId, TStatus.STATUS_SERVICE_TEMPLATE);
    cards.sort((a, b) => {
        const orderNumberA = a.orderNumber === undefined ? -a.created : a.orderNumber;
        const orderNumberB = b.orderNumber === undefined ? -b.created : b.orderNumber;
        return orderNumberA - orderNumberB;
    });
    return cards.reduce((sum: ICard[], card: ICard) => {
        if (isRecurring && card.recurringTasks.length) {
            sum.push(card);
        } else if (!isRecurring && card.recurringTasks.length === 0){
            sum.push(card);
        }
        return sum;
    }, [] as ICard[]);
}
