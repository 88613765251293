import { IGetState, TCardId, TChecklistId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IRestChecklist } from '../../../../types/rest/IRestChecklist';
import { ICards } from '../../../../store/model/cards/types';
import { cardsActiveRequestsUpdate } from '../../../../store/requestsState/effects/cardsActiveRequestsUpdate';
import { restDelete } from './api/restDelete';
import { checklistDeleteStore } from '../../../../store/model/checklists/actionCreators/checklistDelete';
import { sendRealTimeStoreActionByCardId } from '../../../../view/react_components/base/helpers/realTimeHelperTS';
import { CHECKLIST_DEFAULT_ID } from '../../../../store/model/checklists/checklists/constants';

export const checklistDelete = (
    cardId: TCardId,
    checklistId: TChecklistId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const cards: ICards = {
            [cardId]: null
        };
        dispatch(cardsActiveRequestsUpdate(cards, 1));

        const promiseDelete: Promise<any> = checklistId === CHECKLIST_DEFAULT_ID
            ? Promise.resolve()
            : dispatch(restDelete(cardId, checklistId));

        return promiseDelete
            .then((response: IRestChecklist) => {
                const action = checklistDeleteStore(cardId, checklistId);
                dispatch(action);
                dispatch(sendRealTimeStoreActionByCardId(cardId, action));
                return response;
            })
            .finally(() => {
                dispatch(cardsActiveRequestsUpdate(cards, -1));
            });
    };
    return action;
};
