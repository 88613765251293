import * as React from 'react';
import { IFooterTutorialProps } from './types';
import { Button, ButtonsGroup } from 'kui';
import { TUTORIALS_BUTTON_SKIP, TUTORIALS_BUTTON_PREVIOUS, TUTORIALS_BUTTON_FINISH, TUTORIALS_BUTTON_NEXT } from './constants';

export function FooterTutorial ({
    isFirstStep,
    isLastStep,
    onTutorialNext,
    onTutorialPrevious,
    onTutorialSkip,
}: IFooterTutorialProps) {
    return (<>
        {!isLastStep
            ? <Button
                className={'kui-hint__button-tertiary'}
                color={'white'}
                onClick={onTutorialSkip}
            >
                {TUTORIALS_BUTTON_SKIP}
            </Button>
            : <div /> // placeholder to keep next button on right
        }
        <ButtonsGroup>
            {!isFirstStep &&
                <Button
                    className={'kui-hint__button-secondary'}
                    color={'white'}
                    onClick={onTutorialPrevious}
                >
                    {TUTORIALS_BUTTON_PREVIOUS}
                </Button>
            }
            <Button
                color={'white'}
                onClick={onTutorialNext}
            >
                {isLastStep ? TUTORIALS_BUTTON_FINISH : TUTORIALS_BUTTON_NEXT}
            </Button>
        </ButtonsGroup>
    </>);
};
