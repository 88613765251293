import { SelectList, SelectListItem, ButtonDropdown, Button } from 'kui';
import * as React from 'react';
import { ICommentsMentionsProps } from './types';
import { CLASS_COMMENTS_SECTION, CLASS_COMMENT_MENTION_DROPDOWN } from '../../constants';
import './_comments-mentions.scss';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { EAsidePanelProperty } from '../../../../asidePanel/components/AsidePanel/types';
import { CLASS_COMMENTS_SECTION_INPUT } from '../CommentsInput/constants';
import { RIGHT_ASIDE_PANEL_SELECTOR } from '../../../../asidePanel/constants';
import { root } from '../../../../../../../store/constants';
import { ID_ASIDE_PANEL_MENTIONS_PORTAL } from '../../../../asidePanel/components/AsidePanelActionsButton/constants';
import { GOOGLE_SPACING } from '../../../../../../../const';
import { ASSIGNEE_GROUP_ID } from '../../../AssigneesSection/components/constants';
import { UserpicStorage } from 'app/view/react_components/base/components/UserpicStorage/UserpicStorage';
import { AnalyzeRender } from '../../../../../helpers/memoizeHelper';

//@ts-ignore
const analyzeRender = window.Settings.development ? new AnalyzeRender(`CommentsMentions`) : null;

export function CommentsMentions({
    isOpened,
    selectedMention,
    users,
    onMention,
}: ICommentsMentionsProps) {
    const { openedProperty, cardId } = React.useContext(AsidePanelContext);

    if (analyzeRender) analyzeRender.call(`CommentsMentions ${cardId}`);

    const [opened, setOpened] = React.useState(isOpened);
    const isPopup = openedProperty === EAsidePanelProperty.CARD_COMMENTS;
    const portal = isPopup ? {} : {
        portal: true,
        portalId: ID_ASIDE_PANEL_MENTIONS_PORTAL,
        portalSelector: RIGHT_ASIDE_PANEL_SELECTOR,
    };
    const [buttonDropDownContainerStyle, setButtonDropDownContainerStyle] = React.useState(null)

    const userMentionsWrapperClass = CLASS_COMMENTS_SECTION + '__mentions';
    const userMentionsButtonClass = CLASS_COMMENTS_SECTION + '__button';
    const userMentionsSpanClass = CLASS_COMMENTS_SECTION + '__span';
    const groupsClass = CLASS_COMMENTS_SECTION + '-groups';
    const commentButtonDropdownContainerClass = CLASS_COMMENT_MENTION_DROPDOWN + '-container';

    const isGroups = !!users.find(user => user.permissionId === ASSIGNEE_GROUP_ID);

    React.useEffect(() => {
        requestAnimationFrame(() => {
            const active = document.querySelector(`.${CLASS_COMMENT_MENTION_DROPDOWN} .kui-select-list__item--active`);
            if (active) active.scrollIntoView({block: 'nearest'});
        });
    }, [selectedMention]);

    React.useEffect(() => {
        setOpened(isOpened);

        function onEsc (e: any) {
            if (e.which === 27) setOpened(false);
        };

        if (isOpened) { // если дропдаун открыт
            root.addEventListener('keydown', onEsc); // то подписываемся на Esc
        }

        return () => {
            root.removeEventListener('keydown', onEsc);
        }
    }, [isOpened]);

    React.useEffect(() => {
        /**
         * taken from https://codepen.io/jh3y/pen/rpoxxL
         * returns x, y coordinates for absolute positioning of a span within a given text input
         * at a given selection point
         */
        const getCursorXY = (
        ): { x: number, y: number} => {
            const inputLabelClass = `.${ CLASS_COMMENTS_SECTION_INPUT }`;
            const inputElement = document.querySelector(`${ inputLabelClass } textarea`) as HTMLInputElement;

            if (!inputElement) { // html editor
                const selection = root.getSelection();
                const range = selection.getRangeAt(0);
                const span = document.createElement('span');
                const newRange = document.createRange();
                newRange.setStart(selection.focusNode, range.endOffset);
                newRange.insertNode(span);
                let x = span.offsetLeft - 16;
                let y = span.offsetTop;
                span.remove();
                return {x, y};
            }

            let { value: inputValue, selectionEnd } = inputElement;
            selectionEnd= selectionEnd - 1;
            // create a dummy element that will be a clone of our input
            const divElement = document.createElement('div');
            // get the computed style of the input and clone it onto the dummy element
            const copyStyle = getComputedStyle(inputElement);

            for (const prop of copyStyle) {
                divElement.style[prop as any] = copyStyle[prop as any];
            }
            // we need a character that will replace whitespace when filling our dummy element if it's a single line <input/>
            const swap = '.';
            // set the text content of the dummy element div
            divElement.textContent = inputValue.substr(0, selectionEnd);
            divElement.style.height = 'auto';
            divElement.style.position = 'absolute';
            divElement.style.top = '0px';
            divElement.style.left = '0px';
            divElement.style.opacity = '0';
            divElement.style.pointerEvents = 'none';
            // if a single line input then the div needs to be single line and not break out like a text area
            // create a marker element to obtain caret position
            const span = document.createElement('span');
            span.style.display='inline-block';
            // give the span the textContent of remaining content so that the recreated dummy element is as close as possible
            span.innerHTML = inputValue.substr(selectionEnd) || swap;
            // append the span marker to the div
            divElement.appendChild(span);
            // append the dummy element to the body
            document.body.appendChild(divElement);
            // get the marker position, this is the caret position top and left relative to the input
            const { offsetLeft: spanX, offsetTop: spanY } = span;
            // lastly, remove that dummy element
            // NOTE:: can comment this out for debugging purposes if you want to see where that span is rendered
            //document.body.removeChild(labelElement);
            // return an object with the x and y of the caret. account for input positioning so that you don't need to wrap the input
            return {
                x: spanX,
                y: spanY
            }
        }

        let style = {
            top: 'unset',
            left: (-GOOGLE_SPACING * 4) + 'px'
        }

        if (opened) {
            const { x, y } = getCursorXY();
            style = {
                top: y + 'px',
                left: x + 'px'
            }
        }
        setButtonDropDownContainerStyle(style);
    }, [opened])

    return (
        <div className={commentButtonDropdownContainerClass}
            style={buttonDropDownContainerStyle}
        >
            {
                users.length > 0 &&
                <ButtonDropdown
                    className={userMentionsWrapperClass}
                    dontChangeFocus
                    dropdownClassName={CLASS_COMMENT_MENTION_DROPDOWN}
                    directionVertical={isPopup ? 'down' : 'auto'}
                    isFitWindow={true}
                    key={`mentions-dd--popup-${isPopup}`}
                    notBlurClasses={[CLASS_COMMENTS_SECTION_INPUT]}
                    opened={opened}
                    isScaleAnimation
                    {...portal}
                >
                    <Button
                        className={userMentionsButtonClass}
                        tabIndex={-1}
                        aria-hidden
                    />
                    <SelectList
                        active={selectedMention}
                        className={isGroups ? groupsClass : ''}
                    >
                        {
                            users.map((user, key: number) => {
                                if (user.permissionId === ASSIGNEE_GROUP_ID) {
                                    return (
                                        <span
                                            className={`${userMentionsSpanClass} disabled`}
                                            key={user.permissionId + '-' + key}
                                        >
                                            {user.name}
                                        </span>
                                    );
                                }
                                return (
                                    <SelectListItem
                                        key={user.permissionId + '-' + key}
                                        value={user.permissionId}
                                        onClick={() => onMention(key)}
                                    >
                                        <UserpicStorage
                                            initials={user.initials}
                                            src={user.photoUrl}
                                            userId={user.permissionId}
                                        />
                                        {user.name}
                                    </SelectListItem>
                                );
                            })
                        }
                    </SelectList>
                </ButtonDropdown>
            }
        </div>
    );
}
