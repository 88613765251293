import { ICard, TCardOrderNumberField } from '../card/types';

export const getCardOrderNumberByField = (
    card: ICard,
    field: TCardOrderNumberField,
    defValue: number = null
): number => {
    if (!card[field] && card[field] !== 0) return defValue !== null ? defValue : card.created;
    return card[field];
}
