const category = 'Filter';

export const SegmentFilterEvent = {
    SEARCH_FILTER: {category, event: 'by card name and card description filtered'},
    SORT_BY_FILTER: {category, event: 'card sorted'},
    DAYS_AFTER_FILTER: {category, event: 'by days after today filtered'},
    DAYS_BEFORE_FILTER: {category, event: 'by days before today filtered'},
    CREATE_FROM_FILTER: {category, event: 'by create from date filtered'},
    CREATE_TO_FILTER: {category, event: 'by create to date filtered '},
    START_FROM_FILTER: {category, event: 'by start from date filtered'},
    START_TO_FILTER: {category, event: 'by start to date filtered '},
    DONE_DATE_FROM_FILTER: {category, event: 'by done date from date filtered'},
    DONE_DATE_TO_FILTER: {category, event: 'by done date to date filtered'},
    DUE_DATE_FROM_FILTER: {category, event: 'by due date from date filtered'},
    DUE_DATE_TO_FILTER: {category, event: 'by due date to date filtered '},
    ASSIGNED_FILTER: {category, event: 'by assigned user filtered'},
    EMPTY_LIST_HIDE: {category, event: 'hide empty lists clicked'},
    SUBCARDS_HIDE: {category, event: 'hide subcards clicked'},
    EPICS_HIDE: {category, event: 'hide epics clicked'},
    DONE_CARD_HIDE: {category, event: 'hide done cards clicked'},
    DONE_CARD_SHOW: {category, event: 'show done cards clicked'},
    OVERDUE_CARDS_SHOW: {category, event: 'show overdue cards clicked'},
    EPIC_CARDS_SHOW: {category, event: 'show epic cards clicked'},
    SUBCARDS_SHOW: {category, event: 'show subcards clicked'},
    NO_DATES_SHOW: {category, event: 'show no dates clicked'},
    IN_PROGRESS_SHOW: {category, event: 'show in progress clicked'},
    UPCOMING_SHOW: {category, event: 'show upcoming clicked'},
    READY_TO_GO_SHOW: {category, event: 'show ready to go clicked'},
    UNDONE_CHECKLISTS_SHOW: {category, event: 'show undone checklists items clicked'},
    PRIORITY_FILTER: {category, event: 'by priority filtered '},
    COMMENTS_FILTER: {category, event: 'by comments filtered'},
    ATTACHMENTS_FILTER: {category, event: 'by attachments filtered'},
    TAGS_FILTER: {category, event: 'by tags filtered'},
    COLOR_TAGS_FILTER: {category, event: 'by color tags filtered'},
    ROLES_FILTER: {category, event: 'by roles filtered'},
    CARD_COLOR_FILTER: {category, event: 'by card color filtered'},
    LIST_COLOR_FILTER: {category, event: 'by list color filtered'},
    RESET_FILTER: {category, event: 'filter reseted'},
    FILTER_ENABLED: {category, event: 'saved filtered enabled'},
    FILTER_DISABLED: {category, event: 'saved filtered disabled'},
    FILTER_PUBLIC_TOGGLE: {category, event: 'filter public clicked'},
    EDIT_SAVED_FILTER: {category, event: 'edit saved filter button clicked'},
    SAVED_EDITING_FILTER_SAVE: {category, event: 'saved filter editing save button clicked'},
    SAVED_FILTER_EDITING_CANCEL: {category, event: 'saved filter editing cancel button clicked'},
    SAVED_FILTER_DELETED: {category, event: 'saved filter deleted'},
    NEW_FILTER_ADD_CLICKED: {category, event: 'new filter add button clicked'},
    NEW_FILTER_SAVE_CLICKED: {category, event: 'new filter save button clicked'},
    NEW_FILTER_CANCEL_CLICKED: {category, event: 'new filter cancel button clicked'},
    FILTER_ASIDE_PANEL_CLOSED: {category, event: 'filter aside panel closed'},
    FILTER_INFO_PANEL_CLOSED: {category, event: 'filter info panel closed'},
    FILTER_APPLIED_ON: {category, event: 'filter applied on clicked'},
    FILTER_APPLIED_OFF: {category, event: 'filter applied off clicked'},
    FILTER_INFO_PANEL_CLICKED: {category, event: 'filter info panel clicked'},
    FILTER_TOP_BAR_ASSIGNED_TO_ME_CLICK: {category, event: 'assigned to me in top bar clicked'},
    FILTER_TOP_BAR_OVERDUE_CLICK: {category, event: 'overdue in top bar clicked'},
    FILTER_TOP_BAR_DONE_CLICK: {category, event: 'done in top bar clicked'},
    FILTER_TOP_BAR_I_HAVE_ASSIGNED_CLICK: {category, event: 'i have assigned in top bar clicked'},
    FILTER_TOP_BAR_I_AM_SUBSCRIBED_CLICK: {category, event: 'i am subscribed in top bar clicked'},
    FILTER_TOP_BAR_I_HAVE_CREATED_CLICK: {category, event: 'i have created in top bar clicked'},
    FILTER_TOP_BAR_I_HAVE_CONTRIBUTED_CLICK: {category, event: 'i have contributed in top bar clicked'},
    SEARCH_AFTER: {category, event: 'Search after'},
    ASSIGNEES_LOADED: {category, event: 'assignees list loaded'},
    FILTER_CLOSED: {category, event: 'filter closed'},
    FILTER_HIDDEN_CARDS_TOGGLE: {category, event: 'filter hidden cards toggle'},
    FILTER_ARCHIVED_CARDS_TOGGLE: {category, event: 'filter archived cards toggle'},
    FILTER_BOARD_REMOVE: {category, event: 'filter board removed'},
    FILTER_BOARD_TOGGLE: {category, event: 'filter board toggle'},
    FILTER_CARDS_ASSIGNED_TO_ME_CLICK: {category, event: 'filter cards assigned to me clicked'},
    FILTER_CARDS_I_HAVE_ASSIGNED_CLICK: {category, event: 'filter cards i have assigned clicked'},
    FILTER_CARDS_I_HAVE_CREATED_CLICK: {category, event: 'filter cards i have created clicked'},
    FILTER_CARDS_I_AM_SUBSCRIBED_CLICK: {category, event: 'filter cards i am subscribed clicked'},
    FILTER_CARDS_I_HAVE_CONTRIBUTED_CLICK: {category, event: 'filter cards i have contributed clicked'},
    FILTER_CARDS_I_HAVE_ADDED_TO_MY_WORK_CLICK: {category, event: 'filter cards i have added to my work clicked'},
};

export enum SegmentFilterOption {
    PRIORITY = 'Priority',
    COMMENTS = 'Comments',
    ATTACHMENTS = 'Attachments',
    SORT_BY = 'Sort by',
    STATUS = 'Status',
    PROPERTY_COUNT = 'Property count',
    PAGE = 'page'
}

export enum SearchAfterTarget {
    CARD_SELECTED = 'Card selected',
    FILTER_APPLIED = 'Filter applied',
}

export enum ESegmentFilterSource {
    SECTION = 'Section',
    FOOTER = 'Footer',
}

export enum SegmentFilterOptionStatusValue {
    ON = 'on',
    OFF = 'off',
}

export enum SegmentFilterOptionPageValue {
    MY_TASKS = 'my-tasks'
}
