import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId, TChecklistId, TChecklistItemId } from '../../../../../../../../types/types';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { deleteChecklistItem } from '../../effects/deleteChecklistItem';
import { root } from '../../../../../../../../store/constants';
import {
    getChecklistItems
} from '../../../../../../../../store/model/checklists/checklist/selectors/getChecklistItems';
import {
    getAuthUserAllowChecklistItemCount
} from '../../../../../../../../store/model/authUser/selectors/getAuthUserAllowChecklistItemCount';

export const onDelete = (
    cardId: TCardId,
    checklistId: TChecklistId,
    itemId: TChecklistItemId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const maxCount = getAuthUserAllowChecklistItemCount(state);
        const count = Object.keys(getChecklistItems(getState(), cardId, checklistId) || {}).length;
        if (
            root.App.controller.allowCounterSnackbarHelper.isShowSnackbar('allowChecklistItemCount') &&
            count > maxCount
        ) {
            return Promise.resolve(false);
        } else {
            dispatch(segmentTrackAction(SegmentCardEvent.REMOVED_CHECKLIST_ELEMENT));
            return dispatch(deleteChecklistItem(cardId, checklistId, itemId)).then(() => {
                return Promise.resolve(true);
            });
        }
    };
    return action;
};
