export enum ESettingsSectionType {
    COLLAPSE = 'COLLAPSE',
    DEFAULT = 'DEFAULT',
    DISALLOW = 'DISALLOW',
    POPUP = 'POPUP',
    READONLY = 'READONLY',
}

export interface ISettingsSectionFields {
    addButton?: JSX.Element;
    addComponent?: JSX.Element;
    addIcon?: string;
    addTitle?: string; // dashed add button больше не используется
    children?: JSX.Element | JSX.Element[];
    className?: string;
    closeIcon?: string;
    focusSelector?: string;
    isAddWide?: boolean; // расширить кнопку Add на весь хэдер
    isBasicFeature?: boolean;
    isNewFeature?: boolean;
    isOpened?: boolean;
    isProFeature?: boolean;
    isRequired?: boolean;
    isRequiredError?: boolean;
    title: string;
    titleButton?: JSX.Element;
    titleSub?: string;
    isTitleTooltip?: boolean;
}

export interface ISettingsSectionEvents {
    onAddOpened?: () => void;
    onAddClosed?: () => void;
    onClickDisallow?: () => void;
    onClickTitle?: () => void;
}

export interface ISettingsSectionOwnProps { //todo O_O give name like ISettingsSectionBaseFields
    sectionType?: ESettingsSectionType;
}

export interface ISettingsSectionProps extends
    ISettingsSectionFields,
    ISettingsSectionEvents,
    ISettingsSectionOwnProps
{}
