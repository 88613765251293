import * as React from 'react';
import { Button, Input } from 'kui';
import { IQualificationDialogAnswersTextProps } from './types';

export function QualificationDialogAnswersText ({
    questionKey,
    showSaveButton,
    placeholder,
    onSave,
    onChange
}: IQualificationDialogAnswersTextProps) {

    const [text, setText] = React.useState('');

    const onSaveTextHandler = () => {
        if (onSave) {
            onSave(text);
        }
    }

    const onInputChangeHandler = (e: React.SyntheticEvent) => {
        const { value } = (e.target as HTMLInputElement);
        setText(value);
        if (onChange) {
            onChange(value);
        }
    }

    const onKeyDown = (e: React.KeyboardEvent) => {
        if (13 === e.keyCode) {
            e.preventDefault();
            e.stopPropagation();
            setText(text + '\n');
        }
        if (32 === e.keyCode) {
            e.preventDefault();
            e.stopPropagation();
            setText(text + ' ');
        }
    }

    let buttonTitle = 'Next';
    let buttonClassName = 'qualification-dialog__answers-text-next-button';
    const questionComparing = questionKey === 'work_tools_compare' || questionKey === 'edu_tools_compare';
    if (!text.length) {
        if(questionComparing){
            buttonTitle = 'I’m not comparing';
            buttonClassName = 'qualification-dialog__next-button qualification-dialog__next-button--inactive ';
        }else{
            buttonClassName = 'qualification-dialog__answers-text-next-button qualification-dialog__answers-text-next-button--inactive ';
        }
    }

    return (
        <div>
            <Input
                multiple={true}
                className={ 'edit-saved-filter__input' }
                autosize={ true }
                onKeyDown={onKeyDown}
                onChange={onInputChangeHandler}
                value={text}
                placeholder={placeholder || ''}
            />
            {showSaveButton &&
                <Button
                    className={buttonClassName}
                    onClick={onSaveTextHandler}
                    disabled={(!text || text.length === 0) && !questionComparing}
                >
                    {buttonTitle}
                </Button>
            }
        </div>
    );
}
