import * as React from 'react';
import { IRestCardCreateActivity } from '../../../../../../../types/rest/activity/IRestCardCreateActivity';
import { IActivityProps } from '../../../../boardDetails/activity/TabActivity/types';
import { TabActivityContext } from '../../../../boardDetails/activity/TabActivity/components/TabActivity/constants';
import { TActivity } from '../../../../../../../store/activities/types';
import {
    ActivityItemBody
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemBody/ActivityItemBody';
import { ActivityHeader } from '../../../../boardDetails/activity/TabActivity/components/ActivityHeader/ActivityHeader';
import {
    ActivityItemName
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemName/ActivityItemName';
import { CLASS_ACTIVITY_ITEM } from 'app/view/react_components/aside_panel/boardDetails/activity/TabActivity/components/constants';
import './_CardActivityTemplate.scss';
import { Icon } from 'kui';

export function CardActivityTemplate ({
    activity
}: IActivityProps<IRestCardCreateActivity>) {
    const { onOpenCard } = React.useContext(TabActivityContext);
    const { label, card } = activity as TActivity;
    const className = CLASS_ACTIVITY_ITEM + '__template';
    const classNameSpan = className + '-span';

    function onOpenTemplate (e: React.MouseEvent) {
        e.stopPropagation()
        onOpenCard(activity.templateId, activity.id)
    }
    return (
        <ActivityItemBody
            header={<ActivityHeader icon={'card'} label={label}/>}
            activity={activity}
            onClick={() => onOpenCard(card.id, activity.id)}
        >
            <ActivityItemName name={card.name} />
            <span className={classNameSpan}>from template</span>
            <ActivityItemName
                name={activity.oldValue}
                icon={
                    <Icon
                        size={16}
                        xlink={'board-template'}
                    />
                }
                onClick={onOpenTemplate}
            />
        </ActivityItemBody>
    );
}
