import { IApplicationState } from '../../../../../types/types';
import { getCardColorTags } from '../../../../../store/model/selectors/getCardColorTags';
import { getCardFullNameHelper } from '../../../../../store/model/card/helpers/getCardFullNameHelper';
import { ICard } from '../../../../../store/model/card/types';
import { ICardAssignee } from '../../../../../store/model/cardAssignee/types';
import { getListName } from '../../../../../store/model/selectors/getListName';
import { getBoardByCardId } from '../../../../../store/model/selectors/getBoardByCardId';
import { TStatus } from '../../../../../types/model';

export const getCardMyWorkCard = (
    state: IApplicationState,
    card: ICard
): ICardAssignee => {
    const { id, name, cardNumber, priority, processingPercent, startDate, dueDate, backgroundColor, listId, status, assignees } = card;
    const result: ICardAssignee =  {
        name, cardNumber, priority, processingPercent, startDate, dueDate, backgroundColor,
        colorTags: getCardColorTags(state, id),
        cardFullName: getCardFullNameHelper(card),
        status, assignees
    };
    if (card.status === TStatus.STATUS_ARCHIVE) {
        result.status = TStatus.STATUS_ACTIVE;
    }
    result.archived = card.status === TStatus.STATUS_ARCHIVE;
    const listName = getListName(state, listId);
    if (listName) result.listName = listName;

    const board = getBoardByCardId(state, card.id);
    if (board) {
        result.boardName = board.name;
    }
    return result;
}
