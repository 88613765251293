import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { SegmentBoardEvent, SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { openBoardSettings } from '../../../../../../base/effects/openBoardSettings';
import { SegmentBoardSettingsSourceValue } from '../../../../../../../../middlewares/segment/trackEntities/boardEvents';

export const onEditRoles = (): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(openBoardSettings('roles', SegmentBoardSettingsSourceValue.CARD_ASSIGNEES_EDIT_ROLES));
        dispatch(segmentTrackAction(SegmentCardEvent.EDIT_ROLES_LINK));
    }
    return action;
}
