import { AT_PUSH_NOTIFICATION_SETTINGS_ACTION_SET, IPushNotificationActionSetAction } from './types';
import { TPushNotificationSettingsAction } from '../../pushNotificationSettings/actions/types';

export const pushNotificationSettingsActionSet = (
    boardId: number,
    pushNotificationAction: TPushNotificationSettingsAction
): IPushNotificationActionSetAction => ({
    type: AT_PUSH_NOTIFICATION_SETTINGS_ACTION_SET,
    boardId,
    pushNotificationAction
});
