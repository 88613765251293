import { IApplicationState, IGetState, TBoardId, TCardId } from '../../types/types';
import { Dispatch } from '../../types/actions';
import { getBoard } from '../../store/model/selectors/getBoardById';
import { ICardReportRow, ICommentDurationItem, IExportListener, ISaveCardResult, IStartResult } from './types';
import { getBoardCardsActiveAndArchive } from '../../store/model/selectors/getBoardCardsActiveAndArchive';
import { fetchHandler } from '../../util/fetchHandler';
import { IRestCard } from '../../types/rest/IRestCard';
import { root } from '../../store/constants';
import { getRestHeadersPost } from '../../rest/helpers/getRestHeadersHelper';
import Util from '../../util/util';
import { getCard } from '../../store/model/selectors/getCard';
import { getCardColorTags } from '../../store/model/selectors/getCardColorTags';
import { continueExport, finishExport, startExport } from './helpers';
import { getBoardWorkHoursInDay } from '../../store/model/selectors/getBoardWorkHoursInDay';
import { convertTimeNumberToString } from '../../view/react_components/aside_panel/cardDetails/helpers/convertTimeNumberToString';
import { TimeTrackerHelper } from '../../view/react_components/dialogs/timeTrackerDialog/helpers/TimeTrackerHelper';
import { ICommentAuthor } from '../../store/model/card/types/IComment';

const CARDS_STEP = 200;
const HEADERS = ['Card title','Epic','Colour tags','Assigned users','Spent time'];

export const exportTimeTrackerToSpreadsheet = (
    boardId: TBoardId,
    listener: IExportListener
) => {
    return (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const board = getBoard(state, boardId);
        const cardIds: TCardId[] = [];
        const cards = getBoardCardsActiveAndArchive(state, boardId);
        cards.forEach((card) => {
            if (card.totalSpentTime && card.totalSpentTime > 0) {
                cardIds.push(card.id);
            }
        })
        const reportRows: ICardReportRow[] = [];
        const dateHeaders: string[] = [];
        loadCards(cardIds, listener, cardIds.length)
            .then((cards) => {
                cards.forEach(card => {
                    const cardRows = getCardReportRows(state, card, boardId);
                    cardRows.forEach((cardRow) => {
                        reportRows.push(cardRow);
                        if (!dateHeaders.includes(cardRow.date)) {
                            dateHeaders.push(cardRow.date);
                        }
                    })
                });
                dateHeaders.sort();
                return startExport(
                    state,
                    board,
                    listener,
                    '/rest/spreadsheetExport/start/',
                    'Time tracker data',
                    {
                        columnCount: dateHeaders.length + HEADERS.length
                    }
                )
            })
            .then((startResult) => {
                if (!startResult) return;
                exportReport(state, dispatch, reportRows, boardId, startResult, listener, dateHeaders, reportRows.length)
            })
    };
}

const exportReport = (
    state: IApplicationState,
    dispatch: Dispatch,
    reportRows: ICardReportRow[],
    boardId: TBoardId,
    startResult: IStartResult,
    listener: IExportListener,
    dateHeaders: string[],
    rowsCount: number,
): void => {
    const workHoursInDay = getBoardWorkHoursInDay(state, boardId);
    const reducedRows = processRows(reportRows, dateHeaders, workHoursInDay);
    const doExport = (
        rowsProcessed: number = 0,
    ) => {
        const rowsToExport = reducedRows.splice(0, CARDS_STEP);
        rowsProcessed += rowsToExport.length;
        listener.onProgress(rowsCount ? Math.round(rowsProcessed / rowsCount * 100.0) : 0);
        if (rowsToExport.length) {
            addRows(rowsToExport, boardId, startResult, listener).then(() => {
                doExport(rowsProcessed);
            })
        } else {
            finishExport(startResult, '/rest/spreadsheetExport/finish/' + boardId, listener, boardId, dispatch);
        }
    }
    addRows([HEADERS.concat(dateHeaders)], boardId, startResult, listener)
        .then(() => doExport());
}

const processRows = (
    reportRows: ICardReportRow[],
    dateHeaders: string[],
    workHoursInDay: number
): string[][] => {
    const processedRows: string[][] = [];
    const rowsMap: {[key: string]: ICardReportRow[]} = {};

    reportRows.forEach(reportRow => {
        const key = reportRow.author.hash + reportRow.cardId;
        if(rowsMap[key]) {
            rowsMap[key].push(reportRow)
        } else {
            rowsMap[key] = [reportRow]
        }
    });

    for(let key in rowsMap) {
        processedRows.push(reduceArrays(rowsMap[key].map(row => mapRow(row, dateHeaders, workHoursInDay))));
    }
    return processedRows;
};

const reduceArrays = (arrays: string[][]) => {
    const result: string[] = [];
    arrays[0].forEach((item, index) => {
        if(item === '') {
            let rowCounter = 0;
            while (item === '' && rowCounter < arrays.length) {
                item = arrays[rowCounter][index];
                rowCounter++;
            }
        }
        result.push(item);
    });
    return result;
};

const mapRow = (
    { epic, author, cardName, color, spentTime, ...row }: ICardReportRow,
    dateHeaders: string[],
    workHoursInDay: number
): string[] => {
    const minutes: string[] = dateHeaders.map((date) => {
        if (row.date === date) {
            return convertTimeNumberToString(row.minutes, false, workHoursInDay);
        }
        return '';
    });
    return [
        cardName,
        epic,
        color,
        author.fullName,
        spentTime
    ].concat(minutes)
}

const addRows = (
    rows: Array<string[]>,
    boardId: TBoardId,
    startResult: IStartResult,
    listener: IExportListener
): Promise<ISaveCardResult> => {
    return continueExport(
        startResult,
        listener,
        '/rest/spreadsheetExport/continue/' + boardId,
        JSON.stringify(rows)
    )
}

const getCardReportRows = (
    state: IApplicationState,
    card: IRestCard,
    boardId: TBoardId,
): ICardReportRow[] => {
    const workHoursInDay = getBoardWorkHoursInDay(state, boardId);
    const allItems = getAllCommentDurationItems(card, workHoursInDay);
    const unique: ICommentDurationItem[] = [];
    allItems.forEach((item) => {
        const existItem = unique.find((uniqueItem) =>
            uniqueItem.date === item.date && uniqueItem.author.hash === item.author.hash);
        if (existItem) {
            existItem.minutes += item.minutes;
        } else {
            unique.push(item);
        }
    });
    const epic = getCard(state, card.epicId);
    const colors = getCardColorTags(state, card.id);
    return unique.map<ICardReportRow>(item => ({
        cardId: card.id,
        ...item,
        epic: epic ? epic.name : null,
        cardName: card.name,
        color: colors.map(color => color.name).toString(),
        spentTime: calcUserSpentTime(item.author, unique, workHoursInDay)
    }))
}

const calcUserSpentTime = (author: ICommentAuthor, items: ICommentDurationItem[], workHoursInDay: number): string => {
    const authorItems = items.filter(item => item.author.hash === author.hash);
    let spentMinutes = 0;
    authorItems.forEach((item) => {
        spentMinutes += item.minutes;
    });
    return convertTimeNumberToString(spentMinutes, false, workHoursInDay)
};

const getAllCommentDurationItems = (
    card: IRestCard,
    workHoursInDay: number
): ICommentDurationItem[] => {
    const result: ICommentDurationItem[] = [];
    card.comments.forEach((comment) => {
        const minutes: number = TimeTrackerHelper.formatCommentToSecondsByText(comment.text, workHoursInDay) / 60;
        if (minutes) {
            result.push({
                author: comment.author,
                date: Util.formatDateMoment(comment.created, 'YYYY-MM-DD'),
                minutes
            })
        }
    })
    return result;
}

const loadCards = (
    cardIds: TCardId[],
    listener: IExportListener,
    totalCardsCount: number,
    allCards: IRestCard[] = []
): Promise<IRestCard[]> => {
    listener.onProgress(cardIds.length ? Math.round((totalCardsCount - cardIds.length) / totalCardsCount * 100.0) : 0);
    return fetchHandler<IRestCard[]>(
        root.App.Util.getApiUrl('/rest/dashboards/time_tracker/cardbyids'),
        {
            ...getRestHeadersPost(),
            body: JSON.stringify(cardIds.splice(0, CARDS_STEP))
        },
        true
    ).then((cards) => {
        allCards.push(...cards);
        if (cardIds.length > 0) return loadCards(cardIds, listener, totalCardsCount, allCards);
        return allCards;
    })
}
