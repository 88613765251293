import { Dispatch, ThunkAction } from 'app/types/actions';
import { cardNameRenameAction } from '../../../store/cardNameReducer/actions/cardNameRenameAction';

export const onRenameEnd = (): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(cardNameRenameAction(null));
    };
    return action;
};
