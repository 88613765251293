import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState, TBoardId, TCardId } from '../../../../types/types';
import { ICardBatch } from './api/types';
import { cardUpdateMetaBatch } from './cardUpdateMeta';
import { getCard } from '../../../../store/model/selectors/getCard';
import { cardPatchRestBatch } from './api/helper/cardPatchRestBatch';

export const cardAddMetaMoveToBoardBatch = (
    getState: IGetState,
    cardId: TCardId,
    boardId: TBoardId,
    cardBatch: ICardBatch = {card: {}, callbacks: []}
) => {
    const card = getCard(getState(), cardId);
    const cardBatchMetaBoardIds = cardBatch.card.meta && cardBatch.card.meta.moveToBoards;
    const cardMetaBoardIds = card.meta && card.meta.moveToBoards || [];
    return cardUpdateMetaBatch({
        moveToBoards: [
            { // ставим наверх массива
                boardId,
                time: Math.floor(Date.now() / 1000)
            },
            ...(cardBatchMetaBoardIds || cardMetaBoardIds), // cardBatchMetaBoardIds должны уже включать в себя все из cardMetaBoardIds, поэтому cardMetaBoardIds не нужно добавлять
        ]
    }, cardBatch);
};

export const cardAddMetaMoveToBoard = (
    cardId: TCardId,
    boardId: TBoardId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const cardBatch = cardAddMetaMoveToBoardBatch(getState, cardId, boardId);
        return cardPatchRestBatch(cardId, dispatch, cardBatch);
    };
    return action;
};
