import { ICard } from '../card/types';
import { getCardOrderNumberByField } from './getCardOrderNumberByField';

// TODO - пересмотреть сортировку гантта, оставить одно поле ?
export const getRealGanttOrderNumber = (
    card: ICard,
    defValue: number = null
): number => {
    return getCardOrderNumberByField(card, card.epicId ? 'subCardOrderNumber' : 'ganttOrderNumber', defValue);
}
