function DataLoader(exportUrl, listener) {

    this.exportUrl = exportUrl;
    this.listener = listener;

    this.startLoad = function() {
        this.loadNextPart('');
    };

    this.loadNextPart = function(cursor) {
        var url = this.exportUrl + '?rand='+Math.random();
        if (cursor != '') url += '&cursor=' + cursor;

        $.ajax({
            beforeSend: App.vent.beforeSend,
            dataType: "json",
            url: url,
            success: $.proxy(function(result){
                if (result.cursor) {
                    this.listener.onLoadPart(result);
                    this.loadNextPart(result.cursor);
                } else {
                    this.listener.onLoadPart(result);
                    this.listener.onComplete();
                }
            }, this)
        });

    };
}

function inworkCardsBoardsFix() {
    var loader = new DataLoader('/utils/userInworkCard/fixDashboards', {
        onLoadPart: function(data) {
            console.log('norm.wait');
        },
        onComplete: function() {
            console.log('norm.gotovo');
        }
    });
    loader.startLoad();
}
App.inworkCardsBoardsFix = inworkCardsBoardsFix;