import { SegmentCardEvent, SegmentCardOptions, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { SegmentCardSourceValue } from '../../../../../../../../middlewares/segment/trackEntities/cardEvents';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TBoardId, TCardId } from '../../../../../../../../types/types';
import { ICustomPropertiesElement } from '../../../components/CustomPropertiesElements/types';
import { saveProperties } from '../effects/saveProperties';

export const onSave = (
    boardId: TBoardId,
    cardId: TCardId,
    elements: ICustomPropertiesElement[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.CUSTOM_PROPERTIES_EDIT_SAVE_CLICKED, {
            name: SegmentCardOptions.SOURCE,
            value: !!cardId ? SegmentCardSourceValue.DETAILS : SegmentCardSourceValue.BOARD_SETTINGS
        }));
        return dispatch(saveProperties(boardId, elements));
    };
    return action;
};
