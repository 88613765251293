import { IGetState, TCardId, TListId } from '../../../../../types/types';
import { IList } from '../../../../../store/model/list/types';
import { IBoard } from '../../../../../store/model/board/types';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { getCardDone } from '../../../../../store/model/selectors/getCardDone';
import {
    sendCardStatWithIncrement,
    sendCardStatWithIncrementForBoard
} from '../../../../../helper/comet/stat_helper_ts';

export const sendCardStat = (
    cardId: TCardId,
    destListId: TListId,
    sourceList: IList,
    board: IBoard,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        let oldListCardDoneCountDelta = 0;
        let newListCardDoneCountDelta = 0;
        if (getCardDone(getState(), cardId)) {
            oldListCardDoneCountDelta--;
            newListCardDoneCountDelta++;
        }
        return Promise.all([
            // Послать данные для source list
            dispatch(sendCardStatWithIncrement(sourceList, -1, oldListCardDoneCountDelta)),
            // Послать данные для dest list
            dispatch(sendCardStatWithIncrementForBoard(board, destListId, 1, newListCardDoneCountDelta))
        ]);
    };
    return action;
};
