import { getMessages, root } from './store/constants';

//todo remove old constants
export const CLASS_HTML_SCROLL = 'html--scroll';
export const CLASS_HTML_SCROLL_DISABLED = 'html--scroll_disabled';
export const CLASS_CONTENT_WRAPPER = 'content__wrapper';
export const SHOW_MODAL_CLASS = 'modal--show';
export const SHOW_SECOND_PANEL_CLASS = 'second-panel--show';
export const DASHBOARD_LIMIT = 10;
export const CARD_COLOR_TAGS_LIMIT = 10;
export const STATUS_ACTIVE = 0;
export const STATUS_DELETED = 1;
export const STATUS_ARCHIVE = 2;
export const CLASS_MAIN_PANEL = 'main-panel';
export const CLASS_MAIN_PANEL_SCROLL = 'main-panel__scroll';
export const CLASS_MAIN_PANEL_SCROLL_INNER = 'main-panel__scroll-inner';
export const CLASS_ASIDE_PANEL = 'aside-panel';
export const CLASS_LEFT_ASIDE_PANEL = 'left-aside-panel';
export const CLASS_PAGE_SHOW_LISTS_BG = 'page--lists-bg';
export const CLASS_BOARD_VIEW = 'board-view';
export const CLASS_OFFLINE = 'offline';
export const CLASS_PANELS_BLUR = 'panels-blur';

export const STATUS_LIST_ARCHIVE_LIST_ID = -1;

export const SNACKBAR_ID_CARD_AGEING = 'SNACKBAR_ID_CARD_AGING_INFO';

export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const ASIDE_MIN_WIDTH = 420; // px
export const ASIDE_MAX_WIDTH = 640; // px
export const ASIDE_DEFAULT_WIDTH = ASIDE_MIN_WIDTH;

export const TRELLO_BACK_URL = '/rest/dashboard/trello/import';
export const LANDING_N1_URL = '/dashboard/google-workspace-project-management';
export const LANDING_N2_URL = '/dashboard/google-workspace-project-management-v2';
export const LANDING_TASKMANAGER_URL = '/dashboard/taskmanager';
export const LANDING_KANBAN_URL = '/dashboard/kanban';
export const LANDING_GANTTCHART_URL = '/dashboard/ganttchart';
export const LANDING_BRAZIL_URL = '/dashboard/brazil';
export const LANDING_TM_URL = '/dashboard/tm';

export const ORDER_STEP = Math.pow(2, 16);
export const PORTION_SIZE = 10;

export const USER_SUPPRESS_TYPE = 'SUPPRESS';
export const USER_UNSUPPRESS_TYPE = 'UNSUPPRESS';
export const USER_SUPPRESS_AND_DELETE_TYPE = 'SUPPRESS_AND_DELETE';

export const GDPR_URL = '/rest/gdpr/analitycs?type=';
export const GDPR_SUPRESS_URL = `${GDPR_URL}${USER_SUPPRESS_TYPE}`;
export const GDPR_UNSUPPRESS_URL = `${GDPR_URL}${USER_UNSUPPRESS_TYPE}`;
export const GDPR_SUPPRESS_AND_DELETE_URL = `${GDPR_URL}${USER_SUPPRESS_AND_DELETE_TYPE}`;

export const EVENT_KEY_ENTER = 'Enter';
export const EVENT_KEY_DELETE = 'Delete';
export const EVENT_KEY_BACKSPACE = 'Backspace';
export const EVENT_KEY_SLASH = '/';
export const CONFIG_URL = '/rest/config';

export const IN_WORK_CARD_ANIMATION_DURATION = 3000;

export const COPY_BOARD_COPY_PREFIX = 'copyof';
export const COPY_BOARD_TEMPLATE_PREFIX = 'template';

export const REDIRECT_WINDOW_HISTORY_LENGTH = 5;

export const DEFAULT_POLL = { lastTimeShowed: 0, isSkipped: false };

export const CARD_BACKGROUND_COLORS_OLD: ReadonlyArray<string> = Object.freeze([
    '#ad1457',
    '#FFAB80',
    '#304ffe',
    '#90caf9',
    '#4caf50',
    '#98DE6F',
    '#5E1172',
    '#AD82EA',
    '#ff9800',
    '#FAD165',

    '#F08577',
    '#FEB3B3',
    '#2196f3',
    '#C6DEFB',
    '#51E898',
    '#00e676',
    '#aa00ff',
    '#9A9CFF',
    '#F5BE7A',
    '#fdd835',

    '#F691B2',
    '#FF9280',
    '#00bcd4',
    '#9FE1E7',
    '#76ff03',
    '#69D1A2',
    '#90B3EB',
    '#B99AFF',
    '#FBE983',
    '#d7ccc8',

    '#D09E8D',
    '#00e5ff',
    '#A4EEED',
    '#C8F59A',
    '#91E6C0',
    '#E190F8',
    '#D1BFC2',
    '#eeff41',

    '#64ffda',
    '#546e7a',
    '#9e9d24',
    '#d4e157',
    '#C0C6CF',
    '#BFC1B0',
]);

const CARD_BACKGROUND_COLORS: ReadonlyArray<string> = Object.freeze([
    '#FFE4E6',
    '#FFEDD5',
    '#FEF3C7',
    '#E1F1DE',
    '#CBEAF1',
    '#DBEAFE',
    '#EDE9FE',
    '#FCE7F3',
    '#ECE5E1',
    '#E6E9ED',
]);

export const CARD_BACKGROUND_COLORS_PICKER_MATCH: Readonly<Record<string, string>> = Object.freeze({
    '#FFE4E6': '#FFB3B8',
    '#FFEDD5': '#FFD8A3',
    '#FEF3C7': '#FDE896',
    '#E1F1DE': '#C1E2BB',
    '#CBEAF1': '#A2DAE6',
    '#DBEAFE': '#ABCEFD',
    '#EDE9FE': '#C3B6FC',
    '#FCE7F3': '#F6BBDD',
    '#ECE5E1': '#D7C9C1',
    '#E6E9ED': '#B9BFCB',
});

export const getCardBackgroundColors = (
    withNoColor: boolean = true,
    withOldColors: boolean = false,
): ReadonlyArray<string> => {
    let boardColors = [...CARD_BACKGROUND_COLORS];
    if (withNoColor) {
        boardColors = ['' , ...CARD_BACKGROUND_COLORS];
    }
    if (withOldColors) {
        boardColors = [...boardColors, ...CARD_BACKGROUND_COLORS_OLD];
    }
    return Object.freeze(boardColors);
};

export const getListBackgroundColors = (
    withNoColor: boolean
): ReadonlyArray<string> => {
    let boardColors = [...CARD_BACKGROUND_COLORS];
    if (withNoColor) {
        boardColors = ['' , ...CARD_BACKGROUND_COLORS];
    }
    return Object.freeze(boardColors);
};

export const BOARD_LOGO_COLORS: ReadonlyArray<string> = Object.freeze([
    '#dfe2e7',
    '#cacfd8',
    '#dfd4cd',
    '#ffdcad',
    '#fde791',
    '#c8e5c2',
    '#bfd9fd',
    '#dbd3fd',
    '#f9d2e8',
    '#ffa9ae',
]);

export const BOARD_LOGO_TEXT_COLORS: Record<string, string> = Object.freeze({
    '#dfe2e7': '#8893a5',
    '#cacfd8': '#77859d',
    '#dfd4cd': '#a38470',
    '#ffdcad': '#e08000',
    '#fde791': '#be9803',
    '#c8e5c2': '#56a446',
    '#bfd9fd': '#438ff9',
    '#dbd3fd': '#8b72f9',
    '#f9d2e8': '#e260a9',
    '#ffa9ae': '#d76060',
});

export const getBoardLogoTextColor = (
    color: string,
): string => {
    return BOARD_LOGO_TEXT_COLORS[color] || '#8893a5';
};

export const TEXTAREA_MAX_HEIGHT = 240; //px
export const CARD_CREATION_HIGHLIGHT_DELAY = 1500;

export const CARD_CHECKLIST_WEIGHTS = 10;
export const CARD_SUBCARD_WEIGHTS = 10;
export const GOOGLE_SPACING = 8;

export enum EViewTypes {
    KANBAN_VIEW_TYPE = 'KanbanDashboard',
    GANTT_VIEW_TYPE = 'GanttDashboard',
    LIST_VIEW_TYPE = 'ListViewDashboard',
    ARCHIVE_VIEW_TYPE = 'ArchiveDashboard',
    REPORTS_VIEW_TYPE = 'ReportsWorkspace',
    USERS_VIEW_TYPE = 'UsersDashboard',
    COLOR_TAGS_VIEW_TYPE = 'ColorTagsDashboard',
    CARD_COLORS_VIEW_TYPE = 'CardColorsDashboard',
    TAGS_VIEW_TYPE = 'TagsDashboard',
    PRIORITIES_VIEW_TYPE = 'PrioritiesDashboard',

    SWIM_COLOR_TAGS_VIEW_TYPE = 'SWIM_COLOR_TAGS_VIEW_TYPE',
    SWIM_TAGS_VIEW_TYPE = 'SWIM_TAGS_VIEW_TYPE',
}

export enum EViewTypesGroup {
    LIST_GROUP = 'LIST_GROUP',
    SWIMLANE = 'SWIMLANE',
}

export const VIEW_TYPES_OTHER: ReadonlyArray<EViewTypes> = [
    EViewTypes.USERS_VIEW_TYPE,
    EViewTypes.COLOR_TAGS_VIEW_TYPE,
    EViewTypes.TAGS_VIEW_TYPE,
    EViewTypes.PRIORITIES_VIEW_TYPE,
    EViewTypes.CARD_COLORS_VIEW_TYPE,
]

export const VIEW_TYPES_KANBAN_OTHER: ReadonlyArray<EViewTypes> = [
    EViewTypes.KANBAN_VIEW_TYPE,
    ...VIEW_TYPES_OTHER,
]

export const VIEW_TYPES_SWIM: ReadonlyArray<EViewTypes> = [
    EViewTypes.SWIM_COLOR_TAGS_VIEW_TYPE,
    EViewTypes.SWIM_TAGS_VIEW_TYPE,
]

export const TOPBAR_COLLABORATORS_MAX = 3;
export const TOOLTIP_LONG = 600;
export const SELECTOR_MAIN_CONTENT = '.content';
export const CLASS_SNACKBARS = 'snackbars';
export const LIST_LABEL = getMessages().getText('common.list_label');
export const REMOVE_LABEL = getMessages().getText('common.remove');
export const DONE_TITLE = getMessages().getText('common.done');
export const CLASS_CARD_DETAILS_ACTIONS = 'card-details-actions';
export const READY_TO_GO_SECS = 3 * 24 * 60 * 60; // 3 days
export const MAX_USER_ROLES = 3;
export const BIG_BOARD_CARDS = 2000; // убрать счётчики в фильтрах на большой доске, может ещё что отключить
export const ASSIGNED_TO_ME_BACK_BOARD_LOCALSTORAGE = 'ASSIGNED_TO_ME_BACK_BOARD_LOCALSTORAGE';

export enum EDateType {
    START_DATE = 'startDate',
    DUE_DATE = 'dueDate',
}

export const GSUITE_APP_URL = 'https://gsuite.google.com/marketplace/app/kanbanchi/631025100586';
export const MS_APP_URL = 'https://appsource.microsoft.com/';

export enum EDatesFilterConditionTypes {
    CREATED_DATE_TYPE = getMessages().getText('fp.date_filter.title_created'),
    DUE_DATE_TYPE = getMessages().getText('fp.date_filter.title_due'),
    START_DATE_TYPE = getMessages().getText('fp.date_filter.title_start'),
    DONE_DATE_TYPE = getMessages().getText('fp.date_filter.title_done')
}

export const THUMB_SIZE = 290; // px // card attachments
export const THUMB_COMMENT_SIZE = 600; // px
export const THUMB_CARD_SIZE = 272; // px // same in _variables.scss
export const THUMB_CARD_HEIGHT = 160; // px // same .k-card__drive-docs-imgs-image
export const THUMB_COMMENT_REPLY_SIZE = 24; // px

export const FAKE_CARD_ID_FOR_WIDGET_PRESENTATION = -1;
export const FAKE_CARD_ID = -1;

export const FIRST_BOARD_NAME = getMessages().getText('first_board_name');

const QUICK_START_BOARD_ID = 5719466544594944; // почему-то нет в env, но есть на бэке
export const NOT_EDITABLE_BOARD_IDS = [
    root.Settings.firstBoardId,
    root.Settings.firstBoardPwcId,
    root.Settings.firstBoardIdMicrosoft,
    root.Settings.tipsAndTricksBoardId,
    root.Settings.tipsAndTricksBoardIdMicrosoft,
    root.Settings.abilitiesBoardId,
    QUICK_START_BOARD_ID,
];
