import { INotificationsSettingsActionSetAction, NOTIFICATIONS_SETTINGS_ACTION_SET } from './types';
import { TNotificationsSettingsAction } from '../notificationsSettings/actions/types';

export const notificationsSettingsActionSet = (
    notificationsAction: TNotificationsSettingsAction
): INotificationsSettingsActionSetAction => {
    return {
        type: NOTIFICATIONS_SETTINGS_ACTION_SET,
        notificationsAction
    }
};
