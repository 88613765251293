import { getBoardByCardId } from './getBoardByCardId';
import { IColor } from './../../../types/model';
import { getCardColorIds } from './getCardColorIds';
import { IColorTag, TCardId, IApplicationState, TColorId, EMPTY_ARRAY } from './../../../types/types';
import { createSelector } from 'reselect';
import { root } from '../../constants';

type TgetCardColorTags = (
    state: IApplicationState,
    cardId: TCardId,
) => IColorTag[];

const getBoardColorsByCardIdSelector = (
    state: IApplicationState,
    cardId: TCardId,
): IColor[] => {
    const board = getBoardByCardId(state, cardId);
    if (!board || !board.colors) return EMPTY_ARRAY;

    return board.colors;
};

const getCardColorTagsSelector = (
    boardColors: IColor[],
    colorIds: TColorId[],
): IColorTag[] => {
    if (root._debug.selector) {
        console.log('getCardColorTagsSelector');
    }
    if (!boardColors ||
        !boardColors.length ||
        !colorIds ||
        !colorIds.length
    ) return EMPTY_ARRAY;

    const colorTags = colorIds.reduce((arr, colorId) => {
        const boardColor = boardColors.find(color => color.id === colorId);
        if (boardColor) {
            arr.push(boardColor);
        } return arr;
    }, []);
    return colorTags.length ? colorTags : EMPTY_ARRAY;
};

export const getCardColorTagsCreateSelector = (
): TgetCardColorTags => createSelector(
    getBoardColorsByCardIdSelector,
    getCardColorIds,
    getCardColorTagsSelector
);

// export const getCardColorTags: TgetCardColorTags = getCardColorTagsCreateSelector();
export const getCardColorTags = (
    state: IApplicationState,
    cardId: TCardId,
): IColorTag[] => {
    return getCardColorTagsSelector(getBoardColorsByCardIdSelector(state, cardId), getCardColorIds(state, cardId));
};
