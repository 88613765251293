import { IApplicationState } from '../../../../../../../types/types';
import { getPanelButtonShow } from '../../../selectors/getPanelButtonShow';
import { connect } from 'react-redux';
import { ISetInWorkButtonHOCProps } from './types';
import { onClick } from './events/onClick';
import { SetInWorkButton } from '../../components/SetInWorkButton/SetInWorkButton';
import { ISetInWorkButtonEvents, ISetInWorkButtonFields } from '../../components/SetInWorkButton/types';
import { isCardInWorkUserExist } from '../../../../../../../store/model/card/selectors/isCardInWorkUserExist';
import { getAuthUser } from '../../../../../../../store/model/authUser/selectors/getAuthUser';
import {getAsidePanelEntityPanelWidth} from '../../../../asidePanel/store/selectors/getAsidePanelEntityPanelWidth';
import {CLASS_NAME_BUTTON_HIDE_TITLE, MIN_WIDTH_DISPLAY_SET_IN_WORK} from '../../../ActionsInHeadSection/constants';
import { getCardIsLoading } from '../../../../../../../store/requestsState/selectors/getCardIsLoading';
import { getIsUserBasicPlanOnly } from '../../../../../../../store/model/authUser/selectors/getIsUserBasicPlanOnly';

const mapStateToProps = (
    state: IApplicationState,
    ownProps: ISetInWorkButtonHOCProps
): ISetInWorkButtonFields => {
    const { boardId, cardId } = ownProps;
    const user = getAuthUser(state);
    return {
        isBasicFeature: getIsUserBasicPlanOnly(state),
        isLoading: getCardIsLoading(state, cardId),
        isShow: getPanelButtonShow(state, boardId, cardId),
        inWork: isCardInWorkUserExist(state, cardId, user.permissionId),
        panelWidth: getAsidePanelEntityPanelWidth(state),
    }
};
const mapDispatchToProps = (
    dispatch: any,
    {cardId}: ISetInWorkButtonHOCProps
): ISetInWorkButtonEvents => {
    return {
        onClick: () => dispatch(onClick(cardId))
    }
};
export const SetInWorkButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps,
    (mapStateToProps, mapDispatchToProps) => ({...mapStateToProps, ...mapDispatchToProps})
)(SetInWorkButton);

SetInWorkButtonHOC.displayName = 'SetInWorkButtonHOC';
