'use strict';
import DashboardUpdateManager from './dashboard_update_manager';
import { RoomFactory } from "./ts/RoomFactory";
import * as _ from 'underscore';

App.Classes.DashboardRealtime = Backbone.View.extend({

    initialize: function(options) {
        this.board = options.board;
        this.token = this.board.cometToken;

        App.vent.on(App.vent['gapi:authorized'], _.debounce(this.connectRealtime,2000), this);
        //this.listenTo(this.model, App.vent['dashboard:localChange'], this.sendChangesToRealtime);

        this.dashboardUpdateManager = null;
        this.dashboardSocketIO = null;
        this.connectRealtime();
    },

    render: function() {
        return this;
    },

    connectRealtime: function() {
        this.dashboardUpdateManager = new DashboardUpdateManager({ board: this.board });
        this.dashboardSocketIO = RoomFactory.getInstance().getDashboardRoom(this.board.id, this.dashboardUpdateManager, this.token)
    },

    sendChangesToRealtime: function(model, patchJSON) {
        if (this.dashboardSocketIO) {
            // notify via nodejs socket io
            this.dashboardSocketIO.emit(this.dashboardUpdateManager.getJSON(model, patchJSON));
        }
    },

    remove: function() {
        if (this.dashboardSocketIO){
        	this.dashboardSocketIO.removeListener(this.dashboardUpdateManager);
        }
        App.vent.off(null, null, this);
        return Backbone.View.prototype.remove.call(this);
    }

}, {
    changesListLength: 1000,
    GANTT_REALTIME_TYPE: 'gantt'
});
