import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../types/types';
import { commentUpdate } from '../../../store/reducers/actions/commentUpdate';

export const onReplyClose = (): ThunkAction => {
    return (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(commentUpdate({
            replyCommentId: null,
            replyAttachmentId: null,
            replyText: null,
        }));
    };
};
