import { IGetState, TCardId } from '../../../types/types';
import { ICard } from '../../../store/model/card/types';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { sendCardStatWithIncrement, sendUserProductivityStatsTS } from '../../../helper/comet/stat_helper_ts';
import { getList } from '../../../store/model/list/selectors/getList';
import { getBoardByCardId } from '../../../store/model/selectors/getBoardByCardId';
import { EUserProductivityEventStatus } from '../../../view/react_components/reports/UserProductivity/UserProductivityReport/rest/types';
import { getCard } from 'app/store/model/selectors/getCard';

export function onChangeProcessingStatus (cardId: TCardId, updatedCard: ICard, oldCard: ICard): ThunkAction {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const oldProcessingStatus = oldCard.processingStatus;
        const newProcessingStatus = updatedCard.processingStatus;
        const changed = oldProcessingStatus !== newProcessingStatus;
        if (changed) {
            let cardDoneDelta = 0;
            let sendStat = false;
            if (!oldProcessingStatus && newProcessingStatus === 'done') {
                cardDoneDelta = 1;
                sendStat = true;
            }
            if (!newProcessingStatus && oldProcessingStatus === 'done') {
                cardDoneDelta = -1;
                sendStat = true;
            }
            if (sendStat) {
                const card = getCard(state, cardId);
                dispatch(sendCardStatWithIncrement(getList(state, card.listId), 0, cardDoneDelta));
            }
        }
        if(changed) {
            const card = getCard(state, cardId);
            const cardMerged = {...card, ...updatedCard };
            sendUserProductivityStatsTS(getBoardByCardId(getState(), card.id),
                cardMerged,
                getList(getState(), cardMerged.listId),
                EUserProductivityEventStatus.PROCESSING_STATUS_CHANGED);
        }
    };
    return action;
}
