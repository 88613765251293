import * as React from 'react';
import { IAsidePanelBodyProps } from './types';
import './_AsidePanelBody.scss';
import {
    ASIDE_PANEL_HEADER_HEIGHT,
    CLASS_ASIDE_PANEL_BODY,
    CLASS_ASIDE_PANEL_CONTAINER_ACTIONS,
    CLASS_ASIDE_PANEL_CONTAINER_ACTIONS_BODY
} from './constants';
import { AsidePanelActionsHOC } from '../../hocs/AsidePanelActionsHOC/AsidePanelActionsHOC';
import {
    CLASS_ASIDE_PANEL_ACTIONS,
    CLASS_ASIDE_PANEL_ACTIONS_INNER,
    CLASS_ASIDE_PANEL_ACTIONS_SCROLL
} from '../AsidePanelActions/constants';
import { root } from 'app/store/constants';
import { AsidePanelContext } from '../AsidePanel/constants';
import { ID_ASIDE_PANEL_ACTIONS_PORTAL, ID_ASIDE_PANEL_BODY_PORTAL } from '../AsidePanelActionsButton/constants';

export function AsidePanelBody ({
    children,
    isArchiveView,
    isActionsMinimized,
    panelActions,
    tabKey,
}: IAsidePanelBodyProps) {
    const className = CLASS_ASIDE_PANEL_BODY;
    const classNameTab = tabKey ? className + '--' + tabKey.toLowerCase() : '';

    if (!panelActions) {
        return (
            <div className={`${className} ${classNameTab}`}>
                {children}
                <div id={ID_ASIDE_PANEL_BODY_PORTAL}/>
            </div>
        );
    }

    const { isButtonsFixed, isMobile, isScrollDisabled, cardId } = React.useContext(AsidePanelContext);

    const classNameWithActions = !isArchiveView ? CLASS_ASIDE_PANEL_BODY + '--with-actions' : '';
    const classNameActionsMinimized = !isArchiveView && isActionsMinimized && !isButtonsFixed ? CLASS_ASIDE_PANEL_BODY + '--actions-minimized' : '';
    const classNameScrollDisabled = isScrollDisabled ? CLASS_ASIDE_PANEL_BODY + '--scroll-disabled' : '';
    const classNameContainerActions = isArchiveView && cardId
        ? CLASS_ASIDE_PANEL_CONTAINER_ACTIONS + ' ' + CLASS_ASIDE_PANEL_CONTAINER_ACTIONS + '-archive'
        : CLASS_ASIDE_PANEL_CONTAINER_ACTIONS

    const [scrollTop, setScrollTop] = React.useState(0);
    const [minHeight, setMinHeight] = React.useState('100%');
    const [containerWidth, setContainerWidth] = React.useState(null);
    const bodyRef = React.useRef(null);
    const bodyInnerRef = React.useRef(null);
    const scrollBody = React.useRef(null);
    const scrollActions = React.useRef(null);

    const containerStyle: React.CSSProperties = { minHeight };
    if (containerWidth) containerStyle.width = containerWidth;

    function onScroll (event: React.UIEvent<HTMLElement>) {
        if (isScrollDisabled) return;

        const actionsScroll = bodyRef.current.querySelector('.' + CLASS_ASIDE_PANEL_ACTIONS_SCROLL);
        if (!actionsScroll) return;

        if (event.target === actionsScroll) { // on scroll actions
            if (scrollBody.current) return;
            if (scrollActions.current) clearTimeout(scrollActions.current);
            scrollActions.current = setTimeout(() => {
                scrollActions.current = null;
            }, 200);
            bodyRef.current.scrollTop = actionsScroll.scrollTop;

        } else { // on scroll body
            if (scrollActions.current) return;
            if (scrollBody.current) clearTimeout(scrollBody.current);
            scrollBody.current = setTimeout(() => {
                scrollBody.current = null;
            }, 200);
            setScrollTop(bodyRef.current.scrollTop);
        }

        setMobileActionsZIndex();
    }

    function scrollToTop () {
        if (scrollBody.current) clearTimeout(scrollBody.current);
        scrollBody.current = setTimeout(() => {
            scrollBody.current = null;
        }, 200);
        bodyRef.current.scrollTop = 0;
        setScrollTop(0);
    }

    function setBodyMinHeight () {
        if (!bodyRef.current) return;

        const actions = bodyRef.current.querySelector('.' + CLASS_ASIDE_PANEL_ACTIONS) as HTMLElement;
        const actionsScroll = bodyRef.current.querySelector('.' + CLASS_ASIDE_PANEL_ACTIONS_SCROLL);
        const actionsInner = bodyRef.current.querySelector('.' + CLASS_ASIDE_PANEL_ACTIONS_INNER);
        if (
            !actions ||
            !actionsScroll ||
            !actionsInner ||
            !bodyInnerRef.current
        ) return;

        setMinHeight('100%');
        setTimeout(() => {
            if (!bodyRef.current) return;

            const isActionsVisible = root.getComputedStyle(actions).opacity !== '0';
            if (!isActionsVisible) return;

            const actionsPanelHeight = (actionsInner.offsetHeight + bodyRef.current.offsetHeight - actionsScroll.offsetHeight); // actionsScroll не на всю высоту из-за кнопки Resize, добавляем эту разницу
            if (
                actionsPanelHeight > bodyInnerRef.current.offsetHeight || // если высота основной панели меньше высоты панели кнопок
                actionsScroll.offsetHeight < actionsInner.offsetHeight // или в панели кнопок есть скролл
            ) {
                setMinHeight(actionsPanelHeight + ASIDE_PANEL_HEADER_HEIGHT + 'px'); // то у основной панели снизу добавится пустота, чтобы эти 2 панели скролились вместе
            } else if (bodyInnerRef.current.offsetHeight > bodyRef.current.offsetHeight) {
                setMinHeight(`calc(100% + ${ASIDE_PANEL_HEADER_HEIGHT}px)`); // for hiding header on scroll
            }
        }, 100);
    }

    function setBodyWidth () {
        const w = root as any;
        if (w.scrollWidth && w.scrollWidth < 15) { // chrome.scrollWidth=15; в firefox не работает ::-webkit-scrollbar, scrollWidth=8
            const actions = bodyRef.current.querySelector('.' + CLASS_ASIDE_PANEL_ACTIONS) as HTMLElement;
            if (!actions) return;

            setContainerWidth('calc(100% - ' + (actions.offsetWidth + 16 - w.scrollWidth) + 'px)');
        }
    }

    /**
     * на мобилке кнопке действий поднимает zIndex, пока она прилеплена к низу
     */
    function setMobileActionsZIndex () {
        if (!isMobile) return;

        const mobileActions = bodyRef.current.querySelector('.' + CLASS_ASIDE_PANEL_ACTIONS + '-mobile-button') as HTMLElement;
        if (!mobileActions) return;

        const mobileActionsRect = mobileActions.getBoundingClientRect();
        // @ts-ignore
        const isMobileActionsSticky = mobileActionsRect.bottom >= window._innerHeight; // прилеплено к низу
        mobileActions.style.zIndex = isMobileActionsSticky ? '100' : '0';
    }

    React.useEffect(() => {
        scrollToTop();
    }, [tabKey]);

    React.useEffect(() => {
        const observer = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                const body = bodyInnerRef.current as HTMLElement;
                if (body) {
                    setBodyMinHeight();
                }
            });
        });
        const body = bodyInnerRef.current;
        if (body) {
            observer.observe(body, { childList: true, subtree: true });
        }
        return () => observer.disconnect();
    }, [children]);

    React.useEffect(() => {
        requestAnimationFrame(setBodyWidth);
    }, [isActionsMinimized]);

    React.useEffect(() => {
        root.removeEventListener('resize', setBodyMinHeight);
        root.addEventListener('resize', setBodyMinHeight);

        return () => {
            root.removeEventListener('resize', setBodyMinHeight);
            if (scrollBody.current) clearTimeout(scrollBody.current);
            if (scrollActions.current) clearTimeout(scrollActions.current);
        }
    }, []);

    return (
        <div
            className={`
                ${className}
                ${classNameTab}
                ${classNameWithActions}
                ${classNameActionsMinimized}
                ${classNameScrollDisabled}
            `}
            ref={bodyRef}
            onScroll={onScroll}
            tabIndex={-1}
        >
            <div className={classNameContainerActions}>
                <div
                    className={CLASS_ASIDE_PANEL_CONTAINER_ACTIONS_BODY}
                    ref={bodyInnerRef}
                    style={containerStyle}
                >
                    {children}

                    <div id={ID_ASIDE_PANEL_ACTIONS_PORTAL} />
                </div>
                <AsidePanelActionsHOC
                    children={panelActions}
                    scrollTop={scrollTop}
                />
            </div>
        </div>
    );
}
