import { SegmentBoardEvent, segmentTrackAction } from '../../../../../../../../../middlewares/segment';
import { pushNotificationSetEnabled } from '../../../../../../../../../rest/effects/pushNotificationSettings/pushNotificationSetEnabled';
import { root } from '../../../../../../../../../store/constants';
import { getPushNotificationSettingsByBoardId } from '../../../../../../../../../store/model/selectors/getPushNotificationSettingsByBoardId';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { IGetState, TBoardId } from '../../../../../../../../../types/types';

export const toggleEnabledPushNotifications = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const pushNotification = getPushNotificationSettingsByBoardId(getState(), boardId);
        const setEnabled = !pushNotification || !pushNotification.enabled;
        dispatch(pushNotificationSetEnabled(boardId, setEnabled));
        dispatch(segmentTrackAction(setEnabled ? SegmentBoardEvent.IN_APP_NOTIFICATIONS_ON : SegmentBoardEvent.IN_APP_NOTIFICATIONS_OFF));
        if (setEnabled) {
            root.App.statHelper.sendUserStat({
                [App.Classes.StatHelper.DASHBOARD_PUSH_NOTIFICATION_ACTIVATION_DATE]:
                'ifnull' + root.App.Util.nowTrimTimestampGMT()
            });
        }
    }
    return action;
}
