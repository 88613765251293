import { root } from '../../../../store/constants';
import { userIdentifyTraitsSelector } from '../../selectors/userIdentifyTraitsSelector';

export const identify = (inputTraits: any) => {
    const traits = userIdentifyTraitsSelector();
    root.analytics && root.analytics.identify(traits.id, {
        ...traits,
        ...inputTraits
    });
};
