import * as React from 'react';
import './_BoardColor.scss';
import { IBoardColorProps } from './types';
import { Button, ButtonDropdown, Checkbox, Icon, Input } from 'kui';
import { CLASS_BOARD_DETAILS } from '../../../../constants';
import { TColorTagColor, TStatus } from '../../../../../../../../types/model';
import { getParentsClasses } from '../../../../../../helpers/getParentsClasses';
import { ColorPalette } from '../../../../../../base/components/ColorPalette/ColorPalette/ColorPalette';
import { DEFAULT_COLORS } from '../../../../../../base/components/ColorTagsDropdown/hocs/ColorTagsDropdownHOC/constants';
import { EPaletteVariant, TPaletteColor } from '../../../../../../base/components/ColorPalette/types';
import { OLD_AND_NEW_COLORS } from 'app/store/model/board/selectors/getBoardColors';
import { isTextColorLight } from 'app/view/react_components/helpers/getTextColor';
import { CLASS_COLOR_PALETTE } from '../../../../../../base/components/ColorPalette/constants';
import { ID_ASIDE_PANEL_ACTIONS_PORTAL } from '../../../../../asidePanel/components/AsidePanelActionsButton/constants';

export function BoardColor ({
    colorTag,
    customColors,
    editingId,
    isReadonly,
    onAddCustomColor,
    onDelete,
    onEdit,
    onToggle,
    setEditingId
}: IBoardColorProps) {
    const { name, color, status, cardsCount, id } = colorTag;
    const isCustom = !OLD_AND_NEW_COLORS.find((item: any) => color === item || (item.color && color === item.color));

    const className = CLASS_BOARD_DETAILS + '-color';
    const classIcon = className + '__icon';
    const classDropdownButton = className + '__dropdown-button';
    const classDropdown = classDropdownButton + '-dropdown';
    const classContent = className + '__content';
    const classArrow = classIcon + '-arrow';

    const [isOpened, setOpened] = React.useState(null);
    const [newName, setNewName] = React.useState(name);
    const [newColor, setNewColor] = React.useState<TPaletteColor>(color);
    const [isEditing, setEditing] = React.useState(null);

    const saveRef = React.useRef(true);
    const inputRef = React.useRef(null);

    const onClose = () => {
        setOpened(false);
        onSave();
    };

    const onClick = (e: React.MouseEvent) => {
        if (isReadonly) return;
        e.preventDefault();
        const parentClasses = getParentsClasses(e.target as HTMLElement, [
            classIcon,
            classDropdown,
            classContent,
            CLASS_COLOR_PALETTE + '-color-row__picker-dropdown'
        ]);
        if (
            !parentClasses.includes(classIcon) &&
            !parentClasses.includes(classDropdown) &&
            !parentClasses.includes(classContent) &&
            !parentClasses.includes(CLASS_COLOR_PALETTE + '-color-row__picker-dropdown')
        ) {
            e.stopPropagation();
            onToggle();
        }
    };

    const onNameClick = () => {
        setEditing(true);
        setEditingId();
    };

    const onSave = () => {
        if (
            newColor !== color ||
            newName !== name
        ) {
            onEdit(newColor as TColorTagColor, newName);
        }
    };

    const onBlur = () => {
        setEditing(false);
        if (saveRef.current) {
            onSave();
        }
        saveRef.current = true;
    };

    const onChange = (
        e: React.SyntheticEvent
    ) => {
        const { value } = e.target as HTMLInputElement;
        setNewName(value);
    };

    const onFocusCheckbox = (
        parentElement: HTMLElement
    ) => {
        const checkbox = parentElement ? parentElement.closest('.kui-checkbox') as HTMLInputElement : null;
        if (checkbox) setTimeout(() => checkbox.focus(), 100); // закрыть инпут
    };

    const onKeyDown = (
        e: React.KeyboardEvent
    ) => {
        const { parentElement } = e.target as HTMLInputElement;
        if (e.key === 'Enter') {
            e.preventDefault();
            saveRef.current = false;
            onSave();
            onFocusCheckbox(parentElement);
        } else if (e.key === 'Escape') {
            setNewName(name);
            onFocusCheckbox(parentElement);
        } else if (e.key === ' ') {
            e.stopPropagation();
        }
    };

    React.useEffect(() => {
        setNewName(name); // if error on save - reset
    }, [name]);

    React.useEffect(() => {
        if (editingId !== id) {
            setEditing(false);
        }
    }, [editingId]);

    return (
        <div
            className={`
                ${className}
                ${isEditing || isOpened ? className + '--editing' : ''}
            `}
            onClick={onClick}
        >
            <Checkbox
                checked={status === TStatus.STATUS_ACTIVE}
                disabled={isReadonly}
                onChange={null}
            >
                <ButtonDropdown
                    className={classDropdownButton}
                    dropdownClassName={classDropdown}
                    isScaleAnimation
                    onOpen={() => setOpened(true)}
                    onClose={onClose}
                    portal
                    portalId={ID_ASIDE_PANEL_ACTIONS_PORTAL}
                    notBlurClasses={[CLASS_COLOR_PALETTE + '-color-row__picker-dropdown']}
                >
                    <Button
                        variant={'icon'}
                            className={`
                            ${classIcon}
                            ${classIcon + '--' + id}
                            ${classIcon + '--' + newColor}
                            ${isOpened ? classIcon + '--opened' : ''}
                            ${isCustom ? classIcon + (isTextColorLight(newColor) ? '--dark' : '--light') : ''}
                        `}
                    >
                        <Icon size={24} xlink={'color-tag'} style={{ color: newColor }} />
                        <Icon
                            className={`
                                ${classArrow}
                                ${isEditing || isOpened ? classArrow + '--visible' : ''}
                            `}
                            size={16}
                            xlink={'arrow-s-drop-open'}
                        />
                    </Button>
                    <ColorPalette
                        isAutoFocus
                        selectedColor={newColor}
                        onSelect={(color, isNew) => {
                            if (isNew) {
                                onAddCustomColor(color).then(() => {
                                    setNewColor(color);
                                });
                            } else {
                                setNewColor(color);
                            }
                        }}
                        colors={[...DEFAULT_COLORS, ...customColors]}
                        variant={EPaletteVariant.TAG_COLOR}
                        isColorPicker
                    />
                </ButtonDropdown>
                <div
                    className={classContent}
                    onClick={onNameClick}
                >
                    {isEditing || isOpened ?
                        <>
                            <Input
                                autosize={false}
                                className={className + '__input'}
                                readOnly={isReadonly}
                                value={newName}
                                onBlur={onBlur}
                                onChange={onChange}
                                onKeyDown={onKeyDown}
                                autoFocus={!isOpened}
                                ref={inputRef}
                            />
                            <Button
                                variant={'icon'}
                                className={className + '__delete'}
                                onClick={onDelete}
                            >
                                <Icon size={24} xlink={'delete'} />
                            </Button>
                        </>
                        :
                        <>
                            <span className={className + '__name'}>{name}</span>
                            {cardsCount ? <span className={className + '__counter'}>{cardsCount}</span> : null}
                        </>
                    }
                </div>
            </Checkbox>
        </div>
    );
};
