import { connect } from 'react-redux';
import { TotalTiming } from '../../components/TotalTiming/TotalTiming';
import { ITotalTimingFields } from '../../components/TotalTiming/types';
import { IApplicationState } from '../../../../../../../types/types';
import { ITotalTimingHOCProps } from './types';
import { getCardTotalSpentTimeStr } from '../../../../../../../store/model/selectors/getCardTotalSpentTimeStr';
import { getCardEstimateStr } from '../../../../../../../store/model/selectors/getCardEstimateStr';

const mapStateToProps = (
    state: IApplicationState,
    props: ITotalTimingHOCProps
): ITotalTimingFields => {
    const { boardId, cardId } = props;

    return {
        estimate: getCardEstimateStr(state, boardId, cardId),
        totalSpentTime: getCardTotalSpentTimeStr(state, boardId, cardId)
    }
};

export const TotalTimingHOC = connect(mapStateToProps, null)(TotalTiming);
