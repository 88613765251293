import { Dispatch } from '../../../../../types/actions';
import { SnackbarVariant, ISnackbarControlModel } from '../../store/types';
import { addUpdateSnackbarWithBubble } from '../addUpdateSnackbarWithBubble/addUpdateSnackbarWithBubble';
import { SNACKBAR_BUTTON_CLOSE, SNACKBAR_BUTTON_CONTACT } from '../constants';
import { getDefaultActionEvent } from '../helpers/getDefaultActionEvent';
import { ISnackbarErrorPromt } from './types';

export const snackbarErrorContactUs = (
    options: ISnackbarErrorPromt
) => {
    return (dispatch: Dispatch) => {
        const controls: ISnackbarControlModel[] = [
            {
                action: () => dispatch(getDefaultActionEvent(options.id, options.actionApply)),
                isPrimary: true,
                title: SNACKBAR_BUTTON_CONTACT
            },
            {
                action: () => dispatch(getDefaultActionEvent(options.id, options.actionCancel)),
                title: SNACKBAR_BUTTON_CLOSE
            }
        ];
        dispatch(addUpdateSnackbarWithBubble({
            id: options.id,
            controls,
            text: options.text,
            title: options.title,
            variant: SnackbarVariant.ERROR
        }));
    }
};
