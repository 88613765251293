import { IGetState, TBoardId } from '../../../types/types';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { boardsActionSet } from '../../../store/model/actions/boardsActionSet';
import { boardActionSet } from '../../../store/model/boards/actions/boardActionSet';
import { updateAction } from '../../../store/model/board/actions/updateAction';
import { fetchUsersRest } from './api/fetchUsersRest';
import { ISharedUser } from '../../../types/rest/ISharedUser';
import { initWebPushNotifications, isBrowserNotificationsPopupShown } from 'app/helper/webPushNotify';
import { getBoardUsers } from 'app/store/model/selectors/getBoardUsers';
import { root } from 'app/store/constants';
import { EBrowserNotificationsTypes } from '../../../view/react_components/dialogs/notificationsRequest/hocs/BrowserNotificationsRequestHOC/types';
import { getHint } from '../../../view/react_components/hints/store/hints/selectors/getHint';
import { EHintKey, EHintStatus } from '../../../view/react_components/hints';
import { getShowGanttContextHints } from '../../../view/react_components/hints/conditions/ganttContextConditions/getShowGanttContextHints';

export const boardFetchUsers = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(fetchUsersRest(boardId))
            .then((users: ISharedUser[]) => {
                dispatch(storeUsers(boardId, users));
            })
    };
    return action;
}

export const storeUsers = (
    boardId: TBoardId,
    users: ISharedUser[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (users) {
                    if (
                        !isBrowserNotificationsPopupShown() &&
                        (
                            !getShowGanttContextHints(getState()) ||
                            getHint(getState(), EHintKey.GANTT_CONTEXT_GANTT_VIEW_RESIZE).status === EHintStatus.SHOWN
                        )
                    ) {
                const boardUsers = getBoardUsers(getState(), boardId);
                if (users.length > boardUsers.length) { // добавлен юзер на доску
                    initWebPushNotifications(() => {
                        return new Promise((resolve, reject) => {
                            root.App.controller.mainView.showNotificationsRequestDialog(() => resolve(true), EBrowserNotificationsTypes.BOARD_SHARED);
                        })
                    });
                }
            }

            dispatch(boardsActionSet(boardActionSet(boardId, updateAction({ users }))))
        }
        return users;
    };
    return action;
}
