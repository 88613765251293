import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState } from '../../../../types/types';
import { IUserMetaQualificationForm } from '../../../../types/rest/IUserMeta';
import { authUserSetQualificationFormMeta } from '../authUserSetQualificationFormMeta';
import { getUserQualificationFormMeta } from '../../../../store/model/authUser/selectors/getUserQualificationFormMeta';
import { getAuthUser } from '../../../../store/model/authUser/selectors/getAuthUser';

export const updateQualificationFormMeta = (
    options: Partial<IUserMetaQualificationForm>
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const authUser = getAuthUser(state);
        const current = getUserQualificationFormMeta(state);
        return dispatch(authUserSetQualificationFormMeta(authUser.id, {
            ...current,
            ...options
        }));
    };
    return action;
};
