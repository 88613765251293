import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../../../types/types';
import { closeTimeTrackerDialog } from '../../../effects/closeTimeTrackerDialog';
import { resetTimeTracker } from '../../../effects/resetTimeTracker';
import { createTimeTrackerComment } from '../../../effects/createTimeTrackerComment';
import { getTimeTrackerState } from '../../../../../aside_panel/cardDetails/TabTimingSection/TimeTracker/store/selectors/getTimeTrackerState';
import { segmentTrackAction } from '../../../../../../../middlewares/segment';
import { SegmentTimeTrackerEvent } from '../../../../../../../middlewares/segment/trackEntities/timeTrackerEvents';
import { startTrackingCard } from '../../../../../timeTracker/hocs/TimeTrackerHOC/effects/startTrackingCard';

export const onSave = (
    comment: string,
    callBackCardId?: TCardId
): ThunkAction => {
    return (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        dispatch(segmentTrackAction(SegmentTimeTrackerEvent.SAVE));
        const {start, stopTime, cardId, boardId} = getTimeTrackerState(state);
        const delta = stopTime - start;
        const seconds = Math.floor(delta / 1000);

        dispatch(closeTimeTrackerDialog());
        dispatch(createTimeTrackerComment(comment, seconds, cardId, boardId));
        dispatch(resetTimeTracker());

        if (callBackCardId) {
            dispatch(startTrackingCard(callBackCardId));
        }
    };
};
