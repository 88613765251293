import { root } from 'app/store/constants';
import { Dispatch, ThunkAction } from 'app/types/actions';
import { ASSIGNED_TO_ME_BACK_BOARD_LOCALSTORAGE } from 'app/const';
import { segmentTrackAction } from 'app/middlewares/segment';
import { SegmentAssignedToMeEvent } from 'app/middlewares/segment/trackEntities/assignedToMeEvents';

export const onBackClick = (): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(segmentTrackAction(SegmentAssignedToMeEvent.ASSIGNED_TO_ME_CLOSED));
        const boardId = localStorage.getItem(ASSIGNED_TO_ME_BACK_BOARD_LOCALSTORAGE);
        root.App.router.setCurrentDashboard(boardId, false);
        root.App.router.navigate(root.App.router.getUrl(null), {trigger: true});
    }
    return action;
};
