import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { linkClickedSegmentEvent } from '../../effects/segmentEvents';

export const onClickLink = (): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(linkClickedSegmentEvent());
    };
    return action;
};
