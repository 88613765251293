import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { IGetState } from '../../../../../types/types';
import { IAuthUser } from '../../../../../store/model/authUser/types';
import { patchRest } from '../patchRest';
import { IRestAuthUser } from '../../../../../types/rest/IRestAuthUser';
import { updateUser } from '../../../../../store/model/authUser/effects/updateUser';
import { sendRealTimeAuthUserUpdate } from '../../../../../view/react_components/base/helpers/realTimeHelperTS';
import { getAuthUser } from '../../../../../store/model/authUser/selectors/getAuthUser';

export const authUserPatch = (
    authUser: IAuthUser,
    isOptimistic: boolean = true, // true = store -> rest -> rollback on error
    isRealtime: boolean = false, // не нужно все апдейты слать в риалтайм, только необходимые
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (!authUser) Promise.reject();

        const state = getState();
        let authUserForRollback: IAuthUser;
        if (isOptimistic) {
            authUserForRollback = getAuthUser(state);
            dispatch(updateUser(authUser));
        }

        return dispatch(patchRest(authUser))
            .then((response: IRestAuthUser) => {
                if (authUser.gsuiteAdmin && response.gsuiteAdmin !== undefined) {
                    dispatch(updateUser({
                        gsuiteAdmin: response.gsuiteAdmin
                    }));
                }

                if (isRealtime) {
                    dispatch(sendRealTimeAuthUserUpdate(authUser));
                }
            })
            .catch((e: any) => {
                console.error(e);
                if (isOptimistic) {
                    dispatch(updateUser(authUserForRollback));
                }
            })
    };
    return action;
};
