import { IApplicationState, TCardId } from '../../../../../types/types';
import { getCard } from '../../../../../store/model/selectors/getCard';

export const isCardInMyUpLevelViaEpic = (
    state: IApplicationState,
    cardId: TCardId,
    upLevelCardId: TCardId
): boolean => {
    const card = getCard(state, cardId);
    if (!card) {
        console.log('isCardInMyUpLevelViaEpic card not found',cardId);
        return false;
    }
    if (!card.epicId) {
        return false;
    }
    if (card.epicId === upLevelCardId) {
        return true;
    }
    return isCardInMyUpLevelViaEpic(state, card.epicId, upLevelCardId);
};
