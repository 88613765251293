import { TQualificationQuestionEvent } from '../components/QualificationDialog/types';
import {
    ESegmentQuestionEventOptions, getQuestionCompetitorEvent,
    getQuestionImportantFactorEvent, SegmentQuestionEvent
} from '../../../../../middlewares/segment/trackEntities/questionEvents';
import { QUALIFICATION_QUESTIONS } from '../constants/questions';
import { IUserMetaQualificationAnswer } from '../../../../../types/rest/IUserMeta';
import { TSegmentTrackPayload } from '../../../../../middlewares/segment/trackEntities/types';

export const questionKeyFactorEventCallback = (questionKey: string, answer: IUserMetaQualificationAnswer): TQualificationQuestionEvent[] => {
    const events: TQualificationQuestionEvent[] = [];
    if (answer.checkboxKeys) {
        const question = QUALIFICATION_QUESTIONS[questionKey];
        const maxLevel = 3;
        let level = 1;
        answer.checkboxKeys.forEach(answerKey => {
            if (level > maxLevel) {
                return;
            }
            const value = (
                    question.answers[answerKey].eventValueCallback &&
                    question.answers[answerKey].eventValueCallback(answer)
                ) ||
                question.answers[answerKey].eventValue ||
                question.answers[answerKey].answer;
            events.push({
                event: getQuestionImportantFactorEvent(level),
                option: {
                    name: ESegmentQuestionEventOptions.answer,
                    value
                }
            })
            level++;
        })
    }
    return events;
}

export const questionHandHoldingEventCallback = (questionKey: string, answer: IUserMetaQualificationAnswer, answerKeyPrefix: string): TQualificationQuestionEvent[] => {
    const events: TQualificationQuestionEvent[] = [];
    const answerKeyEventMap: {[key: string]: TSegmentTrackPayload} = {
        [answerKeyPrefix + 'hand_holding_sales_email']: SegmentQuestionEvent.HELP_FORM_SALES_EMAILS,
        [answerKeyPrefix + 'hand_holding_customer_tutorials']: SegmentQuestionEvent.HELP_FORM_HELP_TO_USE,
        [answerKeyPrefix + 'hand_holding_customer_show']: SegmentQuestionEvent.HELP_FORM_VALUE_SHOW,
        [answerKeyPrefix + 'hand_holding_customer']: SegmentQuestionEvent.HELP_FORM_CHECK_HOW_I_DO,
        [answerKeyPrefix + 'hand_holding_book']: SegmentQuestionEvent.HELP_FORM_DEMO_CALL,
        [answerKeyPrefix + 'hand_holding_phone_call']:  SegmentQuestionEvent.HELP_FORM_PHONE_CALL,
    }
    const answerKeys = answer.checkboxKeys || [];
    Object.keys(answerKeyEventMap).forEach(answerKey => {
        events.push({
            event: answerKeyEventMap[answerKey],
            option: {
                name: ESegmentQuestionEventOptions.answer,
                value: answerKeys.includes(answerKey) ? 'true' : 'false'
            }
        })
    });
    const noSpamKey = answerKeyPrefix + 'hand_holding_none';
    const helpNeed = answerKeys.length > 0 && !answerKeys.includes(noSpamKey);
    events.push({
        event: SegmentQuestionEvent.HELP_NEED,
        option: {
            name: ESegmentQuestionEventOptions.answer,
            value: helpNeed ? 'true' : 'false'
        }
    })
    if (answer.parameters && answer.parameters['phone']) {
        events.push({
            event: SegmentQuestionEvent.HELP_FORM_PHONE_NUMBER,
            option: {
                name: ESegmentQuestionEventOptions.answer,
                value: answer.parameters['phone']
            }
        })
    }
    return events;
}

export const toolsCompareEventCallback = (questionKey: string, answer: IUserMetaQualificationAnswer): TQualificationQuestionEvent[] => {
    const events: TQualificationQuestionEvent[] = [];
    const competitors = (answer.text || '').split(',');
    let eventNumber = 0;
    competitors.forEach(competitor => {
        const value = competitor.trim();
        if (value) {
            events.push({
                event: getQuestionCompetitorEvent(++eventNumber),
                option: {
                    name: ESegmentQuestionEventOptions.answer,
                    value
                }
            })
        }
    });
    return events;
}
