'use strict';

App.Views.BaseView = Backbone.View.extend({

    removeViews: function(views) {
        for (var i = 0, len = views.length; i < len; i++) {
            views[i].remove();
        }
        views.length = 0;
    },

    testAttributes: function() {
        return {

        };
    },

    remove: function() {
        App.vent.off(null, null, this);
        return Backbone.View.prototype.remove.call(this);
    }
}, {
    mixin: App.Util.viewMixin
});
export default App.Views.BaseView;
