import { TCardAction } from '../actions/types';
import {
    AT_DRIVE_DOC_IDS_SET,
    AT_IN_WORK_USERS_UPDATE,
    AT_NOTIFICATION_BEFORE_DUEDATE_ADD,
    AT_NOTIFICATION_BEFORE_DUEDATE_DELETE,
    AT_NOTIFICATION_BEFORE_DUEDATE_UPDATE,
    AT_UPDATE
} from '../actions/constants';
import { getCardInitial } from '../constants';
import { ICard } from '../types';
import { IRestCardMeta } from '../../../../types/rest/IRestCardMeta';

const initialState: ICard = getCardInitial();

export const cardReducer = (
    state = initialState,
    action: TCardAction
): ICard => {
    switch (action.type) {
        case AT_NOTIFICATION_BEFORE_DUEDATE_ADD: {
            return {
                ...state,
                notificationBeforeDueDates: [
                    ...state.notificationBeforeDueDates,
                    action.notificationBeforeDueDate
                ]
            }
        }
        case AT_NOTIFICATION_BEFORE_DUEDATE_UPDATE: {
            const newState = state.notificationBeforeDueDates.map(
                nbd => {
                    if (action.notificationBeforeDueDate.id === nbd.id){
                        return {
                            ...nbd,
                            ...action.notificationBeforeDueDate
                        }
                    }
                    return nbd;
                }
            )
            return {
                ...state,
                notificationBeforeDueDates: newState
            }
        }

        case AT_NOTIFICATION_BEFORE_DUEDATE_DELETE: {
            const newState = state.notificationBeforeDueDates.filter(
                nbd => nbd.id !== action.notificationBeforeDueDateId
            )
            return {
                ...state,
                notificationBeforeDueDates: newState
            }
        }

        case AT_IN_WORK_USERS_UPDATE: {
            const inWorkUsers = state.inWorkUsers ? [...state.inWorkUsers] : [];
            const index = inWorkUsers.indexOf(action.permissionId);
            if (action.cardInWork && index === -1) {
                inWorkUsers.push(action.permissionId);
            } else if (!action.cardInWork && index > -1) {
                inWorkUsers.splice(index, 1);
            }
            return {
                ...state,
                inWorkUsers
            };
        }
        case AT_UPDATE: {
                return {
                    ...state,
                    ...action.card,
                    meta: {
                        ...state.meta,
                        ...reformatPinedChecklist(action.card.meta),
                    },
                };
        }
        case AT_DRIVE_DOC_IDS_SET: {
            return {
                ...state,
                driveDocIds: [...action.driveDocIds]
            };
        }

        default:
            return state;
    }
};

//  Так как в беке пинед чеклисты хранятся в строках, мы должны преобразовать их в намберы
export const reformatPinedChecklist = (
    meta: IRestCardMeta
) => {
    if (meta && meta.pinnedSections && meta.pinnedSections.checklists) {
        meta.pinnedSections.checklists = meta.pinnedSections.checklists.map(id => Number(id))
        meta = JSON.parse(JSON.stringify(meta));
    }
    return meta;
}
