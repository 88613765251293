import { root } from '../../../../../store/constants';
import { ThunkAction, Dispatch } from '../../../../../types/actions';

export const closeUpdatesModal = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        root.App.controller.updatesModalView.remove();
    };
    return action;
};
