import { INotificationSettings } from '../types';

export const AT_UPDATE = 'NOTIFICATION_SETTINGS_UPDATE';

export interface IUpdateAction {
    type: typeof AT_UPDATE;
    notification: INotificationSettings;
}

export type TNotificationSettingsAction = (IUpdateAction);
