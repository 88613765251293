import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../types/types';
import { EMyWorkGroupType } from '../../../../../../aside_panel/filterPanelBoard/store/filterPanel/types';
import { updateMyWorkMeta } from '../../../../../../../../rest/effects/authUser/myWork/updateMyWorkMeta';
import {
    getProFeaturesTrialActive
} from '../../../../../../../../store/model/authUser/selectors/getProFeaturesTrialActive';
import { showSnoozeBlocker } from '../../../../../effects/showSnoozeBlocker';
import { getAuthUser } from '../../../../../../../../store/model/authUser/selectors/getAuthUser';

export const onSelect = (
    groupBy: EMyWorkGroupType
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const { allowMyWorkTaskGrouping } = getAuthUser(getState());
        if (groupBy !== EMyWorkGroupType.NONE && !allowMyWorkTaskGrouping) {
            dispatch(showSnoozeBlocker({
                allow: 'allowMyWorkTaskGrouping'
            }));
        } else {
            dispatch(updateMyWorkMeta({ groupBy }));
        }
    };
    return action;
};
