import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../../types/types';
import { GanttSection } from '../../components/GanttSection/GanttSection';
import { IGanttSectionFields } from '../../components/GanttSection/types';
import { getIsUserBasicPlanOnly } from '../../../../../../../../store/model/authUser/selectors/getIsUserBasicPlanOnly';

const mapStateToProps = (
    state: IApplicationState,
): IGanttSectionFields => {
    return {
        isBasicFeature: getIsUserBasicPlanOnly(state)
    }
};

export const GanttSectionHOC = connect(
    mapStateToProps,
    null
)(GanttSection);

GanttSectionHOC.displayName = 'GanttSectionHOC';
