import { isObjectEmpty } from './../../../../view/react_components/helpers/isObjectEmpty';
import { Store } from 'redux';
import { IApplicationState, TCardId } from '../../../../types/types';
import { DRIVE_DOCS_ACTION_SET, MODEL_UPDATE, TModelAction } from '../../actions/types';
import { getCard } from '../../selectors/getCard';
import { EMapUpdateType, TIdsMap } from '../types';
import { AT_DELETE, AT_SET, AT_UPDATE_DRIVE_DOC } from '../../driveDocs/actions/constants';
import { TDriveDocId } from '../../../../types/rest/IRestDriveDoc';
import { getDriveDoc } from '../../driveDocs/selectors/getDriveDoc';
import { ICards } from '../../cards/types';
import { cardsActionSetAction } from '../../actions/cardsActionSetAction';
import { cardsUpdateAction } from '../../cards/actions/cardsUpdateAction';

const updateCardDriveDocIdsMap = (
    state: IApplicationState,
    driveDocId: TDriveDocId,
    cardId: TCardId,
    cardDriveDocIdsMap: TIdsMap,
    addOrRemove: EMapUpdateType
): TIdsMap => {
    if (cardId) {
        const card = getCard(state, cardId);
        if (card) {
            if (!cardDriveDocIdsMap[cardId]) {
                const { driveDocIds = [] } = card;
                cardDriveDocIdsMap[cardId] = [...driveDocIds];
            }
            const index = cardDriveDocIdsMap[cardId].indexOf(driveDocId);
            if (addOrRemove === EMapUpdateType.ADD && index === -1) {
                cardDriveDocIdsMap[cardId].push(driveDocId);
            }
            if (addOrRemove === EMapUpdateType.REMOVE && index !== -1) {
                cardDriveDocIdsMap[cardId].splice(index, 1);
            }
        }
    }
    return cardDriveDocIdsMap;
}

const updateCards = (
    store: Store<IApplicationState>,
    cardDriveDocIdsMap: TIdsMap
) => {
    if (isObjectEmpty(cardDriveDocIdsMap)) return;
    const state = store.getState();
    const cards: ICards = {};
    for (let cardId in cardDriveDocIdsMap) {
        const { id, driveDocIds: currentDriveDocIds } = getCard(state, Number(cardId));
        const driveDocIds = currentDriveDocIds ? [
            ...new Set([
                ...currentDriveDocIds,
                ...cardDriveDocIdsMap[cardId]
            ])
        ] : cardDriveDocIdsMap[cardId];
        cards[id] = { driveDocIds };
    }
    store.dispatch(cardsActionSetAction(cardsUpdateAction(cards)));
}

export const driveDocIdsMiddleware  = (store: Store<IApplicationState>) => (next: any) => (action: TModelAction) => {
    let result = null, nextExecuted = false;
    let cardDriveDocIdsMap: TIdsMap = {};
    if (action.type === DRIVE_DOCS_ACTION_SET){
        const subAction = action.driveDocsAction;
        switch (subAction.type) {
            case AT_SET: {
                for (let driveDocId in subAction.driveDocs) {
                    const { cardId } = subAction.driveDocs[driveDocId];
                    if(cardId) {
                        cardDriveDocIdsMap = updateCardDriveDocIdsMap(store.getState(), Number(driveDocId), cardId, cardDriveDocIdsMap, EMapUpdateType.ADD);
                    }
                }
                break;
            }
            case AT_DELETE: {
                for (let driveDocId in subAction.driveDocs) {
                    let { cardId } = subAction.driveDocs[driveDocId];
                    if (!cardId) {
                        const driveDoc = getDriveDoc(store.getState(), Number(driveDocId));
                        cardId = driveDoc && driveDoc.cardId;
                    }
                    if(cardId) {
                        cardDriveDocIdsMap = updateCardDriveDocIdsMap(store.getState(), Number(driveDocId), cardId, cardDriveDocIdsMap, EMapUpdateType.REMOVE);
                    }
                }
                break;
            }
            case AT_UPDATE_DRIVE_DOC: { //тут ничего не делаем, т.к там cardId не меняеться
                break;
            }
        }
    } else if (action.type === MODEL_UPDATE) {
        result = next(action);
        nextExecuted = true;
        for (let driveDocId in action.model.driveDocs) {
            const { cardId } = action.model.driveDocs[driveDocId];
            if (cardId) {
                cardDriveDocIdsMap = updateCardDriveDocIdsMap(store.getState(), Number(driveDocId), cardId, cardDriveDocIdsMap, EMapUpdateType.ADD);
            }
        }
    }
    updateCards(store, cardDriveDocIdsMap);
    if (!nextExecuted) {
        result = next(action);
    }
    return result;
}
