import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../../types/types';
import { snackbarUpgradeDefault } from '../../../../../../../snackbarsStack';

export const onClickDisallow = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(snackbarUpgradeDefault());
    };
    return action;
};
