import { getMessages } from '../../../../../../../store/constants';

export const COLOR_TAGS_INPUT_PLACEHOLDER = getMessages().getText('card_form_color_tags_placeholder');
export const COLOR_TAGS_INPUT_PLACEHOLDER_EDIT = getMessages().getText('card_form_color_tags_placeholder_edit');
export const COLOR_TAGS_BUTTON_NEW = getMessages().getText('card_form_color_tags_button_new');
export const COLOR_TAGS_BUTTON_NEW_SHORT = getMessages().getText('card_form_color_tags_button_new.short');
export const COLOR_TAGS_BUTTON_EDIT = getMessages().getText('card_form_color_tags_button_edit');
export const COLOR_TAGS_BUTTON_EDIT_SHORT = getMessages().getText('card_form_color_tags_button_edit.short');
export const COLOR_TAGS_HINT = getMessages().getText('card_form_color_tags_hint');
export const EDIT_COLOR_TAGS = getMessages().getText('card_form_edit_color_tags');
