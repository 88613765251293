import { connect } from 'react-redux';
import { getCard } from '../../../../../../../store/model/selectors/getCard';
import { IApplicationState } from '../../../../../../../types/types';
import { ICardRelatedPanelButtonHOCProps } from './types';
import { CARD_SECTION_RELATED } from '../../components/CardRelatedSection/constants';
import { onClick } from './events/onClick';
import { getPanelButtonShow } from '../../../selectors/getPanelButtonShow';
import { RelatedCardsPanelButton } from '../../components/RelatedCardsPanelButton/RelatedCardsPanelButton';
import {
    IRelatedCardsPanelButtonEvents,
    IRelatedCardsPanelButtonFields
} from '../../components/RelatedCardsPanelButton/types';

const mapStateToProps = (
    state: IApplicationState,
    { boardId, cardId, isShowTooltip }: ICardRelatedPanelButtonHOCProps
): IRelatedCardsPanelButtonFields => {
    const card = getCard(state, cardId);
    const isShow = getPanelButtonShow(state, boardId, cardId);
    const tooltip = isShowTooltip
        ? CARD_SECTION_RELATED
        : null;
    return {
        isShow,
        relatedCardsCount: card.relatedCardCount || 0,
        tooltip,
    }
};

const mapDispatchToProps = (
    dispatch: any,
    props: ICardRelatedPanelButtonHOCProps
): IRelatedCardsPanelButtonEvents => {
    const {cardId, boardId, onClick: ownOnClick} = props;
    return {
        onClick: (isShowed) => dispatch(onClick(cardId, boardId, isShowed, ownOnClick))
    }
};
export const CardRelatedPanelButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(RelatedCardsPanelButton);

CardRelatedPanelButtonHOC.displayName = 'CardRelatedPanelButtonHOC';
