import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { IGetState, TBoardId } from '../../../../../../../../../types/types';
import { getBoard } from '../../../../../../../../../store/model/selectors/getBoardById';
import { boardSetShowOnGantt } from '../../../../../../../../../rest/effects/board/boardSetShowOnGantt';
import { SegmentBoardEvent, segmentTrackAction } from '../../../../../../../../../middlewares/segment';
import { SegmentBoardOption, SegmentBoardOptionStatusValue } from '../../../../../../../../../middlewares/segment/trackEntities/boardEvents';

export const toggleEnabledGantt = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const board = getBoard(getState(), boardId);
        const showOnGantt = !board.showOnGantt;
        dispatch(boardSetShowOnGantt(boardId, showOnGantt));
        dispatch(segmentTrackAction(SegmentBoardEvent.SHOW_ON_GANTT_CLICKED, {
            name: SegmentBoardOption.STATUS,
            value: showOnGantt ? SegmentBoardOptionStatusValue.ON :
                SegmentBoardOptionStatusValue.OFF
        }));
    }
    return action;
}
