import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { ICardChecklistsHOCProps } from './types';
import { ICardChecklistsEvents, ICardChecklistsFields } from '../../components/CardChecklists/types';
import { CardChecklists } from '../../components/CardChecklists/CardChecklists';
import { onChecklistAdd } from './events/onChecklistAdd';
import { CHECKLIST_DEFAULT_ID } from '../../../../../../../store/model/checklists/checklists/constants';
import { checkIfDefaultChecklistIsEmpty } from './selectors/checkIfDefaultChecklistIsEmpty';
import {
    getBoardIsCardDefaultProperty
} from '../../../../../../../store/model/board/selectors/getBoardIsCardDefaultProperty';
import { EAsidePanelProperty } from '../../../../asidePanel/components/AsidePanel/types';
import {
    getBoardIsCardRequiredProperty
} from '../../../../../../../store/model/board/selectors/getBoardIsCardRequiredProperty';
import { getSectionType } from '../../../selectors/getSectionType';
import { getCardHasChecklist } from '../../../../../../../store/model/selectors/getCardHasChecklist';
import {
    getCardHasCustomChecklist
} from '../../../../../../../store/model/checklists/checklists/selectors/getCardHasCustomChecklist';
import { onSort } from './events/onSort';
import { onNameChange } from './events/onNameChange';
import { IChecklistItem } from '../../../../../../../store/model/checklists/checklist/types';
import { getChecklistItemsSection } from '../selectors/getChecklistItemsSection';
import { onDelete } from './events/onDelete';
import { getAuthUser } from '../../../../../../../store/model/authUser/selectors/getAuthUser';
import { onClose } from './events/onClose';
import { getCardChecklistsSortedCreateSelector } from './selectors/getCardChecklistsSortedSelector';

const mapStateToProps = () => {
    const getChecklistItems = getChecklistItemsSection();
    const getChecklists = getCardChecklistsSortedCreateSelector();

    const _mapStateToProps = (
        state: IApplicationState,
        { cardId, boardId }: ICardChecklistsHOCProps
    ): ICardChecklistsFields => {
        const sectionType = getSectionType(state, boardId, cardId);
        const isShow = getCardHasChecklist(state, cardId) || getCardHasCustomChecklist(state, cardId);
        const checklists = getChecklists(state, cardId);
        const isDefaultChecklistEmpty = checkIfDefaultChecklistIsEmpty(state, cardId);
        const isEmpty = checklists.length === 0 || (
            checklists.length === 1 &&
            checklists[0].id === CHECKLIST_DEFAULT_ID &&
            isDefaultChecklistEmpty
        );
        let checklistItems: IChecklistItem[];
        if (!isEmpty && checklists.length) {
            checklistItems = getChecklistItems(
                state,
                cardId,
                checklists.length === 1 ? checklists[0].id : CHECKLIST_DEFAULT_ID
            );
        }
        const { allowChecklistCount } = getAuthUser(state);
        return {
            aloudCount: allowChecklistCount,
            checklists,
            checklistItems,
            isShow,
            isDefaultProperty: getBoardIsCardDefaultProperty(state, boardId, EAsidePanelProperty.CARD_CHECKLIST),
            isEmpty,
            isRequired: getBoardIsCardRequiredProperty(state, boardId, EAsidePanelProperty.CARD_CHECKLIST),
            sectionType,
        }
    };

    return _mapStateToProps;
};

const mapDispatchToProps = (
    dispatch: any,
    { cardId }: ICardChecklistsHOCProps
): ICardChecklistsEvents => {
    return {
        onChecklistAdd: () => dispatch(onChecklistAdd(cardId)),
        onClose: () => dispatch(onClose()),
        onDelete: (checklistId) => dispatch(onDelete(cardId, checklistId)),
        onSort: (itemId, index, checklistId, newChecklistId) => dispatch(onSort(cardId, itemId, index, checklistId, newChecklistId)),
        onNameChange: (name) => dispatch(onNameChange(cardId, name))
    }
};

export const CardChecklistsHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardChecklists);

CardChecklistsHOC.displayName = 'CardChecklistsHOC';
