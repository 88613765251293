import { IGetState } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { ECardChecklistOrderMode } from '../../../../../../../../types/rest/IUserMeta';
import { getAuthUser } from '../../../../../../../../store/model/authUser/selectors/getAuthUser';
import {
    authUserSetChecklistOrderMode
} from '../../../../../../../../rest/effects/authUser/authUserSetChecklistOrderMode';

export const onOrderModeChange = (
    sorting: ECardChecklistOrderMode
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.CHECKLIST_SORT_BUTTON_CLICKED));
        const user = getAuthUser(getState());
        return dispatch(authUserSetChecklistOrderMode(user.id, sorting));
    };
    return action;
};
