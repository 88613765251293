import { EColorTagColor } from '../../../../../../../types/model';

export const DEFAULT_COLORS: Readonly<EColorTagColor[]> = [
    EColorTagColor.VIVID_RED,
    EColorTagColor.VIVID_ORANGE,
    EColorTagColor.VIVID_YELLOW,
    EColorTagColor.VIVID_GREEN,
    EColorTagColor.VIVID_BLUE,
    EColorTagColor.VIVID_PURPLE,
    EColorTagColor.VIVID_PINK,
    EColorTagColor.VIVID_BROWN,
    EColorTagColor.VIVID_LIGHT_GREY,
    EColorTagColor.VIVID_GREY,
    EColorTagColor.PASTEL_RED,
    EColorTagColor.PASTEL_ORANGE,
    EColorTagColor.PASTEL_YELLOW,
    EColorTagColor.PASTEL_GREEN,
    EColorTagColor.PASTEL_BLUE,
    EColorTagColor.PASTEL_PURPLE,
    EColorTagColor.PASTEL_PINK,
    EColorTagColor.PASTEL_BROWN,
    EColorTagColor.PASTEL_LIGHT_GREY,
    EColorTagColor.PASTEL_GREY,
];
