import { getTemplateListPromise } from '../../../helpers/templates/getTemplateListPromise';
import { INewCard } from '../../../../store/model/card/types';
import { NEW_RECURRING_NAME } from '../../../../view/react_components/aside_panel/cardTemplates/CreateNew/constants';
import { TStatus } from '../../../../types/model';
import { cardAdd } from '../card/cardAdd';
import { Dispatch } from '../../../../types/actions';
import { IGetState, TBoardId } from '../../../../types/types';
import { REST_RECURRING_TASKS } from '../../../constants';
import {
    ERecurringTaskEndType,
    ERecurringTaskFrequencyType,
    IRestRecurringTask
} from '../../../../types/rest/recurringTask/IRestRecurringTask';
import { getBoardListsByStatus } from '../../../../store/model/selectors/getBoardListsByStatus';
import { getListCardsByStatus } from '../../../../store/model/selectors/getListCardsByStatus';
import { getOrderNumberByCards } from '../../../../store/model/helpers/getOrderNumberByCards';

export const createRecurring = (
    boardId: TBoardId,
    name = '',
    epicId: number = 0
) => {
    return (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const templateList = getTemplateListPromise(state, boardId);
        return templateList.then((templateList) => {
            const lists = getBoardListsByStatus(state, boardId);
            const list = lists
                            .sort((aList, bList) => aList.orderNumber - bList.orderNumber )
                            .find((list) => list.status !== TStatus.STATUS_SERVICE_TEMPLATE) || null;

            const recurringTasks: IRestRecurringTask = {
                frequencyType: ERecurringTaskFrequencyType.DAY,
                day: {
                    dayCount: 1
                },
                endType: ERecurringTaskEndType.NEVER,
                listId: list ? list.id : null
            };
            const templateCards = getListCardsByStatus(state, templateList.id, TStatus.STATUS_SERVICE_TEMPLATE);
            const cardName = name || NEW_RECURRING_NAME;
            const newCard: INewCard = {
                name: cardName,
                listId: templateList.id,
                status: TStatus.STATUS_SERVICE_TEMPLATE,
                cardNumber: '',
                recurringTasks: [recurringTasks],
                orderNumber: getOrderNumberByCards(templateCards, false),
                epicId
            };
            return dispatch(cardAdd(newCard, {
                url: REST_RECURRING_TASKS + '/createCard'
            }));
        });
    }
}
