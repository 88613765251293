import { fetchHandler } from './fetchHandler';
import { getRestHeadersPost } from '../rest/helpers/getRestHeadersHelper';

export class PartUploader<T, R = T> {
    constructor(
        url: string,
        collectionToLoad: Array<T>,
        partSize: number,
        init: RequestInit = getRestHeadersPost()
    ) {
        this.url = url;
        this.processResult = [];
        this.collectionToLoad = collectionToLoad;
        this.partSize = partSize;

        this.partNumber = this.collectionToLoad.length / this.partSize;
        this.init = init;
    }
    url: string;
    processResult: Array<R>;
    collectionToLoad: Array<T>;
    partSize: number;
    partNumber: number;
    iterator: number = 0;
    init: RequestInit;

    uploadPart(
        processHandler?: (partResult: Array<R>, processResult: Array<R>) => void
    ): Promise<Array<R>> {
        const part = this.collectionToLoad.slice(this.iterator, this.iterator + this.partSize);
        return fetchHandler<Array<R>>(this.url,
            {
                ...this.init,
                body: JSON.stringify(part)
            })
            .then((result) => {
                this.iterator = this.iterator + this.partSize;

                if (Array.isArray(result)) {
                    result.forEach((obj: R) => {
                        this.processResult.push(obj)
                    });
                }

                if (processHandler) {
                    processHandler(result, this.processResult);
                }

                if (this.iterator >= this.collectionToLoad.length) {
                    return Promise.resolve(this.processResult);
                }
                return this.uploadPart(processHandler);
            })
    }
}
