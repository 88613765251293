import { getMessages } from '../../../../store/constants';
import { IFilter } from './store/filter/types';
import { ICardAssigneeRole, IColor } from '../../../../types/model';
import { ICheckboxFilter } from './components/CheckboxList/types';
import { IRadioFilter } from './components/RadioList/types';
import { EProcessingStatus } from '../../../../types/rest/IUserMeta';
import { ERestCardAssigneeInfoType } from '../../../../types/rest/IRestCardAssigneeInfo';
import { ICardAssigneeElement } from './components/AssigneesSection/components/AssigneesSection/types';

export const CLASS_ASIDE_FILTER = 'aside-panel--filter';
export const CLASS_FILTER_SECTION = 'filters-section';
export const CLASS_FILTER_SECTION_TOGGLER = 'filters-section-toggler';
export const DEFAULT_FILTER_ID: number = 0;
export const MY_WORK_FILTER_BOARD_ID = -1;
export const FP_KEY_ANY = 'Any';
export const FP_KEY_SORT_CARDS_BY_FILTER_KEY_CHRONOLOGICAL_ORDER = 'Chronological order';
export const FP_KEY_SORT_CARDS_BY_FILTER_KEY_DUE_DATE_EARLIEST_FIRST = 'Due date, earliest first';
export const FP_KEY_SORT_CARDS_BY_FILTER_KEY_DUE_DATE_LATEST_FIRST = 'Due date, latest first';

export const FP_KEY_COMMENTS_KEY_WITH_COMMENTS = 'With comments';
export const FP_KEY_COMMENTS_KEY_WITHOUT_COMMENTS = 'Without comments';

export const FP_KEY_COMMENTS_KEY_WITH_ATTACHMENTS = 'With attachments';
export const FP_KEY_COMMENTS_KEY_WITHOUT_ATTACHMENTS = 'Without attachments';

export const FP_KEY_HIDE_FILTER_EMPTY_LIST = 'Empty lists';
export const FP_KEY_HIDE_FILTER_SUBCARDS = 'Subcards';
export const FP_KEY_HIDE_FILTER_DONE_CARDS = 'Done cards';
export const FP_KEY_HIDE_FILTER_EPICS = 'Epic cards';

export const FP_KEY_SHOW_FILTER_DONE_CARDS = 'Done cards';
export const FP_KEY_SHOW_FILTER_OVERDUE_CARDS = 'Overdue cards';
export const FP_KEY_SHOW_FILTER_EPICS_CARDS = 'Epic cards';
export const FP_KEY_SHOW_FILTER_SUBCARDS = 'Subcards';
export const FP_KEY_SHOW_FILTER_UNDONE_CHECKLISTS = 'Cards with assigned to me undone checklist items';
export const FP_MY_WORK_KEY_SHOW_FILTER_NO_DATE = 'No dates';
export const FP_MY_WORK_KEY_SHOW_FILTER_IN_PROGRESS = 'In progress';
export const FP_MY_WORK_KEY_SHOW_FILTER_UPCOMING = 'Upcoming';
export const FP_MY_WORK_KEY_SHOW_FILTER_READY_TO_GO = 'Ready to go';

export const FP_KEY_CARDS_FILTER_ASSIGNED_TO_ME = getMessages().getText('assigned_to_me.type.assigned_to_me');
export const FP_KEY_CARDS_FILTER_I_HAVE_ASSIGNED = getMessages().getText('assigned_to_me.type.i_have_assigned');
export const FP_KEY_CARDS_FILTER_I_HAVE_CREATED = getMessages().getText('assigned_to_me.type.i_have_created');
export const FP_KEY_CARDS_FILTER_I_AM_SUBSCRIBED = getMessages().getText('assigned_to_me.type.i_am_subscribed');
export const FP_KEY_CARDS_FILTER_I_HAVE_CONTRIBUTED = getMessages().getText('assigned_to_me.type.i_have_contributed');
export const FP_KEY_CARDS_FILTER_I_HAVE_ADDED_TO_MYWORK = getMessages().getText('assigned_to_me.type.i_have_added_to_mywork');

export const INITIAL_EDITED_FILTER_ID: number = -1;

export const INIT_FILTER_ID: number = 0;
export const DEFAULT_FILTER_NAME: string = 'Untitled';

export const FP_RESET_ASSIGNED_LIMIT: number = 5;
export const FP_RESET_ASSIGNED_TITLE: string = 'Reset all';
export const FP_DATEPICKER_FROM_DATE_LABEL: string = 'From date';
export const FP_DATEPICKER_TO_DATE_LABEL: string = 'To date';

export const ONE_DAY_LENGTH: number = 1000 * 60 * 60 * 24;

export const SORT_BY_FILTERS_LIST: ReadonlyArray<Readonly<IRadioFilter>> = Object.freeze([
    Object.freeze({
        value: FP_KEY_SORT_CARDS_BY_FILTER_KEY_CHRONOLOGICAL_ORDER,
    }),
    Object.freeze({
        value: FP_KEY_SORT_CARDS_BY_FILTER_KEY_DUE_DATE_EARLIEST_FIRST,
    }),
    Object.freeze({
        value: FP_KEY_SORT_CARDS_BY_FILTER_KEY_DUE_DATE_LATEST_FIRST,
    }),
]);

export const COMMENTS_FILTERS_LIST: ReadonlyArray<Readonly<IRadioFilter>> = Object.freeze([
    Object.freeze({
        value: FP_KEY_ANY,
    }),
    Object.freeze({
        value: FP_KEY_COMMENTS_KEY_WITH_COMMENTS,
    }),
    Object.freeze({
        value: FP_KEY_COMMENTS_KEY_WITHOUT_COMMENTS,
    }),
]);

export const ATTACHMENTS_FILTERS_LIST: ReadonlyArray<Readonly<IRadioFilter>> = Object.freeze([
    Object.freeze({
        value: FP_KEY_ANY,
    }),
    Object.freeze({
        value: FP_KEY_COMMENTS_KEY_WITH_ATTACHMENTS,
    }),
    Object.freeze({
        value: FP_KEY_COMMENTS_KEY_WITHOUT_ATTACHMENTS,
    }),
]);

export const HIDE_FILTERS_LIST: ReadonlyArray<Readonly<ICheckboxFilter>> = Object.freeze([
    Object.freeze({
        value: FP_KEY_HIDE_FILTER_EMPTY_LIST,
    }),
    Object.freeze({
        value: FP_KEY_HIDE_FILTER_SUBCARDS,
    }),
    Object.freeze({
        value: FP_KEY_HIDE_FILTER_DONE_CARDS,
    }),
    Object.freeze({
        value: FP_KEY_HIDE_FILTER_EPICS,
    }),
]);

export const SHOW_FILTERS_LIST: ReadonlyArray<Readonly<ICheckboxFilter>> = Object.freeze([
    Object.freeze({
        value: FP_KEY_SHOW_FILTER_DONE_CARDS,
    }),
    Object.freeze({
        value: FP_KEY_SHOW_FILTER_OVERDUE_CARDS,
    }),
    Object.freeze({
        value: FP_KEY_SHOW_FILTER_EPICS_CARDS,
    }),
    Object.freeze({
        value: FP_KEY_SHOW_FILTER_SUBCARDS,
    }),
    Object.freeze({
        value: FP_KEY_SHOW_FILTER_UNDONE_CHECKLISTS,
    }),
]);

export const MY_WORK_SHOW_FILTERS_MAP: Readonly<Record<string, EProcessingStatus>> = {
    [FP_KEY_SHOW_FILTER_DONE_CARDS]: EProcessingStatus.done,
    [FP_KEY_SHOW_FILTER_OVERDUE_CARDS]: EProcessingStatus.overdue,
    [FP_MY_WORK_KEY_SHOW_FILTER_NO_DATE]: EProcessingStatus.noDates,
    [FP_MY_WORK_KEY_SHOW_FILTER_IN_PROGRESS]: EProcessingStatus.inProgress,
    [FP_MY_WORK_KEY_SHOW_FILTER_UPCOMING]: EProcessingStatus.upcoming,
    [FP_MY_WORK_KEY_SHOW_FILTER_READY_TO_GO]: EProcessingStatus.readyToGo
}

export const MY_WORK_CARDS_FILTERS_MAP: Readonly<Record<string, ERestCardAssigneeInfoType>> = {
    [FP_KEY_CARDS_FILTER_ASSIGNED_TO_ME]: ERestCardAssigneeInfoType.ASSIGNED_TO_ME,
    [FP_KEY_CARDS_FILTER_I_HAVE_ASSIGNED]: ERestCardAssigneeInfoType.I_HAVE_ASSIGNED,
    [FP_KEY_CARDS_FILTER_I_HAVE_CREATED]: ERestCardAssigneeInfoType.I_HAVE_CREATED,
    [FP_KEY_CARDS_FILTER_I_AM_SUBSCRIBED]: ERestCardAssigneeInfoType.I_AM_SUBSCRIBED,
    [FP_KEY_CARDS_FILTER_I_HAVE_CONTRIBUTED]: ERestCardAssigneeInfoType.I_HAVE_CONTRIBUTED,
    [FP_KEY_CARDS_FILTER_I_HAVE_ADDED_TO_MYWORK]: ERestCardAssigneeInfoType.I_HAVE_ADDED_TO_MYWORK
}

export const FP_NO_COLOR_TAG: IColor = {
    id: null,
    name: getMessages().getText('model.color.nocolor')
};

export const FP_NO_COLOR = 'no-color';

export const FP_NO_ROLE: ICardAssigneeRole = {
    id: null,
    name: getMessages().getText('model.role.norole')
};

export const FP_UNASSIGNED_USER_PERMISSION_ID = 'null';
export const FP_UNASSIGNED_USER: ICardAssigneeElement = {
    sharedUser: {
        fullName: 'Unassigned',
        photoUrl: null,
        permissionId: FP_UNASSIGNED_USER_PERMISSION_ID, // надо непустое значение
    }
};

export const FP_EDITED_FILTER_INITIAL_VALUE: IFilter = null;

export const FP_LOCALSTORAGE_KEYWORDS = 'FP_LOCALSTORAGE_KEYWORDS';
export const FP_KEYWORDS_LIMIT = 10;

export const FILTER_PANEL_HEADER_TITLE = getMessages().getText('fp.header.title');
export const FILTER_FOOTER_RESET_BUTTON_TITLE = getMessages().getText('fp.footer.reset_button_title');
export const FILTER_FOOTER_APPLY_BUTTON_TITLE = getMessages().getText('fp.footer.apply_button_title');
export const FILTER_FOOTER_SAVE_BUTTON_TITLE = getMessages().getText('fp.header.save_button_title');
export const FILTER_FOOTER_CANCEL_BUTTON_TITLE = getMessages().getText('fp.header.cancel_button_title');
export const FP_RECENT_LIST_TITLE = getMessages().getText('fp.search.recent_list_title');
export const FP_SAVED_FILTERS_LIST_TITLE = getMessages().getText('fp.saved_filters_list.title');
export const FP_SAVED_FILTERS_LIST_NEW_TITLE = getMessages().getText('fp.saved_filters_list.title.add');
export const FP_SAVED_FILTERS_LIST_EDIT_TITLE = getMessages().getText('fp.saved_filters_list.title.edit');
export const FP_SAVED_FILTERS_DESCRIPTION = getMessages().getText('fp.saved_filters_list.description');
export const FP_SAVED_FILTERS_SHOWN_ON_BAR_TITLE = getMessages().getText('fp.saved_filters_list.shown_on_bar.title');
export const FP_SAVED_FILTERS_OTHER_DESCRIPTION = getMessages().getText('fp.saved_filters_list.other.description');
export const FP_SAVED_FILTERS_OTHER_TITLE = getMessages().getText('fp.saved_filters_list.other.title');
export const FP_CUSTOM_FILTERS_TITLE = getMessages().getText('fp.custom_filters.title');
export const FP_CUSTOM_FILTERS_DESCRIPTION = getMessages().getText('fp.custom_filters.description');
export const FP_SAVED_PUBLIC = getMessages().getText('fp.saved_filters_list.public');
export const FP_ADD_NEW_FILTER_BUTTON_TITLE = getMessages().getText('fp.add_new_filter_button.title');
export const FP_ASSIGNEES_TITLE = getMessages().getText('fp.assignees.title');
export const FP_TAGS_FILTER_TITLE = getMessages().getText('fp.tags_filter.title');
export const FP_COLOR_TAGS_FILTER_TITLE = getMessages().getText('fp.color_tags_filter.title');
export const FP_CARD_COLOR_FILTER_TITLE = getMessages().getText('fp.card_color_filter.title');
export const FP_LIST_COLOR_FILTER_TITLE = getMessages().getText('fp.list_color_filter.title');
export const FP_HIDE_TITLE = getMessages().getText('fp.hide_filter.title');
export const FP_SHOW_TITLE = getMessages().getText('fp.show_filter.title');
export const FP_PRIORITY_TITLE = getMessages().getText('fp.priority_filter.title');
export const FP_SORT_TITLE = getMessages().getText('fp.sort_filter.title');
export const FP_COMMENTS_TITLE = getMessages().getText('fp.comments_filter.title');
export const FP_ATTACHMENTS_TITLE = getMessages().getText('fp.attachments_filter.title');
export const FP_DATES_TITLE = getMessages().getText('fp.dates.title');
export const FP_ROLES_FILTER_TITLE = getMessages().getText('fp.roles_filter.title');
export const FP_BOARDS_TITLE = getMessages().getText('fp.boards_filter.title');
export const FP_HIDDEN_CARDS_TOOLTIP = getMessages().getText('fp.summary.hidden_filter.tooltip');
export const FP_HIDDEN_CARD_TITLE = 'fp.summary.hidden_filter.card';
export const FP_HIDDEN_CARDS_TITLE = 'fp.summary.hidden_filter.cards';
export const FP_CARDS_TITLE = getMessages().getText('fp.cards_filter.title');
export const FP_ARCHIVED_CARDS_TOOLTIP = getMessages().getText('fp.summary.archived_filter.tooltip');
export const FP_ARCHIVED_CARD_TITLE = 'fp.summary.archived_filter.card';
export const FP_ARCHIVED_CARDS_TITLE = 'fp.summary.archived_filter.cards';

export const INITIAL: boolean = null; // INITIAL is null, not false
