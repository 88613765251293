import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId, TChecklistId } from '../../../../../../../../types/types';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { checklistItemUpdate } from '../../../../../../../../rest/effects/card/checklist/checklistItemUpdate';
import { IChecklistItem } from '../../../../../../../../store/model/checklists/checklist/types';

export const onItemSave = (
    cardId: TCardId,
    checklistId: TChecklistId,
    item: IChecklistItem
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.CHANGED_WEIGHT_OF_CHECKLIST_ELEMENT));
        return dispatch(checklistItemUpdate(cardId, checklistId, item.id, item));
    };
    return action;
};
