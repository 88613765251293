import { Dispatch, ThunkAction } from 'app/types/actions';
import { IApplicationState, IGetState, TCardId, TListId } from 'app/types/types';
import { IBoard } from 'app/store/model/board/types';
import routeNavigateToIndex from '../../../../../../base/effects/routeNavigateToIndex';
import { cardsMoveToAnotherBoard } from 'app/rest/effects/card/card/move/cardsMoveToAnotherBoard';
import { SegmentCardEvent, SegmentCardOptions, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { SegmentCardOptionLocationValue } from 'app/middlewares/segment/trackEntities/cardEvents';
import { MAKE_FIRST_ITEM_INDEX } from '../../../../../../base/components/MoveTo/components/constants';
import { getCardSubcardsTree } from 'app/store/model/selectors/getCardSubcardsTree';
import { getMessages } from 'app/store/constants';
import { snackbarPromtYesNo } from '../../../../../../snackbarsStack/effects/variantPromt/snackbarPromtYesNo';
import { getEditCardId } from 'app/store/router/selectors/getEditCardId';
import { getCardCustomProperties } from '../../../../../../../../store/model/selectors/getCardCustomProperties';
import {
    getBoardCardCustomProperties
} from '../../../../../../../../store/model/board/selectors/getBoardCardCustomProperties';
import { getBoardIdByCardId } from '../../../../../../../../store/model/selectors/getBoardIdByCardId';

export const cardMoveToAnotherBoard = (
    currentCardId: TCardId,
    destBoard: IBoard,
    destListId: TListId,
    cardIndex?: number,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.MOVE_CARD_TO_ANOTHER_BOARD_CHOSEN));
        if (cardIndex === MAKE_FIRST_ITEM_INDEX) {
            dispatch(segmentTrackAction(SegmentCardEvent.MOVE_TO_MAKE_FIRST_CHOSEN));
        }
        const destList = destBoard.lists.find(list => list.id === destListId);
        const subcardIds = getCardSubcardsTree(getState(), currentCardId).map(card => card.id);
        return checkMoveSubcards(dispatch, subcardIds).then(moveSubcards => {
            return checkMoveCustomProperties(dispatch, getState(), destBoard, currentCardId).then(moveCustomProperties => {
                if (getEditCardId(getState()) === currentCardId) routeNavigateToIndex();
                const cardIds = moveSubcards ? [currentCardId, ...subcardIds] : [currentCardId];
                return dispatch(cardsMoveToAnotherBoard(cardIds, destBoard, destList, cardIndex, true, moveSubcards, moveCustomProperties))
                    .then(() => {
                        const valueLocation = cardIndex !== undefined ? SegmentCardOptionLocationValue.OTHER_OPENED : SegmentCardOptionLocationValue.OTHER_CLOSED;
                        dispatch(segmentTrackAction(SegmentCardEvent.MOVE_TO_CARD_MOVED,
                            {
                                name: SegmentCardOptions.LOCATION,
                                value: valueLocation
                            }
                        ));
                    });
            });
        })
    };
    return action;
};

const checkMoveSubcards = (
    dispatch: Dispatch,
    subcardIds: number[]
): Promise<boolean> => {
    if (!subcardIds || subcardIds.length < 1) {
        return Promise.resolve(false);
    }
    return new Promise<boolean>((resolve) => {
        dispatch(snackbarPromtYesNo({
            id: 'PROMPT_MOVE_SUBCARDS',
            text: getMessages().getText('snackbar.card.move.subcards.text'),
            actionApply: () => {
                segmentTrackAction(SegmentCardEvent.MOVE_WITH_SUBCARDS_YES);
                resolve(true)
            },
            actionCancel: () => {
                segmentTrackAction(SegmentCardEvent.MOVE_WITH_SUBCARDS_NO);
                resolve(false)
            }
        }));
    });
}

const checkMoveCustomProperties = (
    dispatch: Dispatch,
    state: IApplicationState,
    destBoard: IBoard,
    cardId: TCardId
): Promise<boolean> => {
    const cardCustomProperties= { ...getCardCustomProperties(state, cardId) };
    const boardCustomProperties= getBoardCardCustomProperties(state, getBoardIdByCardId(state, cardId));
    const destBoardCustomProperties= destBoard && destBoard.meta && destBoard.meta.cardCustomProperties || {};
    let isShowSnackbar = false;
    for (let id in cardCustomProperties) {
        const boardCustomProperty =  boardCustomProperties[id];
        let isSimilarProperty = false;
        for (let destId in destBoardCustomProperties) {
            const destBoardCustomProperty = destBoardCustomProperties[destId];
            if (
                destBoardCustomProperty && boardCustomProperty &&
                destBoardCustomProperty.name === boardCustomProperty.name &&
                destBoardCustomProperty.type === boardCustomProperty.type
            ) {
                isSimilarProperty = true;
                break;
            }
        }
        if (!destBoardCustomProperties[id] && !isSimilarProperty) {
            isShowSnackbar = true;
            break;
        }
    }
    if (!isShowSnackbar) {
        return Promise.resolve(false);
    }
    return new Promise<boolean>((resolve) => {
        dispatch(snackbarPromtYesNo({
            id: 'PROMPT_MOVE_CUSTOM_PROPERTIES',
            text: getMessages().getText('snackbar.card.move.custom_properties.text'),
            actionApply: () => {
                segmentTrackAction(SegmentCardEvent.MOVE_WITH_CUSTOM_PROPERTIES_YES);
                resolve(true)
            },
            actionCancel: () => {
                segmentTrackAction(SegmentCardEvent.MOVE_WITH_CUSTOM_PROPERTIES_NO);
                resolve(false)
            }
        }));
    });
}
