import {NP_DEFAULT_TYPE} from '../../constants';
import {INavigationPanelState} from './types';
import {getLocalStorageNavigationPanel} from '../../helpers/getLocalStorageNavigationPanel';

const navigationPanelInitialState = {
    isOpen: false,
    panelType: NP_DEFAULT_TYPE,
    panelTypeChanged: false,
    quickStartGuide: null as any,
};

export const getNavigationPanelInitialState = (): INavigationPanelState => {
    return getLocalStorageNavigationPanel() || {...navigationPanelInitialState}
};
