import { Dispatch, ThunkAction } from '../../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../../types/types';
import { REST_CARD_SECTIONS, REST_CARDS } from '../../../../../constants';
import { getRestHeadersPost } from '../../../../../helpers/getRestHeadersHelper';
import { IRestCard } from '../../../../../../types/rest/IRestCard';
import { ICardChecklists } from '../../../../../../store/model/checklists/checklists/types';
import { IChecklistItems } from '../../../../../../store/model/checklists/checklist/types';
import { checklistsSetStore } from '../../../../../../store/model/effects/checklistsSet';
import { IRestPinnedSections } from '../../../../../../types/rest/IRestCardMeta';
import { PartUploader } from '../../../../../../util/part_uploader';

export type TCardRestGetSectionsRequest = {
    cardId: TCardId;
    sections: Array<keyof IRestPinnedSections>;
}

const PORTION_SIZE = 20;

export const cardRestGetSections = (
    cardRequest: TCardRestGetSectionsRequest[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (!cardRequest.length) return Promise.resolve();
        const partUploader = new PartUploader<TCardRestGetSectionsRequest, IRestCard>(
            REST_CARDS + REST_CARD_SECTIONS,
            cardRequest,
            PORTION_SIZE,
            getRestHeadersPost()
        );
        return partUploader.uploadPart(
            (cards) => {
                cards.forEach(card => {
                    if (card.checklists) {
                        const checklists: ICardChecklists = {};
                        card.checklists.forEach(checklist => {
                            checklists[checklist.id] = checklist;
                            if (checklist.checkItems) {
                                const items: IChecklistItems = {};
                                checklist.checkItems.forEach(item => {
                                    items[item.id] = item;
                                });
                                checklists[checklist.id].checkItems = items;
                            }
                        });
                        dispatch(checklistsSetStore(card.id, checklists));
                    }
                })
            }
        )
    };
    return action;
};
