import { ThunkAction } from '../../../../../../../types/actions';
import { root } from '../../../../../../../store/constants';
import { BROWSER_NOTIFICATIONS_REQUEST_ACTION_DATE_KEY } from '../../../components/NotificationsRequest/constants';

export const completeRequest = (): ThunkAction => {
    return (
        dispatch,
        getState
    ) => {
        localStorage.setItem(BROWSER_NOTIFICATIONS_REQUEST_ACTION_DATE_KEY, Date.now().toString());
        root.App.controller.mainView.notificationsRequestDialog.remove();
    }
}
