import { TBoardId } from '../../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { StatusListsTogglerType } from '../../StatusListsTogglerHOC/types';
import { resetOnHoldInWorkLists } from '../effects/resetOnHoldInWorkLists';
import { resetDoneList } from '../effects/resetDoneList';

export const onToggleListStatusMapping = (
    boardId: TBoardId,
    togleType: StatusListsTogglerType
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        if (togleType === StatusListsTogglerType.ON_HOLD_IN_WORK) {
            return dispatch(resetOnHoldInWorkLists(boardId));
        } else if (togleType === StatusListsTogglerType.DONE) {
            return dispatch(resetDoneList(boardId));
        }
    };
    return action;
};
