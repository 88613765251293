import { IApplicationState } from '../../../../../../types/types';
import { connect } from 'react-redux';
import {
    filterPanelBoardsSelector
} from '../../../../aside_panel/filterPanelBoard/store/selectors/filterPanelBoardsSelector';
import {
    getCurrentEditingFilterIdSelector
} from '../../../../aside_panel/filterPanelBoard/store/selectors/getCurrentEditingFilterIdSelector';
import {
    defaultOrEditedFilterSelectorById
} from '../../../../aside_panel/filterPanelBoard/store/selectors/defaultOrEditedFilterSelectorById';
import { MY_WORK_FILTER_BOARD_ID } from '../../../../aside_panel/filterPanelBoard/constants';
import { MyWorkTopBarSearch } from '../../components/MyWorkTopBarSearch/MyWorkTopBarSearch';
import {
    IMyWorkTopBarSearchEvents,
    IMyWorkTopBarSearchFields
} from '../../components/MyWorkTopBarSearch/types';
import { onFilterChange } from '../TopBarFilterButtonHOC/events/onFilterChange';
import { getMyWorkFilterBarCollapsed } from '../../../myWorkView/selectors/getMyWorkFilterBarCollapsed';
import { IMyWorkTopBarSearchHOCProps } from './types';

const mapStateToProps = (
    state: IApplicationState
): IMyWorkTopBarSearchFields => {
    const filterPanel = filterPanelBoardsSelector(state, MY_WORK_FILTER_BOARD_ID);
    const defaultOrEditedFilterId = getCurrentEditingFilterIdSelector(filterPanel);
    const defaultOrEditedFilter = defaultOrEditedFilterSelectorById(filterPanel, defaultOrEditedFilterId);
    return {
        searchQuery: defaultOrEditedFilter.searchedKeyWord,
        isMyWorkCollapsed: getMyWorkFilterBarCollapsed(state)
    };
};

const mapDispatchToProps = (
    dispatch: any,
    { setSearch }: IMyWorkTopBarSearchHOCProps
): IMyWorkTopBarSearchEvents => ({
    setSearch,
    onSetSearchQuery: (query) => dispatch(onFilterChange(query, MY_WORK_FILTER_BOARD_ID))
});

export const MyWorkTopBarSearchHOC = connect(
    mapStateToProps, mapDispatchToProps
)(MyWorkTopBarSearch);

MyWorkTopBarSearchHOC.displayName = 'MyWorkTopBarSearchHOC';
