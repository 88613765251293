import { IGetState } from '../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { closeUpdatesTips } from '../../../effects/closeUpdatesTips';

export const onClose = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(closeUpdatesTips());
    };
    return action;
};
