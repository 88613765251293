import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { SegmentBoardManagerEvent, SegmentNavPanelEvent, segmentTrackAction } from '../../../../../middlewares/segment';
import { ENavigationPanelTypes} from '../constants';
import { SegmentNavPanelOption } from '../../../../../middlewares/segment/trackEntities/navPanelEvents';
import { EViewTypes } from '../../../../../const';
import { TGoogleDriveId } from '../../../dialogs/openBoards/store/types';

export const navPanelOpenedMouseEnterSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentNavPanelEvent.NAV_PANEL_OPENED_MOUSE_ENTER))
};

export const navPanelClosedMouseLeaveSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentNavPanelEvent.NAV_PANEL_CLOSED_MOUSE_LEAVE))
};

export const anchorClickedSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentNavPanelEvent.ANCHOR_CLICKED))
};

export const anchorMobileClickedSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentNavPanelEvent.ANCHOR_MOBILE_CLICKED))
};

export const boardTabClickedSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentNavPanelEvent.BOARD_TAB_CLICKED))
};

export const boardTabClosedSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentNavPanelEvent.BOARD_TAB_CLOSED))
};

export const recentBoardTabClickedSegmentEvent: ActionCreator<ThunkAction> = (
    boardCount: number
) => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentNavPanelEvent.RECENT_BOARD_TAB_CLICKED, {
        name: SegmentNavPanelOption.ORDER,
        value: boardCount
    }))
};

export const recentBoardTabClosedSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentNavPanelEvent.RECENT_BOARD_TAB_CLOSED))
};

export const boardMenuIconClickedSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentNavPanelEvent.BOARD_MENU_ICON_CLICKED))
};

export const boardAddClickedSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentNavPanelEvent.BOARD_ADD_CLICKED))
};

export const boardCreateClickedSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentNavPanelEvent.BOARD_CREATE_CLICKED))
};

export const boardCreateOnSharedDriveClickedSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentNavPanelEvent.BOARD_CREATE_ON_SHARED_DRIVE_CLICKED));
};

export const boardCreateSharedDriveChosenSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentNavPanelEvent.BOARD_CREATE_SHARED_DRIVE_CHOSEN));
};

export const boardCreateFromTemplateClickedSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentNavPanelEvent.BOARD_CREATE_FROM_TEMPLATE_CLICKED))
};

export const openDashboardDialogClickedSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentBoardManagerEvent.OPENED_BOARD_MANAGER));
    dispatch(segmentTrackAction(SegmentNavPanelEvent.BOARDS_MANAGER_CLICKED))
};

export const boardImportBoardClickedSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentNavPanelEvent.BOARD_IMPORT_CLICKED))
};

export const boardImportBoardCancelClickedSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentNavPanelEvent.BOARD_IMPORT_CANCEL_CLICKED))
};

export const boardImportBoardCSVClickedSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentNavPanelEvent.BOARD_IMPORT_CSV_CLICKED))
};

export const boardImportBoardCSVLearnMoreClickedSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentNavPanelEvent.BOARD_IMPORT_CSV_LEARN_MORE_CLICKED))
};

export const boardImportBoardJiraClickedSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentNavPanelEvent.BOARD_IMPORT_JIRA_CLICKED))
};

export const boardImportBoardJiraLearnMoreClickedSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentNavPanelEvent.BOARD_IMPORT_JIRA_LEARN_MORE_CLICKED))
};

export const boardImportBoardTrelloClickedSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentNavPanelEvent.BOARD_IMPORT_TRELLO_CLICKED))
};

export const boardImportBoardTrelloLearnMoreClickedSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentNavPanelEvent.BOARD_IMPORT_TRELLO_LEARN_MORE_CLICKED))
};

export const openAssignedToMeClickedSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentNavPanelEvent.ASSIGNED_TO_ME_CARDS_CLICKED))
};

export const boardOpenClickedSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentBoardManagerEvent.OPENED_BOARD_MANAGER));
    dispatch(segmentTrackAction(SegmentNavPanelEvent.BOARD_OPEN_CLICKED))
};

export const viewTypeChangedSegmentEvent: ActionCreator<ThunkAction> = (
    viewType: EViewTypes
) => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentNavPanelEvent.VIEW_TYPE_CHANGED, {
        name: 'viewType',
        value: viewType
    }))
};

export const helpClickedSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentNavPanelEvent.HELP_CLICKED))
};

export const resizeClickedSegmentEvent: ActionCreator<ThunkAction> = (
    size: ENavigationPanelTypes
) => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentNavPanelEvent.RESIZE_CLICKED, {
        name: SegmentNavPanelOption.SIZE,
        value: size
    }))
};

export const closeClickedSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentNavPanelEvent.CLOSE_CLICKED))
};
