import { IGetState, TBoardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { SegmentCardEvent, SegmentCardOptions, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { getBoardCardColors } from '../../../../../../../../store/model/selectors/getBoardCardColors';

export const onOpen = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const boardColors = getBoardCardColors(getState(), boardId);
        dispatch(segmentTrackAction(SegmentCardEvent.BACKGROUND_COLOR_OPENED, {
            name: SegmentCardOptions.USED,
            value: boardColors.length
        }));
    };
    return action;
};
