import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId, TTag } from '../../../../../../../../types/types';
import { getCardTags } from '../../../../../../../../store/model/selectors/getCardTags';
import { cardSetTags } from '../../../../../../../../rest/effects/card/card/cardSetTags';
import {
    getAuthUserAllowCardTagCount
} from '../../../../../../../../store/model/authUser/selectors/getAuthUserAllowCardTagCount';
import { showSnoozeBlocker } from '../../../../../../base/effects/showSnoozeBlocker';

export const createTag = (
    cardId: TCardId,
    tag: TTag
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const tags = [...getCardTags(state, cardId)];

        const index = tags.indexOf(tag);
        if (index > -1) return Promise.resolve();

        tags.push(tag);

        const maxCount = getAuthUserAllowCardTagCount(state);
        const count = tags.length;

        if (count > maxCount) {
            dispatch(showSnoozeBlocker({ allow: 'allowCardTagCount' }));
        } else {
            return dispatch(cardSetTags(cardId, tags));
        }
    };
    return action;
};
