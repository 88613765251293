import { getMessages } from '../../../../../../store/constants';
import { EColorTagColor, TColorTagColor } from '../../../../../../types/model';

export const BOARD_SECTION_ROLES_TITLE = getMessages().getText('board_settings.roles.title');

export const COLOR_ROLES: Readonly<TColorTagColor[]> = Object.freeze([
    EColorTagColor.RED,
    EColorTagColor.ORANGE,
    EColorTagColor.YELLOW,
    EColorTagColor.GREEN,
    EColorTagColor.BLUE,
    EColorTagColor.CYAN,
    EColorTagColor.PURPLE,
    EColorTagColor.PINK,
]);
