import { Dispatch, ThunkAction } from 'app/types/actions';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';

export const onCardNumberClick = (): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.CARD_NUMBER_CLICKED));
    };
    return action;
};
