import * as _ from 'underscore';

(function($) {
    'use strict';

    (function(Messages, $) {

    	var messageData = [];
        var messageDaRu = [];
        var messageDJLR = [];

        let messageDataMS = [];

        /**
         * hashmap with  messageData for languages as simple like 'def' and composite like 'ru,def'
         */
        var fullMessageData = {
			'def' : messageData,
            'def.ms': messageDataMS,
			'ru' : messageDaRu,
			'jlr' : messageDJLR
		};

        window.fullMessageData = fullMessageData;

        Messages.mergeMessageData = function(lngsArrayKey){
        	lngsArrayKey = lngsArrayKey || [];
        	lngsArrayKey = _.uniq(lngsArrayKey);
        	var merge = [];
        	for (var i = 0; i < lngsArrayKey.length; i++) {
        		if (!_.isUndefined(fullMessageData[lngsArrayKey[i]])){
        			_.defaults(merge, fullMessageData[lngsArrayKey[i]]);
        		}
        	}
        	return merge;
        };

        /**
         * get MessageData of lngs array and caching them
         */
        Messages.getMessageData = function(lngsArrayKey){
        	lngsArrayKey = _.uniq(lngsArrayKey);
        	var key = lngsArrayKey.join();
        	var messageDataByKey = fullMessageData[key];
        	if (_.isUndefined(messageDataByKey)){
        		messageDataByKey = Messages.mergeMessageData(lngsArrayKey);
        		fullMessageData[key] = messageDataByKey;
        	}
        	return messageDataByKey;
        };



        /**
		 * Try find key on the lngs array
		 */
		Messages.getLangText = function(key, lngsArrayKey) {
			var result = Messages.getMessageData(lngsArrayKey)[key];

			return _.isUndefined(result) ? null : result;
        };

        /*** COMMON ***/

        messageData['common_archived'] = 'Archived';
        messageData['common.add'] = 'Add';
        messageData['common.annually'] = 'annually';
        messageData['common.back'] = 'Back';
        messageData['common.board'] = 'Board';
        messageData['common.board_name'] = 'Board name';
        messageData['common.cancel'] = 'Cancel';
        messageData['common.card_description'] = 'Card description';
        messageData['common.card_name'] = 'Card name';
        messageData['common.checklist'] = 'Checklist';
        messageData['common.close'] = 'Close';
        messageData['common.color_tags'] = 'Colour labels';
        messageData['common.comment_only'] = 'Comment only';
        messageData['common.copied'] =    '&nbsp;Copied!&nbsp;&nbsp;';
        messageData['common.copy_link'] = 'Copy link';
        messageData['common.date'] = 'Date';
        messageData['common.description'] = 'Description';
        messageData['common.done'] = 'Done';
        messageData['common.dropdown'] = 'Dropdown';
        messageData['common.edit'] = 'Edit';
        messageData['common.email'] = 'Email';
        messageData['common.empty'] = 'Empty';
        messageData['common.help'] = 'Help';
        messageData['common.how'] = 'How it works';
        messageData['common.label'] = 'Label';
        messageData['common.list_label'] = 'List: ';
        messageData['common.monthly'] = 'monthly';
        messageData['common.name'] = 'Name';
        messageData['common.no_results'] = 'No results';
        messageData['common.notifications'] = 'Notifications';
        messageData['common.number'] = 'Number';
        messageData['common.of'] = 'of';
        messageData['common.ok'] = 'Ok';
        messageData['common.phone'] = 'Phone number';
        messageData['common.pin_to_card'] = 'Pin to card';
        messageData['common.print'] = 'Print';
        messageData['common.read_only'] = 'Read only';
        messageData['common.remove'] = 'Remove';
        messageData['common.delete'] = 'Delete';
        messageData['common.request_license'] = 'Request license';
        messageData['common.save'] = 'Save';
        messageData['common.search.remove_all'] = 'Remove all';
        messageData['common.search.remove'] = 'Remove';
        messageData['common.search'] = 'Search';
        messageData['common.select_users'] = 'Select users';
        messageData['common.settings'] = 'Settings';
        messageData['common.show_less'] = 'Show less';
        messageData['common.show_more'] = 'Show more';
        messageData['common.starred'] = 'Starred';
        messageData['common.text'] = 'Text';
        messageData['common.time.at'] = 'at';
        messageData['common.time'] = 'Time';
        messageData['common.type'] = 'Type';
        messageData['common.unpin_from_card'] = 'Unpin from card';
        messageData['common.upgrade'] = 'Upgrade';
        messageData['common.view_only'] = 'View only';
        messageData['common.yes'] = 'Yes';
        messageData['common.no'] = 'No';
        messageData['common.turn_off'] = 'Turn off';


        /*** ACCESSIBILITY ***/

        messageData['accessibility.skip_to_main'] = 'Skip to main content';


        /*** DUMMY ***/

        messageData['dummy__archive_title'] = 'No archived cards yet...';
        messageData['dummy__archive_text'] = 'Archived cards & lists will appear here.';


        /*** SNACKBARS ***/

        messageData['snackbar.button.cancel'] = 'Cancel';
        messageData['snackbar.button.close'] = 'Close';
        messageData['snackbar.button.contact_us'] = 'Contact us';
        messageData['snackbar.button.delete'] = 'Delete';
        messageData['snackbar.button.remove'] = 'Remove';
        messageData['snackbar.button.turn_off'] = 'Turn off';
        messageData['snackbar.button.ok'] = 'Ok';
        messageData['snackbar.button.reload'] = 'Reload';
        messageData['snackbar.button.start'] = 'Start';
        messageData['snackbar.button.upgrade'] = 'Upgrade';
        messageData['snackbar.button.yes'] = 'Yes';
        messageData['snackbar.button.no'] = 'No';
        messageData['snackbar.button.undo'] = 'Undo';
        messageData['snackbar.button.archive'] = 'Archive';
        messageData['snackbar.reload.text'] = 'Unfortunately something went wrong';
        messageData['snackbar.upgrade.text'] = 'Not available on your current plan';
        messageData['snackbar.archive.card.text'] = 'Card is archived';
        messageData['snackbar.archive.card.done.text'] = 'Card is archived because it was marked as done';
        messageData['snackbar.archive.card.done.text.multiple'] = 'Cards are archived because they were marked as done';
        messageData['snackbar.archive.card.done.text.epic'] = `Card with it's epic are archived because they were marked as done`;
        messageData['snackbar.archive.card.unarchive.text'] = 'Card is unarchived';
        messageData['snackbar.archive.multi.card.text'] = 'Cards are archived';
        messageData['snackbar.archive.multi.card.unarchive.text'] = 'Cards are unarchived';
        messageData['snackbar.archive.list.text'] = 'List is archived';
        messageData['snackbar.archive.board.text'] = 'Board is archived';
        messageData['snackbar.archive.board.unarchive.text'] = 'Board is unarchived';
        messageData['snackbar.archive.with_subcards.text'] = 'Archive card with its subcards?';
        messageData['snackbar.deleting.in'] = 'Deleting “{0}” in';
        messageData['snackbar.gantt.move.relations.title'] = 'Move dependency chain';
        messageData['snackbar.gantt.move.relations.text'] = 'Move card with its dependencies?';
        messageData['snackbar.free.version.limit.open.board.title'] = 'There are more than {0} cards on this board';
        messageData['snackbar.free.version.limit.open.board.text'] = 'You can view it only in the read-only mode';
        messageData['snackbar.free.version.limit.try.create.extra.card.title'] = 'You can have only {0} cards on a board';
        messageData['snackbar.free.version.limit.try.create.extra.card.text'] = 'Start trial or upgrade to have more cards';
        messageData['snackbar.template.created'] = 'Template has been created!';
        messageData['snackbar.recurring.created'] = 'Recurring card has been created!';
        messageData['snackbar.card.movied.list'] = 'Card “{0}” has been moved to “{1}” list';
        messageData['snackbar.cards.movied.list'] = 'Cards “{0}” has been moved to “{1}” list';
        messageData['snackbar.checklist.delete'] = 'Delete checklist with items?';
        messageData['snackbar.card.checklist.subcard.convert.text'] = 'Convert item to subcard?';
        messageData['snackbar.copy.with_subcards.text'] = 'Copy card with its subcards?';
        messageData['snackbar.save_template.with_subcards.text'] = 'Save with its subcards?';
        messageData['snackbar.card.custom.delete.text'] = 'Delete property “{0}” from {1} {2}?';
        messageData['snackbar.card.custom.delete.card'] = 'card';
        messageData['snackbar.card.custom.delete.cards'] = 'cards';
        messageData['snackbar.card.assigned_to_me.hide.text'] = 'Hide all cards from this board?';
        messageData['snackbar.card.assigned_to_me.show.text'] = 'Show all cards from this board?';
        messageData['snackbar.card.personal.request_file_picker'] = 'Permissions are successfully granted. Please, click Attachments again.';
        messageData['snackbar.list.moved_to'] = 'List is moved'
        messageData['snackbar.board.tag.delete.card'] = 'Tag “{0}” will be deleted from {1} card';
        messageData['snackbar.board.tag.delete.cards'] = 'Tag “{0}” will be deleted from {1} cards';
        messageData['snackbar.card.move.subcards.text'] = 'Move card with its subcards?';
        messageData['snackbar.card.move.custom_properties.text'] = 'Move card with its custom properties?';
        messageData['snackbar.card.wasdeletedon.text'] = 'Card was deleted {0}';
        messageData['snackbar.card.wasdeletedon.date'] = 'on {0}';
        messageData['snackbar.deletion_cancelled_24_hours'] = 'You logged into Kanbanchi within 24 hours after account deletion. Account deletion is canceled.';
        messageData['snackbar.deletion_cancelled_365_days_idle'] = 'You logged into Kanbanchi. Account deletion is canceled.';
        messageData['snackbar.card.color_tags.toggle.card'] = 'Remove colour tag from {0} card';
        messageData['snackbar.card.color_tags.toggle.cards'] = 'Remove colour tag  from {0} cards';
        messageData['snackbar.card.color_tags.delete.card'] = 'Colour tag will be removed from {0} card';
        messageData['snackbar.card.color_tags.delete.cards'] = 'Colour tag will be removed from {0} cards';

        messageData['snackbar.refresh.title'] = 'We’ve updated the app!';
        messageData['snackbar.refresh.text'] = 'You will be reloaded in:';
        messageData['snackbar.refresh.button'] = 'Reload now';

        messageData['snackbar.error_code.secureSharingInvalidRequest'] = 'Microsoft doesn’t allow sharing from a personal to business account';

        messageData['snackbar.ms_file_picker.shared_drive_error'] = 'The feature isn’t supported yet. Please, use drag and drop or paste attachments from clipboard.';

        messageData['snackbar.ms_file_handler.switch_account.text'] = 'У тебя ссылка для одного юзера, а ты залогинен под другим. Залогиниться под первого?';
        messageData['snackbar.ms_file_handler.switch_account.button'] = 'Switch account';

        /*** NOTIFICATIONS LANG PACK ***/
        messageData['noty.title'] = 'My notifications';
        messageData['noty.how'] = 'How it works?';
        messageData['noty.settings'] = 'Settings';
        messageData['noty.filter_all'] = 'From all boards';
        messageData['noty.filter_all.notifications'] = 'All notifications';
        messageData['noty.filter_board'] = 'Current board';
        messageData['noty.filter_starred'] = 'Starred';
        messageData['noty.group'] = 'Group by card';
        messageData['noty.ungroup'] = 'Ungroup';
        messageData['noty.filter_read'] = 'Read';
        messageData['noty.filter_unread'] = 'Unread';
        messageData['noty.loading'] = 'Loading';
        messageData['noty.card_create'] = 'Created card';
        messageData['noty.card_create_assigned'] = 'Created, assigned to';
        messageData['noty.card_create_assigned_me'] = 'Created, assigned you';
        messageData['noty.card_delete'] = 'Deleted card';
        messageData['noty.card_archive'] = 'Archived card';
        messageData['noty.card_unarchive'] = 'Unarchived card';
        messageData['noty.card_restore'] = 'Restored card';
        messageData['noty.card_move'] = 'Moved card';
        messageData['noty.card_reaction'] = 'Liked your comment';
        messageData['noty.card_comment'] = 'Commented';
        messageData['noty.card_comment_mention_my'] = 'Mentioned you';
        messageData['noty.card_comment_timetracker'] = 'Added work time';
        messageData['noty.card_update_name'] = 'Renamed card';
        messageData['noty.card_added_description'] = 'Added description';
        messageData['noty.card_changed_description'] = 'Changed description';
        messageData['noty.card_priority'] = 'Changed priority';
        messageData['noty.card_assigned'] = 'Assigned to';
        messageData['noty.card_assigned_me'] = 'Assigned you';
        messageData['noty.card_unassigned'] = 'Unassigned';
        messageData['noty.card_attachment'] = 'Added attachment';
        messageData['noty.card_attachments'] = 'Added attachments';
        messageData['noty.card_overDue'] = 'Сard is overdue';
        messageData['noty.checklist_item_overDue'] = 'Checklist item is overdue';
        messageData['noty.card_notification_before_due_date_title'] = 'Due date';
        messageData['noty.card_notification_before_due_date'] = 'before the due date';
        messageData['noty.list_change'] = 'Moved card to';
        messageData['noty.moved_to'] = '➞';
        messageData['noty.files'] = 'files';
        messageData['noty.mark.as_read'] = 'Mark as read';
        messageData['noty.mark.all_as_read'] = 'Mark all as read';
        messageData['noty.mark.delete_read'] = 'Remove all read';
        messageData['noty.mark.as_unread'] = 'Mark as unread';
        messageData['noty.close_all'] = 'Close all notifications';

        messageData['noty.system_access.action'] = 'Upgrade your plan';
        messageData['noty.system_access.body'] = 'Not available on your current plan.<br> Click to upgrade your plan ➞';
        messageData['noty.system_export.action'] = 'Please keep Kanbanchi open';
        messageData['noty.system_export.body'] = 'Data is being exported';
        messageData['noty.system_export_done.action'] = 'Data has been successfully exported';
        messageData['noty.system_export_done.body'] = 'We’ve sent you an email with the link.<br> Click to open the file ➞';
        messageData['noty.system_export-tt.action'] = 'Please keep Kanbanchi open';
        messageData['noty.system_export-tt.body'] = 'Time tracker data is being exported';
        messageData['noty.system_export-tt_done.action'] = 'Data has been successfully exported';
        messageData['noty.system_export-tt_done.body'] = 'We’ve sent you an email with the link.<br> Click to open the file ➞';

        messageData['noty.system_trial_activated.action'] = "noty.system_trial_activated.action";
        messageData['noty.system_trial_activated.body'] = "noty.system_trial_activated.body";
        messageData['noty.system_trial_3day.action'] = "noty.system_trial_3day.action";
        messageData['noty.system_trial_3day.body'] = "noty.system_trial_3day.body";
        messageData['noty.system_trial_1day.action'] = "noty.system_trial_1day.action";
        messageData['noty.system_trial_1day.body'] = "noty.system_trial_1day.body";

        messageData['noty.groups.show_more'] = "Show more";
        messageData['noty.groups.less'] = "Less";
        messageData['noty.groups.read'] = "Mark as read";
        messageData['noty.groups.star'] = "Add to starred";
        messageData['noty.groups.unstar'] = "Remove from starred";
        messageData['noty.groups.unstar.all'] = "Remove all from starred";

        /*** POLL ***/

        messageData['poll.title'] = 'Please rate ';
        messageData['poll.feedback.title.start'] = 'Why do you rate ';
        messageData['poll.feedback.title.end'] = ' this way?';
        messageData['poll.rate'] = 'Rate us on';
        messageData['poll.market_name_google'] = 'Marketplace';
        messageData['poll.market_name_ms'] = 'AppSource';
        messageData['poll.placeholder'] = 'Help us provide the perfect experience for you…';
        messageData['poll.button.later'] = 'Ask me later';
        messageData['poll.button.back'] = 'Back to rating';
        messageData['poll.button.cancel'] = 'Cancel';
        messageData['poll.button.send'] = 'Send feedback';
        messageData['poll.button.opinion'] = 'Add my own opinion';
        messageData['poll.notification.text'] = 'Thank you for your feedback!';
        messageData['poll.question.agree'] = 'Strongly agree';
        messageData['poll.question.disagree'] = 'Strongly disagree';
        messageData['poll.question.good'] = 'Very good';
        messageData['poll.question.bad'] = 'Very bad';

        /*** KANBAN POLL ***/

        messageData['kanban-poll.title'] = 'Kanbanchi';
        messageData['kanban-poll.question.requirements'] = 'Kanbanchi capabilities meet my requirements';
        messageData['kanban-poll.question.usage'] = 'Kanbanchi is easy to use';
        messageData['kanban-poll.question.acceptability'] = 'Kanbanchi is aesthetically attractive and acceptable to me';

        /*** FEATURE POLL ***/

        messageData['feature-poll.title'] = ' your experience with ';

        /*** MESSENGER POLL ***/

        messageData['messenger-poll.title'] = 'Which messengers do you use for \n' + 'communications related to projects and tasks?';
        messageData['messenger-poll.review'] = 'What messenger do you use?';
        messageData['messenger-poll.placeholder'] = 'Help us understand your unique experience...';
        messageData['messenger-poll.back'] = 'Back to choosing';
        messageData['messenger-poll.google'] = 'Google Chat';
        messageData['messenger-poll.slack'] = 'Slack';
        messageData['messenger-poll.microsoft'] = 'Microsoft Teams';
        messageData['messenger-poll.telegram'] = 'Telegram';
        messageData['messenger-poll.none'] = 'None of these';

        /*** PRICING POLL ***/

        messageData['pricing-poll.title'] = 'Choose two words that describe your\n' + 'impression of Kanbanchi in the best way';
        messageData['pricing-poll.review.title'] = 'You’ve checked out our subscription plans, but why haven’t you chosen any? Please let us know so that we can help.';
        messageData['pricing-poll.option.1'] = 'Smooth';
        messageData['pricing-poll.option.2'] = 'Usable';
        messageData['pricing-poll.option.3'] = 'Promising';
        messageData['pricing-poll.option.4'] = 'Simple';
        messageData['pricing-poll.option.5'] = 'Eccentric';
        messageData['pricing-poll.option.6'] = 'Overwhelming';
        messageData['pricing-poll.option.7'] = 'Quirky';
        messageData['pricing-poll.option.8'] = 'Confusing';
        messageData['pricing-poll.option.9'] = 'Hard to understand';

        /*** NOTIFICATIONS LANG PACK END ***/

        messageData['board_type_switcher__archive'] = 'Archive';
        messageData['board_open_dialog_tab__all'] = 'All boards';
        messageData['board_open_dialog_tab__starred'] = 'Starred';
        messageData['board_open_dialog_tab__archive'] = 'Archive';
        messageData['board_open_dialog_tab__templates'] = 'Templates';

        messageData['google-groups-sharing.title'] = 'Sharing with Google Groups';
        messageData['google-groups-sharing.bait'] = `<p>Does your company extensively use Google Groups? This option saves much time when emailing each other, organising meetings and sharing documents.</p>
<p>Kanbanchi allows its Enterprise clients to share boards with existing Google Groups as easy as you share other files in your Drive.</p>
<p>Your company G Suite Administrator can enable this feature in one click.</p>`;
        messageData['google-groups-sharing.button-enable'] = 'Enable Sharing with Google Groups';
        messageData['google-groups-sharing.for-enterprise'] = 'If your company is on the Enterprise plan, please, send the link to this page to your company’s G Suite Administrator to enable Sharing with Google Groups.';
        messageData['google-groups-sharing.copy-link'] = 'Copy link';
        messageData['google-groups-sharing.copied-link'] = 'Copied!';
        messageData['google-groups-sharing.title-sorry'] = 'Sorry…';
        messageData['google-groups-sharing.text-sorry'] = 'Enabling Sharing with Google Groups is only available to G Suite Administrator.';
        messageData['google-groups-sharing.title-done'] = 'Sharing with Google Groups has been enabled for every user in your domain';
        messageData['google-groups-sharing.text-done'] = 'Please, note that this feature will be active while you have the G Suite Administrator role. If another Administrator is appointed, they will need to grant permissions again.';
        messageData['google-groups-sharing.permissions-title'] = 'Permissions to enable Sharing with Google Groups';
        messageData['google-groups-sharing.permissions-description'] = 'Let’s start. Kanbanchi needs permission to access Google Groups within domains that you manage as the G Suite Administrator. The scope of permissions requires the ability to view the list of users within Google Groups and get this list to share boards with these users.';

        // Google spreadsheet permission dialog
        messageData['google-spreadsheet-permission-dialog.title'] = ' KANBANCHI USAGE REPORT';
        messageData['google-spreadsheet-permission-dialog.bait'] = `<p> Please, give Kanbanchi permissions to add Google Spreadsheets to your Google Drive. It's necessary for you to receive monthly Kanbanchi usage report about the subscription you are an administrator of.</p>`;
        messageData['google-spreadsheet-permission-dialog.button-enable'] = 'Give permissions';
        messageData['google-spreadsheet-permission-dialog.copy-link'] = 'Copy link';
        messageData['google-spreadsheet-permission-dialog.copied-link'] = 'Copied!';
        messageData['google-spreadsheet-permission-dialog.title-sorry'] = 'Sorry…';
        messageData['google-spreadsheet-permission-dialog.text-sorry'] = 'Sorry, something went wrong. Please, contact your Google Workspace administrator.';
        messageData['google-spreadsheet-permission-dialog.title-done'] = 'Great! Now you will get Kanbanchi usage report every month.';
        messageData['google-spreadsheet-permission-dialog.text-done'] = '';

        messageData['permissions.give'] = 'Give permissions';
        messageData['permissions.granted'] = 'Permissions granted';
        messageData['permissions.gdrive-all'] = 'See and download all your Google Drive files';
        messageData['permissions.gdrive-self'] = 'Add itself to Google Drive';
        messageData['permissions.gdrive-app'] = 'View and manage Google Drive files and folders that you have opened or created with this app';
        messageData['permissions.domain-groups'] = 'View groups on your domain';
        messageData['permissions.sheets-all'] = 'See, edit, create, and delete your spreadsheets in Google Drive';
        messageData['permissions.gcal-all'] = 'See, edit, share, and permanently delete all the calendars you can access using Google Calendar';

        messageData['dialog.blocker.title'] = 'Purchase Subscription';
        messageData['dialog.blocker.text'] = 'Your trial has ended. To continue, please, purchase a subscription. <br/><br/> Note, Kanbanchi has a free version for educational usage. <a target="_blank" href="https://www.kanbanchi.com/faqwd/difference-between-plans">Learn more</a>';
        messageData['dialog.blocker.button'] = 'Go to pricing';
        messageData['dialog.blocker-expired.title'] = 'Your subscription has expired';
        messageData['dialog.blocker-expired.text'] = 'You can renew it or get a new one.';
        messageData['dialog.blocker.questions'] = 'Questions?';
        messageData['dialog.blocker.contact_us'] = 'Contact us';
        messageData['dialog.blocker.readonly'] = 'Continue Read only';

        messageData['dialog.blocker.share.title'] = 'Make your team successful at work!';
        messageData['dialog.blocker.share.text'] = '75% of all successful cases of managing tasks start with doing it with a team. Invite your team to the Trial.';
        messageData['dialog.blocker.share.button'] = 'Share this board';

        messageData['dialog.blocker.renew.seat'] = 'Renew {0} {1} seat';
        messageData['dialog.blocker.renew.seats'] = 'Renew {0} {1} seats';
        messageData['dialog.blocker.back_kanban'] = 'Back to Kanban View';
        messageData['dialog.blocker-has-admin.text'] = 'You can contact your billing admin {0} to renew it or buy a new subscription yourself. <br>Questions? Contact us.';
        messageData['dialog.blocker-gantt.subtitle'] = 'Gantt chart';
        messageData['dialog.blocker-gantt.text'] = 'Convert your Kanbanchi Board to a Gantt Chart in one click. See how all your cards relate in time and visually plan project schedules with your team.';
        messageData['dialog.blocker-assigned-to-me.subtitle'] = 'My work';
        messageData['dialog.blocker-assigned-to-me.text'] = 'Work with all your cards on different boards in one place. See your progress with all the tasks that you have.';
        messageData['dialog.blocker-list-grouping.subtitle'] = 'List grouping';
        messageData['dialog.blocker-list-grouping.text'] = 'Instantly group cards into lists by users that have access to a board, by card colour, tags and colour labels or by priority. It will help you to look at your project from different angles and make better decisions.';
        messageData['dialog.blocker-reports.subtitle'] = 'Reports';
        messageData['dialog.blocker-reports.text'] = 'Create data reports and measure your<br>team productivity.<br>' +
            '<a target="_blank" href="https://www.kanbanchi.com/faqwd/what-are-the-requirements-for-me-to-create-data-reports-in-kanbanchi">' +
            'Read FAQ</a> for more information';
        messageData['dialog.blocker.button-upgrade'] = 'Purchase subscription';
        messageData['dialog.blocker-noactive.title'] = 'You have no active subscriptions for your account';
        messageData['dialog.blocker-noactive.text'] = 'You are still able to manage subscriptions for other users.';
        messageData['dialog.blocker.button-manage'] = 'Manage subscriptions';
        messageData['dialog.blocker-archive.subtitle'] = 'Archive';
        messageData['dialog.blocker-archive.text'] = 'Archive and view archived boards and cards, quickly filter and restore the cards you need.';
        messageData['dialog.blocker.button-back'] = 'Go back';
        messageData['dialog.blocker.button-upgrade-my-plan'] = 'Upgrade my plan';
        messageData['dialog.blocker-after-first-trial.text'] = 'Your trial has ended. To continue, please, purchase a subscription';
        messageData['dialog.blocker.button-trial-extend'] = 'Extend trial for 7 days';
        messageData['dialog.blocker-trial-extend.title'] = 'Extend trial for 7 days';
        messageData['dialog.blocker-trial-extend.label'] = 'Why have you decided to extend the trial instead of purchasing a subscription?';
        messageData['dialog.blocker-trial-extend.button'] = 'Extend trial';

        messageData['dialog.blocker-snooze.title'] = 'This feature is not included in your plan';
        messageData['dialog.blocker-snooze.shared-drives.title'] = 'Shared drives are not included in your plan';
        messageData['dialog.blocker-snooze.upgrade.text'] = 'Upgrade or snooze this screen to try the {0} plan features for 30 minutes a day.';
        messageData['dialog.blocker-snooze.upgrade.text.legacy'] = 'Upgrade to try the {0} plan features.';
        messageData['dialog.blocker-snooze.upgrade.text.legacy.try'] = 'Upgrade or snooze this screen to try the {0} plan features.';
        messageData['dialog.blocker-snooze.used.text'] = 'You’ve used all 30 minutes per day to try {0} features.<br>You can upgrade or access them again tomorrow!';
        messageData['dialog.blocker-snooze.button-try'] = 'Try {0} plan';
        messageData['dialog.blocker-snooze.button-upgrade'] = 'Upgrade';
        messageData['dialog.blocker-snooze.button-back'] = 'Back to Kanbanchi';
        messageData['dialog.blocker-snooze.shared-drives.button-back'] = 'Continue with read-only';
        messageData['dialog.blocker-snooze.plan'] = 'Professional';
        messageData['dialog.blocker-snooze.shared-drives.plan'] = 'Enterprise';

        messageData['dialog.blocker-snooze.basic.button'] = 'Upgrade';
        messageData['dialog.blocker-snooze.basic.custom-filter.title'] = 'My Work is limited for your plan';
        messageData['dialog.blocker-snooze.basic.custom-filter.text'] = 'Your plan allows you to see only cards assigned to you.\n' + 'Upgrade to use all other filters and unleash the full potential of My Work.';
        messageData['dialog.blocker-snooze.basic.saved-filter.title'] = 'Saved Filters are not included in your plan';
        messageData['dialog.blocker-snooze.basic.saved-filter.text'] = 'Upgrade to unleash the full potential of filters.';
        messageData['dialog.blocker-snooze.basic.saved-filter.title.alternative'] = 'My Work: Saved Filters are not included\n' + 'in your plan';
        messageData['dialog.blocker-snooze.basic.saved-filter.text.alternative'] = 'Upgrade to unleash the full potential of My Work.';
        messageData['dialog.blocker-snooze.basic.task-grouping.title'] = 'My Work: Grouping is not included in your plan';
        messageData['dialog.blocker-snooze.basic.task-grouping.text'] = 'Upgrade to unleash the full potential of My Work.';
        messageData['dialog.blocker-snooze.basic.gantt.title'] = 'Gantt Chart is not included in your plan';
        messageData['dialog.blocker-snooze.basic.gantt.text'] = 'See how all your tasks relate in time and visually plan project schedules with your team.';
        messageData['dialog.blocker-snooze.basic.swimlanes.title'] = 'Swimlanes are not included in your plan';
        messageData['dialog.blocker-snooze.basic.swimlanes.text'] = 'Add a new layer of structure to your Kanban board. Keep track of subprocesses and classify tasks, projects, or teams with the help of Swimlanes.';
        messageData['dialog.blocker-snooze.basic.team.title'] = 'List grouping is not included in your plan';
        messageData['dialog.blocker-snooze.basic.team.text'] = 'Manage resources when leading any kind of project with an integrated view for resource management.';
        messageData['dialog.blocker-snooze.basic.archive.title'] = 'Archive is not included in your plan';
        messageData['dialog.blocker-snooze.basic.archive.text'] = 'Prevent deletion of important information by archiving cards, lists, and boards and be able to restore them easily.';
        messageData['dialog.blocker-snooze.basic.list.title'] = 'Task list is not included in your plan';
        messageData['dialog.blocker-snooze.basic.list.text'] = 'Enjoy the sortable list of tasks grouped in your preferred order.';
        messageData['dialog.blocker-snooze.basic.activity.title'] = 'Board activity is not included in your plan';
        messageData['dialog.blocker-snooze.basic.activity.text'] = 'Quickly review the changes made on boards, identify major twists.';
        messageData['dialog.blocker-snooze.basic.activity.title.alternative'] = 'Card activity is not included in your plan';
        messageData['dialog.blocker-snooze.basic.activity.text.alternative'] = 'Quickly review the changes made on tasks, identify major twists.';
        messageData['dialog.blocker-snooze.basic.branding.title'] = 'Branding is not included in your plan';
        messageData['dialog.blocker-snooze.basic.branding.text'] = 'Add your corporate style to your Kanbanchi boards.';
        messageData['dialog.blocker-snooze.basic.backup.title'] = '3';
        messageData['dialog.blocker-snooze.basic.backup.text'] = 'Add an extra layer of security and control to your boards by setting up backups. Restore any version in one click.';
        messageData['dialog.blocker-snooze.basic.export.title'] = 'Export is not included in your plan';
        messageData['dialog.blocker-snooze.basic.export.text'] = 'Export data to use in other software or for reporting.';
        messageData['dialog.blocker-snooze.basic.custom.title'] = 'Custom properties are not included in your plan';
        messageData['dialog.blocker-snooze.basic.custom.text'] = 'Customize your experience and add more information about your tasks. Keep everything in one place.';
        messageData['dialog.blocker-snooze.basic.ageing.title'] = 'Card aging is not included in your plan';
        messageData['dialog.blocker-snooze.basic.ageing.text'] = 'Spot when your tasks aren’t moving or getting done, so you can fix any slowdowns in your workflow.';
        messageData['dialog.blocker-snooze.basic.number.title'] = 'Сard prefixes are not included in your plan';
        messageData['dialog.blocker-snooze.basic.number.text'] = 'Short identifiers will help you to refer to tasks when communicating with colleagues.';
        messageData['dialog.blocker-snooze.basic.roles.title'] = 'Roles are not included in your plan';
        messageData['dialog.blocker-snooze.basic.roles.text'] = 'Easily distinguish responsible people, identify assignees you need to communicate with.';
        messageData['dialog.blocker-snooze.basic.status.title'] = 'Card progress automation is not included in your plan';
        messageData['dialog.blocker-snooze.basic.status.text'] = 'Streamline the process of updating boards: easily spot tasks in progress and save time when changing card status or closing tasks';
        messageData['dialog.blocker-snooze.basic.sorting.title'] = 'Card sorting for lists is not included in your plan';
        messageData['dialog.blocker-snooze.basic.sorting.text'] = 'Add more visibility to the most important tasks in particular lists.';
        messageData['dialog.blocker-snooze.basic.reports.title'] = 'Data reports are not included in your plan';
        messageData['dialog.blocker-snooze.basic.reports.text'] = 'Always be aware of your team’s progress and quickly adjust the workflow according to the report’s data.';
        messageData['dialog.blocker-snooze.basic.shared.title'] = 'Creating boards on Shared Drives is not included in your plan';
        messageData['dialog.blocker-snooze.basic.shared.text'] = 'Save time sharing boards with colleagues by creating them in Shared Drives. Boards will be automatically shared with all Drive members.';
        messageData['dialog.blocker-snooze.basic.shared.text.browse-boards'] = 'Save time sharing boards with colleagues by creating them in Shared Drives. Boards will be automatically shared with all Drive members.';
        messageData['dialog.blocker-snooze.basic.template.title'] = 'Card templates are not included in your plan';
        messageData['dialog.blocker-snooze.basic.template.text'] = 'Save time when adding repetitive tasks to your projects.';
        messageData['dialog.blocker-snooze.basic.board-template.title'] = 'Board templates are not included in your plan';
        messageData['dialog.blocker-snooze.basic.board-template.text'] = 'Save time when creating similar projects. Use templates from the library for typical projects.';
        messageData['dialog.blocker-snooze.basic.recurring.title'] = 'Recurring cards are not included in your plan';
        messageData['dialog.blocker-snooze.basic.recurring.text'] = 'Automate workflow with the repetitive tasks.';
        messageData['dialog.blocker-snooze.basic.dependencies.title'] = 'Dependencies are not included in your plan';
        messageData['dialog.blocker-snooze.basic.dependencies.text'] = 'Help everyone in a team know the sequence of tasks and do them in proper order. Dependencies are part of the Gantt chart.';
        messageData['dialog.blocker-snooze.basic.time.title'] = 'Time tracker is not included in your plan';
        messageData['dialog.blocker-snooze.basic.time.text'] = 'Track time spent on tasks in Kanbanchi, and always know how your team is doing.';
        messageData['dialog.blocker-snooze.basic.milestone.title'] = 'Milestones are not included in your plan';
        messageData['dialog.blocker-snooze.basic.milestone.text'] = 'Highlight key points of your project on the Gantt chart by turning tasks into visual milestones.';
        messageData['dialog.blocker-snooze.basic.notifications.title'] = 'Notifications before the due date are not included\n' + 'in your plan';
        messageData['dialog.blocker-snooze.basic.notifications.text'] = 'Never miss deadlines and fulfill all tasks on time; Kanbanchi will remind you of due dates.';
        messageData['dialog.blocker-snooze.basic.color-tag.title'] = 'Colour labels limit for your plan';
        messageData['dialog.blocker-snooze.basic.color-tag.text'] = 'Your plan includes <strong>{0} colour labels</strong> per card.\n' + 'Upgrade to have an unlimited number of colour labels.';
        messageData['dialog.blocker-snooze.basic.color-tag.title.alternative'] = 'Your plan includes <strong>{0} colour labels</strong> per board.\n' + 'Upgrade to have an unlimited number of colour labels.';
        messageData['dialog.blocker-snooze.basic.tag.title'] = 'Tags limit for your plan';
        messageData['dialog.blocker-snooze.basic.tag.text'] = 'Your plan includes <strong>{0} tags</strong> per board.\n' + 'Upgrade to have an unlimited number of tags.';
        messageData['dialog.blocker-snooze.basic.subcard.title'] = 'Subcards are not included in your plan';
        messageData['dialog.blocker-snooze.basic.subcard.text'] = 'Split your complex tasks into digestable small sub tasks.';
        messageData['dialog.blocker-snooze.basic.color.title'] = 'Card colours limit for your plan';
        messageData['dialog.blocker-snooze.basic.color.title.list'] = 'List colours limit for your plan';
        messageData['dialog.blocker-snooze.basic.color.text'] = 'Your plan includes <strong>{0} card colours</strong> per board.\n' + 'Upgrade to have an unlimited number of card colours.';
        messageData['dialog.blocker-snooze.basic.color.text.list'] = 'Your plan includes <strong>{0} list colours</strong> per board.\n' + 'Upgrade to have an unlimited number of list colours.';
        messageData['dialog.blocker-snooze.basic.checklist.title'] = 'Checklists limit for your plan';
        messageData['dialog.blocker-snooze.basic.checklist.text'] = 'Your plan includes <strong>{0} checklist</strong> per card.\n' + 'Upgrade to have an unlimited number of checklists.';
        messageData['dialog.blocker-snooze.basic.checklist-item.title'] = 'Checklists limit for your plan';
        messageData['dialog.blocker-snooze.basic.checklist-item.text'] = 'Your plan includes <strong>{0} items</strong> per checklist.\n' + 'Upgrade to have an unlimited number of checklist items.';

        messageData['dialog.blocker-snooze.pro-trial.button'] = 'Try PRO for free for 30 minutes per day';
        messageData['dialog.blocker-snooze.pro-trial.end.button'] = 'Upgrade to PRO';
        messageData['dialog.blocker-snooze.pro-trial.end.subtitle'] = 'Your total time of using PRO features is ';
        messageData['dialog.blocker-snooze.pro-trial.end.title.start'] = '30-minutes daily limit for ';
        messageData['dialog.blocker-snooze.pro-trial.end.title.end'] = 'has been reached';
        messageData['dialog.blocker-snooze.pro-trial.end.used.title'] = 'You have used';
        messageData['dialog.blocker-snooze.pro-trial.end.unused.title'] = 'You have not used yet';
        messageData['dialog.blocker-snooze.pro-trial.end.unavailable.title'] = 'Will be unlocked after your upgrade';
        messageData['dialog.blocker-snooze.pro-trial.end.gantt.title'] = 'Gantt chart';
        messageData['dialog.blocker-snooze.pro-trial.end.dependencies.title'] = 'Task dependencies';
        messageData['dialog.blocker-snooze.pro-trial.end.notifications.title'] = 'Notifications before due date';
        messageData['dialog.blocker-snooze.pro-trial.end.archive.title'] = 'Archive';
        messageData['dialog.blocker-snooze.pro-trial.end.reports.title'] = 'Data Reports';
        messageData['dialog.blocker-snooze.pro-trial.end.team.title'] = 'List grouping';
        messageData['dialog.blocker-snooze.pro-trial.end.swimlanes.title'] = 'Swimlanes';
        messageData['dialog.blocker-snooze.pro-trial.end.time-tracker.title'] = 'Time tracker with data export to Google Sheets';
        messageData['dialog.blocker-snooze.pro-trial.end.logo.title'] = 'Company logo';
        messageData['dialog.blocker-snooze.pro-trial.end.custom-properties.title'] = 'Custom properties';
        messageData['dialog.blocker-snooze.pro-trial.end.backup.title'] = 'Auto backup';
        messageData['dialog.blocker-snooze.pro-trial.end.my-work-grouping.title'] = 'My Work: Task grouping';
        messageData['dialog.blocker-snooze.pro-trial.end.my-work-filters.title'] = 'My Work: Custom filters';
        messageData['dialog.blocker-snooze.pro-trial.end.back'] = 'Thanks, not today...';
        messageData['dialog.blocker-snooze.pro-trial.title.extra'] = ' is a ';
        messageData['dialog.blocker-snooze.pro-trial.title.my-work-grouping'] = 'My Work: Task grouping';
        messageData['dialog.blocker-snooze.pro-trial.text.my-work-grouping'] = 'Split your tasks into observable groups to get a better understanding of the scope of work that you have.';
        messageData['dialog.blocker-snooze.pro-trial.text.my-work-grouping.tooltip'] = 'Split your tasks into small observable groups.';
        messageData['dialog.blocker-snooze.pro-trial.title.my-work-filters'] = 'My Work: Custom filters';
        messageData['dialog.blocker-snooze.pro-trial.text.my-work-filters'] = 'Create custom filters and combine them to see the particular tasks that you need to do now or in the future.';
        messageData['dialog.blocker-snooze.pro-trial.text.my-work-filters.tooltip'] = 'Take a look at the specific tasks relevant for you at this moment.';
        messageData['dialog.blocker-snooze.pro-trial.title.gantt'] = 'Gantt chart';
        messageData['dialog.blocker-snooze.pro-trial.text.gantt'] = 'See how all your tasks relate in time and visually plan project schedules with your team.';
        messageData['dialog.blocker-snooze.pro-trial.title.archive'] = 'Archive';
        messageData['dialog.blocker-snooze.pro-trial.text.archive'] = 'Prevent deletion of important information by archiving cards and be able to restore them easily.';
        messageData['dialog.blocker-snooze.pro-trial.title.reports'] = 'Reports';
        messageData['dialog.blocker-snooze.pro-trial.text.reports'] = `Always be aware of your team\'s progress and quickly adjust the workflow according to the report's data.`;
        messageData['dialog.blocker-snooze.pro-trial.title.dependencies'] = 'Task dependencies';
        messageData['dialog.blocker-snooze.pro-trial.text.dependencies'] = 'Help everyone in a team know the sequence of tasks and do them in proper order.';
        messageData['dialog.blocker-snooze.pro-trial.title.backup'] = 'Auto backup';
        messageData['dialog.blocker-snooze.pro-trial.text.backup'] = 'Add an extra layer of security and control to your boards by setting up automatic daily backups. Restore any version in one click.';
        messageData['dialog.blocker-snooze.pro-trial.title.custom-properties'] = 'Custom properties';
        messageData['dialog.blocker-snooze.pro-trial.text.custom-properties'] = 'Customize your experience and add more information about your tasks. Keep everything in one place.';
        messageData['dialog.blocker-snooze.pro-trial.title.time-tracker'] = 'Time tracker';
        messageData['dialog.blocker-snooze.pro-trial.text.time-tracker'] = 'Track time spent on tasks in Kanbanchi, and always know how your team is doing.';
        messageData['dialog.blocker-snooze.pro-trial.title.time-tracker-export'] = 'Time tracker data export';
        messageData['dialog.blocker-snooze.pro-trial.text.time-tracker-export'] = 'Create your time report to track your team’s performance.';
        messageData['dialog.blocker-snooze.pro-trial.title.notifications'] = 'Notifications before the due date';
        messageData['dialog.blocker-snooze.pro-trial.text.notifications'] = 'Never miss deadlines and fulfil all tasks on time; Kanbanchi will remind you of due dates.';
        messageData['dialog.blocker-snooze.pro-trial.title.swimlanes'] = 'Swimlanes';
        messageData['dialog.blocker-snooze.pro-trial.text.swimlanes'] = 'Add a new layer of structure to your Kanban board. Keep track of subprocesses and classify tasks, projects, or teams with the help of Swimlanes.';
        messageData['dialog.blocker-snooze.pro-trial.title.logo'] = 'Custom Logo';
        messageData['dialog.blocker-snooze.pro-trial.text.logo'] = 'Add your corporate style to your Kanbanchi boards.';
        messageData['dialog.blocker-snooze.pro-trial.title.team'] = 'List grouping';
        messageData['dialog.blocker-snooze.pro-trial.text.team'] = 'Manage resources when leading any kind of project with an integrated view for resource management.';

        messageData['pro-trial.upgrade-button.text'] = `<span class="upgrade__text-inner">of <span class="upgrade__text-inner--blue">PRO</span> features left</span>`;

        messageData['error.record.not_found'] = 'This record is not available';
        messageData['error.dashboard.not_found'] = 'Some of the requested boards are not available. If someone gave you this link, they may need to share them with you.';
        messageData['error.button.reload_page'] = 'Reload page';
        messageData['error.button.relogin'] = 'Re-login';
        messageData['error.button.reinstall'] = 'How re-install';

        messageData['error.button.reinstall.message'] = 'Could not login to your Google Drive. To be able to login, please, reinstall the Kanbanchi app. All your data will be saved. ';
        messageDataMS['error.button.reinstall.message'] = 'Could not login to your OneDrive. To be able to login, please, reinstall the Kanbanchi app. All your data will be saved. ';
        messageData['error.button.reinstall.link'] = 'https://www.kanbanchi.com/faqwd/reinstallation';
        messageDataMS['error.button.reinstall.link'] = 'https://www.kanbanchi.com/faqwd/reinstallation'; // todo microsoft

        messageData['error.button.feedback'] = 'Feedback';
        messageData['error.ajax.prefix'] = 'We are unable to fulfil your recent request due to the following reason:<br>';
        messageData['error.authorisation.unavailable'] = 'Please login to Google to have File picker, Board sharing dialogue and live updates working.';

        messageData['login.required'] = 'Please log in to your Google account.';
        messageDataMS['login.required'] = 'Please log in to your Microsoft account.';

        messageData['registration.step2'] = 'One more permission is needed for Kanbanchi. Please, review.';
        messageData['registration.step2.review'] = 'Review';
        messageData['new.scope.review'] = 'To use this functionality please review a permission request for Kanbanchi.';
        messageData['error.authorisation.unavailable.signin'] = 'Sign in';
        messageData['error.notifications_before_due_date.less.5.minutes'] = 'Notification can not be sent in less than 5 minutes';
        messageData['error.notifications_before_due_date.in.past'] = 'Notification can not be set up in the past';
        messageData['error.notifications_before_due_date.exist'] = 'Such notification already exists';
        messageData['tooltip.notifications_before_due_date.list_item.button.remove.text'] = 'Remove';
        messageData['button.notifications_before_due_date.add.text'] = 'Add notification';
        messageData['button.notifications_before_due_date.save.text'] = 'Save';
        messageData['button.notifications_before_due_date.cancel.text'] = 'Cancel';
        messageData['label.notifications_before_due_date.text'] = 'Notifications before due date';
        messageData['error.signIn.googleBlocked'] = 'Google is blocked for your system';
        messageData['error.signIn.internal'] = 'Temporary server error';
        messageData['error.card.not_filled'] = 'Required properties can’t be empty';
        messageData['error.list.delete.automation'] = 'You can not delete list used for board automation, change status mapping in {0} first';
        messageData['error.list.archive.automation'] = 'You can not archive list used for board automation, change status mapping in {0} first';
        messageData['error.list.move.automation'] = 'You can not move list used for board automation to another board, change status mapping in {0} first';
        messageData['error.list.automation.settings'] = 'board settings';

        messageData['cardNumber.title'] = 'Card prefixes';
        messageData['cardNumber.prefix'] = 'Prefix';
        messageData['cardNumber.startValue'] = 'Start number';
        messageData['cardNumber.description'] = 'Card prefixes allow unique identification for your cards. Set your prefix and start number.';
        messageData['error.cardNumber.maxValue'] = 'For such prefix number can not be less than ';
        messageData['error.cardNumber.notNumber'] = 'Only positive numbers are allowed';
        messageData['error.cardNumber.tooLong'] = 'Value is too long, should be less than 10 digits';
        messageData['error.cardNumber.prefix'] = 'Prefix can’t be empty';
        messageData['cardNumber.card_preview.name'] = 'Card title';
        messageData['cardNumber.modal.title'] = 'Adding “{0}-” card prefix';
        messageData['cardNumber.modal.title.loading'] = 'Adding card prefixes...';
        messageData['cardNumber.modal.text'] = 'How do you want cards prefixes to be added?';
        messageData['cardNumber.modal.text.loading'] = 'Please, wait while we are adding prefixes,\n' + 'it may take a few minutes';
        messageData['cardNumber.modal.both'] = 'Existing cards and future additions';
        messageData['cardNumber.modal.new'] = 'Future additions only';
        messageData['cardNumber.modal.current'] = 'Existing cards only';
        messageData['cardNumber.modal.delete.title'] = 'Turn Card prefixes off?';
        messageData['cardNumber.modal.delete.text'] = 'This action is irreversible with your plan’s limitations. Upgrade to be able to turn it back on and use card prefixes feature for all boards.';
        messageData['cardNumber.modal.remove.title'] = 'Card prefixes won’t be added to new cards';
        messageData['cardNumber.modal.remove.title.loading'] = 'Removing card prefixes...';
        messageData['cardNumber.modal.remove.text'] = 'Do you want to remove prefixes from the existing cards?';
        messageData['cardNumber.modal.remove.text.loading'] = 'Please, wait while we are removing your prefixes,\n' +
            'it may take a few minutes';
        messageData['cardNumber.modal.remove.snackbar'] = 'Card prefixes have been removed from all existing\n' +
            'cards and won’t be added to new cards';
        messageData['cardNumber.modal.snackbar'] = 'Card prefixes have been added to all existing cards\n' +
            'and will be automatically added to new cards';
        messageData['cardNumber.modal.edited.snackbar'] = '“{0}-” card prefix has beed added to all existing cards';

        messageData['showOnGantt.title'] = 'All cards on Gantt chart';
        messageData['showOnGantt.button'] = 'All cards on Gantt';
        messageData['showOnGantt.description'] = 'Turn on to add new cards to the Gantt chart automatically. Collaborators will be able to remove or add any card back later from card panel.';

        messageData['popup.dashboard.access_revoked'] = 'You have been revoked access permission to the board';
        messageData['popup.dashboard.deleted'] = ' board has been deleted by ';
        messageData['popup.dashboard.content_changed'] = ' has updated the board';
        messageData['popup.dashboard.copying'] = 'Board is being copied';
        messageData['popup.dashboard.restoring'] = 'Backup is being restored';
        messageData['popup.dashboard.import-trello'] = 'We are creating a new Kanbanchi board using your imported data';
        messageData['popup.dashboard.welcome-board-copy'] = "We are creating the tips & tricks board for you";
        messageData['popup.dashboard.welcome-board-copy.header'] = 'Creation in progress';
        messageData['popup.dashboard.creating-board'] = "We are creating a new board for you.";
        messageData['popup.dashboard.creating-board.header'] = 'Creation in progress';

        messageData['popup.dashboard.copying.header'] = 'Copying to my Google Drive';
        messageDataMS['popup.dashboard.copying.header'] = 'Copying to my OneDrive';
        messageData['popup.dashboard.restoring.header'] = 'Restoring to my Google Drive';
        messageData['popup.dashboard.restoring.oneDrive.header'] = 'Restoring to OneDrive';

        messageData['popup.dashboard.restoring.shared-users.header'] = 'Board sharing';
        messageData['popup.dashboard.restoring.shared-users.text'] = 'Board is shared with {0}/{1} users';

        messageData['popup.dashboard.import-trello.header'] = 'Import in progress';
        messageData['popup.dashboard.no_slots_for_copied'] = 'Sorry, you can’t open more than 10 boards in one browser tab. Please choose how you’d like your new board to be opened.';
        messageData['popup.dashboard.no_slots_for_open'] = 'Sorry, you can’t open more than 10 boards in one browser tab. Please choose how you’d like your new board to be opened.';
        messageData['popup.dashboard.open_copied_in_new_tab'] = 'Open in another tab';
        messageData['popup.dashboard.open_in_new_tab'] = 'Open in another tab';
        messageData['popup.dashboard.reuse_original_tab'] = 'Replace the original board';
        messageData['popup.dashboard.limit.exceeded'] = 'Sorry, you can’t open more than 10 boards in one browser page.<br>Please unselect some boards and then open them in a new browser tab.';
        messageData['popup.dashboard.limit.exceeded.header'] = 'How to open your new board?';
        messageData['popup.dashboard.limit.exceeded.reopen'] = 'Sorry, you can’t open more than 10 boards in one page. Only first 10 boards are opened on this page.';
        messageData['popup.dashboard.limit.exceeded.button.other_tab'] = 'Open other boards in another tab';
        messageData['popup.dashboard.limit.exceeded.button.cancel'] = 'Cancel';
        messageData['popup.dashboard.logo.too_large'] = 'Sorry, only files up to 500Kb are allowed to be uploaded';
        messageData['popup.dashboard.bg.too_large'] = 'Sorry, only files up to 3Mb are allowed to be uploaded';
        messageData['popup.dashboard.image_mime_mismatch'] = 'Sorry, only images are allowed to be uploaded';
        messageData['popup.dashboard.deleted.header'] = 'Deleted Board';
        messageData['popup.dashboard.deleted.button.restore'] = 'Restore';
        messageData['popup.dashboard.deleted.button.close'] = 'Close';
        messageData['popup.dashboard.deleted'] = ' is currently in the trash. To access this board you need to restore it. Otherwise, it will be deleted permanently.<p>Restore this board?</p>';
        messageData['popup.dashboard.switching_to_librarty_theme'] = 'Switching to the library theme will remove your custom theme. Would you like to proceed?';
        messageData['popup.dashboard.switching_to_librarty_theme.button.cancel'] = 'Cancel';
        messageData['popup.dashboard.switching_to_librarty_theme.button.continue'] = 'Continue';
        messageData['popup.dashboard.template.copying'] = 'We are saving your board as a template. It will be available in boards menu.';
        messageData['popup.dashboard.template.copying.header'] = 'Template saving';

        messageData['popup.dashboard.restore-users.title'] = 'Share Board';
        messageData['popup.dashboard.restore-users.text'] = 'Share board with the same users from backup';
        messageData['popup.dashboard.restore-users.button.share'] = 'Share';
        messageData['popup.dashboard.restore-users.button.close'] = 'Close';
        messageData['popup.dashboard.restore-users.button.ok'] = 'OK';
        messageData['popup.dashboard.restore-users.error.title'] = 'Board wasn’t shared with these users:';
        messageData['popup.dashboard.restore-users.error.subtitle'] = 'Try to share with them manually or check with them if they still use the same emails to log in to Kanbanchi';

        messageData['popup.card.drive_doc.already_exists'] = 'The following file is already attached to the card: ';
        messageData['popup.card.drive_doc.too_large'] = 'Sorry, only files up to 10Mb are allowed to be uploaded';
        messageData['popup.error.header'] = 'Error';
        messageData['popup.warning.header'] = 'Warning';
        messageData['popup.info.header'] = 'Information';
        messageData['popup.progress.header'] = 'Loading...';
        messageData['popup.question.header'] = '?';
        messageData['popup.message.header'] = 'Info';
        messageData['popup.block.drag_to_another_board'] = 'To move a card to a different board, use Move option in Card Details';
        messageData['popup.list.copying'] = 'List is being copied';
        messageData['popup.list.copying.header'] = 'Copying list';
        messageData['popup.list.archiving'] = 'List is being archived';
        messageData['popup.list.archiving.header'] = 'Archiving list';
        messageData['popup.list.unarchiving'] = 'Cards are being unarchived';
        messageData['popup.list.unarchiving.header'] = 'Unarchiving cards';
        messageData['popup.list.delete.not_empty'] = ' list is not empty. Do you want to delete it along with all the cards it contains?';
        messageData['popup.list.delete.not_empty.delete_with_cards'] = 'Delete the list and cards';
        messageData['popup.list.delete.not_empty.cancel'] = 'I’ll sort cards first';
        messageData['popup.unsubscribe.dashboard.success'] = 'Your are now unsubscribed from this board updates.<br>You can enable email notifications again in board settings.';
        messageData['popup.unsubscribe.notification.success'] = 'You’ve been successfully unsubscribed and will no longer receive these email communications. <br> Your Kanbanchi Team.';
        messageData['popup.payment.verified'] = 'Thank you! Your payment is being verified!';
        messageData['popup.payment.cancelled'] = 'Your payment has not been completed. Please try once again!';
        messageData['popup.payment.promo_code.success'] = 'Thank you for using Kanbanchi Promo Code.<br>Kanbanchi paid feature has been activated for your account.';
        messageData['popup.payment.paypal.confirm'] = 'Confirm and Pay';
        messageData['tab.account.settings.features'] = 'Plans';
        messageData['tab.account.settings.subscriptions'] = 'Payment';
        messageData['tab.account.settings.transactions'] = 'Transaction History';
        messageData['tab.account.settings.information'] = 'Information';

        messageData['tab.dashboard.details'] = '<span class="hide__iPlus">Board</span> Details';
        messageData['tab.dashboard.design'] = 'Branding';
        messageData['tab.dashboard.activity'] = 'Activity';
        messageData['tab.dashboard.backups'] = 'Backups';
        messageData['tab.help-panel.help'] = 'Help';
        messageData['tab.help-panel.updates'] = 'Updates';
        messageData['tab.card.details'] = 'Card Details';
        messageDaRu['tab.card.details'] = 'Детали карточки';
        messageDJLR['tab.card.details'] = 'Issue Details';
        messageData['tab.card.timing'] = 'Timing';
        messageData['tab.card.activity'] = 'Activity';

        messageData['list_header_fold_list'] = 'Fold list';
        messageData['list_header_unfold_list'] = 'Unfold list';
        messageData['list_header_create_card'] = 'Create a new card';
        messageData['list_header_cancel'] = 'Cancel';

        messageData['archive.card.last_change'] = 'Last change';

        messageData['kanban.list_header_sort_cards'] = 'Sort cards';
        messageData['kanban.list_header_action_tooltip'] = 'List actions';
        messageData['kanban.add_new_list'] = 'Add new list';
        messageData['kanban.attachments_tooltip'] = 'Attachments';
        messageData['kanban.checklists_tooltip'] = 'Checklist items';
        messageData['kanban.comments_tooltip'] = 'Comments';
        messageData['kanban.subcards_tooltip'] = 'Subcards';
        messageData['kanban.description_tooltip'] = 'Card has Description';
        messageData['kanban.list.description_tooltip'] = 'List has Description';
        messageData['kanban.drive_doc_download_tooltip'] = 'Download';
        messageData['kanban.list_card_templates_tooltip'] = 'Create card from template';
        messageData['kanban.list_header.done'] = 'Status mapping: list for done tasks';
        messageData['kanban.list_header.not_done'] = 'Status mapping: done list contains not done cards';

        messageData['kanban.card_action_tooltip'] = 'Card actions';
        messageData['kanban.card.copy_action'] = 'Make a copy';
        messageData['kanban.card.get_link_action'] = 'Get a link to a card';
        messageData['kanban.card.print_action'] = 'Print';
        messageData['kanban.card.archive_action'] = 'Archive';
        messageData['kanban.card.unarchive_action'] = 'Unarchive';
        messageData['kanban.card.delete_action'] = 'Delete';
        messageData['kanban.card.done_action'] = 'Mark as done';
        messageData['kanban.card.more_action'] = 'More actions';
        messageData['kanban.card.edit_action'] = 'Edit card';
        messageData['kanban.card.rename_action'] = 'Rename';
        messageData['kanban.card.assign_action'] = 'Assign to';
        messageData['kanban.card.dates_action'] = 'Set dates';
        messageData['kanban.card.color_label_action'] = 'Add colour labels';
        messageData['kanban.card.priority_action'] = 'Set priority';
        messageData['kanban.card.overall_progress'] = 'Overall progress';
        messageData['kanban.card.epic-title'] = 'Epic card has {0} Subcard{1}';
        messageData['kanban.card.subcard'] = 'Subcard of: ';
        messageData['kanban.card.card_aging.activity'] = ' without changes';
        messageData['kanban.card.card_aging.list'] = ' in the list';
        messageData['kanban.card.card_priority.tooltip'] = 'Priority: ';
        messageData['kanban.card.view_on_board_action'] = 'View on board';
        messageData['kanban.card.get_link_to_card_action'] = 'Get link to card';
        messageData['kanban.default.card.name'] = 'Untitled Card';

        messageData['pro_feature_indicator.title'] = 'Pro';

        messageData['kanban.list.color_action'] = 'Change colour';
        messageData['kanban.list.edit_action'] = 'Edit';
        messageData['kanban.list.fold_action'] = 'Fold';
        messageData['kanban.list.fold_other_lists_action'] = 'Fold all other lists';
        messageData['kanban.list.unarchive_action'] = 'Unarchive list';
        messageData['kanban.list.archive_action'] = 'Archive list';
        messageData['kanban.list.move_to_action'] = 'Move to';
        messageData['kanban.list.select_all_action'] = 'Select all cards';
        messageData['kanban.list.get_link_to_list_action'] = 'Get link to list';
        messageData['kanban.list.copy_action'] = 'Copy';
        messageData['kanban.list.copy_with_cards_action'] = 'Copy with all cards';
        messageData['kanban.list.print_action'] = 'Print';
        messageData['kanban.list.delete_action'] = 'Delete list';
        messageData['kanban.list.delete_with_cards_action'] = 'Delete list with all cards';
        messageData['kanban.list.create_right'] = 'Create list right';

        messageData['kanban.create_card_template_descriptions'] = 'You haven’t yet created card templates';
        messageData['kanban.create_card_template_button'] = 'Create card template';

        messageData['kanban.start_date_tooltip'] = 'Start date: ';
        messageData['kanban.due_date_tooltip'] = 'Due date: ';
        messageData['kanban.estimate_tooltip'] = 'Estimate: ';

        messageData['card_form_card_author'] = 'Author:';
        messageData['card_form_card_author_template_tooltip'] = 'Created from template ';
        messageData['card_form_icon_epic'] = 'This card is an Epic';
        messageData['card_form_icon_archived'] = 'Archived';
        messageData['card_form_icon_archived_epic'] = 'Archived epic';
        messageData['card_form_select_priority'] = 'Priority';
        messageData['card_form_select_color'] = 'Card colour';
        messageData['card_form_color_board'] = 'Used on this board';
        messageData['card_form_color_all'] = 'More colours';
        messageData['card_form_color_modal_title.card'] = 'Remove this card colour?';
        messageData['card_form_color_modal_title.list'] = 'Remove this list colour?';
        messageData['card_form_color_modal_text'] = 'This action is irreversible with your plan’s limit of <strong>{0} card colours per board</strong>. Upgrade to have an unlimited number of card colours.';
        messageData['card_form_add_color_tags'] = 'Add colour labels';
        messageData['card_form_color_tags_all'] = 'More colour labels';
        messageData['card_form_color_tags_hint'] = 'New colours have been added!</br>Check it out here.';
        messageData['card_form_color_tags_board'] = 'Used on this board';
        messageData['card_form_color_tags_edit_tooltip'] = 'Double click to edit colour label';
        messageData['card_form_color_tags_placeholder'] = 'Search or create a new label';
        messageData['card_form_color_tags_placeholder_edit'] = 'New colour label name';
        messageData['card_form_color_tags_button_new'] = 'Create colour label ↵';
        messageData['card_form_color_tags_button_new.short'] = 'Create ↵';
        messageData['card_form_color_tags_button_edit'] = 'Save colour label ↵';
        messageData['card_form_color_tags_button_edit.short'] = 'Save ↵';
        messageData['card_form_edit_color_tags'] = 'Manage colour labels';
        messageData['card_form_edit_color_error'] = 'Colour label with the same name and color is already exist';
        messageData['card_form_list_label'] = 'Card in list';
        messageData['card_form_list_move_to_current_board'] = 'Current board';
        messageData['card_form_list_move_to_another_board'] = 'Another board';
        messageData['card_form_list_move'] = 'Move';
        messageData['card_form_description_add'] = 'Add description';
        messageData['card_form_description_show_more'] = 'Show more';
        messageData['card_form_description_show_less'] = 'Show less';
        messageData['card_form_description_unsaved'] = 'Unsaved: ';
        messageData['card_from_description_unsaved_delete'] = 'Don’t save';
        messageData['card_form_description_save'] = 'Save';
        messageData['card_form_description_cancel'] = 'Cancel';
        messageData['card_form_description_markdown'] = 'Markdown';
        messageData['card_form_section_assignees'] = 'Assignees';
        messageData['card_form_section_description'] = 'Description';
        messageData['card_form_assignee_add'] = 'Add assignee';
        messageData['card_form_assignee_remove'] = 'Remove';
        messageData['card_form_assignee_in_work'] = '(Working on it)';
        messageData['card_form_assignee_add_role'] = 'Add role';
        messageData['card_form_assignee_edit_roles'] = 'Edit roles';
        messageData['card_form_assigned_on_board'] = 'Assigned on this board';
        messageData['card_form_assigned_other'] = 'Other users';
        messageData['card_form_assignee_share'] = 'Invite to this board';
        messageData['card_form_assigned_on_card'] = 'Assigned on this card';

        messageData['card_form_section_attachments'] = 'Attachments';
        messageData['card_form_attachments_add'] = 'Attach file';
        messageData['card_form_attachment_pin'] = 'Pin attachment';
        messageData['card_form_attachment_unpin'] = 'Unpin attachment';
        messageData['card_form_attachment_download'] = 'Download';
        messageData['card_form_attachment_open_card'] = 'Open card';
        messageData['card_form_attachment_copy_link'] = 'Get a link to an attachment';
        messageData['card_form_attachment_delete'] = 'Delete';
        messageData['card_form_attachment_rename'] = 'Rename';
        messageData['card_form_attachment_access'] = 'Access needed';
        messageData['card_form_attachments_tooltip_list'] = 'Show as list';
        messageData['card_form_attachments_tooltip_grid'] = 'Show as grid';
        messageData['card_form_attachments_grid_hint'] = 'Try grid view';
        messageData['card_form_attachments_list_hint'] = 'Find all attachments from boards in one place';
        messageData['card_form_attachments_list_tooltip'] = 'View board attachments';
        messageData['card_form_attachment_file_name'] = 'File name';
        messageData['card_form_attachment_button_select_drive'] = 'Select from OneDrive';
        messageData['card_form_attachment_button_upload_computer'] = 'Upload from computer';
        messageData['card_form_attachment_button_upload_device'] = 'Upload from device';
        messageData['card_form_attachment_note_drop'] = 'You can also drop files here or paste from clipboard';
        messageData['card_form_attachments_tip.1'] = 'To easily attach files, drag and drop them on the card details.';
        messageData['card_form_attachments_tip.2'] = 'You may also paste them from the clipboard using Ctrl+V.';
        messageData['card_form_attachments_tip.3'] = 'This feature works in the comment section as well.';
        messageData['card_form_attachment_attach_file'] = 'Attach file from';
        messageData['card_form_attachment_drive'] = 'Google Drive';
        messageData['card_form_attachment_device'] = 'Your device';
        messageData['card_form_attachment_create'] = 'Create, attach and share Google file';
        messageData['card_form_attachment_docs'] = 'Google Docs';
        messageData['card_form_attachment_sheets'] = 'Google Sheets';
        messageData['card_form_attachment_slides'] = 'Google Slides';
        messageData['card_form_attachment_forms'] = 'Google Forms';
        messageData['card_form_attachment_google_files_tooltip'] = 'Create, attach and share Google file';
        messageData['card_form_attachment_file_creation'] = 'File creation...';
        messageData['card_form_attachment_create_shared_file'] = 'Create shared file';

        messageData['card_form_section_subcards'] = 'Subcards';
        messageData['card_form_section_dependencies'] = 'Dependencies';
        messageData['card_form_subcard_add'] = 'Add subcard';
        messageData['card_form_subcard_delete'] = 'Remove subcard relation';
        messageData['card_form_predecessor_add'] = 'Add predecessor';
        messageData['card_form_dependency_remove'] = ' Remove dependency';
        messageData['card_form_subcard_gantt'] = 'Show on Gantt chart';
        messageData['card_form_subcard_create'] = 'Create new card';
        messageData['card_form_subcards_hide_done'] = 'Hide done items';
        messageData['card_form_subcards_done'] = '{0}% done ({1} / {2})';
        messageData['card_form_section_related'] = 'Related cards';
        messageData['card_form_section_tags'] = 'Tags';
        messageData['card_form_section_rename'] = 'Rename';
        messageData['card_rename_input_placeholder'] = 'Hit Enter/Ctrl+Enter to rename multiple cards';
        messageData['card_form_tag_add'] = 'Add tag';
        messageData['card_form_tag_remove'] = 'Remove';
        messageData['card_form_tag_create'] = 'Create new tag';
        messageData['card_form_tag_modal-title'] = 'Remove “{0}”?';
        messageData['card_form_tag_modal-text'] = 'This action is irreversible with your plan’s limit of <strong>{0} tags per board.</strong> Upgrade to have an unlimited number of tags.';
        messageData['card_form_section_dates'] = 'Start & Due date';
        messageData['card_form_section_dates.start'] = 'Start date';
        messageData['card_form_section_dates.due'] = 'Due date';
        messageData['card_form_section_dates_milestone'] = 'Date';
        messageData['card_form_section_estimate_and_spent_time'] = 'Estimate & spent time';
        messageData['card_form_section_estimate_label_text'] = 'Estimate';
        messageData['card_form_section_spent_time_label_text'] = 'Spent time';
        messageData['card_form_section_estimate_example'] = 'e.g. 1d 2h 5m';
        messageData['card_form_section_estimate_remove'] = 'Remove';
        messageData['card_form_start_date'] = 'Start date';
        messageData['card_form_start_date_milestone'] = 'Date';
        messageData['card_form_due_date'] = 'Due date';
        messageData['card_form_adjust'] = 'Adjust';
        messageData['card_form_adjust_start'] = 'Adjust the start date';
        messageData['card_form_adjust_due'] = 'Adjust the due date';
        messageData['card_form_adjust_forward'] = 'Forward';
        messageData['card_form_adjust_backward'] = 'Backward';
        messageData['card_form_adjust_placeholder'] = 'e.g. 1d 2w 3m 4y';
        messageData['card_form_adjust_error.start'] = 'At least one of the cards has due date before new start date';
        messageData['card_form_adjust_error.due'] = 'At least one of the cards has start date after new due date';
        messageData['card_form_start_date_error'] = 'Start date should be before the due date';
        messageData['card_form_due_date_error'] = 'Due date should be after the start date';
        messageData['card_form_date_remove'] = 'Remove';
        messageData['card_form_date_time_hint'] = 'You can change<br/>default start/due time<br/>in board settings';
        messageData['card_form_date_hint'] = `Would you like Kanbanchi to set card creation moment as its start date automatically? You can later disable the feature in `;
        messageData['card_form_date_hint_board_link'] = 'board settings';
        messageData['card_form_date_hint_learn_link'] = `. <a target="_blank" href='https://www.kanbanchi.com/faqwd/current-date-as-card-start-date'>Learn more</a>`;
        messageData['card_form_date_hint_button_yes'] = 'Yes, set start date automatically';
        messageData['card_form_date_hint_button_no'] = 'No, thanks';
        messageData['card_form_date_remove_all_tooltip'] = 'Remove all dates';
        messageData['card_form_time'] = 'Time';
        messageData['card_form_dates_notify'] = 'Notify me';
        messageData['card_form_dates_notifications_button'] = 'Notifications';
        messageData['card_form_dates_calendar_button'] = 'Add to Google calendar';
        messageData['card_form_dates_calendar_snackbar'] = 'Dates from all cards created from this template will be added to Google Calendar';
        messageData['card_form_section_checklist'] = 'Checklist';
        messageData['card_form_checklist_default_name'] = 'Checklist';
        messageData['card_form_checklist_actions_tooltip'] = 'Checklist actions';
        messageData['card_form_checklist_actions_sort'] = 'Sort by';
        messageData['card_form_checklist_actions_pin'] = 'Pin to card preview';
        messageData['card_form_checklist_actions_unpin'] = 'Unpin from card preview';
        messageData['card_form_checklist_actions_hide'] = 'Hide done items';
        messageData['card_form_checklist_actions_show'] = 'Show done items';
        messageData['card_form_checklist_actions_author_hide'] = 'Hide author';
        messageData['card_form_checklist_actions_author_show'] = 'Show author';
        messageData['card_form_checklist_actions_assign'] = 'Assign all items to...';
        messageData['card_form_checklist_actions_done'] = 'Mark all as done';
        messageData['card_form_checklist_actions_copy'] = 'Copy items from...';
        messageData['card_form_checklist_actions_rename'] = 'Rename checklist';
        messageData['card_form_checklist_actions_delete'] = 'Delete checklist';
        messageData['card_form_checklist_add_new_item'] = 'Add new item';
        messageData['card_form_checklist_add_item'] = 'Add item';
        messageData['card_form_checklist_show_done_items'] = 'Show done items';
        messageData['card_form_checklist_hide_done_items'] = 'Hide done items';
        messageData['card_form_checklist_add_placeholder'] = 'Type the item name ↵';
        messageData['card_form_checklist_add'] = 'Add';
        messageData['card_form_checklist_save'] = 'Save';
        messageData['card_form_checklist_delete'] = 'Delete';
        messageData['card_form_checklist_done'] = '{0}% done ({1} / {2})';
        messageData['card_form_checklist_placeholder'] = 'Type then hit Enter';
        messageData['card_form_checklist_assign_user'] = 'Assign item to user';
        messageData['card_form_checklist_set_dates'] = 'Set dates';
        messageData['card_form_checklist_set_dates_due'] = 'Due date';
        messageData['card_form_checklist_set_dates_start'] = 'Start date';
        messageData['card_form_checklist_items_assign'] = 'Assign all items to user';
        messageData['card_form_checklist_convert_to_subcard'] = 'Convert item to subcard';
        messageData['card_form_checklist_convert_to_subcard_milestone'] = 'Milestone cannot have subcards';
        messageData['card_form_checklist_start_date'] = 'Set start date';
        messageData['card_form_checklist_due_date'] = 'Set due date';
        messageData['card_form_checklist_weight'] = 'Weight';
        messageData['card_form_checklist.sort.manual'] = 'Manual sorting';
        messageData['card_form_checklist.sort.done'] = 'Done items first';
        messageData['card_form_checklist.sort.undone'] = 'Undone items first';
        messageData['card_form_section_checklists'] = 'Checklists';
        messageData['card_form_section_checklists_modal_title'] = 'Delete “{0}”?';
        messageData['card_form_section_checklists_modal_text'] = 'This action is irreversible with your plan’s limit of <strong>{0} checklist per card.</strong> Upgrade to have an unlimited number of checklists.';
        messageData['card_form_section_checklist_item_modal_title'] = 'Delete “{0}”?';
        messageData['card_form_section_checklist_item_modal_text'] = 'This action is irreversible with your plan’s limit of <strong>{0} checklist items per checklist</strong>. Upgrade to have an unlimited number of checklists items.';
        messageData['card_form_checklists_add'] = 'Add checklist';
        messageData['card_form_checklists_add_new'] = 'Add new checklist';
        messageData['card_form_checklists_delete'] = 'Delete checklist';
        messageData['card_form_checklists_name'] = 'Checklist name';
        messageData['card_form_checklists_hide_done'] = 'Hide done items';
        messageData['card_form_checklist_done_status'] = 'Done status';
        messageData['card_form_checklist_droppable'] = 'Drop the item here';
        messageData['card_form_checklist_author'] = 'Author: ';
        messageData['card_form_checklist_copy_from'] = 'Copy checklist from a card or card template...';
        messageData['card_form_checklist_copy_card_label'] = 'Select card or card template to copy checklist from';
        messageData['card_form_checklist_copy_checklist_label'] = 'Select a checklist to copy items from';
        messageData['card_form_checklist_copy_button_cancel'] = 'Add items manually';
        messageData['card_form_checklist_copy_button_ok'] = 'Copy items from the card';

        messageData['move_to_drives_column_title'] = 'Drives';
        messageData['move_to_boards_column_title'] = 'Boards';
        messageData['move_to_lists_column_title'] = 'Lists';
        messageData['move_to_cards_column_title'] = 'Cards';
        messageData['move_to_insert_after_card'] = ' (insert after card)';
        messageData['move_to_insert_after_list'] = ' (insert after list)';
        messageData['move_to_chuwak'] = '¯\\_(ツ)_/¯';
        messageData['move_to_more_boards_item'] = 'More boards';
        messageData['move_to_make_first_item'] = 'Place first';

        messageData['card_form_section_attachments'] = 'Attachments';
        messageData['card_form_move_to_button'] = 'Move to...';
        messageData['card_form_move_to_tooltip'] = 'Move to...';
        messageData['card_form_move_to_section_title'] = 'Move to';

        messageData['card_form_section_estimate'] = 'Estimate';
        messageData['card_form_snackbar_delete_text'] = 'Card “{0}” deleted';
        messageData['card_form_action_delete'] = 'Delete card';
        messageData['card_form_action_delete_tooltip'] = 'Delete card';
        messageData['card_form_action_delete_template'] = 'Delete template';
        messageData['card_form_action_delete_recurring'] = 'Delete recurring card';
        messageData['card_form_copy_card_button'] = 'Make a copy';
        messageData['card_form_copy_card_tooltip'] = 'Make a copy';
        messageData['card_form_action_get_link_to_card'] = 'Get a link to a card';
        messageData['card_form_action_get_link_to_card_tooltip'] = 'Get a link to a card';
        messageData['card_form_action_add_to_my_work_tooltip_remove'] = 'Remove card from My work';
        messageData['card_form_action_add_to_my_work_tooltip_add'] = 'Add card to My work';
        messageData['card_form_action_add_to_my_work_snackbar_add'] = 'Has been added to My work';
        messageData['card_form_action_add_to_my_work_snackbar_remove'] = 'Has been removed from My work';
        messageData['card_form_action_more_properties'] = 'More properties';
        messageData['card_form_make_default'] = 'Make default';
        messageData['card_form_make_default_tooltip'] = 'Use this template for new cards';
        messageData['card_form_show_on_gantt'] = 'Show on Gantt';
        messageData['card_form_show_on_gantt_tooltip'] = 'Show on Gantt chart';
        messageData['card_form_hide_from_gantt_tooltip'] = 'Remove this card from Gantt Chart';
        messageData['card_form_convert_milestone'] = 'Milestone';
        messageData['card_form_convert_to_milestone_tooltip'] = 'Convert to milestone';
        messageData['card_form_convert_from_milestone_tooltip'] = 'Convert from milestone';
        messageData['card_form_milestone_epic'] = 'Epic can not be a milestone';
        messageData['card_form_section_epic'] = 'Epic';
        messageData['card_form_archive_title'] = 'Archive card';
        messageData['card_form_unarchive_title'] = 'Unarchive card';
        messageData['card_form_archive_epic_title'] = 'Archive group';
        messageData['card_form_archive_with_subcards'] = 'Archive Epic with its subcards';
        messageData['card_form_action_save_as_template'] = 'Save as template';
        messageData['card_form_create_new_card_now'] = 'Add to the board now';
        messageData['card_form_create_new_card'] = 'Add to the board';
        messageData['card_form_choose_list'] = 'Choose a list';
        messageData['card_form_action_set_in_work_card_tooltip'] = 'Start working on it';
        messageData['card_form_action_unset_in_work_card_tooltip'] = 'Working on it';

        messageData['card_form_from_template'] = 'Created from';
        messageData['card_form_from_template_tooltip'] = 'Template: ';
        messageData['card_form_from_recurring_tooltip'] = 'Recurring card: ';
        messageData['card_form_from_template_archived'] = ' Archived';

        messageData['card_form_remove_title'] = 'Delete card';
        messageDaRu['card_form_remove_title'] = 'Удалить карточку';
        messageDJLR['card_form_remove_title'] = 'Remove issue';

        messageData['card_form_copy_lable'] = 'Make a copy';
        messageDJLR['card_form_copy_lable'] = 'Copy issue';
        messageData['card_form_print_lable'] = 'Print card';
        messageDJLR['card_form_print_lable'] = 'Print issue';

        messageData['card_form_getlink_lable'] = 'Get a link to a card';
        messageDJLR['card_form_getlink_lable'] = 'Get link to issue';
        messageData['card_form_getlink_copied'] = 'Link copied to the clipboard';
        messageData['card_form_board_tooltip'] = 'Board:';
        messageData['card_form_add_related_cards_label'] = 'Add related cards';
        messageDJLR['card_form_add_related_cards_label'] = 'Add related issues';
        messageData['card_form_edit_related_cards_label'] = 'Edit related cards';
        messageDJLR['card_form_edit_related_cards_label'] = 'Edit related issues';
        messageData['card_form_delete_related_card'] = 'Remove';
        messageData['card_form_add_sub_cards_label'] = 'Add subcard';
        messageDJLR['card_form_add_sub_cards_label'] = 'Add sub-issue';

        messageData['card_form_detail_card_title_placeholder'] = 'Card title';
        messageData['card_form_comment_placeholder'] = 'Comment or mention others with @';
        messageData['card_form_comment_edit_label'] = 'Edit comment';
        messageData['card_form_comment_no_title'] = '...';
        messageDJLR['card_form_detail_card_title_placeholder'] = 'Issue title';
        messageData['card_form_detail_card__desc_placeholder'] = 'Edit card description';
        messageDJLR['card_form_detail_card__desc_placeholder'] = 'Edit issue description';
        messageData['card_form_watch_card'] = 'Watch card (receive notifications)';
        messageData['card_form_watch_card_notifications_off'] = 'You won’t get notifications because they are turned off for the board. Press the icon to switch them on.';
        messageData['card_form_watch_card_change_settings_tooltip'] = 'You are receiving notifications about all cards. To be able to unsubscribe and only receive notifications about particular cards, ';
        messageData['card_form_watch_card_change_settings_tooltip_link'] = 'change board settings.';
        messageDJLR['card_form_watch_card'] = 'Watch issue (receive notifications)';
        messageData['card_form_watch_card_stop'] = 'Stop receiving notifications';
        messageData['card_form_drive_doc__unpin'] = 'Unpin from card';
        messageDJLR['card_form_drive_doc__unpin'] = ' Unpin from issue';
        messageData['card_form_drive_doc__pin'] = 'Pin to card';
        messageDJLR['card_form_drive_doc__pin'] = 'Pin to issue';
        messageData['card_form_section_comments'] = 'Comments';
        messageData['card_form_comment_reply'] = 'Reply';
        messageData['card_form_comment_quote_and_reply'] = 'Quote & Reply';
        messageData['card_form_add_comment__add'] = 'Add your comment...';
        messageDJLR['card_form_add_comment__add'] = 'Update issue status';
        messageData['card_form_comment_add_attachment'] = 'Add attachment';
        messageData['card_form_remove_card_number'] = 'Remove prefix';
        messageData['card_form_remove_card_number_modal_title'] = 'Card prefix removing';
        messageData['card_form_remove_card_number_modal_title_loading'] = 'Removing card prefixes...';
        messageData['card_form_remove_card_number_modal_text'] = 'Do you want to remove prefix from this card only or prefixes from all cards on this board?';
        messageData['card_form_remove_card_number_modal_text_loading'] = 'Please, wait while we are removing your prefixes,\n' + 'it may take a few minutes';
        messageData['card_form_remove_card_number_modal_card'] = 'Only this card';
        messageData['card_form_remove_card_number_modal_cards'] = 'All cards on this board';
        messageData['card_form_generate_card_number'] = 'Add prefix';
        messageData['card_form_default_template'] = 'Default';
        messageData['card_form_section_recurring'] = 'Make recurring';
        messageData['print_card__btn__title'] = 'Print card';
        messageDJLR['print_card__btn__title'] = 'Print issue';

        messageData['card_form_section_custom'] = 'Custom properties';
        messageData['card_form_custom_add'] = 'Add custom property';
        messageData['card_form_custom_new'] = 'New custom property';
        messageData['card_form_custom_empty_show'] = 'Show empty properties';
        messageData['card_form_custom_empty_hide'] = 'Hide empty properties';
        messageData['card_form_custom_section_new'] = 'New custom property';
        messageData['card_form_custom_section_existing'] = 'Existing properties';
        messageData['card_form_custom_section_error_duplicated'] = 'Duplicated property';
        messageData['card_form_custom_section_error_empty'] = 'Empty name';
        messageData['card_form_custom_no_option'] = 'No options added';
        messageData['card_form_custom_error_email'] = 'Invalid email';
        messageData['card_form_custom_error_phone'] = 'Invalid phone number';
        messageData['card_form_custom_new_feature_tooltip'] = 'Use custom properties to adjust boards to your projects best';
        messageData['card_form_custom_section_show'] = 'Show on a card when empty even if other empty custom properties are hidden';
        messageData['card_form_custom_section_hide'] = 'Hide when empty';

        messageData['card_form_comments.link'] = 'Get link to this comment';
        messageData['card_form_comments.link.snackbar.text'] = 'Link to this comment copied to the clipboard';
        messageData['card_form_comments.like.user'] = 'You liked it';
        messageData['card_form_comments.like.others'] = 'Liked:';
        messageData['card_form_comments.like.user-others'] = 'Liked by you and:';
        messageData['card_form_comments.actions.tooltip'] = 'Comment actions';
        messageData['card_form_comments.action.delete'] = 'Delete';
        messageData['card_form_comments.action.reply'] = 'Reply';
        messageData['card_form_comments.action.link'] = 'Get link';

        messageData['print_card__comments'] = 'Comments';
        messageDJLR['print_card__comments'] = 'Status updates';

        messageData['related_cards_panel.save'] = 'Save related cards';
        messageData['related_cards_panel.cancel'] = 'Cancel';
        messageData['related_cards_panel.help'] = 'Select cards to add them as related, then click the Save button.'
        messageData['related_cards_panel.help_other_boards'] = 'To add cards from another board, simply open it and select cards the same way.'

        messageData['dialog__get_link_to_card'] = 'Get a link to a card';
        messageDJLR['dialog__get_link_to_card'] = 'Get link to issue';

        messageData['list_snackbar_delete_text'] = 'List “{0}” deleted';
        messageData['list_new_card_form_placeholder'] = 'Enter or Shift+Enter to create';
        messageData['list_new_card_form_placeholder.mobile'] = 'Card name';
        messageData['list_new_card_form_placeholder.mac'] = 'Enter or Shift+Enter to create';
        messageData['list_new_card_form_placeholder.description'] = 'Add description';
        messageDJLR['list_new_card_form_placeholder'] = 'Type then hit Enter to add an issue';
        messageData['list_new_card_form_add'] = 'Add';
        messageData['activity_item__card_created'] = 'created a card';
        messageDJLR['activity_item__card_created'] = 'created new issue';
        messageData['dashboard_filter_form__filter_cards'] = 'Filter Cards';
        messageDJLR['dashboard_filter_form__filter_cards'] = 'Filter Issues';
        messageData['dashboard_filter_form__sort_cards'] = 'Sort cards by';
        messageDJLR['dashboard_filter_form__sort_cards'] = 'Sort issues by';
        messageData['activity_item__card_description_added'] = 'added card description';
        messageDJLR['activity_item__card_description_added'] = 'added issue description';
        messageData['activity_item__card_description_removed'] = 'removed card description';
        messageDJLR['activity_item__card_description_removed'] = 'removed issue description';
        messageData['activity_item__card_description_edited'] = 'edited card description';
        messageDJLR['activity_item__card_description_edited'] = 'edited issue description';
        messageData['activity_item__card_name'] = 'renamed card';
        messageDJLR['activity_item__card_name'] = 'renamed issue';
        messageData['activity_item_card__due_date_added'] = 'added card due date';
        messageDJLR['activity_item_card__due_date_added'] = 'added issue due date';
        messageData['activity_item_card__due_date_edited'] = 'edited card due date';
        messageDJLR['activity_item_card__due_date_edited'] = 'edited issue due date';
        messageData['activity_item_card__due_date_removed'] = 'removed card due date';
        messageDJLR['activity_item_card__due_date_removed'] = 'removed issue due date';
        messageData['activity_item_card__start_date_added'] = 'added card start date';
        messageDJLR['activity_item_card__start_date_added'] = 'added issue start date';
        messageData['activity_item_card__start_date_edited'] = 'edited card start date';
        messageDJLR['activity_item_card__start_date_edited'] = 'edited issue start date';
        messageData['activity_item_card__start_date_removed'] = 'removed card start date';
        messageDJLR['activity_item_card__start_date_removed'] = 'removed issue start date';
        messageData['activity_item_card__estimate_added'] = 'added card estimate';
        messageDJLR['activity_item_card__estimate_added'] = 'added issue estimate';
        messageData['activity_item_card__estimate_edited'] = 'edited card estimate';
        messageDJLR['activity_item_card__estimate_edited'] = 'edited issue estimate';
        messageData['activity_item_card__estimate_removed'] = 'removed card estimate';
        messageDJLR['activity_item_card__estimate_removed'] = 'removed issue estimate';
        messageData['activity_item_card_processing_status__added'] = 'added card status';
        messageDJLR['activity_item_card_processing_status__added'] = 'added issue status';
        messageData['activity_item_card_processing_status__edited'] = 'edited card status';
        messageDJLR['activity_item_card_processing_status__edited'] = 'edited issue status';
        messageData['activity_item_card_processing_status__removed'] = 'removed card status';
        messageDJLR['activity_item_card_processing_status__removed'] = 'removed issue status';
        messageData['activity_item_card_list__location'] = 'changed card location';
        messageDJLR['activity_item_card_list__location'] = 'changed issue location';

        messageData['activity_item_board__created'] = 'created new board';
        messageData['activity_item_board__copied'] = 'copied board';
        messageData['activity_item_board__name'] = 'renamed board';
        messageData['activity_item_board__description_added'] = 'added board description';
        messageData['activity_item_board__description_removed'] = 'removed board description';
        messageData['activity_item_board__description_edited'] = 'edited board description';

        messageData['activity_item_list__created'] = 'created new list';
        messageData['activity_item_list__deleted'] = 'removed list';
        messageData['activity_item_list__name'] = 'renamed list';
        messageData['activity_item_list__description_added'] = 'added list description';
        messageData['activity_item_list__description_removed'] = 'removed list description';
        messageData['activity_item_list__description_edited'] = 'edited list description';
        messageData['activity_item_list__copied'] = 'copied list';
        messageData['activity_item_list__max_card_number_added'] = 'added max card number';
        messageDJLR['activity_item_list__max_card_number_added'] = 'added max issue number';
        messageData['activity_item_list__max_card_number_edited'] = 'changed max card number';
        messageDJLR['activity_item_list__max_card_number_edited'] = 'changed issue card number';
        messageData['activity_item_list__max_card_number_removed'] = 'removed max card number';
        messageDJLR['activity_item_list__max_card_number_removed'] = 'removed issue card number';
        messageData['activity_item_list__max_card_number__limit_new_value'] = 'Maximum {0} cards';
        messageDJLR['activity_item_list__max_card_number__limit_new_value'] = 'Maximum {0} issues';
        messageData['activity_item_list__max_card_number__limit_old_value'] = 'Maximum {0} cards';
        messageDJLR['activity_item_list__max_card_number__limit_old_value'] = 'Maximum {0} issues';
        messageData['activity_item_list_card_order__changed'] = 'card order changed';
        messageDJLR['activity_item_list_card_order__changed'] = 'issue order changed';
        messageData['activity_item_card__tag_added'] = 'added {0} tag';
        messageData['activity_item_card__tags_added'] = 'added {0} tags';
        messageData['activity_item_card__tag_removed'] = 'removed {0} tag';
        messageData['activity_item_card__tags_removed'] = 'removed {0} tags';
        messageData['activity_item_card__assigner_added'] = 'Assigned';
        messageData['activity_item_card__assigner_user_added'] = 'Assigned a card to me';
        messageData['activity_item_card__assigner_user_others_added'] = 'Assigned me and';
        messageData['activity_item_card__assigner_removed'] = 'Unassigned';
        messageData['activity_item_card__assigner_user_removed'] = 'Unassigned a card from me';
        messageData['activity_item_card__assigner_user_other_removed'] = 'Unassigned me and';
        messageData['activity_item_card__assigner_card_removed'] = 'from this card';
        messageData['activity_item_card__assigner_card_added'] = 'to this card';
        messageData['activity_item_card__comment_reaction_removed'] = 'Removed a like from this comment';
        messageData['activity_item_card__comment_reaction_added'] = 'Liked this comment';
        messageData['activity_item_card__color_added'] = 'added colour label';
        messageData['activity_item_card__colors_added'] = 'added colour labels';
        messageData['activity_item_card__color_edited'] = 'changed colour label';
        messageData['activity_item_card__colors_edited'] = 'changed colour labels';
        messageData['activity_item_card__color_removed'] = 'removed colour label';
        messageData['activity_item_card__colors_removed'] = 'removed colour labels';
        messageData['activity_item_card__attachment_added'] = 'linked {0} attachment';
        messageData['activity_item_card__attachments_added'] = 'linked {0} attachments';
        messageData['activity_item_card__attachment_removed'] = 'unlinked {0} attachment';
        messageData['activity_item_card__attachments_removed'] = 'unlinked {0} attachments';
        messageData['activity_item_card__due_date_passed'] = 'Due date passed';
        messageData['activity_item_card__due_date_overdue'] = 'Due date';
        messageData['activity_item_card__due_date'] = 'It was: ';
        messageData['activity_item_card__checklist_added'] = 'added checklist item';
        messageData['activity_item_card__checklist_edited'] = 'edited checklist item';
        messageData['activity_item_card__checklist_weight_change'] = 'changed checklist item weight';
        messageData['activity_item_card__checklist_duedate_change'] = 'changed checklist item due date';
        messageData['activity_item_card__checklist_duedate_remove'] = 'removed checklist item due date';
        messageData['activity_item_card__checklist_mark_done'] = 'marked checklist item as “done”';
        messageData['activity_item_card__checklist_remove_done'] = 'removed checklist item status “done”';
        messageData['activity_item_card__checklist_deleted'] = 'deleted checklist item';
        messageData['activity_item_card__checklist_due_date_passed'] = 'Checklist item due date passed';
        messageData['activity_item_card__checklist_group_added'] = 'added checklist';
        messageData['activity_item_card__checklist_group_edited'] = 'edited checklist';
        messageData['activity_item_card__checklist_group_deleted'] = 'deleted checklist';

        messageData['activity_item_card__related_card_added'] = 'added related card';
        messageData['activity_item_card__related_card_edited'] = 'changed related card';
        messageData['activity_item_card__related_card_removed'] = 'removed related card';
        messageData['activity_item_card__related_cards_added'] = 'added related cards';
        messageData['activity_item_card__related_cards_edited'] = 'changed related cards';
        messageData['activity_item_card__related_cards_removed'] = 'removed related cards';

        messageData['activity_item_card__comment_added'] = 'added comment';
        messageDJLR['activity_item_card__comment_added'] = 'updated issue status';
        messageData['activity_item_card__comment_edited'] = 'edited comment';
        messageDJLR['activity_item_card__comment_edited'] = 'edited issue status';
        messageData['activity_item_card__comment_removed'] = 'removed comment';
        messageDJLR['activity_item_card__comment_removed'] = 'removed issue status';

        messageData['activity_item_card__custom_property_added'] = 'added custom ';
        messageData['activity_item_card__custom_property_edited'] = 'edited ';
        messageData['activity_item_card__custom_property_removed'] = 'removed ';

        messageData['activity_item__created_from_template'] = 'Created from template'
        messageData['activity_item__created_from_recurring'] = 'Created from recurring card';

        messageData['card_bottom_info__relations'] = 'Card relations';
        messageDJLR['card_bottom_info__relations'] = 'Issue relations';
        messageData['card_bottom_info__title'] = 'Card description';
        messageDJLR['card_bottom_info__title'] = 'Issue description';
        messageData['card__bottom_info__comments'] = 'Comments';
        messageDJLR['card__bottom_info__comments'] = 'Status updates';

        messageData['dashboard_filter_panel__found'] = '{0} card{1}';
        messageData['list_details__link_copy'] = 'Copy list with all cards';
        messageData['list_details__link_delete'] = 'Delete list';
        messageData['list_details__link_delete_with_cards'] = 'Delete list with all cards';
        messageData['list_details__link_select_all'] = 'Select all cards in list';
        messageDJLR['list_details__link_select_all'] = 'Select all issues in list';
        messageData['list_details__link_remove_list'] = 'Delete list with all cards';
        messageDJLR['list_details__link_remove_list'] = 'Remove list with issues';
        messageData['list_details__link_move_list'] = 'Move to';
        messageDJLR['list_details__link_move_list'] = 'Move to';
        messageData['list_details__link_archive'] = 'Archive list with all cards';
        messageData['list_details__link_unarchive'] = 'Unarchive all cards in list';
        messageData['list_details__link_print'] = 'Print list';
        messageData['list_details__link_get_link_to_list'] = 'Get link to list';
        messageData['list_details__sort_by_priority'] = 'Sort cards by priority';
        messageDJLR['list_details__sort_by_priority'] = 'Sort issues by priority';
        messageData['list_details__card_limit'] = 'Limit card number';
        messageDJLR['list_details__card_limit'] = 'Limit issue number';
        messageData['list.cardsNumber.description'] = 'You won’t be limited in placing cards to this list but the indicator will turn red showing you that you’ve exceeded the limit.';
        messageData['dashboard_details_export__link_titles'] = 'Export card titles only';
        messageDJLR['dashboard_details_export__link_titles'] = 'Export Issue titles only';
        messageData['dashboard_details_export__link_full'] = 'Export card titles & description';
        messageDJLR['dashboard_details_export__link_full'] = 'Export issue titles &amp; description';
        messageData['board_details__link_move_to'] = 'Move to';
        messageData['board_details__link_move_to_empty'] = 'No available drives for move';
        messageData['board_details__link_archive'] = 'Archive board';
        messageData['board_details__link_unarchive'] = 'Unarchive board';
        messageData['board_details__link_open_in_drive'] = 'Open in Drive';
        messageData['notification_settings__label_all'] = 'All cards';
        messageDJLR['notification_settings__label_all'] = 'All issues';
        messageDJLR['notification_settings__label_assigned'] = 'Assigned to me and watched issues';
        messageData['notification_settings__label_watched'] = 'Watched cards only';
        messageData['notification_settings__label_watched'] = 'Watched cards only';

        messageData['notification_settings__label_assigned'] = 'Cards assigned to me';
        messageData['notification_settings__label_watched_cards_updates'] = 'Watched cards';
        messageData['notification_settings__label_im_assigned'] = 'Someone assigned a card to me';

        // push notificatins
        messageData['notification_settings__label_watched_cards'] = 'Watched cards';
        messageData['notification_settings__label_im_mentioned'] = 'Someone mentioned me';


        messageDJLR['notification_settings__label_watched'] = 'Watched issues only';
        messageData['activity_item__card_deleted'] = 'removed card';
        messageDJLR['activity_item__card_deleted'] = 'removed issue';
        messageData['activity_item__card_restored'] = 'restored card';
        messageDJLR['activity_item__card_restored'] = 'restored issue';
        messageData['activity_item__card_copied'] = 'copied card';
        messageDJLR['activity_item__card_copied'] = 'copied issue';
        messageData['activity_item__card_archived'] = 'archived card';
        messageData['activity_item__card_unarchived'] = 'unarchived card';
        messageData['activity_item__card_restore'] = 'restored card';
        messageData['activity_item__card_priority_added'] = 'added card priority';
        messageDJLR['activity_item__card_priority_added'] = 'added issue priority';
        messageData['activity_item__card_priority_edited'] = 'changed card priority';
        messageDJLR['activity_item__card_priority_edited'] = 'changed issue priority';
        messageData['activity_item__card_priority_removed'] = 'removed card priority';
        messageDJLR['activity_item__card_priority_removed'] = 'removed issue priority';

        messageData['activity_item__list_archived'] = 'archived list';
        messageData['activity_item__list_unarchived'] = 'unarchived list';

        messageData['activity_item__board_archived'] = 'archived board';
        messageData['activity_item__board_unarchived'] = 'unarchived board';

        messageData['model.dashboard.email_placeholder'] = 'Untitled board';
        messageData['model.default.dashboard.name'] = 'Untitled board';
        messageData['model.default.template.name'] = 'New template';
        messageData['model.default.list.name'] = 'Untitled list';
        messageData['model.dashboard.copy.prefix'] = 'Copy of ';
        messageData['model.color.clear'] = 'No colour labels';
        messageData['model.color.nocolor'] = 'No colour';
        messageData['model.role.norole'] = 'No role';
        messageData['model.user.no_name'] = 'Pending...';
        messageData['model.user.unassigned'] = 'Unassigned';
        messageData['model.user.anonym'] = 'Anonymous';
        messageData['model.calendar.primary'] = 'Primary calendar';
        messageData['model.card.due_date'] = 'Due date';
        messageData['model.card.start_date'] = 'Start date';
        messageData['markdown.link'] = 'link';
        messageData['calendar.clear_date'] = 'Clear date';
        messageData['page.title'] = 'Kanbanchi';
        messageData['page.key'] = 'kanbanchi';
        messageData['util.few_seconds_ago'] = 'just now';
        messageData['util.one_minute_ago'] = 'a minute ago';
        messageData['util.some_minutes_ago'] = 'minutes ago';
        messageData['util.one_hour_ago'] = 'an hour ago';
        messageData['util.some_hours_ago'] = 'hours ago';

        messageData['priority.0'] = 'No priority';
        messageData['priority.1'] = 'Low';
        messageData['priority.2'] = 'Normal';
        messageData['priority.3'] = 'Medium';
        messageData['priority.4'] = 'High';
        messageData['priority.5'] = 'Critical';

        messageData['tutorials.basic.list_success'] = 'Good job! Your list is now on the board!';
        messageData['tutorials.basic.card_success'] = 'Good job! Your card is now on the board!';
        messageData['tutorials.basic.end.title'] = 'Congrats! You mastered the basics!';
        messageData['tutorials.basic.end.description'] = 'Keep in touch with your team :) Invite collaborators using the Share button.';

        messageData['feedback.description.error'] = "Thanks for getting in touch. Your request will be processed";
        messageData['feedback.description.free'] = "Thanks for getting in touch. Please, note that your current plan doesn't include support. However, we would like to help all our users. Your request will be processed if time available";
        messageData['feedback.description.paid'] = 'Thanks for getting in touch. Our support team will reply within 24 hours (except weekend)';
        messageData['feedback.files.large'] = 'Maximum total upload size is 15 MB';
        messageData['feedback.title.normal'] = 'Get in touch with Kanbanchi team';
        messageData['feedback.title.bug'] = 'Need help?';
        messageData['feedback.title.trello'] = 'Need help with import from Trello?';
        messageData['feedback.title.requestProperty'] = 'Need more card properties? Let&nbsp;us&nbsp;know what are you missing!';
        messageData['feedback.title.success.normal'] = 'You’ve made our day!';
        messageData['feedback.title.success.bug'] = 'Your message has been sent';
        messageData['feedback.message.label'] = 'Your thoughts are welcomed here';
        messageData['feedback.we_usually_respond'] = 'We usually respond within&nbsp;24&nbsp;hours';

        messageData['ga.category.dashboard'] = 'Board';
        messageData['ga.category.list'] = 'List';
        messageData['ga.category.card'] = 'Card';
        messageData['ga.category.user'] = 'User';
        messageData['ga.category.user.ru'] = 'RU User';
        messageData['ga.category.new_user'] = 'New User';
        messageData['ga.category.subscription'] = 'Subscription';
        messageData['ga.category.payment'] = 'Payment';
        messageData['ga.category.comment'] = 'Comment';
        messageData['ga.category.activity'] = 'Activity';
        messageData['ga.category.checklist'] = 'Checklist';
        messageData['ga.category.drive_doc'] = 'Drive Doc';
        messageData['ga.category.ajax_error'] = 'Ajax error';
        messageData['ga.category.social'] = 'Social button';
        messageData['ga.category.new_features'] = 'New features popup';
        messageData['ga.category.dashboard_loading'] = 'Board Loading';
        messageData['ga.category.dashboard_tabs_loading'] = 'Board Tabs Loading';
        messageData['ga.category.gantt_chart'] = 'Gantt Chart';
        messageData['ga.category.dependencies'] = 'Gantt Dependencies';
        messageData['ga.category.time_tracker'] = 'Time Tracker';
        messageData['ga.category.related_cards'] = 'Related cards';
        messageData['ga.category.backups'] = 'Board backups';
        messageData['ga.category.import'] = 'Board import';
        messageData['ga.category.notifications'] = 'Notifications';
        messageData['ga.category.open_boards'] = 'Open boards';
        messageData['ga.category.boards_list'] = 'Boards list';

        messageData['ga.category.boards_templates'] = 'Boards templates';
        messageData['ga.category.notifications'] = 'Notifications';
        messageData['ga.category.profile_box'] = 'User profile box';
        messageData['ga.category.pricing_app'] = 'Pricing (app)';
        messageData['ga.category.subscription_management'] = 'Subscription management panel';
        messageData['ga.category.blockers'] = 'Blockers';
        messageData['ga.category.guides'] = 'User guides';
        messageData['ga.category.archive'] = 'Archive';
        messageData['ga.category.support'] = 'Support';

        // PROFILE
        messageData['profile.tooltip'] = 'Authorised with {0}';
        messageData['profile.tooltip.ms'] = 'Microsoft 365';
        messageData['profile.tooltip.google'] = 'Google';

        messageData['ga.action.archive.toggle.success'] = 'Archive toggle clicked (success)';
        messageData['ga.action.archive.toggle.nosuccess'] = 'Archive toggle clicked (no success)';

        messageData['ga.category.time_zone'] = 'Time Zone';

        messageData['ga.action.guide.first_guide.viewed.step_'] = 'First guide viewed. step #';
        messageData['ga.action.guide.first_guide.skiped.step_'] = 'First guide skipped. step #';
        messageData['ga.action.guide.first_guide.back.step_'] = 'First guide back to step #';
        messageData['ga.action.guide.first_guide.next.step_'] = 'First guide move to step #';
        messageData['ga.action.guide.first_guide.finished'] = 'First guide finished';
        messageData['ga.action.guide.first_guide.watch_tutorials'] = 'First guide. Video tutorial (after guide)';
        messageData['ga.action.guide.not_finished.showed'] = 'First guide not finished popup showed';
        messageData['ga.action.guide.not_finished.skip'] = 'First guide not finished - Skipped';
        messageData['ga.action.guide.not_finished.yes'] = 'First guide not finished - Agreed';

        messageData['ga.action.card.create'] = 'Card Created';
        messageData['ga.action.card.image_pinned'] = 'Image Pinned to Card';
        messageData['ga.action.card.doc_pinned'] = 'Doc Pinned to Card';
        messageData['ga.action.card.delete'] = 'Card Deleted';
        messageData['ga.action.card.archive'] = 'Card Archived from';
        messageData['ga.action.card.archive.nosuccess'] = 'Card Archived (no success)';
        messageData['ga.action.card.archive.epic'] = 'Epic Card Archived from';
        messageData['ga.action.card.unarchive'] = 'Card Unarchived';
        messageData['ga.action.card.shift_clicked'] = 'User Shift-clicked on a Card';
        messageData['ga.action.card.deletion_undone'] = 'Card Deletion Undone';
        messageData['ga.action.card.moved_to_another_dashboard'] = 'Card moved to another board';
        messageData['ga.action.card.print_preview'] = 'Card Print Preview Opened';
        messageData['ga.action.card.google_calendar.exported.dueDate'] = 'Card due date exported to Google Calendar';
        messageData['ga.action.card.google_calendar.exported.startDate'] = 'Card start date exported to Google Calendar';

        messageData['ga.action.multi.card.archive'] = 'Multiple cards archived from';
        messageData['ga.action.multi.card.archive.nosuccess'] = 'Multiple cards archived (no success)';
        messageData['ga.action.multi.card.unarchive'] = 'Multiple cards unarchived';

        messageData['ga.action.card.google_calendar.exported.event'] = 'Card event exported to Google Calendar';
        messageData['ga.action.card.dashboard_email_copied'] = 'Board email copied';

        messageData['ga.action.card.move_to_list'] = 'Move to list';
        messageData['ga.action.card.drive_docs_order_changed'] = 'Drive docs order changed';
        messageData['ga.action.card.load_more'] = 'Load more for moving cards between boards';
        messageData['ga.action.card.card_move'] = 'Card moved between boards in Card Details';
        messageData['ga.action.card.drag_and_drop_between_boards'] = 'Drag and Drop between boards';
        messageData['ga.action.card.drag_and_drop_between_boards_faq_click'] = 'FAQ link clicked, when dragging and dropping between boards';
        messageData['ga.action.card.color-tags.one'] = 'One colour labels';
        messageData['ga.action.card.color-tags.multiple'] = 'Multiple colour labels';
        messageData['ga.action.card.mention'] = 'Mention';

        messageData['ga.action.comment.create'] = 'Comment Created';

        messageData['ga.action.cookie.settings.toggle'] = 'Cookie checkboxes toggle';
        messageData['ga.action.cookie.performance.unchecked'] = 'Performance cookies unchecked';
        messageData['ga.action.cookie.functionality.unchecked'] = 'Functionality cookies unchecked';

        messageData['ga.action.list.create'] = 'List Created';
        messageData['ga.action.list.delete'] = 'List Deleted';
        messageData['ga.action.list.deletion_undone'] = 'List Deletion Undone';
        messageData['ga.action.list.archive'] = 'List Archived from';
        messageData['ga.action.list.archive.nosuccess'] = 'List Archived (no success)';
        messageData['ga.action.list.unarchive'] = 'List Unarchived';

        messageData['ga.action.checklist.item_created'] = 'First item added to checklist';

        messageData['ga.action.dashboard.create'] = 'Board Created';
        messageData['ga.action.dashboard.copy'] = 'Board Copied';
        messageData['ga.action.dashboard.star'] = 'Board added to starred';
        messageData['ga.action.dashboard.delete'] = 'Board Deleted';
        messageData['ga.action.dashboard.archive'] = 'Board Archived';
        messageData['ga.action.dashboard.archive.nosuccess'] = 'Board Archived (no success)';
        messageData['ga.action.dashboard.unarchive'] = 'Board Unarchived';
        messageData['ga.action.dashboard.archive.shared'] = 'Archived board shared';

        messageData['ga.action.create_from_drive'] = 'Created from Drive';
        messageData['ga.action.list.delete'] = 'List Deleted';
        messageData['ga.action.list.print_preview'] = 'List Print Preview Opened';

        messageData['ga.action.user.no_email'] = 'User without email';
        messageData['ga.action.user.second_tab_opened'] = 'Second tab opened';
        messageData['ga.action.user.third_tab_opened'] = 'Third tab opened';
        messageData['ga.action.user.fourth_tab_opened'] = 'Fourth tab opened';

        messageData['ga.action.drive_doc.drag_n_dropped'] = 'File Drag’n’dropped';
        messageData['ga.action.drive_doc.uploaded_via_drive'] = 'File uploaded via Google Drive dialogue';
        messageData['ga.action.drive_doc.linked'] = 'Existing file linked';

        messageData['ga.action.openReadonly'] = 'Opened readonly board';
        messageData['ga.action.activity.page_loaded'] = 'Page loaded: ';
        messageData['ga.action.register'] = 'Registered';
        messageData['ga.action.login'] = 'User logged in';
        messageData['ga.action.loginPro'] = 'Pro User logged in';
        messageData['ga.action.anonymEntered'] = 'Anonym user entered';
        messageData['ga.action.feedback'] = 'Feedback window opened';
        messageData['ga.action.panel.help'] = 'Help (FAQ) panel opened';
        messageData['ga.action.panel.updates'] = 'Updates (Release notes) panel opened';
        messageData['ga.action.filter_opened'] = 'Filter opened';
        messageData['ga.action.filter_created'] = 'Filter added';
        messageData['ga.action.dashboard_design_opened'] = 'Branding tab opened';
        messageData['ga.action.dashboard_backups_opened'] = 'Backups tab opened';
        messageData['ga.action.titles_export_CSV'] = 'Card titles exported to CSV';
        messageData['ga.action.titles_descriptions_export_CSV'] = 'Card titles and descriptions exported to CSV';
        messageData['ga.action.notifications_activated'] = 'Notifications activated';
        messageData['ga.action.push_notifications_activated'] = 'Push notifications activated';
        messageData['ga.action.aside_panel_resized'] = 'Aside panel was resized';
        messageData['ga.action.copy_welcome_board'] = 'Copy welcome board';

        messageData['ga.action.first_welcome_opened'] = 'First visit - Welcome board opened';
        messageData['ga.action.welcome_opened'] = 'Welcome board opened';

        messageData['ga.action.subscription_starter_monthly_clicked'] = 'Premium monthly clicked';
        messageData['ga.action.subscription_starter_yearly_clicked'] = 'Premium yearly clicked';
        messageData['ga.action.subscription_professional_monthly_clicked'] = 'Professional monthly clicked';
        messageData['ga.action.subscription_professional_yearly_clicked'] = 'Professional yearly clicked';
        messageData['ga.action.subscription_enterprise_monthly_clicked'] = 'Enterprise monthly clicked';
        messageData['ga.action.subscription_enterprise_yearly_clicked'] = 'Enterprise yearly clicked';
        messageData['ga.action.subscription_payment_tab_opened'] = 'Payment tab opened';
        messageData['ga.action.subscription_information_tab_opened'] = 'Information tab opened';
        messageData['ga.action.subscription_legacy_subscriptions_link_clicked'] = 'Legacy subscriptions link clicked';

        messageData['ga.action.subscription_form_opened'] = 'Subscription form opened';
        messageData['ga.action.subscription_form_opened_from_open_dashboard_dialog'] = 'Subscription form opened from open board dialog';

        messageData['ga.action.payment_initiated'] = 'Payment initiated - clicked to Pay with PayPal';
        messageData['ga.action.payment_started'] = 'Payment details reviewed - sent to Paypal';
        messageData['ga.action.payment_completed'] = 'Payment completed - returned from Paypal';
        messageData['ga.action.payment_cancelled'] = 'Payment cancelled at Paypal';
        messageData['ga.action.promo_code_applied'] = 'Promo code applied';
        messageData['ga.action.promo_code_form_opened'] = 'Promo code form opened';
        messageData['ga.action.logo_uploaded'] = 'Logo uploaded';
        messageData['ga.action.library_theme_applied'] = 'Library theme applied';
        messageData['ga.action.custom_theme_defined'] = 'Custom theme defined';

        messageData['ga.action.new_user.first_visit.dashboard_created'] = 'First visit - Board created';
        messageData['ga.action.new_user.first_visit.dashboard_created_from_popup'] = 'First visit - Board created from popup';
        messageData['ga.action.new_user.first_visit.dashboard_created_from_drive'] = 'First visit - Board created from drive';
        messageData['ga.action.new_user.first_visit.first_card_created'] = 'First visit - First card created';
        messageData['ga.action.new_user.first_visit.10_cards_created'] = 'First visit - 10 cards created';
        messageData['ga.action.new_user.first_visit.first_list_created'] = 'First visit - First list created';
        messageData['ga.action.new_user.first_visit.first_card_moved'] = 'First visit - First card moved';
        messageData['ga.action.new_user.first_visit.watch_tutorials'] = 'First visit - Video tutorial';
        messageData['ga.action.new_user.first_week.second_login'] = 'First Week - Second login';
        messageData['ga.action.new_user.first_week.3_logins'] = 'First week - 3 logins';
        messageData['ga.action.new_user.first_month.5_logins'] = 'First month - 5 logins';
        messageData['ga.action.new_user.first_month.10_logins'] = 'First month - 10 logins';
        messageData['ga.action.new_user.second_visit.dashboard_opened_from_popup'] = 'Second visit - Second login - Board opened from popup';
        messageData['ga.action.new_user.second_visit.dashboard_opened_from_drive'] = 'Second visit - Second login - Board opened from drive';

        messageData['ga.action.social.facebook_footer'] = 'Facebook button clicked in footer';
        messageData['ga.action.social.twitter_footer'] = 'Twitter button clicked in footer';
        messageData['ga.action.social.google-plus_footer'] = 'Google Plus button clicked in footer';
        messageData['ga.action.social.facebook_help'] = 'Facebook button clicked in help panel';
        messageData['ga.action.social.twitter_help'] = 'Twitter button clicked in help panel';
        messageData['ga.action.social.google-plus_help'] = 'Google Plus button clicked in help panel';

        messageData['ga.action.new_features.facebook_new_features'] = 'Facebook clicked in new features popup';
        messageData['ga.action.new_features.twitter_new_features'] = 'Twitter clicked in new features popup';
        messageData['ga.action.new_features.linkedin_new_features'] = 'linkedin clicked in new features popup';
        messageData['ga.action.new_features.youtube_new_features'] = 'Youtube clicked in new features popup';
        messageData['ga.action.new_features.rate'] = '"Rate the app" link clicked in new features popup';

        messageData['ga.action.gantt_chart.gantt_toggle_clicked'] = 'Gantt Toggle clicked';
        messageData['ga.action.gantt_chart.zoom_is'] = 'Zoom is ';
        messageData['ga.action.gantt_chart.print'] = 'Print button clicked';
        messageData['ga.action.gantt_chart.printFTP'] = 'Print fit to page  ';
        messageData['ga.action.gantt_chart.printCOES'] = 'Print cut off empty space';
        messageData['ga.action.gantt_chart.print_cancel'] = 'Print cancel';
        messageData['ga.action.gantt_chart.print_finished'] = 'Print finished';
        messageData['ga.action.gantt_chart.print_error'] = 'Print error';
        messageData['ga.action.gantt_chart.card_added_autosuggest'] = 'Card added (autosuggest)';
        messageData['ga.action.gantt_chart.card_created_autosuggest'] = 'Card created (autosuggest)';
        messageData['ga.action.gantt_chart.card_added_to_gantt'] = 'Show on Gantt Chart in right panel';
        messageData['ga.action.gantt_chart.cards_added_multiple_select'] = 'Cards added (multiple select)';
        messageData['ga.action.gantt_chart.cards_removed_multiple_select'] = 'Cards removed (multiple select)';
        messageData['ga.action.gantt_chart.sort_type_changed'] = 'Gantt sort type changed to ';
        messageData['ga.action.gantt_chart.active_sort_type'] = 'Active sort type on Gantt is ';

        messageData['ga.action.time_tracker.timing_section_opened'] = 'Timing section opened';
        messageData['ga.action.time_tracker.time_saved'] = 'Time saved';
        messageData['ga.action.time_tracker.time_reset_timetracker_dialog'] = 'Time reset in timetracker dialog';
        messageData['ga.action.time_tracker.time_reset_dashboard_close_dialog'] = 'Time reset in board close dialog';
        messageData['ga.action.time_tracker.time_reset_to_dashboard_dialog'] = 'Time reset in to board dialog';
        messageData['ga.action.time_tracker.comment_with_time_created'] = 'Comment with time created';
        messageData['ga.action.time_tracker.time_format_changed'] = 'Time format changed';

        messageData['ga.action.related_cards.related_cards_saved'] = 'Related cards saved';
        messageData['ga.action.related_cards.related_card_clicked'] = 'Related card clicked';

        messageData['ga.action.dashboard.exported_to_google'] = 'All board data exported to Google Sheets';
        messageData['ga.action.dashboard.exported_timetracker'] = 'Board TimeTracker data exported to Google Sheets';

        messageData['ga.action.backups.restored'] = 'Board restored from Backup';
        messageData['ga.action.backups.created'] = 'Backup created';

        messageData['ga.action.import.click'] = 'Import from Trello clicked';
        messageData['ga.action.import.created'] = 'Import from Trello was successful';

        messageData['ga.action.notifications.panel_opened'] = 'Notification panel opened';
        messageData['ga.action.notifications.filter_active'] = 'Filtered notifications for current board';
        messageData['ga.action.notifications.upgrade_plan'] = 'Upgrade plan in-app notification clicked';
        messageData['ga.action.notifications.mark_all_read'] = 'Clicked "Mark all read" button';
        messageData['ga.action.notifications.group_opened'] = 'Group opened';
        messageData['ga.action.notifications.group_read'] = 'Group marked as read';
        messageData['ga.action.notifications.group_closed'] = 'Group closed';
        messageData['ga.action.notifications.before_due_date_set'] = 'Before the due date set';
        messageData['ga.action.notifications.before_due_date_changed'] = 'Before the due date changed';

        messageData['ga.action.promo.domain_open_dashboard_dialog'] = 'Domain banner board picker';

        messageData['ga.action.subcards.sc.right_panel'] = 'Subcard clicked in right panel';
        messageData['ga.action.subcards.ec.right_panel'] = 'Epic clicked in right panel';
        messageData['ga.action.subcards.sc.created'] = 'New subcard created';
        messageData['ga.action.subcards.sc.existed'] = 'Subcard added from existing cards';
        messageData['ga.action.subcards.level'] = 'Subcard added. Level: ';

        messageData['ga.action.dependencies.remove'] = 'Remove dependency';
        messageData['ga.action.dependencies.add'] = 'Add dependency';
        messageData['ga.action.dependencies.use_link'] = 'Move to dependence by link in aside';
        messageData['ga.action.dependencies.change_type.'] = 'Change dependence type to ';

        messageData['ga.action.boards_templates.added_template'] = 'Added template';
        messageData['ga.action.boards_templates.edit_template'] = 'Edited template';
        messageData['ga.action.boards_templates.mark_as_template'] = 'Board marked as template';
        messageData['ga.action.boards_templates.new_custom_template'] = 'New custom template created';
        messageData['ga.action.boards_templates.remove_template'] = 'Сustom template deleted';
        messageData['ga.action.boards_templates.template_preview'] = 'Preview template clicked';
        messageData['ga.action.boards_templates.use_custom_template'] = 'Board created from custom template';
        messageData['ga.action.boards_templates.use_knb_template'] = 'Board created from our template';

        messageData['ga.action.profile_box.payment_button'] = 'Open payment from profile';
        messageData['ga.action.profile_box.logout'] = 'Profile-box logout clicked';
        messageData['ga.action.profile_box.switch'] = 'Profile-box switch account clicked';
        messageData['ga.action.profile_box.settings'] = 'Profile-box settings clicked';

        messageData['ga.action.profile_box.pricing'] = 'Profile-box Pricing menu clicked';
        messageData['ga.action.profile_box.subscription_manager'] = 'Pricing to manageSubscription link clicked';
        messageData['ga.action.profile_box.rate'] = 'Profile-box rate us clicked';
        messageData['ga.action.profile_box.help'] = 'Profile-box help clicked';
        messageData['ga.action.profile_box.updates'] = 'Profile-box updates clicked';
        messageData['ga.action.profile_box.cookies'] = 'Profile-box cookies clicked';
        messageData['ga.action.profile_box.destroy'] = 'Profile-box Destroy link clicked';

        messageData['ga.action.account.delete'] = 'Delete account started';
        messageData['ga.action.account.deleted'] = 'Account deleted';

        // PRICING
        messageData['ga.action.pricing_app.opened'] = 'Pricing modal opened';
        messageData['ga.action.pricing_app.period_switcher_annually'] = 'Plan period Annually button clicked';
        messageData['ga.action.pricing_app.period_switcher_monthly'] = 'Plan period Monthly button clicked';
        messageData['ga.action.pricing_app.pdf_clicked'] = 'PDF comparison button clicked';
        messageData['ga.action.pricing_app.starter_monthly_pay'] = 'Premium monthly pay button';
        messageData['ga.action.pricing_app.starter_monthly_paypal_pay'] = 'Premium paypal monthly button';
        messageData['ga.action.pricing_app.starter_annually_pay'] = 'Premium annually pay button';
        messageData['ga.action.pricing_app.starter_annually_paypal_pay'] = 'Premium paypal annually button';
        messageData['ga.action.pricing_app.professional_monthly_pay'] = 'Professional monthly button';
        messageData['ga.action.pricing_app.professional_monthly_paypal_pay'] = 'Professional paypal monthly button';
        messageData['ga.action.pricing_app.professional_annually_pay'] = 'Professional annually button';
        messageData['ga.action.pricing_app.professional_annually_paypal_pay'] = 'Professional paypal annually button';
        messageData['ga.action.pricing_app.enterprise_monthly_paypal_pay'] = 'Enterprise paypal monthly button';
        messageData['ga.action.pricing_app.enterprise_annually_paypal_pay'] = 'Enterprise paypal annually button';
        messageData['ga.action.pricing_app.contact_us'] = 'Contact us custom';
        messageData['ga.action.pricing_app.faq_item'] = 'FAQ item opened ';

        //KEYBOARD SHORTCUTS
        messageData['ga.action.keyboard_shortcuts.title'] = 'Keyboard Shortcuts';
        messageData['ga.action.keyboard_shortcuts.card_and_lists.title'] = 'Cards & Lists';
        messageData['ga.action.keyboard_shortcuts.card_and_lists.create_card'] = 'Creat a new card';
        messageData['ga.action.keyboard_shortcuts.card_and_lists.print_card'] = 'Print card';
        messageData['ga.action.keyboard_shortcuts.card_and_lists.delete_card'] = 'Delete card';
        messageData['ga.action.keyboard_shortcuts.card_and_lists.copy_card'] = 'Copy selected card';
        messageData['ga.action.keyboard_shortcuts.card_and_lists.paste_card'] = 'Paste selected card';
        messageData['ga.action.keyboard_shortcuts.card_and_lists.create_list'] = 'Creat new list';
        messageData['ga.action.keyboard_shortcuts.card_and_lists.multiselect'] = 'Select multiple cards';
        messageData['ga.action.keyboard_shortcuts.card_and_lists.navigate_in'] = 'Navigate by cards in a list';
        messageData['ga.action.keyboard_shortcuts.card_and_lists.navigate_between'] = 'Navigate by cards between a lists';
        messageData['ga.action.keyboard_shortcuts.card_and_lists.move_up'] = 'Move selected card up';
        messageData['ga.action.keyboard_shortcuts.card_and_lists.move_down'] = 'Move selected card down';
        messageData['ga.action.keyboard_shortcuts.text_inputs.title'] = 'Text Input';
        messageData['ga.action.keyboard_shortcuts.text_inputs.confirm'] = 'Confirm text input';
        messageData['ga.action.keyboard_shortcuts.text_inputs.exit'] = 'Exit text input';
        messageData['ga.action.keyboard_shortcuts.global.title'] = 'General';
        messageData['ga.action.keyboard_shortcuts.global.focus'] = 'Search';
        messageData['ga.action.keyboard_shortcuts.global.open'] = 'Open Filter panel';
        messageData['ga.action.keyboard_shortcuts.global.close'] = 'Close right panel';
        messageData['ga.action.keyboard_shortcuts.global.collapse'] = 'Collapse/expand Sidebar';
        messageData['ga.action.keyboard_shortcuts.global.share'] = 'Share board';
        messageData['ga.action.keyboard_shortcuts.text_formatting.title'] = 'Text Formatting';
        messageData['ga.action.keyboard_shortcuts.text_formatting.bold'] = 'Bold';
        messageData['ga.action.keyboard_shortcuts.text_formatting.italics'] = 'Italics';
        messageData['ga.action.keyboard_shortcuts.text_formatting.underline'] = 'Underline';
        messageData['ga.action.keyboard_shortcuts.text_formatting.strikethrough'] = 'Strikethrough';
        messageData['ga.action.keyboard_shortcuts.text_formatting.monospace'] = 'Monospace';
        messageData['ga.action.keyboard_shortcuts.text_formatting.hyperlink'] = 'Hyperlink';

        // BLOCKERS
        messageData['ga.action.blockers.after_trial'] = 'Blocker after trial showed';
        messageData['ga.action.blockers.manager'] = 'Blocker for manager showed';
        messageData['ga.action.blockers.manager_renew'] = 'Blocker for manager renew clicked';
        messageData['ga.action.blockers.user'] = 'Blocker for user showed';
        messageData['ga.action.blockers.user_pricing'] = 'Blocker for user pricing clicked';
        messageData['ga.action.blockers.noactive'] = 'Blocker for manager if no active showed';
        messageData['ga.action.blockers.noactive_manage'] = 'Blocker for manager if no active manage clicked';
        messageData['ga.action.blockers.noactive_help'] = 'Blocker for manager if no active help clicked';
        messageData['ga.action.blockers.archive.board'] = 'Archived board open attempt (plan restriction)';
        messageData['ga.action.blockers.archive.card'] = 'Archived card open attempt (plan restriction)';

        messageData['ga.action.helpUsPanel.opened'] = 'Help us popup opened';
        messageData['ga.action.helpUsPanel.sent'] = 'Help us popup sent';

        //SUBSCRIPTION MANAGER
        messageData['ga.action.subscription_management.add_seat'] = 'Seat added'; //thunkAddMembers
        messageData['ga.action.subscription_management.removed_seat'] = 'Removed seat'; // finalTimeout
        messageData['ga.action.subscription_management.added_multiple_seat'] = 'Added multiple seats'; //thunkAddMembers
        messageData['ga.action.subscription_management.apply_editing'] = 'Applied editing seat'; //thunkConfirmDoneCancel
        messageData['ga.action.subscription_management.feedback'] = 'Manage Subscription feedback pressed'; //thunkGetSupportClicked
        messageData['ga.action.subscription_management.manage'] = '"Manage subscription" clicked'; //thunkOpenChargeBeeWindow
        messageData['ga.action.subscription_management.search'] = 'Seat search'; //selectItems
        // messageData['ga.action.subscription_management.buy_more'] = 'Buy more clicked';
        messageData['ga.action.subscription_management.pricing_link'] = 'Go to Pricing from Manage Subscription'; //thunkGotoPricingClicked
        messageData['ga.action.subscription_management.switch_subscription'] = 'Switched active subscription'; //thunkSelectSubscription

        messageData['ga.info.user.ru'] = 'User has russian language';
        messageData['ga.info.user.russia'] = 'User from Russia';
        messageData['ga.info.user.saratov_region'] = 'User from Saratov region';
        messageData['ga.info.user.saratov'] = 'User from Saratov';
        messageData['ga.info.user.timezone.moscow'] = 'User has timezone like Moscow';

        messageData['ga.action.snackbar.upgrade'] = 'Upgrade button in UpgradePlan snackbar clicked';
        messageData['ga.category.reports'] = 'Reports';

        // main

        messageData['ga.action.reports.reports_clicked'] = 'Report view toggle clicked';

        // opened

        messageData['ga.action.cumulative.report.opened_from_dashboard'] = 'Cumulative opened from dashboard';
        messageData['ga.action.cumulative.report.opened_from_insights'] = 'Cumulative opened from Insights';

        messageData['ga.action.burndown.report.opened_from_dashboard'] = 'Burndown opened from dashboard';
        messageData['ga.action.burndown.report.opened_from_insights'] = 'Burndown opened from Insights';

        messageData['ga.action.teamPerformance.report.opened_from_dashboard'] = 'TeamPerformance opened from dashboard';
        messageData['ga.action.teamPerformance.report.opened_from_insights'] = 'TeamPerformance opened from Insights';
        messageData['ga.action.userProductivity.report.opened_from_insights'] = 'UserProductivity opened from Insights';
        messageData['ga.action.userProductivity.report.opened_from_dashboard'] = 'UserProductivity opened from dashboard';

        messageData['ga.action.burnup.report.opened_from_dashboard'] = 'Burnup opened from dashboard';
        messageData['ga.action.burnup.report.opened_from_insights'] = 'Burnup opened from Insights';

        // cumulative filters

        messageData['ga.action.cumulative.report_printed'] = 'Cumulative flow printed';
        messageData['ga.action.cumulative.report_filter'] = 'Cumulative flow - filter applied';
        messageData['ga.action.cumulative.report_lists'] = 'Cumulative flow - lists edited';
        messageData['ga.action.cumulative.report_dates'] = 'Cumulative flow - dates edited';

        // burndown filters

        messageData['ga.action.burndown.report.report_printed'] = 'Burndown chart printed';
        messageData['ga.action.burndown.report.report_filter'] = 'Burndown chart - filter applied';
        messageData['ga.action.burndown.report.report_ideal'] = 'Burndown chart - ideal line hide';
        messageData['ga.action.burndown.report.report_lists'] = 'Burndown chart - lists edited';
        messageData['ga.action.burndown.report.report_dates'] = 'Burndown chart - dates edited';
        messageData['ga.action.burndown.report.report_done_cards'] =  'Burndown chart - done status change';
        messageData['ga.action.burndown.report.report_done_list'] = 'Burndown chart - done list changed';

        // teamPerformance filters
        messageData['ga.action.teamPerformance.report.report_printed'] = 'TeamPerformance chart printed';
        messageData['ga.action.teamPerformance.report.report_filter'] = 'TeamPerformance chart - filter applied';
        messageData['ga.action.teamPerformance.report.report_users'] = 'TeamPerformance chart - users list edited';
        messageData['ga.action.teamPerformance.report.report_dates'] = 'TeamPerformance chart - Period edited';
        messageData['ga.action.teamPerformance.report.report_detailed'] = 'TeamPerformance chart - detailed view';
        messageData['ga.action.teamPerformance.report.report_summary'] = 'TeamPerformance chart - summary view';

        // userProductivity filters
        messageData['ga.action.userProductivity.report.report_filter'] = 'UserProductivity chart - filter applied';
        messageData['ga.action.userProductivity.report.report_users'] = 'UserProductivity chart - users list edited';
        messageData['ga.action.userProductivity.report.report_dates'] = 'UserProductivity chart - Period edited';
        messageData['ga.action.userProductivity.report.user_stat_opened'] = 'user statistic opened';


        // burnup filters

        messageData['ga.action.burnup.report.report_printed'] = 'Burnup chart printed';
        messageData['ga.action.burnup.report.report_filter'] = 'Burnup chart - filter applied';
        messageData['ga.action.burnup.report.report_ideal'] = 'Burnup chart - ideal line hide';
        messageData['ga.action.burnup.report.report_lists'] = 'Burnup chart - lists edited';
        messageData['ga.action.burnup.report.report_dates'] = 'Burnup chart - dates edited';
        messageData['ga.action.burnup.report.report_done_cards'] =  'Burnup chart - done status change';
        messageData['ga.action.burnup.report.report_done_list'] = 'Burnup chart - done list changed';

        // timezones

        messageData['ga.action.time_zone.time_zone_changed_burndown'] = 'Time Zone changed from Burndown chart';
        messageData['ga.action.time_zone.time_zone_changed_cumulative'] = 'Time Zone changed from Cumulative flow';
        messageData['ga.action.time_zone.time_zone_changed_teamPerformance'] = 'Time Zone changed from teamPerformance chart';
        messageData['ga.action.time_zone.time_zone_changed_burnup'] = 'Time Zone changed from Burnup chart';
        messageData['ga.action.time_zone.time_zone_changed_userProductivity'] = 'Time Zone changed from userProductivity';

        // Filter Panel

        messageData['fp.header.title'] = 'Filter options';
        messageData['fp.header.title.mobile'] = 'Search & Filter';
        messageData['fp.filter.name.default'] = 'Untitled filter';
        messageData['fp.footer.reset_button_title'] = 'Reset filter';
        messageData['fp.footer.apply_button_title'] = 'Apply filter';
        messageData['fp.header.save_button_title'] = 'Save Filter';
        messageData['fp.header.cancel_button_title'] = 'Cancel';
        messageData['fp.search.recent_list_title'] = 'Recent:';
        messageData['fp.saved_filters_list.title'] = 'Saved filters';
        messageData['fp.saved_filters_list.title.edit'] = 'Edit filter';
        messageData['fp.saved_filters_list.title.add'] = 'Create filter';
        messageData['fp.saved_filters_list.description'] = `Create, use, edit, delete, or change the order of the saved filers.`;
        messageData['fp.saved_filters_list.shown_on_bar.title'] = 'Show on the filter bar';
        messageData['fp.saved_filters_list.other.description'] = 'Drag saved filters here to hide them from the bar ';
        messageData['fp.saved_filters_list.other.title'] = 'Hide from the filter bar';
        messageData['fp.saved_filters_list.public'] = 'Make available to all members of this board';
        messageData['fp.saved_filters_list.public.tooltip'] = 'Public filter, author: ';
        messageData['fp.custom_filters.title'] = 'Custom filters';
        messageData['fp.custom_filters.description'] = `Create a one-time filter or save it to use later.`;
        messageData['fp.add_new_filter_button.title'] = 'Create filter';
        messageData['fp.select_user_button.title'] = 'Select user';
        messageData['fp.tags_filter.title'] = 'Tags';
        messageData['fp.tags_filter.add_tags_button_title'] = 'Add tags';
        messageData['fp.date_filter.title_created'] = 'Created';
        messageData['fp.date_filter.title_done'] = 'Done';
        messageData['fp.date_filter.title_start'] = 'Start';
        messageData['fp.date_filter.title_due'] = 'Due';
        messageData['fp.date_filter.title_due_date'] = 'Due date';
        messageData['fp.date_filter.button'] = 'Add condition';
        messageData['fp.date_filter.from'] = 'from';
        messageData['fp.date_filter.from_to'] = 'from → to';
        messageData['fp.date_filter.days_before'] = 'days before now';
        messageData['fp.date_filter.days_after'] = 'days since now';
        messageData['fp.date_filter.days_number'] = 'Number of days';
        messageData['fp.days_before.title'] = 'or days before now';
        messageData['fp.days_after.title'] = 'or days since now';
        messageData['fp.color_tags_filter.title'] = 'Colour labels';
        messageData['fp.card_color_filter.title'] = 'Card colour';
        messageData['fp.list_color_filter.title'] = 'List colour';
        messageData['fp.hide_filter.title'] = 'Hide';
        messageData['fp.show_filter.title'] = 'Show';
        messageData['fp.priority_filter.title'] = 'Priority';
        messageData['fp.sort_filter.title'] = 'Sort by';
        messageData['fp.comments_filter.title'] = 'Comments';
        messageData['fp.attachments_filter.title'] = 'Attachments';
        messageData['fp.dates.title'] = 'Dates';
        messageData['fp.assignees.title'] = 'Assignees';
        messageData['fp.roles_filter.title'] = 'Roles';
        messageData['fp.boards_filter.title'] = 'Boards';
        messageData['fp.cards_filter.title'] = 'Cards';

        messageData['fp.info_panel.title.filter'] = 'Filter';
        messageData['fp.info_panel.title.applied'] = 'applied';
        messageData['fp.info_panel.card'] = 'card';
        messageData['fp.info_panel.cards'] = 'cards';
        messageData['fp.info_panel.found'] = 'found';
        messageData['fp.counter.tooltip.card'] = '{0} card matching filter';
        messageData['fp.counter.tooltip.cards'] = '{0} cards matching filter';

        messageData['fp.summary.hidden_filter.tooltip'] = 'Show hidden cards';
        messageData['fp.summary.hidden_filter.card'] = 'Include {0} hidden card';
        messageData['fp.summary.hidden_filter.cards'] = 'Include {0} hidden cards';
        messageData['fp.summary.archived_filter.tooltip'] = 'Show archived cards';
        messageData['fp.summary.archived_filter.card'] = 'Include {0} archived card';
        messageData['fp.summary.archived_filter.cards'] = 'Include {0} archived cards';

        messageData['fp.boards_filter.desc'] = 'By default, filters are looking for cards from the last 10 days edited boards. To add older boards select them in the dropdown.';

        // Help Panel
        messageData['help_panel.header.title'] = 'Help';
        messageData['help_panel.links.faq'] = 'FAQ';
        messageData['help_panel.links.video'] = 'Video tutorials';
        messageData['help_panel.links.privacy_policy'] = 'Privacy policy';
        messageData['help_panel.links.terms_conditions'] = 'Terms & conditions';
        messageData['help_panel.links.cookie'] = 'Cookie policy';
        messageData['help_panel.links.affiliate'] = 'Referral & Affiliate';
        messageData['help_panel.links.invite'] = 'Invite friends';
        messageData['help_panel.links.release'] = 'Release notes';
        messageData['help_panel.links.accessibility'] = 'How accessible is Kanbanchi?';

        // OPEN BOARDS
        messageData['open_boards.title'] = 'Boards list';
        messageData['open_boards.import'] = 'Import board from';
        messageData['open_boards.import.how'] = 'How?';
        messageData['open_boards.import.how_url'] = 'https://www.kanbanchi.com/faqwd/how-can-i-import-data-to-kanbanchi';
        messageData['open_boards.import.title'] = 'Import';
        messageData['open_boards.import.button.text.csv'] = 'Choose csv file';
        messageData['open_boards.import.button.text.jira'] = 'Choose jira file';
        messageData['open_boards.import.button.text.trello'] = 'Choose trello file';
        messageData['open_boards.import.learn.more.link'] = 'Learn more';
        messageData['open_boards.import.text.csv'] = 'A CSV file can be created and edited using any text editor. Additionally, you may export an Excel sheet or a Google Spreadsheet to create a CSV file and subsequently import it to Kanbanchi.';
        messageData['open_boards.import.text.jira'] = "In case you’d like to import your Jira data to Kanbanchi, please, choose a file with your Jira board export.";
        messageData['open_boards.import.text.trello'] = 'Are you a Trello user curious to try out Kanbanchi? To ease the transition, you can import Trello Boards to Kanbanchi. Choose a file with your Trello board export.';
        messageData['open_boards.import.title.csv'] = 'CSV';
        messageData['open_boards.import.title.jira'] = 'Jira';
        messageData['open_boards.import.title.trello'] = 'Trello';
        messageData['open_boards.import.url.csv'] = 'https://www.kanbanchi.com/faqwd/how-can-i-import-a-csv-file-to-kanbanchi';
        messageData['open_boards.import.url.jira'] = 'https://www.kanbanchi.com/faqwd/how-can-i-import-my-jira-data-to-kanbanchi';
        messageData['open_boards.import.url.trello'] = 'https://www.kanbanchi.com/faqwd/how-can-i-import-my-trello-boards-to-kanbanchi';

        messageData['open_boards.tips_and_tricks_button_text'] = 'Open Kanbanchi tips and tricks';
        messageData['open_boards.button.create_board'] = 'Create board';
        messageData['open_boards.button.open'] = 'Open';
        messageData['open_boards.button.board'] = 'board';
        messageData['open_boards.button.boards'] = 'boards';
        messageData['open_boards.button.how_it_works'] = 'How it works';
        messageData['open_boards.restore_xml'] = 'Restore board from backup';
        messageData['open_boards.restore_xml.how'] = 'How?';

        messageData['open_boards.tab.recent'] = 'Recent boards';
        messageData['open_boards.tab.all_boards'] = 'All boards';
        messageData['open_boards.tab.shared_drives'] = 'Shared drives';
        messageData['open_boards.tab.starred'] = 'Starred';
        messageData['open_boards.tab.archive'] = 'Archive';
        messageData['open_boards.tab.templates'] = 'Templates';

        messageData['open_boards.board.starred_tooltip'] = 'Favourite';
        messageData['open_boards.board.opened_mark'] = 'Opened';
        messageData['open_boards.board.delete_tooltip'] = 'Delete board';
        messageData['open_boards.board.moveto_tooltip'] = 'Move board';
        messageData['open_boards.board.hide_recent_tooltip'] = 'Hide from recent';

        messageData['open_boards.drive.my_drive'] = 'My Drive';
        messageData['open_boards.drive.shared_with_me'] = 'Shared with me';
        messageData['open_boards.drive.recent_boards'] = 'Recent Boards';

        messageData['open_boards.empty.title'] = 'No boards yet...';
        messageData['open_boards.empty.text'] = 'Create them in your Google Drive';
        messageDataMS['open_boards.empty.text'] = 'Create them in your OneDrive';
        messageData['open_boards.empty.sharedDrives.title'] = 'No Shared Drives yet...';
        messageData['open_boards.empty.sharedDrives.text'] = 'Contact your Google Workspace administrator to enable them';
        messageDataMS['open_boards.empty.sharedDrives.text'] = 'Contact your SharePoint administrator to enable them';
        messageData['open_boards.empty.starred.title'] = 'No favourite boards yet...';
        messageData['open_boards.empty.starred.text'] = 'Starred boards will appear here';
        messageData['open_boards.empty.archive.title'] = 'No archived boards yet...';
        messageData['open_boards.empty.archive.text'] = 'Archived boards will appear here';
        messageData['open_boards.empty.search.title'] = 'No boards found...';
        messageData['open_boards.empty.search.text'] = 'Try another search query';

        messageData['open_boards.blocker.sharedDrives.title'] = 'Shared Drives integration is a part of Enterprise plan';

        messageData['open_boards.blocker.sharedDrives.text'] = 'Create and work on Kanbanchi boards within Shared Drives. Move your boards from Google Drive to Shared Drives';
        messageDataMS['open_boards.blocker.sharedDrives.text'] = 'Create and work on Kanbanchi boards within Shared Drives. Move your boards from OneDrive to Shared Drives';

        messageData['open_boards.blocker.permissions.sharedDrives.text'] = 'Give permissions to integrate Kanbanchi with your Shared Drives and create boards in any of them';
        messageDataMS['open_boards.blocker.permissions.sharedDrives.text'] = 'Boards in Shared Drives will automatically be accessible by everyone in a Team. To use Shared Drives, the administrator should grant permissions to read information from Teams that you are part of. Copy this link to send it to your administrator.';

        messageData['open_boards.blocker.templates.title'] = 'Boards templates integration';
        messageData['open_boards.blocker.templates.text'] = 'Speed up your work process! Choose any of the board templates we have prepared for you or create your custom templates.';

        messageData['open_boards.snackbar.limit_exceeded'] = 'Sorry, you can’t open more than 10 boards in one browser page.<br>Please unselect some boards and then open them in a new browser tab.';
        messageData['open_boards.snackbar.delete.title'] = 'Confirm deletion';
        messageData['open_boards.snackbar.delete.text'] = 'Are you sure you want to delete this board?';
        messageData['open_boards.snackbar.delete_shared.title'] = 'Confirm deletion';
        messageData['open_boards.snackbar.delete_shared.text'] = 'Are you sure you want to delete this board for everyone?';
        messageData['open_boards.snackbar.delete_disallow.title'] = 'Contact owner';
        messageData['open_boards.snackbar.delete_disallow.text'] = 'You don’t have permissions to delete this board';

        messageData['open_boards.snackbar.delete.success.text'] = 'This board is moved to Google Drive trash and will be deleted in 30 days';
        messageDataMS['open_boards.snackbar.delete.success.text'] = 'This board is moved to OneDrive Recycle bin and will be deleted in 30 days';

        messageData['open_boards.snackbar.move.promt.title'] = 'We will move your board';
        messageData['open_boards.snackbar.move.promt.text'] = 'Please, don’t close this page during the process to avoid data loss';
        messageData['open_boards.snackbar.move.progress.title'] = 'We are moving your board';
        messageData['open_boards.snackbar.move.progress.text'] = 'It may take some time';
        messageData['open_boards.snackbar.move.success.title'] = 'Board ’{0}’ has been moved';
        messageData['open_boards.snackbar.move.success.text'] = 'To {0}';
        messageData['open_boards.snackbar.trello.progress.text'] = 'Import in progress';
        messageData['open_boards.snackbar.trello.error.text'] = 'Can’t complete Trello board import';
        messageData['open_boards.snackbar.restore_from_xml.progress.text'] = 'Restoring in progress';
        messageData['open_boards.snackbar.restore_from_xml.error.text'] = 'Can’t complete restoring board from xml';
        messageData['open_boards.snackbar.jira.progress.text'] = 'Import in progress';
        messageData['open_boards.snackbar.jira.error.text'] = 'Can’t complete Jira board import';
        messageData['open_boards.snackbar.csv.progress.text'] = 'Import in progress';
        messageData['open_boards.snackbar.csv.error.text'] = 'Can’t complete csv board import';
        messageData['open_boards.snackbar.pwc.progress.text'] = 'Import in progress';
        messageData['open_boards.snackbar.pwc_template_checklists.progress.text'] = 'Import in progress';
        messageData['open_boards.snackbar.pwc.error.text'] = 'Can’t complete pwc board import';

        messageData['open_boards.templates.no_description_text'] = 'No description';
        messageData['open_boards.templates.shared'] = 'Shared with me';
        messageData['open_boards.templates.shared-drive'] = 'On a Shared drive: "{0}"';
        messageData['open_boards.templates.custom_template'] = 'custom template';
        messageData['open_boards.templates.custom_templates'] = 'custom templates';
        messageData['open_boards.templates.default_template'] = 'default template';
        messageData['open_boards.templates.default_templates'] = 'default templates';
        messageData['open_boards.templates.edu_template'] = 'edu template';
        messageData['open_boards.templates.edu_templates'] = 'edu templates';
        messageData['open_boards.templates.no'] = 'No';

        // BOARDS_LIST GA EVENTS

        messageData['ga.action.boards_list.tab.starred'] = 'List of Starred Boards';
        messageData['ga.action.boards_list.tab.templates'] = 'Template tab opened';
        messageData['ga.action.boards_list.load_fail'] = 'Boards loading fail';
        messageData['ga.action.boards_list.template_load_fail'] = 'Templates loading fail';
        messageData['ga.action.boards_list.create_fail'] = 'Board creating fail';
        messageData['ga.action.boards_list.tricks'] = 'Tips&Tricks';
        messageData['ga.action.boards_list.opened'] = 'Opened via kanbanchi open dialog';
        messageData['ga.action.boards_list.created_within_shared_drive'] = 'Created within Shared Drives';
        messageData['ga.action.boards_list.moved_between_shared_drives'] = 'Moved between Shared Drives';
        messageData['ga.action.boards_list.moved_from_my_to_shared_drive'] = 'Moved from My Drive to Shared Drives';
        messageData['ga.action.boards_list.moved_from_shared_drive_to_my'] = 'Moved from Shared Drives to My Drive';

        // LIST_VIEW

        messageData['list_view.title'] = 'List view';

        messageData['list_view.sort_type.chronological'] = 'Chronological';
        messageData['list_view.sort_type.manual'] = 'Manual';
        messageData['list_view.group_epics_toggler'] = 'Group subcards with Epics';
        messageData['list_view.reset_button'] = 'Reset settings';
        messageData['list_view.settings_button'] = 'Settings';
        messageData['list_view.groups_collapse_toggler.collapse'] = 'Collapse all groups';
        messageData['list_view.groups_collapse_toggler.expand'] = 'Expand all groups';

        messageData['list_view.group_collapse_toggler.collapse'] = 'Collapse subcards';
        messageData['list_view.group_collapse_toggler.expand'] = 'Expand subcards';

        messageData['list_view.card_field.title'] = 'Card title';
        messageData['list_view.card_field.description'] = 'Card description';
        messageData['list_view.card_field.progress'] = 'Progress';
        messageData['list_view.card_field.priority'] = 'Priority';
        messageData['list_view.card_field.list'] = 'List';
        messageData['list_view.card_field.due_date'] = 'Due date';
        messageData['list_view.card_field.start_date'] = 'Start date';
        messageData['list_view.card_field.estimate'] = 'Estimate';
        messageData['list_view.card_field.spent_time'] = 'Spent time';
        messageData['list_view.card_field.related_cards'] = 'Related';
        messageData['list_view.card_field.checklist'] = 'Checklist';
        messageData['list_view.card_field.attachments'] = 'Files';
        messageData['list_view.card_field.comments'] = 'Comments';
        messageData['list_view.card_field.assignees'] = 'Assignees';
        messageData['list_view.card_field.tags'] = 'Tags';

        messageData['list_view.mobile.group_epics_toggler'] = 'Group Epics';
        messageData['list_view.mobile.groups_collapse_toggler.collapse'] = 'Collapse all';
        messageData['list_view.mobile.groups_collapse_toggler.expand'] = 'Expand all';

        messageData['list_view.add_card_button'] = 'Create new card';
        messageData['list_view.add_card_placeholder'] = 'Hit Enter to add a card';

        // LIST_VIEW GA EVENTS

        messageData['ga.category.list_view'] = 'List View';
        messageData['ga.action.list_view.clicked'] = 'List View clicked';
        messageData['ga.action.list_view.toggle.success'] = 'List View toggle clicked (success)';
        messageData['ga.action.list_view.toggle.nosuccess'] = 'List View toggle clicked (no success)';

        messageData['ga.action.list_view.sort.title']         = 'Sorted by card name';

        messageData['ga.action.list_view.sort.description']   = 'Sorted by description';
        messageData['ga.action.list_view.show.description']   = 'Checked description';
        messageData['ga.action.list_view.hide.description']   = 'Unchecked description';

        messageData['ga.action.list_view.sort.priority']      = 'Sorted by priority';
        messageData['ga.action.list_view.show.priority']      = 'Checked Priority';
        messageData['ga.action.list_view.hide.priority']      = 'Unchecked Priority';

        messageData['ga.action.list_view.sort.progress']      = 'Sorted by card progress';
        messageData['ga.action.list_view.show.progress']      = 'Checked Progress';
        messageData['ga.action.list_view.hide.progress']      = 'Unchecked Progress';

        messageData['ga.action.list_view.sort.list']          = 'Sorted by list name';
        messageData['ga.action.list_view.show.list']          = 'Checked List name';
        messageData['ga.action.list_view.hide.list']          = 'Unchecked List name';

        messageData['ga.action.list_view.sort.tags']          = 'Sorted by tags';
        messageData['ga.action.list_view.show.tags']          = 'Checked Tags';
        messageData['ga.action.list_view.hide.tags']          = 'Unchecked Tags';

        messageData['ga.action.list_view.sort.startDate']     = 'Sorted by start date';
        messageData['ga.action.list_view.show.startDate']     = 'Checked Start date';
        messageData['ga.action.list_view.hide.startDate']     = 'Unchecked Start date';

        messageData['ga.action.list_view.sort.dueDate']       = 'Sorted by due date';
        messageData['ga.action.list_view.show.dueDate']       = 'Checked Due date';
        messageData['ga.action.list_view.hide.dueDate']       = 'Unchecked Due date';

        messageData['ga.action.list_view.sort.estimate']      = 'Sorted by estimate';
        messageData['ga.action.list_view.show.estimate']      = 'Checked Estimate';
        messageData['ga.action.list_view.hide.estimate']      = 'Unchecked Estimate';

        messageData['ga.action.list_view.sort.spentTime']     = 'Sorted by spent time';
        messageData['ga.action.list_view.show.spentTime']     = 'Checked Spent time';
        messageData['ga.action.list_view.hide.spentTime']     = 'Unchecked Spent time';

        messageData['ga.action.list_view.sort.relatedCards']  = 'Sorted by related cards';
        messageData['ga.action.list_view.show.relatedCards']  = 'Checked Related card';
        messageData['ga.action.list_view.hide.relatedCards']  = 'Unchecked Related card';

        messageData['ga.action.list_view.sort.checklist']     = 'Sorted by checklists';
        messageData['ga.action.list_view.show.checklist']     = 'Checked Checklist progress';
        messageData['ga.action.list_view.hide.checklist']     = 'Unchecked Checklist progress';

        messageData['ga.action.list_view.sort.attachments']   = 'Sorted by attachments';
        messageData['ga.action.list_view.show.attachments']   = 'Checked Attachments';
        messageData['ga.action.list_view.hide.attachments']   = 'Unchecked Attachments';

        messageData['ga.action.list_view.sort.comments']      = 'Sorted by comments';
        messageData['ga.action.list_view.show.comments']      = 'Checked Comments';
        messageData['ga.action.list_view.hide.comments']      = 'Unchecked Comments';

        messageData['ga.action.list_view.sort.assignees']     = 'Sorted by assignments';
        messageData['ga.action.list_view.show.assignees']     = 'Checked Assigned to';
        messageData['ga.action.list_view.hide.assignees']     = 'Unchecked Assigned to';

        messageData['ga.action.list_view.group.on']           = 'Checked Group subcards';
        messageData['ga.action.list_view.group.off']          = 'Unchecked Group subcards';

        messageData['ga.action.list_view.sortType.chronological']    = 'Sorted Chronological';
        messageData['ga.action.list_view.sortType.manual']    = 'Sorted Manually';

        messageData['ga.action.list_view.groups.collapse']    = 'Clicked Collapse all groups';
        messageData['ga.action.list_view.groups.expand']      = 'Clicked Expand All Groups';
        messageData['ga.action.list_view.group.collapse']     = 'Clicked Collapse subcards';
        messageData['ga.action.list_view.group.expand']       = 'Clicked Expand subcards';

        messageData['ga.action.list_view.reset']              = 'Clicked Reset all settings';
        messageData['ga.action.list_view.print']              = 'Clicked Print List view';
        messageData['ga.action.list_view.drag']               = 'Used Drag&Drop';

        // NAV_PANEL

        messageData['nav_panel.no_boards.title'] = 'No boards are opened';
        messageData['nav_panel.no_boards.text'] = 'Click the Browse boards button on the left panel to open a board.';
        messageData['nav_panel.no_boards.open_board'] = 'Open board: "{0}"';
        messageData['nav_panel.no_boards.open_card'] = 'Open card: "{0}"';
        messageData['nav_panel.boards.close'] = 'Close board';
        messageData['nav_panel.boards.current'] = 'Current board';
        messageData['nav_panel.boards.switch'] = 'Switch to';
        messageData['nav_panel.boards.add'] = 'Create board';
        messageData['nav_panel.boards.create_on_my_drive'] = 'On My drive';
        messageData['nav_panel.boards.create_from_template'] = 'From template';
        messageData['nav_panel.boards.create_shared_drive'] = 'On a Shared drive';
        messageData['nav_panel.boards.open'] = 'Browse boards';
        messageData['nav_panel.boards.import'] = 'Import board';
        messageData['nav_panel.list_grouping'] = 'List grouping';
        messageData['nav_panel.swimlane'] = 'Swimlanes';
        messageData['nav_panel.notifications_count_tooltip'] = 'Open notifications';

        // RECENT_BOARDS_PANEL

        messageData['recent_panel.boards.title'] = 'Recent boards';
        messageData['recent_panel.boards.close'] = 'Hide from Recent';
        messageData['recent_panel.boards.open'] = 'Open';
        messageData['recent_panel.boards.nostarred'] = 'No starred';

        // TOP_BAR

        messageData['top_bar.board.settings'] = 'Board settings';
        messageData['top_bar.board.activity'] = 'Board activity';
        messageData['top_bar.board.rename'] = 'Rename board';
        messageData['top_bar.board.change_logo'] = 'Change logo';
        messageData['top_bar.board.add_to_starred'] = 'Add to Starred';
        messageData['top_bar.board.remove_from_starred'] = 'Remove from Starred';
        messageData['top_bar.board.close'] = 'Close board';
        messageData['top_bar.filter.mobile'] = 'Search & Filter';
        messageData['top_bar.filter.tooltip'] = 'Filter options';
        messageData['top_bar.filter.close.tooltip'] = 'Close filter';
        messageData['top_bar.board.card_templates'] = 'Card templates';
        messageData['top_bar.board.recurring_tasks'] = 'Recurring cards';
        messageData['top_bar.board.my_work.group_by.label'] = 'Group by: ';

        messageData['top_bar.stat.mytasks'] = 'Assigned to me';
        messageData['top_bar.stat.done'] = 'Done';
        messageData['top_bar.stat.overdue'] = 'Overdue';

        messageData['top_bar.in_work_card.tooltip'] = 'Working on card';

        messageData['share_dialog_hint_text'] = `<p>When you share a board with others, there might be a delay in Google granting access.</p>
        <p>For that reason you might not be able to assign a card to a new user strait away. Just wait for a few seconds and try again!</p>`;
        messageData['share_dialog_hint_button_dont_show'] = 'Do not show again';

        messageData['share_dialog_title'] = 'Share board';
        messageData['share_dialog_people_with_access'] = 'People with access';
        messageData['share_dialog.input.placeholder'] = 'Add people';


        // HINTS

        messageData['hint.board_settings'] = 'Tune your board here';
        messageData['hint.board_settings_for_old_users'] = 'Board settings are now here. Feel free to tune your board';

        messageData['hint.opened_last_board'] = 'You are redirected to your last used board. You may configure this setting in the user menu.';
        messageData['hint.opened_last_board.button_settings'] = 'Open settings';

        messageData['hint.card_link'] = 'Get a link to a card to share with <br>your teammates';

        messageData['hint.board_scroll'] = 'Click the current board title to <br>fast scroll to the first list';

        // ASIDE_PANEL

        messageData['aside_panel.tab.card_details'] = 'Card details';
        messageData['aside_panel.tab.board_details'] = 'Board details';
        messageData['aside_panel.tab.comments'] = 'Comments';
        messageData['aside_panel.tab.activity'] = 'Activity';
        messageData['aside_panel.tab.attachments'] = 'Attachments';
        messageData['aside_panel.tab.timing'] = 'Timing';
        messageData['aside_panel.tab.branding'] = 'Appearance';
        messageData['aside_panel.tab.backups'] = 'Backups';
        messageData['aside_panel.tab.export'] = 'Export';
        messageData['aside_panel.tab.atm.import'] = 'Cards/Boards';
        messageData['aside_panel.tab.atm.settings'] = 'Settings';
        messageData['aside_panel.tab.atm.background'] = 'Background';

        messageData['aside_panel.snackbar.delete'] = 'You can delete card with Shift + Delete';
        messageData['aside_panel.snackbar.delete.mac'] = 'You can delete card with ⌘ + Delete';

        messageData['aside_panel.actions.minimize'] = 'Minimize panel';
        messageData['aside_panel.actions.expand'] = 'Expand panel';
        messageData['aside_panel.actions.actions'] = 'Actions';
        messageData['aside_panel.actions.collaborate'] = 'Collaborate';
        messageData['aside_panel.actions.organise'] = 'Organise';
        messageData['aside_panel.actions.plan.project'] = 'Plan project';
        messageData['aside_panel.actions.properties'] = 'Add properties';
        messageData['aside_panel.actions.settings'] = 'Settings';
        messageData['aside_panel.tab.card_template'] = 'Template details';
        messageData['aside_panel.tab.recurring_card_details'] = 'Recurring card details';
        messageData['aside_panel.tab.card_templates'] = 'Card Templates';
        messageData['aside_panel.tab.card_recurring'] = 'Recurring Cards';
        messageData['aside_panel.learn_more.templates'] = 'Learn more about card templates';
        messageData['aside_panel.learn_more.recurring'] = 'Learn more about recurring cards';
        messageData['aside_panel.no_cards_yet'] = 'No cards here yet...';

        // TUTORIALS

        messageData['tutorials.button.finish'] = 'Finish';
        messageData['tutorials.button.next'] = 'Next';
        messageData['tutorials.button.previous'] = 'Previous';
        messageData['tutorials.button.skip'] = 'Skip';

        messageData['tutorials.first.board_name'] = 'My first board';
        messageData['tutorials.first.card_plus.title'] = 'Create card';
        messageData['tutorials.first.card_plus.text'] = 'Click this awesome plus icon to create new card';
        messageData['tutorials.first.card_add.title'] = 'Name card';
        messageData['tutorials.first.card_add.text'] = 'Think up name for new card. Click Add or press Enter. Or press Ctrl+Enter to add card in end of list.';
        messageData['tutorials.first.card_open.title'] = 'Open card';
        messageData['tutorials.first.card_open.text'] = 'Click card to open card settings';


        // Tooltip Help

        messageData['tooltip_help.default_link_text'] = 'Click to learn more';
        messageData['tooltip_help.color_tags'] = 'Colour labels help to make cards more outstanding.';
        messageData['tooltip_help.related_cards'] = 'Connect related tasks to switch between them quickly.';
        messageData['tooltip_help.subcards'] = 'Split tasks to separate subtasks to assign to different people.';
        messageData['tooltip_help.priority'] = 'Quickly prioritise your tasks and make it visual.';
        messageData['tooltip_help.assignto'] = 'You can assign cards to anyone who has access to the board.';
        messageData['tooltip_help.dates'] = 'Start and due dates are helpful for work planning.';
        messageData['tooltip_help.calendar'] = 'Quickly add start/due date or the whole event to your Google Calendar.';
        messageData['tooltip_help.gantt'] = 'The card will be shown on the Gantt chart if it has a start and due date. Predecessors will be also shown there.';
        messageData['tooltip_help.checklist'] = 'Checklists are helpful to visualize steps that lead to tasks completion.';

        // Affiliate
        messageData['affiliate.title'] = 'Offer 10% discount on Kanbanchi subscription to your contacts';
        messageData['affiliate.body'] = 'Copy and send your personal referral link below';
        messageData['affiliate.faq'] = 'Learn more about your reward';
        messageData['affiliate.try_later'] = 'Find your referral link later in the Help section';
        messageData['affiliate.close'] = 'Close';

        // CopyLink
        messageData['copy_link.title'] = 'Permissions needed';
        messageData['copy_link.body'] = 'Copy this link and send to the unit admin so that they could receive usage reports. They need to visit this page and give Kanbanchi permissions to send Google Spreadsheets.'; // todo microsoft пока похоже это только для pwc
        messageData['copy_link.close'] = 'Close';

        // Invite dialog
        messageData['invite.header'] = 'Tell your colleagues and friends about Kanbanchi and invite them to use the app';
        messageData['invite.email_label'] = 'Email';
        messageData['invite.invalid_email'] = 'Please enter a valid email';
        messageData['invite.cancel_btn'] = 'Cancel';
        messageData['invite.invite_btn'] = 'Invite';
        messageData['invite.success.singular'] = 'Perfect! We have sent your invitation';
        messageData['invite.success.plural'] = 'Perfect! We have sent your invitations';

        //Email dialog
        messageData['email.header'] = 'This message will be sent to all admins and sub admins';
        messageData['email.label'] = 'Email message';
        messageData['email.title'] = 'Here’s a message for you from {0}:';
        messageData['email.cancel_btn'] = 'Cancel';
        messageData['email.invite_btn'] = 'Send';
        messageData['email.success.singular'] = 'Perfect! We have sent your email';
        messageData['email.success.plural'] = 'Perfect! We have sent your emails';

        // Recurring
        messageData['recurring.tooltip.clear_date'] = 'Clear date';
        messageData['recurring.label.start'] = 'Start on';
        messageData['recurring.label.next_run'] = 'Next repeat';
        messageData['recurring.label.last_run'] = 'Last repeat';
        messageData['recurring.label.day'] = 'Day';
        messageData['recurring.label.week'] = 'Week';
        messageData['recurring.label.month'] = 'Month';
        messageData['recurring.label.year'] = 'Year';
        messageData['recurring.label.time'] = 'Time';
        messageData['recurring.label.week_days'] = 'Week days';
        messageData['recurring.label.repeat_every'] = 'Repeat every';
        messageData['recurring.label.create_in_list'] = 'Create in list';
        messageData['recurring.label.never'] = 'Never';
        messageData['recurring.label.on'] = 'On';
        messageData['recurring.label.after'] = 'After';
        messageData['recurring.label.occurrences'] = 'occurrence';
        messageData['recurring.label.monthly_on_day'] = 'Monthly on day';
        messageData['recurring.label.monthly_on_weekday'] = 'Monthly on the';
        messageData['recurring.label.weekday.first'] = 'first';
        messageData['recurring.label.weekday.second'] = 'second';
        messageData['recurring.label.weekday.third'] = 'third';
        messageData['recurring.label.weekday.fourth'] = 'fourth';
        messageData['recurring.label.weekday.last'] = 'last';
        messageData['recurring.label.frequency'] = 'Frequency';

        messageData['recurring.button.save'] = 'Save';
        messageData['recurring.button.cancel'] = 'Cancel';
        messageData['recurring.error.start_date'] = 'Start date should be in the future';
        messageData['recurring.error.last_repeat_start_date'] = 'Set new start date to continue recurring';
        messageData['recurring.error.due_date'] = 'Due date should be after the start date';
        // Team worklaod
        messageData['team.workload.in_progress'] = 'In progress';
        messageData['team.workload.upcoming'] = 'Upcoming';
        messageData['team.workload.done'] = 'Done';
        messageData['team.workload.overdue'] = 'Overdue';
        messageData['team.workload.readyToGo'] = 'Ready to go';
        messageData['team.workload.noDates'] = 'No dates';
        messageData['team.workload.tooltip.in_progress'] = 'Cards with start date passed, due date not reached <br> or cards with no dates but started according to checklists/subcards';
        messageData['team.workload.tooltip.upcoming'] = 'Cards with start date ahead (no earlier than in 3 days) <br> or no start date but due date ahead';
        messageData['team.workload.tooltip.done'] = 'Cards marked as done';
        messageData['team.workload.tooltip.overdue'] = 'Cards with due date passed';
        messageData['team.workload.tooltip.readyToGo'] = 'Cards with no start date and due date within the next 3 days <br> or with start date within the next 3 days';
        messageData['team.workload.tooltip.noDates'] = 'Cards with no start/due dates set';
        messageData['team.workload.card_create_error.text'] = 'The card cannot be added. To create a card, please, switch to the Kanban view and create a list at first.';
        messageData['team.workload.header.unassigned'] = 'Unassigned cards';
        messageData['team.workload.header.no_color_tags'] = 'No colour labels';
        messageData['team.workload.header.no_color'] = 'No colour';
        messageData['team.workload.header.no_tags'] = 'No tags';

        //timezone select
        messageData['timezone.select.title'] = 'Time zone';
        messageData['timezone.select.text'] = 'Data in reports will be reflected according to the time zone set by the board’s owner. When the time\n' +
            '                    zone changes the data before the change will still be shown according to the previous time zone.';
        messageData['timezone.select.more_instructions'] = 'More instructions';

        // Free version
        messageData['free_version.upgrade.text'] = 'Free version with limited functionality';
        messageData['free_version.upgrade.button.start.trial'] = 'Start trial';
        messageData['free_version.upgrade.button.upgrade'] = 'Upgrade';
        messageData['free_version.upgrade.snackbar.text'] = 'Hurray! Your 7-day Enterprise plan trial has started!';

        // Board settings
        messageData['board_settings.starred'] = 'Starred';
        messageData['board_settings.description.add'] = 'Add description';
        messageData['board_settings.description.show_more'] = 'Show more';
        messageData['board_settings.description.show_less'] = 'Show less';
        messageData['board_settings.save'] = 'Save';
        messageData['board_settings.cancel'] = 'Cancel';
        messageData['board_settings.formatting'] = 'Formatting';
        messageData['board_settings.template'] = 'Library template';
        messageData['board_settings.showOnGantt.instructions.url'] = 'https://www.kanbanchi.com/faqwd/all-cards-on-gantt-chart';
        messageData['board_settings.showOnGantt.more_instructions'] = 'More instructions';
        messageData['board_settings.links_to_cards.header'] = 'Links to cards preview';
        messageData['board_settings.links_to_cards.button'] = 'Links preview';
        messageData['board_settings.links_to_cards.description'] = 'Whenever you share a link to card board name and card title will be displayed. Don’t use this option when working with sensitive content.';
        messageData['board_settings.links_to_cards.instruction.url'] = 'https://www.kanbanchi.com/faqwd/links-to-cards-preview';
        messageData['board_settings.links_to_cards.more_instructions'] = 'More instructions';
        messageData['board_settings.email.title'] = 'Board email';
        messageData['board_settings.email.description'] = 'To create cards on this board by email, copy board email address below and add it to your address book.';
        messageData['board_settings.email.instruction.url'] = 'http://www.kanbanchi.com/faqwd/card-from-email';
        messageData['board_settings.email.more_instructions'] = 'More instructions';
        messageData['board_settings.email.readonly'] = 'Unavailable on read-only board';
        messageData['board_settings.import.readonly'] = 'Unavailable on read-only boards';
        messageData['board_settings.export.csv'] = 'CSV';
        messageData['board_settings.export.google_sheet'] = 'Google Spreadsheet';
        messageData['board_settings.export.backups.google_sheet'] = 'Google Sheet Exports';
        messageData['board_settings.export.google_sheet_settings'] = 'Google Spreadsheet Settings';
        messageData['board_settings.export.export_and_google_sheet_settings'] = 'Export and Google Spreadsheet Settings';
        messageData['board_settings.export.date_format'] = 'Date format';
        messageData['board_settings.export.time_format'] = 'Time format';
        messageData['board_settings.export.columns'] = 'Columns';
        messageData['board_settings.export.auto.start'] = 'Start on';
        messageData['board_settings.export.auto.next'] = 'Next export';
        messageData['board_settings.export.auto.repeat'] = 'Repeat every';
        messageData['board_settings.export.auto.at'] = 'at';
        messageData['board_settings.export.export_active'] = 'All Active data';
        messageData['board_settings.export.export_archived'] = 'Include Archived data';
        messageData['board_settings.export.settings'] = 'Export Settings';
        messageData['board_settings.export.export_time_tracker'] = 'Time tracker data';
        messageData['board_settings.export.export_card_titles_and_descriptions'] = 'Card titles & descriptions';
        messageData['board_settings.export.columns.select_all'] = 'Select all';
        messageData['board_settings.export.columns.deselect_all'] = 'Clear';
        messageData['board_settings.export.progress'] = 'Your board data is being exported';
        messageData['board_settings.export.keep_tab_open'] = 'Please keep the tab open &mdash; It will take some time.<br> When export is finished, we\'ll send you an email.';
        messageData['board_settings.export.cancel'] = 'Cancel';
        messageData['board_settings.export.checklists'] = 'Checklists';
        messageData['board_settings.export.checklists.rows'] = 'Export checklist items to separate rows';
        messageData['board_settings.export.button.manually'] = 'Manual';
        messageData['board_settings.export.button.automatic'] = 'Automatic';
        messageData['board_settings.export.automatic_export.label'] = 'Automatic export';
        messageData['board_settings.export.automatic_export.tooltip'] = 'Configure automatic export of your board. Enable the feature and set up rules.';
        messageData['board_settings.notification.email'] = 'Email notifications';
        messageData['board_settings.notification.updates'] = 'Updates';
        messageData['board_settings.notification.send_email.soon'] = 'Send emails as soon as possible';
        messageData['board_settings.notification.send_email.hour'] = 'Send emails once an hour';
        messageData['board_settings.notification.send_email.day'] = 'Send emails once a day';
        messageData['board_settings.push_notification.title'] = 'In-app notifications';
        messageData['board_settings.push_notification.important'] = 'Important';
        messageData['board_settings.push_notification.when'] = 'Notify me for any card which i subscribed on when';
        messageData['board_settings.push_notification.help'] = 'How it works';
        messageData['board_settings.push_notification.help_url'] = 'http://www.kanbanchi.com/faqwd/in-app-notifications';
        messageData['board_settings.push_notification.send_updates'] = 'Send me update on';
        messageData['board_settings.push_notification.subscribe'] = 'Subscribe me to  card when';
        messageData['board_settings.push_notification.scope_send_all'] = 'All cards';
        messageData['board_settings.push_notification.scope_send_subscribed'] = 'Cards I’m subscribed to';
        messageData['board_settings.push_notification.subscribe_on_create'] = 'I create it';
        messageData['board_settings.push_notification.subscribe_on_comment_ment'] = 'I am mentioned in comment';
        messageData['board_settings.push_notification.subscribe_on_assign'] = 'A card is assigned to me';
        messageData['board_settings.push_notification.subscribe_on_comment_add'] = 'I add a comment to it';
        messageData['board_settings.push_notification.important.card_assign'] = 'Someone assigned a card to me';
        messageData['board_settings.push_notification.important.comment_ment'] = 'Someone mentions me in a comment';
        messageData['board_settings.push_notification.important.card_create'] = 'Someone creates a card';
        messageData['board_settings.push_notification.important.like_my_comment'] = 'Someone liked my comment';
        messageData['board_settings.push_notification.type.nameChanged'] = 'Card is renamed';
        messageData['board_settings.push_notification.type.descChanged'] = 'Description is changed';
        messageData['board_settings.push_notification.type.userAssigned'] = 'Another user is assigned';
        messageData['board_settings.push_notification.type.priorityChanged'] = 'Priority is changed';
        messageData['board_settings.push_notification.type.commentAdded'] = 'Comment is added';
        messageData['board_settings.push_notification.type.attachAdded'] = 'Attachment is added';
        messageData['board_settings.push_notification.type.deleted'] = 'Card is deleted';
        messageData['board_settings.push_notification.type.archived'] = 'Card is archived';
        messageData['board_settings.push_notification.type.listChange'] = 'Card moved to another list';
        messageData['board_settings.push_notification.type.overdue'] = 'Card is overdue';
        messageData['board_settings.notification.email_note'] = 'We will send a verification code to this email address.';
        messageData['board_settings.notification.email_error'] = 'The email seems to be spelled incorrectly. Please check it.';
        messageData['board_settings.notification.verify_note'] = 'We have sent a verification code to this email address. If you’re having problems finding it, please check your Spam folder.';
        messageData['board_settings.notification.verify_error'] = 'Sorry, the verfication code doesn’t match. Please try again.';
        messageData['board_settings.notification.verify.enter_email'] = 'Enter your email';
        messageData['board_settings.notification.verify.send_email'] = 'Send emails';
        messageData['board_settings.notification.verify.save'] = 'Save';
        messageData['board_settings.notification.verify.enter_code'] = 'Enter verification code';
        messageData['board_settings.notification.verify.label'] = 'Verify';
        messageData['board_settings.notification.verify.resend_code'] = 'Send verification code again';
        messageData['board_settings.notification.verify.reset_email'] = 'Reset email';
        messageData['board_settings.actions.label'] = 'Actions';
        messageData['board_settings.actions.copy'] = 'Copy board';
        messageData['board_settings.actions.delete'] = 'Delete board';
        messageData['board_settings.actions.create_template'] = 'Convert to template';
        messageData['board_settings.theme.max_file_size'] = 'Maximum file size of 3MB';
        messageData['board_settings.theme.change_background'] = 'Upload image';
        messageData['board_settings.theme.use_your_own'] = 'Own image';
        messageData['board_settings.theme.background'] = 'Background';
        messageData['board_settings.theme.view_without_background.text'] = 'To set background image, please switch to the';
        messageData['board_settings.theme.view_without_background.button'] = 'Kanban view';
        messageData['board_settings.logo.logo'] = 'Logo';
        messageData['board_settings.logo.max_size'] = 'Maximum file size of 500KB';
        messageData['board_settings.logo.updating'] = 'Uploading...';
        messageData['board_settings.logo.change'] = 'Change logo';
        messageData['board_settings.logo.upload'] = 'Upload new image';
        messageData['board_settings.logo.remove'] = 'Remove';
        messageData['board_settings.logo.image'] = 'Image';
        messageData['board_settings.logo.reset'] = 'Reset to default';
        messageData['board_settings.logo.icon'] = 'Icon and Colour';
        messageData['board_settings.colors.title'] = 'Colour labels';
        messageData['board_settings.colors.show'] = 'Show unused colour labels';
        messageData['board_settings.colors.hide'] = 'Hide unused colour labels';
        messageData['board_settings.colors.modal.text.board'] = 'This action is irreversible with your plan’s limit of <strong>{0} colour labels per board</strong>. Upgrade to have an unlimited number of colour labels.';
        messageData['board_settings.colors.modal.text'] = 'This action is irreversible with your plan’s limit of <strong>{0} colour labels per card.</strong> Upgrade to have an unlimited number of colour labels.';
        messageData['board_settings.colors.modal.title'] = 'Remove “{0}” colour label?';
        messageData['board_settings.colors.modal.title.multi'] = 'Remove colour label?';
        messageData['board_settings.tags.title'] = 'Tags';
        messageData['board_settings.tag.delete'] = 'Delete tag';
        messageData['board_settings.tag.empty'] = 'No tags on this board yet';
        messageData['board_settings.roles.title'] = 'Roles';
        messageData['board_settings.backup.text'] = 'Board backups are stored in your Google Drive'; // todo microsoft бэкапов пока нет
        messageData['board_settings.backup.text-link-beginning'] = 'Board backups are stored in ';
        messageData['board_settings.backup.link-text'] = 'Google drive';
        messageData['board_settings.backup.one-drive.link-text'] = 'OneDrive';
        messageData['board_settings.backup.restore'] = 'Restore to My Drive';
        messageData['board_settings.backup.restore.shared'] = 'Restore to Shared drive';
        messageData['board_settings.backup.create'] = 'Create new backup';
        messageData['board_settings.backup.progress'] = 'Backup in progress';
        messageData['board_settings.backup.sheets'] = 'Google sheets backups';
        messageData['board_settings.statuslist.onhold'] = 'On hold';
        messageData['board_settings.statuslist.inwork'] = 'Working';
        messageData['board_settings.statuslist.inwork.move'] = 'Move cards with progress greater than 0% to the selected list';
        messageData['board_settings.statuslist.done'] = 'Done';
        messageData['board_settings.statuslist.archive'] = 'Archive';
        messageData['board_settings.statuslists.title'] = 'Status mapping';
        messageData['board_settings.statuslist.onhold.desc'] = 'Choose the list for tasks that will be put on hold';
        messageData['board_settings.statuslist.inwork.desc'] = 'Choose the list for tasks that people are currently working on';
        messageData['board_settings.statuslist.done.desc'] = 'Choose the destination for tasks that are already done';
        messageData['board_settings.statuslist.toggle.workOnHold'] = 'Working / On hold';
        messageData['board_settings.statuslist.modal.title'] = 'Turn this automation off?';
        messageData['board_settings.statuslist.modal.text'] = 'This action is irreversible with your plan’s limitations. Upgrade to be able to turn it back on and automate card status updating for all boards.';
        messageData['board_settings.archive_on_delete.title'] = 'Archive cards upon deletion';
        messageData['board_settings.archive_on_delete.button'] = 'Archive upon deletion';
        messageData['board_settings.archive_on_delete.description'] = 'Whenever users delete a card on this board, it will not be deleted but archived instead. It will be possible to delete it from the archive later.';
        messageData['board_settings.card_properties.button'] = 'Card properties';
        messageData['board_settings.card_properties.title'] = 'Default & Required card properties';
        messageData['board_settings.card_properties.unused.show'] = 'Show unused properties';
        messageData['board_settings.card_properties.unused.hide'] = 'Hide unused properties';
        messageData['board_settings.card_properties.text'] = `<p>Settings apply to&nbsp;this particular board, for all users.</p>
            <p>If&nbsp;you use some properties often, you can set them to&nbsp;appear by&nbsp;default on&nbsp;all new cards. No&nbsp;need to&nbsp;click property buttons every time.</p>
            <p>When you set a property as required it will be marked with «*» on a card so that all users know they can't leave it empty before closing the card.</p>`
        messageData['board_settings.only_owner'] = 'Only the board owner can change these settings.';
        messageData['board_settings.card_properties.default'] = 'Default';
        messageData['board_settings.card_properties.required'] = 'Required';

        messageData['board_settings.card_custom_properties.button'] = 'Custom properties';
        messageData['board_settings.card_custom_properties.title'] = 'Custom card properties';
        messageData['board_settings.card_custom_properties.text'] = `<p>You can add unlimited number of custom properties. They will be added to all cards on this board.</p>
        <p>Add your property name and choose its type.</p>`;

        messageData['board_settings.card_aging.button'] = 'Card aging';
        messageData['board_settings.card_aging.title'] = 'Card aging';
        messageData['board_settings.card_aging.text.board.before'] = `Easily spot which cards have not been active lately or have spent some time in the same list. `;
        messageData['board_settings.card_aging.text.board.after'] = `You can also define the same settings for each separate list.`;
        messageData['board_settings.card_aging.text.list.main'] = `Easily spot which cards have not been active lately or have spent some time in this list. `;
        messageData['board_settings.card_aging.text.list.before'] = `These setting will override `;
        messageData['board_settings.card_aging.text.list.link'] = `card aging settings`;
        messageData['board_settings.card_aging.text.list.after'] = ` for this board.`;
        messageData['board_settings.card_aging.settings.opacity'] = 'Opacity';
        messageData['board_settings.card_aging.settings.indicator'] = 'Indicator';
        messageData['board_settings.card_aging.settings.indicator.activity'] = 'Show time without changes';
        messageData['board_settings.card_aging.settings.indicator.list'] = 'Show time in the list';
        messageData['board_settings.card_aging.snackbar.text'] = 'Card aging is activated on this board';
        messageData['board_settings.card_aging.modal.title'] = 'Turn Card aging off?';
        messageData['board_settings.card_aging.modal.text'] = 'This action is irreversible with your plan’s limitations. Upgrade to be able to turn it back on and use card aging feature for all boards.';

        messageData['board_settings.attachments.tabs.all'] = 'All';
        messageData['board_settings.attachments.tabs.media'] = 'Media';
        messageData['board_settings.attachments.tabs.documents'] = 'Documents';
        messageData['board_settings.attachments.empty.all.title'] = 'No attachments';
        messageData['board_settings.attachments.empty.documents.title'] = 'No documents';
        messageData['board_settings.attachments.empty.media.title'] = 'No media files';
        messageData['board_settings.attachments.empty.title.search'] = 'No attachments found';
        messageData['board_settings.attachments.empty.text.files'] = `<a href="https://www.kanbanchi.com/faqwd/attach-files" target='_blank'>Attach files</a> to cards to see them here`; // todo microsoft
        messageData['board_settings.attachments.empty.text.documents'] = `<a href="https://www.kanbanchi.com/faqwd/attach-files" target='_blank'>Attach documents</a> to cards to see them here`; // todo microsoft
        messageData['board_settings.attachments.empty.text.search'] = 'No items matched your search';


        messageData['board_settings.estimate_time.title'] = 'Estimated time calculation';
        messageData['board_settings.estimate_time.text'] = 'Choose how many hours is a working day';
        messageData['board_settings.dates.title'] = 'Start and due dates';
        messageData['board_settings.dates.auto_start_date.title'] = 'Set current date as start date';
        messageData['board_settings.dates.auto_start_date.text'] = 'Whenever you create a card, Kanbanchi will set the moment of creation as a start date.';
        messageData['board_settings.dates.text'] = 'Default start and due date time settings';
        messageData['board_settings.dates.label.start'] = 'Start date time';
        messageData['board_settings.dates.label.due'] = 'Due date time';
        messageData['board_settings.pwc_import.choose_file'] = 'Choose file';
        messageData['board_settings.pwc_import.title'] = 'PWC import';
        messageData['board_settings.pwc_import.type.subcards'] = 'Import with subcards';
        messageData['board_settings.pwc_import.type.checklists'] = 'Import with checklists';
        messageData['board_settings.pwc_import.type.template_checklist'] = 'New';


        messageData['board_settings.board_import.title'] = 'Import to board';
        messageData['board_settings.board_import.description'] = 'Upload a CSV file to import cards to this board.';
        messageData['board_settings.board_import.more_instructions'] = 'More instructions';
        messageData['board_settings.board_import.faq_link'] = 'https://www.kanbanchi.com/faqwd/import-to-kanbanchi-board';

        //React Gantt
        messageData['gantt.no_date'] = 'No date';
        messageData['gantt.tooltip.collapse_subcards'] = 'Collapse subcards';
        messageData['gantt.tooltip.expand_subcards'] = 'Expand subcards';
        messageData['gantt.label.start_date'] = 'Start date:';
        messageData['gantt.label.due_date'] = 'Due date:';
        messageData['gantt.label.date'] = 'Date:';
        messageData['gantt.label.create_new_card'] = 'Create new card';
        messageData['gantt.placeholder.add_card'] = 'Add card to Gantt chart';
        messageData['gantt.placeholder.add_card_short'] = 'Add card to chart';
        messageData['gantt.label.by_date'] = 'By date';
        messageData['gantt.label.manual'] = 'Manual';
        messageData['gantt.label.dates_filter.all'] = 'All dates';
        messageData['gantt.label.dates_filter.custom'] = 'Custom dates';
        messageData['gantt.tooltip.clear_date'] = 'Clear date';
        messageData['gantt.card_fields_settings_button'] = 'Show fields';
        messageData['gantt.collapse_button'] = 'Collapse panel';
        messageData['gantt.collapse_button.expand'] = 'Expand panel';
        messageData['gantt.zoom.in'] = 'Zoom in';
        messageData['gantt.zoom.out'] = 'Zoom out';
        messageData['gantt.go.today'] = 'Go to present day';
        messageData['gantt.print.title'] = 'Print settings';
        messageData['gantt.print.cut'] = 'Cut off empty space';
        messageData['gantt.print.fit'] = 'Fit to page';
        messageData['gantt.settings.hide_no_dates'] = 'Hide cards without dates';
        messageData['gantt.tooltip.add_to_timeline'] = 'Add to timeline';
        messageData['gantt.empty.title'] = 'Gantt chart is empty';
        messageData['gantt.empty.add_card'] = 'Want to see tasks on the timeline? Click on the top of the chart and add cards from the dropdown list. Alternatively, you may click Show on Gantt button on the property panel of any card.';
        messageData['gantt.empty.add_all'] = 'If you want all cards that have dates to appear on the Gantt chart';
        messageData['gantt.empty.add_all_click_here'] = 'click here';

        // SUBSCRIPTIONS ADMIN

        messageData['subscriptions.title'] = 'Admin panel';
        messageData['subscriptions.button.manage_subscriptions'] = 'Manage subscriptions';
        messageData['subscriptions.button.add_members'] = 'Add members';
        messageData['subscriptions.seats.total'] = 'Seats total';
        messageData['subscriptions.seats.available'] = 'Seats available';
        messageData['subscriptions.snackbar.item.delete'] = 'Delete license for';
        messageData['subscriptions.snackbar.unit.delete'] = 'Delete subscription';
        messageData['subscriptions.unit.add'] = 'Add subunit';
        messageData['subscriptions.unit.edit'] = 'Edit unit';
        messageData['subscriptions.unit.delete'] = 'Delete unit';
        messageData['subscriptions.unit.expired'] = 'Expired';
        messageData['subscriptions.unit.cancelled'] = 'Cancelled';
        messageData['subscriptions.unit.available'] = 'available';
        messageData['subscriptions.unit.send_email'] = 'Email admins';
        messageData['subscriptions.unit.total'] = 'total';
        messageData['subscriptions.unit.download_users'] = 'Download user list';
        messageData['subscriptions.form.unit.new'] = 'New unit';
        messageData['subscriptions.form.unit.edit'] = 'Edit unit';
        messageData['subscriptions.form.unit.name'] = 'Name';
        messageData['subscriptions.form.unit.managers'] = 'Admins';
        messageData['subscriptions.form.unit.seats'] = 'Seats';
        messageData['subscriptions.form.unit.seats.error.min'] = 'Min value ';
        messageData['subscriptions.form.unit.seats.error.max'] = 'Max value ';
        messageData['subscriptions.form.unit.seats.error.nan'] = 'Not a number';
        messageData['subscriptions.form.email.error'] = `The email address is not valid. <br/>
            Please, use <strong>name@domain.com</strong> format. <br/>
            To add a list of emails, separate them with a space or new lines.`;
        messageData['subscriptions.form.email.duplicate'] = 'You have duplicates among seats. Please, check.';
        messageData['subscriptions.form.items.add'] = 'Add members';
        messageData['subscriptions.form.items.emails'] = 'Emails';
        messageData['subscriptions.form.items.error.limit'] = 'The maximum number of seats is exceeded.';
        messageData['subscriptions.form.items.error.duplicate'] = 'This {0} in another {1}:';
        messageData['subscriptions.form.change.platform.label'] = 'Default type';
        messageData['subscriptions.form.change.platform'] = 'Change type for all users to: ';
        messageData['subscriptions.form.change.platform.button'] = 'Change';
        messageData['subscriptions.form.change.platform.success'] = 'Successfully changed!';

        messageData['subscriptions.remove.remove'] = 'Remove';
        messageData['subscriptions.remove.user'] = 'Are you sure that you want to remove {0} user?';
        messageData['subscriptions.remove.users'] = 'Are you sure that you want to remove {0} users?';
        messageData['subscriptions.remove.title'] = 'Remove users';
        messageData['subscriptions.remove.title.all'] = 'Remove all users';

        messageData['subscriptions.paymentExpDate.active'] = 'Next billing date';
        messageData['subscriptions.paymentExpDate.not_expired'] = 'Expiration date';
        messageData['subscriptions.paymentExpDate.expired'] = 'Expired';
        messageData['subscriptions.paymentExpDate.future'] = 'Starts on';
        messageData['subscriptions.admin.super'] = 'Super admin';
        messageData['subscriptions.admin.unit'] = 'Unit admin';
        messageData['subscriptions.admin.admins'] = 'admins';
        messageData['subscriptions.admin.dummy.search.title'] = 'No seats found';
        messageData['subscriptions.admin.dummy.search.text'] = 'We couldn’t find any seats, <br/>try another search.';
        messageData['subscriptions.admin.dummy.expired.title'] = 'Subscription has expired';
        messageData['subscriptions.admin.dummy.expired.text'] = 'Please, click Manage subscriptions <br/>button to renew the same subscription.';
        messageData['subscriptions.admin.dummy.expired.text_manager'] = 'Contact your manager to get new license.';
        messageData['subscriptions.search.placeholder'] = 'Search for a user';
        messageData['subscriptions.platform.tooltip'] = 'Platform';

        messageData['subscriptions.button.add_seats'] = 'Add seats';
        messageData['subscriptions.form.seats.title'] = 'Add new licenses';
        messageData['subscriptions.form.seats.number'] = 'Number of licenses to order';
        messageData['subscriptions.form.seats.territory'] = 'Territory';
        messageData['subscriptions.form.seats.billingCode'] = 'Billing code';
        messageData['subscriptions.form.seats.text'] = 'Please, note that the invoice will be generated based on this number, and you will not be able to reduce the number of requested licenses.';
        messageData['subscriptions.form.seats.add'] = 'Add seats';

        messageData['timetracker.modal.progress'] = 'You have time tracker in process';
        messageData['timetracker.start'] = 'Start time tracker';
        messageData['timetracker.stop'] = 'Stop time tracker';

        //multiselect card
        messageData['multi_card_form_title_card'] = '{0} card selected';
        messageData['multi_card_form_title_cards'] = '{0} cards selected';
        messageData['multi_card_form_snackbar_delete_text'] = '{0} cards deleted';
        messageData['multi_card_form_delete_all'] = 'Delete selected cards';
        messageData['multi_card_form_color_tags'] = 'Colour labels';
        messageData['multi_card_form_archive'] = 'Archive';
        messageData['multi_card_form_unarchive'] = 'Unarchive';
        messageData['multi_card_form_checklist'] = 'Add checklist from a card…';

        // PROFILE

        messageData['profile.subscription.trial_day'] = '{0} day left in trial';
        messageData['profile.subscription.trial_days'] = '{0} days left in trial';
        messageData['profile.subscription.trial_last'] = 'Last day of trial';
        messageData['profile.subscription.trial_day.discount'] = '{0} day left in the {1} plan trial';
        messageData['profile.subscription.trial_days.discount'] = '{0} days left in the {1} plan trial';
        messageData['profile.subscription.trial_last.discount'] = 'Last day of the {0} plan trial';
        messageData['profile.subscription.paymentExpDate.active'] = 'Next billing date';
        messageData['profile.subscription.paymentExpDate.manually'] = 'Renewal date';
        messageData['profile.subscription.paymentExpDate.canceled'] = 'Expiration date';
        messageData['profile.subscriptions.admin'] = 'Admin';
        messageData['profile.subscriptions.admins'] = 'admins';
        messageData['profile.discount'] = 'Get {0}% off your first purchase';

        // cookie
        messageData['cookie.popup.title'] = 'Privacy & data collection';
        messageData['cookie.popup.content'] = `<p>We use cookies to securely identify your account, keep you signed in, and improve our service.</p>
            <p>You may withdraw your consent to analytics in Cookie setting.</p>
            <p>Please read our <a href='https://www.kanbanchi.com/cookie-policy' target='_blank'>Cookie Policy</a>, for more information.</p>`;
        messageData['cookie.popup.button.agree'] = 'Agree';
        messageData['cookie.popup.button.show_settings'] = 'Show cookies settings';
        messageData['cookie.popup.settings.title'] = 'Cookies settings';
        messageData['cookie.popup.settings.necessary.title'] = 'Necessary cookies';
        messageData['cookie.popup.settings.necessary.text'] = 'For the functioning of Kanbanchi, e.g. signing in';
        messageData['cookie.popup.settings.performance.title'] = 'Performance cookies';
        messageData['cookie.popup.settings.performance.text'] = 'For improving the performance, e.g. identifying most/least popular features';

        // user settings
        messageData['user_settings.settings'] = 'Settings';

        messageData['user_settings.account_settings'] = 'Google account settings';
        messageDataMS['user_settings.account_settings'] = 'Microsoft account settings';

        messageData['user_settings.date_format'] = 'Date format';
        messageData['user_settings.time_format'] = 'Time format';
        messageData['user_settings.calendar_format'] = 'First day of the week';
        messageData['user_settings.delete_account'] = 'Delete account';
        messageData['user_settings.12_hours'] = '12 hours';
        messageData['user_settings.12_hours_short'] = '10:05 AM';
        messageData['user_settings.24_hours'] = '24 hours';
        messageData['user_settings.24_hours_short'] = '22:05';
        messageData['user_settings.european_date'] = '5 April ';
        messageData['user_settings.european_date_short'] = '05/04/';
        messageData['user_settings.american_date'] = 'April 5 ';
        messageData['user_settings.american_date_short'] = '4/5/';
        messageData['user_settings.monday'] = 'Monday';
        messageData['user_settings.sunday'] = 'Sunday';
        messageData['user_settings.default_view'] = 'Default view';
        messageData['user_settings.startup'] = 'On login';
        messageData['user_settings.startup.open_last_board'] = 'Open last used board';
        messageData['user_settings.startup.dont_open_any_board'] = `Do not open any board`;
        messageData['user_settings.startup.open_specific_board'] = 'Open specific board';
        messageData['user_settings.text_formatting'] = 'Text formatting';
        messageData['user_settings.text_formatting.wysiwyg'] = 'WYSIWYG';
        messageData['user_settings.text_formatting.markdown'] = 'Markdown';



        messageData['view_types.default'] = 'Default';
        messageData['view_types.kanban'] = 'Kanban';
        messageData['view_types.list'] = 'Task list';
        messageData['view_types.gantt'] = 'Gantt';
        messageData['view_types.reports'] = 'Reports';
        messageData['view_types.archive'] = 'Archive';
        messageData['view_types.list_grouping.user'] = 'By user (Team workload)';
        messageData['view_types.list_grouping.color_tag'] = 'By colour labels';
        messageData['view_types.list_grouping.card_color'] = 'By card colour';
        messageData['view_types.list_grouping.tag'] = 'By tags';
        messageData['view_types.list_grouping.priority'] = 'By priority';

        //Card link
        messageData['card_link.button'] = 'Open board';

        //Assigned to me
        messageData['assigned_to_me.title'] = 'My work';
        messageData['assigned_to_me.settings.menu.tooltip'] = 'Show settings';
        messageData['assigned_to_me.settings.menu.import'] = 'Add cards';
        messageData['assigned_to_me.settings.menu.open'] = 'Settings';
        messageData['assigned_to_me.settings.menu.background'] = 'Background';
        messageData['assigned_to_me.newCards'] = 'New cards';
        messageData['assigned_to_me.no_cards_title'] = 'No cards found';
        messageData['assigned_to_me.no_cards'] = 'Try to apply different set of filters to see cards that you need or reset filters.';
        messageData['assigned_to_me.learnMore'] = 'Learn more about “My work”';
        messageData['assigned_to_me.reset_filters'] = 'Reset filters';
        messageData['assigned_to_me.filter_bar.filter'] = 'Filters';
        messageData['assigned_to_me.filter_bar.filter.tooltip.open'] = 'Filter options';
        messageData['assigned_to_me.filter_bar.filter.tooltip.close'] = 'Close filter';
        messageData['assigned_to_me.filter_bar.hint'] = 'Click to toggle a filter';
        messageData['assigned_to_me.filter_bar.actions.edit'] = 'Edit filter';
        messageData['assigned_to_me.filter_bar.actions.hide'] = 'Hide from bar';
        messageData['assigned_to_me.filter_bar.actions.add'] = 'Create filter';
        messageData['assigned_to_me.filter_bar.actions.icon'] = 'Edit icon';
        messageData['assigned_to_me.filter_bar.actions.title.hide'] = 'Hide title';
        messageData['assigned_to_me.filter_bar.actions.title.show'] = 'Show title';
        messageData['assigned_to_me.filter_bar.actions.delete'] = 'Delete filter';
        messageData['assigned_to_me.filter_bar.actions.select'] = 'Select others';
        messageData['assigned_to_me.filter_bar.actions.unselect'] = 'Unselect other';
        messageData['assigned_to_me.filter_bar.actions.collapse'] = 'Collapse bar';
        messageData['assigned_to_me.filter_bar.actions.expand'] = 'Expand bar';
        messageData['assigned_to_me.filter_bar.actions.unselect.all'] = 'Unselect all';
        messageData['assigned_to_me.filter_bar.actions.select.all'] = 'Select all';
        messageData['assigned_to_me.filter_bar.actions.tooltip'] = 'Filter actions';
        messageData['assigned_to_me.more_boards.title'] = 'Last 10 days are shown';
        messageData['assigned_to_me.more_boards.text'] = `My Work shows cards from the boards you have edited during the past 10 days. You can add cards from other boards here. If you don’t see the board you need on the list, please, go to that board and wait about 3-5 minutes until the system crawler fetches the data.`;
        messageData['assigned_to_me.more_boards.button.hide'] = `Don’t show again`;
        messageData['assigned_to_me.more_boards.button.open'] = 'Add more boards';
        messageData['assigned_to_me.cards.cardTitle'] = 'Card title';
        messageData['assigned_to_me.cards.priority'] = 'Priority';
        messageData['assigned_to_me.cards.color_labels'] = 'Colour labels';
        messageData['assigned_to_me.cards.color'] = 'Card colour';
        messageData['assigned_to_me.cards.progress'] = 'Progress';
        messageData['assigned_to_me.cards.date.start'] = 'Start date';
        messageData['assigned_to_me.cards.date.due'] = 'Due date';
        messageData['assigned_to_me.cards.board'] = 'Board';
        messageData['assigned_to_me.cards.list'] = 'List';
        messageData['assigned_to_me.cards.author'] = 'Author';
        messageData['assigned_to_me.cards.assignees'] = 'Assignees';
        messageData['assigned_to_me.cards.added'] = 'Added';
        messageData['assigned_to_me.cards.created'] = 'Created';
        messageData['assigned_to_me.cards.added.long'] = 'Added to My work';
        messageData['assigned_to_me.cards.updates'] = 'Updates';
        messageData['assigned_to_me.cards.tooltip.colourTag'] = 'Colour tag: ';
        messageData['assigned_to_me.cards.tooltip.board'] = 'Board: ';
        messageData['assigned_to_me.cards.tooltip.list'] = 'List: ';
        messageData['assigned_to_me.cards.tooltip.unread'] = 'Unread';
        messageData['assigned_to_me.cards.tooltip.pin'] = 'Pin card to top';
        messageData['assigned_to_me.cards.tooltip.unpin'] = 'Unpin card';
        messageData['assigned_to_me.newCards.hint'] = 'Check out new cards assigned to you';
        messageData['assigned_to_me.cards.tooltip.hide'] = 'Hide card';
        messageData['assigned_to_me.cards.tooltip.show'] = 'Show card';
        messageData['assigned_to_me.cards.tooltip.hide_all'] = 'Hide cards from this board';
        messageData['assigned_to_me.cards.tooltip.show_all'] = 'Show cards from this board';
        messageData['assigned_to_me.cards.tooltip.updates'] = 'Click to view new card updates';
        messageData['assigned_to_me.summary.tooltip.info'] = 'My Work shows cards from the boards you have edited during the past 10 days. You can add cards from other boards here. If you don\'t see the board you need on the list, please, go to that board and wait about 3-5 minutes until the system crawler fetches the data.';
        messageData['assigned_to_me.summary.tooltip.add_board'] = 'Add board';

        messageData['assigned_to_me.settings.show.title'] = 'Show';
        messageData['assigned_to_me.settings.show.column_titles'] = 'Column titles';
        messageData['assigned_to_me.settings.show.empty_groups'] = 'Empty groups';
        messageData['assigned_to_me.settings.show.archived_cards'] = 'Archived cards';

        messageData['assigned_to_me.settings.filter_bar.title'] = 'Filter bar style';
        messageData['assigned_to_me.settings.filter_bar.collapsed'] = 'Expanded';
        messageData['assigned_to_me.settings.filter_bar.expanded'] = 'Collapsed';

        messageData['assigned_to_me.settings.columns.title'] = 'Columns';
        messageData['assigned_to_me.settings.columns.reset'] = 'Reset to default';

        messageData['assigned_to_me.import.title'] = 'Add cards from boards';
        messageData['assigned_to_me.import.text'] = 'My Work shows cards from the boards you have edited during the past 10 days. You can add cards from other boards here. If you don’t see the board you need on the list, please, go to that board and wait about 3-5 minutes until the system crawler fetches the data.';
        messageData['assigned_to_me.import.boards.add'] = 'Add board';
        messageData['assigned_to_me.import.boards.update.hint'] = 'Some cards from previously selected boards have updates. You can add these boards here.';
        messageData['assigned_to_me.import.boards.select.option.active'] = 'Fetch cards: ';
        messageData['assigned_to_me.import.boards.select.option'] = 'Updated in the ';
        messageData['assigned_to_me.import.boards.select.days7'] = 'past 7 days';
        messageData['assigned_to_me.import.boards.select.days30'] = 'past 30 days';
        messageData['assigned_to_me.import.boards.select.days90'] = 'past 90 days';
        messageData['assigned_to_me.import.boards.select.days180'] = 'past 180 days';

        messageData['assigned_to_me.type.total'] = 'Total';
        messageData['assigned_to_me.type.assigned_to_me'] = 'Assigned to me';
        messageData['assigned_to_me.type.i_have_assigned'] = 'I’ve assigned';
        messageData['assigned_to_me.type.i_am_subscribed'] = 'I’m subscribed to';
        messageData['assigned_to_me.type.i_have_created'] = 'I’ve created';
        messageData['assigned_to_me.type.i_have_contributed'] = 'I’ve contributed to';
        messageData['assigned_to_me.type.i_have_added_to_mywork'] = 'I’ve added to My work';

        messageData['assigned_to_me.last_edit'] = 'Last edit: {0}';

        /*** OFFLINE ***/

        messageData['offline.blocker.title'] = 'Connection lost';
        messageData['offline.blocker.text'] = 'Please check your Internet connection...';

        //Delete account
        messageData['account_delete.title'] = 'Delete Kanbanchi account';
        messageData['account_delete.start_delete'] = 'Start deleting account';
        messageData['account_delete.cancel'] = 'I’ve changed my mind, let me back';
        messageData['account_delete.last_login'] = 'Last login';
        messageData['account_delete.console.welcome'] = 'welcome to the destroy console';
        messageData['account_delete.console.logout'] = 'You will be logged out in 10 seconds';
        messageData['account_delete.understand_cause'] = 'Help us understand why did you decide to delete Kanbanchi account?';
        messageData['account_delete.feedback_prompt'] = 'Please leave your feedback here to activate the button';
        messageData['account_delete.delete_all'] = 'Delete all data';
        messageData['account_delete.console.deletion_scheduled'] = 'Your account is scheduled for permanent deletion.\n' +
            'If you log into Kanbanchi within the next 24 hours, you’ll cancel the deletion'
        messageData['account_delete.reason.complicated.title'] = 'Too complicated';
        messageData['account_delete.reason.complicated.placeholder'] = 'What did you find to be difficult in particular?';
        messageData['account_delete.reason.switch.title'] = 'Switching to another software';
        messageData['account_delete.reason.switch.placeholder'] = 'What software have you decided to move to?';
        messageData['account_delete.reason.need.title'] = 'Don’t need Kanbanchi anymore';
        messageData['account_delete.reason.need.placeholder'] = 'Is it due to the nature of your work or Kanbanchi possibilities?';
        messageData['account_delete.reason.usage.title'] = 'Not using Kanbanchi enough';
        messageData['account_delete.reason.usage.placeholder'] = 'Please, explain what prevented you from using the app more.';
        messageData['account_delete.reason.expensive.title'] = 'Too expensive';
        messageData['account_delete.reason.expensive.placeholder'] = 'What price would you consider to be fair and why?';
        messageData['account_delete.reason.other.title'] = 'Other';
        messageData['account_delete.reason.other.placeholder'] = 'Please, provide more details about the reason for the account deletion';

        messageData['account_delete.description.step.4'] = `Go to your Google account settings and remove Kanbanchi from the list of apps with access to your Google account. <a href="https://www.kanbanchi.com/faqwd/how-to-delete-kanbanchi" target="_blank">For more details read our FAQ.</a>`;
        messageDataMS['account_delete.description.step.4'] = `Go to your Microsoft account settings and remove Kanbanchi from the list of apps with access to your Microsoft account. <a href="https://www.kanbanchi.com/faqwd/how-to-delete-kanbanchi" target="_blank">For more details read our FAQ.</a>`; // todo microsoft faq

        //User tooltip
        messageData['user_tooltip.assignees.title'] = 'Assignees:';
        messageData['user_tooltip.assignees.title_single'] = 'Assignee:';
        messageData['user_tooltip.author.title_single'] = 'Author:';
        messageData['user_tooltip.collaborators.title'] = 'Participants:';
        messageData['user_tooltip.collaborators.title_single'] = 'Participant:';

        //qualification
        messageData['qualification.welcome'] = 'Welcome to Kanbanchi, {0}!';
        messageData['qualification.questions.title'] = 'Let us provide the perfect experience for you — answer a couple of questions. It won’t take long';
        messageData['qualification.questions.subTitle'] = 'Double trial period for your replies';
        messageData['qualification.skipped.title'] = 'Got you, not this time';
        messageData['qualification.skipped.subTitle'] = 'Come back any time to get 7 more days in a trial. Your current trial is 7 days.';
        messageData['qualification.completed.title'] = 'Thank you';
        messageData['qualification.completed.subTitle'] = 'Now get the most out of Kanbanchi.<br>Your trial is 14 days.';
        messageData['qualification.getStarted'] = 'Get started';
        messageData['qualification.navigation.label'] = 'Double your trial';
        messageData['qualification.questions.phone.prompt'] = 'Insert your phone number with a country code';
        messageData['qualification.questions.moredetails.prompt'] = 'Please, add more details';
        messageData['qualification.back'] = 'Back';
        messageData['qualification.skip'] = 'Skip';
        messageData['qualification.next'] = 'Next';

        messageData['assigned_to_me.related.snackbar.text'] = 'To add related cards open board ';
        messageData['assigned_to_me.related.snackbar.button'] = 'Open board';

        //webinar
        messageData['webinar.welcome'] = 'Hello, {0}!';
        messageData['webinar.questions.title'] = 'Would you like to watch our webinar "Getting started with Kanbanchi"?';
        messageData['webinar.questions.subTitle'] = 'Master the app in just 30 minutes and start getting value right away!';
        messageData['webinar.getStarted'] = 'Explore the app';
        messageData['webinar.skip'] = 'Skip';

        //NotificationsItems request
        messageData['notifications_request.title'] = 'Don’t miss important notifications!';
        messageData['notifications_request.not_now'] = 'Not now';
        messageData['notifications_request.got_it'] = 'Got it';

        messageData['notifications_request.browser.text'] = 'Allow Kanbanchi to send you push notifications to receive all valuable updates.';
        messageData['notifications_request.browser.allow'] = 'Allow';

        messageData['notifications_request.chrome.allow'] = 'Install';
        messageData['notifications_request.chrome.text.done'] = 'Look out for an email from Kanbanchi. We’ll let you know when the extension is available.';
        messageData['notifications_request.chrome.title.done'] = 'We’ll reach out to you!';
        messageData['notifications_request.chrome.due.text'] = 'Never miss deadlines with Kanbanchi Chrome extension. Install now and receive notifications about upcoming deadlines and more.';
        messageData['notifications_request.chrome.shared.text'] = 'Follow your team members activity on board. Never miss important actions with Kanbanchi Chrome extension. Install now.';
        messageData['notifications_request.chrome.comment.text'] = 'Don’t miss when your colleagues reply to your comments or add new details. Check it out in the Chrome extension.';
        messageData['notifications_request.chrome.assigned.text'] = 'It’s crucial to know your tasks to deliver on time. Don’t miss when something is assigned to you! Install Chrome extension.';
        messageData['notifications_request.chrome.mention.text'] = 'You’ve been mentioned in this card: {0}\n' +
            'Don’t miss important notifications with Kanbanchi Chrome extension. Install now.\n';

        // _Datepicker
        messageData['datepicker.label.from'] = 'From';
        messageData['datepicker.label.to'] = 'To';
        messageData['datepicker.label.date'] = 'Date';
        messageData['datepicker.to.error'] = 'To date should be after the start date';
        messageData['datepicker.from.error'] = 'From date should be before the due date';
        messageData['datepicker.date_button.am'] = 'AM';
        messageData['datepicker.date_button.pm'] = 'PM';
        messageData['datepicker.quick_date.today'] = 'Today';
        messageData['datepicker.quick_date.tomorrow'] = 'Tomorrow';
        messageData['datepicker.quick_date.next_week'] = 'Next week';
        messageData['datepicker.quick_date.two_weeks'] = '2 weeks';
        messageData['datepicker.quick_date.four_weeks'] = '4 weeks';
        messageData['datepicker.quick_date.two_month'] = '2 month';
        messageData['datepicker.quick_date.four_month'] = '4 month';
        messageData['datepicker.quick_date.six_month'] = '6 month';
        messageData['datepicker.calendar.today.tooltip'] = 'Go to current date';
        messageData['datepicker.calendar.monday'] = 'Mo';
        messageData['datepicker.calendar.tuesday'] = 'Tu';
        messageData['datepicker.calendar.wednesday'] = 'We';
        messageData['datepicker.calendar.thursday'] = 'Th';
        messageData['datepicker.calendar.friday'] = 'Fr';
        messageData['datepicker.calendar.saturday'] = 'Sa';
        messageData['datepicker.calendar.sunday'] = 'Su';
        messageData['datepicker.calendar.january'] = 'January';
        messageData['datepicker.calendar.february'] = 'February';
        messageData['datepicker.calendar.march'] = 'March';
        messageData['datepicker.calendar.april'] = 'April';
        messageData['datepicker.calendar.may'] = 'May';
        messageData['datepicker.calendar.june'] = 'June';
        messageData['datepicker.calendar.july'] = 'July';
        messageData['datepicker.calendar.august'] = 'August';
        messageData['datepicker.calendar.september'] = 'September';
        messageData['datepicker.calendar.october'] = 'October';
        messageData['datepicker.calendar.november'] = 'November';
        messageData['datepicker.calendar.december'] = 'December';

        // NOTIFY USERS
        messageData['notify.button.title'] = 'Notifications';
        messageData['notify.title.already'] = 'Notified about this change:';
        messageData['notify.title.noone'] = 'No one has been notified about this change';
        messageData['notify.title.other'] = 'Notify other users of this board about this change';
        messageData['notify.title.other.noone'] = 'No one selected';
        messageData['notify.users.show'] = 'Show details';
        messageData['notify.users.collapse'] = 'Hide details';
        messageData['notify.users.button1'] = 'Notify {0} user';
        messageData['notify.users.button2'] = 'Notify {0} users';
        messageData['notify.previous'] = 'If you want to notify users of this board about previous changes';
        messageData['notify.previous.activity'] = 'find these changes in Activity';
        messageData['notify.select.group.card'] = 'Assigned on this board';
        messageData['notify.select.group.other'] = 'Other users';
        messageData['notify.success.title'] = 'Users were notified about the change';
        messageData['notify.rejected.title'] = 'Were not notified about the change';
        messageData['notify.rejected.text'] = 'The following users were not notified because their notifications were turned off';

        // CARD PROGRESS
        messageData['card-progress.tooltip.done'] = 'Done on ';
        messageData['card-progress.tooltip.empty'] = 'Mark as done';
        messageData['card-progress.tooltip.progress'] = 'Overall progress';

        
        // SWIMLANES
        messageData['swimlanes.empty_title.SWIM_COLOR_TAGS_VIEW_TYPE'] = 'No colour labels';
        messageData['swimlanes.empty_title.SWIM_TAGS_VIEW_TYPE'] = 'No tags';
        messageData['swimlanes.controls.goto'] = 'Go to swimlane';
        messageData['swimlanes.controls.collapse'] = 'Collapse all';
        messageData['swimlanes.controls.expand'] = 'Expand all';
        messageData['swimlanes.controls.height_auto'] = 'Fit to content';
        messageData['swimlanes.controls.height_manual'] = 'Fit to swimlanes';

        //LANGUAGE FEATURE MODAL
        messageData['language_modal.title'] = 'Bem-vindo(a)!';
        messageData['language_modal.text'] = 'Nós queremos que você se sinta confortável usando nosso app.<br/>Por favor, assista esse vídeo para traduzir a interface para português.';

        //REQUEST MODAL
        messageData['request_modal.title'] = 'Basic plan request';
        messageData['request_modal.description'] = 'This plan is only available for new users. Please let us know why you want to switch to this plan.';
        messageData['request_modal.textarea.placeholder'] = 'Enter the reason why you want to switch to Basic plan...';
        messageData['request_modal.confirmation.title'] = 'Your request has been sent';
        messageData['request_modal.confirmation.description'] = 'We’ve got your request for switching to Basic plan.\n\r Our team will reply via email.';
        messageData['request_modal.send.request'] = 'Send request';

        // BOARD PROGRESS LOADER
        messageData['board-progress-loader.of'] = ' of ';
        messageData['board-progress-loader.loaded'] = ' cards loaded';
        messageData['board-progress-loader.board'] = 'Loading board...';
        messageData['board-progress-loader.quick-start.1'] = 'Welcome! Preparing your workspace';
        messageData['board-progress-loader.quick-start.2.google'] = 'Connecting to your Google Drive...';
        messageData['board-progress-loader.quick-start.3.google'] = 'Creating board on Google Drive...';
        messageData['board-progress-loader.quick-start.2.ms'] = 'Connecting to your Microsoft OneDrive...';
        messageData['board-progress-loader.quick-start.3.ms'] = 'Creating board on Microsoft OneDrive...';
        messageData['board-progress-loader.quick-start.4'] = 'Creating lists...';
        messageData['board-progress-loader.quick-start.5'] = 'Creating cards...';
        messageData['board-progress-loader.quick-start.6'] = 'Assigning cards to you...';
        messageData['board-progress-loader.quick-start.7'] = 'Forming the My work section...';

        // HTML EDITOR
        messageData['html_editor.tooltip.bold'] = 'Bold';
        messageData['html_editor.tooltip.italic'] = 'Italic';
        messageData['html_editor.tooltip.strike'] = 'Strike through';
        messageData['html_editor.tooltip.ordered_list'] = 'Ordered list';
        messageData['html_editor.tooltip.unordered_list'] = 'Unordered list';
        messageData['html_editor.tooltip.link'] = 'Link';
        messageData['html_editor.tooltip.image'] = 'Image';
        messageData['html_editor.tooltip.quote'] = 'Quote';
        messageData['html_editor.tooltip.delete_format'] = 'Delete format';
        messageData['html_editor.tooltip.link.placeholder'] = 'Type or paste URL and press ↩';

        // SORTING
        messageData['sort.sortByPriority'] = 'Sort by priority';
        messageData['sort.sortByStartDate'] = 'Sort by start date';
        messageData['sort.sortByDueDate'] = 'Sort by due date';
        messageData['sort.sortByProgress'] = 'Sort by progress';
        messageData['sort.sortByAssignedUser'] = 'Sort by assigned user';
        messageData['sort.sortByEstimate'] = 'Sort by estimate';
        messageData['sort.sortByTitle'] = 'Sort by title';
        messageData['sort.sortByPrefix'] = 'Sort by prefix';
        messageData['sort.sortByAge'] = 'Sort by card’s age';
        messageData['sort.sortByColorLabel'] = 'Sort by color labels';

        messageData['sorted.sortByPriority'] = 'Sorted by priority';
        messageData['sorted.sortByStartDate'] = 'Sorted by start date';
        messageData['sorted.sortByDueDate'] = 'Sorted by due date';
        messageData['sorted.sortByProgress'] = 'Sorted by progress';
        messageData['sorted.sortByAssignedUser'] = 'Sorted by assigned user';
        messageData['sorted.sortByEstimate'] = 'Sorted by estimate';
        messageData['sorted.sortByTitle'] = 'Sorted by title';
        messageData['sorted.sortByPrefix'] = 'Sorted by prefix';
        messageData['sorted.sortByAge'] = 'Sorted by card’s age';
        messageData['sorted.sortByColorLabel'] = 'Sorted by color labels';

        // UPDATES PANEL
        messageData['updates_panel.title'] = 'Release notes';
        messageData['updates_panel.button_feedback'] = 'Write feedback';
        messageData['updates_panel.text'] = 'Kanbanchi is a constantly growing product, and we strive to make each release better than the previous one. Check out the list below to see what we’ve added in the last release.';
        messageData['updates_panel.type.added'] = 'Added';
        messageData['updates_panel.type.fixed'] = 'Fixed';
        messageData['updates_panel.type.improved'] = 'Improved';
        messageData['updates_panel.button_more.image'] = 'Learn more';
        messageData['updates_panel.button_more.tips'] = 'Show what’s new';
        messageData['updates_panel.button_more.video'] = 'Show video';
        messageData['updates_tips.fake_widget.working_on'] = '“Woking on it” widget';
        messageData['updates_tips.fake_widget.time_tracker'] = 'Card’s time tracker';

        //CARDS SORTING
        messageData['cards_sorting.modal.title'] = 'Turn Card sorting off?';
        messageData['cards_sorting.modal.text'] = 'This action is irreversible with your plan’s limitations. Upgrade to be able to turn it back on and use card sorting feature for all boards.';

        //BASIC
        messageData['basic.upgrade'] = 'Upgrade';

        //FIRST BOARD
        messageData['first_board_name'] = 'Quick Start';

        // GANTT CONTEXT HINTS
        messageData['gantt_context_hints.show_on_gantt'] = 'Click here to show this card<br/>on the Gantt chart';
        messageData['gantt_context_hints.gantt_type_switcher'] = 'Click on the type switcher and select<br/>”Gantt” to show this card on the Gantt chart';
        messageData['gantt_context_hints.gantt_type_switcher.large'] = 'Switch to the Gantt chart view<br/>to see this card on a timeline';
        messageData['gantt_context_hints.add_date'] = 'Add any date to show this card<br/>on the Gantt chart’s timeline';
        messageData['gantt_context_hints.gantt_view.card'] = 'Point here to drag\n' + 'the dates adjustment handles';
        messageData['gantt_context_hints.gantt_view.name'] = 'Here is your card on the Gantt Chart';

        // QUICK START GUIDES
        messageData['quick_start_guides.complete'] = '% complete';
        messageData['quick_start_guides.or'] = 'or';
        messageData['quick_start_guides.book'] = 'Book an onboarding call';
        messageData['quick_start_guides.book.subtitle'] = 'Free coaching session with Kanbanchi’s expert';
        messageData['quick_start_guides.show'] = 'Show me how';
        messageData['quick_start_guides.show.learn'] = 'Learn more';
        messageData['quick_start_guides.step.later'] = 'Explore later';
        messageData['quick_start_guides.step.ok'] = 'I’ve got it';
        messageData['quick_start_guides.finish.button'] = 'Continue exploring';
        messageData['quick_start_guides.finish.text'] = '🎉 Congratulations on completing the {0}. Happy further Kanbanchi exploring!';
        messageData['quick_start_guides.finish.text.guide'] = ' guide';
        messageData['quick_start_guides.hint'] = 'You can always return to the\n' + '<strong>Efficiency Journey Guide</strong>\n' + 'and <strong>Explore Kanbanchi Guide</strong>\n' + 'later from the user panel';
        messageData['quick_start_guides.efficiency_journey_guide.name'] = 'Efficiency Journey Guide';
        messageData['quick_start_guides.efficiency_journey_guide.step.1'] = 'Create account (single sign-on)';
        messageData['quick_start_guides.efficiency_journey_guide.step.2'] = 'Re-group lists on a Kanban board';
        messageData['quick_start_guides.efficiency_journey_guide.step.2.text'] = 'Manage resources when leading any kind of project with an integrated view for resource management.';
        messageData['quick_start_guides.efficiency_journey_guide.step.3'] = 'Explore the Gantt chart';
        messageData['quick_start_guides.efficiency_journey_guide.step.3.text'] = 'See how all your tasks relate in time and visually plan project schedules with your team.';
        messageData['quick_start_guides.efficiency_journey_guide.step.4'] = 'Create dependent tasks';
        messageData['quick_start_guides.efficiency_journey_guide.step.4.text'] = 'Help everyone in a team know the sequence of tasks and do them in proper order. Dependencies are part of the Gantt chart.';
        messageData['quick_start_guides.efficiency_journey_guide.step.5'] = 'Check your scope with My Work';
        messageData['quick_start_guides.efficiency_journey_guide.step.5.text'] = 'It’s not just a list of your tasks, but a complete collection of work on different projects you are involved in. Create custom filters and group it to organize your one-stop place to do your job.';
        messageData['quick_start_guides.efficiency_journey_guide.step.6'] = 'Share a board with collaborators';
        messageData['quick_start_guides.efficiency_journey_guide.step.6.text'] = 'Share a board and work with your colleagues. Workload made easier when it’s organized into a team work.';
        messageData['quick_start_guides.explore_kanbanchi.name'] = 'Explore Kanbanchi';
        messageData['quick_start_guides.explore_kanbanchi.step.1'] = 'Subcards';
        messageData['quick_start_guides.explore_kanbanchi.step.1.text'] = 'Split your complex tasks into digestable small sub tasks.';
        messageData['quick_start_guides.explore_kanbanchi.step.2'] = 'Custom properties';
        messageData['quick_start_guides.explore_kanbanchi.step.2.text'] = 'Customize your experience and add more information about your tasks. Keep everything in one place.';
        messageData['quick_start_guides.explore_kanbanchi.step.3'] = 'Card templates';
        messageData['quick_start_guides.explore_kanbanchi.step.3.text'] = 'Save time when adding repetitive tasks to your projects.';
        messageData['quick_start_guides.explore_kanbanchi.step.4'] = 'Auto backups';
        messageData['quick_start_guides.explore_kanbanchi.step.4.text'] = 'Add an extra layer of security and control to your boards by setting up automatic daily backups. Restore any version in one click.';
        messageData['quick_start_guides.explore_kanbanchi.step.5'] = 'Addon for Gmail';
        messageData['quick_start_guides.explore_kanbanchi.step.5.text'] = 'Convert emails you receive into actionable tasks without leaving your Inbox and deliver better results.';
        messageData['quick_start_guides.kanbanchi_champion.name'] = 'Kanbanchi Champion';
        messageData['quick_start_guides.kanbanchi_champion.step.1'] = 'Notifications before due date';
        messageData['quick_start_guides.kanbanchi_champion.step.1.text'] = 'Never miss deadlines and fulfill all tasks on time; Kanbanchi will remind you of due dates.';
        messageData['quick_start_guides.kanbanchi_champion.step.2'] = 'Card prefixes';
        messageData['quick_start_guides.kanbanchi_champion.step.2.text'] = 'Short identifiers will help you to refer to tasks when communicating with colleagues.';
        messageData['quick_start_guides.kanbanchi_champion.step.3'] = 'Filter options';
        messageData['quick_start_guides.kanbanchi_champion.step.3.text'] = 'Quickly define the scope of tasks you want to focus on, search for an urgent work or group tasks in achievable portions.';
        messageData['quick_start_guides.kanbanchi_champion.step.4'] = 'Swimlanes';
        messageData['quick_start_guides.kanbanchi_champion.step.4.text'] = 'Add a new layer of structure to your Kanban board. Keep track of subprocesses and classify tasks, projects, or teams with the help of Swimlanes.';
        messageData['quick_start_guides.kanbanchi_champion.step.5'] = 'Task list';
        messageData['quick_start_guides.kanbanchi_champion.step.5.text'] = 'Enjoy the sortable list of tasks grouped in your preferred order.';
        messageData['quick_start_guides.kanbanchi_guru.name'] = 'Kanbanchi Guru';
        messageData['quick_start_guides.kanbanchi_guru.step.1'] = 'Time tracker';
        messageData['quick_start_guides.kanbanchi_guru.step.1.text'] = 'Track time spent on tasks in Kanbanchi, and always know how your team is doing.';
        messageData['quick_start_guides.kanbanchi_guru.step.2'] = 'Board templates';
        messageData['quick_start_guides.kanbanchi_guru.step.2.text'] = 'Save time when creating similar projects. Use templates from the library for typical projects.';
        messageData['quick_start_guides.kanbanchi_guru.step.3'] = 'Custom logo';
        messageData['quick_start_guides.kanbanchi_guru.step.3.text'] = 'Add your corporate style to your Kanbanchi boards.';
        messageData['quick_start_guides.kanbanchi_guru.step.4'] = 'Data reports';
        messageData['quick_start_guides.kanbanchi_guru.step.4.text'] = 'Always be aware of your team’s progress and quickly adjust the workflow according to the report’s data.';
        messageData['quick_start_guides.kanbanchi_guru.step.5'] = 'Archive';
        messageData['quick_start_guides.kanbanchi_guru.step.5.text'] = 'Prevent deletion of important information by archiving cards, lists, and boards and be able to restore them easily.';

        /*** PROMOCODE POPUP ***/

        messageData['promocode_popup.title'] = 'Get {0}% off your first purchase';
        messageData['promocode_popup.text'] = 'The first month or the first year, one or\n' + 'thousand users — your discount applies!';
        messageData['promocode_popup.later'] = 'Not now';
        messageData['promocode_popup.purchase'] = 'Purchase';


        /*** UPGRADE BUTTON ***/
        messageData['upgrade_button.discount'] = 'Get {0}% off';
        messageData['upgrade_button.trial'] = 'left in the {0} plan trial';
        messageData['upgrade_button.trial.last'] = 'Last day of the {0} plan trial left';



            /* parameters are accepted: key: arrayName['key'] = 'some text {0}, another text {1}';
             * the call would be Messages.getText('key', 'insert this text', 10);
             * output:  'some text insert this text, another text 10' */
        Messages.getText = function(key, lngs) {
            if (!lngs) lngs = window.lng ? [window.lng] : [];
			if(!_.contains(lngs, 'def' )){
				lngs[lngs.length] = 'def';
			}
			lngs = _.uniq(lngs);

            if ((key in messageDataMS) && !window.signedIn) {
                // getText вызывается до логина - ещё не определён язык 'def.ms'
                console.error(key, 'getText before signedIn, no platformType yet');
            }

			var value = Messages.getLangText(key, lngs);
            if (value == null) {
                return '[' + key + ']';
            }

            var phvList;
/*            if (arguments.length == 2 && $.isArray(arguments[1])) {
                // An array was passed as the only parameter, so assume it is
                // the list of place holder values.
                phvList = arguments[1];
            }*/

            // Place holder replacement
            var i;
            if (typeof (value) == 'string') {
                // Handle escape characters. Done separately from the tokenizing
                // loop below because escape characters are
                // active in quoted strings.
                i = 0;
                while ((i = value.indexOf('\\', i)) != -1) {
                    if (value.charAt(i + 1) == 't') {
                        value = value.substring(0, i) + '\t' + value.substring((i++) + 2); // tab
                    } else if (value.charAt(i + 1) == 'r') {
                        value = value.substring(0, i) + '\r' + value.substring((i++) + 2); // return
                    } else if (value.charAt(i + 1) == 'n') {
                        value = value.substring(0, i) + '\n' + value.substring((i++) + 2); // line feed
                    } else if (value.charAt(i + 1) == 'f') {
                        value = value.substring(0, i) + '\f' + value.substring((i++) + 2); // form feed
                    } else if (value.charAt(i + 1) == '\\') {
                        value = value.substring(0, i) + '\\' + value.substring((i++) + 2); // \
                    } else {
                        value = value.substring(0, i) + value.substring(i + 1); // Quietly drop the character
                    }
                }

                // Lazily convert the string to a list of tokens.
                var arr = [], j, index;
                i = 0;
                while (i < value.length) {
                    if (value.charAt(i) == '\'') {
                        // Handle quotes
                        if (i == value.length - 1) {
                            value = value.substring(0, i); // Silently drop the trailing quote
                        } else if (value.charAt(i + 1) == '\'') {
                            value = value.substring(0, i) + value.substring(++i); // Escaped quote
                        } else {
                            // Quoted string
                            j = i + 2;
                            while ((j = value.indexOf('\'', j)) != -1) {
                                if (j == value.length - 1 || value.charAt(j + 1) != '\'') {
                                    // Found start and end quotes. Remove them
                                    value = value.substring(0, i) + value.substring(i + 1, j) + value.substring(j + 1);
                                    i = j - 1;
                                    break;
                                } else {
                                    // Found a double quote, reduce to a single
                                    // quote.
                                    value = value.substring(0, j) + value.substring(++j);
                                }
                            }

                            if (j == -1) {
                                // There is no end quote. Drop the start quote
                                value = value.substring(0, i) + value.substring(i + 1);
                            }
                        }
                    } else if (value.charAt(i) == '{') {
                        // Beginning of an unquoted place holder.
                        j = value.indexOf('}', i + 1);
                        if (j == -1) {
                            i++; // No end. Process the rest of the line.
                                    // Java would throw an exception
                        } else {
                            // Add 1 to the index so that it aligns with the
                            // function arguments.
                            index = parseInt(value.substring(i + 1, j));
                            if (!isNaN(index) && index >= 0) {
                                // Put the line thus far (if it isn't empty)
                                // into the array
                                var s = value.substring(0, i);
                                if (s != "") {
                                    arr.push(s);
                                }
                                // Put the parameter reference into the array
                                arr.push(index);
                                // Start the processing over again starting from
                                // the rest of the line.
                                i = 0;
                                value = value.substring(j + 1);
                            } else
                                i = j + 1; // Invalid parameter. Leave as is.
                        }
                    } else {
                        i++;
                    }
                }

                // Put the remainder of the no-empty line into the array.
                if (value != "") {
                    arr.push(value);
                }
                value = arr;

                // Make the array the value for the entry.
            	fullMessageData[lngs.join()][key] = arr;
                // messageData[key] = arr;
            }

            if (value.length == 0) {
                return "";
            }
            if (value.lengh == 1 && typeof (value[0]) == "string") {
                return value[0];
            }

            var extraParamStartIndex = 2; // start from 0
            var s = "";
            for (i = 0; i < value.length; i++) {
                if (typeof (value[i]) == "string") {
                    s += value[i];
                } else if (phvList && value[i] < phvList.length) { // Must be a number
                    s += phvList[value[i]];
                } else if (!phvList && value[i] < (arguments.length - extraParamStartIndex)) {
                    s += arguments[extraParamStartIndex + value[i] + (Array.isArray(arguments[1])?1:0) ];
                } else {
                    s += "{" + value[i] + "}";
                }
            }
            return s;
        };

        /**
         * как getText
         * только там в ответ можно получить '[' + key + ']' если запись не найдена
         * а тут если не найдено, то null
         */

        Messages.getText1 = function(key, lngs) {
            let text = Messages.getText(key, lngs);
            if (text === `[${key}]`) message = null;
            return text;
        }

        Messages.getPlural = function(number, variants) {
            if (!variants.length || variants.length < 2) return '';
            return number === 1 ? variants[0] : variants[1];
        };

        Messages.getPluralByKeys = function(number, keys, lng, ...rest) {
            if (!keys.length || keys.length < 2) return '';
            return Messages.getText(number === 1 ? keys[0] : keys[1], lng, ...rest);
        };

    } (window.Messages = window.Messages || {}, jQuery));

})(jQuery);
