export const getDaysInMonth = (
    initialDate: Date
): number => {
    let days = 1;
    const date = new Date(initialDate);
    const initialMonth = initialDate.getMonth();
    for (;;) {
        if (date.getMonth() !== initialMonth) break;
        days = date.getDate();
        date.setDate(days + 1);
    }
    return days;
}
