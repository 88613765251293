import { IDependencies } from '../../model/dependencies/dependencies/types';
import { Dispatch } from '../../../types/actions';
import { IGetState } from '../../../types/types';
import { dependenciesRequestsStateSetAction } from '../actions/dependenciesRequestsStateSetAction';
import { getDependencyActiveRequestsWithDelta } from '../selectors/getDependencyActiveRequestsWithDelta';
import { IDependenciesRequestsState } from '../reducers/types';

export const dependenciesActiveRequestsUpdate = (
    dependencies: IDependencies,
    activeRequestsDelta: number
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {

        const dependenciesRequestsState: IDependenciesRequestsState = {};

        for (let dependencyId in dependencies) {
            const activeRequests = getDependencyActiveRequestsWithDelta(getState(), Number(dependencyId), activeRequestsDelta);
            dependenciesRequestsState[dependencyId] = {
                activeRequests
            };
        }
        dispatch(dependenciesRequestsStateSetAction(dependenciesRequestsState));
    };
    return action;
};
