import { createRoot } from 'react-dom/client';
import {Provider} from "react-redux";
import React from "react";
import {CopyLinkDialogHOC} from "../react_components/dialogs/copyLink/hocs/CopyLinkDialogHOC/CopyLinkDialogHOC";

App.Views.CopyLinkView = App.Views.BaseView.extend({
    initialize: function (options) {
        this.root = createRoot(this.el);
        this.store = options.store;
    },
    render: function () {
        this.root.render(
            <Provider store={this.store}>
                <CopyLinkDialogHOC  />
            </Provider>
        );
        return this;
    },
    remove: function () {
        this.root.unmount();
        Backbone.View.prototype.remove.call(this);
    }
});

export default App.Views.CopyLinkView;
