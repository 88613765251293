import { getMessages } from '../../../../../../store/constants';
import { CLASS_ASIDE_PANEL } from '../../../../../../const';

export const CLASS_ASIDE_PANEL_ACTIONS = CLASS_ASIDE_PANEL + '__actions';
export const CLASS_ASIDE_PANEL_ACTIONS_SCROLL = CLASS_ASIDE_PANEL_ACTIONS + '-scroll';
export const CLASS_ASIDE_PANEL_ACTIONS_INNER = CLASS_ASIDE_PANEL_ACTIONS + '-inner';
export const CLASS_ASIDE_PANEL_ACTIONS_TOOLTIP = CLASS_ASIDE_PANEL + '__tooltip';
export const CLASS_ASIDE_PANEL_ACTIONS_HEAD = CLASS_ASIDE_PANEL + '__head-actions';

export const ASIDE_PANEL_ACTIONS_SECTION_TITLE_ACTIONS = getMessages().getText('aside_panel.actions.actions');
export const ASIDE_PANEL_ACTIONS_SECTION_TITLE_PROPERTIES = getMessages().getText('aside_panel.actions.properties');
export const ASIDE_PANEL_ACTIONS_SECTION_TITLE_SETTINGS = getMessages().getText('aside_panel.actions.settings');
export const ASIDE_PANEL_ACTIONS_SECTION_TITLE_COLLABORATE = getMessages().getText('aside_panel.actions.collaborate');
export const ASIDE_PANEL_ACTIONS_SECTION_TITLE_ORGANISE = getMessages().getText('aside_panel.actions.organise');
export const ASIDE_PANEL_ACTIONS_SECTION_TITLE_PLAN_PROJECT = getMessages().getText('aside_panel.actions.plan.project');
export const ASIDE_PANEL_ACTIONS_MOBILE_BUTTON = 'Settings & actions'; // board, list
export const ASIDE_PANEL_ACTIONS_MOBILE_BUTTON_PROPERTIES = 'Properties'; // card
