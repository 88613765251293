import { IChecklistItem } from '../../../../../../../store/model/checklists/checklist/types';
import { TChecklistId } from '../../../../../../../types/types';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';

export interface ICardChecklistItemsFields {
    checklistId: TChecklistId;
    checklistItems: IChecklistItem[];
    isArchive: boolean;
    isDraggingOver: boolean;
    isHideDoneChecklistItems: boolean;
    placeholder: JSX.Element;
    sectionType: ESettingsSectionType;
}

export interface ICardChecklistItemsEvents {}

export interface ICardChecklistItemsProps extends
    ICardChecklistItemsFields,
    ICardChecklistItemsEvents
{}

export enum EConvertToSubcardWorkStatus {
    STARTED = 0,
    FINISHED = 1,
}
