import { IGetState, TCardId, TChecklistId } from '../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { snackbarTimerDefault } from '../../../../../snackbarsStack';
import { getChecklistItems } from '../../../../../../../store/model/checklists/checklist/selectors/getChecklistItems';
import { checklistDelete } from '../../../../../../../rest/effects/card/checklist/checklistDelete';
import { SNACKBAR_CHECKLIST_DELETE, SNACKBAR_ID_CHECKLIST_DELETE } from '../constants';
import { deleteChecklistItem } from './deleteChecklistItem';
import { isObjectEmpty } from '../../../../../helpers/isObjectEmpty';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../middlewares/segment';

export const deleteChecklist = (
    cardId: TCardId,
    checklistId: TChecklistId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const checklistItems = getChecklistItems(state, cardId, checklistId);
        if (isObjectEmpty(checklistItems)) {
            dispatch(segmentTrackAction(SegmentCardEvent.CHECKLIST_WITH_ITEMS_DELETED));
            return dispatch(checklistDelete(cardId, checklistId));
        } else {
            return new Promise((res, rej) => {
                const actionApply = () => {
                    let promiseItems: Promise<any>[] = [];
                    for (let itemId in checklistItems) {
                        promiseItems.push(dispatch(deleteChecklistItem(cardId, checklistId, Number(itemId))));
                    }
                    return Promise.all(promiseItems)
                        .then(() => {
                            dispatch(segmentTrackAction(SegmentCardEvent.CHECKLIST_WITH_ITEMS_DELETED));
                            return dispatch(checklistDelete(cardId, checklistId));
                        })
                        .then(res);
                };

                const actionCancel = () => {
                    dispatch(segmentTrackAction(SegmentCardEvent.CHECKLIST_WITH_ITEMS_DELETE_CANCELLED));
                }

                dispatch(snackbarTimerDefault({
                    id: SNACKBAR_ID_CHECKLIST_DELETE,
                    text: SNACKBAR_CHECKLIST_DELETE,
                    actionApply,
                    actionCancel
                }));
            });
        }
    };
    return action;
};
