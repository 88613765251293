import * as React from 'react';
import { ICardCollaboratorsProps } from './types';
import { CARD_CLASS } from '../../constants';
import './CardCollaborators.scss';
import { getCollaboratorShortDisplayName } from '../../helpers/getCollaboratorShortDisplayName';
import { Tooltip } from 'kui';
import { COLLABORATOR_TOOLTIP_TITLE, COLLABORATOR_TOOLTIP_TITLE_SINGLE } from './constants';
import { escape } from 'underscore';
import { UserpicStorage } from 'app/view/react_components/base/components/UserpicStorage/UserpicStorage';

export function CardCollaborators({
    collaborators,
    assignees,
    roles
}: ICardCollaboratorsProps) {
    const classCollaborators = CARD_CLASS + '__collaborators';
    const classCollaborator = CARD_CLASS + '__collaborator';
    const classMore = classCollaborator + '--more';
    const classTooltip = classCollaborator + '-tooltip';
    const classTooltipTitle = classCollaborator + '-tooltip--title';
    const classTooltipBody = classCollaborator + '-tooltip--body';
    const classTooltipName = classCollaborator + '-tooltip--name';
    const classTooltipRole = classCollaborator + '-tooltip--role';

    function rolesForTooltip (
        roleIds: number[]
    ) {
        const rolesArray = !!roleIds && !!roleIds.length && roles && roles.length
            ? getUserRoles(roleIds).map(role => role.name)
            : [];
        const result = rolesArray.length
            ? `<div class="${classTooltipRole}">
                ${escape(rolesArray.join(', '))}
            </div>`
            : '';
        return result;
    }

    function getUserRoles (
        roleIds: number[]
    ) {
        return !!roleIds && !!roleIds.length && roles && roles.length
            ? roles.filter(role => roleIds.includes(role.id))
            : [];
    }

    const uniqueIds: any = {};
    const uniqueCollaborators = collaborators.filter(({userId}) =>(!uniqueIds[userId] && (uniqueIds[userId] = 1)));

    return (
        <div className={classCollaborators}>
            {
                uniqueCollaborators.map((user, index) => {
                    if (index > 2) return;

                    const title = `<p class="${classTooltipTitle}">
                                ${index > 1 ?
                                    COLLABORATOR_TOOLTIP_TITLE
                                    : COLLABORATOR_TOOLTIP_TITLE_SINGLE}
                            </p>`;
                    const assignee = assignees.find(assignee => user.userId === assignee.sharedUser.permissionId);
                    const cardAssigneeRoleIds = assignee ? assignee.roleIds : null;

                    if (index > 1) {
                        const tooltipUsers = uniqueCollaborators.reduce((tooltip, user) => {
                            tooltip.push(
                                <span className={classTooltipBody} key={user.id}>
                                    <UserpicStorage
                                        initials={user.initials}
                                        src={user.photoUrl}
                                        userId={user.userId}
                                    />
                                    <div className={classTooltipName}>
                                        {user.displayName}
                                        {rolesForTooltip(cardAssigneeRoleIds)}
                                    </div>
                                </span>
                            );
                            return tooltip;
                        }, []);

                        return (
                            <Tooltip
                                className={classTooltip}
                                key={user.id}
                                value={title}
                                footer={tooltipUsers}
                                isNoWrap
                            >
                                <span
                                    className={`
                                        ${classCollaborator}
                                        ${classMore}
                                    `}
                                    key={user.id}
                                >
                                    +{uniqueCollaborators.length - 2}
                                </span>
                            </Tooltip>
                        );
                    }

                    const style = {
                        borderColor: user.color,
                        backgroundColor: user.color
                    };

                    const tooltipUsers = (
                        <span className={classTooltipBody}>
                            <UserpicStorage
                                initials={user.initials}
                                src={user.photoUrl}
                                userId={user.userId}
                            />
                            <div className={classTooltipName}>
                                {user.displayName}
                                {rolesForTooltip(cardAssigneeRoleIds)}
                            </div>
                        </span>
                    );

                    return (
                        <Tooltip
                            className={classTooltip}
                            key={user.id}
                            value={title}
                            footer={tooltipUsers}
                            isNoWrap
                        >
                            <span
                                className={classCollaborator}
                                style={style}
                            >
                                {
                                    uniqueCollaborators.length > 1 ?
                                        getCollaboratorShortDisplayName(user)
                                        : uniqueCollaborators[0].displayName
                                }
                            </span>
                        </Tooltip>
                    );
                })
            }
        </div>
    );
}
