import { AsidePanelActionsButton } from '../../../../..';
import * as React from 'react';
import { IEmailNotificationsPanelButtonProps } from './types';
import { CLASS_BOARD_DETAILS_SECTION } from '../../../../constants';
import { BOARD_SECTION_EMAIL_NOTIFICATIONS } from '../../constants';

export function EmailNotificationsPanelButton ({
    indicatorBoolean,
    tooltip,
    onClick
}: IEmailNotificationsPanelButtonProps) {

    return (
        <AsidePanelActionsButton
            children={null}
            indicatorBoolean={indicatorBoolean}
            sectionSelector={`.${CLASS_BOARD_DETAILS_SECTION}--email-notifications`}
            title={BOARD_SECTION_EMAIL_NOTIFICATIONS}
            tooltip={tooltip}
            onClick={onClick}
        />
    );
}
