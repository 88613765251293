import { DriveItem } from '@microsoft/microsoft-graph-types';

export const openUrl = (
    url: string,
) => {
    if (!url) return;

    let link = document.createElement('a');
    link.className = 'handle-click-ignore'; // не закрывать кард панель
    link.href = url;
    document.body.appendChild(link);
    link.click();
    link.remove();
}

/**
 * для onedrive надо слепить:
    https://onedrive.live.com/?cid=40F1A2D2A892C5B0&id=40F1A2D2A892C5B0%21207
 * для sharepoint надо слепить такую ссылку:
    https://4b0pnt-my.sharepoint.com/personal/nestorw_4b0pnt_onmicrosoft_com/_layouts/15/onedrive.aspx
    ?id=/personal/nestorw_4b0pnt_onmicrosoft_com/Documents/Kanbanchi-test 3/nestor - board - 01/nestor - board - 01_assets/podem-gabrovo.jpg
    &parent=/personal/nestorw_4b0pnt_onmicrosoft_com/Documents/Kanbanchi-test 3/nestor - board - 01/nestor - board - 01_assets
 */

export const msBuildUrl = (
    item: DriveItem,
) => {
    if (item.webUrl.includes('https://1drv.ms/')) { // personal
        let url = 'https://onedrive.live.com/?cid=' + item.parentReference.id + '&id=' + item.id;
        return url;
    }

    //@ts-ignore
    let downloadUrl = item && item['@microsoft.graph.downloadUrl'] || '';
    let urlObj = new URL(downloadUrl);
    let urlAction = urlObj.pathname.replace('download.aspx', 'onedrive.aspx');
    let webUrlArr = item.webUrl.split(urlObj.origin);
    let urlFile = webUrlArr.length && webUrlArr.length > 1 && webUrlArr[1] || '';
    let indexName = urlFile.indexOf(encodeURIComponent(item.name));
    let urlFolder = indexName > -1 ? urlFile.slice(0, indexName) : '';
    let url = urlObj.origin + urlAction + '?id=' + urlFile + '&parent=' + urlFolder;
    return url;
}
