import * as React from 'react';
import { IAutoBackupsWarningDescriptionProps } from './types';
import { Button, Icon } from 'kui';
import { AUTOBACKUPS_WARNING_DESCRIPTION } from '../AutoBackups/constants';
import './_AutoBackupsWarningDescription.scss';

export function AutoBackupsWarningDescription (
    {
        showDescription,
        onClose
    }: IAutoBackupsWarningDescriptionProps) {
    if(!showDescription) {
        return null;
    }
    return (<div className={'autobackups-warning-description'}>
        <div className={'autobackups-warning-description-icon'}><Icon size={24} xlink={'error'}/></div>
        <div>
            <h3 className={'autobackups-warning-description-title'}>Automatiс backups for this board were turned&nbsp;off</h3>
            <span>{AUTOBACKUPS_WARNING_DESCRIPTION}</span>
        </div>
        <div className={'autobackups-warning-description-close'}><Button variant={'icon'} onClick={onClose}>
            <Icon xlink={'close'} />
        </Button > </div>
    </div>);
}
