import { AsidePanelActionsButton } from '../../../..';
import * as React from 'react';
import { ICardPriorityButtonProps } from './types';
import { CLASS_ASIDE_PANEL_ACTIONS_BUTTON_CONTAINER, CLASS_ASIDE_PANEL_ACTIONS_POPUP } from '../../../../asidePanel/components/AsidePanelActionsButton/constants';
import { CLASS_CARD_DETAILS } from '../../../constants';
import { EAsidePanelProperty } from '../../../../asidePanel/components/AsidePanel/types';
import { PRIORITY_ICONS } from '../../../../../helpers/constants';
import { getAsidePanelActionsButtonTooltip } from '../../../../asidePanel/helpers/getAsidePanelActionsButtonTooltip';
import { SelectList, SelectListItem } from 'kui';
import { CARD_SELECT_PRIORITY } from '../../../../../base/components/CardPriority/components/CardPriority/constants';

export function CardPriorityButton ({
    priority,
    isShow,
    isShowTooltip,
    onClick,
    onChange,
}: ICardPriorityButtonProps) {
    if (!isShow) return null;

    const priorityObj = PRIORITY_ICONS.find(item => item.priority === String(priority) || !priority && !Number(item.priority));
    const [isOpened, setIsOpened] = React.useState(null);
    const onOpened = () => setIsOpened(true);
    const onClosed = () => setIsOpened(false);
    const tooltip = isShowTooltip ? getAsidePanelActionsButtonTooltip({value: CARD_SELECT_PRIORITY}) : null;

    const className = CLASS_ASIDE_PANEL_ACTIONS_BUTTON_CONTAINER + '--priority';
    const classPriority = priority ? className + '-active' : '';

    return (
        <AsidePanelActionsButton
            className={`
                ${className}
                ${classPriority}
            `}
            dropdownClassName={`${CLASS_ASIDE_PANEL_ACTIONS_POPUP}--priority`}
            icon={priorityObj.icon}
            indicatorBoolean={!!priority}
            isIconHiddenOnFullSize={true}
            isOpened={isOpened}
            openedProperty={EAsidePanelProperty.CARD_PRIORITY}
            sectionSelector={`.${CLASS_CARD_DETAILS}-author-and-list`} // верх панели
            title={CARD_SELECT_PRIORITY}
            tooltip={tooltip}
            onClick={onClick}
            onOpen={onOpened}
            onClose={onClosed}
        >
            <SelectList
                active={PRIORITY_ICONS.findIndex(item => item.priority === String(priority) || !priority && !Number(item.priority))}
            >
                {PRIORITY_ICONS.map((icon, index) => (
                    <SelectListItem
                        className={!index ? 'no-priority' : ''}
                        icon={icon.icon}
                        iconSize={24}
                        key={index}
                        value={String(index)}
                        onClick={() => onChange(icon.priority)}
                    >
                        {icon.text}
                    </SelectListItem>
                ))}
            </SelectList>
        </AsidePanelActionsButton>
    );
}
