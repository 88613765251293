import { IMyWorkBackgroundTabProps } from './types';
import {
    BoardLibThemesHOC
} from '../../../../base/components/Branding/LibThemes/hocs/BoardLibThemesHOC/BoardLibThemesHOC';
import {
    BoardCustomThemeHOC
} from '../../../../base/components/Branding/CustomTheme/hocs/BoardCustomThemeHOC/BoardCustomThemeHOC';
import * as React from 'react';
import { CLASS_MY_WORK, MY_WORK_BOARD_ID } from '../../constants';
import './_MyWorkBackgroundTab.scss';

export const MyWorkBackgroundTab = ({}: IMyWorkBackgroundTabProps) => {
    return (
        <div className={CLASS_MY_WORK + '-background-tab'}>
            <BoardLibThemesHOC boardId={MY_WORK_BOARD_ID}/>
            <BoardCustomThemeHOC boardId={MY_WORK_BOARD_ID}/>
        </div>
    );
}
