import { IGetState } from 'app/types/types';
import { isEqual } from 'underscore';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { getCardPanelComments } from '../../../selectors/getCardPanelComments';
import { commentInit } from '../../../store/reducers/actions/commentInit';
import { initialCardCommentsState } from '../../../store/reducers/cardCommentsReducer';

export const onDidMount = (): ThunkAction => {
    return (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        if (!isEqual(getCardPanelComments(getState()), initialCardCommentsState)) { // dispatches optimization
            dispatch(commentInit());
        }
    };
};
