import {
    gaTrackEventHelper,
    getEventAction,
    getEventCat
} from './../../../../../base/helpers/gaHelper';

import {
    PTGC_EVENT_START_DATE,
    PTGC_EVENT_DUE_DATE,
    PTGC_EVENT_START_AND_DUE_DATE
} from './../constants';

export const sendPushToCalendarGaEvents = (event) => {
    let action;

    switch (event) {
        case PTGC_EVENT_START_DATE:
            action = 'ga.action.card.google_calendar.exported.startDate';
            break;
        case PTGC_EVENT_DUE_DATE:
            action = 'ga.action.card.google_calendar.exported.dueDate';
            break;
        case PTGC_EVENT_START_AND_DUE_DATE:
            action = 'ga.action.card.google_calendar.exported.event';
            break;
    }
    gaTrackEventHelper(
        getEventCat('ga.category.card'),
        getEventAction(action)
    );
};