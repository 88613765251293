import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../types/types';
import { MY_WORK_FILTER_BOARD_ID } from '../../../../../../aside_panel/filterPanelBoard/constants';
import { showSnoozeBlocker } from '../../../../../effects/showSnoozeBlocker';
import {
    onFilterButtonClicked
} from '../../../../../../main/topBar/hocs/TopBarFilterButtonHOC/events/onFilterButtonClicked';
import { getAuthUser } from '../../../../../../../../store/model/authUser/selectors/getAuthUser';
import { getIsUserBasicPlanOnly } from '../../../../../../../../store/model/authUser/selectors/getIsUserBasicPlanOnly';

export const onFilterPanelOpen = (): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const { allowMyWorkCustomFilters } = getAuthUser(getState());
        const isBasicPlanOnly = getIsUserBasicPlanOnly(getState());
        if (!allowMyWorkCustomFilters && !isBasicPlanOnly) {
            dispatch(showSnoozeBlocker({
                allow: 'allowMyWorkCustomFilters'
            }));
        } else {
            dispatch(onFilterButtonClicked(MY_WORK_FILTER_BOARD_ID))
        }
    }
    return action;
}
