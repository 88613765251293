import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../../types/types';
import { updateMyWorkMeta } from '../../../../../../../../../rest/effects/authUser/myWork/updateMyWorkMeta';
import { getMyWorkFilterBarCollapsed } from '../../../../../selectors/getMyWorkFilterBarCollapsed';

export const onToggle = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const isFilterBarCollapsed = !getMyWorkFilterBarCollapsed(getState());
        dispatch(updateMyWorkMeta({ isFilterBarCollapsed }));
    };
    return action;
};
