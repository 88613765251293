import * as React from 'react';
import { ICardChecklistCopySearchSelectOptionProps } from './types';
import { SelectListItem, Tooltip } from 'kui';
import { LIST_LABEL } from '../../../../../../../const';
import { escape } from 'underscore';

export function CardChecklistCopySearchSelectOption({
    img,
    text,
    textSub,
    value,
    className
}: ICardChecklistCopySearchSelectOptionProps) {

    return (
        <SelectListItem
            key={value}
            icon={img}
            iconSize={24}
            list={textSub}
            listLabel={LIST_LABEL + escape(textSub)}
            value={value}
            className={className}
        >
            <Tooltip
                isNoWrap={true}
                value={escape(text)}
            >
                <span>{text}</span>
            </Tooltip>
        </SelectListItem>
    );
};
