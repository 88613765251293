import { TCardId, IApplicationState } from '../../../types/types';
import { ICardMeta } from '../card/types/ICardMeta';
import { getCard } from './getCard';
import { ICard } from '../card/types';

export const getCardMeta = (
    card: ICard,
): ICardMeta => {
    if (
        !card ||
        !card.meta
    ) return {};

    return card.meta;
};
