import { ThunkAction, Dispatch } from '../../../../../../../types/actions';
import { IGetState } from '../../../../../../../types/types';
import { getAsidePanelIsMobile } from '../../../store/selectors/getAsidePanelIsMobile';
import { ASIDE_MAX_WIDTH } from '../../../../../../../const';
import { isMobileSetAction } from '../../../store/actions/isMobileSetAction';

export const onResize = (
    width: number
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const isMobile = getAsidePanelIsMobile(state);
        const isMobileWidth = width <= ASIDE_MAX_WIDTH;
        if (isMobile === isMobileWidth) return;

        dispatch(isMobileSetAction(isMobileWidth));
    };
    return action;
};
