import { ICardAttachmentsProps } from './types';
import * as React from 'react';
import { SettingsSection } from '../../../../../base/components/SettingsSection/SettingsSection';
import { CLASS_ASIDE_CARD, CLASS_CARD_DETAILS, CLASS_CARD_DETAILS_SECTION } from '../../../constants';
import { CardAttachmentsElements } from '../CardAttachmentsElements/CardAttachmentsElements';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { CardAttachmentAdd } from '../CardAttacmentAdd/CardAttacmentAdd';
import {
    ATTACHMENTS_TIPS,
    CARD_ATTACHMENTS_ADD, CARD_ATTACHMENTS_CREATE_SHARED_FILE,
    CARD_ATTACHMENTS_FILE_CREATION,
    CARD_ATTACHMENTS_GRID,
    CARD_ATTACHMENTS_HINT,
    CARD_ATTACHMENTS_LIST,
    CARD_ATTACHMENTS_LIST_HINT,
    CARD_ATTACHMENTS_LIST_TOOLTIP,
    CARD_SECTION_ATTACHMENTS
} from './constants';
import { CardAttachmentDropPanel } from '../CardAttachmentDropPanel/CardAttachmentDropPanel';
import { CLASS_ASIDE_DROP_PORTAL, CLASS_ASIDE_POPUP_PORTAL } from '../../../../asidePanel/constants';
import { Portal } from '../../../../../helpers/portal';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { EAsidePanelProperty } from '../../../../asidePanel/components/AsidePanel/types';
import { CLASS_SETTINGS_SECTION_EMPTY } from '../../../../../base/components/SettingsSection/constants';
import { Button, ButtonDropdown, Divider, Icon, ITooltipInheritedProps, SelectList, SelectListItem } from 'kui';
import { getAsidePanelTooltip } from '../../../../asidePanel/helpers/getAsidePanelTooltip';
import {
    ID_ASIDE_PANEL_ACTIONS_PORTAL,
    ID_ASIDE_PANEL_ACTIONS_PORTAL_MOBILE
} from '../../../../asidePanel/components/AsidePanelActionsButton/constants';
import { EHintKey, EHintStatus, HintHOC } from '../../../../../hints';
import { EAuthUserPlatformType } from 'app/types/rest/IRestAuthUser';
import { CardAttachmentGoogleFilesHOC } from 'app/view/react_components/aside_panel/cardDetails/AttachmentsSection/hocs/CardAttachmentGoogleFilesHOC/CardAttachmentGoogleFilesHOC';
import { Tips } from '../../../../../base/components/Tips/Tips';
import './_CardAttachmentsSection.scss';
import { EMPTY_ARRAY } from 'app/types/types';
import { AnalyzeRender } from 'app/view/react_components/helpers/memoizeHelper';
import { TOOLTIP_ACTIONS_CARD } from '../../../../../main/kanbanView/wording';

//@ts-ignore
const analyzeRender = window.Settings.development ? new AnalyzeRender(`CardAttachmentsSection`) : null;

export function CardAttachmentsSection ({
    attachments,
    attachmentsListHintStatus,
    activeRequests,
    isAttachmentViewTypeGrid,
    isDefaultProperty,
    isGoogleFileCreating,
    isLoading,
    isRequired,
    isShowTips,
    isShowSelectFile,
    platformType,
    sectionType,
    userHash,
    onClose,
    onTogglePin,
    onDelete,
    onDownload,
    onAddClick,
    onHideTips,
    onLinkClick,
    onLinkCopy,
    onSort,
    onDropFiles,
    onOpenAttachmentsTab,
    onPasteFile,
    onPasteFileAsComment,
    onToggleAttachmentViewType,
    onSelectClick,
    onUploadClick,
    onShowAttachmentViewHint,
    onShowAttachmentListHint,
    updateIsGoogleFileCreating,
    ...attributes
}: ICardAttachmentsProps) {
    if (!attachments && sectionType === ESettingsSectionType.READONLY) return;

    const { openedProperty, isMobile, addShowedProperty, boardId, cardId, showedProperties } = React.useContext(AsidePanelContext);

    if (analyzeRender) analyzeRender.call(cardId);

    const [isMounted, setMounted] = React.useState(false);
    const [isCreate, setIsCreate] = React.useState(false);
    const isDefault = sectionType === ESettingsSectionType.DEFAULT;
    const isPopup = sectionType === ESettingsSectionType.POPUP;
    const [isShow, setShow] = React.useState(false);
    const [isOpened, setIsOpened] = React.useState(isPopup);
    const [isShowHint, setShowHint] = React.useState(null);
    const [isDropdownOpened, setIsDropdownOpened] = React.useState(false);
    const [isShowAttachmentsListHint, setShowAttachmentsListHint] = React.useState(null);
    const [count, setCount] = React.useState(attachments && attachments.length || 0);
    const className = CLASS_CARD_DETAILS_SECTION + ' ' + CLASS_CARD_DETAILS_SECTION + '--attachments';
    const classNameEmpty = !attachments || !attachments.length ? CLASS_SETTINGS_SECTION_EMPTY : '';
    const classActionToggleViewType = CLASS_CARD_DETAILS + '-attachment__action-toggle-view-type';
    const classViewHint = classActionToggleViewType + '-hint';
    const classActionAttachmentsList = CLASS_CARD_DETAILS + '-attachment__action-attachments-list';
    const classAttachmentsListHint = classActionAttachmentsList + '-hint';
    const classAttachmentSectionId = CLASS_CARD_DETAILS_SECTION + `-attachments--${cardId}`;

    const isShowDialog = platformType && platformType === EAuthUserPlatformType.MICROSOFT;

    const GoogleDriveIcon = require('!!@svgr/webpack!./google-drive-24.svg').default

    const onAddOpened = () => {
        if (isOpened && !isPopup) return;
        setIsOpened(true);
        onAddClick(isShowDialog, onAddClosed);
        const addButton = document.querySelector('.card-details-section--attachments .settings-section__title-add') as HTMLElement;
        if (document.activeElement === addButton) addButton.blur();
    };
    const onAddClosed = () => {
        setIsOpened(false);
        if (onClose) onClose();
    };

    React.useEffect(() => {
        if (
            !isLoading && // пока карта загружается, у неё нет аттачментов
            !isAttachmentViewTypeGrid && // дефолтный вид
            !isShowHint && // хинт не показывался
            isMounted && // есть портал для хинта
            attachments && attachments.length > count // добавили аттач
        ) {
            setShowHint(true);
            onShowAttachmentViewHint();
        }
        if (
            !isLoading &&
            !isShowAttachmentsListHint &&
            isMounted &&
            attachmentsListHintStatus === EHintStatus.NOT_SHOWN &&
            attachments && attachments.length
        ) {
            setShowAttachmentsListHint(true);
            onShowAttachmentListHint();
        }
        setCount(attachments && attachments.length || 0);
    }, [attachments]);

    const portalSelector = `.${CLASS_ASIDE_CARD}:last-of-type`;
    const waitAsideTimer = React.useRef(null);
    React.useEffect(() => { // добавить портал после маунта aside-panel, тут повторяется дичь из CardDetailsBaseView
        function waitAside () {
            waitAsideTimer.current = setTimeout(() => {
                waitAsideTimer.current = setTimeout(() => {
                    const container = document.querySelector(portalSelector);
                    if (container) {
                        setMounted(true);
                    } else {
                        waitAside();
                    }
                }, 100);
            }, 100);
        }
        waitAside();

        return () => {
            if (waitAsideTimer.current) clearTimeout(waitAsideTimer.current);
        }
    }, []);

    React.useEffect(() => {
        if ((attachments && attachments.length) || isDefaultProperty) {
            setShow(true);
            addShowedProperty(EAsidePanelProperty.CARD_ATTACHMENTS);
        }
    }, [attachments, isDefaultProperty]);

    React.useEffect(() => {
        if (isGoogleFileCreating) updateIsGoogleFileCreating()
    }, [count])

    React.useEffect(() => {
        if (!isShow && showedProperties.has(EAsidePanelProperty.CARD_ATTACHMENTS)) {
            setShow(true)
        }
    }, [showedProperties]);

    const dropPanel = <>
        {isDefault && isMounted &&
            <Portal
                className={CLASS_ASIDE_DROP_PORTAL}
                selector={portalSelector}
            >
                <CardAttachmentDropPanel
                    onDropFiles={onDropFiles}
                    onPasteFile={onPasteFile}
                    onPasteFileAsComment={onPasteFileAsComment}
                />
            </Portal>
        }
    </>;
    const fileCreatingPopup = <>
        {isGoogleFileCreating &&
            <Portal
                className={CLASS_ASIDE_POPUP_PORTAL}
                selector={portalSelector}
            >
                <span>{CARD_ATTACHMENTS_FILE_CREATION}</span>
            </Portal>
        }
    </>

    if (
        (!isShow && !isPopup) ||
        (isShow && !isPopup && openedProperty === EAsidePanelProperty.CARD_ATTACHMENTS)
    ) {
        return dropPanel;
    }
    const onHeader = () => {
        setIsCreate(!isCreate)
    }

    const onSelect = () => {
        setIsDropdownOpened(false);
    }

    return (<>
        <SettingsSection
            addTitle={CARD_ATTACHMENTS_ADD}
            title={CARD_SECTION_ATTACHMENTS}
            className={`
                ${className}
                ${classNameEmpty}
                ${classAttachmentSectionId}
            `}
            addComponent={isShowDialog
                ? <CardAttachmentAdd
                    onSelectClick={isShowSelectFile? () => onSelectClick(onAddClosed) : null }
                    onUploadClick={(onStart, onProgress, onFinish) => onUploadClick(onStart, onProgress, () => {
                        onFinish();
                        onAddClosed();
                    })}
                />
                : <div />
            }
            onAddOpened={onAddOpened}
            onAddClosed={onAddClosed}
            sectionType={sectionType}
            isAddWide
            isRequired={isRequired}
            isRequiredError={!attachments || !attachments.length}
            isOpened={isOpened}
            titleButton={isDefault &&
                <>
                    <ButtonDropdown
                        className={classActionAttachmentsList}
                        dropdownClassName={className + '-dropdown'}
                        directionHorizontal={'right'}
                        opened={isDropdownOpened}
                        onOpen={() => setIsDropdownOpened(true)}
                        portal
                        portalId={ID_ASIDE_PANEL_ACTIONS_PORTAL}
                    >
                        <Button
                            tooltip={{
                                direction: 'down',
                                isNoEvents: true,
                                isNoWrap: true,
                                value: TOOLTIP_ACTIONS_CARD,
                            } as ITooltipInheritedProps}
                            variant={'icon'}
                        >
                            <Icon xlink={'more'} size={24}/>
                        </Button>
                        <>
                            {!isShowDialog &&
                                <div className={className + '-header'}>
                                    <div className={className + '-header-title'}>
                                        <GoogleDriveIcon/>
                                        {CARD_ATTACHMENTS_CREATE_SHARED_FILE}
                                    </div>
                                    <Button
                                        variant={'icon'}
                                        onClick={onHeader}
                                    >
                                        <Icon xlink={isCreate ? 'arrow-left' : 'arrow-right'} size={24}/>
                                    </Button>
                                </div>
                            }
                            {!isShowDialog && <Divider />}
                            {!isCreate ?
                                <SelectList fixActive={false}>
                                    {attachments && attachments.length > 0 &&
                                        <SelectListItem
                                            icon={isAttachmentViewTypeGrid ? 'list-view' : 'grid-view-24'}
                                            onClick={() => {
                                                onToggleAttachmentViewType();
                                                onSelect();
                                            }}
                                            iconSize={24}
                                        >
                                            {isAttachmentViewTypeGrid ? CARD_ATTACHMENTS_LIST : CARD_ATTACHMENTS_GRID}
                                        </SelectListItem>
                                    }
                                    {isShowHint &&
                                        <HintHOC
                                            className={classViewHint}
                                            key={EHintKey.ATTACHMENT_GRID_VIEW}
                                            hintKey={EHintKey.ATTACHMENT_GRID_VIEW}
                                            show
                                            arrow={'up'}
                                            direction={'down'}
                                            value={CARD_ATTACHMENTS_HINT}
                                            selector={`.${classActionToggleViewType}`}
                                            isPortal
                                            portalId={isMobile ? ID_ASIDE_PANEL_ACTIONS_PORTAL_MOBILE : ID_ASIDE_PANEL_ACTIONS_PORTAL}
                                        />
                                    }
                                    <SelectListItem
                                        icon={'folder-attachments'}
                                        onClick={onOpenAttachmentsTab}
                                        iconSize={24}
                                    >
                                        {CARD_ATTACHMENTS_LIST_TOOLTIP}
                                    </SelectListItem>
                                    {isShowAttachmentsListHint &&
                                    <HintHOC
                                        className={classAttachmentsListHint}
                                        key={EHintKey.ATTACHMENTS_LIST}
                                        hintKey={EHintKey.ATTACHMENTS_LIST}
                                        show
                                        arrow={'down'}
                                        direction={'up'}
                                        value={CARD_ATTACHMENTS_LIST_HINT}
                                        selector={`.${classActionAttachmentsList}`}
                                        isPortal
                                        portalId={isMobile ? ID_ASIDE_PANEL_ACTIONS_PORTAL_MOBILE : ID_ASIDE_PANEL_ACTIONS_PORTAL}
                                    />
                                    }
                                </SelectList> :
                                !isShowDialog &&
                                <CardAttachmentGoogleFilesHOC
                                    onSelect={onSelect}
                                    boardId={boardId}
                                    cardId={cardId}
                                    isOnlyCreate
                                />
                            }
                        </>
                    </ButtonDropdown>
                </>
            }
            {...attributes}
        >
            <div>
                <CardAttachmentsElements
                    isAttachmentViewTypeGrid={isAttachmentViewTypeGrid}
                    attachments={attachments || EMPTY_ARRAY}
                    activeRequests={activeRequests}
                    sectionType={sectionType}
                    userHash={userHash}
                    onTogglePin={onTogglePin}
                    onDelete={onDelete}
                    onSort={onSort}
                    onDownload={onDownload}
                    onLinkClick={onLinkClick}
                    onLinkCopy={onLinkCopy}
                />
            </div>
        </SettingsSection>
        {dropPanel}
        {fileCreatingPopup}
        {isShowTips && <Tips tips={ATTACHMENTS_TIPS} onHide={onHideTips} />}
    </>);
}
