import { IBoardsOpenedSetAction } from './types';
import { AT_BOARDS_OPENED_SET } from './constants';
import { TBoardId } from '../../../../../../../types/types';

export const boardOpenedSet = (
    boardId: TBoardId,
    isOpened: boolean
): IBoardsOpenedSetAction => ({
    type: AT_BOARDS_OPENED_SET,
    boardId,
    isOpened
});
