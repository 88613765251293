import { IGetState, TCardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { cardsSetStatus } from './cardsSetStatus';
import { TStatus } from '../../../../types/model';
import { authUserSetUsedProFeatures } from '../../authUser/authUserSetUsedProFeatures';
import { getProFeaturesTrialActive } from '../../../../store/model/authUser/selectors/getProFeaturesTrialActive';

export const cardsArchive = (
    cardIds: TCardId[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (getProFeaturesTrialActive(getState())) {
            dispatch(authUserSetUsedProFeatures('allowArchive'));
        }
        return dispatch(cardsSetStatus(cardIds, TStatus.STATUS_ARCHIVE));
    };
    return action;
};
