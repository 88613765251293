import { ICard } from '../../../../../../../../../store/model/card/types';
import { getListMetaCardAging } from '../../../../../../../../../store/model/selectors/getListMetaCardAging';
import {
    getListCardAgingIndicator
} from '../../../../../../../../../store/model/list/selectors/getListCardAgingIndicator';
import {
    getListCardAgingIndicatorType
} from '../../../../../../../../../store/model/list/selectors/getListCardAgingIndicatorType';
import {
    getBoardCardAgingIndicator
} from '../../../../../../../../../store/model/board/selectors/getBoardCardAgingIndicator';
import {
    getBoardCardAgingIndicatorType
} from '../../../../../../../../../store/model/board/selectors/getBoardCardAgingIndicatorType';
import { IApplicationState } from '../../../../../../../../../types/types';
import { getBoardIdByCardId } from '../../../../../../../../../store/model/selectors/getBoardIdByCardId';
import { ECardAgingIndicatorType } from '../../../../../../../../../types/rest/ICardAging';
import { TStatus } from '../../../../../../../../../types/model';

export const getCardAgingIndicator = (
    state: IApplicationState,
    card: ICard
): ECardAgingIndicatorType => {
    const { listId, status } = card;

    if (status === TStatus.STATUS_SERVICE_TEMPLATE) return;

    const boardId = getBoardIdByCardId(state, card.id);

    const listCardAging = getListMetaCardAging(state, listId);
    const listCardAgingIndicator = getListCardAgingIndicator(state, listId) && getListCardAgingIndicatorType(state, listId);
    const boardCardAgingIndicator = getBoardCardAgingIndicator(state, boardId) && getBoardCardAgingIndicatorType(state, boardId);

    return listCardAging ? listCardAgingIndicator : boardCardAgingIndicator;
}
