import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../../types/types';
import { updateMyWorkMeta } from '../../../../../../../../../rest/effects/authUser/myWork/updateMyWorkMeta';
import { getHideMyWorkColumnsTitle } from '../../../../../selectors/getHideMyWorkColumnsTitle';

export const onTitlesToggle = (): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const isHideColumnsTitle = !getHideMyWorkColumnsTitle(state);
        dispatch(updateMyWorkMeta({ isHideColumnsTitle }));
    }
    return action;
}
