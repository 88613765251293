class BaseMigrateView extends Backbone.View {
    initialize(reactView) {
        this.react = reactView;
    }
    
    remove() {
        return Backbone.View.prototype.remove.call(this);
    }
}
export default BaseMigrateView;
