import { connect } from 'react-redux';
import { onSubmit } from './events/onSubmit';
import { onSkip } from './events/onSkip';
import { getAsidePanelWidth } from '../../../../aside_panel/asidePanel/store/selectors/getAsidePanelWidth';
import { getMessages } from '../../../../../../store/constants';
import { IApplicationState } from '../../../../../../types/types';
import { Poll } from '../../../../base/components/Poll/components/Poll/Poll';
import { IPollEvents, IPollFields } from '../../../../base/components/Poll/components/Poll/types';
import { EPollQuestionVariant } from '../../../../base/components/Poll/components/PollQuestion/types';
import { getUserRateFeaturePoll } from '../../../../../../store/model/authUser/selectors/getUserRateFeaturePoll';
import { getAuthUserFeaturePoll } from '../../../../../../store/model/authUser/selectors/getAuthUserFeaturePoll';
import { onRate } from './events/onRate';
import { onShow } from './events/onShow';
import { getAuthUserPlatformType } from '../../../../../../store/model/authUser/selectors/getAuthUserPlatformType';
import {
    POLL_QUESTION_FEEDBACK_END,
    POLL_QUESTION_FEEDBACK_START,
    POLL_TITLE
} from '../../../../base/components/Poll/components/Poll/constants';
import { getShowPricingPoll } from '../../../pricingPoll/hocs/PricingPoll/selectors/getShowPricingPoll';

const mapStateToProps = (
    state: IApplicationState,
): IPollFields => {
    const { answeredFeaturePolls = [], triggeredFeatureId, skipDate } = getUserRateFeaturePoll(state);
    const currentFeaturePoll = getAuthUserFeaturePoll(state);
    let isShow = null;
    const platformType = getAuthUserPlatformType(state)

    // проверяем включен ли опрос
    const isFeaturePollEnabled = currentFeaturePoll && currentFeaturePoll.isEnabled && // включенно в адимин консоле
        currentFeaturePoll.requiredUsersCount > currentFeaturePoll.actualUsersCount; // еще нужны юзеры
    if (!isFeaturePollEnabled) return { isShow, platformType};

    // проверяем показывать ли именно этому юзеру опрос
    isShow = triggeredFeatureId && triggeredFeatureId === currentFeaturePoll.id && // текущий опрос совпадает с тригером
        !answeredFeaturePolls.includes(triggeredFeatureId); // юзер еще не проходил его
    if (!isShow) return { isShow, platformType };

    const oneDay = 1000 * 60 * 60 * 24;

    // проверяем не скипал ли юзер опрос
    isShow = !skipDate || skipDate && (Date.now() - skipDate > oneDay * 2);
    if (!isShow) return { isShow, platformType };

    // проверяем не мешает ли опросу ничего другое
    const isTrialExtendOpen = !!document.querySelector('.dialog-blocker__trial-dialog');
    isShow = !getShowPricingPoll(state) && !getAsidePanelWidth(state) && !isTrialExtendOpen;
    if (!isShow) return { isShow, platformType };

    return {
        isShow,
        platformType,
        title: POLL_TITLE + getMessages().getText('feature-poll.title') + currentFeaturePoll.question,
        reviewTitle: POLL_QUESTION_FEEDBACK_START + currentFeaturePoll.question + POLL_QUESTION_FEEDBACK_END,
        questionsList: [{ question: '' }],
        variant: EPollQuestionVariant.STAR
    };
}

const mapDispatchToProps = (
    dispatch: any,
): IPollEvents => {
    return {
        onRate: () => dispatch(onRate()),
        onShow: (feature) => dispatch(onShow(feature)),
        onSkip: (feature) => dispatch(onSkip(feature)),
        onSubmit: (results, feature) => dispatch(onSubmit(results, feature))
    };
}

export const FeaturePollHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(Poll);

FeaturePollHOC.displayName = 'FeaturePollHOC';
