import { AsidePanelActionsButton } from '../../../..';
import * as React from 'react';
import { ICardSubcardsPanelButton } from './types';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { CARD_SECTION_SUBCARDS } from '../CardSubcardsSection/constants';
import { CardSubcardsSectionHOC } from '../../hocs/CardSubcardsSectionHOC/CardSubcardsSectionHOC';
import { getPopupAttributes } from '../../../../../base/components/SettingsSection/constants';
import { CLASS_CARD_DETAILS_SECTION } from '../../../constants';
import { EAsidePanelProperty } from '../../../../asidePanel/components/AsidePanel/types';
import { getAsidePanelActionsButtonTooltip } from 'app/view/react_components/aside_panel/asidePanel/helpers/getAsidePanelActionsButtonTooltip';

export function CardSubcardsPanelButton({
    isBasicFeature,
    isDisallow,
    isShow,
    subcardsCount,
    tooltip,
    onClick
}: ICardSubcardsPanelButton) {
    if (!isShow) return null;

    const { boardId, cardId, showedProperties } = React.useContext(AsidePanelContext);
    const isShowed = showedProperties.has(EAsidePanelProperty.CARD_SUBCARDS);
    const [isOpened, setIsOpened] = React.useState(null);
    const onOpened = () => setIsOpened(true);
    const onClosed = () => setIsOpened(false);

    return (
        <AsidePanelActionsButton
            indicatorNumber={subcardsCount}
            icon={'subcard'}
            isDisabled={isDisallow}
            isBasicFeature={isBasicFeature}
            isIconHiddenOnFullSize={true}
            isOpened={isOpened}
            openedProperty={EAsidePanelProperty.CARD_SUBCARDS}
            sectionSelector={`.${CLASS_CARD_DETAILS_SECTION}--subcards`}
            title={CARD_SECTION_SUBCARDS}
            tooltip={tooltip && getAsidePanelActionsButtonTooltip({ value: tooltip })}
            onClick={onClick}
            onOpen={onOpened}
        >
            {!isDisallow && !isShowed &&
                <CardSubcardsSectionHOC
                    boardId={boardId}
                    cardId={cardId}
                    onClose={onClosed}
                    {...getPopupAttributes()}
                />
            }
        </AsidePanelActionsButton>
    );
}
