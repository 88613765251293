import {AT_FILTER_PANEL_ACTION_SET} from './constants';
import {TFilterPanelAction} from '../../filterPanel/actions/types';
import {IFilterPanelActionSetAction} from './types';

export const filterPanelActionSetAction = (
    boardId: number,
    filterPanelAction: TFilterPanelAction
): IFilterPanelActionSetAction => ({
        type: AT_FILTER_PANEL_ACTION_SET,
        boardId,
        filterPanelAction
    }
);
