import {INavigationPanelState} from '../types';
import {NavigationPanelAction} from '../actions/types';
import {
    AT_NAVIGATION_PANEL_IS_OPEN_SET,
    AT_NAVIGATION_PANEL_TYPE_SET,
    AT_NAVIGATION_PANEL_IS_ASIDE_OPEN_SET, AT_NAVIGATION_PANEL_QUICK_START_GUIDE_SET,
} from '../actions/constants';
import {getNavigationPanelInitialState} from '../constants';
import * as deepFreeze from 'deep-freeze';

const _NavigationPanelReducer = (
    state = getNavigationPanelInitialState(),
    action: NavigationPanelAction
): INavigationPanelState => {
    switch (action.type) {
        case AT_NAVIGATION_PANEL_IS_OPEN_SET: {
            return {
                ...state,
                isOpen: action.isOpen
            }
        }
        case AT_NAVIGATION_PANEL_TYPE_SET: {
            return {
                ...state,
                panelType: action.panelType
            }
        }
        case AT_NAVIGATION_PANEL_IS_ASIDE_OPEN_SET: {
            return {
                ...state,
                isAsideOpened: action.isAsideOpened
            }
        }
        case AT_NAVIGATION_PANEL_QUICK_START_GUIDE_SET: {
            return {
                ...state,
                quickStartGuide: action.quickStartGuide
            }
        }
        default:
            return state;
    }
};

export const NavigationPanelReducer = (
    state = getNavigationPanelInitialState(),
    action: NavigationPanelAction
): INavigationPanelState => {
    // @ts-ignore
    if (window.Settings.development) {
        // @ts-ignore
        return deepFreeze(_NavigationPanelReducer(state, action));
    } else {
        return _NavigationPanelReducer(state, action);
    }
};
