import { IGetState, TCardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { getCard } from '../../../../store/model/selectors/getCard';
import { cardsRestPatch } from './api/helper/cardsRestPatch';
import { ICards } from '../../../../store/model/cards/types';
import { sendUserProductivityStatsByCardId } from '../../../../helper/comet/stat_helper_ts';
import { EUserProductivityEventStatus } from '../../../../view/react_components/reports/UserProductivity/UserProductivityReport/rest/types';

export const cardSetDueDate  = (
    cardId: TCardId,
    dueDate: number
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const card = getCard(state, cardId);
        if (!card) return Promise.reject();

        if (card.dueDate === dueDate) return Promise.resolve();

        const cards: ICards = {
            [cardId]: {
                dueDate
            }
        };
        return dispatch(cardsRestPatch(cards))
            .then(() => {
                dispatch(sendUserProductivityStatsByCardId(cardId, EUserProductivityEventStatus.DUE_DATE_CHANGED))
            });
    };
    return action;
};
