import { connect } from 'react-redux';
import { UsersTiming } from '../../components/UsersTiming/UsersTiming';
import { IUsersTimingFields } from '../../components/UsersTiming/types';
import { IApplicationState } from '../../../../../../../types/types';
import { IUsersTimingHOCProps } from './types';
import { getUserTimetrackers } from './selectors/getUserTimetrackers';

const mapStateToProps = (
    state: IApplicationState,
    props: IUsersTimingHOCProps
): IUsersTimingFields => {
    const  {cardId, boardId} = props;
    return {
        usersSpentTime: getUserTimetrackers(state, cardId, boardId)
    }
};

export const UsersTimingHOC = connect(mapStateToProps, null)(UsersTiming);
UsersTimingHOC.displayName = 'UsersTimingHOC';
