'use strict';
import * as _ from 'underscore';
import store, { dispatch, getAppState } from "../../store/configureStore";
import { SegmentInAppNotificationEvent, segmentTrackAction } from "../../middlewares/segment";
import { getIsAssignedToMeActive } from '../../store/router/selectors/getIsAssignedToMeActive';
import { ERoutePrefixes } from '../../store/router/constants';

import template from './template/notification-base.html';
import { getActiveBoardId } from '../../store/model/selectors/getActiveBoardId';
import { getActiveBoardLngs } from '../../store/model/selectors/getActiveBoardLngs';
import { msUserPhotoStorage } from 'app/helper/authorisation/microsoft/storage/userPhoto';
import {
    getCurrentNavigationPanelType
} from "../react_components/main/navigationPanel/helpers/getCurrentNavigationPanelType";
import { ENavigationPanelTypes } from "../react_components/main/navigationPanel/constants";
import {
    panelTypeSetAction
} from "../react_components/main/navigationPanel/store/navigationPanel/actions/panelTypeSetAction";
import {
    isOpenSetAction
} from "../react_components/main/navigationPanel/store/navigationPanel/actions/isOpenSetAction";

App.Views.BaseNotification = Backbone.View.extend({
    className: 'notifire',

    initialize: function() {
        var lngs = getActiveBoardLngs(getAppState());
        this.template = App.MLTemplate.getTemplate('#notification-base', lngs, template);
        this.listenTo(this, 'click', this.onClick, this);
        this.listenTo(this, 'panelClick', this.plateClick, this);
        this.listenTo(this, 'contextmenu', this.onContextMenu, this);
        this.listenTo(this.model, 'change', this.renderPlate, this);
        this.listenTo(this.model, App.vent['notificationFilter:active'], this.toggleVisible);
        this.$elPlate = $('<li/>');
        this.fillData();
        this.userPhotos = {};
    },

    fillData: function() {
        if (this.model) {            
            this.notificationModel = this.model.getNotificationModel(0);
            this.user = this.notificationModel.user;                        
        }
    },

    getContent: function() {
        var options = (arguments.length > 0 && arguments[0] !== undefined) ? arguments[0] : {},
            body = null,
            listName = null,
            cardName = null,
            icon = null,
            longName = 'Anonym',
            boardIcon = 'board', // todo star
            time = null,
            timeTooltip = null;
        if (!this.notificationModel) this.notificationModel = {};
        if (this.notificationModel.time) {
            time = App.Util.formatDateAgo(this.notificationModel.time);
            timeTooltip = App.Util.formatBackEndDate(this.notificationModel.time);
        }
        if (this.notificationModel.card) {
            body = cardName = this.notificationModel.card.name;
            listName = this.notificationModel.card.listName;
        }        
        if (this.user) {
            icon = this.user.photoUrl;
            longName = this.user.fullName;

            if (!this.user.photoUrl) {
                if (this.photoUrl !== undefined) {
                    icon = this.photoUrl;
                } else if (msUserPhotoStorage.is()) {
                    msUserPhotoStorage.getPhoto(this.user.permissionId).then((photo) => {
                        this.photoUrl = photo;
                        this.render();
                        this.renderPlate();
                    })
                }
            }
        }
        if (this.notificationModel.users) {
            this.notificationModel.users.forEach((user) => {
                if (!user.photoUrl) {
                    if (this.userPhotos[user.permissionId] !== undefined) {
                        user.photoUrl = this.userPhotos[user.permissionId];
                    } else if (msUserPhotoStorage.is()) {
                        msUserPhotoStorage.getPhoto(user.permissionId).then((photo) => {
                            this.userPhotos[user.permissionId] = photo;
                            this.render();
                            this.renderPlate();
                        })
                    }
                }
            });
        }
        return _.extend({
            plate: options.plate,
            boardIcon: boardIcon,
            boardName: this.notificationModel.dashboardName,
            listName: listName,
            cardName: cardName,
            icon: icon,
            longName: longName,
            time: time,
            timeTooltip: timeTooltip,
            body: body,
            bodyRaw: null,
            actionData: {
                icon: 'card'
            },
            assigned: null,
            badge: null,
            system: null,
            process: null
        }, this.getActions());
    },

    getActions: function() {
        return {
            action: ''
        };
    },

    /**
     * Настройки NOTY
     * @returns {{timeout: number}}
     */
    getSettings: function() {
        return {
            timeout: 10 * App.Util.TIME.SECOND * 1000
        };    
    },

    getGroup: function() {
        return this.$elPlate.closest('.notification-group');
    },

    render: function() {
        this.$el.html(this.template(this.getContent()));
        this.$el.attr('data-cid', this.cid);
        return this;
    },

    renderPlate: function() {
        this.fillData();
        this.$elPlate.html(this.template(this.getContent({plate: true})));
        this.$elPlate.addClass('notification-list__item');
        this.$elPlate.data('backbone-view', this);
        this.toggleRead();
        let filterNotificationsByActiveDashboard = localStorage.getItem('filterNotificationsByActiveDashboard');
        this.toggleVisible(Boolean(localStorage &&  filterNotificationsByActiveDashboard && JSON.parse(filterNotificationsByActiveDashboard).dashboardFilter));
        return this;
    },

    toggleVisible: function(isFilterActive) {
        const boardId = getActiveBoardId(store.getState());
        var invisible = Boolean(isFilterActive && boardId && this.model.getNotificationModel(0).dashboardId != boardId);
        this.$elPlate.toggleClass('animation_height_in', !invisible).toggleClass('animation_height_out', invisible);
        this.model.set({invisible: invisible});
    },

    toggleRead: function() {
        this.$elPlate.toggleClass('notification-list__item--unread', !this.model.get('seen'));
    },

    toggleClean: function(flag) {
        flag = _.isUndefined(flag) ? !this.model.get('seen') : flag;
        this.model.set('seen', flag);
        if (!this.model.isNew()) {
            this.model.save({seen: flag}, {patch: true});
        }
        this.toggleRead();
    },

    setSeen: function(group) {
        this.toggleClean(true);
        if (_.isUndefined(group)) {
            this.setGroupSeen();
        }
    },

    setGroupSeen: function() {
        var group = this.getGroup();
        if (!group.length) return;
        $(group).find('.js-notification-group__read-auto').trigger('click');
    },

    onClick: function(e, notification) {
        const targetClassNames =  e && e.originalEvent && e.originalEvent.srcElement && e.originalEvent.srcElement.className;
        if (
            targetClassNames.includes('js-notifire-notification__star')
        ) {
            window.App.controller.notificationsView.staredNotifyHelper.starredNotification(this.model);
            this.setSeen();
            dispatch(segmentTrackAction(SegmentInAppNotificationEvent.STAR_NOTIFICATION));
        }
        const ignoreClass = 'notification-click-ignore';
        if (targetClassNames.includes(ignoreClass)) return;
        App.router.navigate(App.router.getAssignedToMePanelUrl(ERoutePrefixes.notification, this.model.getNotificationId()), { trigger: true });
        const navigationPanelType = getCurrentNavigationPanelType(getAppState());
        if (navigationPanelType === ENavigationPanelTypes.NP_COMPACT_TYPE) {
            dispatch(panelTypeSetAction(ENavigationPanelTypes.NP_COMPACT_BIG_TYPE));
            dispatch(isOpenSetAction(false));
        }
        this.closeNotification(notification);
        this.setSeen();
        e.stopPropagation();
        dispatch(segmentTrackAction(SegmentInAppNotificationEvent.CLICKED_NOTIFICATION, {
                name: this.notificationModel.modelType,
                value: this.notificationModel.propertyName
            }
        ));
    },

    plateClick: function(e) {
        this.setSeen();
        e.stopPropagation();
        const ignoreClass = 'notification-click-ignore';
        const targetClassNames =  e && e.originalEvent && e.originalEvent.srcElement && e.originalEvent.srcElement.className;
        if (
            targetClassNames.includes &&
            targetClassNames.includes('js-notification__star')
        ) {
            this.$elPlate.remove();
        }
        if (
            targetClassNames.includes &&
            targetClassNames.includes(ignoreClass)
        ) {
            return;
        }
        if (getIsAssignedToMeActive(store.getState())) {
            App.router.navigate(App.router.getAssignedToMePanelUrl(ERoutePrefixes.notification, this.model.getNotificationId()), { trigger: true });
            const navigationPanelType = getCurrentNavigationPanelType(getAppState());
            if (navigationPanelType === ENavigationPanelTypes.NP_COMPACT_TYPE) {
                dispatch(panelTypeSetAction(ENavigationPanelTypes.NP_COMPACT_BIG_TYPE));
                dispatch(isOpenSetAction(false));
            }
        } else {
            var group = this.getGroup();
            var dashboardId = (group) ? group.data('board') : this.notificationModel.dashboardId;
            var cardId = this.notificationModel.card.id;
            var currentBoardId = getActiveBoardId(store.getState());
            if (currentBoardId === dashboardId) {
                App.controller.cleverOpenCard(dashboardId, cardId);
            } else {
                App.router.navigate(App.router.getAssignedToMePanelUrl(ERoutePrefixes.notification, this.model.getNotificationId()), {trigger: true});
            }
            const navigationPanelType = getCurrentNavigationPanelType(getAppState());
            if (navigationPanelType === ENavigationPanelTypes.NP_COMPACT_TYPE) {
                dispatch(panelTypeSetAction(ENavigationPanelTypes.NP_COMPACT_BIG_TYPE));
                dispatch(isOpenSetAction(false));
            }
        }
        dispatch(segmentTrackAction(SegmentInAppNotificationEvent.CLICKED_NOTIFICATION_PLATE, {
                name: this.notificationModel.modelType,
                value: this.notificationModel.propertyName
            }
        ));
    },

    onContextMenu: function(e, notification) {
        e.preventDefault();
        this.closeNotification(notification);
        this.setSeen();
    },

    closeNotification: function (notification) {
        notification.close();
        dispatch(segmentTrackAction(SegmentInAppNotificationEvent.CLOSED_NOTIFICATION, {
                name: this.notificationModel.modelType,
                value: this.notificationModel.propertyName
            }
        ));
    }
});
