import { IApplicationState } from '../../../types/types';
import { ERoutes } from '../constants';

export const getIsLinkCardActive = (
    state: IApplicationState
): boolean => {
    const { route } = state.router;
    return (
        route === ERoutes.LINK_CARD ||
        route === ERoutes.LINK_CARD_ACTIVITY ||
        route === ERoutes.LINK_CARD_TIMING
    );
}
