import { TThemeId } from '../types';

export interface IRestTheme {
    id?: TThemeId;
    libraryCssClass?: string;
    backgroundOption?: EThemeBackgroundOption;
    backgroundImage?: string;
    jsonRest?: boolean;
    userId?: number;
}

export enum EThemeBackgroundOption {
    DEFAULT = '',
    REPEAT = 'repeat',
    SCALE = 'scale'
}
