import * as React from 'react';
import './_LanguageFeatureModal.scss';
import { Modal } from 'kui';
import { ILanguageFeatureProps } from './types';
import {
    LANGUAGE_FEATURE_MODAL_MEDIA_LINK,
    LANGUAGE_FEATURE_MODAL_TEXT,
    LANGUAGE_FEATURE_MODAL_TITLE
} from './constants';

export const LanguageFeatureModal = ({
    onClose,
    onDidMount
}: ILanguageFeatureProps) => {
    const className = 'language-feature-modal';

    React.useEffect(() => {
        onDidMount();
    }, []);

    return (
        <Modal
            className={className + ' handle-click-ignore'}
            title={''}
            variant={'release'}
            onClose={onClose}
            release={{
                footer: {
                    follow: {
                        socials: [],
                        text: null
                    },
                    stars: null,
                },
                slides: [{
                    description: LANGUAGE_FEATURE_MODAL_TEXT,
                    src: LANGUAGE_FEATURE_MODAL_MEDIA_LINK,
                    title: LANGUAGE_FEATURE_MODAL_TITLE,
                    variant: 'video',
                }],
            }}
        />
    );
};
