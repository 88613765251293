import { connect } from 'react-redux';
import { EMPTY_ARRAY, IApplicationState } from '../../../../../../../types/types';
import { ICardTagsHOCProps } from './types';
import { ICardTagsEvents, ICardTagsFields } from '../../components/CardTagsSection/types';
import { CardTagsSection } from '../../components/CardTagsSection/CardTagsSection';
import { onDelete } from './events/onDelete';
import { onSort } from './events/onSort';
import { getCardDetailsTagsCreateSelector } from '../../selectors/getCardTags';
import { getSectionType } from '../../../selectors/getSectionType';
import { onAddClicked } from './events/onAddClicked';
import { getBoardIsCardDefaultProperty } from '../../../../../../../store/model/board/selectors/getBoardIsCardDefaultProperty';
import { EAsidePanelProperty } from '../../../../asidePanel/components/AsidePanel/types';
import { onRename } from './events/onRename';
import { onRenameClicked } from './events/onRenameClicked';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import {
    getBoardIsCardRequiredProperty
} from '../../../../../../../store/model/board/selectors/getBoardIsCardRequiredProperty';
import { getAuthUser } from '../../../../../../../store/model/authUser/selectors/getAuthUser';
import { onModalClose } from './events/onModalClose';
import { onToggle } from './events/onToggle';

const mapStateToProps = () => {
    const getCardTags = getCardDetailsTagsCreateSelector();
    return (
        state: IApplicationState,
        ownProps: ICardTagsHOCProps,
    ): ICardTagsFields => {
        const { boardId, cardId, sectionType: sectionTypeOwn } = ownProps;
        const tags = getCardTags(state, cardId);
        const sectionType = getSectionType(state, boardId, cardId, sectionTypeOwn);
        const { allowCardTagCount } = getAuthUser(state);

        return {
            aloudCount: allowCardTagCount,
            boardId,
            cardId,
            isDefaultProperty: getBoardIsCardDefaultProperty(state, boardId, EAsidePanelProperty.CARD_TAGS),
            isRequired: getBoardIsCardRequiredProperty(state, boardId, EAsidePanelProperty.CARD_TAGS),
            tags: tags && tags.length
                ? tags
                : sectionType !== ESettingsSectionType.READONLY
                    ? EMPTY_ARRAY
                    : null,
            sectionType,
        };
    };
};

const mapDispatchToProps = (
    dispatch: any,
    ownProps: ICardTagsHOCProps,
): ICardTagsEvents => {
    const { cardId } = ownProps;
    return {
        onModalClose: () => dispatch(onModalClose()),
        onToggle: (tag) => dispatch(onToggle(cardId, tag)),
        onDelete: (tag) => dispatch(onDelete(cardId, tag)),
        onSort: (tag, tags) => dispatch(onSort(cardId, tags)),
        onAddClicked: () => dispatch(onAddClicked()),
        onRename: (tag, name) => dispatch(onRename(cardId, tag, name)),
        onRenameClicked: () => dispatch(onRenameClicked())
    };
};

export const CardTagsSectionHOC = connect(
    mapStateToProps,
    mapDispatchToProps,
)(CardTagsSection);

CardTagsSectionHOC.displayName = 'CardTagsSectionHOC';
