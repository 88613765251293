import { Dispatch, ThunkAction } from 'app/types/actions';
import { EQuickStartGuideType } from '../../../../main/navigationPanel/constants';
import {
    quickStartGuideSetAction
} from '../../../../main/navigationPanel/store/navigationPanel/actions/quickStartGuideSetAction';
import { segmentTrackAction, SegmentUserEvent } from '../../../../../../middlewares/segment';
import { SegmentUserOption } from '../../../../../../middlewares/segment/trackEntities/userEvents';

export function onClickGuide (
    guide: EQuickStartGuideType,
    closeProfile: () => void
): ThunkAction {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(segmentTrackAction(SegmentUserEvent.QUICK_START_GUIDE_OPENED, {
            name: SegmentUserOption.VALUE,
            value: guide
        }));
        closeProfile();
        setTimeout(() => { // передать фокус в панель после закрытия дропдауна
            dispatch(quickStartGuideSetAction(guide));
        }, 100);
    };
    return action
};
