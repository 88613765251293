import { Dispatch, ThunkAction } from 'app/types/actions';
import { IGetState } from 'app/types/types';
import { SegmentCardEvent, segmentTrackAction, SegmentUserEvent } from '../../../../../../../../middlewares/segment';
import { showGoogleCalendarsList } from '../effects/showGoogleCalendarsList';
import { authManagerInstance } from 'app/helper/authorisation/google/AuthManager';

export const onOpen = (): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.PUSH_TO_GOOGLE_CALENDAR_BUTTON_CLICKED));
        const onShow = () => { dispatch(segmentTrackAction(SegmentUserEvent.PERMISSION_CALENDAR_SCOPE_SHOWN)) }
        const onRequest = () => { dispatch(segmentTrackAction(SegmentUserEvent.PERMISSION_CALENDAR_SCOPE_REQUESTED)) }
        const onCancel = () => { dispatch(segmentTrackAction(SegmentUserEvent.PERMISSION_CALENDAR_SCOPE_CANCELED)) }
        const onApprove = () => { dispatch(segmentTrackAction(SegmentUserEvent.PERMISSION_CALENDAR_SCOPE_GRANTED)) }
        authManagerInstance.checkAndRequestCalendar(() => {
            dispatch(showGoogleCalendarsList());
        }, onShow, onCancel, onRequest, onApprove);
    };
    return action;
};
