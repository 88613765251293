import { ILibTheme } from '../types';

export const AT_LIB_THEMES_LIST_ACTION_SET = 'LIB_THEMES_LIST_ACTION_SET';

export interface ILibThemesListActionSetAction {
    type: typeof AT_LIB_THEMES_LIST_ACTION_SET,
    list: ILibTheme[]
}

export type TLibThemesAction = (ILibThemesListActionSetAction);
