import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../types/types';
import { getCard } from '../../../../../../../../store/model/selectors/getCard';
import { getBoard } from '../../../../../../../../store/model/selectors/getBoardById';
import { cardsGetRest } from '../../../../../../../../rest/effects/card/card/api/helper/cardsGetRest';
import { IRestDashboard } from '../../../../../../../../types/rest/IRestDashboard';
import { fetchHandler } from '../../../../../../../../util/fetchHandler';
import { getCommentById } from '../../../../../../aside_panel/cardDetails/CommentsSection/selectors/getCommentById';
import { getList } from '../../../../../../../../store/model/list/selectors/getList';
import { root } from '../../../../../../../../store/constants';
import { getCardIdFromUrl } from '../../../../../../../../helper/urlHelper/getCardIdFromUrl';
import { getListIdFromUrl } from '../../../../../../../../helper/urlHelper/getListIdFromUrl';
import { getBoardIdFromUrl } from '../../../../../../../../helper/urlHelper/getBoardIdFromUrl';
import { getCommentIdFromUrl } from '../../../../../../../../helper/urlHelper/getCommentIdFromUrl';
import { getCardIdsFromUrl } from '../../../../../../../../helper/urlHelper/getCardIdsFromUrl';

export const onLoad = (
    href: string,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const cardIds = getCardIdsFromUrl(href);
        if (cardIds && cardIds.length) {
            const promises: Promise<any>[] = [];
            cardIds.forEach(cardId => {
                const card = getCard(state, cardId);
                if (!card) {
                    promises.push(dispatch(cardsGetRest([cardId])))
                }
            });
            return Promise.all(promises);
        } else {
            const cardId = getCardIdFromUrl(href);
            if (cardId) {
                const commentId = getCommentIdFromUrl(href);
                const card = getCard(state, cardId);
                if (!card || card && commentId && !getCommentById(state, cardId, commentId)) {
                    return dispatch(cardsGetRest([cardId]));
                }
            } else {
                const boardId = getBoardIdFromUrl(href);
                if (boardId) {
                    const listId = getListIdFromUrl(href);
                    const board = getBoard(state, boardId);
                    if (!board || board && listId && !getList(state, listId)) {
                        return root.App.controller.loadBoardToStore(boardId, true, true, true, null, true);
                    } else {
                        return Promise.resolve();
                    }
                }
            }
        }
        root.App.controller.magicLinksFakeLinksHelper.addLink(href);
        return Promise.resolve();
    }
    return action;
}
