import { IDriveDocsUpdateDriveDocAction } from './types';
import { AT_UPDATE_DRIVE_DOC } from './constants';
import { IDriveDoc } from '../../card/types/IDriveDoc';
import { TDriveDocId } from '../../../../types/rest/IRestDriveDoc';

export const driveDocUpdate = (
    driveDocId: TDriveDocId,
    driveDoc: IDriveDoc
): IDriveDocsUpdateDriveDocAction => ({
    type: AT_UPDATE_DRIVE_DOC,
    driveDocId,
    driveDoc
});
