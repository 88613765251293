import * as React from 'react';
import { createRoot, Root } from 'react-dom/client';
import './_OfflineBlocker.scss';
import { EmptyDummy } from '../../base/components/EmptyDummy/EmptyDummy';
import { OFFLINE_TEXT, OFFLINE_TITLE } from './constants';

export class OfflineBlocker {
    root: Root;

    constructor () {
        let el = document.querySelector('.offline-blocker-placeholder');
        if (!el) {
            el = document.createElement('div');
            el.className = 'offline-blocker-placeholder';
            document.body.append(el);
        }
        this.root = createRoot(el!);
        this.render();
    }

    render () {
        this.root.render(
            <EmptyDummy
                className={'offline-blocker'}
                icon={'warning'}
                text={OFFLINE_TEXT}
                title={OFFLINE_TITLE}
            />
        );
    }

    remove () {
        this.root.unmount();
    }
}
