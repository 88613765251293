import * as React from 'react';
import './_MyWorkSettingsShowSection.scss';
import { IMyWorkSettingsShowSectionProps } from './types';
import { CLASS_MY_WORK } from '../../../../constants';
import {
    MY_WORK_SETTINGS_SHOW_ARCHIVED_CARDS_LABEL,
    MY_WORK_SETTINGS_SHOW_COLUMNS_TITLE_LABEL,
    MY_WORK_SETTINGS_SHOW_EMPTY_GROUPS_LABEL,
    MY_WORK_SETTINGS_SHOW_TITLE
} from './constants';
import { SettingsSection } from '../../../../../../base/components/SettingsSection/SettingsSection';
import { Switch } from 'kui';

export const MyWorkSettingsShowSection = ({
    isShowArchivedCards,
    isShowColumnsTitles,
    isShowEmptyGroups,
    onArchivedToggle,
    onEmptyGroupsToggle,
    onTitlesToggle
}: IMyWorkSettingsShowSectionProps) => {
    const className = CLASS_MY_WORK + '-settings-show-section';
    const classSwitch = className + '__switch';
    return (
        <SettingsSection
            className={className}
            title={MY_WORK_SETTINGS_SHOW_TITLE}
        >
            <span className={classSwitch}>
                {MY_WORK_SETTINGS_SHOW_COLUMNS_TITLE_LABEL}
                <Switch
                    checked={isShowColumnsTitles}
                    onChange={onTitlesToggle}
                />
            </span>
            <span className={classSwitch}>
                {MY_WORK_SETTINGS_SHOW_EMPTY_GROUPS_LABEL}
                <Switch
                    checked={isShowEmptyGroups}
                    onChange={onEmptyGroupsToggle}
                />
            </span>
            <span className={classSwitch}>
                {MY_WORK_SETTINGS_SHOW_ARCHIVED_CARDS_LABEL}
                <Switch
                    checked={isShowArchivedCards}
                    onChange={onArchivedToggle}
                />
            </span>
        </SettingsSection>
    );
}
