import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { filterPanelActionSetAction } from '../../../../../store/filterPanelBoards/actions/filterPanelActionSetAction';
import { editedFilterSet } from '../../../../../store/filterPanel/actions/editedFilterSet';
import { FP_EDITED_FILTER_INITIAL_VALUE, INITIAL_EDITED_FILTER_ID } from '../../../../../constants';
import { filterPanelBoardsSelector } from '../../../../../store/selectors/filterPanelBoardsSelector';
import { IGetState } from '../../../../../../../../../types/types';
import { savedFilterIdsSet } from '../../../../../store/filterPanel/actions/savedFilterIdsSet';
import { removeSavedFilter } from '../../../../../effects/removeSavedFilter';
import { SegmentFilterEvent } from '../../../../../../../../../middlewares/segment/trackEntities/filterEvents';
import { filterSegmentTrackAction } from '../../../../../effects/filterSegmentTrackAction';
import { removeFilterFromSortedSavedFilterIds } from '../../effects/removeFilterFromSortedSavedFilterIds';

export const onRemoveFilter: ActionCreator<ThunkAction> = (
    filterId: number,
    boardId: number
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (filterId !== INITIAL_EDITED_FILTER_ID) {
            let { savedFilterIds } = filterPanelBoardsSelector(getState(), boardId);
            savedFilterIds = savedFilterIds.filter(item => item !== filterId);
            dispatch(filterPanelActionSetAction(boardId, savedFilterIdsSet(savedFilterIds)));
            dispatch(removeSavedFilter(filterId))
        }
        dispatch(filterPanelActionSetAction(boardId, editedFilterSet(FP_EDITED_FILTER_INITIAL_VALUE)));
        dispatch(filterSegmentTrackAction(SegmentFilterEvent.SAVED_FILTER_DELETED, boardId));
        dispatch(removeFilterFromSortedSavedFilterIds(boardId, filterId));
    };
    return action;
};
