import { segmentTrackAction, SegmentNotificationsEvent } from '../../../../../../../../middlewares/segment';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';

export const onWarning = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(segmentTrackAction(SegmentNotificationsEvent.FORCE_WARNING));
    };
    return action;
};
