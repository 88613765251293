import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { ActionCreator } from 'redux';
import { deleteNotificationBeforeDueDateById } from './rest/deleteNotificationBeforeDueDateById';
import { getEditedNotificationsByCardId } from '../../../selectors/getEditedNotificationsByCardId';
import { getAnEmptyNotification } from '../../../utils/notificationUtils';
import { IGetState, TCardId, TNBDId } from '../../../../../../../../../types/types';
import { TStatus } from '../../../../../../../../../types/model';
import { notificationsBeforeDueDatesForCardUpdate } from '../../../state/effects/notificationsBeforeDueDatesForCardUpdate';

export const initCardNotificationsEditedStateEffect: ActionCreator<ThunkAction> = (
    cardId: TCardId,
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        const state = getState();
        const newEditedState = state.model.cards[cardId].notificationBeforeDueDates.map(
            (notification) => ({
                ...notification,
                status: TStatus.STATUS_ACTIVE
            })
        );
        dispatch(notificationsBeforeDueDatesForCardUpdate(cardId, newEditedState));
    };
    return action;
};

export const createFirstCardNotificationsEditedStateEffect: ActionCreator<ThunkAction> = (
    cardId: TCardId,
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        const notifications = getEditedNotificationsByCardId(getState(), cardId);
        if (!notifications.length) {
            const emptyNotification = getAnEmptyNotification();
            dispatch(notificationsBeforeDueDatesForCardUpdate(cardId, [emptyNotification]))
        }
    };
    return action;
};

export const removeNotificationByIdEffect: ActionCreator<ThunkAction> = (
    cardId: TCardId,
    notificationId: TNBDId
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        return dispatch(deleteNotificationBeforeDueDateById(cardId, notificationId));
    };
    return action;
};
