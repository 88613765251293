import {ActionCreator} from 'redux';
import {Dispatch, ThunkAction} from '../../../../../../../types/actions';
import {IGetState} from '../../../../../../../types/types';
import { boardAddClickedSegmentEvent } from '../../../effects/segmentEvents';
import { addFeatureTrigger } from '../../../../../polls/featurePoll/effects/addFeatureTrigger';
import { ERateFeature } from '../../../../../../../types/rest/IUserMeta';

export const onAddClick: ActionCreator<ThunkAction> = (
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(boardAddClickedSegmentEvent());
        dispatch(addFeatureTrigger(ERateFeature.NEW_NAV_PANEL));
    };
    return action;
};
