import { IGetState, TCardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { deleteAttachment } from '../../../../../../../../rest/effects/card/attacments/deleteAttachment';
import { TDriveDocId } from '../../../../../../../../types/rest/IRestDriveDoc';

export const onDelete = (
    cardId: TCardId,
    driveDocId: TDriveDocId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        dispatch(deleteAttachment(cardId, driveDocId)).then(() => {
            dispatch(segmentTrackAction(SegmentCardEvent.ATTACHED_FILE_REMOVED));
        });
    };
    return action;
};
