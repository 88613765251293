import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState } from '../../../../../../../types/types';
import { segmentTrackAction, SegmentUserEvent } from '../../../../../../../middlewares/segment';

export const onShow: ActionCreator<ThunkAction> = () => {
    const action = (dispatch: Dispatch, getState: IGetState) => {
        dispatch(segmentTrackAction(SegmentUserEvent.COOKIE_SCREEN));
    };
    return action;
};
