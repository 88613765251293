import { Dispatch } from '../../../../../../../../../types/actions';
import { REST_PWC_NEW_IMPORT, REST_PWC_NEW_IMPORT_PREPARE } from '../../../../../../../dialogs/openBoards/constants';
import { IGetState, TBoardId, TCardId } from '../../../../../../../../../types/types';
import { fetchHandler } from '../../../../../../../../../util/fetchHandler';
import Util from '../../../../../../../../../util/util';
import { getRestHeadersDelete, getRestHeadersPost } from '../../../../../../../../../rest/helpers/getRestHeadersHelper';
import { getBoardCardsByStatuses } from '../../../../../../../../../store/model/selectors/getBoardCardsByStatuses';
import { TStatus } from '../../../../../../../../../types/model';
import { PartLoader } from '../../../../../../../../../util/part_loader';
import { getBoardUsers } from '../../../../../../../../../store/model/selectors/getBoardUsers';
import { getFirstListInBoard } from '../../../../../../../../../store/model/lists/selectors/getFirstListInBoard';
import { getNextCardOrderNumber } from '../../../../../../../../../store/model/list/selectors/getNextCardOrderNumber';
import { IRestCard } from '../../../../../../../../../types/rest/IRestCard';
import { getCardChecklists } from '../../../../../../../../../store/model/checklists/checklists/selectors/getCardChecklists';
import { getChecklistItems } from '../../../../../../../../../store/model/checklists/checklist/selectors/getChecklistItems';
import { IRestChecklist } from '../../../../../../../../../types/rest/IRestChecklist';
import { IChecklist, IChecklistItem } from '../../../../../../../../../store/model/checklists/checklist/types';
import { IRestChecklistItem } from '../../../../../../../../../types/rest/IRestChecklistItem';
import { itemRestPatch } from '../../../../../../../../../rest/effects/card/checklist/api/itemRestPatch';
import * as moment from 'moment';
import { getRest } from 'app/rest/effects/card/card/api/getRest';
import { processRestCardData } from 'app/rest/effects/card/card/api/helper/processRestCardData';
import { ICards } from 'app/store/model/cards/types';
import { sortChecklistItems } from 'app/view/react_components/aside_panel/cardDetails/ChecklistSection/hocs/CardChecklistHOC/helpers/sortChecklistItems';
import { ECardChecklistOrderMode } from 'app/types/rest/IUserMeta';
import { patchRest as patchRestCard } from 'app/rest/effects/card/card/api/patchRest';
import { postRest as postRestCard } from 'app/rest/effects/card/card/api/postRest';
import { itemRestPost as restPostChecklistItem } from 'app/rest/effects/card/checklist/api/itemRestPost';
import { restPost as restPostChecklist } from 'app/rest/effects/card/checklist/api/restPost';
import { calculateChecklistsStats } from 'app/store/model/selectors/calcCardChecklistStatsByCardId';
import { IChecklists } from 'app/store/model/checklists/checklists/types';
import { ISharedUser } from 'app/types/rest/ISharedUser';
import { cardsUpdate } from 'app/store/model/actionCreators/cardsUpdate';

export interface IPwcChecklistTemplateImportContextRest {
    templateCount: number
    sessionId: string
    fileId: string
};

export interface IPwcChecklistTemplateImportCsvTemp {
    csvRecord: string[]
}

export interface IValidateCardRecordError {
    name: string
}

export interface IValidateCardRecordWarning {
    name: string
}

export interface IValidatingResult {
    warnings: IValidateCardRecordWarning[];
    errors: IValidateCardRecordError[];
}

export class PwcChecklistTemplatesImport {

    private readonly DATE_FORMAT = 'DD/MM/yyyy';

    private readonly CARD_TITLE_COLUMN_OFFSET = 0;
    private readonly CARD_TEMPLATE_COLUMN_OFFSET = 1;
    private readonly CARD_DESCRIPTION_COLUMN_OFFSET = 2;
    private readonly CARD_DUE_DATE_COLUMN_OFFSET = 3;
    private readonly CARD_REVIEW_ASSIGNERS_COLUMN_OFFSET = 4;
    private readonly CARD_CHECKLISTS_COLUMN_OFFSET = 5;
    private readonly CELL_COUNT_FOR_CARD = 6;

    private readonly WORD_FOR_ASSIGN_USER_ON_CHECK_LIST_ITEM = 'review';

    private readonly boardId: TBoardId;
    private readonly fileId: string;
    private cardTemplates: IRestCard[];
    private readonly getState: IGetState;
    private readonly dispatch: Dispatch;
    private boardUsers: ISharedUser[];
    private readonly onImported: (warnings: IValidateCardRecordWarning[]) => void;
    private readonly onError: (errors: IValidateCardRecordError[]) => void;
    private readonly onProgressCallback: (progressPercent: number) => void;
    private firstListId: number;
    private errors: IValidateCardRecordError[];
    private warnings: IValidateCardRecordWarning[] = [];
    private createdChecklists: IChecklists = {}
    private sessionId: string;
    private totalStepCount: number = 0;
    private currentStepCount: number = 0;

    constructor(boardId: TBoardId,
                fileId: string,
                dispatch: Dispatch,
                getState: IGetState,
                onImported: (warnings: IValidateCardRecordWarning[]) => void,
                onError: (errors: IValidateCardRecordError[]) => void,
                onProgressCallback: (progressPercent: number) => void
    ) {
        this.boardId = boardId;
        this.fileId = fileId;
        this.getState = getState;
        this.dispatch = dispatch;
        this.loadTemplates().then(cardsRest => {
            this.cardTemplates = cardsRest;
            const cards: ICards = {};
            cardsRest.forEach(card => {
                cards[card.id] = card;
            });
            dispatch(cardsUpdate(cards));
            cardsRest.forEach((card: IRestCard) => {
                dispatch(processRestCardData(card));
            })
        })

        this.boardUsers = getBoardUsers(getState(), boardId);
        this.firstListId = getFirstListInBoard(getState(), this.boardId) &&
            getFirstListInBoard(getState(), this.boardId).id;
        this.onError = onError;
        this.onImported = onImported;
        this.onProgressCallback = onProgressCallback;
    }

    private loadTemplates = () => {
        const templateIds = getBoardCardsByStatuses(this.getState(), this.boardId, [TStatus.STATUS_SERVICE_TEMPLATE])
            .map(template => template.id);
        const loadPromises: Promise<any>[] = [];
        templateIds.forEach(templateId => {
            loadPromises.push(this.dispatch(getRest(templateId)))
        })
        return Promise.all(loadPromises);
    }

    public import = () => {
        return this.prepareImport()
            .then((context: IPwcChecklistTemplateImportContextRest) => {
                this.sessionId = context.sessionId;
                return new Promise((resolve) => {
                    setTimeout(() => {
                        resolve(context);
                    }, 3000)
                })
            })
            .then((context: IPwcChecklistTemplateImportContextRest) => {
                return new PartLoader().loadPart(REST_PWC_NEW_IMPORT + '?dashboardId=' + this.boardId + '&sessionId=' + context.sessionId)
            })
            .then((pwcChecklistTemplateImportCsvTempList: IPwcChecklistTemplateImportCsvTemp[]) => {
                const csvRows = pwcChecklistTemplateImportCsvTempList.map((value => value.csvRecord))
                const cardRecords = this.getCardRecordsFromCsvRows(csvRows);
                if (this.isCardRecordsValid(cardRecords)) {
                    return this.createCards(cardRecords).then(() => {
                        return new PartLoader().loadPart(REST_PWC_NEW_IMPORT + '?dashboardId=' + this.boardId + '&sessionId=' + this.sessionId,
                            undefined,undefined, undefined,
                            getRestHeadersDelete()).then(() => {
                            this.onImported(this.warnings);
                        })
                    })
                } else {
                    this.onError(this.errors);
                }
            })
    }

    private prepareImport = () => {
        const templateCount = getBoardCardsByStatuses(this.getState(), this.boardId, [TStatus.STATUS_SERVICE_TEMPLATE]).length;
        const columnCount = templateCount * this.CELL_COUNT_FOR_CARD;

        return fetchHandler(Util.getApiUrl(REST_PWC_NEW_IMPORT_PREPARE + this.boardId), {
            ...getRestHeadersPost(),
            body: JSON.stringify({
                columnCount,
                fileId: this.fileId
            })
        })
    }

    private getCardRecordsFromCsvRows = (csvRows: string[][]): ICardRecord[] => {
        let cardRecords: ICardRecord[] = [];
        csvRows.forEach((row) => {
            for (let i = 0; i < this.cardTemplates.length; i++) {
                cardRecords.push(this.readCardRecord(row, i));
            }
        })
        cardRecords = cardRecords.filter(value => !!value)
        this.totalStepCount = cardRecords.length;
        return cardRecords;
    }

    private readCardRecord = (row: string[], cardIndex: number): ICardRecord => {
        const startColumnIndex = cardIndex * this.CELL_COUNT_FOR_CARD;

        const title = this.getCell(row, startColumnIndex + this.CARD_TITLE_COLUMN_OFFSET);
        if (!title) return;
        return {
            title: title,
            description: this.getCell(row, startColumnIndex + this.CARD_DESCRIPTION_COLUMN_OFFSET),
            templateName: this.getCell(row, startColumnIndex + this.CARD_TEMPLATE_COLUMN_OFFSET),
            dueDate: this.getCell(row, startColumnIndex + this.CARD_DUE_DATE_COLUMN_OFFSET),
            assigneeEmail: this.getCell(row, startColumnIndex + this.CARD_REVIEW_ASSIGNERS_COLUMN_OFFSET),
            checklistNames: this.getCell(row, startColumnIndex + this.CARD_CHECKLISTS_COLUMN_OFFSET).split(','),
        }
    }

    private isCardRecordsValid = (cardRecords: ICardRecord[]) => {
        return cardRecords.every((cardRecord) => {
            const validatingResult = this.validateCardRecord(cardRecord)
            this.warnings.push(...validatingResult.warnings)
            if (validatingResult.errors.length !== 0) {
                this.errors = validatingResult.errors;
                return false
            }
            return validatingResult.errors.length === 0
        })
    }

    private validateCardRecord = (cardRecord: ICardRecord): IValidatingResult => {
        const errors: IValidateCardRecordError[] = [];
        const warnings: IValidateCardRecordWarning[] = [];

        const cardTemplates = this.cardTemplates.filter(template => template.name.toLowerCase().trim() === cardRecord.templateName.toLowerCase().trim());
        if (cardTemplates.length === 0) {
            errors.push({name: `There's no template with such name: ${cardRecord.templateName}`})
        } else if (cardTemplates.length > 1) {
            errors.push({name: `There are several templates with the same name: ${cardRecord.templateName}`})
        } else {
            const checklists = cardTemplates[0].checklists;
            const recordChecklistNames = cardRecord.checklistNames.map(checklistName => checklistName.trim().toLowerCase());
            const cardChecklistsNames = checklists.map(checklist => checklist.name.trim().toLowerCase());

            recordChecklistNames.forEach(name => {
                if(!!name) {
                    if (!cardChecklistsNames.includes(name)) {
                        warnings.push({name: `There's no checklist with such name: ${name} in a template ${cardTemplates[0].name}`})
                    }
                }
            })
        }

        /* сначала надо было валидировать, потом не стало не надо, оставил код, чтобы если что просто раскоментировать его

        if (this.boardUsers.filter(user => user.email.toLowerCase().trim() === cardRecord.assigneeEmail.toLowerCase().trim()).length === 0) {
            warnings.push({name: `There's no user with such email on this board: ${cardRecord.assigneeEmail}`})
        }

        if (!moment(cardRecord.dueDate, this.DATE_FORMAT).isValid()) {
            warnings.push({name: `Invalid date: ${cardRecord.dueDate}`})
        }*/
        return {
            warnings,
            errors
        };

    }

    private getCell = (row: string[], index: number) => {
        return row[index];
    }

    private createCards = (cardRecords: ICardRecord[]) => {
        const createCardPromises = cardRecords.map(cardRecord => {
            return this.createCard(cardRecord);
        })
        return Promise.all(createCardPromises)
    }

    private onProgress = () => {
        this.currentStepCount += 0.25; // у нас 4 шага на карту 4*0,25 =1
        let p = Math.round(this.currentStepCount / this.totalStepCount * 100.0)
        this.onProgressCallback(p);
    }

    private createChecklists = (cardRecord: ICardRecord, cardTemplate: IRestCard, createdCard: IRestCard) => {
        const recordChecklistNames = cardRecord.checklistNames.map(checklistName => checklistName.trim().toLowerCase());
        const templateChecklists = Object.values(getCardChecklists(this.getState(), cardTemplate.id))
            .filter(checklist => recordChecklistNames.includes(checklist.name.trim().toLowerCase()));
        const createChecklistsPromises =  templateChecklists.map((templateChecklist) => {
            return this.createChecklist(cardRecord, cardTemplate, createdCard, templateChecklist)
        });
        return Promise.all(createChecklistsPromises);
    }

    private createChecklist = (cardRecord: ICardRecord, cardTemplate: IRestCard, createdCard: IRestCard, templateChecklist: IChecklist) => {
        const checklistCopy: IRestChecklist = JSON.parse(JSON.stringify(templateChecklist));
        checklistCopy.id = null
        checklistCopy.cardId = createdCard.id;
        checklistCopy.checkItems = null;
        return this.dispatch(restPostChecklist(createdCard.id, checklistCopy)).then((newChecklist: IRestChecklist) => {
            if(!this.createdChecklists[newChecklist.cardId]) {
                this.createdChecklists[newChecklist.cardId] = {};
                if(!this.createdChecklists[newChecklist.cardId][newChecklist.id]) {
                    this.createdChecklists[newChecklist.cardId][newChecklist.id] = {}
                }
            }
            this.createdChecklists[newChecklist.cardId][newChecklist.id] = newChecklist

            return this.createChecklistItems(cardRecord, cardTemplate, createdCard, templateChecklist, newChecklist)
        })
    }

    private createChecklistItems = (cardRecord: ICardRecord, cardTemplate: IRestCard,
                                    createdCard: IRestCard, templateChecklist: IChecklist,
                                    newChecklist: IChecklist) => {
        const templateChecklistItems = getChecklistItems(this.getState(), cardTemplate.id, templateChecklist.id);

        if (templateChecklistItems) {
            const templateChecklistItemsArray = sortChecklistItems(Object.values(templateChecklistItems), ECardChecklistOrderMode.MANUAL);
            const dueDate = moment.unix(createdCard.dueDate)
            let nextDayOffset = 0;
            const createChecklistsItemsPromises = templateChecklistItemsArray.map(templateChecklistItem => {
                const thisOffset = nextDayOffset;
                nextDayOffset+= templateChecklistItem.weight - 1;
                return this.createChecklistItem(cardRecord, cardTemplate, createdCard,
                    newChecklist, templateChecklistItem, dueDate, thisOffset)
            })
            return Promise.all(createChecklistsItemsPromises);
        }
    }

    private createChecklistItem = (cardRecord: ICardRecord, cardTemplate: IRestCard,
                                   createdCard: IRestCard, newChecklist: IChecklist,
                                   templateChecklistItem: IChecklistItem, dueDate: moment.Moment,
                                   dayOffset: number) => {
        const checklistItemCopy = JSON.parse(JSON.stringify(templateChecklistItem));
        checklistItemCopy.id = null;
        checklistItemCopy.checklistId = newChecklist.id
        const isNeedAssign = checklistItemCopy.name.toLowerCase().trim().includes(this.WORD_FOR_ASSIGN_USER_ON_CHECK_LIST_ITEM);

        if(createdCard.dueDate) {
            let checklistDueDate = moment.unix(createdCard.dueDate)
                .add(-22, 'day')
                .add(dayOffset + checklistItemCopy.weight - 1, 'day');

            checklistItemCopy.dueDate = checklistDueDate.unix();
        }
        checklistItemCopy.weight = 1;
        return this.dispatch(restPostChecklistItem(createdCard.id, checklistItemCopy))
            .then((checklistItem: IRestChecklistItem) => {
                if(!this.createdChecklists[newChecklist.cardId][newChecklist.id].checkItems) {
                    this.createdChecklists[newChecklist.cardId][newChecklist.id].checkItems = {};
                }
                this.createdChecklists[newChecklist.cardId][newChecklist.id].checkItems[checklistItem.id] = checklistItem;
                return checklistItem
            })
            .then((checklistItem: IRestChecklistItem) => {
                if(isNeedAssign) {
                    return this.createAssignee(cardRecord, cardTemplate, createdCard,
                        newChecklist, templateChecklistItem, checklistItem)
                }
            })
    }

    private createAssignee = (cardRecord: ICardRecord, cardTemplate: IRestCard,
                              createdCard: IRestCard, newChecklist: IChecklist,
                              templateChecklistItem: IChecklistItem, createdChecklistItem: IRestChecklistItem) => {
        createdChecklistItem = JSON.parse(JSON.stringify(createdChecklistItem));
        createdChecklistItem.orderNumber = null;
        const assignee = this.boardUsers.find(user => user.email.toLowerCase().trim() === cardRecord.assigneeEmail.toLowerCase().trim())
        if(assignee) {
            return this.dispatch(itemRestPatch(createdCard.id, createdChecklistItem.id,
                {
                    ...createdChecklistItem,
                    assignees: [{sharedUser: assignee}],
                }))
        } else {
            return Promise.resolve();
        }
    }

    private createCard = (cardRecord: ICardRecord) => {
        const cardTemplate = this.cardTemplates.find(template => template.name.trim().toLowerCase() === cardRecord.templateName.trim().toLowerCase());

        return this.dispatch(postRestCard({
            name: cardRecord.title,
            description: cardRecord.description,
            listId: this.firstListId,
            orderNumber: getNextCardOrderNumber(this.getState(), this.firstListId, false),
            status: TStatus.STATUS_ACTIVE,
            dueDate: cardRecord.dueDate && moment(cardRecord.dueDate, this.DATE_FORMAT).isValid() ? moment(cardRecord.dueDate, this.DATE_FORMAT).unix() : undefined
        }, {}))
            .then((newCard: IRestCard) => {
                this.onProgress();
                return newCard;
            })
            .then((newCard: IRestCard) => {
                return this.createCardAssignee(cardRecord, cardTemplate, newCard)
                    .then(() => this.onProgress())
                    .then(() => this.createChecklists(cardRecord, cardTemplate, newCard))
                    .then(() => this.onProgress())
                    .then(() => this.updateChecklistStats(newCard.id))
                    .then(() => this.onProgress())
            })
    }

    private createCardAssignee = (cardRecord: ICardRecord, cardTemplate: IRestCard, createdCard: IRestCard) => {
        const assignee = this.boardUsers.find(user => user.email.toLowerCase().trim() === cardRecord.assigneeEmail.toLowerCase().trim())
        if (assignee) {
            return this.dispatch(patchRestCard(createdCard.id, { assignees: [{sharedUser: assignee}]}));
        } else {
            return Promise.resolve();
        }
    }

    private updateChecklistStats = (
        cardId: TCardId
    ) => {
        const checklistStats = calculateChecklistsStats(this.createdChecklists[cardId]);
        return this.dispatch(patchRestCard(cardId, {
            checklistStats
        }))
    }
}

interface ICardRecord {
    title: string,
    description: string,
    templateName: string,
    dueDate: string,
    assigneeEmail: string,
    checklistNames: string[]
}
