import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId, TTag } from '../../../../../../../../types/types';
import { getCardTags } from '../../../../../../../../store/model/selectors/getCardTags';
import { cardSetTags } from '../../../../../../../../rest/effects/card/card/cardSetTags';
import {
    getAuthUserAllowCardTagCount
} from '../../../../../../../../store/model/authUser/selectors/getAuthUserAllowCardTagCount';
import { root } from '../../../../../../../../store/constants';

export const deleteTag = (
    cardId: TCardId,
    tag: TTag
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const tags = [...getCardTags(state, cardId)];

        const maxCount = getAuthUserAllowCardTagCount(state);
        const count = tags.length;

        const index = tags.indexOf(tag);
        if (index < 0) return Promise.resolve(true);
        if (
            count > maxCount &&
            root.App.controller.allowCounterSnackbarHelper.isShowSnackbar('allowCardTagCount')
        ) {
            return Promise.resolve(false);
        } else {
            tags.splice(index, 1);
        }

        return dispatch(cardSetTags(cardId, tags)).then(() => {
            return Promise.resolve(true);
        });
    };
    return action;
};
