import { TClickManagerCardsSelector } from '../store/types';
import { EViewTypes, VIEW_TYPES_OTHER } from '../../../../const';
import { ICard } from '../../../../store/model/card/types';
import { TStatus } from '../../../../types/model';
import { cardTemplatesGetCardIds } from '../../aside_panel/cardTemplates';
import { getCardIdsForClickManager as listViewGetCardIds } from '../../listView/selectors/getCardIdsForClickManager';
import { getCardIdsForClickManager as kanbanViewGetCardIds } from '../../main/kanbanView/selectors/getCardIdsForClickManager';

export const getCardsIdsSelector = (
    viewType: EViewTypes,
    card: ICard
): TClickManagerCardsSelector => {
    if (card.status === TStatus.STATUS_SERVICE_TEMPLATE) return cardTemplatesGetCardIds;
    if (VIEW_TYPES_OTHER.includes(viewType)) return null;
    switch (viewType) {
        case EViewTypes.LIST_VIEW_TYPE:
            return listViewGetCardIds;
        default:
            return kanbanViewGetCardIds;
    }
};
