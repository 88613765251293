import * as React from 'react';
import './_BoardAttachmentsList.scss';
import { IBoardAttachmentsListProps, IItemRendererProps } from './types';
import { CLASS_BOARD_ATTACHMENTS } from '../../TabAttachments/components/TabAttachments/constants';
import { CardAttachment } from '../../../../cardDetails/AttachmentsSection/components/CardAttachment/CardAttachment';
import { EmptyDummy } from '../../../../../base/components/EmptyDummy/EmptyDummy';
import { VariableSizeList as VirtualList } from 'react-window';
import {
    ATTACHMENT_GRID_HEIGHT,
    ATTACHMENT_HEIGHT,
    ATTACHMENT_TITLE_HEIGHT,
    BOARD_ATTACHMENTS_EMPTY_ALL_TITLE,
    BOARD_ATTACHMENTS_EMPTY_DOCUMENTS_TITLE,
    BOARD_ATTACHMENTS_EMPTY_MEDIA_TITLE, BOARD_ATTACHMENTS_EMPTY_TEXT_DOCUMENTS, BOARD_ATTACHMENTS_EMPTY_TEXT_FILES,
    BOARD_ATTACHMENTS_EMPTY_TEXT_SEARCH,
    BOARD_ATTACHMENTS_EMPTY_TITLE_SEARCH
} from './constants';
import { root } from '../../../../../../../store/constants';
import { GOOGLE_SPACING } from '../../../../../../../const';
import { TBoardAttachmentsTab } from '../../TabAttachments/components/TabAttachments/types';
import { CLASS_ASIDE_PANEL_BODY } from 'app/view/react_components/aside_panel/asidePanel/components/AsidePanelBody/constants';

export const BoardAttachmentsList = ({
    asidePanelWidth,
    attachments,
    isSearch,
    isGridView,
    tab,
    onCardOpen,
    onDownload,
    onLinkClick,
    onLinkCopy
}: IBoardAttachmentsListProps) => {
    const className = CLASS_BOARD_ATTACHMENTS + '-list';
    const classEmpty = className + '__empty-dummy';

    const [height, setHeight] = React.useState(0);

    const listRef = React.useRef(null);

    const getItemSize = (
        index: number
    ): number => {
        const attachmentsLength = attachments[index].attachments.length;
        const attachmentsHeight = isGridView ?
            Math.ceil(attachmentsLength / 3) * ATTACHMENT_GRID_HEIGHT
            : attachmentsLength * ATTACHMENT_HEIGHT;
        const attachmentsTitle = ATTACHMENT_TITLE_HEIGHT;
        return attachmentsHeight + attachmentsTitle + GOOGLE_SPACING * 4; // + paddings
    };

    function itemRerender({
        index,
        style,
        data
    }: IItemRendererProps) {
        const className = CLASS_BOARD_ATTACHMENTS + '-list';
        const classMonth = className + '__month';
        const classTitle = classMonth + '-title';
        const classAttachments = classMonth + '-attachments';
        const classGrid = classAttachments + '--grid';

        const {
            isGridView,
            onDownload,
            onCardOpen,
            onLinkClick,
            monthAttachments
        } = data;
        const { title, attachments } = monthAttachments[index];

        return (
            <div className={classMonth} style={style}>
                <span className={classTitle}>{title}</span>
                <div
                    className={`
                    ${classAttachments}
                    ${isGridView ? classGrid : ''}
                `}
                >
                    {
                        attachments.map(attachment => (
                            <CardAttachment
                                key={attachment.id}
                                attachment={attachment}
                                isAttachmentViewTypeGrid={isGridView}
                                isNonDraggable
                                onCardOpen={() => onCardOpen(attachment.cardId)}
                                onDownload={onDownload}
                                onLinkClick={onLinkClick}
                                onLinkCopy={() => onLinkCopy(attachment.url)}
                            />
                        ))
                    }
                </div>
            </div>
        );
    }

    React.useEffect(() => {
        function onResize() {
            const tab = document.querySelector('.' + CLASS_ASIDE_PANEL_BODY) as HTMLElement;
            let newHeight = tab && tab.offsetHeight || root._innerHeight;
            const header = document.querySelector('.' + CLASS_BOARD_ATTACHMENTS + '-header') as HTMLElement;
            if (header) newHeight -= header.offsetHeight;
            if (height !== newHeight) setHeight(newHeight);
        }

        onResize();

        root.addEventListener('resize', onResize);
        return () => root.removeEventListener('resize', onResize);
    }, []);

    React.useEffect(() => {
        if (attachments.length) {
            listRef.current.resetAfterIndex(0);
        }
    }, [attachments, isGridView]);

    return (
        <>
            {attachments.length ?
                <VirtualList
                    ref={listRef}
                    className={className}
                    height={height}
                    width={asidePanelWidth - GOOGLE_SPACING * 0.5} // - scroll
                    itemCount={attachments.length}
                    itemData={{
                        onCardOpen,
                        onDownload,
                        onLinkClick,
                        isGridView,
                        monthAttachments: attachments
                    }}
                    overscanCount={5}
                    itemSize={getItemSize}
                >
                    {itemRerender}
                </VirtualList>
                : <EmptyDummy
                    className={classEmpty}
                    icon={'attachment'}
                    text={isSearch ? BOARD_ATTACHMENTS_EMPTY_TEXT_SEARCH
                        : tab === TBoardAttachmentsTab.DOCUMENTS ?
                            BOARD_ATTACHMENTS_EMPTY_TEXT_DOCUMENTS
                            : BOARD_ATTACHMENTS_EMPTY_TEXT_FILES
                    }
                    title={isSearch ? BOARD_ATTACHMENTS_EMPTY_TITLE_SEARCH
                        : tab === TBoardAttachmentsTab.DOCUMENTS ?
                            BOARD_ATTACHMENTS_EMPTY_DOCUMENTS_TITLE
                            : tab === TBoardAttachmentsTab.MEDIA ?
                                BOARD_ATTACHMENTS_EMPTY_MEDIA_TITLE
                                : BOARD_ATTACHMENTS_EMPTY_ALL_TITLE
                    }
                />
            }
        </>
    );
}
