import { Store } from 'redux';
import { IApplicationState, TCardId } from '../../../../types/types';
import {
    AT_FILTER_PANEL_ACTION_SET
} from '../../../../view/react_components/aside_panel/filterPanelBoard/store/filterPanelBoards/actions/constants';
import {
    AT_SAVED_FILTER_ACTION_SET
} from '../../../../view/react_components/aside_panel/filterPanelBoard/store/savedFilters/actions/constants';
import { getEnabledFilters } from './selectors/getEnabledFilters';
import {
    matchCardIdsSetAction
} from '../../../../view/react_components/aside_panel/filterPanelBoard/store/filterMatch/actions/matchCardIdsSetAction';
import { CARD_ASSIGNEES_ACTION_SET, ICardAssigneesActionSetAction } from '../../actions/types';
import {
    isMyTaskCardMatchFilters
} from '../../../../view/aside_panel/dashboard_filter/dashboard_filter_helper_card_filters';
import {
    matchCardIdAddAction
} from '../../../../view/react_components/aside_panel/filterPanelBoard/store/filterMatch/actions/matchCardIdAddAction';
import {
    matchCardIdRemoveAction
} from '../../../../view/react_components/aside_panel/filterPanelBoard/store/filterMatch/actions/matchCardIdRemoveAction';
import {
    getFilterIgnoreCardIds
} from '../../../../view/react_components/aside_panel/filterPanelBoard/store/selectors/getFilterIgnoreCardIds';
import {
    filterIgnoreCardIdsClear
} from '../../../../view/react_components/aside_panel/filterPanelBoard/store/filterMatch';
import { TFilterMiddlewareAction } from './types';
import { getIsAssignedToMeActive } from '../../../router/selectors/getIsAssignedToMeActive';
import { AT_CARD_ASSIGNEE_ADD_ACTION, AT_CARD_ASSIGNEE_UPDATE_ACTION } from '../../cardAssignees/actions/constants';
import { MY_WORK_FILTER_BOARD_ID } from '../../../../view/react_components/aside_panel/filterPanelBoard/constants';
import { getMyTaskCardsForFilter } from './selectors/getMyTaskCardsForFilter';
import { getMyTaskCardFilter } from './helpers/getMyTaskCardFilter';
import {
    AT_LOADED_SET, AT_SHOW_ARCHIVED_SET,
    AT_SHOW_BOARDS_SET
} from '../../../../view/react_components/main/myWorkView/store/actions/constants';

// тут любое действие, которое должно привести к сбросу игнорируемых карт
const isActionToResetIgnoreIds = (
    action: TFilterMiddlewareAction
): boolean => {
    return (
        action.type === AT_FILTER_PANEL_ACTION_SET ||
        action.type === AT_SAVED_FILTER_ACTION_SET ||
        action.type === AT_SHOW_ARCHIVED_SET ||
        action.type === AT_SHOW_BOARDS_SET ||
        action.type === AT_LOADED_SET
    )
}

const isCardsFilterRelevantAction = (
    action: TFilterMiddlewareAction
): boolean => {
    return (
        isActionToResetIgnoreIds(action) ||
        action.type === CARD_ASSIGNEES_ACTION_SET
    )
}

const cardsFilter = (
    store: Store<IApplicationState>,
    next: any,
    action: TFilterMiddlewareAction
) => {
    const result = next(action);
    if (isCardsFilterRelevantAction(action)) {
        if (isActionToResetIgnoreIds(action)) {
            store.dispatch(filterIgnoreCardIdsClear());
        }

        const state = store.getState();
        const filters = getEnabledFilters(state, MY_WORK_FILTER_BOARD_ID);

        const cardAssignees = getMyTaskCardsForFilter(state);
        const matchCardIds: TCardId[] = [];

        cardAssignees.forEach(cardAssignee => {
            if (isMyTaskCardMatchFilters(cardAssignee, filters, getFilterIgnoreCardIds(state))) {
                matchCardIds.push(cardAssignee.cardId);
            }
        })
        store.dispatch(matchCardIdsSetAction(matchCardIds));
    }
    return result
}

const cardFilter = (
    store: Store<IApplicationState>,
    next: any,
    action: ICardAssigneesActionSetAction
) => {
    switch (action.cardAssigneesAction.type) {
        case AT_CARD_ASSIGNEE_ADD_ACTION:
        case AT_CARD_ASSIGNEE_UPDATE_ACTION: {
            const { cardAssignee } = action.cardAssigneesAction;
            let state = store.getState();
            const filters = getEnabledFilters(state, MY_WORK_FILTER_BOARD_ID);
            const isMatch = getMyTaskCardFilter(state);
            if (
                cardAssignee &&
                cardAssignee.cardId &&
                isMatch(cardAssignee) &&
                isMyTaskCardMatchFilters(cardAssignee, filters, getFilterIgnoreCardIds(state))
            ) {
                store.dispatch(matchCardIdAddAction(cardAssignee.cardId));
            } else {
                store.dispatch(matchCardIdRemoveAction(cardAssignee.cardId));
            }

        }
    }
    return cardsFilter(store, next, action);
}

export const myTasksFilterMiddleware = (store: Store<IApplicationState>) => (next: any) => (action: TFilterMiddlewareAction) => {
    if (!getIsAssignedToMeActive(store.getState())) return next(action);
    if (action.type === CARD_ASSIGNEES_ACTION_SET) return cardFilter(store, next, action as ICardAssigneesActionSetAction);
    return cardsFilter(store, next, action);
}
