import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState } from '../../../../../../../types/types';
import { addFeatureTrigger } from '../../../../../polls/featurePoll/effects/addFeatureTrigger';
import { ERateFeature } from '../../../../../../../types/rest/IUserMeta';

export const onTriggerFeaturePoll: ActionCreator<ThunkAction> = () => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(addFeatureTrigger(ERateFeature.NEW_TOP_BAR));
    };
    return action;
};
