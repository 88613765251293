import { SegmentNotificationsEvent, SegmentNotificationsOptions, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { notifyPeople } from '../../../../../../../../rest/effects/activity/notifyPeople';
import { getActivity } from '../../../../../../../../store/activities/selectors/getActivity';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TActivityId, TPermissionId } from '../../../../../../../../types/types';

export const onNotify = (
    activityId: TActivityId,
    permissionIds: TPermissionId[],
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const activity = getActivity(getState(), activityId);
        if (activity) {
            dispatch(segmentTrackAction(SegmentNotificationsEvent.FORCE_NOTIFY_CLICKED, {
                name: SegmentNotificationsOptions.COUNT,
                value: permissionIds.length
            }, [{
                name: SegmentNotificationsOptions.TYPE,
                value: activity.modelType + '_' + activity.actionType + (activity.propertyName ? '_' + activity.propertyName : '')
            }]));
        }
        return dispatch(notifyPeople(activityId, permissionIds));
    };
    return action;
};
