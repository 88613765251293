import { IChecklistsSetAction } from './types';
import { AT_CHECKLISTS_SET } from './constants';
import { ICardChecklists } from '../types';
import { TCardId } from '../../../../../types/types';

export const checklistsSetAction = (
    cardId: TCardId,
    checklists: ICardChecklists
): IChecklistsSetAction => {
    return {
        type: AT_CHECKLISTS_SET,
        cardId,
        checklists
    }
};
