import * as deepFreeze from 'deep-freeze';
import { IChecklists } from '../types';
import {
    AT_CHECKLIST_ACTION_SET,
    AT_CHECKLIST_ADD,
    AT_CHECKLIST_DELETE,
    AT_CHECKLISTS_SET,
    AT_CHECKLISTS_UPDATE,
} from '../actions/constants';
import { TChecklistsAction } from '../actions/types';
import { checklistReducer } from '../../checklist/reducers/checklistReducer';
import { checklistUpdateAction } from '../../checklist/actions/checklistUpdateAction';

const initialState: IChecklists = {};

const _checklistsReducer = (
    state = initialState,
    action: TChecklistsAction
): IChecklists => {
    switch (action.type) {
        case AT_CHECKLIST_ACTION_SET: {
            const {cardId, checklistId, checklistAction} = action;
            const cardChecklists = {...state[cardId]} || {};
            cardChecklists[checklistId] = checklistReducer(
                state[cardId] ? state[cardId][checklistId] : {}, // если прилетел action из realtime, state[cardId] может не быть
                checklistAction
            );
            return {
                ...state,
                [cardId]: cardChecklists,
            }
        }

        case AT_CHECKLIST_ADD: {
            const cardChecklists = {...state[action.checklist.cardId]} || {};
            cardChecklists[action.checklist.id] = action.checklist;
            return {
                ...state,
                [action.checklist.cardId]: cardChecklists,
            }
        }

        case AT_CHECKLIST_DELETE: {
            const cardChecklists = {...state[action.cardId]} || {};
            delete cardChecklists[action.checklistId];
            return {
                ...state,
                [action.cardId]: cardChecklists,
            };
        }

        case AT_CHECKLISTS_SET: {
            return {
                ...state,
                [action.cardId]: action.checklists,
            };
        }

        case AT_CHECKLISTS_UPDATE: {
            const { checklists } = action;
            const cardChecklists = {...state[action.cardId]} || {};
            for (let checklistId in checklists) {
                cardChecklists[checklistId] = checklistReducer(
                    state[action.cardId][checklistId],
                    checklistUpdateAction(checklists[checklistId])
                );
            }
            return {
                ...state,
                [action.cardId]: {
                    ...state[action.cardId],
                    ...cardChecklists
                },
            };
        }

        default:
            return state;
    }
};

export const checklistsReducer = (
    state = initialState,
    action: TChecklistsAction
    ): IChecklists => {
    // @ts-ignore
    if (window.Settings.development) {
        // @ts-ignore
        return deepFreeze(_checklistsReducer(state, action));
    } else {
        return _checklistsReducer(state, action);
    }
};
