'use strict';

App.Models.StatUser = App.Models.StatBase.extend({

    rest: function() {
        return '/profile/save/' + this.options.user
    },

    onCreate: function() {
        return {
            method: 'create'
        }
    },

    onComplete: function(){
        this.destroy();
    },

    onError: function(xhr, textStatus, errorThrown){

    }
});
