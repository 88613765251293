import { IApplicationState, TCardId } from '../../../types/types';
import { getCardDueDate } from './getCardDueDate';
import { getCardSubcards } from './getCardSubcards';
import { getCard } from './getCard';
import { ICards } from '../cards/types';

export const getSubcardsMaxDate = (
    state: IApplicationState,
    epicId: TCardId,
    cards: ICards = {},
): number => {
    const subcards = getCardSubcards(state, epicId); // исходная карта со своими сёстрами

    let maxDueDate = -Infinity;
    subcards.forEach(card => {
        let dueDate = cards[card.id]  && cards[card.id].dueDate !== undefined ? cards[card.id].dueDate : card.dueDate;
        dueDate = dueDate || -Infinity;
        if (dueDate > maxDueDate) {
            maxDueDate = dueDate;
        }
    });
    return maxDueDate === -Infinity ? null : maxDueDate;
}
