import { ICard } from './types';
import { TStatus } from '../../../types/model';

export const getCardInitial =
(): ICard => {
    const CARD_INITIAL: ICard = {
        assignees: [],
        author: {
            fullName: '',
            initials: '',
            photoUrl: '',
            hash: ''
        },
        backgroundColor: '',
        calendarEvents: [],
        checklistIds: [],
        checklistStats: {
            checkedCount: 0,
            totalCount: 0,
            checkedPercent: 0,
            estimate: 0,
        },
        colorIds: [],
        commentCount: 0,
        comments: [],
        copiedFromId: 0,
        created: 0,
        customFields: [],
        description: '',
        driveDocCount: 0,
        dueDate: 0,
        estimate: 0,
        epicId: null,
        ganttOrderNumber: 0,
        ganttVisibility: false,
        id: 0,
        listId: 0,
        listViewOrderNumber: 0,
        meta: {
            customProperties: {},
            pinnedSections: {},
        },
        name: '',
        notificationBeforeDueDates: [],
        orderNumber: 0,
        priority: '',
        processingPercent: 0,
        processingStatus: '',
        relatedCardCount: 0,
        relatedCards: [],
        status: TStatus.STATUS_ACTIVE,
        startDate: 0,
        subCardOrderNumber: 0,
        tags: [],
        totalSpentTime: 0,
        wtch: false,
        cardNumber: '',
        recurringTasks: []
    }
    return CARD_INITIAL;
};
