import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { validateCardNumber } from '../effects/validateCardNumber';
import { cardNumberSetAction } from '../../../store/cardNumbers/actions/cardNumberSetAction';
import { TBoardId } from '../../../../../../../../../types/types';
import { ECardNumberChangeKey } from '../../../store/cardNumber/actions/types';
import { cardNumberStateUpdateAction } from '../../../store/cardNumber/actions/cardNumberUpdate';

export const onChangeCardNumber = (
    boardId: TBoardId,
    key: ECardNumberChangeKey,
    value: string
): ThunkAction => {
    const action = (dispatch: Dispatch) => {
        dispatch(cardNumberSetAction(boardId, cardNumberStateUpdateAction({
            edited: true,
            [key]: value
        })));
        dispatch(validateCardNumber(boardId));
    }
    return action;
};
