import { getTimeTrackerState } from '../../../../aside_panel/cardDetails/TabTimingSection/TimeTracker/store/selectors/getTimeTrackerState';
import { isTimeTrackerTracking } from '../../../../aside_panel/cardDetails/TabTimingSection/TimeTracker/store/selectors/isTimeTrackerTracking';
import { IApplicationState } from '../../../../../../types/types';
import { getBoardWorkHoursInDay } from '../../../../../../store/model/selectors/getBoardWorkHoursInDay';
import { TimeTrackerHelper } from '../../../../dialogs/timeTrackerDialog/helpers/TimeTrackerHelper';

let dotAnimation = false;

export const getTimeTrackerTitle = (
    state: IApplicationState,
): string => {
    const isTracking = isTimeTrackerTracking(state);
    if (!isTracking) return '';

    const {start, boardId} = getTimeTrackerState(state);
    const workHoursInDay = getBoardWorkHoursInDay(state, boardId);
    if (!workHoursInDay) return ''; // при загрузке доски

    const delta = TimeTrackerHelper.calcTotalSeconds(start);
    let timeTracker = TimeTrackerHelper.formatSecondsToString(delta, workHoursInDay, true);
    const dot = !dotAnimation ? ' · ' : ' . ';
    dotAnimation = !dotAnimation;
    return timeTracker + dot;
}
