import * as React from 'react';
import { Icon } from 'kui';
import { HELP_PANEL_LINKS_FAQ } from '../../constants';
import './_HelpPanelLinksComponent.scss';
import { HelpPanelVideoLinkHOC } from '../../hocs/HelpPanelVideoLinkHOC/HelpPanelVideoLinkHOC';
import { HelpPanelAffiliateLinkHOC } from '../../hocs/HelpPanelAffiliateLinkHOC/HelpPanelAffiliateLinkHOC';
import { HelpPanelInviteLinkHOC } from '../../hocs/HelpPanelInviteLinkHOC/HelpPanelInviteLinkHOC';
import {HelpPanelReleaseNotesHOC} from '../../hocs/HelpPanelReleaseNotesHOC/HelpPanelReleaseNotesHOC';

export const HelpPanelLinksComponent = () => {
    return (
        <div className={'help-panel__links'}>
            <span className={'help-panel__link'}><a href={'http://www.kanbanchi.com/kanbanchi-faq'} target="_blank"><Icon xlink="help" size={24} /> {HELP_PANEL_LINKS_FAQ}</a></span>
            <HelpPanelVideoLinkHOC />
            <HelpPanelAffiliateLinkHOC />
            <HelpPanelInviteLinkHOC />
            <HelpPanelReleaseNotesHOC />
        </div>
    );
};
