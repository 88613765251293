import { IGetState } from '../../../../../types/types';
import { IBoard } from '../store/boards/types';
import {getAuthUser} from '../../../../../store/model/authUser/selectors/getAuthUser';
import { getFullLoadedBoards } from '../../../../../store/model/selectors/getFullLoadedBoards';

export const processLoadedBoards = (
    getState: IGetState,
    boards: IBoard[]
): IBoard[] => {
    const modelBoards = getFullLoadedBoards(getState());
    const boardsFilled = boards.map((board) => {
        board.isOpened = !!modelBoards[board.id];
        return board;
    });
    return boardsFilled;
}
