import * as React from 'react';
import './_Filter.scss';
import { CLASS_MY_WORK, FILTER_ACTIONS_PORTAL } from '../../../../../main/myWorkView/constants';
import { IFilterProps } from './types';
import { Button, ButtonDropdown, Divider, Icon, SelectList, SelectListItem, Tooltip } from 'kui';
import {
    MY_WORK_FILTER_BAR_COLLAPSE,
    MY_WORK_FILTER_BAR_DELETE_FILTER,
    MY_WORK_FILTER_BAR_EDIT_FILTER,
    MY_WORK_FILTER_BAR_EXPAND,
    MY_WORK_FILTER_BAR_HIDE_FILTER,
    MY_WORK_FILTER_BAR_ICON_FILTER,
    MY_WORK_FILTER_BAR_SELECT_OTHERS,
    MY_WORK_FILTER_BAR_TITLE_HIDE_FILTER,
    MY_WORK_FILTER_BAR_TITLE_SHOW_FILTER,
    MY_WORK_FILTER_BAR_UNSELECT_OTHERS
} from './constants';
import { MY_WORK_CONTROL_CLASS } from '../../../../../main/myWorkView/cards/constants';
import { getParentsClasses } from '../../../../../helpers/getParentsClasses';
import {
    MY_WORK_FILTER_BAR_ADD_FILTER,
    MY_WORK_FILTER_BAR_SELECT_ALL_FILTERS,
    MY_WORK_FILTER_BAR_UNSELECT_ALL_FILTERS
} from '../constants';
import { FilterIconHOC } from '../../hocs/FilterIconHOC/FilterIconHOC';
import { escape } from 'underscore';
import { root } from '../../../../../../../store/constants';
import { v4 as uuidv4 } from 'uuid';
import { GOOGLE_SPACING } from '../../../../../../../const';
import { LogoSelect } from '../../../Logo/components/LogoSelect/LogoSelect';

export function Filter({
    allowSavedFilters,
    cardsCount,
    filterId,
    icon,
    isAllFiltersEnabled,
    isAllFiltersUnselected,
    isAssignedToMe,
    isCollapsed,
    isEnabled,
    isHideTitle,
    isReadonly,
    name,
    onCreate,
    onDelete,
    onEdit,
    onHide,
    onIconChange,
    onToggle,
    onToggleAll,
    onToggleCollapse,
    onToggleTitle,
    setDragDisabled,
    setListWidth
}: IFilterProps) {
    const className = CLASS_MY_WORK + '__filter-bar-filter';
    const [classUnique] = React.useState(className + uuidv4());
    const classOpened = className + '--opened';
    const classEnabled = className + '--enabled';
    const classTitle = className + '-title';
    const classCounter = className + '-counter';
    const classMore = className + '-more';
    const classIcon = className + '-icon';
    const classDropdown = className + '-dropdown';
    const [classDropdownUnique] = React.useState(classDropdown + uuidv4());

    const [isOpen, setOpen] = React.useState(null);
    const [isIconEdit, setIconEdit] = React.useState(null);
    const [newIcon, setNewIcon] = React.useState(null);
    const [width, setWidth] = React.useState(null);

    const onOpen = () => {
        setOpen(true);
        setDragDisabled(true);
        setTimeout(()=>{
            const button = document.querySelector('.' + classUnique + ' .' + classMore) as HTMLElement;
            if (button) button.focus();
        }, 100);
    };

    const onClose = () => {
        setOpen(false);
        if (isAssignedToMe && (!newIcon || !newIcon.icon)) {
            setNewIcon(null);
        } else {
            onIconChange(newIcon);
        }
        setTimeout(() => {// wait for dropdown close
            setIconEdit(false);
        },100);
        setDragDisabled(false);
    };

    const onActionClick = (func: () => void) => {
        func();
        onClose();
    };

    const onCLick = (e: React.SyntheticEvent) => {
        const targetClasses = getParentsClasses(
            e.target as HTMLElement,
            [
                MY_WORK_CONTROL_CLASS
            ]
        );
        if (targetClasses.includes(MY_WORK_CONTROL_CLASS)) return;
        onToggle();
    };

    React.useEffect(() => {
        setNewIcon(icon);
    }, [icon]);

    React.useEffect(() => {
        setListWidth();
    }, [isHideTitle, name, cardsCount]);

    React.useEffect(() => {
        if (!isIconEdit) return;

        const onResize = () => {
            const dropdown = document.querySelector('.' + classDropdownUnique) as HTMLElement;
            if (dropdown) {
                const dropdownItem = dropdown.querySelector('.kui-dropdown__item') as HTMLElement;
                const rect = dropdownItem.getBoundingClientRect();
                const windowWidth = root._innerWidth;
                const button = document.querySelector('.' + classUnique) as HTMLElement;
                const buttonRect = button && button.getBoundingClientRect();
                const initialLeft = buttonRect && buttonRect.left;
                if (rect.right > windowWidth) {
                    dropdown.style.left = 'unset';
                    dropdownItem.style.left = 'unset';
                    dropdownItem.style.right = `${GOOGLE_SPACING * 2}px`;
                } else if (initialLeft + dropdownItem.offsetWidth + GOOGLE_SPACING * 2.25 < windowWidth) {
                    dropdown.style.left = `${initialLeft}px`;
                    dropdownItem.style.left = `${GOOGLE_SPACING * 0.25}px`;
                    dropdownItem.style.right = `unset`;
                }
            }
        }

        onResize();

        root.addEventListener('resize', onResize);
        return () => root.removeEventListener('resize', onResize);
    }, [isIconEdit]);

    React.useEffect(() => {
        setWidth(String(cardsCount).length * GOOGLE_SPACING);
    }, [cardsCount]);

    const menu = <SelectList fixActive={false} className={className + '-menu'}>
        <SelectListItem
            icon={'plus'}
            onClick={onCreate}
            iconSize={24}
        >
            {MY_WORK_FILTER_BAR_ADD_FILTER}
        </SelectListItem>
        <Divider className={className + '-divider'} />
        {!isReadonly &&
            <SelectListItem
                icon={'pen'}
                onClick={() => onActionClick(onEdit)}
                iconSize={24}
            >
                {MY_WORK_FILTER_BAR_EDIT_FILTER}
            </SelectListItem>
        }
        <SelectListItem
            icon={'google-apps'}
            onClick={() => setIconEdit(true)}
            iconSize={24}
        >
            {MY_WORK_FILTER_BAR_ICON_FILTER}
            <Icon
                xlink={'arrow-s-right'}
                size={16}
                className={className + '-arrow'}
            />
        </SelectListItem>
        {!isCollapsed &&
            <SelectListItem
                icon={'text'}
                onClick={() => onActionClick(onToggleTitle)}
                iconSize={24}
            >
                {isHideTitle ? MY_WORK_FILTER_BAR_TITLE_SHOW_FILTER
                    : MY_WORK_FILTER_BAR_TITLE_HIDE_FILTER}
            </SelectListItem>
        }
        <SelectListItem
            icon={'visibility-off'}
            onClick={onHide}
            iconSize={24}
        >
            {MY_WORK_FILTER_BAR_HIDE_FILTER}
        </SelectListItem>
        {!isReadonly &&
            <SelectListItem
                icon={'delete'}
                onClick={onDelete}
                iconSize={24}
            >
                {MY_WORK_FILTER_BAR_DELETE_FILTER}
            </SelectListItem>
        }
        <Divider className={className + '-divider'} />
        {allowSavedFilters &&
            <SelectListItem
                icon={'read-notification'}
                onClick={() => onActionClick(onToggleAll)}
                iconSize={24}
            >
                {isAllFiltersEnabled ? MY_WORK_FILTER_BAR_UNSELECT_ALL_FILTERS
                    : (isAllFiltersUnselected ? MY_WORK_FILTER_BAR_SELECT_ALL_FILTERS
                        : (isEnabled ? MY_WORK_FILTER_BAR_SELECT_OTHERS
                            : MY_WORK_FILTER_BAR_UNSELECT_OTHERS))}
            </SelectListItem>
        }
        {allowSavedFilters && <Divider className={className + '-divider'}/>}
        <SelectListItem
            className={className + '-action--collapse'}
            icon={isCollapsed ? 'expand' : 'collapse'}
            onClick={onToggleCollapse}
            iconSize={24}
        >
            {isCollapsed ? MY_WORK_FILTER_BAR_EXPAND : MY_WORK_FILTER_BAR_COLLAPSE}
        </SelectListItem>
    </SelectList>;

    const actions = <div className={MY_WORK_CONTROL_CLASS}>
        <ButtonDropdown
            opened={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            dropdownClassName={`
                ${classDropdown}
                ${isIconEdit ? classDropdown + '--icon' : ''}
                ${classDropdownUnique}
                ${MY_WORK_CONTROL_CLASS}
            `}
            className={className + '-button-dropdown'}
            portal
            portalId={FILTER_ACTIONS_PORTAL}
        >
            <Button
                className={classMore}
                variant={'icon'}
            >
                <Icon xlink={'more'} size={24}/>
            </Button>
            <div className={`${MY_WORK_CONTROL_CLASS} handle-click-ignore`}>
                {isIconEdit ?
                    <LogoSelect
                        logo={newIcon}
                        isChangeColorOnReset
                        onChange={setNewIcon}
                    />
                    : menu
                }
            </div>
        </ButtonDropdown>
    </div>;

    return (
        <>
            <Tooltip
                value={isHideTitle ? escape(name) : null}
                direction={'down'}
            >
                <div
                    className={`
                        ${className}
                        ${classUnique}
                        ${isEnabled ? classEnabled : ''}
                        ${isOpen ? classOpened : ''}
                        ${isHideTitle ? className + '--no-title' : ''}
                    `}
                    onClick={onCLick}
                >
                    <div className={classIcon}>
                        <FilterIconHOC icon={newIcon} filterId={filterId} />
                    </div>
                    <span className={classTitle}>
                        {!isHideTitle && name}
                        <span className={classCounter} style={width ? { width } : {}}>{cardsCount}</span>
                    </span>
                    {actions}
                </div>
            </Tooltip>
            {/*{isAssignedToMe &&*/}
            {/*    <HintHOC*/}
            {/*        arrow={'up'}*/}
            {/*        direction={'down'}*/}
            {/*        hintKey={EHintKey.MY_WORK_FILTER_BAR}*/}
            {/*        value={MY_WORK_FILTER_HINT}*/}
            {/*        show={true}*/}
            {/*        selector={'.' + classUnique}*/}
            {/*        arrowTranslate={{ left: GOOGLE_SPACING * 8 }}*/}
            {/*    />*/}
            {/*}*/}
        </>
    );
}
