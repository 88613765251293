import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../../../../types/types';
import { NO_PRIORITY } from '../../../../../../helpers/constants';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { ICards } from '../../../../../../../../store/model/cards/types';
import { cardsRestPatch } from '../../../../../../../../rest/effects/card/card/api/helper/cardsRestPatch';
import { getCardAssignee } from '../../../../../../../../store/model/cardAssignees/selectors/getCardAssignee';

export const onChange = (
    cardId: TCardId,
    priority: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if(priority === NO_PRIORITY){
            dispatch(segmentTrackAction(SegmentCardEvent.REMOVED_PRIORITY));
        } else {
            dispatch(segmentTrackAction(SegmentCardEvent.ADDED_PRIORITY));
        }
        const state = getState();
        const card = getCardAssignee(state, cardId);
        if (!card) return Promise.reject();

        const cards: ICards = {
            [cardId]: {
                priority: priority,
            }
        };
        return dispatch(cardsRestPatch(cards));
    };
    return action;
}
