import { IApplicationState } from '../../../../../types/types';
import { EMyWorkGroupType } from '../../../aside_panel/filterPanelBoard/store/filterPanel/types';
import { getAuthUserMetaMyWork } from '../../../../../store/model/selectors/getAuthUserMetaMyWork';
import { getProFeaturesTrialActive } from '../../../../../store/model/authUser/selectors/getProFeaturesTrialActive';
import { getIsUserStarterPlanOnly } from '../../../../../store/model/authUser/selectors/getIsUserStarterPlanOnly';
import { getIsUserBasicPlanOnly } from '../../../../../store/model/authUser/selectors/getIsUserBasicPlanOnly';

export const getMyWorkGroupBy = (
    state: IApplicationState
): EMyWorkGroupType => {
    return (getIsUserStarterPlanOnly(state) && !getProFeaturesTrialActive(state) || getIsUserBasicPlanOnly(state))
        ? EMyWorkGroupType.NONE
        : (getAuthUserMetaMyWork(state).groupBy || EMyWorkGroupType.DUE);
};
