import {IAuthUser} from 'app/store/model/authUser/types';
import {AT_SET, ISetAction} from 'app/store/model/authUser/actions/types';

export const setAction = (
    authUser: IAuthUser
): ISetAction => {
    return {
        type: AT_SET,
        authUser
    }
};
