import * as React from 'react';
import './_BoardExport.scss';
import { IBoardExportProps } from './types';
import { Button, ButtonsGroup, Icon, Loader, SectionAccordion, Switch } from 'kui';
import {
    BOARD_EXPORT_ACTIVE,
    BOARD_EXPORT_AUTOMATIC,
    BOARD_EXPORT_AUTOMATIC_LABEL,
    BOARD_EXPORT_AUTOMATIC_TOOLTIP,
    BOARD_EXPORT_CARD_TITLES_AND_DESCRIPTIONS,
    BOARD_EXPORT_GOOGLE_SHEET,
    BOARD_EXPORT_MANUALLY,
    BOARD_EXPORT_TIME_TRACKER,
    BOARD_EXPORT_TITLE_SETTINGS
} from './constants';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { SettingsSection } from '../../../../../base/components/SettingsSection/SettingsSection';
import { CLASS_BOARD_DETAILS_SECTION, CLASS_BOARD_EXPORT } from '../../../constants';
import { ListItem } from '../../../../../base/components/ListItem/ListItem';
import { TOOLTIP_LONG } from '../../../../../../../const';
import { escape } from 'underscore';
import { BoardExportSettingsHOC } from '../../hocs/BoardExportSettingsHOC/BoardExportSettingsHOC';
import { IExportListener } from '../../../../../../../helper/export/types';
import { ExportDateHOC } from '../../hocs/ExportDateHOC/ExportDateHOC';
import { BoardSheetBackupsHOC } from '../../hocs/BoardSheetBackupsHOC/BoardSheetBackupsHOC';
import { ProFeatureIndicator } from '../../../../../base/components/ProFeatureIndicator/ProFeatureIndicator';
import { BasicFeatureIndicator } from '../../../../../base/components/BasicFeatureIndicator/BasicFeatureIndicator';

export function BoardExport ({
    allowAutoExport,
    allowGoogleSheetExport,
    exportItems,
    boardId,
    automaticEnabled,
    isProFeature,
    onLoad,
    onClickDisallowGoogleSheetExport,
    onExportSpreadsheet,
    onExportTimeTracker,
    onExportCardTitles,
    onExportAutomaticToggle
}: IBoardExportProps) {
    const [progressPercent, setProgressPercent] = React.useState(null);
    const className = CLASS_BOARD_EXPORT;
    const classSectionSheet = CLASS_BOARD_DETAILS_SECTION + ' ' + CLASS_BOARD_DETAILS_SECTION + '--sheet';
    const classSwitch = className + '__switch';
    const classSwitchButton = classSwitch + '-button';
    const classSwitchButtonLeft = classSwitchButton + '--left';
    const classSwitchButtonRight = classSwitchButton + '--right';
    const classSwitchButtonActive = classSwitchButton + '--active';
    const classButtons = className + '__buttons';
    const classButton = className + '__button';
    const classSettings = classButton + '--settings';
    const classItems = className + '__items';
    const classLoader = className + '__loader';
    const classLoaderPercent = className + '__loader-percent';
    const classInProgress = progressPercent !== null ? className + '--in-progress' : '';
    const classAutomatic = className + '__automatic';
    const classTooltip = classAutomatic + '--tooltip';
    const classAutomaticSwitch = classSwitch + '-automatic';

    const [active, setActive] = React.useState(BOARD_EXPORT_MANUALLY);
    const [manualSettingsOpened, setManualSettingsOpened] = React.useState(false);
    const [automaticSettingsOpened, setAutomaticSettingsOpened] = React.useState(false);

    const onManuallyHandler = () => {
        setActive(BOARD_EXPORT_MANUALLY);
    };

    const onAutomaticHandler = () => {
        setActive(BOARD_EXPORT_AUTOMATIC);
    }

    const onCardTitlesExportHandler = () => {
        onExportCardTitles(exportListener);
    }

    const onTimeTrackerExportHandler = () => {
        onExportTimeTracker(exportListener);
    }

    const onAllActiveDataExportHandler = () => {
        onExportSpreadsheet(exportListener);
    }

    const exportListener: IExportListener = {
        onStart: () => {
            setProgressPercent(0);
        },
        onSuccess: () => {
            setProgressPercent(null);
        },
        onProgress: (percent: number) => {
            setProgressPercent(Math.round(percent));
        },
        onError: (errorMessage: string) => {}
    }

    const automatic = <p className={classAutomaticSwitch}>
        {automaticEnabled ? 'On' : 'Off'}
    </p>;

    React.useEffect(() => {
        onLoad();
    }, []);

    return (
        <div className={`
            ${className}
            ${classInProgress}
        `}>
            <SettingsSection
                className={classSectionSheet}
                sectionType={allowGoogleSheetExport ? null : ESettingsSectionType.DISALLOW}
                title={BOARD_EXPORT_GOOGLE_SHEET}
                onClickDisallow={onClickDisallowGoogleSheetExport}
            >
                <ButtonsGroup className={classSwitch}>
                    <Button
                        className={`
                            ${classSwitchButton}
                            ${classSwitchButtonLeft}
                            ${active === BOARD_EXPORT_MANUALLY ? classSwitchButtonActive : ''}
                        `}
                        onClick={onManuallyHandler}
                    >
                        {BOARD_EXPORT_MANUALLY}
                    </Button>
                    <Button
                        className={`
                            ${classSwitchButton}
                            ${classSwitchButtonRight}
                            ${active === BOARD_EXPORT_AUTOMATIC ? classSwitchButtonActive : ''}
                        `}
                        onClick={onAutomaticHandler}
                    >
                        {BOARD_EXPORT_AUTOMATIC}
                        {automatic}
                    </Button>
                </ButtonsGroup>
                {active === BOARD_EXPORT_MANUALLY ?
                    <>
                        <div className={classButtons}>
                            <Button
                                onClick={onAllActiveDataExportHandler}
                                text={BOARD_EXPORT_ACTIVE}
                                className={classButton}
                            >
                                <Icon xlink={'card'} size={24}/>
                            </Button>
                            <Button
                                onClick={() => setManualSettingsOpened(!manualSettingsOpened)}
                                variant={'icon'}
                                className={classSettings}
                            >
                                <Icon xlink={'settings'} size={24} />
                            </Button>
                            <Button
                                onClick={onTimeTrackerExportHandler}
                                className={`
                                    ${classButton}
                                    ${classButton + '--time'}
                                `}
                            >
                                <Icon xlink={'hint-arrow-right'} size={12}/>
                                {BOARD_EXPORT_TIME_TRACKER}
                                {isProFeature && <ProFeatureIndicator />}
                            </Button>
                            <Button
                                onClick={onCardTitlesExportHandler}
                                text={BOARD_EXPORT_CARD_TITLES_AND_DESCRIPTIONS}
                                className={`
                                    ${classButton}
                                    ${classButton + '--cards'}
                                `}
                            >
                                <Icon xlink={'description'} size={24}/>
                            </Button>
                        </div>
                        {manualSettingsOpened &&
                            <BoardExportSettingsHOC boardId={boardId} />
                        }
                    </>
                    :
                    <div className={classAutomatic}>
                        <SettingsSection
                            className={className}
                            title={BOARD_EXPORT_AUTOMATIC_LABEL}
                            titleButton={<Switch
                                disabled={!allowAutoExport}
                                checked={automaticEnabled}
                                onChange={onExportAutomaticToggle}
                            />}
                        >
                            <p className={classTooltip}>
                                {BOARD_EXPORT_AUTOMATIC_TOOLTIP}
                            </p>
                            {automaticEnabled &&
                                <>
                                    <SectionAccordion
                                        variant={'simple'}
                                        opened={automaticSettingsOpened}
                                        onClose={() => setAutomaticSettingsOpened(false)}
                                        onOpen={() => setAutomaticSettingsOpened(true)}
                                        title={BOARD_EXPORT_TITLE_SETTINGS}
                                    >
                                        <BoardExportSettingsHOC boardId={boardId} isAuto />
                                    </SectionAccordion>
                                    <ExportDateHOC boardId={boardId} />
                                </>
                            }
                        </SettingsSection>
                    </div>
                }
                {!!exportItems.length &&
                    <div className={classItems}>
                        {exportItems.map(item =>
                            <ListItem
                                actions={null}
                                text={<Button
                                    href={item.url}
                                    target={'blank'}
                                    text={item.name}
                                    tooltip={{
                                        /*delay: TOOLTIP_LONG,*/
                                        direction: 'up-right',
                                        isNoWrap: true,
                                        value: escape(item.name),
                                    }}
                                    variant={'icon-text'}
                                />
                                }
                                key={item.url}
                            />
                        )}
                    </div>
                }
                <BoardSheetBackupsHOC boardId={boardId}/>
            </SettingsSection>
            {progressPercent !== null &&
                <div className={classLoader}>
                    <div className={classLoaderPercent}>
                        {progressPercent}%
                    </div>
                    <Loader size={'large'} />
                </div>
            }
        </div>
    )
};
