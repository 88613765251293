import * as deepFreeze from 'deep-freeze';
import { IDependency } from '../types';
import { AT_TYPE_SET, AT_UPDATE, TDependencyAction } from '../actions/types';

const initialState: IDependency = null;

const _dependencyReducer = (
    state = initialState,
    action: TDependencyAction
): IDependency => {
    switch (action.type) {
        case AT_TYPE_SET:
            return {
                ...state,
                type: action.dependencyType
            };
        case AT_UPDATE:
            return {
                ...state,
                ...action.dependency
            };
        default:
            return state;
    }
};

export const dependencyReducer = (
    state = initialState,
    action: TDependencyAction
): IDependency => {
    // @ts-ignore
    if (window.Settings.development) {
        // @ts-ignore
        return deepFreeze(_dependencyReducer(state, action));
    } else {
        return _dependencyReducer(state, action);
    }
};
