import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TBoardId, TCardId } from '../../../../../../../../types/types';
import { getHighlightedMentionIndex } from '../../../selectors/getHighlightedMentionIndex';
import { getCardMentionOptions } from '../../../selectors/getCardMentionOptions';
import { findOptionGroupIndexes } from '../../../helpers/findOptionGroupIndexes';
import { commentUpdate } from '../../../store/reducers/actions/commentUpdate';

/**
 * Для изменения текущей позиции подсветки меншнов
 * @param shift (1 / -1)
 */
export const highlightNextMention = (
    boardId: TBoardId,
    cardId: TCardId,
    shift: number,
): ThunkAction => {
    return (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const mentions = getCardMentionOptions(state, boardId, cardId);
        const indexes = findOptionGroupIndexes(mentions);
        let highlightedMentionIndex = getHighlightedMentionIndex(state);
        if (indexes.length && !highlightedMentionIndex) { // есть группы
            highlightedMentionIndex = 1;
        }

        const maxIndex = mentions.length - 1;
        let nextIndex = highlightedMentionIndex + shift;

        if (indexes.length) { // есть группы
            const index = indexes.indexOf(nextIndex);
            if (index > -1) { // nextIndex попал на имя группы
                nextIndex += shift; // продвинуть дальше
            }
        }

        if (nextIndex < 0) {
            nextIndex = mentions.length - 1;
        } else if (nextIndex > maxIndex) {
            nextIndex = indexes.length ? 1 : 0;
        }

        dispatch(commentUpdate({ highlightedMention: nextIndex }));
    };
};
