import { EFeature, WYSIWYG_RELEASE } from 'app/types/rest/IUserMeta';
import { IApplicationState } from '../../../../../types/types';
import { isNewFeatureForUser } from '../isNewFeatureForUser';
import { getAuthUser } from '../getAuthUser';

export const getIsNewFeatureWYSIWYG = (
    state: IApplicationState
): boolean => {
    return false; // pause KNB-2756
    const isNewFeature = isNewFeatureForUser(state, EFeature.WYSIWYG);
    if (!isNewFeature) return false;

    const user = getAuthUser(state);
    if (user && user.createDateTimeStamp && user.createDateTimeStamp > WYSIWYG_RELEASE) return false;

    return true;
};
