import { IChecklistUpdateAction } from './types';
import { AT_CHECKLIST_UPDATE } from './constants';
import { IChecklist } from '../types';

export const checklistUpdateAction = (
    checklist: IChecklist
): IChecklistUpdateAction => {
    return {
        type: AT_CHECKLIST_UPDATE,
        checklist
    }
};
