import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../types/actions';
import { EHintKey, TUTORIAL_STEP_DELAY, getHintConfig } from '../../../constants';
import { getHint } from '../../../store/hints/selectors/getHint';
import { IGetState } from '../../../../../../types/types';
import { hintTutorialHide } from '../../../effects/hintTutorialHide';
import { hintTutorialStep } from '../../../effects/hintTutorialStep';

export const onTutorialPrevious: ActionCreator<ThunkAction> = (
    key: EHintKey,
    ownOnTutorialPrevious?: () => void
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const hintConfig = getHintConfig(key);
        if (!hintConfig || !hintConfig.tutorial) return;

        const hint = {...getHint(state, key)};

        if (!hint.step) hint.step = 1;

        dispatch(hintTutorialHide(key));
        setTimeout(() => {
            dispatch(hintTutorialStep(key, hint.step - 1));
        }, TUTORIAL_STEP_DELAY);
        if (ownOnTutorialPrevious) ownOnTutorialPrevious();
    };
    return action;
};
