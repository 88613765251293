import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../../../../types/types';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { cardUpdateOrderField } from '../../../../../../../../rest/effects/card/card/cardUpdateOrderField';

export const onSort = (
    cardId: TCardId,
    index: number,
    cardIds: TCardId[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.MOVED_SUBCARD));
        const collection = [...cardIds];
        const i = collection.indexOf(cardId);
        if (i > -1) collection.splice(i, 1);
        collection.splice(index, 0, cardId);
        return dispatch(cardUpdateOrderField(cardId, collection, 'subCardOrderNumber'));
    };
    return action;
};
