import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IBoard } from '../../../../../../../../store/model/board/types';
import { moveToLoadBoard } from '../../../../../../../../rest/effects/board/moveToLoadBoard';

export const onLoadBoardLists = (
    board: IBoard
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        return dispatch(moveToLoadBoard(board.id));
    };
    return action;
};
