import { dispatch, getAppState } from 'app/store/configureStore';
import { EAuthUserPlatformType, IRestAuthUser } from 'app/types/rest/IRestAuthUser';
import { msAuthManagerInstance } from 'app/helper/authorisation/microsoft/oauth/MSAuthManager';
import { ISignInError } from 'app/view/react_components/dialogs/signIn/store/signIn/types';
import { authorizedUserCookieCheck } from 'app/view/react_components/dialogs/cookies';
import { signInErrorSet } from 'app/view/react_components/dialogs/signIn';
import { signInErrorEvent } from 'app/middlewares/segment/segmentEvents/userSegmentEvents';
import { getAuthUser } from 'app/store/model/authUser/selectors/getAuthUser';
import { CommonAuthController } from 'app/helper/authorisation/common/CommonAuthController';
import { updateUser } from 'app/store/model/authUser/effects/updateUser';
import { signedHelperInstance } from 'app/helper/authorisation/common/SignedHelper';
import { getMessages, root } from 'app/store/constants';
import { errorReset } from 'app/view/react_components/dialogs/signIn/effects/errorReset';
import * as _ from 'underscore';
import { AuthManager } from 'app/helper/authorisation/google/AuthManager';
import { AuthController as GoogleAuthController } from 'app/helper/authorisation/google/AuthController';
import { AuthController as MicrosoftAuthController } from 'app/helper/authorisation/microsoft/oauth/AuthController';
import { getRequestMicrosoftUserId } from 'app/store/model/selectors/getRequestMicrosoftUserId';
import {setUser} from 'app/store/model/authUser/effects/setUser';

const Messages = getMessages();

/***
 * класс общий для всех процессов авторизации и запроса токеном, как для Google там и для Microsoft
 */

export class CommonAuthManager{

    static trySignInOrShowLoginScreen(){
        let user = getAuthUser(getAppState());
        if (user.needLogin){
            if (user.tryRememberMe){
                CommonAuthController.authorizeRememberMeHash()
                    .then(CommonAuthManager.serverAuthorizeCallbackSuccess)
                    .catch(CommonAuthManager.serverAuthorizeCallbackError)
            } else{
                root.App.controller.showSplashScreen();
            }
        } else {
            CommonAuthManager.signInFinished(user);
        }
    }

    static  loginWithRememberMeOrShowLoginScreen(idToken?: string){
        return CommonAuthController.authorizeRememberMeHash(idToken)
            .then(CommonAuthManager.serverAuthorizeCallbackSuccess)
            .catch((user) => {
                CommonAuthManager.serverAuthorizeCallbackError(user);
                return Promise.reject(Messages.getText('login.required'));
            })
    }

    signIn(type: EAuthUserPlatformType){
        let user = getAuthUser(getAppState());
        let scopes = CommonAuthController.getInitScopes(type);
        let hint = user.anonym? undefined : user.email;
        if (type === EAuthUserPlatformType.MICROSOFT ){
            const userId = getRequestMicrosoftUserId();
            hint = userId || hint;
        }
        return new Promise((resolve, reject) => {
            CommonAuthManager.requestScopes(type,
                scopes,
                resolve,
                reject,
                reject,
                hint);
        })
    }

    static logout(){
        const authUser = getAuthUser(getAppState());
        CommonAuthController.saveRememberMeHash('');
        const platformType = authUser.platformType;
        if (EAuthUserPlatformType.MICROSOFT ===  platformType){
            return msAuthManagerInstance.logout();
        } else {
            return AuthManager.logout();
        }
    }

    static signInSwitchAccount(hint?: string){
        return new Promise((resolve, reject) => {
            const authUser = getAuthUser(getAppState());
            CommonAuthManager.requestScopes(authUser.platformType, CommonAuthController.getInitScopes(authUser.platformType),
                resolve,
                reject,
                reject,
                hint);
        })
    }

    getAccessTokenOrShowLoginScreen(){
        return this.getAccessToken().catch((err)=>{
            console.debug(err);
            root.App.controller.showSplashScreen();
            return new Promise<string>(()=>{});
        })
    }

    private getAccessToken(){
        const authUser = getAuthUser(getAppState());
        if (authUser.needLogin || !authUser.token.access_token){
            return Promise.reject(Messages.getText('login.required'));
        }
        const token = authUser.token;

        if (token.expires_at *1000 - Date.now() < 120){
            console.debug('refreshAccessToken');
            return CommonAuthController.refreshAccessToken()
                .then(CommonAuthManager.serverAuthorizeCallbackSuccess)
                .then(user => {
                    return user.token.access_token
                })
                .catch(user => {
                    CommonAuthManager.serverAuthorizeCallbackError(user);
                    return Promise.reject(Messages.getText('login.required'));
                })

        } else {
            return Promise.resolve(authUser.token.access_token);
        }
    }

    static onSignedFinishedForTestMode(){
        root.App.controller.removeSplashScreen();
        root.App.vent.trigger(root.App.vent['gapi:authorized']);
        dispatch(authorizedUserCookieCheck());
    }

    static onSignInError(error: ISignInError) {
        console.error(error);
        root.App.controller.showSplashScreen();
        dispatch(signInErrorSet(error));
        dispatch(signInErrorEvent());
    }

    /********************************************/
    private static serverAuthorizeCallbackSuccess(user: IRestAuthUser){
        if(user.isEmailChanged) {
            location.reload()
        }
        dispatch(setUser(user));
        CommonAuthManager.signInFinished(user);
        return getAuthUser(getAppState());
    }

    private static serverAuthorizeCallbackError(user: IRestAuthUser){
        let userPatch = {
            needLogin: user.needLogin,
            tryRememberMe: user.tryRememberMe,
            ipChanged: user.ipChanged
        }
        dispatch(updateUser(userPatch));
        if (user.ipChanged) {
            console.log('user.ipChanged');
        }
        CommonAuthController.resetToken();
        root.App.controller.showSplashScreen();
        if (user.requiredScopes != null && user.requiredScopes.length>0) {
            root.App.controller.showPermissionsFAQModal();
        }
        return getAuthUser(getAppState());
    }

    private static signInFinished(user: IRestAuthUser){
        CommonAuthController.setToken(user.token);
        CommonAuthController.saveRememberMeHash(user.tokenHash);
        CommonAuthController.signInFinished();
        signedHelperInstance.signedIn();
    }

    public static requestScopes(
        type: EAuthUserPlatformType,
        requiredScopes: string[],
        onSuccess: (authUser: IRestAuthUser)=> void = ()=>{},
        onError: (authUser: IRestAuthUser) => void = ()=>{},
        onCancel: () => void = ()=>{},
        hint?: string,
    ) {
        dispatch(errorReset());
        this.getAuthController(type).authorizeWithCode(
            requiredScopes,
            (authUser => {
                console.log('after authorizeWithCode', authUser)
                onSuccess(CommonAuthManager.serverAuthorizeCallbackSuccess(authUser))
            }),
            (authUser => {
                onError(CommonAuthManager.serverAuthorizeCallbackError(authUser));
            }),
            onCancel,
            hint);
    }

    private static getAuthController(type: EAuthUserPlatformType){
        if (EAuthUserPlatformType.MICROSOFT ===  type){
            return MicrosoftAuthController;
        } else {
            return GoogleAuthController;
        }
    }

    private showNewScopeRequired(onApprove: ()=> void, onCancel: ()=> void, onRequested: ()=> void, scopes: string[]) {
        let approve = ()=> {
            if (onRequested && _.isFunction(onRequested)) {
                onRequested();
            }
            const authUser = getAuthUser(getAppState());
            CommonAuthManager.requestScopes(authUser.platformType,
                scopes,
                onApprove,
                ()=>{},
                onCancel,
                authUser.email
            );
        };

        root.App.controller.showPermissionDialog({
            type: scopes[0],
            onApprove: approve,
            onCancel: onCancel
        });
    }

    // проверяем права, если есть хотя бы одно - return true
    public static checkOneOfScopes(scopes: string|string[]) {
        let permArray = _.isArray(scopes) ? scopes : [scopes];
        let user = getAuthUser(getAppState());

        for (let i=0; i<permArray.length; i++) {
            if (_.contains(user.token.scopes, permArray[i])){
                return true;
            }
        }
        return false;
    }

    // проверяем права, если ни одно нет - выдаем диалог на запрос дополнительных в needPerm
    public checkAndRequestPermission(checkScopes: string|string[], callback: ()=> void, requiredScopes: string|string[], onShow?: ()=> void, onCancel?: ()=> void, onRequested?: ()=> void, onApprove?: ()=> void) {
        if (CommonAuthManager.checkOneOfScopes(checkScopes)){
            if (_.isFunction(callback)) {
                callback();
            }
            return;
        }
        let permArray = _.isArray(checkScopes) ? checkScopes : [checkScopes];

        if (!requiredScopes) {
            requiredScopes = permArray;
        }
        requiredScopes = _.isArray(requiredScopes) ? requiredScopes : [requiredScopes];
        if (onShow) onShow();
        const _callback = () => {
            if (_.isFunction(onApprove)) {
                onApprove();
            }
            if (_.isFunction(callback)) {
                callback();
            }
        }
        this.showNewScopeRequired(_callback, onCancel, onRequested , requiredScopes);
    }
}

export const commonAuthManagerInstance = new CommonAuthManager();
