import { IFilter } from '../types';
import { TFilterAction } from '../actions/types';
import {
    AT_ASSIGNED_TO_SET,
    AT_ATTACHMENTS_SET,
    AT_CARD_COLORS_SET,
    AT_COLORS_SET,
    AT_COMMENTS_SET,
    AT_CREATED_SET,
    AT_DONE_DATE_SET,
    AT_DUE_DATE_SET,
    AT_FILTER_ENABLED_SET,
    AT_HIDE_SET,
    AT_KEYWORD_SET,
    AT_META_SET,
    AT_NAME_SET,
    AT_PRIORITY_SET,
    AT_PUBLIC_SET,
    AT_ROLES_SET,
    AT_SHOW_SET,
    AT_SORT_CARDS_BY_SET,
    AT_STARTED_SET,
    AT_TAGS_SET,
} from '../actions/constants';
import { getDefaultInitialFilter } from '../helpers/getDefaultInitialFilter';

export const initialState: IFilter = getDefaultInitialFilter();

export const filterReducer = (
    state = initialState,
    action: TFilterAction
): IFilter => {
    switch (action.type) {
        case AT_NAME_SET:{
            return {
                ...state,
                name: action.name
            };
        }
        case AT_KEYWORD_SET: {
            return {
                ...state,
                searchedKeyWord: action.searchedKeyWord
            }
        }
        case AT_FILTER_ENABLED_SET:{
            return {
                ...state,
                isEnabled: action.isEnabled
            }
        }
        case AT_CREATED_SET:{
            return {
                ...state,
                created: {...action.created}
            }
        }
        case AT_STARTED_SET:{
            return {
                ...state,
                started: {...action.started}
            }
        }
        case AT_DONE_DATE_SET: {
            return {
                ...state,
                doneDate: {...action.doneDate}
            }
        }
        case AT_DUE_DATE_SET: {
            return {
                ...state,
                dueDate: {...action.dueDate}
            }
        }
        case AT_ASSIGNED_TO_SET:{
            return  {
                ...state,
                assignees: [...action.assignedTo]
            }
        }
        case AT_SORT_CARDS_BY_SET:{
            return {
                ...state,
                sortCardsBy: action.sortCardsBy
            }
        }
        case AT_HIDE_SET:{
            return {
                ...state,
                hide: [...action.hide]
            }
        }
        case AT_SHOW_SET: {
            return {
                ...state,
                show: [...action.show]
            }
        }
        case AT_PRIORITY_SET:{
            return {
                ...state,
                priority: [...action.priority]
            }
        }
        case AT_COMMENTS_SET: {
            return {
                ...state,
                comments: action.comments
            }
        }
        case AT_TAGS_SET: {
            return {
                ...state,
                tags: [...action.tags]
            }
        }
        case AT_COLORS_SET: {
            return {
                ...state,
                colorTags: [...action.colorTags]
            }
        }
        case AT_ATTACHMENTS_SET:{
            return {
                ...state,
                attachments: action.attachments
            }
        }
        case AT_PUBLIC_SET: {
            return {
                ...state,
                isPublic: action.isPublic
            }
        }
        case AT_CARD_COLORS_SET: {
            return {
                ...state,
                cardColors: action.cardColors
            }
        }
        case AT_ROLES_SET: {
            return {
                ...state,
                roles: [...action.roles]
            }
        }
        case AT_META_SET: {
            return {
                ...state,
                meta: {
                    ...state.meta,
                    ...action.meta
                }
            }
        }
        default:
            return state;
    }
};
