import {IApplicationState} from '../../../../../../../types/types';
import {TTabKey} from '../../types';
import { getLoadStatus } from './getLoadStatus';
import { TTabLoadStatus } from '../types';

export const getIsCompleted = (
    state: IApplicationState,
    tabKey: TTabKey
): boolean => {
    if (tabKey ===TTabKey.recent) return true;
    return getLoadStatus(state, tabKey) === TTabLoadStatus.COMPLETED;
}
