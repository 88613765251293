'use strict';

import { getActivityItem } from "../react_components/aside_panel/boardDetails/activity/TabActivity/helpers/aсtivityHelper";

App.Views.CardUpdateTagNotification = App.Views.BaseNotification.extend({

    getActions: function() {
        const item = getActivityItem(this.notificationModel);
        const body = this.notificationModel.tags && this.notificationModel.tags.length
            ? this.notificationModel.tags.join(', ')
            : this.notificationModel.oldValue;
        return {
            body,
            action: item.label,
            actionData: {
                icon: 'tag'
            }
        };
    },

    onClick: function(e, notification) {
        var dashboardId = this.notificationModel.dashboardId;
        var cardId = this.notificationModel.card.id;
        App.controller.cleverOpenCard(dashboardId, cardId);
        this.closeNotification(notification);
        e.stopPropagation();
    }
});
