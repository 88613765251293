import { IFilter } from '../types';
import {
    FP_KEY_ANY,
    FP_KEY_HIDE_FILTER_EMPTY_LIST,
    FP_KEY_SORT_CARDS_BY_FILTER_KEY_CHRONOLOGICAL_ORDER,
    INIT_FILTER_ID
} from '../../../constants';

export const getDefaultInitialFilter = (): IFilter => ({
    id: INIT_FILTER_ID,
    name: '',
    isEnabled: true,
    isPublic: false,
    created: {
        from: null,
        to: null
    },
    started: {
        from: null,
        to: null
    },
    doneDate: {
        from: null,
        to: null
    },
    dueDate: {
        from: null,
        to: null
    },
    assignees: [],
    sortCardsBy: FP_KEY_SORT_CARDS_BY_FILTER_KEY_CHRONOLOGICAL_ORDER,
    searchedKeyWord: null,
    hide: [FP_KEY_HIDE_FILTER_EMPTY_LIST],
    show: [],
    priority: [],
    comments: FP_KEY_ANY,
    attachments: FP_KEY_ANY,
    tags: [],
    colorTags: [],
    cardColors: [],
    roles: [],
    meta: {
        myWorkCards: [],
        myWorkColorTags: [],
    }
});
