import { cardsActionSetAction } from '../../actions/cardsActionSetAction';
import { TCardId, TNBDId } from '../../../../types/types';
import { cardActionSetAction } from '../../cards/actions/cardActionSetAction';
import { notificationBeforeDueDateDeleteAction } from '../../card/actions/notificationBeforeDueDateDeleteAction';
import { ICardsActionSetAction } from '../../actions/types';

export const notificationBeforeDueDateDelete = (
    cardId: TCardId,
    notificationBeforeDueDateId: TNBDId
): ICardsActionSetAction => {
    return cardsActionSetAction(cardActionSetAction(cardId, notificationBeforeDueDateDeleteAction(notificationBeforeDueDateId)));
};
