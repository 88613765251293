import { IGetState, TCardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { ICards } from '../../../../store/model/cards/types';
import { cardsRestPatch } from './api/helper/cardsRestPatch';
import { IChecklistStats } from '../../../../types/model';
import { getCard } from '../../../../store/model/selectors/getCard';
import * as _ from 'underscore';
import { getCardEpicsForProcessingPercentChange } from '../../../../store/model/selectors/getCardEpicsForProcessingPercentChange';
import { ICardBatch, ICardBatches } from './api/types';
import { cardsPatchRestBatch } from './api/helper/cardPatchRestBatch';
import { getBoardIdByCardId } from '../../../../store/model/selectors/getBoardIdByCardId';

export const cardSetChecklistStatsBatches = (
    getState: IGetState,
    cardId: TCardId,
    checklistStats: IChecklistStats,
    cardBatches: ICardBatches = {},
): ICardBatches => {
    let cards: ICards = {
        [cardId]: {
            checklistStats
        }
    };
    cards = getCardEpicsForProcessingPercentChange(getState(), cardId, cards);
    for (let cardId in cards) {
        const cardBatch: ICardBatch = {card: cards[cardId], callbacks: []};
        cardBatches[cardId] = cardBatch;
    }
    return cardBatches;
};

export const cardSetChecklistStats = (
    cardId: TCardId,
    checklistStats: IChecklistStats
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const card = getCard(getState(), cardId);
        if (_.isEqual(card.checklistStats, checklistStats)){
            return Promise.resolve();
        }

        const cardBatches = cardSetChecklistStatsBatches(getState, cardId, checklistStats);
        const boardId = getBoardIdByCardId(getState(), cardId);
        return cardsPatchRestBatch(dispatch, boardId, cardBatches).then(() => cardBatches);
    };
    return action;
};
