import { connect } from 'react-redux';
import { EMPTY_ARRAY, IApplicationState } from '../../../../../../../../types/types';
import { ICardChecklistsHOCProps } from './types';
import { onDidMount } from './events/onDidMount';
import { ICardChecklist, ICardChecklistsEvents, ICardChecklistsFields } from '../../components/CardChecklists/types';
import { onToggle } from './events/onToggle';
import { CardChecklists } from '../../components/CardChecklists/CardChecklists';
import { getCardChecklists } from '../../../../../../../../store/model/checklists/checklists/selectors/getCardChecklists';
import { getAuthUserChecklistOrderMode } from '../../../../../../../../store/model/authUser/selectors/getAuthUserChecklistOrderMode';
import { getAuthUserIsHideDoneChecklistItems } from '../../../../../../../../store/model/authUser/selectors/getAuthUserIsHideDoneChecklistItems';
import { getAuthUser } from '../../../../../../../../store/model/authUser/selectors/getAuthUser';
import { getBoardUsers } from '../../../../../../../../store/model/selectors/getBoardUsers';
import { getBoardIdByCardId } from '../../../../../../../../store/model/selectors/getBoardIdByCardId';
import { getBoardWorkHoursInDay } from '../../../../../../../../store/model/selectors/getBoardWorkHoursInDay';
import { getPinnedCheckListsMemo } from './helpers/getPinnedCheckLists';
import { getCardPinnedSectionsCreateSelector } from '../../../../../../../../store/model/selectors/getCardPinnedSections';
import { isEqual } from 'underscore';

// const analyze = new Analyze('CardChecklistsHOC');
// average: 0.014 ms, calls: 800, total: 10.900 ms (reselects)

const mapStateToProps = () => {
    const getPinnedCheckLists = getPinnedCheckListsMemo();
    const getCardPinnedSections = getCardPinnedSectionsCreateSelector();

    interface ICaheProps extends ICardChecklistsFields{};
    let cache: ICaheProps = null;
    const getCache = (
        value: ICaheProps
    ): ICaheProps => {
        if (isEqual(value, cache)) return cache;
        cache = value; return value;
    };

    return (
        state: IApplicationState,
        props: ICardChecklistsHOCProps
    ): ICardChecklistsFields => {
        // analyze.start();
        const { cardId } = props;
        const authUser = getAuthUser(state); //реселект - все ок
        const checklistOrderMode = getAuthUserChecklistOrderMode(state) // без реселекта - все ок
        const isHideDoneChecklistItems = getAuthUserIsHideDoneChecklistItems(state); // без реселекта - все ок
        const checklists = getCardChecklists(state, cardId); // без реселекта - все ок , пустой объек {} нам не страшен внутри
        const pinnedChecklistIds = getCardPinnedSections(state, cardId).checklists || EMPTY_ARRAY; //реселект - все ок

        const pinnedChecklists: ICardChecklist[] = getPinnedCheckLists(checklists, pinnedChecklistIds, isHideDoneChecklistItems, checklistOrderMode); //memo - все ок

        const cardChecklists = getCache({
            authUserPermissionId: authUser.permissionId,
            checklists: pinnedChecklists.length ? pinnedChecklists : null,
            boardUsers: getBoardUsers(state, getBoardIdByCardId(state, cardId)), //реселект - все ок
            workHoursInDay: getBoardWorkHoursInDay(state, getBoardIdByCardId(state, cardId)), // без реселекта - все ок
        });
        // analyze.finish();
        return cardChecklists;
    };
};

const mapDispatchToProps = (
    dispatch: any,
    { cardId }: ICardChecklistsHOCProps
): ICardChecklistsEvents => {
    return {
        onDidMount: () => dispatch(onDidMount(cardId)),
        onToggle: (checklistId, itemId) => dispatch(onToggle(cardId, checklistId, itemId)),
    };
};

export const CardChecklistsHOC = connect(
    mapStateToProps,
    mapDispatchToProps,
)(CardChecklists);

CardChecklistsHOC.displayName = 'CardChecklistsHOC';
