import { IApplicationState, TBoardId } from '../../../../../../../types/types';
import { IBoard } from '../types';

export const getBoard = (
    state: IApplicationState,
    boardId: TBoardId
): IBoard => {
    const board = state.openBoardsDialog &&
    state.openBoardsDialog.boards.boards.find((item) => {
        return item.id === boardId
    });
    return board;
};
