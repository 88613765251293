import { IGetState, TBoardId, TCardId } from 'app/types/types';
import { Dispatch, ThunkAction } from 'app/types/actions';
import { SegmentArchiveEvent, segmentTrackAction } from 'app/middlewares/segment';
import { getCardIsEpic } from 'app/store/model/selectors/getCardIsEpic';
import { snackbarPromtYesNo } from 'app/view/react_components/snackbarsStack/effects/variantPromt/snackbarPromtYesNo';
import { getMessages, root } from 'app/store/constants';
import { ESnackbarPosition } from 'app/view/react_components/snackbarsStack/store/types';
import { getSectionType } from 'app/view/react_components/aside_panel/cardDetails/selectors/getSectionType';
import { ESettingsSectionType } from 'app/view/react_components/base/components/SettingsSection/types';
import { getIsLinkCardActive } from '../../router/selectors/getIsLinkCardActive';
import { showSnoozeBlocker } from '../../../view/react_components/base/effects/showSnoozeBlocker';

export const cardArchiveGeneral = (
    boardId: TBoardId,
    cardId: TCardId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const sectionType = getSectionType(getState(), boardId, cardId, null, 'allowArchive');
        const isDisallow = sectionType === ESettingsSectionType.DISALLOW;
        if (isDisallow) {
            dispatch(showSnoozeBlocker({
                allow: 'allowArchive'
            }));
            dispatch(segmentTrackAction(SegmentArchiveEvent.ARCHIVE_CARD_NO_SUCCESS));
            return Promise.reject();
        }

        const isEpic = getCardIsEpic(getState(), cardId);
        if(isEpic){
            return new Promise<boolean>((resolve) => {
                dispatch(snackbarPromtYesNo({
                    id: 'SNACKBAR_ARCHIVE_SUBCARDS',
                    text: getMessages().getText('snackbar.archive.with_subcards.text'),
                    actionApply: () => {
                        resolve(true);
                        if (getIsLinkCardActive(getState())) {
                            root.App.controller.mainView.hideCardLink();
                        }
                    },
                    actionCancel: () => {
                        resolve(false);
                        if (getIsLinkCardActive(getState())) {
                            root.App.controller.mainView.hideCardLink();
                        }
                    }
                }, ESnackbarPosition.left));
            });
        } else {
            return new Promise<boolean>((resolve) => {
                resolve(false);
                if (getIsLinkCardActive(getState())) {
                    root.App.controller.mainView.hideCardLink();
                }
            });
        }
    };
    return action;
};
