import { IGetState, TCardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { getSelectedCards } from '../../../../../../clickManager/selectors/getSelectedCards';
import { segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { SegmentMultiSelectEvent } from '../../../../../../../../middlewares/segment/trackEntities/multiselectEvents';
import { getBoardUsers } from '../../../../../../../../store/model/selectors/getBoardUsers';
import { cardSetAssignees } from '../../../../../../../../rest/effects/card/card/cardSetAssignees';
import {getCardAssignees} from '../../../../../../../../store/model/selectors/getCardAssignees';

export const selectUser = (
    boardId: TCardId,
    permissionId: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const cardIds = getSelectedCards(state, boardId);

        if (!cardIds) return;

        const boardUsers = getBoardUsers(state, boardId);
        cardIds.forEach(cardId => {
            const assignees = [...getCardAssignees(state, cardId)];
            const exist = assignees.findIndex(assignee => assignee.sharedUser.permissionId === permissionId) >= 0;
            if (!exist) {
                const assignee = boardUsers.find(assignee => assignee.permissionId === permissionId);
                if (assignee) {
                    assignees.push( {sharedUser: assignee});
                    dispatch(cardSetAssignees(cardId, assignees));
                }
            }
        });
        dispatch(segmentTrackAction(SegmentMultiSelectEvent.USER_ASSIGNED));
    };
    return action;
};
