import { IApplicationState } from '../../../../../../../../types/types';
import { connect } from 'react-redux';
import { IPushNotificationsTogglerHOCProps } from './types';
import { onChange } from './events/onChange';
import { getPushNotificationSettingsByBoardId } from '../../../../../../../../store/model/selectors/getPushNotificationSettingsByBoardId';
import { SectionToggler } from '../../../../../../base/components/SectionToggler/SectionToggler';
import { ISectionTogglerFields, ISectionTogglerEvents } from '../../../../../../base/components/SectionToggler/types';
import { getPanelReadonly } from '../../../../selectors/getPanelReadonly';

const mapStateToProps = (
    state: IApplicationState,
    { boardId }: IPushNotificationsTogglerHOCProps
): ISectionTogglerFields => {
    const pushNotification = getPushNotificationSettingsByBoardId(state, boardId);
    const isDisabled = getPanelReadonly(state, boardId);
    return {
        checked: pushNotification && pushNotification.enabled,
        isDisabled,
    };
};

const mapDispatchToProps = (
    dispatch: any,
    { boardId }: IPushNotificationsTogglerHOCProps
): ISectionTogglerEvents => {
    return {
        onChange: () => dispatch(onChange(boardId))
    }
};

export const PushNotificationsTogglerHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(SectionToggler);

PushNotificationsTogglerHOC.displayName = 'PushNotificationsTogglerHOC';
