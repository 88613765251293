import { IList } from '../list/types';
import { getBoard } from './getBoardById';
import { IApplicationState, TBoardId } from '../../../types/types';
import { TStatus } from '../../../types/model';
import { getList } from '../list/selectors/getList';

export const getBoardListsByStatus = (
    state: IApplicationState,
    boardId: TBoardId,
    status: TStatus = TStatus.STATUS_ACTIVE
): IList[] => {
    const board = getBoard(state, boardId);
    if (
        !board ||
        !board.listIds ||
        !board.listIds.length
    ) return [];

    const lists = board.listIds.reduce((arr, listId) => {
        const list = getList(state, listId);
        if (!list) return arr;

        if (
            status === null ||
            list.status === status
        ) {
            arr.push(list);
        }
        return arr;
    }, []);
    lists.sort((a, b) => a.orderNumber - b.orderNumber);
    return lists;
};
