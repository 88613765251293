import { getMessages } from '../../../../../../store/constants';

export const TOPBAR_BOARD_SETTINGS = getMessages().getText('top_bar.board.settings');
export const TOPBAR_BOARD_ACTIVITY = getMessages().getText('top_bar.board.activity');
export const TOPBAR_BOARD_RENAME = getMessages().getText('top_bar.board.rename');
export const TOPBAR_BOARD_CHANGE_LOGO = getMessages().getText('top_bar.board.change_logo');
export const TOPBAR_BOARD_ADD_TO_STARRED = getMessages().getText('top_bar.board.add_to_starred');
export const TOPBAR_BOARD_REMOVE_FROM_STARRED = getMessages().getText('top_bar.board.remove_from_starred');
export const TOPBAR_BOARD_CLOSE = getMessages().getText('top_bar.board.close');
export const HINT_BOARD_SETTINGS_FOR_OLD_USERS = getMessages().getText('hint.board_settings_for_old_users');
export const TOPBAR_CARD_TEMPLATES = getMessages().getText('top_bar.board.card_templates');
export const TOPBAR_RECURRING_TASKS = getMessages().getText('top_bar.board.recurring_tasks');
export const TOPBAR_BOARD_DROPDOWN_MIN_WIDTH = 200; // px
export const TOPBAR_BOARD_NAME = getMessages().getText('common.board_name');
