import { Dispatch, ThunkAction } from '../../../../types/actions';
import { root, vent } from '../../../../store/constants';
import { EViewTypes } from '../../../../const';
import { getActiveBoard } from '../../../../store/model/selectors/getActiveBoard';
import { IGetState } from '../../../../types/types';
import { closePanel } from '../../aside_panel/asidePanel/effects/closePanel';

export const changeBackboneViewType = (
    viewType: EViewTypes,
    closeAside: boolean = true
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (closeAside) {
            dispatch(closePanel());
        }
        const board = getActiveBoard(getState());
        if (board) {
            const boardViewManager = root.App.controller.getDashboardViewManager(board);
            if (boardViewManager) boardViewManager.setViewType(viewType);
            vent.trigger(vent['dashboard:viewTypeChanged'], board, false);
        }
    };
    return action;
};
