import { IGetState, TCardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { ICard } from '../../../../store/model/card/types';
import { getCard } from '../../../../store/model/selectors/getCard';
import { getBoardByCardId } from '../../../../store/model/selectors/getBoardByCardId';
import { IBoard } from '../../../../store/model/board/types';
import { cardDeleteWithPrompt } from './cardDeleteWithPrompt';
import { TStatus } from '../../../../types/model';
import { cardsArchiveOrDelete } from './cardsArchiveOrDelete';
import { closePanel } from '../../aside_panel/asidePanel/effects/closePanel';
import { getAuthUser } from '../../../../store/model/authUser/selectors/getAuthUser';
import { IAuthUser } from '../../../../store/model/authUser/types';
import { SegmentCardEvent, SegmentCardOptions, segmentTrackAction } from '../../../../middlewares/segment';
import { SegmentCardSourceValue } from '../../../../middlewares/segment/trackEntities/cardEvents';
import { deleteDefaultTemplateCardIdIfNeeded } from 'app/view/react_components/base/effects/deleteDefaultTemplateCardIdIfNeeded';

export const cardDeleteOrArchive = (
    cardId: TCardId,
    closeAsidePanel: boolean = true
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const card: ICard = getCard(state, cardId);
        if (!card) {
            throw new Error('Unknown cardId');
        }
        const user: IAuthUser = getAuthUser(state);
        const board: IBoard = getBoardByCardId(state, cardId);

        dispatch(deleteDefaultTemplateCardIdIfNeeded(cardId))

        if (!user.allowArchive ||
            card.status !== TStatus.STATUS_ACTIVE ||
            !board ||
            !board.archiveCardsOnDelete) {
            return dispatch(cardDeleteWithPrompt(cardId, closeAsidePanel));
        }
        if (closeAsidePanel) {
            dispatch(closePanel());
        }
        dispatch(segmentTrackAction(SegmentCardEvent.ARCHIVE_CARD, {
            name: SegmentCardOptions.SOURCE,
            value: SegmentCardSourceValue.DROPDOWN
        }));

        return dispatch(cardsArchiveOrDelete([cardId]));
    };
    return action;
};
