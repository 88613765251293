import { IGetState, TBoardId, TCardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { getBoardTemplatesCards } from '../../../selectors/getBoardTemplatesCards';
import { ICards } from '../../../../../../../../store/model/cards/types';
import { cardsRestPatch } from '../../../../../../../../rest/effects/card/card/api/helper/cardsRestPatch';
import { ORDER_STEP } from '../../../../../../../../const';

export const onCardSort = (
    cardId: TCardId,
    position: number,
    boardId: TBoardId,
    isRecurring: boolean
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const cardTemplates = getBoardTemplatesCards(getState(), boardId, isRecurring);
        let orderNumber = cardTemplates[position].orderNumber;
        const currentPosition = cardTemplates.findIndex(card => card.id === cardId);
        if (currentPosition < position) {
            if (position === cardTemplates.length - 1) { // last card
                orderNumber += ORDER_STEP;
            } else {
                orderNumber = (orderNumber + cardTemplates[position + 1].orderNumber) / 2;
            }
        } else {
            if (position === 0) { // first card
                orderNumber -= ORDER_STEP;
            } else {
                orderNumber = (orderNumber + cardTemplates[position - 1].orderNumber) / 2;
            }
        }

        const cards: ICards = {
            [cardId]: {
                orderNumber,
            }
        };
        return dispatch(cardsRestPatch(cards));
    };
    return action;
}
