import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../types/types';
import { closeRecentOpenBoard } from '../../../../../../../../rest/effects/authUser/recentBoards/closeRecentOpenBoard';

export const closeBoard: ActionCreator<ThunkAction> = (
    boardId: number,
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(closeRecentOpenBoard(boardId));
    };
    return action;
};
