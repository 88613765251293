import { TBoardAction } from '../../board/actions/types';

/**
 * default prefix "BOARDS"
* */
export const AT_BOARD_ACTION_SET = 'BOARDS_BOARD_ACTION_SET';
export const AT_BOARD_ACTION_DELETE = 'BOARDS_BOARD_ACTION_DELETE';

export interface IBoardActionSetAction {
    type: typeof AT_BOARD_ACTION_SET,
    boardId: number,
    boardAction: TBoardAction
}

export interface IBoardActionDeleteAction {
    type: typeof AT_BOARD_ACTION_DELETE,
    boardId: number
}

export type TBoardsAction = (IBoardActionSetAction | IBoardActionDeleteAction);
