import { IGetState, TBoardId } from '../../../types/types';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { fetchHandler } from '../../../util/fetchHandler';
import { IRestAutoBackup } from '../types';
import { Util } from '../../../util/util';
import { REST_BOARD } from '../../constants';
import { getRestHeadersPatch } from '../../helpers/getRestHeadersHelper';

export const patchRest = (
    body: IRestAutoBackup
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return fetchHandler<IRestAutoBackup>(
            Util.getApiUrl(REST_BOARD + '/autobackups/' + body.id),
            {
                ...getRestHeadersPatch(),
                body: JSON.stringify(body)
            }
        ).then(response => {
            return response;
        });
    };
    return action;
};
