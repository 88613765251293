import { SegmentBoardOptionValue } from '../../boardEvents';
import {
    DRIVE_ID_MY_DRIVE,
    DRIVE_ID_SHARED_WITH_ME
} from '../../../../../view/react_components/dialogs/openBoards/store/drives/constants';
import { TGoogleDriveId } from '../../../../../view/react_components/dialogs/openBoards/store/types';

export const getSegmentEventOptionDriveType = (
    driveId: TGoogleDriveId
) => {
    if (!driveId) return null;
    let value = SegmentBoardOptionValue.SHARED_DRIVE;
    if (driveId === DRIVE_ID_SHARED_WITH_ME)
        value = SegmentBoardOptionValue.SHARED_WITH_ME;
    if (driveId === DRIVE_ID_MY_DRIVE)
        value = SegmentBoardOptionValue.MY_DRIVE;
    return value;
};
