import { IApplicationState, TBoardId, TColorId } from '../../../types/types';
import { IBoard } from '../board/types';
import { getBoardColors } from '../board/selectors/getBoardColors';
import { IColor } from '../../../types/model';
import { getBoardColorById } from '../board/selectors/getBoardColorById';

export const getColorForRollback = (
    state: IApplicationState,
    boardId: TBoardId,
    colorId: TColorId,
    patchColor: IColor
): IColor => {
    const color: IColor = getBoardColorById(state, boardId, colorId);
    if (!patchColor || !color) return;
    let colorForRollback: IBoard = {};

    for (const field in patchColor) {
        const fieldKey = field as keyof IColor;
        colorForRollback = {
            ...colorForRollback,
            [fieldKey]: color[fieldKey]
        };
    }

    return JSON.parse(JSON.stringify(colorForRollback));
}
