import { getMessages } from '../../../../../../../../store/constants';

export const MESSAGE_PREFIX = getMessages().getText( 'cardNumber.prefix');
export const MESSAGE_START_VALUE = getMessages().getText( 'cardNumber.startValue');
export const MESSAGE_DESCRIPTION = getMessages().getText('cardNumber.description');
export const MESSAGE_PREFIX_ERROR = getMessages().getText('error.cardNumber.prefix');
export const BTN_ADD = getMessages().getText('common.add');
export const CARD_NUMBER_MODAL_TITLE_LOADING = getMessages().getText('cardNumber.modal.title.loading');
export const CARD_NUMBER_MODAL_TEXT = getMessages().getText('cardNumber.modal.text');
export const CARD_NUMBER_MODAL_TEXT_LOADING = getMessages().getText('cardNumber.modal.text.loading');
export const CARD_NUMBER_MODAL_NEW = getMessages().getText('cardNumber.modal.new');
export const CARD_NUMBER_MODAL_CURRENT = getMessages().getText('cardNumber.modal.current');
export const CARD_NUMBER_MODAL_BOTH = getMessages().getText('cardNumber.modal.both');
export const CARD_PREVIEW_CARD_NAME = getMessages().getText('cardNumber.card_preview.name');

export const CARD_NUMBER_MIN_LOADING_CARDS_COUNT = 15;
