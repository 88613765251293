import * as React from 'react';
import { useEffect } from 'react';
import { IBoardDescriptionProps } from './types';
import './_BoardDescription.scss';
import { Button, Icon, Input, ButtonsGroup} from 'kui';
import {
    BOARD_DESCRIPTION_ADD,
    BOARD_DESCRIPTION_CANCEL,
    BOARD_DESCRIPTION_FORMATTING,
    BOARD_DESCRIPTION_HOW_TO_URL,
    BOARD_DESCRIPTION_SAVE,
    BOARD_DESCRIPTION_SHOW_MORE,
    BOARD_DESCRIPTION_SHOW_LESS
} from './constants';
import { CLASS_BOARD_DETAILS } from '../../../../constants';
import { root } from 'app/store/constants';
import ReactHtmlParser from 'react-html-parser';
import { CLASS_ASIDE_PANEL, TEXTAREA_MAX_HEIGHT } from 'app/const';
import { getCaretPosition } from 'app/view/react_components/helpers/getCaretPosition';

export function BoardDescription ({
    description,
    descriptionMarkdown,
    isStayOpened,
    onCancel,
    onSave,
    onCtrlEnter,
    onEsc,
    onToggle
}: IBoardDescriptionProps) {
    let className = CLASS_BOARD_DETAILS + '__description';
    const classNameInput = CLASS_BOARD_DETAILS + '__description-input';
    const classNameHtml = CLASS_BOARD_DETAILS + '__description-html';
    const classNameHtmlContainer = CLASS_BOARD_DETAILS + '__description-html-container';
    const classNameButton = CLASS_BOARD_DETAILS + '__description-button';
    const classNameButtonMore = CLASS_BOARD_DETAILS + '__description-button-more';
    const classNameButtonLess = CLASS_BOARD_DETAILS + '__description-button-less';
    const classNameButtonFormatting = CLASS_BOARD_DETAILS + '__description-button-formatting';
    const classNameButtonContainer = CLASS_BOARD_DETAILS + '__description-button-container';
    const classNameButtonSave = CLASS_BOARD_DETAILS + '__description-button-save';

    const [boardDescription, setBoardDescription] = React.useState(description);
    const [isInput, setIsInput] = React.useState(null);
    let [isCollapse, setCollapse] = React.useState(null);
    let [isNeedCollapse, setIsNeedCollapse] = React.useState(null);
    const classEmpty = !boardDescription ? classNameHtmlContainer + '--empty' : '';
    const classCollapse = isCollapse ? classNameHtmlContainer + '--collapsed' : '';
    const classExpanded = !isCollapse ? classNameHtmlContainer + '--expanded' : '';

    const inputRef = React.useRef(null);
    const htmlRef = React.useRef(null);
    const requestAnimation = React.useRef(null);
    const containerRef = React.useRef(null);

    useEffect(() => {
        setBoardDescription(description);
    }, [description]);

    function inputShow(event: React.MouseEvent) {
        const { tagName } = event.target as HTMLElement;
        if (tagName && tagName.toLowerCase() === 'a') return;

        const selection = root.getSelection();
        if (!selection.isCollapsed) return;

        setIsInput(true);
        focusOnInput();
    }

    function handleAddDescriptionButtonCLick(event: React.MouseEvent) {
        setIsInput(true);
        focusOnInput();
    }

    function focusOnInput() {
        const caret = getCaretPosition();
        if (!caret) return;
        let { position, container, selectedParagraphPosition } = caret;
        requestAnimation.current = requestAnimationFrame(() => {
            if (inputRef && inputRef.current) {
                inputRef.current.setFocus();
                const input = document.querySelector(`.${classNameInput} .kui-input__item`) as HTMLInputElement;
                const lines = input.value.split(/\r|\r\n|\n/);
                let lineCount = 0;
                let paragraphsCount = 0;
                let prevLines = null;
                for (let i = 0; i < lines.length; i++) {
                    if (lines[i] === '' && prevLines !== '' && prevLines !== null) paragraphsCount += 1
                    prevLines = lines[i]
                    if (lines[i] === container.nodeValue && selectedParagraphPosition === paragraphsCount) break
                    if (lines[i] === '') lineCount += 1
                }
                position += lineCount;
                input.setSelectionRange(position, position);
            }
        });
    }

    function toggleShowMore() {
        setCollapse(!isCollapse);
        onToggle(isCollapse)
    }

    function onInputKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Escape') {
            event.stopPropagation();//to avoid aside-panel closure on esc if description is in focus
            setBoardDescription(description);
            setIsInput(false);
            onEsc();
        } else if (event.key === 'Enter' && event.ctrlKey) {
            event.preventDefault();
            setIsInput(false);
            onCtrlEnter(boardDescription);
        }
    }

    function handlerSaveClicked(e: React.SyntheticEvent) {
        setIsInput(false);
        onSave(boardDescription);
    }

    function handleCancelClicked() {
        setBoardDescription(description);
        onCancel();
        setIsInput(false);
    }

    function handleInputChange(e: React.SyntheticEvent) {
        let { value } = e.target as HTMLInputElement;
        setBoardDescription(value);
    }

    function checkCollapse () {
        if (!isInput && htmlRef.current) {
            isNeedCollapse = !!htmlRef.current.innerText &&
                htmlRef.current.offsetHeight > TEXTAREA_MAX_HEIGHT;
            setIsNeedCollapse(isNeedCollapse);
            if (isCollapse === null && isNeedCollapse && !isStayOpened || // auto collapse only on open
                isCollapse && !isNeedCollapse && !isStayOpened
            ) {
                setCollapse(isNeedCollapse);
            }
        }
    }

    React.useEffect(() => {
        checkCollapse();
    }, [description]);

    React.useEffect(() => {
        /**
         * при открытии панели надо дождаться отрисовки текста, потом запускать checkCollapse
         */
        const observer = new MutationObserver(() => { // wait classNameHtml innerText
            checkCollapse();
            if (htmlRef.current && htmlRef.current.innerText) observer.disconnect();
        });
        const aside = containerRef.current.closest('.' + CLASS_ASIDE_PANEL) as HTMLElement;
        if (aside) {
            observer.observe(aside, {childList: true, subtree: true});
        }

        return () => {
            if (requestAnimation.current) cancelAnimationFrame(requestAnimation.current);
            observer.disconnect();
        }
    }, []);

    return (
        <div className={ className }
             ref={containerRef}>
            {isInput
                ? <>
                    <Input
                        className={classNameInput}
                        ref={ inputRef }
                        value={ boardDescription }
                        onKeyDown={ onInputKeyDown }
                        onChange={ handleInputChange }
                    />
                    <div className={ classNameButtonContainer }>
                        <Button
                            className={ classNameButtonFormatting }
                            href={ BOARD_DESCRIPTION_HOW_TO_URL }
                            target={ '_blank' }
                            text={ BOARD_DESCRIPTION_FORMATTING }
                            variant={'icon-text'}
                        >
                            <Icon xlink="help" size={ 24 }/>
                        </Button>
                        <ButtonsGroup>
                            <Button
                                variant={'secondary'}
                                text={ BOARD_DESCRIPTION_CANCEL }
                                onClick={ handleCancelClicked }
                            />
                            <Button
                                className={classNameButtonSave}
                                text={ BOARD_DESCRIPTION_SAVE }
                                onClick={ handlerSaveClicked }
                            />
                        </ButtonsGroup>
                    </div>
                </>
                : <>
                    <div
                        className={`
                            ${classNameHtmlContainer}
                            ${classCollapse}
                            ${classEmpty}
                            ${classExpanded}
                        `}
                    >
                        {!!description
                            ? <>
                                <div
                                    className={ classNameHtml }
                                    ref={ htmlRef }
                                    onClick={ inputShow }
                                >
                                    {ReactHtmlParser(descriptionMarkdown)}
                                </div>
                                {(isNeedCollapse || isCollapse) &&
                                <div
                                    className={!!isCollapse ? classNameButtonMore : classNameButtonLess}
                                    onClick={toggleShowMore}
                                >
                                    <Button
                                        variant={'icon-text'}
                                        text={!!isCollapse ? BOARD_DESCRIPTION_SHOW_MORE : BOARD_DESCRIPTION_SHOW_LESS}
                                    >
                                        <Icon
                                            xlink={!!isCollapse ? 'arrow-s-drop-open' : 'arrow-s-drop-close'}
                                            size={24}
                                        />
                                    </Button>
                                </div>
                                }
                            </>
                            : <Button
                                className={ classNameButton }
                                variant={ 'icon-text' }
                                text={ BOARD_DESCRIPTION_ADD }
                                onClick={ handleAddDescriptionButtonCLick }
                            >
                                <Icon
                                    xlink={ 'description' }
                                    size={ 24 }
                                />
                            </Button>
                        }
                    </div>
                </>
            }
        </div>
    );
}
