import { TBoardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { fetchHandler } from '../../../../util/fetchHandler';
import { Util } from '../../../../util/util';
import { getBoardRolesUrl } from '../../../constants';
import { getRestHeadersPost } from '../../../helpers/getRestHeadersHelper';
import { IColor } from '../../../../types/model';

export const migrateRest = (
    boardId: TBoardId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        return fetchHandler<IColor>(
            Util.getApiUrl(getBoardRolesUrl(boardId) + '/migrate'), {
                ...getRestHeadersPost(),
            }
        );
    };
    return action;
};
