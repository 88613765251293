import {IApplicationState} from '../../../../../../types/types';
import {EBoardNameStatus} from '../topBar/types';
import {getTopBar} from './getTopBar';

export const getTopBarBoardNameStatus = (
    state: IApplicationState,
    boardId: number
): EBoardNameStatus => {
    return getTopBar(state, boardId).boardNameStatus;
};
