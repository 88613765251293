import * as React from 'react';
import { IAsidePanelHeaderProps } from './types';
import './_AsidePanelHeader.scss';
import { CLASS_ASIDE_PANEL_HEADER } from './constants';
import { AsidePanelTabsHOC } from '../../hocs/AsidePanelTabsHOC/AsidePanelTabsHOC';
import { AsidePanelCloseHOC } from '../../hocs/AsidePanelCloseHOC/AsidePanelCloseHOC';
import { AsidePanelClButtonHOC } from '../../hocs/AsidePanelClButtonHOC/AsidePanelClButtonHOC';
import { AsidePanelTitle } from '../AsidePanelTitle/AsidePanelTitle';
import { Loader } from 'kui';
import { AsidePanelTitleAction } from '../AsidePanelTitleAction/AsidePanelTitleAction';
import { AsidePanelContext } from '../AsidePanel/constants';

export function AsidePanelHeader ({
    activeTabIndex,
    isFakeLoading,
    isLoading,
    tabs,
    title,
    titleAction,
    isForceClosed,
    onClose,
    onTabChange,
    isAssignedToMe,
    isCardFromNotification
}: IAsidePanelHeaderProps) {
    const className = CLASS_ASIDE_PANEL_HEADER;
    const classNameLoading = isLoading ? className + '--loading' : '';
    const classNameFakeLoading = isFakeLoading ? className + '--fake-loading' : '';
    const classNameLoader = className + '-loader';
    const { boardId, cardId } = React.useContext(AsidePanelContext);
    return (
        <div className={`
            ${className}
            ${classNameLoading}
            ${classNameFakeLoading}
        `}>
            {tabs
                ? <AsidePanelTabsHOC
                    activeTabIndex={activeTabIndex}
                    tabs={tabs}
                    onTabChange={onTabChange}
                />
                : <>
                    <AsidePanelTitle title={title} />
                    {!!titleAction &&
                        <AsidePanelTitleAction titleAction={titleAction} />
                    }
                </>
            }
            {!isLoading && !!tabs && !!tabs[activeTabIndex] && !!tabs[activeTabIndex].button &&
                tabs[activeTabIndex].button
            }
            {(!!isAssignedToMe || !!isCardFromNotification) &&
                <AsidePanelClButtonHOC
                    cardId={cardId}
                />
            }
            <AsidePanelCloseHOC
                boardId={boardId}
                isForceClosed={isForceClosed}
                onClose={onClose}
            />
            <Loader className={classNameLoader} />
        </div>
    );
};
