import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { moveToLoadMore } from '../../../../../../../../rest/effects/board/moveToLoadMore';

export const onLoadMore = (): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.MOVE_TO_LOAD_MORE_CLICKED));
        return dispatch(moveToLoadMore());
    };
    return action;
};
