import { IGetState, TBoardId } from '../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { IPushNotificationSettings } from '../../../../../store/model/pushNotificationSettings/types';
import { updateAction } from '../../../../../store/model/pushNotificationSettings/actions/updateAction';
import { pushNotificationSettingsActionSet } from '../../../../../store/model/pushNotificationsSettings/actions/pushNotificationSettingsActionSet';
import { pushNotificationsSettingsActionSet } from '../../../../../store/model/actions/pushNotificationsSettingsActionSet';
import { getRest } from '../getRest';

export const loadPushNotificationsSettings = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(getRest(boardId))
            .then((notifications: IPushNotificationSettings) => {
                dispatch(pushNotificationsSettingsActionSet(pushNotificationSettingsActionSet(boardId, updateAction(notifications))));
            });
    };
    return action;
};
