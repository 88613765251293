import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { CardPropertiesPanelButton } from '../../components/CardPropertiesPanelButton/CardPropertiesPanelButton';
import { ICardPropertiesPanelButtonEvents, ICardPropertiesPanelButtonFields } from '../../components/CardPropertiesPanelButton/types';
import { onClick } from './events/onClick';
import { ICardPropertiesPanelButtonHOCProps } from './types';

const mapDispatchToProps = (
    dispatch: any,
    {onClick: ownOnClick}: ICardPropertiesPanelButtonHOCProps
): ICardPropertiesPanelButtonEvents => {
    return {
        onClick: () => dispatch(onClick(ownOnClick))
    }
};

export const CardPropertiesPanelButtonHOC = connect(
    null,
    mapDispatchToProps
)(CardPropertiesPanelButton);

CardPropertiesPanelButtonHOC.displayName = 'CardPropertiesPanelButtonHOC';
