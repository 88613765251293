import { IApplicationState, TBoardId } from '../../../types/types';
import { getRequestsState } from './getRequestsState';

export const getBoardActiveRequests = (
    state: IApplicationState,
    boardId: TBoardId
): number => {
    const boards = getRequestsState(state).boards;
    if (!boards[boardId]) return 0;

    return boards[boardId].activeRequests;
}
