import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../types/types';
import { ITypeSwitcherDropUpHOCProps } from './types';
import { getBoardPermissionsAllowEdit } from '../../../../../store/model/selectors/getBoardPermissionsAllowEdit';
import { ITypeSwitcherDropUpEvents, ITypeSwitcherDropUpFields } from '../../components/TypeSwitcherDropUp/types';
import { TypeSwitcherDropUp } from '../../components/TypeSwitcherDropUp/TypeSwitcherDropUp';
import { getActiveBoardId } from 'app/store/model/selectors/getActiveBoardId';
import { getShowProFeaturesTrial } from '../../../../../store/model/authUser/selectors/getShowProFeaturesTrial';
import { EHintKey, EHintStatus } from '../../../hints';
import { getHint } from '../../../hints/store/hints/selectors/getHint';
import { getShowGanttContextHints } from '../../../hints/conditions/ganttContextConditions/getShowGanttContextHints';
import { getIsUserBasicPlanOnly } from '../../../../../store/model/authUser/selectors/getIsUserBasicPlanOnly';

const mapStateToProps = (
    state: IApplicationState,
    props: ITypeSwitcherDropUpHOCProps,
): ITypeSwitcherDropUpFields => {
    const { active, isExpanded } = props;
    const boardId = getActiveBoardId(state);
    const allowEdit = getBoardPermissionsAllowEdit(state, boardId);
    const isShowHint = getShowGanttContextHints(state) &&
        getHint(state, EHintKey.GANTT_CONTEXT_GANTT_TYPE_SWITCHER).status === EHintStatus.SHOWN &&
        getHint(state, EHintKey.GANTT_CONTEXT_GANTT_VIEW).status === EHintStatus.NOT_SHOWN;
    return {
        active,
        isExpanded,
        isBasicFeature: getIsUserBasicPlanOnly(state),
        isProFeature: getShowProFeaturesTrial(state),
        isShowHint,
        isShowUsersView: allowEdit,
    }
};

const mapDispatchToProps = (
    dispatch: any,
    props: ITypeSwitcherDropUpHOCProps
): ITypeSwitcherDropUpEvents => {
    const { onTypeSelected } = props;
    return {
        onTypeSelected,
    }
};

export const TypeSwitcherDropUpHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(TypeSwitcherDropUp);
TypeSwitcherDropUpHOC.displayName = 'TypeSwitcherDropUpHOC';
