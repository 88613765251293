'use strict';

import { getActivityItem } from '../react_components/aside_panel/boardDetails/activity/TabActivity/helpers/aсtivityHelper';
import CardCreateAssignedNotification  from './card_create_assigned_notification';

App.Views.CardCreateNotification = App.Views.BaseNotification.extend({

    getActions: function() {
        if (this.notificationModel.users && this.notificationModel.users.length){
            let notification = new CardCreateAssignedNotification({model: this.model});
            return notification.getActions();
        }

        const item = getActivityItem(this.notificationModel);
        return {
            action: item.label,
            actionData: {
                icon: 'create'
            }
        };
    }
});
