import { IApplicationState } from '../../../../../../../../types/types';
import {
    INotificationEditingPopupDispatchProps,
    INotificationEditingPopupOwnProps,
    INotificationEditingPopupStateProps,
} from '../../components/NotificationEditingPopUp/types';
import { ITimeUnits } from '../../../../../../../../types/model';
import { connect } from 'react-redux';
import { NotificationEditingPopUp } from '../../components/NotificationEditingPopUp/NotificationEditingPopUp';
import { getEditedNotificationsByCardId } from '../../selectors/getEditedNotificationsByCardId';
import { checkEditStateValid } from '../../helpers/checkIsValid';
import { onEnter } from './events/onEnter';
import { onAddNotification } from './events/onAddNotification';
import { onChangeInterval } from './events/onChangeInterval';
import { onChangeTimeUnit } from './events/onChangeTimeUnit';
import { onCancel } from './events/onCancel';
import { onSave } from './events/onSave';
import { onRemoveNotification } from './events/onRemoveNotification';
import { onDidUnmount } from './events/onDidUnmount';
import { getNotificationBeforeDueDateEditsPopUpSettingSelector } from '../../selectors/getNotificationBeforeDueDateEditsPopUpSettingSelector';
import { getCard } from '../../../../../../../../store/model/selectors/getCard';

const mapStateToProps = (
    state: IApplicationState,
    { cardId }: INotificationEditingPopupOwnProps,
): INotificationEditingPopupStateProps => {
    const { dueDate } = getCard(state, cardId);
    return {
        isOpen: getNotificationBeforeDueDateEditsPopUpSettingSelector(state, cardId),
        notifications: getEditedNotificationsByCardId(state, cardId),
        isValid: checkEditStateValid(state, cardId),
        dueDate,
    }
};

const mapDispatchToProps = (
    dispatch: any,
    { boardId, cardId }: INotificationEditingPopupOwnProps,
): INotificationEditingPopupDispatchProps => {
    return {
        onEnter: () => dispatch(onEnter(boardId, cardId)),
        onAddNotification: () => dispatch(onAddNotification(cardId)),
        onChangeInterval: (notificationId: number, interval: number) => dispatch(onChangeInterval(cardId, notificationId, interval)),
        onChangeTimeUnit: (notificationId: number, unit: ITimeUnits) => dispatch(onChangeTimeUnit(cardId, notificationId, unit)),
        onCancel: () => dispatch(onCancel(cardId)),
        onSave: () => dispatch(onSave(boardId, cardId)),
        onRemoveNotificationById: (notificationId: number) => dispatch(onRemoveNotification(cardId, notificationId)),
        onDidUnmount: (uuid) => dispatch(onDidUnmount(uuid)),
    }
};

export const NotificationEditingPopUpHOC = connect(
    mapStateToProps,
    mapDispatchToProps,
)(NotificationEditingPopUp);

NotificationEditingPopUpHOC.displayName = 'NotificationEditingPopUpHOC';
