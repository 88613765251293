import {ThunkAction} from 'app/types/actions';
import {fetchHandler} from 'app/util/fetchHandler';
import Util from 'app/util/util';
import {getRestHeadersPost} from 'app/rest/helpers/getRestHeadersHelper';

export const sendErrorEmail = (
    code: number,
    message: string,
): ThunkAction => {
    const action = (
    ) => {
        console.error(message);
        const url = '/rest/error';
        return fetchHandler(
            Util.getApiUrl(url), {
                ...getRestHeadersPost(),
                body: JSON.stringify({code, message})
            }
        );
    };
    return action;
};
