import { IGetState, TCardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { deleteRestHelper } from './api/helper/deleteRestHelper';
import { ERestDriveDocType, TDriveDocId } from '../../../../types/rest/IRestDriveDoc';
import { getDriveDoc } from '../../../../store/model/driveDocs/selectors/getDriveDoc';
import { cardUpdateAttachmentsCount } from '../cardUpdateAttachmentsCount';

export const deleteAttachment = (
    cardId: TCardId,
    driveDocId: TDriveDocId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const driveDoc = getDriveDoc(getState(), driveDocId);
        return dispatch(deleteRestHelper(cardId, driveDocId))
            .then(() => {
                if (driveDoc && driveDoc.type === ERestDriveDocType.CARD) {
                    dispatch(cardUpdateAttachmentsCount(cardId));
                }
            });
    };
    return action;
};
