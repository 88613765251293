import * as React from 'react';
import { IAsidePanelTabsProps } from './types';
import './_AsidePanelTabs.scss';
import { CLASS_ASIDE_PANEL_TABS } from './constants';
import { Tabs, Button } from 'kui';
import { NewFeatureIndicator } from '../../../../base/components/NewFeatureIndicator/NewFeatureIndicator';
import { ProFeatureIndicator } from '../../../../base/components/ProFeatureIndicator/ProFeatureIndicator';
import { AsidePanelContext } from '../AsidePanel/constants';

export function AsidePanelTabs ({
    activeTabIndex,
    tabs,
    onChange
}: IAsidePanelTabsProps) {
    const { cardId } = React.useContext(AsidePanelContext);
    const classTab = CLASS_ASIDE_PANEL_TABS + '-item';
    return (
        <Tabs
            className={CLASS_ASIDE_PANEL_TABS}
            active={activeTabIndex}
            onChange={newIdx => onChange(newIdx, activeTabIndex, cardId)}
        >
            {tabs.map(tab => (
                <Button
                    key={tab.key}
                    onClick={tab.onClick}
                    className={`${classTab} ${tab.onClick ? classTab + '--action' : ''}`}
                >
                    {tab.name}
                    {
                        tab.indicatorNumber
                            ? <span className={CLASS_ASIDE_PANEL_TABS + '-indicator-number'}>{tab.indicatorNumber}</span>
                            : null
                    }
                    {tab.isNewFeature && <NewFeatureIndicator />}
                    {tab.isProFeature && <ProFeatureIndicator />}
                </Button>
            ))}
        </Tabs>
    );
};
