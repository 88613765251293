import * as React from 'react';
import { Button, Input, Loader, LoaderBlock } from 'kui';
import { IBoardCardNumberProps } from './types';
import './_BoardCardNumber.scss';
import {
    BTN_ADD, CARD_NUMBER_MIN_LOADING_CARDS_COUNT,
    CARD_NUMBER_MODAL_BOTH,
    CARD_NUMBER_MODAL_CURRENT,
    CARD_NUMBER_MODAL_NEW,
    CARD_NUMBER_MODAL_TEXT,
    CARD_NUMBER_MODAL_TEXT_LOADING,
    CARD_PREVIEW_CARD_NAME,
    MESSAGE_DESCRIPTION,
    MESSAGE_PREFIX,
    MESSAGE_PREFIX_ERROR,
    MESSAGE_START_VALUE,
} from './constants';
import { ECardNumberChangeKey } from '../../store/cardNumber/actions/types';
import { ECardNumberStatus } from '../../store/cardNumber/types';
import { v4 as uuidv4 } from 'uuid';
import { ErrorHint } from '../../../../../../base/components/ErrorHint/ErrorHint';
import { BOARD_DETAILS_ONLY_OWNER } from '../../../../constants';
import { getBoardCardNumberModalTitle } from './helpers/getBoardCardNumberModalTitle';
import { ActionModal } from '../../../../../../base/components/ActionModal/ActionModal';
import { root } from '../../../../../../../../store/constants';
import { UserpicStorage } from '../../../../../../base/components/UserpicStorage/UserpicStorage';

export const BoardCardNumber = ({
    edited,
    enabled,
    errorMessage,
    isNoCards,
    isReadonly,
    isDisabled,
    isDisallow,
    nextValue,
    prefix,
    status,
    boardEnabled,
    boardPrefix,
    boardNextValue,
    canGeneratePrefixes,
    userImg,
    onAddExisting,
    onAddExistingAndFuture,
    onAddFuture,
    onCancel,
    onChangeCardNumber,
    onClickLink,
    onClickDisallow,
    onDidMount,
    onSaveCardNumber,
    onBoardChanges,
    onGenerate,
    onRegenerate,
    onToggleOff,
    onShowSnackbar
}: IBoardCardNumberProps) => {
    const imgUrl = require('!file-loader!./card.png').default;

    const className = 'card-number-section';
    const classField = 'card-number-field';
    const classFieldPrefix = classField + '__prefix';
    const classFieldNumber = classField + '__number';
    const classPreview = className + '__preview';

    const [errorPrefix, setErrorPrefix] = React.useState(null as string);
    const [errorPrefixKey, setErrorPrefixKey] = React.useState(uuidv4());
    const [errorNumber, setErrorNumber] = React.useState(null as string);
    const [errorNumberKey, setErrorNumberKey] = React.useState(uuidv4());
    const [isShow, setShow] = React.useState(null);
    const [isLoading, setLoading] = React.useState(null);
    const [activeEl, setActiveEl] = React.useState(null);

    const abortController = React.useRef(null);

    const onClose = () => {
        setShow(false);
        if (isLoading && abortController.current) {
            abortController.current.abort();
            onCancel();
        }
        setLoading(false);
        if (activeEl) activeEl.focus();
    };

    const onEdit = () => {
        if (!edited) return Promise.resolve();
        return onSaveCardNumber();
    };

    const addCurrent = (
        isToggleOff = true
    ) => {
        abortController.current = new AbortController();
        const isEdited = edited && boardPrefix && boardPrefix !== prefix;
        const promise = isEdited ? onRegenerate : onGenerate;
        promise(abortController.current).then(() => {
            if (isToggleOff) {
                onToggleOff();
            }
            onShowSnackbar(!isToggleOff);
        }).finally(onClose);
    };

    const onAddCurrent = () => {
        const isShowGenerate = canGeneratePrefixes && enabled && !!prefix && !!nextValue;
        const isEdited = edited && boardPrefix && boardPrefix !== prefix;
        if (!isShowGenerate && !isEdited) {
            onClose();
            return;
        }
        setLoading(true);
        if (!boardPrefix || isEdited) {
            onSaveCardNumber().then(() => {
                addCurrent();
            });
        } else {
            addCurrent();
        }
    };

    const onBoth = () => {
        setLoading(true);
        onEdit().then(() => {
            addCurrent(false);
        });
    };

    React.useEffect(() => {
        if (status === ECardNumberStatus.LOADED && !prefix) {
            setErrorPrefix(MESSAGE_PREFIX_ERROR);
            setErrorPrefixKey(uuidv4())
        } else {
            setErrorPrefix(null)
        }
    }, [prefix, status])

    React.useEffect(() => {
        if (status === ECardNumberStatus.LOADED && !!errorMessage) {
            setErrorNumber(errorMessage);
            setErrorNumberKey(uuidv4())
        } else {
            setErrorNumber(null)
        }
    }, [errorMessage, status])

    React.useEffect(() => {
        onDidMount();
    }, []);

    React.useEffect(() => {
        onBoardChanges();
    }, [boardEnabled, boardPrefix, boardNextValue]);
    const handleInput = (key: ECardNumberChangeKey) => (
        e: React.SyntheticEvent
    ) => {
        const { value } = e.target as HTMLInputElement;
        onChangeCardNumber(key, value);
    };

    const [progress, setProgress] = React.useState(null);
    const timerRef = React.useRef(null);
    React.useEffect(() => {
        if (timerRef.current) clearInterval(timerRef.current);
        if (isLoading) {
            if (root.App.controller.boardCardPrefixInfo.totalCardsCount < CARD_NUMBER_MIN_LOADING_CARDS_COUNT) return; // нет смысла показывать, если мало карт
            timerRef.current = setInterval(() => {
                setProgress(root.App.controller.boardCardPrefixInfo.getAddedCardsPercentage());
            }, 50);
        } else {
            setProgress(null);
        }
    }, [isLoading]);

    const btnLoading = [ECardNumberStatus.SAVING, ECardNumberStatus.VALIDATING].includes(status);
    const isShowGenerate = canGeneratePrefixes && enabled && !!prefix && !!nextValue;
    const onClick = (e: React.MouseEvent) => {
        if (!isDisallow) return;
        const target = e.target as HTMLElement;
        if (target && target.className.includes(className + '__link')) return;
        onClickDisallow();
    };
    return (
        <div
            className={`${className} ${isDisallow ? className + '--disallow' : ''}`}
            onClick={onClick}
        >
            {[ECardNumberStatus.ENABLING, ECardNumberStatus.INITIAL].includes(status) &&
                <LoaderBlock />
            }
            <div>
                <div className="card-number__text">
                    <p>
                        {MESSAGE_DESCRIPTION}
                        {' '}
                        <a
                            className={className + '__link'}
                            onClick={onClickLink}
                            href="https://www.kanbanchi.com/faqwd/kanbanchi-card-prefix"
                            target="_blank"
                        >More instructions</a>
                    </p>
                    {isDisabled && <p>{BOARD_DETAILS_ONLY_OWNER}</p>}
                </div>
                {enabled && <div className="card-number__fields">
                    <div className={`
                        ${classField}
                        ${classFieldPrefix}
                    `}>
                        <Input
                            value={ prefix }
                            onChange={ handleInput(ECardNumberChangeKey.prefix) }
                            state={!prefix ? 'error': undefined}
                            maxLength={3}
                            placeholder={ MESSAGE_PREFIX }
                            autosize={false}
                            readOnly={isReadonly && !btnLoading}
                        />
                        <ErrorHint
                            direction={'up-right'}
                            key={errorPrefixKey}
                            portalSelector={`.${className} .${classFieldPrefix} .kui-label`}
                            selector={`.${className} .${classFieldPrefix} .kui-input__item`}
                            value={errorPrefix}
                        />
                    </div> - <div className={`
                        ${classField}
                        ${classFieldNumber}
                    `}>
                        <Input
                            className={'card-number-next-value'}
                            value={ nextValue }
                            onChange={ handleInput(ECardNumberChangeKey.nextValue) }
                            state={errorMessage ? 'error': undefined}
                            placeholder={ MESSAGE_START_VALUE }
                            autosize={false}
                            readOnly={isReadonly && !btnLoading}
                            type="number"
                        />
                    <ErrorHint
                        translate={{ left: -90 }}
                        direction={'up-right'}
                        key={errorNumberKey}
                        portalSelector={`.${className} .${classFieldNumber} .kui-label`}
                        selector={`.${className} .${classFieldNumber} .kui-input__item`}
                        value={errorNumber}
                    />
                    </div>
                    {(isShowGenerate || edited) &&
                        <div className={'card-number-button' + (btnLoading ? ' card-number-button--loading' : '')}>
                            {btnLoading && <Loader />}
                            <Button
                                variant={'primary'}
                                onClick={() => {
                                    if (isNoCards) {
                                        onEdit();
                                    } else {
                                        setActiveEl(document.activeElement);
                                        setShow(true);
                                    }
                                }}
                                disabled={isReadonly || !prefix || !nextValue || !!errorMessage }
                            >{ BTN_ADD }</Button>
                        </div>
                    }
                </div>}
            </div>
            {enabled &&
                <div className={classPreview}>
                    <div className={`${classPreview}-img`}>
                        <img src={imgUrl} />
                    </div>
                    <div className={classPreview + '-content'}>
                        <div className={classPreview + '-number'}>{prefix}-{nextValue}</div>
                        <span className={classPreview + '-name'}>{CARD_PREVIEW_CARD_NAME}</span>
                    </div>
                    <UserpicStorage src={userImg} size={24} className={classPreview + '-user'} />
                </div>
            }
            <ActionModal
                isShow={isShow}
                actions={[
                    {
                        variant: 'primary',
                        onClick: () => {
                            onAddFuture();
                            onEdit();
                            onClose();
                        },
                        children: CARD_NUMBER_MODAL_NEW
                    },
                    {
                        variant: 'secondary',
                        onClick: () => {
                            onAddExisting();
                            onAddCurrent();
                        },
                        children: CARD_NUMBER_MODAL_CURRENT
                    },
                    {
                        variant: 'secondary',
                        onClick: () => {
                            onAddExistingAndFuture();
                            onBoth();
                        },
                        children: CARD_NUMBER_MODAL_BOTH
                    },
                ]}
                text={isLoading ? CARD_NUMBER_MODAL_TEXT_LOADING : CARD_NUMBER_MODAL_TEXT}
                title={getBoardCardNumberModalTitle(isLoading, prefix)}
                isLoading={isLoading}
                progress={progress}
                onClose={onClose}
            />
        </div>
    );
};
