import { cardsActionSetAction } from '../../actions/cardsActionSetAction';
import { TCardId } from '../../../../types/types';
import { cardActionSetAction } from '../../cards/actions/cardActionSetAction';
import { INotificationBeforeDueDate } from '../../notificationBeforeDueDate/types';
import { notificationBeforeDueDateAddAction } from '../../card/actions/notificationBeforeDueDateAddAction';
import { ICardsActionSetAction } from '../../actions/types';

export const notificationBeforeDueDateAdd = (
    cardId: TCardId,
    notificationBeforeDueDate: INotificationBeforeDueDate
): ICardsActionSetAction => {
    return cardsActionSetAction(cardActionSetAction(cardId, notificationBeforeDueDateAddAction(notificationBeforeDueDate)));
};
