import { connect } from 'react-redux';
import { AssigneesPanelButton } from '../../components/AssigneesPanelButton/AssigneesPanelButton';
import { IAssigneesPanelButtonEvents, IAssigneesPanelButtonFields } from '../../components/AssigneesPanelButton/types';
import { IApplicationState } from '../../../../../../../types/types';
import { ICardAssigneesPanelButtonHOCProps } from './types';
import { CARD_SECTION_ASSIGNEES } from '../../components/constants';
import { getPanelButtonShow } from '../../../selectors/getPanelButtonShow';
import { onClick } from './events/onClick';
import { getCardAssignees } from '../../../../../../../store/model/selectors/getCardAssignees';

const mapStateToProps = (
    state: IApplicationState,
    { boardId, cardId, isShowTooltip }: ICardAssigneesPanelButtonHOCProps
): IAssigneesPanelButtonFields => {
    const assignedToDetailsCount = getCardAssignees(state, cardId).length;
    const isShow = getPanelButtonShow(state, boardId, cardId);
    const tooltip = isShowTooltip
        ? CARD_SECTION_ASSIGNEES
        : null;
    return {
        assignedToDetailsCount,
        isShow,
        tooltip
    }
};

const mapDispatchToProps = (
    dispatch: any,
    {boardId, cardId, onClick: ownOnClick}: ICardAssigneesPanelButtonHOCProps
): IAssigneesPanelButtonEvents => {
    return {
        onClick: () => dispatch(onClick(boardId, cardId, ownOnClick)),
    }
};

export const AssigneesPanelButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(AssigneesPanelButton);

AssigneesPanelButtonHOC.displayName = 'AssigneesPanelButtonHOC';
