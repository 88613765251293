import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState } from '../../../../../../../types/types';
import { startFirstTrial } from '../effects/startFirstTrial';
import { SegmentUserSourceValue } from '../../../../../../../middlewares/segment/trackEntities/userEvents';

export const onClickStartTrialModal = (
): ThunkAction =>  {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(startFirstTrial(SegmentUserSourceValue.FREE_VERSION_MODAL));
    };
    return action;
};
