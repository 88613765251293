import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../../../../types/types';
import { cardOpen } from '../../../../../../base/effects/cardOpen';

export const onClick = (
    cardId: TCardId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(cardOpen(cardId));
    };
    return action;
};
