import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../types/types';
import { boardOpen } from '../../../../../../base/effects/boardOpen';
import { getActiveBoardId } from 'app/store/model/selectors/getActiveBoardId';

export const clickBoard: ActionCreator<ThunkAction> = (
    boardId: number,
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (getActiveBoardId(getState()) === boardId) return;

        dispatch(boardOpen(boardId));
    };
    return action;
};
