import * as React from 'react';
import { IStatusListProps } from './types';
import './_StatusList.scss';
import { CLASS_BOARD_DETAILS } from '../../../../constants';
import { SearchSelect } from '../../../../../../base/components/SearchSelect/SearchSelect';
import { StatusListAdd } from '../StatusListAdd/StatusListAdd';
import { Switch } from 'kui';

export function StatusList ({
    label,
    description,
    extraOptions,
    options,
    selectedId,
    onChange,
    isDisabled,
}: IStatusListProps) {
    const className = CLASS_BOARD_DETAILS + '__statuslist';
    const classLabel = className + '--label';
    const classDesc = className + '--desc';

    const currentOption = options.find((item) => item.value === String(selectedId));
    const text = currentOption ? currentOption.text : '';
    const onSelectHandler = (id: string) => {
        onChange(Number(id));
    }

    return (
        <div className={className}>
            <div className={classLabel}>{label}</div>
            <div className={classDesc}>{description}</div>
            <SearchSelect
                options={options}
                option={StatusListAdd}
                onSelect={onSelectHandler}
                activeValue={String(selectedId)}
                text={text}
                isFitWindow={true}
                variant={'arrow'}
                disabled = {isDisabled}
            />
            {extraOptions && !!extraOptions.length && extraOptions.map(({ label, isChecked, onChange }) => (
                <div className={className + '-switch'} key={label}>
                    {label}
                    <Switch checked={isChecked} onChange={onChange} />
                </div>
            ))}
        </div>);
};
