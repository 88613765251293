import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../../types/types';

export const onClick = (
    ownOnClick?: () => void
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        // console.log('segment prefixes button onClick');
        if (ownOnClick) ownOnClick();
    };
    return action;
};
