import { IApplicationState, TBoardId } from '../../../../types/types';
import {
    EAsidePanelProperty
} from '../../../../view/react_components/aside_panel/asidePanel/components/AsidePanel/types';
import { getBoardCardProperties } from './getBoardCardProperties';
import { ICardPropertyOption } from '../../../../types/rest/IRestBoardMeta';

export const getBoardCardCustomPropertyOptions = (
    state: IApplicationState,
    boardId: TBoardId
): ICardPropertyOption[] => {
    const cardProperties = getBoardCardProperties(state, boardId);
    const customProperty = cardProperties && cardProperties.find(({ property }) => property === EAsidePanelProperty.CARD_CUSTOM);
    return customProperty && customProperty.options || [];
};
