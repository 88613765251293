import { getNotificationBeforeDueDateEditsSelector } from './getNotificationBeforeDueDateEditsSelector';
import { IApplicationState, TCardId } from '../../../../../../../types/types';

export const getNotificationBeforeDueDateEditsPopUpSettingSelector = (
    state: IApplicationState,
    cardId: TCardId,
): boolean => {
    const notificationEdits = getNotificationBeforeDueDateEditsSelector(state, cardId);
    let isPopupOpened = false;
    if (
        notificationEdits &&
        notificationEdits.settings &&
        notificationEdits.settings.isPopupOpened
    ) {
        isPopupOpened = notificationEdits.settings.isPopupOpened;
    }
    return isPopupOpened;
};
