import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { SegmentCardSourceValue } from '../../../../../../../../middlewares/segment/trackEntities/cardEvents';
import { IGetState, TBoardId, TCardId } from '../../../../../../../../types/types';
import { SegmentCardEvent, SegmentCardOptions, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { cleverOpenCard } from '../../../../../../base/effects/cleverOpenCard';

export const onSubcardLink = (
    subcardId: TCardId,
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.CLICKED_SUBCARD_LINK, {
            name: SegmentCardOptions.SOURCE,
            value: SegmentCardSourceValue.DETAILS
        }));
        dispatch(cleverOpenCard(subcardId, boardId));
    };
    return action;
};
