import * as React from 'react';
import { stripHtml } from "../../helpers/stripHtmlHelper";
import { markdown } from "../../../../../helper/markdownHelper";

const MoveToCategories = (props) => {
    return (
        <ul className="move-card__boards">
            {
                props.categories.map((cat, i) => {
                    const isDisabled = props.disabledCategories.indexOf(cat.id) > -1;
                    const isSelected = props.selectedCat === cat.id;
                    const selectedClass = isSelected ? 'move-card__board--selected' : '';
                    const disabledClass = isDisabled ? 'move-card__board--disabled' : '';

                    return (
                        <li className={`move-card__board ${selectedClass} ${disabledClass}`}
                            key={i}
                            onClick={() => props.onClick(cat)}
                        >
                            <span className="move-card__board-name">
                                { stripHtml(markdown(cat[props.categoryName])) }
                            </span>
                        </li>
                    );
                })
            }
        </ul>
    );
};

MoveToCategories.defaultProps = {
    selectedCat: 0,
    categoryName: 'name',
    disabledCategories: [],
    onClick: () => {}
};

export {MoveToCategories};
