import { IApplicationState, TBoardId, TTag } from '../../../../types/types';
import { ICard } from '../../card/types';
import { createDeepEqualSelector } from '../../../../view/react_components/helpers/memoizeHelper';
import { getBoardCards } from '../../selectors/getBoardCards';

const getBoardTagsSelector = (
    cards: ICard[],
): TTag[] => {
    const cardTags = cards.map((card) => card.tags || []).flat().filter(tag => !!tag);
    return [...new Set(cardTags)];
};

export const getBoardTags: (
    state: IApplicationState,
    boardId: TBoardId,
) => TTag[] = createDeepEqualSelector([
        getBoardCards,
    ],
    getBoardTagsSelector
);
