import { Store } from 'redux';
import { IApplicationState, TCardId, TListId } from '../../../../types/types';
import { LISTS_ACTION_SET, MODEL_UPDATE, TModelAction } from '../../actions/types';
import { EMapUpdateType, TIdsMap } from '../types';
import { AT_DELETE, AT_LISTS_UPDATE, AT_SET } from '../../lists/actions/constants';
import { getBoard } from '../../selectors/getBoardById';
import { getList } from '../../list/selectors/getList';
import { boardsActionSet } from '../../actions/boardsActionSet';
import { boardActionSet } from '../../boards/actions/boardActionSet';
import { updateAction } from '../../board/actions/updateAction';
import { IBoard } from '../../board/types';
import { isObjectEmpty } from '../../../../view/react_components/helpers/isObjectEmpty';

const updateIdsMap = (
    state: IApplicationState,
    listId: TCardId,
    dashboardId: TListId,
    idsMap: TIdsMap,
    addOrRemove: EMapUpdateType
): TIdsMap => {
    if (dashboardId) {
        const board = getBoard(state, dashboardId);
        if (board) {
            if (!idsMap[dashboardId]) {
                const { listIds = [] } = board;
                idsMap[dashboardId] = [...listIds];
            }
            const index = idsMap[dashboardId].indexOf(listId);
            if (addOrRemove === EMapUpdateType.ADD && index === -1) {
                idsMap[dashboardId].push(listId);
            }
            if (addOrRemove === EMapUpdateType.REMOVE && index !== -1) {
                idsMap[dashboardId].splice(index, 1);
            }
        }
    }
    return idsMap;
}

const updateBoards = (
    store: Store<IApplicationState>,
    idsMap: TIdsMap
) => {
    if (isObjectEmpty(idsMap)) return;
    for (let boardId in idsMap) {
        const board: IBoard = {
            listIds: idsMap[boardId]
        }
        store.dispatch(boardsActionSet(boardActionSet(Number(boardId), updateAction(board))));
    }
}

export const listIdsMiddleware  = (store: Store<IApplicationState>) => (next: any) => (action: TModelAction) => {
    let result = null, nextExecuted = false;
    let idsMap: TIdsMap = {};
    if (action.type === LISTS_ACTION_SET){
        const subAction = action.listsAction;
        switch (subAction.type) {
            case AT_SET: {
                for (let listId in subAction.lists) {
                    const { dashboardId } = subAction.lists[listId];
                    if (dashboardId) {
                        idsMap = updateIdsMap(store.getState(), Number(listId), dashboardId, idsMap, EMapUpdateType.ADD);
                    }
                }
                break;
            }
            case AT_DELETE: {
                for (let listId in subAction.lists) {
                    let { dashboardId } = subAction.lists[listId];
                    if (!dashboardId) {
                        const list = getList(store.getState(), Number(listId));
                        dashboardId = list && list.dashboardId;
                    }
                    if (dashboardId) {
                        idsMap = updateIdsMap(store.getState(), Number(listId), dashboardId, idsMap, EMapUpdateType.REMOVE);
                    }
                }
                break;
            }
            case AT_LISTS_UPDATE: {
                for (let listId in subAction.lists) {
                    const list = subAction.lists[listId];
                    if ('dashboardId' in list) {
                        const storeList = getList(store.getState(), Number(listId)) || {};
                        if (storeList.dashboardId !== list.dashboardId) { // поменялся боард
                            // добавляем в новый боард
                            idsMap = updateIdsMap(store.getState(), Number(listId), list.dashboardId, idsMap, EMapUpdateType.ADD);

                            // удаляем из старого боарда
                            idsMap = updateIdsMap(store.getState(), Number(listId), storeList.dashboardId, idsMap, EMapUpdateType.REMOVE);
                        }
                    }
                }
                break;
            }
        }
    } else if (action.type === MODEL_UPDATE) {
        result = next(action);
        nextExecuted = true;
        for (let listId in action.model.lists) {
            const { dashboardId } = action.model.lists[listId];
            if (dashboardId) {
                idsMap = updateIdsMap(store.getState(), Number(listId), dashboardId, idsMap, EMapUpdateType.ADD);
            }
        }
    }
    updateBoards(store, idsMap);
    if (!nextExecuted) {
        result = next(action);
    }
    return result;
}
