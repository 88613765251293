import { ICardAssignees } from '../types';
import { AT_CARD_ASSIGNEE_ADD_ACTION, AT_CARD_ASSIGNEE_UPDATE_ACTION, AT_SET_ACTION } from '../actions/constants';
import { TCardAssigneesAction } from '../actions/types';

const initialState: ICardAssignees = {};

export const cardAssigneesReducer = (
    state = initialState,
    action: TCardAssigneesAction
): ICardAssignees  => {
    switch (action.type) {
        case AT_SET_ACTION: {
            const { cardAssignees } = action;
            return {
                ...state,
                ...cardAssignees
            }
        }
        case AT_CARD_ASSIGNEE_UPDATE_ACTION: {
            const { cardId, cardAssignee } = action;
            if (!state[cardId]) return state;
            return {
                ...state,
                [cardId]: {
                    ...state[cardId],
                    ...cardAssignee
                }
            }
        }
        case AT_CARD_ASSIGNEE_ADD_ACTION: {
            const { cardId, cardAssignee } = action;
            return {
                ...state,
                [cardId]: cardAssignee
            }
        }
        default: {
            return state;
        }
    }
};
