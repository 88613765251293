import {ITopBarBoardState} from '../types';
import {TTopBarBoardAction} from '../actions/types';
import {AT_TOP_BAR_BOARD_ACTION_SET} from '../actions/constants';
import {topBarReducer} from '../../topBar/reducers/topBarReducer';
import * as deepFreeze from 'deep-freeze';
import { setLastUsedFilter } from '../localStorage/actions/setLastUsedFilters';
import { getTopBarBoardInitialState } from '../../constants';

const initialState: ITopBarBoardState = getTopBarBoardInitialState();

const _topBarBoardReducer = (
    state = initialState,
    action: TTopBarBoardAction
    ): ITopBarBoardState => {
        switch (action.type) {
            case AT_TOP_BAR_BOARD_ACTION_SET: {
                return {
                    ...state,
                    [action.boardId]: topBarReducer(state[action.boardId], action.topBarAction)
                }
            }
            default:
                return state;
        }
    };

export const topBarBoardReducer = (
    state = initialState,
    action: TTopBarBoardAction
    ): ITopBarBoardState => {
        let newState;
        // @ts-ignore
        if (window.Settings.development) {
            // @ts-ignore
            newState = deepFreeze(_topBarBoardReducer(state, action));
        } else {
            newState = _topBarBoardReducer(state, action);
        }
        if(newState !== state) {
            setLastUsedFilter(newState);
        }
        return newState;
    };
