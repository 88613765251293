import { IApplicationState, TCardId } from '../../../../../../../../types/types';
import { isObjectEmpty } from '../../../../../../helpers/isObjectEmpty';
import { getChecklist } from '../../../../../../../../store/model/checklists/checklists/selectors/getChecklist';
import { CHECKLIST_DEFAULT_ID } from '../../../../../../../../store/model/checklists/checklists/constants';

export const checkIfDefaultChecklistIsEmpty = (
    state: IApplicationState,
    cardId: TCardId
): boolean => {
    const defaultChecklist = getChecklist(state, cardId, CHECKLIST_DEFAULT_ID);
    const defaultChecklistItems = defaultChecklist && defaultChecklist.checkItems;
    return !defaultChecklistItems || isObjectEmpty(defaultChecklistItems);
}
