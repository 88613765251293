import { AsidePanelActionsButton } from '../../../../..';
import { CLASS_BOARD_DETAILS_SECTION } from '../../../../constants';
import * as React from 'react';
import { IArchiveOnDeletePanelButtonProps } from './types';
import { BOARD_SECTION_ARCHIVE_ON_DELETE_BUTTON } from '../../constants';

export function ArchiveOnDeletePanelButton ({
    indicatorBoolean,
    isBasicFeature,
    isDisabled,
    tooltip,
    onClick
}: IArchiveOnDeletePanelButtonProps) {
    return (
        <AsidePanelActionsButton
            children={null}
            indicatorBoolean={indicatorBoolean}
            isBasicFeature={isBasicFeature}
            isDisabled={isDisabled}
            sectionSelector={`.${CLASS_BOARD_DETAILS_SECTION}--archive-on-del`}
            title={BOARD_SECTION_ARCHIVE_ON_DELETE_BUTTON}
            tooltip={tooltip}
            onClick={onClick}
        />
    );
}
