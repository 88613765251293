import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { REST_TRELLO_IMPORT } from '../../../../constants';
import { TRELLO_CLICK, TRELLO_SUCCESS } from '../../../../effects/googleAnalyticsEvents';
import { DashboardImportRouter } from '../../../../helpers/importBoard';
import {
    SNACKBAR_BOARD_IMPORT_ID,
    SNACKBAR_TRELLO_IMPORT_ERROR_TEXT,
    SNACKBAR_TRELLO_IMPORT_PROGRESS_TEXT, TRELLO_OPEN_FILE_MIME_FILTER
} from '../constants';

export const importTrello = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        new DashboardImportRouter({
            importUrl: REST_TRELLO_IMPORT,
            snackbarImportId: SNACKBAR_BOARD_IMPORT_ID,
            gaStartEvent: TRELLO_CLICK,
            importProgressText: SNACKBAR_TRELLO_IMPORT_PROGRESS_TEXT,
            gaSuccessEvent: TRELLO_SUCCESS,
            importErrorText: SNACKBAR_TRELLO_IMPORT_ERROR_TEXT,
            mimeTypesFilter: TRELLO_OPEN_FILE_MIME_FILTER
        }, dispatch).start();
    };
    return action;
};
