import { ICard } from '../../../card/types';
import {
    IGanttViewBoardDatesFilter,
    IGanttViewBoardState
} from '../../../../../view/react_components/gantt/store/ganttViewBoard/types';
import { EDatesFilterType } from '../../../../../view/react_components/gantt/constants';
import { TStatus } from '../../../../../types/model';
import { TCardId } from '../../../../../types/types';

const isCardMatchDatesFilter = (
    card: ICard,
    { type, from, to }: IGanttViewBoardDatesFilter
): boolean => {
    if (type === EDatesFilterType.ALL) return true;
    if (!from && !to) return true;
    if (!card.startDate && !card.dueDate) return true;

    const toDate = to && new Date(to);
    if (toDate) toDate.setDate(toDate.getDate() + 1);

    const startDate = (card.startDate || card.dueDate) * 1000;
    const dueDate = (card.dueDate || card.startDate) * 1000;
    const matchFrom = !from || dueDate > from;
    const matchTo = !toDate || startDate < toDate.getTime();
    return matchFrom && matchTo;
}

export const ganttFilter = (
    ganttViewBoardState: IGanttViewBoardState,
    ignoreCardIds: TCardId[]
) => (
    card: ICard
): boolean => {
    if (card.status !== TStatus.STATUS_ACTIVE || !card.ganttVisibility) return false;
    if (ignoreCardIds.includes(card.id)) return true;
    return Boolean(
        isCardMatchDatesFilter(card, ganttViewBoardState.datesFilter) &&
        (!ganttViewBoardState.hideEmptyDates || card.startDate || card.dueDate)
    )
}
