import { IApplicationState } from '../../../../../../types/types';
import { filterPanelBoardsSelector } from './filterPanelBoardsSelector';
import { MY_WORK_FILTER_BOARD_ID } from '../../constants';
import * as _ from 'underscore';
import { initialState } from '../filter/reducers/filterReducer';

export const getMyWorkFilterIsInitial = (
    state: IApplicationState
): boolean => {
    const filter = filterPanelBoardsSelector(state, MY_WORK_FILTER_BOARD_ID).defaultFilter;

    const initialFilter = {
        ...initialState,
        id: filter.id,
        searchedKeyWord: filter.searchedKeyWord,
    };

    return _.isEqual(filter, initialFilter);
};
