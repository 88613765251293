import {AT_FILTER_PANEL_ACTION_SET} from '../../../../../aside_panel/filterPanelBoard/store/filterPanelBoards/actions/constants';
import {TTopBarAction} from '../../topBar/actions/types';
import {TTopBarBoardAction} from './types';
import {AT_TOP_BAR_BOARD_ACTION_SET} from './constants';

export const topBarSetAction = (
    boardId: number,
    topBarAction: TTopBarAction
): TTopBarBoardAction => ({
        type: AT_TOP_BAR_BOARD_ACTION_SET,
        boardId,
        topBarAction
    }
);
