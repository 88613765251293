import * as React from 'react';
import { CLASS_CARD_DETAILS_SECTION } from '../../../constants';
import { SettingsSection } from '../../../../../base/components/SettingsSection/SettingsSection';
import { IDependenciesSectionProps } from './types';
import { CARD_PREDECESSOR_ADD, CARD_SECTION_DEPENDENCIES, ECardDependencyType } from '../../constans';
import './_dependency-section.scss';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { DependenciesSearchSelectHOC } from '../../hocs/DependenciesSearchSelectHOC/DependenciesSearchSelectHOC';
import { Dependency } from '../Dependency/Dependency';
import { SEARCH_SELECT_INPUT_SELECTOR } from '../../../../../base/components/SearchSelect/constants';
import { CLASS_SETTINGS_SECTION_EMPTY } from '../../../../../base/components/SettingsSection/constants';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { EAsidePanelProperty } from '../../../../asidePanel/components/AsidePanel/types';
import { AnalyzeRender } from '../../../../../helpers/memoizeHelper';

//@ts-ignore
const analyzeRender = window.Settings.development ? new AnalyzeRender(`DependenciesSection`) : null;

export function DependenciesSection({
    boardId,
    cardId,
    cardName,
    isBasicFeature,
    isDefaultProperty,
    isProFeature,
    predecessors,
    successors,
    onDeleteDependence,
    onDependencyTypeChanged,
    onDependencyCardLink,
    onAddClicked,
    onClose,
    sectionType,
    typesList,
    ...attributes
}: IDependenciesSectionProps) {
    if (analyzeRender) analyzeRender.call(`DependenciesSection ${cardId}`);

    if (!predecessors && !successors && sectionType === ESettingsSectionType.READONLY) return;

    const { addShowedProperty } = React.useContext(AsidePanelContext);

    const isCanEdit = sectionType === ESettingsSectionType.DEFAULT;
    const isPopup = sectionType === ESettingsSectionType.POPUP;
    const className = CLASS_CARD_DETAILS_SECTION + ' ' + CLASS_CARD_DETAILS_SECTION + '--dependencies';
    const classNameEmpty = (!predecessors || !predecessors.length) && (!successors || !successors.length) ? CLASS_SETTINGS_SECTION_EMPTY : '';

    const [isShow, setShow] = React.useState(false);
    const [isOpenedHook, setIsOpened] = React.useState(isPopup);
    const onAddOpened = () => {
        setIsOpened(true);
        onAddClicked();
    };
    const onAddClosed = () => {
        setIsOpened(false);
        if (onClose) onClose();
    }

    React.useEffect(() => {
        if (
            (predecessors && predecessors.length) ||
            (successors && successors.length)
            || isDefaultProperty
        ) {
            setShow(true);
            addShowedProperty(EAsidePanelProperty.CARD_DEPENDENCIES);
        }
    }, [predecessors, successors, isDefaultProperty]);

    if (!isShow && !isPopup) return;

    return (
        <SettingsSection
            addTitle={CARD_PREDECESSOR_ADD}
            addComponent={isOpenedHook
                ? <DependenciesSearchSelectHOC
                    boardId={ boardId }
                    cardId={cardId}
                    isOpened={isOpenedHook}
                    isPopup={isPopup}
                    onAddClosed={onAddClosed}
                />
                : <div/>
            }
            className={`
                ${className}
                ${classNameEmpty}
            `}
            focusSelector={SEARCH_SELECT_INPUT_SELECTOR}
            isAddWide
            isProFeature={isProFeature}
            isBasicFeature={isBasicFeature}
            isOpened={isOpenedHook}
            title={CARD_SECTION_DEPENDENCIES}
            sectionType={sectionType}
            onAddOpened={onAddOpened}
            onAddClosed={onAddClosed}
            {...attributes}
        >
            <>
                {(!predecessors || !predecessors.length) && (!successors || !successors.length)
                    ? <div/> // пустая секция
                    : <div className="dependencies">
                        {!!predecessors && !!predecessors.length &&
                            <div className="dependencies__section dependencies__section--predecessors">
                                <div className="dependencies__body">
                                    <ul className="dependencies__links">
                                        {
                                            predecessors.map((predecessor) => {
                                                return (
                                                    <Dependency
                                                        canEdit={isCanEdit}
                                                        cardId={predecessor.cardId}
                                                        cardName={predecessor.cardName}
                                                        dependencyType={ECardDependencyType.PREDECESSOR}
                                                        id={predecessor.dependencyId}
                                                        key={predecessor.dependencyId}
                                                        link={predecessor.link}
                                                        type={predecessor.dependencyType}
                                                        typesList={typesList}
                                                        onDelete={() => onDeleteDependence(predecessor.dependencyId)}
                                                        onDependencyCardLink={onDependencyCardLink}
                                                        onTypeChanged={onDependencyTypeChanged}
                                                        isValid={predecessor.isValid}
                                                    />
                                                );
                                            })
                                        }
                                    </ul>
                                    <div className="dependencies__pipe"/>
                                </div>
                            </div>
                        }
                        <div className="dependencies__section dependencies__section--card">
                            <div className="dependencies__current-card">{cardName}</div>
                        </div>
                        {!!successors && !!successors.length &&
                            <div className="dependencies__section dependencies__section--successors">
                                <div className="dependencies__body">
                                    <div className="dependencies__pipe"/>
                                    <ul className="dependencies__links">
                                        {
                                            successors.map((successor) => {
                                                return (
                                                    <Dependency
                                                        canEdit={isCanEdit}
                                                        cardId={successor.cardId}
                                                        cardName={successor.cardName}
                                                        dependencyType={ECardDependencyType.SUCCESSOR}
                                                        id={successor.dependencyId}
                                                        key={successor.dependencyId}
                                                        link={successor.link}
                                                        type={successor.dependencyType}
                                                        typesList={typesList}
                                                        onDelete={() => onDeleteDependence(successor.dependencyId)}
                                                        onDependencyCardLink={onDependencyCardLink}
                                                        onTypeChanged={onDependencyTypeChanged}
                                                        isValid={successor.isValid}
                                                    />
                                                );
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        }
                    </div>
                }
            </>
        </SettingsSection>
    );
}
