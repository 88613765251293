import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import React from "react";
import { LazyWrapper } from '../react_components/lazy/lazy_wrapper';

App.Views.RelatedCardsView = App.Views.BaseView.extend({
    className: 'related-cards-panel__reactinbackbone',

    initialize: function(options){
        this.root = createRoot(this.el);
        this.store = options.store;
        this.cardId = options.cardId;
        this.boardId = options.boardId;
        this.parent = options.parent;
    },

    initRelatedCardsStore: function() {
        return import(/* webpackChunkName: "related" */ '../react_components/aside_panel/relatedCards/store/reducers/relatedCardsReducer')
        .then((module) => {
            this.store.reducerManager.add('relatedCards', module.relatedCardsReducer);
            this.store.dispatch({type: 'async-init'}); // fake action to init new reducers
        })
        .catch(e => console.error(e))
    },

    render: function () {
        this.parent.$el.find('.aside-panel__hide').hide();
        const ImportElement = React.lazy(() =>
            this.initRelatedCardsStore()
                .then(() => import(/* webpackChunkName: "related" */ '../react_components/aside_panel/asidePanel/hocs/AsidePanelRelatedHOC/AsidePanelRelatedHOC'))
                .then(module => ({default: module.AsidePanelRelatedHOC}))
                .catch(e => console.error(e))
        );
        this.root.render(
            <LazyWrapper>
                <Provider store={this.store}>
                    <ImportElement
                        boardId={this.boardId}
                        cardId={this.cardId}
                    />
                </Provider>
            </LazyWrapper>
        );
        return this;
    },

    remove: function () {
        this.parent.$el.find('.aside-panel__hide').show();
        this.root.unmount();
        Backbone.View.prototype.remove.call(this);
    }
});

export default App.Views.RelatedCardsView;
