import { IBoardLibThemesProps } from './types';
import * as React from 'react';
import './_BoardLibThemes.scss';
import { BoardLibTheme } from '../BoardLibTheme/BoardLibTheme';
import { THEME_BACKGROUND_TEXT, VIEW_WITHOUT_BACKGROUND_BUTTON, VIEW_WITHOUT_BACKGROUND_TEXT } from './constants';
import { SettingsSection } from '../../../../SettingsSection/SettingsSection';
import { Button } from 'kui';
import {
    CLASS_BOARD_BRANDING,
    CLASS_BOARD_DETAILS_SECTION
} from '../../../../../../aside_panel/boardDetails/constants';

export function BoardLibThemes ({
    activeId,
    isMyWork,
    isViewWithoutBackground,
    sectionType,
    themes,
    onClickDisallow,
    onClickSwitchToKanban,
    onDidMount,
    onThemeClick,
}: IBoardLibThemesProps) {

    React.useEffect(() => {
        onDidMount();
    }, []);

    const className = CLASS_BOARD_DETAILS_SECTION + ' ' + CLASS_BOARD_DETAILS_SECTION + '--theme';
    const classThemes = CLASS_BOARD_BRANDING + '__themes';

    return <SettingsSection
                className={className}
                sectionType={sectionType}
                title={THEME_BACKGROUND_TEXT}
                titleButton={isViewWithoutBackground && !isMyWork
                    ? <p>
                        {VIEW_WITHOUT_BACKGROUND_TEXT}
                        {' '}
                        <Button
                            className={`${classThemes}-button-switch-to-kanban`}
                            variant={'text'}
                            onClick={onClickSwitchToKanban}
                        >
                            {VIEW_WITHOUT_BACKGROUND_BUTTON}
                        </Button>
                    </p>
                    : null
                }
                onClickDisallow={onClickDisallow}
            >
        <div className={classThemes}>
            {themes.map((theme) => (
                <BoardLibTheme
                    key={theme.id}
                    name={theme.libraryCssClass}
                    active={activeId === theme.id}
                    onClick={() => onThemeClick(theme.id)}
                />
            ))}
        </div>
    </SettingsSection>;
};
