import { IApplicationState, TBoardId, TCardId } from '../../../../../../types/types';
import { ICommentMention } from '../../../../../../store/model/card/types/IComment';
import { getBoardMentions } from '../hocs/CommentsInputHOC/selectors/getBoardMentions';
import {
    ASSIGNEE_GROUP_ID,
    TITLE_ASSIGNED_ON_CARD,
    TITLE_ASSIGNED_OTHER
} from '../../AssigneesSection/components/constants';
import { userToMention } from '../helpers/MentionsHelper';
import { getMentionSubstring } from './getMentionSubstring';
import { getCardAssignees } from '../../../../../../store/model/selectors/getCardAssignees';
import { createDeepEqualSelector } from '../../../../helpers/memoizeHelper';

type TgetCardMentionOptions = (
    state: IApplicationState,
    boardId: TBoardId,
    cardId: TCardId,
) => ICommentMention[];

export const getCardMentionOptions = (
    state: IApplicationState,
    boardId: TBoardId,
    cardId: TCardId,
): ICommentMention[] => {
    const boardMentions = getBoardMentions(state, boardId);
    const cardAssigneesFull = getCardAssignees(state, cardId);
    let cardAssignees = cardAssigneesFull;

    if (cardAssigneesFull && cardAssigneesFull.length) {
        const mentionSubstring = getMentionSubstring(state);
        if (mentionSubstring.length > 0) {
            const nameSubstring = mentionSubstring.substring(1).toLowerCase();
            cardAssignees = cardAssigneesFull.filter(item => {
                return item && item.sharedUser && item.sharedUser.fullName && item.sharedUser.fullName.toLowerCase().includes(nameSubstring);
            });
        }
    }

    let cardMentionOptions = boardMentions;

    if (cardAssignees && cardAssignees.length) {
        const cardAssigneeIds = new Set(cardAssignees.map(item => {
            return item.sharedUser && item.sharedUser.permissionId;
        }));
        const otherUsers = boardMentions.filter(item => {
            return !cardAssigneeIds.has(item.permissionId);
        });
        if (otherUsers.length) {
            cardMentionOptions = [
                {
                    name: TITLE_ASSIGNED_ON_CARD,
                    mention: null,
                    permissionId: ASSIGNEE_GROUP_ID,
                    photoUrl: null,
                },
                ...cardAssignees.map((user) => userToMention(user.sharedUser)),
                {
                    name: TITLE_ASSIGNED_OTHER,
                    mention: null,
                    permissionId: ASSIGNEE_GROUP_ID,
                    photoUrl: null,
                },
                ...otherUsers,
            ];
        }
    }

    return cardMentionOptions;
};

export const getCardMentionOptionsCreateSelector = (
): TgetCardMentionOptions => createDeepEqualSelector(
    getCardMentionOptions,
    options => options
);
