import {ActionCreator} from 'redux';
import {Dispatch, ThunkAction} from '../../../../../../../types/actions';
import {IGetState, TBoardId} from '../../../../../../../types/types';
import { openBoardActivity } from '../effects/openBoardActivity';
import { ESegmentBoardMenuLocation, boardActivityClickedSegmentEvent } from '../../../../../../../middlewares/segment/trackEntities/boardMenuEvents';
import { SegmentBoardEvent, segmentTrackAction } from 'app/middlewares/segment';
import { getAuthUser } from '../../../../../../../store/model/authUser/selectors/getAuthUser';
import { showSnoozeBlocker } from '../../../../../base/effects/showSnoozeBlocker';

export const onBoardActivityClicked: ActionCreator<ThunkAction> = (
    boardId: TBoardId
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const { allowActivity } = getAuthUser(getState());
        if (allowActivity) {
            dispatch(openBoardActivity(boardId));
            dispatch(boardActivityClickedSegmentEvent(ESegmentBoardMenuLocation.leftPanel));
            dispatch(segmentTrackAction(SegmentBoardEvent.BOARD_ACTIVITY_TAB_OPENED));
        } else {
            dispatch(showSnoozeBlocker({
                allow: 'allowActivity',
                subAllow: 'allowBoardActivity'
            }));
        }
    };
    return action;
};
