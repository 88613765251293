import * as React from 'react';
import { ICustomPropertiesFormProps } from './types';
import { CLASS_CARD_CUSTOM, CLASS_CARD_CUSTOM_ITEM } from '../constants';
import { Button, ButtonsGroup, Icon, Input, ISelectActiveInheritedProps, Select } from 'kui';
import { CARD_CUSTOM_LABEL, CARD_CUSTOM_NEW, CARD_CUSTOM_TYPE } from '../CustomPropertiesSection/constants';
import './_CustomPropertiesForm.scss';
import { ECustomPropertyType, IBoardCardCustomOption } from '../../../../../../../store/customProperties/types';
import { CARD_CUSTOM_ADD_BUTTON, CARD_CUSTOM_SELECT_DEFAULT_OPTIONS_COUNT } from './constants';
import { ErrorHint } from '../../../../../base/components/ErrorHint/ErrorHint';
import { v4 as uuidv4 } from 'uuid';
import {
    CUSTOM_BUTTON_CANCEL,
    CUSTOM_BUTTON_REMOVE_TOOLTIP,
    CUSTOM_BUTTON_SAVE,
    CUSTOM_ERROR_DUPLICATE,
    CUSTOM_ERROR_EMPTY
} from '../CustomPropertiesElements/constants';
import { ICustomPropertiesElement } from '../CustomPropertiesElements/types';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { getAsidePanelTooltip } from '../../../../asidePanel/helpers/getAsidePanelTooltip';
import { CLASS_LIST_ITEM_CONTAINER } from '../../../../../base/components/ListItem/constants';
import { ListItem } from '../../../../../base/components/ListItem/ListItem';
import { TCustomOptionId } from '../../../../../../../types/types';
import {
    CUSTOM_PROPERTY_TEXT_TYPES,
    CUSTOM_PROPERTY_TO_TEXT_TYPES,
    CUSTOM_PROPERTY_TYPES
} from '../../../../../../../store/customProperties/constants';
import {
    ID_ASIDE_PANEL_PORTAL
} from '../../../../asidePanel/components/AsidePanelActionsButton/constants';

export function CustomPropertiesForm ({
    autofocus,
    element,
    names,
    isCloseOnAdd,
    isReadonly,
    isPopup,
    onChange,
    onChangeType,
    onClose,
    onEnter,
    onOptionAdd,
    onSortOptionsHandler
}: ICustomPropertiesFormProps) {
    const className = CLASS_CARD_CUSTOM + '__form';
    const [uniqueClass] = React.useState(className + '--' + uuidv4());
    const [originalType] = React.useState(element && element.type);
    const classInputContainer = className + '-input';
    const classLabel = className + '-label';
    const classAdd = className + '-add';
    const classType = className + '-type';

    const classSelect = CLASS_CARD_CUSTOM_ITEM + '-select';
    const classOptions = CLASS_CARD_CUSTOM_ITEM + '-options';
    const classOption = CLASS_CARD_CUSTOM_ITEM + '-option';
    const classOptionAdd = CLASS_CARD_CUSTOM_ITEM + '-option-add';
    const classOptionInput = CLASS_CARD_CUSTOM_ITEM + '-option-input';
    const classOptionsButtons = classOptions + '-buttons';

    const types: Record<string, string> = !element
        ? CUSTOM_PROPERTY_TYPES
        : (
            !!CUSTOM_PROPERTY_TO_TEXT_TYPES[originalType]
                ? {
                    ...CUSTOM_PROPERTY_TEXT_TYPES,
                    [originalType]: CUSTOM_PROPERTY_TO_TEXT_TYPES[originalType]
                }
                : null
        );

    let [elementState, setElement] = React.useState<ICustomPropertiesElement>({
        ...element,
        id: element ? element.id : uuidv4(),
        name: element ? element.name : '',
        type: element ? element.type : Object.keys(CUSTOM_PROPERTY_TYPES)[0] as ECustomPropertyType,
    });
    const [active, setActive] = React.useState(element && types
        ? Object.keys(types).indexOf(element.type)
        : 0
    );

    /**
     * удалить все пробелы и в нижний регистр
     */
    const trimLow = (value: string): string => {
        return value.replace(/\s/g, '').toLocaleLowerCase();
    }

    const namesTrim = names ? names.map(name => trimLow(name)) : [];

    const isValid = (): boolean => {
        const nameTrim = trimLow(elementState.name);
        const isEmpty = nameTrim === '';
        const duplicates = namesTrim.filter(name => name === nameTrim);
        setElement({
            ...elementState,
            error: isEmpty
                ? CUSTOM_ERROR_EMPTY
                : duplicates.length ? CUSTOM_ERROR_DUPLICATE : null,
        });
        return !isEmpty && !duplicates.length;
    }

    const getDefaultNewOptions = (): IBoardCardCustomOption[] => {
        const options: IBoardCardCustomOption[] = [];
        for (let i = 1; i <= CARD_CUSTOM_SELECT_DEFAULT_OPTIONS_COUNT; i++) {
            options.push({
                id: uuidv4(),
            })
        }
        return options;
    }

    const onChangeTypeHandler = (
        event: ISelectActiveInheritedProps
    ) => {
        const type = event.item.value as ECustomPropertyType;
        const newElement: ICustomPropertiesElement = {
            ...elementState,
            type
        };
        if (type === ECustomPropertyType.SELECT) {  // для select добавляем 3 дефолтных опции
            if (!newElement.options || !newElement.options.length) {
                newElement.options = getDefaultNewOptions();
            }
        } else {
            newElement.options = [];
        }
        setElement(newElement);
        setActive(event.item.index);
        if (element) {
            onChange(elementState.name, type, newElement.options);
        }
        onChangeType(event.item.value as ECustomPropertyType);
    }

    const onChangeName = (
        event: React.FormEvent
    ) => {
        const { value } = event.target as HTMLInputElement;
        elementState.name = value;
        setElement({...elementState});
        if (element) {
            onChange(value, elementState.type, element.options);
        }
        if (elementState.error) isValid();
    }

    const onClickAdd = () => {
        if (
            elementState.name &&
            elementState.name.trim()
        ) {
            if (!isValid()) return;

            const options = elementState.options && elementState.options.filter(option => !!option.value);
            onChange(elementState.name, elementState.type, options);
            setElement({
                ...elementState,
                name: '',
                options: elementState.type === ECustomPropertyType.SELECT ? getDefaultNewOptions() : null
            });
            if (isCloseOnAdd && onClose) onClose();
            setTimeout(() => {
                const container = document.querySelector(`.${CLASS_CARD_CUSTOM}__items:not(.${CLASS_CARD_CUSTOM}__items--card)`) as HTMLElement;
                if (container) {
                    const item = container.querySelector(`.${CLASS_CARD_CUSTOM_ITEM} .kui-input__item`) as HTMLElement;
                    if (item && item.focus) item.focus(); // focus if input
                    if (item && item.click) item.click(); // open if select
                }
            }, 300);
        }
    };

    const onKeyDown = (
        e: React.KeyboardEvent<HTMLInputElement>
    ) => {
        if (e) {
            if (e.key === 'Enter' &&
                !e.shiftKey
            ) {
                e.preventDefault();
                if (!element) {
                    onClickAdd();
                }
                if (onEnter) onEnter();
            }
            if (e.key === 'Escape') {
                if(onClose)
                    onClose();
                e.stopPropagation();//to avoid aside-panel closure on esc if input is in focus
            }
        }
    };

    const isOneOptionFilled = (): boolean => {
        if (elementState.options && elementState.options.length) {
            return !!elementState.options.find(option => !!option.value);
        }
        return true;
    }

    const showAddBtn = !!elementState.name && elementState.name.trim() && isOneOptionFilled();

    React.useEffect(() => {
        if (element) {
            setElement({
                ...elementState,
                error: element.error,
                options: element.options
            });
        }
    }, [element]);

    function onDragEnd(result: DropResult) {
        if (
            !result.destination ||
            result.destination.index === result.source.index
        ) {
            // do nothing
        } else {
            onSortOptionsHandler(result.destination.droppableId, result.source.index, result.destination.index);
        }
    }

    const storeOptions = (
        options: IBoardCardCustomOption[]
    ) => {
        setElement({
            ...elementState,
            options
        })
        if (element) {
            onChange(elementState.name, elementState.type, options);
        }
    }

    const onOptionAddHandler = () => {
        const options = [
            ...elementState.options,
            {
                id: uuidv4(),
            }
        ]
        storeOptions(options);
        if (element) {
            onOptionAdd(elementState.id, elementState);
        }
    }

    const onOptionDeleteHandler = (optionId: TCustomOptionId) => {
        const options = elementState.options.filter(option => option.id !== optionId);
        storeOptions(options);
    }

    const onChangeOptionHandler = (event: React.FormEvent, optionId: TCustomOptionId) => {
        const target = event.target as HTMLInputElement;
        let { value = null } = target;
        const options = elementState.options.map((option, index) => {
            if (option.id === optionId) {
                return {
                    ...option,
                    value
                }
            }
            return option;
        })
        storeOptions(options);
    }

    return (
        <div className={`
            ${className}
            ${uniqueClass}
        `}>
            <div className={className + '-row'}>
                <div className={classInputContainer}>
                    <Input
                        className={classLabel}
                        autoFocus={autofocus}
                        autosize={false}
                        label={CARD_CUSTOM_LABEL}
                        placeholder={!element ? CARD_CUSTOM_NEW : null}
                        readOnly={isReadonly}
                        state={elementState.error ? 'error' : null}
                        value={elementState.name}
                        onChange={onChangeName}
                        onKeyDown={onKeyDown}
                    />
                    {!element && elementState.type !== ECustomPropertyType.SELECT &&
                    <Button
                        onClick={onClickAdd}
                        className={classAdd}
                        disabled={!showAddBtn}
                    >
                        {CARD_CUSTOM_ADD_BUTTON}
                    </Button>
                    }
                </div>
                {types &&
                    <Select
                        active={active}
                        className={classType}
                        editable={false}
                        isFitWindow={true}
                        label={CARD_CUSTOM_TYPE}
                        options={types}
                        readOnly={isReadonly}
                        variant={'arrow'}
                        onChange={onChangeTypeHandler}
                        dropdownClassName={classType + '-dropdown'}
                        portal={isPopup}
                        portalId={ID_ASIDE_PANEL_PORTAL}
                    />
                }
                {elementState.error &&
                <ErrorHint
                    arrow={'left'}
                    direction={'right'}
                    portalSelector={`.${uniqueClass} .${classLabel}`}
                    selector={`.${uniqueClass} .${classLabel} .kui-input__item`}
                    value={elementState.error}
                />
                }
            </div>
            {elementState.type === ECustomPropertyType.SELECT &&
            <div className={classSelect}>
                {!!elementState.options && !!elementState.options.length &&
                <DragDropContext
                    onDragEnd={onDragEnd}
                >
                    <Droppable
                        droppableId={elementState.id}
                    >
                        {(provided, snapshot) => (
                            <div
                                className={classOptions}
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {elementState.options.map((option, index) => {
                                    const actions = isReadonly || elementState.options.length <= 1 ? null
                                        : <Button
                                            variant={'icon'}
                                            tooltip={ getAsidePanelTooltip({
                                                direction: 'up-left',
                                                value: CUSTOM_BUTTON_REMOVE_TOOLTIP,
                                            })
                                            }
                                            onClick={() => onOptionDeleteHandler(option.id)}
                                        >
                                            <Icon size={24} xlink={'delete'} />
                                        </Button>;

                                    return (
                                        <Draggable
                                            draggableId={option.id}
                                            index={index}
                                            isDragDisabled={isReadonly || isPopup || !element}
                                            key={option.id}
                                        >
                                            {(provided, snapshot) => {
                                                return (
                                                    <div
                                                        className={CLASS_LIST_ITEM_CONTAINER}
                                                        key={option.id}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        ref={provided.innerRef}
                                                    >
                                                        <ListItem
                                                            actions={actions}
                                                            className={classOption}
                                                            isDraggable={!isReadonly && !isPopup && !!element}
                                                            text={<Input
                                                                className={classOptionInput}
                                                                autosize={false}
                                                                placeholder={'Option ' + (index + 1)}
                                                                readOnly={isReadonly}
                                                                value={option.value}
                                                                onChange={(event) => onChangeOptionHandler(event, option.id)}
                                                            />}
                                                        />
                                                    </div>
                                                );
                                            }}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                }
                <Button
                    className={classOptionAdd}
                    variant={'add'}
                    onClick={onOptionAddHandler}
                >
                    Add option
                </Button>
                {!element &&
                <div className={classOptionsButtons}>
                    <ButtonsGroup>
                        <Button
                            variant={'secondary'}
                            onClick={onClose}
                        >
                            {CUSTOM_BUTTON_CANCEL}
                        </Button>
                        <Button
                            disabled={!showAddBtn}
                            variant={'primary'}
                            onClick={onClickAdd}
                        >
                            {CUSTOM_BUTTON_SAVE}
                        </Button>
                    </ButtonsGroup>
                </div>
                }
            </div>
            }
        </div>
    );
};
