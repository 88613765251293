import selectAction from './../store/actions/selectAction'
import setCalendars from './../effects/setCalendars';
import { sendSelectedActionSegmentEvent } from '../effects/sendSelectedActionSegmentEvent';

const onActionSelected = (selectedAction) => {
    return (dispatch) => {
        dispatch(sendSelectedActionSegmentEvent(selectedAction));
        dispatch(selectAction(selectedAction.id));
        dispatch(setCalendars());
    };
};

export default onActionSelected;
