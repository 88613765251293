import {ActionCreator} from 'redux';
import {Dispatch, ThunkAction} from '../../../../../../../types/actions';
import {IGetState} from '../../../../../../../types/types';
import {isOpenNavigationPanelSelector} from '../../../store/selectors/isOpenNavigationPanelSelector';
import {isOpenSetAction} from '../../../store/navigationPanel/actions/isOpenSetAction';

export const onAnchorMouseOut: ActionCreator<ThunkAction> = (
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const isOpen = isOpenNavigationPanelSelector(state);
        if(isOpen){
            dispatch(isOpenSetAction(false));
        }
    };
    return action;
};
