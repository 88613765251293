import { TBoardId } from '../../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { toggleArchiveCardsOnDelete } from '../effects/toggleArchiveCardsOnDelete';

export const onChange = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(toggleArchiveCardsOnDelete(boardId));
    };
    return action;
};
