'use strict';

import store, { getAppState } from '../store/configureStore';
import { EAuthUserAccountType } from '../types/rest/IRestAuthUser';
import { isUserAuthorized } from '../store/model/authUser/selectors/isUserAuthorized';
import { getAuthUser } from '../store/model/authUser/selectors/getAuthUser';
import {
    getSubscriptions
} from "../view/react_components/subscriptionAdmin/store/subscriptions/selectors/getSubscriptions";
import { PRODUCTS_BY_PLAN } from "../view/react_components/dialogs/price_page/constants";
import { EProductPlan } from "../view/react_components/dialogs/price_page/types";
import { getIsTrialOrFreemiumUser } from "../store/model/authUser/selectors/getIsTrialOrFreemiumUser";


export const ONBOARD_MESSAGE_TYPE = 'onboard_form_message_type';
export const ONBOARD_HELP_ASKED = 'onboard_help_asked';

export const Intercom = {

    userLogin: function () {
        if (window.Intercom) {
            window.intercomSettings = this.getIntercomSettings();
            window.Intercom("boot", window.intercomSettings);
        }
    },

    userLogout: function () {
        if (window.Intercom) {
            window.Intercom('shutdown');
        }
    },

    init: function() {
        window.intercomSettings = this.getIntercomSettings();
        (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + App.settings.intercomId;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};l();}})();
        if (!window.intercomSettings.hide_default_launcher) {
            setInterval(this.checkIntercomOnPage, 5000);
        }
        setTimeout(() => {
            this.fixIntercomPerformance();
        }, 3000);
    },

    sendProperties: function(properties){
        if (window.Intercom) {
            window.Intercom("update", properties);
        }
    },

    getIntercomSettings: function() {
        let settings = {
            app_id: App.settings.intercomId
        };
        if (isUserAuthorized(getAppState())) {
            const authUser = getAuthUser(getAppState());
            settings = {
                ...settings,
                name: authUser.fullName, // Full name
                email: authUser.email, // Email address
                user_id: authUser.id,
                created_at: authUser.createDateTimeStamp, // Signup date as a Unix timestamp
            }
            if (!this.isChatEnabled()) {
                settings.hide_default_launcher = true;
            }
        }
        return settings;
    },

    isChatEnabled: function() {
        const state = store.getState();
        // const { email, accountType } = getAuthUser(getAppState());
        // const isPwcOrNihr = /@(pwc.com|pwc.ru|nihr.ac.uk)$/.test(email);
        // return isUserAuthorized(state) && !isPwcOrNihr && accountType !== EAuthUserAccountType.EDU_ACCOUNT_TYPE;
        return isUserAuthorized(state);
    },

    checkIntercomOnPage: function() {
        if (!getIsTrialOrFreemiumUser(store.getState())) return; // KNB-4025 интерком только для триальщиков и фримиум
        const body = document.querySelector('body');
        const className = 'page--with-intercom';
        if (
            document.querySelector('.intercom-lightweight-app-launcher') ||
            document.querySelector('.intercom-namespace .intercom-launcher-frame')
        ) {
            body.classList.add(className);
        } else {
            body.classList.remove(className);
        }
    },

    /**
     * интерком постоянно цепляет стили из замыкания при кликах,
     * мы его разрываем и не даем замедлять приложение (просто создав новую копию стилей и удалив старую)
     */
    fixIntercomPerformance: function () {
        const intercomStyleId = 'intercom-lightweight-app-style';
        const observer = new MutationObserver(function () {
            if (document.getElementById(intercomStyleId)) {
                const intercomStyle = document.getElementById(intercomStyleId);
                const intercomStyleCopy = intercomStyle.cloneNode(true);
                intercomStyle.after(intercomStyleCopy);
                intercomStyle.remove();

                observer.disconnect();
            }
        });
        observer.observe(document.body, {attributes: false, childList: true, characterData: false, subtree: true});
    },

};

App.Helpers.Intercom = Intercom;
