import { TCondition } from '../types';
import { IApplicationState } from '../../../../types/types';
import { EHintKey, getHintConfig } from '../constants';
import { getEditCardId } from '../../../../store/router/selectors/getEditCardId';
import { getBoardMetaAutoStartDate } from '../../../../store/model/selectors/getBoardMetaAutoStartDate';
import { getActiveBoardId } from '../../../../store/model/selectors/getActiveBoardId';
import { CARD_AUTO_START_DATE_SHOW_COUNT } from '../../aside_panel/cardDetails/DatesSection/constants';
import { getBoardMetaStartDateSetCount } from '../../../../store/model/board/selectors/getBoardUserMetaStartDateSetCount';
import { getAsidePanelIsMobile } from '../../aside_panel/asidePanel/store/selectors/getAsidePanelIsMobile';

export const autoStartDateCondition: TCondition = (
    state: IApplicationState
) => {
    const hint = getHintConfig(EHintKey.AUTO_START_DATE);
    if (!hint) return false;

    if (!getEditCardId(state)) return false; // хинт в cardDetails

    if (getAsidePanelIsMobile(state)) return false; // на мобильном хинт плохо видно

    const boardId = getActiveBoardId(state);
    if (getBoardMetaAutoStartDate(state, boardId)) return false;

    const startDateSetCount = getBoardMetaStartDateSetCount(state, boardId);
    return startDateSetCount >= CARD_AUTO_START_DATE_SHOW_COUNT;
};
