import { IApplicationState, TCardId } from '../../../../../types/types';
import { IDependency } from '../../dependency/types';
import { getCardDependencies } from './getCardDependencies';

export const getCardDependenciesCount = (
    state: IApplicationState,
    cardId: TCardId
): number => {
    const filter = (dependency: IDependency): boolean => {
        return (
            dependency.predecessorId === cardId ||
            dependency.successorId === cardId
        );
    };
    return getCardDependencies(state, filter).length;
};
