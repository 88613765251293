import { IGetState, TBoardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { exportAutomaticToggle } from '../effects/exportAutomaticToggle';
import { segmentTrackAction, SegmentUserEvent } from '../../../../../../../../middlewares/segment';
import { root } from '../../../../../../../../store/constants';
import { authManagerInstance } from 'app/helper/authorisation/google/AuthManager';

export const onExportAutomaticToggle = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const App = root.App;
        const onShow = () => {
            dispatch(segmentTrackAction(SegmentUserEvent.PERMISSION_SHEETS_SCOPE_SHOWN));
        }
        const onRequest = () => {
            dispatch(segmentTrackAction(SegmentUserEvent.PERMISSION_SHEETS_SCOPE_REQUESTED));
        }
        const onCancel = () => {
            dispatch(segmentTrackAction(SegmentUserEvent.PERMISSION_SHEETS_SCOPE_CANCELED));
        }
        const onApprove = () => {
            dispatch(segmentTrackAction(SegmentUserEvent.PERMISSION_SHEETS_SCOPE_GRANTED));
            dispatch(exportAutomaticToggle(boardId));
        };
        authManagerInstance.checkAndRequestGoogleSheet(
            onApprove,
            onShow,
            onCancel,
            onRequest);
    };
    return action;
};
