import { getMessages } from '../../../../../../../../store/constants';
import { IPushNotificationsCheckbox, IPushNotificationsRadio } from './types';
import {
    EPushNotificationSettingsScope, EPushNotificationSubscribeCases,
    EPushNotificationSettingsTypes
} from '../../../../../../../../types/rest/IRestPushNotificationSettings';
import { BOARD_NOTIFICATION_SCOPE_ASSIGNED } from '../constants';

export const BOARD_PUSH_NOTIFICATION_HELP = getMessages().getText('board_settings.push_notification.help');
export const BOARD_PUSH_NOTIFICATION_HELP_URL = getMessages().getText('board_settings.push_notification.help_url');
export const BOARD_PUSH_NOTIFICATION_IMPORTANT = getMessages().getText('board_settings.push_notification.important');
export const BOARD_PUSH_NOTIFICATION_WHEN = getMessages().getText('board_settings.push_notification.when');

export const BOARD_NOTIFICATION_SCOPE_ASSIGN_TO_ME = getMessages().getText('notification_settings__label_im_assigned');
export const BOARD_NOTIFICATION_SCOPE_MENTION = getMessages().getText('notification_settings__label_im_mentioned');

export const BOARD_PUSH_NOTIFICATION_SEND_UPDATES = getMessages().getText('board_settings.push_notification.send_updates');
export const BOARD_PUSH_NOTIFICATION_SUBCRIBE = getMessages().getText('board_settings.push_notification.subscribe');

export const BOARD_PUSH_NOTIFICATION_SCOPE_SEND_ALL = getMessages().getText('board_settings.push_notification.scope_send_all');
export const BOARD_PUSH_NOTIFICATION_SCOPE_SEND_SUBSCRIBED = getMessages().getText('board_settings.push_notification.scope_send_subscribed');

const BOARD_PUSH_NOTIFICATION_SUBSCRIBE_ON_CREATED = getMessages().getText('board_settings.push_notification.subscribe_on_create');
const BOARD_PUSH_NOTIFICATION_SUBSCRIBE_ON_COMMET_MENT = getMessages().getText('board_settings.push_notification.subscribe_on_comment_ment');
const BOARD_PUSH_NOTIFICATION_SUBSCRIBE_ON_ASSIGN = getMessages().getText('board_settings.push_notification.subscribe_on_assign');
const BOARD_PUSH_NOTIFICATION_SUBSCRIBE_ON_COMMENT_ADD = getMessages().getText('board_settings.push_notification.subscribe_on_comment_add');

const BOARD_PUSH_NOTIFICATION_IMPORTANT_CARD_ASSIGN = getMessages().getText('board_settings.push_notification.important.card_assign');
const BOARD_PUSH_NOTIFICATION_IMPORTANT_COMMENT_MENT = getMessages().getText('board_settings.push_notification.important.comment_ment');
const BOARD_PUSH_NOTIFICATION_IMPORTANT_CARD_CREATE = getMessages().getText('board_settings.push_notification.important.card_create');
const BOARD_PUSH_NOTIFICATION_IMPORTANT_LIME_MY_COMMENT = getMessages().getText('board_settings.push_notification.important.like_my_comment');

const BOARD_PUSH_NOTIFICATION_TYPE_NAME_CHANGED = getMessages().getText('board_settings.push_notification.type.nameChanged');
const BOARD_PUSH_NOTIFICATION_TYPE_DESC_CHANGED = getMessages().getText('board_settings.push_notification.type.descChanged');
const BOARD_PUSH_NOTIFICATION_TYPE_USER_ASSIGNED = getMessages().getText('board_settings.push_notification.type.userAssigned');
const BOARD_PUSH_NOTIFICATION_TYPE_PRIORITY_CHANGED = getMessages().getText('board_settings.push_notification.type.priorityChanged');
const BOARD_PUSH_NOTIFICATION_TYPE_COMMENT_ADDED = getMessages().getText('board_settings.push_notification.type.commentAdded');
const BOARD_PUSH_NOTIFICATION_TYPE_ATTACH_ADDED = getMessages().getText('board_settings.push_notification.type.attachAdded');
const BOARD_PUSH_NOTIFICATION_TYPE_DELETED = getMessages().getText('board_settings.push_notification.type.deleted');
const BOARD_PUSH_NOTIFICATION_TYPE_ARCHIVED = getMessages().getText('board_settings.push_notification.type.archived');
const BOARD_PUSH_NOTIFICATION_TYPE_LIST_CHANGE = getMessages().getText('board_settings.push_notification.type.listChange');
const BOARD_PUSH_NOTIFICATION_TYPE_OVERDUE = getMessages().getText('board_settings.push_notification.type.overdue');

export const BOARD_PUSH_NOTIFICATION_SUBSCRIBE_ON_CHECKBOXES: IPushNotificationsCheckbox[] = [
    {title: BOARD_PUSH_NOTIFICATION_SUBSCRIBE_ON_CREATED, value: EPushNotificationSubscribeCases.CARD_CREATE},
    {title: BOARD_PUSH_NOTIFICATION_SUBSCRIBE_ON_COMMET_MENT, value: EPushNotificationSubscribeCases.COMMENT_MENTION},
    {title: BOARD_PUSH_NOTIFICATION_SUBSCRIBE_ON_ASSIGN, value: EPushNotificationSubscribeCases.ASSIGN},
    {title: BOARD_PUSH_NOTIFICATION_SUBSCRIBE_ON_COMMENT_ADD, value: EPushNotificationSubscribeCases.COMMENT_ADD},
];

export const BOARD_PUSH_NOTIFICATION_IMPORTANT_CHECKBOXES: IPushNotificationsCheckbox[] = [
    {title: BOARD_PUSH_NOTIFICATION_IMPORTANT_CARD_ASSIGN, value: EPushNotificationSettingsScope.EVENT_ASSIGN_TO_ME},
    {title: BOARD_PUSH_NOTIFICATION_IMPORTANT_COMMENT_MENT, value: EPushNotificationSettingsScope.MENTION},
    {title: BOARD_PUSH_NOTIFICATION_IMPORTANT_CARD_CREATE, value: EPushNotificationSettingsScope.CARD_CREATE},
    {title: BOARD_PUSH_NOTIFICATION_IMPORTANT_LIME_MY_COMMENT, value: EPushNotificationSettingsScope.LIKE_MY_COMMENT}
];

export const BOARD_PUSH_NOTIFICATION_TYPE_CHECKBOXES: IPushNotificationsCheckbox[] = [
    {title: BOARD_PUSH_NOTIFICATION_TYPE_NAME_CHANGED, value: EPushNotificationSettingsTypes.NAME_CHANGED},
    {title: BOARD_PUSH_NOTIFICATION_TYPE_DESC_CHANGED, value: EPushNotificationSettingsTypes.DESC_CHANGED},
    {title: BOARD_PUSH_NOTIFICATION_TYPE_USER_ASSIGNED, value: EPushNotificationSettingsTypes.USER_ASSIGNED},
    {title: BOARD_PUSH_NOTIFICATION_TYPE_PRIORITY_CHANGED, value: EPushNotificationSettingsTypes.PRIORITY_CHANGED},
    {title: BOARD_PUSH_NOTIFICATION_TYPE_COMMENT_ADDED, value: EPushNotificationSettingsTypes.COMMENT_ADDED},
    {title: BOARD_PUSH_NOTIFICATION_TYPE_ATTACH_ADDED, value: EPushNotificationSettingsTypes.ATTACH_ADDED},
    {title: BOARD_PUSH_NOTIFICATION_TYPE_DELETED, value: EPushNotificationSettingsTypes.DELETED},
    {title: BOARD_PUSH_NOTIFICATION_TYPE_ARCHIVED, value: EPushNotificationSettingsTypes.ARCHIVED},
    {title: BOARD_PUSH_NOTIFICATION_TYPE_LIST_CHANGE, value: EPushNotificationSettingsTypes.LIST_CHANGE},
    {title: BOARD_PUSH_NOTIFICATION_TYPE_OVERDUE, value: EPushNotificationSettingsTypes.OVERDUE}
];

export const BOARD_PUSH_NOTIFICATION_TYPE_RADIO: IPushNotificationsRadio[] = [
    {title: BOARD_PUSH_NOTIFICATION_SCOPE_SEND_ALL, value: EPushNotificationSettingsScope.ALL},
    {title: BOARD_NOTIFICATION_SCOPE_ASSIGNED, value: EPushNotificationSettingsScope.ASSIGN_TO_ME},
    {title: BOARD_PUSH_NOTIFICATION_SCOPE_SEND_SUBSCRIBED, value: EPushNotificationSettingsScope.WATCH}
]
