import { CardTagsAdd } from '../CardTagsAdd/CardTagsAdd';
import * as React from 'react';
import { CARD_TAG_CREATE } from './constants';
import { ICardTagsSearchSelectProps } from './types';
import { CARD_TAG_ADD } from '../CardTagsSection/constants';
import { SearchSelect } from '../../../../../base/components/SearchSelect/SearchSelect';
import { CLASS_ACTION_MODAL } from '../../../../../base/components/ActionModal/constants';
import { AnalyzeRender } from '../../../../../helpers/memoizeHelper';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';

//@ts-ignore
const analyzeRender = window.Settings.development ? new AnalyzeRender(`CardTagsSearchSelect`) : null;

export function CardTagsSearchSelect ({
    availableTags,
    isOpened,
    isPopup,
    onAddClosed,
    onSelect,
    onCreate,
}: ICardTagsSearchSelectProps) {
    const { cardId } = React.useContext(AsidePanelContext);

    if (analyzeRender) analyzeRender.call(`CardTagsSearchSelect ${cardId}`);

    return (
        <SearchSelect
            notBlurClasses={[CLASS_ACTION_MODAL]}
            create={ CARD_TAG_CREATE }
            isCloseOnClick={false}
            isFitWindow={ isPopup }
            isDropdownUplifted={!isPopup}
            isEnterEnabled={ true }
            multiple={ true }
            opened={ isOpened }
            options={ availableTags }
            option={ CardTagsAdd }
            placeholderEmpty={ CARD_TAG_ADD }
            onBlur={ onAddClosed }
            onClosed={ onAddClosed }
            onSelect={ onSelect }
            onCreate={ onCreate }
            createHintIcon = { true }
            createHintList = { false }
        />
    );
}
