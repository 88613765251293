import { ICommentUpdateAction } from './types';
import { AT_COMMENT_UPDATE } from './constants';
import { ICardCommentsState } from '../types';

export const commentUpdate = (
    state: Partial<ICardCommentsState>,
): ICommentUpdateAction => ({
    type: AT_COMMENT_UPDATE,
    state
});
