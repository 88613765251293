import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { IGetState } from '../../../../../types/types';
import { authUserPatch } from '../../api/helper/authUserPatch';
import { IRecentBoard } from '../../../../../types/rest/IUserMeta';
import { IAuthUser } from '../../../../../store/model/authUser/types';

export const authUserRecentBoardsPatch: ActionCreator<ThunkAction> = (
    userId: string,
    recentBoards: IRecentBoard[]
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const userPatch: IAuthUser = {
            id: userId,
            meta: {
                //@ts-ignore Да, мы знаем что тут несоответствие типов, но на бек надо слать айди строками а не намбер
                recentBoards: reformatRecentBoards(recentBoards)
            }
        };
        return dispatch(authUserPatch(userPatch, undefined, true));
    };
    return action;
};

const reformatRecentBoards = (
    recentBoards: IRecentBoard[]
) => {
    return recentBoards.map(board => {
        return {
            ...board,
            id: board.id.toString()
        }
    })
}
