import { TCardId, IApplicationState, TChecklistId, EMPTY_ARRAY } from '../../../types/types';
import { getCardPinnedSections } from './getCardPinnedSections';
import { getCard } from './getCard';

export const getCardPinnedChecklists = (
    state: IApplicationState,
    cardId: TCardId,
): TChecklistId[] => {
    const cardPinnedSections = getCardPinnedSections(getCard(state, cardId));

    return cardPinnedSections.checklists || EMPTY_ARRAY;
};
