import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { ISubcardsSearchSelectHOCOwnProps } from './types';
import { onAdd } from './events/onAdd';
import { onCreate } from './events/onCreate';
import { getAvailableSubCardsCreateSelector } from './selectors/getAvailableSubcards';
import { ISubcardsSearchSelectEvents, ISubcardsSearchSelectFields } from '../../components/SubcardsSearchSelect/types';
import { SubcardsSearchSelect } from '../../components/SubcardsSearchSelect/SubcardsSearchSelect';

const mapStateToProps = () => {
    const getAvailableSubCards = getAvailableSubCardsCreateSelector();

    const _mapStateToProps = (
        state: IApplicationState,
        ownProps: ISubcardsSearchSelectHOCOwnProps
    ): ISubcardsSearchSelectFields => {
        const {cardId, sectionType} = ownProps;
        const availableElements = getAvailableSubCards(state, cardId);

        return {
            availableElements,
            sectionType
        };
    };
    return _mapStateToProps;
};

const mapDispatchToProps = (
    dispatch: any,
    ownProps: ISubcardsSearchSelectHOCOwnProps
): ISubcardsSearchSelectEvents => {
    const {cardId, onAddClosed} = ownProps;
    return {
        onAdd: (subcardId) => dispatch(onAdd(cardId, subcardId)),
        onCreate: (name, source) => dispatch(onCreate(cardId, name, source)),
        onAddClosed
    };
};

export const SubcardsSearchSelectHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(SubcardsSearchSelect);

SubcardsSearchSelectHOC.displayName = 'SubcardsSearchSelectHOC';
