'use strict';
import { historyPush } from './../react_components/base/helpers/historyHelper';
import {
    CALENDAR_SCOPE, DRIVE_FILE_SCOPE,
    DRIVE_SCOPE,
    GOOGLE_SHEET_SCOPE,
    GSUITE_ADMIN_GROUP
} from "app/helper/authorisation/google/Scopes";

App.Views.PermissionsDialogRenderer = Backbone.View.extend({
    el: '.app-permissions-dialog',

    initialize: function(options) {
        this.currentView = null;
        this.mainView = options.mainView;
    },

    render: function() {
        return this;
    },

    show: function(options) {
        if (options.type) {
            var view = this.getViewType(options);
            this.clear();
            if (view) {
                this.currentView = view;
                this.showCurrentView();
                historyPush({
                    state: { page: 'permissions' },
                    onpopstate: () => this.clear()
                });
            }
        }
    },

    getViewType: function(options) {
        switch (options.type) {
            case CALENDAR_SCOPE:
                return new App.Views.PermissionsCalendar(options);

            case DRIVE_SCOPE:
                return new App.Views.PermissionsDrive(options);
            case DRIVE_FILE_SCOPE:
                return new App.Views.PermissionsDriveFile(options);
            case GOOGLE_SHEET_SCOPE:
                return new App.Views.PermissionsSheet(options);

            case GSUITE_ADMIN_GROUP:
                return new App.Views.PermissionsGsuite(options);
        }
    },

    showCurrentView: function() {
        this.listenTo(this.currentView, 'hide', this.clear);
        this.$el.html( this.currentView.render().el);
        this.mainView.$el.addClass('page--blur');
        if (this.currentView.afterDOMRendering) {
            this.currentView.afterDOMRendering();
        }
        App.controller.htmlScrollDisabledToggle(true);
    },

    clear: function() {
        if (this.currentView) {
            this.stopListening(this.currentView, 'hide', this.clear);
            this.currentView.remove();
            this.currentView = null;
            App.controller.htmlScrollDisabledToggle();
        }
        this.mainView.$el.removeClass('page--blur');
    },

    remove: function() {
        App.vent.off(null, null, this);
        return Backbone.View.prototype.remove.call(this);
    }
});
