/**
 *  default prefix "FILTER_PANEL_FILTER_MATCH_"
 */

export const AT_CARD_IDS_SET = 'FILTER_PANEL_FILTER_MATCH_CARD_IDS_SET';
export const AT_CARD_ID_ADD = 'FILTER_PANEL_FILTER_MATCH_CARD_ID_ADD';
export const AT_CARD_ID_REMOVE = 'FILTER_PANEL_FILTER_MATCH_CARD_ID_REMOVE';
export const AT_LISTS_SET = 'FILTER_PANEL_FILTER_MATCH_LISTS_SET';
export const AT_IGNORE_CARD_ID_ADD = 'FILTER_PANEL_FILTER_MATCH_IGNORE_CARD_ID_ADD';
export const AT_IGNORE_CARD_IDS_CLEAR = 'FILTER_PANEL_FILTER_MATCH_IGNORE_CARD_IDS_CLEAR';
