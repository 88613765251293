import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../types/types';
import { ISnackbarHOCStateProps, ISnackbarHOCDispatchProps, ISnackbarHOCOwnProps } from './types';
import { snackbarSelector } from '../../selectors/snackbarSelector';
import { getSnackbarInheretedPropsByModel } from './behaviours/getSnackbarInheretedPropsByModel';
import { getAppState } from '../../../../../store/configureStore';
import { getSnackbarInheretedDispatchPropsByModel } from './behaviours/getSnackbarInheretedDispatchPropsByModel';
import { Snackbar } from '../../components/Snackbar/Snackbar';

const mapStateToProps = (
    state: IApplicationState,
    ownProps: ISnackbarHOCOwnProps
): ISnackbarHOCStateProps => {
    const snackbarStateProps: ISnackbarHOCStateProps =
    getSnackbarInheretedPropsByModel(
        snackbarSelector(state, ownProps.snackbarId)
    );
    return snackbarStateProps;
};

const mapDispatchToProps = (
    dispatch: any,
    ownProps: ISnackbarHOCOwnProps
): ISnackbarHOCDispatchProps => {
    const snackbarDispatchProps: ISnackbarHOCDispatchProps =
    dispatch(getSnackbarInheretedDispatchPropsByModel(
        snackbarSelector(getAppState(), ownProps.snackbarId)
    ));
    return snackbarDispatchProps;
};

export const SnackbarHOC = connect(mapStateToProps, mapDispatchToProps)(Snackbar);
SnackbarHOC.displayName = 'SnackbarHOC';
