import { IActivityScrollListIdSetAction } from './types';
import { AT_ACTIVITY_SCROLL_LIST_ID } from './constants';
import { TListId } from '../../../../../../types/types';

export const activityScrollListIdSet = (
    listId: TListId
): IActivityScrollListIdSetAction => {
    return {
        type: AT_ACTIVITY_SCROLL_LIST_ID,
        listId
    }
};
