import { IChecklist } from '../checklist/types';
import { getMessages } from '../../../constants';

export const CHECKLIST_DEFAULT_ID = 0;
export const CHECKLIST_DEFAULT_NAME = getMessages().getText('card_form_checklist_default_name');

export const CHECKLIST_DEFAULT: Readonly<IChecklist> = Object.freeze({
    id: CHECKLIST_DEFAULT_ID,
    name: CHECKLIST_DEFAULT_NAME,
});

export const CHECKLISTS_DEFAULT: Readonly<Record<number, Readonly<IChecklist>>> = Object.freeze({
    [CHECKLIST_DEFAULT_ID]: CHECKLIST_DEFAULT
});
