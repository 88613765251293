import { REST_BOARD } from './../../../../rest/constants';
import { getMessages } from '../../../../store/constants';

export const SCREEN_TITLE = getMessages().getText('open_boards.title');
export const BOARD_DEFAULT_NAME = getMessages().getText('model.default.dashboard.name');
export const TEMPLATE_DEFAULT_NAME = getMessages().getText('model.default.template.name');
export const BOARDS_LIMIT_EXCEEDED_TEXT = getMessages().getText('open_boards.snackbar.limit_exceeded');

export const REST_LOAD_DRIVES = '/rest/sharedDrives';
export const REST_LOAD_BOARDS =                 REST_BOARD + 'openDialog?loadType=';
export const REST_LOAD_BOARD =                  REST_BOARD + 'openDialog/';
export const REST_LOAD_BOARD_PERMISSIONS =      REST_LOAD_BOARD + 'permissions/';
export const REST_LOAD_BOARDS_ALL_BOARDS =      REST_LOAD_BOARDS + 'myDrive_sharedWithMe_sharedDrives';
export const REST_LOAD_BOARDS_SHARED_DRIVES =   REST_LOAD_BOARDS + 'sharedDrives';
export const REST_LOAD_BOARDS_STARRED =         REST_LOAD_BOARDS + 'starred';
export const REST_LOAD_BOARDS_ARCHIVE =         REST_LOAD_BOARDS + 'archive';
export const REST_LOAD_BOARDS_TEMPLATES =       REST_LOAD_BOARDS + 'templates';
export const REST_CREATE_TEMPLATE =             REST_BOARD + '?template=true';
export const REST_BOARD_MOVE =                  REST_BOARD + 'moveToTeamDrive/';
export const REST_BOARD_FILES_MOVE =            REST_BOARD + 'moveFileToTeamDrive';
export const REST_BOARD_FILES_MOVE_FOLDERS =    REST_BOARD_FILES_MOVE + '/folders/';
export const REST_BOARD_UPDATE_PERMISSIONS =    '/updatePermisions';
export const REST_TRELLO_IMPORT =               '/rest/dashboard/trello/import';
export const REST_JIRA_IMPORT =                 '/rest/dashboard/jira/import';
export const REST_CSV_IMPORT =                  '/rest/dashboard/csv/import';
export const REST_PWC_IMPORT =                  '/rest/dashboard/pwc/import';
export const REST_PWC_NEW_IMPORT_PREPARE =      '/rest/dashboard/pwc/templateImport/prepare/';
export const REST_PWC_NEW_IMPORT =              '/rest/dashboard/pwc/templateimport';

export const LOCAL_STORAGE_KEY_OPEN_BOARD_ACTIVE_TAB = 'openBoardActiveTab';

export const BOARDS_DIALOG_CLASS = 'open-boards-dialog';
export const BUTTON_TEXT_GIVE_PERMISSIONS = getMessages().getText('permissions.give');
export const TEXT_PERMISSIONS_GRANTED = getMessages().getText('permissions.granted');

export const REST_LOAD_BOARDS_ALL_BOARDS_V2 =      REST_BOARD + 'browseBoards';
