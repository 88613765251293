import * as React from 'react';
import { SettingsSection } from '../../../../../../base/components/SettingsSection/SettingsSection';
import { CLASS_BOARD_DETAILS_SECTION } from '../../../../constants';
import { IRolesSectionProps } from './types';
import { BOARD_SECTION_ROLES_TITLE } from '../../constants';
import { BoardRoles } from '../BoardRoles/BoardRoles';

export function RolesSection ({
    isBasicFeature,
    sectionType,
    onClickDisallow
}: IRolesSectionProps) {
    const className = CLASS_BOARD_DETAILS_SECTION + ' ' + CLASS_BOARD_DETAILS_SECTION + '--roles';

    return (
        <SettingsSection
            className={className}
            sectionType={sectionType}
            title={BOARD_SECTION_ROLES_TITLE}
            isBasicFeature={isBasicFeature}
            onClickDisallow={onClickDisallow}
        >
            <BoardRoles />
        </SettingsSection>
    );
};
