import { getUserRecentBoards } from 'app/store/model/authUser/selectors/getUserRecentBoards';
import { IRecentBoard } from 'app/types/rest/IUserMeta';
import { IApplicationState } from 'app/types/types';
import { getTabs } from '../../../../store/selectors/getTabs';
import { TTabsModelState } from 'app/store/model/tabs/types';
import { USER_RECENT_BOARDS_NAV_PANEL_MAX } from 'app/rest/effects/authUser/recentBoards/constants';
import { createDeepEqualSelector } from 'app/view/react_components/helpers/memoizeHelper';

type TgetRecentBoards = (
    state: IApplicationState,
) => IRecentBoard[];

const getRecentBoardsSelector = (
    userRecentBoards: IRecentBoard[],
    tabs: TTabsModelState,
): IRecentBoard[] => {
    return userRecentBoards
        .filter(board => !tabs[board.id])
        .splice(0, USER_RECENT_BOARDS_NAV_PANEL_MAX);
};

export const getRecentBoardsCreateSelector = (
): TgetRecentBoards => createDeepEqualSelector(
    getUserRecentBoards,
    getTabs,
    getRecentBoardsSelector
);
