import { IApplicationState, TCardId } from '../../../../../../types/types';
import { IComment, TCommentId } from '../../../../../../store/model/card/types/IComment';
import { getCard } from '../../../../../../store/model/selectors/getCard';

export const getCommentById = (
    state: IApplicationState,
    cardId: TCardId,
    commentId: TCommentId
): IComment => {
    const card = getCard(state, cardId);

    return card.comments && card.comments.find( comment => {
        return comment.id === commentId;
    });
};
