import { connect } from 'react-redux';
import { getBoardColors } from '../../../../../../../../store/model/board/selectors/getBoardColors';
import { TStatus } from '../../../../../../../../types/model';
import { IApplicationState } from '../../../../../../../../types/types';
import { ColorsPanelButton } from '../../components/ColorsPanelButton/ColorsPanelButton';
import { IColorsPanelButtonEvents, IColorsPanelButtonFields } from '../../components/ColorsPanelButton/types';
import { onClick } from './events/onClick';
import { IColorsPanelButtonHOCProps } from './types';

const mapStateToProps = (
    state: IApplicationState,
    { boardId }: IColorsPanelButtonHOCProps
): IColorsPanelButtonFields => {
    const colors = getBoardColors(state, boardId);
    const indicatorNumber = colors.reduce((count, color) => {
        return count + Number(color.status === TStatus.STATUS_ACTIVE);
    }, 0);
    return {
        indicatorNumber,
        tooltip: null,
    }
};

const mapDispatchToProps = (
    dispatch: any,
    {onClick: ownOnClick}: IColorsPanelButtonHOCProps
): IColorsPanelButtonEvents => {
    return {
        onClick: () => dispatch(onClick(ownOnClick))
    }
};

export const ColorsPanelButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(ColorsPanelButton);

ColorsPanelButtonHOC.displayName = 'ColorsPanelButtonHOC';
