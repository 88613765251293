import * as React from 'react';
import './_Logo.scss';
import { ELogoSize, ILogoProps } from './types';
import { Button, ButtonDropdown, Icon } from 'kui';
import { CLASS_LOGO, getIconColor, LOGO_BG_COLORS, LOGO_COLOR_DEFAULT, LOGO_ICONS } from '../constants';
import { LogoSelect } from '../LogoSelect/LogoSelect';
import { getInitialsHelper } from '../../../../../helpers/getInitialsHelper';
import { markdown } from '../../../../../../../helper/markdownHelper';
import { escape } from 'underscore';

export function Logo({
    className: classNameOwn,
    isChangeColorOnReset,
    isSelect,
    name,
    logo,
    size = ELogoSize.SMALL,
    onBlur,
    onChange
}: ILogoProps) {
    const icon = logo && logo.icon && LOGO_ICONS.includes(logo.icon) ? logo.icon : null;
    const color = logo ? LOGO_BG_COLORS.includes(logo.color) && logo.color ||
        LOGO_COLOR_DEFAULT : null;

    const className = CLASS_LOGO;

    const [newIcon, setNewIcon] = React.useState(icon);
    const [newColor, setNewColor] = React.useState(color);

    const initials = getInitialsHelper(markdown(escape(name)));

    React.useEffect(() => {
        const icon = logo && logo.icon && LOGO_ICONS.includes(logo.icon) ? logo.icon : null;
        const color = logo ? LOGO_BG_COLORS.includes(logo.color) && logo.color ||
            LOGO_COLOR_DEFAULT : null;
        setNewColor(color);
        setNewIcon(icon);
    }, [logo]);

    return (
        <ButtonDropdown
            className={`
                ${classNameOwn || ''}
                ${className}
                ${className + (size === ELogoSize.LARGE ? '--large' : '--small')}
            `}
            dropdownClassName={className + '__dropdown'}
            disabled={!isSelect}
            onClose={() => {
                if (newIcon !== icon || newColor !== color) {
                    onChange({ icon: newIcon, color: newColor });
            }}}
            portal
            onBlur={onBlur}
        >
            <Button
                className={className + '__button'}
                style={newColor ? { background: newColor, color: getIconColor(newColor) } : null}
            >
                {newIcon ? <Icon xlink={newIcon} size={24} /> : initials.toUpperCase()}
            </Button>
            <LogoSelect
                logo={{ icon: newIcon, color: newColor }}
                isChangeColorOnReset={isChangeColorOnReset}
                onChange={logo => {
                    setNewColor(logo.color);
                    setNewIcon(logo.icon);
                }}
            />
        </ButtonDropdown>
    );
}
