import { getSignInIsShow } from './../store/signIn/selectors/getSignInIsShow';
import { IGetState } from './../../../../../types/types';
import { signInShowedEvent } from './../../../../../middlewares/segment/segmentEvents/userSegmentEvents';
import { isShowSetAction } from './../store/signIn/actions/isShowSetAction';
import { ThunkAction, Dispatch } from '../../../../../types/actions';

export const isShowSet = (
    isShow: boolean
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (isShow) {
            const state = getState();
            const signInIsShow = getSignInIsShow(state);
            if (!signInIsShow) {
                dispatch(signInShowedEvent());
            }
        }
        dispatch(isShowSetAction(isShow));
    };
    return action;
};
