import { connect } from 'react-redux';
import { EMPTY_ARRAY, IApplicationState } from '../../../../../../../types/types';
import { ICardRelatedHOCOwnProps } from './types';
import { ICardRelatedEvents, ICardRelatedFields } from '../../components/CardRelatedSection/types';
import { CardRelatedSection } from '../../components/CardRelatedSection/CardRelatedSection';
import { onOpenDialog } from './events/onOpenDialog';
import { getSectionType } from '../../../selectors/getSectionType';
import {
    getBoardIsCardDefaultProperty
} from '../../../../../../../store/model/board/selectors/getBoardIsCardDefaultProperty';
import { EAsidePanelProperty } from '../../../../asidePanel/components/AsidePanel/types';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { getIsAssignedToMeActive } from '../../../../../../../store/router/selectors/getIsAssignedToMeActive';
import { onShowSnackbar } from './events/onShowSnackbar';
import { getIsLinkCardActive } from '../../../../../../../store/router/selectors/getIsLinkCardActive';
import { getRelatedCardsCreateSelector } from './selectors/getRelatedCards';

const mapStateToProps = () => {
    const getRelatedCards = getRelatedCardsCreateSelector();

    const _mapStateToProps = (
        state: IApplicationState,
        ownProps: ICardRelatedHOCOwnProps
    ): ICardRelatedFields => {
        const { boardId, cardId, sectionType: sectionTypeOwn } = ownProps;
        const sectionType = getSectionType(state, boardId, cardId, sectionTypeOwn);
        const relatedCards = getRelatedCards(state, boardId, cardId);
        return {
            boardId,
            sectionType,
            relatedCards: relatedCards && relatedCards.length ? relatedCards
                : sectionType !== ESettingsSectionType.READONLY
                    ? EMPTY_ARRAY
                    : null,
            isDisallowEdit: getIsAssignedToMeActive(state) || getIsLinkCardActive(state),
            isDefaultProperty: getBoardIsCardDefaultProperty(state, boardId, EAsidePanelProperty.CARD_RELATED)
        }
    };

    return _mapStateToProps;
};

const mapDispatchToProps = (
    dispatch: any,
    ownProps: ICardRelatedHOCOwnProps
): ICardRelatedEvents => {
    const { cardId, boardId } = ownProps;
    return {
        onOpenDialog: () => dispatch(onOpenDialog(cardId, boardId)),
        onShowSnackbar: () => dispatch(onShowSnackbar(cardId, boardId))
    }
};

export const CardRelatedSectionHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardRelatedSection);

CardRelatedSectionHOC.displayName = 'CardRelatedSectionHOC';
