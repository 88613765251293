import * as React from 'react';
import { TopBarStatisticItem } from '../../components/TopBarStatisticItem/TopBarStatisticItem';
import { INameMapToElement, ITopBarInfoBlockProps } from './types';
import './_TopBarInfoBlock.scss';
import { TOPBAR_STAT_DONE, TOPBAR_STAT_HINT, TOPBAR_STAT_MYTASKS, TOPBAR_STAT_OVERDUE } from './constants';
import { EHintKey, HintHOC } from '../../../../hints';
import { Button, ButtonDropdown, Icon, SelectList, SelectListItem } from 'kui';
import { EFilterButtonsType } from '../../store/topBar/types';
import { CLASS_TOPBAR_STAT_ITEM } from '../../components/TopBarStatisticItem/constants';
import { root } from '../../../../../../store/constants';
import { UserpicStorage } from 'app/view/react_components/base/components/UserpicStorage/UserpicStorage';
import { AnalyzeRender } from 'app/view/react_components/helpers/memoizeHelper';

// @ts-ignore
const analyzeRender = window.Settings.development ? new AnalyzeRender(`TopBarInfoBlock`) : null;

export function TopBarInfoBlock ({
    active,
    cardCounters,
    isAsidePanelOpened,
    userId,
    userInitials,
    userPhotoIrl,
    onFilterButtonClick,
}: ITopBarInfoBlockProps) {
    const ref = React.useRef(null);

    if (analyzeRender) analyzeRender.call(`${isAsidePanelOpened}`);

    const className = 'top-bar__info-block';
    const classNameMore = className + '-more';
    const classNameMoreButton = className + '-more-button';

    const classNameStat = 'top-bar__stat';
    const [hintSelector, setHintSelector] = React.useState(null);
    const timerRef = React.useRef(null);

    const [size, setSize] = React.useState(0);
    const calcSize = () => {
        let size = 0;
        if (root._innerWidth < 600) {
            // 0
        } else {
            if (isAsidePanelOpened) {
                if (root._innerWidth < 1400) {
                    size = 1;
                } else {
                    size = 3;
                }
            } else if (root._innerWidth < 1000 ||
                root._innerWidth > 1100 && root._innerWidth <= 1450
            ) {
                size = 1;
            } else if (
                root._innerWidth > 1450 && root._innerWidth < 1650
            ) {
                size = 2;
            } else {
                size = 3;
            }
        }
        setSize(size);
    }

    function onResizeWindow() {
        if (timerRef.current) clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            calcSize();
        }, 100)
    }

    React.useEffect(() => {
        root.removeEventListener('resize', onResizeWindow);
        root.addEventListener('resize', onResizeWindow);
        calcSize();
    }, [isAsidePanelOpened]);

    const [types, setTypes] = React.useState<EFilterButtonsType[]>([]);
    React.useEffect(() => {
        if (active && size < 3) {
            const index = types.indexOf(active);
            if (index > size - 1) { // активный не видно, поставить его первым
                setTypes([
                    active,
                    ...types.filter(type => type !== active),
                ]);
            }
        }
    }, [active, size]);

    const [icons, setIcons] = React.useState([]);
    const [selectItems, setSelectItems] = React.useState([]);
    React.useEffect(() => {
        const iconsMap: INameMapToElement = {
            [EFilterButtonsType.DONE]: (
                <TopBarStatisticItem
                    active={active === EFilterButtonsType.DONE}
                    value={cardCounters[EFilterButtonsType.DONE]}
                    icon={'progress'}
                    key={EFilterButtonsType.DONE}
                    title={TOPBAR_STAT_DONE}
                    onClick={() => onFilterButtonClick(EFilterButtonsType.DONE, active === EFilterButtonsType.DONE)}
                />),
            [EFilterButtonsType.OVERDUE]: (
                <TopBarStatisticItem
                    active={active === EFilterButtonsType.OVERDUE}
                    value={cardCounters[EFilterButtonsType.OVERDUE]}
                    icon={'overdue'}
                    key={EFilterButtonsType.OVERDUE}
                    title={TOPBAR_STAT_OVERDUE}
                    onClick={() => onFilterButtonClick(EFilterButtonsType.OVERDUE, active === EFilterButtonsType.OVERDUE)}
                />),
            [EFilterButtonsType.MY_TASKS]: (
                <TopBarStatisticItem
                    active={active === EFilterButtonsType.MY_TASKS}
                    value={cardCounters[EFilterButtonsType.MY_TASKS]}
                    type={'userpic'}
                    icon={'userpic'}
                    key={EFilterButtonsType.MY_TASKS}
                    photoUrl={userPhotoIrl}
                    initials={userInitials}
                    userId={userId}
                    title={TOPBAR_STAT_MYTASKS}
                    onClick={() => onFilterButtonClick(EFilterButtonsType.MY_TASKS, active === EFilterButtonsType.MY_TASKS)}
                />)
        };

        const listItemsMap: INameMapToElement = {
            [EFilterButtonsType.DONE]: (
                <SelectListItem
                    key={EFilterButtonsType.DONE}
                    iconSize={24}
                    icon={'progress'}
                    list={String(cardCounters[EFilterButtonsType.DONE])}
                    listLabel={String(cardCounters[EFilterButtonsType.DONE])}
                    onClick={() => onFilterButtonClick(EFilterButtonsType.DONE, false)}>
                    {TOPBAR_STAT_DONE}
                </SelectListItem>),
            [EFilterButtonsType.OVERDUE]: (
                <SelectListItem
                    key={EFilterButtonsType.OVERDUE}
                    iconSize={24}
                    icon={'overdue'}
                    list={String(cardCounters[EFilterButtonsType.OVERDUE])}
                    listLabel={String(cardCounters[EFilterButtonsType.OVERDUE])}
                    onClick={() => onFilterButtonClick(EFilterButtonsType.OVERDUE, false)}>
                    {TOPBAR_STAT_OVERDUE}
                </SelectListItem>),
            [EFilterButtonsType.MY_TASKS]: (
                <SelectListItem
                    key={EFilterButtonsType.MY_TASKS}
                    iconSize={24}
                    list={String(cardCounters[EFilterButtonsType.MY_TASKS])}
                    listLabel={String(cardCounters[EFilterButtonsType.MY_TASKS])}
                    icon={'tag'}
                    onClick={() => onFilterButtonClick(EFilterButtonsType.MY_TASKS, false)}>
                    <UserpicStorage
                        initials={userInitials}
                        src={userPhotoIrl}
                        userId={userId}
                    /> {TOPBAR_STAT_MYTASKS}
                </SelectListItem>)
        };

        setSelectItems(types.map(type => listItemsMap[type]));
        setIcons(types.map(type => iconsMap[type]));
    }, [active, cardCounters, types]);

    React.useEffect(() => {
        if (size && icons.length) {
            setHintSelector(`.${CLASS_TOPBAR_STAT_ITEM}`);
        } else {
            setHintSelector(null);
        }
    }, [icons, size]);

    React.useEffect(() => {
        let types: EFilterButtonsType[] = [];
        if (cardCounters[EFilterButtonsType.DONE]) types.push(EFilterButtonsType.DONE);
        if (cardCounters[EFilterButtonsType.OVERDUE]) types.push(EFilterButtonsType.OVERDUE);
        if (cardCounters[EFilterButtonsType.MY_TASKS]) types.push(EFilterButtonsType.MY_TASKS);
        setTypes(types);

        return () => {
            if (timerRef.current) clearTimeout(timerRef.current);
            root.removeEventListener('resize', onResizeWindow);
        }
    }, []);

    return (
        <div
            className={`${className}`}
            ref={ref}
        >
            <div
                className={`${classNameStat}`}
            >
                {!!size && icons[0]}
                {size > 1 && icons[1]}
                {size > 2 && icons[2]}
                {size < 3 && selectItems.length > size &&
                    <ButtonDropdown
                        className={classNameMore}
                        directionHorizontal={'right'}
                        isScaleAnimation
                    >
                        <Button
                            className={classNameMoreButton}
                            variant={'icon'}
                            aria-label={'Quick filters'}
                        >
                            <Icon xlink={'more'} size={24}/>
                        </Button>
                        <SelectList fixActive={false}>
                            {selectItems.slice(size)}
                        </SelectList>
                    </ButtonDropdown>
                }
            </div>
            {hintSelector &&
                <HintHOC
                    arrow={'up'}
                    direction={'down'}
                    show={true}
                    value={TOPBAR_STAT_HINT}
                    hintKey={EHintKey.TOP_BAR_FILTER_ICONS}
                    selector={hintSelector}
                />
            }
        </div>
    );
};
