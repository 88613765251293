import { IApplicationState, TBoardId } from '../../../../types/types';
import { getBoard } from '../../selectors/getBoardById';
import { IBoardMeta } from '../types';

export const getBoardMeta = (
    state: IApplicationState,
    boardId: TBoardId
): IBoardMeta => {
    const board = getBoard(state, boardId);

    return board.meta || {};
};
