import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId, TTag } from '../../../../../../../../types/types';
import { getCardTags } from '../../../../../../../../store/model/selectors/getCardTags';
import { cardSetTags } from '../../../../../../../../rest/effects/card/card/cardSetTags';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { SegmentCountValue } from '../../../../../../../../middlewares/segment/trackEntities/cardEvents';
import {
    getAuthUserAllowCardTagCount
} from '../../../../../../../../store/model/authUser/selectors/getAuthUserAllowCardTagCount';
import { showSnoozeBlocker } from '../../../../../../base/effects/showSnoozeBlocker';
import { root } from '../../../../../../../../store/constants';

export const toggleTag = (
    cardId: TCardId,
    tag: TTag
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const tags = [...getCardTags(state, cardId)];

        const maxCount = getAuthUserAllowCardTagCount(state);
        const count = tags.length;

        const index = tags.indexOf(tag);
        if (index < 0) {
            if (count >= maxCount) {
                dispatch(showSnoozeBlocker({ allow: 'allowCardTagCount' }));
            } else {
                tags.push(tag);
                dispatch(segmentTrackAction(SegmentCardEvent.CHOOSE_TAG, {
                    name: SegmentCountValue.COUNT,
                    value: tags.length
                }));
            }
        } else {
            if (
                count > maxCount &&
                root.App.controller.allowCounterSnackbarHelper.isShowSnackbar('allowCardTagCount')
            ) {
               return Promise.resolve(false);
            } else {
                dispatch(segmentTrackAction(SegmentCardEvent.REMOVED_TAG));
                tags.splice(index, 1);
            }
        }

        return dispatch(cardSetTags(cardId, tags)).then(() => {
            return Promise.resolve(true);
        });
    };
    return action;
};
