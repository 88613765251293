import * as React from 'react';
import { createRoot, Root } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import { TopBarHOC } from '../main/topBar/hocs/TopBarHOC/TopBarHOC';

export class TopBar {
    root: Root;
    store: Store;

    constructor (el: HTMLElement, store: Store) {
        this.root = createRoot(el!);
        this.store = store;
        this.render();
    }

    render () {
        this.root.render(
            <Provider store={this.store}>
                <TopBarHOC />
            </Provider>
        );
    }

    remove () {
        this.root.unmount();
    }
}
