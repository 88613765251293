import * as React from 'react';
import { IRestCardCommonActivity } from '../../../../../../../types/rest/activity/IRestCardCommonActivity';
import { IActivityProps } from '../../../../boardDetails/activity/TabActivity/types';
import { TabActivityContext } from '../../../../boardDetails/activity/TabActivity/components/TabActivity/constants';
import { TActivity } from '../../../../../../../store/activities/types';
import {
    ActivityItemBody
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemBody/ActivityItemBody';
import { Icon } from 'kui';
import { ActivityHeader } from '../../../../boardDetails/activity/TabActivity/components/ActivityHeader/ActivityHeader';
import { PRIORITY_ICONS } from '../../../../../helpers/constants';
import { SMALL_ACTIVITY_MIN_ASIDE_WIDTH } from '../../../../boardDetails/activity/TabActivity/components/constants';

export function CardActivityPriority({
    activity,
    asidePanelWidth
}: IActivityProps<IRestCardCommonActivity>) {
    const { onOpenCard } = React.useContext(TabActivityContext);
    const { label, card } = activity as TActivity;
    const oldValue = activity.oldValue;
    const newValue = activity.newValue;
    const content = <>
        {oldValue && <Icon
            size={24}
            xlink={PRIORITY_ICONS.find(item => item.priority === String(oldValue) || !oldValue && !Number(item.priority)).icon}
        />}
        {oldValue && newValue && <Icon xlink={'forward'} size={24} />}
        {newValue && <Icon
            size={24}
            xlink={PRIORITY_ICONS.find(item => item.priority === String(newValue) || !newValue && !Number(item.priority)).icon}
        />
        }
    </>;
    return (
        <ActivityItemBody
            onClick={() => onOpenCard(card.id, activity.id)}
            header={
                <ActivityHeader
                    icon={'priority-no'}
                    label={label}
                    content={asidePanelWidth <= SMALL_ACTIVITY_MIN_ASIDE_WIDTH && content}
                />
            }
            activity={activity}
        >
            {asidePanelWidth > SMALL_ACTIVITY_MIN_ASIDE_WIDTH && content}
        </ActivityItemBody>
    );
};
