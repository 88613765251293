import { getMessages } from '../../../../../../../store/constants';

export const CARD_START_DATE = getMessages().getText('card_form_start_date');
export const CARD_START_DATE_MILESTONE = getMessages().getText('card_form_start_date_milestone');
export const CARD_DUE_DATE = getMessages().getText('card_form_due_date');
export const CARD_DATES_BUTTON_NOTIFY = getMessages().getText('card_form_dates_notify');
export const CARD_DATE_TIME_HINT = getMessages().getText('card_form_date_time_hint')
export const CARD_DATE_HINT = getMessages().getText('card_form_date_hint');
export const CARD_DATE_BOARD_LINK = getMessages().getText('card_form_date_hint_board_link');
export const CARD_DATE_LEARN_LINK = getMessages().getText('card_form_date_hint_learn_link');
export const CARD_DATE_HINT_BUTTON_YES = getMessages().getText('card_form_date_hint_button_yes');
export const CARD_DATE_HINT_BUTTON_NO = getMessages().getText('card_form_date_hint_button_no');
export const CARD_DATE_GANTT_CONTEXT_HINT_ADD_DATE = getMessages().getText('gantt_context_hints.add_date');

export const MIN_ASIDE_PANEL_WIDTH_DATES_COLUMN = 464;
