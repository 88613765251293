import { THintKey } from '../../../types';
import { AT_HINT_ACTION_SET } from './constants';
import { IHintActionSetAction } from './types';
import { THintAction } from '../../hint/actions/types';

export const hintActionSetAction = (
    key: THintKey,
    hintAction: THintAction
): IHintActionSetAction => ({
        type: AT_HINT_ACTION_SET,
        key,
        hintAction
    }
);
