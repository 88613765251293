import { Dispatch, ThunkAction } from '../../../types/actions';
import { IGetState, TListId } from '../../../types/types';
import { listPatchRest } from './api/helpers/listPatchRest';
import { getListUpdateMinMaxOrderNumber } from '../../../store/model/list/selectors/getListUpdateMinMaxOrderNumber';
import { getList } from '../../../store/model/list/selectors/getList';

export const listUpdateMinMaxOrderNumber = (
    listId: TListId,
    orderNumbers: number[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const list = getList(getState(), listId);
        const update = getListUpdateMinMaxOrderNumber(list, orderNumbers);
        if (update) {
            return dispatch(listPatchRest(listId, update))
        }
        return Promise.resolve();
    };
    return action;
};
