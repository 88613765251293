import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState, TCardAssigneeRoleId, TCardId, TPermissionId } from '../../../../types/types';
import { cardsRestPatch } from './api/helper/cardsRestPatch';
import { ICards } from '../../../../store/model/cards/types';
import { SegmentCardEvent, segmentTrackAction } from '../../../../middlewares/segment';
import { getCardAssignees } from '../../../../store/model/selectors/getCardAssignees';

export const cardToggleAssigneeRole = (
    cardId: TCardId,
    permissionId: TPermissionId,
    roleId: TCardAssigneeRoleId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();

        const assignees = [...getCardAssignees(state, cardId)];
        const assigneeIndex = assignees.findIndex((user) => user.sharedUser.permissionId === permissionId);
        if (assigneeIndex > -1) {
            const assignee = {...assignees[assigneeIndex]};
            const assigneeRoles = [...(assignee.roleIds || [])];
            const index = assigneeRoles.indexOf(roleId);
            if (index === -1) {
                assigneeRoles.push(roleId);
                dispatch(segmentTrackAction(SegmentCardEvent.ROLE_ASSIGNED));
            } else {
                assigneeRoles.splice(index, 1)
                dispatch(segmentTrackAction(SegmentCardEvent.ROLE_UNASSIGNED));
            }
            assignee.roleIds = assigneeRoles;

            assignees.splice(assigneeIndex, 1, assignee);
            const cards: ICards = {
                [cardId]: {
                    assignees
                }
            };
            return dispatch(cardsRestPatch(cards))
        }
    };
    return action;
};
