import { ITimeUnits } from '../../../../../../../../../types/model';

const singularize = (interval: number, value: string) => {
    return interval === 1 ? value : value + 's';
};

const unitToString = (
    unit: ITimeUnits
) => {
    switch (unit) {
        case ITimeUnits.MINUTES: {
            return 'minute';
        }
        case ITimeUnits.HOURS: {
            return 'hour';
        }
        case ITimeUnits.DAYS: {
            return 'day'
        }
        case ITimeUnits.WEEKS: {
            return 'week'
        }
        case ITimeUnits.MONTHS: {
            return 'month'
        }
    }
    return '';
};

export const getUnitSelectParams = (
    interval: number,
    unit: ITimeUnits
) => {
    let currentIndex = 0;
    const unitsKeys = Object.keys(ITimeUnits);
    const keyValues =  unitsKeys
        .filter((key) => isNaN(Number(key)))
        .map((key: ITimeUnits, index) => {
                if (key === unit){
                    currentIndex  = index;
                }
                return {
                    value: key,
                    text: singularize(interval, unitToString(ITimeUnits[key])),
                }
            }
        );
    return {
        keyValues,
        currentIndex
    }
};
