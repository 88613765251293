import { TBoardId, TCardId } from '../types/types';
import { BoardLoader } from './loader/boardLoader';
import { IBoard } from '../store/model/board/types';
import { getBoard } from '../store/model/selectors/getBoardById';
import store, { dispatch, getAppState } from '../store/configureStore';
import { TStatus } from '../types/model';
import { getTab } from '../view/react_components/main/navigationPanel/store/selectors/getTab';
import { openedBoardSegmentEvent } from '../view/react_components/dialogs/openBoards/effects/segmentEvents';
import { getBoardCardsCount } from '../store/model/selectors/getBoardCardsCount';
import { getBoardListsByStatus } from '../store/model/selectors/getBoardListsByStatus';
import { fetchHandler } from '../util/fetchHandler';
import { getMessages, root } from '../store/constants';
import { getActiveBoard } from '../store/model/selectors/getActiveBoard';
import { activeBoardIdActionSet } from '../store/model/actions/activeBoardIdActionSet';
import { ILists } from '../store/model/lists/types';
import { ICards } from '../store/model/cards/types';
import { getTabIds } from '../store/model/selectors/getTabIds';
import { DASHBOARD_LIMIT } from '../const';
import { BoardTabsLoader } from './loader/boardTabsLoader';
import { snackbarErrorDefault } from '../view/react_components/snackbarsStack';
import { SNACKBAR_ID_BOARD_NOT_FOUND } from '../view/react_components/snackbarsStack/effects/constants';
import { tabsActionSet } from '../store/model/actions/tabsActionSet';
import { IAppTab, TTabsModelState } from '../store/model/tabs/types';
import { tabsModelSetAction } from '../store/model/tabs/actions/tabsModelSetAction';
import { tabsModelRemoveTabsAction } from '../store/model/tabs/actions/tabsModelRemoveTabsAction';
import { IDependencies } from '../store/model/dependencies/dependencies/types';
import { IDriveDocs } from '../store/model/driveDocs/types';
import { modelUpdate } from '../store/model/actions/modelUpdate';
import { IRestCard } from '../types/rest/IRestCard';
import { listsActionSet } from '../store/model';
import { listsUpdate } from '../store/model/lists/actions/listsUpdate';
import { boardClose } from '../view/react_components/base/effects/boardClose';
import { boardSetStatus } from '../rest/effects/board/boardSetStatus';
import { closeRecentOpenBoard } from '../rest/effects/authUser/recentBoards/closeRecentOpenBoard';
import { getFullLoadedBoardIds } from '../store/model/selectors/getFullLoadedBoardIds';
import { boardActionSet } from '../store/model/boards/actions/boardActionSet';
import { updateAction } from '../store/model/board/actions/updateAction';
import { boardsActionSet } from '../store/model/actions/boardsActionSet';
import {
    sendUserActivityStatEventByViewType
} from '../rest/effects/userStatAdminReport/helpers/sendUserActivityStatEventByViewType';
import { getAuthUser } from '../store/model/authUser/selectors/getAuthUser';
import { getUserPaidSubscriptions } from '../store/model/authUser/selectors/getUserPaidSubscriptions';
import { Intercom } from './intercom';
import {
    authUserSetHaveSharedBoardWith2OtherMeta
} from '../rest/effects/authUser/authUserSetHaveSharedBoardWith2OtherMeta';
import { getHaveSharedBoardWith2Other } from '../store/model/authUser/selectors/getHaveSharedBoardWith2Other';
import { snoozeBlockerClosedSet } from '../view/react_components/main/kanbanView/store/actions/snoozeBlockerClosedSet';
import { boardUpdateMeta } from '../rest/effects/board/boardUpdateMeta';
import { getBoardLogoColorNew } from '../store/model/selectors/getBoardLogoColorNew';
import { boardSetLogoColor } from '../rest/effects/board/boardSetLogoColor';
import { getBoardLogoColor } from 'app/store/model/selectors/getBoardLogoColor';
import {
    getLocalStorageBoardViewType
} from '../view/react_components/typeSwitcher/store/localstorage/actions/getLocalStorageBoardViewType';
import { getActiveRouteBoardId } from '../store/router/selectors/getActiveRouteBoardId';
import { addRecentOpenBoards } from '../rest/effects/authUser/recentBoards/addRecentOpenBoards';
import { getBoardPermissionsAllowChangeLogo } from '../store/model/selectors/getBoardPermissionsAllowChangeLogo';
import { cardOpen } from '../view/react_components/base/effects/cardOpen';
import { editList } from '../view/react_components/main/kanbanView/effects/editList';
import { boardSetLogoColorRedux } from '../rest/effects/board/boardSetLogoColorRedux';
import { escape } from 'underscore';
import { initWebPushNotifications, isBrowserNotificationsPopupShown } from './webPushNotify';
import { getBoardOwner } from 'app/store/model/selectors/getBoardOwner';
import {
    EBrowserNotificationsTypes
} from '../view/react_components/dialogs/notificationsRequest/hocs/BrowserNotificationsRequestHOC/types';
import { IRestBoardForOpenDialog } from 'app/view/react_components/dialogs/openBoards/rest/types';
import { addRecentOpenBoard } from 'app/rest/effects/authUser/recentBoards/addRecentOpenBoard';
import { getUserRecentBoards } from 'app/store/model/authUser/selectors/getUserRecentBoards';
import { ERecentBoardKeys, ETutorial, IRecentBoard } from 'app/types/rest/IUserMeta';
import { authUserRecentBoardsPatch } from 'app/rest/effects/authUser/recentBoards/helper/authUserRecentBoardsPatch';
import { IRestAuthUser } from 'app/types/rest/IRestAuthUser';
import { cardsRestPatch } from 'app/rest/effects/card/card/api/helper/cardsRestPatch';
import { getFirstBoardId } from '../abtesting/abTests/firstBoardType/selectors/getFirstBoardId';
import { getHint } from '../view/react_components/hints/store/hints/selectors/getHint';
import { EHintKey, EHintStatus } from '../view/react_components/hints';
import {
    getShowGanttContextHints
} from '../view/react_components/hints/conditions/ganttContextConditions/getShowGanttContextHints';

type TCardsMap = {
    cardsActiveCount: number;
    cardsToStore: ICards,
    dependenciesToStore: IDependencies,
    driveDocsToStore: IDriveDocs
}

export class BoardLoadManager {

    loadBoardToStore(boardId: TBoardId, noCards: boolean = false, noError: boolean = false, noLoading: boolean = false, additional: IBoard = {}, ignoreRecentBoards: boolean = false): Promise<IBoard> {
        const user = getAuthUser(store.getState());
        if (!noLoading) {
            const isShowTips = !user.firstVisit && root.App.controller.boardLoaderInfo.isFirstLoad;
            root.App.controller.showSystemLoader(true, isShowTips, true); // единственный случай, когда показываются tips
        }
        return new Promise(
            (resolve, reject) => {
                const boardLoader = new BoardLoader(boardId);
                boardLoader
                    .load(null, true, noCards, noError)
                    .then((board: IBoard) => {
                        if (board) {
                            root.App.controller.mainView.doAfterLoader(() => {
                                root.App.controller.showTutorial(ETutorial.SIGN_IN_TUTORIAL);
                            });
                            let cardsActiveCount = this.putBoardToStore(board, noCards, additional);
                            if (!noCards) {
                                const boardMetaCardsActiveCount = board.meta && board.meta.cardsActiveCount;
                                if (boardMetaCardsActiveCount !== cardsActiveCount) {
                                    dispatch(boardUpdateMeta(boardId, {cardsActiveCount}));
                                }
                            }
                            if (!ignoreRecentBoards && board.id !== getFirstBoardId(store.getState())) {
                                dispatch(addRecentOpenBoard(boardId));
                            }
                        }
                        resolve(board);
                    })
                    .catch(
                        e => {
                            if (e && e.status === 403 && !noError) {
                                return reject(e);
                            }
                            console.log(e);
                            resolve(null)
                        }
                    );
            }
        )
    }

    putBoardToStore(board: IBoard,  noCards: boolean = false, additional: IBoard = {}){
        Object.assign(board, additional);
        board.noCards = noCards;
        const {lists, ...restBoard} = board;
        const listsToStore: ILists = {};
        let cardsActiveCount = 0;
        let cardsToStore: ICards = {};
        let dependenciesToStore: IDependencies = {};
        let driveDocsToStore: IDriveDocs = {};
        lists.forEach(list => {
            const {cards, ...restList} = list;
            listsToStore[list.id] = {
                ...restList,
                dashboardId: board.id
            };
            const cardsMap = this.mapCardsToStore(cards);
            cardsActiveCount += cardsMap.cardsActiveCount;
            cardsToStore = {...cardsToStore, ...cardsMap.cardsToStore};
            dependenciesToStore = {...dependenciesToStore, ...cardsMap.dependenciesToStore};
            driveDocsToStore = {...driveDocsToStore, ...cardsMap.driveDocsToStore};
        });

        dispatch(modelUpdate({
            dashboards: {
                [restBoard.id]: restBoard
            },
            lists: listsToStore,
            cards: cardsToStore,
            driveDocs: driveDocsToStore,
            dependencies: dependenciesToStore
        }));

        return cardsActiveCount;
    }

    openBoard(boardId: TBoardId, keepUrl: boolean = false) {
        root.App.controller.mainView.hideCardLink();
        const board = getBoard(store.getState(), boardId);
        let loadPromise;
        let sendSegmentEvent = true;
        if (board.id && !board.forDashboard) {
            if (board.closed) {
                dispatch(boardsActionSet(boardActionSet(boardId, updateAction({ closed: false }))));
            } else {
                sendSegmentEvent = false;
            }
            loadPromise = Promise.resolve(board);
        } else {
            loadPromise = this.loadBoardToStore(boardId, undefined, undefined, undefined, {forDashboard: false})
                .then(board =>{
                    if (sendSegmentEvent) {
                        this.sendOpenedBoardSegmentEvent(board, loadingStartTime);
                    }
                    this.syncNotifications(board.id)
                    return board;
                })
                .then(board => this.sendIntercomSharedWithTwoOtherEvent(board))
                .then(board => this.processOpenBoardStatus(board))
        }
        const loadingStartTime = Date.now();
        return loadPromise
            .then(board => {
                if (board) {
                    const state = store.getState();
                    const activeBoard = getActiveBoard(state);
                    if (!activeBoard || activeBoard.id !== board.id) {
                        if (activeBoard) {
                            root.App.vent.trigger(root.App.vent['dashboard:deactivated'], activeBoard.id);
                        }
                        dispatch(activeBoardIdActionSet(board.id));
                        dispatch(snoozeBlockerClosedSet(false));
                        root.App.router.setCurrentDashboard(board.id, (!keepUrl));
                        root.App.vent.trigger(root.App.vent['dashboard:activated'], board.id);
                        /**
                         * проверим lastActivity доски
                         * может надо сразу открыть карту или лист
                         */
                        if (!root.App.controller.mainView.relatedOpened) {
                            const cardId = root.App.controller.lastActivity.getOpenedCardId(board.id);
                            if (cardId) {
                                dispatch(cardOpen(cardId));
                            } else {
                                const listId = root.App.controller.lastActivity.getOpenedListId(board.id);
                                if (listId) {
                                    dispatch(editList(listId, true));
                                }
                            }
                        }

                        /**
                         * показать попап про браузерные нотификации,
                         * если открыл не свою доску
                         */
                        if (
                            !isBrowserNotificationsPopupShown() &&
                            (
                                !getShowGanttContextHints(state) ||
                                getHint(state, EHintKey.GANTT_CONTEXT_GANTT_VIEW_RESIZE).status === EHintStatus.SHOWN
                            )
                        ) {
                            const author = getBoardOwner(state, boardId);
                            const authUser = getAuthUser(state);
                            if (!author || author.permissionId !== authUser.permissionId) {
                                initWebPushNotifications(() => {
                                    return new Promise((resolve, reject) => {
                                        root.App.controller.mainView.showNotificationsRequestDialog(() => resolve(true), EBrowserNotificationsTypes.SHARED_BOARD_OPENED);
                                    })
                                });
                            }
                        }
                    }

                    if (!board.logoColor) {
                        const logoColor = getBoardLogoColor(state, board.id) || getBoardLogoColorNew();
                        if (getBoardPermissionsAllowChangeLogo(state, board.id)) { // иначе на ридонли вылетает please sign in
                            dispatch(boardSetLogoColor(boardId, logoColor));
                        } else {
                            dispatch(boardSetLogoColorRedux(boardId, logoColor));
                        }
                    }
                } else {
                    root.App.controller.showError(getMessages().getText('error.record.not_found'))
                }
            })

    }

    sendIntercomSharedWithTwoOtherEvent(board: IBoard): Promise<IBoard> {
        try {
            const authUser = getAuthUser(getAppState());
            const isAuthor = board.author && board.author.fullName === authUser.fullName;
            const currentDateTimeStamp = new Date().getTime() / 1000;
            const userCreatedEarlyThen1Days = (currentDateTimeStamp - authUser.createDateTimeStamp) < 1 * 24 * 60 * 60;
            const notHavePaidSubs = getUserPaidSubscriptions(getAppState()).length === 0;
            const sharedWithTwoOther = board.users && board.users.length > 2;
            const userHaveSharedBoardWith2Other = getHaveSharedBoardWith2Other(getAppState());
            if (sharedWithTwoOther && !userHaveSharedBoardWith2Other) {
                dispatch(authUserSetHaveSharedBoardWith2OtherMeta(true));
            }
            if (sharedWithTwoOther && isAuthor && userCreatedEarlyThen1Days && notHavePaidSubs) {
                const property = {shared_boards_v2: true}
                Intercom.sendProperties(property);
            }
        } catch (e) {
            console.error(e);
        }
        return Promise.resolve(board);
    }

    mapCardsToStore(cards: IRestCard[]): TCardsMap {
        let cardsActiveCount = 0;
        const cardsToStore: ICards = {};
        const dependenciesToStore: IDependencies = {};
        const driveDocsToStore: IDriveDocs = {};
        cards.forEach(card => {
            const {predecessors = [], mainDriveDocSet = [], ...restCard} = card;

            predecessors.forEach(predecessor => {
                dependenciesToStore[predecessor.id] = {
                    id: predecessor.id,
                    type: predecessor.type,
                    predecessorId: predecessor.predecessorId,
                    successorId: predecessor.cardId
                };
            })
            mainDriveDocSet.forEach(driveDoc => {
                driveDocsToStore[driveDoc.id] = {...driveDoc, main: true};
            })
            cardsToStore[card.id] = restCard;
            if (card.status === TStatus.STATUS_ACTIVE) cardsActiveCount++;
        })
        return {
            cardsActiveCount,
            cardsToStore,
            dependenciesToStore,
            driveDocsToStore
        }
    }

    processOpenBoardStatus(board: IBoard): Promise<IBoard> {
        const onClose = () => {
            dispatch(boardClose(board.id, false));
            dispatch(closeRecentOpenBoard(board.id));
        }
        return this.processBoardStatus(board, onClose);
    }

    processBoardStatus(board: IBoard, onClose: () => void, ignoreDeleteStatus?: boolean): Promise<IBoard> {
        if (board && board.status === TStatus.STATUS_DELETED && !ignoreDeleteStatus) {
            return new Promise((resolve) => {
                const restore = () => {
                    dispatch(boardSetStatus(board.id, TStatus.STATUS_ACTIVE))
                        .then(() => {
                            resolve(board);
                        });
                }
                root.App.controller.showAlert({
                    message: '\"' + escape(board.name) + '\"' + getMessages().getText('popup.dashboard.deleted'),
                    header: getMessages().getText('popup.dashboard.deleted.header'),
                    type: 'question',
                    buttons: [
                        {
                            text: getMessages().getText('popup.dashboard.deleted.button.close'),
                            click: onClose
                        },
                        {
                            text: getMessages().getText('popup.dashboard.deleted.button.restore'),
                            click: restore
                        },
                    ],
                    onClose
                });
            })
        }
        return Promise.resolve(board);
    }

    sendOpenedBoardSegmentEvent(
        board: IBoard,
        loadingStartTime: number
        ) {
        let tab = getTab(store.getState(), board.id);
        if (!tab) {
            tab = {
                id: board.id,
                driveId: board.driveId,
                isShared: board.shared
            }
        }
        const cardCount = getBoardCardsCount(store.getState(), board.id);
        const listCount = getBoardListsByStatus(store.getState(), board.id).length;
        dispatch(openedBoardSegmentEvent(tab, listCount, cardCount, board.users ? board.users.length : 0, loadingStartTime));
        dispatch(sendUserActivityStatEventByViewType(getLocalStorageBoardViewType(board.id)));
    }

    syncNotifications(boardId: TBoardId) {
        fetchHandler(`/rest/dashboards/${boardId}/notifications/synchronize`)
    }

    loadBoardTabsIfNotLoaded(boardIds: TBoardId[], deprecatedCallback: any): Promise<TBoardId[]> {
        if (deprecatedCallback) throw new Error('use promise then')
        const loadedIds = getTabIds(store.getState()).filter(id => boardIds.includes(id));
        const tabIdsToRemove = loadedIds.filter(id => !boardIds.includes(id));
        if (tabIdsToRemove.length) {
            dispatch(tabsActionSet(tabsModelRemoveTabsAction(tabIdsToRemove)));
        }
        let notLoadedIds = boardIds.filter(boardId => !loadedIds.includes(boardId));
        if (!notLoadedIds.length) return Promise.resolve(boardIds);

        const extraCount = loadedIds.length + notLoadedIds.length - DASHBOARD_LIMIT;
        if (extraCount > 0) {
            const extraIds = notLoadedIds.slice(notLoadedIds.length - extraCount).join('-');
            root.App.controller.showAlert({
                message: getMessages().getText('popup.dashboard.limit.exceeded.reopen'),
                type: 'warning',
                buttons: [{
                    text: getMessages().getText('popup.dashboard.limit.exceeded.button.other_tab'),
                    click: function () {
                        window.open('/dashboard/' + extraIds);
                    }
                }, {
                    text: getMessages().getText('popup.dashboard.limit.exceeded.button.cancel')
                }]
            });
            notLoadedIds = notLoadedIds.slice(0, notLoadedIds.length - extraCount);
        }
        return this.loadBoardTabs(notLoadedIds)
            .then((loadedTabs: IAppTab[]) => {
                if (!loadedTabs.length) return null;

                // KNB-2502 в табс теперь одна доска
                // const combinedTabs = [...getTabsArray(store.getState()), ...loadedTabs]
                //     .filter(tab => boardIds.includes(tab.id))
                //     .sort((a, b) => boardIds.indexOf(a.id) - boardIds.indexOf(b.id));

                let tabs: TTabsModelState = {}; // сначала запишем всё в табс, чтобы потом положить в ресент
                loadedTabs.forEach(tab => {
                    if (!tabs[tab.id]) {
                        tabs[tab.id] = tab;

                        if (!tab.logoColor) {
                            tab.logoColor = getBoardLogoColor(store.getState(), tab.id) || getBoardLogoColorNew();
                        }
                    }
                });

                this.closeBoards(tabs);
                dispatch(tabsActionSet(tabsModelSetAction(tabs)));

                let tab; // теперь оставим в табс только активную
                const activeRouteBoardId = getActiveRouteBoardId(store.getState());
                if (activeRouteBoardId) {
                    tab = loadedTabs.find(tab => tab.id === activeRouteBoardId);
                }
                if (!tab) tab = loadedTabs[0]; // atm
                tabs = { [tab.id]: tab };

                const recentIds: TBoardId[] = loadedTabs.reduce((tabs, tab) => {
                    if (tab.id !== activeRouteBoardId) tabs.push(tab.id);
                    return tabs;
                }, []);
                if (recentIds.length) dispatch(addRecentOpenBoards(recentIds, true));
                dispatch(tabsActionSet(tabsModelSetAction(tabs)));

                const tabIds = getTabIds(store.getState());
                root.App.router.setBaseUrl(tabIds);
                return tabIds;
            });
    }

    closeBoards(tabs: TTabsModelState = {}) {
        getFullLoadedBoardIds(store.getState()).forEach(boardId => {
            if (!tabs[boardId]) {
                dispatch(boardsActionSet(boardActionSet(boardId, updateAction({closed: true}))));
            }
        })
    }

    /**
     * todo по идее массивы больше не нужны, т.к. загружается всегда 1 доска
     */
    loadBoardTabs(idsToLoad: TBoardId[]): Promise<IAppTab[]> {
        if (!idsToLoad.length) return Promise.resolve([]);

        const callback = (
            boards: IRestBoardForOpenDialog[]
        ): IAppTab[] => {
            const loadedTabs = this.buildTabs(boards);
            if (loadedTabs.length < idsToLoad.length && !root.App.controller.isLoadingLastBoard) {
                this.showError(idsToLoad[0]);
            }
            root.App.controller.isLoadingLastBoard = false;
            return loadedTabs;
        }

        if (idsToLoad.length === 1) {
            const board = getBoard(store.getState(), idsToLoad[0]);
            if (board.id) {
                return Promise.resolve(callback([board] as IRestBoardForOpenDialog[]));
            }
        }

        root.App.controller.showSystemLoader(true, true, true);
        const boardTabsLoader = new BoardTabsLoader(idsToLoad);
        return boardTabsLoader
            .load()
            .then(callback);
    }

    buildTabs(
        boards: IRestBoardForOpenDialog[]
    ): IAppTab[] {
        const loadedTabs: IAppTab[] = boards.map((board) => ({
                driveId: board.driveId,
                id: board.id,
                isShared: board.isShared,
                logo: board.logo,
                logoColor: board.logoColor,
                name: board.name,
                starred: board.isStarred,
                status: board.status,
            })
        );
        return loadedTabs;
    }

    showError(
        boardId: TBoardId,
    ) {
        root.App.controller.hideSystemLoader();
        dispatch(snackbarErrorDefault({
            id: SNACKBAR_ID_BOARD_NOT_FOUND,
            text: getMessages().getText('error.dashboard.not_found'),
            timer: 600
        }));
        dispatch(closeRecentOpenBoard(boardId));
    }

    loadBoardArchiveToStore(boardId: TBoardId): Promise<void> {
        const boardLoader = new BoardLoader(boardId);
        const state = store.getState();
        const board = getBoard(state, boardId);
        const lists = getBoardListsByStatus(state, boardId, null);

        const listUpdates: ILists = {};
        let cardsLoaded: IRestCard[] = [];
        let cardsFixArchiveIds: TCardId[] = [];

        return Promise.all(lists.map((list => {
            if (list.archiveLoaded) return;

            return boardLoader.listLoadArchiveCards(list, board.version)
                .then((cards: IRestCard[]) => {
                    if (cards && cards.length) {
                        cardsLoaded = [...cardsLoaded, ...cards];
                    };
                    listUpdates[list.id] = {
                        archiveLoaded: true
                    }
                    if (list.status === TStatus.STATUS_ARCHIVE) {
                        return boardLoader.listArchiveLoadActiveCards(list, board.version)
                    }
                })
                .then((cards: IRestCard[]) => {
                    if (cards && cards.length) {
                        cardsLoaded = [...cardsLoaded, ...cards];
                        cards.forEach((card) => {
                            cardsFixArchiveIds.push(card.id);
                        });
                    }
                    return cardsLoaded;
                })
        })))
            .then(() => {
                if (cardsLoaded.length) {
                    const cardsMap = this.mapCardsToStore(cardsLoaded);

                    dispatch(modelUpdate({
                        cards: cardsMap.cardsToStore,
                        driveDocs: cardsMap.driveDocsToStore,
                        dependencies: cardsMap.dependenciesToStore
                    }))
                }
                if (cardsFixArchiveIds.length) {
                    const cardsFixArchive: ICards = {};
                    cardsFixArchiveIds.forEach((cardId) => {
                        cardsFixArchive[cardId] = {
                            status: TStatus.STATUS_ARCHIVE,
                        }
                    });
                    dispatch(cardsRestPatch(cardsFixArchive, false, true, false, true));
                    console.error(cardsFixArchiveIds.length);
                }
                dispatch(listsActionSet(listsUpdate(listUpdates)));
            })
    }

    loadBoardForDashboardToStore(boardId: TBoardId, noCards: boolean = false, noError: boolean = true, noLoading: boolean = false, ignoreDeleteStatus: boolean = false, ignoreRecentBoards: boolean = false) {
        const board = getBoard(store.getState(), boardId);
        let promise
        if (board && board.id && (!board.noCards || noCards)) {
            promise = Promise.resolve(board);
        } else {
            promise = this.loadBoardToStore(boardId, noCards, noError, noLoading, {forDashboard: true}, ignoreRecentBoards);
        }
        return promise
            .then(board => this.processBoardForDashboardStatus(board, ignoreDeleteStatus))
            .then(board => {
                if (board) {
                    this.syncNotifications(board.id);
                }
                if (!noLoading) {
                    root.App.controller.hideSystemLoader();
                }
                return board;
            })
    }

    processBoardForDashboardStatus(board: IBoard, ignoreDeleteStatus: boolean): Promise<IBoard> {
        const onClose = () => {
            root.App.router.navigate(root.App.router.getBaseUrl(), {trigger: true});
        }
        return this.processBoardStatus(board, onClose, ignoreDeleteStatus);
    }

    getRecentBoardsUpdate(): Promise<IRecentBoard[]> {
        let recentBoards = getUserRecentBoards(store.getState());
        if (!recentBoards.length) {
            return Promise.resolve(null);
        }
        const boardTabsLoader = new BoardTabsLoader(recentBoards.map(board => board.id));
        return boardTabsLoader.load()
            .then(boards => {
                /**
                 * получили обновлённые recentBoards
                 * надо сравнить их с текущими
                 */
                let isUpdate = false;
                let recentBoardsUpdate: IRecentBoard[] = [];
                recentBoards.forEach(recentBoard => {
                    let board = boards.find(board => board.id === recentBoard.id);
                    if (board) {
                        let boardUpdate: IRecentBoard = {
                            id: null,
                            name: null,
                        };
                        for (let k in ERecentBoardKeys) {
                            const key = k as keyof IRecentBoard;
                            if (recentBoard[key] || board[key]) { // logo:null например не добавляем, лишняя инфа
                                boardUpdate = {
                                    ...boardUpdate,
                                    [key]: board[key]
                                };
                                if (recentBoard[key] !== board[key]) {
                                    isUpdate = true;
                                }
                            }
                        }
                        recentBoardsUpdate.push(boardUpdate);
                    } else { // если доски нет, значит нет доступа
                        isUpdate = true;
                    }
                });
                if (isUpdate) {
                    return dispatch(authUserRecentBoardsPatch(getAuthUser(store.getState()).id, recentBoardsUpdate))
                        .then((user: IRestAuthUser) => {
                            return user && user.meta && user.meta.recentBoards;
                        });
                }
                return Promise.resolve(null);
            });
    }

}
