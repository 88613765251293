import { IBackup } from '../types';
import { AT_ADD_TO_LIST, IAddToListAction } from './types';

export const addToListAction = (
    backup: IBackup
): IAddToListAction => {
    return {
        type: AT_ADD_TO_LIST,
        backup
    }
};
