import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState } from '../../../../../../../types/types';
import { toggleFilter } from '../../../../../main/topBar/hocs/TopBarFilterButtonHOC/effects/toggleFilter';
import {
    FP_EDITED_FILTER_INITIAL_VALUE,
    MY_WORK_FILTER_BOARD_ID
} from '../../../../../aside_panel/filterPanelBoard/constants';
import {
    onAddNewFilter
} from '../../../../../aside_panel/filterPanelBoard/components/SavedSection/hocs/SavedFiltersListContainerHOC/events/onAddNewFilter';
import {
    filterPanelActionSetAction
} from '../../../../../aside_panel/filterPanelBoard/store/filterPanelBoards/actions/filterPanelActionSetAction';
import { editedFilterSet } from '../../../../../aside_panel/filterPanelBoard/store/filterPanel/actions/editedFilterSet';
import { showSnoozeBlocker } from '../../../../effects/showSnoozeBlocker';
import { getAuthUser } from '../../../../../../../store/model/authUser/selectors/getAuthUser';

export const onAdd = (): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const { allowMyWorkCustomFilters } = getAuthUser(getState());
        if (!allowMyWorkCustomFilters) {
            dispatch(showSnoozeBlocker({
                allow: 'allowMyWorkCustomFilters'
            }));
        } else {
            dispatch(toggleFilter());
            setTimeout(() => {
                dispatch(filterPanelActionSetAction(MY_WORK_FILTER_BOARD_ID, editedFilterSet(FP_EDITED_FILTER_INITIAL_VALUE)));
                dispatch(onAddNewFilter(MY_WORK_FILTER_BOARD_ID));
            }, 0);
        }
    }
    return action;
}
