const category = 'Team Workload';

export const SegmentTeamWorkloadEvent = {
    OPEN: { category, event: 'OPEN' },
    DISALLOW: { category, event: 'DISALLOW' },
    OPENED_SUMMARY: { category, event: 'OPENED_SUMMARY' },
    CLOSED_SUMMARY: { category, event: 'CLOSED_SUMMARY' },
    CARD_CREATED: { category, event: 'CARD_CREATED' }
}

export enum ESegmentTeamWorkloadOption {
    view = 'view'
}
