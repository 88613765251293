import { IChecklistItem } from '../../../../../../../store/model/checklists/checklist/types';
import { IApplicationState, TCardId, TChecklistId, TChecklistItemId } from '../../../../../../../types/types';
import { createDeepEqualSelector } from '../../../../../helpers/memoizeHelper';
import { getChecklistItem } from '../../../../../../../store/model/checklists/checklist/selectors/getChecklistItem';

type TgetChecklistItemSection = (
    state: IApplicationState,
    cardId: TCardId,
    checklistId: TChecklistId,
    checklistItemId: TChecklistItemId,
) => IChecklistItem;

export const getChecklistItemCreateSelector = (
): TgetChecklistItemSection => createDeepEqualSelector(
    getChecklistItem,
    checklistItem => checklistItem
);
