import { AT_CARD_ID_REMOVE } from './constants';
import { IMatchCardIdRemoveAction } from './types';
import { TCardId } from '../../../../../../../types/types';

export const matchCardIdRemoveAction = (
    cardId: TCardId
): IMatchCardIdRemoveAction => ({
        type: AT_CARD_ID_REMOVE,
        cardId
    }
);
