import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../types/types';
import { authUserSetRecentAccordion } from 'app/rest/effects/authUser/authUserSetRecentAccordion';
import { ERecentAccordion } from 'app/types/rest/IUserMeta';

export const updateRecentAccordion = (
    openedNavPanelAccordion: ERecentAccordion
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(authUserSetRecentAccordion(openedNavPanelAccordion))
    };
    return action;
};
