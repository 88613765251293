import { IExportDateProps, IExportForm } from './types';
import { Datepicker, Input, Select } from 'kui';
import * as React from 'react';
import { CLASS_BOARD_EXPORT } from '../../../constants';
import './_ExportDate.scss';
import {
    EExportRepeatMonthType,
    EExportRepeatType,
    LABEL_AT, LABEL_NEXT,
    LABEL_REPEAT_EVERY,
    LABEL_START_ON,
    REPEAT_TYPES
} from './constants';
import { exportReducer } from './exportReducer';
import { getMonthDayData } from '../../../../cardDetails/recurringSection/helpers/getMonthDayData';
import { ISelectActiveInheritedProps, ISelectOptionsArray } from 'kui/src/ui/select/types';
import { getAsidePanelTooltip } from '../../../../asidePanel/helpers/getAsidePanelTooltip';
import { TOOLTIP_CLEAR_DATE } from '../../../../cardDetails/recurringSection/components/wording';
import { AsidePanelTimeSelect } from '../../../../asidePanel/components/AsidePanelTimeSelect/AsidePanelTimeSelect';
import { DatepickerHOC } from '../../../../../base/components/Datepicker/hocs/DatepickerHOC/DatepickerHOC';

export function ExportDate ({
    boardId,
    exportForm,
    isExports,
    updateForm
}: IExportDateProps) {
    const className = CLASS_BOARD_EXPORT + '-date';
    const classTime = className + '__time';
    const classNumber = className + '__number';
    const classPeriod = className + '__period';

    const label = isExports ? LABEL_NEXT : LABEL_START_ON;

    let [form, updateFormState] = React.useReducer(exportReducer, {
        boardId: boardId,
        repeatEvery: 1,
        repeatType: EExportRepeatType.DAY,
        monthDay: EExportRepeatMonthType.DAY_OF_MONTH,
        repeats: 1
    });

    const onUpdate = (update: IExportForm) => {
        updateFormState(update);
        updateForm({...form, ...update});
    };

    const types: ISelectOptionsArray = REPEAT_TYPES.map(type => ({
        value: type.type,
        text: type.title + (form.repeatEvery > 1 ? 's' : ''),
    }));
    const typeIndex = types.findIndex(option => option.value === form.repeatType);

    const onTypeChange = (event: ISelectActiveInheritedProps) => {
        if (event.item) {
            const repeatType = REPEAT_TYPES.find(type => type.type === event.item.value).type;
            onUpdate({ repeatType });
        }
    };

    React.useEffect(() => {
        updateFormState(exportForm);
    }, [exportForm]);

    const onStartDateChange = (
        useDefaultTime = true
    ) => (
        startDate: Date
    ) => {
        if (startDate && !form.startDate && useDefaultTime) {
            startDate.setHours(0, 0);
            if (startDate < new Date()) {
                startDate.setHours(new Date().getHours() + 1);
            }
        }
        if (startDate && form.startDate && startDate.getTime() === form.startDate.getTime()) return;
        onUpdate( {
            startDate,
            monthDayData: getMonthDayData(startDate)
        });
    };

    const getRepeatEvery = (
        target: HTMLInputElement
    ): number => {
        if (!target.value) return null;
        const repeatEvery = Number(target.value);
        return repeatEvery;
    };

    const onRepeatEveryChange = (
        event: React.SyntheticEvent
    ) => {
        updateFormState({ repeatEvery: getRepeatEvery(event.target as HTMLInputElement) });
    };

    const onRepeatEveryBlur = (
        event: React.FocusEvent
    ) => {
        const value = getRepeatEvery(event.target as HTMLInputElement);
        if (value >= 1) {
            onUpdate({ repeatEvery: value });
        } else {
            onUpdate({ repeatEvery: 1 });
        }
    };

    return (
        <div className={className}>
            <DatepickerHOC
                isSingle
                selectedFromDate={form.startDate}
                onChange={onStartDateChange()}
                minDate={new Date()}
                isClearable={false}
                fromLabel={label}
            />
            <AsidePanelTimeSelect
                className={classTime}
                date={form.startDate}
                label={LABEL_AT}
                onChange={onStartDateChange(false)}
            />
            <Input
                className={classNumber}
                label={LABEL_REPEAT_EVERY}
                value={String(form.repeatEvery)}
                autosize={false}
                type={'number'}
                onChange={onRepeatEveryChange}
                onBlur={onRepeatEveryBlur}
            />
            <Select
                className={classPeriod}
                active={typeIndex}
                editable={false}
                isFitWindow={true}
                options={types}
                variant={'arrow'}
                onChange={onTypeChange}
            />
        </div>
    )
}
