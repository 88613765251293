import * as React from 'react';
import { IRestCardCreateActivity } from '../../../../../../../types/rest/activity/IRestCardCreateActivity';
import { IActivityProps } from '../../../../boardDetails/activity/TabActivity/types';
import { TabActivityContext } from '../../../../boardDetails/activity/TabActivity/components/TabActivity/constants';
import { TActivity } from '../../../../../../../store/activities/types';
import {
    ActivityItemBody
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemBody/ActivityItemBody';
import {
    ActivityItemName
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemName/ActivityItemName';
import { ActivityHeader } from '../../../../boardDetails/activity/TabActivity/components/ActivityHeader/ActivityHeader';

export function CardActivityRecurring ({
    activity
}: IActivityProps<IRestCardCreateActivity>) {
    const { onOpenCard } = React.useContext(TabActivityContext);
    const { label, card } = activity as TActivity;
    return (
        <ActivityItemBody
            header={<ActivityHeader icon={'arrow-circle'} label={label} />}
            activity={activity}
            onClick={() => onOpenCard(card.id, activity.id)}
        >
            <ActivityItemName name={activity.oldValue} />
        </ActivityItemBody>
    );
}
