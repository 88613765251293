const category = 'Multiselect';

export const SegmentMultiSelectEvent = {
    OPENED: {category, event: 'Multilselect opened'},
    CARD_REMOVED: {category, event: 'Card removed from multiselect'},
    DELETE_CARDS_CHOSEN: {category, event: 'Delete cards chosen'},
    CARDS_MARKED_DONE: {category, event: 'Cards marked as done'},
    COLOUR_TAG_OPENED: { category, event: 'Colour tags dropdown menu opened in multiselect' },
    COLOUR_TAG_ADDED: {category, event: 'Colour tag added in multiselect'},
    COLOUR_TAG_REMOVED: {category, event: 'Colour tag removed in multiselect'},
    COLOUR_TAG_CLEAR: { category, event: 'No colour tags in multiselect' },
    COLOUR_TAG_EDIT: { category, event: 'Edit colour tags clicked in multiselect, boards details opened' },
    BACKGROUND_COLOR_OPENED: { category, event: 'Cards colour dropdown menu opened in multiselect' },
    BACKGROUND_COLOR_CHANGED: { category, event: 'Cards colour changed in multiselect' },
    BACKGROUND_COLOR_SHOW_MORE_CLICKED: { category, event: 'Cards colour show more clicked in multiselect' },
    USER_ASSIGNED: {category, event: 'User assigned in multiselect'},
    USER_UNASSIGNED: {category, event: 'User unassigned in multiselect'},
    PRIORITY_APPLIED: {category, event: 'Priority applied in multiselect'},
    EXISTING_TAG_APPLIED: {category, event: 'Existing tag applied in multiselect'},
    NEW_TAG_APPLIED: {category, event: 'Newly created tag applied in multiselect'},
    SHOW_GANT: {category, event: 'Show on gantt in multiselect'},
    OPEN_MOVE_TO_DROPDOWN: {category, event: 'Open move to dropdown in multiselect'},
    MOVE_TO_CARD_MOVED: {category, event: 'Move to - cards moved'},
    ARCHIVE_CARDS_CLICKED: {category, event: 'Archive cards clicked'},
    UNARCHIVE_CARDS_CLICKED: {category, event: 'Unarchive cards clicked'},
    START_DATE_SELETCED: {category, event: 'Start date selected in multiselect'},
    DUE_DATE_SELETCED: {category, event: 'Due date selected in multiselect'},
    START_DATE_TIME_SELETCED: {category, event: 'Start date time selected in multiselect'},
    DUE_DATE_TIME_SELETCED: {category, event: 'Due date time selected in multiselect'},

    START_DATE_CLICKED_CLEAR: { category, event: 'Start date clear clicked in multiselect' },
    DUE_DATE_CLICKED_CLEAR: { category, event: 'Due date clear clicked in multiselect' },
    START_DATE_OPENED: { category, event: 'Start date dropdown opened in multiselect' },
    DUE_DATE_OPENED: { category, event: 'Due date dropdown opened in multiselect' },
    START_TIME_CLICKED: { category, event: 'Start date time dropdown opened in multiselect' },
    DUE_TIME_CLICKED: { category, event: 'Due date time dropdown opened in multiselect' },
    START_TIME_CHOOSE_UNEVEN: { category, event: 'Uneven start date time applied in multiselect' },
    DUE_TIME_CHOOSE_UNEVEN: { category, event: 'Uneven due date time applied in multiselect' },

    ESTIMATE_ADDED: {category, event: 'Estimate added in multiselect'},
    ESTIMATE_ERROR: {category, event: 'estimate invalid value in multiselect'},

    COPY_CHECKLIST_OPENED: { category, event: 'Copy checklists dropdown menu opened in multiselect' },
}

export enum SegmentMultiSelectOptions {
    STATUS = 'status',
    TARGET = 'target'
}

export enum SegmentMultiSelectOptionStatusValue {
    ON = 'on',
    OFF = 'off',
}

export enum SegmentMultiSelectColorTargetValue {
    BOARD_COLORS = 'Board colors',
    ALL_COLORS = 'All colors'
}
