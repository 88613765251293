import {Dispatch, ThunkAction} from 'app/types/actions';
import {TBoardId} from 'app/types/types';
import {onImport} from 'app/view/react_components/aside_panel/boardDetails/details/ImportBoard/hocs/BoardImportHOC/effects/onImport';
import {SegmentBoardEvent, segmentTrackAction} from 'app/middlewares/segment';

export const onClick = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(segmentTrackAction(SegmentBoardEvent.IMPORT_TO_BOARD));
        dispatch(onImport(boardId))
    }
    return action;
}
