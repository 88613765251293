import { Button, Icon } from 'kui';
import * as React from 'react';
import './_UpdateCounter.scss';
import { IUpdateCounterProps } from './types';

export const UpdateCounter = ({
    updates,
    ...attributes
}: IUpdateCounterProps) => {
    const className = 'update-counter';

    return (
        <Button
            className={`
                ${className}
                ${attributes && attributes.className || ''}
            `}
            variant={'icon'}
            {...attributes}
        >
            <Icon size={24} xlink={'activity'}/>
            <div className={className + '__updates'}>{updates}</div>
        </Button>
    );
}
