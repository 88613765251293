import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { rollback } from '../../effects/rollback';

export const onCancel = (): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(rollback());
    };
    return action;
};
