import { WebinarQuestionEvent } from '../../../../../middlewares/segment/trackEntities/webinarQuestionEvent';
import { TWebinarQuestions } from '../components/WebinarDialog/types';

export enum EWebinarAnswers {
    yes = 'yes',
    no = 'no'
}
export const WEBINAR_QUESTIONS: TWebinarQuestions = {
    webinar: { // Q1
        answers: {
            [EWebinarAnswers.yes]: {
                answer: 'Yes, send me a link, please',
                event: WebinarQuestionEvent.WEBINAR_YES
            },
            [EWebinarAnswers.no]: {
                answer: 'No, I prefer to explore by myself',
                event: WebinarQuestionEvent.WEBINAR_NO
            }
        },
    },
}
