import { AT_ROLE_ADD, IRoleAddAction } from './types';
import { ICardAssigneeRole } from '../../../../types/model';

export const addRoleAction = (
    role: ICardAssigneeRole
): IRoleAddAction => {
    return {
        type: AT_ROLE_ADD,
        role
    }
};
