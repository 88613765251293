export enum VirtualPage {
    SIGNED_UP = '/vp/dashboard/new-user-signed-up',
    PERMISSIONS_BUTTON_CLICKED = '/vp/dashboard/registration/permissions-button-clicked',
    CREATE = '/vp/dashboard/card-created',
    PERMISSION_SCREEN = '/vp/dashboard/registration/permissions-screen-showed',
    SIGN_IN_SHOWED = '/vp/dashboard/registration/sign-in-button-showed',
    LOGIN_PRESSED = '/vp/dashboard/registration/sign-in-button-clicked',
    NEW_USER_FIRST_SCREEN_LIST = '/vp/new-user-first-screen/list',
    NEW_USER_FIRST_SCREEN_BOARD = '/vp/new-user-first-screen/board',
    NEW_USER_FIRST_SCREEN_TEAM = '/vp/new-user-first-screen/with-my-team',
    NEW_USER_FIRST_SCREEN_PERSONAL = '/vp/new-user-first-screen/for-my-personal-usage',
    CARD_FROM_FREE_TRIAL = '/vp/dashboard/card-form-start-trial-clicked'
}
