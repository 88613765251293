import { TSubscriptionId, IRestSubscription } from '../../../../../types/rest/IRestSubscription';
import { fetchHandler } from '../../../../../util/fetchHandler';
import { Util } from '../../../../../util/util';
import { getRestHeadersGet } from '../../../../../rest/helpers/getRestHeadersHelper';
import { REST_SUBSCRIPTIONS } from './constants';
import { Dispatch, ThunkAction } from '../../store/subscriptionAdmin/types';

export const getByParentIdRest = (
    subscriptionId: TSubscriptionId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        return fetchHandler<IRestSubscription>(
            Util.getApiUrl(REST_SUBSCRIPTIONS + 'byParentId/' + subscriptionId),
            getRestHeadersGet()
        );
    };
    return action;
};
