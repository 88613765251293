import * as React from 'react';
import {SVG} from './../../../../../react-utils';
import { markdown } from "../../../../../helper/markdownHelper";
import { stripHtml } from "../../helpers/stripHtmlHelper";

const MoveToElements = ({
    addFirst,
    elements,
    disabledElements,
    onClick,
    loadingElement,
    selectedElements,
    elementName,
    onFirstSelected,
    firstName
}) => {
    return (
        <ul className="move-card__boards">
            {
                addFirst &&
                <li className={'move-card__list move-card__list--first'} onClick={onFirstSelected}>
                    <span className="move-card__list-name">{firstName}</span>
                    {
                        loadingElement === 0 &&
                        <div className="loader js-loader" title="Loading">
                            <i className="loader__blink"/>
                        </div>
                    }
                    {
                        selectedElements.indexOf(0) > -1 &&
                        <span className="icon move-card__list-ico">
                            <SVG xlink="svgicon--check" />
                        </span>
                    }
                </li>
            }
            {
                elements.map((el, i) => {
                    const isDisabled = disabledElements.indexOf(el.id) > -1;
                    const itemClass = `move-card__list ${isDisabled ? 'move-card__list--disabled' : ''}`;

                    return (
                        <li className={itemClass} key={i} onClick={() => onClick(el)}>
                            <span className="move-card__list-name">
                                { stripHtml(markdown(el[elementName])) }
                            </span>
                            {
                                loadingElement === el.id &&
                                <div className="loader js-loader" title="Loading">
                                    <i className="loader__blink"/>
                                </div>
                            }
                            {
                                selectedElements.indexOf(el.id) > -1 &&
                                <span className="icon move-card__list-ico">
                                    <SVG xlink="svgicon--check" />
                                </span>
                            }
                        </li>
                    );
                })
            }
            {
                !addFirst && !elements.length &&
                <li className="move-card__list">¯\_(ツ)_/¯</li>
            }
        </ul>
    );
};

MoveToElements.defaultProps = {
    elementName: 'name',
    addFirst: false,
    firstName: null,
    loadingElement: null,
    selectedElements: [],
    disabledElements: [],
    elements: [],
    onClick: () => {},
    onFirstSelected: () => {}
};

export {MoveToElements};
