import { IGetState, TBoardId } from '../../../types/types';
import { EThemeBackgroundOption } from '../../../types/rest/IRestTheme';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { postFile } from './api/postFile';

export const boardThemeUploadImage = (
    boardId: TBoardId,
    backgroundOption: EThemeBackgroundOption,
    file: File
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(postFile(boardId, backgroundOption, file));
    };
    return action;
}
