import { IApplicationState } from '../../../../types/types';
import { ISorting } from '../types';
import { DEFAULT_SORTING } from '../../constants';

export const getSorting = (
    state: IApplicationState,
    listId: number
): ISorting => {
    return state.model.lists[listId] && state.model.lists[listId].sorting || DEFAULT_SORTING;
};
