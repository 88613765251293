import { IGetState, TCardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { copyRest } from './api/copyRest';
import { IRestCard } from '../../../../types/rest/IRestCard';
import { ICardCopyRestOptions } from './api/types';
import { ICards } from '../../../../store/model/cards/types';
import { cardsAdd } from '../../../../store/model/actionCreators/cardsAdd';
import { sendRealTimeStoreActionByCardId } from '../../../../view/react_components/base/helpers/realTimeHelperTS';
import { sendCardStatWithIncrement } from '../../../../helper/comet/stat_helper_ts';
import { getList } from '../../../../store/model/list/selectors/getList';
import { listUpdateMinMaxOrderNumber } from '../../list/listUpdateMinMaxOrderNumber';

export const cardCopy = (
    cardId: TCardId,
    sendStatistic: boolean = true,
    options: ICardCopyRestOptions = {}
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(copyRest(cardId, options)).then((cardResponse: IRestCard) => {
            const cards: ICards = {
                [cardResponse.id]: cardResponse
            };
            dispatch(cardsAdd(cards));
            dispatch(sendRealTimeStoreActionByCardId(cardId, cardsAdd(cards)));
            dispatch(listUpdateMinMaxOrderNumber(cardResponse.listId, [cardResponse.orderNumber]))

            if (sendStatistic) {
                dispatch(sendCardStatWithIncrement(getList(getState(), cardResponse.listId), 1, 0));
            }
            return cardResponse;
        });
    };
    return action;
};
