import { IApplicationState } from '../../../../../../types/types';
import { connect } from 'react-redux';
import { TrialExtend } from '../../containers/trialExtend/TrialExtend';
import { ITrialExtendDispatchProps, ITrialExtendStateProps } from '../../containers/trialExtend/types';
import { onOpenTrialExtend } from './events/onOpen';
import { onCloseTrialExtend } from './events/onClose';
import { onSubmitTrialExtend } from './events/onSubmit';

const mapStateToProps = (
    state: IApplicationState
): ITrialExtendStateProps => {
    return {}
};

const mapDispatchToProps = (
    dispatch: any
): ITrialExtendDispatchProps => ({
    onOpen: () => dispatch(onOpenTrialExtend()),
    onClose: () => dispatch(onCloseTrialExtend()),
    onSubmit: (feedback: string) => dispatch(onSubmitTrialExtend(feedback)),
});

export const TrialExtendHOC = connect(mapStateToProps, mapDispatchToProps)(TrialExtend);
TrialExtendHOC.displayName = 'TrialExtendHOC';
