import { EAsidePanelProperty } from '../../react_components/aside_panel/asidePanel/components/AsidePanel/types';
import { IApplicationState, TCardId } from 'app/types/types';
import { getBoardIdByCardId } from 'app/store/model/selectors/getBoardIdByCardId';
import {
    getBoardCardCustomPropertyOptions
} from 'app/store/model/board/selectors/getBoardCardCustomPropertyOptions';
import { getCardCustomProperties } from 'app/store/model/selectors/getCardCustomProperties';
import { getBoardCardRequiredProperties } from 'app/store/model/board/selectors/getBoardCardRequiredProperties';
import { getCard } from 'app/store/model/selectors/getCard';

export const checkIfRequiredPropertiesAreFilled = (
    state: IApplicationState,
    cardId: TCardId
) => {
    const card = getCard(state, cardId);
    if (!card) return;

    const boardId = getBoardIdByCardId(state, cardId);

    const requiredProperties = getBoardCardRequiredProperties(state, boardId);
    let isFilled = true;

    if (
        requiredProperties.has(EAsidePanelProperty.CARD_DESCRIPTION) &&
        (!card.description || !card.description.length)
    ) {
        isFilled = false;
    }

    if (
        requiredProperties.has(EAsidePanelProperty.CARD_ASSIGNEES) &&
        (!card.assignees || !card.assignees.length)
    ) {
        isFilled = false;
    }

    if (
        requiredProperties.has(EAsidePanelProperty.CARD_START_DATE) &&
        !card.startDate
    ) {
        isFilled = false;
    }

    if (
        requiredProperties.has(EAsidePanelProperty.CARD_DUE_DATE) &&
        !card.dueDate
    ) {
        isFilled = false;
    }

    if (
        requiredProperties.has(EAsidePanelProperty.CARD_ATTACHMENTS) &&
        (!card.driveDocIds || !card.driveDocIds.length)
    ) {
        isFilled = false;
    }

    if (
        requiredProperties.has(EAsidePanelProperty.CARD_CHECKLIST) &&
        (!card.checklistStats || !card.checklistStats.totalCount)
    ) {
        isFilled = false;
    }

    if (
        requiredProperties.has(EAsidePanelProperty.CARD_TAGS) &&
        (!card.tags || !card.tags.length)
    ) {
        isFilled = false;
    }

    const cardRequiredCustomProperties = getBoardCardCustomPropertyOptions(state, boardId)
        .filter(option => option.isRequired);
    if (cardRequiredCustomProperties) {
        const cardCustomProperties = getCardCustomProperties(state, cardId);
        cardRequiredCustomProperties.forEach(({option}) => {
            const property = cardCustomProperties && cardCustomProperties[option];
            if (!property || !property.value) {
                isFilled = false;
            }
        });
    }

    return isFilled;
}
