import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TFilterId } from '../../../../../../../../types/types';
import { MY_WORK_FILTER_BOARD_ID } from '../../../../../../aside_panel/filterPanelBoard/constants';
import {
    savedFilterSelector
} from '../../../../../../aside_panel/filterPanelBoard/store/selectors/savedFilterSelector';
import {
    savedFilterActionsSet
} from '../../../../../../aside_panel/filterPanelBoard/store/savedFilters/actions/savedFilterActionsSet';
import { metaSet } from '../../../../../../aside_panel/filterPanelBoard/store/filter/actions/metaSet';
import { patchFilter } from '../../../../../../aside_panel/filterPanelBoard/effects/patchFilter';
import {
    savedFilterSet
} from '../../../../../../aside_panel/filterPanelBoard/store/savedFilters/actions/savedFilterSet';
import {
    removeFilterFromSortedSavedFilterIds
} from '../../../../../../aside_panel/filterPanelBoard/components/SavedSection/hocs/effects/removeFilterFromSortedSavedFilterIds';
import {
    addFilterToSortedSavedFilterIds
} from '../../../../../../aside_panel/filterPanelBoard/components/SavedSection/hocs/effects/addFilterToSortedSavedFilterIds';

export const onHide = (
    filterId: TFilterId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const filter = savedFilterSelector(getState(), filterId);
        dispatch(savedFilterActionsSet(filterId, metaSet({ isMyWorkShowOnBar: false })));
        const newFilter = { ...filter, meta: { ...filter.meta, isMyWorkShowOnBar: false }};
        dispatch(patchFilter(filterId, newFilter)).then(() => {
            dispatch(savedFilterSet(filterId, newFilter));
            dispatch(removeFilterFromSortedSavedFilterIds(MY_WORK_FILTER_BOARD_ID, filterId));
            dispatch(addFilterToSortedSavedFilterIds(MY_WORK_FILTER_BOARD_ID, filterId));
        });
    }
    return action;
}
