import { IApplicationState, IGetState, TBoardId, TListId } from '../../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { getBoard } from '../../../../../../../../../store/model/selectors/getBoardById';
import { EDashboardStatusListType, IDashboardStatusLists } from '../../../../../../../../../types/rest/IRestDashboard';
import { boardSetStatusLists } from '../../../../../../../../../rest/effects/board/boardSetStatusLists';
import { IList } from '../../../../../../../../../store/model/list/types';
import { SegmentBoardEvent, segmentTrackAction } from '../../../../../../../../../middlewares/segment';
import {
    SegmentBoardOption,
    SegmentBoardOptionStatusValue
} from '../../../../../../../../../middlewares/segment/trackEntities/boardEvents';
import { getBoardListsByStatus } from '../../../../../../../../../store/model/selectors/getBoardListsByStatus';
import { getAuthUser } from '../../../../../../../../../store/model/authUser/selectors/getAuthUser';
import { showSnoozeBlocker } from '../../../../../../../base/effects/showSnoozeBlocker';
import { root } from '../../../../../../../../../store/constants';

export const resetDoneList = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const board = getBoard(state, boardId);
        const { allowStatusMapping } = getAuthUser(state);
        let statusLists: IDashboardStatusLists = {...board.statusLists};
        if (statusLists && statusLists.doneId) {
            if (
                !allowStatusMapping &&
                root.App.controller.allowCounterSnackbarHelper.isShowSnackbar('allowStatusMapping')
            ) {
                return Promise.resolve(false);
            } else {
                statusLists[EDashboardStatusListType.DONE] = 0;
                dispatch(segmentTrackAction(SegmentBoardEvent.DONE_LIST_TOGGLE_CLICKED, {
                    name: SegmentBoardOption.STATUS,
                    value: SegmentBoardOptionStatusValue.OFF}));
                return dispatch(boardSetStatusLists(boardId, statusLists)).then(() => {
                    return Promise.resolve(true);
                });
            }
        } else {
            if (allowStatusMapping) {
                statusLists[EDashboardStatusListType.DONE] = getDefaultDoneListId(state, boardId, statusLists);
                dispatch(segmentTrackAction(SegmentBoardEvent.DONE_LIST_TOGGLE_CLICKED, {
                    name: SegmentBoardOption.STATUS,
                    value: SegmentBoardOptionStatusValue.ON}));
                dispatch(boardSetStatusLists(boardId, statusLists));
            } else {
                dispatch(showSnoozeBlocker({ allow: 'allowStatusMapping' }));
            }
        }
    };
    return action;
};

const getDefaultDoneListId = (
    state: IApplicationState,
    boardId: TBoardId,
    statusLists: IDashboardStatusLists
): TListId => {
    const lists: IList[] = getBoardListsByStatus(state, boardId, null);
    const list: IList = lists.find((list) => list.name.toLowerCase() === 'done');
    if (list && !Object.values(statusLists).includes(list.id)) {
        return list.id;
    }
    for(let i=0; i<lists.length; i++) {
        if (!Object.values(statusLists).includes(lists[i].id)) {
            return lists[i].id;
        }
    }
    return -1;
}
