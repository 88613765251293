import setSelectedCalendars from './../store/actions/setSelectedCalendars';
import filterCalendars from './../helpers/filterCalendars';
import * as _ from 'underscore';
import {
    removeCardEventFromGoogleCalendarFail,
    removeCardEventFromGoogleCalendarRequest,
    removeCardEventFromGoogleCalendarSuccess
} from './../store/actions/removeCardEventFromGoogleCalendar'
import { deleteCalendarEvent } from '../../../../../../../rest/effects/card/calendar/deleteCalendarEvent';

const removeCardEventFromGoogleCalendar = calendar => {
    return (dispatch, getState) => {
        const event = getState().pushToGoogleCalendar.selectedAction;
        const selectedCardId = getState().pushToGoogleCalendar.cardId;
        const selectedActionType = getState().pushToGoogleCalendar.selectedAction;

        const filteredCalendars = filterCalendars(selectedCardId, selectedActionType);
        const pushedIds = _.pluck(filteredCalendars, 'calendarId');

        dispatch(setSelectedCalendars(_.without(pushedIds, calendar.id)));
        dispatch(removeCardEventFromGoogleCalendarRequest(calendar.id));

        dispatch(deleteCalendarEvent(selectedCardId, calendar.id, event)).then(() => {
            dispatch(removeCardEventFromGoogleCalendarSuccess());
        }).catch(e => {
            dispatch(removeCardEventFromGoogleCalendarFail());
            dispatch(setSelectedCalendars(pushedIds));
        })
    }
};

export default removeCardEventFromGoogleCalendar;
