import { Dispatch } from '../../../../../types/actions';
import { ESnackbarPosition, SnackbarVariant } from '../../store/types';
import { SNACKBAR_BUTTON_CLOSE, SNACKBAR_BUTTON_UNDO } from '../constants';
import { getDefaultActionEvent } from '../helpers/getDefaultActionEvent';
import { addUpdateSnackbarWithBubble } from '../addUpdateSnackbarWithBubble/addUpdateSnackbarWithBubble';
import { ISnackbarUndo } from './types';

export const snackbarUndoDefault = (
    options: ISnackbarUndo,
    positionLeft: boolean
) => {
    return (dispatch: Dispatch) => {
        const shouldUseControlsDefault = options.controls === undefined;
        if (shouldUseControlsDefault) {
            options.controls = [
                {
                    action: () => {
                        dispatch(getDefaultActionEvent(options.id, options.actionUndo));
                    },
                    title: SNACKBAR_BUTTON_UNDO
                },
                {
                    action: () => {
                        dispatch(getDefaultActionEvent(options.id, options.actionApply));
                    },
                    title: SNACKBAR_BUTTON_CLOSE,
                    icon: 'close'
                },
            ];
        }

        dispatch(addUpdateSnackbarWithBubble({
            className: options.className,
            id: options.id,
            controls: options.controls,
            text: options.text,
            title: null,
            timer: options.timer,
            variant: SnackbarVariant.UNDO,
            position: positionLeft ? ESnackbarPosition.left : ESnackbarPosition.center,
            onTimer: () => {
                dispatch(getDefaultActionEvent(options.id, options.actionApply));
                if (options.actionTimer)
                    options.actionTimer();
            },
            onHide: () => {
                dispatch(getDefaultActionEvent(options.id, options.actionApply))
            }
        }));
    }
};
