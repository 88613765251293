import { createDeepEqualSelector } from '../../../../../../../helpers/memoizeHelper';
import { getBoardCardCustomProperties } from '../../../../../../../../../store/model/board/selectors/getBoardCardCustomProperties';
import { IApplicationState, TBoardId } from '../../../../../../../../../types/types';
import { ICustomPropertiesElement } from '../../../../../../cardDetails/CustomPropertiesSection/components/CustomPropertiesElements/types';

type TgetBoardCustomProperties = (
    state: IApplicationState,
    boardId: TBoardId
) => ICustomPropertiesElement[];

export const getBoardCustomProperties: TgetBoardCustomProperties = createDeepEqualSelector(
    getBoardCardCustomProperties,
    (boardCustomProperties) => {
        const elements = Object.values(boardCustomProperties).map(property => {
            return {
                ...property,
                orderNumber: property.orderNumber || 0,
            }
        }).sort((prop1, prop2) => {
            return prop2.orderNumber - prop1.orderNumber; // больший order сверху
        });
        return elements;
    }
)
