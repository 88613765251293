'use strict';

App.Helpers.BenchmarkHelper = {
    framesArr: [],

    scrollBoard: ({ destination, board }) => new Promise((resolve) => {
        board = board || document.querySelector('.board');
        const scrollDestination = board.classList.contains('list-view__body')
            ? 'scrollTop'
            : 'scrollLeft';
        let frames = 0;
        const speed = 3; // pixels per millisecond
        const change = destination - board[scrollDestination];
        const duration = Math.abs(change) / speed;
        let prevTime;
        let startTime;
        const render = (timestamp) => {
            startTime = startTime || timestamp;
            prevTime = prevTime || timestamp;

            const elapsed = timestamp - startTime;
            const elapsedFromPrevFrame = timestamp - prevTime;
            const next = Math.sign(change) * speed * elapsedFromPrevFrame;
            board[scrollDestination] += next;
            prevTime = timestamp;
            frames++;
            if (elapsed >= duration) {
                this.framesArr.push(frames);
                resolve(frames);
            } else {
                requestAnimationFrame(render);
            }
        }
        requestAnimationFrame(render);
    }),

    benchmark: () => {
        let board = document.querySelector('.board-view');
        let toScroll;
        if (!board) {
            board = document.querySelector('.list-view__body');
        }
        toScroll = board.scrollWidth;

        const run = (destination) => App.Helpers.BenchmarkHelper.scrollBoard({ destination, board });

        const startTime = performance.now()

        this.framesArr = [];
        
        run(toScroll)
        .then(() => run(0))
        .then(() => run(toScroll))
        .then(() => run(0))
        .then(() => run(toScroll))
        .then(() => run(0))
        .then(() => run(toScroll))
        .then(() => run(0))
        .then(() => run(toScroll))
        .then(() => run(0))
        .then(() => run(toScroll))
        .then(() => run(0))
        .then(() => run(toScroll))
        .then(() => run(0))
        .then(() => run(toScroll))
        .then(() => run(0))
        .then(() => run(toScroll))
        .then(() => run(0))
        .then(() => run(toScroll))
        .then(() => run(0))
        .then(() => {
            const endTime = performance.now();
            let min = Infinity;
            let max = 0;
            const frames = this.framesArr.reduce((acc, item) => {
                if (item < min) min = item;
                if (item > max) max = item;
                return acc + item;
            }, 0);
            this.framesArr.forEach((item, index) => {
                let color = 'initial';
                if (item === min) color = 'red';
                if (item === max) color = 'green';
                let indexSpace = '';
                let itemSpace = '';
                if (index < 100) indexSpace += ' ';
                if (index < 10) indexSpace += ' ';
                if (item < 100) itemSpace += ' ';
                if (item < 10) itemSpace += ' ';
                let itemGraph = '';
                for (let i = 0; i < item; i++) itemGraph += '|';
                console.log(`%c ${indexSpace}${index}: %c ${itemSpace}${item} ${itemGraph}`, `color:initial`, `color:${color}`);
            });
            console.log('average fps: ', (1000 * frames / (endTime - startTime)).toFixed(3));
        });
    },
};
