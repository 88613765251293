import { TCardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { ICards } from '../../../../store/model/cards/types';
import { cardsRestPatch } from './api/helper/cardsRestPatch';

export const cardRemoveCardNumber = (
    cardId: TCardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        const cards: ICards = {
            [cardId]: {
                cardNumber: ''
            }
        };

        return dispatch(cardsRestPatch(cards));
    };
    return action;
};
