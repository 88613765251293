import { connect } from 'react-redux';
import { TopBarInfoBlock } from '../../containers/TopBarInfoBlock/TopBarInfoBlock';
import { IApplicationState } from '../../../../../../types/types';
import {
    ITopBarInfoBlockDispatchProps,
    ITopBarInfoBlockStateProps
} from '../../containers/TopBarInfoBlock/types';
import {
    getCurrentEditingFilterIdSelector
} from '../../../../aside_panel/filterPanelBoard/store/selectors/getCurrentEditingFilterIdSelector';
import {
    filterPanelBoardsSelector
} from '../../../../aside_panel/filterPanelBoard/store/selectors/filterPanelBoardsSelector';
import { filterSelector } from '../../../../aside_panel/filterPanelBoard/store/selectors/filterSelector';
import {
    FP_KEY_SHOW_FILTER_DONE_CARDS,
    FP_KEY_SHOW_FILTER_OVERDUE_CARDS
} from '../../../../aside_panel/filterPanelBoard/constants';
import { ITopBarInfoBlockHOCProps } from './types';
import { EFilterButtonsType } from '../../store/topBar/types';
import { onFilterButtonClicked } from './events/onFilterButtonClick';
import { getAuthUser } from '../../../../../../store/model/authUser/selectors/getAuthUser';
import { getAsidePanel } from 'app/view/react_components/aside_panel/asidePanel/store/selectors/getAsidePanel';
import { getCardCountersCreateSelector } from './selectors/getCardCounters';

// const analyze = new Analyze('TopBarInfoBlockHOC');
// TopBarInfoBlockHOC average: 0.060 ms, calls: 96, total: 5.800 ms // открыл-закрыл 5 карт
// TopBarInfoBlockHOC average: 0.038 ms, calls: 102, total: 3.900 ms // reselect

const mapStateToProps = () => {
    const _getCardCounters = getCardCountersCreateSelector();

    return (
        state: IApplicationState,
        props: ITopBarInfoBlockHOCProps
    ): ITopBarInfoBlockStateProps => {
        // analyze.start();

        const { boardId } = props;
        const { initials, permissionId, photoUrl }= getAuthUser(state);

        const filterId = getCurrentEditingFilterIdSelector(filterPanelBoardsSelector(state, boardId));
        const filter = filterSelector(state, boardId, filterId);
        const filterPanelBoards = filterPanelBoardsSelector(state, boardId);
        let active: EFilterButtonsType = null;
        if (filterPanelBoards.isTopBarFiltrationEnabled) {
            if (filter.assignees.length === 1 && filter.assignees[0] === permissionId) {
                active = EFilterButtonsType.MY_TASKS;
            } else if (filter.show.length) {
                if (filter.show.includes(FP_KEY_SHOW_FILTER_DONE_CARDS)) {
                    active = EFilterButtonsType.DONE;
                } else if (filter.show.includes(FP_KEY_SHOW_FILTER_OVERDUE_CARDS)) {
                    active = EFilterButtonsType.OVERDUE;
                }
            }
        }

        const cardCounters = _getCardCounters(state, boardId);

        const isAsidePanelOpened = !!getAsidePanel(state).width;

        // analyze.finish();

        return {
            active,
            cardCounters,
            isAsidePanelOpened,
            userId: permissionId,
            userInitials: initials,
            userPhotoIrl: photoUrl,
        }
    };
};

const mapDispatchToProps = (dispatch: any): ITopBarInfoBlockDispatchProps => ({
    onFilterButtonClick: (filterType: EFilterButtonsType, isActive) => dispatch(onFilterButtonClicked(filterType, isActive))
});

export const TopBarInfoBlockHOC = connect(mapStateToProps, mapDispatchToProps)(TopBarInfoBlock);
TopBarInfoBlockHOC.displayName = 'TopBarInfoBlockHOC';
