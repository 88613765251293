import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { generateClickedSegmentEvent } from '../../effects/segmentEvents';
import {
    SegmentBoardOptionCardPrefixesVariant
} from '../../../../../../../../../middlewares/segment/trackEntities/boardEvents';

export const onAdd = (
    value: SegmentBoardOptionCardPrefixesVariant
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(generateClickedSegmentEvent(value));
    };
    return action;
};
