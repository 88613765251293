import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { onSort } from './events/onSort';
import { IFilterBarEvents, IFilterBarFields } from '../../components/FilterBar/types';
import { onAdd } from '../events/onAdd';
import { FilterBar } from '../../components/FilterBar/FilterBar';
import { getShowOnBarFilterIdsCreateSelector } from './selector/getShowOnBarFilterIds';
import {
    DEFAULT_FILTER_ID,
    FP_KEY_CARDS_FILTER_ASSIGNED_TO_ME,
    MY_WORK_FILTER_BOARD_ID
} from '../../../../../aside_panel/filterPanelBoard/constants';
import { onFilterClose } from '../../../../../main/topBar/hocs/MyWorkTopBarSearchHOC/events/onFilterClose';
import { getEnabledFilters } from '../../../../../../../store/model/middlewares/filters/selectors/getEnabledFilters';
import {
    getMyWorkFilterIsInitial
} from '../../../../../aside_panel/filterPanelBoard/store/selectors/getMyWorkFilterIsInitial';
import { getAsidePanelWidth } from '../../../../../aside_panel/asidePanel/store/selectors/getAsidePanelWidth';
import { getMyWorkGroupBy } from '../../../../../main/myWorkView/selectors/getMyWorkGroupBy';
import { getShowOnBarEnabledFilterCount } from './selector/getShowOnBarEnabledFilterCount';
import { onToggleCollapse } from '../events/onToggleCollapse';
import { onToggleAll } from '../events/onToggleAll';
import { getMyWorkFilterBarCollapsed } from '../../../../../main/myWorkView/selectors/getMyWorkFilterBarCollapsed';
import { getShowProFeaturesTrial } from '../../../../../../../store/model/authUser/selectors/getShowProFeaturesTrial';
import { onFilterPanelOpen } from './events/onFilterPanelOpen';
import { getAuthUser } from '../../../../../../../store/model/authUser/selectors/getAuthUser';

const mapStateToProps = () => {
    const getShowOnBarFilterIds = getShowOnBarFilterIdsCreateSelector();

    return (
        state: IApplicationState
    ): IFilterBarFields => {
        const { allowSavedFilters } = getAuthUser(state);
        const filterIds = getShowOnBarFilterIds(state);
        const isSavedEnabledFilters = getEnabledFilters(state, MY_WORK_FILTER_BOARD_ID)
            .reduce((isSavedEnabledFilters, filter) => {
                const isAssignedToMe = filter.meta.isMyWorkReadonly && filter.name === FP_KEY_CARDS_FILTER_ASSIGNED_TO_ME;
                if (
                    filter.isEnabled && filter.id > DEFAULT_FILTER_ID &&
                    (allowSavedFilters || !isAssignedToMe)
                ) {
                    isSavedEnabledFilters = true;
                }
                return isSavedEnabledFilters;
            }, false);
        return {
            allowSavedFilters,
            filterIds,
            groupBy: getMyWorkGroupBy(state),
            enabledFilterCount: getShowOnBarEnabledFilterCount(state),
            isFilter: !getMyWorkFilterIsInitial(state) || isSavedEnabledFilters,
            isAsidePanelOpened: !!getAsidePanelWidth(state),
            isCollapsed: getMyWorkFilterBarCollapsed(state),
            isProFeature: getShowProFeaturesTrial(state),
        }
    };
}

const mapDispatchToProps = (
    dispatch: any
): IFilterBarEvents => ({
    onAdd: () => dispatch(onAdd()),
    onSort: (filterId, index) => dispatch(onSort(filterId, index)),
    onFilterOpen: () => dispatch(onFilterPanelOpen()),
    onFilterClose: () => dispatch(onFilterClose()),
    onToggleCollapse: () => dispatch(onToggleCollapse()),
    onToggleAll: () => dispatch(onToggleAll())
});

export const FilterBarHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(FilterBar);

FilterBarHOC.displayName = 'FilterBarHOC';
