import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../types/types';
import { TimeTrackerDialog } from '../../components/TTDialog/TimeTrackerDialog';
import { ITimeTrackerDialogEvents, ITimeTrackerDialogFields } from '../../components/TTDialog/types';
import { getTimeTrackerState } from '../../../../aside_panel/cardDetails/TabTimingSection/TimeTracker/store/selectors/getTimeTrackerState';
import { onSave } from './events/onSave';
import { onReset } from './events/onReset';
import { onClose } from './events/onClose';
import { getTimeTrackerTotalSeconds } from '../../../../../../store/model/selectors/getTimeTrackerTotalSeconds';
import { getCard } from '../../../../../../store/model/selectors/getCard';
import { getBoardWorkHoursInDay } from '../../../../../../store/model/selectors/getBoardWorkHoursInDay';
import { onChangeTime } from './events/onChangeTime';
import { ITimeTrackerDialogHOCProps } from './types';
import { getAuthUser } from '../../../../../../store/model/authUser/selectors/getAuthUser';
import { getCardEstimateString } from '../../../../../../store/model/helpers/getCardEstimateString';

const mapStateToProps = (
    state: IApplicationState
): ITimeTrackerDialogFields => {
    const {fullName, initials, permissionId, photoUrl} = getAuthUser(state);
    const {cardName, start, stopTime, cardId, boardId} = getTimeTrackerState(state);
    const period = Math.round((stopTime - start) / 1000);

    const card = getCard(state, cardId);
    const user = getAuthUser(state);
    const workHoursInDay = getBoardWorkHoursInDay(state, boardId);
    const estimate = getCardEstimateString(card, workHoursInDay);
    return {
        fullName,
        initials,
        permissionId,
        photoUrl,
        period,
        cardName,

        your: getTimeTrackerTotalSeconds(state, cardId, workHoursInDay, user.hash),
        total: getTimeTrackerTotalSeconds(state, cardId, workHoursInDay),
        estimate,
        workHoursInDay
    };
};

const mapDispatchToProps = (
    dispatch: any,
    {callBackCardId}: ITimeTrackerDialogHOCProps
): ITimeTrackerDialogEvents => {
    return {
    onSave: (comment) => dispatch(onSave(comment, callBackCardId)),
    onReset: () => dispatch(onReset()),
    onClose: () => dispatch(onClose()),
    onChangeTime: (timeString) => dispatch(onChangeTime(timeString))
    }
};

export const TimeTrackerDialogHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(TimeTrackerDialog);

TimeTrackerDialogHOC.displayName = 'TimeTrackerDialogHOC';
