import { getAuthUser } from "app/store/model/authUser/selectors/getAuthUser";
import store, { dispatch, getAppState } from "app/store/configureStore";
import { snackbarInfoDefault } from "app/view/react_components/snackbarsStack";
import { getMessages, root } from "app/store/constants";
import { authUserCancelDelete } from "app/rest/effects/authUser/authUserCancelDelete";
import { isUserAuthorized } from "app/store/model/authUser/selectors/isUserAuthorized";
import * as _ from "underscore";
import { authUserSetSegmentAlias } from "app/rest/effects/authUser/authUserSetSegmentAlias";
import {
    segmentAliasAction,
    segmentIdentifyAction,
    segmentTrackAction,
    SegmentUserEvent
} from "app/middlewares/segment";
import { SegmentUserOption } from "app/middlewares/segment/trackEntities/userEvents";
import { getUserNewCardsCount } from "app/store/model/authUser/selectors/getUserNewCardsCount";
import { getUnseenNotificationCount } from "app/store/model/authUser/selectors/getUnseenNotificationCount";
import { sendUserStatAdminEvent } from "app/rest/effects/userStatAdminReport/sendUserStatAdminEvent";
import * as moment from "moment";
import { deleteDuplicates } from "app/rest/effects/userStatAdminReport/deleteDuplicates/deleteDuplicates";
import { executeOnLoadTests } from "app/abtesting";
import { authorizedUserCookieCheck } from "app/view/react_components/dialogs/cookies";
import { ESignInErrorStatus } from "app/view/react_components/dialogs/signIn";
import { authUserInWorkCardFixDuplicates } from "app/rest/effects/userInWorkCard/authUserInWorkCardFixDuplicates";
import { fetchHandler } from "app/util/fetchHandler";
import { signedUpEvent } from "app/middlewares/segment/segmentEvents/userSegmentEvents";
import { isIndex } from "app/store/router/selectors/isIndex";
import { createFirstBoard } from "app/rest/effects/board/createFirstBoard";
import { isQualificationAvailableWithStatus } from "app/store/model/authUser/selectors/isQualificationAvailableWithStatus";
import { EUserMetaQualificationFormStatus } from "app/types/rest/IUserMeta";
import { Intercom } from "app/helper/intercom";
import { authUserUpdateMeta } from "app/rest/effects/authUser/authUserUpdateMeta";
import { authUserSetTimeZone } from "app/rest/effects/authUser/authUserSetTimeZone";
import { getUserRateFeaturePoll } from "../../../store/model/authUser/selectors/getUserRateFeaturePoll";
import { authUserSetRateFeaturePoll } from "../../../rest/effects/authUser/authUserSetRateFeaturePoll";
import { CommonAuthManager } from "app/helper/authorisation/common/CommonAuthManager";
import { isMicrosoftAccount } from "app/store/model/authUser/selectors/isMicrosoftAccount";
import { msUserPhotoStorage } from "../microsoft/storage/userPhoto";
import { msFileThumbnailStorage } from "../microsoft/storage/msThumbnail";
import { providerInstance as googleProviderInstance } from "app/helper/authorisation/google/Provider";
import { pushNotificationFixSeen } from "app/rest/effects/pushNotification/pushNotificationFixSeen";
import { googleFileThumbnailStorage } from "../google/storage/googleThumbnail";
import { quickStartGuideSetAction } from "app/view/react_components/main/navigationPanel/store/navigationPanel/actions/quickStartGuideSetAction";
import { EQuickStartGuideType } from "app/view/react_components/main/navigationPanel/constants";
import { isPromoCode } from "app/store/router/selectors/isPromoCode";
import { getUserManagedSubscriptions } from "../../../store/model/authUser/selectors/getUserManagedSubscriptions";
import { getUserPromoCodeDiscount } from "../../../store/model/authUser/selectors/getUserPromoCodeDiscount";
import { getUserPromoCodeShowed } from "../../../store/model/authUser/selectors/getUserPromoCodeShowed";
import { getUserPromoCode } from "../../../store/model/authUser/selectors/getUserPromoCode";
import { isShowSelector } from "../../../view/react_components/dialogs/cookies/selectors/isShowSelector";


const TRANSLATE_EVENT_TIMEOUT = 5 * 60 * 1000;

export class SignedHelper{

    signedIn() {
        window.signedIn = true;
        this.checkUserDeletingScheduled();
        this.initGoogle();
        this.runSegmentIdentify();
        this.sendUserStatAdminEvent();
        dispatch(deleteDuplicates());
        this.initMS();

        dispatch(executeOnLoadTests()).then(() => {
            App.controller.removeSplashScreen();
            this.saveTimeZoneAndLocation();
            App.vent.trigger(App.vent['gapi:authorized']);
            this.notifyServerLoggedIn();
            dispatch(authorizedUserCookieCheck());
            this.checkNewUserRegistration();
            this.checkQualificationDialog();
            this.checkGanttOpened();
        }).catch((error) => { // с одной стороны это уже не ошибка SignIn, с другой всё завязано на АБтесты, и юзер не юзер без них
            console.error(error);
            CommonAuthManager.onSignInError({
                details: Messages.getText('error.signIn.internal'),
                status: ESignInErrorStatus.INTERNAL
            });
        });
        setTimeout(() => {
            dispatch(authUserInWorkCardFixDuplicates())
        }, 2000);
        document.body.classList.add('page--signedin');
        // сбросить тригер, юзер должен снова произвести действие для опроса
        const featurePoll = getUserRateFeaturePoll(store.getState());

        dispatch(authUserSetRateFeaturePoll({
            ...featurePoll,
            triggeredFeatureId: null,
        }));

        dispatch(pushNotificationFixSeen());

        App.controller.renderPolls();
    }

    initMS () {
        if (isMicrosoftAccount(getAppState())) {
            msUserPhotoStorage.init();
            msFileThumbnailStorage.init();
            App.vent.trigger(App.vent['initMsStorage']);
            let lng = window.lng || 'def';
            window.lng = lng + '.ms';
        }
    }

    checkUserDeletingScheduled() {
        const user = getAuthUser(getAppState());
        if (user.userHaveDeletionTask) {
            if(user.isDeletedByCron) {
                dispatch(snackbarInfoDefault({
                    id: 'SNACK_BAR_ABOUT_RESTORE_USER_ID',
                    text: getMessages().getText('snackbar.deletion_cancelled_365_days_idle'),
                    timer: -1
                }));
            } else {
                dispatch(snackbarInfoDefault({
                    id: 'SNACK_BAR_ABOUT_RESTORE_USER_ID',
                    text: getMessages().getText('snackbar.deletion_cancelled_24_hours'),
                    timer: -1
                }));
            }
            dispatch(authUserCancelDelete());
        }
    }

    initGoogle(){
        const state = getAppState();
        if (!isMicrosoftAccount(state)) {
            googleProviderInstance.then(provider => {
                googleFileThumbnailStorage.init();
                App.shareClient = new gapi.drive.share.ShareClient(App.appId);
            });

        }
    }

    runSegmentIdentify(){
        try {
            this.userSegmentIdentify();
        } catch (e) {
            console.error(e);
            App.Util.devDebug("onSignedFinished catch", true);
        }
    }

    userSegmentIdentify() {
        const state = getAppState();
        if (isUserAuthorized(getAppState())) {
            const authUser = getAuthUser(state);
            if (!authUser.segmentAlias) {
                // при первом логине патч на юзера шлется ПАРАЛЛЕЛЬНО 4 раза, что приводит к потере части данных, надо убрать параллельность
                _.debounce( () => {
                    dispatch(authUserSetSegmentAlias(true));
                }, 600);
                dispatch(segmentAliasAction(authUser.id));
            }
            dispatch(segmentIdentifyAction({
                platformType: authUser.platformType,
            }));
        }
        dispatch(segmentTrackAction(SegmentUserEvent.SIGN_IN, {
                name: SegmentUserOption.NEW_ASSIGNED_CARDS,
                value: getUserNewCardsCount(store.getState()),
            }, [{
                name: SegmentUserOption.UNSEEN_NOTIFICATION_COUNT,
                value: getUnseenNotificationCount(store.getState()),
            }]
        ));
        this.checkResizePanelTextEvent();
    }

    checkResizePanelTextEvent() {
        setTimeout(() => {
            const btn = document.querySelector('.navigation-panel__browse-boards .kui-button__text');
            if (btn && btn.innerText !== 'Browse boards') {
                dispatch(segmentIdentifyAction({
                    resize_panel_text: btn.innerText
                }));
            }
        }, TRANSLATE_EVENT_TIMEOUT)
    }

    sendUserStatAdminEvent() {
        dispatch(sendUserStatAdminEvent({
            loginCount: '+1',
            loginLastDate: moment().valueOf()
        }));
    }
    notifyServerLoggedIn(){
        setTimeout( () => {
            fetchHandler("/rest/users/loggedIn");
        }, 10000)
    }

    checkNewUserRegistration(){
        const authUser = getAuthUser(getAppState());
        if (authUser.newCredentialReceived === true) {
            if (authUser.firstVisit === true) {
                this.newUserRegistrationAfter();
            }
        }
    }

    newUserRegistrationAfter() {
        //App.auth.utils.hideNewUserRegistration(); - вроде не надо больще, тк нет у нас окна что новые чел регается
        _.debounce(() => {
            dispatch(signedUpEvent());
            App.controller.trackEvent(
                Messages.getText('ga.category.user'),
                Messages.getText('ga.action.register')
            );
        }, 1000)();

        App.controller.setFirstTimeLogin();
        App.controller.showLanguageFeatureModalIfNeeded();

        const state = getAppState();
        if (// кейс, когда куки приняли до регистрации
            !isShowSelector(state) &&
            !getUserPromoCodeShowed(state) &&
            getUserPromoCode(state) &&
            !getUserManagedSubscriptions(state).length
        ) {
            root.App.controller.renderPromoCodePopup();
        }

        if ( // KNB-3914 слишком много попапов для новых юзеров, для юзеров с промокодом не показываем туториалы
            getUserManagedSubscriptions(state).length ||
            !getUserPromoCodeDiscount(state)
        ) {
            setTimeout(() => { // ждем второго лоудера
                root.App.controller.mainView.doAfterLoader(() => {
                    dispatch(quickStartGuideSetAction(EQuickStartGuideType.EFFICIENCY_JOURNEY_GUIDE));
                });
            }, 500);
        }
        // App.controller.showShareBoardModal(); // KNB 3569 пока скрываем
    }

    checkQualificationDialog() {
        const state = getAppState();
        const firstVisit = getAuthUser(state).firstVisit === true;
        dispatch(segmentTrackAction(SegmentUserEvent.DEBUG, {
            name: 'checkQualificationDialog_FV_TRUE',
            value: getAuthUser(state).firstVisit === true? 'true' : 'false',
        }));
        if (firstVisit) {
            if (isIndex(state) || isPromoCode(state)){ //если мы открываем или создаем доску то доску создавать не надо
                dispatch(segmentTrackAction(SegmentUserEvent.DEBUG, {
                    name: 'checkQualificationDialog_isIndex',
                    value: 'true'
                }));
                dispatch(createFirstBoard());
            } else {
                dispatch(segmentTrackAction(SegmentUserEvent.DEBUG, {
                    name: 'checkQualificationDialog_isIndex',
                    value: window.location.href
                }));
            }
            //KNB-2822
            // const status = getUserQualificationFormStatus(state);
            // if (!status && isUserDomainNotNihrOrPwc(state) && isQualificationAvailableWithStatus(state)) {
            //     dispatch(updateQualificationFormMeta({
            //         status: EUserMetaQualificationFormStatus.FIRST_TIME
            //     }))
            // }
        }

        const showDialog = isQualificationAvailableWithStatus(getAppState(), [EUserMetaQualificationFormStatus.ACTIVE, EUserMetaQualificationFormStatus.FIRST_TIME]);
        if (showDialog) {
            App.controller.mainView.showQualificationDialog();
            App.controller.mainView.onHideStartTips();
        }
    }

    checkGanttOpened() {
        const state = getAppState();
        const userMeta = getAuthUser(state).meta;
        if (userMeta && userMeta.dayInSystem && userMeta.dayInSystem == '3' && !userMeta.noGanttUsageSent) {
            const ganttOpened = !!userMeta.ganttOpened
            Intercom.sendProperties({no_Gantt_usage_3days: !ganttOpened});
            dispatch(authUserUpdateMeta({
                noGanttUsageSent: true
            }))
        }
    }

    // Сохраняем временую зону пользователя
    saveTimeZoneAndLocation() {
        let tzName = App.Util.getTimeZone();
        dispatch(authUserSetTimeZone(tzName));
    }
    //TODO for test jpa
    createFirstBoard() {
        dispatch(createFirstBoard());
    }




}

export const signedHelperInstance = new SignedHelper();
//TODO for test jpa
window._SignedHelper = signedHelperInstance;
