import {Dispatch, ThunkAction} from '../../../../../../../types/actions';
import {root} from '../../../../../../../store/constants';
import {boardImportBoardCancelClickedSegmentEvent} from '../../../../../main/navigationPanel/effects/segmentEvents';
import {OPEN_BOARDS_DIALOG_CLOSE} from '../constants';

export const onCancel = (): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        root.App.vent.trigger(root.App.vent[OPEN_BOARDS_DIALOG_CLOSE]);
        dispatch(boardImportBoardCancelClickedSegmentEvent());
    };
    return action;
}
