import { EMyWorkGroupType } from '../../../../../aside_panel/filterPanelBoard/store/filterPanel/types';
import { getMessages } from '../../../../../../../store/constants';

export const MY_WORK_GROUP_BY_LABEL = getMessages().getText('top_bar.board.my_work.group_by.label');

export const MY_WORK_GROUP_BY_OPTIONS = [
    {
        value: EMyWorkGroupType.DUE,
        icon: 'due-date'
    },
    {
        value: EMyWorkGroupType.PRIORITY,
        icon: 'priority-no'
    },
    {
        value: EMyWorkGroupType.ACTIVITY,
        icon: 'activity'
    },
    {
        value: EMyWorkGroupType.ASSIGNEE,
        icon: 'account'
    },
    {
        value: EMyWorkGroupType.LIST,
        icon: 'list-view'
    },
    {
        value: EMyWorkGroupType.BOARD,
        icon: 'board'
    },
    {
        value: EMyWorkGroupType.NONE,
        icon: 'no'
    }
];
