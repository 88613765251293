/**
 *  default prefix "DEPENDENCIES_"
 */
import { TDependencyAction } from '../../dependency/actions/types';
import { IDependency } from '../../dependency/types';
import { IDependencies } from '../types';

export const AT_DEPENDENCY_ACTION_SET = 'DEPENDENCIES_DEPENDENCY_ACTION_SET';
export const AT_DEPENDENCY_SET = 'DEPENDENCIES_DEPENDENCY_SET';
export const AT_DEPENDENCY_DELETE = 'DEPENDENCIES_DEPENDENCY_DELETE';
export const AT_DEPENDENCIES_UPDATE = 'DEPENDENCIES_DEPENDENCIES_UPDATE';

export interface ITypeDependencyActionSetAction {
    type: typeof AT_DEPENDENCY_ACTION_SET;
    dependencyId: number;
    dependencyAction: TDependencyAction;
}

export interface ITypeDependencySetAction {
    type: typeof AT_DEPENDENCY_SET;
    dependencyId: number;
    dependency: IDependency;
}

export interface ITypeDependencyDeleteAction {
    type: typeof AT_DEPENDENCY_DELETE;
    dependencyId: number;
}

export interface ITypeDependenciesUpdateAction {
    type: typeof AT_DEPENDENCIES_UPDATE;
    dependencies: IDependencies;
}

export type TDependenciesAction = (
    ITypeDependencyActionSetAction |
    ITypeDependencySetAction |
    ITypeDependencyDeleteAction |
    ITypeDependenciesUpdateAction
);
