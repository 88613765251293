import { ITimeUnits, TStatus } from '../../../../../../../types/model';
import { INotificationBeforeDueDate } from '../../../../../../../store/model/notificationBeforeDueDate/types';

const generateTemporaryId = (): number => {
    return -new Date().getTime();
};

export const isTemporaryId = (
    notificationId: number,
): boolean => {
    return notificationId < 0;
};

export const getAnEmptyNotification = (latestInterval: number = 0): INotificationBeforeDueDate => {
    return {
        interval: latestInterval + 5,
        unit: ITimeUnits.MINUTES,
        // negative id means that the current notification is not saved and do not have DB id.
        id: generateTemporaryId(),
        // these 4 fields are not in use in edited state and we keep them only for backwards comparability
        cardId: 0,
        dashboardId: 0,
        dateOfNotification: 0,
        orderNumber: 0,
        dueDate: 0,
        status: TStatus.STATUS_ACTIVE,
    };
};
