import {IFilter} from '../filter/types';

export interface IFilterPanel {
    isLoading: boolean;
    isBlocked: boolean;
    isEnabled: boolean;
    isTopBarFiltrationEnabled: boolean;
    defaultFilter: IFilter;
    editedFilter: IFilter;
    savedFilterIds: number[];
    isOpened: boolean;
    isInitial: boolean; // чтобы не фильтровать, пока ничего не поменяли, только открыли панель
}

export enum EMyWorkGroupType {
    DUE = 'Due date',
    ASSIGNEE = 'Assignee',
    PRIORITY = 'Priority',
    ACTIVITY = 'Last activity',
    LIST = 'List',
    BOARD = 'Board',
    NONE = 'None'
}
