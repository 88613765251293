import { IApplicationState, TBoardId } from '../../../../../types/types';
import { EMyWorkCardFetchOption } from '../../../../../types/rest/IUserMeta';
import { getMyWorkBoardsFetchCardsOptions } from './getMyWorkBoardsFetchCardsOptions';

export const getMyWorkBoardFetchCardsOption = (
    state: IApplicationState,
    boardId: TBoardId
): EMyWorkCardFetchOption => {
    return getMyWorkBoardsFetchCardsOptions(state)[boardId] || EMyWorkCardFetchOption.DAYS_30;
};
