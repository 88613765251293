import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState } from '../../../../../../../types/types';
import { showSnoozeBlocker } from '../../../../../base/effects/showSnoozeBlocker';

export const onTimerEnd = (): ThunkAction => {
    return (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(showSnoozeBlocker({}));
    };
};
