const category = 'Reports';

export const SegmentReportsEvent = {
    OPEN: { category, event: 'Reports screen opened (success)' },
    DISALLOW: { category, event: 'Reports screen opened (no success)' },
    INSIGHT_OPENED: { category, event: 'Insight accordion preview opened' },
    INSIGHT_CLOSE: { category, event: 'Insight accordion preview closed' },
    INSIGHT_TOGGLE: { category, event: 'Insight panel toggled' },
    INSIGHT_REPORT_OPENED: { category, event: 'Report opened from insight' },
    REPORT_OPENED: { category, event: 'Report opened from the list' },
    BACK_TO_WALL: { category, event: 'Back from report to the list' },
    TOGGLE_FILTERS: { category, event: 'Filter toggle clicked in report' },
    CHANGE_LISTS_SETUP: { category, event: 'Lists edited ' },
    CHOOSE_DEFAULT_PERIOD: { category, event: 'Default period chosen' },
    CHANGE_SD: { category, event: 'Start date changed' },
    CHANGE_DD: { category, event: 'Due date changed' },
    CHANGE_IDEAL: { category, event: 'Ideal line show/hide' },
    CHANGE_DONE_SOURCE: { category, event: 'Done status changed' },
    CHANGE_REPORT_TYPE: { category, event: 'Report view changed' },
    CHANGE_TIMEZONE: { category, event: 'Timezone change link clicked from reports' },
    CHANGE_USERS: { category, event: 'Users list edited' },
    USER_PLATE_CLICKED: { category, event: 'User plate clicked' }
};

export enum SegmentReportsOption {
    REPORT_TYPE = 'Report type',
    STATUS = 'status'
}
