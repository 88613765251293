import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../../../../types/types';
import { showDocDriveWindow } from '../../../../effects/showDocDriveWindow';
import { ERestDriveDocType } from '../../../../../../../../types/rest/IRestDriveDoc';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { IDriveDoc } from '../../../../../../../../store/model/card/types/IDriveDoc';
import {
    setAttachmentAddedSessionStorage
} from '../../../../AttachmentsSection/helpers/setAttachmentAddedSessionStorage';

export const onAddAttachments = (
    cardId: TCardId,
    attachments: IDriveDoc[] = [],
    setAttachments: (docs: IDriveDoc[]) => void,
): ThunkAction => {
    return (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.COMMENT_ATTACHMENT_ADD_CLICKED));

        dispatch(showDocDriveWindow(
            cardId,
            attachments,
            ERestDriveDocType.COMMENT,
            (docs) => {
                setAttachmentAddedSessionStorage();
                setAttachments(docs);
            }
        ));
    };
};
