import { IApplicationState, TCardId } from '../../../types/types';
import { getCard } from './getCard';

export const getCardDone = (
    state: IApplicationState,
    cardId: TCardId
): boolean => {
    const card = getCard(state, cardId);
    if (
        !card ||
        !card.processingStatus && !card.processingPercent
    ) return false;

    const done = (
        card.processingStatus === 'done' || // todo achtung kill processingStatus
        card.processingPercent === 100
    );
    return done;
}
