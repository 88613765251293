import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../types/types';
import { IInWorkCardHOCProps } from './types';
import { getInWorkCard } from '../../../../../store/model/selectors/getInWorkCard';
import { onOpenInWorkCard } from './events/onOpenInWorkCard';
import { IInWorkCardEvents, IInWorkCardFields } from '../../components/InWorkCard/types';
import { onInWorkCardUpdate } from './events/onInWorkCardUpdate';
import { InWorkCard } from '../../components/InWorkCard/InWorkCard';
import { getBoardIsLoading } from '../../../../../store/requestsState/selectors/getBoardIsLoading';
import { getActiveBoardId } from '../../../../../store/model/selectors/getActiveBoardId';

const mapStateToProps = (
    state: IApplicationState,
    props: IInWorkCardHOCProps
): IInWorkCardFields => {
    const boardId = getActiveBoardId(state);
    const {
        id,
        boardName,
        name,
        cardNumber,
    } = getInWorkCard(state) || {};

    return {
        id,
        boardName,
        name,
        cardNumber,
        isBoardLoading: boardId && getBoardIsLoading(state, boardId)
    }
};

const mapDispatchToProps = (dispatch: any): IInWorkCardEvents => ({
    onOpenInWorkCard: () => dispatch(onOpenInWorkCard()),
    onInWorkCardUpdate: () => dispatch(onInWorkCardUpdate()),
});

export const InWorkCardHOC = connect(mapStateToProps, mapDispatchToProps)(InWorkCard);
InWorkCardHOC.displayName = 'InWorkCardHOC';
