import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { TCardId } from '../../../../../../../../types/types';
import { SegmentCardEvent, SegmentCardOptions, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { SegmentCardSourceValue } from '../../../../../../../../middlewares/segment/trackEntities/cardEvents';
import { cardDeleteOrArchive } from '../../../../../../base/effects/cardDeleteOrArchive';

export const onCardDelete = (
    cardId: TCardId,
    source: SegmentCardSourceValue,
    closePanelOnDelete: boolean
): ThunkAction => {
    return (dispatch: Dispatch) => {
        dispatch(segmentTrackAction(SegmentCardEvent.DELETE_CARD, {
            name: SegmentCardOptions.SOURCE,
            value: source
        }));
        dispatch(cardDeleteOrArchive(cardId, closePanelOnDelete));
    };
};
