import {Dispatch, ThunkAction} from '../../../../../../../../types/actions';
import {SegmentCardEvent, segmentTrackAction} from '../../../../../../../../middlewares/segment';
import {IGetState} from '../../../../../../../../types/types';
import { downloadFile } from 'app/helper/authorisation/microsoft/filePicker/downloadFile';

export const onDownload = (
    driveId?: string,
    fileId?: string,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.ATTACHED_FILE_DOWNLOAD_CLICKED));
        if (driveId && fileId) downloadFile(driveId, fileId); // ms
    };
    return action;
};
