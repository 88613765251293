import { getUserMetaMyWorkHiddenColumns } from '../../../../../selectors/getUserMetaMyWorkHiddenColumns';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../../types/types';
import { getUserMetaMyWorkColumns } from '../../../../../selectors/getUserMetaMyWorkColumns';
import { updateMyWorkMeta } from '../../../../../../../../../rest/effects/authUser/myWork/updateMyWorkMeta';
import { EMyWorkGroupType } from '../../../../../../../aside_panel/filterPanelBoard/store/filterPanel/types';
import { getMyWorkGroupBy } from '../../../../../selectors/getMyWorkGroupBy';
import { getMessages } from '../../../../../../../../../store/constants';

export const onSortColumns = (
    column: string,
    index: number,
    columns: string[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const myWorkGroupBy = getMyWorkGroupBy(state);
        const groupByBoard = myWorkGroupBy === EMyWorkGroupType.BOARD;
        const savedColumns = getUserMetaMyWorkColumns(state);
        let collection = [...columns];
        if (groupByBoard) {
            const idx = savedColumns.findIndex(item => item.title === getMessages().getText('assigned_to_me.cards.board'));
            if (idx > -1) {
                collection.splice(idx, 0, savedColumns[idx].title);
            }
            if (index > idx) {
                index = Math.min(collection.length, index + 1);
            } else {
                index = Math.max(0, index - 1);
            }
        }
        const idx = collection.indexOf(column);
        if (idx > -1) collection.splice(idx, 1);
        const lastIndex = collection.length - 1;
        const newPosition = index === lastIndex ? lastIndex - 1 : index === 0 ? 1 : index;
        collection.splice(newPosition, 0, column);
        const hiddenColumns = getUserMetaMyWorkHiddenColumns(state);
        const shownColumns = collection.map(item => {
            return { title: item, isHidden: hiddenColumns.includes(item) };
        });
        const allColumns = savedColumns
            .reduce((newColumns, column) => {
                const idx = newColumns.findIndex(item => item.title === column.title);
                if (idx === -1) {
                    newColumns.push(column);
                }
                return newColumns;
            }, shownColumns);
        dispatch(updateMyWorkMeta({ columns: allColumns }));
    };
    return action;
};
