import * as React from 'react';
import { createRoot, Root } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import { EBrowserNotificationsTypes } from '../dialogs/notificationsRequest/hocs/BrowserNotificationsRequestHOC/types';
import {
    BrowserNotificationsRequestHOC
} from '../dialogs/notificationsRequest/hocs/BrowserNotificationsRequestHOC/BrowserNotificationsRequestHOC';

export class BrowserNotificationsRequestReactToBackbone {
    el: Element;
    root: Root;
    store: Store;
    type: EBrowserNotificationsTypes;
    onAllow: () => void;

    constructor (
        store: Store,
        onAllow: () => void,
        type?: EBrowserNotificationsTypes,
    ) {
        this.store = store;
        this.type = type;
        if (this.el) this.remove();
        this.el = document.createElement('div');
        this.el.className = 'notifications-request-dialog-placeholder';
        document.body.append(this.el);
        this.onAllow = onAllow;
        this.root = createRoot(this.el!);
        this.render();
    }

    render () {
        this.root.render(
            <Provider store={this.store}>
                <BrowserNotificationsRequestHOC
                    type={this.type}
                    onAllow={this.onAllow}
                />
            </Provider>
        );
    }

    remove () {
        if (!this.el) return;

        this.root.unmount();
        this.el.remove();
        this.el = null;
    }
}
