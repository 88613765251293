import { getNotificationSettingsInitial } from '../constants';
import { INotificationSettings } from '../types';
import { AT_UPDATE, TNotificationSettingsAction } from '../actions/types';

const initialState: INotificationSettings = getNotificationSettingsInitial();

export const notificationSettingsReducer = (
    state = initialState,
    action: TNotificationSettingsAction
): INotificationSettings => {
    switch (action.type) {
        case AT_UPDATE: {
            return {
                ...state,
                ...action.notification
            };
        }

        default:
            return state;
    }
};
