/*
Use for general notifications with pre-defined OK button. Pass the following params via options:
- header
- message
- type: 'error' or 'warning' or 'progress' or 'message' or 'question'
- buttons: array of objects, e.g. [{ text: 'OK', click: function() {some function } }, { text: 'Cancel', click: function() {some function } } ]
*/

'use strict';

import alertTemplate from './templates/alert.html';
import * as _ from 'underscore';

App.Views.Alert = App.Views.BaseView.extend({
    tagName: 'div',

    className: 'modal__inner',

    template: _.template( alertTemplate ),

    events: {
        'click .modal__close': 'onCloseModelClick',
        'click .button--close-modal': 'onCloseClick'
    },

    initialize: function(options) {
        this.header = options.header;
        this.subtitle = options.subtitle;
        this.message = options.message;
        this.type = options.type || 'message';
        this.buttons = options.buttons;
        this.onClose = options.onClose ||  function(){};
        this.onOkClose = options.onOkClose ||  function(){};
        if (! this.header) {
            this.setDefaultHeader();
        }
        App.vent.on(App.vent['keydown:escape'], this.hide, this);
    },

    render: function() {
        this.$el.html(this.template({
            header: this.header,
            subtitle: this.subtitle,
            message: this.message,
            buttons: this.buttons,
            type: this.type
        }));
        if (this.type) {
            this.$el.addClass('modal__inner--' + this.type);
        }
        if (this.buttons) {
            for (var i = 0, len = this.buttons.length; i < len; i++) {
                this.$el.find('.modal__footer button:nth-child(' + (i+1) + ')').click(this.buttons[i].click);
                if (this.isDevEnv() && this.buttons[i].testClass) {
                    this.$el.find('.modal__footer button:nth-child(' + (i+1) + ')').addClass(this.buttons[i].testClass)
                }
            }
        }
        return this;
    },

    setDefaultHeader: function() {
        if (this.type == 'error') {
            this.header = Messages.getText('popup.error.header');
        } else if (this.type == 'info') {
            this.header = Messages.getText('popup.info.header');
        } else if (this.type == 'warning') {
            this.header = Messages.getText('popup.warning.header');
        } else if (this.type == 'progress') {
            this.header = Messages.getText('popup.progress.header');
        } else if (this.type == 'question') {
            this.header = Messages.getText('popup.question.header');
        } else if (this.type == 'message') {
            this.header = Messages.getText('popup.message.header');
        }
    },

    onCloseModelClick: function(e) {
        e.stopPropagation();
        this.onClose();
        this.hide();
    },

    onCloseClick: function(e) {
        e.stopPropagation();
        this.onOkClose()
        this.hide();
    },

    hide: function() {
        App.vent.trigger(App.vent['dialog:closed']);
    },

    remove: function() {
        if (this.buttons) {
            for (var i = 0, len = this.buttons.length; i < len; i++) {
                this.$el.find('.modal__footer button:nth-child(' + (i+1) + ')').unbind();
            }
        }
        App.vent.off(null, null, this);
        return Backbone.View.prototype.remove.call(this);
    },

    testAttributes: function() {
        return {
            type: 'alert-modal',
            modal: this.type
        };
    }
});
App.Views.Alert.mixin(App.Mixins.Test);
