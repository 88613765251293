import { IIsHighlightCardIdSetAction } from './types';
import { AT_HIGHLIGHT_CARD_ID } from './constants';
import { TCardId } from '../../../../../../types/types';

export const highlightCardIdSet = (
    cardId: TCardId
): IIsHighlightCardIdSetAction => {
    return {
        type: AT_HIGHLIGHT_CARD_ID,
        cardId
    }
};
