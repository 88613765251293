import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../types/types';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { EDateType } from '../../../../../../../../const';

export const onTimeOpened = (
    dateType: EDateType
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (dateType === EDateType.DUE_DATE) {
            dispatch(segmentTrackAction(SegmentCardEvent.CLICKED_DUE_TIME));
        } else {
            dispatch(segmentTrackAction(SegmentCardEvent.CLICKED_START_TIME));
        }
    };
    return action;
};
