import {Dispatch, ThunkAction} from 'app/types/actions';
import {TBoardId} from 'app/types/types';
import {DashboardImportRouter} from 'app/view/react_components/dialogs/openBoards/helpers/importBoard';
import {REST_CSV_IMPORT} from 'app/view/react_components/dialogs/openBoards/constants';
import {
    CSV_OPEN_FILE_MIME_FILTER,
    SNACKBAR_BOARD_IMPORT_ID, SNACKBAR_CSV_IMPORT_ERROR_TEXT,
    SNACKBAR_CSV_IMPORT_PROGRESS_TEXT
} from 'app/view/react_components/dialogs/openBoards/FooterImport/hocs/FooterImportHOC/constants';
import {reopenBoard} from 'app/view/react_components/aside_panel/boardDetails/details/PwcImport/hocs/BoardPwcImportHOC/effects/reopenBoard';

export const onImport = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        new DashboardImportRouter({
            importUrl: REST_CSV_IMPORT + '?dashboardId=' + boardId,
            snackbarImportId: SNACKBAR_BOARD_IMPORT_ID,
            importProgressText: SNACKBAR_CSV_IMPORT_PROGRESS_TEXT,
            importErrorText: SNACKBAR_CSV_IMPORT_ERROR_TEXT,
            mimeTypesFilter: CSV_OPEN_FILE_MIME_FILTER,
            onSuccess: () => {
                dispatch(reopenBoard(boardId));
            }
        }, dispatch).start();
    }
    return action;
}
