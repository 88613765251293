import { AsidePanelActionsButton } from '../../../..';
import * as React from 'react';
import { IMilestoneButtonProps } from './types';
import { getAsidePanelActionsButtonTooltip } from '../../../../asidePanel/helpers/getAsidePanelActionsButtonTooltip';
import { LABEL_CONVERT_FROM_MILESTONE, LABEL_CONVERT_TO_MILESTONE, LABEL_MILESTONE, MILESTONE_EPIC } from './constants';

export function MilestoneButton ({
    isBasicFeature,
    isDisabled,
    isDisallow,
    isMilestone,
    isShow,
    isShowTooltip,
    onClick,
}: IMilestoneButtonProps) {
    if (!isShow) return null;

    let tooltip;
    if (isShowTooltip || isDisabled) {
        tooltip = getAsidePanelActionsButtonTooltip({
            value: isDisabled
                ? MILESTONE_EPIC
                : isMilestone ? LABEL_CONVERT_FROM_MILESTONE : LABEL_CONVERT_TO_MILESTONE
        });
    }

    return (
        <AsidePanelActionsButton
            indicatorBoolean={isMilestone}
            icon={'milestone'}
            isDisabled={isDisabled || isDisallow}
            title={LABEL_MILESTONE}
            isBasicFeature={isBasicFeature}
            tooltip={tooltip}
            onClick={onClick}
        />
    );
}
