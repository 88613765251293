import { IApplicationState } from '../../../../../types/types';
import {
    UPGRADE_BUTTON_TEXT,
    UPGRADE_BUTTON_TEXT_FOR_READ_ONLY_MODE,
    UPGRADE_BUTTON_TEXT_REQUEST_LICENSE,
} from '../constants';
import { isContinuedReadOnly } from '../../../base/selectors/isContinuedReadOnly';
import { isPwc } from '../../../../../store/model/authUser/selectors/isPwc';

export const getButtonText = (
    state: IApplicationState
): string => {
    const isLicense = isPwc(state);
    if (isLicense) {
        return UPGRADE_BUTTON_TEXT_REQUEST_LICENSE
    }

    const isUserReadOnly = isContinuedReadOnly();
    if (isUserReadOnly) {
        return UPGRADE_BUTTON_TEXT_FOR_READ_ONLY_MODE;
    } else {
        return UPGRADE_BUTTON_TEXT;
    }
};
