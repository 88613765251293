import gsuiteAdminTemplate from './templates/googleSpreadsheetPermissions.html';
import { dispatch } from '../../../store/configureStore';
import { segmentTrackAction, SegmentUserEvent } from "../../../middlewares/segment";
import * as _ from 'underscore';
import { authManagerInstance } from "app/helper/authorisation/google/AuthManager";

App.Views.GoogleSpreadasheetPermissionsDialog = App.Views.BaseView.extend({
    tagName: 'div',
    template: _.template( gsuiteAdminTemplate ),
    className: 'google-spreadsheet-sharing',

    events: {
        'click .js-show-permissions': 'onShowPermissions',
        'click .js-copy': 'onCopy'
    },

    onShowPermissions: function(e) {
        e.stopPropagation();
        authManagerInstance.checkAndRequestGoogleSheet(
            () => {
                dispatch(segmentTrackAction(SegmentUserEvent.PERMISSION_SHEETS_SCOPE_GRANTED));
                this.showPage('done');
            },
            undefined,
            () => {
                this.showPage('sorry');
            });
    },

    showPage: function(options) {
        var page = options || 'default';
        this.$el.find('.animation_page_show:not(.google-spreadsheet-sharing__page--' + page + ')').removeClass('animation_page_show').addClass('animation_page_hide');
        this.$el.find('.animation_page_hide.google-spreadsheet-sharing__page--' + page).removeClass('animation_page_hide').addClass('animation_page_show');
    },

    onCopy: function(e) {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(window.location.href).then(function() {
                $(e.target).closest('.js-copy').html(Messages.getText('google-spreadsheet-sharing.copied-link'));
            });
        }
    },

    getContent: function() {
        return {
            title: Messages.getText('google-spreadsheet-permission-dialog.title'),
            text: Messages.getText('google-spreadsheet-permission-dialog.bait'),
            button: Messages.getText('google-spreadsheet-permission-dialog.button-enable'),
            copy: Messages.getText('google-spreadsheet-permission-dialog.copy-link'),
            canCopy: false,
            titleSorry: Messages.getText('google-spreadsheet-permission-dialog.title-sorry'),
            textSorry: Messages.getText('google-spreadsheet-permission-dialog.text-sorry'),
            titleDone: Messages.getText('google-spreadsheet-permission-dialog.title-done'),
            textDone: Messages.getText('google-spreadsheet-permission-dialog.text-done')
        };
    },

    render: function() {
        this.$el.html( this.template( this.getContent() ) );
        return this;
    }
});
