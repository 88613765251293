import { IGetState, TCardId } from 'app/types/types';
import { Dispatch, ThunkAction } from 'app/types/actions';
import { IDriveDoc } from 'app/store/model/card/types/IDriveDoc';
import { uploadFiles } from '../effects/uploadFiles';

export const onUploadClick = (
    cardId: TCardId,
    onStart: () => void,
    onProgress: (percent: number) => void,
    onFinish: () => void,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const input = document.createElement('input');
        input.type = 'file';
        input.multiple = true;

        input.onchange = (e: InputEvent) => {
            const target = e.target as HTMLInputElement;
            onStart();

            return dispatch(uploadFiles(cardId, Array.from(target.files), onProgress))
                .then((driveDocs: IDriveDoc[]) => {
                    return driveDocs && driveDocs.length && driveDocs[0];
                })
                .finally(onFinish);
        }

        input.click();
    };
    return action;
};
