import { AT_UPDATE, IUpdateAction } from './types';
import { IBoard } from '../types';

export const updateAction = (
    board: IBoard
): IUpdateAction => {
    return {
        type: AT_UPDATE,
        board
    }
};
