import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState } from '../../../../../../../types/types';
import { recurringTasksOpen } from '../../../../../base/effects/recurringTasksOpen';
import {
    boardRecurringCardsSegmentEvent,
    ESegmentBoardMenuLocation
} from '../../../../../../../middlewares/segment/trackEntities/boardMenuEvents';
import { getAuthUser } from '../../../../../../../store/model/authUser/selectors/getAuthUser';
import { showSnoozeBlocker } from '../../../../../base/effects/showSnoozeBlocker';

export const onClickRecurringTasks = (): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const user = getAuthUser(getState());
        dispatch(boardRecurringCardsSegmentEvent(ESegmentBoardMenuLocation.topBar, user.allowRecurringTask));
        if (user.allowRecurringTask) {
            dispatch(recurringTasksOpen());
        } else {
            dispatch(showSnoozeBlocker({
                allow: 'allowRecurringTask'
            }));
        }
    };
    return action;
};
