import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState } from '../../../../../../../types/types';
import { WEBINAR_QUESTIONS } from '../../../constants/questions';
import { segmentTrackAction } from '../../../../../../../middlewares/segment';
import { authUserSetWebinarFinishedMeta } from '../../../../../../../rest/effects/authUser/authUserSetWebinarFinishedMeta';

export const onAnswer = (
    questionKey: string,
    answerKey: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(authUserSetWebinarFinishedMeta());
        const answer = WEBINAR_QUESTIONS[questionKey].answers[answerKey];
        dispatch(segmentTrackAction(answer.event));
    };
    return action;
};
