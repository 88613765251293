import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { drivesLoadingSet } from '../../../store/drives/actions/drivesLoadingSet';
import { INITIAL } from '../../../store/constants';
import { loadBoardsByTabKey } from '../../../effects/loadBoardsByTabKey';
import { TTabKey } from '../../../store/types';
import { segmentTrackAction, SegmentUserEvent } from '../../../../../../../middlewares/segment';
import {
    SegmentUserOption,
    SegmentUserOptionValue
} from '../../../../../../../middlewares/segment/trackEntities/userEvents';
import { authManagerInstance } from 'app/helper/authorisation/google/AuthManager';
import { IGetState } from 'app/types/types';
import { getAuthUserPlatformType } from 'app/store/model/authUser/selectors/getAuthUserPlatformType';
import { EAuthUserPlatformType } from 'app/types/rest/IRestAuthUser';

export const givePermissionsSharedDrives = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        return new Promise((resolve, reject) => {
            const eventOpt = { name: SegmentUserOption.TAGRET, value: SegmentUserOptionValue.SHARED_DRIVE }
            const onShow = () => {
                dispatch(segmentTrackAction(SegmentUserEvent.PERMISSION_DRIVE_SCOPE_SHOWN, eventOpt));
            };
            const onRequest = () => {
                dispatch(segmentTrackAction(SegmentUserEvent.PERMISSION_DRIVE_SCOPE_REQUESTED, eventOpt));
            };
            const onCancel = () => {
                dispatch(segmentTrackAction(SegmentUserEvent.PERMISSION_DRIVE_SCOPE_CANCELED, eventOpt));
                reject();
            };
            const onApprove = () => {
                dispatch(segmentTrackAction(SegmentUserEvent.PERMISSION_DRIVE_SCOPE_GRANTED, eventOpt));
                dispatch(drivesLoadingSet(INITIAL));
                resolve(dispatch(loadBoardsByTabKey(TTabKey.sharedDrives)));
            };

            const platformType = getAuthUserPlatformType(getState());
            switch (platformType) {
                case EAuthUserPlatformType.MICROSOFT:
                    console.error('only for google');
                    break;
                default:
                    authManagerInstance.checkAndRequestSharedDrive(onApprove, onShow, onCancel, onRequest);
            }
        });
    };
    return action;
};
