import { EMPTY_ARRAY, IApplicationState } from '../../../../../../../../types/types';
import { connect } from 'react-redux';
import { IEmailNotificationsHOCProps } from './types';
import { EmailNotifications } from '../../components/EmailNotifications/EmailNotifications';
import {
    IEmailNotificationsEvents,
    IEmailNotificationsFields
} from '../../components/EmailNotifications/types';
import { onPeriodChange } from './events/onPeriodChange';
import { onScopeChanges } from './events/onScopeChanges';
import { ENotificationPeriod, ENotificationScope } from '../../../../../../../../types/rest/IRestNotificationSettings';
import { getNotificationSettingsByBoardId } from '../../../../../../../../store/model/selectors/getNotificationSettingsByBoardId';
import { onPeriodSelectOpen } from './events/onPeriodSelectOpen';
import { onScopeClick } from './events/onScopeClick';
import { getPanelReadonly } from '../../../../selectors/getPanelReadonly';

const mapStateToProps = (
    state: IApplicationState,
    ownProps: IEmailNotificationsHOCProps
): IEmailNotificationsFields => {
    const { boardId } = ownProps;
    const notification = getNotificationSettingsByBoardId(state, boardId);
    const isReadonly = getPanelReadonly(state, boardId);
    return {
        email: notification ? notification.notificationEmail : '',
        isEnabled: notification && notification.enabled,
        isReadonly,
        period: notification ? notification.period : ENotificationPeriod.SOON,
        scopes: notification && notification.scope ? notification.scope : EMPTY_ARRAY
    }
};

const mapDispatchToProps = (
    dispatch: any,
    ownProps: IEmailNotificationsHOCProps
): IEmailNotificationsEvents => {
    const { boardId } = ownProps;
    return {
        onPeriodSelectOpen: () => dispatch(onPeriodSelectOpen()),
        onPeriodChange: (period: number) => dispatch(onPeriodChange(boardId, period)),
        onScopeClick: (scope: ENotificationScope) => dispatch(onScopeClick(scope)),
        onScopeChanges: (scopes: ENotificationScope[]) => dispatch(onScopeChanges(boardId, scopes))
    }
};

export const EmailNotificationsHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(EmailNotifications);

EmailNotificationsHOC.displayName = 'EmailNotificationsHOC';
