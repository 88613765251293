import { getAuthUserSettingsCommentsSort } from './../../../../../../../../store/model/authUser/selectors/getAuthUserSettingsCommentsSort';
import { EUserSettingsCommentSort } from './../../../../../../../../types/rest/IRestAuthUser';
import { IComment, TCommentId } from './../../../../../../../../store/model/card/types/IComment';
import { IApplicationState, TCardId } from '../../../../../../../../types/types';
import { getCardComments } from '../../../../../../../../store/model/selectors/getCardComments';
import { createSelector } from 'reselect';
import { getEditCommentId } from '../../../../../../../../store/router/selectors/getEditCommentId';

type TgetSortedComments = (
    state: IApplicationState,
    cardId: TCardId,
) => IComment[];

type TsortFunction = (
    a: IComment,
    b: IComment,
) => number;

const getCommentSortFunction = (
    userSettingsCommentSort: EUserSettingsCommentSort
): TsortFunction => {
    return (a, b) => {
        const compareResult = b.created - a.created;
        return userSettingsCommentSort * compareResult;
    };
};

const getSortedCommentsSelector = (
    userSettingsCommentSort: EUserSettingsCommentSort,
    comments: IComment[],
    activeCommentId: TCommentId,
) => {
    let sortedComments = [...comments]
        .sort(getCommentSortFunction(userSettingsCommentSort));
    if (activeCommentId) {
        const idx = sortedComments.findIndex(comment => comment.id === activeCommentId);
        if (idx > -1) {
            sortedComments = [
                sortedComments[idx],
                ...sortedComments.filter(comment => comment.id !== activeCommentId)
            ];
        }
    }
    return sortedComments;
};

export const getSortedCommentsCreateSelector = (
): TgetSortedComments => createSelector([
        getAuthUserSettingsCommentsSort,
        getCardComments,
        getEditCommentId,
    ],
    getSortedCommentsSelector,
);

export const getSortedComments: TgetSortedComments = getSortedCommentsCreateSelector();
