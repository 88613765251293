import * as React from 'react';
import { CLASS_CARD_SECTION_RECURRING } from '../../../constants';
import { Select } from 'kui';
import { ISelectActiveInheritedProps, ISelectOptionsArray } from 'kui';
import { ERecurringRepeatMonthType } from '../../constants';
import { LABEL_FREQUENCY, LABEL_MONTHLY_ON_DAY, LABEL_MONTHLY_ON_WEEKDAY } from '../wording';
import { WEEK_POSITIONS } from './constants';
import { CardRecurringFormContext } from '../CardRecurringForm/constants';
import './_MonthDay.scss';

export function MonthDay() {
    const {form, updateForm, isReadonly} = React.useContext(CardRecurringFormContext);

    const className = CLASS_CARD_SECTION_RECURRING + '__month-day';

    const options: ISelectOptionsArray = [{
        value: ERecurringRepeatMonthType.DAY_OF_MONTH,
        text: `${LABEL_MONTHLY_ON_DAY} ${form.monthDayData.dayOfMonth}`
    }, {
        value: ERecurringRepeatMonthType.DAY_OF_WEEK_IN_MONTH,
        text: `${LABEL_MONTHLY_ON_WEEKDAY} ${WEEK_POSITIONS[form.monthDayData.dayOfWeekPosition - 1]} ${form.monthDayData.dayOfWeekName}`
    }]

    const active = options.findIndex(option => option.value === form.monthDay);

    const onChangeHandler = (event: ISelectActiveInheritedProps) => {
        updateForm({ monthDay: event.item.value as ERecurringRepeatMonthType })
    }
    return (
        <Select
            className={className}
            label={LABEL_FREQUENCY}
            active={active}
            editable={false}
            isFitWindow={true}
            options={options}
            variant={'arrow'}
            onChange={onChangeHandler}
            readOnly={isReadonly}
        />
    );
}
