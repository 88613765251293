import * as deepFreeze from 'deep-freeze';
import { IChecklist } from '../types';
import {
    AT_CHECKLIST_ITEM_ADD,
    AT_CHECKLIST_ITEM_DELETE,
    AT_CHECKLIST_ITEMS_UPDATE,
    AT_CHECKLIST_UPDATE,
} from '../actions/constants';
import { TChecklistAction } from '../actions/types';

const initialState: IChecklist = {};

const _checklistReducer = (
    state = initialState,
    action: TChecklistAction
): IChecklist => {
    switch (action.type) {
        case AT_CHECKLIST_ITEM_ADD: {
            const checkItems = {...state.checkItems} || {};
            checkItems[action.item.id] = action.item;
            return {
                ...state,
                checkItems,
            }
        }

        case AT_CHECKLIST_ITEM_DELETE: {
            const checkItems = {...state.checkItems} || {};
            delete checkItems[action.itemId];
            return {
                ...state,
                checkItems,
            };
        }

        case AT_CHECKLIST_ITEMS_UPDATE: {
            return {
                ...state,
                checkItems: {
                    ...state.checkItems,
                    ...action.items
                }
            }
        }

        case AT_CHECKLIST_UPDATE: {
            return {
                ...state,
                ...action.checklist
            }
        }

        default:
            return state;
    }
};

export const checklistReducer = (
    state = initialState,
    action: TChecklistAction
    ): IChecklist => {
    // @ts-ignore
    if (window.Settings.development) {
        // @ts-ignore
        return deepFreeze(_checklistReducer(state, action));
    } else {
        return _checklistReducer(state, action);
    }
};
