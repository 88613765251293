import {IApplicationState, TBoardId} from '../../../../types/types';
import {IBackupSchedule} from '../types';
import {EBackupExportType, EBackupRepeatType} from '../../../../types/rest/IRestBackup';
import {getAutoBackupsByBoardIdAndExportType} from '../../../../view/react_components/aside_panel/boardDetails/backups/AutoBackups/store/autoBackup/selectors/getAutoBackupsByBoardIdAndExportType';

export const getBackupScheduleByBoardAndExportType = (
    state: IApplicationState,
    boardId: TBoardId,
): IBackupSchedule  => {
    return  getAutoBackupsByBoardIdAndExportType(state, boardId, EBackupExportType.GOOGLE_SHEET).autoBackupSchedule;
}
