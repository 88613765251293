import { IApplicationState } from '../../../../types/types';
import { getAuthUserSettings } from './getAuthUserSettings';
import { EUserSettingsCommentSort } from '../../../../types/rest/IRestAuthUser';

export const getAuthUserSettingsCommentsSort = (
    state: IApplicationState
): EUserSettingsCommentSort => {
    const settings = getAuthUserSettings(state);
    return settings.commentSort || EUserSettingsCommentSort.ASC;
}
