import { IRelatedCard } from '../../../components/RelatedCard/types';
import { root } from '../../../../../../../store/constants';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../middlewares/segment/index';
import { Dispatch, ThunkAction } from '../../../../../../../types/actions';

export const onClick = (
    relatedCard: IRelatedCard
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.RELATED_LIST_CLICK));
        root.App.controller.cleverOpenCard(relatedCard.dashboardId, relatedCard.cardId);
    };
    return action;
};
