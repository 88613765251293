import { TBoardId } from '../../../../../../../../types/types';

export interface IBoardPwcImportHOCProps {
    boardId: TBoardId;
    importType: EPwcImportTypes;
}

export enum EPwcImportTypes {
    SUBCARDS = 'subcards',
    CHECKLISTS = 'checklists',
    TEMPLATES_CHECKLISTS = 'templateChecklists'
}
