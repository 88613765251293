import { IApplicationState, TBoardId } from '../../../../types/types';
import { getGanttViewBoardInitialState } from '../store/ganttViewBoard/constants';
import { IGanttViewBoardState } from '../store/ganttViewBoard/types';

export const getGanttViewBoardState = (
    state: IApplicationState,
    boardId: TBoardId
): IGanttViewBoardState => {
    const boardState =
        state.ganttView && state.ganttView[boardId] ||
        getGanttViewBoardInitialState();
    return boardState;
};
