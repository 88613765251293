import { ITooltipInheritedProps } from 'kui';
import { getAsidePanelTooltip } from './getAsidePanelTooltip';

export const getAsidePanelActionsButtonTooltip = (
    tooltip: ITooltipInheritedProps,
): ITooltipInheritedProps => {
    return {
        ...getAsidePanelTooltip(tooltip),
        //delay: 200,
        direction: 'left',
        ...tooltip
    } as ITooltipInheritedProps;
}
