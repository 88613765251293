import { IApplicationState, TBoardId } from '../../../types/types';
import { getUserRecentBoard } from '../authUser/selectors/getUserRecentBoard';
import { getBoard } from './getBoardById';
import { getTabBoard } from './getTabBoard';

export const getBoardLogoIcon = (
    state: IApplicationState,
    boardId: TBoardId
): string => {
    let board = getBoard(state, boardId);

    if (!board || !board.logoIcon) {
        board = getTabBoard(state, boardId);
    }

    if (!board || !board.logoIcon) {
        board = getUserRecentBoard(state, boardId);
    }

    return board && board.logoIcon;
}
