import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { IMilestoneButtonHOCProps } from './types';
import { onClick } from './events/onClick';
import { getCard } from '../../../../../../../store/model/selectors/getCard';
import { IMilestoneButtonEvents, IMilestoneButtonFields } from '../../components/MilestoneButton/types';
import { MilestoneButton } from '../../components/MilestoneButton/MilestoneButton';
import { getSectionType } from '../../../selectors/getSectionType';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { TStatus } from '../../../../../../../types/model';
import { getCardSubcardsCount } from '../../../../../../../store/model/selectors/getCardSubcardsCount';
import { getIsUserBasicPlanOnly } from '../../../../../../../store/model/authUser/selectors/getIsUserBasicPlanOnly';

const mapStateToProps = (
    state: IApplicationState,
    { boardId, cardId, isShowTooltip }: IMilestoneButtonHOCProps
): IMilestoneButtonFields => {
    const card = getCard(state, cardId);
    const sectionType = getSectionType(state, boardId, cardId, null, 'allowGantt');
    const isDisallow = sectionType === ESettingsSectionType.DISALLOW;
    const isDisabled = !!getCardSubcardsCount(state, cardId);
    const isShow = (
        (
            card.status === TStatus.STATUS_ACTIVE ||
            card.status === TStatus.STATUS_SERVICE_TEMPLATE
        ) && (
            sectionType === ESettingsSectionType.DEFAULT ||
            isDisallow
        )
    );

    return {
        isBasicFeature: getIsUserBasicPlanOnly(state),
        isDisabled,
        isDisallow,
        isMilestone: card.isMilestone,
        isShow,
        isShowTooltip
    }
};
const mapDispatchToProps = (
    dispatch: any,
    props: IMilestoneButtonHOCProps
): IMilestoneButtonEvents => {
    const {boardId, cardId} = props;
    return {
        onClick: () => dispatch(onClick(boardId, cardId)),
    }
};
export const MilestoneButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(MilestoneButton);

MilestoneButtonHOC.displayName = 'MilestoneButtonHOC';
