import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../types/types';
import { getAuthUser } from '../../../../../../../../store/model/authUser/selectors/getAuthUser';
import {
    authUserSetIsAttachmentViewTypeGrid
} from '../../../../../../../../rest/effects/authUser/authUserSetIsAttachmentViewTypeGrid';
import { SegmentBoardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import {
    SegmentBoardOption,
    SegmentBoardViewType
} from '../../../../../../../../middlewares/segment/trackEntities/boardEvents';

export const onViewTypeChange = (
    isGridView: boolean
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const authUser = getAuthUser(getState());
        dispatch(authUserSetIsAttachmentViewTypeGrid(authUser.id, isGridView));
        dispatch(segmentTrackAction(SegmentBoardEvent.BOARD_ATTACHMENTS_VIEW_TYPE_CLICKED, {
            name: SegmentBoardOption.TYPE,
            value: isGridView ? SegmentBoardViewType.GRID : SegmentBoardViewType.LIST
        }));
    };
    return action;
};
