import { ICardDescriptionAction } from '../actions/types';
import { AT_SET_CURRENT_NOT_FILLED_CARD_ID } from '../actions/constants';
import { INotFilledCards } from './types';

export const initialState: INotFilledCards = {
    currentNotFilledCardId: null
}

export const notFilledCardsReducer = (
    state: INotFilledCards = initialState,
    action: ICardDescriptionAction
): INotFilledCards => {
    switch (action.type) {
        case AT_SET_CURRENT_NOT_FILLED_CARD_ID : {
            return {
                ...state,
                currentNotFilledCardId: action.cardId
            };
        }
        default: {
            return state;
        }
    }
};
