import { IGetState, TBoardId, TCardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { getSectionType } from '../../../../selectors/getSectionType';
import { ESettingsSectionType } from '../../../../../../base/components/SettingsSection/types';
import { showSnoozeBlocker } from '../../../../../../base/effects/showSnoozeBlocker';
import { disallowedSectionClickedEvent } from '../../../../effects/segmentEvents';
import {
    SegmentCardEvent,
    SegmentCardOptions,
    SegmentCardSection,
    segmentTrackAction
} from '../../../../../../../../middlewares/segment';
import { SegmentCardSourceValue } from 'app/middlewares/segment/trackEntities/cardEvents';

export const onCardMakeRecurring = (
    cardId: TCardId,
    boardId: TBoardId,
    onActive: () => void
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const sectionType = getSectionType(getState(), boardId, cardId, null, 'allowRecurringTask');
        const isDisallow = sectionType === ESettingsSectionType.DISALLOW;
        if (isDisallow) {
            dispatch(showSnoozeBlocker({
                allow: 'allowRecurringTask'
            }));
            dispatch(disallowedSectionClickedEvent(SegmentCardSection.MAKE_AS_RECURRING, SegmentCardSourceValue.BUTTONS_PANEL));
        } else {
            onActive();
            dispatch(segmentTrackAction(SegmentCardEvent.BUTTONS_PANEL_BUTTON_CLICKED, {
                name: SegmentCardOptions.TARGET,
                value: SegmentCardSection.MAKE_AS_RECURRING
            }))
        }
    };
    return action;
};
