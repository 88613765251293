import * as React from 'react';
import { IActivityItemNameProps } from './types';
import './_ActivityItemName.scss';
import { CLASS_ACTIVITY_ITEM } from '../constants';
import { escape } from 'underscore';
import { Tooltip } from 'kui';
import { getAsidePanelTooltip } from '../../../../../asidePanel/helpers/getAsidePanelTooltip';
import ReactHtmlParser from 'react-html-parser';
import { clearMarkdown } from '../../../../../../base/helpers/clearMarkdownHelper';

export function ActivityItemName({
    icon,
    isMarkdown,
    isRemove,
    name,
    tooltip,
    style,
    onClick
}: IActivityItemNameProps) {
    const className = CLASS_ACTIVITY_ITEM + '__name';
    const classNameTooltip = className + '--tooltip'
    return <Tooltip {...getAsidePanelTooltip({
                value: escape(clearMarkdown(tooltip || name)),
                className: classNameTooltip,
                direction: 'up-right'}
            )
        }>
        <div
            className={`${className} ${isRemove ? className + '--remove' : ''}`}
            style={style}
            onClick={onClick}
        >
            {icon}
            <div className={className + '-text'}>{ReactHtmlParser(isMarkdown ? name : escape(name))}</div>
        </div>
    </Tooltip>;
}
