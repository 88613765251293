import * as React from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../types/types';
import { ISnackbarsStackOwnProps, ISnackbarsStackProps } from './types';
import { snackbarTopIdRenderedSelector } from '../../selectors/snackbarTopIdRenderedSelector';
import { SnackbarHOC } from '../SnackbarHOC/SnackbarHOC';

const SnackbarsStack = (props: ISnackbarsStackProps) => {
    const { snackbarId } = props;
    return (snackbarId
        ? (<SnackbarHOC snackbarId = {snackbarId}/>)
        : null
    );
};

const mapStateToProps = (
    state: IApplicationState
): ISnackbarsStackOwnProps => {
    const snackbarTopId = snackbarTopIdRenderedSelector(state);
    return {
       snackbarId: snackbarTopId
    }
};

export const SnackbarsStackHOC = connect(mapStateToProps)(SnackbarsStack);
SnackbarsStackHOC.displayName = 'SnackbarsStackHOC';
