import {IGetState, TCardId} from '../../../../../../../../types/types';
import {Dispatch, ThunkAction} from '../../../../../../../../types/actions';
import {getSelectedCards} from '../../../../../../clickManager/selectors/getSelectedCards';
import {cardSetAssignees} from '../../../../../../../../rest/effects/card/card/cardSetAssignees';
import {checklistsDeleteUser} from '../../../../../../../../rest/effects/card/checklist/checklistsDeleteUser';
import {segmentTrackAction} from '../../../../../../../../middlewares/segment';
import {SegmentMultiSelectEvent} from '../../../../../../../../middlewares/segment/trackEntities/multiselectEvents';
import {getCardAssignees} from '../../../../../../../../store/model/selectors/getCardAssignees';

export const deselectUser = (
    boardId: TCardId,
    permissionId: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const cardIds = getSelectedCards(state, boardId);

        if (!cardIds) return;

        cardIds.forEach(cardId => {
            const assignees = [...getCardAssignees(state, cardId)];
            const index = assignees.findIndex((user) => user.sharedUser.permissionId === permissionId);
            if (index >= 0) {
                assignees.splice(index, 1);
                dispatch(cardSetAssignees(cardId, assignees));
                dispatch(checklistsDeleteUser(cardId, permissionId));
            }
        });
        dispatch(segmentTrackAction(SegmentMultiSelectEvent.USER_UNASSIGNED));
    };
    return action;
};
