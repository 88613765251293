import { ICards } from '../cards/types';
import { IApplicationState, TCardId } from '../../../types/types';
import { getCard } from './getCard';
import { ICard } from '../card/types';
import { getCardSubcards } from './getCardSubcards';
import { IChecklistStats, ISubcardsStats } from '../../../types/model';
import { getCardInitial } from '../card/constants';
import { getCardDone } from './getCardDone';
import { getSubcardWeight } from './getSubcardWeight';
import { ICardDetailsSubcard } from '../../../view/react_components/aside_panel/cardDetails/SubcardsSection/components/CardSubcardsElements/types';
import { ICardSubcard } from '../../../view/react_components/main/kanbanView/components/CardSubcards/components/CardSubcards/types';

export const calcCardProcessingPercent = (
    state: IApplicationState,
    cardId: TCardId,
    cards: ICards = {},
): number => {
    const card = getCard(state, cardId);
    let processingPercent = getCardDone(state, cardId) ? 100 : 0;
    if(isCardHasChecklists(card, cards) && isEpic(card)){
        /**
         * Процент равен сумме процентов всех сабкарт + проценты чеклистов делённое на колличество сабкарт + 1
         * @type {number}
         */
        processingPercent = (getSubCardsTotalProcessingPercent(state, card, cards) + getChecklistProgress(card, cards)) / (getSubCardCount(state, card, cards) + 1)
    } else if(isCardHasChecklists(card, cards)){
        /**
         * Процент равен проценту чеклистов
         * @type {number}
         */
        processingPercent = getChecklistProgress(card, cards);
    } else if(isEpic(card)) {
        /**
         * Процент равен сумме процентов всех сабкарт делённое на колличество сабкарт
         * @type {number}
         */
        processingPercent = getSubCardsTotalProcessingPercent(state, card, cards) / getSubCardCount(state, card, cards)
    }
    return processingPercent ? Math.round(processingPercent): processingPercent ;
}

const isCardHasChecklists = (
    card: ICard,
    cards: ICards
): boolean => {
    return Boolean(getChecklistStats(card, cards).totalCount > 0);
}

const isEpic = (
    card: ICard
): boolean => {
    return card.subCardIds && card.subCardIds.length > 0;
}

const getSubCardCount = (
    state: IApplicationState,
    card: ICard,
    cards: ICards,
): number => {
    let count = 0;
    getCardSubcards(state, card.id).forEach( subcard => {
        count += getSubcardWeightValue(state, cards, subcard.id);
    })
    return count;
}

const getSubCardsTotalProcessingPercent =(
    state: IApplicationState,
    card: ICard,
    cards: ICards,
): number => {
    let progress = 0;
    getCardSubcards(state, card.id).forEach( subcard => {
        const subcardProcessingPercent = cards[subcard.id] && cards[subcard.id].processingPercent !== undefined ? cards[subcard.id].processingPercent : subcard.processingPercent;
        progress += getSubcardWeightValue(state, cards, subcard.id) * subcardProcessingPercent;
    })
    return progress;
}

const getChecklistProgress = (
    card: ICard,
    cards: ICards,
): number => {
    return getChecklistStats(card, cards).checkedPercent;
}

const getSubcardWeightValue = (
    state: IApplicationState,
    cards: ICards,
    subcardId: TCardId
): number => {
    return cards[subcardId] && cards[subcardId].meta && cards[subcardId].meta.subcardWeight || getSubcardWeight(state, subcardId);
}

const getChecklistStats = (
    card: ICard,
    cards: ICards,
): IChecklistStats => {
    const checklistStats = cards[card.id] && cards[card.id].checklistStats !== undefined
        ? cards[card.id].checklistStats
        : card.checklistStats;
    return checklistStats || getCardInitial().checklistStats;
}

export const getSubcardsStats =(
    state: IApplicationState,
    cardId: TCardId,
    elements: ICardDetailsSubcard[] | ICardSubcard[],
): ISubcardsStats => {
    const subcardsStats: ISubcardsStats = {
        donePercent: 0,
        totalCount: 0,
        doneCount: 0
    };
    if (!!elements && elements.length > 0) {
        let doneCount = 0;
        let subcards: ICards = {};
        let totalWeighedCount = 0;
        elements.forEach(subcard => {
            subcards[subcard.id] = getCard(state, subcard.id);
            if (
                subcard.processingStatus === 'done' ||
                subcard.processingPercent === 100
            ) {
                doneCount++;
            }
            totalWeighedCount += !!subcard.weight ? subcard.weight : 1;
        });
        subcardsStats.totalCount = elements.length;
        subcardsStats.doneCount = doneCount;
        subcardsStats.donePercent = doneCount === elements.length
            ? 100
            : Math.round(getSubCardsTotalProcessingPercent(state, getCard(state, cardId), subcards) / totalWeighedCount);
    }
    return subcardsStats;
};
