import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { ICommentHOCProps } from './types';
import { Comment } from '../../components/Comment/Comment';
import { ICardCommentEvents, ICardCommentFields } from '../../components/Comment/types';
import { onRemove } from './events/onRemove';
import { getAuthUser } from '../../../../../../../store/model/authUser/selectors/getAuthUser';
import { onReply } from './events/onReply';
import { onGetLink } from './events/onGetLink';
import { getEditCommentId } from '../../../../../../../store/router/selectors/getEditCommentId';
import { getCommentById } from '../../selectors/getCommentById';
import { onEdit } from './events/onEdit';
import { getCommentReply } from './selectors/getCommentReply';
import { onAttachmentReply } from './events/onAttachmentReply';
import { getCommentReplyAttachment } from './selectors/getCommentReplyAttachment';
import { getDriveDoc } from '../../../../../../../store/model/driveDocs/selectors/getDriveDoc';
import { onAttachmentClick } from './events/onAttachmentClick';
import { getCommentReplyText } from './selectors/getCommentReplyText';
import { getCommentDriveDocsByIds } from '../../selectors/getCommentDriveDocsByIds';
import { isEqual } from 'underscore';
import { getCardIsLoading } from '../../../../../../../store/requestsState/selectors/getCardIsLoading';

// const analyze = new Analyze('CommentHOC');
// 10 раз открыл-закрыл карту
// noReselect               average: 0.041 ms, calls: 268, total: 10.900 ms
// createDeepEqualSelector  average: 0.031 ms, calls: 280, total: 8.800 ms
// cache                    average: 0.034 ms, calls: 280, total: 9.400 ms // чуть дольше, но тут много свойств объектов

const mapStateToProps = () => {
    interface ICaheProps extends ICardCommentFields{};
    let cache: ICaheProps = null;
    const getCache = (
        value: ICaheProps
    ): ICaheProps => {
        if (isEqual(value, cache)) {
            return cache;
        }
        cache = value;
        return value;
    };

    return (
        state: IApplicationState,
        { comment, cardId, isMyComment, isReadonly }: ICommentHOCProps
    ): ICardCommentFields => {
        // analyze.start();
        const replyCommentId = getCommentReply(state, cardId, comment.id);
        const replyAttachmentId = getCommentReplyAttachment(state, cardId, comment.id);
        const commentDriveDocs = getCommentDriveDocsByIds(state, comment.id, comment.driveDocIds);

        const props = getCache({
            ...comment,
            cardId,
            isMyComment,
            isActive: !getCardIsLoading(state, cardId) && getEditCommentId(state) === comment.id,
            isReadonly,
            driveDocs: commentDriveDocs.sort((attach1, attach2) => attach1.orderNumber - attach2.orderNumber),
            replyAttachment: replyAttachmentId && getDriveDoc(state, replyAttachmentId),
            replyComment: replyCommentId && getCommentById(state, cardId, replyCommentId),
            replyText: replyCommentId && getCommentReplyText(state, cardId, comment.id),
            userHash: getAuthUser(state).hash,
        });
        // analyze.finish();
        return props;
    };
}

const mapDispatchToProps = (
    dispatch: any,
    { comment, cardId, onEdit: onOwnEdit }: ICommentHOCProps
): ICardCommentEvents => {
    return {
        onAttachmentClick: (id) => dispatch(onAttachmentClick(id)),
        onAttachmentReply: (id) => dispatch(onAttachmentReply(comment.id, id)),
        onGetLink: () => dispatch(onGetLink(cardId, comment.id)),
        onRemove: () => dispatch(onRemove(cardId, comment.id)),
        onReply: (text) => dispatch(onReply(cardId, comment.id, text)),
        onEdit: () => dispatch(onEdit(cardId, comment.id, onOwnEdit))
    };
};

export const CommentHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(Comment);

CommentHOC.displayName = 'CommentHOC';
