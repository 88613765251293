import { AT_UPDATE, IUpdateAction } from './types';
import { ICollaborator } from '../types';

export const updateAction = (
    collaborator: ICollaborator
): IUpdateAction => {
    return {
        type: AT_UPDATE,
        collaborator
    }
};
