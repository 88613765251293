import * as React from 'react';
import { createRoot, Root } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import { LazyWrapper } from '../lazy/lazy_wrapper';

export class UserSettingsReactToBackbone {
    root: Root;
    store: Store;

    constructor (el: Element, store: Store) {
        this.store = store;
        this.root = createRoot(el!);
        this.render();
    }

    render () {
        const ImportElement = React.lazy(() =>
            import(/* webpackChunkName: "userSettings" */ '../dialogs/userSettings/userSettingsDialog/hocs/UserSettingsDialogHOC')
                .then(module => ({default: module.UserSettingsDialogHOC} as any))
                .catch(e => console.error(e))
        );

        this.root.render(
            <LazyWrapper>
                <Provider store={this.store}>
                    <ImportElement />
                </Provider>
            </LazyWrapper>
        );
    }

    remove () {
        this.root.unmount();
    }
}
