import { IApplicationState, TBoardId } from '../../../../../types/types';
import { getBoardPermissions } from '../../../../../store/model/selectors/getBoardPermissions';
import { getBoardPermissionsAllowEdit } from '../../../../../store/model/selectors/getBoardPermissionsAllowEdit';

export const getBoardPermissionsIsReadonly = (
    state: IApplicationState,
    boardId: TBoardId
): boolean => {
    return !getBoardPermissionsAllowEdit(state, boardId);
}
