import { IGetState, TCardId } from '../../../../../../../../types/types';
import { getActiveCardId } from '../../../../../../../../store/router/selectors/getActiveCardId';

import { cardOpen } from '../../../../../../base/effects/cardOpen';
import { Dispatch } from '../../../../../../../../types/actions';

export const openCardIfNeeded = (
    cardId: TCardId
) => {
    return (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const activeCardId = getActiveCardId(getState());
        if (cardId !== activeCardId) {
            dispatch(cardOpen(cardId));
        }
    }
}
