export function updateScopeList<T>(scope: T, list: T[]): T[] {
    let result = [...list];
    const index = result.indexOf(scope);
    if (index < 0) {
        result.push(scope);
    } else {
        result.splice(index, 1);
    }
    return result;
}
