import { Util } from '../../../../../util/util';
import { isInsertPerformance } from '../selectors/isInsertPerformance';
import { segmentResetAction } from '../../../../../middlewares/segment';
import { Dispatch } from '../../../../../types/actions';
import { IGetState } from '../../../../../types/types';
import { root } from '../../../../../store/constants';

export const clearPerformanceScripts = (
) => {
    const action = (dispatch: Dispatch, getState: IGetState) => {
        const insertPerformance = isInsertPerformance();

        if (insertPerformance) {
            dispatch(segmentResetAction());
            removeIntercom();
        }
    };
    return action;
};

const removeIntercom = () => {
    const cookies = [
        'intercom-session-' + root.Settings.intercomId,
        'intercom-id-' + root.Settings.intercomId
    ];

    Util.eraseCookies(cookies, '.' + location.hostname);
};
