export const ID_ASIDE_PANEL_ACTIONS_PORTAL = 'aside-panel-actions-portal';
export const ID_ASIDE_PANEL_ACTIONS_PORTAL_MOBILE = 'aside-panel-actions-portal-mobile';
export const ID_ASIDE_PANEL_TOOLTIPS_PORTAL = 'aside-panel-tooltips-portal';
export const ID_ASIDE_PANEL_PORTAL = 'aside-panel-portal';
export const ID_ASIDE_PANEL_MENTIONS_PORTAL = 'aside-panel-mentions-portal';
export const ID_ASIDE_PANEL_DATEPICKER_PORTAL = 'aside-panel-datepicker-portal';
export const ID_ASIDE_PANEL_BODY_PORTAL = 'aside-panel-body-portal';

export const CLASS_ASIDE_PANEL_ACTIONS_POPUP = 'aside-panel-actions-popup';
export const CLASS_ASIDE_PANEL_ACTIONS_BUTTON_CONTAINER = 'aside-panel__actions-button';
export const CLASS_ASIDE_PANEL_ACTIONS_BUTTON = 'aside-panel__actions-button-button';
export const CLASS_ASIDE_PANEL_ACTIONS_BUTTON_MORE = 'aside-panel__actions-button-more';
export const CLASS_ASIDE_PANEL_ACTIONS_BUTTON_HIGHLIGHT = 'aside-panel__actions-button-highlight';

export const ACTIVE_SECTION_TIMER = 1000; // ms
