import { IGetState, TBoardId, TCardId } from '../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { getMessages, root } from '../../../../../store/constants';
import { getBoard } from '../../../../../store/model/selectors/getBoardById';
import { copyRest } from '../copyRest';
import { COPY_BOARD_COPY_PREFIX, DASHBOARD_LIMIT } from '../../../../../const';
import { IBoard } from '../../../../../store/model/board/types';
import { cardsCopy } from '../../../list/cardsCopy';
import { IRestCard } from '../../../../../types/rest/IRestCard';
import { getBoardCardsActiveAndArchive } from '../../../../../store/model/selectors/getBoardCardsActiveAndArchive';
import { ICard } from '../../../../../store/model/card/types';
import { flatArray } from '../../../../helpers/flatArray';
import { cardsRestPatchBulk } from '../../../card/card/api/helper/cardsRestPatchBulk';
import { getCardPredecessorsDependencies } from '../../../../../store/model/dependencies/dependencies/selectors/getCardPredecessorsDependencies';
import { dependenciesCopy } from '../../../card/dependence/api/helper/dependenciesCopy';
import { IDependency } from '../../../../../store/model/dependencies/dependency/types';
import { getTabIds } from '../../../../../store/model/selectors/getTabIds';
import { boardOpen } from '../../../../../view/react_components/base/effects/boardOpen';
import { addRecentOpenBoard } from '../../../authUser/recentBoards/addRecentOpenBoard';
import { BoardLoadManager } from 'app/helper/BoardLoadManager';

export const boardRestCopy = (
    boardId: TBoardId,
    prefix: string = COPY_BOARD_COPY_PREFIX,
    template: boolean = false,
    openEditPage: boolean = true,
    disableHistory: boolean = false,
    replaceAssignedAuthor: boolean = false,
    disableCopiedFrom: boolean = false,
    setAuthUserToCardsAuthor: boolean = false,
    loadArchive: boolean = true,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const board = getBoard(getState(), boardId);
        if (!board || !board.id) return Promise.reject(getMessages().getText('error.record.not_found'));

        return (loadArchive? root.App.controller.loadBoardArchiveToStore(boardId) : Promise.resolve())
            .then(() => {
                return dispatch(copyRest(boardId, prefix, template));
            })
            .then((newBoard: IBoard) => {
                if (newBoard.lists.length) {
                    const promises = board.listIds.map(fromListId => {
                        const toList = newBoard.lists.find((list) => list.copiedFromId === fromListId);
                        if (!toList) return Promise.resolve([]);
                        return dispatch(cardsCopy(
                            newBoard,
                            fromListId,
                            toList.id,
                            true,
                            {
                                copyCardNumber: true,
                                copyComments: true,
                                setAuthUserToCardsAuthor,
                                resetAssigners: !!template,
                                resetProgress: !!template,
                                disableHistory,
                                replaceAssignedAuthor,
                                disableCopiedFrom
                            }
                        )).then((result: IRestCard[]) => {
                            toList.cards.push(...result);
                            return result;
                        })
                    })
                    return Promise.all(promises)
                        .then((results: Array<IRestCard[]>) => {
                            const cards = flatArray(results);

                            return Promise.all([
                                dispatch(processSubcards(boardId, cards)),
                                dispatch(processDependencies(boardId, cards))
                            ]);
                        })
                        .then(() => {
                            return newBoard;
                        })
                }
                return newBoard;
            })
            .then((newBoard: IBoard)=>{
                new BoardLoadManager().putBoardToStore(newBoard, false)
                return newBoard;
            })
            .then((newBoard: IBoard) => {
                dispatch(openCopiedBoard(newBoard.id, boardId, openEditPage));
                return newBoard;
            });
    };
    return action;
};

const openCopiedBoard = (
    newBoardId: TBoardId,
    oldBoardId: TBoardId,
    openEditPage: boolean = true
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const tabIds = getTabIds(state);
        const action = openEditPage ? 'edit' : null;
        if (tabIds.length + 1 > DASHBOARD_LIMIT) {
            const openInNewTabCallback = () => {
                root.App.vent.trigger(root.App.vent['dialog:closed']); // close 'progress' dialogue
                window.open(root.App.router.getPageUrl(newBoardId, 'edit'));
            };
            const reuseOriginalTab = () => {
                const currentBoardUrl: string = root.App.router.getUrl(action);
                const newUrl = currentBoardUrl.replace(new RegExp(oldBoardId.toString(), 'g'), newBoardId.toString());
                dispatch(addRecentOpenBoard(oldBoardId));
                root.App.router.navigate(newUrl, { trigger: true });
            }

            root.App.controller.showAlert({
                message: getMessages().getText('popup.dashboard.no_slots_for_copied'),
                header: getMessages().getText('popup.dashboard.limit.exceeded.header'),
                type: 'question',
                buttons: [
                    {
                        text: getMessages().getText('popup.dashboard.open_copied_in_new_tab'),
                        click: openInNewTabCallback,
                        testClass: 'js-another-tab'
                    },
                    {
                        text: getMessages().getText('popup.dashboard.reuse_original_tab'),
                        click: reuseOriginalTab,
                        testClass: 'js-replace-original'
                    }
                ]
            });
        } else {
            dispatch(boardOpen(newBoardId, action));
        }
    };
    return action;
}

const getCardByCopedFromId = (
    copiedFromId: TCardId,
    newCards: IRestCard[]
): ICard => {
    return newCards.find(newCard => newCard.copiedFromId === copiedFromId);
}

const processSubcards = (
    fromBoardId: TBoardId,
    newCards: IRestCard[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const boardCards = getBoardCardsActiveAndArchive(state, fromBoardId);
        const oldSubcards = boardCards.filter(card => !!card.epicId);
        const cardsToSave: IRestCard[] = [];
        oldSubcards.forEach(oldSubcard => {
            const newSubcard = getCardByCopedFromId(oldSubcard.id, newCards);
            const newEpic = getCardByCopedFromId(oldSubcard.epicId, newCards);
            if (newSubcard && newEpic) {
                cardsToSave.push({
                    id: newSubcard.id,
                    epicId: newEpic.id
                })
                newSubcard.epicId = newEpic.id;
            }
        })
        return cardsToSave.length ? dispatch(cardsRestPatchBulk(cardsToSave)) : null;
    };
    return action;
}

const processDependencies = (
    fromBoardId: TBoardId,
    newCards: IRestCard[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const boardCards = getBoardCardsActiveAndArchive(state, fromBoardId);
        const predecessorToSave: IDependency[] = [];
        boardCards.forEach(oldCard => {
            const oldPredecessors = getCardPredecessorsDependencies(state, oldCard.id);
            const newCard = getCardByCopedFromId(oldCard.id, newCards);
            oldPredecessors.forEach(oldPredecessor => {
                const newPredecessor = getCardByCopedFromId(oldPredecessor.predecessorId, newCards);
                if (newCard && newPredecessor) {
                    predecessorToSave.push({
                        successorId: newCard.id,
                        predecessorId: newPredecessor.id,
                        type: oldPredecessor.type
                    })
                }
            })
        })
        return predecessorToSave.length ? dispatch(dependenciesCopy(predecessorToSave)) : null;
    };
    return action;
}
