import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { IPanelActionsHOCProps } from './types';
import { PanelActions } from '../../components/PanelActions/PanelActions';
import { IPanelActionsFields } from '../../components/PanelActions/types';
import { getCard } from '../../../../../../../store/model/selectors/getCard';
import { getAsidePanelButtonsPanelIsMinimized } from '../../../../asidePanel/store/selectors/getAsidePanelButtonsPanelIsMinimized';
import { getAuthUserPlatformType } from 'app/store/model/authUser/selectors/getAuthUserPlatformType';

const mapStateToProps = (
    state: IApplicationState,
    ownProps: IPanelActionsHOCProps
): IPanelActionsFields => {
    const { boardId, cardId } = ownProps;
    let isShow = false;
    let isMinimized = false;
    const card = getCard(state, cardId);

    if (card) {
        isShow = true;
        isMinimized = getAsidePanelButtonsPanelIsMinimized(state);
    }

    return {
        boardId,
        cardId,
        isMinimized,
        isShow,
        platformType: getAuthUserPlatformType(state),
    }
};

export const PanelActionsHOC = connect(
    mapStateToProps,
    null
)(PanelActions);

PanelActionsHOC.displayName = 'PanelActionsHOC';
