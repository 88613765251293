import { Dispatch, ThunkAction } from 'app/types/actions';
import { fetchHandler } from 'app/util/fetchHandler';
import Util from '../../../../util/util';
import { getRestHeadersPatch } from '../../../helpers/getRestHeadersHelper';
import { IAuthUser } from 'app/store/model/authUser/types';
import { REST_AUTH_USER } from '../../../constants';
import { IRestAuthUser } from 'app/types/rest/IRestAuthUser';

export const patchRest = (
    authUser: IAuthUser
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        return fetchHandler<IRestAuthUser>(
            Util.getApiUrl(REST_AUTH_USER + authUser.id), {
                ...getRestHeadersPatch(),
                body: JSON.stringify(authUser)
            }
        );
    };
    return action;
};
