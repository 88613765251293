import * as React from 'react';
import { AsidePanelActionsButton } from '../../../..';
import { BOARD_BUTTON_ARCHIVE } from './constants';
import { IArchiveButtonProps } from './types';

export function ArchiveButton ({
    isBasicFeature,
    isDisallow,
    isProFeature,
    isShow,
    tooltip,
    onClick,
}: IArchiveButtonProps) {
    return (
        <AsidePanelActionsButton
            isDisabled={isDisallow}
            title={BOARD_BUTTON_ARCHIVE}
            tooltip={tooltip}
            isShow={isShow}
            onClick={onClick}
            isBasicFeature={isBasicFeature}
            isProFeature={isProFeature}
        />
    );
};
