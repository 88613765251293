
export enum ERoutes {
    INDEX = 'index',
    FALLBACK = 'fallback',
    BOARD = 'board',
    BOARD_ACTIVITY = 'boardActivity',
    BOARD_ATTACHMENTS = 'boardAttachments',
    BOARD_BACKUPS = 'boardBackups',
    BOARD_DESIGN = 'boardDesign',
    BOARD_EDIT = 'editBoard',
    BOARD_EDIT_SCROLL = 'editBoardWithScroll',
    BOARD_EXPORT = 'boardExport',
    BOARD_FILTER = 'boardFilter',
    BOARD_COPY = 'copyBoard',
    CARD_ACTIVITY = 'cardActivity',
    CARD_TIMING = 'cardTiming',
    CARD_PRINT = 'cardPrint',
    EDIT_CARD = 'editCard',
    EDIT_LIST = 'editList',
    LINK_CARD = 'linkCard',
    LINK_CARD_ACTIVITY = 'linkCardActivity',
    LINK_CARD_TIMING = 'linkCardTiming',
    LIST_PRINT_PREVIEW = 'listPrintPreview',
    SELECT_CARDS = 'selectCards',
    ASSIGNED_TO_ME = 'assignedToMe',
    ASSIGNED_TO_ME_IMPORT = 'assignedToMeImport',
    ASSIGNED_TO_ME_SETTINGS = 'assignedToMeSettings',
    ASSIGNED_TO_ME_BACKGROUND = 'assignedToMeBackground',
    ASSIGNED_TO_ME_CARD = 'assignedToMeCard',
    ASSIGNED_TO_ME_CARD_ACTIVITY = 'assignedToMeCardActivity',
    ASSIGNED_TO_ME_CARD_TIMING = 'assignedToMeCardTiming',
    ASSIGNED_TO_ME_NOTIFICATION = 'assignedToMeNotification',
    ASSIGNED_TO_ME_NOTIFICATION_ACTIVITY = 'assignedToMeNotificationActivity',
    ASSIGNED_TO_ME_NOTIFICATION_TIMING = 'assignedToMeNotificationTiming',
    ASSIGNED_TO_ME_FILTER = 'assignedToMeFilter',
    ONEDRIVE= 'onedrive',
    CARD_TEMPLATES = 'cardTemplates',
    RECURRING_TASKS = 'recurringTasks',
    PROMO_CODE = 'promoCode',
    PROMO_CODE_2 = 'promoCode2',
    GSHARE = 'googleGroupsSharing',
    MS_GROUP_PERMISSION = 'microsoftGroupPermission',
    G_SPREADSHEET_PERMISSIONS = 'googleSpreadsheetPermissions',
    TEST = 'test',
    DELETE_ACCOUNT = 'deleteAccount',
    ACCESSIBILITY_STATEMENT = 'accessibilityStatement',
    PRICING = 'pricing',
    _404 = '_404'
}

export enum EPanelRoute {
    ACTIVITY = 'activity',
    ATTACHMENTS = 'attachments',
    BACKUPS = 'backups',
    DESIGN = 'design',
    EDIT = 'edit',
    EXPORT = 'export',
    TIMING = 'timing',
    IMPORT_CARDS = 'import',
    VIEW_SETTINGS = 'settings',
    BACKGROUND = 'background'
}

export enum ERoutePrefixes {
    atm = 'atm',
    notification = 'n'
}
