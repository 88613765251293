import _ from 'underscore';

App.Models.StatBase = Backbone.Model.extend({
    rest: '/',

    initialize: function(options) {
        this.options = options || {};
        this.serverLink = App.settings.statServerUrl;
    },

    url: function() {
        if (_.isFunction(this.rest)) {
            return this.serverLink + this.rest();
        }
        return this.serverLink + this.rest;
    },

    setOptions: function(options) {
        options = options || {};
        for (var key in options) {
            this.options[key] = options[key];
        }
    },

    onRead: function() {
        return {};
    },
    onCreate: function() {
        return {};
    },
    onUpdate: function() {
        return {};
    },
    onComplete: function(){

    },


    sync: function(method, model, options) {
        options = options || {};
        var result = {method: method, model: model, options: options};
        if (method === 'read') {
            result = _.extend(result, this.onRead(method, model, options));
        }
        if (method === 'create') {
            result = _.extend(result, this.onCreate(method, model, options));
        }
        if (method === 'update') {
            result = _.extend(result, this.onUpdate(method, model, options));
        }
        var onComplete = result.options.complete;
        result.options.complete = _.bind(function(){
            this.onComplete();
            if (onComplete){
                onComplete();
            }
        }, this);
        var onError = result.options.error;
        result.options.error = _.bind(function(xhr, textStatus, errorThrown) {
            if (this.onError && _.isFunction(this.onError)) {
                this.onError(xhr, textStatus, errorThrown);
            }
            if (onError) {
                onError(xhr, textStatus, errorThrown);
            }
        }, this);
        options.allowError = true;
        return Backbone.sync.call(result.model, result.method, result.model, result.options);
    }
});
