import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { IGetState, TBoardId } from '../../../../../../../../../types/types';
import { getMessages, root } from '../../../../../../../../../store/constants';
import { getCardsWithCardNumber } from '../selectors/getCardsWithCardNumber';
import { SNACKBAR_BOARD_CARD_PREFIXES_ID, SNACKBAR_BOARD_CARD_PREFIXES_TIMER } from '../../../constants';
import { ICards } from '../../../../../../../../../store/model/cards/types';
import { cardsUpdate } from '../../../../../../../../../store/model/actionCreators/cardsUpdate';
import { cardsRestPatch } from '../../../../../../../../../rest/effects/card/card/api/helper/cardsRestPatch';
import { snackbarUndoDefault } from '../../../../../../../snackbarsStack/effects/variantUndo/snackbarUndoDefault';
import { getBoard } from '../../../../../../../../../store/model/selectors/getBoardById';
import { getDefaultActionEvent } from '../../../../../../../snackbarsStack/effects/helpers/getDefaultActionEvent';
import { SNACKBAR_BUTTON_OK, SNACKBAR_BUTTON_UNDO } from '../../../../../../../snackbarsStack/effects/constants';
import { CLASS_ACTION_MODAL } from '../../../../../../../base/components/ActionModal/constants';
import { ICard } from '../../../../../../../../../store/model/card/types';
import { cardGenerateCardNumber } from '../../../../../../../../../rest/effects/card/card/cardGenerateCardNumber';

export const removePrefixes = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        root.App.controller.boardCardPrefixInfo.resetBoarCardPrefixInfo();
        const cards = getCardsWithCardNumber(getState(), boardId);
        if (!cards.length) return Promise.resolve();

        const cardsForUpdate: ICards = {};

        cards.forEach(card => {
            root.App.controller.boardCardPrefixInfo.addedCardsCount = root.App.controller.boardCardPrefixInfo.totalCardsCount - cards.length;
            root.App.controller.boardCardPrefixInfo.addCardForRollback({ id: card.id, cardNumber: card.cardNumber || '' });
            cardsForUpdate[card.id] = { cardNumber: '' };
        });

        return dispatch(cardsRestPatch(cardsForUpdate));
    }
    return action;
}
