import { TBoardId } from '../../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { notificationSetPeriod } from '../../../../../../../../../rest/effects/notificationSettings/notificationSetPeriod';
import { segmentTrackAction } from '../../../../../../../../../middlewares/segment';
import { periodEventsMap } from '../constants';
import { ENotificationPeriod } from '../../../../../../../../../types/rest/IRestNotificationSettings';

export const onPeriodChange = (
    boardId: TBoardId,
    period: number
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        dispatch(notificationSetPeriod(boardId, period));
        if (periodEventsMap[period as ENotificationPeriod]) {
            dispatch(segmentTrackAction(periodEventsMap[period as ENotificationPeriod]));
        }
    };
    return action;
};
