import { IApplicationState, TCardId } from '../../../types/types';
import { ICard } from '../card/types';
import { getCardSubcards } from './getCardSubcards';
import { getCardIsEpic } from './getCardIsEpic';

export const getCardSubcardsTreeWithOrder = (
    state: IApplicationState,
    cardId: TCardId
) => {
    const getCardSubcardsTreeWithOrderRecurse = (
        cardId: TCardId,
        result: ICard[]
    ) => {
        const subcards = getCardSubcards(state, cardId);
        subcards.forEach(subcard => {
            result.push({...subcard});
            if (getCardIsEpic(state, subcard.id)) {
                getCardSubcardsTreeWithOrderRecurse(subcard.id, result);
            }
        })
    }
    const result: ICard[] = [];
    getCardSubcardsTreeWithOrderRecurse(cardId, result);
    return result;
}
