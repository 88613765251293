import { IRestRecurringTask } from '../../../../types/rest/recurringTask/IRestRecurringTask';
import { postRest } from './api/postRest';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState, TCardId } from '../../../../types/types';
import { ICards } from '../../../../store/model/cards/types';
import { getCardsForRollback } from '../../../../store/model/selectors/getCardsForRollback';
import { cardsUpdate } from '../../../../store/model/actionCreators/cardsUpdate';
import { cardsActiveRequestsUpdate } from '../../../../store/requestsState/effects/cardsActiveRequestsUpdate';
import { getCardRecurringTasks } from '../../../../store/model/selectors/getCardRecurringTasks';
import { sendRealTimeStoreActionByCardId } from '../../../../view/react_components/base/helpers/realTimeHelperTS';

export const recurringTaskAdd = (
    cardId: TCardId,
    task: IRestRecurringTask
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const recurringTasks = getCardRecurringTasks(state, cardId);

        task = {...task, cardId};

        const cards: ICards = {
            [cardId]: {
                recurringTasks: [
                    ...recurringTasks,
                    task
                ]
            }
        };

        const cardsForRollback = getCardsForRollback(state, cards);
        dispatch(cardsUpdate(cards));
        dispatch(cardsActiveRequestsUpdate(cards, 1));

        return dispatch(postRest(task)).then((
            result: IRestRecurringTask
        ) => {
            const cards: ICards = {
                [cardId]: {
                    recurringTasks: [...recurringTasks, result]
                }
            };
            dispatch(cardsUpdate(cards));
            dispatch(sendRealTimeStoreActionByCardId(cardId, cardsUpdate(cards)));
        }).catch(() => {
            dispatch(cardsUpdate(cardsForRollback));
        }).finally(() => {
            dispatch(cardsActiveRequestsUpdate(cards, -1));
        });
    };
    return action;
};
