import * as React from 'react';
import { ICommentCountIndicatorProps } from './types';
import { CardCountIndicator } from '../CardCountIndicator/CardCountIndicator';
import { COMMENTS_TOOLTIP } from '../../wording';

export function CommentCountIndicator({
    commentsCount
}: ICommentCountIndicatorProps) {
    return (
        <CardCountIndicator icon={'comment'} tooltip={COMMENTS_TOOLTIP}>
            <span>{commentsCount}</span>
        </CardCountIndicator>
    );
}
