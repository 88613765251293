import { IUserBoardPermissionsBackboneModel } from './../../../../../types/backbone/IUserBoardPermissionsBackboneModel';
import { TBoardId } from '../../../../../types/types';
import { IApplicationState } from '../../../../../types/types';
import { ESettingsSectionType } from '../../../base/components/SettingsSection/types';
import { getSectionType } from './getSectionType';
import { IAuthUser } from '../../../../../store/model/authUser/types';
import { MY_WORK_FILTER_BOARD_ID } from '../../filterPanelBoard/constants';

export const getPanelButtonShow = (
    state: IApplicationState,
    boardId: TBoardId,
    typeSection?: ESettingsSectionType,
    allowSection?: keyof IAuthUser,
    boardAllow?: keyof IUserBoardPermissionsBackboneModel, // todo achtung kill backbone
): boolean => {
    if (boardId === MY_WORK_FILTER_BOARD_ID) {
        return true;
    }
    const sectionType = getSectionType(state, boardId, typeSection, allowSection, boardAllow);
    const isShow = (
        sectionType === ESettingsSectionType.DEFAULT ||
        sectionType === ESettingsSectionType.DISALLOW
    );

    return isShow;
}
