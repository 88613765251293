import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../types/types';
import { root } from '../../../../../../../../store/constants';

export const onUpgrade = (
    onClick: () => void
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (onClick) onClick();
        root.App.controller.showPayment(undefined, true);
    }
    return action;
}
