import { createDeepEqualSelector } from './../../../../view/react_components/helpers/memoizeHelper';
import { getBoard } from './../../selectors/getBoardById';
import { IApplicationState, TBoardId } from './../../../../types/types';
import { EColorTagColor, IColor, TColorTagColor, TStatus } from './../../../../types/model';

export const sortColors = (
    colorA: IColor,
    colorB: IColor,
): number => {
    return colorA.orderNumber - colorB.orderNumber;
};

const getBoardColorsSorted = (
    boardColors: IColor[]
): IColor[] => {
    return boardColors.sort(sortColors);
};

const getBoardColorsByBoardId = (
    state: IApplicationState,
    boardId: TBoardId,
    isIncludeArchive?: boolean
): IColor[] => {
    const board = getBoard(state, boardId);
    if (!board || !board.colors) return [];

    if (isIncludeArchive) {
        return [...board.colors];
    } else {
        return [...board.colors.filter(color => color.status !== TStatus.STATUS_ARCHIVE)];
    }
};

export const getBoardColors: (
    state: IApplicationState,
    boardId: TBoardId,
    includeArchive?: boolean
) => IColor[] = createDeepEqualSelector(
    getBoardColorsByBoardId,
    getBoardColorsSorted,
);

export const OLD_COLORS: TColorTagColor[] = [
    EColorTagColor.RED,
    EColorTagColor.PERSIMMON,
    EColorTagColor.ORANGE,
    EColorTagColor.YELLOW,
    EColorTagColor.PARIS_DAISY,
    EColorTagColor.MANZ,
    EColorTagColor.LUCKY,
    EColorTagColor.LAWN_GREEN,
    EColorTagColor.SPRING_GREEN,
    EColorTagColor.LIME,
    EColorTagColor.GREEN,
    EColorTagColor.BLUE,
    EColorTagColor.CYAN,
    EColorTagColor.SKY,
    EColorTagColor.AQUA,
    EColorTagColor.AQUAMARINE,
    EColorTagColor.LIGHT_SKY_BLUE,
    EColorTagColor.NEON_BLUE,
    EColorTagColor.INDIGO,
    EColorTagColor.PURPLE,
    EColorTagColor.ELECTRIC_PURPLE,
    EColorTagColor.BRIGHT_PURPLE,
    EColorTagColor.JAZZBERRY_JAM,
    EColorTagColor.PINK,
    EColorTagColor.SWISS_COFFEE,
    EColorTagColor.BISMARK,
    EColorTagColor.BLACK
];

export const NEW_COLORS = [
    { color: EColorTagColor.VIVID_RED, name: 'Red', hex: '#fe4f5a' },
    { color: EColorTagColor.VIVID_ORANGE, name: 'Orange', hex: '#ffb047' },
    { color: EColorTagColor.VIVID_YELLOW, name: 'Yellow', hex: '#fbd22d' },
    { color: EColorTagColor.VIVID_GREEN, name: 'Green', hex: '#67b757' },
    { color: EColorTagColor.VIVID_BLUE, name: 'Blue', hex: '#5c9efa' },
    { color: EColorTagColor.VIVID_PURPLE, name: 'Purple', hex: '#8b72f9' },
    { color: EColorTagColor.VIVID_PINK, name: 'Pink', hex: '#e74ba3' },
    { color: EColorTagColor.VIVID_BROWN, name: 'Brown', hex: '#b79f8f' },
    { color: EColorTagColor.VIVID_LIGHT_GREY, name: 'Light grey', hex: '#a5adbb' },
    { color: EColorTagColor.VIVID_GREY, name: 'Grey', hex: '#718098' },
    { color: EColorTagColor.PASTEL_RED, name: 'Red pastel', hex: '#ffa9ae' },
    { color: EColorTagColor.PASTEL_ORANGE, name: 'Orange pastel', hex: '#ffdcad' },
    { color: EColorTagColor.PASTEL_YELLOW, name: 'Yellow pastel', hex: '#fde791' },
    { color: EColorTagColor.PASTEL_GREEN, name: 'Green pastel', hex: '#c8e5c2' },
    { color: EColorTagColor.PASTEL_BLUE, name: 'Blue pastel', hex: '#bfd9fd' },
    { color: EColorTagColor.PASTEL_PURPLE, name: 'Purple pastel', hex: '#dbd3fd' },
    { color: EColorTagColor.PASTEL_PINK, name: 'Pink pastel', hex: '#f9d2e8' },
    { color: EColorTagColor.PASTEL_BROWN, name: 'Brown pastel', hex: '#dfd4cd' },
    { color: EColorTagColor.PASTEL_LIGHT_GREY, name: 'Light grey pastel', hex: '#dfe2e7' },
    { color: EColorTagColor.PASTEL_GREY, name: 'Grey pastel', hex: '#cacfd8' },
];

export const OLD_AND_NEW_COLORS = [...OLD_COLORS, ...NEW_COLORS];
