import * as React from 'react';
import { SettingsSection } from '../../../../../../base/components/SettingsSection/SettingsSection';
import { AsidePanelContext } from '../../../../../asidePanel/components/AsidePanel/constants';
import { CLASS_BOARD_DETAILS_SECTION } from '../../../../constants';
import { IGanttSectionProps } from './types';
import { BOARD_SECTION_GANTT } from '../../constants';
import { BoardShowOnGantt } from '../BoardShowOnGantt/BoardShowOnGantt';
import { GanttTogglerHOC } from '../../hocs/GanttTogglerHOC/GanttTogglerHOC';

export function GanttSection ({
    isBasicFeature
}: IGanttSectionProps) {
    const { boardId } = React.useContext(AsidePanelContext);
    const className = CLASS_BOARD_DETAILS_SECTION + ' ' + CLASS_BOARD_DETAILS_SECTION + '--gantt';

    return (
        <SettingsSection
            className={className}
            title={BOARD_SECTION_GANTT}
            titleButton={<GanttTogglerHOC boardId={boardId} />}
            isBasicFeature={isBasicFeature}
        >
            <BoardShowOnGantt />
        </SettingsSection>
    );
};
