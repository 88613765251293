import * as React from 'react';
import { IRestCardColorActivity } from '../../../../../../../types/rest/activity/IRestCardColorActivity';
import { IActivityProps } from '../../../../boardDetails/activity/TabActivity/types';
import { TabActivityContext } from '../../../../boardDetails/activity/TabActivity/components/TabActivity/constants';
import { ActivityColorTags } from '../ActivityColorTags/ActivityColorTags';
import { TActivity } from '../../../../../../../store/activities/types';
import { ActivityHeader } from '../../../../boardDetails/activity/TabActivity/components/ActivityHeader/ActivityHeader';
import { Icon } from 'kui';
import {
    ActivityItemBody
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemBody/ActivityItemBody';
import { SMALL_ACTIVITY_MIN_ASIDE_WIDTH } from '../../../../boardDetails/activity/TabActivity/components/constants';

export function CardActivityColor({
    activity,
    asidePanelWidth
}: IActivityProps<IRestCardColorActivity>) {
    const { onOpenCard } = React.useContext(TabActivityContext);
    const { newColors, oldColors } = activity;
    const { label, card } = activity as TActivity;

    const oldValue = !!oldColors.length && <ActivityColorTags colors={oldColors} asidePanelWidth={asidePanelWidth} />;
    const newValue = !!newColors.length && <ActivityColorTags colors={newColors} asidePanelWidth={asidePanelWidth} />;

    return (
        <ActivityItemBody
            header={<ActivityHeader
                icon={'color-tag'}
                label={label}
                content={<>
                    {asidePanelWidth <= SMALL_ACTIVITY_MIN_ASIDE_WIDTH &&
                        <>
                            {oldValue}
                            {newValue}
                        </>
                    }
                </>}
            />}
            activity={activity}
            onClick={() => onOpenCard(card.id, activity.id)}
        >
            {asidePanelWidth > SMALL_ACTIVITY_MIN_ASIDE_WIDTH &&
                <>
                    {oldValue}
                    {oldValue && newValue && <Icon xlink={'forward'} size={24}/>}
                    {newValue}
                </>
            }
        </ActivityItemBody>
    );
};
