import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState } from '../../../../../../../types/types';
import { segmentTrackAction } from '../../../../../../../middlewares/segment';
import { WebinarQuestionEvent } from '../../../../../../../middlewares/segment/trackEntities/webinarQuestionEvent';
import { authUserSetWebinarFinishedMeta } from '../../../../../../../rest/effects/authUser/authUserSetWebinarFinishedMeta';

export const onSkipped = (): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(authUserSetWebinarFinishedMeta());
        dispatch(segmentTrackAction(WebinarQuestionEvent.WEBINAR_SKIPPED));
    };
    return action;
};
