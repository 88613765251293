
import { ISnackbarsStackState } from '../types';
import {
    SNACKBARS_STACK_ADD_UPDATE,
    SNACKBARS_STACK_DELETE,
    SNACKBARS_STACK_SUSPEND,
    SnackbarsStackAction
} from '../actions/types';
import * as deepFreeze from 'deep-freeze';

const initialState: ISnackbarsStackState = {
    isSuspended: true,
    snackbars: []
};

const _snackbarsStackReducer = (
    state = initialState,
    action: SnackbarsStackAction
): ISnackbarsStackState => {
    switch (action.type) {
        case SNACKBARS_STACK_ADD_UPDATE: {
            let updated = false;
            const stack = state.snackbars.map(item => {
                if ( item.id === action.payload.id ) {
                    updated = true;
                    return action.payload;
                }
                return item;
            });
            return {
                ...state,
                snackbars: updated ? [...stack] : [...stack, action.payload],
                isSuspended: false
            };
        }
        case SNACKBARS_STACK_DELETE: {
            const stack = state.snackbars.filter(item => {
                return item.id !== action.payload;
            })
            return {
                ...state,
                snackbars: [...stack],
                isSuspended: false
            };
        }
        case SNACKBARS_STACK_SUSPEND: {
            return {
                ...state,
                isSuspended: true
            };
        }
        default:
            return state;
    }
};

export const snackbarsStackReducer = (
    state = initialState,
    action: SnackbarsStackAction
): ISnackbarsStackState => {
    // @ts-ignore
    if (window.Settings.development) {
        // @ts-ignore
        return deepFreeze(_snackbarsStackReducer(state, action));
    } else {
        return _snackbarsStackReducer(state, action);
    }
};
