import * as React from 'react';
import { IBoardAuthorProps } from './types';
import './_BoardAuthor.scss';
import Util from '../../../../../../../../util/util';
import { TIME_AT } from './constants';
import { getAsidePanelTooltip } from '../../../../../asidePanel/helpers/getAsidePanelTooltip';
import { CLASS_BOARD_DETAILS } from '../../../../constants';
import { UserpicStorage } from 'app/view/react_components/base/components/UserpicStorage/UserpicStorage';
import { getInitials } from 'app/view/react_components/helpers/userPhotoHelper';

export function BoardAuthor ({
    author,
    created,
}: IBoardAuthorProps) {
    const CLASS_BOARD_DETAILS_AUTHOR = CLASS_BOARD_DETAILS + '__author';
    const classNameColumn = CLASS_BOARD_DETAILS + '-author__column';
    const classNameColumnUserpic = classNameColumn + ' ' + classNameColumn + '--userpic';
    const classNameColumnText = classNameColumn + ' ' + classNameColumn + '--text';
    const classNameAuthorName = CLASS_BOARD_DETAILS + '__author-name';
    const classNameCreated = CLASS_BOARD_DETAILS + '__created';

    const createdAt = Util.formatDateMoment(created) + ' ' + TIME_AT + ' ' + Util.formatTime(created);

    return (
        <div className={CLASS_BOARD_DETAILS_AUTHOR}>
            {!!author
                ? <>
                    <div className={classNameColumnUserpic}>
                        <UserpicStorage
                            initials={getInitials(author)}
                            size={32}
                            src={author.photoUrl}
                            tooltip={getAsidePanelTooltip({
                                direction: 'up-right',
                                value: author.fullName
                            })}
                            userId={author.permissionId}
                        />
                    </div>
                    <div className={classNameColumnText}>
                        <div className={classNameCreated}>
                            {createdAt}
                        </div>
                        <div className={classNameAuthorName}>
                            {author.fullName}
                        </div>
                    </div>
                </>
                : <div className={classNameCreated}>
                    {createdAt}
                </div>
            }
        </div>
    );
};
