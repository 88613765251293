import { IApplicationState } from '../../../../../../types/types';
import { EAuthUserAccountType } from '../../../../../../types/rest/IRestAuthUser';
import { getUserAccountType } from '../../../../../../store/model/selectors/getUserAccountType';

export const isShowSaleSelector = (
    state: IApplicationState,
): boolean => {
    // const userAccountType = getUserAccountType(state);
    // const isShowSale = (
    //     userAccountType === EAuthUserAccountType.INDIVIDUAL_ACCOUNT_TYPE ||
    //     userAccountType === EAuthUserAccountType.EDU_ACCOUNT_TYPE
    // );
    // return isShowSale;
    return false;
};
