import { IRestRecurringTask } from '../../../../types/rest/recurringTask/IRestRecurringTask';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState, TCardId, TRecurringTaskId } from '../../../../types/types';
import { patchRest } from './api/patchRest';
import { getCardsForRollback } from '../../../../store/model/selectors/getCardsForRollback';
import { cardsUpdate } from '../../../../store/model/actionCreators/cardsUpdate';
import { cardsActiveRequestsUpdate } from '../../../../store/requestsState/effects/cardsActiveRequestsUpdate';
import { ICards } from '../../../../store/model/cards/types';
import { getCardRecurringTasks } from '../../../../store/model/selectors/getCardRecurringTasks';
import { sendRealTimeStoreActionByCardId } from '../../../../view/react_components/base/helpers/realTimeHelperTS';

export const recurringTaskUpdate = (
    id: TRecurringTaskId,
    updateTask: IRestRecurringTask,
    cardId: TCardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const recurringTasks = getCardRecurringTasks(state, cardId).map(task => {
            if (task.id === id) {
                return {
                    ...task,
                    ...updateTask
                }
            }
            return task
        });

        const cards: ICards = {
            [cardId]: {
                recurringTasks
            }
        };

        const cardsForRollback = getCardsForRollback(state, cards);
        dispatch(cardsUpdate(cards));
        dispatch(cardsActiveRequestsUpdate(cards, 1));

        return dispatch(patchRest(id, updateTask)).then(() => {
            dispatch(sendRealTimeStoreActionByCardId(cardId, cardsUpdate(cards)));
        }).catch(() => {
            dispatch(cardsUpdate(cardsForRollback));
        }).finally(() => {
            dispatch(cardsActiveRequestsUpdate(cards, -1));
        });
    };
    return action;
};
