import {ITypeSwitcherBoardTypeSet} from './types';
import {AT_TYPE_SWITCHER_BOARD_TYPE_SET} from './constants';
import { EViewTypes } from '../../../../../const';

export const typeSwitcherBoardTypeSet = (
    boardId: number,
    viewType: EViewTypes
): ITypeSwitcherBoardTypeSet => ({
        type: AT_TYPE_SWITCHER_BOARD_TYPE_SET,
        boardId,
        viewType
    }
);
