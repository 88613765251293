import { IGetState } from '../../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { root } from '../../../../../../../../../store/constants';

export const onClose = (): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        root.App.controller.allowCounterSnackbarHelper.setSnackbarShown('allowColorTagCount');
    };
    return action;
};
