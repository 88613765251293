import { TCondition } from '../types';
import { IApplicationState } from '../../../../types/types';
import { EHintKey, getHintConfig } from '../constants';
import { getIsAssignedToMeActive } from '../../../../store/router/selectors/getIsAssignedToMeActive';

export const myWorkFilterBarCondition: TCondition = (
    state: IApplicationState
) => {
    const hint = getHintConfig(EHintKey.MY_WORK_FILTER_BAR);
    if (!hint) return false;

    return getIsAssignedToMeActive(state);
};
