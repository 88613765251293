import { getMessages } from '../../../../../../../../store/constants';

export const PREFIXES_SECTION_MODAL_REMOVE_TITLE = getMessages().getText('cardNumber.modal.remove.title');
export const PREFIXES_SECTION_MODAL_REMOVE_TITLE_LOADING = getMessages().getText('cardNumber.modal.remove.title.loading');
export const PREFIXES_SECTION_MODAL__REMOVE_TEXT = getMessages().getText('cardNumber.modal.remove.text');
export const PREFIXES_SECTION_MODAL__REMOVE_TEXT_LOADING = getMessages().getText('cardNumber.modal.remove.text.loading');
export const PREFIXES_SECTION_MODAL_YES = getMessages().getText('common.yes');
export const PREFIXES_SECTION_MODAL_NO = getMessages().getText('common.no');
export const PREFIXES_SECTION_MODAL_DELETE_CANCEL = getMessages().getText('common.cancel');
export const PREFIXES_SECTION_MODAL_DELETE_REMOVE = getMessages().getText('common.turn_off');
export const PREFIXES_SECTION_MODAL_DELETE_TITLE = getMessages().getText('cardNumber.modal.delete.title');
export const PREFIXES_SECTION_MODAL_DELETE_TEXT = getMessages().getText('cardNumber.modal.delete.text');
