import { AT_THEME_UPDATE, IThemeUpdateAction } from './types';
import { IBoardTheme } from '../types';

export const updateThemeAction = (
    theme: IBoardTheme
): IThemeUpdateAction => {
    return {
        type: AT_THEME_UPDATE,
        theme
    }
};
