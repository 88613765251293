import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { ICardBoardNameHOCProps } from './types';
import { CardBoardName } from '../../components/CardBoardName/CardBoardName';
import { ICardBoardNameEvents, ICardBoardNameFields } from '../../components/CardBoardName/types';
import { onClick } from './events/onClick';
import { getBoard } from '../../../../../../../store/model/selectors/getBoardById';
import { getActiveBoardId } from '../../../../../../../store/model/selectors/getActiveBoardId';

const mapStateToProps = (
    state: IApplicationState,
    { boardId }: ICardBoardNameHOCProps
): ICardBoardNameFields => {
    const activeBoardId = getActiveBoardId(state);
    return {
        boardName: getBoard(state, boardId).name,
        isShow: activeBoardId !== boardId
    }
};

const mapDispatchToProps = (
    dispatch: any,
    { cardId }: ICardBoardNameHOCProps
): ICardBoardNameEvents => {
    return {
        onClick: () => dispatch(onClick(cardId)),
    }
};

export const CardBoardNameHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardBoardName);

CardBoardNameHOC.displayName = 'CardBoardNameHOC';
