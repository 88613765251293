import { connect } from 'react-redux';
import { getCard } from '../../../../../../../store/model/selectors/getCard';
import { IApplicationState } from '../../../../../../../types/types';
import { CardPriorityButton } from '../../components/CardPriorityButton/CardPriorityButton';
import { ICardPriorityButtonHOCProps } from './types';
import { ICardPriorityButtonEvents, ICardPriorityButtonFields } from '../../components/CardPriorityButton/types';
import { getPanelButtonShow } from '../../../selectors/getPanelButtonShow';
import { onClick } from './events/onClick';
import { onChange } from '../../../../../base/components/CardPriority/hocs/CardPriorityHOC/events/onChange';

const mapStateToProps = (
    state: IApplicationState,
    { boardId, cardId, isShowTooltip }: ICardPriorityButtonHOCProps
): ICardPriorityButtonFields => {
    const card = getCard(state, cardId);
    const priority = card ? Number(card.priority) : 0;
    const isShow = getPanelButtonShow(state, boardId, cardId);

    return {
        priority,
        isShow,
        isShowTooltip,
    }
};

const mapDispatchToProps = (
    dispatch: any,
    {cardId, onClick: ownOnClick}: ICardPriorityButtonHOCProps
): ICardPriorityButtonEvents => {
    return {
        onClick: () => dispatch(onClick(ownOnClick)),
        onChange: (priority) => dispatch(onChange(cardId, priority)),
    }
};

export const CardPriorityButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardPriorityButton);

CardPriorityButtonHOC.displayName = 'CardPriorityButtonHOC';
