import { IApplicationState, TBoardId, TTag } from '../../../types/types';
import { ICards } from '../cards/types';
import { getBoardCards } from './getBoardCards';
import { getCardTagsToUpdate } from './getCardTagsToUpdate';

export const getBoardCardsToTagUpdate = (
    state: IApplicationState,
    boardId: TBoardId,
    tag: TTag,
    name: TTag
): ICards => {
    const boardCards = getBoardCards(state, boardId);
    const cards: ICards = {};
    boardCards.forEach(card => {
        const tags = getCardTagsToUpdate(state, card.id, tag, name);
        if (tags) {
            cards[card.id] = {
                tags
            };
        }
    })
    return cards;
};
