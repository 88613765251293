import { AsidePanelActionsButton } from '../../../..';
import * as React from 'react';
import { ICardChecklistPanelButton } from './types';
import { CLASS_CARD_DETAILS_SECTION } from '../../../constants';
import { EAsidePanelProperty } from '../../../../asidePanel/components/AsidePanel/types';
import { CLASS_ASIDE_PANEL_ACTIONS_POPUP } from '../../../../asidePanel/components/AsidePanelActionsButton/constants';
import { CARD_SECTION_CHECKLISTS } from '../CardChecklists/constants';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { CLASS_DATEPICKER } from '../../../../../base/components/Datepicker/components/constants';
import { getAsidePanelActionsButtonTooltip } from 'app/view/react_components/aside_panel/asidePanel/helpers/getAsidePanelActionsButtonTooltip';

export function CardChecklistPanelButton({
    checklistCount,
    isShow,
    tooltip,
    onClick
}: ICardChecklistPanelButton) {
    if (!isShow) return null;

    const { addShowedProperty, showedProperties } = React.useContext(AsidePanelContext);

    return (
        <AsidePanelActionsButton
            dropdownClassName={`${CLASS_ASIDE_PANEL_ACTIONS_POPUP}--checklists`}
            indicatorNumber={checklistCount}
            notBlurClasses={[CLASS_DATEPICKER + '__date-dropdown']}
            icon={'checklist'}
            isIconHiddenOnFullSize={true}
            sectionSelector={`.${CLASS_CARD_DETAILS_SECTION}--checklists`}
            title={CARD_SECTION_CHECKLISTS}
            tooltip={tooltip && getAsidePanelActionsButtonTooltip({ value: tooltip })}
            onClick={() => {
                const isShowed = showedProperties.has(EAsidePanelProperty.CARD_CHECKLIST);
                if (!isShowed) {
                    addShowedProperty(EAsidePanelProperty.CARD_CHECKLIST);
                }
                onClick(isShowed);
            }}
        />
    );
}
