const category = 'Subscription management';

export const SegmentSubscriptionManagementEvent = {
    SHOW: { category, event: 'Subscription management panel options' },
    BACK: { category, event: 'Back from subscription management' },
    OPEN_SUBSCRIPTION_SWITCHER: { category, event: 'Subscription switcher dropdown opened' },
    CHANGE_ACTIVE_SUBSCRIPTION: { category, event: 'Subscription changed in subscription management panel' },
    MANAGE_SUBSCRIPTION: { category, event: 'Manage subscription button clicked' },
    REMOVE_SEAT: { category, event: 'Seat removed' },
    EDIT_SEAT: { category, event: 'Edit seat button clicked' },
    PLUS: { category, event: 'Add seat button clicked' },
    ADD_SEAT: { category, event: 'One seat added' },
    ADD_SEATS: { category, event: 'Multiple seats added' },
    ADD_SEAT_DUPLICATE: { category, event: 'Another unit duplicate message shown' },
    GO_PRICING: { category, event: 'Pricing opened from subscription management clicked' },
    ACCEPT_CHANGES: { category, event: 'Seat changes accepted' },
    CANCEL_CHANGES: { category, event: 'Seat changes canceled' },

    UNIT_ADD: { category, event: 'Unit add clicked' },
    UNIT_EDIT: { category, event: 'Unit edit clicked' },
    UNIT_DELETE: { category, event: 'Unit delete clicked' },
    UNIT_SEND_EMAIL: { category, event: 'Unit send email clicked' },

    SEARCH_BUTTON_CLICKED: { category, event: 'Search for a user clicked' },
    SEARCH_RESULT_CLICKED: { category, event: 'User in search results clicked' },
}

export enum SegmentSubscriptionManagementOption {
    SUBSCRIPTION_ID = 'Subscription id',
    COUNT = 'Count',
}
