import { ICardNameState } from '../types';
import { AT_RENAME } from '../actions/constants';
import { getInitialCardName } from '../constants';
import { ICardNameAction } from '../actions/types';

export const initialState = getInitialCardName();

export const cardNameReducer = (
    state: ICardNameState = initialState,
    action: ICardNameAction
): ICardNameState => {
    switch (action.type) {
        case AT_RENAME : {
            return {
                ...state,
                renameCardId: action.cardId
            };
        }
        default: {
            return state;
        }
    }
};
