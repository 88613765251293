import { IGetState, TBoardId, TCardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IComment } from '../../../../store/model/card/types/IComment';
import { ICards } from '../../../../store/model/cards/types';
import { postRest } from './api/postRest';
import { getCard } from '../../../../store/model/selectors/getCard';
import { getCardsForRollback } from '../../../../store/model/selectors/getCardsForRollback';
import { cardsUpdate } from '../../../../store/model/actionCreators/cardsUpdate';
import { cardTotalSpentTimeUpdate } from '../card/cardTotalSpentTimeUpdate';
import { cardsActiveRequestsUpdate } from '../../../../store/requestsState/effects/cardsActiveRequestsUpdate';
import { sendRealTimeStoreActionByBoardId } from '../../../../view/react_components/base/helpers/realTimeHelperTS';

export const commentAdd = (
    comment: IComment,
    boardId: TBoardId,
    isRealtime: boolean = true // send event to socket
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const fakeComment: IComment = {
            id: Date.now(),
            created: Date.now() / 1000,
            ...comment,
            author: {
                allowReports: false,
                ...comment.author
            }
        };
        const cardId: TCardId = comment.cardId;
        const state = getState();
        const card = getCard(state, cardId);
        const comments = [...card.comments, fakeComment];
        const cards: ICards = {
            [cardId]: {
                comments,
                commentCount: comments.length
            }
        };

        let cardsForRollback = getCardsForRollback(state, cards);
        dispatch(cardsUpdate(cards));
        dispatch(cardsActiveRequestsUpdate(cards, 1));

        return dispatch(postRest(comment))
            .then((comment: IComment) => {
                comment.mentions = comment.mentions || [];
                const card = getCard(getState(), cardId);
                const cards: ICards = {
                    [cardId]: {
                        comments: [
                            ...card.comments.filter(comment => comment.id !== fakeComment.id),
                            comment
                        ]
                    }
                };
                dispatch(cardsUpdate(cards));
                if (isRealtime) {
                    dispatch(sendRealTimeStoreActionByBoardId(boardId, cardsUpdate(cards)));
                }
                if (comment.timeTracker){
                    dispatch(cardTotalSpentTimeUpdate(boardId, cardId))
                }
                return comment;
            })
            .catch((e: any) => {
                console.error(e);
                dispatch(cardsUpdate(cardsForRollback));
            })
            .finally(() => {
                dispatch(cardsActiveRequestsUpdate(cards, -1));
            });
    };
    return action;
};
