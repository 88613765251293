'use strict';

import balloonTemplate from './templates/balloon.html';
import * as _ from 'underscore';

App.Views.BalloonRenderer = App.Views.BaseView.extend({
    tagName: 'div',

    className: 'baloon',

    template: _.template( balloonTemplate ),

    events: {
       'click .baloon__close': 'onCloseClick'
    },

    initialize: function(options) {
        _.bindAll(this, 'animateShow');
        App.vent.on(App.vent['balloon:hide'], this.hideBalloon, this);
        this.currentView = null;
        this.render();
    },

    render: function() {
        this.$el.html( this.template());
        return this;
    },

    show: function(view) {
        this.removeCurrentView();
        this.currentView = view;
        this.showCurrentView();
        setTimeout(this.animateShow, 500);
    },

    forceRemove: function() {
        if (this.currentView) {
            this.currentView.onBaloonTimeEnd();
        }
    },

    animateShow: function() {
        this.$el.addClass('baloon--shown');
    },

    animateHide: function() {
        this.$el.removeClass('baloon--shown');
        this.remove();  //TODO ADD LISTENER TO CSS ANIMATION END
    },

    showCurrentView: function() {
        this.$el.append(this.currentView.render().el);
    },

    showCopyDashboard: function(boardId) {
        this.show(new App.Views.CopyDashboardBalloon({ boardId: boardId }));
    },

    hideBalloon: function() {
        this.animateHide();
    },

    onCloseClick: function(e) {
        e.stopPropagation();
        this.currentView.onBaloonTimeEnd();
        return false;
    },

    remove: function() {
        this.removeCurrentView();
        App.vent.off(null, null, this);
        return Backbone.View.prototype.remove.call(this);
    },

    removeCurrentView: function() {
        if (this.currentView) {
            this.currentView.remove();
        }
        this.currentView = null;
    },

    testAttributes: function() {
        return {
            type: 'balloon-renderer'
        }
    }
});
App.Views.BalloonRenderer.mixin(App.Mixins.Test);
