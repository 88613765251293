import { Dispatch, ThunkAction } from '../../../../../../types/actions';
import { IGetState } from '../../../../../../types/types';
import { getTimeTrackerState } from '../../../../aside_panel/cardDetails/TabTimingSection/TimeTracker/store/selectors/getTimeTrackerState';
import { cardLoad } from '../../../../../../rest/effects/card/card/cardLoad';
import { getCard } from '../../../../../../store/model/selectors/getCard';
import { getBoardWorkHoursInDay } from '../../../../../../store/model/selectors/getBoardWorkHoursInDay';
import { root } from '../../../../../../store/constants';
import { FAKE_CARD_ID_FOR_WIDGET_PRESENTATION } from 'app/const';
import { timeTrackerDeleteAction } from 'app/view/react_components/aside_panel/cardDetails/TabTimingSection/TimeTracker/store/actions/timeTrackerDeleteAction';

export const onDidMount = (): ThunkAction => {
    return (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const timeTracker = getTimeTrackerState(state);
        if (timeTracker) {
            if (timeTracker.cardId === FAKE_CARD_ID_FOR_WIDGET_PRESENTATION) {
                if (!document.querySelector('.updates-tips')) { // нет советов на экране
                    dispatch(timeTrackerDeleteAction());
                }
                return;
            }

            root.App.controller.mainView.doAfterLoader(() => { // после загрузки доски
                /**
                 * при остановке открывается модалка с total spent
                 * для этого нужны комментарии карты, т.е. полная модель
                 */
                const card = getCard(state, timeTracker.cardId);
                if (!card || !card.comments || !card.comments.length) { // может комментов вообще нет, а может не загружены
                    dispatch(cardLoad(timeTracker.cardId));
                }
                // с доски надо workHoursInDay для показа таймтрекера
                const workHoursInDay = getBoardWorkHoursInDay(state, timeTracker.boardId);
                if (!workHoursInDay) {
                    root.App.controller.loadBoardForDashboardToStore(timeTracker.boardId, true, true, true);
                }
            });
        }
    };
};
