import * as React from 'react';
import { ISelectActiveInheritedProps, Search, SelectList, SelectListItem } from 'kui';
import {
    ESearchSelectCreationSource,
    ISearchSelectOptionProps,
    ISearchSelectProps
} from './types';
import './_SearchSelect.scss';
import { OPTIONS_MAX_COUNT } from './constants';
import { useCombinedRefs } from '../../../helpers/useCombinedRefs';
import { SearchSelectOption } from './SearchSelectOption';
import { CLASS_SEARCH_SELECT, NO_RESULTS } from './constants';
import { filterAndSortArray } from './helpers/filterAndSortArray';
import { flatOptions } from './helpers/flatOptions';
import { isElementHasOptions } from './helpers/isElementHasOptions';
import { ISearchSelectOption } from './types';

export const SearchSelect = React.forwardRef(({
    activeValue,
    autoFocus,
    className,
    create,
    createHints = true, /*both createHintIcon && createHintList*/
    createHintIcon = true, /* to be able to remove icon*/
    createHintList = true, /* to be able to remove ctrl+enter*/
    checkboxDirection = 'left',
    directionVertical,
    dropdownClassName,
    isClearable = true,
    isMountDropdown = true,
    isAcronymSearchEnabled = true,
    isEnterEnabled,
    isMultipleGroupToggle = false,
    isKeepValue,
    multiple,
    options,
    option,
    opened,
    placeholder,
    placeholderEmpty,
    placeholderNotFound,
    searchDebounce = 300,
    single,
    stickyOption,
    text,
    footer,
    onActiveChange,
    onSelect,
    onInputChange,
    onCreate,
    onClosed,
    onBlur,
    onOpened,
    onInputKeyDown,
    onFilterOptions,
    ...attributes
}: ISearchSelectProps, ref) => {
    const _searchRef = React.useRef(null);
    const searchRef = useCombinedRefs(ref, _searchRef);
    const inputRef = React.useRef(null);
    const debounce = React.useRef(null);
    const addOptionsFrame = React.useRef(null);

    directionVertical = directionVertical || 'down';
    single = (single === undefined) ? multiple : single;

    let [isOpened, _setIsOpened] = React.useState(!!opened || null as boolean);
    const [collapsed, setCollapsed] = React.useState([]);
    const [optionsToRender, setOptionsToRender] = React.useState<ISearchSelectOptionProps[]>([]);
    const [createValue, setCreateValue] = React.useState(null);

    const classNameCreate = CLASS_SEARCH_SELECT + '__create';
    const classNameEmpty = CLASS_SEARCH_SELECT + '__empty';
    const classNameCreateActive = createValue ? classNameCreate + '--active' : 'disabled';
    const classNameCreateActiveWithoutHints = createHints ? '' : classNameCreate + '--no-hints';
    const classNameDropdown = CLASS_SEARCH_SELECT + '__dropdown';

    const setIsOpened = (isOpenedNew: boolean) => {
        isOpened = isOpenedNew;
        _setIsOpened(isOpenedNew);
    };

    const setCheckboxes = ( // recursive set nested checkboxes
        element: ISearchSelectOption,
        active: boolean,
    ) => {
        if (isElementHasOptions(element)) {
            element.options.forEach(option => setCheckboxes(option, active))
        } else if (!element.disabled && !!element.active !== active) {
            onSelect(element.value);
        }
    }

    const findOption = ( // recursive search in nested options
        options: ISearchSelectOption[],
        value: string,
    ): ISearchSelectOption => {
        for (let i=0; i < options.length; i++) {
            if (options[i].value === value) return options[i];
            if (options[i].options) {
                const sub = findOption(options[i].options, value);
                if (sub) return sub;
            }
        }
        return null;
    }

    const isElementIndeterminate = (
        element: ISearchSelectOption,
    ): boolean => {
        if (!isElementHasOptions(element)) return false;
        return element.options.some(element => {
            if (!isElementHasOptions(element)) {
                return element.active;
            } else {
                return isElementIndeterminate(element)
            }
        })
    }

    const isElementActive = (
        element: ISearchSelectOption,
    ): boolean => {
        if (!isElementHasOptions(element)) return element.active;
        return element.options.every(element => {
            if (!isElementHasOptions(element)) {
                return element.active;
            } else {
                return isElementActive(element)
            }
        })
    }

    const onChange = (i: ISelectActiveInheritedProps) => {
        if (i.item) { // option clicked
            if (i.item.value) {
                const option = findOption(options, i.item.value);
                if (option && option.options) { // nested options
                    const e = i as any;
                    if (e.key === 'Enter') {
                        onLevelToggle(i.item.value);
                    } else if (isMultipleGroupToggle) {
                        if (isElementIndeterminate(option)) {
                            setCheckboxes(option, false);
                        } else {
                            setCheckboxes(option, true);
                        }
                    }
                } else {
                    onSelect(i.item.value);
                    if (!multiple) {
                        setTimeout(() => onClose(true)); // закрыть в следующем цикле после onToggle
                    }
                }
            } else if (create && createValue && onCreate) {
                createAndClose(createValue, ESearchSelectCreationSource.MOUSE);
            }
        } else if (i.target) { // input changed
            const { value = '' } = i.target as HTMLInputElement;
            filterOptionsDebounced(value.trim());
            if (onInputChange) onInputChange(value);
        }
    }

    const onOpen = () => {
        if (inputRef.current) {
            if (text && inputRef.current.value === text) { // почистить, если был инишл текст, и ещё ничего не ввели
                inputRef.current.value = '';
            }
            requestAnimationFrame(() => filterOptions(inputRef.current.value));
        }
        if (onOpened) onOpened();
        setIsOpened(true);
    }

    const onClose = (isSelected?: boolean) => {
        addOptionsStop();
        if (!isOpened) return;

        if ((!isSelected || isKeepValue) && inputRef.current) {
            inputRef.current.value = text || '';
        }
        if (onClosed) onClosed();
        setIsOpened(false);
    }

    const createAndClose = (name: string, source: ESearchSelectCreationSource) => {
        if (onCreate) onCreate(name, source);
        setTimeout(() => onClose()); // закрыть в следующем цикле после onCreate
    }

    const onKeyDown = (event: React.KeyboardEvent) => {
        const enterKey = event.key === 'Enter';
        const ctrlEnterKeys = (event.ctrlKey || event.metaKey) && enterKey;
        if (
            create &&
            createValue &&
            event &&
            onCreate &&
            (isEnterEnabled && enterKey || ctrlEnterKeys)
        ) {
            createAndClose(createValue, ESearchSelectCreationSource.KEYBOARD);
        } else {
            if (onInputKeyDown) onInputKeyDown(event.keyCode);
        }
    }

    const onLevelToggle = (value: string) => {
        const collapsedNew = [...collapsed];
        const index = collapsedNew.indexOf(value);
        if (index > -1) {
            collapsedNew.splice(index, 1);
        } else {
            collapsedNew.push(value);
        }
        setCollapsed(collapsedNew);
    }

    const addOptions = (
        optionsToAdd: ISearchSelectOptionProps[],
        currentOptions: ISearchSelectOptionProps[] = []
    ) => {
        const options = [
            ...currentOptions,
            ...optionsToAdd.splice(0, 1)
        ];

        setOptionsToRender(options);
        if (optionsToAdd.length) {
            addOptionsFrame.current = requestAnimationFrame(() => {
                addOptions(optionsToAdd, options);
            })
        }
    }

    const addOptionsStop = () => {
        if (addOptionsFrame.current) cancelAnimationFrame(addOptionsFrame.current);
    }

    const filterOptionsDebounced = (value: string = '') => {
        if (debounce.current) clearTimeout(debounce.current);
        debounce.current = setTimeout(
            () => filterOptions(value),
            searchDebounce
        );
    }

    const filterOptions = (value: string = '') => {
        addOptionsStop();
        const filterFunc = onFilterOptions ? onFilterOptions : filterAndSortArray;
        const filteredOptions: ISearchSelectOption[] = filterFunc(options, value, isAcronymSearchEnabled);
        const optionsToRender = flatOptions(filteredOptions);

        const matchIndex = optionsToRender.findIndex(element => element.text.toLowerCase() === value.toLowerCase());
        if (value && matchIndex === -1) {
            setCreateValue(value);
        } else {
            setCreateValue(null);
        }

        if (filteredOptions.length > OPTIONS_MAX_COUNT) {
            addOptions(optionsToRender);
        } else {
            setOptionsToRender(optionsToRender);
        }
    }

    const isElementCollapsed = (
        element: ISearchSelectOption
    ): boolean => {
        return isElementHasOptions(element) && collapsed.includes(element.value);
    }

    React.useEffect(() => {
        inputRef.current = searchRef.current.querySelector('input');
        if (isMountDropdown) {
            requestAnimationFrame(() => filterOptions(inputRef.current.value));
        }
        return () => {
            addOptionsStop();
            if (debounce.current) clearTimeout(debounce.current);
        }
    }, []);

    React.useEffect(() => {
        if (isMountDropdown) {
            requestAnimationFrame(() => filterOptions(inputRef.current.value));
        }
    }, [options]);

    const onBlurHandler = (event: React.FocusEvent<HTMLElement>) => {
        addOptionsStop();
        if (onBlur) onBlur(event);
    }

    const selectList: JSX.Element[] = [];
    let activeIndex: number = null;
    let lastRenderedElement: ISearchSelectOptionProps = null;

    if (create) {
        selectList.push(
            <SelectListItem
                className={`
                        ${classNameCreate}
                        ${classNameCreateActive}
                        ${!optionsToRender.length ? classNameCreate + '--empty' : ''}
                        ${classNameCreateActiveWithoutHints}
                    `}
                icon={ (createHints && createHintIcon)  && 'plus'}
                key={0}
                list={ (createHints && createHintList) && 'Ctrl + ↩'}
                listLabel={createHints && 'Press Ctrl + Enter'}
            >
                <span>{create}: {createValue}</span>
            </SelectListItem>
        );
    }
    if (stickyOption) {
        selectList.push(stickyOption);
    }

    if (optionsToRender.length) {
        let parentValue = '';
        optionsToRender.forEach(element => {
            if (!element.level) parentValue = element.value;
            // Исключаем из рендера группу свернутого элемента, т.е. все последующие элементы, после collapsed элемента
            const parentIsCollapsed = lastRenderedElement && lastRenderedElement.level < element.level && isElementCollapsed(lastRenderedElement);
            if (!parentIsCollapsed) {
                lastRenderedElement = element;
                const active = isElementActive(element);
                selectList.push(SearchSelectOption({
                    ...element,
                    isCollapsed: isElementCollapsed(element),
                    checkboxDirection,
                    multiple,
                    isMultipleGroupToggle,
                    option,
                    onLevelToggle,
                    active,
                    isIndeterminate: active ? false : isElementIndeterminate(element),
                    parentValue,
                }));
                if (activeIndex === null && element.value === activeValue) activeIndex = selectList.length - 1;
            }
        })
    } else if (!create || !createValue) {
        let placeholderNo = NO_RESULTS;
        if (inputRef.current) {
            if (inputRef.current.value) {
                if (placeholderNotFound) {
                    placeholderNo = placeholderNotFound;
                }
            } else {
                if (placeholderEmpty) {
                    placeholderNo = placeholderEmpty;
                }
            }
        }
        selectList.push(
            <li
                className={classNameEmpty}
                key={'empty'}
            >
                {placeholderNo}
            </li>
        );
    }

    if (footer) {
        selectList.push(footer);
    }

    return (<div
        className={`${CLASS_SEARCH_SELECT}-div`}
        ref={searchRef}
        tabIndex={-1}
    >
        <Search
            autoFocus={autoFocus}
            active={activeIndex}
            className={`
                ${CLASS_SEARCH_SELECT}
                ${className ? className : ''}
            `}
            dropdownClassName={`
                ${classNameDropdown}
                ${dropdownClassName ? dropdownClassName : ''}
            `}
            directionVertical={directionVertical}
            editable={true}
            isClearable={isClearable}
            multiple={multiple}
            opened={isOpened}
            placeholder={placeholder}
            single={single}
            value={text}
            //onActiveChange={handleActiveChange}
            onBlur={onBlurHandler}
            onChange={onChange}
            onClose={onClose}
            onOpen={onOpen}
            onKeyDown={onKeyDown}
            {...attributes}
        >
            {isMountDropdown &&
                <SelectList fixActive={!!activeValue}>
                    {selectList}
                </SelectList>
            }
        </Search>
    </div>);
});
