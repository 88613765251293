import { IApplicationState } from '../../../../types/types';
import { ISubscription } from '../../../../view/react_components/subscriptionAdmin/store/subscription/types';
import { getUserActiveSubscriptions } from './getUserActiveSubscriptions';

export const getTrialSubscription = (
    state: IApplicationState
): ISubscription => {
    const activeSubscriptions = getUserActiveSubscriptions(state);

    return activeSubscriptions.find(subscription => subscription.isTrial);
};
