import {Dispatch, ThunkAction} from '../../../../../../../types/actions';
import {IGetState} from '../../../../../../../types/types';
import { root } from 'app/store/constants';
import { getAuthUser } from '../../../../../../../store/model/authUser/selectors/getAuthUser';
import { showSnoozeBlocker } from '../../../../../base/effects/showSnoozeBlocker';

export const onClickChangeLogo = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const { allowBranding } = getAuthUser(getState());
        if (allowBranding) {
            root.App.router.navigate(root.App.router.getUrl('design'), {trigger: true});
        } else {
            dispatch(showSnoozeBlocker({
                allow: 'allowBranding'
            }));
        }
    };
    return action;
};
