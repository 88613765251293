import * as React from 'react';
import {IAsidePanelActionsProps} from './types';
import './_AsidePanelActions.scss';
import {
    ASIDE_PANEL_ACTIONS_MOBILE_BUTTON,
    CLASS_ASIDE_PANEL_ACTIONS,
    CLASS_ASIDE_PANEL_ACTIONS_INNER,
    CLASS_ASIDE_PANEL_ACTIONS_SCROLL
} from './constants';
import {Icon} from 'kui';
import {AsidePanelContext} from '../AsidePanel/constants';
import { AsidePanelActionsResizeHOC } from '../../hocs/AsidePanelActionsResizeHOC/AsidePanelActionsResizeHOC';

export function AsidePanelActions ({
    children,
    isMinimized,
    scrollTop,
}: IAsidePanelActionsProps) {
    const { isButtonsFixed, mobileButton } = React.useContext(AsidePanelContext);
    const className = CLASS_ASIDE_PANEL_ACTIONS;
    const classNameSize = isButtonsFixed
        ? className + '--fixed'
        : isMinimized ? className + '--minimized' : '';
    const classNameMobileButton = CLASS_ASIDE_PANEL_ACTIONS + '-mobile-button';
    const classNameMobileButtonIcon = CLASS_ASIDE_PANEL_ACTIONS + '-mobile-button-icon';

    const scrollRef = React.useRef(null);
    const observer = React.useRef(null);

    function onScroll (scrollTop: number) {
        scrollRef.current.scrollTop = scrollTop;
    };

    function scrollToActions () {
        scrollRef.current.scrollIntoView({behavior: 'smooth'});
    };

    const createObserver = () => {
        const actions = document.querySelector('.aside-panel__actions-section-title-second') as HTMLElement;
        observer.current = new IntersectionObserver(([e]) => {
            actions.classList.toggle('pinned', !e.isIntersecting);
        });
        const target = document.querySelector('.aside-panel__actions-section-actions .aside-panel__actions-button');
        if (target && actions) observer.current.observe(target);
    }

    React.useEffect(() => {
        onScroll(scrollTop);
    }, [scrollTop]);

    React.useEffect(() => {
        requestAnimationFrame(createObserver);
    }, []);

    return (<>
        <div
            className={classNameMobileButton}
            onClick={scrollToActions}
        >
            {mobileButton || ASIDE_PANEL_ACTIONS_MOBILE_BUTTON}
            <Icon
                className={classNameMobileButtonIcon}
                size={24}
                xlink={'arrow-down'}
            />
        </div>
        <div
            className={`
                ${className}
                ${classNameSize}
            `}
        >
            {!isButtonsFixed &&
                <AsidePanelActionsResizeHOC />
            }
            <div
                className={CLASS_ASIDE_PANEL_ACTIONS_SCROLL}
                ref={scrollRef}
            >
                <div className={CLASS_ASIDE_PANEL_ACTIONS_INNER}>
                    {children}
                </div>
            </div>
        </div>
    </>);
}
