import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import React from "react";
import { BlankerHOC } from '../react_components/main/blancker/hocs/BlankerHOC';

App.Views.Blancker = App.Views.BaseView.extend({

    initialize: function(options){
        this.root = createRoot(this.el);
        this.store = options.store;
        this.boardId = options.boardId
    },

    render: function () {
        this.root.render(
            <Provider store={this.store}>
                <BlankerHOC />
            </Provider>
        );

        return this;
    },

    remove: function () {
        this.root.unmount();
        Backbone.View.prototype.remove.call(this);
    }
});

export default App.Views.Blancker;
