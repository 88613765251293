import {Dispatch, ThunkAction} from '../../../../../../../../../types/actions';
import { TBoardId } from '../../../../../../../../../types/types';
import { topBarSetAction } from '../../../../../store/topBarBoard/actions/topBarSetAction';
import { isShareHintShowSetAction } from '../../../../../store/topBar/actions/isShareHintShowSetAction';
import { authUserSetDontShowShareHint } from '../../../../../../../../../rest/effects/authUser/authUserSetDontShowShareHint';
import { shareDontShowClickedSegmentEvent } from '../../../../../effects/segmentEvents';

export const onHintDontShow = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(topBarSetAction(boardId, isShareHintShowSetAction(false)));
        dispatch(authUserSetDontShowShareHint(true));
        dispatch(shareDontShowClickedSegmentEvent());
    };
    return action;
};
