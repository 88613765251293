import { Icon } from 'kui';
import * as React from 'react';
import { CLASS_CARD_CUSTOM } from '../constants';
import { CUSTOM_BUTTON_HIDE, CUSTOM_BUTTON_SHOW } from './constants';
import { ICustomPropertiesHideButton } from './types';
import './_CustomPropertiesHideButton.scss';

export function CustomPropertiesHideButton ({
    hidden,
    isHide,
    onClick,
}: ICustomPropertiesHideButton) {
    const className = CLASS_CARD_CUSTOM + '__hide-button';
    const classNameText = className + '-text';
    const classNameIcon = className + '-icon';
    const classNameHidden = className + '-hidden';

    return (
        <div
            className={className}
            onClick={onClick}
        >
            <span className={classNameText}>
                {isHide ? CUSTOM_BUTTON_SHOW: CUSTOM_BUTTON_HIDE}
            </span>
            <Icon
                className={classNameIcon}
                size={16}
                xlink={isHide ? 'arrow-s-down': 'arrow-s-up'}
            />
            {isHide && !!hidden && !!hidden.length &&
                <span className={classNameHidden}>
                    {hidden.join(', ')}
                </span>
            }
        </div>
    );
}
