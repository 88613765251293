import { IGetState, TCardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { cardDelete } from '../../../../rest/effects/card/card/cardDelete';
import { ICard } from '../../../../store/model/card/types';
import { getCard } from '../../../../store/model/selectors/getCard';
import { closePanel } from '../../aside_panel/asidePanel/effects/closePanel';
import { getMessages } from '../../../../store/constants';
import {
    SNACKBAR_CARD_DELETE_ID,
    SNACKBAR_CARD_DELETE_TEXT
} from '../../aside_panel/cardDetails/DeleteButtonSection/hocs/DeleteButtonHOC/constants';
import { cardTemplatesOpen } from './cardTemplatesOpen';
import { TStatus } from '../../../../types/model';
import { recurringTasksOpen } from './recurringTasksOpen';
import { escape } from 'underscore';
import { clearMarkdownInline } from '../helpers/clearMarkdownHelper';
import { cardsUpdate } from '../../../../store/model/actionCreators/cardsUpdate';
import { snackbarUndoDefault } from '../../snackbarsStack/effects/variantUndo/snackbarUndoDefault';
import { updateCardAssigneesFromCards } from '../../main/myWorkView/effects/updateCardAssigneesFromCards';
import { getCardFullNameHelper } from '../../../../store/model/card/helpers/getCardFullNameHelper';
import {
    snackbarUndoWithAlternativeAction
} from '../../snackbarsStack/effects/variantUndo/snackbarUndoWithAlternativeAction';
import { SNACKBAR_BUTTON_ARCHIVE } from '../../snackbarsStack/effects/constants';
import { getAuthUser } from '../../../../store/model/authUser/selectors/getAuthUser';
import { cardsArchive } from '../../../../rest/effects/card/card/cardsArchive';
import { SegmentCardEvent, segmentTrackAction } from '../../../../middlewares/segment';

export const cardDeleteWithPrompt = (
    cardId: TCardId,
    closeAsidePanel: boolean = true
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const positionLeft = true;
        const card: ICard = getCard(state, cardId);
        if (!card) {
            throw new Error('Unknown cardId');
        }
        const {allowArchive} = getAuthUser(state);
        const isCardArchived = card.status === TStatus.STATUS_ARCHIVE;

        if (card.status === TStatus.STATUS_SERVICE_TEMPLATE) {
            if (card.recurringTasks.length > 0) {
                dispatch(recurringTasksOpen());
            } else {
                dispatch(cardTemplatesOpen());
            }
        } else if (closeAsidePanel) {
            dispatch(closePanel());
        }

        const update = {
            [cardId]: {
                status: TStatus.STATUS_DELETED // mark card as deleted in store
            }
        }
        dispatch(cardsUpdate(update));
        dispatch(updateCardAssigneesFromCards(update));

        const actionApply = () => {
            dispatch(cardDelete(cardId));
            dispatch(segmentTrackAction(SegmentCardEvent.DELETE_CARD_PROCEED));
        };

        const actionAlternative = () => {
            dispatch(cardsArchive([cardId]));
            dispatch(segmentTrackAction(SegmentCardEvent.ARCHIVE_ON_DELETE_CARD_PROCEED));
        };
        const actionUndo = () => {
            const update = {
                [cardId]: {
                    status: card.status // revert card status
                }
            }
            dispatch(cardsUpdate(update))
            dispatch(updateCardAssigneesFromCards(update));
            dispatch(segmentTrackAction(SegmentCardEvent.DELETE_CARD_CANCELED));
        }
        const actionTimer = () => {
            dispatch(segmentTrackAction(SegmentCardEvent.DELETE_CARD_TIMEOUT));
        };

        if (allowArchive && !isCardArchived) {
            dispatch(snackbarUndoWithAlternativeAction({
                id: SNACKBAR_CARD_DELETE_ID,
                text: getMessages().getText(SNACKBAR_CARD_DELETE_TEXT, null, escape(clearMarkdownInline(getCardFullNameHelper(card)))),
                actionApply,
                actionUndo,
                actionAlternative,
                actionTimer,
                alternativeButton: SNACKBAR_BUTTON_ARCHIVE
            }, positionLeft))
        } else {
            dispatch(snackbarUndoDefault({
                id: SNACKBAR_CARD_DELETE_ID,
                text: getMessages().getText(SNACKBAR_CARD_DELETE_TEXT, null, escape(clearMarkdownInline(getCardFullNameHelper(card)))),
                actionApply,
                actionUndo,
                actionTimer
            }, positionLeft))

        }
    };
    return action;
};
