import { IFilterEnabledSetAction } from './types';
import { AT_FILTER_ENABLED_SET } from './constants';

export const enabledSet = (
    isEnabled: boolean
): IFilterEnabledSetAction => ({
        type: AT_FILTER_ENABLED_SET,
        isEnabled
    }
);
