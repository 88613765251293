import { IGetState, TBoardId, TCardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { uploadFiles } from '../effects/uploadFiles';
import { attachmentPastedSegmentEvent, attachmentPasteErrorSegmentEvent } from '../effects/segmentEffects';
import {
    SegmentCardEvent,
    SegmentCardPasteFileTarget
} from '../../../../../../../../middlewares/segment/trackEntities/cardEvents';
import { ERestDriveDocType } from '../../../../../../../../types/rest/IRestDriveDoc';
import { IDriveDoc } from '../../../../../../../../store/model/card/types/IDriveDoc';
import { createAttachmentComments } from '../../../../CommentsSection/hocs/CommentsInputHOC/effects/createAttachmentComments';
import { segmentTrackAction } from '../../../../../../../../middlewares/segment';

export const onPasteFileAsComment = (
    boardId: TBoardId,
    cardId: TCardId,
    files: File[],
    onProgress: (percent: number) => void
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (!files) {
            return dispatch(attachmentPasteErrorSegmentEvent(SegmentCardPasteFileTarget.COMMENT));
        }

        dispatch(attachmentPastedSegmentEvent(SegmentCardPasteFileTarget.COMMENT));
        dispatch(segmentTrackAction(SegmentCardEvent.COMMENT_ATTACHMENT_PASTE));
        return dispatch(uploadFiles(cardId, files, onProgress, true, ERestDriveDocType.COMMENT))
            .then((driveDocs: IDriveDoc[]) => {
                dispatch(createAttachmentComments(driveDocs, boardId, cardId));
            });
    };
    return action;
};
