import { IGetState, TListId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IRestCard } from '../../../../types/rest/IRestCard';
import { REST_LIST } from '../../../constants';
import { ICardsCopyRestOptions } from './types';
import { PartUploader } from '../../../../util/part_uploader';
import { PORTION_SIZE } from '../../../../const';

export const cardsCopyRest = (
    listId: TListId,
    cards: IRestCard[],
    options: ICardsCopyRestOptions = {}
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const params = Object.keys(options).map((key: keyof ICardsCopyRestOptions) => {
            return `${key}=${options[key]}`
        })
        const paramsString = params.length > 0 ? `?${params.join('&')}` : '';
        const partUploader = new PartUploader<IRestCard>(
            `${REST_LIST}${listId}/cards/copy${paramsString}`,
            cards,
            PORTION_SIZE
        );
        return partUploader.uploadPart()
    };
    return action;
};
