import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { IGetState, TBoardId } from '../../../../../../../../../types/types';
import { getCardNumberByBoardId } from '../../../store/cardNumbers/selectors/getCardNumberByBoardId';
import { cardNumberSetAction } from '../../../store/cardNumbers/actions/cardNumberSetAction';
import { statusChange } from '../../../store/cardNumber/actions/statusChange';
import { ECardNumberStatus } from '../../../store/cardNumber/types';
import { sendOnSaveSegmentEvents } from './sendOnSaveSegmentEvents';
import { cardNumberStateUpdateAction } from '../../../store/cardNumber/actions/cardNumberUpdate';
import { boardSetCardNumberValues } from '../../../../../../../../../rest/effects/board/boardSetCardNumberValues';

export const saveCardNumber = (
    boardId: TBoardId
): ThunkAction => {
    const action = (dispatch: Dispatch, getState: IGetState) => {
        dispatch(sendOnSaveSegmentEvents(boardId));
        dispatch(cardNumberSetAction(boardId, statusChange(ECardNumberStatus.SAVING)));
        const { prefix, nextValue } = getCardNumberByBoardId(getState(), boardId);
        return dispatch(boardSetCardNumberValues(boardId, prefix, parseInt(nextValue))).then(() => {
            return dispatch(cardNumberSetAction(boardId, cardNumberStateUpdateAction({
                edited: false,
                status: ECardNumberStatus.LOADED,
                initialValue: {
                    nextValue, prefix
                }
            })));
        })
    }
    return action;
}
