import { IGetState } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { fetchHandler } from '../../../../util/fetchHandler';
import { Util } from '../../../../util/util';
import { REST_THEMES } from '../../../constants';
import { getRestHeadersGet } from '../../../helpers/getRestHeadersHelper';
import { ILibTheme } from '../../../../store/model/libThemes/types';

export const getRest = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return fetchHandler<ILibTheme[]>(
            Util.getApiUrl(REST_THEMES),
            getRestHeadersGet()
        ).then(response => {
            return response
        });
    };
    return action;
};
