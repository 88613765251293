import { Dispatch, ThunkAction } from '../../../types/actions';
import { fetchHandler } from '../../../util/fetchHandler';
import Util from '../../../util/util';
import { REST_MESSENGER_POLL } from '../../constants';
import { getRestHeadersPost } from '../../helpers/getRestHeadersHelper';
import { IMessengerPollRest } from './types';

export const authUserPostMessengerPoll = (
    messengerPoll: IMessengerPollRest
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        return fetchHandler(Util.getApiUrl(REST_MESSENGER_POLL), {
            ...getRestHeadersPost(),
            body: JSON.stringify(messengerPoll)
        })
    }
    return action;
};
