import { TBoardId } from '../../../../types/types';
import { IBoard } from '../../../../store/model/board/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { fetchHandler } from '../../../../util/fetchHandler';
import { Util } from '../../../../util/util';
import { getBoardBackupsRestUrl } from '../../../constants';
import { getRestHeadersPost } from '../../../helpers/getRestHeadersHelper';
import { IBackup } from '../../../../store/model/boardBackups/types';

export const patchRest = (
    boardId: TBoardId,
    backup: IBackup
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        return fetchHandler<IBoard>(
            Util.getApiUrl(getBoardBackupsRestUrl(boardId)), {
                ...getRestHeadersPost(),
                body: JSON.stringify(backup)
            }
        );
    };
    return action;
};
