import { createRoot } from 'react-dom/client';
import {Provider} from "react-redux";
import React from "react";

App.Views.ActiveRootView = App.Views.BaseView.extend({
    initialize: function(options){
        this.root = createRoot(this.el);
        this.store = options.store;
        this.View = options.view;
        this.boardId = options.boardId;
    },

    render: function () {
        let View = this.View;
        this.root.render(
            <Provider store={this.store}>
                <View boardId={this.boardId}/>
            </Provider>
        );
        return this;
    },

    remove: function () {
        this.root.unmount();
        Backbone.View.prototype.remove.call(this);
    }
});
export default App.Views.ActiveRootView;
