import { ILastUsedFiltersSetAction } from './types';
import { AT_LAST_USED_FILTERS } from './constants';
import { TLastUsedFilter } from '../types';

export const lastFiltersSet = (
    lastUsedFilters: TLastUsedFilter
): ILastUsedFiltersSetAction => ({
    type: AT_LAST_USED_FILTERS,
    lastUsedFilters
});
