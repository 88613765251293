import { AT_AUTH_PERMISSIONS_UPDATE, IAuthPermissionsUpdateAction } from './types';
import { IUserBoardPermissionsBackboneModel } from '../../../../types/backbone/IUserBoardPermissionsBackboneModel';

export const updateAuthPermissionsAction = (
    authPermissions: IUserBoardPermissionsBackboneModel
): IAuthPermissionsUpdateAction => {
    return {
        type: AT_AUTH_PERMISSIONS_UPDATE,
        authPermissions
    }
};
