import { IApplicationState } from '../../../../types/types';
import { TBoardId } from '../../../../types/types';
import { getListViewBoard } from './getListViewBoard';

export const getSortType = (
    state: IApplicationState,
    boardId: TBoardId
): number => {
    const sortType = getListViewBoard(state, boardId).sortType;
    return sortType;
}
