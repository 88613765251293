import * as React from 'react';
import { CLASS_ACTIVITY_ITEM } from '../constants';
import './_ActivityHeader.scss';
import { IActivityHeaderProps } from './types';
import { Icon } from 'kui';
import { isString } from 'underscore';

export function ActivityHeader({
    className: classNameOwn,
    content,
    icon,
    label,
}: IActivityHeaderProps) {
    const className = CLASS_ACTIVITY_ITEM + '__header'
    return (
        <div className={`${className} ${classNameOwn || ''}`}>
            {icon ?
                isString(icon) ?
                    <Icon xlink={icon} size={16} className={className + '-icon'}/> : icon
                : null
            }
            <span className={className + '-label'}>{label}</span>
            {content}
        </div>
    );
};
