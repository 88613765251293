import * as React from 'react';
import { AsidePanelContext } from '../../../../../asidePanel/components/AsidePanel/constants';
import { IBoardCardAgingSectionProps } from './types';
import { SettingsSection } from '../../../../../../base/components/SettingsSection/SettingsSection';
import { CLASS_BOARD_DETAILS_SECTION } from '../../../../constants';
import { CardAgingHOC } from '../../hocs/CardAgingHOC/CardAgingHOC';
import {
    CARD_AGING_MODAL_CANCEL,
    CARD_AGING_MODAL_REMOVE, CARD_AGING_MODAL_TEXT, CARD_AGING_MODAL_TITLE,
    DETAILS_SECTION_CARD_AGING_PROPERTIES
} from '../../constants';
import { CardAgingTogglerHOC } from '../../hocs/CardAgingTogglerHOC/CardAgingTogglerHOC';
import { ActionModal } from '../../../../../../base/components/ActionModal/ActionModal';
import {
    CARDS_SORTING_MODAL_CANCEL,
    CARDS_SORTING_MODAL_REMOVE, CARDS_SORTING_MODAL_TEXT, CARDS_SORTING_MODAL_TITLE
} from '../../../../../listDetails/sortingSection/components/CardsSortingSection/constants';
import {
    BasicUpgradeButtonHOC
} from '../../../../../../base/components/BasicUpgradeButton/hocs/BasicUpgradeButtonHOC/BasicUpgradeButtonHOC';

export function CardAgingSection ({
    isBasicFeature,
    onChange,
    onClose
}: IBoardCardAgingSectionProps) {
    const { boardId } = React.useContext(AsidePanelContext);
    const className = CLASS_BOARD_DETAILS_SECTION + ' ' + CLASS_BOARD_DETAILS_SECTION + '--card-aging';
    const [isShow, setShow] = React.useState(null);
    const onCloseModal = () => {
        onClose();
        setShow(false);
    };
    return (
        <SettingsSection
            className={className}
            title={DETAILS_SECTION_CARD_AGING_PROPERTIES}
            isBasicFeature={isBasicFeature}
            titleButton={
                <CardAgingTogglerHOC
                    boardId={boardId}
                    onChange={() => onChange().then(result => {
                        if (!result) setShow(true);
                    })}
                />
            }
        >
            <CardAgingHOC
                boardId={boardId}
            />
            <ActionModal
                isShow={isShow}
                actions={[
                    {
                        variant: 'primary',
                        onClick: onCloseModal,
                        children: CARD_AGING_MODAL_CANCEL
                    },
                    {
                        variant: 'secondary',
                        onClick: () => {
                            onCloseModal();
                            onChange();
                        },
                        children: CARD_AGING_MODAL_REMOVE
                    },
                ]}
                footer={<BasicUpgradeButtonHOC onClick={onCloseModal} />}
                text={CARD_AGING_MODAL_TEXT}
                title={CARD_AGING_MODAL_TITLE}
                onClose={onCloseModal}
            />
        </SettingsSection>
    );
};
