import * as React from 'react';
import { Modal, Button, Radio } from 'kui';
import './_PermissionsFAQModal.scss';
import {
    IPermissionFAQModalProps
} from './types';

export function PermissionsFAQModal ({
   onClose
}: IPermissionFAQModalProps) {

    return (
        <Modal
            title={'Kanbanchi needs specific permissions. <br/>Don’t worry, it is absolutely safe.'}
            variant={'actions'}
            className={'permissions-faq'}
            onClose={onClose}
        >
            <img
                src={'/dist/img/permissions-list.png'}
                alt="#"
                className={'permissions-faq__image'}
            />
            <p className={'permissions-faq__text'}>To function properly, Kanbanchi requires access to your Google Drive and the ability to manage files within it. Please ensure you check all boxes during the sign-up process.</p>
            <p className={'permissions-faq__text'}>For more information, <a className={'permissions-faq__link'} href={'https://www.kanbanchi.com/kanbanchi-faq'} target={'_blank'}>visit our FAQ </a> <br/>
                or <a className={'permissions-faq__link'} href={'https://www.kanbanchi.com/contacts'} target={'_blank'}>contact our support team</a> for assistance.</p>
            <Button
                className={'permissions-faq__button'}
                onClick={onClose}
            >OK</Button>
        </Modal>
    );
}
