import { cardsAddAction } from '../cards/actions/cardsAddAction';
import { cardsActionSetAction } from '../actions/cardsActionSetAction';
import { ICards } from '../cards/types';
import { ICardsActionSetAction } from '../actions/types';

export const cardsAdd = (
    cards: ICards
): ICardsActionSetAction => {
    return cardsActionSetAction(cardsAddAction(cards));
};
