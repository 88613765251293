import { IApplicationState, TBoardId } from '../../../../types/types';
import { getBoard } from '../../selectors/getBoardById';

export const getBoardHideCardAgeingSnackbar = (
    state: IApplicationState,
    boardId: TBoardId
): boolean => {
    const { userMeta } = getBoard(state, boardId);

    return userMeta && userMeta.isHideCardAgeingSnackbar;
};
