import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState, TCardId } from '../../../../types/types';
import { ICards } from '../../../../store/model/cards/types';
import { cardFullGetRest } from './api/helper/cardFullGetRest';
import { getCardAssignee } from '../../../../store/model/cardAssignees/selectors/getCardAssignee';
import { TStatus } from '../../../../types/model';
import { cardAssigneeSetArchived } from '../../cardAssignees/cardAssigneeSetArchived';
import { snackbarErrorDefault } from 'app/view/react_components/snackbarsStack';
import {
    SNACKBAR_ID_CARD_DELETED
} from 'app/view/react_components/snackbarsStack/effects/constants';
import { getMessages } from 'app/store/constants';
import Util from 'app/util/util';
import { ICard } from 'app/store/model/card/types';

export const cardLoad = (
    cardId: TCardId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(cardFullGetRest(cardId)).then((result: ICards) => {
            dispatch(checkCardAssigneeStatus(result[cardId]));
            dispatch(checkCardDeletedStatus(result));
            return result;
        })
    };
    return action;
};

const checkCardAssigneeStatus = (
    card: ICard,
    ): ThunkAction => {
        const action = (
            dispatch: Dispatch,
            getState: IGetState
        ) => {
        if (card && card.status === TStatus.STATUS_ARCHIVE) {
            const cardAssignee = getCardAssignee(getState(), card.id);
            if (cardAssignee && !cardAssignee.archived) {
                dispatch(cardAssigneeSetArchived(card.id, true));
            }
        }
    };
    return action;
};

const checkCardDeletedStatus = (
    cards: ICards,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        for (let cardId in cards) {
            const card = cards[cardId];
            if (card.status === TStatus.STATUS_DELETED) {
                const date = card.deletionDate
                    ? getMessages().getText('snackbar.card.wasdeletedon.date', null, Util.formatDateMoment(card.deletionDate))
                    : '';
                dispatch(snackbarErrorDefault({
                    id: SNACKBAR_ID_CARD_DELETED,
                    text: getMessages().getText('snackbar.card.wasdeletedon.text', null, date),
                }));
            }
        }
    };
    return action;
};
