import { IGetState, TCardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { restPost } from '../api/restPost';
import { getIsCardInMyWork } from '../selectors/getIsCardInMyWork';
import { restDelete } from '../api/restDelete';
import { IRestCardAssigneeInfo } from '../../../../../../../../types/rest/IRestCardAssigneeInfo';
import { cardAssigneesActionSetAction } from '../../../../../../../../store/model/actions/cardAssigneesActionSetAction';
import { cardAssigneeAddAction } from '../../../../../../../../store/model/cardAssignees/actions/cardAssigneeAddAction';
import { sendAuthUserRealTimeStoreAction } from '../../../../../../base/helpers/realTimeHelperTS';
import {
    cardAssigneeUpdateAction
} from '../../../../../../../../store/model/cardAssignees/actions/cardAssigneeUpdateAction';
import { TStatus } from '../../../../../../../../types/model';

export const addOrRemoveCardAssignee = (
    cardId: TCardId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const isCardInMyWork = getIsCardInMyWork(getState(), cardId);
        if (isCardInMyWork) {
            dispatch(restDelete(cardId)).then(() => {
                const updateAction = dispatch(cardAssigneesActionSetAction(cardAssigneeUpdateAction(cardId, { status: TStatus.STATUS_DELETED })));
                dispatch(sendAuthUserRealTimeStoreAction(updateAction));
            });
        } else {
            dispatch(restPost(cardId)).then((cardAssignee: IRestCardAssigneeInfo) => {
                const updateAction = dispatch(cardAssigneesActionSetAction(cardAssigneeAddAction(cardId, cardAssignee)));
                dispatch(sendAuthUserRealTimeStoreAction(updateAction));
            });
        }
    };
    return action;
};
