import { errorHandler, graphClient } from 'app/helper/authorisation/microsoft/graph/Graph';
import { DriveItem } from '@microsoft/microsoft-graph-types';

export const getSharedFileFromOneDrive = async (
    item: string
) => {
    try {
        let url = item.replace('https://graph.microsoft.com/v1.0','');
        let request = graphClient.api(url);
        let response = await request.get() as DriveItem;
        return response;
    } catch (e) {
        return errorHandler(e);
    }
}
