import { getRestHeadersPost } from './../../rest/helpers/getRestHeadersHelper';
import { fetchHandler } from '../../util/fetchHandler';
import { IABTestCurrentRest } from './types';

const AB_SAVE_URL = '/rest/abtesting/current';

export const saveTests = (tests: IABTestCurrentRest[]): Promise<IABTestCurrentRest[]> => {
    const init: RequestInit = {
        ...getRestHeadersPost(),
        body: JSON.stringify(tests)
    };

    return fetchHandler(AB_SAVE_URL, init);
};
