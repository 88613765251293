import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../../types/types';
import { onClick } from './events/onClick';
import { IEstimateTimePanelButtonHOCProps } from './types';
import { EstimateTimePanelButton } from '../../components/EstimateTimePanelButton/EstimateTimePanelButton';
import { IEstimateTimePanelButtonEvents, IEstimateTimePanelButtonFields } from '../../components/EstimateTimePanelButton/types';

const mapStateToProps = (
    state: IApplicationState,
    { }: IEstimateTimePanelButtonHOCProps
): IEstimateTimePanelButtonFields => {
    return {
        tooltip: null
    }
};

const mapDispatchToProps = (
    dispatch: any,
    {onClick: ownOnClick}: IEstimateTimePanelButtonHOCProps
): IEstimateTimePanelButtonEvents => {
    return {
        onClick: () => dispatch(onClick(ownOnClick))
    }
};

export const EstimateTimePanelButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(EstimateTimePanelButton);

EstimateTimePanelButtonHOC.displayName = 'EstimateTimePanelButtonHOC';
