import { IApplicationState } from 'app/types/types';
import { connect } from 'react-redux';
import { IBackupsTextEvents, IBackupsTextFields } from '../../components/BackupsText/types';
import { BackupsText } from '../../components/BackupsText/BackupsText';
import { getSectionType } from '../../../../selectors/getSectionType';
import { ESettingsSectionType } from 'app/view/react_components/base/components/SettingsSection/types';
import { getBackupLink } from 'app/view/react_components/aside_panel/boardDetails/backups/BackupsText/hocs/BackupsTextHOC/helper/getBackupLink';
import { IBackupsTextHOCProps } from 'app/view/react_components/aside_panel/boardDetails/backups/BackupsText/hocs/BackupsTextHOC/types';
import { getBoard } from 'app/store/model/selectors/getBoardById';

const mapStateToProps = (
    state: IApplicationState,
    ownProps: IBackupsTextHOCProps
): IBackupsTextFields => {
    const { boardId } = ownProps;
    const board = getBoard(state, boardId);
    const sectionType = getSectionType(state, boardId, null, 'allowBackupXml', 'allowCreateBackUp');
    const isShow = sectionType === ESettingsSectionType.DEFAULT;
    return {
        isShow,
        platformType: board.platformType
    }
};

const mapDispatchToProps = (
    dispatch: any,
    { boardId }: IBackupsTextHOCProps
): IBackupsTextEvents => ({
    getBackupLink: () => dispatch(getBackupLink(boardId))
});

export const BackupsTextHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(BackupsText);

BackupsTextHOC.displayName = 'BackupsTextHOC';
