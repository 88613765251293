import { ISnackbarPromt } from './types';
import { Dispatch } from '../../../../../types/actions';
import { SNACKBAR_BUTTON_NO, SNACKBAR_BUTTON_YES } from '../constants';
import { getDefaultActionEvent } from '../helpers/getDefaultActionEvent';
import { snackbarPromtDefault } from './promtDefault';
import { ESnackbarPosition } from '../../store/types';

export const snackbarPromtYesNo = (
    options: ISnackbarPromt,
    position?: ESnackbarPosition,
) => {
    return (dispatch: Dispatch) => {
        options.controls = [
            {
                action: () => dispatch(getDefaultActionEvent(options.id, options.actionCancel)),
                title: options.cancelBtnTitle || SNACKBAR_BUTTON_NO
            },
            {
                action: () => dispatch(getDefaultActionEvent(options.id, options.actionApply)),
                isPrimary: true,
                title: options.applyBtnTitle || SNACKBAR_BUTTON_YES
            },
        ];
        dispatch(snackbarPromtDefault(options, position));
    }
};
