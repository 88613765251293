import { errorHandler, graphClient } from 'app/helper/authorisation/microsoft/graph/Graph';

export const deleteFilePermission = async (
    driveId: string,
    fileId: string,
    permId: string,
) => {
    try {
        let request = graphClient.api(`/drives/${driveId}/items/${fileId}/permissions/${permId}`);
        return await request.delete();
    } catch (e) {
        return errorHandler(e);
    }
}
