import { connect } from 'react-redux';
import { DeleteButton } from '../../components/DeleteButton/DeleteButton';
import { IApplicationState } from '../../../../../../../types/types';
import { IDeleteButtonEvents, IDeleteButtonFields } from '../../components/DeleteButton/types';
import { IDeleteButtonHOCProps } from './types';
import { onClick } from './events/onClick';
import { getPanelButtonShow } from '../../../selectors/getPanelButtonShow';

const mapStateToProps = (
    state: IApplicationState,
    props: IDeleteButtonHOCProps,
): IDeleteButtonFields => {
    let { boardId } = props;
    const isShow = getPanelButtonShow(state, boardId);
    return {
        isShow,
        tooltip: null,
    }
};
const mapDispatchToProps = (
    dispatch: any,
    { boardId }: IDeleteButtonHOCProps
): IDeleteButtonEvents => ({
    onClick: () => dispatch(onClick(boardId)),
});

export const DeleteButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(DeleteButton);

DeleteButtonHOC.displayName = 'DeleteButtonHOC';
