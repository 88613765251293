import * as React from 'react';
import { Button, Icon, Input } from 'kui';
import { IColorTagsDropdownProps } from './types';
import {
    CARD_COLOR_TAGS_ALL,
    CARD_COLOR_TAGS_BOARD
} from '../../../../../aside_panel/cardDetails/ColorTags/components/CardColorTags/constants';
import { ColorTagsSelect } from '../ColorTagsSelect/ColorTagsSelect';
import { CARD_COLOR_TAGS_LIMIT } from '../../../../../../../const';
import {
    COLOR_TAGS_BUTTON_EDIT,
    COLOR_TAGS_BUTTON_EDIT_SHORT,
    COLOR_TAGS_BUTTON_NEW,
    COLOR_TAGS_BUTTON_NEW_SHORT,
    COLOR_TAGS_INPUT_PLACEHOLDER,
    COLOR_TAGS_INPUT_PLACEHOLDER_EDIT,
    EDIT_COLOR_TAGS
} from './constants';
import './_ColorTagsDropdown.scss';
import { SegmentCardColorTagAddSourceValue } from '../../../../../../../middlewares/segment/trackEntities/cardEvents';
import { IColor, TColorTagColor } from '../../../../../../../types/model';
import { ColorPalette } from '../../../ColorPalette/ColorPalette/ColorPalette';
import { TColorId } from '../../../../../../../types/types';
import {
    CardColorTagsTooltipHOC
} from '../../../../../aside_panel/cardDetails/ColorTags/hocs/CardColorTagsTooltipHOC/CardColorTagsTooltipHOC';
import { DEFAULT_COLORS } from '../../hocs/ColorTagsDropdownHOC/constants';
import { CLASS_COLOR_TAGS_DROPDOWN } from '../../constants';
import { CLASS_CARD_DETAILS } from '../../../../../aside_panel/cardDetails';

export function ColorTagsDropdown ({
    boardCardColors,
    editingColor,
    boardColors,
    colorTags,
    customColors,
    isAutoFocus = true,
    isReset,
    isSmall,
    paletteVariant,
    onAddCustomColor,
    onChange,
    onClose,
    onEdit,
    onCreate,
    onDidMount,
    onDoubleClick,
    onDropdownOpen,
    onOpenSettings,
    onSave,
    onSetEditing,
    onSectionAllClick,
    onSectionUsedClick,
    onShowSnackbar,
    setRemovingSource,
    setRemovingId,
}: IColorTagsDropdownProps) {
    const className = CLASS_COLOR_TAGS_DROPDOWN;
    const classBoardColors = className + '__board-colors';
    const classInput = className + '__input';

    const isLimit = colorTags.length >= CARD_COLOR_TAGS_LIMIT;

    let [query, setQuery] = React.useState('');
    const [colorLabel, setColorLabel] = React.useState(editingColor);
    let [color, _setColor] = React.useState(null);
    let [availableBoardCardColors, _setAvailableBoardCardColors] = React.useState(boardCardColors);
    let [availableBoardColors, _setAvailableBoardColors] = React.useState(boardColors.filter(onColorsFilter));
    const [isBoardColorsOpened, setBoardColorsOpened] = React.useState(!!availableBoardCardColors.length);
    const [isAllColorsOpened] = React.useState(!availableBoardCardColors.length);
    const [isAllColorsForceOpened, setAllColorsForceOpened] = React.useState(null);
    const [isError, setError] = React.useState(null);
    const [isNew, setNew] = React.useState(null);
    const [isNewEditing, setNewEditing] = React.useState(null);

    const setAvailableBoardCardColors = (colors: IColor[]) => {
        availableBoardCardColors = colors;
        _setAvailableBoardCardColors(colors);
    };

    const onColorEditSelect = (color: IColor) => {
        onDoubleClick();
        setColorLabel(color)
    };

    const setAvailableBoardColors = (colors: IColor[]) => {
        availableBoardColors = colors;
        _setAvailableBoardColors(colors);
    };

    const setColor = (newColor: TColorTagColor) => {
        color = newColor
        _setColor(newColor);
    };

    const isEdit = colorLabel || isNewEditing || (availableBoardCardColors && !availableBoardCardColors.length && !availableBoardColors.length);

    const inputRef = React.useRef(null);

    function onColorsFilter(color: IColor) {
        return !availableBoardCardColors.find(boardColor => boardColor.id === color.id);
    }

    const onInputFocus = () => {
        if (inputRef.current) {
            inputRef.current.setFocus();
        }
    };

    const onColorSelect = (
        colorTagId: TColorId,
        source: SegmentCardColorTagAddSourceValue
    ) => {
        onChange(colorTagId, source).then(result => {
            if (!result) {
                setRemovingId(colorTagId);
                if (setRemovingSource) setRemovingSource(source);
                return;
            }
            if (isAutoFocus) {
                onInputFocus();
            } else {
                setTimeout(() => {
                    const color = document.querySelector('.' + CLASS_CARD_DETAILS + '__color-tags-item--' + colorTagId) as HTMLElement;
                    if (color) color.focus();
                }, 0);
            }
        });
    };

    const onQueryChange = (e: React.SyntheticEvent) => {
        const { value } = e.target as HTMLInputElement;
        setQuery(value);
        setError(false);
    };

    const onColorChange = (newColor: TColorTagColor, isNew: boolean) => {
        if (isNew) {
            setError(false);
            onAddCustomColor(newColor).then(() => {
                setColor(newColor);
            });
        } else if (newColor !== color) {
            setColor(newColor);
            setError(false);
            if (!colorLabel) { // чтобы не возвращаться к поиску при измнении инпута
                setNewEditing(true);
            }
        }
    };

    const onBack = () => {
        if (editingColor) {
            onClose();
        } else {
            setQuery('');
            setColor(null);
            setColorLabel(null);
            setNewEditing(false);
            if (colorLabel && !availableBoardCardColors.find(label => label.id === colorLabel.id)) {
                setAllColorsForceOpened(true);
            }
            if (onSetEditing) onSetEditing(false);
        }
    };

    const handleSave = () => {
        if (!query || !color) return;
        if (colorLabel) {
            const isExist = !!boardColors.find(colorTag => {
                return colorTag.id !== colorLabel.id &&
                    colorTag.color === color &&
                    colorTag.name.toLowerCase() === query.toLowerCase();
            });
            if (isExist) {
                setError(true);
                onShowSnackbar();
                setTimeout(onInputFocus, 0);
                return;
            }
            onEdit({ id: colorLabel.id, color, name: query });
        } else if (!isLimit) {
            onSave({ color, name: query }).then((colorTag) => {
                onChange(colorTag.id, SegmentCardColorTagAddSourceValue.NOT_CHECKBOX);
            });
        }
        onBack();
    };

    const setNewColor = () => {
        const colorsLength = DEFAULT_COLORS.length
        let newColor = DEFAULT_COLORS[Math.floor(Math.random() * colorsLength)]; // random color if none found
        for (let i = 0; i < colorsLength; i++) {
            const color = DEFAULT_COLORS[i]
            if (!boardCardColors.find(tag => tag.color === color)) {
                newColor = color;
                break;
            }
        }
        setColor(newColor);
    };

    const onNewClick = () => {
        if (
            isNew &&
            !isNewEditing &&
            (availableBoardColors.length || availableBoardCardColors.length)
        ) {
            setNewEditing(true);
            setNewColor();
            onInputFocus();
        } else {
            handleSave();
        }
        if (!colorLabel && !isLimit) {
            onCreate();
        }
    };

    const onKeyDown = (e: React.KeyboardEvent) => {
        if (isEdit) {
            if (e.key === 'Escape') {
                e.stopPropagation();
                onBack();
                return;
            }
            if (e.key === 'Enter') {
                e.stopPropagation();
                if (!colorLabel && !isLimit) {
                    onCreate();
                }
                onNewClick();
            }
        } else  {
            if (e.key === 'Enter') {
                e.stopPropagation();
                if (isNew && !isNewEditing) {
                    setNewEditing(true);
                    setNewColor();
                } else {
                    const colors = [...availableBoardColors, ...availableBoardCardColors];
                    if (query && colors.length === 1) { // если 100% совподение
                        if (availableBoardCardColors.length) { // лэйбел включен - сбрасываем поиск
                            onBack();
                        } else { // лэйбел выключен - добавляем на карту
                            onChange(colors[0].id, SegmentCardColorTagAddSourceValue.NOT_CHECKBOX);
                            onInputFocus();
                        }
                    }
                }
            }
        }
    };

    React.useEffect(() => {
        if (colorLabel) {
            setColor(colorLabel.color);
            setQuery(colorLabel.name);
            if (onSetEditing) onSetEditing(true);
            setTimeout(() => { // wait for dropdown to open
                const input = inputRef.current && inputRef.current.getInput();
                if (input) {
                    input.setSelectionRange(0, colorLabel.name.length);
                }
            }, 50);
        }
    }, [colorLabel]);

    React.useEffect(() => {
        if (colorLabel) return;
        let isNew = false; // когда инпут пуст скрываем создание нового
        if (query) {
            isNew = true; // преполагаем, что нужно показывать создание нового
            const search = query.toLowerCase();
            setAvailableBoardCardColors(boardCardColors.filter(colorTag => {
                const colorName = colorTag.name.toLowerCase();
                if (search === colorName) {
                    isNew = false; // имя тэга совпадает - скрываем
                }
                return colorName.includes(search);
            }));
            setAvailableBoardColors(
                boardColors
                    .filter(colorTag => {
                        const colorName = colorTag.name.toLowerCase();
                        if (search === colorName) {
                            isNew = false; // имя тэга совпадает - скрываем
                        }
                        return !availableBoardCardColors.find(boardColor => boardColor.id === colorTag.id) &&
                            colorName.includes(search);
                    })
            );
            if (// одно из имен совпало с инпутом, но скрываем только когда 100% совподение
                !isNew &&
                availableBoardColors.length + availableBoardCardColors.length !== 1
            ) {
                isNew = true;
            }
        } else {
            setAvailableBoardCardColors(boardCardColors);
            setAvailableBoardColors(boardColors.filter(onColorsFilter));
        }
        setNew(isNew);
        if (!color && !availableBoardCardColors.length && !availableBoardColors.length) {
            setNewColor();
        }
        setTimeout(onInputFocus, 0); // set focus from color to input
    }, [query, boardColors, boardCardColors]);

    React.useEffect(() => {
        onDidMount();
        if (onDropdownOpen) onDropdownOpen(boardCardColors.length, boardColors.length);
    }, []);

    React.useEffect(() => {
        if (isReset && isEdit) {
            setAvailableBoardCardColors(boardCardColors);
            setAvailableBoardColors(boardColors.filter(onColorsFilter));
            onBack();
        }
    }, [isReset]);

    React.useEffect(() => {
        setTimeout(onInputFocus, 0); // set focus from color to input
    }, [colorLabel, color]);

    React.useEffect(() => {
        setColorLabel(editingColor);
    }, [editingColor]);

    const isShowSave = !isEdit && isNew ||
        isEdit && (
            (
                !availableBoardCardColors.length &&
                !availableBoardColors.length ||
                isNewEditing
            ) ||
            colorLabel
        );

    const isButtonDisabled = (
            isNewEditing ||
            isEdit && !availableBoardCardColors.length && !availableBoardColors.length
        ) && (!query || isError) ||
        colorLabel && colorLabel.color === color && colorLabel.name === query;

    return (
        <div className={className} onKeyDown={onKeyDown}>
            <div className={classInput + '-container'}>
                <Input
                    ref={inputRef}
                    className={`
                        ${classInput}
                        ${isShowSave && isSmall ? classInput + '--short' : ''}
                        ${isShowSave && !colorLabel ? classInput + '--new' : ''}
                        ${isShowSave && colorLabel ? classInput + '--edit' : ''}
                    `}
                    autoFocus
                    placeholder={isEdit ? COLOR_TAGS_INPUT_PLACEHOLDER_EDIT : COLOR_TAGS_INPUT_PLACEHOLDER}
                    value={query}
                    onChange={onQueryChange}
                    autosize={false}
                    state={isEdit && isError ? 'error' : null}
                />
                {isShowSave &&
                    <Button
                        className={className + '__button-new'}
                        onClick={onNewClick}
                        disabled={isButtonDisabled}
                        variant={'text'}
                    >
                        {colorLabel ?
                            isSmall ? COLOR_TAGS_BUTTON_EDIT_SHORT : COLOR_TAGS_BUTTON_EDIT
                            : isSmall ? COLOR_TAGS_BUTTON_NEW_SHORT : COLOR_TAGS_BUTTON_NEW
                        }
                    </Button>
                }
            </div>
            {!colorLabel && !isEdit &&
                <>
                    <CardColorTagsTooltipHOC />
                    <div className={className + '__colors'}>
                        {isAllColorsOpened || (query.length && !availableBoardCardColors.length) ? null
                            : <ColorTagsSelect
                                title={CARD_COLOR_TAGS_BOARD}
                                colors={availableBoardCardColors}
                                checkedColors={colorTags}
                                disableAll={isLimit}
                                onChange={onColorSelect}
                                isHideTitle={!availableBoardColors.length}
                                opened={isBoardColorsOpened || !!query.length}
                                onSelect={onColorEditSelect}
                                onClick={onSectionUsedClick}
                                onOpen={() => setBoardColorsOpened(true)}
                            />
                        }
                        {!availableBoardColors.length || (query.length && !availableBoardColors.length) ? null
                            : <div className={classBoardColors}>
                                <ColorTagsSelect
                                    title={CARD_COLOR_TAGS_ALL}
                                    colors={availableBoardColors}
                                    checkedColors={colorTags}
                                    disableAll={isLimit}
                                    onChange={onColorSelect}
                                    opened={isAllColorsOpened || !!query.length || isAllColorsForceOpened}
                                    isHideTitle={isAllColorsOpened}
                                    onSelect={onColorEditSelect}
                                    onClick={onSectionAllClick}
                                />
                            </div>
                        }
                        {onOpenSettings &&
                            <Button
                                className={className + '__button'}
                                text={EDIT_COLOR_TAGS}
                                variant={'icon-text'}
                                onClick={onOpenSettings}
                            >
                                <Icon
                                    size={24}
                                    xlink={'pen'}
                                />
                            </Button>
                        }
                    </div>
                </>
            }
            {isEdit &&
                <ColorPalette
                    selectedColor={color}
                    isNoRaws={isSmall}
                    onSelect={onColorChange}
                    colors={[...DEFAULT_COLORS, ...customColors]}
                    variant={paletteVariant}
                    isColorPicker
                />
            }
        </div>
    );
};
