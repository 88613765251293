import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../../types/types';
import { IEstimateTimeEvents, IEstimateTimeFields } from '../../components/EstimateTime/types';
import { IEstimateTimeHOCProps } from './types';
import { EstimateTime } from '../../components/EstimateTime/EstimateTime';
import { onChange } from './events/onChange';
import { getSectionType } from '../../../../selectors/getSectionType';
import { ESettingsSectionType } from '../../../../../../base/components/SettingsSection/types';
import { getBoardWorkHoursInDay } from '../../../../../../../../store/model/selectors/getBoardWorkHoursInDay';

const mapStateToProps =
(
    state: IApplicationState,
    { boardId }: IEstimateTimeHOCProps
): IEstimateTimeFields => {
    const workHours = getBoardWorkHoursInDay(state, boardId);
    const sectionType = getSectionType(state, boardId, null, null, null);
    const allowChangeEstimateTime = sectionType !== ESettingsSectionType.DISALLOW && sectionType !== ESettingsSectionType.READONLY;
    return {
        text: workHours ? workHours.toString() : '',
        value: workHours ? workHours.toString() : '',
        allowChangeEstimateTime
    }
};

const mapDispatchToProps = (
    dispatch: any,
    { boardId }: IEstimateTimeHOCProps
): IEstimateTimeEvents => ({
    onChange: (value) => dispatch(onChange(boardId, value))
});

export const EstimateTimeHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(EstimateTime);

EstimateTimeHOC.displayName = 'EstimateTimeHOC';
