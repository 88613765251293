'use strict';

import * as _ from 'underscore';
import templateClose from '../view/notifications/template/notification-close.html';
import { dispatch, getAppState } from '../store/configureStore';
import { getActiveBoardLngs } from '../store/model/selectors/getActiveBoardLngs';
import { updateUser } from "app/store/model/authUser/effects/updateUser";
import { loadUser } from "app/rest/effects/authUser/loadUser";

var CONST = {
    defaultTimer: 10 * App.Util.TIME.SECOND * 1000,
    notificationWrapper: '.notifire-list--float'
};

App.Helpers.NotifyHelper = Backbone.View.extend({
    notifications: [],
    settings: {
        type: "default",
        layout: 'bottomRight',
        theme: 'notifire-list__item',
        dismissQueue: true, // If you want to use queue feature set this true
        force: false, // adds notification to the beginning of queue when set to true
        maxVisible: 5, // you can set max visible notification count for dismissQueue true option,
        timeout: CONST.defaultTimer,
        animation: {
            open: 'notifire-list__item--open',
            close: 'notifire-list__item--closing'
        },
        closeWith: ['click'], // ['click', 'button', 'hover', 'backdrop'] // backdrop click will close all notifications
        modal: false,
        killer: false,
        callback: {
            onShow: function() {
                this.di = this.options.callback.inject;
                this.di(this, true);
            },
            onClose: function() {
                var selfDestroy = _.bind(this.closeCleanUp, this);
                setTimeout(selfDestroy, 610);//todo use timer from options 
            }
        },
        buttons: false // an array of buttons, for creating confirmation dialogs.
    },

    initialize: function(options) {
        var lngs = getActiveBoardLngs(getAppState());
        this.fabrika = new App.Classes.NotificationFabrika();
        $.noty.defaults = this.settings;
        $.noty.defaults.callback.inject = _.bind(this.injection, this);
        this.initEventListeners();        
        this.templateClose = App.MLTemplate.getTemplate('#notification-close', lngs, templateClose);
    },

    injection: function(noty, flag) {
        if (flag) {
            noty.$bar.on('click contextmenu', _.bind(function(event) {
                this.notificationClickGate(noty, event);
            }, this));
        } else {
            noty.$bar.off('click contextmenu');
        }
    },

    notificationClickGate: function(notification, event) {
        var cid = notification.$bar.find('.notifire').data('cid');
        var view = this._getNotify(cid).view;
        view.trigger(event.type, event, notification);
    },

    setNotyLayout: function() {
        $.noty.layouts.bottomRight.container.style = this.setNotyStyle;
        $.noty.layouts.bottomRight.css.width = 'auto';
    },

    setNotyStyle: function() {
        $(this).addClass('notifire-list').addClass('notifire-list--float');
    },

    getNotificationFactory: function() {
        return this.fabrika;
    },

    initEventListeners: function() {
        App.vent.on(App.vent["notify:send"], this.send, this);
        App.vent.on(App.vent["notify:show"], this.send, this);
        App.vent.on(App.vent["notify:update"], this.update, this);
    },

    _getNotify: function(cid) {
        return _.findWhere(this.notifications, {id: cid}) || this._createNotify(cid);
    },

    _createNotify: function(cid) {
        return this.notifications[this.notifications.push({id: cid, obj: null}) - 1];
    },

    _addCloseButton: function () {                
        if (!$('.notifire-list-close').length) {
            var content = {
                title: Messages.getText('noty.close_all')
            }   
            $('#noty_bottomRight_layout_container').append(this.templateClose(content));
            $('.notifire-list').on('click', '.notifire-list-close', function(e){
                e.stopPropagation();
                $.noty.closeAll();
                $(this).remove();
            });
        }
    },

    send: function(model) {        
        this.setNotyLayout();        
        var view = this.getNotificationFactory().getInstance(model);
        this.listenTo(view, "change", this.update);
        var notify = this._getNotify(view.cid);
        var template = view.render().$el[0].outerHTML.trim();
        var settings = view.getSettings();
        notify.obj = noty(_.extend({template: template}, settings));
        notify.view = view;
        App.vent.trigger(App.vent['view:resized']);
        this._addCloseButton();
        //TODO Если количество превышает 5 послать команду на закрытие первого и слайснуть массив затем добавить новое
    },

    update: function(view) {
        var notify = this._getNotify(view.cid);
        notify.obj.$bar.html(notify.view.render().$el);
    },

    remove: function() {
        App.vent.off(null, null, this);
        return Backbone.View.prototype.remove.call(this);
    }
}, CONST);
