import { getMessages } from '../../../store/constants';

export const NO_PRIORITY = '0';
export const PRIORITY_ICONS = [
    {
        priority: NO_PRIORITY,
        icon: 'priority-no',
        text: getMessages().getText('priority.0')
    },
    {
        priority: '5',
        icon: 'priority-critical',
        text: getMessages().getText('priority.5')
    },
    {
        priority: '4',
        icon: 'priority-high',
        text: getMessages().getText('priority.4')
    },
    {
        priority: '3',
        icon: 'priority-medium',
        text: getMessages().getText('priority.3')
    },
    {
        priority: '2',
        icon: 'priority-normal',
        text: getMessages().getText('priority.2')
    },
    {
        priority: '1',
        icon: 'priority-low',
        text: getMessages().getText('priority.1')
    },
];
