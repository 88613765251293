import { getMessages } from 'app/store/constants';
import { THUMB_COMMENT_SIZE } from '../../../../../const';

export const CLASS_HTML_EDITOR = 'html-editor';

export const TOOLTIP_BOLD = getMessages().getText('html_editor.tooltip.bold');
export const TOOLTIP_ITALIC = getMessages().getText('html_editor.tooltip.italic');
export const TOOLTIP_STRIKE = getMessages().getText('html_editor.tooltip.strike');
export const TOOLTIP_ORDERED_LIST = getMessages().getText('html_editor.tooltip.ordered_list');
export const TOOLTIP_UNORDERED_LIST = getMessages().getText('html_editor.tooltip.unordered_list');
export const TOOLTIP_LINK = getMessages().getText('html_editor.tooltip.link');
export const TOOLTIP_IMAGE = getMessages().getText('html_editor.tooltip.image');
export const TOOLTIP_QUOTE = getMessages().getText('html_editor.tooltip.quote');
export const TOOLTIP_DELETE_FORMAT = getMessages().getText('html_editor.tooltip.delete_format');
export const TOOLTIP_LINK_PLACEHOLDER = getMessages().getText('html_editor.tooltip.link.placeholder');
export const HTML_EDITOR_GOOGLE_THUMB_SIZE = `w${THUMB_COMMENT_SIZE}`;
