import { IUserBoardActivityTimesSetAction } from './types';
import { AT_SET_ACTION } from './constants';
import { IUserBoardActivityTimes } from '../types';

export const userBoardActivityTimesSetAction = (
    items: IUserBoardActivityTimes
): IUserBoardActivityTimesSetAction => ({
    type: AT_SET_ACTION,
    items
});
