import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { snackbarInfoDefault } from '../../../../../snackbarsStack';
import { SNACKBAR_BOARD_DELETE_ID, SNACKBAR_DELETE } from '../constants';
import { TBoardDeleteType } from '../../../store/boards/types';

export const deleteBoardSnackbarDisallow: ActionCreator<ThunkAction> = () => {
    const action = (dispatch: Dispatch) => {
        const snackbarWordings = SNACKBAR_DELETE[TBoardDeleteType.disallow];
        const {
            text,
            title
        } = snackbarWordings;

        dispatch(snackbarInfoDefault({
            id: SNACKBAR_BOARD_DELETE_ID,
            text,
            title
        }));
    };
    return action;
};
