import { Dispatch } from '../../../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../../../types/types';
import { getTimeTrackerState } from '../../../../../aside_panel/cardDetails/TabTimingSection/TimeTracker/store/selectors/getTimeTrackerState';
import { cleverOpenCard } from '../../../../../base/effects/cleverOpenCard';
import { closeTimeTrackerDialog } from '../../../effects/closeTimeTrackerDialog';
import { getBoard } from 'app/store/model/selectors/getBoardById';
import { showTimeTrackerDialog } from 'app/view/react_components/timeTracker/hocs/TimeTrackerHOC/effects/showTimeTrackerDialog';

export const onOpen = (
    targetCardId: TCardId
) => {
    return (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        dispatch(closeTimeTrackerDialog());
        requestAnimationFrame(() => { // закрыть модал, потом открыть другой
            const {cardId, boardId} = getTimeTrackerState(state);
            const board = getBoard(state, boardId);
            if (board.id) {
                dispatch(showTimeTrackerDialog(targetCardId));
            } else {
                dispatch(cleverOpenCard(cardId, boardId));
                //todo maybe in timming tab ???
                //todo add dialog opening
            }
        });
    };
};
