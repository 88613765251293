import { Dispatch, ThunkAction } from '../../../../../../types/actions';
import { IApplicationState, IGetState, TCardId } from '../../../../../../types/types';
import { ICards } from '../../../../../../store/model/cards/types';
import { getRest } from '../getRest';
import { IRestCard } from '../../../../../../types/rest/IRestCard';
import { cardsGetRest } from './cardsGetRest';
import { cardsIsLoadingUpdate } from '../../../../../../store/requestsState/effects/cardsIsLoadingUpdate';
import { calcCardChecklistStatsByCardId } from '../../../../../../store/model/selectors/calcCardChecklistStatsByCardId';
import { cardSetChecklistStats } from '../../cardSetChecklistStats';
import { cardSubcardsGetRest } from './cardSubcardsGetRest';
import { getIsAssignedToMeActive } from '../../../../../../store/router/selectors/getIsAssignedToMeActive';
import { processRestCardData } from './processRestCardData';
import { getIsLinkCardActive } from '../../../../../../store/router/selectors/getIsLinkCardActive';
import { getCardIsLoading } from 'app/store/requestsState/selectors/getCardIsLoading';
import { cardsUpdate } from 'app/store/model/actionCreators/cardsUpdate';

export const cardFullGetRest = (
    cardId: TCardId,
    loadDependencies: boolean = true, // send event to socket
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (!getCardIsLoading(getState(), cardId)) {
            dispatch(cardsIsLoadingUpdate({ [cardId]: {} }, true));
        }

        const cardGetPromises: Promise<any>[] = [];
        cardGetPromises.push(dispatch(getRest(cardId)));

        return Promise.all(cardGetPromises)
            .then((cardsFromResponses: Array<IRestCard>) => {
                const cardGetSubcardsPromises: Promise<any>[] = [];
                if (getIsAssignedToMeActive(getState()) || getIsLinkCardActive(getState())) {
                    cardGetSubcardsPromises.push(dispatch(cardSubcardsGetRest(cardId)));
                }
                return Promise.all(cardGetSubcardsPromises)
                    .then(() => {
                        return cardsFromResponses;
                    })
            })
            .then((cardsFromResponses: Array<IRestCard>) => {
                const dependencyIds = getDependencyIds(cardsFromResponses);
                const dependencyForLoad = getDependencyForLoad(getState(), dependencyIds);
                if(dependencyForLoad.length) {
                    dispatch(cardsGetRest(dependencyForLoad));
                }
                return cardsFromResponses;
            })
            .then((cardsFromResponses: Array<IRestCard>) => {
                const cards: ICards = {};
                cardsFromResponses.forEach(card => {
                    cards[card.id] = card;
                });
                dispatch(cardsUpdate(cards)); // в atm карты ещё нет, поэтому сначала добавим карту, а потом processRestCardData
                cardsFromResponses.forEach(card => {
                    dispatch(processRestCardData(card));
                });
                for (let cardId in cards) {
                    const card = cards[cardId];
                    dispatch(cardSetChecklistStats(card.id, calcCardChecklistStatsByCardId(getState(), card.id))); // сравнить с текущим, если расходится - обновить
                }
                return cards;
            })
            .finally(() => {
                if (getCardIsLoading(getState(), cardId)) {
                    dispatch(cardsIsLoadingUpdate({ [cardId]: {} }, false));
                }
            });
    };
    return action;
};

export const getDependencyIds = (
    cards: Array<IRestCard>
): Array<TCardId> => {
    const ids = new Set<TCardId>();
    cards.forEach((card)=>{
        if (card.predecessors){
            card.predecessors.forEach((predecessor)=>{
                ids.add(predecessor.predecessorId);
            })
        }
        if (card.successors){
            card.successors.forEach((successors)=>{
                ids.add(successors.cardId);
            })
        }
        if (card.epicId){
            ids.add(card.epicId);
        }
    });
    return [...ids];
};

export const getDependencyForLoad = (
    state: IApplicationState,
    ids: Array<TCardId>
): Array<TCardId> => {
    const loadIds = new Array<TCardId>();
    ids.forEach((id) => {
        if (!state.model.cards[id]){
            loadIds.push(id);
        }
    });
    return loadIds;
};
