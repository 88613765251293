import { Dispatch, ThunkAction } from '../../../types/actions';
import { IGetState } from '../../../types/types';
import { IAuthUser } from '../../../store/model/authUser/types';
import { authUserPatch } from './api/helper/authUserPatch';
import { getAuthUser } from '../../../store/model/authUser/selectors/getAuthUser';
import { ILoginSpecificBoard } from 'app/types/rest/IUserMeta';

export const authUserSetLoginSpecificBoard = (
    specificBoard: ILoginSpecificBoard
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const authUser = getAuthUser(getState());
        const userPatch: IAuthUser = {
            id: authUser.id,
            meta: {
                loginSpecificBoard: specificBoard
            }
        };
        return dispatch(authUserPatch(userPatch));
    };
    return action;
};
