import * as React from 'react';
import { createRoot, Root } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import { SharingDialogHOC } from '../main/topBar/hocs/SharingDialogHOC/SharingDialogHOC';
import { MagicLinkHOC } from '../base/components/MagicLink/hocs/MagicLinkHOC/MagicLinkHOC';
import { IMagicLinkHOCProps } from '../base/components/MagicLink/hocs/MagicLinkHOC/types';

export class MagicLinkReactToBackbone {
    el: Element;
    root: Root;
    store: Store;

    constructor (el: HTMLElement, options: IMagicLinkHOCProps, store: Store) {
        this.root = createRoot(el!);
        this.store = store;
        this.render(options);
    }

    render (options: IMagicLinkHOCProps) {
        this.root.render(
            <Provider store={this.store}>
                <MagicLinkHOC {...options} />
            </Provider>
        );
    }

    remove () {
        if (!this.el) return;

        this.root.unmount();
        this.el.remove();
        this.el = null;
    }
}
