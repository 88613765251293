import { Dispatch, ThunkAction } from '../../../types/actions';
import { IGetState, TCardId } from '../../../types/types';
import { authUserPatch } from './api/helper/authUserPatch';
import { getAuthUser } from '../../../store/model/authUser/selectors/getAuthUser';

export const authUserSetInWorkCardId = (
    inWorkCardId: TCardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const authUser = getAuthUser(state);
        const userPatch = {
            id: authUser.id,
            inWorkCardId
        };
        return dispatch(authUserPatch(userPatch, undefined, true));
    };
    return action;
};
