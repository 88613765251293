import { IGetState } from '../../../../../../../../../types/types';
import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import {
    ESegmentFilterSource,
    SegmentFilterEvent
} from '../../../../../../../../../middlewares/segment/trackEntities/filterEvents';
import { addNewFilter } from '../../../../../effects/addNewFilter';
import { filterSegmentTrackAction } from '../../../../../effects/filterSegmentTrackAction';

export const onAddNewFilter: ActionCreator<ThunkAction> = (
    boardId: number
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(addNewFilter(boardId));
        dispatch(filterSegmentTrackAction(SegmentFilterEvent.NEW_FILTER_ADD_CLICKED, boardId, {
            name: 'source',
            value: ESegmentFilterSource.SECTION
        }));
    };
    return action;
};
