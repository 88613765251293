import { connect } from 'react-redux';
import { getBoardPermissionsAllowEdit } from './../../../../../../store/model/selectors/getBoardPermissionsAllowEdit';
import {
    INavigationPanelTabItemEvents,
    INavigationPanelTabItemFields
} from '../../components/NavigationPanelTabItem/types';
import { IApplicationState } from '../../../../../../types/types';
import { INavigationPanelTabItemProps } from './types';
import { NavigationPanelTabItem } from '../../components/NavigationPanelTabItem/NavigationPanelTabItem';
import { getTab } from '../../store/selectors/getTab';
import { getActiveBoardId } from '../../../../../../store/model/selectors/getActiveBoardId';
import { onBoardActivityClicked } from './events/onBoardActivityClicked';
import { onBoardSettingsClicked } from './events/onBoardSettingsClicked';
import { onBoardMenuIconClicked } from './events/onBoardMenuIconClicked';
import { onBoardMenuClose } from './events/onBoardMenuClose';
import { getBoardInitialState } from '../../../../../../store/model/constants';
import { getCurrentNavigationPanelType } from '../../helpers/getCurrentNavigationPanelType';
import { onClickCardTemplates } from './events/onClickCardTemplates';
import { onClickRecurringTasks } from './events/onClickRecurringTasks';
import { getBoard } from '../../../../../../store/model/selectors/getBoardById';
import {getBoardCardTemplateIdsSelector} from '../../../../aside_panel/cardTemplates/list/selectors/getBoardCardTemplateIds';
import { NAVIGATION_PANEL_BOARD_TOOLTIP_SHORT_NAME } from '../../constants';
import { getBoardLogoColor } from '../../../../../../store/model/selectors/getBoardLogoColor';
import { onClickReports } from './events/onClickReports';
import { onClickStarred } from './events/onClickStarred';
import { getBoardLockStatus } from 'app/store/model/selectors/getBoardLockStatus';
import { onClickChangeLogo } from './events/onClickChangeLogo';
import { onNameChanged } from './events/onNameChanged';
import { getBoardLogo } from 'app/store/model/selectors/getBoardLogo';
import { getHint } from 'app/view/react_components/hints/store/hints/selectors/getHint';
import { EHintKey } from 'app/view/react_components/hints';
import { onCloseHint } from 'app/view/react_components/main/navigationPanel/hocs/NavigationPanelTabItemHOC/events/onCloseHint';
import { getTypeSwitcherActiveType } from 'app/view/react_components/typeSwitcher/store/selectors/getTypeSwitcherActiveType';
import { getIsUserBasicPlanOnly } from '../../../../../../store/model/authUser/selectors/getIsUserBasicPlanOnly';
import { getBoardLogoIcon } from '../../../../../../store/model/selectors/getBoardLogoIcon';
import { onLogoChange } from './events/onLogoChange';
import { getShowProFeaturesTrial } from '../../../../../../store/model/authUser/selectors/getShowProFeaturesTrial';
import { getAuthUser } from '../../../../../../store/model/authUser/selectors/getAuthUser';
import { ONE_DAY_LENGTH } from '../../../../aside_panel/filterPanelBoard/constants';
import { onHintShow } from './events/onHintShow';

const mapStateToProps = (
    state: IApplicationState,
    ownProps: INavigationPanelTabItemProps
): INavigationPanelTabItemFields => {
    const {id} = ownProps;
    let source = getBoard(state, id);
    if (!source.id) {
        source = getTab(state, id) || getBoardInitialState();
    }
    const { name, starred } = source;
    const navigationPanelType = getCurrentNavigationPanelType(state);
    const allowEdit = getBoardPermissionsAllowEdit(state, id);
    const recurringCount = getBoardCardTemplateIdsSelector(state, {boardId: id, isRecurring: true}).length;
    const templatesCount = getBoardCardTemplateIdsSelector(state, {boardId: id, isRecurring: false}).length;
    const isActive = getActiveBoardId(state) === id;
    const isLong = !isActive && name.length > NAVIGATION_PANEL_BOARD_TOOLTIP_SHORT_NAME;
    const logo = getBoardLogo(state, id);
    const logoColor = getBoardLogoColor(state, id);
    const logoIcon = getBoardLogoIcon(state, id);
    const boardScrollHintStatus = getHint(state, EHintKey.BOARD_SCROLL).status;
    const activeBoardId = getActiveBoardId(state);
    const viewType = getTypeSwitcherActiveType(state, activeBoardId);
    const createDateTimeStamp = getAuthUser(state).createDateTimeStamp;
    const isNewUser = (new Date().getTime() - createDateTimeStamp * 1000) < ONE_DAY_LENGTH;

    return {
        boardScrollHintStatus,
        isActive,
        isBasicFeature: getIsUserBasicPlanOnly(state),
        isBlocked: getBoardLockStatus(state, id),
        isLong,
        isNewUser,
        isProFeature: getShowProFeaturesTrial(state),
        isReadOnly: !allowEdit,
        logo,
        logoColor,
        logoIcon,
        name,
        navigationPanelType,
        recurringCount,
        starred,
        templatesCount,
        viewType
    }
};

const mapDispatchToProps = (
    dispatch: any,
    ownProps: INavigationPanelTabItemProps
): INavigationPanelTabItemEvents => {
    const { id } = ownProps;
    return {
        onBoardActivityClicked: () => dispatch(onBoardActivityClicked(id)),
        onBoardSettingsClicked: () => dispatch(onBoardSettingsClicked(id)),
        onBoardMenuClose:  () => dispatch(onBoardMenuClose(id)),
        onBoardMenuIconClicked:  () => dispatch(onBoardMenuIconClicked()),
        onClickCardTemplates: () => dispatch(onClickCardTemplates()),
        onClickRecurringTasks: () => dispatch(onClickRecurringTasks()),
        onClickReports: () => dispatch(onClickReports()),
        onClickStarred: () => dispatch(onClickStarred(id)),
        onClickChangeLogo: () => dispatch(onClickChangeLogo()),
        onNameChanged: (name) => dispatch(onNameChanged(id, name)),
        onCloseHint: () => dispatch(onCloseHint()),
        onLogoChange: (logo) => dispatch(onLogoChange(id, logo)),
        onHintShow: () => dispatch(onHintShow())
    }
}

export const NavigationPanelTabItemHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(NavigationPanelTabItem);

NavigationPanelTabItemHOC.displayName = 'NavigationPanelTabItemHOC';
