import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { quickStartGuideSetAction } from '../../../store/navigationPanel/actions/quickStartGuideSetAction';
import { isAsideOpenSetAction } from '../../../store/navigationPanel/actions/isAsideOpenSetAction';

export const onClose = (): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        dispatch(quickStartGuideSetAction(null));
        dispatch(isAsideOpenSetAction(false));
    };
    return action;
};
