const category = 'Board manager';

export const SegmentBoardManagerEvent = {
    OPENED_BOARD_MANAGER: { category, event: 'Boards manager opened' },
    CLICKED_GO_BACK: { category, event: 'Boards manager Go back clicked' },
    CHANGED_TAB: { category, event: 'Changed tab in Boards manager' }, // TAB_NAME
    CLICKED_SEARCH_INPUT: { category, event: 'Search opened in Boards manager' },
    CHANGED_SEARCH_INPUT: { category, event: 'Search in Boards manager performed' },
    CLEARED_SEARCH_INPUT: { category, event: 'Search in Boards manager cleared' },
    OPENED_BOARDS_ACCORDION: { category, event: 'Boards accordion expanded' },
    CLOSED_BOARDS_ACCORDION: { category, event: 'Boards accordion collapsed' },
    CLICKED_CREATE_NEW_BOARD: { category, event: 'Create new board in Boards manager clicked' },
    CLICKED_BOARD_SETTINGS: { category, event: 'Board options in Boards manager clicked' },
    ESCAPE_RENAME_BOARD: { category, event: 'Board name change escaped' },
    CLICKED_DELETE_BOARD: { category, event: 'Delete board in Boards manager clicked' },
    DELETED_BOARD: { category, event: 'Board deleted via Boards manager' },
    CLICKED_MOVE_BOARD_BUTTON: { category, event: 'Move board in Boards manager clicked' },
    MOVED_BOARD: { category, event: 'Board moving in boards manager initiated' },
    OPEN_BOARD_CLICKED: { category, event: 'Board clicked' },
    CLICKED_IMPORT_FROM_TRELLO: { category, event: 'Import from trello in Boards manager clicked' },
    CLICKED_IMPORT_FROM_JIRA: { category, event: 'Import from JIRA in Boards manager clicked' },
    CLICKED_IMPORT_FROM_CSV: { category, event: 'Import from CSV in Boards manager clicked' },
    CLICKED_HOW_TO_ON_TEMPLATES_TAB: { category, event: 'Templates instruction in boards manager clicked' },
    CLICKED_CREATE_NEW_TEMPLATE: { category, event: 'Create new template in boards manager clicked' },
    CLOSED_TEMPLATE_PREVIEW: { category, event: 'Template preview closed' },
    CLICKED_PREVIEW_TEMPLATE: { category, event: 'Template preview opened' },
    CLICKED_USE_TEMPLATE: { category, event: 'Template used' },
    CLICKED_EDIT_TEMPLATE: { category, event: 'Edit template clicked' },
    CLICKED_TIPS_AND_TRICKS: { category, event: 'Tips and tricks clicked' },
    TRELLO_CLICKED_HOW_TO: { category, event: 'Trello instructions clicked' },
    RESTORE_FROM_XML_CLICKED: { category, event: 'Restore board from backup clicked' },
    RESTORE_FROM_XML_HOW_TO_CLICKED: { category, event: 'Restore board from backup instruction clicked' },
    MY_DRIVE_LIST_LOADED: { category, event: 'My drive list loaded' }
}

export enum SegmentBoardManagerOption {
    TAB = 'Tab',
    COUNT = 'Count',
    BOARD_COUNT = 'Board count'
}
