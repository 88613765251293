import { EMPTY_ARRAY, IApplicationState } from '../../../../types/types';
import { ISubscription } from '../../../../view/react_components/subscriptionAdmin/store/subscription/types';
import { getAuthUser } from './getAuthUser';

export const getUserActiveSubscriptions = (
    state: IApplicationState
): ISubscription[] => {
    const user = getAuthUser(state);

    return user.activeSubscription && user.activeSubscription.length
        ? user.activeSubscription
        : EMPTY_ARRAY;
};
