import { IBoardProgressLoaderFields } from '../../components/BoardProgressLoader/types';
import { getAuthUserPlatformType } from '../../../../../store/model/authUser/selectors/getAuthUserPlatformType';
import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../types/types';
import { BoardProgressLoader } from '../../components/BoardProgressLoader/BoardProgressLoader';

const mapStateToProps = (
    state: IApplicationState,
): IBoardProgressLoaderFields => {
   return {
       platformType: getAuthUserPlatformType(state)
   }
}

export const BoardProgressLoaderHOC = connect(
    mapStateToProps,
    null
)(BoardProgressLoader);

BoardProgressLoaderHOC.displayName = 'BoardProgressLoaderHOC';
