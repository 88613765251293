import store from '../../store/configureStore';
import SubscriptionAdminView from './subscription_admin_view';
import BaseDialogRenderer from './base_dialog_renderer';
import PriceDialogControllerView from './price_dialog_controller_view';
import { TimeTrackerDialogView } from "../main/timeTrackerDialogView";
import { TimeTrackerActionsView } from "../main/timeTrackerActionsView";
import { UserSettingsReactToBackbone } from '../react_components/reactToBackbone/UserSettingsReactToBackbone';
import { getAuthUser } from '../../store/model/authUser/selectors/getAuthUser';
import * as _ from 'underscore';

const App = window.App;

App.Views.ModalDialogRenderer = BaseDialogRenderer.extend({
    el: '.modal--scaled',
    initialize: function(options) {
        BaseDialogRenderer.prototype.initialize.call(this, options);
        this.tutoriaModalView = null;
        this.listenTo(App.vent, App.vent['modal:closing'], this.onClosing);
        this.listenTo(App.vent, App.vent['modal:toggle'], this.onToggle);
        this.listenTo(App.vent, App.vent['modal:closed'], this.onModalClose);
        this.modifiers = [
            'modal--wide',
            'modal--activate-promo',
            'modal--welcome',
            'modal--splash',
            'modal--payment',
            'modal--timetracker',
            'modal--to-dashboard',
            'modal--dashboard-close',
            'modal--fullscreen',
            'js-dashboard-dialog',
            'modal--tutorial-dialog',
            'modal--closing',
            'modal--tutorial-continue',
            'modal--print-gantt',
            'modal--open-boards-dialog',
            'modal--time-tracker',
        ];
    },

    onToggle: function(flag) {
        if (!_.isUndefined(flag)) {
            this.$el.toggleClass('modal--closing');
        } else {
            this.$el.toggleClass('modal--closing', flag);
        }
    },

    onClosing: function() {
        this.$el.addClass('modal--closing');
    },

    showOpenSettings: function() {
        this.beforeShow();
        this.$el.addClass('modal--fullscreen');
        this.currentView = new UserSettingsReactToBackbone(this.el, store);
    },

    // showSubscriptionManagement: function() {
    //     this.beforeShow();
    //     App.router.navigate(App.router.getUrl(), { trigger: true });
    //     this.$el.addClass('modal--subscription-management');

    //     dispatch(segmentTrackAction(SegmentSubscriptionManagementEvent.SHOW));
    //     this.currentView = new SubscriptionManagementView({
    //         className: 'modal-reactinbackbone',
    //         store: store
    //     });
    //     this.$el.append(this.currentView.render().$el);
    // },

    showSubscriptionManagement: function(subscriptionId = null) {
        this.beforeShow();
        this.$el.addClass('modal--subscription-admin modal--fullscreen');

        this.currentView = new SubscriptionAdminView({
            store,
            subscriptionId
        });
        this.$el.append(this.currentView.render().$el);
        App.controller.htmlScrollDisabledToggle(true);
    },

    showTimeTrackerDialogView: function({callBackCardId}) {
        this.beforeShow();
        this.$el.addClass('modal--time-tracker modal--show');
        this.currentView = new TimeTrackerDialogView({
            className: 'modal-reactinbackbone',
            store: store,
            callBackCardId
        });
        this.$el.append(this.currentView.render().$el);
    },

    showTimeTrackerActionsView: function({targetCardId} = {}) {
        this.beforeShow();
        this.$el.addClass('modal--time-tracker modal--show');
        this.currentView = new TimeTrackerActionsView({
            className: 'modal-reactinbackbone',
            store: store,
            targetCardId
        });
        this.$el.append(this.currentView.render().$el);
    },

    showPricing: function(eventOptions, noNavigateOnBack) {
        $('body').addClass('page--pricing');
        this.beforeShow();
        this.$el.addClass('modal--fullscreen');

        this.currentView = new PriceDialogControllerView({
            className: 'modal-reactinbackbone',
            eventOptions,
            noNavigateOnBack,
            parent: this,
            store,
        });
        this.$el.append(this.currentView.$el);
        this.currentView.render();
        App.controller.htmlScrollDisabledToggle(true);
    },

    showPromoCodeForm: function() {
        this.show(new App.Views.PromoCodeDialog({
            model: getAuthUser(store.getState())
        }), 'modal--activate-promo');
    },

    showGsuiteAdminForm: function(){
      this.show(new App.Views.GsuiteAdminDialog(), 'modal--fullscreen');
      App.controller.htmlScrollDisabledToggle(true);
    },

    showGoogleSpreadsheetPermissionsPage: function(){
        this.show(new App.Views.GoogleSpreadasheetPermissionsDialog(), 'modal--fullscreen');
        App.controller.htmlScrollDisabledToggle(true);
    },

    showDashboardClose: function(options) {
        this.show(new App.Views.DashboardCloseDialog({
            model: options
        }), 'modal--dashboard-close');
    },

    showNotificationsDialogView: function() {
        this.show(new App.Views.GoogleSpreadasheetPermissionsDialog())
    },


    onModalClose: function() {
        if(this.tutoriaModalView){
            this.tutoriaModalView.remove();
            this.tutoriaModalView = null;
        }
        if(this.currentView){
            this.clear();
        }
        $('body').removeClass('page--tutorial');
        $('body').removeClass('page--pricing');
        App.controller.htmlScrollDisabledToggle();
    },
    remove: function () {
        if(this.tutoriaModalView){
            this.tutoriaModalView.remove();
            this.tutoriaModalView = null;
        }
        return Backbone.View.prototype.remove.call(this);
    }
});
export default App.Views.ModalDialogRenderer;
