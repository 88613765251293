import { IABTestState } from '../types';

export const AT_AB_TESTS_VALUE_SET = 'AT_AB_TESTS_VALUE_SET';

export interface IABTestsValueSet {
    type: typeof AT_AB_TESTS_VALUE_SET;
    abTests: IABTestState;
}

export type TABTestAction = IABTestsValueSet;
