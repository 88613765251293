import * as React from 'react';
import '../../../../../aside_panel/cardDetails/ColorTags/components/CardColorTags/_CardColorTags.scss';
import { SectionAccordion, SelectList } from 'kui';
import { IColorTag } from '../../../../../../../types/types';
import {
    CardColorTagItem
} from '../../../../../aside_panel/cardDetails/ColorTags/components/CardColorTagItem/CardColorTagItem';
import { IColorTagSelectProps } from './types';
import './_ColorTagsSelects.scss';
import { CLASS_COLOR_TAGS_DROPDOWN } from '../../constants';

export function ColorTagsSelect({
    checkedColors = [],
    colors,
    disableAll,
    isHideTitle,
    opened,
    title,
    onChange,
    onClick,
    onOpen,
    onSelect
}: IColorTagSelectProps) {
    const className = CLASS_COLOR_TAGS_DROPDOWN + '-select';
    const classColumns = className + '__columns';
    const classColumn = className + '__column';

    const middleIndex = Math.ceil(colors.length / 2);
    const column1 = colors.slice(0, middleIndex);
    const column2 = colors.slice(middleIndex);

    const boardColorsColumn = (colors: IColorTag[]): JSX.Element[] => {
        if (!colors.length) return null;
        const colorTagsIds = checkedColors.map(tag => tag.id);

        return colors.map((item) => {
            const isChecked = colorTagsIds.includes(item.id);
            const isDisabled = !isChecked && disableAll;
            return (
                <CardColorTagItem
                    isChecked={isChecked}
                    isDisabled={isDisabled}
                    item={item}
                    key={item.id}
                    onChange={onChange}
                    onSelect={onSelect}
                />
            );
        });
    };

    const body = <div
        className={`
            ${classColumns}
            ${classColumns + '--no-title'}
        `}
    >
        <SelectList
            className={classColumn}
            fixActive={false}
        >
            {boardColorsColumn(column1)}
        </SelectList>
        <SelectList
            className={classColumn}
            fixActive={false}
        >
            {boardColorsColumn(column2)}
        </SelectList>
    </div>;

    return (
        <>
            {isHideTitle ?
                body
                : <SectionAccordion
                    className={className + '__section'}
                    variant={'simple'}
                    title={title}
                    opened={opened}
                    onOpen={() => {
                        if (onOpen) onOpen();
                        onClick();
                    }}
                    onClose={onClick}
                >
                    {body}
                </SectionAccordion>
            }
        </>
    );
};
