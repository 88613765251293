import { IMetaSetAction } from './types';
import { AT_META_SET } from './constants';
import { IRestFilterMeta } from '../../../rest/types';

export const metaSet = (
    meta: Partial<IRestFilterMeta>
): IMetaSetAction => ({
        type: AT_META_SET,
        meta
    }
);
