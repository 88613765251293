import { ICards } from '../../model/cards/types';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { IGetState } from '../../../types/types';
import { cardsRequestsStateSetAction } from '../actions/cardsRequestsStateSetAction';
import { ICardsRequestsState } from '../reducers/types';

export const cardsIsLoadingUpdate = (
    cards: ICards,
    isLoading: boolean
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {

        const cardsRequestsState: ICardsRequestsState = {};

        for (let cardId in cards) {
            cardsRequestsState[cardId] = { isLoading }
        }
        dispatch(cardsRequestsStateSetAction(cardsRequestsState));
    };
    return action;
};
