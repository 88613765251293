import {getABTestValue} from '../../../store/selectors/getABTestValue';
import {root} from '../../../../store/constants';
import {IApplicationState} from '../../../../types/types';
import {AB_TEST_FIRST_BOARD_TYPE, ABILITIES_BOARD_VALUE} from '../constants';
import {isPwc} from 'app/store/model/authUser/selectors/isPwc';
import {getAuthUser} from 'app/store/model/authUser/selectors/getAuthUser';
import {EAuthUserPlatformType} from 'app/types/rest/IRestAuthUser';

export const getFirstBoardId = (
    state: IApplicationState
): number => {
    const abTestValue = getABTestValue(state, AB_TEST_FIRST_BOARD_TYPE);
    if (abTestValue === ABILITIES_BOARD_VALUE)
        return parseInt(root.Settings.abilitiesBoardId);
    const user = getAuthUser(state);

    if(user.platformType === EAuthUserPlatformType.MICROSOFT) {
        return parseInt(root.Settings.firstBoardIdMicrosoft);
    } else {
        return parseInt(isPwc(state) ? root.Settings.firstBoardPwcId : root.Settings.firstBoardId);
    }
};
