import { IFilterKeyWordSetAction } from './types';
import { AT_KEYWORD_SET } from './constants';

export const keyWordSet = (
    searchedKeyWord: string
): IFilterKeyWordSetAction => ({
        type: AT_KEYWORD_SET,
        searchedKeyWord
    }
);
