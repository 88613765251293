import {INavigationPanelAnchorProps} from './types';
import * as React from 'react';
import './_NavigationPanelAnchor.scss'
import { NAVIGATION_PANEL_CLASS, NP_EXPAND_TIMER } from '../../constants';

export const NavigationPanelAnchor: React.FC<INavigationPanelAnchorProps> = (
    {
        onMouseHold,
        onMouseOut,
        onClick,
        isShown
    }
) => {
    const timerRef = React.useRef(null);
    const handleMouseOut = (e: React.MouseEvent) => {
        const target = e.relatedTarget as HTMLElement;
        const inPanel = Boolean(e && e.relatedTarget && target.closest('.' + NAVIGATION_PANEL_CLASS));
        if (!inPanel) {
            onMouseOut();
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        }
    };
    const handleMouseOver = () => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(() => {
            onMouseHold();
        }, NP_EXPAND_TIMER);
    };
    return (isShown &&
            <div className={'navigation-panel__anchor-area'}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
            />
    );
};
