import * as React from 'react';
import { IColorDropdownProps } from './types';
import { COLOR_ALL, COLOR_BOARD } from '../Color/constants';
import { CLASS_COLOR } from '../../constants';
import { ColorSelect } from '../ColorSelect/ColorSelect';

export function ColorDropdown ({
    boardColors,
    color,
    colors,
    onChange,
}: IColorDropdownProps) {
    const className = CLASS_COLOR + '-dropdown';

    const [usedColors, setUsedColors] = React.useState(boardColors);
    const [allColors, setAllColors] = React.useState(colors);

    const remainingColors = allColors.reduce((newColors, color) => {
        if (!usedColors.includes(color)) {
            newColors.push(color);
        }
        return newColors;
    }, []);

    return <div className={className}>
        {!!usedColors.length &&
            <ColorSelect
                colors={usedColors}
                selectedColor={color}
                title={COLOR_BOARD}
                onSelect={onChange}
            />
        }
        {!!remainingColors.length &&
            <ColorSelect
                colors={remainingColors}
                selectedColor={color}
                title={COLOR_ALL}
                isHideTitle={!usedColors.length}
                onSelect={onChange}
            />
        }
    </div>;
};
