import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { TSnackbarAction } from '../types';
import { TBoardDeleteType } from '../../../store/boards/types';
import { deleteBoardSnackbarDisallow } from './deleteBoardSnackbarDisallow';
import { deleteBoardSnackbarPromt } from './deleteBoardSnackbarPromt';
import { TBoardId } from '../../../../../../../types/types';

export const deleteBoardSnackbar: ActionCreator<ThunkAction> = (
    boardId: TBoardId,
    boardDeleteType: TBoardDeleteType,
    afterDelete: TSnackbarAction = null
) => {
    const action = (dispatch: Dispatch) => {
        if (boardDeleteType === TBoardDeleteType.disallow) {
            dispatch(deleteBoardSnackbarDisallow());
        } else {
            dispatch(deleteBoardSnackbarPromt(boardId, boardDeleteType, afterDelete));
        }
    };
    return action;
};
