import './_OptionButton.scss';
import * as React from 'react';
import { Button, ButtonDropdown, IButtonInheritedProps, Icon, SelectList, SelectListItem } from 'kui';
import { CLASS_OPTION_BUTTON } from './constants';
import { IOptionButtonProps } from './types';

export const OptionButton = ({
    icon,
    options,
    children,
    disabled,
    dropdownOpened,
    notBlurClasses,
    onDropdownOpen,
    onDropdownClick,
    ...attributes
}: IOptionButtonProps) => {
    const classButton = CLASS_OPTION_BUTTON + '__button';
    const classDropdown = CLASS_OPTION_BUTTON + '__dropdown';
    const classArrow = CLASS_OPTION_BUTTON + '__arrow';

    const onBlur = () => {
        const button = document.querySelector('.' + classArrow) as HTMLElement;

        setTimeout(() => {
            if (button) {
                button.blur();
            }
        }, 0);
    };

    return (
        <div
            className={CLASS_OPTION_BUTTON}
        >
            <Button
                className={classButton}
                variant={icon ? 'icon-text' : 'text'}
                disabled={disabled}
                {...attributes as IButtonInheritedProps}
            >
                {icon &&
                    <Icon size={24} xlink={icon}/>
                }
                {children}
            </Button>
            <ButtonDropdown
                className={classDropdown}
                onClick={onDropdownClick}
                disabled={disabled}
                opened={dropdownOpened}
                onOpen={onDropdownOpen}
                onClose={onBlur}
                notBlurClasses={notBlurClasses}
            >
                <Button
                    className={classArrow}
                    variant={'icon'}
                >
                    <Icon size={24} xlink={'arrow-down'} />
                </Button>
                <SelectList
                    fixActive={false}
                >
                    {
                        options.map((option, index) => {
                            const { name, icon, onClick, element, isHidden, iconEnd } = option;
                            if (isHidden) return;
                            if (element) return element;

                            return (
                                <SelectListItem
                                    icon={icon}
                                    iconSize={24}
                                    onClick={onClick}
                                    key={index}
                                >
                                    <>
                                        {name}
                                        {iconEnd && iconEnd}
                                    </>
                                </SelectListItem>
                            );
                        })
                    }
                </SelectList>
            </ButtonDropdown>
        </div>
    );
}
