import { IApplicationState } from '../../../../../../../types/types';
import { getUserPricingPoll } from '../../../../../../../store/model/authUser/selectors/getUserPricingPoll';
import { POLL_DELAY_AFTER } from '../../../../../base/components/Poll/components/constants';

export const getShowPricingPoll = (state: IApplicationState): boolean => {
    const { lastTimeShowed, isShowed, isTriggered } = getUserPricingPoll(state);
    if (isShowed || !isTriggered) return false;
    let isShow = !lastTimeShowed;
    if (lastTimeShowed) {
        const timeSinceLastShowed = Date.now() - lastTimeShowed;
        if (timeSinceLastShowed < POLL_DELAY_AFTER) { // только что ответил, показать Thank you
            isShow = true;
        }
    }
    return isShow;
}
