import { connect } from 'react-redux';
import { MyWorkTopBarTitle } from '../../components/MyWorkTopBarTitle/MyWorkTopBarTitle';
import { IMyWorkTopBarTitleEvents, IMyWorkTopBarTitleFields } from '../../components/MyWorkTopBarTitle/types';
import { onOpenBackground } from './events/onOpenBackground';
import { onOpenImport } from './events/onOpenImport';
import { onOpenSettings } from './events/onOpenSettings';
import { IApplicationState } from '../../../../../../types/types';
import {
    getBoardsUpdateCount
} from '../../../myWorkView/myWorkSettingsPanel/MyWorkImportTab/hocs/MyWorkBoardsSearchSelectHOC/selectors/getBoardsUpdateCount';

const mapStateToProps = (
    state: IApplicationState
): IMyWorkTopBarTitleFields => {
    return {
        newUpdates: !!getBoardsUpdateCount(state)
    };
}

const mapDispatchToProps = (
    dispatch: any
): IMyWorkTopBarTitleEvents => ({
    onOpenBackground: () => dispatch(onOpenBackground()),
    onOpenImportCards: () => dispatch(onOpenImport()),
    onOpenSettings: () => dispatch(onOpenSettings())
});

export const MyWorkTopBarTitleHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(MyWorkTopBarTitle);

MyWorkTopBarTitleHOC.displayName = 'MyWorkTopBarTitleHOC';
