import { TNotificationSettingsAction } from '../../notificationSettings/actions/types';

export const AT_NOTIFICATION_SETTINGS_ACTION_SET = 'NOTIFICATIONS_SETTINGS_NOTIFICATION_ACTION_SET';

export interface INotificationSettingsActionSetAction {
    type: typeof AT_NOTIFICATION_SETTINGS_ACTION_SET,
    boardId: number,
    notificationAction: TNotificationSettingsAction
}

export type TNotificationsSettingsAction = (INotificationSettingsActionSetAction);
