import { ThunkAction } from '../../../../types/actions';
import { root } from '../../../../store/constants';

export const recurringTasksOpen = (): ThunkAction => {
    const action = (
    ) => {
        if (!root.App.controller.isRelatedPanelNotOpened()) return;
        root.App.router.navigate(root.App.router.getUrl('recurring'), {trigger: true});
    };
    return action;
};
