import { ILists } from '../types';
import { IListsSetAction } from './types';
import { AT_SET } from './constants';

export const listsSet = (
    lists: ILists
): IListsSetAction => ({
    type: AT_SET,
    lists
});
