import { IGetState, TBoardId } from '../../../../../../../../types/types';
import {Dispatch, ThunkAction} from '../../../../../../../../types/actions';
import {loadPushNotificationsSettings} from '../../../../../../../../rest/effects/pushNotificationSettings/api/helpers/loadPushNotificationsSettings';
import { getPushNotificationSettingsByBoardId } from 'app/store/model/selectors/getPushNotificationSettingsByBoardId';

export const onDidMount = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const pushNotification = getPushNotificationSettingsByBoardId(getState(), boardId);
        if (!pushNotification) {
            dispatch(loadPushNotificationsSettings(boardId));
        }
    };
    return action;
};
