'use strict';

App.Views.CardUpdateNameNotification = App.Views.BaseNotification.extend({

    getActions: function() {
        return {
            action: Messages.getText('noty.card_update_name'),
            actionData: {
                icon: 'edit'
            }
        };
    },

    onClick: function(e, notification) {
        var dashboardId = this.notificationModel.dashboardId;
        var cardId = this.notificationModel.card.id;
        App.controller.cleverOpenCard(dashboardId, cardId);
        this.closeNotification(notification);
        e.stopPropagation();
    }
});
