import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { IDeleteButtonSectionProps } from './types';
import { DeleteButtonSection } from '../../components/DeleteButtonSection/DeleteButtonSection';
import { IDeleteButtonSectionFields } from '../../components/DeleteButtonSection/types';
import { getSectionType } from '../../../selectors/getSectionType';
import { getBoardPermissions } from '../../../../../../../store/model/selectors/getBoardPermissions';

const mapStateToProps = (
    state: IApplicationState,
    props: IDeleteButtonSectionProps,
): IDeleteButtonSectionFields => {
    const { boardId, cardId, sectionType: sectionTypeOwn } = props;
    const sectionType = getSectionType(state, boardId, cardId, sectionTypeOwn);
    const { authPermissions: { allowEdit } } = getBoardPermissions(state, boardId);

    return {
        boardId,
        cardId,
        sectionType,
        allowEdit
    }
};

export const DeleteButtonSectionHOC = connect(
    mapStateToProps,
)(DeleteButtonSection);
