import { Dispatch, ThunkAction } from 'app/types/actions';
import { IGetState, TCardId, TColorId } from 'app/types/types';
import { getCard } from 'app/store/model/selectors/getCard';
import { SegmentCardEvent } from 'app/middlewares/segment';
import { cardSaveColorTags } from 'app/rest/effects/card/card/cardSaveColorTags';
import { SegmentCardColorTagAddSourceValue } from 'app/middlewares/segment/trackEntities/cardEvents';
import { trackChangeColorTags } from '../effects/trackChangeColorTags';
import { addFeatureTrigger } from '../../../../../polls/featurePoll/effects/addFeatureTrigger';
import { ERateFeature } from '../../../../../../../types/rest/IUserMeta';
import { showSnoozeBlocker } from '../../../../../base/effects/showSnoozeBlocker';
import { root } from '../../../../../../../store/constants';
import {
    getAuthUserAllowColorTagCount
} from '../../../../../../../store/model/authUser/selectors/getAuthUserAllowColorTagCount';

export const onChange = (
    cardId: TCardId,
    colorId: TColorId,
    source: SegmentCardColorTagAddSourceValue,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const colorIds = [...getCard(state, cardId).colorIds];
        const maxCount = getAuthUserAllowColorTagCount(state);
        const count = colorIds.length;
        if (colorIds.includes(colorId)) {
            if (
                root.App.controller.allowCounterSnackbarHelper.isShowSnackbar('allowColorTagCount') &&
                count > maxCount
            ) {
                return Promise.resolve(false);
            } else {
                colorIds.splice(colorIds.indexOf(colorId), 1);
                dispatch(trackChangeColorTags(SegmentCardEvent.REMOVE_COLOR_TAG, source, colorIds.length));
                dispatch(addFeatureTrigger(ERateFeature.COLOR_TAGS));
                return dispatch(cardSaveColorTags(cardId, colorIds)).then(() => {
                    return Promise.resolve(true);
                });
            }
        } else {
            if (count >= maxCount) {
                dispatch(showSnoozeBlocker({ allow: 'allowColorTagCount' }));
            } else {
                colorIds.push(colorId);
                dispatch(trackChangeColorTags(SegmentCardEvent.ADD_COLOR_TAG, source, colorIds.length));
                dispatch(addFeatureTrigger(ERateFeature.COLOR_TAGS));
                return dispatch(cardSaveColorTags(cardId, colorIds)).then(() => {
                    return Promise.resolve(true);
                });
            }
        }
    };
    return action;
};
