import { EPushNotificationSubscribeCases } from '../../../../../../../../../types/rest/IRestPushNotificationSettings';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { subscribeCaseTypeValueMap } from '../constants';
import { SegmentBoardEvent, segmentTrackAction } from '../../../../../../../../../middlewares/segment';
import {
    SegmentBoardOption,
    SegmentBoardOptionStatusValue
} from '../../../../../../../../../middlewares/segment/trackEntities/boardEvents';
import { ISegmentEventOption } from '../../../../../../../../../middlewares/segment/trackEntities/types';

export const onNotifySubscribeCaseClick = (
    scope: EPushNotificationSubscribeCases,
    checked: boolean
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        if (subscribeCaseTypeValueMap[scope]) {
            const option: ISegmentEventOption = {
                name: SegmentBoardOption.STATUS,
                value: checked ? SegmentBoardOptionStatusValue.ON: SegmentBoardOptionStatusValue.OFF
            }
            dispatch(segmentTrackAction(SegmentBoardEvent.SUBSCRIBE_ME_TO_CARD_CLICKED, option,
                [{name: SegmentBoardOption.TYPE, value: subscribeCaseTypeValueMap[scope]}]));
        }
    };
    return action;
};
