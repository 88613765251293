import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { TCardId } from '../../../../../../../../types/types';
import { cardSetPriority } from '../../../../../../../../rest/effects/card/card/cardSetPriority';
import { SegmentCardEvent, SegmentCardOptions, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { NO_PRIORITY } from '../../../../../../helpers/constants';
import { ISegmentEventOption } from '../../../../../../../../middlewares/segment/trackEntities/types';
import { SegmentCardType } from '../../../../../../../../middlewares/segment/trackEntities/cardEvents';

export const onChange: ActionCreator<ThunkAction> = (
    cardId: TCardId,
    priority: string,
    onOwnChange: (priority: string) => void,
    source: SegmentCardType,
) => {
    const action = (
        dispatch: Dispatch,
    ) => {
        const option: ISegmentEventOption = source && {
            name: SegmentCardOptions.TYPE,
            value: SegmentCardType.CARD_INPUT
        };
        if(priority === NO_PRIORITY){
            dispatch(segmentTrackAction(SegmentCardEvent.REMOVED_PRIORITY, option));
        } else {
            dispatch(segmentTrackAction(SegmentCardEvent.ADDED_PRIORITY, option));
        }
        if (onOwnChange) {
            onOwnChange(priority);
        } else {
            dispatch(cardSetPriority(cardId, priority));
        }
    };
    return action;
};
