import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { ActionCreator } from 'redux';
import { getEditedNotificationsByCardId } from '../../../selectors/getEditedNotificationsByCardId';
import { saveNotificationsBeforeDueDate } from '../../../helpers/saveNotificationsBeforeDueDate';
import { nbdSaveClickedSegmentEvent, nbdSavedSegmentEvent } from './segmentEvents';
import { IGetState, TBoardId, TCardId } from '../../../../../../../../../types/types';
import { TStatus } from '../../../../../../../../../types/model';
import { popupStatusForCardUpdate } from '../../../state/effects/popupStatusForCardUpdate';

export const focusNotificationById: ActionCreator<ThunkAction> = (
    notificationId: number,
) => {
    const action = () => {
        if (notificationId) {
            //console.log(`focus notification by id = ${notificationId}`);
        }
    };
    return action;
};

export const focusNewlyCreatedNotification: ActionCreator<ThunkAction> = () => {
    const action = () => {
        //console.log('focusing newly created notification');
    };
    return action;
};

export const editingPopupSaveEffect: ActionCreator<ThunkAction> = (
    boardId: TBoardId,
    cardId: TCardId,
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        dispatch(popupStatusForCardUpdate(cardId, false));
        const state = getState();
        const activeNotifications = getEditedNotificationsByCardId(state, cardId);
        const deletedNotifications = getEditedNotificationsByCardId(state, cardId, TStatus.STATUS_DELETED);
        const notifications = [...activeNotifications, ...deletedNotifications];
        if (notifications && notifications.length) {
            dispatch(nbdSaveClickedSegmentEvent(activeNotifications));
        }
        return dispatch(saveNotificationsBeforeDueDate(boardId, cardId, notifications))
            .then(() => {
                dispatch(nbdSavedSegmentEvent(notifications));
            });
    };
    return action;
};
