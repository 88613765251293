import * as React from 'react';
import './_FilterBar.scss';
import { CLASS_MY_WORK, FILTER_ACTIONS_PORTAL } from '../../../../../main/myWorkView/constants';
import {
    Button,
    ButtonDropdown,
    ButtonTitle,
    Divider,
    Icon,
    ITooltipInheritedProps,
    SelectList,
    SelectListItem
} from 'kui';
import { IFilterBarProps } from './types';
import { FilterHOC } from '../../hocs/FilterHOC/FilterHOC';
import {
    MY_WORK_FILTER_BAR_ACTIONS_TOOLTIP,
    MY_WORK_FILTER_BAR_BUTTON_FILTER,
    MY_WORK_FILTER_BAR_TOOLTIP_FILTER_CLOSE,
    MY_WORK_FILTER_BAR_TOOLTIP_FILTER_OPEN, TOP_BAR_Z_INDEX
} from './constants';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { FilterGroupByHOC } from '../../hocs/FilterGroupByHOC/FilterGroupByHOC';
import { root } from '../../../../../../../store/constants';
import { MY_WORK_CONTROL_CLASS } from '../../../../../main/myWorkView/cards/constants';
import {
    MY_WORK_FILTER_BAR_ADD_FILTER,
    MY_WORK_FILTER_BAR_SELECT_ALL_FILTERS,
    MY_WORK_FILTER_BAR_UNSELECT_ALL_FILTERS
} from '../constants';
import { GOOGLE_SPACING } from '../../../../../../../const';
import { MY_WORK_FILTER_BAR_EXPAND } from '../Filter/constants';
import { TOP_BAR_CLASS } from '../../../../../main/topBar/constants';
import { ProFeatureIndicator } from '../../../ProFeatureIndicator/ProFeatureIndicator';

export function FilterBar({
    allowSavedFilters,
    enabledFilterCount,
    filterIds,
    groupBy,
    isAsidePanelOpened,
    isFilter,
    isCollapsed,
    isProFeature,
    onAdd,
    onFilterClose,
    onFilterOpen,
    onSort,
    onToggleAll,
    onToggleCollapse
}: IFilterBarProps) {
    const isAllFiltersEnabled = filterIds.length === enabledFilterCount;

    const className = CLASS_MY_WORK + '__filter-bar';
    const classNamePause = isAsidePanelOpened ? className + '--pause' : '';
    const classAdd = className + '-add';
    const classFilterList = className + '-list';
    const classFilterListWrapper = classFilterList + '-wrapper';
    const classFilter = classFilterList + '-filter';
    const classFilterButton = className + '-filter-panel';
    const classButton = classFilterButton + '-button';
    const classActive = classFilterButton + '--active';
    const classDivider = classFilterButton + '-divider';
    const classClose = classFilterButton + '-close';
    const classArrowButton = classFilterList + '-button';
    const classShadow = classFilterList + '-shadow';
    const classScroll = classFilterList + '-scroll';

    //disable drag to be able to close dropdown menu on button click in Filter or when collapsed
    const [isDragDisabled, setDragDisabled] = React.useState(null);
    const [width, setWidth] = React.useState(null);
    const [isLeftPanel, setLeftPanel] = React.useState(null);
    const [isRightPanel, setRightPanel] = React.useState(null);

    const scrollRef = React.useRef(null);

    const onDragEnd = (result: DropResult) => {
        if (
            !result.destination ||
            result.destination.droppableId === result.source.droppableId &&
            result.destination.index === result.source.index
        ) {
            // do nothing
        } else {
            onSort(Number(result.draggableId.slice(4)), result.destination.index);
        }
        const topBar = document.querySelector('.' + TOP_BAR_CLASS) as HTMLElement;
        if (topBar) {
            topBar.style.zIndex = TOP_BAR_Z_INDEX;
        }
    };

    const onDragStart = () => {
        const topBar = document.querySelector('.' + TOP_BAR_CLASS) as HTMLElement;
        if (topBar) {
            topBar.style.zIndex = '0'; // for element to go over;
        }
    };

    const onScrollClick = (directionLeft: boolean) => {
        const list = scrollRef.current as HTMLElement;
        if (list) {
            list.scrollLeft += list.offsetWidth * 0.5 * (directionLeft ? -1 : 1);
        }
        onFixList();
    };

    const onFixList = () => {
        const list = scrollRef.current as HTMLElement;
        if (!list) return;
        const offset = GOOGLE_SPACING * 0.5;
        if (list.scrollLeft > offset) {
            setLeftPanel(true);
        } else {
            setLeftPanel(false);
        }
        if (list.scrollLeft + list.offsetWidth + offset < list.scrollWidth) {
            setRightPanel(true);
        } else {
            setRightPanel(false);
        }
    };

    React.useEffect(() => {
        const onWheel = (e: React.WheelEvent) => {
            const list = scrollRef.current as HTMLElement;
            if (!list) return;
            const isMacLike = navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i) ? true : false;

            if (!isMacLike) {
                e.cancelable && e.preventDefault();
                const left = e.deltaX || e.deltaY;
                list.scrollLeft += left;
            }
            onFixList();
        }

        const scroll = scrollRef.current;
        scroll && scroll.addEventListener('wheel', onWheel, { passive: false });
        return () => {
            scroll && scroll.removeEventListener('wheel', onWheel);
        }
    }, []);

    React.useEffect(() => {
        const onResize = () => {
            setTimeout(() => { //wait for groupBy to change
                const rightEl = document.querySelector('.' + className + '-right') as HTMLElement;
                const filterButton = document.querySelector('.' + classFilterButton) as HTMLElement
                if (rightEl && filterButton && isCollapsed) {
                    setWidth(rightEl.offsetWidth + GOOGLE_SPACING + filterButton.offsetWidth + GOOGLE_SPACING); // + margin
                } else if (rightEl) {
                    setWidth(rightEl.offsetWidth + GOOGLE_SPACING); // + margin
                }
                onFixList();
            }, 0);
        };

        onResize();

        root.addEventListener('resize', onResize);
        return () => root.removeEventListener('resize', onResize);
    }, [isCollapsed, groupBy, isFilter]);

    React.useEffect(() => {
        onFixList();
    }, [filterIds]);

    React.useEffect(() => { // первый раз иногда не успевает правую стрелку подставить и при включении выключении фильтров
        setTimeout(onFixList, 600); // в css width transition delay стоит, поэтому нужно подождать
    }, [enabledFilterCount]);

    React.useEffect(() => {
        root.App.controller.myWorkFilterBarCollapseToggle();
    }, [isCollapsed])

    const filterButton = <div
        className={`
            ${classFilterButton}
            ${isFilter ? classActive : ''}
            ${isProFeature ? classFilterButton + '--pro-feature' : ''}
        `}
    >
        <div className={classButton + '-wrapper'}>
            <Button
                className={`${classButton} handle-click-ignore`}
                variant={'icon-text'}
                tooltip={{
                    value: MY_WORK_FILTER_BAR_TOOLTIP_FILTER_OPEN,
                    isNoWrap: true,
                    direction: 'down'
                }}
                onClick={onFilterOpen}
            >
                <Icon className={classButton + '-icon'} xlink={'filters'} size={24}/>
                <ButtonTitle>{MY_WORK_FILTER_BAR_BUTTON_FILTER}</ButtonTitle>
            </Button>
        </div>
        {isProFeature && <ProFeatureIndicator />}
        {isFilter &&
            <>
                <div className={classDivider}/>
                <Button
                    className={classClose}
                    variant={'icon'}
                    onClick={onFilterClose}
                    tooltip={{
                        value: MY_WORK_FILTER_BAR_TOOLTIP_FILTER_CLOSE,
                        isNoWrap: true,
                        direction: 'down'
                    }}
                >
                    <Icon size={24} xlink={'close'}/>
                </Button>
            </>
        }
    </div>;

    const actions = <ButtonDropdown
        className={className + '-actions'}
        portal
        portalId={FILTER_ACTIONS_PORTAL}
    >
        <Button
            className={className + '-more'}
            tooltip={{
                isNoEvents: true,
                isNoWrap: true,
                value: MY_WORK_FILTER_BAR_ACTIONS_TOOLTIP,
                direction: 'down'
            } as ITooltipInheritedProps}
            variant={'icon'}
        >
            <Icon xlink={'more'} size={24}/>
        </Button>
        <SelectList
            fixActive={false}
            className={`${MY_WORK_CONTROL_CLASS} handle-click-ignore`}
        >
            <SelectListItem
                icon={'plus'}
                onClick={onAdd}
                iconSize={24}
            >
                {MY_WORK_FILTER_BAR_ADD_FILTER}
            </SelectListItem>
            <Divider className={className + '-divider'} />
            <SelectListItem
                icon={'read-notification'}
                onClick={onToggleAll}
                iconSize={24}
            >
                {isAllFiltersEnabled ? MY_WORK_FILTER_BAR_UNSELECT_ALL_FILTERS
                    : MY_WORK_FILTER_BAR_SELECT_ALL_FILTERS}
            </SelectListItem>
            <Divider className={className + '-divider'} />
            <SelectListItem
                className={className + '-action--collapse'}
                icon={'expand'}
                onClick={onToggleCollapse}
                iconSize={24}
            >
                {MY_WORK_FILTER_BAR_EXPAND}
            </SelectListItem>
        </SelectList>
    </ButtonDropdown>;

    const addButton = <Button
        onClick={onAdd}
        className={`${classAdd} handle-click-ignore`}
        variant={'icon'}
        tooltip={{
            value: MY_WORK_FILTER_BAR_ADD_FILTER,
            isNoWrap: true,
            direction: 'down'
        }}
    >
        <Icon xlink={'plus'} size={24} />
        {isProFeature && <ProFeatureIndicator />}
    </Button>;

    return (
        <div className={`${className} ${classNamePause}`}>
            {isCollapsed && filterButton}
            <div
                className={className + '-left'}
                style={width ? { width: `calc(100% - ${width}px)` } : null}
            >
                {!isCollapsed && addButton}
                <div className={classFilterListWrapper}>
                    {isLeftPanel &&
                        <>
                            <Button
                                variant={'icon'}
                                className={`
                                    ${classArrowButton}
                                    ${classArrowButton + '--left'}
                                `}
                                onClick={() => onScrollClick(true)}
                            >
                                <Icon xlink={'arrow-left'} size={24} />
                            </Button>
                            <div className={`${classShadow} ${classShadow + '--left'}`} />
                        </>
                    }
                    <div
                        className={`
                            ${classScroll}
                            ${isLeftPanel ? classScroll + '--left' : ''}
                            ${isRightPanel ? classScroll + '--right' : ''}
                        `}
                    >
                        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
                            <Droppable droppableId={classFilterList} direction={'horizontal'} type={'column'}>
                                {
                                    (provided, snapshot) => (
                                        <div
                                            className={classFilterList}
                                            ref={(node) => {
                                                provided.innerRef(node);
                                                scrollRef.current = node;
                                            }}
                                            {...provided.droppableProps}
                                        >
                                            {filterIds.map((filterId, index) => (
                                                <Draggable
                                                    draggableId={'bar-' + filterId} // unique id for asidePanel
                                                    index={index}
                                                    key={'bar-' + filterId}
                                                    isDragDisabled={isDragDisabled || isCollapsed}
                                                >
                                                    {
                                                        (provided, snapshot) => (
                                                            <div
                                                                className={`
                                                                ${classFilter}
                                                                ${snapshot.isDragging ? classFilter + '--dragging' : ''}
                                                            `}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                ref={provided.innerRef}
                                                            >
                                                                <FilterHOC
                                                                    isFilterEnabled={isFilter || !allowSavedFilters}
                                                                    filterId={filterId}
                                                                    isCollapsed={isCollapsed}
                                                                    setDragDisabled={setDragDisabled}
                                                                    isAllFiltersEnabled={isAllFiltersEnabled}
                                                                    setListWidth={onFixList}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                </Draggable>
                                            ))
                                            }
                                            {provided.placeholder}
                                        </div>
                                    )
                                }
                            </Droppable>
                        </DragDropContext>
                    </div>
                    {isRightPanel &&
                        <>
                            <Button
                                variant={'icon'}
                                className={`
                                ${classArrowButton}
                                ${classArrowButton + '--right'}
                            `}
                                onClick={() => onScrollClick(false)}
                            >
                                <Icon xlink={'arrow-right'} size={24} />
                            </Button>
                            <div className={`${classShadow} ${classShadow + '--right'}`} />
                        </>
                    }
                </div>
                {isCollapsed &&
                    <>
                        {addButton}
                        {actions}
                    </>
                }
            </div>
            <div className={className + '-right'}>
                {!isCollapsed && filterButton}
                <FilterGroupByHOC />
            </div>
        </div>
    );
}
