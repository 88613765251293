import { ITimeTrackerState } from '../types';
import { ITimeTrackerAction } from '../actions/types';
import { setTimeTrackerLocalStorage } from '../localStorage/actions/setTimeTrackerLocalStorage';
import { getInitialTimeTracker } from '../constants';
import { AT_DELETE, AT_SET } from '../actions/constants';

export const initialState = getInitialTimeTracker();

const _timeTrackerReducer = (
    state: ITimeTrackerState = initialState,
    action: ITimeTrackerAction
): ITimeTrackerState => {
    switch (action.type) {
        case AT_SET : {
            return {
                ...state,
                ...action.data
            };
        }
        case AT_DELETE : {
            return null;
        }
        default: {
            return state;
        }
    }
};

export const timeTrackerReducer = (
    state: ITimeTrackerState = initialState,
    action: ITimeTrackerAction
): ITimeTrackerState => {
    const newState = _timeTrackerReducer(state, action);
    if (newState !== state) {
        setTimeTrackerLocalStorage(newState);
    }
    return newState;
};
