import { calcDateOfNotification } from '../utils/calcDateOfNotification';
import { MIN_INTERVAL_SECOND } from '../constants/constants';
import { IApplicationState, TCardId } from 'app/types/types';
import { getEditedNotificationsByCardId } from '../selectors/getEditedNotificationsByCardId';
import { getMessages } from 'app/store/constants';
import { getCard } from 'app/store/model/selectors/getCard';
import { INotificationBeforeDueDate } from 'app/store/model/notificationBeforeDueDate/types';

export const checkEditStateValid = (
    state: IApplicationState,
    cardId: TCardId,
) => {
    const card = getCard(state, cardId);
    const { dueDate } = card;
    const notifications = getEditedNotificationsByCardId(state, cardId);
    return checkIsValid(dueDate, notifications);
};

const checkIsValid = (
    dueDate: number,
    notifications: INotificationBeforeDueDate[],
): boolean => {
    if (notifications && notifications.length) {
        const failedNotification = notifications.find(
            (notification) => {
                return getErrorMessageIfNotificationsNotValid(dueDate, notifications, notification) !== null;
            },
        );
        return failedNotification === undefined;
    } else {
        return true;
    }
};

export const getErrorMessageIfNotificationsNotValid = (
    dueDate: number,
    notifications: INotificationBeforeDueDate[],
    notification: INotificationBeforeDueDate,
): string => {
    if (checkInPast(dueDate, notification)) {
        return getMessages().getText('error.notifications_before_due_date.in.past');
    }
    if (checkLessMinTime(dueDate, MIN_INTERVAL_SECOND, notification)) {
        return getMessages().getText('error.notifications_before_due_date.less.5.minutes');
    }
    if (checkDuplicate(notification, notifications)) {
        return getMessages().getText('error.notifications_before_due_date.exist');
    }
    return null;
};

const checkInPast = (
    dueDate: number,
    notification: INotificationBeforeDueDate,
) => {
    const { interval, unit } = notification;
    const dateOfNotification = calcDateOfNotification(interval, unit, dueDate);
    const result = new Date().getTime() / 1000 > dateOfNotification;
    return result;
};

const checkDuplicate = (
    notification: INotificationBeforeDueDate,
    notifications: INotificationBeforeDueDate[],
): boolean => {
    const duplicate = notifications.find(
        (item) => {
            if (notification.id !== item.id) {
                return notification.unit === item.unit &&
                    notification.interval === item.interval;
            }
        },
    );
    const result = duplicate !== undefined;
    return result;
};

const checkLessMinTime = (
    dueDate: number,
    minTimeSeconds: number,
    notification: INotificationBeforeDueDate,
) => {
    const { interval, unit } = notification;
    const dateOfNotification = calcDateOfNotification(interval, unit, dueDate);
    const result = dueDate - dateOfNotification + 1 < minTimeSeconds;//5 min - 5 min =0 and this is valid result
    return result;
};
