import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { IGetState, TBoardId } from '../../../../../../../../../types/types';
import {
    getBoardShowIconAndColorLogo
} from '../../../../../../../../../store/model/board/selectors/getBoardShowIconAndColorLogo';
import { logoDelete } from '../effects/logoDelete';

export const onDidUnmount = (
    boardId: TBoardId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (getBoardShowIconAndColorLogo(getState(), boardId)) {
            return dispatch(logoDelete(boardId));
        }
    };
    return action;
};
