import { getCard } from './getCard';
import { IApplicationState, TCardId } from '../../../types/types';
import { ICard } from '../card/types';

const getRealGanttOrderNumber = (
    card: ICard
): number => {
    const isSubCard = card.epicId ? card : false;
    if (isSubCard) {
        return card.subCardOrderNumber != null ? card.subCardOrderNumber : card.id;
    }
    return card.ganttOrderNumber !== null ? card.ganttOrderNumber : card.id; // показывать последней
}

const sortSubcards = (
    cardA: ICard,
    cardB: ICard,
): number => {
    return getRealGanttOrderNumber(cardA) - getRealGanttOrderNumber(cardB);
};

export const getCardSubcards = (
    state: IApplicationState,
    cardId: TCardId
): ICard[] => {
    const card = getCard(state, cardId);
    if (
        !card ||
        !card.subCardIds ||
        !card.subCardIds.length
    ) return [];

    return card.subCardIds.reduce((arr, cardId) => {
        const card = getCard(state, cardId);
        if (card) {
            arr.push(card);
        }
        return arr;
    }, []);
}

export const getCardSubcardsSorted = (
    state: IApplicationState,
    cardId: TCardId
): ICard[] => {
    const cards = getCardSubcards(state, cardId);
    if (
        !cards ||
        !cards.length
    ) return [];

    return cards.sort(sortSubcards);
}
