import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { deleteDependency } from '../../../../../../../../rest/effects/card/dependence/deleteDependency';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';

export const onDeleteDependency = (
    dependencyId: number
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.REMOVED_PREDECESSOR));
        return dispatch(deleteDependency(dependencyId));
    };
    return action;
};
