import { fetchHandler } from "./fetchHandler";
import {userIdentifyTraitsSelector} from "../middlewares/segment/selectors/userIdentifyTraitsSelector";

class BaseProvider {
    constructor() {}
    identify(userId, traits, options) {}
    track(name, trackOptions) {}
    alias(previousId, newUserId) {}
    reset() {}
}

class AmplitudeProvider extends BaseProvider {

    statusSent = false;
    statusResult = null;

    constructor(settings) {
        super();
        (function(e,t){var n=e.amplitude||{_q:[],_iq:{}};var r=t.createElement("script")
        ;r.type="text/javascript"
        ;r.crossOrigin="anonymous";r.async=false
        ;r.src="https://cdn.amplitude.com/libs/amplitude-7.2.1-min.gz.js"
        ;r.onload=function(){if(!e.amplitude.runQueuedFunctions){
            console.log("[Amplitude] Error: could not load SDK")}}
        ;var i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)
        ;function s(e,t){e.prototype[t]=function(){
            this._q.push([t].concat(Array.prototype.slice.call(arguments,0)));return this}}
            var o=function(){this._q=[];return this}
            ;var a=["add","append","clearAll","prepend","set","setOnce","unset"]
            ;for(var u=0;u<a.length;u++){s(o,a[u])}n.Identify=o;var c=function(){this._q=[]
                ;return this}
            ;var l=["setProductId","setQuantity","setPrice","setRevenueType","setEventProperties"]
            ;for(var p=0;p<l.length;p++){s(c,l[p])}n.Revenue=c
            ;var d=["init","logEvent","logRevenue","setUserId","setUserProperties","setOptOut","setVersionName","setDomain","setDeviceId", "enableTracking", "setGlobalUserProperties","identify","clearUserProperties","setGroup","logRevenueV2","regenerateDeviceId","groupIdentify","onInit","logEventWithTimestamp","logEventWithGroups","setSessionId","resetSessionId"]
            ;function v(e){function t(t){e[t]=function(){
                e._q.push([t].concat(Array.prototype.slice.call(arguments,0)))}}
                for(var n=0;n<d.length;n++){t(d[n])}}v(n);n.getInstance=function(e){
                e=(!e||e.length===0?"$default_instance":e).toLowerCase()
                ;if(!n._iq.hasOwnProperty(e)){n._iq[e]={_q:[]};v(n._iq[e])}return n._iq[e]}
            ;e.amplitude=n})(window,document);

        const ampOptions = {
            includeUtm: true,
        };

        if (location.search && location.search.includes('amp_device_id')) {
            ampOptions.deviceIdFromUrlParam = true;
        }

        amplitude.getInstance().init(settings.amplitudeApiKey, null, ampOptions);
    }

    identify(userId, traits, options) {
        amplitude.getInstance().setUserId(userId);
        amplitude.getInstance().setUserProperties(traits);

        if (!this.statusSent) {
            setTimeout(() => {
                this.sendStatus(Boolean(this.statusResult), userId);
            }, 20000);
            this.statusSent = true;
        }

    }

    track(name, trackOptions) {
        if (this.statusResult !== null) {
            amplitude.getInstance().logEvent(name, trackOptions);
        } else {
            const trackExec = new TrackExecutor(name, trackOptions, amplitude.getInstance(), (result) => {
                this.statusResult = result;
            });
            trackExec.exec();
        }
    }

    alias(previousId, newUserId) {
        if (previousId) {
            amplitude.getInstance().setUserId(previousId);
        }
    }

    reset() {
        amplitude.getInstance().setUserId(null);
    }

    sendStatus(status, userId) {
        fetchHandler('/rest/users/amplitudeStatus/'+status + '?' + userId, {
            method: 'PATCH'
        });
    }

}
const TrackExecutor = function(name, trackOptions, amplitude, callback) {
    this.success = false;
    this.timerId = null;
    this.exec = () => {
        this.timerId = setTimeout(() => {
            if (!this.success) {
                callback(false);
            }
        }, 10000);

        amplitude.logEvent(name, trackOptions, (code, str, data) => {
            if (this.timerId) {
                clearTimeout(this.timerId);
                this.timerId = null;
            }
            if (code === 200) {
                this.success = true;
                callback(true);
            } else {
                callback(false);
            }
        });
    }
}

class CustomerIoProvider extends BaseProvider {
    constructor(settings) {
        super();
        window._cio = window._cio || [];
        (function() {
            var a,b,c;a=function(f){return function(){_cio.push([f].
            concat(Array.prototype.slice.call(arguments,0)))}};b=["load","identify",
                "sidentify","track","page"];for(c=0;c<b.length;c++){_cio[b[c]]=a(b[c])};
            var t = document.createElement('script'),
                s = document.getElementsByTagName('script')[0];
            t.async = true;
            t.id    = 'cio-tracker';
            t.setAttribute('data-site-id', settings.customerIoApiKey);
            t.src = 'https://assets.customer.io/assets/track.js';
            s.parentNode.insertBefore(t, s);
        })();
        this._cio = window._cio;
    }

    identify(userId, traits, options) {
        const params = {...traits, ...{id: userId}};
        if (params.createdAt) {
            params.created_at = params.createdAt.getTime() / 1000;
        }
        this._cio.identify(params);

        setInterval(() => {
            const params = {...userIdentifyTraitsSelector(), ...{id: userId}};
            if (params.createdAt) {
                params.created_at = params.createdAt.getTime() / 1000;
            }
            this._cio.identify(params);
        }, 1000 * 60 * 60 * 10); // 10 hrs
    }

    track(name, trackOptions) {
        this._cio.track(name, trackOptions);
    }

}

class AnalyticsRouter {
    constructor(settings) {
        this.devMode = settings.development;
        this.providers = [new AmplitudeProvider(settings), new CustomerIoProvider(settings)];
    }

    identify(userId, traits, options) {
        this.providers.forEach(provider => {
            provider.identify(userId, traits, options);
        })
        if (this.devMode) {
            console.log('Sent identify to segment', traits);
        }
    }

    track(name, trackOptions) {
        this.providers.forEach(provider => {
            provider.track(name, trackOptions);
        })
        if (this.devMode) {
            console.log('Sent track to segment: ' + name, trackOptions);
        }
    }

    alias(previousId, newUserId) {
        this.providers.forEach(provider => {
            provider.alias(previousId, newUserId);
        })
        if (this.devMode) {
            console.log('Sent alias to segment', previousId);
        }
    }

    reset() {
        this.providers.forEach(provider => {
            provider.reset();
        })
    }

}

export function insertAnalytics(){
    if (!window.analytics) {
        window.analytics = new AnalyticsRouter(window.App.settings);
        if (typeof window.analyticsOnLoad === 'function'){
            window.analyticsOnLoad();
        }
    }
};
