import { IAsidePanelEvents, IAsidePanelFields } from '../../components/AsidePanel/types';
import { AsidePanel } from '../../components/AsidePanel/AsidePanel';
import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../types/types';
import { IAsidePanelBoardHOCProps } from './types';
import { getActiveTabIndexByTabKey } from './selectors/getActiveTabIndexByTabKey';
import { CLASS_ASIDE_BOARD } from '../../../boardDetails/constants';
import { getBoardLockStatus } from '../../../../../../store/model/selectors/getBoardLockStatus';
import { getAsidePanelIsMobile } from '../../store/selectors/getAsidePanelIsMobile';
import { getUserTimeFormat12 } from '../../../../../../store/model/authUser/selectors/getUserTimeFormat12';
import { getBoardIsLoading } from '../../../../../../store/requestsState/selectors/getBoardIsLoading';
import { getBoardActiveRequests } from '../../../../../../store/requestsState/selectors/getBoardActiveRequests';
import { onTabActive } from './effects/onTabActive';
import { onDidMount } from './events/onDidMount';
import { onDidUnmount } from './events/onDidUnmount';
import { onResize } from '../AsidePanelHOC/events/onResize';
import { onClose } from './events/onClose';
import { onResized } from '../AsidePanelHOC/events/onResized';
import { getBoardDetailsTabs } from './selectors/getBoardDetailsTabs';
import { getAsidePanelWidth } from '../../store/selectors/getAsidePanelWidth';

const mapStateToProps = (
    state: IApplicationState,
    ownProps: IAsidePanelBoardHOCProps
): IAsidePanelFields => {
    const { boardId, tabKey } = ownProps;
    const tabs = getBoardDetailsTabs(state, boardId);
    const activeTabIndex = getActiveTabIndexByTabKey(state, tabs, tabKey);

    return {
        activeTabIndex,
        asidePanelWidth: getAsidePanelWidth(state),
        boardId,
        className: CLASS_ASIDE_BOARD,
        isButtonsFixed: true,
        isLoading: getBoardIsLoading(state, boardId) || getBoardLockStatus(state, boardId),
        isMobile: getAsidePanelIsMobile(state),
        isRequests: Boolean(getBoardActiveRequests(state, boardId)),
        isUserTimeFormat12: getUserTimeFormat12(state),
        tabs,
    }
};

const mapDispatchToProps = (
    dispatch: any,
    ownProps: IAsidePanelBoardHOCProps
): IAsidePanelEvents => {
    const { boardId } = ownProps;
    return {
        onTabActive: (activeTabIndex) => dispatch(onTabActive(activeTabIndex)),
        onDidMount: () => dispatch(onDidMount(boardId)),
        onDidUnmount: () => dispatch(onDidUnmount(boardId)),
        onResize: (width) => dispatch(onResize(width)),
        onResized: (width) => dispatch(onResized(width)),
        onClose: () => dispatch(onClose()),
    }
};

export const AsidePanelBoardHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(AsidePanel);

AsidePanelBoardHOC.displayName = 'AsidePanelBoardHOC';
