import Util from '../../util/util';
import { fetchHandler } from '../../util/fetchHandler';
import { IRestBoardForOpenDialog } from '../../view/react_components/dialogs/openBoards/rest/types';

export const boardLoaderByFileIdDriveId = (
    fileId: string,
    driveId: string,
) => {
    return fetchHandler<IRestBoardForOpenDialog[]>(
        Util.getApiUrl(`/rest/dashboards/getBoardByFileId?fileId=${fileId}&driveId=${driveId}`)
    );
};
