import { EViewTypes } from '../../../../const';
import { IApplicationState } from '../../../../types/types';
import { getAuthUser } from './getAuthUser';

export const getUserDefaultView = (
    state: IApplicationState
): EViewTypes => {
    const user = getAuthUser(state);
    return user.meta && user.meta.defaultView || EViewTypes.KANBAN_VIEW_TYPE;
};
