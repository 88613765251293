export class BoardLoaderInfoHelper {
    boardName?: string;
    cardsToLoadCount?: number;
    isFirstLoad?: boolean = true;
    loadedCardsCount?: number;
    isFirstBoard?: boolean;

    resetBoardLoaderInfo() {
        this.boardName = null;
        this.cardsToLoadCount = null;
        this.loadedCardsCount = 0;
    }
}
