import { Action, ActionCreator, Reducer, Store } from 'redux';
import { Dispatch, ThunkAction } from './actions';
import { IReportsState } from '../view/react_components/reports/types/types';
import { TColorTagColor } from './model';
import { IFeedbackState } from '../view/react_components/dialogs/feedback/store/types';
import { IOpenBoardsDialogState } from '../view/react_components/dialogs/openBoards/store/types';
import { ISnackbarsStackState } from '../view/react_components/snackbarsStack/store/types';

import { TFiltersState } from '../view/react_components/aside_panel/filterPanelBoard/store/types';
import { IListDetailsState } from '../view/react_components/aside_panel/listDetails/store/types';
import { IListViewState } from '../view/react_components/listView/store/listView/types';
import { IClickManager } from '../view/react_components/clickManager/store/types';
import { INavigationPanelState } from '../view/react_components/main/navigationPanel/store/navigationPanel/types';
import { ITopBarBoardState } from '../view/react_components/main/topBar/store/topBarBoard/types';
import { IReportsBoardState } from '../view/react_components/reports/UserProductivity/UserProductivityReport/store/reportsBoard/types';
import { ICookieDialogState } from '../view/react_components/dialogs/cookies/store/types';
import { ICardNumbersState } from '../view/react_components/aside_panel/boardDetails/details/CardNumber/store/cardNumbers/types';
import { ICards } from '../store/model/cards/types';
import { ISignIn } from '../view/react_components/dialogs/signIn/store/signIn/types';
import { IHints } from '../view/react_components/hints/store/hints/types';
import { IABTestState } from '../abtesting/store/types';
import { IRelatedCardsState } from '../view/react_components/aside_panel/relatedCards/store/types';
import { ICardPanelState } from '../view/react_components/aside_panel/cardDetails/store/reducers/types';
import { IDependencies } from '../store/model/dependencies/dependencies/types';
import { IAsidePanelState } from '../view/react_components/aside_panel/asidePanel/store/types';
import { ILists } from '../store/model/lists/types';
import { IBoards } from '../store/model/boards/types';
import { IKanbanViewState } from '../view/react_components/main/kanbanView/store/reducers/types';
import { IRouterState } from '../store/router/reducers/types';
import { IDriveDocs } from '../store/model/driveDocs/types';
import { IAuthUser } from '../store/model/authUser/types';
import { TModelCollaboratorsState } from '../store/collaborators/modelsCollaborators/types';
import { IRequestsState } from '../store/requestsState/reducers/types';
import { IAutoBackupsBoard } from '../view/react_components/aside_panel/boardDetails/backups/AutoBackups/store/autoBackupsBoard/types';
import { INotificationsSettings } from '../store/model/notificationsSettings/types';
import { IPushNotificationsSettings } from '../store/model/pushNotificationsSettings/types';
import { ILibThemes } from '../store/model/libThemes/types';
import { ITimeTrackerState } from '../view/react_components/aside_panel/cardDetails/TabTimingSection/TimeTracker/store/types';
import { IChecklists } from '../store/model/checklists/checklists/types';
import { IBoardsExportState } from '../view/react_components/aside_panel/boardDetails/export/store/types';
import { IBackups } from '../store/model/backups/types';
import { IGanttViewState } from '../view/react_components/gantt/store/ganttView/types';
import { INotificationBeforeDueDateEditsState } from '../view/react_components/aside_panel/cardDetails/TimingSection/notificationsBeforeDueDate/state/notificationBeforeDueDateEditsState/types';
import { ICardAssignees } from '../store/model/cardAssignees/types';
import { IMyWorkState } from '../view/react_components/main/myWorkView/store/types';
import { IAccountDeleteViewState } from '../view/react_components/accountDelete/store/types';
import { TTabsModelState } from '../store/model/tabs/types';
import { ICardWithBoard } from '../store/model/card/types';
import { IActivities } from '../store/activities/types';
import { IUserBoardActivityTimes } from '../store/model/boardUserActivityTimes/types';
import * as deepFreeze from 'deep-freeze';
import { ITypeSwitcherState } from '../view/react_components/typeSwitcher/store/types';

export interface IHOCEvent<T extends any[] = []> {
    (...args: T): (dispatch?: Dispatch, getState?: IGetState) => any;
}

export interface ISubscriptionManagementState {
    inputOpen: boolean;
    inputString: string;
    recognizedItems: string[];

    searchingString: string;

    isItemEditing: boolean;
    editingItemId: number;
    editingValue: string;
    isEditingValid: boolean;

    itemSetToRemove: number;

    itemsNew: number[];
    itemsEdited: number[];
}

export interface IDoneCancelState {
    done_cancel_isShown: boolean;
}

export interface IUserMessageState extends IDoneCancelState {
}

export interface ICustomerPortalState {
    access_url: string;
    redirect_url: string;
}

export interface IApplicationState {
    router: IRouterState;
    reports?: IReportsState;
    reportsNew?: IReportsBoardState;
    model: IModelState;
    snackbarsStack: ISnackbarsStackState;
    notificationBeforeDueDateEdits?: INotificationBeforeDueDateEditsState;
    feedBackForm?: IFeedbackState;
    pushToGoogleCalendar?: any;
    listView?: IListViewState;
    clickManager: IClickManager;
    filters: TFiltersState;
    listDetails?: IListDetailsState;
    openBoardsDialog?: IOpenBoardsDialogState;
    navigationPanel: INavigationPanelState;
    typeSwitcher: ITypeSwitcherState;
    topBar: ITopBarBoardState;
    cookieDialog: ICookieDialogState;
    hints: IHints;
    signIn: ISignIn;
    abTesting: IABTestState;
    cardPanel: ICardPanelState;
    cardNumbers?: ICardNumbersState;
    autobackups?: IAutoBackupsBoard;
    relatedCards?: IRelatedCardsState;
    asidePanel: IAsidePanelState;
    requestsState: IRequestsState;
    kanbanView: IKanbanViewState;
    boardsExport?: IBoardsExportState;
    modelsCollaborators: TModelCollaboratorsState;
    timeTracker: ITimeTrackerState;
    myWork?: IMyWorkState;
    ganttView?: IGanttViewState;
    accountDelete?: IAccountDeleteViewState;
    activities: IActivities;
}

export type IGetState = () => IApplicationState;

export interface IModelState {
    dashboards: IBoards;
    lists: ILists;
    cards: ICards;
    activeDashboardId: number;
    authUser: IAuthUser;
    tabs: TTabsModelState;
    dependencies: IDependencies;
    checklists: IChecklists;
    driveDocs: IDriveDocs,
    notificationsSettings: INotificationsSettings,
    pushNotificationsSettings?: IPushNotificationsSettings,
    libThemes?: ILibThemes,
    backups?: IBackups,
    cardAssignees?: ICardAssignees,
    inWorkCard?: ICardWithBoard,
    boardUserActivityTimes?: IUserBoardActivityTimes,
}

export type TApplicationStore = Store<IApplicationState> & {
    reducerManager: IReduceManager;
}

export interface IReduceManager {
    getReducerMap: () => Record<string, Reducer>;
    reduce: (state: IApplicationState, action: Action) => Reducer<IApplicationState>,
    add: (key: string, reducer: Reducer) => void,
    remove: (key: string) => void;
}

export type StoreAction<T = {}> = ActionCreator<T>;
export type Effect = ActionCreator<ThunkAction>;

export type TActivityId = number;
export type TBoardId = number;
export type TCardId = number;
export type TColorId = number;
export type TFirstBoardId = number;
export type TCardAssigneeRoleId = number;
export type TFilterId = number;
export type TListId = number;
export type TNBDId = number;
export type TUserId = string;
export type TTag = string;
export type TChecklistItemId = number;
export type TRecurringTaskId = number;
export type TChecklistId = number;
export type TThemeId = number;
export type TPermissionId = string;
export type TBoardPermissionId = string;
export type TCustomPropertyId = string;
export type TCustomOptionId = string;
export type TDriveId = string;
export type TSwimlaneId = string;

// @ts-ignore
export const EMPTY_ARRAY: any[] = deepFreeze([]);

export interface IColorTag {
    id?: TColorId;
    color?: TColorTagColor;
    name?: string;
}

export interface IGDriveFile {
    id: string,
    title: string,
    mimeType: string,
    url: string,
    iconUrl: string,
    alternateLink: string,
    iconLink: string
}

export interface IGoogleDriveOpenDialogueFile {
    id: string;
    name: string;
    mimeType: string;
    url: string;
    iconUrl: string;
    isNew: boolean;
}

export enum EShareMode {
    EDIT = 'EDIT',
    VIEW = 'VIEW',
    COMMENT = 'COMMENT',
}

export enum EHTMLEditorType {
    WYSIWYG = 'WYSIWYG',
    MARKDOWN = 'MARKDOWN',
}
