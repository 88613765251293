import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId, TTag } from '../../../../../../../../types/types';
import { cardSetTags } from '../../../../../../../../rest/effects/card/card/cardSetTags';

export const onSort = (
    cardId: TCardId,
    tags: TTag[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(cardSetTags(cardId, tags));
    };
    return action;
};
