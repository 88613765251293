import * as deepFreeze from 'deep-freeze';
import { IBoards } from '../types';
import { AT_BOARD_ACTION_DELETE, AT_BOARD_ACTION_SET, TBoardsAction } from '../actions/types';
import { boardReducer } from '../../board/reducers/boardReducer';

const initialState: IBoards = {};

const _boardsReducer = (
    state = initialState,
    action: TBoardsAction
): IBoards  => {
    switch (action.type) {
        case AT_BOARD_ACTION_SET:{
            const {boardId, boardAction} = action;
            return {
                ...state,
                [boardId]: boardReducer(state[boardId], boardAction)
            }
        }
        case AT_BOARD_ACTION_DELETE: {
            const {boardId} = action;
            const result = {...state};
            if (result[boardId]) {
                delete result[boardId];
            }
            return result;
        }
        default: {
            return state;
        }
    }
};

export const boardsReducer = (
    state = initialState,
    action: TBoardsAction
): IBoards  => {
    // @ts-ignore
    if(window.Settings.development) {
        // @ts-ignore
        return deepFreeze(_boardsReducer(state, action));
    } else {
        return _boardsReducer(state, action);
    }
};
