import { IAuthUser } from '../types';

export const AT_UPDATE = 'AUTH_USER_UPDATE';
export const AT_SET = 'AUTH_USER_SET';

export interface IUpdateAction {
    type: typeof AT_UPDATE;
    authUser: IAuthUser;
}

export interface ISetAction {
    type: typeof AT_SET;
    authUser: IAuthUser;
}

export type TAuthUserAction = (
    IUpdateAction |
    ISetAction
);
