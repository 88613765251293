import { IHint } from '../types';
import { EHintStatus } from '../../../constants';

export const getHintInitial = (
): IHint => {
    const hint: IHint = {
        status: EHintStatus.NOT_SHOWN
    };
    return hint;
}
