import { IAsidePanelEvents, IAsidePanelFields } from '../../components/AsidePanel/types';
import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../types/types';
import { IAsidePanelCardTemplatesHOCProps } from './types';
import { AsidePanel } from '../../components/AsidePanel/AsidePanel';
import { getAsidePanelIsMobile } from '../../store/selectors/getAsidePanelIsMobile';
import { onResize } from '../AsidePanelHOC/events/onResize';
import { CARD_TEMPLATES_TITLE, CLASS_NAME_CARD_TEMPLATES } from './constants';
import { onClose } from './events/onClose';
import { onResized } from '../AsidePanelHOC/events/onResized';
import { CardTemplatesBody } from '../../../cardTemplates';

const mapStateToProps = (
    state: IApplicationState,
    {boardId}: IAsidePanelCardTemplatesHOCProps
): IAsidePanelFields => ({
    boardId,
    isLeft: true,
    isMobile: getAsidePanelIsMobile(state),
    title: CARD_TEMPLATES_TITLE,
    children: CardTemplatesBody({
        boardId,
        isRecurring: false,
    }),
    className: CLASS_NAME_CARD_TEMPLATES
});

const mapDispatchToProps = (
    dispatch: any
): IAsidePanelEvents => ({
    onResize: (width) => dispatch(onResize(width)),
    onResized: (width) => dispatch(onResized(width)),
    onClose: () => dispatch(onClose())
});

export const AsidePanelCardTemplatesHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(AsidePanel);

AsidePanelCardTemplatesHOC.displayName = 'AsidePanelCardTemplatesHOC';
