import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../../../types/types';
import { copyCard } from '../effects/copyCard';

export const onClick = (
    cardId: TCardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(copyCard(cardId));
    };
    return action;
};
