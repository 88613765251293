import { IApplicationState, TListId } from '../../../../types/types';
import { getListCardAgingSettings } from './getListCardAgingSettings';

export const getListCardAgingOpacity = (
    state: IApplicationState,
    listId: TListId
): boolean => {
    const cardAgingSettings = getListCardAgingSettings(state, listId);

    return cardAgingSettings && cardAgingSettings.opacity;
}
