import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { root } from '../../../../../../../../store/constants';
import { IGetState, TCardId, TBoardId } from '../../../../../../../../types/types';

export const openCardOnBoard = (
    cardId: TCardId,
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        root.App.controller.cleverOpenCard(boardId, cardId);
    };
    return action;
};
