import { IMessageData, TMessage, TRoomId } from './SocketProvider';

export class Room {
    roomId: TRoomId;
    emitCloseProvider: IEmitCloseProvider;

    private listeners: Array<IRoomSubscriber> = [];

    constructor(roomId: TRoomId, emitProvider: IEmitCloseProvider) {
        this.roomId = roomId;
        this.emitCloseProvider = emitProvider;
    }

    emit (message: TMessage) {
        this.emitCloseProvider.emit(message);
    };

    private _close() {
        this.emitCloseProvider.closeRoom();
    };

    onMessage(message: IMessageData) {
        this.listeners.forEach(listener => {
            listener.updateModelByObj && listener.updateModelByObj(message)
        })
    }

    onNewSocketMessage(message: IMessageData) {
        this.listeners.forEach(listener => {
            listener.updateNewSocketModelByObj && listener.updateNewSocketModelByObj(message);
        });
    }

    addListener (listener: IRoomSubscriber) {
        if (listener && !this.listeners.includes(listener)){
            this.listeners.push(listener);
        }
        return this;
    }

    removeListener (listener: IRoomSubscriber) {
        this.listeners = this.listeners.filter((item: IRoomSubscriber) => item !== listener);
        if (this.listeners.length === 0 ){
            this._close();
        }
    }
}

export interface IRoomSubscriber{
    updateModelByObj?: (message: TMessage) => void;
    updateNewSocketModelByObj?: (message: TMessage) => void;
}

export interface IEmitCloseProvider {
    emit: (message: TMessage) => void;
    closeRoom: () => void;
}
