import { getPushNotificationSettingsInitial } from '../constants';
import { IPushNotificationSettings } from '../types';
import { AT_UPDATE, TPushNotificationSettingsAction } from '../actions/types';

const initialState: IPushNotificationSettings = getPushNotificationSettingsInitial();

export const pushNotificationSettingsReducer = (
    state = initialState,
    action: TPushNotificationSettingsAction
): IPushNotificationSettings => {
    switch (action.type) {
        case AT_UPDATE: {
            return {
                ...state,
                ...action.pushNotification
            };
        }

        default:
            return state;
    }
};
