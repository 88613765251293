import { IModelState, TBoardId } from '../../../types/types';
import { TListsAction } from '../lists/actions/types';
import { TCardsAction } from '../cards/actions/types';
import { TDependenciesAction } from '../dependencies/dependencies/actions/types';
import { TBoardsAction } from '../boards/actions/types';
import { TDriveDocsAction } from '../driveDocs/actions/types';
import { TAuthUserAction } from '../authUser/actions/types';
import { TChecklistsAction } from '../checklists/checklists/actions/types';
import { TNotificationsSettingsAction } from '../notificationsSettings/actions/types';
import { TPushNotificationsSettingsAction } from '../pushNotificationsSettings/actions/types';
import { TLibThemesAction } from '../libThemes/actions/types';
import { TBackupsAction } from '../backups/actions/types';
import { TCardAssigneesAction } from '../cardAssignees/actions/types';
import { TTabsModelAction } from '../tabs/actions/types';
import { ICardWithBoard } from '../card/types';
import { IUserBoardActivityTimesAction } from '../boardUserActivityTimes/actions/types';

export const MODEL_SET = 'ROOT_STORE_MODEL_SET';
export const MODEL_UPDATE = 'ROOT_STORE_MODEL_UPDATE';
export const CARDS_ACTION_SET = 'ROOT_STORE_MODEL_CARDS_ACTION_SET';
export const LISTS_ACTION_SET = 'ROOT_STORE_MODEL_LISTS_ACTION_SET';
export const BOARDS_ACTION_SET = 'ROOT_STORE_MODEL_BOARDS_ACTION_SET';
export const DEPENDENCIES_ACTION_SET = 'ROOT_STORE_MODEL_DEPENDENCIES_ACTION_SET';
export const DRIVE_DOCS_ACTION_SET = 'ROOT_STORE_MODEL_DRIVE_DOCS_SET';
export const AT_AUTH_USER_ACTION_SET = 'ROOT_STORE_MODEL_AUTH_USER_ACTION_SET';
export const CHECKLISTS_ACTION_SET = 'ROOT_STORE_MODEL_CHECKLISTS_ACTION_SET';
export const NOTIFICATIONS_SETTINGS_ACTION_SET = 'ROOT_STORE_MODEL_NOTIFICATIONS_SETTINGS_ACTION_SET';
export const PUSH_NOTIFICATIONS_SETTINGS_ACTION_SET = 'ROOT_STORE_MODEL_PUSH_NOTIFICATIONS_SETTINGS_ACTION_SET';
export const CARD_AGING_ACTION_SET = 'ROOT_STORE_MODEL_CARD_AGING_ACTION_SET';
export const THEMES_ACTION_SET = 'ROOT_STORE_MODEL_THEMES_ACTION_SET';
export const BOARD_THEMES_ACTION_SET = 'ROOT_STORE_MODEL_BOARD_THEMES_ACTION_SET';
export const BACKUPS_ACTION_SET = 'ROOT_STORE_MODEL_BACKUPS_ACTION_SET';
export const CARD_ASSIGNEES_ACTION_SET = 'ROOT_STORE_MODEL_CARD_ASSIGNEES_ACTION_SET';
export const ACTIVE_BOARD_ID_ACTION_SET = 'ROOT_STORE_MODEL_ACTIVE_BOARD_ID_ACTION_SET';
export const TABS_ACTION_SET = 'ROOT_STORE_MODEL_TABS_ACTION_SET';
export const AT_IN_WORK_CARD_ACTION_SET = 'ROOT_STORE_MODEL_IN_WORK_CARD_ACTION_SETT';
export const BOARD_USER_ACTIVITY_TIMES_ACTION_SET = 'ROOT_STORE_MODEL_BOARD_USER_ACTIVITY_TIMES_ACTION_SET';

export interface IModelAction {
    type: typeof MODEL_SET;
    model: IModelState;
}

export type TModelUpdate = Partial<Pick<IModelState, Extract<keyof IModelState,
    'dashboards'|'lists'|'cards'|'driveDocs'|'dependencies'
    >>>;

export interface IModelUpdateAction {
    type: typeof MODEL_UPDATE;
    model: TModelUpdate;
}

export interface ICardsActionSetAction {
    type: typeof CARDS_ACTION_SET
    cardsAction: TCardsAction
}

export interface IListsActionSetAction {
    type: typeof LISTS_ACTION_SET
    listsAction: TListsAction
}

export interface IBoardsActionSetAction {
    type: typeof BOARDS_ACTION_SET
    boardsAction: TBoardsAction
}

export interface IDependenciesActionSetAction {
    type: typeof DEPENDENCIES_ACTION_SET
    dependenciesAction: TDependenciesAction
}

export interface IAuthUserActionSetAction {
    type: typeof AT_AUTH_USER_ACTION_SET
    authUserAction: TAuthUserAction
}

export interface IDriveDocsActionSetAction {
    type: typeof DRIVE_DOCS_ACTION_SET
    driveDocsAction: TDriveDocsAction
}

export interface IChecklistsActionSetAction {
    type: typeof CHECKLISTS_ACTION_SET
    checklistsAction: TChecklistsAction
}

export interface INotificationsSettingsActionSetAction {
    type: typeof NOTIFICATIONS_SETTINGS_ACTION_SET
    notificationsAction: TNotificationsSettingsAction
}

export interface IPushNotificationsSettingsActionSetAction {
    type: typeof PUSH_NOTIFICATIONS_SETTINGS_ACTION_SET
    pushNotificationsAction: TPushNotificationsSettingsAction
}

export interface IThemesActionSetAction {
    type: typeof THEMES_ACTION_SET
    themesAction: TLibThemesAction
}

export interface IBackupsActionSetAction {
    type: typeof BACKUPS_ACTION_SET
    backupsAction: TBackupsAction
}

export interface ICardAssigneesActionSetAction {
    type: typeof CARD_ASSIGNEES_ACTION_SET;
    cardAssigneesAction: TCardAssigneesAction;
}

export interface IActiveBoardIdActionSetAction {
    type: typeof ACTIVE_BOARD_ID_ACTION_SET;
    activeBoardId: TBoardId;
}

export interface ITabsActionSetAction {
    type: typeof TABS_ACTION_SET;
    tabsAction: TTabsModelAction;
}

export interface IInWorkCardActionSetAction {
    type: typeof AT_IN_WORK_CARD_ACTION_SET
    inWorkCard: ICardWithBoard;
}

export interface IBoardUserActivityTimesActionSetAction {
    type: typeof BOARD_USER_ACTIVITY_TIMES_ACTION_SET;
    action: IUserBoardActivityTimesAction;
}

export type TModelAction = (
    IModelAction |
    IModelUpdateAction |
    ICardsActionSetAction |
    IListsActionSetAction |
    IDependenciesActionSetAction |
    IDriveDocsActionSetAction |
    IBoardsActionSetAction |
    IAuthUserActionSetAction |
    IChecklistsActionSetAction |
    INotificationsSettingsActionSetAction |
    IPushNotificationsSettingsActionSetAction |
    IThemesActionSetAction |
    IBackupsActionSetAction |
    ICardAssigneesActionSetAction |
    IActiveBoardIdActionSetAction |
    ITabsActionSetAction |
    IInWorkCardActionSetAction |
    IBoardUserActivityTimesActionSetAction
);
