import { deleteFilePermission } from 'app/helper/authorisation/microsoft/graph/api/deleteFilePermission';
import {Dispatch, ThunkAction} from '../../../../../../../types/actions';
import { IGetState, TBoardId } from '../../../../../../../types/types';
import { getBoardParentFolderInfo } from 'app/store/model/selectors/getBoardParentFolderInfo';
import { snackbarErrorDefault } from 'app/view/react_components/snackbarsStack';
import { SNACKBAR_ID_BOARD_ERROR } from 'app/view/react_components/snackbarsStack/effects/constants';

export const onDelete = (
    boardId: TBoardId,
    permId: string,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        const parentFolderInfo = getBoardParentFolderInfo(getState(), boardId);
        if (!parentFolderInfo) return;

        return deleteFilePermission(parentFolderInfo.driveId, parentFolderInfo.folderId, permId)
            .catch((error) => {
                dispatch(snackbarErrorDefault({
                    id: SNACKBAR_ID_BOARD_ERROR,
                    text: error && error.message,
                }));
            });
    };
    return action;
};
