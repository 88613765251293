import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../../../../../types/types';

export const onDidMount = (
    cardId: TCardId
): ThunkAction => {
    return (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
    };
};
