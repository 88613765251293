import { IApplicationState, TCardId, TNBDId } from '../../../types/types';
import { INotificationBeforeDueDate } from '../notificationBeforeDueDate/types';

export const getCardNotificationBeforeDueDate = (
    state: IApplicationState,
    cardId: TCardId,
    notificationId: TNBDId,
): INotificationBeforeDueDate => {
    const card = state.model.cards[cardId];
    const nbd =
        card &&
        card.notificationBeforeDueDates &&
        card.notificationBeforeDueDates.find(item => item.id === notificationId);
    return nbd;
}
