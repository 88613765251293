import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState, TCardId, TChecklistId } from '../../../../types/types';
import { getCardPinnedChecklists } from '../../../../store/model/selectors/getCardPinnedChecklists';
import { cardUpdatePinnedSection } from './cardUpdatePinnedSection';

export const cardTogglePinnedChecklist = (
    cardId: TCardId,
    checklistId: TChecklistId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const checklists = [...getCardPinnedChecklists(getState(), cardId)];
        const index = checklists.indexOf(checklistId);
        if (index < 0) {
            checklists.push(checklistId);
        } else {
            checklists.splice(index, 1);
        }

        return dispatch(cardUpdatePinnedSection(cardId, { checklists }));
    };
    return action;
};
