import * as React from 'react';
import {
    CLASS_ACTIVITY_ITEM,
    CLASS_ACTIVITY_ITEM_TEMP
} from '../../../../boardDetails/activity/TabActivity/components/constants';
import './_CardActivityRelated.scss';
import { IActivityProps } from '../../../../boardDetails/activity/TabActivity/types';
import { TabActivityContext } from '../../../../boardDetails/activity/TabActivity/components/TabActivity/constants';
import { TActivity } from '../../../../../../../store/activities/types';
import { IRestCardRelatedActivity } from 'app/types/rest/activity/IRestCardRelatedActivity';
import { root } from 'app/store/constants';
import { LABEL_BOARD_NAME } from './constants';
import { escape } from 'underscore';
import {
    ActivityItemBody
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemBody/ActivityItemBody';
import { ActivityHeader } from '../../../../boardDetails/activity/TabActivity/components/ActivityHeader/ActivityHeader';
import {
    ActivityItemName
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemName/ActivityItemName';
import {
    ActivityItemMore
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemMore/ActivityItemMore';
import { GOOGLE_SPACING } from '../../../../../../../const';
import { IActivityRelatedCard } from './types';

export function CardActivityRelated({
    activity,
    asidePanelWidth
}: IActivityProps<IRestCardRelatedActivity>) {
    const className = CLASS_ACTIVITY_ITEM + '__related';

    const { onOpenCard } = React.useContext(TabActivityContext);
    const { label, card } = activity as TActivity;
    const { relatedCards } = activity;

    const [isNeedToCalculate, setNeedToCalculate] = React.useState(true);
    const [visibleElements, setVisibleElements] = React.useState([]);
    const [tooltip, setTooltip] = React.useState(null);

    const ref = React.useRef(null);
    const tempRef = React.useRef(null);

    React.useEffect(() => {
        setNeedToCalculate(true);
    }, [relatedCards, asidePanelWidth]);

    React.useEffect(() => {
        if (!isNeedToCalculate) return;

        const newElements: IActivityRelatedCard[] = [];

        const container = ref.current as HTMLElement;
        const tempContainer = tempRef.current as HTMLElement;
        if (!container || !tempContainer) return;

        const maxWidth = container.offsetWidth - GOOGLE_SPACING * 2 // место для плашки с плюсом
        let currentWidth = 0;

        for (let i = 0; i < relatedCards.length; i++) {
            const cardEl = tempContainer.children[i] as HTMLElement;
            if (!cardEl) return;

            const minWidth = Math.min(cardEl.offsetWidth, GOOGLE_SPACING * 20)
            const cardWidth = minWidth + GOOGLE_SPACING; // + gap
            currentWidth += cardWidth;
            if (currentWidth >= maxWidth) {
                break;
            }
            newElements.push({ ...relatedCards[i], minWidth });
        }

        setVisibleElements(newElements);

        const dif = relatedCards.length - newElements.length;
        if (dif) {
            setTooltip(
                relatedCards.slice(newElements.length).reduce((tooltip, card) => {
                    tooltip += '\n' + card.cardName;
                    return tooltip;
                }, '')
            );
        }

        setNeedToCalculate(false);
    }, [isNeedToCalculate, relatedCards, asidePanelWidth]);

    return (
        <ActivityItemBody
            header={<ActivityHeader icon={'card'} label={label} />}
            activity={activity}
            onClick={() => onOpenCard(card.id, activity.id, 'related')}
        >
            {relatedCards && !!relatedCards.length &&
                <>
                    <div className={className} ref={ref}>
                        {visibleElements.map(({ cardName, dashboardId, cardId, dashboardName, minWidth }) => (
                            <ActivityItemName
                                name={cardName}
                                onClick={e => {
                                    e.stopPropagation();
                                    root.App.controller.cleverOpenCard(dashboardId, cardId);
                                }}
                                tooltip={`${LABEL_BOARD_NAME}: ${escape(dashboardName)}`}
                                style={{ minWidth }}
                            />
                        ))}
                        {visibleElements.length !== relatedCards.length && <ActivityItemMore count={relatedCards.length - visibleElements.length} tooltip={tooltip} />}
                    </div>
                    {isNeedToCalculate &&
                        <div
                            className={`${className} ${CLASS_ACTIVITY_ITEM_TEMP}`}
                            ref={tempRef}
                        >
                            {relatedCards.map(card => (
                                <ActivityItemName name={card.cardName} />
                            ))}
                        </div>
                    }
                </>
            }
        </ActivityItemBody>
    );
};
