import { IApplicationState } from '../../../../types/types';
import { getAuthUser } from './getAuthUser';

export const getHaveSharedBoardWith2Other = (
    state: IApplicationState
): boolean => {
    const user = getAuthUser(state);
    if (!user.meta) return false;

    return Boolean(user.meta.haveSharedBoardWith2Other);
};
