import { IBoard, IBoardTheme } from '../types';
import { ICardAssigneeRole, IColor } from '../../../../types/model';
import { TCardAssigneeRoleId, TColorId } from '../../../../types/types';
import { IUserBoardPermissionsBackboneModel } from '../../../../types/backbone/IUserBoardPermissionsBackboneModel';

export const AT_UPDATE = 'BOARD_UPDATE';
export const AT_COLOR_UPDATE = 'BOARD_COLOR_UPDATE';
export const AT_COLOR_ADD = 'BOARD_COLOR_TAG_ADD';
export const AT_COLOR_DELETE = 'BOARD_COLOR_TAG_DELETE';
export const AT_ROLE_UPDATE = 'BOARD_ROLE_UPDATE';
export const AT_ROLE_ADD = 'BOARD_ROLE_ADD';
export const AT_THEME_UPDATE = 'BOARD_THEME_UPDATE';
export const AT_AUTH_PERMISSIONS_UPDATE = 'BOARD_AUTH_PERMISSIONS_UPDATE';

export interface IUpdateAction {
    type: typeof AT_UPDATE;
    board: IBoard;
}

export interface IColorUpdateAction {
    type: typeof AT_COLOR_UPDATE;
    colorId: TColorId;
    color: IColor;
}

export interface IColorAddAction {
    type: typeof AT_COLOR_ADD;
    color: IColor;
}

export interface IColorDeleteAction {
    type: typeof AT_COLOR_DELETE;
    colorId: TColorId;
}

export interface IRoleUpdateAction {
    type: typeof AT_ROLE_UPDATE;
    roleId: TCardAssigneeRoleId;
    role: ICardAssigneeRole;
}

export interface IRoleAddAction {
    type: typeof AT_ROLE_ADD;
    role: ICardAssigneeRole;
}

export interface IThemeUpdateAction {
    type: typeof AT_THEME_UPDATE;
    theme: IBoardTheme;
}

export interface IAuthPermissionsUpdateAction {
    type: typeof AT_AUTH_PERMISSIONS_UPDATE;
    authPermissions: IUserBoardPermissionsBackboneModel;
}

export type TBoardAction = (
    IUpdateAction |
    IColorUpdateAction |
    IRoleUpdateAction |
    IRoleAddAction |
    IThemeUpdateAction |
    IAuthPermissionsUpdateAction |
    IColorAddAction |
    IColorDeleteAction
);
