import { deleteRelation } from '../../../../../../../rest/effects/card/relatedCards/deleteRelation';
import { IGetState } from '../../../../../../../types/types';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../middlewares/segment/index';
import { IRestRelatedCard } from '../../../../../../../types/rest/IRestRelatedCard';
import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { getActiveCardId } from '../../../../../../../store/router/selectors/getActiveCardId';

export const onDelete = (
    relatedCard: IRestRelatedCard
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const cardId = getActiveCardId(getState());
        dispatch(segmentTrackAction(SegmentCardEvent.RELATED_CARD_REMOVE));
        dispatch(deleteRelation(cardId, relatedCard));
    };
    return action;
};
