import { EMPTY_ARRAY, IApplicationState, TCardId } from '../../../../../../../types/types';
import { TStatus } from '../../../../../../../types/model';
import { INotificationBeforeDueDate } from '../../../../../../../store/model/notificationBeforeDueDate/types';
import { getNotificationBeforeDueDateEditsSelector } from './getNotificationBeforeDueDateEditsSelector';

export const getEditedNotificationsByCardId = (
    state: IApplicationState,
    cardId: TCardId,
    status: TStatus = TStatus.STATUS_ACTIVE,
): INotificationBeforeDueDate[] => {
    const notificationsEdits = getNotificationBeforeDueDateEditsSelector(state, cardId);
    if (
        !notificationsEdits ||
        !notificationsEdits.notifications ||
        !notificationsEdits.notifications.length
    ) return EMPTY_ARRAY;

    return notificationsEdits.notifications.filter(notification => notification.status === status);
};
