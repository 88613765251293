import {
    SegmentCardEvent, SegmentCardOptions, SegmentCardSection,
    SegmentCardSourceValue
} from '../../../../../../../../middlewares/segment/trackEntities/cardEvents';
import { cardArchiveGeneral } from 'app/store/model/effects/cardArchiveGeneral';
import { segmentTrackAction } from 'app/middlewares/segment';
import { cardArchive } from 'app/store/model/effects/cardArchive';
import { disallowedSectionClickedEvent } from 'app/view/react_components/aside_panel/cardDetails/effects/segmentEvents';
import { TBoardId, TCardId } from 'app/types/types';
import { Dispatch, ThunkAction } from 'app/types/actions';

export const onCardArchive = (
    boardId: TBoardId,
    cardId: TCardId,
): ThunkAction => {
    return (
        dispatch: Dispatch,
    ) => {

        dispatch(segmentTrackAction(SegmentCardEvent.ARCHIVE_CARD, {
            name: SegmentCardOptions.SOURCE,
            value: SegmentCardSourceValue.DROPDOWN,
        }));

        dispatch(cardArchiveGeneral(boardId, cardId)).then(
            (result: boolean) => {
            dispatch(segmentTrackAction(result ? SegmentCardEvent.ARCHIVE_WITH_SUBCARDS_YES :
                SegmentCardEvent.ARCHIVE_WITH_SUBCARDS_NO, {
                name: SegmentCardOptions.SOURCE,
                value: SegmentCardSourceValue.DROPDOWN
            }));
            dispatch(cardArchive(boardId, cardId, result));
        }, () => {
                dispatch(disallowedSectionClickedEvent(SegmentCardSection.ARCHIVE, SegmentCardSourceValue.DROPDOWN));
            });
    };
};
