import { IApplicationState, TBoardId, TCardId } from '../../../../../../types/types';
import { ICard } from '../../../../../../store/model/card/types';
import { getBoardCards } from '../../../../../../store/model/selectors/getBoardCards';
import { TStatus } from '../../../../../../types/model';
import { createDeepEqualSelector } from '../../../../helpers/memoizeHelper';

type TgetBoardCardTemplateIds = {
    boardId: TBoardId;
    isRecurring: boolean;
}

export const getBoardCardTemplateIds = createDeepEqualSelector([
    (state: IApplicationState, {boardId, isRecurring}: TgetBoardCardTemplateIds) => getBoardCards(state, boardId, TStatus.STATUS_SERVICE_TEMPLATE),
    (state: IApplicationState, {boardId, isRecurring}: TgetBoardCardTemplateIds) => isRecurring
], (cards: ICard[], isRecurring: boolean) => {
    cards.sort((a, b) => {
        const orderNumberA = a.orderNumber === undefined ? a.created : a.orderNumber;
        const orderNumberB = b.orderNumber === undefined ? b.created : b.orderNumber;
        return orderNumberA - orderNumberB;
    });
    return cards.reduce((sum: TCardId[], card: ICard) => {
        if (isRecurring && card.recurringTasks.length) {
            sum.push(card.id);
        } else if (!isRecurring && card.recurringTasks.length === 0){
            sum.push(card.id);
        }
        return sum;
    }, [] as TCardId[]);
});

export const getBoardCardTemplateIdsSelector = createDeepEqualSelector(
    getBoardCardTemplateIds,
    ids => ids
);
