import { Icon } from 'kui';
import * as React from 'react';
import { IHideButton } from './types';
import './_HideButton.scss';

export function HideButton ({
    isHide,
    labels,
    onClick,
}: IHideButton) {
    const className = 'hide-button';

    return (
        <div
            className={className}
            onClick={onClick}
        >
            <span className={className + '-text'}>
                {isHide ? labels[0]: labels[1]}
            </span>
            <Icon
                className={className + '-icon'}
                size={16}
                xlink={isHide ? 'arrow-s-down': 'arrow-s-up'}
            />
        </div>
    );
}
