import { QUALIFICATION_QUESTIONS } from '../constants/questions';

export const getQuestionAnswerChildAnswerKeys = (
    questionKey: string,
    parentAnswerKey: string
): string[] => {
    const question = QUALIFICATION_QUESTIONS[questionKey];
    const answerKeys = Object.keys(question.answers);
    const result: string[] = [];
    answerKeys.forEach(answerKey => {
        if (question.answers[answerKey].parent && question.answers[answerKey].parent === parentAnswerKey) {
            result.push(answerKey);
        }
    })
    return result;
}
