import { IGetState, TCardId } from '../../../types/types';
import { ICard } from '../../../store/model/card/types';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { getList } from '../../../store/model/list/selectors/getList';
import { getBoard } from '../../../store/model/selectors/getBoardById';
import { sendCardStatWithIncrement, sendUserProductivityStatsTS } from '../../../helper/comet/stat_helper_ts';
import { EUserProductivityEventStatus } from '../../../view/react_components/reports/UserProductivity/UserProductivityReport/rest/types';
import { getCard } from 'app/store/model/selectors/getCard';

export function onDeleteCard(cardId: TCardId, updatedCard: ICard): ThunkAction {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const card = getCard(state, cardId) || {};
        const cardMerged = {...card, ...updatedCard };
        const list = getList(state, cardMerged.listId);
        const board = getBoard(state, list.dashboardId);
        if (cardMerged.processingStatus === 'done') {
            dispatch(sendCardStatWithIncrement(list, -1, -1));
        } else {
            dispatch(sendCardStatWithIncrement(list, -1, 0));
        }
        sendUserProductivityStatsTS(board, cardMerged, list, EUserProductivityEventStatus.CARD_DELETED);
    };
    return action;
}
