import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { INotifyButtonHOCProps } from './types';
import {
    INotifyButtonEvents,
    INotifyButtonFields
} from '../../components/NotifyButton/types';
import { NotifyButton } from '../../components/NotifyButton/NotifyButton';
import { onClick } from './events/onClick';
import { getAsidePanelActivityId } from '../../../../asidePanel/store/selectors/getAsidePanelActivityId';
import { getBoardUsers } from '../../../../../../../store/model/selectors/getBoardUsers';

const mapStateToProps = (
    state: IApplicationState,
    { boardId, activityId }: INotifyButtonHOCProps
): INotifyButtonFields => {
    let isNotActivity = false;
    let isShow = false; //hide button KNB-3568
    if (!activityId) {
        activityId = getAsidePanelActivityId(state);
        isNotActivity = true;
    }
    //hide button KNB-3568
    // if (activityId) {
    //     const users = getBoardUsers(state, boardId);
    //     if (users.length < 2) isShow = true; // hide button if 1 user on board
    // } else {
    //     isShow = false;
    // }

    return {
        activityId,
        isNotActivity,
        isShow,
    }
};

const mapDispatchToProps = (
    dispatch: any,
    {boardId, cardId, activityId, onClick: ownOnClick}: INotifyButtonHOCProps
): INotifyButtonEvents => {
    return {
        onClick: () => dispatch(onClick(activityId, ownOnClick))
    }
};

export const NotifyButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(NotifyButton);

NotifyButtonHOC.displayName = 'NotifyButtonHOC';
