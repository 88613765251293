import { IApplicationState } from '../../../../types/types'
import { ICollaborator } from '../../collaborators/types';

export const getBoardCollaborators = (
    state: IApplicationState,
    boardId: number,
): ICollaborator[] => {
    const boardCollaborators = state.modelsCollaborators.boards[boardId];
    return boardCollaborators || [];
};
