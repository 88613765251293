import { getMessages } from 'app/store/constants';

export const ATTACH_FILE_FROM = getMessages().getText('card_form_attachment_attach_file');
export const GOOGLE_DRIVE = getMessages().getText('card_form_attachment_drive');
export const YOUR_DEVICE = getMessages().getText('card_form_attachment_device');
export const CREATE_AND_ATTACH = getMessages().getText('card_form_attachment_create');
export const GOOGLE_DOCS = getMessages().getText('card_form_attachment_docs');
export const GOOGLE_SHEETS = getMessages().getText('card_form_attachment_sheets');
export const GOOGLE_SLIDES = getMessages().getText('card_form_attachment_slides');
export const GOOGLE_FORMS = getMessages().getText('card_form_attachment_forms');

export const CARD_ATTACHMENT_GOOGLE_FILES_TOOLTIP = getMessages().getText('card_form_attachment_google_files_tooltip');

export enum EFileType {
    DOCS = 'document',
    SHEETS = 'spreadsheet',
    SLIDES = 'presentation',
    FORMS = 'form',
}
