import { Dispatch, ThunkAction } from '../../../types/actions';
import { IGetState } from '../../../types/types';
import { getAuthUser } from '../../../store/model/authUser/selectors/getAuthUser';
import { IAuthUser } from '../../../store/model/authUser/types';
import { authUserPatch } from './api/helper/authUserPatch';
import { IOneTimePoll } from '../../../types/rest/IUserMeta';

export const authUserSetPricingPoll = (
    poll: IOneTimePoll
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const authUser = getAuthUser(getState());
        const currentPoll = authUser.meta && authUser.meta.pricingPoll || {};
        const userPatch: IAuthUser = {
            id: authUser.id,
            meta: {
                pricingPoll: { ...currentPoll, ...poll }
            }
        };
        return dispatch(authUserPatch(userPatch));
    };
    return action;
};
