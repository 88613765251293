import { getMessages } from '../../../../../../store/constants';

export const TOPBAR_FILTER_PLACEHOLDER = getMessages().getText('common.search');
export const TOPBAR_FILTER_MOBILE = getMessages().getText('top_bar.filter.mobile');
export const TOPBAR_FILTER_TOOLTIP = getMessages().getText('top_bar.filter.tooltip');
export const TOP_BAR_FILTER_SECTIONS = [
    {field: 'text', name: 'Titles'},
    {field: 'description', name: 'Descriptions'},
    {field: 'comments', name: 'Comments'}
];
