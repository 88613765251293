import { IGetState, TCardId, TListId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { SegmentCardEvent, SegmentCardOptions, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { SegmentCardOptionLocationValue } from '../../../../../../../../middlewares/segment/trackEntities/cardEvents';
import { IBoard } from '../../../../../../../../store/model/board/types';
import { cardsMoveOnCurrentBoard } from '../../../../../../../../rest/effects/card/card/move/cardsMoveOnCurrentBoard';
import { MAKE_FIRST_ITEM_INDEX } from '../../../../../../base/components/MoveTo/components/constants';

export const cardMoveOnCurrentBoard = (
    currentCardId: TCardId,
    board: IBoard,
    targetListId: TListId,
    cardIndex: number,
    onClose: () => void
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.MOVE_CARD_ON_BOARD_CHOSEN));
        if (cardIndex === MAKE_FIRST_ITEM_INDEX) {
            dispatch(segmentTrackAction(SegmentCardEvent.MOVE_TO_MAKE_FIRST_CHOSEN));
        }
        return dispatch(cardsMoveOnCurrentBoard([currentCardId], board, targetListId, cardIndex))
            .then(() => {
                dispatch(segmentTrackAction(SegmentCardEvent.MOVE_TO_CARD_MOVED,
                    {
                        name: SegmentCardOptions.LOCATION,
                        value: SegmentCardOptionLocationValue.CURRENT
                    }
                ))
                if (onClose) onClose();
            })
    };
    return action;
};
