import { IApplicationState } from '../../../types/types';
import { IUserMetaMyWork } from '../../../types/rest/IUserMeta';
import { createDeepEqualSelector } from '../../../view/react_components/helpers/memoizeHelper';
import { getAuthUser } from '../authUser/selectors/getAuthUser';

const getAuthUserMeta = (
    state: IApplicationState
): IUserMetaMyWork => {
    const authUser = getAuthUser(state);
    return authUser && authUser.meta && authUser.meta.myWork;
}

export const getAuthUserMetaMyWork = createDeepEqualSelector(
    getAuthUserMeta,
    (meta: IUserMetaMyWork) => {
        return {
            ...defaultAuthUserMetaMyWork,
            ...meta
        }
    }
)

const defaultAuthUserMetaMyWork: IUserMetaMyWork = {
    sortType: null
}
