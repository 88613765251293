import { root } from 'app/store/constants';
import { Dispatch, ThunkAction } from 'app/types/actions';

export function onClickMarketplace(
): ThunkAction {
    const action = (
        dispatch: Dispatch
    ) => {
        root.App.statHelper.sendUserStat({
            [root.App.Classes.StatHelper.RATE_COUNT]: '+1',
            [root.App.Classes.StatHelper.RATE_DATE]: root.App.Util.nowTrimTimestampGMT(),
        });
    };
    return action
};
