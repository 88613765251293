'use strict';

App.Views.CardUnarchiveNotification = App.Views.BaseNotification.extend({

    getActions: function() {
        return {
            action: this.notificationModel.oldValue == 1
                ? Messages.getText('noty.card_restore')
                : Messages.getText('noty.card_unarchive')
        };
    }
});
