import { IGetState, TBoardId } from '../../../types/types';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { boardUpdateMeta } from './boardUpdateMeta';
import { getBoardCardProperties } from '../../../store/model/board/selectors/getBoardCardProperties';
import { ICardProperty } from '../../../types/rest/IRestBoardMeta';
import { EAsidePanelProperty } from '../../../view/react_components/aside_panel/asidePanel/components/AsidePanel/types';

export const boardSetCardProperties = (
    boardId: TBoardId,
    cardProperty: ICardProperty,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        let cardProperties = [...getBoardCardProperties(getState(), boardId)];
        const { isDefault } = cardProperty;
        if (cardProperty.property === EAsidePanelProperty.CARD_DUE_DATE) {
            const startDateProperty = cardProperties
                .find(({ property }) => property === EAsidePanelProperty.CARD_START_DATE);
            cardProperties = addOrUpdateCardProperties(cardProperties, {
                property: EAsidePanelProperty.CARD_START_DATE,
                isDefault,
                isRequired: startDateProperty && startDateProperty.isRequired
            });
        }
        if (cardProperty.property === EAsidePanelProperty.CARD_START_DATE) {
            const dueDateProperty = cardProperties
                .find(({ property }) => property === EAsidePanelProperty.CARD_DUE_DATE);
            cardProperties = addOrUpdateCardProperties(cardProperties, {
                property: EAsidePanelProperty.CARD_DUE_DATE,
                isDefault,
                isRequired: dueDateProperty && dueDateProperty.isRequired
            });
        }
        cardProperties = addOrUpdateCardProperties(cardProperties, cardProperty)
            .filter(({ isDefault, options }) => {
                return isDefault || options && options.length > 0;
            });
        return dispatch(boardUpdateMeta(boardId, { cardProperties }));
    };
    return action;
}

const addOrUpdateCardProperties = (
    cardProperties: ICardProperty[],
    cardProperty: ICardProperty
) => {
    const idx = cardProperties.findIndex(({ property }) => property === cardProperty.property);
    return idx > -1 ? [
        ...cardProperties.slice(0, idx),
        cardProperty,
        ...cardProperties.slice(idx + 1)
    ] : [...cardProperties, cardProperty];
}
