import * as React from 'react';
import { IRestCardAssignUserActivity } from '../../../../../../../types/rest/activity/IRestCardAssignUserActivity';
import { IActivityProps } from '../../../../boardDetails/activity/TabActivity/types';
import { TabActivityContext } from '../../../../boardDetails/activity/TabActivity/components/TabActivity/constants';
import { TActivity } from '../../../../../../../store/activities/types';
import { ERestActivityPropertyActionType } from '../../../../../../../types/rest/activity/IRestActivityBase';
import { CARD_ASSIGNER_CARD_ADDED, CARD_ASSIGNER_CARD_REMOVED } from './constants';
import { UsersTooltip } from '../../../../../base/components/UsersTooltip/UsersTooltip';
import {
    ActivityItemName
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemName/ActivityItemName';
import { ActivityHeader } from '../../../../boardDetails/activity/TabActivity/components/ActivityHeader/ActivityHeader';
import {
    ActivityItemBody
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemBody/ActivityItemBody';

export function CardActivityAssignedTo ({
    activity,
    userId,
}: IActivityProps<IRestCardAssignUserActivity>) {
    const { onOpenCard } = React.useContext(TabActivityContext);
    const { label } = activity as TActivity;
    const isSingle = activity.users && !!activity.users.length && activity.users.length === 1;
    return (
        <ActivityItemBody
            activity={activity}
            onClick={() => onOpenCard(activity.card.id, activity.id, 'assignees')}
            header={<ActivityHeader
                label={label}
                content={<>
                    {(!isSingle || activity.users[0].permissionId !== userId) &&
                        <>
                            <UsersTooltip
                                users={activity.users.filter(user => user.permissionId !== userId).map(user => {
                                    return { sharedUser: user };
                                })}
                                isNoEvents={true}
                            />
                            {activity.propertyActionType === ERestActivityPropertyActionType.ADD ?
                                CARD_ASSIGNER_CARD_ADDED : CARD_ASSIGNER_CARD_REMOVED}
                        </>
                    }
                </>}
            />}
        >
            {isSingle && activity.users[0].permissionId === userId &&
                <ActivityItemName name={activity.card.name} />
            }
        </ActivityItemBody>
    );
}
