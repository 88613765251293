import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState, TCardId } from '../../../../types/types';
import { getCard } from '../../../../store/model/selectors/getCard';
import { ICardBatch } from './api/types';
import { cardPatchRestBatch } from './api/helper/cardPatchRestBatch';
import { ICards } from '../../../../store/model/cards/types';
import { getCardEpicsForDueDateChange } from '../../../../store/model/selectors/getCardEpicsForDueDateChange';
import { getCardEpicsForStartDateChange } from '../../../../store/model/selectors/getCardEpicsForStartDateChange';
import { cardsRestPatch } from './api/helper/cardsRestPatch';
import { getCardEpicsForProcessingPercentChange } from '../../../../store/model/selectors/getCardEpicsForProcessingPercentChange';
import { getCardEpicsForPinnedSectionsChange } from 'app/store/model/selectors/getCardEpicsForPinnedSectionsChange';

export const cardDeleteEpicBatch = (
    getState: IGetState,
    dispatch: Dispatch,
    cardId: TCardId,
    cardBatch: ICardBatch = {card: {}, callbacks: []},
    updateEpic: boolean = true
) => {
    const state = getState();
    const card = getCard(state, cardId);
    if (!card) return cardBatch;

    if (!card.epicId) return cardBatch;

    const epicId = card.epicId;

    cardBatch.card = {
        ...cardBatch.card,
        epicId: null as number,
    }

    if (updateEpic) {
        cardBatch.callbacks.push(() => {
            const state = getState();
            let cards: ICards = {};
            cards = getCardEpicsForDueDateChange(state, epicId, cards);
            cards = getCardEpicsForStartDateChange(state, epicId, cards);
            cards = getCardEpicsForProcessingPercentChange(state, epicId, cards);
            cards = getCardEpicsForPinnedSectionsChange(state, epicId, cards)
            return dispatch(cardsRestPatch(cards));
        });
    }

    return cardBatch;
};

export const cardDeleteEpic = (
    cardId: TCardId,
    updateEpic: boolean = true
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const cardBatch = cardDeleteEpicBatch(getState, dispatch, cardId, undefined, updateEpic);
        return cardPatchRestBatch(cardId, dispatch, cardBatch);
    };
    return action;
};
