import { ICard } from '../../card/types';
import { ICardsComparator } from './types';
import { compareStrings } from './compareStrings';

const getCardValue = (
    card: ICard
): string => {
    const fullNames = card.assignees
        ? card.assignees.map(user => user.sharedUser.fullName)
        : [];
    return fullNames.sort().join(' ');
};

export const assignedTo: ICardsComparator = {
    getCardValue,
    sort: compareStrings
}
