import { getMessages } from '../../../../store/constants';

const Messages = getMessages();

export enum EFeedbackTitle {
    NORMAL_TITLE = Messages.getText('feedback.title.normal'),
    BUG_TITLE = Messages.getText('feedback.title.bug'),
    TRELLO_TITLE = Messages.getText('feedback.title.trello'),
    REQUEST_PROPERTY_TITLE = Messages.getText('feedback.title.requestProperty')
}

export enum EFeedbackCategory {
    DEFAULT = 'DEFAULT',
    REQUEST_PROPERTY = 'REQUEST_PROPERTY'
}

export enum EFeedbackSuccessTitle {
    NORMAL_TITLE = Messages.getText('feedback.title.success.normal'),
    BUG_TITLE = Messages.getText('feedback.title.success.bug')
}

export enum EFeedbackFormMode {
    MODAL = 'MODAL',
    INLINE = 'INLINE'
}

export const HP_HOST_URL = 'https://helpdesk.kanbanchi.com';
export const HP_SESSION_URL_PART = '/session';
export const HP_FILE_URL_PART = '/attachments';
export const HP_TICKET_URL_PART = '/ticket';

export const HP_DEFAULT_PRIORITY = 1;
export const HP_DEFAULT_STATUS = 2;
export const HP_DEFAULT_SUBJECT = 'Kanbanchi feedback [app]';

export const HP_CHANGE_FEEDBACK = 'HP_CHANGE_FEEDBACK';
export const HP_CHANGE_TITLE = 'HP_CHANGE_TITLE';
export const HP_CHANGE_SUCCESS_TITLE = 'HP_CHANGE_SUCCESS_TITLE';
export const HP_SET_SESSION_ID = 'HP_SET_SESSION_ID';
export const HP_SET_SENT = 'HP_SET_SENT';
export const HP_FILE_ADD = 'HP_FILE_ADD';
export const HP_FILE_REMOVE = 'HP_FILE_REMOVE';
export const HP_FILE_UPLOADED = 'HP_FILE_UPLOADED';
export const HP_SET_SENDING = 'HP_SET_SENDING';
export const HP_SET_TECH_DETAILS = 'HP_SET_TECH_DETAILS';
export const HP_SET_SUBMIT_BUTTON_STATUS = 'HP_SET_SUBMIT_BUTTON_STATUS';
export const HP_CLEAR = 'HP_CLEAR';
export const AT_HP_USER_EMAIL_SET = 'HP_USER_EMAIL_SET';
export const AT_HP_IS_USER_EMAIL_VALID_SET = 'HP_IS_USER_EMAIL_VALID_SET';
export const HP_SET_CATEGORY = 'HP_SET_CATEGORY';

export const USER_PERSONAL_ACCOUNT_TYPE = 1;
export const USER_DOMAIN_ACCOUNT_TYPE = 2;
export const USER_EDU_ACCOUNT_TYPE = 3;

export const HP_USER_ACCOUNT_TYPE_TAG_DOMAIN = 'domain';
export const HP_USER_ACCOUNT_TYPE_TAG_EDU = 'edu';
export const HP_USER_ACCOUNT_TYPE_TAG_PERSONAL = 'personal';

export const HP_USER_PLAN_FREE = 'free';
export const HP_USER_PLAN_PRO = 'pro';
export const HP_USER_PLAN_ENTERPRISE = 'enterprise';
export const HP_USER_PLAN_PREMIUM = 'premium';
export const HP_USER_PLAN_OLD = 'old';

export const HP_PAID_DESCRIPTION = Messages.getText('feedback.description.paid');
export const HP_FREE_DESCRIPTION = Messages.getText('feedback.description.free');
export const HP_ERROR_DESCRIPTION = Messages.getText('feedback.description.error');
export const HP_ERROR_TOO_LARGE = Messages.getText('feedback.files.large');
export const HP_MESSAGE_LABEL = Messages.getText('feedback.message.label');
export const HP_WE_USSUALLY_RESPOND = Messages.getText('feedback.we_usually_respond');

export const HP_OVERFLOW_AFTER_FILES = 3;

export const HP_TOOLTIP_ON_INVALID_EMAIL = 'Please, use name@domain.com format';
export const HP_ERROR_EMAIL = 'error@kanbanchi.com';
export const HP_ERROR_FULL_NAME = 'Unknown Unknown';
export const HP_MICROSOFT_TAG = 'microsoft'

export const HTTP_PAYLOAD_LARGE_STATUS = 413;
export const KB = 1024;
export const MB = 1024 * KB;
