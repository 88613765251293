import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { ICardChecklistCopySearchSelectHOCProps } from './types';
import { getAvailableCardsCreateSelector } from './selectors/getAvailableCards';
import { ICardChecklistCopySearchSelectFields } from '../../components/CardChecklistCopySearchSelect/types';
import {
    CardChecklistCopySearchSelect
} from '../../components/CardChecklistCopySearchSelect/CardChecklistCopySearchSelect';

const mapStateToProps = () => {
    const getAvailableCards = getAvailableCardsCreateSelector();

    const _mapStateToProps = (
        state: IApplicationState,
        ownProps: ICardChecklistCopySearchSelectHOCProps
    ): ICardChecklistCopySearchSelectFields => {
        const { boardId, cardId, selectedCardId, isAutoFocus, isClose, isClearable } = ownProps;
        const availableElements = getAvailableCards(state, boardId, cardId);

        return {
            availableElements,
            isAutoFocus,
            isClearable,
            isClose,
            selectedCardId,
        };
    };
    return _mapStateToProps;
};

export const CardChecklistCopySearchSelectHOC = connect(
    mapStateToProps,
    null
)(CardChecklistCopySearchSelect);

CardChecklistCopySearchSelectHOC.displayName = 'CardChecklistCopySearchSelectHOC';
