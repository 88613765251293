import { Dispatch, ThunkAction } from '../../../../types/actions';
import { fetchHandler } from '../../../../util/fetchHandler';
import Util from '../../../../util/util';
import { getRestHeadersPost } from '../../../helpers/getRestHeadersHelper';
import { IRestUserWebPushToken } from '../../../../types/rest/IRestUserWebPushToken';

export const postRest = (
    url: string,
    request: IRestUserWebPushToken
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        return fetchHandler<string>(
            Util.getApiUrl(url), {
                ...getRestHeadersPost(),
                body: JSON.stringify(request)
            }
        ).then((response: string) => {
            return response;
        });
    };
    return action;
};
