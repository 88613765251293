import { Button, Icon } from 'kui';
import * as React from 'react';
import { CLASS_CARD_DETAILS } from '../../..';
import { getAsidePanelTooltip } from '../../../../asidePanel/helpers/getAsidePanelTooltip';
import { CARD_PROPERTIES_SETTINGS } from './constants';
import { ICardPropertiesPanelButtonProps } from './types';
import './_CardPropertiesPanelButton.scss';

export function CardPropertiesPanelButton ({
    onClick
}: ICardPropertiesPanelButtonProps) {
    const className = CLASS_CARD_DETAILS + '__properties-settings';

    return (
        <Button
            className={className}
            tooltip={getAsidePanelTooltip({
                direction: 'left',
                isNoWrap: true,
                value: CARD_PROPERTIES_SETTINGS,
            })}
            variant={'icon'}
            onClick={onClick}
        >
            <Icon size={24} xlink={'settings'} />
        </Button>
    );
}
