import { AsidePanelActionsButton } from '../../../../..';
import * as React from 'react';
import { ICardPropertiesPanelButtonProps } from './types';
import { CLASS_BOARD_DETAILS_SECTION } from '../../../../constants';
import { BOARD_SECTION_CARD_PROPERTIES_BUTTON } from '../../constants';

export function CardPropertiesPanelButton ({
    indicatorNumber,
    onClick
}: ICardPropertiesPanelButtonProps) {

    return (
        <AsidePanelActionsButton
            children={null}
            indicatorNumber={indicatorNumber}
            sectionSelector={`.${CLASS_BOARD_DETAILS_SECTION}--card-properties`}
            title={BOARD_SECTION_CARD_PROPERTIES_BUTTON}
            onClick={onClick}
        />
    );
}
