import { REST_NOTIFICATION_BEFORE_DUE_DATE_EDIT } from '../../../../constants';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { fetchHandler } from '../../../../../util/fetchHandler';
import Util from '../../../../../util/util';
import { getRestHeadersDelete } from '../../../../helpers/getRestHeadersHelper';
import { IGetState, TCardId, TNBDId } from '../../../../../types/types';
import { IRestNotificationBeforeDueDate } from '../../../../../types/rest/IRestNotificationBeforeDueDate';

export const deleteRest = (
    cardId: TCardId,
    notificationBeforeDueDateId: TNBDId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return fetchHandler<IRestNotificationBeforeDueDate>(
            Util.getApiUrl(REST_NOTIFICATION_BEFORE_DUE_DATE_EDIT + notificationBeforeDueDateId), {
                ...getRestHeadersDelete()
            }
        );
    };
    return action;
};
