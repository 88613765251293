import { IApplicationState, TBoardId } from '../../../../../../../types/types';
import { IBoardExportItem } from '../types';

export const getBoardExportItems = (
    state: IApplicationState,
    boardId: TBoardId
): IBoardExportItem[] => {
    const items = state.boardsExport && state.boardsExport[boardId] && state.boardsExport[boardId].items;
    return items || [];
};
