import { createRoot } from 'react-dom/client';
import { snackbarErrorDefault } from "../../react_components/snackbarsStack";
import { ESnackbarPosition } from "../../react_components/snackbarsStack/store/types";
import { checkIfRequiredPropertiesAreFilled } from "../helpers/checkIfRequiredPropertiesAreFilled";
import { SNACKBAR_CARD_NOT_FILLED_TEXT, SNACKBAR_ID_CARD_NOT_FILLED_ERROR } from "./constants";
import {
    getCurrentNotFilledCardId
} from "../../react_components/aside_panel/cardDetails/store/selectors/getCurrentNotFilledCardId";
import {
    currentNotFilledCardIdSetAction
} from "../../react_components/aside_panel/cardDetails/store/actions/currentNotFilledCardIdSetAction";
import { removeAsidePanelPortal } from '../../../helper/removeAsidePanelPortal';
import { cardsRequestsStateSetAction } from 'app/store/requestsState/actions/cardsRequestsStateSetAction';
import { getCardIsLoading } from 'app/store/requestsState/selectors/getCardIsLoading';
import { FAKE_CARD_ID } from 'app/const';
import { getActiveBoardId } from "app/store/model/selectors/getActiveBoardId";
import { getPanelReadonly } from "app/view/react_components/aside_panel/boardDetails/selectors/getPanelReadonly";
import { getCard } from "app/store/model/selectors/getCard";
import { TStatus } from "app/types/model";

export const CardDetailsBaseView = App.Views.BaseView.extend({

    initialize: function (options) {
        this.root = createRoot(this.el);
        this.store = options.store;
        this.parent = options.parent;
        this.cardId = options.cardId;
    },

    asideClassName: 'aside-panel__card',

    initStore: function() {
        return Promise.all([
            import(/* webpackChunkName: "cardDetails" */ 'app/view/react_components/aside_panel/cardDetails/TimingSection/notificationsBeforeDueDate/state/notificationBeforeDueDateEditsState/reducers/notificationBeforeDueDateEditsStateReducer'),
            import(/* webpackChunkName: "cardDetails" */ 'app/view/react_components/aside_panel/cardDetails/TimingSection/pushToGoogleCalendar/store/reducers/pushToGoogleCalendarReducer'),
        ])
        .then(([
            module1, module2, module3,
        ]) => {
            this.store.reducerManager.add('notificationBeforeDueDateEdits', module1.notificationBeforeDueDateEditsStateReducer);
            this.store.reducerManager.add('pushToGoogleCalendar', module2.pushToGoogleCalendarReducer);
            this.store.dispatch({type: 'async-init'}); // fake action to init new reducers
        })
        .catch(e => console.error(e))
    },

    render: function () {
        this.store.dispatch(cardsRequestsStateSetAction({ [this.cardId]: { isLoading: true } }));
        
        this.parent.$el.find('.aside-panel__hide').hide();
        this.reactRender();
        return this;
    },
    
    reactRender: function() {
    },

    remove: function () {
        if (getCardIsLoading(this.store.getState(), this.cardId)) {
            this.store.dispatch(cardsRequestsStateSetAction({ [this.cardId]: { isLoading: false } }));
        }

        this.parent.$el.find('.aside-panel__hide').show();
        const el = this.el;
        setTimeout(() => {
            setTimeout(() => {
                this.root.unmount(); // надо дождаться пока сработает react onblur, только потом отцеплять конпонент
                Backbone.View.prototype.remove.call(this);
            });
        });
        removeAsidePanelPortal(this.el);

        const card = getCard(this.store.getState(), this.cardId);
        const boardId = getActiveBoardId(this.store.getState());
        const isReadOnly = getPanelReadonly(this.store.getState(), boardId);
        if (!isReadOnly) {
          const isCardDeleted = card ? card.status === TStatus.STATUS_DELETED : true;
          const isFilled = this.cardId === undefined || this.cardId === FAKE_CARD_ID ||
              checkIfRequiredPropertiesAreFilled(this.store.getState(), this.cardId);
          if (!isFilled && !isCardDeleted) {
              this.store.dispatch(currentNotFilledCardIdSetAction(this.cardId));
              setTimeout(() => {
                  const currentCard = getCurrentNotFilledCardId(this.store.getState());
                  if (this.cardId === currentCard) {
                      this.store.dispatch(currentNotFilledCardIdSetAction(null));
                  }
              }, 3000);
              return this.store.dispatch(snackbarErrorDefault({
                  id: SNACKBAR_ID_CARD_NOT_FILLED_ERROR,
                  text: SNACKBAR_CARD_NOT_FILLED_TEXT,
                  position: ESnackbarPosition.left
              }));
          }
        }
    }
});
