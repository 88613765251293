import { IGetState, TBoardId } from '../../../types/types';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { boardPatchFile } from './api/helpers/boardPatchFile';
import { getBoardLogoRestUrl } from '../../constants';
import { boardPatchRest } from './api/helpers/boardPatchRest';
import { refreshRecentOpenBoard } from '../authUser/recentBoards/refreshRecentOpenBoard';

export const boardSetLogo = (
    boardId: TBoardId,
    file: File
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (file) {
            const query: string = '?' + ['name='+encodeURI(file.name), 'mimeType='+file.type].join('&');
            return dispatch(boardPatchFile(boardId, file, getBoardLogoRestUrl(boardId) + query))
                .then(() => {
                    dispatch(refreshRecentOpenBoard(boardId));
                })
        } else {
            return dispatch(boardPatchRest(boardId, {logo: ''}))
                .then(() => {
                    dispatch(refreshRecentOpenBoard(boardId));
                })
        }
    };
    return action;
}
