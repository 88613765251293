import { root } from 'app/store/constants';
import { Dispatch, ThunkAction } from 'app/types/actions';
import { IGetState } from 'app/types/types';

export function onOpen (
): ThunkAction {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        root.App.router.navigate(root.App.router.getModalUrl('help'), {trigger: true});
    };
    return action
};
