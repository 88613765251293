import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId, TChecklistId } from '../../../../../../../../types/types';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { IChecklistItem } from '../../../../../../../../store/model/checklists/checklist/types';
import { addChecklistItem } from '../../effects/addChecklistItem';
import {
    cardToggleAssignee,
    EToggleAssigneeType
} from '../../../../AssigneesSection/hocs/AssigneesSectionHOC/effects/cardToggleAssignee';
import { getBoardIdByCardId } from '../../../../../../../../store/model/selectors/getBoardIdByCardId';
import {
    getChecklistItems
} from '../../../../../../../../store/model/checklists/checklist/selectors/getChecklistItems';
import { getCard } from '../../../../../../../../store/model/selectors/getCard';
import { cardSetDueDateWithEpics } from '../../../../../../../../rest/effects/card/card/cardSetDueDateWithEpics';
import { cardSetStartDateWithEpics } from '../../../../../../../../rest/effects/card/card/cardSetStartDateWithEpics';
import {
    getBoardMetaDefaultDueDateTime
} from '../../../../../../../../store/model/selectors/getBoardMetaDefaultDueDateTime';
import {
    getBoardMetaDefaultStartDateTime
} from '../../../../../../../../store/model/selectors/getBoardMetaDefaultStartDateTime';
import { SegmentCountValue } from 'app/middlewares/segment/trackEntities/cardEvents';
import {
    getAuthUserAllowChecklistItemCount
} from '../../../../../../../../store/model/authUser/selectors/getAuthUserAllowChecklistItemCount';
import { showSnoozeBlocker } from '../../../../../../base/effects/showSnoozeBlocker';

export const onAdd = (
    cardId: TCardId,
    checklistId: TChecklistId,
    item: IChecklistItem,
    isSaveFirst: boolean
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const maxCount = getAuthUserAllowChecklistItemCount(state);
        const checklistItems = getChecklistItems(getState(), cardId, checklistId) || {};
        const count = Object.keys(checklistItems).length;
        if (count >= maxCount) {
            return dispatch(showSnoozeBlocker({ allow: 'allowChecklistItemCount' }));
        } else {
            let orderNumber = checklistItems && Object.keys(checklistItems).length + 1 || 0;
            if (isSaveFirst) {
                orderNumber = 0;
                for (let id in checklistItems) {
                    if (orderNumber >= checklistItems[id].orderNumber) {
                        orderNumber = checklistItems[id].orderNumber - 1;
                    }
                }
            }
            return dispatch(addChecklistItem(cardId, checklistId, { ...item, orderNumber }))
                .then(() => {
                    const state = getState();
                    const boardId = getBoardIdByCardId(state, cardId);
                    const card = getCard(state, cardId)
                    dispatch(segmentTrackAction(SegmentCardEvent.CHECKLIST_ITEM_ADDED, {
                        name: SegmentCountValue.COUNT,
                        value: card.checklistStats.totalCount + 1
                    }));
                    const { assignees, dueDate, startDate } = item;
                    if (assignees && assignees.length) {
                        const promises = [];
                        assignees.forEach(assignee => {
                            promises.push(dispatch(cardToggleAssignee(
                                boardId,
                                cardId,
                                assignee.sharedUser.permissionId,
                                EToggleAssigneeType.ADD,
                                false
                            )));
                        })
                    }
                    if (dueDate || startDate) {
                        const {
                            dueDate: cardDueDate = null,
                            isMilestone = false,
                            startDate: cardStartDate = null,
                        } = getCard(state, cardId) || {};

                        if (isMilestone) return;

                        if (
                            dueDate &&
                            (!cardDueDate || cardDueDate < dueDate) &&
                            (!cardStartDate || cardStartDate < dueDate)
                        ) {
                            const dueHours = getBoardMetaDefaultDueDateTime(state, boardId).getHours();
                            dispatch(cardSetDueDateWithEpics(cardId, dueDate + dueHours * 3600));
                        }

                        if (
                            startDate &&
                            (!cardDueDate || cardDueDate > startDate) &&
                            (!cardStartDate || cardStartDate < startDate)
                        ) {
                            const startHours = getBoardMetaDefaultStartDateTime(state, boardId).getHours();
                            dispatch(cardSetStartDateWithEpics(cardId, startDate + startHours * 3600));
                        }
                    }
                });
        }
    };
    return action;
};
