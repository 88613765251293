import { IMessageData, IRoomProviderSubscriber, SocketProvider, TMessage, TRoomId, TToken } from './SocketProvider';
import { SocketFactory } from './SocketFactory';
import { IEmitCloseProvider, Room } from './Room';
import { fetchHandler } from '../../../util/fetchHandler';
import { root } from 'app/store/constants';

export class RoomProvider  implements  IRoomProviderSubscriber, IEmitCloseProvider{
    protected roomId: TRoomId;
    protected token: TToken;
    protected isBoardRoom: boolean;
    protected socketProvide: SocketProvider;
    protected lastMessageTime = 0;
    protected room: Room;
    closeProvider: ICloseProvider;

    constructor(roomId: TRoomId, token: TToken, isBoardRoom: boolean, closeProvider: ICloseProvider) {
        this.roomId = roomId;
        this.token = token;
        this.isBoardRoom = isBoardRoom;
        this.closeProvider = closeProvider;
        this.socketProvide = SocketFactory.getInstance().getSocketProvider();
        this.connectToRoom();
        this.room = new Room(this.roomId, this);
    }

    getRoom() {
        return this.room;
    }

    connectToRoom(){
        this.socketProvide.subscribeToRoom(this.roomId, this.token, this.lastMessageTime, this.isBoardRoom, this );
    }

    onIncorrectToken(): void {
        this._receiveToken().then(res => {
            // @ts-ignore
            this.token = res.token;
            this.connectToRoom();
        }).catch(error => {
            console.error(error);
        });
    }

    onMessage(message: IMessageData): void {
        this.room.onMessage(message);
    }

    onSocketConnect(): void {
        this.connectToRoom();
    }

    setStartMessageTime(time: number): void {
        if (time) {
            this.lastMessageTime = time;
        }
    }

    private _receiveToken() {
        return fetchHandler('/rest/users/comet/token/' + this.roomId);
    };

    emit(message: TMessage): void {
        this.socketProvide.emitToRoom(this.roomId, message);
    }

    closeRoom(): void {
        this.socketProvide.exitFromRoom(this.roomId);
        this.closeProvider.closeRoom(this.roomId);
    }

}

export interface ICloseProvider {
    closeRoom: (roomId: TRoomId) => void;
}
