import { ISnackbarUpgrade } from './types';
import { Dispatch } from '../../../../../types/actions';
import { SnackbarVariant } from '../../store/types';
import { SNACKBAR_BUTTON_UPGRADE, SNACKBAR_ID_UPGRADE } from '../constants';
import { getDefaultActionEvent } from '../helpers/getDefaultActionEvent';
import { addUpdateSnackbarWithBubble } from '../addUpdateSnackbarWithBubble/addUpdateSnackbarWithBubble';
import { SNACKBAR_UPGRADE_TEXT } from './constants';
import { onUpgradeApply } from './onUpgradeApply';

export const snackbarUpgradeDefault = (
) => {
    return (dispatch: Dispatch) => {
        const id = SNACKBAR_ID_UPGRADE;
        const controls = [{
            action: () => dispatch(getDefaultActionEvent(id, onUpgradeApply)),
            title: SNACKBAR_BUTTON_UPGRADE
        }];
        dispatch(addUpdateSnackbarWithBubble({
            id,
            controls,
            text: SNACKBAR_UPGRADE_TEXT,
            title: null,
            variant: SnackbarVariant.UPGRADE
        }));
    }
};
