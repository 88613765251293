import { IGetState, TBoardId } from '../../../types/types';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { boardPatchRest } from './api/helpers/boardPatchRest';

export const boardSetDescription = (
    boardId: TBoardId,
    description: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(boardPatchRest(boardId, {description}));
    };
    return action;
}
