import * as React from 'react';
import { ITutorialProps } from './types';

export function Tutorial ({
    Component,
    isShow,
    tutorialStep,
    onTutorialNext
}: ITutorialProps) {
    if (
        !isShow ||
        !Component
    ) return null;

    return (<Component
        tutorialStep={tutorialStep}
        onTutorialNext={onTutorialNext}
    />);
};
