import { IGetState, TBoardId } from '../../../types/types';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { boardColorPatchRest } from './api/helpers/boardColorPatchRest';
import { IColor } from '../../../types/model';
import { getActiveCardId } from '../../../store/router/selectors/getActiveCardId';
import { cardsActiveRequestsUpdate } from '../../../store/requestsState/effects/cardsActiveRequestsUpdate';
import { ICards } from '../../../store/model/cards/types';
import { boardActiveRequestsUpdate } from '../../../store/requestsState/effects/boardActiveRequestsUpdate';

export const boardColorEdit = (
    boardId: TBoardId,
    colorTag: IColor
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const cardId = getActiveCardId(getState());
        if (cardId) {
            dispatch(cardsActiveRequestsUpdate({ [cardId]: {} } as ICards, 1))
        } else {
            dispatch(boardActiveRequestsUpdate(boardId, 1));
        }
        return dispatch(boardColorPatchRest(boardId, colorTag))
            .then((colorTag: IColor) => {
               return colorTag;
            })
            .finally(() => {
                if (cardId) {
                    dispatch(cardsActiveRequestsUpdate({ [cardId]: {} } as ICards, -1))
                } else {
                    dispatch(boardActiveRequestsUpdate(boardId, -1));
                }
            });
    };
    return action;
}
