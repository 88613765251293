import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TBoardId, TCardId, TChecklistId } from '../../../../../../../../types/types';
import { copyChecklist } from '../effects/copyChecklist';

export const onCopy = (
    boardId: TBoardId,
    cardId: TCardId,
    srcCardId: TCardId,
    srcChecklistId: TChecklistId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(copyChecklist(boardId, cardId, srcCardId, srcChecklistId));
    };
    return action;
};
