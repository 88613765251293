import { AsidePanelActionsButton } from '../../../../..';
import * as React from 'react';
import { IBoardImportPanelButtonProps } from './types';
import { CLASS_BOARD_DETAILS_SECTION } from '../../../../constants';
import {BOARD_IMPORT_TITLE} from 'app/view/react_components/aside_panel/boardDetails/details/ImportBoard/components/BoardImport/constants';

export function BoardImportPanelButton ({
    show,
    tooltip,
    onClick
}: IBoardImportPanelButtonProps) {
    if (!show) {
        return null;
    }
    return (
        <AsidePanelActionsButton
            children={null}
            sectionSelector={`.${CLASS_BOARD_DETAILS_SECTION}--board-import`}
            title={BOARD_IMPORT_TITLE}
            tooltip={tooltip}
            onClick={onClick}
        />
    );
}
