import { getDateWithoutTime } from './getDateWithoutTime';

export const getDeepDateCheck = (
    date: Date,
    fromDate?: Date,
    toDate?: Date,
    isAllowSameDate?: boolean
): boolean => {
    let isValid = true;
    const selectedDay = date.getDate();
    const selectedMonth = date.getMonth();
    const selectedYear = date.getFullYear();
    if (fromDate) {
        isValid = isAllowSameDate ?
            getDateWithoutTime(date) >= getDateWithoutTime(fromDate)
            : (
                date >= fromDate &&
                !(
                    selectedDay === fromDate.getDate() &&
                    selectedMonth === fromDate.getMonth() &&
                    selectedYear === fromDate.getFullYear()
                )
            );
    }
    if (toDate) {
        isValid = isAllowSameDate ?
            getDateWithoutTime(date) <= getDateWithoutTime(toDate)
            : (
                date <= toDate &&
                !(
                    selectedDay === toDate.getDate() &&
                    selectedMonth === toDate.getMonth() &&
                    selectedYear === toDate.getFullYear()
                )
            );
    }
    return isValid;
}
