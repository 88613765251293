import {Dispatch, ThunkAction} from '../../../types/actions';
import {IGetState} from '../../../types/types';
import {IAuthUser} from '../../../store/model/authUser/types';
import {authUserPatch} from './api/helper/authUserPatch';

export const authUserSetIsAttachmentViewTypeGrid = (
    userId: string,
    isAttachmentViewTypeGrid: boolean
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const userPatch: IAuthUser = {
            id: userId,
            meta: {
                isAttachmentViewTypeGrid
            }
        };
        return dispatch(authUserPatch(userPatch));
    };
    return action;
};
