import { ThunkAction } from '../../../../../../../../types/actions';
import { TBoardId } from '../../../../../../../../types/types';
import { getExportSortedColumns } from '../selectors/getExportSortedColumns';
import { saveColumns } from '../effects/saveColumns';
import { SegmentBoardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import {
    SegmentBoardOption,
    SegmentBoardOptionStatusValue
} from '../../../../../../../../middlewares/segment/trackEntities/boardEvents';

export const onColumnToggle = (
    boardId: TBoardId,
    columnId: string,
    checked: boolean
): ThunkAction => {
    return (
        dispatch,
        getState
    ) => {
        const columns = getExportSortedColumns(getState(), boardId);
        const currentColumn = columns.find(currentColumn => currentColumn.id === columnId);
        if (currentColumn) {
            currentColumn.enabled = checked;
            dispatch(segmentTrackAction(SegmentBoardEvent.EXPORT_COLUMN_CLICKED, {
                name: SegmentBoardOption.STATUS,
                value: checked ? SegmentBoardOptionStatusValue.ON : SegmentBoardOptionStatusValue.OFF
            }, [{
                name: SegmentBoardOption.TYPE,
                value: currentColumn.label
            }]));
            return dispatch(saveColumns(boardId, columns));
        }
    }
}
