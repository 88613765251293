import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState, TBoardId } from '../../../../../../../types/types';
import { IColor } from '../../../../../../../types/model';
import { boardColorEdit } from '../../../../../../../rest/effects/color/boardColorEdit';

export const onEdit = (
    boardId: TBoardId,
    colorTag: IColor
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(boardColorEdit(boardId, colorTag));
    };
    return action;
};
