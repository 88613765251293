import { Dispatch, ThunkAction } from '../../../types/actions';
import { segmentTrackAction, SegmentUserEvent } from '../../../middlewares/segment';
import { SegmentUserOption, SegmentUserTypeOption } from '../../../middlewares/segment/trackEntities/userEvents';
import { snackbarInfoDefault } from '../../../view/react_components/snackbarsStack';
import {
    FREE_VERSION_CREATE_EXTRA_CARD_TEXT,
    FREE_VERSION_CREATE_EXTRA_CARD_TITLE,
    FREE_VERSION_OPEN_BOARD_TEXT, FREE_VERSION_OPEN_BOARD_TITLE,
    FREE_VERSION_TIMER,
    SNACKBAR_ID_FREE_VERSION_LIMIT_CARD, VIEW_ONLY_TIMER
} from '../../../view/react_components/snackbarsStack/effects/constants';

export const showBoardLimitSnackbarAndEvent = (type: SegmentUserTypeOption): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        dispatch(segmentTrackAction(SegmentUserEvent.FREE_LIMIT_SNACKBAR, {
            name: SegmentUserOption.TYPE,
            value: type,
        }));
        if(type === SegmentUserTypeOption.CARD_CREATION) {
            dispatch(snackbarInfoDefault({
                id: SNACKBAR_ID_FREE_VERSION_LIMIT_CARD,
                text: FREE_VERSION_CREATE_EXTRA_CARD_TEXT,
                title: FREE_VERSION_CREATE_EXTRA_CARD_TITLE,
                timer: FREE_VERSION_TIMER
            }));
        } else if (type === SegmentUserTypeOption.BOARD_OPENING) {
            dispatch(snackbarInfoDefault({
                id: SNACKBAR_ID_FREE_VERSION_LIMIT_CARD,
                text: FREE_VERSION_OPEN_BOARD_TEXT,
                title: FREE_VERSION_OPEN_BOARD_TITLE,
                timer: FREE_VERSION_TIMER
            }));
        } else if (type === SegmentUserTypeOption.VIEW_ONLY_CLICK) {
            dispatch(snackbarInfoDefault({
                id: SNACKBAR_ID_FREE_VERSION_LIMIT_CARD,
                text: FREE_VERSION_OPEN_BOARD_TEXT,
                title: FREE_VERSION_OPEN_BOARD_TITLE,
                timer: VIEW_ONLY_TIMER,
                controls: []
            }));
        }
    };
    return action;
};
