import * as React from 'react';
import { ICardChecklistCopySectionProps } from './types';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { CardChecklistCopyHOC } from '../../hocs/CardChecklistCopyHOC/CardChecklistCopyHOC';
import { TChecklistId } from '../../../../../../../types/types';

export function CardChecklistCopySection({
    isMultipleCards,
    selectedCardId,
    onCancel,
    onClose,
    setSelectedCardId
}: ICardChecklistCopySectionProps) {
    const { boardId, cardId } = React.useContext(AsidePanelContext);

    const [selectedChecklistId, setSelectedChecklistId] = React.useState(null);

    return (
        <CardChecklistCopyHOC
            boardId={boardId}
            cardId={cardId}
            isMultipleCards={isMultipleCards}
            selectedCardId={selectedCardId}
            selectedChecklistId={selectedChecklistId}
            onSelectCard={setSelectedCardId}
            onSelectChecklists={setSelectedChecklistId}
            onCancel={onCancel}
            onClose={onClose}
        />
    );
};
