const emailRegexp =  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateEmail = (
    email: string
): boolean => {
    return emailRegexp.test(email);
};

export const getInvalidEmailIndex = (
    emails: string[]
): number => {
    for (let i = 0; i < emails.length; i++) {
        if (!validateEmail(emails[i])) return i;
    }
    return -1;
};

export const getArraysIntersectionIndex = (
    a1: string[],
    a2: string[],
): number => {
    for (let i = 0; i < a1.length; i++) {
        if (a2.includes(a1[i])) return i;
    }
    return -1;
};
