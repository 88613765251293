import { ICardAddAction } from './types';
import { AT_CARD_ADD } from './constants';
import { IRestRelatedCard } from '../../../../../../types/rest/IRestRelatedCard';

export const cardAddAction = (
    relatedCard: IRestRelatedCard
): ICardAddAction => {
    return {
        type: AT_CARD_ADD,
        relatedCard
    }
};
