import * as React from 'react';
import { ListItem } from '../../../../../base/components/ListItem/ListItem';
import { Button, Icon, Loader, LoaderBlock, Tooltip, TTooltipDirection, Userpic } from 'kui';
import './_CardAttachment.scss';
import { CLASS_CARD_DETAILS } from '../../../constants';
import { getAsidePanelTooltip } from '../../../../asidePanel/helpers/getAsidePanelTooltip';
import Util from '../../../../../../../util/util';
import { ICardAttachmentProps } from './type';
import {
    ATTACHMENT_ACCESS,
    ATTACHMENT_CARD_OPEN, ATTACHMENT_COPY_LINK,
    ATTACHMENT_DELETE,
    ATTACHMENT_DOWNLOAD,
    ATTACHMENT_GOOGLE_THUMB_SIZE,
    ATTACHMENT_PIN,
    ATTACHMENT_RENAME,
    ATTACHMENT_UNPIN,
} from './constants';
import { CardAttachmentFormHOC } from '../../hocs/CardAttachmentFormHOC/CardAttachmentFormHOC';
import { CardAttachmentGridPreview } from '../CardAttachmentGridPreview/CardAttachmentGridPreview';
import { root } from 'app/store/constants';
import { EAuthUserPlatformType } from 'app/types/rest/IRestAuthUser';
import { THUMB_SIZE } from 'app/const';
import { UserpicStorage } from 'app/view/react_components/base/components/UserpicStorage/UserpicStorage';
import { msFileThumbnailStorage } from 'app/helper/authorisation/microsoft/storage/msThumbnail';
import { escape } from 'underscore';
import { googleFileThumbnailStorage } from 'app/helper/authorisation/google/storage/googleThumbnail';

export function CardAttachment ({
                                    attachment,
                                    activeRequests,
                                    isReadonly,
                                    userHash,
                                    hideAuthor,
                                    isNonDraggable,
                                    isAttachmentViewTypeGrid,
                                    onCardOpen,
                                    onDownload,
                                    onLinkClick,
                                    onLinkCopy,
                                    onTogglePin,
                                    onDelete
                                }: ICardAttachmentProps) {
    const CLASS_CARD_ATTACHMENT = CLASS_CARD_DETAILS + '-attachment';
    const classNameBody = CLASS_CARD_ATTACHMENT + '__body';
    const classNameIcon = CLASS_CARD_ATTACHMENT + '__icon';
    const classNameLink = CLASS_CARD_ATTACHMENT + '__link';
    const classNamePinned = CLASS_CARD_ATTACHMENT + '__pinned';
    const classNameActions = CLASS_CARD_ATTACHMENT + '__actions';
    const classNameAction = CLASS_CARD_ATTACHMENT + '__action';
    const classNameActionActive = classNameAction + '--active';
    const classNameTooltip = CLASS_CARD_ATTACHMENT + '__tooltip';
    const classNameTooltipImage = CLASS_CARD_ATTACHMENT + '__tooltip-image';
    const classNameTooltipDoc = CLASS_CARD_ATTACHMENT + '__tooltip-doc';
    const classNameAuthor = CLASS_CARD_ATTACHMENT + '__author';
    const classNameDate = CLASS_CARD_ATTACHMENT + '__date';
    const classNameKeyhole = CLASS_CARD_ATTACHMENT + '__keyhole';
    const classNameKeyholeButton = CLASS_CARD_ATTACHMENT + '__keyhole-button';

    const [thumbIsLoaded, setThumbIsLoaded] = React.useState(false);
    const [formVisible, setFormVisible] = React.useState(false);
    const [tooltipDirection, setTooltipDirection] = React.useState<TTooltipDirection>('up-right');

    const Keyhole = require('!!@svgr/webpack!./keyhole.svg').default;
    const isPreviewType = Util.fileIsImage(attachment.mimeType) || Util.fileIsVideo(attachment.mimeType);

    function onLinkClickHandler (event: React.SyntheticEvent) {
        onLinkClick(event, attachment.id);
    }

    const onRenameClickHandler = React.useCallback(() => {
        setFormVisible(true);
    }, []);

    const onHover = (e: React.MouseEvent) => {
        const target = e.currentTarget as HTMLElement;
        if (target) {
            const rect = target.getBoundingClientRect();
            if (rect.top > root._innerHeight * 0.5) {
                setTooltipDirection('up-right');
            } else {
                setTooltipDirection('down-right');
            }
        }
    };

    const onCloseForm = React.useCallback(() => {
        setFormVisible(false);
    }, []);

    let icon = attachment.iconUrl
        ? <img
            className={classNameIcon}
            src={attachment.iconUrl}
        />
        : <Icon xlink={'attachment'} size={24}/>;

    let link = (
        <a
            className={classNameLink}
            href={Util.addAuthUserToUrl(attachment.url)}
            target={'_blank'}
            onClick={onLinkClickHandler}
        >
            {attachment.title}
        </a>
    );

    const [attachmentThumbUrl, setAttachmentThumbUrl] = React.useState('');
    React.useEffect(() => {
        if (attachment.platformType === EAuthUserPlatformType.MICROSOFT ){
            if (msFileThumbnailStorage.is()) {
                const thumbSize = `c${THUMB_SIZE}x${THUMB_SIZE}`;
                msFileThumbnailStorage.getThumbnail(attachment.driveId, attachment.fileId, thumbSize)
                    .then(thumb => setAttachmentThumbUrl(thumb.url));
            }
        } else if (googleFileThumbnailStorage.is()) {
            googleFileThumbnailStorage.getThumbnail(attachment.fileId, ATTACHMENT_GOOGLE_THUMB_SIZE)
                .then(thumb => setAttachmentThumbUrl(thumb));
        }
    }, [attachment]);

    const onError = () => {
        if (googleFileThumbnailStorage.is()) {
            googleFileThumbnailStorage.reGetThumbnail(
                attachment.fileId,
                ATTACHMENT_GOOGLE_THUMB_SIZE,
                setAttachmentThumbUrl,
            );
        }
    };

    const tooltipImg = <>
        {!thumbIsLoaded
            ? (thumbIsLoaded === null
                    ? !isAttachmentViewTypeGrid ? icon :
                        <Keyhole
                            className={classNameKeyhole}
                        />
                    :
                    isAttachmentViewTypeGrid ? <LoaderBlock /> : <Loader/>
            )
            :
            <img
                className={attachmentThumbUrl ? classNameTooltipImage : attachment.bigThumbUrl ? classNameTooltipDoc : ''}
                src={attachmentThumbUrl}
                onError={onError}
            />
        }
    </>;
    let headerImg = tooltipImg;
    const tooltip = getAsidePanelTooltip({
        className: `${classNameTooltip} ${isAttachmentViewTypeGrid ? 'grid' : 'list'}`,
        delay: 300,
        direction: tooltipDirection,
        footer: attachmentThumbUrl ? tooltipImg : null,
        value: escape(attachment.title),
    });
    icon = (
        <Tooltip {...tooltip}>
            {icon}
        </Tooltip>
    );
    link = (
        <Tooltip {...tooltip}>
            {link}
        </Tooltip>
    );

    const createdAt = Util.formatDateMoment(attachment.created);

    const body = (
        <div className={classNameBody}>
            {link}
            {!hideAuthor &&
            <div className={classNameAuthor}>
                {attachment.main && !onCardOpen &&
                <Icon className={classNamePinned} xlink={'pin'} size={24} />
                }
                {thumbIsLoaded === null &&
                <Keyhole
                    className={classNameKeyhole}
                />
                }
                {}
                <div className={classNameDate}>
                    {attachment.authorDetails ? createdAt : ''}
                </div>
                {
                    attachment.authorDetails
                        ? <UserpicStorage
                            initials={attachment.authorDetails.initials}
                            src={attachment.authorDetails.photoUrl}
                            title={attachment.authorDetails.fullName}
                            userId={attachment.authorDetails.permissionId}
                        />
                        : <Userpic src={''}/>
                }
            </div>
            }
        </div>
    );

    const downloadUrl = Util.fileHasAvailableMimeType(attachment.mimeType)
        ? Util.createDownLoadLink(attachment.fileId)
        : null;

    const downloadButton = attachment.platformType === EAuthUserPlatformType.MICROSOFT
        ? null
        : {
            href: downloadUrl,
            target: '_blank',
        }

    const pinButton = (
        <Button
            className={`
                    ${classNameAction}
                    ${attachment.main ? classNameActionActive : ''}
                `}
            tooltip={getAsidePanelTooltip({
                direction: 'up-left',
                isNoWrap: true,
                value: attachment.main ? ATTACHMENT_UNPIN : ATTACHMENT_PIN,
            })}
            variant={'icon'}
            onClick={() => onTogglePin(attachment.id)}
        >
            <Icon xlink={'pin'} size={24}/>
        </Button>
    );

    const actions = isReadonly ? null : (<div className={classNameActions}>
        {attachment.authorDetails && userHash === attachment.authorDetails.hash &&
        <Button
            className={classNameAction}
            tooltip={
                getAsidePanelTooltip({
                    direction: 'up-left',
                    value: ATTACHMENT_RENAME
                })
            }
            variant={'icon'}
            onClick={onRenameClickHandler}
        >
            <Icon xlink={'rename'} size={24}/>
        </Button>
        }
        {thumbIsLoaded === null && !isAttachmentViewTypeGrid &&
        <Button
            className={`${classNameAction} ${classNameKeyholeButton}`}
            href={Util.addAuthUserToUrl(attachment.url)}
            target={'_blank'}
            tooltip={
                getAsidePanelTooltip({
                    direction: 'up-left',
                    value: ATTACHMENT_ACCESS
                })
            }
            variant={'icon'}
        >
            <Keyhole
                className={classNameKeyhole}
            />
        </Button>
        }
        {attachment.url &&
        <Button
            className={classNameAction}
            tooltip={
                getAsidePanelTooltip({
                    direction: 'up-left',
                    value: ATTACHMENT_COPY_LINK
                })
            }
            variant={'icon'}
            onClick={() => onLinkCopy(attachment.url)}
        >
            <Icon xlink={'link'} size={24} />
        </Button>
        }
        {!!onCardOpen &&
        <Button
            className={classNameAction}
            tooltip={
                getAsidePanelTooltip({
                    direction: 'up-left',
                    value: ATTACHMENT_CARD_OPEN
                })
            }
            variant={'icon'}
            onClick={onCardOpen}
        >
            <Icon xlink={'card'} size={24} />
        </Button>
        }
        {!!downloadUrl &&
        <Button
            className={classNameAction}
            tooltip={
                getAsidePanelTooltip({
                    direction: 'up-left',
                    value: ATTACHMENT_DOWNLOAD
                })
            }
            variant={'icon'}
            {...downloadButton}
            onClick={() => onDownload(attachment.driveId, attachment.fileId)}
        >
            <Icon xlink={'cloud-upload'} size={24} />
        </Button>
        }
        {!onCardOpen &&
        <Button
            className={classNameAction}
            tooltip={
                getAsidePanelTooltip({
                    direction: 'up-left',
                    value: ATTACHMENT_DELETE
                })
            }
            variant={'icon'}
            onClick={() => onDelete(attachment.id)}
        >
            <Icon xlink={'delete'} size={24}/>
        </Button>
        }
        {onTogglePin && pinButton}
    </div>);

    return (
        <>
            {!!attachmentThumbUrl && <img
                src={attachmentThumbUrl}
                onLoad={()=>setThumbIsLoaded(true)}
                onError={()=> {
                    setThumbIsLoaded(null);
                    onError();
                }}
                hidden
            />}
            {formVisible
                ? <CardAttachmentFormHOC
                    attachment={attachment}
                    onClose={onCloseForm}
                />
                : isAttachmentViewTypeGrid
                    ? <CardAttachmentGridPreview
                        actions={actions}
                        headerImg={attachmentThumbUrl ? headerImg : null}
                        isDoc={!attachment.isImage && !!attachment.bigThumbUrl}
                        img={icon}
                        text={body}
                        isDraggable={!isReadonly && !activeRequests && !isNonDraggable}
                        data-id={attachment.id}
                        url={attachment.url}
                        onMouseEnter={onHover}
                    />
                    : <ListItem
                        onMouseEnter={onHover}
                        actions={actions}
                    img={attachmentThumbUrl && isPreviewType ? headerImg : icon}
                        text={body}
                        className={CLASS_CARD_ATTACHMENT}
                        isDraggable={!isReadonly && !activeRequests && !isNonDraggable}
                        data-id={attachment.id}
                        isLoading={!attachment.authorDetails}
                    />
            }
        </>
    );
}
