import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { root } from '../../../../../../../../store/constants';
import { TCardId } from '../../../../../../../../types/types';
import { commentUpdate } from '../../../store/reducers/actions/commentUpdate';

export const onChangeCommentValue = (
    cardId: TCardId,
    value: string
): ThunkAction => {
    return (dispatch: Dispatch) => {
        dispatch(commentUpdate({ value }));
        root.App.controller.cardsCommentsInfo.setValue(cardId, value);
    };
};
