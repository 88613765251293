import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { boardsLoadingSet } from '../store/boards/actions/boardsLoadingSet';
import { onLoadBoardsFail } from './onLoadBoardsFail';

import { TTabKey } from '../store/types';
import { IGetState } from '../../../../../types/types';

import { getDrivesIsLoading } from '../store/drives/selectors/getDrivesIsLoading';
import { getBoardsIsLoading } from '../store/boards/selectors/getBoardsIsLoading';
import { checkBoardsLoadedByTabKey } from './checkBoardsLoadedByTabKey';
import { tabLoadStatusSet } from '../store/common/actions/tabLoadstatusSet';
import { TTabLoadStatus } from '../store/common/types';

export const loadBoardsByTabKey: ActionCreator<ThunkAction> = (
    tabKey: TTabKey
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(tabLoadStatusSet(tabKey, TTabLoadStatus.LOADING));
        dispatch(boardsLoadingSet(tabKey,true))
        dispatch(checkBoardsLoadedByTabKey(tabKey))
            .then(() => {
                dispatch(boardsLoadingSet(tabKey, false));
                dispatch(tabLoadingReset(tabKey));
            })
            .catch(() => {
                dispatch(onLoadBoardsFail())
            });
    };
    return action;
};

const tabLoadingReset: ActionCreator<ThunkAction> = (
    tabKey: TTabKey
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (
            getDrivesIsLoading(getState()) ||
            getBoardsIsLoading(getState(), tabKey)
        ) {
            return;
        }
        dispatch(tabLoadStatusSet(tabKey, TTabLoadStatus.COMPLETED));
    };
    return action;
};
