import { getMessages } from '../../../../../../store/constants';

export const STATUS_LISTS_TITLE =  getMessages().getText('board_settings.statuslists.title');
export const STATUS_LIST_ON_HOLD = getMessages().getText('board_settings.statuslist.onhold');
export const STATUS_LIST_IN_WORK = getMessages().getText('board_settings.statuslist.inwork');
export const STATUS_LIST_IN_WORK_MOVE = getMessages().getText('board_settings.statuslist.inwork.move');
export const STATUS_LIST_DONE = getMessages().getText('board_settings.statuslist.done');
export const STATUS_LIST_ARCHIVE = getMessages().getText('board_settings.statuslist.archive');
export const STATUS_LIST_ON_HOLD_DESC = getMessages().getText('board_settings.statuslist.onhold.desc');
export const STATUS_LIST_IN_WORK_DESC = getMessages().getText('board_settings.statuslist.inwork.desc');
export const STATUS_LIST_DONE_DESC = getMessages().getText('board_settings.statuslist.done.desc');
