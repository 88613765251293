'use strict';
import * as _ from 'underscore';
/**
 * Inherit from this class when a model needs to be updated via realtime;
 * add the following properties to the successor class: classKey
 * and implement methods: triggerLocalChange
 */
App.Models.BaseRelationalModel = Backbone.RelationalModel.extend({

    constructor: function(attributes, options) {
        Backbone.RelationalModel.apply(this, arguments);

        this.initialized = true;
        if (this.initEvents){
            this.initEvents(options);
        }
    },



    sync: function(method, model, options) {
        if (method === 'copy') {
            var originalMethod = method;
            method = 'create';
            options = options || {};
            options.url = _.result(this, 'url') + '/' + originalMethod;
        }
        if (method == 'create' || method == 'update' || method == 'delete' || method == 'patch') {
            var oldSuccess = options.success;            
            options.success = _.bind(function(obj) {
                if (oldSuccess) {
                    oldSuccess(obj);
                }
                if (!options.noTriggerLocalChange) {
                    this.triggerLocalChange(options);
                }
                if (options.onAfterLocalChange && _.isFunction(options.onAfterLocalChange)) {
                    options.onAfterLocalChange(options);
                }
            }, this);
        }
        return Backbone.sync.call(model, method, model, options);
    },
    
    /**
     * abstract - implement in subclass
     */
    triggerLocalChange: function() {},

    updateFromJson: function(data, options) {
        if (_.isObject(data)) {
            options = options ? _.clone(options) : {};
            if (options.parse === void 0) options.parse = true;       
            this.set(this.parse(data, options), options);
        }
    },

    unloadFromMemory: function(options){
        this.trigger('destroy', this, this.collection, options);
    }
});
