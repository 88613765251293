import { AsidePanelActionsButton } from '../../../../..';
import * as React from 'react';
import { ILinksToCardsPanelButtonProps } from './types';
import { CLASS_BOARD_DETAILS_SECTION } from '../../../../constants';
import { LINKS_TO_CARDS_BUTTON } from '../../constants';

export function LinksToCardsPanelButton ({
    indicatorBoolean,
    isDisabled,
    tooltip,
    onClick
}: ILinksToCardsPanelButtonProps) {

    return (
        <AsidePanelActionsButton
            children={null}
            indicatorBoolean={indicatorBoolean}
            isDisabled={isDisabled}
            sectionSelector={`.${CLASS_BOARD_DETAILS_SECTION}--open-graph`}
            title={LINKS_TO_CARDS_BUTTON}
            tooltip={tooltip}
            onClick={onClick}
        />
    );
}
