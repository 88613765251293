import {IShowSetAction} from './types';
import {AT_SHOW_SET} from './constants';

export const showSet = (
    show: string[]
): IShowSetAction => ({
        type: AT_SHOW_SET,
        show
    }
);
