import {connect} from 'react-redux';
import {onClick} from './events/onClick';
import {ButtonLarge} from '../../components/ButtonLarge/ButtonLarge';
import {IButtonLargeDispatchProps, IButtonLargeStateProps} from '../../components/ButtonLarge/types';
import {onDidMount} from './events/onDidMount';
import { BUTTON_TEXT_GIVE_PERMISSIONS } from '../../constants';

const mapStateToProps =
(): IButtonLargeStateProps => {
    return {
        text: BUTTON_TEXT_GIVE_PERMISSIONS
    }
};

const mapDispatchToProps = (
    dispatch: any
): IButtonLargeDispatchProps => {
    return {
        onClick: () => dispatch(onClick()),
        onDidMount: () => dispatch(onDidMount())
    }
};

export const ButtonGivePermissionsSharedDrivesHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(ButtonLarge);

ButtonGivePermissionsSharedDrivesHOC.displayName = 'ButtonGivePermissionsSharedDrivesHOC';
