import { AsidePanelActionsButton } from '../../../../..';
import { CLASS_BOARD_DETAILS_SECTION } from '../../../../constants';
import * as React from 'react';
import { IStatusListsPanelButtonProps } from './types';
import { STATUS_LISTS_TITLE } from '../../constants';
import { getAsidePanelActionsButtonTooltip } from 'app/view/react_components/aside_panel/asidePanel/helpers/getAsidePanelActionsButtonTooltip';

export function StatusListsPanelButton ({
    isBasicFeature,
    isDisabled,
    tooltip,
    indicatorBoolean,
    onClick
}: IStatusListsPanelButtonProps) {
    return (
        <AsidePanelActionsButton
            children={null}
            indicatorBoolean={indicatorBoolean}
            isDisabled={isDisabled}
            isBasicFeature={isBasicFeature}
            sectionSelector={`.${CLASS_BOARD_DETAILS_SECTION}--statuslists`}
            title={STATUS_LISTS_TITLE}
            tooltip={tooltip && getAsidePanelActionsButtonTooltip({ value: tooltip })}
            onClick={onClick}
        />
    );
}
