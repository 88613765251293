'use strict';

App.Models.UserProductivityStat = Backbone.Model.extend({
    rest: function() {
        return '/rest/stat/teamperfomance'
    },

    url: function() {
        return App.Util.getApiUrl(this.rest());
    },

    onCreate: function() {
        return {
            method: 'create'
        }
    },

    onComplete: function(){
        this.destroy();
    },

    onError: function(xhr, textStatus, errorThrown){

    }
});
