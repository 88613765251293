import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState } from '../../../../types/types';
import { postRest } from './postRest';
import { REST_AUTH_USER_UPDATE_ACCESS_TIME } from '../../../constants';

export const userUpdateWebPushNotifyTokenAccessTime = (
    token: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(postRest(REST_AUTH_USER_UPDATE_ACCESS_TIME, {token}))
    };
    return action;
};
