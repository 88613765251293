import * as React from 'react';
import { INotificationEditingPopupProps } from './types';
import './_NotificationEditingPopUp.scss';
import { NotificationListItem } from '../NotificationListItem/NotificationListItem';
import { Button, ButtonsGroup } from 'kui';
import { ITimeUnits } from 'app/types/model';
import { getErrorMessageIfNotificationsNotValid } from '../../helpers/checkIsValid';
import {
    ADD_NOTIFICATION_BUTTON_TEXT,
    CANCEL_NOTIFICATION_BUTTON_TEXT,
    NOTIFICATIONS_EDITING_POPUP_LABEL_TEXT,
    SAVE_NOTIFICATION_BUTTON_TEXT,
} from './constants';
import { INotificationBeforeDueDate } from 'app/store/model/notificationBeforeDueDate/types';

export const NotificationEditingPopUp: React.FC<INotificationEditingPopupProps> = ({
    notifications,
    onRemoveNotificationById,
    onChangeInterval,
    onChangeTimeUnit,
    onSave,
    onCancel,
    onAddNotification,
    onEnter,
    dueDate,
    onDidUnmount,
    isValid,
    isOpen,
}) => {
    if (!isOpen) {
        return null;
    }

    const validateItem = (notification: INotificationBeforeDueDate): string => {
        return getErrorMessageIfNotificationsNotValid(dueDate, notifications, notification);
    }

    return (
        <div className="notifications-section">
            <div className="notifications-container">
                <span className={ 'kui-label__item' }>
                    { NOTIFICATIONS_EDITING_POPUP_LABEL_TEXT }
                </span>
                {
                    notifications.map((notification) => {
                        const { id } = notification;
                        return (
                            <NotificationListItem
                                id={ id }
                                key={ id }
                                notification = {notification}
                                isPopupOpen={ isOpen }
                                onRemoveNotificationById={ () => onRemoveNotificationById(id) }
                                onUnitChange={ (unit: ITimeUnits) => onChangeTimeUnit(id, unit) }
                                onIntervalChange={ (interval: number) => onChangeInterval(id, interval) }
                                orderNumber={ notification.orderNumber }
                                onOpenEditingPopup={ () => undefined }
                                unit={ notification.unit }
                                interval={ notification.interval }
                                onEnter={ onEnter }
                                onDidUnmount={ onDidUnmount }
                                validateItem={ validateItem }
                            />
                        );
                    })
                }
            </div>
            <div className="notifications-actions">
                <div className="notifications-actions__add">
                    <Button
                        variant="add"
                        onClick={ onAddNotification }
                    >
                        { ADD_NOTIFICATION_BUTTON_TEXT }
                    </Button>
                </div>
                <div className="notifications-actions__save">
                    <ButtonsGroup>
                        <Button
                            variant="secondary"
                            onClick={ onCancel }>
                            { CANCEL_NOTIFICATION_BUTTON_TEXT }
                        </Button>
                        <Button
                            onClick={ onSave }
                            disabled={ !isValid }
                        >
                            { SAVE_NOTIFICATION_BUTTON_TEXT }
                        </Button>
                    </ButtonsGroup>
                </div>
            </div>
        </div>
    );
};
