import { Dispatch, ThunkAction } from '../../../types/actions';
import { fetchHandler } from '../../../util/fetchHandler';
import Util from '../../../util/util';
import { REST_CSAT } from '../../constants';
import { getRestHeadersPost } from '../../helpers/getRestHeadersHelper';
import { ICSATPollRest } from './types';

export const authUserPostCsat = (
    csatPoll: ICSATPollRest
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        return fetchHandler(Util.getApiUrl(REST_CSAT), {
            ...getRestHeadersPost(),
            body: JSON.stringify(csatPoll)
        })
    }
    return action;
};
