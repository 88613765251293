import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState, TCardId } from '../../../../types/types';
import { cardUpdateMeta } from './cardUpdateMeta';
import { ICardCustomProperties } from '../../../../store/customProperties/types';

export const cardSetCustomProperties = (
    cardId: TCardId,
    customProperties: ICardCustomProperties,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(cardUpdateMeta(cardId, {
            customProperties
        }));
    };
    return action;
};
