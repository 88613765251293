import * as React from 'react';
import { IGetState, TCardId } from '../../../../types/types';
import { Dispatch } from '../../../../types/actions';
import { getCard } from '../../../../store/model/selectors/getCard';
import { isRelatedPanelClosed } from '../../main/kanbanView/components/Card/hocs/CardHOC/selectors/isRelatedPanelOpened';
import { getBoardByCardId } from '../../../../store/model/selectors/getBoardByCardId';
import { selectCard } from '../../clickManager';
import { relatedCardToggle } from '../../aside_panel/relatedCards/effects/relatedCardToggle';

export const cardClick = (
    e: React.MouseEvent,
    cardId: TCardId
) => {
    return (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const card = getCard(state, cardId);
        if (!card || card.isNew) return;
        const isPanelClosed = isRelatedPanelClosed(state);
        if (isPanelClosed) {
            const boardId = getBoardByCardId(state, cardId).id;
            dispatch(selectCard(e, boardId, cardId));
        } else {
            dispatch(relatedCardToggle(cardId));
        }
    };
}
