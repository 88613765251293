import { EViewTypes } from 'app/const';

export const CARD_CLASS = 'k-card';
export const CARD_ACTIONS_CLASS = CARD_CLASS + '__actions';
export const CARD_CONTROL_CLASS = CARD_CLASS + '__control';
export const CARD_CONTAINER_CLASS = 'k-card__container';
export const LISTS_CLASS = 'lists-container';
export const LISTS_ASIDE_PLACEHOLDER_CLASS = 'lists-aside-placeholder';
export const LIST_CONTAINER_CLASS = 'list-container';
export const LIST_CLASS = 'list-component';
export const LIST_CLASS_DRAG_OVER = LIST_CLASS + '--drag-over';
export const LIST_CLASS_ADD_CARD_OPENED = LIST_CLASS + '--add-card-opened'
export const LIST_CLASS_HEADER = 'list-header';
export const LIST_CONTROL_CLASS = 'list-control';
export const LIST_ADD_CLASS = 'list-add';
export const CARDS_CLASS = 'list-cards';
export const CARDS_SCROLL_CLASS = 'list-cards__scroll';
export const CARD_ACTIONS_PORTAL = 'k-card-actions-portal';
export const CARDS_DROPDOWN_SCROLL_CLASS = 'list-cards__dropdown-scroll';
export const CARD_TEMPLATES_PORTAL = 'card-templates-portal';

export const LIST_MARGIN = 8; // px
export const LIST_WIDTH = 316 + LIST_MARGIN; // px
export const LIST_WIDTH_FOLDED = 60 + LIST_MARGIN; // px

export const CARD_HEIGHT_DEFAULT = 100; // px
export const SWIMLANE_HEIGHT_DEFAULT = 300; // px
export const SWIMLANE_HEIGHT_MIN = 100; // px
export const SWIMLANE_EMPTY = 'SWIMLANE_EMPTY';
export const CLASS_SWIMLANES_RBDND = 'swimlanes-rbdnd';
export const CLASS_SWIMLANE_RBDND = 'swimlane-rbdnd';
export const CLASS_SWIMLANES_CONTROLS = 'swimlanes-controls';
export const SWIMLANE_ICONS = {
    [EViewTypes.SWIM_COLOR_TAGS_VIEW_TYPE]: 'color-tag',
    [EViewTypes.SWIM_TAGS_VIEW_TYPE]: 'tag',
};

export const DRAGGABLE_TYPE_COLUMN = 'column';
export const DRAGGABLE_TYPE_CARD = 'card';
export const DRAGGABLE_TYPE_SWIMLANE = 'swimlane';
