import * as React from 'react';
import {
    Button,
    ButtonDropdown,
    Icon,
    Input,
    ITooltipInheritedProps,
    Loader,
    SelectList,
    SelectListItem,
    Tooltip
} from 'kui';
import { ITopBarDashboardNameProps } from './types';
import { EBoardNameStatus } from '../../store/topBar/types';
import './_TopBarDashboardName.scss';
import {
    TOPBAR_BOARD_ACTIVITY,
    TOPBAR_BOARD_ADD_TO_STARRED,
    TOPBAR_BOARD_CHANGE_LOGO,
    TOPBAR_BOARD_CLOSE,
    TOPBAR_BOARD_NAME,
    TOPBAR_BOARD_REMOVE_FROM_STARRED,
    TOPBAR_BOARD_RENAME,
    TOPBAR_BOARD_SETTINGS,
    TOPBAR_CARD_TEMPLATES,
    TOPBAR_RECURRING_TASKS,
} from './constants';
import { TopBarWarningHOC } from '../../hocs/TopBarWarningHOC/TopBarWarningHOC';
import { getVisibleBoardTooltipTitle } from '../../../../helpers/getVisibleBoardTooltipTitle';
import { markdown } from 'app/helper/markdownHelper';
import { escape } from 'underscore';
import { NewFeatureIndicator } from '../../../../base/components/NewFeatureIndicator/NewFeatureIndicator';
import ReactHtmlParser from 'react-html-parser';
import { BasicFeatureIndicator } from '../../../../base/components/BasicFeatureIndicator/BasicFeatureIndicator';
import { CLASS_LOGO } from '../../../../base/components/Logo/components/constants';
import { NAVIGATION_PANEL_CLOSE_MARKER } from '../../../navigationPanel/constants';
import { Logo } from '../../../../base/components/Logo/components/Logo/Logo';
import { ELogoSize } from '../../../../base/components/Logo/components/Logo/types';
import { getParentsClasses } from '../../../../helpers/getParentsClasses';
import { ProFeatureIndicator } from '../../../../base/components/ProFeatureIndicator/ProFeatureIndicator';

export function TopBarDashboardName ({
    boardNameStatus,
    dashboardName,
    isAsidePanelOpened,
    isBasicFeature,
    isProFeature,
    isReadOnly,
    isBlocked,
    starred,
    isLong,
    isWarning,
    logo,
    logoColor,
    logoIcon,
    recurringCount,
    templatesCount,
    isSettingsNewFeature,
    supportStarred,
    onClickBoardActivity,
    onClickBoardSettings,
    onClickRename,
    onClickChangeLogo,
    onClickStarred,
    onClickName,
    onClickIcon,
    onDblClickName,
    onDblClickIcon,
    onClose,
    onNameChanged,
    onNameSuccess,
    onClickCardTemplates,
    onClickRecurringTasks,
    onTriggerFeaturePoll,
    onClickReports,
    onLogoChange,
}: ITopBarDashboardNameProps) {
    const isEditing = boardNameStatus === EBoardNameStatus.editing;
    const isSyncing = boardNameStatus === EBoardNameStatus.syncing;
    const isSynced = boardNameStatus === EBoardNameStatus.synced;
    const className = 'topbar__dashboard-name';
    const classContainer = className + '-container';
    const classContainerReadOnly = isReadOnly ? classContainer + '--readonly' : '';
    const classNameAside = isAsidePanelOpened ? `${classContainer}--aside` : '';
    const classNameWarning = isWarning ? `${classContainer}--warning` : '';
    const classNameDropdown = 'topbar__dashboard-menu-dropdown';
    const classNameStarIcon = 'topbar__star-icon';

    const classTooltip = className + '-tooltip';

    let [boardName, setNameHook] = React.useState(dashboardName);
    let [boardMarkdown, setBoardMarkdown] = React.useState('');
    let [dropdownOpened, setDropdownOpened] = React.useState(false);
    const nameRef = React.useRef(null);
    const divRef = React.useRef(null);
    const timerRef = React.useRef(null);
    const doubleClick = React.useRef(null);
    const inputRef = React.useRef(null);

    const boardTooltip: ITooltipInheritedProps = {
        className: `${classTooltip} ${isLong ? classTooltip + '--long' : ''}`,
        direction: 'down',
        show: !dropdownOpened,
        value: getVisibleBoardTooltipTitle(boardMarkdown),
    }
    const nameDoubleClicked = React.useRef(false);

    function setName (name: string) {
        boardName = name;
        setNameHook(boardName);
        setBoardMarkdown(markdown(escape(boardName)));
    }

    function handleEnter () {
        nameRef.current.focus();
    }

    function handleEsc (e: React.KeyboardEvent) {
        if (e.which === 27) { // esc
            nameChanged(dashboardName);
        }
    }

    function handleBlur (e: React.FocusEvent) {
        if (e.relatedTarget) {
            const parentClasses = getParentsClasses(e.relatedTarget as HTMLElement, [CLASS_LOGO]);
            if (parentClasses.includes(CLASS_LOGO)) return;
        }
        nameChanged(boardName);
    }

    const onIconBlur = (e: React.FocusEvent) => {
        if (e.relatedTarget) {
            const targetClass = 'topbar__dashboard-input';
            const parentClasses = getParentsClasses(e.relatedTarget as HTMLElement, [className]);
            if (parentClasses.includes(targetClass)) return;
        }
        nameChanged(boardName);
    }

    function nameChanged (value: string) {
        if (value && value !== dashboardName){
            onNameChanged(value, nameDoubleClicked.current)
        } else {
            setName(dashboardName);
            onNameChanged(dashboardName, nameDoubleClicked.current);
        }
        nameDoubleClicked.current = false;
    }

    function handleInputChange (e: React.SyntheticEvent) {
        const target = e.target as HTMLInputElement;
        setName(target.value);
    }

    function handleMenuClick (e: React.MouseEvent) {
        if (dropdownOpened) return;

        onTriggerFeaturePoll();

        const dropdownPortal = document.getElementById('topbar-portal') as HTMLElement;
        if (!dropdownPortal) return;

        const containerRect = nameRef.current.getBoundingClientRect();
        let left = e.clientX - 8 * 10;
        if (left > containerRect.right - 8 * 25) left = containerRect.right - 8 * 25;
        if (left < containerRect.left) left = containerRect.left;
        dropdownPortal.style.left = left + 'px';
    }

    function handleClickName () {
        if (timerRef.current) clearTimeout(timerRef.current);
        timerRef.current = setTimeout(onClickName, 500);
    }

    function handleDblClickName () {
        if (timerRef.current) clearTimeout(timerRef.current);
        if (doubleClick.current) clearTimeout(doubleClick.current);
        nameDoubleClicked.current = true;
        onDblClickName();
    }

    function handleClickIcon () {
        if (timerRef.current) clearTimeout(timerRef.current);
        timerRef.current = setTimeout(onClickIcon, 500);
    }

    function handleDblClickIcon () {
        if (timerRef.current) clearTimeout(timerRef.current);
        if (doubleClick.current) clearTimeout(doubleClick.current);
        nameDoubleClicked.current = true;
        onDblClickIcon();
    }

    function beforeOpen (): Promise<any> { // ждём doubleClick перед открытием
        return new Promise((res, rej) => {
            if (doubleClick.current) clearTimeout(doubleClick.current);
            doubleClick.current = setTimeout(res, 300);
        });
    };

    React.useEffect(() => {
        if (isEditing) {
            const input = nameRef.current.querySelector('.kui-input__item') as HTMLInputElement;
            if (input) {
                input.focus();
                input.select();
            }
        }
    }, [boardNameStatus]);

    React.useEffect(() => {
        setName(dashboardName);
    }, [dashboardName]);

    React.useEffect(() => {
        if (isSynced) {
            setTimeout(() => {
                onNameSuccess();
            }, 1000);
        }
    }, [boardNameStatus]);

    React.useEffect(() => {
        return () => {
            if (timerRef.current) clearTimeout(timerRef.current);
            if (doubleClick.current) clearTimeout(doubleClick.current);
        }
    }, []);

    const logoEl = logo
        ? <div
            className={'topbar__logo'}
            style={{
                backgroundImage: 'url(\'' + logo + '\')'
            }}
        />
        : <Logo
            name={dashboardName}
            logo={{ icon: logoIcon, color: logoColor }}
            className={NAVIGATION_PANEL_CLOSE_MARKER}
            isSelect={isEditing}
            size={isEditing ? ELogoSize.SMALL : ELogoSize.LARGE}
            onBlur={onIconBlur}
            onChange={onLogoChange}
        />;

    return (
    <div className={`${classContainer} ${classContainerReadOnly} ${classNameAside} ${classNameWarning}`}>
        <div
            className={`${className}
                ${isEditing ? className + '--edit': ''}
                ${isSyncing ? className + '--syncing': ''}
                ${isSynced ? className + '--synced' : ''}
                ${isBlocked ? className + '--blocked': ''}
            `}
            ref={nameRef}
            tabIndex={-1}
        >
            {isWarning && <TopBarWarningHOC/>}
            {logoEl}
            <Input
                ref={inputRef}
                className={'topbar__dashboard-input'}
                value={boardName}
                onChange={handleInputChange}
                onBlur={handleBlur}
                onEnter={handleEnter}
                onKeyDown={handleEsc}
                autosize={false}
                disabled={!isEditing || isReadOnly || isBlocked}
                label={TOPBAR_BOARD_NAME}
            />
            <Icon xlink={'done'} size={16} className={`${className}-done`} />
            <div className={'topbar__dashboard-loader'}>
                <Loader />
            </div>
            <ButtonDropdown
                className={'topbar__dashboard-menu'}
                dropdownClassName={classNameDropdown}
                isScaleAnimation
                portal={true}
                portalId={'topbar-portal'}
                onClick={handleMenuClick}
                onOpen={() => setDropdownOpened(true)}
                onClose={() => setDropdownOpened(false)}
                beforeOpen={beforeOpen}
            >
                <Button
                    className={`topbar__dashboard-menu-button`}
                    variant={'icon'}
                >
                    <Tooltip {...boardTooltip}>
                        <div>
                            {logoEl}
                        </div>
                    </Tooltip>
                    {starred && supportStarred &&
                        <Icon
                            className={classNameStarIcon}
                            size={16}
                            xlink={'star'}
                        />
                    }
                    <Tooltip {...boardTooltip}>
                        <div
                            className={'topbar__dashboard-input-text'}
                            onClick={handleClickName}
                            onDoubleClick={handleDblClickName}
                        >
                            {ReactHtmlParser(boardMarkdown)}
                        </div>
                    </Tooltip>
                    <Icon
                        className={'topbar__dashboard-menu-icon'}
                        size={24}
                        xlink={'more'}
                        onClick={handleClickIcon}
                        onDoubleClick={handleDblClickIcon}
                    />
                </Button>
                <SelectList
                    fixActive={false}
                >
                     <SelectListItem
                        icon={'settings'}
                        iconSize={24}
                        onClick={onClickBoardSettings}
                    >
                        {TOPBAR_BOARD_SETTINGS}
                        <div>{isSettingsNewFeature && <NewFeatureIndicator />}</div>
                    </SelectListItem>
                    <SelectListItem
                        className={'divider'}
                        icon={'activity'}
                        iconSize={24}
                        onClick={onClickBoardActivity}
                    >
                        <>
                            {TOPBAR_BOARD_ACTIVITY}
                            {isBasicFeature && <BasicFeatureIndicator />}
                            {isProFeature && <ProFeatureIndicator />}
                        </>
                    </SelectListItem>
                    {(!isReadOnly && !isBlocked) &&
                        <SelectListItem
                            icon={'rename'}
                            iconSize={24}
                            onClick={onClickRename}
                        >
                            {TOPBAR_BOARD_RENAME}
                        </SelectListItem>
                    }
                    {(!isReadOnly && !isBlocked) &&
                        <SelectListItem
                            icon={'kanbanchi-logo'}
                            iconSize={24}
                            onClick={onClickChangeLogo}
                        >
                            <>
                                {TOPBAR_BOARD_CHANGE_LOGO}
                                {isBasicFeature && <BasicFeatureIndicator />}
                                {isProFeature && <ProFeatureIndicator />}
                            </>
                        </SelectListItem>
                    }
                    {supportStarred &&
                        <SelectListItem
                            icon={'star'}
                            iconSize={24}
                            className={`divider`}
                            onClick={onClickStarred}
                        >
                            {starred ? TOPBAR_BOARD_REMOVE_FROM_STARRED : TOPBAR_BOARD_ADD_TO_STARRED}
                        </SelectListItem>
                    }
                    {(!isReadOnly && !isBlocked) &&
                        <SelectListItem
                            icon={'board-template'}
                            iconSize={24}
                            list={!isBasicFeature && String(templatesCount)}
                            onClick={onClickCardTemplates}
                        >
                            <>
                                {TOPBAR_CARD_TEMPLATES}
                                {isBasicFeature && <BasicFeatureIndicator />}
                            </>
                        </SelectListItem>
                    }
                    {(!isReadOnly && !isBlocked) &&
                        <SelectListItem
                            icon={'arrow-circle'}
                            iconSize={24}
                            list={!isBasicFeature && String(recurringCount)}
                            onClick={onClickRecurringTasks}
                        >
                            <>
                                {TOPBAR_RECURRING_TASKS}
                                {isBasicFeature && <BasicFeatureIndicator />}
                            </>
                        </SelectListItem>
                    }
                    <SelectListItem
                        className={'reports-navigation divider'}
                        icon={'chart'}
                        iconSize={24}
                        onClick={onClickReports}
                    >
                        <>
                            Reports
                            {isBasicFeature && <BasicFeatureIndicator />}
                        </>
                    </SelectListItem>
                    <SelectListItem
                        icon={'close'}
                        iconSize={24}
                        onClick={onClose}
                    >
                        {TOPBAR_BOARD_CLOSE}
                    </SelectListItem>
                </SelectList>
            </ButtonDropdown>
        </div>
        <div
            className={'topbar__dashboard-input-div'}
            ref={divRef}
        >
            {ReactHtmlParser(boardMarkdown)}
        </div>
    </div>
    );
};
