import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../../../../../types/types';
import { TStatus } from '../../../../../../../../../types/model';
import { notificationsBeforeDueDatesForCardUpdate } from '../../../state/effects/notificationsBeforeDueDatesForCardUpdate';

export const onRemoveNotification: ActionCreator<ThunkAction> = (
    cardId: TCardId,
    notificationId: number,
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        const cardEditedState = getState().notificationBeforeDueDateEdits[cardId];
        if (cardEditedState) {
            const targetNotifications = cardEditedState.notifications;
            const newEditedState = targetNotifications.map((item) => {
                if (item.id === notificationId) {
                    return {
                        ...item,
                        status : TStatus.STATUS_DELETED
                    }
                }
                return item;
            });
            dispatch(notificationsBeforeDueDatesForCardUpdate(cardId, newEditedState));
        }
    };
    return action;
};
