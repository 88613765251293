import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { savedFilterSelector } from '../../../../../aside_panel/filterPanelBoard/store/selectors/savedFilterSelector';
import { IFilterIconHOCProps } from './types';
import { ERestCardAssigneeInfoType } from '../../../../../../../types/rest/IRestCardAssigneeInfo';
import { getAuthUser } from '../../../../../../../store/model/authUser/selectors/getAuthUser';
import { IFilterIconFields } from '../../components/FilterIcon/types';
import { FilterIcon } from '../../components/FilterIcon/FilterIcon';
import {
    filterPanelBoardsSelector
} from '../../../../../aside_panel/filterPanelBoard/store/selectors/filterPanelBoardsSelector';
import { MY_WORK_BOARD_ID } from '../../../../../main/myWorkView/constants';
import { fixOldColors } from '../../../Logo/components/constants';

const mapStateToProps = (
    state: IApplicationState,
    { filterId, icon }: IFilterIconHOCProps
): IFilterIconFields => {
    let filter = savedFilterSelector(state, filterId);
    if (!filter) {
        filter = filterPanelBoardsSelector(state, MY_WORK_BOARD_ID).editedFilter;
    }
    const { name, isPublic, author, meta } = filter;
    let photoUrl: string;
    let userName: string;
    const isDefaultAssignedToMe = meta.myWorkCards && meta.myWorkCards[0] === ERestCardAssigneeInfoType.ASSIGNED_TO_ME;
    if (isDefaultAssignedToMe) {
        const authUser = getAuthUser(state);
        photoUrl = authUser.photoUrl;
        userName = authUser.fullName;
    } else if (isPublic && author) {
        photoUrl = author.photoUrl;
        userName = author.fullName;
    }

    return {
        name,
        photoUrl,
        logo: !isPublic && (icon ? icon : fixOldColors(meta.myWorkIcon)),
        userName
    }
};

export const FilterIconHOC = connect(
    mapStateToProps,
    null
)(FilterIcon);

FilterIconHOC.displayName = 'FilterIconHOC';
