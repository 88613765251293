import * as React from 'react';
import './_MyWorkImportBoardSection.scss';
import { IMyWorkImportBoardSectionProps } from './types';
import { CLASS_MY_WORK } from '../../../../constants';
import { MY_WORK_IMPORT_BOARDS_TEXT, MY_WORK_IMPORT_BOARDS_TITLE } from './constants';
import { SettingsSection } from '../../../../../../base/components/SettingsSection/SettingsSection';
import { MyWorkBoardsHOC } from '../../hocs/MyWorkBoardsHOC/MyWorkBoardsHOC';

export const MyWorkImportBoardSection = ({
}: IMyWorkImportBoardSectionProps) => {
    const className = CLASS_MY_WORK + '-import-boards-section';
    return (
        <SettingsSection
            className={className}
            title={MY_WORK_IMPORT_BOARDS_TITLE}
        >
            <span className={className + '__text'}>
                {MY_WORK_IMPORT_BOARDS_TEXT}
            </span>
            <MyWorkBoardsHOC />
        </SettingsSection>
    );
}
