import * as React from 'react';
import './_CardChecklistItemActionButton.scss';
import { ICardChecklistItemActionButtonProps } from './types';
import { CLASS_CARD_DETAILS } from '../../../constants';
import { Button, ButtonDropdown, Icon } from 'kui';
import { isString } from 'underscore';
import {
    ID_ASIDE_PANEL_ACTIONS_PORTAL,
    ID_ASIDE_PANEL_ACTIONS_PORTAL_MOBILE
} from '../../../../asidePanel/components/AsidePanelActionsButton/constants';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { CHECKLIST_CONTROL_CLASS } from '../CardChecklistItem/constants';

export function CardChecklistItemActionButton ({
    className: ownClassName,
    buttonClassName,
    directionHorizontal,
    dropdown,
    dropdownClassName,
    icon,
    id,
    isEmpty,
    isOpen,
    label,
    notBlurClasses = [],
    onClick,
    setOpen,
    ...attributes
}: ICardChecklistItemActionButtonProps) {
    const { isMobile } = React.useContext(AsidePanelContext);

    const className = CLASS_CARD_DETAILS + '-checklist-item-action-button';

    const button = <Button
        className={`
            ${CHECKLIST_CONTROL_CLASS}
            ${className}
            ${isOpen ? className + '--active' : ''}
            ${isEmpty ? className + '--empty' : ''}
            ${ownClassName && !dropdown ? ownClassName : ''}
            ${buttonClassName ? buttonClassName : ''}
        `}
        variant={label && icon ? 'icon-text' : icon ? 'icon' : 'text'}
        text={icon && isString(label) && label}
        onClick={!dropdown && onClick}
        {...attributes}
    >
        {icon && <Icon xlink={icon} size={16}/>}
        {!icon && label}
    </Button>;

    return (
        <>
            {dropdown ?
                <ButtonDropdown
                    opened={isOpen}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    className={`${className + '__wrapper'} ${isEmpty ? className + '__wrapper--empty' : ''} ${ownClassName || ''}`}
                    directionVertical={'down'}
                    dropdownClassName={`${className + '__dropdown'} ${CHECKLIST_CONTROL_CLASS} ${dropdownClassName || ''}`}
                    onClick={onClick}
                    directionHorizontal={directionHorizontal}
                    portal
                    portalId={isMobile ? ID_ASIDE_PANEL_ACTIONS_PORTAL_MOBILE : ID_ASIDE_PANEL_ACTIONS_PORTAL}
                    notBlurClasses={notBlurClasses}
                >
                    {button}
                    {dropdown}
                </ButtonDropdown>
                : button
            }
        </>
    );
}
