import { segmentTrackAction } from '../../../../../../../middlewares/segment';
import { Dispatch, ThunkAction } from '../../../../../../../types/actions';

export const onTabBrandingActive = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        // console.log('segment onTabBrandingActive');
    };
    return action;
};
