import {Dispatch, ThunkAction} from '../../../../../../types/actions';
import { EHintKey } from '../../../constants';
import { hintHide } from '../../../effects/hintHide';

export const onHide = (
    key: EHintKey,
    ownOnHide?: () => void,
    tutorialStep?: number,
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        if (!tutorialStep) {
            dispatch(hintHide(key));
        }
        if (ownOnHide) ownOnHide();
    };
    return action;
};
