import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState } from '../../../../../../../types/types';
import { segmentTrackAction } from '../../../../../../../middlewares/segment';
import {
    ESegmentQuestionEventOptions,
    SegmentQuestionEvent
} from '../../../../../../../middlewares/segment/trackEntities/questionEvents';
import { getAuthUser } from '../../../../../../../store/model/authUser/selectors/getAuthUser';
import { IPoll } from '../../../../../../../rest/effects/umux/types';
import { IQuestion } from '../../../../../base/components/Poll/components/Poll/types';
import { authUserSetMessengerPoll } from '../../../../../../../rest/effects/authUser/authUserSetMessengerPoll';
import { authUserPostMessengerPoll } from '../../../../../../../rest/effects/messengerPoll/authUserPostMessengerPoll';
import { getMessages } from '../../../../../../../store/constants';

export const onSubmit = (
    results: IQuestion[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const date = Date.now();
        const result = results[0];
        const poll: IPoll = {};
        const extra = results[1] ? [{
                name: ESegmentQuestionEventOptions.value,
                value: results[1].answer as string
            }] : null
        dispatch(segmentTrackAction(SegmentQuestionEvent.MESSENGER_POLL_SUBMIT, {
            name: ESegmentQuestionEventOptions.answer,
            value: result.answer as string
        }, extra));

        results.forEach(({ question, answer }) => {
            if (question === '') {
                question = getMessages().getText('messenger-poll.review')
            }
            poll[String(question)] = String(answer);
        });

        dispatch(authUserSetMessengerPoll({ lastTimeShowed: date, isSkipped: false, isShowed: true }));
        dispatch(authUserPostMessengerPoll( {
            userId: getAuthUser(getState()).id,
            date,
            poll
        }))
    };
    return action;
};
