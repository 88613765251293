import { TBoardId } from '../../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { logoDelete } from '../effects/logoDelete';

export const onDelete = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        return dispatch(logoDelete(boardId));
    };
    return action;
};
