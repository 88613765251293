import { IApplicationState, TBoardId } from '../../../../../../types/types';
import { filterPanelBoardsSelector } from './filterPanelBoardsSelector';
import { initialState } from '../filter/reducers/filterReducer';
import { FP_KEY_HIDE_FILTER_EMPTY_LIST } from '../../constants';

export const getIsDefaultFilterChanged = (
    state: IApplicationState,
    boardId: TBoardId,
): boolean => {
    const { defaultFilter } = filterPanelBoardsSelector(state, boardId);
    const {hide: defaultFilterHide, ...defaultFilterRest} = defaultFilter;
    const {hide, ...initialStateFilterRest} = initialState;
    // all filters except 'hide' are equals to initial state
    const isDefaultNotEqualToInitial = JSON.stringify(defaultFilterRest) !== JSON.stringify(initialStateFilterRest);
    // ignore empty list - hide is empty or only empty list is checked
    const isDefaultHideNotChanged = defaultFilterHide.length === 0 || (defaultFilterHide.length === 1 && defaultFilterHide.includes(FP_KEY_HIDE_FILTER_EMPTY_LIST));
    return isDefaultNotEqualToInitial || !isDefaultHideNotChanged;
};
