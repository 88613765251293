import {getActivityItem} from "../react_components/aside_panel/boardDetails/activity/TabActivity/helpers/aсtivityHelper";
import {DefaultContent} from "../react_components/aside_panel/cardDetails/TabActivity/components/CardActivityCheckList/DefaultContent/DefaultContent";
import {ERestActivityPropertyActionType} from "../../types/rest/activity/IRestActivityBase";
import {RenameContent} from "../react_components/aside_panel/cardDetails/TabActivity/components/CardActivityCheckList/RenameContent/RenameContent";
import {renderToStaticMarkup} from "react-dom/server";
import * as React from "react";

App.Views.CardUpdateChecklistGroupNotification = App.Views.BaseNotification.extend({

    getActions: function() {
        const item = getActivityItem(this.notificationModel);
        const { oldValue, newValue, propertyActionType, checkListGroupName, checkListGroupProperty } = this.notificationModel;
        let content = <DefaultContent label={checkListGroupName || newValue || oldValue} />;

        if (propertyActionType === ERestActivityPropertyActionType.UPDATE) {
            if (checkListGroupProperty === 'name') {
                content = <RenameContent oldValue={oldValue} newValue={newValue}/>
            }
        }
        content = renderToStaticMarkup(content);
        if (propertyActionType === ERestActivityPropertyActionType.DELETE) {
            content = '<strike>' + content + '</strike>';
        }

        return {
            bodyRaw: content,
            action: item.label,
            actionData: {
                icon: 'checklist'
            },
        };
    },

    onClick: function(e, notification) {
        var dashboardId = this.notificationModel.dashboardId;
        var cardId = this.notificationModel.card.id;
        App.controller.cleverOpenCard(dashboardId, cardId);
        this.closeNotification(notification);
        e.stopPropagation();
    }
});