import { IApplicationState, TBoardId } from '../../../types/types';
import { getBoardListIds } from 'app/store/model/selectors/getBoardListIds';
import { getBoardCardAgingEnabled } from 'app/store/model/board/selectors/getBoardCardAgingEnabled';
import { getListCardAgingEnabled } from 'app/store/model/list/selectors/getListCardAgingEnabled';

export const isAgingEnabled = (
    state: IApplicationState,
    boardId: TBoardId,
): boolean => {
    let isListAging: boolean = false;
    const listIds = getBoardListIds(state, boardId);
    const isCardAging = getBoardCardAgingEnabled(state, boardId);
    if (!isCardAging) {
        for (let i = 0; i < listIds.length; i++) {
            const isEnabled = getListCardAgingEnabled(state, listIds[i])
            if (isEnabled) {
                isListAging = true
                break
            }
        }
    }
    return isListAging || isCardAging
};
