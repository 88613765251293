import { createDeepEqualSelector } from '../../../../../../helpers/memoizeHelper';
import { IApplicationState, TCardId, TSwimlaneId } from '../../../../../../../../types/types';
import { IList } from '../../../../../../../../store/model/list/types';
import { ICard } from '../../../../../../../../store/model/card/types';
import {
    filterPanelBoardsSelector
} from '../../../../../../aside_panel/filterPanelBoard/store/selectors/filterPanelBoardsSelector';
import { sortCards } from '../../../../../../../../store/model/helpers/sortCards';
import { TStatus } from '../../../../../../../../types/model';
import {
    getListFilteredCardsByStatus
} from '../../../../../../../../store/model/selectors/getListFilteredCardsByStatus';
import {
    getBoardCardAgingIndicatorType
} from '../../../../../../../../store/model/board/selectors/getBoardCardAgingIndicatorType';
import {
    getListCardAgingIndicatorType
} from '../../../../../../../../store/model/list/selectors/getListCardAgingIndicatorType';

type TOptions = {
    list: IList,
    status?: TStatus,
    swimlaneId?: TSwimlaneId;
}
type TGetSortedCardIds = (
    state: IApplicationState,
    options: TOptions,
) => TCardId[];

const getSortedCardIdsSelector = (
    state: IApplicationState,
    options: TOptions,
): TCardId[] => {
    const {
        list,
        status = TStatus.STATUS_ACTIVE,
        swimlaneId,
    } = options;
    const cards: ICard[] = getListFilteredCardsByStatus(state, list.id, status, swimlaneId);
    const filterPanel = filterPanelBoardsSelector(state, list.dashboardId);
    const cardAgeingType = getListCardAgingIndicatorType(state, list.id) || getBoardCardAgingIndicatorType(state, list.dashboardId);
    cards.sort(sortCards(list.sorting, filterPanel, cardAgeingType));

    return cards.map(card => card.id);
};

export const getSortedCardIdsCreateSelector = (): TGetSortedCardIds => createDeepEqualSelector(
    getSortedCardIdsSelector,
    cardIds => cardIds
);

export const getSortedCardIds: TGetSortedCardIds = getSortedCardIdsCreateSelector();
