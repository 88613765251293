import { connect } from 'react-redux';
import { IRelatedCardHOCProps } from './types';
import { IApplicationState } from '../../../../../../types/types';
import { onDelete } from './events/onDelete';
import { IRelatedCardEvents, IRelatedCardFields } from '../../components/RelatedCard/types';
import { onClick } from './events/onClick';
import { onLoad } from './events/onLoad';
import { RelatedCard } from '../../components/RelatedCard/RelatedCard';
import { getCard } from '../../../../../../store/model/selectors/getCard';

const mapStateToProps = (
    state: IApplicationState,
    { boardId, relatedCard, isReadonly }: IRelatedCardHOCProps
): IRelatedCardFields => {
    const card = getCard(state, relatedCard.cardId);
    return {
        boardId,
        cardId: card && card.id,
        relatedCard,
        isHideProgress: !card,
        isReadonly
    };
}

const mapDispatchToProps = (
    dispatch: any,
    { relatedCard, onHandleDelete }: IRelatedCardHOCProps
): IRelatedCardEvents => {
    return {
        onClick: () => dispatch(onClick(relatedCard)),
        onDelete: () => dispatch(onHandleDelete ? onHandleDelete(relatedCard) : onDelete(relatedCard)),
        onLoad: () => dispatch(onLoad(relatedCard))
    }
};

export const RelatedCardHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(RelatedCard);
RelatedCardHOC.displayName = 'RelatedCardHOC';
