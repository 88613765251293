import { EThemeBackgroundOption } from '../../../../../../../../../types/rest/IRestTheme';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { boardThemeUploadImage } from '../../../../../../../../../rest/effects/boardTheme/boardThemeUploadImage';
import { IGetState, TBoardId } from '../../../../../../../../../types/types';
import { MAX_BOARD_BG_FILE_SIZE, THEME_WRONG_FILE_SIZE_TEXT, THEME_WRONG_FILE_TYPE_TEXT } from '../constants';
import { getMessages, root } from '../../../../../../../../../store/constants';
import { ILibTheme } from '../../../../../../../../../store/model/libThemes/types';
import { getBoardTheme } from '../../../../../../../../../store/model/board/selectors/getBoardTheme';
import { SegmentBrandingEvent, segmentTrackAction } from '../../../../../../../../../middlewares/segment';
import { snackbarErrorDefault, snackbarInfoDefault } from '../../../../../../../snackbarsStack';
import { SNACKBAR_ID_BOARD_ERROR } from '../../../../../../../snackbarsStack/effects/constants';
import { IBoardTheme } from '../../../../../../../../../store/model/board/types';
import { boardSetTheme } from '../../../../../../../../../rest/effects/board/boardSetTheme';

export const themeUpload = (
    boardId: TBoardId,
    file: File,
    backgroundOption: EThemeBackgroundOption
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (!file) {
            return Promise.reject();
        }
        if (file.size > MAX_BOARD_BG_FILE_SIZE) {
            dispatch(showWrongFileSizeWarning());
            return Promise.reject();
        }
        if (!root.App.Util.fileIsImage(file.type)) {
            dispatch(showWrongFileTypeWarning());
            return Promise.reject();
        }
        const prevTheme: ILibTheme = getBoardTheme(getState(), boardId);
        return dispatch(boardThemeUploadImage(boardId, backgroundOption, file)).then((boardTheme: IBoardTheme) => {
            if (!prevTheme|| !prevTheme.userId) {
                root.App.controller.trackEvent(getMessages().getText('ga.category.dashboard'),
                    getMessages().getText('ga.action.custom_theme_defined'));
            }
            if (boardTheme && boardTheme.id) {
                dispatch(boardSetTheme(boardId, boardTheme));
                dispatch(segmentTrackAction(SegmentBrandingEvent.BACKGROUND_CUSTOM_SELECTED));
            }
        });
    };
    return action;
};

const showWrongFileSizeWarning = (): ThunkAction => {
    const action = (dispatch: Dispatch) => {
        dispatch(snackbarErrorDefault({
            id: SNACKBAR_ID_BOARD_ERROR,
            text: THEME_WRONG_FILE_SIZE_TEXT
        }));
    };
    return action;
};

const showWrongFileTypeWarning = (): ThunkAction => {
    const action = (dispatch: Dispatch) => {
        dispatch(snackbarInfoDefault({
            id: SNACKBAR_ID_BOARD_ERROR,
            text: THEME_WRONG_FILE_TYPE_TEXT
        }));
    };
    return action;
};
