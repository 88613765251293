import { IGetState, TBoardId } from './../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { commentAdd } from '../../../../../../../../rest/effects/card/comments/commentAdd';
import { IComment } from '../../../../../../../../store/model/card/types/IComment';
import {
    cardCheckActivateNotifySubscription
} from '../../../../../../../../rest/effects/card/card/cardCheckActivateNotifySubscription';
import { EPushNotificationSubscribeCases } from '../../../../../../../../types/rest/IRestPushNotificationSettings';

export const createComment = (
    boardId: TBoardId,
    comment: IComment,
): ThunkAction => {
    return (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        return dispatch(commentAdd(comment, boardId))
            .then((comment: IComment) => {
                dispatch(cardCheckActivateNotifySubscription(comment.cardId, EPushNotificationSubscribeCases.COMMENT_ADD));
                return Promise.resolve(comment.id);
            })
    }
};
