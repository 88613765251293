import * as momentTZ from 'moment-timezone';
import { ITimeZoneDetails, ITimeZones } from '../components/TimeZone/types';
import { ISearchSelectOption } from '../../../../../base/components/SearchSelect/types';

export const breakOffsetToNumber =
(
    offset: string
): number => {
    const timeOffset = offset.split(':')[0];
    const numberTimeOffset = parseInt(timeOffset, 10);
    return numberTimeOffset;
}

export const constructTimeZone =
(
    name: string,
    offset: string
) => {
    const offsetValue = breakOffsetToNumber(offset);
    const temeZoneDetails: ITimeZoneDetails = {
        name,
        offset,
        offsetValue
    }

    return temeZoneDetails;
}

export const getOffset = (timeZone: string): string => momentTZ.tz(timeZone).format('Z z');

export const getTimeZones =
(): ITimeZones => {
    const timeZoneNames = momentTZ.tz.names();

    const timeZones = timeZoneNames.reduce((accumulator, item) => {
        const timeZone = constructTimeZone(item, getOffset(item));
        accumulator.push(timeZone);
        return accumulator;
    }, []);

    return timeZones;
}

export const getTimezonesSelectOptions =
(
    timeZones: ITimeZones
): ISearchSelectOption[] => {

    const selectOptions = timeZones.map((item: ITimeZoneDetails): ISearchSelectOption => {
        return {
            text: `${item.name} ${item.offset}`,
            value: item.name
        }
    });

    return selectOptions;
}

export const getInitialTimeZone = () => {
    return momentTZ.tz.guess();
}

export const isValidTimeZone = (zoneName: string): boolean => {
    const zone = momentTZ.parseZone(undefined, zoneName);
    return !!zone;
}
