/**
 * Возвращает offsetTop элемента до элемента className
 * или до body если className не задан
 */
export const getParentsOffsetTop = (
    element: HTMLElement,
    className?: string,
): number => {
    let target = element;
    let offsetTop = 0;
    do {
        offsetTop += target.offsetTop || 0;
        target = target.offsetParent as HTMLElement;
    } while (
        target &&
        (!className || !target.classList.contains(className))
    );

    return offsetTop;
}
