import { TBoardBackupsAction } from '../../boardBackups/actions/types';
import { AT_BOARD_BACKUPS_ACTION_SET, IBoardBackupsActionSetAction } from './types';

export const backupActionSet = (
    boardId: number,
    boardBackupsAction: TBoardBackupsAction
): IBoardBackupsActionSetAction => ({
    type: AT_BOARD_BACKUPS_ACTION_SET,
    boardId,
    boardBackupsAction
});
