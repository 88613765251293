import { getAuthUser } from './getAuthUser';
import { IApplicationState } from '../../../../types/types';
import { EUserTimeformat } from '../../../../types/rest/IRestAuthUser';

export const getUserTimeFormat12 = (
    state: IApplicationState,
): boolean => {
    const user = getAuthUser(state);
    return user.timeFormat === EUserTimeformat.FORMAT_12;
};
