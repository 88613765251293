import { IList } from '../types';

export const getListUpdateMinMaxOrderNumber = (
    list: IList,
    orderNumbers: number[]
): IList => {
    if (!list) return null;

    let cardAutoDecrement = list.cardAutoDecrement || 0;
    let cardAutoIncrement = list.cardAutoIncrement || 0;
    orderNumbers.forEach(orderNumber => {
        if (orderNumber < cardAutoDecrement) {
            cardAutoDecrement = orderNumber;
        }
        if (orderNumber > cardAutoIncrement) {
            cardAutoIncrement = orderNumber;
        }
    })
    if (cardAutoDecrement !== list.cardAutoDecrement || cardAutoIncrement !== list.cardAutoIncrement) {
        return {
            cardAutoDecrement,
            cardAutoIncrement
        }
    }
    return null;
}
