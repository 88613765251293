import { Dispatch } from '../../../../../types/actions';
import { SnackbarVariant, ISnackbarControlModel } from '../../store/types';
import { addUpdateSnackbarWithBubble } from '../addUpdateSnackbarWithBubble/addUpdateSnackbarWithBubble';
import { SNACKBAR_BUTTON_RELOAD, SNACKBAR_ID_RELOAD, SNACKBAR_BUTTON_CLOSE } from '../constants';
import { SNACKBAR_RELOAD_TEXT } from './constants';
import { getReloadActionEvent } from '../helpers/getReloadActionEvent';
import { getDefaultActionEvent } from '../helpers/getDefaultActionEvent';

export const snackbarErrorReload = (
    isClosable: boolean = true
) => {
    return (dispatch: Dispatch) => {
        const closeControl = {
            action: () => dispatch(getDefaultActionEvent(SNACKBAR_ID_RELOAD)),
            title: SNACKBAR_BUTTON_CLOSE
        };
        const reloadControl = {
            action: getReloadActionEvent(),
            isPrimary: true,
            title: SNACKBAR_BUTTON_RELOAD
        };
        const controls: ISnackbarControlModel[] = [ reloadControl ];
        if (isClosable) controls.push(closeControl);
        dispatch(addUpdateSnackbarWithBubble({
            id: SNACKBAR_ID_RELOAD,
            controls: controls,
            text: SNACKBAR_RELOAD_TEXT,
            title: null,
            variant: SnackbarVariant.RELOAD
        }));
    }
};
