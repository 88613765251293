import * as React from 'react';
import { ChangeEvent } from 'react';
import './_BoardLogo.scss';
import { IBoardLogoProps } from './types';
import { Button, Icon, Loader, Switch } from 'kui';
import {
    BOARD_LOGO_EMPTY,
    BOARD_LOGO_ICON,
    BOARD_LOGO_IMAGE,
    BOARD_LOGO_LOGO,
    BOARD_LOGO_MAX_SIZE,
    BOARD_LOGO_REMOVE,
    BOARD_LOGO_RESET,
    BOARD_LOGO_UPLOAD,
} from './constants';
import { SettingsSection } from '../../../../../../base/components/SettingsSection/SettingsSection';
import { BOARD_DETAILS_ONLY_OWNER, CLASS_BOARD_BRANDING, CLASS_BOARD_DETAILS_SECTION } from '../../../../constants';
import { getAsidePanelTooltip } from '../../../../../asidePanel/helpers/getAsidePanelTooltip';
import { ESettingsSectionType } from '../../../../../../base/components/SettingsSection/types';
import { LogoSelect } from '../../../../../../base/components/Logo/components/LogoSelect/LogoSelect';
import { LOGO_COLOR_DEFAULT } from '../../../../../../base/components/Logo/components/constants';

export function BoardLogo ({
    isProFeature,
    logo,
    url,
    sectionType,
    onClickDisallow,
    onDelete,
    onDidUnmount,
    onFileSelect,
    onToggleShowIconAndColorLogo,
    onLogoChange,
}: IBoardLogoProps) {
    const isEditable = sectionType === ESettingsSectionType.DEFAULT;
    let [isLoading, _setLoading] = React.useState(false);
    const [isLogo, setLogo] = React.useState(!!url);
    const [isChanged, setChanged] = React.useState(null);
    const fileInputRef = React.useRef(null);
    const className = CLASS_BOARD_DETAILS_SECTION + ' ' + CLASS_BOARD_DETAILS_SECTION + '--logo';
    const classLogo = CLASS_BOARD_BRANDING + '__logo';
    const classLogoContainer = CLASS_BOARD_BRANDING + '__logo-container';
    const classLogoLoader = isLoading ? classLogo + '--loading' : '';
    const classLogoText = CLASS_BOARD_BRANDING + '__logo-text';
    const classButtons = CLASS_BOARD_BRANDING + '__buttons';

    const setLoading = (loading: boolean) => {
        isLoading = loading;
        _setLoading(loading);
    };

    const onUploadClick = () => {
        fileInputRef.current.click();
    }

    const onFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files.length > 0) {
            const file: File = e.target.files[0];
            setLoading(true);
            onFileSelect(file)
                .finally(() => setLoading(false));
        }
    }

    React.useEffect(() => {
        if (isLogo) return;
        setChanged(logo.icon || logo.color !== LOGO_COLOR_DEFAULT);
    }, [logo]);

    React.useEffect(() => {
        // не удаляем url на каждое переключение
        // но даем возможность смотреть изменения лого на доске (в асайд панели и топ баре)
        onToggleShowIconAndColorLogo(url && !isLogo);
    }, [isLogo]);

    React.useEffect(() => {
        return () => onDidUnmount();
    }, []);

    const isDisabled = !isEditable || isProFeature;

    return (
        <SettingsSection
            className={className}
            sectionType={sectionType}
            title={BOARD_LOGO_LOGO}
            onClickDisallow={onClickDisallow}
            isProFeature={isProFeature}
        >
            <Switch
                checked={isLogo}
                onChange={() => setLogo(!isLogo)}
                disabled={isDisabled}
            >
                <span className={CLASS_BOARD_BRANDING + '__label'}>{BOARD_LOGO_IMAGE}</span>
                {isLogo && <div className={CLASS_BOARD_BRANDING + '__subtext'}>{BOARD_LOGO_MAX_SIZE}</div>}
            </Switch>
            {isLogo &&
                <div className={classLogoContainer + '-wrapper'}>
                    <div className={classLogoContainer}>
                        <div className={`
                                ${classLogo}
                                ${classLogoLoader}
                            `}
                             style={{
                                 backgroundImage: 'url(\'' + url + '\')'
                             }}
                        >
                            {!url && !isLoading && <div className={classLogoText}>{BOARD_LOGO_EMPTY}</div>}
                            <Loader/>
                        </div>
                    </div>
                    <div className={classButtons}>
                        <Button
                            tooltip={getAsidePanelTooltip({value: BOARD_LOGO_UPLOAD})}
                            variant={'icon'}
                            onClick={onUploadClick}
                            disabled={isDisabled}
                        >
                            <Icon
                                size={24}
                                xlink={'cloud-download'}
                            />
                        </Button>
                        <Button
                            tooltip={BOARD_LOGO_REMOVE}
                            variant={'icon'}
                            onClick={onDelete}
                            disabled={isDisabled}
                        >
                            <Icon
                                size={24}
                                xlink={'delete'}
                            />
                        </Button>
                    </div>
                    <input
                        className="hidden"
                        ref={fileInputRef}
                        type="file"
                        onChange={onFileInputChange}
                    />
                </div>
            }
            <div className={classLogo + '-wrapper'}>
                <Switch
                    checked={!isLogo}
                    onChange={() => setLogo(!isLogo)}
                    disabled={isDisabled}
                >
                    <span className={CLASS_BOARD_BRANDING + '__label'}>{BOARD_LOGO_ICON}</span>
                </Switch>
                {!isLogo && isChanged && <Button
                    className={CLASS_BOARD_BRANDING + '__reset'}
                    variant={'icon-text'}
                    text={BOARD_LOGO_RESET}
                    disabled={isDisabled}
                    onClick={(e) => {
                        onLogoChange({ icon: '', color: LOGO_COLOR_DEFAULT });
                    }}
                >
                    <Icon xlink={'arrow-circle'} size={16} />
                </Button>}
            </div>
            {!isLogo &&
                <LogoSelect
                    logo={logo}
                    onChange={onLogoChange}
                    isDisabled={isDisabled}
                />
            }
            {!isEditable && <span className={CLASS_BOARD_BRANDING + '__owner'}>{BOARD_DETAILS_ONLY_OWNER}</span>}
        </SettingsSection>
    );
};
