import { IColor, TStatus } from '../../../../../../../../../types/model';

export const getAllColorsEnabled = (
    colors: IColor[]
): boolean => {
    let isEnabled = true;
    for (let i = 0; i < colors.length; i++) {
        if (colors[i].status === TStatus.STATUS_DELETED) {
            isEnabled = false;
            break;
        }
    }
    return isEnabled;
}
