import { IApplicationState, TCardId } from '../../../types/types';
import { getRequestsState } from './getRequestsState';

export const getCardActiveRequests = (
    state: IApplicationState,
    cardId: TCardId
): number => {
    const cards = getRequestsState(state).cards;
    if (!cards[cardId]) return 0;

    return cards[cardId].activeRequests || 0;
}
