export const AT_ADD_ITEM_SUBSCRIPTION_ID_SET = 'SUBSCRIPTION_ADMIN_ADD_ITEM_SUBSCRIPTION_ID_SET';
export const AT_ADD_SEATS_SUBSCRIPTION_ID_SET = 'SUBSCRIPTION_ADMIN_ADD_SEATS_SUBSCRIPTION_ID_SET';
export const AT_EDIT_SUBSCRIPTION_ID_SET = 'SUBSCRIPTION_ADMIN_EDIT_SUBSCRIPTION_ID_SET';
export const AT_EMAIL_TO_ADMINS_SUBSCRIPTION_ID_SET = 'SUBSCRIPTION_ADMIN_EMAIL_TO_ADMINS_SUBSCRIPTION_ID_SET';
export const AT_INIT = 'SUBSCRIPTION_ADMIN_INIT';
export const AT_IS_LOADING_SET = 'SUBSCRIPTION_ADMIN_IS_LOADING_SET';
export const AT_IS_SEARCH_SET = 'SUBSCRIPTION_ADMIN_IS_SEARCH_SET';
export const AT_ITEMS_SEARCH_SET = 'SUBSCRIPTION_ADMIN_ITEMS_SEARCH_SET';
export const AT_OPENED_UNITS_SET = 'SUBSCRIPTION_ADMIN_OPENED_UNITS_SET';
export const AT_PARENT_SUBSCRIPTION_ID_SET = 'SUBSCRIPTION_ADMIN_PARENT_SUBSCRIPTION_ID_SET';
export const AT_SEARCH_SET = 'SUBSCRIPTION_ADMIN_SEARCH_SET';
export const AT_SUBSCRIPTION_ID_SET = 'SUBSCRIPTION_ADMIN_SUBSCRIPTION_ID_SET';
export const AT_SUBSCRIPTIONS_ACTION_SET = 'SUBSCRIPTION_ADMIN_SUBSCRIPTIONS_ACTION_SET';
