import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { TCardId, IGetState } from '../../../../../../../../../types/types';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../../middlewares/segment';
import {
    changeRoute,
    clickManagerActionSet,
    selectedCardIdsSet
} from 'app/view/react_components/clickManager';
import { getBoardIdByCardId } from 'app/store/model/selectors/getBoardIdByCardId';
import { getSelectedCards } from 'app/view/react_components/clickManager/selectors/getSelectedCards';
import { getIsMultiselect } from 'app/view/react_components/clickManager/selectors/getIsMultiselect';
import { isRelatedPanelClosed } from 'app/view/react_components/main/kanbanView/components/Card/hocs/CardHOC/selectors/isRelatedPanelOpened';
import { relatedCardToggle } from 'app/view/react_components/aside_panel/relatedCards/effects/relatedCardToggle';

export const onCheckboxClick = (
    cardId: TCardId,
): ThunkAction => {
    return (
        dispatch: Dispatch,
        getState: IGetState
) => {
        const state = getState();
        const boardId = getBoardIdByCardId(state, cardId);
        const cardIds = getSelectedCards(state, boardId);
        const isPanelClosed = isRelatedPanelClosed(state);
        if (isPanelClosed) {
            dispatch(segmentTrackAction(SegmentCardEvent.CLICKED_ON_MULTISELECT_CHECKBOX));
            let newSelection: number[] = [];

            if (cardIds.includes(cardId)) {
                newSelection = cardIds.filter(item => {
                    if (item !== cardId) {
                        return item;
                    }
                });
            } else {
                newSelection = [...cardIds, cardId];
            }

            const isMultiselect = getIsMultiselect(state, boardId) && newSelection.length > 0;
            dispatch(clickManagerActionSet(boardId, selectedCardIdsSet(newSelection, isMultiselect)));
            dispatch(changeRoute(boardId));
        } else {
            dispatch(relatedCardToggle(cardId));
        }
    };
};
