import { IApplicationState, TBoardId, TCustomPropertyId } from '../../../../types/types';
import { IBoardCardCustomProperty } from '../../../customProperties/types';
import { getBoardCardCustomProperties } from './getBoardCardCustomProperties';

export const getBoardCardCustomProperty = (
    state: IApplicationState,
    boardId: TBoardId,
    customPropertyId: TCustomPropertyId,
): IBoardCardCustomProperty => {
    const boardCardCustomProperties = getBoardCardCustomProperties(state, boardId);

    return boardCardCustomProperties[customPropertyId];
};
