import { ActionCreator } from 'redux';
import { ThunkAction } from '../../../../../types/actions';
import { fetchHandler } from '../../../../../util/fetchHandler';
import { IRestFilter } from '../rest/types';
import Util from '../../../../../util/util';
import { getRestHeadersDelete } from '../../../../../rest/helpers/getRestHeadersHelper';

export const removeSavedFilter: ActionCreator<ThunkAction> = (
    filterId: number,
) => {
    const action = () => {
        return fetchHandler<IRestFilter>(
            Util.getApiUrl(`/rest/dashboards/filters/${ filterId }`),
            {
                ...getRestHeadersDelete(),
            }
        )
            .then(() => Promise.resolve())
            .catch(() => Promise.reject());
    };
    return action;
};
