import { GOOGLE_SPACING } from '../../../../const';

export const NP_EXPAND_TIMER = 250;
export const NP_AUTO_EXPAND_TIMER = 1000;
export const NP_AUTO_COLLAPSE_TIMER = 500;

export enum ENavigationPanelTypes {
    NP_COMPACT_TYPE = 'compact',
    NP_COMPACT_BIG_TYPE = 'compact_big', // если из compact открыли панель profile или notifications, то navPanel становится как big, но возвращается в compact, когда теряет фокус
    NP_BIG_TYPE = 'big',
    NP_MOBILE_TYPE = 'mobile',
}

// constants used from navigationPanel/containers/NavigationPanel/_NavigationPanel.scss
export enum ENavigationPanelWidths {
    'compact' = 0,
    'compact_big' = 0,
    'big' = GOOGLE_SPACING * 35,
    'mobile' = 0,
}

export const NP_DEFAULT_TYPE = ENavigationPanelTypes.NP_BIG_TYPE;

export const NP_LOCAL_STORAGE_FIELD = 'collapseStore';

export const NAVIGATION_PANEL_CONTAINER_CLASS = 'navigation-panel-container';
export const NAVIGATION_PANEL_INNER_CLASS = 'navigation-panel__inner';
export const NAVIGATION_PANEL_CLASS = 'navigation-panel';
export const NAVIGATION_PANEL_CLOSE_MARKER = 'navigation-panel__close_marker';
export const NAVIGATION_PANEL_DROPDOWN_CLASS = 'navigation-panel__dropdown';
export const NAVIGATION_PANEL_BOARDS_CLASS = 'navigation-panel__dashboards';
export const NAVIGATION_PANEL_ARROW_RIGHT_CLASS = 'navigation-panel__arrow-right';
export const NAVIGATION_PANEL_SECTION_CLASS = 'navigation-panel__section';
export const NAVIGATION_PANEL_ASIDE_CLASS = 'navigation-panel__aside';

export const NAVIGATION_PANEL_MOBILE_WIDTH = 1100; // px // css $navigationPanelBreakPoint
export const NAVIGATION_PANEL_THRESHOLD = 3 * 28; // px
export const NAVIGATION_PANEL_BOARD_SHORT_NAME = 256; // symbols
export const NAVIGATION_PANEL_BOARD_TOOLTIP_SHORT_NAME = 32; // symbols
// @ts-ignore
export const isWindowMobile = () => window._innerWidth <= NAVIGATION_PANEL_MOBILE_WIDTH;

export enum EQuickStartGuideType {
    EFFICIENCY_JOURNEY_GUIDE = 'EFFICIENCY_JOURNEY_GUIDE',
    EXPLORE_KANBANCHI = 'EXPLORE_KANBANCHI',
    KANBANCHI_CHAMPION = 'KANBANCHI_CHAMPION',
    KANBANCHI_GURU = 'KANBANCHI_GURU',
}
