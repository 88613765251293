import { getMessages } from '../../../../../../../store/constants';

export const CARD_NUMBER_REMOVE_MODAL_THIS_CARD = getMessages().getText('card_form_remove_card_number_modal_card');
export const CARD_NUMBER_REMOVE_MODAL_ALL_CARDS = getMessages().getText('card_form_remove_card_number_modal_cards');
export const CARD_NUMBER_REMOVE_MODAL_TITLE = getMessages().getText('card_form_remove_card_number_modal_title');
export const CARD_NUMBER_REMOVE_MODAL_TITLE_LOADING = getMessages().getText('card_form_remove_card_number_modal_title_loading');
export const CARD_NUMBER_REMOVE_MODAL_TEXT = getMessages().getText('card_form_remove_card_number_modal_text');
export const CARD_NUMBER_REMOVE_MODAL_TEXT_LOADING = getMessages().getText('card_form_remove_card_number_modal_title_loading');
export const CARD_NUMBER_REMOVE = getMessages().getText('card_form_remove_card_number');
export const CARD_NUMBER_GENERATE = getMessages().getText('card_form_generate_card_number');
export const CARD_DEFAULT_TEMPLATE = getMessages().getText('card_form_default_template');
export const CARD_NAME = getMessages().getText('common.card_name');
