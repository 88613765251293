import { connect } from 'react-redux';
import { onAnchorClick } from './events/onAnchorClick';
import {
    INavigationPanelAnchorMobileDispatchProps,
    INavigationPanelAnchorMobileStateProps
} from '../../components/NavigationPanelAnchorMobile/types';
import { NavigationPanelAnchorMobile } from '../../components/NavigationPanelAnchorMobile/NavigationPanelAnchorMobile';
import { IApplicationState } from '../../../../../../types/types';
import { isQualificationAvailableWithStatus } from '../../../../../../store/model/authUser/selectors/isQualificationAvailableWithStatus';
import { EUserMetaQualificationFormStatus } from '../../../../../../types/rest/IUserMeta';

const mapStateToProps = (
    state: IApplicationState
): INavigationPanelAnchorMobileStateProps => {
    const isNewFeature = isQualificationAvailableWithStatus(state, [EUserMetaQualificationFormStatus.SKIPPED]);
    return {
        isNewFeature
    }
}

const mapDispatchToProps = (
    dispatch: any,
): INavigationPanelAnchorMobileDispatchProps => ({
    onClick: () => dispatch(onAnchorClick())
});

export const NavigationPanelAnchorMobileHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(NavigationPanelAnchorMobile);
NavigationPanelAnchorMobileHOC.displayName = 'NavigationPanelAnchorMobileHOC';
