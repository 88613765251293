import { TBoardId } from '../../../../../../../../types/types';
import { ThunkAction } from '../../../../../../../../types/actions';
import { getBoardExportSettings } from '../../../../../../../../store/model/board/selectors/getBoardExportSettings';
import { boardSetExportSettings } from '../../../../../../../../rest/effects/board/boardSetExportSettings';
import { SegmentBoardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { SegmentBoardOption } from '../../../../../../../../middlewares/segment/trackEntities/boardEvents';
import { BOARD_SETTINGS_DATE_FORMATS } from '../../../components/BoardExportSettings/constants';

export const onDateFormatChange = (
    boardId: TBoardId,
    dateFormat: number
): ThunkAction => {
    return (
        dispatch,
        getState
    ) => {
        const settings = getBoardExportSettings(getState(), boardId);
        dispatch(segmentTrackAction(SegmentBoardEvent.EXPORT_DATE_FORMAT_CHANGED, {
            name: SegmentBoardOption.VALUE,
            value: BOARD_SETTINGS_DATE_FORMATS[dateFormat]
        }));
        return dispatch(boardSetExportSettings(boardId, {
            ...settings,
            dateFormat
        }))
    }
}
