import { fetchHandler } from '../../../../../util/fetchHandler';
import { Util } from '../../../../../util/util';
import { getRestHeadersPatch } from '../../../../../rest/helpers/getRestHeadersHelper';
import { IRestSubscription } from '../../../../../types/rest/IRestSubscription';
import { REST_SUBSCRIPTIONS } from './constants';
import { Dispatch, ThunkAction } from '../../store/subscriptionAdmin/types';

export const patchRest = (
    body: IRestSubscription,
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        return fetchHandler<IRestSubscription>(
            Util.getApiUrl(REST_SUBSCRIPTIONS + body.id),
            {
                ...getRestHeadersPatch(),
                body: JSON.stringify(body)
            }
        );
    };
    return action;
};
