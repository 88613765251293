import React from "react";
import { CLASS_PAGE_SHOW_LISTS_BG } from "../../const";
import { Provider } from "react-redux";
import store, { dispatch, getAppState } from "../../store/configureStore";
import { ESegmentBlockerType } from "../../middlewares/segment/trackEntities/blockersEvents";
import { SegmentArchiveEvent, segmentTrackAction } from "../../middlewares/segment";
import { getBoardCards } from '../../store/model/selectors/getBoardCards';
import { TStatus } from '../../types/model';
import { getAuthUser } from '../../store/model/authUser/selectors/getAuthUser';
import { showSnoozeBlocker } from '../react_components/base/effects/showSnoozeBlocker';
import { storeListener } from '../../store/helpers/storeListener';

export const ArchiveDashboard = App.Views.AbstractDashboard.extend({

    initialize: function(options) {
        App.Views.AbstractDashboard.prototype.initialize.call(this, options);

        this.unsubscribeAllowListener = storeListener(
            store,
            state => getAuthUser(state).allowArchive,
            this.checkPermission.bind(this)
        );
    },
    
    onImportFail: function() {
        dispatch(snackbarErrorReload(false));
        dispatch(segmentTrackAction(SegmentErrorEvent.LAZY_IMPORT_ERROR, {
            name: SegmentErrorOptions.SOURCE,
            value: 'archiveView'
        }));
    },

    render: function() {
        const ImportElement = React.lazy(() =>
            import(/* webpackChunkName: "kanban" */ '../react_components/main/archiveView/hocs/ArchiveViewHOC/ArchiveViewHOC')
                .then(module => ({default: module.ArchiveViewHOC}))
                .catch(() => this.onImportFail())
        );
        this.root.render(
            <Provider store={store}>
                <ImportElement boardId={this.boardId} />
            </Provider>
        );
        return this;
    },

    onActivated: function() {
        App.Views.AbstractDashboard.prototype.onActivated.call(this);
        $('body')
            .addClass('page--archive')
            .addClass(CLASS_PAGE_SHOW_LISTS_BG);
        App.controller.mainView.themeHelper.clearTheme();
    },

    activate: function(boardId) {
        if (this.boardId !== boardId) return;
        App.controller.showSystemLoader(true);
        App.controller.loadBoardArchiveToStore(this.boardId)
            .then(() => {
                this.toggleEmptyClass();
                App.Views.AbstractDashboard.prototype.activate.call(this, boardId);
            })
    },

    mouseScroll: function(event) {
        App.Views.AbstractDashboard.prototype.kanbanLikeScroll.call(this, event);
    },

    filterPanelToggled: function() {
        App.Views.AbstractDashboard.prototype.filterPanelToggled.call(this);
    },

    toggleEmptyClass: function() {
        const cards = getBoardCards(store.getState(), this.boardId, TStatus.STATUS_ARCHIVE);
        this.$el.toggleClass('board-view--archive-empty', !cards.length);
    },

    checkPermission: function() {
        const isBlocked = !getAuthUser(getAppState()).allowArchive;
        if (isBlocked) {
            dispatch(showSnoozeBlocker({
                blockerType: ESegmentBlockerType.ARCHIVE,
                allow: 'allowArchive'
            }));
        }
        App.controller.mainView.blockerClassToggle(isBlocked);
        const event = isBlocked ? SegmentArchiveEvent.DISALLOW : SegmentArchiveEvent.OPEN;
        dispatch(segmentTrackAction(event));
    },

    remove: function() {
        if (this.unsubscribeAllowListener) this.unsubscribeAllowListener();
        this.scrollRemove();

        App.Views.AbstractDashboard.prototype.remove.call(this);
        $('body')
            .removeClass('page--archive')
            .removeClass(CLASS_PAGE_SHOW_LISTS_BG);
    }
});
