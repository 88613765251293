import { AT_SET_FIRST, ISetFirstAction } from './types';
import { ICollaborator } from '../types';

export const setFirstAction = (
    collaborator: ICollaborator
): ISetFirstAction => {
    return {
        type: AT_SET_FIRST,
        collaborator
    }
};
