import { IGetState, TCardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { getDescriptionUnsaved } from '../../../store/cardDescriptionUnsaved/selectors/getDescriptionUnsaved';
import { cardDescriptionUnsavedDeleteAction } from '../../../store/cardDescriptionUnsaved/actions/cardDescriptionUnsavedDeleteAction';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';

export const onDeleteUnsavedDescription = (
    cardId: TCardId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        if(getDescriptionUnsaved(state, cardId)){
            dispatch(cardDescriptionUnsavedDeleteAction(cardId));
            dispatch(segmentTrackAction(SegmentCardEvent.CLEAR_UNSAVED_DESCRIPTION));
        }
    };
    return action;
};
