import { getMessages } from '../../../../../../../store/constants';

export const BOARD_EXPORT_CSV = getMessages().getText('board_settings.export.csv');
export const BOARD_EXPORT_GOOGLE_SHEET = getMessages().getText('board_settings.export.google_sheet');
export const BOARD_EXPORT_ACTIVE = getMessages().getText('board_settings.export.export_active');
export const BOARD_EXPORT_TIME_TRACKER = getMessages().getText('board_settings.export.export_time_tracker');
export const BOARD_EXPORT_CARD_TITLES_AND_DESCRIPTIONS = getMessages().getText('board_settings.export.export_card_titles_and_descriptions');
export const BOARD_EXPORT_PROGRESS = getMessages().getText('board_settings.export.progress');
export const BOARD_EXPORT_KEEP_TAB_OPEN = getMessages().getText('board_settings.export.keep_tab_open');
export const BOARD_EXPORT_CANCEL = getMessages().getText('board_settings.export.cancel');
export const BOARD_EXPORT_LINK_TITLES = getMessages().getText('dashboard_details_export__link_titles');
export const BOARD_EXPORT_MANUALLY = getMessages().getText('board_settings.export.button.manually');
export const BOARD_EXPORT_AUTOMATIC = getMessages().getText('board_settings.export.button.automatic');
export const BOARD_EXPORT_AUTOMATIC_LABEL = getMessages().getText('board_settings.export.automatic_export.label');
export const BOARD_EXPORT_AUTOMATIC_TOOLTIP = getMessages().getText('board_settings.export.automatic_export.tooltip');
export const BOARD_EXPORT_TITLE_SETTINGS = getMessages().getText('board_settings.export.export_and_google_sheet_settings');
