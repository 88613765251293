import { Dispatch, ThunkAction } from '../../../../../../types/actions';
import { IGetState } from '../../../../../../types/types';
import { root } from '../../../../../../store/constants';

export const onLater = (): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        root.App.controller.hidePromoCodePopup();
    };
    return action;
};
