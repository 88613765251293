import { Dispatch, ThunkAction } from '../../../types/actions';
import { IGetState } from '../../../types/types';
import { getAuthUser } from '../../../store/model/authUser/selectors/getAuthUser';
import { IAuthUser } from '../../../store/model/authUser/types';
import { authUserPatch } from './api/helper/authUserPatch';
import { IOneTimePoll } from '../../../types/rest/IUserMeta';

export const authUserSetMessengerPoll = (
    messengerPoll: IOneTimePoll
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const authUser = getAuthUser(getState());
        const messengerPollCurrent = authUser.meta && authUser.meta.messengerPoll || {};
        const userPatch: IAuthUser = {
            id: authUser.id,
            meta: {
                messengerPoll: { ...messengerPollCurrent, ...messengerPoll }
            }
        };
        return dispatch(authUserPatch(userPatch));
    };
    return action;
};
