/* eslint-disable */
'use strict';

import { dispatch, getAppState } from "../../store/configureStore";
import { cardActionSet } from "../../store/collaborators/modelsCollaborators/actions/cardActionSet";
import { removeAction } from "../../store/collaborators/collaborators/actions/removeAction";
import { updateAction as collaboratorsUpdateAction } from "../../store/collaborators/collaborators/actions/updateAction";
import { boardActionSet as collaboratorsBoardActionSet } from "../../store/collaborators/modelsCollaborators/actions/boardActionSet";
import { batch } from "react-redux";
import { getBoardCollaboratorBySessionId } from "../../store/collaborators/modelsCollaborators/selectors/getBoardCollaboratorBySessionId";
import { ERealtimeClassKeys, sendRealTimeVoinOccupy } from '../../view/react_components/base/helpers/realTimeHelperTS';
import { getActiveCardId } from '../../store/router/selectors/getActiveCardId';
import { boardFetchPermissions } from '../../rest/effects/board/boardFetchPermissions';
import { boardFetchUsers } from '../../rest/effects/board/boardFetchUsers';
import { boardsActionSet } from '../../store/model/actions/boardsActionSet';
import { updateAction as boardUpdateAction } from '../../store/model/board/actions/updateAction';
import { cardsUpdate } from '../../store/model/actionCreators/cardsUpdate';
import { processRestCardData } from '../../rest/effects/card/card/api/helper/processRestCardData';
import { boardActionSet } from '../../store/model/boards/actions/boardActionSet';
import { getCard } from '../../store/model/selectors/getCard';
import { getListUpdateMinMaxOrderNumber } from '../../store/model/list/selectors/getListUpdateMinMaxOrderNumber';
import { getList } from '../../store/model/list/selectors/getList';
import { listsActionSet } from '../../store/model';
import { listActionSet } from '../../store/model/lists/actions/listActionSet';
import { listUpdate as listUpdateAction } from '../../store/model/list/actions/listUpdate';
import { checkInWorkCardOnDeletedBoard } from '../../rest/effects/card/card/checkInWorkCardOnDeletedBoard';
import { getBoardPermissions } from "app/store/model/selectors/getBoardPermissions";
import { getBoardUsers } from "app/store/model/selectors/getBoardUsers";
import { SegmentBoardEvent, segmentTrackAction } from "app/middlewares/segment";

function DashboardUpdateManager(prm) {
    //this.dashboard = prm.dashboard;
    this.board = prm.board;
};

DashboardUpdateManager.prototype.updateModelByObj = function(modelWrapper) {
    if (modelWrapper.classKey === ERealtimeClassKeys.STORE_ACTION) {
        dispatch(modelWrapper.action);
    } else if (modelWrapper.classKey === 'pereklichka') {
        this.processPereklichka(modelWrapper);
    } else if (modelWrapper.classKey === 'voinLeft') {
        this.processVoinLeft(modelWrapper);
    } else if (modelWrapper.classKey === 'voinOccupy') {
        this.processVoinOccupy(modelWrapper);
    } else if (modelWrapper.classKey === 'dashboard-permission') {
        this.updatePermissionsRedux(modelWrapper.model);
    } else if (modelWrapper.classKey === 'dashboard') {
        this.processDashboardRedux(modelWrapper);
    } else if (modelWrapper.classKey === ERealtimeClassKeys.CARD) {
        this.processCardRedux(modelWrapper);
    } else if (modelWrapper.classKey === 'dashboard-deleted') {
        this.processDashboardDeleted(modelWrapper);
    }
};

DashboardUpdateManager.prototype.updatePermissionsRedux = function(model) {
    if (this.board.id == model.id) {
        const state = getAppState();
        const boardPermissions = getBoardPermissions(state, this.board.id);
        const publicPermissions = boardPermissions ? {...boardPermissions.publicPermissions} : {};
        const users = [...getBoardUsers(state, this.board.id)];

        const promises = [
            dispatch(boardFetchPermissions(this.board.id)),
            dispatch(boardFetchUsers(this.board.id)),
        ];

        Promise.all(promises)
            .then((results) => {
                if (App.controller.getDashboardViewManager().getIsInitiatorBoardUpdatePermissions()) {
                    App.controller.getDashboardViewManager().setIsInitiatorBoardUpdatePermissions(false);
                    if (results && results.length && results.length > 1) {
                        let newPublicPermissions = results[0] && results[0].publicPermissions;
                        let newUsers = results[1] || [];
                        let isShared = false;

                        if (
                            newPublicPermissions.type && !publicPermissions.type || // дали доступ всем или домену
                            newUsers.length > users.length // добавили юзера
                        ) {
                            isShared = true;
                        } else { // могли и добавить, и удалить
                            newUsers.forEach(user => { // перебрать всех юзеров, есть ли новый
                                if (!users.find(item => item.permissionId === user.permissionId)) { // новый юзер
                                    isShared = true;
                                }
                            });
                        }
                        if (isShared) {
                            dispatch(segmentTrackAction(SegmentBoardEvent.BOARD_SHARED, {
                                name: 'type',
                                value: newPublicPermissions.type || 'person',
                            }));
                        }
                    }
                }
            });
    }
},

DashboardUpdateManager.prototype.processDashboardRedux = function(modelWrapper) {
    const model= modelWrapper.model;
    if(model.lockDate === 'null') {
        model.lockDate = null;
    }
    dispatch(boardsActionSet(boardActionSet(this.board.id, boardUpdateAction(model))));
},

DashboardUpdateManager.prototype.processDashboardDeleted = function(modelWrapper) {
    const model = modelWrapper.model;
    dispatch(checkInWorkCardOnDeletedBoard(model.id));
},

DashboardUpdateManager.prototype.processCardRedux = function(modelWrapper) {
    dispatch(processRestCardData(modelWrapper.model));
    dispatch(cardsUpdate({
        [modelWrapper.model.id]: modelWrapper.model
    }));
    const card = getCard(getAppState(), modelWrapper.model.id);
    const list = card && getList(getAppState(), card.listId);
    if (card && list) {
        // пришла карта по риалтайму, которая могла быть создана на бекенде,
        // у листа cardAutoDecrement и cardAutoIncrement обновятся на бекенде
        // обновляем их в сторе
        const update = getListUpdateMinMaxOrderNumber(list, [card.orderNumber]);
        if (update) {
            dispatch(listsActionSet(listActionSet(card.listId, listUpdateAction(update))))
        }
    }
};

DashboardUpdateManager.prototype.processPereklichka = function(modelWrapper) {
    //console.log("processPereklichka" , modelWrapper);
    dispatch(sendRealTimeVoinOccupy(this.board.id, getActiveCardId(getAppState())))
};

DashboardUpdateManager.prototype.processVoinLeft = function(modelWrapper) {
   // console.log("processVoinLeft" , modelWrapper);
    this.onCollaboratorLeft(modelWrapper.sessionId);
};

DashboardUpdateManager.prototype.processVoinOccupy = function(modelWrapper) {
   // console.log("processVoinOccupy" , modelWrapper);
    const collaboratorModel = modelWrapper.model.collaborator;
    batch(() => {
        const collaborator = {
            ... collaboratorModel,
            activeCardId: modelWrapper.model.cardId
        };
        if (modelWrapper.model.prevCardId) {
            dispatch(cardActionSet(modelWrapper.model.prevCardId, removeAction(collaborator)));
        }
        if (modelWrapper.model.cardId) {
            dispatch(cardActionSet(modelWrapper.model.cardId, collaboratorsUpdateAction(collaborator)));
        }
        dispatch(collaboratorsBoardActionSet(this.board.id, collaboratorsUpdateAction(collaborator)));
    })
};

/**
 * Мы знаем кто покинул наши ряды, то нам приедим его sessionId
 * @param sessionId
 */
DashboardUpdateManager.prototype.onCollaboratorLeft = function (sessionId) {
    batch(() => {
        const collaborator = getBoardCollaboratorBySessionId(getAppState(), this.board.id, sessionId);
        if (collaborator) {
            if (collaborator.activeCardId) {
                dispatch(cardActionSet(collaborator.activeCardId, removeAction(collaborator)));
            }
            dispatch(boardActionSet(this.board.id, removeAction(collaborator)));
        }
    })
};

export default DashboardUpdateManager;
