import * as React from 'react';
import { connect } from 'react-redux';
import { IAddNotificationDispatchProps, IAddNotificationStateProps } from '../../components/AddNotification/types';
import { AddNotification } from '../../components/AddNotification/AddNotification';
import { onAddBellClicked } from './events/onAddBellClicked';
import { IAddNotificationsOwnProps } from './types';
import { IApplicationState } from '../../../../../../../../types/types';
import {
    getShowProFeaturesTrial
} from '../../../../../../../../store/model/authUser/selectors/getShowProFeaturesTrial';
import { getIsUserBasicPlanOnly } from '../../../../../../../../store/model/authUser/selectors/getIsUserBasicPlanOnly';

const mapStateToProps = (
    state: IApplicationState
): IAddNotificationStateProps => {
    return {
        isBasicFeature: getIsUserBasicPlanOnly(state),
        isProFeature: getShowProFeaturesTrial(state)
    }
}

const mapDispatchToProps = (
    dispatch: any,
    { cardId }: IAddNotificationsOwnProps,
): IAddNotificationDispatchProps => {
    return {
        onAddBellClicked: () => dispatch(onAddBellClicked(cardId)),
    }
};

export const AddNotificationHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(AddNotification);

AddNotificationHOC.displayName = 'AddNotificationHOC';
