import { IMyWorkBoardsSearchSelectProps } from './types';
import * as React from 'react';
import { Button, Icon } from 'kui';
import './_MyWorkBoardsSearchSelect.scss';
import { SearchSelect } from '../../../../../../base/components/SearchSelect/SearchSelect';
import { MyWorkBoardsAdd } from '../MyWorkBoardsAdd/MyWorkBoardsAdd';
import { CLASS_MY_WORK } from '../../../../constants';
import { MY_WORK_BOARD_ADD, MY_WORK_BOARD_UPDATES_HINT } from './constants';
import { ListItem } from '../../../../../../base/components/ListItem/ListItem';
import { UpdateCounter } from '../../../../../../base/components/UpdatesCounter/UpdateCounter';
import { EHintKey, HintHOC } from '../../../../../../hints';
import { GOOGLE_SPACING } from '../../../../../../../../const';

export function MyWorkBoardsSearchSelect ({
    availableElements,
    updatesCount,
    onResetCounter,
    onSelect
}: IMyWorkBoardsSearchSelectProps) {
    const className = CLASS_MY_WORK + '-import-boards__search';
    const classUpdates = className + '-updates';

    const [isOpened, setOpened] = React.useState(false);

    const onOpenHandler = () => {
        setOpened(true);
        onResetCounter();
    };

    const onCloseHandler = () => {
        setOpened(false);
    };

    const updates = <>
        <HintHOC
            className={className + '-hint'}
            hintKey={EHintKey.MY_WORK_IMPORT_CARDS_UPDATES}
            value={MY_WORK_BOARD_UPDATES_HINT}
            arrow={'down'}
            direction={'up'}
            show
            arrowTranslate={{ right: GOOGLE_SPACING * 3.5 }}
            translate={{ top: -GOOGLE_SPACING }}
            selector={'.' + classUpdates}
        />
        <UpdateCounter updates={updatesCount} className={classUpdates} />
    </>;

    return (
        <div
            className={`
                ${className}
                ${isOpened ? className + '--opened' : ''}
            `}
        >
            {isOpened ?
                <>
                    <SearchSelect
                        isCloseOnClick={false}
                        multiple
                        options={availableElements}
                        option={MyWorkBoardsAdd}
                        single={false}
                        onClosed={onCloseHandler}
                        onSelect={onSelect}
                        isFitWindow
                        autoFocus
                        opened={isOpened}
                    />
                    <Button
                        className={className + '-arrow'}
                        variant={'icon'}
                        onClick={onCloseHandler}
                    >
                        <Icon
                            xlink={isOpened ? 'arrow-up' : 'arrow-down'}
                            size={24}
                        />
                    </Button>
                </>
                : <ListItem
                    className={className + '-add'}
                    text={MY_WORK_BOARD_ADD}
                    img={<Icon xlink={'plus'} size={24} />}
                    onClick={onOpenHandler}
                    actions={updatesCount ? updates : null}
                />
            }
        </div>
    )
}
