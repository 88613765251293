import { IApplicationState, TCardId } from 'app/types/types';
import { getCard } from './getCard';
import { ICalendarEvent } from '../card/types/ICalendarEvent';

export const getCardCalendarEvents = (
    state: IApplicationState,
    cardId: TCardId,
): ICalendarEvent[] => {
    const card = getCard(state, cardId);
    if (
        !card ||
        !card.calendarEvents ||
        !card.calendarEvents.length
    ) return [];

    return card.calendarEvents;
};
