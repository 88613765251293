import { IApplicationState } from '../../../../../../../../types/types';
import * as React from 'react';
import {
    getShowOnBarSortedSavedFilterIds
} from '../../../../../../aside_panel/filterPanelBoard/components/SavedSection/hocs/selectors/getShowOnBarSortedSavedFilterIds';
import {
    savedFilterSelector
} from '../../../../../../aside_panel/filterPanelBoard/store/selectors/savedFilterSelector';

export const getShowOnBarEnabledFilterCount = (
    state: IApplicationState
) => {
    let enabledFilterCount = 0;
    const filterIds = getShowOnBarSortedSavedFilterIds(state);
    for (let i = 0; i < filterIds.length; i++) {
        const { isEnabled } = savedFilterSelector(state, filterIds[i]);
        if (isEnabled) {
            enabledFilterCount++;
        }
    }
    return enabledFilterCount;
}
