import { root } from 'app/store/constants';
import { Dispatch, ThunkAction } from 'app/types/actions';

export function onClickHelp(
    closeProfile: () => void
): ThunkAction {
    const action = (
        dispatch: Dispatch
    ) => {
        closeProfile();
        setTimeout(() => { // передать фокус в панель после закрытия дропдауна
            root.App.router.navigate(root.App.router.getModalUrl('help'), {trigger: true});
        }, 300);
    };
    return action
};
