import { IKeyboardShortcutsOpenerProps } from './types';
import * as React from 'react';
import { EVENT_KEY_SLASH } from '../../../../../const';

export const KeyboardShortcutsOpener = ({
    onOpen
}: IKeyboardShortcutsOpenerProps) => {
    React.useEffect(() => {
        function onOpenKeyboardShortcuts(e: KeyboardEvent){
            if (
                e.key === EVENT_KEY_SLASH &&
                (e.ctrlKey || e.metaKey)
            ) {
                e.preventDefault();
                onOpen();
            }
        }

        document.addEventListener('keydown', onOpenKeyboardShortcuts);
        return () => document.removeEventListener('keydown', onOpenKeyboardShortcuts);
    }, []);

    return <div />;
}
