import { IApplicationState } from '../../../../../../../types/types';
import { ICommentsListEvents, ICommentsListFields } from '../../../CommentsSection/components/CommentsList/types';
import { getCardComments } from '../../../../../../../store/model/selectors/getCardComments';
import { connect } from 'react-redux';
import { CommentsList } from '../../../CommentsSection/components/CommentsList/CommentsList';
import { ITabTimingCommentsListHOCProps } from './types';
import { onEdit } from './events/onEdit';
import { getAuthUser } from '../../../../../../../store/model/authUser/selectors/getAuthUser';

const mapStateToProps = (
    state: IApplicationState,
    ownProps: ITabTimingCommentsListHOCProps
): ICommentsListFields => {
    const {cardId} = ownProps;
    let comments = getCardComments(state, cardId)
        .filter(comment => comment.timeTracker);
    const authUser = getAuthUser(state);

    return {
        authUserHash: authUser.hash,
        cardId,
        comments,
        isReadonly: true
    };
};

const mapDispatchToProps = (
    dispatch: any,//todo check Dispatch not work
): ICommentsListEvents => {
    return {
        onEdit: () => dispatch(onEdit())
    };
};

export const TabTimingCommentsListHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CommentsList);

TabTimingCommentsListHOC.displayName = 'CommentsListHOC';
