'use strict';

import { getActiveBoard } from '../../store/model/selectors/getActiveBoard';
import { getAppState } from '../../store/configureStore';

App.Views.CardMoveNotification = App.Views.BaseNotification.extend({

    getContent: function() {
        const board = getActiveBoard(getAppState());
        return {
            boardName: board && board.name,
        };        
    },

    onClick: function(e, notification) {
        var dashboardId = this.notificationModel.newDashboardId;
        var cardId = this.notificationModel.cardId;
        App.controller.cleverOpenCard(dashboardId, cardId);
        this.closeNotification(notification);
        e.stopPropagation();
    }
});
