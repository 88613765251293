import * as React from 'react';
import './_TimeButton.scss';
import { ITimeButtonProps, ITimeOption } from './types';
import { Button, ButtonDropdown, Input } from 'kui';
import { CLASS_DATEPICKER } from '../constants';
import { DateItem } from '../DateItem/DateItem';
import { getTwoDigitValue } from '../../helpers/getTwoDigitValue';
import { getHourValue } from '../../helpers/getHourValue';
import { getTimeOptions } from './helpers/getTimeOptions';
import { MAX_HOURS, MAX_MINUTES, TIME_OPTION_HEIGHT } from './constants';
import { ListItem } from '../../../ListItem/ListItem';
import { EDateType } from '../../../../../../../const';
import { v4 as uuidv4 } from 'uuid';
import { DatepickerContext } from '../Datepicker/constants';
import * as moment from 'moment/moment';

export const TimeButton = ({
    date,
    dateType,
    hoursType,
    isTimeEditing,
    onDateChange,
    onSetFocus,
    onTimeOpened,
    resetTimeEditing
}: ITimeButtonProps) => {
    const { isReadonly, dropdownDirectionY, portalId } = React.useContext(DatepickerContext);

    const className = CLASS_DATEPICKER + '__time-button';
    const [classUnique] = React.useState(className + '--' + uuidv4());
    const classOption = className + '-option';
    const classSelected = classOption + '--selected';
    const classDropdown = className + '-dropdown';
    const [classUniqueDropdown] = React.useState(classDropdown + '--' + uuidv4());
    const classButton = className + '-items';
    const classDropdownType = classDropdown + '--' + (dateType === EDateType.DUE_DATE ? 'to' : 'from');

    const [isOpened, setOpened] = React.useState(null);
    const [hour, setHour] = React.useState(null);
    const [minutes, setMinutes] = React.useState(null);
    let [timeOptions, _setTimeOptions] = React.useState<ITimeOption[]>();
    const [input, setInput] = React.useState(null);
    const [active, setActive] = React.useState(null);
    const [isEditing, setEditing] = React.useState(null);
    const [isItemEditing, setItemEditing] = React.useState(null);
    const [inputValue, setInputValue] = React.useState(null);
    let [dropdownPart, _setDropdownPart] = React.useState(null);
    const [isSaveItemsOnEnter, setSaveItemsOnEnter] = React.useState(null);

    const optionsRef = React.useRef(null);
    const timerRef = React.useRef(null);
    const prevTimeRef = React.useRef(null);
    const escRef = React.useRef(null);

    const setTimeOptions = (options: ITimeOption[]) => {
        timeOptions = options;
        _setTimeOptions(options);
    };

    const setDropdownPart = (value: number) => {
        dropdownPart = value;
        _setDropdownPart(value);
    };

    const onOpen = () => {
        if (onTimeOpened) onTimeOpened();
        setOpened(true);
        onSetFocus(true);
        prevTimeRef.current = date;
    };

    const onClose = (isEdit: boolean = true) => {
        if (!escRef.current && isEdit && isItemEditing && input && input.length < 4) {
            const newDate = new Date(date);
            let newHours = Number(input.slice(0, 2) + (input.length < 2 ? hour[1] : ''));
            const dateWithNewHours = new Date();
            if (newHours > MAX_HOURS) {
                newHours = hoursType ? 11 : 23;
            }
            dateWithNewHours.setHours(newHours);
            let newMinutes = Number(input[2] ? input[2] + minutes[1] : minutes);
            if (newMinutes > MAX_MINUTES) {
                newMinutes = 59;
            }
            newDate.setHours(
                getHourValue(dateWithNewHours, hoursType),
                newMinutes
            );
            onDateChange(newDate);
        }
        setOpened(false);
        setInput(null);
        setItemEditing(false);
        onSetFocus(false);
        escRef.current = false;
    };

    const onTimeType = (e: React.KeyboardEvent) => {
        if (e.key === 'Escape') {
            onDateChange(prevTimeRef.current);
            escRef.current = true;
            return;
        }
        if (e.key === 'Enter' && isSaveItemsOnEnter) {
            onClose(true);
            return;
        }
        if (!isOpened || !/^\d+$/.test(e.key)) return;
        const idx = input ? input.length : 0;
        if (idx === 3) {
            const newDate = new Date(date);
            const dateWithNewHours = new Date();
            dateWithNewHours.setHours(Number(hour));
            let newMinutes = input[input.length - 1] + e.key;
            if (Number(newMinutes) > MAX_MINUTES) {
                newMinutes = '59';
                setMinutes(newMinutes);
            }
            newDate.setHours(
                getHourValue(dateWithNewHours, hoursType),
                Number(newMinutes)
            );
            onDateChange(newDate);
            onClose(false);
            setSaveItemsOnEnter(false);
        } else {
            if (idx === 0) {
                setHour(e.key + hour.slice(1));
            } else if (idx === 1) {
                let newHours = input + e.key;
                if (Number(newHours) > MAX_HOURS) {
                    newHours = hoursType ? '11' : '23';
                }
                setHour(newHours);
            } else if (idx === 2) {
                setMinutes(e.key + minutes.slice(1));
            }
            setInput(input ? input + e.key : e.key);
        }
        setSaveItemsOnEnter(true);
    };

    const onKeyDown = (e: React.KeyboardEvent) => {
        onTimeType(e);
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            if (active < timeOptions.length - 1) {
                setActive(active + 1);
                setSaveItemsOnEnter(false);
            }
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            if (active > 0) {
                setActive(active - 1);
                setSaveItemsOnEnter(false);
            }
        }
    };

    const onChange = (date: Date) => {
        date.setHours(getHourValue(date, hoursType));
        onDateChange(date);
        onClose(false);
    };

    const onEnter = (
        e: React.KeyboardEvent,
        date: Date
    ) => {
        if (e.key === 'Enter' && !isSaveItemsOnEnter) {
            onChange(date);
        }
    };

    const onClick = (e: React.SyntheticEvent) => {
        if (timerRef.current) clearTimeout(timerRef.current);
        const button = document.querySelector('.' + classUnique + ' .' + classButton);
        if (!button) return;
        if (button.children[0] === e.target) {
            setDropdownPart(0);
            setInput(null);
        } else {
            setDropdownPart(1);
            setInput(hour);
        }
        timerRef.current = setTimeout(() => {
            setItemEditing(true);
        }, 200);
    };

    const onDblClick = () => {
        if (timerRef.current) clearTimeout(timerRef.current);
        setEditing(true);
    };

    const onInputBlur = () => {
        setEditing(false);
        onSetFocus(false);
        const parsedDate = moment(inputValue.trim(), ['h:m a', 'H:m']);
        if (parsedDate && parsedDate.isValid()) {
            const newDate = new Date(parsedDate.valueOf());
            onChange(newDate);
        }
    };

    const onInputEnter = () => {
        onInputBlur();
    };

    const onInputEsc = (e: React.KeyboardEvent) => {
        if (e.key === 'Escape') {
            setEditing(false);
            onDateChange(prevTimeRef.current);
        }
    };

    const onInputChange = (e: React.SyntheticEvent) => {
        const { value } = e.target as HTMLInputElement;
        setInputValue(value);
    };

    React.useEffect(() => {
        setHour(getTwoDigitValue(date.getHours(), !!hoursType));
        setMinutes(getTwoDigitValue(date.getMinutes()));
    }, [date, hoursType]);

    React.useEffect(() => {
        if (isEditing || isOpened) return;
        setEditing(isTimeEditing);
        resetTimeEditing();
    }, [isTimeEditing]);

    React.useEffect(() => {
        if (isOpened) {
            setTimeout(() => {
                const dropdown = document.querySelector('.' + classDropdown + ' .kui-dropdown__item') as HTMLElement;
                if (dropdown) {
                    const newTimeOptions = getTimeOptions(date, hoursType, dropdownPart === 1);
                    setTimeOptions(newTimeOptions);
                    const idx = timeOptions.findIndex(item => item.value.getTime() === date.getTime());
                    setActive(idx);
                    setTimeout(() => {// wait for focus
                        const options = optionsRef.current as HTMLElement;
                        if (options) {
                            const element = options.children[idx] as HTMLElement;
                            if (element) {
                                element.focus();
                            }
                        }
                        dropdown.scrollTop = idx * TIME_OPTION_HEIGHT - dropdown.offsetHeight * 0.5 + TIME_OPTION_HEIGHT * 0.5;
                    }, 100);
                }
            }, 0);
        }
    }, [isOpened]);

    React.useEffect(() => {
        const options = optionsRef.current as HTMLElement;
        if (options) {
            const element = options.children[active] as HTMLElement;
            if (element) {
                element.focus();
            }
        }
        const dropdown = document.querySelector('.' + classDropdown + ' .kui-dropdown__item') as HTMLElement;
        if (dropdown) {
            dropdown.scrollTop = active * TIME_OPTION_HEIGHT - dropdown.offsetHeight * 0.5 + TIME_OPTION_HEIGHT * 0.5;
        }
    }, [active]);

    React.useEffect(() => {
        if (isEditing && date) {
            onSetFocus(true);
            setInputValue(`${getTwoDigitValue(date.getHours(), !!hoursType)}:${getTwoDigitValue(date.getMinutes())}`)
        }
    }, [isEditing]);

    return (
        <>
            {isEditing ?
                <Input
                    className={className + '-input'}
                    autosize={false}
                    autoFocus
                    onBlur={onInputBlur}
                    onChange={onInputChange}
                    onEnter={onInputEnter}
                    onKeyDown={onInputEsc}
                    value={inputValue}
                />
                :
                <ButtonDropdown
                    className={`
                        ${className}
                        ${classUnique}
                    `}
                        dropdownClassName={`
                        ${classDropdown}
                        ${classDropdownType}
                        ${classUniqueDropdown}
                    `}
                    opened={isOpened}
                    onOpen={onOpen}
                    onClose={onClose}
                    disabled={isReadonly}
                    directionVertical={dropdownDirectionY}
                    onClick={onClick}
                    onDoubleClick={onDblClick}
                    dontChangeFocus
                    isFitWindow
                    notBlurClasses={[CLASS_DATEPICKER + '__hour-switch']}
                    portal
                    portalId={portalId}
                >
                    <Button
                        className={classButton}
                        variant={'text'}
                    >
                        <DateItem
                            value={hour}
                            isFocused={isItemEditing && isOpened && (!input || input.length <= 1)}
                        />
                        {hour && ':'}
                        <DateItem
                            value={minutes}
                            isFocused={isItemEditing && isOpened && input && (input.length >= 2)}
                        />
                    </Button>
                    <div ref={optionsRef} onKeyDown={onKeyDown}>
                        {timeOptions && timeOptions.map(time => (
                            <ListItem
                                key={time.text}
                                tabIndex={0}
                                className={`
                                    ${classOption}
                                    ${time.value.getTime() === date.getTime() ? classSelected : ''}
                                `}
                                actions={null}
                                text={time.text}
                                onClick={() => onChange(time.value)}
                                onKeyDown={e => onEnter(e, time.value)}
                            />
                        ))}
                    </div>
                </ButtonDropdown>
            }
        </>
    );
}
