import * as React from 'react';
import './_CardChecklistItem.scss';
import { CLASS_CARD_DETAILS } from '../../../constants';
import { ListItem } from '../../../../../base/components/ListItem/ListItem';
import { Button, Checkbox, Icon, Input } from 'kui';
import { TChecklistItemId } from '../../../../../../../types/types';
import { ICardChecklistItemProps } from './types';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { blockMarkdown } from '../../../../../../../helper/markdownHelper';
import { getAsidePanelTooltip } from '../../../../asidePanel/helpers/getAsidePanelTooltip';
import { CHECKLIST_CONTROL_CLASS, CHECKLIST_LABEL, CHECKLIST_LABEL_CLOSE, CHECKLIST_SAVE_BUTTON } from './constants';
import { CLASS_LIST_ITEM_CONTAINER } from '../../../../../base/components/ListItem/constants';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { EAsidePanelType } from '../../../../asidePanel/components/AsidePanel/types';
import { CardChecklistItemActionsHOC } from '../../hocs/CardChecklistItemActionsHOC/CardChecklistItemActionsHOC';
import { RIGHT_ASIDE_PANEL_SELECTOR } from '../../../../asidePanel/constants';
import ReactHtmlParser from 'react-html-parser';
import { getParentsClasses } from '../../../../../helpers/getParentsClasses';
import { CardChecklistAuthor } from '../CardChecklistAuthor/CardChecklistAuthor';
import { useCombinedRefs } from '../../../../../helpers/useCombinedRefs';
import { AnalyzeRender } from '../../../../../helpers/memoizeHelper';

//@ts-ignore
const analyzeRender = window.Settings.development ? new AnalyzeRender(`CardChecklistItem`) : null;

export function CardChecklistItem ({
    asidePanelWidth,
    checklistId,
    draggableProvided = null,
    isDrag,
    isHideDoneChecklistItems,
    isShowAuthor,
    isShowWeights,
    item,
    sectionType,
    onItemSave,
    onToggle,
}: ICardChecklistItemProps) {
    const { type, boardId, cardId } = React.useContext(AsidePanelContext);
    const { name, id, checked, temp, startDate, dueDate, estimate, author, assignees } = item;

    if (analyzeRender) analyzeRender.call(id);

    const isTemplate = type === EAsidePanelType.CARD_TEMPLATE; // в темплэйте нельзя зачекать галку
    const isReadonly = sectionType === ESettingsSectionType.READONLY;

    const className = CLASS_CARD_DETAILS + '__checklist-item';
    const classLabel = className + '-name';
    const uniqueClass = className + '--' + id;

    const [isLoading, _setLoading] = React.useState<TChecklistItemId[]>([]);
    const [width, setWidth] = React.useState(null);
    const [height, setHeight] = React.useState(null);
    const [newName, setNewName] = React.useState(name);
    const [isEditing, setEditing] = React.useState(null);
    const [isHide, setHide] = React.useState(null);
    let [isActionsOpen, _setActionsOpen] = React.useState(null);
    const [s] = React.useState<any>({});
    s.isLoading = isLoading;

    const draggable = draggableProvided
        ? {
            ...draggableProvided.draggableProps,
            ...draggableProvided.dragHandleProps
        }
        : {};
    const _containerRef = React.useRef(null);
    const containerRef = useCombinedRefs(_containerRef, draggableProvided ? draggableProvided.innerRef : null);
    const inputRef = React.useRef(null);
    const textRef = React.useRef(null);

    const setActionsOpen = (open: boolean) => {
        isActionsOpen = open;
        _setActionsOpen(open);
    };

    const onChange = (
        e: React.SyntheticEvent,
    ) => {
        const { value } = e.target as HTMLInputElement;
        setNewName(value);
    };

    const onSave = () => {
        if (newName && newName.trim() && name !== newName) {
            return onItemSave({
                id,
                name: newName
            });
        } else {
            setNewName(name);
        }
    };

    const onBlur = () => {
        setHide(true);
        setTimeout(() => { // wait animation end
            setEditing(false);
            saveIfInCardPanel();
            setHide(false);
        }, 250);
    };

    const onCancel = () => {
        setHide(true);
        setTimeout(() => { // wait animation end
            setEditing(false);
            setNewName(name);
            const item = document.querySelector('.' + CLASS_LIST_ITEM_CONTAINER + '--' + id) as HTMLElement;
            if (item) {
                item.focus();
            }
            setHide(false);
        }, 250);
    };

    const onEnter = () => {
        setHide(true);
        setTimeout(() => { // wait animation end
            setEditing(false);
            onSave();
            const item = containerRef.current as HTMLElement;
            if (item) {
                item.focus();
            }
            setHide(false);
        }, 250);
    };

    const onKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Backspace' || e.key === 'Delete') {
            e.stopPropagation();
            return;
        }
        if (e.key === 'Escape') {
            e.preventDefault();
            e.stopPropagation();
            onCancel();
            return;
        }
        if (e.key === 'Enter') {
            if (e.shiftKey) { // new line on Shift+Enter
            } else {
                e.preventDefault();
                onEnter();
            }
            return;
        }
    };

    const onKeyDownContainer = (e: React.KeyboardEvent) => {
        if (isEditing || isReadonly) return;

        if (e.key === 'Enter') {
            setEditing(true);
        }
    };

    const setLoading = (
        loading: boolean = true,
    ) => {
        const index = s.isLoading.indexOf(id);

        if (
            loading && index > -1 ||
            !loading && index === -1
        ) return;

        if (loading) {
            _setLoading([...s.isLoading, id]);
        } else {
            s.isLoading.splice(index, 1);
            _setLoading([...s.isLoading]);
        }
    }

    const saveIfInCardPanel = () => {
        const activeElement = document.activeElement as HTMLElement;
        if (
            !activeElement ||
            !activeElement.closest(RIGHT_ASIDE_PANEL_SELECTOR) // фокус не в панели
        ) {
            return;
        }
        onSave();
    };

    const onClick = (e: React.SyntheticEvent) => {
        if (isReadonly) return;
        const { tagName } = e.target as HTMLElement;
        if (tagName && tagName.toLowerCase() === 'a') return;
        const parentClasses = getParentsClasses(e.target as HTMLElement, [
            CHECKLIST_CONTROL_CLASS,
        ]);
        if (parentClasses.includes(CHECKLIST_CONTROL_CLASS)) return;
        setEditing(true);
    };

    React.useEffect(() => {
        document.removeEventListener('click', onClickOutside);

        if (!isEditing) return;
        function onClickOutside(e: MouseEvent) {
            const className = CLASS_LIST_ITEM_CONTAINER + '--' + id;
            const classDates = CLASS_CARD_DETAILS + '-checklist-item__actions-dates-dropdown--' + id;
            const parentClasses = getParentsClasses(e.target as HTMLElement, [
                className,
                classDates
            ]);
            if (parentClasses.includes(className) || parentClasses.includes(classDates) || isActionsOpen) return;
            setHide(true);
            setTimeout(() => { //wait animation end
                setEditing(false);
                setHide(false);
            }, 250);
        };
        setTimeout(() => document.addEventListener('click', onClickOutside), 0); // ignore initial click
        return () => document.removeEventListener('click', onClickOutside);
    }, [isEditing, isActionsOpen]);

    React.useEffect(() => {
        if (isEditing) return;
        setTimeout(() => saveIfInCardPanel()); // нужно подождать, куда уйдёт фокус
    }, [isEditing]);

    React.useEffect(() => {
        if (!isEditing || isActionsOpen) return;
        setTimeout(() => { // wait for datepicker dropdown open
            const className = CLASS_CARD_DETAILS + '-checklist-item__actions-dates-dropdown--' + id;
            const parentClasses = getParentsClasses(document.activeElement as HTMLElement, [
                className
            ]);
            if (parentClasses.includes(className) || isActionsOpen) return;
            const input = inputRef.current && inputRef.current.getInput();
            if (input) {
                input.focus();
            }
        }, 200);
    }, [isEditing, isActionsOpen]);

    React.useEffect(() => {
        let width = 0;
        const container = containerRef.current as HTMLElement;
        if (!container) return;

        if (
            !isEditing &&
            !startDate &&
            !dueDate &&
            !estimate &&
            (assignees && assignees.length || isShowWeights)
        ) {
            const actions = container.querySelector(`.${CLASS_CARD_DETAILS}-checklist-item__actions-right`) as HTMLElement;
            if (actions) {
                width += actions.offsetWidth;
            }
        }
        if (isShowAuthor && author) {
            const author = container.querySelector(`.${CLASS_CARD_DETAILS}-checklist__author
           `) as HTMLElement
            if (author) {
                width += author.offsetWidth
            }
        }
        if (width) setWidth(width);
    }, [item, isShowWeights, isShowAuthor, asidePanelWidth, isEditing]);

    React.useEffect(() => {
        if (!width || estimate || startDate || dueDate) return;
        let height = 0;
        const text = textRef.current as HTMLElement;
        if (!text) return;
        for (let item of text.children) {
            if (
                !item.className.includes(classLabel + '-float') &&
                !item.className.includes(CLASS_CARD_DETAILS + '-checklist__author')
            ) {
                const styles = window.getComputedStyle(item);
                const margin = parseFloat(styles['marginTop']) +
                    parseFloat(styles['marginBottom']);
                //@ts-ignore
                height += item.offsetHeight + margin;
            }
        }
        setHeight(height);
    }, [item, isShowWeights, isShowAuthor, asidePanelWidth, width, isEditing]);

    const itemName = <div
        className={`
            ${classLabel}
            ${isEditing && newName && newName !== name ? classLabel + '--save' : ''}
        `}
    >
        {isEditing ?
            <Input
                className={classLabel + '-input'}
                ref={inputRef}
                value={name}
                onChange={onChange}
                onKeyDown={onKeyDown}
            />
            : <p
                className={`${classLabel + '-text'} ${name.includes('https://') ? classLabel + '-text--with-link' : ''}`}
                ref={textRef}
                style={{ height }}
            >
                {isShowAuthor && author && <CardChecklistAuthor author={{ sharedUser: author, roleIds: [] }} />}
                {!dueDate && !startDate && !estimate &&
                    (assignees && assignees.length || isShowWeights) &&
                    <div className={classLabel + '-float'}>
                        <div style={{ width }} />
                    </div>
                }
                {ReactHtmlParser(blockMarkdown(name))}
            </p>
        }
        <Button
            className={classLabel + '-button'}
            onClick={onBlur}
        >
            {CHECKLIST_SAVE_BUTTON}
        </Button>
    </div>

    const text = <>
        {isEditing ?
            <Button
                className={`${className + '-close'} ${CHECKLIST_CONTROL_CLASS}`}
                onClick={onCancel}
                tooltip={getAsidePanelTooltip({
                    value: CHECKLIST_LABEL_CLOSE,
                })}
                variant={'icon'}
            >
                <Icon xlink={'close'} size={24} />
            </Button>
            : <Checkbox
                className={CHECKLIST_CONTROL_CLASS}
                checked={checked}
                direction={'left'}
                disabled={isReadonly || isTemplate}
                onChange={() => onToggle(id)}
            >
                {CHECKLIST_LABEL}
            </Checkbox>
        }
        {itemName}
        <CardChecklistItemActionsHOC
            cardId={cardId}
            boardId={boardId}
            checklistId={checklistId}
            item={item}
            isEditing={isEditing}
            setLoading={setLoading}
            setDropdownOpen={setActionsOpen}
        />
    </>;

    const classNameChecked = checked && !isEditing ? className + '--checked' : '';
    return (
        <div
            className={`
                ${CLASS_LIST_ITEM_CONTAINER}
                ${CLASS_LIST_ITEM_CONTAINER + '--' + id}
                ${isHideDoneChecklistItems && checked ? CLASS_LIST_ITEM_CONTAINER + '--hide' : ''}
                ${isDrag ? CLASS_LIST_ITEM_CONTAINER + '--drag' : ''}
            `}
            ref={containerRef}
            {...draggable}
            tabIndex={-1}
            onKeyDown={onKeyDownContainer}
            onClick={onClick}
        >
            <ListItem
                actions={null}
                isDraggable={!isReadonly}
                className={`
                    ${className}
                    ${uniqueClass}
                    ${classNameChecked}
                    ${isEditing ? className + '--editing' : ''}
                    ${isHide ? className + '--hide' : ''}
                `}
                img={null}
                isLoading={isLoading.includes(id) || temp}
                key={id}
                text={text}
            />
        </div>
    );
}
