import { EBoardNameStatus, EFilterButtonsType, ITopBarState } from './topBar/types';
import { getLastUsedFilter } from './topBarBoard/localStorage/actions/getLastUsedFilters';

const topBarInitialState: ITopBarState = {
    boardNameStatus: EBoardNameStatus.idle,
    lastUsedFilter: EFilterButtonsType.MY_TASKS,
    isShareHintShow: false,
};

export const getTopBarInitialState = (): ITopBarState => {
    return {...topBarInitialState}
};
export const topBarBoardInitialState = {};

export const getTopBarBoardInitialState = () => ({...topBarBoardInitialState, ...getLastUsedFilter()});
