'use strict';
import * as _ from 'underscore';
App.Views.UndoBalloonBase = App.Views.BaseView.extend({
  
    events: {
        'click .undo-link': 'undo'
    },
    
    render: function() {
        this.$el.html(this.template(this.model));
        this.time = 10000;
        this.baloonTimeOut = setTimeout(_.bind(this.onBaloonTimeEnd, this), this.time);
        this.countDownInterval = setInterval(_.bind(this.countDown, this), 1000);
        return this;
    },
    
    
    countDown: function(){
    	this.time = this.time - 1000;
    	this.$el.find('.undo-timer').html( Math.floor(this.time/1000) + 's');
    	if(this.time <0){
    		clearInterval(this.countDownInterval);
    	}
    },
    
    undo: function(e) {
        e.stopPropagation();
        this.clearTimers();
        App.vent.trigger(App.vent['balloon:hide']);
        return false;
    },
    
    deleteNode: function() {
    	
    },
    
    clearTimers: function(){
    	if(this.baloonTimeOut){
    		clearTimeout(this.baloonTimeOut);
    	}
    	if(this.baloonTimeOut){
    		clearInterval(this.countDownInterval);
    	}
        this.baloonTimeOut = null;
        this.countDownInterval =null;
    },
    
    onBaloonTimeEnd: function() {
        this.clearTimers();
        this.deleteNode();
        App.vent.trigger(App.vent['balloon:hide']);
    },
    
    remove: function() {
    	this.clearTimers();
        App.vent.off(null, null, this);
        return Backbone.View.prototype.remove.call(this);
    },

    testAttributes: function() {
        return {
            type: 'undo-balloon'
        }
    }
});
App.Views.UndoBalloonBase.mixin(App.Mixins.Test);