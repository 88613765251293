import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../../../../types/types';
import { SegmentCardEvent, SegmentCardOptions, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { SegmentCardSourceValue } from '../../../../../../../../middlewares/segment/trackEntities/cardEvents';
import { waitUntilCardIsOpenedAndLoaded } from '../../../../../../helpers/waitUntilCardIsOpenedAndLoaded';
import { openCardIfNeeded } from '../effects/openCardIfNeeded';

export const onAddColor = (
    cardId: TCardId,
    source: SegmentCardSourceValue
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.COLOR_CARD_DROPDOWN_CLICKED, {
            name: SegmentCardOptions.SOURCE,
            value: source
        }));

        dispatch(openCardIfNeeded(cardId));

        const openCardColors = () => {
            const colorTagsDropdown = document.querySelector(
                '.card-details__color-tags' + ' .kui-button'
            ) as HTMLElement;
            if (colorTagsDropdown) {
                colorTagsDropdown.focus();
                colorTagsDropdown.click();
            }
        };

        waitUntilCardIsOpenedAndLoaded(
            getState,
            cardId,
            openCardColors
        );
    };

    return action;
};
