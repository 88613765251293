import { IApplicationState, TBoardId } from 'app/types/types';
import { ICardPropertyOption } from 'app/types/rest/IRestBoardMeta';
import {
    getBoardCardCustomPropertyOptions
} from 'app/store/model/board/selectors/getBoardCardCustomPropertyOptions';
import {
    getBoardCustomProperties
} from '../../../../CardCustomProperties/hocs/CardCustomPropertiesSectionHOC/selectors/getBoardCustomProperties';

export const toggleCardDefaultCustomPropertyOptions = (
    state: IApplicationState,
    boardId: TBoardId,
    option: string
): ICardPropertyOption[] => {
    let options: ICardPropertyOption[] = [];
    const cardCustomProperties = getBoardCardCustomPropertyOptions(state, boardId);
    const index = cardCustomProperties.findIndex((item) => item.option === option && item.isDefault);
    if (index < 0) {
        options = getBoardCustomProperties(state, boardId).map((customProperty) => {
            const property = cardCustomProperties.find(({ option }) => option === customProperty.name);
            return {
                option: customProperty.id,
                name: customProperty.name,
                isDefault: true,
                isRequired: property && property.isRequired
            };
        });
    }

    return options;
};
