import { IABTestSegmentTrait, IABTestValue } from '../types';
import { IABTestCurrentRest } from '../rest/types';
import { segmentIdentifyAction } from '../../middlewares/segment';
import { saveTests } from '../rest/saveTests';
import { getABTest } from '../abTests/constants';
import { Dispatch, ThunkAction } from '../../types/actions';

const SEGMENT_AB_TEST_NAME_PREFIX = 'AB_';

const sendToSegment = (
    relevantTestsValues: IABTestCurrentRest[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        const traits: IABTestSegmentTrait = relevantTestsValues.reduce((
            memo: IABTestSegmentTrait,
            testValue
        ) => {
            memo[SEGMENT_AB_TEST_NAME_PREFIX + testValue.name] = testValue.property;
            return memo;
        }, {});

        setTimeout(() => { //ждем окончания identify, который происходит на onload
            dispatch(segmentIdentifyAction(traits));
        }, 500);
    }
    return action;
}

// отправляем новые тесты в REST -> post processor -> сегмент
export const saveNewTests = (
    testValues: IABTestValue[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        const relevantTestsValues: IABTestCurrentRest[] = testValues.filter(testValues => {
            return testValues.isRelevant
        });
        if (!relevantTestsValues.length) return Promise.resolve([]);
        return saveTests(relevantTestsValues).then(() => {
            relevantTestsValues.forEach(testValue => {
                const abTest = getABTest(testValue.name);
                if (abTest.postProcessor) {
                    dispatch(abTest.postProcessor(testValue.property))
                }
            });
            dispatch(sendToSegment(relevantTestsValues));
        })
    }
    return action;
};
