import {ActionCreator} from 'redux';
import {Dispatch, ThunkAction} from '../../../../../../types/actions';
import { EHintKey } from '../../../constants';

export const onShow: ActionCreator<ThunkAction> = (
    key: EHintKey,
    ownOnShow?: () => void
) => {
    const action = (
        dispatch: Dispatch
    ) => {
        if (ownOnShow) ownOnShow();
    };
    return action;
};
