import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { IDependenciesPanelButtonHOCProps } from './types';
import {
    IDependenciesPanelButtonEvents,
    IDependenciesPanelButtonFields
} from '../../components/DependenciesPanelButton/types';
import { DependenciesPanelButton } from '../../components/DependenciesPanelButton/DependenciesPanelButton';
import { CARD_SECTION_DEPENDENCIES } from '../../constans';
import { onClick } from './events/onClick';
import { getSectionType } from '../../../selectors/getSectionType';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import {
    getCardDependenciesCount
} from '../../../../../../../store/model/dependencies/dependencies/selectors/getCardDependenciesCount';
import { getShowProFeaturesTrial } from '../../../../../../../store/model/authUser/selectors/getShowProFeaturesTrial';
import { getIsUserBasicPlanOnly } from '../../../../../../../store/model/authUser/selectors/getIsUserBasicPlanOnly';

const mapStateToProps = (
    state: IApplicationState,
    { boardId, cardId, isShowTooltip }: IDependenciesPanelButtonHOCProps
): IDependenciesPanelButtonFields => {
    const sectionType = getSectionType(state, boardId, cardId, null, 'allowDependencies');
    const isDisallow = sectionType === ESettingsSectionType.DISALLOW;
    const isShow = (
        sectionType === ESettingsSectionType.DEFAULT ||
        isDisallow
    );
    const tooltip = isShowTooltip
        ? CARD_SECTION_DEPENDENCIES
        : null;
    const dependenciesCount = getCardDependenciesCount(state, cardId);
    return {
        dependenciesCount,
        isBasicFeature: getIsUserBasicPlanOnly(state),
        isDisallow,
        isProFeature: getShowProFeaturesTrial(state),
        isShow,
        tooltip,
    }
};

const mapDispatchToProps = (
    dispatch: any,
    {boardId, cardId, onClick: ownOnClick}: IDependenciesPanelButtonHOCProps
): IDependenciesPanelButtonEvents => {
    return {
        onClick: () => dispatch(onClick(boardId, cardId, ownOnClick)),
    }
};

export const DependenciesPanelButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(DependenciesPanelButton);

DependenciesPanelButtonHOC.displayName = 'DependenciesPanelButtonHOC';
