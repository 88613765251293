import { IGoogleCodeResponce, Provider, providerInstance } from 'app/helper/authorisation/google/Provider';
import { IAuthToken, IRestAuthUser } from 'app/types/rest/IRestAuthUser';
import { fetchHandler } from 'app/util/fetchHandler';
import { getRestHeadersPost } from 'app/rest/helpers/getRestHeadersHelper';
import { CommonAuthController} from 'app/helper/authorisation/common/CommonAuthController';

/***
 * класс овечает за общение с  Goolge Provider и бекендом
 */
export class AuthController extends CommonAuthController{

    static authorizeWithCode(
        scopes: string[],
        onSuccess: (authUser: IRestAuthUser)=> void,
        onError: (authUser: IRestAuthUser) => void,
        onCancel: () => void,
        hint?: string) {
        providerInstance.then(provider => {
            return provider.authorizeWithCode(scopes, hint)
                .catch(err =>{
                    console.debug(err);
                    onCancel();
                    return new Promise<IGoogleCodeResponce>(()=>{});
                })
                .then(
                    (codeResponce) => {
                        this.serverAuthorizeWithCode(codeResponce)
                            .catch((err) => {
                                if (codeResponce.error === 'access_denied') {
                                    onCancel();
                                } else {
                                    onError(err);
                                }
                                return new Promise<IGoogleCodeResponce>(()=>{});
                            })
                            .then(onSuccess);
                        }
                    )
        })
    }

    protected static serverAuthorizeWithCode(response: IGoogleCodeResponce): Promise<IRestAuthUser> {
        return fetchHandler('/rest/users/authorizeWithCode', {
            ...getRestHeadersPost(),
            body: JSON.stringify(response)
        }).then(this.processServerAuthResponse);
    }

}
