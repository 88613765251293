import { AT_ROUTER_SET } from './constants';
import { IRouterSetAction } from './types';
import { ERoutes } from '../constants';

export const routerChange = (
    route: ERoutes,
    args: Array<any>
): IRouterSetAction => ({
    type: AT_ROUTER_SET,
    route,
    args
});
