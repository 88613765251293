import { IGetState, TBoardId } from '../../../../../../../../types/types';
import { IExportListener } from '../../../../../../../../helper/export/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { exportCardTitlesWithCheckPerm } from '../effects/exportCardTitlesWithCheckPerm';

export const onExportCardTitles = (
    boardId: TBoardId,
    listener: IExportListener
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(exportCardTitlesWithCheckPerm(boardId, listener));
    };
    return action;
};
