import { IApplicationState, TFilterId } from '../../../../../../../../types/types';
import { savedFilterSelector } from '../../../../store/selectors/savedFilterSelector';
import { getSortedSavedFiltersIds } from './getSortedSavedFiltersIds';
import { MY_WORK_FILTER_BOARD_ID } from '../../../../constants';

export const getShowOnBarSortedSavedFilterIds = (
    state: IApplicationState
): TFilterId[] => {
    const sortedSavedFiltersIds = getSortedSavedFiltersIds(state, MY_WORK_FILTER_BOARD_ID);
    const showOnBarFilterIds = sortedSavedFiltersIds.reduce((ids, filterId) => {
        const filter = savedFilterSelector(state, filterId);
        if (filter && filter.meta && filter.meta.isMyWorkShowOnBar) ids.push(filterId);
        return ids;
    }, []);
    return showOnBarFilterIds;
}
