import { IMyWorkClosedGroups } from './store/types';
import { EMyWorkGroupType } from '../../aside_panel/filterPanelBoard/store/filterPanel/types';

export const CLASS_MY_WORK = 'my-work';
export const CLASS_MY_WORK_CARDS = CLASS_MY_WORK + '-cards';
export const CLASS_MY_WORK_CARD = CLASS_MY_WORK + '-card';
export const CLASS_MY_WORK_CARD_CONTROL = CLASS_MY_WORK_CARD + '__control';
export const ID_MY_WORK_GROUP_PORTAL = 'my-work-group-portal';
export const MY_WORK_FAQ_LINK = 'https://www.kanbanchi.com/faqwd/my-tasks';
export const BOARDS_LAST_DAYS = 10;
export const FILTER_ACTIONS_PORTAL = 'my-work-filter-portal';
export const MY_WORK_BOARD_ID = -1;

export const MY_WORK_DEFAULT_OPENED_GROUPS: IMyWorkClosedGroups = {
    [EMyWorkGroupType.DUE]: [],
    [EMyWorkGroupType.ACTIVITY]: [],
    [EMyWorkGroupType.PRIORITY]: [],
    [EMyWorkGroupType.ASSIGNEE]: [],
    [EMyWorkGroupType.LIST]: [],
    [EMyWorkGroupType.BOARD]: [],
    [EMyWorkGroupType.NONE]: []
}
