import {IFilter} from '../filter/types';
import {DEFAULT_FILTER_ID} from '../../constants';
import {IFilterPanel} from '../filterPanel/types';

export const defaultOrEditedFilterSelectorById = (
    state: IFilterPanel,
    filterId: number
): IFilter => {
    return filterId === DEFAULT_FILTER_ID ? state.defaultFilter : state.editedFilter;
};
