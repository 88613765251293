import { TBoardId } from '../../../../../../../../types/types';
import { ThunkAction } from '../../../../../../../../types/actions';
import { getBoardExportSettings } from '../../../../../../../../store/model/board/selectors/getBoardExportSettings';
import { boardSetExportSettings } from '../../../../../../../../rest/effects/board/boardSetExportSettings';
import { SegmentBoardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import {
    SegmentBoardOption,
    SegmentBoardOptionStatusValue
} from '../../../../../../../../middlewares/segment/trackEntities/boardEvents';
import {
    getAutoBackupsByBoardIdAndExportType
} from '../../../../backups/AutoBackups/store/autoBackup/selectors/getAutoBackupsByBoardIdAndExportType';
import { EBackupExportType } from '../../../../../../../../types/rest/IRestBackup';

export const onArchivedChange = (
    boardId: TBoardId,
    isIncludeArchive: boolean,
    isAuto: boolean
): ThunkAction => {
    return (
        dispatch,
        getState
    ) => {
        const state = getState();
        const autoBackup = getAutoBackupsByBoardIdAndExportType(state, boardId, EBackupExportType.GOOGLE_SHEET);
        const settings = getBoardExportSettings(state, boardId);
        dispatch(segmentTrackAction(SegmentBoardEvent.EXPORT_WITH_ARCHIVE_CHANGED, {
            name: SegmentBoardOption.STATUS,
            value: isIncludeArchive ? SegmentBoardOptionStatusValue.ON: SegmentBoardOptionStatusValue.OFF
        }));
        if (isAuto) {
            dispatch(segmentTrackAction(SegmentBoardEvent.AUTO_EXPORT_INCLUDE_ARCHIVED_CLICKED, {
                name: SegmentBoardOption.STATUS,
                value: !autoBackup.enabled ? SegmentBoardOptionStatusValue.ON: SegmentBoardOptionStatusValue.OFF
            }));
        }
        dispatch(boardSetExportSettings(boardId, {
            ...settings,
            isIncludeArchive
        }));
    }
}
