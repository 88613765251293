import { INavigationPanelQuickStartGuideSetAction } from './types';
import { AT_NAVIGATION_PANEL_QUICK_START_GUIDE_SET } from './constants';
import { EQuickStartGuideType } from '../../../constants';

export const quickStartGuideSetAction = (
    quickStartGuide: EQuickStartGuideType
): INavigationPanelQuickStartGuideSetAction => ({
        type: AT_NAVIGATION_PANEL_QUICK_START_GUIDE_SET,
        quickStartGuide
    }
);
