import { IApplicationState } from '../../../types/types';
import { getCard } from './getCard';

export const getCardEstimate = (
    state: IApplicationState,
    cardId: number,
    includeChecklists?: boolean,
): number => {
    const card = getCard(state, cardId);
    if (!card) return null;

    let estimate = card.estimate || 0;
    if (includeChecklists) {
        const {estimate: checklistsEstimate = 0} = card.checklistStats || {};
        estimate += checklistsEstimate;
    }

    return estimate;
};
