import * as React from 'react';
import { IDependencyProps } from './types';
import { DEPENDENCY_CLASS, REMOVE_DEPENDENCY_TOOLTIP } from './constants';
import './_dependency.scss';
import { Button, ButtonDropdown, ButtonTitle, Icon, SelectList, Tooltip } from 'kui';
import { ECardDependencyType } from '../../constans';
import { EDependencyTypes } from '../../../../../../../store/model/dependencies/dependency/types';
import { TCardId } from '../../../../../../../types/types';
import { getAsidePanelTooltip } from '../../../../asidePanel/helpers/getAsidePanelTooltip';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { escape } from 'underscore';

export function Dependency({
    canEdit,
    cardId,
    cardName,
    dependencyType,
    id,
    link,
    type,
    typesList,
    onDelete,
    onDependencyCardLink,
    onTypeChanged,
    isValid
}: IDependencyProps) {
    const { isMobile } = React.useContext(AsidePanelContext);
    const isPredecessor = dependencyType === ECardDependencyType.PREDECESSOR;

    let dependencyClass = DEPENDENCY_CLASS;
    const dependencyCellClass = DEPENDENCY_CLASS + '__cell';
    const cardClass = DEPENDENCY_CLASS + '__card';
    const cardNameClass = DEPENDENCY_CLASS + '__card-name';
    const trashClass = DEPENDENCY_CLASS + '__trash';
    const classNameTypeButton = DEPENDENCY_CLASS + '__type-button';
    const classNameTypeButtonText = DEPENDENCY_CLASS + '__type-button-text';
    const classNameTypeButtonIcon = DEPENDENCY_CLASS + '__type-button-icon';

    const typeActiveIndex = typesList.findIndex((itemType) => itemType.value === type);

    let lineClass = DEPENDENCY_CLASS + '__line';
    lineClass += isValid ? ` ${lineClass}--valid` : ` ${lineClass}--invalid`;
    dependencyClass += isPredecessor ? ` ${DEPENDENCY_CLASS}--predecessor` : ` ${DEPENDENCY_CLASS}--successor`;

    function onLinkHandler (
        event: React.MouseEvent,
        cardId: TCardId
    ) {
        event.preventDefault();
        onDependencyCardLink(cardId);
    }

    const name = (
        <a
            className={cardNameClass}
            href={link}
            onClick={(event: React.MouseEvent) => onLinkHandler(event, cardId)}
        >
            {cardName}
        </a>
    );

    const nameWithTooltip = isMobile ? name : (
        <Tooltip
            isNoEvents
            isNoWrap
            value={escape(cardName)}
        >
            {name}
        </Tooltip>
    );

    return (
        <li className={dependencyClass}>
            <div className={`${dependencyCellClass} ${dependencyCellClass}--card`}>
                <div className={cardClass}>
                    {nameWithTooltip}
                    {
                        canEdit &&
                        <Button
                            className={trashClass}
                            tooltip={getAsidePanelTooltip({
                                    direction: isPredecessor ? 'left' : 'up-left',
                                    value: REMOVE_DEPENDENCY_TOOLTIP
                                })
                            }
                            variant={'icon'}
                            onClick={onDelete}
                        >
                            <Icon
                                xlink={'clear'}
                                size={24}
                            />
                        </Button>
                    }
                </div>
                <div className={lineClass}/>
            </div>
            <div className={`${dependencyCellClass} ${dependencyCellClass}--type`}>
                <ButtonDropdown
                    className="dependency__link-type"
                    disabled={!canEdit}
                >
                    <Button
                        className={classNameTypeButton}
                        variant={'icon-text'}
                    >
                        <ButtonTitle className={classNameTypeButtonText}>
                            {type}
                        </ButtonTitle>
                        <Icon
                            className={classNameTypeButtonIcon}
                            xlink={'arrow-down'}
                            size={16}
                        />
                    </Button>
                    <SelectList active={typeActiveIndex}>
                        {
                            typesList.map((typeItem, index: number) => {
                                return (
                                    <li
                                        key={index}
                                        value={typeItem.key}
                                        onClick={() => onTypeChanged(id, typeItem.key as EDependencyTypes)}
                                    >
                                        {typeItem.value}
                                    </li>
                                );
                            })
                        }
                    </SelectList>
                </ButtonDropdown>
            </div>
        </li>
    );
}
