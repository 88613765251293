import * as React from 'react';
import {SVG, AbstractReactComponent} from './../../../../../react-utils';

import {MoveToCategories} from './move_to-category';
import {MoveToElements} from './move_to-element';


class MoveTo extends AbstractReactComponent {
    initialize(props) {
        this.state = {
            isLoaded: props.hasOwnProperty('isLoaded') ? props.isLoaded : false
        };
    }

    onLoadMore(e) {
        if (e) {
            e.stopPropagation();
        }
        this.setState({
            isLoaded: true
        });
        this.props.onLoadMore();
    }

    render() {
        const CategoryView = this.props.categoryView;
        const ElementView = this.props.elementView;

        const loaderClass = `move-card__progress js-move-loading ${this.props.isLoading ? 'move-card__progress--loading' : ''}`;
        return (
            <div className="dropdown__body">
                <div className="move-card">
                    <div className="move-card__boards-holder">
                        <div className="move-card__header move-card__header--boards">
                            <div className="move-card__header-title">
                                {
                                    this.props.categoryHeader
                                }
                            </div>
                            <div className={loaderClass}>
                                <div className="progress-bar" title="Loading">
                                    <div className="progress-bar__indeterminate"></div>
                                </div>
                            </div>
                        </div>
                        <div className="move-card__body move-card__body--boards">
                            <CategoryView
                                selectedCat={this.props.selectedCat}
                                categories={this.props.categories}
                                categoryName={this.props.categoryName}
                                onClick={this.props.getElementsForCategory}
                                disabledCategories={this.props.disabledCategories}
                            />
                            {
                                !this.props.isLoaded &&
                                <span className="move-card__more js-move-more" onClick={e => this.onLoadMore(e)}>
                                    <span className="move-card__more-text">Load more</span>
                                    <span className="icon">
                                        <SVG xlink="svgicon--b-arrow" />
                                    </span>
                                </span>
                            }
                        </div>
                    </div>
                    <div className="move-card__lists-holder">
                        <div className="move-card__header move-card__header--lists">
                            <div className="move-card__header-title">
                                {
                                    this.props.elementsHeader
                                }
                                {
                                    this.props.elementsLoader &&
                                    <div className="loader js-loader" title="Loading">
                                        <i className="loader__blink"/>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="move-card__body move-card__body--lists">
                            <ElementView
                                addFirst={this.props.addFirst}
                                firstName={this.props.firstName}
                                elements={this.props.elements}
                                onClick={this.props.onElementSelected}
                                elementName={this.props.elementName}
                                selectedElements={this.props.selectedElements}
                                loadingElement={this.props.loadingElement}
                                disabledElements={this.props.disabledElements}
                                {...this.props.elementViewsProps}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

MoveTo.defaultProps = {
    isLoading: false,
    selectedCat: 0,
    selectedElements: [],
    disabledElements: [],
    disabledCategories: [],
    loadingElement: null,
    elementsLoader: false,

    categories: [],
    elements: [],
    categoryHeader: 'Boards',
    elementsHeader: 'Available lists',
    categoryView: MoveToCategories,
    elementView: MoveToElements,
    categoryName: undefined,
    elementName: undefined,
    elementViewsProps: undefined,
    getElementsForCategory: catId => {},
    onLoadMore: () => {},
    onElementSelected: () => {}
};

export default MoveTo;
