import { INavigationPanelCollapseProps } from './types';
import { Button, Icon, Tooltip } from 'kui';
import * as React from 'react';
import { ENavigationPanelTypes } from '../../constants';
import './_NavigationPanelCollapse.scss'
import { NOTIFICATIONS_COUNT_TOOLTIP } from './constants';

export const NavigationPanelCollapse: React.FC<INavigationPanelCollapseProps> = ({
    isInPanel,
    status,
    onCloseClicked,
    onCollapseClicked,
    unseenNotificationCount,
    updateIsNotificationsOpen
}) => {
    const className = 'navigation-panel__resize';
    const classNameTooltip = 'navigation-panel__tooltip navigation-panel__tooltip--small'

    const getIconXlink = (
        status: ENavigationPanelTypes
    ): string => {
        switch (status) {
            case ENavigationPanelTypes.NP_COMPACT_TYPE:
                return 'arrow-right';
            default:
                return 'arrow-left';
        }
    };

    const icon = getIconXlink(status);

    let text, onClick;

    if (status === ENavigationPanelTypes.NP_MOBILE_TYPE) {
        text = 'Close';
        onClick = onCloseClicked;
    } else if (status === ENavigationPanelTypes.NP_COMPACT_TYPE) {
        text = isInPanel ? 'Pin panel' : 'Expand panel';
        onClick = onCollapseClicked;
    } else {
        text = 'Collapse panel';
        onClick = onCollapseClicked;
    }
    function onCounterClick (event: React.MouseEvent) {
        event.stopPropagation()
        updateIsNotificationsOpen(true)
    }
    return (
        <Button
            className={`${className} handle-click-ignore`}
            variant="icon"
            onClick={onClick}
        >
            <Tooltip
                className={classNameTooltip}
                delay={200}
                direction={'right'}
                key={`${className}--${status}`}
                value={text}
            >
                <Icon xlink={icon} size={24}/>
            </Tooltip>
            {status === ENavigationPanelTypes.NP_COMPACT_TYPE && !!unseenNotificationCount &&
                <Tooltip
                    className={classNameTooltip}
                    delay={200}
                    direction={'right'}
                    value={NOTIFICATIONS_COUNT_TOOLTIP}
                >
                    <span
                        className={`
                            ${className + '-notifications'}
                            ${unseenNotificationCount > 99 ? className + '-notifications--big' : ''}
                        `}
                        onClick={(event) => onCounterClick(event)}
                    >
                        {unseenNotificationCount < 100 ? unseenNotificationCount : '99+'}
                    </span>
                </Tooltip>
            }
        </Button>
    );
};
