import {Dispatch, ThunkAction} from '../../../../../../../types/actions';
import { SegmentFilterEvent, SearchAfterTarget } from '../../../../../../../middlewares/segment/trackEntities/filterEvents';
import { filterSegmentTrackAction } from '../../../../../aside_panel/filterPanelBoard/effects/filterSegmentTrackAction';

export const afterSearchFilter = (
    boardId: number
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        dispatch(filterSegmentTrackAction(SegmentFilterEvent.SEARCH_AFTER, boardId, {
            name: 'target',
            value: SearchAfterTarget.FILTER_APPLIED
        }));
    };
    return action;
};
