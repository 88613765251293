import { IGetState, TBoardId } from '../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { boardsActionSet } from '../../../../../store/model/actions/boardsActionSet';
import { boardActionSet } from '../../../../../store/model/boards/actions/boardActionSet';
import { getColorForRollback } from '../../../../../store/model/selectors/getColorForRollback';
import { IColor } from '../../../../../types/model';
import { patchRest } from '../patchRest';
import { updateColorAction } from '../../../../../store/model/board/actions/updateColorAction';
import { sendRealTimeStoreActionByBoardId } from '../../../../../view/react_components/base/helpers/realTimeHelperTS';
import { showColorLabelLongNameError } from './showColorLabelLongNameError';

export const boardColorPatchRest = (
    boardId: TBoardId,
    colorTag: IColor,
    isRealtime: boolean = true, // send event to socket
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const { id } = colorTag;
        const colorForRollback = getColorForRollback(getState(), boardId, id, colorTag);
        const actionSet = boardsActionSet(boardActionSet(boardId, updateColorAction(id, colorTag)));
        dispatch(actionSet);

        return dispatch(patchRest(id, colorTag))
            .then(() => {
                if (isRealtime) {
                    dispatch(sendRealTimeStoreActionByBoardId(boardId, actionSet))
                }
                return colorTag;
            })
            .catch((err: any) => {
                console.log(err);
                err.json().then((err: any) => {
                    if (err.code === 5) {
                        dispatch(showColorLabelLongNameError(err.message));
                    }
                });
                dispatch(boardsActionSet(boardActionSet(boardId, updateColorAction(id, colorForRollback))));
            });
    };
    return action;
};
