import { connect } from 'react-redux';
import { onClick } from './events/onClick';
import { IApplicationState } from '../../../../../../types/types';
import { NavigationPanelQualificationForm } from '../../components/NavigationPanelQualificationForm/NavigationPanelQualificationForm';
import {
    INavigationPanelQualificationFormEvents,
    INavigationPanelQualificationFormFields
} from '../../components/NavigationPanelQualificationForm/types';
import { isQualificationAvailableWithStatus } from '../../../../../../store/model/authUser/selectors/isQualificationAvailableWithStatus';
import { EUserMetaQualificationFormStatus } from '../../../../../../types/rest/IUserMeta';

const mapStateToProps = (
    state: IApplicationState,
): INavigationPanelQualificationFormFields => {
    return {
        isShow: isQualificationAvailableWithStatus(state, [EUserMetaQualificationFormStatus.SKIPPED])
    }
};

const mapDispatchToProps = (dispatch: any): INavigationPanelQualificationFormEvents => ({
    onClick: () => dispatch(onClick())
});

export const NavigationPanelQualificationFormHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(NavigationPanelQualificationForm);
NavigationPanelQualificationFormHOC.displayName = 'NavigationPanelQualificationFormHOC';
