import { IGetState, TCardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { getPushNotificationSettingsByBoardId } from '../../../../store/model/selectors/getPushNotificationSettingsByBoardId';
import { getBoardIdByCardId } from '../../../../store/model/selectors/getBoardIdByCardId';
import { EPushNotificationSubscribeCases } from '../../../../types/rest/IRestPushNotificationSettings';
import { ICards } from '../../../../store/model/cards/types';
import { cardsUpdate } from '../../../../store/model/actionCreators/cardsUpdate';

export const cardCheckActivateNotifySubscription = (
    cardId: TCardId,
    subscribeCase: EPushNotificationSubscribeCases
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const boardId = getBoardIdByCardId(state, cardId);
        const pushNotify = getPushNotificationSettingsByBoardId(state, boardId);
        if (pushNotify && pushNotify.subscribeCases && pushNotify.subscribeCases.includes(subscribeCase)) {
            const cards: ICards = {
                [cardId]: {
                    wtch: true
                }
            };
            dispatch(cardsUpdate(cards));
        }
        return Promise.resolve();
    };
    return action;
};
