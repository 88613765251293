import _ from 'underscore';

import filterCalendars from './../helpers/filterCalendars';
import setSelectedCalendars from './../store/actions/setSelectedCalendars';
import setAvailableCalendars from './../store/actions/setAvailableCalendars';

const setCalendars = () => {
    return (dispatch, getState) => {
        const selectedCardId = getState().pushToGoogleCalendar.cardId;
        const selectedActionType = getState().pushToGoogleCalendar.selectedAction;
        const googleCalendars = getState().pushToGoogleCalendar.googleCalendars;

        const filteredCalendars = filterCalendars(selectedCardId, selectedActionType);
        const pushedIds = _.pluck(filteredCalendars, 'calendarId');
        dispatch(setAvailableCalendars(googleCalendars));
        dispatch(setSelectedCalendars(pushedIds));
    };
};

export default setCalendars;