import { TBoardId, TCardId } from '../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../types/actions';

export const onDidUnmount = (
    boardId: TBoardId,
): ThunkAction => {
    const action = (dispatch: Dispatch) => {
    };
    return action;
};
