import { IGetState, TBoardId, TTag } from '../../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { SegmentBoardEvent, segmentTrackAction } from '../../../../../../../../../middlewares/segment';
import { getBoardCardsToTagUpdate } from '../../../../../../../../../store/model/selectors/getBoardCardsToTagUpdate';
import { cardsRestPatch } from '../../../../../../../../../rest/effects/card/card/api/helper/cardsRestPatch';
import { getMessages } from '../../../../../../../../../store/constants';
import { escape } from 'underscore';
import { clearMarkdownInline } from '../../../../../../../base/helpers/clearMarkdownHelper';
import { snackbarPromtDefault } from '../../../../../../../snackbarsStack';

export const onDelete = (
    boardId: TBoardId,
    tag: TTag
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentBoardEvent.DELETED_TAG));

        const cards = getBoardCardsToTagUpdate(getState(), boardId, tag, null);
        const cardsCount = Object.keys(cards).length;
        if (cardsCount) {
            const text = getMessages().getPluralByKeys(
                cardsCount,
                ['snackbar.board.tag.delete.card', 'snackbar.board.tag.delete.cards'],
                null, escape(clearMarkdownInline(tag)), cardsCount
            );
            dispatch(snackbarPromtDefault({
                id: 'DELETE_TAGS',
                text,
                actionApply: () => {
                    dispatch(cardsRestPatch(cards));
                    dispatch(segmentTrackAction(SegmentBoardEvent.DELETED_TAG));
                },
                actionCancel: () => {
                },
            }));
        }
    };
    return action;
};
