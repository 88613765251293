export const compareStrings = (
    stringA: string,
    stringB: string,
    isDesc?: boolean,
): number => {
    if (isDesc) {
        // Пустая строка всегда внизу
        if (stringB && !stringA) {
            return -1;
        }
        if (!stringB && stringA) {
            return 1;
        }
        return stringB.localeCompare(stringA, 'en', { numeric: true });
    }

    // Пустая строка всегда внизу
    if (stringA && !stringB) {
        return -1;
    }
    if (!stringA && stringB) {
        return 1;
    }
    return stringA.localeCompare(stringB, 'en', { numeric: true });
}
