import * as React from 'react';
import { IEstimateTimeProps } from './types';
import { v4 as uuidv4 } from 'uuid';
import { ESTIMATE_OPTIONS, ESTIMATE_TIME_TEXT } from '../../constants';
import { CLASS_BOARD_DETAILS } from '../../../../constants';
import './_EstimateTime.scss';
import { Select } from 'kui';
import { ISelectActiveInheritedProps } from 'kui';

export function EstimateTime ({
    text,
    value,
    allowChangeEstimateTime,
    onChange
}: IEstimateTimeProps){
    const [key, setKey] = React.useState(uuidv4());
    const classText = CLASS_BOARD_DETAILS + '__estimate-time-text';
    const classDropdown = CLASS_BOARD_DETAILS + '__estimate-time-dropdown';

    function onClose() {
        setKey(uuidv4())
    }

    const onChangeHandler = (
        event: ISelectActiveInheritedProps
    ) => {
        onChange(event.item.value);
    }

    return (
        <div>
            <div className={classText}>
                {ESTIMATE_TIME_TEXT}
            </div>
            <Select
                active={ESTIMATE_OPTIONS.findIndex(o => o.value === value)}
                directionVertical={'auto'}
                disabled={!allowChangeEstimateTime}
                dropdownClassName={classDropdown}
                key={key}
                options={ESTIMATE_OPTIONS}
                value={text}
                variant={'arrow'}
                onClose={onClose}
                onChange={onChangeHandler}
            />
        </div>
    );
}
