import { IGetState, TBoardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { fetchHandler } from '../../../../util/fetchHandler';
import { Util } from '../../../../util/util';
import { REST_BOARD } from '../../../constants';
import { getRestHeadersPatch } from '../../../helpers/getRestHeadersHelper';
import { IBoard } from '../../../../store/model/board/types';

export const patchRest = (
    boardId: TBoardId,
    patch: IBoard
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return fetchHandler<IBoard>(
            Util.getApiUrl(REST_BOARD + boardId), {
                ...getRestHeadersPatch(),
                body: JSON.stringify(patch)
            }
        );
    };
    return action;
};
