import { AT_COLOR_UPDATE, IColorUpdateAction, IUpdateAction } from './types';
import { IColor } from '../../../../types/model';
import { TColorId } from '../../../../types/types';

export const updateColorAction = (
    colorId: TColorId,
    color: IColor
): IColorUpdateAction => {
    return {
        type: AT_COLOR_UPDATE,
        colorId,
        color
    }
};
