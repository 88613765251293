import { ONE_HOUR } from '../constants';

export const getTimeStringSinceDate = (
    date: number,
    short?: boolean
) => {
    const oneDay = ONE_HOUR * 24;
    const oneWeek = oneDay * 7;
    const oneMonth = oneWeek * 4;
    const oneYear = oneMonth * 12;

    if (Math.floor(date / oneYear) > 0) {
        return Math.floor(date / oneYear) + (short ?
            'yr' : Math.floor(date / oneYear) > 1 ? ' years' : ' year');
    } else {
        if (Math.floor(date / oneMonth) > 0) {
            return Math.floor(date / oneMonth) + (short ?
                'mo' : Math.floor(date / oneMonth) > 1 ? ' months' : ' month');
        } else {
            if (Math.floor(date / oneWeek) > 0) {
                return Math.floor(date / oneWeek) + (short ?
                    'wk' : Math.floor(date / oneWeek) > 1 ? ' weeks' : ' week');
            } else {
                if (Math.floor(date / oneDay) > 0) {
                    return Math.floor(date / oneDay) + (short ?
                        'd' : Math.floor(date / oneDay) > 1 ? ' days' : ' day');
                } else {
                    return Math.floor(date / ONE_HOUR) + (short ?
                        'hr' : ' hours');
                }
            }
        }
    }
}
