import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState } from '../../../../types/types';
import { IAuthUser } from '../../../../store/model/authUser/types';
import { getAuthUser } from '../../../../store/model/authUser/selectors/getAuthUser';
import { getUserRecentBoards } from '../../../../store/model/authUser/selectors/getUserRecentBoards';
import { authUserRecentBoardsPatch } from './helper/authUserRecentBoardsPatch';
import { getTabs } from 'app/view/react_components/main/navigationPanel/store/selectors/getTabs';
import { getNumberKeys } from 'app/helper/getNumberKeys';
import { root } from 'app/store/constants';

export const closeRecentOpenBoard: ActionCreator<ThunkAction> = (
    boardId: number
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();

        // KNB-2502 в recent может быть неактивная таба, удалить из табов
        const tabs = getTabs(state);
        if (tabs && tabs[boardId]) {
            const tabIds = getNumberKeys(tabs).filter(id => id !== boardId);
            root.App.router.setBaseUrl(tabIds);
            root.App.router.navigate(root.App.router.getBaseUrl(), { trigger: true });
        }

        const user: IAuthUser = getAuthUser(state);
        const recentBoardsFromState = getUserRecentBoards(state)
        const recentBoards = recentBoardsFromState.filter((board) => board.id !== boardId);
        if (recentBoards.length < recentBoardsFromState.length) {
            return dispatch(authUserRecentBoardsPatch(user.id, recentBoards));
        }
        return Promise.resolve();
    };
    return action;
};
