/**
 *  default prefix "FILTER_PANEL_"
 */

export const AT_LOADING_SET = 'FILTER_PANEL_LOADING_SET';
export const AT_BLOCKED_SET = 'FILTER_PANEL_AT_BLOCKED_SET';
export const AT_IS_OPEN_SET = 'FILTER_PANEL_IS_OPEN_SET';
export const AT_DEFAULT_FILTER_ACTION_SET = 'FILTER_PANEL_DEFAULT_FILTER_ACTIONS_SET';
export const AT_EDITED_FILTER_ACTION_SET = 'FILTER_PANEL_EDITED_FILTER_ACTIONS_SET';
export const AT_ENABLED_SET = 'FILTER_PANEL_ENABLED_SET';
export const AT_KEY_WORD_FILTRATION_ENABLED_SET = 'KEY_WORD_FILTRATION_ENABLED_SET';
export const AT_EDITED_FILTER_SET = 'FILTER_PANEL_EDITED_FILTER_SET';
export const AT_DEFAULT_FILTER_RESET = 'FILTER_PANEL_DEFAULT_FILTER_RESET';
export const AT_SAVED_FILTER_IDS_SET = 'FILTER_PANEL_SAVED_FILTER_ID_SET';
export const AT_INITIAL_STATE_SET = 'FILTER_PANEL_INITIAL_STATE_SET';
export const AT_PANEL_OPENED_SET = 'FILTER_PANEL_PANEL_OPENED_SET';
export const AT_IS_INITIAL_SET = 'FILTER_PANEL_IS_INITIAL_SET';
