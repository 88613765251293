import { TCollaboratorsAction } from '../../collaborators/actions/types';

export const AT_CARD_ACTION_SET = 'COLLABORATORS_CARD_ACTION_SET';
export const AT_BOARD_ACTION_SET = 'COLLABORATORS_BOARD_ACTION_SET';

export interface ICardActionSetAction {
    type: typeof AT_CARD_ACTION_SET;
    id: number;
    collaboratorsAction: TCollaboratorsAction;
}

export interface IBoardActionSetAction {
    type: typeof AT_BOARD_ACTION_SET;
    id: number;
    collaboratorsAction: TCollaboratorsAction;
}

export type TModelsCollaboratorsAction = (
    ICardActionSetAction |
    IBoardActionSetAction
);
