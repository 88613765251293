import { TBoardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import {
    removePrefixes
} from '../../../../../boardDetails/details/CardNumber/hocs/PrefixesSectionHOC/effects/removePrefixes';

export const onCardNumberRemoveAll = (
    boardId: TBoardId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        return dispatch(removePrefixes(boardId));
    };
    return action;
};
