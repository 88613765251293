import * as React from 'react';
import {IRecentBoardsProps} from './types';
import { TITLE_STARRED, TITLE_RECENT, TEXT_STARRED_EMPTY } from './constants';
import './_RecentBoards.scss';
import { Loader, SectionAccordion } from 'kui';
import { NAVIGATION_PANEL_SECTION_CLASS } from '../../../constants';
import { BoardsItem } from '../BoardsItem/BoardsItem';
import { ERecentAccordion } from 'app/types/rest/IUserMeta';
import { BOARD_NAME_DEFAULT } from '../../../../../aside_panel/boardDetails/constants';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { CLASS_LIST_ITEM_CONTAINER } from 'app/view/react_components/base/components/ListItem/constants';
import { AnalyzeRender } from 'app/view/react_components/helpers/memoizeHelper';

// @ts-ignore
const analyzeRender = window.Settings.development ? new AnalyzeRender(`RecentBoards`) : null;

export function RecentBoards({
    boards,
    starredBoards,
    isMobile,
    isLoadingStarred,
    loadStarred,
    onClickBoard,
    onCloseBoard,
    onSort,
    onUnstar,
    recentAccordion,
    updateRecentAccordion
}: IRecentBoardsProps) {
    if (analyzeRender) analyzeRender.call(`RecentBoards`);

    let [isCloseDisabled, setCloseDisabled] = React.useState(false);
    const onClose = (id: number) => {
        if (isCloseDisabled) return;
        setCloseDisabled(true);
        onCloseBoard(id).then(() => {
            setCloseDisabled(false);
        }).catch(() => {
            setCloseDisabled(false);
        })
    }

    const [isLoadingStarredHook, setLoadingStarredHook] = React.useState(isLoadingStarred);

    const onSelectAccordion = (accordion: ERecentAccordion) => {
        updateRecentAccordion(accordion)
        if (accordion === ERecentAccordion.STARRED) {
            loadStarred();
            setLoadingStarredHook(true);
        }
    }

    React.useEffect(() => {
        if (!isLoadingStarred) setLoadingStarredHook(false); // тут только снимается, а ставится в onOpenAccordionStarred
    }, [isLoadingStarred]);

    React.useEffect(() => {
        if (recentAccordion === ERecentAccordion.STARRED) {
            onSelectAccordion(ERecentAccordion.STARRED)
        }
    }, [recentAccordion])

    function onDragEnd(result: DropResult) {
        if (
            !result.destination ||
            result.destination.index === result.source.index
        ) {
            // do nothing
        } else {
            onSort(result.source.index, result.destination.index, starredBoards)
        }
    }

    return (
        <>
            {!!boards && !!boards.length &&
                <SectionAccordion
                    variant={'simple'}
                    className={NAVIGATION_PANEL_SECTION_CLASS}
                    opened={recentAccordion === ERecentAccordion.RECENT}
                    title={TITLE_RECENT}
                    onOpen={() => onSelectAccordion(ERecentAccordion.RECENT)}
                    onClose={() => onSelectAccordion(ERecentAccordion.STARRED)}
                >
                    {boards.map((board) => {
                        return <BoardsItem
                            key={board.id}
                            id={board.id}
                            name={board.name || BOARD_NAME_DEFAULT}
                            logo={board.logo}
                            logoColor={board.logoColor}
                            logoIcon={board.logoIcon}
                            isMobile={isMobile}
                            closeDisabled={isCloseDisabled}
                            onClick={() => onClickBoard(board.id)}
                            onClose={() => onClose(board.id)}
                        />
                    })}
                </SectionAccordion>
            }
            {/**
             * 1. null in testMode
             * 2. в остальных случаях показываем, даже если [] - подгрузятся после открытия аккордеона
             */
            !!starredBoards &&
            <DragDropContext
                onDragEnd={onDragEnd}
            >
                <SectionAccordion
                    variant={'simple'}
                    className={`${NAVIGATION_PANEL_SECTION_CLASS} ${NAVIGATION_PANEL_SECTION_CLASS}--starred`}
                    opened={recentAccordion === ERecentAccordion.STARRED}
                    title={TITLE_STARRED}
                    onOpen={() => onSelectAccordion(ERecentAccordion.STARRED)}
                    onClose={() => onSelectAccordion(ERecentAccordion.RECENT)}
                >
                    <Droppable
                        droppableId={'droppable-2'}
                    >
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                    {isLoadingStarredHook &&
                        <Loader className={`${NAVIGATION_PANEL_SECTION_CLASS}-starred-loader`} />
                    }
                    {starredBoards.map((board, index) => {
                        return (
                            <Draggable
                                draggableId={String(board.id)}
                                index={index}
                                key={String(board.id)}
                            >
                                {(provided, snapshot) => {
                                    return (
                                        <div
                                            className={snapshot.isDragging? `${CLASS_LIST_ITEM_CONTAINER}--drag` : CLASS_LIST_ITEM_CONTAINER}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            ref={provided.innerRef}
                                        >
                                            <BoardsItem
                                                key={board.id}
                                                id={board.id}
                                                name={board.name || BOARD_NAME_DEFAULT}
                                                logo={board.logo}
                                                logoColor={board.logoColor}
                                                logoIcon={board.logoIcon}
                                                isMobile={isMobile}
                                                isStarred
                                                onClick={() => onClickBoard(board.id)}
                                                onUnstar={() => snapshot.isDragging ? null : onUnstar(board.id)}
                                            />
                                        </div>
                                    );
                                }}
                            </Draggable>)
                    })}
                    {!isLoadingStarredHook && !starredBoards.length &&
                        <div className={`${NAVIGATION_PANEL_SECTION_CLASS}-empty`}>
                            {TEXT_STARRED_EMPTY}
                        </div>
                    }
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </SectionAccordion>
            </DragDropContext>
            }
        </>
    );
};
