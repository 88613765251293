import * as React from 'react';
import './_Body.scss';
import { ListHOC } from '../../../list/hocs/ListHOC/ListHOC';
import { CreateNewHOC } from '../../../CreateNew/hocs/CreateNewHOC/CreateNewHOC';
import { IBodyProps } from './types';
import { TemplatesSearchSelectHOC } from '../../../TemplatesSearchSelect/hocs/TemplatesSearchSelectHOC';

export function Body({
    boardId,
    isRecurring,
}: IBodyProps) {
    return (
        <React.Fragment>
            <div className={'card-templates__header'}>
                <TemplatesSearchSelectHOC boardId={boardId} isRecurring={isRecurring} />
                <CreateNewHOC boardId={boardId} isRecurring={isRecurring} />
            </div>
            <ListHOC boardId={boardId} isRecurring={isRecurring} />
        </React.Fragment>
    );
}
