import { createRoot } from 'react-dom/client';
import React from "react";
import { Provider } from "react-redux";
import { InviteDialogHOC } from '../react_components/dialogs/invite/hocs/InviteDialogHOC/InviteDialogHOC';

App.Views.InviteView = App.Views.BaseView.extend({
    initialize: function (options) {
        this.root = createRoot(this.el);
        this.store = options.store;
    },
    render: function () {
        this.root.render(
            <Provider store={this.store}>
                <InviteDialogHOC />
            </Provider>
        );
        return this;
    },
    remove: function () {
        this.root.unmount();
        Backbone.View.prototype.remove.call(this);
    }
});

export const InviteView = App.Views.InviteView;
