import { TBoardId } from '../../../../../../../../types/types';
import { ThunkAction } from '../../../../../../../../types/actions';
import { getExportSortedColumns } from '../selectors/getExportSortedColumns';
import { saveColumns } from '../effects/saveColumns';

export const onColumnsSort = (
    boardId: TBoardId,
    columnId: string,
    newIndex: number
): ThunkAction => {
    return (
        dispatch,
        getState
    ) => {
        const columns = getExportSortedColumns(getState(), boardId);
        const index = columns.findIndex(column => column.id === columnId);
        const removed = columns.splice(index, 1);
        columns.splice(newIndex, 0, ...removed);

        return dispatch(saveColumns(boardId, columns));
    }
}
