import { IApplicationState, TBoardId, TCardId, TPermissionId } from '../../../../../../../../types/types';
import { ISharedUser } from '../../../../../../../../types/rest/ISharedUser';
import { getBoardUsers } from '../../../../../../../../store/model/selectors/getBoardUsers';
import { createDeepEqualSelector } from '../../../../../../helpers/memoizeHelper';
import { getBoardCards } from '../../../../../../../../store/model/selectors/getBoardCards';
import { getAuthUser } from '../../../../../../../../store/model/authUser/selectors/getAuthUser';
import { getInitials } from 'app/view/react_components/helpers/userPhotoHelper';
import { getCardAssignee } from '../../../../../../../../store/model/cardAssignees/selectors/getCardAssignee';
import {
    IAssigneesSearchSelectOption
} from '../../../../../../aside_panel/cardDetails/AssigneesSection/hocs/AssigneesSearchSelectHOC/types';
import {
    ASSIGNEE_GROUP_DIVIDER,
    ASSIGNEE_GROUP_TITLE,
    TITLE_ASSIGNED_ON_BOARD,
    TITLE_ASSIGNED_OTHER
} from '../../../../../../aside_panel/cardDetails/AssigneesSection/components/constants';

type TgetAssigneesSearchSelectOptions = (
    state: IApplicationState,
    boardId: TBoardId,
    cardId: TCardId,
) => IAssigneesSearchSelectOption[];

const getBoardUsersSelector = (
    state: IApplicationState,
    boardId: TBoardId,
    cardId: TCardId
): ISharedUser[] => {
    const boardUsers = [...getBoardUsers(state, boardId)];
    const boardUserIds = new Set(boardUsers.map(user => user.permissionId)); // вспомогательное множество для проверки дубликатов

    const { assignees: cardAssignees = [] } = getCardAssignee(state, cardId);
    cardAssignees.forEach(assignee => {
        if (!boardUserIds.has(assignee.sharedUser.permissionId)) { // юзер есть на карте, но удалён с доски
            boardUsers.push(assignee.sharedUser); // добавить его
            boardUserIds.add(assignee.sharedUser.permissionId);
        }
    });
    if (!boardUsers.length) return [];

    const { permissionId: authUserPermissionId } = getAuthUser(state);
    const authUser = boardUsers.find((user) => user.permissionId === authUserPermissionId);
    const assignees = boardUsers
        .filter((user) => user.permissionId !== authUserPermissionId)
        .sort((userA, userB) => {
            const getUserName = (user: ISharedUser) => user.fullName ? user.fullName : '';
            return getUserName(userA).localeCompare(getUserName(userB))
        })
    ;
    if (authUser) {
        assignees.unshift(authUser); // сделать себя первым в списке
    }

    return assignees;
};

const getAssigneeIdsSelector = (
    state: IApplicationState,
    boardId: TBoardId,
    cardId: TCardId,
): TPermissionId[] => {
    const { assignees = [] } = getCardAssignee(state, cardId);
    return assignees.map(assignee => assignee.sharedUser.permissionId);
};

const getBoardAssigneeIdsSelector = (
    state: IApplicationState,
    boardId: TBoardId,
    cardId: TCardId
): Set<TPermissionId> => {
    const assigneeIdsSet = new Set<TPermissionId>();
    const cards = getBoardCards(state, boardId);
    cards.forEach(card => {
        const { assignees = [] } = getCardAssignee(state, cardId);
        assignees.forEach(assignee => assigneeIdsSet.add(assignee.sharedUser.permissionId));
    });
    return assigneeIdsSet
};

const getAssigneesSearchSelectOptionsSelector = (
    boardUsers: ISharedUser[],
    assigneeIds: TPermissionId[],
    assigneeNamesSet: Set<TPermissionId>,
): IAssigneesSearchSelectOption[] => {
    const options = boardUsers.map(user => ({
        active: !!assigneeIds.includes(user.permissionId),
        img: user.photoUrl,
        initials: getInitials(user),
        text: user.fullName,
        value: user.permissionId
    }));
    const assigned = options.filter(option => assigneeNamesSet.has(option.value));
    const notAssigned = options.filter(option => !assigneeNamesSet.has(option.value));
    if (
        assigneeNamesSet.size === 0 ||
        assigned.length === options.length ||
        notAssigned.length === options.length
    ) {
        return options;
    }
    const filteredOptions: IAssigneesSearchSelectOption[] = [];
    if (assigned.length) {
        filteredOptions.push({
            text: TITLE_ASSIGNED_ON_BOARD,
            value: ASSIGNEE_GROUP_TITLE,
            options: assigned
        })
    }
    if (notAssigned.length) {
        filteredOptions.push({
            text: TITLE_ASSIGNED_OTHER,
            value: ASSIGNEE_GROUP_DIVIDER,
            options: notAssigned
        })
    }
    return filteredOptions;
};

export const getMyWorkAssigneesSearchSelectOptionsCreateSelector = (
): TgetAssigneesSearchSelectOptions => createDeepEqualSelector(
    getBoardUsersSelector,
    getAssigneeIdsSelector,
    getBoardAssigneeIdsSelector,
    getAssigneesSearchSelectOptionsSelector,
);

export const getMyWorkAssigneesSearchSelectOptions: TgetAssigneesSearchSelectOptions = getMyWorkAssigneesSearchSelectOptionsCreateSelector();
