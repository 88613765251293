import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState, TActivityId } from '../../../../types/types';
import { fetchHandler } from '../../../../util/fetchHandler';
import { REST_ACTIVITIES } from '../../../constants';
import { getRestHeadersGet } from '../../../helpers/getRestHeadersHelper';
import { Util } from '../../../../util/util';
import { IRestActivities } from '../../../../types/rest/activity/IRestActivities';

export const getByIdRest = (
    activityId: TActivityId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return fetchHandler<IRestActivities>(
            Util.getApiUrl(REST_ACTIVITIES + activityId),
            getRestHeadersGet()
        )
    };
    return action;
};
