import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState } from '../../../../../../../types/types';
import {
    SegmentUserEvent, SegmentUserOption,
    SegmentUserSourceValue
} from '../../../../../../../middlewares/segment/trackEntities/userEvents';
import { getAuthUser } from 'app/store/model/authUser/selectors/getAuthUser';
import { extendTrial } from 'app/view/react_components/dialogs/trialExtend/effects/extendTrial';
import { isPwc } from 'app/store/model/authUser/selectors/isPwc';
import { root } from 'app/store/constants';
import { snackbarSuccessDefault } from 'app/view/react_components/snackbarsStack';
import {
    SNACKBAR_ID_START_FIRST_TRIAL,
    START_FIRST_TRIAL_SNACKBAR_TEXT
} from 'app/view/react_components/main/upgradeButton/hocs/FreeVersionButtonHOC/constants';
import { segmentTrackAction } from 'app/middlewares/segment';
import { VirtualPage } from '../../../../../../../middlewares/segment/trackEntities/virtualPages';

export const onClickStartFreeTrial = (
): ThunkAction =>  {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const user = getAuthUser(getState());
        if (!user || !user.firstTrialCode) return Promise.reject();

        dispatch(extendTrial('', user.firstTrialCode))
            .then(() => {
                const pwc = isPwc(getState());
                if (pwc) {
                    root.App.controller.blockerHelper.showBlockerPWCTrial();
                } else {
                    dispatch(snackbarSuccessDefault({
                        id: SNACKBAR_ID_START_FIRST_TRIAL,
                        text: START_FIRST_TRIAL_SNACKBAR_TEXT
                    }));
                }
            });
        dispatch(segmentTrackAction(SegmentUserEvent.CARD_FORM_START_TRIAL_CLICKED, {
            name: SegmentUserOption.SOURCE,
            value: SegmentUserSourceValue.CARD_FORM_TRIAL_BUTTON
        }));
        root.App.controller.trackPage(VirtualPage.CARD_FROM_FREE_TRIAL);
        root.App.controller.trackFacebookCustomEvent(VirtualPage.CARD_FROM_FREE_TRIAL);
    };
    return action;
};
