import { executeAbTest } from 'app/abtesting';
import { AB_TEST_HELP_FEEDBACK_FORM } from 'app/abtesting/abTests/helpFeedbackForm/constants';
import { Dispatch, ThunkAction } from 'app/types/actions';
import { IGetState } from 'app/types/types';

export function onDidMount (
): ThunkAction {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        dispatch(executeAbTest(AB_TEST_HELP_FEEDBACK_FORM));
    };
    return action
};
