import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { EFilterButtonsType } from '../../../store/topBar/types';
import { IGetState } from '../../../../../../../types/types';
import { onDoneCardCounterClick } from '../effects/onDoneCardCounterClick';
import { onMyCardCounterClick } from '../effects/onMyCardCounterClick';
import { onOverdueCardCounterClick } from '../effects/onOverdueCardCounterClick';
import { saveLastUsedFilters } from '../effects/saveLastUsedFilters';
import { getActiveBoardId } from '../../../../../../../store/model/selectors/getActiveBoardId';
import { ETutorial } from '../../../../../../../types/rest/IUserMeta';
import { root } from '../../../../../../../store/constants';
import { getAuthUser } from '../../../../../../../store/model/authUser/selectors/getAuthUser';
import { getIsAssignedToMeActive } from '../../../../../../../store/router/selectors/getIsAssignedToMeActive';
import {
    getProFeaturesTrialActive
} from '../../../../../../../store/model/authUser/selectors/getProFeaturesTrialActive';

export const onFilterButtonClicked = (
    filterType: EFilterButtonsType,
    isActive: boolean
): ThunkAction =>  {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const boardId = getActiveBoardId(state);
        dispatch(saveLastUsedFilters(boardId, filterType));
        const user = getAuthUser(state);
        if (
            !getIsAssignedToMeActive(state) ||
            user['allowMyWorkCustomFilters'] ||
            getProFeaturesTrialActive(state)
        ) {
            root.App.controller.showTutorial(ETutorial.FILTER_TUTORIAL);
        }
        switch (filterType) {
            case EFilterButtonsType.DONE: {
                dispatch(onDoneCardCounterClick(isActive));
                break;
            }
            case EFilterButtonsType.MY_TASKS: {
                dispatch(onMyCardCounterClick(isActive));
                break;
            }
            case EFilterButtonsType.OVERDUE: {
                dispatch(onOverdueCardCounterClick(isActive));
                break;
            }
        }
    };
    return action;
};
