import { getUserPaidSubscriptions } from '../../../../../../store/model/authUser/selectors/getUserPaidSubscriptions';
import { isUserDomainWide } from '../../../../../../store/model/authUser/selectors/isUserDomainWide';
import { IApplicationState } from '../../../../../../types/types';
import { getIsLinkCardActive } from '../../../../../../store/router/selectors/getIsLinkCardActive';
import { getProFeaturesTrialActive } from '../../../../../../store/model/authUser/selectors/getProFeaturesTrialActive';

export const isUpgradeButtonShownSelector = (
    state: IApplicationState,
): boolean => {
    return (
        getProFeaturesTrialActive(state) ||
        !getUserPaidSubscriptions(state).length &&
        !isUserDomainWide(state) &&
        !getIsLinkCardActive(state)
    );
};
