import { IBoardsMoveAction } from './types';
import { AT_BOARDS_MOVE } from './constants';
import { TBoardId } from '../../../../../../../types/types';
import { TGoogleDriveId } from '../../types';

export const boardMove = (
    boardId: TBoardId,
    driveId: TGoogleDriveId
): IBoardsMoveAction => ({
    type: AT_BOARDS_MOVE,
    boardId,
    driveId
});
