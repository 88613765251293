/***
 * default prefix "MY_WORK"
 */

export const AT_LOADED_SET = 'MY_WORK_LOADED_SET';
export const AT_NEW_CARD_ID_ADD = 'MY_WORK_NEW_CARD_ID_ADD';
export const AT_NEW_CARD_ID_REMOVE = 'MY_WORK_NEW_CARD_ID_REMOVE';
export const AT_SHOW_ARCHIVED_SET = 'MY_WORK_SHOW_ARCHIVED_SET';
export const AT_SHOW_BOARDS_SET = 'MY_WORK_SHOW_BOARDS_SET';
export const AT_LOADED_BOARDS_ADD = 'MY_WORK_LOADED_BOARDS_ADD';
export const AT_CLOSED_GROUP_SET = 'MY_WORK_CLOSED_GROUP_SET';
