import { IGetState, TBoardId, TCardAssigneeRoleId } from '../../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { TStatus } from '../../../../../../../../../types/model';
import { boardRoleSetNameStatus } from '../../../../../../../../../rest/effects/role/boardRoleSetNameStatus';
import { getBoardRoleById } from '../../../../../../../../../store/model/board/selectors/getBoardRoleById';
import { SegmentBoardEvent, segmentTrackAction } from '../../../../../../../../../middlewares/segment';
import {
    SegmentBoardOption,
    SegmentBoardOptionStatusValue
} from '../../../../../../../../../middlewares/segment/trackEntities/boardEvents';

export const onChange = (
    boardId: TBoardId,
    roleId: TCardAssigneeRoleId,
    name: string,
    status: TStatus
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {

        const role = getBoardRoleById(getState(), boardId, roleId);
        if (role && role.status !== status) {
            dispatch(segmentTrackAction(SegmentBoardEvent.ROLE_CHECKED, {
                name: SegmentBoardOption.STATUS,
                value: status === TStatus.STATUS_ACTIVE ? SegmentBoardOptionStatusValue.ON : SegmentBoardOptionStatusValue.OFF
            }))
        }

        return dispatch(boardRoleSetNameStatus(boardId, roleId, name, status));
    };
    return action;
};
