import { EFilePermissionRole } from 'app/helper/authorisation/microsoft/graph/api/getFilePermissions';
import { getMessages } from '../../../../../../store/constants';

export const CLASS_SHARING_DIALOG = 'sharing-dialog';
export const PORTAL_ID_SHARING_DIALOG = `${CLASS_SHARING_DIALOG}-dd-portal`;

export const SHARE_DIALOG_ROLE_DEFAULT = EFilePermissionRole.WRITE;

export const SHARE_DIALOG_ROLE_OPTIONS = [
    EFilePermissionRole.WRITE,
    EFilePermissionRole.READ,
];

export const SHARE_DIALOG_ROLE_ICONS: any = {
    [EFilePermissionRole.WRITE]: 'pen',
    [EFilePermissionRole.READ]: 'eye',
};

export const SHARE_DIALOG_TITLE = getMessages().getText('share_dialog_title');
export const SHARE_DIALOG_BUTTON_CANCEL = getMessages().getText('common.cancel');
export const SHARE_DIALOG_BUTTON_DONE = getMessages().getText('common.add');

export const SHARE_DIALOG_INPUT_PLACEHOLDER = getMessages().getText('share_dialog.input.placeholder');
export const SHARE_DIALOG_PEOPLE_WITH_ACCESS = getMessages().getText('share_dialog_people_with_access');
export const SHARE_DIALOG_ROLE_REMOVE = getMessages().getText('common.remove');
export const SHARE_DIALOG_NO_RESULTS = getMessages().getText('common.no_results');
