import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState } from '../../../../../../../types/types';
import { cardTemplatesOpen } from '../../../../../base/effects/cardTemplatesOpen';
import {
    boardTemplatesSegmentEvent,
    ESegmentBoardMenuLocation
} from '../../../../../../../middlewares/segment/trackEntities/boardMenuEvents';
import { getAuthUser } from '../../../../../../../store/model/authUser/selectors/getAuthUser';
import { showSnoozeBlocker } from '../../../../../base/effects/showSnoozeBlocker';

export const onClickCardTemplates = (): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const user = getAuthUser(getState());
        dispatch(boardTemplatesSegmentEvent(ESegmentBoardMenuLocation.topBar, user.allowRecurringTask));
        if (user.allowRecurringTask) {
            dispatch(cardTemplatesOpen());
        } else {
            dispatch(showSnoozeBlocker({
                allow: 'allowRecurringTask',
                subAllow: 'allowTemplates'
            }));
        }
    };
    return action;
};
