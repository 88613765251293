import { IABTest } from '../types';
import { getUpgradeButtonColorConfig } from './upgradeButtonColor/constants';
import { getHelpFeedbackFormConfig } from './helpFeedbackForm/constants';
import { getFirstBoardTypeConfig } from './firstBoardType/constants';
import { getFeedbackIconConfig } from './feedbackIcon/constants';
import { getCommentSortingConfig } from './commentSorting/constants';
import { getVideoVoiceConfig } from './videoVoice/constants';

const abTests: IABTest[] = [
    getUpgradeButtonColorConfig(),
    getHelpFeedbackFormConfig(),
    getFirstBoardTypeConfig(),
    getFeedbackIconConfig(),
    getCommentSortingConfig(),
    getVideoVoiceConfig()
];

export const getABTests =
(): IABTest[] => {
    return [ ...abTests ]
}

export const getABTest = (
    name: string
): IABTest => {
    const abTest = getABTests().find(test => {
        return test.name === name
    });
    return abTest;
}
