import { LoaderBlock } from 'kui';
import * as React from 'react';
import './_FeedbackConainerLazy.scss';

export function LazyLoader() {
    return (
        <div className={'feedback-loader-container'}>
            <LoaderBlock/>
        </div>)
}
