import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { SegmentPricingEvent, segmentTrackAction, SegmentUserEvent } from '../../../../../../../middlewares/segment';
import {
    SegmentUserOption,
    SegmentUserSourceValue
} from '../../../../../../../middlewares/segment/trackEntities/userEvents';
import { dispatch } from '../../../../../../../store/configureStore';
import {
    SegmentPricingOption,
    SegmentPricingSourceValue
} from '../../../../../../../middlewares/segment/trackEntities/pricingEvents';

export const startFirstTrialEvent = (
    source: SegmentUserSourceValue
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(segmentTrackAction(SegmentUserEvent.START_TRIAL_BUTTON_CLICKED, {
            name: SegmentUserOption.SOURCE,
            value: source
        }));
    };
    return action;
}

export const freeHelpEvent = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(segmentTrackAction(SegmentUserEvent.FREE_VERSION_FAQ_CLICKED));
    };
    return action;
}
