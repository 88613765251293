import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../../types/types';
import { IPriorityIndicatorHOCProps } from './types';
import { IPriorityIndicatorFields } from '../../components/PriorityIndicator/types';
import { getPriorityIcon } from './selectors/getPriorityIcon';
import { getPriorityLabel } from './selectors/getPriorityLabel';
import { PriorityIndicator } from '../../components/PriorityIndicator/PriorityIndicator';

const mapStateToProps = (
    state: IApplicationState,
    { priority, isShort }: IPriorityIndicatorHOCProps
): IPriorityIndicatorFields => {
    return {
        priorityIcon: getPriorityIcon(priority),
        priorityLabel: getPriorityLabel(priority),
        isShort: isShort
    };
};

export const PriorityIndicatorHOC = connect(
    mapStateToProps,
    null
)(PriorityIndicator);

PriorityIndicatorHOC.displayName = 'PriorityIndicatorHOC';
