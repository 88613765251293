import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { IGetState, TBoardId } from '../../../../../../../../../types/types';
import { getMessages, root } from '../../../../../../../../../store/constants';
import { SegmentBoardEvent, segmentTrackAction } from '../../../../../../../../../middlewares/segment';
import { getBoard } from '../../../../../../../../../store/model/selectors/getBoardById';
import { boardSetStarred } from '../../../../../../../../../rest/effects/board/boardSetStarred';

export const toggleBoardStarred = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const messages = getMessages();
        root.App.controller.trackEvent(
            messages.getText('ga.category.dashboard'),
            messages.getText('ga.action.dashboard.star')
        );
        const board = getBoard(state, boardId);
        const isStarred = board && board.starred;
        const segmentEvent = isStarred ? SegmentBoardEvent.UNSTARRED_BOARD : SegmentBoardEvent.STARRED_BOARD;
        dispatch(segmentTrackAction(segmentEvent));
        dispatch(boardSetStarred(boardId, !isStarred));
    };
    return action;
};
