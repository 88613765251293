export enum ERecurringRepeatType {
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month',
    YEAR = 'year',
}

export enum ERecurringRepeatMonthType {
    DAY_OF_MONTH = 'dayOfMonth',
    DAY_OF_WEEK_IN_MONTH = 'dayOfWeekInMonth',
}
