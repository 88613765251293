import { connect } from 'react-redux';
import { RecurringHeader } from '../../components/RecurringHeader/RecurringHeader';
import { IApplicationState } from '../../../../../../../../types/types';
import { IRecurringHeaderHOCProps } from './types';
import { getList } from '../../../../../../../../store/model/list/selectors/getList';
import { getUserTimeFormat12 } from '../../../../../../../../store/model/authUser/selectors/getUserTimeFormat12';
import { IRecurringHeaderFields } from '../../components/RecurringHeader/types';
import { TStatus } from '../../../../../../../../types/model';
import { stripHtml } from '../../../../../../base/helpers/stripHtmlHelper';
import { markdown } from '../../../../../../../../helper/markdownHelper';
import { getCard } from '../../../../../../../../store/model/selectors/getCard';

const mapStateToProps = (
    state: IApplicationState,
    {recurringTasks}: IRecurringHeaderHOCProps
): IRecurringHeaderFields => {
    const recurringTask = recurringTasks[0];
    const card = getCard(state, recurringTask.cardId);
    const showPeriodTime = card && !card.epicId;
    const {listId} = recurringTask;
    const list = getList(state, listId);
    return {
        recurringTask,
        listName: list && list.status === TStatus.STATUS_ACTIVE ? stripHtml(markdown(list.name)) : null,
        isUser12HourFormat: getUserTimeFormat12(state),
        showPeriodTime
    };
};

export const RecurringHeaderHOC = connect(
    mapStateToProps,
    null
)(RecurringHeader);
