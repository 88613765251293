export enum EPanelsBlurType {
    LEFT = 'left',
    RIGHT = 'right',
}

export interface IPanelsBlurFields {
    type: EPanelsBlurType;
}

export interface IPanelsBlurEvents {}

export interface IPanelsBlurProps extends
    IPanelsBlurFields,
    IPanelsBlurEvents
{}
