import { connect } from 'react-redux';

import { IApplicationState } from '../../../../../../../../types/types';
import { getTimeZones, getTimezonesSelectOptions } from '../../helpers/timeZoneHelper';
import { ITimeZoneEvents, ITimeZoneFields } from '../../components/TimeZone/types';
import { ITimeZoneHOCProps } from './types';
import { getBoard } from '../../../../../../../../store/model/selectors/getBoardById';
import { TimeZone } from '../../components/TimeZone/TimeZone';
import { onChange } from './events/onChange';
import { onClickLink } from './events/onClickLink';
import { getPanelReadonly } from '../../../../selectors/getPanelReadonly';

const timeZones = getTimeZones();

const mapStateToProps =
(
    state: IApplicationState,
    ownProps: ITimeZoneHOCProps
): ITimeZoneFields => {
    const {boardId} = ownProps;
    const board = getBoard(state, boardId);
    const currentTimeZone = board ? board.timeZone : '';
    const isDisabled = getPanelReadonly(state, boardId, null, null, 'allowChangeTimeZone');
    const options = getTimezonesSelectOptions(timeZones);
    const currentOption = options.find((item) => item.value === currentTimeZone);
    return {
        options,
        text: currentOption ? currentOption.text : '',
        value: currentOption ? currentOption.value : '',
        isDisabled
    }
};

const mapDispatchToProps = (
    dispatch: any,
    { boardId }: ITimeZoneHOCProps
): ITimeZoneEvents => ({
    onChange: (timeZone: string) => dispatch(onChange(boardId, timeZone)),
    onClickLink: () => dispatch(onClickLink())
});

export const TimeZoneHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(TimeZone);
