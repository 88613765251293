import { TFilterAction } from '../../filter/actions/types';
import { AT_SAVED_FILTER_ACTION_SET } from './constants';
import { ISavedFilterActionsSetAction } from './types';

export const savedFilterActionsSet = (
    filterId: number,
    filterAction: TFilterAction
): ISavedFilterActionsSetAction => ({
    type: AT_SAVED_FILTER_ACTION_SET,
    filterId,
    savedFilterAction: filterAction
});
