import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TBoardId, TCardId } from '../../../../../../../../types/types';
import { getCard } from '../../../../../../../../store/model/selectors/getCard';
import { cardOpen } from '../../../../../../base/effects/cardOpen';
import { getAuthUser } from '../../../../../../../../store/model/authUser/selectors/getAuthUser';
import { showSnoozeBlocker } from '../../../../../../base/effects/showSnoozeBlocker';

export const onTemplateLinkClick = (
    boardId: TBoardId,
    cardId: TCardId
): ThunkAction => {
    return (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const card = getCard(state, cardId);
        const { allowRecurringTask } = getAuthUser(getState());
        if (!allowRecurringTask) {
            dispatch(showSnoozeBlocker({
                allow: 'allowRecurringTask',
                subAllow: 'allowTemplates'
            }));
        } else {
            if (!card.templateId) return;
            dispatch(cardOpen(card.templateId));
        }
    };
};
