import { DRIVE_ID_MY_DRIVE } from 'app/view/react_components/dialogs/openBoards/store/drives/constants';
import { IApplicationState, TBoardId } from '../../../../types/types';
import { getBoard } from '../../selectors/getBoardById';

export const getIsBoardOnMyDrive = (
    state: IApplicationState,
    boardId: TBoardId
): boolean => {
    const board = getBoard(state, boardId);

    return board.driveId === DRIVE_ID_MY_DRIVE;
};
