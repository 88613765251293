import { IGetState, TCardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { ICardBatch } from './api/types';
import { cardPatchRestBatch } from './api/helper/cardPatchRestBatch';

export const cardSetStartDateBatch = (
    getState: IGetState,
    cardId: TCardId,
    startDate: number,
    cardBatch: ICardBatch = {card: {}, callbacks: []}
) => {
    cardBatch.card = {
        ...cardBatch.card,
        startDate
    };
    return cardBatch;
};

export const cardSetStartDate = (
    cardId: TCardId,
    startDate: number
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const cardBatch = cardSetStartDateBatch(getState, cardId, startDate);
        return cardPatchRestBatch(cardId, dispatch, cardBatch);
    };
    return action;
};
