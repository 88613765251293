import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { TCardId } from '../../../../../../../../types/types';
import { cardOpen } from '../../../../../../base/effects/cardOpen';
import { SegmentCardEvent, SegmentCardOptions, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { SegmentCardSourceValue } from '../../../../../../../../middlewares/segment/trackEntities/cardEvents';
import {
    cardNameRenameAction
} from '../../../../../../aside_panel/cardDetails/Name/store/cardNameReducer/actions/cardNameRenameAction';
import { openCardIfNeeded } from '../effects/openCardIfNeeded';

export const onRename = (
    cardId: TCardId,
    source: SegmentCardSourceValue
): ThunkAction => {
    const action = (dispatch: Dispatch) => {
        dispatch(segmentTrackAction(SegmentCardEvent.RENAME_CARD_DROPDOWN_CLICKED, {
            name: SegmentCardOptions.SOURCE,
            value: source
        }));
        dispatch(openCardIfNeeded(cardId));
        dispatch(cardNameRenameAction(cardId));
    };

    return action;
};
