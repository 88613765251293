import * as React from 'react';
import './_BackupsText.scss';
import { IBackupsTextProps } from './types';
import { CLASS_BOARD_BACKUPS } from '../../../../constants';
import {
    BOARD_BACKUPS_LINK_TEXT, BOARD_BACKUPS_ONEDRIVE_LINK_TEXT,
    BOARD_BACKUPS_TEXT_LINK_BEGINNING
} from './constants';
import { EAuthUserPlatformType } from 'app/types/rest/IRestAuthUser';
import { Icon } from 'kui';

export function BackupsText ({
    isShow,
    getBackupLink,
    platformType
}: IBackupsTextProps) {
    if (!isShow) return null;

    const className = CLASS_BOARD_BACKUPS + '__text';

    const [link, setLink] = React.useState(null);

    React.useEffect(() => {
        getBackupLink().then(result => {
            setLink(result);
        })
    }, []);

    return (
        <div
            className={className}
        >
            {
                platformType === EAuthUserPlatformType.GOOGLE ?
                    <>
                        <img src="/dist/img/drive-colored.svg"/>
                        {BOARD_BACKUPS_TEXT_LINK_BEGINNING}<a href={link}  target="_blank">{BOARD_BACKUPS_LINK_TEXT}</a>
                    </> :
                    <>
                        <Icon size={24} xlink={'project'} />
                        {BOARD_BACKUPS_TEXT_LINK_BEGINNING}<a href={link}  target="_blank">{BOARD_BACKUPS_ONEDRIVE_LINK_TEXT}</a>
                    </>
            }
        </div>
    );
}
