import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { SegmentBoardEvent, segmentTrackAction } from '../../../../../../../../../middlewares/segment';

export const onClickLink = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(segmentTrackAction(SegmentBoardEvent.CLICKED_MORE_INSTUCTIONS_TIME_ZONE));
    };
    return action;
};
