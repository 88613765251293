import { getBoard } from './getBoardById';
import { root } from '../../constants';
import { createSelector } from 'reselect';
import { IBoard } from '../board/types';
import { ISharedUser } from '../../../types/rest/ISharedUser';
import { EMPTY_ARRAY, IApplicationState, TBoardId } from 'app/types/types';

export const getBoardUsersSelector = (
    board: IBoard
): ISharedUser[] => {
    if (root._debug.selector) {
        console.log('getBoardUsers');
    }
    if (
        !board ||
        !board.users ||
        !board.users.length
    ) return EMPTY_ARRAY;

    return board.users.filter(user => !!user.permissionId);
};

export const getBoardUsersCreateSelector = () => createSelector(
    getBoard,
    getBoardUsersSelector
);

// так как доска обычно одна и та же, можем сделать кеш для getBoardUsers
export const getBoardUsers = getBoardUsersCreateSelector();

// замеры CardProgressHOC показывают, что реселекты там не нужны
export const getBoardUsersNoreselect = (
    state: IApplicationState,
    boardId: TBoardId
): ISharedUser[] => {
    return getBoardUsersSelector(getBoard(state, boardId));
};
