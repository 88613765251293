import './_PollQuestion.scss';
import { EPollQuestionVariant, IPollQuestionProps } from './types';
import * as React from 'react';
import { Button, Icon, Radio } from 'kui';
import { POLL_QUESTION_AGREE, POLL_QUESTION_BAD, POLL_QUESTION_DISAGREE, POLL_QUESTION_GOOD, } from './constants';
import { isArray, isNumber } from 'underscore';
import { CLASS_POLL, POLL_DELAY_AFTER, POLL_RATING_OPTIONS } from '../constants';

export function PollQuestion({
    isChips: isChips,
    isDisabled,
    isHideTitle,
    options,
    question,
    value,
    variant,
    setDisabled,
    setValue,
}: IPollQuestionProps) {
    const className = CLASS_POLL + '__question';
    const classOptions = className + '-options';
    const classText = classOptions + '-text';
    const classButton = classOptions + '-button';
    const classChip = className + '-chip';

    const [selected, setSelected] = React.useState(value);

    const onStarClick = (idx: number) => {
        setDisabled(true);
        setSelected(idx);
        setTimeout(() => {
            setValue(idx);
            setDisabled(false);
        }, POLL_DELAY_AFTER * 0.75);
    };

    return (
        <div
            className={`
                ${className}
                ${className + '--' + variant}
                ${options && value || !options && isNumber(value) ? className + '--selected' : ''}
            `}
        >
            {!isHideTitle &&
                <span className={className + '-title'}>
                    {question}
                </span>
            }
            <div
                className={`
                    ${classOptions}
                    ${options && !isChips ? classOptions + '--custom' : ''}
                    ${isChips ? classOptions + '--chips' : ''}
                `}
            >
                {options ?
                    isChips ?
                        options.map(({ label }) => (
                            <Button
                                key={label}
                                className={`
                                    ${classChip}
                                    ${isArray(value) && value.includes(label) ? classChip + '--selected' : ''}
                                `}
                                onClick={() => setValue(label)}
                            >
                                {label}
                            </Button>
                        ))
                        : <Radio
                            active={options.findIndex(option => option.label === value)}
                            onChange={e => setValue(options[e.index].label)}
                            direction={'left'}
                        >
                            {options.map(option => {
                                const Icon = option.icon && require(`!!@svgr/webpack!./icons/${option.icon}.svg`).default;
                                return <div key={option.label}>
                                    {option.icon && <Icon />}
                                    {option.label}
                                </div>;
                            })}
                        </Radio>
                    : <>
                        <span className={classText}>
                            {variant === EPollQuestionVariant.STAR ? POLL_QUESTION_BAD : POLL_QUESTION_DISAGREE}
                        </span>
                        {variant === EPollQuestionVariant.STAR ?
                            <>
                                {POLL_RATING_OPTIONS.map((option, idx) =>
                                    <Button
                                        key={idx}
                                        className={`
                                            ${classButton}
                                            ${Number(selected) && idx <= selected ? classButton + '--selected' : ''}
                                        `}
                                        disabled={isDisabled}
                                        variant={'icon'}
                                        onClick={() => onStarClick(idx)}
                                    >
                                        <Icon xlink={'star'} size={24} />
                                    </Button>
                                )}
                            </>
                            : <Radio
                                active={isNumber(value) ? value : null}
                                onChange={e => setValue(e.index)}
                            >
                                {POLL_RATING_OPTIONS.map((option, idx) => <div key={idx}></div>)}
                            </Radio>
                        }
                        <span className={classText}>
                            {variant === EPollQuestionVariant.STAR ? POLL_QUESTION_GOOD : POLL_QUESTION_AGREE}
                        </span>
                    </>
                }
            </div>
        </div>
    );
}
