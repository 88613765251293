import { root } from '../../../../store/constants';
import { ISegmentEventOption, ISegmentTrackQueueItem, TSegmentTrackPayload } from '../../trackEntities/types';
import { isCookiePerformanceAllowed } from '../../../../view/react_components/dialogs/cookies';

const analyticsOnLoad = (): void => {
    if (root.analytics && tracksQueue && tracksQueue.length) {
        tracksQueue.forEach((queueItem) => {
            processTrack(queueItem.action, queueItem.option, queueItem.additionalOptions);
        })
    }
    tracksQueue = [];
    root.analyticsOnLoad = null;
}

let tracksQueue: ISegmentTrackQueueItem[] = [];

export const processTrack = (action: TSegmentTrackPayload, option: ISegmentEventOption, additionalOptions: ISegmentEventOption[]) => {
    let actionName = `${action.event}`;
    let categoryName = `${action.category}`;
    let eventName = `${action.category} : ${actionName}`;
    let label = option? `${option.name}-${option.value}` : undefined;

    if (!root.analytics) {
        if (!root.analyticsOnLoad)
            root.analyticsOnLoad = analyticsOnLoad;
        tracksQueue.push({ action, option, additionalOptions });
        return;
    }

    if (isCookiePerformanceAllowed()) {
        let labelProperty: {[k: string]: any} = {};
        if (option){
            labelProperty.label =  label;
        }
        let trackOption: {[k: string]: any} = {
            'category': categoryName,
            ...labelProperty
        };
        if (option){
            fixForGA(option, '_');
            trackOption[option.name] = option.value;
        }

        if (additionalOptions && additionalOptions.length){
            additionalOptions.forEach(additionOption => {
                if (additionOption) {
                    fixForGA(additionOption, '__');
                    trackOption[additionOption.name] = additionOption.value;
                }
            })
        }

        root.analytics.track(eventName, {
            ...trackOption
        });
    }
    if (root.App.controller) {
        root.App.controller.trackEvent(categoryName, actionName, label);
    } else {
        setTimeout(()=> {
            // for avoid 'controller is undefine' error on send event from Controller constructor
            root.App.controller.trackEvent(categoryName, actionName, label);
        }, 100);
    }
};

const fixForGA = (
    option: ISegmentEventOption,
    postfix: string
) => {
    if (option){
        if(option.name === 'category'){
            option.name =  option.name+postfix;
        }
        if(option.name === 'label'){
            option.name =  option.name+postfix;
        }
    }
};
