import * as React from 'react';
import { IRepeatTypeSelectProps } from './types';
import { Select } from 'kui';
import { ISelectActiveInheritedProps, ISelectOptionsArray } from 'kui';
import { RECURRING_REPEAT_TYPES } from './constants';
import { CardRecurringFormContext } from '../CardRecurringForm/constants';

export function RepeatTypeSelect({
    className,
}: IRepeatTypeSelectProps) {

    const {form, updateForm, isReadonly} = React.useContext(CardRecurringFormContext);

    const types: ISelectOptionsArray = RECURRING_REPEAT_TYPES.map(type => ({
        value: type.type,
        text: type.title + (form.repeatEvery > 1 ? 's' : ''),
    }));
    const typeIndex = types.findIndex(option => option.value === form.repeatType);

    const onTypeChange = (event: ISelectActiveInheritedProps) => {
        if (event.item) {
            const repeatType = RECURRING_REPEAT_TYPES.find(type => type.type === event.item.value).type;
            updateForm({ repeatType });
        }
    }

    return <Select
        className={className}
        active={typeIndex}
        editable={false}
        isFitWindow={true}
        options={types}
        variant={'arrow'}
        onChange={onTypeChange}
        readOnly={isReadonly}
    />
}
