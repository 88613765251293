/***
 * Если передали { 1: {}, 2: {}} , то вернет [1,2]
 * @param map
 */
export const getNumberKeys = (
   map: {
       [key: number]: any;
   }
): number[] => {
    if (!map) {
        return [];
    }
    return Object.keys(map).map(Number);
};
