import * as React from 'react';
import './_ProFeatureIndicator.scss';
import { IProFeatureIndicatorProps } from './types';
import { PRO_FEATURE_INDICATOR_TITLE } from './constants';

export const ProFeatureIndicator = ({
}: IProFeatureIndicatorProps) => {
    return (
        <div className={'pro-feature-indicator'}>
            {PRO_FEATURE_INDICATOR_TITLE}
        </div>
    );
}
