import { IGetState, TBoardId, TCardId, TListId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { snackbarSuccessDefault } from '../../snackbarsStack';
import { getMessages, root } from '../../../../store/constants';

export const copyLink = (
    cardId: TCardId,
    boardId: TBoardId,
    listId?: TListId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        let link;
        if (listId) {
            link = root.App.router.getAbsoluteListPageUrl(boardId, listId)
        } else {
            link = root.App.router.getAbsoluteCardPageUrl(boardId, cardId);
        }

        if (navigator.clipboard) {
            navigator.clipboard.writeText(link).then(() => {
                dispatch(snackbarSuccessDefault({
                    id: 'SNACKBAR_ID_LINK_COPIED',
                    text: getMessages().getText('card_form_getlink_copied')
                }));
            });
        } else {
            let copyText = '<p>Press <strong>Ctrl+C</strong> to copy link to clipboard and press OK.</p>' +
                '<p style="margin-bottom: 0;"><textarea id="js-card-link" class="textarea-info autoSelect" autofocus>' +
                link + '</textarea></p>';
            root.App.controller.showInfo(copyText, getMessages().getText('dialog__get_link_to_card'));
            $('#js-card-link').select().on('click', function() {
                // @ts-ignore
                this.select();
            });
        }
    };
    return action;
};
