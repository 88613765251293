import * as React from 'react';
import './_TabDetails.scss';
import { ITabDetailsProps } from './types';
import { BoardNameHOC } from '../../../BoardName/hocs/BoardNameHOC/BoardNameHOC';
import { BoardDescriptionHOC } from '../../../BoardDescription/hocs/BoardDescriptionHOC/BoardDescriptionHOC';
import { BoardAuthorHOC } from '../../../Author/hocs/BoardAuthorHOC/BoardAuthorHOC';
import { ESettingsSectionType } from '../../../../../../base/components/SettingsSection/types';
import { CLASS_BOARD_DETAILS, CLASS_BOARD_DETAILS_READ_ONLY } from '../../../../constants';
import { BoardStarredButtonHOC } from '../../../StarredButton/hocs/BoardStarredButtonHOC/BoardStarredButtonHOC';
import { LinksToCardsSection } from '../../../LinksToCards/components/LinksToCardsSection/LinksToCardsSection';
import {
    EmailNotificationsSection
} from '../../../Notifications/components/EmailNotificationsSection/EmailNotificationsSection';
import { TimeZoneSection } from '../../../TimeZone/components/TimeZoneSection/TimeZoneSection';
import { BoardEmailSection } from '../../../Email/components/BoardEmailSection/BoardEmailSection';
import { ArchiveHOC } from '../../../Archive/hocs/ArchiveHOC/ArchiveHOC';
import { CardPropertiesSection } from '../../../CardProperties/components/CardPropertiesSection/CardPropertiesSection';
import {
    CardCustomPropertiesSection
} from '../../../CardCustomProperties/components/CardCustomPropertiesSection/CardCustomPropertiesSection';
import { EstimateTimeSection } from '../../../EstimateTime/components/EstimateTimeSection/EstimateTimeSection';
import {
    PushNotificationsSectionHOC
} from '../../../Notifications/hocs/PushNotificationsSectionHOC/PushNotificationsSectionHOC';
import { TagsSection } from '../../../Tags/components/TagsSection/TagsSection';
import { PwcImportSection } from '../../../PwcImport/components/PwcImportSection/PwcImportSection';
import { DatesSection } from '../../../Dates/components/DatesSection/DatesSection';
import {
    BoardImportSection
} from 'app/view/react_components/aside_panel/boardDetails/details/ImportBoard/components/BoardImportSection/BoardImportSection';
import { ColorsSection } from '../../../Colors/components/ColorsSection/ColorsSection';
import { RolesSectionHOC } from '../../../Roles/hocs/RolesSectionHOC/RolesSectionHOC';
import { CardAgingSectionHOC } from '../../../CardAging/hocs/CardAgingSectionHOC/CardAgingSectionHOC';
import { PrefixesSectionHOC } from '../../../CardNumber/hocs/PrefixesSectionHOC/PrefixesSectionHOC';
import { StatusListsSectionsHOC } from '../../../StatusLists/hocs/StatusListsSectionsHOC/StatusListsSectionsHOC';
import { GanttSectionHOC } from '../../../ShowOnGantt/hocs/GanttSectionHOC/GanttSectionHOC';
import {
    ArchiveOnDeletePanelSectionHOC
} from '../../../ArchiveOnDelete/hocs/ArchiveOnDeletePanelSectionHOC/ArchiveOnDeletePanelSectionHOC';

export function TabDetails ({
    boardId,
    sectionType,
}: ITabDetailsProps) {
    const isReadonly = sectionType === ESettingsSectionType.READONLY;
    const className = CLASS_BOARD_DETAILS;
    const classNameReadonly = isReadonly ? CLASS_BOARD_DETAILS_READ_ONLY : '';
    const classSectionAuthorAndStarred = CLASS_BOARD_DETAILS + '-author-and-starred';
    const classSectionTitle = CLASS_BOARD_DETAILS + '-section-title';

    return (<div className={`
            ${className}
            ${classNameReadonly}
        `}>
        <div className={classSectionAuthorAndStarred}>
            <BoardAuthorHOC boardId={boardId} />
            <BoardStarredButtonHOC boardId={boardId} />
            <ArchiveHOC boardId={boardId} />
        </div>

        <div className={classSectionTitle}>
            <BoardNameHOC boardId={boardId} isReadonly={isReadonly}/>
            <BoardDescriptionHOC boardId={boardId}/>
        </div>

        <EmailNotificationsSection />

        <PushNotificationsSectionHOC boardId={boardId} />

        <PrefixesSectionHOC boardId={boardId} />

        <CardPropertiesSection />

        <CardCustomPropertiesSection />

        <CardAgingSectionHOC boardId={boardId} />

        <ColorsSection />
        <TagsSection />
        <RolesSectionHOC />

        <GanttSectionHOC />

        <ArchiveOnDeletePanelSectionHOC />

        <LinksToCardsSection />

        <StatusListsSectionsHOC />

        <DatesSection />

        <EstimateTimeSection />

        <TimeZoneSection />

        <BoardEmailSection />
        <PwcImportSection />
        <BoardImportSection />
    </div>)
}
