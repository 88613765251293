import {ActionCreator} from 'redux';
import {Dispatch, ThunkAction} from '../../../../types/actions';
import {IGetState, TBoardId, TCardId} from '../../../../types/types';
import {getSelectedCards} from '../selectors/getSelectedCards';
import {clickManagerActionSet} from '../store/clickManager/actions/clickManagerActionSet';
import {selectedCardIdsSet} from '../store/clickManagerModel/actions/selectedCardIdsSet';
import { getIsMultiselect } from 'app/view/react_components/clickManager/selectors/getIsMultiselect';

export const selectRelatedCard: ActionCreator<ThunkAction> = (
    boardId: TBoardId,
    cardId: TCardId,
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const selectedCards = getSelectedCards(state, boardId);
        let newSelection: number[];
        if (selectedCards.includes(cardId)) {
            newSelection = selectedCards.filter(item => {
                if (item !== cardId) {
                    return item;
                }
            });
        } else {
            newSelection = [...selectedCards, cardId];
        }
        const isMultiselect = getIsMultiselect(state, boardId)
        dispatch(clickManagerActionSet(boardId, selectedCardIdsSet(newSelection, isMultiselect)));

    };
    return action;
};
