const category = 'Archive';

export const SegmentArchiveEvent = {
    OPEN: { category, event: 'Archive toggle clicked (success)' },
    DISALLOW: { category, event:  'Archive toggle clicked (no success)' },
    ARCHIVE_LIST: { category, event: 'List with all cards archived' },
    UNARCHIVE_LIST: { category, event: 'List unarchived with all cards' },
    CARD_ARCHIVED: { category, event: 'Card archived' },
    EPIC_ARCHIVED: { category, event: 'Epic with its subcards archived' },
    CARD_UNARCHIVED: { category, event: 'Card unarchived' },
    ARCHIVE_CARD_NO_SUCCESS: { category, event: 'Card Archived (no success)' }
}
