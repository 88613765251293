export interface IRestNotificationSettings {
    id?: string,
    enabled?: boolean,
    notificationEmail?: string,
    notificationEmailStatus?: ENotificationEmailStatus,
    period?: ENotificationPeriod,
    scope?: ENotificationScope[]
}

export enum ENotificationScope {
    ALL = 'all',
    ASSIGN_TO_ME = 'assignedToMe',
    WATCH = 'watch'
}

export enum ENotificationPeriod {
    SOON = 10,
    HOUR = 60,
    DAY = 1440
}

export enum ENotificationEmailStatus {
    NOT_DEFINED = 'notDefined',
    AWAITING = 'awaitingVerification',
    ACTIVE = 'active',
    FILLING_EMAIL = 'fillingEmail'
}
