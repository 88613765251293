import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState } from '../../../../../../../types/types';
import { freeHelpEvent } from '../effects/segmentEvents';

export const onClickFreeHelp = (
): ThunkAction =>  {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(freeHelpEvent());
    };
    return action;
};
