import { IAsidePanelTab } from '../../../types';
import { IApplicationState } from '../../../../../../../types/types';
import { EBoardTab } from '../../../../boardDetails/types';

export const getActiveTabIndexByTabKey = (
    state: IApplicationState,
    tabs: IAsidePanelTab[] | ReadonlyArray<IAsidePanelTab>,
    tabKey: EBoardTab
): number => {
    let activeTabIndex = tabs.findIndex(tab => tab.key === tabKey);
    activeTabIndex = activeTabIndex !== -1 ? activeTabIndex : 0;
    const tab = tabs[activeTabIndex];
    if (tab.checkPermission && !tab.checkPermission(state)) {
        activeTabIndex = 0;
    }
    return activeTabIndex;
}
