import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { CardRecurringSection } from '../../components/CardRecurringSection/CardRecurringSection';
import { ICardRecurringEvents, ICardRecurringFields, } from '../../components/CardRecurringSection/types';
import { ICardRecurringHOCProps } from './types';
import { getSectionType } from '../../../selectors/getSectionType';
import { getCardRecurringTasks } from '../../../../../../../store/model/selectors/getCardRecurringTasks';

const mapStateToProps = (
    state: IApplicationState,
    props: ICardRecurringHOCProps,
): ICardRecurringFields => {
    const { boardId, cardId, sectionType: sectionTypeOwn } = props;
    const sectionType = getSectionType(state, boardId, cardId, sectionTypeOwn);
    const recurringTasks = getCardRecurringTasks(state, cardId);

    return {
        sectionType,
        hasRecurring: Boolean(recurringTasks.length)
    }
};

const mapDispatchToProps = (
    dispatch: any,
    props: ICardRecurringHOCProps,
): ICardRecurringEvents => {
    return {}
};

export const CardRecurringSectionHOC = connect(
    mapStateToProps,
    mapDispatchToProps,
)(CardRecurringSection);

CardRecurringSectionHOC.displayName = 'CardRecurringSectionHOC';
