import { IApplicationState } from '../../../../types/types';
import { getAuthUser } from './getAuthUser';
import { EAuthUserPlatformType } from 'app/types/rest/IRestAuthUser';

export const isMicrosoftAccount = (
    state: IApplicationState,
): boolean => {
    const authUser = getAuthUser(state);
    return authUser.platformType === EAuthUserPlatformType.MICROSOFT;
};
