import { EMPTY_ARRAY, IApplicationState } from 'app/types/types';
import { connect } from 'react-redux';
import { RecentBoards } from '../../components/RecentBoards/RecentBoards';
import { IRecentBoardsEvents, IRecentBoardsFields } from '../../components/RecentBoards/types';
import { onClickBoard } from './events/onClickBoard';
import { ERecentAccordion, IRecentBoard } from 'app/types/rest/IUserMeta';
import { onCloseBoard } from './events/onCloseBoard';
import { getCurrentNavigationPanelType } from '../../../helpers/getCurrentNavigationPanelType';
import { ENavigationPanelTypes } from '../../../constants';
import { getAuthUser } from 'app/store/model/authUser/selectors/getAuthUser';
import { getStarredBoardsCreateSelector } from './selectors/getStarredBoards';
import { loadStarred } from './effects/loadStarred';
import { getRecentBoardsCreateSelector } from './selectors/getRecentBoards';
import { getIsCompleted } from 'app/view/react_components/dialogs/openBoards/store/common/selectors/getIsCompleted';
import { TTabKey } from 'app/view/react_components/dialogs/openBoards/store/types';
import { onUnstar } from './events/onUnstar';
import { updateRecentAccordion } from 'app/view/react_components/main/navigationPanel/RecentBoards/hocs/RecentBoardsHOC/effects/updateRecentAccordion';
import { onSort } from 'app/view/react_components/main/navigationPanel/RecentBoards/hocs/RecentBoardsHOC/events/onSort';

const mapStateToProps = () => {
    const getRecentBoards = getRecentBoardsCreateSelector();
    const getStarredBoards = getStarredBoardsCreateSelector();

    const _mapStateToProps = (
        state: IApplicationState,
    ): IRecentBoardsFields => {
        const authUser = getAuthUser(state);
        const recentAccordion = authUser.meta && authUser.meta.recentAccordion || ERecentAccordion.RECENT;

        let boards: IRecentBoard[] = authUser.testMode
            ? EMPTY_ARRAY
            : getRecentBoards(state)

        let starredBoards: IRecentBoard[] = authUser.testMode
            ? null
            : getStarredBoards(state);

        return {
            boards,
            starredBoards,
            isMobile: getCurrentNavigationPanelType(state) === ENavigationPanelTypes.NP_MOBILE_TYPE,
            isLoadingStarred: !getIsCompleted(state, TTabKey.starred),
            recentAccordion
        }
    };
    return _mapStateToProps;
}

const mapDispatchToProps = (
    dispatch: any,
): IRecentBoardsEvents => {
    return {
        loadStarred: () => dispatch(loadStarred()),
        onClickBoard: (boardId: number) => dispatch(onClickBoard(boardId)),
        onCloseBoard: (boardId: number) => dispatch(onCloseBoard(boardId)),
        onSort: (sourceIndex, destinationIndex, boards) => dispatch(onSort(sourceIndex, destinationIndex, boards)),
        onUnstar: (boardId) => dispatch(onUnstar(boardId)),
        updateRecentAccordion: (recentAccordion: ERecentAccordion) => dispatch(updateRecentAccordion(recentAccordion))
    }
};

export const RecentBoardsHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(RecentBoards);

RecentBoardsHOC.displayName = 'RecentBoardsHOC';
