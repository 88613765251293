import { IGetState, TCardId } from '../types/types';
import { Dispatch, ThunkAction } from '../types/actions';
import { getCard } from '../store/model/selectors/getCard';
import { getMessages, root } from '../store/constants';

export const openCardActivity = (
    cardId: TCardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const card = getCard(getState(), cardId);
        if (card) {
            root.App.controller.asidePanel.showCardActivity(cardId);
        } else {
            root.App.controller.showError(getMessages().getText('error.record.not_found'));
        }
    };
    return action;
}
