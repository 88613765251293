import * as React from 'react';
import { ICardDefaultTemplateProps } from './types';
import { CARD_CLASS } from '../../constants';
import './_CardDefaultTemplate.scss';
import { CARD_DEFAULT_TEMPLATE } from './constants';

export function CardDefaultTemplate({}: ICardDefaultTemplateProps) {
    const className = CARD_CLASS + '__c-default-template';
    return (
        <span className={className}>{CARD_DEFAULT_TEMPLATE}</span>
    );
}
