import * as React from 'react';
import { CardCountIndicator } from '../CardCountIndicator/CardCountIndicator';
import { ISubCardsCountIndicatorProps } from './types';
import { SUBCARDS_TOOLTIP } from '../../wording';

export function SubCardsCountIndicator({
    subCardsCount
}: ISubCardsCountIndicatorProps) {
    return (
        <CardCountIndicator icon={'subcard'} tooltip={SUBCARDS_TOOLTIP}>
            <span>{subCardsCount}</span>
        </CardCountIndicator>
    );
}
