import {IGetState, TCardId} from '../../../../../../../../types/types';
import {Dispatch, ThunkAction} from '../../../../../../../../types/actions';
import {
    SegmentArchiveEvent,
    SegmentCardEvent,
    SegmentCardOptions,
    SegmentCardSection,
    segmentTrackAction
} from '../../../../../../../../middlewares/segment';
import {isBoardCardsLimitReached} from '../../../../../../../../store/model/selectors/isBoardCardsLimitReached';
import {getBoardIdByCardId} from '../../../../../../../../store/model/selectors/getBoardIdByCardId';
import {showBoardLimitSnackbarAndEvent} from '../../../../../../../../store/model/effects/showBoardLimitSnackbarAndEvent';
import {SegmentUserTypeOption} from '../../../../../../../../middlewares/segment/trackEntities/userEvents';
import {cardsUnarchive} from '../../../../../../../../rest/effects/card/card/cardsUnarchive';
import {snackbarCardUnarchived} from '../../../../../../snackbarsStack';
import {closePanel} from '../../../../../asidePanel/effects/closePanel';

export const onUnarchive = (
    cardId: TCardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.BUTTONS_PANEL_BUTTON_CLICKED, {
            name: SegmentCardOptions.TARGET,
            value: SegmentCardSection.UNARCHIVE
        }));

        if (isBoardCardsLimitReached(getState(), getBoardIdByCardId(getState(), cardId) , 1)){
            dispatch(showBoardLimitSnackbarAndEvent(SegmentUserTypeOption.CARD_CREATION));
        } else {
            dispatch(closePanel());
            dispatch(cardsUnarchive([cardId]))
                .then(() => {
                    dispatch(segmentTrackAction(SegmentArchiveEvent.CARD_UNARCHIVED));
                    dispatch(snackbarCardUnarchived());
                });
        }
    };
    return action;
};
