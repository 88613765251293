import { getAuthUser } from './getAuthUser';
import { IApplicationState } from '../../../../types/types';

export const getUserDomain = (
    state: IApplicationState
): string => {
    const user = getAuthUser(state);
    return user.email
        ? user.email.split('@')[1]
        : '';
};
