import * as React from 'react';
import {
    CLASS_ACTIVITY_ITEM,
    CLASS_ACTIVITY_ITEM_TEMP
} from '../../../../boardDetails/activity/TabActivity/components/constants';
import './_ActivityColorTags.scss';
import { IActivityColor, IActivityColorTagsProps } from './types';
import { Icon, Tooltip } from 'kui';
import { renderToStaticMarkup } from 'react-dom/server';
import ReactHtmlParser from 'react-html-parser';
import { markdown } from '../../../../../../../helper/markdownHelper';
import {
    ActivityItemMore
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemMore/ActivityItemMore';
import { escape } from 'underscore';
import { GOOGLE_SPACING } from '../../../../../../../const';

export function ActivityColorTags({
    asidePanelWidth,
    colors
}: IActivityColorTagsProps) {
    const className = CLASS_ACTIVITY_ITEM + '__colors';
    const classColor = className + '-item';
    const classTooltipItem = className + '-tooltip-item';
    const classTooltipItemName = className + '-tooltip-item-name';

    const [isNeedToCalculate, setNeedToCalculate] = React.useState(true);
    const [visibleElements, setVisibleElements] = React.useState([]);
    const [tooltip, setTooltip] = React.useState(null);

    const ref = React.useRef(null);
    const tempRef = React.useRef(null);

    React.useEffect(() => {
        setNeedToCalculate(true);
    }, [colors, asidePanelWidth]);

    React.useEffect(() => {
        if (!isNeedToCalculate) return;

        const newElements: IActivityColor[] = [];

        const container = ref.current as HTMLElement;
        const tempContainer = tempRef.current as HTMLElement;
        if (!container || !tempContainer) return;

        const maxWidth = container.offsetWidth - GOOGLE_SPACING * 2 // место для плашки с плюсом
        let currentWidth = 0;

        for (let i = 0; i < colors.length; i++) {
            const colorEl = tempContainer.children[i] as HTMLElement;
            if (!colorEl) return;

            const minWidth = Math.min(colorEl.offsetWidth, GOOGLE_SPACING * 18)
            const colorWidth = minWidth + GOOGLE_SPACING; // + gap
            currentWidth += colorWidth;
            if (currentWidth >= maxWidth) {
                break;
            }
            newElements.push({ ...colors[i], minWidth });
        }

        setVisibleElements(newElements);

        const dif = colors.length - newElements.length;
        if (dif) {
            setTooltip(
                colors.slice(newElements.length).reduce((tooltip, color) => {
                    return tooltip + (`${renderToStaticMarkup(
                        <div className={`${classTooltipItem} ${classTooltipItem}--${color.color}`}>
                            <Icon size={24} xlink={'color-tag'} />
                            <div className={classTooltipItemName}>{ReactHtmlParser(markdown(color.name))}</div>
                        </div>
                    )}`);
                }, '')
            );
        }

        setNeedToCalculate(false);
    }, [isNeedToCalculate, colors, asidePanelWidth]);

    return (
        <>
            <div
                className={`
                    ${className}
                    ${isNeedToCalculate ? className + '--temp' : ''}
                    ${colors && colors.length > 1 ? className + '--multiple' : ''}
                `}
                ref={ref}
            >
                {visibleElements.map(({ name, color, minWidth }, idx) => (
                    <Tooltip value={escape(name)} isNoWrap key={idx}>
                        <span className={`${classColor} ${classColor}--${color}`} style={{ minWidth }}>{name}</span>
                    </Tooltip>
                ))}
                {visibleElements.length !== colors.length &&
                    <ActivityItemMore count={colors.length - visibleElements.length} tooltip={tooltip} />}
            </div>
            {isNeedToCalculate &&
                <div
                    className={`${className} ${CLASS_ACTIVITY_ITEM_TEMP}`}
                    ref={tempRef}
                >
                    {colors.map((color, idx) => (
                        <span
                            className={`${classColor} ${classColor}--${color.color}`}
                            id={color.name + color.color}
                            key={idx}
                        >
                            {color.name}
                        </span>
                    ))}
                </div>
            }
        </>
    );
};
