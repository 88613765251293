import { IApplicationState, TCardId } from '../../../types/types';
import { getCard } from './getCard';

export const getCardSubcardsCount = (
    state: IApplicationState,
    cardId: TCardId
): number => {
    const card = getCard(state, cardId);
    if (
        !card ||
        !card.subCardIds
    ) return 0;

    return card.subCardIds.length;
}
