import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { boardCreateOnSharedDriveClickedSegmentEvent } from '../../../effects/segmentEvents';

export const onClick = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(boardCreateOnSharedDriveClickedSegmentEvent());
    };
    return action;
}
