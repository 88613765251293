import * as React from 'react';
import { Modal, Button, Radio } from 'kui';
import { IFreeTrialModalProps } from './types';
import './FreeTrialModal.scss';
import { ANNUALLY, MONTHLY, PRICE_PER_MONTH, PRICE_PER_MONTH_WITH_DISCOUNT } from '../../constants';

export function FreeTrialModal({
   onClickBackToSite,
   onClickStartFreeTrial,
   onClose
  }: IFreeTrialModalProps) {
    const pricePerMonthWithDiscount = +PRICE_PER_MONTH_WITH_DISCOUNT.toFixed(2);
    const fixedPrice = PRICE_PER_MONTH.toFixed(2);
    const fixedPriceWithDiscount = pricePerMonthWithDiscount.toFixed(2);

    const [price, setPrice] = React.useState(pricePerMonthWithDiscount);
    const [paymentType, setPaymentType] = React.useState('annually');

    const annualPayment = (+fixedPrice * 12).toFixed(2);
    const yearlyDiscount = (+annualPayment - (price * 12)).toFixed(2);
    const annualPaymentWithDiscount = (+annualPayment - +yearlyDiscount).toFixed(2)

    const setPriceValue = (index: number) => {
        if (index === 1) {
            setPrice(PRICE_PER_MONTH);
            setPaymentType(MONTHLY)
        } else if (index === 0) {
            setPrice(pricePerMonthWithDiscount);
            setPaymentType(ANNUALLY)
        }
    }

    const Arrow = require('!!@svgr/webpack!./arrow-left.svg').default;
    const Logo = require('!!@svgr/webpack!./kanbanchi-logo.svg').default;

    const onSubmit = (e: any) => {
        e.preventDefault();
        onClickStartFreeTrial();
        onClose();
    }

    return (
        <Modal
            title={''}
            variant={'actions'}
            className={'free-trial'}
            onClose={() => {}}
            onSubmit={ e =>{onSubmit(e)}}
        >
            <div className={'free-trial__back'}>
                <button className={'free-trial__back-button'}
                   onClick={onClickBackToSite} type="button">
                    <Arrow />
                    <span className={'free-trial__back-button-span'}>Go back to site</span>
                </button>
            </div>
            <div className={'free-trial__promo'}>
                <Logo />
                <span className={'free-trial__promo-title'}>Start your 7-day FREE Trial</span>
                <span className={'free-trial__promo-text'}>Use all Kanbanchi features
                    <span className={'free-trial__promo-span'}> for 7 days free</span>
                    { paymentType === MONTHLY &&
                        <span>, then ${fixedPrice}/monthly</span>
                    }
                    { paymentType === ANNUALLY &&
                        <span>, then ${annualPaymentWithDiscount}/annually</span>
                    }
                </span>
            </div>
            <div className={'free-trial__offers'}>
                <div className={'free-trial__form'}>
                    <Radio
                        className={'free-trial__radio'}
                        onChange={e => setPriceValue(e.index)}
                        direction={'left'}
                        active={paymentType === ANNUALLY ? 0 : 1}
                    >
                        <div className={'free-trial__radio-item free-trial__radio-item--discount'}>
                            <span className={'free-trial__radio-item-price'}>${fixedPriceWithDiscount}</span>
                            <span className={'free-trial__radio-item-span'}>per user per month</span>
                            <span className={'free-trial__radio-item-billed'}>billed annually</span>
                        </div>
                        <div className={'free-trial__radio-item'}>
                            <span className={'free-trial__radio-item-price'}>${fixedPrice}</span>
                            <span className={'free-trial__radio-item-span'}>per user per month</span>
                            <span className={'free-trial__radio-item-billed'}>billed monthly</span>
                        </div>
                    </Radio>
                    <div className={'free-trial__billing-info'}>
                        <span className={'free-trial__billing-info-title'}>Billing information</span>
                        <span className={'free-trial__guarantee'}>Nothing is due today! 30-day moneyback guarantee</span>
                    </div>
                    <div className={'free-trial__billing-card'}>
                        <div className={'free-trial__billing-item free-trial__billing-card-number'}>
                            <label htmlFor="card-number">Card number</label>
                            <input className={'free-trial__billing-number'}
                                   type="text"
                                   id="card-number"
                                   inputMode="numeric"
                                   pattern="[0-9]{14,20}|[0-9]{4}\s[0-9]{4}\s[0-9]{4}\s[0-9]{4}"
                                   maxLength={20}
                                   placeholder="XXXX XXXX XXXX XXXX"
                                   required/>
                        </div>
                        <div className={'free-trial__billing-item'}>
                            <label htmlFor="expiration-date">Expiration date</label>
                            <input className={'free-trial__billing-date'}
                                   type="text"
                                   id="expiration-date"
                                   maxLength={5}
                                   placeholder="MM/YY"
                                   pattern="[0-9]{2}\/[0-9]{2}"
                                   required/>
                        </div>
                        <div className={'free-trial__billing-item'}>
                            <label htmlFor="cvv">CVV</label>
                            <input className={'free-trial__billing-cvv'}
                                   type="text"
                                   id="cvv"
                                   maxLength={3}
                                   placeholder="3 digits"
                                   pattern="[0-9]{3}"
                                   required/>
                        </div>
                    </div>
                    <div className={'free-trial__bill'}>
                        <ul className={'free-trial__bill-wrapper'}>
                            <li className={'free-trial__bill-item'}>
                                <span>1 Kanbanchi license</span>
                                { paymentType === ANNUALLY &&
                                <span>${annualPayment}</span>
                                }
                                { paymentType === MONTHLY &&
                                    <span>${fixedPrice}</span>
                                }
                            </li>
                            <li className={'free-trial__bill-item'}>
                                { paymentType === ANNUALLY &&
                                    <>
                                        <span>Annual payment before the discount</span>
                                        <span>${annualPayment}</span>
                                    </>
                                }
                                { paymentType === MONTHLY &&
                                    <>
                                        <span>Monthly payment</span>
                                        <span>${fixedPrice}</span>
                                    </>
                                }
                            </li>
                            <li className={'free-trial__bill-item free-trial__bill-discount'}>
                                <span>Yearly discount</span>
                                { paymentType === MONTHLY &&
                                    <span className={'free-trial__bill-discount--plan'}>Choose annual plan to get 20% discount</span>
                                }
                                <span>-${yearlyDiscount}</span>
                            </li>
                            { paymentType === ANNUALLY &&
                                <li className={'free-trial__bill-item'}>
                                    <span>Annual payment after the discount</span>
                                    <span>${annualPaymentWithDiscount}</span>
                                </li>
                            }
                        </ul>
                        <div className={'free-trial__bill-total'}>
                            <span>Due today</span>
                            <span>$0.00</span>
                        </div>
                    </div>
                    <Button className={'free-trial__button'}
                            type="submit"
                    >Start FREE Trial</Button>
                </div>
                <div className={'free-trial__description'}>
                    <span className={'free-trial__description-title'}>Here is why Kanbanchi is best for Google Workspace users:</span>
                    <ul className={'free-trial__description-list'}>
                        <li className={'free-trial__description-item'}>It natively integrates with Google Workspace ecosystem</li>
                        <li className={'free-trial__description-item'}>All your data stays on your Google drive and Google cloud, and not shared with yet another software vendor</li>
                        <li className={'free-trial__description-item'}>Kanbanchi uses interfaces similar to Google Workspace apps — meaning faster learning curve for your team</li>
                        <li className={'free-trial__description-item'}>No bots or endless knowledge bases — if you have a problem your chat and video call to human beings</li>
                    </ul>
                </div>
            </div>
        </Modal>
    );
}
