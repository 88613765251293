import * as React from 'react';
import { CARD_CLASS } from '../../constants';
import { Icon, Tooltip } from 'kui';
import './_CardAgingIndicator.scss';
import { ICardAgingIndicatorProps } from './types';
import { ECardAgingIndicatorType } from '../../../../../../types/rest/ICardAging';
import { CARD_AGING_INDICATOR_BY_ACTIVITY, CARD_AGING_INDICATOR_BY_LIST, ONE_HOUR } from './constants';
import Timeout = NodeJS.Timeout;
import { getTimeStringSinceDate } from './helpers/getTimeStringSinceDate';

export function CardAgingIndicator({
    date,
    type
}: ICardAgingIndicatorProps) {
    const className = CARD_CLASS + '__card-aging-indicator';
    const classText = className + '-text';

    let [_date, _setDate] = React.useState(date);
    const timer = React.useRef(null);

    const setDate = (newDate: number) => {
        _setDate(newDate);
        _date = newDate;
    };

    const tooltipValue = getTimeStringSinceDate(_date) + (type === ECardAgingIndicatorType.BY_ACTIVITY ?
        CARD_AGING_INDICATOR_BY_ACTIVITY
        : CARD_AGING_INDICATOR_BY_LIST);

    // update cardAgingIndicator time every hour for user
    React.useEffect(() => {
        setDate(date);

        let interval: Timeout;
        const timePassed = ONE_HOUR - date % ONE_HOUR;

        if (timer.current) clearTimeout(timer.current);

        timer.current = setTimeout(() => {
            setDate(_date - timePassed + ONE_HOUR);

            if (interval) clearInterval(interval);
            interval = setInterval(() => {
                setDate(_date + ONE_HOUR);
            }, ONE_HOUR);
        }, timePassed);

        return () => {
            clearTimeout(timer.current);
            clearInterval(interval);
        };
    }, [date]);

    return (
        <Tooltip
            isNoEvents
            isNoWrap
            value={tooltipValue}
            direction={'down'}
        >
            <span className={className}>
                <Icon xlink={type === ECardAgingIndicatorType.BY_ACTIVITY ? 'sand-clock' : 'list'}/>
                <span className={classText}>
                    {getTimeStringSinceDate(_date, true)}
                </span>
            </span>
        </Tooltip>
    );
}
