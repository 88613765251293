import { IApplicationState } from '../../../../../../../../types/types';
import { connect } from 'react-redux';
import { IBoardRoleHOCProps } from './types';
import { onChange } from './events/onChange';
import { getPanelReadonly } from '../../../../selectors/getPanelReadonly';
import { IBoardRoleEvents, IBoardRoleFields } from '../../components/BoardRole/types';
import { getBoardRoleByColor } from '../../../../../../../../store/model/board/selectors/getBoardRoleByColor';
import { BoardRole } from '../../components/BoardRole/BoardRole';
import { TStatus } from '../../../../../../../../types/model';
import { onCreate } from './events/onCreate';

const mapStateToProps = (
    state: IApplicationState,
    ownProps: IBoardRoleHOCProps
): IBoardRoleFields => {
    const { boardId, color } = ownProps;
    const isReadonly = getPanelReadonly(state, boardId);
    const role = getBoardRoleByColor(state, boardId, color);
    return {
        isReadonly,
        color,
        status: role ? role.status : TStatus.STATUS_DELETED,
        name: role ? role.name : '',
        id: role ? role.id : null
    }
};

const mapDispatchToProps = (
    dispatch: any,
    ownProps: IBoardRoleHOCProps
): IBoardRoleEvents => {
    const { boardId } = ownProps;
    return {
        onChange: (roleId, name, status) => dispatch(onChange(boardId, roleId, name, status)),
        onCreate: (color, name, status) => dispatch(onCreate(boardId, color, name, status))
    }
};

export const BoardRoleHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(BoardRole);

BoardRoleHOC.displayName = 'BoardRoleHOC';
