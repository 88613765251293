'use strict';

import dialogPermissionsTemplate from './templates/dialogPermissions.html';
import * as _ from 'underscore';

App.Views.PermissionsDialog = App.Views.BaseView.extend({
    className: 'app-permissions hidden',
    template: _.template( dialogPermissionsTemplate ),

    events: {
        'click .js-approve': 'onApprove',
        'click .js-dismiss': 'hide',
    },

    initialize: function(options) {
        this.options = options || {};
    },

    getData: function() {
        return {
            video: '',
            icon: '',
            description: '',
            annotation: '',
            shortText: '',
            title: ''
        }
    },

    onApprove: function(e) {
        if (this.options.onApprove && _.isFunction(this.options.onApprove)) {
            this.options.onApprove();
            this.trigger('hide');
            e.stopPropagation();
        }
    },

    hide: function(e) {
        this.trigger('hide');
        e.stopPropagation();
        if (this.options.onCancel && _.isFunction(this.options.onCancel)) {
            this.options.onCancel();
        }
    },

    render: function() {
        this.$el.html(this.template(this.getData()));
        this.$el.removeClass('hidden');
        return this;
    },

    remove: function() {
        App.vent.off(null, null, this);
        return Backbone.View.prototype.remove.call(this);
    },

    testAttributes: function() {
        return {
            type: 'permissions-dialog'
        }
    },
});

App.Views.PermissionsDialog.mixin(App.Mixins.Test);
