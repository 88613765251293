import { IApplicationState, TListId } from '../../../types/types';
import { ERoutes } from '../constants';

export const getEditListId = (
    state: IApplicationState
): TListId => {
    const { route, args } = state.router;
    if (route !== ERoutes.EDIT_LIST) return null;
    const listId = args[2];
    if (!listId) return null;
    return parseInt(listId);
}
