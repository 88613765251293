import { IApplicationState } from '../../../types/types';
import { ERoutes } from '../constants';
import { TCommentId } from '../../model/card/types/IComment';

export const getEditCommentId = (
    state: IApplicationState
): TCommentId => {
    const { route, args } = state.router;

    let commentId = null;

    if (route === ERoutes.EDIT_CARD) {
        commentId = args[3];
    } else if (route === ERoutes.LINK_CARD) {
        commentId = args[2];
    }

    return commentId && parseInt(commentId);
}
