import {ISavedFilterSetAction} from './types';
import {AT_SAVED_FILTER_SET} from './constants';
import {IFilter} from '../../filter/types';

export const savedFilterSet = (
    filterId: number,
    filter: IFilter
): ISavedFilterSetAction => ({
        type: AT_SAVED_FILTER_SET,
        filterId,
        filter
    }
);
