'use strict';
import React from "react";
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import store, { dispatch } from "../../../store/configureStore";
import { TrialExtendHOC } from "../../react_components/dialogs/trialExtend";
import * as blockerSegmentEvents from '../../../middlewares/segment/helpers/blockersSegmentEvents';
import { BlockerHelper } from '../../../helper/blocker_helper';

import dialogBlockerTemplate from './templates/dialogBlocker.html';
import { authUserUpdateMeta } from '../../../rest/effects/authUser/authUserUpdateMeta';
import { getActiveBoardId } from '../../../store/model/selectors/getActiveBoardId';
import { storeListener } from '../../../store/helpers/storeListener';
import { getAuthUser } from '../../../store/model/authUser/selectors/getAuthUser';
import * as _ from 'underscore';

App.Views.BlockerDialog = Backbone.View.extend({
    tagName: 'div',

    className: 'second-panel__inner',

    template: _.template( dialogBlockerTemplate ),

    events: {
        'click .js-button': 'onClickButton',
        'click .second-panel__close': 'onClose',
        'click .js-buttonSecondary': 'onClickButtonSecondary',
        'click .js-readonly': 'onClickReadonlyButton',
        'click .js-free-version': 'onClickFreeVersionButton',
        'click .js-contactus': 'onClickLinkContactUs',
        'click .js-additional-link-btn': 'onClickAdditionalLinkButton',
        'keydown': 'onKeyDown',
    },

    initialize: function(options) {
        this.options = options || {};

        if (options.allow) {
            const allowSelector = (state) => {
                return getAuthUser(state)[options.allow];
            };

            const allowListener = (allow) => {
                if (allow) {
                    this.hide();
                }
            };

            this.unsubscribeAllowListener = storeListener(
                store,
                allowSelector,
                allowListener
            );
        }
        const activeBoardSelector = (state) => {
            return getActiveBoardId(state);
        };

        const activeBoardListener = () => {
            this.hide();
        };

        this.unsubscribeActiveBoardListener = storeListener(
            store,
            activeBoardSelector,
            activeBoardListener
        );
    },

    onClose: function(e) {
        if (this.options.onCloseClick) this.options.onCloseClick();
        this.hide();
    },

    render: function() {
        if (this.options.questions === true) {
            this.options.questions = Messages.getText('dialog.blocker.questions');
            this.options.contact_us = Messages.getText('dialog.blocker.contact_us');
        }
        if (this.options.close === true) {
            this.options.close = Messages.getText('common.close');
        }
        this.$el.html(this.template(_.extend({
            additionalClass: '',
            button: Messages.getText('dialog.blocker.button'),
            close: false,
            contact_us: null,
            buttonSecondary: null,
            questions: null,
            subtitle: null,
            video: null,
            videoHeight: null,
            videoWidth: null,
            img: null,
            closeIcon: null,
            titleIcon: null,
            features: null,
            extraText: null,
            text: Messages.getText('dialog.blocker.text'),
            title: Messages.getText('dialog.blocker.title'),
        }, this.options)));

        const rootButtonTrialPlaceholder = this.el.querySelector('.dialog-blocker__trial-dialog');
        if (!this.rootButtonTrial && this.options.buttonTrial && rootButtonTrialPlaceholder) {
            this.rootButtonTrial = createRoot(rootButtonTrialPlaceholder);
        }

        if (this.rootButtonTrial) {
            this.rootButtonTrial.render(
                <Provider store={store}>
                    <TrialExtendHOC />
                </Provider>
            );
        }

        setTimeout(() => {
            const el = document.querySelector('.second-panel__inner');
            if (!el) return;
            el.tabIndex = 0;
            el.focus();
        }, 0);

        $('.upgrade').appendTo('body');
        return this;
    },

    afterDOMRendering: function() {
    },

    onClickLinkContactUs: function() {
        if (this.options.onClickLinkContactUs) this.options.onClickLinkContactUs();
        App.controller.showHelpPopup();
        return false;
    },

    onClickButton: function() {
        if (this.options.onClickButton) {
            this.options.onClickButton();
            return;
        }
        App.controller.showPayment();
        dispatch(blockerSegmentEvents.gotoPricingClickedSegmentEvent());
    },

    onClickReadonlyButton: function() {
        App.controller.activateReadOnly();
        if (this.options.onClickReadonlyButton) {
            this.options.onClickReadonlyButton();
        }
    },


    onClickFreeVersionButton: function() {
        dispatch(authUserUpdateMeta({
            freeVersionAfterFirstTrial: true
        }))
        App.controller.activateFreeVersion();
        if (this.options.onClickFreeVersionButton) {
            this.options.onClickFreeVersionButton();
        }
    },
    onClickButtonSecondary: function() {
        if (this.options.onClickButtonSecondary) {
            this.options.onClickButtonSecondary();
        } else if (getActiveBoardId(store.getState())) {
            BlockerHelper.backToKanban();
            dispatch(blockerSegmentEvents.backToKanbanClickedSegmentEvent());
        }
    },

    onClickAdditionalLinkButton: function() {
        if (this.options.onClickAdditionalLinkButton) {
            this.options.onClickAdditionalLinkButton();
        }
    },

    onKeyDown: function(e) {
        if (e.key === 'Escape') {
            this.onCloseClick(e);
        }
    },

    onCloseClick: function(e) {
        e.stopPropagation();
        this.hide();
    },

    hide: function() {
        App.vent.trigger(App.vent['secondPanel:close']);
        return this;
    },

    remove: function() {
        if (this.unsubscribeAllowListener) this.unsubscribeAllowListener();
        if (this.unsubscribeActiveBoardListener) this.unsubscribeActiveBoardListener();
        App.vent.off(null, null, this);
        $('.upgrade').appendTo('.content__inner');
        if (this.rootButtonTrial) this.rootButtonTrial.unmount();
        return Backbone.View.prototype.remove.call(this);
    }
});
