import * as React from 'react';
import './_CardPriority.scss';
import { Button, ButtonDropdown, Icon, SelectList, SelectListItem, Tooltip } from 'kui';
import { CLASS_MY_WORK_CARD, CLASS_MY_WORK_CARD_CONTROL } from '../../../constants';
import { PRIORITY_ICONS } from '../../../../../helpers/constants';
import { ICardPriorityProps } from './types';

export function CardPriority ({
    priority,
    portalId,
    onChange,
    onLoadCard
}: ICardPriorityProps) {
    const className = CLASS_MY_WORK_CARD + '__priority';
    const priorityObj = PRIORITY_ICONS.find(
        item => item.priority === String(priority) || !priority && !Number(item.priority)
    );
    const [isOpened, setOpened] = React.useState(null);
    const onClose = () => setOpened(false);

    return (
        <Tooltip
            delayClose={1}
            direction={'up'}
            value={priorityObj.text}
            isNoEvents
        >
            <ButtonDropdown
                className={`
                    ${className}
                    ${className + '--' + priorityObj.icon}
                    ${isOpened ? className + '--opened' : ''}
                    ${CLASS_MY_WORK_CARD_CONTROL}
                `}
                dropdownClassName={`${className + '-dropdown'} ${CLASS_MY_WORK_CARD_CONTROL}`}
                onOpen={() => {
                    onLoadCard();
                    setOpened(true);
                }}
                onClose={onClose}
                opened={isOpened}
                portal
                portalId={portalId}
                directionHorizontal={'right'}
            >
                <Button className={className + '-button'}>
                    <Icon size={16} xlink={priorityObj.icon} />
                </Button>
                <SelectList
                    active={PRIORITY_ICONS.findIndex(item => item.priority === String(priority) || !priority && !Number(item.priority))}
                >
                    {PRIORITY_ICONS.map((item) => (
                        <SelectListItem
                            className={!Number(item.priority) ? 'no-priority' : ''}
                            icon={item.icon}
                            iconSize={24}
                            key={item.priority}
                            value={item.priority}
                            onClick={() => {
                                onChange(item.priority);
                                onClose();
                            }}
                        >
                            {item.text}
                        </SelectListItem>
                    ))}
                </SelectList>
            </ButtonDropdown>
        </Tooltip>
    );
};
