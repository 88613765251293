import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../../types/types';
import { updateMyWorkMeta } from '../../../../../../../../../rest/effects/authUser/myWork/updateMyWorkMeta';
import { CARDS_COLUMNS } from '../../../../../cards/constants';

export const onReset = (): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const columns = CARDS_COLUMNS.map(({ title, isHidden }) => {
            return { title, isHidden };
        });
        dispatch(updateMyWorkMeta({
            columns,
            isFilterBarCollapsed: false,
            isHideColumnsTitle: false,
            isShowArchivedCards: false,
            isHideEmptyGroups: true
        }));
    };
    return action;
};
