import { SegmentNotificationsEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../types/types';

export const onClickHow = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentNotificationsEvent.FORCE_HOW_CLICKED));
    };
    return action;
};
