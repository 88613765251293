import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { root } from '../../../../../../../store/constants';
import { tabKeySetAction } from '../../../store/actions/tabKeySetAction';
import { routeSetAction } from '../../../../../../../store/router/actions/routeSetAction';
import { IGetState } from '../../../../../../../types/types';
import { MY_WORK_SETTINGS_TABS } from '../../../../../main/myWorkView/myWorkSettingsPanel/constants';

export const onTabActive = (
    activeTabIndex: number
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const tabs = MY_WORK_SETTINGS_TABS;
        const tab = tabs[activeTabIndex];
        if (!tab) return;

        dispatch(tabKeySetAction(tab.key));

        const tabUrlSuffix = tab.url || '';
        root.App.router.navigate(root.App.router.getAssignedToMeUrl(tabUrlSuffix), { trigger: false });
        if (tab.route) dispatch(routeSetAction(tab.route));
    };
    return action;
};
