import * as React from 'react';
import { SettingsSection } from '../../../../../../base/components/SettingsSection/SettingsSection';
import { AsidePanelContext } from '../../../../../asidePanel/components/AsidePanel/constants';
import { CLASS_BOARD_DETAILS_SECTION } from '../../../../constants';
import { IEstimateTimeSectionProps } from './types';
import { ESTIMATE_TIME_TITLE } from '../../constants';
import { EstimateTimeHOC } from '../../hocs/EstimateTimeHOC/EstimateTimeHOC';

export function EstimateTimeSection ({
}: IEstimateTimeSectionProps) {
    const { boardId } = React.useContext(AsidePanelContext);
    const className = CLASS_BOARD_DETAILS_SECTION + ' ' + CLASS_BOARD_DETAILS_SECTION + '--estimate-time';

    return (
        <SettingsSection
            className={className}
            title={ESTIMATE_TIME_TITLE}
        >
            <EstimateTimeHOC boardId={boardId} />
        </SettingsSection>
    );
};
