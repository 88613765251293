import { IApplicationState, TBoardId } from '../../../types/types';
import { getBoard } from './getBoardById';
import { START_DATE_TIME } from '../constants';

export const getBoardMetaDefaultStartDateTime = (
    state: IApplicationState,
    boardId: TBoardId
): Date => {
    const board = getBoard(state, boardId);
    return board && board.meta && board.meta.defaultStartDateTime && new Date(board.meta.defaultStartDateTime) || START_DATE_TIME;
}
