import { Dispatch, ThunkAction } from '../../../types/actions';
import { TBoardId } from '../../../types/types';
import { boardUpdateMeta } from './boardUpdateMeta';

export const boardSetAutoStartDateMeta = (
    boardId: TBoardId,
    isAutoStartDate: boolean
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        return dispatch(boardUpdateMeta(boardId, { isAutoStartDate }));
    };
    return action;
};
