import * as React from 'react';
import { CARD_CLASS } from '../../constants';
import { ICardIndicatorProps } from './types';
import './CardIndicator.scss';

export function CardIndicator({
    type,
    title,
    children
}: ICardIndicatorProps) {
    const foldClass = CARD_CLASS + '__indicator';
    const classes = [
        foldClass
    ];
    if (type) {
        classes.push(`${foldClass}--${type}`);
    }
    return (
        <span className={classes.join(' ')} title={title}>
            {children}
        </span>
    );
}
