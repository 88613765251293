import { IGetState, TCardId, TChecklistId, TChecklistItemId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { getCard } from '../../../../../../../../store/model/selectors/getCard';
import { createSubcardByChecklistItem } from './createSubcardByChecklistItem';
import { getAuthUser } from '../../../../../../../../store/model/authUser/selectors/getAuthUser';
import { isBoardCardsLimitReached } from '../../../../../../../../store/model/selectors/isBoardCardsLimitReached';
import { getBoardIdByCardId } from '../../../../../../../../store/model/selectors/getBoardIdByCardId';
import {
    showBoardLimitSnackbarAndEvent
} from '../../../../../../../../store/model/effects/showBoardLimitSnackbarAndEvent';
import { SegmentUserTypeOption } from '../../../../../../../../middlewares/segment/trackEntities/userEvents';
import { getChecklistItem } from '../../../../../../../../store/model/checklists/checklist/selectors/getChecklistItem';
import { snackbarPromtYesNo } from '../../../../../../snackbarsStack/effects/variantPromt/snackbarPromtYesNo';
import { getMessages } from '../../../../../../../../store/constants';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { EConvertToSubcardWorkStatus } from '../../../components/CardChecklistItems/types';
import { ensureBoardIsFullLoaded } from '../../../../../../../../store/model/effects/ensureBoardIsFullLoaded';
import { showSnoozeBlocker } from '../../../../../../base/effects/showSnoozeBlocker';
import { deleteChecklistItem } from '../../effects/deleteChecklistItem';

export const convertChecklistItemToSubcard = (
    cardId: TCardId,
    checklistId: TChecklistId,
    itemId: TChecklistItemId,
    onWork: (status: EConvertToSubcardWorkStatus) => void
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const card = getCard(state, cardId);
        const item = getChecklistItem(state, cardId, checklistId, itemId);
        if (!card || !item) return Promise.reject();
        const boardId = getBoardIdByCardId(state, cardId);

        if (!getAuthUser(state).allowSubCard) {
            dispatch(showSnoozeBlocker({
                allow: 'allowSubCard'
            }));
            return Promise.reject();
        }
        if (isBoardCardsLimitReached(state, boardId, 1)) {
            dispatch(showBoardLimitSnackbarAndEvent(SegmentUserTypeOption.CARD_CREATION));
            return Promise.reject();
        }

        return new Promise((resolve, reject) => {
            dispatch(snackbarPromtYesNo({
                id: 'CONVERT_CHECKLIST_TO_SUBCARD',
                text: getMessages().getText('snackbar.card.checklist.subcard.convert.text'),
                actionApply: () => {
                    dispatch(segmentTrackAction(SegmentCardEvent.CREATE_SUBCARD_FROM_CHECKLIST_CLICKED));
                    onWork(EConvertToSubcardWorkStatus.STARTED);
                    dispatch(ensureBoardIsFullLoaded(boardId))
                        .then(() => dispatch(createSubcardByChecklistItem(card, item)))
                        .then(() => dispatch(deleteChecklistItem(cardId, checklistId, itemId)))
                        .then(() => {
                            onWork(EConvertToSubcardWorkStatus.FINISHED);
                            resolve(true);
                        })
                },
                actionCancel: () => {
                    dispatch(segmentTrackAction(SegmentCardEvent.CREATE_SUBCARD_FROM_CHECKLIST_CANCEL));
                    reject();
                }
            }));
        });
    };
    return action;
};
