import * as React from 'react';
import './_ProfileSubscription.scss';
import { IProfileSubscriptionProps } from './types';
import {
    PROFILE_ADMIN,
    PROFILE_ADMINS,
    PROFILE_BUTTON_LICENSE,
    PROFILE_BUTTON_UPGRADE,
    PROFILE_DATE_ACTIVE,
    PROFILE_DATE_CANCELED,
    PROFILE_DATE_MANUALLY,
    PROFILE_TRIAL_DISCOUNT_KEYS,
    PROFILE_TRIAL_KEYS
} from './constants';
import { Button, ButtonDropdown, ButtonTitle, Icon, SelectList } from 'kui';
import { CLASS_PROFILE_DROPDOWN } from '../../constants';
import { getDaysLeft } from 'app/view/react_components/main/upgradeButton/helpers/getDaysLeft';
import { getMessages } from 'app/store/constants';
import { EPaymentSource } from 'app/types/rest/IRestSubscription';
import Util from 'app/util/util';

export function ProfileSubscription ({
    discount,
    isCanceled,
    isCorp,
    isOwn,
    subscription,
    onClickUpgrade,
}: IProfileSubscriptionProps) {
    if (!subscription) return null;

    const className = CLASS_PROFILE_DROPDOWN + '__subscription';
    const classNameTrial = CLASS_PROFILE_DROPDOWN + '__subscription-trial';
    const classNameTrialText = CLASS_PROFILE_DROPDOWN + '__subscription-trial-text';
    const classNamePlan = CLASS_PROFILE_DROPDOWN + '__subscription-plan';
    const classNamePlanName = CLASS_PROFILE_DROPDOWN + '__subscription-plan-name';
    const classNamePlanDate = CLASS_PROFILE_DROPDOWN + '__subscription-plan-date';
    const classNamePlanDateText = CLASS_PROFILE_DROPDOWN + '__subscription-plan-date-text';
    const classNamePlanAdmin = CLASS_PROFILE_DROPDOWN + '__subscription-plan-admin';

    let elements;
    const upgrade = (
        <Button
            variant={'primary'}
            onClick={onClickUpgrade}
        >
            {isCorp ? PROFILE_BUTTON_LICENSE : PROFILE_BUTTON_UPGRADE}
        </Button>
    );

    if (subscription.isTrial) {
        let trialDaysLeft = getDaysLeft(subscription.paymentExpDate);
        let trialText = getMessages().getText(PROFILE_TRIAL_KEYS[2]);
        if (discount) {
            if (trialDaysLeft >= 1) {
                trialDaysLeft = Math.floor(trialDaysLeft);
                trialText = getMessages().getPluralByKeys(trialDaysLeft, PROFILE_TRIAL_DISCOUNT_KEYS, null, trialDaysLeft, subscription.name);
            } else {
                trialText = getMessages().getText(PROFILE_TRIAL_DISCOUNT_KEYS[2], null, subscription.name);
            }
        } else if (trialDaysLeft >= 1) {
            trialDaysLeft = Math.floor(trialDaysLeft);
            trialText = getMessages().getPluralByKeys(trialDaysLeft, PROFILE_TRIAL_KEYS, null, trialDaysLeft);
        }
        elements = (
            <div className={`${classNameTrial} ${discount ? classNameTrial + '--discount' : ''}`}>
                <div className={classNameTrialText}>
                    {trialText}
                </div>
                {upgrade}
            </div>
        );
    } else {
        const dateLabel = isCanceled
            ? PROFILE_DATE_CANCELED
            : subscription.paymentSource === EPaymentSource.PAYMENT_SOURCE_MANUALLY
                ? PROFILE_DATE_MANUALLY
                : PROFILE_DATE_ACTIVE;
        elements = (
            <div className={classNamePlan}>
                <div className={classNamePlanName}>
                    {subscription.name} plan
                </div>
                <div className={classNamePlanDate}>
                    <div className={classNamePlanDateText}>
                        {dateLabel}: {Util.formatDateMoment(subscription.paymentExpDate)}
                    </div>
                    {isCanceled && upgrade}
                </div>
                {!isOwn && subscription.managersObjects && !!subscription.managersObjects.length &&
                    <div className={classNamePlanAdmin}>
                        <Emails emails={subscription.managersObjects.map(manager => manager.email)} />
                    </div>
                }
            </div>
        );
    }

    return (
        <div className={className}>
            {elements}
        </div>
    );
};

function Emails ({
    emails = []
}: {
    emails: string[]
}) {
    if (!emails.length) return null;

    if (emails.length === 1) return (
        <Button
            href={'mailto:' + emails[0]}
            title={emails[0]}
            variant={'icon-text'}
        >
            {PROFILE_ADMIN}: {emails[0]}
        </Button>
    );

    return (
        <ButtonDropdown
            className={CLASS_PROFILE_DROPDOWN + '__subscription-plan-admin-dropdown'}
            directionVertical={'down'}
            isFitWindow
        >
            <Button
                variant={'icon-text'}
            >
                <ButtonTitle>
                    {emails.length} {PROFILE_ADMINS}
                </ButtonTitle>
                <Icon
                    size={24}
                    xlink={'arrow-s-drop-open'}
                />
            </Button>
            <SelectList fixActive={false}>
                {emails.map(email => (
                    <Button
                        href={'mailto:' + email}
                        key={email}
                        title={email}
                        variant={'icon-text'}
                    >
                        {email}
                    </Button>
                ))}
            </SelectList>
        </ButtonDropdown>
    )
};
