import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId, TChecklistId, TChecklistItemId } from '../../../../../../../../types/types';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { checklistItemUpdate } from '../../../../../../../../rest/effects/card/checklist/checklistItemUpdate';
import { getChecklistItem } from '../../../../../../../../store/model/checklists/checklist/selectors/getChecklistItem';

export const onChangeEstimate = (
    cardId: TCardId,
    checklistId: TChecklistId,
    itemId: TChecklistItemId,
    estimate: number,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (estimate) {
            const { estimate = null } = getChecklistItem(getState(), cardId, checklistId, itemId) || {};
            if (estimate) {
                dispatch(segmentTrackAction(SegmentCardEvent.CHECKLIST_ITEM_ESTIMATE_CHANGED));
            } else {
                dispatch(segmentTrackAction(SegmentCardEvent.CHECKLIST_ITEM_ESTIMATE_ADDED));
            }
        } else {
            dispatch(segmentTrackAction(SegmentCardEvent.CHECKLIST_ITEM_ESTIMATE_REMOVED));
        }
        return dispatch(checklistItemUpdate(cardId, checklistId, itemId, {
            estimate,
        }));
    };
    return action;
};
