import { IApplicationState, TBoardId } from '../../../../../types/types';
import { ICard } from '../../../card/types';
import { getTypeSwitcherActiveType } from '../../../../../view/react_components/typeSwitcher/store/selectors/getTypeSwitcherActiveType';
import { EViewTypes } from '../../../../../const';
import { ganttFilter } from '../helpers/ganttFilter';
import { getGanttViewBoardState } from '../../../../../view/react_components/gantt/selectors/getGanttViewBoardState';
import { statusFilter } from '../helpers/statusFilter';
import { TStatus } from '../../../../../types/model';
import { getFilterIgnoreCardIds } from '../../../../../view/react_components/aside_panel/filterPanelBoard/store/selectors/getFilterIgnoreCardIds';

export const getViewTypeCardFilter = (
    state: IApplicationState,
    boardId: TBoardId
): (card: ICard) => boolean => {
    const viewType = getTypeSwitcherActiveType(state, boardId);
    switch (viewType) {
        case EViewTypes.GANTT_VIEW_TYPE: {
            return ganttFilter(getGanttViewBoardState(state, boardId), getFilterIgnoreCardIds(state));
        }
        case EViewTypes.ARCHIVE_VIEW_TYPE: {
            return statusFilter(TStatus.STATUS_ARCHIVE);
        }
    }
    return statusFilter(TStatus.STATUS_ACTIVE);
}
