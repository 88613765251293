import { TSegmentAction } from '../types';
import { SEGMENT_ALIAS, SEGMENT_IDENTIFY, SEGMENT_RESET, SEGMENT_TRACK } from '../constants';
import { identify } from '../methods/identify/identify';
import { alias } from '../methods/alias/alias';
import { trackRouter } from '../methods/track/trackRouter';
import { reset } from '../methods/reset/reset';

export const segmentActionsRouter = (action: TSegmentAction) => {
    switch (action.case) {

        case SEGMENT_IDENTIFY:
            identify(action.traits);
            break;

        case SEGMENT_ALIAS:
            alias(action.userId);
            break;

        case SEGMENT_TRACK:
            trackRouter(action.track, action.option, action.additionalOptions);
            break;

        case SEGMENT_RESET:
            reset();
            break;
    }
};
