import * as React from 'react';
import './_HourSwitch.scss';
import { IHourSwitchProps } from './types';
import { CLASS_DATEPICKER } from '../constants';
import { Button } from 'kui';
import { DateItem } from '../DateItem/DateItem';
import { E12HoursType } from '../DatepickerItem/types';
import { DATEPICKER_DATE_BUTTON_AM, DATEPICKER_DATE_BUTTON_PM } from './constants';
import { KEY_DOWN, KEY_UP } from '../../../../../aside_panel/cardDetails/CommentsSection/constants';
import { DatepickerContext } from '../Datepicker/constants';

export const HourSwitch = ({
    hoursType,
    onToggle
}: IHourSwitchProps) => {
    const { isReadonly } = React.useContext(DatepickerContext);

    const className = CLASS_DATEPICKER + '__hour-switch';

    const onKeyDown = (e: React.KeyboardEvent) => {
        e.preventDefault();
        if (e.key === KEY_DOWN || e.key === KEY_UP) {
            onToggle();
        }
    };

    return (
        <Button
            className={className}
            onClick={onToggle}
            onKeyDown={onKeyDown}
            variant={'icon'}
            disabled={isReadonly}
        >
            <DateItem
                value={hoursType === E12HoursType.PM ? DATEPICKER_DATE_BUTTON_PM : DATEPICKER_DATE_BUTTON_AM}
            />
        </Button>
    );
}
