import { IApplicationState, TBoardId, TCardId } from '../../../../types/types';
import { getActiveCardId } from '../../../../store/router/selectors/getActiveCardId';
import { IRouterState } from '../../../../store/router/reducers/types';
import { IClickManagerModel } from '../store/types';
import { root } from '../../../../store/constants';

export const isCardSelected = (
    state: IApplicationState,
    boardId: TBoardId,
    cardId: TCardId
): boolean => {
    return isCardSelectedSelector(state.router, state.clickManager[boardId], cardId);
};

const isCardSelectedSelector = (
    routerState: IRouterState,
    clickManagerModelState: IClickManagerModel,
    cardId: TCardId
): boolean => {
    if (root._debug.selector) {
        console.log('isCardSelectedSelector');
    }
    let isSelected = clickManagerModelState && clickManagerModelState.selectedCardIds.includes(cardId);
    if (!isSelected) {
        const activeCardId = getActiveCardId(null, routerState);
        isSelected = activeCardId === cardId;
    }
    return isSelected;
};

// замеры CardProgressHOC показывают, что реселекты там не нужны
// export const isCardSelectedCreateSelector = (
// ) => createSelector(
//     (state: IApplicationState) => state.router,
//     (state: IApplicationState, boardId: TBoardId ) => state.clickManager[boardId],
//     (state: IApplicationState, boardId: TBoardId , cardId: TCardId) => cardId,
//     isCardSelectedSelector
// );
