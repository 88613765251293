import { IApplicationState, TCardId } from '../../../types/types';
import { getCardHasChecklist } from './getCardHasChecklist';
import { getCardIsEpic } from './getCardIsEpic';

/**
 * нет чеклиста, нет сабкарт
 */
export const getCardCanBeSetDone = (
    state: IApplicationState,
    cardId: TCardId,
): boolean => {
    const hasChecklist = getCardHasChecklist(state, cardId);
    if (hasChecklist) return false;

    const isEpic = getCardIsEpic(state, cardId);
    if (isEpic) return false;

    return true;
}
