import { TCardId } from '../../../../../../../types/types';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../middlewares/segment';
import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { cardOpen } from '../../../../../base/effects/cardOpen';
import { CARD_CLASS } from '../../../../../main/kanbanView';
import { CLASS_NAME_CARD_TEMPLATES } from '../../../../asidePanel/hocs/AsidePanelCardTemplatesHOC/constants';
import { CLASS_ASIDE_PANEL } from '../../../../../../../const';

export const onSelect = (
    cardId: TCardId
): ThunkAction => {
    return (
        dispatch: Dispatch
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.CARD_CLICKED));
        dispatch(cardOpen(cardId));
        const card = document.querySelector('.' + CARD_CLASS + cardId) as HTMLElement;
        const body = document.querySelector('.' + CLASS_NAME_CARD_TEMPLATES + ' .' +  CLASS_ASIDE_PANEL + '__body') as HTMLElement;
        const header = document.querySelector('.card-templates__header') as HTMLElement;
        if (body && card && header) {
            body.scrollTop = card.offsetTop - header.offsetHeight;
        }
    };
};
