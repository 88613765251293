import { IGetState, TBoardId } from '../../../types/types';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { fetchPermissionsRest } from './api/fetchPermissionsRest';
import { IPermissions } from '../../../types/model';
import { boardsActionSet } from '../../../store/model/actions/boardsActionSet';
import { boardActionSet } from '../../../store/model/boards/actions/boardActionSet';
import { updateAction } from '../../../store/model/board/actions/updateAction';
import {closeBoard} from 'app/view/react_components/main/navigationPanel/RecentBoards/hocs/RecentBoardsHOC/effects/closeBoard';
import {boardClose} from 'app/view/react_components/base/effects/boardClose';
import {boardDelete} from 'app/view/react_components/dialogs/openBoards/store/boards/actions/boardDelete';
import {closeRecentOpenBoard} from 'app/rest/effects/authUser/recentBoards/closeRecentOpenBoard';
import {snackbarErrorReload} from 'app/view/react_components/snackbarsStack';

export const boardFetchPermissions = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(fetchPermissionsRest(boardId))
            .then((permissions: IPermissions) => {
                if (permissions) {
                    dispatch(boardsActionSet(boardActionSet(boardId, updateAction({ permissions }))))
                }
                return permissions;
            })
            .catch((e: any) => {
                if(e.status === 403) {
                    dispatch(boardClose(boardId));
                    dispatch(boardDelete(boardId));
                    dispatch(closeRecentOpenBoard(boardId));
                    dispatch(snackbarErrorReload());
                }
            })
    };
    return action;
}
