import { connect } from 'react-redux';
import { TStatus } from '../../../../../../../../types/model';
import { IApplicationState } from '../../../../../../../../types/types';
import { RolesPanelButton } from '../../components/RolesPanelButton/RolesPanelButton';
import { IRolesPanelButtonEvents, IRolesPanelButtonFields } from '../../components/RolesPanelButton/types';
import { onClick } from './events/onClick';
import { IRolesPanelButtonHOCProps } from './types';
import { getBoardRoles } from '../../../../../../../../store/model/board/selectors/getBoardRoles';
import { getBoard } from '../../../../../../../../store/model/selectors/getBoardById';
import { getIsUserBasicPlanOnly } from '../../../../../../../../store/model/authUser/selectors/getIsUserBasicPlanOnly';

const mapStateToProps = (
    state: IApplicationState,
    { boardId }: IRolesPanelButtonHOCProps
): IRolesPanelButtonFields => {
    const roles = getBoardRoles(getBoard(state, boardId));
    const indicatorNumber = roles.filter( // filter out duplicates
        (role, index) => {
            const firstIndex = roles.findIndex(roleIndex => roleIndex.color === role.color);
            return firstIndex === index;
        }
    ).reduce((count, role) => {
        return count + Number(role.status === TStatus.STATUS_ACTIVE);
    }, 0);
    return {
        indicatorNumber,
        isBasicFeature: getIsUserBasicPlanOnly(state),
        tooltip: null,
    }
};

const mapDispatchToProps = (
    dispatch: any,
    {onClick: ownOnClick}: IRolesPanelButtonHOCProps
): IRolesPanelButtonEvents => {
    return {
        onClick: () => dispatch(onClick(ownOnClick))
    }
};

export const RolesPanelButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(RolesPanelButton);

RolesPanelButtonHOC.displayName = 'RolesPanelButtonHOC';
