import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { segmentTrackAction, SegmentUserEvent } from '../../../../../../../middlewares/segment';

export const onFaqClicked = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(segmentTrackAction(SegmentUserEvent.AFFILIATE_FAQ_LINK_CLICKED));
    };
    return action;
}
