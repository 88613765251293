import { ISegmentBoardOptionNotifyTypeValueMap, ISegmentBoardOptionSubscribeCaseTypeValueMap } from './types';
import {
    SegmentBoardOptionNotifyTypeValue,
    SegmentBoardOptionSubscribeCaseTypeValue
} from '../../../../../../../../middlewares/segment/trackEntities/boardEvents';
import {
    EPushNotificationSubscribeCases,
    EPushNotificationSettingsTypes
} from '../../../../../../../../types/rest/IRestPushNotificationSettings';

export const subscribeCaseTypeValueMap: ISegmentBoardOptionSubscribeCaseTypeValueMap = {
    [EPushNotificationSubscribeCases.CARD_CREATE]: SegmentBoardOptionSubscribeCaseTypeValue.CARD_CREATE,
    [EPushNotificationSubscribeCases.ASSIGN]: SegmentBoardOptionSubscribeCaseTypeValue.ASSIGN,
    [EPushNotificationSubscribeCases.COMMENT_ADD]: SegmentBoardOptionSubscribeCaseTypeValue.COMMENT_ADD,
    [EPushNotificationSubscribeCases.COMMENT_MENTION]: SegmentBoardOptionSubscribeCaseTypeValue.COMMENT_MENTION
}

export const notifyTypeValueMap: ISegmentBoardOptionNotifyTypeValueMap = {
    [EPushNotificationSettingsTypes.NAME_CHANGED]: SegmentBoardOptionNotifyTypeValue.NAME_CHANGED,
    [EPushNotificationSettingsTypes.DESC_CHANGED]: SegmentBoardOptionNotifyTypeValue.DESC_CHANGED,
    [EPushNotificationSettingsTypes.USER_ASSIGNED]: SegmentBoardOptionNotifyTypeValue.USER_ASSIGNED,
    [EPushNotificationSettingsTypes.PRIORITY_CHANGED]: SegmentBoardOptionNotifyTypeValue.PRIORITY_CHANGED,
    [EPushNotificationSettingsTypes.COMMENT_ADDED]: SegmentBoardOptionNotifyTypeValue.COMMENT_ADDED,
    [EPushNotificationSettingsTypes.ATTACH_ADDED]: SegmentBoardOptionNotifyTypeValue.ATTACH_ADDED,
    [EPushNotificationSettingsTypes.DELETED]: SegmentBoardOptionNotifyTypeValue.DELETED,
    [EPushNotificationSettingsTypes.ARCHIVED]: SegmentBoardOptionNotifyTypeValue.ARCHIVED,
    [EPushNotificationSettingsTypes.LIST_CHANGE]: SegmentBoardOptionNotifyTypeValue.LIST_CHANGE,
    [EPushNotificationSettingsTypes.OVERDUE]: SegmentBoardOptionNotifyTypeValue.OVERDUE
}
