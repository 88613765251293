import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { IGetState, TBoardId } from '../../../../../../../../../types/types';
import { root } from '../../../../../../../../../store/constants';
import { boardStartListening } from '../../../../../../../../../rest/effects/board/boardStartListening';
import { boardUpdatePermissions } from '../../../../../../../../../rest/effects/board/boardUpdatePermissions';
import { getBoard } from '../../../../../../../../../store/model/selectors/getBoardById';

export const openShare = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const App = root.App;
        // @ts-ignore
        const s =  root.App.shareClient;
        const board = getBoard(state, boardId);

        s.setItemIds([board.fileId]);
        s.showSettingsDialog();

        dispatch(boardStartListening(boardId));

        let timer = 0;
        const w = window as any;
        let isOpened = false;

        if (w.waitForElements) clearInterval(w.waitForElements); // новый диалог обнуляет интервал
        w.waitForElements = setInterval(() => {
            timer++;
            const elements = document.querySelectorAll('body > div[guidedhelpid="drive_share_dialog"][tabindex="0"]'); // tabindex=0 когда диалог открыт
            if (!isOpened && elements && elements.length) {
                isOpened = true; // диалог открылся
            }
            if (elements && elements.length > 1) {
                elements.forEach((element, i) => {
                    if (i) element.remove(); // удалить все кроме 0
                });
            } else if (isOpened && (!elements || !elements.length)) { // диалог закрылся
                isOpened = false;
                dispatch(boardUpdatePermissions(boardId));
                setTimeout(() => { // повторить через минуту, т.к. гугл может долго обновлять пермишены
                    dispatch(boardUpdatePermissions(boardId));
                }, App.Util.TIME.MINUTE * 1000);
                if (w.waitForElements) clearInterval(w.waitForElements);
            }
        }, 100);
    };
    return action;
};
