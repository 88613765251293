import { ThunkAction } from '../../../../../../types/actions';
import { root } from '../../../../../../store/constants';
import { IRestCardWithBoard } from '../../../../../../types/rest/IRestCardWithBoard';
import { SegmentNavPanelEvent, segmentTrackAction } from '../../../../../../middlewares/segment';

export const onCardOpen = (
    card: IRestCardWithBoard
): ThunkAction => {
    return (
        dispatch
    ) => {
        dispatch(segmentTrackAction(SegmentNavPanelEvent.OPEN_IN_WORK_CARD_CLICKED));
        root.App.controller.cleverOpenCard(card.boardId, card.id);
    }
}
