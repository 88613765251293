import * as React from 'react';
import './_UsersTooltip.scss';
import { IUsersTooltipProps, TUserTooltipNaming } from './types';
import { Tooltip } from 'kui';
import { escape } from 'underscore';
import { USER_TOOLTIP_AUTHOR_TITLE_SINGLE, USER_TOOLTIP_TITLE, USER_TOOLTIP_TITLE_SINGLE } from './constants';
import { IAssignee } from 'app/store/model/card/types/IAssignee';
import { UserpicStorage } from '../UserpicStorage/UserpicStorage';
import { getInitials } from 'app/view/react_components/helpers/userPhotoHelper';

export function UsersTooltip ({
    authUserPermissionId,
    isBig,
    isTooltip = true,
    roles,
    users,
    boardUsers,
    naming = TUserTooltipNaming.ASSIGNEE,
    onClick,
    ...attributes
}: IUsersTooltipProps) {
    if (!users.length) return null;

    const className = 'users-tooltip';
    const classNameBig = isBig ? className + '--big': '';
    const classNumber = className + '__number';
    const classUser = className + '__user';
    const classUserRoles = className + '__user-roles';
    const classUserRole = className + '__user-role';
    const classTooltip = className + '__tooltip';
    const classTooltipTitle = className + '__tooltip-title';
    const classTooltipUser = className + '__tooltip-user';
    const classTooltipUserName = className + '__tooltip-user-name';
    const classTooltipUserRoles = className + '__tooltip-user-roles';

    const tooltipTitle = `<p class="${classTooltipTitle}">
        ${users.length < 3
        ? naming === TUserTooltipNaming.ASSIGNEE ? USER_TOOLTIP_TITLE_SINGLE : USER_TOOLTIP_AUTHOR_TITLE_SINGLE
        : USER_TOOLTIP_TITLE
    }</p>`;
    let elements;

    function rolesForTooltip (
        roleIds: number[]
    ) {
        const rolesArray = !!roleIds && !!roleIds.length && roles && roles.length
            ? getUserRoles(roleIds).map(role => role.name)
            : [];
        return (users.length === 1 || isBig && users.length < 3 ?
                `<div class="${classTooltipUserRoles}">
                    ${escape(rolesArray.join(', '))}
                 </div>`
                : !!rolesArray.length ?
                    <div className={classTooltipUserRoles}>
                        {escape(rolesArray.join(', '))}
                    </div>
                    : null
        )
    }

    function getUserRoles (
        roleIds: number[]
    ) {
        return !!roleIds && !!roleIds.length && roles && roles.length
            ? roles.filter(role => roleIds.includes(role.id))
            : [];
    }

    function getPhotoUrl (
        user: IAssignee
    ): string {
        const boardUser = boardUsers && boardUsers.find(boardUser => (
            boardUser.permissionId && (
                boardUser.permissionId === user.sharedUser.permissionId ||
                boardUser.permissionId.includes(user.sharedUser.permissionId)
            )
        ));
        if (boardUser && boardUser.photoUrl) return boardUser.photoUrl;
        return user.sharedUser.photoUrl;
    }

    if (
        users.length === 1 ||
        isBig && users.length < 3
    ) {
        elements = users.map(user => {
            if (!user.sharedUser) return;
            return (
                <Tooltip
                    delay={300}
                    isNoWrap
                    key={user.sharedUser.permissionId}
                    value={!isTooltip ? null : `
                        ${tooltipTitle}
                        ${user.sharedUser.fullName}
                        ${rolesForTooltip(user.roleIds)}
                    `}
                    {...attributes}
                >
                    <div className={classUser}>
                        <UserpicStorage
                            initials={getInitials(user.sharedUser)}
                            src={getPhotoUrl(user)}
                            onClick={onClick}
                            userId={user.sharedUser.permissionId}
                        />
                        {!!user.roleIds && !!user.roleIds.length && !!roles && !!roles.length &&
                            <div className={classUserRoles}>
                                {getUserRoles(user.roleIds).map(role => (
                                    <div
                                        className={`
                                                ${classUserRole}
                                                ${classUserRole}--${role.color}
                                            `}
                                        key={role.id}
                                    />
                                ))}
                            </div>
                        }
                    </div>
                </Tooltip>
            );
        });
    } else {
        const assignTooltipUsers = !isTooltip
            ? null
            : users.reduce((tooltip, user) => {
                tooltip.push(
                    <div className={classTooltipUser} key={user.sharedUser.permissionId}>
                        <UserpicStorage
                            initials={getInitials(user.sharedUser)}
                            src={getPhotoUrl(user)}
                            userId={user.sharedUser.permissionId}
                        />
                        <div className={classTooltipUserName}>
                            {user.sharedUser.fullName}
                            {rolesForTooltip(user.roleIds)}
                        </div>
                    </div>
                );
                return tooltip;
            }, []);

        const index = authUserPermissionId // показать первым пользователя
            ? users.findIndex(user => user.sharedUser.permissionId.includes(authUserPermissionId))
            : 0;
        const user = users[index > -1 ? index : 0];

        elements = <Tooltip
            className={classTooltip}
            delay={300}
            isNoWrap
            value={tooltipTitle}
            footer={assignTooltipUsers}
        >
            <div className={classUser}>
                <UserpicStorage
                    initials={getInitials(user.sharedUser)}
                    src={getPhotoUrl(user)}
                    onClick={onClick}
                    userId={user.sharedUser.permissionId}
                />
                {isBig && !!user.roleIds && !!user.roleIds.length && !!roles && !!roles.length &&
                    <div className={classUserRoles}>
                        {getUserRoles(user.roleIds).map(role => (
                            <div
                                className={`
                                        ${classUserRole}
                                        ${classUserRole}--${role.color}
                                    `}
                                key={role.id}
                            />
                        ))}
                    </div>
                }
            </div>
            <div className={classNumber} onClick={onClick}>
                {isBig
                    ? '+' + (users.length - 1)
                    : users.length
                }
            </div>
        </Tooltip>
    }

    return (
        <div className={`
            ${className}
            ${classNameBig}
        `}>
            {elements}
        </div>
    );
}
