import { TCardId, TListId, TRecurringTaskId } from '../../types';
import { IFrequencyDay } from './IFrequencyDay';
import { IFrequencyWeek } from './IFrequencyWeek';
import { IFrequencyYear } from './IFrequencyYear';
import { IFrequencyDayOfMonth } from './IFrequencyDayOfMonth';
import { IFrequencyDayOfWeekInMonth } from './IFrequencyDayOfWeekInMonth';

export enum ERecurringTaskEndType {
    NEVER = 'never',
    REPEATS = 'repeats',
    DATE = 'date'
}

export enum ERecurringTaskFrequencyType {
    DAY = 'day',
    WEEK = 'week',
    YEAR = 'year',
    DAY_OF_MONTH = 'dayOfMonth',
    DAY_OF_WEEK_IN_MONTH = 'dayOfWeekInMonth'
}

export enum ERecurringTaskStatus {
    NOT_PROCESSED = 'not_processed',
    PROCESSED = 'processed',
    CLOSED = 'closed',
    PENDING = 'pending'
}

export interface IRestRecurringTask {
    // from RecurringTaskRest.java
    id?: TRecurringTaskId;
    status?: ERecurringTaskStatus;
    startDate?: number;
    dueDate?: number;
    listId?: TListId;
    cardId?: TCardId;
    endType?: ERecurringTaskEndType;
    frequencyType?: ERecurringTaskFrequencyType;
    repeats?: number;
    day?: IFrequencyDay;
    week?: IFrequencyWeek;
    year?: IFrequencyYear;
    dayOfMonth?: IFrequencyDayOfMonth;
    dayOfWeekInMonth?: IFrequencyDayOfWeekInMonth;
}
