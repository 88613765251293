import { root } from '../../../../../../store/constants';
import { TCardId } from '../../../../../../types/types';
import { Dispatch } from '../../../../../../types/actions';
import { timeTrackerSetAction } from '../../../../aside_panel/cardDetails/TabTimingSection/TimeTracker/store/actions/timeTrackerSetAction';

export const showTimeTrackerDialog = (
    callBackCardId?: TCardId
) => {
    return (
        dispatch: Dispatch
    ) => {
        dispatch(timeTrackerSetAction({ stopTime: Date.now() }));
        root.App.controller.showTimeTrackerDialogView({callBackCardId});
    };
};
