import { REST_COMMENTS_EDIT } from '../../../../constants';
import { TCardId } from '../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { fetchHandler } from '../../../../../util/fetchHandler';
import { Util } from '../../../../../util/util';
import { getRestHeadersPatch } from '../../../../helpers/getRestHeadersHelper';
import { IComment } from '../../../../../store/model/card/types/IComment';

export const patchRest = (
    comment: Partial<IComment>,
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        return fetchHandler<IComment>(
            Util.getApiUrl(REST_COMMENTS_EDIT + comment.id), {
                ...getRestHeadersPatch(),
                body: JSON.stringify(comment)
            }
        );
    };
    return action;
};
