import { ICard } from '../../card/types';
import { ICardsComparator } from './types';
import { compareNumbers } from './compareNumbers';
import { ECardAgingIndicatorType } from '../../../../types/rest/ICardAging';

const getCardValue = (
    card: ICard,
    cardAgingType: ECardAgingIndicatorType
): number => cardAgingType === ECardAgingIndicatorType.BY_LIST ? card.moveInListDate : card.lastActivityDateStamp;

export const age: ICardsComparator = {
    getCardValue,
    sort: compareNumbers
}
