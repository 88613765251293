import { DAY_LENGTH } from '../DatepickerItem/constants';
import { getMessages } from '../../../../../../../store/constants';

const weekLength = DAY_LENGTH * 7;
const monthLength = weekLength * 4;

const today = new Date();
const todayTimestamp = today.getTime();
const nextWeek = new Date(today);

for (let i = 0;;i++) {
    nextWeek.setDate(nextWeek.getDate() + 1);
    if (nextWeek.getDay() === 1) break;
}

export const QUICK_DATES = [
    {
        title: getMessages().getText('datepicker.quick_date.today'),
        value: today,
        isWeekDay: true
    },
    {
        title: getMessages().getText('datepicker.quick_date.tomorrow'),
        value: new Date(todayTimestamp + DAY_LENGTH),
        isWeekDay: true
    },
    {
        title: getMessages().getText('datepicker.quick_date.next_week'),
        value: nextWeek,
        isWeekDay: true
    },
    {
        title: getMessages().getText('datepicker.quick_date.two_weeks'),
        value: new Date(todayTimestamp + weekLength * 2)
    },
    {
        title: getMessages().getText('datepicker.quick_date.four_weeks'),
        value: new Date(todayTimestamp + weekLength * 4)
    },
    {
        title: getMessages().getText('datepicker.quick_date.two_month'),
        value: new Date(todayTimestamp + monthLength * 2)
    },
    {
        title: getMessages().getText('datepicker.quick_date.four_month'),
        value: new Date(todayTimestamp + monthLength * 4)
    },
    {
        title: getMessages().getText('datepicker.quick_date.six_month'),
        value: new Date(todayTimestamp + monthLength * 6)
    },
];
