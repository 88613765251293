import { IGetState } from '../../../../types/types';
import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { addRecentOpenBoards } from './addRecentOpenBoards';

export const addRecentOpenBoard: ActionCreator<ThunkAction> = (
    boardId: number,
    placeToTop: boolean = true
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
         return dispatch(addRecentOpenBoards([boardId], placeToTop));
    }
    return action;
}
