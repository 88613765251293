import { IKanbanchiWindow } from '../types/backbone';
import { compose } from 'redux';

// @ts-ignore
export const root = window as IKanbanchiWindow;

export const getController = () => root.App.controller as any;
export const getMessages = () => root.Messages as any;

export const vent = root.App.vent as any;

export const productLabel = (productId: number): string => {
    switch (productId) {
        case 13 : {
            return 'Premium monthly';
        }
        case 14 : {
            return 'Premium yearly';
        }
        case 15 : {
            return 'Professional monthly';
        }
        case 16 : {
            return 'Professional yearly';
        }
        case 17 : {
            return 'Enterprise monthly';
        }
        case 18 : {
            return 'Enterprise';
        }
        default : {
            throw new Error(`Unknown productId`);
        }
    }
};

export const productCat = (productId: number): string => {
    switch (productId) {
        case 13 :
        case 15 :
        case 17 :
            return 'Monthly';

        case 14 :
        case 16 :
        case 18 :
            return 'Annualy';

        default :
            throw new Error(`Unknown productId`);
    }
};

export const ASYNC_REQUEST_TYPE = '_REQUEST';
export const ASYNC_SUCCESS_TYPE = '_SUCCESS';
export const ASYNC_FAILURE_TYPE = '_FAILURE';

export const CLICK_OUTSIDE_SNACKBAR = 'click_outside_snackbar';

declare global {
    // tslint:disable-next-line: interface-name
    interface Window {
      __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: any;
    }
}

const REDUX_DEVTOOLS_DEFAULTS = {
    instanceId: 'store',
    trace: true,
    traceLimit: 25
};

export const getEnhancers = (
    options: any = {}
) => {
    return window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            ...REDUX_DEVTOOLS_DEFAULTS,
            ...options
        })
        : compose;
}
