import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { boardDelete } from '../../../store/boards/actions/boardDelete';
import { IGetState, TBoardId } from '../../../../../../../types/types';
import { TSnackbarAction } from '../types';
import { closeRecentOpenBoard } from '../../../../../../../rest/effects/authUser/recentBoards/closeRecentOpenBoard';
import { getUserLoginSpecificBoard } from 'app/store/model/authUser/selectors/getUserLoginSpecificBoard';
import { authUserSetLoginSpecificBoard } from 'app/rest/effects/authUser/authUserSetLoginSpecificBoard';
import { authUserSetIsOpenLastBoard } from 'app/rest/effects/authUser/authUserSetIsOpenLastBoard';

export const onBoardDeleted: ActionCreator<ThunkAction> = (
    boardId: TBoardId,
    afterDelete: TSnackbarAction
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(boardDelete(boardId));
        dispatch(closeRecentOpenBoard(boardId));
        const specificBoard = getUserLoginSpecificBoard(getState());
        if (specificBoard && boardId === specificBoard.id) {
            dispatch(authUserSetIsOpenLastBoard(false));
            dispatch(authUserSetLoginSpecificBoard(null));
        }
        if (afterDelete) afterDelete();
    };
    return action;
};
