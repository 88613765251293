import { decodeBase64Image } from 'app/util/decodeBase64Image';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../../../../types/types';
import { IDriveDoc } from 'app/store/model/card/types/IDriveDoc';
import { getBoardIdByCardId } from 'app/store/model/selectors/getBoardIdByCardId';
import { getBoardPlatformType } from 'app/store/model/selectors/getBoardPlatformType';
import { EAuthUserPlatformType } from 'app/types/rest/IRestAuthUser';
import { uploadFiles as uploadFilesGoogle } from '../../../../AttachmentsSection/hocs/CardAttachmentsSectionHOC/effects/uploadFiles';
import { uploadFiles as uploadFilesMS } from '../../../../AttachmentsSection/hocs/microsoft/CardAttachmentsSectionHOC/effects/uploadFiles';

export const onAddImage = (
    cardId: TCardId,
    base64: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const onProgress = () => {}
        const platformType = getBoardPlatformType(state, getBoardIdByCardId(state, cardId));
        let uploadFiles;
        switch (platformType) {
            case EAuthUserPlatformType.MICROSOFT: {
                uploadFiles = uploadFilesMS;
                break;
            }
            default: uploadFiles = uploadFilesGoogle;
        }
        return dispatch(uploadFiles(cardId, [decodeBase64Image(base64, '')], onProgress, true))
            .then((driveDocs: IDriveDoc[]) => {
                return driveDocs && driveDocs.length && driveDocs[0];
            });
    };
    return action;
};
