import { IApplicationState, TListId } from '../../../types/types';
import { IList } from '../list/types';
import { getList } from '../list/selectors/getList';

/***
 * Выдает коллекцию листов из state, НО только с полями которые присутствуют в patchList. Т.е. если мы меняем status, то будет {5567136609075200: {status: 0} }
 * @param state
 * @param patchCards
 */
export const getListForRollback = (
    state: IApplicationState,
    listId: TListId,
    patchList: IList
): IList => {

    const list = getList(state, listId);
    if (!patchList || !list) return;
    let listForRollback: IList = {};

    for (const field in patchList) {
        const fieldKey = field as keyof IList;
        listForRollback = {
            ...listForRollback,
            [fieldKey]: list[fieldKey]
        };
    }

    return JSON.parse(JSON.stringify(listForRollback));
}
