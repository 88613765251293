import { Dispatch, ThunkAction } from '../../../../../../types/actions';
import { isBoardCardsLimitReached } from '../../../../../../store/model/selectors/isBoardCardsLimitReached';
import { showBoardLimitSnackbarAndEvent } from '../../../../../../store/model/effects/showBoardLimitSnackbarAndEvent';
import { SegmentUserTypeOption } from '../../../../../../middlewares/segment/trackEntities/userEvents';
import { IGetState, TBoardId } from '../../../../../../types/types';

export const onClick = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (isBoardCardsLimitReached(getState(), boardId , 1)){
            dispatch(showBoardLimitSnackbarAndEvent(SegmentUserTypeOption.VIEW_ONLY_CLICK));
        }
    };
    return action;
};
