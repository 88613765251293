import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../types/types';
import { TStatus } from '../../../../../types/model';
import { getBoardByCardId } from '../../../../../store/model/selectors/getBoardByCardId';
import { getCardFullName } from '../../../../../store/model/card/selectors/getCardFullName';
import { relatedCardDelete } from './relatedCardDelete';
import { relatedCardAdd } from './relatedCardAdd';
import { selectRelatedCard } from '../../../clickManager/effects/selectRelatedCard';
import { relatedCardAddedTargetBoardEvent } from './segmentEvents';

export const relatedCardToggle = (
    relatedCardId: TCardId
): ThunkAction => {
    return (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const { cardId, cardsMap } =  getState().relatedCards;
        if (relatedCardId !== cardId) {
            let relatedCard = cardsMap[relatedCardId];//todo O_O move to selector

            if (relatedCard && relatedCard.status === TStatus.STATUS_ACTIVE) {
                dispatch(relatedCardDelete(relatedCardId));
            } else {
                if (!relatedCard) {
                    const cardName = getCardFullName(getState(), relatedCardId);
                    const board = getBoardByCardId(getState(), relatedCardId);
                    relatedCard = {
                        cardId: relatedCardId,
                        cardName,
                        dashboardId: board.id,
                        dashboardName: board.name,
                        status: TStatus.STATUS_ACTIVE
                    }
                }
                dispatch(relatedCardAdd(relatedCard));
                dispatch(relatedCardAddedTargetBoardEvent(relatedCard));
            }
            dispatch(selectRelatedCard(relatedCard.dashboardId, relatedCard.cardId));
        }
    };
};
