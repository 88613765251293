import { AT_HINTS_SET } from './constants';
import { IHintsSetAction } from './types';
import { IHints } from '../types';

export const hintsSetAction = (
    hints: IHints
): IHintsSetAction => ({
        type: AT_HINTS_SET,
        hints
    }
);
