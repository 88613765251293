import { ISharedUser } from './ISharedUser';
import { IAuthorRest, IColor, IPermissions, TStatus } from '../model';
import { IRestTemplateInfo } from '../../view/react_components/dialogs/openBoards/rest/types';
import { IRestList } from './IRestList';
import { TListId } from '../types';
import { IRestUserBoardMeta } from './IRestUserBoardMeta';
import { IRestBoardMeta } from './IRestBoardMeta';
import { IRestCardAssigneeRole } from './IRestCardAssigneeRole';
import { IRestTheme } from './IRestTheme';
import { EAuthUserPlatformType } from 'app/types/rest/IRestAuthUser';

export interface IRestDashboard {
    //from DashboardLightRest.java
    id?: number;
    name?: string;
    author?: IAuthorRest;
    description?: string;
    created?: number;
    permissions?: IPermissions;
    shared?: boolean;
    starred?: boolean;
    theme?: IRestTheme;
    timeZone?: string;
    workHoursInDay?: number;
    hash?: string;
    status?: TStatus;
    templateInfo?: IRestTemplateInfo;

    //from DashboardRest.java
    fileId?: string;
    platformType?: EAuthUserPlatformType,
    colors?: IColor[];
    lists?: IRestList[];
    users?: ISharedUser[];
    copiedFromId?: number;
    template?: boolean;
    logo?: string;
    logoColor?: string;
    logoIcon?: string;
    cardFromEmail?: string;
    version?: EBoardVersion;
    parentFolderInfo?: IFolderInfo;
    backupFolderInfo?: IFolderInfo;

    cardNumberEnabled?: boolean;
    cardNumberNextValue?: number;
    cardNumberPrefix?: string;
    cometToken?: string;
    showOnGantt?: boolean;
    lockDate?: number | string;
    enableOpenGraphInfo?: boolean;
    archiveCardsOnDelete?: boolean;
    assetFolderId?: string;
    userMeta?: IRestUserBoardMeta;
    meta?: IRestBoardMeta;
    statusLists?: IDashboardStatusLists;
    cardAssigneeRoles?: IRestCardAssigneeRole[];
    driveId?: string;
    dashboardSettings?: IRestDashboardSettings;
    allowReports?: boolean;
    lastUpdatePermissionTime?: number;
}

export interface  IFolderInfo{
    folderId: string;
    driveId?: string;
    webUrl?: string;
}

export interface IRestDashboardSettings {
    lngs?: string[];
}

export enum EDashboardStatusListType {
    ON_HOLD = 'onHoldId',
    IN_WORK = 'inWorkId',
    DONE = 'doneId'
}

export type IDashboardStatusLists = {
    [key in EDashboardStatusListType]: TListId
}

export enum EBoardVersion {
    /**
     * add cardJson
     */
    V3 = 3,
    /** DEPRECATED no longer used, commented for history, migration on CardAssigneeInfo and MyTasks processing via flag
     * create CardAssigneeInfo justRestoredFromBackups
     */
    V4 = 4,
    /** DEPRECATED no longer used, commented for history, migration on CardAssigneeInfo and MyTasks processing via flag
     * create CardAssigneeInfo justRestoredFromBackups
     * my tasks
     */
    V5 = 5,
    /**
     * create Assignee, deleted UserRest
     */
    V6 = 6,
    /**
     * added flag isMyTasksDone for create CardAssigneeInfo via /migrate-v2
     */
    V7 = 7,
    /**
     * migrated archived cards
     */
    V8 = 8,
    /**
     * new color labels from 2023-01-17
     */
    V9 = 9,
    /**
     * new color labels fix
     */
    V10 = 10
};
