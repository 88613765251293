// tslint:disable: indent
import * as React from 'react';
import {
	CLASS_HTML_EDITOR,
	HTML_EDITOR_GOOGLE_THUMB_SIZE,
	TOOLTIP_BOLD,
	TOOLTIP_DELETE_FORMAT,
	TOOLTIP_IMAGE,
	TOOLTIP_ITALIC,
	TOOLTIP_LINK,
	TOOLTIP_LINK_PLACEHOLDER,
	TOOLTIP_ORDERED_LIST,
	TOOLTIP_QUOTE,
	TOOLTIP_STRIKE,
	TOOLTIP_UNORDERED_LIST
} from './constants';
// import 'react-quill/dist/quill.bubble.css';
import { EEditorType, IHtmlEditorProps } from './types';
// import { Quill } from 'react-quill';
import './_HtmlEditor.scss';
import {
	CLASS_ASIDE_PANEL_CONTAINER_ACTIONS_BODY
} from 'app/view/react_components/aside_panel/asidePanel/components/AsidePanelBody/constants';
import { root as window } from 'app/store/constants';
import { LoaderBlock } from 'kui';
import { IDriveDoc } from 'app/store/model/card/types/IDriveDoc';
import { msFileThumbnailStorage } from '../../../../../helper/authorisation/microsoft/storage/msThumbnail';
import { THUMB_COMMENT_SIZE } from 'app/const';
import { googleFileThumbnailStorage } from '../../../../../helper/authorisation/google/storage/googleThumbnail';

// const ReactQuill = require('react-quill');
const TurndownService = require('turndown').default;

export function HtmlEditor({
	selectionTree,
	type,
	value,
	onAddImage,
	onChange,
	onCtrlEnter,
	onEnter,
	onEscape,
	onKeyDown,
	...attributes
}: IHtmlEditorProps) {
	return null;
	if (value &&
		(!type || type !== EEditorType.NEW_COMMENT) // в new comment ломаются меншены
	) {
		value = value.replaceAll('<br>', '<span style="color: rgb(255, 255, 255);"> </span>');
	}

	const IconBold = require('!!@svgr/webpack!./icons/bold.svg').default;
	const IconItalic = require('!!@svgr/webpack!./icons/italic.svg').default;
	const IconStrike = require('!!@svgr/webpack!./icons/strikethrough.svg').default;
	const IconOL = require('!!@svgr/webpack!./icons/ol.svg').default;
	const IconUL = require('!!@svgr/webpack!./icons/ul.svg').default;
	const IconLink = require('!!@svgr/webpack!./icons/link.svg').default;
	const IconImage = require('!!@svgr/webpack!./icons/image.svg').default;
	const IconQuote = require('!!@svgr/webpack!./icons/quote.svg').default;
	const IconClean = require('!!@svgr/webpack!./icons/clear-formatting.svg').default;

	const className = CLASS_HTML_EDITOR;
	const classLoader = className + '__loader'
	const quill = React.useRef();

	const turndownService = new TurndownService({preformattedCode: true});
	turndownService.addRule('strikethrough', {
		filter: ['del', 's', 'strike'],
		replacement: function (content: string) {
			return '~' + content + '~'
		}
	});
	turndownService.addRule('pre', {
		filter: 'pre',
		replacement: function (content: string) {
		  	return '\n```\n' + content + '\n```\n'
		}
	});
	turndownService.addRule('br', {
		filter: 'br',
		replacement: function (content: string) {
		  	return '  \n';
		}
	});

	const isInited = React.useRef(null);
	const debounceChange = React.useRef(null);
	const onChangeHandler = (value: string) => {
		const html = turndownService.turndown(value.replaceAll('\n', '<br>'));
		if (quill.current) onChange(html); // dont onChange while init

		prepareContent();
    }

	const prepareContent = () => {
		let q = quill.current as any;
		if (q &&
			q.editor &&
			q.editor.root
		) {
			let root = q.editor.root as HTMLElement;
			const links = root.querySelectorAll('.ql-editor a');
			links.forEach(link => {
				let href = link.getAttribute('href');
				const hrefReg = /^https?:\/\//i;
				if (href && !hrefReg.test(href)) {
					href = 'http://' + href;
					link.setAttribute('href', href);
				}
				link.setAttribute('title', href);
			});

			const image = root.querySelector('img[src*=base64]:not(.loading)');
			if (image) {
				const loader = root.closest(`.${CLASS_HTML_EDITOR}`).parentNode.querySelector('.' + classLoader) as HTMLElement;
				if (loader) loader.classList.add('loading');

				onAddImage(image.getAttribute('src'))
					.then((driveDoc: IDriveDoc) => {
						if (msFileThumbnailStorage.is()) {
                            const thumbSize = `c${THUMB_COMMENT_SIZE}x${THUMB_COMMENT_SIZE}`;
                            msFileThumbnailStorage.getThumbnail(driveDoc.driveId, driveDoc.fileId, thumbSize)
                                .then(thumb => {
									image.setAttribute('src', thumb.url + '#driveId=' + driveDoc.driveId + '#fileId=' + driveDoc.fileId);
								});
                        } else if (googleFileThumbnailStorage.is()) {
							image.addEventListener('error', () => {
								googleFileThumbnailStorage.reGetThumbnail(
									driveDoc.fileId,
									HTML_EDITOR_GOOGLE_THUMB_SIZE,
									(thumb) => image.setAttribute('src', thumb),
								);
							});
							googleFileThumbnailStorage.getThumbnail(driveDoc.fileId, HTML_EDITOR_GOOGLE_THUMB_SIZE).then(thumb => {
								image.setAttribute('src', thumb);
							});
                        }
					})
					.finally(() => {
						if (loader) loader.classList.remove('loading');
					});
			}

			/**
			 * если например удалить текст h1, то остаётся <h1><br></h1>
			 * надо удалить это всё
			 */
			const brs = root.querySelectorAll('.ql-editor br');
			brs.forEach(br => {
				const parent = br.parentNode as HTMLElement;
				if (parent &&
					parent.tagName !== 'P' &&
					parent.tagName !== 'LI' &&
					parent.children.length < 2
				) parent.remove();
			});

			/**
			 * перенос строки на Shift + Enter
			 */
			const shiftBrStyle = 'style="color: rgb(255, 255, 255);"';
			const shiftBrs = root.querySelectorAll(`.ql-editor [${shiftBrStyle}]`);
			let parentPrev: Node;
			shiftBrs.forEach(shiftBr => {
				let tag = shiftBr.tagName.toLowerCase();
				const selection = window.getSelection();
				const parent = (shiftBr.parentNode || parentPrev) as HTMLElement;
				parentPrev = parent; // после первой замены parent почему-то теряется
				let i = Array.prototype.indexOf.call(parent.childNodes, shiftBr);
				let length = parent.childNodes.length;
				let offset = 0
				if (i > 0) {
					const prev = parent.childNodes[i-1] as HTMLElement;
					if (prev) {
						let val = prev.nodeValue || prev.innerHTML;
						offset = val.length;
					}
				}
				let span = document.createElement('span');
				span.style.color = '#fff';
				shiftBr.replaceWith(span);
				if (parent) {
					let h = parent.offsetHeight;
					parent.innerHTML = parent.innerHTML.replace(`<span ${shiftBrStyle}></span>`, '\n');
					if (parent.offsetHeight <= h) parent.innerHTML += '\n'; // в первый раз почему-то недостаточно одного перевода строки
				}
				if (isInited.current && i > 0) {
					const range = document.createRange();
					if (i >= length - 1) { // в конце
						range.selectNodeContents(parent);
						range.collapse(false);
					} else {
						try {
							range.setStart(parent.childNodes[i-1], offset + 1);
						} catch (e) {
							range.setStart(parent.childNodes[i], 1);
						}
					}
					selection.removeAllRanges();
					selection.addRange(range);
				}
			});
			/**
			 * prepareContent вызывается несколько раз подряд, isInited надо проставить после всего
			 */
			if (!isInited.current) {
				if (debounceChange.current) clearTimeout(debounceChange.current);
				debounceChange.current = setTimeout(() => {
					isInited.current = true;
				}, 500);
			};
		}
    }

	let BlockEmbed = null //Quill.import('blots/block/embed');
	class DividerBlot extends BlockEmbed {}
	// @ts-ignore
	DividerBlot.blotName = 'hr';
	// @ts-ignore
	DividerBlot.tagName = 'hr';
	// Quill.register(DividerBlot);

	const modules = {
		toolbar: [
		  [{ 'header': [1, 2, 3, false] }],
		  ['bold', 'italic', 'strike'],
		  [{'list': 'ordered'}, {'list': 'bullet'}],
		  ['link', 'image', 'blockquote'],
		  ['clean']
		],
	};

	const onKeyDownHadler = (
        e: React.KeyboardEvent
    ) => {
        if (e.key === 'Escape') {
            e.stopPropagation();
			let q = quill.current as any;
			let root = q && q.editor && q.editor.root as HTMLElement;
			const tooltip = root && root.querySelector('.ql-tooltip') as HTMLElement;
			if (tooltip && !tooltip.classList.contains('ql-hidden')) {
				tooltip.classList.add('ql-hidden');
			} else {
				if (onEscape) onEscape();
			}
        } else if (e.key === 'Enter') {
			if (onEnter) onEnter();
			if (e.ctrlKey || e.metaKey) {
				e.preventDefault();
				if (onCtrlEnter) onCtrlEnter();
				return;
			}
		}
		if (onKeyDown) onKeyDown(e as any);
    };

	React.useEffect(() => {
		if (quill.current) {
			let q = quill.current as any;

			if (q.editor &&
				q.editor.keyboard &&
				q.editor.keyboard.bindings
			) {
				if (onEnter) {
					if (q.editor.keyboard.bindings[13]) {
						q.editor.keyboard.bindings[1300] = JSON.parse(JSON.stringify(q.editor.keyboard.bindings[13]));
						delete q.editor.keyboard.bindings[13];
					}
				} else {
					if (q.editor.keyboard.bindings[1300]) {
						q.editor.keyboard.bindings[13] = JSON.parse(JSON.stringify(q.editor.keyboard.bindings[1300]));
						delete q.editor.keyboard.bindings[1300];
					} else {
						q.editor.keyboard.bindings[13].unshift({
							key: 13,
							shiftKey: true,
							handler: (range: any, ctx: any) => {
								q.editor.insertText(range.index, ' ',  {'color': '#fff'}); // добавляет span, который потом меняется в prepareContent
							}
						});
					}
				}
			}
		}
    }, [onEnter]);

	React.useEffect(() => {
		function onEsc (e: KeyboardEvent) {
			if (e.key === 'Escape') {
                e.preventDefault();
            }
        };

		let observer: MutationObserver;
		setTimeout(() => {

			let q = quill.current as any;
			let container = q && q.editor && q.editor.container as HTMLElement;

			const leftMin = 16; // editor padding-left
			observer = new MutationObserver(mutations => {
				mutations.forEach(mutation => {
					const tooltips = container && container.querySelectorAll('.ql-tooltip');
					if (tooltips) tooltips.forEach((tooltip: HTMLElement) => {
						window.removeEventListener('keydown', onEsc);

						if (tooltip.classList.contains('ql-hidden')) {
							// null
						} else {
							window.addEventListener('keydown', onEsc);

							if (tooltip.offsetLeft < leftMin) {
								tooltip.style.left = leftMin + 'px';
							} else {
								const aside = container.closest('.' + CLASS_ASIDE_PANEL_CONTAINER_ACTIONS_BODY) as HTMLElement;
								if (aside &&
									tooltip.offsetLeft + tooltip.offsetWidth > aside.offsetWidth - leftMin * 2
								) {
									const left = aside.offsetWidth - tooltip.offsetWidth - leftMin * 3;
									tooltip.style.left = left + 'px';
								}
							}

							const input = tooltip.querySelector('.ql-tooltip-editor input') as HTMLElement;
							if (input) {
								input.dataset.link = TOOLTIP_LINK_PLACEHOLDER;
							}

							const icons = container.closest(`.${className}`).parentNode.querySelector(`.${className}__icons`) as HTMLElement;

							const iconBold = tooltip.querySelector('.ql-bold svg');
							const iconBoldNew = icons.querySelector('.' + className + '__icons .' + className + '__icon-bold');
							if (iconBold && iconBoldNew) iconBold.replaceWith(iconBoldNew);

							const iconItalic = tooltip.querySelector('.ql-italic svg');
							const iconItalicNew = icons.querySelector('.' + className + '__icons .' + className + '__icon-italic');
							if (iconItalic && iconItalicNew) iconItalic.replaceWith(iconItalicNew);

							const iconStrike = tooltip.querySelector('.ql-strike svg');
							const iconStrikeNew = icons.querySelector('.' + className + '__icons .' + className + '__icon-strike');
							if (iconStrike && iconStrikeNew) iconStrike.replaceWith(iconStrikeNew);

							const iconOL = tooltip.querySelector('.ql-list[value="ordered"] svg');
							const iconOLNew = icons.querySelector('.' + className + '__icons .' + className + '__icon-ol');
							if (iconOL && iconOLNew) iconOL.replaceWith(iconOLNew);

							const iconUL = tooltip.querySelector('.ql-list[value="bullet"] svg');
							const iconULNew = icons.querySelector('.' + className + '__icons .' + className + '__icon-ul');
							if (iconUL && iconULNew) iconUL.replaceWith(iconULNew);

							const iconLink = tooltip.querySelector('.ql-link svg');
							const iconLinkNew = icons.querySelector('.' + className + '__icons .' + className + '__icon-link');
							if (iconLink && iconLinkNew) iconLink.replaceWith(iconLinkNew);

							const iconImage = tooltip.querySelector('.ql-image svg');
							const iconImageNew = icons.querySelector('.' + className + '__icons .' + className + '__icon-image');
							if (iconImage && iconImageNew) iconImage.replaceWith(iconImageNew);

							const iconQuote = tooltip.querySelector('.ql-blockquote svg');
							const iconQuoteNew = icons.querySelector('.' + className + '__icons .' + className + '__icon-quote');
							if (iconQuote && iconQuoteNew) iconQuote.replaceWith(iconQuoteNew);

							const iconClean = tooltip.querySelector('.ql-clean svg');
							const iconCleanNew = icons.querySelector('.' + className + '__icons .' + className + '__icon-clean');
							if (iconClean && iconCleanNew) iconClean.replaceWith(iconCleanNew);
						}
					});
				});
			});
			const tooltip = container && container.querySelector('.ql-tooltip');
			if (tooltip) {
				observer.observe(tooltip, { attributes: true });
			}

			let btn = container && container.querySelector('.ql-bold');
			if (btn) btn.setAttribute('title', TOOLTIP_BOLD);
			btn = container && container.querySelector('.ql-italic');
			if (btn) btn.setAttribute('title', TOOLTIP_ITALIC);
			btn = container && container.querySelector('.ql-strike');
			if (btn) btn.setAttribute('title', TOOLTIP_STRIKE);
			btn = container && container.querySelector('.ql-list[value="ordered"]');
			if (btn) btn.setAttribute('title', TOOLTIP_ORDERED_LIST);
			btn = container && container.querySelector('.ql-list[value="bullet"]');
			if (btn) btn.setAttribute('title', TOOLTIP_UNORDERED_LIST);
			btn = container && container.querySelector('.ql-link');
			if (btn) btn.setAttribute('title', TOOLTIP_LINK);
			btn = container && container.querySelector('.ql-image');
			if (btn) btn.setAttribute('title', TOOLTIP_IMAGE);
			btn = container && container.querySelector('.ql-blockquote');
			if (btn) btn.setAttribute('title', TOOLTIP_QUOTE);
			btn = container && container.querySelector('.ql-clean');
			if (btn) btn.setAttribute('title', TOOLTIP_DELETE_FORMAT);

			try {
				let start = q && q.editor && q.editor.root as HTMLElement;
				if (start) {
					for (let i=selectionTree.length-1; i>0; i--) {
						start = start.childNodes[selectionTree[i]] as HTMLElement;
					}
					const range = document.createRange();
					range.setStart(start, selectionTree[0]);
					const selection = window.getSelection();
					selection.removeAllRanges();
					selection.addRange(range);
				}
			} catch (e) {}
		}, 300);

		prepareContent();

        return () => {
            window.removeEventListener('keydown', onEsc);
        }
    }, []);

	return (<>
		{/* <ReactQuill
			className={className}
			modules={modules}
			ref={quill}
			theme="bubble"
			value={value}
			onChange={onChangeHandler}
			onKeyDown={onKeyDownHadler}
			{...attributes}
		/> */}
		<LoaderBlock
			className={classLoader}
		/>
		<div className={className + '__icons'} >
			<IconBold className={className + '__icon-bold'} />
			<IconItalic className={className + '__icon-italic'} />
			<IconStrike className={className + '__icon-strike'} />
			<IconOL className={className + '__icon-ol'} />
			<IconUL className={className + '__icon-ul'} />
			<IconLink className={className + '__icon-link'} />
			<IconImage className={className + '__icon-image'} />
			<IconQuote className={className + '__icon-quote'} />
			<IconClean className={className + '__icon-clean'} />
		</div>
	</>);
}
