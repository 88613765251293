import { IGetState, TBoardId, TDriveId } from '../../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { getMessages, root } from '../../../../../../../../../store/constants';
import { getAvailableBoardsCount } from '../../../../../../../dialogs/openBoards/helpers/getAvailableBoardsCount';
import { snackbarPromtYesNo } from '../../../../../../../snackbarsStack/effects/variantPromt/snackbarPromtYesNo';
import { BOARDS_LIMIT_CANCEL_TEXT, BOARDS_LIMIT_EXCEEDED_TEXT, BOARDS_LIMIT_OPEN_NEW_TAB_TEXT } from '../constants';
import { openBoard } from '../../../../../../../dialogs/openBoards/effects/openBoard';
import { DashboardXmlImportManager } from '../../../../../../../../../helper/xml_import_export/dashboard_xml_import_manager_no_backbone';
import { getBoardBackup } from '../../../../../../../../../store/model/boardBackups/selectors/getBoardBackup';
import { getBoard } from 'app/store/model/selectors/getBoardById';
import { EAuthUserPlatformType } from 'app/types/rest/IRestAuthUser';
import {
    boardRestoreSharedUsersIfNeeded
} from '../../../../../../../../../helper/loader/boardRestoreSharedUsersIfNeeded';

export const boardBackupRestore = (
    boardId: TBoardId,
    fileId: string,
    driveId: TDriveId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const backup = getBoardBackup(state, boardId, fileId);
        if (!backup) return;

        let messageTitle = getMessages().getText('popup.dashboard.restoring.header');

        const board = getBoard(state, boardId);
        if (board.platformType === EAuthUserPlatformType .MICROSOFT){
            messageTitle =getMessages().getText('popup.dashboard.restoring.oneDrive.header');
        }

        const manager = new DashboardXmlImportManager({
            onCancel: () => {},
            onError: (xhr: any) => {
                dispatch(onError(xhr));
                root.App.controller.mainView.removeBlockerModal();
            },
            onProgress: (percent: number) => {
                if (root.App.controller.mainView.blockerModal) root.App.controller.mainView.blockerModal.update({
                    text: getMessages().getText('popup.dashboard.restoring') + '... ' + Math.round(percent) + '%',
                    title: messageTitle,
                });
            },
            onSuccess: ({destDashboardId,parentFolderInfo}) => {
                root.App.controller.mainView.removeBlockerModal();
                dispatch(boardRestoreSharedUsersIfNeeded(
                    destDashboardId,
                    parentFolderInfo.driveId,
                    parentFolderInfo.folderId,
                    () => {
                        dispatch(onSuccess(destDashboardId));
                    }
                ));
            },
        });
        root.App.controller.mainView.renderBlockerModal({
            text: getMessages().getText('popup.dashboard.restoring'),
            title: messageTitle,
        });

        manager.startImport({
            importFileId: fileId,
            dashboardName: backup.title,
            destDriveId: driveId,
            importDriveId: backup.driveId
        });
    };
    return action;
};

const onError = (
    xhr: any
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        console.error('error', xhr);
    };
    return action;
};

const onSuccess = (
    boardId: TBoardId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        root.App.controller.trackEvent(
            getMessages().getText('ga.category.backups'),
            getMessages().getText('ga.action.backups.restored')
        );
        root.App.vent.trigger(root.App.vent['dialog:closed']);
        const availableBoardsCount = getAvailableBoardsCount(getState());
        if (availableBoardsCount <= 0) {
            return dispatch(snackbarPromtYesNo({
                id: 'SNACKBAR_BOARD_LIMIT_EXCEEDED',
                text: BOARDS_LIMIT_EXCEEDED_TEXT,
                title: '',
                applyBtnTitle: BOARDS_LIMIT_OPEN_NEW_TAB_TEXT,
                cancelBtnTitle: BOARDS_LIMIT_CANCEL_TEXT,
                actionApply: () => {
                    window.open(root.App.router.getPageUrl(boardId));
                },
                actionCancel: () => {}
            }));
        }
        return dispatch(openBoard(boardId));
    };
    return action;
};
