import { IBoardsDeleteAction } from './types';
import { AT_BOARDS_DELETE } from './constants';
import { TBoardId } from '../../../../../../../types/types';

export const boardDelete = (
    boardId: TBoardId
): IBoardsDeleteAction => ({
    type: AT_BOARDS_DELETE,
    boardId
});
