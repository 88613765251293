/*
Use for print views.
*/

'use strict';

App.Views.PrintDialogRenderer = Backbone.View.extend({
    el: '.print',
    
    initialize: function(options) {
        App.vent.on(App.vent['dialog:hide'], this.hide, this);
        App.vent.on(App.vent['dashboard:close'], this.hide, this);
        this.currentView = null;
    },
    
    render: function() {
        return this;
    },
    
    show: function(view) {
        this.clear();
        this.currentView = view;
        this.$el.removeClass('hidden');
        this.showCurrentView();
        App.controller.htmlScrollDisabledToggle(true);
    },
    
    showCurrentView: function() {
        this.$el.html(this.currentView.render().el);
        if (this.currentView.afterDOMRendering) {
            this.currentView.afterDOMRendering();
        }
    },

    showListPrintPreview: function(listId) {
        window.onafterprint = this.hide;
        this.show(new App.Views.ListPrintPreview({ listId }));
        window.print();
    },

    hide: function() {
        if (this.currentView) {
            this.clear();
            this.$el.addClass('hidden');
            App.controller.htmlScrollDisabledToggle();
        }
        window.onafterprint = null;
    },

    clear: function() {
        if (this.currentView) {
            this.currentView.remove();
            this.currentView = null;
        }
    },

    remove: function() {
        App.vent.off(null, null, this);
        return Backbone.View.prototype.remove.call(this);
    }
    
});
