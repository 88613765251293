const category = 'Assigned to me';

export const SegmentAssignedToMeEvent = {
    ASSIGNED_TO_ME_OPENED: { category, event: 'Assigned to me opened' },
    BOARDS_LIST: {category, event: 'boards list'},
    NEW_CARDS_CLICKED: { category, event: 'new cards clicked' },
    ASSIGNED_TO_ME_FAQ_CLICKED: { category, event: 'assigned to me faq clicked' },
    CARD_PINNED: { category, event: 'card pinned' },
    CARD_UNPINNED: { category, event: 'card unpinned' },
    SORT_CLICKED: { category, event: 'sort clicked' },
    UPDATES_CLICKED: { category, event: 'Updates clicked' },
    SHOW_HIDDEN_CARDS_CLICKED: { category, event: 'Show hidden cards clicked' },
    HIDE_CARD_CLICKED: { category, event: 'Hide card clicked' },
    HIDE_ALL_CARDS_CLICKED: { category, event: 'Hide all cards clicked' },
    SHOW_CARD_CLICKED: { category, event: 'Show card clicked' },
    SHOW_ALL_CARDS_CLICKED: { category, event: 'Show all cards clicked' },
    CARD_CLICKED_IN_SEARCH: { category, event: 'Card clicked in search' },
    CARD_WITHOUT_ASSIGNEES_FOUND: { category, event: 'assigned card without assignees found' },
    ASSIGNED_TO_ME_CLOSED: { category, event: ' Assigned to me closed' },
};

export enum SegmentAssignedToMeOptions {
    TYPE = 'type',
    PINNED_CARDS = 'pinned cards',
    NEW_CARDS = 'new cards',
    TOTAL_CARDS = 'total cards',
    TARGET = 'target',
    COUNT = 'count',
    BOARDS = 'boards'
}

export enum SegmentAssignedToMeSortTypes {
    ASC = 'asc',
    DESC = 'desc',
    DISABLED = 'disabled'
}
