import { IApplicationState, TBoardId } from '../../../types/types';
import { getBoard } from './getBoardById';
import {
    DRIVE_ID_MY_DRIVE,
    DRIVE_ID_SHARED_WITH_ME
} from '../../../view/react_components/dialogs/openBoards/store/drives/constants';

export const getBoardIsOnSharedDrive = (
    state: IApplicationState,
    boardId: TBoardId,
): boolean => {
    const board = getBoard(state, boardId);
    return board.id && board.driveId && board.driveId !== DRIVE_ID_MY_DRIVE && board.driveId !== DRIVE_ID_SHARED_WITH_ME
};
