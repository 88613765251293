import { IApplicationState } from '../../../../../../../types/types';
import { IGoogleDrive, TGoogleDriveId } from '../../types';
import { getDrives } from './getDrives';

export const getDrive = (
    state: IApplicationState,
    driveId: TGoogleDriveId
): IGoogleDrive => {
    const drive = getDrives(state).find((item) => {
        return item.id === driveId
    });
    return drive;
}
