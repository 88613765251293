import { AT_LIST_ACTION_SET } from './constants';
import { TListAction } from '../../list/actions/types';
import { IListActionSetAction } from './types';

export const listActionSet = (
    listId: number,
    listAction: TListAction
): IListActionSetAction => ({
    type: AT_LIST_ACTION_SET,
    listId,
    listAction,
});
