import { CLASS_ACTIVITY_ITEM } from '../../../../../boardDetails/activity/TabActivity/components/constants';
import * as React from 'react';
import { IRenameContent } from './types';
import { blockMarkdown } from '../../../../../../../../helper/markdownHelper';
import {
    CLASS_CARD_ACTIVITY_ITEM_ADD_VALUE,
    CLASS_CARD_ACTIVITY_ITEM_REMOVE_VALUE
} from '../../../../../boardDetails/activity/TabActivity/components/ActivityItemBody/constants';
import ReactHtmlParser from 'react-html-parser';

export function RenameContent({
    oldValue,
    newValue
}: IRenameContent) {
    return <>
        <div>
            <span className={CLASS_CARD_ACTIVITY_ITEM_REMOVE_VALUE + ' ' + CLASS_ACTIVITY_ITEM + '__checklist-name'}>{ReactHtmlParser(blockMarkdown(oldValue))}</span>
        </div>
        <div>
            <span className={CLASS_CARD_ACTIVITY_ITEM_ADD_VALUE + ' ' + CLASS_ACTIVITY_ITEM + '__checklist-name'}>{ReactHtmlParser(blockMarkdown(newValue))}</span>
        </div>
    </>
}
