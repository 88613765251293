import { IGetState, TNBDId } from '../../../../types/types';
import { INotificationBeforeDueDate } from '../../../../store/model/notificationBeforeDueDate/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IRestNotificationBeforeDueDate } from '../../../../types/rest/IRestNotificationBeforeDueDate';
import { notificationBeforeDueDateUpdate as notificationBeforeDueDateUpdateStoreAction } from '../../../../store/model/actionCreators/notificationBeforeDueDate/notificationBeforeDueDateUpdate';
import { patchRest } from './api/patchRest';
import { sendRealTimeStoreActionByCardId } from '../../../../view/react_components/base/helpers/realTimeHelperTS';

export const notificationBeforeDueDateUpdate = (
    notificationId: TNBDId,
    notification: INotificationBeforeDueDate
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(patchRest(notificationId, notification)).then((
            result: IRestNotificationBeforeDueDate
        ) => {
            dispatch(notificationBeforeDueDateUpdateStoreAction(result.cardId, result));

            dispatch(sendRealTimeStoreActionByCardId(result.cardId, notificationBeforeDueDateUpdateStoreAction(result.cardId, result)));
        });
    };
    return action;
};
