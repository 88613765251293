import { CSSProperties } from 'react';

export enum EProgressColor {
    purple = 'purple',
    yellow = 'yellow',
    red = 'red',
    green = 'green',
    grey = 'grey',
    orange = 'orange',
}

export type THeaderProgress = {
    title: string;
    tooltip: string;
    color: EProgressColor;
    number: number;
}

export interface IHeaderFields {
    isOpened: boolean;
    noImage?: boolean;
    photoUrl?: string;
    title?: string;
    progress: THeaderProgress[];
    cardsNumber: number;
    estimateStr: string;
    className?: string;
    icon?: string;
    isReadonly: boolean;
    actionsPortalId: string;
    initials?: string;
    titleStyle: CSSProperties;
    userId?: string;
}

export interface IHeaderEvents {
    onClickListSummaryHeader: (isOpen: boolean) => void;
    onAddCardToggle?: (visible: boolean) => void;
    onAddCard: (name: string) => void;
    onAddCardByEnter: (name: string, ctrl: boolean, shift: boolean) => void;
    onOpenAddCard: () => void;
}

export interface IHeaderProps extends
    IHeaderFields,
    IHeaderEvents
{}
