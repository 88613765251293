import { getCard } from './../../../../../../../store/model/selectors/getCard';
import { EMPTY_ARRAY, IApplicationState, TCardId } from '../../../../../../../types/types';
import { INotificationBeforeDueDate } from '../../../../../../../store/model/notificationBeforeDueDate/types';

export const getCardNotificationsBeforeDueDateSelector = (
    state: IApplicationState,
    cardId: TCardId,
): INotificationBeforeDueDate[] => {
    const card = getCard(state, cardId);
    if (
        !card ||
        !card.dueDate ||
        card.dueDate < Date.now() / 1000
    ) return EMPTY_ARRAY;

    return card.notificationBeforeDueDates || EMPTY_ARRAY;
};
