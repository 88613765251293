import { IApplicationState } from '../../../../types/types';
import { getAuthUser } from './getAuthUser';
import { EDefaultAttachmentType } from 'app/types/rest/IUserMeta';

export const getUserDefaultAttachmentType = (
    state: IApplicationState
): EDefaultAttachmentType => {
    const user = getAuthUser(state);
    return user.meta && user.meta.defaultAttachmentType || EDefaultAttachmentType.DEVICE;
};
