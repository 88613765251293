import { EQualificationAnswerType } from '../QualificationDialog/types';
import * as React from 'react';
import { QUALIFICATION_QUESTIONS } from '../../constants/questions';
import { Checkbox, IRadioActiveProps, Radio } from 'kui';
import { IQualificationDialogAnswersRadioProps, TAnswerCheckboxItems } from './types';
import { QualificationDialogAnswersText } from '../QualificationDialogAnswersText/QualificationDialogAnswersText';
import { QUALIFICATION_MORE_DETAILS_PROMPT } from '../../constants';

export function QualificationDialogAnswersRadio ({
    questionKey,
    onAnswer
}: IQualificationDialogAnswersRadioProps) {

    const [visibleFreeTextPanels, setVisibleFreeTextPanels] = React.useState([]);
    const [radioActiveIndex, setRadioActiveIndex] = React.useState(-1);
    const answerCheckboxItemsInit: TAnswerCheckboxItems = {};
    const [answerChekboxItems, setAnswerChekboxItems] = React.useState(answerCheckboxItemsInit);

    const onRadioChangeHandler = (
        e: IRadioActiveProps
    ) => {
        const question = QUALIFICATION_QUESTIONS[questionKey];
        const answerKeys = Object.keys(question.answers);
        const answerKey = answerKeys[e.index];
        if (question.answers[answerKey].type === EQualificationAnswerType.FREE_TEXT) {
            const list = [...visibleFreeTextPanels];
            const index = list.indexOf(answerKey);
            if (index >= 0) {
                list.splice(index, 1);
            } else {
                list.push(answerKey);
            }
            setVisibleFreeTextPanels(list);
        } else {
            onAnswer({key: answerKey, checkboxKeys: answerChekboxItems[answerKey]});
        }
        setRadioActiveIndex(e.index);
    }
    const onSaveText = (text: string, answerKey: string) => {
        onAnswer({key: answerKey, text});
    }
    const onCheckboxChangeHandler = (answerKey: string, checkboxItemKey: string) => {
        const items: string[] = answerChekboxItems[answerKey] ? [...answerChekboxItems[answerKey]] : [];
        const index = items.indexOf(checkboxItemKey);
        if (index >= 0) {
            items.splice(index, 1);
        } else {
            items.push(checkboxItemKey);
        }
        answerChekboxItems[answerKey] = items;
        setAnswerChekboxItems(answerChekboxItems);
    }

    const question = QUALIFICATION_QUESTIONS[questionKey];
    const answerKeys = Object.keys(question.answers);

    return (
        <Radio
            key={questionKey}
            active={radioActiveIndex}
            onChange={onRadioChangeHandler}
            direction={'left'}
        >
            {answerKeys.map(answerKey => {
                return <div key={questionKey + '-' + answerKey}>
                    <div>
                        {question.answers[answerKey].answer}
                    </div>
                    {question.answers[answerKey].type === EQualificationAnswerType.FREE_TEXT && visibleFreeTextPanels.includes(answerKey) &&
                        <QualificationDialogAnswersText
                            showSaveButton={true}
                            placeholder={QUALIFICATION_MORE_DETAILS_PROMPT}
                            onSave={(text) => onSaveText(text, answerKey)}
                        />
                    }
                    {question.answers[answerKey].checkboxItems &&
                    question.answers[answerKey].checkboxItems.length &&
                    question.answers[answerKey].checkboxItems.map((item) =>{
                        return <div key={item.key}>
                            <Checkbox
                                direction={'left'}
                                onChange={() => {onCheckboxChangeHandler(answerKey, item.key)}}
                            >
                                {item.title}
                            </Checkbox>
                        </div>
                    })
                    }
                </div>
            })}
        </Radio>
    );
}
