import * as React from 'react';
import { useEffect } from 'react';
import './_CardProperties.scss';
import { ICardPropertiesProps } from './types';
import { BOARD_DETAILS_ONLY_OWNER, CLASS_BOARD_DETAILS } from '../../../../constants';
import {
    BOARD_SECTION_CARD_PROPERTIES_TEXT,
    BOARD_SECTION_CARD_UNUSED_PROPERTIES_HIDE,
    BOARD_SECTION_CARD_UNUSED_PROPERTIES_SHOW
} from '../../constants';
import { CardPropertyItem } from '../CardPropertyItem/CardPropertyItem';
import { HideButton } from '../../../../../../base/components/HideButton/HideButton';
import { EAsidePanelProperty } from '../../../../../asidePanel/components/AsidePanel/types';
import ReactHtmlParser from 'react-html-parser';

export function CardProperties ({
    areAllDefault,
    cardProperties,
    isDisallow,
    isReadonly,
    onDefaultToggle,
    onRequiredToggle,
    onDisallowClick
}: ICardPropertiesProps) {
    const className = CLASS_BOARD_DETAILS + '__card-properties';
    const classText = className + '-text';

    const [isHide, setHide] = React.useState(true);
    const [elements, setElements] = React.useState(cardProperties);

    const onChange = (
        key: EAsidePanelProperty,
        onToggle: (key: EAsidePanelProperty, option?: string) => void,
        isDefault: boolean,
        isRequired?: boolean,
        option?: string
    ) => {
        const newElements = elements.map((element) => {
            if (element.options && option) {
                return {
                    ...element,
                    options: element.options.map((item) => {
                        return {
                            ...item,
                            isDefault: isDefault,
                            isRequired: isDefault ?
                                option === item.option ? isRequired : item.isRequired
                                : false
                        }
                    })
                }
            } else if (
                (
                    element.key === EAsidePanelProperty.CARD_DUE_DATE ||
                    element.key === EAsidePanelProperty.CARD_START_DATE
                ) &&
                (
                    key === EAsidePanelProperty.CARD_DUE_DATE ||
                    key === EAsidePanelProperty.CARD_START_DATE
                )
            ) {
                return {
                    ...element,
                    isDefault: isDefault,
                    isRequired: isDefault ?
                        key === element.key ? isRequired : element.isRequired
                        : false
                }
            } else {
                return {
                    ...element,
                    isDefault: key === element.key ? isDefault : element.isDefault,
                    isRequired: isRequired && key === element.key ?
                        isDefault ? isRequired : false
                        : element.isRequired
                }
            }
        });
        setElements(newElements);

        onToggle(key, option);
    }

    useEffect(() => {
        const newElements = cardProperties.filter((element) => {
            const { isDefault, options, isRequiredOnly } = element;
            return !isHide || (
                isDefault ||
                isRequiredOnly ||
                (options && options.length && options[0].isDefault)
            );
        });
        setElements(newElements);
    }, [isHide]);

    return (
        <div className={className}>
            <div className={classText}>
                <div>{ReactHtmlParser(BOARD_SECTION_CARD_PROPERTIES_TEXT)}</div>
                {isReadonly && <p className={classText}>{BOARD_DETAILS_ONLY_OWNER}</p>}
            </div>
            {
                elements.map((property) => {
                    const {
                        key,
                        name,
                        isRequired,
                        isDefaultOnly,
                        isDefault,
                        options,
                        isRequiredOnly
                    } = property;

                    return options ? options.map((item) => {
                            const { option, name, isRequired, isDefault } = item;

                            return (
                                <CardPropertyItem
                                    key={option}
                                    propertyKey={key}
                                    name={name}
                                    isReadonly={isReadonly}
                                    isRequired={isRequired}
                                    isDefault={isDefault}
                                    option={option}
                                    isDisallow={isDisallow}
                                    onDisallowClick={onDisallowClick}
                                    onDefaultToggle={() => onChange(key, onDefaultToggle, !isDefault, isRequired, option)}
                                    onRequiredToggle={() => onChange(key, onRequiredToggle, true, !isRequired, option)}
                                />
                            );
                        })
                        : <CardPropertyItem
                            key={key}
                            propertyKey={key}
                            name={name}
                            isReadonly={isReadonly}
                            isRequired={isRequired}
                            isDefault={isDefault}
                            isDefaultOnly={isDefaultOnly}
                            isDisallow={isDisallow}
                            onDisallowClick={onDisallowClick}
                            onDefaultToggle={() => onChange(key, onDefaultToggle, !isDefault, isRequired)}
                            onRequiredToggle={() => onChange(key, onRequiredToggle, true, !isRequired)}
                            isRequiredOnly={isRequiredOnly}
                        />
                })
            }
            {!areAllDefault &&
                <HideButton
                    labels={[BOARD_SECTION_CARD_UNUSED_PROPERTIES_SHOW, BOARD_SECTION_CARD_UNUSED_PROPERTIES_HIDE]}
                    isHide={isHide}
                    onClick={() => setHide(!isHide)}
                />
            }
        </div>
    );
};
