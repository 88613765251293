import * as React from 'react';
import './_CardAttachmentGoogleFiles.scss';
import { Divider, Icon, SelectList, SelectListItem, ButtonDropdown, Button } from 'kui';
import { EDefaultAttachmentType } from 'app/types/rest/IUserMeta';
import {
    ATTACH_FILE_FROM, CARD_ATTACHMENT_GOOGLE_FILES_TOOLTIP, CREATE_AND_ATTACH, EFileType, GOOGLE_DOCS,
    GOOGLE_DRIVE, GOOGLE_FORMS, GOOGLE_SHEETS, GOOGLE_SLIDES, YOUR_DEVICE
} from 'app/view/react_components/aside_panel/cardDetails/AttachmentsSection/components/CardAttachmentGoogleFiles/constants';
import { CLASS_CARD_DETAILS } from 'app/view/react_components/aside_panel/cardDetails';
import { ICardAttachmentGoogleFilesProps } from 'app/view/react_components/aside_panel/cardDetails/AttachmentsSection/components/CardAttachmentGoogleFiles/types';
import { getAsidePanelTooltip } from 'app/view/react_components/aside_panel/asidePanel/helpers/getAsidePanelTooltip';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { EAsidePanelProperty } from '../../../../asidePanel/components/AsidePanel/types';

export function CardAttachmentGoogleFiles ({
    isOnlyCreate,
    onAttachFileType,
    onCreate,
    onChange,
    onSelect
    }: ICardAttachmentGoogleFilesProps) {
    const { addShowedProperty } = React.useContext(AsidePanelContext);
    const className = CLASS_CARD_DETAILS + '-attachment__google-files';
    const classNameButtonDropdown = className + '--dropdown';
    const classNameDisabledItem = 'kui-select-list__item--disabled';
    const classNameActionButton = className + '--action-button'

    return (
        <>
            {isOnlyCreate ?
                // <ButtonDropdown
                //     directionVertical={'auto'}
                //     directionHorizontal={'right'}
                //     dropdownClassName={classNameButtonDropdown}
                // >
                //     <Button
                //         className={classNameActionButton}
                //         variant={'icon'}
                //         tooltip={getAsidePanelTooltip({
                //             value: CARD_ATTACHMENT_GOOGLE_FILES_TOOLTIP
                //         })}
                //     >
                //         <Icon
                //             size={24}
                //             xlink={'document-shared'}
                //         />
                //     </Button>
                    <SelectList fixActive={false}>
                        <SelectListItem
                            icon={'google-docs'}
                            onClick={() => {
                                onCreate(EFileType.DOCS);
                                onSelect();
                            }}
                            iconSize={24}
                        >
                            {GOOGLE_DOCS}
                        </SelectListItem>
                        <SelectListItem
                            icon={'google-sheets'}
                            onClick={() => {
                                onCreate(EFileType.SHEETS);
                                onSelect();
                            }}
                            iconSize={24}
                        >
                            {GOOGLE_SHEETS}
                        </SelectListItem>
                        <SelectListItem
                            icon={'google-slides'}
                            onClick={() => {
                                onCreate(EFileType.SLIDES);
                                onSelect();
                            }}
                            iconSize={24}
                        >
                            {GOOGLE_SLIDES}
                        </SelectListItem>
                        <SelectListItem
                            icon={'google-forms'}
                            onClick={() => {
                                onCreate(EFileType.FORMS);
                                onSelect();
                            }}
                            iconSize={24}
                        >
                            {GOOGLE_FORMS}
                        </SelectListItem>
                    </SelectList>
                // </ButtonDropdown>
                :
                <SelectList onChange={onChange}>
                    <SelectListItem
                        className={classNameDisabledItem}
                    >
                        {ATTACH_FILE_FROM}
                    </SelectListItem>
                    <SelectListItem
                        icon={'drive'}
                        onClick={() => onAttachFileType(EDefaultAttachmentType.DRIVE)}
                        iconSize={24}
                    >
                        {GOOGLE_DRIVE}
                    </SelectListItem>
                    <SelectListItem
                        icon={'cloud-upload'}
                        onClick={() => onAttachFileType(EDefaultAttachmentType.DEVICE)}
                        iconSize={24}
                    >
                        {YOUR_DEVICE}
                    </SelectListItem>

                    <Divider />

                    <SelectListItem
                        className={classNameDisabledItem}
                    >
                        {CREATE_AND_ATTACH}
                    </SelectListItem>
                    <SelectListItem
                        icon={'google-docs'}
                        onClick={() => {
                            onCreate(EFileType.DOCS);
                            addShowedProperty(EAsidePanelProperty.CARD_ATTACHMENTS);
                        }}
                        iconSize={24}
                    >
                        {GOOGLE_DOCS}
                    </SelectListItem>
                    <SelectListItem
                        icon={'google-sheets'}
                        onClick={() => {
                            onCreate(EFileType.SHEETS);
                            addShowedProperty(EAsidePanelProperty.CARD_ATTACHMENTS);
                        }}
                        iconSize={24}
                    >
                        {GOOGLE_SHEETS}
                    </SelectListItem>
                    <SelectListItem
                        icon={'google-slides'}
                        onClick={() => {
                            onCreate(EFileType.SLIDES);
                            addShowedProperty(EAsidePanelProperty.CARD_ATTACHMENTS);
                        }}
                        iconSize={24}
                    >
                        {GOOGLE_SLIDES}
                    </SelectListItem>
                    <SelectListItem
                        icon={'google-forms'}
                        onClick={() => {
                            onCreate(EFileType.FORMS);
                            addShowedProperty(EAsidePanelProperty.CARD_ATTACHMENTS);
                        }}
                        iconSize={24}
                    >
                        {GOOGLE_FORMS}
                    </SelectListItem>
                </SelectList>}
        </>
    )
}
