import * as React from 'react';
import { IRestListCommonActivity } from '../../../../../../../../types/rest/activity/IRestListCommonActivity';
import { ERestActivityPropertyActionType } from '../../../../../../../../types/rest/activity/IRestActivityBase';
import { TabActivityContext } from '../TabActivity/constants';
import { IActivityProps } from '../../types';
import { TActivity } from '../../../../../../../../store/activities/types';
import { ActivityHeader } from '../ActivityHeader/ActivityHeader';
import { ActivityItemBody } from '../ActivityItemBody/ActivityItemBody';
import { ActivityItemName } from '../ActivityItemName/ActivityItemName';
import { blockMarkdown } from '../../../../../../../../helper/markdownHelper';
import { msFileThumbnailStorage } from '../../../../../../../../helper/authorisation/microsoft/storage/msThumbnail';

export function ListActivityDescription({
    activity
}: IActivityProps<IRestListCommonActivity>) {
    const { onOpenList } = React.useContext(TabActivityContext);
    const { label, list } = activity as TActivity;

    const [markdown, setMarkdown] = React.useState('');

    React.useEffect(() => {
        let name = activity.propertyActionType === ERestActivityPropertyActionType.DELETE
            ? activity.oldValue
            : activity.newValue;

        setMarkdown(blockMarkdown(name));

        if (msFileThumbnailStorage.is()) {
            if (name) {
                msFileThumbnailStorage.getSrcThumbnails(name)
                    .then((text) => {
                        setMarkdown(blockMarkdown(text));
                    })
                    .catch(() => {});
            }
        }
    }, [activity]);

    return (
        <ActivityItemBody
            header={<ActivityHeader icon={'description'} label={label} />}
            activity={activity}
            onClick={() => onOpenList(list.id)}
        >
            <ActivityItemName name={markdown} isMarkdown />
        </ActivityItemBody>
    );
};
