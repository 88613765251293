import { IApplicationState } from '../../../../../../../../types/types';
import { TopBarSharing } from '../../../../components/TopBarSharing/TopBarSharing';
import { ITopBarSharingEvents, ITopBarSharingFields } from '../../../../components/TopBarSharing/types';
import { connect } from 'react-redux';
import { getActiveBoardSharingType } from '../../../../../../../../store/model/selectors/getActiveBoardSharingType';
import { ITopBarSharingHOCProps } from './types';
import { onClick } from './events/onClick';

const mapStateToProps = (
    state: IApplicationState,
    props: ITopBarSharingHOCProps,
): ITopBarSharingFields => {
    const { boardId } = props;
    return {
        sharingType: getActiveBoardSharingType(state),
    };
};

const mapDispatchToProps = (
    dispatch: any,
    props: ITopBarSharingHOCProps,
): ITopBarSharingEvents => {
    const { boardId } = props;
    return {
        onClick: () => dispatch(onClick(boardId)),
    }
};

export const TopBarSharingHOC = connect(mapStateToProps, mapDispatchToProps)(TopBarSharing);
TopBarSharingHOC.displayName = 'TopBarSharingHOC';
