import Util from '../../util/util';
import { fetchHandler } from '../../util/fetchHandler';
import { EBoardVersion, IRestDashboard } from '../../types/rest/IRestDashboard';
import { ListLoader } from './listLoader';
import { root } from '../../store/constants';
import { IRestList } from '../../types/rest/IRestList';
import {TColorTagColor, IColor, TStatus} from '../../types/model';
import { CardsLoader } from './cardsLoader';
import { setVersion } from '../../rest/effects/board/setVersion';
import { dispatch, getAppState } from '../../store/configureStore';
import { FREE_VERSION_CARDS_COUNT } from '../../store/model/constants';
import { SegmentUserTypeOption } from '../../middlewares/segment/trackEntities/userEvents';
import { showBoardLimitSnackbarAndEvent } from '../../store/model/effects/showBoardLimitSnackbarAndEvent';
import { PartUploader } from '../../util/part_uploader';
import { boardSetMeta } from '../../rest/effects/board/boardSetMeta';
import { SegmentBoardEvent, segmentTrackAction } from '../../middlewares/segment';
import { TCardId, TColorId, TListId } from '../../types/types';
import { migrateRest } from '../../rest/effects/role/api/migrateRest';
import { IRestCardAssigneeRole } from '../../types/rest/IRestCardAssigneeRole';
import { IList } from '../../store/model/list/types';
import { ORDER_STEP, PORTION_SIZE } from '../../const';
import { ICard } from '../../store/model/card/types';
import { ISharedUser } from '../../types/rest/ISharedUser';
import { cardsRestPatch } from '../../rest/effects/card/card/api/helper/cardsRestPatch';
import { ICards } from '../../store/model/cards/types';
import {
    getPermissionIdFromDashboardPermissionId
} from '../../store/model/user/helpers/getPermissionIdFromDashboardPermissionId';
import { IRestUser } from '../../types/rest/IRestUser';
import { boardUpdateMeta } from '../../rest/effects/board/boardUpdateMeta';
import {
    checkCardAssignInfoExistForBoard
} from '../../rest/effects/cardAssignees/api/checkCardAssignInfoExistForBoard';
import { ICheckExitsMyTasksForBoard } from '../../rest/effects/cardAssignees/api/types';
import { sendErrorEmail } from 'app/rest/effects/utils/sendErrorEmail';
import { IBoard } from 'app/store/model/board/types';
import { getRest } from 'app/rest/effects/card/card/api/getRest';
import { IRestCard } from 'app/types/rest/IRestCard';
import { NEW_COLORS, OLD_COLORS } from 'app/store/model/board/selectors/getBoardColors';
import { deleteRest } from '../../rest/effects/color/api/deleteRest';
import { patchRest } from '../../rest/effects/color/api/patchRest';
import { sendRealTimeStoreAction } from '../../view/react_components/base/helpers/realTimeHelperTS';
import { boardsActionSet } from '../../store/model/actions/boardsActionSet';
import { boardActionSet } from '../../store/model/boards/actions/boardActionSet';
import { deleteColorAction } from '../../store/model/board/actions/deleteColorAction';
import { updateColorAction } from '../../store/model/board/actions/updateColorAction';
import { addColorAction } from '../../store/model/board/actions/addColorAction';
import { boardColorPostRest } from '../../rest/effects/color/api/helpers/boardColorPostRest';
import {
    getColorTagColor
} from '../../view/react_components/aside_panel/boardDetails/details/Colors/hocs/BoardColorsHOC/helpers/getColorTagColor';
import {ColorTagsFixer} from 'app/helper/loader/ColorTagsFixer';
import {PermissionSynchronizer} from 'app/helper/PermissionSynchronizer';
import {getAuthUser} from 'app/store/model/authUser/selectors/getAuthUser';
import {EAuthUserPlatformType} from 'app/types/rest/IRestAuthUser';
import { patchRest as patchRestCard } from 'app/rest/effects/card/card/api/patchRest';

export const MAX_LIST_LOAD_AT_A_TIME = 10;

export class BoardLoader {
    boardId: number;
    showCardsLimitReachedSnackbar: boolean;

    constructor(
        boardId: number,
        showCardsLimitReachedSnackbar: boolean = true,
    ) {
        this.boardId = boardId;
        this.showCardsLimitReachedSnackbar = showCardsLimitReachedSnackbar;
    }

    load (
        url?: string,
        sendCardStat: boolean = true,
        noCards = false,
        noError = false,
        isNoProcess?: boolean, // just fetch board
    ): Promise<IRestDashboard> {
        return fetchHandler<IRestDashboard>(
            url? url : this.getUrl(),
            undefined,
            false,
            noError
        )
            .then(board => {
                const state = getAppState();
                if(getAuthUser(state) && getAuthUser(state).platformType === EAuthUserPlatformType.MICROSOFT) {
                    new PermissionSynchronizer(board).synchronize()
                }
                return board;
            })
            .then(
            (board) => {
                if (!board) {
                    throw new Error('Access denied');
                }

                if (isNoProcess) return board;

                if (!noCards) {
                    root.App.controller.boardLoaderInfo.boardName = board.name;
                    root.App.controller.boardLoaderInfo.cardsToLoadCount = board.meta && board.meta.cardsActiveCount || 0;
                }
                const state = getAppState();
                let version: EBoardVersion = board.version;
                let allowEdit: boolean = board.permissions && board.permissions.authPermissions && board.permissions.authPermissions.allowEdit;
                let migrateToV3 = (!version || version < EBoardVersion.V3) && allowEdit;
                const migrateToV8 = (!version || version < EBoardVersion.V8) && allowEdit
                const migrateToV9 = (!version || version < EBoardVersion.V9) && allowEdit;
                const migrateToV10 = (!version || version < EBoardVersion.V10) && allowEdit;

                if (board.users && board.users.length) {
                    board.users.forEach(user => {
                        if (user && !user.fullName) { // fix users without fullName
                            const names = [];
                            if (user.firstName) names.push(user.firstName);
                            if (user.lastName) names.push(user.lastName);
                            user.fullName = names.length ? names.join(' ') : 'Anonymous';
                        }
                    });
                }

                const listsToLoad = noCards && !migrateToV9 && !migrateToV10 ? [] : board.lists;

                const loadList = (list: IList) => {
                    if (list.status === TStatus.STATUS_SERVICE_TEMPLATE) {
                        return this.listLoadTemplateCards(list, version, migrateToV3)
                            .then((cards) => {
                                list.cards = [...list.cards, ...cards];
                            });
                    }
                    return this.listLoadActiveCards(list, version, migrateToV3)
                        .then((cards) => {
                            if (cards.length) {
                                const loadedCardsCount = root.App.controller.boardLoaderInfo.loadedCardsCount || 0;
                                root.App.controller.boardLoaderInfo.loadedCardsCount = loadedCardsCount + cards.length;
                            }
                            list.cards = [...list.cards, ...cards];
                            if (migrateToV3 || migrateToV8) {
                                return this.listLoadArchiveCards(list, version, migrateToV3)
                                    .then((cards) => {
                                        list.archiveLoaded = true;
                                        return cards;
                                    });
                            } else {
                                return this.listLoadArchiveSubcardsWithActiveEpic(list, version);
                            }
                        })
                        .then((cards) => {
                            list.cards = [...list.cards, ...cards];
                            if (sendCardStat) {
                                root.App.statHelper.sendCardStatNonBackboneModels(board, list, 0, 0, 90000);
                            }
                        });
                };

                const loadListsBlock = (items: IList[]) => {
                    return Promise.all(items.map(item => loadList(item)));
                };

                const loadLists = (
                    listsToLoad: IList[],
                    result: any = []
                ) => {
                    if (listsToLoad === undefined || listsToLoad.length === 0) {
                        return Promise.resolve(result);
                    }
                    return new Promise((resolve) => {
                        const remainingLists = [...listsToLoad];
                        const listBlock = remainingLists.splice(0, MAX_LIST_LOAD_AT_A_TIME);
                        loadListsBlock(listBlock).then((res) => {
                            resolve(loadLists(remainingLists, [...result, ...res]));
                        });
                    });
                };

                return loadLists(listsToLoad)
                    .then(() => {
                        return this.loadLeaseEpics(board).then(() => {
                            return board;
                        });
                    })
                    .then((board) => {
                            if (migrateToV3) {
                                dispatch(setVersion(EBoardVersion.V3, board.id));
                                board.version = EBoardVersion.V3;
                            }
                            root.App.controller.mainView.doAfterLoader(() => {
                                if (this.showCardsLimitReachedSnackbar) {
                                    this.showBoardCardsLimitReachedSnackbar(board);
                                }
                            })
                            return board;
                        }
                    )
                    .then((board) => { // migrate folded lists from localStorage, can be removed later
                        if (!board.userMeta || !board.userMeta.foldedLists) {
                            const localStorageData = localStorage.getItem('FOLDED_LISTS_LOCALSTORAGE');
                            const data: TListId[] = localStorageData ? JSON.parse(localStorageData) : [];
                            const foldedLists: TListId[] = [];
                            board.lists.forEach((list) => {
                                if (data.includes(list.id)) {
                                    foldedLists.push(list.id);
                                }
                            });
                            board.userMeta = board.userMeta || {};
                            board.userMeta.foldedLists = foldedLists;
                            dispatch(boardSetMeta(board.id, { foldedLists }));
                        }
                        return board;
                    })
                    .then((board) => {
                        if (allowEdit && (!board.cardAssigneeRoles || !board.cardAssigneeRoles.length)) {
                            return dispatch(migrateRest(board.id))
                                .then((cardAssigneeRoles: IRestCardAssigneeRole[]) => {
                                    board.cardAssigneeRoles = cardAssigneeRoles;
                                    return board;
                                })
                        }
                        return board;
                    })
                    .then((board) => {
                        if (board.meta && board.meta.cardProperties) {
                            dispatch(segmentTrackAction(SegmentBoardEvent.CARD_DEFAULT_PROPERTIES_COUNT, {
                                name: 'count',
                                value: board.meta.cardProperties.length,
                            }));
                        }
                        return board;
                    })
                    .then((board) => {
                        if(allowEdit && board.version < EBoardVersion.V6) {
                            return this.migrateCards(board, false)
                                .then(() => {
                                    board.version = EBoardVersion.V6;
                                    return dispatch(setVersion(EBoardVersion.V6, board.id)).then(() => board);
                                })
                        } else {
                            return board;
                        }
                    })
                    .then((board: IRestDashboard) => {
                        if(allowEdit) {
                            board.meta = board.meta || {};
                            if(board.version < EBoardVersion.V7) {
                                return dispatch(checkCardAssignInfoExistForBoard(board.id)).then((result: ICheckExitsMyTasksForBoard) => {
                                    if (!result.isCardAssigneesInfoExists) {
                                        return this.createMyTasks(board).then(() => {
                                            return dispatch(boardUpdateMeta(board.id, {isMyTasksDone: true}))
                                                .then(() => {
                                                    board.version = EBoardVersion.V7;
                                                    return dispatch(setVersion(EBoardVersion.V7, board.id)).then(() => board);
                                                })
                                        });
                                    } else {
                                        board.meta.isMyTasksDone = true;
                                        return dispatch(boardUpdateMeta(board.id, {isMyTasksDone: true}))
                                            .then(() => {
                                                board.version = EBoardVersion.V7;
                                                return dispatch(setVersion(EBoardVersion.V7, board.id)).then(() => board);
                                            })
                                    }
                                });
                            } else {
                                if (!board.meta.isMyTasksDone) {
                                    return this.createMyTasks(board).then(() => {
                                        return dispatch(boardUpdateMeta(board.id, {isMyTasksDone: true}))
                                            .then(() => board);
                                    });
                                } else {
                                    return board;
                                }
                            }
                        } else {
                            return board;
                        }
                    })
                    .then((board) => {
                        if(allowEdit && migrateToV8) {
                            return this.migrateCards(board, false)
                                .then(() => {
                                    board.version = EBoardVersion.V8;
                                    return dispatch(setVersion(EBoardVersion.V8, board.id)).then(() => board);
                                })
                        } else {
                            return board;
                        }
                    })
                    .then((board) => {
                        if (migrateToV9) {
                            return root.App.controller.loadBoardArchiveToStore().then(() => {
                                return this.migrateColors(board).then((board: IBoard) => {
                                    return dispatch(setVersion(EBoardVersion.V10, board.id)).then(() => board); //здесь перепрыгиваем через версию, потому что нам больше не нужно фиксить KNB-3366
                                });
                            });
                        } else {
                            return board;
                        }
                    })
                    .then((board) => {
                        if (migrateToV10) {
                            return dispatch(setVersion(EBoardVersion.V10, board.id)).then(() => board); //здесь перепрыгиваем через версию, потому что нам больше не нужно фиксить KNB-3366
                        } else {
                            return board;
                        }
                    })
                    .then(() => {
                        return this.migrateCards(board, true)
                    })
                    .then(() => {
                        return this.fixEpicToEpic(board);
                    })
                    .catch((e) => {
                        dispatch(sendErrorEmail(1, 'Migration error: ' + e + '; \n\n' + e.stack + ';\n\n ' + 'boardId: ' + board.id + ' version: ' + board.version))
                        return board;
                    })
            }
        );
    }

    createMyTasks(board: IRestDashboard) {
        const cardIds: TCardId[] = [];
        board.lists.forEach((list) => {
            cardIds.push(...list.cards.map(card => card.id));
        });
        const uploader = new PartUploader<TCardId>('/rest/dashboards/' + board.id + '/lists/cards/assignees/migrate-v2', cardIds, PORTION_SIZE);
        return uploader.uploadPart().then(() => {
            return dispatch(boardUpdateMeta(board.id, {isMyTasksDone: false})).then(() => board);
        });
    }

    fixEpicToEpic(board: IRestDashboard) {
        board.lists.forEach((list) => {
            list.cards.map(card => {
                if (card.epicId === card.id) {
                    delete card.epicId;
                    dispatch(patchRestCard(card.id, {
                        epicId: null
                    }));
                }
            });
        });
        return board;
    }

    listLoadActiveCards(list: IRestList, version: EBoardVersion, migrate: boolean) {
        if (list.status === TStatus.STATUS_ARCHIVE || list.status === TStatus.STATUS_DELETED){
            return Promise.resolve([] as IRestCard[]);
        }
        let listLoader = new ListLoader(list.id, version, undefined, undefined, migrate);
        return listLoader.load();
    }

    /**
     * KNB-3365 fix у листа статус 2, а у части карт в нём статус 0
     */
    listArchiveLoadActiveCards(list: IRestList, version: EBoardVersion, migrate: boolean = null) {
        if (list.status !== TStatus.STATUS_ARCHIVE){
            return Promise.resolve([]);
        }
        let listLoader = new ListLoader(list.id, version, undefined, undefined, migrate);
        return listLoader.load();
    }

    listLoadTemplateCards(list: IRestList, version: EBoardVersion, migrate: boolean) {
        const listLoader = new ListLoader(list.id, version, TStatus.STATUS_SERVICE_TEMPLATE, false, migrate);
        return listLoader.load();
    }

    listLoadArchiveCards(list: IRestList, version: EBoardVersion, migrate: boolean = false) {
        let listLoader = new ListLoader(
            list.id,
            version,
            TStatus.STATUS_ARCHIVE,
            false,
            migrate
        );
        return listLoader.load();
    }

    listLoadArchiveSubcardsWithActiveEpic(list: IRestList, version: EBoardVersion) {
        let listLoader = new ListLoader(
            list.id,
            version,
            TStatus.STATUS_ARCHIVE,
            true
        );
        return listLoader.load();
    }

    getIdsLeaseEpics(board: IRestDashboard) {
        let idsForLoad: number[] = [];
        let cardIds: Array<number> = [];
        board.lists.map((list) => {
            list.cards.map((card) => {
                cardIds.push(card.id);
            });
        });
        board.lists.map((list) => {
            list.cards.map((card) => {
                if (card.epicId && !cardIds.includes(card.epicId)) {
                    idsForLoad.push(card.epicId);
                }
            });
        });
        return idsForLoad;
    }

    getBoardCardsCount(board: IRestDashboard) {
        let count = 0;
        board.lists.map((list) => {
            list.cards.map((card) => {
                if (card.status === TStatus.STATUS_ACTIVE) {
                    count++;
                }
            });
        });
        return count;
    }

    isBoardCardsLimitReached(board: IRestDashboard) {
        const boardPermissions = board.permissions;
        const allowEdit =
            boardPermissions &&
            boardPermissions.authPermissions &&
            boardPermissions.authPermissions.allowEdit;
        if (!allowEdit) {
            return false;
        }

        const allowUnlimitedCards = boardPermissions.authPermissions.allowUnlimitedCards;
        let boardCount = this.getBoardCardsCount(board);
        const result = !allowUnlimitedCards && boardCount > FREE_VERSION_CARDS_COUNT;
        return result;
    }

    showBoardCardsLimitReachedSnackbar(board: IRestDashboard) {
        if (this.isBoardCardsLimitReached(board)) {
            dispatch(showBoardLimitSnackbarAndEvent(SegmentUserTypeOption.BOARD_OPENING));
        }
    }

    loadLeaseEpics(board: IRestDashboard) {
        let idsForLoad = this.getIdsLeaseEpics(board);
        return new CardsLoader(idsForLoad).load().then((cards) => {
            let listMap: {
                [listId: number]: IRestList
            } = {};
            board.lists.map((list) => {
                listMap[list.id] = list;
            });

            cards.map((card) => {
                let list = listMap[card.listId];
                if (list) {
                    list.cards.push(card);
                } else {
                    console.log(`list with id:${card.listId} not found`);
                    //throw  new Error(`list with id:${card.listId} not found`)
                }
            });
        });
    }

    getUrl(): string {
        return Util.getApiUrl('/rest/dashboards/' + this.boardId);
    }

    migrateCardAssignees(card: ICard, board: IBoard): ICard {
        if (!card.assignees) {
            if (card.assignedToDetails && card.assignedToDetails.length > 0) { // если есть старые асайнеры и нет новых асайнеров
                // парвим чтоб в сторе были нормальыне данные
                card.assignees = card.assignedToDetails
                    .filter(assignedToDetails => !!assignedToDetails)
                    .map((assignedToDetails: IRestUser) => {
                        const boardSharedUser = board.users.find((boardUser: ISharedUser) => boardUser.permissionId === getPermissionIdFromDashboardPermissionId(assignedToDetails.dashboardPermissionId));
                        const fullNameSplitted = assignedToDetails.fullName.split(' ');
                        const sharedUser: ISharedUser = {
                            permissionId: getPermissionIdFromDashboardPermissionId(assignedToDetails.dashboardPermissionId),
                            fullName: assignedToDetails.fullName,
                            photoUrl: assignedToDetails.photoUrl,
                            role: boardSharedUser && boardSharedUser.role,
                            firstName: assignedToDetails.fullName.split(' ')[0],
                            lastName: fullNameSplitted.length > 0 ? fullNameSplitted[1] : '',
                        };
                        const assignee = {
                            sharedUser,
                            roleIds: assignedToDetails.cardAssigneeRoleIds
                        };
                        return assignee;
                    });
                return {
                    assignees: card.assignees,
                    assignedToDetails: card.assignedToDetails
                }
            } else {
                return {
                    assignees: [],
                    assignedToDetails: null
                }
            }
        }
        return null;
    }

    migrateColors (board: IBoard) {
        let colors = [...board.colors];
        let orderNumber = 0;
        const usedColorIds = board.lists.reduce((cardColors: {[key: number]: { colorId: TColorId, isArchiveOnly: boolean }}, list: IRestList) => {
            list.cards.forEach((card: ICard) => {
                if (card.colorIds && card.colorIds.length) {
                    card.colorIds.forEach(colorId => {
                        if (!cardColors[colorId]) {
                            cardColors[colorId] = {
                                colorId,
                                isArchiveOnly: card.status === TStatus.STATUS_ARCHIVE
                            }
                        } else if (cardColors[colorId].isArchiveOnly && card.status !== TStatus.STATUS_ARCHIVE){
                            cardColors[colorId].isArchiveOnly = false;
                        }
                    });
                }
            });
            return cardColors;
        }, {});
        const boardCardColors = colors.reduce((newColors: IColor[], colorTag: IColor) => {
            if (usedColorIds[colorTag.id]) {
                const isArchiveOnly = usedColorIds[colorTag.id].isArchiveOnly;
                newColors.push({
                    ...colorTag,
                    color: isArchiveOnly ? getColorTagColor(colorTag.color) : colorTag.color,
                    status: isArchiveOnly ? TStatus.STATUS_ARCHIVE : TStatus.STATUS_ACTIVE
                });
            }
            return newColors;
        }, []);

        const promises: Promise<any>[] = [];
        colors = colors.reduce((newColors, colorTag) => {
            const { id, color } = colorTag;
            if (boardCardColors.find((color: IColor) => color.id === id)) {
                const newColor = { ...colorTag, orderNumber };
                orderNumber += ORDER_STEP;
                newColors.push(newColor);
                promises.push(
                    dispatch(patchRest(id, newColor)).then(() => {
                        const action = boardsActionSet(boardActionSet(board.id, updateColorAction(id, newColor)));
                        sendRealTimeStoreAction(board.id, board.cometToken, action);
                    })
                );
            } else if (OLD_COLORS.includes(color)) {
                // delete old unused colors
                promises.push(
                    dispatch(deleteRest(id)).then(() => {
                        const action = boardsActionSet(boardActionSet(board.id, deleteColorAction(id)));
                        sendRealTimeStoreAction(board.id, board.cometToken, action);
                    })
                );
            }
            return newColors;
        }, []);
        const isHidden = !!colors.length;
        NEW_COLORS.forEach(({ color, name }) => {
            promises.push(
                dispatch(boardColorPostRest({
                    color,
                    name,
                    dashboardId: board.id,
                    orderNumber,
                    status: isHidden ? TStatus.STATUS_DELETED : TStatus.STATUS_ACTIVE
                }, false, false)) // без риалтайма и без пачта стора
                .then((color: IColor) => {
                    if(color) {
                        colors.push(color);
                        const action = boardsActionSet(boardActionSet(board.id, addColorAction(color)));
                        sendRealTimeStoreAction(board.id, board.cometToken, action);
                    }
                })
            );
            orderNumber += ORDER_STEP;
        });
        return Promise.all(promises)
            .then(() => {
                board.colors = colors;
                return board;
            });
    }

    migrateCards (board: IBoard, fixMigrate: boolean = false) {
        let cardsForMigrate: ICards = {};
        let cardsForFixShortModel: ICards = {};
        board.lists.forEach((list: IList) => {
            list.cards.forEach((card: ICard) => {
                let needAddToPatch = false;
                let needFixShortModel = false;
                if(fixMigrate) {
                    if (card.assignedToDetails && !card.assignees) {
                        needAddToPatch = true;
                    } else if(!card.assignedToDetails && !card.assignees) {
                        needFixShortModel = true;
                    }
                } else {
                    needAddToPatch = true;
                }
                let migratedCard = this.migrateCardAssignees(card, board)
                if (needAddToPatch) {
                    if(migratedCard) {
                        cardsForMigrate[card.id] = migratedCard;
                    }
                } else if(needFixShortModel) {
                    cardsForFixShortModel[card.id] = card
                }
            })
        })
        return dispatch(cardsRestPatch(cardsForMigrate, undefined, undefined, undefined, true))
            .then(() => {
                return this.fixCardsShortModel(board, cardsForFixShortModel)
            }).then(() => {
                return board;
            })
    }

    fixCardsShortModel (board: IBoard, cardsForFix: ICards) {
        return this.loadCards(cardsForFix).then((cardResponses: Array<IRestCard>) => {
            board.lists.forEach(list => {
                list.cards = list.cards.map(card => {
                    const fixedCard = cardResponses.find(cardResponse => cardResponse.id === card.id);
                    if (fixedCard) {
                        return fixedCard;
                    } else {
                        return card;
                    }
                })
            })
            const fixedCards: ICards = {}
            cardResponses.forEach((cardResponse) => {
                fixedCards[cardResponse.id] = {
                    assignees: cardResponse.assignees
                };
            })
            return dispatch(cardsRestPatch(fixedCards, undefined, undefined, undefined, true))
        })
    }

    loadCards (cards: ICards) {
        const cardGetPromises: Promise<any>[] = [];
        for (let cardId in cards) {
            cardGetPromises.push(dispatch(getRest(Number(cardId))));
        }

        return Promise.all(cardGetPromises)
    }
}
