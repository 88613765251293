import * as React from 'react';
import './_PushToGoogleCalendar.scss';
import { IPushToGoogleCalendarProps } from './types';
import { ButtonDropdown, ButtonTitle, Icon } from 'kui';
import { CLASS_CARD_DETAILS } from '../../../constants';
import {
    CLASS_ASIDE_PANEL_ACTIONS_POPUP,
    ID_ASIDE_PANEL_ACTIONS_PORTAL_MOBILE
} from '../../../../asidePanel/components/AsidePanelActionsButton/constants';
import GoogleCalendarMoveTo from '../../../TimingSection/pushToGoogleCalendar/hocs/googleCalendarMoveToHoc';
import { CardDatesButton } from '../CardDatesButton/CardDatesButton';
import { CARD_DATES_BUTTON_CALENDAR } from './constants';
import { useEffect } from 'react';
import { RIGHT_ASIDE_PANEL_SELECTOR } from 'app/view/react_components/aside_panel/asidePanel/constants';

export function PushToGoogleCalendar({
    isShowTitle,
    onOpen,
    onDidMount,
    isDatePushed,
    isReadonly,
}: IPushToGoogleCalendarProps) {
    const CLASS_CARD_DATES = CLASS_CARD_DETAILS + '-dates';
    const classNamePush = CLASS_CARD_DATES + '__push-to-google-calendar';
    const classNamePushButton = CLASS_CARD_DATES + '__push-button';
    const classPushDropdownName = 'aside-panel-actions-popup--google-push';
    const classNameIcon = classNamePush + '-icon';
    const classNameIconPushed = classNameIcon + (isDatePushed ? '--pushed' : '');

    let [portal, setPortal] = React.useState(null);
    useEffect(() => {
        if (onDidMount) onDidMount();

        requestAnimationFrame(() => {
            setPortal({
                portal: true,
                portalId: ID_ASIDE_PANEL_ACTIONS_PORTAL_MOBILE,
                portalSelector: RIGHT_ASIDE_PANEL_SELECTOR,
            });
        });
    }, []);

    return (portal &&
        <ButtonDropdown
            className={classNamePush}
            directionHorizontal={'right'}
            dropdownClassName={classPushDropdownName}
            notBlurClasses={[CLASS_ASIDE_PANEL_ACTIONS_POPUP]}
            disabled={isReadonly}
            onOpen={onOpen}
            {...portal}
        >
            <CardDatesButton
                className={classNamePushButton}
                variant={!isShowTitle ? 'icon' : null}
            >
                {isShowTitle &&
                    <ButtonTitle>
                        {CARD_DATES_BUTTON_CALENDAR}
                    </ButtonTitle>
                }
                <Icon xlink={'push-calendar'} size={24} className = {`${classNameIcon}  ${classNameIconPushed}`}/>
            </CardDatesButton>
            <GoogleCalendarMoveTo />
        </ButtonDropdown>
    );
}
