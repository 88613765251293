import * as React from 'react';
import './_Color.scss';
import { IColorProps } from './types';
import { CLASS_COLOR_PALETTE } from '../constants';
import { CARD_BACKGROUND_COLORS_PICKER_MATCH } from '../../../../../../const';
import { EPaletteVariant } from '../types';

export const Color = ({
    color,
    isAutoFocus,
    isSelected,
    variant,
    onSelect
}: IColorProps) => {
    const className = CLASS_COLOR_PALETTE + '-color';

    const ref = React.useRef(null);

    const onEnter = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            onSelect();
        }
    };

    React.useEffect(() => {
        if (!isAutoFocus || !isSelected) return;
        const el = ref.current as HTMLElement;
        if (el) {
            el.focus();
        }
    }, []);

    return (
        <div
            ref={ref}
            tabIndex={0}
            className={`
                ${className}
                ${variant === EPaletteVariant.TAG_COLOR || variant === EPaletteVariant.TAG_COLOR_KANBAN_CARD ? className + '--' + color : ''}
                ${variant === EPaletteVariant.CARD_COLOR && !color ? className + '--empty' : ''}
                ${isSelected ? className + '--selected' : ''}
            `}
            style={{
                backgroundColor: variant === EPaletteVariant.CARD_COLOR ? CARD_BACKGROUND_COLORS_PICKER_MATCH[color] || color : color
            }}
            onClick={onSelect}
            onKeyDown={onEnter}
        />
    );
}
