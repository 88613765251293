import { AsidePanelActionsButton } from '../../../..';
import * as React from 'react';
import { IDependenciesPanelButton } from './types';
import { CARD_SECTION_DEPENDENCIES } from '../../constans';
import { CLASS_CARD_DETAILS_SECTION } from '../../../constants';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { DependenciesSectionHOC } from '../../hocs/DependenciesSectionHOC/DependenciesSectionHOC';
import { getPopupAttributes } from '../../../../../base/components/SettingsSection/constants';
import { EAsidePanelProperty } from '../../../../asidePanel/components/AsidePanel/types';
import { CLASS_ASIDE_PANEL_ACTIONS_POPUP } from '../../../../asidePanel/components/AsidePanelActionsButton/constants';
import { CLASS_ASIDE_PANEL_BODY } from '../../../../asidePanel/components/AsidePanelBody/constants';
import { getAsidePanelActionsButtonTooltip } from 'app/view/react_components/aside_panel/asidePanel/helpers/getAsidePanelActionsButtonTooltip';

export function DependenciesPanelButton({
    dependenciesCount,
    isBasicFeature,
    isDisallow,
    isProFeature,
    isShow,
    tooltip,
    onClick,
}: IDependenciesPanelButton) {
    if (!isShow) return null;

    const { boardId, cardId, setIsScrollDisabled, showedProperties } = React.useContext(AsidePanelContext);
    const isShowed = showedProperties.has(EAsidePanelProperty.CARD_DEPENDENCIES);
    const [isOpened, setIsOpened] = React.useState(null);
    const timer = React.useRef(null);
    const onOpened = () => {
        setIsOpened(true);
        timer.current = setTimeout(checkScroll, 1000);
    }
    const onClosed = () => setIsOpened(false);

    /**
     * Попапы обычно вписаны в экран и скрол панели отключается, чтобы ничего не скакало при добавлении-удалении свойств.
     * Но у Dependencies нестандартный попап, в нём должны поместиться все 100500 predecessors.
     * SearchSelect наезжает на 24px на дропдаун, который рисуется посередине, отсуда берется /2 + 24px
     * Если попап не помещается, включить обратно скрол панели.
     */
    function checkScroll() {
        const body = document.querySelector(`.${CLASS_ASIDE_PANEL_BODY}`) as HTMLElement;
        const dropdown = document.querySelector(`.${CLASS_ASIDE_PANEL_ACTIONS_POPUP}--dependencies > .kui-dropdown__item`) as HTMLElement;
        const cardsSelect = document.querySelector(`.${CLASS_ASIDE_PANEL_ACTIONS_POPUP}--dependencies > .kui-dropdown__item .search-select__dropdown .kui-dropdown__item`) as HTMLElement;
        if (!body || !dropdown) return;
        setIsScrollDisabled(dropdown.offsetHeight + cardsSelect.offsetHeight < body.offsetHeight / 2 + 24);
    }

    React.useEffect(() => {
        return () => {
            if (timer.current) clearTimeout(timer.current);
        }
    }, []);

    return (
        <AsidePanelActionsButton
            dropdownClassName={`${CLASS_ASIDE_PANEL_ACTIONS_POPUP}--dependencies`}
            indicatorNumber={dependenciesCount}
            icon={'predecessor'}
            isDisabled={isDisallow}
            isIconHiddenOnFullSize={true}
            isOpened={isOpened}
            openedProperty={EAsidePanelProperty.CARD_DEPENDENCIES}
            sectionSelector={`.${CLASS_CARD_DETAILS_SECTION}--dependencies`}
            title={CARD_SECTION_DEPENDENCIES}
            tooltip={tooltip && getAsidePanelActionsButtonTooltip({ value: tooltip })}
            onClick={onClick}
            onOpen={onOpened}
            isProFeature={isProFeature}
            isBasicFeature={isBasicFeature}
        >
            {!isDisallow && !isShowed &&
                <DependenciesSectionHOC
                    boardId={boardId}
                    cardId={cardId}
                    onClose={onClosed}
                    {...getPopupAttributes()}
                />
            }
        </AsidePanelActionsButton>
    );
}
