import { extend } from 'underscore';
import store, { dispatch } from '../../store/configureStore';
import { UpdatesModalView } from '../react_components/dialogs/updatesModal/components/UpdatesModalView/UpdatesModalView';
import {
    SHOW_SECOND_PANEL_CLASS
} from './../../const';
import {
    LanguageFeatureModalView
} from "../react_components/dialogs/languageFeatureModal/components/LanguageFeatureModalView/LanguageFeatureModalView";
import { getMessages, root } from "../../store/constants";
import { segmentTrackAction, SegmentUserEvent } from "../../middlewares/segment";
import { openShare } from "../react_components/main/topBar/hocs/sharing/google/TopBarSharingHOC/effect/openShare";
/* Use for complex dialogs with specific buttons, with variable height, and probably with scroll */
App.Views.SecondPanelRenderer = App.Views.BaseDialogRenderer.extend({
    el: '.second-panel',
    modifiers: [
        'second-panel--related-cards',
        'second-panel--features-list',
        'second-panel--blocker',
        'second-panel--intro',
        'second-panel--snooze-blocker',
        'second-panel--pro-features-trial',
        'second-panel--pro-features-trial-end',
        'second-panel--basic-features',
        'second-panel--basic-features-small',
    ],

    initialize: function(options) {
        App.vent.on(App.vent['secondPanel:close'], this.hide, this);
        this.currentView = null;
        this.updatesModalView = null;
        this.languageFeatureView = null;
    },
   
    show(view, modifier, layout) {
        this.beforeShow(view, modifier);
        this.showCurrentView();
        this.$el.addClass(SHOW_SECOND_PANEL_CLASS);
        if (!layout) {
            this.$el.addClass('second-panel--without-overlay');
        }
        this.testDataUpdate();
    },

    resetModifiers: function() {
        App.Views.BaseDialogRenderer.prototype.resetModifiers.call(this);
        this.$el.removeClass('second-panel--without-overlay');
    },

    // showRelatedCards: function(options) {
    //     this.show(new App.Views.RelatedCardsDialog({
    //         card: options.card,
    //         parent: this
    //     }), 'second-panel--related-cards', false);
    // },

    showBlocker: function(options, isSnooze) {
        options = options || {};
        const modifiers = ['second-panel--blocker'];
        let layout = true;
        if (isSnooze) {
            modifiers.push('second-panel--snooze-blocker');
        } else {
            layout = false;
            $('body').addClass('page--second-panel-blocker');
        }
        if (options.modifier) {
            modifiers.push(options.modifier);
        }
        this.show(new App.Views.BlockerDialog(extend(options, {parent: this})), modifiers.join(' '), layout);
    },

    showLanguageFeatureModal: function () {
        this.languageFeatureView = new LanguageFeatureModalView(store);
    },

    showShareBoardModal: function (boardId) {
        store.dispatch(segmentTrackAction(SegmentUserEvent.SHARE_MODAL_SHOWED));
        const options = {
            text: getMessages().getText('dialog.blocker.share.text'),
            title: getMessages().getText('dialog.blocker.share.title'),
            img: '/dist/img/sharing-image.svg',
            closeIcon: 'kui-close',
            close: true,
            button: getMessages().getText('dialog.blocker.share.button'),
            onClickButton: () => {
                store.dispatch(segmentTrackAction(SegmentUserEvent.SHARE_MODAL_SHARE_CLICKED));
                root.App.vent.trigger(root.App.vent['secondPanel:close']);
                store.dispatch(openShare(boardId));
            },
            onCloseClick: () => {
                store.dispatch(segmentTrackAction(SegmentUserEvent.SHARE_MODAL_CLOSED));
            }
        };
        this.show(new App.Views.BlockerDialog(extend(options, {parent: this})), 'second-panel--share-board', true);
    },

    showIntroScreen: function(options) {
        options = options || {};
        this.show(new App.Views.InfoScreens({
            type: options.type,
            updates: options.updates
        }), 'second-panel--intro', true);
        App.vent.on(App.vent['keydown:escape'], this.hide, this); //todo ask
    },

    hide: function() {
        App.Views.BaseDialogRenderer.prototype.hide.call(this);
        App.vent.off(App.vent['keydown:escape'], this.hide, this);
        $('body').removeClass('page--second-panel-blocker');
    }
}, {
    showClass: 'second-panel--show'
});
