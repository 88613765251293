import * as React from 'react';
import { ICommentsListProps } from './types';
import { CLASS_COMMENTS_SECTION } from '../../constants';
import './_comments-list.scss';
import { IComment } from '../../../../../../../store/model/card/types/IComment';
import { CommentHOC } from '../../hocs/CommentHOC/CommentHOC';
import { AnalyzeRender } from '../../../../../helpers/memoizeHelper';

//@ts-ignore
const analyzeRender = window.Settings.development ? new AnalyzeRender(`CommentsList`) : null;

export function CommentsList({
    comments,
    authUserHash,
    cardId,
    isReadonly,
    onEdit
}: ICommentsListProps) {
    if (!comments.length) return null;

    if (analyzeRender) analyzeRender.call(`CommentsList ${cardId}`);

    const className = CLASS_COMMENTS_SECTION + '__list';

    return (
        <div className={className}>
            {
                comments.map((comment: IComment) =>
                    <CommentHOC
                        key={comment.id}
                        comment={comment}
                        isReadonly={isReadonly}
                        cardId={cardId}
                        isMyComment={authUserHash === comment.author.hash}
                        onEdit={onEdit}
                    />
                )
            }
        </div>
    );
}
