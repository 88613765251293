import { Dispatch, ThunkAction } from '../../../types/actions';
import { IGetState } from '../../../types/types';
import { PartLoader } from '../../../util/part_loader';
import { Util } from '../../../util/util';
import { IBoard } from '../../../store/model/board/types';
import { onLoadBoardsFail } from '../../../view/react_components/dialogs/openBoards/effects/onLoadBoardsFail';
import { getFullLoadedBoardIds } from '../../../store/model/selectors/getFullLoadedBoardIds';
import { REST_LOAD_MORE_URL } from '../../constants';

export const moveToLoadMore = (): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const loadedBoardIds = getFullLoadedBoardIds(state);
        const url = REST_LOAD_MORE_URL;
        const excludes = '?excludeIds=' + loadedBoardIds.join(',');

        const loader = new PartLoader();
        return loader.loadPart(
            Util.getApiUrl(url + excludes),
            '',
        )
            .then((boards: IBoard[]) => {
                return boards.sort((a, b) =>
                    a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
                );
            })
            .catch(
                () => dispatch(onLoadBoardsFail())
            )
    };
    return action;
}
