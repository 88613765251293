import { Dispatch, ThunkAction } from '../../../../types/actions';
import { TGoogleDriveId } from '../../dialogs/openBoards/store/types';
import { BOARD_DEFAULT_NAME } from '../../dialogs/openBoards/constants';
import { onCreateBoardFail } from '../../dialogs/openBoards/effects/onCreateBoardFail';
import { SegmentBoardEvent, segmentTrackAction } from '../../../../middlewares/segment';
import { SegmentBoardOption } from '../../../../middlewares/segment/trackEntities/boardEvents';
import { getSegmentEventOptionDriveType } from '../../../../middlewares/segment/trackEntities/helpers/boardEvents/getSegmentEventOptionDriveType';
import { IBoard } from '../../../../store/model/board/types';
import { boardCreate } from '../../../../rest/effects/board/boardCreate';
import { IGetState } from 'app/types/types';
import { getOpened } from '../../dialogs/openBoards/store/common/selectors/getOpened';
import { boardOpen } from './boardOpen';
import { topBarSetAction } from 'app/view/react_components/main/topBar/store/topBarBoard/actions/topBarSetAction';
import { topBarNameStatusSet } from 'app/view/react_components/main/topBar/store/topBar/actions/topBarNameStatusSet';
import { EBoardNameStatus } from 'app/view/react_components/main/topBar/store/topBar/types';
import { getActiveBoardId } from 'app/store/model/selectors/getActiveBoardId';
import { getBoardIsLoading } from '../../../../store/requestsState/selectors/getBoardIsLoading';

export const createBoardByDriveId = (
    driveId: TGoogleDriveId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        const isOpenBoardsDialog = getOpened(getState());
        if (isOpenBoardsDialog) {
            import(/* webpackChunkName: "openBoards" */ 'app/view/react_components/dialogs/openBoards/store/common/actions/blockerSet')
                .then(module => dispatch(module.blockerSet(true)))
                .catch(e => console.error(e))
        }
        const body = {
            name: BOARD_DEFAULT_NAME
        };
        function focusAfterLoading (board: IBoard) {
            let checkLoadingBoard = setInterval(() => {
                const boardId = getActiveBoardId(getState());
                if (boardId === board.id) {
                    clearInterval(checkLoadingBoard)
                    dispatch(topBarSetAction(board.id, topBarNameStatusSet(EBoardNameStatus.editing)));
                    function editListener() {
                        const edit = document.querySelector('.topbar__dashboard-name--edit .kui-input__item') as HTMLElement;
                        requestAnimationFrame(() => {
                            if (edit) {
                                edit.focus()
                            }
                        })
                        window.removeEventListener('focus', editListener)
                    }
                    window.addEventListener('focus', editListener)
                }
            }, 500)
        }

        dispatch(boardCreate(driveId, body))
            .then(
                (board: IBoard) => {
                    if (isOpenBoardsDialog) {
                        import(/* webpackChunkName: "openBoards" */ 'app/view/react_components/dialogs/openBoards/effects/openBoard')
                            .then(module => dispatch(module.openBoard(board.id)))
                            .then(() => focusAfterLoading(board))
                            .catch(e => console.error(e))
                    } else {
                        dispatch(boardOpen(board.id))
                        focusAfterLoading(board)
                    }
                    let option = {
                        name: SegmentBoardOption.LOCATION,
                        value: getSegmentEventOptionDriveType(driveId)
                    };
                    dispatch(segmentTrackAction(SegmentBoardEvent.CREATE, option));
                }
            ).catch(
            () => dispatch(onCreateBoardFail())
        );
    };
    return action;
};
