import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState } from '../../../../types/types';
import { IAuthUser } from '../../../../store/model/authUser/types';
import { getAuthUser } from '../../../../store/model/authUser/selectors/getAuthUser';
import { getUserRecentBoards } from '../../../../store/model/authUser/selectors/getUserRecentBoards';
import { IRecentBoard } from '../../../../types/rest/IUserMeta';
import { getBoard } from '../../../../store/model/selectors/getBoardById';
import { getTab } from '../../../../view/react_components/main/navigationPanel/store/selectors/getTab';
import { getBoard as getOpenBoard } from '../../../../view/react_components/dialogs/openBoards/store/boards/selectors/getBoard';
import { authUserRecentBoardsPatch } from './helper/authUserRecentBoardsPatch';

export const refreshRecentOpenBoard = (
    boardId: number,
    recentBoard?: Partial<IRecentBoard>,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const user: IAuthUser = getAuthUser(state);
        let updateExist = false;
        const recentBoards = getUserRecentBoards(state).map((board: IRecentBoard) => {
            let updateBoard;
            if (board.id === boardId) {
                if (recentBoard) {
                    for (let k in recentBoard) { // есть ли изменения в recentBoard
                        const key = k as keyof IRecentBoard;
                        if (recentBoard[key] !== board[key]) {
                            updateBoard = board;
                            break;
                        }
                    }
                } else {
                    updateBoard = getBoard(state, boardId);
                    if (!updateBoard.id) {
                        updateBoard = getTab(state, boardId) || getOpenBoard(state, boardId);
                    }
                }

            }
            if (updateBoard && updateBoard.id) {
                updateExist = true;
                return {
                    id: updateBoard.id,
                    name: updateBoard.name,
                    logo: updateBoard.logo,
                    logoColor: updateBoard.logoColor,
                    logoIcon: updateBoard.logoIcon,
                    ...recentBoard,
                };
            }
            return {...board};
        });
        if (updateExist) {
            return dispatch(authUserRecentBoardsPatch(user.id, recentBoards));
        }
    };
    return action;
};
