import { TBoardId } from '../../types';
import { IHistoryUserRest } from '../IHistoryUserRest';

export enum ERestActivityActionType {
    CREATE = 'create',
    UPDATE = 'update',
    COPY = 'copy',
    ARCHIVE = 'archive',
    RESTORE = 'restore',
    DATE_PASSED = 'datePassed',
    CHECK_LIST_DATE_PASSED = 'checkLitsItemDatePassed',
    CREATED_FROM_TEMPLATE = 'copyFromTemplate',
    CREATED_FROM_RECURRING = 'copyFromRecurring',
    DELETE = 'delete'
}

export enum ERestActivityType {
    TYPE_CARD_CUSTOM_PROPERTIES = 324,
}

export enum ERestActivityPropertyName {
    TAGS = 'tags',
    START_DATE = 'startDate',
    DUE_DATE = 'dueDate',
    LIST = 'list',
    ASSIGNED_TO = 'assignedTo',
    ASSIGNEES = 'assignees',
    COMMENT = 'comment',
    COMMENT_REACTION = 'commentReacted',
    COLOR_ID = 'colorId',
    ESTIMATE = 'estimate',
    NAME = 'name',
    DESCRIPTION = 'description',
    PROCESSING_STATUS = 'processingStatus',
    PRIORITY = 'priority',
    DRIVE_DOC = 'driveDoc',
    CHECK_LIST = 'checkList',
    CHECK_LIST_GROUP = 'checkListGroup',
    MAX_CARD_NUMBER = 'maxCardNumber',
    STATUS = 'status',
    RELATED_CARDS = 'relatedCard',
}

export enum ERestActivityPropertyActionType {
    ADD = 'add',
    UPDATE = 'update',
    DELETE = 'delete'
}

export enum ERestActivityModelType {
    CARD = 'card',
    LIST = 'list',
    BOARD = 'dashboard'
}

export interface IRestActivityBase {
    //from HistoryRestBase.java
    id: number;
    user: IHistoryUserRest;
    time: number;
    actionType: ERestActivityActionType;
    modelId: number;
    modelType: ERestActivityModelType;
    type: number;
    propertyName: ERestActivityPropertyName;
    propertyActionType: ERestActivityPropertyActionType;
    dashboardId: TBoardId;
    dashboardName: string;
    usersPushNotified: IHistoryUserRest[];
}
