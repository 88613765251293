import store, { dispatch, getAppState } from './../store/configureStore.ts';
import { routerChange } from '../store/router/actions/routerChange';
import { updateSelectedCards } from '../view/react_components/clickManager';
import { addRecentOpenBoard } from "../rest/effects/authUser/recentBoards/addRecentOpenBoard";
import { relatedCardsSelect } from '../view/react_components/aside_panel/relatedCards/effects/relatedCardsSelect';
import { ERoutes } from '../store/router/constants';
import { getIsAssignedToMeActive } from '../store/router/selectors/getIsAssignedToMeActive';
import { resetSelectedCards } from '../view/react_components/clickManager/effects/resetSelectedCards';
import { getUserRecentBoards } from '../store/model/authUser/selectors/getUserRecentBoards';
import { getTabIds } from '../store/model/selectors/getTabIds';
import { tabsActionSet } from '../store/model/actions/tabsActionSet';
import { tabsModelSetAction } from '../store/model/tabs/actions/tabsModelSetAction';
import { openListDetails } from '../controller/openListDetails';
import { openCardForm } from '../controller/openCardForm';
import { openCardTiming } from '../controller/openCardTiming';
import { openCardActivity } from '../controller/openCardActivity';
import { openCardTemplates } from '../controller/openCardTemplates';
import { openRecurringTasks } from '../controller/openRecurringTasks';
import { openMultiCardForm } from '../controller/openMultiCardForm';
import { openBoardForm } from '../controller/openBoardForm';
import { openBoardActivity } from '../controller/openBoardActivity';
import { openBoardDesign } from '../controller/openBoardDesign';
import { openBoardBackups } from '../controller/openBoardBackups';
import { openBoardExport } from '../controller/openBoardExport';
import { openBoardFilterForm } from '../controller/openBoardFilterForm';
import { openCopyBoard } from '../controller/openCopyBoard';
import { openCardPrint } from '../controller/openCardPrint';
import { MY_WORK_FILTER_BOARD_ID } from '../view/react_components/aside_panel/filterPanelBoard/constants';
import { openBoardAttachments } from "../controller/openBoardAttachments";
import * as _ from 'underscore';
import { getNoBoardsLoaded } from "../store/model/selectors/getNoBoardsLoaded";
import { REDIRECT_WINDOW_HISTORY_LENGTH } from "../const";
import { getAuthUser } from "app/store/model/authUser/selectors/getAuthUser";
import { getRequestMicrosoftUserId } from "app/store/model/selectors/getRequestMicrosoftUserId";
import { getRequestMicrosoftGraphUrls } from "app/store/model/selectors/getRequestMicrosoftGraphUrls";
import { getSharedFileFromOneDrive } from "app/helper/authorisation/microsoft/graph/api/getSharedFileFromOneDrive";
import { boardLoaderByFileIdDriveId } from "app/helper/loader/boardLoaderByFileIdDriveId";
import { getCardIdFromUrl } from "../helper/urlHelper/getCardIdFromUrl";
import { getListIdFromUrl } from "../helper/urlHelper/getListIdFromUrl";
import { getCommentIdFromUrl } from "../helper/urlHelper/getCommentIdFromUrl";
import { getCardIdsFromUrl } from "../helper/urlHelper/getCardIdsFromUrl";
import Util from "app/util/util";
import { authUserSetPromoCode } from "../rest/effects/authUser/authUserSetPromoCode";

const App = window.App;
const Backbone = window.Backbone;

const modals = {
    help: 'openHelp',
    updates: 'openUpdates',
    pricing: 'showPayment'
}

export const DashboardRouter = App.Router.DashboardRouter = Backbone.Router.extend({
    routes: {
        '': ERoutes.INDEX, //'index'
        'promo(/)': ERoutes.PROMO_CODE, //'promoCode',
        'promo2(/)': ERoutes.PROMO_CODE_2, //'promoCode2',
        'google-groups-sharing(/)': ERoutes.GSHARE, //'googleGroupsSharing',
        'google-spreadsheet-permission(/)': ERoutes.G_SPREADSHEET_PERMISSIONS, //'googleSpreadsheetPermissions',
        'microsoft-groups-permission(/)': ERoutes.MS_GROUP_PERMISSION, //'microsoftGroupPermission',
        'test(/)': ERoutes.TEST, //'test',
        'destroy(/)': ERoutes.DELETE_ACCOUNT, //'deleteAccount',
        'accessibility-statement(/)': ERoutes.ACCESSIBILITY_STATEMENT, // accessibilityStatement,
        'pricing(/)(:productId)': ERoutes.PRICING, // pricing
        'onedrive': ERoutes.ONEDRIVE,

        // Board
        ':openedBoardIds/d-:id/edit(/)': ERoutes.BOARD_EDIT, //'editBoard',
        ':openedBoardIds/d-:id/edit/:scrollElem(/)': ERoutes.BOARD_EDIT_SCROLL, //'editBoardWithScroll',
        ':openedBoardIds/d-:id/activity(/)': ERoutes.BOARD_ACTIVITY, //'boardActivity',
        ':openedBoardIds/d-:id/attachments(/)': ERoutes.BOARD_ATTACHMENTS, //'boardAttachments',
        ':openedBoardIds/d-:id/filter(/)': ERoutes.BOARD_FILTER, //'boardFilter',
        ':openedBoardIds/d-:id/design(/)': ERoutes.BOARD_DESIGN, //'boardDesign',
        ':openedBoardIds/d-:id/backups(/)': ERoutes.BOARD_BACKUPS, //'boardBackups',
        ':openedBoardIds/d-:id/export(/)': ERoutes.BOARD_EXPORT, //'boardExport'
        ':openedBoardIds/d-:id/copy(/)': ERoutes.BOARD_COPY, //'copyBoard',
        ':openedBoardIds/d-:id/card-templates(/)': ERoutes.CARD_TEMPLATES, //'cardTemplates',
        ':openedBoardIds/d-:id/recurring(/)': ERoutes.RECURRING_TASKS, //'recurringTasks',
        ':openedBoardIds/d-:id/c-:id(/)': ERoutes.EDIT_CARD, //'editCard',
        ':openedBoardIds/d-:id/c-:id/timing(/)': ERoutes.CARD_TIMING, //'cardTiming',
        ':openedBoardIds/d-:id/c-:id/activity(/)': ERoutes.CARD_ACTIVITY, //'cardActivity',
        ':openedBoardIds/d-:id/c-:id/cmt-:id(/)': ERoutes.EDIT_CARD, //'editCard',
        ':openedBoardIds/d-:id/c-:id/print(/)': ERoutes.CARD_PRINT, //'cardPrint',
        ':openedBoardIds/d-:id/cs-:id(/)': ERoutes.SELECT_CARDS, //'selectCards',
        ':openedBoardIds/d-:id/l-:id/print(/)': ERoutes.LIST_PRINT_PREVIEW, //'listPrintPreview',
        ':openedBoardIds/d-:id/l-:id(/)': ERoutes.EDIT_LIST, //editList',
        // /dashboard/123-456-789/d-123 || /dashboard/123-456-789/d-123/help
        ':openedBoardIds/d-:id(/)(:modal)(/)(:productId)': ERoutes.BOARD, //'board'

        'd-:id/cl-:id(/)': ERoutes.LINK_CARD, //linkCard
        'd-:id/cl-:id/cmt-:id(/)': ERoutes.LINK_CARD, //linkCard
        'd-:id/cl-:id/activity(/)': ERoutes.LINK_CARD_ACTIVITY, //linkCardActivity
        'd-:id/cl-:id/timing(/)': ERoutes.LINK_CARD_TIMING, //linkCardTiming

        '(:openedBoardIds/)atm/import': ERoutes.ASSIGNED_TO_ME_IMPORT, //'assignedToMeImport'
        '(:openedBoardIds/)atm/settings': ERoutes.ASSIGNED_TO_ME_SETTINGS, //'assignedToMeSettings'
        '(:openedBoardIds/)atm/background': ERoutes.ASSIGNED_TO_ME_BACKGROUND, //'assignedToMeBackground'
        '(:openedBoardIds/)atm/atm-:id(/)': ERoutes.ASSIGNED_TO_ME_CARD, //'assignedToMeCard'
        '(:openedBoardIds/)atm/atm-:id/activity(/)': ERoutes.ASSIGNED_TO_ME_CARD_ACTIVITY, //'assignedToMeCardActivity'
        '(:openedBoardIds/)atm/atm-:id/timing(/)': ERoutes.ASSIGNED_TO_ME_CARD_TIMING, //'assignedToMeCardTiming'
        '(:openedBoardIds/)atm/n-:id(/)': ERoutes.ASSIGNED_TO_ME_NOTIFICATION, //'assignedToMeNotification'
        '(:openedBoardIds/)atm/n-:id/activity(/)': ERoutes.ASSIGNED_TO_ME_NOTIFICATION_ACTIVITY, //'assignedToMeNotificationActivity'
        '(:openedBoardIds/)atm/n-:id/timing(/)': ERoutes.ASSIGNED_TO_ME_NOTIFICATION_TIMING, //'assignedToMeNotificationTiming'
        '(:openedBoardIds/)atm/filter(/)': ERoutes.ASSIGNED_TO_ME_FILTER, //'assignedToMeFilter'
        '(:openedBoardIds/)atm/filter/:scrollElem(/)': ERoutes.ASSIGNED_TO_ME_FILTER, //'assignedToMeFilter'
        '(:openedBoardIds/)atm(/)(:modal)': ERoutes.ASSIGNED_TO_ME, //'assignedToMe'

        ':params(/)': ERoutes.FALLBACK, //'fallback'
        '*other': ERoutes._404
    },

    // copy of source, added before:route event
    route: function (route, name, callback) {
        if (!_.isRegExp(route)) route = this._routeToRegExp(route);
        if (_.isFunction(name)) {
            callback = name;
            name = '';
        }
        if (!callback) callback = this[name];
        var router = this;
        Backbone.history.route(route, function (fragment) {
            var args = router._extractParameters(route, fragment);
            router.trigger('before:route', name, args);
            if (router.execute(callback, args) !== false) {
                router.trigger.apply(router, ['route:' + name].concat(args));
                router.trigger('route', name, args);
                Backbone.history.trigger('route', router, name, args);
            }
        });
        return this;
    },

    initialize: function(options) {
        this.on('before:route', this.onRouteChange);

        this.controller = options.controller;
        this.setBaseUrl(null);
    },

    onRouteChange: function(name, args) {
        dispatch(routerChange(name, args));
    },

    openModal: function(modal) {
        if (modal && modals[modal] && this.controller[modals[modal]]) {
            this.controller[modals[modal]]();
        }
    },

    index: function() {
        const callback = _.bind(function() {
            dispatch(tabsActionSet(tabsModelSetAction({})));
            this.controller.closeBoards();
            this.controller.clearActiveBoard();
            this.controller.showMainPanel();
            const recentBoards = getUserRecentBoards(store.getState());
            if (recentBoards.length < 1) {
                this.controller.showOpenDashboardDialog('allBoards'); // no recent boards
            } else {
                this.controller.hideSystemLoader();
                this.controller.mainView.hideAssignedToMe();
            }
        }, this);
        this.controller.checkBlockAndRun(callback);
    },

    assignedToMe: function(openedBoardIds, modal) {
        dispatch(resetSelectedCards());
        const callback = () => {
            this.controller.showMainPanel();
            this.controller.showMyWork();
            this.openModal(modal);
        };
        this.processAssignedToMe(openedBoardIds, callback);
    },

    assignedToMeImport: function(openedBoardIds) {
        dispatch(resetSelectedCards());
        const callback = () => {
            this.controller.showMyWork();
            this.controller.asidePanel.openAssignedToMeImport();
        };
        this.processAssignedToMe(openedBoardIds, callback);
    },

    assignedToMeSettings: function(openedBoardIds) {
        dispatch(resetSelectedCards());
        const callback = () => {
            this.controller.showMyWork();
            this.controller.asidePanel.openAssignedToMeSettings();
        };
        this.processAssignedToMe(openedBoardIds, callback);
    },

    assignedToMeBackground: function(openedBoardIds) {
        dispatch(resetSelectedCards());
        const callback = () => {
            this.controller.showMyWork();
            this.controller.asidePanel.openAssignedToMeBackground();
        };
        this.processAssignedToMe(openedBoardIds, callback);
    },

    assignedToMeCard: function(openedBoardIds, cardId) {
        dispatch(resetSelectedCards());
        const callback = () => {
            this.controller.showMyWork();
            this.controller.asidePanel.openAssignedToMeCard(parseInt(cardId));
        };
        this.processAssignedToMe(openedBoardIds, callback);
    },

    assignedToMeCardActivity: function(openedBoardIds, cardId) {
        this.assignedToMeCard(openedBoardIds, cardId) // пока тоже самое действие
    },

    assignedToMeCardTiming: function(openedBoardIds, cardId) {
        this.assignedToMeCard(openedBoardIds, cardId) // пока тоже самое действие
    },

    linkCard: function(boardId, cardId) {
        this.controller.clearActiveBoard(); // close board on browser go back
        this.controller.asidePanel.openLinkCard(parseInt(boardId), parseInt(cardId));
    },

    linkCardActivity: function(boardId, cardId) {
        this.linkCard(boardId, cardId);
    },

    linkCardTiming: function(boardId, cardId) {
        this.linkCard(boardId, cardId);
    },

    assignedToMeNotification: function(openedBoardIds, notificationId) {
        this.controller.asidePanel.openAssignedToMeNotification(parseInt(notificationId));
    },

    assignedToMeNotificationActivity: function(openedBoardIds, notificationId) {
        this.assignedToMeNotification(openedBoardIds, notificationId) // пока тоже самое действие
    },

    assignedToMeNotificationTiming: function(openedBoardIds, notificationId) {
        this.assignedToMeNotification(openedBoardIds, notificationId) // пока тоже самое действие
    },

    assignedToMeFilter: function(openedBoardIds, scrollElem) {
        dispatch(resetSelectedCards());
        const callback = () => {
            this.controller.showMyWork();
            dispatch(openBoardFilterForm(MY_WORK_FILTER_BOARD_ID, scrollElem));
        };
        this.processAssignedToMe(openedBoardIds, callback);
    },

    processAssignedToMe: function(openedBoardIds, callback) {
        if (!openedBoardIds) {
            if (getTabIds(store.getState()).length) {
                this.controller.closeBoards();
                dispatch(tabsActionSet(tabsModelSetAction({})));
            }
            this.controller.checkBlockAndRun(callback);
        } else {
            this.controller.loadBoardTabsIfNotLoaded(openedBoardIds.split('-').map(Number), callback);
        }
    },

    test: function() {
        App.Util.setCookie('TEST_ENV', 1);
        this.navigate('', {trigger: true});
    },

    promoCode: function() {
        this.controller.showPromoCodeForm();
    },

    promoCode2: function() {
        let params = App.Helpers.StartupProcessor.params();
        let promoObj = null;
        try {
            promoObj = Util.decodePromo(params.code, params.add);
            console.log(promoObj);
            dispatch(authUserSetPromoCode(promoObj));
        } catch (e) {
            console.log('code invalid');
        }
        this.index();

    },

    onedrive: function () {
        App.controller.boardLoaderInfo.isFirstLoad = false; // чтоб синий экран больше не появлялся
        const authUser = getAuthUser(getAppState());
        const userId  = getRequestMicrosoftUserId();
        const items = getRequestMicrosoftGraphUrls();
        if (!authUser.anonym && userId && items) {
            if (userId.toLowerCase() !== authUser.email.toLowerCase()) {
                App.controller.showSnackbarOnedriveFileHandlerSwitchAccount(userId);
            } else {
                getSharedFileFromOneDrive(items[0])
                .then(driveItem => {
                    console.log(driveItem)
                    boardLoaderByFileIdDriveId(driveItem.id, driveItem.parentReference.driveId)
                        .then(list=> {
                            if(list && list.length >0) {
                                this.navigate(this.getBoardUrl(list[0].id), {trigger: true});
                            } else {
                                this.navigate('', {trigger: true});
                            }
                        });
                })
                .catch(e =>{
                    console.error(e);
                    this.navigate('', {trigger: true});
                });
            }
        } else {
            this.navigate('', {trigger: true});
        }
    },

    googleGroupsSharing: function(){
      this.controller.showGsuiteAdminForm();
    },

    googleSpreadsheetPermissions: function(){
        this.controller.showGoogleSpreadsheetPermissionsPage();
    },

    microsoftGroupPermission: function(){
        this.controller.showMicrosoftGroupPermission();
    },

    checkBoards: function(boardIds) {
        var patt = /^\d+((\d|-)\d+)+$/g;
        return patt.test(boardIds);
    },

    fallback: function(params) {
        if (this.checkBoards(params)) {
            var ids = params.split('-');
            this.navigate(params + '/d-' + parseInt(ids[0]), { trigger: true });
        } else {
            if (modals[params]) {
                this.openModal(params);
            } else {
                this.navigate('', { trigger: true });
            }
        }
    },

    navigate: function(fragment, options) {
        if (fragment){
            fragment = fragment.replace(/^\//,"");
        }
        if (Backbone.history.getFragment()===fragment) {
            return false;
        }
        const result = Backbone.history.navigate(fragment, options);
        App.controller.trackPage();
        return result;
    },

    board: function(openedBoardIds, id, modal) {
        if (this.checkBoards(openedBoardIds) && _.isNumber(parseInt(id))) {
            this.controller.showMainPanel();
            var callback = _.bind(function(loadedBoardIds) {
                if (loadedBoardIds && _.contains(loadedBoardIds, parseInt(id))) {
                    dispatch(addRecentOpenBoard(parseInt(id)));
                    this.controller.openBoard(parseInt(id), !!modal);
                    this.openModal(modal);
                } else {
                    App.router.navigate(App.router.getUrl(modal), { trigger: true });
                }
            }, this);
            this.controller.loadBoardTabsIfNotLoaded(openedBoardIds.split('-').map(Number), callback);
        } else {
            this.navigate('', { trigger: true });
        }
        if (this.controller.isRelatedPanelNotOpened()) {
            dispatch(updateSelectedCards(Number(id), []));
        } else {
            dispatch(relatedCardsSelect(Number(id)));
        }
    },

    editBoard: function(openedBoardIds, id, scrollElem = '') {
        var callback = _.bind(function(loadedBoardIds) {
            if (loadedBoardIds && _.contains(loadedBoardIds, parseInt(id))) {
                this.controller.openBoard(parseInt(id), true)
                    .then(()=> {
                        //this.controller.openBoardForm(parseInt(id), scrollElem)
                        dispatch(openBoardForm(parseInt(id), scrollElem))
                    })

            }else{
                App.router.navigate(App.router.getUrl(), { trigger: true });
            }
        }, this);
        this.controller.loadBoardTabsIfNotLoaded(openedBoardIds.split('-').map(Number), callback);
    },

    cardTemplates: function(openedBoardIds, id) {
        var callback = _.bind(function(loadedBoardIds) {
            if (loadedBoardIds && _.contains(loadedBoardIds, parseInt(id))) {
                this.controller.openBoard(parseInt(id), true)
                    .then(() => {
                        //this.controller.openCardTemplates(parseInt(id))
                        dispatch(openCardTemplates(parseInt(id)));
                    })
            }else{
                App.router.navigate(App.router.getUrl(), { trigger: true });
            }
        }, this);
        this.controller.loadBoardTabsIfNotLoaded(openedBoardIds.split('-').map(Number), callback);
    },

    recurringTasks: function(openedBoardIds, id) {
        var callback = _.bind(function(loadedBoardIds) {
            if (loadedBoardIds && _.contains(loadedBoardIds, parseInt(id))) {
                this.controller.openBoard(parseInt(id), true)
                    .then(() => {
                        //this.controller.openRecurringTasks(parseInt(id))
                        dispatch(openRecurringTasks(parseInt(id)));
                    })
            } else{
                App.router.navigate(App.router.getUrl(), { trigger: true });
            }
        }, this);
        this.controller.loadBoardTabsIfNotLoaded(openedBoardIds.split('-').map(Number), callback);
    },

    editBoardWithScroll: function(openedBoardIds, id, scrollElem = '') {
        this.editBoard(openedBoardIds, id, scrollElem);
    },

    boardActivity: function(openedBoardIds, id) {
        var callback = _.bind(function(loadedBoardIds) {
            if (loadedBoardIds && _.contains(loadedBoardIds, parseInt(id))) {
                this.controller.openBoard(parseInt(id), true)
                    .then(() => {
                        //this.controller.openBoardActivity(parseInt(id))
                        dispatch(openBoardActivity(parseInt(id)));
                    });
            }else{
                App.router.navigate(App.router.getUrl(), { trigger: true });
            }
        }, this);
        this.controller.loadBoardTabsIfNotLoaded(openedBoardIds.split('-').map(Number), callback);
    },

    boardDesign: function(openedBoardIds, id) {
        var callback = _.bind(function(loadedBoardIds) {
            if (loadedBoardIds && _.contains(loadedBoardIds, parseInt(id))) {
                this.controller.openBoard(parseInt(id), true)
                    .then(() => {
                        //this.controller.openBoardDesign(parseInt(id))
                        dispatch(openBoardDesign(parseInt(id)));
                    });
            }else{
                App.router.navigate(App.router.getUrl(), { trigger: true });
            }
        }, this);
        this.controller.loadBoardTabsIfNotLoaded(openedBoardIds.split('-').map(Number), callback);
    },

    boardAttachments: function(openedBoardIds, id) {
        var callback = _.bind(function(loadedBoardIds) {
            if (loadedBoardIds && _.contains(loadedBoardIds, parseInt(id))) {
                this.controller.openBoard(parseInt(id), true)
                    .then(() => {
                        //this.controller.openBoardBackups(parseInt(id))
                        dispatch(openBoardAttachments(parseInt(id)));
                    });
            }else{
                App.router.navigate(App.router.getUrl(), { trigger: true });
            }
        }, this);
        this.controller.loadBoardTabsIfNotLoaded(openedBoardIds.split('-').map(Number), callback);
    },

    boardBackups: function(openedBoardIds, id) {
        var callback = _.bind(function(loadedBoardIds) {
            if (loadedBoardIds && _.contains(loadedBoardIds, parseInt(id))) {
                this.controller.openBoard(parseInt(id), true)
                    .then(() => {
                        //this.controller.openBoardBackups(parseInt(id))
                        dispatch(openBoardBackups(parseInt(id)));
                    });
            }else{
                App.router.navigate(App.router.getUrl(), { trigger: true });
            }
        }, this);
        this.controller.loadBoardTabsIfNotLoaded(openedBoardIds.split('-').map(Number), callback);
    },

    boardExport: function(boardIds, id) {
        this.controller.loadBoardTabsIfNotLoaded(
            boardIds.split('-').map(Number),
            (loadedBoardIds) => {
                if (!loadedBoardIds || !loadedBoardIds.includes(Number(id))) {
                    return App.router.navigate(App.router.getUrl(), { trigger: true });
                }
                this.controller.openBoard(parseInt(id), true)
                    .then(() => {
                        //this.controller.openBoardExport(Number(id))
                        dispatch(openBoardExport(Number(id)))
                    });
            }
        );
    },

    boardFilter: function(openedBoardIds, id) {
        var callback = _.bind(function(loadedBoardIds) {
            if (loadedBoardIds && _.contains(loadedBoardIds, parseInt(id))) {
                this.controller.openBoard(parseInt(id), true)
                    .then(() => {
                        //this.controller.openBoardFilterForm(parseInt(id))
                        dispatch(openBoardFilterForm(parseInt(id)))
                    });
            }else{
                App.router.navigate(App.router.getUrl(), { trigger: true });
            }
        }, this);
        this.controller.loadBoardTabsIfNotLoaded(openedBoardIds.split('-').map(Number), callback);
    },

    boardHelp: function(openedBoardIds, boardId) {
        const callback = (loadedBoardIds) => {
            let promise = Promise.resolve();
            if (boardId) {
                if (loadedBoardIds && _.contains(loadedBoardIds, parseInt(boardId))) {
                    promise = this.controller.openBoard(parseInt(boardId), true);
                }
            }
            promise.then(() => {
                this.controller.openHelp();
                App.vent.trigger(App.vent['dashboards:loaded']);
            })
        }
        this.controller.loadBoardTabsIfNotLoaded(openedBoardIds.split('-').map(Number), callback);
    },

    deleteAccount: function() {
        this.controller.deleteAccount();
        App.vent.trigger(App.vent['dashboards:loaded']);
    },

    editCard: function(openedBoardIds, boardId, cardId, onEdit) {
        const isNoBoards = getNoBoardsLoaded(store.getState());
        if (isNoBoards && window.history.length < REDIRECT_WINDOW_HISTORY_LENGTH && this.isInitPage()) return;
        var callback = _.bind(function(loadedBoardIds) {
            if (loadedBoardIds && _.contains(loadedBoardIds, parseInt(boardId))) {
                this.controller.openBoard(parseInt(boardId), true)
                    .then(() => {
                        return dispatch(openCardForm(parseInt(cardId)));
                    })
                    .then(() => {
                        dispatch(updateSelectedCards(parseInt(boardId), [ parseInt(cardId)]));
                    })
                    .then( () => {
                        if (onEdit && _.isFunction(onEdit)) {
                            onEdit();
                        }
                    })
                    .catch(() => {
                        App.router.navigate(App.router.getUrl(), { trigger: true });
                    });
            }else{
                App.router.navigate(App.router.getUrl(), { trigger: true });
            }
        }, this);
        this.controller.loadBoardTabsIfNotLoaded(openedBoardIds.split('-').map(Number), callback);
    },

    cardTiming: function(openedBoardIds, boardId, cardId) {
        const isNoBoards = getNoBoardsLoaded(store.getState());
        if (isNoBoards && window.history.length < REDIRECT_WINDOW_HISTORY_LENGTH && this.isInitPage()) return;
        var callback = _.bind(function(loadedBoardIds) {
            if (loadedBoardIds && _.contains(loadedBoardIds, parseInt(boardId))) {
                this.controller.openBoard(parseInt(boardId), true)
                    .then(() => {
                        //this.controller.openCardTiming(parseInt(cardId))
                        dispatch(openCardTiming(parseInt(cardId)));
                    })
                    .then(() => {
                        dispatch(updateSelectedCards(parseInt(boardId), [ parseInt(cardId)]));
                    });
            }else{
                App.router.navigate(App.router.getUrl(), { trigger: true });
            }
        }, this);
        this.controller.loadBoardTabsIfNotLoaded(openedBoardIds.split('-').map(Number), callback);
    },

    cardActivity: function(openedBoardIds, boardId, cardId) {
        const isNoBoards = getNoBoardsLoaded(store.getState());
        if (isNoBoards && window.history.length < REDIRECT_WINDOW_HISTORY_LENGTH && this.isInitPage()) return;
        var callback = _.bind(function(loadedBoardIds) {
            if (loadedBoardIds && _.contains(loadedBoardIds, parseInt(boardId))) {
                this.controller.openBoard(parseInt(boardId), true)
                    .then(() => {
                        //this.controller.openCardActivity(parseInt(cardId))
                        dispatch(openCardActivity(parseInt(cardId)));
                    })
                    .then(() => {
                        dispatch(updateSelectedCards(parseInt(boardId), [ parseInt(cardId)]));
                    });
            }else{
                App.router.navigate(App.router.getUrl(), { trigger: true });
            }
        }, this);
        this.controller.loadBoardTabsIfNotLoaded(openedBoardIds.split('-').map(Number), callback);
    },

    cardPrint: function(openedBoardIds, boardId, cardId) {
        var callback = _.bind(function(loadedBoardIds) {
            if (loadedBoardIds && _.contains(loadedBoardIds, parseInt(boardId))) {
                this.controller.openBoard(parseInt(boardId), true)
                    .then(() => {
                        //this.controller.openCardPrint(parseInt(cardId))
                        dispatch(openCardPrint(parseInt(cardId)));
                    });
            }else{
                App.router.navigate(App.router.getUrl(), { trigger: true });
            }
        }, this);
        this.controller.loadBoardTabsIfNotLoaded(openedBoardIds.split('-').map(Number), callback);
    },

    listPrintPreview: function(openedBoardIds, boardId, listId) {
        var callback = _.bind(function(loadedBoardIds) {
            if (loadedBoardIds && _.contains(loadedBoardIds, parseInt(boardId))) {
                this.controller.openBoard(parseInt(boardId), true)
                    .then(()=>this.controller.openListPrintPreview(parseInt(listId)));
            }else{
                App.router.navigate(App.router.getUrl(), { trigger: true });
            }
        }, this);
        this.controller.loadBoardTabsIfNotLoaded(openedBoardIds.split('-').map(Number), callback);
    },

    selectCards: function(openedBoardIds, boardId, cardIds) {
        var callback = _.bind(function(loadedBoardIds) {
            if (loadedBoardIds && _.contains(loadedBoardIds, parseInt(boardId))) {
                var cards = (cardIds ? cardIds.split('-').map(Number) : []);
                if (cards.length) {
                    this.controller.openBoard(parseInt(boardId), true)
                        .then(() => {
                            //this.controller.openMultiCardForm(cards)
                            dispatch(openMultiCardForm(parseInt(boardId), cards));
                        });
                }
            }else{
                App.router.navigate(App.router.getUrl(), { trigger: true });
            }
        }, this);
        this.controller.loadBoardTabsIfNotLoaded(openedBoardIds.split('-').map(Number), callback);
    },

    copyBoard: function(openedBoardIds, id) {
        var callback = _.bind(function(loadedBoardIds) {
            if (loadedBoardIds && _.contains(loadedBoardIds, parseInt(id))) {
                this.controller.openBoard(parseInt(id))
                    .then(() => {
                        //this.controller.copyDashboard(parseInt(id))
                        dispatch(openCopyBoard(parseInt(id)));
                    });
            }
        }, this);
        this.controller.loadBoardTabsIfNotLoaded(openedBoardIds.split('-').map(Number), callback);
    },

    editList: function(openedBoardIds, boardid, id) {
        var callback = _.bind(function(loadedBoardIds) {
            if (loadedBoardIds && _.contains(loadedBoardIds, parseInt(boardid))) {
                this.controller.openBoard(parseInt(boardid), true)
                    .then(() => {
                        //this.controller.openListDetails(parseInt(id))
                        dispatch(openListDetails(parseInt(id)))
                    });
            }else{
                App.router.navigate(App.router.getUrl(), { trigger: true });
            }
        }, this);
        this.controller.loadBoardTabsIfNotLoaded(openedBoardIds.split('-').map(Number), callback);
    },

    _404: function(other) {
        this.navigate('', { trigger: true });
    },

    openLink: function(route) {
        const board = route.match(/\/d-\d{16}/);
        const boardId = board && board[0].slice(3);
        if (boardId) {
            let action;
            const cardIds = getCardIdsFromUrl(route);
            if (cardIds && cardIds.length) {
                action = `cs-${cardIds.join('-')}`;
            } else {
                const listId = getListIdFromUrl(route);
                if (listId) {
                    action = `l-${listId}`;
                } else {
                    const cardId = getCardIdFromUrl(route);
                    const commentId = getCommentIdFromUrl(route);
                    if (commentId && cardId) {
                        action = `c-${cardId}/cmt-${commentId}`;
                    } else if (cardId) {
                        action = `c-${cardId}`;
                    }
                }
            }
            this.navigate(this.getBoardUrl(boardId, action ? action : ''), {trigger: true});
            return true;
        }
    },

    setBaseUrl: function(openedBoardIds) {
        this.baseUrl = (openedBoardIds ? openedBoardIds.join('-') : '') + '/';
    },

    getBoardUrl: function(boardId, action) {
        const boardIds = new Set(getTabIds(store.getState()));
        boardIds.add(boardId);
        return boardIds.join('-') +
            ('/d-' + boardId ) +
            (action? '/' + action : '');
    },

    getLinkCardUrl: function (boardId, cardId, action) {
        return ('d-' + boardId) +
            ('/cl-' + cardId) +
            (action ? '/' + action : '');
    },

    setCurrentDashboard: function(boardId, navigate) {
        if (typeof(navigate) === 'undefined') {
            navigate = true;
        }
        this.dashboardId = boardId;
        if (navigate) {
            this.navigate(this.getUrl(null), { trigger: true });
        }
    },

    getAssignedToMeUrl: function(action) {
        action = action ? '/' + action : '';
        return this.baseUrl + 'atm' + action;
    },

    getAssignedToMePanelUrl: function(prefix, id, action) {
        let url = `${prefix}-${id}`;
        url += action ? '/' + action : '';
        return this.getAssignedToMeUrl(url);
    },

    getValidBoardId: function() {
        if (this.dashboardId && this.baseUrl.includes(this.dashboardId))
            return this.dashboardId;
        const boardIds = this.baseUrl.substring(0, this.baseUrl.length - 1).split('-');
        if (boardIds[0]) return parseInt(boardIds[0]);
        return null;
    },

    getUrl: function(action) {
        const parts = [];
        const boardId = this.getValidBoardId();
        if (boardId) parts.push('d-' + boardId)
        if (action) parts.push(action);

        return this.baseUrl + parts.join('/');
    },

    getBaseUrl: function() {
        if (getIsAssignedToMeActive(store.getState())) {
            return this.getAssignedToMeUrl();
        }
        return this.getUrl(null);
    },

    getModalUrl: function(modal) {
        if (getIsAssignedToMeActive(store.getState())) {
            return this.getAssignedToMeUrl(modal)
        }
        return this.getUrl(modal);
    },

    getBoardTabUrl: function(boardId, action) {
        return boardId + '/d-' + boardId + (action ? ('/' + action) : '');
    },

    getPageUrl: function(boardId, action) {
        return '/dashboard/' + boardId + '/d-' + boardId + (action ? ('/' + action) : '');
    },

    getCardPageUrl: function(boardId, cardId, action) {
        return '/dashboard/' + boardId + '/d-' + boardId + '/c-' + cardId + (action ? ('/' + action) : '');
    },

    getListPageUrl: function(boardId, listId, action) {
        return '/dashboard/' + boardId + '/d-' + boardId + '/l-' + listId + (action ? ('/' + action) : '');
    },

    getAbsoluteListPageUrl: function(boardId, listId, action) {
        return window.location.origin + this.getListPageUrl(boardId, listId, action);
    },

    getAbsoluteCardPageUrl: function(boardId, cardId, action) {
        return window.location.origin + this.getCardPageUrl(boardId, cardId, action);
    },

    getAbsoluteBaseUrl: function() {
        return window.location.origin + '/dashboard/' + this.getUrl();
    },

    accessibilityStatement: function () {
        this.controller.openAccessibilityStatement();
    },

    pricing: function (productId) {
        this.openModal('pricing');
    },

    start: function() {
        this.initPage = window.location.href;
        Backbone.history.start({pushState: true, root: 'dashboard'});
    },

    restart: function () {
        Backbone.history.stop();
        this.start();
    },

    isInitPage: function (){
        return this.initPage === window.location.href;
    }
});
/**
Перед началом работы по этой самой аналитике я поставил себе целями следующее:
 1) Добится того чтоб мы смогли сформировать воронку пользователей на каждом этапе,
 1.1) Перед регистрации, (Окно Гив пермишн)
 1.2) После регистрации, (После запроса прав при окне с выбором создать первую доску или посмотреть обучающие видео)
 1.3) Пользователь создал первую доску
 После этого мы считаем что пользователь успешно прошел все 3 стадии этой воронки, она покажет нам на сколько пользователь
 зашедший на канбан, испгулася запроса прав, или посмотрев видео о канбане понял что это не тот тул который ему нужен.
 Текущую воронку мы исправили в сегодняшнем релизе и если всё хорошо, то в ближайшее время оно должно заработать, но
 Есть одна большая проблема, связана она с тем что эти цифры будет не точные именно из-за следующего:
 */

