import { IShowBoardsSetAction } from './types';
import { AT_SHOW_BOARDS_SET } from './constants';
import { TBoardId } from '../../../../../../types/types';

export const myWorkShowBoardsSet = (
    boardIds: TBoardId[]
): IShowBoardsSetAction => ({
    type: AT_SHOW_BOARDS_SET,
    boardIds
});
