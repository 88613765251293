import { ICard } from '../../card/types';
import { ICardsComparator } from './types';
import { compareNumbers } from './compareNumbers';

const getCardValue = (
    card: ICard
): number => card.estimate;

export const estimate: ICardsComparator = {
    getCardValue,
    sort: compareNumbers
}
