import { connect } from 'react-redux';
import { CustomPropertiesPanelButton } from '../../components/CustomPropertiesPanelButton/CustomPropertiesPanelButton';
import {
    ICustomPropertiesPanelButtonEvents,
    ICustomPropertiesPanelButtonFields
} from '../../components/CustomPropertiesPanelButton/types';
import { IApplicationState } from '../../../../../../../types/types';
import { ICustomPropertiesPanelButtonHOCProps } from './types';
import { onClick } from './events/onClick';
import { getCardCustomProperties } from '../../../../../../../store/model/selectors/getCardCustomProperties';
import {
    getBoardCardCustomProperties
} from '../../../../../../../store/model/board/selectors/getBoardCardCustomProperties';
import { getSectionType } from '../../../selectors/getSectionType';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { ECustomPropertyType } from '../../../../../../../store/customProperties/types';
import { getShowProFeaturesTrial } from '../../../../../../../store/model/authUser/selectors/getShowProFeaturesTrial';
import { getIsUserBasicPlanOnly } from '../../../../../../../store/model/authUser/selectors/getIsUserBasicPlanOnly';

const mapStateToProps = (
    state: IApplicationState,
    { boardId, cardId, isShowTooltip }: ICustomPropertiesPanelButtonHOCProps
): ICustomPropertiesPanelButtonFields => {

    let elements = 0;
    let indicator = 0;
    const sectionType = getSectionType(state, boardId, cardId, null, 'allowCardCustomProperties');
    const isDisallow = sectionType === ESettingsSectionType.DISALLOW;
    const isShow = (
        sectionType === ESettingsSectionType.DEFAULT ||
        isDisallow
    );
    if (isShow) {
        const boardProperties = getBoardCardCustomProperties(state, boardId);
        const cardProperties = getCardCustomProperties(state, cardId);
        for (let key in boardProperties) { // найдём непустые поля
            if (cardProperties[key] &&
                cardProperties[key].value !== null &&
                cardProperties[key].value !== undefined
            ) {
                if (boardProperties[key].type === ECustomPropertyType.SELECT && (
                        !boardProperties[key].options ||
                        !boardProperties[key].options.find(option => option.id === cardProperties[key].value)
                    )
                ) {
                    // опция удалена
                } else {
                    indicator++;
                }
            }
            elements++;
        }
    }

    return {
        elements,
        indicator,
        isBasicFeature: getIsUserBasicPlanOnly(state),
        isDisallow,
        isProFeature: getShowProFeaturesTrial(state),
        isShow,
        isShowTooltip,
    }
};

const mapDispatchToProps = (
    dispatch: any,
    {boardId, cardId}: ICustomPropertiesPanelButtonHOCProps
): ICustomPropertiesPanelButtonEvents => {
    return {
        onClick: () => dispatch(onClick(boardId, cardId)),
    }
};

export const CustomPropertiesPanelButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomPropertiesPanelButton);

CustomPropertiesPanelButtonHOC.displayName = 'CustomPropertiesPanelButtonHOC';
