import {
    ERecurringTaskEndType,
    ERecurringTaskFrequencyType,
    IRestRecurringTask
} from '../../../../../../types/rest/recurringTask/IRestRecurringTask';
import { IRecurringTaskForm } from '../types';
import { ERecurringRepeatMonthType, ERecurringRepeatType } from '../constants';

export const mapFormToRestRecurringTask = (
    form: IRecurringTaskForm
): IRestRecurringTask => {
    const frequencyData = mapFrequency(form);
    if (!frequencyData) return;

    const restRecurringTask: IRestRecurringTask = {
        startDate: form.startDate ? form.startDate.getTime() : null,
        listId: form.listId,
        endType: form.endType,
    }
    if (form.endType === ERecurringTaskEndType.DATE) {
        restRecurringTask.dueDate = form.dueDate ? form.dueDate.getTime() : null;
    } else if (restRecurringTask.endType === ERecurringTaskEndType.REPEATS) {
        restRecurringTask.repeats = form.repeats;
    }
    Object.assign(restRecurringTask, frequencyData);
    return restRecurringTask;
}

const mapFrequency = (
    form: IRecurringTaskForm
): IRestRecurringTask => {
    const { repeatEvery, weekDays, monthDay, monthDayData } = form;
    switch (form.repeatType) {
        case ERecurringRepeatType.DAY:
            return {
                frequencyType: ERecurringTaskFrequencyType.DAY,
                day: {
                    dayCount: repeatEvery
                }
            };
        case ERecurringRepeatType.WEEK:
            return {
                frequencyType:  ERecurringTaskFrequencyType.WEEK,
                week: {
                    weekCount: repeatEvery,
                    selectedDaysOfWeek: weekDays
                }
            };
        case ERecurringRepeatType.YEAR:
            return {
                frequencyType: ERecurringTaskFrequencyType.YEAR,
                year: {
                    yearCount: repeatEvery
                }
            };
        case ERecurringRepeatType.MONTH: {
            if (monthDay === ERecurringRepeatMonthType.DAY_OF_MONTH) {
                return {
                    frequencyType: ERecurringTaskFrequencyType.DAY_OF_MONTH,
                    dayOfMonth: {
                        monthCount: repeatEvery,
                        dayOfMonth: monthDayData ? monthDayData.dayOfMonth : 0
                    }
                }
            }
            if (form.monthDay === ERecurringRepeatMonthType.DAY_OF_WEEK_IN_MONTH) {
                return {
                    frequencyType: ERecurringTaskFrequencyType.DAY_OF_WEEK_IN_MONTH,
                    dayOfWeekInMonth: {
                        monthFrequency: repeatEvery,
                        dayOfWeek: monthDayData ? monthDayData.dayOfWeek : 0,
                        dayOfWeekPosition: monthDayData ? monthDayData.dayOfWeekPosition : 0
                    }
                }
            }
        }
    }
    return null;
}
