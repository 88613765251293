import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { IGetState, TBoardId } from '../../../../../../../../../types/types';
import { SegmentBoardEvent, segmentTrackAction } from '../../../../../../../../../middlewares/segment';
import { boardSetCardAgingMeta } from '../../../../../../../../../rest/effects/board/boardSetCardAgingMeta';
import { getBoardMetaCardAging } from '../../../../../../../../../store/model/selectors/getBoardMetaCardAging';
import { ECardAgingIndicatorType } from '../../../../../../../../../types/rest/ICardAging';
import {
    SegmentBoardOptionStatusValue
} from '../../../../../../../../../middlewares/segment/trackEntities/boardEvents';
import { getAuthUser } from '../../../../../../../../../store/model/authUser/selectors/getAuthUser';
import { showSnoozeBlocker } from '../../../../../../../base/effects/showSnoozeBlocker';
import { root } from '../../../../../../../../../store/constants';

export const onChange = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const { allowCardAgeing } = getAuthUser(state);
        const cardAging = getBoardMetaCardAging(state, boardId);
        const enabled = !cardAging || !cardAging.enabled;
        if (!allowCardAgeing && enabled) {
            dispatch(showSnoozeBlocker({ allow: 'allowCardAgeing' }));
        } else if (
            !allowCardAgeing &&
            !enabled &&
            root.App.controller.allowCounterSnackbarHelper.isShowSnackbar('allowCardAgeing')
        ) {
            return Promise.resolve(false);
        } else {
            const updatedCardAging = {
                ...cardAging,
                cardAgingSettings: cardAging && cardAging.cardAgingSettings ? cardAging.cardAgingSettings : {
                    indicator: {
                        enabled: true,
                        indicatorType: ECardAgingIndicatorType.BY_ACTIVITY
                    }
                },
                enabled
            };
            dispatch(segmentTrackAction(SegmentBoardEvent.CARD_AGING_CLICKED, {
                name: 'status',
                value: enabled ? SegmentBoardOptionStatusValue.ON : SegmentBoardOptionStatusValue.OFF
            }));
            return dispatch(boardSetCardAgingMeta(boardId, updatedCardAging)).then(() => {
                return Promise.resolve(true);
            });
        }
    };
    return action;
};
