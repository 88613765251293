import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TBoardId, TCardId } from '../../../../../../../../types/types';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { getCommentModel } from '../../../helpers/commentModelHelper';
import { commentUpdate } from '../../../../../../../../rest/effects/card/comments/commentUpdate';
import { getEditCommentId } from '../../../selectors/getEditCommentId';
import { getCommentById } from '../../../selectors/getCommentById';
import { IDriveDoc } from '../../../../../../../../store/model/card/types/IDriveDoc';
import { IComment } from '../../../../../../../../store/model/card/types/IComment';
import { addAttachments } from '../../../../../../../../rest/effects/card/attacments/addAttachments';
import { TDriveDocId } from '../../../../../../../../types/rest/IRestDriveDoc';
import Util from 'app/util/util';
import { getAuthUser } from '../../../../../../../../store/model/authUser/selectors/getAuthUser';
import { showSnoozeBlocker } from '../../../../../../base/effects/showSnoozeBlocker';
import { isTimeTracker } from '../selectors/isTimeTracker';
import { root } from '../../../../../../../../store/constants';

export const onSaveChanges = (
    boardId: TBoardId,
    cardId: TCardId,
    text: string,
    attachments: IDriveDoc[]
): ThunkAction => {
    return (
        dispatch: Dispatch,
        getState
    ) => {
        const state = getState();
        const commentId = getEditCommentId(state);
        const comment = getCommentById(state, cardId, commentId);
        const { allowTimeTracker } = getAuthUser(state);
        if (!allowTimeTracker && isTimeTracker(comment.text)) {
            dispatch(showSnoozeBlocker({ allow: 'allowTimeTracker' }));
            return;
        }

        let attachmentsOld: IDriveDoc[] = [];
        let attachmentsNew: IDriveDoc[] = [];

        if (attachments && attachments.length) {
            attachments.forEach(attachment => {
                if (attachment.id) {
                    attachmentsOld.push(attachment);
                } else {
                    attachmentsNew.push(attachment);
                }
            });
        }

        if (attachmentsNew.length) {
            return dispatch(addAttachments(cardId, attachmentsNew))
                .then((docs: IDriveDoc[]) => {
                    return dispatch(onCommentEdit(
                        boardId, cardId, text,
                        [...attachmentsOld, ...docs].map(doc => doc.id),
                        comment
                    ));
                });
        } else {
            return dispatch(onCommentEdit(boardId, cardId, text, attachmentsOld.map(doc => doc.id), comment));
        }
    };
};

const onCommentEdit = (
    boardId: TBoardId,
    cardId: TCardId,
    text: string,
    driveDocIds: TDriveDocId[],
    comment: IComment
): ThunkAction => {
    return (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        root.App.controller.cardsCommentsInfo.resetCardComment(comment.cardId);
        const { id, meta } = comment;
        text = Util.setAuthuser0(text).trim();
        const commentEdited = getCommentModel(getState(), boardId, text, driveDocIds);
        const updatedCommentModel = {
            id,
            cardId,
            ...commentEdited,
            meta: {
                ...commentEdited.meta,
                replyCommentId: meta && meta.replyCommentId
            }
        };

        dispatch(commentUpdate(updatedCommentModel, boardId));
        dispatch(segmentTrackAction(SegmentCardEvent.CLICKED_SAVE_SHANGES_ON_COMMENT));

        return Promise.resolve(id);
    };
}
