import { INotificationSettings } from '../types';
import { AT_UPDATE, IUpdateAction } from './types';

export const updateAction = (
    notification: INotificationSettings
): IUpdateAction => {
    return {
        type: AT_UPDATE,
        notification
    }
};
