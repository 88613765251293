import { IApplicationState, TBoardId } from '../../../types/types';
import { ERoutes } from '../constants';

export const getActiveRouteBoardId = (
    state: IApplicationState
): TBoardId => {
    const { route, args } = state.router;
    if (
        route !== ERoutes.BOARD_EDIT &&
        route !== ERoutes.BOARD_EDIT_SCROLL &&
        route !== ERoutes.BOARD_ACTIVITY &&
        route !== ERoutes.BOARD_FILTER &&
        route !== ERoutes.BOARD_DESIGN &&
        route !== ERoutes.BOARD_BACKUPS &&
        route !== ERoutes.BOARD_EXPORT &&
        route !== ERoutes.BOARD_COPY &&
        route !== ERoutes.CARD_TEMPLATES &&
        route !== ERoutes.RECURRING_TASKS &&
        route !== ERoutes.EDIT_CARD &&
        route !== ERoutes.CARD_TIMING &&
        route !== ERoutes.CARD_ACTIVITY &&
        route !== ERoutes.CARD_PRINT &&
        route !== ERoutes.SELECT_CARDS &&
        route !== ERoutes.LIST_PRINT_PREVIEW &&
        route !== ERoutes.EDIT_LIST &&
        route !== ERoutes.BOARD
    ) return null;

    const boardId = args[1];
    if (!boardId) return null;

    return parseInt(boardId);
}
