import { getMessages } from '../../../../../../../../store/constants';

export const BOARD_LOGO_LOGO = getMessages().getText('board_settings.logo.logo');
export const BOARD_LOGO_MAX_SIZE = getMessages().getText('board_settings.logo.max_size');
export const BOARD_LOGO_UPDATING = getMessages().getText('board_settings.logo.updating');
export const BOARD_LOGO_CHANGE = getMessages().getText('board_settings.logo.change');
export const BOARD_LOGO_UPLOAD = getMessages().getText('board_settings.logo.upload');
export const BOARD_LOGO_REMOVE = getMessages().getText('board_settings.logo.remove');
export const BOARD_LOGO_EMPTY = getMessages().getText('common.empty');
export const BOARD_LOGO_IMAGE = getMessages().getText('board_settings.logo.image');
export const BOARD_LOGO_ICON = getMessages().getText('board_settings.logo.icon');
export const BOARD_LOGO_RESET = getMessages().getText('board_settings.logo.reset');
