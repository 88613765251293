'use strict';
import ModalDialogRenderer from './view/dialog/modal_dialog_renderer';
import store, { dispatch } from './store/configureStore';
import DeleteAccountRenderer from './view/dialog/delete_account_dialog';
import { SegmentFeedbackEvent, segmentTrackAction } from "./middlewares/segment";
import { ESignInErrorStatus } from './view/react_components/dialogs/signIn';
import { BlockerHelper } from './helper/blocker_helper';
import { getActiveCardId } from './store/router/selectors/getActiveCardId';
import { DASHBOARD_LIMIT, REDIRECT_WINDOW_HISTORY_LENGTH } from './const';
import { CLASS_ASIDE_PANEL_OPENED } from "./view/react_components/aside_panel/asidePanel/constants";
import { BoardLoadManager } from './helper/BoardLoadManager';
import { getActiveBoard } from './store/model/selectors/getActiveBoard';
import { getActiveBoardId } from './store/model/selectors/getActiveBoardId';
import { activeBoardIdActionSet } from './store/model/actions/activeBoardIdActionSet';
import { getTabIds } from './store/model/selectors/getTabIds';
import { updateUser } from './store/model/authUser/effects/updateUser';
import { getAuthUser } from './store/model/authUser/selectors/getAuthUser';
import { loadUser } from './rest/effects/authUser/loadUser';
import { SegmentWebinarEvent } from "./middlewares/segment/trackEntities/webinarEvents";
import {
    KeyboardShortcutsView
} from './view/react_components/keyboardShortcuts/view/KeyboardShortcutsView/KeyboardShortcutsView';
import { SocketFactory } from "./helper/realtime/ts/SocketFactory";
import { DashboardRouter } from './router/router';
import * as _ from 'underscore';
import { getUserIsOpenLastBoard } from './store/model/authUser/selectors/getUserIsOpenLastBoard';
import { getUserRecentBoards } from './store/model/authUser/selectors/getUserRecentBoards';
import { getCurrentRoute } from './store/router/selectors/getCurrentRoute';
import { EPanelRoute, ERoutes } from './store/router/constants';
import { getActiveRouteBoardId } from "./store/router/selectors/getActiveRouteBoardId";
import { LastActivityHelper } from "./helper/last_activity_helper";
import { getBoardCardsMap } from './store/model/selectors/getBoardCardsMap';
import { cardFullGetRest } from './rest/effects/card/card/api/helper/cardFullGetRest';
import { BoardTabsLoader } from "./helper/loader/boardTabsLoader";
import { root } from "./store/constants";
import { openHelpPanel } from './view/react_components/base/effects/openHelpPanel';
import { getLinkCardBoardId } from "./store/router/selectors/getLinkCardBoardId";
import { getLinkCardId } from "./store/router/selectors/getLinkCardId";
import { isCookiePerformanceAllowed } from "app/view/react_components/dialogs/cookies";
import { getUserLoginSpecificBoard } from "app/store/model/authUser/selectors/getUserLoginSpecificBoard";
import { CommonAuthManager } from "app/helper/authorisation/common/CommonAuthManager";
import { BoardLoaderInfoHelper } from "./helper/board_loader_info_helper";
import { Tutorials } from "./view/react_components/reactToBackbone/Tutorials";
import { authUserSetTutorialWatched } from "./rest/effects/authUser/authUserSetTutorialWatched";
import { getAuthUserTutorialWatched } from "./store/model/authUser/selectors/getAuthUserTutorialWatched";
import { EAuthUserPlatformType } from './types/rest/IRestAuthUser';
import { snackbarPromtDefault } from './view/react_components/snackbarsStack';
import { UpdatesView } from './view/react_components/updates/UpdatesView';
import {
    UpdatesModalView
} from './view/react_components/dialogs/updatesModal/components/UpdatesModalView/UpdatesModalView';
import {
    UpdatesTipsView
} from './view/react_components/dialogs/updatesModal/components/UpdatesTipsView/UpdatesTipsView';
import { getSlidesForUpdatesTips } from './view/react_components/updates/updates';
import {
    getIsNewFeatureTopbarRedesignTips
} from './store/model/authUser/selectors/featuresSeen/getIsNewFeatureTopbarRedesignTips';
import { AllowCounterSnackbarHelper } from "./helper/allow_counter_snackbar_helper";
import { getEditCommentId } from "./store/router/selectors/getEditCommentId";
import { BoardCardPrefixInfoHelper } from "./helper/board_card_prefix-info_helper";
import { authUserSetPromoCode } from "./rest/effects/authUser/authUserSetPromoCode";
import { getUserPromoCode } from "./store/model/authUser/selectors/getUserPromoCode";
import { MagicLinksFakeLinksHelper } from "./helper/magic_links_fake_links_helper";
import { CardsCommentInfoHelper } from "./helper/cards_comment_info_helper";



var App = window.App;
var Messages = window.Messages;
var Backbone = window.Backbone;
App.Views.Controller = Backbone.View.extend({
    initialize: function(options) {
        App.model = new Backbone.Model();
        App.statHelper = new App.Classes.StatHelper();
        App.router = null;

        App.vent.on(App.vent['ajax:error'], this.handleAjaxError, this);
        this.gaEnabled = options.gaEnabled;
        this.acceptLanguage = options.acceptLanguage;

        this.region = options.region;
        this.country = options.country;
        this.city = options.city;
        this.beforeUserInit();
        this.initUser((user => this.afterInitUser(user)));
        this.signInError = false;
        this.allowReadOnly = false;
        this.lastActivity = new LastActivityHelper();
        this.isLoadingLastBoard = null;
        this.boardLoaderInfo = new BoardLoaderInfoHelper();
        this.allowCounterSnackbarHelper = new AllowCounterSnackbarHelper();
        this.boardCardPrefixInfo = new BoardCardPrefixInfoHelper();
        this.magicLinksFakeLinksHelper = new MagicLinksFakeLinksHelper();
        this.cardsCommentsInfo = new CardsCommentInfoHelper();
    },

    eraseCookies: function() {
        const unusedCookies = [
            '_hjClosedSurveyInvites',
            '_hjDonePolls',
            '_hjMinimizedPolls',
            '_hjDoneTestersWidgets',
            '_hjMinimizedTestersWidgets',
            '_hjIncludedInSample',
            '_hjShownFeedbackMessage',
            '_hjUUID',
            '_hjid'
        ];
        App.Util.eraseCookies(unusedCookies, '.' + location.hostname);
    },

    beforeUserInit: function() {
        this.eraseCookies();

        this.mainView = new App.Views.MainView({ parent: this });
        this.permissionsDialog = new App.Views.PermissionsDialogRenderer({mainView: this.mainView});
        this.dashboardsView = new App.Views.Dashboards({});
        this.modalDialog = new ModalDialogRenderer();
        this.secondPanel = new App.Views.SecondPanelRenderer();
        this.messageDialog = new App.Views.MessageDialogRenderer();
        this.printDialog = new App.Views.PrintDialogRenderer();
        this.boardLoadManager = new BoardLoadManager();
        this.blockerHelper = new BlockerHelper();
        this.mainView.renderSnackbarsStack();
        this.mainView.renderAsidePanel();
        this.mainView.renderLeftPanel();
        this.asidePanel = this.mainView.asidePanel;
        this.leftAsidePanel = this.mainView.leftAsidePanel;
    },

    insertPerformance: function() {
        this.mainView.insertPerformance();
    },

    afterInitUser: function(options){
        const authUser = getAuthUser(store.getState());
        try {

            if (!authUser.testMode) {
                if (authUser.platformType === EAuthUserPlatformType.GOOGLE) {
                    gapiLoaded.then(()=>{
                        gapi.load('client,drive-share,picker',
                            ()=>{
                                gapi.client.init({
                                })
                                    .catch((e)=>{
                                        CommonAuthManager.onSignInError(e);
                                    });
                                const isCookieAllowed = isCookiePerformanceAllowed();
                                if (isCookieAllowed) {
                                    App.controller.insertPerformance();
                                }
                                gapi.client.load('calendar', 'v3');
                                CommonAuthManager.trySignInOrShowLoginScreen();

                            })
                    })
                } else {
                    CommonAuthManager.trySignInOrShowLoginScreen();
                }
            } else {
                CommonAuthManager.onSignedFinishedForTestMode();
            }




        } catch (e) { // ms сюда по идее не упадёт, т.к. не надо загружать gapi
            CommonAuthManager.onSignInError({
                details: Messages.getText("error.signIn.googleBlocked"),
                status: ESignInErrorStatus.GAPI_CONNECT
            });
        }
        App.Helpers.StartupProcessor.init();
        this.mainView.renderUpgradeButton();
        if (authUser.testMode) {
            this.mainView.renderTestMode();
        }
        this.mainView.renderFreeTrialFormIfNeeded();
    },

    renderPolls: function() {
        this.mainView.renderKanbanPoll();
        this.mainView.renderFeaturePoll();
        this.mainView.renderMessengerPoll();
        this.mainView.renderPricingPoll();
    },

    renderPromoCodePopup: function() {
        this.mainView.renderPromoCodePopup();
        dispatch(authUserSetPromoCode({ ...getUserPromoCode(store.getState()), isShowed: true }));
    },

    hidePromoCodePopup: function() {
        this.mainView.hidePromoCodePopup();
    },

    getUMUXEnabled: function() {
        return this.mainView.getUMUXEnabled();
    },

    getMessengerPollEnabled: function() {
        return this.mainView.getMessengerPollEnabled();
    },

    removeStart: function() {
        const startDiv = document.getElementById('start');
        if (startDiv) {
            startDiv.remove();
        }

        const tipsEl = document.querySelector('.start-tips');
        if (tipsEl) { // на старте и на доске разный вид типсов
            tipsEl.classList.add('loaded');
        }
    },

    initUser: function(callback){
        App.statHelper.getCurrentTimeLag();
        dispatch(loadUser())
            .then(response => {
                if (response.anonym) response.photoUrl = ''; // не запоминать фото анонима, а то в мс аккаунте потом ничего не приходит, и остаётся это
                dispatch(updateUser(response));
                callback(response);
            })
            .catch((e) => {
                console.debug(e);
                this.signInError = true;
                CommonAuthManager.onSignInError({
                    details: Messages.getText("error.signIn.internal"),
                    status: ESignInErrorStatus.INTERNAL
                });
            })
    },

    start: function() {
    	if (!this.notStart){
	        this.initRouter();
            SocketFactory.getInstance().getSocketProvider();
            this.notStart = true;
    	}
    },

    loadBoardTabsIfNotLoaded: function(boardIds, onLoad) {
        var callback = _.bind(function() {
            this.boardLoadManager.loadBoardTabsIfNotLoaded(boardIds)
                .then((loadedBoardIds) => onLoad(loadedBoardIds))
        }, this);
        this.blockerHelper.checkBlockAndRun(callback);
    },

    checkBlockAndRun: function(callback) {
        this.blockerHelper.checkBlockAndRun(callback);
    },

    initRouter: function() {
        if (!App.router) {
            App.router = new DashboardRouter({
                controller: this
            });
            App.router.start();
            setTimeout(() =>this.removeStart(), 300); // KNB-3524
            this.startUp();
        }
    },

    startUp: function() {
        const state = store.getState();
        const route = getCurrentRoute(state);
        if (
            window.history.length < REDIRECT_WINDOW_HISTORY_LENGTH &&
            (
                route === ERoutes.EDIT_CARD ||
                route === ERoutes.CARD_TIMING ||
                route === ERoutes.CARD_ACTIVITY ||
                route === ERoutes.LINK_CARD ||
                route === ERoutes.LINK_CARD_TIMING ||
                route === ERoutes.LINK_CARD_ACTIVITY
            )
        ) {
            const boardId = getActiveRouteBoardId(state) || getLinkCardBoardId(state.router);
            const boardTabsLoader = new BoardTabsLoader([boardId]);
            boardTabsLoader
                .load()
                .then((boards) => {
                    if (boards.length) { // user has permission
                        const cardId = getActiveCardId(state) || getLinkCardId(state.router);
                        let action = '';
                        const commentId = getEditCommentId(state);
                        if (route === ERoutes.CARD_TIMING) {
                            action = EPanelRoute.CARD_TIMING;
                        } else if (route === ERoutes.CARD_ACTIVITY) {
                            action = EPanelRoute.TIMING;
                        } else if (commentId) {
                            action = 'cmt-' + commentId;
                        }
                        App.router.navigate(App.router.getLinkCardUrl(boardId, cardId, action), { trigger: true });
                    } else {
                        root.App.controller.mainView.hideCardLink();
                        this.closeAsidePanel();
                        App.router.navigate(App.router.getUrl(), { trigger: true });
                        this.boardLoadManager.showError(boardId);
                    }
                });
        } else {
            if (route === ERoutes.INDEX) {
                const isOpenLastBoard = getUserIsOpenLastBoard(store.getState());
                if (isOpenLastBoard) {
                    const recentBoards = getUserRecentBoards(store.getState());
                    if (recentBoards.length) {
                        this.isLoadingLastBoard = true;
                        App.router.navigate(App.router.getBoardUrl(recentBoards[0].id, ''), {trigger: true});
                        setTimeout(()=>this.mainView.showHintOpenedLastBoard(), 1000);
                    }
                } else {
                    const specificBoard = getUserLoginSpecificBoard(store.getState());
                    if (specificBoard && !!specificBoard.id) {
                        App.router.navigate(App.router.getBoardUrl(specificBoard.id, ''), {trigger: true});
                    }

                }
            }
        }
    },

    openUserSettings: function() {
        this.modalDialog.showOpenSettings();
    },

    openKeyboardShortcuts: function() {
        const keyboardShortcutsView = new KeyboardShortcutsView();
        keyboardShortcutsView.render();
    },

    openBoard: function(id, keepUrl) {
        return this.boardLoadManager.openBoard(id, keepUrl);
    },

    loadBoardArchiveToStore: function(boardId) {
        return this.boardLoadManager.loadBoardArchiveToStore(boardId);
    },

    loadBoardToStore: function(boardId) {
        return this.boardLoadManager.loadBoardToStore(boardId);
    },

    loadBoardForDashboardToStore: function(boardId, noCards = false, noError = false, noLoading = false, ignoreDeleteStatus = false, ignoreRecentBoards = false) {
        return this.boardLoadManager.loadBoardForDashboardToStore(boardId, noCards, noError, noLoading, ignoreDeleteStatus, ignoreRecentBoards);
    },

    closeBoards: function() {
        return this.boardLoadManager.closeBoards();
    },

    setAsidePanelMouseDown: function(value) {
        this.mainView.setAsidePanelMouseDown(value);
    },

    isLeftAsideOpened: function() {
        return this.leftAsidePanel.isOpened();
    },

    openHelp: function() {
        dispatch(openHelpPanel());
        this.hideSystemLoader();
    },

    showRelatedCardsPanel: function(cardId, boardId) {
        this.asidePanel.showRelatedCardsPanel(cardId, boardId);
    },

    openUpdates: function() {
        this.updatesView = new UpdatesView(store);
        this.hideSystemLoader();
    },

    closeCardPanel: function(cardId) {
        const activeCardId = getActiveCardId(store.getState());
        if (activeCardId === cardId) App.vent.trigger(App.vent['aside-panel:hide']);
    },

    openListPrintPreview: function(listId) {
        this.printDialog.showListPrintPreview(listId);
    },

    clearActiveBoard: function() {
        const activeBoardId = getActiveBoardId(store.getState());
        if (activeBoardId) {
            App.vent.trigger(App.vent['dashboard:deactivated'], activeBoardId);
            dispatch(activeBoardIdActionSet(null));
            App.router.setCurrentDashboard(null, false);
        }
    },

    showMainPanel: function() {
        this.closeAsidePanel();
        this.printDialog.hide();
        this.leftAsidePanel.hide(false);
        this.secondPanel.hide();
        this.mainView.blockerClassToggle();
    },

    closeAsidePanel: function() {
        this.asidePanel.hide(false);
    },

    showOpenDashboardDialog: function(tabKey = null) {
        this.hideSystemLoader();
        const authUser = getAuthUser(store.getState());
        if (!authUser.firstVisit) {
            this.mainView.openBoardsDialogShow(tabKey);
        }
    },

    openSubscriptionManagement: function(subscriptionId = null) {
        this.modalDialog.showSubscriptionManagement(subscriptionId);
    },

    showUpdatesModal: function(updates) {
        this.mainView.onHideStartTips();
        if (this.updatesModalView) this.updatesModalView.remove();
        this.updatesModalView = new UpdatesModalView(store, updates);
    },

    showUpdatesTips: function(slides, isFirstShow = false) {
        if (this.updatesTipsView) this.updatesTipsView.remove();
        this.updatesTipsView = new UpdatesTipsView(store, slides, isFirstShow);
    },

    showUpdatesTipsKNB2502Topbar: function () {
        if (getIsNewFeatureTopbarRedesignTips(store.getState())) {
            let waitinterval = setInterval(() => {
                if (
                    !document.querySelector('.aside-panel--opened') &&
                    !document.querySelector('.start-tips:not(.hide)') &&
                    getIsNewFeatureTopbarRedesignTips(store.getState())
                ) {
                    this.showUpdatesTips(getSlidesForUpdatesTips('2023-10-4', 'TOPBAR_REDESIGN'), true);
                    clearInterval(waitinterval);
                }
            }, 3000);
        }
    },

    showLanguageFeatureModalIfNeeded: function () {
        const user = getAuthUser(store.getState());
        if (user.country === 'BR') {
            this.secondPanel.showLanguageFeatureModal();
            this.mainView.onHideStartTips();
        }
    },

    showShareBoardModal: function () {
        setTimeout(() => {
            const openedBoard = getActiveBoard(store.getState());
            if (openedBoard && !openedBoard.shared) {
                this.secondPanel.showShareBoardModal(openedBoard.id);
            }
        }, 1000 * 60 * 2); // 2 min
    },

    sendJoinWebinarEvent: function() {
        dispatch(segmentTrackAction(SegmentWebinarEvent.JOIN_WEBINAR_CLICKED));
    },

    showTutorialVideos: function() {
        this.showUpdatesModal([{
            media: 'https://www.youtube-nocookie.com/embed/videoseries?list=PLeKebXXFFKM51iAjj5CYIY-W9JBGAMqYF&index=0&rel=0',
            type: 'video',
            title: 'Learn the basics in 8 minutes',
            text: 'In these videos, you will find step-by-step guidance on how to work with Kanbanchi',
        }]);
    },

    showPromoCodeForm: function() {
        this.hideSystemLoader();
        this.modalDialog.showPromoCodeForm();
        this.trackEvent(
            Messages.getText('ga.category.payment'),
            Messages.getText('ga.action.promo_code_form_opened')
        );
    },

    showGsuiteAdminForm: function() {
        this.hideSystemLoader();
        this.modalDialog.showGsuiteAdminForm();
        this.trackEvent(
            Messages.getText('ga.category.payment'),
            Messages.getText('ga.action.promo_code_form_opened')
        );
    },

    showGoogleSpreadsheetPermissionsPage: function() {
        this.hideSystemLoader();
        this.modalDialog.showGoogleSpreadsheetPermissionsPage(App.model.get('authUser'));
        this.trackEvent(
            Messages.getText('ga.category.payment'),
            Messages.getText('ga.action.promo_code_form_opened')
        );
    },

    showBannerPromoCode: function(code) {
        this.showPromoCodeForm();
        App.router.navigate('promo');
    },

    showMicrosoftGroupPermission: function() {
        this.mainView.showMicrosoftGroupPermission();
    },

    getDefaultAlertButtons: function(request, url) {
        return [{
            text: Messages.getText('error.button.feedback'),
            click: _.bind(function() {
                App.controller.showHelpPopup({
                    alert: true,
                    request,
                    url
                });
            }, this)
        }];
    },

    handleAjaxError: function(jqxhr, settings) {
        if(this.signInError) return;
        var url = settings.url;

    	var internalError = url.indexOf("/rest/") == 0; // not Google services error
        var status = parseInt(jqxhr.status);
        var alertMessagePrefix = Messages.getText('error.ajax.prefix');
        var alertMessage = jqxhr.responseJSON && jqxhr.responseJSON.message && status != 530 ?
                        jqxhr.responseJSON.message : App.Util.getHttpStatusMessage(status);
        var alertBtns = this.getDefaultAlertButtons(jqxhr, url);
        switch (status) {
        case 200: //server returned empty response, but backbone expects at least an empty object: {}
            if (console) {
                console.log('Empty response:', jqxhr, jqxhr);
            }
            break;
        case 400:
        	//CODE_REFRESH_TOKEN
        	if(jqxhr.responseJSON.code == 1){
        		alertMessage =Messages.getText('error.button.reinstall.message');
	            alertBtns.push({
	                text: Messages.getText('error.button.reinstall'),
	                click: function() {
                            location.href = "http://www.kanbanchi.com/faqwd/reinstallation"
	                    }
	            });
	            break;
        	}
        case 401:
        case 403:
            if(internalError) {
                CommonAuthManager.loginWithRememberMeOrShowLoginScreen().then(() => {
                    console.error(settings);
                    $.ajax(settings)
                });
            }
            return;
        case 404:
            break;
        case 409:
        	settings.count = settings.count || 0;
        	settings.count++;
        	// try 15 steps
        	if(settings.count>15){
        	    if(window.Settings.development){
        	        break;
                }else{
                    console.log("try 15 but fail: " + alertMessage);
                    return;
                }
        	}
            _.debounce(function(){ $.ajax(settings) }, settings.count>2?2000:1000)();
            return;
        case 422:  // normal validation error; let the client logic process it
            return;
        default:
            alertBtns.push({
                text: Messages.getText('error.button.reload_page'),
                click: function() { location.reload(); }
            });
            break;
        }
        if (_.isNumber(status)) {
            if (internalError) {
                App.controller.trackEvent(Messages.getText('ga.category.ajax_error'), status, 'Code',
                                (jqxhr.responseJSON ? jqxhr.responseJSON.code : 'Unknown'));
            } else {
                App.controller.trackEvent(Messages.getText('ga.category.ajax_error'), 'External ' + status);
            }
        }
        if(alertMessage == 'Unknown reason' && url.indexOf("/rest/error") == -1 && status > 0){
        	App.Util.logError(status, jqxhr);
        }
        if(status > 0){
            this.messageDialog.showAlert({
                message: alertMessagePrefix + alertMessage,
                type: 'error',
                buttons: alertBtns
            });
        }
    },

    showError: function(message, header) {
        App.controller.hideSystemLoader();
        this.messageDialog.showAlert({
            message: message,
            header: header,
            type: 'error'
        });
    },

    showWarning: function(message, header, onClose, onOkClose) {
        this.messageDialog.showAlert({
            message: message,
            header: header,
            type: 'warning',
            onClose: onClose,
            onOkClose: onOkClose
        });
    },

    showInfo: function(message, header) {
        this.messageDialog.showAlert({
            message: message,
            header: header,
            type: 'info'
        });
    },

    showBlockingProgress: function(message, header) {
        this.messageDialog.showAlert({
            message: message,
            header: header,
            type: 'progress'
        });
    },

    showAffiliate: function(source) {
        this.messageDialog.showAffiliate(source);
    },

    showCopyLinkDialog: function() {
        this.messageDialog.showCopyLinkDialog();
    },

    activateReadOnly: function() {
        this.allowReadOnly = true;
        this.secondPanel.hide();
        this.mainView.blockerClassToggle();
        App.router.restart();
    },

    activateFreeVersion: function() {
        this.secondPanel.hide();
        this.mainView.blockerClassToggle();
        App.router.restart();
    },

    hideMessageDialog: function() {
        this.messageDialog.hide();
    },

    showAlert: function(options) {
        this.hideSystemLoader();
        this.messageDialog.showAlert(options);
    },

    showImport: function() {
        this.messageDialog.showImport();
    },

    showInvite: function() {
        this.messageDialog.showInvite();
    },

    showPermissionDialog: function(options) {
        this.hideSystemLoader();
        this.permissionsDialog.show(options);
    },

    /**
     * Показывает в "верхнем" модельном окне панель зависимых карт
     */
    // showRelatedDialog: function(options) {
    //     this.secondPanel.showRelatedCards(options);
    // },

    /**
     * Возвращает открыта ли панель релейтед кардс
     * @returns boolean
     */
    isRelatedPanelNotOpened: function() {
        return !(this.mainView.$el.hasClass(this.mainView.constructor.relatedClass));
    },

    isAsidePanelOpened: function () {
        return this.asidePanel.$el.hasClass(CLASS_ASIDE_PANEL_OPENED)
    },

    /**
     * Вешает системный лоадер
     */
    showSystemLoader: function(force, showTips, showProgress) {
        this.mainView.showLoader(force, showTips, showProgress);
    },

    setFirstTimeLogin: function() {
        this.mainView.setLoginStorageTime(new Date().getTime() - 1000);
    },

    /**
     * Убираем системный лоадер
     */
    hideSystemLoader: function() {
        this.mainView.hideLoader();
    },

    /**
     * Показывает в "верхнем" модальном окне блокер
     */
    showBlocker: function(options, isSnooze) {
        this.secondPanel.showBlocker(options, isSnooze);
    },

    /**
     * Возвращает дешборд менеджер ативной доски
     */
    getDashboardViewManager: function(board) {
        if (!board) board = getActiveBoard(store.getState());
        return this.dashboardsView.getDashboardViewManager(board);
    },

    showPayment: function(eventOptions, noNavigateOnBack) {
        this.hideSystemLoader();
        this.modalDialog.showPricing(eventOptions, noNavigateOnBack);
        this.trackEvent(
            Messages.getText('ga.category.subscription'),
            Messages.getText('ga.action.subscription_form_opened')
        );
    },

    getNotificationHelper: function () {
        return this.mainView.getNotificationHelper();
    },

    /**
     * Возвращает размер боковой панельки
     */
    getAsidePanelWidth: function() {
        return this.mainView.getAsidePanelWidth();
    },

    showSplashScreen: function() {
        this.mainView.showSplashScreen();
    },

    showPermissionsFAQModal: function () {
        this.mainView.showPermissionsFAQModal();
    },

    closePermissionsFAQModal: function () {
        this.mainView.closePermissionsFAQModal();
    },
    getMainPanelWidth: function() {
       return this.mainView.getMainPanelWidth();
    },

    removeSplashScreen: function() {
        this.mainView.removeSplashScreen();
    },

    showHelpPopup: function(options) {
        dispatch(segmentTrackAction(SegmentFeedbackEvent.FB_SHOW));
        this.mainView.showHelpPopup(options);
    },

    showTimeTrackerDialogView: function(options) {
        this.modalDialog.showTimeTrackerDialogView(options);
    },

    showTimeTrackerActionsView: function(options) {
        this.modalDialog.showTimeTrackerActionsView(options);
    },

    renderMagicLink: function(options, selector) {
        this.mainView.renderMagicLink(options, selector);
    },

    showMyWork: function() {
        this.clearActiveBoard();
        this.mainView.showMyWork();
    },

    myWorkFilterBarCollapseToggle: function() {
        this.mainView.myWorkFilterBarCollapseToggle();
    },

    /**
     * Умное открытие карты
     * 1. если доска уже загружена - открывает карту
     * 2. если не загружена, и открыто меньше 4 табов, загружает доску и открывает
     * 3. если не загружена и нет места, спрашивает что сделать.
     */
     cleverOpenCard: function(boardId, cardId) {
        App.vent.trigger(App.vent['modal:closed']);
        const tabIds = getTabIds(store.getState());
        if (!tabIds.includes(boardId) && tabIds.length >= DASHBOARD_LIMIT) {
            App.controller.showAlert({
                message: Messages.getText('popup.dashboard.no_slots_for_copied'),
                header: Messages.getText('popup.dashboard.limit.exceeded.header'),
                type: 'question',
                buttons: [
                    {
                        text: Messages.getText('popup.dashboard.open_copied_in_new_tab'),
                        click: function() {
                            App.vent.trigger(App.vent['dialog:closed']);
                            window.open(App.router.getCardPageUrl(boardId, cardId));
                        }
                    },
                    {
                        text: Messages.getText('popup.dashboard.limit.exceeded.button.cancel'),
                        click: function() {
                            App.vent.trigger(App.vent['dialog:closed']);
                        }
                    }
                ]
            });
        } else {
            App.router.navigate(App.router.getBoardUrl(boardId, 'c-'+ cardId), { trigger: true });
        }
    },

    showAuthView: function(callback) {
        window.history.pushState(null, 'Registration', '/dashboard/registration');
        this.trackPage();
        this.mainView.onHideStartTips();
        this.mainView.showRegistration(callback);
    },

    blockInterface: function() {
        this.blockerHelper.blockInterface();
    },

    isUserBlocked: function() {
        return this.blockerHelper.isUserBlocked();
    },

    deleteAccount: function(){
        if (!this.deleteAccountDialog) this.deleteAccountDialog = new DeleteAccountRenderer({store});
        this.deleteAccountDialog.render();
    },

    trackPage: function(page = location.pathname) {
        if (this.gaEnabled) {
            try {
                ga('send', {hitType: 'pageview', page});
            } catch (e) {
                console.error('ga not approved');
            }
            try {
                gtag('event', 'page_view', {
                    page_location: page,
                });
            } catch (e) {
                console.error('gtag error');
            }
        } else {
            console.log("GA pageview tracked: ", page);
        }
    },

    hideTutorial: function (){
        if (this.tutorial) {
            dispatch(authUserSetTutorialWatched(this.currentTutorial));
            this.tutorial.remove();
            this.tutorial = null;
            this.currentTutorial = null;
        }
    },

    showTutorial: function (tutorial){
        const mediaIPadWidth = 768; // $media-IPad
        if (root._innerWidth < mediaIPadWidth) return;

        const isOnboardingTutorialsEnabled = this.mainView.getOnboardingTutorialsEnabled();
        if (!isOnboardingTutorialsEnabled) return;

        const createDate = getAuthUser(store.getState()).createDateTimeStamp;
        const releaseDate = 1701374400; // Dec 1 2023
        if (createDate < releaseDate) return;

        if (getAuthUserTutorialWatched(store.getState(), tutorial)) return;

        this.hideTutorial();
        this.currentTutorial = tutorial;
        this.tutorial = new Tutorials(tutorial);
    },

    openReportFilterAside: function (boardId){
        this.asidePanel.showReportsFilterAside(boardId);
    },

    closeReportsAside: function() {
        this.asidePanel.hide();
    },

    openReportInsightsAside: function (boardId){
        this.asidePanel.showReportsInsightsAside(boardId);
    },

    htmlScrollDisabledToggle: function(flag) {
        this.mainView.htmlScrollDisabledToggle(flag);
    },

    openAccessibilityStatement: function (){
        this.mainView.openAccessibilityStatement();
    },

    showSnackbarOnedriveFileHandlerSwitchAccount: function (userId){
        dispatch(snackbarPromtDefault({
            id: 'SnackbarOnedriveFileHandlerSwitchAccount',
            text: getMessages().getText('snackbar.ms_file_handler.switch_account.text'),
            actionApply: () => {
                CommonAuthManager.signInSwitchAccount(userId).then(function () {
                    App.vent.trigger(App.vent['user:switch_account']);
                    document.location.reload();
                });
            },
            actionCancel: () => {
                App.router.navigate('', {trigger: true});
            },
            applyBtnTitle: getMessages().getText('snackbar.ms_file_handler.switch_account.button'),
        }));
    },

    trackFacebookEvent: function (method, eventName, parameters) {
        if (!window.fbq){
            return;
        }
        try {
            parameters
                ? fbq(method, eventName, parameters)
                : fbq(method, eventName);
        } catch (e) {
            console.error('facebook pixel not approved');
        }
    },

    trackFacebookCustomEvent: function (eventName, parameters) {
        this.trackFacebookEvent('trackCustom', eventName, parameters);
    },

    trackFacebookStandardEvent: function (eventName, parameters) {
        this.trackFacebookEvent('track', eventName, parameters);
    },

    trackTwitterEvent: function (method, eventName, parameters) {
        if (!window.twq){
            setTimeout(() => {
                this.trackTwitterEvent(method, eventName, parameters);
            }, 3000)
        } else {
            try {
                parameters
                    ? twq(method, eventName, parameters)
                    : twq(method, eventName);
            } catch (e) {
                console.error('twitter pixel not approved');
            }
        }
    },

    trackTwitterStandardEvent: function (eventName, parameters) {
        this.trackTwitterEvent('event', eventName, parameters);
    },

    /**
     * tracks event in Google Analytics (in live mode only)
     * @param {string} category - String, required
     * @param {string} action - String, required
     * @param {string} label - String, optional
     * @param {number} value - Number, optional
     * @return {undefined}
     */
    trackEvent: function(category, action, label, value) {
   	        // console.log("GA event tracked: ", category, ' - ', action, '; ', label, ' - ', value);
        if (this.gaEnabled) {
            try {
                ga('send', 'event', category, action, label, value);
            } catch (e) {
                console.error('ga not approved');
            }
            try {
                gtag('event', action, {
                    'event_category': category,
                    'event_label': label,
                    'value': value
                  });
            } catch (e) {
                console.error('gtag error');
            }
        } else {
            console.log("GA event tracked: ", category, ' - ', action, '; ', label, ' - ', value);
        }
        App.statHelper.trackEvent(category, action);
    },

    getReduxForLS: function() {
        const storeSrc = store.getState();
        const storeExport = {
            model: storeSrc.model,
        };
        return storeExport;
    },

    toggleGoogleThumbnailMode: function(value) {
        this.mainView.toggleGoogleThumbnailMode(value);
    },

    /**
     * загружает все карты для демо доски
     * объект из консоли надо положить в demoStore.js
     */
    exportReduxForDemo: function() {
        const activeBoardId = getActiveBoardId(store.getState());
        if (!activeBoardId) return console.log('open demo board');

        const cards = getBoardCardsMap(store.getState(), activeBoardId);
        return dispatch(cardFullGetRest(cards, false))
            .then(() => {
                const reduxForLS = JSON.parse(JSON.stringify(this.getReduxForLS()));
                for (let boardId in reduxForLS.model.dashboards) {
                    reduxForLS.model.dashboards[boardId].userMeta = {
                        isHideCardAgeingSnackbar: true,
                    };
                }
                console.log(reduxForLS);
            });
    }
});


export default App.Views.Controller;
