import {Dispatch, ThunkAction} from '../../../../../../../types/actions';
import {root} from '../../../../../../../store/constants';
import {
    boardImportBoardJiraClickedSegmentEvent,
} from '../../../../../main/navigationPanel/effects/segmentEvents';
import {isContinuedReadOnly} from '../../../../../base/selectors/isContinuedReadOnly';
import {snackbarUpgradeDefault} from '../../../../../snackbarsStack';
import {importJira} from '../../../../openBoards/FooterImport/hocs/FooterImportHOC/effects/importJira';
import {OPEN_BOARDS_DIALOG_CLOSE} from '../constants';

export const onClickJira = (): ThunkAction => {
    const action = (dispatch: Dispatch) => {
        root.App.vent.trigger(root.App.vent[OPEN_BOARDS_DIALOG_CLOSE]);
        dispatch(boardImportBoardJiraClickedSegmentEvent());
        if (isContinuedReadOnly()) {
            dispatch(snackbarUpgradeDefault());
        } else {
            dispatch(importJira());
        }
    };
    return action;
};
