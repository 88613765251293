import { connect } from 'react-redux';
import { ITabBrandingHOCProps } from './types';
import { ITabBrandingFields } from '../components/TabBranding/types';
import { TabBranding } from '../components/TabBranding/TabBranding';
import { IApplicationState } from 'app/types/types';
import { getAuthUserSupport } from 'app/store/model/authUser/selectors/getAuthUserSupport';

const mapStateToProps = (
    state: IApplicationState,
    ownProps: ITabBrandingHOCProps
): ITabBrandingFields => {
    const { boardId } = ownProps;
    return {
        boardId,
        isShowCustomTheme: getAuthUserSupport(state, 'supportBranding'),
    }
};

export const TabBrandingHOC = connect(
    mapStateToProps,
    null
)(TabBranding);

TabBrandingHOC.displayName = 'TabBrandingHOC';
