import * as React from 'react';
import { IBoardPwcImportProps } from './types';
import { Button } from 'kui';
import { BOARD_PWC_IMPORT_TEXT } from '../../constants';
import { CLASS_BOARD_DETAILS } from '../../../../constants';
import './_BoardPwcImport.scss';

export function BoardPwcImport ({
    title,
    onSelect
}: IBoardPwcImportProps) {
    return (
        <div >
            <div className={CLASS_BOARD_DETAILS + '__pwc_import_title'}>{title}:</div>
            <Button onClick={onSelect}  text={ BOARD_PWC_IMPORT_TEXT }/>
        </div>
    );
};
