import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState, TBoardId } from '../../../../types/types';
import { fetchHandler } from '../../../../util/fetchHandler';
import { Util } from '../../../../util/util';
import { REST_FIRST_BOARD } from '../../../constants';
import { getRestHeadersGet } from '../../../helpers/getRestHeadersHelper';
import { IFirstBoard } from 'app/rest/effects/firstBoard/types';

export const getRest = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return fetchHandler<IFirstBoard[]>(
            Util.getApiUrl(`${REST_FIRST_BOARD}/${boardId}`),
            getRestHeadersGet()
        ).then(response => {
            return response
        });
    };
    return action;
};
