import { IApplicationState, TCardId } from '../../../../../types/types'
import { ICardChecklists } from '../types';
import { getChecklists } from './getChecklists';

export const getCardChecklists = (
    state: IApplicationState,
    cardId: TCardId,
): ICardChecklists => {
    return getChecklists(state)[cardId] || {};
};
