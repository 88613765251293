import { TWELVE_HOURS } from '../components/constants';

export const getTwoDigitValue = (
    value: number,
    is12hours?: boolean,
    isAmericanDateFormat?: boolean
): string => {
    let result = is12hours && value >= TWELVE_HOURS ? String(value - TWELVE_HOURS) : String(value);
    return result.length === 1 && !isAmericanDateFormat ? '0' + result : result.length > 2 ? result.slice(2) : result;
}
