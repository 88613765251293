import { connect } from 'react-redux';
import { onClick } from './events/onClick';
import { NavigationPanelAssignedToMe } from '../../components/NavigationPanelAssignedToMe/NavigationPanelAssignedToMe';
import {
    INavigationPanelAssignedToMeEvents,
    INavigationPanelAssignedToMeFields
} from '../../components/NavigationPanelAssignedToMe/types';
import { IApplicationState } from '../../../../../../types/types';
import { getIsAssignedToMeActive } from '../../../../../../store/router/selectors/getIsAssignedToMeActive';
import { getUserNewCardsCount } from '../../../../../../store/model/authUser/selectors/getUserNewCardsCount';

const mapStateToProps = (
    state: IApplicationState,
): INavigationPanelAssignedToMeFields => {
    return {
        isActive: getIsAssignedToMeActive(state),
        newCardsCount: getUserNewCardsCount(state),
    }
};

const mapDispatchToProps = (dispatch: any): INavigationPanelAssignedToMeEvents => ({
    onClick: () => dispatch(onClick())
});

export const NavigationPanelAssignedToMeHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(NavigationPanelAssignedToMe);
NavigationPanelAssignedToMeHOC.displayName = 'NavigationPanelAssignedToMeHOC';
