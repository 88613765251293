import { IAuthenticateCommand } from 'app/helper/authorisation/microsoft/filePicker/types';
import { combine } from 'app/helper/authorisation/microsoft/filePicker/utils';
import { providerInstance } from 'app/helper/authorisation/microsoft/oauth/Provider';
import { SilentRequest } from '@azure/msal-browser';

export function getToken(
    command: IAuthenticateCommand,
    loginHint: string,
    silentOnly = false,
    popupOnly = false
) {
    let request: SilentRequest = {scopes: []};

    switch (command.type) {
        case 'SharePoint':
            request.scopes = [`${combine(command.resource, '.default')}`];
            break;
        case 'Graph':
            request.scopes = ['OneDrive.ReadWrite'];
            request.authority = 'https://login.microsoftonline.com/consumers'
            break;
        default:
            break;
    }

    return providerInstance.acquireToken(request, loginHint, silentOnly, popupOnly);
}
