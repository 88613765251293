import { TBoardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { fetchHandler } from '../../../../util/fetchHandler';
import { Util } from '../../../../util/util';
import { REST_BOARD, REST_PUSH_NOTIFICATIONS_SETTINGS } from '../../../constants';
import { getRestHeadersGet } from '../../../helpers/getRestHeadersHelper';
import { IPushNotificationSettings } from '../../../../store/model/pushNotificationSettings/types';

export const getRest = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        return fetchHandler<IPushNotificationSettings>(
            Util.getApiUrl(REST_BOARD + boardId + REST_PUSH_NOTIFICATIONS_SETTINGS), {
                ...getRestHeadersGet()
            }
        );
    };
    return action;
};
