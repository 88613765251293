import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId, TChecklistId, TChecklistItemId } from '../../../../../../../../types/types';
import { checklistItemUpdate } from '../../../../../../../../rest/effects/card/checklist/checklistItemUpdate';

export const onChangeWeight = (
    cardId: TCardId,
    checklistId: TChecklistId,
    itemId: TChecklistItemId,
    weight: number
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(checklistItemUpdate(cardId, checklistId, itemId, {
            weight,
        }));
    };
    return action;
};
