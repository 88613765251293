import * as React from 'react';
import { MESSAGE_DUE_DATE } from './constants';
import { IRestCardCommonActivity } from '../../../../../../../types/rest/activity/IRestCardCommonActivity';
import './_CardActivityDueDatePassed.scss';
import { getDateFormatted } from '../../../../../helpers/dateService';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import {
    CLASS_CARD_ACTIVITY_ITEM_DATE
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemBody/constants';
import { IActivityProps } from '../../../../boardDetails/activity/TabActivity/types';
import { TabActivityContext } from '../../../../boardDetails/activity/TabActivity/components/TabActivity/constants';
import { TActivity } from '../../../../../../../store/activities/types';
import {
    ActivityItemBody
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemBody/ActivityItemBody';
import { ActivityHeader } from '../../../../boardDetails/activity/TabActivity/components/ActivityHeader/ActivityHeader';
import {
    ActivityItemName
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemName/ActivityItemName';
import { SMALL_ACTIVITY_MIN_ASIDE_WIDTH } from '../../../../boardDetails/activity/TabActivity/components/constants';

export function CardActivityDueDatePassed ({
    activity,
    asidePanelWidth
}: IActivityProps<IRestCardCommonActivity>) {
    const className = CLASS_CARD_ACTIVITY_ITEM_DATE;
    const { onOpenCard } = React.useContext(TabActivityContext);
    const { isUserTimeFormat12 } = React.useContext(AsidePanelContext);
    const date = getDateFormatted(Number(activity.newValue), isUserTimeFormat12);
    const { label, card } = activity as TActivity;
    const content = <ActivityItemName name={`${MESSAGE_DUE_DATE} ${date}`} />;
    return (
        <ActivityItemBody
            activity={activity}
            onClick={() => onOpenCard(card.id, activity.id, 'dates')}
            headerIcon={'notifications'}
            header={<ActivityHeader
                className={`${className} ${className}--overdue`}
                icon={'overdue'}
                label={label}
                content={asidePanelWidth <= SMALL_ACTIVITY_MIN_ASIDE_WIDTH && content}
            />}
        >
            {asidePanelWidth > SMALL_ACTIVITY_MIN_ASIDE_WIDTH && content}
        </ActivityItemBody>
    );
}
