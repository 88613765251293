import * as React from 'react';
import './_CalendarSelect.scss';
import { ICalendarSelectProps } from './types';
import { CLASS_DATEPICKER } from '../constants';
import { Button, ButtonDropdown, Icon } from 'kui';
import { CARD_CONTROL_CLASS } from '../../../../../main/kanbanView';
import { FixedSizeList as VirtualList } from 'react-window';
import { GOOGLE_SPACING } from '../../../../../../../const';
import { DatepickerContext } from '../Datepicker/constants';

export const CalendarSelect = ({
    options,
    selectedIdx,
    width,
    onSelect
}: ICalendarSelectProps) => {
    const { portalId } = React.useContext(DatepickerContext);

    const className = CLASS_DATEPICKER + '__calendar-select';

    const [isOpened, setOpened] = React.useState(null);
    const [idx, setIdx] = React.useState(selectedIdx);

    const listRef = React.useRef(null);

    const onKeyDown = (e: React.KeyboardEvent) => {
        if (!isOpened) return;
        e.stopPropagation();
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            if (idx < options.length - 1) {
                setIdx(idx + 1);
            }
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            if (idx > 0) {
                setIdx(idx - 1);
            }
        } else if (e.key === 'Escape') {
            setOpened(false);
            setIdx(selectedIdx);
        } else if (e.key === 'Enter') {
            if (idx !== selectedIdx) {
                onSelect(idx);
            }
            setOpened(false);
            setIdx(selectedIdx);
        }
    };

    React.useEffect(() => {
        if (!isOpened) return;
        setTimeout(() => { // wait for dropdown
            if (listRef.current) {
                listRef.current.scrollToItem(idx, 'center');
            }
        }, 0);
    }, [idx, isOpened]);

    return (
        <ButtonDropdown
            className={className}
            style={{ width }}
            dropdownClassName={`${className + '-dropdown'} ${CARD_CONTROL_CLASS}`}
            opened={isOpened}
            onClose={() => {
                setOpened(false);
                setIdx(selectedIdx);
            }}
            onOpen={() => setOpened(true)}
            onKeyDown={onKeyDown}
            dontChangeFocus
            portal
            portalId={portalId}
        >
            <Button
                className={className + '-button'}
                variant={'icon-text'}
            >
                {options[selectedIdx]}
                <Icon xlink={'arrow-down'} size={24} />
            </Button>
            <VirtualList
                className={className + '-list'}
                itemCount={options.length}
                itemData={{
                    onSelect: (idx) => {
                        onSelect(idx);
                        setOpened(false);
                    },
                    selectedIdx,
                    activeIdx: idx,
                    options
                }}
                itemSize={GOOGLE_SPACING * 5}
                width={width}
                height={GOOGLE_SPACING * 27} // same height in _CalendarSelect.scss
                overscanCount={5}
                ref={listRef}
            >
                {itemRenderer}
            </VirtualList>
        </ButtonDropdown>
    );
}

function itemRenderer ({
    data,
    index,
    style
}: IItemRendererProps) {
    const className = CLASS_DATEPICKER + '__calendar-select-item';

    return (
        <div
            style={style}
            className={`
                ${className}
                ${index === data.selectedIdx ? className + '--selected' : ''}
                ${index === data.activeIdx ? className + '--active' : ''}
            `}
            key={index}
            onClick={() => data.onSelect(index)}
        >
            {data.options[index]}
        </div>
    );
}

interface IItemRendererProps {
    index: number;
    style: React.CSSProperties;
    data: IItemData;
}

interface IItemData {
    activeIdx: number;
    options: Array<string | number>;
    selectedIdx: number;
    onSelect: (idx: number) => void;
}
