import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState, TCardId } from '../../../../types/types';
import { getCard } from '../../../../store/model/selectors/getCard';
import { getCardEpicsForDueDateChange } from '../../../../store/model/selectors/getCardEpicsForDueDateChange';
import { cardsRestPatch } from './api/helper/cardsRestPatch';
import { ICards } from '../../../../store/model/cards/types';
import { ICardsDatesDelta } from 'app/types/ICardsDatesDelta';
import { initWebPushNotifications, isBrowserNotificationsPopupShown } from 'app/helper/webPushNotify';
import { root } from 'app/store/constants';
import {
    EBrowserNotificationsTypes
} from '../../../../view/react_components/dialogs/notificationsRequest/hocs/BrowserNotificationsRequestHOC/types';
import {
    getShowGanttContextHints
} from '../../../../view/react_components/hints/conditions/ganttContextConditions/getShowGanttContextHints';
import {
    getAuthUserIsShowGanttContextHints
} from '../../../../store/model/authUser/selectors/getAuthUserIsShowGanttContextHints';
import { getHint } from '../../../../view/react_components/hints/store/hints/selectors/getHint';
import { EHintKey, EHintStatus } from '../../../../view/react_components/hints';

//TODO implement
export const cardSetDueDateWithEpics = (
    cardId: TCardId,
    dueDate: number,
    cardDatesUpdate: ICardsDatesDelta = {}
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const card = getCard(state, cardId);
        if (!card) return Promise.reject();

        if (card.dueDate === dueDate) return Promise.resolve();

        if (
            dueDate &&
            !isBrowserNotificationsPopupShown() &&
            (
                !getShowGanttContextHints(state) ||
                getHint(state, EHintKey.GANTT_CONTEXT_GANTT_VIEW_RESIZE).status === EHintStatus.SHOWN
            )
        ) {
            initWebPushNotifications(() => {
                return new Promise((resolve, reject) => {
                    root.App.controller.mainView.showNotificationsRequestDialog(() => resolve(true), EBrowserNotificationsTypes.DUE_DATE);
                })
            });
        }

        let cards: ICards = {
            [cardId]: {
                dueDate
            }
        };
        cardDatesUpdate[cardId] ={
            deltaDueDate: dueDate - card.dueDate
        }
        cards = getCardEpicsForDueDateChange(state, card.epicId, cards, cardDatesUpdate);
        dispatch(cardsRestPatch(cards));
        return Promise.resolve(cards);
    };
    return action;
};
