import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { cardTogglePinnedChecklist } from '../../../../../../../../rest/effects/card/card/cardTogglePinnedChecklist';
import { CHECKLIST_DEFAULT_ID } from '../../../../../../../../store/model/checklists/checklists/constants';
import { getChecklist } from '../../../../../../../../store/model/checklists/checklists/selectors/getChecklist';
import { getCardPinnedChecklists } from '../../../../../../../../store/model/selectors/getCardPinnedChecklists';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IRestChecklist } from '../../../../../../../../types/rest/IRestChecklist';
import { IGetState, TCardId, TChecklistId } from '../../../../../../../../types/types';
import { updateDefaultChecklistWithItems } from '../effects/updateDefaultChecklistWithItems';

export const onPinToggle = (
    cardId: TCardId,
    checklistId: TChecklistId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const checklists = getCardPinnedChecklists(getState(), cardId);
        if (checklists.includes(checklistId)) {
            dispatch(segmentTrackAction(SegmentCardEvent.CHECKLIST_UNPIN_CLICKED));
        } else {
            dispatch(segmentTrackAction(SegmentCardEvent.CHECKLIST_PIN_CLICKED));
        }
        if (checklistId === CHECKLIST_DEFAULT_ID) {
            const checklist = getChecklist(getState(), cardId, checklistId);
            return dispatch(updateDefaultChecklistWithItems(checklist))
                .then((checklist: IRestChecklist) => {
                    return dispatch(cardTogglePinnedChecklist(cardId, checklist.id));
                });
        } else {
            return dispatch(cardTogglePinnedChecklist(cardId, checklistId));
        }
    };
    return action;
};
