import { ICards } from '../cards/types';
import { IApplicationState, TCardId } from '../../../types/types';
import { getCard } from './getCard';
import { getSubcardsMinDate } from './getSubcardsMinDate';
import { ICardsDatesDelta } from 'app/types/ICardsDatesDelta';

export const getCardEpicsForStartDateChange = (
    state: IApplicationState,
    epicId: TCardId,
    cards: ICards = {},
    cardDatesUpdate: ICardsDatesDelta = {}
): ICards => {
    if (!epicId){
        return  cards;
    }
    const epic = getCard(state, epicId);
    if (!epic) return cards;
    const startDate = getSubcardsMinDate(state, epicId, cards);
    const epicStartDate = cards[epicId] && cards[epicId].startDate !== undefined ? cards[epicId].startDate : epic.startDate;
    if (epicStartDate !== startDate){
        cards[epicId] = {
            ...cards[epicId],
            startDate
        };
        if (startDate && epic.startDate) {
            cardDatesUpdate[epicId] = {
                ...cardDatesUpdate[epicId], ...{deltaStartDate: startDate - epic.startDate}
            }
        }
        return getCardEpicsForStartDateChange(state, epic.epicId, cards, cardDatesUpdate);
    }

    return cards;
}
