import { EViewTypes } from '../../../../const';
import * as moment from 'moment';
import { sendUserStatAdminEvent } from '../sendUserStatAdminEvent';
import { Dispatch } from '../../../../types/actions';

export const sendUserActivityStatEventByViewType = (viewType: EViewTypes) => {
    const action = (
        dispatch: Dispatch
    ) => {
        if (viewType === EViewTypes.GANTT_VIEW_TYPE) {
            dispatch(sendUserStatAdminEvent({
                gantViewCount: '+1',
                gantViewLastDate: moment().valueOf()
            }));
        }
        if (viewType === EViewTypes.LIST_VIEW_TYPE) {
            dispatch(sendUserStatAdminEvent({
                listViewCount: '+1',
                listViewLastViewDate: moment().valueOf()
            }));
        }
        if (viewType === EViewTypes.KANBAN_VIEW_TYPE) {
            dispatch(sendUserStatAdminEvent({
                kanbanViewCount: '+1',
                kanbanLastViewDate: moment().valueOf()
            }));
        }
        if (viewType === EViewTypes.REPORTS_VIEW_TYPE) {
            dispatch(sendUserStatAdminEvent({
                reportViewCount: '+1',
                reportLastViewDate: moment().valueOf()
            }));
        }
        if (viewType === EViewTypes.USERS_VIEW_TYPE) {
            dispatch(sendUserStatAdminEvent({
                usersDashboardViewCount: '+1',
                usersDashboardLastViewDate: moment().valueOf()
            }));
        }

        if (viewType === EViewTypes.COLOR_TAGS_VIEW_TYPE) {
            dispatch(sendUserStatAdminEvent({
                colorTagsDashboardViewCount: '+1',
                colorTagsDashboardLastViewDate: moment().valueOf()
            }));
        }
        if (viewType === EViewTypes.CARD_COLORS_VIEW_TYPE) {
            dispatch(sendUserStatAdminEvent({
                cardColorsDashboardViewCount: '+1',
                cardColorsDashboardLastViewDate: moment().valueOf()
            }));
        }
        if (viewType === EViewTypes.TAGS_VIEW_TYPE) {
            dispatch(sendUserStatAdminEvent({
                tagsDashboardViewCount: '+1',
                tagsDashboardLastViewDate: moment().valueOf()
            }));
        }
        if (viewType === EViewTypes.PRIORITIES_VIEW_TYPE) {
            dispatch(sendUserStatAdminEvent({
                prioritiesDashboardViewCount: '+1',
                prioritiesDashboardLastViewDate: moment().valueOf()
            }));
        }

    };
    return action;
};
