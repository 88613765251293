import { INotifySearchSelectProps } from './types';
import * as React from 'react';
import { NotifySearchSelectOption } from '../NotifySearchSelectOption/NotifySearchSelectOption';
import { NOTIFY_SEARCH_PLACEHOLDER } from './constants';
import { CLASS_CARD_DETAILS } from '../../..';
import { Button, Icon } from 'kui';
import './_NotifySearchSelect.scss';
import { SearchSelect } from '../../../../../base/components/SearchSelect/SearchSelect';

export function NotifySearchSelect ({
    availableElements,
    onClosed,
    onOpened,
    onSelect,
}: INotifySearchSelectProps) {
    const className = CLASS_CARD_DETAILS + '__notify-search';
    const classNameArrow = CLASS_CARD_DETAILS + '__notify-search-arrow';
    const [isOpened, setOpened] = React.useState(false);
    const classNameOpened = isOpened ? className + '--opened' : '';

    function onOpenHandler () {
        setOpened(true);
        onOpened();
    }

    function onCloseHandler () {
        setOpened(false);
        onClosed();
    }

    return (
        <div className={`${className} ${classNameOpened}`}>
            <SearchSelect
                isCloseOnClick={false}
                multiple
                options={ availableElements }
                option={ NotifySearchSelectOption }
                searchPlaceholder={NOTIFY_SEARCH_PLACEHOLDER}
                single={false}
                isMultipleGroupToggle={true}
                onClosed={ onCloseHandler }
                onOpened={ onOpenHandler }
                onSelect={ onSelect }
            />
            <Button
                className={ classNameArrow }
                variant={'icon'}
                onClick={isOpened ? onCloseHandler : onOpenHandler}
            >
                <Icon
                    xlink={isOpened ? 'arrow-up' : 'arrow-down'}
                    size={24}
                />
            </Button>
        </div>
    )
}
