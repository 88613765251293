import { connect } from 'react-redux';
import { AsidePanelClButton } from '../../components/AsidePanelClButton/AsidePanelClButton';
import { IAsidePanelClButtonEvents } from '../../components/AsidePanelClButton/types';
import { onClick } from './events/onClick';
import { IAsidePanelClButtonHOCProps } from './types';

const mapDispatchToProps = (
    dispatch: any,
    props: IAsidePanelClButtonHOCProps
): IAsidePanelClButtonEvents => {
    const {cardId} = props;
    return {
        onClick: () => dispatch(onClick(cardId))
    }
};

export const AsidePanelClButtonHOC = connect(
    null,
    mapDispatchToProps
)(AsidePanelClButton);

AsidePanelClButtonHOC.displayName = 'AsidePanelClButtonHOC';
