import { Dispatch, ThunkAction } from '../../../types/actions';
import { IGetState } from '../../../types/types';
import { getAuthUser } from '../../../store/model/authUser/selectors/getAuthUser';
import { IAuthUser } from '../../../store/model/authUser/types';
import { authUserPatch } from './api/helper/authUserPatch';
import { getAuthUserWatchedTutorials } from '../../../store/model/authUser/selectors/getAuthUserWatchedTutorials';
import { ETutorial } from '../../../types/rest/IUserMeta';

export const authUserSetTutorialWatched = (
    tutorial: ETutorial
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const authUser = getAuthUser(state);
        const userPatch: IAuthUser = {
            id: authUser.id,
            meta: {
                watchedTutorials: [
                    ...getAuthUserWatchedTutorials(state),
                    tutorial
                ]
            }
        };
        return dispatch(authUserPatch(userPatch));
    };
    return action;
};
