import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { fetchHandler } from '../../../../../util/fetchHandler';
import Util from '../../../../../util/util';
import { REST_PREDECESSOR } from '../../../../constants';
import { getRestHeadersPost } from '../../../../helpers/getRestHeadersHelper';
import { IRestPredecessor } from '../../../../../types/rest/IRestPredecessor';
import { EDependencyTypes, IDependency } from '../../../../../store/model/dependencies/dependency/types';
import { IGetState } from '../../../../../types/types';
import { dependencyToRestPredecessor } from './helper/dependencyToRestPredecessor';

export const postRest = (
    dependency: IDependency
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {

        const body = dependencyToRestPredecessor(dependency);
        return fetchHandler<IRestPredecessor>(
            Util.getApiUrl(REST_PREDECESSOR), {
                ...getRestHeadersPost(),
                body: JSON.stringify(body)
            }
        )
        .then((predecessor: IRestPredecessor) => {
            return restPredecessorToDependency(predecessor);
        });
    };
    return action;
};

const restPredecessorToDependency = (
    response: IRestPredecessor
) => {
    const dependency: IDependency = {
        id: response.id,
        successorId: response.cardId,
        type: EDependencyTypes.FINISH_TO_START,
        predecessorId: response.predecessorId
    }
    return dependency;

}
