import { ICardCounters } from '../../../containers/TopBarInfoBlock/types';
import { getAuthUser } from 'app/store/model/authUser/selectors/getAuthUser';
import { IAuthUser } from 'app/store/model/authUser/types';
import { getBoardCards } from 'app/store/model/selectors/getBoardCards';
import { ICard } from 'app/store/model/card/types';
import { EFilterButtonsType } from '../../../store/topBar/types';
import { createDeepEqualSelector } from 'app/view/react_components/helpers/memoizeHelper';
import { IApplicationState, TBoardId } from 'app/types/types';
import { CARD_DONE_PROCESSING_STATUS } from '../../../constants';

type TgetCardCounters = (
    state: IApplicationState,
    boardId: TBoardId,
) => ICardCounters;

const getCardCountersSelector = (
    authUser: IAuthUser,
    cards: ICard[],
): ICardCounters => {
    const { permissionId }= authUser;

    const counters: ICardCounters = {
        [EFilterButtonsType.DONE]: 0,
        [EFilterButtonsType.OVERDUE]: 0,
        [EFilterButtonsType.MY_TASKS]: 0,
    };

    cards.forEach((card) => {
        const assigned = card.assignees && card.assignees.find(
            assignee => assignee.sharedUser.permissionId === permissionId
        );
        if (assigned) counters[EFilterButtonsType.MY_TASKS]++;

        const done = card.processingStatus === CARD_DONE_PROCESSING_STATUS;
        if (done) counters[EFilterButtonsType.DONE]++;

        const overdue = (
            card.processingStatus !== CARD_DONE_PROCESSING_STATUS &&
            card.dueDate &&
            card.dueDate * 1000 < Date.now()
        );
        if (overdue) counters[EFilterButtonsType.OVERDUE]++;
    });

    return counters;
};

export const getCardCountersCreateSelector = (): TgetCardCounters => createDeepEqualSelector(
    getAuthUser,
    getBoardCards,
    getCardCountersSelector,
);
