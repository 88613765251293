'use strict';

import store, { dispatch } from "../../store/configureStore";
import { Provider } from "react-redux";
import React from "react";
import { removeAsidePanelPortal } from "../../helper/removeAsidePanelPortal";
import { snackbarErrorReload } from "../react_components/snackbarsStack";
import { segmentTrackAction } from "app/middlewares/segment";
import { SegmentErrorEvent, SegmentErrorOptions } from "app/middlewares/segment/trackEntities/errorEvents";


export const ListViewDashboard = App.Views.ListViewDashboard = App.Views.AbstractDashboard.extend({

    initialize: function(options) {
        App.Views.AbstractDashboard.prototype.initialize.call(this, options);
        this.activateTimer = null;
        this.initEventListeners();
    },
    
    initEventListeners: function() {
        this.listenTo(App.vent, 'key:keydown', this.onKeyDown);
    },

    onActivated: function () {
        App.Views.AbstractDashboard.prototype.onActivated.call(this);
        $('body').addClass('page--listview');
        App.controller.mainView.themeHelper.clearTheme();
    },

    onDeactivated: function() {
        clearTimeout(this.activateTimer);
        App.Views.AbstractDashboard.prototype.onDeactivated.call(this);
    },

    initListViewStore: function() {
        return Promise.all([
            import(/* webpackChunkName: "listview" */ 'app/view/react_components/listView/store/listView/reducers/listView'),
        ])
        .then(([module1]) => {
            const listViewReducer = module1.listViewReducer;
            store.reducerManager.add('listView', listViewReducer);
            dispatch({type: 'async-init'}); // fake action to init new reducers
        })
        .catch(() => {
            this.onImportFail();
            return {
                default: () =>(<div/>)
            }
        });
    },
    
    onImportFail: function() {
        dispatch(snackbarErrorReload(false));
        dispatch(segmentTrackAction(SegmentErrorEvent.LAZY_IMPORT_ERROR, {
            name: SegmentErrorOptions.SOURCE,
            value: 'listView'
        }));
    },

    render: function() {
        const ImportElement = React.lazy(() =>
            this.initListViewStore()
                .then(() => import(/* webpackChunkName: "listview" */ 'app/view/react_components/listView/hocs/ListViewHOC/ListViewHOC'))
                .then(module => ({default: module.ListViewHOC}))
                .catch(() => this.onImportFail())
        );
        this.root.render(
            <Provider store={store}>
                <ImportElement boardId={this.boardId} />
            </Provider>
        );
        return this;
    },

    remove: function() {
        App.Views.AbstractDashboard.prototype.remove.call(this);
        if(this.unsubscribeFilterPanelBoard){
            this.unsubscribeFilterPanelBoard();
        }
        $('body').removeClass('page--listview');
        removeAsidePanelPortal(this.el);
    }
});

