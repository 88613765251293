import { TCardId, IApplicationState, TChecklistId } from '../../../types/types';
import { getCardPinnedChecklists } from './getCardPinnedChecklists';

export const getCardChecklistIsPinned = (
    state: IApplicationState,
    cardId: TCardId,
    checklistId: TChecklistId,
): boolean => {
    const cardPinnedChecklists = getCardPinnedChecklists(state, cardId);

    return cardPinnedChecklists.includes(checklistId);
};
