import { TBoardId, TCardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { toggleCardColor } from '../effects/toggleCardColor';
import { addFeatureTrigger } from '../../../../../../polls/featurePoll/effects/addFeatureTrigger';
import { ERateFeature } from '../../../../../../../../types/rest/IUserMeta';

export const onChange = (
    cardId: TCardId,
    boardId: TBoardId,
    color: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(addFeatureTrigger(ERateFeature.CARD_COLORS));
        return dispatch(toggleCardColor(cardId, boardId, color));
    };
    return action;
};
