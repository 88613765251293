import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { showEditTooltipSetAction } from '../../../store/actions/showEditTooltipSetAction';

export const onClose = (): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(showEditTooltipSetAction(false));
    };
    return action;
};
