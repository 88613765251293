import { TCardId, TChecklistId } from '../../../../types/types';
import { checklistsActionSetAction } from '../../actions/checklistsActionSetAction';
import { checklistDeleteAction } from '../checklists/actions/checklistDeleteAction';

export const checklistDeleteStore = (
    cardId: TCardId,
    checklistId: TChecklistId
) => {
    return checklistsActionSetAction(checklistDeleteAction(cardId, checklistId));
};
