import { IGetState, TCardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { addAttachmentSegmentEvent } from '../effects/segmentEffects';
import { showDocDriveWindow } from '../../../../effects/showDocDriveWindow';
import { ERestDriveDocType } from '../../../../../../../../types/rest/IRestDriveDoc';
import { getDriveDocsByCardId } from '../../../../../../../../store/model/driveDocs/selectors/getDriveDocsByCardId';
import { addAttachments } from '../../../../../../../../rest/effects/card/attacments/addAttachments';
import { setAttachmentAddedSessionStorage } from '../../../helpers/setAttachmentAddedSessionStorage';

export const onAddClicked = (
    cardId: TCardId,
    isDialog?: boolean, // показать диалог Select, drag, paste
    onClose?: () => void,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(addAttachmentSegmentEvent());
        if (!isDialog) {
            dispatch(showDocDriveWindow(
                cardId,
                getDriveDocsByCardId(getState(), cardId),
                ERestDriveDocType.CARD,
                (docs) => dispatch(addAttachments(cardId, docs))
                    .then(() => setAttachmentAddedSessionStorage()),
                onClose
            ))
        }
    };
    return action;
};
