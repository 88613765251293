import { IGetState, TCardId, TCustomPropertyId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { getCardCustomProperties } from '../../../../../../../../store/model/selectors/getCardCustomProperties';
import { cardSetCustomProperties } from '../../../../../../../../rest/effects/card/card/cardSetCustomProperties';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';

export const updateValue = (
    cardId: TCardId,
    id: TCustomPropertyId,
    value: string,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (value === '') value = null;
        const cardCustomProperties = {...getCardCustomProperties(getState(), cardId)};
        const customProperty = cardCustomProperties[id] || {id: id};

        if (
            (value === null && !customProperty.value)
            || customProperty.value === value
        ) {
            return Promise.resolve();
        }

        cardCustomProperties[id] = {
            ...customProperty,
            value,
        };

        dispatch(segmentTrackAction(SegmentCardEvent.CUSTOM_PROPERTY_FILLED));
        return dispatch(cardSetCustomProperties(cardId, cardCustomProperties));
    };
    return action;
};
