import { IApplicationState } from '../../../../../types/types';
import { DASHBOARD_LIMIT } from '../../../../../const';
import { getTabIds } from '../../../../../store/model/selectors/getTabIds';

export const getAvailableBoardsCount = (
    state: IApplicationState
): number => {
    const tabIds = getTabIds(state);
    return DASHBOARD_LIMIT - tabIds.length;
};
