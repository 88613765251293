import { dispatch } from '../../../../../../../../store/configureStore';
import { setType } from '../../../../../../../../rest/effects/card/dependence/setType';
import { EDependencyTypes, TDependencyId } from '../../../../../../../../store/model/dependencies/dependency/types';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';

export const onDependencyTypeChanged = (
    dependencyId: TDependencyId,
    type: EDependencyTypes
) => {
    return () => {
        dispatch(segmentTrackAction(SegmentCardEvent.CHANGED_DEPENDENCY_TYPE))
        return dispatch(setType(dependencyId, type));
    };
};
