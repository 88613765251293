import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { ITabDetailsHOCProps } from './types';
import { TabDetails } from '../../containers/TabDetails/TabDetails';
import { ITabDetailsFields } from '../../containers/TabDetails/types';
import { getCard } from '../../../../../../../store/model/selectors/getCard';
import { getSectionType } from '../../../selectors/getSectionType';
import { getAuthUserPlatformType } from 'app/store/model/authUser/selectors/getAuthUserPlatformType';

const mapStateToProps = (
    state: IApplicationState,
    ownProps: ITabDetailsHOCProps
): ITabDetailsFields => {
    const { boardId, cardId } = ownProps;
    const sectionType = getSectionType(state, boardId, cardId);
    let isShow = false;
    const card = getCard(state, cardId);

    if (card) {
        isShow = true;
    }

    return {
        isShow,
        cardId,
        boardId,
        platformType: getAuthUserPlatformType(state),
        sectionType
    }
};

export const TabDetailsHOC = connect(
    mapStateToProps,
    null
)(TabDetails);

TabDetailsHOC.displayName = 'TabDetailsHOC';
