import { TCommentAction } from './actions/types';
import { ICardCommentsState } from './types';
import { AT_COMMENT_INIT, AT_COMMENT_SET, AT_COMMENT_UPDATE, } from './actions/constants';

export const initialCardCommentsState: ICardCommentsState = {
    value: '',
    mentionSubstring: '',
    highlightedMention: 0,
    replyCommentId: null,
    replyAttachmentId: null,
    replyText: '',
    editCommentId: null
};

export const cardCommentsReducer = (
    state: ICardCommentsState = initialCardCommentsState,
    action: TCommentAction
) => {
    switch (action.type) {

        case AT_COMMENT_SET:
            return {
                ...action.state,
            };

        case AT_COMMENT_UPDATE:
            return {
                ...state,
                ...action.state,
            };

        case AT_COMMENT_INIT:
            return initialCardCommentsState;

        default:
            return state;
    }
};
