import { CHECKLISTS_ACTION_SET, IChecklistsActionSetAction } from './types';
import { TChecklistsAction } from '../checklists/checklists/actions/types';

export const checklistsActionSetAction = (
    checklistsAction: TChecklistsAction
): IChecklistsActionSetAction => {
    return {
        type: CHECKLISTS_ACTION_SET,
        checklistsAction
    }
};
