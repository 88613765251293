import { IAuthUser } from '../../../store/model/authUser/types';
import { EAuthUserAccountType, EAuthUserAccountTypeNames } from '../../../types/rest/IRestAuthUser';

/**
 * получает на вход цифровое значение аккаунт тайпа, возвращает название этого типа
 * @param userTraits
 */
export const userIdentifyAccountTypeNameSelector = (userTraits: IAuthUser): EAuthUserAccountTypeNames => {
    switch (userTraits.accountType) {
        case EAuthUserAccountType.DOMAIN_ACCOUNT_TYPE:
            return EAuthUserAccountTypeNames.DOMAIN_ACCOUNT_TYPE;

        case EAuthUserAccountType.EDU_ACCOUNT_TYPE:
            return EAuthUserAccountTypeNames.EDU_ACCOUNT_TYPE;

        case EAuthUserAccountType.INDIVIDUAL_ACCOUNT_TYPE:
            return EAuthUserAccountTypeNames.INDIVIDUAL_ACCOUNT_TYPE;
    }
};
