import * as React from 'react';
import { IStatusListsProps } from './types';
import { StatusList } from '../StatusList/StatusList';
import { TListId } from 'app/types/types';
import { EDashboardStatusListType } from 'app/types/rest/IRestDashboard';
import {
    STATUS_LIST_DONE,
    STATUS_LIST_DONE_DESC,
    STATUS_LIST_IN_WORK,
    STATUS_LIST_IN_WORK_DESC, STATUS_LIST_IN_WORK_MOVE,
    STATUS_LIST_ON_HOLD,
    STATUS_LIST_ON_HOLD_DESC
} from '../../constants';
import { StatusListsTogglerHOC } from '../../hocs/StatusListsTogglerHOC/StatusListsTogglerHOC';
import { StatusListsTogglerType } from '../../hocs/StatusListsTogglerHOC/types';
import { SettingsSection } from '../../../../../../base/components/SettingsSection/SettingsSection';
import {
    STATUS_LIST_MODAL_CANCEL,
    STATUS_LIST_MODAL_REMOVE,
    STATUS_LIST_MODAL_TEXT,
    STATUS_LIST_MODAL_TITLE,
    STATUS_LIST_TOGGLE_WORK_ON_HOLD
} from './constants';
import { CLASS_BOARD_DETAILS_SECTION } from '../../../../constants';
import { ActionModal } from '../../../../../../base/components/ActionModal/ActionModal';
import {
    BasicUpgradeButtonHOC
} from '../../../../../../base/components/BasicUpgradeButton/hocs/BasicUpgradeButtonHOC/BasicUpgradeButtonHOC';
import { AnalyzeRender } from '../../../../../../helpers/memoizeHelper';

//@ts-ignore
const analyzeRender = window.Settings.development ? new AnalyzeRender(`StatusLists`) : null;

export function StatusLists ({
    boardId,
    holdInWorkTogleChecked,
    doneTogleChecked,
    onHoldId,
    inWorkId,
    doneId,
    onHoldListsOptions,
    inWorkListsOptions,
    isWorkListsMove,
    doneListsOptions,
    isDisabled,
    onClose,
    onListSelected,
    onToggleListStatusMapping,
    onWorkListMoveToggle,
}: IStatusListsProps) {
    if (analyzeRender) analyzeRender.call(`StatusLists ${boardId}`);

    const className = CLASS_BOARD_DETAILS_SECTION + ' ' + CLASS_BOARD_DETAILS_SECTION + '--statuslists-subsection';
    const [removeOption, setRemoveOption] = React.useState(null);
    const onCloseModal = () => {
        onClose();
        setRemoveOption(null);
    };
    return (
        <div>
            <SettingsSection
                className={className}
                title={STATUS_LIST_TOGGLE_WORK_ON_HOLD}
                titleButton={<StatusListsTogglerHOC
                    boardId={boardId}
                    type={StatusListsTogglerType.ON_HOLD_IN_WORK}
                    checked={holdInWorkTogleChecked}
                    onChange={() => onToggleListStatusMapping(StatusListsTogglerType.ON_HOLD_IN_WORK).then(result => {
                        if (!result) setRemoveOption(StatusListsTogglerType.ON_HOLD_IN_WORK);
                    })}/>
                }
            >
                    <div>
                        {holdInWorkTogleChecked &&
                        <>
                        <StatusList
                            options={onHoldListsOptions}
                            selectedId={onHoldId}
                            label={STATUS_LIST_ON_HOLD}
                            description={STATUS_LIST_ON_HOLD_DESC}
                            onChange={(id: TListId) => {
                                onListSelected(EDashboardStatusListType.ON_HOLD, id)
                            }}
                            isDisabled={isDisabled}
                        />
                        <StatusList
                            options={inWorkListsOptions}
                            selectedId={inWorkId}
                            label={STATUS_LIST_IN_WORK}
                            description={STATUS_LIST_IN_WORK_DESC}
                            extraOptions={[{
                                label: STATUS_LIST_IN_WORK_MOVE,
                                onChange: onWorkListMoveToggle,
                                isChecked: isWorkListsMove,
                            }]}
                            onChange={(id: TListId) => {
                                onListSelected(EDashboardStatusListType.IN_WORK, id)
                            }}
                            isDisabled={isDisabled}
                            />
                        </>}
                    </div>
            </SettingsSection>
            <SettingsSection
                className={className}
                title={STATUS_LIST_DONE}
                titleButton={<StatusListsTogglerHOC
                    boardId={boardId}
                    type={StatusListsTogglerType.DONE}
                    checked={doneTogleChecked}
                    onChange={() => onToggleListStatusMapping(StatusListsTogglerType.DONE).then(result => {
                        if (!result) setRemoveOption(StatusListsTogglerType.DONE);
                    })}/>
                }
            >
                <div>
                {doneTogleChecked &&
                <StatusList
                    options={doneListsOptions}
                    selectedId={doneId}
                    label={''}
                    description={STATUS_LIST_DONE_DESC}
                    onChange={(id: TListId) => {
                        onListSelected(EDashboardStatusListType.DONE, id)
                    }}
                    isDisabled={isDisabled}
                />}
                </div>
            </SettingsSection>
            <ActionModal
                isShow={!!removeOption}
                actions={[
                    {
                        variant: 'primary',
                        onClick: onCloseModal,
                        children: STATUS_LIST_MODAL_CANCEL
                    },
                    {
                        variant: 'secondary',
                        onClick: () => {
                            onCloseModal();
                            onToggleListStatusMapping(removeOption);
                        },
                        children: STATUS_LIST_MODAL_REMOVE
                    },
                ]}
                footer={<BasicUpgradeButtonHOC onClick={onCloseModal} />}
                text={STATUS_LIST_MODAL_TEXT}
                title={STATUS_LIST_MODAL_TITLE}
                onClose={onCloseModal}
            />
        </div>
    );
}
