import {getMessages} from '../../../../../../store/constants';

export const OPEN_BOARDS_IMPORT_BUTTON_TEXT_CSV = getMessages().getText('open_boards.import.button.text.csv');
export const OPEN_BOARDS_IMPORT_BUTTON_TEXT_JIRA = getMessages().getText('open_boards.import.button.text.jira');
export const OPEN_BOARDS_IMPORT_BUTTON_TEXT_TRELLO = getMessages().getText('open_boards.import.button.text.trello');
export const OPEN_BOARDS_IMPORT_TITLE = getMessages().getText('open_boards.import.title');
export const OPEN_BOARDS_IMPORT_TITLE_CSV = getMessages().getText('open_boards.import.title.csv');
export const OPEN_BOARDS_IMPORT_TITLE_JIRA = getMessages().getText('open_boards.import.title.jira');
export const OPEN_BOARDS_IMPORT_TITLE_TRELLO = getMessages().getText('open_boards.import.title.trello');
export const OPEN_BOARDS_IMPORT_TEXT_CSV = getMessages().getText('open_boards.import.text.csv');
export const OPEN_BOARDS_IMPORT_TEXT_JIRA = getMessages().getText('open_boards.import.text.jira');
export const OPEN_BOARDS_IMPORT_TEXT_TRELLO = getMessages().getText('open_boards.import.text.trello');
export const OPEN_BOARDS_IMPORT_LEARN_MORE_LINK = getMessages().getText('open_boards.import.learn.more.link');
export const OPEN_BOARDS_IMPORT_URL_CSV = getMessages().getText('open_boards.import.url.csv');
export const OPEN_BOARDS_IMPORT_URL_JIRA = getMessages().getText('open_boards.import.url.jira');
export const OPEN_BOARDS_IMPORT_URL_TRELO = getMessages().getText('open_boards.import.url.trello');
