import { connect } from 'react-redux';
import { getPushNotificationSettingsByBoardId } from '../../../../../../../../store/model/selectors/getPushNotificationSettingsByBoardId';
import { IApplicationState } from '../../../../../../../../types/types';
import { PushNotificationsPanelButton } from '../../components/PushNotificationsPanelButton/PushNotificationsPanelButton';
import { IPushNotificationsPanelButtonEvents, IPushNotificationsPanelButtonFields } from '../../components/PushNotificationsPanelButton/types';
import { onClick } from './events/onClick';
import { IPushNotificationsPanelButtonHOCProps } from './types';

const mapStateToProps = (
    state: IApplicationState,
    { boardId }: IPushNotificationsPanelButtonHOCProps
): IPushNotificationsPanelButtonFields => {
    const pushNotification = getPushNotificationSettingsByBoardId(state, boardId);
    return {
        indicatorBoolean: pushNotification && pushNotification.enabled,
        tooltip: null,
    }
};

const mapDispatchToProps = (
    dispatch: any,
    {onClick: ownOnClick}: IPushNotificationsPanelButtonHOCProps
): IPushNotificationsPanelButtonEvents => {
    return {
        onClick: () => dispatch(onClick(ownOnClick))
    }
};

export const PushNotificationsPanelButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(PushNotificationsPanelButton);

PushNotificationsPanelButtonHOC.displayName = 'PushNotificationsPanelButtonHOC';
