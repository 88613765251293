import * as React from 'react';
import './_Header.scss';
import { Button, Icon } from 'kui';
import { IModalPageHeaderProps } from './types';
import { BACK_TEXT } from './constants';
import { CLASS_MODAL_PAGE } from '../constants';
import { BackButton } from '../../BackButton/BackButton';

export function Header ({
    children,
    showBackButton,
    rightContent,
    onClickBack,
    ...attributes
}: IModalPageHeaderProps) {
    const className = CLASS_MODAL_PAGE + '__header';
    const classNameBack = CLASS_MODAL_PAGE + '__header-back';
    const classNameTitle = CLASS_MODAL_PAGE + '__header-title';
    const classNameRightContent = CLASS_MODAL_PAGE + '__right-content';

    return (
        <div className={className} {...attributes}>
            {showBackButton &&
                <div className={classNameBack}>
                    <BackButton
                        onClick={onClickBack}
                    />
                </div>
            }
            <div className={classNameTitle}>
                {children}
            </div>
            {!!rightContent &&
                <div className={classNameRightContent}>{rightContent}</div>
            }
        </div>
    );
};
