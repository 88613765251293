import { IPushNotificationSettings } from '../types';
import { AT_UPDATE, IUpdateAction } from './types';

export const updateAction = (
    pushNotification: IPushNotificationSettings
): IUpdateAction => {
    return {
        type: AT_UPDATE,
        pushNotification
    }
};
