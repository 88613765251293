import { ICardNumberActionSetAction } from './types';
import { TCardNumberAction } from '../../cardNumber/actions/types';
import { TBoardId } from '../../../../../../../../../types/types';
import { AT_CARD_NUMBER_ACTION_SET } from './constants';

export const cardNumberSetAction = (
    boardId: TBoardId,
    cardNumberAction: TCardNumberAction
): ICardNumberActionSetAction => ({
        type: AT_CARD_NUMBER_ACTION_SET,
        boardId,
        cardNumberAction
    }
);
