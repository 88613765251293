import { TCardId } from '../../../../../../../../types/types';
import { ICardDescriptionUnsavedDeleteAction } from './types';
import { AT_DELETE } from './constants';

export const cardDescriptionUnsavedDeleteAction =
    (
        cardId: TCardId,
    ): ICardDescriptionUnsavedDeleteAction => ({
        type: AT_DELETE,
        cardId,
    });
