import { IGetState, TCardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { generateCardNumberPostRest } from './api/generateCardNumberPostRest';
import { IRestCard } from '../../../../types/rest/IRestCard';
import { cardsUpdate } from '../../../../store/model/actionCreators/cardsUpdate';
import { ICards } from '../../../../store/model/cards/types';
import {
    updateCardAssigneesFromCards
} from '../../../../view/react_components/main/myWorkView/effects/updateCardAssigneesFromCards';
import { sendRealTimeStoreActionByCardId } from '../../../../view/react_components/base/helpers/realTimeHelperTS';

export const cardGenerateCardNumber = (
    cardId: TCardId,
    isRealtime: boolean = true,
    abortController?: AbortController
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const promise = dispatch(generateCardNumberPostRest(cardId, abortController));
        return promise
            .then((response: IRestCard) => {
                const cards: ICards = {
                    [cardId]: response
                };
                dispatch(cardsUpdate(cards));
                if (isRealtime) {
                    dispatch(sendRealTimeStoreActionByCardId(cardId, cardsUpdate(cards)));
                }
                dispatch(updateCardAssigneesFromCards(cards));
                return response;
            });
    };
    return action;
};
