import { IApplicationState } from '../../../../../../types/types';
import { connect } from 'react-redux';
import { WebinarDialog } from '../../components/WebinarDialog/WebinarDialog';
import { IWebinarDialogEvents, IWebinarDialogFields } from '../../components/WebinarDialog/types';
import { onDidMount } from './events/onDidMount';
import { onAnswer } from './events/onAnswer';
import { onSkipped } from './events/onSkipped';
import { onGetStarted } from './events/onGetStarted';
import { getAuthUser } from '../../../../../../store/model/authUser/selectors/getAuthUser';
import { onVideoTutorialsClick } from './events/onVideoTutorialsClick';

const mapStateToProps = (
    state: IApplicationState,
): IWebinarDialogFields => {
    const { fullName, initials, permissionId, photoUrl } = getAuthUser(state);
    return {
        fullName,
        initials,
        permissionId,
        photoUrl,
    }
};

const mapDispatchToProps = (
    dispatch: any
): IWebinarDialogEvents => {
    return {
        onDidMount: () => dispatch(onDidMount()),
        onAnswer: (questionKey, answerKey) => dispatch(onAnswer(questionKey, answerKey)),
        onSkipped: () => dispatch(onSkipped()),
        onGetStarted: () => dispatch(onGetStarted()),
        onVideoTutorialsClick: () => dispatch(onVideoTutorialsClick())
    }
};

export const WebinarDialogHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(WebinarDialog);

WebinarDialogHOC.displayName = 'WebinarDialogHOC';
