import { IButtonsPanelTypeSetAction } from './types';
import { AT_BUTTONS_PANEL_TYPE_SET } from './constants';
import { EButtonsPanelType } from '../types';

export const buttonsPanelTypeSetAction = (
    buttonsPanelType: EButtonsPanelType
): IButtonsPanelTypeSetAction => ({
    type: AT_BUTTONS_PANEL_TYPE_SET,
    buttonsPanelType
});
