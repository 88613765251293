import * as React from 'react';
import './_DatepickerItem.scss';
import { E12HoursType, IDatepickerItemProps } from './types';
import { CLASS_DATEPICKER, TWELVE_HOURS } from '../constants';
import { getHourValue } from '../../helpers/getHourValue';
import { Button, Icon } from 'kui';
import { TimeButton } from '../TimeButton/TimeButton';
import { EDateType, GOOGLE_SPACING } from '../../../../../../../const';
import { HourSwitch } from '../HourSwitch/HourSwitch';
import { v4 as uuidv4 } from 'uuid';
import { DatepickerContext } from '../Datepicker/constants';
import { DateButton } from '../DateButton/DateButton';
import { getDeepDateCheck } from '../../helpers/getDeepDateCheck';
import { EDatepickerItemSize } from '../DateButton/types';
import { HintHOC } from '../../../../../hints';
import { ErrorHint } from '../../../ErrorHint/ErrorHint';
import { DATEPICKER_ITEM_FROM_ERROR, DATEPICKER_ITEM_TO_ERROR } from './constants';

export const DatepickerItem = ({
    dateType,
    defaultTime,
    dateHint,
    fromDate,
    isDisabled,
    isHideDate,
    isOpened,
    isRequired,
    maxFromDate,
    minToDate,
    label,
    placeholder,
    selectedDate,
    timeHint,
    toDate,
    onBlur,
    onCalendarClosed,
    onCalendarOpened,
    onClear,
    onClick,
    onDateChange,
    onDateSelected,
    onFocus,
    onKeyDown,
    onTimeOpened,
    onTimeSelected,
    onUnevenTimeSelected
}: IDatepickerItemProps) => {
    const {
        isAllowSameDate,
        isClearable,
        isOverdue,
        isReadonly,
        isCompact,
        isTime,
        is12Hour,
        maxDate,
        minDate,
        portalId,
    } = React.useContext(DatepickerContext);

    const [uniqueId] = React.useState(uuidv4());

    const className = CLASS_DATEPICKER + '__item';
    const classUnique = className + '--' + uniqueId;
    const classType = className + (dateType === EDateType.DUE_DATE ? '--to' : '--from');
    const classClear = className + '-clear';
    const classTime = className + '-time';

    let [hoursType, _setHoursType] = React.useState<E12HoursType>(null);
    const [isRequireError, setRequireError] = React.useState(null);
    const [error, setError] = React.useState(null);
    const [date, setDate] = React.useState(selectedDate);
    const [isFocused, setFocused] = React.useState(isOpened);
    const [isShowTimeHint, setShowTimeHint] = React.useState(null);
    const [isShowDateHint, setShowDateHint] = React.useState(null);
    const [isTimeEditing, setTimeEditing] = React.useState(null);

    const setHoursType = (value: E12HoursType) => {
        hoursType = value;
        _setHoursType(value);
    };

    const onSetHoursType = (date: Date) => {
        if (!is12Hour || !date) return;
        if (date.getHours() >= TWELVE_HOURS) {
            setHoursType(E12HoursType.PM);
        } else {
            setHoursType(E12HoursType.AM);
        }
    };

    const onChange = (
        selectedDate: Date,
        ignoreChange?: boolean,
        time?: boolean
    ): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            if (!selectedDate) {
                if (!ignoreChange) {
                    onDateChange(null);
                }
                resolve(false);
                return;
            }
            if (time) {
                setShowTimeHint(true);
                if (onTimeSelected) {
                    onTimeSelected();
                }
                if (selectedDate.getMinutes() > 0) {
                    if (onUnevenTimeSelected) onUnevenTimeSelected();
                }
            } else {
                if (onDateSelected) onDateSelected();
            }
            if (isRequired) setRequireError(false);
            let newDate = time ? date : selectedDate;
            if (maxDate && newDate > maxDate) {
                newDate = maxDate;
            } else if (minDate && newDate < minDate) {
                newDate = minDate;
            } else if (maxFromDate && newDate <= maxFromDate) {
                newDate = new Date(maxFromDate);
                newDate.setDate(newDate.getDate() + 1);
            } else if (minToDate && newDate >= minToDate) {
                newDate = new Date(minToDate);
                newDate.setDate(newDate.getDate() - 1);
            }
            if (!getDeepDateCheck(newDate, fromDate, toDate, isTime || isAllowSameDate)) {
                resolve(false);
                return;
            }
            newDate.setHours(selectedDate.getHours(), selectedDate.getMinutes());
            if (!ignoreChange) {
                if (dateType === EDateType.START_DATE && toDate && toDate < newDate) {
                    setError(DATEPICKER_ITEM_TO_ERROR);
                } else if (dateType === EDateType.DUE_DATE && fromDate && fromDate > newDate) {
                    setError(DATEPICKER_ITEM_FROM_ERROR);
                } else {
                    setError(null);
                    onDateChange(newDate);
                }
            }
            setDate(new Date(newDate)); // для ререндера в timeButton
            setShowDateHint(true);
            resolve(true);
        });
    };

    const onToggleHoursType = () => {
        setShowTimeHint(true);
        if (hoursType === E12HoursType.AM) {
            setHoursType(E12HoursType.PM);
        } else {
            setHoursType(E12HoursType.AM);
        }
        const newDate = new Date(date.setHours(getHourValue(date, hoursType)));
        onChange(newDate, false);
        const isTimeDropdown = document.querySelector('.' + CLASS_DATEPICKER + '__time-button-option') as HTMLElement;
        if (!isTimeDropdown) return;
        const timeDropdown = document.querySelector('.' + CLASS_DATEPICKER + '__time-button-dropdown') as HTMLElement;
        if (timeDropdown) {
            timeDropdown.focus();
        }
    };

    const onClearClick = () => {
        if (isRequired) setRequireError(true);
        if (onClear) onClear();
        setDate(null);
        onDateChange(null);
        const isDate = dateType === EDateType.DUE_DATE ? fromDate : toDate;
        if (isDate) {
            const calendar = document.querySelector('.' + CLASS_DATEPICKER + '__calendar') as HTMLElement;
            if (calendar) calendar.focus();
        }
    };

    React.useEffect(() => {
        onSetHoursType(selectedDate);
    }, [selectedDate, date]);

    React.useEffect(() => {
        setDate(selectedDate);
    }, [selectedDate]);

    React.useEffect(() => {
        if (error) {
            onChange(date);
        }
    }, [toDate, fromDate]);

    React.useEffect(() => {
        setTimeout(() => setFocused(isOpened), 200);
    }, [isOpened]);

    return (
        <div
            className={`
                ${className}
                ${classUnique}
                ${classType}
                ${date || isHideDate ? className + '--filled' : ''}
                ${isRequired ? className + '--required' : ''}
                ${isRequireError || error ? className + '--error' : ''}
                ${isFocused ? className + '--focused' : ''}
                ${label ? className + '--with-label' : ''}
                ${isDisabled ? className + '--disabled' : ''}
                ${dateType === EDateType.DUE_DATE && isOverdue ? className + '--overdue' : ''}
            `}
            onClick={onClick}
        >
            {label &&
                <div className={className + '-label'}>{label}</div>
            }
            <DateButton
                uniqueId={uniqueId}
                dateType={dateType}
                date={date}
                defaultTime={defaultTime}
                isRequireError={isRequireError}
                placeholder={placeholder}
                maxFromDate={maxFromDate}
                minToDate={minToDate}
                size={is12Hour
                    ? EDatepickerItemSize.EXTRA_LARGE
                    : isTime
                        ? EDatepickerItemSize.LARGE
                        : EDatepickerItemSize.BASE
                }
                fromDate={fromDate}
                toDate={toDate}
                isHideDate={isHideDate}
                isOpened={isOpened}
                onBlur={onBlur}
                onCalendarClosed={onCalendarClosed}
                onCalendarOpened={onCalendarOpened}
                onDateChange={onChange}
                onFocus={onFocus}
                onKeyDown={onKeyDown}
                onSetFocus={value => setFocused(value)}
                onSetHoursType={onSetHoursType}
                setTimeEditing={() => setTimeEditing(true)}
            />
            {dateHint && isShowDateHint &&
                <HintHOC
                    className={className + '-hint'}
                    arrow={'down'}
                    direction={'up'}
                    show={true}
                    arrowTranslate={{ left: GOOGLE_SPACING * 5 }}
                    translate={{ left: GOOGLE_SPACING * 7.75 }}
                    selector={'.' + classUnique}
                    isPortal
                    portalId={portalId}
                    {...dateHint}
                />
            }
            {(date || isHideDate) &&
                <div
                    className={`
                        ${classTime}
                        ${!isTime && !isClearable ? classTime + '--no-events' : ''}
                    `}
                >
                    {isTime && !isCompact && !isHideDate &&
                        <>
                            <TimeButton
                                date={date}
                                dateType={dateType}
                                hoursType={hoursType}
                                onDateChange={(date) => onChange(date,false,true)}
                                onTimeOpened={onTimeOpened}
                                onSetFocus={setFocused}
                                isTimeEditing={isTimeEditing}
                                resetTimeEditing={() => setTimeEditing(false)}
                            />
                            {is12Hour &&
                                <HourSwitch
                                    hoursType={hoursType}
                                    onToggle={onToggleHoursType}
                                />
                            }
                            {timeHint && isShowTimeHint &&
                                <HintHOC
                                    className={classTime + '-hint'}
                                    arrow={'down'}
                                    direction={'up'}
                                    show={true}
                                    arrowTranslate={{ left: GOOGLE_SPACING * (EDatepickerItemSize.LARGE ? 8.25 : 6.5)}}
                                    selector={`.${classUnique} .${classTime}`}
                                    isPortal
                                    portalId={portalId}
                                    {...timeHint}
                                />
                            }
                        </>
                    }
                    {isClearable && !isReadonly ?
                        <Button
                            variant={'icon'}
                            className={classClear}
                            onClick={onClearClick}
                        >
                            <Icon
                                xlink={'clear'}
                                size={24}
                            />
                        </Button>
                        : <Icon
                            className={className + '-calendar'}
                            xlink={'calendar'}
                            size={24}
                        />
                    }
                    {dateType === EDateType.DUE_DATE && isOverdue &&
                        <Button
                            variant={'icon'}
                            className={`
                                ${classClear}
                                ${classClear + '--overdue'}
                            `}
                        >
                            <Icon
                                xlink={'overdue'}
                                size={24}
                            />
                        </Button>
                    }
                </div>
            }
            {isTime &&
                <ErrorHint
                    arrowTranslate={{ left: GOOGLE_SPACING * 12 + 1 }}
                    direction={'up-right'}
                    key={classUnique}
                    portalSelector={'.' + classUnique}
                    selector={`.${classUnique} .${CLASS_DATEPICKER + '__date-button-create'}`}
                    value={error}
                />
            }
        </div>
    );
}
