import { TSegmentTrackPayload } from './../trackEntities/types';
import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { SegmentBlockersEvent, segmentTrackAction } from '../';
import { ESegmentBlockerOptions, ESegmentBlockerType } from '../trackEntities/blockersEvents';

export const blockerSegmentEvent = (
    blockerEvent: TSegmentTrackPayload,
    blockerType: ESegmentBlockerType
): ThunkAction => {
    return (
        dispatch: Dispatch
    ) => {
        dispatch(segmentTrackAction(blockerEvent, {
            name: ESegmentBlockerOptions.type,
            value: blockerType
        }));
    };
};

export const blockerShownSegmentEvent: ActionCreator<ThunkAction> = (
    blockerType: ESegmentBlockerType
) => (dispatch: Dispatch) => {
    dispatch(blockerSegmentEvent(SegmentBlockersEvent.BLOCKER_SHOWN, blockerType));
};

export const gotoPricingClickedSegmentEvent: ActionCreator<ThunkAction> = (
    blockerType: ESegmentBlockerType
) => (dispatch: Dispatch) => {
    dispatch(blockerSegmentEvent(SegmentBlockersEvent.GOTO_PRICING_CLICKED, blockerType));
};

export const upgradeNowClickedSegmentEvent = (
    blockerType: ESegmentBlockerType
): ThunkAction => (dispatch: Dispatch) => {
    dispatch(blockerSegmentEvent(SegmentBlockersEvent.UPGRADE_NOW_CLICKED, blockerType));
};
export const readonlyClickedSegmentEvent = (
    blockerType: ESegmentBlockerType
): ThunkAction => (dispatch: Dispatch) => {
    dispatch(blockerSegmentEvent(SegmentBlockersEvent.READ_ONLY, blockerType));
};

export const freeVersionClickedSegmentEvent = (
    blockerType: ESegmentBlockerType
): ThunkAction => (dispatch: Dispatch) => {
    dispatch(blockerSegmentEvent(SegmentBlockersEvent.FREE_VERSION, blockerType));
};

export const goBackClickedSegmentEvent: ActionCreator<ThunkAction> = (
    blockerType: ESegmentBlockerType
) => (dispatch: Dispatch) => {
    dispatch(blockerSegmentEvent(SegmentBlockersEvent.GO_BACK_CLICKED, blockerType));
};

export const backToKanbanClickedSegmentEvent: ActionCreator<ThunkAction> = (
    blockerType: ESegmentBlockerType
) => (dispatch: Dispatch) => {
    dispatch(blockerSegmentEvent(SegmentBlockersEvent.BACK_TO_KANBAN_CLICKED, blockerType));
};

export const renewSeatsClickedSegmentEvent: ActionCreator<ThunkAction> = (
    blockerType: ESegmentBlockerType
) => (dispatch: Dispatch) => {
    dispatch(blockerSegmentEvent(SegmentBlockersEvent.RENEW_SEATS_CLICKED, blockerType));
};

export const manageSubscriptionsClickedSegmentEvent: ActionCreator<ThunkAction> = (
    blockerType: ESegmentBlockerType
) => (dispatch: Dispatch) => {
    dispatch(blockerSegmentEvent(SegmentBlockersEvent.MANAGE_SUBSCRIPTIONS_CLICKED, blockerType));
};

export const contactUsClickedSegmentEvent: ActionCreator<ThunkAction> = (
    blockerType: ESegmentBlockerType
) => (dispatch: Dispatch) => {
    dispatch(blockerSegmentEvent(SegmentBlockersEvent.CONTACT_US_CLICKED, blockerType));
};

export const extendTrialLinkClickedSegmentEvent: ActionCreator<ThunkAction> = (
    blockerType: ESegmentBlockerType
) => (dispatch: Dispatch) => {
    dispatch(blockerSegmentEvent(SegmentBlockersEvent.EXTEND_TRIAL_FOR_14_DAYS_LINK_CLICKED, blockerType));
};

export const extendTrialSubmittedSegmentEvent: ActionCreator<ThunkAction> = (
    blockerType: ESegmentBlockerType
) => (dispatch: Dispatch) => {
    dispatch(blockerSegmentEvent(SegmentBlockersEvent.EXTEND_TRIAL_SUBMITTED, blockerType));
};
