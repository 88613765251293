import { ITimeUnits } from '../../../../../../../types/model';
import * as moment from 'moment';
import DurationConstructor = moment.unitOfTime.DurationConstructor;

export const calcDateOfNotification = (
    interval: number,
    unit: ITimeUnits,
    dueDate: number,
) => {
    const timeUnit = unit.toString().toLowerCase();
    // interval of type number has to be converted to type DurationConstructor
    return moment.unix(dueDate).subtract(interval as unknown as DurationConstructor, timeUnit).unix();
};
