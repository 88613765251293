import { IApplicationState } from '../../../../types/types';
import { getAuthUser } from './getAuthUser';
import { IQuickStartGuides } from '../../../../types/rest/IUserMeta';

export const getAuthUserQuickStartGuides = (
    state: IApplicationState
): IQuickStartGuides => {
    const user = getAuthUser(state);
    return user.meta && user.meta.quickStartGuides;
};
