import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { EQuickStartGuideType } from '../../../constants';
import { authUserSetQuickStartGuides } from '../../../../../../../rest/effects/authUser/authUserSetQuickStartGuides';
import {
    getAuthUserQuickStartGuides
} from '../../../../../../../store/model/authUser/selectors/getAuthUserQuickStartGuides';
import { IGetState } from '../../../../../../../types/types';
import { segmentTrackAction, SegmentUserEvent } from '../../../../../../../middlewares/segment';
import { SegmentUserOption } from '../../../../../../../middlewares/segment/trackEntities/userEvents';

export const onFinish = (
    key: EQuickStartGuideType,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const quickStartGuides = { ...getAuthUserQuickStartGuides(getState()) };
        dispatch(authUserSetQuickStartGuides({
            ...quickStartGuides[key],
            isFinished: true
        }));
        dispatch(segmentTrackAction(SegmentUserEvent.QUICK_START_GUIDE_FINISHED, {
            name: SegmentUserOption.VALUE,
            value: key
        }));
    };
    return action;
};
