import { IApplicationState, TBoardId } from '../../../types/types';
import { TStatus } from '../../../types/model';
import { ICard } from '../card/types';
import { getList } from '../list/selectors/getList';
import { getCard } from './getCard';

export const getListCardsActiveAndArchive = (
    state: IApplicationState,
    listId: TBoardId
): ICard[] => {
    const list = getList(state, listId);
    if (
        !list ||
        !list.cardIds ||
        !list.cardIds.length
    ) return [];

    const cards = list.cardIds.reduce((arr, cardId) => {
        const card = getCard(state, cardId);
        if (
            card && (
                card.status === TStatus.STATUS_ACTIVE ||
                card.status === TStatus.STATUS_ARCHIVE
            )
        ) {
            arr.push(card);
        }
        return arr;
    }, []);
    return cards;
}
