import { connect } from 'react-redux';
import { CommentsMentions } from '../../components/CommentsMentions/CommentsMentions';
import { IApplicationState } from '../../../../../../../types/types';
import { ICommentsMentionsDispatchProps, ICommentsMentionsStateProps } from '../../components/CommentsMentions/types';
import { onMention } from './events/onMention';
import { isMentionsDropDownShown } from '../../selectors/isMentionsDropDownShown';
import { getHighlightedMentionIndex } from '../../selectors/getHighlightedMentionIndex';
import { ICommentsMentionHOCFields } from './types';
import { getCardMentionOptionsCreateSelector } from '../../selectors/getCardMentionOptions';
import { findOptionGroupIndexes } from '../../helpers/findOptionGroupIndexes';

const mapStateToProps = () => {
    const getCardMentionOptions = getCardMentionOptionsCreateSelector();

    const _mapStateToProps = (
        state: IApplicationState,
        { boardId, cardId }: ICommentsMentionHOCFields
    ): ICommentsMentionsStateProps => {
        const users = getCardMentionOptions(state, boardId, cardId);
        let selectedMention = getHighlightedMentionIndex(state);

        const indexes = findOptionGroupIndexes(users);
        if (indexes.length && !selectedMention) { // есть группы
            selectedMention = 1;
        }

        return  {
            isOpened: isMentionsDropDownShown(state),
            selectedMention,
            users,
        };
    };

    return _mapStateToProps;
};
const mapDispatchToProps = (
    dispatch: any,
    ownProps: ICommentsMentionHOCFields
): ICommentsMentionsDispatchProps => {
    return {
        onMention: (mentionIndex: number) => dispatch(onMention(mentionIndex, ownProps.boardId, ownProps.cardId))
    };
};

export const CommentsMentionsHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CommentsMentions);

CommentsMentionsHOC.displayName = 'CommentsMentionsHOC';
