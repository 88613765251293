import { IApplicationState } from '../../../../types/types';
import { getAuthUser } from './getAuthUser';
import { IProFeaturesUsage } from '../../../../types/rest/IUserMeta';

export const getUserProFeaturesUsage = (
    state: IApplicationState
): IProFeaturesUsage => {
    const user = getAuthUser(state);
    return user.meta && user.meta.proFeaturesUsage || { time: 0, usedFeatures: [] };
};
