import { IApplicationState, TCardId } from '../../../types/types';
import { ERoutes } from '../constants';
import { createSelector } from 'reselect';
import { IRouterState } from '../reducers/types';
import { root } from '../../constants';

/**
 * if cardPanel opened: details, activity, timing
 */
const getActiveCardIdSelector = (
    routerState: IRouterState
): TCardId => {
    if (root._debug.selector) {
        console.log('getActiveCardIdSelector');
    }
    const { route, args } = routerState;
    if (
        route !== ERoutes.EDIT_CARD &&
        route !== ERoutes.CARD_ACTIVITY &&
        route !== ERoutes.CARD_TIMING &&
        route !== ERoutes.ASSIGNED_TO_ME_CARD
    ) return null;

    const cardId = route === ERoutes.ASSIGNED_TO_ME_CARD ? args[1] : args[2];
    if (!cardId) return null;

    return parseInt(cardId);
}

export const getActiveCardIdCreateSelector = (
) => createSelector(
    (state: IApplicationState, routerState: IRouterState) =>  routerState || state.router,
    getActiveCardIdSelector
);

export const getActiveCardId: (state: IApplicationState, props?: IRouterState) => number  = getActiveCardIdCreateSelector();
