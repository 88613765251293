import * as React from 'react';
import './_ProfileUser.scss';
import { Icon, Tooltip } from 'kui';
import { IProfileUserProps } from './types';
import { escape } from 'underscore';
import { CLASS_PROFILE_DROPDOWN } from '../../constants';
import { UserpicStorage } from 'app/view/react_components/base/components/UserpicStorage/UserpicStorage';
import { NewFeatureIndicator } from 'app/view/react_components/base/components/NewFeatureIndicator/NewFeatureIndicator';
import { EAuthUserPlatformType } from '../../../../../types/rest/IRestAuthUser';
import { getMessages } from '../../../../../store/constants';
import { PROFILE_GOOGLE, PROFILE_MS } from './constants';

export function ProfileUser ({
    id,
    isNewFeature,
    email,
    fullName,
    initials,
    photoUrl,
    platformType,
    tooltipDirection = 'up-right',
}: IProfileUserProps) {
    const className = CLASS_PROFILE_DROPDOWN + '__user';
    const classNameColumn = CLASS_PROFILE_DROPDOWN + '__user-column';
    const classNameColumnUserpic = classNameColumn + ' ' + classNameColumn + '--userpic';
    const classNameColumnText = classNameColumn + ' ' + classNameColumn + '--text';
    const classNameUserName = CLASS_PROFILE_DROPDOWN + '__user-name';
    const classNameUserEmail = CLASS_PROFILE_DROPDOWN + '__user-email';
    const classPlatform = className + '-platform';

    let platformIcon;
    let tooltip;
    switch (platformType) {
        case EAuthUserPlatformType.MICROSOFT:
            platformIcon = 'microsoft';
            tooltip = getMessages().getText('profile.tooltip', null, PROFILE_MS);
            break;
        default:
            platformIcon = 'google-color';
            tooltip = getMessages().getText('profile.tooltip', null, PROFILE_GOOGLE);
    }

    return (
        <div className={className}>
            <div className={classNameColumnUserpic}>
                <UserpicStorage
                    userId={id}
                    initials={initials}
                    size={32}
                    src={photoUrl}
                    tooltip={{
                        direction: tooltipDirection,
                        value: tooltip,
                    }}
                />
                <Icon className={`${classPlatform} ${classPlatform}--${platformType}`} xlink={platformIcon} size={16} />
                {isNewFeature &&
                    <NewFeatureIndicator />
                }
            </div>
            <div className={classNameColumnText}>
                <div className={classNameUserName}>
                    {fullName}
                </div>
                <Tooltip
                    direction={'up-right'}
                    value={escape(email)}
                >
                    <div
                        className={classNameUserEmail}
                    >
                        {email}
                    </div>
                </Tooltip>
            </div>
        </div>
    );
}
