export interface ICardAging {
    enabled?: boolean;
    cardAgingSettings?: ICardAgingSettings;
}

export interface ICardAgingSettings {
    opacity?: boolean;
    indicator?: ICardAgingIndicator;
}

export interface ICardAgingIndicator {
    enabled: boolean;
    indicatorType: ECardAgingIndicatorType;
}

export enum ECardAgingIndicatorType {
    BY_ACTIVITY = 'byActivity',
    BY_LIST = 'byList'
}
