import { IGetState, TBoardId } from '../../../types/types';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { boardPatchRest } from './api/helpers/boardPatchRest';
import { IRestDashboard } from 'app/types/rest/IRestDashboard';
import { boardsActionSet } from 'app/store/model/actions/boardsActionSet';
import { boardActionSet } from 'app/store/model/boards/actions/boardActionSet';
import { updateAction } from 'app/store/model/board/actions/updateAction';

export const boardSetName = (
    boardId: TBoardId,
    name: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(boardPatchRest(boardId, {name}))
            .then((response: IRestDashboard) => {
                if (response && response.parentFolderInfo) {
                    dispatch(boardsActionSet(boardActionSet(boardId, updateAction({
                        parentFolderInfo: response.parentFolderInfo,
                    }))));
                }
                return response;
            });
    };
    return action;
}
