import { IApplicationState, TBoardId, TCardId } from '../../../types/types';
import { getBoard } from './getBoardById';

export const getBoardMetaDefaultTemplateCardId = (
    state: IApplicationState,
    boardId: TBoardId
): TCardId => {
    const board = getBoard(state, boardId);
    return board && board.meta && board.meta.defaultTemplateCardId;
}
