import { IApplicationState, TBoardId, TColorId } from '../../../types/types';
import { IColor, TStatus } from '../../../types/model';
import { createDeepEqualSelector } from '../../../view/react_components/helpers/memoizeHelper';
import { getBoardColors } from '../board/selectors/getBoardColors';
import { ICard } from '../card/types';
import { getBoardCardsByStatuses } from './getBoardCardsByStatuses';

type TgetBoardCardColorTags = (
    state: IApplicationState,
    boardId: TBoardId,
    includeArchive?: boolean
) => IColor[];

const getCardColorTagsSelector = (
    boardColors: IColor[],
    cards: ICard[],
): IColor[] => {
    let usedColorIds: Set<TColorId> = new Set<TColorId>();
    cards.forEach(card => {
        if (card.colorIds && card.colorIds.length) {
            card.colorIds.forEach(colorId => usedColorIds.add(colorId));
        }
    });
    return boardColors.filter(boardColor => usedColorIds.has(boardColor.id));
};

export const getBoardCardColorTagsCreateSelector = (
): TgetBoardCardColorTags => createDeepEqualSelector(
    (state: IApplicationState, boardId: TBoardId, includeArchive: boolean) => getBoardColors(state, boardId, includeArchive),
    (state: IApplicationState, boardId: TBoardId) => getBoardCardsByStatuses(state, boardId, [TStatus.STATUS_ACTIVE, TStatus.STATUS_SERVICE_TEMPLATE]),
    getCardColorTagsSelector,
);

export const getBoardCardColorTags = (
    state: IApplicationState,
    boardId: TBoardId,
    includeArchive: boolean,
): IColor[] => {
    return getCardColorTagsSelector(
        getBoardColors(state, boardId, includeArchive),
        getBoardCardsByStatuses(state, boardId, [TStatus.STATUS_ACTIVE, TStatus.STATUS_SERVICE_TEMPLATE])
    );
};
