import * as React from 'react';
import './_BoardLibTheme.scss';
import { IBoardLibThemeProps } from './types';
import { Icon } from 'kui';
import { CLASS_BOARD_BRANDING } from '../../../../../../aside_panel/boardDetails/constants';

export function BoardLibTheme ({
    name,
    active,
    onClick
}: IBoardLibThemeProps) {
    const className = CLASS_BOARD_BRANDING + '__theme';
    const classNameName = CLASS_BOARD_BRANDING + '__' + name;
    const classNameActive = active ? className + '--active' : '';
    const classIcon = CLASS_BOARD_BRANDING + '__theme-icon';

    return (
        <div
            className={`
                ${className}
                ${classNameName}
                ${classNameActive}
            `}
            onClick={onClick}
        >
            <Icon
                className={classIcon}
                xlink={'done'}
                size={24}
            />
        </div>
    );
};
