import { boardStarredClickedSegmentEvent, ESegmentBoardMenuLocation } from 'app/middlewares/segment/trackEntities/boardMenuEvents';
import { boardSetStarred } from 'app/rest/effects/board/boardSetStarred';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TBoardId } from '../../../../../../../../types/types';

export const onUnstar = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(boardSetStarred(boardId, false));
        dispatch(boardStarredClickedSegmentEvent(ESegmentBoardMenuLocation.navPanelFavorite));
    };
    return action;
};
