import {AsidePanelActionsButton} from '../../../../asidePanel/components/AsidePanelActionsButton/AsidePanelActionsButton';
import {getAsidePanelActionsButtonTooltip} from '../../../../asidePanel/helpers/getAsidePanelActionsButtonTooltip';
import * as React from 'react';
import {IArchiveButton} from './types';
import {ARCHIVE_TITLE, UNARCHIVE_TITLE} from './constants';
import {CLASS_ASIDE_PANEL_ACTIONS_BUTTON} from '../../../../asidePanel/components/AsidePanelActionsButton/constants';

export function ArchiveButton({
    isArchived,
    isBasicFeature,
    isDisallow,
    isProFeature,
    isShow,
    isShowTooltip,
    className,
    onClick,
    onUnarchive
}: IArchiveButton) {
    if (!isShow) return null;
    const classNameArchive = CLASS_ASIDE_PANEL_ACTIONS_BUTTON + '--archive';

    return <>
        {isArchived
            ? <AsidePanelActionsButton
                icon={'archive'}
                title={UNARCHIVE_TITLE}
                tooltip={isShowTooltip ? getAsidePanelActionsButtonTooltip({
                    direction: 'down',
                    value: UNARCHIVE_TITLE
                }) : null}
                onClick={onUnarchive}
                className={classNameArchive}
                isProFeature={isProFeature}
                isBasicFeature={isBasicFeature}
            /> : <AsidePanelActionsButton
                className={`
                    ${className}
                    ${classNameArchive}
                `}
                icon={'archive'}
                isDisabled={isDisallow}
                title={ARCHIVE_TITLE}
                tooltip={isShowTooltip ? getAsidePanelActionsButtonTooltip({
                    direction: 'down',
                    value: ARCHIVE_TITLE
                }) : null}
                isProFeature={isProFeature}
                isBasicFeature={isBasicFeature}
                onClick={onClick}
            />
        }
    </>
}
