import { TBoardId } from '../../../../../../../../../types/types';
import { ThunkAction } from '../../../../../../../../../types/actions';
import { fetchHandler } from '../../../../../../../../../util/fetchHandler';
import { IRestBoardCardNumber } from '../types';
import Util from '../../../../../../../../../util/util';
import { boardsActionSet } from '../../../../../../../../../store/model/actions/boardsActionSet';
import { boardActionSet } from '../../../../../../../../../store/model/boards/actions/boardActionSet';
import { updateAction } from '../../../../../../../../../store/model/board/actions/updateAction';

export const loadNumberNextValue = (
    boardId: TBoardId
): ThunkAction => {
    return (
        dispatch
    ) => {
        return fetchHandler<IRestBoardCardNumber>(
            Util.getApiUrl(`/rest/dashboards/${boardId }/cardNumberNextValue`)
        ).then((result: IRestBoardCardNumber) => {
            if (result.cardNumberNextValue) {
                dispatch(boardsActionSet(boardActionSet(boardId, updateAction({ // сохраним в сторе самое актуальное значение с бекенда
                    cardNumberNextValue: result.cardNumberNextValue
                }))));
            }
            return result;
        })
    }
}
