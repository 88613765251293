import { TCardId } from '../../../../types/types';
import { checklistsActionSetAction } from '../../actions/checklistsActionSetAction';
import { IChecklist } from '../checklist/types';
import { checklistsUpdateAction } from '../checklists/actions/checklistsUpdateAction';

export const checklistUpdateStore = (
    cardId: TCardId,
    checklist: IChecklist
) => {
    return checklistsActionSetAction(checklistsUpdateAction(cardId, {
        [checklist.id]: checklist
    }));
};
