import { ICardAssigneeRole } from '../../../../types/model';
import { COLOR_ROLES } from '../../../../view/react_components/aside_panel/boardDetails/details/Roles/constants';
import { getBoardRoles } from './getBoardRoles';
import { IBoard } from '../types';
import { createSelector } from 'reselect';
import { getBoard } from '../../selectors/getBoardById';
import { EMPTY_ARRAY } from '../../../../types/types';
import { root } from '../../../constants';

/**
 * board.cardAssigneeRoles отсортированные как в boardSettings
 */

export const getBoardRolesSorted = (
    board: IBoard,
): ICardAssigneeRole[] => {
    if (root._debug.selector) {
        console.log('getBoardRolesSorted');
    }
    const boardRoles = getBoardRoles(board);
    if (!boardRoles.length) return EMPTY_ARRAY;

    const rolesMap: Record<string, ICardAssigneeRole> = {}; // сначала сделаем мапу
    boardRoles.forEach(role => {
        if (!rolesMap[role.color]) {
            rolesMap[role.color] = role
        }
    });

    const rolesSorted = COLOR_ROLES.reduce((roles, role) => { // отсортировать как в boardSettings
        if (rolesMap[role]) roles.push(rolesMap[role]);
        return roles;
    }, []);

    return rolesSorted.length ? rolesSorted : EMPTY_ARRAY;
};

export const getBoardRolesSortedCreateSelector = (
) => createSelector(
    getBoard,
    getBoardRolesSorted
);
