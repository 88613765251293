import * as React from 'react';
import { IRecurringHeaderProps } from './types';
import './RecurringHeader.scss';
import { getTextPeriod } from '../../helper/getTextPeriod';
import { getTextTime } from '../../helper/getTextTime';
import { Tooltip } from 'kui';

export function RecurringHeader({
    recurringTask,
    listName,
    isUser12HourFormat,
    showPeriodTime
}: IRecurringHeaderProps) {
    const recurringHeaderClass = 'recurring-header';
    const recurringHeaderInnerClass = recurringHeaderClass + '__inner';
    const recurringHeaderText = recurringHeaderClass + '__text';
    const recurringHeaderList = recurringHeaderClass + '__list';
    const period = getTextPeriod(recurringTask);
    const time = getTextTime(recurringTask, isUser12HourFormat);

    return (
        <div className={recurringHeaderClass}>
            <div className={recurringHeaderInnerClass}>
                {showPeriodTime &&
                <Tooltip
                    direction={'up-right'}
                    value={`${period} ${time}`}
                >
                    <div className={recurringHeaderText}>
                        {period} {time}
                    </div>
                </Tooltip>
                }
                {!showPeriodTime &&
                <div className={recurringHeaderText}></div>
                }
                <div className={recurringHeaderList}>{listName}</div>
            </div>
        </div>
    )
}
