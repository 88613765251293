import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PromoCodePopupHOC } from '../promoCode/hocs/PromoCodePopup/PromoCodePopupHOC';
import store from '../../../store/configureStore';

export class PromoCodePopup {
    el: HTMLElement;

    constructor () {
        if (this.el) this.remove();
        this.el = document.createElement('div');
        this.el.className = 'promo-code-popup-placeholder';
        document.body.append(this.el);
        this.render();
    }

    render () {
        ReactDOM.render(
            <Provider store={store}>
                <PromoCodePopupHOC />
            </Provider>
            , this.el);
    }

    remove () {
        if (!this.el) return;

        ReactDOM.unmountComponentAtNode(this.el);
        this.el.remove();
        this.el = null;
    }
}
