import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TBoardId, TCardId } from '../../../../../../../../types/types';
import { openCardOnBoard } from '../effects/openCardOnBoard';
import { SegmentCardEvent, SegmentCardOptions, SegmentCardSection, segmentTrackAction } from 'app/middlewares/segment';

export const onViewOnBoard: ActionCreator<ThunkAction> = (
    cardId: TCardId,
    boardId: TBoardId
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(openCardOnBoard(cardId, boardId));

        dispatch(segmentTrackAction(SegmentCardEvent.CARD_DROPDOWN_BUTTON_CLICKED, {
            name: SegmentCardOptions.TARGET,
            value: SegmentCardSection.VIEW_ON_BOARD
        }))
    };
    return action;
};
