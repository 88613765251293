import * as React from 'react';
import { ICardAuthorProps } from './types';
import './_CardAuthor.scss';
import { CLASS_CARD_DETAILS } from '../../../constants';
import Util from '../../../../../../../util/util';
import { AUTHOR, TEMPLATE_TOOLTIP, TIME_AT } from './constants';
import { getAsidePanelTooltip } from '../../../../asidePanel/helpers/getAsidePanelTooltip';
import { UserpicStorage } from 'app/view/react_components/base/components/UserpicStorage/UserpicStorage';
import { getInitials } from 'app/view/react_components/helpers/userPhotoHelper';
import { Button, Icon } from 'kui';
import { AnalyzeRender } from '../../../../../helpers/memoizeHelper';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { escape } from 'underscore';
import { stripHtml } from 'app/view/react_components/base/helpers/stripHtmlHelper';
import { markdown } from 'app/helper/markdownHelper';

//@ts-ignore
const analyzeRender = window.Settings.development ? new AnalyzeRender(`CardAuthor`) : null;

export function CardAuthor({
    author,
    cardName,
    created,
    photoUrl,
    onClick
}: ICardAuthorProps) {
    const { cardId } = React.useContext(AsidePanelContext);

    if (analyzeRender) analyzeRender.call(`CardAuthor ${cardId}`);

    const CLASS_CARD_DETAILS_AUTHOR = CLASS_CARD_DETAILS + '__author';
    const classNameColumn = CLASS_CARD_DETAILS + '-author__column';
    const classNameColumnUserpic = classNameColumn + ' ' + classNameColumn + '--userpic';
    const classNameColumnText = classNameColumn + ' ' + classNameColumn + '--text';
    const classNameAuthorName = CLASS_CARD_DETAILS + '__author-name';
    const classNameCreated = CLASS_CARD_DETAILS + '__created';
    const classNameTemplateLink = CLASS_CARD_DETAILS + '__template-link';

    const createdAt = Util.formatDateMoment(created) + ' ' + TIME_AT + ' ' + Util.formatTime(created);

    const onClickLinkHandler = (event: React.MouseEvent) => {
        event.preventDefault();
        onClick();
    };

    const [_cardName, setCardName] = React.useState('');
    React.useEffect(() => {
        setCardName(cardName ? escape(stripHtml(markdown(cardName))) : '');
    }, [cardName]);

    return (
        <div className={CLASS_CARD_DETAILS_AUTHOR}>
            <div className={classNameColumnUserpic}>
                {!!author &&
                    <UserpicStorage
                    initials={getInitials(author)}
                        size={32}
                        src={photoUrl || author.photoUrl}
                        tooltip={getAsidePanelTooltip({
                            direction: 'up-right',
                            value: `${AUTHOR}<br>${author.fullName}`
                        })}
                        userId={author.permissionId}
                    />
                }
            </div>
            <div className={classNameColumnText}>
                <div className={classNameCreated}>
                    {createdAt}
                    {!!_cardName &&
                        <Button
                            className={classNameTemplateLink}
                            tooltip={getAsidePanelTooltip({
                                direction: 'up',
                                isNoWrap: true,
                                value: `${TEMPLATE_TOOLTIP} "${_cardName}"`
                            })}
                            variant={'icon'}
                            onClick={onClickLinkHandler}
                        >
                            <Icon
                                size={16}
                                xlink={'card'}
                            />
                        </Button>
                    }
                </div>
                <div className={classNameAuthorName}>
                    {!!author && author.fullName}
                </div>
            </div>
        </div>
    );
};
