import { EHintKey } from '../../../../../../hints';
import { setHintCanShow } from '../../../../../../hints/effects/setHintCanShow';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../types/types';

export const onDidMount = (): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(setHintCanShow(EHintKey.NEW_COLOR_TAGS));
    };
    return action;
};
