import { ISubscriptions } from './../types';
import { ISubscriptionsUpdateAction } from './types';
import { AT_SUBSCRIPTIONS_UPDATE } from './constants';

export const subscriptionsUpdateAction = (
    subscriptions: ISubscriptions
): ISubscriptionsUpdateAction => ({
    type: AT_SUBSCRIPTIONS_UPDATE,
    subscriptions,
});
