import { IABTest } from '../../types';

export const AB_TEST_HELP_FEEDBACK_FORM = 'AB_TEST_HELP_FEEDBACK_FORM';
export enum ABHelpFeedbackFormTestValues {
    TOP = 'top',
    BOTTOM = 'bottom'
}

export const getHelpFeedbackFormConfig = (): IABTest => {
    return {
        name: AB_TEST_HELP_FEEDBACK_FORM,
        defaultValue: ABHelpFeedbackFormTestValues.TOP,
        size: 5000,
        groups: [
            {
                weight: 100,
                value: ABHelpFeedbackFormTestValues.TOP
            },
            {
                weight: 100,
                value: ABHelpFeedbackFormTestValues.BOTTOM
            }
        ],
        isRelevant: () => true,
        executeOnLoad: false
    }
};
