import { getNumberKeys } from '../../../../../../../../../helper/getNumberKeys';
import { cardRestGetPinnedSections } from '../../../../../../../../../rest/effects/card/card/api/helper/cardRestGetPinnedSections';
import { getCardChecklists } from '../../../../../../../../../store/model/checklists/checklists/selectors/getCardChecklists';
import { getCardPinnedChecklists } from '../../../../../../../../../store/model/selectors/getCardPinnedChecklists';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../../../../../types/types';

export const onDidMount = (
    cardId: TCardId
): ThunkAction => {
    return (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const pinnedChecklistIds = getCardPinnedChecklists(state, cardId);
        if (pinnedChecklistIds.length) {
            const cardChecklists = getCardChecklists(state, cardId);
            const checklistIds = getNumberKeys(cardChecklists);
            const needLoad = pinnedChecklistIds.reduce((notFound, id) => {
                if (!checklistIds.includes(id)) notFound = true;
                return notFound;
            }, false);
            if (needLoad) {
                dispatch(cardRestGetPinnedSections(cardId));
            }
        }
    };
};
