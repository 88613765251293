import { Dispatch, ThunkAction } from '../../../types/actions';
import { IGetState, TBoardId } from '../../../types/types';
import { getRest } from './api/getRest';
import { TBoardBackupList } from '../../../store/model/boardBackups/types';
import { backupsActionSet } from '../../../store/model/actions/backupsActionSet';
import { backupActionSet } from '../../../store/model/backups/actions/backupActionSet';
import { updateListAction } from '../../../store/model/boardBackups/actions/updateListAction';

export const loadBoardBackups = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(getRest(boardId)).then((list: TBoardBackupList) => {
            dispatch(backupsActionSet(backupActionSet(boardId, updateListAction(list))));
        });
    };
    return action;
};
