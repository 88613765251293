import { root } from 'app/store/constants';
import { Dispatch, ThunkAction } from 'app/types/actions';

export function onClickSettings(
): ThunkAction {
    const action = (
        dispatch: Dispatch
    ) => {
        root.App.controller.openUserSettings();
    };
    return action
};
