import {ITypeSwitcherLastKanbanViewSet} from './types';
import {AT_TYPE_SWITCHER_LAST_KANBAN_VIEW_SET} from './constants';
import { EViewTypes } from '../../../../../const';
import { TBoardId } from 'app/types/types';

export const typeSwitcherLastKanbanViewSet = (
    boardId: TBoardId,
    viewType: EViewTypes
): ITypeSwitcherLastKanbanViewSet => ({
        type: AT_TYPE_SWITCHER_LAST_KANBAN_VIEW_SET,
        boardId,
        viewType
    }
);
