import { IGetState, TBoardId, TCardId } from '../../../../../../types/types';
import { ICardBatch } from '../types';
import { Dispatch, ThunkAction } from '../../../../../../types/actions';
import { getBoard } from '../../../../../../store/model/selectors/getBoardById';
import { getList } from '../../../../../../store/model/list/selectors/getList';
import { getCard } from '../../../../../../store/model/selectors/getCard';
import { cardMoveToListBatch } from '../../move/cardMoveToList';

export const processWorkingCardUpdate = (
    cardId: TCardId,
    boardId: TBoardId,
    cardBatches: { [cardId: number]: ICardBatch },
    calcOrderNumbers: boolean,
    orderNumbers: number[],
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const cardFromStore = getCard(state, Number(cardId));
        const board = getBoard(getState(), boardId);
        const workingList = board.statusLists && board.statusLists.inWorkId && getList(state, board.statusLists.inWorkId);
        if (workingList && cardFromStore.listId !== workingList.id) {
            const orderNumber = calcOrderNumbers ? undefined : cardBatches[cardId].card.orderNumber;
            cardBatches[cardId] = cardMoveToListBatch(getState, dispatch, cardFromStore.id, board.statusLists.inWorkId, orderNumber, cardBatches[cardId]);
            orderNumbers.push(cardBatches[cardId].card.orderNumber);
        }
    };
    return action;
};
