import { IApplicationState, TCardId } from '../../../types/types';
import { getRequestsState } from './getRequestsState';

export const getCardIsLoading = (
    state: IApplicationState,
    cardId: TCardId
): boolean => {
    const cards = getRequestsState(state).cards;
    if (!cards[cardId]) return false;

    return cards[cardId].isLoading;
}
