import { IApplicationState } from '../../../../../types/types';
import { TCondition } from '../../types';
import { EHintKey, getHintConfig } from '../../constants';
import { getHintCanShow } from '../../store/hint/selectors/getHintCanShow';
import { getCard } from '../../../../../store/model/selectors/getCard';
import { getShowGanttContextHints } from './getShowGanttContextHints';
import { getGanttContextHintsCardId } from '../../../aside_panel/asidePanel/store/selectors/getGanttContextHintsCardId';

export const ganttContextGanttTypeSwitcher: TCondition = (
    state: IApplicationState
) => {
    const hint = getHintConfig(EHintKey.GANTT_CONTEXT_GANTT_TYPE_SWITCHER);
    if (!hint) return false;

    if (!getShowGanttContextHints(state)) return false;

    const cardId = getGanttContextHintsCardId(state);
    const card = getCard(state, cardId);

    if (!card || !card.ganttVisibility) return false;

    return !!getHintCanShow(state, EHintKey.GANTT_CONTEXT_GANTT_TYPE_SWITCHER);
};
