import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TBoardId, TBoardPermissionId, TCardId, TChecklistId } from '../../../../../../../../types/types';
import { checklistAdd } from '../../../../../../../../rest/effects/card/checklist/checklistAdd';
import { getChecklist } from '../../../../../../../../store/model/checklists/checklists/selectors/getChecklist';
import { IRestChecklist } from '../../../../../../../../types/rest/IRestChecklist';
import { getCardPinnedChecklists } from '../../../../../../../../store/model/selectors/getCardPinnedChecklists';
import { cardTogglePinnedChecklist } from '../../../../../../../../rest/effects/card/card/cardTogglePinnedChecklist';
import {
    getChecklistItems
} from '../../../../../../../../store/model/checklists/checklist/selectors/getChecklistItems';
import { checklistItemAdd } from '../../../../../../../../rest/effects/card/checklist/checklistItemAdd';
import { getSelectedCards } from '../../../../../../clickManager/selectors/getSelectedCards';
import {
    isCardAssigneeExistByBoardPermissionId
} from '../../../../../../../../store/model/selectors/isCardAssigneeExistByBoardPermissionId';
import {
    cardToggleAssignee,
    EToggleAssigneeType
} from '../../../../AssigneesSection/hocs/AssigneesSectionHOC/effects/cardToggleAssignee';
import { getCard } from '../../../../../../../../store/model/selectors/getCard';
import { cardSetStartDateWithEpics } from '../../../../../../../../rest/effects/card/card/cardSetStartDateWithEpics';
import { cardSetDueDateWithEpics } from '../../../../../../../../rest/effects/card/card/cardSetDueDateWithEpics';
import {
    getAuthUserAllowChecklistCount
} from '../../../../../../../../store/model/authUser/selectors/getAuthUserAllowChecklistCount';
import { showSnoozeBlocker } from '../../../../../../base/effects/showSnoozeBlocker';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import {
    getCardChecklists
} from '../../../../../../../../store/model/checklists/checklists/selectors/getCardChecklists';
import {
    getAuthUserAllowChecklistItemCount
} from '../../../../../../../../store/model/authUser/selectors/getAuthUserAllowChecklistItemCount';
import { checkIfDefaultChecklistIsEmpty } from '../../CardChecklistsHOC/selectors/checkIfDefaultChecklistIsEmpty';
import { CHECKLIST_DEFAULT_ID } from '../../../../../../../../store/model/checklists/checklists/constants';

export const copyChecklist = (
    boardId: TBoardId,
    cardId: TCardId,
    srcCardId: TCardId,
    srcChecklistId: TChecklistId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();

        const cardIds = !!cardId ? [cardId] : getSelectedCards(state, boardId);

        const maxChecklistsCount = getAuthUserAllowChecklistCount(state);
        const maxChecklistsItemsCount = getAuthUserAllowChecklistItemCount(state);
        let checklistsCount = 0;
        let checklistItemsCount = 0;

        for (let i = 0; i < cardIds.length; i++) {
            const cardChecklists = getCardChecklists(state, cardIds[i]);
            let cardChecklistsCount = Object.keys(cardChecklists).length;
            if (cardChecklists[CHECKLIST_DEFAULT_ID] && checkIfDefaultChecklistIsEmpty(state, cardId)) {
                cardChecklistsCount -= 1;
            }
            if (cardChecklistsCount > checklistsCount) {
                checklistsCount = cardChecklistsCount;
            }
            for (let j = 0; j < cardChecklistsCount; j++) {
                const cardChecklistItemsCount = Object.keys(cardChecklists[j] && cardChecklists[j].checkItems || {}).length;
                if (cardChecklistItemsCount > checklistItemsCount) {
                    checklistItemsCount = cardChecklistItemsCount;
                }
                if (checklistItemsCount >= maxChecklistsItemsCount) {
                    break;
                }
            }
            if (
                checklistsCount >= maxChecklistsCount &&
                checklistItemsCount >= maxChecklistsItemsCount
            ) {
                break;
            }
        }
        if (checklistsCount >= maxChecklistsCount) {
            dispatch(showSnoozeBlocker({ allow: 'allowChecklistCount' }));
            return Promise.resolve(null);
        } else if (checklistItemsCount >= maxChecklistsItemsCount) {
            dispatch(showSnoozeBlocker({ allow: 'allowChecklistItemCount' }));
            return Promise.resolve(null);
        } else {
            dispatch(segmentTrackAction(SegmentCardEvent.CHECKLIST_COPY_OK_CLICKED));
            let newChecklist: IRestChecklist;
            const promises: Promise<any>[] = [];
            const itemPromises: Promise<any>[] = [];
            const srcChecklist = getChecklist(state, srcCardId, srcChecklistId);
            cardIds.forEach(cardId => {
                promises.push(dispatch(checklistAdd({
                        cardId,
                        isPinned: srcChecklist.isPinned,
                        name: srcChecklist.name,
                    }))
                        .then((checklist: IRestChecklist) => {
                            newChecklist = checklist;
                            const checklistItems = getChecklistItems(state, srcCardId, srcChecklistId);
                            const assignees: Record<TBoardPermissionId, any> = {}; // все ассайны из айтемов, которых нет на карте
                            const {
                                dueDate = null,
                                isMilestone = false,
                                startDate = null,
                            } = getCard(state, cardId) || {};
                            let itemsDueDate, itemsStartDate;

                            for (let itemId in checklistItems) {
                                itemPromises.push(dispatch(checklistItemAdd(cardId, {
                                    ...checklistItems[itemId],
                                    id: null,
                                    checked: false,
                                    checklistId: checklist.id,
                                })));

                                const itemAssignees = checklistItems[itemId].assignees;
                                if (!!itemAssignees && itemAssignees.length) {
                                    itemAssignees.forEach(assignee => {
                                        if (!assignees[assignee.sharedUser.permissionId] &&
                                            !isCardAssigneeExistByBoardPermissionId(state, cardId, assignee.sharedUser.permissionId)
                                        ) assignees[assignee.sharedUser.permissionId] = assignee;
                                    });
                                }

                                if (!isMilestone) {
                                    const itemDueDate = checklistItems[itemId].dueDate;
                                    const itemStartDate = checklistItems[itemId].startDate;
                                    if (itemDueDate &&
                                        (!itemsDueDate || itemsDueDate < itemDueDate)
                                    ) {
                                        itemsDueDate = itemDueDate;
                                    }
                                    if (itemStartDate &&
                                        (!itemsStartDate || itemsStartDate > itemStartDate)
                                    ) {
                                        itemsStartDate = itemStartDate;
                                    }
                                }
                            };

                            const pinnedChecklists = getCardPinnedChecklists(state, srcCardId);
                            if (pinnedChecklists.includes(srcChecklistId)) {
                                itemPromises.push(dispatch(cardTogglePinnedChecklist(cardId, checklist.id)));
                            }

                            for (let dashboardPermissionId in assignees) {
                                dispatch(cardToggleAssignee(boardId, cardId, dashboardPermissionId, EToggleAssigneeType.ADD, false));
                            };

                            if (!isMilestone) {
                                if (itemsStartDate &&
                                    (!startDate || startDate > itemsStartDate) && // новый старт раньше существующего
                                    (!dueDate || dueDate > itemsStartDate) // и новый старт не конфликтует с дью
                                ) {
                                    itemPromises.push(dispatch(cardSetStartDateWithEpics(cardId, itemsStartDate)));
                                }
                                if (itemsDueDate &&
                                    (!dueDate || dueDate < itemsDueDate) && // новый дью позже существующего
                                    (!startDate || startDate < itemsDueDate) // и новый дью не конфликтует со старт
                                ) {
                                    itemPromises.push(dispatch(cardSetDueDateWithEpics(cardId, itemsDueDate)));
                                }
                            }
                        })
                );
            });

            return Promise.all(promises)
                .then(() => {
                    return Promise.all(itemPromises);
                })
                .then(() => {
                    return newChecklist;
                });
        }
    };
    return action;
};
