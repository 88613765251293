import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../../../../types/types';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';

export const onCopy = (
    cardId: TCardId,
    onCopeOwn?: (cardId: TCardId) => void
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (onCopeOwn && cardId) onCopeOwn(cardId);
        dispatch(segmentTrackAction(SegmentCardEvent.CHECKLIST_COPY_CLICKED));
    };
    return action;
};
