import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { ICardHOCProps } from '../CardHOC/types';
import { ICardBackgroundColorEvents, ICardBackgroundColorFields } from '../../components/CardBackgroundColor/types';
import { CardBackgroundColor } from '../../components/CardBackgroundColor/CardBackgroundColor';
import { getBoardCardColorsCreateSelector } from '../../../../../../../store/model/selectors/getBoardCardColors';
import { getCardBackgroundColors } from '../../../../../../../const';
import { getBoardIdByCardId } from '../../../../../../../store/model/selectors/getBoardIdByCardId';
import { onChange } from './events/onChange';
import { onLoadCard } from '../events/onLoadCard';

const mapStateToProps = () => {
    const getBoardCardColors = getBoardCardColorsCreateSelector();

    const _mapStateTopProps = (
        state: IApplicationState,
        { card, portalId }: ICardHOCProps
    ): ICardBackgroundColorFields => {
        const boardId = getBoardIdByCardId(state, card.cardId);
        return {
            background: card.backgroundColor,
            boardColors: getBoardCardColors(state, boardId),
            colors: getCardBackgroundColors(false),
            portalId
        }
    }

    return _mapStateTopProps;
}

const mapDispatchToProps = (
    dispatch: any,
    { card }: ICardHOCProps
): ICardBackgroundColorEvents => ({
    onChange: (color) => dispatch(onChange(card.cardId, color)),
    onLoadCard: () => dispatch(onLoadCard(card.cardId))
})

export const CardBackgroundColorHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardBackgroundColor);
CardBackgroundColorHOC.displayName = 'CardBackgroundColorHOC';
