import * as React from 'react';
import { AsidePanelContext } from '../../../../../asidePanel/components/AsidePanel/constants';
import { CLASS_BOARD_DETAILS, CLASS_BOARD_DETAILS_SECTION } from '../../../../constants';
import { SettingsSection } from '../../../../../../base/components/SettingsSection/SettingsSection';
import { BoardPwcImportHOC } from '../../hocs/BoardPwcImportHOC/BoardPwcImportHOC';
import { IPwcImportSectionProps } from './types';
import { BOARD_SECTION_PWC_IMPORT } from '../../constants';
import { isPwcImportEnabled } from '../../helpers/isPwcImportEnabled';
import { getAppState } from '../../../../../../../../store/configureStore';
import { EPwcImportTypes } from '../../hocs/BoardPwcImportHOC/types';
import './_PwcImportSection.scss';

export function PwcImportSection ({
}: IPwcImportSectionProps) {
    if (!isPwcImportEnabled(getAppState())) {
        return null;
    }
    const { boardId } = React.useContext(AsidePanelContext);
    const className = CLASS_BOARD_DETAILS_SECTION + ' ' + CLASS_BOARD_DETAILS_SECTION + '--pwc-import';

    return (
        <SettingsSection
            className={className}
            title={BOARD_SECTION_PWC_IMPORT}
        >
            <BoardPwcImportHOC
                boardId={boardId}
                importType={EPwcImportTypes.SUBCARDS}
            />
            <div className={CLASS_BOARD_DETAILS + '__pwc_import_separator'}></div>
            <BoardPwcImportHOC
                boardId={boardId}
                importType={EPwcImportTypes.CHECKLISTS}
            />
            <div className={CLASS_BOARD_DETAILS + '__pwc_import_separator'}></div>
            <BoardPwcImportHOC
                boardId={boardId}
                importType={EPwcImportTypes.TEMPLATES_CHECKLISTS}
            />
        </SettingsSection>
    );
};
