import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { ICardCommentsFields } from '../../components/CommentsSection/types';
import {
    getAuthUserSettingsCommentsSort
} from '../../../../../../../store/model/authUser/selectors/getAuthUserSettingsCommentsSort';
import { CommentsSection } from '../../components/CommentsSection/CommentsSection';
import { ICommentsSectionHOCProps } from './types';
import { getCardComments } from '../../../../../../../store/model/selectors/getCardComments';
import { EUserSettingsCommentSort } from '../../../../../../../types/rest/IRestAuthUser';
import { getBoardPermissions } from '../../../../../../../store/model/selectors/getBoardPermissions';
import {
    getBoardIsCardDefaultProperty
} from '../../../../../../../store/model/board/selectors/getBoardIsCardDefaultProperty';
import { EAsidePanelProperty } from '../../../../asidePanel/components/AsidePanel/types';
import { getSectionType } from '../../../selectors/getSectionType';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';

const mapStateToProps = (
    state: IApplicationState,
    ownProps: ICommentsSectionHOCProps
): ICardCommentsFields => {
    const sort = getAuthUserSettingsCommentsSort(state);
    const {cardId, boardId} = ownProps;
    const { authPermissions: { allowComment, allowEdit } } = getBoardPermissions(state, boardId);
    const sectionType = getSectionType(state, boardId, cardId, null, null, 'allowComment');
    const isShow = !allowEdit && allowComment || // если можно только комментировать, то всегда видно
        getCardComments(state, cardId).length > 0;
    return {
        isDefaultProperty: getBoardIsCardDefaultProperty(state, boardId, EAsidePanelProperty.CARD_COMMENTS),
        isShow,
        descOrder: sort === EUserSettingsCommentSort.DESC,
        cardId,
        boardId,
        sectionType
    };
};

export const CommentsSectionHOC = connect(
    mapStateToProps,
    null
)(CommentsSection);

CommentsSectionHOC.displayName = 'CommentsSectionHOC';
