import { ICardAssigneeUpdateAction } from './types';
import { AT_CARD_ASSIGNEE_UPDATE_ACTION } from './constants';
import { ICardAssignee } from '../../cardAssignee/types';
import { TCardId } from '../../../../types/types';

export const cardAssigneeUpdateAction = (
    cardId: TCardId,
    cardAssignee: ICardAssignee
): ICardAssigneeUpdateAction => ({
    type: AT_CARD_ASSIGNEE_UPDATE_ACTION,
    cardId,
    cardAssignee
});
