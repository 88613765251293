export const SEGMENT_ACTION = 'SEGMENT_ACTION';
export const SEGMENT_IDENTIFY = 'SEGMENT_IDENTIFY';
export const SEGMENT_ALIAS = 'SEGMENT_ALIAS';
export const SEGMENT_RESET = 'SEGMENT_RESET';
export const SEGMENT_TRACK = 'SEGMENT_TRACK';

export enum ESegmentClickType {
    double = 'double',
    single = 'single'
}

export const FIRST_TRIAL_PROMO_CODE = 'Esie8lue';
export const SECOND_TRIAL_PROMO_CODE = '0J0pA1141';
