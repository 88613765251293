import { IApplicationState } from '../../../../types/types';
import { ILibTheme } from '../types';
import { getLibThemes } from './getLibThemes';

export const getLibThemesWithDefault = (
    state: IApplicationState
): ILibTheme[]  => {
    const defaultTheme: ILibTheme = {
        id: 0,
        libraryCssClass: 'theme-0'
    }
    return [defaultTheme, ...getLibThemes(state)];
}
