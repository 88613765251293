import * as React from 'react';
import { INotifyUsersProps } from './types';
import './_NotifyUsers.scss';
import { CLASS_CARD_DETAILS } from '../../../constants';
import { Button, Icon, Userpic } from 'kui';
import { NOTIFY_USERS_COLLAPSE, NOTIFY_USERS_MOBILE, NOTIFY_USERS_REMOVE, NOTIFY_USERS_SHORT_MAX, NOTIFY_USERS_SHORT_MAX_MOBILE, NOTIFY_USERS_SHOW } from './constants';
import { ListItem } from '../../../../../base/components/ListItem/ListItem';
import { getAsidePanelTooltip } from '../../../../asidePanel/helpers/getAsidePanelTooltip';
import { ID_ASIDE_PANEL_ACTIONS_PORTAL_MOBILE } from '../../../../asidePanel/components/AsidePanelActionsButton/constants';
import { UserpicStorage } from 'app/view/react_components/base/components/UserpicStorage/UserpicStorage';
import { getInitials } from 'app/view/react_components/helpers/userPhotoHelper';

export function NotifyUsers ({
    users,
    onDelete,
}: INotifyUsersProps) {
    const className = CLASS_CARD_DETAILS + '__notify-users';
    const classNameShort = CLASS_CARD_DETAILS + '__notify-users-short';
    const classNameShortList = CLASS_CARD_DETAILS + '__notify-users-short-list';
    const classNameFullList = CLASS_CARD_DETAILS + '__notify-users-full-list';
    const classNameFullListItem = CLASS_CARD_DETAILS + '__notify-users-full-list-item';
    const classNameShow = CLASS_CARD_DETAILS + '__notify-users-show';
    const classNameCollapse = CLASS_CARD_DETAILS + '__notify-users-collapse';
    const classNamePlus = CLASS_CARD_DETAILS + '__notify-users-plus';
    const [isOpened, setIsOpened] = React.useState(null);
    const stickyRef = React.useRef(null);
    const observer = React.useRef(null);
    const [usersShort, setUsersShort] = React.useState([]);

    function createObserver() {
        if (!stickyRef.current) return;
        observer.current = new IntersectionObserver(([e]) => {
                e.target.classList.toggle('pinned', e.intersectionRatio < 1)
            }, {
                root: document.querySelector(`#${ID_ASIDE_PANEL_ACTIONS_PORTAL_MOBILE} .kui-dropdown__item`), // на дектопе будет null, это ок, а на мобиле null не работает
                threshold: 1
            }
        );
        observer.current.observe(stickyRef.current);
    }

    function onShow() {
        setIsOpened(true);
        requestAnimationFrame(createObserver); // когда элементы появятся в доме
    }

    function onCollapse() {
        setIsOpened(false);
        if (observer.current) observer.current.disconnect();
    }

    React.useEffect(() => {
        // @ts-ignore
        const max = window._innerWidth && window._innerWidth < NOTIFY_USERS_MOBILE ? NOTIFY_USERS_SHORT_MAX_MOBILE : NOTIFY_USERS_SHORT_MAX;
        setUsersShort(users.length > max + 1
            ? users.slice(0, max)
            : users
        );
    }, [users]);

    return (
        <div className={ className }>
            {isOpened
                ? <>
                    <div className={ classNameFullList }>
                        {users.map(user => {
                            const actions = onDelete
                                ? <Button
                                    variant={'icon'}
                                    tooltip={ getAsidePanelTooltip({
                                        direction: 'up-left',
                                        value: NOTIFY_USERS_REMOVE,
                                    })
                                    }
                                    onClick={() => onDelete(user.permissionId)}
                                >
                                    <Icon size={24} xlink={'clear'} />
                                </Button>
                                : null;

                            const img = (
                                <UserpicStorage
                                    initials={getInitials(user)}
                                    size={24}
                                    src={user.photoUrl}
                                    userId={user.permissionId}
                                />
                            );
                            return (<ListItem
                                className={ classNameFullListItem }
                                actions={actions}
                                img={img}
                                key={user.permissionId}
                                text={user.fullName}
                                variant={'noborder'}
                            />);
                        })}
                    </div>
                    <div className={classNameCollapse} ref={stickyRef}>
                        <Button
                            variant={'icon-text'}
                            text={NOTIFY_USERS_COLLAPSE}
                            onClick={onCollapse}
                        >
                            <Icon
                                xlink={'arrow-s-drop-close'}
                                size={24}
                            />
                        </Button>
                    </div>
                </>
                : <div className={ classNameShort }>
                    <div className={ classNameShortList }>
                        {usersShort.map(user => (
                            <UserpicStorage
                                initials={getInitials(user)}
                                key={user.permissionId}
                                size={32}
                                src={user.photoUrl}
                                tooltip={getAsidePanelTooltip({
                                    value: user.fullName,
                                })}
                                userId={user.permissionId}
                            />
                        ))}
                        {users.length > usersShort.length &&
                            <div className={classNamePlus}>
                                +{users.length - usersShort.length}
                            </div>
                        }
                    </div>
                    <div className={classNameShow}>
                        <Button
                            variant={'icon-text'}
                            text={NOTIFY_USERS_SHOW}
                            onClick={onShow}
                        >
                            <Icon
                                xlink={'arrow-s-drop-open'}
                                size={ 24 }
                            />
                        </Button>
                    </div>
                </div>
            }
        </div>
    );
};
