import { IGetState, TCardId, TChecklistId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IRestChecklistItem } from '../../../../types/rest/IRestChecklistItem';
import { itemsRestPatch } from './api/itemsRestPatch';
import { checklistItemsUpdateStore } from '../../../../store/model/checklists/actionCreators/checklistItemsUpdate';
import { getChecklistItems } from '../../../../store/model/checklists/checklist/selectors/getChecklistItems';
import { sendRealTimeStoreActionByCardId } from '../../../../view/react_components/base/helpers/realTimeHelperTS';
import { cardChecklistStatsUpdate } from '../../../../store/model/effects/cardChecklistStatsUpdate';
import { ICards } from '../../../../store/model/cards/types';
import { cardsActiveRequestsUpdate } from '../../../../store/requestsState/effects/cardsActiveRequestsUpdate';

export const checklistItemsUpdate = (
    cardId: TCardId,
    checklistId: TChecklistId,
    items: IRestChecklistItem[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const checklistItems = getChecklistItems(state, cardId, checklistId);
        if (!checklistItems) return Promise.reject();

        const itemsForRollback = {...checklistItems};
        const itemsForUpdate = {...checklistItems};
        items.forEach(item => {
            itemsForUpdate[item.id] = {
                ...itemsForUpdate[item.id],
                ...item,
            }
        });
        const action = checklistItemsUpdateStore(cardId, checklistId, itemsForUpdate);
        dispatch(action);

        const cards: ICards = {[cardId]: null};
        dispatch(cardsActiveRequestsUpdate(cards, 1));

        return dispatch(itemsRestPatch(cardId, items))
            .then(() => {
                dispatch(sendRealTimeStoreActionByCardId(cardId, action));
                dispatch(cardChecklistStatsUpdate(cardId));
            })
            .catch((e: any) => {
                console.error(e);
                dispatch(checklistItemsUpdateStore(cardId, checklistId, itemsForRollback));
            })
            .finally(() => {
                dispatch(cardsActiveRequestsUpdate(cards, -1));
            });
    };
    return action;
};
