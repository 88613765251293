import { IGetState, TCardId } from '../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { fetchHandler } from '../../../../../util/fetchHandler';
import Util from '../../../../../util/util';
import { REST_CARD, REST_CARD_CHECKLIST_ITEMS } from '../../../../constants';
import { getRestHeadersPatch } from '../../../../helpers/getRestHeadersHelper';
import { IRestChecklistItem } from '../../../../../types/rest/IRestChecklistItem';

export const itemsRestPatch = (
    cardId: TCardId,
    items: IRestChecklistItem[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return fetchHandler<IRestChecklistItem[]>(
            Util.getApiUrl(REST_CARD + cardId + '/' + REST_CARD_CHECKLIST_ITEMS), {
                ...getRestHeadersPatch(),
                body: JSON.stringify(items)
            }
        );
    };
    return action;
};
