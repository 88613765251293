import { EMPTY_ARRAY, IApplicationState, TBoardId, TCardId } from '../../../../../../../../types/types';
import { IComment, TCommentId } from '../../../../../../../../store/model/card/types/IComment';
import { createDeepEqualSelector } from '../../../../../../helpers/memoizeHelper';
import { ISharedUser } from '../../../../../../../../types/rest/ISharedUser';
import { getCommentById } from '../../../selectors/getCommentById';
import { getBoardUsers } from '../../../../../../../../store/model/selectors/getBoardUsers';

type TgetCommentLikes = (
    state: IApplicationState,
    boardId: TBoardId,
    cardId: TCardId,
    commentId: TCommentId,
) => ISharedUser[];

export const getCommentLikesSelector = (
    comment: IComment,
    boardUsers: ISharedUser[]
): ISharedUser[] => {
    const likes = comment.meta && comment.meta.likes || EMPTY_ARRAY;
    return likes
        .map(permissionId => boardUsers.find(user => user.permissionId === permissionId))
        .filter(like => !!like); // KNB-3947 юзер может быть удален
};

export const getCommentByIdCreateSelector = (
): TgetCommentLikes => createDeepEqualSelector(
    (state: IApplicationState, boardId: TBoardId, cardId: TCardId, commentId: TCommentId) => getCommentById(state, cardId, commentId),
    getBoardUsers,
    getCommentLikesSelector
);
