import { IApplicationState, TListId } from '../../../../types/types';
import { IList } from '../types';

export const getList = (
    state: IApplicationState,
    listId: TListId
): IList => {
    const list = state.model.lists[listId];
    return list;
}
