'use strict';

import { getAuthUser } from '../../store/model/authUser/selectors/getAuthUser';
import { getAppState } from '../../store/configureStore';
import { ERestActivityPropertyActionType } from '../../types/rest/activity/IRestActivityBase';
import { blockMarkdown } from '../../helper/markdownHelper';

App.Views.CardAddCommentNotification = App.Views.BaseNotification.extend({

    getActions: function() {
        let comment = blockMarkdown(this.notificationModel.newValue);
        if (this.notificationModel.propertyActionType === ERestActivityPropertyActionType.DELETE) {
            comment = blockMarkdown(this.notificationModel.oldValue);
            comment = '<strike>' + comment + '</strike>';
        }
        var action = {
            action: Messages.getText('noty.card_comment'),
            actionData: {
                icon: 'comment'
            },
            bodyRaw: comment
        };                
        
        if (this.isMe()) {
            action.action = Messages.getText('noty.card_comment_mention_my');
            action.actionData.icon = 'mention';
            action.body = action.bodyRaw.replace(/<[^>]+>/g, '');
            action.bodyRaw = this.findMentions(action.body);
        }

        if (this.isTimeTracker()) {
            action.action = Messages.getText('noty.card_comment_timetracker');
            action.actionData.icon = 'estimate';
            action.body = action.bodyRaw.replace(/<[^>]+>/g, '');
            action.bodyRaw = this.findTimeTracker(action.body);
        }

        return action;
    },

    findMentions: function (src) {
        var words = src.split(' ');
        var wordsMarked = words.map(function (word) {
            if (word[0] !== '@') return word;
            return '<span class="markdown_mention">' + word.slice(1).replace('.', ' ') + '</span>';
        });
        return wordsMarked.join(' ');
    },

    findTimeTracker: function (src) {
        var marked = src.split('').map(function (chr) {
            if (chr === '[') return '<span class="markdown_ttracker">';
            if (chr === ']') return '</span>';
            return chr;
        });
        return marked.join('');
    },

    isMe: function () {
        return (this.notificationModel.mentions && this.notificationModel.mentions.includes(getAuthUser(getAppState()).permissionId));
    },

    isTimeTracker: function () {
        return (this.notificationModel.newValue && this.notificationModel.newValue.indexOf('[') > -1);
    },

    onClick: function(e, notification) {
        var dashboardId = this.notificationModel.dashboardId;
        var cardId = this.notificationModel.card.id;
        App.controller.cleverOpenCard(dashboardId, cardId);
        this.closeNotification(notification);
        e.stopPropagation();
    }
});
