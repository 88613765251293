import { Dispatch, ThunkAction } from 'app/types/actions';
import { IGetState, TBoardId } from 'app/types/types';
import { topBarNameStatusSet } from '../../../store/topBar/actions/topBarNameStatusSet';
import { topBarSetAction } from '../../../store/topBarBoard/actions/topBarSetAction';
import { EBoardNameStatus } from '../../../store/topBar/types';
import {
    renameBoardFromDblClickSegmentEvent,
    renameBoardFromMenuSegmentEvent
} from '../../../../../dialogs/openBoards/effects/segmentEvents';
import { getBoardPermissionsAllowEdit } from 'app/store/model/selectors/getBoardPermissionsAllowEdit';
import { updateBoardName } from 'app/rest/effects/board/updateBoardName';

export const onNameChanged = (
    boardId: TBoardId,
    name: string,
    dblClicked: boolean,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const isReadOnly = !boardId || !getBoardPermissionsAllowEdit(state, boardId );
        if (isReadOnly) return;

        dispatch(topBarSetAction(boardId, topBarNameStatusSet(EBoardNameStatus.syncing)));
        return dispatch(updateBoardName(boardId, name))
            .then(() => {
                dispatch(renameBoardFromMenuSegmentEvent());
                dispatch(topBarSetAction(boardId, topBarNameStatusSet(EBoardNameStatus.synced)));
            })
            .catch(() => {
                dispatch(topBarSetAction(boardId, topBarNameStatusSet(EBoardNameStatus.idle)));
            })
            .finally(() => {
                if(dblClicked){
                    dispatch(renameBoardFromDblClickSegmentEvent());
                }else{
                }
            });

    };
    return action;
};
