import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState, TBoardId } from '../../../../../../../types/types';
import {
    boardCloseClickedSegmentEvent,
    ESegmentBoardMenuLocation
} from '../../../../../../../middlewares/segment/trackEntities/boardMenuEvents';
import { boardClose } from '../../../../../base/effects/boardClose';

export const onBoardMenuClose: ActionCreator<ThunkAction> = (
    boardId: TBoardId
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const promise: Promise<any> = dispatch(boardClose(boardId));
        dispatch(boardCloseClickedSegmentEvent(ESegmentBoardMenuLocation.leftPanel));
        return promise;
    };
    return action;
};
