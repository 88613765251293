import { IListSelectProps } from './types';
import * as React from 'react';
import { CARD_IN_LIST_TITLE } from './constants';
import './_ListSelect.scss';
import { CLASS_CARD_DETAILS } from '../../../constants';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { v4 as uuidv4 } from 'uuid';
import { SearchSelect } from '../../../../../base/components/SearchSelect/SearchSelect';
import { Tooltip} from 'kui/index';
import { ISearchSelectOptionProps } from '../../../../../base/components/SearchSelect/types';
import { markdown } from '../../../../../../../helper/markdownHelper';
import { getAsidePanelTooltip } from 'app/view/react_components/aside_panel/asidePanel/helpers/getAsidePanelTooltip';
import { AnalyzeRender } from 'app/view/react_components/helpers/memoizeHelper';

// @ts-ignore
const analyzeRender = window.Settings.development ? new AnalyzeRender(`ListSelect`) : null;

export function ListSelect ({
    options,
    text,
    value,
    onOpened,
    onSelect
}: IListSelectProps) {
    if (analyzeRender) analyzeRender.call(`${text}`);

    const { isMobile } = React.useContext(AsidePanelContext);
    const [key, setKey] = React.useState(uuidv4);
    const CLASS_LIST_SELECT = CLASS_CARD_DETAILS + '__list-select';
    if (!isMobile) text = CARD_IN_LIST_TITLE + text;

    const classItemText = CLASS_LIST_SELECT + '-text';

    function onSelectHandler (listId: string) {
        onSelect(Number(listId));
        setKey(uuidv4()); // fix: при выборе листа с таким же именем инпут оставался пустой
    }

    function getOptionWithTooltip({
        text,
        value
    }: ISearchSelectOptionProps) {
        const markdownText = markdown(text);
        return (
            <li
                key={value}
                value={value}
            >
                <Tooltip
                    {...getAsidePanelTooltip({
                    direction: 'up-left',
                    value: markdownText,
                    isNoWrap: true,
                })}
                >
                    <span className={classItemText}>
                        {text}
                    </span>
                </Tooltip>
            </li>
        );
    };

    return (
        <SearchSelect
            activeValue={value}
            className={CLASS_LIST_SELECT}
            isFitWindow={true}
            key={key}
            label={CARD_IN_LIST_TITLE}
            opened={false}
            options={options}
            option={getOptionWithTooltip}
            text={text}
            variant={'arrow'}
            onOpened={onOpened}
            onSelect={onSelectHandler}
        />
    )
}
