import { IGetState, TCardId, TCustomPropertyId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { getCardCustomProperties } from '../../../../../../../../store/model/selectors/getCardCustomProperties';
import { cardSetCustomProperties } from '../../../../../../../../rest/effects/card/card/cardSetCustomProperties';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';

export const togglePin = (
    cardId: TCardId,
    id: TCustomPropertyId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const cardCustomProperties = {...getCardCustomProperties(getState(), cardId)};
        const pinned = cardCustomProperties[id] ? cardCustomProperties[id].pinned : false;
        const customProperty = cardCustomProperties[id] || {id: id};
        cardCustomProperties[id] = {
            ...customProperty,
            pinned: !pinned
        };
        dispatch(segmentTrackAction(pinned ? SegmentCardEvent.CUSTOM_PROPERTY_UNPINNED : SegmentCardEvent.CUSTOM_PROPERTY_PINNED));
        return dispatch(cardSetCustomProperties(cardId, cardCustomProperties));
    };
    return action;
};
