import { IApplicationState } from '../../../../../types/types';
import { connect } from 'react-redux';
import { Blanker } from '../components/Blanker';
import { IBlankerEvents, IBlankerFields } from '../components/types';
import { getUserRecentBoards } from '../../../../../store/model/authUser/selectors/getUserRecentBoards';
import { onBoardOpen } from './events/onBoardOpen';
import { onCardOpen } from './events/onCardOpen';
import { getInWorkCard } from '../../../../../store/model/selectors/getInWorkCard';
import { getIsLinkCardActive } from '../../../../../store/router/selectors/getIsLinkCardActive';

const mapStateToProps = (
    state: IApplicationState,
): IBlankerFields => {
    return {
        isShow: !getIsLinkCardActive(state),
        recentBoard: getUserRecentBoards(state)[0],
        inWorkCard: getInWorkCard(state)
    }
};

export const mapDispatchToProps = (
    dispatch: any
): IBlankerEvents => ({
    onBoardOpen: (boardId) => dispatch(onBoardOpen(boardId)),
    onCardOpen: (card) => dispatch(onCardOpen(card))
});

export const BlankerHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(Blanker);

BlankerHOC.displayName = 'BlankerHOC';
