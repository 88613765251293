import { IGetState, TCardId } from '../../types/types';
import { TStatus } from '../../types/model';
import { Dispatch, ThunkAction } from '../../types/actions';
import { getCard } from '../../store/model/selectors/getCard';
import { sendCardStatWithIncrement } from '../../helper/comet/stat_helper_ts';
import { getList } from '../../store/model/list/selectors/getList';

interface IListStatistic {
    [listId: number]: {
        cardCountDelta: number;
        cardDoneCountDelta: number;
    }
}

export const cardStatusSendStatistic = (
    cardIds: TCardId[],
    status: TStatus
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const listStatistic: IListStatistic = {};
        cardIds.forEach(cardId => {
            const card = getCard(state, cardId);
            const doneDelta = card.processingStatus === 'done' ? 1 : 0;
            let stat = listStatistic[card.listId];
            if (!stat) {
                stat = {
                    cardCountDelta: 1,
                    cardDoneCountDelta: doneDelta
                }
            } else {
                stat.cardCountDelta++;
                stat.cardDoneCountDelta += doneDelta;
            }
            listStatistic[card.listId] = stat;
        });
        for (let listId in listStatistic) {
            const stat = listStatistic[listId];
            const cardCountDelta = status === TStatus.STATUS_ACTIVE ? stat.cardCountDelta : -stat.cardCountDelta;
            const cardDoneCountDelta = status === TStatus.STATUS_ACTIVE ? stat.cardDoneCountDelta : -stat.cardDoneCountDelta;
            dispatch(sendCardStatWithIncrement(getList(state, Number(listId)), cardCountDelta, cardDoneCountDelta));
        }
    };
    return action;
};
