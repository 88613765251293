import { IGetState, TCardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { sortAttachments } from '../../../../../../../../rest/effects/card/attacments/sortAttacments';
import { getMessages, root } from '../../../../../../../../store/constants';

export const onSort = (
    cardId: TCardId,
    docId: number,
    index: number,
    docIds: number[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const collection = [...docIds];
        const i = collection.indexOf(docId);
        if (i > -1) collection.splice(i, 1);
        collection.splice(index, 0, docId);

        dispatch(sortAttachments(cardId, collection));
        root.App.controller.trackEvent(
            getMessages().getText('ga.category.card'),
            getMessages().getText('ga.action.card.drive_docs_order_changed'));
    };
    return action;
};
