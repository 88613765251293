import { IApplicationState } from '../../../../../../types/types';
import { EHintStatus, getHintsConfig, HINTS_INITIAL } from '../../../constants';
import { getHint } from './getHint';
import { IActiveHint } from '../types';
import { getAuthUser } from 'app/store/model/authUser/selectors/getAuthUser';

export const getActiveHint = (
    state: IApplicationState
): IActiveHint => {
    if (state.hints === HINTS_INITIAL) { // hints not loaded
        return null;
    }
    const user = getAuthUser(state);

    const hints = getHintsConfig();
    for (let i = 0; i < hints.length; i++) {
        const hintItem = hints[i];
        if (!user.firstVisit || hintItem.isAllowFirstVisit) {
            let hint = {...getHint(state, hintItem.key)};
            if (
                hint.status !== EHintStatus.NOT_SHOWN ||
                hintItem.condition && !hintItem.condition(state)
            ) {
                continue;
            }
            if (hintItem.tutorial) {
                if (!hint.step) hint.step = 0;
                const tutorialStep = hintItem.tutorial[hint.step];
                if (
                    tutorialStep &&
                    tutorialStep.condition &&
                    !tutorialStep.condition(state)
                ) {
                    continue;
                }
            }
            return {
                ...hint,
                key: hintItem.key
            };
        }
    }
    return null;
}
