import { IGetState, TCardId } from '../../../../../../../../types/types';
import pushToGoogleCalendarSelectCard
    from '../../../../TimingSection/pushToGoogleCalendar/store/actions/pushToGoogleCalendarSelectCard';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { getPushToGoogleCalendarCardId } from '../../../../TimingSection/pushToGoogleCalendar/selectors/getPushToGoogleCalendarCardId';

export const onDidMount = (
    cardId: TCardId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (getPushToGoogleCalendarCardId(getState()) !== cardId) { // dispatches optimization
            dispatch(pushToGoogleCalendarSelectCard(cardId));
        }
    };
    return action;
};
