import { refreshRecentOpenBoard } from '../authUser/recentBoards/refreshRecentOpenBoard';
import { IGetState, TBoardId } from '../../../types/types';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { boardSetName } from './boardSetName';
import { getBoard } from 'app/store/model/selectors/getBoardById';

export const updateBoardName = (
    boardId: TBoardId,
    name: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        const board = getBoard(getState(), boardId);
        if (board && board.name && board.name === name) return Promise.reject();

        return dispatch(boardSetName(boardId, name)).then(() => {
            dispatch(refreshRecentOpenBoard(boardId));
        })
    };
    return action;
};
