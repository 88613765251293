import { IApplicationState, TBoardId } from '../../../../../types/types';
import { TFilterMatchLists } from '../../../../../view/react_components/aside_panel/filterPanelBoard/store/filterMatch/types';
import { getTypeSwitcherActiveType } from '../../../../../view/react_components/typeSwitcher/store/selectors/getTypeSwitcherActiveType';
import { EViewTypes, getCardBackgroundColors } from '../../../../../const';
import { getBoardUsers } from '../../../selectors/getBoardUsers';
import { getBoardListsByStatus } from '../../../selectors/getBoardListsByStatus';
import { PRIORITY_ICONS } from '../../../../../view/react_components/helpers/constants';
import { getBoardCardColorTagIds } from '../../../../../view/react_components/teamWorkload/teamWorkloadView/hocs/ColorTagsViewHOC/selectors/getBoardCardColorTagIds';

export const getMatchListsDefault = (
    state: IApplicationState,
    boardId: TBoardId
): TFilterMatchLists => {
    const viewType = getTypeSwitcherActiveType(state, boardId);
    const result: TFilterMatchLists = {};
    switch (viewType) {
        case EViewTypes.KANBAN_VIEW_TYPE:
        case EViewTypes.SWIM_COLOR_TAGS_VIEW_TYPE:
        case EViewTypes.SWIM_TAGS_VIEW_TYPE: {
            const lists = getBoardListsByStatus(state, boardId, null);
            lists.forEach(list => {
                result[list.id] = [];
            })
            break;
        }
        case EViewTypes.USERS_VIEW_TYPE: {
            const users = getBoardUsers(state, boardId);
            users.forEach(user => {
                result[user.permissionId] = [];
            });
            break;
        }
        case EViewTypes.COLOR_TAGS_VIEW_TYPE: {
            const colorTagIds = getBoardCardColorTagIds(state, boardId);
            colorTagIds.forEach(id => {
                result[id] = [];
            });
        }
        case EViewTypes.CARD_COLORS_VIEW_TYPE: {
            const colors = getCardBackgroundColors();
            colors.forEach(color => {
                result[color.slice(1)] = [];
            });
        }
        case EViewTypes.PRIORITIES_VIEW_TYPE: {
            PRIORITY_ICONS.forEach(obj => {
                result[obj.priority] = [];
            })
        }
    }
    return result;
}
