export const historyPush = ({
    state,
    title,
    url,
    onpopstate
}: IHistoryProps) => {
    window.history.pushState(state, title, url);
    window.onpopstate = onpopstate;
};

export interface IHistoryProps{
    state: {
        page: string;
    };
    title?: string;
    url?: string;
    onpopstate: () => void;
}
