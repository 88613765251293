import { TABTestName } from '../types';
import { isABTestInit } from '../store/selectors/isABTestInit';
import { getABTest } from '../abTests/constants';
import { defineNewABTestsValue } from '../core/defineNewABTestsValue';
import { dispatchValues } from './dispatchValues';
import { saveNewTests } from './saveNewTests';
import { Dispatch, ThunkAction } from '../../types/actions';
import { IGetState } from '../../types/types';

export const executeAbTest = (
    name: TABTestName
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (!isABTestInit(getState(), name)) {
            const abTest = getABTest(name);
            const newABTestsValues = defineNewABTestsValue(getState(), [ abTest ]);
            dispatch(dispatchValues(newABTestsValues));
            return dispatch(saveNewTests(newABTestsValues));
        }
        return Promise.resolve();
    }
    return action;
};
