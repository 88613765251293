import React from 'react';
import store, { dispatch } from '../../store/configureStore';
import { CardTemplatesView } from './card_templates';
import { RecurringTasksView } from './recurring_tasks';
import { leftPanelOpenedSetAction } from '../react_components/aside_panel/asidePanel/store/actions/leftPanelOpenedSetAction';
import { CLASS_LEFT_ASIDE_PANEL, CLASS_PANELS_BLUR } from '../../const';
import * as _ from 'underscore';
import { PanelsBlur } from '../react_components/base/components/PanelsBlur/PanelsBlur';
import { EPanelsBlurType } from '../react_components/base/components/PanelsBlur/types';
import { createRoot } from 'react-dom/client';

var App = window.App;

App.Views.LeftAsidePanelRenderer = App.Views.BaseView.extend({
    initialize: function() {
        this.currentViewType = null;
        this.blurElement = document.createElement('div');
        this.blurElement.classList.add(`${CLASS_PANELS_BLUR}-left-aside`);
        this.el.parentNode.insertBefore(this.blurElement, this.el.nextSibling);
        this.blurRoot = createRoot(this.blurElement);
    },

    isOpened: function() {
        return Boolean(this.currentView);
    },

    render: function() {
        this.blurRoot.render(
            <PanelsBlur type={EPanelsBlurType.LEFT} />
        );
        return this;
    },

    show: function(view, viewType) {
        this.clear();
        this.currentView = view;
        this.currentViewType = viewType;
        this.testDataUpdate();
        this.showCurrentView();
    },

    showCurrentView: function() {
        dispatch(leftPanelOpenedSetAction(true));
        this.currentView.el = this.el;
        this.currentView.render();
        this.openedClassToggle();
    },

    showCardTemplates: function(boardId) {
        this.show(new CardTemplatesView({ store, boardId }), this.constructor.CARD_TEMPLATES);
    },

    showRecurringTasks: function(boardId) {
        this.show(new RecurringTasksView({ store, boardId }), this.constructor.RECURRING_TASKS);
    },

    isCardTemplatesOpened: function() {
        return this.currentViewType === this.constructor.CARD_TEMPLATES;
    },

    isRecurringTasksOpened: function() {
        return this.currentViewType === this.constructor.RECURRING_TASKS;
    },

    clear: function() {
        this.currentViewType = null;
        if (this.currentView) {
            this.currentView.remove();
            this.currentView = null;
        }
    },

    hide: function(navigate = true) {
        this.clear();
        dispatch(leftPanelOpenedSetAction(false));
        this.openedClassToggle();
        if (navigate) {
            App.router.navigate(App.router.getBaseUrl(), { trigger: true });
        }
    },

    openedClassToggle: function() {
        this.$el.toggleClass(CLASS_LEFT_ASIDE_PANEL + '--opened', this.isOpened());
        requestAnimationFrame(() => App.vent.trigger(App.vent['view:resized'])); // setPanelsWidth
    },

    remove: function() {
        this.blurRoot.unmount();
        this.blurElement.remove();
        App.vent.off(null, null, this);
        return Backbone.View.prototype.remove.call(this);
    },

    testAttributes: function() {
        return {}
    }

}, {
    CARD_TEMPLATES: 'CARD_TEMPLATES',
    RECURRING_TASKS: 'RECURRING_TASKS'
});
App.Views.LeftAsidePanelRenderer.mixin(App.Mixins.Test);
export const LeftAsidePanelRenderer = App.Views.LeftAsidePanelRenderer;
