import { IApplicationState, TCardId } from '../../../../../../types/types';
import { getCardPredecessorsDependencies } from '../../../../../../store/model/dependencies/dependencies/selectors/getCardPredecessorsDependencies';
import { IDependency } from '../../../../../../store/model/dependencies/dependency/types';
import { getCardDependencies } from '../../../../../../store/model/dependencies/dependencies/selectors/getCardDependencies';

export const isGivenCardIsPredecessorForMe = (
    state: IApplicationState,
    meCardId: TCardId,
    givenCardId: TCardId
): boolean => {
    const filter = (dependency: IDependency): boolean => {
        return dependency.successorId === meCardId && dependency.predecessorId === givenCardId;
    };
    return getCardDependencies(state, filter).length > 0;
}
