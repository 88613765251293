import * as React from 'react';
import './_MyWorkSettingsTab.scss';
import { IMyWorkSettingsTabProps } from './types';
import { MyWorkSettingsShowSectionHOC } from '../../hocs/MyWorkSettingsShowSectionHOC/MyWorkSettingsShowSectionHOC';
import {
    MyWorkSettingsFilterBarSectionHOC
} from '../../hocs/MyWorkSettingsFilterBarSectionHOC/MyWorkSettingsFilterBarSectionHOC';
import {
    MyWorkSettingsColumnsSectionHOC
} from '../../hocs/MyWorkSettingsColumnsSectionHOC/MyWorkSettingsColumnsSectionHOC';

export const MyWorkSettingsTab = ({}: IMyWorkSettingsTabProps) => {
    return (
        <>
            <MyWorkSettingsShowSectionHOC />
            <MyWorkSettingsFilterBarSectionHOC />
            <MyWorkSettingsColumnsSectionHOC />
        </>
    );
}
