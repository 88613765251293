import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../../../types/types';
import { root } from '../../../../../../../store/constants';
import { getCardAssignee } from '../../../../../../../store/model/cardAssignees/selectors/getCardAssignee';
import { cardsIsLoadingUpdate } from '../../../../../../../store/requestsState/effects/cardsIsLoadingUpdate';
import { cardAssigneeLoad } from '../../../../../../../rest/effects/cardAssignees/cardAssigneeLoad';
import { loadData } from '../../../../../aside_panel/assigneToMe/helpers/loadData';
import { IRestCardAssigneeInfo } from '../../../../../../../types/rest/IRestCardAssigneeInfo';
import { cardFullGetRest } from '../../../../../../../rest/effects/card/card/api/helper/cardFullGetRest';
import { getCard } from '../../../../../../../store/model/selectors/getCard';

export const onLoadCard = (
    cardId: TCardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(cardsIsLoadingUpdate({ [cardId]: {} }, true)); // флаг должен сняться в cardFullGetRest
        const cardAssignee = getCardAssignee(getState(), cardId);
        const promise = cardAssignee ? Promise.resolve(cardAssignee) : dispatch(cardAssigneeLoad(cardId));
        return promise
            .then((cardAssignee: IRestCardAssigneeInfo) => {
                return loadData(getState(), cardAssignee.dashboardId, cardId);
            })
            .then(() => {
                const card = getCard(getState(), cardId);
                if (!card) {
                    root.App.controller.showSystemLoader(true);
                    return dispatch(cardFullGetRest(cardId));
                }
            })
            .then(() => Promise.resolve(null))
            .catch((e: any) => {
                root.App.router.navigate(root.App.router.getAssignedToMeUrl(), { trigger: true });
            })
            .finally(() => root.App.controller.hideSystemLoader());
    };
    return action;
}
