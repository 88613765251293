import { IPinnedSections } from '../card/types/ICardMeta';
import { getCardMeta } from './getCardMeta';
import { ICard } from '../card/types';
import { createSelector } from 'reselect';
import { getCard } from './getCard';
import { root } from '../../constants';
import { IApplicationState, TCardId } from 'app/types/types';

export const getCardPinnedSections = (
    card: ICard,
): IPinnedSections => {
    if (root._debug.selector) {
        console.log('getCardPinnedSections');
    }
    const meta = getCardMeta(card);
    if (
        !meta ||
        !meta.pinnedSections
    ) return {};

    return meta.pinnedSections;
};

export const getCardPinnedSectionsCreateSelector = (
) => createSelector(
    getCard,
    getCardPinnedSections
);

// замеры CardProgressHOC показывают, что реселекты там не нужны
export const getCardPinnedSectionsNoreselect = (
    state: IApplicationState,
    cardId: TCardId
): IPinnedSections => {
    return getCardPinnedSections(getCard(state, cardId));
};
