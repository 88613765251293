import { IGetState, TBoardId } from '../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { INotificationSettings } from '../../../../../store/model/notificationSettings/types';
import { notificationSettingsActionSet } from '../../../../../store/model/notificationsSettings/actions/notificationSettingsActionSet';
import { updateAction } from '../../../../../store/model/notificationSettings/actions/updateAction';
import { patchRest } from '../patchRest';
import { notificationsSettingsActionSet } from '../../../../../store/model/actions/notificationsSettingsActionSet';
import { getNotificationSettingsForRollback } from '../../../../../store/model/selectors/getNotificationSettingsForRollback';
import { sendAuthUserRealTimeStoreAction } from '../../../../../view/react_components/base/helpers/realTimeHelperTS';

export const notificationSettingsPatchRest = (
    boardId: TBoardId,
    notification: INotificationSettings,
    isRealtime: boolean = true, // send event to socket
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const notificationForRollback = getNotificationSettingsForRollback(getState(), boardId, notification);
        const update = notificationsSettingsActionSet(notificationSettingsActionSet(boardId, updateAction(notification)));
        dispatch(update);
        return dispatch(patchRest(boardId, notification))
            .then(() => {
                if (isRealtime) {
                    dispatch(sendAuthUserRealTimeStoreAction(update))
                }
                return notification;
            })
            .catch((e: any) => {
                console.error(e);
                dispatch(notificationsSettingsActionSet(notificationSettingsActionSet(boardId, updateAction(notificationForRollback))));
            });
    };
    return action;
};
