import { getMessages } from '../../../../store/constants';
import { ICardPropertyElement } from './details/CardProperties/components/CardProperties/types';
import { EAsidePanelProperty } from '../asidePanel/components/AsidePanel/types';

export const BOARD_NAME_DEFAULT = getMessages().getText('model.default.dashboard.name');

export const CLASS_ASIDE_BOARD = 'aside-panel--board';
export const CLASS_BOARD_DETAILS = 'board-details';
export const CLASS_BOARD_DETAILS_READ_ONLY = CLASS_BOARD_DETAILS + '--readonly';
export const CLASS_BOARD_DETAILS_SECTION = 'board-details-section';
export const CLASS_BOARD_EXPORT = 'board-export';
export const CLASS_BOARD_BRANDING = 'board-branding';
export const CLASS_BOARD_BACKUPS = 'board-backups';

export const CARD_PROPERTIES: ReadonlyArray<Readonly<ICardPropertyElement>> = Object.freeze([
    Object.freeze({
        key: EAsidePanelProperty.CARD_DESCRIPTION,
        name: getMessages().getText('card_form_section_description'),
        isRequiredOnly: true
    }),
    Object.freeze({
        key: EAsidePanelProperty.CARD_ASSIGNEES,
        name: getMessages().getText('card_form_section_assignees'),
    }),
    Object.freeze({
        key: EAsidePanelProperty.CARD_TAGS,
        name: getMessages().getText('card_form_section_tags'),
    }),
    Object.freeze({
        key: EAsidePanelProperty.CARD_START_DATE,
        name: getMessages().getText('card_form_section_dates.start'),
    }),
    Object.freeze({
        key: EAsidePanelProperty.CARD_DUE_DATE,
        name: getMessages().getText('card_form_section_dates.due'),
    }),
    Object.freeze({
        key: EAsidePanelProperty.CARD_CHECKLIST,
        name: getMessages().getText('card_form_section_checklists'),
    }),
    Object.freeze({
        key: EAsidePanelProperty.CARD_ATTACHMENTS,
        name: getMessages().getText('card_form_section_attachments'),
    }),
    Object.freeze({
        key: EAsidePanelProperty.CARD_ESTIMATE,
        name: getMessages().getText('card_form_section_estimate'),
    }),
    Object.freeze({
        key: EAsidePanelProperty.CARD_SUBCARDS,
        name: getMessages().getText('card_form_section_subcards'),
        isDefaultOnly: true
    }),
    Object.freeze({
        key: EAsidePanelProperty.CARD_RELATED,
        name: getMessages().getText('card_form_section_related'),
        isDefaultOnly: true
    }),
    Object.freeze({
        key: EAsidePanelProperty.CARD_DEPENDENCIES,
        name: getMessages().getText('card_form_section_dependencies'),
        isDefaultOnly: true
    }),
    Object.freeze({
        key: EAsidePanelProperty.CARD_COMMENTS,
        name: getMessages().getText('card_form_section_comments'),
        isDefaultOnly: true
    }),
    Object.freeze({
        key: EAsidePanelProperty.CARD_CUSTOM,
        name: getMessages().getText('card_form_section_custom'),
        options: []
    }),
]);

export const CARD_TEMPLATE_PROPERTIES: ReadonlyArray<Readonly<ICardPropertyElement>> = Object.freeze([
    Object.freeze({
        key: EAsidePanelProperty.CARD_DESCRIPTION,
        name: getMessages().getText('card_form_section_description'),
        isRequiredOnly: true
    }),
    Object.freeze({
        key: EAsidePanelProperty.CARD_ASSIGNEES,
        name: getMessages().getText('card_form_section_assignees'),
    }),
    Object.freeze({
        key: EAsidePanelProperty.CARD_TAGS,
        name: getMessages().getText('card_form_section_tags'),
    }),
    Object.freeze({
        key: EAsidePanelProperty.CARD_START_DATE,
        name: getMessages().getText('card_form_section_dates.start'),
    }),
    Object.freeze({
        key: EAsidePanelProperty.CARD_DUE_DATE,
        name: getMessages().getText('card_form_section_dates.due'),
    }),
    Object.freeze({
        key: EAsidePanelProperty.CARD_CHECKLIST,
        name: getMessages().getText('card_form_section_checklists'),
    }),
    Object.freeze({
        key: EAsidePanelProperty.CARD_ATTACHMENTS,
        name: getMessages().getText('card_form_section_attachments'),
    }),
    Object.freeze({
        key: EAsidePanelProperty.CARD_ESTIMATE,
        name: getMessages().getText('card_form_section_estimate'),
    }),
    Object.freeze({
        key: EAsidePanelProperty.CARD_CUSTOM,
        name: getMessages().getText('card_form_section_custom'),
        options: []
    }),
]);

export const BOARD_DETAILS_ONLY_OWNER = getMessages().getText('board_settings.only_owner');
