const category = 'Notifications';

export const SegmentNotificationsEvent = {
    FORCE_CLICKED: { category, event: 'Force notify icon clicked' },
    FORCE_USER_CHOSEN: { category, event: 'User chosen in force notify dropdown' },
    FORCE_NOTIFY_CLICKED: { category, event: 'Notify users button clicked' },
    FORCE_WARNING: { category, event: 'Force notify warning shown' },
    FORCE_SUCCESS: { category, event: 'Force notify success shown' },
    FORCE_HOW_CLICKED: { category, event: 'Force notify how to clicked' },
    FORCE_GROUP_BOARD_CHOSEN: { category, event: 'Assigned users chosen in force notify dropdown' },
    FORCE_GROUP_OTHER_CHOSEN: { category, event: 'Other users chosen in force notify dropdown' },
    APP_NOTIFICATION_CHANGE_SETTINGS_CLICKED: { category, event: 'In App notifications: Cnahge notitifcations settings clicked' },
    APP_NOTIFICATION_VIEW_ACTIVITY_CLICKED: { category, event: 'In App notifications: View activity clicked' },
};

export enum SegmentNotificationsOptions {
    COUNT = 'count',
    SOURCE = 'source',
    TYPE = 'TYPE',
}

export enum SegmentNotificationsOptionSource {
    ACTIVITY = 'activity',
    CARD_DETAILS = 'card details',
    SELECT_GROUP_BOARD = 'Assigned on this board',
    SELECT_GROUP_OTHER = 'Other users',
}
