import * as React from 'react';
import { IMyWorkBoardProps } from './types';
import './_MyWorkBoard.scss';
import { Button, Icon, ISelectActiveInheritedProps, Select } from 'kui';
import { getMessages } from '../../../../../../../../store/constants';
import {
    MY_WORK_BOARD_FETCH_CARDS_OPTIONS,
    MY_WORK_BOARD_REMOVE_BUTTON_TOOLTIP, MY_WORK_BOARD_SELECT_ACTIVE_OPTION_TEXT,
    MY_WORK_BOARD_SELECT_OPTION_TEXT
} from './constants';
import { Util } from '../../../../../../../../util/util';
import { MESSAGE_LAST_EDIT_KEY } from '../../constants';
import { CLASS_MY_WORK } from '../../../../constants';
import { getAsidePanelTooltip } from '../../../../../../aside_panel/asidePanel/helpers/getAsidePanelTooltip';
import { v4 as uuidv4 } from 'uuid';
import {
    ID_ASIDE_PANEL_PORTAL
} from '../../../../../../aside_panel/asidePanel/components/AsidePanelActionsButton/constants';

export function MyWorkBoard ({
    board,
    onFetchChange,
    onRemove
}: IMyWorkBoardProps) {
    const { name, lastActivityTime, fetchCards } = board;

    const className = CLASS_MY_WORK + '-import-boards__board';
    const [classUnique] = React.useState(className + uuidv4());
    const classBody = className + '-body';
    const classBoardName = classBody + '-name';
    const classSelect = className + '-select';

    const onChange = (event: ISelectActiveInheritedProps) => {
        onFetchChange(Number(event.item.value));
    };

    React.useEffect(() => {
        const onInputReplace = () => {
            const input = document.querySelector('.' + classUnique + ' .' + classSelect + ' .kui-input__item') as HTMLInputElement;
            if (input && input.value) {
                input.value = input.value.replace(MY_WORK_BOARD_SELECT_OPTION_TEXT, MY_WORK_BOARD_SELECT_ACTIVE_OPTION_TEXT);
            } else {
                setTimeout(() => {
                    onInputReplace();
                }, 0);
            }
        };

        onInputReplace();
    }, [board]);

    const lastActivity = Util.formatDateMoment(new Date(lastActivityTime));

    return (
        <div className={`${className} ${classUnique}`}>
            <div className={classBody}>
                <div className={classBoardName}>
                    <Icon xlink={'board'} size={24} />
                    <span className={classBoardName + '-title'}>
                        {name}
                    </span>
                </div>
                <Button
                    className={className + '-close'}
                    variant={'icon'}
                    tooltip={getAsidePanelTooltip({
                        direction: 'up-left',
                        value: MY_WORK_BOARD_REMOVE_BUTTON_TOOLTIP,
                    })}
                    onClick={onRemove}
                >
                    <Icon size={24} xlink={'clear'} />
                </Button>
            </div>
            <div className={className + '-footer'}>
                <Select
                    className={classSelect}
                    active={MY_WORK_BOARD_FETCH_CARDS_OPTIONS.findIndex(item => item.value === fetchCards)}
                    options={MY_WORK_BOARD_FETCH_CARDS_OPTIONS}
                    onChange={onChange}
                    variant={'arrow'}
                    portal
                    portalId={ID_ASIDE_PANEL_PORTAL}
                />
                <span className={className + '-date'}>
                    {getMessages().getText(MESSAGE_LAST_EDIT_KEY, null, lastActivity)}
                </span>
            </div>
        </div>
    );
};
