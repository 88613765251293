import * as React from 'react';
import { ITopBarWarningProps } from './types';
import { Button, Icon, Tooltip } from 'kui';
import './_TopBarWarning.scss';

export function TopBarWarning (
    {
        hasWarning,
        warningTitle,
        warningCode,
        onClick
    }: ITopBarWarningProps
) {
    return (
        <div className={'topbar__dashboard-warning handle-click-ignore'}>
            {hasWarning && (
                <Tooltip value={warningTitle} direction={'down'}>
                    <Button variant={'icon'} onClick={() => {
                        onClick(warningCode)}}>
                        <Icon size={24} xlink={'error'}/>
                    </Button></Tooltip>
            )}
        </div>);
}
