import { IApplicationState, TCardId } from '../../../types/types';
import { ICard } from '../card/types';
import { getCardSubcards } from './getCardSubcards';
import { getCardIsEpic } from './getCardIsEpic';

export const getCardSubcardsTree = (
    state: IApplicationState,
    cardId: TCardId
): ICard[] => {
    const subcards = getCardSubcards(state, cardId);
    const result = [...subcards];
    subcards.forEach(subcard => {
        if (getCardIsEpic(state, subcard.id)) {
            result.push(...getCardSubcardsTree(state, subcard.id))
        }
    })
    return result;
}
