import { ECardNumberStatus, ICardNumberState } from './types';

export const getInitialState = (
): ICardNumberState => {
    return {
        enabled: false,
        prefix: '',
        nextValue: '1',
        errorMessage: null,
        status: ECardNumberStatus.INITIAL,
        edited: false
    }
}
