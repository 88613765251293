import { getActivities } from '../../../../../../../../../store/activities/selectors/getActivities';
import { IActivityGroup } from '../../../components/TabActivity/types';
import { IApplicationState } from '../../../../../../../../../types/types';
import { createDeepEqualSelector } from '../../../../../../../helpers/memoizeHelper';
import { TActivity } from '../../../../../../../../../store/activities/types';
import { Util } from '../../../../../../../../../util/util';
import { ONE_DAY_LENGTH } from '../../../../../../filterPanelBoard/constants';

export const getActivityGroupsByDay = (
    activities: TActivity[]
): IActivityGroup[] => {
    const activityGroups: IActivityGroup[] = [];
    activities.forEach(activity => {
        const date = new Date(activity.time * 1000);
        const now = new Date(Date.now());
        const yesterday = new Date(Date.now() - ONE_DAY_LENGTH);
        let title = Util.formatDateMoment(date, 'DD MMM YYYY');
        if (
            now.getFullYear() === date.getFullYear() &&
            now.getMonth() === date.getMonth() &&
            now.getDate() === date.getDate()
        ) {
            title = 'Today, ' + title;
        } else if (
            yesterday.getFullYear() === date.getFullYear() &&
            yesterday.getMonth() === date.getMonth() &&
            yesterday.getDate() === date.getDate()
        ) {
            title = 'Yesterday, ' + title;
        }
        const idx = activityGroups.findIndex(item => item.title === title);
        if (idx >= 0) {
            activityGroups[idx].activities.push(activity);
        } else {
            activityGroups.push({
                title,
                date: date.getTime(),
                activities: [activity]
            });
        }
    });
    return activityGroups;
}

type TgetActivityGroupsByDay = (
    state: IApplicationState,
) => IActivityGroup[];

export const getActivityGroupsByDayCreateSelector = (): TgetActivityGroupsByDay => createDeepEqualSelector(
    getActivities,
    getActivityGroupsByDay,
);
