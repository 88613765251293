import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { ICardChecklistPanelButtonHOCProps } from './types';
import {
    ICardChecklistPanelButtonEvents,
    ICardChecklistPanelButtonFields
} from '../../components/CardChecklistPanelButton/types';
import { CardChecklistPanelButton } from '../../components/CardChecklistPanelButton/CardChecklistPanelButton';
import { getPanelButtonShow } from '../../../selectors/getPanelButtonShow';
import { onClick } from './events/onClick';
import {
    getCardChecklistItemsNumber
} from '../../../../../../../store/model/checklists/checklists/selectors/getCardChecklistItemsNumber';
import { CARD_SECTION_CHECKLISTS } from '../../components/CardChecklists/constants';

const mapStateToProps = (
    state: IApplicationState,
    { boardId, cardId, isShowTooltip }: ICardChecklistPanelButtonHOCProps
): ICardChecklistPanelButtonFields => {
    const checklistCount = getCardChecklistItemsNumber(state, cardId);
    const isShow = getPanelButtonShow(state, boardId, cardId);
    const tooltip = isShowTooltip
        ? CARD_SECTION_CHECKLISTS
        : null;
    return {
        checklistCount,
        isShow,
        tooltip,
    }
};

const mapDispatchToProps = (
    dispatch: any,
    {boardId, cardId, onClick: ownOnClick}: ICardChecklistPanelButtonHOCProps
): ICardChecklistPanelButtonEvents => {
    return {
        onClick: (isOpened) => dispatch(onClick(boardId, cardId, isOpened, ownOnClick))
    }
};

export const CardChecklistPanelButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardChecklistPanelButton);

CardChecklistPanelButtonHOC.displayName = 'CardChecklistPanelButtonHOC';
