import { AT_IN_WORK_USERS_UPDATE } from './constants';
import { IInWorkUsersUpdateAction } from './types';
import { TPermissionId } from '../../../../types/types';

export const inWorkUsersUpdateAction = (
    permissionId: TPermissionId,
    cardInWork: boolean
): IInWorkUsersUpdateAction => {
    return {
        type: AT_IN_WORK_USERS_UPDATE,
        permissionId,
        cardInWork
    }
};
