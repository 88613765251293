import {IAuthUser} from 'app/store/model/authUser/types';
import {Dispatch, ThunkAction} from 'app/types/actions';
import {IGetState} from 'app/types/types';
import {authUserActionSet} from 'app/store/model/actions/authUserActionSet';
import {setAction} from 'app/store/model/authUser/actions/setAction';
import { validateLogoImage } from 'app/util/validateLogoImage';
import { updateAction } from '../actions/updateAction';
import { getAuthUser } from '../selectors/getAuthUser';

export const setUser = (
    authUser: IAuthUser
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        let logo = authUser.logo;
        if (logo) {
            authUser.logo = null; // 1. сначала спрятать лого, чтоб скорее сохранить юзера
        } else {
            logo = null;
        }
        // if (!authUser.firstVisit) {
        //     const currentAuthUser = getAuthUser(getState());
        //     if (authUser.id === currentAuthUser.id) {
        //         authUser.firstVisit = getAuthUser(getState()).firstVisit; // не обновлять firstVisit
        //     }
        // }

        dispatch(authUserActionSet(setAction(authUser)));

        if (logo) {
            validateLogoImage(logo) // 2. потом проверить лого
                .then((logoX: number) => {
                    dispatch(authUserActionSet(updateAction({
                        logo,
                        logoX,
                    })));
                });
        }
    }
    return action;
};
