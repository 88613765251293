import { TBoardId } from 'app/types/types';
import { Dispatch, ThunkAction } from 'app/types/actions';
import { toggleCardDefaultProperty } from '../effects/toggleCardDefaultProperty';
import { EAsidePanelProperty } from 'app/view/react_components/aside_panel/asidePanel/components/AsidePanel/types';

export const onDefaultToggle = (
    boardId: TBoardId,
    cardProperty: EAsidePanelProperty,
    option?: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        dispatch(toggleCardDefaultProperty(boardId, cardProperty, option));
    };
    return action;
};
