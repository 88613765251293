import { GOOGLE_SPACING } from './../../../const';
import * as React from 'react';
import { getMessages } from '../../../store/constants';
import { ECardField, ICardField, IGanttViewContext, IGanttViewPageContext, TGanttZoom } from './types';

export const CLASS_GANTT_VIEW = 'gantt-view';
export const CLASS_GANTT_DEPENDENCIES = CLASS_GANTT_VIEW + '__dependencies';
export const CLASS_GANTT_LIST = CLASS_GANTT_VIEW + '__list-cards'
export const CLASS_GANTT_VIEW_CHART_PORTAL = CLASS_GANTT_VIEW + '__chart-portal';
export const CLASS_GANTT_VIEW_DATES = CLASS_GANTT_VIEW + '__dates';
export const CLASS_GANTT_VIEW_DATES_TODAY = CLASS_GANTT_VIEW + '__dates-today';
export const CLASS_GANTT_VIEW_NOW_LINE = CLASS_GANTT_VIEW + '__now-line';
export const CLASS_GANTT_SCROLL_CHART_MAIN = CLASS_GANTT_VIEW + '__scroll-chart-main';
export const CLASS_GANTT_VIEW_SCROLL_CHART = CLASS_GANTT_VIEW + '__scroll-chart';
export const CLASS_GANTT_VIEW_SCROLL_INFO = CLASS_GANTT_VIEW + '__scroll-info';
export const GANTT_HINT_PORTAL = 'gantt-view-hint-portal';

export const GanttViewContext = React.createContext<IGanttViewContext>(null);
export const GanttViewPageContext = React.createContext<IGanttViewPageContext>(null);

export enum EGanttZoom {
    ZOOM_3 = 3,
    ZOOM_6 = 6,
    ZOOM_12 = 12,
    ZOOM_24 = 24,
    ZOOM_48 = 48,
    ZOOM_72 = 72,
    ZOOM_96 = 96,
}

const DELTA_DAYS = 7 * 5;
export const DAY_SECS = 60 * 60 * 24;
export const DAY_MS = 1000 * DAY_SECS;
export const GANTT_CARD_MIN_WIDTH = GOOGLE_SPACING * 1.5;
export const GANTT_CARD_HEIGHT = GOOGLE_SPACING * 5; // px
export const GANTT_CARD_WIDTH = GOOGLE_SPACING * 34; // px
export const GANTT_CARD_WIDTH_MIN = GOOGLE_SPACING * 19; // px
export const GANTT_CARD_PADDING_LEFT = GOOGLE_SPACING;
export const GANTT_LEVEL_OFFSET = GOOGLE_SPACING * 3; // px
export const GANTT_MOBILE_WIDTH = 1024; // px
export const GANTT_PRINT_MM = 96 / 25.4; // convert mm to px
export const GANTT_PRINT_WIDTH = 297 * GANTT_PRINT_MM; // mm -> px
export const GANTT_PRINT_HEIGHT = 200 * GANTT_PRINT_MM; // mm -> px
export const GANTT_PRINT_PADDING_RIGHT = GOOGLE_SPACING * 20; // отступ справа на печати для имени карты
export const GANTT_PADDINGS = GOOGLE_SPACING * 4;
export const GANTT_SCROLL_THRESHOLD = 200; // px
export const GANTT_INFO_WIDTH = GANTT_CARD_PADDING_LEFT + GANTT_CARD_WIDTH;
export const GANTT_CARD_HIGHLIGHT = 2000; // ms подсветка карты при создании сегмента

export const GANTT_ZOOMS: Readonly<Record<EGanttZoom, Readonly<TGanttZoom>>> = Object.freeze({
    [EGanttZoom.ZOOM_3]: Object.freeze({
        zoom: EGanttZoom.ZOOM_3,
        deltaDays: DELTA_DAYS * 12,
        groupByWeeks: true
    }),
    [EGanttZoom.ZOOM_6]: Object.freeze({
        zoom: EGanttZoom.ZOOM_6,
        deltaDays:  DELTA_DAYS * 6,
        groupByWeeks: true
    }),
    [EGanttZoom.ZOOM_12]: Object.freeze({
        zoom: EGanttZoom.ZOOM_12,
        deltaDays: DELTA_DAYS * 3,
        groupByWeeks: true
    }),
    [EGanttZoom.ZOOM_24]: Object.freeze({
        zoom: EGanttZoom.ZOOM_24,
        deltaDays: DELTA_DAYS
    }),
    [EGanttZoom.ZOOM_48]: Object.freeze({
        zoom: EGanttZoom.ZOOM_48,
        deltaDays: DELTA_DAYS
    }),
    [EGanttZoom.ZOOM_72]: Object.freeze({
        zoom: EGanttZoom.ZOOM_72,
        deltaDays: DELTA_DAYS
    }),
    [EGanttZoom.ZOOM_96]: Object.freeze({
        zoom: EGanttZoom.ZOOM_96,
        deltaDays: DELTA_DAYS
    })
})

export const COUNTRY_FIRST_WEEK_DAYS = {
    'uk': 1,
    'us': 0
}

export enum EGanttDraggingType {
    DRAGGING = 'DRAGGING',
    RESIZING = 'RESIZING'
}

export enum EGanttSortType {
    BY_DATE = 0,
    MANUAL = 1
}

export enum EDatesFilterType {
    ALL = 'ALL',
    CUSTOM = 'CUSTOM'
}

export const CARD_FIELDS: Readonly<Record<ECardField, Readonly<ICardField>>> = Object.freeze({
    [ECardField.DESCRIPTION]: Object.freeze({
        title: getMessages().getText('list_view.card_field.description'),
        width: 0,
    }),
    [ECardField.PRIORITY]: Object.freeze({
        title: getMessages().getText('list_view.card_field.priority'),
        width: GOOGLE_SPACING * 3.3,
    }),
    [ECardField.PROGRESS]: Object.freeze({
        title: getMessages().getText('list_view.card_field.progress'),
        width: GOOGLE_SPACING * 4,
    }),
    [ECardField.LIST]: Object.freeze({
        title: getMessages().getText('list_view.card_field.list'),
        width: GOOGLE_SPACING * 10,
    }),
    [ECardField.TAGS]: Object.freeze({
        title: getMessages().getText('list_view.card_field.tags'),
        width: GOOGLE_SPACING * 11,
    }),
    // [ECardField.START_DATE]: Object.freeze({
    //     title: getMessages().getText('list_view.card_field.start_date'),
    //     width: GOOGLE_SPACING * 10,
    // }),
    // [ECardField.DUE_DATE]: Object.freeze({
    //     title: getMessages().getText('list_view.card_field.due_date'),
    //     width: GOOGLE_SPACING * 10,
    // }),
    [ECardField.ESTIMATE]: Object.freeze({
        title: getMessages().getText('list_view.card_field.estimate'),
        width: GOOGLE_SPACING * 11,
    }),
    [ECardField.SPENT_TIME]: Object.freeze({
        title: getMessages().getText('list_view.card_field.spent_time'),
        width: GOOGLE_SPACING * 11,
    }),
    // [ECardField.RELATED_CARDS]: Object.freeze({
    //     title: getMessages().getText('list_view.card_field.related_cards'),
    //     width: GOOGLE_SPACING * 6,
    // }),
    // [ECardField.CHECKLIST]: Object.freeze({
    //     title: getMessages().getText('list_view.card_field.checklist'),
    //     width: GOOGLE_SPACING * 8,
    // }),
    // [ECardField.ATTACHMENTS]: Object.freeze({
    //     title: getMessages().getText('list_view.card_field.attachments'),
    //     width: GOOGLE_SPACING * 6,
    // }),
    // [ECardField.COMMENTS]: Object.freeze({
    //     title: getMessages().getText('list_view.card_field.comments'),
    //     width: GOOGLE_SPACING * 6,
    // }),
    [ECardField.ASSIGNEES]: Object.freeze({
        title: getMessages().getText('list_view.card_field.assignees'),
        width: GOOGLE_SPACING * 7.5,
    }),
});
