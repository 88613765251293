import { IApplicationState, TBoardId } from '../../../../../types/types';
import { ICard } from '../../../card/types';
import { getBoard } from '../../../selectors/getBoardById';
import { getList } from '../../../list/selectors/getList';
import { getCard } from '../../../selectors/getCard';
import { getViewTypeCardFilter } from './getViewTypeCardFilter';

export const getBoardCardsForFilter = (
    state: IApplicationState,
    boardId: TBoardId
): ICard[] => {
    const isCardMatch = getViewTypeCardFilter(state, boardId);
    const board = getBoard(state, boardId);
    if (
        !board ||
        !board.listIds ||
        !board.listIds.length
    ) return [];

    const cards = board.listIds.reduce((arr, listId) => {
        const list = getList(state, listId);
        if (
            !list ||
            !list.cardIds ||
            !list.cardIds.length
        ) return arr;

        list.cardIds.forEach((cardId) => {
            const card = getCard(state, cardId);
            if (
                card &&
                isCardMatch(card)
            ) {
                arr.push(card);
            }
        });
        return arr;
    }, []);
    return cards;
}
