import * as React from 'react';
import './_ColorPalette.scss';
import { IColorPaletteProps } from './types';
import { CLASS_COLOR_PALETTE } from '../constants';
import { ColorRow } from '../ColorRow/ColorRow';
import { TPaletteColor } from '../types';
import { Color } from '../Color/Color';

export const ColorPalette = ({
    colors,
    isAutoFocus,
    isColorPicker,
    isNoRaws,
    selectedColor,
    variant,
    onSelect
}: IColorPaletteProps) => {
    const className = CLASS_COLOR_PALETTE;

    const [rows, setRows] = React.useState([]);
    const [isColorPickerOpen, setColorPickerOpen] = React.useState(null);

    React.useEffect(() => {
        let rows: TPaletteColor[][] = [];
        if (isNoRaws) {
            rows.push([...colors]);
        } else {
            const length = colors.length + (isColorPicker ? 1 : 0);
            const rowLength = 10;
            for (let i = 0; i < length; i += rowLength) {
                rows.push(colors.slice(i, i + rowLength));
            }
        }
        setRows(rows);
    }, [colors]);

    return (
        <div
            className={`
                ${className}
                ${className + '--' + variant}
                ${isColorPickerOpen ? className + '--w-overlay' : ''}`
            }
        >
            {
                rows.map((row, index) => (
                    <ColorRow
                        key={index}
                        allColors={[...colors]}
                        colors={row}
                        variant={variant}
                        selectedColor={selectedColor}
                        isAutoFocus={isAutoFocus}
                        isColorPicker={isColorPicker && index === rows.length - 1}
                        onSelect={onSelect}
                        setColorPickerOpen={setColorPickerOpen}
                    />
                ))
            }
        </div>
    );
}
