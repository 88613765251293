import { ISnackbarRefresh } from './types';
import { Dispatch } from '../../../../../types/actions';
import { SnackbarVariant } from '../../store/types';
import { SNACKBAR_ID_REFRESH } from '../constants';
import { getDefaultActionEvent } from '../helpers/getDefaultActionEvent';
import { addUpdateSnackbarWithBubble } from '../addUpdateSnackbarWithBubble/addUpdateSnackbarWithBubble';
import { getMessages } from 'app/store/constants';

export const snackbarRefreshDefault = (
    options: ISnackbarRefresh
) => {
    return (dispatch: Dispatch) => {
        const controls = [
            {
                action: () => {
                    dispatch(getDefaultActionEvent(SNACKBAR_ID_REFRESH, options.actionApply));
                },
                isPrimary: true,
                title: getMessages().getText('snackbar.refresh.button'),
            },
        ];
        dispatch(addUpdateSnackbarWithBubble({
            id: SNACKBAR_ID_REFRESH,
            controls,
            title: getMessages().getText('snackbar.refresh.title'),
            text: getMessages().getText('snackbar.refresh.text'),
            variant: SnackbarVariant.REFRESH,
            onTimer: () => {
                dispatch(getDefaultActionEvent(SNACKBAR_ID_REFRESH, options.actionApply));
            }
        }));
    }
};
