import { IApplicationState } from '../../../../../../../../types/types';
import { onClick } from '../../../Tags/hocs/TagsPanelButtonHOC/events/onClick';
import { connect } from 'react-redux';
import { IPwcImportPanelButtonHOCProps } from './types';
import { IPwcPanelButtonEvents, IPwcPanelButtonFields } from '../../components/BoardPwcPanelButton/types';
import { PwcPanelButton } from '../../components/BoardPwcPanelButton/PwcPanelButton';
import { isPwcImportEnabled } from '../../helpers/isPwcImportEnabled';

const mapStateToProps = (
    state: IApplicationState
): IPwcPanelButtonFields => {
    return {
        tooltip: null,
        show: isPwcImportEnabled(state)
    }
};

const mapDispatchToProps = (
    dispatch: any,
    {onClick: ownOnClick}: IPwcImportPanelButtonHOCProps
): IPwcPanelButtonEvents => {
    return {
        onClick: () => dispatch(onClick(ownOnClick))
    }
};

export const PwcImportPanelButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(PwcPanelButton);
