import * as React from 'react';
import './_MyWorkSettingsFilterBarSection.scss';
import { IMyWorkSettingsFilterBarSectionProps } from './types';
import { CLASS_MY_WORK } from '../../../../constants';
import {
    MY_WORK_SETTINGS_FILTER_BAR_COLLAPSED_LABEL,
    MY_WORK_SETTINGS_FILTER_BAR_EXPANDED_LABEL,
    MY_WORK_SETTINGS_FILTER_BAR_TITLE
} from './constants';
import { SettingsSection } from '../../../../../../base/components/SettingsSection/SettingsSection';
import { Radio } from 'kui';

export const MyWorkSettingsFilterBarSection = ({
    isCollapsed,
    onToggle
}: IMyWorkSettingsFilterBarSectionProps) => {
    const className = CLASS_MY_WORK + '-settings-filter-bar-section';
    return (
        <SettingsSection
            className={className}
            title={MY_WORK_SETTINGS_FILTER_BAR_TITLE}
        >
            <Radio
                active={isCollapsed ? 1 : 0}
                onChange={onToggle}
                direction={'left'}
            >
                <span>{MY_WORK_SETTINGS_FILTER_BAR_COLLAPSED_LABEL}</span>
                <span>{MY_WORK_SETTINGS_FILTER_BAR_EXPANDED_LABEL}</span>
            </Radio>
        </SettingsSection>
    );
}
