import {IGetState, TCardId, TChecklistId, TPermissionId,} from '../../../../../../../../types/types';
import {Dispatch, ThunkAction} from '../../../../../../../../types/actions';
import {isCardChecklistUserExist} from '../../../../../../../../store/model/selectors/isCardChecklistUserExist';
import {checklistItemDeleteUser} from '../../../../../../../../rest/effects/card/checklist/checklistItemDeleteUser';
import {checklistItemAddUser} from '../../../../../../../../rest/effects/card/checklist/checklistItemAddUser';
import {SegmentCardEvent, segmentTrackAction} from '../../../../../../../../middlewares/segment';
import {
    cardToggleAssignee,
    EToggleAssigneeType
} from '../../../../AssigneesSection/hocs/AssigneesSectionHOC/effects/cardToggleAssignee';
import {getChecklistItems} from '../../../../../../../../store/model/checklists/checklist/selectors/getChecklistItems';

export const toggleChecklistItemsUser = (
    boardId: number,
    cardId: TCardId,
    checklistId: TChecklistId,
    permissionId: TPermissionId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        let promiseItems: Promise<any>[] = [];
        const checklistItems = getChecklistItems(state, cardId, checklistId);
        const checklistItemIds = [];
        let isChecked = true;
        for (let itemId in checklistItems) { // isChecked останется true, только если юзер есть во всех айтемах
            if (!checklistItems[itemId] ||
                !checklistItems[itemId].assignees ||
                !checklistItems[itemId].assignees.length ||
                !checklistItems[itemId].assignees.find(assignee => assignee.sharedUser.permissionId === permissionId)
            ) {
                isChecked = false;
                break;
            }
            checklistItemIds.push(Number(itemId));
        }

        if (isChecked) {
            for (let itemId in checklistItems) {
                promiseItems.push(dispatch(checklistItemDeleteUser(cardId, checklistId, Number(itemId), permissionId)));
            }
            if (!isCardChecklistUserExist(state, cardId, permissionId, checklistItemIds)) {
                dispatch(segmentTrackAction(SegmentCardEvent.UNASSIGNED_USER));
                promiseItems.push(dispatch(cardToggleAssignee(boardId, cardId, permissionId, EToggleAssigneeType.DELETE, false)));
            }
            dispatch(segmentTrackAction(SegmentCardEvent.CHECKLIST_USER_UNASSIGNED));
        } else {
            for (let itemId in checklistItems) {
                promiseItems.push(dispatch(checklistItemAddUser(boardId, cardId, checklistId, Number(itemId), permissionId)));
            }
            promiseItems.push(dispatch(cardToggleAssignee(boardId, cardId, permissionId, EToggleAssigneeType.ADD, false)));
            dispatch(segmentTrackAction(SegmentCardEvent.ASSIGNED_USER));
            dispatch(segmentTrackAction(SegmentCardEvent.CHECKLIST_USER_ASSIGNED));
        }

        return Promise.all(promiseItems);
    };
    return action;
};
