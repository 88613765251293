import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../types/types';
import { fetchHandler } from '../../../../../util/fetchHandler';
import { REST_CARD } from '../../../../constants';
import { getRestHeadersGet } from '../../../../helpers/getRestHeadersHelper';
import { Util } from '../../../../../util/util';
import { IRestCardWithBoard } from '../../../../../types/rest/IRestCardWithBoard';

export const getWithBoardRest = (
    cardId: TCardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return fetchHandler<IRestCardWithBoard>(
            Util.getApiUrl(
                REST_CARD + cardId + '/withBoard'),
            getRestHeadersGet(),
            undefined,
            true
        )
    };
    return action;
};
