import { IGetState, TBoardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { root } from '../../../../../../../../store/constants';
import { SegmentBoardEvent, segmentTrackAction, SegmentUserEvent } from '../../../../../../../../middlewares/segment';
import { exportToSpreadsheet } from '../../../../../../../../helper/export/exportToSpreadsheet';
import { IExportListener } from '../../../../../../../../helper/export/types';
import { authManagerInstance } from 'app/helper/authorisation/google/AuthManager';

export const exportSpreadsheetWithCheckPerm = (
    boardId: TBoardId,
    withArchive: boolean,
    listener: IExportListener
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const App = root.App;
        const onShow = () => {
            dispatch(segmentTrackAction(SegmentUserEvent.PERMISSION_SHEETS_SCOPE_SHOWN));
        }
        const onRequest = () => {
            dispatch(segmentTrackAction(SegmentUserEvent.PERMISSION_SHEETS_SCOPE_REQUESTED));
        }
        const onCancel = () => {
            dispatch(segmentTrackAction(SegmentUserEvent.PERMISSION_SHEETS_SCOPE_CANCELED));
        }
        const onApprove = () => {
            dispatch(segmentTrackAction(SegmentUserEvent.PERMISSION_SHEETS_SCOPE_GRANTED));
            dispatch(exportToSpreadsheet(boardId, withArchive, listener))
        }
        authManagerInstance.checkAndRequestGoogleSheet(onApprove, onShow, onCancel, onRequest);
        dispatch(segmentTrackAction(withArchive ? SegmentBoardEvent.EXPORTED_ACTIVE_ARCHIVE_BOARD_DATA : SegmentBoardEvent.EXPORTED_ACTIVE_BOARD_DATA));
    };
    return action;
};
