import { IApplicationState } from 'app/types/types';
import { THintKey } from '../../../types';
import { getHint } from '../../hints/selectors/getHint';
import { THintCanShow } from '../types';

export const getHintCanShow = (
    state: IApplicationState,
    key: THintKey,
): THintCanShow => {
    const hint = getHint(state, key);
    return hint && hint.canShow;
}
