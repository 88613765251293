import {IGetState, TBoardId} from '../../../types/types';
import {IBackupSchedule} from '../../../store/model/boardBackups/types';
import {Dispatch, ThunkAction} from '../../../types/actions';
import {patchRest} from './api/patchRest';
import {autoBackupBackupSchedule} from '../../../view/react_components/aside_panel/boardDetails/backups/AutoBackups/store/autoBackup/actions/autoBackupBackupSchedule';
import {autoBackupsBoardActionSet} from '../../../view/react_components/aside_panel/boardDetails/backups/AutoBackups/store/autoBackupsBoard/actions/autoBackupsBoardActionSet';
import {autoBackupsUpdate} from '../../../view/react_components/aside_panel/boardDetails/backups/AutoBackups/store/autoBackups/actions/autoBackupsUpdate';
import {autoBackupIsLoadingSet} from '../../../view/react_components/aside_panel/boardDetails/backups/AutoBackups/store/autoBackups/actions/autoBackupIsLoadingSet';

export const backupScheduleUpdate = (
    boardId: TBoardId,
    autoBackupId: number,
    update: IBackupSchedule
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const backupSchedule: IBackupSchedule = {
            ...update,
        }
        dispatch(autoBackupsBoardActionSet(boardId, autoBackupIsLoadingSet(true)));
        return dispatch(patchRest(autoBackupId, backupSchedule)).then((backupSchedule: IBackupSchedule) => {
            dispatch(autoBackupsBoardActionSet(boardId,autoBackupsUpdate(autoBackupId, autoBackupBackupSchedule(backupSchedule))));
            dispatch(autoBackupsBoardActionSet(boardId,autoBackupIsLoadingSet(false)));
        })
    };
    return action;
};
