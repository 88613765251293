import { TBoardId } from '../../../../../../../../types/types';

export interface IStatusListsTogglerHOCProps {
    boardId: TBoardId,
    type: StatusListsTogglerType,
    checked: boolean,
    onChange: () => void;
}

export enum StatusListsTogglerType {
    ON_HOLD_IN_WORK = 'OnHldInWork',
    DONE = 'Done'
}
