import {IApplicationState} from '../../types/types';
import {Store} from 'redux';
import * as _ from 'underscore';

/** this function use to subscribe on specific part of store, determined be selector
 * usage example:
 * //create selector
    var filterPanelBoardSelector = (state) => {
            return filterPanelBoardsSelector(state, boardId);
        };
    //create listener
    var filterPanelBoardListener = () => {
            this.doSort();
        };

    //create storeListener and pass to it store, selector and listener, comparator is optional, it is being used for deep compare objects
    this.unsubscribeFilterPanelBoard = storeListener(
        store,
        filterPanelBoardSelector,
        filterPanelBoardListener,
        (a, b) => {return  _.isEqual(a,b)});

    optional parameter is comparator, it's using for compare objects

    don't forget about unsubscribe in remove method!
 **/

export const storeListener = (
    store: Store<IApplicationState>,
    selector: (state: IApplicationState) => Object,
    listener: (value: any) => void,
    equalityCheck: (a: any, b: any) => boolean = defaultEqualityCheck
) => {
    let initValue = selector(store.getState());
    const unsubscribe = store.subscribe(() => {
        const newValue = selector(store.getState());
        if (!equalityCheck(newValue, initValue)) {
            initValue = newValue;
            listener(newValue);
        }
    });
    return unsubscribe;
};

function defaultEqualityCheck(a: any, b: any) {
    return a === b
}

export const deepEqualityCheck = (a: any, b: any) => _.isEqual(a, b);
