import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TBoardId } from '../../../../../../../../types/types';
import { TColorTagColor } from '../../../../../../../../types/model';
import { getBoardCustomColors } from '../../../../../../../../store/model/board/selectors/getBoardCustomColors';
import { boardUpdateMeta } from '../../../../../../../../rest/effects/board/boardUpdateMeta';
import { ISegmentEventOption } from '../../../../../../../../middlewares/segment/trackEntities/types';
import { SegmentCardEvent, SegmentCardOptions, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { SegmentCardType } from '../../../../../../../../middlewares/segment/trackEntities/cardEvents';

export const onAddCustomColor = (
    boardId: TBoardId,
    color: TColorTagColor
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (!color) return;
        const customColors = [...getBoardCustomColors(getState(), boardId), color];
        dispatch(segmentTrackAction(SegmentCardEvent.COLOUR_TAGS_CUSTOM_COLOR_SELECTED, {
            name: SegmentCardOptions.COLOR,
            value: color
        }));
        return dispatch(boardUpdateMeta(boardId, { customColors }));
    };
    return action;
};
