import { IApplicationState, TCardId, TChecklistId, TChecklistItemId } from '../../../../../types/types'
import { IChecklistItem } from '../types';
import { getChecklistItems } from './getChecklistItems';

export const getChecklistItem = (
    state: IApplicationState,
    cardId: TCardId,
    checklistId: TChecklistId,
    itemId: TChecklistItemId,
): IChecklistItem => {
    const items = getChecklistItems(state, cardId, checklistId) || {};
    return items[itemId];
};
