import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { snackbarSuccessDefault } from '../../../../../snackbarsStack';
import { COPIED_SNACKBAR_TEXT, SNACKBAR_ID_AFFILIATE_LINK_COPIED } from '../constants';
import { segmentTrackAction, SegmentUserEvent } from '../../../../../../../middlewares/segment';

export const onCopy = (
    link: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(link).then(() => {
                dispatch(snackbarSuccessDefault({
                    id: SNACKBAR_ID_AFFILIATE_LINK_COPIED,
                    text: COPIED_SNACKBAR_TEXT
                }));
                dispatch(segmentTrackAction(SegmentUserEvent.AFFILIATE_LINK_COPIED));
            });
        }
    };
    return action;
}
