import { IGetState } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { EDateType } from '../../../../../../../../const';

export const onDateOpened = (
    dateType: EDateType
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (dateType === EDateType.DUE_DATE) {
            dispatch(segmentTrackAction(SegmentCardEvent.DUE_DATE_OPENED));
        } else {
            dispatch(segmentTrackAction(SegmentCardEvent.START_DATE_OPENED));
        }
    };
    return action;
};
