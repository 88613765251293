import { getMessages } from '../../../../../../store/constants';
import { TSnackbarWordings } from './types';
import { TBoardDeleteType } from '../../store/boards/types';

export const SNACKBAR_BOARD_DELETE_ID = 'SNACKBAR_BOARD_DELETE_ID';
export const SNACKBAR_DELETE: TSnackbarWordings = {
    [TBoardDeleteType.own]: {
        title: getMessages().getText('open_boards.snackbar.delete.title'),
        text: getMessages().getText('open_boards.snackbar.delete.text')
    },
    [TBoardDeleteType.shared]: {
        title: getMessages().getText('open_boards.snackbar.delete_shared.title'),
        text: getMessages().getText('open_boards.snackbar.delete_shared.text')
    },
    [TBoardDeleteType.disallow]: {
        title: getMessages().getText('open_boards.snackbar.delete_disallow.title'),
        text: getMessages().getText('open_boards.snackbar.delete_disallow.text')
    },
};
