import { IApplicationState } from '../../../../types/types';
import { getAuthUser } from './getAuthUser';

export const isMwsUser = (
    state: IApplicationState
): boolean => {
    const mwsReg = /magicwebsolutions.co.uk$/;
    // todo achtung: пока возьмём юзера из бэкбона
    const email = getAuthUser(state).email;
    return mwsReg.test(email);
};
