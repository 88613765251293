import { Dispatch, ThunkAction } from '../../../../../../types/actions';
import { IGetState } from '../../../../../../types/types';
import { ICardChecklists } from '../../../../../../store/model/checklists/checklists/types';
import { CHECKLIST_DEFAULT, CHECKLIST_DEFAULT_ID } from '../../../../../../store/model/checklists/checklists/constants';
import { IChecklistItems } from '../../../../../../store/model/checklists/checklist/types';
import { checklistsSetStore } from '../../../../../../store/model/effects/checklistsSet';
import { IRestCard } from '../../../../../../types/rest/IRestCard';
import { IDriveDocs } from '../../../../../../store/model/driveDocs/types';
import { driveDocsActionSetAction } from '../../../../../../store/model/actions/driveDocsActionSetAction';
import { driveDocsSet } from '../../../../../../store/model/driveDocs/actions/driveDocsSet';
import { isObjectEmpty } from '../../../../../../view/react_components/helpers/isObjectEmpty';

export const processRestCardData = (
    card: IRestCard
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (card.checklists) {
            const checklists: ICardChecklists = {};
            if (!card.checklists || !card.checklists.length) {
                checklists[CHECKLIST_DEFAULT_ID] = {
                    ...CHECKLIST_DEFAULT,
                    cardId: card.id,
                };
            } else {
                card.checklists.forEach(checklist => {
                    checklists[checklist.id] = checklist;
                    checklists[checklist.id].cardId = card.id; // т.к. в default нет cardId
                    if (checklist.checkItems) {
                        const items: IChecklistItems = {};
                        checklist.checkItems.forEach(item => {
                            items[item.id] = item;
                        });
                        checklists[checklist.id].checkItems = items;
                    }
                });
            }
            dispatch(checklistsSetStore(card.id, checklists));
        }
        const driveDocs: IDriveDocs = {};
        if (card.driveDocs && card.driveDocs.length) {
            card.driveDocs.forEach(driveDoc => {
                driveDocs[driveDoc.id] = driveDoc;
            })
        } else if (card.mainDriveDocSet && card.mainDriveDocSet.length) {
            card.mainDriveDocSet.forEach(driveDoc => {
                driveDocs[driveDoc.id] = {
                    ...driveDoc,
                    main: true
                };
            })
        }
        if (!isObjectEmpty(driveDocs)) {
            dispatch(driveDocsActionSetAction(driveDocsSet(driveDocs)));
        }
    }
    return action;
}
