import { TCardId, TChecklistId, TChecklistItemId } from '../../../../types/types';
import { checklistsActionSetAction } from '../../actions/checklistsActionSetAction';
import { checklistItemDeleteAction } from '../checklist/actions/checklistItemDeleteAction';
import { checklistActionSetAction } from '../checklists/actions/checklistActionSetAction';

export const checklistItemDeleteStore = (
    cardId: TCardId,
    checklistId: TChecklistId,
    itemId: TChecklistItemId
) => {
    return checklistsActionSetAction(checklistActionSetAction(cardId, checklistId, checklistItemDeleteAction(itemId)));
};
