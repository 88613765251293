import { AT_UPDATE, IUpdateAction } from './types';
import { IDependency } from '../types';

export const updateAction = (
    dependency: IDependency
): IUpdateAction => {
    return {
        type: AT_UPDATE,
        dependency
    }
};
