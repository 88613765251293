import { TBoardId } from '../../../../types/types';
import { IList } from '../../../../store/model/list/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { fetchHandler } from '../../../../util/fetchHandler';
import { Util } from '../../../../util/util';
import { REST_BOARD } from '../../../constants';
import { getRestHeadersPost } from '../../../helpers/getRestHeadersHelper';
import { IRestList } from '../../../../types/rest/IRestList';

export const postRest = (
    boardId: TBoardId,
    list: IList,
    isServiceTemplate: boolean = false
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        const serviceTemplate = isServiceTemplate ? '/serviceTemplate' : '';
        return fetchHandler<IRestList>(
            Util.getApiUrl(REST_BOARD + boardId + '/lists' + serviceTemplate), {
                ...getRestHeadersPost(),
                body: JSON.stringify(list)
            }
        );
    };
    return action;
};
