import { IApplicationState } from '../../../../types/types';
import { IListViewBoard } from '../store/listViewBoard/types';
import { TBoardId } from '../../../../types/types';
import { getListViewBoardInitialState } from '../store/constants';
import { getLocalStorageItem } from '../helpers/getLocalStorageItem';

export const getListViewBoard = (
    state: IApplicationState,
    boardId: TBoardId
): IListViewBoard => {
    const board =
        state.listView[boardId] ||
        getLocalStorageItem()[boardId] ||
        getListViewBoardInitialState();
    return {...board};
};
