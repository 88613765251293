//todo check copy as is from live kanban
export const convertStringToSeconds = (
    timeTrackerString: string,
    hoursInDay: number
): number => {
    timeTrackerString = timeTrackerString.trim().toLowerCase();
    timeTrackerString = timeTrackerString.replace(/( seconds|seconds| second|second| sec|sec| s)/g, 's');
    timeTrackerString = timeTrackerString.replace(/( minutes|minutes| minute|minute| mins|mins| min|min| m)/g, 'm');
    timeTrackerString = timeTrackerString.replace(/( hours|hours| hour|hour| h)/g, 'h');
    timeTrackerString = timeTrackerString.replace(/( days|days| day|day| d)/g, 'd');
    // if (
    //     timeTrackerString.indexOf('d') === -1 &&
    //     timeTrackerString.indexOf('h') === -1 &&
    //     timeTrackerString.indexOf('m') === -1 &&
    //     timeTrackerString.indexOf('s') === -1
    // ) {
    //     timeTrackerString += 'm';
    // }

    let totalSeconds = 0;
    hoursInDay = hoursInDay ? hoursInDay : 24;
    const durationInSeconds: IParts = {
        [ETimePart.D]: (hoursInDay * 60 * 60),
        [ETimePart.H]: 3600,
        [ETimePart.M]: 60,
        [ETimePart.S]: 1
    };
    const raw = timeTrackerString.split(' ');
    const patt = /(\d{1,5})(.*)/i;
    let data;
    for (let i = 0; i < raw.length; i++) {
        data = raw[i].match(patt);
        if (!data) continue;
        if (!data || data.length < 2) continue;
        const timePart = data[2] as ETimePart;
        if (Object.values(ETimePart).indexOf(timePart) !== -1) {
            totalSeconds += durationInSeconds[timePart] * Number(data[1]);
        }
    }

    return totalSeconds;
};

enum ETimePart {
    D = 'd',
    H = 'h',
    M = 'm',
    S = 's',
}

interface IParts {
    [ETimePart.D]?: number;
    [ETimePart.H]?: number;
    [ETimePart.M]?: number;
    [ETimePart.S]?: number;
}
