import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { rollback } from '../../effects/rollback';
import { snackbarUndoDefault } from '../../../../../../../snackbarsStack/effects/variantUndo/snackbarUndoDefault';
import { CLASS_ACTION_MODAL_SNACKBAR } from '../../../../../../../base/components/ActionModal/constants';
import { SNACKBAR_BOARD_CARD_PREFIXES_ID, SNACKBAR_BOARD_CARD_PREFIXES_TIMER } from '../../../constants';
import { getMessages } from '../../../../../../../../../store/constants';
import { getDefaultActionEvent } from '../../../../../../../snackbarsStack/effects/helpers/getDefaultActionEvent';
import { SNACKBAR_BUTTON_OK, SNACKBAR_BUTTON_UNDO } from '../../../../../../../snackbarsStack/effects/constants';
import { getBoard } from '../../../../../../../../../store/model/selectors/getBoardById';
import { IGetState, TBoardId } from '../../../../../../../../../types/types';

export const onShowSnackbar = (
    boardId: TBoardId,
    isAll: boolean
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return new Promise<void>(resolve => {
            const actionApply = () => {
                return resolve();
            };

            const actionUndo = () => {
                return dispatch(rollback()).then(() => {
                    return resolve();
                });
            };

            const board = getBoard(getState(), boardId);

            dispatch(snackbarUndoDefault({
                className: CLASS_ACTION_MODAL_SNACKBAR,
                id: SNACKBAR_BOARD_CARD_PREFIXES_ID,
                text: isAll ? getMessages().getText('cardNumber.modal.snackbar')
                    : getMessages().getText('cardNumber.modal.edited.snackbar', null, board.cardNumberPrefix),
                timer: SNACKBAR_BOARD_CARD_PREFIXES_TIMER,
                controls: [
                    {
                        action: () => {
                            dispatch(getDefaultActionEvent(SNACKBAR_BOARD_CARD_PREFIXES_ID, actionUndo));
                        },
                        title: SNACKBAR_BUTTON_UNDO
                    },
                    {
                        action: () => {
                            dispatch(getDefaultActionEvent(SNACKBAR_BOARD_CARD_PREFIXES_ID, actionApply));
                        },
                        title: SNACKBAR_BUTTON_OK,
                        isPrimary: true
                    },
                ]
            }, true));
        });
    };
    return action;
};
