import { ICardsColumn } from '../../../../../cards/types';
import { IApplicationState } from '../../../../../../../../../types/types';
import { getMessages } from '../../../../../../../../../store/constants';
import { getMyWorkGroupBy } from '../../../../../selectors/getMyWorkGroupBy';
import { EMyWorkGroupType } from '../../../../../../../aside_panel/filterPanelBoard/store/filterPanel/types';
import { CARDS_COLUMNS } from '../../../../../cards/constants';
import { getUserMetaMyWorkColumns } from '../../../../../selectors/getUserMetaMyWorkColumns';

export const getCardsColumns = (
    state: IApplicationState
): ICardsColumn[] => {
    const savedColumns = getUserMetaMyWorkColumns(state);
    const myWorkGroupBy = getMyWorkGroupBy(state);
    const groupByBoard = myWorkGroupBy === EMyWorkGroupType.BOARD;
    const columns = savedColumns ? savedColumns.map(column => {
        const item = CARDS_COLUMNS.find(item => item.title === column.title);
        return {
            ...item,
            ...column
        }
    }) : CARDS_COLUMNS;
    CARDS_COLUMNS.forEach((column, idx) => {
        if (column.isNotDraggable) {
            const el = columns.find(item => column.title === item.title);
            if (!el) return;
            const currentEl = columns[idx];
            if (currentEl.title !== column.title) {
                columns[idx] = el;
            }
        }
    });
    return groupByBoard
        ? columns.filter(({ title }) => title !== getMessages().getText('assigned_to_me.cards.board'))
        : columns;
}
