import { cardDescriptionUnsavedSetAction } from '../../../store/cardDescriptionUnsaved/actions/cardDescriptionUnsavedSetAction';
import { Dispatch, ThunkAction } from 'app/types/actions';
import { IGetState, TCardId } from 'app/types/types';
import { IDescriptionUnsavedState } from '../../../store/cardDescriptionUnsaved/types';

export const saveDescriptionLocal = (
    cardId: TCardId,
    description: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const date = new Date().getTime() / 1000;
        const unsavedDescription: IDescriptionUnsavedState = {
            descriptionUnsaved: description,
            datetime: date
        };
        dispatch(cardDescriptionUnsavedSetAction(cardId, unsavedDescription));
    };
    return action;
};
