import { root } from 'app/store/constants';

export function getCaretPosition() {
    let position: number = 0;
    const selection = root.getSelection();
    if (!selection.rangeCount) return null;
    const range = selection.getRangeAt(0);
    const container: Node = range.commonAncestorContainer;
    const startOffset = range.startOffset;
    const childNodes = range.commonAncestorContainer.parentNode.childNodes;
    const paragraphs = range.commonAncestorContainer.parentNode.parentNode.childNodes;
    if (!childNodes || !paragraphs) return null;
    let selectedParagraphPosition = 0;

    for (let i = 0; i < paragraphs.length; i++) {
        if (paragraphs[i].childNodes !== childNodes) {
            paragraphs[i].childNodes.forEach((item) => {
                if (item.nodeValue) {
                    position += item.nodeValue.length + 1
                }
            })
        } else {
            selectedParagraphPosition = i
            for (let j = 0; j < childNodes.length; j++) {
                if (childNodes[j] !== container) {
                    if (childNodes[j].nodeValue) {
                        position += childNodes[j].nodeValue.length + 1;
                    }
                } else {
                    break
                }
            }
            break
        }
    }

    position += startOffset;

    return {
        position: position,
        container: container,
        selectedParagraphPosition: selectedParagraphPosition
    };
}
