import { IKeyboardShortcutsProps } from './types';
import * as React from 'react';
import './_KeyboardShortcuts.scss';
import {
    KEYBOARD_SHORTCUTS_CARDS_AND_LISTS,
    KEYBOARD_SHORTCUTS_CARDS_AND_LISTS_SHORTCUTS,
    KEYBOARD_SHORTCUTS_CLASS,
    KEYBOARD_SHORTCUTS_GLOBAL,
    KEYBOARD_SHORTCUTS_GLOBAL_SHORTCUTS,
    KEYBOARD_SHORTCUTS_TEXT_INPUTS,
    KEYBOARD_SHORTCUTS_TEXT_INPUTS_SHORTCUTS
} from './constants';
import { Shortcuts } from '../shortcuts/Shortcuts';

export const KeyboardShortcuts = ({}: IKeyboardShortcutsProps) => {
    return (
        <div className={KEYBOARD_SHORTCUTS_CLASS}>
            <div>
                <Shortcuts
                    title={KEYBOARD_SHORTCUTS_CARDS_AND_LISTS}
                    shortcuts={KEYBOARD_SHORTCUTS_CARDS_AND_LISTS_SHORTCUTS}
                />
                <Shortcuts
                    title={KEYBOARD_SHORTCUTS_TEXT_INPUTS}
                    shortcuts={KEYBOARD_SHORTCUTS_TEXT_INPUTS_SHORTCUTS}
                />
            </div>
            <div>
                <Shortcuts
                    title={KEYBOARD_SHORTCUTS_GLOBAL}
                    shortcuts={KEYBOARD_SHORTCUTS_GLOBAL_SHORTCUTS}
                />
                {/*<Shortcuts*/}
                {/*    title={KEYBOARD_SHORTCUTS_TEXT}*/}
                {/*    shortcuts={KEYBOARD_SHORTCUTS_TEXT_SHORTCUTS}*/}
                {/*/>*/}
            </div>
        </div>
    );
}
