import { Dispatch, ThunkAction } from '../../../types/actions';
import { IGetState, TCardId } from '../../../types/types';
import { calcCardChecklistStatsByCardId } from '../selectors/calcCardChecklistStatsByCardId';
import { cardSetChecklistStats } from '../../../rest/effects/card/card/cardSetChecklistStats';
import { getCardDone } from '../selectors/getCardDone';

type TCardChecklistStatsDebounces = {
    [cardId: number]: number;
};
const debounces: TCardChecklistStatsDebounces = {};

/**
 * метод исполняется, как только его перестают непрерывно дёргать
 */

export const cardChecklistStatsUpdate = (
    cardId: TCardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        if (debounces[cardId]) clearTimeout(debounces[cardId]);
        return new Promise((resolve) => {
            debounces[cardId] = window.setTimeout(() => {
                delete debounces[cardId];
                const checklistStats = calcCardChecklistStatsByCardId(getState(), cardId);
                return dispatch(cardSetChecklistStats(cardId, checklistStats)).then(resolve);
            }, 1000);
        });
    };
    return action;
};
