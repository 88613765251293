import { IGetState, TBoardId, TThemeId } from '../../../../../../../../../types/types';
import { EThemeBackgroundOption } from '../../../../../../../../../types/rest/IRestTheme';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { boardThemeSetBackgroundOption } from '../../../../../../../../../rest/effects/boardTheme/boardThemeSetBackgroundOption';

export const themeBackgroundOptionUpdate = (
    boardId: TBoardId,
    themeId: TThemeId,
    backgroundOption: EThemeBackgroundOption
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(boardThemeSetBackgroundOption(boardId, themeId, backgroundOption))
    };
    return action;
};
