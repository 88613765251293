import { IGetState, TBoardId } from '../../../types/types';
import { EPushNotificationSettingsScope } from '../../../types/rest/IRestPushNotificationSettings';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { pushNotificationSettingsPatchRest } from './api/helpers/pushNotificationSettingsPatchRest';
import { getPushNotificationSettingsByBoardId } from '../../../store/model/selectors/getPushNotificationSettingsByBoardId';

export const pushNotificationAddScope = (
    boardId: TBoardId,
    addScope: EPushNotificationSettingsScope
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const pushNotification = getPushNotificationSettingsByBoardId(getState(), boardId);
        const scope: EPushNotificationSettingsScope[] = pushNotification ? [...pushNotification.scope] : [];
        if (!scope.includes(addScope)) {
            scope.push(addScope);
            return dispatch(pushNotificationSettingsPatchRest(boardId, {scope}));
        }
        return Promise.resolve();
    };
    return action;
}
