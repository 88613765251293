import { IApplicationState } from '../../../../../../../../types/types';
import { connect } from 'react-redux';
import { IEmailNotificationsTogglerHOCProps } from './types';
import { onChange } from './events/onChange';
import { getNotificationSettingsByBoardId } from '../../../../../../../../store/model/selectors/getNotificationSettingsByBoardId';
import { SectionToggler } from '../../../../../../base/components/SectionToggler/SectionToggler';
import { ISectionTogglerFields, ISectionTogglerEvents } from '../../../../../../base/components/SectionToggler/types';
import { getPanelReadonly } from '../../../../selectors/getPanelReadonly';
import { onLoad } from './events/onLoad';

const mapStateToProps = (
    state: IApplicationState,
    { boardId }: IEmailNotificationsTogglerHOCProps
): ISectionTogglerFields => {
    const notification = getNotificationSettingsByBoardId(state, boardId);
    const isDisabled = getPanelReadonly(state, boardId);
    return {
        checked: notification && notification.enabled,
        isDisabled,
    };
};

const mapDispatchToProps = (
    dispatch: any,
    { boardId }: IEmailNotificationsTogglerHOCProps
): ISectionTogglerEvents => {
    return {
        onLoad: () => dispatch(onLoad(boardId)),
        onChange: () => dispatch(onChange(boardId))
    }
};

export const EmailNotificationsTogglerHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(SectionToggler);

EmailNotificationsTogglerHOC.displayName = 'EmailNotificationsTogglerHOC';
