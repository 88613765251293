import * as React from 'react';
import './_CardAssign.scss';
import { CLASS_MY_WORK_CARD } from '../../../constants';
import { Tooltip } from 'kui';
import { ICardAssignFields } from './types';
import { getFormattedDate } from '../../../helpers/getFormattedDate';

export function CardAssign ({
    card,
    isUserTimeFormat12
}: ICardAssignFields) {
    const cardAssignDate = card.cardAssignDate / 1000;
    const className = CLASS_MY_WORK_CARD + '__assign';

    return (
        <div className={className}>
            {
                !!cardAssignDate &&
                <Tooltip
                    delayClose={1}
                    value={getFormattedDate(cardAssignDate, false, isUserTimeFormat12)}
                    isNoEvents
                >
                    <span>{getFormattedDate(cardAssignDate)}</span>
                </Tooltip>
            }
        </div>
    );
};
