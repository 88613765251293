import * as React from 'react';
import './_EmptyDummy.scss';
import { Icon } from 'kui';
import { IEmptyDummyInheritedProps } from './types';
import ReactHtmlParser from 'react-html-parser';

export function EmptyDummy ({
    className,
    buttons,
    icon,
    text,
    title
}: IEmptyDummyInheritedProps) {
    let classNameMerged = 'empty-dummy';
    classNameMerged += className ? ' ' + className : '';

    return (
        <div className={classNameMerged}>
            <div className="empty-dummy__content">
                <Icon
                    className="empty-dummy__icon"
                    size={96}
                    xlink={icon}
                />
                <div className="empty-dummy__title">
                    {title}
                </div>
                {!!text &&
                    <div className="empty-dummy__text">{ReactHtmlParser(text)}</div>
                }
                {buttons &&
                    <div className="empty-dummy__buttons">
                        {buttons}
                    </div>
                }
            </div>
        </div>
    )
};

EmptyDummy.defaultProps = {
    buttons: null,
    icon: 'e-board',
    text: '',
    title: ''
};
