import * as React from 'react';
import { IMoveToProps } from './types';
import { MoveToColumn } from '../MoveToColumn/MoveToColumn';
import './_MoveTo.scss';
import { moveToReducer } from './moveToReducer';
import {
    BOARDS_COLUMN_TITLE,
    CARDS_COLUMN_TITLE,
    CLASS_MOVE_TO,
    INSERT_AFTER_CARD,
    INSERT_AFTER_LIST,
    LISTS_COLUMN_TITLE
} from './constants';
import { Loader } from 'kui';
import { IBoard } from '../../../../../../../store/model/board/types';

export function MoveTo({
    activeListId,
    activeBoardId,
    boards,
    isLoading,
    onLoadMore,
    onListSelected,
    onCardSelected,
    onDidMount,
    onLoadBoardLists
}: IMoveToProps) {
    const classLoader = CLASS_MOVE_TO + '-loader';
    if (isLoading) return <Loader className={classLoader}/>;
    const className = CLASS_MOVE_TO + '-column-container';

    let [moveToState, updateMoveToState] = React.useReducer(moveToReducer, {
        showLoadMore: true,
        isLoading: false,
        activeBoard: 0,
        activeList: 0,
        boards: []
    });

    const hasBoardCards = onCardSelected && moveToState.boards.length && !!moveToState.boards[moveToState.activeBoard].lists.find(list => list.cards && list.cards.length);

    const classNameTypeMoveTo = className + (hasBoardCards ? '-with-cards' : '');

    const handleBoardSelected = (index: number) => {
        const board = moveToState.boards[index];
        if (board) {
            if (board.lists && board.lists.length) {
                updateMoveToState({
                    activeBoard: index,
                    activeList: 0
                });
            } else {
                if (moveToState.isLoading) return;
                updateMoveToState({...moveToState, isLoading: true});
                onLoadBoardLists(board).then((loadedBoard: IBoard) => {
                    const boards = replaceBoard([...moveToState.boards], {...loadedBoard})
                    updateMoveToState({
                        isLoading: false,
                        activeBoard: index,
                        activeList: 0,
                        boards
                    });
                })
            }
        }
    };

    const handleListSelected = (index: number) => {
        if (onCardSelected && hasBoardCards) {
            updateMoveToState({
                activeList: index
            });
        } else {
            const board = moveToState.boards[moveToState.activeBoard];
            onListSelected(board, index);
        }
    };

    const handleCardSelected = (index: number) => {
        const board = moveToState.boards[moveToState.activeBoard];
        const list = board.lists[moveToState.activeList];
        onCardSelected(board, list.id, index);
    };
    const replaceBoard = (
        boards: IBoard[],
        boardToReplace: IBoard
    ): IBoard[] => {
        let boardsSet = [...boards];
        const index = boardsSet.findIndex(b => b.id === boardToReplace.id);
        if (index >= 0) {
            boardToReplace.lists.sort((list1, list2) => list1.orderNumber - list2.orderNumber);
            boardsSet[index] = boardToReplace;
        }
        return boardsSet;
    }

    const getUpdatedBoards = (
        boards: IBoard[]
    ): IBoard[] => {
        let boardsSet = [...boards];
        boardsSet = boardsSet.filter((boardSetItem, index) => { // deleting duplicate items
            const findBoardIndex = boardsSet.findIndex(board => {
                return board.id === boardSetItem.id;
            });
            return findBoardIndex === index;
        });
        boardsSet.forEach((board: IBoard) => {
            board.lists.sort((list1, list2) => list1.orderNumber - list2.orderNumber);
        });
        const currentBoardIndex = boardsSet.findIndex(board => board.id === activeBoardId);
        const currentBoard = boardsSet[currentBoardIndex];
        boardsSet.splice(currentBoardIndex, 1);
        boardsSet = [currentBoard, ...boardsSet];
        return boardsSet;
    }

    const handleLoadMore = () => {
        if (moveToState.isLoading) {
            return;
        }
        updateMoveToState({...moveToState, isLoading: true});
        onLoadMore().then((boards) => {
            updateMoveToState({
                isLoading: false,
                showLoadMore: false,
                boards: getUpdatedBoards([
                    ...moveToState.boards,
                    ...boards
                ])
            });
        });
    };

    React.useEffect(() => {
        const currentBoardIndex = boards.findIndex(board => board.id === activeBoardId);
        const activeList = boards[currentBoardIndex].lists.findIndex((list) => list.id === activeListId);
        updateMoveToState({
            activeList
        });
        if (onDidMount)
            onDidMount();
    }, []);

    React.useEffect(() => {
        updateMoveToState({
            boards: getUpdatedBoards([
                ...boards,
                ...moveToState.boards
            ])
        })
    }, [boards])

    const boardItems =
        moveToState
            .boards.map(board => ({name: board.name, id: board.id}));

    const boardListItems = moveToState.boards.length
        ? moveToState
            .boards[moveToState.activeBoard]
            .lists.map(list => ({name: list.name, id: list.id}))
        : [];

    const activeBoardList = moveToState.boards.length
        ? moveToState.boards[moveToState.activeBoard]
            .lists[moveToState.activeList]
        : null;

    const listCardsItems = activeBoardList && activeBoardList.cards ?
        activeBoardList.cards.map(card => ({name: card.name, id: card.id})) : [];

    return (
            <div>
                {
                    moveToState.isLoading &&
                    (<Loader className={classLoader}/>)
                }
                    <div className={className + ' ' +classNameTypeMoveTo}>
                        <MoveToColumn title={BOARDS_COLUMN_TITLE}
                                      items={boardItems}
                                      typeColumn={'boards'}
                                      onClick={handleBoardSelected}
                                      loadMore={moveToState.showLoadMore}
                                      onClickLoadMore={handleLoadMore}
                                      activeIndex={moveToState.activeBoard}
                        />
                        <MoveToColumn title={LISTS_COLUMN_TITLE}
                                      typeColumn={'lists'}
                                      items={boardListItems}
                                      onClick={handleListSelected}
                                      activeIndex={hasBoardCards ? moveToState.activeList : null}
                                      insertFirst={!onCardSelected}
                                      insertAfterTitle={INSERT_AFTER_LIST}
                        />
                        {
                            hasBoardCards && onCardSelected &&
                            <MoveToColumn title={CARDS_COLUMN_TITLE}
                                          items={listCardsItems}
                                          typeColumn={'cards'}
                                          onClick={handleCardSelected}
                                          insertFirst={true}
                                          insertAfterTitle={INSERT_AFTER_CARD}
                            />
                        }
                    </div>
            </div>);
}
