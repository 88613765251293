import { connect } from 'react-redux';
import { getCard } from '../../../../../../../store/model/selectors/getCard';
import { IApplicationState } from '../../../../../../../types/types';
import { ICardEstimateAndSpentTimePanelButtonHOCProps } from './types';
import {
    ICardEstimateAndSpentTimePanelButtonEvents,
    ICardEstimateAndSpentTimePanelButtonFields
} from '../../components/CardEstimateAndSpentTimePanelButton/types';
import {
    CardEstimateAndSpentTimePanelButton
} from '../../components/CardEstimateAndSpentTimePanelButton/CardEstimateAndSpentTimePanelButton';
import { CARD_SECTION_ESTIMATE } from '../../constants';
import { getPanelButtonShow } from '../../../selectors/getPanelButtonShow';
import { onClick } from './events/onClick';

const mapStateToProps = (
    state: IApplicationState,
    { boardId, cardId, isShowTooltip }: ICardEstimateAndSpentTimePanelButtonHOCProps
): ICardEstimateAndSpentTimePanelButtonFields => {
    const card = getCard(state, cardId);
    const isShow = getPanelButtonShow(state, boardId, cardId);
    const tooltip = isShowTooltip
        ? CARD_SECTION_ESTIMATE
        : null;
    return {
        boardId,
        cardId,
        estimateFlag: !!card.estimate || !!card.totalSpentTime, // индикатор должен соответствовать отображению секции, поэтому пришлось спент тоже добавить
        isShow,
        tooltip,
    }
};

const mapDispatchToProps = (
    dispatch: any,
    {boardId, cardId, onClick: ownOnClick}: ICardEstimateAndSpentTimePanelButtonHOCProps
): ICardEstimateAndSpentTimePanelButtonEvents => {
    return {
        onClick: () => dispatch(onClick(boardId, cardId, ownOnClick))
    }
};

export const CardEstimateAndSpentTimePanelButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardEstimateAndSpentTimePanelButton);

CardEstimateAndSpentTimePanelButtonHOC.displayName = 'CardEstimateAndSpentTimePanelButtonHOC';
