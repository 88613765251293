export const filterArrayByValue = (
    list: any[],
    fieldForSearch: string,
    searchQuery: string
): any[] => {
    let result: any = [];
    const search = searchQuery.toLowerCase();
    const filtered = list.filter((item) => {
        let itemForSearch = item[fieldForSearch]
        if (!itemForSearch && item.fields) itemForSearch = item.fields[fieldForSearch];
        if (!itemForSearch) return false;

        itemForSearch = itemForSearch.toLowerCase();
        let indexFirst = itemForSearch.indexOf(search);
        if (indexFirst < 0) {
            return false;
        }
        return true;
    });
    result = [...new Set( // deduplicate merged array
        [...result, ...filtered]
    )];
    return result;
};
