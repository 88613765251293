import { getCardListName } from './../../../../../../store/model/selectors/getCardListName';
import { root } from './../../../../../../store/constants';
import { getCardIcon } from '../../../../helpers/getCardIcon';
import { IApplicationState, TBoardId, TCardId } from '../../../../../../types/types';
import { getCardSubcardsSorted } from '../../../../../../store/model/selectors/getCardSubcards';
import { ICardDetailsSubcard } from '../components/CardSubcardsElements/types';
import { getCardIsEpic } from '../../../../../../store/model/selectors/getCardIsEpic';
import { getCardFullNameHelper } from '../../../../../../store/model/card/helpers/getCardFullNameHelper';
import { stripHtml } from '../../../../base/helpers/stripHtmlHelper';
import { markdown } from '../../../../../../helper/markdownHelper';
import { getCardActiveRequests } from '../../../../../../store/requestsState/selectors/getCardActiveRequests';
import { getCardColorTags } from '../../../../../../store/model/selectors/getCardColorTags';
import { getSubcardWeight } from '../../../../../../store/model/selectors/getSubcardWeight';
import { TStatus } from '../../../../../../types/model';

export const getCardDetailsSubCards = (
    state: IApplicationState,
    cardId: TCardId,
    boardId: TBoardId
): ICardDetailsSubcard[] => {
    const subCards = getCardSubcardsSorted(state, cardId);
    return subCards.filter((card) => {
        return [TStatus.STATUS_ACTIVE, TStatus.STATUS_ARCHIVE, TStatus.STATUS_SERVICE_TEMPLATE].includes(card.status);
    }).map((card) => {
        const isEpic = getCardIsEpic(state, card.id);
        const cardFullName = getCardFullNameHelper(card);
        const listName = getCardListName(state, card.id);
        return {
            ...card,
            activeRequests: getCardActiveRequests(state, card.id),
            colorTags: getCardColorTags(state, card.id),
            fullName: stripHtml(markdown(cardFullName)),
            icon: getCardIcon(card.status, isEpic),
            isEpic,
            link: root.App.router.getCardPageUrl(boardId, card.id),
            listName: stripHtml(markdown(listName)),
            weight: getSubcardWeight(state, card.id)
        };
    });
};
