export enum EBrowserNotificationsTypes {
    BOARD_SHARED = 'BOARD_SHARED',
    DUE_DATE = 'DUE_DATE',
    SHARED_BOARD_OPENED = 'SHARED_BOARD_OPENED',
}

export interface IBrowserNotificationsRequestHOCProps {
    type?: EBrowserNotificationsTypes;
    onAllow: () => void;
}
