import { connect } from 'react-redux';
import { onSubmit } from './events/onSubmit';
import { onSkip } from './events/onSkip';
import { getAsidePanelWidth } from '../../../../aside_panel/asidePanel/store/selectors/getAsidePanelWidth';
import { IApplicationState } from '../../../../../../types/types';
import { Poll } from '../../../../base/components/Poll/components/Poll/Poll';
import { IPollEvents, IPollFields } from '../../../../base/components/Poll/components/Poll/types';
import { getUserPricingPoll } from '../../../../../../store/model/authUser/selectors/getUserPricingPoll';
import { getShowFeaturePoll } from '../../../featurePoll/hocs/FeaturePoll/selectors/getShowFeaturePoll';
import { POLL_DELAY_AFTER } from '../../../../base/components/Poll/components/constants';
import { CLASS_PRICING_POLL, PRICING_POLL_OPTIONS, PRICING_POLL_REVIEW_TITLE, PRICING_POLL_TITLE } from './constants';
import { onAddOpinion } from './events/onAddOpinion';

const mapStateToProps = (
    state: IApplicationState,
): IPollFields => {
    const { lastTimeShowed, isShowed, isTriggered } = getUserPricingPoll(state);
    if (isShowed || !isTriggered) return;

    let isShow = null;

    const isTrialExtendOpen = !!document.querySelector('.dialog-blocker__trial-dialog');
    isShow = !getAsidePanelWidth(state) && !isTrialExtendOpen && !getShowFeaturePoll(state);
    if (!isShow) return { isShow };

    isShow = !lastTimeShowed;

    if (lastTimeShowed) {
        const timeSinceLastShowed = Date.now() - lastTimeShowed;
        if (timeSinceLastShowed < POLL_DELAY_AFTER) { // только что ответил, показать Thank you
            isShow = true;
        }
    }

    return {
        isHideOnCancel: true,
        isShow,
        className: CLASS_PRICING_POLL,
        title: PRICING_POLL_TITLE,
        reviewTitle: PRICING_POLL_REVIEW_TITLE,
        // questionsList: [{ question: '', isChips: true, options: PRICING_POLL_OPTIONS }],
    };
}

const mapDispatchToProps = (
    dispatch: any,
): IPollEvents => {
    return {
        onAddOpinion: () => dispatch(onAddOpinion()),
        onSkip: () => dispatch(onSkip()),
        onSubmit: (results) => dispatch(onSubmit(results))
    };
}

export const PricingPollHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(Poll);

PricingPollHOC.displayName = 'PricingPollHOC';
