import { getInWorkCard } from '../../../../../../store/model/selectors/getInWorkCard';
import { Dispatch, ThunkAction } from '../../../../../../types/actions';
import { IGetState } from '../../../../../../types/types';
import { root } from '../../../../../../store/constants';
import { SegmentTopBarEvent, segmentTrackAction } from '../../../../../../middlewares/segment';
import { FAKE_CARD_ID_FOR_WIDGET_PRESENTATION } from 'app/const';

export const onOpenInWorkCard = (): ThunkAction =>  {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const inWorkCard = getInWorkCard(getState());
        if (!inWorkCard) return;

        if (inWorkCard.id === FAKE_CARD_ID_FOR_WIDGET_PRESENTATION) return;

        dispatch(segmentTrackAction(SegmentTopBarEvent.WORKING_ON_CLICKED));
        root.App.controller.cleverOpenCard(inWorkCard.boardId, inWorkCard.id);
    }

    return action;
}
