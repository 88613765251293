import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { DashboardImportRouter } from '../../../../../../../dialogs/openBoards/helpers/importBoard';
import { REST_PWC_IMPORT } from '../../../../../../../dialogs/openBoards/constants';
import {
    PWC_OPEN_FILE_MIME_FILTER,
    SNACKBAR_BOARD_IMPORT_ID,
    SNACKBAR_PWC_IMPORT_ERROR_TEXT,
    SNACKBAR_PWC_IMPORT_PROGRESS_TEXT
} from '../../../../../../../dialogs/openBoards/FooterImport/hocs/FooterImportHOC/constants';
import { TBoardId } from '../../../../../../../../../types/types';
import { setVersion } from '../../../../../../../../../rest/effects/board/setVersion';
import { EBoardVersion } from '../../../../../../../../../types/rest/IRestDashboard';
import {reopenBoard} from 'app/view/react_components/aside_panel/boardDetails/details/PwcImport/hocs/BoardPwcImportHOC/effects/reopenBoard';

export const importPwc = (
    boardId: TBoardId,
    importType: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(setVersion(EBoardVersion.V4, boardId)).then(() => {
            new DashboardImportRouter({
                importUrl: REST_PWC_IMPORT + '/' + boardId + '?importType=' + importType,
                snackbarImportId: SNACKBAR_BOARD_IMPORT_ID,
                importProgressText: SNACKBAR_PWC_IMPORT_PROGRESS_TEXT,
                importErrorText: SNACKBAR_PWC_IMPORT_ERROR_TEXT,
                mimeTypesFilter: PWC_OPEN_FILE_MIME_FILTER,
                onSuccess: () => {
                    dispatch(reopenBoard(boardId));
                }
            }, dispatch).start();
        });
    };
    return action;
};
