import { IApplicationState, TBoardId } from '../../../types/types';
import { getBoardPermissions } from './getBoardPermissions';

export const getBoardPermissionsAllowChangeLogo = (
    state: IApplicationState,
    boardId: TBoardId,
): boolean => {
    const boardPermissions = getBoardPermissions(state, boardId);
    const allowChangeLogo = (
        boardPermissions &&
        boardPermissions.authPermissions &&
        boardPermissions.authPermissions.allowChangeLogo
    );
    return allowChangeLogo;
};
