import { connect } from 'react-redux';
import { IApplicationState, TCardId } from 'app/types/types';
import { CustomPropertiesSection } from '../../../../../cardDetails/CustomPropertiesSection/components/CustomPropertiesSection/CustomPropertiesSection';
import {
    ICustomPropertiesEvents,
    ICustomPropertiesFields
} from '../../../../../cardDetails/CustomPropertiesSection/components/CustomPropertiesSection/types';
import { onAdd } from '../../../../../cardDetails/CustomPropertiesSection/hocs/CustomPropertiesSectionHOC/events/onAdd';
import { onChangeType } from 'app/view/react_components/aside_panel/cardDetails/CustomPropertiesSection/hocs/CustomPropertiesSectionHOC/events/onChangeType';
import { onClickCancel } from '../../../../../cardDetails/CustomPropertiesSection/hocs/CustomPropertiesSectionHOC/events/onClickCancel';
import { onClickDisallow } from '../../../../../cardDetails/CustomPropertiesSection/hocs/CustomPropertiesSectionHOC/events/onClickDisallow';
import { onClickEdit } from '../../../../../cardDetails/CustomPropertiesSection/hocs/CustomPropertiesSectionHOC/events/onClickEdit';
import { onClickEmpty } from '../../../../../cardDetails/CustomPropertiesSection/hocs/CustomPropertiesSectionHOC/events/onClickEmpty';
import { onOpen } from '../../../../../cardDetails/CustomPropertiesSection/hocs/CustomPropertiesSectionHOC/events/onOpen';
import { onOptionAdd } from '../../../../../cardDetails/CustomPropertiesSection/hocs/CustomPropertiesSectionHOC/events/onOptionAdd';
import { onSave } from '../../../../../cardDetails/CustomPropertiesSection/hocs/CustomPropertiesSectionHOC/events/onSave';
import { onSort } from '../../../../../cardDetails/CustomPropertiesSection/hocs/CustomPropertiesSectionHOC/events/onSort';
import { CLASS_BOARD_DETAILS_SECTION } from '../../../../constants';
import { getSectionType } from '../../../../selectors/getSectionType';
import { BOARD_SECTION_CARD_CUSTOM_PROPERTIES } from '../../constants';
import { ICustomPropertiesHOCOwnProps } from './types';
import { onConfirmDelete } from '../../../../../cardDetails/CustomPropertiesSection/hocs/CustomPropertiesSectionHOC/events/onConfirmDelete';
import { getBoardCustomProperties } from './selectors/getBoardCustomProperties';
import { getIsUserBasicPlanOnly } from 'app/store/model/authUser/selectors/getIsUserBasicPlanOnly';

const mapStateToProps = () => {
    return (
        state: IApplicationState,
        ownProps: ICustomPropertiesHOCOwnProps
    ): ICustomPropertiesFields => {
        const { boardId, sectionType: sectionTypeOwn } = ownProps;
        const sectionType = getSectionType(state, boardId, sectionTypeOwn, 'allowCardCustomProperties');
        return {
            isBasicFeature: getIsUserBasicPlanOnly(state),
            className: CLASS_BOARD_DETAILS_SECTION + ' ' + CLASS_BOARD_DETAILS_SECTION + '--card-custom-properties',
            elements: getBoardCustomProperties(state, boardId),
            sectionType,
            title: BOARD_SECTION_CARD_CUSTOM_PROPERTIES,
        }
    };
};

const mapDispatchToProps = (
    dispatch: any,
    { boardId }: ICustomPropertiesHOCOwnProps
): ICustomPropertiesEvents => {
    const cardId: TCardId = null;
    return {
        onChange: (name, type, options) => dispatch(onAdd(boardId, cardId, name, type, options)),
        onChangeType: (type) => dispatch(onChangeType(cardId, type)),
        onClickCancel: () => dispatch(onClickCancel(cardId)),
        onClickDisallow: () => dispatch(onClickDisallow()),
        onClickEdit: () => dispatch(onClickEdit(cardId)),
        onClickEmpty: (isShow) => dispatch(onClickEmpty(isShow)),
        onConfirmDelete: (customPropertyId) => dispatch(onConfirmDelete(boardId, cardId, customPropertyId)),
        onSort: () => dispatch(onSort()),
        onOpen: () => dispatch(onOpen(cardId)),
        onOptionAdd: (customPropertyId, element) => dispatch(onOptionAdd(boardId, cardId, customPropertyId, element)),
        onUpdateValue: () => null, // no values in board settings
        onSave: (elements) => dispatch(onSave(boardId, cardId, elements)),
    }
};

export const CardCustomPropertiesSectionHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomPropertiesSection);

CardCustomPropertiesSectionHOC.displayName = 'CardCustomPropertiesSectionHOC';
