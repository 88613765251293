import * as React from 'react';
import { CLASS_CARD_DETAILS } from '../../../constants';
import { ICardChecklistAuthorProps } from './types';
import { UsersTooltip } from '../../../../../base/components/UsersTooltip/UsersTooltip';
import { TUserTooltipNaming } from '../../../../../base/components/UsersTooltip/types';
import { Icon } from 'kui';
import './_CardChecklistAuthor.scss';

export function CardChecklistAuthor ({
    author
}: ICardChecklistAuthorProps) {
    const className = CLASS_CARD_DETAILS + '-checklist__author';

    return (
        <div className={className}>
            <UsersTooltip
                naming={TUserTooltipNaming.AUTHOR}
                users={[author]}
                isNoEvents
            />
            <Icon xlink={'feather'} size={16} />
        </div>
    );
}
