import { getBoardMetaCardAging } from '../../selectors/getBoardMetaCardAging';
import { IApplicationState, TBoardId } from '../../../../types/types';

export const getBoardCardAgingEnabled = (
    state: IApplicationState,
    boardId: TBoardId
): boolean => {
    const boardAging = getBoardMetaCardAging(state, boardId);

    return boardAging && boardAging.enabled;
}
