import { PageCollection } from '@microsoft/microsoft-graph-client';
import { Permission } from '@microsoft/microsoft-graph-types';
import { errorHandler, graphClient } from 'app/helper/authorisation/microsoft/graph/Graph';
import {
    EFilePermissionRole,
    getGrantedFromPermissions,
    IFilePermission
} from 'app/helper/authorisation/microsoft/graph/api/getFilePermissions';

export const addFilePermissions = async (
    driveId: string,
    fileId: string,
    emails: string[],
    role: EFilePermissionRole
) => {
    try {
        let request = graphClient.api(`/drives/${driveId}/items/${fileId}/invite`);
        let invite = {
            'recipients': emails.map(email => (
                {
                    email
                }
            )),
            'requireSignIn': true,
            'roles': [role]
        };
        let response = await request.post(invite) as PageCollection;
        let permissions = response.value as Permission[];
        return permissions.map((val) => {
            return {
                id: val.id,
                role: val.roles[0],
                granted: getGrantedFromPermissions(val)
            } as IFilePermission
        })
    } catch (e) {
        return errorHandler(e);
    }
}
