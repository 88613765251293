import { IApplicationState } from '../../../../../../../types/types';
import { TTabKey, IGoogleDrive } from '../../types';
import { getDrives } from './getDrives';
import { TABS } from '../../constants';

export const getDrivesByTabKey = (
    state: IApplicationState,
    tabKey: TTabKey
): IGoogleDrive[] => {
    const drives = getDrives(state);
    const excludeDrives = TABS[tabKey].excludeDrives;
    const drivesByTabKey = drives.filter((drive) => {
        const excludeDrivesFilter = (
            !excludeDrives ||
            !excludeDrives.includes(drive.id)
        );
        return excludeDrivesFilter;
    });
    return drivesByTabKey;
}
