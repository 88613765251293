import { ISnackbarModel, SnackbarId } from '../types';

export const SNACKBARS_STACK_ADD_UPDATE =   'SNACKBARS_STACK_ADD_UPDATE';
export const SNACKBARS_STACK_DELETE =       'SNACKBARS_STACK_DELETE';
export const SNACKBARS_STACK_SUSPEND =      'SNACKBARS_STACK_SUSPEND';

interface ISnackbarAddAction {
    type: typeof SNACKBARS_STACK_ADD_UPDATE;
    payload: ISnackbarModel;
}

interface ISnackbarDeleteAction {
    type: typeof SNACKBARS_STACK_DELETE;
    payload: SnackbarId;
}

interface ISnackbarsStackSuspendAction {
    type: typeof SNACKBARS_STACK_SUSPEND;
}

export type SnackbarsStackAction =
    ISnackbarAddAction |
    ISnackbarDeleteAction |
    ISnackbarsStackSuspendAction;
