import { AsidePanelActionsButton } from '../../../..';
import * as React from 'react';
import { IShowOnGanttButtonProps } from './types';
import { getAsidePanelActionsButtonTooltip } from '../../../../asidePanel/helpers/getAsidePanelActionsButtonTooltip';
import { HIDE_FROM_GANTT_TOOLTIP, SHOW_ON_GANTT, SHOW_ON_GANTT_HINT, SHOW_ON_GANTT_TOOLTIP } from './constants';
import { EHintKey, HintHOC } from '../../../../../hints';
import {
    CLASS_ASIDE_PANEL_ACTIONS_BUTTON_CONTAINER
} from '../../../../asidePanel/components/AsidePanelActionsButton/constants';
import { CLASS_GANTT_CONTEXT_HINT, GANTT_CONTEXT_TIMEOUT } from '../../../../../hints/components/Hint/constants';
import { MINUTE } from '../../../../../../../const';

export function ShowOnGanttButton({
    ganttVisibility,
    isBasicFeature,
    isDisallow,
    isProFeature,
    isShow,
    isShowHint,
    isShowTooltip,
    onClick,
    onShowHint,
}: IShowOnGanttButtonProps) {
    if (!isShow) return null;

    const className = CLASS_ASIDE_PANEL_ACTIONS_BUTTON_CONTAINER + '--gantt';

    let tooltip;
    if (isShowTooltip && !ganttVisibility) {
        tooltip = getAsidePanelActionsButtonTooltip({value: SHOW_ON_GANTT_TOOLTIP})
    } else if (ganttVisibility) {
        tooltip = getAsidePanelActionsButtonTooltip({value: HIDE_FROM_GANTT_TOOLTIP})
    }

    const timerRef = React.useRef(null);
    React.useEffect(() => {
        if (timerRef.current) clearTimeout(timerRef.current);
        if (!isShowHint) {
            timerRef.current = setTimeout(() => {
                onShowHint();
            }, MINUTE * 0.5);
        }

        return () => clearTimeout(timerRef.current);
    }, [isShowHint]);

    const [showHint, setShowHint] = React.useState(null);
    const onHide = () => {
        setTimeout(() => {
            setShowHint(true);
        }, GANTT_CONTEXT_TIMEOUT);
    };

    return (
        <>
            <AsidePanelActionsButton
                className={`${className} ${isShowHint ? className + '-highlight' : ''}`}
                indicatorBoolean={!!ganttVisibility}
                icon={'gantt-chart'}
                isDisabled={isDisallow}
                title={SHOW_ON_GANTT}
                tooltip={showHint && tooltip}
                onClick={onClick}
                isProFeature={isProFeature}
                isBasicFeature={isBasicFeature}
            />
            <HintHOC
                className={CLASS_GANTT_CONTEXT_HINT}
                key={EHintKey.GANTT_CONTEXT_SHOW_ON_GANTT}
                hintKey={EHintKey.GANTT_CONTEXT_SHOW_ON_GANTT}
                show={isShowHint}
                isHideOnHintClick
                direction={'up'}
                value={SHOW_ON_GANTT_HINT}
                isHidable={false}
                selector={`.${className}`}
                onHide={onHide}
            />
        </>
    );
}
