import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { ICardHOCProps } from '../CardHOC/types';
import { onOpenLists } from './events/onOpenLists';
import { ICardListEvents, ICardListFields } from '../../components/CardList/types';
import { CardList } from '../../components/CardList/CardList';

const mapStateToProps = (
    state: IApplicationState,
    { card }: ICardHOCProps
): ICardListFields => {
    return {
        listName: card.listName,
    }
}

const mapDispatchToProps = (
    dispatch: any,
    { card }: ICardHOCProps
): ICardListEvents => ({
    onOpenLists: () => dispatch(onOpenLists(card.cardId)),
});

export const CardListHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardList);
CardListHOC.displayName = 'CardListHOC';
