import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { IGetState, TBoardId } from '../../../../../../../../../types/types';
import { getMyWorkShowBoards } from '../../../../../store/selectors/getMyWorkShowBoards';
import { myWorkShowBoardsSet } from '../../../../../../../main/myWorkView/store/actions/myWorkShowBoardsSet';
import { SegmentFilterEvent } from '../../../../../../../../../middlewares/segment/trackEntities/filterEvents';
import {
    filterSegmentTrackAction
} from '../../../../../../../aside_panel/filterPanelBoard/effects/filterSegmentTrackAction';
import { MY_WORK_FILTER_BOARD_ID } from '../../../../../../../aside_panel/filterPanelBoard/constants';
import { updateMyWorkSelectedBoards } from '../../../../../effects/updateMyWorkSelectedBoards';

export const onRemoveBoard = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const boardIds = getMyWorkShowBoards(state);
        dispatch(updateMyWorkSelectedBoards(boardId, false));
        dispatch(myWorkShowBoardsSet(boardIds.filter(id => id !== boardId)));
        dispatch(filterSegmentTrackAction(SegmentFilterEvent.FILTER_BOARD_REMOVE, MY_WORK_FILTER_BOARD_ID));
    };
    return action;
};
