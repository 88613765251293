import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { ICardEpicInHeadSectionHOCProps } from './types';
import { getCard } from '../../../../../../../store/model/selectors/getCard';
import { getSectionType } from '../../../selectors/getSectionType';
import { CardInHeadLinkSection } from '../../components/CardEpicInHeadSection/CardInHeadLinkSection';
import { ICardEpicInHeadSectionFields, ICardEpicInHeadSectionEvents } from '../../components/CardEpicInHeadSection/types';
import { getCardFullNameHelper } from '../../../../../../../store/model/card/helpers/getCardFullNameHelper';
import { onClick } from './events/onClick';
import { root } from '../../../../../../../store/constants';
import { TStatus } from '../../../../../../../types/model';
import { markdown } from '../../../../../../../helper/markdownHelper';
import { stripHtml } from '../../../../../base/helpers/stripHtmlHelper';
import { EPIC_ARCHIVED, EPIC_LABEL, EPIC_TOOLTIP } from './constants';

const mapStateToProps = (
    state: IApplicationState,
    props: ICardEpicInHeadSectionHOCProps
): ICardEpicInHeadSectionFields => {
    const { boardId, cardId, sectionType: sectionTypeOwn } = props;
    const card = getCard(state, cardId);
    const epicCard = card.epicId ? getCard(state, card.epicId) : null;
    const isShow = Boolean(epicCard);
    const sectionType = getSectionType(state, boardId, cardId, sectionTypeOwn, 'allowSubCard');

    let cardName = null;
    let cardUrl = null;
    let isArchived = null;

    if (epicCard) {
        const cardFullName = getCardFullNameHelper(epicCard);
        cardName = stripHtml(markdown(cardFullName));
        cardUrl = root.App.router.getCardPageUrl(boardId, card.epicId);
        isArchived = epicCard.status === TStatus.STATUS_ARCHIVE;
    }

    return {
        label: EPIC_LABEL,
        tooltip: EPIC_TOOLTIP,
        archived: EPIC_ARCHIVED,
        cardName,
        cardUrl,
        isArchived,
        isShow,
        sectionType,
    }
};

const mapDispatchToProps = (
    dispatch: any,
    props: ICardEpicInHeadSectionHOCProps
): ICardEpicInHeadSectionEvents => {
    const { boardId, cardId } = props;
    return {
        onClick: () => dispatch(onClick(boardId, cardId)),
    }
};

export const CardEpicInHeadSectionHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardInHeadLinkSection);

CardEpicInHeadSectionHOC.displayName = 'CardEpicInHeadSectionHOC';
