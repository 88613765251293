import { AT_COLOR_ADD, IColorAddAction } from './types';
import { IColor } from '../../../../types/model';

export const addColorAction = (
    color: IColor
): IColorAddAction => {
    return {
        type: AT_COLOR_ADD,
        color
    }
};
