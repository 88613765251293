import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { openATM } from 'app/view/react_components/base/effects/openATM';

export const onClick = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        setTimeout(() => { // KNB-3727: подождать bodyClicked в notify_informer.js для закрытия notifications
            dispatch(openATM());
        }, 0);
    };
    return action;
};
