import * as React from 'react';
import { ERestActivityPropertyActionType } from '../../../../../../../types/rest/activity/IRestActivityBase';
import { IRestCardCommonActivity } from '../../../../../../../types/rest/activity/IRestCardCommonActivity';
import { IActivityProps } from '../../../../boardDetails/activity/TabActivity/types';
import { TabActivityContext } from '../../../../boardDetails/activity/TabActivity/components/TabActivity/constants';
import { TActivity } from '../../../../../../../store/activities/types';
import {
    ActivityItemBody
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemBody/ActivityItemBody';
import { ActivityHeader } from '../../../../boardDetails/activity/TabActivity/components/ActivityHeader/ActivityHeader';
import {
    ActivityItemName
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemName/ActivityItemName';
import { msFileThumbnailStorage } from '../../../../../../../helper/authorisation/microsoft/storage/msThumbnail';
import { blockMarkdown } from '../../../../../../../helper/markdownHelper';

export function CardActivityDescription({
    activity
}: IActivityProps<IRestCardCommonActivity>) {
    const { onOpenCard } = React.useContext(TabActivityContext);
    const { label, card } = activity as TActivity;

    const [markdown, setMarkdown] = React.useState('');

    React.useEffect(() => {
        let description = activity.newValue;
        if (activity.propertyActionType === ERestActivityPropertyActionType.DELETE) {
            description = activity.oldValue;
        }

        setMarkdown(blockMarkdown(description));

        if (description && msFileThumbnailStorage.is()) {
            msFileThumbnailStorage.getSrcThumbnails(description)
                .then((text) => {
                    setMarkdown(blockMarkdown(text));
                })
                .catch(() => {})
        }
    }, [activity]);

    return (
        <ActivityItemBody
            header={<ActivityHeader icon={'description'} label={label} />}
            activity={activity}
            onClick={() => onOpenCard(card.id, activity.id)}
        >
            <ActivityItemName name={markdown} isMarkdown />
        </ActivityItemBody>
    );
};
