import { AsidePanelActionsButton } from '../../../../..';
import * as React from 'react';
import { IPushNotificationsPanelButtonProps } from './types';
import { CLASS_BOARD_DETAILS_SECTION } from '../../../../constants';
import { BOARD_SECTION_PUSH_NOTIFICATIONS } from '../../constants';

export function PushNotificationsPanelButton ({
    indicatorBoolean,
    tooltip,
    onClick
}: IPushNotificationsPanelButtonProps) {

    return (
        <AsidePanelActionsButton
            children={null}
            indicatorBoolean={indicatorBoolean}
            sectionSelector={`.${CLASS_BOARD_DETAILS_SECTION}--notifications`}
            title={BOARD_SECTION_PUSH_NOTIFICATIONS}
            tooltip={tooltip}
            onClick={onClick}
        />
    );
}
