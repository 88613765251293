import { IApplicationState, TBoardId, TFilterId } from '../../../../../../../../types/types';
import {
    getAuthUserSortedSavedFiltersIds
} from '../../../../../../../../store/model/authUser/selectors/getAuthUserSortedSavedFiltersIds';
import { filterPanelBoardsSelector } from '../../../../store/selectors/filterPanelBoardsSelector';
import { savedFilterSelector } from '../../../../store/selectors/savedFilterSelector';

export const getSortedSavedFiltersIds = (
    state: IApplicationState,
    boardId: TBoardId
): TFilterId[] => {
    const sortedSavedFiltersIds = getAuthUserSortedSavedFiltersIds(state);
    if (
        sortedSavedFiltersIds &&
        sortedSavedFiltersIds[boardId]
    ) {
        return sortedSavedFiltersIds[boardId];
    }
    const { savedFilterIds } = filterPanelBoardsSelector(state, boardId);
    return savedFilterIds
        .map(filterId => savedFilterSelector(state, filterId))
        .sort((a, b) => { // сначала публичные
            const isPublic = Number(b.isPublic) - Number(a.isPublic);
            return isPublic || a.name.localeCompare(b.name)
        })
        .map(filter => filter.id);
}
