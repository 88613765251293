import { getMessages } from '../../../../../../../store/constants';

export const SNACKBAR_BOARD_IMPORT_ID = 'SNACKBAR_BOARD_IMPORT_ID';

export const SNACKBAR_TRELLO_IMPORT_PROGRESS_TEXT = getMessages().getText('open_boards.snackbar.trello.progress.text');
export const SNACKBAR_TRELLO_IMPORT_ERROR_TEXT = getMessages().getText('open_boards.snackbar.trello.error.text');
export const TRELLO_OPEN_FILE_MIME_FILTER = 'application/json,application/javascript';

export const SNACKBAR_JIRA_IMPORT_PROGRESS_TEXT = getMessages().getText('open_boards.snackbar.jira.progress.text');
export const SNACKBAR_JIRA_IMPORT_ERROR_TEXT = getMessages().getText('open_boards.snackbar.jira.error.text');
export const JITA_OPEN_FILE_MIME_FILTER = 'application/xml,application/javascript,text/xml';

export const SNACKBAR_CSV_IMPORT_PROGRESS_TEXT = getMessages().getText('open_boards.snackbar.csv.progress.text');
export const SNACKBAR_CSV_IMPORT_ERROR_TEXT = getMessages().getText('open_boards.snackbar.csv.error.text');
export const CSV_OPEN_FILE_MIME_FILTER = 'text/csv';

export const SNACKBAR_PWC_IMPORT_PROGRESS_TEXT = getMessages().getText('open_boards.snackbar.pwc.progress.text');
export const SNACKBAR_PWC_IMPORT_ERROR_TEXT = getMessages().getText('open_boards.snackbar.pwc.error.text');
export const PWC_OPEN_FILE_MIME_FILTER = 'text/csv';

export const SNACKBAR_PWC_TEMPLATE_CHECKLISTS_IMPORT_PROGRESS_TEXT = getMessages().getText('open_boards.snackbar.pwc.progress.text');
