import { IBoardsItemProps } from './types';
import * as React from 'react';
import { Button, Icon, Tooltip } from 'kui';
import { TOOLTIP_BOARD_OPEN, TOOLTIP_CLOSE_BOARD, TOOLTIP_REMOVE_FROM_STARRED } from '../RecentBoards/constants';
import {
    NAVIGATION_PANEL_BOARD_TOOLTIP_SHORT_NAME,
    NAVIGATION_PANEL_CLOSE_MARKER,
    NAVIGATION_PANEL_CONTAINER_CLASS
} from '../../../constants';
import { getParentsClasses } from '../../../../../helpers/getParentsClasses';
import { NAVIGATION_PANEL_DASHBOARD_ACTION_CLASS } from '../../../components/NavigationPanelTabItem/constants';
import { getShortNameFromHtml } from '../../../../../helpers/getShortName';
import { Logo } from '../../../../../base/components/Logo/components/Logo/Logo';
import { ScrollText } from 'app/view/react_components/base/components/ScrollText/ScrollText';
import { markdown } from '../../../../../../../helper/markdownHelper';
import { IScrollTarget } from '../../../../../base/components/ScrollText/types';

export function BoardsItem({
    id,
    name,
    logo,
    logoColor,
    logoIcon,
    isMobile,
    closeDisabled,
    isStarred,
    onClick,
    onClose,
    onUnstar,
}: IBoardsItemProps) {
    if (!id) return null;

    const [markdownName, setMarkdownName] = React.useState('');
    const [title, setTitle] = React.useState('');
    const [isLong, setLong] = React.useState(false);
    const [scrollTarget, setScrollTarget] = React.useState({} as IScrollTarget);

    const className = 'navigation-panel__dashboard';
    const classLogo = className + '-logo';

    const onClickHandler = (e: React.MouseEvent<HTMLElement>) => {
        const element = e.target as HTMLElement;
        const path = getParentsClasses(element, [
            NAVIGATION_PANEL_DASHBOARD_ACTION_CLASS,
            'navigation-panel__dashboard'
        ]);
        if (!path.includes(NAVIGATION_PANEL_DASHBOARD_ACTION_CLASS)){
            onClick();
        }
    };

    const onKeyDown = (e: React.KeyboardEvent) => {
        if (!e) return;
        if (e.key === 'Enter') {
            onClickHandler(e as any);
        }
    }

    React.useEffect(() => {
        const markdownName = markdown(name);
        setMarkdownName(markdownName);
        let isLong = name.length > NAVIGATION_PANEL_BOARD_TOOLTIP_SHORT_NAME * 1.5;
        setLong(isLong);
        let title = isLong ? getShortNameFromHtml(markdownName) : markdownName;
        setTitle(isMobile ? null : isLong ? TOOLTIP_BOARD_OPEN + ' ' + title : null);
    }, [name, isMobile]);

    return (
        <div
            className={`navigation-panel__row ${className} ${className}--recent`}
            aria-label={title}
            role={'button'}
            tabIndex={0}
            onClick={onClickHandler}
            onKeyDown={onKeyDown}
        >
            <div className="navigation-panel__dashboard-button">
                <Tooltip
                    className={`
                            navigation-panel__tooltip
                            navigation-panel__tooltip--tab
                            ${isLong ? 'navigation-panel__tooltip-tab--long' : ''}
                        `}
                    direction={'right'}
                    portalId={'navigation-panel-portal'}
                    portalSelector={'.' + NAVIGATION_PANEL_CONTAINER_CLASS}
                    value={title}
                >
                    <span className={classLogo + '-wrapper'}>
                        {logo
                            ? <div
                                className={`${classLogo} ${classLogo}--custom ${NAVIGATION_PANEL_CLOSE_MARKER}`}
                                style={{backgroundImage: `url("${logo}")`}}
                                key={`${classLogo}${id}--custom`}
                            />
                            : <Logo
                                name={markdownName}
                                logo={{ icon: logoIcon, color: logoColor }}
                                className={NAVIGATION_PANEL_CLOSE_MARKER}
                            />
                        }
                        <div className={ 'navigation-panel__dashboard-name ' + NAVIGATION_PANEL_CLOSE_MARKER}>
                            <ScrollText
                                text={markdownName}
                                scrollTarget={scrollTarget}
                            />
                        </div>
                    </span>
                </Tooltip>
                {isStarred
                    ? <Button
                        className={`
                            ${NAVIGATION_PANEL_DASHBOARD_ACTION_CLASS}
                            ${NAVIGATION_PANEL_DASHBOARD_ACTION_CLASS}--starred
                            ${isMobile ? '' : ' navigation-panel__dashboard-close'}
                        `}
                        tooltip={TOOLTIP_REMOVE_FROM_STARRED}
                        variant={'icon'}
                        onClick={onUnstar}
                        onMouseOver={() => setScrollTarget({scrollToEnd: true})}
                        onMouseOut={(e) => {setScrollTarget({scrollToEnd: false, relatedTarget: e.relatedTarget})}}
                    >
                        <Icon size={16} xlink={'star'}/>
                    </Button>
                    : <Button
                        className={NAVIGATION_PANEL_DASHBOARD_ACTION_CLASS + (isMobile ? '' : ' navigation-panel__dashboard-close')}
                        tooltip={TOOLTIP_CLOSE_BOARD}
                        variant={'icon'}
                        onClick={onClose}
                        disabled={closeDisabled}
                        onMouseOver={() => setScrollTarget({scrollToEnd: true})}
                        onMouseOut={(e) => {setScrollTarget({scrollToEnd: false, relatedTarget: e.relatedTarget})}}
                    >
                        <Icon size={24} xlink={'close'}/>
                    </Button>
                }
            </div>
        </div>
    );
};
