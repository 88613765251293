import { ESortField, ISorting } from '../../list/types';
import { ICardsComparator } from './types';
import { priority } from './priority';
import { assignedTo } from './assignedTo';
import { startDate } from './startDate';
import { dueDate } from './dueDate';
import { processingPercent } from './processingPercent';
import { estimate } from './estimate';
import { title } from './title';
import { prefix } from './prefix';
import { age } from './age';
import { colorLabel } from './colorLabel';

export const CARDS_COMPARATORS: Record<ESortField, ICardsComparator> = {
    [ESortField.sortByPriority]: priority,
    [ESortField.sortByStartDate]: startDate,
    [ESortField.sortByDueDate]: dueDate,
    [ESortField.sortByProgress]: processingPercent,
    [ESortField.sortByAssignedUser]: assignedTo,
    [ESortField.sortByEstimate]: estimate,
    [ESortField.sortByTitle]: title,
    [ESortField.sortByPrefix]: prefix,
    [ESortField.sortByCardAge]: age,
    [ESortField.sortByColorLabel]: colorLabel,
}

export const getCardsComparator = (
    sorting: ISorting
): ICardsComparator => {
    return CARDS_COMPARATORS[sorting.sortField]
}
