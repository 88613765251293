import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { MagicLink } from '../../components/MagicLink/MagicLink';
import { IMagicLinkEvents, IMagicLinkFields } from '../../components/MagicLink/types';
import { IMagicLinkHOCProps } from './types';
import { getCommentById } from '../../../../../aside_panel/cardDetails/CommentsSection/selectors/getCommentById';
import { getDriveDoc } from '../../../../../../../store/model/driveDocs/selectors/getDriveDoc';
import { getList } from '../../../../../../../store/model/list/selectors/getList';
import { getCard } from '../../../../../../../store/model/selectors/getCard';
import { getBoard } from '../../../../../../../store/model/selectors/getBoardById';
import { onLoad } from './events/onLoad';
import { MAGIC_LINK_MAX_SYMBOLS, MAGIC_LINK_MAX_SYMBOLS_TRIMMED } from '../../constants';
import { getCardIdFromUrl } from '../../../../../../../helper/urlHelper/getCardIdFromUrl';
import { getListIdFromUrl } from '../../../../../../../helper/urlHelper/getListIdFromUrl';
import { getBoardIdFromUrl } from '../../../../../../../helper/urlHelper/getBoardIdFromUrl';
import { getCommentIdFromUrl } from '../../../../../../../helper/urlHelper/getCommentIdFromUrl';
import { getCardIdsFromUrl } from '../../../../../../../helper/urlHelper/getCardIdsFromUrl';

const mapStateToProps = (
    state: IApplicationState,
    { href, textLink }: IMagicLinkHOCProps
): IMagicLinkFields => {
    let icon = 'link';
    let label = textLink;
    let url = href;
    if (href.includes(window.location.host)) {
        url = window.location.host; // скоращаем длинну ссылки, чтобы сильно не скакало после загрузки
        const cardIds = getCardIdsFromUrl(href);
        if (cardIds && cardIds.length) {
            icon = 'card';
            label = '';
            for (let i = 0; i < cardIds.length; i++) {
                const card = getCard(state, cardIds[i]);
                if (card && card.name) {
                    label += card.name;
                    if (i !== cardIds.length - 1) {
                        label += ', ';
                    }
                }
            }
        } else {
            const commentId = getCommentIdFromUrl(href);
            if (commentId) {
                icon = 'comment';
                const cardId = getCardIdFromUrl(href);
                if (cardId) {
                    const comment = getCommentById(state, cardId, commentId);
                    if (comment) {
                        label = comment.text ||
                            comment.driveDocIds &&
                            comment.driveDocIds[0] &&
                            getDriveDoc(state, comment.driveDocIds[0]) &&
                            getDriveDoc(state, comment.driveDocIds[0]).title ||
                            textLink;
                    }
                }
            } else {
                const listId = getListIdFromUrl(href);
                if (listId) {
                    icon = 'list';
                    const list = getList(state, listId);
                    if (list && list.name) {
                        label = list.name;
                    }
                } else {
                    const cardId = getCardIdFromUrl(href);
                    if (cardId) {
                        icon = 'card';
                        const card = getCard(state, cardId);
                        if (card && card.name) {
                            label = card.name;
                        }
                    } else {
                        const boardId = getBoardIdFromUrl(href);
                        if (boardId) {
                            icon = 'board';
                            const board = getBoard(state, boardId);
                            if (board && board.name) {
                                label = board.name;
                            }
                        }
                    }
                }
            }
        }
    }
    if (label.length > MAGIC_LINK_MAX_SYMBOLS) {
        label = label.slice(0, MAGIC_LINK_MAX_SYMBOLS_TRIMMED) + '...';
    }
    return {
        url,
        icon,
        label,
    };
};

const mapDispatchToProps = (
    dispatch: any,
    { href }: IMagicLinkHOCProps
): IMagicLinkEvents => {
    return {
        onLoad: () => dispatch(onLoad(href)),
    }
};

export const MagicLinkHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(MagicLink);

MagicLinkHOC.displayName = 'MagicLinkHOC';
