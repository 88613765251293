import filterCalendars from './../helpers/filterCalendars';
import removeCardEventFromGoogleCalendar from './../effects/removeCardEventFromGoogleCalendar';
import pushCardEventToGoogleCalendar from './../effects/pushCardEventToGoogleCalendar';
import { SegmentCardEvent, segmentTrackAction } from "../../../../../../../middlewares/segment";
import * as _ from 'underscore';
import {getCard} from "app/store/model/selectors/getCard";
import {onShowSnackbar} from "app/view/react_components/aside_panel/cardDetails/TimingSection/pushToGoogleCalendar/events/onShowSnackbar";
import {TStatus} from "app/types/model";

const onElementClicked = (event, calendar) => {
    return (dispatch, getState) => {
        if (event.target.closest('a.js-gc-link')) {
            event.stopPropagation();
            return;
        }
        // todo move DataGetter to helper
        const selectedCardId = getState().pushToGoogleCalendar.cardId;
        const selectedActionType = getState().pushToGoogleCalendar.selectedAction;



        const filteredCalendars = filterCalendars(selectedCardId, selectedActionType);
        const pushedIds = _.pluck(filteredCalendars, 'calendarId');

        if (pushedIds.indexOf(calendar.id) > -1) {
            dispatch(segmentTrackAction(SegmentCardEvent.DELETE_FROM_GOOGLE_CALENDAR, {
                name: 'event',
                value: selectedActionType
            }));
            dispatch(removeCardEventFromGoogleCalendar(calendar));
        } else {
            dispatch(segmentTrackAction(SegmentCardEvent.PUSH_TO_GOOGLE_CALENDAR_PERFORMED, {
                name: 'event',
                value: selectedActionType
            }));
            dispatch(pushCardEventToGoogleCalendar(calendar));
            const isTemplate = getCard(getState(), selectedCardId).status === TStatus.STATUS_SERVICE_TEMPLATE;
            if(isTemplate) {
                dispatch(onShowSnackbar())
            }
        }

    };
};

export default onElementClicked;
