import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { TBoardId } from '../../../../../../../../../types/types';
import { removeAutoBackupsWarningMeta } from '../../../../../../../../../rest/effects/board/removeAutoBackupsWarningMeta';

export const onClose = (boardId: TBoardId): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        dispatch(removeAutoBackupsWarningMeta(boardId));
    };
    return action;
};
