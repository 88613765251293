import { Dispatch } from '../../../../../types/actions';
import { getMessages } from '../../../../../store/constants';
import { snackbarSuccessDefault } from '../variantSuccess/successDefault';
import { SNACKBAR_ID_ARCHIVE } from '../constants';

export const snackbarBoardArchived = () => {
    return (dispatch: Dispatch) => {
        dispatch(snackbarSuccessDefault({
            id: SNACKBAR_ID_ARCHIVE,
            text: getMessages().getText('snackbar.archive.board.text'),
        }));
    }
};
