import { getMessages } from '../../../../store/constants';

export const UPGRADE_BUTTON_SALE_HINT_VALUE = '50% off';
export const UPGRADE_BUTTON_SALE_TOOLTIP_VALUE = 'For the first year of any yearly plan';
export const UPGRADE_BUTTON_TEXT_FREE = 'Special offer';
export const UPGRADE_BUTTON_FOR_READ_ONLY_MODE = 'Read-only mode';
export const UPGRADE_BUTTON_TRIAL = ' left in trial';
export const UPGRADE_BUTTON_TRIAL_LAST_DAY = 'Last day of trial';
export const UPGRADE_BUTTON_TEXT = 'Purchase';
export const UPGRADE_BUTTON_TEXT_FOR_READ_ONLY_MODE = 'Upgrade';
export const UPGRADE_BUTTON_TEXT_REQUEST_LICENSE= getMessages().getText('common.request_license');
