import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState, TCardId } from '../../../../types/types';
import { cardSetPinnedSections } from './cardSetPinnedSections';
import { getCardPinnedSections } from '../../../../store/model/selectors/getCardPinnedSections';
import { IPinnedSections } from '../../../../store/model/card/types/ICardMeta';
import { getCard } from '../../../../store/model/selectors/getCard';

export const cardUpdatePinnedSection = (
    cardId: TCardId,
    pinnedSection: Partial<IPinnedSections>,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const cardPinnedSections = getCardPinnedSections(getCard(getState(), cardId));
        const pinnedSections: IPinnedSections = {
            ...cardPinnedSections,
            ...pinnedSection
        };

        return dispatch(cardSetPinnedSections(cardId, pinnedSections));
    };
    return action;
};
