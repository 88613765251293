import { IColor, TStatus } from '../../../../../../../../../types/model';
import { IApplicationState, TBoardId } from '../../../../../../../../../types/types';
import { createDeepEqualSelector } from '../../../../../../../helpers/memoizeHelper';
import { getBoardColors } from '../../../../../../../../../store/model/board/selectors/getBoardColors';
import { ICards } from '../../../../../../../../../store/model/cards/types';
import { getBoardCardsByStatuses } from '../../../../../../../../../store/model/selectors/getBoardCardsByStatuses';

const getBoardColorsWithCount = (
    boardColors: IColor[],
    boardCards: ICards
): IColor[] => {
    return boardColors.map(color => {
        let cardsCount = 0;
        for (let id in boardCards) { // перебрать карты доски
            const card = boardCards[id];
            const cardColors = card && card.colorIds || [];
            if (cardColors.includes(color.id)) {
                cardsCount++;
            }
        }
        return {
            ...color,
            cardsCount
        }
    });
}

export const getBoardColorsWithCardsCount: (
    state: IApplicationState,
    boardId: TBoardId,
) => IColor[] = createDeepEqualSelector(
    getBoardColors,
    (state: IApplicationState, boardId: TBoardId) => getBoardCardsByStatuses(state, boardId, [TStatus.STATUS_ACTIVE, TStatus.STATUS_SERVICE_TEMPLATE]),
    getBoardColorsWithCount,
);
