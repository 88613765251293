const category = 'List';

export const SegmentListEvent = {
    ADD_CARD: { category, event: 'Add card button clicked' },
    CREATE: { category, event: 'List created' },
    OPEN_DETAILS: { category, event: 'List details opened' },
    FAKE_OPEN_DETAILS: { category, event: 'List details opened accidentally' },
    RENAME: { category, event: 'List renamed' },
    EDIT_DESCRIPTION: { category, event: 'List description edited' },
    CARD_LIMIT: { category, event: 'Cards limit set in list details' },
    DELETE: { category, event: 'List deletion button clicked' },
    ACTIONS: { category, event: 'Action button clicked in list details' },
    MOVE_ON_BOARD: { category, event: 'List moved within current board' },
    MOVE_TO_ANOTHER_BOARD: { category, event: 'List moved to another board' },
    COPY: { category, event: 'List copied' },
    BACKGROUND_CHANGED: { category, event: 'List colour changed' },
    PRINT: { category, event: 'List print button clicked' },
    ALL_CARDS: { category, event: 'All cards in list selected' },
    SORTING_ON: { category, event: 'Cards sorting in list - on' },
    SORTING_OFF: { category, event: 'Cards sorting in list - off' },
    SORTING_DENIED: { category, event: 'Cards sorting in list (no success)' },
    PRIORITY_SORTING_ON: { category, event: 'Cards sorting by priority set in list details' },
    START_DATE_SORTING_ON: { category, event: 'Cards sorting by start date set in list details' },
    DUE_DATE_SORTING_ON: { category, event: 'Cards sorting by due date set in list details' },
    PROGRESS_SORTING_ON: { category, event: 'Cards sorting by progress set in list details' },
    ASSIGNED_USER_SORTING_ON: { category, event: 'Cards sorting by assigned users set in list details' },
    ESTIMATE_SORTING_ON: { category, event: 'Cards sorting by estimate set in list details' },
    TITLE_SORTING_ON: { category, event: 'Cards sorting by title set in list details' },
    PREFIX_SORTING_ON: { category, event: 'Cards sorting by prefix set in list details' },
    AGE_SORTING_ON: { category, event: 'Cards sorting by card`s age set in list details' },
    COLOR_LABEL_SORTING_ON: { category, event: 'Cards sorting by card`s color labels set in list details' },
    DESCENDING_SORTING_ON: { category, event: 'Descending cards sorting set in list details' },
    ASCENDING_SORTING_ON: { category, event: 'Ascending cards sorting set in list details' },
    CARD_CREATE_BY_ENTER: { category, event: 'CARD_CREATE_BY_ENTER' },
    CARD_CREATE_BY_CTRL_ENTER: { category, event: 'CARD_CREATE_BY_CTRL_ENTER' },
    CARD_CREATE_BY_ADD_BUTTON: { category, event: 'CARD_CREATE_BY_ADD_BUTTON' },
    LIST_CREATE_NEW_CARD_PLUS_CLICKED : { category, event: 'Create new card clicked' },
    DRAG_N_DROP_PERFORMED: { category, event: 'drag n drop performed' },
    LIST_DETAILS_CLOSE_CLICKED: {category, event: 'List details close clicked'},
    LIST_FOLDED: { category, event: 'list folded' },
    LIST_OTHERS_FOLDED: { category, event: 'all other lists folded' },
    LIST_UNFOLDED: { category, event: 'list unfolded' },
    KANBAN_ACTIONS: {category, event: 'Action button clicked on kanban'},
    LIST_DROPDOWN_BUTTON_CLICKED: { category, event: 'list dropdown button clicked' },
    DELETE_CLICKED_ON_ARCHIVE_SNACKBAR: { category, event: 'delete clicked on archive snackbar' },
    CREATE_CARD_FROM_TEMPLATE_CLICKED: { category, event: 'Create card from templates clicked' },
    CREATE_A_NEW_TEMPLATE_CLICKED: { category, event: 'Create a new template licked' },
    TEMPLATE_CARD_CHOSEN: { category, event: 'card in template dropdown chosen' },
    TEMPLATES_EDIT_CLICKED: { category, event: 'Edit card templates clicked' },
    CARD_AGING_CLICKED: {category, event: 'Card aging clicked'},
    CARD_AGING_INDICATOR_CLICKED: {category, event: 'Card aging indicator clicked'},
    CARD_AGING_OPACITY_CLICKED: {category, event: 'Card aging opacity clicked'},
    CARD_AGING_WITHOUT_ACTIVITY_CLICKED: {category, event: 'Card aging without activity clicked'},
    CARD_AGING_IN_LIST_CLICKED: {category, event: 'Card aging in list clicked'},
    GET_LINK: { category, event: 'Get list link chosen' },
    RENAME_BUTTON_CLICKED: { category, event: 'List rename button clicked' },
};

export enum SegmentListOptions {
    offsetX = 'offsetX',
    offsetY = 'offsetY',
    SOURCE = 'source',
    COLOR = 'color',
    TARGET = 'target'
}

export enum SegmentListSourceValue {
    HEADER = 'list header',
    DROPDOWN = 'list dropdown',
    DETAILS = 'list details'
}

export enum SegmentListOptionStatusValue {
    ON = 'on',
    OFF = 'off'
}
