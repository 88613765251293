'use strict';

import template from './template/notification-system--overdue.html';
import { getActiveBoardLngs } from '../../store/model/selectors/getActiveBoardLngs';
import { getAppState } from '../../store/configureStore';

App.Views.CardOverdueNotification = App.Views.SystemNotification.extend({

    initialize: function (options) {
        App.Views.SystemNotification.prototype.initialize.call(this, options);
        var lngs = getActiveBoardLngs(getAppState());
        this.template = App.MLTemplate.getTemplate('#notification-system--overdue', lngs, template);
    },

    getActions: function() {
        var actions = {
            path: true,
            action: Messages.getText('noty.card_overDue'),
            actionData: {
                icon: 'deadline',
                due: App.Util.formatShortDate(this.notificationModel.newValue),
                dueFull: App.Util.formatBackEndDate(this.notificationModel.newValue)
            },
            icon: 'datetime',
            system: 'overdue'
        };
        actions.actionData.title = actions.action + ' (' + actions.actionData.due + ')';
        return actions;
    },
    
    onClick: function(e, notification) {
        App.Views.SystemNotification.prototype.onClick.call(this, e, notification);
        var dashboardId = this.notificationModel.dashboardId;
        var cardId = this.notificationModel.card.id;
        App.controller.cleverOpenCard(dashboardId, cardId);
    }     
});
