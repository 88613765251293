import { IApplicationState, TCardId } from '../../../types/types';
import { ICard } from '../card/types';

export const getCard = (
    state: IApplicationState,
    cardId: TCardId
): ICard => {
    const card = state.model.cards[cardId];
    return card;
}
