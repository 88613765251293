import {ThunkAction} from '../../../../types/actions';
import {fetchHandler} from '../../../../util/fetchHandler';
import {Util} from '../../../../util/util';
import {REST_USER_STAT_ADMIN_CHECK_DUPLICATES} from '../../../constants';
import {getRestHeadersPost} from '../../../helpers/getRestHeadersHelper';

export const postRestCheckDuplicates = (
): ThunkAction => {
    const action = () => {
        return fetchHandler(Util.getApiUrl(REST_USER_STAT_ADMIN_CHECK_DUPLICATES), {
            ...getRestHeadersPost(),
            body: JSON.stringify({})
        })
    };
    return action;
};
