import { IGetState, TBoardId, TThemeId } from '../../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { boardSetTheme } from '../../../../../../../../../rest/effects/board/boardSetTheme';
import { getMessages, root } from '../../../../../../../../../store/constants';
import { getLibTheme } from '../../../../../../../../../store/model/libThemes/selectors/getLibTheme';
import { ILibTheme } from '../../../../../../../../../store/model/libThemes/types';
import { getBoardTheme } from '../../../../../../../../../store/model/board/selectors/getBoardTheme';
import { snackbarPromtYesNo } from '../../../../../../../snackbarsStack/effects/variantPromt/snackbarPromtYesNo';
import { IBoardTheme } from '../../../../../../../../../store/model/board/types';

export const boardSaveTheme = (
    boardId: TBoardId,
    themeId: TThemeId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const boardTheme: IBoardTheme = getBoardTheme(state, boardId);
        if (!boardTheme) {
            root.App.controller.trackEvent(getMessages().getText('ga.category.dashboard'),
                getMessages().getText('ga.action.library_theme_applied'));
        }
        const theme: ILibTheme = getLibTheme(state, themeId);
        if (boardTheme && boardTheme.userId) {
            dispatch(snackbarPromtYesNo({
                id: 'SNACKBAR_BOARD_THEME_REMOVE_CUSTOM',
                text: getMessages().getText('popup.dashboard.switching_to_librarty_theme'),
                title: '',
                actionApply: () => {
                    applyTheme(dispatch, boardId, theme);
                },
                actionCancel: () => {}
            }));
        } else {
            applyTheme(dispatch, boardId, theme);
        }
    };
    return action;
};

const applyTheme = (
    dispatch: Dispatch,
    boardId: TBoardId,
    theme: ILibTheme
) => {
    dispatch(boardSetTheme(boardId, theme))
}
