import { IApplicationState } from '../../../../../types/types';
import { getMyWorkBoardsUpdateLastSeen } from './getMyWorkBoardsUpdateLastSeen';
import {
    getUserBoardActivityTimes
} from '../../../../../store/model/boardUserActivityTimes/selectors/getUserBoardActivityTimes';

export const getMyWorkShowBoardsUpdates = (
    state: IApplicationState
): boolean => {
    let isShow = false;
    const lastSeen = getMyWorkBoardsUpdateLastSeen(state);
    const boards = getUserBoardActivityTimes(state);
    for (let boardId in boards) {
        if (boards[boardId].lastUnseenUpdateTime > lastSeen) {
            isShow = true;
            break;
        }
    }
    return isShow;
};
