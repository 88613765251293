import { IApplicationState } from '../../../../../../../../types/types';
import { connect } from 'react-redux';
import { IStatusListsTogglerHOCProps } from './types';
import { SectionToggler } from '../../../../../../base/components/SectionToggler/SectionToggler';
import { ISectionTogglerEvents, ISectionTogglerFields } from '../../../../../../base/components/SectionToggler/types';
import { getPanelReadonly } from '../../../../selectors/getPanelReadonly';

const mapStateToProps = (
    state: IApplicationState,
    { boardId, type, checked}: IStatusListsTogglerHOCProps
): ISectionTogglerFields => {
    const isDisabled = getPanelReadonly(state, boardId);
    return {
        checked,
        isDisabled
    };
};

const mapDispatchToProps = (
    dispatch: any,
    ownProps: IStatusListsTogglerHOCProps
): ISectionTogglerEvents => {
    return {
        onChange: () => ownProps.onChange()
    }
};

export const StatusListsTogglerHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(SectionToggler);

StatusListsTogglerHOC.displayName = 'StatusListsTogglerHOC';
