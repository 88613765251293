import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId, TPermissionId } from '../../../../../../../../types/types';
import { sortAssignees } from '../effects/sortAssignees';

export const onSort = (
    cardId: TCardId,
    permissionId: TPermissionId,
    index: number,
    permissionIds: TPermissionId[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const collection = [...permissionIds];
        const i = collection.indexOf(permissionId);
        if (i > -1) collection.splice(i, 1);
        collection.splice(index, 0, permissionId);

        return dispatch(sortAssignees(cardId, collection));
    };
    return action;
};
