import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState } from '../../../../types/types';
import { changeBackboneViewType } from './changeBackboneViewType';
import { viewTypeChangedSegmentEvent } from '../../main/navigationPanel/effects/segmentEvents';
import { CLASS_MAIN_PANEL, EViewTypes } from '../../../../const';
import { root } from '../../../../store/constants';
import { object } from 'underscore';
import { sendUserActivityStatEventByViewType } from '../../../../rest/effects/userStatAdminReport/helpers/sendUserActivityStatEventByViewType';

export const changeViewType = (
    viewType: EViewTypes
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(changeBackboneViewType(viewType));
        dispatch(viewTypeChangedSegmentEvent(viewType));

        const main = document.querySelector('.' + CLASS_MAIN_PANEL) as HTMLElement;
        if (main) main.focus();

        if(viewType === EViewTypes.GANTT_VIEW_TYPE) {
            root.App.statHelper.sendUserStat(object(
                [
                    App.Classes.StatHelper.GANTT_TOGGLE_CLICKED_COUNT,
                    App.Classes.StatHelper.GANTT_TOGGLE_CLICKED_FDATE,
                    App.Classes.StatHelper.GANTT_TOGGLE_CLICKED_LDATE
                ],
                [
                    '+1',
                    'ifnull'+root.App.Util.nowTrimTimestampGMT(),
                    root.App.Util.nowTrimTimestampGMT()
                ]
            ));
        }
        dispatch(sendUserActivityStatEventByViewType(viewType));
    };
    return action;
};
