import { IHintStatusSetAction } from './types';
import { EHintStatus } from '../../../constants';
import { AT_STATUS_SET } from './constants';

export const hintStatusSetAction = (
    status: EHintStatus
): IHintStatusSetAction => ({
        type: AT_STATUS_SET,
        status
    }
);
