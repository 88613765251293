import { SegmentBoardEvent, segmentTrackAction } from '../../../../../../../../../middlewares/segment';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../../types/types';

export const onClick = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentBoardEvent.CLICKED_IN_APP_INSTRUCTIONS))
    };
    return action;
};
