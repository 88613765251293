import { ERestCardAssigneeInfoType } from '../../../../../../types/rest/IRestCardAssigneeInfo';

export type TLastUsedFilter = EFilterButtonsType | ERestCardAssigneeInfoType;

export interface ITopBarState {
    boardNameStatus: EBoardNameStatus;
    lastUsedFilter: TLastUsedFilter,
    isShareHintShow?: boolean;
}

export enum EBoardNameStatus {
    syncing = 'syncing',
    synced = 'synced',
    editing = 'editing',
    idle = 'idle'
}

export enum EFilterButtonsType {
    DONE = 'done',
    OVERDUE = 'overdue',
    MY_TASKS = 'myTasks'
}
