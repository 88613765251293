import * as React from 'react';
import './_MyWorkTopBar.scss';
import { IMyWorkTopBarProps } from './types';
import { MyWorkTopBarSearchHOC } from '../../hocs/MyWorkTopBarSearchHOC/MyWorkTopBarSearchHOC';
import { BackButton } from '../../../../base/components/BackButton/BackButton';
import { MyWorkTopBarTitleHOC } from '../../hocs/MyWorkTopBarTitleHOC/MyWorkTopBarTitleHOC';
import { FilterBarHOC } from '../../../../base/components/FilterBar/hocs/FilterBarHOC/FilterBarHOC';

export function MyWorkTopBar({
    isShowFilterBar,
    onBackClick,
}: IMyWorkTopBarProps) {
    const className = 'top-bar-my-work';

    const [isSearch, setSearch] = React.useState(null);

    return (
        <div
            className={`
                ${className}
                ${isShowFilterBar && isSearch ? className + '--search' : ''}
            `}
        >
            <BackButton
                onClick={onBackClick}
                tooltipDirection={'down'}
            />
            <div className={className + '-left'}>
                <MyWorkTopBarTitleHOC />
                <MyWorkTopBarSearchHOC setSearch={setSearch} />
            </div>
            {isShowFilterBar && <FilterBarHOC />}
        </div>
    );
}
