import { BOARDS_ACTION_SET, IBoardsActionSetAction } from './types';
import { TBoardsAction } from '../boards/actions/types';

export const boardsActionSet = (
    boardsAction: TBoardsAction
): IBoardsActionSetAction => {
    return {
        type: BOARDS_ACTION_SET,
        boardsAction
    }
};
