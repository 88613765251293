import { ICardActionSetAction } from './types';
import { TCardId } from '../../../../types/types';
import { TCardAction } from '../../card/actions/types';
import { AT_CARD_ACTION_SET } from './constants';

export const cardActionSetAction = (
    cardId: TCardId,
    cardAction: TCardAction
): ICardActionSetAction => ({
    type: AT_CARD_ACTION_SET,
    cardId,
    cardAction,
});
