import { IHeader } from './header/types';
import { ITemplates } from './templates/types';
import { ICommon } from './common/types';
import { IRestSharedDriveAllowedAction } from '../rest/types';
import { IBoards } from './boards/types';
import { IDrives } from './drives/types';
import { TEmptyDummyId } from '../containers/TabBoards/types';
import { IApplicationState } from '../../../../../types/types';
import { IAuthUser } from 'app/store/model/authUser/types';
import { EFilePermissionRole } from '../../../../../helper/authorisation/microsoft/graph/api/getFilePermissions';

export enum TTabKey {
    allBoards = 'allBoards',
    recent = 'recent',
    sharedDrives = 'sharedDrives',
    starred = 'starred',
    archive = 'archive',
    templates = 'templates'
}

export type TTabName = string;
export type TGoogleDriveId = string;

export interface IOpenBoardsDialogState {
    common: ICommon;
    header: IHeader;
    boards: IBoards;
    drives: IDrives;
    templates: ITemplates;
}

export interface  IGoogleDriveAllowedAction extends
    IRestSharedDriveAllowedAction
{}

export interface IGoogleDrive {
    icon: string;
    id: TGoogleDriveId;
    name: string;
    allowedAction: IGoogleDriveAllowedAction;
}

export type TTabs = {
    [tabKey: string]: {
        allow?: keyof IAuthUser; // 'allowSharedDrive'| 'allowCreateBoard'
        support?: keyof IAuthUser; // 'supportBoardArchive' | 'supportSharedDrive' | 'supportStarred';
        allowCreateBoard?: boolean;
        excludeDrives?: TGoogleDriveId[];
        gaEvent?: string;
        isProFeature?: boolean;
        loadUrl?: string;
        name: string;
        scopes?: TTabScopesByPlarform;
        showPlanBlocker?: TEmptyDummyId; // if not allowed, show blocker or hide tab
        showEmptyDrives?: boolean;
        userActiveSave?: boolean;
        urlPreparator?: (state: IApplicationState, url: string) => string;
        onLoadListener?: (state: IApplicationState) => void;
    };
};

export type TTabScopesByPlarform = {
    [platformType: string]: ITabScopes;
};

export interface ITabScopes {
    success: string[]; // достаточно, если уже есть один из этих скоупов
    need: string[];
    emptyDummyId: TEmptyDummyId;
};

export interface IMSRestoreUsers {
    [email: string]: EFilePermissionRole;
}
