import { ITutorialProps } from './types';
import * as React from 'react';
import './_Tutorial.scss';
import { CLASS_TUTORIAL, TUTORIAL_HEIGHT, TUTORIAL_WIDTH, TUTORIALS_VIDEO_URL_MAP } from './constants';
import { Button, Icon } from 'kui';
import { GOOGLE_SPACING } from '../../../../../const';
import { root } from '../../../../../store/constants';

export function Tutorial({
    asidePanelWidth,
    tutorial,
    onClose,
    onFullscreen,
    onMute,
    onPlay
}: ITutorialProps) {
    const className = CLASS_TUTORIAL;

    const playerRef = React.useRef(null);
    const userCloseRef = React.useRef(null);
    const timerRef = React.useRef(null);
    const showRef = React.useRef(0);
    const fullScreenRef = React.useRef(null);
    const muteTimerRef = React.useRef(null);
    const isMutedRef = React.useRef(true);
    const isInitialPlayRef = React.useRef(true);

    const onLoadVideo = () => {
        if (playerRef.current) return;
        //@ts-ignore
        playerRef.current = new window.YT.Player('yt-player-tutorial', {
            videoId: TUTORIALS_VIDEO_URL_MAP[tutorial],
            height: TUTORIAL_HEIGHT,
            width: TUTORIAL_WIDTH,
            playerVars: {
                autoplay: 1,
                mute: 1,
            },
            events: {
                onStateChange: (e: any) => {
                    if (isInitialPlayRef.current) {
                        isInitialPlayRef.current = false;
                        return;
                    }
                    const state = e.target.getPlayerState();
                    if (state === 2) {
                        onPlay(tutorial, false)
                    } else if (state === 1) {
                        onPlay(tutorial, true)
                    }
                }
            }
        });
    };

    React.useEffect(() => {
        //@ts-ignore
        if (!window.YT) {
            if (document.querySelector('script[src="https://www.youtube.com/iframe_api"]')) return;
            const tag = document.createElement('script');
            tag.src = 'https://www.youtube.com/iframe_api';
            //@ts-ignore
            window.onYouTubeIframeAPIReady = onLoadVideo;
            const firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        } else {
            onLoadVideo();
        }
    }, []);

    React.useEffect(() => {
        timerRef.current = setInterval(() => {
            showRef.current += 1;
        }, 1000);

        return () => {
            clearTimeout(timerRef.current);
            if (userCloseRef.current) return;
            const currentTime = playerRef.current && playerRef.current.getCurrentTime() || 0;
            onClose(Math.round(currentTime), showRef.current, tutorial);
        }
    }, []);

    React.useEffect(() => {
        const onToggle = (e: Event) => {
            const target = e.target as HTMLElement;
            if (target.id !== 'yt-player-tutorial') return;
            fullScreenRef.current = !fullScreenRef.current;
            onFullscreen(tutorial, fullScreenRef.current);
        };
        document.addEventListener('fullscreenchange', onToggle);
        return () => document.removeEventListener('fullscreenchange', onToggle);
    }, []);

    React.useEffect(() => {
        if (muteTimerRef.current) clearInterval(muteTimerRef.current);
        muteTimerRef.current = setInterval(() => {
            if (!playerRef.current) return;
            const isMuted = playerRef.current.isMuted();
            if (isMutedRef.current !== isMuted) {
                isMutedRef.current = isMuted;
                onMute(tutorial, isMuted);
            }
        }, 500);
        return () => {
            if (muteTimerRef.current) clearInterval(muteTimerRef.current);
        }
    }, []);

    const isFit = root._innerWidth - asidePanelWidth > TUTORIAL_WIDTH + GOOGLE_SPACING * 4

    return (
        <div
            className={`${className} ${!isFit ? className + '--left' : ''}`}
            style={{ right: asidePanelWidth + GOOGLE_SPACING * 2 }}
        >
            <div id={'yt-player-tutorial'} />
            <Button
                variant={'icon'}
                onClick={() => {
                    const currentTime = playerRef.current && playerRef.current.getCurrentTime() || 0;
                    userCloseRef.current = true;
                    onClose(Math.round(currentTime), showRef.current, tutorial, true);
                }}
                className={className + '__close'}
            >
                <Icon xlink={'close'} size={24} />
            </Button>
        </div>
    );
}
