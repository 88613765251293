import { DEPENDENCIES_ACTION_SET, IDependenciesActionSetAction } from './types';
import { TDependenciesAction } from '../dependencies/dependencies/actions/types';

export const dependenciesActionSetAction = (
    dependenciesAction: TDependenciesAction
): IDependenciesActionSetAction => {
    return {
        type: DEPENDENCIES_ACTION_SET,
        dependenciesAction
    }
};
