import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { root } from 'app/store/constants';

export const onClose = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        root.App.controller.closePermissionsFAQModal();
    };
    return action;
};
