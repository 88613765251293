import {IAutoBackupState} from '../../autoBackupsBoard/types';
import {EBackupRepeatType} from '../../../../../../../../../types/rest/IRestBackup';
import * as moment from 'moment';

const initialState: IAutoBackupState = {
    enabled: false,
    boardId: null,
    id: null,
    isArchiveIncluded: true,
    autoBackupSchedule: {
        enabled: false,
        repeatEvery: 1,
        repeatType: EBackupRepeatType.DAY,
        startDate: moment().set('hours', 0).set('minutes', 0).valueOf()
    }
};

export const getAutoBackupInitial = () => ({...initialState});
