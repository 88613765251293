import { IGetState, TBoardId, TCustomPropertyId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { boardSetCardCustomProperties } from '../../../../../../../../rest/effects/board/boardSetCardCustomProperties';
import {
    IBoardCardCustomProperties,
    ICardCustomProperties
} from '../../../../../../../../store/customProperties/types';
import { escape } from 'underscore';
import { ICustomPropertiesElement } from '../../../components/CustomPropertiesElements/types';
import { getBoardCardsMap } from '../../../../../../../../store/model/selectors/getBoardCardsMap';
import { ICards } from '../../../../../../../../store/model/cards/types';
import { getCardCustomProperties } from '../../../../../../../../store/model/selectors/getCardCustomProperties';
import { cardsRestPatch } from '../../../../../../../../rest/effects/card/card/api/helper/cardsRestPatch';

export const saveProperties = (
    boardId: TBoardId,
    elements: ICustomPropertiesElement[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const propertiesToRemove: TCustomPropertyId[] = elements
            .filter(property => property.removed)
            .map(property => property.id)

        const cards: ICards = {};
        if (propertiesToRemove.length) {
            const boardCards = getBoardCardsMap(getState(), boardId, null);
            for (let cardId in boardCards) { // перебрать карты доски
                const customProperties = getCardCustomProperties(getState(), Number(cardId));
                const cardCustomPropertiesIds = Object.keys(customProperties);
                const needUpdate = !!cardCustomPropertiesIds.find(id => propertiesToRemove.includes(id)); // В карте есть удаленное свойство, надо обновить карту
                if (needUpdate) {
                    const customPropertiesNew: ICardCustomProperties = {};
                    for (let id in customProperties) {
                        if (!propertiesToRemove.includes(id)) {  // если в карте было свойство, добавляем мету карты без этого свойства
                            customPropertiesNew[id] = customProperties[id];
                        }
                    }
                    cards[cardId] = {
                        meta: {
                            customProperties: customPropertiesNew
                        }
                    };
                }
            }
        }

        return dispatch(cardsRestPatch(cards)) // патчим карты
            .then(() => {
                const boardCardCustomProperties: IBoardCardCustomProperties = {};
                elements.forEach(property => {
                    if (!property.removed) {
                        boardCardCustomProperties[property.id] = {
                            id: property.id,
                            name: escape(property.name),
                            options: property.options,
                            orderNumber: property.orderNumber,
                            type: property.type,
                        };
                    }
                });
                return dispatch(boardSetCardCustomProperties(boardId, boardCardCustomProperties));
            })
    };
    return action;
};
