import { IApplicationState, TCardId, TChecklistItemId, TPermissionId } from '../../../types/types';
import { getCardChecklists } from '../checklists/checklists/selectors/getCardChecklists';

export const isCardChecklistUserExist = (
    state: IApplicationState,
    cardId: TCardId,
    permissionId: TPermissionId,
    excludeCheckListItemsIds: TChecklistItemId[] = null
): boolean => {
    const checklists = getCardChecklists(state, cardId);
    if (!checklists) return false;

    for (let checklistId in checklists) {
        const checklist = checklists[checklistId];
        if (checklist.checkItems) for (let itemId in checklist.checkItems) {
            if (
                (!excludeCheckListItemsIds || !excludeCheckListItemsIds.includes(Number(itemId))) &&
                checklist.checkItems[itemId].assignees.find(user =>
                user.sharedUser.permissionId === permissionId)
            ) {
                return true;
            }
        }
    }

    return false;
}
