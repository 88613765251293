import { IGetState, TBoardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { exportTimeTrackerWithCheckPerm } from '../effects/exportTimeTrackerWithCheckPerm';
import { IExportListener } from '../../../../../../../../helper/export/types';
import { showSnoozeBlocker } from '../../../../../../base/effects/showSnoozeBlocker';
import { getAuthUser } from '../../../../../../../../store/model/authUser/selectors/getAuthUser';
import {
    getProFeaturesTrialActive
} from '../../../../../../../../store/model/authUser/selectors/getProFeaturesTrialActive';
import { authUserSetUsedProFeatures } from '../../../../../../../../rest/effects/authUser/authUserSetUsedProFeatures';

export const onExportTimeTracker = (
    boardId: TBoardId,
    listener: IExportListener
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const isAllow = getAuthUser(getState()).allowTimeTrackerExport;
        if (isAllow) {
            if (getProFeaturesTrialActive(getState())) {
                dispatch(authUserSetUsedProFeatures('allowTimeTrackerExport'));
            }
            dispatch(exportTimeTrackerWithCheckPerm(boardId, listener));
        } else {
            dispatch(showSnoozeBlocker({
                allow: 'allowTimeTrackerExport'
            }));
        }
    };
    return action;
};
