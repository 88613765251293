import * as deepFreeze from 'deep-freeze';
import { AT_BOARD_BACKUPS_ACTION_SET, TBackupsAction } from '../actions/types';
import { IBackups } from '../types';
import { boardBackupsReducer } from '../../boardBackups/reducers/boardBackupsReducer';

const initialState: IBackups = {};

const _backupsReducer = (
    state = initialState,
    action: TBackupsAction
): IBackups  => {
    switch (action.type) {
        case AT_BOARD_BACKUPS_ACTION_SET:{
            const {boardId, boardBackupsAction} = action;
            return {
                ...state,
                [boardId]: boardBackupsReducer(state[boardId], boardBackupsAction)
            }
        }
        default: {
            return state;
        }
    }
};

export const backupsReducer = (
    state = initialState,
    action: TBackupsAction
): IBackups  => {
    // @ts-ignore
    if(window.Settings.development) {
        // @ts-ignore
        return deepFreeze(_backupsReducer(state, action));
    } else {
        return _backupsReducer(state, action);
    }
};
