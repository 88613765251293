import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { snackbarSuccessDefault } from '../../../../../snackbarsStack';
import { SNACKBAR_BOARD_DELETE_ID } from '../constants';
import { getMessages } from 'app/store/constants';

export const deleteBoardSnackbarSuccess: ActionCreator<ThunkAction> = (
) => {
    const action = (dispatch: Dispatch) => {
        const text = getMessages().getText('open_boards.snackbar.delete.success.text');

        dispatch(snackbarSuccessDefault({
            id: SNACKBAR_BOARD_DELETE_ID,
            text,
        }));
    };
    return action;
};
