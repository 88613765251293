import { createRoot } from 'react-dom/client';
import {Provider} from "react-redux";
import React from "react";
import { ReportsInsightsHOCLazy } from "../react_components/reports/lazy";

export const insightsAsideView = App.Views.BaseView.extend({
    className: 'reports-filters__react-in-backbone',

    initialize: function (options) {
        this.root = createRoot(this.el);
        this.store = options.store;
        this.boardId = options.boardId;
    },

    render: function () {
        this.root.render(
            <Provider store={this.store}>
                <ReportsInsightsHOCLazy dashboardId={this.boardId}/>
            </Provider>
        );
        return this;
    },

    remove: function () {
        this.root.unmount();
        Backbone.View.prototype.remove.call(this);
    }
});
