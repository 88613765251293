import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { IAddToMyWorkButtonHOCProps } from './types';
import { IAsidePanelActionsButtonProps } from '../../../../asidePanel/components/AsidePanelActionsButton/types';
import {
    AsidePanelActionsButton
} from '../../../../asidePanel/components/AsidePanelActionsButton/AsidePanelActionsButton';
import { ADD_TO_MY_WORK_TOOLTIP_ADD, ADD_TO_MY_WORK_TOOLTIP_REMOVE } from './constants';
import { onClick } from './events/onClick';
import { getPanelButtonShow } from '../../../selectors/getPanelButtonShow';
import { CLASS_NAME_BUTTON_HIDE_TITLE } from '../../../ActionsInHeadSection/constants';
import { getIsCardInMyWork } from './selectors/getIsCardInMyWork';
import { onLoad } from './events/onLoad';

const mapStateToProps = (
    state: IApplicationState,
    { cardId, boardId }: IAddToMyWorkButtonHOCProps
): IAsidePanelActionsButtonProps => {
    const isShow = getPanelButtonShow(state, boardId, cardId);
    const isAddedToMyWork = getIsCardInMyWork(state, cardId);
    return {
        icon: 'card-assigned',
        isShow,
        tooltipText: isAddedToMyWork ? ADD_TO_MY_WORK_TOOLTIP_REMOVE : ADD_TO_MY_WORK_TOOLTIP_ADD,
        tooltipDirection: 'down',
        title: ADD_TO_MY_WORK_TOOLTIP_REMOVE,
        indicatorBoolean: isAddedToMyWork,
        className: CLASS_NAME_BUTTON_HIDE_TITLE
    }
};

const mapDispatchToProps = (
    dispatch: any,
    { cardId }: IAddToMyWorkButtonHOCProps
): IAsidePanelActionsButtonProps => {
    return {
        onClick: () => dispatch(onClick(cardId)),
        onDidMount: () => dispatch(onLoad(cardId))
    }
};
export const AddToMyWorkButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps,
)(AsidePanelActionsButton);

AddToMyWorkButtonHOC.displayName = 'AddToMyWorkButtonHOC';
