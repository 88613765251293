import { TPushNotificationSettingsAction } from '../../pushNotificationSettings/actions/types';

export const AT_PUSH_NOTIFICATION_SETTINGS_ACTION_SET = 'PUSH_NOTIFICATIONS_SETTINGS_PUSH_NOTIFICATION_ACTION_SET';

export interface IPushNotificationActionSetAction {
    type: typeof AT_PUSH_NOTIFICATION_SETTINGS_ACTION_SET,
    boardId: number,
    pushNotificationAction: TPushNotificationSettingsAction
}

export type TPushNotificationsSettingsAction = (IPushNotificationActionSetAction);
