import * as React from 'react';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import * as _ from 'underscore';
import { v4 as uuidv4 } from 'uuid';

export const advancedEqualLogger = (
    name: string,
    color: string = 'red'
) => (a: any, b: any) => {
    const t0 = performance.now();
    const equalResult = _.isEqual(a, b);
    const t1 = performance.now();
    if (!equalResult) {
        console.log(`%c Logger:${name}`, `color:${color}`, a, b);
        const diff = _.omit(b, (v: any, k: any, obj: any) => {
            return _.isEqual(a[k], v);
        });
        console.log(`%c diff изменилось`, `color:${color}`, diff);
    }
    const t2 = performance.now();
    equalResult ? console.groupCollapsed('timers') : console.group('timers');
    console.log(`%c equal: ${(t1 - t0).toFixed(3)}`, `color:${color}`);
    console.log(`%c diff: ${(t2 - t1).toFixed(3)}`, `color:${color}`);
    console.log(`%c total: ${(t2 - t0).toFixed(3)}`, `color:${color}`);
    console.groupEnd();
    return equalResult;
};

export const equalLogger = (a: any, b: any) => {
    const equalResult = _.isEqual(a, b);
    if (!equalResult) {
        console.log('%c equalLogger', 'color:red', a, b);
    }
    return equalResult;
};

export const equalMemo = _.isEqual;

export const createAdvancedEqualSelectorLogger = (name: string, color: string = 'red') => createSelectorCreator(
    defaultMemoize,
    advancedEqualLogger(name)
);

export const createDeepEqualSelectorLogged = createSelectorCreator(
    defaultMemoize,
    equalLogger
);

export const createDeepEqualSelector = createSelectorCreator(
    defaultMemoize,
    _.isEqual
);

/**
 * window используется, чтобы в консоли чистить analyze.clear()
 */
export class Analyze {
    color: string;
    key: string;
    name: string;

    constructor (name: string = '') {
        this.color = '#' + Math.floor(Math.random()*16777215).toString(16); // цвета могут получиться неожиданные, зато можно измерять много инстансов %)
        this.key = uuidv4();
        this.name = name;

        const w = window as any;
        if (!w.analyze) {
            w.analyze = {
                clear: this.clear,
            };
        }
        w.analyze[this.key] = {
            calls: 0,
            time: 0,
            timeTotal: 0,
        }
    };

    start () {
        const { analyze } = window as any;
        analyze[this.key].time = performance.now();
    }

    finish () {
        const w = window as any;
        const { analyze, Settings: { development } } = w;
        if (!development) return;

        const analyzeItem = analyze[this.key];
        analyzeItem.calls++;
        analyzeItem.time = performance.now() - analyzeItem.time;
        analyzeItem.timeTotal += analyzeItem.time;
        console.log(`%c  ${this.name} average: ${(analyzeItem.timeTotal / analyzeItem.calls).toFixed(3)} ms, calls: ${analyzeItem.calls}, total: ${analyzeItem.timeTotal.toFixed(3)} ms`, `color:${this.color}`);
    };

    clear () {
        const { analyze } = window as any;
        for (let key in analyze) {
            if (analyze[key].calls) {
                analyze[key].calls = 0;
                analyze[key].time = 0;
                analyze[key].timeTotal = 0;
            }
        }
    };
};

/**
 * Отслежавает, если компонент рендерится слишком часто
 * чтобы включить: в консоли analyzeRender.start()
 */
export class AnalyzeRender {
    name: string;

    constructor (name: string = '') {
        const w = window as any;
        const { Settings: { development } } = w;
        if (!development) return;

        this.name = name;

        if (!w.analyzeRender) {
            w.analyzeRender = {
                start: this.start,
                stop: this.stop,
            };
        }
    };

    call (
        id: any,
        prevState?: any,
        newState?: any,
        setState?: any,
    ) {
        id = this.name + ' ' + id; // unique id
        const w = window as any;
        const { Settings: { development } } = w;
        if (!development) return;

        React.useEffect(() => {
            if (!w.analyzeRender.isActive) return;

            if (!w.analyzeRender[id]) {
                w.analyzeRender[id] = {
                    calls: 0,
                    time: 0,
                }
            }

            if (w.analyzeRender[id].time) {
                const calls = w.analyzeRender[id].calls || 0;
                const delta = Math.round(performance.now() - w.analyzeRender[id].time);
                if (delta > 2000) {
                    if (calls > 5) {
                        console.log(`%c ERROR AnalyzeRender: ${id}, calls: ${calls} / ${delta}ms`, `background: red; color: white;`);
                        if (prevState && newState && setState) {
                            const propsString = JSON.stringify(newState);
                            const stateString = JSON.stringify(prevState);
                            let stringEqual = '';
                            let notEq = false;
                            for (let i=0; i<propsString.length; i++) {
                                if (propsString[i] === stateString[i]) {
                                    stringEqual += propsString[i];
                                } else {
                                    console.log('stringEqual: ', stringEqual.slice(-20));
                                    setState(newState);
                                    notEq = true;
                                    break;
                                }
                            }
                            if (!notEq) console.log('EQUAL', `new == prev`, prevState === newState);
                        }
                    } else {
                        // console.log(`%c OK AnalyzeRender: ${id}, calls: ${calls} / ${delta}ms`, `color:#aaa`);
                    }
                    w.analyzeRender[id].time = performance.now();
                    w.analyzeRender[id].calls = 0;
                } else {
                    w.analyzeRender[id].calls = calls + 1;
                }
            } else {
                w.analyzeRender[id].time = performance.now();
            }
        }); // каждое обновление
    };

    start () {
        const { analyzeRender } = window as any;
        analyzeRender.isActive = true;
    };

    stop () {
        const { analyzeRender } = window as any;
        analyzeRender.isActive = false;
    };
};
