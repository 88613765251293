import { IApplicationState } from '../../../../../../types/types';
import { connect } from 'react-redux';
import { IUpdatesTipsEvents, IUpdatesTipsFields } from '../../components/UpdatesTips/types';
import { UpdatesTips } from '../../components/UpdatesTips/UpdatesTips';
import { onClose } from './events/onClose';
import { IUpdatesTipsHOCProps } from './types';

const mapStateToProps = (
    state: IApplicationState,
    { isFirstShow, slides }: IUpdatesTipsHOCProps
): IUpdatesTipsFields => {

    return {
        isFirstShow,
        slides,
    }
};

const mapDispatchToProps = (
    dispatch: any
): IUpdatesTipsEvents => {
    return {
        onClose: () => dispatch(onClose()),
    }
};

export const UpdatesTipsHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(UpdatesTips);

UpdatesTipsHOC.displayName = 'UpdatesTipsHOC';
