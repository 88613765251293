import { IApplicationState } from '../../../../../../../../types/types';
import { getBoard } from '../../../../../../../../store/model/selectors/getBoardById';
import { connect } from 'react-redux';
import { ArchiveOnDeletePanelButton } from '../../components/ArchiveOnDeletePanelButton/ArchiveOnDeletePanelButton';
import { IArchiveOnDeletePanelButtonHOCProps } from './types';
import {
    IArchiveOnDeletePanelButtonEvents,
    IArchiveOnDeletePanelButtonFields
} from '../../components/ArchiveOnDeletePanelButton/types';
import { onClick } from './events/onClick';
import { getIsUserBasicPlanOnly } from '../../../../../../../../store/model/authUser/selectors/getIsUserBasicPlanOnly';

const mapStateToProps = (
    state: IApplicationState,
    { boardId }: IArchiveOnDeletePanelButtonHOCProps
): IArchiveOnDeletePanelButtonFields => {
    const { archiveCardsOnDelete: indicatorBoolean } = getBoard(state, boardId);
    return {
        indicatorBoolean,
        isBasicFeature: getIsUserBasicPlanOnly(state),
        tooltip:  null,
    }
};

const mapDispatchToProps = (
    dispatch: any,
    {onClick: ownOnClick}: IArchiveOnDeletePanelButtonHOCProps
): IArchiveOnDeletePanelButtonEvents => {
    return {
        onClick: () => dispatch(onClick(ownOnClick))
    }
};

export const ArchiveOnDeletePanelButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(ArchiveOnDeletePanelButton);

ArchiveOnDeletePanelButtonHOC.displayName = 'ArchiveOnDeletePanelButtonHOC';
