import * as React from 'react';
import { ITypeSwitcherItemProps } from './types';
import { SelectListItem, Tooltip } from 'kui';
import './_TypeSwitcherItem.scss';
import { CLASS_TYPE_SWITCHER, VIEW_TYPES } from '../../constants';
import { ProFeatureIndicator } from '../../../base/components/ProFeatureIndicator/ProFeatureIndicator';
import { BasicFeatureIndicator } from '../../../base/components/BasicFeatureIndicator/BasicFeatureIndicator';

export const TypeSwitcherItem: React.FC<ITypeSwitcherItemProps> =
React.forwardRef(({
    type,
    className,
    isActive,
    isBasicFeature,
    isProFeature,
    onTypeSelected
}, ref) => {
    const classItem = `${CLASS_TYPE_SWITCHER}__item`;
    return (
        <div
            className={`
                ${classItem}
                ${isActive ? classItem + '--active' : ''}
            `}
            ref={ref as any}
            tabIndex={0}
            onClick={() => onTypeSelected(type)}
        >
            <Tooltip
                className={`${CLASS_TYPE_SWITCHER}__tooltip`}
                direction={'down'}
                value={VIEW_TYPES[type].name}
            >
                <SelectListItem
                    className={className || ''}
                    icon={VIEW_TYPES[type].icon}
                    iconSize={24}
                >
                    <>
                        {VIEW_TYPES[type].name}
                        {isProFeature && <ProFeatureIndicator />}
                        {isBasicFeature && <BasicFeatureIndicator />}
                    </>
                </SelectListItem>
            </Tooltip>
            {isProFeature && <ProFeatureIndicator />}
            {isBasicFeature && <BasicFeatureIndicator />}
            {isActive &&
                <div className={`${CLASS_TYPE_SWITCHER}__active`} />
            }
        </div>
    )
})
