import { IApplicationState } from '../../../../../types/types';
import { connect } from 'react-redux';
import { getActiveHint } from '../../../hints/store/hints/selectors/getActiveHint';
import { ITutorialHOCOwnProps } from './types';
import { Tutorial } from '../../components/Tutorial/Tutorial';
import { ITutorialStateProps, ITutorialDispatchProps } from '../../components/Tutorial/types';
import { onTutorialNext } from '../HintHOC/events/onTutorialNext';

const mapStateToProps = (
    state: IApplicationState,
    ownProps: ITutorialHOCOwnProps
): ITutorialStateProps => {
    const { hintKey } = ownProps;
    let isShow = true;
    let tutorialStep = 0;
    const activeHint = getActiveHint(state);
    if (
        activeHint !== null &&
        activeHint.key === hintKey
    ) {
        tutorialStep = activeHint.step;
        if (tutorialStep < 0) isShow = false;
    }
    return {
        isShow,
        tutorialStep
    }
};

const mapDispatchToProps = (
    dispatch: any,
    ownProps: ITutorialHOCOwnProps
): ITutorialDispatchProps => {
    const { hintKey } = ownProps;

    return {
        onTutorialNext: () => dispatch(onTutorialNext(hintKey)),
    }
};

export const TutorialHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(Tutorial);

TutorialHOC.displayName = 'TutorialHOC';
