import * as React from 'react';
import { useState } from 'react';
import { Button, Input, Tooltip } from 'kui';
import { OldIcon } from '../../../../../react-utils';
import './TimeTracker.scss';
import { ITimeTrackerProps } from './types';
import { TimeTrackerHelper } from '../../../dialogs/timeTrackerDialog/helpers/TimeTrackerHelper';
import { TIME_LABEL, TIME_START, TIME_STOP } from '../../constants';
import { getShortName } from '../../../helpers/getShortName';
import { NAVIGATION_PANEL_CLOSE_MARKER } from 'app/view/react_components/main/navigationPanel/constants';
import ReactHtmlParser from 'react-html-parser';
import { FAKE_CARD_ID_FOR_WIDGET_PRESENTATION } from 'app/const';

export function TimeTracker({
    boardName,
    cardName,
    cardId,
    isEditCard,
    isEditTrackingCard,
    start,
    workHoursInDay,
    onClickButton,
    onCardNameClick,
    onChange,
    onDidMount
}: ITimeTrackerProps) {
    let timer = React.useRef(null);
    let editing = React.useRef(null);

    let [invalidInput, setInputValidateState] = useState(false); //todo error
    let [inputValue, setInputValue] = useState('');

    const isTracking = !!cardName || isEditTrackingCard;

    const className = 'time-tracker';
    const classNameTracking = isTracking ? className + '--tracking' : '';
    const classNameIsEditCard = isEditCard ? className + '--incard' : '';

    function handleFocus() {
        editing.current = true;
    }

    function handleBlur(e: React.SyntheticEvent) {
        onChange(inputValue);
        editing.current = false;
    }

    function handleChange(e: React.SyntheticEvent) {
        const {value} = e.target as HTMLInputElement;
        setInputValue(value);
    }

    function updateInputValue() {
        if (timer.current) clearTimeout(timer.current);
        if (start) {
            timer.current = setTimeout(updateInputValue, 1000);
        }
        if (editing.current) return;
        if (!start) {
            setInputValue('');
            return;
        }

        if (cardName && !workHoursInDay) return; // доска ещё загружается, или карта с другой доски (догрузится в onDidMount)

        const delta = TimeTrackerHelper.calcTotalSeconds(start);
        let timerString = TimeTrackerHelper.formatSecondsToString(delta, workHoursInDay, !isEditCard);
        setInputValue(timerString);
    }

    React.useEffect(() => {
        updateInputValue();

        return () => {
            if (timer.current) clearTimeout(timer.current);
        };
    }, [start, workHoursInDay]);

    const [boardNameMarkdown, setBoardNameMarkdown] = React.useState('');
    const [cardNameMarkdown, setCardNameMarkdown] = React.useState('');
    React.useEffect(() => {
        if (boardName) setBoardNameMarkdown(getShortName(boardName));
        if (cardName) setCardNameMarkdown(getShortName(cardName));
    }, [boardName, cardName]);

    React.useEffect(() => {
        if (onDidMount) onDidMount();
    }, []);

    return (
        <div
            className={`${className} ${classNameTracking} ${classNameIsEditCard}
                ${!boardName ? className + '--noboard' : ''}
                ${cardId === FAKE_CARD_ID_FOR_WIDGET_PRESENTATION ? 'fake' : ''}
            `}
        >
            <Button
                className={'time-tracker__button'}
                tooltip={isTracking ? TIME_STOP : TIME_START}
                variant={'icon'}
                onClick={onClickButton}
            >
                <OldIcon xlink={isTracking ? 'kui-stop' : 'kui-play'}/>
            </Button>
            {!isEditCard && !!cardNameMarkdown &&
                <Button
                    className={`time-tracker__card ${NAVIGATION_PANEL_CLOSE_MARKER}`}
                    variant={'icon'}
                    onClick={onCardNameClick}
                >
                    <Tooltip
                        value={cardNameMarkdown}
                    >
                        <div className={'time-tracker__card-name'}>
                            {ReactHtmlParser(cardNameMarkdown)}
                        </div>
                    </Tooltip>
                    {!!boardNameMarkdown &&
                        <Tooltip
                            value={boardNameMarkdown}
                        >
                            <div className={'time-tracker__board-name'}>
                                {ReactHtmlParser(boardNameMarkdown)}
                            </div>
                        </Tooltip>
                    }
                </Button>
            }
            <Input
                autosize={false}
                className={'time-tracker__input'}
                placeholder={'00:00:00'}
                value={inputValue}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                label={TIME_LABEL}
                state={invalidInput ? 'error' : null}
            />
        </div>
    );
};
