import * as React from 'react';
import { ISharedDrivesScopeBlockerProps } from './types';
import './_SharedDrivesScopeBlocker.scss';
import { SHARED_DRIVES_SCOPES_BLOCKER_COPIED, SHARED_DRIVES_SCOPES_BLOCKER_COPY, SHARED_DRIVES_SCOPES_BLOCKER_LINK, getSharedDrivesScopesBlockerText } from './constants';
import { Button, Icon } from 'kui';
import { BUTTON_TEXT_GIVE_PERMISSIONS, TEXT_PERMISSIONS_GRANTED } from '../../constants';
import ReactHtmlParser from 'react-html-parser';

export function SharedDrivesScopeBlocker ({
    isPermission,
    onClick
}: ISharedDrivesScopeBlockerProps) {
    const className = 'shared-drives-scope-blocker';

    const [isCopied, setCopied] = React.useState(false);

    const onCopyClick = (e: React.SyntheticEvent) => {
        if (navigator.clipboard) {
            e.preventDefault();
            navigator.clipboard.writeText(window.location.origin + SHARED_DRIVES_SCOPES_BLOCKER_LINK)
                .then(() => {
                    setCopied(true);
                });
        }
    }

    return (
        <div className={className}>
            <div className={`${className}__text`}>
                {getSharedDrivesScopesBlockerText()}
                {' '}
                <a
                    className={`${className}__copy ${isCopied ? 'copied' : ''}`}
                    href={SHARED_DRIVES_SCOPES_BLOCKER_LINK}
                    target={'_blank'}
                    onClick={onCopyClick}
                >
                    {ReactHtmlParser(isCopied ? SHARED_DRIVES_SCOPES_BLOCKER_COPIED : SHARED_DRIVES_SCOPES_BLOCKER_COPY)}
                </a>
            </div>
            <div className={`${className}__buttons`}>
                {isPermission
                    ? <div className={`${className}__granted`}>
                        <Icon size={24} xlink={'done'} /> {TEXT_PERMISSIONS_GRANTED}
                    </div>
                    : <Button
                        size={'large'}
                        onClick={onClick}
                    >
                        {BUTTON_TEXT_GIVE_PERMISSIONS}
                    </Button>
                }
            </div>
        </div>
    )
};
