import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { ICardRecurringFormHOCProps } from './types';
import { CardRecurringForm } from '../../components/CardRecurringForm/CardRecurringForm';
import { ICardRecurringFormEvents, ICardRecurringFormFields } from '../../components/CardRecurringForm/types';
import {
    getListSelectSearchSelectOptions
} from '../../../ListSelect/hocs/ListSelectHOC/selectors/getListSelectSearchSelectOptions';
import { getBoardIdByCardId } from '../../../../../../../store/model/selectors/getBoardIdByCardId';
import { onAdd } from './events/onAdd';
import { onUpdate } from './events/onUpdate';
import { getRecurringTaskFormCreateSelector } from '../../selectors/getRecurringTaskForm';
import { getCardRecurringTasks } from '../../../../../../../store/model/selectors/getCardRecurringTasks';
import { ERecurringTaskStatus } from '../../../../../../../types/rest/recurringTask/IRestRecurringTask';
import { onFaqClick } from './events/onFaqClick';
import { onCancel } from './events/onCancel';
import { getSectionType } from '../../../selectors/getSectionType';
import {
    getBoardMetaDefaultStartDateTime
} from '../../../../../../../store/model/selectors/getBoardMetaDefaultStartDateTime';

const mapStateToProps = () => {
    const getRecurringTaskForm = getRecurringTaskFormCreateSelector();
    const _mapStateToProps = (
        state: IApplicationState,
        { cardId, sectionType: sectionTypeOwn }: ICardRecurringFormHOCProps
    ): ICardRecurringFormFields => {
        const boardId = getBoardIdByCardId(state, cardId);
        const lists = getListSelectSearchSelectOptions(state, boardId);
        const recurringTask = getCardRecurringTasks(state, cardId)[0];
        const sectionType = getSectionType(state, boardId, cardId, sectionTypeOwn);
        let recurringTaskForm = {};
        let status = ERecurringTaskStatus.NOT_PROCESSED;
        if (recurringTask) {
            recurringTaskForm = getRecurringTaskForm(state, {cardId, taskId: recurringTask.id})
            status = recurringTask.status
        }
        const startTime = getBoardMetaDefaultStartDateTime(state, boardId);
        return  {
            lists,
            recurringTaskForm,
            status,
            sectionType,
            cardId,
            startHours: startTime.getHours(),
            startMinutes: startTime.getMinutes()
        };
    };
    return _mapStateToProps;
}

const mapDispatchToProps = (
    dispatch: any,
    { onCancel: onCancelOwn, cardId, boardId }: ICardRecurringFormHOCProps
): ICardRecurringFormEvents => {
    return {
        onAdd: (form) => dispatch(onAdd(form, cardId, boardId)),
        onUpdate: (form) => dispatch(onUpdate(form, cardId)),
        onCancel: () => dispatch(onCancel(onCancelOwn)),
        onFaqClick: () => dispatch(onFaqClick())
    }
};

export const CardRecurringFormHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardRecurringForm);

CardRecurringFormHOC.displayName = 'CardRecurringFormHOC';
