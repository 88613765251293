import { connect } from 'react-redux';
import { ICardChecklistActionsHOCProps } from './types';
import { IApplicationState } from '../../../../../../../types/types';
import { ICardChecklistActionsEvents, ICardChecklistActionsFields } from '../../components/CardChecklistActions/types';
import { getCardChecklistIsPinned } from '../../../../../../../store/model/selectors/getCardChecklistIsPinned';
import {
    getAuthUserIsHideDoneChecklistItems
} from '../../../../../../../store/model/authUser/selectors/getAuthUserIsHideDoneChecklistItems';
import { CardChecklistActions } from '../../components/CardChecklistActions/CardChecklistActions';
import { onPinToggle } from './events/onPinToggle';
import { onHideDoneChange } from './events/onHideDoneChange';
import { onAllItemsDone } from './events/onAllItemsDone';
import { getChecklistItems } from '../../../../../../../store/model/checklists/checklist/selectors/getChecklistItems';
import { getBoardIdByCardId } from '../../../../../../../store/model/selectors/getBoardIdByCardId';
import { isNumber } from 'underscore';
import {
    getAuthUserShowChecklistItemsAuthor
} from '../../../../../../../store/model/authUser/selectors/getAuthUserShowChecklistItemsAuthor';
import { onAuthorToggle } from './events/onAuthorToggle';
import { getBoardUsers } from '../../../../../../../store/model/selectors/getBoardUsers';
import { onRename } from './events/onRename';
import { onCopy } from './events/onCopy';

const mapStateToProps = (
    state: IApplicationState,
    { cardId, checklistId, copyCardId, isEmptyChecklists, isMultiple }: ICardChecklistActionsHOCProps
): ICardChecklistActionsFields => {
    const boardId = getBoardIdByCardId(state, cardId);
    const checklistItems = isNumber(checklistId) && getChecklistItems(state, cardId, checklistId);
    const isItems = checklistItems && !!Object.values(checklistItems).length;
    return {
        checklistId,
        copyCardId,
        isEmptyChecklists,
        isItems,
        isMultiple,
        isPinned: checklistId && getCardChecklistIsPinned(state, cardId, checklistId),
        isShowDone: getAuthUserIsHideDoneChecklistItems(state),
        isShowAuthor: getAuthUserShowChecklistItemsAuthor(state),
        isMultipleUsers: getBoardUsers(state, boardId).length > 1, // имеет показывать смысл, когда больше одного юзера на доске
    };
};

const mapDispatchToProps = (
    dispatch: any,
    { cardId, checklistId, onRename: onRenameOwn, onCopy: onCopyOwn, onDelete }: ICardChecklistActionsHOCProps
): ICardChecklistActionsEvents => {
    return {
        onDelete: () => onDelete(),
        onCopy: (cardId) => dispatch(onCopy(cardId, onCopyOwn)),
        onRename: () => dispatch(onRename(onRenameOwn)),
        onPin: () => dispatch(onPinToggle(cardId, checklistId)),
        onHide: () => dispatch(onHideDoneChange()),
        onDone: () => dispatch(onAllItemsDone(cardId, checklistId)),
        onAuthorToggle: () => dispatch(onAuthorToggle())
    }
};

export const CardChecklistActionsHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardChecklistActions);

CardChecklistActionsHOC.displayName = 'CardChecklistActionsHOC';
