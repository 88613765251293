import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { root } from '../../../../../store/constants';
import { getEditListId } from '../../../../../store/router/selectors/getEditListId';
import { SegmentListEvent, SegmentListOptions, segmentTrackAction } from '../../../../../middlewares/segment';
import { IGetState, TListId } from '../../../../../types/types';
import { SegmentListSourceValue } from '../../../../../middlewares/segment/trackEntities/listEvents';
import { ISegmentEventOption } from '../../../../../middlewares/segment/trackEntities/types';

export const editList = (
    listId: TListId,
    allowToOpen: boolean,
    source?: SegmentListSourceValue
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (!root.App.controller.isRelatedPanelNotOpened()) return;
        const editListId = getEditListId(getState());
        if (allowToOpen && editListId !== listId) {
            const option: ISegmentEventOption = source ? {
                name: SegmentListOptions.SOURCE,
                value: source
            } : null;
            dispatch(segmentTrackAction(SegmentListEvent.OPEN_DETAILS, option));
            requestAnimationFrame(() => { // был баг в listActions: открывается панель, потом закрывается дропдаун и забирает фокус - панель закрывается
                root.App.router.navigate(root.App.router.getUrl('l-' + listId), {trigger: true});
            });
        } else {
            root.App.router.navigate(root.App.router.getUrl(null), {trigger: true});
        }
    };
    return action;
};
