import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId, TTag } from '../../../../../../../../types/types';
import { cardSetTags } from '../../../../../../../../rest/effects/card/card/cardSetTags';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { getCardTagsToUpdate } from '../../../../../../../../store/model/selectors/getCardTagsToUpdate';

export const renameTag = (
    cardId: TCardId,
    tag: TTag,
    name: TTag
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const tags = getCardTagsToUpdate(getState(), cardId, tag, name);
        if (tags) {
            dispatch(segmentTrackAction(SegmentCardEvent.ONE_TAG_RENAMED));
            dispatch(cardSetTags(cardId, tags));
        }
    };
    return action;
};
