import { marked } from 'marked';
import * as DOMPurify from 'dompurify';
import { root } from '../../store/constants';
import { v4 as uuidv4 } from 'uuid';
import { clearMarkdown } from '../../view/react_components/base/helpers/clearMarkdownHelper';
import { renderToStaticMarkup } from 'react-dom/server';
import { Icon } from 'kui';
import React from 'react';
import { getAppState } from '../../store/configureStore';
import {
    getCommentById
} from '../../view/react_components/aside_panel/cardDetails/CommentsSection/selectors/getCommentById';
import { getDriveDoc } from '../../store/model/driveDocs/selectors/getDriveDoc';
import { getList } from '../../store/model/list/selectors/getList';
import { getCard } from '../../store/model/selectors/getCard';
import { getBoard } from '../../store/model/selectors/getBoardById';
import {
    MAGIC_LINK_MAX_SYMBOLS,
    MAGIC_LINK_MAX_SYMBOLS_TRIMMED
} from '../../view/react_components/base/components/MagicLink/constants';
import { CLASS_MAGIC_LINK } from '../../view/react_components/base/components/MagicLink/components/MagicLink/constants';
import { getCardIdFromUrl } from '../urlHelper/getCardIdFromUrl';
import { getListIdFromUrl } from '../urlHelper/getListIdFromUrl';
import { getBoardIdFromUrl } from '../urlHelper/getBoardIdFromUrl';
import { getCommentIdFromUrl } from '../urlHelper/getCommentIdFromUrl';
import { getCardIdsFromUrl } from '../urlHelper/getCardIdsFromUrl';
import _, { isNumber } from 'underscore';
import { validateEmail } from '../../view/react_components/helpers/emailHelper';

export enum EMarkdownType {
    BLOCK = 'BLOCK',
    INLINE = 'INLINE',
};

export function markdown (
    text: string,
    type: EMarkdownType = EMarkdownType.INLINE,
): string {
    if (!text) return null;
    const renderer = new marked.Renderer();
    const isBlock = type === EMarkdownType.BLOCK;

    marked.setOptions({
        gfm: true,
        breaks: true,
        // sanitize: true,
        renderer,
        // pedantic: true,
        // smartLists: true,
        // smartypants: true,
    });

    // обрабатываем меншены
    renderer.text = (textMd) => textMd.replace(/(^|\s)@([\S]*[^\s.,]+)/gmi, domElementMention);

    // переопределяем ссылки
    renderer.link = (href, title, textLink) => {
        const isKanbanchiLink = href.includes(window.location.host);
        let isMarkdown = false;
        const isEmail = href.includes('mailto:');
        const fixedHref = href.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
        let regex = new RegExp(`.{0,1}${fixedHref}.{0,1}`);
        const match = text.match(regex);
        const result = match && match[0];
        if (!isEmail && result && !isKanbanchiLink) {
            const firstChar = result[0];
            const lastChar = result[result.length - 1];
            isMarkdown = ['*', '~', '`'].includes(firstChar) && firstChar === lastChar;
            if (!isMarkdown) {
                regex = new RegExp(`.{0,3}${fixedHref}.{0,3}`);
                const match = text.match(regex);
                const result = match && match[0];
                if (result) {
                    const firstChar = result[0];
                    const secondChar = result[1];
                    const thirdChar = result[2];
                    isMarkdown = ['#', '*', '>'].includes(secondChar) && thirdChar === ' ' ||
                        ['#', '*', '>'].includes(firstChar) && secondChar === ' ' ||
                        _.isNumber(parseInt(firstChar)) && secondChar === '.' && thirdChar === ' ' ||
                        href !== textLink;
                }
            }
        }

        if (isMarkdown || isEmail || !isKanbanchiLink) return `<a href="${href}" target="_blank" title="${title || ''}">${textLink}</a>`;

        let icon = 'link';
        let name = textLink;
        let isNeedLoad = false;
        if (href.includes(window.location.host)) {
            const state = getAppState();
            const cardIds = getCardIdsFromUrl(href);
            if (cardIds && cardIds.length) {
                icon = 'card';
                name = '';
                for (let i = 0; i < cardIds.length; i++) {
                    const card = getCard(state, cardIds[i]);
                    if (card && card.name) {
                        name += card.name;
                        if (i !== cardIds.length - 1) {
                            name += ', ';
                        }
                    } else {
                        isNeedLoad = true;
                        name = textLink;
                        break
                    }
                }
            } else {
                const commentId = getCommentIdFromUrl(href);
                if (commentId) {
                    icon = 'comment';
                    const cardId = getCardIdFromUrl(href);
                    if (cardId) {
                        const comment = getCommentById(state, cardId, commentId);
                        if (comment) {
                            name = comment.text ||
                                comment.driveDocIds &&
                                comment.driveDocIds[0] &&
                                getDriveDoc(state, comment.driveDocIds[0]) &&
                                getDriveDoc(state, comment.driveDocIds[0]).title ||
                                textLink;
                        } else {
                            isNeedLoad = true;
                        }
                    }
                } else {
                    const listId = getListIdFromUrl(href);
                    if (listId) {
                        icon = 'list';
                        const list = getList(state, listId);
                        if (list && list.name) {
                            name = list.name;
                        } else {
                            isNeedLoad = true;
                        }
                    } else {
                        const cardId = getCardIdFromUrl(href);
                        if (cardId) {
                            icon = 'card';
                            const card = getCard(state, cardId);
                            if (card && card.name) {
                                name = card.name;
                            } else {
                                isNeedLoad = true;
                            }
                        } else {
                            const boardId = getBoardIdFromUrl(href);
                            if (boardId) {
                                icon = 'board';
                                const board = getBoard(state, boardId);
                                if (board && board.name) {
                                    name = board.name;
                                }
                            } else {
                                isNeedLoad = true;
                            }
                        }
                    }
                }
            }
        }
        if (root.App.controller.magicLinksFakeLinksHelper.getIsFakeLink(href)) {
            isNeedLoad = false;
        }
        if (isNeedLoad) {
            const className = 'magic-link__wrapper';
            const classUnique = `${className}--${uuidv4()}`;
            setTimeout(() => { // ждем рендера
                root.App.controller.renderMagicLink({href, title, textLink}, `.${classUnique}`);
            }, 100);
            return `<a class="${className} ${classUnique}"href="${href}" target="_blank" title="${title || ''}"></a>`;
        } else {
            if (name.length > MAGIC_LINK_MAX_SYMBOLS) {
                name = name.slice(0, MAGIC_LINK_MAX_SYMBOLS_TRIMMED) + '...';
            }
            return `<a class="${CLASS_MAGIC_LINK}" href="${href}" target="_blank" title="${title || ''}">
                ${renderToStaticMarkup(<Icon xlink={icon} size={16} />)}
                <span class="${CLASS_MAGIC_LINK}__label">${escape(clearMarkdown(name))}</span>
            </a>`;
        }
    };

    // quill не понимает del
    renderer.del = (textMd) => `<s>${textMd}</s>`;
    // quill не добавляет в цитату p, а маркдаун добавляет - убрать
    renderer.blockquote = (textMd) => `<blockquote>${textMd.replace('<p>', '').replace('</p>', '')}</blockquote>`;

    const textEscape = escape(text);
    const textMark = isBlock ? marked.parse(textEscape) : marked.parseInline(textEscape);
    const textPurify = DOMPurify.sanitize(textMark, {
        ADD_ATTR: ['target', 'title'],
        RETURN_DOM: true,
    });
    return textPurify.innerHTML;
}

/**
 * html тэги заменить на &lt; &gt;
 * > не трогать, это blockuote в маркдауне
 */
function escape (htmlStr: string) {
    let isTag = false;
    let str = '';
    for (let i=0; i<htmlStr.length; i++) {
        if (htmlStr[i] === '<') {
            isTag = true;
            str += '&lt;';
        } else if (isTag && htmlStr[i] === '>') {
            isTag = false;
            str += '&gt;';
        } else {
            str += htmlStr[i];
        }
    }
    return str;
}

function domElementMention(
    match: string,
    a: string,
    mention: string
) {
    const nameMention = a + mention.replace('.', ' ');
    return `<span class="markdown_mention">${nameMention}</span>`;
    // return `<span class="markdown_mention">${a}@${mention}</span>`;
}
