import { Button, ButtonDropdown, Icon, ITooltipInheritedProps, SelectList, SelectListItem } from 'kui';
import * as React from 'react';
import { ICommentActionsProps } from './types';
import './_CommentActions.scss';
import { CLASS_COMMENT } from '../../constants';
import {
    COMMENT_ACTION_DELETE,
    COMMENT_ACTION_EDIT,
    COMMENT_ACTION_LINK,
    COMMENT_ACTION_REPLY,
    COMMENT_ACTIONS_TOOLTIP
} from './constants';

export function CommentActions({
    isMyComment,
    onDelete,
    onEdit,
    onGetLink,
    onReply
}: ICommentActionsProps) {
    const className = CLASS_COMMENT + '-actions';

    return (
        <ButtonDropdown
            className={className}
            directionHorizontal={'right'}
        >
            <Button
                className={className + '__button'}
                variant={'icon'}
                tooltip={{
                    isNoEvents: true,
                    isNoWrap: true,
                    value: COMMENT_ACTIONS_TOOLTIP,
                } as ITooltipInheritedProps}
            >
                <Icon xlink={'more'} size={24} />
            </Button>
            <SelectList fixActive={false}>
                {isMyComment &&
                    <SelectListItem
                        icon={'pen'}
                        onClick={onEdit}
                        iconSize={24}
                    >
                        {COMMENT_ACTION_EDIT}
                    </SelectListItem>
                }
                <SelectListItem
                    icon={'reply'}
                    onClick={onReply}
                    iconSize={24}
                >
                    {COMMENT_ACTION_REPLY}
                </SelectListItem>
                <SelectListItem
                    icon={'link'}
                    onClick={onGetLink}
                    iconSize={24}
                >
                    {COMMENT_ACTION_LINK}
                </SelectListItem>
                {isMyComment &&
                    <SelectListItem
                        icon={'delete'}
                        onClick={onDelete}
                        iconSize={24}
                    >
                        {COMMENT_ACTION_DELETE}
                    </SelectListItem>
                }
            </SelectList>
        </ButtonDropdown>
    );
}
