import { root } from '../../store/constants';
import { fetchHandler } from '../../util/fetchHandler';
import { IBaseResult, IExportListener, ISaveCardResult, IStartResult } from './types';
import { getDateFormatted } from '../../view/react_components/helpers/dateService';
import { getUserTimeFormat12 } from '../../store/model/authUser/selectors/getUserTimeFormat12';
import { IBoard } from '../../store/model/board/types';
import { IApplicationState, TBoardId } from '../../types/types';
import { getRestHeadersPost } from '../../rest/helpers/getRestHeadersHelper';
import { boardExportItemAdd } from '../../view/react_components/aside_panel/boardDetails/export/store/actions/boardExportItemAdd';
import { Dispatch } from '../../types/actions';

type TParams = {
    [key: string]: any
}

export const startExport = (
    state: IApplicationState,
    board: IBoard,
    listener: IExportListener,
    urlPrefix: string,
    fileNamePrefix: string = '',
    params: TParams = {}
): Promise<IStartResult> => {
    listener.onStart();
    const date = getDateFormatted(Math.floor(new Date().getTime() / 1000), getUserTimeFormat12(state));
    const fileName = encodeURIComponent(board.name + ' ' + fileNamePrefix + ' (' + date + ')');
    const url = root.App.Util.getApiUrl(
        urlPrefix + board.id
            + '?rand=' + Math.random()
            + '&fileName=' + fileName
            + getParamsStr(params)
        );
    return fetchHandler<IStartResult>(url, {})
        .then((result) => {
            if (!checkResponseError(result, listener.onError)) return;
            result.fileName = fileName;
            return result;
        })
}

export const continueExport = (
    startResult: IStartResult,
    listener: IExportListener,
    urlPrefix: string,
    body: string,
    params: TParams = {}
): Promise<ISaveCardResult> => {
    const url = root.App.Util.getApiUrl(urlPrefix
        + '?fileId=' + encodeURI(startResult.fileId)
        + '&rand=' + Math.random()
        + getParamsStr(params));
    return fetchHandler<ISaveCardResult>(
        url,
        {
            ...getRestHeadersPost(),
            body
        }
    ).then(result => {
        if (!checkResponseError(result, listener.onError)) return;
        return result;
    });
}

export const finishExport = (
    startResult: IStartResult,
    prefixUrl: string,
    listener: IExportListener,
    boardId: TBoardId,
    dispatch: Dispatch
) => {
    return fetchHandler<ISaveCardResult>(
        root.App.Util.getApiUrl(prefixUrl),
        {
            ...getRestHeadersPost(),
            body: JSON.stringify({
                fileName: decodeURI(startResult.fileName),
                docUrl: startResult.docUrl
            })
        }
    ).then(result => {
        listener.onProgress(100);
        listener.onSuccess();
        dispatch(boardExportItemAdd(boardId, {
            name: decodeURIComponent(startResult.fileName),
            url: startResult.docUrl
        }));
        return result;
    })
}

const getParamsStr = (
    params: TParams
): string => {
    const paramsStr = Object.keys(params).map(key => `${key}=${params[key].toString()}`).join('&');
    return paramsStr ? '&' + paramsStr : '';
}

const checkResponseError = (
    result: IBaseResult,
    onError: (errorMessage: string) => void
): boolean => {
    if (!result.success) {
        if (onError) {
            onError(result.errorMessage)
        }
        return false;
    }
    return true;
}
