import { Store } from 'redux';
import { IApplicationState, TCardId } from '../../../../types/types';
import { AT_FILTER_PANEL_ACTION_SET } from '../../../../view/react_components/aside_panel/filterPanelBoard/store/filterPanelBoards/actions/constants';
import { AT_TYPE_SWITCHER_BOARD_TYPE_SET } from '../../../../view/react_components/typeSwitcher/store/actions/constants';
import { AT_SAVED_FILTER_ACTION_SET } from '../../../../view/react_components/aside_panel/filterPanelBoard/store/savedFilters/actions/constants';
import { getActiveBoardId } from '../../selectors/getActiveBoardId';
import { ICard } from '../../card/types';
import { getTypeSwitcherActiveType } from '../../../../view/react_components/typeSwitcher/store/selectors/getTypeSwitcherActiveType';
import { EViewTypes } from '../../../../const';
import { getEnabledFilters } from './selectors/getEnabledFilters';
import { matchCardIdsSetAction } from '../../../../view/react_components/aside_panel/filterPanelBoard/store/filterMatch/actions/matchCardIdsSetAction';
import {
    BOARDS_ACTION_SET,
    CARDS_ACTION_SET,
    ICardsActionSetAction,
    LISTS_ACTION_SET,
    MODEL_SET
} from '../../actions/types';
import {
    isCardMatchFilters,
    isListMatchFilters
} from '../../../../view/aside_panel/dashboard_filter/dashboard_filter_helper_card_filters';
import { matchListsSetAction } from '../../../../view/react_components/aside_panel/filterPanelBoard/store/filterMatch/actions/matchListsSetAction';
import { TFilterMatchLists } from '../../../../view/react_components/aside_panel/filterPanelBoard/store/filterMatch/types';
import { getBoardCardsForFilter } from './selectors/getBoardCardsForFilter';
import { AT_CARDS_ADD, AT_CARDS_DELETE, AT_CARDS_UPDATE } from '../../cards/actions/constants';
import { getCard } from '../../selectors/getCard';
import { getViewTypeCardFilter } from './selectors/getViewTypeCardFilter';
import { matchCardIdAddAction } from '../../../../view/react_components/aside_panel/filterPanelBoard/store/filterMatch/actions/matchCardIdAddAction';
import { matchCardIdRemoveAction } from '../../../../view/react_components/aside_panel/filterPanelBoard/store/filterMatch/actions/matchCardIdRemoveAction';
import { ICards } from '../../cards/types';
import { IFilter } from '../../../../view/react_components/aside_panel/filterPanelBoard/store/filter/types';
import { AT_GANTT_VIEW_ACTION_SET } from '../../../../view/react_components/gantt/store/ganttView/actions/constants';
import { IGanttViewActionSetAction } from '../../../../view/react_components/gantt/store/ganttView/actions/types';
import {
    AT_DATES_FILTER_SET,
    AT_HIDE_EMPTY_DATES_SET
} from '../../../../view/react_components/gantt/store/ganttViewBoard/actions/constants';
import { getFilterIgnoreCardIds } from '../../../../view/react_components/aside_panel/filterPanelBoard/store/selectors/getFilterIgnoreCardIds';
import { getFilterMatchLists } from '../../../../view/react_components/aside_panel/filterPanelBoard/store/filterMatch/selectors/getFilterMatchLists';
import { filterIgnoreCardIdsClear } from '../../../../view/react_components/aside_panel/filterPanelBoard/store/filterMatch';
import { TFilterMiddlewareAction, TFilterMiddlewareCardsListIds } from './types';
import { getMatchListsDefault } from './selectors/getMatchListsDefault';
import { NO_PRIORITY } from '../../../../view/react_components/helpers/constants';
import { getIsAssignedToMeActive } from '../../../router/selectors/getIsAssignedToMeActive';
import { getFilterMatchCardIds } from 'app/view/react_components/aside_panel/filterPanelBoard/store/filterMatch/selectors/getFilterMatchCardIds';
import { getList } from '../../list/selectors/getList';

const fillListsByViewType = (
    card: ICard,
    matchLists: TFilterMatchLists,
    viewType: EViewTypes
) => {
    getCardListIdsByViewType(card, viewType).forEach(listId => {
        matchLists[listId] = matchLists[listId] || [];
        matchLists[listId].push(card.id)
    })
}

const filterAndDispatchMatchLists = (
    store: Store<IApplicationState>,
    matchLists: TFilterMatchLists,
    filters: IFilter[]
) => {
    const filtered: TFilterMatchLists = {};
    for (let id in matchLists) {
        if (isListMatchFilters(getList(store.getState(), Number(id)), filters, matchLists[id].length)) {
            filtered[id] = matchLists[id];
        }
    }
    store.dispatch(matchListsSetAction(filtered));
}

// тут любое действие, которое должно привести к сбросу игнорируемых карт
const isActionToResetIgnoreIds = (
    action: TFilterMiddlewareAction
): boolean => {
    if (action.type === AT_GANTT_VIEW_ACTION_SET) {
        const ganttAction = action as IGanttViewActionSetAction;
        return (
            ganttAction.ganttViewAction.type === AT_HIDE_EMPTY_DATES_SET ||
            ganttAction.ganttViewAction.type === AT_DATES_FILTER_SET
        )
    }
    return (
        action.type === AT_FILTER_PANEL_ACTION_SET ||
        action.type === AT_SAVED_FILTER_ACTION_SET ||
        action.type === AT_TYPE_SWITCHER_BOARD_TYPE_SET
    )
}

const isCardsFilterRelevantAction = (
    action: TFilterMiddlewareAction
): boolean => {
    return (
        isActionToResetIgnoreIds(action) ||
        action.type === LISTS_ACTION_SET ||
        action.type === BOARDS_ACTION_SET ||
        action.type === MODEL_SET
    )
}

const cardsFilter = (
    store: Store<IApplicationState>,
    next: any,
    action: TFilterMiddlewareAction
) => {
    const oldBoardId = getActiveBoardId(store.getState());
    const result = next(action);
    const boardId = getActiveBoardId(store.getState());

    if (
        oldBoardId !== boardId ||
        isCardsFilterRelevantAction(action)
    ) {
        if (getFilterIgnoreCardIds(store.getState()).length && // dispatches optimization
            isActionToResetIgnoreIds(action)
        ) {
            store.dispatch(filterIgnoreCardIdsClear());
        }

        const state = store.getState();
        const filters = getEnabledFilters(state, boardId);
        const viewType = getTypeSwitcherActiveType(state, boardId);

        const allCards = getBoardCardsForFilter(store.getState(), boardId);
        const matchCardIds: TCardId[] = [];

        const matchLists: TFilterMatchLists = getMatchListsDefault(state, boardId);
        allCards.forEach(card => {
            if (
                isCardMatchFilters(state, card, filters, getFilterIgnoreCardIds(state))
            ) {
                matchCardIds.push(card.id);
                fillListsByViewType(card, matchLists, viewType);
            }
        })
        store.dispatch(matchCardIdsSetAction(matchCardIds));
        filterAndDispatchMatchLists(store, matchLists, filters);
    }
    return result
}

const getCardListIdsByViewType = (
    card: ICard,
    viewType: EViewTypes
): string[] => {
    if (!card) return [];
    switch (viewType) {
        case EViewTypes.KANBAN_VIEW_TYPE:
        case EViewTypes.ARCHIVE_VIEW_TYPE:
        case EViewTypes.SWIM_COLOR_TAGS_VIEW_TYPE:
        case EViewTypes.SWIM_TAGS_VIEW_TYPE: {
            return [card.listId.toString()];
        }
        case EViewTypes.USERS_VIEW_TYPE: {
            return (card.assignees || []).map(user => user.sharedUser.permissionId)
        }
        case EViewTypes.COLOR_TAGS_VIEW_TYPE: {
            return (card.colorIds || []).map(colorId => colorId.toString())
        }
        case EViewTypes.CARD_COLORS_VIEW_TYPE: {
            return [card.backgroundColor ? card.backgroundColor.slice(1) : ''];
        }
        case EViewTypes.TAGS_VIEW_TYPE: {
            return [ ...(card.tags || []) ]
        }
        case EViewTypes.PRIORITIES_VIEW_TYPE: {
            return [card.priority || NO_PRIORITY]
        }
    }
    return [];
}

const getCardsListIds = (
    state: IApplicationState,
    cards: ICards,
    viewType: EViewTypes
): TFilterMiddlewareCardsListIds => {
    const result: TFilterMiddlewareCardsListIds = {};
    for (let cardId in cards) {
        result[cardId] = getCardListIdsByViewType(getCard(state, Number(cardId)), viewType)
    }
    return result;
}

// обновляет matchLists, удаляет cardId из листов, перечисленных в removeListIds и добавляет к addListIds
const updateMatchLists = (
    cardId: TCardId,
    removeFromLists: string[],
    addToLists: string[],
    matchLists: TFilterMatchLists
) => {
    if (!cardId) return;
    removeFromLists.forEach(removeListId => {
        if (
            !addToLists.includes(removeListId)
            && matchLists[removeListId]
            && matchLists[removeListId].includes(cardId)
        ) {
            matchLists[removeListId].splice(matchLists[removeListId].indexOf(cardId), 1);
        }
    })
    addToLists.forEach(addListId => {
        matchLists[addListId] = matchLists[addListId] || [];
        if (!matchLists[addListId].includes(cardId)) {
            matchLists[addListId].push(cardId)
        }
    })
}

const cardFilter = (
    store: Store<IApplicationState>,
    next: any,
    action: ICardsActionSetAction
) => {
    switch (action.cardsAction.type) {
        case AT_CARDS_ADD:
        case AT_CARDS_DELETE:
        case AT_CARDS_UPDATE: {
            const { cards } = action.cardsAction;
            let state = store.getState();
            const boardId = getActiveBoardId(state);

            if (!boardId) {
                return next(action);
            }

            const viewType =  getTypeSwitcherActiveType(state, boardId);
            const oldCardsListIds = getCardsListIds(state, cards, viewType);
            const result = next(action);

            state = store.getState();

            const filters = getEnabledFilters(state, boardId);

            const viewTypeFilter = getViewTypeCardFilter(state, boardId);
            const matchLists: TFilterMatchLists = {}; // объект, ключ - это id листа, значение - массив карт, которые в листе
            const stateMatchLists = getFilterMatchLists(state);
            for (let id in stateMatchLists) {
                matchLists[id] = [...stateMatchLists[id]];
            }
            for (let cardIdString in cards) {
                const cardId = Number(cardIdString);
                const card = getCard(state, cardId);
                const oldListIds = oldCardsListIds[cardId];

                if (
                    card &&
                    viewTypeFilter(card) &&
                    isCardMatchFilters(state, card, filters, getFilterIgnoreCardIds(state))
                ) {
                    if (!getFilterMatchCardIds(state).includes(card.id)) { // dispatches optimization
                        store.dispatch(matchCardIdAddAction(card.id));
                    }
                    const newListIds = getCardListIdsByViewType(card, viewType);
                    updateMatchLists(card.id, oldListIds, newListIds, matchLists); // убираем текущую карту из oldListIds и добавляем ее в newListIds
                } else {
                    store.dispatch(matchCardIdRemoveAction(cardId));
                    updateMatchLists(cardId, oldListIds, [], matchLists);  // убираем текущую карту из oldListIds
                }
            }
            filterAndDispatchMatchLists(store, matchLists, filters);
            return result;
        }
    }
    return next(action);
}

export const filterMiddleware = (store: Store<IApplicationState>) => (next: any) => (action: TFilterMiddlewareAction) => {
    if (getIsAssignedToMeActive(store.getState())) return next(action);
    if (action.type === CARDS_ACTION_SET) return cardFilter(store, next, action as ICardsActionSetAction);
    return cardsFilter(store, next, action);
}
