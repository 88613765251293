import * as React from 'react';
import { ITimeZoneOptionProps } from './types';
import { v4 as uuidv4 } from 'uuid';

export function TimeZoneOption ({
    text,
    value,
}: ITimeZoneOptionProps) {
    return (
        <li
            key={uuidv4()} // тут есть айтемы с одинаковым value
            value={value}
        >
            {text}
        </li>
    );
};
