import { AT_ROUTE_SET } from './constants';
import { IRouteSetAction } from './types';
import { ERoutes } from '../constants';

export const routeSetAction = (
    route: ERoutes,
): IRouteSetAction => ({
    type: AT_ROUTE_SET,
    route,
});
