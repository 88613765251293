import { ICardDescriptionUnsavedState } from '../types';
import { ICardDescriptionAction } from '../actions/types';
import { AT_DELETE, AT_SET } from '../actions/constants';
import { setCardDescriptionUnsavedLocalStorage } from '../localStorage/actions/setCardDescriptionUnsavedLocalStorage';
import { getInitialCardDescriptionUnsaved } from '../constants';

export const initialState = getInitialCardDescriptionUnsaved();

const _cardDescriptionUnsavedReducer = (
    state: ICardDescriptionUnsavedState = initialState,
    action: ICardDescriptionAction
): ICardDescriptionUnsavedState => {
    switch (action.type) {
        case AT_SET : {
            return {
                ...state,
                [action.cardId]: action.description
            };
        }
        case AT_DELETE : {
            const newState = {...state};
            const {cardId} = action;
            delete newState[cardId];
            return {...newState};
        }
        default: {
            return state;
        }
    }
};

export const cardDescriptionUnsavedReducer = (
    state: ICardDescriptionUnsavedState = initialState,
    action: ICardDescriptionAction
): ICardDescriptionUnsavedState => {
   const newState = _cardDescriptionUnsavedReducer(state, action);
   if (newState !== state) {
        setCardDescriptionUnsavedLocalStorage(newState);
   }
   return newState;
};
