import { IDependency } from '../../../../../../store/model/dependencies/dependency/types';
import { IRestPredecessor } from '../../../../../../types/rest/IRestPredecessor';
import { TStatus } from '../../../../../../types/model';

export const dependencyToRestPredecessor = (
    dependency: IDependency
) => {
    const restPredecessor: IRestPredecessor = {
        type: dependency.type,
        status: TStatus.STATUS_ACTIVE,
        cardId: dependency.successorId,
        predecessorId: dependency.predecessorId
    }
    return restPredecessor;
}
