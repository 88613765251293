import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { SegmentBoardEvent, segmentTrackAction } from '../../../../../../../../../middlewares/segment';

export const onClickInstructions = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        dispatch(segmentTrackAction(SegmentBoardEvent.CLICKED_BOARD_EMAIL_INSTRUCTION));
    };
    return action;
};
