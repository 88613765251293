import { IApplicationState, IGetState, TCardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { postRest } from './api/postRest';
import { dependencySetAction } from '../../../../store/model/dependencies/dependencies/actions/dependencySetAction';
import { EDependencyTypes, IDependency } from '../../../../store/model/dependencies/dependency/types';
import { dependenciesActionSetAction } from '../../../../store/model/actions/dependenciesActionSetAction';
import { dependencyDeleteAction } from '../../../../store/model/dependencies/dependencies/actions/dependencyDeleteAction';
import { getCard } from '../../../../store/model/selectors/getCard';
import { ICards } from '../../../../store/model/cards/types';
import { cardsRestPatch } from '../card/api/helper/cardsRestPatch';
import { dispatch } from '../../../../store/configureStore';
import { getBoardIdByCardId } from '../../../../store/model/selectors/getBoardIdByCardId';
import {
    sendRealTimeStoreActionByBoardId,
    sendRealTimeStoreActionByCardId
} from '../../../../view/react_components/base/helpers/realTimeHelperTS';

export const addPredecessor = (
    cardId: TCardId,
    predecessorCardId: TCardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const tmpDependency: IDependency = {
            id: Date.now(),
            successorId: cardId,
            type: EDependencyTypes.FINISH_TO_START,
            predecessorId: predecessorCardId
        }
        dispatch(dependenciesActionSetAction(dependencySetAction(tmpDependency.id, tmpDependency))); // optimistic set

        return dispatch(postRest(tmpDependency))
            .then((dependency: IDependency) => {
                const actionSet = dependenciesActionSetAction(dependencySetAction(dependency.id, dependency));
                dispatch(actionSet);
                checkCardGanttVisibility(getState(), cardId);
                checkCardGanttVisibility(getState(), predecessorCardId);
                const boardId = getBoardIdByCardId(getState(), tmpDependency.successorId);
                dispatch(sendRealTimeStoreActionByBoardId(boardId, actionSet))
            })
            .finally(() => {
                dispatch(dependenciesActionSetAction(dependencyDeleteAction(tmpDependency.id)));
            });
    };
    return action;
};

const checkCardGanttVisibility = (state: IApplicationState, cardId: TCardId) => {
    const card = getCard(state, cardId);
    if (!card) {
        return Promise.reject();
    }
    if (card.ganttVisibility) {
        return Promise.resolve();
    }
    const cards: ICards = {
        [cardId]: {
            ganttVisibility: true,
        }
    };
    return dispatch(cardsRestPatch(cards));
}
