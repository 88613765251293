import { IApplicationState, TBoardId } from '../../../../types/types';
import { getBoard } from '../../selectors/getBoardById';

export const getBoardWorkListMove = (
    state: IApplicationState,
    boardId: TBoardId
): boolean => {
    const board = getBoard(state, boardId);
    const meta = board && board.meta;
    return board && board.statusLists && board.statusLists.inWorkId && meta && meta.isWorkListMove;
};
