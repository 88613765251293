import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { snackbarPromtStartCancel } from '../../../../../snackbarsStack';
import { SNACKBAR_BOARD_MOVE_ID, SNACKBAR_MOVE_PROMT_TEXT, SNACKBAR_MOVE_PROMT_TITLE } from '../constants';
import { TGoogleDriveId } from '../../../store/types';
import { TBoardId } from '../../../../../../../types/types';
import { MoveBoard } from '../../../effects/moveBoard';

export const moveSnackbarPromt: ActionCreator<ThunkAction> = (
    boardId: TBoardId,
    driveId: TGoogleDriveId
) => {
    const moveBoard = new MoveBoard();
    const action = (dispatch: Dispatch) => {
        return new Promise((resolve) => {
            dispatch(snackbarPromtStartCancel({
                id: SNACKBAR_BOARD_MOVE_ID,
                text: SNACKBAR_MOVE_PROMT_TEXT,
                title: SNACKBAR_MOVE_PROMT_TITLE,
                actionApply: () => resolve(dispatch(moveBoard.start(boardId, driveId)))
            }));
        });
    };
    return action;
};
