import { IList } from './../../../../../../../../store/model/list/types';
import { IApplicationState, TBoardId } from '../../../../../../../../types/types';
import { IListSelectSearchSelectOptions } from '../types';
import { createDeepEqualSelector } from '../../../../../../helpers/memoizeHelper';
import { getBoardListsByStatus } from '../../../../../../../../store/model/selectors/getBoardListsByStatus';
import { stripHtml } from '../../../../../../base/helpers/stripHtmlHelper';
import { markdown } from '../../../../../../../../helper/markdownHelper';

const getListSelectSearchSelectOptionsSelector = (
    lists: IList[],
): IListSelectSearchSelectOptions[] => {
    const options = [...lists]
        .sort((aList, bList) => aList.orderNumber - bList.orderNumber )
        .map((list) => {
            return {
                text: stripHtml(markdown(list.name)),
                value: String(list.id)
            };
        });

    return options;
};

export const getListSelectSearchSelectOptions: (
    state: IApplicationState,
    boardId: TBoardId,
) => IListSelectSearchSelectOptions[] = createDeepEqualSelector(
    getBoardListsByStatus,
    getListSelectSearchSelectOptionsSelector,
);
