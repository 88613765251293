import { Dispatch, ThunkAction } from '../../../../../../types/actions';
import { segmentTrackAction, SegmentUserEvent } from '../../../../../../middlewares/segment';
import {
    SegmentUserOption,
    SegmentUserPlayerValue
} from '../../../../../../middlewares/segment/trackEntities/userEvents';
import { ETutorial } from '../../../../../../types/rest/IUserMeta';
import { TUTORIALS_NAME_MAP } from '../constants';

export const onFullscreen = (
    tutorial: ETutorial,
    isFullscreen: boolean
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(segmentTrackAction(SegmentUserEvent.TUTORIAL_FULLSCREEN, {
            name: SegmentUserOption.TYPE,
            value: isFullscreen ? SegmentUserPlayerValue.FULLSCREEN_ON : SegmentUserPlayerValue.FULLSCREEN_OFF
        }, [
            { name: SegmentUserOption.STATUS, value: TUTORIALS_NAME_MAP[tutorial] }
        ]));
    }
    return action;
}
