import { connect } from 'react-redux';
import { IPushNotificationsSectionHOCProps } from './types';
import { onDidMount } from './events/onDidMount';
import { PushNotificationsSection } from '../../components/PushNotificationsSection/PushNotificationsSection';
import { IPushNotificationsSectionEvents } from '../../components/PushNotificationsSection/types';

const mapDispatchToProps = (
    dispatch: any,
    { boardId }: IPushNotificationsSectionHOCProps
): IPushNotificationsSectionEvents => {
    return {
        onDidMount: () => dispatch(onDidMount(boardId))
    }
};

export const PushNotificationsSectionHOC = connect(
    null,
    mapDispatchToProps
)(PushNotificationsSection);

PushNotificationsSectionHOC.displayName = 'PushNotificationsSectionHOC';
