import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { TBoardId } from '../../../../../../../../../types/types';
import { toggleBoardStarred } from '../effects/toggleBoardStarred';

export const onClick = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        dispatch(toggleBoardStarred(boardId));
    };
    return action;
};
