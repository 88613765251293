import * as React from 'react';
import {IAsidePanelActionsButtonsGroupTitleProps} from './types';

export function AsidePanelActionsButtonsGroupTitle ({
    title
}: IAsidePanelActionsButtonsGroupTitleProps) {
    return (
        <div className={'aside-panel__actions-buttons-group-title'}>
            {title}
        </div>
    );
}
