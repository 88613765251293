import { IApplicationState } from '../../../../../../../../types/types';
import { connect } from 'react-redux';
import { ITabDetailsHOCProps } from './types';
import { ITabDetailsFields } from '../../components/TabDetails/types';
import { TabDetails } from '../../components/TabDetails/TabDetails';
import { getSectionType } from '../../../../selectors/getSectionType';

const mapStateToProps = (
    state: IApplicationState,
    ownProps: ITabDetailsHOCProps
): ITabDetailsFields => {
    const { boardId } = ownProps;
    const sectionType = getSectionType(state, boardId);
    return {
        boardId,
        sectionType,
    }
};

export const TabDetailsHOC = connect(
    mapStateToProps,
    null
)(TabDetails);

TabDetailsHOC.displayName = 'TabDetailsHOC';
