import { getAuthUser } from 'app/store/model/authUser/selectors/getAuthUser';
import { IAuthUser } from 'app/store/model/authUser/types';
import { createDeepEqualSelector } from 'app/view/react_components/helpers/memoizeHelper';
import { IApplicationState, TBoardId } from 'app/types/types';
import { ICollaborator } from 'app/store/collaborators/collaborators/types';
import { getBoardCollaborators } from 'app/store/collaborators/modelsCollaborators/selectors/getBoardCollaborators';

type TgetBoardCollaborators = (
    state: IApplicationState,
    boardId: TBoardId,
) => ICollaborator[];

const getBoardCollaboratorsSelector = (
    authUser: IAuthUser,
    boardCollaborators: ICollaborator[],
): ICollaborator[] => {
    const { id } = authUser;
    const userIds: string[] = [];
    const collaborators = [...boardCollaborators].reverse().filter(collaborator => {
        if (
            collaborator.userId === id || // скрыть себя
            userIds.includes(collaborator.userId) // если один юзер зашёл несколько раз, показываем только последний
        ) return false;
        userIds.push(collaborator.userId);
        return true;
    });

    return collaborators;
};

export const getBoardCollaboratorsCreateSelector = (): TgetBoardCollaborators => createDeepEqualSelector(
    getAuthUser,
    getBoardCollaborators,
    getBoardCollaboratorsSelector,
);
