import { IGetState, TBoardId, TFilterId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { getAuthUser } from '../../../../../../../../store/model/authUser/selectors/getAuthUser';
import {
    authUserSetSortedSavedFiltersIds
} from '../../../../../../../../rest/effects/authUser/authUserSetSortedSavedFiltersIds';
import {
    getAuthUserSortedSavedFiltersIds
} from '../../../../../../../../store/model/authUser/selectors/getAuthUserSortedSavedFiltersIds';

export const setSortedSavedFilterIds = (
    boardId: TBoardId,
    newSortedSavedFiltersIds: TFilterId[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const { id } = getAuthUser(state);

        const sortedSavedFiltersIds = {
            ...getAuthUserSortedSavedFiltersIds(state),
            [boardId]: newSortedSavedFiltersIds
        };

        return dispatch(authUserSetSortedSavedFiltersIds(id, sortedSavedFiltersIds));
    };
    return action;
};
