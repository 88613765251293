import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState } from '../../../../../../../types/types';
import { updateQualificationFormMeta } from '../../../../../../../rest/effects/authUser/qualificationForm/updateQualificationFormMeta';
import { EUserMetaQualificationFormStatus } from '../../../../../../../types/rest/IUserMeta';
import { segmentTrackAction } from '../../../../../../../middlewares/segment';
import {
    ESegmentQuestionEventOptions,
    SegmentQuestionEvent
} from '../../../../../../../middlewares/segment/trackEntities/questionEvents';
import { getUserQualificationFormAnswers } from '../../../../../../../store/model/authUser/selectors/getUserQualificationFormAnswers';
import { ISegmentEventOption } from '../../../../../../../middlewares/segment/trackEntities/types';
import { Intercom, ONBOARD_MESSAGE_TYPE } from '../../../../../../../helper/intercom';
import { MESSAGE_TYPE_SKIPPED } from '../../../constants/messageType';
import { getAnswersEvents } from '../utils/getAnswersEvents';

export const onSkipped = (): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(updateQualificationFormMeta({
            status: EUserMetaQualificationFormStatus.SKIPPED
        }));

        const answers = getUserQualificationFormAnswers(getState()) || {};
        const count = Object.values(answers).filter(answer => !!answer).length;
        const questionEvents = getAnswersEvents(answers);
        const options: ISegmentEventOption[] = questionEvents.map(questionEvent => {
            return {
                name: questionEvent.event.event,
                    value: questionEvent.option ? questionEvent.option.value : ''
            }
        })

        const intercomProperties = {
            [ONBOARD_MESSAGE_TYPE]: MESSAGE_TYPE_SKIPPED
        }
        Intercom.sendProperties(intercomProperties); // обновить юзера с данными по опросу

        dispatch(segmentTrackAction(SegmentQuestionEvent.SKIPPED, {
            name: ESegmentQuestionEventOptions.questions,
            value: count.toString()
        }, options));
    };
    return action;
};
