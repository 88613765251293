import { IBoard } from './types';

export const getBoardInitial =
(): IBoard => {
    const BOARD_INITIAL: IBoard = {
        description: '',
        name: '',
    }
    return BOARD_INITIAL;
};
