import * as React from 'react';
import { ICardChecklistAddProps } from './types';
import { CLASS_CARD_DETAILS } from '../../../constants';
import {
    CARD_CHECKLIST_ADD_BUTTON,
    CARD_CHECKLIST_ADD_MOBILE_BUTTON,
    CARD_CHECKLIST_ADD_PLACEHOLDER,
    CHECKLIST_WEIGHT_DEFAULT
} from './constants';
import './_CardChecklistAdd.scss';
import { Button, Icon, Input, Tooltip } from 'kui';
import { EDateType } from '../../../../../../../const';
import { CardChecklistWeight } from '../CardChecklistWeight/CardChecklistWeight';
import { CardChecklistDateDatepicker } from '../CardChecklistDateDatepicker/CardChecklistDateDatepicker';
import { CardChecklistAssignUserSelect } from '../CardChecklistAssignUserSelect/CardChecklistAssignUserSelect';
import { TPermissionId } from '../../../../../../../types/types';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { ASSIGNEE_GROUP_DIVIDER, ASSIGNEE_GROUP_TITLE } from '../../../AssigneesSection/components/constants';
import { CardChecklistItemActionButton } from '../CardChecklistItemActionButton/CardChecklistItemActionButton';
import {
    CARD_CHECKLIST_ASSIGN_USER, CARD_CHECKLIST_ESTIMATE,
    CARD_CHECKLIST_SET_DATES,
    CARD_CHECKLIST_SET_DATES_DUE,
    CARD_CHECKLIST_SET_DATES_START,
    CARD_CHECKLIST_WEIGHT
} from '../CardChecklistItemActions/constants';
import { getAsidePanelTooltip } from '../../../../asidePanel/helpers/getAsidePanelTooltip';
import { CARD_DUE_DATE, CARD_START_DATE } from '../../../DatesSection/components/CardDatesSection/constants';
import { Util } from '../../../../../../../util/util';
import { UsersTooltip } from '../../../../../base/components/UsersTooltip/UsersTooltip';
import { v4 as uuidv4 } from 'uuid';
import { getParentsClasses } from '../../../../../helpers/getParentsClasses';
import { CardChecklistEstimate } from '../CardChecklistEstimate/CardChecklistEstimate';
import { convertTimeNumberToString } from '../../../helpers/convertTimeNumberToString';
import { CLASS_DATEPICKER } from '../../../../../base/components/Datepicker/components/constants';
import { AnalyzeRender } from 'app/view/react_components/helpers/memoizeHelper';

//@ts-ignore
const analyzeRender = window.Settings.development ? new AnalyzeRender(`CardChecklistAdd`) : null;

export const CardChecklistAdd = ({
    authUserPermissionId,
    avaliableAssignees,
    boardUsers,
    isOpened,
    sectionType,
    workHoursInDay,
    onAdd,
    onAddOpen,
    onAddClose,
    onCancel
}: ICardChecklistAddProps) => {
    if (analyzeRender) analyzeRender.call(`${authUserPermissionId}`);

    const isReadonly = sectionType === ESettingsSectionType.READONLY;

    const className = CLASS_CARD_DETAILS + '-checklist__add';
    const classActions = className + '-actions';
    const classIndicator = classActions + '-indicator';
    const classInput = className + '-input'

    const [key, setKey] = React.useState(uuidv4());
    let [isEditing, _setEditing] = React.useState(isOpened);
    const [name, setName] = React.useState('');
    const [weight, setWeight] = React.useState(CHECKLIST_WEIGHT_DEFAULT);
    const [assignees, setAssignees] = React.useState([]);
    const [estimate, setEstimate] = React.useState(null);
    const [dueDate, setDueDate] = React.useState(null);
    const [startDate, setStartDate] = React.useState(null);
    let [isAssignOpen, _setAssignOpen] = React.useState(null);
    let [isDatesOpen, _setDatesOpen] = React.useState(null);
    let [isWeightOpen, _setWeightOpen] = React.useState(null);
    let [isEstimateOpen, _setEstimateOpen] = React.useState(null);
    const [assigneesElements, setAssigneesElements] = React.useState(null);
    const [openedProperty, setOpenedProperty] = React.useState(null);
    const [isFocus, setFocus] = React.useState(null);
    const [isSaveFirst, setSaveFirst] = React.useState(null);

    const inputRef = React.useRef(null);

    const estimateStr = !estimate ? null : convertTimeNumberToString(estimate, false, workHoursInDay);

    const setAssignOpen = (open: boolean) => {
        isAssignOpen = open;
        _setAssignOpen(open);
    };

    const setEstimateOpen = (open: boolean) => {
        isEstimateOpen = open;
        _setEstimateOpen(open);
    };

    const setDatesOpen = (open: boolean) => {
        isDatesOpen = open;
        _setDatesOpen(open);
    };

    const setWeightOpen = (open: boolean) => {
        isWeightOpen = open;
        _setWeightOpen(open);
    };

    const isItemOverdue = (
        !!dueDate &&
        Date.now() > dueDate * 1000
    );

    const onReset = () => {
        setKey(uuidv4());
        setName('');
        setWeight(1);
        setAssignees([]);
        setEstimate(null);
        setDueDate(null);
        setStartDate(null);
        setDatesOpen(null);
        setAssignOpen(null);
        setWeightOpen(null);
    };

    const onWrapperClick = (e: React.SyntheticEvent) => {
        const className = CLASS_CARD_DETAILS + '-checklist-item-action-button';
        const classDropdown = className + '__dropdown';
        const classDatesDropdown = CLASS_DATEPICKER + '__calendar-select-dropdown';
        const parentClasses = getParentsClasses(e.target as HTMLElement, [
            className,
            classDropdown,
            classDatesDropdown
        ]);
        if (
            parentClasses.includes(className) ||
            parentClasses.includes(classDropdown) ||
            parentClasses.includes(classDatesDropdown)
        ) {
            return;
        }
        onInputFocus();
    };

    const onInputFocus = () => {
        const input = inputRef.current && inputRef.current.getInput();
        if (input) {
            input.focus();
        }
    };

    const setEditing = (value: boolean) => {
        isEditing = value;
        _setEditing(value);
    };

    const onClick = () => {
        setEditing(true);
        onAddOpen(true);
    };

    const cancelHandler = () => {
        onCancel();
        onClose();
    };

    const onClose = () => {
        setEditing(false);
        onAddOpen(false);
        onAddClose();
        onReset();
    };

    const onInputChange = (e: React.SyntheticEvent) => {
        const { value } = e.target as HTMLInputElement;
        setName(value);
    };

    const onBlur = (e: React.FocusEvent) => {
        setFocus(false);
        const parentClasses = getParentsClasses(e.relatedTarget as HTMLElement, [
            className
        ]);
        if (
            !parentClasses.includes(className) &&
            !isWeightOpen &&
            !isDatesOpen &&
            !isAssignOpen
        ) {
            onSave();
            onClose();
        }
    };

    const onSave = () => {
        if (!name) {
            onClose();
            return;
        }
        onAdd({
            name,
            weight,
            assignees,
            startDate,
            dueDate,
            estimate
        }, isSaveFirst);
        onReset();
    };

    const onSaveClick = () => {
        onSave();
        onInputFocus();
    };

    const onKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Backspace' || e.key === 'Delete') {
            e.stopPropagation();
            return;
        }
        if (e.key === 'Escape') {
            e.preventDefault();
            e.stopPropagation();
            cancelHandler();
            return;
        }
        if (e.key === 'Enter') {
            if (e.shiftKey) { // new line on Shift+Enter
            } else {
                e.preventDefault();
                onSave();
            }
            return;
        }
    };

    const onAssigneesChange = (permissionId: TPermissionId) => {
        let newAssignees = assignees;
        const idx = assignees.findIndex(assignee => assignee.sharedUser.permissionId === permissionId);
        if (idx > -1) {
            newAssignees = assignees.filter(assignee => assignee.sharedUser.permissionId !== permissionId);
        } else {
            newAssignees = [
                ...newAssignees,
                {
                    sharedUser: boardUsers.find(user => user.permissionId === permissionId),
                    roleIds: []
                }
            ];
        }
        setAssignees(newAssignees);
    };

    const onChangeDueDate = (date: number) => {
        setDueDate(date);
    };

    const onChangeStartDate = (date: number) => {
        setStartDate(date);
    };

    const onDatesClick = (e: React.SyntheticEvent) => {
        const classDates = className + '-dates--' + key;
        const parentClasses = getParentsClasses(e.target as HTMLElement, [
            classDates
        ]);
        if (parentClasses.includes(classIndicator + '--start')) {
            if (openedProperty === EDateType.START_DATE) {
                setDatesOpen(false);
            } else {
                setOpenedProperty(EDateType.START_DATE);
                setTimeout(() => {
                    setDatesOpen(true);
                }, 120);
            }
        } else if (parentClasses.includes(classIndicator + '--due')) {
            if (openedProperty === EDateType.DUE_DATE) {
                setDatesOpen(false);
            } else {
                setOpenedProperty(EDateType.DUE_DATE);
                setTimeout(() => {
                    setDatesOpen(true);
                }, 120);
            }
        } else {
            setDatesOpen(true);
            setOpenedProperty(EDateType.DUE_DATE);
        }
    };

    const onEnter = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') { // чтобы не переходить в режим редактирования айтема
            e.stopPropagation();
        }
    };

    React.useEffect(() => {
        if (!isEditing) return;
        const assigneesElements = avaliableAssignees.map(item => {
            if (item.value === ASSIGNEE_GROUP_TITLE || item.value === ASSIGNEE_GROUP_DIVIDER) {
                return {
                    ...item,
                    options: item.options.map(element => {
                        let active = false;
                        if (assignees.find(assignee => assignee.sharedUser.permissionId === element.value)) {
                            active = true;
                        }
                        return {
                            ...element,
                            active
                        };
                    })
                }
            } else {
                let active = false;
                if (assignees.find(assignee => assignee.sharedUser.permissionId === item.value)) {
                    active = true;
                }
                return {
                    ...item,
                    active
                };
            }
        });
        setAssigneesElements(assigneesElements);
    }, [assignees, isEditing]);

    React.useEffect(() => {
        if (isOpened) {
            setEditing(true);
            setSaveFirst(true);
        }
    }, [isOpened]);

    React.useEffect(() => {
        if (isEditing) {
            onInputFocus();
        } else {
            setFocus(false);
        }
    }, [isEditing]);

    React.useEffect(() => {
        if (isEditing && !isDatesOpen && !isAssignOpen && !isWeightOpen) {
            onInputFocus();
        }
    }, [isEditing, isDatesOpen, isAssignOpen, isWeightOpen]);

    return (
        <div
            className={`
                ${className}
                ${isDatesOpen || isAssignOpen || isWeightOpen || isFocus ? className + '--focused' : ''}
                ${isEditing ? className + '--editing' : ''}
            `}
        >
            {isEditing &&
                <div tabIndex={-1} className={className + '-wrapper'} onClick={onWrapperClick}>
                    <Input
                        className={classInput}
                        ref={inputRef}
                        value={name}
                        onInput={onInputChange}
                        onBlur={(e) => {
                            setTimeout(() => onBlur(e), 200); // wait for action click
                        }}
                        onKeyDown={onKeyDown}
                        onFocus={() => setFocus(true)}
                        placeholder={CARD_CHECKLIST_ADD_PLACEHOLDER}
                    />
                    {name &&
                        <Button
                            className={className + '-save-button'}
                            onClick={onSaveClick}
                        >
                            {CARD_CHECKLIST_ADD_MOBILE_BUTTON}
                        </Button>
                    }
                    <div className={classActions} onKeyDown={onEnter}>
                        <div className={classActions + '-left'}>
                            <CardChecklistItemActionButton
                                className={`
                                    ${classActions + '-dates'}
                                    ${classActions + '-dates--' + key}
                                    ${(!!startDate || !!dueDate) && classActions + '-dates-button'}
                                    ${isDatesOpen ? classActions + '-dates--' + (openedProperty === EDateType.DUE_DATE ? 'due' : 'start') : ''}
                                `}
                                disabled={isReadonly}
                                icon={!startDate && !dueDate ? 'calendar' : ''}
                                label={!dueDate && !startDate ? CARD_CHECKLIST_SET_DATES
                                    : <>
                                        {!!startDate ? <Tooltip
                                                {...getAsidePanelTooltip({
                                                    value: CARD_START_DATE + ': ' + Util.formatDateMoment(startDate),
                                                })}
                                            >
                                                <div tabIndex={0} className={`${classIndicator} ${classIndicator + '--start'}`}>
                                                    <Icon size={16} xlink={'start-date'} />
                                                    {Util.formatDateMoment(startDate, 'DD MMM')}
                                                </div>
                                            </Tooltip>
                                            : !!dueDate ? <div
                                                tabIndex={0}
                                                className={`
                                                    ${classIndicator}
                                                    ${classIndicator + '--start'}
                                                    ${classIndicator}--empty
                                                `}
                                            >
                                                <Icon size={16} xlink={'start-date'} />
                                                {CARD_CHECKLIST_SET_DATES_START}
                                            </div> : null
                                        }
                                        {!!dueDate ? <Tooltip
                                                {...getAsidePanelTooltip({
                                                    value: CARD_DUE_DATE + ': ' + Util.formatDateMoment(dueDate),
                                                })}
                                            >
                                                <div
                                                    tabIndex={0}
                                                    className={`
                                                        ${classIndicator}
                                                        ${classIndicator + '--due'}
                                                        ${isItemOverdue ? classIndicator + '--overdue' : ''}
                                                    `}
                                                >
                                                    <Icon size={16} xlink={isItemOverdue ? 'overdue' : 'due-date'} />
                                                    {Util.formatDateMoment(dueDate, 'DD MMM')}
                                                </div>
                                            </Tooltip>
                                            : !!startDate ? <div
                                                tabIndex={0}
                                                className={`
                                                    ${classIndicator}
                                                    ${classIndicator + '--due'}
                                                    ${classIndicator}--empty
                                                `}
                                            >
                                                <Icon size={16} xlink={isItemOverdue ? 'overdue' : 'due-date'} />
                                                {CARD_CHECKLIST_SET_DATES_DUE}
                                            </div> : null
                                        }
                                    </>
                                }
                                isEmpty={!dueDate && !startDate}
                                onClick={onDatesClick}
                                dropdownClassName={`${classActions + '-dates-dropdown'} ${classActions + '-dates-dropdown--' + key}`}
                                isOpen={isDatesOpen}
                                setOpen={setDatesOpen}
                                notBlurClasses={[CLASS_DATEPICKER + '__calendar-select-dropdown']}
                                dropdown={<CardChecklistDateDatepicker
                                    dateType={openedProperty}
                                    startDate={startDate}
                                    dueDate={dueDate}
                                    onChangeStartDate={onChangeStartDate}
                                    onChangeDueDate={onChangeDueDate}
                                    setOpen={setDatesOpen}
                                    onClickStartDate={() => {
                                        setOpenedProperty(EDateType.START_DATE);
                                    }}
                                    onClickDueDate={() => {
                                        setOpenedProperty(EDateType.DUE_DATE);
                                    }}
                                />}
                            />
                            <CardChecklistItemActionButton
                                tooltip={estimate ? getAsidePanelTooltip({
                                    value: CARD_CHECKLIST_ESTIMATE + ': ' + estimateStr,
                                }) : null}
                                disabled={isReadonly}
                                className={classActions + '-estimate'}
                                buttonClassName={classActions + '-estimate-button'}
                                icon={estimate ? null : 'estimate'}
                                label={estimate ? estimateStr : CARD_CHECKLIST_ESTIMATE}
                                isEmpty={!estimate}
                                isOpen={isEstimateOpen}
                                setOpen={setEstimateOpen}
                                dropdown={<CardChecklistEstimate
                                    estimate={estimate}
                                    workHoursInDay={workHoursInDay}
                                    onSave={setEstimate}
                                    onChange={setEstimate}
                                    onClose={() => setEstimateOpen(false)}
                                />}
                            />
                        </div>
                        <div className={classActions + '-right'}>
                            <CardChecklistItemActionButton
                                directionHorizontal={'right'}
                                className={classActions + '-assign'}
                                tooltip={getAsidePanelTooltip({
                                    value: !assignees || !assignees.length ? CARD_CHECKLIST_ASSIGN_USER : null,
                                })}
                                icon={!assignees || !assignees.length ? 'account' : null}
                                disabled={isReadonly}
                                label={assignees && !!assignees.length &&
                                    <UsersTooltip
                                        authUserPermissionId={authUserPermissionId}
                                        users={assignees}
                                        boardUsers={boardUsers}
                                        isNoEvents
                                    />
                                }
                                isEmpty={!assignees || !assignees.length}
                                id={key}
                                isOpen={isAssignOpen}
                                setOpen={setAssignOpen}
                                dropdown={<CardChecklistAssignUserSelect
                                    availableAssignees={assigneesElements}
                                    onSelect={onAssigneesChange}
                                    onClose={() => setAssignOpen(false)}
                                />}
                            />
                            <CardChecklistItemActionButton
                                className={classActions + '-weight'}
                                tooltip={getAsidePanelTooltip({
                                    value: CARD_CHECKLIST_WEIGHT + ': ' + weight,
                                })}
                                icon={'weight'}
                                directionHorizontal={'right'}
                                disabled={isReadonly}
                                label={String(weight)}
                                id={key}
                                isOpen={isWeightOpen}
                                setOpen={setWeightOpen}
                                dropdown={<CardChecklistWeight
                                    weight={weight}
                                    onChange={setWeight}
                                    onClose={() => setWeightOpen(false)}
                                />}
                            />
                        </div>
                    </div>
                </div>
            }
            {!isEditing &&
                <Button
                    className={className + '-button'}
                    onClick={onClick}
                    variant={'text'}
                >
                    {CARD_CHECKLIST_ADD_BUTTON}
                </Button>
            }
            <Button
                variant={'icon'}
                className={className + '-close'}
                onClick={cancelHandler}
            >
                <Icon xlink={'plus'} size={24} />
            </Button>
        </div>
    );
};
