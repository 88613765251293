import { Dispatch, ThunkAction } from 'app/types/actions';
import { openedSet } from '../store/common/actions/openedSet';
import { initialStateSet } from '../store/common/actions/initialStateSet';
import { historyPush } from '../../../base/helpers/historyHelper';
import { closeDialog } from './closeDialog';
import { SCREEN_TITLE } from '../constants';
import { TTabKey } from '../store/types';
import { tabSet } from '../store/header/actions/tabSet';
import { IGetState } from 'app/types/types';

export const openBoardsDialog = (
    tabKey?: TTabKey
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        dispatch(initialStateSet());
        if (!tabKey) {
            tabKey = TTabKey.recent;
        }
        if (tabKey) {
            dispatch(tabSet(tabKey));
        }
        dispatch(openedSet(true));

        historyPush({
            state: { page: 'boards-list' },
            title: SCREEN_TITLE,
            onpopstate: () => dispatch(closeDialog(true))
        });
    };
    return action;
};
