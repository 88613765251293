import { Dispatch, ThunkAction } from '../../../types/actions';
import { IGetState } from '../../../types/types';
import { fetchHandler } from '../../../util/fetchHandler';
import Util from '../../../util/util';

export const authUserQualificationFinished = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return fetchHandler(Util.getApiUrl('/rest/users/qualificationFinished'));
    };
    return action;
};
