import { AsidePanelActionsButton } from '../../../../..';
import * as React from 'react';
import { IEstimateTimePanelButtonProps } from './types';
import { CLASS_BOARD_DETAILS_SECTION } from '../../../../constants';
import { ESTIMATE_TIME_TITLE } from '../../constants';

export function EstimateTimePanelButton ({
    isDisabled,
    tooltip,
    onClick
}: IEstimateTimePanelButtonProps) {

    return (
        <AsidePanelActionsButton
            children={null}
            isDisabled={isDisabled}
            sectionSelector={`.${CLASS_BOARD_DETAILS_SECTION}--estimate-time`}
            title={ESTIMATE_TIME_TITLE}
            tooltip={tooltip}
            onClick={onClick}
        />
    );
}
