import { IGetState, TBoardId } from '../types/types';
import { Dispatch, ThunkAction } from '../types/actions';
import { getMessages, root } from '../store/constants';
import { getBoard } from '../store/model/selectors/getBoardById';

export const openCardTemplates = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        root.App.controller.closeAsidePanel();
        if (root.App.controller.leftAsidePanel.isCardTemplatesOpened()) return;
        const board = getBoard(getState(), boardId);
        if (board) {
            root.App.controller.leftAsidePanel.showCardTemplates(boardId);
        } else {
            root.App.controller.showError(getMessages().getText('error.record.not_found'));
        }
    };
    return action;
}
