import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../types/actions';
import { EViewTypes } from '../../../../../../const';
import { changeViewType } from '../../../effects/changeViewType';
import { addFeatureTrigger } from '../../../../polls/featurePoll/effects/addFeatureTrigger';
import { ERateFeature } from '../../../../../../types/rest/IUserMeta';
import { hintHide } from '../../../../hints/effects/hintHide';
import { EHintKey } from '../../../../hints';
import { getShowGanttContextHints } from '../../../../hints/conditions/ganttContextConditions/getShowGanttContextHints';
import { IGetState } from '../../../../../../types/types';

export const onTypeChanged: ActionCreator<ThunkAction> = (
    viewType: EViewTypes
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (getShowGanttContextHints(getState()) && viewType === EViewTypes.GANTT_VIEW_TYPE) {
            dispatch(hintHide(EHintKey.GANTT_CONTEXT_GANTT_TYPE_SWITCHER));
        }
        dispatch(addFeatureTrigger(ERateFeature.NEW_TOP_BAR));
        dispatch(changeViewType(viewType));
    };
    return action;
};
