import { initializeApp } from 'firebase/app';
import { deleteToken, getMessaging, getToken, isSupported, Messaging } from 'firebase/messaging';
import { dispatch, getAppState } from '../store/configureStore';
import { userDeleteWebPushNotifyToken } from '../rest/effects/authUser/webPush/userDeleteWebPushNotifyToken';
import { userUpdateWebPushNotifyTokenAccessTime } from '../rest/effects/authUser/webPush/userUpdateWebPushNotifyTokenAccessTime';
import { getAuthUser } from '../store/model/authUser/selectors/getAuthUser';
import {
    BROWSER_NOTIFICATIONS_REQUEST_ACTION_DATE_KEY
} from '../view/react_components/dialogs/notificationsRequest/components/NotificationsRequest/constants';

const LOCAL_STORAGE_LAST_USER_EMAIL_KEY = 'lastLoginUserFbEmail';
const LOCAL_STORAGE_LAST_USER_TOKEN_KEY = 'lastLoginUserFbToken';

export const initWebPushNotifications = (showUserNotifyPermissionsDialog: () => Promise<any>) => {
    if (!checkNotificationsSupport()) {
        return;
    }

    checkNotifyPermissions(showUserNotifyPermissionsDialog).then(() => {
        const lastUserEmail = localStorage.getItem(LOCAL_STORAGE_LAST_USER_EMAIL_KEY);
        const lastUserToken = localStorage.getItem(LOCAL_STORAGE_LAST_USER_TOKEN_KEY);
        const user = getAuthUser(getAppState());
        initFireBase().then((messaging) => {
            let clearPromise = Promise.resolve();
            if (!lastUserEmail || lastUserEmail !== user.email) {
                clearPromise = clearPrevUser(messaging, lastUserToken).then(() => {
                    getToken(messaging).then((token) => {
                        clearPromise.then(() => {
                            localStorage.setItem(LOCAL_STORAGE_LAST_USER_EMAIL_KEY, user.email);
                            localStorage.setItem(LOCAL_STORAGE_LAST_USER_TOKEN_KEY, token);
                            dispatch(userUpdateWebPushNotifyTokenAccessTime(token));
                        })
                    })
                })
            }
        })
    })
};

const checkNotificationsSupport = () => {
    return 'Notification' in window &&
        isSupported() &&
        !navigator.userAgent.match(/Opera|OPR\//);
}

const checkNotifyPermissions = (showUserNotifyPermissionsDialog: () => Promise<any>): Promise<any> => {
    if (Notification.permission === 'granted')
        return Promise.resolve();
    if (Notification.permission === 'denied')
        return Promise.reject();
    return new Promise(function (resolve, reject) {
        showUserNotifyPermissionsDialog().then(() => {
            Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    resolve(undefined);
                } else {
                    reject();
                }
            }).catch(() => {
                reject();
            })
        }).catch(() => {
            reject();
        })
    });
}

const initFireBase = () => {
    const firebaseConfig = {
        // @ts-ignore
        apiKey: window.Settings.fireBase.apiKey,
        // @ts-ignore
        projectId: window.Settings.fireBase.projectId,
        // @ts-ignore
        messagingSenderId: window.Settings.fireBase.messagingSenderId,
        // @ts-ignore
        appId: window.Settings.fireBase.appId,
    };
    initializeApp(firebaseConfig);
    const queryStr = ['apiKey=' + encodeURIComponent(firebaseConfig.apiKey),
        'projectId=' + encodeURIComponent(firebaseConfig.projectId),
        'messagingSenderId=' + encodeURIComponent(firebaseConfig.messagingSenderId),
        'appId=' + encodeURIComponent(firebaseConfig.appId)].join('&');
    return navigator.serviceWorker.register('/js/app/util/firebase-messaging-sw.js?' + queryStr)
        .then((registration) => {
            let messaging = getMessaging();
            // @ts-ignore хак , но кастомный (не /firebase-messaging-sw.js) можно подсунуть толбко через getToken, но  мне он тут не нужен и для deleteToken по другому никак не даш
            messaging.swRegistration = registration
            // let token =  getToken(messaging, {serviceWorkerRegistration: registration});
            // console.log(token);
            return messaging;
        });
}

const clearPrevUser = (messaging: Messaging, token: string): Promise<any> => {
    if (!token) {
        return Promise.resolve();
    }
    return Promise.all([
        deleteToken(messaging),
        clearWebPushUser()
    ]);
}

export const clearWebPushUser = () => {
    const lastUserToken = localStorage.getItem(LOCAL_STORAGE_LAST_USER_TOKEN_KEY);
    localStorage.removeItem(LOCAL_STORAGE_LAST_USER_EMAIL_KEY);
    localStorage.removeItem(LOCAL_STORAGE_LAST_USER_TOKEN_KEY);
    if (lastUserToken) {
        return dispatch(userDeleteWebPushNotifyToken(lastUserToken));
    }
    return Promise.resolve();
}

export const isBrowserNotificationsPopupShown = () => {
    return localStorage.getItem(BROWSER_NOTIFICATIONS_REQUEST_ACTION_DATE_KEY);
}
