import { IGetState, TCardId } from '../../../types/types';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { getCardAssignee } from '../../../store/model/cardAssignees/selectors/getCardAssignee';
import { IRestCardAssigneeInfo } from '../../../types/rest/IRestCardAssigneeInfo';
import { cardAssigneeRestPatch } from './api/helpers/cardAssigneeRestPatch';

export const cardAssigneeSetArchived = (
    cardId: TCardId,
    archived: boolean
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const cardAssignee = getCardAssignee(getState(), cardId);
        if (!cardAssignee) return Promise.resolve();
        const body: IRestCardAssigneeInfo = {
            archived
        }
        return dispatch(cardAssigneeRestPatch(cardId, body));
    };
    return action;
};
