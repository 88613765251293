import { ICard } from '../../card/types';
import { ICardsComparator } from './types';
import { compareNumbers } from './compareNumbers';

const getCardValue = (
    card: ICard
): number => card.startDate;

export const startDate: ICardsComparator = {
    getCardValue,
    sort: compareNumbers
}
