import { IChecklistDeleteAction } from './types';
import { AT_CHECKLIST_DELETE } from './constants';
import { TCardId, TChecklistId } from '../../../../../types/types';

export const checklistDeleteAction = (
    cardId: TCardId,
    checklistId: TChecklistId
): IChecklistDeleteAction => {
    return {
        type: AT_CHECKLIST_DELETE,
        cardId,
        checklistId
    }
};
