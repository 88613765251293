import * as React from 'react';
import { AsidePanelActionsButton } from '../../../..';
import { IDeleteButtonProps } from './types';
import { isKeyDelete } from '../../../../../base/helpers/isKeyDelete';
import { getParentsClasses } from '../../../../../helpers/getParentsClasses';
import { isKeyShiftDelete } from '../../../../../base/helpers/isKeyShiftDelete';
import { CLASS_ASIDE_PANEL, EVENT_KEY_BACKSPACE } from '../../../../../../../const';
import { AnalyzeRender } from '../../../../../helpers/memoizeHelper';
import { getAsidePanelActionsButtonTooltip } from 'app/view/react_components/aside_panel/asidePanel/helpers/getAsidePanelActionsButtonTooltip';

//@ts-ignore
const analyzeRender = window.Settings.development ? new AnalyzeRender(`DeleteButton`) : null;

export function DeleteButton({
    className,
    isShow,
    tooltip,
    title,
    onClick,
    onDelKey,
    onShowSnackbar,
}: IDeleteButtonProps) {
    if (analyzeRender) analyzeRender.call(`DeleteButton`);
    React.useEffect(() => {
        /**
         * keyup delete работает, если фокус в asidePanel или на body
         * отключить, если фокус ушёл в модалку
         */
         function handleKeydownEvent(e: KeyboardEvent) {
            if (isKeyShiftDelete(e)) {
                //if any text selected
                if (
                    window.getSelection &&
                    window.getSelection().type === 'Range') {
                    return;
                }

                const active = document.activeElement;
                let parents: string[] = null;
                if (active !== document.body) {
                    parents = getParentsClasses(
                        document.activeElement as HTMLElement,
                        [CLASS_ASIDE_PANEL]
                    );
                }
                if (parents && !parents.includes(CLASS_ASIDE_PANEL)) return;

                onDelKey();
                document.removeEventListener('keydown', handleKeydownEvent);
            } else if (isKeyDelete(e)) {
                onShowSnackbar();
            }
        }
        document.addEventListener('keydown', handleKeydownEvent);
        return () => {
            document.removeEventListener('keydown', handleKeydownEvent);
        }
    }, []);

    return (
        <AsidePanelActionsButton
            className={className}
            icon={'delete'}
            title={title}
            tooltip={tooltip && getAsidePanelActionsButtonTooltip({
                direction: 'down',
                value: tooltip,
            })}
            isShow={isShow}
            onClick={onClick}
        />
    );
};
