import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../../../../types/types';
import { checklistUpdate } from '../../../../../../../../rest/effects/card/checklist/checklistUpdate';
import {
    getCardChecklistsSorted
} from '../../../../../../../../store/model/checklists/checklists/selectors/getCardChecklistsSorted';
import { CHECKLIST_DEFAULT_ID } from '../../../../../../../../store/model/checklists/checklists/constants';
import { updateDefaultChecklistWithItems } from '../../CardChecklistActionsHOC/effects/updateDefaultChecklistWithItems';

export const onNameChange = (
    cardId: TCardId,
    name: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const checklists = getCardChecklistsSorted(getState(), cardId);
        const checklist = checklists && checklists[0];
        if (!checklists) return;
        if (checklist.id === CHECKLIST_DEFAULT_ID) {
            return dispatch(updateDefaultChecklistWithItems({ ...checklist, name }));
        } else {
            return dispatch(checklistUpdate({ ...checklist, name }));
        }
    };
    return action;
};
