import * as React from 'react';
import { ICardTagsAddProps } from './types';
import { SelectListItem } from 'kui';

export function CardTagsAdd({
    text,
    value,
    className,
}: ICardTagsAddProps) {
    return (
        <SelectListItem
            key={value}
            icon={'tag'}
            value={value}
            className={className}
        >
            {text}
        </SelectListItem>
    );
}
