import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { SegmentBoardEvent, SegmentBoardManagerEvent, segmentTrackAction } from '../../../../../middlewares/segment';
import { IGetState } from '../../../../../types/types';
import { SegmentBoardManagerOption } from '../../../../../middlewares/segment/trackEntities/boardManagerEvents';
import { TGoogleDriveId } from '../store/types';
import {
    SegmentBoardOption,
    SegmentBoardOptionSharedWithOtherValue, SegmentBoardOptionStatusValue,
    SegmentBoardOptionTargetValue
} from '../../../../../middlewares/segment/trackEntities/boardEvents';
import { ISegmentEventOption } from '../../../../../middlewares/segment/trackEntities/types';
import { IBoard } from '../store/boards/types';
import { getSegmentEventOptionDriveType } from '../../../../../middlewares/segment/trackEntities/helpers/boardEvents/getSegmentEventOptionDriveType';
import { getFullLoadedBoardIds } from '../../../../../store/model/selectors/getFullLoadedBoardIds';
import { IAppTab } from '../../../../../store/model/tabs/types';
import { VIEW_TYPES } from '../../../typeSwitcher/constants';
import { getLocalStorageBoardViewType } from '../../../typeSwitcher/store/localstorage/actions/getLocalStorageBoardViewType';
import { getBoardBackgroundColors } from 'app/store/model/board/selectors/getBoardBackgroundColors';
import { getBoardSubcardsCount } from 'app/store/model/selectors/getBoardSubcardsCount';
import { getBoardCardSorting } from 'app/store/model/selectors/getBoardCardSorting';
import { isAgingEnabled } from 'app/store/model/selectors/isAgingEnabled';

export const clickedGoBackSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentBoardManagerEvent.CLICKED_GO_BACK))
};

export const tabsSegmentEvent: ActionCreator<ThunkAction> = (
    tabName: string
) => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentBoardManagerEvent.CHANGED_TAB,
        {
            name: SegmentBoardManagerOption.TAB,
            value: tabName
        }))
};

export const onCLickSearchSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentBoardManagerEvent.CLICKED_SEARCH_INPUT))
};

export const onChangeSearchSegmentEvent: ActionCreator<ThunkAction> = (
    value: string
) => (
    dispatch: Dispatch
) => {
    const segmentEvent = value ? SegmentBoardManagerEvent.CHANGED_SEARCH_INPUT : SegmentBoardManagerEvent.CLEARED_SEARCH_INPUT;
    dispatch(segmentTrackAction(segmentEvent))
};

export const onOpenBoardClickedSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentBoardManagerEvent.OPEN_BOARD_CLICKED));
};

export const onToggleBoardsAccordionSegmentEvent: ActionCreator<ThunkAction> = (
    isOpen: boolean
) => (
    dispatch: Dispatch
) => {
    const segmentEvent = isOpen ? SegmentBoardManagerEvent.OPENED_BOARDS_ACCORDION : SegmentBoardManagerEvent.CLOSED_BOARDS_ACCORDION;
    dispatch(segmentTrackAction(segmentEvent));
};

export const onClickCreateNewBoardSegmentEvent = (
    driveId: TGoogleDriveId
): ThunkAction => {
    return (dispatch: Dispatch) => {
        const option: ISegmentEventOption = {name: SegmentBoardOption.LOCATION, value: getSegmentEventOptionDriveType(driveId) };
        dispatch(segmentTrackAction(SegmentBoardManagerEvent.CLICKED_CREATE_NEW_BOARD, option));
    };
};

export const onClickBoardSectionSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentBoardManagerEvent.CLICKED_BOARD_SETTINGS));
};

export const clickedDeleteBoardSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentBoardManagerEvent.CLICKED_DELETE_BOARD));
};

export const openedBoardSegmentEvent: ActionCreator<ThunkAction> = (
    board: IAppTab,
    listCount: number,
    cardCount: number,
    assigneesCount: number,
    loadingStartTime: number
) => (
    dispatch: Dispatch,
    getState: IGetState
) => {
    if (board) {
        const option: ISegmentEventOption = {
            name: SegmentBoardOption.LOCATION,
            value: getSegmentEventOptionDriveType(board.driveId)
        };
        const loadedBoardIds = getFullLoadedBoardIds(getState());
        if (!loadedBoardIds.includes(board.id)) {
            loadedBoardIds.push(board.id)
        }

        const additionalOptions: ISegmentEventOption[] = [{
            name: SegmentBoardOption.SHARED_WITH_OTHER,
            value: board.isShared ? SegmentBoardOptionSharedWithOtherValue.TRUE : SegmentBoardOptionSharedWithOtherValue.FALSE
        }, {
            name: SegmentBoardOption.BOARDS_COUNT,
            value: loadedBoardIds.length
        }];
        const viewType = getLocalStorageBoardViewType(board.id);
        if (viewType && VIEW_TYPES[viewType]) {
            additionalOptions.push({
                name: SegmentBoardOption.VIEW,
                value: VIEW_TYPES[viewType].name
            })
        }
        additionalOptions.push({
            name: SegmentBoardOption.LIST_COUNT,
            value: listCount
        })
        const loadingTime = Math.round((Date.now() - loadingStartTime) / 1000);
        additionalOptions.push({
            name: SegmentBoardOption.LOADING_TIME,
            value: loadingTime > 20 ? Math.round(loadingTime / 5) * 5 : loadingTime
        });
        if (cardCount > 0 && cardCount < 50) {
            cardCount = cardCount < 5 ? 1 : Math.floor(cardCount / 5) * 5;
        } else
            cardCount = Math.floor(cardCount / 50) * 50;
        additionalOptions.push({
            name: SegmentBoardOption.CARD_COUNT,
            value: cardCount
        })
        additionalOptions.push({
            name: SegmentBoardOption.ID,
            value: board.id
        });
        additionalOptions.push({
            name: SegmentBoardOption.ASSIGNEES_COUNT,
            value: assigneesCount
        });
        const backgroundColors = getBoardBackgroundColors(getState(), board.id).length;
        additionalOptions.push({
            name: SegmentBoardOption.CARD_BACKGROUND_COLORS_COUNT,
            value: backgroundColors
        });
        const boardSubcardsCount = getBoardSubcardsCount(getState(), board.id);
        additionalOptions.push({
            name: SegmentBoardOption.SUBCARDS_COUNT,
            value: boardSubcardsCount
        });
        const isAging = isAgingEnabled(getState(), board.id);
        additionalOptions.push({
            name: SegmentBoardOption.CARD_AGING,
            value: isAging ? SegmentBoardOptionStatusValue.ON : SegmentBoardOptionStatusValue.OFF
        });
        const isSorting = getBoardCardSorting(getState(), board.id);
        additionalOptions.push({
            name: SegmentBoardOption.CARD_SORTING,
            value: isSorting ? SegmentBoardOptionStatusValue.ON : SegmentBoardOptionStatusValue.OFF
        });
        dispatch(segmentTrackAction(SegmentBoardEvent.OPENED, option, additionalOptions));
    }
};

export const deletedBoardSegmentEvent: ActionCreator<ThunkAction> = (
    board: IAppTab
) => (
    dispatch: Dispatch
) => {
    const option: ISegmentEventOption = {name: SegmentBoardOption.LOCATION, value: getSegmentEventOptionDriveType(board.driveId) };
    dispatch(segmentTrackAction(SegmentBoardEvent.BOARD_DELETED, option));
};

const renameBoardSegmentEvent: ActionCreator<ThunkAction> = (
    targetValue: SegmentBoardOptionTargetValue
) => (
    dispatch: Dispatch
) => {
    const option: ISegmentEventOption = {name: SegmentBoardOption.TARGET, value: targetValue };
    dispatch(segmentTrackAction(SegmentBoardEvent.BOARD_RENAMED, option));
};

export const renameBoardFromMenuSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(renameBoardSegmentEvent(SegmentBoardOptionTargetValue.BOARD_MENU));
};

export const renameBoardFromDblClickSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(renameBoardSegmentEvent(SegmentBoardOptionTargetValue.BOARD_DBL_CLICK));
};

export const renameBoardFromDetailsSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(renameBoardSegmentEvent(SegmentBoardOptionTargetValue.BOARD_DETAILS));
};

export const deletedBoardManagerSegmentEvent: ActionCreator<ThunkAction> = (
    board: IBoard
) => (
    dispatch: Dispatch
) => {
    const option: ISegmentEventOption = {name: SegmentBoardOption.LOCATION, value: getSegmentEventOptionDriveType(board.driveId) };
    dispatch(segmentTrackAction(SegmentBoardManagerEvent.DELETED_BOARD, option));
};

export const movedBoardSegmentEvent: ActionCreator<ThunkAction> = (
    driveId: TGoogleDriveId
) => (
    dispatch: Dispatch
) => {
    const option: ISegmentEventOption = {name: SegmentBoardOption.DIRECTION, value: getSegmentEventOptionDriveType(driveId)};
    dispatch(segmentTrackAction(SegmentBoardManagerEvent.MOVED_BOARD, option));
};

export const clickedCreateNewTemplateSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentBoardManagerEvent.CLICKED_CREATE_NEW_TEMPLATE));
};

export const clickedUseTemplateSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentBoardManagerEvent.CLICKED_USE_TEMPLATE));
};

export const clickedPreviewTemplateSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentBoardManagerEvent.CLICKED_PREVIEW_TEMPLATE));
};

export const closedTemplatePreviewSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentBoardManagerEvent.CLOSED_TEMPLATE_PREVIEW));
};

export const clickedEditTemplateSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentBoardManagerEvent.CLICKED_EDIT_TEMPLATE));
};

export const clickedHowToSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentBoardManagerEvent.TRELLO_CLICKED_HOW_TO));
};

export const clickedRestoreXmlHowToSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentBoardManagerEvent.RESTORE_FROM_XML_HOW_TO_CLICKED));
};

export const clickedRestoreXmlSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentBoardManagerEvent.RESTORE_FROM_XML_CLICKED));
};

export const clickedHowToOnTemplatesTabSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentBoardManagerEvent.CLICKED_HOW_TO_ON_TEMPLATES_TAB));
};

export const clickedImportFromTrelloSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentBoardManagerEvent.CLICKED_IMPORT_FROM_TRELLO));
};

export const clickedImportFromJiraSegmentEvent = (): ThunkAction => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentBoardManagerEvent.CLICKED_IMPORT_FROM_JIRA));
};

export const clickedImportFromCsvSegmentEvent = (): ThunkAction => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentBoardManagerEvent.CLICKED_IMPORT_FROM_CSV));
};

export const clickedOpenTicksAndTricksSegmentEvents: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentBoardManagerEvent.CLICKED_TIPS_AND_TRICKS));
};

export const clickedMoveToSegmentEvent: ActionCreator<ThunkAction> = () => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentBoardManagerEvent.CLICKED_MOVE_BOARD_BUTTON));
};

export const myDriveListLoadedEvent: ActionCreator<ThunkAction> = (
    boardCount: number
) => (
    dispatch: Dispatch
) => {
    dispatch(segmentTrackAction(SegmentBoardManagerEvent.MY_DRIVE_LIST_LOADED,
        {
            name: SegmentBoardManagerOption.BOARD_COUNT,
            value: boardCount
        }))
};
