import { TGoogleDriveId } from '../types';
import { IRestUserBoardPermissions } from '../../../../../../types/rest/IRestUserBoardPermissions';
import { TBoardId } from '../../../../../../types/types';
import { EAuthUserPlatformType } from 'app/types/rest/IRestAuthUser';

export interface IUserBoardPermissions extends
    IRestUserBoardPermissions
{}

export interface IBoard {
    driveId: TGoogleDriveId;
    id: TBoardId;
    name: string;
    logo?: string;
    logoColor?: string;
    logoIcon?: string;
    isArchived?: boolean;
    isOpened?: boolean;
    isShared?: boolean;
    isStarred?: boolean;
    allowedAction?: IUserBoardPermissions;
    selectionOrder?: number;
    platformType?: EAuthUserPlatformType;
}

export interface IBoards {
    boards: IBoard[];
    isLoading: {
        [tabKey: string]: boolean;
    };
}

export enum TBoardDeleteType {
    own = 'own',
    shared = 'shared',
    disallow = 'disallow'
};
