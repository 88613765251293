import { IApplicationState, TCardId } from '../../../../../types/types'
import { CHECKLIST_DEFAULT_ID } from '../constants';
import { getCardChecklists } from './getCardChecklists';

export const getCardHasCustomChecklist = (
    state: IApplicationState,
    cardId: TCardId,
): boolean => {
    const checklists = getCardChecklists(state, cardId);
    for (let checklistId in checklists) {
        if (Number(checklistId) !== CHECKLIST_DEFAULT_ID) return true;
    }
    return false;
};
