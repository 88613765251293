import * as React from 'react';
import { SegmentErrorEvent, SegmentErrorOptions } from 'app/middlewares/segment/trackEntities/errorEvents';
import { segmentTrackAction } from 'app/middlewares/segment';
import { LazyWrapper } from 'app/view/react_components/lazy/lazy_wrapper';
import { IReactRootViewProps } from 'app/view/react_components/types';
import store, { dispatch } from 'app/store/configureStore';
import { snackbarErrorReload } from 'app/view/react_components/snackbarsStack';

const initGanttStore = () => Promise.all([
    import(/* webpackChunkName: "gantt" */ '../../../store/ganttView/reducers/ganttView'),
])
    .then(([module1]) => {
        const ganttViewReducer = module1.ganttViewReducer;
        store.reducerManager.add('ganttView', ganttViewReducer);
        dispatch({type: 'async-init'}); // fake action to init new reducers
    })
    .catch(() => {
        onImportFail();
        return {
            default: () =>(<div/>)
        }
    });

const onImportFail = () => {
    dispatch(snackbarErrorReload(false));
    dispatch(segmentTrackAction(SegmentErrorEvent.LAZY_IMPORT_ERROR, {
        name: SegmentErrorOptions.SOURCE,
        value: 'ganttView'
    }));
}

const Gantt = React.lazy(() =>
    initGanttStore()
        .then(() => import(/* webpackChunkName: "gantt" */ '../../hocs/GanttViewHOC/GanttViewHOC'))
        .then(module => ({default: module.GanttViewHOC}) as any)
        .catch(() => onImportFail())
);

export const GanttViewLazy = (
    props: IReactRootViewProps
) => {
    // лоадер не нужен, т.к. при смене view висит systemLoader
    return <LazyWrapper loader={<div/>}>
        <Gantt {...props} />
    </LazyWrapper>;
};
