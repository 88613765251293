import { TBoardId } from '../../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { root } from '../../../../../../../../../store/constants';
import { BOARD_LOGO_WRONG_FILE_SIZE_TEXT, BOARD_LOGO_WRONG_FILE_TYPE_TEXT, MAX_BOARD_LOGO_FILE_SIZE } from '../constants';
import { snackbarInfoDefault } from '../../../../../../../snackbarsStack';
import { boardSetLogo } from '../../../../../../../../../rest/effects/board/boardSetLogo';
import { SNACKBAR_ID_BOARD_ERROR } from '../../../../../../../snackbarsStack/effects/constants';

export const logoUpload = (
    boardId: TBoardId,
    file: File
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        if (!file) {
            return Promise.reject();
        }
        if (file.size > MAX_BOARD_LOGO_FILE_SIZE) {
            dispatch(showWrongFileSizeWarning());
            return Promise.reject();
        }
        if (!root.App.Util.fileIsImage(file.type)) {
            dispatch(showWrongFileTypeWarning());
            return Promise.reject();
        }
        return dispatch(boardSetLogo(boardId, file))
    };
    return action;
};

const showWrongFileSizeWarning = (): ThunkAction => {
    const action = (dispatch: Dispatch) => {
        dispatch(snackbarInfoDefault({
            id: SNACKBAR_ID_BOARD_ERROR,
            text: BOARD_LOGO_WRONG_FILE_SIZE_TEXT
        }));
    };
    return action;
};

const showWrongFileTypeWarning = (): ThunkAction => {
    const action = (dispatch: Dispatch) => {
        dispatch(snackbarInfoDefault({
            id: SNACKBAR_ID_BOARD_ERROR,
            text: BOARD_LOGO_WRONG_FILE_TYPE_TEXT
        }));
    };
    return action;
};
