import * as React from 'react';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { TabDetailsHOC } from '../../hocs/TabDetailsHOC/TabDetailsHOC';

export function TabDetailsContext() {
    const { boardId, cardId } = React.useContext(AsidePanelContext);

    return <TabDetailsHOC
        boardId={boardId}
        cardId={cardId}
    />;
}
