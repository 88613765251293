import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { settingsOpenSet } from '../store/actions/settingsOpenSet';
import { showSet } from '../store/actions/showSet';
import { ActionCreator } from 'redux';

export const openCookieDialogFull: ActionCreator<ThunkAction> = () => {
    const action = (dispatch: Dispatch) => {
        dispatch(settingsOpenSet(true));
        dispatch(showSet(true));
    };
    return action;
};
