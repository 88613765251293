import { Permission } from '@microsoft/microsoft-graph-types';
import { errorHandler, graphClient } from 'app/helper/authorisation/microsoft/graph/Graph';
import {
    getGrantedFromPermissions,
    IFilePermission,
    EFilePermissionRole
} from 'app/helper/authorisation/microsoft/graph/api/getFilePermissions';

export const updateFilePermissions = async (
    driveId: string,
    fileId: string,
    permId: string,
    role: EFilePermissionRole
) => {
    try {
        let request = graphClient.api(`/drives/${driveId}/items/${fileId}/permissions/${permId}`);
        let perm = {
            'roles': [ role ]
        };
        let response = await request.patch(perm) as Permission;
        return {
            id: response.id,
            role: response.roles[0],
            granted: getGrantedFromPermissions(response)
        } as IFilePermission
    } catch (e) {
        return errorHandler(e);
    }
}
