import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../types/types';
import { IProfileSubscriptionEvents, IProfileSubscriptionFields } from '../../components/ProfileSubscription/types';
import { ProfileSubscription } from '../../components/ProfileSubscription/ProfileSubscription';
import {
    getUserPaidSubscriptionFirst
} from '../../../../../store/model/authUser/selectors/getUserPaidSubscriptionFirst';
import { getTrialSubscription } from '../../../../../store/model/authUser/selectors/getTrialSubscription';
import { isPwc } from '../../../../../store/model/authUser/selectors/isPwc';
import { onClickUpgrade } from './events/onClickUpgrade';
import { getPlanName } from '../../../subscriptionAdmin/store/subscription/selectors/getPlanName';
import { getAuthUser } from '../../../../../store/model/authUser/selectors/getAuthUser';
import { isSubscriptionCanceled } from '../../../../../store/model/authUser/selectors/isSubscriptionCanceled';
import { getUserPromoCodeDiscount } from '../../../../../store/model/authUser/selectors/getUserPromoCodeDiscount';
import { getUserManagedSubscriptions } from '../../../../../store/model/authUser/selectors/getUserManagedSubscriptions';

const mapStateToProps = (
    state: IApplicationState
): IProfileSubscriptionFields => {
    let isCanceled = false;
    const isCorp = isPwc(state);
    let isOwn = false;
    const _subscription = getUserPaidSubscriptionFirst(state) || getTrialSubscription(state);
    const subscription = _subscription ? {..._subscription} : null;
    if (subscription) {
        subscription.name = getPlanName(subscription.productId);
        isCanceled = isSubscriptionCanceled(subscription);
        isOwn = subscription.authorId === getAuthUser(state).id;
    }
    return {
        discount: !getUserManagedSubscriptions(state).length && getUserPromoCodeDiscount(state),
        isCanceled,
        isCorp,
        isOwn,
        subscription
    }
};

const mapDispatchToProps = (
    dispatch: any
): IProfileSubscriptionEvents => {
    return {
        onClickUpgrade: () => dispatch(onClickUpgrade())
    }
};

export const ProfileSubscriptionHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileSubscription);

ProfileSubscriptionHOC.displayName = 'ProfileSubscriptionHOC';
