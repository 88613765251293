import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../../../../types/types';
import { IRecurringTaskForm } from '../../../types';
import { mapFormToRestRecurringTask } from '../../../helpers/mapFormToRestRecurringTask';
import { getCard } from '../../../../../../../../store/model/selectors/getCard';
import { recurringTaskUpdate } from '../../../../../../../../rest/effects/card/recurringTask/recurringTaskUpdate';
import { ERecurringTaskStatus } from '../../../../../../../../types/rest/recurringTask/IRestRecurringTask';
import { updateRecurringEvents } from '../effects/segmentEvents';

export const onUpdate = (
    form: IRecurringTaskForm,
    cardId: TCardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const { recurringTasks } = getCard(getState(), cardId);
        const recurringTask = recurringTasks && recurringTasks[0];
        if (!recurringTask) return;

        const restRecurringTask = mapFormToRestRecurringTask(form);
        if (!restRecurringTask) return;

        if (
            restRecurringTask.startDate > Date.now() && (
                recurringTask.status === ERecurringTaskStatus.CLOSED ||
                recurringTask.status === ERecurringTaskStatus.PENDING
            )
        ) {
            restRecurringTask.status = ERecurringTaskStatus.NOT_PROCESSED;
        }
        if (restRecurringTask.startDate <= Date.now()) {
            restRecurringTask.status = ERecurringTaskStatus.PENDING;
        }
        dispatch(updateRecurringEvents(recurringTask, restRecurringTask));

        dispatch(recurringTaskUpdate(recurringTask.id, restRecurringTask, cardId));
    };
    return action;
};
