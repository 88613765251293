import {IApplicationState} from '../../../../../types/types';
import { EViewTypes } from '../../../../../const';
import { getUserDefaultView } from '../../../../../store/model/authUser/selectors/getUserDefaultView';
import { getRealTypeSwitcherActiveType } from './getRealTypeSwitcherActiveType';

export const getTypeSwitcherActiveType = (
    state: IApplicationState,
    boardId: number
): EViewTypes => {
    return getRealTypeSwitcherActiveType(state, boardId) || getUserDefaultView(state);
};
