import { IUserBoardPermissionsBackboneModel } from '../../../../../types/backbone/IUserBoardPermissionsBackboneModel';
import { TBoardId } from '../../../../../types/types';
import { IApplicationState } from '../../../../../types/types';
import { ESettingsSectionType } from '../../../base/components/SettingsSection/types';
import { getSectionType } from './getSectionType';
import { IAuthUser } from '../../../../../store/model/authUser/types';

export const getPanelReadonly = (
    state: IApplicationState,
    boardId: TBoardId,
    typeSection?: ESettingsSectionType,
    allowSection?: keyof IAuthUser,
    boardAllow?: keyof IUserBoardPermissionsBackboneModel, // todo achtung kill backbone
): boolean => {
    const sectionType = getSectionType(state, boardId, typeSection, allowSection, boardAllow);
    return sectionType === ESettingsSectionType.READONLY;
}
