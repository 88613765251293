import { CARDS_ACTION_SET, ICardsActionSetAction } from './types';
import { TCardsAction } from '../cards/actions/types';

export const cardsActionSetAction = (
    cardsAction: TCardsAction
): ICardsActionSetAction => {
    return {
        type: CARDS_ACTION_SET,
        cardsAction
    }
};
