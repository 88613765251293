const category = 'Nav panel';

export const SegmentNavPanelEvent = {
    NAV_PANEL_OPENED_MOUSE_ENTER: { category, event: 'NavPanel opened on mouseEnter' },
    NAV_PANEL_CLOSED_MOUSE_LEAVE: { category, event: 'NavPanel closed on mouseLeave' },
    ANCHOR_CLICKED: { category, event: 'Anchor clicked in NavPanel' },
    ANCHOR_MOBILE_CLICKED: { category, event: 'Anchor clicked on mobile in NavPanel' },
    BOARD_TAB_CLICKED: { category, event: 'Board tab clicked in NavPanel' },
    BOARD_TAB_CLOSED: { category, event: 'Board tab closed in NavPanel' },
    VIEW_TYPE_CHANGED: { category, event: 'View type changed in NavPanel' },
    HELP_CLICKED: { category, event: 'Help clicked in NavPanel' },
    RESIZE_CLICKED: { category, event: 'Resize clicked in NavPanel' },
    CLOSE_CLICKED: { category, event: 'Close clicked on mobile in NavPanel' },
    BOARD_MENU_ICON_CLICKED: { category, event: 'Board menu icon clicked' },
    BOARD_ADD_CLICKED: { category, event: 'Add board clicked' },
    BOARD_CREATE_CLICKED: { category, event: 'Add board - Create board clicked' },
    BOARD_CREATE_ON_SHARED_DRIVE_CLICKED: { category, event: 'Add board - Create board on a shared drive clicked' },
    BOARD_CREATE_SHARED_DRIVE_CHOSEN: { category, event: 'Add board - Shared drive chosen' },
    BOARD_CREATE_FROM_TEMPLATE_CLICKED: { category, event: 'Add board - Create board from template clicked' },
    BOARD_IMPORT_CANCEL_CLICKED: { category, event: 'Add board - Import cancel clicked' },
    BOARD_IMPORT_CLICKED: { category, event: 'Add board - Import board clicked' },
    BOARD_IMPORT_CSV_CLICKED: { category, event: 'Add board - Import board CSV clicked' },
    BOARD_IMPORT_CSV_LEARN_MORE_CLICKED: { category, event: 'Add board - Learn more about CSV import clicked' },
    BOARD_IMPORT_JIRA_CLICKED: { category, event: 'Add board - Import board Jira clicked' },
    BOARD_IMPORT_JIRA_LEARN_MORE_CLICKED: { category, event: 'Add board - Learn more about Jira import clicked' },
    BOARD_IMPORT_TRELLO_CLICKED: { category, event: 'Add board - Import board Trello clicked' },
    BOARD_IMPORT_TRELLO_LEARN_MORE_CLICKED: { category, event: 'Add board - Learn more about Trello import clicked' },
    BOARD_OPEN_CLICKED: { category, event: 'Add board - Open board clicked' },
    BOARDS_MANAGER_CLICKED: { category, event: 'Boards manager clicked' },
    ASSIGNED_TO_ME_CARDS_CLICKED: { category, event: 'Assigned to me cards clicked' },
    NO_ACTIONS: {category, event: 'Nav panel opened and closed without action'},
    RECENT_BOARD_TAB_CLICKED: { category, event: 'Recent board tab clicked in NavPanel' },
    RECENT_BOARD_TAB_CLOSED: { category, event: 'Recent board tab closed in NavPanel' },
    OPEN_RECENT_BOARD_CLICKED: { category, event: 'Open recent board clicked' },
    OPEN_IN_WORK_CARD_CLICKED: { category, event: 'Open in work card clicked' },
};

export enum SegmentNavPanelOption {
    TYPE = 'Type',
    SIZE = 'Size',
    ORDER = 'Order'
}
