import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState } from '../../../../types/types';
import { authUserSetInWorkCardId } from '../authUserSetInWorkCardId';
import { checkAndMoveCardOnHoldList } from '../../card/card/checkAndMoveCardOnHoldList';
import { cardResetInWorkUser } from './cardResetInWorkUser';
import { getAuthUser } from '../../../../store/model/authUser/selectors/getAuthUser';

export const authUserResetInWorkCard = (): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const user = getAuthUser(getState());
        const lastInWorkCardId = user.inWorkCardId;
        if (lastInWorkCardId) {
            return dispatch(checkAndMoveCardOnHoldList()).then(() => {
                if (!lastInWorkCardId) return Promise.resolve();
                dispatch(cardResetInWorkUser(user.permissionId, lastInWorkCardId));
                return dispatch(authUserSetInWorkCardId(0));
            });
        }
    };
    return action;
};
