import { formatCommentToSeconds } from './../../helpers/formatCommentToSeconds';
import { IApplicationState, TBoardId } from '../../../../../../types/types';
import { IComment } from '../../../../../../store/model/card/types/IComment';
import { getMentions } from './MentionsHelper';
import { getCommentValue } from '../selectors/getCommentValue';
import { getBoardWorkHoursInDay } from '../../../../../../store/model/selectors/getBoardWorkHoursInDay';
import { getReplyCommentId } from '../selectors/getReplyCommentId';
import { TDriveDocId } from '../../../../../../types/rest/IRestDriveDoc';
import { getReplyAttachmentId } from '../selectors/getReplyAttachmentId';
import { getReplyText } from '../selectors/getReplyText';

export const getCommentMention = (
    state: IApplicationState,
    boardId: TBoardId
) => {
    const commentText = getCommentValue(state);
    return getMentions(state, boardId, commentText);
};

export const getCommentModel = (
    state: IApplicationState,
    boardId: TBoardId,
    inputText: string = '',
    driveDocIds?: TDriveDocId[]
): Partial<IComment> => {
    const text = inputText || getCommentValue(state);
    const mentions = getCommentMention(state, boardId) || [];
    const workHoursInDay = getBoardWorkHoursInDay(state, boardId);
    const timeTrackerData = formatCommentToSeconds(text, workHoursInDay);

    let commentModel = {
        text,
        mentions,
        driveDocIds,
        meta: {
            replyCommentId: getReplyCommentId(state),
            replyAttachmentId: getReplyAttachmentId(state),
            replyText: getReplyText(state),
        }
    } as Partial<IComment>;

    commentModel.timeTracker = !!timeTrackerData;

    return commentModel;
};
