import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState } from '../../../../../../../types/types';
import { filterPanelBoardsSelector } from '../../../../../aside_panel/filterPanelBoard/store/selectors/filterPanelBoardsSelector';
import { getCurrentEditingFilterIdSelector } from '../../../../../aside_panel/filterPanelBoard/store/selectors/getCurrentEditingFilterIdSelector';
import { filterSelector } from '../../../../../aside_panel/filterPanelBoard/store/selectors/filterSelector';
import { getDefaultOrEditedFilterAction } from '../../../../../aside_panel/filterPanelBoard/helpers/getDefaultOrEditedFilterAction';
import { filterPanelActionSetAction } from '../../../../../aside_panel/filterPanelBoard/store/filterPanelBoards/actions/filterPanelActionSetAction';
import { keyWordSet } from '../../../../../aside_panel/filterPanelBoard/store/filter/actions/keyWordSet';
import { keywordFiltrationEnabledSet } from '../../../../../aside_panel/filterPanelBoard/store/filterPanel/actions/keywordFiltrationEnabledSet';
import { hideSet } from '../../../../../aside_panel/filterPanelBoard/store/filter/actions/hideSet';
import {
    FP_KEY_HIDE_FILTER_EMPTY_LIST,
    MY_WORK_FILTER_BOARD_ID
} from '../../../../../aside_panel/filterPanelBoard/constants';
import { closeFilterInfoPanel } from '../../../../../aside_panel/filterInfoPanel/hocs/effects/closeFilterInfoPanel';
import { panelOpenedSet } from '../../../../../aside_panel/filterPanelBoard/store/filterPanel/actions/panelOpenedSet';

export const searchFilter = (
    key: string,
    boardId: number,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const filterPanel = filterPanelBoardsSelector(state, boardId);
        const filterId = getCurrentEditingFilterIdSelector(filterPanel);
        const { searchedKeyWord } = filterSelector(state, boardId, filterId);
        const defaultOrEditedFilterAction = getDefaultOrEditedFilterAction(filterId);
        const isTopBarFiltrationEnabled = filterPanelBoardsSelector(state, MY_WORK_FILTER_BOARD_ID).isTopBarFiltrationEnabled
        if (!key) key = null;
        if (searchedKeyWord !== key) {
            if (!key) {
                dispatch(filterPanelActionSetAction(boardId, keywordFiltrationEnabledSet(false)));
                dispatch(filterPanelActionSetAction(boardId, defaultOrEditedFilterAction(hideSet([]))));
                if (!filterPanel.isEnabled && isTopBarFiltrationEnabled) {
                    dispatch(closeFilterInfoPanel(boardId));
                }
            } else {
                dispatch(filterPanelActionSetAction(boardId, panelOpenedSet(true)));
                dispatch(filterPanelActionSetAction(boardId, keywordFiltrationEnabledSet(true)));
                dispatch(filterPanelActionSetAction(boardId, defaultOrEditedFilterAction(hideSet([FP_KEY_HIDE_FILTER_EMPTY_LIST]))));
            }
            dispatch(filterPanelActionSetAction(boardId, defaultOrEditedFilterAction(keyWordSet(key))));
        }
    };
    return action;
};
