import { Dispatch, ThunkAction } from '../../../types/actions';
import { IGetState } from '../../../types/types';
import { IAuthUser } from '../../../store/model/authUser/types';
import { authUserPatch } from './api/helper/authUserPatch';
import { getAuthUser } from '../../../store/model/authUser/selectors/getAuthUser';

export const authUserSetHideDoneSubcards = (
    isHideDoneSubcards: boolean
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const userPatch: IAuthUser = {
            id: getAuthUser(state).id,
            meta: {
                isHideDoneSubcards
            }
        };
        return dispatch(authUserPatch(userPatch));
    };
    return action;
};
