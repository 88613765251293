import { AssigneesSectionHOC } from '../../hocs/AssigneesSectionHOC/AssigneesSectionHOC';
import { AsidePanelActionsButton } from '../../../..';
import * as React from 'react';
import { getPopupAttributes } from '../../../../../base/components/SettingsSection/constants';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { CARD_SECTION_ASSIGNEES } from '../constants';
import { IAssigneesPanelButtonProps } from './types';
import { CLASS_CARD_DETAILS_SECTION } from '../../../constants';
import { EAsidePanelProperty } from '../../../../asidePanel/components/AsidePanel/types';
import {
    CLASS_ASIDE_PANEL_ACTIONS_BUTTON_CONTAINER
} from '../../../../asidePanel/components/AsidePanelActionsButton/constants';
import { getAsidePanelActionsButtonTooltip } from 'app/view/react_components/aside_panel/asidePanel/helpers/getAsidePanelActionsButtonTooltip';

export function AssigneesPanelButton({
    assignedToDetailsCount,
    tooltip,
    isShow,
    onClick
}: IAssigneesPanelButtonProps) {
    if (!isShow) return null;

    const className = CLASS_ASIDE_PANEL_ACTIONS_BUTTON_CONTAINER + '--assignees';

    const { boardId, cardId, showedProperties, openedProperty } = React.useContext(AsidePanelContext);
    const isShowed = showedProperties.has(EAsidePanelProperty.CARD_ASSIGNEES) && openedProperty !== EAsidePanelProperty.CARD_ASSIGNEES;
    const [isOpened, setIsOpened] = React.useState(null);
    const onOpened = () => setIsOpened(true);
    const onClosed = () => setIsOpened(false);

    return (
        <AsidePanelActionsButton
            className={className}
            indicatorNumber={assignedToDetailsCount}
            icon={'user'}
            isOpened={isOpened}
            openedProperty={EAsidePanelProperty.CARD_ASSIGNEES}
            sectionSelector={`.${CLASS_CARD_DETAILS_SECTION}--assignees`}
            title={CARD_SECTION_ASSIGNEES}
            tooltip={tooltip && getAsidePanelActionsButtonTooltip({ value: tooltip })}
            onClick={onClick}
            onOpen={onOpened}
        >
            {!isShowed &&
                <AssigneesSectionHOC
                    boardId={boardId}
                    cardId={cardId}
                    onClose={onClosed}
                    {...getPopupAttributes()}
                />
            }
        </AsidePanelActionsButton>
    );
}
