import { createDeepEqualSelector } from '../../../../../helpers/memoizeHelper';
import { IApplicationState, TBoardId } from '../../../../../../../types/types';
import { ISearchSelectOption } from '../../../../../base/components/SearchSelect/types';
import { TStatus } from '../../../../../../../types/model';
import { getBoardCards } from '../../../../../../../store/model/selectors/getBoardCards';
import { getCardIsEpic } from '../../../../../../../store/model/selectors/getCardIsEpic';
import { getCardListName } from '../../../../../../../store/model/selectors/getCardListName';
import { getCardFullNameHelper } from '../../../../../../../store/model/card/helpers/getCardFullNameHelper';
import { clearMarkdown } from '../../../../../base/helpers/clearMarkdownHelper';
import { getCardIcon } from '../../../../../helpers/getCardIcon';
import { stripHtml } from '../../../../../base/helpers/stripHtmlHelper';
import { getAppState } from '../../../../../../../store/configureStore';
import { markdown } from '../../../../../../../helper/markdownHelper';
import { ICard } from 'app/store/model/card/types';
import { ILists } from 'app/store/model/lists/types';

type TgetSearchSelectCardOptions = (
    state: IApplicationState,
    boardId: TBoardId,
    status: TStatus
) => ISearchSelectOption[];

export const getSearchSelectCardOptions: TgetSearchSelectCardOptions = createDeepEqualSelector(
    getBoardCards,
    (state: IApplicationState) => state.model.lists,
    (cards: ICard[], lists: ILists) => {
        const options: ISearchSelectOption[]  = cards.map((card) => {
            const isEpic = getCardIsEpic(getAppState(), card.id);
            const listName = getCardListName(getAppState(), card.id);
            const cardFullName = getCardFullNameHelper(card);
            const cardComments = card.comments && card.comments.length ? card.comments.reduce((comments, comment) => {
                comments += comment.text;
                return comments;
            }, '') : ''
            return {
                fields: {
                    description: clearMarkdown(card.description),
                    comments: clearMarkdown(cardComments),
                },
                img: getCardIcon(card.status, isEpic),
                text: stripHtml(markdown(cardFullName)),
                textSub: stripHtml(markdown(listName)),
                value: String(card.id),
                lastActivity: card.lastActivityDateStamp
            };
        });
        return options;
    }
)
