import * as React from 'react';
import './_UpdatesModal.scss';
import { Modal } from 'kui';
import { IUpdatesModalProps } from './types';

export function UpdatesModal ({
    updates,
    onClose,
}: IUpdatesModalProps) {

    return (
        <Modal
            className={'handle-click-ignore'}
            title={''}
            variant={'release'}
            onClose={onClose}
            release={{
                footer: {
                    follow: {
                        socials: [
                            {
                                icon: 'facebook',
                                link: 'https://www.facebook.com/kanbanchiapp',
                                name: 'Facebook'
                            },
                            {
                                icon: 'twitter',
                                link: 'https://twitter.com/Kanbanchi',
                                name: 'Twitter'
                            },
                            {
                                icon: 'linkedin',
                                link: 'https://www.linkedin.com/company/kanbanchi',
                                name: 'LinkedIn'
                            },
                            {
                                icon: 'youtube',
                                link: 'https://www.youtube.com/user/kanbanchi',
                                name: 'YouTube'
                            },
                        ],
                        text: 'Follow us!'
                    },
                    stars: `Give us 5 stars on <a href="https://gsuite.google.com/marketplace/app/kanbanchi/631025100586" target="_blank">Marketplace</a>`,
                },
                slides: updates.map(update => {
                    return {
                        description: update.text,
                        src: update.media,
                        title: update.title,
                        variant: update.type === 'video' ? 'video' : 'img',
                    }
                }),
            }}
        />
    );
};
