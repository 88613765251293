import { IColor } from '../../../../types/model';
import { IApplicationState, TBoardId, TColorId } from '../../../../types/types';
import { getBoardColors } from './getBoardColors';

export const getBoardColorById = (
    state: IApplicationState,
    boardId: TBoardId,
    colorId: TColorId,
): IColor  => {
    return getBoardColors(state, boardId).find((color) => color.id === colorId);
}
