import { createRoot } from 'react-dom/client';
import React from "react";
import {dispatch} from "../../store/configureStore";
import {permissionScreenEvent} from "../../middlewares/segment/segmentEvents/userSegmentEvents";
import { LazyWrapper } from '../react_components/lazy/lazy_wrapper';

App.Views.RegistrationDialogView = App.Views.BaseView.extend({
    initialize: function() {
        this.root = createRoot(this.el);
    },

    render: function () {
        dispatch(permissionScreenEvent());

        const ImportElement = React.lazy(() =>
            import(/* webpackChunkName: "registration" */ '../dialog/registration_dialog')
                .then(module => ({default: module.RegistrationDialog}))
                .catch(e => console.error(e))
        );

        this.root.render(
            <LazyWrapper>
                <ImportElement callback={this.model.callback} />
            </LazyWrapper>
        );

        return this;
    },
    remove: function () {
        this.root.unmount();
        Backbone.View.prototype.remove.call(this);
    }
});
export default App.Views.RegistrationDialogView;
