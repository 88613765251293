import { IApplicationState } from '../../../../types/types';
import { getAuthUser, SNOOZE_BLOCKER_MINUTES, SNOOZE_BLOCKER_PRO_FEATURES } from './getAuthUser';
import { PRODUCTS_BY_PLAN } from '../../../../view/react_components/dialogs/price_page/constants';
import { EProductPlan } from '../../../../view/react_components/dialogs/price_page/types';
import { getBoardIsOnSharedDrive } from '../../selectors/getBoardIsOnSharedDrive';
import { getActiveBoardId } from '../../selectors/getActiveBoardId';
import { IAuthUser } from '../types';

export const getIsUserStarterPlanOnly = (
    state: IApplicationState
): boolean => {
    const user = getAuthUser(state);
    const snoozeBlockerTime = user.meta && user.meta.snoozeBlockerTime;
    const isSnoozeActive = snoozeBlockerTime && Date.now() - snoozeBlockerTime < SNOOZE_BLOCKER_MINUTES * 60 * 1000;
    let isStarterPlan = false;
    const subscriptions = user.activeSubscription;
    if ( // KNB-3493 сейчас показываем новый дизайн и плашки pro и стартеру и без подписки легаси юзерам
        !subscriptions.length &&
        !getBoardIsOnSharedDrive(state, getActiveBoardId(state)) &&
        !isSnoozeActive &&
        !user[SNOOZE_BLOCKER_PRO_FEATURES[0] as keyof IAuthUser] // проверяем, что это не фримиум
    ) {
        return true;
    }
    for (let i in subscriptions) {
        const { productId } = subscriptions[i];
        if (PRODUCTS_BY_PLAN[EProductPlan.PREMIUM].includes(productId)) {
            isStarterPlan = true;
        } else if ([...PRODUCTS_BY_PLAN[EProductPlan.PROFESSIONAL], ...PRODUCTS_BY_PLAN[EProductPlan.ENTERPRISE]].includes(productId)) {
            isStarterPlan = false;
            break;
        }
    }
    return isStarterPlan;
};
