import { NAVIGATION_PANEL_ASIDE_CLASS, NAVIGATION_PANEL_CONTAINER_CLASS } from 'app/view/react_components/main/navigationPanel/constants';
import { Button, ButtonDropdown, Icon, ITooltipInheritedProps } from 'kui';
import * as React from 'react';
import { TOOLTIP_HELP } from './constants';
import {INavPanelHelpProps} from './types';
import FocusLock from 'react-focus-lock';
import './_NavPanelHelp.scss';
import { HelpPanelContainerHOC } from 'app/view/react_components/aside_panel/help/hocs/HelpPanelContainerHOC/HelpPanelContainerHOC';

export function NavPanelHelp ({
    isOpened,
    isReturnFocus,
    onDidMount,
    onOpen,
    onClose,
}: INavPanelHelpProps) {

    const className = 'helppanel';

    const [_isOpened, setIsOpened] = React.useState(isOpened);
    const onOpened = () => {
        setIsOpened(true);
        onOpen();
    }
    const onClosed = () => {
        setIsOpened(false);
        onClose();
    }

    React.useEffect(() => {
        setIsOpened(isOpened);
    }, [isOpened]);

    React.useEffect(() => {
        onDidMount();
    }, []);

    return (
        <ButtonDropdown
            className={``}
            directionVertical={'down'}
            dropdownClassName={`${NAVIGATION_PANEL_ASIDE_CLASS} `}
            opened={_isOpened}
            portal
            portalId={'navigation-aside-portal'}
            portalSelector={'.' + NAVIGATION_PANEL_CONTAINER_CLASS}
            onOpen={onOpened}
            onClose={onClosed}
        >
            <Button
                className={'navigation-panel__help'}
                tooltip={{
                    className: 'navigation-panel__tooltip',
                    direction: 'right',
                    value: TOOLTIP_HELP
                } as ITooltipInheritedProps}
                variant={'icon'}
            >
                <Icon size={24} xlink={'help'} />
            </Button>

            <FocusLock
                returnFocus={isReturnFocus}
            >
                <div
                    className={className}
                    tabIndex={0} // сюда FocusLock поставит фокус при открытии
                >
                    <div className={`${className}__header`}>
                        {TOOLTIP_HELP}
                        <Button
                            className={`${className}__close`}
                            variant={'icon'}
                            aria-label={'Close'}
                            onClick={onClosed}
                        >
                            <Icon
                                size={24}
                                xlink="close"
                            />
                        </Button>
                    </div>
                    <div className={`${className}__body handle-click-ignore`} >
                        <HelpPanelContainerHOC />
                    </div>
                </div>
            </FocusLock>

        </ButtonDropdown>
    );
};
