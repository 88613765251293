'use strict';

import { getAppState } from '../../store/configureStore';
import { getUserTimeFormat12 } from '../../store/model/authUser/selectors/getUserTimeFormat12';
import { getActivityItem } from "../react_components/aside_panel/boardDetails/activity/TabActivity/helpers/aсtivityHelper";
import { ERestActivityPropertyActionType } from '../../types/rest/activity/IRestActivityBase';
import { getDateFormatted } from '../react_components/helpers/dateService';
import { ECustomPropertyType } from '../../store/customProperties/types';
import { blockMarkdown } from '../../helper/markdownHelper';

App.Views.CardUpdateCustomNotification = App.Views.BaseNotification.extend({

    getActions: function() {
        const item = getActivityItem(this.notificationModel);
        const isUserTimeFormat12 = getUserTimeFormat12(getAppState());
        const { oldValue, newValue, propertyActionType, customProperty } = this.notificationModel;
        let value = newValue || oldValue;

        if (customProperty.type === ECustomPropertyType.DATE){
            value = getDateFormatted(Number(value)/1000, isUserTimeFormat12);
        }
        if (customProperty.type === ECustomPropertyType.SELECT){
            let selectedOption = customProperty.options.find( option => option.id === value)
            value = selectedOption.value;
        }
        value = blockMarkdown(value);
        if (!propertyActionType || propertyActionType === ERestActivityPropertyActionType.DELETE) {
            value = '<strike>' + value + '</strike>';
        }
        return {
            bodyRaw: value,
            action: item.label,
        };
    },

    onClick: function(e, notification) {
        var dashboardId = this.notificationModel.dashboardId;
        var cardId = this.notificationModel.card.id;
        App.controller.cleverOpenCard(dashboardId, cardId);
        this.closeNotification(notification);
        e.stopPropagation();
    }
});
