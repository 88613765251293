import { getMessages } from 'app/store/constants';

export const PROFILE_TRIAL_KEYS = [
    'profile.subscription.trial_day',
    'profile.subscription.trial_days',
    'profile.subscription.trial_last',
];

export const PROFILE_TRIAL_DISCOUNT_KEYS = [
    'profile.subscription.trial_day.discount',
    'profile.subscription.trial_days.discount',
    'profile.subscription.trial_last.discount',
];

export const PROFILE_BUTTON_UPGRADE = getMessages().getText('common.upgrade');
export const PROFILE_BUTTON_LICENSE = getMessages().getText('common.request_license');

export const PROFILE_DATE_ACTIVE = getMessages().getText('profile.subscription.paymentExpDate.active');
export const PROFILE_DATE_MANUALLY = getMessages().getText('profile.subscription.paymentExpDate.manually');
export const PROFILE_DATE_CANCELED = getMessages().getText('profile.subscription.paymentExpDate.canceled');

export const PROFILE_ADMIN = getMessages().getText('profile.subscriptions.admin');
export const PROFILE_ADMINS = getMessages().getText('profile.subscriptions.admins');
