import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from '../../../store/configureStore';
import { TutorialHOC } from '../tutorial/hocs/ToturialHOC/TutorialHOC';
import { ETutorial } from '../../../types/rest/IUserMeta';

export class Tutorials {
    el: HTMLElement;

    constructor (tutorial: ETutorial) {
        if (this.el) this.remove();
        this.el = document.createElement('div');
        this.el.className = 'tutorials-placeholder';
        document.body.append(this.el);
        this.render(tutorial);
    }

    render (tutorial: ETutorial) {
        ReactDOM.render(
            <Provider store={store}>
                <TutorialHOC tutorial={tutorial} />
            </Provider>
            , this.el);
    }

    remove () {
        if (!this.el) return;

        ReactDOM.unmountComponentAtNode(this.el);
        this.el.remove();
        this.el = null;
    }
}
