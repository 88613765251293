'use strict';

App.Views.CardUpdateDescriptionNotification = App.Views.BaseNotification.extend({

    getActions: function() {
        const action = Messages.getText(this.notificationModel.propertyActionType == 'add' ?'noty.card_added_description' : 'noty.card_changed_description');
        return {
            body: this.notificationModel.newValue,
            action,
            actionData: {
                icon: 'description'
            }
        };
    },

    onClick: function(e, notification) {
        var dashboardId = this.notificationModel.dashboardId;
        var cardId = this.notificationModel.card.id;
        App.controller.cleverOpenCard(dashboardId, cardId);
        this.closeNotification(notification);
        e.stopPropagation();
    }
});
