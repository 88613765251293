import { IModelUpdateAction, MODEL_UPDATE, TModelUpdate } from './types';

export const modelUpdate = (
    model: TModelUpdate
): IModelUpdateAction => {
    return {
        type: MODEL_UPDATE,
        model,
    }
};
