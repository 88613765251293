import {ActionCreator} from 'redux';
import {Dispatch, ThunkAction} from '../../../../../../../types/actions';
import { extendTrialLinkClickedSegmentEvent } from '../../../../../../../middlewares/segment/helpers/blockersSegmentEvents';
import { ESegmentBlockerType } from '../../../../../../../middlewares/segment/trackEntities/blockersEvents';

export const onOpenTrialExtend: ActionCreator<ThunkAction> = (
) => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(extendTrialLinkClickedSegmentEvent(ESegmentBlockerType.AFTER_FIRST_TRIAL));
    };
    return action;
};
