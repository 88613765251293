import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../types/types';
import { TopBarCollaborators } from '../../components/TopBarCollaborators/TopBarCollaborators';
import { ITopBarCollaboratorsEvents, ITopBarCollaboratorsFields } from '../../components/TopBarCollaborators/types';
import { ITopBarCollaboratorsHOCProps } from './types';
import { onClick } from './events/onClick';
import { getBoardCollaboratorsCreateSelector } from './selectors/getBoardCollaboratorsSelector';

// const analyze = new Analyze('TopBarCollaboratorsHOC');
// TopBarCollaboratorsHOC average: 0.011 ms, calls: 102, total: 1.100 ms // открыл-закрыл 5 карт
// TopBarCollaboratorsHOC average: 0.002 ms, calls: 102, total: 0.200 ms // reselect

const mapStateToProps = () => {
    const _getBoardCollaborators = getBoardCollaboratorsCreateSelector();

    return (
        state: IApplicationState,
        props: ITopBarCollaboratorsHOCProps,
    ): ITopBarCollaboratorsFields => {
        // analyze.start();

        const { boardId } = props;
        const collaborators = _getBoardCollaborators(state, boardId);

        // analyze.finish();

        // return {
        //     // collaborators: collaborators.reverse(),
        //     collaborators: [
        //         {
        //             activeCardId: null,
        //             color: 'hsl(336, 60%, 34%)',
        //             displayName: 'Danila Ivanov',
        //             id: 'pjwTrtyTfwYYn2DnA1gp_114995689335812344440',
        //             initials: 'DI',
        //             photoUrl: 'https://lh3.googleusercontent.com/a-/AOh14GiM1PNJkorwTVeA5AN1n2zat0MCv20MXDHf8GZI=s96-c',
        //             sessionId: '',
        //             userId: '114995689335812344440',
        //         },
        //         {
        //             activeCardId: null,
        //             color: 'hsl(306, 10%, 34%)',
        //             displayName: 'ustric0',
        //             id: 'e8cc8b773d0a4b79562a6c285dc18b1e',
        //             initials: 'u',
        //             photoUrl: 'https://lh3.googleusercontent.com/a-/AOh14GhstIPsBrha8IMr0cWGcXECWnsGJd0mw_5a0sLt\u003ds96-c',
        //             sessionId: '',
        //             userId: '114995689335812344440',
        //         },
        //         {
        //             activeCardId: null,
        //             color: 'hsl(136, 20%, 34%)',
        //             displayName: 'Sea Duck',
        //             id: 'd23c8afcd87a9060553a746e1b9b6e52',
        //             initials: 'SD',
        //             photoUrl: 'https://lh3.googleusercontent.com/a-/AOh14GjU8nzZCEji1jzFVvS1bc5D4RLSK8aBhzDYxuz0\u003ds64',
        //             sessionId: '',
        //             userId: '114995689335812344440',
        //         },
        //         {
        //             activeCardId: null,
        //             color: 'hsl(236, 40%, 34%)',
        //             displayName: 'Alexey Fominykh',
        //             id: '29a282b0f5b17616539f57ca8458ab0c',
        //             initials: 'AF',
        //             photoUrl: 'https://lh3.googleusercontent.com/a-/AOh14Gj_a5qs0T6M8UMEGR45BrLXhIw42qk5EemVNqUT\u003ds96-c',
        //             sessionId: '',
        //             userId: '114995689335812344440',
        //         },
        //         {
        //             activeCardId: 5494992646832128,
        //             color: 'hsl(91, 44%, 34%)',
        //             displayName: 'Andrey Philippov',
        //             id: '_SB_m6hJJyA3eEL7A1ku_105883057263429094887',
        //             initials: 'AP',
        //             photoUrl: 'https://lh3.googleusercontent.com/a-/AOh14GjGQV4dHW12ez_l-5ltx2IN6uTPb7I3hILDaTp4=s96-c',
        //             sessionId: '_SB_m6hJJyA3eEL7A1ku',
        //             userId: '105883057263429094887',
        //         },
        //         {
        //             activeCardId: null,
        //             color: 'hsl(36, 40%, 34%)',
        //             displayName: 'Leonid Shevtsov',
        //             id: '496ffe454e85497af3da871b5d6ca953',
        //             initials: 'LS',
        //             photoUrl: 'https://lh3.googleusercontent.com/a-/AOh14GiabmnRuO-gRDvWQftJk-N6X-EbdOvuTK607G0q',
        //             sessionId: '',
        //             userId: '114995689335812344440',
        //         },
        //         {
        //             activeCardId: null,
        //             color: 'hsl(116, 40%, 34%)',
        //             displayName: 'Ilia Zubkov',
        //             id: '5d27624611974d8e9f51935424ca41aa',
        //             initials: 'IZ',
        //             photoUrl: 'https://lh3.googleusercontent.com/a-/AOh14GgGn9VJry0ioliEaitxQq0Y86btST2Foh6odm8Y',
        //             sessionId: '',
        //             userId: '114995689335812344440',
        //         },
        //         {
        //             activeCardId: null,
        //             color: 'hsl(146, 40%, 34%)',
        //             displayName: 'Ilia Khabibullin',
        //             id: 'bee48a7383a67e4f490d8225b6f772e3',
        //             initials: 'IZ',
        //             photoUrl: 'https://lh4.googleusercontent.com/-Cd_oByGPiug/AAAAAAAAAAI/AAAAAAAAAAA/ACHi3rd02_V1iUuYrl7LRnwarquAdjyMqQ/photo.jpg',
        //             sessionId: '',
        //             userId: '114995689335812344440',
        //         },
        //         {
        //             activeCardId: null,
        //             color: 'hsl(224, 73%, 44%)',
        //             displayName: 'Megan',
        //             id: 'jVZrNesO3lG05nhmAEGZ_0a0cf033-8a5f-4c0a-9e85-e0881e18ca0b',
        //             initials: 'MB',
        //             photoUrl: '',
        //             sessionId: 'jVZrNesO3lG05nhmAEGZ',
        //             userId: '0a0cf033-8a5f-4c0a-9e85-e0881e18ca0b',
        //         },
        //     ].reverse(),
        // }

        return {
            collaborators,
        }

        // return {
        //     collaborators: [ // DELETE AFTER TEST
        //     {
        //       'id': '-wxR2KOUMfbWkGluBEBi_110258114549198615354',
        //       'sessionId': '-wxR2KOUMfbWkGluBEBi',
        //       'userId': '110258114549198615354',
        //       'displayName': 'Vladislav Zazorin',
        //       'color': 'hsl(294, 55%, 42%)',
        //       'photoUrl': 'https://lh3.googleusercontent.com/a/AEdFTp7hTAd1QtdiAVBaFQV2IvjC2fTJd3XCsRcjK3RJ=s96-c',
        //       'activeCardId': 5150095555297280
        //     },
        //     {
        //       'id': 'Ud0MRFnqY5OyKtFhBELy_104865359058274035647',
        //       'sessionId': 'Ud0MRFnqY5OyKtFhBELy',
        //       'userId': '104865359058274035647',
        //       'displayName': 'Kirill Isachenko',
        //       'color': 'hsl(275, 64%, 44%)',
        //       'photoUrl': 'https://lh3.googleusercontent.com/a/AEdFTp6gHAqK0nbuuMXf4tMjkfqwjTESLnX_rfPxG7OC=s96-c',
        //       'activeCardId': null
        //     },
        //     {
        //       'id': 'SUdoPsmXPc-vXjaSBELj_115987930694128128545',
        //       'sessionId': 'SUdoPsmXPc-vXjaSBELj',
        //       'userId': '115987930694128128545',
        //       'displayName': 'Danila Ivanov',
        //       'color': 'hsl(64, 56%, 46%)',
        //       'photoUrl': 'https://lh3.googleusercontent.com/a/AEdFTp4GXAKCbM5E9rVuVfL-113AyYP-cLc5Schb_EXb=s96-c',
        //       'activeCardId': null
        //     },
        //     {
        //       'id': 'oTcheQ_ti38XyAKcBEHo_106563154810955061737',
        //       'sessionId': 'oTcheQ_ti38XyAKcBEHo',
        //       'userId': '106563154810955061737',
        //       'displayName': 'Andrey Seragov',
        //       'color': 'hsl(336, 50%, 44%)',
        //       'photoUrl': 'https://lh3.googleusercontent.com/a/AEdFTp7yM96Frl1xgN7sVPPGws0MDt5lxmaitXDO5OGs=s96-c',
        //       'activeCardId': 6316359851048960
        //     },
        //     {
        //       'id': 'CtSE1uMwItobz3DjBD0j_101751181802164705563',
        //       'sessionId': 'CtSE1uMwItobz3DjBD0j',
        //       'userId': '101751181802164705563',
        //       'displayName': 'Ilya Khabibullin',
        //       'color': 'hsl(272, 60%, 39%)',
        //       'photoUrl': 'https://lh3.googleusercontent.com/a/AEdFTp7rmYEZNeVqS0Vj3g-6J2F0hMB-wx6YULh7-m6U=s96-c',
        //       'activeCardId': null
        //     },
        //     {
        //       'id': '17fqtB9dfQG5Vng-BDjx_112228123042054489745',
        //       'sessionId': '17fqtB9dfQG5Vng-BDjx',
        //       'userId': '112228123042054489745',
        //       'displayName': 'Arkady Chakhalyan',
        //       'color': 'hsl(118, 74%, 46%)',
        //       'photoUrl': 'https://lh3.googleusercontent.com/a/AEdFTp68EJCo1e1pfLJh4_MgBhkW3lPZ1xG3mi6o0sOA=s96-c',
        //       'activeCardId': null
        //     }
        //   ],
        // }
    };
};

const mapDispatchToProps = (
    dispatch: any
): ITopBarCollaboratorsEvents => {
    return {
        onClick: (collaborator) => dispatch(onClick(collaborator))
    }
}

export const TopBarCollaboratorsHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(TopBarCollaborators);
TopBarCollaboratorsHOC.displayName = 'TopBarCollaboratorsHOC';
