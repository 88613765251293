import * as React from 'react';
import './_Calendar.scss';
import { ICalendarProps } from './types';
import { CLASS_DATEPICKER } from '../constants';
import {
    CALENDAR_TODAY,
    CALENDAR_WEEK_DAYS, CALENDAR_WEEK_DAYS_SUNDAY_START,
    ESTIMATED_MONTH_HEIGHT,
    MONTH_HEADER_HEIGHT,
    MONTH_LIST_HEIGHT, MONTH_LIST_MIDDLE,
    MONTH_LIST_SIZE,
    MONTH_LIST_WIDTH,
    MONTH_WEEK_HEIGHT, MONTH_WEEK_MARGIN_TOP
} from './constants';
import { CalendarMonth } from '../CalendarMonth/CalendarMonth';
import { ListOnScrollProps, VariableSizeList as VirtualList } from 'react-window';
import { getInitialDate } from './helpers/getInitialDate';
import { getIndexFromDate } from './helpers/getIndexFromDate';
import { isNumber } from 'underscore';
import { Button, Icon } from 'kui';
import { GOOGLE_SPACING } from '../../../../../../../const';
import { ONE_DAY_LENGTH } from '../../../../../aside_panel/filterPanelBoard/constants';
import { getDeepDateCheck } from '../../helpers/getDeepDateCheck';
import { getDateWithoutTime } from '../../helpers/getDateWithoutTime';
import { DatepickerContext } from '../Datepicker/constants';

export const Calendar = ({
    date,
    fromDate,
    isNoFocus,
    maxDate,
    maxFromDate,
    minDate,
    minToDate,
    toDate,
    onArrowPress,
    onBlur,
    onChange,
    onDateType
}: ICalendarProps) => {
    const {
        isTime,
        isAllowSameDate,
        isCalendarStartsSunday
    } = React.useContext(DatepickerContext);

    const className = CLASS_DATEPICKER + '__calendar';
    const classHeader = className + '-header';
    const classDot = classHeader + '-dot';

    const initialDate = date || new Date();
    const initialMonth = initialDate.getMonth();
    const initialYear = initialDate.getFullYear();

    const [month, setMonth] = React.useState(initialMonth);
    const [year, setYear] = React.useState(initialYear);
    const [headIndex, setHeadIndex] = React.useState(MONTH_LIST_MIDDLE);
    const [day, setDay] = React.useState(null);

    const listRef = React.useRef(null);
    const isNoScrollNeeded = React.useRef(null);

    const today = new Date();
    const todayMonth = today.getMonth();
    const todayYear = today.getFullYear();

    const current = getInitialDate(initialDate, headIndex);
    const currentMonth = current.getMonth();
    const currentYear = current.getFullYear();

    const onKeyDown = (e: React.KeyboardEvent) => {
        onDateType(e);
        const week = ONE_DAY_LENGTH * 7;
        let newDay = day;
        const newDayWithoutTime = getDateWithoutTime(day);
        const maxFromDateWithoutTime = getDateWithoutTime(maxFromDate).getTime();
        const minToDateWithoutTime = getDateWithoutTime(minToDate).getTime();
        if ( // if selectedDate or today are not visible or disabled
            !document.querySelector('.' + className + '-month-day--' + newDayWithoutTime.getTime()) ||
            !getDeepDateCheck(newDayWithoutTime, fromDate, toDate, isTime || isAllowSameDate) ||
            minToDate && newDayWithoutTime.getTime() >= minToDateWithoutTime ||
            maxFromDate && newDayWithoutTime.getTime() <= maxFromDateWithoutTime
        ) {
            let newPossibleDate = new Date(newDay);
            let day = 1; // first day of the current month by default
            if (
                (fromDate && !getDeepDateCheck(newPossibleDate, fromDate, null, isTime || isAllowSameDate)) ||
                (maxFromDate && newPossibleDate <= maxFromDate)
            ) { // selected day is disabled
                newPossibleDate = new Date(fromDate);
                day = isTime ? newPossibleDate.getDate() : newPossibleDate.getDate() + 1;
            }
            if (
                (toDate && !getDeepDateCheck(newPossibleDate, null, toDate, isTime || isAllowSameDate)) ||
                (minToDate && newPossibleDate >= minToDate)
            ) { // selected day is disabled
                newPossibleDate = new Date(toDate);
                day = isTime ? newPossibleDate.getDate() : newPossibleDate.getDate() - 1;
            }
            newPossibleDate.setMonth(month);
            newPossibleDate.setFullYear(year);
            newPossibleDate.setDate(day);
            newDay = getDateWithoutTime(newPossibleDate);
            setDay(newDay.getTime());
        }
        if (e.key === 'ArrowDown') {
            newDay += week;
            onArrowPress();
        } else if (e.key === 'ArrowUp') {
            newDay -= week;
            onArrowPress();
        } else if (e.key === 'ArrowRight') {
            e.preventDefault(); // prevent kanban scroll
            newDay += ONE_DAY_LENGTH;
            onArrowPress();
        } else if (e.key === 'ArrowLeft') {
            e.preventDefault(); // prevent kanban scroll
            newDay -= ONE_DAY_LENGTH;
            onArrowPress();
        }
        const dateWithoutTime = getDateWithoutTime(newDay);
        if (
            !getDeepDateCheck(dateWithoutTime, fromDate, toDate, isTime || isAllowSameDate) ||
            maxFromDate && dateWithoutTime.getTime() <= maxFromDateWithoutTime ||
            minToDate && dateWithoutTime.getTime() >= minToDateWithoutTime
        ) {
            return;
        }
        setDay(dateWithoutTime.getTime());
    };

    const onScrollToItem = (
        month: number,
        year: number
    ) => {
        if (!document.activeElement || document.activeElement === document.body) {
            const calendar = document.querySelector('.' + className) as HTMLElement;
            if (calendar) {
                calendar.focus();
            }
        }
        const newDate = new Date(initialDate);
        newDate.setDate(1);
        newDate.setMonth(month);
        newDate.setFullYear(year);
        listRef.current.scrollToItem(getIndexFromDate(initialDate, newDate), 'start');
    };

    const onMonthChange = (month: number, ownYear?: number) => {
        onScrollToItem(month, ownYear ? ownYear : year);
    };

    const onYearChange = (year: number) => {
        onScrollToItem(month, year);
    };

    const getItemSize = (index: number) => {
        const date = getInitialDate(initialDate, index);
        const month = date.getMonth();
        let numberOfWeeks = 0;
        for (let i = 1;; i++) {
            const day = date.getDay()
            if (date.getMonth() !== month) {
                if (isCalendarStartsSunday ? day !== 0 : day !== 1) numberOfWeeks += 1;
                break;
            }
            if (isCalendarStartsSunday ? day === 6 : !day) {
                numberOfWeeks += 1;
            }
            date.setDate(i + 1);
        }
        return MONTH_HEADER_HEIGHT + MONTH_WEEK_HEIGHT * numberOfWeeks + MONTH_WEEK_MARGIN_TOP * (numberOfWeeks - 1);
    };

    const onScroll = (e: ListOnScrollProps) => {
        let scroll = GOOGLE_SPACING * -2.75;
        for (let i = 0; i <= MONTH_LIST_SIZE; i++) {
            scroll += getItemSize(i);
            if (scroll >= e.scrollOffset) {
                setHeadIndex(i);
                break;
            }
        }
    };

    const onTodayClick = () => {
        const today = new Date();
        onScrollToItem(today.getMonth(), today.getFullYear());
        const calendar = document.querySelector('.' + className) as HTMLElement;
        if (calendar) {
            calendar.focus();
        }
    };

    const onPrevMonth = () => {
        onMonthChange(month - 1, month === 0 ? year - 1 : null);
    };

    const onNextMonth = () => {
        onMonthChange(month + 1, month === 11 ? year + 1 : null);
    };

    React.useEffect(() => {
        if (!isNumber(headIndex)) return;
        const date = getInitialDate(initialDate, headIndex);
        setYear(date.getFullYear());
        setMonth(date.getMonth());
    }, [headIndex]);

    React.useEffect(() => {
        if (listRef.current) {
            listRef.current.resetAfterIndex(0);
            const month = date ? date.getMonth() : new Date().getMonth();
            const year = date ? date.getFullYear() : new Date().getFullYear();
            onScrollToItem(month, year);
        }
        isNoScrollNeeded.current = true;
        setTimeout(() => {
            const day = date ? getDateWithoutTime(date).getTime() : getDateWithoutTime(new Date).getTime();
            setDay(day);
        }, 0);
    }, [date]);

    React.useEffect(() => {
        if (isNoFocus || day === getDateWithoutTime(date)) return;
        const dayEl = document.querySelector('.' + className + '-month-day--' + day) as HTMLElement;
        if (dayEl) {
            if (!isNoScrollNeeded.current) {
                const scrollEl = document.querySelector('.' + className + '-months') as HTMLElement;
                const headEl = dayEl.parentElement.parentElement.firstElementChild as HTMLElement;
                const dayRect = dayEl.getBoundingClientRect();
                const headRect = headEl.getBoundingClientRect();
                if (dayRect.top < headRect.bottom) {
                    const scrollTop = headRect.bottom - dayRect.top; // not visible part of element
                    scrollEl.scrollTop -= scrollTop + MONTH_HEADER_HEIGHT; // scroll header
                }
            }
            isNoScrollNeeded.current = false;
            dayEl.focus();
        }
    }, [day]);

    const weekDays= isCalendarStartsSunday ? CALENDAR_WEEK_DAYS_SUNDAY_START : CALENDAR_WEEK_DAYS;

    return (
        <div
            className={className}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            tabIndex={0}
        >
            <div className={classHeader}>
                {weekDays.map(day => <div className={classHeader + '-item'} key={day}>{day}</div>)}
            </div>
            <div className={className + '-actions'}>
                {(currentMonth !== todayMonth || currentYear !== todayYear) &&
                    <Button
                        className={classDot + '-button'}
                        variant={'icon'}
                        tooltip={{
                            isNoWrap: true,
                            isNoEvents: true,
                            value: CALENDAR_TODAY
                        }}
                        onClick={onTodayClick}
                    >
                        <div className={classDot + '-wrapper'}>
                            <div className={classDot} />
                        </div>
                    </Button>
                }
                <Button
                    variant={'icon'}
                    onClick={onPrevMonth}
                >
                    <Icon xlink={'arrow-up'} size={24} />
                </Button>
                <Button
                    variant={'icon'}
                    onClick={onNextMonth}
                >
                    <Icon xlink={'arrow-down'} size={24} />
                </Button>
            </div>
            <div>
                <VirtualList
                    onScroll={onScroll}
                    className={className + '-months'}
                    itemCount={MONTH_LIST_SIZE}
                    itemData={{
                        date,
                        toDate,
                        fromDate,
                        headIndex,
                        maxDate,
                        minDate,
                        maxFromDate,
                        minToDate,
                        onChange,
                        onYearChange,
                        onMonthChange
                    }}
                    itemSize={getItemSize}
                    width={MONTH_LIST_WIDTH}
                    height={MONTH_LIST_HEIGHT}
                    estimatedItemSize={ESTIMATED_MONTH_HEIGHT}
                    overscanCount={10}
                    ref={listRef}
                >
                    {itemRenderer}
                </VirtualList>
            </div>
        </div>
    );
}

function itemRenderer ({
    data,
    index,
    style
}: IItemRendererProps) {
    const {
        date,
        toDate,
        fromDate,
        minDate,
        maxDate,
        maxFromDate,
        minToDate,
        headIndex,
        onChange,
        onMonthChange,
        onYearChange
    } = data;

    const initialDate = date || new Date();

    return (
        <div style={style} key={index}>
            <CalendarMonth
                date={getInitialDate(initialDate, index)}
                toDate={toDate}
                fromDate={fromDate}
                selectedDate={date}
                isHead={headIndex === index}
                isAbove={index < headIndex}
                isNoDate={!date}
                onChange={onChange}
                onMonthChange={onMonthChange}
                onYearChange={onYearChange}
                minDate={minDate}
                maxDate={maxDate}
                maxFromDate={maxFromDate}
                minToDate={minToDate}
            />
        </div>
    );
}

interface IItemRendererProps {
    index: number;
    style: React.CSSProperties;
    data: IItemData;
}

interface IItemData {
    date: Date;
    fromDate?: Date;
    headIndex: number;
    maxDate?: Date;
    maxFromDate?: Date;
    minDate?: Date;
    minToDate?: Date;
    toDate?: Date;
    onChange: (date: Date) => void;
    onMonthChange: (month: number) => void;
    onYearChange: (year: number) => void;
}
