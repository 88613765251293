import {Dispatch, ThunkAction} from '../../../types/actions';
import {segmentTrackAction} from '../actions/segmentTrackAction/segmentTrackAction';
import {root} from '../../../store/constants';
import {VirtualPage} from '../trackEntities/virtualPages';
import { SegmentCardCreatedByKeysSourceValue, SegmentCardEvent, SegmentCardOptions } from '../trackEntities/cardEvents';
import { isUserAccountTypeDomain } from 'app/store/model/authUser/selectors/isUserAccountTypeDomain';
import { IGetState } from 'app/types/types';
import { ISegmentOptions } from '../types';

export const createCardEvent = (
    options?: ISegmentOptions
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        const event = SegmentCardEvent.CREATE;
        let option = null;
        if (options) {
            if (options.ctrl) {
                option = {
                    name: SegmentCardOptions.KEYS,
                    value: SegmentCardCreatedByKeysSourceValue.CTRL_ENTER
                };
            } else if (options.shift) {
                option = {
                    name: SegmentCardOptions.KEYS,
                    value: SegmentCardCreatedByKeysSourceValue.SHIFT_ENTER
                };
            } else if (options.enter) {
                option = {
                    name: SegmentCardOptions.KEYS,
                    value: SegmentCardCreatedByKeysSourceValue.ENTER
                };
            }
        }
        dispatch(segmentTrackAction(event, option));
        root.App.controller.trackPage(VirtualPage.CREATE);
        root.App.controller.trackFacebookCustomEvent(VirtualPage.CREATE);

        if (isUserAccountTypeDomain(getState())) {
            dispatch(segmentTrackAction(SegmentCardEvent.CREATE_DOMAIN));
            //@ts-ignore
            gtag('event', 'conversion', {'send_to': 'AW-753015000/LEnfCIrzrqsYENixiOcC'});
        }
    };
    return action;
};
