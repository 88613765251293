import { TBoardId } from 'app/types/types';
import { firstBoardGet } from 'app/rest/effects/firstBoard/firstBoardGet';
import { IBoard } from 'app/store/model/board/types';
import { BoardLoadManager } from 'app/helper/BoardLoadManager';
import { dispatch, getAppState } from 'app/store/configureStore';
import { getBoard } from 'app/store/model/selectors/getBoardById';

export const firstBoardLoadToStore = (
    boardId: TBoardId,
): Promise<IBoard> => {
    const board = getBoard(getAppState(), boardId);
    if  (board.id){
        return Promise.resolve(board);
    }
    return dispatch(firstBoardGet(boardId))
        .then((board: IBoard) => {
            new BoardLoadManager().putBoardToStore(board, false)
            return board;
        })
}
