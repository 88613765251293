import * as React from 'react';
import './_QuickDates.scss';
import { IQuickDatesProps } from './types';
import { CLASS_DATEPICKER } from '../constants';
import { QUICK_DATES } from './constants';
import { Button } from 'kui';
import * as moment from 'moment';
import { getDeepDateCheck } from '../../helpers/getDeepDateCheck';
import { getDateWithoutTime } from '../../helpers/getDateWithoutTime';

export const QuickDates = ({
    fromDate,
    maxDate,
    maxFromDate,
    minDate,
    minToDate,
    toDate,
    onChange
}: IQuickDatesProps) => {
    const className = CLASS_DATEPICKER + '__quick-date';
    const setDisabled = (date: Date): boolean => {
        const dateWithNoTime = getDateWithoutTime(date);
        return (
            dateWithNoTime < minDate ||
            dateWithNoTime > maxDate ||
            !getDeepDateCheck(date, maxFromDate, minToDate) ||
            !getDeepDateCheck(date, fromDate, toDate)
        );
    };

    return (
        <div className={CLASS_DATEPICKER + '__quick-dates'}>
            {
                QUICK_DATES.map((quickDate, index) => (
                    <Button
                        key={index}
                        className={className}
                        onClick={() => onChange(quickDate.value)}
                        variant={'secondary'}
                        disabled={setDisabled(quickDate.value)}
                    >
                            <span className={className + '-title'}>
                                {quickDate.title}
                            </span>
                        <span className={className + '-date'}>
                                {quickDate.isWeekDay ?
                                    moment(quickDate.value).format('dddd').slice(0, 2)
                                    : moment(quickDate.value).format('MMM') + ' ' + quickDate.value.getDate()
                                }
                            </span>
                    </Button>
                ))
            }
        </div>
    );
}
