import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { TBoardId } from '../../../../../../../../../types/types';
import { loadPushNotificationsSettings } from '../../../../../../../../../rest/effects/pushNotificationSettings/api/helpers/loadPushNotificationsSettings';

export const onDidMount = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(loadPushNotificationsSettings(boardId));
    };
    return action;
};
