import * as React from 'react';
import './_comments-header.scss';
import { ICommentsHeaderProps } from './types';
import { Icon, Button, ButtonTitle } from 'kui';
import { CLASS_COMMENTS_SECTION, CLASS_COMMENTS_SECTION_TITLE } from '../../constants';
import { COMMENTS_ORDER_LABEL } from './constants';
import { EUserSettingsCommentSort } from '../../../../../../../types/rest/IRestAuthUser';

export function CommentsHeader({
    order,
    onChangeOrder
}: ICommentsHeaderProps) {
    const isAsc = order === EUserSettingsCommentSort.ASC;

    const wrapperName = CLASS_COMMENTS_SECTION + '__header';
    const titleClass = CLASS_COMMENTS_SECTION_TITLE;
    const titleTextClass = CLASS_COMMENTS_SECTION + '__label';
    const sorterClass = CLASS_COMMENTS_SECTION + '__sorter';
    const sorterClassAsc = isAsc ? sorterClass + '--asc' : '';
    const sorterIconClass = CLASS_COMMENTS_SECTION + '__sorter-icon';
    const sorterTitleClass = CLASS_COMMENTS_SECTION + '__sorter-title';

    const orderText = isAsc
        ? COMMENTS_ORDER_LABEL.ASC
        : COMMENTS_ORDER_LABEL.DESC;

    return (
        <div className={wrapperName}>
            <div className={titleClass}>
                <span
                    className={titleTextClass}
                >
                    Comments
                </span>
            </div>
            {!!order &&
                <Button
                    className={`
                        ${sorterClass}
                        ${sorterClassAsc}
                    `}
                    variant={'icon-text'}
                    onClick={onChangeOrder}
                >
                    <Icon
                        className={sorterIconClass}
                        xlink={'arrow-s-drop-close'}
                        size={24}
                    />
                    <ButtonTitle
                        className={sorterTitleClass}
                    >
                        {orderText}
                    </ButtonTitle>
                </Button>
            }
        </div>
    );
}
