import * as React from 'react';
import { IWebinarDialogProps } from './types';
import './_WebinarDialog.scss';
import {
    WEBINAR_FIRST_QUESTION,
    WEBINAR_GET_STARTED,
    WEBINAR_HIDE_TIMEOUT,
    WEBINAR_QUESTIONS_SUBTITLE,
    WEBINAR_QUESTIONS_TITLE,
    WEBINAR_SKIP,
} from '../../constants';
import { ModalPage } from '../../../../base/components/ModalPage';
import { getMessages } from '../../../../../../store/constants';
import { Button, IRadioActiveProps, Radio } from 'kui';
import { EWebinarAnswers, WEBINAR_QUESTIONS } from '../../constants/questions';
import { UserpicStorage } from 'app/view/react_components/base/components/UserpicStorage/UserpicStorage';

export function WebinarDialog ({
    fullName,
    initials,
    permissionId,
    photoUrl,
    onDidMount,
    onAnswer,
    onSkipped,
    onGetStarted,
    onVideoTutorialsClick
}: IWebinarDialogProps) {
    const className = 'webinar-dialog';
    const classBody = className + '__body';
    const classBodyItem = classBody + '-item';
    const classSection = className + '__section';
    const classSectionBody = classSection + '-body';
    const classSubtitle = className + '__sub-title';

    const [questionKey, setQuestionKey] = React.useState(WEBINAR_FIRST_QUESTION);
    const [skipped, setSkipped] = React.useState(false);
    const [answer, setAnswer] = React.useState<EWebinarAnswers>(null);

    const onAnswerHandler = (
        e: IRadioActiveProps
    ) => {
        const answerKeys = Object.keys(WEBINAR_QUESTIONS[questionKey].answers);
        const answerKey = answerKeys[e.index] as EWebinarAnswers;

        onAnswer(questionKey, answerKey)
        setAnswer(answerKey);
        setTimeout(() => {
            setQuestionKey(null);
        }, WEBINAR_HIDE_TIMEOUT)
    }

    const onSkipHandler = () => {
        onSkipped();
        setSkipped(true);
        setTimeout(() => {
            setQuestionKey(null);
        }, WEBINAR_HIDE_TIMEOUT)
    }

    const questionsScreen = !answer && !skipped;

    return (
        <ModalPage
            title={getMessages().getText('webinar.welcome', null, fullName)}
            onDidMount={onDidMount}
            className={className}
            showBackButton={false}
            rightContent={
                <UserpicStorage
                    initials={initials}
                    src={photoUrl}
                    size={40}
                    userId={permissionId}
                />
            }
        >
            <div className={classBody}>
                {!questionsScreen &&
                <div className={classBodyItem}>
                    <div className={className + '__title'}>
                        {
                            skipped || answer === EWebinarAnswers.no
                            ? <>Okay, not this time. Check out our <a onClick={onVideoTutorialsClick} target="_blank" href="https://www.youtube.com/playlist?list=PLeKebXXFFKM51iAjj5CYIY-W9JBGAMqYF">video tutorials</a> instead.</>
                            : <>We'll shortly send you an email with the link.<br /> Please, check your inbox.</>
                        }
                    </div>
                    <Button
                        size={'large'}
                        className={className + '__get-started'}
                        onClick={onGetStarted}
                    >
                        {WEBINAR_GET_STARTED}
                    </Button>
                </div>
                }
                <div className={`
                    ${classBodyItem}
                    ${!questionsScreen ? classBodyItem + '--prev-screen' : ''}
                `}>
                    <div className={className + '__title'}>{WEBINAR_QUESTIONS_TITLE}</div>
                    <div className={`
                        ${classSubtitle}
                        ${classSubtitle + '--double-trial'}
                    `}
                    >
                        {WEBINAR_QUESTIONS_SUBTITLE}
                    </div>
                    <div className={className + '__questions'}>
                        {
                            [questionKey].map((questionKeyToRender, index) => {
                                if (!questionKeyToRender) return null;
                                const question = WEBINAR_QUESTIONS[questionKeyToRender];
                                const answerKeys = Object.keys(question.answers);
                                return (
                                    <div
                                        className={`
                                            ${classSection}
                                            ${index === 1 ? classSection + '--prev-screen' : ''}
                                        `}
                                        key={questionKeyToRender + index}
                                    >
                                        <div className={classSectionBody}>
                                            <Radio
                                                key={questionKeyToRender}
                                                onChange={onAnswerHandler}
                                                direction={'left'}
                                            >
                                                {answerKeys.map(key => {
                                                    return <div key={questionKeyToRender + '-' + key}>{question.answers[key].answer}</div>
                                                })}
                                            </Radio>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className={className + '__footer'}>
                        <Button
                            variant={'icon-text'}
                            text={WEBINAR_SKIP}
                            onClick={onSkipHandler}
                        />
                    </div>
                </div>
            </div>
        </ModalPage>
    );
}
