import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState } from '../../../../../../../types/types';
import { updateQualificationFormMeta } from '../../../../../../../rest/effects/authUser/qualificationForm/updateQualificationFormMeta';
import {
    EUserMetaQualificationFormStatus,
    IUserMetaQualificationAnswers
} from '../../../../../../../types/rest/IUserMeta';
import { getUserQualificationFormAnswers } from '../../../../../../../store/model/authUser/selectors/getUserQualificationFormAnswers';
import { ISegmentEventOption } from '../../../../../../../middlewares/segment/trackEntities/types';
import { QUALIFICATION_QUESTIONS } from '../../../constants/questions';
import { segmentIdentifyAction, segmentTrackAction } from '../../../../../../../middlewares/segment';
import { SegmentQuestionEvent } from '../../../../../../../middlewares/segment/trackEntities/questionEvents';
import { SEGMENT_IDENTIFY_PREFIX } from '../../../constants';
import { Intercom, ONBOARD_HELP_ASKED, ONBOARD_MESSAGE_TYPE } from '../../../../../../../helper/intercom';
import { MESSAGE_TYPE_MAP, MESSAGE_TYPE_NO_SPAM, MESSAGE_TYPE_TEAM } from '../../../constants/messageType';
import { authUserQualificationFinished } from '../../../../../../../rest/effects/authUser/authUserQualificationFinished';
import { EQualificationAnswersType, TQualificationQuestionEvent } from '../../../components/QualificationDialog/types';
import { getAnswersEvents } from '../utils/getAnswersEvents';
import { loadUser } from '../../../../../../../rest/effects/authUser/loadUser';
import { updateUser } from '../../../../../../../store/model/authUser/effects/updateUser';
import { IAuthUser } from '../../../../../../../store/model/authUser/types';

export const onCompleted = (): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const answers = getUserQualificationFormAnswers(getState());
        const messageType = getMessageType(answers);

        const intercomHelpAsked = isIntercomHelpAsked(answers);
        dispatch(updateQualificationFormMeta({
            status: EUserMetaQualificationFormStatus.COMPLETE,
            intercomMessageType: messageType,
            intercomHelpAsked
        }));

        const options: ISegmentEventOption[] = [];
        const questionEvents = getAnswersEvents(answers);
        sendEvents(dispatch, questionEvents);
        questionEvents.forEach(questionEvent => {
           // dispatch(segmentTrackAction(questionEvent.event, questionEvent.option));
            options.push({
                name: questionEvent.event.event,
                value: questionEvent.option ? questionEvent.option.value : ''
            })
        })
        dispatch(segmentTrackAction(SegmentQuestionEvent.SUBMITTED, null, options));

        let identifyTraits = options.reduce<any>((result, option) => {
            result[SEGMENT_IDENTIFY_PREFIX + option.name] = option.value;
            return result;
        }, {});
        const intercomProperties = {
            [ONBOARD_MESSAGE_TYPE]: messageType,
            [ONBOARD_HELP_ASKED]: intercomHelpAsked
        }
        Intercom.sendProperties(intercomProperties); // обновить юзера с данными по опросу
        dispatch(loadUser()).then((user: IAuthUser) => { //обновить юзера с бека чтобы послать обновленное eduV2 свойство
            dispatch(updateUser(user));
            dispatch(segmentIdentifyAction({
                ...identifyTraits,
                ...intercomProperties // отправить в юзера данные по опросу
            }));

            dispatch(authUserQualificationFinished());
        })
    };
    return action;
};

const getMessageType = (answers: IUserMetaQualificationAnswers): string => {
    if (answers['work_tool'] && answers['work_tool'].key === 'work_tool_yes' &&
        answers['choosing_project'] && answers['choosing_project'].key === 'choosing_project_no') {
        return MESSAGE_TYPE_TEAM;
    }
    if (answers['work_hand_holding'] && answers['work_hand_holding'].checkboxKeys && answers['work_hand_holding'].checkboxKeys.includes('work_hand_holding_none')) {
        return MESSAGE_TYPE_NO_SPAM;
    }
    if (answers['edu_hand_holding'] && answers['edu_hand_holding'].checkboxKeys && answers['edu_hand_holding'].checkboxKeys.includes('edu_hand_holding_none')) {
        return MESSAGE_TYPE_NO_SPAM;
    }

    const edu = !!answers['edu_role'];
    let questionKeys;
    if (edu) {
        questionKeys = ['edu_key_factor', 'edu_role', 'edu_team_plan_to_use'];
    } else {
        questionKeys = ['work_key_factor', 'work_role', 'work_team_plan_to_use'];
    }
    const answerKeys = questionKeys.map(questionKey => {
        const question = QUALIFICATION_QUESTIONS[questionKey];
        if (question.answersType === EQualificationAnswersType.CHECKBOXES) {
            const answerKeys = [...Object.keys(question.answers)];
            answerKeys.sort((answerKey1, answerKey2) => {
                return question.answers[answerKey1].onBoardMessagePriority - question.answers[answerKey2].onBoardMessagePriority;
            })
            let findAnswerKey = '';
            answerKeys.forEach((answerKey) => {
                if (!findAnswerKey && answers[questionKey] && answers[questionKey].checkboxKeys && answers[questionKey].checkboxKeys.includes(answerKey)) {
                    findAnswerKey = answerKey;
                }
            })
            return findAnswerKey;
        } else {
            return answers[questionKey] ? answers[questionKey].key : ''
        }
    })
    for(let i=questionKeys.length; i>=1; i--) {
        const value = getMessageTypeByAnswerKeys(answerKeys, i);
        if (value) {
            return value;
        }
    }
    return '';
}

const getMessageTypeByAnswerKeys = (answerKeys: string[], keysCount: number): string => {
    const list = answerKeys.slice(0, keysCount);
    const key = list.join('|');
    return MESSAGE_TYPE_MAP[key] ? MESSAGE_TYPE_MAP[key] : MESSAGE_TYPE_MAP[key + '|'];
}

const isIntercomHelpAsked = (answers: IUserMetaQualificationAnswers) => {
    if (answers['work_hand_holding'] && answers['work_hand_holding'].key) {
        const checkboxList = answers['work_hand_holding'].checkboxKeys || [];
        return checkboxList.length > 0 && !checkboxList.includes('work_hand_holding_none');
    }
    if (answers['edu_hand_holding'] && answers['edu_hand_holding'].key) {
        const checkboxList = answers['edu_hand_holding'].checkboxKeys || [];
        return checkboxList.length > 0 && !checkboxList.includes('edu_hand_holding_none');
    }
    return false;
}

const sendEvents = (
    dispatch: Dispatch,
    questionEvents: TQualificationQuestionEvent[]
) => {
    let events = [...questionEvents];
    const segmentTrack = () => {
        const sendEvents = events.splice(0, 3);
        sendEvents.forEach((questionEvent) => {
            dispatch(segmentTrackAction(questionEvent.event, questionEvent.option));
        })
        if (events.length > 0) {
            setTimeout(() => {
                segmentTrack();
            }, 1000);
        }
    }
    segmentTrack();
}
