import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { root } from '../../../../../../../store/constants';
import { segmentTrackAction, SegmentUserEvent } from '../../../../../../../middlewares/segment';

export const onClose = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        root.App.vent.trigger(root.App.vent['dialog:closed']);
        dispatch(segmentTrackAction(SegmentUserEvent.AFFILIATE_CLOSED));
    };
    return action;
}
