import { notificationBeforeDueDateEditActionSetAction } from '../notificationBeforeDueDateEditsState/actions/notificationBeforeDueDateEditActionSetAction';
import { notificationSetAction } from '../notificationBeforeDueDateCardEditState/actions/notificationSetAction';
import { TCardId } from '../../../../../../../../types/types';
import { INotificationBeforeDueDate } from '../../../../../../../../store/model/notificationBeforeDueDate/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';

export const notificationsBeforeDueDatesForCardUpdate = (
    cardId: TCardId,
    notificationBeforeDueDates: INotificationBeforeDueDate[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(notificationBeforeDueDateEditActionSetAction(cardId, notificationSetAction(notificationBeforeDueDates)));
    };
    return action;
};
