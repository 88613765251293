import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../../types/types';
import { MyWorkBoards } from '../../components/MyWorkBoards/MyWorkBoards';
import { IMyWorkBoardsEvents, IMyWorkBoardsFields } from '../../components/MyWorkBoards/types';
import { onRemoveBoard } from './events/onRemoveBoard';
import { getMyWorkBoardsCreateSelector } from './selectors/getMyWorkBoards';
import { onFetchChange } from './events/onFetchChange';

const mapStateToProps = () => {
    const getMyWorkBoards = getMyWorkBoardsCreateSelector();

    const _mapStateToProps = (
        state: IApplicationState,
    ): IMyWorkBoardsFields => {
        return {
            showBoards: getMyWorkBoards(state),
        }
    };

    return _mapStateToProps;
}

const mapDispatchToProps = (
    dispatch: any,
): IMyWorkBoardsEvents => {
    return {
        onRemoveBoard: (boardId) => dispatch(onRemoveBoard(boardId)),
        onFetchChange: (boardId, option) => dispatch(onFetchChange(boardId, option))
    }
};

export const MyWorkBoardsHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(MyWorkBoards);

MyWorkBoardsHOC.displayName = 'MyWorkBoardsHOC';
