/**
 *  default prefix "FILTER_PANEL_"
 */
export const AT_ASSIGNED_TO_SET = 'FILTER_PANEL_ASSIGNED_TO_SET';
export const AT_ATTACHMENTS_SET = 'FILTER_PANEL_ATTACHMENTS_SET';
export const AT_COLORS_SET = 'FILTER_PANEL_AT_COLORS_SET';
export const AT_COMMENTS_SET = 'FILTER_PANEL_COMMENTS_SET';
export const AT_CREATED_SET = 'FILTER_PANEL_CREATED_SET';
export const AT_STARTED_SET = 'FILTER_PANEL_STARTED_SET';
export const AT_DONE_DATE_SET = 'FILTER_PANEL_DONE_DATE_SET';
export const AT_DUE_DATE_SET = 'FILTER_PANEL_DUE_DATE_SET';
export const AT_FILTER_ENABLED_SET = 'FILTER_PANEL_FILTER_ENABLED_SET';
export const AT_HIDE_SET = 'FILTER_PANEL_HIDE_SET';
export const AT_KEYWORD_SET = 'FILTER_PANEL_FILTER_KEYWORD_SET';
export const AT_NAME_SET = 'FILTER_PANEL_FILTER_NAME_SET';
export const AT_PRIORITY_SET = 'FILTER_PANEL_PRIORITY_SET';
export const AT_PUBLIC_SET = 'FILTER_PANEL_AT_PUBLIC_SET';
export const AT_SHOW_SET = 'FILTER_PANEL_SHOW_SET';
export const AT_SORT_CARDS_BY_SET = 'FILTER_PANEL_SORT_CARDS_BY_SET';
export const AT_TAGS_SET = 'FILTER_PANEL_TAGS_SET';
export const AT_CARD_COLORS_SET = 'FILTER_PANEL_AT_CARD_COLORS_SET';
export const AT_ROLES_SET = 'FILTER_PANEL_AT_ROLES_SET';
export const AT_META_SET = 'FILTER_PANEL_META_SET';
