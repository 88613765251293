import { IHintStepSetAction } from './types';
import { AT_STEP_SET } from './constants';

export const hintStepSetAction = (
    step: number
): IHintStepSetAction => ({
        type: AT_STEP_SET,
        step
    }
);
