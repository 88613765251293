import { AsidePanelActionsButton } from '../../../..';
import * as React from 'react';
import { ICardEstimateAndSpentTimePanelButton } from './types';
import { CLASS_CARD_DETAILS_SECTION } from '../../../constants';
import {
    CardEstimateAndSpentTimeSectionHOC
} from '../../hocs/CardEstimateAndTimeHOC/CardEstimateAndSpentTimeSectionHOC';
import { CLASS_ASIDE_PANEL_ACTIONS_POPUP } from '../../../../asidePanel/components/AsidePanelActionsButton/constants';
import { EAsidePanelProperty } from '../../../../asidePanel/components/AsidePanel/types';
import { getPopupAttributes } from '../../../../../base/components/SettingsSection/constants';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { getAsidePanelActionsButtonTooltip } from 'app/view/react_components/aside_panel/asidePanel/helpers/getAsidePanelActionsButtonTooltip';

export function CardEstimateAndSpentTimePanelButton({
    boardId,
    cardId,
    estimateFlag,
    isShow,
    tooltip,
    onClick
}: ICardEstimateAndSpentTimePanelButton) {
    if (!isShow) return null;

    const { showedProperties, openedProperty } = React.useContext(AsidePanelContext);
    const isShowed = showedProperties.has(EAsidePanelProperty.CARD_ESTIMATE) && openedProperty !== EAsidePanelProperty.CARD_ESTIMATE;
    const [isOpened, setIsOpened] = React.useState(null);
    const onOpened = () => setIsOpened(true);
    const onClosed = () => setIsOpened(false);
    return (
        <AsidePanelActionsButton
            dropdownClassName={`${CLASS_ASIDE_PANEL_ACTIONS_POPUP}--estimate`}
            indicatorBoolean={estimateFlag}
            icon={'estimate'}
            isOpened={isOpened}
            isIconHiddenOnFullSize={true}
            openedProperty={EAsidePanelProperty.CARD_ESTIMATE}
            sectionSelector={`.${CLASS_CARD_DETAILS_SECTION}--estimate`}
            sectionElementSelector={`.${CLASS_CARD_DETAILS_SECTION}--estimate`} // чтобы зафокусить инпут
            title={'Estimate'}
            tooltip={tooltip && getAsidePanelActionsButtonTooltip({ value: tooltip })}
            onClick={onClick}
            onOpen={onOpened}
        >
            {!isShowed &&
                <CardEstimateAndSpentTimeSectionHOC
                    boardId={boardId}
                    cardId={ cardId }
                    onClose={onClosed}
                    {...getPopupAttributes()}
                />
            }
        </AsidePanelActionsButton>
    );
}
