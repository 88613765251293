import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../types/types';
import { SegmentCardEvent, SegmentCardOptions, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { SegmentCardType } from '../../../../../../../../middlewares/segment/trackEntities/cardEvents';
import { ISegmentEventOption } from '../../../../../../../../middlewares/segment/trackEntities/types';

export const onCreate = (
    source: SegmentCardType,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const option: ISegmentEventOption = source && {
            name: SegmentCardOptions.TYPE,
            value: SegmentCardType.CARD_INPUT
        };
        dispatch(segmentTrackAction(SegmentCardEvent.COLOUR_TAGS_CREATE, option));
    };
    return action;
};
