import { IApplicationState, TBoardId } from '../../../../types/types';
import { IBackup } from '../types';

export const getBoardBackupList = (
    state: IApplicationState,
    boardId: TBoardId
): IBackup[]  => {
    if (
        !state.model.backups ||
        !state.model.backups[boardId] ||
        !state.model.backups[boardId].list
    ) return [];

    return [...state.model.backups[boardId].list];
}
