import * as React from 'react';
import './_CardChecklistEstimate.scss';
import { CLASS_CARD_DETAILS } from '../../../constants';
import { Input } from 'kui';
import { ICardChecklistEstimateProps } from './types';
import { getAsidePanelTooltip } from '../../../../asidePanel/helpers/getAsidePanelTooltip';
import {
    CARD_ESTIMATE_EXAMPLE,
    CARD_ESTIMATE_LABEL_TEXT,
    CARD_ESTIMATE_REMOVE_TOOLTIP
} from '../../../EstimateAndSpentTimeSection/components/CardEstimateAndSpentTimeSection/constants';
import {
    parseEstimate
} from '../../../EstimateAndSpentTimeSection/components/CardEstimateAndSpentTimeSection/behaviour/parseEstimate';
import { convertTimeNumberToString } from '../../../helpers/convertTimeNumberToString';

export function CardChecklistEstimate ({
    estimate,
    workHoursInDay,
    onChange,
    onClose,
    onSave,
}: ICardChecklistEstimateProps) {
    const [inputState, setInputState] = React.useState(null);
    const [showValid, setShowValid] = React.useState(null);
    const [estimateStr] = React.useState(convertTimeNumberToString(estimate, false, workHoursInDay));

    const className = CLASS_CARD_DETAILS + '__checklist-item-estimate-dropdown';
    const classInput = CLASS_CARD_DETAILS + '__checklist-item-estimate-input';

    const getSyntheticEventTargetValue = (
        e: React.SyntheticEvent,
    ): string => {
        const target = e.target as HTMLInputElement;
        return target.value || ''
    };

    const isEventTargetValueValid = (
        e: React.SyntheticEvent,
    ): boolean => {
        const targetValue = getSyntheticEventTargetValue(e);
        const isValid = targetValue.trim().length === 0 || parseEstimate(targetValue, workHoursInDay) > 0;
        return isValid;
    };

    const handleChange = (
        e: React.SyntheticEvent,
    ) => {
        const isValid = isEventTargetValueValid(e);
        if (showValid) {
            const inputStateStr = isValid ? 'success' : 'error';
            setInputState(inputStateStr);
        } else {
            setInputState(null);
        }
        if (isValid) {
            const targetValue = getSyntheticEventTargetValue(e);
            const estimateInMinutes = parseEstimate(targetValue, workHoursInDay);
            onChange(estimateInMinutes);
        }
    };

    const onBlur = (
        e: React.FocusEvent,
    ) => {
        const targetValue = getSyntheticEventTargetValue(e);
        const isValid = isEventTargetValueValid(e);
        setShowValid(!isValid);
        if (!isValid) {
            setInputState('error');
        } else {
            setInputState(null);
            const estimateInMinutes = parseEstimate(targetValue, workHoursInDay);
            if (estimate !== estimateInMinutes) {
                onSave(estimateInMinutes);
            }
            if (
                !e.relatedTarget.className.includes(`${CLASS_CARD_DETAILS}-checklist-item__actions-estimate-button`) &&
                !e.relatedTarget.className.includes(`${CLASS_CARD_DETAILS}-checklist__add-actions-estimate-button`)
            ) {
                onClose();
            }
        }
    };

    const onEnter = (
        e: React.KeyboardEvent,
    ) => {
        const targetValue = getSyntheticEventTargetValue(e);
        const isValid = isEventTargetValueValid(e);
        setShowValid(!isValid);
        if (!isValid) {
            setInputState('error');
        } else {
            setInputState(null);
            const estimateInMinutes = parseEstimate(targetValue, workHoursInDay);
            if (estimate !== estimateInMinutes) {
                onSave(estimateInMinutes);
            }
            onClose();
        }
    };

    React.useEffect(() => {
        onChange(estimate);
    }, [estimate]);

    return (
        <div className={className} >
            <Input
                autoFocus
                className={ classInput }
                isClearable={true}
                iconTooltip={
                    getAsidePanelTooltip({
                        direction: 'up-left',
                        value: CARD_ESTIMATE_REMOVE_TOOLTIP,
                    })
                }
                label={ CARD_ESTIMATE_LABEL_TEXT }
                tooltip={
                    getAsidePanelTooltip({
                        direction: 'up-right',
                        value: CARD_ESTIMATE_EXAMPLE,
                    })
                }
                autosize={ false }
                state={ inputState }
                placeholder={ CARD_ESTIMATE_EXAMPLE }
                onChange={ handleChange }
                onEnter={ onEnter }
                onBlur={ onBlur }
                value={ estimateStr }
            />
        </div>
    );
};
