import {
    ERecurringTaskEndType,
    ERecurringTaskFrequencyType,
    IRestRecurringTask
} from '../../../../../../types/rest/recurringTask/IRestRecurringTask';
import { IRecurringTaskForm } from '../types';
import { ERecurringRepeatMonthType, ERecurringRepeatType } from '../constants';
import { getMonthDayData } from './getMonthDayData';

export const mapRestRecurringTaskToForm = (
    recurringTask: IRestRecurringTask
): IRecurringTaskForm => {
    if (!recurringTask) return {};
    const startDate = recurringTask.startDate ? new Date(recurringTask.startDate) : null;
    const recurringTaskForm: IRecurringTaskForm = {
        startDate,
        listId: recurringTask.listId,
        endType: recurringTask.endType,
        monthDayData: getMonthDayData(startDate),
        ...mapFrequency(recurringTask)
    }
    if (recurringTask.endType === ERecurringTaskEndType.DATE && recurringTask.dueDate) {
        recurringTaskForm.dueDate = new Date(recurringTask.dueDate)
    } else if (recurringTask.endType === ERecurringTaskEndType.REPEATS) {
        recurringTaskForm.repeats = recurringTask.repeats
    }
    return recurringTaskForm;
};

const mapFrequency = (
    recurringTask: IRestRecurringTask
): IRecurringTaskForm => {
    switch (recurringTask.frequencyType) {
        case ERecurringTaskFrequencyType.DAY:
            return {
                repeatType: ERecurringRepeatType.DAY,
                repeatEvery: recurringTask.day.dayCount
            };
        case ERecurringTaskFrequencyType.WEEK:
            return {
                repeatType: ERecurringRepeatType.WEEK,
                repeatEvery: recurringTask.week.weekCount,
                weekDays: recurringTask.week.selectedDaysOfWeek
            }
        case ERecurringTaskFrequencyType.YEAR:
            return {
                repeatType: ERecurringRepeatType.YEAR,
                repeatEvery: recurringTask.year.yearCount
            };
        case ERecurringTaskFrequencyType.DAY_OF_MONTH:
            return {
                repeatType: ERecurringRepeatType.MONTH,
                repeatEvery: recurringTask.dayOfMonth.monthCount,
                monthDay: ERecurringRepeatMonthType.DAY_OF_MONTH
            }
        case ERecurringTaskFrequencyType.DAY_OF_WEEK_IN_MONTH:
            return {
                repeatType: ERecurringRepeatType.MONTH,
                repeatEvery: recurringTask.dayOfWeekInMonth.monthFrequency,
                monthDay: ERecurringRepeatMonthType.DAY_OF_WEEK_IN_MONTH
            }
    }
    return {};
}
