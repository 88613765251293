import * as React from 'react';
import { AsidePanelActionsButton } from '../../../..';
import { BOARD_BUTTON_UNARCHIVE } from './constants';
import { IUnarchiveButtonProps } from './types';

export function UnarchiveButton ({
    isBasicFeature,
    isDisallow,
    isProFeature,
    isShow,
    tooltip,
    onClick,
}: IUnarchiveButtonProps) {
    return (
        <AsidePanelActionsButton
            isDisabled={isDisallow}
            title={BOARD_BUTTON_UNARCHIVE}
            tooltip={tooltip}
            isShow={isShow}
            onClick={onClick}
            isBasicFeature={isBasicFeature}
            isProFeature={isProFeature}
        />
    );
};
