import { IGetState } from '../../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../../middlewares/segment';

export const cardClickedWithCtrlSegmentEvent = (): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.CARD_CLICKED_WITH_CTRL));
    };
    return action;
};

export const cardClickedWithShiftSegmentEvent = (): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.CARD_CLICKED_WITH_SHIFT));
    };
    return action;
};
