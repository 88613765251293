import * as React from 'react';
import './_BoardColors.scss';
import { IBoardColorsProps } from './types';
import { CLASS_BOARD_DETAILS } from '../../../../constants';
import { BoardColor } from '../BoardColor/BoardColor';
import {
    BOARD_SECTION_COLORS_HIDE,
    BOARD_SECTION_COLORS_MODAL_CANCEL,
    BOARD_SECTION_COLORS_MODAL_REMOVE,
    BOARD_SECTION_COLORS_SHOW
} from './constants';
import { HideButton } from '../../../../../../base/components/HideButton/HideButton';
import { IColor, TStatus } from 'app/types/model';
import { TColorId } from 'app/types/types';
import { ActionModal } from '../../../../../../base/components/ActionModal/ActionModal';
import {
    BasicUpgradeButtonHOC
} from '../../../../../../base/components/BasicUpgradeButton/hocs/BasicUpgradeButtonHOC/BasicUpgradeButtonHOC';
import { getMessages } from '../../../../../../../../store/constants';

export function BoardColors ({
    aloudCount,
    colors,
    customColors,
    isAllColorsEnabled,
    isReadonly,
    onAddCustomColor,
    onClose,
    onDelete,
    onEdit,
    onToggleStatus,
}: IBoardColorsProps) {
    const className = CLASS_BOARD_DETAILS + '__colors';

    const [isHide, setHide] = React.useState(true);
    const [hiddenElements, setHiddenElements] = React.useState(colors.filter(color => color.status === TStatus.STATUS_DELETED));
    const [shownElements, setShownElements] = React.useState(colors.filter(color => color.status === TStatus.STATUS_ACTIVE));
    const [elements, setElements] = React.useState(shownElements);
    const [editingId, setEditingId] = React.useState(null); //for multiple focus fix
    const [removingId, setRemovingId] = React.useState(null);

    const handleEdit = (
        colorTag: IColor
    ) => {
        const idx = elements.findIndex(color => color.id === colorTag.id);
        if (idx < 0) return;
        const oldColorTag = elements[idx];
        setElements([
            ...elements.slice(0, idx),
            {
                ...oldColorTag,
                ...colorTag
            },
            ...elements.slice(idx + 1)
        ]);
        if (isHide) {
            const shownIdx = shownElements.findIndex(color => color.id === colorTag.id);
            setShownElements([
                ...shownElements.slice(0, shownIdx),
                {
                    ...oldColorTag,
                    ...colorTag
                },
                ...shownElements.slice(shownIdx + 1)
            ]);
        }
        onEdit(colorTag).then((newColorTag) => {
            if (!newColorTag) { // if error
                setElements([
                    ...elements.slice(0, idx),
                    oldColorTag,
                    ...elements.slice(idx + 1)
                ]);
                if (isHide) {
                    const shownIdx = shownElements.findIndex(color => color.id === colorTag.id);
                    setShownElements([
                        ...shownElements.slice(0, shownIdx),
                        oldColorTag,
                        ...shownElements.slice(shownIdx + 1)
                    ]);
                }
            }
        });
    };

    const handleToggle = (
        id: TColorId
    ) => {
        onToggleStatus(id).then(isToggle => {
            if (!isToggle) {
                if (isToggle !== null) {
                    setRemovingId(id);
                }
                return;
            }
            const idx = elements.findIndex(color => color.id === id);
            if (idx < 0) return;
            setElements([
                ...elements.slice(0, idx),
                {
                    ...elements[idx],
                    status: elements[idx].status === TStatus.STATUS_ACTIVE
                        ? TStatus.STATUS_DELETED
                        : TStatus.STATUS_ACTIVE,
                    cardsCount: elements[idx].status === TStatus.STATUS_ACTIVE ? 0 : elements[idx].cardsCount
                },
                ...elements.slice(idx + 1)
            ]);
            if (!isHide) return;
            const shownIdx = shownElements.findIndex(color => color.id === id);
            if (shownIdx < 0) return;
            setShownElements([
                ...shownElements.slice(0, shownIdx),
                {
                    ...shownElements[shownIdx],
                    status: shownElements[shownIdx].status === TStatus.STATUS_ACTIVE
                        ? TStatus.STATUS_DELETED
                        : TStatus.STATUS_ACTIVE,
                    cardsCount: shownElements[shownIdx].status === TStatus.STATUS_ACTIVE ? 0 : elements[shownIdx].cardsCount
                },
                ...shownElements.slice(shownIdx + 1)
            ]);
        });
    };

    const handleDelete = (
        id: TColorId
    ) => {
        onDelete(id).then(isDelete => {
            if (!isDelete) return;
            const idx = elements.findIndex(color => color.id === id);
            if (idx < 0) return;
            setElements(elements.filter(color => color.id !== id));
            if (!isHide) return;
            setShownElements(shownElements.filter(color => color.id !== id));
        });
    };

    const onModalClose = () => {
        onClose();
        setRemovingId(null);
    };

    React.useEffect(() => {
        let newElements;
        if (isHide) {
            newElements = colors.filter(color => color.status === TStatus.STATUS_ACTIVE);
            setShownElements(newElements);
            setHiddenElements(colors.filter(color => color.status === TStatus.STATUS_DELETED));
        } else {
            newElements = [...shownElements, ...hiddenElements];
        }
        setElements(newElements);
    }, [isHide]);

    return (
        <div className={className + '-container'}>
            <div className={className}>
                {
                    elements.map(colorTag => (
                        <BoardColor
                            editingId={editingId}
                            customColors={customColors}
                            setEditingId={() => setEditingId(colorTag.id)}
                            key={colorTag.id}
                            colorTag={colorTag}
                            isReadonly={isReadonly}
                            onAddCustomColor={onAddCustomColor}
                            onEdit={(color, name) => handleEdit({ id: colorTag.id, color, name })}
                            onToggle={() => handleToggle(colorTag.id)}
                            onDelete={() => handleDelete(colorTag.id)}
                        />
                    ))
                }
            </div>
            {!isAllColorsEnabled &&
                <HideButton
                    labels={[BOARD_SECTION_COLORS_SHOW, BOARD_SECTION_COLORS_HIDE]}
                    isHide={isHide}
                    onClick={() => setHide(!isHide)}
                />
            }
            <ActionModal
                isShow={!!removingId}
                actions={[
                    {
                        variant: 'primary',
                        onClick: onModalClose,
                        children: BOARD_SECTION_COLORS_MODAL_CANCEL
                    },
                    {
                        variant: 'secondary',
                        onClick: () => {
                            onModalClose();
                            return handleToggle(removingId);
                        },
                        children: BOARD_SECTION_COLORS_MODAL_REMOVE
                    },
                ]}
                footer={<BasicUpgradeButtonHOC onClick={onModalClose} />}
                text={getMessages().getText('board_settings.colors.modal.text.board', null, aloudCount)}
                title={getMessages().getText('board_settings.colors.modal.title', null, elements.find(color => color.id === removingId)?.name)}
                onClose={onModalClose}
            />
        </div>
    );
}
