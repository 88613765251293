import * as React from 'react';
import { IRestCardCommentActivity } from '../../../../../../../types/rest/activity/IRestCardCommentActivity';
import { ERestActivityPropertyActionType } from '../../../../../../../types/rest/activity/IRestActivityBase';
import { IActivityProps } from '../../../../boardDetails/activity/TabActivity/types';
import { TabActivityContext } from '../../../../boardDetails/activity/TabActivity/components/TabActivity/constants';
import { TActivity } from '../../../../../../../store/activities/types';
import {
    ActivityItemBody
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemBody/ActivityItemBody';
import { ActivityHeader } from '../../../../boardDetails/activity/TabActivity/components/ActivityHeader/ActivityHeader';
import {
    ActivityItemName
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemName/ActivityItemName';
import { msFileThumbnailStorage } from '../../../../../../../helper/authorisation/microsoft/storage/msThumbnail';
import { blockMarkdown } from '../../../../../../../helper/markdownHelper';
import { CARD_COMMENT_REACTION__REMOVED, CARD_COMMENT_REACTION_ADDED } from './constants';
import { escape } from 'underscore';
import { clearMarkdown } from '../../../../../base/helpers/clearMarkdownHelper';
import { IActivityDriveDoc } from '../CardActivityDriveDoc/types';
import { GOOGLE_SPACING } from '../../../../../../../const';
import { renderToStaticMarkup } from 'react-dom/server';
import {
    CLASS_ACTIVITY_ITEM,
    CLASS_ACTIVITY_ITEM_TEMP
} from '../../../../boardDetails/activity/TabActivity/components/constants';
import {
    ActivityItemMore
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemMore/ActivityItemMore';
import './_CardActivityCommentReaction.scss';

export function CardActivityCommentReaction({
    activity,
    asidePanelWidth
}: IActivityProps<IRestCardCommentActivity>) {
    const className = CLASS_ACTIVITY_ITEM + '__comment-reaction';
    const classContent = className + '-content';
    const classTooltipItem = className + '-tooltip-item';
    const classTooltipItemName = className + '-tooltip-item-name';
    const classIcon = className + '-icon';

    const { onOpenCard } = React.useContext(TabActivityContext);
    const { propertyActionType, card } = activity as TActivity;
    const { driveDocs, commentText} = activity;

    const [markdown, setMarkdown] = React.useState('');
    const [isNeedToCalculate, setNeedToCalculate] = React.useState(true);
    const [visibleElements, setVisibleElements] = React.useState([]);
    const [tooltip, setTooltip] = React.useState(null);

    const ref = React.useRef(null);
    const tempRef = React.useRef(null);

    React.useEffect(() => {
        setNeedToCalculate(true);
    }, [driveDocs, asidePanelWidth]);

    React.useEffect(() => {
        if (!isNeedToCalculate) return;

        const newElements: IActivityDriveDoc[] = [];

        const container = ref.current as HTMLElement;
        const tempContainer = tempRef.current as HTMLElement;
        if (!container || !tempContainer) return;

        const maxWidth = container.offsetWidth - GOOGLE_SPACING * 2 // место для плашки с плюсом
        let currentWidth = 0;

        for (let i = 0; i < driveDocs.length; i++) {
            const driveDocEl = tempContainer.children[i] as HTMLElement;
            if (!driveDocEl) return;

            const minWidth = Math.min(driveDocEl.offsetWidth, GOOGLE_SPACING * 25)
            const driveDocWidth = minWidth + GOOGLE_SPACING; // + gap
            currentWidth += driveDocWidth;
            if (currentWidth >= maxWidth) {
                break;
            }
            newElements.push({ ...driveDocs[i], minWidth });
        }

        setVisibleElements(newElements);

        const dif = driveDocs.length - newElements.length;
        if (dif) {
            setTooltip(
                driveDocs.slice(newElements.length).reduce((tooltip, driveDoc) => {
                    return tooltip + (`${renderToStaticMarkup(
                        <div className={classTooltipItem}>
                            <img className={classIcon} src={driveDoc.iconUrl} />
                            <div className={classTooltipItemName}>{driveDoc.title}</div>
                        </div>
                    )}`);
                }, '')
            );
        }

        setNeedToCalculate(false);
    }, [isNeedToCalculate, driveDocs, asidePanelWidth]);

    React.useEffect(() => {
        let text = commentText || '';
        const isTime = text && text.indexOf('[') > -1;
        if (isTime) {
            const marked = text.split('').map(function (chr) {
                if (chr === '[' || chr === ']') return '';
                return chr;
            });
            text = marked.join('');
        }
        setMarkdown(blockMarkdown(text));

        if (text && msFileThumbnailStorage.is()) {
            msFileThumbnailStorage.getSrcThumbnails(text)
                .then((result) => {
                    setMarkdown(blockMarkdown(result));
                })
                .catch(() => {})
        }
    }, [activity]);

    return (
        <ActivityItemBody
            className={className}
            header={<ActivityHeader
                icon={'like'}
                label={propertyActionType === ERestActivityPropertyActionType.DELETE ?
                    CARD_COMMENT_REACTION__REMOVED : CARD_COMMENT_REACTION_ADDED}
            />}
            activity={activity}
            onClick={() => onOpenCard(card.id, activity.id, 'comments')}
        >
            {
                commentText ?
                    <ActivityItemName name={markdown} isMarkdown tooltip={escape(clearMarkdown(activity.commentText))} />
                    : driveDocs && !!driveDocs.length &&
                    <>
                        {driveDocs &&
                            <div
                                className={`
                                        ${classContent}
                                        ${driveDocs && driveDocs.length > 1 ? classContent + '--multiple' : ''}
                                    `}
                                ref={ref}
                            >
                                {visibleElements.map(({ title, iconUrl, minWidth }, idx) => (
                                    <ActivityItemName
                                        name={title}
                                        icon={<img className={classIcon} src={iconUrl} />}
                                        style={{ minWidth }}
                                        key={idx}
                                    />
                                ))}
                                {visibleElements.length !== driveDocs.length && <ActivityItemMore count={driveDocs.length - visibleElements.length} tooltip={tooltip} />}
                            </div>
                        }
                        {isNeedToCalculate &&
                            <div
                                className={`${classContent} ${CLASS_ACTIVITY_ITEM_TEMP}`}
                                ref={tempRef}
                            >
                                {driveDocs.map(driveDoc => (
                                    <ActivityItemName
                                        name={driveDoc.title}
                                        icon={<img className={classIcon} src={driveDoc.iconUrl} />}
                                        key={driveDoc.url}
                                    />
                                ))}
                            </div>
                        }
                    </>
            }
        </ActivityItemBody>
    );
};
