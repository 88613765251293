import { IFilter } from '../../react_components/aside_panel/filterPanelBoard/store/filter/types';
import { isCardMatchFilter, isMyTasksCardMatchFilter } from './dashboard_filter_helper_card_filter';
import {
    FP_KEY_HIDE_FILTER_EMPTY_LIST, FP_NO_COLOR,
    FP_NO_COLOR_TAG
} from '../../react_components/aside_panel/filterPanelBoard/constants';
import { ICard } from '../../../store/model/card/types';
import { IApplicationState, TCardId, TListId } from '../../../types/types';
import { initialState } from '../../react_components/aside_panel/filterPanelBoard/store/filter/reducers/filterReducer';
import { ICardAssignee } from '../../../store/model/cardAssignee/types';
import {
    getFilterPanelIsInitial
} from '../../react_components/aside_panel/filterPanelBoard/store/selectors/getFilterPanelIsInitial';
import { getBoardIdByCardId } from '../../../store/model/selectors/getBoardIdByCardId';
import { IList } from '../../../store/model/list/types';

export const isCardMatchFilters = (
    state: IApplicationState,
    card: ICard,
    filters: IFilter[],
    ignoreCardIds: TCardId[] = [],
): boolean => {
    if (ignoreCardIds && ignoreCardIds.includes(card.id)) return true;

    if (state) {
        const boardId = getBoardIdByCardId(state, card.id);
        const isInitial = getFilterPanelIsInitial(state, boardId);
        if (isInitial) return true; // initial = панель только открыли => все карты подходят
    }

    for (let i = 0; i < filters.length; i++) {
        if (!isCardMatchFilter(state, card, filters[i])) {
            return false;
        }
    }
    return true;
};

export const isListMatchFilters = (
    list: IList,
    filters: IFilter[],
    filteredCardsCount: number
): boolean => {
    for (let i = 0; i < filters.length; i++) {
        const { isEnabled, hide, meta } = filters[i];
        if (isEnabled) {
            if (hide.includes(FP_KEY_HIDE_FILTER_EMPTY_LIST) && filteredCardsCount === 0) {
                return false;
            }
            const filterColors = meta && meta.listColors;
            if (filterColors && filterColors.length && !filterColors.includes(list.backgroundColor || FP_NO_COLOR)) {
                return false;
            }
        }
    }
    return true;
};

export const getFilteredCardsForAmendedFilter = (
    cards: ICard[],
    amendedFilter: Partial<IFilter>,
    ignoreCardIds: TCardId[],
    filters: IFilter[] = [{ ...initialState }]
): ICard[] => {
    if (filters && filters.length && amendedFilter) {
        filters.splice(0, 1, {
            ...filters[0],
            ...amendedFilter
        });
    }
    return cards.filter(
        card => isCardMatchFilters(null, card, filters, ignoreCardIds)
    )
}

export const getFilteredListsForAmendedFilter = (
    lists: IList[],
    amendedFilter: Partial<IFilter>,
    filters: IFilter[] = [{ ...initialState }]
): IList[] => {
    if (filters && filters.length && amendedFilter) {
        filters.splice(0, 1, {
            ...filters[0],
            ...amendedFilter
        });
    }
    return lists.filter(
        list => isListMatchFilters(list, filters, null)
    );
}

export const isMyTaskCardMatchFilters = (
    cardAssignee: ICardAssignee,
    filters: IFilter[],
    ignoreCardIds: TCardId[] = []
): boolean => {
    if (ignoreCardIds && ignoreCardIds.includes(cardAssignee.cardId)) return true;

    for (let i = 0; i < filters.length; i++) {
        if (!isMyTasksCardMatchFilter(cardAssignee, filters[i])) {
            return false;
        }
    }
    return true;
};

export const getFilteredMyTaskCardsForAmendedFilter = (
    cardAssignees: ICardAssignee[],
    amendedFilter: Partial<IFilter>,
    ignoreCardIds: TCardId[],
    filters: IFilter[] = [{ ...initialState }]
): ICardAssignee[] => {
    if (filters && filters.length && amendedFilter) {
        const newFilter: IFilter = {
            ...filters[0],
            ...amendedFilter,
            meta: {
                ...filters[0].meta,
                ...amendedFilter.meta
            }
        }
        filters.splice(0, 1, newFilter);
    }
    return cardAssignees.filter(
        cardAssignee => isMyTaskCardMatchFilters(cardAssignee, filters, ignoreCardIds)
    )
}
