import {Button, ButtonDropdown, Icon, ITooltipInheritedProps, SelectList, SelectListItem} from 'kui';
import { PRINT_CARD_ACTION, TOOLTIP_ACTIONS_CARD, VIEW_ON_BOARD } from '../../../../../main/kanbanView/wording';
import * as React from 'react';
import { ICardActionsTopMoreProps } from './types';
import {
    CLASS_ASIDE_PANEL_ACTIONS_POPUP,
    ID_ASIDE_PANEL_ACTIONS_PORTAL
} from '../../../../asidePanel/components/AsidePanelActionsButton/constants';
import './_CardActionsTopMore.scss';
import {
    CardRecurringSectionHOC
} from '../../../recurringSection/hocs/CardRecurringSectionHOC/CardRecurringSectionHOC';
import { getPopupAttributes } from '../../../../../base/components/SettingsSection/constants';
import { EAsidePanelProperty } from '../../../../asidePanel/components/AsidePanel/types';
import { CLASS_CARD_SECTION_RECURRING, CLASS_TIME_DROPDOWN } from '../../../index';
import { AsidePanelActionsButton } from '../../../../index';
import { CLASS_ASIDE_PANEL_ACTIONS_HEAD } from '../../../../asidePanel/components/AsidePanelActions/constants';
import { CARD_SECTION_RECURRING } from '../../../recurringSection/components/wording';
import { TITLE_SAVE_AS_TEMPLATE } from '../../../saveAsTemplateButton/hocs/SaveAsTemplateButtonHOC/constants';
import { CLASS_DATEPICKER } from 'app/view/react_components/base/components/Datepicker/components/constants';
import { BasicFeatureIndicator } from '../../../../../base/components/BasicFeatureIndicator/BasicFeatureIndicator';
import { ARCHIVE_TITLE, UNARCHIVE_TITLE } from '../../../ArchiveButton/components/ArchiveButton/constants';
import { SET_IN_WORK_CARD_TOOLTIP } from '../../../SetInWorkButton/components/SetInWorkButton/constants';

export function CardActionsTopMore({
    boardId,
    cardId,
    isArchived,
    isBasicFeature,
    isBoard,
    isShow,
    onArchive,
    onCardSaveAsTemplate,
    onCardPrint,
    onCardMakeRecurring,
    onStartWorking,
    onViewOnBoard
}: ICardActionsTopMoreProps) {
    if (!isShow) return null;
    const className = CLASS_ASIDE_PANEL_ACTIONS_HEAD + '-top-more';
    const classNameDropdown = className + '-dropdown';
    const classNameDropdownButton = classNameDropdown + '-button';
    const classNameRecurring = className + '-recurring';
    const [isOpened, setIsOpened] = React.useState(null);
    const onOpened = () => setIsOpened(true);
    const onClosed = () => setIsOpened(false);

    return <>
        {isOpened &&
            <AsidePanelActionsButton
                className={classNameRecurring}
                dropdownClassName={`${CLASS_ASIDE_PANEL_ACTIONS_POPUP}--recurring
                    ${CLASS_ASIDE_PANEL_ACTIONS_POPUP}--recurring-top-section`}
                isOpened={isOpened}
                notBlurClasses={[
                    CLASS_DATEPICKER,
                    CLASS_TIME_DROPDOWN,
                    CLASS_DATEPICKER + '__time-button',
                    CLASS_DATEPICKER + '__time-button-dropdown',
                    CLASS_DATEPICKER + '__calendar',
                    CLASS_DATEPICKER + '__calendar-select-dropdown',
                    CLASS_DATEPICKER + '__hour-switch',
                ]}
                openedProperty={EAsidePanelProperty.CARD_RECURRING}
                sectionSelector={`.${CLASS_CARD_SECTION_RECURRING}`}
                onBlur={onClosed}
            >
                <CardRecurringSectionHOC
                    boardId={boardId}
                    cardId={cardId}
                    onClose={onClosed}
                    {...getPopupAttributes()}
                />
            </AsidePanelActionsButton>
        }

        <ButtonDropdown
            className={classNameDropdownButton}
            dropdownClassName={`${classNameDropdown} handle-click-ignore`}
            isScaleAnimation
            directionHorizontal={'right'}
            portal
            portalId={ID_ASIDE_PANEL_ACTIONS_PORTAL}
        >
            <Button
                tooltip={{
                    direction: 'down',
                    isNoEvents: true,
                    isNoWrap: true,
                    value: TOOLTIP_ACTIONS_CARD,
                } as ITooltipInheritedProps}
                variant={'icon'}
            >
                <Icon xlink={'more'} size={24}/>
            </Button>

            <SelectList
                fixActive={false}
            >
                {isBasicFeature &&
                    <SelectListItem
                        icon={'archive'}
                        iconSize={24}
                        onClick={onArchive}
                    >
                        <>
                            {isArchived ? UNARCHIVE_TITLE : ARCHIVE_TITLE}
                            <BasicFeatureIndicator />
                        </>
                    </SelectListItem>
                }

                {isBasicFeature &&
                    <SelectListItem
                        icon={'pulse'}
                        iconSize={24}
                        onClick={onStartWorking}
                    >
                        <>
                            {SET_IN_WORK_CARD_TOOLTIP}
                            <BasicFeatureIndicator />
                        </>
                    </SelectListItem>
                }

                <SelectListItem
                    icon={'board-template'}
                    iconSize={24}
                    onClick={onCardSaveAsTemplate}
                >
                    <>
                        {TITLE_SAVE_AS_TEMPLATE}
                        {isBasicFeature && <BasicFeatureIndicator />}
                    </>
                </SelectListItem>

                <SelectListItem
                    icon={'arrow-circle'}
                    iconSize={24}
                    onClick={() => onCardMakeRecurring(onOpened)}
                >
                    <>
                        {CARD_SECTION_RECURRING}
                        {isBasicFeature && <BasicFeatureIndicator />}
                    </>
                </SelectListItem>

                <SelectListItem
                    icon={'print'}
                    onClick={onCardPrint}
                    iconSize={24}
                >
                    {PRINT_CARD_ACTION}
                </SelectListItem>

                {
                    !isBoard &&
                    <SelectListItem
                        icon={'kanban'}
                        onClick={onViewOnBoard}
                        iconSize={24}
                    >
                        {VIEW_ON_BOARD}
                    </SelectListItem>
                }
            </SelectList>
        </ButtonDropdown>
    </>
}
