import { getMessages } from '../../../../../../../store/constants';

export const CARD_CHECKLIST_ACTIONS_TOOLTIP = getMessages().getText('card_form_checklist_actions_tooltip');
export const CARD_CHECKLIST_ACTIONS_SORT = getMessages().getText('card_form_checklist_actions_sort');
export const CARD_CHECKLIST_ACTIONS_PIN = getMessages().getText('card_form_checklist_actions_pin');
export const CARD_CHECKLIST_ACTIONS_UNPIN = getMessages().getText('card_form_checklist_actions_unpin');
export const CARD_CHECKLIST_ACTIONS_HIDE = getMessages().getText('card_form_checklist_actions_hide');
export const CARD_CHECKLIST_ACTIONS_SHOW = getMessages().getText('card_form_checklist_actions_show');
export const CARD_CHECKLIST_ACTIONS_AUTHOR_HIDE = getMessages().getText('card_form_checklist_actions_author_hide');
export const CARD_CHECKLIST_ACTIONS_AUTHOR_SHOW = getMessages().getText('card_form_checklist_actions_author_show');
export const CARD_CHECKLIST_ACTIONS_ASSIGN = getMessages().getText('card_form_checklist_actions_assign');
export const CARD_CHECKLIST_ACTIONS_DONE = getMessages().getText('card_form_checklist_actions_done');
export const CARD_CHECKLIST_ACTIONS_COPY = getMessages().getText('card_form_checklist_actions_copy');
export const CARD_CHECKLIST_ACTIONS_RENAME = getMessages().getText('card_form_checklist_actions_rename');
export const CARD_CHECKLIST_ACTIONS_DELETE = getMessages().getText('card_form_checklist_actions_delete');
