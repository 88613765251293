import { IGetState } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IRestChecklist } from '../../../../types/rest/IRestChecklist';
import { restPost } from './api/restPost';
import { ICards } from '../../../../store/model/cards/types';
import { cardsActiveRequestsUpdate } from '../../../../store/requestsState/effects/cardsActiveRequestsUpdate';
import { checklistAddStore } from '../../../../store/model/checklists/actionCreators/checklistAdd';
import { checklistDeleteStore } from '../../../../store/model/checklists/actionCreators/checklistDelete';
import { IChecklist } from '../../../../store/model/checklists/checklist/types';
import { sendRealTimeStoreActionByCardId } from '../../../../view/react_components/base/helpers/realTimeHelperTS';

export const checklistAdd = (
    checklist: IChecklist
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (!checklist) return Promise.reject();
        const cardId = checklist.cardId;

        const id = Date.now();
        dispatch(checklistAddStore({
            ...checklist,
            cardId,
            id,
        })); // optimistic set

        const cards: ICards = {
            [cardId]: null
        };
        dispatch(cardsActiveRequestsUpdate(cards, 1));

        return dispatch(restPost(cardId, checklist as IRestChecklist))
            .then((response: IRestChecklist) => {
                const action = checklistAddStore(response);
                dispatch(action);
                dispatch(sendRealTimeStoreActionByCardId(cardId, action));
                return response;
            })
            .finally(() => {
                dispatch(checklistDeleteStore(cardId, id));
                dispatch(cardsActiveRequestsUpdate(cards, -1));
            });
    };
    return action;
};
