import { connect } from 'react-redux';
import { getBoard } from '../../../../../../../../store/model/selectors/getBoardById';
import { IApplicationState } from '../../../../../../../../types/types';
import { GanttPanelButton } from '../../components/GanttPanelButton/GanttPanelButton';
import { IGanttPanelButtonEvents, IGanttPanelButtonFields } from '../../components/GanttPanelButton/types';
import { onClick } from './events/onClick';
import { IGanttPanelButtonHOCProps } from './types';
import { getIsUserBasicPlanOnly } from '../../../../../../../../store/model/authUser/selectors/getIsUserBasicPlanOnly';

const mapStateToProps = (
    state: IApplicationState,
    { boardId }: IGanttPanelButtonHOCProps
): IGanttPanelButtonFields => {
    const { showOnGantt: indicatorBoolean } = getBoard(state, boardId);
    return {
        indicatorBoolean,
        isBasicFeature: getIsUserBasicPlanOnly(state),
        tooltip: null,
    }
};

const mapDispatchToProps = (
    dispatch: any,
    {onClick: ownOnClick}: IGanttPanelButtonHOCProps
): IGanttPanelButtonEvents => {
    return {
        onClick: () => dispatch(onClick(ownOnClick))
    }
};

export const GanttPanelButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(GanttPanelButton);

GanttPanelButtonHOC.displayName = 'GanttPanelButtonHOC';
