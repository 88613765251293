import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../../../types/types';
import { cardOpen } from '../../../../../base/effects/cardOpen';

export const onSelect = (
    cardId: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(cardOpen(Number(cardId)));
    };
    return action;
};
