import * as React from 'react';
import './_NewFeatureIndicator.scss';
import { INewFeatureIndicatorProps } from './types';

export const NewFeatureIndicator = ({
}: INewFeatureIndicatorProps) => {
    return (
        <div className={'new-feature-indicator'} />
    );
}
