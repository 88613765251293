import { getAuthUser } from "../store/model/authUser/selectors/getAuthUser";
import store from '../store/configureStore';
import { getUserManagedSubscriptions } from '../store/model/authUser/selectors/getUserManagedSubscriptions';
import { getUserActiveSubscriptions } from '../store/model/authUser/selectors/getUserActiveSubscriptions';
import {ESubscriptionStatus} from "../types/rest/IRestSubscription";
import * as _ from 'underscore';

export const PaypalHelper = {
    productsGantt: [1, 2, 3],
    productsTimeTracker: [4, 5, 6],
    productsBranding: [7, 8, 9],
    productsPaidFeatures: [10, 11, 12],

    oldSubscriptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],

    productsPremium: [13, 14, 22, 23],
    productsProfessional: [15, 16, 24, 25],
    productsEnterprise: [17, 18, 26, 27],

    trialProduct: 17,

    STATUS_ACTIVE: 'Active',
    STATUS_CANCELLED: 'Cancelled',
    STATUS_CANCELLED_BY_USER: 'CancelledByUser',
    STATUS_INACTIVE: 'Inactive',
    PAYMENT_SOURCE_CHARGEBEE: 'payment_source_chargebee',
    PAYMENT_SOURCE_MANUALLY: 'payment_source_manually',

    TEMP_TRIAL_CODE: 'EExkd9ops',

    isSubscriptionActive: function(subscription) {
        return Boolean(subscription.status == this.STATUS_ACTIVE);
    },

    isSubscriptionCancelled: function(subscription) {
        return Boolean(subscription.status == this.STATUS_CANCELLED);
    },

    isSharedSubscriptionCancelledButStillActive: function(subscription) {
        var ownSubscriptions = this.getOwnSubscriptions(subscription).length;
        var cancelledByUser = subscription.status == this.STATUS_CANCELLED_BY_USER || subscription.status ==  this.STATUS_CANCELLED;
        return Boolean(!ownSubscriptions && cancelledByUser)
    },

    isSubscriptionCancelledButStillActive: function (subscription) {
        var active = this.getSubscriptionTimeLeft(subscription) > 0;
        var cancelledByUser = subscription.status == this.STATUS_CANCELLED_BY_USER || subscription.status ==  this.STATUS_CANCELLED;
        return Boolean(active && cancelledByUser)
    },

    getProducts: function() {
        return getAuthUser(store.getState()).products;
    },

    getTrialProduct: function() {
        // TODO: написать логику определения id триального продукта
        return this.trialProduct;
    },

    getPaidSubscriptions: function() {
        return _.filter(this.getSubscriptions(), _.bind(function(subscription) {
            return !this.isTrial(subscription);
        }, this));
    },

    getChargeBeeSubscriptions: function() {
        return _.filter(this.getSubscriptions(), _.bind(function(subscription) {
            return subscription.paymentSource === this.PAYMENT_SOURCE_CHARGEBEE ||
                subscription.paymentSource === this.PAYMENT_SOURCE_MANUALLY;
        }, this));
    },

    isUserHavePaidSubscription: function() {
        return Boolean(this.getPaidSubscriptions().length);
    },

    /**
     * если несколько активных подписок, где-то админ, где-то овнер
     * то тут нужно отдать первую овн
     */
    getFirstSubscription: function() {
        const paidSubscriptions = this.getPaidSubscriptions();
        if (!paidSubscriptions.length) return null;

        const ownSubscriptions = this.getOwnSubscriptions();
        if (!ownSubscriptions.length) return paidSubscriptions[0];

        for (let i = 0; i < paidSubscriptions.length; i++) {
            const own = ownSubscriptions.find(subscription => subscription.id === paidSubscriptions[i].id);
            if (own) return own;
        }

        return paidSubscriptions[0];
    },

    isUserHaveSubscription: function() {
        return Boolean(this.getFirstSubscription());
    },

    getTrialSubscriptionId: function() {
        var subscriptions = this.getSubscriptions();
        var id;

        _.each(subscriptions, function(subscription) {
            if (App.Helpers.PaypalHelper.isTrial(subscription)) {
                id = subscription.id;
                return;
            }
        });
        return id;
    },

    isUserHaveSecondTrialCode: function() {
        return getAuthUser(store.getState()).secondTrialCode;
    },

    isUserHaveFirstTrialCode: function() {
        return getAuthUser(store.getState()).firstTrialCode;
    },

    isUserChoiceFreeVersionAfterFirstTrial: function() {
        const user = getAuthUser(store.getState());
        return user.meta && user.meta.freeVersionAfterFirstTrial === true;
    },

    isUserAllowFreeVersion: function() {
        return getAuthUser(store.getState()).allowFreeVersion;
    },

    isUserHaveActivePromo: function(promoCode) {
        return Boolean(_.findWhere(this.getSubscriptions(), {promoCode: promoCode}));
    },

    isUserHaveActiveTrial: function() {
        return Boolean(this.getActiveTrialSubscription());
    },

    getActiveTrialSubscription: function() {
        return _.findWhere(this.getSubscriptions(), {isTrial: true});
    },

    getSubscriptionTimeLeft: function (subscription) {
        var currentDate = new Date();
        var expDate = new Date(subscription.paymentExpDate * 1000);
        return expDate - currentDate;
    },

    getSubscriptionPercent: function(subscription) {
        var from = subscription.paymentDate * 1000;
        var due = subscription.paymentExpDate * 1000;
        var now = Date.now();

        var tillEnd = due - now;
        var fullDue = due - from;
        return Math.floor((tillEnd / fullDue) * 100);
    },

    getSubscriptions: function() {
        return getUserActiveSubscriptions(store.getState());
    },

    getOwnSubscriptions: function() {
        const user = getAuthUser(store.getState());
        return this.getManagedSubscriptions().filter(subscription => subscription.authorId === user.id);
    },

    getManagedSubscriptions: function() {
        return getUserManagedSubscriptions(store.getState());
    },

    getActiveManagedSubscriptions: function() {
        return getUserManagedSubscriptions(store.getState()).filter(subscription => subscription.status === ESubscriptionStatus.ACTIVE);
    },

    getPromoCode: function() {
        var product = this.getProductById(this.trialProduct);
        return ( product.promoCode ? product.promoCode : false );
    },

    isPromo: function(subscription) {
        return Boolean(subscription.promoCode);
    },

    /**
     * Возвращает является ли подписка пользователя платной
     * @returns {boolean}
     */
    isPaid: function() {
        var subscriptions = this.getSubscriptions();
        if (subscriptions.length) {
            if (subscriptions.length > 1) {
                return true;
            }
            return !this.isTrial(subscriptions[0]);
        }
        return false;
    },

    isDomainWide: function() {
        return getAuthUser(store.getState()).domainWide;
    },

    isDomainWidePremium: function() {
        return getAuthUser(store.getState()).domainWidePremium;
    },

    isDomainWideProfessional: function() {
        return getAuthUser(store.getState()).domainWideProfessional;
    },

    /**
     * Проверяет является ли подписка триальной.
     * @param item элемент подписки
     * @returns {boolean}
     */
    isTrial: function(item) {
        return Boolean(item.isTrial);
    },

    getProductById: function(id) {
        var products = this.getProducts();
        var n = _.findIndex(products, { id: id });
        return products[n];
    },

    getProductInfo: function(id) {
        var products = this.getProducts();
        var n = _.findIndex(products, { id: id });
        var product = products[n];
        var name = product.name.split('-');
        var title = $.trim(name[0]);
        var code = title.replace(/ /g, '-').toLowerCase();

        return {
            title: title,
            code: code,
            period: $.trim(name[1])
        };
    },

    paymentData: function() {
        var products = this.getProducts();
        var subscriptions = this.getSubscriptions();

        var subscriptionPremium = false;
        var subscriptionProfessional = false;
        var subscriptionEnterprise = false;
        var subscriptionPaidFeatures = false;
        var subscriptionTrial = false;
        var subscriptionOld = false;

        _.each(subscriptions, _.bind(function(subscription) {
            if (App.Helpers.PaypalHelper.productsPaidFeatures.indexOf(subscription.productId) > -1) {
                subscriptionPaidFeatures = this.getSubscriptionPaidObject(subscription);
            }
            if (App.Helpers.PaypalHelper.productsPremium.indexOf(subscription.productId) > -1) {
                subscriptionPremium = this.getSubscriptionPaidObject(subscription);
            }
            if (App.Helpers.PaypalHelper.productsProfessional.indexOf(subscription.productId) > -1) {
                subscriptionProfessional = this.getSubscriptionPaidObject(subscription);
            }
            if (App.Helpers.PaypalHelper.productsEnterprise.indexOf(subscription.productId) > -1) {
                subscriptionEnterprise = this.getSubscriptionPaidObject(subscription);
            }
            if (App.Helpers.PaypalHelper.oldSubscriptions.indexOf(subscription.productId) > -1) {
                subscriptionOld = this.getSubscriptionPaidObject(subscription);
            }
            if (App.Helpers.PaypalHelper.trialProduct == subscription.productId) {
                subscriptionTrial = this.getSubscriptionPaidObject(subscription);
            }
        }, this));

        const user = getAuthUser(store.getState());

        return {
            // subscribed
            subscriptionPremium: subscriptionPremium,
            subscriptionProfessional: subscriptionProfessional,
            subscriptionEnterprise: subscriptionEnterprise,
            subscriptionPaidFeatures: subscriptionPaidFeatures,
            subscriptionTrial: subscriptionTrial,
            subscriptionOld: subscriptionOld,

            isTrialActive: Boolean(this.getActiveTrialSubscription()),
            isDomainWide: user.domainWide,
            isDomainWidePremium: user.domainWidePremium,
            isDomainWideProfessional: user.domainWideProfessional,

            // Gantt
            price1: Number((products[0].price/products[0].frequency)).toFixed(2),
            // price2: (products[1].price/products[1].frequency),
            price3: Number((products[2].price/products[2].frequency)).toFixed(2),

            // Time Tracker
            price4: Number((products[3].price/products[3].frequency)).toFixed(2),
            // price5: (products[4].price/products[4].frequency),
            price6: Number((products[5].price/products[5].frequency)).toFixed(2),

            // Branding
            price7: Number((products[6].price/products[6].frequency)).toFixed(2),
            // price8: (products[7].price/products[7].frequency),
            price9: Number((products[8].price/products[8].frequency)).toFixed(2),

            // All paid features
            price10: Number((products[9].price/products[9].frequency)).toFixed(2),
            // price11: (products[10].price/products[10].frequency),
            price12: Number((products[11].price/products[11].frequency)).toFixed(2),

            // Premium
            price13: Number((products[12].price/products[12].frequency)).toFixed(2),
            price14: Number((products[13].price/products[13].frequency)).toFixed(2),

            // Professional
            price15: Number((products[14].price/products[14].frequency)).toFixed(2),
            price16: Number((products[15].price/products[15].frequency)).toFixed(2),

            // TODO: что, если нет email?
            email: user.email,
            photoUrl: user.photoUrl,
            initials: user.initials
        };
    },

    getSubscriptionPaidObject: function(subscription){
        var now = parseInt(new Date().getTime() / 1000);
        return {
            expDateStr: App.Util.formatDate(new Date(subscription.paymentExpDate * 1000)),
            trial: App.Helpers.PaypalHelper.isTrial(subscription),
            cancelled: subscription.status === 'Cancelled' || subscription.status === 'CancelledByUser',
            percent: 100 - parseInt((now - subscription.paymentDate) * 100 / (subscription.paymentExpDate - subscription.paymentDate))
        }
    },

    getEarliestExpDate: function() {
        var subscriptions = this.getSubscriptions();
        var now = parseInt(new Date().getTime() / 1000);
        var ealiestExpDate = 0;

        _.each(subscriptions, function(subscription) {
            if (subscription.paymentExpDate > ealiestExpDate) {
                ealiestExpDate = subscription.paymentExpDate;
            }
        });
        return App.Util.formatRegularDate(new Date(ealiestExpDate * 1000));
    },

    getItems: function() {
        var items = [{ email: getAuthUser(store.getState()).email }];
        return items;
    },

    // из старого /subscription_management/store/selectors/

    getUserExpiredSubscription: function() {
        return this.getOwnSubscriptions()
            .filter(subscription => subscription.status === this.STATUS_INACTIVE)
            .sort((a,b) => (a.paymentExpDate > b.paymentExpDate) ? -1 : ((b.paymentExpDate > a.paymentExpDate) ? 1 : 0)); // last expired in begin of array

    },

    isUserHasManager: function () {
        const user = getAuthUser(store.getState());
        return Boolean(user.lastManager && user.id !== user.lastManager.userId);
    },

    getPlanNameByProductId: function (id) {
        let index = this.productsPremium.indexOf(id);
        if (index < 0) {
            index = this.productsProfessional.indexOf(id);
        }
        if (index < 0) {
            index = this.productsEnterprise.indexOf(id);
        }
        if (index >= 0) {
            if (index > 0) return 'annually';
            return 'monthly';
        }
        return '';
    },

};

App.Helpers.PaypalHelper = PaypalHelper;
