import * as React from 'react';
import './_NotificationsRequest.scss';
import {
    CLASS_NOTIFICATIONS_REQUEST,
    NOTIFICATIONS_REQUEST_LABEL_GOT_IT,
    NOTIFICATIONS_REQUEST_LABEL_NOT_NOW,
    NOTIFICATIONS_REQUEST_TITLE
} from './constants';
import { Button, ButtonsGroup } from 'kui';
import { INotificationsRequestProps } from './types';

export function NotificationsRequest({
    allowText,
    doneText,
    doneTitle,
    text,
    onAllow,
    onCancel,
    onClose,
    onDidMount,
}: INotificationsRequestProps) {
    const className = CLASS_NOTIFICATIONS_REQUEST;

    const [isDone, setDone] = React.useState(null);

    React.useEffect(() => {
        onDidMount();
    }, [])

    return (
        <div className={className}>
            <div className={className + '__title'}>{isDone ? doneTitle : NOTIFICATIONS_REQUEST_TITLE}</div>
            {isDone ? doneText : text}
            {isDone ?
                <Button
                    className={className + '__done'}
                    size={'large'}
                    onClick={onClose}
                >
                    {NOTIFICATIONS_REQUEST_LABEL_GOT_IT}
                </Button>
                : <ButtonsGroup size={'large'}>
                    <Button
                        size={'large'}
                        variant={'secondary'}
                        onClick={onCancel}
                    >
                        {NOTIFICATIONS_REQUEST_LABEL_NOT_NOW}
                    </Button>
                    <Button
                        size={'large'}
                        onClick={() => {
                            onAllow();
                            if (onClose) setDone(true);
                        }}
                    >
                        {allowText}
                    </Button>
                </ButtonsGroup>
            }
        </div>
    )
}
