'use strict';

import DialogPromoCodeTemplate from './templates/dialogPromoCode.html';
import SubscriptionsFormTemplate from './templates/subscriptionsForm.html';
import Util from './../../../util/util';
import * as _ from 'underscore';

App.Views.PromoCodeDialog = App.Views.BaseView.extend({
    tagName: 'div',

    className: 'modal__inner',

    template: _.template(DialogPromoCodeTemplate),
    formTemplate: _.template(SubscriptionsFormTemplate),

    events: {
        'click .modal__close': 'onCloseClick',
        'click .js-apply-promo-code': 'applyPromoCode',
        'keyup .js-promo-code': 'onPromoCodeChanged',
        'keypress .js-promo-code': 'submit'
    },

    initialize: function(options) {
    	var params = App.Helpers.StartupProcessor.params();
        this.promoObj = null;
        try {
            this.promoObj = Util.decodePromo(params.code, params.add);
        } catch (e) {
            console.log('code invalid');
        }
        _.bindAll(this, 'onPromoCodeApplied', 'onPromoCodeFailed');
        App.vent.on(App.vent['keydown:escape'], this.onCloseClick, this);
    },

    render: function() {
        var data = _.extend(this.model, {code: "", productId: ""}, this.promoObj);
        this.$el.html(this.template(data));
        return this;
    },

    afterDOMRendering: function() {
        this.$el.find('.js-promo-code').focus();
        this.onPromoCodeChanged();
    },

    onPromoCodeChanged: function(e) {
        var inpt = this.$el.find('.js-promo-code');
        this.$el.find('.js-apply-promo-code').attr('disabled', $.trim(inpt.val()).length == 0);
    },

    submit: function(e) {
        if (e.keyCode == 13) {
            this.applyPromoCode();
        }
    },

    applyPromoCode: function() {
        Chargebee.init({
            site: window.Settings.chargebeeName
        });

        const instance = Chargebee.getInstance();

        const paymentCheckout = new App.Models.PaymentCheckout({
            productId: this.$el.find('.js-product-code').val(),
            items: App.Helpers.PaypalHelper.getItems(),
            promoCode: this.$el.find('.js-promo-code').val()
        });

        const onPromoCodeApplied = this.onPromoCodeApplied.bind(this);
        const onPromoCodeFailed = this.onPromoCodeFailed.bind(this);

        instance.openCheckout({
            hostedPage: function() {
                return new Promise(function(resolve, reject) {
                    paymentCheckout.save(null, {
                        success: function(model, response) {
                            onPromoCodeApplied();
                            resolve(response);
                        },
                        error: function(model, response) {
                            onPromoCodeFailed();
                            reject(response);
                        }
                    });
                });
            }
        });

        this.showLoader();
        this.$el.find('.js-promo-code-error').addClass('hidden');
        this.$el.find('.js-promo-code').removeClass('input--invalid');
    },

    onPromoCodeApplied: function(result) {
        App.controller.trackEvent(
            Messages.getText('ga.category.payment'),
            Messages.getText('ga.action.promo_code_applied')
        );
        App.statHelper.sendUserStat(_.object([
            App.Classes.StatHelper.PROMOCODE_APPLIED_COUNT,
            App.Classes.StatHelper.PROMOCODE_APPLIED_DATE
        ],[
            '+1',App.Util.nowTrimTimestampGMT()
        ]));

        this.hideLoader();
    },

    onPromoCodeFailed: function() {
        this.hideLoader();
        this.$el.find('.js-promo-code').addClass('input--invalid');
        this.$el.find('.js-promo-code-error').removeClass('hidden');
    },

    onCloseClick: function(e) {
      //  App.router.navigate('', {trigger: true});
        e.stopPropagation();
        this.hide();
    },

    hide: function() {
        App.vent.trigger(App.vent['modal:closed']);
    },

    showLoader: function() {
        this.$el.find('.loader').removeClass('hidden');
    },

    hideLoader: function() {
        this.$el.find('.loader').addClass('hidden');
    },

    remove: function() {
        App.vent.off(null, null, this);
        return Backbone.View.prototype.remove.call(this);
    }

});
