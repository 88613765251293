import { ISegmentEventOption, TSegmentTrackPayload } from '../../trackEntities/types';
import { processTrack } from './trackEngine';

export const trackRouter = (track: TSegmentTrackPayload, options: ISegmentEventOption, additionalOptions: ISegmentEventOption[]) => {
    if (track.category) {
        processTrack(track, options, additionalOptions);
    } else {
        console.error('Category is empty for ', track, options);
    }
};
