import { IApplicationState, TThemeId } from '../../../../types/types';
import { ILibTheme } from '../types';
import { getLibThemes } from './getLibThemes';

export const getLibTheme = (
    state: IApplicationState,
    id: TThemeId
): ILibTheme  => {
    return getLibThemes(state).find(theme => theme.id === id);
}
