import { IGetState, TBoardId, TCardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { ICommentMeta, TCommentId } from '../../../../store/model/card/types/IComment';
import {
    getCommentById
} from '../../../../view/react_components/aside_panel/cardDetails/CommentsSection/selectors/getCommentById';
import { commentUpdate } from './commentUpdate';

export const commentUpdateMeta = (
    meta: ICommentMeta,
    boardId: TBoardId,
    cardId: TCardId,
    commentId: TCommentId,
    isRealtime: boolean = true
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const comment = getCommentById(state, cardId, commentId);
        const newComment = {
            ...comment,
            meta: {
                ...comment.meta,
                ...meta
            }
        };
        dispatch(commentUpdate(newComment, boardId, isRealtime));
    };
    return action;
};
