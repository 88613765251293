import { IApplicationState } from '../../../../../../../../types/types';
import { connect } from 'react-redux';
import { ICardPropertiesHOCProps } from './types';
import { CardProperties } from '../../components/CardProperties/CardProperties';
import { ICardPropertiesEvents, ICardPropertiesFields } from '../../components/CardProperties/types';
import { onDefaultToggle } from './events/onDefaultToggle';
import { getPanelReadonly } from '../../../../selectors/getPanelReadonly';
import { onRequiredToggle } from './events/onRequiredToggle';
import { getCardPropertiesForSettingsCreateSelector } from './selectors/getCardPropertiesForSettings';
import { getAuthUser } from '../../../../../../../../store/model/authUser/selectors/getAuthUser';
import { onDisallowClick } from './events/onDisallowClick';
import { checkIfAllCardPropertiesAreDefault } from './selectors/checkIfAllCardPropertiesAreDefault';

const mapStateToProps = () => {
    const getCardPropertiesForSettings = getCardPropertiesForSettingsCreateSelector();

    const _mapStateToProps = (
        state: IApplicationState,
        { boardId, type }: ICardPropertiesHOCProps
    ): ICardPropertiesFields => {
        const authUser = getAuthUser(state);
        const cardProperties = getCardPropertiesForSettings(state, boardId, type);
        const areAllDefault = checkIfAllCardPropertiesAreDefault(cardProperties);
        return {
            areAllDefault,
            cardProperties,
            isReadonly: getPanelReadonly(state, boardId, null, null, 'allowEditCardDetailSettings'),
            isDisallow: authUser && !authUser.allowRequiredProperties
        }
    };

    return _mapStateToProps;
};

const mapDispatchToProps = (
    dispatch: any,
    ownProps: ICardPropertiesHOCProps
): ICardPropertiesEvents => {
    const { boardId } = ownProps;
    return {
        onDefaultToggle: (key, name) => dispatch(onDefaultToggle(boardId, key, name)),
        onRequiredToggle: (key, name) => dispatch(onRequiredToggle(boardId, key, name)),
        onDisallowClick: () => dispatch(onDisallowClick())
    }
};

export const CardPropertiesHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardProperties);

CardPropertiesHOC.displayName = 'CardPropertiesHOC';
