import * as React from 'react';
import './_ImportDialog.scss';
import {IImportDialogProps} from './types';
import {Button, Icon, Modal} from 'kui';

import {
    OPEN_BOARDS_IMPORT_BUTTON_TEXT_CSV,
    OPEN_BOARDS_IMPORT_BUTTON_TEXT_JIRA,
    OPEN_BOARDS_IMPORT_BUTTON_TEXT_TRELLO,
    OPEN_BOARDS_IMPORT_LEARN_MORE_LINK,
    OPEN_BOARDS_IMPORT_TEXT_CSV,
    OPEN_BOARDS_IMPORT_TEXT_JIRA,
    OPEN_BOARDS_IMPORT_TEXT_TRELLO,
    OPEN_BOARDS_IMPORT_TITLE,
    OPEN_BOARDS_IMPORT_TITLE_CSV,
    OPEN_BOARDS_IMPORT_TITLE_JIRA,
    OPEN_BOARDS_IMPORT_TITLE_TRELLO,
    OPEN_BOARDS_IMPORT_URL_CSV,
    OPEN_BOARDS_IMPORT_URL_JIRA,
    OPEN_BOARDS_IMPORT_URL_TRELO
} from './constants';

export const ImportDialog = (
    {
        onCancel,
        onClickCsv,
        onClickCsvLearnMore,
        onClickJira,
        onClickJiraLearnMore,
        onClickTrello,
        onClickTrelloLearnMore
    }: IImportDialogProps) => {
    const className = 'import';
    const classNameBody = className + '-body';
    const classNameDelimiter = classNameBody + '__delimiter';
    const classNameDescription = classNameBody + '__description';
    const classNameHeader = className + '-header';
    const classNameHeaderIcon = classNameHeader + '__icon';
    const classNameHeaderTitle = classNameHeader + '__title';
    const classNameHeaderTitleText = classNameHeader + '__title-text';
    const classNameItem = classNameBody + '__item';
    const classNameModal = className + '-modal';

    return (
        <Modal
            className={classNameModal}
            title={''}
            variant={'actions'}
            onClose={onCancel}
        >
            <div className={classNameHeader}>
                <div className={classNameHeaderTitle}>
                    <Icon
                        className={classNameHeaderIcon}
                        size={24}
                        xlink={'import-document'}
                    />
                    <h2 className={classNameHeaderTitleText}>{OPEN_BOARDS_IMPORT_TITLE}</h2>
                </div>
                <Button
                    variant={'icon'}
                    aria-label={'Close'}
                    onClick={onCancel}
                >
                    <Icon
                        size={24}
                        xlink="close"
                    />
                </Button>
            </div>
            <hr/>
            <div className={classNameBody}>
                <div className={classNameItem}>
                    <div className={classNameDescription}>
                        <h2>{OPEN_BOARDS_IMPORT_TITLE_TRELLO}</h2>
                        <p>{OPEN_BOARDS_IMPORT_TEXT_TRELLO} <a href={OPEN_BOARDS_IMPORT_URL_TRELO} onClick={onClickTrelloLearnMore} target="_blank">{OPEN_BOARDS_IMPORT_LEARN_MORE_LINK}</a></p>
                    </div>
                    <Button
                        text={OPEN_BOARDS_IMPORT_BUTTON_TEXT_TRELLO}
                        size={'large'}
                        variant={'primary'}
                        onClick={onClickTrello}
                    />
                </div>
                <hr className={classNameDelimiter}/>
                <div className={classNameItem}>
                    <div className={classNameDescription}>
                        <h2>{OPEN_BOARDS_IMPORT_TITLE_JIRA}</h2>
                        <p>{OPEN_BOARDS_IMPORT_TEXT_JIRA} <a href={OPEN_BOARDS_IMPORT_URL_JIRA} onClick={onClickJiraLearnMore} target="_blank">{OPEN_BOARDS_IMPORT_LEARN_MORE_LINK}</a></p>
                    </div>
                    <Button
                        text={OPEN_BOARDS_IMPORT_BUTTON_TEXT_JIRA}
                        size={'large'}
                        variant={'primary'}
                        onClick={onClickJira}
                    />
                </div>
                <hr className={classNameDelimiter}/>
                <div className={classNameItem}>
                    <div className={classNameDescription}>
                        <h2>{OPEN_BOARDS_IMPORT_TITLE_CSV}</h2>
                        <p>{OPEN_BOARDS_IMPORT_TEXT_CSV} <a href={OPEN_BOARDS_IMPORT_URL_CSV} onClick={onClickCsvLearnMore} target="_blank">{OPEN_BOARDS_IMPORT_LEARN_MORE_LINK}</a></p>
                    </div>
                    <Button
                        text={OPEN_BOARDS_IMPORT_BUTTON_TEXT_CSV}
                        size={'large'}
                        variant={'primary'}
                        onClick={onClickCsv}
                    />
                </div>
            </div>
        </Modal>
    )
}
