import * as React from 'react';
import './_CustomPropertiesElements.scss';
import { ListItem } from '../../../../../base/components/ListItem/ListItem';
import { Button, ButtonsGroup, Icon, Input, ISelectActiveInheritedProps, Select, Tooltip } from 'kui';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { ICustomPropertiesElement, ICustomPropertiesElementsProps } from './types';
import { getAsidePanelTooltip } from '../../../../asidePanel/helpers/getAsidePanelTooltip';
import {
    CUSTOM_BUTTON_CANCEL,
    CUSTOM_BUTTON_REMOVE_TOOLTIP,
    CUSTOM_BUTTON_SAVE,
    CUSTOM_ERROR_DUPLICATE,
    CUSTOM_ERROR_EMAIL,
    CUSTOM_ERROR_EMPTY,
    CUSTOM_ERROR_PHONE,
    CUSTOM_HIDE,
    CUSTOM_NO_OPTION,
    CUSTOM_PIN,
    CUSTOM_SHOW,
    CUSTOM_UNPIN
} from './constants';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { CLASS_LIST_ITEM_CONTAINER } from '../../../../../base/components/ListItem/constants';
import { CLASS_CARD_CUSTOM, CLASS_CARD_CUSTOM_ITEM } from '../constants';
import { TCustomPropertyId } from '../../../../../../../types/types';
import { ECustomPropertyType, IBoardCardCustomOption } from '../../../../../../../store/customProperties/types';
import { CustomPropertiesForm } from '../CustomPropertiesForm/CustomPropertiesForm';
import { GOOGLE_SPACING, ORDER_STEP } from '../../../../../../../const';
import { CustomPropertiesHideButton } from '../CustomPropertiesHideButton/CustomPropertiesHideButton';
import { v4 as uuidv4 } from 'uuid';
import { CUSTOM_PROPERTY_TYPES } from '../../../../../../../store/customProperties/constants';
import { validateEmail } from '../../../../../helpers/emailHelper';
import { validatePhone } from '../../../../../helpers/phoneHelper';
import { CustomPropertiesText } from '../CustomPropertiesText/CustomPropertiesText';
import { CARD_CONTROL_CLASS } from '../../../../../main/kanbanView';
import { escape } from 'underscore';
import {
    CLASS_ASIDE_PANEL_ACTIONS_BUTTON_CONTAINER,
    ID_ASIDE_PANEL_DATEPICKER_PORTAL
} from '../../../../asidePanel/components/AsidePanelActionsButton/constants';
import { ID_DATEPICKER_PORTAL } from '../../../../../base/components/Datepicker/components/constants';
import { DatepickerHOC } from '../../../../../base/components/Datepicker/hocs/DatepickerHOC/DatepickerHOC';
import { AnalyzeRender } from '../../../../../helpers/memoizeHelper';
import { root } from '../../../../../../../store/constants';

//@ts-ignore
const analyzeRender = window.Settings.development ? new AnalyzeRender(`CustomPropertiesElements`) : null;

export function CustomPropertiesElements ({
    elements,
    selectedCardId,
    isEdit,
    isHideEmpty,
    sectionType,
    isCard,
    classModule,
    onCancel,
    onChangeType,
    onEdit,
    onOptionAdd,
    onSave,
    onSort,
    onToggleHideEmpty,
    onUpdateValue,
    onConfirmDelete,
    onTogglePin,
    onTogglePinOnCard,
    setCardHeight
}: ICustomPropertiesElementsProps) {
    const cardId = selectedCardId ? selectedCardId : React.useContext(AsidePanelContext).cardId;
    const isBoardSettings = !cardId;

    if (analyzeRender) analyzeRender.call(`CustomPropertiesElements ${isBoardSettings ? 'board' : isCard ? 'kanban card' : 'aside card'} ${cardId ? cardId : ''}`);

    const isReadonly = sectionType === ESettingsSectionType.READONLY || sectionType === ESettingsSectionType.DISALLOW;
    const isPopup = sectionType === ESettingsSectionType.POPUP;
    const classRow = CLASS_CARD_CUSTOM_ITEM + '-row';
    const className = CLASS_CARD_CUSTOM + '__items';
    const classFooter = CLASS_CARD_CUSTOM + '__footer';
    const classSave = CLASS_CARD_CUSTOM_ITEM + '-save';
    const classPin = CLASS_CARD_CUSTOM_ITEM + '-pin';
    const classSaveShow = classSave + '--show';
    const classOptionAdd = CLASS_CARD_CUSTOM_ITEM + '-option-add';
    const classIsCard = className + '--card';
    const classTooltip = CLASS_CARD_CUSTOM_ITEM + '-tooltip';
    const classInput = CLASS_CARD_CUSTOM_ITEM + '-input';
    const classLabel = CLASS_CARD_CUSTOM_ITEM  + '-label';
    const classRequired = classLabel + '--required';
    const classEmpty = classLabel + '--empty';
    const classError = classInput + '--error';
    const classTooltipLabel = classLabel + '-tooltip';
    const [elementsState, setElements] = React.useState(getElementsClone(elements));
    const [keyDiv, setKeyDiv] = React.useState(uuidv4());
    const [isChanged, setChange] = React.useState(false); //for elements on card to rerender
    const [telCursor, setTelCursor] = React.useState(null);
    const ref = React.useRef(null);
    const timer = React.useRef(null);

    const hidden = isBoardSettings ? null
        : elements.reduce((hidden, element) => {
            if (castValue(element.value) === null && !element.pinned && !element.isRequired) {
                hidden.push(element.name);
            }
            return hidden;
        }, []);

    const isError = elementsState.reduce((error, item) => error || !!item.error, false);

    function getElementsClone (
        elements: ICustomPropertiesElement[],
        cleanChanges?: boolean,
    ): ICustomPropertiesElement[] {
        return elements.map(element => {
            let changed: Partial<ICustomPropertiesElement> = {};
            const elementState = !!elementsState && elementsState.find(item => item.id === element.id);
            if (!cleanChanges && elementState) {
                if (elementState.isChanged) {
                    changed = {
                        ...element,
                        isChanged: true,
                        value: elementState.value,
                    };
                }
                changed.removed = elementState.removed;
            }

            return {
                ...element,
                options: element.options ? element.options.map(option => ({...option})) : [],
                ...changed
            };
        });
    }

    /**
     * удалить все пробелы и в нижний регистр
     */
    const trimLow = (value: string): string => {
        return value.replace(/\s/g, '').toLocaleLowerCase();
    }

    const isValid = (): boolean => {
        const names = elementsState.map(item => trimLow(item.name));
        const namesDeduplicated = [...new Set(names)];
        const duplicateIds: TCustomPropertyId[] = [];
        const emptyIds: TCustomPropertyId[] = [];
        if (names.length !== namesDeduplicated.length) {
            elementsState.forEach(item => {
                if (!duplicateIds.includes(item.id)) {
                    const nameTrim = trimLow(item.name);
                    const elementDuplicates = elementsState.filter(element => trimLow(element.name) === nameTrim);
                    if (elementDuplicates.length > 1) {
                        elementDuplicates.forEach(item => duplicateIds.push(item.id));
                    }
                }
            });
        }
        elementsState.forEach(item => {
            const nameTrim = trimLow(item.name);
            if (nameTrim === '') emptyIds.push(item.id);
        });
        setElements(elementsState.map(item => ({
            ...item,
            error: emptyIds.length && emptyIds.includes(item.id)
                ? CUSTOM_ERROR_EMPTY
                : duplicateIds.length && duplicateIds.includes(item.id) ? CUSTOM_ERROR_DUPLICATE : null,
        })));
        return !emptyIds.length && !duplicateIds.length;
    }

    const onSaveHandler = () => {
        if (!isValid()) return;

        onSave(elementsState);
    };

    const onDeleteHandler = (
        id: TCustomPropertyId
    ) => {
        onConfirmDelete(id)
            .then((result) => {
                if (result) {
                    setElements(elementsState.map(item => {
                        return item.id === id
                            ? {
                                ...item,
                                removed: true
                            }
                            : item;
                    }));
                }
            })
    };

    const onChangeProperty = (
        id: TCustomPropertyId,
        name: string,
        type: ECustomPropertyType,
        options: IBoardCardCustomOption[]
    ) => {
        elementsState.forEach(item => {
            if (item.id === id) {
                item.name = name;
                item.type = type;
                item.options = options;
            }
        });
        setElements([...elementsState]);
        if (isError) isValid();
    }

    const onSortOptionsHandler = (
        propertyId: TCustomPropertyId,
        sourceIndex: number,
        index: number,
    ) => {
        const element = elementsState.find(item => item.id === propertyId);
        const options = [...element.options];
        const option = options.splice(sourceIndex, 1)[0];
        options.splice(index, 0, option);

        setElements(elementsState.map(item => {
            return item.id === propertyId
                ? {
                    ...item,
                    options
                }
                : item;
        }));
    };

    const onSortHandler = (
        id: TCustomPropertyId,
        index: number,
    ) => {
        onSort();
        let orderNumber: number;
        const elementsNew = elementsState.filter(item => item.id !== id);
        if (index === 0) {
            orderNumber = elementsNew[0].orderNumber + ORDER_STEP;
        } else if (index === elementsState.length - 1) {
            orderNumber = elementsNew[elementsNew.length - 1].orderNumber - ORDER_STEP;
        } else {
            orderNumber = (elementsNew[index - 1].orderNumber + elementsNew[index].orderNumber) / 2;
        }
        setElements(elementsState.map(item => {
            return item.id === id
                ? {
                    ...item,
                    orderNumber,
                }
                : item;
        }).sort((prop1, prop2) => {
            return prop2.orderNumber - prop1.orderNumber; // больший order сверху
        }));
    };

    function onDragEnd(result: DropResult) {
        if (
            !result.destination ||
            result.destination.index === result.source.index
        ) {
            // do nothing
        } else {
            onSortHandler(result.draggableId, result.destination.index);
        }
    }

    function castValue (
        value: string,
    ) {
        return value === undefined || value === '' ? null : value;
    }

    function errorEmail (
        id: TCustomPropertyId,
        value?: string,
    ): string {
        let isValue = value !== undefined;
        if (!isValue) {
            const element = elementsState.find(item => item.id === id);
            if (element) value = element.value;
        }
        if (castValue(value) === null || validateEmail(value)) return null;
        const error = CUSTOM_ERROR_EMAIL;
        if (!isValue) {
            setElements(elementsState.map(item => {
                return item.id === id
                    ? {
                        ...item,
                        error,
                    }
                    : item;
            }));
        }
        return error;
    }

    function errorPhone (
        id: TCustomPropertyId,
        value?: string,
    ): string {
        let isValue = value !== undefined;
        if (!isValue) {
            const element = elementsState.find(item => item.id === id);
            // mac adds invisible symbols on copy from contacts KNB-2606
            if (element) value = element.value.replace(/[^ -~]+/g, '');
        }
        if (castValue(value) === null || validatePhone(value)) return null;
        const error = CUSTOM_ERROR_PHONE;
        if (!isValue) {
            setElements(elementsState.map(item => {
                return item.id === id
                    ? {
                        ...item,
                        error,
                    }
                    : item;
            }));
        }
        return error;
    }

    const setCardHeightIfNeeded = () => {
        if (setCardHeight) setCardHeight();
    }

    const onChangeInput = (
        event: React.FormEvent,
        id: TCustomPropertyId,
    ) => {
        setCardHeightIfNeeded();

        const input = event.target as HTMLInputElement;
        let { value = null, selectionStart } = input;
        const element = elements.find(item => item.id === id);

        setElements(elementsState.map(item => {
            if (item.id === id) {
                let error = item.error;
                if (error) {
                    if (item.type === ECustomPropertyType.EMAIL) {
                        error = errorEmail(id, value);
                    } else if (item.type === ECustomPropertyType.PHONE) {
                        setTelCursor(selectionStart);
                        // mac adds invisible symbols on copy from contacts KNB-2606
                        value = value.replace(/[^ -~]+/g, '');
                        error = errorPhone(id, value);
                    }
                }
                const isChanged = !element || castValue(element.value) !== castValue(value)
                setChange(isChanged);

                return {
                    ...item,
                    error,
                    value,
                    isChanged: isChanged
                };
            } else {
                return {
                    ...item,
                    isChanged: false
                };
            }
        }));
    };

    const onBlurInput = (
        id: TCustomPropertyId,
        isRequired: boolean,
        value: any,
        isSelect?: boolean
    ) => {
        onBlur(id);
        if (isRequired && !value) {
            if (isSelect) {
                const input = document.querySelector('.' + classInput + id) as HTMLElement;
                if (input) {
                    input.classList.add(classError);
                }
            } else {
                const newElements = elements.map((element) => {
                    if (element.id === id) {
                        return {
                            ...element,
                            isInputError: true
                        }
                    } else {
                        return element;
                    }
                });
                setElements(newElements);
            }
        }
    };

    const onFocus = (
        id: TCustomPropertyId,
        isRequired: boolean,
        isSelect?: boolean
    ) => {
        if (!isRequired) return;
        const input = document.querySelector('.' + classInput + id) as HTMLInputElement;
        if (isSelect) {
            if (input) {
                input.classList.remove(classError);
            }
        } else {
            const newElements = elements.map((element) => {
                return {
                    ...element,
                    isInputError: false
                };
            });
            setElements(newElements);
        }

        const element = elements.find(item => item.id === id);
        if (element.type === ECustomPropertyType.PHONE && input) {
            input.setSelectionRange(telCursor, telCursor);
        }
    };

    const onChangeDate = (
        date: Date,
        id: TCustomPropertyId,
    ) => {
        const value = date ? String(date.getTime()) : null;
        setElements(elementsState.map(item => {
            return item.id === id
                ? {
                    ...item,
                    value,
                }
                : item;
        }));
        onUpdateValue(id, value);
    }

    /**
     * due to tabIndex -1 on top div focus always stays on last element,
     * so when there are 2 buttons, one will lose focus onClick and second will always stay focused
     */
    const onClickPin = (
        id: TCustomPropertyId,
        func: (id: TCustomPropertyId) => Promise<any>
    ) => {
        ref.current.focus();
        return func(id);
    }

    const onChangeSelect = (
        event: ISelectActiveInheritedProps,
        id: TCustomPropertyId,
    ) => {
        setElements(elementsState.map(item => {
            return item.id === id
                ? {
                    ...item,
                    value: event.item.value,
                }
                : item;
        }));
        onUpdateValue(id, event.item.value);
        ref.current.focus();
    }

    const onKeyDown = (
        e: React.KeyboardEvent | KeyboardEvent,
    ) => {
        if (!e) return;

        if (e.key === 'Escape') {
            setElements(getElementsClone(elements, true));
            e.stopPropagation();//to avoid aside-panel closure on esc if description is in focus
        }
    };

    function onBlur (
        id: TCustomPropertyId,
    ) {
        onClickSave(id);
        setCardHeightIfNeeded();
    }

    function onClickSave (
        id: TCustomPropertyId,
    ) {
        const element = elementsState.find(item => item.id === id);
        if (!element || element.error) return;

        if (element.type === ECustomPropertyType.EMAIL) {
            if (errorEmail(id)) return;
        } else if (element.type === ECustomPropertyType.PHONE) {
            if (errorPhone(id)) return;
        }

        onUpdateValue(id, castValue(element.value));
        setChange(false);
        setElements(elementsState.map(item => {
            return item.id === id
                ? {
                    ...item,
                    isChanged: false,
                }
                : item;
        }));
    }

    React.useEffect(() => {
        if (!isPopup) return;
        const onResize = () => {
            const button = document.querySelector(`.${CLASS_ASIDE_PANEL_ACTIONS_BUTTON_CONTAINER}--custom`) as HTMLElement;
            if (button) {
                const bottom = Math.round(button.getBoundingClientRect().bottom);
                const dropdown = document.querySelector('.card-details-section--custom') as HTMLElement
                if (dropdown) {
                    dropdown.style.maxHeight = root._innerHeight - bottom + GOOGLE_SPACING * 5 + 'px'; // плюс часть сдвига из _AsidePanelActionsButtons.scss
                }
            }
        };

        onResize();

        root.addEventListener('resize', onResize);
        return () => {
            root.removeEventListener('resize', onResize);
        }
    }, []);

    React.useEffect(() => {
        setElements(getElementsClone(elements));
    }, [elements]);

    React.useEffect(() => {
        setCardHeightIfNeeded();
    }, [elements]);

    /**
     * didMount происходит, когда asidePanel ещё не открыта
     * для autosize надо перерисовать, когда откроется
     */
    React.useEffect(() => {
        timer.current = setTimeout(() => {
            setKeyDiv(uuidv4());
        });
        return () => {
            if (timer.current) clearTimeout(timer.current);
        }
    }, []);

    return (<div
        className={`
                ${className}
                ${isCard ? classIsCard : ''}
                ${isCard ? classModule : ''}
        `}
        key={keyDiv}
        tabIndex={-1}
        ref={ref}
    >{isEdit
        ? <DragDropContext
            onDragEnd={onDragEnd}
        >
            <Droppable
                droppableId={String(cardId)}
            >
                {(provided, snapshot) => (
                    <div
                        className={CLASS_CARD_CUSTOM}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        {elementsState.map((element, index) => {
                            if (element.removed) return null;

                            const actions = isReadonly ? null
                                : <Button
                                    variant={'icon'}
                                    tooltip={ getAsidePanelTooltip({
                                        direction: 'up-left',
                                        value: CUSTOM_BUTTON_REMOVE_TOOLTIP,
                                        })
                                    }
                                    onClick={() => onDeleteHandler(element.id)}
                                >
                                    <Icon size={24} xlink={'delete'} />
                                </Button>;

                            return (
                                <Draggable
                                    draggableId={element.id}
                                    index={index}
                                    isDragDisabled={isReadonly || isPopup}
                                    key={element.id}
                                >
                                    {(provided, snapshot) => {
                                        const classType = CLASS_CARD_CUSTOM_ITEM + '--' + element.type.toLocaleLowerCase();
                                        return (
                                            <div
                                                className={CLASS_LIST_ITEM_CONTAINER}
                                                key={element.id}
                                                {...provided.draggableProps}
                                                ref={provided.innerRef}
                                            >
                                                <ListItem
                                                    actions={actions}
                                                    className={`
                                                        ${CLASS_CARD_CUSTOM_ITEM}
                                                        ${classType}
                                                    `}
                                                    isDraggable={!isReadonly && !isPopup}
                                                    text={<CustomPropertiesForm
                                                        element={element}
                                                        isPopup={isPopup}
                                                        onChange={(name, type, options) => onChangeProperty(element.id, name, type, options)}
                                                        onChangeType={onChangeType}
                                                        onEnter={onSaveHandler}
                                                        onOptionAdd={onOptionAdd}
                                                        onSortOptionsHandler={onSortOptionsHandler}
                                                    />}
                                                    {...provided.dragHandleProps}
                                                />
                                            </div>
                                        );
                                    }}
                                </Draggable>
                            );
                        })}
                        {provided.placeholder}
                        <div className={classFooter}>
                            <ButtonsGroup>
                                <Button
                                    variant={'secondary'}
                                    onClick={onCancel}
                                >
                                    {CUSTOM_BUTTON_CANCEL}
                                </Button>
                                <Button
                                    disabled={isError}
                                    variant={'primary'}
                                    onClick={onSaveHandler}
                                >
                                    {CUSTOM_BUTTON_SAVE}
                                </Button>
                            </ButtonsGroup>
                        </div>
                    </div>
                )}
            </Droppable>
        </DragDropContext>
        : <div className={CLASS_CARD_CUSTOM}>
            {elementsState.map(element => {
                const elementOriginal = elements.find(item => item.id === element.id);
                const isEmptyValue = elementOriginal && castValue(elementOriginal.value) === null && !element.isRequired;
                if (isReadonly || isHideEmpty) {
                    const hideElement = (isReadonly && isEmptyValue) || (isHideEmpty && isEmptyValue && !elementOriginal.pinned);
                    if (hideElement) {
                        return null;
                    }
                }

                const buttonSave = (
                    <Button
                        className={`
                            ${classSave}
                            ${isChanged && element.isChanged ? classSaveShow : ''}
                            ${isCard ? CARD_CONTROL_CLASS : ''}
                        `}
                        onClick={() => onClickSave(element.id)}
                    >
                        {CUSTOM_BUTTON_SAVE}
                    </Button>
                );
                let input;
                let classType = classRow;
                if (element.type === ECustomPropertyType.TEXT) {
                    input = <div className={classInput + element.id}>
                        <Tooltip
                            {...getAsidePanelTooltip({
                                className: `
                                    ${classTooltipLabel}
                                    ${isCard ? classTooltipLabel + '--card' : ''}
                                `,
                                direction: 'up-right',
                                value: escape(element.name),
                            })}
                        >
                                <span
                                    className={`
                                        ${classLabel}
                                        ${element.isRequired ? classRequired : ''}
                                        ${!element.value ? classEmpty : ''}
                                    `}
                                >
                                    {element.name}
                                </span>
                        </Tooltip>
                        <CustomPropertiesText
                            element={element}
                            tooltipClassName={classTooltip}
                            isTooltip={isCard}
                            isReadonly={isReadonly}
                            onBlur={() => onBlurInput(element.id, element.isRequired, element.value)}
                            onFocus={() => onFocus(element.id, element.isRequired)}
                            onChangeInput={onChangeInput}
                            onKeyDown={onKeyDown}
                            onClick={setCardHeightIfNeeded}
                        />
                        {buttonSave}
                    </div>;
                    classType += '--text';
                } else if (element.type === ECustomPropertyType.NUMBER) {
                    input = <div className={classInput + element.id}>
                        <Tooltip
                            {...getAsidePanelTooltip({
                                className: `
                                    ${classTooltipLabel}
                                    ${isCard ? classTooltipLabel + '--card' : ''}
                                `,
                                direction: 'up-right',
                                value: escape(element.name),
                            })}
                        >
                               <span
                                   className={`
                                        ${classLabel}
                                        ${element.isRequired ? classRequired : ''}
                                        ${!element.value ? classEmpty : ''}
                                    `}
                               >
                                    {element.name}
                                </span>
                        </Tooltip>
                        <Tooltip
                            {...getAsidePanelTooltip({
                                className: classTooltip,
                                direction: 'up-right',
                                value: isCard ? escape(element.value) : null,
                            })}
                            key={element.id}
                        >
                            <Input
                                className={`
                                    ${CLASS_CARD_CUSTOM_ITEM}
                                    ${isCard && !isReadonly ? CARD_CONTROL_CLASS : ''}
                                `}
                                state={element.isInputError ? 'error' : null}
                                autosize={false}
                                placeholder={CUSTOM_PROPERTY_TYPES[element.type]}
                                readOnly={isReadonly}
                                type={'number'}
                                value={castValue(element.value) !== null ? String(element.value) : ''}
                                onBlur={() => onBlurInput(element.id, element.isRequired, element.value)}
                                onFocus={() => onFocus(element.id, element.isRequired)}
                                onChange={(e) => onChangeInput(e, element.id)}
                                onKeyDown={(e) => onKeyDown(e)}
                                onClick={setCardHeightIfNeeded}
                            />
                        </Tooltip>
                        {buttonSave}
                    </div>;
                    classType += '--number';
                } else if (element.type === ECustomPropertyType.DATE) {
                    const valueNum = element.value ? Number(element.value) : null;
                    input = (
                        <div  className={classInput + element.id}>
                            <Tooltip
                                {...getAsidePanelTooltip({
                                    className: `
                                        ${classTooltipLabel}
                                        ${isCard ? classTooltipLabel + '--card' : ''}
                                    `,
                                    direction: 'up-right',
                                    value: escape(element.name),
                                })}
                            >
                                <span
                                    className={`
                                        ${classLabel}
                                        ${element.isRequired ? classRequired : ''}
                                        ${!element.value ? classEmpty : ''}
                                    `}
                                >
                                    {element.name}
                                </span>
                            </Tooltip>
                            <DatepickerHOC
                                className={isCard && !isReadonly ? CARD_CONTROL_CLASS : ''}
                                isSingle
                                isReadonly={isReadonly}
                                selectedFromDate={valueNum && !isNaN(valueNum) ? new Date(valueNum) : null}
                                onChange={(date) => onChangeDate(date, element.id)}
                                onKeyDown={(e) => onKeyDown(e)}
                                onBlur={() => onBlurInput(element.id, element.isRequired, element.value)}
                                onFocus={() => onFocus(element.id, element.isRequired)}
                                portalId={isCard ? ID_DATEPICKER_PORTAL : ID_ASIDE_PANEL_DATEPICKER_PORTAL}
                            />
                        </div>
                    );
                    classType += '--date';
                } else if (element.type === ECustomPropertyType.EMAIL) {
                    input = <div className={classInput + element.id}>
                        <Tooltip
                            {...getAsidePanelTooltip({
                                className: `
                                    ${classTooltipLabel}
                                    ${isCard ? classTooltipLabel + '--card' : ''}
                                `,
                                direction: 'up-right',
                                value: escape(element.error || element.name),
                            })}
                        >
                                <span
                                    className={`
                                            ${classLabel}
                                            ${element.isRequired ? classRequired : ''}
                                            ${!element.value ? classEmpty : ''}
                                        `}
                                >
                                    {element.error || element.name}
                                </span>
                        </Tooltip>
                        <Tooltip
                            {...getAsidePanelTooltip({
                                className: classTooltip,
                                direction: 'up-right',
                                value: isCard ? escape(element.value) : null,
                            })}
                            key={element.id}
                        >
                            <Input
                                className={`
                                    ${CLASS_CARD_CUSTOM_ITEM}
                                    ${isCard && !isReadonly ? CARD_CONTROL_CLASS : ''}
                                `}
                                autosize={false}
                                placeholder={CUSTOM_PROPERTY_TYPES[element.type]}
                                readOnly={isReadonly}
                                state={element.error || element.isInputError ? 'error' : null}
                                type={'email'}
                                value={castValue(element.value) !== null ? String(element.value) : ''}
                                onBlur={() => onBlurInput(element.id, element.isRequired, element.value)}
                                onFocus={() => onFocus(element.id, element.isRequired)}
                                onChange={(e) => onChangeInput(e, element.id)}
                                onKeyDown={(e) => onKeyDown(e)}
                                onClick={setCardHeightIfNeeded}
                            />
                        </Tooltip>
                        {!element.error && buttonSave}
                    </div>;
                    classType += '--email';
                } else if (element.type === ECustomPropertyType.PHONE) {
                    input = <div className={classInput + element.id}>
                        <Tooltip
                            {...getAsidePanelTooltip({
                                className: `
                                    ${classTooltipLabel}
                                    ${isCard ? classTooltipLabel + '--card' : ''}
                                    ${!element.value ? classEmpty : ''}
                                `,
                                direction: 'up-right',
                                value: escape(element.error || element.name),
                            })}
                        >
                                <span
                                    className={`
                                        ${classLabel}
                                        ${element.isRequired ? classRequired : ''}
                                        ${!element.value ? classEmpty : ''}
                                    `}
                                >
                                    {element.error || element.name}
                                </span>
                        </Tooltip>
                        <Tooltip
                            {...getAsidePanelTooltip({
                                className: classTooltip,
                                direction: 'up-right',
                                value: isCard ? escape(element.value) : null,
                            })}
                            key={element.id}
                        >
                            <Input
                                className={`
                                    ${CLASS_CARD_CUSTOM_ITEM}
                                    ${isCard && !isReadonly ? CARD_CONTROL_CLASS : ''}
                                `}
                                autosize={false}
                                placeholder={CUSTOM_PROPERTY_TYPES[element.type]}
                                readOnly={isReadonly}
                                state={element.error || element.isInputError ? 'error' : null}
                                type={'tel'}
                                value={castValue(element.value) !== null ? String(element.value) : ''}
                                onBlur={() => onBlurInput(element.id, element.isRequired, element.value)}
                                onFocus={() => onFocus(element.id, element.isRequired)}
                                onChange={(e) => onChangeInput(e, element.id)}
                                onKeyDown={(e) => onKeyDown(e)}
                                onClick={setCardHeightIfNeeded}
                            />
                        </Tooltip>
                        {!element.error && buttonSave}
                    </div>;
                    classType += '--phone';
                } else if (element.type === ECustomPropertyType.SELECT) {
                    let active: number;
                    const options = !element.options ? null : [
                        {
                            text: 'None',
                            value: null,
                        },
                        ...element.options.map((option, index) => {
                            if (element.value && element.value === option.id) active = index + 1;
                            return {
                                text: option.value,
                                value: option.id,
                            };
                        })
                    ];
                    input = <div className={classInput + element.id}>
                        <Tooltip
                            {...getAsidePanelTooltip({
                                className: `
                                    ${classTooltipLabel}
                                    ${isCard ? classTooltipLabel + '--card' : ''}
                                `,
                                direction: 'up-right',
                                value: escape(element.error || element.name),
                            })}
                        >
                                <span
                                    className={`
                                        ${classLabel}
                                        ${element.isRequired ? classRequired : ''}
                                        ${!element.value ? classEmpty : ''}
                                    `}
                                >
                                    {element.name}
                                </span>
                        </Tooltip>
                        <Select
                            active={active}
                            className={`
                                ${CLASS_CARD_CUSTOM_ITEM}
                                ${isCard && !isReadonly ? CARD_CONTROL_CLASS : ''}
                            `}
                            dropdownClassName={`
                                ${CLASS_CARD_CUSTOM_ITEM}-dropdown
                                ${isCard && !isReadonly ? CARD_CONTROL_CLASS : ''}
                            `}
                            isFitWindow={isPopup}
                            state={element.isInputError ? 'error' : null}
                            key={element.id + active}
                            options={options}
                            placeholder={element.options && element.options.length ? CUSTOM_PROPERTY_TYPES[element.type] : CUSTOM_NO_OPTION}
                            readOnly={isReadonly || !element.options || !element.options.length}
                            variant={'arrow'}
                            onChange={(event) => onChangeSelect(event, element.id)}
                            onKeyDown={(e) => onKeyDown(e)}
                            onBlur={() => onBlurInput(element.id, element.isRequired, element.value, true)}
                            onFocus={() => onFocus(element.id, element.isRequired, true)}
                            onClick={setCardHeightIfNeeded}
                        />
                        {(!element.options || !element.options.length) &&
                            <Button
                                className={classOptionAdd}
                                variant={'add'}
                                onClick={onEdit}
                            >
                                Add option
                            </Button>
                        }
                    </div>;
                    classType += '--select';
                }
                const classVisible = !isEmptyValue || elementOriginal.pinned ? `${classRow}--visible` : '';
                return (<div
                    className={`
                        ${classRow}
                        ${classType}
                        ${classVisible}
                    `}
                    key={element.id}
                >
                    {input}
                    <div className={classPin}>
                        {onTogglePin &&
                            <Button
                                className={`
                                    ${element.pinned ? classPin + '--active' : ''}
                                `}
                                variant={'icon'}
                                tooltip={getAsidePanelTooltip({
                                    value: element.pinned ? CUSTOM_HIDE : CUSTOM_SHOW
                                })}
                                aria-label={CUSTOM_SHOW}
                                onClick={() => onClickPin(element.id, onTogglePin)}
                                key={String(!!element.pinned)}
                                disabled={isReadonly}
                            >
                                <Icon
                                    size={24}
                                    xlink={'eye'}
                                />
                            </Button>
                        }
                        {onTogglePinOnCard &&
                            <Button
                                className={`
                                    ${element.pinnedOnCard ? classPin + '--active' : ''}
                                `}
                                variant={'icon'}
                                tooltip={getAsidePanelTooltip({
                                    value: element.pinnedOnCard ? CUSTOM_UNPIN : CUSTOM_PIN
                                })}
                                aria-label={CUSTOM_PIN}
                                onClick={() => onClickPin(element.id, onTogglePinOnCard)}
                                disabled={isReadonly}
                            >
                                <Icon
                                    size={24}
                                    xlink={'pin'}
                                />
                            </Button>
                        }
                    </div>
                </div>);
            })}
            {!isReadonly && isHideEmpty !== null && !isCard &&
                <CustomPropertiesHideButton
                    hidden={hidden}
                    isHide={isHideEmpty}
                    onClick={onToggleHideEmpty}
                />
            }
        </div>
    }</div>);
};
