import { ITopBarDashboardNameEvents, ITopBarDashboardNameFields } from '../../components/TopBarDashboardName/types';
import { IApplicationState } from '../../../../../../types/types';
import { onNameChanged } from './events/onNameChanged';
import { connect } from 'react-redux';
import { TopBarDashboardName } from '../../components/TopBarDashboardName/TopBarDashboardName';
import { onClose } from './events/onClose';
import { getTopBarBoardNameStatus } from '../../store/selectors/getTopBarBoardNameStatus';
import { onBoardsSettingsClicked } from './events/onBoardsSettingsClicked';
import { onBoardsActivityClicked } from './events/onBoardsActivityClicked';
import { onClickRename } from './events/onClickRename';
import { onClickName } from './events/onClickName';
import { onClickIcon } from './events/onClickIcon';
import { onDblClickName } from './events/onDblClickName';
import { onDblClickIcon } from './events/onDblClickIcon';
import { ITopBarDashboardNameHOCOwnProps } from './types';
import { getBoardLockStatus } from '../../../../../../store/model/selectors/getBoardLockStatus';
import { getBoardPermissionsAllowEdit } from '../../../../../../store/model/selectors/getBoardPermissionsAllowEdit';
import { onClickCardTemplates } from './events/onClickCardTemplates';
import { onClickRecurringTasks } from './events/onClickRecurringTasks';
import { getBoard } from '../../../../../../store/model/selectors/getBoardById';
import { onClickStarred } from './events/onClickStarred';
import { NAVIGATION_PANEL_BOARD_TOOLTIP_SHORT_NAME } from '../../../navigationPanel/constants';
import { getBoardLogo } from 'app/store/model/selectors/getBoardLogo';
import { getBoardLogoColor } from '../../../../../../store/model/selectors/getBoardLogoColor';
import { getBoardCardTemplateIdsSelector } from '../../../../aside_panel/cardTemplates/list/selectors/getBoardCardTemplateIds';
import { onClickChangeLogo } from './events/onClickChangeLogo';
import { onClickReports } from './events/onClickReports';
import { getAsidePanel } from 'app/view/react_components/aside_panel/asidePanel/store/selectors/getAsidePanel';
import { hasBoardWarning } from 'app/store/model/board/selectors/hasBoardWarning';
import { onNameSuccess } from './events/onNameSuccess';
import { getAuthUserSupport } from '../../../../../../store/model/authUser/selectors/getAuthUserSupport';
import { onTriggerFeaturePoll } from './events/onTriggerFeaturePoll';
import { getIsUserBasicPlanOnly } from '../../../../../../store/model/authUser/selectors/getIsUserBasicPlanOnly';
import { onLogoChange } from './events/onLogoChange';
import { getBoardLogoIcon } from '../../../../../../store/model/selectors/getBoardLogoIcon';
import { getShowProFeaturesTrial } from '../../../../../../store/model/authUser/selectors/getShowProFeaturesTrial';

const mapStateToProps = (
    state: IApplicationState,
    { boardId }: ITopBarDashboardNameHOCOwnProps
): ITopBarDashboardNameFields => {
    const board = getBoard(state, boardId);
    const { starred, name} = board;
    const isLong = name.length > NAVIGATION_PANEL_BOARD_TOOLTIP_SHORT_NAME;
    const supportStarred = getAuthUserSupport(state, 'supportStarred');

    return {
        dashboardName: name,
        dashboardId: boardId,
        boardNameStatus: getTopBarBoardNameStatus(state, boardId),
        isAsidePanelOpened: !!getAsidePanel(state).width,
        isBasicFeature: getIsUserBasicPlanOnly(state),
        isProFeature: getShowProFeaturesTrial(state),
        isReadOnly: !boardId || !getBoardPermissionsAllowEdit(state, boardId ),
        isBlocked: getBoardLockStatus(state, boardId),
        starred: starred && supportStarred,
        isLong,
        isWarning: hasBoardWarning(state, boardId),
        logo: getBoardLogo(state, boardId),
        logoColor: getBoardLogoColor(state, boardId),
        logoIcon: getBoardLogoIcon(state, boardId),
        recurringCount: getBoardCardTemplateIdsSelector(state, {boardId, isRecurring: true}).length,
        templatesCount: getBoardCardTemplateIdsSelector(state, {boardId, isRecurring: false}).length,
        supportStarred,
    }
};

const mapDispatchToProps = (
    dispatch: any,
    { boardId }: ITopBarDashboardNameHOCOwnProps
): ITopBarDashboardNameEvents => ({
    onClickBoardActivity: () => dispatch(onBoardsActivityClicked()),
    onClickBoardSettings: () => dispatch(onBoardsSettingsClicked()),
    onClickRename: () => dispatch(onClickRename()),
    onClickChangeLogo: () => dispatch(onClickChangeLogo()),
    onClose: () => dispatch(onClose(boardId)),
    onNameChanged: (value: string, dblClicked: boolean) => dispatch(onNameChanged(boardId, value, dblClicked)),
    onNameSuccess: () => dispatch(onNameSuccess(boardId)),
    onClickName: () => dispatch(onClickName()),
    onClickIcon: () => dispatch(onClickIcon()),
    onDblClickName: () => dispatch(onDblClickName()),
    onDblClickIcon: () => dispatch(onDblClickIcon()),
    onClickCardTemplates: () => dispatch(onClickCardTemplates()),
    onClickRecurringTasks: () => dispatch(onClickRecurringTasks()),
    onClickStarred: () => dispatch(onClickStarred(boardId)),
    onClickReports: () => dispatch(onClickReports()),
    onTriggerFeaturePoll: () => dispatch(onTriggerFeaturePoll()),
    onLogoChange: (logo) => dispatch(onLogoChange(boardId, logo))
});

export const TopBarDashboardNameHOC = connect(mapStateToProps, mapDispatchToProps)(TopBarDashboardName);
TopBarDashboardNameHOC.displayName = 'connectedTopBarDashboardNameHoc';
