import { isObjectEmpty } from './../../../../view/react_components/helpers/isObjectEmpty';
import { Store } from 'redux';
import { IApplicationState, TCardId, TListId } from '../../../../types/types';
import { CARDS_ACTION_SET, MODEL_UPDATE, TModelAction } from '../../actions/types';
import { AT_CARDS_ADD, AT_CARDS_DELETE, AT_CARDS_UPDATE } from '../../cards/actions/constants';
import { listsActionSet } from '../..';
import { listActionSet } from '../../lists/actions/listActionSet';
import { getList } from '../../list/selectors/getList';
import { cardIdsSet } from '../../list/actions/cardIdsSet';
import { getCard } from '../../selectors/getCard';
import { EMapUpdateType, TIdsMap } from '../types';

const updateListCardIdsMap = (
    state: IApplicationState,
    cardId: TCardId,
    listId: TListId,
    listCardIdsMap: TIdsMap,
    addOrRemove: EMapUpdateType
): TIdsMap => {
    if (listId) {
        const list = getList(state, listId);
        if (list) {
            if (!listCardIdsMap[listId]) {
                const { cardIds = [] } = list;
                listCardIdsMap[listId] = [...cardIds];
            }
            const index = listCardIdsMap[listId].indexOf(cardId);
            if (addOrRemove === EMapUpdateType.ADD && index === -1) {
                listCardIdsMap[listId].push(cardId);
            }
            if (addOrRemove === EMapUpdateType.REMOVE && index !== -1) {
                listCardIdsMap[listId].splice(index, 1);
            }
        }
    }
    return listCardIdsMap;
}

const updateLists = (
    store: Store<IApplicationState>,
    listCardIdsMap: TIdsMap
) => {
    if (isObjectEmpty(listCardIdsMap)) return;
    for (let listId in listCardIdsMap) {
        store.dispatch(listsActionSet(listActionSet(Number(listId), cardIdsSet(listCardIdsMap[listId]))))
    }
}

export const cardIdsMiddleware  = (store: Store<IApplicationState>) => (next: any) => (action: TModelAction) => {
    let result = null, nextExecuted = false;
    let listCardIdsMap: TIdsMap = {};
    if (action.type === CARDS_ACTION_SET){
        const subAction = action.cardsAction;
        switch (subAction.type) {
            case AT_CARDS_ADD: {
                for (let cardId in subAction.cards) {
                    const { listId } = subAction.cards[cardId];
                    if(listId) {
                        listCardIdsMap = updateListCardIdsMap(store.getState(), Number(cardId), listId, listCardIdsMap, EMapUpdateType.ADD);
                    }
                }
                break;
            }
            case AT_CARDS_DELETE: {
                for (let cardId in subAction.cards) {
                    let { listId } = subAction.cards[cardId];
                    if (!listId) {
                        const card = getCard(store.getState(), Number(cardId));
                        listId = card && card.listId;
                    }
                    if(listId) {
                        listCardIdsMap = updateListCardIdsMap(store.getState(), Number(cardId), listId, listCardIdsMap, EMapUpdateType.REMOVE);
                    }
                }
                break;
            }
            case AT_CARDS_UPDATE: {
                for (let cardId in subAction.cards) {
                    const card = subAction.cards[cardId];
                    if ('listId' in card) {
                        const storeCard = getCard(store.getState(), Number(cardId)) || {};
                        if (storeCard.listId !== card.listId) { // поменялся лист
                            // добавляем в новый лист
                            listCardIdsMap = updateListCardIdsMap(store.getState(), Number(cardId), card.listId, listCardIdsMap, EMapUpdateType.ADD);

                            // удаляем из старого листа
                            listCardIdsMap = updateListCardIdsMap(store.getState(), Number(cardId), storeCard.listId, listCardIdsMap, EMapUpdateType.REMOVE);
                        }
                    }
                }
                break;
            }
        }
    } else if (action.type === MODEL_UPDATE) {
        result = next(action);
        nextExecuted = true;
        for (let cardId in action.model.cards) {
            const { listId } = action.model.cards[cardId];
            if (listId) {
                listCardIdsMap = updateListCardIdsMap(store.getState(), Number(cardId), listId, listCardIdsMap, EMapUpdateType.ADD);
            }
        }
    }
    updateLists(store, listCardIdsMap);
    if (!nextExecuted) {
        result = next(action);
    }
    return result;
}
