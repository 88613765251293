import { IRestSubscription } from './../../../../../types/rest/IRestSubscription';
import { getRestHeadersPost } from '../../../../../rest/helpers/getRestHeadersHelper';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { fetchHandler } from '../../../../../util/fetchHandler';
import Util from '../../../../../util/util';
import { REST_TRIAL } from '../constants';
import { IRestTrial } from '../types';
import { IGetState } from '../../../../../types/types';
import { root } from '../../../../../store/constants';
import { segmentIdentifyAction } from '../../../../../middlewares/segment';
import { getNumberKeys } from '../../../../../helper/getNumberKeys';
import { boardsActionSet } from '../../../../../store/model/actions/boardsActionSet';
import { boardActionSet } from '../../../../../store/model/boards/actions/boardActionSet';
import { updateAuthPermissionsAction } from '../../../../../store/model/board/actions/updateAuthPermissionsAction';
import { updateUser } from '../../../../../store/model/authUser/effects/updateUser';

export const extendTrial = (
    feedback: string,
    trialCode: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (!trialCode) return false;

        const body: IRestTrial = {
            promoCode: trialCode
        };

        return fetchHandler<IRestSubscription[]>(
            Util.getApiUrl(REST_TRIAL + '?feedback=' + feedback), {
                ...getRestHeadersPost(),
                body: JSON.stringify(body)
            }
        )
        .then((activeSubscriptions) => {
            dispatch(updateUser({ allowFreeVersion: false, activeSubscription: activeSubscriptions }));

            getNumberKeys(getState().model.dashboards).forEach(boardId => {
                dispatch(boardsActionSet(boardActionSet(boardId, updateAuthPermissionsAction( { allowUnlimitedCards: true }))))
            })
            root.App.router.restart();
            setTimeout(() =>{
                dispatch(segmentIdentifyAction());
            }, 1000)
        });
    };
    return action;
};
