import * as deepFreeze from 'deep-freeze';
import { IPushNotificationsSettings } from '../types';
import { AT_PUSH_NOTIFICATION_SETTINGS_ACTION_SET, TPushNotificationsSettingsAction } from '../actions/types';
import { pushNotificationSettingsReducer } from '../../pushNotificationSettings/reducers/pushNotificationSettingsReducer';

const initialState: IPushNotificationsSettings = {};

const _pushNotificationsSettingsReducer = (
    state = initialState,
    action: TPushNotificationsSettingsAction
): IPushNotificationsSettings  => {
    switch (action.type) {
        case AT_PUSH_NOTIFICATION_SETTINGS_ACTION_SET:{
            const {boardId, pushNotificationAction} = action;
            return {
                ...state,
                [boardId]: pushNotificationSettingsReducer(state[boardId], pushNotificationAction)
            }
        }
        default: {
            return state;
        }
    }
};

export const pushNotificationsSettingsReducer = (
    state = initialState,
    action: TPushNotificationsSettingsAction
): IPushNotificationsSettings  => {
    // @ts-ignore
    if(window.Settings.development) {
        // @ts-ignore
        return deepFreeze(_pushNotificationsSettingsReducer(state, action));
    } else {
        return _pushNotificationsSettingsReducer(state, action);
    }
};
