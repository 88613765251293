import { ITypeSwitcherState } from '../types';
import { TTypeSwitcherAction } from '../actions/types';
import {
    AT_TYPE_SWITCHER_BOARD_TYPE_SET,
    AT_TYPE_SWITCHER_LAST_KANBAN_VIEW_SET,
} from '../actions/constants';
import * as deepFreeze from 'deep-freeze';
import { setTypeSwitcherLocalStorage } from '../localstorage/actions/setTypeSwitcherLocalStorage';
import { getInitialState } from './constants';
import { EViewTypes } from '../../../../../const';

const initialState: ITypeSwitcherState = getInitialState();

const _TypeSwitcherReducer = (
    state: ITypeSwitcherState,
    action: TTypeSwitcherAction
): ITypeSwitcherState => {
    switch (action.type) {
        case AT_TYPE_SWITCHER_BOARD_TYPE_SET: {
            return {
                ...state,
                [action.boardId]: {
                    ...state[action.boardId],
                    activeType: action.viewType
                }
            }
        }
        case AT_TYPE_SWITCHER_LAST_KANBAN_VIEW_SET: {
            return {
                ...state,
                [action.boardId]: {
                    ...state[action.boardId],
                    lastKanbanView: action.viewType
                }
            }
        }
        default:
            return state;
    }
};

export const _TypeSwitcherReducerDeepFreeze = (
    state: ITypeSwitcherState,
    action: TTypeSwitcherAction
): ITypeSwitcherState => {
    // @ts-ignore
    if(window.Settings.development){
        return deepFreeze(_TypeSwitcherReducer(state, action));
    } else {
        return _TypeSwitcherReducer(state, action);
    }
};

export const typeSwitcherReducer = (
    state = initialState,
    action: TTypeSwitcherAction
) => {
    const newState = _TypeSwitcherReducerDeepFreeze(state, action);
    if (newState !== state){
        if(!(action.type === 'TYPE_SWITCHER_BOARD_TYPE_SET' && action.viewType === EViewTypes.ARCHIVE_VIEW_TYPE))
            setTypeSwitcherLocalStorage(newState);
    }
    return  newState;
};
