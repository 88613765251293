import { IApplicationState, TTag } from '../../../../../../../../types/types';
import { connect } from 'react-redux';
import { IBoardTagsHOCProps } from './types';
import { BoardTags } from '../../components/BoardTags/BoardTags';
import { IBoardTagsEvents, IBoardTagsFields } from '../../components/BoardTags/types';
import { onChangeName } from './events/onChangeName';
import { onDelete } from './events/onDelete';
import { getPanelReadonly } from '../../../../selectors/getPanelReadonly';
import { getBoardTags } from '../../../../../../../../store/model/board/selectors/getBoardTags';

const mapStateToProps = (
    state: IApplicationState,
    { boardId }: IBoardTagsHOCProps
): IBoardTagsFields => {
    const tags = getBoardTags(state, boardId);
    const isReadonly = getPanelReadonly(state, boardId);
    return {
        tags,
        isReadonly,
    }
};

const mapDispatchToProps = (
    dispatch: any,
    ownProps: IBoardTagsHOCProps
): IBoardTagsEvents => {
    const { boardId } = ownProps;
    return {
        onChangeName: (tag: TTag, name: TTag) => dispatch(onChangeName(boardId, tag, name)),
        onDelete: (tag: TTag) => dispatch(onDelete(boardId, tag)),
    }
};

export const BoardTagsHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(BoardTags);

BoardTagsHOC.displayName = 'BoardTagsHOC';
