import {TSavedFilterAction} from '../actions/types';
import {AT_SAVED_FILTER_ACTION_SET, AT_SAVED_FILTER_SET, AT_SAVED_FILTERS_SET} from '../actions/constants';
import {filterReducer} from '../../filter/reducers/filterReducer';
import {TSavedFilters} from '../types';
import * as deepFreeze from 'deep-freeze';
import { TFilters } from '../../filter/types';

const initialState: TSavedFilters = {};

const _savedFiltersReducer = (
    state = initialState,
    action: TSavedFilterAction
): TSavedFilters => {
    switch (action.type) {
        case AT_SAVED_FILTER_SET: {
            return {
                ...state,
                [action.filterId]: action.filter
            };
        }
        case AT_SAVED_FILTERS_SET: {
            const filters: TFilters = {};
            for (let filterId in action.filters) {
                const isEnabled = state[filterId] ? state[filterId].isEnabled : false;
                filters[filterId] = {
                    ...action.filters[filterId],
                    isEnabled // больше нет на бэке
                }
            }
            return {
                ...state,
                ...filters
            }
        }
        case AT_SAVED_FILTER_ACTION_SET: {
            const { filterId, savedFilterAction } = action;
            return {
                ...state,
                [filterId]: filterReducer(state[filterId], savedFilterAction)
            };
        }
        default:
            return state;
    }
};
export const savedFiltersReducer = (
    state = initialState,
    action: TSavedFilterAction
): TSavedFilters => {
    // @ts-ignore
    if(window.Settings.development) {
        // @ts-ignore
        return deepFreeze(_savedFiltersReducer(state, action));
    } else {
        return _savedFiltersReducer(state, action);
    }
};
