import { connect } from 'react-redux';
import { ICardHOCProps } from '../CardHOC/types';
import { onClick } from './events/onClick';
import { CardUpdates } from '../../components/CardUpdates/CardUpdates';
import { ICardUpdatesEvents } from '../../components/CardUpdates/types';

const mapDispatchToProps = (
    dispatch: any,
    { card }: ICardHOCProps
): ICardUpdatesEvents => ({
    onClick: () => dispatch(onClick(card))
});

export const CardUpdatesHOC = connect(
    null,
    mapDispatchToProps
)(CardUpdates);
CardUpdatesHOC.displayName = 'CardUpdatesHOC';
