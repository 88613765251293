import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { SegmentCardEvent, SegmentCardOptions, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { SegmentCardCommentEditTargetOptionValue } from '../../../../../../../../middlewares/segment/trackEntities/cardEvents';

export const onEdit = (): ThunkAction => {
    return (dispatch: Dispatch) => {
        dispatch(segmentTrackAction(SegmentCardEvent.CLICKED_ON_COMMENT, {
            name: SegmentCardOptions.TARGET,
            value: SegmentCardCommentEditTargetOptionValue.TIMING
        }));
    };
};
