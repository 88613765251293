import { connect } from 'react-redux';
import { EMPTY_ARRAY, IApplicationState } from '../../../../../../../types/types';
import { IAssigneesHOCOwnProps } from './types';
import { IAssigneesEvents, IAssigneesFields, IAssigneesCardAssignee } from '../../components/AssigneesSection/types';
import { AssigneesSection } from '../../components/AssigneesSection/AssigneesSection';
import { onDelete } from './events/onDelete';
import { onSort } from './events/onSort';
import { getCard } from '../../../../../../../store/model/selectors/getCard';
import { getSectionType } from '../../../selectors/getSectionType';
import { onOpen } from './events/onOpen';
import { getBoardIsCardDefaultProperty } from '../../../../../../../store/model/board/selectors/getBoardIsCardDefaultProperty';
import { EAsidePanelProperty } from '../../../../asidePanel/components/AsidePanel/types';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { getCardAssigneesCreateSelector, getCardAssigneesNoReselect } from './selectors/getCardAssignees';
import { onToggleRole } from './events/onToggleRole';
import { onEditRoles } from './events/onEditRoles';
import {
    getBoardIsCardRequiredProperty
} from '../../../../../../../store/model/board/selectors/getBoardIsCardRequiredProperty';
import { getAuthUser } from '../../../../../../../store/model/authUser/selectors/getAuthUser';
import { onClickDisallow } from './events/onClickDisallow';
import { isEqual } from 'underscore';

const mapStateToProps = () => {
    // const getCardAssignees = getCardAssigneesCreateSelector();
    interface ICaheProps extends IAssigneesFields{};
    let cache: ICaheProps = null;
    const getCache = (
        value: ICaheProps
    ): ICaheProps => {
        if (isEqual(value, cache)) {
            return cache;
        }
        cache = value;
        return value;
    };

    const _mapStateToProps = (
        state: IApplicationState,
        ownProps: IAssigneesHOCOwnProps
    ): IAssigneesFields => {
        const { allowRoles } = getAuthUser(state);
        const { boardId, cardId, sectionType: sectionTypeOwn } = ownProps;
        const card = getCard(state, cardId);
        const sectionType = getSectionType(state, boardId, cardId, sectionTypeOwn);
        let elements: IAssigneesCardAssignee[] = null;

        if (card) {
            const assignees = card.assignees;
            if (assignees && assignees.length) {
                elements = getCardAssigneesNoReselect(state, boardId, cardId);
            }
        }
        return getCache({
            allowRoles,
            boardId,
            cardId,
            elements: elements && elements.length
                ? elements
                : sectionType !== ESettingsSectionType.READONLY
                    ? EMPTY_ARRAY
                    : null,
            sectionType,
            inWorkUsers: card.inWorkUsers || EMPTY_ARRAY,
            isDefaultProperty: getBoardIsCardDefaultProperty(state, boardId, EAsidePanelProperty.CARD_ASSIGNEES),
            isRequired: getBoardIsCardRequiredProperty(state, boardId, EAsidePanelProperty.CARD_ASSIGNEES)
        })
    };
    return _mapStateToProps;
};

const mapDispatchToProps = (
    dispatch: any,
    { cardId, boardId }: IAssigneesHOCOwnProps
): IAssigneesEvents => {
    return {
        onClickDisallow: () => dispatch(onClickDisallow()),
        onDelete: (permissionId) => dispatch(onDelete(boardId, cardId, permissionId)),
        onSort: (permissionId, index, permissionIds) => dispatch(onSort(cardId, permissionId, index, permissionIds)),
        onOpen: () => dispatch(onOpen()),
        onToggleRole: (permissionId, roleId) => dispatch(onToggleRole(cardId, permissionId, roleId)),
        onEditRoles: () => dispatch(onEditRoles())
    }
};

export const AssigneesSectionHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(AssigneesSection);

AssigneesSectionHOC.displayName = 'AssigneesSectionHOC';
