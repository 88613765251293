import * as React from 'react';
import './_CardChecklistItems.scss';
import { CLASS_CARD_DETAILS } from '../../../constants';
import { ICardChecklistItemsProps } from './types';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { Draggable } from 'react-beautiful-dnd';
import { CardChecklistItemHOC } from '../../hocs/CardChecklistItemHOC/CardChecklistItemHOC';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { CHECKLIST_DROPPABLE_LABEL } from './constants';

export function CardChecklistItems({
    checklistId,
    checklistItems,
    isArchive,
    isDraggingOver,
    isHideDoneChecklistItems,
    placeholder,
    sectionType,
}: ICardChecklistItemsProps) {
    const { boardId, cardId } = React.useContext(AsidePanelContext);
    const isReadonly = sectionType === ESettingsSectionType.READONLY;

    const className = CLASS_CARD_DETAILS + '__checklist-items';

    return (
        <div
            className={`
                ${className}
                ${isReadonly ? className + '--readonly' : ''}
            `}
        >
            {checklistItems && checklistItems.map((item, index) => {
                return (
                    <Draggable
                        draggableId={String(item.id)}
                        index={index}
                        key={String(item.id)}
                        isDragDisabled={isReadonly}
                    >
                        {(provided, snapshot) => {
                            return <CardChecklistItemHOC
                                draggableProvided={provided}
                                checklistId={checklistId}
                                itemId={item.id}
                                cardId={cardId}
                                boardId={boardId}
                                isDrag={snapshot.isDragging}
                                isHideDoneChecklistItems={!isReadonly && !isArchive && isHideDoneChecklistItems}
                            />;
                        }}
                    </Draggable>
                );
            })}
            {placeholder}
            {(!checklistItems || !checklistItems.length) && isDraggingOver &&
                <div className={className + '-drop-area'}>
                    {CHECKLIST_DROPPABLE_LABEL}
                </div>
            }
        </div>
    );
};
