import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { closeDialog } from '../../../effects/closeDialog';
import { getController } from '../../../../../../../store/constants';
import { openBoardsDialog } from '../../../effects/openDialog';

export const onClickButtonUpgradeMyPlan: ActionCreator<ThunkAction> =
() => {
    const action = (dispatch: Dispatch) => {
        dispatch(closeDialog());
        getController().showPayment(
            () => dispatch(openBoardsDialog())
        ); // todo achtung backbone controller used
    };
    return action;
};
