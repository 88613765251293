import * as React from 'react';
import { ITimeSelectProps } from './types';
import { Select } from 'kui';
import { ISelectActiveInheritedProps, ISelectOptionsArray } from 'kui';
import * as moment from 'moment';
import { AsidePanelContext } from '../AsidePanel/constants';

export function AsidePanelTimeSelect({
    date,
    className,
    isNotValid,
    onChange,
    label,
    isReadonly
}: ITimeSelectProps) {
    const { isUserTimeFormat12 } = React.useContext(AsidePanelContext);
    const selectRef = React.useRef(null);
    const timeInputRef = React.useRef(null);

    let [timeOptions, setTimeOptions] = React.useState([] as ISelectOptionsArray);
    let [timeActive, setTimeActive] = React.useState(null as number);
    let [timeValue, setTimeValue] = React.useState(null as string);

    const formatTime = isUserTimeFormat12 ? 'hh:mm A' : 'HH:mm';
    const timeString = date ? moment(date).format(formatTime) : '';
    const defaultDate = moment().startOf('day').add(1, 'day').toDate();
    const dateWithoutTime = moment(date || defaultDate).startOf('day').valueOf()

    const buildTimeOptions = () => {
        const dateMs = date && date.getTime ? date.getTime() : null;

        timeOptions = [];
        timeActive = null;

        for (let i = 0; i < 24; i++) {
            const value = dateWithoutTime + i * 60 * 60 * 1000;

            if (timeActive === null && dateMs) {
                if (value === dateMs) {
                    timeActive = i;
                } else if (value > dateMs) {
                    timeActive = i;
                    timeOptions.push({
                        text: timeString,
                        value: dateMs,
                    });
                }
            }
            timeOptions.push({
                text: moment(value).format(formatTime),
                value,
            });
        }
        setTimeOptions(timeOptions);
        setTimeActive(timeActive);
        setTimeValue(timeString);
    }

    React.useEffect(() => {
        if (!timeInputRef.current && selectRef.current) {
            timeInputRef.current = selectRef.current.querySelector('input');
        }
    });

    React.useEffect(() => {
        buildTimeOptions();
    }, [date]);

    function getFormattedTimeString (timeString: string) {
        return moment(timeString, formatTime);
    }

    function isTimeInvalid(timeString: string) {
        const m = getFormattedTimeString(timeString);
        const maxLength = isUserTimeFormat12 ? 8 : 5;
        return isNaN(m.hours()) || isNaN(m.minutes()) || timeString.length > maxLength;
    }

    function validateInput(target: HTMLInputElement) {
        if (isTimeInvalid(target.value)) {
            setLastTime();
            setTimeValue(timeValue);//todo move to setLastTime
        } else {
            setTimeValue(timeInputRef.current.value);
        }
    }

    function setLastTime() {
        if (timeInputRef.current) {
            timeInputRef.current.value = timeValue;
        }
    }

    const onTimeChange = (event: ISelectActiveInheritedProps) => {
        if (event.item) {
            onChange(new Date(Number(event.item.value)));
        } else {
            validateInput(event.target as HTMLInputElement);
        }
    }

    const onTimeChanged = (
        event: React.SyntheticEvent
    ) => {
        const target = event.target as HTMLInputElement;
        if (target && target.value) {
            const m = getFormattedTimeString(target.value);
            if (isTimeInvalid(target.value)) {
                setLastTime();
                return;
            }

            const time = moment(dateWithoutTime).hours(m.hours()).minutes(m.minutes());
            const formattedTime = time.format(formatTime);
            const dateTime = time.toDate();
            if (timeInputRef.current) {
                timeInputRef.current.value = formattedTime;
            }
            setTimeValue(formattedTime);
            onChange(dateTime);
            event.stopPropagation();
        }
    }

    return <Select
        className={className}
        active={timeActive}
        editable={true}
        label={label}
        isFitWindow={true}
        options={timeOptions}
        ref={selectRef}
        state={isNotValid ? 'error' : null}
        value={timeValue}
        onChange={onTimeChange}
        onEnter={onTimeChanged}
        onBlur={onTimeChanged}
        readOnly={isReadonly}
    />
}
