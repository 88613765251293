import { IApplicationState } from '../../../../types/types';
import { getAuthUser } from './getAuthUser';
import { ILoginSpecificBoard } from 'app/types/rest/IUserMeta';

export const getUserLoginSpecificBoard = (
    state: IApplicationState
): ILoginSpecificBoard => {
    const user = getAuthUser(state);
    return user.meta && user.meta.loginSpecificBoard;
};
