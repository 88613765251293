import { IApplicationState, TListId } from '../../../types/types';
import { TStatus } from '../../../types/model';
import { ICard } from '../card/types';
import { getList } from '../list/selectors/getList';
import { getCard } from './getCard';

export const getListCardsByStatuses = (
    state: IApplicationState,
    listId: TListId,
    statuses: TStatus[]
): ICard[] => {
    const list = getList(state, listId);
    if (
        !list ||
        !list.cardIds ||
        !list.cardIds.length ||
        !statuses ||
        statuses.length < 1
    ) return [];

    const cards = list.cardIds.reduce((arr, cardId) => {
        const card = getCard(state, cardId);
        if (!card) return arr;

        if (statuses.includes(card.status)) {
            arr.push(card);
        }
        return arr;
    }, []);
    return cards;
};
