import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { SegmentBoardEvent, segmentTrackAction } from '../../../../../../../../../middlewares/segment';
import { IGetState, TBoardId } from '../../../../../../../../../types/types';
import {
    SegmentBoardAutoStartSourceValue,
    SegmentBoardOption,
    SegmentBoardOptionStatusValue
} from '../../../../../../../../../middlewares/segment/trackEntities/boardEvents';
import { boardSetAutoStartDateMeta } from '../../../../../../../../../rest/effects/board/boardSetAutoStartDateMeta';
import { getBoardMetaAutoStartDate } from '../../../../../../../../../store/model/selectors/getBoardMetaAutoStartDate';

export const onAutoStartDateToggle = (
    boardId: TBoardId,
    source: SegmentBoardAutoStartSourceValue
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const value = !getBoardMetaAutoStartDate(getState(), boardId);
        dispatch(boardSetAutoStartDateMeta(boardId, value));
        dispatch(segmentTrackAction(SegmentBoardEvent.AUTO_START_DATE_TOGGLED, {
            name: SegmentBoardOption.VALUE,
            value: value ? SegmentBoardOptionStatusValue.ON : SegmentBoardOptionStatusValue.OFF
        }, [{
            name: SegmentBoardOption.SOURCE,
            value: source
        }]));
    };
    return action;
};
