import { TCustomOptionId, TCustomPropertyId } from '../../types/types';

export interface ICustomOption {
    id: TCustomOptionId;
}

export interface ICustomProperty {
    id: TCustomPropertyId;
}

export enum ECustomPropertyType {
    DATE = 'DATE',
    EMAIL = 'EMAIL',
    NUMBER = 'NUMBER',
    PHONE = 'PHONE',
    SELECT = 'SELECT',
    TEXT = 'TEXT',
}

export interface IBoardCardCustomOption extends ICustomOption {
    value?: string;
}

export interface IBoardCardCustomProperty extends ICustomProperty {
    name: string;
    options?: IBoardCardCustomOption[];
    orderNumber: number;
    type: ECustomPropertyType;
}

export interface ICardCustomProperty extends ICustomProperty {
    value?: string;
    pinned?: boolean;
    pinnedOnCard?: boolean;
}

export type IBoardCardCustomProperties = {
    [customPropertyId: string]: IBoardCardCustomProperty;
};

export type ICardCustomProperties = {
    [customPropertyId: string]: ICardCustomProperty;
};

export interface ICardCustomPropertyData {
    name: string;
    type: ECustomPropertyType;
    value: string;
    pinned?: boolean;
}
