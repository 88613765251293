import * as React from 'react';
import { IFilterIconProps } from './types';
import { getAsidePanelTooltip } from '../../../../../aside_panel/asidePanel/helpers/getAsidePanelTooltip';
import { FILTER_ICON_TOOLTIP } from './constants';
import { UserpicStorage } from '../../../UserpicStorage/UserpicStorage';
import { Logo } from '../../../Logo/components/Logo/Logo';
import { ELogoSize } from '../../../Logo/components/Logo/types';

export function FilterIcon({
    logo,
    name,
    photoUrl,
    userName
}: IFilterIconProps) {
    return (
        <>
            {photoUrl && (!logo || !logo.icon) ?
                <UserpicStorage
                    src={photoUrl}
                    size={24}
                    tooltip={getAsidePanelTooltip({
                        value: FILTER_ICON_TOOLTIP + userName
                    })}
                />
                : <Logo name={name} logo={logo} isChangeColorOnReset />
            }
        </>
    );
}
