import {IGetState, TBoardId} from '../../../../../../../../../types/types';
import {Dispatch, ThunkAction} from '../../../../../../../../../types/actions';
import {getSectionType} from '../../../../../selectors/getSectionType';
import {ESettingsSectionType} from '../../../../../../../base/components/SettingsSection/types';
import {EBackupExportType} from '../../../../../../../../../types/rest/IRestBackup';
import { loadAutoBackups } from '../../../effects/loadAutoBackups';

export const onDidMount = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        const sectionType = getSectionType(getState(), boardId, null, 'allowAutoBackupXml', 'allowCreateBackUp');
        if (sectionType === ESettingsSectionType.DEFAULT) {
            dispatch(loadAutoBackups(boardId, EBackupExportType.XML));
        }
    };
    return action;
};
