import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../../../../types/types';
import { cardDeleteEpic } from '../../../../../../../../rest/effects/card/card/cardDeleteEpic';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';

export const onDelete = (
    subcardId: TCardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.REMOVED_SUBCARD));
        return dispatch(cardDeleteEpic(subcardId));
    };
    return action;
};
