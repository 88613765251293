import { IABTest } from '../types';
import { IABTestCurrentRest } from '../rest/types';

// выбираем новые тесты для юзера, в которых он ее не участвует
export const getNewOnLoadABTests = (
    abTests: IABTest[],
    currentUserTests: IABTestCurrentRest[]
): IABTest[] => {
    return abTests
        .filter(test => test.executeOnLoad) // только тесты, которые выполняются при загрузке
        .filter((test) => {
            const currentUserTest = currentUserTests.find((c) => c.name === test.name);
            return !currentUserTest;
        });
}
