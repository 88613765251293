import { IGetState } from '../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { TTabKey } from '../../../../../dialogs/openBoards/store/types';
import { getDrivesByTabKey } from '../../../../../dialogs/openBoards/store/drives/selectors/getDrivesByTabKey';
import { ISearchSelectOption } from '../../../../../base/components/SearchSelect/types';
import { isAllowCreateBoard } from '../../../../../dialogs/openBoards/store/drives/selectors/isAllowCreateBoard';
import OpenBoardsDialogView from 'app/view/main/openboards_dialog_view';
import store from '../../../../../../../store/configureStore';

export const onLoadOptions = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return new Promise((resolve, reject) => {
            const openBoardsDialogView = new OpenBoardsDialogView({
                store
            });
            openBoardsDialogView.initOpenBoardsStore()
                .then(() => {
                    import(/* webpackChunkName: "openBoards" */ 'app/view/react_components/dialogs/openBoards/effects/loadDrives')
                        .then(module => dispatch(module.loadDrivesFromBack()))
                        .then(() => {
                            let options: ISearchSelectOption[] = [];
                            const drives = getDrivesByTabKey(getState(), TTabKey.sharedDrives);
                            drives.map((drive) => {
                                const { name, id } = drive;
                                if (isAllowCreateBoard(getState(), drive)) {
                                    options.push({
                                        text: name,
                                        value: id
                                    });
                                }
                            })
                            return resolve(options);
                        })
                        .catch(e => console.error(e))
                });
        });
    };
    return action;
}
