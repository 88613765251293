import * as React from 'react';
import { createRoot, Root } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import { UpdatesModalHOC } from '../../hocs/UpdatesModalHOC/UpdatesModalHOC';
import { IUpdate } from '../UpdatesModal/types';

export class UpdatesModalView {
    el: Element;
    root: Root;
    store: Store;
    updates: IUpdate[];

    constructor (
        store: Store,
        updates: IUpdate[],
    ) {
        this.store = store;
        this.updates = updates;
        if (this.el) this.remove();
        this.el = document.createElement('div');
        this.el.className = 'updates-modal-placeholder';
        document.body.append(this.el);
        this.root = createRoot(this.el!);
        this.render();
    }

    render () {
        this.root.render(
            <Provider store={this.store}>
                <UpdatesModalHOC updates={this.updates} />
            </Provider>
        );
    }

    remove () {
        if (!this.el) return;

        this.root.unmount();
        this.el.remove();
        this.el = null;
    }
}
