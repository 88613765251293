import { EViewTypes } from '../../../../../../const';
import { IApplicationState, TBoardId } from '../../../../../../types/types';
import { getTypeSwitcherActiveType } from '../../../../typeSwitcher/store/selectors/getTypeSwitcherActiveType';

export const getIsSwimlanesView = (
    state: IApplicationState,
    boardId: TBoardId,
): boolean => {
    const type = getTypeSwitcherActiveType(state, boardId);
    return type === EViewTypes.SWIM_COLOR_TAGS_VIEW_TYPE ||
        type === EViewTypes.SWIM_TAGS_VIEW_TYPE;
};
