import {ISharingDialogProps, ISharingDialogItem} from './types';
import * as React from 'react';
import { ISelectActiveInheritedProps, Icon, LoaderBlock, Modal, Select, SelectList, SelectListItem, Tag, Tags } from 'kui';
import './_SharingDialog.scss';
import {
    CLASS_SHARING_DIALOG,
    PORTAL_ID_SHARING_DIALOG,
    SHARE_DIALOG_BUTTON_CANCEL,
    SHARE_DIALOG_BUTTON_DONE,
    SHARE_DIALOG_INPUT_PLACEHOLDER,
    SHARE_DIALOG_NO_RESULTS,
    SHARE_DIALOG_PEOPLE_WITH_ACCESS,
    SHARE_DIALOG_ROLE_DEFAULT,
    SHARE_DIALOG_ROLE_ICONS,
    SHARE_DIALOG_ROLE_OPTIONS,
    SHARE_DIALOG_TITLE
} from './constants';
import { IGraphPeopleSearchItem } from 'app/helper/authorisation/microsoft/graph/api/peopleSearch';
import { EFilePermissionRole } from 'app/helper/authorisation/microsoft/graph/api/getFilePermissions';
import { validateEmail } from 'app/view/react_components/helpers/emailHelper';
import { SharingDialogItem } from '../SharingDialogItem/SharingDialogItem';

export function SharingDialog ({
    msAllowEditFilePermissionRole,
    msAllowDeleteFilePermission,
    loadPermissions,
    onAdd,
    onClose,
    onDelete,
    onSearch,
    onUpdate,
}: ISharingDialogProps) {
    let [search, _setSearch] = React.useState('');
    const [items, setItems] = React.useState<ISharingDialogItem[]>([]);
    const [isSync, setSync] = React.useState(true);
    const [searchItems, setSearchItems] = React.useState<IGraphPeopleSearchItem[]>([]);
    const [addRole, setAddRole] = React.useState(SHARE_DIALOG_ROLE_DEFAULT);
    const [addItems, setAddItems] = React.useState<IGraphPeopleSearchItem[]>([]);
    const [isSearching, setSearching] = React.useState(true);
    const debounce = React.useRef(null);

    const className = CLASS_SHARING_DIALOG;
    const portalId = PORTAL_ID_SHARING_DIALOG;

    const setSearch = (value: string) => {
        search = value;
        _setSearch(value);
    }

    const onClickDone = () => {
        setSync(true);
        onAdd(addItems.map(item => item.email), addRole)
            .then(() => {
                setSearch('');
                setAddItems([]);
                setAddRole(SHARE_DIALOG_ROLE_DEFAULT);
                return loadPermissions();
            })
            .then((result) => {
                setItems(result);
            })
            .finally(() => {
                setSync(false);
            });
    }

    const searchHandler = () => {
        setSearching(true);

        onSearch(search)
            .then(result => {
                let idsNotNew = [
                    ...items.map(items => items.id),
                    ...addItems.map(item => item.id),
                ];
                items.forEach(item => {
                    if (item.role === EFilePermissionRole.OWNER) {
                        const granted = item.granted &&
                            item.granted.length &&
                            item.granted[0];
                        idsNotNew.push(granted && granted.id);
                    }
                });
                let itemsNew = result.filter(item => {
                    return !idsNotNew.includes(item.id);
                });
                if (
                    !itemsNew.length &&
                    search &&
                    validateEmail(search) &&
                    !idsNotNew.includes(search)
                ) {
                    itemsNew.push({
                        displayName: search,
                        email: search,
                        id: search,
                    });
                }
                setSearchItems(itemsNew);
            })
            .finally(() => {
                setSearching(false);
            });
    }

    const onChangeHandler = (event: ISelectActiveInheritedProps) => {
        const target = event.target as HTMLInputElement;
        if (event.item) {
            setAddItems([
                ...addItems,
                searchItems[event.item.index]
            ]);
            setSearch('');
        } else {
            setSearch(target.value);
            if (debounce.current) clearTimeout(debounce.current);
            debounce.current = setTimeout(searchHandler, 500);
        }
    }

    const onClearTag = (index: number) => {
        const items = [...addItems];
        items.splice(index, 1);
        setAddItems(items);
    }

    const onChangeRole = (
        id: string,
        event: ISelectActiveInheritedProps,
    ) => {
        const item = event.item;
        if (!item) return;

        const permission = items.find(a => a.id === id);
        let isChange = false;
        if (item.value) {
            if (permission && permission.role !== item.value &&
                msAllowEditFilePermissionRole &&
                !permission.sharedLink // линки нельзя редактировать
            ) {
                isChange = true;
                onUpdate(id, item.value as EFilePermissionRole)
                    .then((result) => {
                        const itemsNew = items.map(permission => {
                            if (permission.id === id) {
                                return result;
                            } else {
                                return permission;
                            }
                        });
                        setItems(itemsNew);
                    });
            }
        } else if (msAllowDeleteFilePermission ||
            permission.sharedLink // линки все могут удалять
        ) {
            isChange = true;
            onDelete(id)
                .then((result) => {
                    const itemsNew = items.filter(permission => permission.id !== id);
                    setItems(itemsNew);
                });
        }

        if (isChange) {
            const itemsNew = items.map(permission => {
                if (permission.id === id) {
                    return {
                        ...permission,
                        isLoading: true,
                        role: item.value as EFilePermissionRole,
                    }
                } else {
                    return permission;
                }
            });
            setItems(itemsNew);
        }
    }

    React.useEffect(() => {
        loadPermissions()
            .then((result) => {
                setItems(result);
            })
            .finally(() => {
                setSync(false);
            });

        return () => {
            if (debounce.current) clearTimeout(debounce.current);
        }
    }, []);

    return (
        <Modal
            className={className}
            title={SHARE_DIALOG_TITLE}
            buttons={[
                {
                    text: SHARE_DIALOG_BUTTON_CANCEL,
                    onClick: onClose
                },
                {
                    autoFocus: true,
                    disabled: !addItems || !addItems.length,
                    text: SHARE_DIALOG_BUTTON_DONE,
                    isOpenedAfterClick: true,
                    isPrimary: true,
                    onClick: onClickDone
                }
            ]}
            variant={'actions'}
            tabIndex={-1}
            onClose={onClose}
        >
            {!!addItems.length &&
                <div className={`${className}__add-section`}>
                    <Tags className={`${className}__add-items`}>
                        <div className={`${className}__add-items-title`}>
                            {SHARE_DIALOG_INPUT_PLACEHOLDER}:
                        </div>
                        {addItems.map((item, index) => {
                            return <Tag
                                className={`${className}__add-item`}
                                key={item.id}
                                onClear={() => onClearTag(index)}
                                onClick={() => {}}
                            >
                                {item.displayName}
                            </Tag>
                        })}
                    </Tags>
                    <div
                        className={`${className}__add-roles-div`}
                    >
                        <Select
                            active={SHARE_DIALOG_ROLE_OPTIONS.findIndex(role => role === addRole)}
                            className={`${className}__add-roles`}
                            dropdownClassName={`${className}__add-roles-dd`}
                            isFitWindow
                            portal
                            portalId={portalId}
                            variant={'arrow'}
                            onChange={(e) => setAddRole(e.item.value as EFilePermissionRole)}
                        >
                            <SelectList fixActive={false}>
                                {SHARE_DIALOG_ROLE_OPTIONS.map(option => (
                                    <SelectListItem
                                        icon={SHARE_DIALOG_ROLE_ICONS[option]}
                                        key={option}
                                        value={option}
                                    >
                                        {option}
                                    </SelectListItem>
                                ))}
                            </SelectList>
                        </Select>
                        <Icon
                            className={`${className}__add-roles-icon`}
                            xlink={SHARE_DIALOG_ROLE_ICONS[addRole]}
                        />
                    </div>
                </div>
            }
            <Select
                className={`${className}__input`}
                dropdownClassName={`${className}__input-dd`}
                editable={true}
                isFitWindow
                label={SHARE_DIALOG_INPUT_PLACEHOLDER}
                placeholder={SHARE_DIALOG_INPUT_PLACEHOLDER}
                portal
                portalId={portalId}
                value={search}
                variant={'arrow'}
                onChange={onChangeHandler}
                onOpen={searchHandler}
            >
                <SelectList
                    fixActive={false}
                    loading={isSearching}
                >
                    {searchItems.map(item => {
                        return (
                            <SelectListItem
                                key={item.id}
                                value={item.id}
                            >
                                {item.displayName}
                                <div className={`${className}__email`}>
                                    {item.email}
                                </div>
                            </SelectListItem>
                        );
                    })}
                    {!isSearching && !searchItems.length &&
                        <div className={`${className}__input-search-empty disabled`}>
                            {SHARE_DIALOG_NO_RESULTS}
                        </div>
                    }
                    <LoaderBlock />
                </SelectList>
            </Select>
            {!!items.length &&
                <div className={`${className}__people-with-access`}>
                    <div className={`${className}__people-with-access-title`}>
                        {SHARE_DIALOG_PEOPLE_WITH_ACCESS}
                    </div>
                    {items.map(item => {
                        return <SharingDialogItem
                            item={item}
                            msAllowEditFilePermissionRole={msAllowEditFilePermissionRole}
                            msAllowDeleteFilePermission={msAllowDeleteFilePermission}
                            key={item.id}
                            onChangeRole={(e)=>onChangeRole(item.id, e)}
                        />
                    })}
                </div>
            }
            {isSync && <LoaderBlock />}
        </Modal>
    )
};
