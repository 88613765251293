import { getMessages } from '../../../../../../../store/constants';
import { ECardChecklistOrderMode } from '../../../../../../../types/rest/IUserMeta';

export const CARD_CHECKLIST_SORTING = [
    {
        name: getMessages().getText('card_form_checklist.sort.manual'),
        value: ECardChecklistOrderMode.MANUAL
    },
    {
        name: getMessages().getText('card_form_checklist.sort.done'),
        value: ECardChecklistOrderMode.DONE_FIRST
    },
    {
        name: getMessages().getText('card_form_checklist.sort.undone'),
        value: ECardChecklistOrderMode.DONE_LAST
    },
];
