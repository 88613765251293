import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { ENotificationScope } from '../../../../../../../../../types/rest/IRestNotificationSettings';
import { scopeEventsMap } from '../constants';
import { segmentTrackAction } from '../../../../../../../../../middlewares/segment';

export const onScopeClick = (
    scope: ENotificationScope
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        if (scopeEventsMap[scope]) {
            dispatch(segmentTrackAction(scopeEventsMap[scope]));
        }
    };
    return action;
};
