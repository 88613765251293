import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../../../../types/types';
import { SegmentCardEvent, SegmentCardOptions, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { getCard } from '../../../../../../../../store/model/selectors/getCard';
import { SegmentCardOptionStatusValue } from '../../../../../../../../middlewares/segment/trackEntities/cardEvents';
import { toggleCardWatch } from '../effects/toggleCardWatch';

export const onToggle = (
    cardId: TCardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const card = getCard(getState(), cardId);
        dispatch(segmentTrackAction(SegmentCardEvent.WATCH_CARD, {
            name: SegmentCardOptions.STATUS,
            value: card.wtch ? SegmentCardOptionStatusValue.OFF : SegmentCardOptionStatusValue.ON
        }));

        dispatch(toggleCardWatch(cardId));
    };
    return action;
};
