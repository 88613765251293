import { connect } from 'react-redux';
import { Notifications } from '../../components/Notifications/Notifications';
import { IApplicationState } from '../../../../../types/types';
import { INotificationsEvents, INotificationsFields } from '../../components/Notifications/types';
import { getAuthUser } from 'app/store/model/authUser/selectors/getAuthUser';
import { getCurrentNavigationPanelType } from '../../../main/navigationPanel/helpers/getCurrentNavigationPanelType';
import { ENavigationPanelTypes } from '../../../main/navigationPanel/constants';
import { onOpen } from './events/onOpen';
import { onClose } from './events/onClose';
import {
    isOpenNavigationPanelSelector
} from '../../../main/navigationPanel/store/selectors/isOpenNavigationPanelSelector';
import { INotificationsHOCProps } from './types';

const mapStateToProps = (
    state: IApplicationState,
    { isNotificationsOpen }: INotificationsHOCProps
): INotificationsFields => {
    let { unseenNotificationCount, id: userId, anonym} = getAuthUser(state);
    if (anonym){
        userId = '0';
    }
    const navigationPanelType = getCurrentNavigationPanelType(state);
    const isReturnFocus = navigationPanelType !== ENavigationPanelTypes.NP_COMPACT_BIG_TYPE;

    return {
        isForceClose: navigationPanelType === ENavigationPanelTypes.NP_MOBILE_TYPE && !isOpenNavigationPanelSelector(state),
        isNotificationsOpen,
        isReturnFocus,
        unseenNotificationCount,
        userId,
    }
}

const mapDispatchToProps = (
    dispatch: any,
    { updateNotificationsOpen }: INotificationsHOCProps
): INotificationsEvents => ({
    onOpen: (openNavPanel) => dispatch(onOpen(openNavPanel)),
    onClose: (isClick) => dispatch(onClose(isClick)),
    updateNotificationsOpen
});

export const NotificationsHOC = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Notifications);
NotificationsHOC.displayName = 'NotificationsHOC';
