import {TCardId} from '../../../../types/types';
import {Dispatch, ThunkAction} from '../../../../types/actions';
import {ICards} from '../../../../store/model/cards/types';
import { cardsRestPatch } from './api/helper/cardsRestPatch';

export const cardSetBackgroundColor = (
    cardId: TCardId,
    backgroundColor: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        const cards: ICards = {
            [cardId]: {
                backgroundColor:backgroundColor
            }
        };

        return dispatch(cardsRestPatch(cards));
    };
    return action;
};
