import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../types/types';
import { AccessibilityStatement } from '../../components/AccessibilityStatement';
import { IAccessibilityStatementEvents, IAccessibilityStatementFields } from '../../components/types';
import { onClickBack } from './events/onClickBack';
import { onClickContact } from './events/onClickContact';
import { onDidMount } from './events/onDidMount';

const mapStateToProps = (
    state: IApplicationState,
): IAccessibilityStatementFields => {
    return {};
};

const mapDispatchToProps = (
    dispatch: any,
): IAccessibilityStatementEvents => {
    return {
        onClickBack: () => dispatch(onClickBack()),
        onClickContact: () => dispatch(onClickContact()),
        onDidMount: (onClickBack) => dispatch(onDidMount(onClickBack)),
    }
};

export const AccessibilityStatementHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(AccessibilityStatement);

AccessibilityStatementHOC.displayName = 'AccessibilityStatementHOC';
