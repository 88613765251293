import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { commentInit } from '../../../store/reducers/actions/commentInit';

export const onCancel = (): ThunkAction => {
    return (dispatch: Dispatch) => {
        dispatch(commentInit());
        dispatch(segmentTrackAction(SegmentCardEvent.CLICKED_CANCEL_ON_COMMENT));
    };
};
