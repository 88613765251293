import * as React from 'react';
import './_card-comments.scss';
import { ICardCommentsProps } from './types';
import { CLASS_COMMENTS_SECTION } from '../../constants';
import { CommentsHeaderHOC } from '../../hocs/CommentsHeaderHOC/CommentsHeaderHOC';
import { CommentsListHOC } from '../../hocs/CommentsListHOC/CommentsListHOC';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { CommentInputHOC } from '../../hocs/CommentsInputHOC/CommentsInputHOC';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { EAsidePanelProperty } from '../../../../asidePanel/components/AsidePanel/types';
import { CLASS_CARD_DETAILS_SECTION } from '../../../constants';

export function CommentsSection({
    descOrder,
    isDefaultProperty,
    isShow,
    cardId,
    boardId,
    sectionType
}: ICardCommentsProps) {
    if (!isShow && sectionType === ESettingsSectionType.READONLY) return;

    const isPopup = sectionType === ESettingsSectionType.POPUP;
    const descClass = descOrder ? CLASS_COMMENTS_SECTION + '--desc' : '';
    const panelClass = CLASS_COMMENTS_SECTION + '__panel';
    const { openedProperty, addShowedProperty } = React.useContext(AsidePanelContext);
    const [isShowSection, setShowSection] = React.useState(false);

    React.useEffect(() => {
        if (isShow || isDefaultProperty) {
            setShowSection(true);
            addShowedProperty(EAsidePanelProperty.CARD_COMMENTS);
        }
    }, [isShow, isDefaultProperty]);

    if (
        !isShowSection &&
        !(isPopup && openedProperty === EAsidePanelProperty.CARD_COMMENTS)
    ) {
        return;
    }

    return (
        <div
            className={`
                ${CLASS_CARD_DETAILS_SECTION}
                ${CLASS_CARD_DETAILS_SECTION + '--comments'}
                ${CLASS_COMMENTS_SECTION}
                ${descClass}
            `}
            tabIndex={-1}
        >
            <CommentsHeaderHOC cardId={cardId} />
            <div className={panelClass}>
                <CommentsListHOC cardId={cardId} boardId={boardId}/>
                <CommentInputHOC
                    cardId={cardId}
                    boardId={boardId}
                    sectionType={ESettingsSectionType.DEFAULT}
                />
            </div>
        </div>
    );
}
