import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState } from '../../../../types/types';
import { snackbarInfoDefault } from '../../snackbarsStack';
import { getMessages } from '../../../../store/constants';
import { EVENT_KEY_BACKSPACE, EVENT_KEY_DELETE } from '../../../../const';
import { onDelKey } from '../cardDetails/DeleteButtonSection/hocs/DeleteButtonHOC/events/onDelKey';
import { getActiveCardId } from '../../../../store/router/selectors/getActiveCardId';
import { getSelectedCards } from '../../clickManager/selectors/getSelectedCards';
import { getActiveBoardId } from '../../../../store/model/selectors/getActiveBoardId';
import { onDelete } from '../multipleCards/Actions/ActionsSection/hocs/ActionsHOC/events/onDelete';
import { getDefaultActionEvent } from '../../snackbarsStack/effects/helpers/getDefaultActionEvent';
import { SegmentCardEvent, segmentTrackAction } from '../../../../middlewares/segment';

const ASIDE_PANEL_DELETE_SNACKBAR_ID = 'ASIDE_PANEL_DELETE';
const ASIDE_PANEL_DELETE_SNACKBAR_CLASSNAME = 'aside-panel-delete-snackbar';

export const showAsidePanelDeleteSnackbar = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.CARD_DELETE_SNACKBAR));
        const isMac = navigator.platform.indexOf('Mac') > -1;
        dispatch(snackbarInfoDefault({
            id: ASIDE_PANEL_DELETE_SNACKBAR_ID,
            text: isMac ? getMessages().getText('aside_panel.snackbar.delete.mac')
                : getMessages().getText('aside_panel.snackbar.delete'),
            className: ASIDE_PANEL_DELETE_SNACKBAR_CLASSNAME,
        }));
        setTimeout(() => {
            const handleDelete = (e: KeyboardEvent) => {
                if (
                    isMac && e.metaKey && EVENT_KEY_BACKSPACE === e.key ||
                    !isMac && e.shiftKey && EVENT_KEY_DELETE === e.key
                ) {
                    const state = getState();
                    const boardId = getActiveBoardId(state);
                    const cardIds = getSelectedCards(state, boardId);
                    if (cardIds && cardIds.length) {
                        dispatch(onDelete(boardId));
                    } else {
                        const cardId = getActiveCardId(state);
                        if (cardId) dispatch(onDelKey(cardId));
                    }
                    dispatch(getDefaultActionEvent(ASIDE_PANEL_DELETE_SNACKBAR_ID));
                }
            };
            const snackbarEl = document.querySelector(`.${ASIDE_PANEL_DELETE_SNACKBAR_CLASSNAME}`) as HTMLElement;
            if (snackbarEl) snackbarEl.addEventListener('keydown', handleDelete);
        }, 0);
    };
    return action;
}
