import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { SegmentBoardEvent, segmentTrackAction } from '../../../../../../../../../middlewares/segment';
import { EPushNotificationSettingsScope } from '../../../../../../../../../types/rest/IRestPushNotificationSettings';
import {
    SegmentBoardOption,
    SegmentBoardOptionImportantTypeValue,
    SegmentBoardOptionSendMeUpdateOnTypeValue,
    SegmentBoardOptionStatusValue
} from '../../../../../../../../../middlewares/segment/trackEntities/boardEvents';
import { ISegmentEventOption } from '../../../../../../../../../middlewares/segment/trackEntities/types';

export const onScopeClick = (
    scope: EPushNotificationSettingsScope,
    checked: boolean
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        const option: ISegmentEventOption = {
            name: SegmentBoardOption.STATUS,
            value: checked ? SegmentBoardOptionStatusValue.ON: SegmentBoardOptionStatusValue.OFF
        }
        if (EPushNotificationSettingsScope.ALL === scope) {
            dispatch(segmentTrackAction(SegmentBoardEvent.SEND_ME_UPDATES_ON_CLICKED, option,
                [{name: SegmentBoardOption.TYPE, value: SegmentBoardOptionSendMeUpdateOnTypeValue.ALL_CARDS}]));
        }
        if (EPushNotificationSettingsScope.WATCH === scope) {
            dispatch(segmentTrackAction(SegmentBoardEvent.SEND_ME_UPDATES_ON_CLICKED, option,
                [{name: SegmentBoardOption.TYPE, value: SegmentBoardOptionSendMeUpdateOnTypeValue.WATCH}]));
        }
        if (EPushNotificationSettingsScope.ASSIGN_TO_ME === scope) {
            dispatch(segmentTrackAction(SegmentBoardEvent.SEND_ME_UPDATES_ON_CLICKED, option,
                [{name: SegmentBoardOption.TYPE, value: SegmentBoardOptionSendMeUpdateOnTypeValue.ASSIGNED_TO_ME}]));
        }

        if (EPushNotificationSettingsScope.EVENT_ASSIGN_TO_ME === scope) {
            dispatch(segmentTrackAction(SegmentBoardEvent.IMPORTANT_NOTIFICATION_CLICKED, option,
                [{name: SegmentBoardOption.TYPE, value: SegmentBoardOptionImportantTypeValue.ASSIGNED_TO_ME}]));
        }
        if (EPushNotificationSettingsScope.MENTION === scope) {
            dispatch(segmentTrackAction(SegmentBoardEvent.IMPORTANT_NOTIFICATION_CLICKED, option,
                [{name: SegmentBoardOption.TYPE, value: SegmentBoardOptionImportantTypeValue.MENTIONS_ME}]));
        }
        if (EPushNotificationSettingsScope.CARD_CREATE === scope) {
            dispatch(segmentTrackAction(SegmentBoardEvent.IMPORTANT_NOTIFICATION_CLICKED, option,
                [{name: SegmentBoardOption.TYPE, value: SegmentBoardOptionImportantTypeValue.CREATE_CARD}]));
        }
    };
    return action;
};
