import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { fetchHandler } from '../../../../../../../util/fetchHandler';
import { IRestCard } from '../../../../../../../types/rest/IRestCard';
import Util from '../../../../../../../util/util';
import { getRestHeadersPost } from '../../../../../../../rest/helpers/getRestHeadersHelper';

const REST_INVITATIONS = '/rest/promotion/invite'

export const sendInvitations = (
    emails: string[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        return fetchHandler<IRestCard>(
            Util.getApiUrl(REST_INVITATIONS), {
                ...getRestHeadersPost(),
                body: JSON.stringify(emails)
            }
        )
    };
    return action;
}
