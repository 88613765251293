import { IApplicationState } from '../../../../types/types';
import { EUserMetaQualificationFormStatus } from '../../../../types/rest/IUserMeta';
import { isFreeVersionSelector } from '../../../../view/react_components/base/selectors/isFreeVersionSelector';
import { getUserQualificationFormStatus } from './getUserQualificationFormStatus';
import { getUserPaidSubscriptions } from './getUserPaidSubscriptions';
import { isUserDomainWide } from './isUserDomainWide';

export const isQualificationAvailableWithStatus = (
    state: IApplicationState,
    checkStatuses: EUserMetaQualificationFormStatus[] = null
): boolean => {
    return false; //KNB-2822

    const status = getUserQualificationFormStatus(state);
    const hasPaidSubscriptions = getUserPaidSubscriptions(state).length > 0;
    const isDomainWide = isUserDomainWide(state);
    return (
        (!checkStatuses || checkStatuses.includes(status))
        && !hasPaidSubscriptions
        && !isFreeVersionSelector()
        && !isDomainWide
    );
};
