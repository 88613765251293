import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { EThemeBackgroundOption } from '../../../../../../../../../types/rest/IRestTheme';
import { themeUpload } from '../effects/themeUpload';
import { TBoardId } from '../../../../../../../../../types/types';

export const onFileSelect = (
    boardId: TBoardId,
    file: File,
    backgroundOption: EThemeBackgroundOption
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        return dispatch(themeUpload(boardId, file, backgroundOption));
    };
    return action;
};
