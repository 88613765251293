import { ICard } from '../../card/types';
import { ICardsComparator } from './types';

const getCardValue = (
    card: ICard
): string => card.cardNumber;

const sort = (
    stringA: string,
    stringB: string,
    isDesc: boolean
): number => {
    if (!stringA && !stringB) return 0;
    // Пустая строка всегда внизу
    if (stringA && !stringB) return -1;
    if (!stringA && stringB) return 1;

    let result = 0;
    const a = stringA.split('-');
    const b = stringB.split('-');

    // первую часть сравниваем как строки
    result = isDesc ? b[0].localeCompare(a[0]) : a[0].localeCompare(b[0]);
    if (result !== 0) return result;

    // вторую часть сравниваем как числа
    return isDesc ? Number(b[1]) - Number(a[1]) : Number(a[1]) - Number(b[1]);
}

export const prefix: ICardsComparator = {
    getCardValue,
    sort
}
