import {
    IApplicationState,
    TBoardId,
    TBoardPermissionId,
    TCardId,
    TChecklistId,
    TChecklistItemId,
    TPermissionId
} from '../../../../../../../types/types';
import { getBoardUsers } from '../../../../../../../store/model/selectors/getBoardUsers';
import { getChecklistItem } from '../../../../../../../store/model/checklists/checklist/selectors/getChecklistItem';
import { getAuthUser } from '../../../../../../../store/model/authUser/selectors/getAuthUser';
import {
    ASSIGNEE_GROUP_DIVIDER,
    ASSIGNEE_GROUP_TITLE,
    TITLE_ASSIGNED_ON_CARD,
    TITLE_ASSIGNED_OTHER
} from '../../../AssigneesSection/components/constants';
import { createSelector } from 'reselect';
import { IAssignee } from '../../../../../../../store/model/card/types/IAssignee';
import { IChecklistItem } from '../../../../../../../store/model/checklists/checklist/types';
import { ISharedUser } from '../../../../../../../types/rest/ISharedUser';
import { IChecklistUsersSearchSelectOption } from '../../components/CardChecklistAssignUserSelect/types';
import { getInitials } from 'app/view/react_components/helpers/userPhotoHelper';
import { getCardAssignees } from '../../../../../../../store/model/selectors/getCardAssignees';

type TgetChecklistUsersSearchSelectOptions = (
    state: IApplicationState,
    boardId: TBoardId,
    cardId: TCardId,
    checklistId: TChecklistId,
    itemId?: TChecklistItemId
) => IChecklistUsersSearchSelectOption[];

const getBoardUsersSelector = (
    boardUsers: ISharedUser[],
    authUserPermissionId: TPermissionId,
): ISharedUser[] => {
    if (!boardUsers.length) return [];

    const authUser = boardUsers.find((user) => user.permissionId === authUserPermissionId);
    const assignees = boardUsers
        .filter((user) => user.permissionId !== authUserPermissionId)
        .sort((userA, userB) => {
            const getUserName = (user: ISharedUser) => user.fullName ? user.fullName : '';
            return getUserName(userA).localeCompare(getUserName(userB))
        })
    ;
    if (authUser) {
        assignees.unshift(authUser); // сделать себя первым в списке
    }

    return assignees;
};

const getAuthUserPermissionIdSelector = (
    state: IApplicationState,
): TPermissionId=> {
    const { permissionId } = getAuthUser(state);
    return permissionId;
};

/**
 * Юзеры доски + юзеры карты, удалённые с доски
 */
const getBoardUsersWithCardAssignees = (
    state: IApplicationState,
    boardId: TBoardId,
    cardId: TCardId
): ISharedUser[] => {
    const boardUsers = getBoardUsers(state, boardId);
    const cardAssignees = getCardAssignees(state, cardId).map((assignee) => assignee.sharedUser);
    const ids: Set<TBoardPermissionId> = new Set(boardUsers.map(user => user.permissionId));

    return cardAssignees.length
        ? cardAssignees.reduce((users, user) => { // сложить юзеров доски и карты, удалить дубликаты
            if (!ids.has(user.permissionId)) {
                users.push(user);
                ids.add(user.permissionId);
            }
            return users;
        }, [...boardUsers])
        : boardUsers; // если нет юзеров карты, просто отдать юзеров доски
};

const getCardAssigneeIdsSelector = (
    assignees: IAssignee[],
): Set<TBoardPermissionId> => {
    const assigneeIdsSet = new Set<TBoardPermissionId>();
    assignees.forEach(assignee => assigneeIdsSet.add(assignee.sharedUser.permissionId));
    return assigneeIdsSet;
};

const getItemUsersSelector = (
    item: IChecklistItem
): Set<TBoardPermissionId> => {
    const assigneeIdsSet = new Set<TBoardPermissionId>();
    if (item && item.assignees && item.assignees.length) {
        item.assignees.map(assignee => assigneeIdsSet.add(assignee.sharedUser.permissionId));
    }
    return assigneeIdsSet;
};

const getChecklistSearchSelectOptionsSelector = (
    authUserPermissionId: TPermissionId,
    users: ISharedUser[],
    cardAssignees: IAssignee[],
    checklistItem: IChecklistItem,
): IChecklistUsersSearchSelectOption[] => {
    const boardUsers = getBoardUsersSelector(users, authUserPermissionId);
    const cardAssigneesSet = getCardAssigneeIdsSelector(cardAssignees);
    const itemAssigneesSet = getItemUsersSelector(checklistItem);

    const options = boardUsers.map(user => ({
        active: !!itemAssigneesSet && itemAssigneesSet.has(user.permissionId),
        img: user.photoUrl,
        initials: getInitials(user),
        text: user.fullName,
        value: user.permissionId
    }));
    if ((cardAssigneesSet.size === 0) || (cardAssigneesSet.size === options.length)) {
        return options;
    }

    const filteredOptions: IChecklistUsersSearchSelectOption[] = [];
    const assigned = options.filter(option => cardAssigneesSet.has(option.value));
    const notAssigned = options.filter(option => !cardAssigneesSet.has(option.value));
    if (assigned.length) {
        filteredOptions.push({
            text: TITLE_ASSIGNED_ON_CARD,
            value: ASSIGNEE_GROUP_TITLE,
            options: assigned
        })
    }
    if (notAssigned.length) {
        filteredOptions.push({
            text: TITLE_ASSIGNED_OTHER,
            value: ASSIGNEE_GROUP_DIVIDER,
            options: notAssigned
        })
    }
    return filteredOptions;
};

export const getChecklistUsersSearchSelectOptionsCreateSelector = (
): TgetChecklistUsersSearchSelectOptions => createSelector(
    getAuthUserPermissionIdSelector,
    getBoardUsersWithCardAssignees,
    (state: IApplicationState, boardId: TBoardId, cardId: TCardId) => getCardAssignees(state, cardId),
    (state: IApplicationState, boardId: TBoardId, cardId: TCardId, checklistId: TChecklistId, itemId: TChecklistItemId) => getChecklistItem(state, cardId, checklistId, itemId),
    getChecklistSearchSelectOptionsSelector,
);

export const getChecklistUsersSearchSelectOptions: TgetChecklistUsersSearchSelectOptions = getChecklistUsersSearchSelectOptionsCreateSelector();

export const getChecklistUsersSearchSelectOptionsNoReselect = (
    state: IApplicationState,
    boardId: TBoardId,
    cardId: TCardId,
    checklistId: TChecklistId,
    itemId?: TChecklistItemId
): IChecklistUsersSearchSelectOption[] => getChecklistSearchSelectOptionsSelector(
    getAuthUserPermissionIdSelector(state),
    getBoardUsersWithCardAssignees(state, boardId, cardId),
    getCardAssignees(state, cardId),
    getChecklistItem(state, cardId, checklistId, itemId),
);
