import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { IGetState } from '../../../../../types/types';
import { cardAddAction } from '../store/actions/cardAddAction';
import { IRelatedCard } from '../components/RelatedCard/types';

export const relatedCardAdd = (
    relatedCard: IRelatedCard
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(cardAddAction(relatedCard));
    };
    return action;
};
