import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TBoardId } from '../../../../../../../../types/types';
import { getExportSortedColumns } from '../selectors/getExportSortedColumns';
import { saveColumns } from '../effects/saveColumns';
import { SegmentBoardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';

export const onAllColumnsSet = (
    boardId: TBoardId,
    checked: boolean
): ThunkAction => {
    const action =  (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const columns = getExportSortedColumns(getState(), boardId);
        columns.forEach((column) => column.enabled = checked);
        if (!checked) {
            dispatch(segmentTrackAction(SegmentBoardEvent.EXPORT_DESELECT_ALL_COLUMNS_CLICKED));
        } else {
            dispatch(segmentTrackAction(SegmentBoardEvent.EXPORT_SELECT_ALL_COLUMNS_CLICKED));
        }
        return dispatch(saveColumns(boardId, columns));
    }
    return action;
}
