import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { createComment } from '../effects/createComment';
import { IGetState, TBoardId, TCardId } from '../../../../../../../../types/types';
import { IComment } from '../../../../../../../../store/model/card/types/IComment';
import { commentSet } from '../../../store/reducers/actions/commentSet';
import { getCommentModel } from '../../../helpers/commentModelHelper';
import { trackCommentSent } from '../effects/trackCommentSent';
import * as React from 'react';
import { IDriveDoc } from '../../../../../../../../store/model/card/types/IDriveDoc';
import { addAttachments } from '../../../../../../../../rest/effects/card/attacments/addAttachments';
import Util from 'app/util/util';
import { showSnoozeBlocker } from '../../../../../../base/effects/showSnoozeBlocker';
import { getAuthUser } from '../../../../../../../../store/model/authUser/selectors/getAuthUser';
import { isTimeTracker } from '../selectors/isTimeTracker';
import { commentInit } from '../../../store/reducers/actions/commentInit';
import { root } from '../../../../../../../../store/constants';

export const onCreate = (
    boardId: TBoardId,
    cardId: TCardId,
    event: React.KeyboardEvent<HTMLElement>,
    attachments: IDriveDoc[]
): ThunkAction => {
    return (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const { allowTimeTracker } = getAuthUser(state);

        let comment = getCommentModel(state, boardId) as IComment;
        comment.cardId = cardId;
        comment.text = Util.setAuthuser0(comment.text).trim();
        if (!allowTimeTracker && isTimeTracker(comment.text)) {
            dispatch(showSnoozeBlocker({ allow: 'allowTimeTracker' }));
            return;
        }
        if (attachments && attachments.length) {
            return dispatch(addAttachments(cardId, attachments))
                .then((docs: IDriveDoc[]) => {
                    comment.driveDocIds = docs.map(doc => doc.id);
                    return dispatch(onCommentAdd(boardId, event, comment));
                });
        } else {
            return dispatch(onCommentAdd(boardId, event, comment));
        }
    };
};

const onCommentAdd = (
    boardId: TBoardId,
    event: React.KeyboardEvent<HTMLElement>,
    comment: IComment
): ThunkAction => {
    return (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(commentInit());
        root.App.controller.cardsCommentsInfo.resetCardComment(comment.cardId);
        if (comment.text) {
            dispatch(trackCommentSent(event));
        }

        return dispatch(createComment(boardId, comment));
    };
}
