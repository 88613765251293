import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../types/types';
import { cardDeleteAction } from '../store/actions/cardDeleteAction';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../middlewares/segment';

export const relatedCardDelete = (
    relatedCardId: TCardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(cardDeleteAction(relatedCardId));
        dispatch(segmentTrackAction(SegmentCardEvent.RELATED_CARD_REMOVE))
    };
    return action;
};
