import { TListAction } from '../actions/types';
import { AT_CARD_IDS_SET, AT_UPDATE } from '../actions/constants';
import { DEFAULT_SORTING } from '../../constants';
import * as deepFreeze from 'deep-freeze';
import { IList } from '../types';

const initialState: IList = {
    id: 0,
    dashboardId: 0,
    cardIds: [],
    name: '',
    description: '',
    orderNumber: 0,
    cardAutoIncrement: 0,
    cardAutoDecrement: 0,
    maxCardNumber: 0,
    status: 0,
    matchDashboardFilter: true,
    filteredCardNumber: 0,
    sorting: DEFAULT_SORTING,
    meta: {}
};

const _listReducer = (
    state = initialState,
    action: TListAction
): IList => {
    switch (action.type) {
        case AT_UPDATE: {
            return {
                ...state,
                ...action.list,
                meta: {
                    ...state.meta,
                    ...action.list.meta,
                },
            }
        }
        case AT_CARD_IDS_SET: {
            return {
                ...state,
                cardIds: action.cardIds
            }
        }
        default:
            return state;
    }
};

export const listReducer = (
    state = initialState,
    action: TListAction
): IList => {
    // @ts-ignore
    if (window.Settings.development && false) {
        // @ts-ignore
        return deepFreeze(_listReducer(state, action));
    } else {
        return _listReducer(state, action);
    }
};
