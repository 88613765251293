import { IGetState, TCardId } from '../../../types/types';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { getCard } from '../../../store/model/selectors/getCard';
import { ICards } from '../../../store/model/cards/types';
import { cardsRestPatch } from './card/api/helper/cardsRestPatch';
import {getDriveDocsByCardId} from '../../../store/model/driveDocs/selectors/getDriveDocsByCardId';

export const cardUpdateAttachmentsCount = (
    cardId: TCardId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const card = getCard(state, cardId);
        const attachmentsCount = getDriveDocsByCardId(state, cardId).length;
        if (!card) return Promise.reject();

        const cards: ICards = {
            [cardId]: {
                driveDocCount: attachmentsCount,
            }
        };
        dispatch(cardsRestPatch(cards));
    };
    return action;
};
