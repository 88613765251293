import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { segmentTrackAction, SegmentUserEvent } from '../../../../../../../middlewares/segment';
import { SegmentUserOption } from '../../../../../../../middlewares/segment/trackEntities/userEvents';
import { EQuickStartGuideType } from '../../../constants';
import { authUserSetQuickStartGuides } from '../../../../../../../rest/effects/authUser/authUserSetQuickStartGuides';
import {
    getAuthUserQuickStartGuides
} from '../../../../../../../store/model/authUser/selectors/getAuthUserQuickStartGuides';
import { IGetState } from '../../../../../../../types/types';

export const onDone = (
    guideKey: EQuickStartGuideType,
    key: number,
    name: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const quickStartGuides = { ...getAuthUserQuickStartGuides(getState()) };
        const guide = quickStartGuides && quickStartGuides[guideKey] || { key: guideKey, steps: [] };
        dispatch(authUserSetQuickStartGuides({
            ...guide,
            steps: [
                ...guide.steps,
                {
                    key,
                    completed: true
                }
            ]
        }));
        dispatch(segmentTrackAction(SegmentUserEvent.QUICK_START_GUIDE_DONE, {
            name: SegmentUserOption.VALUE,
            value: name
        }));
    };
    return action;
};
