import { IUserMetaQualificationAnswer } from '../../../../../../types/rest/IUserMeta';
import { ISegmentEventOption, TSegmentTrackPayload } from '../../../../../../middlewares/segment/trackEntities/types';

export interface IQualificationDialogFields {
    firstQuestionKey: string;
    fullName: string;
    initials: string;
    permissionId: string;
    photoUrl: string;
}

export interface IQualificationDialogEvents {
    onDidMount: () => void;
    onAnswer: (questionKey: string, answer: IUserMetaQualificationAnswer) => Promise<void>;
    onSkipped: () => void;
    onCompleted: () => void;
    onGetStarted: () => void;
    onShowCalendar: () => void;
}

export interface IQualificationDialogProps extends
    IQualificationDialogFields,
    IQualificationDialogEvents
{}

export type TQualificationAnswer = {
    answer: string;
    eventValue?: string; // не обязательный параметр, используется для отправки ивентов, если не установлен, используется answer,
    eventValueCallback?: (answer: IUserMetaQualificationAnswer) => string, // используется для получения динамических значений ивентов например из freetext
    nextQuestion?: string;
    type?: EQualificationAnswerType;
    checkboxItems?: TQualificationAnswerCheckboxItem[],
    parent?: string,
    onBoardMessagePriority?: number,
    singleSelect?: boolean
}

export enum EQualificationAnswerType {
    RADIO_ITEM,
    FREE_TEXT,
    PHONE_INPUT,
    BOOK_VIDEO_CALL
}

export type TQualificationQuestion = {
    question: string;
    event?: TSegmentTrackPayload;
    eventsCallback?: (questionKey: string, answer: IUserMetaQualificationAnswer) => TQualificationQuestionEvent[]
    answers: { [key: string]: TQualificationAnswer };
    nextQuestion?: string;
    answersType?: EQualificationAnswersType
}

export type TQualificationQuestions = {
    [key: string]: TQualificationQuestion
}

export enum EQualificationAnswersType {
    RADIO_SELECT = 1,
    FREE_TEXT = 2,
    CHECKBOXES = 3
}

export type TQualificationAnswerCheckboxItem = {
    key: string;
    title: string;
}

export type TQualificationQuestionEvent = {
    event: TSegmentTrackPayload;
    option?: ISegmentEventOption;
}
