import { connect } from 'react-redux';
import { MyWorkTopBar } from '../../components/MyWorkTopBar/MyWorkTopBar';
import { IMyWorkTopBarEvents, IMyWorkTopBarFields } from '../../components/MyWorkTopBar/types';
import { onBackClick } from './events/onBackClick';
import { IApplicationState } from '../../../../../../types/types';
import { getMyWorkFilterBarCollapsed } from '../../../myWorkView/selectors/getMyWorkFilterBarCollapsed';

const mapStateToProps = (
    state: IApplicationState
): IMyWorkTopBarFields => {
    return {
        isShowFilterBar: getMyWorkFilterBarCollapsed(state)
    };
};

const mapDispatchToProps = (
    dispatch: any
): IMyWorkTopBarEvents => {
    return {
        onBackClick: () => dispatch(onBackClick())
    }
};

export const MyWorkTopBarHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(MyWorkTopBar);

MyWorkTopBarHOC.displayName = 'MyWorkTopBarHOC';
