import * as React from 'react';
import { ICardProps } from './types';
import { CARD_CLASS, CARD_CONTROL_CLASS } from '../../../../constants';
import { CardIndicator } from '../../../CardIndicator/CardIndicator';
import { PriorityIndicatorHOC } from '../../../priorityIndicator/hocs/PriorityIndicatorHOC/PriorityIndicatorHOC';
import { SubCardIndicator } from '../../../subCardIndicator/SubCardIndicator';
import { CommentCountIndicator } from '../../../CommentCoundIndicator/CommentCountIndicator';
import { AttachmentsCountIndicator } from '../../../AttachmentsCountIndicator/AttachmentsCountIndicator';
import { SubCardsCountIndicator } from '../../../SubcardsCountIndicator/SubCardsCountIndicator';
import { ChecklistsCountIndicator } from '../../../ChecklistsCountIndicator/ChecklistsCountIndicator';
import { ColorTags } from '../../../../../../base/components/ColorTags/ColorTags';
import { CardStartDate } from '../../../CardStartDate/CardStartDate';
import { CardDueDate } from '../../../CardDueDate/CardDueDate';
import { CardEstimate } from '../../../CardEstimate/CardEstimate';
import { CardAssignees } from '../../../CardAssignees/CardAssignees';
import { CardCollaborators } from '../../../CardCollaborators/CardCollaborators';
import { CardTitle } from '../../../CardTitle/CardTitle';
import './Card.scss';
import { CardNumber } from '../../../CardNumber/CardNumber';
import { CardActionsHOC } from '../../../CardActions/hocs/CardActionsHOC';
import { CardTags } from '../../../CardTags/components/CardTags/CardTags';
import { DescriptionIndicator } from '../../../DescriptionIndicator/DescriptionIndicator';
import { NO_PRIORITY } from '../../../../../../helpers/constants';
import { getParentsClasses } from '../../../../../../helpers/getParentsClasses';
import { RecurringHeaderHOC } from '../../../RecurringHeader/hocs/RecurringHeaderHOC/RecurringHeaderHOC';
import { GOOGLE_SPACING } from '../../../../../../../../const';
import { CardChecklistsHOC } from '../../../CardChecklists/hocs/CardChecklistsHOC/CardChecklistsHOC';
import { CardSubcardsHOC } from '../../../CardSubcards/hocs/CardSubcardsHOC/CardSubcardsHOC';
import { getWeeks } from './helpers/getWeeks';
import { CardAgingIndicator } from '../../../CardAgingIndicator/CardAgingIndicator';
import { ECardAgingIndicatorType } from '../../../../../../../../types/rest/ICardAging';
import { CardCustomPropertiesHOC } from '../../../CardCustomProperties/hocs/CardCustomPropertiesHOC';
import { Checkbox } from 'kui';
import { CARD_BLINK_ANIMATION_TIME, CARD_BLINK_ANIMATION_TIME_WITH_DELAY, DONE_PROCESSING_STATUS } from './constants';
import { CardDescription } from '../../../CardDescription/CardDescription';
import { AnalyzeRender } from 'app/view/react_components/helpers/memoizeHelper';
import { CardDefaultTemplate } from '../../../CardDefaultTemplate/CardDefaultTemplate';
import { DriveDocs } from '../../../driveDocs/components/DriveDocs/DriveDocs';
import { DriveDocImgsHOC } from '../../../driveDocs/hocs/DriveDocImgsHOC/DriveDocImgsHOC';
import { CardProgressHOC } from '../../../../../../base/components/CardProgress/hocs/CardProgressHOC';
import { NAVIGATION_PANEL_CONTAINER_CLASS } from 'app/view/react_components/main/navigationPanel/constants';
import { root } from '../../../../../../../../store/constants';

// @ts-ignore
const analyzeRender = window.Settings.development ? new AnalyzeRender(`Card`) : null;

export function Card (props: ICardProps) {
    const {
        assignees,
        attachmentsCount,
        backgroundColor,
        boardId,
        cardNumber,
        checklistsCount,
        collaborators,
        colorTags,
        commentCount,
        description,
        doneChecklistsCount,
        dueDate,
        dragHandleProps,
        epicName,
        estimateStr,
        height: heightInitial,
        hasDescription,
        id,
        isCovered,
        isNotFilled,
        isPinnedChecklists,
        isPinnedSubcards,
        isPinnedCustomProperties,
        isReadonly,
        isRelatedCardEdit,
        isRelatedPanel,
        isSelected,
        isFakeCovered,
        isRecentlyWatched,
        isTemplate,
        listId,
        mainDriveDocSet,
        name,
        percent,
        priority,
        processingStatus,
        startDate,
        subCardsCount,
        tags,
        onCardClick,
        onDidMount,
        setHeight,
        hideActions,
        isRecurring,
        recurringTasks,
        actionsPortalId,
        boardRoles,
        boardUsers,
        authUserPermissionId,
        isMultipleDragging,
        isHighlight,
        onAnimationEnd,
        isCardAgingOpacity,
        cardAgingIndicator,
        timeSinceLastActivity,
        timeSinceMovedToList,
        isDefaultTemplate,
        isListVisible,
        isMultiselect,
        onCheckboxClick
    } = props;
    // const [ state, setState ] = React.useState(props); // to get prev state for optimizing
    // const propsString = JSON.stringify(props);
    // const stateString = JSON.stringify(state);
    // let stringEqual = '';
    // for (let i=0; i<propsString.length; i++) {
    //     if (propsString[i] === stateString[i]) {
    //         stringEqual += propsString[i];
    //     } else {
    //         console.log('Card', name);
    //         console.log('stringEqual: ', stringEqual.slice(-20));
    //         setState(props);
    //         break;
    //     }
    // }

    if (analyzeRender) analyzeRender.call(`${id} ${cardNumber}`);

    const cardRef = React.useRef(null);
    const modulesRef = React.useRef(null);

    const debounceRef = React.useRef(null); // debounce setHeight
    const [ height, _setHeight ] = React.useState(0);
    const data = React.useRef({ height });
    data.current = { height };
    const setHeightHandle = (height: number) => {
        if (setHeight) setHeight(height);
        _setHeight(height);
        data.current.height = height;
    }
    const checkCardVisibility = () => {
        let isCardVisibility: boolean = null;
        const card = document.querySelector(`[data-rbd-drag-handle-draggable-id="${id}"]`) as HTMLElement;
        if (!card) return isCardVisibility;
        const nav = document.querySelector('.' + NAVIGATION_PANEL_CONTAINER_CLASS) as HTMLElement;
        const navWidth = nav ? nav.offsetWidth : 0;
        const rect = card.getBoundingClientRect();
        isCardVisibility = rect.right > navWidth &&
            rect.left < root._innerWidth &&
            rect.right < root._innerWidth &&
            rect.left > navWidth;
        return isCardVisibility
    }

    const isRendered = React.useRef(null);

    const cardClasses = [CARD_CLASS, CARD_CLASS + id];

    const getHeightInit = () => {
        if (heightInitial) {
            let margin = GOOGLE_SPACING;
            if (collaborators.length) margin += GOOGLE_SPACING * .5; // same as _setCardHeight
            return `${heightInitial - margin}px`;
        } else {
            return 'auto';
        }
    }
    let [cardStyle, _setCardStyle] = React.useState<React.CSSProperties>({
        backgroundColor,
        height: getHeightInit(),
    });
    const setCardStyle = (value: React.CSSProperties) => {
        cardStyle = {
            ...cardStyle,
            ...value,
        };
        _setCardStyle(cardStyle);
    }

    if (backgroundColor) {
        const colorCode = backgroundColor.slice(1).toUpperCase();
        cardClasses.push(`${CARD_CLASS}--colored`);
        cardClasses.push(`${CARD_CLASS}--bgcolor-${colorCode}`);
    }
    if (isCovered) {
        cardClasses.push(`${CARD_CLASS}--loading`);
    }
    if (isFakeCovered) {
        cardClasses.push(`${CARD_CLASS}--fake-loading`);
    }
    if (isRecentlyWatched) {
        cardClasses.push(`${CARD_CLASS}--recently-watched`);
    }
    if (isSelected) {
        cardClasses.push(`${CARD_CLASS}--selected`);
    }
    if (collaborators.length) {
        cardClasses.push(`${CARD_CLASS}--w-collaborators`);
    }
    React.useEffect(() => {
        let style = {
            backgroundColor,
            backgroundImage: '',
            borderColor: '',
        }
        if (collaborators.length) {
            if (collaborators.length === 1) {
                if (!isSelected) {
                    style.borderColor = collaborators[0].color;
                }
            } else if (collaborators.length > 1) {
                const colorsArray: string[] = [];
                collaborators.forEach(collaborator => {
                    colorsArray.push(collaborator.color)
                })
                const gradient = `linear-gradient(to right, ${colorsArray.join(', ')})`;
                style.backgroundImage = `radial-gradient(white, white), ${gradient}`;
            }
        }
        setCardStyle(style);
    }, [backgroundColor, collaborators, isSelected]);

    if (isSelected && isMultiselect) {
        cardClasses.push(`${CARD_CLASS}--multi-selected`)
    }
    if (isMultipleDragging) {
        cardClasses.push(`${CARD_CLASS}--multiple-dragging`)
    }
    if (isNotFilled) {
        cardClasses.push(`${CARD_CLASS}--error`);
    }
    let weeks = 0;
    if (isCardAgingOpacity) {
        let cardAgingOpacityClass = CARD_CLASS + '--aging';
        const time = Date.now() - (cardAgingIndicator === ECardAgingIndicatorType.BY_LIST
            ? timeSinceMovedToList
            : timeSinceLastActivity);
        weeks = getWeeks(time);
        if (weeks === 0) {
            cardAgingOpacityClass += '-0-week';
        } else if (weeks === 1) {
            cardAgingOpacityClass += '-1-week';
        } else if (weeks === 2) {
            cardAgingOpacityClass += '-2-week';
        } else if (weeks === 3) {
            cardAgingOpacityClass += '-3-week';
        } else {
            cardAgingOpacityClass += '-4-week';
        }
        cardClasses.push(cardAgingOpacityClass);
    }

    const cardStateClass = CARD_CLASS + '__state';
    const cardModulesClass = CARD_CLASS + '__modules';
    const cardModuleClass = CARD_CLASS + '__module';
    const cardDatesClass = CARD_CLASS + '__dates';
    const threeDatesModifier = startDate && dueDate && estimateStr ? cardDatesClass + '--all' : '';
    const cardTitleInnerClass = CARD_CLASS + '__title-inner';
    const cardCheckboxClass = CARD_CLASS + '__checkbox';
    const colorTagsClass = cardModuleClass + '--color-tags';
    const cardIndicatorsClass = cardModuleClass + '--indicators';
    const cardCountIndicatorsClass = cardModuleClass + '--counters';
    const cardTitleModuleClass = cardModuleClass + '--title';
    const cardTagsModuleClass = cardModuleClass + '--tags';
    const cardAttachmentsModuleClass = cardModuleClass + '--attachments';
    const cardAttachmentsImgsModuleClass = cardAttachmentsModuleClass + '-imgs'
    const cardChecklistsModuleClass = cardModuleClass + '--checklists';
    const cardSubcardsModuleClass = cardModuleClass + '--subcards';

    const cardHasSubCards = subCardsCount > 0;
    const cardHasPriority = !!priority && priority !== NO_PRIORITY;
    const cardHasPercent = percent > 0;
    const cardHasTags = tags && tags.length > 0;
    const isShowCardAgingIndicator = cardAgingIndicator &&
        (
            cardAgingIndicator === ECardAgingIndicatorType.BY_LIST && !!timeSinceMovedToList ||
            cardAgingIndicator === ECardAgingIndicatorType.BY_ACTIVITY && !!timeSinceLastActivity
        );
    const isShotPriority = cardHasPercent && (cardHasSubCards || !!epicName) && cardHasSubCards && isShowCardAgingIndicator;
    const isCardHasIndicators = cardHasSubCards || cardHasPriority || cardHasPercent || !!epicName || isShowCardAgingIndicator;

    const isCardDatesLine = Boolean(startDate || dueDate || estimateStr || assignees.length > 0);
    const isCardHasCountersOrDescription = Boolean(
        subCardsCount > 0 ||
        commentCount > 0 ||
        attachmentsCount > 0 ||
        checklistsCount > 0 ||
        hasDescription
    );

    function handleClick(e: React.MouseEvent) {
        const { tagName } = e.target as HTMLElement;
        if (tagName && tagName.toLowerCase() === 'a') return; // ссылка в pinnedChecklist
        const targetClasses = getParentsClasses(
            e.target as HTMLElement, [
                CARD_CONTROL_CLASS,
                CARD_CLASS
        ]);
        if (targetClasses.includes(CARD_CONTROL_CLASS)) {
            return;
        }
        modulesRef.current.focus(); // панельке нужно знать, куда вернуть фокус
        onCardClick(e);
    }

    function setCardHeight() {
        setCardStyle({
            height: `auto`,
        });
        if (!heightInitial) {
            requestAnimationFrame(_setCardHeight);
        } else {
            if (debounceRef.current) clearTimeout(debounceRef.current);
            debounceRef.current = setTimeout(_setCardHeight, 500);
        }
    }

    function _setCardHeight() {
        if (
            cardRef.current &&
            cardRef.current.offsetHeight
        ) {
            let margin = GOOGLE_SPACING;
            if (collaborators.length) margin += GOOGLE_SPACING * .5;
            let h = cardRef.current.offsetHeight + margin;
            if (setHeight) setHeight(h);
        }
    }

    function onModulesHeightChange() {
        if (cardRef.current && modulesRef.current &&
            modulesRef.current.offsetHeight - cardRef.current.parentElement.offsetHeight > 15
        ) {
            setCardHeight();
        }
    }

    function onKeyDown (event: React.KeyboardEvent) {
        if (!event) return;
        if (event.key === 'Enter') {
            handleClick(event as any);
        }
    }

    /**
     * подписка на изменения ПОСЛЕ рендера
     */
    React.useEffect(() => {
        if (heightInitial && !isRendered.current) return;

        setCardHeight();
    }, [
        id,
        assignees,
        attachmentsCount,
        cardNumber,
        checklistsCount,
        collaborators,
        colorTags,
        commentCount,
        description,
        doneChecklistsCount,
        dueDate,
        epicName,
        estimateStr,
        hasDescription,
        isPinnedChecklists,
        isPinnedSubcards,
        isReadonly,
        mainDriveDocSet,
        name,
        percent,
        priority,
        processingStatus,
        startDate,
        subCardsCount,
        tags,
        isShowCardAgingIndicator
    ]);

    const [ driveDocs, setDriveDocs ] = React.useState([]);
    const [ driveDocsImgs, setDriveDocsImgs ] = React.useState([]);
    React.useEffect(() => {
        setDriveDocs(mainDriveDocSet.filter(driveDoc => !driveDoc.isImage));
        setDriveDocsImgs(mainDriveDocSet.filter(driveDoc => driveDoc.isImage));
    }, [mainDriveDocSet]);

    const [isCardVisible, setCardVisible] = React.useState(null);
    React.useEffect(() => {
        setCardVisible(checkCardVisibility());
    }, [isHighlight, isCardAgingOpacity]);

    React.useEffect(() => {
        onDidMount();

        if (cardRef.current) new ResizeObserver(setCardHeight).observe(cardRef.current);

        return () => {
            if (debounceRef.current) clearTimeout(debounceRef.current);
        }
    }, []);

    React.useEffect(() => {
        const isCardVisibility = checkCardVisibility()
        if (
            isHighlight &&
            cardRef.current
        ) {
            //wait until animation is finished
            setTimeout(() => {
                onAnimationEnd();
            }, isCardVisibility ? CARD_BLINK_ANIMATION_TIME : CARD_BLINK_ANIMATION_TIME_WITH_DELAY);
        }
    }, [isHighlight]);

    React.useEffect(() => {
        /**
         * если в дескрипшене есть картинка
         * то ResizeObserver отслеживает изменение высоты после её загрузки
         */
        if (description && description.includes('![')) {
            if (modulesRef.current) new ResizeObserver(onModulesHeightChange).observe(modulesRef.current);
        }
    }, [description]);

    React.useEffect(() => {
        onDidMount();
        isRendered.current = true;

        /**
         * при setInWorkCard высота увеличивается, т.к. добавляется assignee и startDate
         * одновременно меняется listId, т.е. получается новый компонент, и высота не пересчитывается
         * но воспроизводится почему-то только при маленькой высоте карты
         */
        if (modulesRef.current && heightInitial &&
            modulesRef.current.offsetHeight - heightInitial > 15
        ) {
            setCardHeight();
        }

        return () => {
            if (debounceRef.current) clearTimeout(debounceRef.current);
        }
    }, []);

    return (
        <div
            className={`
                ${cardClasses.join(' ')}
                ${isHighlight ? `${CARD_CLASS}--${weeks > 0 ? 'aging-' : ''}blink${isCardVisible ? '-delay' : ''}` : ''}
            `}
            ref={cardRef}
            style={cardStyle}
        >
            <div
                className={cardModulesClass}
                {...dragHandleProps}
                ref={modulesRef}
                onClick={handleClick}
                onKeyDown={onKeyDown}
            >
                {!!driveDocsImgs.length &&
                    <div className={`${cardModuleClass} ${cardAttachmentsImgsModuleClass}`}>
                        <DriveDocImgsHOC cardId={id} images={driveDocsImgs} />
                    </div>
                }
                {!isReadonly && !hideActions && !isMultiselect && !isRelatedPanel &&
                    <CardActionsHOC
                        boardId={boardId}
                        cardId={id}
                        portalId={actionsPortalId}
                        key={listId + '-' + id}
                        portal={true}
                    />
                }
                {!isReadonly && !hideActions && isMultiselect && !isRelatedPanel &&
                    <Checkbox
                        className={`${cardCheckboxClass}
                                    ${CARD_CONTROL_CLASS}`}
                        onChange={() => onCheckboxClick()}
                        checked={isSelected}
                    >
                    </Checkbox>
                }
                {isRelatedPanel && !isRelatedCardEdit &&
                    <Checkbox
                        className={`${cardCheckboxClass}
                                        ${CARD_CONTROL_CLASS}`}
                        onChange={() => onCheckboxClick()}
                        checked={isSelected}
                    >
                    </Checkbox>
                }
                {
                    isRecurring &&
                    <RecurringHeaderHOC recurringTasks={recurringTasks} />
                }
                {
                    colorTags.length > 0 &&
                    <div className={`${cardModuleClass} ${colorTagsClass}`}>
                        <ColorTags colorTags={colorTags} size={20} />
                    </div>
                }
                {isCardHasIndicators &&
                    <div className={`${cardModuleClass} ${cardIndicatorsClass}`}>
                        {cardHasPercent &&
                            <CardIndicator type={'processing'}>
                                <CardProgressHOC cardId={id} backgroundColor={backgroundColor} isLightBgStroke />
                            </CardIndicator>
                        }
                        {
                            isShowCardAgingIndicator && !isRecurring &&
                            <CardIndicator>
                                <CardAgingIndicator
                                    date={
                                        Date.now() - (cardAgingIndicator === ECardAgingIndicatorType.BY_ACTIVITY
                                                ? timeSinceLastActivity
                                                : timeSinceMovedToList
                                        )
                                    }
                                    type={cardAgingIndicator}
                                />
                            </CardIndicator>
                        }
                        {
                            (
                                cardHasSubCards || !!epicName
                            ) &&
                            <CardIndicator>
                                <SubCardIndicator epicName={epicName} subCardsCount={subCardsCount}/>
                            </CardIndicator>
                        }
                        {
                            cardHasPriority &&
                            <CardIndicator>
                                <PriorityIndicatorHOC
                                    priority={priority}
                                    isShort={isShotPriority}
                                />
                            </CardIndicator>
                        }
                    </div>
                }
                <div className={`${cardModuleClass} ${cardTitleModuleClass}`}>
                    <div className={cardTitleInnerClass}>
                        {
                            cardNumber &&
                            <CardNumber number={cardNumber}/>
                        }
                        {isDefaultTemplate && <CardDefaultTemplate />}
                        <CardTitle text={name}/>
                    </div>
                </div>
                {description && <CardDescription description={description} />}
                {
                    cardHasTags &&
                    <div className={`${cardModuleClass} ${cardTagsModuleClass}`}>
                        <CardTags tags={tags} />
                    </div>
                }
                {
                    isCardHasCountersOrDescription &&
                    <div className={`${cardModuleClass} ${cardCountIndicatorsClass}`}>
                        {
                            hasDescription &&
                            <DescriptionIndicator />
                        }
                        {
                            subCardsCount > 0 &&
                            <SubCardsCountIndicator subCardsCount={subCardsCount}/>
                        }
                        {
                            commentCount > 0 &&
                            <CommentCountIndicator commentsCount={commentCount}/>
                        }
                        {
                            attachmentsCount > 0 &&
                            <AttachmentsCountIndicator attachmentsCount={attachmentsCount}/>
                        }
                        {
                            checklistsCount > 0 &&
                            <ChecklistsCountIndicator
                                doneChecklistsCount={doneChecklistsCount}
                                checklistsCount={checklistsCount}
                            />
                        }
                    </div>
                }
                {
                    isCardDatesLine &&
                    <div className={cardModuleClass}>
                        <div className={`${cardDatesClass} ${threeDatesModifier}`}>
                            {
                                Boolean(startDate) &&
                                <CardStartDate date={startDate}/>
                            }
                            {
                                Boolean(dueDate) &&
                                <CardDueDate date={dueDate} highlightOverdue={processingStatus !== DONE_PROCESSING_STATUS}/>
                            }
                            {
                                Boolean(estimateStr) &&
                                <CardEstimate estimateStr={estimateStr} />
                            }
                        </div>
                        <CardAssignees
                            assignees={assignees}
                            authUserPermissionId={authUserPermissionId}
                            boardRoles={boardRoles}
                            boardUsers={boardUsers}
                        />
                    </div>
                }
                {!!driveDocs.length &&
                    <div className={`${cardModuleClass} ${cardAttachmentsModuleClass}`}>
                        <DriveDocs mainDriveDocSet={driveDocs} />
                    </div>
                }
                {isPinnedChecklists &&
                    <CardChecklistsHOC
                        cardId={id}
                        className={`
                            ${cardModuleClass}
                            ${cardChecklistsModuleClass}
                        `}
                        isReadonly={isReadonly || isTemplate}
                        setCardHeight={setCardHeight}
                    />
                }
                {isPinnedSubcards &&
                    <CardSubcardsHOC
                        cardId={id}
                        className={`
                            ${cardModuleClass}
                            ${cardSubcardsModuleClass}
                        `}
                        isReadonly={isReadonly}
                        setCardHeight={setCardHeight}
                    />
                }
                {isPinnedCustomProperties &&
                    <CardCustomPropertiesHOC
                        cardId={id}
                        boardId={boardId}
                        setCardHeight={setCardHeight}
                        className={cardModuleClass}
                    />
                }
            </div>
            <div className={cardStateClass}>
                <CardCollaborators
                    collaborators={collaborators}
                    assignees={assignees}
                    roles={boardRoles}
                />
            </div>
        </div>
    );
};
