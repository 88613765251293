import { getMessages } from '../../../../../store/constants';
import { TTabKey, TTabs } from './types';
import { DRIVE_ID_MY_DRIVE, DRIVE_ID_RECENT, DRIVE_ID_SHARED_WITH_ME } from './drives/constants';
import {
    REST_LOAD_BOARDS_ALL_BOARDS,
    REST_LOAD_BOARDS_ARCHIVE,
    REST_LOAD_BOARDS_SHARED_DRIVES,
    REST_LOAD_BOARDS_STARRED,
    REST_LOAD_BOARDS_TEMPLATES
} from '../constants';
import { allBoardsUrlPreparator } from '../helpers/allBoardsUrlPreparator';
import { allBoardsLoadListener } from '../helpers/allBoardsLoadListener';
import { TTabLoadStatus } from './common/types';
import { EAuthUserPlatformType } from 'app/types/rest/IRestAuthUser';
import { GROUP_MEMBER_READ_ALL } from 'app/helper/authorisation/microsoft/oauth/Scopes';
import { DRIVE_FULL_SCOPE, DRIVE_SCOPE } from 'app/helper/authorisation/google/Scopes';

/**
 * Логика показывать Табу в списке таб:
 * 1) если allow не задан - таба показывается
 * 2) если задан allow && user[allow] === false && showBlocker === true - таба показывается
 * 3) если задан allow && user[allow] === false && showBlocker != true - таба НЕ показывается
 * 4) иначе показывает табу
 */

export const TABS: TTabs = {
    [TTabKey.allBoards]: {
        allowCreateBoard: true,
        loadUrl: REST_LOAD_BOARDS_ALL_BOARDS,
        //loadUrl: REST_LOAD_BOARDS_ALL_BOARDS_V2, for new iteration
        name: getMessages().getText('open_boards.tab.all_boards'),
        showEmptyDrives: true,
        userActiveSave: false,
        urlPreparator: allBoardsUrlPreparator,
        onLoadListener: allBoardsLoadListener,
        excludeDrives: [DRIVE_ID_RECENT]
    },
    [TTabKey.recent]: {
        allowCreateBoard: true,
        name: getMessages().getText('open_boards.tab.recent'),
        userActiveSave: true,
        showEmptyDrives: true,
        excludeDrives: [
            DRIVE_ID_MY_DRIVE,
            DRIVE_ID_SHARED_WITH_ME
        ]
    },
    [TTabKey.sharedDrives]: {
        allow: 'allowSharedDrive',
        allowCreateBoard: true,
        loadUrl: REST_LOAD_BOARDS_SHARED_DRIVES,
        name: getMessages().getText('open_boards.tab.shared_drives'),
        excludeDrives: [
            DRIVE_ID_MY_DRIVE,
            DRIVE_ID_SHARED_WITH_ME,
            DRIVE_ID_RECENT
        ],
        scopes: {
            [EAuthUserPlatformType.GOOGLE]: {
                success: [DRIVE_SCOPE, DRIVE_FULL_SCOPE],
                need: [DRIVE_SCOPE],
                emptyDummyId: TTabKey.sharedDrives + '-scopesBlocker'
            },
            [EAuthUserPlatformType.MICROSOFT]: {
                success: [GROUP_MEMBER_READ_ALL],
                need: [GROUP_MEMBER_READ_ALL],
                emptyDummyId: TTabKey.sharedDrives + '-scopesBlocker' + EAuthUserPlatformType.MICROSOFT
            },
        },
        showPlanBlocker: TTabKey.sharedDrives + '-planBlocker',
        showEmptyDrives: true,
        support: 'supportSharedDrive',
        userActiveSave: false
    },
    [TTabKey.starred]: {
        support: 'supportStarred',
        gaEvent: getMessages().getText('ga.action.boards_list.tab.starred'),
        loadUrl: REST_LOAD_BOARDS_STARRED,
        name: getMessages().getText('open_boards.tab.starred'),
        userActiveSave: false,
        excludeDrives: [DRIVE_ID_RECENT]
    },
    [TTabKey.archive]: {
        support: 'supportBoardArchive',
        loadUrl: REST_LOAD_BOARDS_ARCHIVE,
        name: getMessages().getText('open_boards.tab.archive'),
        userActiveSave: false,
        excludeDrives: [DRIVE_ID_RECENT],
        isProFeature: true
    },
    [TTabKey.templates]: {
        allow: 'allowCreateBoard',
        gaEvent: getMessages().getText('ga.action.boards_list.tab.templates'),
        loadUrl: REST_LOAD_BOARDS_TEMPLATES,
        name: getMessages().getText('open_boards.tab.templates'),
        userActiveSave: false,
        showPlanBlocker: TTabKey.templates + '-planBlocker',
        excludeDrives: [DRIVE_ID_RECENT]
    },
};

export const INITIAL: boolean = null;
export const INITIAL_LOAD_STATUS: TTabLoadStatus = TTabLoadStatus.EMPTY;
export const INITIAL_NUMBER: number = null;

export const TABS_LOAD_STATUS_INITIAL = {
    [TTabKey.allBoards]: INITIAL_LOAD_STATUS,
    [TTabKey.sharedDrives]: INITIAL_LOAD_STATUS,
    [TTabKey.starred]: INITIAL_LOAD_STATUS,
    [TTabKey.archive]: INITIAL_LOAD_STATUS,
    [TTabKey.templates]: INITIAL_LOAD_STATUS
};

export const BOARDS_ISLOADING_INITIAL = {
    [TTabKey.allBoards]: INITIAL,
    [TTabKey.sharedDrives]: INITIAL,
    [TTabKey.starred]: INITIAL,
    [TTabKey.archive]: INITIAL,
    [TTabKey.templates]: INITIAL
};
