import { IApplicationState, IGetState, TBoardId } from '../../types/types';
import { Dispatch } from '../../types/actions';
import { getListCardsActiveAndArchive } from '../../store/model/selectors/getListCardsActiveAndArchive';
import { getCardFullNameHelper } from '../../store/model/card/helpers/getCardFullNameHelper';
import { IExportListener, IStartResult } from './types';
import { continueExport, finishExport, startExport } from './helpers';
import { getBoard } from '../../store/model/selectors/getBoardById';
import { getBoardListsByStatus } from '../../store/model/selectors/getBoardListsByStatus';

const ROWS_PART_LENGTH = 20;
const EXPORT_START_URL = '/rest/common/export/start/';
const EXPORT_ROWS_URL = '/rest/common/export/rows/';
const EXPORT_FINISH_URL = '/rest/common/export/finish';

export const exportCardTitles = (
    boardId: TBoardId,
    listener: IExportListener
) => {
    return (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const rows = prepareRows(state, boardId);
        const board = getBoard(state, boardId);

        return startExport(
            state,
            board,
            listener,
            EXPORT_START_URL,
            'card titles & description'
        ).then((result) => {
            return exportRows(dispatch, rows, result, listener).then(() => {
                return finishExport(result, EXPORT_FINISH_URL, listener, board.id, dispatch);
            })
        })
    };
}

const prepareRows = (state: IApplicationState, boardId: TBoardId) => {
    const lists = getBoardListsByStatus(state, boardId, null);
    const dataContentArray: Array<string[]> = [];
    lists.forEach(list => {
        const cardsInfoArray: string[] = [
            list.name
        ];
        const cards = getListCardsActiveAndArchive(state, list.id);
        cards.forEach(card => {
            let cardStr = getCardFullNameHelper(card);
            if (card.description) {
                cardStr += '\n' + card.description;
            }
            cardsInfoArray.push(cardStr);
        });
        dataContentArray.push(cardsInfoArray);
    });

    const result: string[][] = [];
    const maxRowsNumber = dataContentArray.reduce<number>((max, rows) => {
        if (rows.length > max) return rows.length;
        return max;
    }, 0);

    for (let rowNum = 0; rowNum < maxRowsNumber; rowNum++) {
        const row = [];
        for (let col = 0; col < dataContentArray.length; col++) {
            if (dataContentArray[col][rowNum]) {
                row.push(dataContentArray[col][rowNum]);
            } else {
                row.push('');
            }
        }
        result.push(row);
    }
    return result;
}

const exportRows = (
    dispatch: Dispatch,
    rowList: string[][],
    startResult: IStartResult,
    listener: IExportListener,
): Promise<any> => {
    return new Promise<void>((resolve, reject) => {
        let allRows = [...rowList];
        let rowsIndex = 0;
        const exportPart = (
            rows: string[][]
        ) => {
            continueExport(
                startResult,
                listener,
                EXPORT_ROWS_URL,
                JSON.stringify(rows)
            ).then((result) => {
                if (!result) return reject();
                listener.onProgress(Math.round(((rowsIndex) / (rowList.length)) * 100.0));
                rowsIndex += ROWS_PART_LENGTH;
                const rowsPart: string[][] = allRows.splice(0, ROWS_PART_LENGTH);
                if (rowsPart.length) {
                    exportPart(rowsPart);
                } else {
                    resolve();
                }
            })
        }
        const rowsPart: string[][] = allRows.splice(0, ROWS_PART_LENGTH);
        if (rowsPart.length) {
            exportPart(rowsPart);
        }
    });
}
