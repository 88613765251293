import { IApplicationState } from '../../../../types/types';
import { IUserMetaQualificationAnswers } from '../../../../types/rest/IUserMeta';
import { getUserQualificationFormMeta } from './getUserQualificationFormMeta';

export const getUserQualificationFormAnswers = (
    state: IApplicationState
): IUserMetaQualificationAnswers => {
    const qualificationForm = getUserQualificationFormMeta(state);
    return qualificationForm && qualificationForm.answers;
}
