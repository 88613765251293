import { IApplicationState } from '../../../../types/types';
import { getAuthUser } from './getAuthUser';
import { IRateFeaturePoll } from '../../../../types/rest/IUserMeta';

export const getUserRateFeaturePoll = (
    state: IApplicationState
): IRateFeaturePoll => {
    const user = getAuthUser(state);
    return user.meta && user.meta.rateFeaturePoll || {};
};
