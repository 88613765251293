import { TBoardId, TThemeId } from '../../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { boardSaveTheme } from '../effects/boardSaveTheme';
import { SegmentBrandingEvent, segmentTrackAction } from '../../../../../../../../../middlewares/segment';

export const onThemeClick = (
    boardId: TBoardId,
    themeId: TThemeId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        if (themeId) {
            dispatch(segmentTrackAction(SegmentBrandingEvent.BACKGROUND_FROM_LIBRARY_SELECTED));
        } else {
            dispatch(segmentTrackAction(SegmentBrandingEvent.BACKGROUND_EMPTY_SELECTED));
        }
        dispatch(boardSaveTheme(boardId, themeId));
    };
    return action;
};
