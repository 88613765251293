import * as React from 'react';
import { INotificationsListProps } from './types';
import './_NotificationsList.scss';
import { NotificationListItemHOC } from '../../hocs/NotificationListItem/NotificationListItemHOC';
import { NOTIFICATIONS_LIST_LABEL_TEXT } from './constants';

export function NotificationsList ({
    notifications,
    isPopupOpen,
    isReadonly,
}: INotificationsListProps) {
    if (!notifications.length) {
        return null
    }
    const className = 'notifications-list';
    const classNamePopup = isPopupOpen ? className + '--popup' : '';

    return (
        <div className={`
            ${className}
            ${classNamePopup}
        `}
        >
            <span className={ 'kui-label__item' }>
                { NOTIFICATIONS_LIST_LABEL_TEXT }
            </span>
            {
                notifications.map(item => {
                    if (item) {
                        const { cardId, id } = item;
                        return (
                            <NotificationListItemHOC
                                cardId={ cardId }
                                id={ id }
                                isPopupOpen={ isPopupOpen }
                                isReadonly={ isReadonly }
                                key={ id }
                            />
                        )
                    }
                })
            }
        </div>
    )
};
