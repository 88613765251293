(function(i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r;
    i[r] = i[r] || function() {
            (i[r].q = i[r].q || []).push(arguments)
    }, i[r].l = 1 * new Date();
    a = s.createElement(o),
        m = s.getElementsByTagName(o)[0];
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m)
    ga('create', i.Settings.gaTrackingId, 'auto', {
        'allowLinker': true
    });
    ga('require', 'linker');
    ga('linker:autoLink', ['kanbanchi.com']);

    ga('set', 'displayFeaturesTask', null);
    ga('set', 'anonymizeIp', true);
    //ga('require', 'displayfeatures');//DBLCLICK
    ga('send', 'pageview');
})(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');

//tag (gtag.js)
(function(i, s, o, g, a, m) {
    a = s.createElement(o);
    m = s.getElementsByTagName(o)[0];
    a.async = 1;
    a.src = g + '?id=' + i.Settings.ga4Id;
    m.parentNode.insertBefore(a, m);
    
    i.dataLayer = i.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    const gtagIds = [{ id: i.Settings.ga4Id }, { id: i.Settings.ga4IdAds, group: 'ads' }];
    gtagIds.forEach(item => {
        const { id, group } = item;
        if (id) {
            const options = {
                'linker': {
                    'domains': ['kanban-chi.appspot.com', '11-dot-kanbanchi-test.appspot.com']
                }
            };
            if (group) {
                options['group'] = group;
            }
            gtag('config', id, options);
        }
    });
    i.gtag = i.gtag || gtag;
})(window, document, 'script', '//www.googletagmanager.com/gtag/js');
