import * as React from 'react';
import { ITypeSwitcherDropUpProps } from './types';
import { Button, ButtonDropdown, Icon, SelectList, SelectListItem } from 'kui';
import './_TypeSwitcherDropUp.scss';
import { EViewTypes, VIEW_TYPES_KANBAN_OTHER, VIEW_TYPES_SWIM } from '../../../../../const';
import {
    CLASS_TYPE_SWITCHER,
    CLASS_TYPE_SWITCHER_ARROW,
    LABEL_KANBAN,
    LABEL_LIST_GROUPING,
    LABEL_SWIMLANE,
    TYPE_SWITCHER_ANIMATE_TIMER,
    TYPE_SWITCHER_MOBILE_BREAKPOINT,
    TYPE_SWITCHER_TIMER
} from '../../constants';
import { TypeSwitcherItem } from '../TypeSwitcherItem/TypeSwitcherItem';
import { root } from 'app/store/constants';
import { ProFeatureIndicator } from '../../../base/components/ProFeatureIndicator/ProFeatureIndicator';

export function TypeSwitcherDropUp ({
    active,
    isExpanded,
    isProFeature,
    isBasicFeature,
    isShowHint,
    isShowUsersView,
    onTypeSelected,
}: ITypeSwitcherDropUpProps) {
    const classGantt = `${CLASS_TYPE_SWITCHER}__list-item-gantt`;
    const items = [
        EViewTypes.KANBAN_VIEW_TYPE,
        EViewTypes.GANTT_VIEW_TYPE,
        EViewTypes.LIST_VIEW_TYPE,
        EViewTypes.ARCHIVE_VIEW_TYPE,
    ];

    let viewTypesOther = VIEW_TYPES_KANBAN_OTHER;
    if (!isShowUsersView) {
        viewTypesOther = viewTypesOther.filter(viewType => viewType !== EViewTypes.USERS_VIEW_TYPE);
    }
    const viewTypesSwim = VIEW_TYPES_SWIM;
    const viewTypesKanban = [
        'group',
        ...viewTypesOther,
        'group',
        ...viewTypesSwim,
    ];
    const activeKanbanIndex = viewTypesKanban.indexOf(active);
    if (activeKanbanIndex > -1) {
        active = EViewTypes.KANBAN_VIEW_TYPE;
    }

    const kanbanGroup = (
        <SelectList
            active={activeKanbanIndex}
        >
            <SelectListItem
                className={`${CLASS_TYPE_SWITCHER}__list-group-title disabled`}
            >
                <>
                    {LABEL_LIST_GROUPING}
                    {isProFeature && <ProFeatureIndicator />}
                </>
            </SelectListItem>
            {viewTypesOther.map(viewType => (
                <TypeSwitcherItem
                    key={viewType}
                    type={viewType}
                    onTypeSelected={onTypeSelected}
                    isBasicFeature={viewType !== EViewTypes.KANBAN_VIEW_TYPE && isBasicFeature}
                />
            ))}
            <SelectListItem
                className={`${CLASS_TYPE_SWITCHER}__list-group-title disabled`}
            >
                <>
                    {LABEL_SWIMLANE}
                    {isProFeature && <ProFeatureIndicator />}
                </>
            </SelectListItem>
            {viewTypesSwim.map(viewType => (
                <TypeSwitcherItem
                    key={viewType}
                    type={viewType}
                    onTypeSelected={onTypeSelected}
                    isBasicFeature={isBasicFeature}
                />
            ))}
        </SelectList>
    );

    const timer = React.useRef(null);
    const isMobile = root._innerWidth < TYPE_SWITCHER_MOBILE_BREAKPOINT;
    const [isKanbanGroupOpened, setKanbanGroupOpened] = React.useState(false);
    let [isKanbanGroupAnimate, _setKanbanGroupAnimate] = React.useState(false); // block while opening or closing
    const setKanbanGroupAnimate = (
        val: boolean
    ) => {
        isKanbanGroupAnimate = val;
        _setKanbanGroupAnimate(val);
    }

    const onKanbanMouseEnter = (
    ) => {
        if (isMobile || isKanbanGroupAnimate) return;

        if (timer.current) clearTimeout(timer.current);
        timer.current = setTimeout(onKanbanOpen, TYPE_SWITCHER_TIMER);
    };

    const onKanbanMouseLeave = (
    ) => {
        if (isMobile || isKanbanGroupAnimate) return;

        if (timer.current) clearTimeout(timer.current);
        timer.current = setTimeout(onKanbanClose, TYPE_SWITCHER_TIMER);
    };

    const onKanbanOpen = (
    ) => {
        if (isKanbanGroupAnimate || isKanbanGroupOpened) return;
        if (timer.current) clearTimeout(timer.current);
        setKanbanGroupOpened(true);
        setKanbanGroupAnimate(true);
        timer.current = setTimeout(() => {
            setKanbanGroupAnimate(false);
        }, TYPE_SWITCHER_ANIMATE_TIMER);
    };

    const onKanbanClose = (
    ) => {
        if (isKanbanGroupAnimate || !isKanbanGroupOpened) return;
        if (timer.current) clearTimeout(timer.current);
        setKanbanGroupOpened(false);
        setKanbanGroupAnimate(true);
        timer.current = setTimeout(() => {
            setKanbanGroupAnimate(false);
        }, TYPE_SWITCHER_ANIMATE_TIMER);
    };

    const [isHighlight, setHighlight] = React.useState(null);
    React.useEffect(() => {
        if (!isShowHint) {
            setHighlight(false);
        } else {
            setTimeout(() => setHighlight(true), TYPE_SWITCHER_ANIMATE_TIMER * 1.25);
        }
    }, [isShowHint]);

    return isExpanded ? kanbanGroup : (
        <SelectList
            active={items.indexOf(active)}
            className={`${CLASS_TYPE_SWITCHER}__list`}
        >
            <SelectListItem
                icon={'kanban'}
                iconSize={24}
                onMouseEnter={onKanbanMouseEnter}
                onMouseLeave={onKanbanMouseLeave}
            >
                <ButtonDropdown
                    className={`type-switcher__level1`}
                    directionHorizontal={'right'}
                    dropdownClassName={`type-switcher__level1-dd`}
                    opened={isKanbanGroupOpened}
                    onOpen={onKanbanOpen}
                    onClose={onKanbanClose}
                >
                    <Button
                        disabled={isKanbanGroupAnimate}
                        text={LABEL_KANBAN}
                        variant={'icon-text'}
                    >
                        <Icon xlink={'arrow-s-right'} size={16} className={CLASS_TYPE_SWITCHER_ARROW} />
                    </Button>
                    {kanbanGroup}
                </ButtonDropdown>
            </SelectListItem>
            <TypeSwitcherItem
                className={`${classGantt} ${isHighlight ? classGantt + '--highlight' : ''}`}
                type={EViewTypes.GANTT_VIEW_TYPE}
                onTypeSelected={onTypeSelected}
                isProFeature={isProFeature}
                isBasicFeature={isBasicFeature}
            />
            <TypeSwitcherItem
                type={EViewTypes.LIST_VIEW_TYPE}
                onTypeSelected={onTypeSelected}
                isBasicFeature={isBasicFeature}
            />
            <TypeSwitcherItem
                type={EViewTypes.ARCHIVE_VIEW_TYPE}
                onTypeSelected={onTypeSelected}
                isProFeature={isProFeature}
                isBasicFeature={isBasicFeature}
            />
        </SelectList>
    );
};
