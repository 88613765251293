import { IGetState, TBoardId } from '../../../types/types';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { ICardAssigneeRole } from '../../../types/model';
import { boardRolePostRest } from './api/helpers/boardRolePostRest';

export const boardRoleCreate = (
    boardId: TBoardId,
    role: ICardAssigneeRole
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(boardRolePostRest(boardId, role));
    };
    return action;
}
