import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { boardBackupRestore } from '../effects/boardBackupRestore';
import { TBoardId, TDriveId } from '../../../../../../../../../types/types';

export const onBackupRestore = (
    boardId: TBoardId,
    fileId: string,
    driveId: TDriveId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        return dispatch(boardBackupRestore(boardId, fileId, driveId));
    };
    return action;
};
