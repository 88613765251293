import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { TBoardId } from '../../../../../../../types/types';
import { boardOpenedSet } from '../../../store/boards/actions/boardOpenedSet';
import { boardClose } from '../../../../../base/effects/boardClose';

export const closeDeletedBoard: ActionCreator<ThunkAction> = (
    boardId: TBoardId
) => {
    const action = (dispatch: Dispatch) => {
        dispatch(boardOpenedSet(boardId, false));
        dispatch(boardClose(boardId, false));
    };
    return action;
};
