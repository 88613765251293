import { IApplicationState } from '../../../../../../../types/types';
import { connect } from 'react-redux';
import { BoardExport } from '../../components/BoardExport/BoardExport';
import { IBoardExportEvents, IBoardExportFields, } from '../../components/BoardExport/types';
import { onExportSpredsheet } from './events/onExportSpredsheet';
import { onExportTimeTracker } from './events/onExportTimeTracker';
import { getBoardExportItems } from '../../store/selectors/getBoardExportItems';
import { onClickDisallowGoogleSheetExport } from './events/onClickDisallowGoogleSheetExport';
import { getAuthUser } from '../../../../../../../store/model/authUser/selectors/getAuthUser';
import { IBoardExportHOCProps } from './types';
import { onExportCardTitles } from './events/onExportCardTitles';
import { onExportAutomaticToggle } from './events/onExportAutomaticToggle';
import { IBackupSchedule } from '../../../../../../../store/model/boardBackups/types';
import { EBackupExportType } from '../../../../../../../types/rest/IRestBackup';
import { onLoad } from './events/onLoad';
import { getShowProFeaturesTrial } from '../../../../../../../store/model/authUser/selectors/getShowProFeaturesTrial';
import {
    getAutoBackupsByBoardIdAndExportType
} from '../../../backups/AutoBackups/store/autoBackup/selectors/getAutoBackupsByBoardIdAndExportType';
import { getSectionType } from '../../../selectors/getSectionType';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';

const mapStateToProps = (
    state: IApplicationState,
    ownProps: IBoardExportHOCProps
): IBoardExportFields => {
    const { boardId } = ownProps;
    const exportItems = getBoardExportItems(state, boardId);
    const allowGoogleSheetExport = getAuthUser(state).allowGoogleSheetExport;
    const autoBackup: IBackupSchedule = getAutoBackupsByBoardIdAndExportType(state, boardId, EBackupExportType.GOOGLE_SHEET);
    const autoSectionType = getSectionType(state, boardId, null, 'allowAutoBackupXml', 'allowCreateBackUp')
    return {
        allowGoogleSheetExport,
        exportItems,
        boardId,
        automaticEnabled: autoBackup.enabled,
        isProFeature: getShowProFeaturesTrial(state),
        allowAutoExport: autoSectionType !== ESettingsSectionType.READONLY && autoSectionType !== ESettingsSectionType.DISALLOW
    }
};

const mapDispatchToProps = (
    dispatch: any,
    ownProps: IBoardExportHOCProps
): IBoardExportEvents => {
    const { boardId } = ownProps;
    return {
        onLoad: () => dispatch(onLoad(boardId)),
        onClickDisallowGoogleSheetExport: () => dispatch(onClickDisallowGoogleSheetExport()),
        onExportSpreadsheet: (listener) => dispatch(onExportSpredsheet(boardId, listener)),
        onExportTimeTracker: (listener) => dispatch(onExportTimeTracker(boardId, listener)),
        onExportCardTitles: (listener) => dispatch(onExportCardTitles(boardId, listener)),
        onExportAutomaticToggle: () => dispatch(onExportAutomaticToggle(boardId))
    }
};

export const BoardExportHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(BoardExport);

BoardExportHOC.displayName = 'BoardExportHOC';
