import { IApplicationState } from '../../../../../../../../types/types';
import { connect } from 'react-redux';
import { TabBackups } from '../../components/TabBackups/TabBackups';
import { ITabBackupsHOCProps } from './type';
import { ITabBackupsFields } from '../../components/TabBackups/types';

const mapStateToProps = (
    state: IApplicationState,
    ownProps: ITabBackupsHOCProps
): ITabBackupsFields => {
    const { boardId } = ownProps;
    return {
        boardId
    }
};

export const TabBackupsHOC = connect(
    mapStateToProps,
    null
)(TabBackups);

TabBackupsHOC.displayName = 'TabBackupsHOC';
