import { IGetState, TCardId, TChecklistId, TChecklistItemId, TPermissionId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { checklistItemUpdate } from './checklistItemUpdate';
import { getChecklistItem } from '../../../../store/model/checklists/checklist/selectors/getChecklistItem';
import { IAssigneeRest } from '../../../../types/rest/IAssigneeRest';

export const checklistItemDeleteUser = (
    cardId: TCardId,
    checklistId: TChecklistId,
    itemId: TChecklistItemId,
    permissionId: TPermissionId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const item = getChecklistItem(state, cardId, checklistId, itemId);
        if (!item) return Promise.reject();

        const assignUsers: IAssigneeRest[] = [...(item.assignees || [])];
        const index = assignUsers.findIndex(user => user.sharedUser.permissionId === permissionId);
        if (index < 0) return Promise.resolve();

        assignUsers.splice(index, 1);
        return dispatch(checklistItemUpdate(cardId, checklistId, itemId, {
            assignees: assignUsers
        }));
    };
    return action;
};
