import { IGetState } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { getAuthUser } from '../../../../../../../../store/model/authUser/selectors/getAuthUser';
import { getAuthUserIsHideDoneChecklistItems } from '../../../../../../../../store/model/authUser/selectors/getAuthUserIsHideDoneChecklistItems';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { authUserSetHideDoneChecklistItems } from '../../../../../../../../rest/effects/authUser/authUserSetHideDoneChecklistItems';

export const changeHideDone = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const user = getAuthUser(state);
        const isHideDoneChecklistItems = getAuthUserIsHideDoneChecklistItems(state);
        const event = isHideDoneChecklistItems ? SegmentCardEvent.CHECKLISTS_HIDE_DONE_BUTTON_CLICKED_OFF : SegmentCardEvent.CHECKLISTS_HIDE_DONE_BUTTON_CLICKED_ON;
        dispatch(segmentTrackAction(event));

        return dispatch(authUserSetHideDoneChecklistItems(user.id, !isHideDoneChecklistItems));
    };
    return action;
};
