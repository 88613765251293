import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { IHtmlEditorHOCProps } from './types';
import { IHtmlEditorEvents, IHtmlEditorFields } from 'app/view/react_components/base/components/HtmlEditor/types';
import { onAddImage } from './events/onAddImage';
import { HtmlEditor } from 'app/view/react_components/base/components/HtmlEditor/HtmlEditor';

const mapStateToProps = (
    state: IApplicationState,
    props: IHtmlEditorHOCProps,
): IHtmlEditorFields => {
    const { selectionTree, value } = props;

    return {
        selectionTree,
        value,
    };
};

const mapDispatchToProps = (
    dispatch: any,
    props: IHtmlEditorHOCProps,
): IHtmlEditorEvents => {
    const { cardId, onChange, onCtrlEnter, onEscape } = props;

    return {
        onAddImage: (base64) => dispatch(onAddImage(cardId, base64)),
        onChange,
        onCtrlEnter,
        onEscape,
    };
};

export const HtmlEditorHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(HtmlEditor);

HtmlEditorHOC.displayName = 'HtmlEditorHOC';
