import { TActivitiesAction } from '../actions/types';
import { IActivities } from '../types';
import { AT_ACTIVITIES_INIT, AT_ACTIVITIES_ADD, AT_ACTIVITIES_UPDATE } from '../actions/constants';

const initialState: IActivities = {};

export const activitiesReducer = (
    state = initialState,
    action: TActivitiesAction
): IActivities => {
    switch (action.type) {
        case AT_ACTIVITIES_INIT: {
            return initialState;
        }

        case AT_ACTIVITIES_ADD: {
            const { activities } = action;
            return {
                ...state,
                ...activities,
            }
        }

        case AT_ACTIVITIES_UPDATE: {
            const { activities } = action;
            const stateUpdated: IActivities = {};
            for (let activityId in activities) {
                stateUpdated[activityId] = activities[activityId];
            }
            return {
                ...state,
                ...stateUpdated
            };
        }

        default:
            return state;
    }
};
