import {Dispatch, ThunkAction} from '../../../../../../../../../types/actions';
import {IGetState, TBoardId} from '../../../../../../../../../types/types';
import * as moment from 'moment';
import {getAutoBackupsByBoardIdAndExportType} from '../../../store/autoBackup/selectors/getAutoBackupsByBoardIdAndExportType';
import {EBackupExportType} from '../../../../../../../../../types/rest/IRestBackup';
import {backupScheduleUpdate} from '../../../../../../../../../rest/effects/backupSchedule/backupScheduleUpdate';
import {getBoard} from '../../../../../../../../../store/model/selectors/getBoardById';

export const onTimeChange = (
    boardId: TBoardId,
    time: number
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const momentTime = moment(time).utc();
        const board = getBoard(state, boardId);
        const hours = momentTime.get('hours');
        const minutes = momentTime.get('minutes');
        const startTime = moment().tz(board.timeZone).set('hours', hours).set('minutes', minutes);
        if(moment().tz(board.timeZone).isAfter(startTime)) {
            startTime.add(1, 'days');
        }

        const autoBackup = getAutoBackupsByBoardIdAndExportType(state, boardId, EBackupExportType.XML);
        if (autoBackup.autoBackupSchedule.startDate !== startTime.valueOf()) {
            const backupSchedule = {
                startDate: startTime.valueOf()
            };
            return dispatch(backupScheduleUpdate(boardId,autoBackup.id, backupSchedule));
        }
    };
    return action;
};
