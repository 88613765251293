import { AT_TOP_BAR_BOARD_ACTION_SET } from '../../topBarBoard/actions/constants';
import { TTopBarAction } from '../actions/types';
import { ITopBarState } from '../types';
import * as deepFreeze from 'deep-freeze';
import { AT_LAST_USED_FILTERS, AT_IS_SHARE_HINT_SHOW_SET } from '../actions/constants';
import { getTopBarInitialState } from '../../constants';

const initialState: ITopBarState = {...getTopBarInitialState()};

const _topBarReducer = (
    state = initialState,
    action: TTopBarAction
): ITopBarState => {
    switch (action.type) {
        case AT_TOP_BAR_BOARD_ACTION_SET: {
            return {
                ...state,
                boardNameStatus: action.boardNameStatus
            }
        }
        case AT_LAST_USED_FILTERS: {
            return {
                ...state,
                lastUsedFilter: action.lastUsedFilters
            }
        }
        case AT_IS_SHARE_HINT_SHOW_SET: {
            return {
                ...state,
                isShareHintShow: action.isShareHintShow,
            }
        }
        default:
            return state;
    }
};

export const topBarReducer = (
    state = initialState,
    action: TTopBarAction
): ITopBarState => {
    // @ts-ignore
    if (window.Settings.development) {
        return deepFreeze(_topBarReducer(state, action));
    } else {
        return _topBarReducer(state, action);
    }
};
