import {CLASS_BOARD_DETAILS} from 'app/view/react_components/aside_panel/boardDetails/constants';
import {Button} from 'kui';
import * as React from 'react';
import {IBoardImportProps} from 'app/view/react_components/aside_panel/boardDetails/details/ImportBoard/components/BoardImport/types';
import './_BoardImport.scss'
import {
    BOARD_IMPORT_DESCRIPTION, BOARD_IMPORT_FAQ_LINK,
    BOARD_IMPORT_MORE_INSTRUCTIONS, BOARD_IMPORT_TITLE
} from 'app/view/react_components/aside_panel/boardDetails/details/ImportBoard/components/BoardImport/constants';
import { BOARD_IMPORT_READONLY } from 'app/view/react_components/aside_panel/boardDetails/details/ImportBoard/constants';

export function BoardImport ({
    onClick,
    isReadOnly
}: IBoardImportProps) {
    return (
        <div >
            <div className={CLASS_BOARD_DETAILS + '__import-text'}>
                {BOARD_IMPORT_DESCRIPTION}
                {' '}
                <a
                    href={BOARD_IMPORT_FAQ_LINK}
                    target="_blank"
                >{BOARD_IMPORT_MORE_INSTRUCTIONS}</a>
               </div>
            {isReadOnly ?
                <p className={CLASS_BOARD_DETAILS + '__import-text--readonly'}>{BOARD_IMPORT_READONLY}</p>
                :
                <Button onClick={onClick} text={BOARD_IMPORT_TITLE}/>
            }
        </div>
    );
}
