import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { segmentTrackAction, SegmentUserEvent } from '../../../../../../../middlewares/segment';
import { IGetState } from '../../../../../../../types/types';
import { getAuthUser } from '../../../../../../../store/model/authUser/selectors/getAuthUser';
import { authUserSetAffiliateId } from '../../../../../../../rest/effects/authUser/authUserSetAffiliateId';

export const onDidMount = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const user = getAuthUser(getState());
        if (!user.affiliateId) {
            dispatch(authUserSetAffiliateId(user.hash))
        }
        dispatch(segmentTrackAction(SegmentUserEvent.AFFILIATE_SHOWN));
    };
    return action;
}
