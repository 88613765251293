import { connect } from 'react-redux';
import { CreateNew } from '../../components/CreateNew/CreateNew';
import { onAddClick } from './events/onAddClick';
import { ICreateNewEvents } from '../../components/CreateNew/types';
import { ICreateNewHOCProps } from './types';

const mapDispatchToProps = (
    dispatch: any,
    {boardId, isRecurring}: ICreateNewHOCProps
): ICreateNewEvents => ({
    onAdd: () => dispatch(onAddClick(boardId, isRecurring))
})

export const CreateNewHOC = connect(
    null,
    mapDispatchToProps
)(CreateNew);

CreateNewHOC.displayName = 'CreateNewHOC';
