import { Dispatch, ThunkAction } from '../../../../../../types/actions';
import { IGetState, TBoardId } from '../../../../../../types/types';
import { getList } from '../../../../../../store/model/list/selectors/getList';
import { ICardBatch } from '../types';
import { ICard } from '../../../../../../store/model/card/types';
import { IList } from '../../../../../../store/model/list/types';
import * as _ from 'underscore';
import { snackbarInfoDefault } from '../../../../../../view/react_components/snackbarsStack';
import { SNACKBAR_ID_CARD_MOVIED } from '../../../../../../view/react_components/snackbarsStack/effects/constants';
import { getMessages } from '../../../../../../store/constants';
import { getBoard } from '../../../../../../store/model/selectors/getBoardById';
import { listUpdateMinMaxOrderNumber } from '../../../../list/listUpdateMinMaxOrderNumber';
import { processDoneCardUpdate } from './processDoneCardUpdate';
import { processCardMoveToListUpdate } from './processCardMoveToListUpdate';
import { getBoardWorkListMove } from '../../../../../../store/model/board/selectors/getBoardWorkListMove';
import { processWorkingCardUpdate } from './processWorkingCardUpdate';

export const processCardUpdate = (
    boardId: TBoardId,
    cardBatches: { [cardId: number]: ICardBatch },
    showMoveInfoPopup: boolean = true,
    calcOrderNumbers: boolean = true
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const board = getBoard(getState(), boardId);
        const doneList = board.statusLists && board.statusLists.doneId && getList(state, board.statusLists.doneId);
        const orderNumbers: number[] = [];
        for (let cardId in cardBatches) {
            const updateCard = cardBatches[cardId].card;
            if (updateCard.processingStatus === 'done') {
                dispatch(processDoneCardUpdate(Number(cardId), boardId, cardBatches, showMoveInfoPopup, calcOrderNumbers, orderNumbers));
            } else if (getBoardWorkListMove(state, boardId) && updateCard.processingPercent) {
                dispatch(processWorkingCardUpdate(Number(cardId), boardId, cardBatches, calcOrderNumbers, orderNumbers));
            }
            if (updateCard.listId) {
                dispatch(processCardMoveToListUpdate(Number(cardId), boardId, cardBatches))
            }
        }

        if (doneList) {
            dispatch(listUpdateMinMaxOrderNumber(doneList.id, orderNumbers))
        }
    };
    return action;
};

export const showMoveInfo = (
    dispatch: Dispatch,
    movedCards: ICard[],
    list: IList
) => {
    const textKey = movedCards.length > 1 ? 'snackbar.cards.movied.list' : 'snackbar.card.movied.list';
    const cardNames = movedCards.map(card => _.escape(card.name)).join(', ');
    dispatch(snackbarInfoDefault({
        id: SNACKBAR_ID_CARD_MOVIED,
        text: getMessages().getText(textKey, null, cardNames, _.escape(list.name))
    }));
};
