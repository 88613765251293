import { IApplicationState } from '../../../../../../types/types';
import { getTimeTrackerTitle } from '../../../../timeTracker/hocs/TimeTrackerHOC/selectors/getTimeTrackerTitle';
import { getKanbanStaticTitle } from './getKanbanStaticTitle';

export const getKanbanTitle = (
    state: IApplicationState,
): string => {
    const timeTrackerTitle = getTimeTrackerTitle(state);
    return timeTrackerTitle + getKanbanStaticTitle(state);
}
