import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { IGetLinkActionButtonHOCProps } from './types';
import { IAsidePanelActionsButtonProps } from '../../../../asidePanel/components/AsidePanelActionsButton/types';
import {
    AsidePanelActionsButton
} from '../../../../asidePanel/components/AsidePanelActionsButton/AsidePanelActionsButton';
import { GET_LINK_BUTTON_TITLE, GET_LINK_TEXT_TOOLTIP_VALUE } from './constants';
import { onClick } from './events/onClick';
import { getPanelButtonShow } from '../../../selectors/getPanelButtonShow';

const mapStateToProps = (
    state: IApplicationState,
    ownProps: IGetLinkActionButtonHOCProps
): IAsidePanelActionsButtonProps => {
    const { boardId, cardId, className, isShowTooltip } = ownProps;
    const isShow = getPanelButtonShow(state, boardId, cardId);
    return {
        className,
        icon: 'link',
        isShow,
        tooltipText: isShowTooltip ? GET_LINK_TEXT_TOOLTIP_VALUE : null,
        tooltipDirection: 'down',
        title: GET_LINK_BUTTON_TITLE
    };
};

const mapDispatchToProps = (
    dispatch: any,
    {cardId, boardId}: IGetLinkActionButtonHOCProps
): IAsidePanelActionsButtonProps => {
    return {
        onClick: () => dispatch(onClick(cardId, boardId))
    }
};
export const GetLinkButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps,
    (mapStateToProps, mapDispatchToProps) => ({...mapStateToProps, ...mapDispatchToProps})
)(AsidePanelActionsButton);

GetLinkButtonHOC.displayName = 'GetLinkButtonHOC';
