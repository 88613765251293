import { ICards } from '../cards/types';
import { IApplicationState, TCardId } from '../../../types/types';
import { getCard } from './getCard';
import { getCardSubcardsCount } from 'app/store/model/selectors/getCardSubcardsCount';
import { getCardPinnedSections } from 'app/store/model/selectors/getCardPinnedSections';
import { IPinnedSections } from 'app/store/model/card/types/ICardMeta';

export const getCardEpicsForPinnedSectionsChange = (
    state: IApplicationState,
    epicId: TCardId,
    cards: ICards = {},
): ICards => {
    const subcardsCount = getCardSubcardsCount(state, epicId);
    if (!subcardsCount) {
        const cardPinnedSections = getCardPinnedSections(getCard(state, epicId));
        const meta = cards[epicId] && cards[epicId].meta;
        const pinnedSections: IPinnedSections = {
            ...cardPinnedSections,
            subcards: false
        };
        cards[epicId] = {
            ...cards[epicId],
            meta: {
                ...meta,
                pinnedSections: {
                    ...pinnedSections
                }
            }
        }
    }
    return cards;
}
