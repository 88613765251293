import { Dispatch, ThunkAction } from 'app/types/actions';
import { IGetState, TCardId } from 'app/types/types';
import { SegmentCardEvent, SegmentCardOptions, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { openCardIfNeeded } from '../effects/openCardIfNeeded';
import { CLASS_CARD_DETAILS_SECTION } from '../../../../../../aside_panel/cardDetails';
import { CLASS_DATEPICKER } from '../../../../../../base/components/Datepicker/components/constants';
import { SegmentCardSourceValue } from '../../../../../../../../middlewares/segment/trackEntities/cardEvents';
import { asidePanelActionButtonClick } from '../../../../../../helpers/asidePanelActionButtonClick';
import { getCard } from '../../../../../../../../store/model/selectors/getCard';
import { waitUntilCardIsOpenedAndLoaded } from '../../../../../../helpers/waitUntilCardIsOpenedAndLoaded';

export const onSetDate = (
    cardId: TCardId,
    source: SegmentCardSourceValue
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.DATE_CARD_DROPDOWN_CLICKED, {
            name: SegmentCardOptions.SOURCE,
            value: source
        }));

        dispatch(openCardIfNeeded(cardId));

        const openDates = () => {
            setTimeout(() => {
                asidePanelActionButtonClick('dates');

                setTimeout(() => {
                    const card = getCard(getState(), cardId);
                    let dateSection = 'from';
                    if (card.startDate && !card.dueDate) {
                        dateSection = 'to';
                    }
                    const datePicker = document.querySelector(`
                    .${CLASS_CARD_DETAILS_SECTION}--dates
                    .${CLASS_DATEPICKER}__item--${dateSection}
                    .${CLASS_DATEPICKER}__date-button-create
                `) as HTMLInputElement;
                    if (datePicker) {
                        datePicker.click();
                    }
                }, 600); // подождать, когда проскролится к секции
            }, 100);
        };

        waitUntilCardIsOpenedAndLoaded(
            getState,
            cardId,
            openDates
        );
    };

    return action;
};
