import { onOpenATM } from './effects/knb-2472-my-work';
import {
    isNewKNB2502TopbarNavpanel,
    onCloseTipsKNB2502TopbarNavpanel,
    onCloseTipsKNB2502TopbarNavpanelWidgets,
    onOpenTipsKNB2502TopbarNavpanel,
    onOpenTipsKNB2502TopbarNavpanelMobile,
    onOpenTipsKNB2502TopbarNavpanelWidgets,
    onSeenTipsKNB2502TopbarNavpanel
} from './effects/knb-2502-topbar-navpanel';
import { EUpdateSlideType, EUpdateType, IUpdates } from './types';

const UPDATES: IUpdates = {
    '2024-7-2': [
        {
            type: EUpdateType.ADD,
            title: 'Starred Notifications',
            text: `Stay connected with our new starred notifications feature, ensuring you never miss important updates. Customize your alerts and prioritize what matters most to you with just a click.`,
        }
    ],
    '2024-1-22': [
        {
            type: EUpdateType.ADD,
            title: 'Creating Google files within cards',
            text: `Don't leave a card to create and attach files. Create Google files right within the card, and automatically share with collaborators. You can create Google Docs, Sheets, Forms or Slides in one click without interrupting your work process.`,
        }
    ],
    '2023-11-7': [
        {
            type: EUpdateType.ADD,
            title: 'My Work',
            text: `My Tasks is now My Work. It’s not just a list of your tasks anymore but a complete collection of all the work you are involved in different projects. Group them, create custom filters and organize your one-stop place to do your job.`,
            button: {
                text: 'Check it out',
                onClick: onOpenATM,
            },
        }
    ],
    '2023-10-4': [ // то же в showUpdatesTipsKNB2502Topbar
        {
            id: 'TOPBAR_REDESIGN',
            isNew: isNewKNB2502TopbarNavpanel,
            slides: [
                {
                    title: `🎉 New Top and Navigation panels!`,
                    text: `<p>“How to switch to Gantt chart?” — you may ask — “Something has changed”. Don’t worry! All things related to the current board are now grouped on the top panel, including the view switcher. Anything that affects the whole app have moved to the left navigation panel: user details, help, and notifications...</p>
                    +100 to staying organized!`,
                    type: EUpdateSlideType.TIPS,
                    onOpen: onOpenTipsKNB2502TopbarNavpanel,
                    onClose: onCloseTipsKNB2502TopbarNavpanel,
                },
                {
                    highlightSelector: '.type-switcher',
                    title: `🔄 Switch It Up`,
                    text: `The switcher between Kanban board, Gantt chart, Task list, and Archive moved to the top of the page. This design update ensures your view options are always in sight and just a click away. Check out those neat tabs!`,
                    onOpen: onOpenTipsKNB2502TopbarNavpanel,
                },
                {
                    highlightSelector: '.notifications__button-dd',
                    title: `🔔 Ring the Changes`,
                    text: `We’ve given our notification bell a new home! Now located on the left navigation panel, the icon takes the form of a convenient menu item. Staying informed about updates and changes is more streamlined than ever now.`,
                    onOpen: () => {
                        onOpenTipsKNB2502TopbarNavpanel();
                        onOpenTipsKNB2502TopbarNavpanelMobile();
                    },
                },
                {
                    highlightSelector: '.navigation-panel__section--starred .kui-section-accordion-header',
                    title: `💖 Starred: boards you need every day are one click away!`,
                    text: `<p>No more “Where’s that board?” drama! Our new Starred on the left panel make hopping between your top picks lightning speed fast.</p>
                    Remember the days of endless searching? Smile and wave them good bye!`,
                    onOpen: () => {
                        onOpenTipsKNB2502TopbarNavpanel();
                        onOpenTipsKNB2502TopbarNavpanelMobile();
                    },
                },
                {
                    highlightSelector: '.navigation-panel__widgets',
                    title: `✨ Hello, Widgets!`,
                    text: `<p>Say hi to your new best friends — widgets! With “Working on it,” you can jump straight to your current task like a pro. Keep track of time spent on tasks with the snazzy “Timing” widget.</p>
                    Work just got a whole lot more delightful! And more widgets are coming soon...`,
                    onOpen: () => {
                        onOpenTipsKNB2502TopbarNavpanel();
                        onOpenTipsKNB2502TopbarNavpanelMobile();
                        onOpenTipsKNB2502TopbarNavpanelWidgets();
                    },
                    onClose: onCloseTipsKNB2502TopbarNavpanelWidgets,
                },
                {
                    highlightSelector: '.navigation-panel__user-help',
                    title: `😎 User details and Help on the Move!`,
                    text: `Your user picture now comfortably sits on the left panel. Clicking on it will bring up your user details as usual. The Help icon is also there, with our team always ready to assist with any questions you might have.`,
                    onOpen: () => {
                        onOpenTipsKNB2502TopbarNavpanel();
                        onOpenTipsKNB2502TopbarNavpanelMobile();
                    },
                },
                {
                    title: `😍 New UI: Lookin’ good!`,
                    text: `Our app just had a makeover! Embrace the fresh, user-friendly interface that screams, “I’m stylish and productive!” Enjoy working and work enjoying.`,
                    onOpen: onSeenTipsKNB2502TopbarNavpanel
                },
            ],
            type: EUpdateType.IMPROVE,
            title: `🎉 New Top and Navigation panels!`,
            text: `<p>“How to switch to Gantt chart?” — you may ask — “Something has changed”. Don’t worry! All things related to the current board are now grouped on the top panel, including the view switcher. Anything that affects the whole app have moved to the left navigation panel: user details, help, and notifications...</p>
                +100 to staying organized!`,
        },
    ],

    '2023-9-27': [
        {
            type: EUpdateType.IMPROVE,
            title: 'Checklists improvements'
        }
    ],
    '2023-7-6': [
        {
            type: EUpdateType.IMPROVE,
            title: 'Notification centre'
        }
    ],
    '2023-5-24': [
        {
            type: EUpdateType.IMPROVE,
            title: 'Date picker'
        }
    ],
    '2023-4-10': [
        {
            type: EUpdateType.IMPROVE,
            title: '“Add to Google calendar” setting for card templates and recurring cards.'
        }
    ],
    '2023-4-5': [
        {
            type: EUpdateType.ADD,
            title: 'Ability to choose default card template'
        }
    ],
    '2023-3-16': [
        {
            type: EUpdateType.IMPROVE,
            title: 'Exporting card “age” data (last change date and the date of occurring in the list)'
        }
    ],
    '2023-3-14': [
        {
            type: EUpdateType.IMPROVE,
            title: 'Colour labels UX/UI'
        }
    ],
    '2023-2-28': [
        {
            type: EUpdateType.ADD,
            title: 'Automatic board exports'
        }
    ],
    '2023-2-2': [
        {
            type: EUpdateType.ADD,
            title: 'Card from link is opened without board'
        }
    ],
    '2023-1-26': [
        {
            type: EUpdateType.ADD,
            title: 'Swimlanes',
            text: 'Add a new layer of structure to your Kanban board. Keep track of subprocesses and classify tasks, projects, or teams with the help of Swimlanes.',
            slides: [
                {
                    media: '/dist/img/releases/2-120/2-120@2x.png',
                    text: 'Add a new layer of structure to your Kanban board. Keep track of subprocesses and classify tasks, projects, or teams with the help of Swimlanes. <a href="https://www.kanbanchi.com/swimlanes" target="_blank">Learn more</a>',
                    title: 'Swimlanes',
                    type: EUpdateSlideType.IMAGE
                }
            ]
        }
    ],
    '2023-1-11': [
        {
            type: EUpdateType.ADD,
            title: 'Attachments tab in board settings',
            text: 'Kanbanchi has a new tool for efficient file management. All attachments from your board can now be found in the Attachments tab in board settings.',
            slides: [
                {
                    media: '/dist/img/releases/2-119/2-119@2x.png',
                    text: 'Kanbanchi has a new tool for efficient file management. All attachments from your board can now be found in the Attachments tab in board settings. <a href="https://www.kanbanchi.com/attachments-tab-in-board-settings" target="_blank">Learn more</a>',
                    title: 'Attachments tab in board settings',
                    type: EUpdateSlideType.IMAGE
                }
            ]
        },
        {
            type: EUpdateType.ADD,
            title: 'Actions with multiple cards: ability to rename cards',
        }
    ],
    '2022-12-13': [
        {
            type: EUpdateType.ADD,
            title: 'Current date as start date automation'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Cookie consent message'
        }
    ],
    '2022-11-11': [
        {
            type: EUpdateType.ADD,
            title: 'Import to existing board',
            text: 'We’ve expanded Kanbanchi import feature to make data entry easier. Need to migrate from another software or quickly add cards to your board? No problem! Check out the article to learn more',
            slides: [
                {
                    media: '/dist/img/releases/2-117-2/2-117-2@2x.png',
                    text: 'We’ve expanded Kanbanchi import feature to make data entry easier. Need to migrate from another software or quickly add cards to your board? No problem! Check out the article to <a href="https://www.kanbanchi.com/import-enhancements" target="_blank">learn more</a>',
                    title: 'Import enhancements',
                    type: EUpdateSlideType.IMAGE
                }
            ]
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'CSV import:  added checklists and assignees',
        }
    ],
    '2022-11-1': [
        {
            type: EUpdateType.ADD,
            title: 'Keyboard shortcuts menu'
        },
        {
            type: EUpdateType.ADD,
            title: 'Ability to pin card description'
        },
        {
            type: EUpdateType.ADD,
            title: 'My tasks: ability to view card on board'
        }
    ],
    '2022-10-25': [
        {
            type: EUpdateType.ADD,
            title: 'Ability to reorder card templates and recurring cards'
        }
    ],
    '2022-10-14': [
        {
            type: EUpdateType.ADD,
            title: 'Actions with multiple cards: ability to adjust dates'
        },
        {
            type: EUpdateType.ADD,
            title: 'Last used board is opened upon login'
        }
    ],
    '2022-10-4': [
        {
            type: EUpdateType.ADD,
            title: 'Ability to review related cards progress'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Dates filter'
        }
    ],
    '2022-7-27': [
        {
            type: EUpdateType.IMPROVE,
            title: 'Card details',
            text: 'Card details become more convenient. The updated layout will help you to configure tasks faster and easier. Explore your cards to check out the improvement.',
            slides: [
                {
                    media: '/dist/img/releases/2-111/2-111@2x.png',
                    text: 'Card details become more convenient. The updated layout will help you to configure tasks faster and easier. Explore your cards to check out the improvement.',
                    title: 'Card details improvement',
                    type: EUpdateSlideType.IMAGE
                }
            ]
        }
    ],
    '2022-7-20': [
        {
            type: EUpdateType.ADD,
            title: 'Reactions in comments'
        },
        {
            type: EUpdateType.ADD,
            title: 'Progress bar in list of subcards '
        },
        {
            type: EUpdateType.ADD,
            title: 'Ability to hide done items in list of subcards'
        }
    ],
    '2022-7-12': [
        {
            type: EUpdateType.ADD,
            title: 'Required properties',
            text: 'Reduce the information deficiency and make sure all your team members have access to the necessary data with the required properties in Kanbanchi. Don’t let a card to be closed without adding everything that’s important to do your work.',
            slides: [
                {
                    media: '/dist/img/releases/2-110/2-110@2x.png',
                    text: 'Reduce the information deficiency and make sure all your team members have access to the necessary data with the required properties in Kanbanchi. Don’t let a card to be closed without adding everything that’s important to do your work.  <a href="https://www.kanbanchi.com/faqwd/can-i-require-some-properties-to-be-mandatory-filled" target="_blank">Learn more</a>',
                    title: 'Required properties',
                    type: EUpdateSlideType.IMAGE
                }
            ]
        }
    ],
    '2022-7-7': [
        {
            type: EUpdateType.ADD,
            title: 'Search option for recurring cards &amp; card templates'
        }
    ],
    '2022-7-5': [
        {
            type: EUpdateType.ADD,
            title: 'Card aging',
            text: 'Blocked tasks won’t be unnoticed. Easily detect cards without changes using the new feature - Card aging.',
            slides: [
                {
                    media: '/dist/img/releases/2-109/2-109@2x.png',
                    text: 'Blocked tasks won’t be unnoticed. Easily detect cards without changes using the new feature - Card aging.  <a href="https://www.kanbanchi.com/card-aging" target="_blank">Learn more</a>',
                    title: 'Card aging',
                    type: EUpdateSlideType.IMAGE
                }
            ]
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'More quick actions for cards',
        }
    ],
    '2022-6-23': [
        {
            type: EUpdateType.ADD,
            title: 'Archive done cards automation'
        },
        {
            type: EUpdateType.ADD,
            title: 'New filter option for undone checklist items'
        }
    ],
    '2022-6-2': [
        {
            type: EUpdateType.ADD,
            title: 'Ability to rename list by double click'
        },
        {
            type: EUpdateType.ADD,
            title: 'Ability to create board on a Shared drive from navigation panel'
        }
    ],
    '2022-5-25': [
        {
            type: EUpdateType.ADD,
            title: 'Pinning custom card properties',
            text: 'We keep working on the ability to pin card properties to cards. Additionally to checklists and subcards, now you will be able to pin custom properties that you need to see when a card is not opened.',
            slides: [
                {
                    media: '/dist/img/releases/2-107/2-107@2x.png',
                    text: 'We keep working on the ability to pin card properties to cards. Additionally to checklists and subcards, now you will be able to pin custom properties that you need to see when a card is not opened.  <a href="https://www.kanbanchi.com/faqwd/how-do-i-pin-custom-properties-to-a-card" target="_blank">Learn more</a>',
                    title: 'Pinning custom properties',
                    type: EUpdateSlideType.IMAGE
                }
            ]
        },
        {
            type: EUpdateType.ADD,
            title: 'Reply (fast mentioning) in comments',
        },
        {
            type: EUpdateType.ADD,
            title: 'Recent boards tab on the list of boards',
        }
    ],
    '2022-5-23': [
        {
            type: EUpdateType.ADD,
            title: 'Overdue checklist items on Gantt chart'
        }
    ],
    '2022-5-16': [
        {
            type: EUpdateType.ADD,
            title: 'Default time setting for card dates',
            text: 'Schedule time effectively no matter what type of work shift you have. Set default time settings for card dates and receive notifications just in time.',
            slides: [
                {
                    media: '/dist/img/releases/2-106/2-106@2x.png',
                    text: 'Schedule time effectively no matter what type of work shift you have. Set default time settings for card dates and receive notifications just in time.  <a href="https://www.kanbanchi.com/default-time" target="_blank">Learn more</a>',
                    title: 'Default time settings for start and due dates',
                    type: EUpdateSlideType.IMAGE
                }
            ]
        },
        {
            type: EUpdateType.ADD,
            title: 'Last opened card indicator',
        },
        {
            type: EUpdateType.ADD,
            title: 'Ability to move board to Shared Drive from board settings',
        }
    ],
    '2022-3-21': [
        {
            type: EUpdateType.IMPROVE,
            title: 'Recent boards in "Move to" menu'
        }
    ],
    '2022-3-14': [
        {
            type: EUpdateType.ADD,
            title: 'Creating card templates with subcards (Epic card templates)'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Checklist items on the Gantt chart'
        }
    ],
    '2022-3-1': [
        {
            type: EUpdateType.ADD,
            title: 'Ability to assign all checklist items to user',
            text: ' Assign your entire checklist to a user quickly and easily. This simple time-saving feature will help you to avoid repeated clicks and concentrate on work.',
            slides: [
                {
                    media: '/dist/img/releases/2-103-1/2-103-1@2x.png',
                    text: ' Assign your entire checklist to a user quickly and easily. This simple time-saving feature will help you to avoid repeated clicks and concentrate on work.  <a href="https://www.kanbanchi.com/checklist-items" target="_blank">Learn more</a>',
                    title: 'Ability to bulk assign users to checklists',
                    type: EUpdateSlideType.IMAGE
                }
            ]
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Checklist items shift on Gantt chart',
        }
    ],
    '2022-2-15': [
        {
            type: EUpdateType.ADD,
            title: 'Notifications when a checklist item is overdue'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Adding checklist dates (manual input)'
        }
    ],
    '2022-1-27': [
        {
            type: EUpdateType.ADD,
            title: 'Filtering by the date when a card was done',
            text: 'Create checklists faster - just copy them from existing cards or card templates. It works for multiple cards as well! And don’t forget to check out our new filtering option: by the date when a card was done.',
            slides: [
                {
                    media: '/dist/img/releases/2-101/2-101@2x.png',
                    text: 'Create checklists faster - just copy them from existing cards or card templates. It works for multiple cards as well! And don’t forget to check out our new filtering option: by the date when a card was done.  <a href="https://www.kanbanchi.com/additional-options-for-checklists-and-filter" target="_blank">Learn more</a>',
                    title: 'Additional options for checklists and filter',
                    type: EUpdateSlideType.IMAGE
                }
            ]
        },
        {
            type: EUpdateType.ADD,
            title: 'Copying checklists from existing cards and card templates',
        }
    ],
    '2021-11-11': [
        {
            type: EUpdateType.ADD,
            title: 'Start dates and estimates for checklist items',
            text: 'Set start dates and estimate each checklist item separately. This new addition will help you be even more precise with planning and then with a comparison of the actual project performance to the estimated plan.',
            slides: [
                {
                    media: 'https://www.youtube-nocookie.com/embed/t-no1_7FWrA',
                    text: 'Set start dates and estimate each checklist item separately. This new addition will help you be even more precise with planning and then with a comparison of the actual project performance to the estimated plan.  <a href="https://youtu.be/t-no1_7FWrA" target="_blank">Learn more</a>',
                    title: 'Start dates and estimates for checklist items',
                    type: EUpdateSlideType.VIDEO
                }
            ]
        },
        {
            type: EUpdateType.ADD,
            title: 'Additional export option for checklist items',
        },
        {
            type: EUpdateType.ADD,
            title: 'Filtering by start dates',
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Estimates calculation on a Team Workload view',
        }
    ],
    '2021-11-8': [
        {
            type: EUpdateType.IMPROVE,
            title: 'Filtering options (hiding epic)'
        }
    ],
    '2021-10-21': [
        {
            type: EUpdateType.ADD,
            title: 'My tasks',
            text: 'The “Cards assigned to me" space has evolved into "My tasks"! All cards and projects you are working on are gathered in one place.',
            slides: [
                {
                    media: 'https://www.youtube-nocookie.com/embed/xd1jCkAKA_M',
                    text: 'The “Cards assigned to me" space has evolved into "My tasks"! All cards and projects you are working on are gathered in one place.  <a href="https://www.kanbanchi.com/my-tasks" target="_blank">Learn more</a>  about the new types of cards and features.',
                    title: 'My tasks',
                    type: EUpdateSlideType.VIDEO
                }
            ]
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Checklist items due dates on touch-screen devices',
        }
    ],
    '2021-10-7': [
        {
            type: EUpdateType.ADD,
            title: 'Filtering by roles'
        },
        {
            type: EUpdateType.ADD,
            title: 'Grid view for attachments'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Navigation panel UI improvements'
        }
    ],
    '2021-9-30': [
        {
            type: EUpdateType.ADD,
            title: 'Notifications for colleagues who turned them off (accidentally)',
            text: 'Wow! This is a powerful release. Send notifications to your colleagues even if they (accidentally) turned them off, use our presets for due dates filtering and watch your cards marked as done as soon as they reach your targeted list.',
            slides: [
                {
                    media: '/dist/img/releases/2-98/2-98@2x.png',
                    text: 'Wow! This is a powerful release. Send notifications to your colleagues even if they (accidentally) turned them off, use our presets for due dates filtering and watch your cards marked as done as soon as they reach your targeted list.  <a href="https://www.kanbanchi.com/wow-powerful-kanbanchi-release" target="_blank">Learn more</a>',
                    title: 'Additional notifications and filtering options, auto-marking cards as done',
                    type: EUpdateSlideType.IMAGE
                }
            ]
        },
        {
            type: EUpdateType.ADD,
            title: 'Presets when filtering by due dates',
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Card becomes done when moved to the done list',
        }
    ],
    '2021-9-23': [
        {
            type: EUpdateType.ADD,
            title: 'Checklist items and due dates on the Gantt chart (tooltips)'
        }
    ],
    '2021-9-7': [
        {
            type: EUpdateType.ADD,
            title: 'Ability to move card to another board preserving custom properties'
        }
    ],
    '2021-8-31': [
        {
            type: EUpdateType.IMPROVE,
            title: 'Export configuration: date and time format; columns and their placement'
        }
    ],
    '2021-8-24': [
        {
            type: EUpdateType.ADD,
            title: 'Card actions in subcards list'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Custom properties: hyperlinks in "text" type'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Ability to add prefix to all cards without it'
        }
    ],
    '2021-8-18': [
        {
            type: EUpdateType.ADD,
            title: 'Browser notifications'
        }
    ],
    '2021-8-4': [
        {
            type: EUpdateType.ADD,
            title: 'Ability to quickly find card in work'
        },
        {
            type: EUpdateType.ADD,
            title: 'Dragging multiple cards'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Creating card from template'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Pin of custom properties'
        }
    ],
    '2021-7-23': [
        {
            type: EUpdateType.IMPROVE,
            title: 'Creating cards when a filter is on'
        }
    ],
    '2021-7-13': [
        {
            type: EUpdateType.ADD,
            title: 'Card progress automation',
            text: 'Simplify your routine with card progress automation. Leave the repetitive stuff to Kanbanchi and it will keep your boards nice and up to date.',
            slides: [
                {
                    media: '/dist/img/releases/2-93/2-93@2x.png',
                    text: 'Simplify your routine with card progress automation. Leave the repetitive stuff to Kanbanchi and it will keep your boards nice and up to date.  <a href="https://www.kanbanchi.com/card-progress-automation" target="_blank">Learn more</a>',
                    title: 'Card progress automation',
                    type: EUpdateSlideType.IMAGE
                }
            ]
        }
    ],
    '2021-7-6': [
        {
            type: EUpdateType.ADD,
            title: 'List grouping',
            text: 'Open up new opportunities for data tracking with the fresh Kanbanchi feature - list grouping. View your tasks from different perspectives and manage the project smart.',
            slides: [
                {
                    media: '/dist/img/releases/2-92/2-92@2x.png',
                    text: 'Open up new opportunities for data tracking with the fresh Kanbanchi feature - list grouping. View your tasks from different perspectives and manage the project smart. <a href="https://www.kanbanchi.com/list-grouping" target="_blank">Learn more</a>',
                    title: 'List grouping',
                    type: EUpdateSlideType.IMAGE
                }
            ]
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Email notifications speed up',
        }
    ],
    '2021-6-21': [
        {
            type: EUpdateType.ADD,
            title: 'Attachments in comments',
            text: 'Need to quickly let your colleagues know they should check any document? Add file as a comment and leave a message for them. Save time and keep the attachments section tidy.',
            slides: [
                {
                    media: '/dist/img/releases/2-91/2-91@2x.png',
                    text: 'Need to quickly let your colleagues know they should check any document? Add file as a comment and leave a message for them. Save time and keep the attachments section tidy. <a href="https://www.kanbanchi.com/faqwd/how-do-i-attach-files-to-comments" target="_blank">Learn more</a>',
                    title: 'Attachments in comments',
                    type: EUpdateSlideType.IMAGE
                }
            ]
        },
        {
            type: EUpdateType.FIX,
            title: 'Fixed a bug with having legacy card creator when copying and creating from templates/recurring',
        }
    ],
    '2021-6-16': [
        {
            type: EUpdateType.ADD,
            title: 'More custom properties',
            text: 'More flexibility to support your workflow! With this release, we are adding an option to add emails, phone numbers, and custom dropdowns to your cards.',
            slides: [
                {
                    media: '/dist/img/releases/2-90/2-90@2x.png',
                    text: 'More flexibility to support your workflow! With this release, we are adding an option to add emails, phone numbers, and custom dropdowns to your cards. <a href="https://www.kanbanchi.com/faqwd/how-do-i-add-custom-property-to-my-cards" target="_blank">Learn more</a>',
                    title: 'More custom properties',
                    type: EUpdateSlideType.IMAGE
                }
            ]
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Importing boards from Trello, Jira and CSV',
        }
    ],
    '2021-6-3': [
        {
            type: EUpdateType.ADD,
            title: 'Helpful tips for users'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Ability to add weight to subcards'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Tags management'
        }
    ],
    '2021-5-18': [
        {
            type: EUpdateType.ADD,
            title: 'Checklist items author',
        },
        {
            type: EUpdateType.IMPROVE,
            title: '<a href="https://www.kanbanchi.com/kanbanchi-navigation-panel-improvements" target="_blank">Navigation panel UI</a>',
            text: 'Move across your project boards effortlessly with the improved Kanbanchi navigation panel. Easy switchers and helpful buttons are here for your smooth experience.',
            slides: [
                {
                    media: '/dist/img/releases/2-88/2-88@2x.jpg',
                    text: 'Move across your project boards effortlessly with the improved Kanbanchi navigation panel. Easy switchers and helpful buttons are here for your smooth experience. <a href="https://www.kanbanchi.com/kanbanchi-navigation-panel-improvements" target="_blank">Learn more</a>',
                    title: 'Navigation panel improvements',
                    type: EUpdateSlideType.IMAGE
                }
            ]
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Top panel UI',
        }
    ],
    '2021-5-12': [
        {
            type: EUpdateType.IMPROVE,
            title: '<a href="https://www.kanbanchi.com/cards-assigned-to-me-improvements" target="_blank">Cards assigned to me</a>',
            text: 'The "Cards assigned to me" space has become even better. The enhanced functionality will definitely help you to monitor your tasks carefully.',
            slides: [
                {
                    media: '/dist/img/releases/2-87/2-87@2x.jpg',
                    text: 'The "Cards assigned to me" space has become even better. The enhanced functionality will definitely help you to monitor your tasks carefully. <a href="https://www.kanbanchi.com/cards-assigned-to-me-improvements" target="_blank">Learn more</a>',
                    title: '"Cards assigned to me" improvements',
                    type: EUpdateSlideType.IMAGE
                }
            ]
        }
    ],
    '2021-4-30': [
        {
            type: EUpdateType.ADD,
            title: '<a href="https://www.kanbanchi.com/faqwd/user-roles-kanbanchi" target="_blank">User roles</a>',

            text: 'Identify people’s roles on different tasks. It will help collaborators better understand who is responsible for what and enhance your team communications.',
            slides: [
                {
                    media: '/dist/img/releases/2-86/2-86@2x.jpg',
                    text: 'Identify people’s roles on different tasks. It will help collaborators better understand who is responsible for what and enhance your team communications. <a href="https://www.kanbanchi.com/faqwd/user-roles-kanbanchi" target="_blank">Learn more</a>',
                    title: 'User roles in Kanbanchi',
                    type: EUpdateSlideType.IMAGE
                }
            ]
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Filter UX/UI',
        }
    ],
    '2021-4-27': [
        {
            type: EUpdateType.ADD,
            title: 'Ability to add up to ten colour tags to a card'
        },
        {
            type: EUpdateType.ADD,
            title: 'Search option in a subscription management panel'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Made export and import to the same format'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Subscription management panel behaviour when adding duplicate emails'
        }
    ],
    '2021-4-20': [
        {
            type: EUpdateType.ADD,
            title: '<a href="https://www.kanbanchi.com/custom-card-properties" target="_blank">Custom card properties</a>',
            slides: [
                {
                    media: '/dist/img/releases/2-84/2-84@2x.jpg',
                    text: 'Add custom properties to your cards to adjust your boards to your projects in the best possible way. Starting with the option to add text, numbers and dates, we are continuously moving towards customisation. <a href="https://www.kanbanchi.com/custom-card-properties" target="_blank">Learn more</a>',
                    title: 'Custom card properties',
                    type: EUpdateSlideType.IMAGE
                }
            ]
        },
        {
            type: EUpdateType.FIX,
            title: 'Mentioning in comments when working with cards assigned to you',
        }
    ],
    '2021-4-9': [
        {
            type: EUpdateType.ADD,
            title: 'Assignees and colour tags icons on subcards within the Epic card'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Filtering options for all cards assigned to me'
        }
    ],
    '2021-4-6': [
        {
            type: EUpdateType.IMPROVE,
            title: '<a href="https://www.kanbanchi.com/faqwd/in-app-notifications" target="_blank">In-app notifications (more options)</a>',
            text: 'We have improved the way in-app notifications work. Now you have more options and more control of the information you receive.',
            slides: [
                {
                    media: '/dist/img/releases/2-82/2-82@2x.jpg',
                    text: 'We have improved the way in-app notifications work. Now you have more options and more control of the information you receive.  <a href="https://www.kanbanchi.com/faqwd/in-app-notifications" target="_blank">Learn more</a>',
                    title: 'Notifications improvements',
                    type: EUpdateSlideType.IMAGE
                }
            ]
        }
    ],
    '2021-3-30': [
        {
            type: EUpdateType.ADD,
            title: '<a href="https://www.kanbanchi.com/default-card-properties" target="_blank">Setting default card properties</a>'
        },
        {
            type: EUpdateType.ADD,
            title: 'Filtering cards by colour'
        }
    ],
    '2021-3-26': [
        {
            type: EUpdateType.ADD,
            title: 'Ability to see all cards assigned to me on different boards',
            text: 'Focus and get things done with Kanbanchi "Cards assigned to me" - a place where you can find all your tasks no matter what boards they are located on.',
            slides: [
                {
                    media: 'https://www.youtube-nocookie.com/embed/HfpQsQNxBm4',
                    text: 'Focus and get things done with Kanbanchi "Cards assigned to me" - a place where you can find all your tasks no matter what boards they are located on.  <a href="https://www.kanbanchi.com/cards-assigned-to-me" target="_blank">Learn more</a>',
                    title: 'Cards assigned to me',
                    type: EUpdateSlideType.VIDEO
                }
            ]
        }
    ],
    '2021-3-24': [
        {
            type: EUpdateType.ADD,
            title: 'Ability to copy epic with its subcards'
        },
        {
            type: EUpdateType.ADD,
            title: 'Ability to pin subcards to card'
        }
    ],
    '2021-3-19': [
        {
            type: EUpdateType.ADD,
            title: 'Ability to share saved filters',
        },
        {
            type: EUpdateType.ADD,
            title: 'Ability to choose default view for boards',
        },
        {
            type: EUpdateType.ADD,
            title: 'Ability to pin checklists to card',
            text: 'Meet a new Kanbanchi feature! Pinning checklists to cards you need to perform fewer actions to see the list of tasks that need to be accomplished.',
            slides: [
                {
                    media: '/dist/img/releases/2-79/2-79@2x.jpg',
                    text: 'Meet a new Kanbanchi feature! Pinning checklists to cards you need to perform fewer actions to see the list of tasks that need to be accomplished.  <a href="https://www.kanbanchi.com/pinning-checklists-to-cards" target="_blank">Learn more</a>',
                    title: 'Pinning checklists to cards',
                    type: EUpdateSlideType.IMAGE
                }
            ]
        },
        {
            type: EUpdateType.ADD,
            title: 'Ability to rename tags',
        }
    ],
    '2021-2-11': [
        {
            type: EUpdateType.IMPROVE,
            title: 'Creating recurring cards with the dates &amp; prior to start date',
            text: 'Now your task automation becomes even better! Add start and due dates to your recurring cards and make sure they appear on board prior to the start date.',
            slides: [
                {
                    media: '/dist/img/releases/2-77/2-77@2x.png',
                    text: 'Now your task automation becomes even better! Add start and due dates to your recurring cards and make sure they appear on board prior to the start date. <a href="https://www.kanbanchi.com/recurring-cards-prior-to-start-date" target="_blank">Learn more</a>',
                    title: 'Recurring cards improvements',
                    type: EUpdateSlideType.IMAGE
                }
            ]
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Milestones status on the Gantt chart (done/overdue)',
        }
    ],
    '2021-1-29': [
        {
            type: EUpdateType.ADD,
            title: 'Import data from Jira',
            text: 'Meet new import options in Kanbanchi. Effortless migration and easy file uploading are now available.',
            slides: [
                {
                    media: '/dist/img/releases/2-76/2-76@2x.png',
                    text: 'Meet new import options in Kanbanchi. Effortless migration and easy file uploading are now available. <a href="https://www.kanbanchi.com/new-import-options-in-kanbanchi" target="_blank">Learn more</a>',
                    title: 'New import options',
                    type: EUpdateSlideType.IMAGE
                }
            ]
        },
        {
            type: EUpdateType.ADD,
            title: 'Import data from CSV',
        }
    ],
    '2021-1-25': [
        {
            type: EUpdateType.ADD,
            title: 'Checklists due dates'
        },
        {
            type: EUpdateType.ADD,
            title: 'Ability to archive cards instead of permanent deletion'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Visual representation of collaborators on board'
        }
    ],
    '2020-12-24': [
        {
            type: EUpdateType.ADD,
            title: 'Ability to add multiple checklists to a card'
        },
        {
            type: EUpdateType.ADD,
            title: 'Attachments - drag &amp; drop option'
        },
        {
            type: EUpdateType.ADD,
            title: 'Attachments - paste from clipboard option'
        }
    ],
    '2020-12-16': [
        {
            type: EUpdateType.ADD,
            title: 'Milestones',
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Gantt chart UI/UX',
            text: 'Meet the updated Kanbanchi Gantt chart with milestones. Its fresh interface and brand new features will give you a clear visual representation of project progress and time frames.',
            slides: [
                {
                    media: 'https://www.youtube-nocookie.com/embed/EkNw27tV7AA',
                    text: 'Meet the updated Kanbanchi Gantt chart with milestones. Its fresh interface and brand new features will give you a clear visual representation of project progress and time frames. <a href="https://www.kanbanchi.com/updated-kanbanchi-gantt-chart-fresh-interface-and-brand-new-features" target="_blank">Learn more</a>',
                    title: 'New Kanbanchi Gantt chart & Milestones',
                    type: EUpdateSlideType.VIDEO
                }
            ]
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Board details UI',
        }
    ],
    '2020-11-19': [
        {
            type: EUpdateType.IMPROVE,
            title: 'Time tracker improvements'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Multiselect improvements'
        }
    ],
    '2020-11-5': [
        {
            type: EUpdateType.ADD,
            title: 'Checklists sorting options',
            text: 'Enhance your use of Kanbanchi with the new Checklists features. Assign checklists to teammates, sort items and create subcards from checklists just in one click.',
            slides: [
                {
                    media: '/dist/img/releases/2-71/2-71@2x.png',
                    text: 'Enhance your use of Kanbanchi with the new Checklists features. Assign checklists to teammates, sort items and create subcards from checklists just in one click. <a href="https://www.kanbanchi.com/new-checklists-features" target="_blank">Learn more</a>',
                    title: 'New Checklists features',
                    type: EUpdateSlideType.IMAGE
                }
            ]
        },
        {
            type: EUpdateType.ADD,
            title: 'Ability to convert checklist item to subcard',
        },
        {
            type: EUpdateType.ADD,
            title: 'Ability to assign checklist item',
        }
    ],
    '2020-10-14': [
        {
            type: EUpdateType.ADD,
            title: 'Admin panel to manage subscriptions '
        }
    ],
    '2020-9-30': [
        {
            type: EUpdateType.ADD,
            title: 'Free plan for corporate users. <a href="https://www.kanbanchi.com/faqwd/kanbanchi-free-plan" target="_blank">Learn more</a>'
        }
    ],
    '2020-9-21': [
        {
            type: EUpdateType.ADD,
            title: 'Recurring cards for work automation',
            text: 'Automate your workflow with recurring cards in Kanbanchi. Create templates to save time when adding new cards to boards. Make your work faster and become more productive with new automations.',
            slides: [
                {
                    media: 'https://www.youtube-nocookie.com/embed/zHwN_HI4YqE',
                    text: 'Automate your workflow with recurring cards in Kanbanchi. Create templates to save time when adding new cards to boards. Make your work faster and become more productive with new automations.    <a href="https://www.kanbanchi.com/automate-your-workflow-with-recurring-cards-and-cards-templates" target="_blank">Learn more</a>',
                    title: 'Recurring cards & Card templates',
                    type: EUpdateSlideType.VIDEO
                }
            ]
        },
        {
            type: EUpdateType.ADD,
            title: 'Card templates',
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Checklists creation in one action (pasting each item with the new line)',
        }
    ],
    '2020-9-2': [
        {
            type: EUpdateType.IMPROVE,
            title: 'Moving cards via action button'
        }
    ],
    '2020-8-26': [
        {
            type: EUpdateType.ADD,
            title: 'Search hints',
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Kanban view UI improvements',
            text: 'Stay focused on what matters most to you - your work. And the updated Kanban view UI will definitely help you with it.',
            slides: [
                {
                    media: '/dist/img/releases/2-66/2-66@2x.png',
                    text: 'Stay focused on what matters most to you - your work. And the updated Kanban view UI will definitely help you with it.\n                <a href="https://www.kanbanchi.com/meet-new-kanban-board" target="_blank">Learn more</a>',
                    title: 'New Kanban view UI',
                    type: EUpdateSlideType.IMAGE
                }
            ]
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Performance improvements',
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Filter UI improvements',
        }
    ],
    '2020-8-7': [
        {
            type: EUpdateType.ADD,
            title: 'Automatic backups'
        },
        {
            type: EUpdateType.ADD,
            title: 'Export to Google Spreadsheets - link to card'
        }
    ],
    '2020-8-4': [
        {
            type: EUpdateType.ADD,
            title: 'Navigation panel - section "Recent"'
        },
        {
            type: EUpdateType.ADD,
            title: 'Gantt chart - dependency chain shift'
        }
    ],
    '2020-7-30': [
        {
            type: EUpdateType.ADD,
            title: 'Creating cards on the List view',
        },
        {
            type: EUpdateType.ADD,
            title: 'Permission type "can comment" for a board',
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Card panel UI/UX improvements',
            text: 'Adding card details is now easier and faster! They are all on the right, just choose anything you need and add it to the card. Also, quickly access the details that are already added.',
            slides: [
                {
                    media: '/dist/img/releases/2-65/2-65@2x.png',
                    text: 'Adding card details is now easier and faster! They are all on the right, just choose anything you need and add it to the card. Also, quickly access the details that are already added.\n                <a href="https://www.kanbanchi.com/new-kanbanchi-card-panel" target="_blank">Learn more</a>',
                    title: 'New Kanbanchi card panel',
                    type: EUpdateSlideType.IMAGE
                }
            ]
        }
    ],
    '2020-7-22': [
        {
            type: EUpdateType.ADD,
            title: 'Reports - Team performance chart (beta)',
            text: 'Team performance chart will help you to see your team’s progress in getting tasks done. Choose a project, time period and people involved and check out the overall and individual task completion rate.',
            slides: [
                {
                    media: '/dist/img/releases/2-64/2-64@2x.png',
                    text: 'Team performance chart will help you to see your team’s progress in getting tasks done. Choose a project, time period and people involved and check out the overall and individual task completion rate.\n                <a href="https://www.kanbanchi.com/faqwd/team-performance-report-kanbanchi" target="_blank">Learn more</a>',
                    title: 'Team performance chart',
                    type: EUpdateSlideType.IMAGE
                }
            ]
        }
    ],
    '2020-7-16': [
        {
            type: EUpdateType.IMPROVE,
            title: 'Filtering options (hiding empty lists, quick filter)'
        }
    ],
    '2020-7-15': [
        {
            type: EUpdateType.IMPROVE,
            title: 'Team workload view UI improvements'
        },
        {
            type: EUpdateType.FIX,
            title: 'Fixed a bug with settings panel on the list view'
        }
    ],
    '2020-7-1': [
        {
            type: EUpdateType.ADD,
            title: 'Referral and Affiliate program'
        },
        {
            type: EUpdateType.ADD,
            title: 'Inviting friends to Kanbanchi via email'
        },
        {
            type: EUpdateType.FIX,
            title: 'Bug with the custom background images'
        }
    ],
    '2020-6-25': [
        {
            type: EUpdateType.IMPROVE,
            title: 'Saving zoom state on Gantt chart'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Gantt chart bars match card colours'
        }
    ],
    '2020-6-23': [
        {
            type: EUpdateType.ADD,
            title: 'Restoring boards from backup files'
        },
        {
            type: EUpdateType.ADD,
            title: 'Auto-adding cards on Gantt chart'
        }
    ],
    '2020-6-11': [
        {
            type: EUpdateType.ADD,
            title: 'Storing the last opened tab on the boards list'
        },
        {
            type: EUpdateType.ADD,
            title: 'Last selected board view storing'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Export to Google Spreadsheets'
        }
    ],
    '2020-4-23': [
        {
            type: EUpdateType.ADD,
            title: 'Read-only mode after access expiration'
        },
        {
            type: EUpdateType.ADD,
            title: 'EDU templates'
        }
    ],
    '2020-4-17': [
        {
            type: EUpdateType.ADD,
            title: 'Card prefixes'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Move cards to another list in one click (link in a card)'
        }
    ],
    '2020-4-10': [
        {
            type: EUpdateType.IMPROVE,
            title: 'Login screen update'
        },
        {
            type: EUpdateType.FIX,
            title: 'Gantt chart card dependencies bug'
        }
    ],
    '2020-4-3': [
        {
            type: EUpdateType.ADD,
            title: 'Reverse order for comments'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Assigned users are kept when moving a card to another board'
        }
    ],
    '2020-3-26': [
        {
            type: EUpdateType.ADD,
            title: 'New filter option',
        },
        {
            type: EUpdateType.ADD,
            title: 'Trial period countdown',
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Updated Help panel',
        }
    ],
    '2020-3-19': [
        {
            type: EUpdateType.IMPROVE,
            title: 'UI/UX improvements'
        },
        {
            type: EUpdateType.ADD,
            title: 'Feedback bubble'
        }
    ],
    '2020-3-13': [
        {
            type: EUpdateType.IMPROVE,
            title: 'Free boards templates',
            text: 'Haven’t tried Kanbanchi templates yet? Now’s the time! Choose any of the default templates or create your own to speed up your work process. Now available for all Kanbanchi users.',
            slides: [
                {
                    media: '/dist/img/releases/2-57/2-57@2x.png',
                    text: 'Haven’t tried Kanbanchi templates yet? Now’s the time! Choose any of the default templates or create your own to speed up your work process. Now available for all Kanbanchi users.\n                <a href="https://www.kanbanchi.com/faqwd/how-to-use-boards-templates" target="_blank">Learn more</a>',
                    title: 'Streamline your workflow with templates',
                    type: EUpdateSlideType.IMAGE
                }
            ]
        }
    ],
    '2020-3-5': [
        {
            type: EUpdateType.IMPROVE,
            title: 'Sign up process for new users'
        },
        {
            type: EUpdateType.FIX,
            title: 'Gantt chart bugs'
        },
        {
            type: EUpdateType.FIX,
            title: 'Card details bug'
        }
    ],
    '2020-2-26': [
        {
            type: EUpdateType.IMPROVE,
            title: 'Board settings relocation'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'UI/UX improvements'
        },
        {
            type: EUpdateType.FIX,
            title: 'Bug with archived card'
        }
    ],
    '2020-2-5': [
        {
            type: EUpdateType.ADD,
            title: 'Gantt chart on mobile devices',
            text: 'Kanbanchi mobile experience becomes smoother and deeper. Updated interface and extended functionality including the Gantt chart feature are now available in a mobile browser.',
            slides: [
                {
                    media: '/dist/img/releases/2-53/2-53@2x.png',
                    text: 'Kanbanchi mobile experience becomes smoother and deeper. Updated interface and extended functionality including the Gantt chart feature are now available in a mobile browser.\n                <a href="https://www.kanbanchi.com/kanbanchi-works-on-mobile-devices" target="_blank">Learn&nbsp;more</a> useful tips and don’t forget to drop us a line.',
                    title: 'Kanbanchi works on mobile devices',
                    type: EUpdateSlideType.IMAGE
                }
            ]
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Gantt chart UI improvement',
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'UI/UX improvements',
        },
        {
            type: EUpdateType.FIX,
            title: 'Bug with feature pop-up for new users',
        }
    ],
    '2020-1-30': [
        {
            type: EUpdateType.FIX,
            title: 'Bug with showing done cards as overdue on the List view'
        },
        {
            type: EUpdateType.FIX,
            title: 'Bug of the Upgrade button on user profile box'
        }
    ],
    '2020-1-21': [
        {
            type: EUpdateType.ADD,
            title: 'Team Workload view',
            text: 'Manage your team resources in Kanbanchi - meet the Team Workload view. Instantly see the workload your team members have and make quick decisions for better productivity.',
            slides: [
                {
                    media: '/dist/img/releases/2-52/2-52@2x.png',
                    text: 'Manage your team resources in Kanbanchi - meet the Team Workload view. Instantly see the workload your team members have and make quick decisions for better productivity. <a href="https://www.kanbanchi.com/kanbanchi-for-resource-management-meet-the-team-workload-view" target="_blank">Learn&nbsp;more</a>',
                    title: 'Team workload monitoring',
                    type: EUpdateSlideType.IMAGE
                }
            ]
        },
        {
            type: EUpdateType.ADD,
            title: 'Saving board/list/card description with Ctrl+Enter',
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Reports improvements',
        },
        {
            type: EUpdateType.FIX,
            title: 'Minor bug with the account deletion',
        }
    ],
    '2020-1-16': [
        {
            type: EUpdateType.ADD,
            title: 'New navigation',
            text: 'Navigation becomes more convenient! All the necessary switchers and board settings are now on one left-side panel. And you may choose from its three sizes to find the one suitable for you.',
            slides: [
                {
                    media: '/dist/img/releases/2-51/2-51@2x.png',
                    text: 'Navigation becomes more convenient! All the necessary switchers and board settings are now on one left-side panel. And you may choose from its three sizes to find the one suitable for you. <a href="https://www.kanbanchi.com/new-kanbanchi-navigation-panel" target="_blank">Learn&nbsp;more</a>',
                    title: 'New navigation',
                    type: EUpdateSlideType.IMAGE
                }
            ]
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'UI improvements connected with the new navigation',
        }
    ],
    '2019-12-18': [
        {
            type: EUpdateType.ADD,
            title: 'Add-on for Gmail',
            text: 'Ho ho ho! Meet the Christmas gift from Kanbanchi team - add-on for Gmail. Create new cards on your Kanbanchi boards in seconds from your inboх and boost your productivity.\nHurry up, the first 100 users will get promotional $5.',
            slides: [
                {
                    media: '/dist/img/releases/2-50/2-50@2x.png',
                    text: 'Ho ho ho! Meet the Christmas gift from Kanbanchi team - add-on for Gmail. Create new cards on your Kanbanchi boards in seconds from your inboх and boost your productivity.\nHurry up, the first 100 users will get promotional $5. <a href="https://www.kanbanchi.com/faqwd/card-from-email-addon" target="_blank">Learn&nbsp;more</a>',
                    title: 'Add-on for Gmail',
                    type: EUpdateSlideType.IMAGE
                }
            ]
        },
        {
            type: EUpdateType.FIX,
            title: 'Bug with saving list title',
        }
    ],
    '2019-12-11': [
        {
            type: EUpdateType.ADD,
            title: 'New cards sorting options',
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'List details panel',
            text: 'Check out Kanbanchi improved List details panel. Fresh interface and new sorting options will definitely help you to easily distinguish important tasks and review cards with tight deadlines.',
            slides: [
                {
                    media: '/dist/img/releases/2-49/2-49@2x.png',
                    text: 'Check out Kanbanchi improved List details panel. Fresh interface and new sorting options will definitely help you to easily distinguish important tasks and review cards with tight deadlines. <a href="https://www.kanbanchi.com/faqwd/how-can-i-sort-cards-in-a-list-in-kanbanchi" target="_blank">Learn&nbsp;more</a>',
                    title: 'Improved List details panel',
                    type: EUpdateSlideType.IMAGE
                }
            ]
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Scroll improvements',
        }
    ],
    '2019-11-28': [
        {
            type: EUpdateType.IMPROVE,
            title: 'Zoom on Gantt Chart'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Automatic link to the card'
        },
        {
            type: EUpdateType.FIX,
            title: 'Bug with the scroll on card details and filter'
        }
    ],
    '2019-11-27': [
        {
            type: EUpdateType.IMPROVE,
            title: 'List View improvements',
            text: 'Improved List View becomes even better. Check out the advanced sorting and grouping functionality and don’t forget to share your feedback with our team, we do value it a lot!',
            slides: [
                {
                    media: '/dist/img/releases/2-48/2-48@2x.png',
                    text: 'Improved List View becomes even better. Check out the advanced sorting and grouping functionality and don’t forget to share your feedback with our team, we do value it a lot! <a href="https://www.kanbanchi.com/faqwd/how-to-use-list-view" target="_blank">Learn&nbsp;more</a>',
                    title: 'List View improvements',
                    type: EUpdateSlideType.IMAGE
                }
            ]
        },
        {
            type: EUpdateType.FIX,
            title: 'Bug with downloading attachments',
        }
    ],
    '2019-10-23': [
        {
            type: EUpdateType.IMPROVE,
            title: 'Filter presets',
            text: 'Check out the filter presets to get ultimately quick access to the tasks you need. Just tune the filter, save the preset and here it is!',
            slides: [
                {
                    media: '/dist/img/releases/2-47/2-47@2x.png',
                    text: 'Check out the filter presets to get ultimately quick access to the tasks you need. Just tune the filter, save the preset and here it is! <a href="https://www.kanbanchi.com/faqwd/filter-presets" target="_blank">Learn more</a>',
                    title: 'Filter presets',
                    type: EUpdateSlideType.IMAGE
                }
            ]
        }
    ],
    '2019-10-8': [
        {
            type: EUpdateType.ADD,
            title: 'Links to cards preview setting',
            text: 'Now board’s owner may decide what data is represented while sharing a link to a card via messengers.',
            slides: [
                {
                    media: '/dist/img/releases/2-45/2-45@2x.png',
                    text: 'Now board’s owner may decide what data is represented while sharing a link to a card via messengers. <a href="https://www.kanbanchi.com/faqwd/links-to-cards-preview" target="_blank">Learn more</a>',
                    title: 'Links to cards preview',
                    type: EUpdateSlideType.IMAGE
                }
            ]
        }
    ],
    '2019-9-26': [
        {
            type: EUpdateType.IMPROVE,
            title: 'Mobile layout improvements'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Months and weeks in Notifications before due date'
        }
    ],
    '2019-8-21': [
        {
            type: EUpdateType.ADD,
            title: 'Reports (beta) - Burn Up chart',
            text: 'Burn Up chart will provide you with the necessary details to analyse your workflow and compare the number of completed tasks with the overall work scope. Take advantage and boost up your productivity.',
            slides: [
                {
                    media: '/dist/img/releases/2-40/2-40.png',
                    text: 'Burn Up chart will provide you with the necessary details to analyse your workflow and compare the number of completed tasks with the overall work scope. Take advantage and boost up your productivity. <a href="https://www.kanbanchi.com/faqwd/kanbanchi-burnup-chart" target="_blank">Learn more</a>.',
                    title: 'Burn Up chart',
                    type: EUpdateSlideType.IMAGE
                }
            ]
        }
    ],
    '2019-8-15': [
        {
            type: EUpdateType.ADD,
            title: 'User productivity chart'
        }
    ],
    '2019-7-24': [
        {
            type: EUpdateType.ADD,
            title: 'Reports (beta) - Cumulative flow diagram',
            text: 'The first reports are now live! Start analysing your workflow with Burn Down chart and Cumulative flow diagram. ',
            slides: [
                {
                    media: '/dist/img/releases/2-38/2-38.png',
                    text: 'The first reports are now live! Start analysing your workflow with Burn Down chart and Cumulative flow diagram. Learn more about Kanbanchi reports and the data collection <a href="https://www.kanbanchi.com/faqwd/kanbanchi-reports-requirements" target="_blank">here</a>.',
                    title: 'Reports',
                    type: EUpdateSlideType.IMAGE
                }
            ]
        },
        {
            type: EUpdateType.ADD,
            title: 'Reports (beta) - Burn Down chart',
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Added the option to archive Epic card with its subcards',
        }
    ],
    '2019-7-3': [
        {
            type: EUpdateType.ADD,
            title: 'Archive (boards, cards, lists)',
            text: 'Archive cards and lists to make your boards work faster. Go through the archived items and restore them any time. Archive boards to see them in a separate tab.',
            slides: [
                {
                    media: '/dist/img/releases/2-36/2-36.png',
                    text: 'Archive cards and lists to make your boards work faster. Go through the archived items and restore them any time. Archive boards to see them in a separate tab. <a href="https://www.kanbanchi.com/faqwd/kanbanchi-archive" target="_blank">Find out more.</a>',
                    title: 'Archive',
                    type: EUpdateSlideType.IMAGE
                }
            ]
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Sign up process for new users',
        },
        {
            type: EUpdateType.FIX,
            title: 'Bug with tags when selecting multiple cards',
        }
    ],
    '2019-6-20': [
        {
            type: EUpdateType.ADD,
            title: 'Notifications before due date',
            text: 'Set up and get notifications before the due date. Achieve better, faster and on time with this new feature. Set up one or multiple notifications to support your work process.',
            slides: [
                {
                    media: '/dist/img/releases/2-35/2-35.png',
                    text: 'Set up and get notifications before the due date. Achieve better, faster and on time with this new feature. Set up one or multiple notifications to support your work process.',
                    title: 'Get notified before the due date!',
                    type: EUpdateSlideType.IMAGE
                }
            ]
        }
    ],
    '2019-6-4': [
        {
            type: EUpdateType.ADD,
            title: 'Keyboard arrows navigation when mentioning users'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'No default card limits in new lists'
        },
        {
            type: EUpdateType.FIX,
            title: 'Bug with unavailable cards list on Gantt chart for some screen resolutions'
        },
        {
            type: EUpdateType.FIX,
            title: 'Uneven time bug when the time rounded up to 5 mins after saving'
        },
        {
            type: EUpdateType.FIX,
            title: 'Subcards sorting on Gantt chart'
        },
        {
            type: EUpdateType.FIX,
            title: 'Bug with copying a board when four boards are already opened'
        }
    ],
    '2019-4-23': [
        {
            type: EUpdateType.IMPROVE,
            title: 'List View layout improvement',
            text: 'Meet Kanbanchi improved List View. We’ve updated the design and advanced its functionality with an absolutely new feature - sorting by properties. Now there are even more opportunities to organise and monitor workflow the best possible way.',
            slides: [
                {
                    media: '/dist/img/releases/2-34/2-34.png',
                    text: 'Meet Kanbanchi improved List View. We’ve updated the design and advanced its functionality with an absolutely new feature - sorting by properties. Now there are even more opportunities to organise and monitor workflow the best possible way.',
                    title: 'The good old List View improvement',
                    type: EUpdateSlideType.IMAGE
                }
            ]
        },
        {
            type: EUpdateType.FIX,
            title: 'Top panel on the List view with 12-hour clock format',
        },
        {
            type: EUpdateType.FIX,
            title: 'Due Date icon on the List View',
        }
    ],
    '2019-4-2': [
        {
            type: EUpdateType.FIX,
            title: 'Bug with adding cards after switching to Gantt and back'
        },
        {
            type: EUpdateType.FIX,
            title: 'Bug with notifications about cards that were moved to another board'
        }
    ],
    '2019-3-19': [
        {
            type: EUpdateType.ADD,
            title: 'Markdown in list titles (bold, italic, struck our text, monospace)'
        },
        {
            type: EUpdateType.FIX,
            title: 'Mobile view improvements'
        },
        {
            type: EUpdateType.FIX,
            title: 'Markdown in cards'
        }
    ],
    '2019-3-13': [
        {
            type: EUpdateType.ADD,
            title: '12-hour clock',
            text: 'Two features that you’ve been waiting. Combo today! The 12-hours clock that some of you are used to and the option of pushing the whole event to your Google Calendar. Time-management will be your strong point now;-)',
            slides: [
                {
                    media: '/dist/img/releases/2-32/2-32.png',
                    text: 'Two features that you’ve been waiting. Combo today! The 12-hours clock that some of you are used to and the option of pushing the whole event to your Google Calendar. Time-management will be your strong point now;-)',
                    title: 'New features for time-management',
                    type: EUpdateSlideType.IMAGE
                }
            ]
        },
        {
            type: EUpdateType.ADD,
            title: 'Uneven time',
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Push to Google Calendar (events)',
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Gantt chart printing',
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Notifications panel',
        },
        {
            type: EUpdateType.FIX,
            title: 'Actions with multiple cards',
        },
        {
            type: EUpdateType.FIX,
            title: 'Bug with empty lists not showing when closing the filer',
        },
        {
            type: EUpdateType.FIX,
            title: 'Tips&amp;Tricks board behaviour',
        }
    ],
    '2019-2-20': [
        {
            type: EUpdateType.ADD,
            title: 'More colour tags',
        },
        {
            type: EUpdateType.ADD,
            title: 'New card colours',
            text: 'Colour your boards to make them more visual and to achieve better results. Combine colour tags and card colours in the unique way to quickly distinguish tasks that need to be done.',
            slides: [
                {
                    media: '/dist/img/releases/2-31/2-31.png',
                    text: 'Colour your boards to make them more visual and to achieve better results. Combine colour tags and card colours in the unique way to quickly distinguish tasks that need to be done.',
                    title: 'More colour tags and card colours',
                    type: EUpdateSlideType.IMAGE
                }
            ]
        },
        {
            type: EUpdateType.ADD,
            title: 'Moving list to another board',
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Actions with multiple cards',
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Profile panel UI improvements',
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Notifications panel',
        },
        {
            type: EUpdateType.FIX,
            title: 'Multiplying similar notifications',
        }
    ],
    '2019-2-8': [
        {
            type: EUpdateType.IMPROVE,
            title: 'UI improvements of the Notifications panel'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Minor UX and UI improvements of the Boards templates'
        },
        {
            type: EUpdateType.FIX,
            title: 'Disappearing Help icon'
        },
        {
            type: EUpdateType.FIX,
            title: 'Boards templates behaviour in FireFox'
        },
        {
            type: EUpdateType.FIX,
            title: 'The ability to open a board when notified about moving a card there'
        },
        {
            type: EUpdateType.FIX,
            title: 'Sharing bug (white pop-up window)'
        },
        {
            type: EUpdateType.FIX,
            title: 'Read-only mode bugs'
        },
        {
            type: EUpdateType.FIX,
            title: 'Actions with multiple cards bugs (status, assignment, dates settings, priorities, views switching)'
        }
    ],
    '2019-2-1': [
        {
            type: EUpdateType.ADD,
            title: 'Easy and fast checkout with a credit card',
        },
        {
            type: EUpdateType.ADD,
            title: 'Team subscriptions management',
            text: 'You’ve been waiting for it desperately. Now you can buy and manage subscriptions for you and your team right within the app. More details in the video for you.',
            slides: [
                {
                    media: 'https://www.youtube-nocookie.com/embed/64AQdSzGcVg',
                    text: 'You’ve been waiting for it desperately. Now you can buy and manage subscriptions for you and your team right within the app. More details in the video for you.',
                    title: 'Manage your Subscriptions',
                    type: EUpdateSlideType.VIDEO
                }
            ]
        }
    ],
    '2019-1-30': [
        {
            type: EUpdateType.ADD,
            title: 'Boards templates',
            text: 'Speed up your work process! Choose any of the boards templates we’ve prepared for you or create your custom templates.',
            slides: [
                {
                    media: 'https://www.youtube-nocookie.com/embed/WMfD_S_T4l8',
                    text: 'Speed up your work process! Choose any of the boards templates we’ve prepared for you or create your custom templates.',
                    title: 'Boards Templates',
                    type: EUpdateSlideType.VIDEO
                }
            ]
        }
    ],
    '2018-12-20': [
        {
            type: EUpdateType.ADD,
            title: 'Mentions ‘@’ in comments',
            text: 'Merry Christmas! We’ve got a gift for you :) Mention people in comments using ‘@‘ and they’ll be notified. Adjust in-app notification settings to get only important updates. Add multiple colour tags to cards to better visualise your workflow.',
            slides: [
                {
                    media: 'https://www.youtube-nocookie.com/embed/6Z06asVgaQM',
                    text: 'Merry Christmas! We’ve got a gift for you :) Mention people in comments using ‘@‘ and they’ll be notified. Adjust in-app notification settings to get only important updates. Add multiple colour tags to cards to better visualise your workflow.',
                    title: 'Meet Mentions & Multiple Colour Tags',
                    type: EUpdateSlideType.VIDEO
                }
            ]
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'New, more granular in-app notification settings',
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Multiple colour tags on cards!',
            slides: [
                {
                    media: 'https://www.youtube-nocookie.com/embed/6Z06asVgaQM',
                    text: 'Merry Christmas! We’ve got a gift for you :) Mention people in comments using ‘@‘ and they’ll be notified. Adjust in-app notification settings to get only important updates. Add multiple colour tags to cards to better visualise your workflow.',
                    title: 'Meet Mentions & Multiple Colour Tags',
                    type: EUpdateSlideType.VIDEO
                }
            ]
        },
        {
            type: EUpdateType.FIX,
            title: 'Bug where Gantt chart layout was displaced when the filter was on',
        },
        {
            type: EUpdateType.FIX,
            title: 'Bug where predecessors showed incorrectly when the filter was on',
        }
    ],
    '2018-11-26': [
        {
            type: EUpdateType.ADD,
            title: 'Option of printing the Gantt chart (built-in browser printing)',
        },
        {
            type: EUpdateType.ADD,
            title: 'Starred boards',
            text: 'Meet the new way of structuring your boards list - Starred Boards. Just choose which boards you would like to star and spend less time searching for them.',
            slides: [
                {
                    media: 'https://www.youtube-nocookie.com/embed/in_1dJBhImg',
                    text: 'Meet the new way of structuring your boards list - Starred Boards. Just choose which boards you would like to star and spend less time searching for them.',
                    title: 'Starred Boards',
                    type: EUpdateSlideType.VIDEO
                }
            ]
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Sign up process is now easier',
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Kanbanchi is now working faster!',
        },
        {
            type: EUpdateType.FIX,
            title: 'Cookie management improved',
        },
        {
            type: EUpdateType.FIX,
            title: 'Done cards in the List view',
        },
        {
            type: EUpdateType.FIX,
            title: 'Actions with predecessors',
        }
    ],
    '2018-5-24': [
        {
            type: EUpdateType.ADD,
            title: 'Deletion of Kanbanchi account and all user data (GDPR)'
        },
        {
            type: EUpdateType.ADD,
            title: 'Cookie consent screen and cookie settings (GDPR)'
        },
        {
            type: EUpdateType.FIX,
            title: 'Changing list position'
        }
    ],
    '2018-4-27': [
        {
            type: EUpdateType.ADD,
            title: 'Pre-saving of comments'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Improved saving of card description'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Improved multiselection of cards'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Imroved security and load time for background images on board'
        },
        {
            type: EUpdateType.FIX,
            title: 'Minor bug fixes'
        }
    ],
    '2018-4-17': [
        {
            type: EUpdateType.ADD,
            title: 'New dependency types for Gantt Chart',
            text: 'Hello everyone! It’s been quite some time since our last video. Meanwhile, the app got the new features. Among others, Time Tracker Data Export & New Gantt Chart Dependencies. Watch this video to learn more!',
            slides: [
                {
                    media: 'https://www.youtube-nocookie.com/embed/0IIVs1rcemA',
                    text: 'Hello everyone! It’s been quite some time since our last video. Meanwhile, the app got the new features. Among others, Time Tracker Data Export & New Gantt Chart Dependencies. Watch this video to learn more!',
                    title: 'What’s new in Kanbanchi?',
                    type: EUpdateSlideType.VIDEO
                }
            ]
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'System in-app notifications for moving cards to other boards',
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Right panel remembers last active tab (Card Details, Timing or Activity) when opening card',
        },
        {
            type: EUpdateType.FIX,
            title: 'Links in card description',
        },
        {
            type: EUpdateType.FIX,
            title: 'Closing of export notification',
        }
    ],
    '2018-4-6': [
        {
            type: EUpdateType.ADD,
            title: 'Time Tracker to Google Spreadsheet export',
            text: 'Hey! Time Tracker to Google Spreadsheet export is now available with the Professional and Starter plans. By the way one of the teams is eager to use it;)',
            slides: [
                {
                    media: '/dist/img/export-timetracker-to-sheets.png',
                    text: 'Hey! Time Tracker to Google Spreadsheet export is now available with the Professional and Starter plans. By the way one of the teams is eager to use it;)',
                    title: 'Meet Time Tracker data export!',
                    type: EUpdateSlideType.IMAGE
                }
            ]
        },
        {
            type: EUpdateType.ADD,
            title: 'Pre-save function (data in the card description is saved)',
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'More colours, representing collaborators currently on board',
        },
        {
            type: EUpdateType.FIX,
            title: 'Email forwarding',
        },
        {
            type: EUpdateType.FIX,
            title: 'Sorting cards in the list',
        }
    ],
    '2018-3-29': [
        {
            type: EUpdateType.ADD,
            title: 'Notifications filter'
        },
        {
            type: EUpdateType.ADD,
            title: 'Coloured cards on the left-side panel of the Gantt Chart'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Google authorization method upgraded to oAuth 2.0'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Help information for permissions requests'
        },
        {
            type: EUpdateType.FIX,
            title: 'Inactivity disconnect message'
        },
        {
            type: EUpdateType.FIX,
            title: 'Auto sharing of attachments'
        },
        {
            type: EUpdateType.FIX,
            title: 'Bug when collaborators userpics on boards doubled or tripled'
        },
        {
            type: EUpdateType.FIX,
            title: 'Minor bug fixes'
        }
    ],
    '2018-2-19': [
        {
            type: EUpdateType.ADD,
            title: 'Email notifications about the trial progress'
        },
        {
            type: EUpdateType.IMPROVE,
            title: ' UI/UX of profile box and Trial/Upgrade options'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Progress is shown on Gantt Chart and in List view'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Priority management options when multiple cards are selected'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Colour labels on Gantt Chart'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Minor UI improvements'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Notifications about trial progress'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'App behavior when subscription is purchased or cancelled'
        },
        {
            type: EUpdateType.FIX,
            title: 'Minor bug fixes'
        }
    ],
    '2018-1-31': [
        {
            type: EUpdateType.IMPROVE,
            title: 'System notifications about releases'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'New unobtrusive blockers for features not available on user’s current plan'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'More tag management options when multiple cards are selected'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'List of successors in Dependencies section of Card Details'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'New sign in button “Sign In with Google”'
        },
        {
            type: EUpdateType.FIX,
            title: 'Bug where Google asked for permissions twice during signup'
        },
        {
            type: EUpdateType.FIX,
            title: 'Bug where a card was marked overdue before its due date'
        },
        {
            type: EUpdateType.FIX,
            title: 'Incorrect layout when uploading a logo to a board'
        }
    ],
    '2018-1-18': [
        {
            type: EUpdateType.ADD,
            title: 'Backups now include dependencies',
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'New Pricing',
            text: 'Hi everyone! Welcome two coherent subscription plans – Starter and Professional – instead of multiple feature-based subscriptions. ',
            slides: [
                {
                    media: 'https://www.youtube-nocookie.com/embed/ZUAv-xGQlKM',
                    text: 'Hi everyone! Welcome two coherent subscription plans – Starter and Professional – instead of multiple feature-based subscriptions. See information on <a href="https://www.kanbanchi.com/faqwd/legacy-subscriptions" target="_blank">legacy subscriptions</a> or learn more about the <a href="https://www.kanbanchi.com/faqwd/difference-between-plans" target="_blank">new plans</a>.',
                    title: 'New Kanbanchi Pricing',
                    type: EUpdateSlideType.VIDEO
                }
            ]
        },
        {
            type: EUpdateType.FIX,
            title: 'Bug where filtered resulted were not saved when switching between different views',
        },
        {
            type: EUpdateType.FIX,
            title: 'Bug where the frame signalling the current date on Gantt Chart didn’t change when switching to a weekly timescale',
        }
    ],
    '2017-12-29': [
        {
            type: EUpdateType.ADD,
            title: 'Gantt Chart Dependencies (Finish-to-Start)',
            text: 'Our Christmas gift to you! Meet the much-awaited Gantt Chart Dependencies :)',
            slides: [
                {
                    media: 'https://www.youtube-nocookie.com/embed/XXHZ4ImYbt8',
                    text: 'Our Christmas gift to you! Meet the much-awaited Gantt Chart Dependencies :)',
                    title: 'Meet Gantt Chart Dependencies',
                    type: EUpdateSlideType.VIDEO
                }
            ]
        },
        {
            type: EUpdateType.FIX,
            title: 'Bug where card moved to another board was still present on Gantt Chart',
        }
    ],
    '2017-12-12': [
        {
            type: EUpdateType.IMPROVE,
            title: 'Performance improvements'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Minor UI improvements of the new Move card feature'
        },
        {
            type: EUpdateType.FIX,
            title: 'Bug where cards moved to another list appeared at the bottom of the list'
        }
    ],
    '2017-12-5': [
        {
            type: EUpdateType.FIX,
            title: ' A new improved way to move cards between lists and boards',
            text: 'Check out a new improved way to move cards between lists and boards!',
            slides: [
                {
                    media: 'https://www.youtube-nocookie.com/embed/t3qQ4sXmgZA',
                    text: 'Check out a new improved way to move cards between lists and boards!',
                    title: 'New Release: Move Card',
                    type: EUpdateSlideType.VIDEO
                }
            ]
        },
        {
            type: EUpdateType.FIX,
            title: 'Gantt Chart Performance',
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Minor checklist bugs',
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Bug where time data was recorded incorrectly',
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Fixed Bug where time data was converted incorrectly',
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Bug where scroll in Timing section was not working',
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Bug where start and due dates of epic card were calculated incorrectly when adding a subcard',
        }
    ],
    '2017-10-24': [
        {
            type: EUpdateType.FIX,
            title: 'Bug where progress of epic card didn’t change when it’s subcard was removed from the board',
        },
        {
            type: EUpdateType.FIX,
            title: 'Bug where progress bar icon wasn’t visible on cards with colored background',
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'When copying card it’s progress isn’t copied',
            text: 'The progress of epic card is now determined by its checklist AND subcards. Monitor easily how the task goes!',
            slides: [
                {
                    media: 'https://www.youtube-nocookie.com/embed/SQe1n5Vszs8',
                    text: 'The progress of epic card is now determined by its checklist AND subcards. Monitor easily how the task goes!',
                    title: 'New Release: New card progress',
                    type: EUpdateSlideType.VIDEO
                }
            ]
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Help &amp; Support bubble changed',
        }
    ],
    '2017-10-18': [
        {
            type: EUpdateType.ADD,
            title: 'Progress of epic card based on progress of its subcards',
        },
        {
            type: EUpdateType.ADD,
            title: 'A new filtering option “Hide Done Cards”',
        },
        {
            type: EUpdateType.ADD,
            title: 'Team Drive Integration',
            text: 'In this release we made attaching files from Google Drive easier and added much-awaited integration with Team Drives. Comes with any paid subscription :)',
            slides: [
                {
                    media: 'https://www.youtube-nocookie.com/embed/g41OWiwx0H8',
                    text: 'In this release we made attaching files from Google Drive easier and added much-awaited integration with Team Drives. Comes with any paid subscription :)',
                    title: 'Attach files from Team Drives!',
                    type: EUpdateSlideType.VIDEO
                }
            ]
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Automatically ticking the box “Show on Gantt” for added subcards in Gantt Chart.',
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Sorting of pinned attachments as in Attachments section in Card Details',
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Improved UI of Subcards section with “Show on Gantt” checkbox, subcard progress &amp; epic/subcard icon',
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Checklist progress now shows the number of completed items',
        },
        {
            type: EUpdateType.FIX,
            title: 'Bug where clicking the link in card title opened Card Details',
        },
        {
            type: EUpdateType.FIX,
            title: 'Bug where related cards selection wasn’t displayed',
        },
        {
            type: EUpdateType.FIX,
            title: 'Bug where clicking a pinned attachment opened Card Details',
        },
        {
            type: EUpdateType.FIX,
            title: 'Bug where priority icon in activity was distorted',
        },
        {
            type: EUpdateType.FIX,
            title: 'Bug where attachment preview appeared behind comments',
        },
        {
            type: EUpdateType.FIX,
            title: 'Bug where pinned image appeared with margins',
        },
        {
            type: EUpdateType.FIX,
            title: 'Bug where drag-and-drop between boards didn’t work',
        },
        {
            type: EUpdateType.FIX,
            title: 'Bug where adding dates to cards caused timeline scroll in manual sorting mode',
        }
    ],
    '2017-10-4': [
        {
            type: EUpdateType.ADD,
            title: 'Subcard highlighting'
        },
        {
            type: EUpdateType.ADD,
            title: 'Start &amp; Due Date, Estimate icons on cards'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'User Interface: New Card and Board Layout'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Pinned attachments reworked'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Multiple selection indication'
        },
        {
            type: EUpdateType.FIX,
            title: 'Comment height bug'
        },
        {
            type: EUpdateType.FIX,
            title: 'Security issues'
        }
    ],
    '2017-9-21': [
        {
            type: EUpdateType.IMPROVE,
            title: 'Improved Card and List removal',
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Improved Board &amp; Board layout copying',
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Improved Board security',
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'UX for loading of Card Details',
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'UX for a new card created on Gantt Chart',
            text: 'Learn about the most recent changes in Kanbanchi. Multiple-level subcards, improved Gantt Chart and more. Let us know what you think!',
            slides: [
                {
                    media: 'https://www.youtube-nocookie.com/embed/vKJ9q5dLPT4',
                    text: 'Learn about the most recent changes in Kanbanchi. Multiple-level subcards, improved Gantt Chart and more. Let us know what you think!',
                    title: 'The Gantt Chart Has Changed.',
                    type: EUpdateSlideType.VIDEO
                }
            ]
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Saving Gantt Chart sorting mode',
        },
        {
            type: EUpdateType.FIX,
            title: 'Realtime bug on Move to list function',
        },
        {
            type: EUpdateType.FIX,
            title: 'Ton of subcards bugs fixed',
        }
    ],
    '2017-8-11': [
        {
            type: EUpdateType.ADD,
            title: 'New feature - Nested subcards'
        },
        {
            type: EUpdateType.ADD,
            title: 'New feature - Waterfall or manual sorting cards on Gantt Chart'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Sort now feature diminished on Gantt Chart'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Layout of Gantt View improvement'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Design improvement of sub cards and epics'
        },
        {
            type: EUpdateType.FIX,
            title: 'Active subcard highlighting bug'
        },
        {
            type: EUpdateType.FIX,
            title: 'Zoom out bug'
        },
        {
            type: EUpdateType.FIX,
            title: 'Icons on the card bug'
        },
        {
            type: EUpdateType.FIX,
            title: 'Multiple userpics bug'
        },
        {
            type: EUpdateType.FIX,
            title: 'Checkbox Show on Gantt with multiple cards bug'
        }
    ],
    '2017-5-2': [
        {
            type: EUpdateType.ADD,
            title: 'New Feature — Epics &amp; Subcards',
            text: 'Subcards let you specify your tasks to the level of granularity that you need – create subtasks for each of the individual steps in a task & assign different parts of a task to different people. ',
            slides: [
                {
                    media: 'https://www.youtube-nocookie.com/embed/aQY_Or-24Mc',
                    text: 'Subcards let you specify your tasks to the level of granularity that you need – create subtasks for each of the individual steps in a task & assign different parts of a task to different people. <a data-extra="subcards">Try free for 30 days</a>! Learn more in <a href="http://www.kanbanchi.com/faqwd/how-do-i-create-a-subcard" target="_blank">Kanbanchi FAQ</a>',
                    title: 'Meet Subcards!',
                    type: EUpdateSlideType.VIDEO
                }
            ]
        },
        {
            type: EUpdateType.FIX,
            title: 'In-app notifications don’t overlap the right panel anymore',
        },
        {
            type: EUpdateType.FIX,
            title: 'Right panel bug fixed',
        },
        {
            type: EUpdateType.FIX,
            title: 'Tons of minor bugs fixed',
        }
    ],
    '2017-2-3': [
        {
            type: EUpdateType.ADD,
            title: 'New Feature — In-app notifications',
            text: 'Get real-time updates about activity on Kanbanchi dashboards right within the app. Is it something useful for you, guys? It’s YOU who help us decide what we shall concentrate our development effort on.  Let’s talk? :)',
            slides: [
                {
                    media: 'https://www.youtube-nocookie.com/embed/xeY_qyps0oM',
                    text: 'Get real-time updates about activity on Kanbanchi dashboards right within the app. Is it something useful for you, guys? It’s YOU who help us decide what we shall concentrate our development effort on.  Let’s talk? :)',
                    title: 'In-app notifications are now here!',
                    type: EUpdateSlideType.VIDEO
                }
            ]
        }
    ],
    '2016-11-22': [
        {
            type: EUpdateType.ADD,
            title: 'New Feature – List View',
            text: 'Our new List View feature allows you to see all your cards in a scrollable list, quickly look through them from top to bottom and filter the ones you need.',
            slides: [
                {
                    media: 'https://www.youtube-nocookie.com/embed/tjg6TYKHucI',
                    text: 'Our new List View feature allows you to see all your cards in a scrollable list, quickly look through them from top to bottom and filter the ones you need.',
                    title: 'Your cards in the new light!',
                    type: EUpdateSlideType.VIDEO
                }
            ]
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Performance improvement',
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Gantt Chart centering',
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Ability to change card position in Gantt Chart',
        },
        {
            type: EUpdateType.FIX,
            title: 'Overlay bug in checklist and attachments sections',
        },
        {
            type: EUpdateType.FIX,
            title: 'Timetracker markdown and other minor bugs',
        },
        {
            type: EUpdateType.FIX,
            title: 'Colour tag and Due date simultaneous display bug',
        },
        {
            type: EUpdateType.FIX,
            title: 'Colour tag update bug',
        },
        {
            type: EUpdateType.FIX,
            title: 'Long card title display bug in Gantt Chart',
        }
    ],
    '2016-10-18': [
        {
            type: EUpdateType.ADD,
            title: 'New Feature — Board Backups',
            text: 'Here comes a new release! With our new Board Backups feature you can create backups of your most important Kanbanchi dashboards to restore them easily with a single click. Give it a try and let us know what you think :)',
            slides: [
                {
                    media: 'https://www.youtube-nocookie.com/embed/8u-1CzS3sZY',
                    text: 'Here comes a new release! With our new Board Backups feature you can create backups of your most important Kanbanchi dashboards to restore them easily with a single click. Give it a try and let us know what you think :)',
                    title: 'Meet Board Backups!',
                    type: EUpdateSlideType.VIDEO
                }
            ]
        }
    ],
    '2016-10-12': [
        {
            type: EUpdateType.ADD,
            title: 'New Feature - Import from Trello',
            text: 'You’ve been waiting for this update! Now you can import your Trello Boards to Kanbanchi!',
            slides: [
                {
                    media: 'https://www.youtube-nocookie.com/embed/s0EMGY-reuo',
                    text: 'You’ve been waiting for this update! Now you can import your Trello Boards to Kanbanchi!',
                    title: 'Migrate from Trello to Kanbanchi easily!',
                    type: EUpdateSlideType.VIDEO
                }
            ]
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Unique Logo per Board',
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Performance improvement',
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Loading optimisation',
        },
        {
            type: EUpdateType.FIX,
            title: 'Bug of Estimate setting',
        }
    ],
    '2016-8-11': [
        {
            type: EUpdateType.IMPROVE,
            title: 'Pinning multiple attachments to a card',
            text: 'Four important updates which will change the way you work with attachments in Kanbanchi. Watch now to see what’s new in your favorite app :)',
            slides: [
                {
                    media: 'https://www.youtube-nocookie.com/embed/eypCNYz-xZ8',
                    text: 'Four important updates which will change the way you work with attachments in Kanbanchi. Watch now to see what’s new in your favorite app :)',
                    title: 'Meet improved attachments!',
                    type: EUpdateSlideType.VIDEO
                }
            ]
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Attachment creator and the date of adding',
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Attachment thumbnails',
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Auto-sharing of uploaded files with board collaborators',
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Usability of Kanbanchi in mobile browsers(iPad)',
        },
        {
            type: EUpdateType.FIX,
            title: 'Time Tracker bug',
        }
    ],
    '2016-7-26': [
        {
            type: EUpdateType.IMPROVE,
            title: 'Related card icon added'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Usability of Related card feature'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Filter (hide empty list option and priority added)'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Markdown rendering'
        },
        {
            type: EUpdateType.FIX,
            title: 'Bug of Paid Subscription activation'
        },
        {
            type: EUpdateType.FIX,
            title: 'Bug of Card title movement on card details panel'
        }
    ],
    '2016-7-19': [
        {
            type: EUpdateType.IMPROVE,
            title: 'Filter improvement -  Priority’s been added'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Export to Google Spreadsheet - Priority and color tags have been added'
        }
    ],
    '2016-7-18': [
        {
            type: EUpdateType.ADD,
            title: 'Extra features available with any paid subscription',
            text: 'Make your dashboards bright and colourful - backgrounds are now free! Also in this release: Card from e-mail and export to Google sheets available with any paid subscription.',
            slides: [
                {
                    media: 'https://www.youtube-nocookie.com/embed/i2ZnZ8CaCPA',
                    text: 'Make your dashboards bright and colourful - backgrounds are now free! Also in this release: Card from e-mail and export to Google sheets available with any paid subscription.',
                    title: 'Another nice release',
                    type: EUpdateSlideType.VIDEO
                }
            ]
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'The cheapest subscription is only $5 per month',
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Free backgrounds',
        }
    ],
    '2016-7-14': [
        {
            type: EUpdateType.ADD,
            title: 'New Feature - Priority for cards',
        },
        {
            type: EUpdateType.ADD,
            title: 'New Feature - Sorting cards by priority',
            text: 'Simplify your work and make it even more productive using new Sorting cards by priority feature.',
            slides: [
                {
                    media: 'https://www.youtube-nocookie.com/embed/ThwtrdTdozw',
                    text: 'Simplify your work and make it even more productive using new Sorting cards by priority feature.',
                    title: 'Sorting cards by priority',
                    type: EUpdateSlideType.VIDEO
                }
            ]
        }
    ],
    '2016-7-11': [
        {
            type: EUpdateType.IMPROVE,
            title: 'Card from email improvements (tags and attachments)',
            slides: [
                {
                    media: 'https://www.youtube-nocookie.com/embed/MBU9FY24DYk',
                    text: 'Kanbanchi Card from email improvements (tags and attachments)',
                    title: 'Card from email improvements',
                    type: EUpdateSlideType.VIDEO
                }
            ]
        }
    ],
    '2016-6-22': [
        {
            type: EUpdateType.ADD,
            title: 'New Feature - Card from email',
            text: 'You asked for it! Now you can create cards on your dashboards just by sending an email to your board email address',
            slides: [
                {
                    media: 'https://www.youtube-nocookie.com/embed/gb25LQdW0dA',
                    text: 'You asked for it! Now you can create cards on your dashboards just by sending an email to your board email address',
                    title: 'Cards from Email',
                    type: EUpdateSlideType.VIDEO
                }
            ]
        }
    ],
    '2016-6-21': [
        {
            type: EUpdateType.ADD,
            title: 'New Feature - Export to Google Sheets',
            slides: [
                {
                    media: 'https://www.youtube-nocookie.com/embed/mXD7yXkCLvI',
                    text: 'You can export to Google Sheets now!',
                    title: 'Export to Google Sheets',
                    type: EUpdateSlideType.VIDEO
                }
            ]
        }
    ],
    '2016-6-16': [
        {
            type: EUpdateType.FIX,
            title: 'Attach files'
        }
    ],
    '2016-5-31': [
        {
            type: EUpdateType.ADD,
            title: 'Ability to copy board layout'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Multi-step process for permission requests'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'New approach to release announcements'
        },
        {
            type: EUpdateType.FIX,
            title: 'Time Tracker bug'
        },
        {
            type: EUpdateType.FIX,
            title: 'Gantt zoom bug'
        }
    ],
    '2016-4-21': [
        {
            type: EUpdateType.ADD,
            title: 'New feature — Related cards'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Empty list UI'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Alex is no longer looking at you from the bottom right corner :)'
        },
        {
            type: EUpdateType.FIX,
            title: 'Print card bug'
        },
        {
            type: EUpdateType.FIX,
            title: 'Multiple cards selection bug'
        }
    ],
    '2016-3-9': [
        {
            type: EUpdateType.ADD,
            title: 'New feature — Timetracker '
        },
        {
            type: EUpdateType.ADD,
            title: 'New feature — Gantt chart '
        },
        {
            type: EUpdateType.ADD,
            title: 'New backgrounds for branding'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Push to calendar (New UI and ability to push to multiple calendars)'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'App’s performance'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Payment method via Paypal subscription'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Multiple cards selection'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'New card creation field'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Help and Support Panel'
        },
        {
            type: EUpdateType.FIX,
            title: 'Issue with multiple Google accounts'
        },
        {
            type: EUpdateType.FIX,
            title: 'Minor bugs related to UI'
        },
        {
            type: EUpdateType.FIX,
            title: 'Issue with start date being set after due date'
        },
        {
            type: EUpdateType.FIX,
            title: 'Issue with drag &amp; drop for empty lists'
        },
        {
            type: EUpdateType.FIX,
            title: 'Issue with renaming card with done status'
        }
    ],
    '2015-7-6': [
        {
            type: EUpdateType.ADD,
            title: 'Set up notification on due date (on this date)'
        },
        {
            type: EUpdateType.ADD,
            title: '“Watch” card'
        },
        {
            type: EUpdateType.ADD,
            title: 'Ability to unsubscribe from email notifications'
        },
        {
            type: EUpdateType.ADD,
            title: 'Author’s name and date of card creation now displayed on a card'
        },
        {
            type: EUpdateType.ADD,
            title: 'Ability to print cards and lists'
        },
        {
            type: EUpdateType.ADD,
            title: 'Ability to download attached files from cards'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Keyword filter to search by name &amp; description'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Separate icon for removing a card'
        }
    ],
    '2015-6-4': [
        {
            type: EUpdateType.ADD,
            title: 'Capability to add time to due date'
        },
        {
            type: EUpdateType.ADD,
            title: 'Sorting by due date (in filters)'
        },
        {
            type: EUpdateType.ADD,
            title: 'Capability to remove text from colour labels (save empty labels) '
        },
        {
            type: EUpdateType.ADD,
            title: 'Integration with Google Calendar'
        },
        {
            type: EUpdateType.ADD,
            title: 'color fill for cards'
        },
        {
            type: EUpdateType.ADD,
            title: 'New card fields: estimates, start date, &amp; tags'
        },
        {
            type: EUpdateType.ADD,
            title: 'Auto-suggest field was added to “Assign to” button on boards with more than 10 users'
        },
        {
            type: EUpdateType.ADD,
            title: 'Online collaborators displayed like in Google Docs'
        }
    ],
    '2015-4-20': [
        {
            type: EUpdateType.ADD,
            title: 'New design'
        },
        {
            type: EUpdateType.ADD,
            title: 'PRO version'
        },
        {
            type: EUpdateType.ADD,
            title: 'Upload logo &amp; background (PRO)'
        },
        {
            type: EUpdateType.ADD,
            title: 'Copy card'
        },
        {
            type: EUpdateType.ADD,
            title: 'Copy list'
        },
        {
            type: EUpdateType.ADD,
            title: 'List description'
        },
        {
            type: EUpdateType.ADD,
            title: 'Bulk actions for all cards in a list (quick launch of multi-selection)'
        },
        {
            type: EUpdateType.ADD,
            title: 'Sort users by name alphabetically in “Assing to” list'
        },
        {
            type: EUpdateType.ADD,
            title: '“Done” status'
        }
    ],
    '2015-1-29': [
        {
            type: EUpdateType.ADD,
            title: 'Enhanced checklists'
        },
        {
            type: EUpdateType.ADD,
            title: 'Adjustable right panel'
        },
        {
            type: EUpdateType.ADD,
            title: 'Card highlighting for items currently being modified by smb'
        },
        {
            type: EUpdateType.ADD,
            title: 'Sort assigned people/attachments by drag’n’drop'
        },
        {
            type: EUpdateType.ADD,
            title: 'Mandatory use of HTTPS'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Enhanced keyword filtering'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Multi-card form performance enhancements'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Increased stability for restoring card and list function'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Minor improvements of activity and notifications'
        },
        {
            type: EUpdateType.FIX,
            title: 'Repaired issue of tab order changing after page reload'
        },
        {
            type: EUpdateType.FIX,
            title: 'Removed compatibility issue to allow clients with no support for advanced HTTP methods to use Kanbanchi'
        },
        {
            type: EUpdateType.FIX,
            title: 'Repaired issue regarding automatic movement of newly attached drive documents to the board’s folder in Google Drive'
        }
    ],
    '2014-11-26': [
        {
            type: EUpdateType.ADD,
            title: 'Card filters'
        },
        {
            type: EUpdateType.ADD,
            title: 'Notifications'
        },
        {
            type: EUpdateType.ADD,
            title: 'CSV export'
        },
        {
            type: EUpdateType.ADD,
            title: 'New icon on cards showing that additional description for a card is available'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Similar activities are combined together making activity stream more readable'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Improved board opening dialog'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Labels for colour tags are now edited in place (board properties)'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Improvements to settings menu and options'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Improved interface performance'
        },
        {
            type: EUpdateType.FIX,
            title: 'Fixed a bug in Members &amp; Permissions related to displaying people who “Can Comment” as “Can View”'
        }
    ],
    '2014-9-6': [
        {
            type: EUpdateType.ADD,
            title: 'Checklist'
        },
        {
            type: EUpdateType.FIX,
            title: 'Major bugs related to authentication via Google'
        }
    ],
    '2014-8-19': [
        {
            type: EUpdateType.ADD,
            title: 'Graphical attachments now have previews'
        },
        {
            type: EUpdateType.ADD,
            title: 'Main image / document can be pinned to a card'
        },
        {
            type: EUpdateType.ADD,
            title: 'New Activity tabs added to card and board right panels'
        },
        {
            type: EUpdateType.ADD,
            title: 'Card saving animation'
        },
        {
            type: EUpdateType.ADD,
            title: 'Sign out'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Improved drag’n’drop performance in a board with collapsed lists'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Browser tab name is now changing on board name change'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Colour tags management made more user-friendly'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Removed limit on the number of characters when creating a card'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Markdown link formatting enabled in the card title ([This link](http://example.net/))'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Autosuggest filter added open board dialogue'
        },
        {
            type: EUpdateType.FIX,
            title: 'Board members automatically updated after changing them in Google Drive dialogue'
        },
        {
            type: EUpdateType.FIX,
            title: 'Drag’n’drop to the end of the list improved'
        },
        {
            type: EUpdateType.FIX,
            title: 'Number of attachments and comments update in real time'
        },
        {
            type: EUpdateType.FIX,
            title: 'Several bugs fixed in board copying function'
        },
        {
            type: EUpdateType.FIX,
            title: 'Existing Google Drive documents attached to a board are not moved to board’s folder any more'
        },
        {
            type: EUpdateType.FIX,
            title: 'Several issues in IE10 fixed'
        },
        {
            type: EUpdateType.FIX,
            title: 'Fixed drag’n’drop issue which appears when a board has collapsed lists (card appeared at the end of a collapsed list)'
        },
        {
            type: EUpdateType.FIX,
            title: 'Sorting tabs fixed'
        }
    ],
    '2014-5-14': [
        {
            type: EUpdateType.ADD,
            title: 'Number of comments and attachments and due date displayed on the card.'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Cards and lists redesigned'
        },
        {
            type: EUpdateType.ADD,
            title: 'Board activity'
        }
    ],
    '2014-4-22': [
        {
            type: EUpdateType.ADD,
            title: 'Boards are now updated in real time via Google Realtime API'
        },
        {
            type: EUpdateType.ADD,
            title: 'Cards multi-selection and bulk actions are added'
        },
        {
            type: EUpdateType.ADD,
            title: 'Users who are currently working with the board are highlighted in Board Details'
        },
        {
            type: EUpdateType.IMPROVE,
            title: '“Undo Delete” popup automatically disappears after 10 seconds'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Ctrl+Enter hotkey added to add a card to the top of the list'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Google Drive “attachments” are now uploaded to the same folder as the board'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Card titles can be as long as needed'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Drag’n’drop to a card layout improved'
        },
        {
            type: EUpdateType.FIX,
            title: 'Horizontal scrolling is now working even when the mouse is positioned on a list'
        },
        {
            type: EUpdateType.FIX,
            title: 'Fixed bug caused inability to sort board tabs in certain cases'
        }
    ],
    '2014-2-19': [
        {
            type: EUpdateType.ADD,
            title: 'Files can now be drag’n’droped to right card panel to be automatically uploaded to Google Drive'
        },
        {
            type: EUpdateType.ADD,
            title: 'Added a facility to undo latest card and list deletion'
        },
        {
            type: EUpdateType.ADD,
            title: 'Card due date added'
        },
        {
            type: EUpdateType.ADD,
            title: 'Board is now scrolling horizontally by a mouse wheel on Windows and Linux platforms'
        },
        {
            type: EUpdateType.ADD,
            title: 'Links entered into card titles are now clickable on the board'
        },
        {
            type: EUpdateType.ADD,
            title: 'Active cards can now be deleted by pressing Delete key on the keyboard'
        },
        {
            type: EUpdateType.ADD,
            title: 'Edited comments now have an appropriate mark'
        },
        {
            type: EUpdateType.ADD,
            title: 'Added a facility to restore a board by clicking on the record in Google Drive Trash'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Board “editors” can now share it from Kanbanchi interface'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Allowed card title and comment length increased'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Comments are now sorted by date, and dates are displayed in a user-friendly manner'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Social sharing forms are now displayed in small popup windows'
        },
        {
            type: EUpdateType.FIX,
            title: 'Empty value in a list Max Card Number is now saved as “any number of cards”'
        },
        {
            type: EUpdateType.FIX,
            title: 'Fixed the problem related to displaying stale card details after quick closing and re-opening the card'
        }
    ],
    '2014-1-13': [
        {
            type: EUpdateType.ADD,
            title: 'Added a facility to drag cards between boards via tabs'
        },
        {
            type: EUpdateType.ADD,
            title: 'Card and board descriptions and comments are now formatted with markdown'
        },
        {
            type: EUpdateType.ADD,
            title: 'Activated Google Drive’s “can comment” privilege; anonymous users can comment on cards subject to board access privileges'
        },
        {
            type: EUpdateType.ADD,
            title: 'A link to Kanbanchi can now be easily shared by clicking Settings -&gt; Share'
        },
        {
            type: EUpdateType.IMPROVE,
            title: '“Add a card” field doesn’t disappear on mouseleave when focused'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Improved the layout of the card and board details panel for users with read-only access permissions'
        },
        {
            type: EUpdateType.FIX,
            title: 'Card and board description layout fixed in a case of empty text'
        }
    ],
    '2013-12-20': [
        {
            type: EUpdateType.ADD,
            title: 'Added Settings popup'
        },
        {
            type: EUpdateType.ADD,
            title: 'Added a facility to comment on cards'
        },
        {
            type: EUpdateType.ADD,
            title: 'Added a facility to delete a list with cards'
        },
        {
            type: EUpdateType.ADD,
            title: 'SSL activated'
        },
        {
            type: EUpdateType.FIX,
            title: 'Fixed the bug which sometimes made new cards appear in the middle of a list'
        },
        {
            type: EUpdateType.FIX,
            title: 'Lists mouseover decoration restored'
        }
    ],
    '2013-12-9': [
        {
            type: EUpdateType.ADD,
            title: 'Added a facility to attach Google Docs'
        },
        {
            type: EUpdateType.IMPROVE,
            title: '“Remove card” link moved out from the drop-down menu for quicker access'
        },
        {
            type: EUpdateType.IMPROVE,
            title: '“Add a card” field hidden by default, and it only appears on mouseover on a list'
        },
        {
            type: EUpdateType.IMPROVE,
            title: 'Improved the layout of the board in “Read-only” mode'
        },
        {
            type: EUpdateType.FIX,
            title: 'Added internal scroll in open board dialogue (needed for a case when a user has many boards)'
        }
    ]
};

export const getUpdates = () => UPDATES;

export const getSlidesForUpdatesTips = (
    date: string,
    id: string,
) => {
    const items = getUpdates()[date];
    const item = items && items.find(item => item.id === id);
    return item && item.slides;
};
