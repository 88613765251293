import { IFolderInfo } from 'app/types/rest/IRestDashboard';
import { IApplicationState, TBoardId } from '../../../types/types';
import { getBoard } from './getBoardById';

export const getBoardParentFolderInfo = (
    state: IApplicationState,
    boardId: TBoardId,
): IFolderInfo => {
    const board = getBoard(state, boardId);

    return board && board.parentFolderInfo;
};
