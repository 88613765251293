import { INavigationPanelAssignedToMeProps } from './types';
import * as React from 'react';
import './_NavigationPanelAssignedToMe.scss';
import { Button, ButtonTitle, Icon, Tooltip } from 'kui';
import {
    NAVIGATION_PANE_NEW_CARDS,
    NAVIGATION_PANE_NEW_CARDS_HINT,
    NAVIGATION_PANEL_ASSIGNED_TO_ME_TITLE
} from './constants';
import { EHintKey, HintHOC } from '../../../../hints';
import { GOOGLE_SPACING } from '../../../../../../const';
import { NewFeatureIndicator } from '../../../../base/components/NewFeatureIndicator/NewFeatureIndicator';

export const NavigationPanelAssignedToMe = ({
    isActive,
    newCardsCount,
    onClick
}: INavigationPanelAssignedToMeProps) => {
    const className = 'navigation-panel__assigned-to_me'
    const classActive = isActive ? className + '--active' : '';
    const classCounter = className + '-counter';
    const showNewCardsCount = !isActive && !!newCardsCount;
    return <div className={`
            navigation-panel__row
            ${className}
            ${classActive}
        `}>
        <Button
            onClick={onClick}
            variant={'icon-text'}
        >
            <Icon size={24} xlink={'card-assigned'} />
            <ButtonTitle>{NAVIGATION_PANEL_ASSIGNED_TO_ME_TITLE}</ButtonTitle>
        </Button>
        {showNewCardsCount &&
            <Tooltip
                className={'navigation-panel__tooltip'}
                value={NAVIGATION_PANE_NEW_CARDS}
                direction={'right'}
            >
                <div
                    className={`${classCounter} ${newCardsCount > 99 ? classCounter + '--big' : ''}`}
                    onClick={onClick}
                >
                    {newCardsCount > 99 ? '99+' : newCardsCount}
                </div>
            </Tooltip>
        }
        {showNewCardsCount &&
            <HintHOC
                arrow={'up'}
                direction={'down-right'}
                arrowTranslate={{ left: GOOGLE_SPACING * 2 }}
                translate={{ top: -GOOGLE_SPACING }}
                show={true}
                value={NAVIGATION_PANE_NEW_CARDS_HINT}
                hintKey={EHintKey.NEW_CARD_COUNT}
                selector={`.${className}`}
            />
        }
    </div>
}
