import { segmentTrackAction } from '../../../../../middlewares/segment';
import { ThunkAction } from '../../../../../types/actions';
import { ISegmentEventOption, TSegmentTrackPayload } from '../../../../../middlewares/segment/trackEntities/types';
import { MY_WORK_FILTER_BOARD_ID } from '../constants';
import {
    SegmentFilterOption,
    SegmentFilterOptionPageValue
} from '../../../../../middlewares/segment/trackEntities/filterEvents';

export const filterSegmentTrackAction = (
    event: TSegmentTrackPayload,
    boardId: number,
    option?: ISegmentEventOption,
    additionalOptions: ISegmentEventOption[] = []
): ThunkAction => {
    return (
        dispatch
    ) => {
        if (boardId === MY_WORK_FILTER_BOARD_ID) {
            additionalOptions.push({
                name: SegmentFilterOption.PAGE,
                value: SegmentFilterOptionPageValue.MY_TASKS
            })
        }
        dispatch(segmentTrackAction(event, option, additionalOptions));
    }
}
