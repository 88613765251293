export interface IDependency {
    id?: TDependencyId;
    successorId?: number;
    type?: EDependencyTypes;
    predecessorId?: number;
};

export enum EDependencyTypes {
    START_TO_START = 'STS',
    START_TO_FINISH = 'STF',
    FINISH_TO_START = 'FTS',
    FINISH_TO_FINISH = 'FTF',
};

export type TDependencyId = number;
