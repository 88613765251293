import { IGetState, TCardId, TChecklistId } from '../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { fetchHandler } from '../../../../../util/fetchHandler';
import Util from '../../../../../util/util';
import { REST_CARD, REST_CHECKLISTS } from '../../../../constants';
import { getRestHeadersDelete } from '../../../../helpers/getRestHeadersHelper';
import { IRestChecklist } from '../../../../../types/rest/IRestChecklist';

export const restDelete = (
    cardId: TCardId,
    checklistId: TChecklistId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return fetchHandler<IRestChecklist>(
            Util.getApiUrl(REST_CARD + cardId + REST_CHECKLISTS + checklistId), {
                ...getRestHeadersDelete(),
            }
        )
    };
    return action;
};
