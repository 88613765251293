import {IApplicationState} from '../../../../../../../../types/types';
import {onClick} from '../../../Tags/hocs/TagsPanelButtonHOC/events/onClick';
import {connect} from 'react-redux';
import {IBoardImportPanelButtonHOCProps} from './types';
import {
    IBoardImportPanelButtonEvents,
    IBoardImportPanelButtonFields
} from 'app/view/react_components/aside_panel/boardDetails/details/ImportBoard/components/BoardImportPanelButton/types';
import {BoardImportPanelButton} from 'app/view/react_components/aside_panel/boardDetails/details/ImportBoard/components/BoardImportPanelButton/PwcPanelButton';

const mapStateToProps = (
    state: IApplicationState
): IBoardImportPanelButtonFields => {
    return {
        tooltip: null,
        show: true
    }
};

const mapDispatchToProps = (
    dispatch: any,
    {onClick: ownOnClick}: IBoardImportPanelButtonHOCProps
): IBoardImportPanelButtonEvents => {
    return {
        onClick: () => dispatch(onClick(ownOnClick))
    }
};

export const BoardImportPanelButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(BoardImportPanelButton);
