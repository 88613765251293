import { REST_CARD } from '../../../../constants';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../types/types';
import { fetchHandler } from '../../../../../util/fetchHandler';
import { IRestCard } from '../../../../../types/rest/IRestCard';
import Util from '../../../../../util/util';
import { ICard } from '../../../../../store/model/card/types';
import { getRestHeadersPatch } from '../../../../helpers/getRestHeadersHelper';

export const patchRest = (
    cardId: TCardId,
    body: ICard,
    generateCardNumber: boolean = false,
    disableHistory: boolean = false
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return fetchHandler<IRestCard>(
            Util.getApiUrl(REST_CARD + cardId + '?generateCardNumber=' + generateCardNumber + '&disableHistory=' + disableHistory), {
                ...getRestHeadersPatch(),
                body: JSON.stringify(body)
            }
        );
    };
    return action;
};
