import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { ICardSubcardsPanelButtonHOCProps } from './types';
import {
    ICardSubcardsPanelButtonEvents,
    ICardSubcardsPanelButtonFields
} from '../../components/CardSubcardsPanelButton/types';
import { CardSubcardsPanelButton } from '../../components/CardSubcardsPanelButton/CardSubcardsPanelButton';
import { getCardSubcardsCount } from '../../../../../../../store/model/selectors/getCardSubcardsCount';
import { CARD_SECTION_SUBCARDS } from '../../components/CardSubcardsSection/constants';
import { onClick } from './events/onClick';
import { getSectionType } from '../../../selectors/getSectionType';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { getCard } from '../../../../../../../store/model/selectors/getCard';
import { getIsUserBasicPlanOnly } from '../../../../../../../store/model/authUser/selectors/getIsUserBasicPlanOnly';

const mapStateToProps = (
    state: IApplicationState,
    { boardId, cardId, isShowTooltip }: ICardSubcardsPanelButtonHOCProps
): ICardSubcardsPanelButtonFields => {
    const sectionType = getSectionType(state, boardId, cardId, null, 'allowSubCard');
    const isDisallow = sectionType === ESettingsSectionType.DISALLOW;
    const card = getCard(state, cardId);
    const isShow = (
        (
            sectionType === ESettingsSectionType.DEFAULT ||
            isDisallow
        )
        && !card.isMilestone
    );
    const subcardsCount = getCardSubcardsCount(state, cardId);
    const tooltip = isShowTooltip
        ? CARD_SECTION_SUBCARDS
        : null;
    return {
        isBasicFeature: getIsUserBasicPlanOnly(state),
        isDisallow,
        isShow,
        subcardsCount,
        tooltip,
    }
};

const mapDispatchToProps = (
    dispatch: any,
    {boardId, cardId, onClick: ownOnClick}: ICardSubcardsPanelButtonHOCProps
): ICardSubcardsPanelButtonEvents => {
    return {
        onClick: () => dispatch(onClick(boardId, cardId, ownOnClick))
    };
};

export const CardSubcardsPanelButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardSubcardsPanelButton);

CardSubcardsPanelButtonHOC.displayName = 'CardSubcardsPanelButtonHOC';
