import * as React from 'react';
import { CLASS_CARD_DETAILS } from '../../../constants';
import { CARD_CHECKLIST_COPY_BUTTON_CANCEL, CARD_CHECKLIST_COPY_BUTTON_OK } from './constants';
import './_CardChecklistCopy.scss';
import { ICardChecklistCopyProps } from './types';
import {
    CardChecklistCopySearchSelectHOC
} from '../../hocs/CardChecklistCopySearchSelectHOC/CardChecklistCopySearchSelectHOC';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { CardChecklistCopyChecklistSelect } from '../CardChecklistCopyChecklistSelect/CardChecklistCopyChecklistSelect';
import { Button, LoaderBlock } from 'kui';
import { AnalyzeRender } from '../../../../../helpers/memoizeHelper';

//@ts-ignore
const analyzeRender = window.Settings.development ? new AnalyzeRender(`CardChecklistCopy`) : null;

export function CardChecklistCopy({
    isMultipleCards,
    checklistOptions,
    selectedCardId,
    selectedChecklistId,
    onLoadChecklist,
    onSelectCard,
    onSelectChecklists,
    onCancel,
    onClose,
    onCopy,
    onDidMount,
}: ICardChecklistCopyProps) {
    const { boardId, cardId } = React.useContext(AsidePanelContext);

    if (analyzeRender) analyzeRender.call(`CardChecklistCopy ${cardId}`);

    const className = CLASS_CARD_DETAILS + '__checklist-copy';
    const classNameMultiple = isMultipleCards ? className + '--multiple' : '';
    const classNameButtons = CLASS_CARD_DETAILS + '__checklist-copy-buttons';
    const classNameButtonEsc = CLASS_CARD_DETAILS + '__checklist-copy-button-esc';
    const classNameButtonOk = CLASS_CARD_DETAILS + '__checklist-copy-button-ok';

    const [isLoading, setLoading] = React.useState(true);
    const [isLoaded, setLoaded] = React.useState(null);

    const onCopyClick = () => {
        const selectedChecklistIds = String(selectedChecklistId).split(',')
        if (!selectedChecklistIds || selectedChecklistIds[0] === '') return;
        selectedChecklistIds.forEach(id => {
            setLoading(true);
            onCopy(selectedCardId, Number(id)).finally(() => {
                setLoading(false);
                if (onClose) onClose();
            });
        })
    };

    React.useEffect(() => {
        if (!checklistOptions[0]) return;
        onSelectChecklists([Number(checklistOptions[0].value)]);
    }, [selectedCardId, isLoading]);

    React.useEffect(() => {
        onDidMount().finally(() => {
            setLoading(false);
            setLoaded(true);
        });
    }, []);

    React.useEffect(() => {// KNB-3617 прячем лоудер если выбрали карту
        if (isLoaded) { // уже все карты загрузились
            return;
        } else if (checklistOptions.length > 1) { // уже загрузилась нужная нам карта вместе со всеми
            setLoading(false);
        } else if (selectedCardId) {
            onLoadChecklist(selectedCardId).finally(() => setLoading(false));
        }
    }, [selectedCardId]);

    return (
        <div className={`${className} ${classNameMultiple}`}>
            <CardChecklistCopySearchSelectHOC
                boardId={boardId}
                cardId={cardId}
                isAutoFocus={isMultipleCards}
                isClearable={false}
                selectedCardId={selectedCardId}
                onSelect={onSelectCard}
            />
            {checklistOptions.length > 1 &&
                <CardChecklistCopyChecklistSelect
                    options={checklistOptions}
                    selectedChecklistId={selectedChecklistId}
                    onSelect={onSelectChecklists}
                    selectedCardId={selectedCardId}
                />
            }
            <div className={classNameButtons}>
                {!isMultipleCards &&
                    <Button
                        className={classNameButtonEsc}
                        variant={'icon-text'}
                        onClick={onCancel}
                    >
                        {CARD_CHECKLIST_COPY_BUTTON_CANCEL}
                    </Button>
                }
                <Button
                    className={classNameButtonOk}
                    disabled={!selectedCardId && isLoading}
                    variant={'icon-text'}
                    onClick={onCopyClick}
                >
                    {CARD_CHECKLIST_COPY_BUTTON_OK}
                </Button>
            </div>
            {isLoading && <LoaderBlock />}
        </div>
    );
};
