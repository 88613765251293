import * as React from 'react';
import './_DateDropdown.scss';
import { IDateDropdownProps } from './types';
import { CLASS_DATEPICKER } from '../constants';
import { QuickDates } from '../QuickDates/QuickDates';
import { Calendar } from '../Calendar/Calendar';
import { CARD_CONTROL_CLASS } from '../../../../../main/kanbanView';

export const DateDropdown = ({
    date,
    fromDate,
    isNoFocus,
    maxDate,
    maxFromDate,
    minDate,
    minToDate,
    toDate,
    onArrowPress,
    onBlur,
    onChange,
    onDateType
}: IDateDropdownProps) => {
    const className = CLASS_DATEPICKER + '__date-dropdown';

    return (
        <div className={`${className} ${CARD_CONTROL_CLASS}`}>
            <QuickDates
                maxDate={maxDate}
                minDate={minDate}
                maxFromDate={maxFromDate}
                minToDate={minToDate}
                fromDate={fromDate}
                toDate={toDate}
                onChange={onChange}
            />
            <Calendar
                onBlur={onBlur}
                date={date}
                toDate={toDate}
                maxDate={maxDate}
                minDate={minDate}
                fromDate={fromDate}
                minToDate={minToDate}
                maxFromDate={maxFromDate}
                onChange={onChange}
                isNoFocus={isNoFocus}
                onDateType={onDateType}
                onArrowPress={onArrowPress}
            />
        </div>
    );
}
