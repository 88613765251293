import { IApplicationState, TBoardId } from '../../../../../../../../../types/types';
import { getMyWorkShowBoards } from '../../../../../store/selectors/getMyWorkShowBoards';
import {
    getUserBoardActivityTimes
} from '../../../../../../../../../store/model/boardUserActivityTimes/selectors/getUserBoardActivityTimes';
import { IUserBoardActivityTime } from '../../../../../../../../../store/model/boardUserActivityTimes/types';
import { getMyWorkBoardsFetchCardsOptions } from '../../../../../selectors/getMyWorkBoardsFetchCardsOptions';
import { EMyWorkCardFetchOption, IBoardsFetchCard } from '../../../../../../../../../types/rest/IUserMeta';
import { createDeepEqualSelector } from '../../../../../../../helpers/memoizeHelper';

type TgetBoardSectionBoards = (
    state: IApplicationState
) => IUserBoardActivityTime[];

export const getMyWorkBoardsSelector = (
    activityTimes: IUserBoardActivityTime,
    boardIds: TBoardId[],
    fetchCards: IBoardsFetchCard
) => {
        return Object.values(activityTimes)
            .map(item => {
                return ({
                    ...item,
                    fetchCards: fetchCards[item.id] || EMyWorkCardFetchOption.DAYS_30
                });
            })
            .filter(item => boardIds.includes(item.id))
            .sort((item1, item2) => item2.lastActivityTime - item1.lastActivityTime)
};

export const getMyWorkBoardsCreateSelector = (
): TgetBoardSectionBoards => createDeepEqualSelector([
        getUserBoardActivityTimes,
        getMyWorkShowBoards,
        getMyWorkBoardsFetchCardsOptions
    ],
    getMyWorkBoardsSelector
);
