import { ThunkAction } from '../../../../../../../../types/actions';
import { TCardId } from '../../../../../../../../types/types';
import { TCommentId } from '../../../../../../../../store/model/card/types/IComment';
import { getMessages, root } from '../../../../../../../../store/constants';
import { getBoardIdByCardId } from '../../../../../../../../store/model/selectors/getBoardIdByCardId';
import { snackbarSuccessDefault } from '../../../../../../snackbarsStack/index';

export const onGetLink = (
    cardId: TCardId,
    commentId: TCommentId,
): ThunkAction => {
    return (
        dispatch,
        getState
    ) => {
        const state = getState();
        const boardId = getBoardIdByCardId(state, cardId);
        const link = root.App.router.getAbsoluteCardPageUrl(boardId, cardId, 'cmt-' + commentId);
        navigator.clipboard.writeText(link).then(() => {
            dispatch(snackbarSuccessDefault({
                id: 'SNACKBAR_ID_COMMENT_LINK_COPIED',
                text: getMessages().getText('card_form_comments.link.snackbar.text')
            }));
        });
    }
}
