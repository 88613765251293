import { Dispatch, ThunkAction } from '../../../../../../types/actions';
import { IGetState } from '../../../../../../types/types';
import { IDriveDocs } from '../../../../../../store/model/driveDocs/types';
import { driveDocsActionSetAction } from '../../../../../../store/model/actions/driveDocsActionSetAction';
import { patchRest } from '../patchRest';
import { IDriveDoc } from '../../../../../../store/model/card/types/IDriveDoc';
import { ICards } from '../../../../../../store/model/cards/types';
import { cardsActiveRequestsUpdate } from '../../../../../../store/requestsState/effects/cardsActiveRequestsUpdate';
import { IRestDriveDoc, TDriveDocId } from '../../../../../../types/rest/IRestDriveDoc';
import { getDriveDoc } from '../../../../../../store/model/driveDocs/selectors/getDriveDoc';
import { driveDocUpdate } from '../../../../../../store/model/driveDocs/actions/driveDocUpdate';
import { sendRealTimeStoreActionByCardId } from '../../../../../../view/react_components/base/helpers/realTimeHelperTS';

export const patchRestHelper = (
    driveDocId: TDriveDocId,
    update: IRestDriveDoc,
    isOptimistic: boolean = true,
    isRealtime: boolean = true // send event to socket
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (!update) Promise.reject();
        const state = getState();
        const originalDriveDoc = getDriveDoc(state, driveDocId);

        const cards: ICards = {
            [originalDriveDoc.cardId]: {
                id: originalDriveDoc.cardId
            }
        };
        if (isOptimistic) {
            dispatch(driveDocsActionSetAction(driveDocUpdate(driveDocId, update)));
        }
        dispatch(cardsActiveRequestsUpdate(cards, 1));
        return dispatch(patchRest(originalDriveDoc.cardId, driveDocId, update))
            .then((responseDoc: IDriveDoc) => {
                const driveDocsMapResponse: IDriveDocs = {};
                driveDocsMapResponse[responseDoc.id] = responseDoc;
                if (!isOptimistic) {
                    dispatch(driveDocsActionSetAction(driveDocUpdate(driveDocId, update)));
                }
                if (isRealtime) {
                    dispatch(sendRealTimeStoreActionByCardId(originalDriveDoc.cardId, driveDocsActionSetAction(driveDocUpdate(driveDocId, update))))
                }
                return responseDoc;
            })
            .catch((e: any) => {
                console.error(e);
                if (isOptimistic) {
                    dispatch(driveDocsActionSetAction(driveDocUpdate(driveDocId, originalDriveDoc)));
                }
            }).finally(() => {
                dispatch(cardsActiveRequestsUpdate(cards, -1));
            });
    };
    return action;
};
