import { IGetState, TBoardId, TCardId } from '../../../../../../types/types';
import { ICardBatch } from '../types';
import { Dispatch, ThunkAction } from '../../../../../../types/actions';
import { getCard } from '../../../../../../store/model/selectors/getCard';
import { getBoard } from '../../../../../../store/model/selectors/getBoardById';
import { getList } from '../../../../../../store/model/list/selectors/getList';
import { getAuthUser } from '../../../../../../store/model/authUser/selectors/getAuthUser';
import { cardsUpdate } from '../../../../../../store/model/actionCreators/cardsUpdate';
import { sendRealTimeStoreActionByCardId } from '../../../../../../view/react_components/base/helpers/realTimeHelperTS';
import { authUserSetInWorkCardId } from '../../../../authUser/authUserSetInWorkCardId';

export const processCardMoveToListUpdate = (
    cardId: TCardId,
    boardId: TBoardId,
    cardBatches: { [cardId: number]: ICardBatch },
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const cardFromStore = getCard(state, Number(cardId));
        const board = getBoard(getState(), boardId);
        const inWorkList = board.statusLists && board.statusLists.inWorkId && getList(state, board.statusLists.inWorkId);
        if (inWorkList && cardFromStore.id !== cardBatches[cardId].card.listId && cardBatches[cardId].card.listId !== inWorkList.id) {
            cardBatches[cardId].callbacks.push(() => {
                const user = getAuthUser(state);
                if (user.inWorkCardId === Number(cardId)) {
                    dispatch(cardsUpdate({
                        [cardId]: {
                            inWorkUsers: []
                        }
                    }));
                    dispatch(sendRealTimeStoreActionByCardId(Number(cardId), cardsUpdate({
                        [cardId]: {
                            inWorkUsers: []
                        }
                    })));
                    dispatch(authUserSetInWorkCardId(0));
                }
            });
        }
    };
    return action;
};
