import { IDriveDocs } from '../types';
import { IDriveDocsSetAction } from './types';
import { AT_SET } from './constants';

export const driveDocsSet = (
    driveDocs: IDriveDocs
): IDriveDocsSetAction => ({
    type: AT_SET,
    driveDocs
});
