import { providerInstance } from 'app/helper/authorisation/microsoft/oauth/Provider';
import { CommonAuthController } from 'app/helper/authorisation/common/CommonAuthController';
import { IRestAuthUser } from 'app/types/rest/IRestAuthUser';

/***
 * класс овечает за общение с  microsoft Provider и бекендом
 */
export class AuthController  extends  CommonAuthController{

    static authorizeWithCode(
        scopes: string[],
        onSuccess: (authUser: IRestAuthUser)=> void,
        onError: (authUser: IRestAuthUser) => void,
        onCancel: () => void,
        hint?: string) {
        providerInstance.authorizeWithCode(scopes, hint)
                .catch(err =>{
                    onCancel();
                    return new Promise<IRestAuthUser>(()=>{});
                })
            .then(onSuccess);
    }

    static logout(){
        return providerInstance.logout();
    }

}
