export const TEXT_COLOR_LIGHT = '#ffffff';
export const TEXT_COLOR_DARK = '#263238';

export const getTextColor = (
    color: string
): string => {
    const colorCode = parseInt(color.slice(1), 16);

    const r = (colorCode >> 16) & 255;
    const g = (colorCode >> 8) & 255;
    const b = colorCode & 255;

    const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

    return hsp > 127.5 ? TEXT_COLOR_DARK : TEXT_COLOR_LIGHT;
}

export const isTextColorLight = (
    color: string
): boolean => {
    return getTextColor(color) === TEXT_COLOR_LIGHT;
}
