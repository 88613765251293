import * as React from 'react';
import * as ReactDOM from 'react-dom';

export interface IPortalReceivedProps {
    children?: JSX.Element | JSX.Element[];
    selector: string;
    prepend?: boolean;
    className?: string;
    onMount?: () => void;
}

interface IPortalOwnProps {
    portalDefaultClass?: string;
}

type PortalProps = IPortalReceivedProps & IPortalOwnProps;

export const Portal: React.FC<PortalProps> = ({
    children,
    className,
    portalDefaultClass,
    selector,
    prepend,
    onMount
}) => {
    const classNames = className ? `${portalDefaultClass} ${className}` : portalDefaultClass;
    const [insertSelector, setSelector] = React.useState(null);
    const [el, setElement] = React.useState(document.createElement('div'));

    el.setAttribute('class', classNames);

    React.useEffect(() => {
        const insertDOM = document.querySelector(selector);

        setSelector(insertDOM);
        if (prepend) {
            insertDOM.prepend(el);
        } else {
            insertDOM.appendChild(el);
        }
        if (onMount) onMount();
        return () => {
            const classNameSelector = className  && className.trim().split(' ')[0];
            if(classNameSelector && insertSelector && insertSelector.querySelector(`.${classNameSelector}`)){
                insertSelector.querySelector(`.${classNameSelector}`).remove();
            } else if (insertSelector && insertSelector.querySelector(`.${portalDefaultClass}`)) {
                insertSelector.querySelector(`${selector} > .${portalDefaultClass}`).remove();
            }
        };
    }, [insertSelector]);

    return ReactDOM.createPortal(
        children,
        el,
    );
};

Portal.defaultProps = {
    portalDefaultClass: 'portal'
};
