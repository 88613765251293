import * as React from 'react';
import { IMicrosoftGroupPermissionProps } from './types';
import './_MicrosoftGroupPermission.scss';
import { ModalPage } from 'app/view/react_components/base/components/ModalPage';
import { SharedDrivesScopeBlockerHOC } from '../../../openBoards/hocs/SharedDrivesScopeBlockerHOC/SharedDrivesScopeBlockerHOC';
import { EmptyDummy } from 'app/view/react_components/base/components/EmptyDummy/EmptyDummy';
import { MICROSOFT_GROUP_PERMISSION_TITLE } from './constants';

export function MicrosoftGroupPermission ({
    onBackClick,
    onDidMount,
}: IMicrosoftGroupPermissionProps) {
    const className = 'microsoft-groups-permission';

    return (
        <ModalPage
            onClickBack={onBackClick}
            onDidMount={onDidMount}
            className={className}
        >
            <EmptyDummy
                buttons={<SharedDrivesScopeBlockerHOC />}
                icon={'permissions'}
                title={MICROSOFT_GROUP_PERMISSION_TITLE}
            />
        </ModalPage>
    )
};
