import {IApplicationState, TBoardId} from '../../../../../types/types';
import { EViewTypes } from '../../../../../const';
import { getUserDefaultView } from '../../../../../store/model/authUser/selectors/getUserDefaultView';
import { isKanbanGroup } from '../../selectors/isKanbanGroup';

export const getTypeSwitcherLastKanbanView = (
    state: IApplicationState,
    boardId: TBoardId,
): EViewTypes => {
    let view = state.typeSwitcher[boardId] && state.typeSwitcher[boardId].lastKanbanView;
    if (!view) {
        const userDefaultView = getUserDefaultView(state);
        if (isKanbanGroup(userDefaultView)) {
            view = userDefaultView;
        }
    }
    return view || EViewTypes.KANBAN_VIEW_TYPE;
};
