import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../../../../types/types';
import { cardAddEpic } from '../../../../../../../../rest/effects/card/card/cardAddEpic';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { root } from '../../../../../../../../store/constants';
import { ETutorial } from '../../../../../../../../types/rest/IUserMeta';

export const onAdd = (
    cardId: TCardId,
    subcardId: TCardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.CHOOSE_SUBCARD));
        root.App.controller.showTutorial(ETutorial.SUBCARD_OR_CHECKLIST_TUTORIAL);
        return dispatch(cardAddEpic(subcardId, cardId));
    };
    return action;
};
