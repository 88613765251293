import { IApplicationState, TBoardId } from 'app/types/types';
import { getBoardMeta } from './getBoardMeta';
import { ICardProperty } from 'app/types/rest/IRestBoardMeta';
import {
    EAsidePanelProperty
} from 'app/view/react_components/aside_panel/asidePanel/components/AsidePanel/types';
import { getBoardCardDefaultPropertiesForMigration } from './getBoardCardDefaultPropertiesForMigration';
import {
    getBoardCustomProperties
} from 'app/view/react_components/aside_panel/boardDetails/details/CardCustomProperties/hocs/CardCustomPropertiesSectionHOC/selectors/getBoardCustomProperties';

export const getBoardCardProperties = (
    state: IApplicationState,
    boardId: TBoardId
): ICardProperty[] => {
    const meta = getBoardMeta(state, boardId);
    const cardDefaultPropertiesBeforeMigration = getBoardCardDefaultPropertiesForMigration(state, boardId);
    let defaultProperties: ICardProperty[] = [{
        property: EAsidePanelProperty.CARD_DESCRIPTION,
        isDefault: true
    }];
    if (cardDefaultPropertiesBeforeMigration) {
        cardDefaultPropertiesBeforeMigration.forEach((property) => {
            if (property === EAsidePanelProperty.CARD_CUSTOM) {
                defaultProperties.push({
                    property: EAsidePanelProperty.CARD_CUSTOM,
                    options: getBoardCustomProperties(state, boardId).map((customProperty) => ({
                        option: customProperty.id,
                        name: customProperty.name,
                        isDefault: true
                    }))
                })
            } else if (property === EAsidePanelProperty.CARD_DATES) {
                defaultProperties.push({
                    property: EAsidePanelProperty.CARD_START_DATE,
                    isDefault: true
                });
                defaultProperties.push({
                    property: EAsidePanelProperty.CARD_DUE_DATE,
                    isDefault: true
                });
            } else {
                defaultProperties.push({
                    property,
                    isDefault: true
                });
            }
        });
    } else {
        defaultProperties = [
            {
                property: EAsidePanelProperty.CARD_ASSIGNEES,
                isDefault: true
            },
            {
                property: EAsidePanelProperty.CARD_COMMENTS,
                isDefault: true
            },
            {
                property: EAsidePanelProperty.CARD_START_DATE,
                isDefault: true
            },
            {
                property: EAsidePanelProperty.CARD_DUE_DATE,
                isDefault: true
            }
        ];
    }
    return meta && meta.cardProperties && meta.cardProperties.length && meta.cardProperties || defaultProperties;
};
