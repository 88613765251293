import { createDeepEqualSelector } from './../../../../../../helpers/memoizeHelper';
import { getCardTags } from './../../../../../../../../store/model/selectors/getCardTags';
import { getBoardTags } from './../../../selectors/getBoardTags';
import { IApplicationState, TBoardId, TCardId, TTag } from '../../../../../../../../types/types';
import { ICardTagsSearchSelectOption } from '../types';
import { ICardDetailsTag } from '../../../components/CardTagsElements/types';

type TgetCardTagsSearchSelectOptions = (
    state: IApplicationState,
    boardId: TBoardId,
    cardId: TCardId,
) => ICardTagsSearchSelectOption[];

const getCardTagsSearchSelectOptionsSelector = (
    boardTags: ICardDetailsTag[],
    cardTags: TTag[],
): ICardTagsSearchSelectOption[] => {
    const options = boardTags.map(tag => ({
        active: !!cardTags.includes(tag.name),
        text: tag.name,
        value: tag.name,
    })).sort((tag1, tag2) => {
        const text1 = tag1.text || '';
        const text2 = tag2.text || '';
        return text1.localeCompare(text2);
    });

    return options;
};

export const getCardTagsSearchSelectOptionsCreateSelector = (
): TgetCardTagsSearchSelectOptions => createDeepEqualSelector(
    (state: IApplicationState, boardId: TBoardId) => getBoardTags(state, boardId),
    (state: IApplicationState, boardId: TBoardId, cardId: TCardId) => getCardTags(state, cardId),
    getCardTagsSearchSelectOptionsSelector,
);

export const getCardTagsSearchSelectOptions: TgetCardTagsSearchSelectOptions = getCardTagsSearchSelectOptionsCreateSelector();
