import { ENotificationScope } from '../../../types/rest/IRestNotificationSettings';
import { IGetState, TBoardId } from '../../../types/types';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { notificationSettingsPatchRest } from './api/helpers/notificationSettingsPatchRest';

export const notificationSetScope = (
    boardId: TBoardId,
    scope: ENotificationScope[],
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(notificationSettingsPatchRest(boardId, {scope}));
    };
    return action;
}
