import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../../../../../types/types';
import { cardOpen } from '../../../../../../../base/effects/cardOpen';
import { SegmentCardEvent, SegmentCardOptions, segmentTrackAction } from '../../../../../../../../../middlewares/segment';
import { SegmentCardSourceValue } from '../../../../../../../../../middlewares/segment/trackEntities/cardEvents';

export const onSubcardLink = (
    subcardId: TCardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.CLICKED_SUBCARD_LINK, {
            name: SegmentCardOptions.SOURCE,
            value: SegmentCardSourceValue.CARD
        }));
        dispatch(cardOpen(subcardId));
    };
    return action;
};
