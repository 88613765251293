import { IPromoCodePopupProps } from './types';
import './_PromoCodePopup.scss';
import * as React from 'react';
import {
    CLASS_PROMO_CODE_POPUP, getPromoCodePopupTitle,
    PROMO_CODE_POPUP_LATER,
    PROMO_CODE_POPUP_PURCHASE,
    PROMO_CODE_POPUP_TEXT
} from './constants';
import { Button } from 'kui';

export const PromoCodePopup = ({
    discount,
    onLater,
    onPurchase,
}: IPromoCodePopupProps) => {
    const className = CLASS_PROMO_CODE_POPUP;
    const Background = require('!!@svgr/webpack!./background.svg').default;
    return <div className={className}>
        <Background className={className + '__background'}/>
        <div className={className + '__content'}>
            <div className={className + '__title'}>{getPromoCodePopupTitle(discount)}</div>
            <div className={className + '__text'}>{PROMO_CODE_POPUP_TEXT}</div>
        </div>
        <div className={className + '__actions'}>
            <Button
                variant={'secondary'}
                onClick={onLater}
            >
                {PROMO_CODE_POPUP_LATER}
            </Button>
            <Button
                onClick={onPurchase}
            >
                {PROMO_CODE_POPUP_PURCHASE}
            </Button>
        </div>
    </div>;
}
