import { IApplicationState } from '../../../types/types';
import { IBoards } from '../boards/types';
import { getIsLinkCardActive } from '../../router/selectors/getIsLinkCardActive';

export const getFullLoadedBoards = (
    state: IApplicationState,
): IBoards => {
    const result: IBoards = {};
    for (let id in state.model.dashboards) {
        const board = state.model.dashboards[id];
        const isLinkCard = getIsLinkCardActive(state);
        if (!board.noCards && (isLinkCard || !board.forDashboard)) {
            result[id] = board;
        }
    }
    return result;
}
