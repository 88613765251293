import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TBoardId, TCardId } from '../../../../../../../../types/types';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { cleverOpenCard } from '../../../../../../base/effects/cleverOpenCard';

export const onDependencyCardLink = (
    dependencyCardId: TCardId,
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.CLICKED_SUBCARD_LINK));
        dispatch(cleverOpenCard(dependencyCardId, boardId));
    };
    return action;
};
