import * as React from 'react';
import { IRestCardTagActivity } from '../../../../../../../types/rest/activity/IRestCardTagActivity';
import { IActivityProps } from '../../../../boardDetails/activity/TabActivity/types';
import { TabActivityContext } from '../../../../boardDetails/activity/TabActivity/components/TabActivity/constants';
import { TActivity } from '../../../../../../../store/activities/types';
import {
    ActivityItemBody
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemBody/ActivityItemBody';
import { ActivityHeader } from '../../../../boardDetails/activity/TabActivity/components/ActivityHeader/ActivityHeader';
import { ActivityTags } from '../ActivityTags/ActivityTags';

export function CardActivityTags({
    activity,
    asidePanelWidth
}: IActivityProps<IRestCardTagActivity>) {
    const { onOpenCard } = React.useContext(TabActivityContext);
    const { label, card } = activity as TActivity;
    return (
        <ActivityItemBody
            header={<ActivityHeader icon={'tag'} label={label} />}
            activity={activity}
            onClick={() => onOpenCard(card.id, activity.id, 'tags')}
        >
            <ActivityTags tags={activity.tags} asidePanelWidth={asidePanelWidth} />
        </ActivityItemBody>
    );
};
