import { IGetState, TBoardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { fetchHandler } from '../../../../util/fetchHandler';
import { Util } from '../../../../util/util';
import { REST_BOARD, REST_BOARD_PERMISSIONS, REST_BOARD_USERS } from '../../../constants';
import { getRestHeadersGet } from '../../../helpers/getRestHeadersHelper';
import { IPermissions } from '../../../../types/model';
import { ISharedUser } from '../../../../types/rest/ISharedUser';

export const fetchUsersRest = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return fetchHandler<ISharedUser[]>(
            Util.getApiUrl(REST_BOARD + boardId + REST_BOARD_USERS),
            getRestHeadersGet()
        );
    };
    return action;
};
