import { getCard } from './getCard';
import { IApplicationState, TCardId } from '../../../types/types';

export const getSubcardWeight = (
    state: IApplicationState,
    cardId: TCardId
): number => {
    const card = getCard(state, cardId);
    return card.meta && card.meta.subcardWeight || 1;
}
