import { getCardColorTags } from './../../../../../../../../store/model/selectors/getCardColorTags';
import { connect } from 'react-redux';
import * as React from 'react';
import { EMPTY_ARRAY, IApplicationState } from '../../../../../../../../types/types';
import { ICardHOCProps } from './types';
import { ICardEvents, ICardFields } from '../../components/Card/types';
import { onCardClick } from './events/onCardClick';
import { Card } from '../../components/Card/Card';
import { getCardCollaborators } from '../../../../../../../../store/model/selectors/getCardCollaborators';
import { isCardHasActiveRequests } from '../../../../../../../../store/model/card/selectors/isCardHasActiveRequests';
import { getCard } from '../../../../../../../../store/model/selectors/getCard';
import { getCardSubcardsCount } from '../../../../../../../../store/model/selectors/getCardSubcardsCount';
import { getBoardWorkHoursInDay } from '../../../../../../../../store/model/selectors/getBoardWorkHoursInDay';
import { getBoardIdByCardId } from '../../../../../../../../store/model/selectors/getBoardIdByCardId';
import { onDidMount } from './events/onDidMount';
import { getBoardPermissionsIsReadonly } from '../../../../selectors/getBoardPermissionsIsReadonly';
import { getCardFullName } from '../../../../../../../../store/model/card/selectors/getCardFullName';
import { TStatus } from '../../../../../../../../types/model';
import { getCardPinnedSectionsNoreselect } from '../../../../../../../../store/model/selectors/getCardPinnedSections';
import {
    getBoardRolesSortedCreateSelector
} from '../../../../../../../../store/model/board/selectors/getBoardRolesSorted';
import { getAuthUser } from '../../../../../../../../store/model/authUser/selectors/getAuthUser';
import { getBoardUsersNoreselect } from '../../../../../../../../store/model/selectors/getBoardUsers';
import { getDraggableCardId } from '../../../../store/selectors/getDraggableCardId';
import { getSelectedCards } from '../../../../../../clickManager/selectors/getSelectedCards';
import { getCardEstimateString } from '../../../../../../../../store/model/helpers/getCardEstimateString';
import { isCardSelected } from '../../../../../../clickManager/selectors/isCardSelected';
import { getDriveDocsByCardId } from '../../../../../../../../store/model/driveDocs/selectors/getDriveDocsByCardId';
import { root } from '../../../../../../../../store/constants';
import { ERestDriveDocType } from '../../../../../../../../types/rest/IRestDriveDoc';
import {
    getCardPinnedCustomPropertiesIds
} from '../../../../../../../../store/model/selectors/getCardPinnedCustomPropertiesIds';
import { getHighlightCardId } from '../../../../store/selectors/getHighlightCardId';
import { onAnimationEnd } from './events/onAnimationEnd';
import { getRecentlyWatchCardId } from '../../../../store/selectors/getRecentlyWatchedCardId';
import {
    getCurrentNotFilledCardId
} from '../../../../../../aside_panel/cardDetails/store/selectors/getCurrentNotFilledCardId';
import { getCardTimeSinceLastActivity } from './selectors/getCardTimeSinceLastActivity';
import { getCardTimeSinceMovedToList } from './selectors/getCardTimeSinceMovedToList';
import { getIsCardAgingOpacity } from './selectors/getIsCardAgingOpacity';
import { getCardAgingIndicator } from './selectors/getCardAgingIndicator';
import { getIsMultiselect } from 'app/view/react_components/clickManager/selectors/getIsMultiselect';
import { onCheckboxClick } from './events/onCheckboxClick'
import { isEqual } from 'underscore';
import {
    getBoardMetaDefaultTemplateCardId
} from '../../../../../../../../store/model/selectors/getBoardMetaDefaultTemplateCardId';
import { Util } from 'app/util/util';
import { isRelatedCardsPanelOpened } from 'app/view/react_components/aside_panel/relatedCards/store/selectors/isRelatedCardsPanelOpened';

// const analyze = new Analyze('CardHOC');
// (reselect) average: 0.058 ms, calls: 226512, total: 13190.835 ms
// (_.isEqual) average: 0.049 ms, calls: 56144, total: 2758.950 ms
// 2022-12-16:
// (isEqualOneLevel) average: 0.029 ms, calls: 18884, total: 549.300 ms (непустые mainDriveDocSet не кэшируются)
// (isEqual) average: 0.042 ms, calls: 17743, total: 743.000 ms (полный кэш)
// (isEqual + убрал реселекты) average: 0.030 ms, calls: 18000, total: 546.100 ms

const mapStateToProps = () => {
    const getBoardRolesSorted = getBoardRolesSortedCreateSelector()

    let cardCache: ICardFields = null;
    const getCardCache = (
        card: ICardFields
    ): ICardFields => {
        if (isEqual(card, cardCache)) {
            return cardCache;
        }
        cardCache = card;
        if (root._debug.selector) {
            console.log('getCardCache ');
        }
        return card;
    };

    return (
        state: IApplicationState,
        props: ICardHOCProps
    ): ICardFields => {
        const {cardId, height} = props;
        const boardId = getBoardIdByCardId(state, cardId);
        const hoursInDay = getBoardWorkHoursInDay(state, boardId);
        const cardStore = getCard(state, cardId);
        const {
            assignees = EMPTY_ARRAY,
            backgroundColor,
            cardNumber,
            checklistStats,
            commentCount,
            description,
            driveDocCount,
            dueDate,
            epicId,
            estimate = 0,
            listId,
            name,
            priority,
            processingPercent,
            processingStatus,
            startDate,
            status,
            tags = EMPTY_ARRAY,
            recurringTasks = EMPTY_ARRAY
        } = cardStore;
        const {totalCount = 0, checkedCount = 0} = checklistStats || {};

        // analyze.start();
        const collaborators = getCardCollaborators(state, cardId); // без реселекта - все ок
        const colorTags = getCardColorTags(state, cardId); // без реселекта
        const epicName = getCardFullName(state, epicId); // без реселекта - все ок
        const estimateStr = getCardEstimateString(cardStore, hoursInDay); // без реселекта
        const isFakeCovered = isCardHasActiveRequests(state, cardId);
        const isCovered = Date.now() - cardStore.created * 1000 <= 5000 && // KNB-3401 лоудер только при создании карты
            isCardHasActiveRequests(state, cardId); // без реселекта - все ок
        const isSelected = isCardSelected(state, boardId, cardId); // без реселекта
        const mainDriveDocSet = getDriveDocsByCardId(state, cardId, ERestDriveDocType.CARD, true, true); // без реселекта
        const subCardsCount = getCardSubcardsCount(state, cardId);  // без реселекта - все ок
        const pinnedSections = getCardPinnedSectionsNoreselect(state, cardId); // без реселекта
        const pinnedChecklists = pinnedSections.checklists ? pinnedSections.checklists : EMPTY_ARRAY;
        const boardRoles = getBoardRolesSorted(state, boardId); //реселект - все ок
        const boardUsers = getBoardUsersNoreselect(state, boardId); // без реселекта
        const {permissionId: authUserPermissionId, allowCardAgeing} = getAuthUser(state); //реселект - все ок
        const draggableCardId = getDraggableCardId(state); // без реселекта - все ок
        const selectedCards = getSelectedCards(state, boardId); // без реселекта - все ок
        const isRecentlyWatched = getRecentlyWatchCardId(state) === cardId;
        const isNotFilled = getCurrentNotFilledCardId(state) === cardId;
        const cardAgingIndicator = !allowCardAgeing ? null : getCardAgingIndicator(state, cardStore);
        const isCardAgingOpacity = !allowCardAgeing ? null : getIsCardAgingOpacity(state, cardStore);
        const timeSinceLastActivity = !cardAgingIndicator ? null : getCardTimeSinceLastActivity(cardStore);
        const timeSinceMovedToList = !cardAgingIndicator ? null : getCardTimeSinceMovedToList(cardStore);
        // const isRelatedPanel = isRelatedPanelClosed(state);
        const isRelatedPanel = isRelatedCardsPanelOpened(state)
        const isRelatedCardEdit = isRelatedPanel && state.relatedCards.cardId === cardId;

        // Если перетаскивается карта из выделения и текущая карта так же в выделении
        const isMultipleDragging = draggableCardId && draggableCardId !== cardId && selectedCards.includes(draggableCardId) && selectedCards.includes(cardId);
        const isMultiselect = getIsMultiselect(state, boardId) || isRelatedPanel && !isRelatedCardEdit;

        const card = getCardCache({
            boardId,
            assignees,
            attachmentsCount: driveDocCount,
            backgroundColor,
            cardNumber,
            checklistsCount: totalCount,
            commentCount,
            description: pinnedSections.description && Util.fixAuthuser(description),
            doneChecklistsCount: checkedCount,
            dueDate,
            epicName,
            estimate,
            recurringTasks,
            hasDescription: Boolean(description),
            height,
            id: cardId,
            listId,
            name,
            percent: processingPercent,
            priority,
            processingStatus,
            startDate,
            tags,

            collaborators,
            isHighlight: getHighlightCardId(state) === cardId,
            colorTags,
            estimateStr,
            isCovered,
            isNotFilled,
            isSelected,
            isFakeCovered,
            isRecentlyWatched,
            isRelatedCardEdit,
            isRelatedPanel,
            isTemplate: status === TStatus.STATUS_SERVICE_TEMPLATE,
            mainDriveDocSet,
            subCardsCount,
            boardRoles,
            boardUsers,
            authUserPermissionId,
            isCardAgingOpacity,
            cardAgingIndicator,
            timeSinceLastActivity,
            timeSinceMovedToList,

            isPinnedChecklists: !!pinnedChecklists.length,
            isPinnedSubcards: pinnedSections.subcards,
            isPinnedCustomProperties: !!getCardPinnedCustomPropertiesIds(state, cardId).length,
            isReadonly: getBoardPermissionsIsReadonly(state, boardId),
            isMultipleDragging,
            isMultiselect,
            isDefaultTemplate: getBoardMetaDefaultTemplateCardId(state, boardId) === cardId
        });
        // analyze.finish();

        return card;
    };
};

const mapDispatchToProps = (
    dispatch: any,
    {cardId}: ICardHOCProps
): ICardEvents => {
    return {
        onCardClick: (e: React.MouseEvent) => dispatch(onCardClick(e, cardId)),
        onDidMount: () => dispatch(onDidMount(cardId)),
        onAnimationEnd: () => dispatch(onAnimationEnd(cardId)),
        onCheckboxClick: () => dispatch(onCheckboxClick(cardId))
    };
};

export const CardHOC = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Card);

CardHOC.displayName = 'CardHOC';
