import {
    ERecurringTaskFrequencyType,
    IRestRecurringTask
} from '../../../../../../../types/rest/recurringTask/IRestRecurringTask';

export const getTextPeriod = (task: IRestRecurringTask): string => {
    if (task.day && task.frequencyType === ERecurringTaskFrequencyType.DAY) {
        let dayWording = `Every ${task.day.dayCount} day`;
        return task.day.dayCount > 1 ? dayWording + 's' : dayWording;
    } else if (task.week && task.frequencyType === ERecurringTaskFrequencyType.WEEK) {
        let weekWording = `Every ${task.week.weekCount} week`;
        return task.week.weekCount > 1 ? weekWording + 's' : weekWording;
    } else if (task.year && task.frequencyType === ERecurringTaskFrequencyType.YEAR) {
        let yearWording = `Every ${task.year.yearCount} year`;
        return task.year.yearCount > 1 ? yearWording + 's' : yearWording;
    } else if (task.frequencyType === ERecurringTaskFrequencyType.DAY_OF_WEEK_IN_MONTH) {
        let monthWording = `Every ${task.dayOfWeekInMonth.monthFrequency} month`;
        return task.dayOfWeekInMonth.monthFrequency > 1 ? monthWording + 's' : monthWording;
    } else if(task.frequencyType === ERecurringTaskFrequencyType.DAY_OF_MONTH) {
        let monthWording = `Every ${task.dayOfMonth.monthCount} month`;
        return task.dayOfMonth.monthCount > 1 ? monthWording + 's' : monthWording;
    }
    return '';
}
