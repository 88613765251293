import { getMessages } from '../../../../store/constants';

export const TOP_BAR_CLASS = 'top-bar';

export const CARD_DONE_PROCESSING_STATUS = 'done';

export const TB_DASHBOARD_EDITING_TYPE = 'TB_DASHBOARD_EDITING_TYPE';
export const TB_DASHBOARD_SYNC_TYPE = 'TB_DASHBOARD_SYNC_TYPE';

export const TB_SHARE_ANYONE_TYPE = 'anyone';
export const TB_SHARE_ANYONE_WLINK_TYPE = 'anyoneWithLink';
export const TB_SHARE_DOMAIN_TYPE = 'domain';
export const TB_SHARE_DOMAIN_WLINK__TYPE = 'domainWithLink';

export enum TB_SHARE_ICON {
    TB_SHARING_TYPE_LOCK = 'lock',
    TB_SHARING_TYPE_SPECIFIC = 'share-specific',
    TB_SHARING_TYPE_PUBLIC = 'share-public',
    TB_SHARING_TYPE_LINK = 'share-link',
    TB_SHARING_TYPE_DOMAIN = 'share-domain',
    TB_SHARING_TYPE_DOMAIN_LINK = 'share-domain-link'
}

export const TB_SHARING_TYPE_LOCK = TB_SHARE_ICON.TB_SHARING_TYPE_LOCK;
export const TB_SHARING_TYPE_SPECIFIC = TB_SHARE_ICON.TB_SHARING_TYPE_SPECIFIC;
export const TB_SHARING_TYPE_PUBLIC = TB_SHARE_ICON.TB_SHARING_TYPE_PUBLIC;
export const TB_SHARING_TYPE_LINK = TB_SHARE_ICON.TB_SHARING_TYPE_LINK;
export const TB_SHARING_TYPE_DOMAIN = TB_SHARE_ICON.TB_SHARING_TYPE_DOMAIN;
export const TB_SHARING_TYPE_DOMAIN_LINK = TB_SHARE_ICON.TB_SHARING_TYPE_DOMAIN_LINK;

export const TOP_BAR_LEFT_COLUMN_MAX_WIDTH = 720; // px

export const SHARING_ERROR_MESSAGE = 'Sorry, sharing is unavailable at this time. Please try again later.';
