import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TBoardId } from '../../../../../../../../types/types';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { ensureBoardIsFullLoaded } from '../../../../../../../../store/model/effects/ensureBoardIsFullLoaded';

export const onAddClicked = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.ADD_SUBCARD_BUTTON));
        dispatch(ensureBoardIsFullLoaded(boardId));
    };
    return action;
};
