import * as React from 'react';
import { SectionAccordion } from 'kui';
import { IColorSelectProps } from './types';
import './_ColorSelect.scss';
import { ColorPalette } from '../../../ColorPalette/ColorPalette/ColorPalette';
import { EPaletteVariant } from '../../../ColorPalette/types';
import { CLASS_COLOR } from '../../constants';

export const ColorSelect = ({
    colors,
    isHideTitle,
    selectedColor,
    title,
    onSelect
}: IColorSelectProps) => {
    const className = CLASS_COLOR + '-select';

    const body = <ColorPalette
        isAutoFocus
        colors={colors}
        selectedColor={selectedColor || ''}
        onSelect={onSelect}
        variant={EPaletteVariant.CARD_COLOR}
    />;

    return (
        <div className={className}>
            {isHideTitle ?
                body
                : <SectionAccordion
                    variant={'simple'}
                    title={title}
                    opened
                >
                    {body}
                </SectionAccordion>
            }
        </div>
    );
};
