import { Dispatch } from 'app/types/actions';
import { IGetState, TCardId } from 'app/types/types';
import { getBoardByCardId } from 'app/store/model/selectors/getBoardByCardId';
import { getBoardMetaDefaultTemplateCardId } from 'app/store/model/selectors/getBoardMetaDefaultTemplateCardId';
import { boardSetDefaultTemplateCardIdMeta } from 'app/rest/effects/board/boardSetDefaultTemplateCardIdMeta';

export const deleteDefaultTemplateCardIdIfNeeded = (
    cardId: TCardId
) => {
    return (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const boardId = getBoardByCardId(getState(), cardId).id;
        const isDefault = getBoardMetaDefaultTemplateCardId(getState(), boardId) === cardId;
        if (isDefault) {
            dispatch(boardSetDefaultTemplateCardIdMeta(boardId, null));
        }
    }
}
