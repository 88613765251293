import { createRoot } from 'react-dom/client';
import React from "react";
import {Provider} from "react-redux";
import { LazyWrapper } from '../react_components/lazy/lazy_wrapper';
import { historyPush } from '../react_components/base/helpers/historyHelper';
import { EFeedbackCategory, EFeedbackSuccessTitle, EFeedbackTitle } from '../react_components/dialogs/feedback';
import { TRELLO_BACK_URL } from 'app/const';

App.Views.HelpPopupView = App.Views.BaseView.extend({
    initialize: function(options) {
        this.root = createRoot(this.el);
        this.store = options.store;
    },

    initFeedbackStore: function() {
        return import(/* webpackChunkName: "feedback" */ '../react_components/dialogs/feedback/store/reducers/feedBackFormReducer')
        .then((module) => {
            this.store.reducerManager.add('feedBackForm', module.feedBackFormReducer);
            this.store.dispatch({type: 'async-init'}); // fake action to init new reducers
        })
        .catch(e => console.error(e))
    },

    render: function (options) {
        const ImportElement = React.lazy(() =>
            this.initFeedbackStore()
                .then(() => import(/* webpackChunkName: "feedback" */ '../react_components/dialogs/feedback/hocs/feedbackFormContainerHoc/feedbackFormContainerHoc'))
                .then(module => {
                    this.show(options);
                    return {default: module.FeedbackContainerHoc}
                })
                .catch(e => console.error(e))
        );
        this.root.render(
            <LazyWrapper>
                <Provider store={this.store}>
                    <ImportElement />
                </Provider>
            </LazyWrapper>
        );
        return this;
    },

    show: function (options) {
        return Promise.all([
            import(/* webpackChunkName: "feedback" */ '../react_components/dialogs/feedback/effects/createFeedBackSession'),
            import(/* webpackChunkName: "feedback" */ '../react_components/dialogs/feedback/store/actions/changeFeedBackSuccessTitle'),
            import(/* webpackChunkName: "feedback" */ '../react_components/dialogs/feedback/store/actions/setFeedbackTechDetails'),
            import(/* webpackChunkName: "feedback" */ '../react_components/dialogs/feedback/store/actions/changeFeedBackTitle'),
            import(/* webpackChunkName: "feedback" */ '../react_components/dialogs/feedback/store/actions/setFeedbackCategory'),
        ])
            .then(([
                createFeedBackSession,
                changeFeedBackSuccessTitle,
                setFeedbackTechDetails,
                changeFeedBackTitle,
                setFeedbackCategory
            ]) => {
                const {alert, request, url, requestProperty, category} = options;
                if (requestProperty) {
                    this.store.dispatch(changeFeedBackTitle.changeFeedBackTitle(EFeedbackTitle.REQUEST_PROPERTY_TITLE));
                } else if (alert) {
                    this.store.dispatch(changeFeedBackSuccessTitle.changeFeedBackSuccessTitle(EFeedbackSuccessTitle.BUG_TITLE));
                    if (url === TRELLO_BACK_URL) {
                        this.store.dispatch(changeFeedBackTitle.changeFeedBackTitle(EFeedbackTitle.TRELLO_TITLE));
                    } else {
                        this.store.dispatch(changeFeedBackTitle.changeFeedBackTitle(EFeedbackTitle.BUG_TITLE));
                    }
                    this.store.dispatch(setFeedbackTechDetails.setFeedbackTechDetails(request.responseText));
                }
                this.store.dispatch(createFeedBackSession.createFeedBackSession());
                this.store.dispatch(setFeedbackCategory.setFeedbackCategory(category ? category : EFeedbackCategory.DEFAULT));

                historyPush({
                    state: { page: 'feedback' },
                    onpopstate: () => this.hide()
                });
            })
            .catch(e => console.error(e))
    },

    hide: function () {
        import(/* webpackChunkName: "feedback" */ '../react_components/dialogs/feedback/effects/closeHelpPopup')
            .then(module => this.store.dispatch(module.closeHelpPopup()))
            .catch(e => console.error(e))
    },

    remove: function () {
        this.root.unmount();
        Backbone.View.prototype.remove.call(this);
    }
});

export default App.Views.HelpPopupView;
