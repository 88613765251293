import { EViewTypes } from '../../../const';
import { getMessages } from '../../../store/constants';

export const CLASS_TYPE_SWITCHER = 'type-switcher';
export const CLASS_TYPE_SWITCHER_ARROW = CLASS_TYPE_SWITCHER + '__arrow';

export const KanbanDashboardItem = Object.freeze({
    name: getMessages().getText('view_types.default'),
    icon: 'kanban-default'
});
export const GanttDashboardItem = Object.freeze({
    name: getMessages().getText('view_types.gantt'),
    icon: 'gantt-chart'
});
export const ListViewDashboardItem = Object.freeze({
    name: getMessages().getText('view_types.list'),
    icon: 'list-view'
});
export const ArchiveDashboardItem = Object.freeze({
    name: getMessages().getText('view_types.archive'),
    icon: 'archive'
});
export const ReportsItem = Object.freeze({
    name: getMessages().getText('view_types.reports'),
    icon: 'chart'
});

export const TeamWorkloadItem = Object.freeze({
    name: getMessages().getText('view_types.list_grouping.user'),
    icon: 'user'
});

export const ColorTagItem = Object.freeze({
    name: getMessages().getText('view_types.list_grouping.color_tag'),
    icon: 'color-tag'
});

export const CardColorItem = Object.freeze({
    name: getMessages().getText('view_types.list_grouping.card_color'),
    icon: 'card-color'
});

export const TagItem = Object.freeze({
    name: getMessages().getText('view_types.list_grouping.tag'),
    icon: 'tag'
});

export const PriorityItem = Object.freeze({
    name: getMessages().getText('view_types.list_grouping.priority'),
    icon: 'priority-no'
});

export const VIEW_TYPES = {
    [EViewTypes.KANBAN_VIEW_TYPE]: KanbanDashboardItem,
    [EViewTypes.GANTT_VIEW_TYPE]: GanttDashboardItem,
    [EViewTypes.LIST_VIEW_TYPE]: ListViewDashboardItem,
    [EViewTypes.ARCHIVE_VIEW_TYPE]: ArchiveDashboardItem,
    [EViewTypes.REPORTS_VIEW_TYPE]: ReportsItem,
    [EViewTypes.USERS_VIEW_TYPE]: TeamWorkloadItem,
    [EViewTypes.COLOR_TAGS_VIEW_TYPE]: ColorTagItem,
    [EViewTypes.CARD_COLORS_VIEW_TYPE]: CardColorItem,
    [EViewTypes.TAGS_VIEW_TYPE]: TagItem,
    [EViewTypes.PRIORITIES_VIEW_TYPE]: PriorityItem,

    [EViewTypes.SWIM_COLOR_TAGS_VIEW_TYPE]: ColorTagItem,
    [EViewTypes.SWIM_TAGS_VIEW_TYPE]: TagItem,
};

export const LABEL_KANBAN = getMessages().getText('view_types.kanban');
export const LABEL_LIST_GROUPING = getMessages().getText('nav_panel.list_grouping');
export const LABEL_SWIMLANE = getMessages().getText('nav_panel.swimlane');

export const LOCALSTORAGE_LAST_KANBAN_VIEW = 'LAST_KANBAN_VIEW';

export const TYPE_SWITCHER_MOBILE_BREAKPOINT = 640; // px, same in css
export const TYPE_SWITCHER_TIMER = 300; // ms
export const TYPE_SWITCHER_ANIMATE_TIMER = 300; // ms
