import { EAuthUserPlatformType, TUserId } from './IRestAuthUser';

export type TSubscriptionId = number;
export type TSubscriptionItemId = number;

export enum ESubscriptionStatus {
    // from Subscription.java
    ACTIVE = 'Active',
    CANCELLED = 'Cancelled',
    CANCELLED_BY_USER = 'CancelledByUser',
    FUTURE = 'Future',
    INACTIVE = 'Inactive',
    INITIAL = 'Initial',
    IN_TRIAL = 'in_trial',
    PAUSED = 'Paused', // не используется
}

export enum EPaymentSource {
    PAYMENT_SOURCE_CHARGEBEE = 'payment_source_chargebee',
    PAYMENT_SOURCE_MANUALLY = 'payment_source_manually',
    PAYMENT_SOURCE_PAYPAL = 'payment_source_paypal',
    PAYMENT_SOURCE_PROMOCODE = 'payment_source_promocode',
}

export interface IRestSubscription {
    // from SubscriptionRest.java
    id?: TSubscriptionId;
    authorId?: TUserId;
    created?: number;
    description?: string;
    gross?: number;
    isPromo?: boolean;
    isTrial?: boolean;
    items?: IRestSubscriptionItem[];
    managersObjects?: IRestSubscriptionManager[];
    name?: string;
    parentId?: TSubscriptionId;
    paymentDate?: number;
    paymentExpDate?: number;
    paymentSource?: EPaymentSource;
    paypalButton?: string; // todo надо ещё?
    platformType?: EAuthUserPlatformType;
    productId?: number;
    promoCode?: string;
    seatsAvailable?: number;
    seatsCount?: number;
    status?: ESubscriptionStatus;
    superManagersObjects?: IRestSubscriptionManager[];
    cometToken?: string;

    territory?: string;
    billingCode?: string;
}

export interface IRestSubscriptionItem {
    //from SubscriptionRest.java
    id?: TSubscriptionItemId;
    email?: string;
    gross?: number;
    platformType?: EAuthUserPlatformType;
    subscriptionId?: TSubscriptionId;
}

export interface IRestSubscriptionManager {
    email?: string,
    reportEnabled?: boolean
    signedUp?: boolean
    hasPermissions?: boolean
}

export type TRestSubscriptionsGetByIds = TSubscriptionItemId[];
