import { IApplicationState } from '../../../../../types/types';
import { getUserMetaMyWorkColumns } from './getUserMetaMyWorkColumns';

export const getUserMetaMyWorkHiddenColumns = (
    state: IApplicationState
): string[] => {
    return getUserMetaMyWorkColumns(state)
        .filter(column => column.isHidden)
        .map(column => column.title);
}
