import { IApplicationState } from 'app/types/types';
import { getBoard } from 'app/store/model/selectors/getBoardById';
import { connect } from 'react-redux';
import { IBoardNameEvents, IBoardNameFields } from '../../components/BoardName/types';
import { onChange } from './events/onChange';
import { onEsc } from './events/onEsc';
import { IBoardNameHOCProps } from './types';
import { BoardName } from '../../components/BoardName/BoardName';
import { getAsidePanelWidth } from 'app/view/react_components/aside_panel/asidePanel/store/selectors/getAsidePanelWidth';

const mapStateToProps = (
    state: IApplicationState,
    ownProps: IBoardNameHOCProps
): IBoardNameFields => {
    const { boardId, isReadonly } = ownProps;
    const board = getBoard(state, boardId);
    const asidePanelWidth = getAsidePanelWidth(state);
    return {
        name: board ? board.name : '',
        asidePanelWidth,
        isReadonly
    }
};

const mapDispatchToProps = (
    dispatch: any,
    ownProps: IBoardNameHOCProps
): IBoardNameEvents => {
    const { boardId } = ownProps;
    return {
        onChange: (name: string) => dispatch(onChange(boardId, name)),
        onEsc: () => dispatch(onEsc())
    }
};

export const BoardNameHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(BoardName);

BoardNameHOC.displayName = 'BoardNameHOC';
