import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../../types/types';
import { savedFilterSelector } from '../../../../../store/selectors/savedFilterSelector';
import { savedFilterActionsSet } from '../../../../../store/savedFilters/actions/savedFilterActionsSet';
import { enabledSet } from '../../../../../store/filter/actions/enabledSet';
import {
    SegmentFilterEvent,
    SegmentFilterOption
} from '../../../../../../../../../middlewares/segment/trackEntities/filterEvents';
import {
    getSavedEnabledFiltersCountSelector
} from '../../../../../store/selectors/getSavedEnabledFiltersCountSelector';
import { ISegmentEventOption } from '../../../../../../../../../middlewares/segment/trackEntities/types';
import { filterSegmentTrackAction } from '../../../../../effects/filterSegmentTrackAction';
import { filterPanelActionSetAction } from '../../../../../store/filterPanelBoards/actions/filterPanelActionSetAction';
import { isInitialSetAction } from '../../../../../store/filterPanel/actions/isInitialSetAction';
import { MY_WORK_BOARD_ID } from '../../../../../../../main/myWorkView/constants';
import { updateMyWorkMeta } from '../../../../../../../../../rest/effects/authUser/myWork/updateMyWorkMeta';
import { getMyWorkEnabledFilters } from '../../../../../../../main/myWorkView/selectors/getMyWorkEnabledFilters';
import {
    getIsUserBasicPlanOnly
} from '../../../../../../../../../store/model/authUser/selectors/getIsUserBasicPlanOnly';
import { showSnoozeBlocker } from '../../../../../../../base/effects/showSnoozeBlocker';
import { getIsAssignedToMeActive } from '../../../../../../../../../store/router/selectors/getIsAssignedToMeActive';

export const onToggleFilter: ActionCreator<ThunkAction> = (
    boardId: number,
    filterId: number
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const { isEnabled} = savedFilterSelector(state, filterId);
        const isBasicPlanOnly = getIsUserBasicPlanOnly(state);
        if (isBasicPlanOnly) {
            dispatch(showSnoozeBlocker({
                allow: getIsAssignedToMeActive(state) ? 'allowMyWorkCustomFilters' : 'allowSavedFilters'
            }));
        } else {
            dispatch(savedFilterActionsSet(filterId, enabledSet(!isEnabled)));
            if (boardId === MY_WORK_BOARD_ID) {
                const enabledFilters = [...getMyWorkEnabledFilters(getState())];
                if (isEnabled) {
                    const idx = enabledFilters.indexOf(filterId);
                    if (idx >= 0) {
                        enabledFilters.splice(idx, 1);
                    }
                } else {
                    enabledFilters.push(filterId);
                }
                dispatch(updateMyWorkMeta({ enabledFilters }));
            }
            dispatch(filterPanelActionSetAction(boardId, isInitialSetAction(false)));
            if (!isEnabled) {
                const options: ISegmentEventOption = {
                    name: SegmentFilterOption.PROPERTY_COUNT,
                    value: getSavedEnabledFiltersCountSelector(getState())
                };
                dispatch(filterSegmentTrackAction(SegmentFilterEvent.FILTER_ENABLED, boardId, options));
            } else {
                dispatch(filterSegmentTrackAction(SegmentFilterEvent.FILTER_DISABLED, boardId));
            }
        }
    };
    return action;
};
