import { IGetState, TCardId } from '../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { fetchHandler } from '../../../../../util/fetchHandler';
import { IRestCard } from '../../../../../types/rest/IRestCard';
import Util from '../../../../../util/util';
import { REST_CARD } from '../../../../constants';
import { getRestHeadersPost } from '../../../../helpers/getRestHeadersHelper';
import { ICardCopyRestOptions } from './types';

export const copyRest = (
    cardId: TCardId,
    options: ICardCopyRestOptions = {}
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const params = Object.keys(options).map((key: keyof ICardCopyRestOptions) => {
            return `${key}=${options[key]}`
        })
        const paramsString = params.length > 0 ? `?${params.join('&')}` : '';
        return fetchHandler<IRestCard>(
            Util.getApiUrl(`${REST_CARD}${cardId}/copy${paramsString}`), {
                ...getRestHeadersPost(),
            }
        )
    };
    return action;
};
