import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState, TCardId } from '../../../../types/types';
import { getCard } from '../../../../store/model/selectors/getCard';
import { root } from '../../../../store/constants';

export const cardOpen = (
    cardId: TCardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const card = getCard(state, cardId);
        if (!card) return;

        const list = state.model.lists[card.listId];
        if (!list) return;

        root.App.controller.cleverOpenCard(list.dashboardId, cardId);
    };
    return action;
};
