import { IActivityIdSetAction } from './types';
import { AT_ACTIVITYID_SET } from './constants';
import { TActivityId } from '../../../../../../types/types';

export const activityIdSetAction = (
    activityId: TActivityId
): IActivityIdSetAction => ({
    type: AT_ACTIVITYID_SET,
    activityId
});
