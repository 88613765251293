import { IActivityProps } from '../../../../boardDetails/activity/TabActivity/types';
import * as React from 'react';
import { TabActivityContext } from '../../../../boardDetails/activity/TabActivity/components/TabActivity/constants';
import {
    IRestCardCheckListGroupActivity
} from '../../../../../../../types/rest/activity/IRestCardCheckListGroupActivity';
import { TActivity } from '../../../../../../../store/activities/types';
import {
    ActivityItemBody
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemBody/ActivityItemBody';
import { ActivityHeader } from '../../../../boardDetails/activity/TabActivity/components/ActivityHeader/ActivityHeader';
import {
    ActivityItemName
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemName/ActivityItemName';
import { Icon } from 'kui';
import { CLASS_ACTIVITY_ITEM } from '../../../../boardDetails/activity/TabActivity/components/constants';
import './_CardActivityCheckListGroup.scss';
import { blockMarkdown } from '../../../../../../../helper/markdownHelper';
import { msFileThumbnailStorage } from '../../../../../../../helper/authorisation/microsoft/storage/msThumbnail';

export function CardActivityCheckListGroup({
    activity
}: IActivityProps<IRestCardCheckListGroupActivity>) {
    const { onOpenCard } = React.useContext(TabActivityContext);
    const { oldValue, newValue } = activity;
    const { label, card } = activity as TActivity;
    const className = CLASS_ACTIVITY_ITEM + '__checklist-group';
    const [newNameMarkdown, setNewNameMarkdown] = React.useState('');
    const [oldNameMarkdown, setOldNameMarkdown] = React.useState('');

    React.useEffect(() => {
        let newName = activity.newValue;
        let oldName = activity.oldValue;

        setNewNameMarkdown(blockMarkdown(newName));
        setOldNameMarkdown(blockMarkdown(oldName));

        if (msFileThumbnailStorage.is()) {
            if (newName) {
                msFileThumbnailStorage.getSrcThumbnails(newName)
                    .then((text) => {
                        setNewNameMarkdown(blockMarkdown(text));
                    })
                    .catch(() => {});
            }
            if (oldName) {
                msFileThumbnailStorage.getSrcThumbnails(oldName)
                    .then((text) => {
                        setOldNameMarkdown(blockMarkdown(text));
                    })
                    .catch(() => {});
            }
        }
    }, [activity]);

    return (
        <ActivityItemBody
            className={`${className} ${oldValue && newValue ? className + '--edit' : ''}`}
            header={<ActivityHeader icon={'done'} label={label} />}
            activity={activity}
            onClick={() => onOpenCard(card.id, activity.id, 'checklists')}
        >
            <>
                {oldValue && <ActivityItemName name={oldNameMarkdown} isRemove={!!newValue} isMarkdown />}
                {newValue && oldValue && <Icon xlink={'forward'} size={24}/>}
                {newValue && <ActivityItemName name={newNameMarkdown} isMarkdown />}
            </>
        </ActivityItemBody>
    );
};
