import { IApplicationState, TBoardId } from '../../../types/types';
import { getBoardListIds } from 'app/store/model/selectors/getBoardListIds';
import { getSorting } from 'app/store/model';

export const getBoardCardSorting = (
    state: IApplicationState,
    boardId: TBoardId,
): boolean => {
    const lists = getBoardListIds(state, boardId)
    let isSorting: boolean = false;
    for (let i = 0; i < lists.length; i++) {
        const isEnabled = getSorting(state, lists[i]).isEnabled;
        if (isEnabled) {
            isSorting = true
            break
        }
    }
    return isSorting;
};
