import { ICurrentNotFilledCardId } from './types';
import { AT_SET_CURRENT_NOT_FILLED_CARD_ID } from './constants';
import { TCardId } from '../../../../../../types/types';

export const currentNotFilledCardIdSetAction = (
    cardId: TCardId,
): ICurrentNotFilledCardId => ({
    type: AT_SET_CURRENT_NOT_FILLED_CARD_ID,
    cardId
});
