import * as React from 'react';
import { ITrialExtendProps } from './types';
import { TrialExtendButton } from '../../components/TrialExtendButton/TrialExtendButton';
import { TrialExtendDialog } from '../../components/TrialExtendDialog/TrialExtendDialog';
import { Portal } from '../../../../helpers/portal';
import { historyPush } from '../../../../base/helpers/historyHelper';

export function TrialExtend ({
    onOpen,
    onClose,
    onSubmit
}: ITrialExtendProps) {

    const [isOpen, setOpen] = React.useState(false);

    function openTrialDialog () {
        setOpen(true);
        onOpen();
        historyPush({
            state: { page: 'blocker' },
            onpopstate: closeTrialDialog
        });
    }

    function closeTrialDialog () {
        setOpen(false);
        onClose();
    }

    return (<>
        <TrialExtendButton onClick={openTrialDialog} />
        {isOpen &&
            <Portal
                className={'trial-extend__dialog-container'}
                prepend={true}
                selector={'.second-panel--blocker.second-panel--show .second-panel__inner'}
            >
                <TrialExtendDialog
                    onClose={closeTrialDialog}
                    onSubmit={onSubmit}
                />
            </Portal>
        }
    </>);
}
