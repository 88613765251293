import { Thumbnail } from '@microsoft/microsoft-graph-types';
import { getMSFileThumbnails } from '../graph/api/getMSFileThumbnails';
import { THUMB_COMMENT_SIZE } from 'app/const';

type TFileThumbnails = {
    [id: string]: TFileThumbnailSizes;
};

type TFileThumbnailSizes = {
    [size: string]: Promise<Thumbnail>;
}

class MSFileThumbnail {
    thumbnails: TFileThumbnails;

    init (
    ) {
        this.thumbnails = {};
    }

    is (
    ) {
        return !!this.thumbnails;
    }

    getThumbnail (
        driveId: string,
        fileId: string,
        thumbSize: string,
    ): Promise<Thumbnail> {
        if (!this.thumbnails) return null;

        if (this.thumbnails[fileId] &&
            (thumbSize in this.thumbnails[fileId])
        ) {
            return this.thumbnails[fileId][thumbSize];
        }

        if (!this.thumbnails[fileId]) this.thumbnails[fileId] = {};

        this.thumbnails[fileId][thumbSize] = getMSFileThumbnails(driveId, fileId, thumbSize)
            .then(sizes => sizes[thumbSize]);

        return this.thumbnails[fileId][thumbSize];
    }

    /**
     * для html, содержащих img
     * в мс нет посотянного урла тамнейла, нужно каждый раз запрашивать
     * поэтому в img src добавляем #driveId=...#fileId=...
     */
    getSrcThumbnails (
        text: string,
    ): Promise<string> {
        if (!text || !this.thumbnails || !text.includes('#fileId=')) return Promise.reject();

        const thumbSize = `c${THUMB_COMMENT_SIZE}x${THUMB_COMMENT_SIZE}`;

        interface ICoord {
            start: number;
            end: number;
            fileId: string;
        }
        let coords: ICoord[] = [];
        let isImg = 0;
        let src = '';
        let coord: ICoord;
        let promises: Promise<any>[] = [];
        let thumbnails: Record<string, string> = {};

        for (let i=0; i<text.length; i++) {
            if (isImg) {
                if (isImg === 2) {
                    if (text[i] === ')') { // src end
                        isImg = 0;
                        let srcArr = src.split('#fileId=');
                        let fileId = srcArr[1];
                        srcArr = srcArr[0].split('#driveId=');
                        let driveId = srcArr[1];

                        coord.end = i;
                        coord.fileId = fileId;
                        coords.push(coord);

                        if (!thumbnails[fileId]) {
                            promises.push(
                                msFileThumbnailStorage.getThumbnail(driveId, fileId, thumbSize)
                                    .then(thumb => {
                                        thumbnails[fileId] = thumb.url + '#driveId=' + driveId + '#fileId=' + fileId;
                                    })
                            );
                        }
                    }
                    src += text[i];
                } else if (text[i] === '(') { // src begin
                    isImg = 2;
                    coord = {
                        start: i+1,
                        end: null,
                        fileId: null,
                    };
                }
            } else if (text[i] === '!' && i < text.length-2 && text[i+1] === '[') { // img
                isImg = 1;
                src = '';
            }
        }
        if (coords.length && promises.length) {
            return Promise.all(promises)
                .then(() => {
                    let textNew = text;
                    coords.reverse().forEach(coord => {
                        let srcOld = textNew.slice(coord.start, coord.end);
                        if (srcOld !== thumbnails[coord.fileId]) {
                            textNew = textNew.replaceAll(srcOld, thumbnails[coord.fileId]);
                        }
                    });
                    return textNew !== text ? textNew : Promise.reject();
                });
        }
        return Promise.reject();
    }
}

export const msFileThumbnailStorage = new MSFileThumbnail();
