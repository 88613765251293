import { IGetState, TCardId } from '../../../../../types/types';
import { IRestCardAssigneeInfo } from '../../../../../types/rest/IRestCardAssigneeInfo';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { patchRest } from '../patchRest';
import { cardAssigneesActionSetAction } from '../../../../../store/model/actions/cardAssigneesActionSetAction';
import { cardAssigneeUpdateAction } from '../../../../../store/model/cardAssignees/actions/cardAssigneeUpdateAction';
import { isObjectEmpty } from '../../../../../view/react_components/helpers/isObjectEmpty';
import { sendAuthUserRealTimeStoreAction } from '../../../../../view/react_components/base/helpers/realTimeHelperTS';
import { getCardAssignee } from '../../../../../store/model/cardAssignees/selectors/getCardAssignee';

export const cardAssigneeRestPatch = (
    cardId: TCardId,
    cardAssignee: IRestCardAssigneeInfo,
    isOptimistic: boolean = true,
    isRealtime: boolean = true
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (isObjectEmpty(cardAssignee)) return Promise.resolve();
        const state = getState();
        let cardAssigneeForRollBack: IRestCardAssigneeInfo = null;

        if (isOptimistic) {
            cardAssigneeForRollBack = getCardAssignee(state, cardId);
            dispatch(cardAssigneesActionSetAction(cardAssigneeUpdateAction(cardId, cardAssignee)));
        }

        return dispatch(patchRest(cardId, cardAssignee))
            .then((result: IRestCardAssigneeInfo) => {
                const updateAction = cardAssigneesActionSetAction(cardAssigneeUpdateAction(cardId, result));
                if (!isOptimistic) {
                    dispatch(updateAction);
                }
                if (isRealtime) {
                    dispatch(sendAuthUserRealTimeStoreAction(updateAction));
                }
            })
            .catch((e: any) => {
                console.error(e);
                if (isOptimistic) {
                    dispatch(cardAssigneesActionSetAction(cardAssigneeUpdateAction(cardId, cardAssigneeForRollBack)));
                }
            });
    };
    return action;
}
