import { IGetState, TCardId } from '../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { fetchHandler } from '../../../../../util/fetchHandler';
import { Util } from '../../../../../util/util';
import { REST_CALENDAR_EVENTS, REST_CARD } from '../../../../constants';
import { getRestHeadersPost } from '../../../../helpers/getRestHeadersHelper';
import { IRestCalendarEvent } from '../../../../../types/rest/IRestCalendarEvent';

export const postRest = (
    cardId: TCardId,
    calendarEvent: IRestCalendarEvent
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return fetchHandler<IRestCalendarEvent>(
            Util.getApiUrl(`${REST_CARD}${cardId}${REST_CALENDAR_EVENTS}`),
            {
                ...getRestHeadersPost(),
                body: JSON.stringify(calendarEvent)
            }
        )
    };
    return action;
};
