import { IGetState } from '../../../types/types';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { TGoogleDriveId } from '../../../view/react_components/dialogs/openBoards/store/types';
import { IBoard } from '../../../store/model/board/types';
import { postRest } from './api/postRest';

export const boardCreate = (
    driveId: TGoogleDriveId,
    board: IBoard
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(postRest(driveId, board));
    };
    return action;
};
