export const AT_SHOW_SET = 'COOKIE_SHOW_SET';
export const AT_SETTINGS_OPENED_SET = 'COOKIE_SETTINGS_OPENED_SET';
export const AT_PERFORMANCE_ALLOWED_SET = 'COOKIE_PERFORMANCE_ALLOWED_SET';
export const AT_NEW_PERFORMANCE_ALLOWED_VALUE_SET = 'COOKIE_NEW_PERFORMANCE_ALLOWED_VALUE_SET';
export const AT_NEW_VALUES_PERSIST = 'COOKIE_NEW_VALUES_PERSIST';

export interface ICookieDialogShowSetAction {
    type: typeof AT_SHOW_SET;
    isShow: boolean;
}

export interface ICookieDialogSettingsOpenSetAction {
    type: typeof AT_SETTINGS_OPENED_SET;
    isSettingsOpened: boolean;
}

export interface ICookieDialogPerformanceAllowedSetAction {
    type: typeof AT_PERFORMANCE_ALLOWED_SET;
    isPerformanceAllowed: boolean
}

export interface ICookieDialogNewPerformanceAllowedValueSetAction {
    type: typeof AT_NEW_PERFORMANCE_ALLOWED_VALUE_SET;
    newPerformanceAllowedValue: boolean
}

export interface ICookieDialogNewValuesPersistAction {
    type: typeof AT_NEW_VALUES_PERSIST;
}

export type TCookieDialogActions = ICookieDialogShowSetAction |
    ICookieDialogSettingsOpenSetAction |
    ICookieDialogPerformanceAllowedSetAction |
    ICookieDialogNewPerformanceAllowedValueSetAction|
    ICookieDialogNewValuesPersistAction;
