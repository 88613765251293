import { IABTestState } from '../types';
import { AT_AB_TESTS_VALUE_SET, TABTestAction } from '../actions/types';
import { getInitialState } from '../helpers/getInitialState';

const initialState = getInitialState();

export const abTestingReducer = (
    state: IABTestState = initialState,
    action: TABTestAction
): IABTestState => {
    switch (action.type) {
        case AT_AB_TESTS_VALUE_SET:
            return {
                ...state,
                ...action.abTests
            };
        default:
            return state;
    }
};
