export const PTGC_DROPDOWN_OPEN = 'PTGC_DROPDOWN_OPEN';
export const PTGC_DROPDOWN_CLOSE = 'PTGC_DROPDOWN_CLOSE';

export const PTGC_SELECT_CARD = 'PTGC_SELECT_CARD';

export const PTGC_AVAILABLE_ACTIONS = 'PTGC_AVAILABLE_ACTIONS';
export const PTGC_SELECT_ACTION = 'PTGC_SELECT_ACTION';
export const PTGC_SET_DISABLED_ACTIONS = 'PTGC_SET_DISABLED_ACTIONS';

export const PTGC_SET_SELECTED_ELEMENTS = 'PTGC_SET_SELECTED_ELEMENTS';

export const PTGC_SYNC_CALENDARS = 'PTGC_SYNC_CALENDARS';
export const PTGC_PUSH_EVENT = 'PTGC_PUSH_EVENT';
export const PTGC_REMOVE_EVENT = 'PTGC_REMOVE_EVENT';

export const PTGC_EVENT_START_DATE = "startDate";
export const PTGC_EVENT_DUE_DATE = "dueDate";
export const PTGC_EVENT_START_AND_DUE_DATE = "startAndDueDate";
// export const PTGC_EVENT_START_APART_DUE_DATE = "startApartDueDate";

const PTGC_START_DATE = 'Start date';
const PTGC_DUE_DATE = 'Due date';
const PTGC_BOTH_DATES = 'Entire period';

export const PTGC_ACTIONS_LIST = [
    {name: PTGC_START_DATE, id: PTGC_EVENT_START_DATE},
    {name: PTGC_DUE_DATE, id: PTGC_EVENT_DUE_DATE},
    {name: PTGC_BOTH_DATES, id: PTGC_EVENT_START_AND_DUE_DATE}
];