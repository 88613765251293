import { AsidePanelActionsButton } from '../../../../..';
import * as React from 'react';
import { IBoardEmailPanelButtonProps } from './types';
import { CLASS_BOARD_DETAILS_SECTION } from '../../../../constants';
import { BOARD_SECTION_EMAIL } from '../../constants';

export function BoardEmailPanelButton ({
    tooltip,
    onClick
}: IBoardEmailPanelButtonProps) {

    return (
        <AsidePanelActionsButton
            children={null}
            sectionSelector={`.${CLASS_BOARD_DETAILS_SECTION}--email`}
            title={BOARD_SECTION_EMAIL}
            tooltip={tooltip}
            onClick={onClick}
        />
    );
}
