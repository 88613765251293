import { LABEL_DAY, LABEL_MONTH, LABEL_WEEK, LABEL_YEAR } from '../wording';
import { ERecurringRepeatType } from '../../constants';
import { TRecurringRepeat } from './types';

export const RECURRING_REPEAT_TYPES: TRecurringRepeat[] = [
    {
        type: ERecurringRepeatType.DAY,
        title: LABEL_DAY
    }, {
        type: ERecurringRepeatType.WEEK,
        title: LABEL_WEEK
    }, {
        type: ERecurringRepeatType.MONTH,
        title: LABEL_MONTH
    }, {
        type: ERecurringRepeatType.YEAR,
        title: LABEL_YEAR
    }
]
