import {Button, ButtonDropdown, Icon} from 'kui';
import {IUpdatesProps} from './types';
import * as React from 'react';
import './_Updates.scss'
import { CLASS_UPDATES_PANEL } from '../../constants';
import FocusLock from 'react-focus-lock';
import { NAVIGATION_PANEL_ASIDE_CLASS } from 'app/view/react_components/main/navigationPanel/constants';
import { UPDATES_PANEL_BUTTON_FEEDBACK, UPDATES_PANEL_SLIDE_TYPES, UPDATES_PANEL_TEXT, UPDATES_PANEL_TITLE, UPDATES_PANEL_TYPES } from './constants';
import { EUpdateSlideType } from '../../types';
import ReactHtmlParser from 'react-html-parser';
import { Util } from 'app/util/util';

export function Updates ({
    dateFormat,
    updates,
    onClickFeedback,
    onOpen,
    onClose,
    onShowPopup,
    onShowTips,
}: IUpdatesProps) {
    const className = CLASS_UPDATES_PANEL;

    const [updateDates, setUpdateDates] = React.useState<string[]>([]);

    React.useEffect(() => {
        setUpdateDates(Object.keys(updates));
    }, [updates]);

    React.useEffect(() => {
        onOpen();
    }, []);

    return (
        <ButtonDropdown
            className={`${className}__button-dropdown`}
            directionVertical={'down'}
            dropdownClassName={`${NAVIGATION_PANEL_ASIDE_CLASS} `}
            notBlurClasses={[
                'kui-modal', // прежний фиче-попап
                // 'updates-tips', // новые фиче-типсы // лучше закрыть дропдаун, чтоб не загораживал топбар
            ]}
            opened
            portal
            portalId={'navigation-aside-portal'}
            onClose={onClose}
        >
            <Button
                className={`${className}__button`}
            />
            <FocusLock >
                <div
                    className={className}
                    tabIndex={0} // сюда FocusLock поставит фокус при открытии
                >
                    <div className={`${className}__header`}>
                        {UPDATES_PANEL_TITLE}

                        <Button
                            className={`${className}__feedback`}
                            variant={'icon-text'}
                            aria-label={UPDATES_PANEL_BUTTON_FEEDBACK}
                            onClick={onClickFeedback}
                        >
                            {UPDATES_PANEL_BUTTON_FEEDBACK}
                        </Button>
                        <Button
                            className={`${className}__close`}
                            variant={'icon'}
                            aria-label={'Close'}
                            onClick={onClose}
                        >
                            <Icon
                                size={24}
                                xlink="close"
                            />
                        </Button>
                    </div>
                    <div className={`${className}__body`}>
                        <div className={`${className}__text`}>
                            {UPDATES_PANEL_TEXT}
                        </div>

                        <div className={`${className}__list`}>
                            {updateDates.map(date => {
                                return (
                                    <div
                                        className={`${className}__group`}
                                        key={`date-${date}`}
                                    >
                                        <div className={`${className}__date`}>
                                            {!!date && Util.getLongDateFromString(date, 'YYYY-M-D', dateFormat)}
                                        </div>
                                        <div className={`${className}__group-body`}>
                                            {updates[date].map((update, i) => {
                                                let buttonType: EUpdateSlideType;
                                                if (update.slides && update.slides.length) {
                                                    buttonType = update.slides[0].type;
                                                }
                                                return (
                                                    <div
                                                        className={`${className}__item`}
                                                        key={`date-${date}-item-${i}`}
                                                    >
                                                        <div className={`${className}__item-header`}>
                                                            <div className={`${className}__item-title`}>
                                                                {ReactHtmlParser(update.title)}
                                                            </div>
                                                            <div className={`${className}__item-type`}>
                                                                {UPDATES_PANEL_TYPES[update.type]}
                                                            </div>
                                                        </div>
                                                        {!!update.text &&
                                                            <div className={`${className}__item-text`}>
                                                                {ReactHtmlParser(update.text)}
                                                            </div>
                                                        }
                                                        {buttonType &&
                                                            <Button
                                                                className={`${className}__item-link`}
                                                                variant={'icon-text'}
                                                                onClick={() => {
                                                                    if (buttonType === EUpdateSlideType.TIPS) {
                                                                        onShowTips(update.slides);
                                                                    } else {
                                                                        onShowPopup(update.slides);
                                                                    }
                                                                }}
                                                            >
                                                                {UPDATES_PANEL_SLIDE_TYPES[buttonType]}
                                                                {buttonType === EUpdateSlideType.TIPS && update.isNew && update.isNew() &&
                                                                    <div className={`${className}__item-link-icon-tips`}></div>
                                                                }
                                                                {buttonType === EUpdateSlideType.VIDEO &&
                                                                    <Icon className={`${className}__item-link-icon-play`} xlink={'play'} size={24} />
                                                                }
                                                            </Button>
                                                        }
                                                        {update.button &&
                                                            <Button
                                                                className={`${className}__item-link ${className}__item-link--custom`}
                                                                variant={'icon-text'}
                                                                {...update.button}
                                                            />
                                                        }
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </FocusLock>
        </ButtonDropdown>
    )
};
