import { Dispatch, ThunkAction } from '../../../types/actions';
import { IUserMetaMyWork } from '../../../types/rest/IUserMeta';
import { IAuthUser } from '../../../store/model/authUser/types';
import { authUserPatch } from './api/helper/authUserPatch';
import { TUserId } from '../../../types/rest/IRestAuthUser';

export const authUserSetMyWorkMeta = (
    userId: TUserId,
    myWork: IUserMetaMyWork
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        const userPatch: IAuthUser = {
            id: userId,
            meta: {
                myWork
            }
        };
        return dispatch(authUserPatch(userPatch));
    };
    return action;
};
