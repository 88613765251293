import * as React from 'react';
import { ITotalTimingProps } from './types';
import { TIMING_ESTIMATE, TIMING_TOTAL_SPENT } from './constants';
import './_TotalTiming.scss';
import { CLASS_CARD_DETAILS_TAB_TIMING } from '../TimingTab/constants';

export function TotalTiming({
    estimate,
    totalSpentTime,
}: ITotalTimingProps) {
    const className = CLASS_CARD_DETAILS_TAB_TIMING + '__totals';
    const classNameTotal = CLASS_CARD_DETAILS_TAB_TIMING + '__total';
    const classNameTotalTitle = CLASS_CARD_DETAILS_TAB_TIMING + '__total-title';
    const classNameTotalText = CLASS_CARD_DETAILS_TAB_TIMING + '__total-text';
    const classNameEstimate = `${classNameTotal} ${classNameTotal}--estimate`;
    const classNameSpent = `${classNameTotal} ${classNameTotal}--spent`;

    return (
        <div className={className}>
            <div className={classNameSpent}>
                <div className={classNameTotalTitle}>
                    {TIMING_TOTAL_SPENT}
                </div>
                <div className={classNameTotalText}>
                    {totalSpentTime}
                </div>
            </div>
            <div className={classNameEstimate}>
                <div className={classNameTotalTitle}>
                    {TIMING_ESTIMATE}
                </div>
                <div className={classNameTotalText}>
                    {estimate}
                </div>
            </div>
        </div>
    );
}
