import { IApplicationState } from '../../../types/types';
import { getDependencyActiveRequests } from './getDependencyActiveRequests';

export const getDependencyActiveRequestsWithDelta = (
    state: IApplicationState,
    dependencyId: number,
    activeRequestsDelta: number,
): number => {
    let activeRequests = getDependencyActiveRequests(state, dependencyId);
    activeRequests += activeRequestsDelta;
    if (activeRequests < 0) {
        activeRequests = 0;
    }
    return activeRequests;
};
