import { IGetState, TCardId } from 'app/types/types';
import { Dispatch, ThunkAction } from 'app/types/actions';
import { getCard } from 'app/store/model/selectors/getCard';
import { getCardDone } from 'app/store/model/selectors/getCardDone';
import { cardsSetDoneProcessing } from './cardsSetDoneProcessing';
import { getBoardIdByCardId } from 'app/store/model/selectors/getBoardIdByCardId';

export const cardToggleDone = (
    cardId: TCardId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const card = getCard(state, cardId);
        if (!card) return Promise.reject();

        const done = getCardDone(state, cardId);
        const boardId = getBoardIdByCardId(state, cardId);
        return dispatch(cardsSetDoneProcessing(boardId,[cardId], !done));
    };
    return action;
};
