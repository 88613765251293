import * as React from 'react';
import { IAsidePanelTitleActionProps } from './types';
import './_AsidePanelTitleAction.scss';
import { CLASS_ASIDE_PANEL_TITLE_ACTION } from './constants';

export function AsidePanelTitleAction ({
    titleAction
}: IAsidePanelTitleActionProps) {
    return (
        <div className={CLASS_ASIDE_PANEL_TITLE_ACTION}>
            {titleAction}
        </div>
    );
};
