import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { showSnoozeBlocker } from '../../../../../../../base/effects/showSnoozeBlocker';

export const onClickDisallow = (): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(showSnoozeBlocker({ allow: 'allowCardNumber' }));
    };
    return action;
};
