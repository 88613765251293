import { IGetState, TBoardId, TCardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { getBoardUsers } from '../../../../store/model/selectors/getBoardUsers';
import { cardSetAssigneesBatch } from '../card/cardSetAssignees';
import { ICardBatch } from '../card/api/types';
import { cardPatchRestBatch } from '../card/api/helper/cardPatchRestBatch';
import { getCardAssignees } from '../../../../store/model/selectors/getCardAssignees';

export const addCardAssignerBatch = (
    getState: IGetState,
    dispatch: Dispatch,
    cardId: TCardId,
    boardId: TBoardId,
    userPermissionId: string,
    cardBatch: ICardBatch = {card: {}, callbacks: []}
) => {
    const state = getState();
    const assignees = [...getCardAssignees(state, cardId)];
    const boardUsers = getBoardUsers(state, boardId);
    const assignee = boardUsers.find(assignee => assignee.permissionId === userPermissionId);
    if (!assignee) {
        return cardBatch;
    }
    assignees.push({sharedUser: assignee});
    cardSetAssigneesBatch(dispatch, cardId, assignees, cardBatch);
    return cardBatch;
};

export const addCardAssigner = (
    cardId: TCardId,
    boardId: TBoardId,
    userPermissionId: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const cardBatch = addCardAssignerBatch(getState, dispatch, cardId, boardId, userPermissionId);
        return cardPatchRestBatch(cardId, dispatch, cardBatch);
    };
    return action;
};
