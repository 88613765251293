import { IApplicationState } from '../../../../../types/types';
import { connect } from 'react-redux';
import {
    IKeyboardShortcutsOpenerEvents,
    IKeyboardShortcutsOpenerFields
} from '../../components/KeyboardShortcutsOpener/types'
import { KeyboardShortcutsOpener } from '../../components/KeyboardShortcutsOpener/KeyboardShortcutsOpener';
import { onOpen } from './events/onOpen';

const mapStateToProps = (
    state: IApplicationState
): IKeyboardShortcutsOpenerFields => {
    return {}
};

const mapDispatchToProps = (
    dispatch: any
): IKeyboardShortcutsOpenerEvents => {
    return {
        onOpen: () => dispatch(onOpen())
    }
}

export const KeyboardShortcutsOpenerHOC = connect(
    mapStateToProps,
    mapDispatchToProps)
(KeyboardShortcutsOpener);

KeyboardShortcutsOpenerHOC.displayName = 'KeyboardShortcutsOpenerHOC';
