import { IGetState, TCardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { patchDriveDocsHelper } from './api/helper/patchDriveDocsHelper';
import { getDriveDocsByCardId } from '../../../../store/model/driveDocs/selectors/getDriveDocsByCardId';

export const sortAttachments = (
    cardId: TCardId,
    docIds: number[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const driveDocs = getDriveDocsByCardId(state, cardId);
        const updatedDriveDocs = driveDocs.map((doc) => {
            return {
                ...doc,
                orderNumber: docIds.indexOf(doc.id)
            }
        });
        dispatch(patchDriveDocsHelper(cardId, updatedDriveDocs));
    };
    return action;
};
