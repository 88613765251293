import { connect } from 'react-redux';
import { IApplicationState } from 'app/types/types';
import { ICardNameHOCOwnProps } from './types';
import { getCard } from 'app/store/model/selectors/getCard';
import { ICardNameEvents, ICardNameFields } from '../../components/CardName/types';
import { CardName } from '../../components/CardName/CardName';
import { onSave } from './events/onSave';
import { onCardNumberRemove } from './events/onCardNumberRemove';
import { getBoard } from 'app/store/model/selectors/getBoardById';
import { onCardNumberGenerate } from './events/onCardNumberGenerate';
import { getSectionType } from '../../../selectors/getSectionType';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { getAsidePanelEntityPanelWidth } from '../../../../asidePanel/store/selectors/getAsidePanelEntityPanelWidth';
import { NEW_RECURRING_NAME, NEW_TEMPLATE_NAME } from '../../../../cardTemplates/CreateNew/constants';
import { markdown } from 'app/helper/markdownHelper';
import { onClick } from './events/onClick';
import { onEsc } from './events/onEsc';
import { getCardNameRename } from '../../store/cardNameReducer/selectors/getCardNameRename';
import { onRenameEnd } from './events/onRenameEnd';
import { TStatus } from '../../../../../../../types/model';
import {
    getBoardMetaDefaultTemplateCardId
} from '../../../../../../../store/model/selectors/getBoardMetaDefaultTemplateCardId';
import { onCardNumberClick } from 'app/view/react_components/aside_panel/cardDetails/Name/hocs/CardNameHOC/events/onCardNumberClick';
import { getPanelButtonShow } from '../../../selectors/getPanelButtonShow';
import { root } from '../../../../../../../store/constants';
import { onShowSnackbar } from './events/onShowSnackbar';
import { onCardNumberRemoveAll } from './events/onCardNumberRemoveAll';

const mapStateToProps = (
    state: IApplicationState,
    ownProps: ICardNameHOCOwnProps
): ICardNameFields => {
    const { cardId, boardId } = ownProps;
    const card = getCard(state, cardId);
    const name = card.name;
    const cardNumber = card.cardNumber;
    const color = card.backgroundColor;
    const { cardNumberEnabled, cardNumberPrefix, cardNumberNextValue } = getBoard(state, boardId);
    const sectionType = getSectionType(state, boardId, cardId);
    const isReadonly = sectionType === ESettingsSectionType.READONLY;
    const panelWidth = getAsidePanelEntityPanelWidth(state);
    const isDefault = getCardNameRename(state) === cardId || name === NEW_TEMPLATE_NAME || name === NEW_RECURRING_NAME;
    const isMarkdown = markdown(name) !== name;

    return {
        boardId,
        cardId,
        cardNumber,
        cardNumberEnabled: card.status !== TStatus.STATUS_SERVICE_TEMPLATE && cardNumberEnabled && !!cardNumberPrefix && !!cardNumberNextValue,
        color,
        isReadonly,
        isShowColorButton: getPanelButtonShow(state, boardId, cardId),
        name,
        panelWidth,
        isDefault,
        isDefaultTemplate: getBoardMetaDefaultTemplateCardId(state, boardId) === cardId,
        isMarkdown,
        isShowRemoveModal: root.App.controller.boardCardPrefixInfo.getCardPrefixRemoveCount() < 2
    }
};

const mapDispatchToProps = (
    dispatch: any,
    ownProps: ICardNameHOCOwnProps
): ICardNameEvents => {
    const {cardId, boardId} = ownProps;
    return {
        onSave: (name) => dispatch(onSave(cardId, name)),
        onCardNumberClick: () => dispatch(onCardNumberClick()),
        onCardNumberRemove: () => dispatch(onCardNumberRemove(cardId)),
        onCardNumberRemoveAll: () => dispatch(onCardNumberRemoveAll(boardId)),
        onCardNumberGenerate: () => dispatch(onCardNumberGenerate(cardId)),
        onClick: () => dispatch(onClick()),
        onRenameEnd: () => dispatch(onRenameEnd()),
        onEsc: () => dispatch(onEsc()),
        onShowSnackbar: () => dispatch(onShowSnackbar(boardId))
    }
};

export const CardNameHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardName);

CardNameHOC.displayName = 'CardNameHOC';
