import { Dispatch, ThunkAction } from '../../../../../../types/actions';
import { showSnoozeBlocker } from '../../../../base/effects/showSnoozeBlocker';

export const checkTabActivityPermission = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(showSnoozeBlocker({
            allow: 'allowActivity'
        }));
    };
    return action;
};
