import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../../types/actions';

export const onHelpMount: ActionCreator<ThunkAction> = () => {
    const action = (
        dispatch: Dispatch
    ) => {
        // dispatch(createFeedBackSession())
    };
    return action;
};
