import { IGetState, TCardId } from '../../../../../types/types';
import { IDriveDoc } from '../../../../../store/model/card/types/IDriveDoc';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { fetchHandler } from '../../../../../util/fetchHandler';
import { Util } from '../../../../../util/util';
import { REST_CARD } from '../../../../constants';
import { getRestHeadersPost } from '../../../../helpers/getRestHeadersHelper';

export const postRest = (
    cardId: TCardId,
    attachments: IDriveDoc[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return fetchHandler<IDriveDoc[]>(
            Util.getApiUrl(`${REST_CARD}${cardId}/drivedocs`),
            {
                ...getRestHeadersPost(),
                body: JSON.stringify(attachments)
            }
        )
    };
    return action;
};
