import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import {
    SegmentCardEvent,
    SegmentCardOptions,
    SegmentCardSection,
    segmentTrackAction
} from '../../../../../../../../middlewares/segment';
import { SegmentCardColorTagsSourceValue } from '../../../../../../../../middlewares/segment/trackEntities/cardEvents';

export const onDropdownOpen = (
    used: number,
    all: number,
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.COLOR_TAGS, {
            name: SegmentCardOptions.SOURCE,
            value: SegmentCardColorTagsSourceValue.BUTTONS_PANEL,
        }, [{
            name: 'used',
            value: used
        }, {
            name: 'all',
            value: all
        }]));
        dispatch(segmentTrackAction(SegmentCardEvent.BUTTONS_PANEL_BUTTON_CLICKED, {
            name: SegmentCardOptions.TARGET,
            value: SegmentCardSection.COLOUR_TAGS
        }));
    };
    return action;
};
