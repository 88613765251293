import { TBoardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { fetchHandler } from '../../../../util/fetchHandler';
import { Util } from '../../../../util/util';
import { REST_FIRST_BOARD } from '../../../constants';
import { getRestHeadersPost } from '../../../helpers/getRestHeadersHelper';
import { IFirstBoard } from 'app/rest/effects/firstBoard/types';

export const postRest = (
    boardId: TBoardId,
    firstBoard: IFirstBoard
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        return fetchHandler<IFirstBoard>(
            Util.getApiUrl(`${REST_FIRST_BOARD}/${boardId}`), {
                ...getRestHeadersPost(),
                body: JSON.stringify(firstBoard)
            }
        );
    };
    return action;
};
