export const isObjectEmpty = (
    object: any
): boolean => {
    for (let key in object) {
        if (object.hasOwnProperty(key)) {
            return false;
        }
    }
    return true;
};
