import { getMessages } from '../../../../../../../store/constants';
import { CLASS_COMMENTS_SECTION } from '../../constants';
import { THUMB_COMMENT_REPLY_SIZE } from '../../../../../../../const';

export const CLASS_COMMENTS_SECTION_INPUT = CLASS_COMMENTS_SECTION + '__input';
export const COMMENT_LABEL = getMessages().getText('card_form_add_comment__add');
export const COMMENT_PLACEHOLDER = getMessages().getText('card_form_comment_placeholder');
export const COMMENT_EDIT_LABEL = getMessages().getText('card_form_comment_edit_label');
export const COMMENT_NOT_TITLE_LABEL = getMessages().getText('card_form_comment_no_title');
export const COMMENTS_INPUT_GOOGLE_THUMB_SIZE = `s${THUMB_COMMENT_REPLY_SIZE}`;

export const DEFAULT_INPUT_HEIGHT = 65;

export const COMMENT_REPLY_ANIMATION_DURATION = 250; // тоже самое в _comments-input.scss;
