import {getController, getMessages} from '../../../../../store/constants';
import {ActionCreator} from 'redux';
import {Dispatch, ThunkAction} from '../../../../../types/actions';
import { TTabKey, TGoogleDriveId } from '../store/types';
import { ETemplateType } from '../store/templates/types';
import { TBoardId } from '../../../../../types/types';
import { getBoard } from '../store/boards/selectors/getBoard';
import { IGetState } from '../../../../../types/types';
import { DRIVE_ID_MY_DRIVE } from '../store/drives/constants';

export const TEMPLATES_LOAD_FAIL = getMessages().getText('ga.action.boards_list.template_load_fail');
export const TEMPLATES_EDIT = getMessages().getText('ga.action.boards_templates.edit_template');
export const TEMPLATES_CREATE = getMessages().getText('ga.action.boards_templates.new_custom_template');
export const TEMPLATES_PREVIEW = getMessages().getText('ga.action.boards_templates.template_preview');
export const TEMPLATES_USE_CUSTOM = getMessages().getText('ga.action.boards_templates.use_custom_template');
export const TEMPLATES_USE_DEFAULT = getMessages().getText('ga.action.boards_templates.use_knb_template');

export const TRELLO_CLICK = getMessages().getText('ga.action.import.click');
export const TRELLO_SUCCESS = getMessages().getText('ga.action.import.created');
export const TRICKS_CREATE = getMessages().getText('ga.action.boards_list.tricks');

export const BOARDS_LIST_LOAD_FAIL = getMessages().getText('ga.action.boards_list.load_fail');
export const BOARDS_LIST_CREATE_FAIL = getMessages().getText('ga.action.boards_list.create_fail');
export const BOARDS_LIST_DELETE = getMessages().getText('ga.action.dashboard.delete');
export const BOARDS_LIST_BOARDS_OPENED = getMessages().getText('ga.action.boards_list.opened');
export const BOARDS_LIST_CREATED_WITHIN_SHARED_DRIVE = getMessages().getText('ga.action.boards_list.created_within_shared_drive');
export const BOARDS_LIST_MOVED_BETWEEN_SHARED_DRIVES = getMessages().getText('ga.action.boards_list.moved_between_shared_drives');
export const BOARDS_LIST_MOVED_FROM_MY_TO_SHARED_DRIVE = getMessages().getText('ga.action.boards_list.moved_from_my_to_shared_drive');
export const BOARDS_LIST_MOVED_FROM_SHARED_DRIVE_TO_MY = getMessages().getText('ga.action.boards_list.moved_from_shared_drive_to_my');

export const boardsListGoogleAnalyticsEvents: ActionCreator<ThunkAction> = (
    action: string
) => (dispatch: Dispatch) => {
    getController().trackEvent(
        getMessages().getText('ga.category.boards_list'),
        action,
    )
};

export const boardsTemplatesGoogleAnalyticsEvents: ActionCreator<ThunkAction> = (
    action: string
) => (dispatch: Dispatch) => {
    getController().trackEvent(
        getMessages().getText('ga.category.boards_templates'),
        action,
    )
};

export const tabsGoogleAnalyticsEvents: ActionCreator<ThunkAction> = (
    tabKey: TTabKey,
    action: string
) => (dispatch: Dispatch) => {
    switch (tabKey) {
        case TTabKey.templates:
            return dispatch(boardsTemplatesGoogleAnalyticsEvents(action));
        default:
            return dispatch(boardsListGoogleAnalyticsEvents(action));
    }
};

export const useTemplateGoogleAnalyticsEvents: ActionCreator<ThunkAction> = (
    templateType: ETemplateType
) => (dispatch: Dispatch) => {
    switch (templateType) {
        case ETemplateType.custom:
            return dispatch(boardsTemplatesGoogleAnalyticsEvents(TEMPLATES_USE_CUSTOM));
        default:
            return dispatch(boardsTemplatesGoogleAnalyticsEvents(TEMPLATES_USE_DEFAULT));
    }
};

export const createBoardGoogleAnalyticsEvents: ActionCreator<ThunkAction> = (
    driveId: TGoogleDriveId
) => {
    const action = (dispatch: Dispatch) => {
        const isMyDrive = driveId === DRIVE_ID_MY_DRIVE;

        if (!isMyDrive) {
            return dispatch(boardsListGoogleAnalyticsEvents(BOARDS_LIST_CREATED_WITHIN_SHARED_DRIVE));
        }
    }
    return action;
};

export const moveBoardGoogleAnalyticsEvents: ActionCreator<ThunkAction> = (
    boardId: TBoardId,
    driveId: TGoogleDriveId
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const board = getBoard(getState(), boardId);
        const isFromMyDrive = board.driveId === DRIVE_ID_MY_DRIVE;
        const isToMyDrive = driveId === DRIVE_ID_MY_DRIVE;

        if (
            isFromMyDrive &&
            !isToMyDrive
        ) {
            return dispatch(boardsListGoogleAnalyticsEvents(BOARDS_LIST_MOVED_FROM_MY_TO_SHARED_DRIVE));
        }
        if (
            !isFromMyDrive &&
            isToMyDrive
        ) {
            return dispatch(boardsListGoogleAnalyticsEvents(BOARDS_LIST_MOVED_FROM_SHARED_DRIVE_TO_MY));
        }
        if (
            !isFromMyDrive &&
            !isToMyDrive
        ) {
            return dispatch(boardsListGoogleAnalyticsEvents(BOARDS_LIST_MOVED_BETWEEN_SHARED_DRIVES));
        }
    }
    return action;
};
