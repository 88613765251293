import { TCondition } from '../types';
import { IApplicationState } from '../../../../types/types';
import { EHintKey, getHintConfig } from '../constants';
import { getEditCardId } from '../../../../store/router/selectors/getEditCardId';
import { getAsidePanelIsMobile } from '../../aside_panel/asidePanel/store/selectors/getAsidePanelIsMobile';
import { getHintCanShow } from '../store/hint/selectors/getHintCanShow';

export const defaultCardTimeCondition: TCondition = (
    state: IApplicationState
) => {
    const hint = getHintConfig(EHintKey.DEFAULT_CARD_TIME);
    if (!hint) return false;

    if (!getEditCardId(state)) return false; // хинт в cardDetails

    if (getAsidePanelIsMobile(state)) return false; // на мобильном хинт плохо видно

    return !!getHintCanShow(state, EHintKey.DEFAULT_CARD_TIME);
};
