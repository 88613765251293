'use strict';
import store, { dispatch, getAppState } from '../store/configureStore';
import { getMessages, root, vent } from '../store/constants';
import * as Backbone from 'backbone';
import * as blockerSegmentEvents from '../middlewares/segment/helpers/blockersSegmentEvents';
import { ESegmentBlockerType } from '../middlewares/segment/trackEntities/blockersEvents';
import { setSwitcherBoardType } from '../view/react_components/typeSwitcher/effects/setSwitcherBoardType';
import { EViewTypes } from '../const';
import { isFreeVersionSelector } from '../view/react_components/base/selectors/isFreeVersionSelector';
import { isPwc } from './../store/model/authUser/selectors/isPwc';
import { getAuthUser } from '../store/model/authUser/selectors/getAuthUser';
import { getUserDomain } from '../store/model/authUser/selectors/getUserDomain';
import { EAuthUserAccountType } from '../types/rest/IRestAuthUser';
import { isUserDomainWide } from '../store/model/authUser/selectors/isUserDomainWide';
import { getActiveBoard } from '../store/model/selectors/getActiveBoard';
import { showSnoozeBlocker } from '../view/react_components/base/effects/showSnoozeBlocker';
import { IAuthUser } from 'app/store/model/authUser/types';
//import * as Backbone from 'backbone'; cannot install backbone & backbone-relation from package.json . No required version of backbone-relation

// export const PERSONAL_BLOCK_DATE = 1569873600000; //01-10-2019
export const PERSONAL_BLOCK_DATE = 1571083200000; //15-10-2019

export const WHITELISTED_DOMAIN = [
    'google.com',
    'moeskops.info'
];

// @ts-ignore
export class BlockerHelper extends Backbone.View {
    getAuthUser() {
        return getAuthUser(getAppState());
    };

    allowArchive() {
        if (!this.getAuthUser().allowArchive) {
            dispatch(showSnoozeBlocker({
                allow: 'allowArchive'
            }));
            return false;
        }
        return true;
    };

    checkBlockAndRun (callback: any) {
        if (this.isUserBlocked()) {
            vent.trigger(vent['dashboards:loaded']);
            this.blockInterface();
        } else {
            callback();
        }
    };

    /**
     * true - для того чтоб бы заблокировать
     * Звучит как:
     * 1) если у пользователя нет подписок и не допущен по использованию канбанка по доменному признаку
     * 1.1) если пользователь, с лимитом по кол-ву карт, использовал первый триал и не выбрал хотел ли он использовать еще триал или будет сидеть на free версии
     * заблокировать
     * @returns {boolean}
     */
    isUserBlocked () {
        const thisSessionReadOnly = root.App.controller.allowReadOnly;
        const isFreeVersion = isFreeVersionSelector();
        if (!this.isUserHaveSubscription() && !this.isUserGrantedByDomain() && !thisSessionReadOnly){
            if (App.Helpers.PaypalHelper.isUserAllowFreeVersion()) { // пользователь, с лимитом по кол-ву карт
                if (!App.Helpers.PaypalHelper.isUserHaveFirstTrialCode() && App.Helpers.PaypalHelper.isUserHaveSecondTrialCode()) { //использовал первый триал
                    if (!App.Helpers.PaypalHelper.isUserChoiceFreeVersionAfterFirstTrial()) { //не выбрал хотел ли он использовать еще триал или будет сидеть на free версии
                        return true;
                    } else {
                        return false;
                    }
                } else { //после второго триала влючается free версия
                    return false;
                }
            }
            return true;
        }
        return false;
    };

    /**
     * Возвращает true если у пользователя есть платный способ войти в аккаунт, а именно:
     * 1) Оплаченная Активная подписка, 2) Домен вайд подписка,  3) Триал подписка
     * @returns {boolean}
     */
    isUserHaveSubscription () {
        return (
            this.isUserHavePaidSubscription() ||
            this.isUserDomainWide() ||
            this.isUserHaveActiveTrial()
        );
    };

    /**
     * Возвращаем true - если надо дать доступ пользователю, потому что он может зайти благодоря доменной
     * принадлежности,
     * 1) едушка, 2) старый фри аккаунт 3) WhiteList доменов
     * @returns {*}
     */
    isUserGrantedByDomain () {
        return (
            this.isUseInEduDomain() ||
            this.isUserGrantedByFreeMail() ||
            this.isUserInWhiteListedDomain()
        );
    };

    /**
     * Дословно метод означает если "пользовтель фри" И "создан ранее чем дата блокировки" - дать доступ
     * @returns {boolean}
     */
    isUserGrantedByFreeMail () {
        const createDateTimeStamp = this.getAuthUser().createDateTimeStamp;
        return Boolean(this.isUserInPersonalDomain() && createDateTimeStamp < ( PERSONAL_BLOCK_DATE / 1000 ));
    };

    isUserInWhiteListedDomain () {
        const userDomain = getUserDomain(getAppState());
        return WHITELISTED_DOMAIN.includes(userDomain);
    };

    isUserInPersonalDomain () {
        return this.getAuthUser().accountType === EAuthUserAccountType.INDIVIDUAL_ACCOUNT_TYPE;
    };

    isUseInEduDomain () {
        return this.getAuthUser().accountType === EAuthUserAccountType.EDU_ACCOUNT_TYPE;
    };

    /**
     * return true - if need block
     */
    isUserHavePaidSubscription () {
        return App.Helpers.PaypalHelper.isUserHavePaidSubscription();
    };

    isUserDomainWide () {
        return isUserDomainWide(getAppState());
    };

    isUserHaveActiveTrial () {
        return App.Helpers.PaypalHelper.isUserHaveActiveTrial();
    };

    getLastSubscriptionMessage () {
        const lastSubscription = App.Helpers.PaypalHelper.getUserExpiredSubscription()[0];
        if (!lastSubscription) return '';

        const seats =  - lastSubscription.seatsAvailable;
        const messageKeys = [
            'dialog.blocker.renew.seat',
            'dialog.blocker.renew.seats'
        ];
        const lastSubscriptionMessage = getMessages().getPluralByKeys(
            lastSubscription.seatsCount,
            messageKeys,
            null,
            lastSubscription.seatsCount,
            getMessages().getText('common.' + App.Helpers.PaypalHelper.getPlanNameByProductId(lastSubscription.productId))
        );
        return lastSubscriptionMessage;
    };

    getLastManagerFullName () {
        const lastManager = this.getAuthUser().lastManager;
        if (!lastManager) return '';

        const lastManagerFullName = (lastManager) ? '<br><b>' + lastManager.fullName + '</b>' : '';
        return lastManagerFullName;
    };

    blockInterface () {
        const isUserBlocked = this.isUserBlocked();
        if (!isUserBlocked) return; // 1 user blocked

        if (isPwc(getAppState())) {
            this.showBlocker(this.BLOCKERS.PWC_AFTER_TRIAL);
            return;
        }

        const isUserManager = App.Helpers.PaypalHelper.getOwnSubscriptions().length;
        const isUserHasNoActiveChargeBeeSubscriptions = !App.Helpers.PaypalHelper.getChargeBeeSubscriptions().length;
        const isUserOwnSubscriptionExpired = App.Helpers.PaypalHelper.getUserExpiredSubscription().length;
        const isUserHasManager = App.Helpers.PaypalHelper.isUserHasManager();

        // todo Danya remove after tests
        // this.getLastSubscriptionMessage = () => 'LAST MESSAGE';
        // this.getLastManagerFullName = () => 'LAST MANAGER';

        // this.showBlocker(this.BLOCKERS.AFTER_FIRST_TRIAL);
        // this.showBlocker(this.BLOCKERS.AFTER_SECOND_TRIAL);
        // this.showBlocker(this.BLOCKERS.ASK_MANAGER);
        // this.showBlocker(this.BLOCKERS.OWN_SUBSCRIPTION_DELETED);
        // this.showBlocker(this.BLOCKERS.OWN_SUBSCRIPTION_EXPIRED);
        // return;

        if (isUserManager && !App.Helpers.PaypalHelper.isUserAllowFreeVersion()) { // 2 user manager
            if (isUserHasNoActiveChargeBeeSubscriptions) {
                if (isUserOwnSubscriptionExpired) { // 3 own subscription expired
                    this.showBlocker(this.BLOCKERS.OWN_SUBSCRIPTION_EXPIRED);
                    dispatch(blockerSegmentEvents.blockerShownSegmentEvent(ESegmentBlockerType.OWN_SUBSCRIPTION_EXPIRED));
                } else { // user deleted him self from own subscription
                    this.showBlocker(this.BLOCKERS.OWN_SUBSCRIPTION_DELETED);
                    dispatch(blockerSegmentEvents.blockerShownSegmentEvent(ESegmentBlockerType.OWN_SUBSCRIPTION_DELETED));
                }
            }
        } else if (isUserHasManager && !App.Helpers.PaypalHelper.isUserAllowFreeVersion()) { // 4 ask manager
            this.showBlocker(this.BLOCKERS.ASK_MANAGER);
            dispatch(blockerSegmentEvents.blockerShownSegmentEvent(ESegmentBlockerType.ASK_MANAGER));
        } else { //5 buy by yourself
            if (App.Helpers.PaypalHelper.isUserHaveSecondTrialCode()) {
                if (App.Helpers.PaypalHelper.isUserAllowFreeVersion()){
                    this.showBlocker(this.BLOCKERS.AFTER_FIRST_TRIAL_FREE_VERSION);
                } else {
                    this.showBlocker(this.BLOCKERS.AFTER_FIRST_TRIAL);
                }
                dispatch(blockerSegmentEvents.blockerShownSegmentEvent(ESegmentBlockerType.AFTER_FIRST_TRIAL));
            } else {
                this.showBlocker(this.BLOCKERS.AFTER_SECOND_TRIAL);
                dispatch(blockerSegmentEvents.blockerShownSegmentEvent(ESegmentBlockerType.AFTER_SECOND_TRIAL));
            }
        }
    };

    showBlocker (options: IBlockerOptions) {
        root.App.controller.secondPanel.showBlocker(options);
    };

    showBlockerPWCTrial () {
        root.App.controller.secondPanel.showBlocker(this.BLOCKERS.PWC_TRIAL, true);
    };

    static backToKanban () {
        const board = getActiveBoard(store.getState());
        if (!board || !board.id) return;

        const kanbanType = EViewTypes.KANBAN_VIEW_TYPE;
        root.App.controller.getDashboardViewManager(board).setViewType(kanbanType);
        dispatch(setSwitcherBoardType(board.id, kanbanType));
        vent.trigger(vent['dashboard:viewTypeChanged'], board, true);
        vent.trigger(vent['secondPanel:close']);
    };

    remove () {
        vent.off(null, null, this);
        // @ts-ignore
        return Backbone.View.prototype.remove.call(this);
    };

    BLOCKERS = {
        OWN_SUBSCRIPTION_EXPIRED: {
            title: getMessages().getText('dialog.blocker-expired.title'),
            text: getMessages().getText('dialog.blocker-expired.text'),
            questions: true,
            buttonSecondary: this.getLastSubscriptionMessage(),
            onClose: () => {
                root.App.controller.openSubscriptionManagement();
                dispatch(blockerSegmentEvents.renewSeatsClickedSegmentEvent(ESegmentBlockerType.OWN_SUBSCRIPTION_EXPIRED));
            },
            onClickButton: () => {
                root.App.controller.showPayment();
                dispatch(blockerSegmentEvents.gotoPricingClickedSegmentEvent(ESegmentBlockerType.OWN_SUBSCRIPTION_EXPIRED));
            },
            readonlyButton: true,
            onClickReadonlyButton: () => {
                dispatch(blockerSegmentEvents.readonlyClickedSegmentEvent(ESegmentBlockerType.AFTER_FIRST_TRIAL));
            },
            allow: 'activeSubscription',
            additionalClass: 'dialog-blocker--link-arrow'
        } as IBlockerOptions,
        OWN_SUBSCRIPTION_DELETED: {
            title: getMessages().getText('dialog.blocker-noactive.title'),
            text: getMessages().getText('dialog.blocker-noactive.text'),
            questions: true,
            button: getMessages().getText('dialog.blocker.button-manage'),
            onClickButton: () => {
                root.App.controller.openSubscriptionManagement();
                dispatch(blockerSegmentEvents.manageSubscriptionsClickedSegmentEvent(ESegmentBlockerType.OWN_SUBSCRIPTION_DELETED));
            },
            onClickLinkContactUs: () => {
                dispatch(blockerSegmentEvents.contactUsClickedSegmentEvent(ESegmentBlockerType.OWN_SUBSCRIPTION_DELETED));
            },
            readonlyButton: true,
            onClickReadonlyButton: () => {
                dispatch(blockerSegmentEvents.readonlyClickedSegmentEvent(ESegmentBlockerType.AFTER_FIRST_TRIAL));
            },
            allow: 'activeSubscription'
        } as IBlockerOptions,
        ASK_MANAGER: {
            title: getMessages().getText('dialog.blocker-expired.title'),
            text: getMessages().getText('dialog.blocker-has-admin.text', null, this.getLastManagerFullName()),
            buttonSecondary: getMessages().getText('dialog.blocker.contact_us'),
            onClose: () => {
                root.App.controller.showHelpPopup();
                dispatch(blockerSegmentEvents.contactUsClickedSegmentEvent(ESegmentBlockerType.ASK_MANAGER));
            },
            onClickButton: () => {
                root.App.controller.showPayment();
                dispatch(blockerSegmentEvents.gotoPricingClickedSegmentEvent(ESegmentBlockerType.ASK_MANAGER));
            },
            readonlyButton: true,
            onClickReadonlyButton: () => {
                dispatch(blockerSegmentEvents.readonlyClickedSegmentEvent(ESegmentBlockerType.AFTER_FIRST_TRIAL));
            },
            allow: 'activeSubscription'
        } as IBlockerOptions,
        AFTER_FIRST_TRIAL: {
            text: getMessages().getText('dialog.blocker-after-first-trial.text'),
            button: getMessages().getText('dialog.blocker.button-upgrade'),
            readonlyButton: true,
            onClickReadonlyButton: () => {
                dispatch(blockerSegmentEvents.readonlyClickedSegmentEvent(ESegmentBlockerType.AFTER_FIRST_TRIAL));
            },
            onClickButton: () => {
                root.App.controller.showPayment();
                dispatch(blockerSegmentEvents.upgradeNowClickedSegmentEvent(ESegmentBlockerType.AFTER_FIRST_TRIAL));
            },
            allow: 'activeSubscription',
            buttonTrial: true,
        } as IBlockerOptions,
        AFTER_FIRST_TRIAL_FREE_VERSION: {
            text: getMessages().getText('dialog.blocker-after-first-trial.text'),
            button: getMessages().getText('dialog.blocker.button-upgrade'),
            freeVersionButton: true,
            onClickFreeVersionButton: () => {
                dispatch(blockerSegmentEvents.freeVersionClickedSegmentEvent(ESegmentBlockerType.AFTER_FIRST_TRIAL));
            },
            onClickButton: () => {
                root.App.controller.showPayment();
                dispatch(blockerSegmentEvents.upgradeNowClickedSegmentEvent(ESegmentBlockerType.AFTER_FIRST_TRIAL));
            },
            allow: 'activeSubscription',
            buttonTrial: true,
        } as IBlockerOptions,
        AFTER_SECOND_TRIAL: {
            buttonSecondary: getMessages().getText('dialog.blocker.contact_us'),
            button: getMessages().getText('dialog.blocker.button-upgrade'),
            onClose: () => {
                root.App.controller.showHelpPopup();
                dispatch(blockerSegmentEvents.contactUsClickedSegmentEvent(ESegmentBlockerType.AFTER_SECOND_TRIAL));
            },
            onClickButton: () => {
                root.App.controller.showPayment();
                dispatch(blockerSegmentEvents.upgradeNowClickedSegmentEvent(ESegmentBlockerType.AFTER_SECOND_TRIAL));
            },
            readonlyButton: true,
            onClickReadonlyButton: () => {
                dispatch(blockerSegmentEvents.readonlyClickedSegmentEvent(ESegmentBlockerType.AFTER_FIRST_TRIAL));
            },
            allow: 'activeSubscription',
        } as IBlockerOptions,
        PWC_TRIAL: {
            title: 'Your 30-days trial has started!',
            text: 'You are on the Enterprise plan trial. Consider requesting a full license during your trial to ensure continued access to Kanbanchi.',
            button: getMessages().getText('common.request_license'),
            buttonSecondary: getMessages().getText('common.ok'),
            onClose: () => {
                root.App.controller.secondPanel.hide();
            },
            onClickButton: () => {
                root.App.controller.showPayment();
            },
        } as IBlockerOptions,
        PWC_AFTER_TRIAL: {
            title: 'Your trial is over',
            text: '<p>Dear User,</p>' +
                '<p>Kanbanchi licenses are locally managed by each territory. If you would like to get a Kanbanchi license, please visit the PwC IPS website below for more details.</p>' +
                '<p><a href="https://sites.google.com/pwc.com/g-suite-ips/kanbanchi/getting-license" target="_blank">https://sites.google.com/pwc.com/g-suite-ips/kanbanchi/getting-license</a></p>' +
                '<p>Thank you!</p>',
            freeVersionButton: true,
            button: '',
        } as IBlockerOptions,

    };
}

export interface IBlockerOptions {
    additionalClass?: string;
    allow?: keyof IAuthUser;
    button?: string;
    buttonTrial?: boolean;
    close?: boolean;
    contact_us?: string;
    buttonSecondary?: string;
    questions?: boolean;
    subtitle?: string;
    text?: string;
    title?: string;
    onClickButton?: () => void;
    onClose?: () => void;
    onClickLinkContactUs?: () => void;
}
