import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { snackbarErrorReload } from '../../../../../snackbarsStack';
import { TStatus } from '../../../../../../../types/model';
import { onBoardDeleted } from './onBoardDeleted';
import { TBoardId } from '../../../../../../../types/types';
import { blockerSet } from '../../../store/common/actions/blockerSet';
import { TSnackbarAction } from '../types';
import { closeDeletedBoard } from './closeDeletedBoard';
import { boardSetStatus } from '../../../../../../../rest/effects/board/boardSetStatus';

export const deleteBoard = (
    boardId: TBoardId,
    afterDelete: TSnackbarAction
): ThunkAction => {
    const action = (dispatch: Dispatch) => {
        dispatch(blockerSet(true));
        dispatch(closeDeletedBoard(boardId));
        return dispatch(boardSetStatus(boardId, TStatus.STATUS_DELETED))
            .then(() => {
                dispatch(onBoardDeleted(boardId, afterDelete));
            })
            .catch(() => {
                dispatch(snackbarErrorReload());
            })
            .finally(() => {
                dispatch(blockerSet(false));
            })
    };
    return action;
};
