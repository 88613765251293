import { Dispatch, ThunkAction } from 'app/types/actions';
import { IGetState } from '../../../../../../types/types';
import { closeUpdatesPanel } from 'app/view/react_components/base/effects/closeUpdatesPanel';

export function onClose (
): ThunkAction {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        dispatch(closeUpdatesPanel());
    };
    return action
};
