import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../types/types';
import { fetchHandler } from '../../../../../util/fetchHandler';
import { REST_CARD } from '../../../../constants';
import { getRestHeadersGet } from '../../../../helpers/getRestHeadersHelper';
import { Util } from '../../../../../util/util';
import { IRestCard } from '../../../../../types/rest/IRestCard';

export const getRest = (
    cardId: TCardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return fetchHandler<IRestCard>(
            Util.getApiUrl(
                REST_CARD + cardId),
                getRestHeadersGet()
        );
    };
    return action;
};
