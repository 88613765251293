'use strict';

App.Models.NotificationMove = App.Models.Notification.extend({
    classKey: 'notificationMove',
    defaults: {
        modelType: 'card',
        actionType: 'move',
        seen: false
    },


    initialize: function(options) {
        this.cardName = options.cardName;
    },

    getNotificationModel: function() {
        return this.attributes;
    },

    // @Override
    triggerLocalChange: function() {
    },

    triggerLocalChangeWithCards: function() {
    }
});
