import { IApplicationState } from '../../../../types/types';
import { SnackbarId } from '../store/types';
import { snackbarTopSelector } from './snackbarTopSelector';

export const snackbarTopIdRenderedSelector = (
    state: IApplicationState
): SnackbarId => {
    const snackbar = snackbarTopSelector(state);
    return state.snackbarsStack.isSuspended
        ? undefined
        : snackbar ? snackbar.id : undefined;
}
