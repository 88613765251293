import * as React from 'react';
import { IAsidePanelActionsSectionProps } from './types';
import './_AsidePanelActionsSection.scss';
import {
    CLASS_ASIDE_PANEL_ACTIONS_SECTION_TITLE
} from './constants';

export function AsidePanelActionsSection ({
    children,
    className,
    isShow,
    title,
    titleButton,
}: IAsidePanelActionsSectionProps) {
    if (isShow === false) return null;

    return (
        <div className={`
            aside-panel__actions-section
            ${className || ''}
        `}>
            <div className={CLASS_ASIDE_PANEL_ACTIONS_SECTION_TITLE}>
                {title}
                {titleButton}
            </div>
            <div className={'aside-panel__actions-section-body'}>
                {children}
            </div>
        </div>
    );
};
