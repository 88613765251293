import { connect } from 'react-redux';
import { ITemplatesSearchSelectHOCProps } from './types';
import { TemplatesSearchSelect } from '../components/TemplatesSearchSelect/TemplatesSearchSelect';
import { ITemplatesSearchSelectEvents, ITemplatesSearchSelectFields } from '../components/TemplatesSearchSelect/types';
import { IApplicationState } from '../../../../../../types/types';
import { getBoardCardTemplateIdsSelector } from '../../list/selectors/getBoardCardTemplateIds';
import { getCardFullNameHelper } from '../../../../../../store/model/card/helpers/getCardFullNameHelper';
import { stripHtml } from '../../../../base/helpers/stripHtmlHelper';
import { getCard } from '../../../../../../store/model/selectors/getCard';
import { markdown } from '../../../../../../helper/markdownHelper';
import { onSelect } from './events/onSelect';

const mapStateToProps = (
    state: IApplicationState,
    { boardId, isRecurring }: ITemplatesSearchSelectHOCProps
): ITemplatesSearchSelectFields => {
    const cardIds = getBoardCardTemplateIdsSelector(state, { boardId, isRecurring });
    const cards = cardIds.map((cardId) => {
        const card = getCard(state, cardId);
        const cardFullName = getCardFullNameHelper(card);
        return {
            value: String(cardId),
            text: stripHtml(markdown(cardFullName)),
            img: 'card',
        }
    });

    return {
        cards
    };
}

const mapDispatchToProps = (
    dispatch: any,
    {}: ITemplatesSearchSelectHOCProps
): ITemplatesSearchSelectEvents => {
    return {
        onSelect: (cardId) => dispatch(onSelect(cardId))
    };
}

export const TemplatesSearchSelectHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(TemplatesSearchSelect);

TemplatesSearchSelectHOC.displayName = 'TemplatesSearchSelectHOC';
