import { Dispatch, ThunkAction } from '../../../../types/actions';
import { getCard } from '../../../../store/model/selectors/getCard';
import { IGetState, TCardId, TColorId } from '../../../../types/types';
import { ICardBatch } from './api/types';
import { cardPatchRestBatch } from './api/helper/cardPatchRestBatch';

export const cardSaveColorTagsBatch = (
    getState: IGetState,
    cardId: TCardId,
    colorIds: TColorId[],
    cardBatch: ICardBatch = {card: {}, callbacks: []}
) => {
    const state = getState();
    const card = getCard(state, cardId);
    if (!card) return cardBatch;

    cardBatch.card = {
        ...cardBatch.card,
        colorIds: colorIds,
    }
    return cardBatch;
};

export const cardSaveColorTags = (
    cardId: TCardId,
    colorIds: TColorId[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const cardBatch = cardSaveColorTagsBatch(getState, cardId, colorIds);
        return cardPatchRestBatch(cardId, dispatch, cardBatch);
    };
    return action;
};
