import {IApplicationState, TBoardId} from '../../../../types/types';

export const getIsMultiselect = (
    state: IApplicationState,
    boardId: TBoardId,
): boolean => {
    if (!state.clickManager[boardId]) return false;

    return  state.clickManager[boardId].isMultiselect;
};
