import * as React from 'react';
import { ButtonTitle, Icon } from 'kui';
import { IAddNotificationProps } from './types';
import './_AddNotification.scss';
import { CARD_DATES_BUTTON_NOTIFY } from '../../../../DatesSection/components/CardDatesSection/constants';
import { CardDatesButton } from '../../../../DatesSection/components/CardDatesButton/CardDatesButton';
import { CLASS_CARD_DETAILS } from '../../../../constants';
import { ProFeatureIndicator } from '../../../../../../base/components/ProFeatureIndicator/ProFeatureIndicator';
import { BasicFeatureIndicator } from '../../../../../../base/components/BasicFeatureIndicator/BasicFeatureIndicator';

export const AddNotification: React.FC<IAddNotificationProps> = (
    {
        isBasicFeature,
        isProFeature,
        isShowTitle,
        onAddBellClicked,
    },
) => {
    const CLASS_CARD_DATES = CLASS_CARD_DETAILS + '-dates';
    const classNameNotifyBell = CLASS_CARD_DATES + '__notify-bell';
    const classNameButtonNotify = CLASS_CARD_DATES + '__button-notify';

    return (
        <div
            className={`
                ${classNameNotifyBell}
                ${isProFeature || isBasicFeature ? classNameNotifyBell + '--indicator' : ''}
            `}
        >
            <CardDatesButton
                className={ classNameButtonNotify }
                onClick={ onAddBellClicked }
                variant={!isShowTitle ? 'icon' : null}
            >
                {isShowTitle &&
                    <ButtonTitle>
                        { CARD_DATES_BUTTON_NOTIFY }
                    </ButtonTitle>
                }
                <Icon xlink={ 'add-notification' } size={ 24 }/>
                {isProFeature && <ProFeatureIndicator />}
                {isBasicFeature && <BasicFeatureIndicator />}
            </CardDatesButton>
        </div>
    );
};
