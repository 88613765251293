import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../../types/types';
import {
    SegmentFilterEvent,
    SegmentFilterOption,
    SegmentFilterOptionStatusValue
} from '../../../../../../../../../middlewares/segment/trackEntities/filterEvents';
import {
    filterSegmentTrackAction
} from '../../../../../../../aside_panel/filterPanelBoard/effects/filterSegmentTrackAction';
import { getMyWorkShowBoards } from '../../../../../store/selectors/getMyWorkShowBoards';
import { MY_WORK_FILTER_BOARD_ID } from '../../../../../../../aside_panel/filterPanelBoard/constants';
import { myWorkShowBoardsSet } from '../../../../../store/actions/myWorkShowBoardsSet';
import { updateMyWorkSelectedBoards } from '../../../../../effects/updateMyWorkSelectedBoards';

export const onSelect = (
    value: string,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        const state = getState();
        const boardId = parseInt(value);
        const boardIds = getMyWorkShowBoards(state);
        const index = boardIds.indexOf(boardId);
        const newBoardIds = index === -1 ? [...boardIds, boardId] : boardIds.filter(id => id !== boardId);
        dispatch(updateMyWorkSelectedBoards(boardId, index === -1));
        dispatch(myWorkShowBoardsSet(newBoardIds));
        dispatch(filterSegmentTrackAction(SegmentFilterEvent.FILTER_BOARD_TOGGLE, MY_WORK_FILTER_BOARD_ID, {
            name: SegmentFilterOption.STATUS,
            value: index === -1 ? SegmentFilterOptionStatusValue.ON : SegmentFilterOptionStatusValue.OFF
        }));
    };
    return action;
};
