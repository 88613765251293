import { IGetState, TBoardId } from '../../../types/types';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { IColor, TStatus } from '../../../types/model';
import { getActiveCardId } from '../../../store/router/selectors/getActiveCardId';
import { boardActiveRequestsUpdate } from '../../../store/requestsState/effects/boardActiveRequestsUpdate';
import { cardsActiveRequestsUpdate } from '../../../store/requestsState/effects/cardsActiveRequestsUpdate';
import { ICards } from '../../../store/model/cards/types';
import { getBoardColors } from '../../../store/model/board/selectors/getBoardColors';
import { ORDER_STEP } from '../../../const';
import { boardColorPostRest } from './api/helpers/boardColorPostRest';

export const boardColorAdd = (
    boardId: TBoardId,
    colorTag: IColor,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const { status } = colorTag;
        const firstItem = getBoardColors(getState(), boardId)[0];
        colorTag = {
            ...colorTag,
            dashboardId: boardId,
            orderNumber: firstItem && (firstItem.orderNumber - ORDER_STEP) || 0,
            status: status ? status : TStatus.STATUS_ACTIVE
        }

        const cardId = getActiveCardId(getState());
        if (cardId) {
            dispatch(cardsActiveRequestsUpdate({ [cardId]: {} } as ICards, 1));
        } else {
            dispatch(boardActiveRequestsUpdate(boardId, 1));
        }

        return dispatch(boardColorPostRest(colorTag))
            .finally(() => {
                if (cardId) {
                    dispatch(cardsActiveRequestsUpdate({ [cardId]: {} } as ICards, -1));
                } else {
                    dispatch(boardActiveRequestsUpdate(boardId, -1));
                }
            });
    };
    return action;
}
