import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { IGetState } from '../../../../../types/types';
import { timeTrackerDeleteAction } from '../../../aside_panel/cardDetails/TabTimingSection/TimeTracker/store/actions/timeTrackerDeleteAction';

export const resetTimeTracker = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(timeTrackerDeleteAction());
    };
    return action;
};
