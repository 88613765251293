import { root } from '../../../../../../../store/constants';
import { Dispatch, ThunkAction } from '../../../../../../../types/actions';

export const onClickAccessibility = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        root.App.controller.openAccessibilityStatement();
    };
    return action;
};
