import { AT_LISTS_SET } from './constants';
import { IMatchListsSetAction } from './types';
import { TFilterMatchLists } from '../types';

export const matchListsSetAction = (
    lists: TFilterMatchLists
): IMatchListsSetAction => ({
        type: AT_LISTS_SET,
        lists
    }
);
