import {
    IUpgradePlanButtonDispatchProps,
    IUpgradePlanButtonStateProps
} from '../../components/upgradePlanButton/types';
import { connect } from 'react-redux';
import { onClickPlanUpgrade } from './events/onClickPlanUpgrade';
import { isUpgradeButtonShownSelector } from '../selectors/isUpgradeButtonShownSelector';
import {
    ABUpgradePlanButtonHOC
} from '../../../../../../abtesting/abTests/upgradeButtonColor/hocs/ABUpgradeButtonHOC/ABUpgradeButtonHOC';
import { IApplicationState } from '../../../../../../types/types';
import { getUpgradeMessage } from '../../helpers/getUpgradeMessage';
import { getButtonText } from '../../helpers/getButtonText';
import { isFreeVersionSelector } from '../../../../base/selectors/isFreeVersionSelector';
import { isShowSaleSelector } from '../selectors/isShowSaleSelector';
import { getProFeaturesTrialActive } from '../../../../../../store/model/authUser/selectors/getProFeaturesTrialActive';
import { getAuthUser, SNOOZE_BLOCKER_MINUTES } from '../../../../../../store/model/authUser/selectors/getAuthUser';
import { onTimerEnd } from './events/onTimerEnd';
import { getUserPromoCode } from '../../../../../../store/model/authUser/selectors/getUserPromoCode';
import { getPaymentExpDate } from '../../selectors/getPaymentExpDate';
import { getMessages } from '../../../../../../store/constants';
import { getUserPromoCodeDiscount } from '../../../../../../store/model/authUser/selectors/getUserPromoCodeDiscount';
import {
    getUserManagedSubscriptions
} from '../../../../../../store/model/authUser/selectors/getUserManagedSubscriptions';

const mapStateToProps = (
    state: IApplicationState
): IUpgradePlanButtonStateProps => {
    const isShown = isUpgradeButtonShownSelector(state);
    if (!isShown) return {
        isShown
    }

    const isFreeVersion = isFreeVersionSelector();
    const buttonText = getButtonText(state);
    const upgradeMessage = getUpgradeMessage(state);
    const isShowSale = isShowSaleSelector(state)
    const isProFeature = getProFeaturesTrialActive(state);
    const user = getAuthUser(state);
    const snoozeBlockerTime = user.meta && user.meta.snoozeBlockerTime;

    return {
        isFreeVersion,
        isShown,
        isShowPromoCode: getUserPromoCode(state) && !!getPaymentExpDate(state) && !getUserManagedSubscriptions(state).length,
        isShowSale,
        promoCodeText: getMessages().getText('upgrade_button.discount', null, getUserPromoCodeDiscount(state)),
        timerDuration: isProFeature && SNOOZE_BLOCKER_MINUTES * 60 * 1000,
        timerStartDate: isProFeature && snoozeBlockerTime,
        upgradeMessage,
        buttonText,
    }
};

const mapDispatchToProps = (
    dispatch: any
): IUpgradePlanButtonDispatchProps => {
    return {
        onClick: () => dispatch(onClickPlanUpgrade()),
        onTimerEnd: () => dispatch(onTimerEnd()),
        // onClickGet: () => dispatch(onClickGet()),
        // onClickSale: () => dispatch(onClickSale()),
    }
};

export const UpgradePlanButtonHOC = connect(mapStateToProps, mapDispatchToProps)(ABUpgradePlanButtonHOC);
UpgradePlanButtonHOC.displayName = 'UpgradePlanButtonHOC';
