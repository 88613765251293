import { root } from '../../../../../../store/constants';
import { CLASS_ASIDE_PANEL_BODY } from '../../../asidePanel/components/AsidePanelBody/constants';
import { CLASS_COMMENTS_SECTION, CLASS_COMMENTS_SECTION_FORM } from '../constants';
import { CLASS_ASIDE_PANEL } from '../../../asidePanel/constants';
import { CLASS_ASIDE_PANEL_HEADER } from '../../../asidePanel/components/AsidePanelHeader/constants';

export enum EScrollIntoViewPosition {
    CENTER = 'center',
    END = 'end',
    NEAREST = 'nearest',
    START = 'start'
}

export const scrollCommentIntoView = (
    comment: HTMLElement,
    position = EScrollIntoViewPosition.CENTER
) => {
    if (navigator.userAgent.includes('Chrome')) { // smooth в scrollCommentIntoView не всегда работает для Chrome
        const version = parseInt(navigator.userAgent.match(/Chrome\/(\d+)/)[1], 10);
        if (version > 60) {
            const rect = comment.getBoundingClientRect();
            const isNeedToScroll = rect.top < 0 ||
                rect.top > (root._innerHeight || document.documentElement.clientHeight);

            if (!isNeedToScroll) return;

            const scrollEL = document.querySelector('.' + CLASS_ASIDE_PANEL_BODY) as HTMLElement;
            const sectionEl = document.querySelector('.' + CLASS_COMMENTS_SECTION + '__panel') as HTMLElement;
            const headerEl = document.querySelector('.' + CLASS_COMMENTS_SECTION + '__header') as HTMLElement;
            const commentsList = document.querySelector('.' + CLASS_COMMENTS_SECTION + '__list') as HTMLElement;

            if (!sectionEl || !sectionEl || !sectionEl || !commentsList) return;

            let top = sectionEl.offsetTop - headerEl.offsetHeight + comment.offsetTop;

            const isScrollUp = scrollEL.scrollTop > top;

            if (position === EScrollIntoViewPosition.CENTER) {
                top -= scrollEL.offsetHeight * 0.5 - comment.offsetHeight;
            } else if (
                position === EScrollIntoViewPosition.END ||
                position === EScrollIntoViewPosition.NEAREST && !isScrollUp
            ) {
                const headerActions = document.querySelector('.' + CLASS_ASIDE_PANEL + '__head-actions') as HTMLElement;
                if (headerActions) {
                    top += headerActions.offsetHeight;
                }
                const header = document.querySelector('.' + CLASS_ASIDE_PANEL_HEADER) as HTMLElement;
                const isNoAsideHeader = !!document.querySelector('.' + CLASS_ASIDE_PANEL + '--without-header');
                if (isNoAsideHeader && header) {
                    top += header.offsetHeight;
                }
                if (isScrollUp && header) {
                    top += header.offsetHeight;
                }
                let inputHeight = 0;
                const input = document.querySelector('.' + CLASS_COMMENTS_SECTION_FORM + '--floating') as HTMLElement;
                if (input) {
                    inputHeight = input.offsetHeight;
                }
                top -= scrollEL.offsetHeight - comment.offsetHeight - inputHeight;
            } else if (
                position === EScrollIntoViewPosition.START ||
                position === EScrollIntoViewPosition.NEAREST && isScrollUp
            ) {
                const headerActions = document.querySelector('.' + CLASS_ASIDE_PANEL + '__head-actions') as HTMLElement;
                if (headerActions) {
                    top += headerActions.offsetHeight;
                }
            }

            scrollEL.scroll({ top, behavior: 'smooth' });
        } else { // версии <= 60 не поддерживает scroll - скролим резко
            comment.scrollIntoView({ block: position });
        }
    } else {
        comment.scrollIntoView({ block: position, behavior: 'smooth' });
    }
}
