import {
    AT_NEW_PERFORMANCE_ALLOWED_VALUE_SET,
    AT_PERFORMANCE_ALLOWED_SET,
    AT_SETTINGS_OPENED_SET,
    AT_SHOW_SET, AT_NEW_VALUES_PERSIST,
    TCookieDialogActions
} from '../actions/types';
import { getCookieDialogInitialState, ICookieDialogState } from '../types';
import { setCookieDialogLocalStorage } from '../localstorage/actions/setCookieDialogLocalStorage';

const initialCookieDialogState = getCookieDialogInitialState();

const _cookiesDialogReducer = (
    state: ICookieDialogState = initialCookieDialogState,
    action: TCookieDialogActions
) => {
    switch (action.type) {
        case AT_SHOW_SET:
            return {
                ...state,
                isShow: action.isShow
            };
        case AT_SETTINGS_OPENED_SET:
            return {
                ...state,
                isSettingsOpened: action.isSettingsOpened
            };
        case AT_PERFORMANCE_ALLOWED_SET:
            return {
                ...state,
                isPerformanceAllowed: action.isPerformanceAllowed
            };
        case AT_NEW_PERFORMANCE_ALLOWED_VALUE_SET:
            return {
                ...state,
                newPerformanceAllowedValue: action.newPerformanceAllowedValue
            };
        case AT_NEW_VALUES_PERSIST:
            return {
                ...state,
                isPerformanceAllowed: state.newPerformanceAllowedValue,
                newPerformanceAllowedValue: undefined
            };
        default:
            return state;
    }
};

export const cookiesDialogReducer = (
    state: ICookieDialogState = initialCookieDialogState,
    action: TCookieDialogActions
) => {
    const newState = _cookiesDialogReducer(state,action);
    if (newState !== state){
        setCookieDialogLocalStorage(newState);
    }
    return  newState;
};
