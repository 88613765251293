import { IGetState, TBoardId, TCardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IComment } from '../../../../store/model/card/types/IComment';
import { getCard } from '../../../../store/model/selectors/getCard';
import { ICards } from '../../../../store/model/cards/types';
import { getCardsForRollback } from '../../../../store/model/selectors/getCardsForRollback';
import { cardsUpdate } from '../../../../store/model/actionCreators/cardsUpdate';
import { cardTotalSpentTimeUpdate } from '../card/cardTotalSpentTimeUpdate';
import { deleteRest } from './api/deleteRest';
import { cardsActiveRequestsUpdate } from '../../../../store/requestsState/effects/cardsActiveRequestsUpdate';
import { sendRealTimeStoreActionByBoardId } from '../../../../view/react_components/base/helpers/realTimeHelperTS';

export const commentDelete = (
    comment: IComment,
    boardId: TBoardId,
    isRealtime: boolean = true, // send event to socket
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const cardId: TCardId = comment.cardId;
        const state = getState();
        const card = getCard(state, cardId);
        const comments = card.comments.filter((com) =>{
            return com.id  !== comment.id;
        });
        const cards: ICards = {
            [cardId]: {
                comments,
                commentCount: comments.length
            }
        };

        let cardsForRollback = getCardsForRollback(state, cards);
        dispatch(cardsUpdate(cards));
        dispatch(cardsActiveRequestsUpdate(cards, 1));

        return dispatch(deleteRest(comment))
            .then(() => {
                if (isRealtime) {
                    dispatch(sendRealTimeStoreActionByBoardId(boardId, cardsUpdate(cards)));
                }
                if (comment.timeTracker){
                    dispatch(cardTotalSpentTimeUpdate(boardId, cardId))
                }
                return ;
            })
            .catch((e: any) => {
                console.error(e);
                dispatch(cardsUpdate(cardsForRollback));
            })
            .finally(() => {
                dispatch(cardsActiveRequestsUpdate(cards, -1));
            });
    };
    return action;
};
