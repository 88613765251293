import { ICommentMention } from '../../../../../../store/model/card/types/IComment';
import { ASSIGNEE_GROUP_ID } from '../../AssigneesSection/components/constants';

export const findOptionGroupIndexes = (
    options: ICommentMention[]
): number[] => {
    let indexes: number[] = [];

    for (let i=0; i<options.length; i++) {
        if (options[i].permissionId === ASSIGNEE_GROUP_ID) {
            indexes.push(i);
        }
    }

    return indexes;
};
