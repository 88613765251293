import * as moment from 'moment';
import { IMonthDayData } from '../types';

const LAST_DAY_OF_WEEK_POSITION = 5;

export const getMonthDayData = (
    date: Date,
    extra?: Partial<IMonthDayData>
): IMonthDayData => {
    if (!date) return null;
    const momentStartDate = moment(date);
    const dayOfMonth = momentStartDate.get('date');
    const dayOfWeek = Number(momentStartDate.format('d')) + 1;
    const dayOfWeekName = momentStartDate.format('dddd');
    let dayOfWeekPosition = Math.floor((dayOfMonth - 1) / 7) + 1;
    const isLast = moment(momentStartDate).endOf('month').get('date') - dayOfMonth < 7;
    if (isLast) dayOfWeekPosition = LAST_DAY_OF_WEEK_POSITION;
    return {
        dayOfMonth,
        dayOfWeek,
        dayOfWeekPosition,
        dayOfWeekName,
        ...extra
    }
}
