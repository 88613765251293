import { TSearchQuery } from '../store/header/types';
import { filterArrayByAcronym } from '../../../helpers/search/filterArrayByAcronym';
import { IBoard } from '../store/boards/types';

export const filterBoardsForSearch = (
    boards: IBoard[],
    searchQuery: TSearchQuery
): IBoard[] => {
    return filterArrayByAcronym(boards, 'name', searchQuery);
};
