import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { IPanelActionsHOCProps } from './types';
import { PanelActions } from '../../components/PanelActions/PanelActions';
import { IPanelActionsFields } from '../../components/PanelActions/types';
import { getBoard } from '../../../../../../../store/model/selectors/getBoardById';

const mapStateToProps = (
    state: IApplicationState,
    ownProps: IPanelActionsHOCProps
): IPanelActionsFields => {
    const { boardId } = ownProps;
    let isShow = false;
    const board = getBoard(state, boardId);

    if (board.id) {
        isShow = true;
    }

    return {
        boardId,
        isMinimized: false, // в board settings панель кнопок всегда раскрыта KNB-1098
        isShow,
    }
};

export const PanelActionsHOC = connect(
    mapStateToProps,
    null
)(PanelActions);

PanelActionsHOC.displayName = 'PanelActionsHOC';
