import * as React from 'react';
import { IBoardShowOnGanttProps } from './types';
import './_BoardShowOnGantt.scss';
import { INSTRUCTIONS_URL, MESSAGE_DESCRIPTION, MESSAGE_MORE_INSTRUCTIONS } from './constants';
import { CLASS_BOARD_DETAILS } from '../../../../constants';

export function BoardShowOnGantt ({
}: IBoardShowOnGanttProps) {
    const className = CLASS_BOARD_DETAILS + '__gantt';
    const classText = CLASS_BOARD_DETAILS + '__gantt-text';

    return <div className={className}>
        <div className={classText}>
            { MESSAGE_DESCRIPTION }
            {' '}
            <a
                href={ INSTRUCTIONS_URL }
                target="_blank"
            >
                { MESSAGE_MORE_INSTRUCTIONS }
            </a>
        </div>
    </div>
}
