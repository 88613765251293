const App = window.App;
const Messages = window.Messages;

export const gaTrackEventHelper = (...args) => {
    App.controller.trackEvent(args);
};

const getMessage = (...args) => {
    return Messages.getText(args);    
};

export const getEventCat = (eventActionCode, lngs) => {    
    return getMessage(eventActionCode, lngs);
};

export const getEventAction = (eventActionCode, lngs) => {
    return getMessage(eventActionCode, lngs);
};