import * as React from 'react';
import { AsidePanelActionsButton } from '../../../..';
import { BOARD_BUTTON_CREAT_TEMPLATE } from './constants';
import { ICreateTemplateButtonProps } from './types';

export function CreateTemplateButton ({
    isBasicFeature,
    isDisallow,
    isShow,
    tooltip,
    onClick,
}: ICreateTemplateButtonProps) {
    if (!isShow) return null;

    return (
        <AsidePanelActionsButton
            isDisabled={isDisallow}
            isBasicFeature={isBasicFeature}
            title={BOARD_BUTTON_CREAT_TEMPLATE}
            tooltip={tooltip}
            onClick={onClick}
        />
    );
};
