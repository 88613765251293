import { connect } from 'react-redux';
import { getBoard } from '../../../../../../../../store/model/selectors/getBoardById';
import { getBoardOwner } from '../../../../../../../../store/model/selectors/getBoardOwner';
import { IApplicationState } from '../../../../../../../../types/types';
import { BoardAuthor } from '../../components/BoardAuthor/BoardAuthor';
import { IBoardAuthorFields } from '../../components/BoardAuthor/types';
import { IBoardAuthorHOCProps } from './types';

const mapStateToProps = (
    state: IApplicationState,
    ownProps: IBoardAuthorHOCProps
): IBoardAuthorFields => {
    const { boardId } = ownProps;
    const { created } = getBoard(state, boardId);
    const author = getBoardOwner(state, boardId);

    return {
        author,
        created
    }
};

export const BoardAuthorHOC = connect(
    mapStateToProps,
    null
)(BoardAuthor);

BoardAuthorHOC.displayName = 'BoardAuthorHOC';
