import { Dispatch, ThunkAction } from '../../../types/actions';
import { IGetState } from '../../../types/types';
import { fetchHandler } from 'app/util/fetchHandler';
import { IRestPushNotificationItem } from 'app/types/rest/IRestPushNotificationItem';
import Util from 'app/util/util';
import { getRestHeadersPatch } from 'app/rest/helpers/getRestHeadersHelper';

export const pushNotificationMarkAllRead = (
    ids: number[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return fetchHandler<IRestPushNotificationItem>(
            Util.getApiUrl('/rest/pushnotification/seen'), {
                ...getRestHeadersPatch(),
                body: JSON.stringify(ids)
            }
        ).then( res => {
            console.log(res);
        })
    };
    return action;
};
