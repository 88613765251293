import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState } from '../../../../../../../types/types';
import { peopleSearch } from 'app/helper/authorisation/microsoft/graph/api/peopleSearch';

export const onSearch = (
    value: string,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        return peopleSearch(value);
    };
    return action;
};
