import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState, TBoardId, TCardId } from '../../../../types/types';
import { getCard } from '../../../../store/model/selectors/getCard';
import { root } from '../../../../store/constants';

export const cleverOpenCard = (
    cardId: TCardId,
    boardId: TBoardId
): ThunkAction => {
    return () => {
        root.App.controller.cleverOpenCard(boardId, cardId);
    };
};
