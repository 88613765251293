import { WYSIWYG_RELEASE } from 'app/types/rest/IUserMeta';
import { EHTMLEditorType, IApplicationState } from '../../../../types/types';
import { getAuthUser } from './getAuthUser';

export const getUserHTMLEditorType = (
    state: IApplicationState
): EHTMLEditorType => {
    const user = getAuthUser(state);
    let HTMLEditorType = user.meta && user.meta.HTMLEditorType;
    if (HTMLEditorType) return HTMLEditorType;

    return EHTMLEditorType.MARKDOWN; // pause KNB-2756
    if (user && user.createDateTimeStamp && user.createDateTimeStamp > WYSIWYG_RELEASE) {
        return EHTMLEditorType.WYSIWYG;
    } else {
        return EHTMLEditorType.MARKDOWN;
    }
};
