import { IAsidePanelTab, IAsidePanelTabFields } from '../types';
import { createDeepEqualSelector } from '../../../helpers/memoizeHelper';

type TgetTabsStateProps = (
    tabs: ReadonlyArray<IAsidePanelTab>
) => IAsidePanelTabFields[];

const getTabsStatePropsSelector = (
    tabs: ReadonlyArray<IAsidePanelTab>
): IAsidePanelTabFields[] => {
    return tabs.map(tab => ({
        key: tab.key,
        name: tab.name,
        isReadonly: tab.isReadonly,
        indicatorNumber: tab.indicatorNumber,
        isNewFeature: tab.isNewFeature,
        isProFeature: tab.isProFeature,
        onClick: tab.onClick
    }));
}

export const getTabsStatePropsCreateSelector = (
): TgetTabsStateProps => createDeepEqualSelector(
    (tabs: ReadonlyArray<IAsidePanelTab>) => tabs,
    getTabsStatePropsSelector,
);

export const getTabsStateProps: TgetTabsStateProps = getTabsStatePropsCreateSelector();
