import { IGetState, TCardId } from '../types/types';
import { Dispatch, ThunkAction } from '../types/actions';
import { getCard } from '../store/model/selectors/getCard';
import { cardLoad } from '../rest/effects/card/card/cardLoad';
import { ICards } from '../store/model/cards/types';
import { TStatus } from '../types/model';
import { ICard } from '../store/model/card/types';
import { getBoardByCardId } from '../store/model/selectors/getBoardByCardId';
import { getMessages, root } from '../store/constants';
import { getTypeSwitcherActiveType } from '../view/react_components/typeSwitcher/store/selectors/getTypeSwitcherActiveType';
import { EViewTypes } from '../const';
import { getCardRecurringTasks } from '../store/model/selectors/getCardRecurringTasks';
import { openCardTemplates } from './openCardTemplates';
import { openRecurringTasks } from './openRecurringTasks';
import { getAuthUser } from '../store/model/authUser/selectors/getAuthUser';
import { changeBackboneViewType } from 'app/view/react_components/typeSwitcher/effects/changeBackboneViewType';

export const openCardForm = (
    cardId: TCardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        let card = getCard(state, cardId);
        if (card) {
            return dispatch(afterArchiveCheck(card))
        }
        return dispatch(cardLoad(cardId))
            .then((cards: ICards) => {
                if (cards[cardId] && cards[cardId].status !== TStatus.STATUS_DELETED) {
                    card = cards[cardId];
                    return dispatch(afterArchiveCheck(card));
                }
                return Promise.reject();
            });
    };
    return action;
}

const afterArchiveCheck = (
    card: ICard
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const board = card && getBoardByCardId(state, card.id);
        if (!card || !board) return;

        const authUser = getAuthUser(state);
        const viewType = getTypeSwitcherActiveType(state, board.id);
        const isCardArchived = card.status === TStatus.STATUS_ARCHIVE;
        const isArchivedView = viewType === EViewTypes.ARCHIVE_VIEW_TYPE;

        if (
            viewType === EViewTypes.REPORTS_VIEW_TYPE ||
            isCardArchived !== isArchivedView
        ) {
            if (isCardArchived && !authUser.allowArchive) {
                root.App.controller.trackEvent(
                    getMessages().getText('ga.category.archive'),
                    getMessages().getText('ga.action.blockers.archive.card')
                );
            }
            const viewType = isCardArchived
                ? EViewTypes.ARCHIVE_VIEW_TYPE
                : root.App.controller.getDashboardViewManager().getViewTypePrevious() || EViewTypes.KANBAN_VIEW_TYPE;
            dispatch(changeBackboneViewType(viewType, false));
        }

        const isTemplate = card.status === TStatus.STATUS_SERVICE_TEMPLATE;
        let isRecurring = false;
        if (isTemplate) {
            isRecurring = getCardRecurringTasks(state, card.id).length > 0;
            if (isRecurring) {
                dispatch(openRecurringTasks(board.id));
            } else {
                dispatch(openCardTemplates(board.id));
            }
        } else if (
            root.App.controller.leftAsidePanel.isCardTemplatesOpened() ||
            root.App.controller.leftAsidePanel.isRecurringTasksOpened()
        ) {
            root.App.controller.leftAsidePanel.hide(false);
        }

        root.App.controller.asidePanel.showCard(card.id, isTemplate, isRecurring);
        return Promise.resolve();
    };
    return action;
}
