import * as React from 'react';
import { MouseEvent } from 'react';
import { IDriveDocProps } from './types';
import { CARD_CLASS } from '../../../../constants';
import { Util } from '../../../../../../../../util/util';
import './_DriveDoc.scss';
import { Button, Icon, Tooltip } from 'kui';
import { DRIVE_DOC_GOOGLE_THUMB_SIZE, MIME_TYPE_GOOGLE_APPS } from './constants';
import { DRIVE_DOC_TOOLTIP } from '../../../../wording';
import { EAuthUserPlatformType } from 'app/types/rest/IRestAuthUser';
import { msFileThumbnailStorage } from '../../../../../../../../helper/authorisation/microsoft/storage/msThumbnail';
import { THUMB_CARD_SIZE } from '../../../../../../../../const';
import {
    googleFileThumbnailStorage
} from '../../../../../../../../helper/authorisation/google/storage/googleThumbnail';

export function DriveDoc({
    driveDoc,
    onDownload,
    onLinkClick,
}: IDriveDocProps) {
    if (!driveDoc) return null;

    const classFile = CARD_CLASS + '__drive-docs__file';
    const isPreviewType = Util.fileIsImage(driveDoc.mimeType) || Util.fileIsVideo(driveDoc.mimeType);

    const onClick = (
        e: MouseEvent
    ) => {
        e.stopPropagation();
        onDownload(driveDoc.driveId, driveDoc.fileId);
    };

    let [thumbUrl, _setThumbUrl] = React.useState(null);
    const setThumbUrl = (url: any) => {
        thumbUrl = url;
        _setThumbUrl(url);
    }
    React.useEffect(() => {
        if (driveDoc.isImage) {
            if (msFileThumbnailStorage.is()) {
                const thumbSize = `c${THUMB_CARD_SIZE}x${THUMB_CARD_SIZE}`;
                msFileThumbnailStorage.getThumbnail(driveDoc.driveId, driveDoc.fileId, thumbSize)
                    .then(thumb => setThumbUrl(thumb.url));
            } else if (googleFileThumbnailStorage.is()) {
                googleFileThumbnailStorage.getThumbnail(driveDoc.fileId, DRIVE_DOC_GOOGLE_THUMB_SIZE)
                    .then(thumb => setThumbUrl(thumb));
            }
        }
    }, [driveDoc]);

    const icon = driveDoc.iconUrl
        ? isPreviewType
            ? <img
                src={thumbUrl}
                onError={() => {
                    if (googleFileThumbnailStorage.is()) {
                        googleFileThumbnailStorage.reGetThumbnail(
                            driveDoc.fileId,
                            DRIVE_DOC_GOOGLE_THUMB_SIZE,
                            setThumbUrl,
                        );
                    }
                }}
            />
            : <img src={driveDoc.iconUrl}/>
        : <Icon xlink={'attachment'} size={24}/>;

    const downloadUrl = Util.fileHasAvailableMimeType(driveDoc.mimeType)
        ? Util.createDownLoadLink(driveDoc.fileId)
        : null;

    const downloadButton = driveDoc.platformType === EAuthUserPlatformType.MICROSOFT
        ? null
        : {
            href: downloadUrl,
            target: '_blank',
        }

    return (
        <div className={classFile} key={driveDoc.fileId}>
            <a
                className={classFile + '_link'}
                href={driveDoc.url}
                target={'_blank'}
                onClick={(e) => onLinkClick(e, driveDoc.id)}
            >
                <div className={classFile + '_icon'}>{icon}</div>
                <span className={classFile + '_title'}>
                    {driveDoc.title}
                </span>
            </a>
            {
                !driveDoc.mimeType.includes(MIME_TYPE_GOOGLE_APPS) &&
                <Tooltip
                    isNoEvents
                    isNoWrap
                    value={DRIVE_DOC_TOOLTIP}
                >
                    <Button
                        className={classFile + '_download'}
                        {...downloadButton}
                        variant={'icon'}
                        onClick={onClick}
                    >
                        <Icon size={24} xlink={'cloud-upload'}/>
                    </Button>
                </Tooltip>
            }
        </div>
    );
}
