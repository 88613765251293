import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../types/types';
import { cardsRestPatch } from '../../../../../../../../rest/effects/card/card/api/helper/cardsRestPatch';
import { root } from '../../../../../../../../store/constants';

export const rollback = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(cardsRestPatch(root.App.controller.boardCardPrefixInfo.getCardsForRollback()));
    }
    return action;
}
