import { IAuthUser } from './types';
import { EAuthUserAccountType, EAuthUserTutorialStatus } from '../../../types/rest/IRestAuthUser';
import { getInitUserBackboneModel } from '../../../types/rest/ISharedUser';
import { EViewTypes } from '../../../const';

export const FIRST_TRIAL_PROMO_CODE = 'Esie8lue';
export const SECOND_TRIAL_PROMO_CODE = '0J0pA1141';

export const APP_TEAM_EMAIL = 'app.team@kanbanchi.com';

export const AUTH_USER_DEFAULT_ID = '0';
export const getInitialStateAuthUser = (): IAuthUser => {
    return {
        id: AUTH_USER_DEFAULT_ID,
        online: false,
        photoUrl: '',
        timezone: '',
        logo: '',
        firstName: '',
        lastName: '',
        fullName: '',
        initials: '',
        email: '',
        hash: '',
        notificationEmail: '',
        anonym: false,
        admin: false,
        needLogin: false,
        firstVisit: false,
        createDate: '',
        createDateTimeStamp: 0,
        allowArchive: false,
        allowTimeTracker: false,
        allowBranding: false,
        allowGantt: false,
        allowChatSupport: false,
        allowCreateBoard: true,
        allowCardFromEmail: false,
        allowRequiredProperties: false,
        allowGoogleSheetExport: false,
        allowPriorityAutoSorting: false,
        allowDashboardBackground: false,
        allowBackupXml: false,
        allowAutoBackupXml: false,
        allowSubCard: false,
        allowAttacheTeamDriveFile: false,
        allowSharedDrive: false,
        allowReports: false,
        allowedCookies: '',
        tutorialStatus: EAuthUserTutorialStatus.NOT_SHOWN,
        lastManager: getInitUserBackboneModel(),
        permissionId: '',
        domainWide: false,
        domainWidePremium: false,
        domainWideProfessional: false,
        accountType: EAuthUserAccountType.INDIVIDUAL_ACCOUNT_TYPE,
        unseenNotificationCount: 0,
        refreshTokenExeption: false,
        oAuthScopes: [],
        activeSubscription: [],
        ownSubscription: [],
        managedSubscription: [],
        products: [],
        region: '',
        country: '',
        city: '',
        gsuiteAdmin: false,
        settings: {},
        meta: {
            defaultView: EViewTypes.KANBAN_VIEW_TYPE,
            recentBoards: []
        },
        availableChargeBeeCoupons: [],
        domainWideEndDate: 0,
        hasAssignedCards: false,
        collaboratorColor: null,
        eduV2: false
    }
};
