import * as React from 'react';
import './_CardPriority.scss';
import { Button, ButtonDropdown, Icon, SelectList, SelectListItem } from 'kui';
import { PRIORITY_ICONS } from '../../../../../helpers/constants';
import { ECardPriorityVariant, ICardPriorityProps } from './types';
import { CARD_SELECT_PRIORITY, CLASS_CARD_PRIORITY } from './constants';
import { CARD_ACTIONS_PORTAL, CARD_CONTROL_CLASS, CARD_TEMPLATES_PORTAL } from '../../../../../main/kanbanView';
import { v4 as uuidv4 } from 'uuid';
import { CLASS_ABSTRACT_DASHBOARD_SCROLL_PREVENT } from '../../../../../../abstracts/constants';

export function CardPriority({
    priority,
    isReadonly,
    isTemplates,
    listId,
    portal,
    variant = ECardPriorityVariant.BASE,
    onChange,
    onDropDownOpen,
}: ICardPriorityProps) {
    const priorityObj = PRIORITY_ICONS.find(item => item.priority === String(priority) || !priority && !Number(item.priority));

    const className = CLASS_CARD_PRIORITY;
    const classButton = className + '__button';

    const [isOpened, setOpened] = React.useState(false);
    const [classUnique] = React.useState(classButton + uuidv4());

    const onOpen = () => {
        onDropDownOpen();
        setOpened(true);
        setTimeout(()=>{
            const button = document.querySelector('.' + classUnique) as HTMLElement;
            if (button) button.focus();
        }, 100);
    }

    const onClose = () => {
        setOpened(false);
    }

    const label = priority ? priorityObj.text : CARD_SELECT_PRIORITY;

    const portalAttributes = portal ? {
        portal: true,
        portalId: isTemplates ? CARD_TEMPLATES_PORTAL : CARD_ACTIONS_PORTAL + '-' + listId,
    } : {};

    return (
        <ButtonDropdown
            className={`
                ${className}
                ${className + '--' + variant}
                ${!priority ? className + '--no' : ''}
                ${CARD_CONTROL_CLASS}
            `}
            dropdownClassName={`
                ${className + '__dropdown'}
                ${className + '__dropdown--card'}
                ${className + '__dropdown--' + variant}
                ${CARD_CONTROL_CLASS}
                ${CLASS_ABSTRACT_DASHBOARD_SCROLL_PREVENT}
            `}
            directionVertical={variant === ECardPriorityVariant.SMALL ? 'auto' : 'down'}
            disabled={isReadonly}
            { ...portalAttributes }
            onOpen={onOpen}
            onClose={onClose}
        >
            <Button
                className={`${classButton} ${classUnique}`}
                variant={variant === ECardPriorityVariant.SMALL ? 'icon' : 'icon-text'}
                tooltip={variant === ECardPriorityVariant.SMALL && !isOpened ? label : null} // нет тултипа когда открыто, иначе на карте не закрывалось
            >
                <Icon
                    className={className + '__icon'}
                    size={24}
                    xlink={priorityObj.icon}
                />
                {variant === ECardPriorityVariant.BASE &&
                    <>
                        <div className={className + '__label'}>
                            {label}
                        </div>
                        <Icon
                            className={className + '__arrow'}
                            size={24}
                            xlink={'arrow-down'}
                        />
                    </>
                }
            </Button>
            <SelectList
                active={PRIORITY_ICONS.findIndex(item => item.priority === String(priority) || !priority && !Number(item.priority))}
            >
                {PRIORITY_ICONS.map((item) => (
                    <SelectListItem
                        className={!Number(item.priority) ? 'no-priority' : ''}
                        icon={item.icon}
                        iconSize={24}
                        key={item.priority}
                        value={item.priority}
                        onClick={() => onChange(item.priority)}
                        tabIndex={0}
                    >
                        {item.text}
                    </SelectListItem>
                ))}
            </SelectList>
        </ButtonDropdown>
    );
};
