import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../types/actions';
import { ISnackbarModel, SnackbarVariant } from '../../../store/types';
import { getDefaultActionEvent } from '../../../effects/helpers/getDefaultActionEvent';
import { ISnackbarDispatchProps } from 'kui';
import { onBlur } from '../../../effects/onBlur/onBlur';

const getDefaultSnackbar: ActionCreator<ThunkAction> = (
    snackbarModel: ISnackbarModel
) => {
    const action = (dispatch: Dispatch): ISnackbarDispatchProps => {
        return {
            onTimer: () => dispatch(getDefaultActionEvent(snackbarModel.id, snackbarModel.onTimer))
        };
    };
    return action;
};

const getPromtSnackbar: ActionCreator<ThunkAction> = (
    snackbarModel: ISnackbarModel
) => {
    const action = (dispatch: Dispatch): ISnackbarDispatchProps => {
        return {
            onBlur: (event: React.FocusEvent<HTMLDivElement>) => dispatch(onBlur(event, snackbarModel.id))
        };
    };
    return action;
};

export const getSnackbarInheretedDispatchPropsByModel: ActionCreator<ThunkAction> = (
    snackbarModel: ISnackbarModel
) => {
    const action = (dispatch: Dispatch): ISnackbarDispatchProps => {
        switch (snackbarModel.variant) {
            case SnackbarVariant.PROMT:
                return dispatch(getPromtSnackbar(snackbarModel));
            default:
                return dispatch(getDefaultSnackbar(snackbarModel));
        }
    };
    return action;
}
