import { Button, Icon } from 'kui';
import * as React from 'react';
import * as moment from 'moment';
import { ICommentProps } from './types';
import { blockMarkdown } from '../../../../../../../helper/markdownHelper';
import './_comment.scss';
import { CLASS_COMMENT, CLASS_COMMENTS_SECTION } from '../../constants';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { getAsidePanelTooltip } from '../../../../asidePanel/helpers/getAsidePanelTooltip';
import { CommentAttachments } from '../CommentAttachments/CommentAttachments';
import {
    COMMENT_ANIMATION_DURATION,
    COMMENT_GOOGLE_THUMB_SIZE,
    COMMENT_QUOTE_AND_REPLY,
    COMMENT_REPLY
} from './constants';
import ReactHtmlParser from 'react-html-parser';
import { CommentLikesHOC } from '../../hocs/CommentLikesHOC/CommentLikesHOC';
import { CommentActions } from '../CommentActions/CommentActions';
import {
    getBigPreviewIcon
} from '../../../AttachmentsSection/hocs/CardAttachmentsSectionHOC/helpers/getBigPreviewIcon';
import { getInitials } from 'app/view/react_components/helpers/userPhotoHelper';
import { UserpicStorage } from 'app/view/react_components/base/components/UserpicStorage/UserpicStorage';
import Util from 'app/util/util';
import { msFileThumbnailStorage } from '../../../../../../../helper/authorisation/microsoft/storage/msThumbnail';
import { THUMB_COMMENT_REPLY_SIZE } from '../../../../../../../const';
import { EScrollIntoViewPosition, scrollCommentIntoView } from '../../helpers/scrollCommentIntoView';
import { googleFileThumbnailStorage } from '../../../../../../../helper/authorisation/google/storage/googleThumbnail';
import { clearMarkdown } from '../../../../../base/helpers/clearMarkdownHelper';
import { AnalyzeRender } from '../../../../../helpers/memoizeHelper';

//@ts-ignore
const analyzeRender = window.Settings.development ? new AnalyzeRender(`Comment`) : null;

export function Comment({
    id,
    author,
    text,
    created,
    isMyComment,
    isActive,
    timeTracker,
    cardId,
    isReadonly,
    driveDocs,
    replyAttachment,
    replyComment,
    replyText,
    onAttachmentClick,
    onAttachmentReply,
    onEdit,
    onGetLink,
    onRemove,
    onReply
}: ICommentProps) {
    if (analyzeRender) analyzeRender.call(`Comment ${id}`);

    const { boardId } = React.useContext(AsidePanelContext);
    const isFullScreenAttachment = driveDocs && driveDocs.length === 1 && driveDocs[0].isImage;
    const itemRef = React.useRef(null);
    const contentRef = React.useRef(null);
    const timerRef = React.useRef(null);
    const bodyRef = React.useRef(null);

    const wrapperClass = CLASS_COMMENT + (isMyComment ? ` ${CLASS_COMMENT}--my` : '');
    const headerClass = CLASS_COMMENT + '__header-line';
    const userClass = CLASS_COMMENT + '__user';
    const userNameClass = CLASS_COMMENT + '__username';
    const dateClass = CLASS_COMMENT + '__date';
    const commentBodyClass = CLASS_COMMENT + '__body';
    const commentContentClass = CLASS_COMMENT + '__content';
    const classHtmlOutput = 'html-output';
    const commentContentClassTime = CLASS_COMMENT + '__content--time';
    const commentReplyClass = CLASS_COMMENT + '__reply';
    const commentReplyCommentClass = CLASS_COMMENT + '__reply-comment';
    const commentFooterClass = CLASS_COMMENT + '__footer';
    const commentActiveClass = CLASS_COMMENT + '--active';
    const commentTimerClass = CLASS_COMMENT + '--timer';

    const [markdown, setMarkdown] = React.useState('');
    const [markdownArray, setMarkdownArray] = React.useState([]);
    const [highlightedText, setHighlightedText] = React.useState('');

    React.useEffect(() => {
        if (timeTracker) {
            let markdownArray: string[] = [];
            let markdownString = '';
            let isTime = false;
            for (let i=0; i<text.length; i++) {
                if (text[i] === '[') {
                    if (i > 0 && text[i-1] === '!') { // img = ![](link)
                    } else if (i < text.length - 1 && text[i+1].match(/\d/)) {
                        markdownString += '`';
                        isTime = true;
                        continue;
                    }
                } else if (isTime && text[i] === ']') {
                    const lastQuotationMark = markdownString.lastIndexOf('`');
                    const isTimer = markdownString.slice(lastQuotationMark + 1).match(/^(\d+[dhms]\s?)+$/);//выражение для точного соответвия типу 1d 1h 1m 1s: ^(?!.*\s\s)(?!.* $)(?!(?:\d[dhms]){2})(\d+d)?\s?(\d+h)?\s?(\d+m)?\s?(\d+s)?$
                    if (text[i+1] === '(' || !isTimer) {  //если ссылка [](link) или не соответствует выражению, меняем предыдущюю '`' на '['
                        markdownString = markdownString.substring(0, lastQuotationMark) + '[' + markdownString.substring(lastQuotationMark + 1) + text[i];
                        isTime = false;
                        continue
                    } else {
                        markdownString += '`';
                        isTime = false;
                        continue;
                    }
                }
                markdownString += text[i];
            }

            let markdownItem = '';
            for (let i = 0; i < markdownString.length; i++) { // разбить коммент на текст и время: ['текст' , '`1m`', 'текст' ...]
                if (markdownString[i] === '`') {
                    if (markdownItem[0] && markdownItem[0] === '`') {
                        markdownArray.push(markdownItem + markdownString[i]);
                        markdownItem = '';
                        continue;
                    } else if (markdownItem) {
                        markdownArray.push(markdownItem);
                        markdownItem = '';
                    }
                }
                markdownItem += markdownString[i];
            }
            if (markdownItem) markdownArray.push(markdownItem);
            setMarkdownArray(markdownArray.map(text => blockMarkdown(Util.fixAuthuser(text))));
            if (msFileThumbnailStorage.is()) {
                let promises = markdownArray.map(text => msFileThumbnailStorage.getSrcThumbnails(text).catch(() => null));
                Promise.all(promises)
                    .then((texts) => {
                        let textsNew = markdownArray.map((text, i) => {
                            return blockMarkdown(texts[i] || text);
                        });
                        setMarkdownArray(textsNew);
                    })
            }
        } else {
            setMarkdown(blockMarkdown(Util.fixAuthuser(text)));
            if (text && msFileThumbnailStorage.is()) {
                msFileThumbnailStorage.getSrcThumbnails(text)
                    .then((text) => {
                        setMarkdown(blockMarkdown(text));
                    })
                    .catch(() => {})
            }
        }
    }, [text]);

    const dateString = moment(created * 1000).format('DD MMM YYYY [at] HH:mm');

    function onDblClick() {
        if (timerRef.current) clearTimeout(timerRef.current);
        if (isReadonly) return;

        if (isMyComment) {
            onEdit();
        } else {
            if (contentRef.current) {
                const range = document.createRange();
                range.selectNode(contentRef.current);
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(range);
            }
        }
    }

    const onRemoveHandler = () => {
        if (itemRef.current) {
            itemRef.current.closest(`.${CLASS_COMMENTS_SECTION}`).focus();
        }
        onRemove();
    }

    const onReplyCommentClick = () => {
        if (timerRef.current) clearTimeout(timerRef.current);
        const onClick = () => {
            const comment = document.querySelector('.' + CLASS_COMMENT + '--' + replyComment.id) as HTMLElement;
            if (comment) {
                scrollCommentIntoView(comment, EScrollIntoViewPosition.CENTER);
                comment.classList.add(commentActiveClass);
                setTimeout(() => {
                    comment.classList.remove(commentActiveClass);
                }, COMMENT_ANIMATION_DURATION);
            }
        };
        timerRef.current = setTimeout(() => {
            onClick();
        }, 200);
    };

    const onReplyClick = () => {
        let value;
        const selectedText = window.getSelection().toString();
        if (
            selectedText &&
            clearMarkdown(text).replace(/(\r\n|\n|\r| |-)/gm,'').includes(clearMarkdown(selectedText).replace(/(\r\n|\n|\r| )/gm,''))
        ) {
            value = selectedText;
        }
        onReply(value);
    };

    React.useEffect(() => {
        if (isActive) {
            setTimeout(() => {
                const comment = itemRef && itemRef.current as HTMLElement;
                if (comment) {
                    scrollCommentIntoView(comment, EScrollIntoViewPosition.CENTER);
                    comment.classList.add(commentActiveClass);
                    setTimeout(() => {
                        const comment = itemRef && itemRef.current as HTMLElement;
                        if (comment) {
                            comment.classList.remove(commentActiveClass);
                        }
                    }, COMMENT_ANIMATION_DURATION);
                }
            }, 200);
        }
    }, [isActive]);

    const attachments = <CommentAttachments
        attachments={driveDocs}
        isFullScreen={isFullScreenAttachment}
        onReply={onAttachmentReply}
        onClick={(e, driveDocId) => {
            e.preventDefault();
            if (timerRef.current) clearTimeout(timerRef.current);
            timerRef.current = setTimeout(() => {
                onAttachmentClick(driveDocId);
            }, 200);
        }}
    />;

    const [replyAttachmentThumbUrl, setReplyAttachmentThumbUrl] = React.useState('');
    React.useEffect(() => {
        if (!replyAttachment) return;

        if (msFileThumbnailStorage.is()) {
            msFileThumbnailStorage.getThumbnail(replyAttachment.driveId, replyAttachment.fileId, `c${THUMB_COMMENT_REPLY_SIZE}x${THUMB_COMMENT_REPLY_SIZE}`)
                .then(thumb => setReplyAttachmentThumbUrl(thumb.url));
        } else if (googleFileThumbnailStorage.is()) {
            googleFileThumbnailStorage.getThumbnail(replyAttachment.fileId, COMMENT_GOOGLE_THUMB_SIZE)
                .then(thumb => setReplyAttachmentThumbUrl(thumb));
        }
    }, [replyAttachment]);

    const replyContent = replyComment &&
        <div
            className={`${commentReplyCommentClass} ${replyAttachment ? commentReplyCommentClass + '--attachment' : ''}`}
            onClick={onReplyCommentClick}
        >
            {replyAttachment &&
                <div className={commentReplyCommentClass + '-icon'}>
                    <img
                        className={commentReplyCommentClass + '-img'}
                        onError={()=> {
                            if (googleFileThumbnailStorage.is()) {
                                googleFileThumbnailStorage.reGetThumbnail(
                                    replyAttachment.fileId,
                                    COMMENT_GOOGLE_THUMB_SIZE,
                                    setReplyAttachmentThumbUrl,
                                );
                            }
                        }}
                        src={replyAttachment.isImage ? replyAttachmentThumbUrl : getBigPreviewIcon(replyAttachment.iconUrl)}
                    />
                </div>
            }
            <div className={commentReplyCommentClass + '-body'}>
                <div className={commentReplyCommentClass + '-author'}>{replyComment.author.fullName}</div>
                <div className={commentReplyCommentClass + '-text'}>
                    {replyAttachment ? replyAttachment.title : replyText ? replyText : replyComment.text ? replyComment.text : '...'}
                </div>
            </div>
        </div>;

    const commentContent = (
            markdownArray.length
                ? markdownArray.map((markdown, index) => {
                    const isTimer = markdown.includes('<code>');
                    return ( // элементы массива отдельными баблами, каждый с новой строки
                    <React.Fragment key={`${id}-${index}`}>
                        <div
                            className={`
                                ${commentContentClass}
                                ${classHtmlOutput}
                                ${isTimer ? commentContentClassTime : ''}
                            `}
                            onDoubleClick={onDblClick}
                        >
                            {replyContent}
                            <div className={commentTimerClass}>
                                {isTimer &&
                                    <Icon
                                        xlink={'spent-time'}
                                        size={24}
                                    />
                                }
                                <div
                                    ref={contentRef}
                                    className={commentContentClass + '-inner'}
                                    onClick={e => e.stopPropagation()}
                                >
                                    {ReactHtmlParser(markdown)}
                                </div>
                            </div>
                            {attachments}
                        </div>
                    </React.Fragment>
                )})
                : <div
                    className={`
                        ${commentContentClass}
                        ${classHtmlOutput}
                    `}
                    onDoubleClick={onDblClick}
                >
                    {replyContent}
                    {text &&
                        <div
                            ref={contentRef}
                            className={commentContentClass + '-inner'}
                            onClick={e => e.stopPropagation()}
                        >
                            {ReactHtmlParser(markdown)}
                        </div>
                    }
                    {attachments}
                </div>
        );
    const multiMarkdownCommentClass = !isFullScreenAttachment && markdownArray.length > 1 ? commentBodyClass + '--multiple' : '';

    React.useEffect(() => {
        function onSelectedText() {
            const selection = document.getSelection();
            const selectedText = selection ? selection.toString() : '';
            if (selection.rangeCount >= 1) {
                const range = selection.getRangeAt(0);
                const container = range.commonAncestorContainer;
                if (bodyRef && bodyRef.current && bodyRef.current.contains(container)) {
                    setHighlightedText(selectedText);
                } else {
                    setHighlightedText('');
                }
            }
        }
        function onSelectionClick() {
            const selection = document.getSelection().toString();
            if (selection) {
                setHighlightedText('');
            }
        }
        document.addEventListener('selectionchange', onSelectedText);
        if (bodyRef && bodyRef.current) {
            bodyRef.current.addEventListener('mousedown', onSelectionClick);
        }

        return () => {
            document.removeEventListener('selectionchange', onSelectedText);
            if (bodyRef && bodyRef.current) {
                bodyRef.current.removeEventListener('mousedown', onSelectionClick);
            }
        }
    }, []);

    return (
        <div
            className={`
                ${wrapperClass}
                ${CLASS_COMMENT + '--' + id}
                ${isFullScreenAttachment ? CLASS_COMMENT + '--fullscreen' : ''}
                ${isFullScreenAttachment && !text ? CLASS_COMMENT + '--fullscreen-no-text' : ''}
                ${isReadonly ? CLASS_COMMENT + '--readonly' : ''}
            `}
            ref={itemRef}
            tabIndex={0}
        >
            <div className={headerClass}>
                <div className={userClass}>
                    <UserpicStorage
                        initials={getInitials(author)}
                        size={24}
                        src={author.photoUrl}
                        tooltip={getAsidePanelTooltip({
                            direction: 'up-right',
                            value: author.fullName
                        })}
                        userId={author.permissionId}
                    />
                    <div className={userNameClass}>
                        {author.fullName}
                    </div>
                </div>
                <div className={dateClass}>{dateString}</div>
            </div>
            <div className={`
                ${commentBodyClass}
                ${multiMarkdownCommentClass}
            `}
            ref={bodyRef}>
                {commentContent}
            </div>
            <div className={commentFooterClass}>
                <div className={commentFooterClass + '-left'}>
                    {!isReadonly &&
                        <Button
                            className={commentReplyClass}
                            onClick={onReplyClick}
                        >
                            {highlightedText ? COMMENT_QUOTE_AND_REPLY : COMMENT_REPLY}
                        </Button>
                    }
                </div>
                <div className={commentFooterClass + '-right'}>
                    <CommentLikesHOC
                        commentId={id}
                        cardId={cardId}
                        boardId={boardId}
                        isReadonly={isReadonly}
                    />
                    {!isReadonly &&
                        <CommentActions
                            isMyComment={isMyComment}
                            onEdit={onEdit}
                            onDelete={onRemoveHandler}
                            onGetLink={onGetLink}
                            onReply={onReplyClick}
                        />
                    }
                </div>
            </div>
        </div>
    );
}
