import * as React from 'react';
import { getMessages } from '../../../../../../store/constants';
import { TTabKey } from '../../store/types';
import { TEmptyBoards } from './types';
import { ButtonUpgradeMyPlanHOC } from '../../hocs/ButtonUpgradeMyPlanHOC/ButtonUpgradeMyPlanHOC';
import { ButtonGivePermissionsSharedDrivesHOC } from '../../hocs/ButtonGivePermissionsSharedDrivesHOC/ButtonGivePermissionsSharedDrivesHOC';
import { EAuthUserPlatformType } from 'app/types/rest/IRestAuthUser';
import { SharedDrivesScopeBlockerHOC } from '../../hocs/SharedDrivesScopeBlockerHOC/SharedDrivesScopeBlockerHOC';

export const EMPTY_DUMMY_NO_BOARDS = getMessages().getText('open_boards.empty.title');

/**
 * геттер нужен, чтоб getText вызывался после логина, тогда известен язык (def.ms)
 */
export const getEmptyDummy = (): TEmptyBoards => ({
    [TTabKey.allBoards]: {
        title: getMessages().getText('open_boards.empty.title'),
        text: getMessages().getText('open_boards.empty.text')
    },
    [TTabKey.sharedDrives]: {
        title: getMessages().getText('open_boards.empty.sharedDrives.title'),
        text: getMessages().getText('open_boards.empty.sharedDrives.text')
    },
    [TTabKey.starred]: {
        icon: 'e-starred-folder',
        title: getMessages().getText('open_boards.empty.starred.title'),
        text: getMessages().getText('open_boards.empty.starred.text')
    },
    [TTabKey.archive]: {
        icon: 'e-archive',
        title: getMessages().getText('open_boards.empty.archive.title'),
        text: getMessages().getText('open_boards.empty.archive.text')
    },
    search: {
        icon: 'e-search-folder',
        text: getMessages().getText('open_boards.empty.search.text'),
        title: getMessages().getText('open_boards.empty.search.title')
    },
    [TTabKey.sharedDrives + '-planBlocker']: {
        buttons: <ButtonUpgradeMyPlanHOC />,
        icon: 'get-premium',
        title: getMessages().getText('open_boards.blocker.sharedDrives.title'),
        text: getMessages().getText('open_boards.blocker.sharedDrives.text')
    },
    [TTabKey.sharedDrives + '-scopesBlocker']: {
        buttons: <ButtonGivePermissionsSharedDrivesHOC />,
        icon: 'permissions',
        title: getMessages().getText('open_boards.blocker.sharedDrives.title'),
        text: getMessages().getText('open_boards.blocker.permissions.sharedDrives.text')
    },
    [TTabKey.sharedDrives + '-scopesBlocker' + EAuthUserPlatformType.MICROSOFT]: {
        buttons: <SharedDrivesScopeBlockerHOC />,
        icon: 'permissions',
        title: getMessages().getText('open_boards.blocker.sharedDrives.title'),
        text: null,
    },
    [TTabKey.templates + '-planBlocker']: {
        buttons: <ButtonUpgradeMyPlanHOC />,
        icon: 'get-premium',
        title: getMessages().getText('open_boards.blocker.templates.title'),
        text: getMessages().getText('open_boards.blocker.templates.text')
    },
});
