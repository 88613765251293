export const filterArrayByAcronym = (
    list: any[],
    fieldForSearch: string,
    searchQuery: string
): any[] => {
    let result: any = [];
    const search = searchQuery.toLowerCase();
    let searchFirst = '',
        searchSecond = '';
    let searchIndex = search.length;
    while (searchIndex > 0) {
        searchFirst = search.substring(0, searchIndex);
        searchSecond = search.substring(searchIndex);
        const filtered = list.filter((item) => {
            if (!item[fieldForSearch]) return false;

            const itemForSearch = item[fieldForSearch].toLowerCase();
            let indexFirst = itemForSearch.indexOf(searchFirst);
            if (indexFirst < 0) {
                return false;
            }
            let itemForSearchSecond = itemForSearch.substring(indexFirst + searchFirst.length);
            for (
                let searchSecondIndex = 0;
                searchSecondIndex < searchSecond.length;
                searchSecondIndex++
            ) {
                let indexSecond = itemForSearchSecond.indexOf(searchSecond[searchSecondIndex]);
                if (indexSecond < 0) {
                    return false;
                }
                itemForSearchSecond = itemForSearchSecond.substring(indexSecond + 1);
            }
            return true;
        });
        result = [...new Set( // deduplicate merged array
            [...result, ...filtered]
        )];
        searchIndex--;
    };
    return result;
};
