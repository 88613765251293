import { IApplicationState } from '../../../../../../../../types/types';
import { connect } from 'react-redux';
import { IAutoBackupsTogglerHOCProps } from './types';
import { onChange } from './events/onChange';
import { ISectionTogglerEvents, ISectionTogglerFields } from '../../../../../../base/components/SectionToggler/types';
import { SectionToggler } from '../../../../../../base/components/SectionToggler/SectionToggler';
import { onClickDisallow } from './events/onClickDisallow';
import { getSectionType } from '../../../../selectors/getSectionType';
import { ESettingsSectionType } from '../../../../../../base/components/SettingsSection/types';
import {getAutoBackupsByBoardIdAndExportType} from '../../store/autoBackup/selectors/getAutoBackupsByBoardIdAndExportType';
import {EBackupExportType} from '../../../../../../../../types/rest/IRestBackup';

const mapStateToProps = (
    state: IApplicationState,
    { boardId }: IAutoBackupsTogglerHOCProps
): ISectionTogglerFields => {
    const autoBackup = getAutoBackupsByBoardIdAndExportType(state, boardId, EBackupExportType.XML);
    const sectionType = getSectionType(state, boardId, null, 'allowAutoBackupXml', 'allowCreateBackUp');
    const isDisabled = sectionType === ESettingsSectionType.READONLY;
    const isDisallowed = sectionType === ESettingsSectionType.DISALLOW;
    return {
        checked: autoBackup.enabled,
        isDisabled,
        isDisallowed,
    };
};

const mapDispatchToProps = (
    dispatch: any,
    { boardId }: IAutoBackupsTogglerHOCProps
): ISectionTogglerEvents => {
    return {
        onChange: () => dispatch(onChange(boardId)),
        onClickDisallow: () => dispatch(onClickDisallow()),
    }
};

export const AutoBackupsTogglerHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(SectionToggler);

AutoBackupsTogglerHOC.displayName = 'AutoBackupsTogglerHOC';
