'use strict';
import * as _ from 'underscore';
App.Views.SystemAccessNotification = App.Views.SystemNotification.extend({
    getActions: function() {
        return _.extend(
            App.Views.SystemNotification.prototype.getActions.call(this),
            {
                icon: 'lock',
                action: Messages.getText('noty.system_access.action'),
                bodyRaw: Messages.getText('noty.system_access.body'),
                body: App.Helpers.MarkdownHelper.stripTags(Messages.getText('noty.system_access.body'))
            }
        )
    },

    onClick: function(e, notification) {
        App.controller.showPayment();
        App.controller.trackEvent(
            Messages.getText('ga.category.subscription'),
            Messages.getText('ga.action.new_features.facebook_new_features')
        );
        App.Views.SystemNotification.prototype.onClick.call(this, e, notification);
    }
});
