import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId, TTag } from '../../../../../../../../types/types';
import { renameTag } from '../effects/renameTag';

export const onRename = (
    cardId: TCardId,
    tag: TTag,
    name: TTag
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(renameTag(cardId, tag, name));
    };
    return action;
};
