import { TBoardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { root } from '../../../../store/constants';

export const boardOpen = (
    boardId: TBoardId,
    routeAction: string = ''
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        root.App.router.navigate(root.App.router.getBoardUrl(boardId, routeAction), {trigger: true});
    }
    return action;
}
