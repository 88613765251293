import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { SegmentCardEvent, SegmentCardOptions, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { IGetState, TCardId } from '../../../../../../../../types/types';
import { SegmentCardSourceValue } from '../../../../../../../../middlewares/segment/trackEntities/cardEvents';
import { closeAsidePanelIfNeeded } from '../effects/closeAsidePanelIfNeeded';

export const onClick = (
    source: SegmentCardSourceValue,
    cardId: TCardId,
): ThunkAction => {
    return (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (source !== SegmentCardSourceValue.SUBCARDS_LIST) closeAsidePanelIfNeeded(getState(), cardId);
        dispatch(segmentTrackAction(SegmentCardEvent.CARD_DROPDOWN_BUTTON_CLICKED, {
            name: SegmentCardOptions.SOURCE,
            value: source
        }));
    };
};
