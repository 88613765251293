import {connect} from 'react-redux';
import {UnarchiveButton} from '../../components/UnarchiveButton/UnarchiveButton';
import {IApplicationState} from '../../../../../../../types/types';
import {IUnarchiveButtonEvents, IUnarchiveButtonFields} from '../../components/UnarchiveButton/types';
import {IUnarchiveButtonHOCProps} from './types';
import {onClick} from './events/onClick';
import { getSectionType } from '../../../selectors/getSectionType';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { getBoard } from '../../../../../../../store/model/selectors/getBoardById';
import { TStatus } from '../../../../../../../types/model';
import { getAuthUserSupport } from '../../../../../../../store/model/authUser/selectors/getAuthUserSupport';
import { getShowProFeaturesTrial } from '../../../../../../../store/model/authUser/selectors/getShowProFeaturesTrial';
import { getIsUserBasicPlanOnly } from '../../../../../../../store/model/authUser/selectors/getIsUserBasicPlanOnly';

const mapStateToProps = (
    state: IApplicationState,
    props: IUnarchiveButtonHOCProps,
): IUnarchiveButtonFields => {
    let { boardId } = props;
    const sectionType = getSectionType(state, boardId, null, null, 'allowArchive');
    const isDisallow = sectionType === ESettingsSectionType.DISALLOW;
    const { status } = getBoard(state, boardId);
    const isShow = (
        getAuthUserSupport(state,'supportBoardArchive') &&
        status === TStatus.STATUS_ARCHIVE &&
        (sectionType === ESettingsSectionType.DEFAULT || isDisallow)
    );
    return {
        isBasicFeature: getIsUserBasicPlanOnly(state),
        isDisallow,
        isProFeature: getShowProFeaturesTrial(state),
        isShow,
        tooltip: null,
    }
};
const mapDispatchToProps = (
    dispatch: any,
    { boardId }: IUnarchiveButtonHOCProps
): IUnarchiveButtonEvents => ({
    onClick: () => dispatch(onClick(boardId)),
});

export const UnarchiveButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(UnarchiveButton);

UnarchiveButtonHOC.displayName = 'UnarchiveButtonHOC';
