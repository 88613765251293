import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../types/actions';
import { EHintKey, getHintConfig, TUTORIAL_STEP_DELAY } from '../../../constants';
import { IGetState } from '../../../../../../types/types';
import { getHint } from '../../../store/hints/selectors/getHint';
import { hintHide } from '../../../effects/hintHide';
import { hintTutorialHide } from '../../../effects/hintTutorialHide';
import { hintTutorialStep } from '../../../effects/hintTutorialStep';

export const onTutorialNext: ActionCreator<ThunkAction> = (
    key: EHintKey,
    ownOnTutorialNext?: () => void
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const hintConfig = getHintConfig(key);
        if (!hintConfig || !hintConfig.tutorial) return;

        const hint = {...getHint(state, key)};

        if (!hint.step) hint.step = 0;

        if (hint.step >= hintConfig.tutorial.length - 1) { // finish
            dispatch(hintHide(key));
        } else {
            dispatch(hintTutorialHide(key));
            setTimeout(() => {
                dispatch(hintTutorialStep(key, hint.step + 1));
            }, TUTORIAL_STEP_DELAY);
        }
        if (ownOnTutorialNext) ownOnTutorialNext();
    };
    return action;
};
