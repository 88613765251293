import { IApplicationState } from '../../../../../../../../types/types';
import { connect } from 'react-redux';
import { BoardBackups } from '../../components/BoardBackups/BoardBackups';
import { IBoardBackupsHOCProps } from './types';
import { IBackupListener, IBoardBackupsEvents, IBoardBackupsFields } from '../../components/BoardBackups/types';
import { onBackupRestore } from './events/onBackupRestore';
import { onBackupCreate } from './events/onBackupCreate';
import { getSectionType } from '../../../../selectors/getSectionType';
import { ESettingsSectionType } from '../../../../../../base/components/SettingsSection/types';
import { isAllowCreateBoard } from '../../../../../../../../store/model/authUser/selectors/isAllowCreateBoard';
import { getBoardBackupListByExportType } from '../../../../../../../../store/model/boardBackups/selectors/getBoardBackupListByExportType';
import { EBackupExportType } from '../../../../../../../../types/rest/IRestBackup';
import {
    getAuthUserPlatformType
} from '../../../../../../../../store/model/authUser/selectors/getAuthUserPlatformType';
import { TABS } from '../../../../../../dialogs/openBoards/store/constants';
import { TTabKey } from '../../../../../../dialogs/openBoards/store/types';
import { getAuthUser } from '../../../../../../../../store/model/authUser/selectors/getAuthUser';
import { CommonAuthManager } from '../../../../../../../../helper/authorisation/common/CommonAuthManager';
import { onLoadSharedDrives } from './events/onLoadSharedDrives';
import { isMicrosoftPersonalAccount } from 'app/store/model/authUser/selectors/isMicrosoftPersonalAccount';
import { EAuthUserPlatformType } from 'app/types/rest/IRestAuthUser';

const mapStateToProps = (
    state: IApplicationState,
    ownProps: IBoardBackupsHOCProps
): IBoardBackupsFields => {
    const { boardId } = ownProps;
    const backups = getBoardBackupListByExportType(state, boardId, EBackupExportType.XML);
    const sectionType = getSectionType(state, boardId, null, 'allowBackupXml', 'allowCreateBackUp');
    const isCreateDisabled = (
        sectionType === ESettingsSectionType.DISALLOW || // !allowCreateBackUp
        sectionType === ESettingsSectionType.READONLY// !allowBackupXml
    );
    const isRestoreDisabled = isCreateDisabled;

    const platformType = getAuthUserPlatformType(state);
    let planBlocker = null;
    let scopesBlocker = null;
    const tab = TABS[TTabKey.sharedDrives];
    const allow = tab.allow;
    if (allow) {
        const user = getAuthUser(state);
        if (
            user &&
            !user[allow] &&
            tab.showPlanBlocker
        ) {
            planBlocker = tab.showPlanBlocker;
        }
    }

    if (!planBlocker && tab.scopes) {
        const scopes = tab.scopes[platformType];
        if (scopes &&
            !CommonAuthManager.checkOneOfScopes(scopes.success)
        ) {
            scopesBlocker = scopes.emptyDummyId;
        }
    }

    return {
        backups,
        isCreateDisabled,
        isRestoreDisabled,
        planBlocker,
        scopesBlocker,
        platformType: getAuthUserPlatformType(state),
        isShowRestoreToSharedDrive: platformType === EAuthUserPlatformType.GOOGLE || !isMicrosoftPersonalAccount(state),
    }
};

const mapDispatchToProps = (
    dispatch: any,
    ownProps: IBoardBackupsHOCProps
): IBoardBackupsEvents => {
    const { boardId } = ownProps;
    return {
        onBackupRestore: (fileId, driveId) => dispatch(onBackupRestore(boardId, fileId, driveId)),
        onBackupCreate: (eventListener: IBackupListener) => dispatch(onBackupCreate(boardId, eventListener)),
        onLoadSharedDrives: () => dispatch(onLoadSharedDrives())
    }
};

export const BoardBackupsHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(BoardBackups);

BoardBackupsHOC.displayName = 'BoardBackupsHOC';
