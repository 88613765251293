import { AT_SUBSCRIPTIONS_ACTION_SET } from './constants';
import { TSubscriptionsAction } from '../../subscriptions/actions/types';
import { ISubscriptionsActionSetAction } from './types';

export const subscriptionsActionSetAction = (
    subscriptionsAction: TSubscriptionsAction,
): ISubscriptionsActionSetAction => ({
    type: AT_SUBSCRIPTIONS_ACTION_SET,
    subscriptionsAction,
});
