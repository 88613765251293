import { getController, getMessages } from '../../../../store/constants';
import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState } from '../../../../types/types';

const gaEffect =
(
    category: string,
    action: string,
) => {
    const categoryText = getMessages().getText(category);
    const actionText = getMessages().getText(action);
    getController().trackEvent(categoryText, actionText);
}

export const reportsClickedStatisticEffect: ActionCreator<ThunkAction> =
() => {
    const action = (dispatch: Dispatch, getState: IGetState) => {
        gaEffect('ga.category.reports', 'ga.action.reports.reports_clicked');
    }
    return action;
}

// opened

export const cumulativeOpenedFromDashboardsStatisticEffect: ActionCreator<ThunkAction> =
() => {
    const action = (dispatch: Dispatch, getState: IGetState) => {
        gaEffect('ga.category.reports', 'ga.action.cumulative.report.opened_from_dashboard');
    }
    return action;
}

export const cumulativeOpenedFromInsightsStatisticEffect: ActionCreator<ThunkAction> =
() => {
    const action = (dispatch: Dispatch, getState: IGetState) => {
        gaEffect('ga.category.reports', 'ga.action.cumulative.report.opened_from_insights');
    }
    return action;
}

export const burndownOpenedFromDashboardsStatisticEffect: ActionCreator<ThunkAction> =
() => {
    const action = (dispatch: Dispatch, getState: IGetState) => {
        gaEffect('ga.category.reports', 'ga.action.burndown.report.opened_from_dashboard');
    }
    return action;
}

export const burndownOpenedFromInsightsStatisticEffect: ActionCreator<ThunkAction> =
() => {
    const action = (dispatch: Dispatch, getState: IGetState) => {
        gaEffect('ga.category.reports', 'ga.action.burndown.report.opened_from_insights');
    }
    return action;
}

export const teamPerformanceOpenedFromDashboardsStatisticEffect: ActionCreator<ThunkAction> =
() => {
    const action = (dispatch: Dispatch, getState: IGetState) => {
        gaEffect('ga.category.reports', 'ga.action.teamPerformance.report.opened_from_dashboard');
    }
    return action;
}

export const userProductivityOpenedFromDashboardsStatisticEffect: ActionCreator<ThunkAction> =
    () => {
        const action = (dispatch: Dispatch, getState: IGetState) => {
            gaEffect('ga.category.reports', 'ga.action.userProductivity.report.opened_from_dashboard');
        }
        return action;
    }
export const userProductivityOpenedFromInsightsStatisticEffect: ActionCreator<ThunkAction> =
    () => {
        const action = (dispatch: Dispatch, getState: IGetState) => {
            gaEffect('ga.category.reports', 'ga.action.teamPerformance.report.opened_from_insights');
        }
        return action;
    }
export const teamPerformanceOpenedFromInsightsStatisticEffect: ActionCreator<ThunkAction> =
() => {
    const action = (dispatch: Dispatch, getState: IGetState) => {
        gaEffect('ga.category.reports', 'ga.action.teamPerformance.report.opened_from_insights');
    }
    return action;
}

export const burnupOpenedFromDashboardsStatisticEffect: ActionCreator<ThunkAction> =
() => {
    const action = (dispatch: Dispatch, getState: IGetState) => {
        gaEffect('ga.category.reports', 'ga.action.burnup.report.opened_from_dashboard');
    }
    return action;
}

export const burnupOpenedFromInsightsStatisticEffect: ActionCreator<ThunkAction> =
() => {
    const action = (dispatch: Dispatch, getState: IGetState) => {
        gaEffect('ga.category.reports', 'ga.action.burnup.report.opened_from_insights');
    }
    return action;
}

// filters cumulative

export const cumulativePrintedStatisticEffect: ActionCreator<ThunkAction> =
() => {
    const action = (dispatch: Dispatch, getState: IGetState) => {
        gaEffect('ga.category.reports', 'ga.action.cumulative.report_printed');
    }
    return action;
}

export const cumulativeListsEditedStatisticEffect: ActionCreator<ThunkAction> =
() => {
    const action = (dispatch: Dispatch, getState: IGetState) => {
        gaEffect('ga.category.reports', 'ga.action.cumulative.report_filter');
        gaEffect('ga.category.reports', 'ga.action.cumulative.report_lists');
    }
    return action;
}

export const cumulativeDatesEditedStatisticEffect: ActionCreator<ThunkAction> =
() => {
    const action = (dispatch: Dispatch, getState: IGetState) => {
        gaEffect('ga.category.reports', 'ga.action.cumulative.report_filter');
        gaEffect('ga.category.reports', 'ga.action.cumulative.report_dates');
    }
    return action;
}

// filters burndown

export const burndownPrintedStatisticEffect: ActionCreator<ThunkAction> =
() => {
    const action = (dispatch: Dispatch, getState: IGetState) => {
        gaEffect('ga.category.reports', 'ga.action.burndown.report_printed');
    }
    return action;
}

export const burndownIdealLineStatisticEffect: ActionCreator<ThunkAction> =
() => {
    const action = (dispatch: Dispatch, getState: IGetState) => {
        gaEffect('ga.category.reports', 'ga.action.burndown.report.report_filter');
        gaEffect('ga.category.reports', 'ga.action.burndown.report.report_ideal');
    }
    return action;
}

export const burndownListsEditedStatisticEffect: ActionCreator<ThunkAction> =
() => {
    const action = (dispatch: Dispatch, getState: IGetState) => {
        gaEffect('ga.category.reports', 'ga.action.burndown.report.report_filter');
        gaEffect('ga.category.reports', 'ga.action.burndown.report.report_lists');
    }
    return action;
}
export const burndownDatesEditedStatisticEffect: ActionCreator<ThunkAction> =
() => {
    const action = (dispatch: Dispatch, getState: IGetState) => {
        gaEffect('ga.category.reports', 'ga.action.burndown.report.report_filter');
        gaEffect('ga.category.reports', 'ga.action.burndown.report.report_dates');
    }
    return action;
}
export const burndownDoneCardsStatisticEffect: ActionCreator<ThunkAction> =
() => {
    const action = (dispatch: Dispatch, getState: IGetState) => {
        gaEffect('ga.category.reports', 'ga.action.burndown.report.report_filter');
        gaEffect('ga.category.reports', 'ga.action.burndown.report.report_done_cards');
    }
    return action;
}
export const burndownDoneListStatisticEffect: ActionCreator<ThunkAction> =
() => {
    const action = (dispatch: Dispatch, getState: IGetState) => {
        gaEffect('ga.category.reports', 'ga.action.burndown.report.report_filter');
        gaEffect('ga.category.reports', 'ga.action.burndown.report.report_done_list');
    }
    return action;
}

// filters teamPerformance
export const teamPerformancePrintedStatisticEffect: ActionCreator<ThunkAction> =
() => {
    const action = (dispatch: Dispatch, getState: IGetState) => {
        gaEffect('ga.category.reports', 'ga.action.teamPerformance.report.report_printed')
    }
    return action;
}

export const teamPerformanceDatesEditedStatisticEffect: ActionCreator<ThunkAction> =
() => {
    const action = (dispatch: Dispatch, getState: IGetState) => {
        gaEffect('ga.category.reports', 'ga.action.userProductivity.report.report_filter');
        gaEffect('ga.category.reports', 'ga.action.userProductivity.report.report_dates');
    }
    return action;
}

export const teamPerformanceUsersEditedStatisticEffect: ActionCreator<ThunkAction> =
() => {
    const action = (dispatch: Dispatch, getState: IGetState) => {
        gaEffect('ga.category.reports', 'ga.action.teamPerformance.report.report_filter');
        gaEffect('ga.category.reports', 'ga.action.teamPerformance.report.report_users');
    }
    return action;
}

export const userProductivityUsersFilterEditedStatisticEffect: ActionCreator<ThunkAction> =
    () => {
        const action = (dispatch: Dispatch, getState: IGetState) => {
            gaEffect('ga.category.reports', 'ga.action.teamPerformance.report.report_filter');
            gaEffect('ga.category.reports', 'ga.action.teamPerformance.report.report_users');
        }
        return action;
    }

export const userProductivityDatesEditedStatisticEffect: ActionCreator<ThunkAction> =
    () => {
        const action = (dispatch: Dispatch, getState: IGetState) => {
            gaEffect('ga.category.reports', 'ga.action.userProductivity.report.report_filter');
            gaEffect('ga.category.reports', 'ga.action.userProductivity.report.report_dates');
        }
        return action;
    }

export const teamPerformanceDetailedStatisticEffect: ActionCreator<ThunkAction> =
(
    dashboardId: number,
) => {
    const action = (dispatch: Dispatch, getState: IGetState) => {
        const appState = getState();
        const dashboardReports = appState.reports[dashboardId];
        const reportType = dashboardReports.activeReportType;

        if (reportType) {
            /*const isDetailed = dashboardReports.reportDetails[reportType].reportSettings.isDetailed;

            if (isDetailed) {
                // gaEffect('ga.category.reports', 'ga.action.teamPerformance.report.report_filter');
                gaEffect('ga.category.reports', 'ga.action.teamPerformance.report.report_detailed');
            } else {
                // gaEffect('ga.category.reports', 'ga.action.teamPerformance.report.report_filter');
                gaEffect('ga.category.reports', 'ga.action.teamPerformance.report.report_summary');
            }*/
        }
    }

    return action;
}

// filter burnup

export const burnupIdealLineStatisticEffect: ActionCreator<ThunkAction> =
() => {
    const action = (dispatch: Dispatch, getState: IGetState) => {
        gaEffect('ga.category.reports', 'ga.action.burnup.report.report_filter');
        gaEffect('ga.category.reports', 'ga.action.burnup.report.report_ideal');
    }
    return action;
}

export const burnupPrintedStatisticEffect: ActionCreator<ThunkAction> =
() => {
    const action = (dispatch: Dispatch, getState: IGetState) => {
        gaEffect('ga.category.reports', 'ga.action.burnup.report_printed');
    }
    return action;
}

export const burnupListsEditedStatisticEffect: ActionCreator<ThunkAction> =
() => {
    const action = (dispatch: Dispatch, getState: IGetState) => {
        gaEffect('ga.category.reports', 'ga.action.burnup.report.report_filter');
        gaEffect('ga.category.reports', 'ga.action.burnup.report.report_lists');
    }
    return action;
}
export const burnupDatesEditedStatisticEffect: ActionCreator<ThunkAction> =
() => {
    const action = (dispatch: Dispatch, getState: IGetState) => {
        gaEffect('ga.category.reports', 'ga.action.burnup.report.report_filter');
        gaEffect('ga.category.reports', 'ga.action.burnup.report.report_dates');
    }
    return action;
}
export const burnupDoneCardsStatisticEffect: ActionCreator<ThunkAction> =
() => {
    const action = (dispatch: Dispatch, getState: IGetState) => {
        gaEffect('ga.category.reports', 'ga.action.burnup.report.report_filter');
        gaEffect('ga.category.reports', 'ga.action.burnup.report.report_done_cards');
    }
    return action;
}
export const burnupDoneListStatisticEffect: ActionCreator<ThunkAction> =
() => {
    const action = (dispatch: Dispatch, getState: IGetState) => {
        gaEffect('ga.category.reports', 'ga.action.burnup.report.report_filter');
        gaEffect('ga.category.reports', 'ga.action.burnup.report.report_done_list');
    }
    return action;
}
