import { IChecklistActionSetAction } from './types';
import { AT_CHECKLIST_ACTION_SET } from './constants';
import { TCardId, TChecklistId } from '../../../../../types/types';
import { TChecklistAction } from '../../checklist/actions/types';

export const checklistActionSetAction = (
    cardId: TCardId,
    checklistId: TChecklistId,
    checklistAction: TChecklistAction
): IChecklistActionSetAction => {
    return {
        type: AT_CHECKLIST_ACTION_SET,
        cardId,
        checklistId,
        checklistAction
    }
};
