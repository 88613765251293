import { connect } from 'react-redux';
import { IBrowserNotificationsRequestHOCProps } from './types';
import { onCancel } from './events/onCancel';
import { onAllow } from './events/onAllow';
import { onDidMount } from './events/onDidMount';
import { INotificationsRequestEvents, INotificationsRequestFields } from '../../components/NotificationsRequest/types';
import { NotificationsRequest } from '../../components/NotificationsRequest/NotificationsRequest';
import { IApplicationState } from '../../../../../../types/types';
import { BROWSER_NOTIFICATIONS_REQUEST_LABEL_ALLOW, BROWSER_NOTIFICATIONS_REQUEST_TEXT } from './constants';

const mapStateToProps = (
    state: IApplicationState
): INotificationsRequestFields => {
    return ({
        allowText: BROWSER_NOTIFICATIONS_REQUEST_LABEL_ALLOW,
        text: BROWSER_NOTIFICATIONS_REQUEST_TEXT
    });
}

const mapDispatchToProps = (
    dispatch: any,
    { onAllow: onAllowOwn }: IBrowserNotificationsRequestHOCProps
): INotificationsRequestEvents => {
    return {
        onCancel: () => dispatch(onCancel()),
        onAllow: () => dispatch(onAllow(onAllowOwn)),
        onDidMount: () => dispatch(onDidMount())
    }
};

export const BrowserNotificationsRequestHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationsRequest);

BrowserNotificationsRequestHOC.displayName = 'BrowserNotificationsRequestHOC';
