import { IGetState, TCardId, TChecklistItemId } from '../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { fetchHandler } from '../../../../../util/fetchHandler';
import Util from '../../../../../util/util';
import { REST_CHECKLIST_ITEM } from '../../../../constants';
import { getRestHeadersDelete } from '../../../../helpers/getRestHeadersHelper';
import { IRestChecklistItem } from '../../../../../types/rest/IRestChecklistItem';

export const itemRestDelete = (
    cardId: TCardId,
    itemId: TChecklistItemId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return fetchHandler<IRestChecklistItem>(
            Util.getApiUrl(REST_CHECKLIST_ITEM + itemId), {
                ...getRestHeadersDelete()
            }
        );
    };
    return action;
};
