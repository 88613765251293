import * as React from 'react';
import { Button } from 'kui';
import { IBasicUpgradeButtonProps } from './types';
import './_BasicUpgradeButton.scss';
import { BASIC_UPGRADE_BUTTON_LABEl, CLASS_BASIC_UPGRADE_BUTTON, } from './constants';

export const BasicUpgradeButton = ({
    onUpgrade
}: IBasicUpgradeButtonProps) => (
    <Button
        className={CLASS_BASIC_UPGRADE_BUTTON}
        variant={'primary'}
        onClick={onUpgrade}
    >
        {BASIC_UPGRADE_BUTTON_LABEl}
    </Button>
);
