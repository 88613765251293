'use strict';

import * as React from 'react';
import { renderToStaticMarkup } from "react-dom/server";
import { getAppState } from '../../store/configureStore';
import { getBoardIdByCardId } from '../../store/model/selectors/getBoardIdByCardId';
import { getBoardWorkHoursInDay } from '../../store/model/selectors/getBoardWorkHoursInDay';
import { CLASS_CARD_ACTIVITY_ITEM_ADD_VALUE, CLASS_CARD_ACTIVITY_ITEM_REMOVE_VALUE } from '../react_components/aside_panel/boardDetails/activity/TabActivity/components/ActivityItemBody/constants';
import { getActivityItem } from "../react_components/aside_panel/boardDetails/activity/TabActivity/helpers/aсtivityHelper";
import { convertTimeNumberToString } from '../react_components/aside_panel/cardDetails/helpers/convertTimeNumberToString';
import { Icon } from 'kui';

App.Views.CardUpdateEstimateNotification = App.Views.BaseNotification.extend({

    getActions: function() {
        const item = getActivityItem(this.notificationModel);
        const { oldValue, newValue } = this.notificationModel;
        const boardId = getBoardIdByCardId(getAppState(), this.notificationModel.card.id);
        const workHoursInDay = getBoardWorkHoursInDay(getAppState(), boardId);
        return {
            bodyRaw: renderToStaticMarkup(<>
                {!!oldValue &&
                    <span className={CLASS_CARD_ACTIVITY_ITEM_REMOVE_VALUE}>
                        <Icon xlink={ 'estimate' } size={ 16 } />
                        { convertTimeNumberToString(Number(oldValue), false, workHoursInDay) }
                    </span>
                }
                {!!newValue &&
                    <span className={CLASS_CARD_ACTIVITY_ITEM_ADD_VALUE}>
                        <Icon xlink={ 'estimate' } size={ 16 } />
                        { convertTimeNumberToString(Number(newValue), false, workHoursInDay) }
                    </span>
                }
            </>),
            action: item.label,
            actionData: {
                icon: 'estimate'
            }
        };
    },

    onClick: function(e, notification) {
        var dashboardId = this.notificationModel.dashboardId;
        var cardId = this.notificationModel.card.id;
        App.controller.cleverOpenCard(dashboardId, cardId);
        this.closeNotification(notification);
        e.stopPropagation();
    }
});
