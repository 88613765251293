import { BOARD_USER_ACTIVITY_TIMES_ACTION_SET, IBoardUserActivityTimesActionSetAction } from './types';
import { IUserBoardActivityTimesAction } from '../boardUserActivityTimes/actions/types';

export const userBoardActivityTimesActionSetAction = (
    action: IUserBoardActivityTimesAction
): IBoardUserActivityTimesActionSetAction => {
    return {
        type: BOARD_USER_ACTIVITY_TIMES_ACTION_SET,
        action
    }
};
