import * as React from 'react';
import './_LogoSelect.scss';
import { ILogoSelectProps } from './types';
import { Button, Divider, Icon } from 'kui';
import {
    CLASS_LOGO,
    LOGO_COLOR_DEFAULT,
    LOGO_ICONS,
    LOGO_BG_COLORS,
    getIconColor
} from '../constants';

export function LogoSelect({
    logo,
    isChangeColorOnReset = false,
    isDisabled,
    onChange
}: ILogoSelectProps) {
    const currentIcon = logo && logo.icon && LOGO_ICONS.includes(logo.icon) ? logo.icon : null;
    const currentColor = logo ? LOGO_BG_COLORS.includes(logo.color) && logo.color ||
        LOGO_COLOR_DEFAULT : null;

    const className = CLASS_LOGO + '-select';
    const classIcon = className + '__icon';
    const classColor = className + '__color';

    const ref = React.useRef(null);

    const onFocus = (
        isColor?: boolean
    ) => {
        setTimeout(() => {
            let el = document.querySelector('.' + (isColor ? classColor : classIcon) + '--selected') as HTMLElement;
            if (!el && ref.current) { // focus dropdown to be able to close on blur
                ref.current.focus();
            }
            if (el) {
                el.focus();
            }
        }, 0);
    };

    const onIconChange = (newIcon: string) => {
        let icon = newIcon;
        let color = currentColor;
        if (icon === currentIcon) {
            icon = '';
            color = isChangeColorOnReset ? LOGO_COLOR_DEFAULT : currentColor;
        }
        onChange({ icon, color });
        onFocus();
    };

    const onColorChange = (color: string) => {
        onChange({ icon: currentIcon, color });
        onFocus(true);
    };

    const onKeyDown = (
        e: React.KeyboardEvent,
        color: string
    ) => {
        if (e.key === 'Enter') {
            onColorChange(color);
        }
    };

    React.useEffect(() => {
        onFocus();
    }, []);

    return (
        <div className={className} tabIndex={-1} ref={ref}>
            <div className={className + '__icons'}>
                {LOGO_ICONS.map(icon => (
                    <Button
                        className={`
                            ${classIcon}
                            ${currentIcon === icon ? classIcon + '--selected' : ''}
                        `}
                        style={currentColor && currentIcon === icon ?
                            { background: currentColor, color: getIconColor(currentColor) } : null}
                        variant={'icon'}
                        onClick={() => onIconChange(icon)}
                        key={icon}
                        disabled={isDisabled}
                    >
                        <Icon xlink={icon} size={24} />
                    </Button>
                ))}
            </div>
            <Divider />
            <div className={className + '__colors'}>
                {LOGO_BG_COLORS.map(color => (
                    <div
                        className={`
                            ${classColor}
                            ${currentColor === color ? classColor + '--selected' : ''}
                            ${isDisabled ? classColor + '--disabled' : ''}
                        `}
                        style={{ background: color }}
                        role={'button'}
                        onClick={() => onColorChange(color)}
                        onKeyDown={(e) => onKeyDown(e, color)}
                        tabIndex={0}
                        key={color}
                    />
                ))}
            </div>
        </div>
    );
}
