import { IApplicationState, TBoardId } from '../../../../../../../../../types/types';
import { getInitialState } from '../../cardNumber/constants';
import { ICardNumberState } from '../../cardNumber/types';

export const getCardNumberByBoardId = (
    state: IApplicationState,
    boardId: TBoardId
): ICardNumberState => {
    return state.cardNumbers[boardId] ? state.cardNumbers[boardId] : getInitialState();
}
