import { IApplicationState, TListId, TSwimlaneId } from '../../../types/types';
import { TStatus } from '../../../types/model';
import { ICard } from '../card/types';
import { getList } from '../list/selectors/getList';
import { getCard } from './getCard';
import { getIsCardMatchFilters } from '../../../view/react_components/aside_panel/filterPanelBoard/store/filterMatch/selectors/getIsCardMatchFilters';
import { getBoardIdByListId } from './getBoardIdByListId';
import { getTypeSwitcherActiveType } from '../../../view/react_components/typeSwitcher/store/selectors/getTypeSwitcherActiveType';
import { EViewTypes } from '../../../const';
import { SWIMLANE_EMPTY } from '../../../view/react_components/main/kanbanView';

export const getListFilteredCardsByStatus = (
    state: IApplicationState,
    listId: TListId,
    status: TStatus = TStatus.STATUS_ACTIVE,
    swimlaneId?: TSwimlaneId,
): ICard[] => {
    const list = getList(state, listId);
    if (
        !list ||
        !list.cardIds ||
        !list.cardIds.length
    ) return [];

    let filterCardsBySwimlane: TFilterCardsBySwimlane;

    if (!!swimlaneId) {
        const boardId = getBoardIdByListId(state, listId);
        const type = getTypeSwitcherActiveType(state, boardId);
        switch (type) {
            case EViewTypes.SWIM_COLOR_TAGS_VIEW_TYPE:
                filterCardsBySwimlane = (card) => {
                    if (swimlaneId === SWIMLANE_EMPTY) return !card.colorIds || !card.colorIds.length;
                    return card.colorIds && card.colorIds.includes && card.colorIds.includes(+swimlaneId);
                }
                break;
            case EViewTypes.SWIM_TAGS_VIEW_TYPE:
                filterCardsBySwimlane = (card) => {
                    if (swimlaneId === SWIMLANE_EMPTY) return !card.tags || !card.tags.length;
                    return card.tags && card.tags.includes && card.tags.includes(swimlaneId);
                }
                break;
            default:
                return null;
        }
    }

    const cards = list.cardIds.reduce((arr, cardId) => {
        const card = getCard(state, cardId);
        if (!card) return arr;

        if (filterCardsBySwimlane && !filterCardsBySwimlane(card)) return arr;

        if (
            card.status === status &&
            getIsCardMatchFilters(state, card.id)
        ) {
            arr.push(card);
        }
        return arr;
    }, []);

    return cards;
};

type TFilterCardsBySwimlane = (
    card: ICard
) => boolean;
