import React from "react";
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import { TimeTrackerDialogHOC } from "../react_components/dialogs/timeTrackerDialog/hocs/TTDialogHOC/TTDIalogHOC";


export const TimeTrackerDialogView = App.Views.BaseView.extend({

    initialize: function(options) {
        this.root = createRoot(this.el);
        this.store = options.store;
        this.callBackCardId = options.callBackCardId;
        this.render();
    },

    render: function() {
        this.root.render(
            <Provider store={this.store}>
                <TimeTrackerDialogHOC callBackCardId={this.callBackCardId}/>
            </Provider>
        );
        return this;
    },

    remove: function () {
        this.root.unmount();
        Backbone.View.prototype.remove.call(this);
    }
});
