import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../types/types';
import { SegmentCardEvent, SegmentCardOptions, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import {
    ERecurringTaskEndType,
    ERecurringTaskFrequencyType,
    IRestRecurringTask
} from '../../../../../../../../types/rest/recurringTask/IRestRecurringTask';
import {
    SegmentCardOptionRecurringEndsTypeValue,
    SegmentCardOptionRecurringPeriodTypeValue
} from '../../../../../../../../middlewares/segment/trackEntities/cardEvents';

const mapPeriod = (
    frequencyType: ERecurringTaskFrequencyType
): SegmentCardOptionRecurringPeriodTypeValue => {
    switch (frequencyType) {
        case ERecurringTaskFrequencyType.DAY: return SegmentCardOptionRecurringPeriodTypeValue.DAY;
        case ERecurringTaskFrequencyType.WEEK: return SegmentCardOptionRecurringPeriodTypeValue.WEEK;
        case ERecurringTaskFrequencyType.YEAR: return SegmentCardOptionRecurringPeriodTypeValue.YEAR;
    }
    return SegmentCardOptionRecurringPeriodTypeValue.MONTH;
}

const mapEnds = (
    frequencyType: ERecurringTaskEndType
): SegmentCardOptionRecurringEndsTypeValue => {
    switch (frequencyType) {
        case ERecurringTaskEndType.DATE: return SegmentCardOptionRecurringEndsTypeValue.ON;
        case ERecurringTaskEndType.REPEATS:return SegmentCardOptionRecurringEndsTypeValue.AFTER;
    }
    return SegmentCardOptionRecurringEndsTypeValue.NEVER;
}

export const addRecurringEvents = (
    recurringTask: IRestRecurringTask,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.MAKE_RECURRING_SAVE_CLICKED));
        dispatch(segmentTrackAction(SegmentCardEvent.RECURRING_PERIOD_CHOSEN, {
            name: SegmentCardOptions.TYPE,
            value: mapPeriod(recurringTask.frequencyType)
        }));
        dispatch(segmentTrackAction(SegmentCardEvent.RECURRING_ENDS_CHOSEN, {
            name: SegmentCardOptions.TYPE,
            value: mapEnds(recurringTask.endType)
        }));
    };
    return action;
};

export const updateRecurringEvents = (
    oldRecurringTask: IRestRecurringTask,
    newRecurringTask: IRestRecurringTask
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (oldRecurringTask.frequencyType !== newRecurringTask.frequencyType) {
            dispatch(segmentTrackAction(SegmentCardEvent.RECURRING_PERIOD_CHOSEN, {
                name: SegmentCardOptions.TYPE,
                value: mapPeriod(newRecurringTask.frequencyType)
            }));
        }
        if (oldRecurringTask.endType !== newRecurringTask.endType) {
            dispatch(segmentTrackAction(SegmentCardEvent.RECURRING_ENDS_CHOSEN, {
                name: SegmentCardOptions.TYPE,
                value: mapEnds(newRecurringTask.endType)
            }));
        }
    };
    return action;
};
