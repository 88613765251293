import {ActionCreator} from 'redux';
import {Dispatch, ThunkAction} from '../../../../../../../types/actions';
import {IGetState} from '../../../../../../../types/types';
import { ESegmentBoardMenuLocation, boardRenameClickedSegmentEvent } from '../../../../../../../middlewares/segment/trackEntities/boardMenuEvents';
import { nameEditing } from '../effects/nameEditing';

export const onClickRename: ActionCreator<ThunkAction> = (
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(nameEditing());
        dispatch(boardRenameClickedSegmentEvent(ESegmentBoardMenuLocation.topBar));
    };
    return action;
};
