import * as React from 'react';
import { ICardAssigneesProps } from './types';
import { CARD_CLASS } from '../../constants';
import './CardAssignees.scss';
import { UsersTooltip } from '../../../../base/components/UsersTooltip/UsersTooltip';

export function CardAssignees({
    assignees,
    authUserPermissionId,
    boardRoles,
    boardUsers
}: ICardAssigneesProps) {
    const cardAssigneesClass = CARD_CLASS + '__assignees';

    return (
        <div className={cardAssigneesClass}>
            <UsersTooltip
                authUserPermissionId={authUserPermissionId}
                isBig
                roles={boardRoles}
                users={assignees}
                boardUsers={boardUsers}
            />
        </div>
    );
}
