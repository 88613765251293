import { IGetState, TCardId } from '../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import {
    SegmentCardEvent,
    SegmentCardOptions,
    SegmentCardSection,
    segmentTrackAction
} from '../../../../../../../middlewares/segment';
import { cardCopyWithSubcardsPrompt } from '../../../../../helpers/cardCopyWithSubcardsPrompt';
import { SegmentCardSourceValue } from '../../../../../../../middlewares/segment/trackEntities/cardEvents';
import { getCard } from '../../../../../../../store/model/selectors/getCard';
import { TStatus } from '../../../../../../../types/model';

export const copyCard = (
    cardId: TCardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.BUTTONS_PANEL_BUTTON_CLICKED, {
            name: SegmentCardOptions.TARGET,
            value: SegmentCardSection.COPY
        }));
        const state = getState();
        const card = getCard(state, cardId);
        dispatch(cardCopyWithSubcardsPrompt(cardId, SegmentCardSourceValue.DETAILS));
    };
    return action;
};
