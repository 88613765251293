import * as React from 'react';
import { ISubCardIndicatorProps } from './types';
import { CARD_CLASS } from '../../constants';
import { Icon, Tooltip } from 'kui';
import './SubCardIndicator.scss';
import { getEpicTitle, TITLE_SUBCARD } from '../../wording';
import { escape } from 'underscore';

export function SubCardIndicator({
    epicName,
    subCardsCount,
}: ISubCardIndicatorProps) {
    const indicatorWrapperClass = CARD_CLASS + '__sub-card-status';
    const epicClass = CARD_CLASS + '__epic-indicator';
    const subCardClass = CARD_CLASS + '__sub-card-indicator';
    return (
        <div className={indicatorWrapperClass}>
            {!!epicName &&
                <Tooltip
                    isNoWrap={true}
                    value={TITLE_SUBCARD + escape(epicName)}
                >
                    <Icon xlink={'subcard'} size={24} className={subCardClass} />
                </Tooltip>
            }
            {subCardsCount > 0 &&
                <Tooltip
                    isNoWrap={true}
                    value={getEpicTitle(subCardsCount)}
                >
                    <Icon xlink={'epic-card'} size={24} className={epicClass}/>
                </Tooltip>
            }
        </div>
    );
}
