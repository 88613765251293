import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { toggleEnabledAutoBackups } from '../effects/toggleEnabledAutoBackups';
import { IGetState, TBoardId } from '../../../../../../../../../types/types';
import {
    getProFeaturesTrialActive
} from '../../../../../../../../../store/model/authUser/selectors/getProFeaturesTrialActive';
import {
    authUserSetUsedProFeatures
} from '../../../../../../../../../rest/effects/authUser/authUserSetUsedProFeatures';

export const onChange = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (getProFeaturesTrialActive(getState())) {
            dispatch(authUserSetUsedProFeatures('allowAutoBackupXml'));
        }
        dispatch(toggleEnabledAutoBackups(boardId));
    };
    return action;
};
