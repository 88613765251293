import { getCardAssignees } from '../../../../../../../../store/model/selectors/getCardAssignees';
import { TPermissionId } from './../../../../../../../../types/types';
import { ISharedUser } from '../../../../../../../../types/rest/ISharedUser';
import { getBoardUsers } from './../../../../../../../../store/model/selectors/getBoardUsers';
import { IApplicationState, TBoardId, TCardId } from '../../../../../../../../types/types';
import { IAssigneesSearchSelectOption } from '../types';
import { createDeepEqualSelector } from '../../../../../../helpers/memoizeHelper';
import {
    ASSIGNEE_GROUP_DIVIDER,
    ASSIGNEE_GROUP_TITLE,
    TITLE_ASSIGNED_ON_BOARD,
    TITLE_ASSIGNED_OTHER
} from '../../../components/constants';
import { getBoardCards } from '../../../../../../../../store/model/selectors/getBoardCards';
import { getAuthUser } from '../../../../../../../../store/model/authUser/selectors/getAuthUser';
import { getInitials } from 'app/view/react_components/helpers/userPhotoHelper';

type TgetAssigneesSearchSelectOptions = (
    state: IApplicationState,
    boardId: TBoardId,
    cardIds: TCardId[],
) => IAssigneesSearchSelectOption[];

const getBoardUsersSelector = (
    state: IApplicationState,
    boardId: TBoardId,
    cardIds: TCardId[]
): ISharedUser[] => {
    const boardUsers = [...getBoardUsers(state, boardId)];
    const boardUserIds = new Set(boardUsers.map(user => user.permissionId)); // вспомогательное множество для проверки дубликатов

    cardIds.forEach((cardId) => {
        getCardAssignees(state, cardId).forEach(assignee => {
            if (!boardUserIds.has(assignee.sharedUser.permissionId)) { // юзер есть на карте, но удалён с доски
                boardUsers.push(assignee.sharedUser); // добавить его
                boardUserIds.add(assignee.sharedUser.permissionId);
            }
        });
    });
    if (!boardUsers.length) return [];

    const { permissionId: authUserPertmissionId } = getAuthUser(state);
    const authUser = boardUsers.find((user) => user.permissionId === authUserPertmissionId);
    const assignees = boardUsers
        .filter((user) => user.permissionId !== authUserPertmissionId)
        .sort((userA, userB) => {
            const getUserName = (user: ISharedUser) => user.fullName ? user.fullName : '';
            return getUserName(userA).localeCompare(getUserName(userB))
        })
    ;
    if (authUser) {
        assignees.unshift(authUser); // сделать себя первым в списке
    }

    return assignees;
};

const getAssigneeIdsSelector = (
    state: IApplicationState,
    boardId: TBoardId,
    cardIds: TCardId[],
): TPermissionId[] => {
    const boardPermissionIds = cardIds.reduce((ids, cardId, i) => { // найдём пересечение
        const assignees = getCardAssignees(state, cardId);
        if (!i) return assignees.map(assignee => assignee.sharedUser.permissionId);
        if (!ids.length) return ids;

        for (let i=ids.length - 1; i>=0; i--) {
            if (!assignees.find(assignee => assignee.sharedUser.permissionId === ids[i])) {
                ids.splice(i, 1);
            }
        }
        return ids;
    }, []);
    return boardPermissionIds;
};

const getBoardAssigneeIdsSelector = (
    state: IApplicationState,
    boardId: TBoardId,
): Set<TPermissionId> => {
    const assigneeIdsSet = new Set<TPermissionId>();
    const cards = getBoardCards(state, boardId);
    cards.forEach(card => {
        const assignees = getCardAssignees(state, card.id);
        assignees.forEach(assignee => assigneeIdsSet.add(assignee.sharedUser.permissionId));
    });
    return assigneeIdsSet
};

const getAssigneesSearchSelectOptionsSelector = (
    boardUsers: ISharedUser[],
    assigneeIds: TPermissionId[],
    assigneeNamesSet: Set<TPermissionId>,
): IAssigneesSearchSelectOption[] => {
    const options = boardUsers.map(user => ({
        active: !!assigneeIds.includes(user.permissionId),
        img: user.photoUrl,
        initials: getInitials(user),
        text: user.fullName,
        value: user.permissionId
    }));
    const assigned = options.filter(option => assigneeNamesSet.has(option.value));
    const notAssigned = options.filter(option => !assigneeNamesSet.has(option.value));
    if (
        assigneeNamesSet.size === 0 ||
        assigned.length === options.length ||
        notAssigned.length === options.length
    ) {
        return options;
    }
    const filteredOptions: IAssigneesSearchSelectOption[] = [];
    if (assigned.length) {
        filteredOptions.push({
            text: TITLE_ASSIGNED_ON_BOARD,
            value: ASSIGNEE_GROUP_TITLE,
            options: assigned
        })
    }
    if (notAssigned.length) {
        filteredOptions.push({
            text: TITLE_ASSIGNED_OTHER,
            value: ASSIGNEE_GROUP_DIVIDER,
            options: notAssigned
        })
    }
    return filteredOptions;
};

export const getAssigneesSearchSelectOptionsCreateSelector = (
): TgetAssigneesSearchSelectOptions => createDeepEqualSelector(
    getBoardUsersSelector,
    getAssigneeIdsSelector,
    getBoardAssigneeIdsSelector,
    getAssigneesSearchSelectOptionsSelector,
);

export const getAssigneesSearchSelectOptions: TgetAssigneesSearchSelectOptions = getAssigneesSearchSelectOptionsCreateSelector();
