import { ICardCell, ICardsColumn } from './types';
import { GOOGLE_SPACING } from '../../../../../const';
import { CardProgress } from './components/CardProgress/CardProgress';
import { getMessages } from '../../../../../store/constants';
import { EUserMetaAssignedToMeSortField } from '../../../../../types/rest/IUserMeta';
import { CardStartDateHOC } from './hocs/CardStartDateHOC/CardStartDateHOC';
import { CardDueDateHOC } from './hocs/CardDueDateHOC/CardDueDateHOC';
import { CardAssignHOC } from './hocs/CardAssignHOC/CardAssignHOC';
import { CardBoardHOC } from './hocs/CardBoardHOC/CardBoardHOC';
import { CardUpdatesHOC } from './hocs/CardUpdatesHOC/CardUpdatesHOC';
import { CardAssigneesHOC } from './hocs/CardAssigneesHOC/CardAssigneesHOC';
import { CardAuthorHOC } from './hocs/CardAuthorHOC/CardAuthorHOC';
import { CLASS_MY_WORK } from '../constants';
import { CardName } from './components/CardName/CardName';
import { CardPriorityHOC } from './hocs/CardPriorityHOC/CardPriorityHOC';
import { CardColorTagsHOC } from './hocs/CardColorTagsHOC/CardColorTagsHOC';
import { CardListHOC } from './hocs/CardListHOC/CardListHOC';
import { CardBackgroundColorHOC } from './hocs/CardBackgroundColorHOC/CardBackgroundColorHOC';
import { CardCreatedHOC } from './hocs/CardCreatedHOC/CardCreatedHOC';

export const CARDS_COLUMNS: ICardsColumn[] = [
    Object.freeze({
        title: getMessages().getText('assigned_to_me.cards.cardTitle'),
        width: GOOGLE_SPACING * 43,
        sortField: EUserMetaAssignedToMeSortField.name,
        component: CardName,
        isAlwaysShown: true,
        isNotDraggable: true
    }),
    Object.freeze({
        title: getMessages().getText('assigned_to_me.cards.color_labels'),
        width: GOOGLE_SPACING * 11.25,
        sortField: EUserMetaAssignedToMeSortField.colorLabels,
        component: CardColorTagsHOC as React.FC<ICardCell>,
        isHidden: false
    }),
    Object.freeze({
        title: getMessages().getText('assigned_to_me.cards.board'),
        width: GOOGLE_SPACING * 17,
        sortField: EUserMetaAssignedToMeSortField.board,
        component: CardBoardHOC as React.FC<ICardCell>,
        isHidden: false
    }),
    Object.freeze({
        title: getMessages().getText('assigned_to_me.cards.list'),
        width: GOOGLE_SPACING * 13,
        sortField: EUserMetaAssignedToMeSortField.list,
        component: CardListHOC as React.FC<ICardCell>,
        isHidden: false
    }),
    Object.freeze({
        title: getMessages().getText('assigned_to_me.cards.assignees'),
        width: GOOGLE_SPACING * 9.5,
        sortField: EUserMetaAssignedToMeSortField.assignees,
        component: CardAssigneesHOC as React.FC<ICardCell>,
        isHidden: false
    }),
    Object.freeze({
        title: getMessages().getText('assigned_to_me.cards.progress'),
        width: GOOGLE_SPACING * 9,
        sortField: EUserMetaAssignedToMeSortField.progress,
        component: CardProgress,
        isHidden: false
    }),
    Object.freeze({
        title: getMessages().getText('assigned_to_me.cards.priority'),
        width: GOOGLE_SPACING * 7.75,
        sortField: EUserMetaAssignedToMeSortField.priority,
        component: CardPriorityHOC as React.FC<ICardCell>,
        isHidden: false
    }),
    Object.freeze({
        title: getMessages().getText('assigned_to_me.cards.date.start'),
        width: GOOGLE_SPACING * 10.75,
        sortField: EUserMetaAssignedToMeSortField.startDate,
        component: CardStartDateHOC as React.FC<ICardCell>,
        isHidden: false
    }),
    Object.freeze({
        title: getMessages().getText('assigned_to_me.cards.date.due'),
        width: GOOGLE_SPACING * 10.75,
        sortField: EUserMetaAssignedToMeSortField.dueDate,
        component: CardDueDateHOC as React.FC<ICardCell>,
        isHidden: false
    }),
    Object.freeze({
        title: getMessages().getText('assigned_to_me.cards.author'),
        width: GOOGLE_SPACING * 8.25,
        sortField: EUserMetaAssignedToMeSortField.author,
        component: CardAuthorHOC as React.FC<ICardCell>,
        isHidden: true
    }),
    Object.freeze({
        title: getMessages().getText('assigned_to_me.cards.added'),
        titleLong: getMessages().getText('assigned_to_me.cards.added.long'), // для added в настройках в асайд панле используем длинный тайтл
        width: GOOGLE_SPACING * 7,
        sortField: EUserMetaAssignedToMeSortField.assign,
        component: CardAssignHOC as React.FC<ICardCell>,
        isHidden: true
    }),
    Object.freeze({
        title: getMessages().getText('assigned_to_me.cards.created'),
        width: GOOGLE_SPACING * 8.5,
        sortField: EUserMetaAssignedToMeSortField.created,
        component: CardCreatedHOC as React.FC<ICardCell>,
        isHidden: true
    }),
    Object.freeze({
        title: getMessages().getText('assigned_to_me.cards.color'),
        width: GOOGLE_SPACING * 10.5,
        sortField: EUserMetaAssignedToMeSortField.color,
        component: CardBackgroundColorHOC as React.FC<ICardCell>,
        isHidden: true
    }),
    Object.freeze({
        title: getMessages().getText('assigned_to_me.cards.updates'),
        width: GOOGLE_SPACING * 10,
        sortField: EUserMetaAssignedToMeSortField.updates,
        component: CardUpdatesHOC as React.FC<ICardCell>,
        isHidden: false,
        isNotDraggable: true
    }),
];

export const MY_WORK_CONTROL_CLASS = CLASS_MY_WORK + '_control'
