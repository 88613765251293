import { IColorTag, TBoardId, TCardId, TListId } from '../types';
import { IAuthorRest, TStatus } from '../model';
import { IRestUser } from './IRestUser';
import { IAssignee } from '../../store/model/card/types/IAssignee';

export interface IRestCardAssigneeInfo {
    //from CardAssigneeRest.java
    //id?: number;
    cardId?: TCardId;
    dashboardId?: TBoardId;
    listId?: TListId;
    cardCreated?: number;
    cardAssignDate?: number;
    name?: string;
    cardNumber?: string;
    cardFullName?: string;
    boardName?: string;
    listName?: string;
    processingPercent?: number;
    priority?: string;
    startDate?: number;
    dueDate?: number;
    pinned?: boolean;
    backgroundColor?: string;
    colorTags?: IColorTag[];
    firstSeenDate?: number;
    lastActionDate?: number;
    status?: TStatus;
    updatesCount?: number;
    hidden?: boolean;
    types?: ERestCardAssigneeInfoType[];
    assignees?: IAssignee[];
    author?: IAuthorRest;
    archived?: boolean;
}

export enum ERestCardAssigneeInfoType {
    ASSIGNED_TO_ME = 'assigned_to_me',
    I_HAVE_ASSIGNED = 'i_have_assigned',
    I_AM_SUBSCRIBED = 'i_am_subscribed',
    I_HAVE_CREATED = 'i_have_created',
    I_HAVE_CONTRIBUTED = 'i_have_contributed',
    I_HAVE_ADDED_TO_MYWORK = 'i_have_added_to_mywork'
}
