import * as React from 'react';
import './_RelatedCardsList.scss';
import { IRelatedCardsListProps } from './types';
import { ESettingsSectionType } from '../../../../base/components/SettingsSection/types';
import { CLASS_RELATED_CARDS_LIST } from './constants';
import { RelatedCardHOC } from '../../hocs/RelatedCardHOC/RelatedCardHOC';

export function RelatedCardsList({
    boardId,
    relatedCards,
    onDelete,
    sectionType
}: IRelatedCardsListProps) {
    const isReadonly = sectionType === ESettingsSectionType.READONLY;

    return (!!relatedCards &&
        <div className={CLASS_RELATED_CARDS_LIST}>
            {
                relatedCards.map((relatedCard) => (
                    <RelatedCardHOC
                        key={relatedCard.cardId}
                        relatedCard={relatedCard}
                        boardId={boardId}
                        onHandleDelete={onDelete}
                        isReadonly={isReadonly}
                    />
                ))
            }
        </div>
    );
};
