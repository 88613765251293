import { AT_CARD_ID_ADD } from './constants';
import { IMatchCardIdAddAction } from './types';
import { TCardId } from '../../../../../../../types/types';

export const matchCardIdAddAction = (
    cardId: TCardId
): IMatchCardIdAddAction => ({
        type: AT_CARD_ID_ADD,
        cardId
    }
);
