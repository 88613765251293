import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../types/types';
import { SegmentCardEvent, SegmentCardOptions, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { SegmentCardSection } from '../../../../../../../../middlewares/segment/trackEntities/cardEvents';

export const onClick = (
    ownOnClick?: () => void
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.BUTTONS_PANEL_BUTTON_CLICKED, {
            name: SegmentCardOptions.TARGET,
            value: SegmentCardSection.PRIORITY
        }, [{
            name: SegmentCardOptions.FIRST,
            value: 'false' // приорити есть на панели
        }]));
        if (ownOnClick) ownOnClick();
    };
    return action;
};
