import { connect } from 'react-redux';
import { IApplicationState } from 'app/types/types';
import { ICardDescriptionHOCOwnProps } from './types';
import { ICardDescriptionEvents, ICardDescriptionFields } from '../../components/CardDescription/types';
import { getCard } from 'app/store/model/selectors/getCard';
import { CardDescription } from '../../components/CardDescription/CardDescription';
import { onSaveUnsavedDescription } from './events/onSaveUnsavedDescription';
import { onSave } from './events/onSave';
import { getDescriptionUnsaved } from '../../store/cardDescriptionUnsaved/selectors/getDescriptionUnsaved';
import { onDeleteUnsavedDescription } from './events/onDeleteUnsavedDescription';
import { onCtrlEnter } from './events/onCtrlEnter';
import { onShowUnsavedDescription } from './events/onShowUnsavedDescription';
import { formatDescriptionUnsaved } from './helpers/formatDescriptionUnsaved';
import { getUserTimeFormat12 } from 'app/store/model/authUser/selectors/getUserTimeFormat12';
import { onCancelWithoutChanges } from './events/onCancelWithoutChanges';
import { onCancel } from './events/onCancel';
import { getBoardIsCardRequiredProperty } from 'app/store/model/board/selectors/getBoardIsCardRequiredProperty';
import { EAsidePanelProperty } from '../../../../asidePanel/components/AsidePanel/types';
import { getBoardIdByCardId } from 'app/store/model/selectors/getBoardIdByCardId';
import { onTogglePin } from './events/onTogglePin';
import { getCardPinnedDescription } from 'app/store/model/selectors/getCardPinnedDescription';
import Util from 'app/util/util';
import {
    onEsc
} from 'app/view/react_components/aside_panel/cardDetails/DescriptionSection/hocs/CardDescriptionHOC/events/onEsc';
import { ESettingsSectionType } from 'app/view/react_components/base/components/SettingsSection/types';
import { getSectionType } from 'app/view/react_components/aside_panel/cardDetails/selectors/getSectionType';
import { getUserHTMLEditorType } from 'app/store/model/authUser/selectors/getUserHTMLEditorType';

const mapStateToProps = (
    state: IApplicationState,
    ownProps: ICardDescriptionHOCOwnProps
): ICardDescriptionFields => {
    const { cardId } = ownProps;
    const boardId = getBoardIdByCardId(state, cardId);
    const card = getCard(state, cardId);
    let description = card ? Util.fixAuthuser(card.description) : '';

    const cardDescriptionUnsaved = getDescriptionUnsaved(state, cardId);
    let isUserTimeFormat12 = getUserTimeFormat12(state);
    const formattedDescriptionUnsaved = formatDescriptionUnsaved(cardDescriptionUnsaved, isUserTimeFormat12);
    const sectionType = getSectionType(state, boardId, cardId);
    const isReadonly = sectionType === ESettingsSectionType.READONLY;
    return {
        description,
        descriptionUnsaved: formattedDescriptionUnsaved,
        HTMLEditorType: getUserHTMLEditorType(state),
        isPinned: getCardPinnedDescription(state, cardId),
        isReadonly,
        isRequired: getBoardIsCardRequiredProperty(state, boardId, EAsidePanelProperty.CARD_DESCRIPTION)
    };
};

const mapDispatchToProps = (
    dispatch: any,
    ownProps: ICardDescriptionHOCOwnProps
): ICardDescriptionEvents => {
    const { cardId } = ownProps;
    return {
        onCancel: () => dispatch(onCancel()),
        onCancelWithoutChanges: () => dispatch(onCancelWithoutChanges()),
        onSaveUnsavedDescription: (description) => dispatch(onSaveUnsavedDescription(cardId, description)),
        onSave: (description) => dispatch(onSave(cardId, description)),
        onEsc: () => dispatch(onEsc()),
        onDeleteUnsavedDescription: () => dispatch(onDeleteUnsavedDescription(cardId)),
        onCtrlEnter: (description) => dispatch(onCtrlEnter(cardId,description)),
        onShowUnsavedDescription: () => dispatch(onShowUnsavedDescription()),
        onTogglePin: () => dispatch(onTogglePin(cardId))
    };
};

export const CardDescriptionHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardDescription);

CardDescriptionHOC.displayName = 'CardDescriptionHOC';
