import { IApplicationState } from '../../../../../types/types';
import { TTabKey } from '../store/types';
import { getLoadStatus } from '../store/common/selectors/getLoadStatus';
import { tabLoadStatusSet } from '../store/common/actions/tabLoadstatusSet';
import { dispatch } from '../../../../../store/configureStore';
import { TTabLoadStatus } from '../store/common/types';
import { getDrivesWithBoardsByTabKey } from '../store/drives/selectors/getDrivesWithBoardsByTabKey';
import { DRIVE_ID_MY_DRIVE } from '../store/drives/constants';
import { IDrivesListItem } from '../containers/DrivesList/types';
import { myDriveListLoadedEvent } from '../effects/segmentEvents';

export const allBoardsLoadListener = (
    state: IApplicationState
): void => {
    const tabs: TTabKey[] = [TTabKey.sharedDrives, TTabKey.starred];
    tabs.forEach((tab: TTabKey) => {
        if (getLoadStatus(state, tab) === TTabLoadStatus.EMPTY) {
            dispatch(tabLoadStatusSet(tab, TTabLoadStatus.COMPLETED));
        }
    });
    const myDrive: IDrivesListItem =
        getDrivesWithBoardsByTabKey(state, TTabKey.allBoards).find(drive => drive.id === DRIVE_ID_MY_DRIVE);
    dispatch(myDriveListLoadedEvent(myDrive ? myDrive.boardsCount : 0));
}
