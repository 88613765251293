import * as React from 'react';
import { SelectListItem } from 'kui';
import { INotifySearchSelectOptionProps } from './types';
import { UserpicStorage } from 'app/view/react_components/base/components/UserpicStorage/UserpicStorage';

export function NotifySearchSelectOption ({
    img,
    initials,
    text,
    value,
    className
}: INotifySearchSelectOptionProps) {
    return (
        <SelectListItem value={value} className={className}>
            <UserpicStorage
                initials={initials}
                size={24}
                src={img}
                userId={value}
            />
            {text}
        </SelectListItem>
    );
};
