import * as React from 'react';
import { IRestCardDriveDocActivity } from '../../../../../../../types/rest/activity/IRestCardDriveDocActivity';
import './_CardActivityDriveDoc.scss';
import { IActivityProps } from '../../../../boardDetails/activity/TabActivity/types';
import { TabActivityContext } from '../../../../boardDetails/activity/TabActivity/components/TabActivity/constants';
import { TActivity } from '../../../../../../../store/activities/types';
import {
    ActivityItemBody
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemBody/ActivityItemBody';
import { ActivityHeader } from '../../../../boardDetails/activity/TabActivity/components/ActivityHeader/ActivityHeader';
import {
    ActivityItemName
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemName/ActivityItemName';
import {
    ActivityItemMore
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemMore/ActivityItemMore';
import {
    CLASS_ACTIVITY_ITEM,
    CLASS_ACTIVITY_ITEM_TEMP
} from '../../../../boardDetails/activity/TabActivity/components/constants';
import { renderToStaticMarkup } from 'react-dom/server';
import { GOOGLE_SPACING } from '../../../../../../../const';
import { IActivityDriveDoc } from './types';

export function CardActivityDriveDoc({
    activity,
    asidePanelWidth
}: IActivityProps<IRestCardDriveDocActivity>) {
    const { onOpenCard } = React.useContext(TabActivityContext);
    const { label, card } = activity as TActivity;
    const { driveDocs } = activity;
    const className = CLASS_ACTIVITY_ITEM + '__attachments';
    const classContent = className + '-content';
    const classTooltipItem = className + '-tooltip-item';
    const classTooltipItemName = className + '-tooltip-item-name';
    const classIcon = className + '-icon';

    const [isNeedToCalculate, setNeedToCalculate] = React.useState(true);
    const [visibleElements, setVisibleElements] = React.useState([]);
    const [tooltip, setTooltip] = React.useState(null);

    const ref = React.useRef(null);
    const tempRef = React.useRef(null);

    React.useEffect(() => {
        setNeedToCalculate(true);
    }, [driveDocs, asidePanelWidth]);

    React.useEffect(() => {
        if (!isNeedToCalculate) return;

        const newElements: IActivityDriveDoc[] = [];

        const container = ref.current as HTMLElement;
        const tempContainer = tempRef.current as HTMLElement;
        if (!container || !tempContainer) return;

        const maxWidth = container.offsetWidth - GOOGLE_SPACING * 2 // место для плашки с плюсом
        let currentWidth = 0;

        for (let i = 0; i < driveDocs.length; i++) {
            const driveDocEl = tempContainer.children[i] as HTMLElement;
            if (!driveDocEl) return;

            const minWidth = Math.min(driveDocEl.offsetWidth, GOOGLE_SPACING * 25)
            const driveDocWidth = minWidth + GOOGLE_SPACING; // + gap
            currentWidth += driveDocWidth;
            if (currentWidth >= maxWidth) {
                break;
            }
            newElements.push({ ...driveDocs[i], minWidth });
        }

        setVisibleElements(newElements);

        const dif = driveDocs.length - newElements.length;
        if (dif) {
            setTooltip(
                driveDocs.slice(newElements.length).reduce((tooltip, driveDoc) => {
                    return tooltip + (`${renderToStaticMarkup(
                        <div className={classTooltipItem}>
                            <img className={classIcon} src={driveDoc.iconUrl} />
                            <div className={classTooltipItemName}>{driveDoc.title}</div>
                        </div>
                    )}`);
                }, '')
            );
        }

        setNeedToCalculate(false);
    }, [isNeedToCalculate, driveDocs, asidePanelWidth]);

    return (
        <ActivityItemBody
            className={className}
            header={<ActivityHeader icon={'attachment'} label={label} />}
            activity={activity}
            onClick={() => onOpenCard(card.id, activity.id, 'attachments')}
        >
            {driveDocs &&
                <div
                    className={`
                        ${classContent}
                        ${driveDocs && driveDocs.length > 1 ? classContent + '--multiple' : ''}
                    `}
                    ref={ref}
                >
                    {visibleElements.map(({ title, iconUrl, minWidth, url }) => (
                        <ActivityItemName
                            name={title}
                            icon={<img className={classIcon} src={iconUrl} />}
                            style={{ minWidth }}
                            key={url}
                        />
                    ))}
                    {visibleElements.length !== driveDocs.length && <ActivityItemMore count={driveDocs.length - visibleElements.length} tooltip={tooltip} />}
                </div>
            }
            {isNeedToCalculate &&
                <div
                    className={`${classContent} ${CLASS_ACTIVITY_ITEM_TEMP}`}
                    ref={tempRef}
                >
                    {driveDocs.map(driveDoc => (
                        <ActivityItemName
                            key={driveDoc.url}
                            name={driveDoc.title}
                            icon={<img className={classIcon} src={driveDoc.iconUrl} />}
                        />
                    ))}
                </div>
            }
        </ActivityItemBody>
    );
};
