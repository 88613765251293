import { IGetState } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IDependency, TDependencyId } from '../../../../store/model/dependencies/dependency/types';
import { IDependencies } from '../../../../store/model/dependencies/dependencies/types';
import { dependenciesDelete } from './api/helper/dependenciesDelete';
import { getCardDependency } from '../../../../store/model/dependencies/dependencies/selectors/getCardDependency';

export const deleteDependency = (
    dependencyId: TDependencyId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const dependency: IDependency = getCardDependency(getState(), dependencyId);
        if (!dependency)
            throw new Error('Invalid dependence');
        const deps: IDependencies = {
            [dependency.id]: dependency
        };
        dispatch(dependenciesDelete(deps, true));
    };
    return action;
}
