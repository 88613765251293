import './_CardPropertyItem.scss';
import { ICardPropertiesItemProps } from './types';
import { Checkbox, Tooltip } from 'kui';
import { BOARD_SECTION_CARD_PROPERTIES_DEFAULT, BOARD_SECTION_CARD_PROPERTIES_REQUIRED } from '../../constants';
import * as React from 'react';
import { CLASS_BOARD_DETAILS } from '../../../../constants';
import { escape } from 'underscore';

export const CardPropertyItem = ({
    isReadonly,
    propertyKey,
    name,
    isDefaultOnly,
    isDisallow,
    isRequiredOnly,
    isDefault,
    isRequired,
    option,
    onDefaultToggle,
    onRequiredToggle,
    onDisallowClick
}: ICardPropertiesItemProps) => {
    const className = CLASS_BOARD_DETAILS + '__card-properties';
    const classItem = className + '-item';
    const classDisabled = classItem + '--disabled';
    const classCheckboxes = className + '-checkboxes';
    const classSingle = classCheckboxes + '--single';
    const classCheckbox = className + '-checkbox';
    const classChecked = classCheckbox + '--checked';
    const classText = classItem + '-text';
    const classWrapper = classCheckbox + '-wrapper';
    const classDisallow = classWrapper + '--disallow';

    return (
        <div
            className={`
                ${classItem}
                ${isReadonly ? classDisabled : ''}
            `}
        >
            <Tooltip
                direction={'down'}
                value={name ? escape(name) : null}
                isNoWrap
                isNoEvents
            >
                <span
                    className={classText}
                >
                    {name}
                </span>
            </Tooltip>
            <span
                className={`
                    ${classCheckboxes}
                    ${isDefaultOnly ? classSingle : ''}
                `}
            >
                 <Checkbox
                     className={`
                        ${classCheckbox}
                        ${isDefault ? classChecked : ''}
                     `}
                     checked={isDefault || isRequiredOnly}
                     direction={'left'}
                     disabled={isReadonly || isRequiredOnly}
                     onChange={() => onDefaultToggle(propertyKey, option)}
                 >
                     {BOARD_SECTION_CARD_PROPERTIES_DEFAULT}
                 </Checkbox>
                {!isDefaultOnly &&
                    <div
                        className={`
                            ${classWrapper}
                            ${isDisallow ? classDisallow : ''}
                        `}
                        onClick={isDisallow ? onDisallowClick : ()=>{}}
                    >
                        <Checkbox
                            className={`
                                ${classCheckbox}
                                ${isRequired ? classChecked : ''}
                            `}
                            checked={isRequired}
                            direction={'left'}
                            disabled={isReadonly}
                            onChange={() => !isDisallow && onRequiredToggle(propertyKey, option)}
                        >
                            {BOARD_SECTION_CARD_PROPERTIES_REQUIRED}
                        </Checkbox>
                    </div>
                }
            </span>
        </div>
    );
}
