import { calcDateOfNotification } from '../../../../utils/calcDateOfNotification';
import { INotificationBeforeDueDate } from '../../../../../../../../../../store/model/notificationBeforeDueDate/types';
import { getCardNotificationBeforeDueDate } from '../../../../../../../../../../store/model/selectors/getCardNotificationBeforeDueDate';
import { Dispatch } from '../../../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../../../types/types';
import { notificationBeforeDueDateUpdate } from '../../../../../../../../../../rest/effects/card/notificationBeforeDueDate/notificationBeforeDueDateUpdate';

export const patchNotificationBeforeDueDate = (
    notification: INotificationBeforeDueDate,
    orderNumber: number,
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        const notificationBeforeDueDate = getCardNotificationBeforeDueDate(getState(), notification.cardId, notification.id);
        if (notificationBeforeDueDate) {
            const {interval, unit} = notification;
            const dueDate = notificationBeforeDueDate.dueDate;
            const patchNotification = {
                unit,
                interval,
                dateOfNotification: calcDateOfNotification(interval, unit, dueDate),
                orderNumber,
            };
            return dispatch(notificationBeforeDueDateUpdate(notification.id, patchNotification));
        } else {
            return Promise.reject('Record not found: ' + notification.id);
        }
    }
    return action;
};
