import { AsidePanelActionsButton } from '../../../..';
import * as React from 'react';
import { ICardDatesPanelButton } from './types';
import { getPopupAttributes } from '../../../../../base/components/SettingsSection/constants';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import {
    CLASS_ASIDE_PANEL_ACTIONS_BUTTON_CONTAINER,
    CLASS_ASIDE_PANEL_ACTIONS_POPUP
} from '../../../../asidePanel/components/AsidePanelActionsButton/constants';
import { CARD_SECTION_DATES, CARD_SECTION_DATES_MILESTONE } from '../../constants';
import { CardDatesSectionHOC } from '../../hocs/CardDatesSectionHOC/CardDatesSectionHOC';
import { CLASS_CARD_DETAILS_SECTION, CLASS_TIME_DROPDOWN } from '../../../constants';
import { EAsidePanelProperty } from '../../../../asidePanel/components/AsidePanel/types';
import { CLASS_DATEPICKER } from 'app/view/react_components/base/components/Datepicker/components/constants';
import { getAsidePanelActionsButtonTooltip } from 'app/view/react_components/aside_panel/asidePanel/helpers/getAsidePanelActionsButtonTooltip';

export function CardDatesPanelButton({
    dueStartFlag,
    isDisabled,
    isMilestone,
    isShow,
    tooltip,
    onClick
}: ICardDatesPanelButton) {
    if (!isShow) return null;

    const className = CLASS_ASIDE_PANEL_ACTIONS_BUTTON_CONTAINER + '--dates';

    const { boardId, cardId, showedProperties, openedProperty } = React.useContext(AsidePanelContext);
    const isShowed = showedProperties.has(EAsidePanelProperty.CARD_DATES) && (openedProperty !== EAsidePanelProperty.CARD_START_DATE && openedProperty !== EAsidePanelProperty.CARD_DUE_DATE);
    const [isOpened, setIsOpened] = React.useState(null);
    const onOpened = () => setIsOpened(true);
    const onClosed = () => setIsOpened(false);
    return (
        <AsidePanelActionsButton
            className={className}
            dropdownClassName={`${CLASS_ASIDE_PANEL_ACTIONS_POPUP}--dates`}
            indicatorBoolean={dueStartFlag}
            isDisabled={isDisabled}
            icon={'calendar'}
            isOpened={isOpened}
            notBlurClasses={[
                CLASS_DATEPICKER,
                CLASS_TIME_DROPDOWN,
                CLASS_DATEPICKER + '__time-button',
                CLASS_DATEPICKER + '__time-button-dropdown',
                CLASS_DATEPICKER + '__calendar',
                CLASS_DATEPICKER + '__calendar-select-dropdown',
                CLASS_DATEPICKER + '__hour-switch',
            ]}
            openedProperty={EAsidePanelProperty.CARD_START_DATE}
            sectionSelector={`.${CLASS_CARD_DETAILS_SECTION}--dates`}
            title={isMilestone ? CARD_SECTION_DATES_MILESTONE : CARD_SECTION_DATES}
            tooltip={tooltip && getAsidePanelActionsButtonTooltip({ value: tooltip })}
            onClick={onClick}
            onOpen={onOpened}
        >
            {!isDisabled && !isShowed &&
                <CardDatesSectionHOC
                    boardId={boardId}
                    cardId={cardId}
                    onClose={onClosed}
                    {...getPopupAttributes()}
                />
            }
        </AsidePanelActionsButton>
    );
}
