import { connect } from 'react-redux';
import { CardAttachmentsSection } from '../../../components/CardAttachmentsSection/CardAttachmentsSection';
import {
    ICardAttachmentsDispatchProps,
    ICardAttachmentsStateProps
} from '../../../components/CardAttachmentsSection/types';
import { ICardAttachmentsHOCOwnProps } from './types';
import { EMPTY_ARRAY, IApplicationState } from 'app/types/types';
import { getSectionType } from '../../../../selectors/getSectionType';
import { onAddClicked } from './events/onAddClicked';
import { getCardActiveRequests } from 'app/store/requestsState/selectors/getCardActiveRequests';
import { onDropFiles } from './events/onDropFiles';
import { onPasteFile } from './events/onPasteFile';
import { onSelectClick } from './events/onSelectClick';
import { getAuthUser } from 'app/store/model/authUser/selectors/getAuthUser';
import { getBoardIsCardDefaultProperty } from 'app/store/model/board/selectors/getBoardIsCardDefaultProperty';
import { EAsidePanelProperty } from '../../../../../asidePanel/components/AsidePanel/types';
import { ESettingsSectionType } from '../../../../../../base/components/SettingsSection/types';
import { onPasteFileAsComment } from './events/onPasteFileAsComment';
import {getAuthUserIsAttachmentViewTypeGrid} from 'app/store/model/authUser/selectors/getAuthUserIsAttachmentViewTypeGrid';
import {getDriveDocsByCardId} from 'app/store/model/driveDocs/selectors/getDriveDocsByCardId';
import {ERestDriveDocType} from 'app/types/rest/IRestDriveDoc';
import {
    getBoardIsCardRequiredProperty
} from 'app/store/model/board/selectors/getBoardIsCardRequiredProperty';
import { getCardIsLoading } from 'app/store/requestsState/selectors/getCardIsLoading';
import { getHint } from 'app/view/react_components/hints/store/hints/selectors/getHint';
import { EHintKey } from 'app/view/react_components/hints';
import { onToggleAttachmentViewType } from '../../CardAttachmentsSectionHOC/events/onToggleAttachmentViewType';
import { onTogglePin } from '../../CardAttachmentsSectionHOC/events/onTogglePin';
import { onDelete } from '../../CardAttachmentsSectionHOC/events/onDelete';
import { onSort } from '../../CardAttachmentsSectionHOC/events/onSort';
import { onLinkClick } from '../../CardAttachmentsSectionHOC/events/onLinkClick';
import { onShowAttachmentViewHint } from '../../CardAttachmentsSectionHOC/events/onShowAttachmentViewHint';
import { onShowAttachmentListHint } from '../../CardAttachmentsSectionHOC/events/onShowAttachmentListHint';
import { onOpenAttachmentsTab } from '../../CardAttachmentsSectionHOC/events/onOpenAttachmentsTab';
import { onDownload } from '../../CardAttachmentsSectionHOC/events/onDownload';
import { EAuthUserPlatformType } from 'app/types/rest/IRestAuthUser';
import { onUploadClick } from './events/onUploadClick';
import {
    getIsNewFeaturePasteAttachment
} from '../../../../../../../../store/model/authUser/selectors/featuresSeen/getIsNewFeaturePasteAttachment';
import { onHideTips } from '../../CardAttachmentsSectionHOC/events/onHideTips';
import { isMicrosoftPersonalAccount } from 'app/store/model/authUser/selectors/isMicrosoftPersonalAccount';
import { isEqual } from 'underscore';
import { onLinkCopy } from '../../CardAttachmentsSectionHOC/events/onLinkCopy';

const mapStateToProps = () => {
    interface ICaheProps extends ICardAttachmentsStateProps{};
    let cache: ICaheProps = null;
    const getCache = (
        value: ICaheProps
    ): ICaheProps => {
        if (isEqual(value, cache)) {
            return cache;
        }
        cache = value;
        return value;
    };

    return (
        state: IApplicationState,
        ownProps: ICardAttachmentsHOCOwnProps
    ): ICardAttachmentsStateProps => {
        const {cardId, boardId,  sectionType: sectionTypeOwn} = ownProps;
        const sectionType = getSectionType(state, boardId, cardId, sectionTypeOwn);
        const attachments = getDriveDocsByCardId(state, cardId, ERestDriveDocType.CARD, undefined, true);
        const activeRequests = getCardActiveRequests(state, cardId);
        const isAttachmentViewTypeGrid = getAuthUserIsAttachmentViewTypeGrid(state);
        const isLoading = getCardIsLoading(state, cardId);
        const attachmentsListHintStatus = getHint(state, EHintKey.ATTACHMENTS_LIST).status
        const microsoftPersonalAccount = isMicrosoftPersonalAccount(state);

        const ret = getCache({
            cardId,
            boardId,
            attachments: attachments && attachments.length
                ? attachments
                : sectionType !== ESettingsSectionType.READONLY &&
                getBoardIsCardDefaultProperty(state, boardId, EAsidePanelProperty.CARD_ATTACHMENTS)
                    ? EMPTY_ARRAY
                    :  null,
            isAttachmentViewTypeGrid,
            isDefaultProperty: getBoardIsCardDefaultProperty(state, boardId, EAsidePanelProperty.CARD_ATTACHMENTS),
            isLoading,
            isRequired: getBoardIsCardRequiredProperty(state, boardId, EAsidePanelProperty.CARD_ATTACHMENTS),
            isShowTips: getIsNewFeaturePasteAttachment(state),
            isShowSelectFile: !microsoftPersonalAccount,
            sectionType,
            activeRequests,
            userHash: getAuthUser(state).hash,
            attachmentsListHintStatus,
            platformType: EAuthUserPlatformType.MICROSOFT,
        });
        return ret;
    };
};

const mapDispatchToProps = (
    dispatch: any,
    ownProps: ICardAttachmentsHOCOwnProps
): ICardAttachmentsDispatchProps => {
    const {cardId, onClose: onCloseOwn, boardId} = ownProps;
    return {
        onToggleAttachmentViewType: () => dispatch(onToggleAttachmentViewType()),
        onTogglePin: (driveDocId) => dispatch(onTogglePin(driveDocId)),
        onDownload: (driveId, fileId) => dispatch(onDownload(driveId, fileId)),
        onDelete: (driveDocId) => dispatch(onDelete(cardId, driveDocId)),
        onAddClick: (isDialog, onClose) => dispatch(onAddClicked(boardId, cardId, isDialog, onClose || onCloseOwn)),
        onSort: (docId, index, docIds) => dispatch(onSort(cardId, docId, index, docIds)),
        onLinkClick: (event, driveDocId) => dispatch(onLinkClick(event, driveDocId)),
        onLinkCopy: (url) => dispatch(onLinkCopy(url)),
        onDropFiles: (files, isPopup, onProgress) => dispatch(onDropFiles(cardId, files, isPopup, onProgress)),
        onPasteFile: (files, onProgress) => dispatch(onPasteFile(cardId, files, onProgress)),
        onSelectClick: (onClose) => dispatch(onSelectClick(cardId, onClose || onCloseOwn)),
        onUploadClick: (onStart, onProgress, onFinish) => dispatch(onUploadClick(cardId, onStart, onProgress, onFinish)),
        onPasteFileAsComment: (files, onProgress) => dispatch(onPasteFileAsComment(boardId, cardId, files, onProgress)),
        onShowAttachmentViewHint: () => dispatch(onShowAttachmentViewHint()),
        onShowAttachmentListHint: () => dispatch(onShowAttachmentListHint()),
        onOpenAttachmentsTab: () => dispatch(onOpenAttachmentsTab(boardId)),
        onHideTips: () => dispatch(onHideTips()),
    }
};

export const CardAttachmentsSectionHOC = connect(mapStateToProps, mapDispatchToProps)(CardAttachmentsSection);
CardAttachmentsSectionHOC.displayName = 'CardAttachmentsSectionHOC';
