import {ActionCreator} from 'redux';
import {Dispatch, ThunkAction} from '../../../../../../../types/actions';
import {IGetState} from '../../../../../../../types/types';
import { boardMenuIconClickedSegmentEvent } from '../../../effects/segmentEvents';

export const onBoardMenuIconClicked: ActionCreator<ThunkAction> = (
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(boardMenuIconClickedSegmentEvent());
    };
    return action;
};
