import * as React from 'react';
import './_Tips.scss';
import { ITipsProps } from './types';
import { CLASS_TIPS, TIPS_ANIMATION_LENGTH, TIPS_HIDE_TIME } from './constants';

export function Tips ({
    tips,
    onHide
}: ITipsProps) {
    const className = CLASS_TIPS;
    const classItem = className + '__item';

    const [tipIdx, setTipIdx] = React.useState(0);
    const [isShowTip, setShowTip] = React.useState(null);
    const [isHide, setHide] = React.useState(null);

    const timerRef = React.useRef(null);

    const onClick = () => {
        setShowTip(false);
        setTimeout(() => {
            let newIdx = tipIdx + 1;
            if (newIdx > tips.length - 1) {
                newIdx = 0;
            }
            setTipIdx(newIdx);
            setShowTip(true);
        }, TIPS_ANIMATION_LENGTH);
    };

    const onMouseEnter = () => {
        if (timerRef.current) clearTimeout(timerRef.current);
    };

    const onMouseLeave = () => {
        timerRef.current = setTimeout(() => {
            setHide(true);
            setTimeout(() => {
                onHide();
            }, TIPS_ANIMATION_LENGTH);
        }, TIPS_HIDE_TIME);
    };

    React.useEffect(() => {
        onMouseLeave();
        setShowTip(true);
    }, []);

    return <div
        className={`${className} ${isHide ? className + '--hide' : ''}`}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
    >
        <div
            className={className + '__inner'}
            onClick={onClick}
        >
            <div
                className={`
                    ${classItem}
                    ${classItem + (isShowTip ? '--show' : '--hide')}
                `}
            >
                <span className={classItem + '-icon'}>💡</span>
                <span className={classItem + '-text'}>{tips[tipIdx]}</span>
            </div>
        </div>
    </div>;
}
