import { ICard } from '../card/types';

export const sortCardsByOrderNumber = (
    cardA: ICard,
    cardB: ICard,
): number => {
    const valueA = cardA.orderNumber ? cardA.orderNumber : 0;
    const valueB = cardB.orderNumber ? cardB.orderNumber : 0;
    return valueA - valueB;
};
