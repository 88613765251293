import {Dispatch, ThunkAction} from 'app/types/actions';
import { SegmentBoardEvent, segmentTrackAction } from 'app/middlewares/segment';
import { TBoardId } from 'app/types/types';
import { getBoardIdsOpenedDescription, setBoardIdsOpenedDescription } from 'app/view/react_components/aside_panel/boardDetails/details/BoardDescription/helpers/appealLocalStorage';
import { LOCAL_STORAGE_KEY_BOARD_IDS_OPENED_DESCRIPTION } from 'app/view/react_components/aside_panel/boardDetails/details/BoardDescription/constants';

export const onToggle = (
    isCollapse: boolean,
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        let boardIdsOpenedDescription = getBoardIdsOpenedDescription(LOCAL_STORAGE_KEY_BOARD_IDS_OPENED_DESCRIPTION);
        if (!boardIdsOpenedDescription) boardIdsOpenedDescription = [];
        if (isCollapse) {
            dispatch(segmentTrackAction(SegmentBoardEvent.BOARD_SHOW_MORE_DESCRIPTION_CLICKED))
            boardIdsOpenedDescription.push(boardId)
            setBoardIdsOpenedDescription(LOCAL_STORAGE_KEY_BOARD_IDS_OPENED_DESCRIPTION, boardIdsOpenedDescription);
        } else {
            dispatch(segmentTrackAction(SegmentBoardEvent.BOARD_SHOW_LESS_DESCRIPTION_CLICKED))
            boardIdsOpenedDescription = boardIdsOpenedDescription.filter((board: TBoardId) => board !== boardId)
            setBoardIdsOpenedDescription(LOCAL_STORAGE_KEY_BOARD_IDS_OPENED_DESCRIPTION, boardIdsOpenedDescription);
        }
    };
    return action;
};
