import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import React from "react";
import { AsidePanelRecurringTasksHOC } from "../react_components/aside_panel/asidePanel/hocs/AsidePanelRecurringTasksHOC/AsidePanelRecurringTasksHOC";

export const RecurringTasksView = App.Views.BaseView.extend({
    initialize: function(options){
        this.store = options.store;
        this.boardId = options.boardId;
    },

    render: function () {
        ReactDOM.render(
            <Provider store={this.store}>
                <AsidePanelRecurringTasksHOC
                    boardId={this.boardId}
                    closedRef={document.activeElement}
                />
            </Provider>,
            this.el
        );

        return this;
    },

    remove: function () {
        ReactDOM.unmountComponentAtNode(this.el);
        Backbone.View.prototype.remove.call(this);
    }
});
