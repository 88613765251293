import {IClickManagerSet} from './types';
import {AT_CLICK_MANAGER_SET} from './constants';
import {TClickManagerModelAction} from '../../clickManagerModel/actions/types';

export const clickManagerActionSet = (
    boardId: number,
    clickManagerModelAction: TClickManagerModelAction
): IClickManagerSet => ({
    type: AT_CLICK_MANAGER_SET,
    boardId,
    clickManagerModelAction
});
