import * as React from 'react';
import { CardCountIndicator } from '../CardCountIndicator/CardCountIndicator';
import { IChecklistsCountIndicatorProps } from './types';
import { CHECKLISTS_TOOLTIP } from '../../wording';

export function ChecklistsCountIndicator({
    checklistsCount,
    doneChecklistsCount
}: IChecklistsCountIndicatorProps) {
    return (
        <CardCountIndicator icon={'done'} tooltip={CHECKLISTS_TOOLTIP}>
            <span>{doneChecklistsCount} / {checklistsCount}</span>
        </CardCountIndicator>
    );
}
