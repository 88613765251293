import { convertStringToSeconds } from './convertStringToSeconds';

export const formatCommentToSeconds = (
    comment: string, // 100500 [30m] + `1800s` [1jopa] + `38попугаев` [1d] [2d] [7h] [нечисло]
    workHoursInDay: number
): number => {
    let text = comment.replace(/(\[|\])/g,'`'); // 100500 `30m` + `1800s` `1jopa` + `38попугаев` `1d` `2d` `7h` `нечисло`
    let times: string[] = [];
    let i = 0;
    while (i < text.length) {
        if (text[i] === '`') { // время началось
            let time = '';
            i++;
            while (
                text[i] !== '`' && // время закончилось
                i < text.length
            ) {
                time += text[i];
                i++;
            }
            if (!isNaN(parseInt(time, 10))) { // если время парсится в число
                times.push(time);
            }
        }
        i++;
    }
    const str = times.join(' '); // '30m 1800s 1jopa 38попугаев 1d 2d 7h'
    return str ? convertStringToSeconds(str, workHoursInDay) : 0;
};
