import { IGetState, TCardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IRestCalendarEvent } from '../../../../types/rest/IRestCalendarEvent';
import { postRest } from './api/postRest';
import { ICards } from '../../../../store/model/cards/types';
import { getCard } from '../../../../store/model/selectors/getCard';
import { cardsUpdate } from '../../../../store/model/actionCreators/cardsUpdate';
import { sendAuthUserRealTimeStoreAction } from '../../../../view/react_components/base/helpers/realTimeHelperTS';

export const addCalendarEvent = (
    cardId: TCardId,
    calendarEvent: IRestCalendarEvent
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(postRest(cardId, calendarEvent))
            .then((calendarEvent: IRestCalendarEvent) => {
                const card = getCard(getState(), cardId);
                const cards: ICards = {
                    [cardId]: {
                        calendarEvents: [
                            ...card.calendarEvents,
                            calendarEvent
                        ]
                    }
                }
                const updateAction = cardsUpdate(cards);
                dispatch(updateAction);
                dispatch(sendAuthUserRealTimeStoreAction(updateAction))
            });
    };
    return action;
};
