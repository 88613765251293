import * as React from 'react';
import { CLASS_MY_WORK_CARD } from '../../../constants';
import { ICardNameProps } from './types';
import './_CardName.scss';
import { Tooltip } from 'kui';
import { escape } from 'underscore';
import { clearMarkdownInline } from '../../../../../base/helpers/clearMarkdownHelper';
import ReactHtmlParser from 'react-html-parser';

export function CardName({
    card
}: ICardNameProps) {
    const className = CLASS_MY_WORK_CARD + '__main-info';

    const [name, setName] = React.useState('');
    React.useEffect(() => {
        setName(escape(clearMarkdownInline(card.cardFullName)));
    }, [card.cardFullName]);

    return (
        <div className={className}>
            <div className={className + '-name-wrapper'}>
                <div className={className + '-name'}>
                    <Tooltip value={name} direction={'up-right'}>
                        <span>{ReactHtmlParser(name)}</span>
                    </Tooltip>
                </div>
            </div>
        </div>
    )
}
