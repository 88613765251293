import { IApplicationState, TBoardId } from '../../../types/types';
import { getBoardCardsActiveAndArchive } from './getBoardCardsActiveAndArchive';
import { ORDER_STEP } from '../../../const';
import { getRealGanttOrderNumber } from '../helpers/getRealGanttOrderNumber';

export const getBoardNextGanttOrderNumber = ( // logic from App.Collections.Cards
    state: IApplicationState,
    boardId: TBoardId
): number => {
    let maxGanttOrderNumber = ORDER_STEP;
    const cards = getBoardCardsActiveAndArchive(state, boardId);
    cards.forEach(card => {
        const realGanttCardOrder = getRealGanttOrderNumber(card, 0);
        if (realGanttCardOrder > maxGanttOrderNumber) {
            maxGanttOrderNumber = realGanttCardOrder;
        }
    });
    return maxGanttOrderNumber + ORDER_STEP;
}
