import * as React from 'react';
import './_ScrollText.scss';
import { IScrollTextProps } from './types';
import { CLASS_SCROLL_TEXT } from './constants';
import ReactHtmlParser from 'react-html-parser';

export function ScrollText ({
    text,
    scrollTarget
}: IScrollTextProps) {
    const ref = React.useRef(null);

    const timeout = React.useRef(null);

    const classNameLeftFade = CLASS_SCROLL_TEXT + '__fade--left';
    const classNameRightFade = CLASS_SCROLL_TEXT + '__fade--right';

    const minIndent = `0px`;
    const maxIndent = ref.current ? `calc(100% - ${ref.current.scrollWidth}px)` : `0px`;

    function onMouseMove (e: any) {
        if (!timeout.current) {
            timeout.current = setTimeout(() => { // пауза, чтоб не дёргалось, когда курсор проходит мимо
                timeout.current = null;
                const target = e.target as HTMLElement
                if (target && (target.scrollWidth - target.clientWidth) > 16) {//16px, не скролить если не видно только окончание строки
                    const x = e.clientX - 56; // 56 это какие-то отступы наверно, хз
                    const maxTargetIndent = `calc(100% - ${target.scrollWidth}px)`;
                    if (x < target.clientWidth * .50 && target.style.textIndent === minIndent) {
                        return;
                    } else if (x < target.clientWidth * .10) {
                        target.style.textIndent = minIndent;
                    } else if (x > target.clientWidth * .85) {
                        target.style.textIndent = maxTargetIndent;
                    } else {
                        const percent = x / (target.clientWidth - 8); // 8px величина наложения кнопки справа на текст
                        const scroll = target.clientWidth - target.scrollWidth;
                        target.style.textIndent = `calc(${percent} * ${scroll}px)`
                    }
                    if (target.style.textIndent !== minIndent) {
                        setFade(target, classNameLeftFade)
                    } else {
                        const fade = target.querySelector(`.${classNameLeftFade}`)
                        if (fade) fade.remove()
                    }
                    if (target.style.textIndent !== maxTargetIndent) {
                        setFade(target, classNameRightFade)
                    } else {
                        const fade = target.querySelector(`.${classNameRightFade}`)
                        if (fade) fade.remove()
                    }
                }
            }, 300);
        }
    }
    function onMouseLeave (e: any) { //проскролить в начало, если курсор ушёл
        if (timeout.current) {
            clearTimeout(timeout.current);
            timeout.current = null;
        }
        const target = e.target as HTMLElement
        if (target && target.scrollWidth > target.clientWidth) {
            target.style.textIndent = minIndent;
            const fade = target.querySelector(`.${classNameLeftFade}`)
            if (fade) fade.remove()
            setFade(target, classNameRightFade)
        }
    }

    function setFade (target: HTMLElement, className: string) {
        const fade = target.querySelector(`.${className}`);
        if (!fade) {
            const newFade = document.createElement('div');
            newFade.classList.add(`${className}`)
            target.appendChild(newFade);
        }
    }

    React.useEffect(() => {
        setTimeout(() => {
            if (ref.current && ref.current.scrollWidth > ref.current.clientWidth) {
                ref.current.addEventListener('mousemove', onMouseMove);
                ref.current.addEventListener('mouseleave', onMouseLeave);
                setFade(ref.current, classNameRightFade)
            }
        }, 100);
        return (() => {
            if (ref.current) {
                ref.current.removeEventListener('mousemove', onMouseMove);
                ref.current.removeEventListener('mouseleave', onMouseLeave);
                ref.current.style.textIndent = 0;
            }
        })
    }, [text]);

    React.useEffect(() => {
        if (timeout.current) {
            clearTimeout(timeout.current);
            timeout.current = null;
        }
        if (ref.current && (ref.current.scrollWidth - ref.current.clientWidth) > 16) {//16px, не скролить если не видно только окончание строки
            if (timeout.current) {
                clearTimeout(timeout.current);
                timeout.current = null;
            }
            if (scrollTarget.scrollToEnd) {
                ref.current.style.textIndent = maxIndent;
                const fade = ref.current.querySelector(`.${classNameRightFade}`)
                if (fade) fade.remove()
                setFade(ref.current, classNameLeftFade)
            } else if (!scrollTarget.scrollToEnd && scrollTarget.relatedTarget !== ref.current) {
                ref.current.style.textIndent = minIndent;
                const fade = ref.current.querySelector(`.${classNameLeftFade}`)
                if (fade) fade.remove()
                setFade(ref.current, classNameRightFade)
            }
        }
    },[scrollTarget])

    return (
        <div
            className={CLASS_SCROLL_TEXT}
            ref={ref}
            onMouseMove={onMouseMove}
            onMouseLeave={onMouseLeave}
        >
            {ReactHtmlParser(text)}
        </div>
    )
};
