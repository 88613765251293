import { calcDateOfNotification } from '../../../../utils/calcDateOfNotification';
import { IGetState, TBoardId, TCardId } from '../../../../../../../../../../types/types';
import { INotificationBeforeDueDate } from '../../../../../../../../../../store/model/notificationBeforeDueDate/types';
import { Dispatch } from '../../../../../../../../../../types/actions';
import { getCard } from '../../../../../../../../../../store/model/selectors/getCard';
import { notificationBeforeDueDateAdd } from '../../../../../../../../../../rest/effects/card/notificationBeforeDueDate/notificationBeforeDueDateAdd';

export const saveNotificationBeforeDueDate = (
    boardId: TBoardId,
    cardId: TCardId,
    notification: INotificationBeforeDueDate,
    orderNumber: number,
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        const card = getCard(getState(), cardId);
        const {interval, unit} = notification;
        const dueDate = card.dueDate;
        const dateOfNotification = calcDateOfNotification(interval, unit, dueDate);

        const notificationBeforeDueDate: INotificationBeforeDueDate = {
            cardId,
            dashboardId: boardId,
            dateOfNotification,
            dueDate,
            interval,
            orderNumber,
            unit,
        };

        return dispatch(notificationBeforeDueDateAdd(cardId, notificationBeforeDueDate));
    }
    return action;
};
