import { IGetState, TCardId, TChecklistId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { checklistItemsUpdate } from '../../../../../../../../rest/effects/card/checklist/checklistItemsUpdate';
import { getChecklistItemsSection } from '../../selectors/getChecklistItemsSection';

const getChecklistItems = getChecklistItemsSection();

export const onAllItemsDone = (
    cardId: TCardId,
    checklistId: TChecklistId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const checklistItems = getChecklistItems(getState(), cardId, checklistId).map(item => {
            return { id: item.id, checked: true };
        });
        return dispatch(checklistItemsUpdate(cardId, checklistId, checklistItems));
    };
    return action;
};
