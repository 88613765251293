import { IApplicationState } from '../../../../types/types';
import { getAuthUser, SNOOZE_BLOCKER_MINUTES } from './getAuthUser';
import { getIsUserStarterPlanOnly } from './getIsUserStarterPlanOnly';

export const getProFeaturesTrialActive = (
    state: IApplicationState
): boolean => {
    if (!getIsUserStarterPlanOnly(state)) return;
    const user = getAuthUser(state);
    const snoozeBlockerTime = user.meta && user.meta.snoozeBlockerTime;
    return snoozeBlockerTime && Date.now() - snoozeBlockerTime < SNOOZE_BLOCKER_MINUTES * 60 * 1000;
};
