import { IApplicationState } from '../../../../types/types';
import { getAuthUser } from './getAuthUser';
import { EAuthUserPlatformType } from 'app/types/rest/IRestAuthUser';

export const isMicrosoftPersonalAccount = (
    state: IApplicationState,
): boolean => {
    const authUser = getAuthUser(state);
    if (authUser.platformType === EAuthUserPlatformType.GOOGLE) {
        // @ts-ignore
        if(window.Settings.development) {
            console.error('isMicrosoftPersonalAccount available only with MICROSOFT account', authUser);
        }
    }
    return !Boolean(authUser.tenantName);
};
