import { root, vent } from '../../../../../store/constants';

export const reboot = (timer: number = 0) => {
    const action = () => {
        vent.trigger(vent['dashboards:loading'], true);
        root.App.controller.mainView.blockerClassToggle(true);
        const reboot = () => {
            location.reload();
        };
        setTimeout(reboot, timer);
    };
    return action;
};
