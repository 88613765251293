import { IApplicationState, TCardId } from '../../../../types/types';
import { getListCardsActiveAndArchive } from '../../selectors/getListCardsActiveAndArchive';
import { getOrderNumberByCards } from '../../helpers/getOrderNumberByCards';
import { getBoardByListId } from '../../selectors/getBoardByListId';
import { getList } from './getList';
import { ORDER_STEP } from '../../../../const';

export const getNextCardOrderNumber = (
    state: IApplicationState,
    listId: TCardId,
    first: boolean = true
): number => {
    const board = getBoardByListId(state, listId);
    if (board.noCards) {
        const list = getList(state, listId);
        return first ? (list.cardAutoDecrement || 0) - ORDER_STEP : (list.cardAutoIncrement || 0) + ORDER_STEP;
    }
    const cards = getListCardsActiveAndArchive(state, listId);
    return getOrderNumberByCards(cards, first);
};
