import { ISignIn } from './types';

export const getSignInInitialState = (
): ISignIn => {
    const SIGNIN_INITIAL_STATE: ISignIn = {
        error: null,
        isLoading: false,
        isShow: false,
    };

    return SIGNIN_INITIAL_STATE;
};
