import './_Shortcuts.scss';
import { IShortcutsProps } from './types';
import * as React from 'react';
import { KEYBOARD_SHORTCUTS_CLASS } from '../keyboardShortcuts/constants';
import { Shortcut } from '../shortcut/Shortcut';

export const Shortcuts = ({
    title,
    shortcuts
}: IShortcutsProps) => {
    const classShortcuts = KEYBOARD_SHORTCUTS_CLASS + '__shortcuts';
    const classTitle = KEYBOARD_SHORTCUTS_CLASS + '-shortcuts__title';

    return (
        <div className={classShortcuts}>
             <span className={classTitle}>
                 {title}
             </span>
            {
                shortcuts.map(({ name, buttons}, index) => {
                    return (
                        <Shortcut
                            name={name}
                            buttons={buttons}
                            key={index}
                        />
                    );
                })
            }
        </div>
    );
}
