import { EFeature, TOPBAR_REDESIGN_RELEASE } from 'app/types/rest/IUserMeta';
import { IApplicationState } from '../../../../../types/types';
import { getAuthUser } from '../getAuthUser';
import { isNewFeatureForUser } from '../isNewFeatureForUser';

export const getIsNewFeatureTopbarRedesign = (
    state: IApplicationState
): boolean => {
    const isNewFeature = isNewFeatureForUser(state, EFeature.TOPBAR_REDESIGN);
    if (!isNewFeature) return false;

    const user = getAuthUser(state);
    if (user && user.createDateTimeStamp && user.createDateTimeStamp > TOPBAR_REDESIGN_RELEASE) return false;

    return true;
};
