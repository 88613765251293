import { IGetState, TCardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { ICards } from '../../../../store/model/cards/types';
import { getCard } from '../../../../store/model/selectors/getCard';
import { cardsUpdate } from '../../../../store/model/actionCreators/cardsUpdate';
import { sendAuthUserRealTimeStoreAction } from '../../../../view/react_components/base/helpers/realTimeHelperTS';
import { deleteRest } from './api/deleteRest';
import { getCardCalendarEvents } from '../../../../store/model/selectors/getCardCalendarEvents';

export const deleteCalendarEvent = (
    cardId: TCardId,
    calendarId: string,
    type: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const calendarEvent = getCardCalendarEvents(getState(), cardId)
            .find(event => event.calendarId === calendarId && event.type === type);

        if (!calendarEvent) return Promise.reject();

        return dispatch(deleteRest(calendarEvent.id))
            .then(() => {
                const card = getCard(getState(), cardId);
                const calendarEvents = card.calendarEvents.filter(event => event.id !== calendarEvent.id);
                const cards: ICards = {
                    [cardId]: {
                        calendarEvents
                    }
                }
                const updateAction = cardsUpdate(cards);
                dispatch(updateAction);
                dispatch(sendAuthUserRealTimeStoreAction(updateAction))
            });
    };
    return action;
};
