import { IList } from '../../../store/model/list/types';
import { TStatus } from '../../../types/model';
import { listTemplateAdd } from '../../effects/list/listTemplateAdd';
import { IApplicationState, TBoardId } from '../../../types/types';
import { dispatch } from '../../../store/configureStore';
import { getBoardListsByStatus } from '../../../store/model/selectors/getBoardListsByStatus';

export const getTemplateListPromise = (
    state: IApplicationState,
    boardId: TBoardId
): Promise<IList> => {
    const templateList = getBoardListsByStatus(state, boardId, TStatus.STATUS_SERVICE_TEMPLATE)[0];
    if (templateList) {
        return Promise.resolve(templateList);
    }
    return dispatch(listTemplateAdd(boardId));
};
