import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TBoardId, TCardId } from '../../../../../../../../types/types';
import { SegmentCardEvent, SegmentCardOptions, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { SegmentCardSection } from '../../../../../../../../middlewares/segment/trackEntities/cardEvents';
import { getSectionType } from '../../../../selectors/getSectionType';
import { ESettingsSectionType } from '../../../../../../base/components/SettingsSection/types';
import { showSnoozeBlocker } from '../../../../../../base/effects/showSnoozeBlocker';

export const onClick = (
    boardId: TBoardId,
    cardId: TCardId,
    ownOnClick?: () => void
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.BUTTONS_PANEL_BUTTON_CLICKED, {
            name: SegmentCardOptions.TARGET,
            value: SegmentCardSection.CUSTOM
        }));
        if (ownOnClick) ownOnClick();

        const sectionType = getSectionType(getState(), boardId, cardId, null, 'allowCardCustomProperties');
        const isDisallow = sectionType === ESettingsSectionType.DISALLOW;

        if (isDisallow) {
            dispatch(showSnoozeBlocker({
                allow: 'allowCardCustomProperties'
            }));
        }
    };
    return action;
};
