import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { commentInit } from '../../../store/reducers/actions/commentInit';

export const onClose = (
    onCloseOwn?: () => void
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(commentInit());
        if (onCloseOwn) onCloseOwn();
    };
    return action;
};
