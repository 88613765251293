import { INotificationSettings } from './types';
import { ENotificationEmailStatus, ENotificationPeriod } from '../../../types/rest/IRestNotificationSettings';

export const getNotificationSettingsInitial =
    (): INotificationSettings => {
        const NOTIFICATION_INITIAL: INotificationSettings = {
            enabled: false,
            notificationEmail: '',
            notificationEmailStatus: ENotificationEmailStatus.NOT_DEFINED,
            period: ENotificationPeriod.SOON,
            scope: []
        }
        return NOTIFICATION_INITIAL;
    };
