import { ITabsModelRemoveTabsAction } from './types';
import { AT_REMOVE_TABS_ACTION } from './constants';
import { TBoardId } from '../../../../types/types';

export const tabsModelRemoveTabsAction = (
    tabIds: TBoardId[]
): ITabsModelRemoveTabsAction => ({
    type: AT_REMOVE_TABS_ACTION,
    tabIds
});
