import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState } from '../../../../../../../types/types';
import { NAVIGATION_PANEL_QUICK_START_GUIDE_LOCALSTORAGE } from '../constants';
import { ENavigationPanelTypes } from '../../../constants';
import { getCurrentNavigationPanelType } from '../../../helpers/getCurrentNavigationPanelType';
import { panelTypeSetAction } from '../../../store/navigationPanel/actions/panelTypeSetAction';
import { isOpenSetAction } from '../../../store/navigationPanel/actions/isOpenSetAction';
import { isAsideOpenSetAction } from '../../../store/navigationPanel/actions/isAsideOpenSetAction';

export const onOpen = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const navigationPanelType = getCurrentNavigationPanelType(getState());
        if (navigationPanelType === ENavigationPanelTypes.NP_COMPACT_TYPE) {
            dispatch(panelTypeSetAction(ENavigationPanelTypes.NP_COMPACT_BIG_TYPE));
            dispatch(isOpenSetAction(false));
        }
        dispatch(isAsideOpenSetAction(true));
        localStorage.setItem(NAVIGATION_PANEL_QUICK_START_GUIDE_LOCALSTORAGE, String(1));
    };
    return action;
};
