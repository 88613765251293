import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState } from '../../../../../../../types/types';
import { Util } from '../../../../../../../util/util';
import { QUALIFICATION_QUESTIONS_CALENDAR_URL, QUALIFICATION_QUESTIONS_EDU_CALENDAR_URL } from '../../../constants';
import { getUserQualificationFormAnswers } from '../../../../../../../store/model/authUser/selectors/getUserQualificationFormAnswers';

export const onShowCalendar = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const date = new Date();
        const month = date.getMonth() + 1;
        const dateParam = date.getFullYear() + '-' + (month < 10 ? '0' : '') + month;
        const url = (isShowEduCalendar(getState) ? QUALIFICATION_QUESTIONS_EDU_CALENDAR_URL : QUALIFICATION_QUESTIONS_CALENDAR_URL).replace('{date}', dateParam);
        Util.popupWindow(url, 1000, 750);
    };
    return action;
};

const isShowEduCalendar = (getState: IGetState) => {
    const answers = getUserQualificationFormAnswers(getState());
    return ((answers['work_tool'] && answers['work_tool'].key === 'work_tool_edu') ||
        (answers['organization'] && answers['organization'].key === 'organization_edu'));
}
