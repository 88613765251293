import { IApplicationState } from '../../../../types/types';
import { ISubscription } from '../../../../view/react_components/subscriptionAdmin/store/subscription/types';
import { getAuthUser } from './getAuthUser';

export const getUserManagedSubscriptions = (
    state: IApplicationState
): ISubscription[] => {
    const user = getAuthUser(state);

    return user.managedSubscription || [];
};
