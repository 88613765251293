import { IApplicationState, TBoardId } from '../../../../types/types';
import { getBoard } from '../../selectors/getBoardById';

export const hasBoardWarning = (
    state: IApplicationState,
    boardId: TBoardId
): boolean => {
    const board = getBoard(state, boardId);
    return Boolean(board.meta.warnings) && board.meta.warnings.length > 0;
};
