import { IDriveDoc } from '../../../../../../../../store/model/card/types/IDriveDoc';
import { ERestDriveDocType } from '../../../../../../../../types/rest/IRestDriveDoc';
import { IGoogleDriveOpenDialogueFile, TCardId } from '../../../../../../../../types/types';

export const mapNewDriveDoc = (
    doc: IGoogleDriveOpenDialogueFile,
    orderNumber: number,
    cardId: TCardId,
    type: ERestDriveDocType,
): IDriveDoc => {
    return {
        fileId: doc.id,
        title: doc.name,
        mimeType: doc.mimeType,
        url: doc.url,
        iconUrl: doc.iconUrl,
        newFile: doc.isNew,
        orderNumber,
        type,
        cardId
    }
}
