import { fetchHandler } from '../../../../util/fetchHandler';
import { Util } from '../../../../util/util';
import { REST_USER_STAT_ADMIN } from '../../../constants';
import { getRestHeadersPost } from '../../../helpers/getRestHeadersHelper';
import { ThunkAction } from '../../../../types/actions';

export const postRest = (
    event: IUserStatAdminReportEventRest
): ThunkAction => {
    const action = () => {
        return fetchHandler(Util.getApiUrl(REST_USER_STAT_ADMIN + event.permissionId), {
            ...getRestHeadersPost(),
            body: JSON.stringify(event)
        })
    };
    return action;
};

export interface IUserStatAdminReportEventRest {
    permissionId?: string
    email?: string
    fullName?: string
    domain?: string
    country?: string
    createDate?: number
    loginCount?: string
    loginLastDate?: number
    gantViewCount?: string
    gantViewLastDate?: number
    kanbanViewCount?: string
    kanbanLastViewDate?: number
    listViewCount?: string
    listViewLastViewDate?: number
    reportViewCount?: string
    reportLastViewDate?: number
    usersDashboardViewCount?: string
    usersDashboardLastViewDate?: number
    colorTagsDashboardViewCount?: string
    colorTagsDashboardLastViewDate?: number
    cardColorsDashboardViewCount?: string
    cardColorsDashboardLastViewDate?: number
    tagsDashboardViewCount?: string
    tagsDashboardLastViewDate?: number
    prioritiesDashboardViewCount?: string
    prioritiesDashboardLastViewDate?: number
}
