import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { TBoardId } from '../../../../../../../../../types/types';
import { toggleEnabledCardNumber } from '../../PrefixesSectionHOC/effects/toggleEnabledCardNumber';

export const onToggleOff = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(toggleEnabledCardNumber(boardId));
    };
    return action;
};
