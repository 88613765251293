import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState, TCardId } from '../../../../types/types';
import { getNextSubCardOrderNumber } from '../../../../store/model/selectors/getNextSubCardOrderNumber';
import { getCard } from '../../../../store/model/selectors/getCard';
import { cardsRestPatch } from './api/helper/cardsRestPatch';
import { ICards } from '../../../../store/model/cards/types';
import { getCardEpicsForDueDateChange } from '../../../../store/model/selectors/getCardEpicsForDueDateChange';
import { getCardEpicsForStartDateChange } from '../../../../store/model/selectors/getCardEpicsForStartDateChange';
import { getCardEpicsForProcessingPercentChange } from '../../../../store/model/selectors/getCardEpicsForProcessingPercentChange';

export const cardAddEpic = (
    cardId: TCardId,
    epicId: TCardId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const card = getCard(state, cardId);
        if (!card) return Promise.reject();

        if (card.epicId === epicId) return Promise.resolve();

        const subCardOrderNumber = getNextSubCardOrderNumber(state, epicId);
        const cards: ICards = {
            [cardId]: {
                epicId,
                subCardOrderNumber
            }
        };

        return dispatch(cardsRestPatch(cards))
        //TODO оставил пока логику бекбона. Потом будем переписывать все вместе.
            .then(() => {
                const state = getState();
                let cards: ICards = {};
                cards = getCardEpicsForDueDateChange(state, epicId, cards);
                cards = getCardEpicsForStartDateChange(state, epicId, cards);
                cards = getCardEpicsForProcessingPercentChange(state, epicId, cards);
                return dispatch(cardsRestPatch(cards));
            });
    };
    return action;
};
