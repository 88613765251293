import { IGetState } from '../../../types/types';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { getMessages, root } from '../../../store/constants';
import { IBoard } from '../../../store/model/board/types';
import { boardRestCopy } from './api/helpers/boardRestCopy';
import { createBoardByDriveId } from '../../../view/react_components/base/effects/createBoardByDriveId';
import { DRIVE_ID_MY_DRIVE } from '../../../view/react_components/dialogs/openBoards/store/drives/constants';
import { segmentTrackAction, SegmentUserEvent } from 'app/middlewares/segment';
import { SegmentUserOption } from 'app/middlewares/segment/trackEntities/userEvents';
import { getFirstBoardId } from 'app/abtesting/abTests/firstBoardType/selectors/getFirstBoardId';
import { getOpened } from 'app/view/react_components/dialogs/openBoards/store/common/selectors/getOpened';
import { firstBoardLoadToStore } from 'app/rest/effects/firstBoard/firstBoardLoadToStore';
import { ETutorial } from 'app/types/rest/IUserMeta';
import { FIRST_BOARD_NAME } from '../../../const';

export const createFirstBoard = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        const boardId = getFirstBoardId(getState());
        dispatch(segmentTrackAction(SegmentUserEvent.DEBUG, {
            name: 'createFirstBoard',
            value: 'start:' + boardId,
        }));
        const start = Date.now();
        root.App.controller.showSystemLoader(true, null, true);
        root.App.controller.boardLoaderInfo.isFirstBoard = true;
        root.App.controller.boardLoaderInfo.boardName = FIRST_BOARD_NAME; // так как доска теперь не грузится как остальные, заставляем лоудер с подсказками появится
        root.App.controller.mainView.doAfterLoader(() => {
            root.App.controller.showTutorial(ETutorial.SIGN_IN_TUTORIAL);
        });
        return firstBoardLoadToStore(boardId)
            .then((board: IBoard) => {
                if (!board) {
                    dispatch(segmentTrackAction(SegmentUserEvent.DEBUG, {
                        name: 'createFirstBoard',
                        value: 'error.record.not_found',
                    }));
                    return Promise.reject(getMessages().getText('error.record.not_found'));
                }
                dispatch(segmentTrackAction(SegmentUserEvent.DEBUG, {
                    name: 'createFirstBoard',
                    value: 'boardRestCopy_start',
                }));
                return dispatch(boardRestCopy(boardId, '', false, false, false, true, true, true, false))
            })
            .then(() => {
                dispatch(segmentTrackAction(SegmentUserEvent.DEBUG, {
                    name: 'createFirstBoard',
                    value: 'boardRestCopy_done',
                }));
                const time = Math.round((Date.now() - start) / 1000);
                dispatch(segmentTrackAction(SegmentUserEvent.MY_FIRST_BOARD_TIME, {
                    name: SegmentUserOption.TIME,
                    value: time,
                }));
            })
            .catch((e: any) => {
                let message = e.stack ? e.stack : (e.massage ? e.massage : ''+JSON.stringify(e))
                dispatch(segmentTrackAction(SegmentUserEvent.DEBUG, {
                    name: 'createFirstBoard_catch',
                    value: message,
                }));
                return dispatch(createBoardByDriveId(DRIVE_ID_MY_DRIVE));
            })
            .finally(() => {
                dispatch(segmentTrackAction(SegmentUserEvent.DEBUG, {
                    name: 'createFirstBoard',
                    value: 'finally',
                }));

                if (getOpened(getState())) {
                    import(/* webpackChunkName: "openBoards" */ 'app/view/react_components/dialogs/openBoards/effects/closeDialog')
                        .then(module => dispatch(module.closeDialog()))
                        .catch(e => console.error(e))
                }
            });
    };
    return action;
};
