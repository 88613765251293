import { IGetState, TBoardId } from '../../../types/types';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { fetchHandler } from '../../../util/fetchHandler';
import Util from '../../../util/util';
import { REST_BOARD, REST_BOARD_STARTLISTENING } from '../../constants';
import { getRestHeadersGet } from '../../helpers/getRestHeadersHelper';

export const boardStartListening = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return fetchHandler(
            Util.getApiUrl(REST_BOARD + boardId + REST_BOARD_STARTLISTENING),
            getRestHeadersGet()
        );
    };
    return action;
}
