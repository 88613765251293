'use strict';

import { getActiveBoardId } from '../store/model/selectors/getActiveBoardId';
import { getAppState } from '../store/configureStore';
import * as _ from 'underscore';

App.Collections.StaredNotifications = App.Collections.BaseCollection.extend({
    classKey: 'staredNotifications',
    model: App.Models.StaredNotification,
    url: function() {
        const params = {};

        if (this.cursor) {
            params.cursor = this.cursor;
        }

        const query = new URLSearchParams(params).toString();
        return App.Util.getApiUrl('/rest/staredPushnotification/list' + (query? "?"+ query: ""));
    },
    initialize: function(models, options) {
        this.options = options || {};
    },


    fetch: function(options) {
        const startLoading = new Date().getTime();
        this.startLoading = startLoading;
        options = options || {};
        options.originalSuccess = options.success;
        options.startLoading = startLoading;
        options.success = _.bind(this.fetchSuccess, this);
        this.backUp = this.clone();
        Backbone.Collection.prototype.fetch.call(this, options);
    },

    fetchSuccess: function(collection, response, options) {
        if (this.startLoading !== options.startLoading){
            return;
        }
        var backUpModels = this.backUp.filter(function(model) {
            return !model.get('cursor');
        });
        var uniqueModelsIds = [];
        var fetchedModels = _.filter(response.objects, function(obj) {
            var modelHistoryId = JSON.parse(obj.modelHistoryJson)[0].id;
            var same = _.find(backUpModels, function(model) {
                return model.getNotificationModel(0).id === modelHistoryId;
            });
            if (same || uniqueModelsIds.indexOf(modelHistoryId) > -1) return false;
            uniqueModelsIds.push(modelHistoryId);
            return true;
        });
        var models = _.union(backUpModels, fetchedModels);
        this.reset(models);
        this.cursor = response.cursor;
        if (_.isFunction(options.originalSuccess)) {
            options.originalSuccess();
        }
    },

    hasMore: function() {
        return this.cursor && this.cursor != '';
    },

});
