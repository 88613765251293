import * as React from 'react';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { PanelActionsHOC } from '../../hocs/PanelActionsHOC/PanelActionsHOC';

export function PanelActionsContext() {
    const { boardId } = React.useContext(AsidePanelContext);

    return <PanelActionsHOC
        boardId={boardId}
    />;
}
