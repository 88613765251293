import { IApplicationState, TBoardId } from '../../../types/types';
import { getBoard } from './getBoardById';

export const getBoardMetaAutoStartDate = (
    state: IApplicationState,
    boardId: TBoardId
): boolean => {
    const board = getBoard(state, boardId);
    return board && board.meta && board.meta.isAutoStartDate;
}
