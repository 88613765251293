import { getMessages } from '../../../../../../../store/constants';

export const CALENDAR_WEEK_DAYS= [
    getMessages().getText('datepicker.calendar.monday'),
    getMessages().getText('datepicker.calendar.tuesday'),
    getMessages().getText('datepicker.calendar.wednesday'),
    getMessages().getText('datepicker.calendar.thursday'),
    getMessages().getText('datepicker.calendar.friday'),
    getMessages().getText('datepicker.calendar.saturday'),
    getMessages().getText('datepicker.calendar.sunday')
];

export const CALENDAR_WEEK_DAYS_SUNDAY_START = [
    CALENDAR_WEEK_DAYS.at(-1),
    ...CALENDAR_WEEK_DAYS.slice(0, -1)
];

export const MONTH_LIST_WIDTH = 276;
export const MONTH_LIST_HEIGHT = 276;
export const MONTH_LIST_SIZE = 3000;
export const MONTH_LIST_MIDDLE = MONTH_LIST_SIZE / 2;
export const MONTH_HEADER_HEIGHT = 36;
export const MONTH_WEEK_HEIGHT = 32;
export const MONTH_WEEK_MARGIN_TOP = 6;
export const ESTIMATED_MONTH_HEIGHT = MONTH_WEEK_HEIGHT * 5 + MONTH_WEEK_MARGIN_TOP * 3 + MONTH_HEADER_HEIGHT;

export const CALENDAR_TODAY = getMessages().getText('datepicker.calendar.today.tooltip');
