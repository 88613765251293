import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../../types/types';
import { CardAgingPanelButton } from '../../components/CardAgingPanelButton/CardAgingPanelButton';
import { onClick } from './events/onClick';
import { IBoardCardAgingPanelButtonHOCProps } from './types';
import {
    IBoardCardAgingPanelButtonEvents,
    IBoardCardAgingPanelButtonFields
} from '../../components/CardAgingPanelButton/types';
import { getBoardCardAgingEnabled } from '../../../../../../../../store/model/board/selectors/getBoardCardAgingEnabled';
import { getIsUserBasicPlanOnly } from '../../../../../../../../store/model/authUser/selectors/getIsUserBasicPlanOnly';

const mapStateToProps = (
    state: IApplicationState,
    { boardId }: IBoardCardAgingPanelButtonHOCProps
): IBoardCardAgingPanelButtonFields => {
    return {
        boardId,
        indicatorBoolean: getBoardCardAgingEnabled(state, boardId),
        isBasicFeature: getIsUserBasicPlanOnly(state)
    }
};

const mapDispatchToProps = (
    dispatch: any,
    { onClick: ownOnClick }: IBoardCardAgingPanelButtonHOCProps
): IBoardCardAgingPanelButtonEvents => {
    return {
        onClick: () => dispatch(onClick(ownOnClick))
    }
};

export const CardAgingPanelButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardAgingPanelButton);

CardAgingPanelButtonHOC.displayName = 'CardAgingPanelButtonHOC';
