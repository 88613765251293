import { snackbarErrorDefault } from '../../../../../view/react_components/snackbarsStack';
import { SNACKBAR_TIMER_INFO } from '../../../../../view/react_components/snackbarsStack/hocs/SnackbarHOC/constants';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { IGetState } from '../../../../../types/types';

export const showColorLabelLongNameError = (
    text: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(snackbarErrorDefault({
            id: 'SNACKBAR_COLOR_LABEL_LONG_NAME_ERROR_ID',
            text: text,
            timer: SNACKBAR_TIMER_INFO
        }));
    };
    return action;
}
