import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState } from '../../../../../../../types/types';
import { IQuestion } from '../../../../../base/components/Poll/components/Poll/types';
import { POLL_DELAY_AFTER } from '../../../../../base/components/Poll/components/constants';
import { segmentTrackAction, SegmentUserEvent } from '../../../../../../../middlewares/segment';
import {
    pricingAnswerSegmentEvent,
    SegmentUserOption
} from '../../../../../../../middlewares/segment/trackEntities/userEvents';
import { authUserSetPricingPoll } from '../../../../../../../rest/effects/authUser/authUserSetPricingPoll';

export const onSubmit = (
    results: IQuestion[],
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        // const answers = results[0].answer as string[];
        // answers.forEach(value => {
        //     dispatch(pricingAnswerSegmentEvent(value));
        // });
        const review = results[0] && results[0].answer as string;
        if (review && review.length) {
            dispatch(segmentTrackAction(SegmentUserEvent.PRICING_POLL_REVIEW, {
                name: SegmentUserOption.TEXT,
                value: review
            }));
        }
        setTimeout(() => {
            dispatch(authUserSetPricingPoll({
                isShowed: true,
                isSkipped: false,
                lastTimeShowed: Date.now()
            }));
        }, POLL_DELAY_AFTER) // только что ответил, показать Thank you
    };
    return action;
};
