import { getMessages } from '../../../../../../store/constants';

export const CLASS_FREE_VERSION_BUTTON = 'free-version-button';

export const FREE_VERSION_BUTTON_TEXT = getMessages().getText('free_version.upgrade.text');
export const FREE_VERSION_BUTTON_BUTTON_START_TRIAL = getMessages().getText('free_version.upgrade.button.start.trial');
export const FREE_VERSION_BUTTON_BUTTON_UPGRADE= getMessages().getText('free_version.upgrade.button.upgrade');
export const FREE_VERSION_BUTTON_BUTTON_REQUEST_LICENSE= getMessages().getText('common.request_license');

export const FREE_VERSION_MODAL = {
    title: 'Free version has limited functionality',
    header: [
        'Only 10 cards on a board',
        'Read-only mode for shared boards with exceeded card limit',
    ],
    trial: [
        'Unlimited cards on a board',
        'Unlimited boards',
        'Kanbanchi Enterprise features',
    ],
    trialButton: 'Start trial now',
    buy_after_trial: [
        'Unlimited cards on a board',
        'Unlimited boards',
    ],
    buy: [
        'Gantt chart',
        'Google Workspace integration',
        'Boards templates',
        'Backups',
        'And much more...',
    ],
    buyButton: 'Buy subscription',
}
