import * as React from 'react';
import { IEndTypeProps } from './types';
import { Input, IRadioActiveProps, Radio } from 'kui';
import './_EndType.scss';
import { CLASS_CARD_SECTION_RECURRING } from '../../../constants';
import { ErrorHint } from '../../../../../base/components/ErrorHint/ErrorHint';
import { v4 as uuidv4 } from 'uuid';
import * as moment from 'moment';
import { END_TYPES } from './constants';
import { LABEL_AFTER, LABEL_NEVER, LABEL_OCCURRENCES, LABEL_ON } from '../wording';
import { ERecurringTaskEndType } from '../../../../../../../types/rest/recurringTask/IRestRecurringTask';
import { CardRecurringFormContext } from '../CardRecurringForm/constants';
import { DatepickerHOC } from '../../../../../base/components/Datepicker/hocs/DatepickerHOC/DatepickerHOC';
import { GOOGLE_SPACING } from '../../../../../../../const';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { ID_ASIDE_PANEL_DATEPICKER_PORTAL } from '../../../../asidePanel/components/AsidePanelActionsButton/constants';

export function EndType({
    errorDueDate,
    sectionType,
    onDueDateChange
}: IEndTypeProps) {
    const {form, updateForm, updateFormState, isReadonly} = React.useContext(CardRecurringFormContext);
    const {endType, startDate, dueDate, repeats} = form
    const isPopup = sectionType === ESettingsSectionType.POPUP;
    const repeatsPostfix = repeats > 1 ? 's' : '';
    errorDueDate = errorDueDate && endType === ERecurringTaskEndType.DATE ? errorDueDate :  null;

    const className = CLASS_CARD_SECTION_RECURRING + '__end-type';
    const classNameDisabled = isReadonly ? 'disabled' : '';
    const classNameLabel = className + '-label';
    const classNameDate = className + '-date';
    const classNameDateError = errorDueDate ? classNameDate + '--error' : '';
    const [errorKey, setErrorKey] = React.useState(uuidv4());
    const [repeatsKey, setRepeatsKey] = React.useState(uuidv4());

    React.useEffect(() => {
        setErrorKey(uuidv4());
    }, [errorDueDate]);

    const minDate = moment(startDate || new Date()).add(1, 'day').startOf('day').toDate();

    const onChangeHandler = (
        e: IRadioActiveProps
    ) => {
        const endType = END_TYPES[e.index];
        let dueDate = form.dueDate;
        if (endType === ERecurringTaskEndType.DATE && !dueDate) {
            dueDate = moment(startDate || new Date()).add(1, 'year').startOf('day').toDate();
        }
        updateForm({ endType, dueDate });
    }

    const getRepeats = (
        target: HTMLInputElement
    ): number => {
        if (!target.value) return null;
        return Number(target.value);
    }

    const onRepeatsChange = (
        event: React.SyntheticEvent
    ) => {
        updateFormState({ repeats: getRepeats(event.target as HTMLInputElement) });
    }

    const onRepeatsBlur = (
        event: React.FocusEvent
    ) => {
        const value = getRepeats(event.target as HTMLInputElement);
        if (value >= 1) {
            updateForm({ repeats: value });
        } else {
            updateForm({ repeats: 0 });
            setRepeatsKey(uuidv4());
        }
    }

    return (
        <>
            <span className={ 'kui-label__item' }>
                Ends
            </span>
            <Radio
                className={className}
                active={END_TYPES.indexOf(endType)}
                onChange={onChangeHandler}
            >
                <div className={classNameDisabled}><span className={classNameLabel}>{LABEL_NEVER}</span></div>
                <div className={`
                    ${classNameDate}
                    ${classNameDateError}
                    ${classNameDisabled}
                `}>
                    <div className={classNameLabel}>{LABEL_ON}</div>
                    <DatepickerHOC
                        selectedFromDate={dueDate}
                        onChange={onDueDateChange}
                        minDate={minDate}
                        isReadonly={isReadonly}
                        isCompact
                        isFromDisabled={endType !== ERecurringTaskEndType.DATE}
                        isSingle
                        portalId={isPopup ? ID_ASIDE_PANEL_DATEPICKER_PORTAL : null}
                        translate={GOOGLE_SPACING * (isPopup ? -16.5 : -15.5)}
                    />
                    <ErrorHint
                        direction={'up'}
                        key={errorKey}
                        portalSelector={`.${classNameDate} .kui-label`}
                        selector={`.${classNameDate} .kui-input__item`}
                        value={errorDueDate}
                    />
                </div>
                <div className={`
                    ${className}-repeats
                    ${classNameDisabled}
                `}>
                    <span className={classNameLabel}>{LABEL_AFTER}</span>
                    <Input
                        disabled={endType !== ERecurringTaskEndType.REPEATS}
                        onChange={onRepeatsChange}
                        onBlur={onRepeatsBlur}
                        value={String(repeats)}
                        autosize={false}
                        type={'number'}
                        key={repeatsKey}
                    />
                    {LABEL_OCCURRENCES}{repeatsPostfix}
                </div>
            </Radio>
        </>
    );
}
