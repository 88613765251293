import * as React from 'react';
import './_CopyLinkDialog.scss';
import { COPY_LINK_BODY, COPY_LINK_CLOSE, COPY_LINK_TITLE } from './constants';
import { Input } from 'kui';
import { ICopyLinkDialogProps } from './types';

export const CopyLinkDialog = ({
    onClose,
    onCopy,
}: ICopyLinkDialogProps) => {
    const className = 'affiliate-dialog';
    const headerClassName = className + '__header';
    const bodyClassName = className + '__body';
    const footerClassName = className + '__footer';

    const textareaRef = React.useRef(null);
    const [copied, setCopied] = React.useState(false);

    const link = window.location.origin + '/dashboard/google-spreadsheet-permission';

    const onTextareaClick = (
    ) => {
        textareaRef.current.getInput().select();
        if (!copied) {
            onCopy(link);
            setCopied(true);
        }
    }

    return (
        <div className={className}>
            <div className={headerClassName}>
                {COPY_LINK_TITLE}
            </div>
            <div className={bodyClassName}>
                {COPY_LINK_BODY}
                <Input
                    value={link}
                    ref={textareaRef}
                    onClick={onTextareaClick}
                />
            </div>
            <div className={footerClassName}>
                <p><span className={className + '__close'} onClick={onClose}>{COPY_LINK_CLOSE}</span></p>
            </div>
        </div>
    )
}
