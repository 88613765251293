import { IApplicationState } from '../../../../types/types';
import { getAuthUser } from './getAuthUser';
import { IUserMetaQualificationForm } from '../../../../types/rest/IUserMeta';

export const getUserQualificationFormMeta = (
    state: IApplicationState
): IUserMetaQualificationForm => {
    const user = getAuthUser(state);
    return user.meta && user.meta.qualificationForm;
};
