export enum ERestMethod {
    DELETE = 'DELETE',
    GET = 'GET',
    PATCH = 'PATCH',
    POST = 'POST',
    PUT = 'PUT',
}

export interface IPartActionResponse {
    cursor: string;
    complete: boolean
}
