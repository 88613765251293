import { getMessages } from '../../../../../../store/constants';

export const CLASS_CARD_AGING = 'board-details-card-aging';

export const DETAILS_SECTION_CARD_AGING_PROPERTIES = getMessages().getText('board_settings.card_aging.title');
export const BOARD_DETAILS_SECTION_CARD_AGING_PROPERTIES_TEXT_BEFORE = getMessages().getText('board_settings.card_aging.text.board.before');
export const BOARD_DETAILS_SECTION_CARD_AGING_PROPERTIES_TEXT_AFTER = getMessages().getText('board_settings.card_aging.text.board.after');
export const LIST_DETAILS_SECTION_CARD_AGING_PROPERTIES_TEXT_MAIN = getMessages().getText('board_settings.card_aging.text.list.main');
export const LIST_DETAILS_SECTION_CARD_AGING_PROPERTIES_TEXT_BEFORE = getMessages().getText('board_settings.card_aging.text.list.before');
export const LIST_DETAILS_SECTION_CARD_AGING_PROPERTIES_TEXT_LINK = getMessages().getText('board_settings.card_aging.text.list.link');
export const LIST_DETAILS_SECTION_CARD_AGING_PROPERTIES_TEXT_AFTER = getMessages().getText('board_settings.card_aging.text.list.after');
export const DETAILS_SECTION_CARD_AGING_PROPERTIES_BUTTON = getMessages().getText('board_settings.card_aging.button');
export const DETAILS_SECTION_CARD_AGING_PROPERTIES_OPACITY = getMessages().getText('board_settings.card_aging.settings.opacity');
export const DETAILS_SECTION_CARD_AGING_PROPERTIES_INDICATOR = getMessages().getText('board_settings.card_aging.settings.indicator');
export const DETAILS_SECTION_CARD_AGING_PROPERTIES_INDICATOR_ACTIVITY = getMessages().getText('board_settings.card_aging.settings.indicator.activity');
export const DETAILS_SECTION_CARD_AGING_PROPERTIES_INDICATOR_LIST = getMessages().getText('board_settings.card_aging.settings.indicator.list');
export const CARD_AGING_MODAL_CANCEL = getMessages().getText('common.cancel');
export const CARD_AGING_MODAL_REMOVE = getMessages().getText('common.turn_off');
export const CARD_AGING_MODAL_TITLE = getMessages().getText('board_settings.card_aging.modal.title');
export const CARD_AGING_MODAL_TEXT = getMessages().getText('board_settings.card_aging.modal.text');
