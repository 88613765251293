import {TBoardId} from '../../../../../../../types/types';
import {Dispatch, ThunkAction} from '../../../../../../../types/actions';
import {toggleBoardStarred} from '../../../../../aside_panel/boardDetails/details/StarredButton/hocs/BoardStarredButtonHOC/effects/toggleBoardStarred';
import {
    boardStarredClickedSegmentEvent,
    ESegmentBoardMenuLocation
} from '../../../../../../../middlewares/segment/trackEntities/boardMenuEvents';

export const onClickStarred = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        dispatch(toggleBoardStarred(boardId));
        dispatch(boardStarredClickedSegmentEvent(ESegmentBoardMenuLocation.navPanel));
    };
    return action;
};
