import { ESegmentTraitKey, IUserCommentSortTrait } from '../actions/segmentIdentifyAction/types';
import { EUserSettingsCommentSort } from '../../../types/rest/IRestAuthUser';
import { EUserSettingsCommentSortLabel } from '../../../abtesting/abTests/commentSorting/constants';
import { IApplicationState } from '../../../types/types';
import { getAuthUserSettingsCommentsSort } from '../../../store/model/authUser/selectors/getAuthUserSettingsCommentsSort';

export const userIdentifyCommentSortTraitSelector = (
    state: IApplicationState
): IUserCommentSortTrait => {
    const currentOrder = getAuthUserSettingsCommentsSort(state);
    const value = EUserSettingsCommentSort.DESC === currentOrder ?
        EUserSettingsCommentSortLabel.DESC :
        EUserSettingsCommentSortLabel.ASC;
    return {
        [ESegmentTraitKey.COMMENT_SORT]: value
    }
};
