import { IApplicationState } from '../../../../../types/types';
import { getAuthUser } from '../../../../../store/model/authUser/selectors/getAuthUser';
import { ONE_DAY_LENGTH } from '../../../aside_panel/filterPanelBoard/constants';
import { getIsAssignedToMeActive } from '../../../../../store/router/selectors/getIsAssignedToMeActive';
import { getIsLinkCardActive } from '../../../../../store/router/selectors/getIsLinkCardActive';
import { root } from '../../../../../store/constants';
import { isTrialUser } from '../../../../../store/model/authUser/selectors/isTrialUser';

export const getShowGanttContextHints = (
    state: IApplicationState
): boolean => {
    const { createDateTimeStamp, allowGantt } = getAuthUser(state);
    const isNewUser = (new Date().getTime() - createDateTimeStamp * 1000) < ONE_DAY_LENGTH; // меньше 1 дня
    return !getIsAssignedToMeActive(state) && // в ассайн ту ми нет тайп свитчера
        !getIsLinkCardActive(state) && // у карты без доски нет тайп свитчера
        root._innerWidth > 900 && // тайп свитчер должен быть виден
        isTrialUser(state) &&
        allowGantt &&
        isNewUser;
};
