import { TBoardBackupList } from '../types';
import { AT_LIST_UPDATE, IListUpdateAction } from './types';

export const updateListAction = (
    list: TBoardBackupList
): IListUpdateAction => {
    return {
        type: AT_LIST_UPDATE,
        list
    }
};
