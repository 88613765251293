import * as React from 'react';
import { ERestActivityPropertyActionType } from '../../../../../../../types/rest/activity/IRestActivityBase';
import { IActivityProps } from '../../../../boardDetails/activity/TabActivity/types';
import { TabActivityContext } from '../../../../boardDetails/activity/TabActivity/components/TabActivity/constants';
import {
    IRestCardCustomPropertyActivity
} from '../../../../../../../types/rest/activity/IRestCardCustomPropertyActivity';
import { ECustomPropertyType } from '../../../../../../../store/customProperties/types';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { getDateFormatted } from '../../../../../helpers/dateService';
import {
    ActivityItemBody
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemBody/ActivityItemBody';
import { ActivityHeader } from '../../../../boardDetails/activity/TabActivity/components/ActivityHeader/ActivityHeader';
import {
    ActivityItemName
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemName/ActivityItemName';

export function CardActivityCustomPropertyCommon({
    activity
}: IActivityProps<IRestCardCustomPropertyActivity>) {
    const { onOpenCard } = React.useContext(TabActivityContext);
    let value = activity.newValue;
    if (activity.propertyActionType === ERestActivityPropertyActionType.DELETE) {
        value = activity.oldValue;
    }
    if (activity.customProperty.type === ECustomPropertyType.DATE){
        const { isUserTimeFormat12 } = React.useContext(AsidePanelContext);
        value = getDateFormatted(Number(value)/1000, isUserTimeFormat12);
    }
    if (activity.customProperty.type === ECustomPropertyType.SELECT){
        let selectedOption = activity.customProperty.options.find( option => option.id === value)
        value = selectedOption.value;
    }
    const { label, card } = activity as any;

    return (
        <ActivityItemBody
            header={<ActivityHeader icon={'dot'} label={label.split(' ')[0]} />}
            activity={activity}
            onClick={() => onOpenCard(card.id, activity.id, 'custom')}
        >
            <ActivityItemName name={activity.customProperty.name} /><ActivityItemName name={value} />
        </ActivityItemBody>
    );
};
