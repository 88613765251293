import * as React from 'react';
import './_BoardTag.scss';
import { IBoardTagProps } from './types';
import { Button, Icon, Input } from 'kui';
import { CLASS_BOARD_DETAILS } from '../../../../constants';
import { getAsidePanelTooltip } from '../../../../../asidePanel/helpers/getAsidePanelTooltip';
import { BOARD_SECTION_TAG_DELETE } from '../../constants';

export function BoardTag ({
    tag,
    isReadonly,
    onChangeName,
    onDelete
}: IBoardTagProps) {
    const [tagName, setTagName] = React.useState(tag);
    const ref = React.useRef(null);

    const className = CLASS_BOARD_DETAILS + '__tag';
    const classHash = CLASS_BOARD_DETAILS + '__tag-hash';
    const classInput = CLASS_BOARD_DETAILS + '__tag-input';
    const classDelete = CLASS_BOARD_DETAILS + '__tag-delete';

    const handleInputBlur = (
        e: React.SyntheticEvent
    ) => {
        const { value } = e.target as HTMLInputElement;
        saveName(value);
    };
    const handleInputChange = (
        e: React.SyntheticEvent
    ) => {
        const { value } = e.target as HTMLInputElement;
        setTagName(value);
    };
    const handleKeyDown = (
        e: React.KeyboardEvent
    ) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const target = e.target as HTMLInputElement;
            saveName(target.value);
            if (ref.current) ref.current.focus();
        }
    };
    const saveName = (value: string) => {
        setTagName(value);
        if (value !== tag){
            onChangeName(value);
        }
    };

    React.useEffect(() => {
        setTagName(tag);
    }, [tag]);

    return (
        <div
            className={className}
            ref={ref}
            tabIndex={0}
        >
            <span className={classHash}>#</span>
            <Input
                autosize={false}
                className={classInput}
                readOnly={isReadonly}
                value={tagName}
                onBlur={handleInputBlur}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
            />
            {!isReadonly &&
                <Button
                    className={classDelete}
                    tooltip={getAsidePanelTooltip({
                        value: BOARD_SECTION_TAG_DELETE
                    })}
                    variant={'icon'}
                    onClick={onDelete}
                >
                    <Icon size={24} xlink={'delete'} />
                </Button>
            }
        </div>
    );
};
