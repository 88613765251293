import { IGetState } from '../../../../../../../types/types';
import {
    SegmentCardColorTagAddSourceValue,
    SegmentCardOptions
} from '../../../../../../../middlewares/segment/trackEntities/cardEvents';
import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { segmentTrackAction } from '../../../../../../../middlewares/segment';
import { TSegmentTrackPayload } from '../../../../../../../middlewares/segment/trackEntities/types';

export const trackChangeColorTags = (
    track: TSegmentTrackPayload,
    source: SegmentCardColorTagAddSourceValue,
    count: number
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(track, {
            name: SegmentCardOptions.SOURCE,
            value: source
        }, [{
            name: SegmentCardOptions.COUNT,
            value: count
        }]));
    };
    return action;
};
