import { IApplicationState, TBoardId, TCardId, TListId } from '../../../types/types';
import { getCard } from './getCard';
import { getList } from '../list/selectors/getList';

export const getBoardIdByListId = (
    state: IApplicationState,
    listId: TListId
): TBoardId => {
    const list = getList(state, listId);
    if (!list) return null;

    return list.dashboardId;
};
