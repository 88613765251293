import { getMessages } from '../../../../../../store/constants';

export const COOKIE_POPUP_TITLE = getMessages().getText('cookie.popup.title');
export const COOKIE_POPUP_TEXT = getMessages().getText('cookie.popup.content');
export const COOKIE_POPUP_BUTTON_AGREE = getMessages().getText('cookie.popup.button.agree');
export const COOKIE_POPUP_BUTTON_SHOW_SETTINGS = getMessages().getText('cookie.popup.button.show_settings');
export const COOKIE_POPUP_SETTINGS_TITLE = getMessages().getText('cookie.popup.settings.title');
export const COOKIE_POPUP_SETTINGS_NECESSARY_TITLE = getMessages().getText('cookie.popup.settings.necessary.title');
export const COOKIE_POPUP_SETTINGS_NECESSARY_TEXT = getMessages().getText('cookie.popup.settings.necessary.text');
export const COOKIE_POPUP_SETTINGS_PERFORMANCE_TITLE = getMessages().getText('cookie.popup.settings.performance.title');
export const COOKIE_POPUP_SETTINGS_PERFORMANCE_TEXT = getMessages().getText('cookie.popup.settings.performance.text');
