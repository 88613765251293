import * as React from 'react';
import { Checkbox, SelectListItem, Tooltip } from 'kui';
import { ICardColorTagsItemProps } from './types';
import { v4 as uuidv4 } from 'uuid';
import { SegmentCardColorTagAddSourceValue } from 'app/middlewares/segment/trackEntities/cardEvents';
import { useCombinedRefs } from '../../../../../helpers/useCombinedRefs';
import { markdown } from '../../../../../../../helper/markdownHelper';
import './_CardColorTagItem.scss';
import { CLASS_CARD_DETAILS } from '../../../constants';
import ReactHtmlParser from 'react-html-parser';
import { isTextColorLight } from '../../../../../helpers/getTextColor';
import { OLD_AND_NEW_COLORS } from '../../../../../../../store/model/board/selectors/getBoardColors';

export const CardColorTagItem =
React.forwardRef(({
    className,
    isChecked,
    isDisabled,
    item,
    onChange,
    onSelect
}: ICardColorTagsItemProps, ref) => {
    const _itemRef = React.useRef(null);
    const itemRef =  useCombinedRefs(ref, _itemRef);
    const timerRef = React.useRef(null);

    const classNameItem = CLASS_CARD_DETAILS + '__color-tags-item';
    const [key, setKey] = React.useState(uuidv4());
    const [name, setName] = React.useState(markdown(item.name));

    const onClick = (e: React.MouseEvent) => {
        e.stopPropagation(); // to not close dropdown on dbl click
        e.preventDefault(); // for checkbox not to trigger

        function handleChange() {
            let source = SegmentCardColorTagAddSourceValue.NOT_CHECKBOX;
            const { layerX } = e.nativeEvent as any;
            if (layerX) { // клик на чекбокс не определить, т.к. он :before, поэтому посмотрим координаты клика
                const rect = itemRef.current.getBoundingClientRect();
                if (
                    rect.width &&
                    layerX > rect.width - 40 // близко к чекбоксу
                ) {
                    source = SegmentCardColorTagAddSourceValue.CHECKBOX;
                }
            }
            onChange(item.id, source);
            setKey(uuidv4());
        }

        if (timerRef.current) clearTimeout(timerRef.current);
        timerRef.current = setTimeout(handleChange, 300);
    };

    const onEdit = () => {
        if (timerRef.current) clearTimeout(timerRef.current);
        onSelect(item);
    };

    const onTouchStart = () => {
        if (timerRef.current) clearTimeout(timerRef.current);
        timerRef.current = setTimeout(onEdit ,500);
    };

    const onTouchEnd = () => {
        if (timerRef.current) clearTimeout(timerRef.current);
    };

    React.useEffect(() => {
        setName(markdown(item.name));
    }, [item]);

    const isCustom = !OLD_AND_NEW_COLORS.find((color: any) => item.color === color || (color.color && item.color === color.color));

    return (
        <Tooltip
           /* delay={TOOLTIP_LONG}*/
            isNoWrap={true}
            value={name}
        >
            <div
                ref={itemRef}
                onClick={onClick}
                onDoubleClick={onEdit}
                onTouchStart={onTouchStart}
                onTouchEnd={onTouchEnd}
                className={isDisabled ? `${classNameItem}--disabled` : ''}
            >
                <Checkbox
                    className={`
                        ${className}
                        ${classNameItem}
                        ${classNameItem}--${item.color}
                        ${classNameItem}--${item.id}
                        ${isCustom ? classNameItem + (isTextColorLight(item.color) ? '--dark' : '--light') : ''}
                    `}
                    checked={isChecked}
                    labelStyle={isCustom ? { background: item.color } : null}
                    color={'light'}
                    disabled={isDisabled}
                    onChange={null}
                    key={key}
                >
                    <SelectListItem
                        value={String(item.id)}
                        className={isDisabled ? 'disabled' : ''}
                    >
                        <span>{ReactHtmlParser(name)}</span>
                    </SelectListItem>
                </Checkbox>
            </div>
        </Tooltip>
    );
});

CardColorTagItem.displayName = 'CardColorTagItem';
