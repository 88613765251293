import { IGetState, TBoardId } from '../../../types/types';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { root } from '../../constants';
import { getBoard } from '../selectors/getBoardById';

export const ensureBoardIsFullLoaded = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const board = getBoard(getState(), boardId);
        if (!board || !board.id || board.noCards) {
            return root.App.controller.loadBoardForDashboardToStore(boardId);
        }
        return Promise.resolve();
    }
    return action;
}
