import { IAssigneeRolesSelectProps } from './types';
import * as React from 'react';
import './_AssigneeRolesSelect.scss';
import { Button, ButtonDropdown, Checkbox, Divider, Icon, SelectList, SelectListItem } from 'kui';
import { CLASS_CARD_DETAILS } from '../../../constants';
import { MAX_USER_ROLES } from '../../../../../../../const';
import { getAsidePanelTooltip } from '../../../../asidePanel/helpers/getAsidePanelTooltip';
import { AssigneeRoles } from '../AssigneeRoles/AssigneeRoles';
import { ASSIGNEE_ADD_ROLE_BUTTON_TOOLTIP, ASSIGNEE_EDIT_ROLES, CLASS_CARD_ASSIGNEES } from '../constants';
import { v4 as uuidv4 } from 'uuid';

export function AssigneeRolesSelect ({
    isReadonly,
    roles,
    onToggle,
    onEdit,
    ...attributes
}: IAssigneeRolesSelectProps) {
    const classRolesButton = CLASS_CARD_ASSIGNEES + '__roles-button';
    const [classUnique] = React.useState(classRolesButton + '--' + uuidv4());
    const classRolesButtonDropdown = CLASS_CARD_ASSIGNEES + '__roles-button-dropdown';
    const classRolesDropdown = CLASS_CARD_ASSIGNEES + '__roles-dropdown';
    const className = CLASS_CARD_DETAILS + '__assignees-roles-select';
    const classItem = className + '-item';
    const classNameActions = classRolesDropdown + '-actions';

    const checked = roles.reduce((checked, role) => {
        return checked + Number(role.isChecked);
    }, 0);
    const isDisabled = checked >= MAX_USER_ROLES;
    const classRolesButtonEmpty = !checked ? classRolesButton + '--empty' : '';

    return (
        <ButtonDropdown
            className={classRolesButtonDropdown}
            dropdownClassName={classRolesDropdown}
            directionHorizontal={'right'}
            multiple={true}
            onOpen={() => {
                const button = document.querySelector('.' + classUnique) as HTMLElement;
                if (button) button.focus();
            }}
            {...attributes}
        >
            <Button
                className={`
                    ${classRolesButton}
                    ${classRolesButtonEmpty}
                    ${classUnique}
                `}
                disabled={isReadonly}
                tooltip={checked ? null : getAsidePanelTooltip({
                    isNoWrap: true,
                    value: ASSIGNEE_ADD_ROLE_BUTTON_TOOLTIP
                })}
                variant={'icon'}
            >
                {checked
                    ? <AssigneeRoles
                        roles={roles.filter(role => role.isChecked)}
                    />
                    : <Icon size={24} xlink={'user'}/>
                }
            </Button>
            <SelectList
                fixActive={false}
            >
                {roles.map((role) => {
                    return (
                        <Checkbox
                            className={`
                            ${isDisabled && !role.isChecked ? 'disabled' : ''}
                            ${classItem}
                            ${classItem}--${role.color}
                        `}
                            checked={role.isChecked}
                            color={'light'}
                            onChange={() => onToggle(role.id)}
                            key={role.id}
                            disabled={isDisabled && !role.isChecked}
                        >
                            <SelectListItem
                                value={String(role.id)}
                                icon={'user'}
                                iconSize={24}
                            >
                                {role.name}
                            </SelectListItem>
                        </Checkbox>
                    );
                })}
            </SelectList>
            <div className={classNameActions}>
                <Divider />
                <Button
                    text={ASSIGNEE_EDIT_ROLES}
                    variant={'icon-text'}
                    onClick={onEdit}
                >
                    <Icon
                        size={24}
                        xlink={'pen'}
                    />
                </Button>
            </div>
        </ButtonDropdown>
    );
}
