import { IApplicationState, TCardId } from '../../../types/types';
import { ORDER_STEP } from '../../../const';
import { getCardSubcards } from './getCardSubcards';

export const getNextSubCardOrderNumber = (
    state: IApplicationState,
    cardId: TCardId
): number => {
    const subcards = getCardSubcards(state, cardId);
    if (!subcards.length) return 0;
    const subCardOrderNumbers = subcards.map(card => card.subCardOrderNumber || 0);
    const nextSubCardOrderNumber = Math.max(...subCardOrderNumbers) + ORDER_STEP;
    return nextSubCardOrderNumber;
}
