import { IApplicationState } from '../../../../../types/types';
import { connect } from 'react-redux';
import { IHintDispatchProps, IHintStateProps } from '../../components/Hint/types';
import { Hint } from '../../components/Hint/Hint';
import { IHintHOCOwnProps } from './types';
import { onHide } from './events/onHide';
import { onShow } from './events/onShow';
import { getActiveHint } from '../../store/hints/selectors/getActiveHint';
import { onTutorialNext } from './events/onTutorialNext';
import { onTutorialPrevious } from './events/onTutorialPrevious';
import { onTutorialSkip } from './events/onTutorialSkip';
import { getHintConfig } from '../../constants';

const mapStateToProps = (
    state: IApplicationState,
    ownProps: IHintHOCOwnProps
): IHintStateProps => {
    const { hintKey, show, tutorialStep } = ownProps;
    let mount = false;
    let isFirstStep = false;
    let isLastStep = false;

    if (show) {
        const activeHint = getActiveHint(state);
        if (
            activeHint !== null &&
            activeHint.key === hintKey
        ) {
            if (tutorialStep) {
                mount = activeHint.step === tutorialStep;
                if (mount) {
                    if (tutorialStep === 0) {
                        isFirstStep = true;
                    } else {
                        const hintConfig = getHintConfig(hintKey);
                        if (
                            hintConfig &&
                            hintConfig.tutorial &&
                            tutorialStep >= hintConfig.tutorial.length - 1
                        ) {
                            isLastStep = true;
                        }
                    }
                }
            } else {
                mount = true;
            }
        }
    }
    return {
        ...ownProps,
        isFirstStep,
        isLastStep,
        mount
    }
};

const mapDispatchToProps = (
    dispatch: any,
    ownProps: IHintHOCOwnProps
): IHintDispatchProps => {
    const {
        hintKey,
        tutorialStep,
        onHide: ownOnHide,
        onShow: ownOnShow,
        onTutorialNext: ownOnTutorialNext,
        onTutorialPrevious: ownOnTutorialPrevious,
        onTutorialSkip: ownOnTutorialSkip,
    } = ownProps;

    const tutorialEvents = tutorialStep === undefined ? {} : {
        onTutorialNext: () => dispatch(onTutorialNext(hintKey, ownOnTutorialNext)),
        onTutorialPrevious: () => dispatch(onTutorialPrevious(hintKey, ownOnTutorialPrevious)),
        onTutorialSkip: () => dispatch(onTutorialSkip(hintKey, ownOnTutorialSkip)),
    };

    return {
        onHide: () => dispatch(onHide(hintKey, ownOnHide, tutorialStep)),
        onShow: () => dispatch(onShow(hintKey, ownOnShow)),
        ...tutorialEvents
    }
};

export const HintHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(Hint);

HintHOC.displayName = 'HintHOC';
