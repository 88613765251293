import * as React from 'react';
import './_CommentAttachment.scss';
import { ICommentAttachmentProps } from './types';
import { Button, Icon, LoaderBlock, Tooltip, TTooltipDirection } from 'kui';
import { THUMB_CARD_SIZE, THUMB_COMMENT_SIZE } from 'app/const';
import { clearMarkdownInline } from 'app/view/react_components/base/helpers/clearMarkdownHelper';
import { escape } from 'underscore';
import { EAuthUserPlatformType } from 'app/types/rest/IRestAuthUser';
import { Util } from 'app/util/util';
import { CLASS_COMMENT } from '../../constants';
import { getBigPreviewIcon } from '../../../AttachmentsSection/hocs/CardAttachmentsSectionHOC/helpers/getBigPreviewIcon';
import ReactHtmlParser from 'react-html-parser';
import { root } from 'app/store/constants';
import { getParentsClasses } from '../../../../../helpers/getParentsClasses';
import { msFileThumbnailStorage } from 'app/helper/authorisation/microsoft/storage/msThumbnail';
import { getAsidePanelTooltip } from 'app/view/react_components/aside_panel/asidePanel/helpers/getAsidePanelTooltip';
import {
    EGoogleFileThumbnailMode,
    googleFileThumbnailStorage
} from 'app/helper/authorisation/google/storage/googleThumbnail';
import { COMMENT_ATTACHMENT_FS_GOOGLE_THUMB_SIZE, COMMENT_ATTACHMENT_GOOGLE_THUMB_SIZE } from './constants';

export function CommentAttachment({
    attachment,
    isActions,
    isFullScreen,
    onClick,
    onDelete,
    onReply
}: ICommentAttachmentProps) {
    const { id, mimeType, url, title, iconUrl } = attachment;

    const className = CLASS_COMMENT + '-attachment';
    const classButton = className + '__button';
    const classIcon = className + '__icon';

    const [thumbIsLoaded, setThumbIsLoaded] = React.useState(false);
    const [attachmentThumbUrl, setAttachmentThumbUrl] = React.useState('');
    const [direction, setDirection] = React.useState<TTooltipDirection>('up-right');

    const onHover = (e: React.MouseEvent) => {
        const target = e.currentTarget as HTMLElement;
        if (target) {
            const rect = target.getBoundingClientRect();
            if (rect.top > root._innerHeight * 0.5) {
                setDirection('up-right');
            } else {
                setDirection('down-right');
            }
        }
    };

    const onLinkClick = (e: React.SyntheticEvent) => {
        const parentClasses = getParentsClasses(e.target as HTMLElement, [
            classButton,
        ]);

        if (parentClasses.includes(classButton)) return;

        if (onClick) onClick(e, id);
    };

    const onError = () => {
        if (googleFileThumbnailStorage.is()) {
            googleFileThumbnailStorage.reGetThumbnail(
                attachment.fileId,
                isFullScreen ? COMMENT_ATTACHMENT_FS_GOOGLE_THUMB_SIZE : COMMENT_ATTACHMENT_GOOGLE_THUMB_SIZE,
                setAttachmentThumbUrl,
                () => setThumbIsLoaded(null),
            );
        }
    };

    const linkClick = onClick
        ? {
            href: Util.addAuthUserToUrl(url),
            target: '_blank',
        }
        : null;

    const isImage = mimeType.includes('image/');

    React.useEffect(() => {
        if (attachment.platformType === EAuthUserPlatformType.MICROSOFT ){
            if (msFileThumbnailStorage.is()) {
                const thumbSize = isFullScreen ? `c${THUMB_COMMENT_SIZE}x${THUMB_COMMENT_SIZE / 2}` : `c${THUMB_CARD_SIZE}x${THUMB_CARD_SIZE}`;
                msFileThumbnailStorage.getThumbnail(attachment.driveId, attachment.fileId, thumbSize)
                    .then(thumb => setAttachmentThumbUrl(thumb.url));
            }
        } else if (googleFileThumbnailStorage.is()) {
            googleFileThumbnailStorage.getThumbnail(attachment.fileId,isFullScreen ? COMMENT_ATTACHMENT_FS_GOOGLE_THUMB_SIZE : COMMENT_ATTACHMENT_GOOGLE_THUMB_SIZE)
                .then((thumb) => {
                    setAttachmentThumbUrl(thumb);
                });
        }
    }, [attachment]);

    const Keyhole = require('!!@svgr/webpack!./keyhole.svg').default;

    return (
        <>
            {isFullScreen ?
                <div
                    className={`
                        ${className}
                        ${className + '--fullscreen'}
                        ${!thumbIsLoaded ? className + '--loading' : ''}
                        ${thumbIsLoaded === null ? className + '--error' : ''}
                    `}
                >
                    <Tooltip
                        isNoWrap={true}
                        value={clearMarkdownInline(escape(title))}
                    >
                        {!thumbIsLoaded && <>
                            {thumbIsLoaded === null ? <Keyhole className={className + '__keyhole'} /> : <LoaderBlock/>}
                        </>}
                        <a
                            {...linkClick}
                            onClick={onLinkClick}
                        >
                            <img
                                src={attachmentThumbUrl}
                                onLoad={() => setThumbIsLoaded(true)}
                                onError={onError}
                            />
                        </a>
                    </Tooltip>
                </div>
                : <Tooltip
                    {...getAsidePanelTooltip(null)}
                    isNoWrap={true}
                    direction={direction}
                    className={className + '__tooltip'}
                    footer={isImage && attachmentThumbUrl
                        ? <>
                            {!thumbIsLoaded && <LoaderBlock />}
                            <img
                                src={ attachmentThumbUrl }
                                className={className + '__tooltip-image'}
                                onLoad={() => setThumbIsLoaded(true)}
                                onError={onError}
                            />
                        </>
                        : null
                    }
                    value={clearMarkdownInline(escape(title))}
                >
                    <a
                        className={`${className} ${isActions ? className + '--actions' : ''}`}
                        tabIndex={isActions ? -1 : null}
                        {...linkClick}
                        onMouseOver={onHover}
                        onClick={onLinkClick}
                    >
                        <img
                            className={`
                                ${classIcon}
                                ${isImage ? classIcon + '--image' : ''}
                            `}
                            src={isImage && attachmentThumbUrl ? attachmentThumbUrl : getBigPreviewIcon(iconUrl)}
                        />
                        <div className={className + '__name'}>{ReactHtmlParser(clearMarkdownInline(escape(title)))}</div>
                        {isActions &&
                            <>
                                {onReply ?
                                    <Button
                                        className={classButton}
                                        variant={'icon'}
                                        onClick={e => {
                                            e.preventDefault();
                                            onReply(attachment.id)
                                        }}
                                    >
                                        <Icon xlink={'reply'} size={24} />
                                    </Button>
                                    : <Button
                                        className={classButton}
                                        variant={'icon'}
                                        onClick={e => {
                                            e.preventDefault();
                                            onDelete(attachment.id, attachment.fileId)
                                        }}
                                    >
                                        <Icon xlink={'clear'} size={24} />
                                    </Button>
                                }
                            </>
                        }
                    </a>
                </Tooltip>
            }
        </>
    );
}
