import { ISnackbarModel } from '../../store/types';
import { Dispatch } from '../../../../../types/actions';
import { deleteSnackbar } from '../deleteSnackbar/deleteSnackbar';
import { addUpdateSnackbar } from '../../store/actions/addUpdateSnackbar';
import { snackbarTopSelector } from '../../selectors/snackbarTopSelector';
import { getAppState } from '../../../../../store/configureStore';
import { SNACKBAR_VARIANTS_MINOR } from '../constants';
import { suspendSnackbarsStack } from '../suspendSnackbarsStack/suspendSnackbarsStack';
import { snackbarSelector } from '../../selectors/snackbarSelector';

export const addUpdateSnackbarWithBubble = (
    snackbarModel: ISnackbarModel
) => {
    return (dispatch: Dispatch) => {
        const topSnackbar = snackbarTopSelector(getAppState());
        if (topSnackbar && SNACKBAR_VARIANTS_MINOR.includes(topSnackbar.variant)) {
            dispatch(deleteSnackbar(topSnackbar.id));
        }
        const currentSnackbar = snackbarSelector(getAppState(), snackbarModel.id);
        if (currentSnackbar && currentSnackbar.onHide) {
            currentSnackbar.onHide();
        }
        dispatch(deleteSnackbar(snackbarModel.id));
        dispatch(suspendSnackbarsStack())
        .then(() => dispatch(addUpdateSnackbar(snackbarModel)));
    }
};
