import { TBoardPermissionId, TChecklistId, TPermissionId } from '../../../../../../../../types/types';
import { getBoardUsers } from '../../../../../../../../store/model/selectors/getBoardUsers';
import { IApplicationState, TBoardId, TCardId } from '../../../../../../../../types/types';
import { getAuthUser } from '../../../../../../../../store/model/authUser/selectors/getAuthUser';
import {
    ASSIGNEE_GROUP_DIVIDER,
    ASSIGNEE_GROUP_TITLE,
    TITLE_ASSIGNED_ON_CARD,
    TITLE_ASSIGNED_OTHER
} from '../../../../AssigneesSection/components/constants';
import {
    getChecklistItems
} from '../../../../../../../../store/model/checklists/checklist/selectors/getChecklistItems';
import { createSelector } from 'reselect';
import { IChecklistItems } from '../../../../../../../../store/model/checklists/checklist/types';
import { IAssignee } from '../../../../../../../../store/model/card/types/IAssignee';
import { ISharedUser } from '../../../../../../../../types/rest/ISharedUser';
import { getCardAssignees } from '../../../../../../../../store/model/selectors/getCardAssignees';
import { IChecklistAssigneesSearchSelectOption } from '../types';

type TgetChecklistAssigneesSearchSelectOptions = (
    state: IApplicationState,
    boardId: TBoardId,
    cardId: TCardId,
    checklistId: TChecklistId,
) => IChecklistAssigneesSearchSelectOption[];

const getBoardUsersSelector = (
    boardUsers: ISharedUser[],
    authUserPermissionId: TPermissionId,
): ISharedUser[] => {
    if (!boardUsers.length) return [];

    const authUser = boardUsers.find((user) => user.permissionId === authUserPermissionId);
    const assignees = boardUsers
        .filter((user) => user.permissionId !== authUserPermissionId)
        .sort((userA, userB) => {
            const getUserName = (user: ISharedUser) => user.fullName ? user.fullName : '';
            return getUserName(userA).localeCompare(getUserName(userB))
        })
    ;
    if (authUser) {
        assignees.unshift(authUser); // сделать себя первым в списке
    }

    return assignees;
};

const getAuthUserIdSelector = (
    state: IApplicationState,
): TPermissionId => {
    const { permissionId } = getAuthUser(state);
    return permissionId;
};

/**
 * Юзеры доски + юзеры карты, удалённые с доски
 */
 const getBoardUsersWithCardAssignees = (
    state: IApplicationState,
    boardId: TBoardId,
    cardId: TCardId
): ISharedUser[] => {
    const boardUsers = getBoardUsers(state, boardId);
    const cardAssignees = getCardAssignees(state, cardId).map((assignee) => assignee.sharedUser);
    const ids: Set<TPermissionId> = new Set(boardUsers.map(user => user.permissionId));

    return cardAssignees.length
        ? cardAssignees.reduce((users, user) => { // сложить юзеров доски и карты, удалить дубликаты
            if (!ids.has(user.permissionId)) {
                users.push(user);
                ids.add(user.permissionId);
            }
            return users;
        }, [...boardUsers])
        : boardUsers; // если нет юзеров карты, просто отдать юзеров доски
};

const getCardAssigneeIdsSelector = (
    assignees: IAssignee[],
): Set<TBoardPermissionId> => {
    const assigneeIdsSet = new Set<TBoardPermissionId>();
    assignees.forEach(assignee => assigneeIdsSet.add(assignee.sharedUser.permissionId));
    return assigneeIdsSet;
};

/**
 * Юзеры, которые есть в каждом айтеме
 */
const getChecklistAssigneeIdsSelector = (
    checklistItems: IChecklistItems
): Set<TBoardPermissionId> => {
    let assigneeIdsSet: Set<TBoardPermissionId>;
    for (let itemId in checklistItems) {
        if (checklistItems[itemId] && checklistItems[itemId].assignees && checklistItems[itemId].assignees.length) {
            if (!assigneeIdsSet) { // из первого айтема берём всех юзеров
                assigneeIdsSet = new Set<TBoardPermissionId>();
                checklistItems[itemId].assignees.forEach(assignee => assigneeIdsSet.add(assignee.sharedUser.permissionId));
            } else { // удалить из assigneeIdsSet юзеров, которых нет в айтеме
                const itemAssigneeIdsSet = new Set<TBoardPermissionId>();
                checklistItems[itemId].assignees.forEach(assignee => itemAssigneeIdsSet.add(assignee.sharedUser.permissionId));
                for (let assigneeId of assigneeIdsSet) {
                    if (!itemAssigneeIdsSet.has(assigneeId)) assigneeIdsSet.delete(assigneeId);
                }
            }
        } else { // в каком-то айтеме нет юзеров -> нет юзеров, которые есть в каждом айтеме
            return null;
        }
    }
    return assigneeIdsSet;
};

const getChecklistAssigneesSearchSelectOptionsSelector = (
    authUserPermissionId: TPermissionId,
    users: ISharedUser[],
    cardAssignees: IAssignee[],
    checklistItems: IChecklistItems,
): IChecklistAssigneesSearchSelectOption[] => {
    const boardUsers = getBoardUsersSelector(users, authUserPermissionId);

    const cardAssigneesSet = getCardAssigneeIdsSelector(cardAssignees);
    const checklistAssigneesSet = getChecklistAssigneeIdsSelector(checklistItems);

    const options = boardUsers.map(user => ({
        active: !!checklistAssigneesSet && checklistAssigneesSet.has(user.permissionId),
        img: user.photoUrl,
        text: user.fullName,
        value: user.permissionId
    }));
    if ((cardAssigneesSet.size === 0) || (cardAssigneesSet.size === options.length)) {
        return options;
    }
    const filteredOptions: IChecklistAssigneesSearchSelectOption[] = [];
    const assigned = options.filter(option => cardAssigneesSet.has(option.value));
    const notAssigned = options.filter(option => !cardAssigneesSet.has(option.value));
    if (assigned.length) {
        filteredOptions.push({
            text: TITLE_ASSIGNED_ON_CARD,
            value: ASSIGNEE_GROUP_TITLE,
            options: assigned
        })
    }
    if (notAssigned.length) {
        filteredOptions.push({
            text: TITLE_ASSIGNED_OTHER,
            value: ASSIGNEE_GROUP_DIVIDER,
            options: notAssigned
        })
    }
    return filteredOptions;
};

export const getChecklistAssigneesSearchSelectOptionsCreateSelector = (
): TgetChecklistAssigneesSearchSelectOptions => createSelector(
    getAuthUserIdSelector,
    getBoardUsersWithCardAssignees,
    (state: IApplicationState, boardId: TBoardId, cardId: TCardId) => getCardAssignees(state, cardId),
    (state: IApplicationState, boardId: TBoardId, cardId: TCardId, checklistId: TChecklistId) => getChecklistItems(state, cardId, checklistId),
    getChecklistAssigneesSearchSelectOptionsSelector,
);
