import { INotificationBeforeDueDateAddAction } from './types';
import { AT_NOTIFICATION_BEFORE_DUEDATE_ADD } from './constants';
import { INotificationBeforeDueDate } from '../../notificationBeforeDueDate/types';

export const notificationBeforeDueDateAddAction = (
    notificationBeforeDueDate: INotificationBeforeDueDate
): INotificationBeforeDueDateAddAction => {
    return {
        type: AT_NOTIFICATION_BEFORE_DUEDATE_ADD,
        notificationBeforeDueDate
    }
};
