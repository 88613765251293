import { connect } from 'react-redux';
import { MoveToButton } from '../../components/MoveToButton/MoveToButton';
import { IApplicationState } from '../../../../../../../types/types';
import { IMoveToButtonEvents, IMoveToButtonFields } from '../../components/MoveToButton/types';
import { IMoveToButtonHOCProps } from './types';
import { onMoveTo } from './events/onMoveTo';
import { onLoadItems } from './events/onLoadItems';
import { getBoardPermissions } from '../../../../../../../store/model/selectors/getBoardPermissions';
import { getAuthUser } from '../../../../../../../store/model/authUser/selectors/getAuthUser';
import { TABS } from '../../../../../dialogs/openBoards/store/constants';
import { TTabKey } from '../../../../../dialogs/openBoards/store/types';
import { CommonAuthManager } from 'app/helper/authorisation/common/CommonAuthManager';
import { getAuthUserPlatformType } from 'app/store/model/authUser/selectors/getAuthUserPlatformType';

const mapStateToProps = (
    state: IApplicationState,
    { boardId }: IMoveToButtonHOCProps,
): IMoveToButtonFields => {
    const boardPermissions = getBoardPermissions(state, boardId);
    const platformType = getAuthUserPlatformType(state);

    let planBlocker = null;
    let scopesBlocker = null;
    const tab = TABS[TTabKey.sharedDrives];
    const allow = tab.allow;
    if (allow) {
        const user = getAuthUser(state);
        if (
            user &&
            !user[allow] &&
            tab.showPlanBlocker
        ) {
            planBlocker = tab.showPlanBlocker;
        }
    }

    if (!planBlocker && tab.scopes) {
        const scopes = tab.scopes[platformType];
        if (scopes &&
            !CommonAuthManager.checkOneOfScopes(scopes.success)
        ) {
            scopesBlocker = scopes.emptyDummyId;
        }
    }

    return {
        isShow: boardPermissions && boardPermissions.authPermissions && boardPermissions.authPermissions.allowMoveThisBoard,
        planBlocker,
        scopesBlocker
    }
};

const mapDispatchToProps = (
    dispatch: any,
    { boardId }: IMoveToButtonHOCProps
): IMoveToButtonEvents => ({
    onMoveTo: (driveId) => dispatch(onMoveTo(boardId, driveId)),
    onLoadItems: () => dispatch(onLoadItems(boardId))
});

export const MoveToButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(MoveToButton);

MoveToButtonHOC.displayName = 'MoveToButtonHOC';
