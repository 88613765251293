import * as React from 'react';
import { IRestCardCreateActivity } from '../../../../../../../types/rest/activity/IRestCardCreateActivity';
import { IActivityProps } from '../../../../boardDetails/activity/TabActivity/types';
import { TabActivityContext } from '../../../../boardDetails/activity/TabActivity/components/TabActivity/constants';
import { TActivity } from '../../../../../../../store/activities/types';
import {
    ActivityItemBody
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemBody/ActivityItemBody';
import { ActivityHeader } from '../../../../boardDetails/activity/TabActivity/components/ActivityHeader/ActivityHeader';
import {
    ActivityItemName
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemName/ActivityItemName';
import { ActivityColorTags } from '../ActivityColorTags/ActivityColorTags';
import { ActivityTags } from '../ActivityTags/ActivityTags';
import { UsersTooltip } from '../../../../../base/components/UsersTooltip/UsersTooltip';
import { NO_PRIORITY, PRIORITY_ICONS } from '../../../../../helpers/constants';
import { Icon } from 'kui';

export function CardActivityCreate ({
    activity,
    asidePanelWidth
}: IActivityProps<IRestCardCreateActivity>) {
    const { onOpenCard } = React.useContext(TabActivityContext);
    const { label, card } = activity as TActivity;
    return (
        <ActivityItemBody
            header={<ActivityHeader icon={'card'} label={label} />}
            activity={activity}
            onClick={() => onOpenCard(card.id, activity.id)}
        >
            <ActivityItemName name={card.name} />
            {activity.users && !!activity.users.length && <UsersTooltip
                users={activity.users.map(user => {
                    return { sharedUser: user };
                })}
                isNoEvents
            />}
            {activity.colors && !!activity.colors.length &&
                <ActivityColorTags colors={activity.colors} />}
            {activity.tags && !!activity.tags.length &&
                <ActivityTags tags={activity.tags} asidePanelWidth={asidePanelWidth} />}
            {activity.priority && activity.priority !== NO_PRIORITY && <Icon
                xlink={PRIORITY_ICONS.find(item => item.priority === String(activity.priority)).icon}
                size={24}
            />}
        </ActivityItemBody>
    );
}
