import {IApplicationState, TBoardId} from '../../../../../types/types';
import { VIEW_TYPES_KANBAN_OTHER } from '../../../../../const';
import { getRealTypeSwitcherActiveType } from './getRealTypeSwitcherActiveType';

export const isViewTypeWithBackground = (
    state: IApplicationState,
    boardId: TBoardId,
): boolean => {
    const viewType = getRealTypeSwitcherActiveType(state, boardId);

    return VIEW_TYPES_KANBAN_OTHER.includes(viewType);
};
