import { Dispatch, ThunkAction } from '../../../types/actions';
import { IGetState, TBoardId } from '../../../types/types';
import { fetchHandler } from '../../../util/fetchHandler';
import { IRestAutoBackup } from '../types';
import { REST_BOARD } from '../../constants';
import { Util } from '../../../util/util';
import { getRestHeadersGet } from '../../helpers/getRestHeadersHelper';
import {EBackupExportType} from '../../../types/rest/IRestBackup';

export const getRest = (
    boardId: TBoardId,
    exportType: EBackupExportType
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return fetchHandler<IRestAutoBackup>(
            Util.getApiUrl(REST_BOARD + boardId + '/autobackups?exportType=' + exportType),
            getRestHeadersGet()
        ).then(response => {
            return response
        });
    };
    return action;
};
