import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { IGetState, TBoardId } from '../../../../../types/types';
import { getMyWorkSelectedBoards } from '../selectors/getMyWorkSelectedBoards';
import { getMyWorkUnselectedBoards } from '../selectors/getMyWorkUnselectedBoards';
import { updateMyWorkMeta } from '../../../../../rest/effects/authUser/myWork/updateMyWorkMeta';

export const updateMyWorkSelectedBoards = (
    boardId: TBoardId,
    isShow: boolean
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        let selectedBoards;
        let unselectedBoards;
        if (isShow) {
            selectedBoards = [
                ...getMyWorkSelectedBoards(state),
                boardId
            ];
            unselectedBoards = getMyWorkUnselectedBoards(state).filter(id => id !== boardId);
        } else {
            unselectedBoards = [
                ...getMyWorkUnselectedBoards(state),
                boardId
            ];
            selectedBoards = getMyWorkSelectedBoards(state).filter(id => id !== boardId);
        }
        dispatch(updateMyWorkMeta( { unselectedBoards, selectedBoards }));
    };
    return action;
};
