import { cardToggleDone } from 'app/rest/effects/card/card/cardToggleDone';
import { IGetState, TCardId } from 'app/types/types';
import { Dispatch, ThunkAction } from 'app/types/actions';
import { getCardCanBeSetDone } from 'app/store/model/selectors/getCardCanBeSetDone';
import {
    SegmentCardEvent,
    SegmentCardOptions, SegmentCardOptionStatusValue,
    SegmentCardSourceValue
} from 'app/middlewares/segment/trackEntities/cardEvents';
import { segmentTrackAction } from 'app/middlewares/segment';
import { getCardDone } from 'app/store/model/selectors/getCardDone';

export const onClick = (
    cardId: TCardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const canDone = getCardCanBeSetDone(state, cardId);

        const done = getCardDone(state, cardId);
        if (canDone) {
            dispatch(segmentTrackAction(SegmentCardEvent.DONE, {
                    name: SegmentCardOptions.SOURCE,
                    value: SegmentCardSourceValue.DETAILS,
                },
                [{
                    name: SegmentCardOptions.STATUS,
                    value: done ? SegmentCardOptionStatusValue.OFF : SegmentCardOptionStatusValue.ON
                }]
            ));
            dispatch(cardToggleDone(cardId));
        }
    };
    return action;
};
