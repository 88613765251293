import { IGetState, TBoardId } from '../../../types/types';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { getBoardMetaWarnings } from '../../../store/model/board/selectors/getBoardMetaWarnings';
import { META_WARNING_AUTOBCKUPS_FAILED } from '../../../view/react_components/main/topBar/hocs/TopBarWarningHOC/constants';
import { boardSetMetaWarnings } from './boardSetWarningsMeta';

export const removeAutoBackupsWarningMeta = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const warnings = [...getBoardMetaWarnings(state, boardId)];
        const index = warnings.indexOf(META_WARNING_AUTOBCKUPS_FAILED);
        warnings.splice(index, 1);
        dispatch(boardSetMetaWarnings(boardId, warnings));
    };
    return action;
};
