import { Dispatch, ThunkAction } from '../../../../types/actions';
import { fetchHandler } from '../../../../util/fetchHandler';
import { Util } from '../../../../util/util';
import { getRestHeadersPost } from '../../../helpers/getRestHeadersHelper';
import { IBoard } from '../../../../store/model/board/types';
import { TGoogleDriveId } from '../../../../view/react_components/dialogs/openBoards/store/types';
import { DRIVE_ID_MY_DRIVE } from '../../../../view/react_components/dialogs/openBoards/store/drives/constants';
import { REST_CREATE_BOARD_MY_DRIVES, REST_CREATE_BOARD_SHARED_DRIVES } from '../../../constants';

export const postRest = (
    driveId: TGoogleDriveId,
    board: IBoard
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        const isMyDrive = driveId === DRIVE_ID_MY_DRIVE;

        const restUrl = isMyDrive ?
            REST_CREATE_BOARD_MY_DRIVES :
            REST_CREATE_BOARD_SHARED_DRIVES + driveId;
        return fetchHandler<IBoard>(
            Util.getApiUrl(restUrl), {
                ...getRestHeadersPost(),
                body: JSON.stringify(board)
            }
        );
    };
    return action;
};
