'use strict';

App.Views.PermissionsSheet = App.Views.PermissionsDialog.extend({
    getData: function() {
        return {
            video: 'bZ-MORtrHMg',
            icon: '/dist/img/google-sheets-colored.svg',
            description: "Kanbanchi needs permission to view and manage your spreadsheets in Google Drive to export data from your boards. You will be able to change the type of access later.",
            annotation: 'Kanbanchi will have access to your Google Drive to export data from your boards to Google Sheets.',
            shortText: 'View and manage your spreadsheets in Google Drive',
            title: 'Google Sheets'
        }
    }
});
