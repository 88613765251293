import * as React from 'react';
import './_MyWorkImportTab.scss';
import { IMyWorkImportTabProps } from './types';
import { MyWorkImportBoardSection } from '../MyWorkImportBoardsSection/MyWorkImportBoardSection';

export const MyWorkImportTab = ({}: IMyWorkImportTabProps) => {
    return (
        <MyWorkImportBoardSection />
    );
}
