import { IApplicationState } from '../../../../../../../../types/types';
import { connect } from 'react-redux';
import { IBoardColorsHOCProps } from './types';
import { BoardColors } from '../../components/BoardColors/BoardColors';
import { IBoardColorsEvents, IBoardColorsFields } from '../../components/BoardColors/types';
import { onToggle } from './events/onToggle';
import { getPanelReadonly } from '../../../../selectors/getPanelReadonly';
import { getAllColorsEnabled } from './selectors/getAllColorsEnabled';
import { onEdit } from '../../../../../cardDetails/ColorTags/hocs/events/onEdit';
import { onDelete } from './events/onDelete';
import { getBoardColorsWithCardsCount } from './selectors/getBoardColorsWithCardsCount';
import { onClose } from './events/onClose';
import { getAuthUser } from '../../../../../../../../store/model/authUser/selectors/getAuthUser';
import { getBoardCustomColors } from '../../../../../../../../store/model/board/selectors/getBoardCustomColors';
import {
    onAddCustomColor
} from '../../../../../../base/components/ColorTagsDropdown/hocs/ColorTagsDropdownHOC/events/onAddCustomColor';

const mapStateToProps = (
    state: IApplicationState,
    { boardId }: IBoardColorsHOCProps
): IBoardColorsFields => {
    const colors = getBoardColorsWithCardsCount(state, boardId);
    const { allowColorTagCount } = getAuthUser(state);
    return {
        aloudCount: allowColorTagCount,
        colors,
        customColors: getBoardCustomColors(state, boardId),
        isAllColorsEnabled: getAllColorsEnabled(colors),
        isReadonly: getPanelReadonly(state, boardId),
    }
};

const mapDispatchToProps = (
    dispatch: any,
    ownProps: IBoardColorsHOCProps
): IBoardColorsEvents => {
    const { boardId } = ownProps;
    return {
        onAddCustomColor: (color) => dispatch(onAddCustomColor(boardId, color)),
        onClose: () => dispatch(onClose()),
        onToggleStatus: (colorId) => dispatch(onToggle(boardId, colorId)),
        onEdit: (colorTag) => dispatch(onEdit(boardId, colorTag)),
        onDelete: (colorId) => dispatch(onDelete(boardId, colorId))
    }
};

export const BoardColorsHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(BoardColors);

BoardColorsHOC.displayName = 'BoardColorsHOC';
