import * as React from 'react';
import { IRestCardCommonActivity } from '../../../../../../../types/rest/activity/IRestCardCommonActivity';
import { IActivityProps } from '../../../../boardDetails/activity/TabActivity/types';
import { TabActivityContext } from '../../../../boardDetails/activity/TabActivity/components/TabActivity/constants';
import { TActivity } from '../../../../../../../store/activities/types';
import {
    ActivityItemBody
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemBody/ActivityItemBody';
import { ActivityHeader } from '../../../../boardDetails/activity/TabActivity/components/ActivityHeader/ActivityHeader';

export function CardActivityArchive ({
    activity
}: IActivityProps<IRestCardCommonActivity>) {
    const { onOpenCard } = React.useContext(TabActivityContext);
    const { label, card } = activity as TActivity;
    return (
        <ActivityItemBody
            onClick={() => onOpenCard(card.id, activity.id)}
            header={<ActivityHeader
                icon={'archive'}
                label={label}
            />}
            activity={activity}
        >
        </ActivityItemBody>
    );
}
