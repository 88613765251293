'use strict';

import { getBoardIdByListId } from '../store/model/selectors/getBoardIdByListId';
import { getAppState } from '../store/configureStore';
import { getAuthUser } from '../store/model/authUser/selectors/getAuthUser';

App.Models.StatReport = App.Models.StatBase.extend({

    rest: '/updateCardCount',

    onCreate: function() {
        return {
            method: 'create'
        }
    },

    onComplete: function(){ 
       this.destroy();
    },

    onError: function(xhr, textStatus, errorThrown){
        var user = getAuthUser(getAppState());
        var dashboardId = getBoardIdByListId(getAppState(), this.get('listId')) || this.get('dashboardId');
        var options = {
            userId: user.id,
            dashboardId: dashboardId,
        };
        var errorReport = new App.Models.DashboardUserStatError(options);
        errorReport.save({
            userInfo: user.fullName,
            errCode: xhr.status,
            message: xhr.statusText,
            responseText: xhr.responseJSON,
            lastErrDate: App.Util.nowTimestampWithOffset()

        });
    },

    saveWithGet: function(data){
        this.set(data);
        var data = $.param(data);
        return this.fetch({data});
    }

});
