
App.Collections.NotificationsTimeFilter = App.Collections.Notifications.extend({
    classKey: 'notifications',
    model: App.Models.Notification,
    url: function() {
        let queryList = [];
        if (this.cursor)
            queryList.push('cursor=' + this.cursor);
        if (this.options.timeFilter.fromTimeAge)
            queryList.push('fromTimeAge=' + this.options.timeFilter.fromTimeAge);
        if (this.options.timeFilter.toTimeAge)
            queryList.push('toTimeAge=' + this.options.timeFilter.toTimeAge);
        queryList.push('isNewFrontend=' + true);
        return App.Util.getApiUrl('/rest/pushnotification/time-filter-list' + (queryList.length > 0 ? '?' + queryList.join('&') : ''));
    },

    initialize: function(models, options) {
        this.options = options || {};
        this.portion = 0; // помечаем, в какой порции приехала нотификация, чтобы для разных порций делать разные группы
    },

    comparator: function(noty) {
        return this.get('time') - noty.get('time');
    },
});
