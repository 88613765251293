import { EMPTY_ARRAY } from './../../../../types/types';
import { ICardAssigneeRole } from './../../../../types/model';
import { IBoard } from '../types';

export const getBoardRoles = (
    board: IBoard,
): ICardAssigneeRole[] => {
    if (!board || !board.cardAssigneeRoles) return EMPTY_ARRAY;

    return board.cardAssigneeRoles;
};
