import { IGetState, TCardId, TNBDId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { notificationBeforeDueDateDelete as notificationBeforeDueDateDeleteStoreAction } from '../../../../store/model/actionCreators/notificationBeforeDueDate/notificationBeforeDueDateDelete';
import { deleteRest } from './api/deleteRest';
import { sendRealTimeStoreActionByCardId } from '../../../../view/react_components/base/helpers/realTimeHelperTS';

export const notificationBeforeDueDateDelete = (
    cardId: TCardId,
    notificationId: TNBDId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(deleteRest(cardId, notificationId)).then(() => {
            dispatch(notificationBeforeDueDateDeleteStoreAction(cardId, notificationId));

            dispatch(sendRealTimeStoreActionByCardId(cardId, notificationBeforeDueDateDeleteStoreAction(cardId, notificationId)));
        });
    };
    return action;
};
