import { root } from 'app/store/constants';
import {ActionCreator} from 'redux';
import {Dispatch, ThunkAction} from 'app/types/actions';
import {IGetState} from 'app/types/types';
import {boardOpenClickedSegmentEvent} from '../../../effects/segmentEvents';

export const onClick: ActionCreator<ThunkAction> = (
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        root.App.controller.mainView.openBoardsDialogShow();
        dispatch(boardOpenClickedSegmentEvent());
    };
    return action;
};
