import * as React from 'react';
import './_AffiliateDialog.scss';
import { EAffiliateDialogSource, IAffiliateDialogProps } from './types';
import {
    AFFILIATE_BODY,
    AFFILIATE_CLOSE,
    AFFILIATE_FAQ,
    AFFILIATE_LINK,
    AFFILIATE_TITLE,
    AFFILIATE_TRY_LATER
} from './constants';
import { root } from '../../../../../../store/constants';
import { Input } from 'kui';

export const AffiliateDialog = ({
    onClose,
    onCopy,
    onDidMount,
    onFaqClicked,
    source,
    affiliateId
}: IAffiliateDialogProps) => {
    const className = 'affiliate-dialog';
    const headerClassName = className + '__header';
    const bodyClassName = className + '__body';
    const footerClassName = className + '__footer';

    const textareaRef = React.useRef(null);
    const [copied, setCopied] = React.useState(false);

    React.useEffect(() => {
        onDidMount();
    }, []);

    const link = affiliateId ? `${root.location.origin}${AFFILIATE_LINK}${affiliateId}` : '';

    const onTextareaClick = (
    ) => {
        textareaRef.current.getInput().select();
        if (!copied) {
            onCopy(link);
            setCopied(true);
        }
    }

    return (
        <div className={className}>
            <div className={headerClassName}>
                {AFFILIATE_TITLE}
            </div>
            <div className={bodyClassName}>
                {AFFILIATE_BODY}
                <Input
                    value={link}
                    ref={textareaRef}
                    onClick={onTextareaClick}
                />
            </div>
            <div className={footerClassName}>
                <p><a href={'https://www.kanbanchi.com/faqwd/do-you-have-referral-and-affiliate-program'} target={'_blank'} onClick={onFaqClicked}>{AFFILIATE_FAQ}</a></p>
                {source !== EAffiliateDialogSource.HELP && <p>{AFFILIATE_TRY_LATER}</p>}
                <p><span className={className + '__close'} onClick={onClose}>{AFFILIATE_CLOSE}</span></p>
            </div>
        </div>
    )
}
