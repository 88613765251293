/*
    default prefix "KANBAN_VIEW_"
*/
export const AT_MOVE_TO_OPENED_LIST_ID = 'KANBAN_VIEW_MOVE_TO_OPENED_LIST_ID';
export const AT_DRAGGABLE_CARD_ID = 'KANBAN_VIEW_DRAGGABLE_CARD_ID';
export const AT_SNOOZE_BLOCKER_CLOSED_SET = 'KANBAN_VIEW_SNOOZE_BLOCKER_CLOSED_SET';
export const AT_HIGHLIGHT_CARD_ID = 'KANBAN_VIEW_HIGHLIGHT_CARD_ID';
export const AT_RECENTLY_WATCHED_CARD_ID = 'KANBAN_VIEW_RECENTLY_WATCHED_CARD_ID';
export const AT_SWIMLANES_UPDATE = 'KANBAN_VIEW_SWIMLANES_UPDATE';
export const AT_ACTIVITY_SCROLL_LIST_ID = 'KANBAN_VIEW_ACTIVITY_LIST_ID';
export const AT_NEW_CARD_SET = 'KANBAN_VIEW_NEW_CARD_INPUT_NAME_SET';
