import { IApplicationState, TBoardId } from '../../../types/types';
import { getBoardActiveRequests } from './getBoardActiveRequests';

export const getBoardActiveRequestsWithDelta = (
    state: IApplicationState,
    boardId: TBoardId,
    delta: number,
): number => {
    let boardActiveRequests = getBoardActiveRequests(state, boardId);
    boardActiveRequests += delta;
    if (boardActiveRequests < 0) {
        console.error(`activeRequests < 0 for board: ${boardId}`);
        boardActiveRequests = 0;
    }
    return boardActiveRequests;
};
