import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { TCardId } from '../../../../../../../../types/types';
import { SegmentCardEvent, SegmentCardOptions, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { SegmentCardSourceValue } from '../../../../../../../../middlewares/segment/trackEntities/cardEvents';
import { openCardIfNeeded } from '../effects/openCardIfNeeded';

export const onEdit = (
    cardId: TCardId,
    source: SegmentCardSourceValue
): ThunkAction => {
    return (dispatch: Dispatch) => {
        dispatch(segmentTrackAction(SegmentCardEvent.EDIT_CARD_DROPDOWN_CLICKED, {
            name: SegmentCardOptions.SOURCE,
            value: source
        }));
        dispatch(openCardIfNeeded(cardId));
    };
};
