import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../../../../types/types';
import { cardToggleGantt } from '../../../../../../../../rest/effects/card/card/cardToggleGantt';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';

export const onGantt = (
    subcardId: TCardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.CLICKED_SHOW_SUBCARD_ON_GANTT));
        return dispatch(cardToggleGantt(subcardId));
    };
    return action;
};
