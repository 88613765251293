import { LazyWrapper } from 'app/view/react_components/lazy/lazy_wrapper';
import React from 'react';
import { Provider } from "react-redux";
import { CardDetailsBaseView } from './base';

export const AssignedToMeCardDetailsView = CardDetailsBaseView.extend({

    initialize: function (options) {
        CardDetailsBaseView.prototype.initialize.call(this, options);
        this.cardId = options.cardId;
    },

    reactRender: function () {
        const ImportElement = React.lazy(() =>
            this.initStore()
                .then(() => import(/* webpackChunkName: "cardDetails" */ '../../react_components/aside_panel/assigneToMe/assignedToMe/hocs/AssignedToMeHOC/AssignedToMeHOC'))
                .then(module => ({default: module.AssignedToMeHOC}))
                .catch(e => console.error(e))
        );

        this.root.render(
            <LazyWrapper>
                <Provider store={this.store}>
                    <ImportElement cardId={this.cardId} />
                </Provider>
            </LazyWrapper>
        );
    }

});
