import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { ICardHOCProps } from '../CardHOC/types';
import { getAuthUser } from '../../../../../../../store/model/authUser/selectors/getAuthUser';
import { ICardAuthorFields } from '../../components/CardAuthor/types';
import { CardAuthor } from '../../components/CardAuthor/CardAuthor';
import { getBoardRolesCreateSelector } from './selectors/getBoardRolesSelector';
import { getBoardUsersCreateSelector } from '../../../../../../../store/model/selectors/getBoardUsers';

const mapStateToProps = () => {
    const getBoardUsers = getBoardUsersCreateSelector();
    const getBoardRoles = getBoardRolesCreateSelector();
    return (
        state: IApplicationState,
        { card }: ICardHOCProps
    ): ICardAuthorFields => {
        return {
            boardUsers: getBoardUsers(state, card.dashboardId),
            authUserPermissionId: getAuthUser(state).permissionId,
            roles: getBoardRoles(state, card.dashboardId),
            users: [{sharedUser: card.author}]
        }
    }
}

export const CardAuthorHOC = connect(
    mapStateToProps,
    null
)(CardAuthor);
CardAuthorHOC.displayName = 'CardAuthorHOC';
