import { REST_BOARD } from './../../../../../rest/constants';
import { getRestHeadersPost, getRestHeadersDelete, getRestHeadersGet } from './../../../../../rest/helpers/getRestHeadersHelper';
import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { TGoogleDriveId } from '../store/types';
import { blockerSet } from '../store/common/actions/blockerSet';
import {
    REST_BOARD_FILES_MOVE,
    REST_BOARD_FILES_MOVE_FOLDERS,
    REST_BOARD_MOVE,
    REST_BOARD_UPDATE_PERMISSIONS,
} from '../constants';
import Util from '../../../../../util/util';
import {
    snackbarErrorReload,
    snackbarProgressAdd,
    snackbarProgressUpdate,
    snackbarSuccessDefault,
} from '../../../snackbarsStack';
import { IRestMoveBoard, IRestMoveFiles } from '../rest/types';
import {
    SNACKBAR_BOARD_MOVE_ID,
    SNACKBAR_MOVE_PROGRESS_TEXT,
    SNACKBAR_MOVE_PROGRESS_TITLE,
    SNACKBAR_MOVE_SUCCESS_TEXT,
    SNACKBAR_MOVE_SUCCESS_TITLE,
} from '../hocs/BoardActionMoveToDriveHOC/constants';
import { TBoardId } from '../../../../../types/types';
import { getMessages, root } from '../../../../../store/constants';
import { IGetState } from '../../../../../types/types';
import { getBoard } from '../store/boards/selectors/getBoard';
import { getDrive } from '../store/drives/selectors/getDrive';
import { boardMove } from '../store/boards/actions/boardMove';
import { deleteSnackbar } from '../../../snackbarsStack/store/actions/deleteSnackbar';
import { loadBoard } from './loadBoard';
import { moveBoardGoogleAnalyticsEvents } from './googleAnalyticsEvents';
import { fetchHandler } from '../../../../../util/fetchHandler';
import { escape } from 'underscore';
import { clearMarkdownInline } from '../../../base/helpers/clearMarkdownHelper';
import { getOpened } from '../store/common/selectors/getOpened';

export class MoveBoard {
    constructor() {
        this.moveState = {
            boardId: null,
            driveId: null,
            assetsTo: null,
            backupTo: null,
            parentFrom: null,
            filesIds: null,
            filesCount: null,
            progress: null,
            time: null,
            progressInterval: null
        };
    }

    private moveState: IMoveState;

    start: ActionCreator<ThunkAction> = (
        boardId: TBoardId,
        driveId: TGoogleDriveId
    ) => {
        const action = (
            dispatch: Dispatch,
            getState: IGetState
        ) => {
            this.moveState.boardId = boardId;
            this.moveState.driveId = driveId;
            dispatch(moveBoardGoogleAnalyticsEvents(boardId, driveId));
            dispatch(blockerSet(true));
            if (!getOpened(getState())) { // for boardSettings moveTo button
                root.App.controller.mainView.showLoader(true);
                root.App.controller.mainView.hardBlockToggle(true);
            }
            dispatch(snackbarProgressAdd({
                id: SNACKBAR_BOARD_MOVE_ID,
                text: SNACKBAR_MOVE_PROGRESS_TEXT,
                title: SNACKBAR_MOVE_PROGRESS_TITLE,
                buttonProgress: 0
            }));
            return fetchHandler<IRestMoveBoard>(
                Util.getApiUrl(REST_BOARD_MOVE + boardId + '/' + driveId),
                getRestHeadersPost()
            )
            .then(
                (response) => {
                    dispatch(boardMove(boardId, driveId));
                    this.moveState.assetsTo = response.assetsTo;
                    this.moveState.backupTo = response.backupTo;
                    this.moveState.parentFrom = response.parentFrom;
                    this.moveState.filesIds = {
                        [this.moveState.assetsTo]: null,
                        [this.moveState.backupTo]: null
                    };
                    dispatch(this.moveFiles(
                        response.assetsFrom,
                        response.assetsTo,
                        []
                    ));
                    dispatch(this.moveFiles(
                        response.backupFrom,
                        response.backupTo,
                        []
                    ));
                }
            )
            .catch(
                () => {
                    dispatch(deleteSnackbar(SNACKBAR_BOARD_MOVE_ID));
                    dispatch(snackbarErrorReload());
                }
            );
        };
        return action;
    };

    private moveFiles: ActionCreator<ThunkAction> = (
        moveFrom: TGoogleDriveId,
        moveTo: TGoogleDriveId,
        idsForCopy: TGoogleDriveId[] = []
    ) => {
        const action = (dispatch: Dispatch) => {
            const body = {
                moveFrom,
                moveTo,
                idsForCopy
            };
            this.moveState.time = Date.now();
            return fetchHandler<IRestMoveFiles>(
                Util.getApiUrl(REST_BOARD_FILES_MOVE), {
                    ...getRestHeadersPost(),
                    body: JSON.stringify(body)
                }
            )
            .then(
                (response) => {
                    this.moveState.filesIds[moveTo] = response.idsForCopy;
                    if (this.moveState.progress === null) {
                        if (
                            this.moveState.filesIds[this.moveState.assetsTo] !== null &&
                            this.moveState.filesIds[this.moveState.backupTo] !== null
                        ) {
                            this.moveState.progress = 0;
                            this.moveState.filesCount = this.getMoveFilesCount();
                        }
                    }
                    if (this.moveState.progress !== null) {
                        if (!this.getMoveFilesCount()) {
                            dispatch(this.moveSuccess());
                            return Promise.resolve();
                        } else {
                            dispatch(this.snackbarProgressTransition());
                        }
                    }
                    if (response.idsForCopy.length) {
                        dispatch(this.moveFiles(
                            moveFrom,
                            moveTo,
                            response.idsForCopy
                        ));
                    }
                }
            )
            .catch(
                () => {
                    dispatch(deleteSnackbar(SNACKBAR_BOARD_MOVE_ID));
                    dispatch(snackbarErrorReload());
                }
            );
        };
        return action;
    };

    private snackbarProgressTransition: ActionCreator<ThunkAction> = () => {
        const action = (dispatch: Dispatch) => {
            clearInterval(this.moveState.progressInterval);
            let progress = this.moveState.progress;
            let doneAfter = this.moveState.filesCount - this.getMoveFilesCount();
            let progressAfter = Math.ceil(100 * doneAfter / this.moveState.filesCount);
            let progressDelta = progressAfter - progress;
            if (!progressDelta) return;

            this.moveState.progress = progressAfter;
            let time = Date.now() - this.moveState.time;
            let intervalTime = time / progressDelta;
            this.moveState.progressInterval = window.setInterval(() => {
                dispatch(snackbarProgressUpdate({
                    id: SNACKBAR_BOARD_MOVE_ID,
                    text: SNACKBAR_MOVE_PROGRESS_TEXT,
                    title: SNACKBAR_MOVE_PROGRESS_TITLE,
                    buttonProgress: progress
                }));
                progress++;
                if (progress > this.moveState.progress) {
                    clearInterval(this.moveState.progressInterval);
                }
            }, intervalTime);
        };
        return action;
    };

    private deleteFolders: ActionCreator<ThunkAction> = () => {
        const action = (dispatch: Dispatch) => {
            return fetchHandler(
                Util.getApiUrl(REST_BOARD_FILES_MOVE_FOLDERS + this.moveState.parentFrom),
                getRestHeadersDelete()
            );
        };
        return action;
    };

    private updatePermissions: ActionCreator<ThunkAction> = () => {
        const action = (dispatch: Dispatch) => {
            return fetchHandler(
                Util.getApiUrl(REST_BOARD + this.moveState.boardId + REST_BOARD_UPDATE_PERMISSIONS),
                getRestHeadersGet()
            );
        };
        return action;
    };

    private moveSuccess: ActionCreator<ThunkAction> = () => {
        const action = (
            dispatch: Dispatch,
            getState: IGetState
        ) => {
            setTimeout(
                () => {
                    dispatch(this.deleteFolders());
                    dispatch(this.updatePermissions());
                },
                10 * 1000 // 10 sec
            );
            clearInterval(this.moveState.progressInterval);
            dispatch(snackbarProgressUpdate({
                id: SNACKBAR_BOARD_MOVE_ID,
                text: SNACKBAR_MOVE_PROGRESS_TEXT,
                title: SNACKBAR_MOVE_PROGRESS_TITLE,
                buttonProgress: 100
            }));
            dispatch(loadBoard(this.moveState.boardId))
            .then(() => {
                const board = getBoard(getState(), this.moveState.boardId);
                const drive = getDrive(getState(), this.moveState.driveId);
                dispatch(snackbarSuccessDefault({
                    id: SNACKBAR_BOARD_MOVE_ID,
                    text: getMessages().getText(SNACKBAR_MOVE_SUCCESS_TEXT, null, escape(clearMarkdownInline(drive.name))),
                    title: getMessages().getText(SNACKBAR_MOVE_SUCCESS_TITLE, null, escape(clearMarkdownInline(board.name)))
                }));
            })
            .finally(() => {
                dispatch(blockerSet(false));
                root.App.controller.mainView.hideLoader(); // for boardSettings moveTo button
                root.App.controller.mainView.hardBlockToggle(false);
            });
        };
        return action;
    };

    private getMoveFilesCount = () => {
        const assets = this.moveState.filesIds[this.moveState.assetsTo];
        const backup = this.moveState.filesIds[this.moveState.backupTo];
        return assets.length + backup.length;
    }
}

type TIdsForCopy = {
    [folderId: string]: TGoogleDriveId[]
};

interface IMoveState {
    boardId: TBoardId;
    driveId: TGoogleDriveId;
    assetsTo: TGoogleDriveId;
    backupTo: TGoogleDriveId;
    parentFrom: TGoogleDriveId;
    filesIds: TIdsForCopy;
    filesCount: number;
    progress: number;
    time: number;
    progressInterval: number;
};
