import { createRoot } from 'react-dom/client';
import {Provider} from "react-redux";
import React from "react";
import {NavigationPanelHOC} from "../react_components/main/navigationPanel/hocs/NavigationPanel/NavigationPanelHOC";

App.Views.NavigationPanelView = App.Views.BaseView.extend({

    initialize: function(options){
        this.root = createRoot(this.el);
        this.store = options.store;
    },

    render: function () {
        this.root.render(
            <Provider store={this.store}>
                <NavigationPanelHOC />
            </Provider>
        );

        return this;
    },

    remove: function () {
        this.root.unmount();
        Backbone.View.prototype.remove.call(this);
    }
});

export default App.Views.NavigationPanelView;
