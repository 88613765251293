import { getMessages } from '../../../../../store/constants';
import {
    UPGRADE_BUTTON_FOR_READ_ONLY_MODE,
    UPGRADE_BUTTON_TEXT_FREE,
    UPGRADE_BUTTON_TRIAL,
    UPGRADE_BUTTON_TRIAL_LAST_DAY
} from '../constants';
import { getDaysLeft } from './getDaysLeft';
import { getPaymentExpDate } from '../selectors/getPaymentExpDate';
import { IApplicationState } from '../../../../../types/types';
import { isContinuedReadOnly } from '../../../base/selectors/isContinuedReadOnly';
import { isShowSaleSelector } from '../hocs/selectors/isShowSaleSelector';
import { getProFeaturesTrialActive } from '../../../../../store/model/authUser/selectors/getProFeaturesTrialActive';
import { getUserPromoCode } from '../../../../../store/model/authUser/selectors/getUserPromoCode';
import { getTrialSubscription } from '../../../../../store/model/authUser/selectors/getTrialSubscription';
import { getPlanName } from '../../../subscriptionAdmin/store/subscription/selectors/getPlanName';

export const getUpgradeMessage = (state: IApplicationState): string => {
    if(getProFeaturesTrialActive(state)) {
        return  getMessages().getText('pro-trial.upgrade-button.text');
    }
    let daysLeft = getDaysLeft(getPaymentExpDate(state));
    const isUserReadOnly = isContinuedReadOnly();
    const isShowSale = isShowSaleSelector(state);
    if (isShowSale) {
        return UPGRADE_BUTTON_TEXT_FREE
    } else if (isUserReadOnly) {
        return UPGRADE_BUTTON_FOR_READ_ONLY_MODE;
    } else if (daysLeft >= 1) {
        daysLeft = Math.floor(daysLeft);
        if (getUserPromoCode(state)) {
            const planName = getPlanName(getTrialSubscription(state).productId);
            return `${daysLeft} ${getMessages().getPlural(daysLeft, ['day', 'days'])} ${getMessages().getText('upgrade_button.trial', null, planName)}`;
        } else {
            return (daysLeft + ' ' + getMessages().getPlural(daysLeft, ['day', 'days']) + UPGRADE_BUTTON_TRIAL);
        }
    } else if (daysLeft > 0) {
        if (getUserPromoCode(state)) {
            const planName = getPlanName(getTrialSubscription(state).productId);
            return getMessages().getText('upgrade_button.trial.last', null, planName);
        } else {
            return UPGRADE_BUTTON_TRIAL_LAST_DAY;
        }
    } else return ''
};
