import {IApplicationState, TBoardId} from '../../../../types/types';
import {getLists} from './getLists';
import {IList} from '../../list/types';

export const getListsByBoardId = (
    state: IApplicationState,
    boardId: TBoardId
): IList[] => {
    return Object.values(getLists(state))
        .filter(list => list.dashboardId === boardId);
}
