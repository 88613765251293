import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TFilterId } from '../../../../../../../../types/types';
import {
    onRemoveFilter
} from '../../../../../../aside_panel/filterPanelBoard/components/SavedSection/hocs/EditSavedFilterHOC/events/onRemoveFilter';
import { MY_WORK_FILTER_BOARD_ID } from '../../../../../../aside_panel/filterPanelBoard/constants';

export const onDelete = (
    filterId: TFilterId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(onRemoveFilter(filterId, MY_WORK_FILTER_BOARD_ID));
    }
    return action;
}
