import { IGetState, TBoardId, TCardId, TColorId } from '../../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { SegmentBoardEvent, segmentTrackAction } from '../../../../../../../../../middlewares/segment';
import { TStatus } from '../../../../../../../../../types/model';
import { getBoardColorById } from '../../../../../../../../../store/model/board/selectors/getBoardColorById';
import { getCard } from '../../../../../../../../../store/model/selectors/getCard';
import { cardSaveColorTags } from '../../../../../../../../../rest/effects/card/card/cardSaveColorTags';
import { getMessages, root } from '../../../../../../../../../store/constants';
import { snackbarPromtYesNo } from '../../../../../../../snackbarsStack/effects/variantPromt/snackbarPromtYesNo';
import { getDefaultActionEvent } from '../../../../../../../snackbarsStack/effects/helpers/getDefaultActionEvent';
import { boardColorEdit } from '../../../../../../../../../rest/effects/color/boardColorEdit';
import { getBoardCardsByStatuses } from '../../../../../../../../../store/model/selectors/getBoardCardsByStatuses';
import { showSnoozeBlocker } from '../../../../../../../base/effects/showSnoozeBlocker';
import {
    getAuthUserAllowColorTagCount
} from '../../../../../../../../../store/model/authUser/selectors/getAuthUserAllowColorTagCount';
import { getBoardColorsByStatus } from '../../../../../../../../../store/model/board/selectors/getBoardColorsByStatus';

export const onToggle = (
    boardId: TBoardId,
    colorId: TColorId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentBoardEvent.CHECKED_COLOUR_TAG));
        return new Promise((resolve, reject) => {
            const colorTag = getBoardColorById(getState(), boardId, colorId);
            const isDisabled = colorTag && colorTag.status === TStatus.STATUS_DELETED;
            const resolvePromise = (
                cardIds?: TCardId[]
            ) => {
                const state = getState();
                if (cardIds) {
                    cardIds.forEach(cardId => {
                        const colorIds = [...getCard(state, cardId).colorIds];
                        dispatch(cardSaveColorTags(cardId, colorIds.filter(id => id !== colorId)));
                    })
                }
                const status = isDisabled ? TStatus.STATUS_ACTIVE : TStatus.STATUS_DELETED;
                const count = getBoardColorsByStatus(state, boardId, TStatus.STATUS_ACTIVE).length;
                const maxCount = getAuthUserAllowColorTagCount(state);
                if (
                    status === TStatus.STATUS_ACTIVE &&
                    count >= maxCount
                ) {
                    dispatch(showSnoozeBlocker({ allow: 'allowColorTagCount', subAllow: 'allowBoardColorTagCount' }));
                } else if (
                    status === TStatus.STATUS_DELETED &&
                    root.App.controller.allowCounterSnackbarHelper.isShowSnackbar('allowColorTagCount') &&
                    count > maxCount
                ) {
                    resolve(false);
                } else {
                    return dispatch(boardColorEdit(boardId, { id: colorId, status })).then(() => {
                        return resolve(true);
                    });
                }
            };

            const cardIdsWithTag: TCardId[] = [];

            if (!isDisabled) {
                const boardCards = getBoardCardsByStatuses(getState(), boardId, [TStatus.STATUS_ACTIVE, TStatus.STATUS_SERVICE_TEMPLATE]);
                boardCards.forEach(card => { // перебрать карты доски
                    const colorTags = card.colorIds || [];
                    if (colorTags.includes(colorId)) {
                        cardIdsWithTag.push(card.id);
                    }
                })
            }

            const cardsCount = cardIdsWithTag.length
            if (cardsCount && !isDisabled) { // если на картах есть колорТэг, снекбар с подтверждением
                const textCards = getMessages().getPluralByKeys(
                    cardsCount,
                    ['snackbar.card.color_tags.toggle.card', 'snackbar.card.color_tags.toggle.cards'],
                    null,
                    cardsCount
                );
                const snackbarId = 'SNACKBAR_COLOR_TOGGLE_ID';
                dispatch(snackbarPromtYesNo({
                    id: snackbarId,
                    text: textCards,
                    actionApply: () => dispatch(getDefaultActionEvent(snackbarId, resolvePromise(cardIdsWithTag))),
                    actionCancel: () => dispatch(getDefaultActionEvent(snackbarId, () => resolve(null)))
                }));
            } else {
                resolvePromise();
            }
        });
    };
    return action;
};
