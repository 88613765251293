import * as React from 'react';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { TabActivityHOC } from '../../../../boardDetails/activity/TabActivity/hocs/TabActivityHOC/TabActivityHOC';
import { REST_CARD } from '../../../../../../../rest/constants';
import { ICardTabActivityProps } from './types';
import { ECardTab } from '../../../types';
import { AnalyzeRender } from '../../../../../helpers/memoizeHelper';

//@ts-ignore
const analyzeRender = window.Settings.development ? new AnalyzeRender(`CardTabActivity`) : null;

export function  CardTabActivity({
    fetchOptions
}: ICardTabActivityProps) {
    const { cardId, tabs } = React.useContext(AsidePanelContext);
    if (analyzeRender) analyzeRender.call(`CardTabActivity ${cardId}`);
    const activityTab = tabs.find(tab => tab.key === ECardTab.ACTIVITY);
    const newActivitiesCount = activityTab && activityTab.indicatorNumber;

    return <TabActivityHOC
        objectId={cardId}
        url={REST_CARD}
        fetchOptions={fetchOptions}
        newActivitiesCount={newActivitiesCount}
    />;
}
