import { ISnackbarProgress } from './types';
import { Dispatch } from '../../../../../types/actions';
import { addUpdateSnackbarWithBubble } from '../addUpdateSnackbarWithBubble/addUpdateSnackbarWithBubble';
import { getSnackbarProgress } from './progressDefault';

export const snackbarProgressAdd = (
    options: ISnackbarProgress
) => {
    return (dispatch: Dispatch) => {
        dispatch(addUpdateSnackbarWithBubble(
            getSnackbarProgress(options)
        ));
    }
};
