import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState } from '../../../../../../../types/types';
import { updateMyWorkMeta } from '../../../../../../../rest/effects/authUser/myWork/updateMyWorkMeta';
import { getMyWorkFilterBarCollapsed } from '../../../../../main/myWorkView/selectors/getMyWorkFilterBarCollapsed';

export const onToggleCollapse = (): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const isFilterBarCollapsed = !getMyWorkFilterBarCollapsed(getState());
        dispatch(updateMyWorkMeta({ isFilterBarCollapsed }));
    }
    return action;
}
