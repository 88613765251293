import { EMPTY_ARRAY, IApplicationState } from '../../../../types/types';
import { getAuthUser } from './getAuthUser';
import { IRecentBoard } from '../../../../types/rest/IUserMeta';

export const getUserStarredBoards = (
    state: IApplicationState
): IRecentBoard[] => {
    const user = getAuthUser(state);
    return user.meta && user.meta.starredBoards || EMPTY_ARRAY;
};
