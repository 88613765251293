'use strict';

import confirmDeleteTemplate from './templates/confirmDelete.html';
import * as _ from 'underscore';

App.Views.ConfirmDeleteRecord = App.Views.BaseView.extend({
    tagName: 'div',
    
    className: 'modal__inner',
    
    template: _.template( confirmDeleteTemplate ),
    
    events: {
        'click .modal__close': 'onCloseClick',
        'click .button--close-modal': 'onCloseClick',
        'click .button--delete': 'deleteRecord'
    },
    
    initialize: function(options) {
        this.onDeleteCallback = options.onDeleteCallback;
        App.vent.on(App.vent['keydown:escape'], this.hide, this);
    },
    
    render: function() {
        this.$el.html( this.template(this.model.toJSON()) );
        return this;
    },
    
    deleteRecord: function(e) {
        e.stopPropagation();
        this.onDeleteCallback();
        this.hide();
    },
    
    onCloseClick: function(e) {
        e.stopPropagation();
        this.hide();
    },
    
    hide: function() {
        App.vent.trigger(App.vent['dialog:closed']);
    },
    
    remove: function() {
        App.vent.off(null, null, this);
        return Backbone.View.prototype.remove.call(this);
    },

    testAttributes: function() {
        return {
            type: 'delete-record'
        };
    }
});
App.Views.ConfirmDeleteRecord.mixin(App.Mixins.Test);
