import { TBoardId } from '../../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { SegmentBoardEvent, segmentTrackAction } from '../../../../../../../../../middlewares/segment';
import { boardSetDescription } from '../../../../../../../../../rest/effects/board/boardSetDescription';

export const onCtrlEnter = (
    boardId: TBoardId,
    description: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        dispatch(segmentTrackAction(SegmentBoardEvent.DESCRIPTION_CTRL_ENTER));
        dispatch(boardSetDescription(boardId, description));
    };
    return action;
};
