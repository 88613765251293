import { IApplicationState, TCardId } from '../../../../../../../../types/types';
import { createDeepEqualSelector } from '../../../../../../helpers/memoizeHelper';
import {
    getCardChecklistsSorted
} from '../../../../../../../../store/model/checklists/checklists/selectors/getCardChecklistsSorted';
import { IChecklist } from '../../../../../../../../store/model/checklists/checklist/types';

type TgetCardChecklists = (
    state: IApplicationState,
    cardId: TCardId,
) => IChecklist[];

export const getCardChecklistsSortedCreateSelector = (
): TgetCardChecklists => createDeepEqualSelector(
    getCardChecklistsSorted,
    checklists => checklists
);
