import { connect } from 'react-redux';
import { IHelpPanelDispatchProps } from '../../containers/types';
import { HelpPanelContainer } from '../../containers/HelpPanelContainer';
import { onHelpMount } from './events/onHelpMount';
import { onClickAccessibility } from './events/onClickAccessibility';

const mapDispatchToProps = (
    dispatch: any
): IHelpPanelDispatchProps => {
    return {
        onClickAccessibility: () => dispatch(onClickAccessibility()),
        onHelpMount: () => dispatch(onHelpMount()),
    }
};

export const HelpPanelContainerHOC = connect(
    null,
    mapDispatchToProps
)(HelpPanelContainer);
HelpPanelContainerHOC.displayName = 'HelpPanelHOC';
