import { IApplicationState } from '../../../../types/types';
import { TBoardId } from '../../../../types/types';
import { IOrderType } from '../store/listViewBoard/types';
import { getListViewBoard } from './getListViewBoard';

export const getOrderType = (
    state: IApplicationState,
    boardId: TBoardId
): IOrderType => {
    const orderType = getListViewBoard(state, boardId).orderType;
    return {...orderType};
}
