import { AT_AB_TESTS_VALUE_SET, IABTestsValueSet } from './types';
import { IABTestState } from '../types';

export const abTestsValueSet = (
    abTests: IABTestState
): IABTestsValueSet => {
    return {
        type: AT_AB_TESTS_VALUE_SET,
        abTests
    }
};
