import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState, TCardId } from '../../../../types/types';
import { getCard } from '../../../../store/model/selectors/getCard';
import { getCardEpicsForDueDateChange } from '../../../../store/model/selectors/getCardEpicsForDueDateChange';
import { cardsRestPatch } from './api/helper/cardsRestPatch';
import { ICards } from '../../../../store/model/cards/types';

export const cardToggleMilestone = (
    cardId: TCardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const card = getCard(state, cardId);
        if (!card) return;

        const isMilestone = !card.isMilestone;
        let cards: ICards = {
            [cardId]: {
                isMilestone
            }
        }

        if (isMilestone && card.dueDate) {
            cards[cardId].dueDate = null;
            cards = getCardEpicsForDueDateChange(getState(), card.epicId, cards);
        }
        return dispatch(cardsRestPatch(cards));
    };
    return action;
};
