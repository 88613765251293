import {connect} from 'react-redux';
import {IHelpPanelReleaseNotesEvents} from '../../components/HelpPanelReleaseNotes/types';
import {HelpPanelReleaseNotes} from '../../components/HelpPanelReleaseNotes/HelpPanelReleaseNotes';
import {onClick} from './events/onClick';

const mapDispatchToProps = (
    dispatch: any
): IHelpPanelReleaseNotesEvents => {
    return {
        onClick: () => dispatch(onClick())
    }
};

export const HelpPanelReleaseNotesHOC = connect(
    null,
    mapDispatchToProps
)(HelpPanelReleaseNotes);

HelpPanelReleaseNotesHOC.displayName = 'HelpPanelReleaseNotesHOC';
