import * as React from 'react';
import { ITrialExtendDialogProps } from './types';
import { Button, Input, Icon, LoaderBlock } from 'kui';
import './_TrialExtendDialog.scss';
import { TRIAL_EXTEND_TITLE, TRIAL_EXTEND_LABEL, TRIAL_EXTEND_SUBMIT } from './constants';

export function TrialExtendDialog ({
    onClose,
    onSubmit
}: ITrialExtendDialogProps) {
    const [isDisabled, setDisabled] = React.useState(true);
    const [isLoad, setLoad] = React.useState(false);
    const [value, setValue] = React.useState('');

    function onChange (event: React.FormEvent) {
        if (isLoad) return;

        const target = event.target as HTMLInputElement;
        const isEmpty = !target.value;
        setDisabled(isEmpty);
        setValue(target.value);
    }

    function onSubmitHandler () {
        if (isDisabled) return;

        onSubmit(value);
        setDisabled(true);
        setLoad(true);
    }

    return (
        <div
            className={'trial-extend__dialog'}
        >
            <Button
                className={'trial-extend__back'}
                variant={'icon'}
                onClick={onClose}
            >
                <Icon size={24} xlink={'back'} />
            </Button>
            <div
                className={'trial-extend__title'}
            >
                {TRIAL_EXTEND_TITLE}
            </div>
            <div
                className={'trial-extend__text'}
            >
                {TRIAL_EXTEND_LABEL}
            </div>
            <Input
                className={'trial-extend__input'}
                onChange={onChange}
            />
            <Button
                className={'trial-extend__dialog-submit'}
                disabled={isDisabled}
                size={'large'}
                onClick={onSubmitHandler}
            >
                {TRIAL_EXTEND_SUBMIT}
            </Button>
            {isLoad &&
                <LoaderBlock />
            }
        </div>
    );
}
