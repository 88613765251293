import { IApplicationState } from '../../../../../../../types/types';
import { IGoogleDrive } from '../../types';
import { getAuthUser } from '../../../../../../../store/model/authUser/selectors/getAuthUser';

export const isAllowCreateBoard = (
    state: IApplicationState,
    drive: IGoogleDrive): boolean => {
    const allowCreateBoard = getAuthUser(state).allowCreateBoard;
    if (!allowCreateBoard && drive.allowedAction.allowCreateBoard) {
        return allowCreateBoard;
    }
    return drive.allowedAction.allowCreateBoard;
};
