import * as React from 'react';
import { createRoot, Root } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import { AccessibilityStatementHOC } from './hocs/AccessibilityStatementHOC/AccessibilityStatementHOC';

export class AccessibilityStatementView {
    root: Root;
    store: Store;

    constructor (store: Store) {
        let el = document.querySelector('.accessibility-statement-placeholder');
        if (!el) {
            el = document.createElement('div');
            el.className = 'accessibility-statement-placeholder';
            document.body.append(el);
        }
        this.store = store;
        this.root = createRoot(el!);
        this.render();
    }

    render() {
        this.root.render(
            <Provider store={this.store}>
                <AccessibilityStatementHOC />
            </Provider>
        );
    }

    remove() {
        this.root.unmount();
    }
}
