import { getRestHeadersPatch } from './../../../../../rest/helpers/getRestHeadersHelper';
import { ActionCreator } from 'redux';
import { ThunkAction } from '../../../../../types/actions';
import { IFilter } from '../store/filter/types';
import { fetchHandler } from '../../../../../util/fetchHandler';
import { IRestFilter } from '../rest/types';
import Util from '../../../../../util/util';
import { getSavedFilterFromRest } from '../helpers/getSavedFilterFromRest';

export const patchFilter: ActionCreator<ThunkAction> = (
    filterId: number,
    editedFilter: IFilter
) => {
    const action = () => {
        return fetchHandler<IRestFilter>(
            Util.getApiUrl(`/rest/dashboards/filters/${ filterId }`),
            {
                ...getRestHeadersPatch(),
                body: JSON.stringify(editedFilter)
            }
        )
            .then((filterRest) => {
                    const filter = getSavedFilterFromRest(filterRest);
                    return Promise.resolve(filter);
                }
            )
            .catch(() => Promise.reject());
    };
    return action;
};
