import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState, TCardId } from '../../../../types/types';
import { ICardMeta } from '../../../../store/model/card/types/ICardMeta';
import { ICardBatch } from './api/types';
import { cardPatchRestBatch } from './api/helper/cardPatchRestBatch';

export const cardUpdateMetaBatch = (
    meta: Partial<ICardMeta>,
    cardBatch: ICardBatch = {card: {}, callbacks: []}
) => {
    cardBatch.card = {
        ...cardBatch.card,
        meta: {
            ...(cardBatch.card.meta || {}),
            ...meta
        }
    };
    return cardBatch;
};

export const cardUpdateMeta = (
    cardId: TCardId,
    meta: Partial<ICardMeta>,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const cardBatch = cardUpdateMetaBatch(meta);
        return cardPatchRestBatch(cardId, dispatch, cardBatch);
    };
    return action;
};
