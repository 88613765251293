import * as React from 'react';
import './_HelpPanelContainer.scss';
import { IHelpPanelProps } from './types';
import {
    HELP_PANEL_LINKS_COOKIE,
    HELP_PANEL_LINKS_PRIVACY_POLICY,
    HELP_PANEL_LINKS_TERMS_CONDITIONS,
    HELP_PANEL_LINK_ACCESSIBILITY,
} from '../constants';
import { EFeedbackFormMode } from '../../../dialogs/feedback';
import { HelpPanelLinksComponent } from '../components/HelpPanelLinks/HelpPanelLinksComponent';
import { FeedbackContainerHocLazy } from 'app/view/react_components/dialogs/feedback/lazy';

export function HelpPanelContainer ({
    onClickAccessibility,
    onHelpMount,
}: IHelpPanelProps) {

    function onClickAccessibilityHandler (e: React.MouseEvent) {
        e.preventDefault();
        onClickAccessibility();
    }

    React.useEffect(() => {
        onHelpMount();
    }, []);

    return (
        <div className={'help-panel__container'}>
            {/*<HelpPanelHeaderHOC />*/}
            <div className={'help-panel__content'}>
                {/*<FeedbackContainerHocLazy />
                <HelpPanelLinksComponent />*/}
                <div className={`ab_feedback ab_feedback`}>
                    <FeedbackContainerHocLazy mode={EFeedbackFormMode.INLINE}/>
                    <HelpPanelLinksComponent />
                </div>
            </div>
            <div className={'help_panel__footer'}>
                <a href={'https://www.kanbanchi.com/privacy-policy'} target="_blank">{HELP_PANEL_LINKS_PRIVACY_POLICY}</a>
                {' '}
                <a href={'https://www.kanbanchi.com/terms-conditions'} target="_blank">{HELP_PANEL_LINKS_TERMS_CONDITIONS}</a>
                {' '}
                <a href={'https://www.kanbanchi.com/cookie-policy'} target="_blank">{HELP_PANEL_LINKS_COOKIE}</a>
                {' '}
                <a href={'/dashboard/accessibility-statement'} onClick={onClickAccessibilityHandler} >{HELP_PANEL_LINK_ACCESSIBILITY}</a>
            </div>
        </div>
    );
};
