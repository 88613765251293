import { IApplicationState, TThemeId } from 'app/types/types';
import { connect } from 'react-redux';
import { BoardCustomTheme } from '../../components/BoardCustomTheme/BoardCustomTheme';
import { IBoardCustomThemeEvents, IBoardCustomThemeFields } from '../../components/BoardCustomTheme/types';
import { IBoardCustomThemeHOCProps } from './types';
import { getBoardTheme } from 'app/store/model/board/selectors/getBoardTheme';
import { ILibTheme } from 'app/store/model/libThemes/types';
import { EThemeBackgroundOption } from 'app/types/rest/IRestTheme';
import { onFileSelect } from './events/onFileSelect';
import { onBackgroundOptionChange } from './events/onBackgroundOptionChange';
import { getSectionType } from '../../../../../../aside_panel/boardDetails/selectors/getSectionType';
import { onClickDisallow } from './events/onClickDisallow';
import { MY_WORK_BOARD_ID } from '../../../../../../main/myWorkView/constants';
import { ESettingsSectionType } from 'app/view/react_components/base/components/SettingsSection/types';
import { isViewTypeWithBackground } from 'app/view/react_components/typeSwitcher/store/selectors/isViewTypeWithBackground';
import { onChangeSwitch } from 'app/view/react_components/base/components/Branding/CustomTheme/hocs/BoardCustomThemeHOC/events/onChangeSwitch';
import { onOptionChange } from 'app/view/react_components/base/components/Branding/CustomTheme/hocs/BoardCustomThemeHOC/events/onOptionChange';

const mapStateToProps = (
    state: IApplicationState,
    ownProps: IBoardCustomThemeHOCProps
): IBoardCustomThemeFields => {
    const { boardId } = ownProps;
    const theme: ILibTheme = getBoardTheme(state, boardId);
    let sectionType = boardId === MY_WORK_BOARD_ID ? null
        : getSectionType(state, boardId, null, null, 'allowChangeBackground');

    if (sectionType === ESettingsSectionType.DEFAULT &&
        !isViewTypeWithBackground(state, boardId)
    ) {
        sectionType = ESettingsSectionType.READONLY;
    }

    return {
        backgroundImage: theme && theme.userId > 0 && theme.backgroundImage,
        backgroundOption: theme && theme.backgroundOption || EThemeBackgroundOption.DEFAULT,
        sectionType,
        themeId: theme && theme.id,
    }
};

const mapDispatchToProps = (
    dispatch: any,
    ownProps: IBoardCustomThemeHOCProps
): IBoardCustomThemeEvents => {
    const { boardId } = ownProps;
    return {
        onBackgroundOptionChange: (themeId: TThemeId, backgroundOption: EThemeBackgroundOption) => dispatch(onBackgroundOptionChange(boardId, themeId, backgroundOption)),
        onClickDisallow: () => dispatch(onClickDisallow()),
        onChangeSwitch: (enabled) => dispatch(onChangeSwitch(boardId, enabled)),
        onOptionChange: (option) => dispatch(onOptionChange(boardId, option)),
        onFileSelect: (file: File, backgroundOption: EThemeBackgroundOption) => dispatch(onFileSelect(boardId, file, backgroundOption))
    }
};

export const BoardCustomThemeHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(BoardCustomTheme);

BoardCustomThemeHOC.displayName = 'BoardCustomThemeHOC';
