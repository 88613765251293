import { IApplicationState } from '../../../../../../../../types/types';
import { connect } from 'react-redux';
import { IPrefixesSectionHOCProps } from './types';
import { getIsUserBasicPlanOnly } from '../../../../../../../../store/model/authUser/selectors/getIsUserBasicPlanOnly';
import { getCardNumberByBoardId } from '../../store/cardNumbers/selectors/getCardNumberByBoardId';
import { PrefixesSection } from '../../components/PrefixesSection/PrefixesSection';
import { onChange } from './events/onChange';
import { IPrefixesSectionEvents, IPrefixesSectionFields } from '../../components/PrefixesSection/types';
import { onRemove } from './events/onRemove';
import { onShowSnackbar } from './events/onShowSnackbar';
import { getCardsWithCardNumber } from './selectors/getCardsWithCardNumber';
import { onClose } from './events/onClose';

const mapStateToProps = (
    state: IApplicationState,
    { boardId }: IPrefixesSectionHOCProps
): IPrefixesSectionFields => {
    const { enabled: isEnabled } = getCardNumberByBoardId(state, boardId);
    const cardsWithPrefixes = getCardsWithCardNumber(state, boardId);
    return {
        isBasicFeature: getIsUserBasicPlanOnly(state),
        isCardsWithPrefixes: cardsWithPrefixes && !!cardsWithPrefixes.length,
        isEnabled,
    };
};

export const mapDispatchToProps = (
    dispatch: any,
    { boardId }: IPrefixesSectionHOCProps
): IPrefixesSectionEvents => {
    return {
        onChange: () => dispatch(onChange(boardId)),
        onCloseDeleteModal: () => dispatch(onClose()),
        onRemove: () => dispatch(onRemove(boardId)),
        onShowSnackbar: () => dispatch(onShowSnackbar(boardId))
    };
};

export const PrefixesSectionHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(PrefixesSection);

PrefixesSectionHOC.displayName = 'PrefixesSectionHOC';
