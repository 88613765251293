import * as React from 'react';
import './_BoardAttachmentsHeader.scss';
import { IBoardAttachmentsHeaderProps } from './types';
import { CLASS_BOARD_ATTACHMENTS } from '../../TabAttachments/components/TabAttachments/constants';
import { Button, ButtonsSegmented, Icon, Input } from 'kui';
import {
    BOARD_ATTACHMENTS_GRID_VIEW_TOOLTIP,
    BOARD_ATTACHMENTS_LIST_VIEW_TOOLTIP,
    BOARD_ATTACHMENTS_TABS
} from './constants';
import { getAsidePanelTooltip } from 'app/view/react_components/aside_panel/asidePanel/helpers/getAsidePanelTooltip';

export const BoardAttachmentsHeader = ({
    tab,
    searchQuery,
    isGridView,
    onSearchChange,
    onSearchClick,
    onTabChange,
    onTabClick,
    onViewTypeChange
}: IBoardAttachmentsHeaderProps) => {
    const className = CLASS_BOARD_ATTACHMENTS + '-header';
    const classActions = className + '__actions';
    const classButton = classActions + '-button';
    const classSearch = classActions + '-search';
    const classOpen = classSearch + '--open';

    const onInputChange = (e: React.SyntheticEvent) => {
        const { value } = e.target as HTMLInputElement;
        onSearchChange(value);
    };

    const onKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            onSearchChange(null);
            const panel = document.querySelector('.' + CLASS_BOARD_ATTACHMENTS) as HTMLElement;
            if (panel) {
                panel.focus();
            }
        }
    };

    const handleSearchClick = () => {
        if (!searchQuery) {
            onSearchClick();
        }
    };

    return (
        <div className={className}>
            <ButtonsSegmented
                active={BOARD_ATTACHMENTS_TABS.findIndex(item => item.value === tab)}
                onChange={(index) => onTabChange(BOARD_ATTACHMENTS_TABS[index].value)}
            >
                {
                    BOARD_ATTACHMENTS_TABS.map(tab => (
                        <Button key={tab.value} onClick={() => onTabClick(tab.value)}>
                            {tab.title}
                        </Button>
                    ))
                }
            </ButtonsSegmented>
            <div className={classActions}>
                <Input
                    className={`
                        ${classSearch}
                        ${searchQuery ? classOpen : ''}
                    `}
                    onClick={handleSearchClick}
                    autosize={false}
                    variant={'search'}
                    value={searchQuery}
                    onChange={onInputChange}
                    onKeyDown={onKeyDown}
                    isClearable
                />
                <Button
                    className={classButton}
                    key={classButton + isGridView} // перерисовать тултип
                    variant={'icon'}
                    onClick={() => onViewTypeChange(!isGridView)}
                    tooltip={getAsidePanelTooltip({
                        isNoWrap: true,
                        value: isGridView ? BOARD_ATTACHMENTS_LIST_VIEW_TOOLTIP : BOARD_ATTACHMENTS_GRID_VIEW_TOOLTIP
                    })}
                >
                    <Icon xlink={isGridView ? 'list-view' : 'grid-view-24'} size={24} />
                </Button>
            </div>
        </div>
    );
}
