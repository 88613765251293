'use strict';
import * as _ from 'underscore';
import { getAuthUser } from '../store/model/authUser/selectors/getAuthUser';
import { getAppState } from '../store/configureStore';

App.Helpers.StartupProcessor = {

    init: function() {
        this.detectMac();
        this.detectScroll();
        this.detectCriOS();
        this.showMessages(); // about payment status
        this.initUserBehaviorAnalyser();
    },

    params: function() {
        var queryString = {};
        var query = window.location.search.substring(1);
        var vars = query.split("&");
        var pair;
        var arr;

        for (var i = 0, len = vars.length; i < len; i++) {
            pair = vars[i].split("=");
            // If first entry with this name
            if (typeof queryString[pair[0]] === "undefined") {
                queryString[pair[0]] = pair[1];
            // If second entry with this name
            } else if (typeof queryString[pair[0]] === "string") {
                arr = [ queryString[pair[0]], pair[1] ];
                queryString[pair[0]] = arr;
            // If third or later entry with this name
            } else {
                queryString[pair[0]].push(pair[1]);
            }
        } 
        return queryString;
    },

    showMessages: function() {
        var params = this.params();
        if (params.paypalStatus || params.chargebeeStatus) {
            if (params.paypalStatus === 'success' || params.chargebeeStatus === 'success') {
                App.controller.showAlert({ message: Messages.getText('popup.payment.verified') });
                App.controller.trackEvent(Messages.getText('ga.category.payment'), 
                                          Messages.getText('ga.action.payment_completed'));
                App.statHelper.sendUserStat(_.object([App.Classes.StatHelper.PAYMENT_COMPLETED_COUNT,App.Classes.StatHelper.PAYMENT_COMPLETED_DATE],['+1',App.Util.nowTrimTimestampGMT()]));
            } else if (params.paypalStatus == 'cancelled' || params.chargebeeStatus == 'cancelled') {
                App.controller.showAlert({ message: Messages.getText('popup.payment.cancelled'), type: 'warning' });
                App.controller.trackEvent(Messages.getText('ga.category.payment'), 
                                          Messages.getText('ga.action.payment_cancelled'));
                App.statHelper.sendUserStat(_.object([App.Classes.StatHelper.PAYMENT_CANCELED_COUNT,App.Classes.StatHelper.PAYMENT_CANCELED_DATE],['+1',App.Util.nowTrimTimestampGMT()]));
            }
        }
        if (params.promoCodeApplied == 'true') {
            App.controller.showAlert({ message: Messages.getText('popup.payment.promo_code.success') });
        }
        if (params.unsubscribed == 'true') {
        	if(params.code){
        		App.statHelper.sendUserStat(_.object([params.code],[false]), {
                    hash:params.h
                });
        		App.controller.showAlert({ message: Messages.getText('popup.unsubscribe.notification.success') });
        	}
        	if(params.hash){
        		App.controller.showAlert({ message: Messages.getText('popup.unsubscribe.dashboard.success') });
        	}
        }
    },

    detectTouch: function() {
        const isTouch = ('ontouchstart' in window) ||
            (navigator.maxTouchPoints > 0) ||
            (navigator.msMaxTouchPoints > 0);
        if (isTouch) {
            document.documentElement.classList.add('touch');
        } else {
            document.documentElement.classList.add('no-touch');
        }
        return isTouch;
    },

    detectMac: function() {
        if (navigator.platform.indexOf("Mac") === -1) {
            document.documentElement.classList.add('no-mac');
        } else {
            document.documentElement.classList.add('mac');
        }
    },

    detectScroll: function() {
        const w = 100;
        const div = document.createElement('div');
        document.body.append(div);
        div.style.overflow = 'scroll';
        div.style.width = w + 'px';
        const div1 = document.createElement('div');
        div.append(div1);
        const scrollWidth = w - div1.offsetWidth;
        window.scrollWidth = scrollWidth;
        const scrollClass = scrollWidth ? 'scroll-visible' : 'scroll-hidden';
        div.remove();
        document.documentElement.classList.add(scrollClass);
    },

    detectCriOS: function() {
        if (this.detectTouch()) {
            if (navigator.userAgent.includes("iPad")) {
                if (navigator.userAgent.includes("CriOS")) {
                    document.documentElement.classList.add('crios');
                } else {
                    document.documentElement.classList.add('ipad');
                }
            } else if (navigator.userAgent.includes("iPhone")) {
                document.documentElement.classList.add('iphone');
            }
        }
    },

    initUserBehaviorAnalyser: function() {
            var now = Math.floor(new Date().getTime() / 1000);
            var userCreatedDate = getAuthUser(getAppState()).createDateTimeStamp;
            var oneMonth = 60 * 60 * 24 * 30;

            if (userCreatedDate) {
                if (now - parseInt(parseFloat(userCreatedDate)) < oneMonth) {
                    var analyser = new App.Views.UserBehaviorAnalyser({userCreatedDate: userCreatedDate});
                }
            }
    }
};

App.Views.UserBehaviorAnalyser = Backbone.View.extend({

    initialize: function(options) {
        this.userCreatedDate = options.userCreatedDate;
        App.vent.on(App.vent['gapi:authorized'], _.debounce(this.userLoggedIn, 2000), this);
        App.vent.on(App.vent['dashboard:open'], this.dashboardOpened, this);
        App.vent.on(App.vent['stat:cardCreated'], this.cardCreated, this);
        App.vent.on(App.vent['stat:cardMoved'], this.cardMoved, this);
        App.vent.on(App.vent['stat:listCreated'], this.listCreated, this);
        App.vent.on(App.vent['stat:dashboardCreated'], this.dashboardCreated, this);
        App.vent.on(App.vent['stat:dashboardCreatedFromDrive'], this.dashboardCreatedFromDrive, this);
        this.logins = parseInt(this.getParam('logins'));
        this.cards = parseInt(this.getParam('cards'));
        this.now = Math.floor(new Date().getTime() / 1000);
        this.oneWeek = 60 * 60 * 24 * 7;
        this.oneMonth = 60 * 60 * 24 * 30;
    },

    userLoggedIn: function() {
        this.logins = this.logins ? this.logins + 1 : 1;
        this.setParam('logins', this.logins);

        if (this.now - parseInt(parseFloat(this.userCreatedDate)) < this.oneMonth) {
            if (this.now - parseInt(parseFloat(this.userCreatedDate)) < this.oneWeek) {
                if (this.logins === 2) {
                    App.controller.trackEvent(Messages.getText('ga.category.new_user'),
                                              Messages.getText('ga.action.new_user.first_week.second_login'));
                } else if (this.logins === 3) {
                    App.controller.trackEvent(Messages.getText('ga.category.new_user'),
                                              Messages.getText('ga.action.new_user.first_week.3_logins'));
                }
            }
            if (this.logins === 5) {
                App.controller.trackEvent(Messages.getText('ga.category.new_user'),
                                          Messages.getText('ga.action.new_user.first_month.5_logins'));
            } else if (this.logins === 10) {
                App.controller.trackEvent(Messages.getText('ga.category.new_user'),
                                          Messages.getText('ga.action.new_user.first_month.10_logins'));
            }
        }
    },

    cardCreated: function() {
        this.cards = this.cards ? this.cards + 1 : 1;
        this.setParam('cards', this.cards);

        if (this.logins === 1) {
            if (this.cards === 1) {
                App.controller.trackEvent(Messages.getText('ga.category.new_user'),
                                          Messages.getText('ga.action.new_user.first_visit.first_card_created'));
            } else if (this.cards === 10) {
                App.controller.trackEvent(Messages.getText('ga.category.new_user'),
                                          Messages.getText('ga.action.new_user.first_visit.10_cards_created'));
            }
        }
    },

    cardMoved: function() {
        if (this.logins === 1 && !this.getParam('cardMoved')) {
            App.controller.trackEvent(Messages.getText('ga.category.new_user'),
                                      Messages.getText('ga.action.new_user.first_visit.first_card_moved'));
            this.setParam('cardMoved', 1);
        }
    },

    listCreated: function() {
        if (this.logins === 1 && !this.getParam('listCreated')) {
            App.controller.trackEvent(Messages.getText('ga.category.new_user'),
                                      Messages.getText('ga.action.new_user.first_visit.first_list_created'));
            this.setParam('listCreated', 1);
        }
    },

    dashboardCreated: function() {
        if (this.logins === 1 && !this.getParam('dashboardCreated')) {
            App.statHelper.sendUserStat(_.object([App.Classes.StatHelper.FIRST_VISIT_DASHBOARD_CREATED],[App.Util.nowTrimTimestampGMT()]));
            App.controller.trackEvent(
                Messages.getText('ga.category.new_user'),
                Messages.getText('ga.action.new_user.first_visit.dashboard_created_from_popup')
            );
            this.setParam('dashboardCreated', 1);
        }
    },

    dashboardCreatedFromDrive: function() {
        if (this.logins === 1) {
            // from drive
            App.controller.trackEvent(Messages.getText('ga.category.new_user'),
                                      Messages.getText('ga.action.new_user.first_visit.dashboard_created_from_drive'));
        }
    },

    dashboardOpened: function(ids) {
        if (this.logins === 2 && !this.getParam('dashboardOpened')) {
            // from popup
            App.controller.trackEvent(
                Messages.getText('ga.category.new_user'),
                Messages.getText('ga.action.new_user.second_visit.dashboard_opened_from_popup')
            );
            this.setParam('dashboardOpened', 1);
        }
    },

    setParam: function(name, value) {
        var stat = localStorage.getItem('KanbanchiStat');
        var data = JSON.parse(stat) || {};
        data[name] = value;
        localStorage.setItem('KanbanchiStat', JSON.stringify(data));
    },

    getParam: function(name) {
        var stat = localStorage.getItem('KanbanchiStat');
        var value = false;
        var data;

        if (stat) {
            data = JSON.parse(stat);
            value = data.hasOwnProperty(name) ? data[name] : null;
        }
        return value;
    }

});
