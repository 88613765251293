import {IApplicationState, TCardId} from '../../../types/types';
import {getCard} from './getCard';
import {TStatus} from '../../../types/model';
import { IRestRelatedCard } from '../../../types/rest/IRestRelatedCard';

export const getCardRelatedCardsActive = (
    state: IApplicationState,
    cardId: TCardId
): IRestRelatedCard[] => {
    const card = getCard(state, cardId);
    if (card &&
        card.relatedCards
    ) {
        return card.relatedCards.filter( (relatedCard) => relatedCard.status === TStatus.STATUS_ACTIVE);
    }
    return [];
}
