import { getDriveDocsByCardId } from 'app/store/model/driveDocs/selectors/getDriveDocsByCardId';
import { connect } from 'react-redux';
import { IApplicationState } from 'app/types/types';
import { ICardAttachmentsPanelButtonHOCProps } from './types';
import {
    ICardAttachmentsPanelButtonEvents,
    ICardAttachmentsPanelButtonFields
} from '../../../components/CardAttachmentsPanelButton/types';
import { CardAttachmentsPanelButton } from '../../../components/CardAttachmentsPanelButton/CardAttachmentsPanelButton';
import { CARD_SECTION_ATTACHMENTS } from '../../../constants';
import { getPanelButtonShow } from '../../../../selectors/getPanelButtonShow';
import {EAsidePanelProperty} from '../../../../../asidePanel/components/AsidePanel/types';
import {getBoardIsCardDefaultProperty} from 'app/store/model/board/selectors/getBoardIsCardDefaultProperty';
import { onSelectClick } from '../CardAttachmentsSectionHOC/events/onSelectClick';
import { onClick } from '../../CardAttachmentsPanelButtonHOC/events/onClick';
import { EAuthUserPlatformType } from 'app/types/rest/IRestAuthUser';
import { onUploadClick } from '../CardAttachmentsSectionHOC/events/onUploadClick';
import { isMicrosoftPersonalAccount } from 'app/store/model/authUser/selectors/isMicrosoftPersonalAccount';

const mapStateToProps = (
    state: IApplicationState,
    { boardId, cardId, isShowTooltip }: ICardAttachmentsPanelButtonHOCProps
): ICardAttachmentsPanelButtonFields => {
    const isDefaultProperty = getBoardIsCardDefaultProperty(state, boardId, EAsidePanelProperty.CARD_ATTACHMENTS);
    const isShow = getPanelButtonShow(state, boardId, cardId);
    const microsoftPersonalAccount = isMicrosoftPersonalAccount(state);
    const tooltip = isShowTooltip
        ? CARD_SECTION_ATTACHMENTS
        : null;
    return {
        boardId,
        cardId,
        driveDocCount: getDriveDocsByCardId(state, cardId).length,
        isDefaultProperty,
        isShow,
        isShowSelectFile: !microsoftPersonalAccount,
        platformType: EAuthUserPlatformType.MICROSOFT,
        tooltip,
    }
};

const mapDispatchToProps = (
    dispatch: any,
    {boardId, cardId, onClick: ownOnClick}: ICardAttachmentsPanelButtonHOCProps
): ICardAttachmentsPanelButtonEvents => {
    return {
        onClick: () => dispatch(onClick(boardId, cardId, ownOnClick)),
        onSelectClick: (onClose) => dispatch(onSelectClick(cardId, onClose)),
        onUploadClick: (onStart, onProgress, onFinish) => dispatch(onUploadClick(cardId, onStart, onProgress, onFinish)),
    }
};

export const CardAttachmentsPanelButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardAttachmentsPanelButton);

CardAttachmentsPanelButtonHOC.displayName = 'CardAttachmentsPanelButtonHOC';
