import { ThunkAction, Dispatch } from '../../../../../types/actions';
import { resetAction } from '../store/signIn/actions/resetAction';

export const signInReset = (
): ThunkAction => {
    const action = (dispatch: Dispatch) => {
        dispatch(resetAction());
    };
    return action;
};
