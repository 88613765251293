import * as React from 'react';
import { AsidePanelContext } from '../../../../../asidePanel/components/AsidePanel/constants';
import { ICardCustomPropertiesSectionProps } from './types';
import { CardCustomPropertiesSectionHOC } from '../../hocs/CardCustomPropertiesSectionHOC/CardCustomPropertiesSectionHOC';

export function CardCustomPropertiesSection ({
}: ICardCustomPropertiesSectionProps) {
    const { boardId } = React.useContext(AsidePanelContext);

    return (
        <CardCustomPropertiesSectionHOC
            boardId={boardId}
        />
    );
};
