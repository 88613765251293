import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../../types/types';
import { LinksToCardsPanelButton } from '../../components/LinksToCardsPanelButton/LinksToCardsPanelButton';
import { ILinksToCardsPanelButtonEvents, ILinksToCardsPanelButtonFields } from '../../components/LinksToCardsPanelButton/types';
import { onClick } from './events/onClick';
import { ILinksToCardsPanelButtonHOCProps } from './types';
import { getBoard } from '../../../../../../../../store/model/selectors/getBoardById';

const mapStateToProps = (
    state: IApplicationState,
    { boardId }: ILinksToCardsPanelButtonHOCProps
): ILinksToCardsPanelButtonFields => {
    const { enableOpenGraphInfo: indicatorBoolean } = getBoard(state, boardId);
    return {
        indicatorBoolean,
        tooltip: null,
    }
};

const mapDispatchToProps = (
    dispatch: any,
    {onClick: ownOnClick}: ILinksToCardsPanelButtonHOCProps
): ILinksToCardsPanelButtonEvents => {
    return {
        onClick: () => dispatch(onClick(ownOnClick))
    }
};

export const LinksToCardsPanelButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(LinksToCardsPanelButton);

LinksToCardsPanelButtonHOC.displayName = 'LinksToCardsPanelButtonHOC';
