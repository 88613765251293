import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { ICardHOCProps } from '../CardHOC/types';
import { getUserTimeFormat12 } from '../../../../../../../store/model/authUser/selectors/getUserTimeFormat12';
import { ICardAssignFields } from '../../components/CardAssign/types';
import { CardCreated } from '../../components/CardCreated/CardCreated';

const mapStateToProps = (
    state: IApplicationState,
    { card }: ICardHOCProps
): ICardAssignFields => {
    return {
        card,
        isUserTimeFormat12: getUserTimeFormat12(state)
    }
}

export const CardCreatedHOC = connect(
    mapStateToProps,
    null
)(CardCreated);
CardCreatedHOC.displayName = 'CardCreatedHOC';
