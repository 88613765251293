import { AsidePanelActionsButton } from '../../../../..';
import * as React from 'react';
import { ICardCustomPropertiesPanelButtonProps } from './types';
import { CLASS_BOARD_DETAILS_SECTION } from '../../../../constants';
import { BOARD_SECTION_CARD_CUSTOM_PROPERTIES_BUTTON } from '../../constants';

export function CardCustomPropertiesPanelButton ({
    indicatorNumber,
    isBasicFeature,
    onClick
}: ICardCustomPropertiesPanelButtonProps) {

    return (
        <AsidePanelActionsButton
            children={null}
            indicatorNumber={indicatorNumber}
            isBasicFeature={isBasicFeature}
            sectionSelector={`.${CLASS_BOARD_DETAILS_SECTION}--card-custom-properties`}
            title={BOARD_SECTION_CARD_CUSTOM_PROPERTIES_BUTTON}
            onClick={onClick}
        />
    );
}
