import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState } from '../../../../../../../types/types';
import { isOpenSetAction } from '../../../store/navigationPanel/actions/isOpenSetAction';
import { anchorMobileClickedSegmentEvent } from '../../../effects/segmentEvents';
import { root } from '../../../../../../../store/constants';
import { closeTemplates } from '../../../../../aside_panel/asidePanel/effects/closeTemplates';

export const onAnchorClick = (): ThunkAction => {
    return (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const leftAsideOpened = root.App.controller.isLeftAsideOpened();
        if(leftAsideOpened) {
            dispatch(closeTemplates())
        }
        dispatch(isOpenSetAction(true));
        dispatch(anchorMobileClickedSegmentEvent());
    };
};
