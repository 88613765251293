import {ActionCreator} from 'redux';
import {Dispatch, ThunkAction} from '../../../../../../../types/actions';
import {IGetState} from '../../../../../../../types/types';
import {isOpenSetAction} from '../../../store/navigationPanel/actions/isOpenSetAction';
import { anchorClickedSegmentEvent } from '../../../effects/segmentEvents';

export const onAnchorClick: ActionCreator<ThunkAction> = (
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(isOpenSetAction(true));
        dispatch(anchorClickedSegmentEvent());
    };
    return action;
};
