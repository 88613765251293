import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { DependenciesSearchSelect } from '../../components/DependenciesSearchSelect/DependenciesSearchSelect';
import {
    IDependenciesSearchSelectEvents,
    IDependenciesSearchSelectFields,
} from '../../components/DependenciesSearchSelect/types';
import { IDependenciesSearchSelectProps } from './types';
import { getAvailableDependenciesCreateSelector } from '../../selectors/getAvailableDependencies';
import { onAddPredecessor } from './events/onAddPredecessor';

const mapStateToProps = () => {
    const getAvailableDependencies = getAvailableDependenciesCreateSelector();

    const _mapStateToProps = (
        state: IApplicationState,
        { cardId, isOpened }: IDependenciesSearchSelectProps,
    ): IDependenciesSearchSelectFields => {
        const availableCards = getAvailableDependencies(state, cardId);

        return {
            availableCards,
            isOpened,
        }
    };
    return _mapStateToProps;
};

const mapDispatchToProps = (
    dispatch: any,
    { cardId, onAddClosed }: IDependenciesSearchSelectProps,
): IDependenciesSearchSelectEvents => {
    return {
        onAdd: (predecessorId) => dispatch(onAddPredecessor(cardId, predecessorId)),
        onAddClosed,
    }
};

export const DependenciesSearchSelectHOC = connect(
    mapStateToProps,
    mapDispatchToProps,
)(DependenciesSearchSelect);

DependenciesSearchSelectHOC.displayName = 'DependenciesSearchSelectHOC';
