import { ICollaborator } from '../types';

export const AT_REMOVE = 'COLLABORATORS_REMOVE';
export const AT_UPDATE = 'COLLABORATORS_UPDATE';
export const AT_SET_FIRST = 'COLLABORATORS_CLICK';

export interface IRemoveAction {
    type: typeof AT_REMOVE;
    collaborator: ICollaborator;
}

export interface IUpdateAction {
    type: typeof AT_UPDATE;
    collaborator: ICollaborator;
}

export interface ISetFirstAction {
    type: typeof AT_SET_FIRST;
    collaborator: ICollaborator;
}

export type TCollaboratorsAction = (
    IRemoveAction |
    IUpdateAction |
    ISetFirstAction
);
