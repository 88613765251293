import {fetchHandler} from './fetchHandler';
import {getRestHeadersGet} from 'app/rest/helpers/getRestHeadersHelper';

export class PartLoader<T> {
    constructor() {
        this.processResult = [];
    }

    processResult: Array<T>;

    loadPart(
        url: string,
        cursor: string = '',
        progressCallback?: (count: number) => void,
        processHandler?: TPartLoaderProcessHandler<T>,
        init: RequestInit = getRestHeadersGet()
    ): Promise<Array<T>> {
        const fetchUrl = putParamToUrl(url, 'cursor', cursor);
        return fetchHandler<ILoadResult<T>>(fetchUrl, init)
            .then((result) => {
                if (processHandler) {
                    processHandler(result, this.processResult);
                } else {
                    result.objects.forEach((obj: T) => {
                        this.processResult.push(obj)
                    });
                }
                if (progressCallback) {
                    progressCallback(this.processResult.length);
                }
                if (result.cursor === '' || result.cancelled) {
                    return Promise.resolve(this.processResult);
                }
                return this.loadPart(url, result.cursor, progressCallback, processHandler);
            })
    }
}

export const getParamFromUrl = (
    url_string: string,
    key: string
): string => {
    const url = document.createElement('a');
    url.href = url_string;
    const urlSearch = url.search;
    if (!urlSearch.length) return null;
    const urlParams = urlSearch.substring(1).split('&');
    for (let i = 0; i < urlParams.length; i++) {
        const pair = urlParams[i].split('=');
        if (pair[0] === key) return pair[1];
    }
    return null;
};

export const putParamToUrl = (
    url_string: string,
    key: string,
    value: string
) => {
    const url = document.createElement('a');
    url.href = url_string;
    const urlSearch = url.search;
    const urlBase = url.protocol + '//' + url.host + url.pathname + '?';
    let urlParams: string[] = [];
    if (urlSearch.length) {
        urlParams = urlSearch.substring(1).split('&');
        for (let i = 0; i < urlParams.length; i++) {
            const pair = urlParams[i].split('=');
            if (pair[0] === key) {
                urlParams[i] = key + '=' + value;
                return urlBase + urlParams.join('&');
            }
        }
    }
    urlParams.push(key + '=' + value);
    return urlBase + urlParams.join('&');
};

export type ILoadResult<T = any> = {
    cursor: string,
    objects: Array<T>,
    cancelled?: boolean
} & {
    [prop: string]: any
}

export type TPartLoaderProcessHandler<T = any> = (loadResult: ILoadResult<T>, processResult: Array<T>) => void;
