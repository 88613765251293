import { IAsidePanelEvents, IAsidePanelFields } from '../../components/AsidePanel/types';
import { AsidePanel } from '../../components/AsidePanel/AsidePanel';
import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../types/types';
import { IAsidePanelMyWorkSettingsHOCProps } from './types';
import { getActiveTabIndexByTabKey } from './selectors/getActiveTabIndexByTabKey';
import { getAsidePanelIsMobile } from '../../store/selectors/getAsidePanelIsMobile';
import { onTabActive } from './effects/onTabActive';
import {
    CLASS_ASIDE_MY_WORK_SETTINGS,
    MY_WORK_SETTINGS_TABS
} from '../../../../main/myWorkView/myWorkSettingsPanel/constants';
import { onClose } from './events/onClose';
import { onResize } from '../AsidePanelHOC/events/onResize';
import { onResized } from '../AsidePanelHOC/events/onResized';

const mapStateToProps = (
    state: IApplicationState,
    { tabKey }: IAsidePanelMyWorkSettingsHOCProps
): IAsidePanelFields => {
    const tabs = MY_WORK_SETTINGS_TABS;
    const activeTabIndex = getActiveTabIndexByTabKey(state, tabs, tabKey);

    return {
        activeTabIndex,
        className: CLASS_ASIDE_MY_WORK_SETTINGS,
        isButtonsFixed: true,
        isMobile: getAsidePanelIsMobile(state),
        tabs
    }
};

const mapDispatchToProps = (
    dispatch: any,
    ownProps: IAsidePanelMyWorkSettingsHOCProps
): IAsidePanelEvents => {
    return {
        onTabActive: (activeTabIndex) => dispatch(onTabActive(activeTabIndex)),
        onClose: () => dispatch(onClose()),
        onResize: (width) => dispatch(onResize(width)),
        onResized: (width) => dispatch(onResized(width))
    }
};

export const AsidePanelMyWorkSettingsHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(AsidePanel);

AsidePanelMyWorkSettingsHOC.displayName = 'AsidePanelMyWorkSettingsHOC';
