import { ICards } from '../cards/types';
import { IApplicationState, TCardId } from '../../../types/types';
import { getCard } from './getCard';
import { calcCardProcessingPercent } from './calcCardProcessingPercent';
import { getCardProcessingUpdates } from './getCardProcessingUpdates';

export const getCardEpicsForProcessingPercentChange = (
    state: IApplicationState,
    epicId: TCardId,
    cards: ICards = {},
): ICards => {
    if (!epicId){
        return  cards;
    }
    const epic = getCard(state, epicId);
    if (!epic) return cards;
    const processingPercent = calcCardProcessingPercent(state, epicId, cards);
    if (epic.processingPercent !== processingPercent){
        cards = getCardProcessingUpdates(state, epicId, processingPercent, cards)
    }

    return cards;
}
