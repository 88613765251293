import * as React from 'react';
import './_CardBackgroundColor.scss';
import { CLASS_MY_WORK_CARD, CLASS_MY_WORK_CARD_CONTROL } from '../../../constants';
import { ICardBackgroundColorProps } from './types';
import { CARD_BACKGROUND_COLORS_PICKER_MATCH } from '../../../../../../../const';
import { Button, ButtonDropdown } from 'kui';
import { ColorDropdown } from '../../../../../base/components/Color/components/ColorDropdown/ColorDropdown';

export function CardBackgroundColor ({
    background,
    boardColors,
    colors,
    portalId,
    onChange,
    onLoadCard
}: ICardBackgroundColorProps) {
    const className = CLASS_MY_WORK_CARD + '__bg';
    const [isOpened, setOpened] = React.useState(null);
    const onClose = () => setOpened(false);
    return (
        <ButtonDropdown
            className={`
                ${className}
                ${!background ? className + '--no-color' : ''}
                ${isOpened ? className + '--opened' : ''}
                ${CLASS_MY_WORK_CARD_CONTROL}
            `}
            dropdownClassName={`${className + '-dropdown'} ${CLASS_MY_WORK_CARD_CONTROL}`}
            onOpen={() => setOpened(true)}
            beforeOpen={onLoadCard}
            onClose={onClose}
            opened={isOpened}
            portal
            portalId={portalId}
            directionHorizontal={'right'}
        >
            <Button className={className + '-button'}>
                <div
                    className={className + '-color'}
                    style={background ? { background : CARD_BACKGROUND_COLORS_PICKER_MATCH[background] || background } : {}}
                />
            </Button>
            <ColorDropdown
                color={background}
                colors={colors}
                boardColors={boardColors}
                onChange={(color) => {
                    onChange(color);
                    onClose();
                }}
            />
        </ButtonDropdown>
    );
};
