import { IRestCardAssigneeInfo } from '../../../../types/rest/IRestCardAssigneeInfo';
import { ThunkAction } from '../../../../types/actions';
import { TCardId } from '../../../../types/types';
import { REST_CARDS_ASSIGNED_TO_ME } from '../../../constants';
import { fetchHandler } from '../../../../util/fetchHandler';
import Util from '../../../../util/util';
import { getRestHeadersGet } from '../../../helpers/getRestHeadersHelper';

export const getRest = (
    cardId: TCardId
): ThunkAction => {
    const action = () => {
        return fetchHandler<IRestCardAssigneeInfo>(
            Util.getApiUrl(REST_CARDS_ASSIGNED_TO_ME + '/' + cardId), {
                ...getRestHeadersGet()
            }
        );
    };
    return action;
}
