import { getMessages } from '../../../../../../store/constants';
import { CLASS_POLL } from '../../../../base/components/Poll/components/constants';

export const PRICING_POLL_TITLE = getMessages().getText('pricing-poll.title');
export const PRICING_POLL_REVIEW_TITLE = getMessages().getText('pricing-poll.review.title');
export const PRICING_POLL_OPTIONS = [
    { label: getMessages().getText('pricing-poll.option.1') },
    { label: getMessages().getText('pricing-poll.option.2') },
    { label: getMessages().getText('pricing-poll.option.3') },
    { label: getMessages().getText('pricing-poll.option.4') },
    { label: getMessages().getText('pricing-poll.option.5') },
    { label: getMessages().getText('pricing-poll.option.6') },
    { label: getMessages().getText('pricing-poll.option.7') },
    { label: getMessages().getText('pricing-poll.option.8') },
    { label: getMessages().getText('pricing-poll.option.9') },
];

export const CLASS_PRICING_POLL = CLASS_POLL + '--pricing';
