import { IGetState, TBoardId, TCardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { copyLink } from '../../../../../../base/effects/copyLink';
import { SegmentCardEvent, SegmentCardOptions, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { SegmentCardSection } from '../../../../../../../../middlewares/segment';
import { SegmentCardSourceValue } from '../../../../../../../../middlewares/segment/trackEntities/cardEvents';

export const onClick = (
    cardId: TCardId,
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.BUTTONS_PANEL_BUTTON_CLICKED, {
            name: SegmentCardOptions.TARGET,
            value: SegmentCardSection.GET_LINK
        }));

        dispatch(segmentTrackAction(SegmentCardEvent.GET_LINK, {
            name: SegmentCardOptions.SOURCE,
            value: SegmentCardSourceValue.DETAILS
        }));
        dispatch(copyLink(cardId, boardId));
    };
    return action;
};
