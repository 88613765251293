import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { IGetState, TListId } from '../../../../../../../../../types/types';
import { activityScrollListIdSet } from '../../../../../../../main/kanbanView/store/actions/activityScrollListIdSet';

export const onScrollToList = (
    listId: TListId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(activityScrollListIdSet(listId));
        setTimeout(() => { // сброить, чтобы была возможность проскролится еще раз
            dispatch(activityScrollListIdSet(null));
        }, 0);
    };
    return action;
};
