import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { ICardHOCProps } from '../CardHOC/types';
import { getUserTimeFormat12 } from '../../../../../../../store/model/authUser/selectors/getUserTimeFormat12';
import { CardAssign } from '../../components/CardAssign/CardAssign';
import { ICardAssignFields } from '../../components/CardAssign/types';

const mapStateToProps = (
    state: IApplicationState,
    { card }: ICardHOCProps
): ICardAssignFields => {
    return {
        card,
        isUserTimeFormat12: getUserTimeFormat12(state)
    }
}

export const CardAssignHOC = connect(
    mapStateToProps,
    null
)(CardAssign);
CardAssignHOC.displayName = 'CardAssignHOC';
