import {INavigationPanelStatusSet} from './types';
import {AT_NAVIGATION_PANEL_TYPE_SET} from './constants';
import {ENavigationPanelTypes} from '../../../constants';

export const panelTypeSetAction = (
    panelType: ENavigationPanelTypes
): INavigationPanelStatusSet => ({
        type: AT_NAVIGATION_PANEL_TYPE_SET,
        panelType
    }
);
