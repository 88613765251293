import { ICardsCopyRestOptions } from './api/types';
import { IGetState, TListId } from '../../../types/types';
import { IRestCard } from '../../../types/rest/IRestCard';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { TStatus } from '../../../types/model';
import { sortCardsByOrderNumber } from '../../../store/model/helpers/sortCardsByOrderNumber';
import { sendCardStatWithIncrementForBoard } from '../../../helper/comet/stat_helper_ts';
import { IBoard } from '../../../store/model/board/types';
import { cardsCopyRest } from './api/cardsCopyRest';
import { listUpdateMinMaxOrderNumber } from './listUpdateMinMaxOrderNumber';
import { getListCardsByStatuses } from '../../../store/model/selectors/getListCardsByStatuses';

export const cardsCopy = (
    board: IBoard,
    fromListId: TListId,
    toListId: TListId,
    activeAndArchive: boolean = false,
    options: ICardsCopyRestOptions = {}
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const cardStatuses = activeAndArchive ?
            [TStatus.STATUS_ACTIVE, TStatus.STATUS_ARCHIVE, TStatus.STATUS_SERVICE_TEMPLATE] :
            [TStatus.STATUS_ACTIVE, TStatus.STATUS_SERVICE_TEMPLATE];
        const allCards = getListCardsByStatuses(state, fromListId, cardStatuses);

        allCards.sort(sortCardsByOrderNumber);
        const cardsToCopy = allCards.map(card => ({ id: card.id }));

        return dispatch(cardsCopyRest(toListId, cardsToCopy, options))
            .then((result: IRestCard[]) => {
                const activeCardsCount = allCards.filter(card => card.status === TStatus.STATUS_ACTIVE).length;
                dispatch(sendCardStatWithIncrementForBoard(board, toListId, activeCardsCount, 0));
                dispatch(listUpdateMinMaxOrderNumber(toListId, result.map(card => card.orderNumber)))
                return result;
            });
    };
    return action;
};
