import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState } from '../../../../types/types';
import { EDependencyTypes, TDependencyId } from '../../../../store/model/dependencies/dependency/types';
import { dependenciesPatch } from './api/helper/dependenciesPatch';
import { IDependencies } from '../../../../store/model/dependencies/dependencies/types';

export const setType = (
    dependencyId: TDependencyId,
    type: EDependencyTypes
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const dependencies: IDependencies = {
            [dependencyId]: {
                id: dependencyId,
                type
            }
        };

        return dispatch(dependenciesPatch(dependencies));
    };
    return action;
};
