import * as React from 'react';
import './_PushNotificationsSection.scss';
import { IPushNotificationsSectionProps } from './types';
import { AsidePanelContext } from '../../../../../asidePanel/components/AsidePanel/constants';
import { CLASS_BOARD_DETAILS, CLASS_BOARD_DETAILS_SECTION } from '../../../../constants';
import { SettingsSection } from '../../../../../../base/components/SettingsSection/SettingsSection';
import { BOARD_SECTION_PUSH_NOTIFICATIONS } from '../../constants';
import { PushNotificationsTogglerHOC } from '../../hocs/PushNotificationsTogglerHOC/PushNotificationsTogglerHOC';
import { PushNotificationsHOC } from '../../hocs/PushNotificationsHOC/PushNotificationsHOC';
import { PushNotificationsHelpButtonHOC } from '../../hocs/PushNotificationsHelpButtonHOC/PushNotificationsHelpButtonHOC';

export function PushNotificationsSection ({
    onDidMount
}: IPushNotificationsSectionProps) {
    const { boardId } = React.useContext(AsidePanelContext);
    const className = CLASS_BOARD_DETAILS_SECTION + ' ' + CLASS_BOARD_DETAILS_SECTION + '--notifications';
    const classButtons = CLASS_BOARD_DETAILS + '__push-title-buttons';

    const titleButton = (
        <div className={classButtons}>
            <PushNotificationsHelpButtonHOC/>
            <PushNotificationsTogglerHOC boardId={boardId} />
        </div>
    );

    React.useEffect(() => {
        onDidMount();
    }, []);

    return (
        <SettingsSection
            className={className}
            title={BOARD_SECTION_PUSH_NOTIFICATIONS}
            titleButton={titleButton}
        >
            <PushNotificationsHOC
                boardId={boardId}
            />
        </SettingsSection>
    );
};
