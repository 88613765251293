import { getMessages } from '../../../../../../../store/constants';

export const CUSTOM_BUTTON_REMOVE_TOOLTIP = getMessages().getText('common.remove');
export const CUSTOM_BUTTON_CANCEL = getMessages().getText('common.cancel');
export const CUSTOM_BUTTON_SAVE = getMessages().getText('common.save');
export const CUSTOM_ERROR_DUPLICATE = getMessages().getText('card_form_custom_section_error_duplicated');
export const CUSTOM_ERROR_EMPTY = getMessages().getText('card_form_custom_section_error_empty');
export const CUSTOM_NO_OPTION = getMessages().getText('card_form_custom_no_option');
export const CUSTOM_ERROR_EMAIL = getMessages().getText('card_form_custom_error_email');
export const CUSTOM_ERROR_PHONE = getMessages().getText('card_form_custom_error_phone');
export const CUSTOM_SHOW = getMessages().getText('card_form_custom_section_show');
export const CUSTOM_HIDE = getMessages().getText('card_form_custom_section_hide');
export const CUSTOM_PIN = getMessages().getText('common.pin_to_card');
export const CUSTOM_UNPIN = getMessages().getText('common.unpin_from_card');
