import { IRestList } from '../../../types/rest/IRestList';
import { TCardId } from '../../../types/types';
import { IRestListMeta } from '../../../types/rest/IRestListMeta';
import { getMessages } from 'app/store/constants';

export enum ESortField {
    sortByPriority = 'Sort by priority',
    sortByStartDate = 'Sort by start date',
    sortByDueDate = 'Sort by due date',
    sortByProgress = 'Sort by progress',
    sortByAssignedUser = 'Sort by assigned user',
    sortByEstimate = 'Sort by estimate',
    sortByTitle = 'Sort by title',
    sortByPrefix = 'Sort by prefix',
    sortByCardAge = 'Sort by card’s age',
    sortByColorLabel = 'Sort by color labels',
}

export const mapESortField = {
    [ESortField.sortByPriority]: getMessages().getText('sort.sortByPriority'),
    [ESortField.sortByStartDate]: getMessages().getText('sort.sortByStartDate'),
    [ESortField.sortByDueDate]: getMessages().getText('sort.sortByDueDate'),
    [ESortField.sortByProgress]: getMessages().getText('sort.sortByProgress'),
    [ESortField.sortByAssignedUser]: getMessages().getText('sort.sortByAssignedUser'),
    [ESortField.sortByEstimate]: getMessages().getText('sort.sortByEstimate'),
    [ESortField.sortByTitle]: getMessages().getText('sort.sortByTitle'),
    [ESortField.sortByPrefix]: getMessages().getText('sort.sortByPrefix'),
    [ESortField.sortByCardAge]: getMessages().getText('sort.sortByAge'),
    [ESortField.sortByColorLabel]: getMessages().getText('sort.sortByColorLabel'),
}

export const mapESortedField = {
    [ESortField.sortByPriority]: getMessages().getText('sorted.sortByPriority'),
    [ESortField.sortByStartDate]: getMessages().getText('sorted.sortByStartDate'),
    [ESortField.sortByDueDate]: getMessages().getText('sorted.sortByDueDate'),
    [ESortField.sortByProgress]: getMessages().getText('sorted.sortByProgress'),
    [ESortField.sortByAssignedUser]: getMessages().getText('sorted.sortByAssignedUser'),
    [ESortField.sortByEstimate]: getMessages().getText('sorted.sortByEstimate'),
    [ESortField.sortByTitle]: getMessages().getText('sorted.sortByTitle'),
    [ESortField.sortByPrefix]: getMessages().getText('sorted.sortByPrefix'),
    [ESortField.sortByCardAge]: getMessages().getText('sorted.sortByAge'),
    [ESortField.sortByColorLabel]: getMessages().getText('sorted.sortByColorLabel'),
}

export interface ISorting {
    isEnabled: boolean
    sortField: ESortField;
    isDesc: boolean;
}

export interface IList extends IRestList{
    dashboardId?: number;
    cardIds?: TCardId[];  // backrelation for card.listId, не обновлять напрямую, см. cardIdsMiddleware
    matchDashboardFilter?: boolean;
    filteredCardNumber?: number;
    isNew?: boolean; // созданный лист, еще не синхронизирован с бекендом
    archiveLoaded?: boolean;
    meta?: IListMeta;
}

export interface IListMeta extends IRestListMeta {
}
