import { IApplicationState, TCardId } from '../../../../types/types';
import { IListViewCard } from '../store/listViewBoard/types';

export const getListViewCard = (
    state: IApplicationState,
    cardId: TCardId
): IListViewCard => {
    const card = state.model.cards[cardId];
    return card;
}
