import { createDeepEqualSelector } from '../../../../helpers/memoizeHelper';
import { IApplicationState, TCardId, TRecurringTaskId } from '../../../../../../types/types';
import { IRestRecurringTask } from '../../../../../../types/rest/recurringTask/IRestRecurringTask';
import { IRecurringTaskForm } from '../types';
import { mapRestRecurringTaskToForm } from '../helpers/mapRestRecurringTaskToForm';
import { getCardRecurringTask } from '../../../../../../store/model/selectors/getCardRecurringTask';

type TgetRecurringTaskForm = {
    cardId: TCardId;
    taskId: TRecurringTaskId;
}

export const getRecurringTaskFormCreateSelector = () => createDeepEqualSelector([
        (state, {cardId, taskId}) => getCardRecurringTask(state, cardId, taskId)
    ],
    (recurringTask) => {
        return mapRestRecurringTaskToForm(recurringTask)
    }
)
