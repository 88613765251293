import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { SegmentBoardEvent, segmentTrackAction } from '../../../../../../../../../middlewares/segment';

export const onMoreLinkClick = (): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(segmentTrackAction(SegmentBoardEvent.CLICKED_MORE_INSTRUCTIONS_LINK_TO_CARD));
    };
    return action;
};
