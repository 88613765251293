import { getMessages } from '../../../../store/constants';

export const HELP_PANEL_HEADER_TITLE = getMessages().getText('help_panel.header.title');
export const HELP_PANEL_LINKS_FAQ = getMessages().getText('help_panel.links.faq');
export const HELP_PANEL_LINKS_VIDEO = getMessages().getText('help_panel.links.video');
export const HELP_PANEL_LINKS_PRIVACY_POLICY = getMessages().getText('help_panel.links.privacy_policy');
export const HELP_PANEL_LINKS_TERMS_CONDITIONS = getMessages().getText('help_panel.links.terms_conditions');
export const HELP_PANEL_LINKS_COOKIE = getMessages().getText('help_panel.links.cookie');
export const HELP_PANEL_LINKS_AFFILIATE = getMessages().getText('help_panel.links.affiliate');
export const HELP_PANEL_LINKS_INVITE = getMessages().getText('help_panel.links.invite');
export const HELP_PANEL_LINKS_RELEASE = getMessages().getText('help_panel.links.release');
export const HELP_PANEL_LINK_ACCESSIBILITY = getMessages().getText('help_panel.links.accessibility');
