import { REST_PREDECESSOR } from '../../../../constants';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { getRestHeadersPatch } from '../../../../helpers/getRestHeadersHelper';
import { IRestPredecessor } from '../../../../../types/rest/IRestPredecessor';
import { IDependency } from '../../../../../store/model/dependencies/dependency/types';
import { dependencyToRestPredecessor } from './helper/dependencyToRestPredecessor';
import { PartUploader } from '../../../../../util/part_uploader';
import { PORTION_SIZE } from '../../../../../const';

export const copyRest = (
    dependencies: IDependency[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        const predecessors = dependencies.map(dependencyToRestPredecessor);
        const partUploader = new PartUploader<IRestPredecessor>(
            REST_PREDECESSOR + '/copy',
            predecessors,
            PORTION_SIZE,
            getRestHeadersPatch()
        );
        return partUploader.uploadPart()
    };
    return action;
};
