import { TCardId } from '../../types/types';
import { Dispatch, ThunkAction } from '../../types/actions';
import { ICards } from '../../store/model/cards/types';
import { cardsRestPatch } from './card/card/api/helper/cardsRestPatch';

export const cardSetDescription = (
    cardId: TCardId,
    description: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        const cards: ICards = {
            [cardId]: {
                description: description
            }
        };

        return dispatch(cardsRestPatch(cards));
    };
    return action;
};
