import { IBoardsSetAction } from './types';
import { IBoard } from '../types';
import { AT_BOARDS_SET } from './constants';

export const boardsSet = (
    boards: IBoard[]
): IBoardsSetAction => ({
    type: AT_BOARDS_SET,
    boards
});
