import { Dispatch, ThunkAction } from '../../../../types/actions';
import { fetchHandler } from '../../../../util/fetchHandler';
import { Util } from '../../../../util/util';
import { getRestHeadersPatch } from '../../../helpers/getRestHeadersHelper';
import { IComment } from '../../../../store/model/card/types/IComment';
import { IList } from '../../../../store/model/list/types';
import { REST_LIST } from '../../../constants';
import { TListId } from '../../../../types/types';

export const patchRest = (
    listId: TListId,
    list: IList
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        return fetchHandler<IComment>(
            Util.getApiUrl(REST_LIST + listId), {
                ...getRestHeadersPatch(),
                body: JSON.stringify(list)
            }
        );
    };
    return action;
};
