import { TBoardId } from '../../../../../../types/types';
import { getTypeSwitcherLocalStorage } from './getTypeSwitcherLocalStorage';
import { EViewTypes } from '../../../../../../const';

export const getLocalStorageBoardViewType = (
    boardId: TBoardId
): EViewTypes => {
    const typeSwitcherLocalStorage = getTypeSwitcherLocalStorage();
    return typeSwitcherLocalStorage[boardId] && typeSwitcherLocalStorage[boardId].activeType;
}
