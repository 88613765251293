import { IApplicationState } from '../../../../../../types/types';
import { connect } from 'react-redux';
import { NavigationPanelLogo } from '../../components/NavigationPanelLogo/NavigationPanelLogo';
import { INavigationPanelLogoHOCProps } from './types';
import { INavigationPanelLogoFields } from '../../components/NavigationPanelLogo/types';
import { getAuthUser } from 'app/store/model/authUser/selectors/getAuthUser';

const mapStateToProps = (
    state: IApplicationState,
    props: INavigationPanelLogoHOCProps,
): INavigationPanelLogoFields => {
    const user = getAuthUser(state);

    return {
        logo: user.logo,
        logoX: user.logoX,
    }
};

export const NavigationPanelLogoHOC = connect(
    mapStateToProps,
    null
)(NavigationPanelLogo);
NavigationPanelLogoHOC.displayName = 'NavigationPanelLogoHOC';
