import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../../../../types/types';
import { SegmentCardSourceValue } from '../../../../../../../../middlewares/segment/trackEntities/cardEvents';
import { cardCopyWithSubcardsPrompt } from '../../../../../../helpers/cardCopyWithSubcardsPrompt';

export const onCardCopy = (
    cardId: TCardId,
    source: SegmentCardSourceValue
): ThunkAction => {
    return (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(cardCopyWithSubcardsPrompt(cardId, source));
    };
};
