import { IGetState, TBoardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import {
    getBoardCardCustomProperties
} from '../../../../../../../../store/model/board/selectors/getBoardCardCustomProperties';
import { ECustomPropertyType, IBoardCardCustomOption } from '../../../../../../../../store/customProperties/types';
import { v4 as uuidv4 } from 'uuid';
import { ICustomPropertiesElement } from '../../../components/CustomPropertiesElements/types';
import { getNextCustomPropertyOrderNumber } from '../../selectors/getNextCustomPropertyOrderNumber';
import { boardSetCardCustomProperties } from '../../../../../../../../rest/effects/board/boardSetCardCustomProperties';
import { EAsidePanelProperty } from '../../../../../asidePanel/components/AsidePanel/types';
import { boardSetCardProperties } from '../../../../../../../../rest/effects/board/boardSetCardProperties';
import { isObjectEmpty } from '../../../../../../helpers/isObjectEmpty';
import {
    getBoardCardCustomPropertyOptions
} from '../../../../../../../../store/model/board/selectors/getBoardCardCustomPropertyOptions';

export const addProperty = (
    boardId: TBoardId,
    name: string,
    type: ECustomPropertyType,
    options: IBoardCardCustomOption[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const boardCardCustomProperties = getBoardCardCustomProperties(state, boardId);

        const id = uuidv4();

        if (isObjectEmpty(boardCardCustomProperties)) { // при добавлении первого свойства сделать секцию дефолтной
            const cardDefaultProperties = getBoardCardCustomPropertyOptions(state, boardId);
            if (!cardDefaultProperties || !cardDefaultProperties.length) {
                const options = [{
                    name: name,
                    option: id,
                    isDefault: true
                }];
                dispatch(boardSetCardProperties(boardId, { property: EAsidePanelProperty.CARD_CUSTOM, options }));
            }
        }

        const customProperty: ICustomPropertiesElement = {
            id,
            name: name,
            orderNumber: getNextCustomPropertyOrderNumber(state, boardId),
            type,
            options
        };

        return dispatch(boardSetCardCustomProperties(boardId, {
            ...boardCardCustomProperties,
            [customProperty.id]: customProperty
        }));
    };
    return action;
};
