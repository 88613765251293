import * as React from 'react';
import { IEmailNotificationsSectionProps } from './types';
import { AsidePanelContext } from '../../../../../asidePanel/components/AsidePanel/constants';
import { CLASS_BOARD_DETAILS_SECTION } from '../../../../constants';
import { SettingsSection } from '../../../../../../base/components/SettingsSection/SettingsSection';
import { BOARD_SECTION_EMAIL_NOTIFICATIONS } from '../../constants';
import { EmailNotificationsHOC } from '../../hocs/EmailNotificationsHOC/EmailNotificationsHOC';
import { EmailNotificationsTogglerHOC } from '../../hocs/EmailNotificationsTogglerHOC/EmailNotificationsTogglerHOC';

export function EmailNotificationsSection ({
}: IEmailNotificationsSectionProps) {
    const { boardId } = React.useContext(AsidePanelContext);
    const className = CLASS_BOARD_DETAILS_SECTION + ' ' + CLASS_BOARD_DETAILS_SECTION + '--email-notifications';
    return (
        <SettingsSection
            className={className}
            title={BOARD_SECTION_EMAIL_NOTIFICATIONS}
            titleButton={<EmailNotificationsTogglerHOC boardId={boardId} />}
        >
            <EmailNotificationsHOC
                boardId={boardId}
            />
        </SettingsSection>
    );
};
