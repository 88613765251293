import * as React from 'react';
import './_RelatedCard.scss';
import { Button, Done, Icon, Tooltip } from 'kui';
import { IRelatedCardProps } from './types';
import { ListItem } from '../../../../base/components/ListItem/ListItem';
import { BOARD_TOOLTIP, RELATED_CARD_DELETE_TOOLTIP } from './constants';
import { TStatus } from '../../../../../../types/model';
import { AsidePanelContext } from '../../../asidePanel/components/AsidePanel/constants';
import { getAsidePanelTooltip } from '../../../asidePanel/helpers/getAsidePanelTooltip';
import * as _ from 'underscore';
import { clearMarkdownInline } from '../../../../base/helpers/clearMarkdownHelper';
import { DONE_TITLE } from '../../../../../../const';
import { CLASS_RELATED_CARDS_LIST } from '../RelatedCardsList/constants';
import { CardProgressHOC } from '../../../../base/components/CardProgress/hocs/CardProgressHOC';

export function RelatedCard({
    boardId,
    cardId,
    relatedCard,
    isHideProgress,
    isReadonly,
    onClick,
    onDelete,
    onLoad
}: IRelatedCardProps) {
    const { isMobile } = React.useContext(AsidePanelContext);

    const className = CLASS_RELATED_CARDS_LIST + '__card';
    const classNameBody = className + '-body';
    const classNameName = className + '-name';
    const classNameBoard = className + '-board';
    const classNameWithBoard = className + '--with-board';
    const classNameColumn = className + '-col';
    const classNameColumnName = classNameColumn + ' ' + classNameColumn + '--name';
    const classNameColumnBoard = classNameColumn + ' ' + classNameColumn + '--board';
    const classNameItem = className + '-item';
    const classNameItemDeleted = classNameItem + '--deleted';

    function onLinkHandler (event: React.MouseEvent) {
        event.preventDefault();
        onClick();
    }

    React.useEffect(() => {
        onLoad();
    }, []);

    const actions = isReadonly ? null
        : <Button
            variant={ 'icon' }
            tooltip={ getAsidePanelTooltip({
                direction: 'up-left',
                value: RELATED_CARD_DELETE_TOOLTIP
            })
            }
            onClick={() => onDelete()}
        >
            <Icon size={24} xlink={'clear'} />
        </Button>;

    const img = (
        <Icon
            size={24}
            xlink={relatedCard.icon}
        />
    );

    const withBoard = boardId === relatedCard.dashboardId ?
        '' : ' ' + classNameWithBoard;

    const cardName = !!onClick
        ? <Button
            className={classNameName}
            href={relatedCard.link}
            tooltip={{
                isNoEvents: true,
                isNoWrap: true,
                value: isMobile ? null : _.escape(relatedCard.cardName)
            }}
            variant={'icon-text'}
            onClick={onLinkHandler}
        >
            {relatedCard.cardName}
        </Button>
        : relatedCard.cardName;

    const cardNameDiv = (
        <div className={classNameColumnName}>
            {cardName}
        </div>
    );

    const boardName = clearMarkdownInline(relatedCard.dashboardName);

    const body = (
        <div className={classNameBody + withBoard}>
            {cardNameDiv}
            {withBoard &&
                <Tooltip
                    direction={'up-right'}
                    isNoEvents
                    isNoWrap
                    value={isMobile ? null : `${BOARD_TOOLTIP} ${_.escape(boardName)}`}
                >
                    <div className={classNameColumnBoard}>
                        <Icon className={classNameBoard} size={24} xlink={'board'} />
                        {boardName}
                    </div>
                </Tooltip>
            }
            {!isHideProgress && <CardProgressHOC cardId={cardId} />}
        </div>
    );

    const isDeleted = relatedCard.status === TStatus.STATUS_DELETED;

    return (
        <ListItem
            actions={actions}
            className={`
                ${classNameItem}
                ${isDeleted ? classNameItemDeleted : ''}
            `}
            data-id={relatedCard.cardId}
            img={img}
            key={relatedCard.cardId}
            text={body}
        />
    );
};
