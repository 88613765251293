import { notificationBeforeDueDateEditActionSetAction } from '../notificationBeforeDueDateEditsState/actions/notificationBeforeDueDateEditActionSetAction';
import { TCardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { popupSetAction } from '../notificationBeforeDueDateCardEditState/actions/popupSetAction';

export const popupStatusForCardUpdate = (
    cardId: TCardId,
    isOpen: boolean
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(notificationBeforeDueDateEditActionSetAction(cardId, popupSetAction(isOpen)));
    };
    return action;
};
