import { IApplicationState, TListId } from '../../../../types/types';
import { getListMetaCardAging } from '../../selectors/getListMetaCardAging';

export const getListCardAgingEnabled = (
    state: IApplicationState,
    listId: TListId
): boolean => {
    const listAging = getListMetaCardAging(state, listId);

    return listAging && listAging.enabled;
}
