import { IApplicationState } from '../../../types/types';
import { TABTestName, TABTestValue } from '../../types';
import { getInitialState } from '../helpers/getInitialState';

export const getABTestValue = (
    state: IApplicationState,
    name: TABTestName
): TABTestValue => {
    const initialState = getInitialState();
    return (state.abTesting || initialState)[name].value;
};
