import * as React from 'react';
import { IListItemProps } from './types';
import './_ListItem.scss';
import { CLASS_LIST_ITEM, CLASS_LIST_ITEM_LOADING, CLASS_LIST_ITEM_DRAGGABLE } from './constants';
import { Loader } from 'kui';

export const ListItem: React.FC<IListItemProps> = ({
    actions,
    className,
    img,
    isDraggable,
    isLoading,
    text,
    variant,
    ...attributes
}) => {
    let classNameItem = CLASS_LIST_ITEM;
    if (className) classNameItem += ' ' + className;
    if (isDraggable) classNameItem += ' ' + CLASS_LIST_ITEM_DRAGGABLE;
    if (isLoading) classNameItem += ' ' + CLASS_LIST_ITEM_LOADING;
    if (variant) classNameItem += ' ' + CLASS_LIST_ITEM + '--variant_' + variant;
    const classNameLoader = CLASS_LIST_ITEM + '__loader';
    const classNameColumn = CLASS_LIST_ITEM + '__col';
    const classNameColumnImg = classNameColumn + ' ' + classNameColumn + '--img';
    const classNameColumnText = classNameColumn + ' ' + classNameColumn + '--text';
    const classNameColumnActions = classNameColumn + ' ' + classNameColumn + '--actions';

    return (
        <div className={classNameItem} {...attributes}>
            {!!img &&
                <div className={classNameColumnImg}>
                    {img}
                </div>
            }
            <div className={classNameColumnText}>
                {text}
            </div>
            <div className={classNameColumnActions}>
                {actions}
            </div>
            {<Loader className={classNameLoader}/>}
        </div>
    );
};
