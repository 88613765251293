import { errorSetAction } from '../store/signIn/actions/errorSetAction';
import { Dispatch, ThunkAction } from '../../../../../types/actions';

export const errorReset = (
): ThunkAction => {
    const action = (dispatch: Dispatch) => {
        dispatch(errorSetAction(undefined));
    };
    return action;
};
