import { root } from '../../../../../../../../../store/constants';
import { getCardIcon } from '../../../../../../../helpers/getCardIcon';
import { IApplicationState, TCardId } from '../../../../../../../../../types/types';
import { getCardSubcardsSorted } from '../../../../../../../../../store/model/selectors/getCardSubcards';
import { getCardIsEpic } from '../../../../../../../../../store/model/selectors/getCardIsEpic';
import { getCardFullNameHelper } from '../../../../../../../../../store/model/card/helpers/getCardFullNameHelper';
import { stripHtml } from '../../../../../../../base/helpers/stripHtmlHelper';
import { markdown } from '../../../../../../../../../helper/markdownHelper';
import { ICardSubcard } from '../../../components/CardSubcards/types';
import { getBoardIdByCardId } from '../../../../../../../../../store/model/selectors/getBoardIdByCardId';
import { getAuthUserIsHideSubcards } from '../../../../../../../../../store/model/authUser/selectors/getAuthUserIsHideSubcards';
import { getSubcardWeight } from '../../../../../../../../../store/model/selectors/getSubcardWeight';

export const getKnbCardSubcards = (
    state: IApplicationState,
    cardId: TCardId
): ICardSubcard[] => {
    const subCards = getCardSubcardsSorted(state, cardId);
    const boardId = getBoardIdByCardId(state, cardId);
    return subCards.map((card) => {
        const isEpic = getCardIsEpic(state, card.id);
        const cardFullName = getCardFullNameHelper(card);
        return {
            ...card,
            fullName: stripHtml(markdown(cardFullName)),
            icon: getCardIcon(card.status, isEpic),
            isEpic,
            link: root.App.router.getCardPageUrl(boardId, card.id),
            weight: getSubcardWeight(state, card.id)
        };
    });
};

export const getKnbCardHideDoneFilteredSubcards = (
    state: IApplicationState,
    subcards: ICardSubcard[],
): ICardSubcard[] => {
    const isHideDoneSubcards = getAuthUserIsHideSubcards(state);
    if (!isHideDoneSubcards)
        return subcards;

    const filteredElements = subcards.filter(item => {
        return !!item && !(item.processingStatus === 'done' || item.processingPercent === 100);
    });
    return filteredElements;
};
