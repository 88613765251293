import * as React from 'react';
import './_Body.scss';
import { IBodyProps } from './types';
import { LoaderBlock } from 'kui';
import { CLASS_MODAL_PAGE } from '../constants';

export function Body ({
    children,
    isLoading,
}: IBodyProps) {
    const className = CLASS_MODAL_PAGE + '__body';
    const classNameLoading = isLoading ? className + '--loading' : '';
    const classNameInner = CLASS_MODAL_PAGE + '__body-inner';

    return (
        <div
            className={`
                ${className}
                ${classNameLoading}
            `}
        >
            <div className={classNameInner}>
                {children}
            </div>
            {isLoading && <LoaderBlock />}
        </div>
    )
};
