import {
    ERecurringTaskFrequencyType,
    IRestRecurringTask
} from '../../../../../../../types/rest/recurringTask/IRestRecurringTask';
import * as moment from 'moment';
import { getMonthDayData } from '../../../../../aside_panel/cardDetails/recurringSection/helpers/getMonthDayData';
import { WEEK_POSITIONS } from '../../../../../aside_panel/cardDetails/recurringSection/components/MonthDay/constants';

export const getTextTime = (
    task: IRestRecurringTask,
    isUser12HourFormat: boolean
): string => {
    if (!task.startDate) return '';
    if (task.frequencyType === ERecurringTaskFrequencyType.DAY) {
        const format = isUser12HourFormat ? '[at] hh:mm A' : '[at] kk:mm';
        return moment(task.startDate).format(format);
    } else if (task.frequencyType === ERecurringTaskFrequencyType.WEEK) {
        const days = task.week.selectedDaysOfWeek;
        const text = days.map((day) => moment.weekdays(day - 1));
        return days.length ? `on ${text.join(', ')}` : '';
    } else if (task.frequencyType === ERecurringTaskFrequencyType.DAY_OF_MONTH) {
        return `at ${task.dayOfMonth.dayOfMonth}`;
    } else if (task.frequencyType === ERecurringTaskFrequencyType.DAY_OF_WEEK_IN_MONTH) {
        const monthDayData = getMonthDayData(new Date(task.startDate));
        return `at ${WEEK_POSITIONS[monthDayData.dayOfWeekPosition - 1]} ${monthDayData.dayOfWeekName}`;
    }
};
