import { root } from 'app/store/constants';
import { Dispatch, ThunkAction } from 'app/types/actions';
import { IGetState } from 'app/types/types';
import { isAsideOpenSetAction } from 'app/view/react_components/main/navigationPanel/store/navigationPanel/actions/isAsideOpenSetAction';
import { getIsAssignedToMeActive } from '../../../../../../../store/router/selectors/getIsAssignedToMeActive';

export function onClose (
): ThunkAction {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        const isATM = getIsAssignedToMeActive(getState());
        if (isATM) {
            root.App.router.navigate(root.App.router.getAssignedToMeUrl(), { trigger: true });
        } else {
            root.App.router.navigate(root.App.router.getUrl(null), { trigger: true });
        }
        dispatch(isAsideOpenSetAction(false));
    };
    return action
};
