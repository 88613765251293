import * as React from 'react';
import { IMoveToItemProps } from './types';
import './_MoveToItem.scss';
import { Icon } from 'kui';
import { CLASS_MOVE_TO } from '../MoveTo/constants';
import { markdown } from '../../../../../../../helper/markdownHelper';
import { stripHtml } from '../../../../../base/helpers/stripHtmlHelper';

export function MoveToItem(
    {
        name,
        index,
        className,
        icon,
        isActive,
        onClick
    }: IMoveToItemProps
) {
    let classNameItem = CLASS_MOVE_TO + '__column-item';
    const classNameItemActive = classNameItem + '--active';
    const classNameItemIcon = classNameItem + '-icon';
    const classNameItemText = classNameItem + '-text';

    classNameItem += (isActive? (' ' + classNameItemActive): '');
    classNameItem += !!className ? ' ' + className : '';
    return (
        <div className={classNameItem} onClick={() => { onClick(index) }}>
            {icon && <Icon className={classNameItemIcon} xlink={icon} size={24}/>}
            <span className={classNameItemText}>{stripHtml(markdown(name))}</span>
        </div>
    );
}
