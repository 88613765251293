import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState } from '../../../../../../../types/types';
import { topBarSetAction } from '../../../store/topBarBoard/actions/topBarSetAction';
import { getActiveBoardId } from '../../../../../../../store/model/selectors/getActiveBoardId';
import { topBarNameStatusSet } from '../../../store/topBar/actions/topBarNameStatusSet';
import { EBoardNameStatus } from '../../../store/topBar/types';
import { getBoardPermissionsAllowEdit } from '../../../../../../../store/model/selectors/getBoardPermissionsAllowEdit';

export const nameEditing: ActionCreator<ThunkAction> = () => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const boardId = getActiveBoardId(state);
        if (!getBoardPermissionsAllowEdit(state, boardId )) return;
        dispatch(topBarSetAction(boardId, topBarNameStatusSet(EBoardNameStatus.editing)));
    };
    return action;
};
