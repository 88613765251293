import { IGetState } from 'app/types/types';
import { Dispatch, ThunkAction } from 'app/types/actions';
import { togglePinAttachment } from 'app/rest/effects/card/attacments/togglePinAttachment';
import { ERestDriveDocType, TDriveDocId } from 'app/types/rest/IRestDriveDoc';
import { getActiveCardId } from 'app/store/router/selectors/getActiveCardId';
import { getDriveDocsByCardId } from 'app/store/model/driveDocs/selectors/getDriveDocsByCardId';
import { CARD_MAX_PINNED_ATTACHMENTS_IMAGES, SNACKBAR_ID_CARD_ATTACHMENTS_IMAGES_LIMIT } from '../../../constants';
import { getDriveDoc } from 'app/store/model/driveDocs/selectors/getDriveDoc';
import { snackbarErrorDefault } from '../../../../../../snackbarsStack';
import { getMessages } from 'app/store/constants';

export const onTogglePin = (
    driveDocId: TDriveDocId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const driveDoc = getDriveDoc(state, driveDocId);
        if (driveDoc.isImage && driveDoc.main) {
            const cardId = getActiveCardId(state);
            const pinnedImgsCount = getDriveDocsByCardId(state, cardId, ERestDriveDocType.CARD, true, true)
                .filter(driveDoc => driveDoc.isImage).length;
            if (pinnedImgsCount > CARD_MAX_PINNED_ATTACHMENTS_IMAGES) {
                dispatch(snackbarErrorDefault({
                    id: SNACKBAR_ID_CARD_ATTACHMENTS_IMAGES_LIMIT,
                    text: getMessages().getText('card_form_attachments_pin_limit'),
                }));
            } else {
                dispatch(togglePinAttachment(driveDocId));
            }
        } else {
            dispatch(togglePinAttachment(driveDocId));
        }
    };
    return action;
};
