'use strict';
import { dispatch, getAppState } from "../../store/configureStore";
import { getAuthUser } from '../../store/model/authUser/selectors/getAuthUser';
import * as _ from 'underscore';
import { staredNotificationsDelete } from "app/rest/effects/staredNotification/staredNotificationsDelete";
import { sendRealTimeStarredUpdate } from "app/view/react_components/base/helpers/realTimeHelperTS";
import { TStatus } from "app/types/model";

export const StaredNotifyHelper = App.Views.StaredNotifyHelper = Backbone.View.extend({

    notificationStaredMap: {},
    loadPromise: false,

    initialize: function(options) {
        if (getAuthUser(getAppState()).anonym){
            return;
        }
        this.collection = new App.Collections.StaredNotifications([]);
        this.collection.comparator = (m, m1) =>{
            return m.get('time') < m1.get('time') ? -1 : 1;
        }
        this.listenTo(this.collection, 'reset', _.debounce(this.onResetCollection, 200));
        this.load();
    },

    render: function() {
        return this;
    },

    hasMore: function() {
        return this.collection.hasMore();
    },

    onResetCollection: function () {
        this.collection.each( item => {
            let notificationId = item.get('notificationId');
            if (!this.notificationStaredMap[notificationId]){
                this.notificationStaredMap[notificationId]=item.id;
            }
        })

        this.trigger(App.vent['staredNotifyHelper:reset']);
    },

    starredNotification: function(notification){
        let id = notification.getNotificationId();
        if (this.isStarredNotification(id)){
            console.error('StaredNotifications try stared, but already stared',notification,this.isStarredNotification(id) );
            throw 'StaredNotifications try stared, but already stared';
        }
        let model = new App.Models.StaredNotification({notificationId: notification.id});
        this.notificationStaredMap[id] = Date.now();
        model.save().done(() =>{
            this.notificationStaredMap[id] = model.id;
            this.sendRealTimeAdd(model);
            this.collection.add(model);
        });
        return model;

    },

    unStarredNotification: function(notification){
        let id = notification.getNotificationId();
        if (!this.isStarredNotification(id)){
            console.error('StaredNotifications did not stared',notification,this.isStarredNotification(id) );
            throw 'StaredNotifications did not stared';
        }
        let model = this.collection.findWhere({id: this.isStarredNotification(id)});
        model.destroy();
        this.sendRealTimeRemove([model.id]);
        delete this.notificationStaredMap[id];
        return model;
    },

    unStarredCardNotifications: function(cardId) {
        let ids = [];
        for (let key in this.notificationStaredMap) {
            if (!this.notificationStaredMap.hasOwnProperty(key)) {
                continue;
            }
            let model = this.collection.findWhere({id: this.isStarredNotification(key)});
            if (cardId === model.getNotificationModel().modelId) {
                ids.push(model.id)
            }
        }
        if (ids.length){
            staredNotificationsDelete(ids);
            this.sendRealTimeRemove(ids);
            ids.forEach((id) =>{
                let model = this.collection.findWhere({id: id});
                this.collection.remove(model);
                delete this.notificationStaredMap[model.get('notificationId')];
            })
        }

    },

    unStarredAllNotifications: function() {
        let ids =Object.values(this.notificationStaredMap);
        this.sendRealTimeRemove(ids);
        return staredNotificationsDelete(ids).then(()=>{
            this.notificationStaredMap = {};
            this.collection.reset([]);
        });

    },

    isStarredNotification: function(notificationId){
        return this.notificationStaredMap[notificationId];
    },

    load: function() {
        if (!this.loadPromise){
            this.loadPromise = {};
            this.loadPromise.promise = new Promise((resolve, reject) => {
                    this.loadPromise.resolve = resolve;
                    this.loadPromise.reject = reject;
                })
            this.loadPart();
        }

        return this.loadPromise.promise;
    },

    loadPart: function() {
            this.collection.fetch({
                success: _.bind(function() {
                    if (this.hasMore()){
                        this.loadPart();
                    } else {
                        setTimeout(() => {
                            this.loadPromise.resolve();
                        }, 200);
                    }
                }, this)
            });
    },

    sendRealTimeAdd: function(model){
        dispatch(sendRealTimeStarredUpdate([model.attributes]));
    },

    sendRealTimeRemove: function(ids){
        let toSend =[]
        ids.forEach((id) =>{
            toSend.push({
                id,
                status: TStatus.STATUS_DELETED
            });
        })
        dispatch(sendRealTimeStarredUpdate(toSend));
    },


    starredNotificationListener: function(array){
        array.forEach((model) =>{
            if (model.status === TStatus.STATUS_DELETED){
                this.starredNotificationRemoveListener(model.id);
            } else {
                this.starredNotificationAddListener(model);
            }
        })
        this.onResetCollection();
    },

    starredNotificationAddListener: function(modelAttributes){
        let model = new App.Models.StaredNotification(modelAttributes);
        let id = model.get('notificationId');
        if (this.isStarredNotification(id)){
            console.error('StaredNotifications try stared, but already stared',notification,this.isStarredNotification(id) );
            throw 'StaredNotifications try stared, but already stared';
        }
        this.notificationStaredMap[id] = model.id;
        this.collection.add(model);
    },

    starredNotificationRemoveListener: function(id){
        let notificationId;
        for (let key in this.notificationStaredMap) {
            if (!this.notificationStaredMap.hasOwnProperty(key)) {
                continue;
            }
            if (this.notificationStaredMap[key] === id) {
                notificationId = key;
            }
        }
        if (!notificationId){
            console.error('StaredNotifications did not have', id );
            throw 'StaredNotifications did not stared';
        }
        let model = this.collection.findWhere({id: id});
        this.collection.remove(model);
        delete this.notificationStaredMap[notificationId];
        return model;
    },

});
