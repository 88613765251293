import * as React from 'react';
import { useEffect } from 'react';
import { Button, Icon, Input, ISelectActiveInheritedProps, Select } from 'kui';
import { INotificationFormItemOwnProps, INotificationListItemProps } from './types';
import { getUnitSelectParams } from './helpers/NotificationListItemHelper';
import './_NotificationListItem.scss';
import { v4 as uuidv4 } from 'uuid';
import { ErrorHint } from '../../../../../../base/components/ErrorHint/ErrorHint';
import { NOTIFICATION_LIST_ITEM_REMOVE_BUTTON_TOOLTIP } from './constants';
import { getAsidePanelTooltip } from '../../../../../asidePanel/helpers/getAsidePanelTooltip';
import { ITimeUnits } from 'app/types/model';

export const NotificationListItem: React.FC<INotificationListItemProps> = ({
        id,
        interval,
        notification,
        unit,
        isPopupOpen,
        isReadonly,
        onIntervalChange,
        onUnitChange,
        onOpenEditingPopup,
        onRemoveNotificationById,
        onEnter,
        onDidUnmount,
        validateItem,
}) => {
    const uuid = uuidv4();
    const selectParams = getUnitSelectParams(interval, unit);
    const className = 'notification-list-item';
    const [uniqueClass] = React.useState(className + '--' + uuid);
    const [errorNotificationKey, setErrorNotificationKey] = React.useState(uuid);
    const debounce = React.useRef(null);

    let [elementState, _setElementState] = React.useState<INotificationFormItemOwnProps>({
        id,
        unit,
        isPopupOpen,
        interval,
        error: null as string,
    });

    const setElementState = (value: INotificationFormItemOwnProps) => {
        elementState = {...value};
        _setElementState(value);
    }
    /**
     * matching empty '' and numeric
     * values in input string
     * @param e
     */
    const validate = (): void => {
        let tmpNotification = {
            ...notification,
            id: elementState.id,
            interval: elementState.interval,
            unit: elementState.unit,
        };//Just need to match the type INotificationBeforeDueDate in getErrorMessageIfNotificationsNotValid
        const errorMessage = validateItem(tmpNotification);
        const errorToggle = () => {
            setElementState({
                ...elementState,
                error: errorMessage
            });
        }
        if (debounce.current) clearTimeout(debounce.current);
        if (errorMessage) {
            debounce.current = setTimeout(errorToggle, 1000);
        } else {
            errorToggle();
        }
    }

    const isValid = (): boolean => {
        return !elementState.error;
    }

    const onIntervalInputChange = (
        e: React.SyntheticEvent<HTMLElement, Event>,
    ) => {
        if (isReadonly || !isPopupOpen) return;

        const eventTarget = e.target as HTMLInputElement;
        let value = eventTarget.value;
        if (!value || value.match(/^\d+$/)) {
            onIntervalChange(Number(value), uuid);
        }
        const newNotification: INotificationFormItemOwnProps = {
            ...elementState,
            interval: Number(value),
        }
        setElementState(newNotification);
        validate();
    };

    const onUnitsSelectChange = (
        e: ISelectActiveInheritedProps,
    ) => {
        if (isReadonly || !isPopupOpen) return;

        const value = e.item.value;
        const newNotification: INotificationFormItemOwnProps = {
            ...elementState,
            unit: value as ITimeUnits,
        }
        setElementState(newNotification);
        validate();
        onUnitChange(value, uuid);
    };

    const handleClick = () => {
        if (isReadonly || isPopupOpen) return;

        onOpenEditingPopup();
    };

    const handleEnter = () => {
        validate();
        if (isValid())
            onEnter();
    }

    useEffect(() => {
        return () => {
            if (onDidUnmount) {
                onDidUnmount(uuid);
            }
        };
    }, []);

    React.useEffect(() => {
        setErrorNotificationKey(uuid);
    }, []);

    React.useEffect(() => {
        return (() => {
            if (debounce.current) clearTimeout(debounce.current);
        });
    }, []);
    const classNameIntervalInput = 'interval-input';
    const classNameUnitSelectContainer = 'unit-select--container';
    const classNameUnitSelectContainerPopup = isPopupOpen ? classNameUnitSelectContainer + '--popup' : '';

    return (
        <li className={ `
            ${ uniqueClass }
            ${ className }
        ` }>
            <span className="interval-input--container">
                <Input
                    className={ `
                        ${ uniqueClass }
                        ${ classNameIntervalInput }
                    ` }
                    type="number"
                    value={ interval.toString() }
                    onClick={ handleClick }
                    readOnly={ isReadonly }
                    onChange={ onIntervalInputChange }
                    autosize={ false }
                    state={ elementState.error ? 'error' : undefined }
                    onEnter={ handleEnter }
                />
            </span>
            <span
                className={ `
                    ${classNameUnitSelectContainer}
                    ${classNameUnitSelectContainerPopup}
                ` }
                onClick={ handleClick }
            >
                <Select
                    active={ selectParams.currentIndex }
                    className="unit-select"
                    options={ selectParams.keyValues }
                    readOnly={ isReadonly }
                    variant="arrow"
                    onChange={ onUnitsSelectChange }
                />
            </span>
            <span className="notification-list-item__trash">
                {!isReadonly &&
                    <Button
                        tooltip={getAsidePanelTooltip({
                                    direction: 'left',
                                    value: NOTIFICATION_LIST_ITEM_REMOVE_BUTTON_TOOLTIP,
                            })
                        }
                        variant="icon"
                        className="trash-notification--icon"
                        onClick={ onRemoveNotificationById }
                    >
                        <Icon
                            xlink={'delete'}
                            size={ 24 }
                        />
                    </Button>
                }
            </span>
            <ErrorHint
                arrowTranslate={ { left: 24 } }
                direction={ 'up-right' }
                key={ errorNotificationKey }
                portalSelector={ `.${ uniqueClass } .${ classNameIntervalInput }` }
                selector={ `.${ uniqueClass } .${ classNameIntervalInput }` }
                value={ elementState.error }
            />
        </li>
    );
};
