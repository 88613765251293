import { IPollQuestionOption } from '../Poll/types';

export enum EPollQuestionVariant {
    RADIO = 'radio',
    STAR = 'star'
}

export interface IPollQuestionFields {
    isChips: boolean;
    isDisabled: boolean;
    isHideTitle: boolean;
    options?: IPollQuestionOption[];
    question: string;
    value: number | string | Array<string | number>;
    variant: EPollQuestionVariant;
}

export interface IPollQuestionEvents {
    setDisabled: (value: boolean) => void;
    setValue: (value: number | string) => void;
}

export interface IPollQuestionProps extends
    IPollQuestionFields,
    IPollQuestionEvents
{}
