import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TBoardId, TCardId } from '../../../../../../../../types/types';
import { IRecurringTaskForm } from '../../../types';
import { recurringTaskAdd } from '../../../../../../../../rest/effects/card/recurringTask/recurringTaskAdd';
import { createTemplateFromCard } from '../../../../../../../../rest/effects/card/template/createTemplateFromCard';
import { IRestCard } from '../../../../../../../../types/rest/IRestCard';
import { cardOpen } from '../../../../../../base/effects/cardOpen';
import { mapFormToRestRecurringTask } from '../../../helpers/mapFormToRestRecurringTask';
import { addRecurringEvents } from '../effects/segmentEvents';
import { snackbarSuccessDefault } from '../../../../../../snackbarsStack';
import { SNACKBAR_ID_RECURRING_CREATED, SNACKBAR_RECURRING_CREATED } from '../constants';

export const onAdd = (
    form: IRecurringTaskForm,
    cardId: TCardId,
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const restRecurringTask = mapFormToRestRecurringTask(form);
        if (!restRecurringTask) return;

        dispatch(addRecurringEvents(restRecurringTask));
        dispatch(createTemplateFromCard(cardId, boardId))
            .then((cardTemplate: IRestCard) => {
                dispatch(recurringTaskAdd(cardTemplate.id, restRecurringTask))
                    .then(() => {
                        dispatch(cardOpen(cardTemplate.id));
                        dispatch(snackbarSuccessDefault({
                            id: SNACKBAR_ID_RECURRING_CREATED,
                            text: SNACKBAR_RECURRING_CREATED,
                        }));
                    });
            });
    };
    return action;
};
