const category = 'Board';

export const SegmentBoardEvent = {
    CREATE: { category, event: 'Board created' },
    OPENED: { category, event: 'Board opened' },
    BOARD_DETAILS: { category, event: 'Board settings panel opened' },
    STARRED_BOARD: { category, event: 'Board starred' },
    UNSTARRED_BOARD: { category, event: 'Board unstarred' },
    BOARD_RENAMED: { category, event: 'Board renamed' },
    EDITED_DESCRIPTION: { category, event: 'Board description edited' },
    CANCEL_DESCRIPTION: { category, event: 'Description editing canceled' },
    ESCAPE_BOARD_DESCRIPTION_CHANGING: { category, event: 'Board description change escaped' },
    DESCRIPTION_FORMATTING_LINK: { category, event: 'Description formatting link clicked' },
    CLICKED_BOARD_EMAIL_INSTRUCTION: { category, event: 'Board email instruction link clicked' },
    BOARD_EMAIL_CLICKED: { category, event: 'Board email copied' },
    EMAIL_NOTIFICATIONS_ON: { category, event: 'Email notifications - on' },
    EMAIL_NOTIFICATIONS_OFF: { category, event: 'Email notifications - off' },
    EMAIL_NOTIFICATIONS_FREQUENCY: { category, event: 'Email notifications frequency dropdown opened' },
    CLICKED_AS_SOON_AS_POSSIBLE: { category, event: 'Email notifications as soon as possible set' },
    CLICKED_ONCE_AN_HOUR: { category, event: 'Email notifications once an hour set' },
    CLICKED_ONCE_A_DAY: { category, event: 'Email notifications once a day set' },
    CLICKED_EMAIL_ALL_CARDS: { category, event: 'Email notifications for all cards set' },
    CLICKED_EMAIL_ASSIGNED_TO_ME: { category, event: 'Email notifications for assigned to me set' },
    CLICKED_EMAIL_WATCHED_CARDS: { category, event: 'Email notifications for watched cards set' },
    IN_APP_NOTIFICATIONS_ON: { category, event: 'In-app notifications - on' },
    IN_APP_NOTIFICATIONS_OFF: { category, event: 'In-app notifications - off' },
    CLICKED_IN_APP_INSTRUCTIONS: { category, event: 'In-app instruction link clicked' },
    CHECKED_COLOUR_TAG: { category, event: 'Colour tag checkbox clicked' },
    RENAMED_COLOUR_TAG: { category, event: 'Colour tag renamed' },
    RENAMED_TAG: { category, event: 'Tag renamed' },
    DELETED_TAG: { category, event: 'Tag deleted' },
    CLICKED_LINKS_TO_CARD_PREVIEW: { category, event: 'Link to card preview checkbox clicked' },
    CLICKED_MORE_INSTRUCTIONS_LINK_TO_CARD: { category, event: 'Link to card preview instruction clicked' },
    CHANGED_TIME_ZONE: { category, event: 'Timezone changed' },
    ESTIMATE_TIME_ZONE: { category, event: 'Estimate time changed' },
    START_DATE: { category, event: 'Default start time changed' },
    DUE_DATE: { category, event: 'Default due time changed' },
    AUTO_START_DATE_TOGGLED: { category, event: 'Auto start date toggled' },
    CLICKED_MORE_INSTUCTIONS_TIME_ZONE: { category, event: 'Timezone instruction clicked' },
    EXPORTED_CARD_TITLES: { category, event: 'Card titles exported (CSV)' },
    EXPORTED_CARD_TITLES_AND_DESCRIPTION: { category, event: 'Card titles and description exported (CSV) ' },
    EXPORTED_ACTIVE_BOARD_DATA: { category, event: 'All active data exported (GoogleSheet)' },
    EXPORTED_ACTIVE_ARCHIVE_BOARD_DATA: { category, event: 'All active and archived data exported (GoogleSheet)' },
    EXPORTED_TIME_TRACKER_DATA: { category, event: 'TimeTracked data exported (GoogleSheet)' },
    CLOSED_BOARD_SETTINGS: { category, event: 'Board settings panel closed' },
    DESCRIPTION_CTRL_ENTER: { category, event: 'DESCRIPTION_CTRL_ENTER' },
    BOARD_DELETED: { category, event: 'Board deleted' },
    ASIDE_PANEL_RESIZED: { category, event: 'Aside panel resized' },
    BOARD_DETAILS_CLOSE_CLICKED: { category, event: 'Board details close clicked' },
    CARD_PREFIXES_TOGGLE_CLICKED: { category, event: 'card prefixes toggle clicked' },
    CARD_PREFIXES_SAVE_CLICKED: { category, event: 'card prefixes save clicked' },
    CARD_PREFIXES_FAQ_CLICKED: { category, event: 'card prefixes faq clicked' },
    CARD_PREFIXES_VALUE_CHANGED: { category, event: 'card prefixes value changed' },
    CARD_PREFIXES_GENERATE_CLICKED: { category, event: 'card prefixes generate clicked' },
    SHOW_ON_GANTT_CLICKED: { category, event: 'All cards on Gantt toggle clicked' },
    AUTO_BACKUP_TOGGLED: {category, event: ' Automatic backups toggle clicked'},
    CREATE_NEW_BACKUP_CLICKED: {category, event: ' Create new backup clicked'},
    ARCHIVE_ON_DELETE_TOGGLED: {category, event: 'Archive on deletion toggle clicked'},
    SEND_ME_UPDATES_ON_CLICKED:  {category, event: 'Send me updates on clicked'},
    NOTIFY_ME_WHEN_CLICKED:  {category, event: 'Notify me when clicked'},
    IMPORTANT_NOTIFICATION_CLICKED:  {category, event: 'Important notifications clicked'},
    SUBSCRIBE_ME_TO_CARD_CLICKED:  {category, event: 'Subscribe me to a card clicked'},
    CARD_DEFAULT_PROPERTY_CLICKED: { category, event: 'Card property checkbox clicked' },
    CARD_REQUIRED_PROPERTY_CLICKED: { category, event: 'Card property checkbox clicked' },
    CARD_DEFAULT_PROPERTIES_COUNT: { category, event: 'default field' },
    OPEN_IN_DRIVE_CLICKED: {category, event: 'open in drive clicked'},
    ROLE_CHECKED: {category, event: 'role checked'},
    WORKING_ON_HOLD_TOGGLE_CLICKED: {category, event: 'Working/On hold toggle clicked' },
    DONE_LIST_TOGGLE_CLICKED: {category, event: 'Done list toggle clicked' },
    ON_HOLD_LIST_CHANGED: {category, event: 'on hold list changed' },
    WORKING_LIST_CHANGED: {category, event: 'working list changed' },
    DONE_LIST_CHANGED: {category, event: 'done list changed' },
    EXPORT_DATE_FORMAT_CHANGED: {category, event: 'export date format changed'},
    EXPORT_TIME_FORMAT_CHANGED: {category, event: 'export time format changed'},
    EXPORT_WITH_ARCHIVE_CHANGED: {category, event: 'export with archive changed'},
    EXPORT_COLUMN_CLICKED: {category, event: 'export column clicked'},
    EXPORT_SELECT_ALL_COLUMNS_CLICKED: {category, event: 'export select all columns clicked'},
    EXPORT_DESELECT_ALL_COLUMNS_CLICKED: {category, event: 'export deselect all columns clicked'},
    EXPORT_CHECKLISTS_ROWS_CLICKED: {category, event: 'export checklists rows clicked'},
    AUTO_EXPORT_TOGGLED: {category, event: 'Automatic export toggle clicked'},
    AUTO_EXPORT_INCLUDE_ARCHIVED_CLICKED: {category, event: 'Include archived data clicked'},
    CARD_AGING_CLICKED: {category, event: 'Card aging clicked'},
    CARD_AGING_INDICATOR_CLICKED: {category, event: 'Card aging indicator clicked'},
    CARD_AGING_OPACITY_CLICKED: {category, event: 'Card aging opacity clicked'},
    CARD_AGING_WITHOUT_ACTIVITY_CLICKED: {category, event: 'Card aging without activity clicked'},
    CARD_AGING_IN_LIST_CLICKED: {category, event: 'Card aging in list clicked'},
    BOARD_ATTACHMENTS_OPEN_CARD_CLICKED: {category, event: 'Attachments open card clicked'},
    BOARD_ATTACHMENTS_DOWNLOAD_CLICKED: {category, event: 'Attachments download clicked'},
    BOARD_ATTACHMENTS_LINK_CLICKED: {category, event: 'Attachments link clicked'},
    BOARD_ATTACHMENTS_TAB_CLICKED: {category, event: 'Attachments tab clicked'},
    BOARD_ATTACHMENTS_MEDIA_CLICKED: {category, event: 'Attachments media clicked'},
    BOARD_ATTACHMENTS_DOCUMENTS_CLICKED: {category, event: 'Attachments documents clicked'},
    BOARD_ATTACHMENTS_ALL_CLICKED: {category, event: 'Attachments all clicked'},
    BOARD_ATTACHMENTS_SEARCH_CLICKED: {category, event: 'Attachments search clicked'},
    BOARD_ATTACHMENTS_VIEW_TYPE_CLICKED: {category, event: 'attachments view type clicked'},
    IMPORT_TO_BOARD: {category, event: 'Imports csv clicked'},
    BOARD_SHOW_MORE_DESCRIPTION_CLICKED: {category, event: 'Board show more description clicked'},
    BOARD_SHOW_LESS_DESCRIPTION_CLICKED: {category, event: 'Board show less description clicked'},
    BOARD_SHARED: {category, event: 'board shared'},
    BOARD_ACTIVITY_TAB_OPENED: {category, event: 'Activity tab opened'},
};

export enum SegmentBoardOption {
    LOCATION = 'Location',
    DIRECTION = 'Direction',
    SHARED_WITH_OTHER = 'Shared with other',
    TARGET = 'target',
    STATUS = 'status',
    TYPE = 'type',
    VIEW = 'view',
    BOARDS_COUNT = 'boards count',
    LIST_COUNT = 'list count',
    CARD_COUNT = 'card count',
    SOURCE = 'source',
    VALUE = 'value',
    LOADING_TIME = 'loading time',
    ID = 'id',
    ASSIGNEES_COUNT = 'assignees count',
    CARD_BACKGROUND_COLORS_COUNT = 'background colors count',
    SUBCARDS_COUNT = 'subcards count',
    CARD_AGING = 'card aging',
    CARD_SORTING = 'card sorting'
}

export enum SegmentBoardOptionValue {
    MY_DRIVE = 'my drive',
    SHARED_WITH_ME = 'shared with me',
    SHARED_DRIVE = 'shared drive'
}

export enum SegmentBoardOptionTargetValue {
    BOARD_MENU = 'board menu',
    BOARD_DETAILS = 'board details',
    BOARD_DBL_CLICK = 'double click'
}

export enum SegmentBoardOptionSharedWithOtherValue {
    TRUE = 'true',
    FALSE = 'false'
}

export enum SegmentBoardOptionStatusValue {
    ON = 'on',
    OFF = 'off'
}

export enum SegmentBoardAutoStartSourceValue {
    BOARD_SETTINGS = 'board settings',
    HINT = 'hint'
}

export enum SegmentBoardOptionCardPrefixesTypeValue {
    PREFIX = 'prefix',
    NUMBER = 'number'
}

export enum SegmentBoardOptionCardPrefixesVariant {
    FUTURE = 'future',
    EXISTING = 'existing',
    EXISTING_AND_FUTURE = 'existing and future'
}

export enum SegmentBoardOptionSendMeUpdateOnTypeValue {
    ALL_CARDS = 'all cards',
    WATCH = 'cards subscribed to',
    ASSIGNED_TO_ME = 'cards assigned to me'
}

export enum SegmentBoardOptionImportantTypeValue {
    MENTIONS_ME = 'mentions me',
    CREATE_CARD = 'create card',
    ASSIGNED_TO_ME = 'cards assigned to me'
}

export enum SegmentBoardOptionSubscribeCaseTypeValue {
    CARD_CREATE = 'card create',
    COMMENT_MENTION = 'comment mention',
    ASSIGN = 'assign',
    COMMENT_ADD = 'comment add'
}

export enum SegmentBoardOptionNotifyTypeValue {
    NAME_CHANGED = 'card name changed',
    DESC_CHANGED = 'card description changed',
    USER_ASSIGNED = 'card user assigned',
    PRIORITY_CHANGED = 'card priority changed',
    COMMENT_ADDED = 'comment added',
    ATTACH_ADDED = 'card attach added',
    DELETED = 'card deleted',
    ARCHIVED = 'card archived',
    LIST_CHANGE = 'card list change',
    OVERDUE = 'card overdue'
}

export enum SegmentBoardOptionSourceValue {
    BOARD = 'board',
    CARD = 'card'
}

export enum SegmentBoardOptionTimeFormatValue {
    HOURS_12 = '12',
    HOURS_24 = '24'
}

export enum SegmentBoardDoneListType {
    LIST = 'list',
    ARCHIVE = 'archive'
}

export enum SegmentBoardCardPropertiesType {
    DEFAULT = 'default',
    REQUIRED = 'required'
}

export enum SegmentBoardViewType {
    LIST = 'list',
    GRID = 'grid'
}

export enum SegmentBoardSettingsSourceValue {
    CARD_AGING_SNACKBAR = 'card aging snackbar',
    CARD_ASSIGNEES_EDIT_ROLES = 'card assignees edit roles',
    CARD_COLOR_TAGS = 'card color tags',
    CARD_DATES_DATEPICKER_HINT = 'card dates datepicker hint',
    CARD_PROPERTIES_SETTINGS = 'card properties settings',
    CARD_WATCH_ICON = 'card watch icon',
    CARD_WATCH_SETTINGS = 'card watch settings',
    LIST_HEADER_STATUS_LIST = 'list header status list',
    LIST_DELETE_SNACKBAR = 'list delete snackbar',
    LIST_DETAILS_AGING_SETTINGS = 'list details aging settings',
    LIST_MOVE_SNACKBAR = 'list move snackbar',
    NAVIGATION_PANEL = 'navigation panel',
    NEW_CARD_COLOR_TAGS = 'new card color tags',
    NOTIFICATIONS = 'notifications',
    TIME_ZONE_INFORMER = 'time zone informer',
    TOP_BAR_DROPDOWN = 'top bar dropdown'
}
