import { AT_ROLE_UPDATE, IRoleUpdateAction } from './types';
import { ICardAssigneeRole } from '../../../../types/model';
import { TCardAssigneeRoleId } from '../../../../types/types';

export const updateRoleAction = (
    roleId: TCardAssigneeRoleId,
    role: ICardAssigneeRole
): IRoleUpdateAction => {
    return {
        type: AT_ROLE_UPDATE,
        roleId,
        role
    }
};
