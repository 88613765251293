import { IApplicationState } from '../../../../../../../types/types';
import { connect } from 'react-redux';
import { IExportDateEvents, IExportDateFields } from '../../components/ExportDate/types';
import { ExportDate } from '../../components/ExportDate/ExportDate';
import { IExportDateHOCProps } from './types';
import { onUpdate } from './events/onUpdate';
import { IBackupSchedule } from '../../../../../../../store/model/boardBackups/types';
import {
    getBackupScheduleByBoardAndExportType
} from '../../../../../../../store/model/boardBackups/selectors/getBackupScheduleByBoardAndExportType';
import { mapRestBackupToFormRecurring } from '../../helpers/mapFormRecurringToRestBackup';
import { getBoardExportItems } from '../../store/selectors/getBoardExportItems';

const mapStateToProps = (
    state: IApplicationState,
    { boardId }: IExportDateHOCProps
): IExportDateFields => {
    const backupSchedule: IBackupSchedule = getBackupScheduleByBoardAndExportType(state, boardId);
    const exportForm = mapRestBackupToFormRecurring(backupSchedule);
    return {
        boardId,
        exportForm,
        isExports: !!getBoardExportItems(state, boardId).length
    }
};

const mapDispatchToProps = (
    dispatch: any,
    { boardId }: IExportDateHOCProps
): IExportDateEvents => {
    return {
        updateForm: (exportForm) => dispatch(onUpdate(boardId, exportForm))
    }
};

export const ExportDateHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(ExportDate);

ExportDateHOC.displayName = 'ExportDateHOC';
