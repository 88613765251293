import * as React from 'react';
import { ITabActivityContext, ITabActivityProps } from './types';
import './_TabActivity.scss';
import { CLASS_BOARD_ACTIVITY, SCROLL_OFFSET, TabActivityContext } from './constants';
import { LoaderBlock } from 'kui';
import { CLASS_ASIDE_PANEL_BODY } from '../../../../../asidePanel/components/AsidePanelBody/constants';
import { ActivityItem } from '../ActivityItem/ActivityItem';
import { CLASS_ACTIVITY_ITEM } from '../constants';

export function TabActivity({
    activityGroups,
    asidePanelWidth,
    fetchActivities,
    lastClickedActivityId,
    objectId,
    fetchOptions,
    newActivitiesCount,
    userId,
    onOpenCard,
    onOpenList,
    onScrollToList,
    resetLastClickedActivity
}: ITabActivityProps) {
    const className = CLASS_BOARD_ACTIVITY;
    const classGroup = className + '__group';

    let [ loading, _setLoading ] = React.useState(false);
    const data = React.useRef({ cursor: null, option: 0, loading, activityGroups });
    data.current = { ...data.current, activityGroups, loading }

    const setLoading = (value: boolean) => {
        loading = value;
        data.current.loading = value;
        _setLoading(value);
    };

    const scrollRef = React.useRef(null);
    const wrapperRef = React.useRef(null);

    const fetch = (): Promise<void> => {
        const initActivities = !data.current.cursor && !data.current.option;
        return fetchActivities(data.current.cursor, fetchOptions ? fetchOptions[data.current.option] : undefined, initActivities)
            .then((result) => {
                data.current.cursor = result.cursor;
                if (!result.cursor && fetchOptions && data.current.option < fetchOptions.length - 1) {
                    data.current.option += 1;
                    return fetch();
                }
            })
    }

    const loadActivities = () => {
        if (data.current.loading) return;
        setLoading(true);
        fetch().finally(() => {
            setLoading(false);
            if (lastClickedActivityId) {
                const activity = document.querySelector(`.${CLASS_ACTIVITY_ITEM}--${lastClickedActivityId}`) as HTMLElement;
                if (activity) {
                    setTimeout(() => {
                        activity.scrollIntoView({ block: 'center' });
                        resetLastClickedActivity();
                    }, 0);
                } else {
                    loadActivities();
                }
            }
        })
    };

    let prevScrollTop = scrollRef.current && scrollRef.current.scrollTop || 0;
    const onScroll = () => {
        if (!scrollRef.current) return;

        const toScrollEnd = scrollRef.current.scrollHeight - scrollRef.current.offsetHeight - scrollRef.current.scrollTop;
        if (toScrollEnd <= SCROLL_OFFSET &&
            data.current.cursor &&
            prevScrollTop <= scrollRef.current.scrollTop
        ) {
            loadActivities();
        }
        prevScrollTop = scrollRef.current.scrollTop;
    };

    React.useEffect(() => {
        loadActivities();
    }, [objectId]);

    /**
     * надо слушать скрол родителя (CLASS_ASIDE_PANEL_BODY)
     * т.к. скролы боди и панели кнопок связаны
     */

    React.useEffect(() => {
        scrollRef.current = document.querySelector('.' + CLASS_ASIDE_PANEL_BODY) as HTMLElement;
        if (scrollRef.current) {
            scrollRef.current.addEventListener('scroll', onScroll);
        }
        if (scrollRef.current) {
            return () => scrollRef.current.removeEventListener('scroll', onScroll);
        }
    }, []);

    const [context] = React.useState<ITabActivityContext>({
        onOpenCard,
        onOpenList,
        onScrollToList
    });

    return (
        <TabActivityContext.Provider value={context}>
            <div
                className={`${className} ${!activityGroups || !activityGroups.length ? className + '--empty' : ''}`}
                ref={wrapperRef}
            >
                {activityGroups.map(group => (
                    <div className={classGroup} key={group.date}>
                        <div className={classGroup + '-title'}>{group.title}</div>
                        <div className={classGroup + '-activities'}>
                            {group.activities.map((activity, index) => {
                                //@ts-ignore
                                return <ActivityItem
                                    activity={activity}
                                    isHighlight={newActivitiesCount && index < newActivitiesCount}
                                    userId={userId}
                                    asidePanelWidth={asidePanelWidth}
                                    key={activity.id}
                                />
                            })}
                        </div>
                    </div>
                ))}
                {loading &&
                <div className={className + '-loader'}>
                  <LoaderBlock />
                </div>
                }
            </div>
        </TabActivityContext.Provider>
    );
}
