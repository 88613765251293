import { IApplicationState, TCardId } from '../../../types/types';
import { getCard } from './getCard';
import { IRestMoveToBoard } from '../../../types/rest/IRestCardMeta';

export const getCardMetaMoveToBoards = (
    state: IApplicationState,
    cardId: TCardId,
): IRestMoveToBoard[] => {
    const card = getCard(state, cardId);
    if (
        !card ||
        !card.meta
    ) return [];

    return card.meta.moveToBoards || [];
};
