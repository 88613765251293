import { root } from 'app/store/constants';
import { Dispatch, ThunkAction } from 'app/types/actions';

export function onClickShortcuts (
): ThunkAction {
    const action = (
        dispatch: Dispatch
    ) => {
        root.App.controller.openKeyboardShortcuts();
    };
    return action
};
