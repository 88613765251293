import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { IGetState, TBoardId } from '../../../../../../../../../types/types';
import { topBarSetAction } from '../../../../../store/topBarBoard/actions/topBarSetAction';
import { isShareHintShowSetAction } from '../../../../../store/topBar/actions/isShareHintShowSetAction';
import { openShare } from './openShare';
import { getAuthUserIsDontShowShareHint } from '../../../../../../../../../store/model/authUser/selectors/getAuthUserIsDontShowShareHint';

export const shareClick = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(openShare(boardId));

        const state = getState();
        const isDontShowShareHint = getAuthUserIsDontShowShareHint(state);
        if (!isDontShowShareHint) {
            dispatch(topBarSetAction(boardId, isShareHintShowSetAction(true)));
        }
    };
    return action;
};
