import { IApplicationState } from '../../../../types/types';
import { getAuthUser } from './getAuthUser';
import { TSortedSavedFiltersIds } from '../../../../types/rest/IUserMeta';

export const getAuthUserSortedSavedFiltersIds = (
    state: IApplicationState
): TSortedSavedFiltersIds => {
    const user = getAuthUser(state);

    return user.meta && user.meta.sortedSavedFiltersIds;
};
