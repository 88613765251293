import { SegmentProfileBoxEvent, segmentTrackAction } from 'app/middlewares/segment';
import { root } from 'app/store/constants';
import { Dispatch, ThunkAction } from 'app/types/actions';

export function onClickPricing(
): ThunkAction {
    const action = (
        dispatch: Dispatch
    ) => {
        root.App.controller.showPayment();
        dispatch(segmentTrackAction(SegmentProfileBoxEvent.PRICING_ROW));
    };
    return action
};
