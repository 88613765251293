import { Dispatch, ThunkAction } from 'app/types/actions';
import { TCardId } from 'app/types/types';
import {
    SegmentCardEvent,
    SegmentCardOptions, SegmentCardOptionStatusValue,
    SegmentCardSourceValue
} from 'app/middlewares/segment/trackEntities/cardEvents';
import { cardToggleDone } from 'app/rest/effects/card/card/cardToggleDone';
import { segmentTrackAction } from 'app/middlewares/segment';
import { getCardDone } from 'app/store/model/selectors/getCardDone';
import { getAppState } from 'app/store/configureStore';

export const onDone = (
    cardId: TCardId,
    source: SegmentCardSourceValue
): ThunkAction => {
    return (dispatch: Dispatch) => {
        const done = getCardDone(getAppState(), cardId);
        dispatch(segmentTrackAction(SegmentCardEvent.DONE, {
                name: SegmentCardOptions.SOURCE,
                value: source
            },
            [{
                name: SegmentCardOptions.STATUS,
                value: done ? SegmentCardOptionStatusValue.OFF : SegmentCardOptionStatusValue.ON
            }]));
        dispatch(cardToggleDone(cardId));
    };
};
