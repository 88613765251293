import { AsidePanelActionsButton } from '../../../../..';
import * as React from 'react';
import { IColorsPanelButtonProps } from './types';
import { CLASS_BOARD_DETAILS_SECTION } from '../../../../constants';
import { BOARD_SECTION_COLORS } from '../../constants';

export function ColorsPanelButton ({
    indicatorNumber,
    tooltip,
    onClick
}: IColorsPanelButtonProps) {

    return (
        <AsidePanelActionsButton
            children={null}
            indicatorNumber={indicatorNumber}
            sectionSelector={`.${CLASS_BOARD_DETAILS_SECTION}--colors`}
            title={BOARD_SECTION_COLORS}
            tooltip={tooltip}
            onClick={onClick}
        />
    );
}
