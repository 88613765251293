import * as React from 'react';
import { Hint as KuiHint } from 'kui';
import { IHintProps } from './types';
import { vent, root } from '../../../../../store/constants';
import { FooterTutorial } from '../FooterTutorial/FooterTutorial';
import { HINTS_PORTAL_ID } from '../../constants';
import './_Hint.scss';
import { v4 as uuidv4 } from 'uuid';
import { CLASS_HINT_HIDE_ON_CLICK } from './constants';

export function Hint ({
    children,
    className,
    dontHide,
    footer,
    isHideOnAnyClick,
    isHideOnHintClick,
    mount,
    portalId,
    show,
    tutorialStep,
    onShow,
    onHide,
    isFirstStep,
    isLastStep,
    onTutorialNext,
    onTutorialPrevious,
    onTutorialSkip,
    ...attributes
}: IHintProps) {
    const [isShow, setShow] = React.useState(show);
    const [isMounted, setIsMounted] = React.useState(mount);
    const [hintKey, setHintKey] = React.useState(uuidv4());
    const timerRef = React.useRef(null);
    const resizeTimerRef = React.useRef(null);

    if (
        isHideOnAnyClick === undefined &&
        tutorialStep === undefined
    ) isHideOnAnyClick = true;

    if (tutorialStep !== undefined) {
        if (!footer) {
            const tutorialAttributes = {
                isFirstStep,
                isLastStep,
                onTutorialNext,
                onTutorialPrevious,
                onTutorialSkip,
            }
            footer = <FooterTutorial {...tutorialAttributes}/>;
        }
        if (attributes.isHidable === undefined) {
            attributes.isHidable = false;
        }
    }

    function onClick () {
        timerRef.current = setTimeout(() => setShow(false), 500); // wait onHideHandler
        removeListeners();
    };

    function onShowHandler () {
        if (isHideOnAnyClick) addListeners();
        if (onShow) onShow();
    };

    function onHideHandler () {
        removeListeners();
        setIsMounted(false);
        if (onHide && !dontHide) onHide();
    };

    function addListeners () {
        if (attributes.isHidable !== undefined && !attributes.isHidable) return;
        root.addEventListener('click', onClick);
        vent.on(vent['stopPropagation'], onClick);
    };

    function removeListeners () {
        root.removeEventListener('click', onClick);
        vent.off(vent['stopPropagation'], onClick);
    };

    React.useEffect(() => {
        setShow(show);
    }, [show]);

    React.useEffect(() => {
        if (isMounted !== mount && mount === true) {
            setIsMounted(true);
        }
        else if (isMounted !== mount && mount === false && !dontHide){
            if (onHide) onHide();
        }
    }, [mount]);

    React.useEffect(() => {
        if (!isHideOnHintClick || !isShow) return;
        setTimeout(() => { // подождать пока появится хинт
            const tooltip = document.querySelector(`.${CLASS_HINT_HIDE_ON_CLICK}`) as HTMLElement;
            if (tooltip) tooltip.addEventListener('click', onClick);
        }, 500);

        return () => {
            const tooltip = document.querySelector(`.${CLASS_HINT_HIDE_ON_CLICK}`) as HTMLElement;
            if (tooltip) tooltip.removeEventListener('click', onClick);
        }
    }, [isShow]);

    React.useEffect(() => {
        function onResizeWindow() {
            setHintKey(null);
            if (resizeTimerRef.current) clearTimeout(resizeTimerRef.current);
            resizeTimerRef.current = setTimeout(() => {
                setHintKey(uuidv4())
            }, 500)
        }
        root.removeEventListener('resize', onResizeWindow);
        root.addEventListener('resize', onResizeWindow);

        return () => {
            removeListeners();
            if (timerRef.current) clearTimeout(timerRef.current);
            if (resizeTimerRef.current) clearTimeout(resizeTimerRef.current);
            root.removeEventListener('resize', onResizeWindow);
        }
    }, []);

    const element = mount && hintKey
        ? <KuiHint
            footer={footer}
            portalId={portalId || HINTS_PORTAL_ID}
            show={isShow}
            onShow={onShowHandler}
            onHide={onHideHandler}
            key={hintKey}
            className={`${className || ''} ${isHideOnHintClick ? CLASS_HINT_HIDE_ON_CLICK : ''}`}
            {...attributes}
        >
            {children}
        </KuiHint>
        : children || null;

    return element;
};
