import { IApplicationState, TCardId } from '../../../types/types';
import { isObjectEmpty } from '../../../view/react_components/helpers/isObjectEmpty';
import { getCardChecklists } from '../checklists/checklists/selectors/getCardChecklists';
import { getCard } from './getCard';

export const getCardHasChecklist = (
    state: IApplicationState,
    cardId: TCardId,
): boolean => {
    const card = getCard(state, cardId);
    if (!card) return;
    if (card.checklistStats && !!card.checklistStats.totalCount) return true; // короткая модель

    const checklists = getCardChecklists(state, cardId);
    if (!checklists) return false;

    for (let checklistId in checklists) {
        const checklist = checklists[checklistId];
        const items = !!checklist && checklist.checkItems;
        if (items && !isObjectEmpty(items)) {
                return true;
        }
    }

    return false;
}
