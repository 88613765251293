import { TCardId } from '../types/types';
import { IDriveDoc } from '../store/model/card/types/IDriveDoc';

interface ICardsCommentInfo {
    [cardId: number]: ICommentInfo;
}

interface ICommentInfo {
    attachments?: IDriveDoc[];
    value?: string;
}

export class CardsCommentInfoHelper {
    cards: ICardsCommentInfo = {};

    setValue(
        cardId: TCardId,
        value: string
    ) {
        this.cards = {
            ...this.cards,
            [cardId]: {
                ...this.cards[cardId],
                value,
            }
        }
    }

    setAttachments(
        cardId: TCardId,
        attachments: IDriveDoc[]
    ) {
        this.cards = {
            ...this.cards,
            [cardId]: {
                ...this.cards[cardId],
                attachments,
            }
        }
    }

    resetCardComment(
        cardId: TCardId
    ) {
        this.cards = {
            ...this.cards,
            [cardId]: null
        }
    }

    getValue(cardId: TCardId) {
        return this.cards[cardId] && this.cards[cardId].value || '';
    }

    getAttachments(cardId: TCardId) {
        return this.cards[cardId] && this.cards[cardId].attachments || [];
    }
}
