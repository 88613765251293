import './_Poll.scss';
import { IPollProps, IQuestion } from './types';
import * as React from 'react';
import {
    POLL_BUTTON_ADD_OPINION,
    POLL_BUTTON_BACK,
    POLL_BUTTON_CANCEL,
    POLL_BUTTON_LATER,
    POLL_BUTTON_SEND,
    POLL_DOWNLOADS_COUNT,
    POLL_MARKET_NAME_GOOGLE,
    POLL_MARKET_NAME_MICROSOFT,
    POLL_MIN_POSITIVE_ANSWER,
    POLL_NOTIFICATION_TEXT,
    POLL_PLACEHOLDER,
    POLL_RATE,
    POLL_RATING,
    POLL_RATING_COUNT
} from './constants';
import { Button, Icon, Input } from 'kui';
import { PollQuestion } from '../PollQuestion/PollQuestion';
import { isArray, isNumber } from 'underscore';
import { CLASS_POLL, POLL_DELAY_AFTER, POLL_DELAY_AFTER_HOVER, POLL_RATING_OPTIONS } from '../constants';
import { ONE_DAY_LENGTH } from '../../../../../aside_panel/filterPanelBoard/constants';
import { GoogleMarketDownloadIcon } from './icons/GoogleMarketDownloadIcon';
import { EAuthUserPlatformType } from '../../../../../../../types/rest/IRestAuthUser';
import { EPollQuestionVariant } from '../PollQuestion/types';

export function Poll({
    backButtonLabel,
    className: classNameOwn,
    isHideOnCancel,
    isShow,
    platformType,
    questionsList = [],
    title,
    reviewPlaceholder,
    reviewTitle,
    variant = EPollQuestionVariant.RADIO,
    onAddOpinion,
    onShow,
    onSkip,
    onSubmit,
    onRate,
}: IPollProps) {
    const className = CLASS_POLL;
    const classHeader = className + '__header';
    const classButton = className + '__button';
    const classInfo = className + '__info';
    const classMarket = className + '__market';
    const classStar = classInfo + '-star';

    const [questions, setQuestions] = React.useState<IQuestion[]>([]);
    const [isShowReview, setShowReview] = React.useState(null);
    const [inputValue, setValue] = React.useState('');
    const [isDone, setDone] = React.useState(null);
    const [isShowPoll, setShowPoll] = React.useState(null);
    const [isDisabled, setDisabled] = React.useState(null);
    const [isPositive, setPositive] = React.useState(null);
    const [isHovered, setHovered] = React.useState(null);
    const [isHideDone, setHideDone] = React.useState(null);

    const isHideTimer = React.useRef(null);
    const isHoverHideTimer = React.useRef(null);

    const onChange = (e: React.SyntheticEvent) => {
        const target = e.target as HTMLInputElement;
        setValue(target.value);
    };

    const onSubmitHandler = () => {
        setDone(true);
        const results = [
            ...questions,
            {
                question: title,
                answer: inputValue
            }
        ];
        setHideDone(isHideOnCancel && !inputValue.length);
        onSubmit(results, title);
        isHideTimer.current = setTimeout(() => {
            setShowPoll(false);
        }, isPositive ? ONE_DAY_LENGTH : POLL_DELAY_AFTER);
    };

    const onAnswer = (
        question: string,
        value: number | string
    ) => {
        setQuestions(questions.map(item => {
            let answer = item.answer;
            if (item.question === question || !question) {
                if (item.isChips) {
                    const answers = item.answer && isArray(item.answer) ? item.answer : [];
                    if (answers.includes(value)) {
                        answer = answers.filter(item => item !== value);
                    } else {
                        answer = [...answers.slice(-1), value];
                    }
                } else {
                    answer = value;
                }
            }
            return {
                ...item,
                answer
            };
        }));
    };

    React.useEffect(() => {
        const lastQuestion = questions[questions.length - 1];
        if (
            lastQuestion &&
            lastQuestion.options &&
            lastQuestion.answer
        ) {
            const option = lastQuestion.options.find(option => option.label === lastQuestion.answer);
            if (
                option && option.isSkipReview ||
                isArray(lastQuestion.answer) && lastQuestion.answer.length === 2
            ) {
                setDone(true);
                onSubmit(questions, title);
                isHideTimer.current = setTimeout(() => {
                    setShowPoll(false);
                }, ONE_DAY_LENGTH);
            } else if (!lastQuestion.isChips) {
                setShowReview(true);
            }
        } else {
            setShowReview(!questions.filter((question => !isNumber(question.answer))).length);
        }
    }, [questions]);

    React.useEffect(() => {
        return () => {
            if (isHideTimer.current) clearTimeout(isHideTimer.current);
        }
    }, []);

    React.useEffect(() => {
        if (!questions.length) return;
        let isPositive = true;
        for (let i = 0; i < questions.length; i++) {
            if (!questions[i].answer || questions[i].answer < POLL_MIN_POSITIVE_ANSWER) {
                isPositive = false;
                break;
            }
        }
        setPositive(isPositive);
    }, [questions, isShow]);

    React.useEffect(() => {
        if (isPositive && isDone) return;
        setShowPoll(isShow);

        if (isShow && onShow) {
            onShow(title);
        }
    }, [isShow]);

    React.useEffect(() => {
        if (isHovered && isHoverHideTimer.current) {
            clearTimeout(isHoverHideTimer.current);
        }
        if (isDone && isPositive && !isHovered) {
            isHoverHideTimer.current = setTimeout(() => {
                setShowPoll(false);
            }, POLL_DELAY_AFTER_HOVER);
        }
    }, [isHovered]);

    React.useEffect(() => {
        if (!isShow) return;
        setQuestions(questionsList.map(question => { return question }));
    }, [isShow]);

    React.useEffect(() => {
        if (!isPositive) return;
        setHovered(true);
    }, [isDone]);

    if (!isShowPoll) return;

    let rateEl;

    switch (platformType) {
        case EAuthUserPlatformType.GOOGLE: {
            rateEl = <>
                <div className={classMarket}>
                    {POLL_RATE}
                    <div className={classMarket + '-store'}>
                        <><Icon size={16} xlink={'google-color'} />{POLL_MARKET_NAME_GOOGLE}</>
                    </div>
                </div>
                <div className={classInfo}>
                    <div className={classInfo + '-rating'}>
                        {POLL_RATING_OPTIONS.map((item, idx) => {
                            let dec = idx + 1 - POLL_RATING;
                            let isHalf = dec > 0 && dec < 1;
                            let style = isHalf
                                ? {
                                    clipPath: `inset(0 ${dec * 100}% 0 0)`
                                }
                                : null;
                            return <div className={`${classStar}--wrap`}>
                                {isHalf &&
                                    <Icon
                                        className={`
                                                ${classStar}
                                                ${classStar}--empty
                                            `}
                                        key={'empty-star'}
                                        xlink={'star'}
                                        size={24}
                                    />
                                }
                                <Icon
                                    className={`
                                            ${classStar}
                                            ${dec < 0
                                        ? classStar + '--full'
                                        : dec < 1 ? classStar + '--half' : ''}
                                        `}
                                    key={idx}
                                    xlink={'star'}
                                    size={24}
                                    style={style}
                                />
                            </div>
                        })}
                        {POLL_RATING_COUNT}
                    </div>
                    <div className={classInfo + '-downloads'}>
                        <GoogleMarketDownloadIcon />
                        {POLL_DOWNLOADS_COUNT}
                    </div>
                </div>
            </>;
            break;
        }
        case EAuthUserPlatformType.MICROSOFT: {
            rateEl = <div className={classMarket}>
                {POLL_RATE}
                <div className={classMarket + '-store'}>
                    <Icon size={16} xlink={'microsoft'} />{POLL_MARKET_NAME_MICROSOFT}
                </div>
            </div>;
            break;
        }
    }

    return <div
        className={`
            ${className}
            ${classNameOwn || ''}
            ${isDone ? className + '--done' : ''}
            ${isDone && isPositive ? className + '--rating' : ''}
            ${isShowReview ? classHeader + '--review' : ''}
            ${isHideDone ? className + '--hide' : ''}
        `}
        onClick={() => {
            if (isPositive && isDone) {
                onRate();
            }
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
    >
        {!isDone &&
            <div className={classHeader}>
                    <span className={classHeader + '-title'}>
                        {isShowReview ? reviewTitle : title}
                    </span>
                {(!isShowReview || questions.length === 1 && questions[0].isChips) &&
                    <Button
                        className={classHeader + '-button'}
                        onClick={() => onSkip(title)}
                        disabled={isDisabled}
                    >
                        {POLL_BUTTON_LATER}
                    </Button>
                }
            </div>
        }
        {!isDone && !isShowReview && questions &&
            <>
                {
                    questions.map(({ question, answer, options, isChips }) => (
                        <PollQuestion
                            key={question}
                            isChips={isChips}
                            question={question}
                            isDisabled={isDisabled}
                            setDisabled={setDisabled}
                            options={options}
                            value={answer}
                            setValue={value => onAnswer(question, value)}
                            isHideTitle={questionsList.length === 1}
                            variant={variant}
                        />
                    ))
                }
                {questions.length === 1 && questions[0].isChips &&
                    <Button
                        className={className + '__add-opinion'}
                        onClick={() => {
                            onAddOpinion();
                            setShowReview(true);
                        }}
                    >
                        {POLL_BUTTON_ADD_OPINION}
                    </Button>
                }
            </>
        }
        {isShowReview && !isDone &&
            <>
                <Input
                    className={className + '__input'}
                    autoFocus
                    value={inputValue}
                    onChange={onChange}
                    placeholder={reviewPlaceholder || POLL_PLACEHOLDER}
                    multiple
                />
                <div className={className + '__footer'}>
                    <Button
                        className={`${classButton} ${classButton}--back`}
                        onClick={() => {
                            const lastQuestion = questions[questions.length - 1];
                            const question: IQuestion = {
                                ...lastQuestion,
                                answer: lastQuestion.isChips ? lastQuestion.answer : null
                            };
                            setQuestions([...questions.slice(0, -1), question]);
                            setShowReview(false);
                        }}
                    >
                        {backButtonLabel || POLL_BUTTON_BACK}
                    </Button>
                    <Button
                        className={inputValue.length ? null : classButton}
                        onClick={onSubmitHandler}
                    >
                        {inputValue.length ? POLL_BUTTON_SEND : POLL_BUTTON_CANCEL}
                    </Button>
                </div>
            </>
        }
        {isDone &&
            <>
                <span>{POLL_NOTIFICATION_TEXT}</span>
                {isPositive && rateEl}
            </>
        }
    </div>;
}
