import { Dispatch, ThunkAction } from '../../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../../types/types';
import { getCardPinnedSections } from '../../../../../../store/model/selectors/getCardPinnedSections';
import { cardRestGetSections } from './cardRestGetSections';
import { IRestPinnedSections } from '../../../../../../types/rest/IRestCardMeta';
import { isObjectEmpty } from '../../../../../../view/react_components/helpers/isObjectEmpty';
import { getCard } from '../../../../../../store/model/selectors/getCard';

export const cardRestGetPinnedSections = (
    cardId: TCardId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const sections = getCardPinnedSections(getCard(getState(), cardId));
        if (
            isObjectEmpty(sections)
        ) return Promise.resolve();
        return dispatch(cardRestGetSections([{
            cardId,
            sections: Object.keys(sections) as Array<keyof IRestPinnedSections>
        }]));
    };
    return action;
};
