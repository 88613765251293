import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { IGetState, TBoardId } from '../../../../../../../../../types/types';
import { getCardsWithoutCardNumber } from '../selectors/getCardsWithoutCardNumber';
import { ICard } from '../../../../../../../../../store/model/card/types';
import { cardGenerateCardNumber } from '../../../../../../../../../rest/effects/card/card/cardGenerateCardNumber';
import { root } from '../../../../../../../../../store/constants';
import { loadNumberNextValue } from './loadNumberNextValue';
import { IRestBoardCardNumber } from '../types';
import { cardNumberSetAction } from '../../../store/cardNumbers/actions/cardNumberSetAction';
import { cardNumberStateUpdateAction } from '../../../store/cardNumber/actions/cardNumberUpdate';

export const generatePrefixes = (
    boardId: TBoardId,
    abortController: AbortController,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        root.App.controller.boardCardPrefixInfo.resetBoarCardPrefixInfo();

        const cards = getCardsWithoutCardNumber(getState(), boardId);
        if (!cards.length) return Promise.resolve();

        cards.sort((card1, card2) => card1.created - card2.created);

        root.App.controller.boardCardPrefixInfo.totalCardsCount = cards.length;

        return generatePrefixForFirstCard(cards, dispatch, abortController)
            .then(() => {
                dispatch(loadNumberNextValue(boardId)).then((result: IRestBoardCardNumber) => {
                    if (!result || !result.cardNumberNextValue) return;
                    dispatch(cardNumberSetAction(boardId, cardNumberStateUpdateAction({
                        nextValue: result.cardNumberNextValue.toString()
                    })));
                });
            })
    }
    return action;
}

const generatePrefixForFirstCard = (
    cards: ICard[],
    dispatch: Dispatch,
    abortController: AbortController
) => {
    const card = cards.shift();
    if (!card) {
        return Promise.resolve();
    }
    root.App.controller.boardCardPrefixInfo.addCardForRollback({ id: card.id, cardNumber: card.cardNumber || '' });
    return dispatch(cardGenerateCardNumber(card.id, true, abortController))
        .then(() => {
            root.App.controller.boardCardPrefixInfo.addedCardsCount = root.App.controller.boardCardPrefixInfo.totalCardsCount - cards.length;
            return generatePrefixForFirstCard(cards, dispatch, abortController);
        });
}
