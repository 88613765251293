import { IApplicationState, TCardId } from '../../../../../types/types'
import { IChecklist } from '../../checklist/types';
import { getCardChecklists } from './getCardChecklists';

export const getCardChecklistsSorted = (
    state: IApplicationState,
    cardId: TCardId,
): IChecklist[] => {
    return Object.values(getCardChecklists(state, cardId)).sort(sortChecklists);
};

export const sortChecklists = (
    a: IChecklist,
    b: IChecklist
) => {
    if (a.name === b.name) {
        return b.id - a.id;
    }
    return a.name.localeCompare(b.name);
}
