import { getMessages } from '../../../../../../store/constants';

export const BOARD_SECTION_PWC_IMPORT = getMessages().getText('board_settings.pwc_import.title');
export const BOARD_PWC_IMPORT_TEXT = getMessages().getText('board_settings.pwc_import.choose_file');
export const BOARD_PWC_IMPORT_TYPE_SUBCARDS = getMessages().getText('board_settings.pwc_import.type.subcards');
export const BOARD_PWC_IMPORT_TYPE_CHECKLISTS = getMessages().getText('board_settings.pwc_import.type.checklists');
export const BOARD_PWC_IMPORT_TYPE_TEMPLATE_CHECKLISTS = getMessages().getText('board_settings.pwc_import.type.template_checklist');

export const PWC_IMPORT_USERS: string[] = [
    'danila.ivanov@mail.magicwebsolutions.co.uk',
    'markus.tiigimae@pwc.com',
    'olga.bakulina@mail.magicwebsolutions.co.uk',
    'marlen.kuusk@pwc.com',
    'sandra.kuusik@pwc.com',
    'getlin.meetua@pwc.com',
    'laura.kurvet@pwc.com',
    'roman_v@magicwebsolutions.co.uk',
    'kanbanchi.autotester@gmail.com',
    'kanbanchi.autotester12@gmail.com',
    'ilya.khabibullin@kanbanchi.com',
    'katri.kahk@pwc.com',
    'mary-ann.tammiste@pwc.com',
    'andrey.philippov@kanbanchi.com'
];
