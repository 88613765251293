import { loadTestsState } from '../rest/loadTestsState';
import { IABTest } from '../types';
import { fillABTestsCollectedValue } from '../core/fillABTestsCollectedValue';
import { getABTests } from '../abTests/constants';
import { getNewOnLoadABTests } from '../core/getNewOnLoadABTests';
import { defineNewABTestsValue } from '../core/defineNewABTestsValue';
import { dispatchValues } from './dispatchValues';
import { saveNewTests } from './saveNewTests';
import { Dispatch, ThunkAction } from '../../types/actions';
import { IGetState } from '../../types/types';

export const executeOnLoadTests = (): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return loadTestsState().then((result) => {
            const allTests: IABTest[] = fillABTestsCollectedValue(getABTests(), result.available);
            const newABTestsForUser: IABTest[] = getNewOnLoadABTests(allTests, result.current);
            const newABTestsValues = defineNewABTestsValue(getState(), newABTestsForUser);
            dispatch(dispatchValues(result.current.concat(newABTestsValues))); // set all values to redux (current from REST and new ones)
            return dispatch(saveNewTests(newABTestsValues));
        });
    }
    return action;
};
