import * as React from 'react';
import { useState } from 'react';
import { Icon, Input, Modal } from 'kui';
import './TimeTrackerDialog.scss';
import { ITimeTrackerDialogProps } from './types';
import { TimeTrackerHelper } from '../../helpers/TimeTrackerHelper';
import { markdown } from '../../../../../../helper/markdownHelper';
import ReactHtmlParser from 'react-html-parser';
import { UserpicStorage } from 'app/view/react_components/base/components/UserpicStorage/UserpicStorage';

export function TimeTrackerDialog({
    initials,
    permissionId,
    photoUrl,
    fullName,
    cardName,
    period,
    your,
    total,
    estimate,
    workHoursInDay,

    onChangeTime,
    onClose,
    onSave,
    onReset
}: ITimeTrackerDialogProps) {
    let inputRef = React.useRef(null);
    let [showInput, setShowInput] = useState(false);
    let [inputValue, setInputValue] = useState('');
    let [timeValue, setTimeValue] = useState('');
    let [comment, setComment] = useState('');

    const yourString = TimeTrackerHelper.formatSecondsToString(your, workHoursInDay);
    const totalString = TimeTrackerHelper.formatSecondsToString(total, workHoursInDay);

    //auto update component
    React.useEffect(() => {
        const timerString = TimeTrackerHelper.formatSecondsToString(period, workHoursInDay);
        setInputValue(timerString);
    }, [showInput, period]);

    function toggleInputShow() {
        if (!showInput) {
            setTimeValue(inputValue);
        }
        setShowInput(!showInput);
    }

    function handleKeyDown(
        e: React.KeyboardEvent
    ) {
        if (e.key === 'Enter') {
            setShowInput(!showInput);
        }
    }

    function handleInputChange() {
        const inputEl = inputRef.current;
        const value = inputEl.getInput().value;
        onChangeTime(value);
    }

    function handleCommentChange(e: React.SyntheticEvent) {
        const target = e.target as HTMLInputElement;
        setComment(target.value);
    }

    function handleSave() {
        onSave(comment);
    }

    return (
        <Modal
            title={'Save progress'}
            variant={'actions'}
            className={'time-dialog'}
            onClose={onClose}
            buttons={[
                {
                    text: 'Reset Time',
                    onClick: onReset
                },
                {
                    text: 'Save',
                    isPrimary: true,
                    onClick: handleSave
                }
            ]}
        >
            <div className={'time-dialog__user-info'}>
                <div className={'time-dialog__user-meta'}>
                    <UserpicStorage
                        initials={initials}
                        src={photoUrl}
                        size={40}
                        userId={permissionId}
                    />
                    <span className={'time-dialog__user-name'}>
                        {fullName}
                    </span>
                </div>
                <div className={'time-dialog__time'}>
                    <span className={'time-dialog__time-value'}>
                        {
                            !showInput &&
                            <span onClick={toggleInputShow}>
                                {inputValue}
                                <Icon xlink={'pen'} size={24}/>
                            </span>
                        }
                        {
                            showInput &&
                            <Input
                                ref={inputRef}
                                className={'time-dialog__time-input'}
                                onBlur={toggleInputShow}
                                onChange={handleInputChange}
                                onKeyDown={handleKeyDown}
                                autosize={false}
                                autoFocus={true}
                                value={timeValue}
                            />
                        }
                    </span>
                </div>
            </div>
            <div className={'time-dialog__comment'}>
                <Input
                    className={'time-dialog__comment-el'}
                    value={comment}
                    onChange={handleCommentChange}
                    placeholder={'Add comment for progress'}
                />
            </div>
            <div className={'time-dialog__card-info'}>
                <div className={'time-dialog__card'}>
                    <Icon xlink={'card'} size={24} />
                    <span>{ReactHtmlParser(markdown(cardName))}</span>
                </div>
                <div className={'time-dialog__time'}>
                    <div className={'time-dialog__row'}>
                        <div className={'time-dialog__data-label'}>
                            Your spent time
                        </div>
                        <div className={'time-dialog__data-time'}>
                            {yourString}
                        </div>
                    </div>
                    <div className={'time-dialog__row'}>
                        <div className={'time-dialog__data-label'}>
                            Total spent time
                        </div>
                        <div className={'time-dialog__data-time'}>
                            {totalString}
                        </div>
                    </div>
                    <div className={'time-dialog__row'}>
                        <div className={'time-dialog__data-label'}>
                            Estimate
                        </div>
                        <div className={'time-dialog__data-time'}>
                            {estimate}
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
