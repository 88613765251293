import { IGetState } from '../../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { getRest, TGetRestActivityOptions } from '../../../../../../../../../rest/effects/activity/api/getRest';
import { IRestActivities } from '../../../../../../../../../types/rest/activity/IRestActivities';
import { activitiesInitAction } from '../../../../../../../../../store/activities/actions/activitiesInitAction';
import { updateActivities } from '../../../../../../../../../rest/effects/activity/updateActivities';

export const fetchActivities = (
    objectId: number,
    url: string,
    cursor: string,
    options: TGetRestActivityOptions = {},
    initActivities: boolean
): ThunkAction => {
    const action: ThunkAction = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (initActivities) {
            dispatch(activitiesInitAction());
        } // clear activities store

        return dispatch(getRest(objectId, url, cursor, options))
            .then((response: IRestActivities) => {
                dispatch(updateActivities(response.objects));
                return response;
            });
    };
    return action;
}
