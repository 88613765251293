import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { DashboardImportRouter } from '../../../../helpers/importBoard';
import { REST_JIRA_IMPORT } from '../../../../constants';
import {
    JITA_OPEN_FILE_MIME_FILTER,
    SNACKBAR_BOARD_IMPORT_ID,
    SNACKBAR_JIRA_IMPORT_ERROR_TEXT,
    SNACKBAR_JIRA_IMPORT_PROGRESS_TEXT
} from '../constants';

export const importJira = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,

    ) => {
        new DashboardImportRouter({
            importUrl: REST_JIRA_IMPORT,
            snackbarImportId: SNACKBAR_BOARD_IMPORT_ID,
            importProgressText: SNACKBAR_JIRA_IMPORT_PROGRESS_TEXT,
            importErrorText: SNACKBAR_JIRA_IMPORT_ERROR_TEXT,
            mimeTypesFilter: JITA_OPEN_FILE_MIME_FILTER
        }, dispatch).start();
    };
    return action;
};
