import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState, TCardId, TChecklistId } from '../../../../../../../types/types';
import { checklistItemAdd } from '../../../../../../../rest/effects/card/checklist/checklistItemAdd';
import { IChecklistItem } from '../../../../../../../store/model/checklists/checklist/types';
import {
    getChecklistItems
} from '../../../../../../../store/model/checklists/checklist/selectors/getChecklistItems';
import { CHECKLIST_DEFAULT_ID } from '../../../../../../../store/model/checklists/checklists/constants';
import { ICards } from '../../../../../../../store/model/cards/types';
import {
    cardsActiveRequestsUpdate
} from '../../../../../../../store/requestsState/effects/cardsActiveRequestsUpdate';

export const addChecklistItem = (
    cardId: TCardId,
    checklistId: TChecklistId,
    item: IChecklistItem,
    ignoreNewLines?: boolean
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const cards: ICards = {
            [cardId]: null
        };
        if (checklistId === CHECKLIST_DEFAULT_ID) {
            dispatch(cardsActiveRequestsUpdate(cards, 1));
        }
        if (!item || !item.name) return Promise.reject();
        const state = getState();
        const checklistItems = getChecklistItems(state, cardId, checklistId);
        const checklistItemsLength = checklistItems ? Object.values(checklistItems).length : 0;

        let promises;
        if (ignoreNewLines) {
            promises = [dispatch(checklistItemAdd(cardId, {
                orderNumber: checklistItemsLength + 1,
                checked: false,
                ...item,
                checklistId
            }))];
        } else {
            const lines = item.name.split('\n');
            promises = lines.reduce((promises: Promise<any>[], line: string, index) => {
                const name = !line ? '' : line.trim();
                if (name) {
                    promises.push(dispatch(checklistItemAdd(cardId, {
                        orderNumber: checklistItemsLength + index,
                        checked: false,
                        ...item,
                        checklistId,
                        name
                    })));
                }
                return promises;
            }, []);
        }
        return Promise.all(promises)
            .finally(() => dispatch(cardsActiveRequestsUpdate(cards, -1)));
    };
    return action;
};
