import { TBoardId } from '../../../../../types/types';
import { IApplicationState } from '../../../../../types/types';
import { IFilter } from '../../../../../view/react_components/aside_panel/filterPanelBoard/store/filter/types';
import { filterPanelBoardsSelector } from '../../../../../view/react_components/aside_panel/filterPanelBoard/store/selectors/filterPanelBoardsSelector';
import { getCurrentEditingFilterIdSelector } from '../../../../../view/react_components/aside_panel/filterPanelBoard/store/selectors/getCurrentEditingFilterIdSelector';
import { defaultOrEditedFilterSelectorById } from '../../../../../view/react_components/aside_panel/filterPanelBoard/store/selectors/defaultOrEditedFilterSelectorById';
import { DEFAULT_FILTER_ID } from '../../../../../view/react_components/aside_panel/filterPanelBoard/constants';
import { savedFilterSelector } from '../../../../../view/react_components/aside_panel/filterPanelBoard/store/selectors/savedFilterSelector';

export const getEnabledFilters = (
    state: IApplicationState,
    boardId: TBoardId
): IFilter[] => {
    const filterPanel = filterPanelBoardsSelector(state, boardId);
    if (!filterPanel.isEnabled && !filterPanel.isTopBarFiltrationEnabled) return [];

    const defaultOrEditedFilterId = getCurrentEditingFilterIdSelector(filterPanel);
    const defaultOrEditedFilter = defaultOrEditedFilterSelectorById(filterPanel, defaultOrEditedFilterId);

    const result: IFilter[] = [defaultOrEditedFilter];

    if (defaultOrEditedFilterId === DEFAULT_FILTER_ID && filterPanel.isEnabled) {
        const savedFilters = filterPanel.savedFilterIds.map(filterId => {
            return savedFilterSelector(state, filterId);
        });
        result.push(...savedFilters)
    }

    return result;
};
