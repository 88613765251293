'use strict';


import { getAuthUser } from '../store/model/authUser/selectors/getAuthUser';
import { getAppState } from '../store/configureStore';
import { commonAuthManagerInstance } from "app/helper/authorisation/common/CommonAuthManager";
import { getUserDefaultAttachmentType } from "app/store/model/authUser/selectors/getUserDefaultAttachmentType";
import { EDefaultAttachmentType } from "app/types/rest/IUserMeta";

App.Helpers.googleDriveOpenDialogue = {
    listenView: null,
    callback: false,
    cancelCallback: false,

    createPicker: function(oauthToken) {
    	oauthToken =  oauthToken || this.access_token;
    	if(!(oauthToken)){
    		return;
    	}
        //var view = new google.picker.View(google.picker.ViewId.DOCS);
        var teamDrivesView = new google.picker.DocsView(google.picker.ViewId.DOCS);
        teamDrivesView.setEnableDrives(true);
        teamDrivesView.setIncludeFolders(true);
        teamDrivesView.setSelectFolderEnabled(true);
        
        var sharedDocView = new google.picker.DocsView(google.picker.ViewId.DOCS);
        sharedDocView.setIncludeFolders(true);
        sharedDocView.setOwnedByMe(false);
        sharedDocView.setSelectFolderEnabled(true);
        
        var myDocView = new google.picker.DocsView(google.picker.ViewId.DOCS);
        myDocView.setIncludeFolders(true); 
        myDocView.setOwnedByMe(true);
        myDocView.setSelectFolderEnabled(true);

        var recentlyDocView = new google.picker.DocsView(google.picker.ViewId.RECENTLY_PICKED);
        
        var uploadView = new google.picker.DocsUploadView();

        const attachmentType = getUserDefaultAttachmentType(getAppState());

        this.picker = new google.picker.PickerBuilder().
        enableFeature(google.picker.Feature.MULTISELECT_ENABLED).
        enableFeature(google.picker.Feature.SUPPORT_TEAM_DRIVES).
        setAppId(App.appId).
        setOAuthToken(oauthToken).
        addView(attachmentType === EDefaultAttachmentType.DEVICE ? uploadView : myDocView).
        addView(attachmentType === EDefaultAttachmentType.DEVICE ? myDocView : uploadView);
        if (getAuthUser(getAppState()).allowAttacheTeamDriveFile) {
			this.picker = this.picker.addView(teamDrivesView);
		}
        this.picker = this.picker.addView(sharedDocView).
        addView(recentlyDocView).
        setCallback(this.pickerCallback).
        build();
    },
    
    configurePickerPicker: function(oauthToken, mimeTypes) {
        var view = new google.picker.View(google.picker.ViewId.DOCS);
  //      view.setEnableTeamDrives(true);
        view.setMimeTypes(mimeTypes);
        var uploadView = new google.picker.DocsUploadView();

        this.pickerJSON = new google.picker.PickerBuilder().
        enableFeature(google.picker.Feature.SUPPORT_TEAM_DRIVES).
        setAppId(App.appId).
        setOAuthToken(oauthToken).
        addView(uploadView).
        addView(view).
        setCallback(this.pickerCallback).
        build();
    },
        
    pickerCallback: function(data) {
        switch (data.action) {
            case google.picker.Action.PICKED: {
                return App.Helpers.googleDriveOpenDialogue.onSelect(data.docs);
            }
            case google.picker.Action.CANCEL: {
                return App.Helpers.googleDriveOpenDialogue.onCancel();
            }
        }
    },

    onCancel: function() {
        if (this.cancelCallback) {
            this.cancelCallback();
        };
    },

    onSelect: function(docs) {
        if(this.callback){
            this.callback(docs);
        }else {
            App.vent.trigger(App.vent['card:driveDocsSelected'], docs, this.listenView);
        }
        this.listenView = null;
    },
        
    show: function() {
        this.initPicker().then(()=>{
            this.picker.setVisible(true);
        });
    },
    
    showJSON: function(view, callback) {
        this.listenView = view;
        this.callback = callback || false;
        this.initPicker("application/json,application/javascript").then(()=>{
            this.picker.setVisible(true);
        });
    },

    showXML: function(view, callback) {
        this.listenView = view;
        this.callback = callback || false;
        this.initPicker("application/xml,application/javascript,text/xml").then(()=>{
            this.picker.setVisible(true);
        });
    },

    showByMimeTypes: function(view, mimeTypes, callback) {
        this.listenView = view;
        this.callback = callback || false;
        this.initPicker(mimeTypes).then(()=>{
            this.picker.setVisible(true);
        });
    },

    initPicker: function (mimeTypes) {
        return commonAuthManagerInstance.getAccessTokenOrShowLoginScreen().then((access_token) => {
            this.createPicker(access_token);
            this.configurePickerPicker(access_token, mimeTypes);
            })
        ;
    }

};
