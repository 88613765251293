import { INavigationPanelQualificationFormProps } from './types';
import * as React from 'react';
import './_NavigationPanelQualificationForm.scss';
import { Button, Icon } from 'kui';
import { NAVIGATION_PANEL_QUALIFICATION_FORM_LABEL } from './constants';

export const NavigationPanelQualificationForm = ({
    isShow,
    onClick
}: INavigationPanelQualificationFormProps) => {
    if (!isShow) return null;
    const className = 'navigation-panel__qualification-form'
    return <div className={`
            navigation-panel__row
            ${className}
        `}>
        <Button
            onClick={onClick}
            text={NAVIGATION_PANEL_QUALIFICATION_FORM_LABEL}
            variant={'icon-text'}
        >
            <Icon size={24} xlink={'account-settings'} />
        </Button>
        <span className={'navigation-panel__qualification-form-new-feature'} />
    </div>
}
