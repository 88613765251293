import { IGetState } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { getAuthUser } from '../../../../../../../../store/model/authUser/selectors/getAuthUser';
import {
    authUserSetShowChecklistItemsAuthor
} from '../../../../../../../../rest/effects/authUser/authUserSetShowChecklistItemsAuthor';
import {
    getAuthUserShowChecklistItemsAuthor
} from '../../../../../../../../store/model/authUser/selectors/getAuthUserShowChecklistItemsAuthor';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';

export const onAuthorToggle = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const user = getAuthUser(state);
        const isShow = getAuthUserShowChecklistItemsAuthor(state);
        dispatch(segmentTrackAction(isShow ? SegmentCardEvent.CHECKLIST_AUTHOR_HIDE : SegmentCardEvent.CHECKLIST_AUTHOR_SHOW));

        return dispatch(authUserSetShowChecklistItemsAuthor(user.id, !isShow));
    };
    return action;
};
