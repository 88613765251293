import { connect } from 'react-redux';
import { ICardChecklistAddHOCProps } from './types';
import { onAdd } from './events/onAdd';
import { onAddOpen } from './events/onAddOpen';
import { ICardChecklistAddEvents, ICardChecklistAddFields } from '../../components/CardChecklistAdd/types';
import { CardChecklistAdd } from '../../components/CardChecklistAdd/CardChecklistAdd';
import { getChecklistUsersSearchSelectOptionsCreateSelector, getChecklistUsersSearchSelectOptionsNoReselect } from '../selectors/getChecklistUsersSearchSelectOptions';
import { IApplicationState } from '../../../../../../../types/types';
import { getSectionType } from '../../../selectors/getSectionType';
import { getAuthUser } from '../../../../../../../store/model/authUser/selectors/getAuthUser';
import { getBoardUsers, getBoardUsersNoreselect, getBoardUsersSelector } from '../../../../../../../store/model/selectors/getBoardUsers';
import { getBoardWorkHoursInDay } from '../../../../../../../store/model/selectors/getBoardWorkHoursInDay';
import { isEqual } from 'underscore';

const mapStateToProps = () => {
    // const getUsersSearchSelectOptions = getChecklistUsersSearchSelectOptionsCreateSelector();
    interface ICaheProps extends ICardChecklistAddFields{};
    let cache: ICaheProps = null;
    const getCache = (
        value: ICaheProps
    ): ICaheProps => {
        if (isEqual(value, cache)) return cache;
        cache = value; return value;
    };

    return (
        state: IApplicationState,
        { isOpened, boardId, cardId, checklistId }: ICardChecklistAddHOCProps
    ): ICardChecklistAddFields => {
        const sectionType = getSectionType(state, boardId, cardId);
        const { permissionId: authUserPermissionId } = getAuthUser(state);
        const avaliableAssignees = getChecklistUsersSearchSelectOptionsNoReselect(state, boardId, cardId, checklistId);

        return getCache({
            avaliableAssignees,
            authUserPermissionId,
            boardUsers: getBoardUsersNoreselect(state, boardId),
            isOpened,
            sectionType,
            workHoursInDay: getBoardWorkHoursInDay(state, boardId)
        });
    };
};

const mapDispatchToProps = (
    dispatch: any,
    { cardId, checklistId, onAddOpen: onAddOpenOwn, onAddClose, onCancel }: ICardChecklistAddHOCProps
): ICardChecklistAddEvents => {
    return {
        onAdd: (item, isSaveFirst) => dispatch(onAdd(cardId, checklistId, item, isSaveFirst)),
        onAddOpen: (value) => dispatch(onAddOpen(value, onAddOpenOwn)),
        onAddClose,
        onCancel
    }
};

export const CardChecklistAddHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardChecklistAdd);

CardChecklistAddHOC.displayName = 'CardChecklistAddHOC';
