import { ACTIVE_BOARD_ID_ACTION_SET, IActiveBoardIdActionSetAction } from './types';
import { TBoardId } from '../../../types/types';

export const activeBoardIdActionSet = (
    activeBoardId: TBoardId
): IActiveBoardIdActionSetAction => {
    return {
        type: ACTIVE_BOARD_ID_ACTION_SET,
        activeBoardId
    }
};
