import { AutoBackupsWarningDescription } from '../../components/AutoBackupsWarningDescription/AutoBackupsWarningDescription';
import { connect } from 'react-redux';
import { onClose } from './events/onClose';
import {
    IAutoBackupsWarningDescriptionEvents,
    IAutoBackupsWarningDescriptionFields
} from '../../components/AutoBackupsWarningDescription/types';
import { IAutoBackupsWarningDescriptionHOCProps } from './types';
import { hasAutoBackupsWarning } from '../../../../../../../../store/model/board/selectors/hasAutoBackupsWarning';
import { IApplicationState } from '../../../../../../../../types/types';

const mapStateToProps = (
    state: IApplicationState,
    {boardId}: IAutoBackupsWarningDescriptionHOCProps
): IAutoBackupsWarningDescriptionFields => {
    return {
        showDescription: hasAutoBackupsWarning(state, boardId)
    }
}

const mapDispatchToProps = (
    dispatch: any,
    {boardId}: IAutoBackupsWarningDescriptionHOCProps
): IAutoBackupsWarningDescriptionEvents => ({
    onClose: () => dispatch(onClose(boardId))
});

export const AutoBackupsWarningDescriptionHOC = connect(mapStateToProps, mapDispatchToProps)(AutoBackupsWarningDescription);
