import { TCondition } from '../types';
import { IApplicationState } from '../../../../types/types';
import { EHintKey, getHintConfig } from '../constants';
import { getCurrentNavigationPanelType } from '../../main/navigationPanel/helpers/getCurrentNavigationPanelType';
import { ENavigationPanelTypes } from '../../main/navigationPanel/constants';
import { isOpenNavigationPanelSelector } from '../../main/navigationPanel/store/selectors/isOpenNavigationPanelSelector';
import { getUserNewCardsCount } from '../../../../store/model/authUser/selectors/getUserNewCardsCount';

export const newCardsCountCondition: TCondition = (
    state: IApplicationState
) => {
    const hint = getHintConfig(EHintKey.NEW_CARD_COUNT);
    if (!hint) return false;

    return getUserNewCardsCount(state) && (
        getCurrentNavigationPanelType(state) !== ENavigationPanelTypes.NP_COMPACT_TYPE
        || isOpenNavigationPanelSelector(state));
};
