import { IListsActionSetAction, LISTS_ACTION_SET } from './types';
import { TListsAction } from '../lists/actions/types';

export const listsActionSet = (
    listsAction: TListsAction
): IListsActionSetAction => {
    return {
        type: LISTS_ACTION_SET,
        listsAction
    }
};
