'use strict';

import { dispatch, getAppState } from '../../../app/store/configureStore';
import { segmentTrackAction, SegmentInAppNotificationEvent } from "../../middlewares/segment";

import template from './template/notification-group.html';
import { getActiveBoardLngs } from '../../store/model/selectors/getActiveBoardLngs';
import { ERoutePrefixes } from "app/store/router/constants";
import {
    getCurrentNavigationPanelType
} from "../react_components/main/navigationPanel/helpers/getCurrentNavigationPanelType";
import { ENavigationPanelTypes } from "../react_components/main/navigationPanel/constants";
import {
    panelTypeSetAction
} from "../react_components/main/navigationPanel/store/navigationPanel/actions/panelTypeSetAction";
import {
    isOpenSetAction
} from "../react_components/main/navigationPanel/store/navigationPanel/actions/isOpenSetAction";
import {
    getUserStarredNotificationsGrouped
} from "../../store/model/authUser/selectors/getUserStarredNotificationsGrouped";

App.Views.NotificationGroup = App.Views.BaseView.extend({
    className: 'notification-group notification-group--one',

    events: {
        'click .js-notification-group__show': 'onShow',
        'click .js-notification-group__hide': 'onHide',
        'click .js-notification-group__read': 'onRead',
        'click .js-notification-group__star': 'onStar',
        'click .js-notification__star': 'onUnstar',
        'click .js-notification-group__read-auto': 'readGroup',
        'click .js-notification__card': 'onCardOpen',
    },

    initialize: function (options) {
        var lngs = getActiveBoardLngs(getAppState());
        this.template = App.MLTemplate.getTemplate('#notification-group', lngs, template);
        this.model = options.model.getNotificationModel(0);
        this.nofiticationItem = options.model;
        this.notifyInformer = options.notifyInformer;
        this.staredNotifyHelper = options.staredNotifyHelper;
        if (options.isOpened) { // открывать группу при перерендере после загрузки новых нотификаций
            this.$el.addClass('notification-group--opened');
        }
    },

    onShow: function() {
        // var opened = this.$el.closest('.notification-list').find('.notification-group--opened');
        // if (opened.length) { // аккордеон
        //     opened.removeClass('notification-group--opened');
        //     this.scrollTo();
        // }
        this.$el.addClass('notification-group--opened');
        this.mergeGroups();
        App.controller.trackEvent(
            Messages.getText('ga.category.notifications'),
            Messages.getText('ga.action.notifications.group_opened')
        );
        dispatch(segmentTrackAction(SegmentInAppNotificationEvent.CLICKED_SHOW_MORE_ON_NOTIFICATIONS_GROUP, {
                name: this.model.modelType,
                value: this.model.propertyName
            }
        ));
    },

    onHide: function() {
        this.scrollTo();
        App.controller.trackEvent(
            Messages.getText('ga.category.notifications'),
            Messages.getText('ga.action.notifications.group_closed')
        );
        dispatch(segmentTrackAction(SegmentInAppNotificationEvent.CLICKED_SHOW_LESS_ON_NOTIFICATIONS_GROUP, {
                name: this.model.modelType,
                value: this.model.propertyName
            }
        ));
    },

    onRead: function() {
        this.readGroup();
        App.controller.trackEvent(
            Messages.getText('ga.category.notifications'),
            Messages.getText('ga.action.notifications.group_read')
        );
        this.$el.remove();
        dispatch(segmentTrackAction(SegmentInAppNotificationEvent.CLICKED_MARK_ALL_AS_READ, {
                name: this.model.modelType,
                value: this.model.propertyName
            }
        ));
    },

    onStar: function() {
        this.starGroup();
    },

    onUnstar: function() {
        this.starGroup(true);
    },

    onCardOpen: function(e) {
        App.router.navigate(App.router.getAssignedToMePanelUrl(ERoutePrefixes.notification, this.nofiticationItem.getNotificationId()), { trigger: true });
        const navigationPanelType = getCurrentNavigationPanelType(getAppState());
        if (navigationPanelType === ENavigationPanelTypes.NP_COMPACT_TYPE) {
            dispatch(panelTypeSetAction(ENavigationPanelTypes.NP_COMPACT_BIG_TYPE));
            dispatch(isOpenSetAction(false));
        }
        this.setGroupSeen();
        e.stopPropagation();
        dispatch(segmentTrackAction(SegmentInAppNotificationEvent.CLICKED_NOTIFICATION, {
                name: this.model.modelType,
                value: this.model.propertyName
            }
        ));
    },

    readGroup: function() {
        this.$el.closest('.notifications').focus(); // элемент исчезнет из вкладки unread, перед этим надо перенести фокус, чтоб дропдаун не закрылся
        this.scrollTo();
        this.setGroupSeen();
        this.nofiticationItem.set('rendered', false);
        //this.$el.remove();
    },

    starGroup: function(isSingle) {
        if (document.querySelector('.topbar-notifications__tabs-tab--starred.active') && !isSingle) {
            this.$el.closest('.notifications').focus(); // элемент исчезнет из вкладки starred, перед этим надо перенести фокус, чтоб дропдаун не закрылся
            this.scrollTo();
            this.nofiticationItem.set('rendered', false);
            this.$el.remove();
        }
        this.toggleGroupStarred(isSingle);
    },

    toggleGroupStarred: function(isSingle) {
        const isGrouped = this.notifyInformer.isStarredActive() &&  getUserStarredNotificationsGrouped(getAppState());
        if (!isSingle && isGrouped) {
            const cardId = this.model.modelId;
            this.staredNotifyHelper.unStarredCardNotifications(cardId);
            dispatch(segmentTrackAction(SegmentInAppNotificationEvent.UNSTAR_GROUP));
        } else {
            const starred = this.staredNotifyHelper.isStarredNotification(this.nofiticationItem.getNotificationId());
            if (starred) {
                this.$el.removeClass('notification-group--starred');
                this.staredNotifyHelper.unStarredNotification(this.nofiticationItem);
                this.$el.find('.notification-group__star-tooltip').html(Messages.getText('noty.groups.star'));
                dispatch(segmentTrackAction(SegmentInAppNotificationEvent.UNSTAR_NOTIFICATION));
            } else {
                this.$el.addClass('notification-group--starred');
                this.staredNotifyHelper.starredNotification(this.nofiticationItem);
                this.setGroupSeen();
                this.$el.find('.notification-group__star-tooltip').html(Messages.getText('noty.groups.unstar'));
                this.$el.remove();
                dispatch(segmentTrackAction(SegmentInAppNotificationEvent.STAR_NOTIFICATION));
            }
        }
        this.notifyInformer.updateView();
    },

    setGroupSeen: function() {
        this.$el.find('.notification-list__item--unread').each(function () {
            $(this).data('backbone-view').setSeen(true);
        });
    },

    scrollTo: function() {
        var top = this.$el.position().top;
        if (top < 0) {
            var scroll = this.$el.closest('.dropdown__body').get(0);
            scroll.scrollTop += top - 7;
        }
    },

    mergeGroups: function() {
        var duplicates = $('[data-card="' + this.$el.data('card') + '"]');
        if (duplicates.length < 2) return;
        if (duplicates.index(this.$el) > 0) return;
        var remove = duplicates.not(this.$el);
        remove.find('.notification-list__item').appendTo(this.$el.find('.notification-group__list').first());
        remove.remove();
    },

    getContent: function() {
        var boardName = this.model.dashboardName || null,
            boardIcon = 'board', // todo star
            listName = null,
            cardName = null;
        if (this.model.card) {
            cardName = this.model.card.name;
            listName = this.model.card.listName;
        }
        return {
            boardName: boardName,
            boardIcon: boardIcon,
            listName: listName,
            cardName: cardName,
            showMore: Messages.getText('noty.groups.show_more'),
            less: Messages.getText('noty.groups.less'),
            read: Messages.getText('noty.groups.read'),
            star: this.staredNotifyHelper.isStarredNotification(this.nofiticationItem.getNotificationId()) ? Messages.getText('noty.groups.unstar') : Messages.getText('noty.groups.star'),
        }
    },

    render: function () {
        this.$el.html(this.template(this.getContent()));
        return this;
    }
});
App.Views.NotificationGroup.mixin(App.Mixins.Test);
