import { EViewTypes, VIEW_TYPES_OTHER } from '../../../../../const';

export const isShowMetrics = (type: EViewTypes): boolean => {
    return (
        type === EViewTypes.GANTT_VIEW_TYPE ||
        type === EViewTypes.KANBAN_VIEW_TYPE ||
        type === EViewTypes.LIST_VIEW_TYPE ||
        VIEW_TYPES_OTHER.includes(type)
    );
};
