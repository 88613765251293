import * as React from 'react';
import { Input, Tooltip } from 'kui';
import { CLASS_CARD_CUSTOM_ITEM } from '../constants';
import { CUSTOM_PROPERTY_TYPES } from '../../../../../../../store/customProperties/constants';
import { ICustomPropertiesTextProps } from './types';
import { blockMarkdown } from '../../../../../../../helper/markdownHelper';
import { root } from '../../../../../../../store/constants';
import './_CustomPropertiesText.scss';
import { CARD_CONTROL_CLASS } from '../../../../../main/kanbanView';
import { escape } from 'underscore';
import ReactHtmlParser from 'react-html-parser';

export function CustomPropertiesText({
    element,
    isReadonly,
    tooltipClassName,
    isTooltip,
    onBlur,
    onChangeInput,
    onKeyDown,
    onClick,
    onFocus
}: ICustomPropertiesTextProps) {
    const classNameHtml = CLASS_CARD_CUSTOM_ITEM + '-text-html';
    const classReadonly = CLASS_CARD_CUSTOM_ITEM + '--readonly';

    const inputRef = React.useRef(null);
    const requestAnimation = React.useRef(null);
    const [isInput, setIsInput] = React.useState(!element.value);

    function inputShow(event: React.MouseEvent) {
        if (isReadonly) return;

        const { tagName } = event.target as HTMLElement;
        if (tagName && tagName.toLowerCase() === 'a') return;

        const selection = root.getSelection();
        if (!selection.isCollapsed) return;

        setIsInput(true);
        focusOnInput();
        onClick();
    }

    const onHtmlKeyDown = (e: React.KeyboardEvent) => {
        if (!e) return;
        if (e.key === 'Enter') {
            inputShow(e as any);
        }
    }

    const focusOnInput = () => {
        requestAnimation.current = requestAnimationFrame(() => {
            if (inputRef && inputRef.current) {
                inputRef.current.setFocus();
            }
        });
    }

    const onBlurHandler = () => {
        onBlur(element.id);
        setIsInput(!element.value);
    }

    React.useEffect(() => {
        return () => {
            if (requestAnimation.current) cancelAnimationFrame(requestAnimation.current);
        }
    }, []);

    React.useEffect(() => {
        if (!isInput && !element.value) {
            setIsInput(true);
        }
    }, [element.value]);

    return (
        <div className={`
                ${CLASS_CARD_CUSTOM_ITEM}
                ${isReadonly ? classReadonly : ''}
            `}
        >
            {isInput ?
                <Input
                    className={`
                            ${CLASS_CARD_CUSTOM_ITEM}
                            ${CARD_CONTROL_CLASS}
                        `}
                    placeholder={CUSTOM_PROPERTY_TYPES[element.type]}
                    readOnly={isReadonly}
                    value={element.value ? String(element.value) : ''}
                    ref={inputRef}
                    onBlur={onBlurHandler}
                    state={element.isInputError ? 'error' : null}
                    onFocus={onFocus}
                    onChange={(e) => onChangeInput(e, element.id)}
                    onKeyDown={(e) => onKeyDown(e)}
                    onClick={onClick}
                />
                :  <label className={'kui-label'}>
                    <Tooltip
                        className={tooltipClassName}
                        key={element.id}
                        isNoEvents
                        value={isTooltip ? escape(element.value) : null}
                        direction={'down'}
                    >
                        <div
                            className={`
                                ${classNameHtml}
                                ${CARD_CONTROL_CLASS}
                            `}
                            role={'button'}
                            tabIndex={0}
                            aria-label={element.value}
                            onClick={ inputShow }
                            onKeyDown={onHtmlKeyDown}
                        >
                            {ReactHtmlParser(blockMarkdown(element.value))}
                        </div>
                    </Tooltip>
                </label>
            }
        </div>
    );
}
