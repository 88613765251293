import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../../../../types/types';
import { IBoard } from '../../../../../../../../store/model/board/types';
import { cardMoveToAnotherBoard } from '../effects/cardMoveToAnotherBoard';
import { root } from '../../../../../../../../store/constants';
import { getBoardIdByCardId } from '../../../../../../../../store/model/selectors/getBoardIdByCardId';
import { getLinkCardId } from '../../../../../../../../store/router/selectors/getLinkCardId';

export const onListSelected = (
    currentCardId: TCardId,
    board: IBoard,
    listIndex: number
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const listId = board.lists[listIndex].id;
        const currentBoardId = getBoardIdByCardId(getState(), currentCardId);
        dispatch(cardMoveToAnotherBoard(currentCardId, board, listId)).then(() => {
            root.App.controller.lastActivity.setLastOpenedCardId(currentBoardId, null);
            const linkCardId = getLinkCardId(getState().router);
            if (linkCardId) {
                root.App.router.navigate(root.App.router.getLinkCardUrl(board.id, linkCardId, ''), { trigger: true });
            }
        });
    };
    return action;
};
