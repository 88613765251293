import { IGetState, TBoardId } from '../../../types/types';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { boardSetMeta } from './boardSetMeta';

export const boardSetHideCardAgeingSnackbar = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(boardSetMeta(boardId, { isHideCardAgeingSnackbar: true }));
    };
    return action;
}
