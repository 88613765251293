import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState } from '../../../../../../../types/types';
import { root } from '../../../../../../../store/constants';

export const onOpenSettings = (): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        root.App.router.navigate(root.App.router.getAssignedToMeUrl('settings'), { trigger: true });
    };
    return action;
};
