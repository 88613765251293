import { ISharedUser } from 'app/types/rest/ISharedUser';

export const getInitials = (
    user: ISharedUser
): string => {
    let initials = null;
    if (user.firstName && user.lastName) {
        initials = user.firstName[0] + user.lastName[0];
    } else if (user.fullName) {
        initials = user.fullName.substring(0, 2);
    }
    return initials;
};
