import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState, TBoardId } from '../../../../../../../types/types';
import { updateBoardName } from 'app/rest/effects/board/updateBoardName';

export const onNameChanged = (
    boardId: TBoardId,
    name: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(updateBoardName(boardId, name));
    };
    return action;
};
