import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { root } from '../../../../../../../store/constants';

export const onClose = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        root.App.router.navigate(root.App.router.getAssignedToMeUrl(), { trigger: true });
    };
    return action;
};
