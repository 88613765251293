import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { TBoardId } from '../../../../../../../../../types/types';
import { regeneratePrefixes } from '../effects/regeneratePrefixes';

export const onRegenerate = (
    boardId: TBoardId,
    abortController: AbortController
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        return dispatch(regeneratePrefixes(boardId, abortController))
    };
    return action;
};
