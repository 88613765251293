import { IGetState, TBoardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { root } from '../../../../../../../../store/constants';
import { SegmentBoardEvent, segmentTrackAction, SegmentUserEvent } from '../../../../../../../../middlewares/segment';
import { IExportListener } from '../../../../../../../../helper/export/types';
import { exportTimeTrackerToSpreadsheet } from '../../../../../../../../helper/export/exportTimeTrackerToSpreadsheet';
import { authManagerInstance } from 'app/helper/authorisation/google/AuthManager';

export const exportTimeTrackerWithCheckPerm = (
    boardId: TBoardId,
    listener: IExportListener
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const App = root.App;
        const onApprove = () => {
            dispatch(segmentTrackAction(SegmentUserEvent.PERMISSION_SHEETS_SCOPE_GRANTED));
            dispatch(exportTimeTrackerToSpreadsheet(boardId, listener))
        }
        dispatch(segmentTrackAction(SegmentBoardEvent.EXPORTED_TIME_TRACKER_DATA));

        authManagerInstance.checkAndRequestGoogleSheet(onApprove);
    };
    return action;
};
