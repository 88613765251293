import * as React from 'react';
import { IRestCardListActivity } from '../../../../../../../types/rest/activity/IRestCardListActivity';
import { IActivityProps } from '../../../../boardDetails/activity/TabActivity/types';
import { TabActivityContext } from '../../../../boardDetails/activity/TabActivity/components/TabActivity/constants';
import './_CardActivityList.scss';
import { TActivity } from '../../../../../../../store/activities/types';
import {
    ActivityItemBody
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemBody/ActivityItemBody';
import { CLASS_ACTIVITY_ITEM } from '../../../../boardDetails/activity/TabActivity/components/constants';
import { ActivityHeader } from '../../../../boardDetails/activity/TabActivity/components/ActivityHeader/ActivityHeader';
import {
    ActivityItemName
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemName/ActivityItemName';
import { Icon } from 'kui';
import { getInitialsHelper } from '../../../../../helpers/getInitialsHelper';
import { getIconColor } from '../../../../../base/components/Logo/components/constants';
import { NAVIGATION_PANEL_CLOSE_MARKER } from '../../../../../main/navigationPanel/constants';
import { Logo } from '../../../../../base/components/Logo/components/Logo/Logo';

export function CardActivityList({
    activity
}: IActivityProps<IRestCardListActivity>) {
    const { onScrollToList } = React.useContext(TabActivityContext);
    const isShowBoardName = true;
    const { label } = activity as TActivity;

    const classLogo = CLASS_ACTIVITY_ITEM + '__logo';

    const getLogo = (
        logo: string,
        logoColor: string,
        logoIcon: string,
        boardName: string
    ) => {
        return logo ? <div
                className={`${classLogo} ${classLogo}--custom`}
                style={{ backgroundImage: `url("${logo}")` }}
            />
            : <Logo
                name={boardName}
                logo={{ icon: logoIcon, color: logoColor }}
            />
    }

    return (
        <ActivityItemBody
            className={`${CLASS_ACTIVITY_ITEM + '--list'} ${isShowBoardName ? CLASS_ACTIVITY_ITEM + '--list-with-board' : ''}`}
            header={<ActivityHeader icon={'move-to'} label={label} />}
            activity={activity}
            onClick={() => onScrollToList(activity.newList.id)}
        >
            {isShowBoardName &&
                <ActivityItemName
                    name={activity.oldBoard.name}
                    isRemove
                    icon={getLogo(activity.oldBoard.logo, activity.oldBoard.logoColor, activity.oldBoard.logoIcon, activity.oldBoard.name)}
                />
            }
            <ActivityItemName name={activity.oldList.name} isRemove icon={<Icon size={16} xlink={'list-view'} />} />
            <Icon xlink={'forward'} size={24} />
            {isShowBoardName &&
                <ActivityItemName
                    name={activity.newBoard.name}
                    icon={getLogo(activity.newBoard.logo, activity.newBoard.logoColor, activity.newBoard.logoIcon, activity.newBoard.name)}
                />
            }
            <ActivityItemName name={activity.newList.name} icon={<Icon size={16} xlink={'list-view'} />} />
        </ActivityItemBody>
    );
};
