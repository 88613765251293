import { ISnackbarProgress } from './types';
import { Dispatch } from '../../../../../types/actions';
import { getSnackbarProgress } from './progressDefault';
import { addUpdateSnackbar } from '../../store/actions/addUpdateSnackbar';

export const snackbarProgressUpdate = (
    options: ISnackbarProgress
) => {
    return (dispatch: Dispatch) => {
        dispatch(addUpdateSnackbar(
            getSnackbarProgress(options)
        ));
    }
};
