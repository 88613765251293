import { connect } from 'react-redux';
import { IApplicationState, IColorTag } from '../../../../../../../types/types';
import { IColorTagsHOCProps } from './types';
import { getCardColorTagsCreateSelector } from '../../../../../../../store/model/selectors/getCardColorTags';
import { getBoardColorsByStatus } from '../../../../../../../store/model/board/selectors/getBoardColorsByStatus';
import { onSave } from '../../../../../aside_panel/cardDetails/ColorTags/hocs/events/onSave';
import { getBoardCardColorTagsCreateSelector } from '../../../../../../../store/model/selectors/getBoardCardColorTags';
import { IColorTagsDropdownEvents, IColorTagsDropdownFields } from '../../components/ColorTagsDropdown/types';
import { ColorTagsDropdown } from '../../components/ColorTagsDropdown/ColorTagsDropdown';
import { getBoardColors } from '../../../../../../../store/model/board/selectors/getBoardColors';
import { getSelectedCards } from '../../../../../clickManager/selectors/getSelectedCards';
import { getCardColorIds } from '../../../../../../../store/model/selectors/getCardColorIds';
import { onDidMount } from './events/onDidMount';
import { onShowSnackbar } from './events/onShowSnackbar';
import { onEditColor } from './events/onEditColor';
import { onAllClicked } from './events/onAllClicked';
import { onUsedClicked } from './events/onUsedClicked';
import { onDoubleClick } from './events/onDoubleClick';
import { onCreate } from './events/onCreate';
import { onAddCustomColor } from './events/onAddCustomColor';
import { getBoardCustomColors } from '../../../../../../../store/model/board/selectors/getBoardCustomColors';
import { EPaletteVariant } from '../../../ColorPalette/types';

const mapStateToProps = () => {
    const getCardColorTags = getCardColorTagsCreateSelector();
    const getBoardCardColorTags = getBoardCardColorTagsCreateSelector();

    return (
        state: IApplicationState,
        ownProps: IColorTagsHOCProps
    ): IColorTagsDropdownFields => {
        const {
            boardId,
            cardId,
            colorTags: colorTagsOwn,
            editingColor,
            isAutoFocus,
            isMultiselect,
            isReset,
            isSmall,
            paletteVariant,
        } = ownProps;

        let boardColors = getBoardColorsByStatus(state, boardId);

        const boardCardColors = getBoardCardColorTags(state, boardId);
        let colorTags: IColorTag[];
        if (colorTagsOwn) {
            colorTags = colorTagsOwn;
        } else if (isMultiselect) {
            const allBoardColors = getBoardColors(state, boardId);
            const cardIds = getSelectedCards(state, boardId);
            const tagIds = cardIds.reduce((ids, cardId, i) => { // найдём пересечение тегов
                const colorIds = getCardColorIds(state, cardId);
                if (!i) return [...colorIds];
                if (!ids.length) return ids;

                for (let i = ids.length - 1; i >= 0; i--) {
                    if (!colorIds.includes(ids[i])) {
                        ids.splice(i, 1);
                    }
                }
                return ids;
            }, []);
            colorTags = tagIds.map(id => {
                return allBoardColors.find(color => color.id === id);
            });
        } else {
            colorTags = getCardColorTags(state, cardId);
        }

        return {
            boardCardColors,
            boardColors,
            colorTags,
            customColors: getBoardCustomColors(state, boardId),
            editingColor,
            paletteVariant: paletteVariant || EPaletteVariant.TAG_COLOR,
            isAutoFocus,
            isReset,
            isSmall,
        }
    };
}

const mapDispatchToProps = (
    dispatch: any,
    { boardId, onOpenSettings, onChange, onClose, onSetEditing, onDropdownOpen, setRemovingSource, setRemovingId, source }: IColorTagsHOCProps
): IColorTagsDropdownEvents => (
    {
        onAddCustomColor: (color) => dispatch(onAddCustomColor(boardId, color)),
        onClose,
        onChange,
        onDropdownOpen,
        onCreate: () => dispatch(onCreate(source)),
        onDoubleClick: () => dispatch(onDoubleClick(source)),
        onSectionAllClick: () => dispatch(onAllClicked(source)),
        onSectionUsedClick: () => dispatch(onUsedClicked(source)),
        onSave: (colorTag) => dispatch(onSave(boardId, colorTag)),
        onEdit: (colorTag) => dispatch(onEditColor(boardId, colorTag, source)),
        onOpenSettings,
        onDidMount: () => dispatch(onDidMount()),
        onSetEditing,
        onShowSnackbar: () => dispatch(onShowSnackbar()),
        setRemovingSource,
        setRemovingId
    }
);

export const ColorTagsDropdownHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(ColorTagsDropdown);

ColorTagsDropdownHOC.displayName = 'ColorTagsDropdownHOC';
