import { INavigationPanelLogoProps } from './types';
import * as React from 'react';
import './_NavigationPanelLogo.scss'

export function NavigationPanelLogo ({
    logo,
    logoX,
}: INavigationPanelLogoProps) {
    const [userLogo, setUserLogo] = React.useState('');
    const className = 'navigation-panel__logo';
    const k = 48 / 132; // 48 высота элемента, 132 реальная высота изображения
    const style = logoX
        ? {
            marginLeft: (-logoX * k) + 'px',
        }
        : {}
    React.useEffect(() => {
        if (!logo) return;
        setUserLogo(logo)
    }, [logo])

    return userLogo
        ? <div
            className={`${className} ${className}--custom ${!logoX ? className + '--noopacity' : ''}`}
            style={style}
        >
            <img src={userLogo} />
        </div>
        : <div className={className} />
};
