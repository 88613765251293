import { AT_LIST_ACTION_SET, AT_LISTS_UPDATE } from './constants';
import { TListAction } from '../../list/actions/types';
import { IListActionSetAction, IListsUpdateAction } from './types';
import { ILists } from '../types';

export const listsUpdate = (
    lists: ILists
): IListsUpdateAction => ({
    type: AT_LISTS_UPDATE,
    lists
});
