import { AT_DEPENDENCY_DELETE, ITypeDependencyDeleteAction } from './types';

export const dependencyDeleteAction = (
    dependencyId: number
): ITypeDependencyDeleteAction => {
    return {
        type: AT_DEPENDENCY_DELETE,
        dependencyId
    }
};
