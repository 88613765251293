import { IColor } from 'app/types/model';
import { Dispatch, ThunkAction } from 'app/types/actions';
import { fetchHandler } from 'app/util/fetchHandler';
import { Util } from 'app/util/util';
import { REST_COLOR } from 'app/rest/constants';
import { getRestHeadersDelete } from 'app/rest/helpers/getRestHeadersHelper';
import { TColorId } from '../../../../types/types';

export const deleteRest = (
    colorId: TColorId
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        return fetchHandler<IColor>(Util.getApiUrl(REST_COLOR + colorId), getRestHeadersDelete());
    };
    return action;
};
