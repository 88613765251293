import { AT_AUTH_USER_ACTION_SET, IAuthUserActionSetAction } from './types';
import { TAuthUserAction } from '../authUser/actions/types';

export const authUserActionSet = (
    authUserAction: TAuthUserAction
): IAuthUserActionSetAction => {
    return {
        type: AT_AUTH_USER_ACTION_SET,
        authUserAction
    }
};
