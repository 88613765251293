import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { getCard } from '../../../../../../../store/model/selectors/getCard';
import { ICardIconFields } from '../../components/CardIcon/types';
import { CardIcon } from '../../components/CardIcon/CardIcon';
import { TStatus } from '../../../../../../../types/model';
import { ICardIconHOCOwnProps } from './types';

const mapStateToProps = (
    state: IApplicationState,
    ownProps: ICardIconHOCOwnProps
): ICardIconFields => {
    const { cardId } = ownProps;
    const card = getCard(state, cardId);
    const isArchive: boolean = card.status === TStatus.STATUS_ARCHIVE;
    return {
        isArchive,
    }
};

export const CardIconHOC = connect(
    mapStateToProps,
    null
)(CardIcon);

CardIconHOC.displayName = 'CardIconHOC';
