import { convertTimeNumberToString } from '../../../view/react_components/aside_panel/cardDetails/helpers/convertTimeNumberToString';
import { ICard } from '../card/types';
import { root } from '../../constants';

/**
 *
 * @param card
 * @param includeChecklists plus checklists estimate
 * @returns
 */
export const getCardEstimateString = (
    card: ICard,
    hoursInDay: number = 24,
    includeChecklists: boolean = true,
    seconds: boolean = false,
    includeDayDigit: boolean = true
): string => {
    if (root._debug.selector) {
        console.log('getCardEstimateString');
    }
    let estimate = card.estimate || 0;
    if (includeChecklists) {
        const {estimate: checklistsEstimate = 0} = card.checklistStats || {};
        estimate += checklistsEstimate;
    }
    return convertTimeNumberToString(estimate, seconds, hoursInDay, includeDayDigit);
}

// замеры CardProgressHOC показывают, что реселекты там не нужны
// export const getCardEstimateStringMemo = (
// ) => defaultMemoize(getCardEstimateString);
