import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { isRelatedPanelClosed } from '../../../../../kanbanView/components/Card/hocs/CardHOC/selectors/isRelatedPanelOpened';
import { root } from '../../../../../../../../store/constants';
import { ERoutePrefixes } from '../../../../../../../../store/router/constants';
import { relatedCardToggle } from '../../../../../../aside_panel/relatedCards/effects/relatedCardToggle';
import { IGetState } from '../../../../../../../../types/types';
import { IAssignedToMeCard } from '../../../../myWork/components/MyWork/types';
import { onUpdatesClickEvent } from '../../../../effects/segmentEvents';

export const onClick = (
    card: IAssignedToMeCard
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const isPanelClosed = isRelatedPanelClosed(state);
        if (isPanelClosed) {
            dispatch(onUpdatesClickEvent(card.updatesCount));
            root.App.router.navigate(root.App.router.getAssignedToMePanelUrl(ERoutePrefixes.atm, card.cardId, 'activity'), { trigger: true });
        } else {
            dispatch(relatedCardToggle(card.cardId));
        }
    }
    return action;
}
