import { IApplicationState } from '../../../../../../../../types/types';
import { connect } from 'react-redux';
import { IBoardCardNumberEvents, IBoardCardNumberFields } from '../../components/BoardCardNumber/types';
import { IBoardCardNumberHOCProps } from './types';
import { BoardCardNumber } from '../../components/BoardCardNumber/BoardCardNumber';
import { onDidMount } from './events/onDidMount';
import { onChangeCardNumber } from './events/onChangeCardNumber';
import { onSaveCardNumber } from './events/onSaveCardNumber';
import { getCardNumberByBoardId } from '../../store/cardNumbers/selectors/getCardNumberByBoardId';
import { onClickLink } from './events/onClickLink';
import { getCardNumberLoading } from '../../store/cardNumbers/selectors/getCardNumberLoading';
import { getPanelReadonly } from '../../../../selectors/getPanelReadonly';
import { getBoard } from '../../../../../../../../store/model/selectors/getBoardById';
import { onBoardChanges } from './events/onBoardChanges';
import { onGenerate } from './events/onGenerate';
import { getCardsWithoutCardNumber } from './selectors/getCardsWithoutCardNumber';
import { getAuthUser } from '../../../../../../../../store/model/authUser/selectors/getAuthUser';
import { onClickDisallow } from './events/onClickDisallow';
import { onToggleOff } from './events/onToggleOff';
import { onRegenerate } from './events/onRegenerate';
import { onCancel } from './events/onCancel';
import { onShowSnackbar } from './events/onShowSnackbar';
import { onAdd } from './events/onAdd';
import {
    SegmentBoardOptionCardPrefixesVariant
} from '../../../../../../../../middlewares/segment/trackEntities/boardEvents';
import { getBoardCards } from '../../../../../../../../store/model/selectors/getBoardCards';
import { TStatus } from '../../../../../../../../types/model';

const mapStateToProps = (
    state: IApplicationState,
    { boardId }: IBoardCardNumberHOCProps
): IBoardCardNumberFields => {
    const { nextValue, prefix, errorMessage, enabled, status, edited } = getCardNumberByBoardId(state, boardId);
    const isDisabled = getPanelReadonly(state, boardId, null, null, 'allowChangeCardNumber');
    const isReadonly = isDisabled || getCardNumberLoading(state, boardId);
    const board = getBoard(state, boardId);
    const boardEnabled = board.cardNumberEnabled;
    const boardNextValue = '' + board.cardNumberNextValue;
    const boardPrefix = board.cardNumberPrefix;
    const canGeneratePrefixes = getCardsWithoutCardNumber(state, boardId).length > 0;
    const { photoUrl } = getAuthUser(state);
    return {
        isNoCards: !getBoardCards(state, boardId, TStatus.STATUS_ACTIVE).length,
        isReadonly,
        isDisallow: !getAuthUser(state).allowCardNumber,
        isDisabled,
        nextValue,
        prefix,
        errorMessage,
        status,
        enabled,
        edited,
        boardEnabled,
        boardNextValue,
        boardPrefix,
        canGeneratePrefixes,
        userImg: photoUrl
    };
};

const mapDispatchToProps = (
    dispatch: any,
    { boardId }: IBoardCardNumberHOCProps
): IBoardCardNumberEvents => {
    return {
        onAddExisting: () => dispatch(onAdd(SegmentBoardOptionCardPrefixesVariant.EXISTING)),
        onAddExistingAndFuture: () => dispatch(onAdd(SegmentBoardOptionCardPrefixesVariant.EXISTING_AND_FUTURE)),
        onAddFuture: () => dispatch(onAdd(SegmentBoardOptionCardPrefixesVariant.FUTURE)),
        onCancel: () => dispatch(onCancel()),
        onSaveCardNumber: () => dispatch(onSaveCardNumber(boardId)),
        onDidMount: () => dispatch(onDidMount(boardId)),
        onChangeCardNumber: (key, value) => dispatch(onChangeCardNumber(boardId, key, value)),
        onClickLink: () => dispatch(onClickLink()),
        onClickDisallow: () => dispatch(onClickDisallow()),
        onBoardChanges: () => dispatch(onBoardChanges(boardId)),
        onGenerate: (abortController) => dispatch(onGenerate(boardId, abortController)),
        onRegenerate: (abortController) => dispatch(onRegenerate(boardId, abortController)),
        onToggleOff: () => dispatch(onToggleOff(boardId)),
        onShowSnackbar: (isAll) => dispatch(onShowSnackbar(boardId, isAll))
    }
};

export const BoardCardNumberHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(BoardCardNumber);

BoardCardNumberHOC.displayName = 'BoardCardNumberHOC';
