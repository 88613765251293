import { AT_IS_SHOW_SET } from './constants';
import { IIsShowSetAction } from './types';

export const isShowSetAction = (
    isShow: boolean
): IIsShowSetAction => {
    return {
        type: AT_IS_SHOW_SET,
        isShow,
    };
};
