import { SegmentCardEvent, SegmentCardOptions, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { SegmentCardSourceValue } from 'app/middlewares/segment/trackEntities/cardEvents';
import { ECustomPropertyType } from 'app/store/customProperties/types';
import { Dispatch, ThunkAction } from 'app/types/actions';
import { IGetState, TCardId } from 'app/types/types';

export const onChangeType = (
    cardId: TCardId,
    type: ECustomPropertyType,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.CUSTOM_PROPERTY_TYPE_CHANGED, {
            name: SegmentCardOptions.SOURCE,
            value: !!cardId ? SegmentCardSourceValue.DETAILS : SegmentCardSourceValue.BOARD_SETTINGS
        }, [{
            name: SegmentCardOptions.TYPE,
            value: type
        }]));
    };
    return action;
};
