import { IGetState, TCardId } from '../../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { cardsActionSetAction } from '../../../../../../../../../store/model/actions/cardsActionSetAction';
import { cardsUpdateAction } from '../../../../../../../../../store/model/cards/actions/cardsUpdateAction';

export const onChange = (
    cardId: TCardId,
    pinnedImageIndex: number
): ThunkAction => {
    return (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(cardsActionSetAction(cardsUpdateAction({ [cardId]: { pinnedImageIndex } })));
    };
};
