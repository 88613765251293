import { IGetState, TBoardId, TDriveId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { movedBoardSegmentEvent } from '../../../../../../dialogs/openBoards/effects/segmentEvents';
import { moveSnackbarPromt } from '../../../../../../dialogs/openBoards/hocs/BoardActionMoveToDriveHOC/effects/moveSnackbarPromt';
import { boardSet } from '../../../../../../dialogs/openBoards/store/boards/actions/boardSet';
import { getBoard } from '../../../../../../../../store/model/selectors/getBoardById';
import { IBoard } from '../../../../../../dialogs/openBoards/store/boards/types';

/**
 * тут используется openBoards, поэтому надо положить доску в openBoards
 */
export const onMoveTo = (
    boardId: TBoardId,
    driveId: TDriveId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(boardSet(getBoard(getState(), boardId) as IBoard));
        dispatch(movedBoardSegmentEvent(driveId));
        return dispatch(moveSnackbarPromt(boardId, driveId));
    };
    return action;
}
