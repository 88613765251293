import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';

export const onClick = (): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        dispatch(segmentTrackAction(SegmentCardEvent.CARD_NAME_CLICKED));
    };
    return action;
};
