import { IApplicationState, TBoardId } from '../../../../../../../../../types/types';
import { ICard } from '../../../../../../../../../store/model/card/types';
import { getBoardCards } from '../../../../../../../../../store/model/selectors/getBoardCards';

export const getCardsWithoutCardNumber= (
    state: IApplicationState,
    boardId: TBoardId
): ICard[] => {
    const cards = getBoardCards(state, boardId);
    return cards.filter(card => !card.cardNumber);
}
