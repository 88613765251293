import React from "react";
import {Provider} from "react-redux";
import {ImportDialogHOC} from '../react_components/dialogs/import/hocs/ImportDialogHOC/ImportDialogHOC';
import * as ReactDOM from "react-dom";
import store from "app/store/configureStore";

export class ImportView {

    constructor () {
        if (this.el) this.remove();
        this.el = document.createElement('div');
        this.el.className = 'kanbanchi-import-modal-placeholder';
        document.body.append(this.el);
        this.render();
    }

    render () {
        ReactDOM.render(
            <Provider store={store}>
                <ImportDialogHOC />
            </Provider>
            , this.el);
    }

    remove () {
        if (!this.el) return;

        ReactDOM.unmountComponentAtNode(this.el);
        this.el.remove();
        this.el = null;
    }
}
