import { IApplicationState } from '../../../../../../../types/types';
import { getSignInInitialState } from '../constants';
import { ISignIn } from '../types';

export const getSignIn = (
    state: IApplicationState
): ISignIn => {
    const signIn = state.signIn || getSignInInitialState();
    return signIn;
}
