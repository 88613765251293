import * as React from 'react';
import './_PushNotifications.scss';
import { IPushNotificationsProps } from './types';
import { Checkbox, Radio } from 'kui';
import {
    BOARD_PUSH_NOTIFICATION_IMPORTANT,
    BOARD_PUSH_NOTIFICATION_IMPORTANT_CHECKBOXES,
    BOARD_PUSH_NOTIFICATION_SEND_UPDATES,
    BOARD_PUSH_NOTIFICATION_SUBCRIBE,
    BOARD_PUSH_NOTIFICATION_SUBSCRIBE_ON_CHECKBOXES,
    BOARD_PUSH_NOTIFICATION_TYPE_CHECKBOXES, BOARD_PUSH_NOTIFICATION_TYPE_RADIO,
    BOARD_PUSH_NOTIFICATION_WHEN,
} from './constants';
import { updateScopeList } from '../helpers/updateScopeList';
import {
    EPushNotificationSettingsScope, EPushNotificationSubscribeCases,
    EPushNotificationSettingsTypes
} from '../../../../../../../../types/rest/IRestPushNotificationSettings';
import { CLASS_BOARD_DETAILS } from '../../../../constants';

export function PushNotifications ({
    isEnabled,
    isReadonly,
    scopes,
    types,
    subscribeCases,
    onChange,
    onScopeClick,
    onNotifyTypeClick,
    onNotifySubscribeCaseClick
}: IPushNotificationsProps) {
    if (!isEnabled) return null;
    const [scopeList, setScopeList] = React.useState(scopes);
    const [typesDisabled, setTypesDisabled] = React.useState(false);
    const [index, setIndex] = React.useState(null)

    const className = CLASS_BOARD_DETAILS + '__push-notifications';
    const classHeader = CLASS_BOARD_DETAILS  + '__push-header';
    const classLevel = CLASS_BOARD_DETAILS  + '__checkbox-level';

    const radioValues = BOARD_PUSH_NOTIFICATION_TYPE_RADIO.map((item) => item.value);

    const getIndex = () => {
        for (let i = 0; i < radioValues.length; i++) {
            let index;
            if (scopes.includes(radioValues[i])) {
                index = i
                return index
            }
        }
    }

    const onScopeChangeHandler = (scope: EPushNotificationSettingsScope) => {
        onScopeClick(scope, !scopeList.includes(scope));
        let _scopeList: EPushNotificationSettingsScope[];
        if (radioValues.includes(scope)) {
            _scopeList = scopeList.filter((item) => !radioValues.includes(item))
            _scopeList.push(scope)
        } else {
            _scopeList = updateScopeList<EPushNotificationSettingsScope>(scope, scopeList);
        }
        let _subscribeCases = [...subscribeCases];
        if (scope === EPushNotificationSettingsScope.ALL || scope === EPushNotificationSettingsScope.ASSIGN_TO_ME ) {
            _subscribeCases = [];
        }
        onChange(_scopeList, _subscribeCases, types);
    }
    const onTypeChangeHandler = (type: EPushNotificationSettingsTypes) => {
        onNotifyTypeClick(type, !types.includes(type));
        const _types = updateScopeList<EPushNotificationSettingsTypes>(type, types);
        onChange(scopes, subscribeCases, _types);
    }
    const onSubscribeCaseChangeHandler = (subscribeCase: EPushNotificationSubscribeCases) => {
        onNotifySubscribeCaseClick(subscribeCase, !subscribeCases.includes(subscribeCase));
        const _subscribeCases = updateScopeList<EPushNotificationSubscribeCases>(subscribeCase, subscribeCases);
        let _scopes = [...scopes];
        if (!_scopes.includes(EPushNotificationSettingsScope.WATCH)) {
            _scopes = scopeList.filter((item) => !radioValues.includes(item))
            _scopes.push(EPushNotificationSettingsScope.WATCH)
        }
        onChange(_scopes, _subscribeCases, types);
    }

    React.useEffect(() => {
        setScopeList(scopes);
        setTypesDisabled(
            !scopes.includes(EPushNotificationSettingsScope.ALL) &&
            !scopes.includes(EPushNotificationSettingsScope.WATCH) &&
            !scopes.includes(EPushNotificationSettingsScope.ASSIGN_TO_ME));
        setIndex(getIndex())
    }, [scopes]);

    return (
        <div
            className={className}
        >
            <div
                className={classHeader}
            >
                {BOARD_PUSH_NOTIFICATION_SEND_UPDATES}
            </div>
            <Radio
                className={className + '--radio'}
                active={index}
                direction={'left'}
                onChange={ e => onScopeChangeHandler(BOARD_PUSH_NOTIFICATION_TYPE_RADIO[e.index].value)}
            >
                {
                    BOARD_PUSH_NOTIFICATION_TYPE_RADIO.map(item => {
                        const {title, value} = item
                        if (!scopeList.includes(EPushNotificationSettingsScope.ASSIGN_TO_ME) && value === EPushNotificationSettingsScope.ASSIGN_TO_ME) return
                        return (
                            <span
                                className={isReadonly ? 'disabled' : ''}
                                key={value}
                            >
                                {title}
                            </span>
                        )
                    })
                }
            </Radio>
            <div className={classLevel}>
                <div
                    className={classHeader}
                >
                    {BOARD_PUSH_NOTIFICATION_SUBCRIBE}
                </div>
                {BOARD_PUSH_NOTIFICATION_SUBSCRIBE_ON_CHECKBOXES.map((cb) =>
                    <Checkbox
                        key={cb.value}
                        checked={subscribeCases.includes(cb.value as EPushNotificationSubscribeCases)}
                        disabled={isReadonly}
                        value={cb.value as EPushNotificationSettingsScope}
                        onChange={() => onSubscribeCaseChangeHandler(cb.value as EPushNotificationSubscribeCases)}
                    >
                        {cb.title}
                    </Checkbox>)
                }
            </div>
            <div
                className={classHeader}
            >
                {BOARD_PUSH_NOTIFICATION_WHEN}
            </div>
            {BOARD_PUSH_NOTIFICATION_TYPE_CHECKBOXES.map((cb) =>
                <Checkbox
                    key={cb.value}
                    checked={types.includes(cb.value as EPushNotificationSettingsTypes)}
                    disabled={isReadonly || typesDisabled}
                    value={cb.value as EPushNotificationSettingsTypes}
                    onChange={() => onTypeChangeHandler(cb.value as EPushNotificationSettingsTypes)}
                >
                    {cb.title}
                </Checkbox>)}
            <div
                className={classHeader}
            >
                {BOARD_PUSH_NOTIFICATION_IMPORTANT}
            </div>
            {BOARD_PUSH_NOTIFICATION_IMPORTANT_CHECKBOXES.map((cb) =>
                <Checkbox
                    key={cb.value}
                    checked={scopeList.includes(cb.value as EPushNotificationSettingsScope)}
                    disabled={isReadonly}
                    value={cb.value as EPushNotificationSettingsScope}
                    onChange={() => onScopeChangeHandler(cb.value as EPushNotificationSettingsScope)}
                >
                    {cb.title}
                </Checkbox>)}
        </div>
    );
};
