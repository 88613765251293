import { cardsActionSetAction } from '../../actions/cardsActionSetAction';
import { TCardId } from '../../../../types/types';
import { cardActionSetAction } from '../../cards/actions/cardActionSetAction';
import { INotificationBeforeDueDate } from '../../notificationBeforeDueDate/types';
import { notificationBeforeDueDateUpdateAction } from '../../card/actions/notificationBeforeDueDateUpdateAction';
import { ICardsActionSetAction } from '../../actions/types';

export const notificationBeforeDueDateUpdate = (
    cardId: TCardId,
    notificationBeforeDueDate: INotificationBeforeDueDate
): ICardsActionSetAction => {
    return cardsActionSetAction(cardActionSetAction(cardId, notificationBeforeDueDateUpdateAction(notificationBeforeDueDate)));
};
