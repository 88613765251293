import { ERestMethod } from './types';
import { TBoardId, TCardId, TListId } from '../types/types';
import { TStatus } from '../types/model';
import { getAppVersion } from '../middlewares/segment/selectors/getAppVersion';
import { EBackupExportType } from '../types/rest/IRestBackup';

export const REST_BOARD = '/rest/dashboards/';
export const REST_LIST = '/rest/dashboards/lists/';
export const REST_CARDS = '/rest/dashboards/lists/cards';
export const REST_CARD = REST_CARDS + '/';
export const REST_AUTH_USER = '/rest/users/';
export const REST_COMMENTS_EDIT = '/rest/dashboards/lists/cards/comments/'
export const REST_COLOR = '/rest/dashboards/colors/';
export const REST_ROLE = '/rest/dashboards/roles/';
export const REST_NOTIFICATION_BEFORE_DUE_DATE_EDIT = '/rest/dashboards/lists/cards/notificationBeforeDueDates/';
export const REST_CARD_SECTIONS = '/sections';

export const REST_ATTACHMENT = '/rest/dashboards/lists/cards/drivedocs/';
export const REST_RECURRING_TASKS = '/rest/recurringTasks';

export const REST_CREATE_BOARD_SHARED_DRIVES = REST_BOARD + 'sharedDrive/';
export const REST_CREATE_BOARD_MY_DRIVES = REST_BOARD;

export const REST_CARDS_ASSIGNED_TO_ME = REST_CARDS + '/assignedToMe';
export const REST_CALENDAR_EVENTS = '/calendarevents';

export const REST_MY_TASKS = 'myTasks';
export const REST_MY_WORK = 'myWork';

export const getCardCommentRestUrl = (cardId: TCardId): string => {
    return REST_CARD + cardId + '/comments';
}

export const getBoardLogoRestUrl = (boardId: TBoardId): string => {
    return REST_BOARD + boardId + '/logo';
}
export const getBoardBackupsRestUrl = (boardId: TBoardId): string => {
    return REST_BOARD + boardId + '/backups';
}
export const getAutoBackupScheduleRestUrl = (autoBackupId: number): string => {
    return REST_BOARD + 'autoBackup/' + autoBackupId + '/backupSchedules';
}
export const getListCheckAvailCardRestUrl = (listId: TListId, status: TStatus): string => {
    return REST_LIST + listId + '/checkAvailabilityCardByStatus?status=' + status;
}
export const getBoardRolesUrl = (boardId: TBoardId): string => {
    return REST_BOARD + boardId + '/roles';
}
export const getBoardActivityUrl = (boardId: TBoardId): string => {
    return REST_BOARD + boardId + '/activity';
}
export const getBoardLoadForMoveUrl = (boardId: TBoardId): string => {
    return REST_BOARD + boardId + '/forMove';
}

export const REST_FIRST_BOARD =    '/rest/firstBoard';
export const REST_PREDECESSOR =    '/rest/predecessors';
export const REST_PREDECESSOR_LIST = '/rest/predecessors-list'
export const REST_ACTIVITIES = '/rest/dashboards/activities/';
export const REST_ACTIVITY = '/activity/';
export const REST_ACTIVITY_NOTIFY = '/notifyPeople';
export const REST_CARD_CHECKLIST_ITEMS = 'checklistitems';
export const REST_CHECKLIST_ITEM = REST_CARD + REST_CARD_CHECKLIST_ITEMS + '/';
export const REST_CHECKLISTS = '/checklists/';
export const REST_CARD_RELATED_CARDS = '/relatedCards';
export const REST_NOTIFICATIONS_SETTINGS = '/notifications';
export const REST_PUSH_NOTIFICATIONS_SETTINGS = '/dashboardPushNotifications';
export const REST_THEMES = '/rest/themes';
export const REST_BOARD_THEME = '/rest/themes/dashboards/';
export const REST_THEME_FILE = '/rest/themes/file';
export const REST_USER_STAT_ADMIN = '/rest/userActivityStat/event/';
export const REST_USER_STAT_ADMIN_CHECK_DUPLICATES = '/rest/userActivityStat/checkDuplicates/';
export const REST_PORTION_SIZE = 10;
export const REST_BOARD_STARTLISTENING = '/startlistening';
export const REST_BOARD_UPDATE_PERMISSIONS = '/updatePermisions';
export const REST_BOARD_PERMISSIONS = '/permissions';
export const REST_BOARD_USERS = '/users';
export const REST_LOAD_MORE_URL = '/rest/dashboards/listForMove';
export const REST_AUTH_USER_DELETE_PUSH_TOKEN = REST_AUTH_USER + 'web-push-notify/delete-token'
export const REST_AUTH_USER_UPDATE_ACCESS_TIME = REST_AUTH_USER + 'web-push-notify/update-access-time'
export const REST_UMUX = '/rest/umuxpoll'
export const REST_CSAT = '/rest/csatPoll';
export const REST_MESSENGER_POLL = '/rest/messengerPoll';

const REST_JSON_HEADER = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
};
const REST_DELETE = {
    headers: {
        ...REST_JSON_HEADER,
        'X-HTTP-Method-Override': 'DELETE'
    },
    method: 'POST'
};
const REST_GET = {
    headers: {
        ...REST_JSON_HEADER,
    },
    method: 'GET'
};
const REST_PATCH = {
    headers: {
        ...REST_JSON_HEADER,
        'X-HTTP-Method-Override': 'PATCH'
    },
    method: 'POST'
};
const REST_PUT = {
    headers: {
        ...REST_JSON_HEADER,
        'X-HTTP-Method-Override': 'PUT'
    },
    method: 'POST'
};
const REST_POST = {
    headers: {
        ...REST_JSON_HEADER,
    },
    method: 'POST'
};

export const getRestHeaders = (
    methtod: ERestMethod
): RequestInit => {
    switch (methtod) {
        case ERestMethod.DELETE: {
            return {...REST_DELETE};
        }

        case ERestMethod.GET: {
            return {...REST_GET};
        }

        case ERestMethod.PATCH: {
            return {...REST_PATCH};
        }

        case ERestMethod.POST: {
            return {...REST_POST};
        }

        case ERestMethod.PUT: {
            return {...REST_PUT};
        }

        default:
            return {};
    }
};
