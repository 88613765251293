import { TBoardId } from '../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { fetchHandler } from '../../../../../util/fetchHandler';
import { Util } from '../../../../../util/util';
import { getRestHeadersPatch } from '../../../../helpers/getRestHeadersHelper';
import { boardsActionSet } from '../../../../../store/model/actions/boardsActionSet';
import { boardActionSet } from '../../../../../store/model/boards/actions/boardActionSet';
import { IBoard } from '../../../../../store/model/board/types';
import { updateAction } from '../../../../../store/model/board/actions/updateAction';
import { sendRealTimeStoreActionByBoardId } from '../../../../../view/react_components/base/helpers/realTimeHelperTS';

export const boardPatchFile = (
    boardId: TBoardId,
    file: File,
    url: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        return fetchHandler<IBoard>(
            Util.getApiUrl(url), {
                ...getRestHeadersPatch(),
                body: file
            }
        ).then((board: IBoard) => {
            const actionSet = boardsActionSet(boardActionSet(boardId, updateAction(board)));
            dispatch(actionSet);
            dispatch(sendRealTimeStoreActionByBoardId(boardId, actionSet))
            return board;
        });
    };
    return action;
};
