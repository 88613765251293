import { Dispatch, ThunkAction } from '../../../types/actions';
import { IGetState, TBoardId } from '../../../types/types';
import { EBoardVersion } from '../../../types/rest/IRestDashboard';
import { fetchHandler } from '../../../util/fetchHandler';
import Util from '../../../util/util';
import { getRestHeadersPatch } from '../../helpers/getRestHeadersHelper';
import { REST_BOARD } from '../../constants';

export const setVersion = (
    version: EBoardVersion,
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const board = {
            id: boardId,
            version
        };
        return fetchHandler(
            Util.getApiUrl(REST_BOARD + boardId), {
                ...getRestHeadersPatch(),
                body: JSON.stringify(board)
            }
        )
    };
    return action;
}
