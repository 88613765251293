import { IGetState, TBoardId } from '../../../types/types';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { boardPatchRest } from './api/helpers/boardPatchRest';
import { boardsSet } from 'app/view/react_components/dialogs/openBoards/store/boards/actions/boardsSet';
import { getBoard } from 'app/view/react_components/dialogs/openBoards/store/boards/selectors/getBoard';

export const boardSetStarred = (
    boardId: TBoardId,
    starred: boolean
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(boardPatchRest(boardId, {starred}, false))
            .then(() => {
                // в openBoards доски теперь не обнуляются при открытии, т.к. оттуда берутся starred для навпанели
                // нужно обновить там starred
                const board = getBoard(getState(), boardId);
                if (board) {
                    dispatch(boardsSet([
                        {
                            ...board,
                            isStarred: starred,
                        }
                    ]));
                }
            });
    };
    return action;
}
