import { IRestPredecessor } from '../../../../../types/rest/IRestPredecessor';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { REST_PREDECESSOR } from '../../../../constants';
import { fetchHandler } from '../../../../../util/fetchHandler';
import Util from '../../../../../util/util';
import { getRestHeadersDelete } from '../../../../helpers/getRestHeadersHelper';
import { TDependencyId } from '../../../../../store/model/dependencies/dependency/types';
import { IGetState, TBoardId } from '../../../../../types/types';

export const deleteRest = (
    dependencyId: TDependencyId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const url: string = REST_PREDECESSOR + '/' + dependencyId;
        return fetchHandler<IRestPredecessor>(
            Util.getApiUrl(url), {
                ...getRestHeadersDelete()
            }
        );
    };
    return action;
};
