import {IAutoBackupState} from '../../autoBackupsBoard/types';
import {IAutoBackupAddAction} from './types';
import {AT_AUTO_BACKUPS_ADD_ACTION} from './constants';

export const autoBackupsAdd = (
    autoBackup: IAutoBackupState
): IAutoBackupAddAction => ({
    type: AT_AUTO_BACKUPS_ADD_ACTION,
    autoBackup
});
