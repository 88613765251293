import { IApplicationState, TBoardId } from '../../../../../../../types/types';
import { getBoardCardCustomProperties } from '../../../../../../../store/model/board/selectors/getBoardCardCustomProperties';
import { ORDER_STEP } from '../../../../../../../const';

export const getNextCustomPropertyOrderNumber = (
    state: IApplicationState,
    boardId: TBoardId,
): number => {
    const boardCardCustomProperties = getBoardCardCustomProperties(state, boardId);
    let orderNumber = 0;

    for (let key in boardCardCustomProperties) {
        if (orderNumber < boardCardCustomProperties[key].orderNumber) {
            orderNumber = boardCardCustomProperties[key].orderNumber;
        }
    }

    return orderNumber + ORDER_STEP;
};
