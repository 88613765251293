import { E12HoursType } from '../components/DatepickerItem/types';
import { TWELVE_HOURS } from '../components/constants';

export const getHourValue = (
    date: Date,
    hoursType?: E12HoursType,
): number => {
    const hours = date.getHours();
    return hoursType && hoursType === E12HoursType.PM && hours <= TWELVE_HOURS
        ? hours + TWELVE_HOURS
        : hoursType === E12HoursType.AM && hours >= TWELVE_HOURS
            ? hours - TWELVE_HOURS
            : hours;
}
