import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TBoardId, TCardId } from '../../../../../../../../types/types';
import { SegmentCardEvent, SegmentCardOptions, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { SegmentCardSection } from '../../../../../../../../middlewares/segment/trackEntities/cardEvents';
import { getBoardIsCardDefaultProperty } from '../../../../../../../../store/model/board/selectors/getBoardIsCardDefaultProperty';
import { EAsidePanelProperty } from '../../../../../asidePanel/components/AsidePanel/types';
import { getCard } from '../../../../../../../../store/model/selectors/getCard';

export const onClick = (
    boardId: TBoardId,
    cardId: TCardId,
    ownOnClick?: () => void
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const card = getCard(getState(), cardId);
        const isFirst = !card.dueDate && !card.startDate &&
            !getBoardIsCardDefaultProperty(getState(), boardId, EAsidePanelProperty.CARD_START_DATE);
        dispatch(segmentTrackAction(SegmentCardEvent.BUTTONS_PANEL_BUTTON_CLICKED, {
            name: SegmentCardOptions.TARGET,
            value: SegmentCardSection.DUE_START_DATE
        }, [{
            name: SegmentCardOptions.FIRST,
            value: String(isFirst)
        }]));
        if (ownOnClick) ownOnClick();
    };
    return action;
};
