import { Dispatch } from '../../../../../types/actions';
import { IGetState } from '../../../../../types/types';
import { PERFORMANCE_TYPE } from '../constants';
import { saveAllowedCookiesUser } from './saveAllowedCookiesUser';
import { getNewPerformanceAllowedValueSelector } from '../selectors/getNewPerformanceAllowedValueSelector';
import { isUserAuthorized } from '../../../../../store/model/authUser/selectors/isUserAuthorized';

export const saveUserCookies = () => {
    const action = (dispatch: Dispatch, getState: IGetState) => {
        const state = getState();
        const isPerformanceAllowed = getNewPerformanceAllowedValueSelector(state);
        const scopes = isPerformanceAllowed ? [PERFORMANCE_TYPE] : [];
        const isAuth = isUserAuthorized(state);

        if(isAuth) {
            dispatch(saveAllowedCookiesUser(scopes));
        }
    };
    return action;
};
