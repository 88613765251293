import {ActionCreator} from 'redux';
import {Dispatch, ThunkAction} from '../../../../../../../types/actions';
import {IGetState} from '../../../../../../../types/types';
import { boardCreateFromTemplateClickedSegmentEvent } from '../../../effects/segmentEvents';
import { TTabKey } from '../../../../../dialogs/openBoards/store/types';
import { root } from 'app/store/constants';

export const onCreateFromTemplate: ActionCreator<ThunkAction> = (
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        root.App.controller.mainView.openBoardsDialogShow(TTabKey.templates);
        dispatch(boardCreateFromTemplateClickedSegmentEvent());
    };
    return action;
};
