import { IChecklistsUpdateAction } from './types';
import { AT_CHECKLISTS_UPDATE } from './constants';
import { ICardChecklists } from '../types';
import { TCardId } from '../../../../../types/types';

export const checklistsUpdateAction = (
    cardId: TCardId,
    checklists: ICardChecklists
): IChecklistsUpdateAction => {
    return {
        type: AT_CHECKLISTS_UPDATE,
        cardId,
        checklists
    }
};
