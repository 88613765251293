import { createRoot } from 'react-dom/client';
import {Provider} from "react-redux";
import React from "react";
import { removeAsidePanelPortal } from "../../../helper/removeAsidePanelPortal";
import { LazyWrapper } from 'app/view/react_components/lazy/lazy_wrapper';

App.Views.FilterPanelView = App.Views.BaseView.extend({
    className: 'filter-panel__reactinbackbone',

    initialize: function(options){
        this.boardId = options.boardId;
        this.parent = options.parent;
        this.root = createRoot(this.el);
        this.store = options.store;
        this.scrollElem = options.scrollElem;
    },

    render: function () {
        this.parent.$el.find('.aside-panel__hide').hide();
        const ImportElement = React.lazy(() =>
            import(/* webpackChunkName: "filter" */ '../../react_components/aside_panel/asidePanel/hocs/AsidePanelFilterBodyHOC/AsidePanelFilterBodyHOC')
                .then(module => ({default: module.AsidePanelFilterBodyHOC}))
                .catch(e => console.error(e))
        );
        this.root.render(
            <LazyWrapper>
                <Provider store={store}>
                    <ImportElement
                        boardId={this.boardId}
                        closedRef={document.activeElement}
                        scrollElem={this.scrollElem}
                    />
                </Provider>
            </LazyWrapper>
        );
        return this;
    },

    remove: function () {
        this.parent.$el.find('.aside-panel__hide').show();
        this.root.unmount();
        Backbone.View.prototype.remove.call(this);
        removeAsidePanelPortal(this.el);
    }
});

export default App.Views.FilterPanelView;
