import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { showSnoozeBlocker } from '../../../../../base/effects/showSnoozeBlocker';

export const checkTabBackupsPermission = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(showSnoozeBlocker({
            allow: 'allowAutoBackupXml'
        }));
    };
    return action;
};
