import { ISearchSelectOption } from '../types';
import { isElementHasOptions } from './isElementHasOptions';
import { filterArrayByAcronym } from '../../../../helpers/search/filterArrayByAcronym';
import { filterArrayByValue } from '../../../../helpers/search/filterArrayByValue';

export const filterAndSortArray = (
    list: ISearchSelectOption[],
    searchQuery: string,
    isAcronymSearchEnabled: boolean
): ISearchSelectOption[] => {
    if (!searchQuery) return [...list];
    // первый элемент списка не содержит options, значит это уже данные, которые надо фильтровать - применяем searchMethod
    if (!isElementHasOptions(list[0])) {
        const searchMethod = isAcronymSearchEnabled ? filterArrayByAcronym : filterArrayByValue;
        const options = searchMethod(list, 'text', searchQuery);
        options.sort((o1, o2) => {
            if (!o1.text || !o2.text || o1.text.toLowerCase() === o2.text.toLowerCase()) return 0;
            if (o1.text.toLowerCase() === searchQuery.toLowerCase()) return -1;
            if (o2.text.toLowerCase() === searchQuery.toLowerCase()) return 1;
            return 0;
        });
        return options;
    }

    const filtered = list.reduce((items, item) => {
        const options = filterAndSortArray(item.options, searchQuery, isAcronymSearchEnabled);
        if (options && options.length) {
            items.push({
                ...item,
                options
            });
        }
        return items;
    }, []);
    return filtered;
};
