import * as React from 'react';
import '../CardColorTags/_CardColorTags.scss';
import { Button, Icon } from 'kui';
import { ICardColorTagTooltipProps } from './types';
import './_CardColorTagsTooltip.scss';
import { CLASS_CARD_DETAILS } from '../../../constants';
import { CARD_COLOR_TAGS_EDIT_TOOLTIP } from '../../hocs/CardColorTagsTooltipHOC/constants';

export function CardColorTagsTooltip({
    isShow,
    onClose
}: ICardColorTagTooltipProps) {
    if (!isShow) return;

    const className = CLASS_CARD_DETAILS + '__color-tags-tooltip';

    return (
        <div className={className}>
            <span className={className + '-text'}>
                {CARD_COLOR_TAGS_EDIT_TOOLTIP}
            </span>
            <Button
                className={className + '-button'}
                variant={'icon'}
                onClick={onClose}
            >
                <Icon xlink={'close'} size={24}/>
            </Button>
        </div>
    );
};
