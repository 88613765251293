import { connect } from 'react-redux';
import { onMoreLinkClick } from './events/onMoreLinkClick';
import { LinksToCards } from '../../components/LinksToCards/LinksToCards';
import { ILinksToCardsEvents, ILinksToCardsFields } from '../../components/LinksToCards/types';
import { IApplicationState } from '../../../../../../../../types/types';
import { getPanelReadonly } from '../../../../selectors/getPanelReadonly';
import { ILinksToCardsHOCProps } from './types';

const mapStateToProps = (
    state: IApplicationState,
    { boardId }: ILinksToCardsHOCProps
): ILinksToCardsFields => {
    const isDisabled = getPanelReadonly(state, boardId, null, null, 'allowEnableOpenGraphInfo');
    return {
        isDisabled
    };
};

const mapDispatchToProps = (
    dispatch: any
): ILinksToCardsEvents => {
    return {
        onMoreLinkClick: () => dispatch(onMoreLinkClick())
    }
};

export const LinksToCardsHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(LinksToCards);

LinksToCardsHOC.displayName = 'LinksToCardsHOC';
