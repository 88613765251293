import {Dispatch, ThunkAction} from '../../../../types/actions';
import {IGetState, TBoardId} from '../../../../types/types';
import {getSelectedCards} from '../selectors/getSelectedCards';
import {root} from '../../../../store/constants';
import { getActiveCardId } from '../../../../store/router/selectors/getActiveCardId';
import { getPanelTab } from '../../../../store/router/selectors/getPanelTab';
import { getIsMultiselect } from '../selectors/getIsMultiselect';

export const changeRoute = (
    boardId: TBoardId,
    trigger: boolean = true,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const selectedCards = getSelectedCards(state, boardId);
        const isMultiselect = getIsMultiselect(state, boardId);
        let route = '';
        if (!selectedCards || !selectedCards.length) {
            // ничего не делать
        } else if (isMultiselect || selectedCards.length > 1) {
            route = 'cs-' + selectedCards.join('-');
        } else if (selectedCards.length === 1) {
            route = 'c-' + selectedCards[0];
            const activeCardId = getActiveCardId(state);
            if (activeCardId) {
                const tab = getPanelTab(state);
                if (tab) route += '/' + tab;
            }
        }
        root.App.router.navigate(root.App.router.getUrl(route), { trigger });
    };
    return action;
};
