import {IApplicationState} from '../../../../../../types/types';
import {ITopBarState} from '../topBar/types';
import {getTopBarInitialState} from '../constants';

export const getTopBar = (
    state: IApplicationState,
    boardId: number
): ITopBarState => {
    return state.topBar[boardId] || getTopBarInitialState();
};
