import { EMPTY_ARRAY, IApplicationState } from '../../../../../../../../types/types';
import { connect } from 'react-redux';
import { IPushNotificationsHOCProps } from './types';
import { PushNotifications } from '../../components/PushNotifications/PushNotifications';
import { IPushNotificationsEvents, IPushNotificationsFields } from '../../components/PushNotifications/types';
import {
    EPushNotificationSettingsScope,
    EPushNotificationSubscribeCases,
    EPushNotificationSettingsTypes
} from '../../../../../../../../types/rest/IRestPushNotificationSettings';
import { getPushNotificationSettingsByBoardId } from '../../../../../../../../store/model/selectors/getPushNotificationSettingsByBoardId';
import { onScopeClick } from './events/onScopeClick';
import { getPanelReadonly } from '../../../../selectors/getPanelReadonly';
import { onChange } from './events/onChange';
import { onNotifySubscribeCaseClick } from './events/onNotifySubscribeCaseClick';
import { onNotifyTypeClick } from './events/onNotifyTypeClick';

const mapStateToProps = (
    state: IApplicationState,
    ownProps: IPushNotificationsHOCProps
): IPushNotificationsFields => {
    const { boardId } = ownProps;
    const pushNotification = getPushNotificationSettingsByBoardId(state, boardId);
    const isReadonly = getPanelReadonly(state, boardId);
    return {
        isEnabled: pushNotification && pushNotification.enabled,
        isReadonly,
        scopes: pushNotification && pushNotification.scope ? pushNotification.scope : EMPTY_ARRAY,
        types: pushNotification && pushNotification.types ? pushNotification.types : EMPTY_ARRAY,
        subscribeCases: pushNotification && pushNotification.subscribeCases ? pushNotification.subscribeCases : EMPTY_ARRAY,
    }
};

const mapDispatchToProps = (
    dispatch: any,
    ownProps: IPushNotificationsHOCProps
): IPushNotificationsEvents => {
    const { boardId } = ownProps;
    return {
        onScopeClick: (scope: EPushNotificationSettingsScope, checked: boolean) => dispatch(onScopeClick(scope, checked)),
        onNotifySubscribeCaseClick: (scope: EPushNotificationSubscribeCases, checked: boolean) => dispatch(onNotifySubscribeCaseClick(scope, checked)),
        onNotifyTypeClick: (scope: EPushNotificationSettingsTypes, checked: boolean) => dispatch(onNotifyTypeClick(scope, checked)),
        onChange: (scopes: EPushNotificationSettingsScope[],
                   subscribeCases: EPushNotificationSubscribeCases[],
                   types: EPushNotificationSettingsTypes[]) => dispatch(onChange(boardId, scopes, subscribeCases, types))
    }
};

export const PushNotificationsHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(PushNotifications);

PushNotificationsHOC.displayName = 'PushNotificationsHOC';
