import { IApplicationState, TCardId, TTag } from '../../../types/types';
import { getCardTags } from './getCardTags';

export const getCardTagsToUpdate = (
    state: IApplicationState,
    cardId: TCardId,
    tag: TTag,
    name: TTag
): TTag[] => {
    const tags = [...getCardTags(state, cardId)];
    const index = tags.indexOf(tag);
    if (index < 0) return null;
    const newIndex = tags.indexOf(name);
    if (newIndex < 0 && name) { // если name пустой, то просто удаляем текущий тег
        tags.splice(index, 1, name);
    } else {
        tags.splice(index, 1);
    }
    return tags;
}
