import { IApplicationState, TCardId, TTag } from '../../../types/types';

export const getCardTags = (
    state: IApplicationState,
    cardId: TCardId
): TTag[] => {
    const card = state.model.cards[cardId];
    if (
        !card ||
        !card.tags ||
        !card.tags.length
    ) return [];

    return card.tags;
}
