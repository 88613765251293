import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { IGetState, TBoardId } from '../../../../../../../../../types/types';
import { ECardNumberStatus } from '../../../store/cardNumber/types';
import { cardNumberSetAction } from '../../../store/cardNumbers/actions/cardNumberSetAction';
import { statusChange } from '../../../store/cardNumber/actions/statusChange';
import { getCardNumberByBoardId } from '../../../store/cardNumbers/selectors/getCardNumberByBoardId';
import { getValidationError } from '../../../helpers/getValidationError';
import { cardNumberStateUpdateAction } from '../../../store/cardNumber/actions/cardNumberUpdate';
import { getBoardCardsActiveAndArchive } from '../../../../../../../../../store/model/selectors/getBoardCardsActiveAndArchive';
import { root } from '../../../../../../../../../store/constants';

export const validateCardNumber = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(cardNumberSetAction(boardId, statusChange(ECardNumberStatus.VALIDATING)));
        const { prefix, nextValue } = getCardNumberByBoardId(getState(), boardId);
        root.App.controller.loadBoardArchiveToStore(boardId).then(() => {
            dispatch(cardNumberSetAction(boardId, cardNumberStateUpdateAction({
                errorMessage: getValidationError(getBoardCardsActiveAndArchive(getState(), boardId), prefix, nextValue),
                status: ECardNumberStatus.LOADED
            })));
        });
    }
    return action;
}
