import * as React from 'react';
import { ICardCustomPropertiesProps } from './types';
import { SelectListItem } from 'kui';
import './_CardCustomProperties.scss';
import { CLASS_BOARD_CARD_CUSTOM } from '../../constants';
import { CUSTOM_PROPERTY_TYPES } from '../../../../../../../../store/customProperties/constants';

export function CardCustomProperties ({
    elements,
    onEdit,
}: ICardCustomPropertiesProps) {
    const className = CLASS_BOARD_CARD_CUSTOM;
    const classItem = className + '__item';

    return (
        <div className={className}>
            {elements.map(element => {
                return (
                    <SelectListItem
                        className={classItem}
                        key={element.id}
                        list={CUSTOM_PROPERTY_TYPES[element.type]}
                        onClick={onEdit}
                    >
                        {element.name}
                    </SelectListItem>
                );
            })}
        </div>
    );
};
