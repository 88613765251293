import * as React from 'react';
import './_AsidePanel.scss';
import { CLASS_ASIDE_PANEL, CLASS_ASIDE_PANEL_STATIC, CLASS_ASIDE_PANEL_STATIC_NO_HEADER } from '../../constants';
import { AsidePanelHeader } from '../AsidePanelHeader/AsidePanelHeader';
import { AsidePanelBodyHOC } from '../../hocs/AsidePanelBodyHOC/AsidePanelBodyHOC';
import { AsidePanelContext } from './constants';
import { EAsidePanelProperty, IAsidePanelContext, IAsidePanelProps } from './types';
import { AsidePanelPrintStyle } from '../AsidePanelPrintStyle/AsidePanelPrintStyle';
import { root } from 'app/store/constants';
import { AsidePanelFooter } from '../AsidePanelFooter/AsidePanelFooter';
import { MoveFocusInside } from 'react-focus-lock';
import { CLASS_ASIDE_PANEL_ACTIONS_SCROLL } from '../AsidePanelActions/constants';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { ASIDE_MAX_WIDTH, ASIDE_MIN_WIDTH } from 'app/const';
import { v4 as uuidv4 } from 'uuid';
import { CLASS_ASIDE_PANEL_BODY } from '../AsidePanelBody/constants';
import { HEADER_HEIGHT } from '../AsidePanelHeader/constants';
import { getParentsClasses } from 'app/view/react_components/helpers/getParentsClasses';
import { ID_ASIDE_PANEL_ACTIONS_PORTAL_MOBILE, ID_ASIDE_PANEL_DATEPICKER_PORTAL } from '../AsidePanelActionsButton/constants';
import { SegmentCardDetailsCloseSourceValue } from '../../../../../../middlewares/segment/trackEntities/cardEvents';
import { AnalyzeRender } from 'app/view/react_components/helpers/memoizeHelper';
import { isEqual } from 'underscore';

//@ts-ignore
const analyzeRender = window.Settings.development ? new AnalyzeRender(`AsidePanel_container`) : null;

export function AsidePanel ({
    activeTabIndex,
    asidePanelWidth,
    boardId,
    cardId,
    listId,
    children,
    className,
    closedRef,
    filterId,
    footer,
    isActionsScticky,
    isActionsMinimized,
    isButtonsFixed,
    isFakeRequests,
    isLeft,
    isLoading,
    isMobile,
    isPrint,
    isRequests,
    isUserTimeFormat12,
    mobileButton,
    isAssignedToMe,
    isCardFromNotification,
    tabs,
    title,
    titleAction,
    type,
    isForceClosed,
    onClose,
    onTabActive,
    onDidMount,
    onDidUnmount,
    onResize,
    onResized,
    panelActions,
}: IAsidePanelProps) {
    if (analyzeRender) analyzeRender.call(`${boardId} ${listId} ${cardId}`);

    const [activeHook, setActiveHook] = React.useState(activeTabIndex);
    const [isLoadingHook, setIsLoading] = React.useState(isLoading);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [isWithoutHeader, setWithoutHeader] = React.useState(false);
    const [s] = React.useState<any>({}); // положить getActiveHook в useState, чтобы сhangeTab из контекста получил актуальный activeHook
    s.activeHook = activeHook;

    const panelRef = React.useRef(null);

    const classNamePanel = CLASS_ASIDE_PANEL;
    const classNamePanelPrint = isPrint ? CLASS_ASIDE_PANEL + '--print' : '';
    const classNamePanelMobile = isMobile ? CLASS_ASIDE_PANEL + '--mobile' : '';
    const classNamePanelFooter = footer ? CLASS_ASIDE_PANEL + '--footer' : '';
    const classNameActionsMinimized = isActionsMinimized && !isButtonsFixed ? CLASS_ASIDE_PANEL + '--actions-minimized' : '';
    const classNameWithoutHeader = isWithoutHeader ? CLASS_ASIDE_PANEL + '--without-header' : '';
    const classNamePanelResizer = classNamePanel + '__resizer';

    const addShowedProperty = React.useCallback((
        property: EAsidePanelProperty
    ) => {
        const showedProperties = new Set([...context.showedProperties]) as Set<EAsidePanelProperty>;
        showedProperties.add(property);
        const contextNew = {
            ...context,
            showedProperties,
        };
        // showedProperty обновляем всегда т.к. set не проходит проверку в isEqual
        // напрямую showedProperty менять тоже нельзя, иначе компоненты обновляются с большим лагом
        context = contextNew;
        _setContext(contextNew);
    }, []);

    const onLoaded = React.useCallback((
        key: string, isLoading: boolean
    ) => {
        requestAnimationFrame(() => setIsLoading(!isLoading));
    }, []);

    const onTabChange = React.useCallback((
        activeTabIndex: number
    ) => {
        setActiveHook(activeTabIndex);
        if (onTabActive) onTabActive(activeTabIndex);
    }, []);

    const changeTab = React.useCallback((
        tabKey: string
    ) => {
        const activeHook = s.activeHook;
        if (tabs[activeHook].key === tabKey) return;

        const tabIndex = tabs.findIndex(tab => tab.key === tabKey);
        onTabChange(tabIndex);
    }, []);

    const setIsScrollDisabled = React.useCallback((
        isScrollDisabled: boolean
    ) => {
        setContext({isScrollDisabled});
    }, []);

    const setOpenedProperty = React.useCallback((
        openedProperty: EAsidePanelProperty
    ) => {
        setContext({openedProperty});
    }, []);

    let [context, _setContext] = React.useState({
        boardId,
        cardId,
        listId,
        filterId,
        isButtonsFixed,
        isMobile,
        isUserTimeFormat12,
        mobileButton,
        type,
        tabs,
        showedProperties: new Set,
        changeTab,
        setIsScrollDisabled,
        setOpenedProperty,
        onLoaded,
        addShowedProperty,
        asidePanelWidth
    } as IAsidePanelContext);
    function setContext (
        value: IAsidePanelContext
    ) {
        const contextNew = {
            ...context,
            ...value
        };

        if (!isEqual(contextNew, context)) {
            context = contextNew;
            _setContext(contextNew);
        }
    }

    const resizer = React.useRef({
        actionsWidth: 0,
        minWidth: ASIDE_MIN_WIDTH,
        maxWidth: ASIDE_MAX_WIDTH,
        width: 0,
        x: 0,
    });
    const resizerRef = React.useRef(null);
    const [resizerKey, setResizerKey] = React.useState('resizer-key');

    const handleResizerDragStart = () => {
    };

    const handleResizerDrag = (
        e: DraggableEvent,
        draggableData: DraggableData,
    ) => {
        resizer.current.x = draggableData.x;
        const asideWidth = resizer.current.width - draggableData.x;
        root.App.controller.mainView.setAsidePanelWidth(asideWidth + 'px');
    };

    const handleResizerDragStop = () => {
        if (resizer.current.x) {
            resizer.current.width -= resizer.current.x;
            resizer.current.x = 0;
            setResizerKey(uuidv4());
            if (onResized) onResized(resizer.current.width - resizer.current.actionsWidth);
        }
    };

    const onMouseDown = () => {// to keep asidePanel open if onMouseUp is outside asidePanel
        root.App.controller.setAsidePanelMouseDown(true);
    };
    React.useEffect(() => {
        requestAnimationFrame(() => {
            const asidePanelActions = panelRef.current && panelRef.current.querySelector('.' + CLASS_ASIDE_PANEL_ACTIONS_SCROLL) as HTMLElement;
            if (asidePanelActions) {
                const actionsWidth = asidePanelActions.offsetWidth;
                resizer.current.actionsWidth = actionsWidth;
                resizer.current.minWidth = ASIDE_MIN_WIDTH + actionsWidth;
                resizer.current.maxWidth = ASIDE_MAX_WIDTH + actionsWidth;
            }

            resizer.current.width = panelRef.current && panelRef.current.offsetWidth || 0;
            if (resizerRef.current) resizerRef.current.style.right = resizer.current.width;
            setResizerKey(uuidv4());
        });
    }, [
        className,
        isActionsMinimized,
    ]);

    React.useEffect(() => {
        const body = panelRef.current && panelRef.current.querySelector('.' + CLASS_ASIDE_PANEL_BODY) as HTMLElement;
        let scrollTop = 0;
        let isWithoutHeader = false;
        let blocker: number;

        function _setWithoutHeader(is: boolean) {
            if (isWithoutHeader === is) return;

            isWithoutHeader = is;
            setWithoutHeader(is);
            if (blocker) clearTimeout(blocker);
            blocker = root.setTimeout(() => { // блокер для onScroll, пока меняется состояние хэдера
                blocker = null;
            }, 300);
        }

        function onScroll () {
            const asidePanel = document.querySelector('.' + CLASS_ASIDE_PANEL) as HTMLElement;
            if (
                blocker ||
                (
                    asidePanel && (
                        asidePanel.classList.contains(CLASS_ASIDE_PANEL_STATIC_NO_HEADER) ||
                        asidePanel.classList.contains(CLASS_ASIDE_PANEL_STATIC)
                    )
                )
            ) {
                return;
            }

            if (body.scrollTop < scrollTop) { // при скроле наверх сразу возвращать хэдер
                _setWithoutHeader(false);
            } else if (
                !isWithoutHeader &&
                body.scrollTop > HEADER_HEIGHT * 2 // прятать хэдер, когда прокрутили больше его высоты
            ) {
                _setWithoutHeader(true);
            }
            scrollTop = body.scrollTop;
        }

        if (isActionsScticky) {
            if (body) {
                body.addEventListener('scroll', onScroll);
            }
        } else {
            _setWithoutHeader(false);
            if (body) body.removeEventListener('scroll', onScroll);
        }

        return ()=>{
            if (body) body.removeEventListener('scroll', onScroll);
            if (blocker) clearTimeout(blocker);
        }
    }, [isActionsScticky]);

    React.useEffect(() => {
        setContext({
            filterId,
            isMobile,
            isUserTimeFormat12,
            tabs,
            asidePanelWidth
        });
    }, [
        filterId,
        isMobile,
        isUserTimeFormat12,
        tabs,
        activeTabIndex,
        asidePanelWidth
    ]);

    React.useEffect(() => {
        onTabChange(activeTabIndex);
    }, [activeTabIndex]);

    React.useEffect(() => {
        setIsLoading(isLoading);
    }, [isLoading]);

    React.useEffect(() => {
        function onResizeWindow () {
            if (onResize) onResize(root._innerWidth);
        };

        if (onDidMount) {
            onDidMount().then(() => {
                setIsLoaded(true);
            });
        } else {
            setIsLoaded(true);
        }
        if (onResize) {
            requestAnimationFrame(onResizeWindow);
            root.removeEventListener('resize', onResizeWindow);
            root.addEventListener('resize', onResizeWindow);
        }

        function onEsc (e: KeyboardEvent) {
            if (e.key === 'Escape') {
                const target = e.target as HTMLElement;
                const targetClasses = getParentsClasses(
                    target, [
                        'kui-select-list__item', // закрыть дропдаун
                        'kui-input', // убрать фокус с инпута
                        CLASS_ASIDE_PANEL,
                    ]
                );
                if (targetClasses.includes('kui-select-list__item')) return;

                if (targetClasses.includes('kui-input')) {
                    const closestTabIndex = target.closest('[tabindex]') as HTMLElement;
                    if (closestTabIndex) closestTabIndex.focus();
                    return;
                }
                if (!document.activeElement.classList.contains('picker-dialog')) {
                    onClose(SegmentCardDetailsCloseSourceValue.ESC_BUTTON)
                }
            }
        };
        root.addEventListener('keydown', onEsc);

        return () => {
            if (onDidUnmount) onDidUnmount();
            if (onResize) {
                root.removeEventListener('resize', onResizeWindow);
            }
            if (
                closedRef &&
                document.body === document.activeElement // если фокус "нигде", вернуть инициатору панели
            ) {
                closedRef.focus();
            }
            root.removeEventListener('keydown', onEsc);
        }
    }, []);
    const ResizerSvg = require('!!@svgr/webpack!../../../../../../../../../assets/svg/spreader.svg').default;

    const content = (
        <AsidePanelContext.Provider value={context}>
            <div
                className={`
                    ${classNamePanel}
                    ${className || ''}
                    ${classNamePanelPrint}
                    ${classNamePanelMobile}
                    ${classNamePanelFooter}
                    ${classNameActionsMinimized}
                    ${classNameWithoutHeader}
                `}
                ref={panelRef}
                onMouseDown={onMouseDown}
                tabIndex={-1}
            >
                {isLoadingHook &&
                    <div className={classNamePanel + '__loader'} />
                }
                <AsidePanelHeader
                    activeTabIndex={activeHook}
                    isLoading={isLoadingHook || isRequests}
                    isFakeLoading={isFakeRequests}
                    tabs={tabs}
                    title={title}
                    titleAction={titleAction}
                    isForceClosed={isLoaded && isForceClosed}
                    onClose={onClose}
                    onTabChange={onTabChange}
                    isAssignedToMe={isAssignedToMe}
                    isCardFromNotification={isCardFromNotification}
                />
                <AsidePanelBodyHOC
                    boardId={boardId}
                    activeTabIndex={activeHook}
                    children={children}
                    tabs={tabs}
                    panelActions={panelActions}
                    isAssignedToMe={isAssignedToMe}
                    cardId={cardId}
                />
                {!!footer &&
                    <AsidePanelFooter footer={footer} />
                }
                {!isMobile &&
                    <Draggable
                        key={resizerKey}
                        axis={'y'}
                        bounds={{
                            left: resizer.current.width - resizer.current.maxWidth,
                            right: resizer.current.width - resizer.current.minWidth,
                            top: 0,
                            bottom: 0,
                        }}
                        onStart={handleResizerDragStart}
                        onDrag={handleResizerDrag}
                        onStop={handleResizerDragStop}
                    >
                        <div className={classNamePanelResizer} ref={resizerRef}>
                            <ResizerSvg />
                        </div>
                    </Draggable>
                }
                {!isLeft &&
                    <div id={ID_ASIDE_PANEL_ACTIONS_PORTAL_MOBILE}>
                        <div id={ID_ASIDE_PANEL_DATEPICKER_PORTAL} />
                    </div>
                }
            </div>
            <AsidePanelPrintStyle/>
        </AsidePanelContext.Provider>
    );

    return <MoveFocusInside>{content}</MoveFocusInside>;
};
