import * as React from 'react';
import { SettingsSection } from '../../../../../../base/components/SettingsSection/SettingsSection';
import { AsidePanelContext } from '../../../../../asidePanel/components/AsidePanel/constants';
import { CLASS_BOARD_DETAILS_SECTION } from '../../../../constants';
import { BOARD_SECTION_PREFIXES } from '../../constants';
import { BoardCardNumberHOC } from '../../hocs/BoardCardNumberHOC/BoardCardNumberHOC';
import './_PrefixesSection.scss';
import { IPrefixesSectionProps } from './types';
import { PrefixesTogglerHOC } from '../../hocs/PrefixesTogglerHOC/PrefixesTogglerHOC';
import { ActionModal } from '../../../../../../base/components/ActionModal/ActionModal';
import {
    PREFIXES_SECTION_MODAL__REMOVE_TEXT,
    PREFIXES_SECTION_MODAL__REMOVE_TEXT_LOADING,
    PREFIXES_SECTION_MODAL_DELETE_CANCEL,
    PREFIXES_SECTION_MODAL_DELETE_REMOVE,
    PREFIXES_SECTION_MODAL_DELETE_TEXT,
    PREFIXES_SECTION_MODAL_DELETE_TITLE,
    PREFIXES_SECTION_MODAL_NO,
    PREFIXES_SECTION_MODAL_REMOVE_TITLE,
    PREFIXES_SECTION_MODAL_REMOVE_TITLE_LOADING,
    PREFIXES_SECTION_MODAL_YES
} from './constants';
import { root } from '../../../../../../../../store/constants';
import { CARD_NUMBER_MIN_LOADING_CARDS_COUNT } from '../BoardCardNumber/constants';
import {
    CARD_AGING_MODAL_CANCEL,
    CARD_AGING_MODAL_REMOVE,
    CARD_AGING_MODAL_TEXT,
    CARD_AGING_MODAL_TITLE
} from '../../../CardAging/constants';
import {
    BasicUpgradeButtonHOC
} from '../../../../../../base/components/BasicUpgradeButton/hocs/BasicUpgradeButtonHOC/BasicUpgradeButtonHOC';

export function PrefixesSection ({
    isBasicFeature,
    isCardsWithPrefixes,
    isEnabled,
    onChange,
    onCloseDeleteModal,
    onRemove,
    onShowSnackbar
}: IPrefixesSectionProps) {
    const { boardId } = React.useContext(AsidePanelContext);
    const className = CLASS_BOARD_DETAILS_SECTION + ' ' + CLASS_BOARD_DETAILS_SECTION + '--prefixes';

    const [isShow, setShow] = React.useState(null);
    const [isShowDeleteModal, setShowDeleteModal] = React.useState(null);
    const [isLoading, setLoading] = React.useState(null);
    const [activeEl, setActiveEl] = React.useState(null);

    const onClose = () => {
        setShow(false);
        setLoading(false);
        if (activeEl) activeEl.focus();
    };

    const onCloseModalDelete = () => {
        setShowDeleteModal(false);
        onCloseDeleteModal();
    };

    const onRemoveCurrent = () => {
        setLoading(true);
        onChange().then(result => {
            if (!result) setShowDeleteModal(true);
        });
        onRemove().then(() => {
            onShowSnackbar();
        }).finally(onClose);
    };

    const onToggle = () => {
        if (isEnabled && isCardsWithPrefixes) {
            setActiveEl(document.activeElement);
            setShow(true);
        } else {
            onChange().then(result => {
                if (!result) setShowDeleteModal(true);
            });
        }
    };

    const [progress, setProgress] = React.useState(null);
    const timerRef = React.useRef(null);
    React.useEffect(() => {
        if (timerRef.current) clearInterval(timerRef.current);
        if (isLoading) {
            if (root.App.controller.boardCardPrefixInfo.totalCardsCount < CARD_NUMBER_MIN_LOADING_CARDS_COUNT) return; // нет смысла показывать, если мало карт
            timerRef.current = setInterval(() => {
                setProgress(root.App.controller.boardCardPrefixInfo.getAddedCardsPercentage());
            }, 50);
        } else {
            setProgress(null);
        }
    }, [isLoading]);

    return (
        <SettingsSection
            className={className}
            isBasicFeature={isBasicFeature}
            title={BOARD_SECTION_PREFIXES}
            titleButton={<PrefixesTogglerHOC boardId={boardId} onChange={onToggle} />}
        >
            <BoardCardNumberHOC
                boardId={boardId}
            />
            <ActionModal
                isShow={isShow}
                actions={[
                    {
                        variant: 'primary',
                        onClick: () => {
                            onClose();
                            onChange().then(result => {
                                if (!result) setShowDeleteModal(true);
                            });
                        },
                        children: PREFIXES_SECTION_MODAL_NO
                    },
                    {
                        variant: 'secondary',
                        onClick: onRemoveCurrent,
                        children: PREFIXES_SECTION_MODAL_YES
                    }
                ]}
                isLoading={isLoading}
                progress={progress}
                text={isLoading ? PREFIXES_SECTION_MODAL__REMOVE_TEXT_LOADING : PREFIXES_SECTION_MODAL__REMOVE_TEXT}
                title={isLoading ? PREFIXES_SECTION_MODAL_REMOVE_TITLE_LOADING : PREFIXES_SECTION_MODAL_REMOVE_TITLE}
                onClose={onClose}
            />
            <ActionModal
                isShow={isShowDeleteModal}
                actions={[
                    {
                        variant: 'primary',
                        onClick: onCloseModalDelete,
                        children: PREFIXES_SECTION_MODAL_DELETE_CANCEL
                    },
                    {
                        variant: 'secondary',
                        onClick: () => {
                            onCloseModalDelete();
                            onChange();
                        },
                        children: PREFIXES_SECTION_MODAL_DELETE_REMOVE
                    },
                ]}
                footer={<BasicUpgradeButtonHOC onClick={onCloseModalDelete} />}
                text={PREFIXES_SECTION_MODAL_DELETE_TEXT}
                title={PREFIXES_SECTION_MODAL_DELETE_TITLE}
                onClose={onCloseModalDelete}
            />
        </SettingsSection>
    );
};
