import { segmentTrackAction, SegmentNotificationsEvent } from '../../../../../../../../middlewares/segment';
import { TSegmentTrackPayload } from '../../../../../../../../middlewares/segment/trackEntities/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { NOTIFY_SELECT_GROUP } from '../../../constants';

export const onGroupSelect = (
    group: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        const event: Record<string, TSegmentTrackPayload> = {
            [NOTIFY_SELECT_GROUP.BOARD]: SegmentNotificationsEvent.FORCE_GROUP_BOARD_CHOSEN,
            [NOTIFY_SELECT_GROUP.OTHER]: SegmentNotificationsEvent.FORCE_GROUP_OTHER_CHOSEN,
        }
        if (event[group]) {
            dispatch(segmentTrackAction(event[group]));
        }
    };
    return action;
};
