import * as React from 'react';
import { BOARD_DETAILS_ONLY_OWNER, CLASS_BOARD_DETAILS } from '../../../../constants';
import './_LinksToCards.scss';
import { LINKS_TO_CARDS_DESC, LINKS_TO_CARDS_INSTRUCTION_URL, LINKS_TO_CARDS_MORE_INSTRUCTIONS } from '../../constants';
import { ILinksToCardsProps } from './types';

export function LinksToCards ({
    isDisabled,
    onMoreLinkClick
 }: ILinksToCardsProps) {
    const classText = CLASS_BOARD_DETAILS + '__links-text';

    return (
        <div className={classText}>
            {LINKS_TO_CARDS_DESC} <a href={LINKS_TO_CARDS_INSTRUCTION_URL} onClick={onMoreLinkClick} target="_blank">{LINKS_TO_CARDS_MORE_INSTRUCTIONS}</a>
            {isDisabled && <p>{BOARD_DETAILS_ONLY_OWNER}</p>}
        </div>
    );
};
