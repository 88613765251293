import { Dispatch, PromiseAction, ThunkAction } from '../../../../../types/actions';
import { IGetState, TBoardId, TCardId, TColorId } from '../../../../../types/types';
import { deleteAllDependencies } from '../../dependence/deleteAllDependencies';
import { getCard } from '../../../../../store/model/selectors/getCard';
import { cardUpdateRelatedCards } from '../../relatedCards/cardUpdateRelatedCards';
import { cardSetListBatch } from '../cardSetList';
import { cardDeleteEpic, cardDeleteEpicBatch } from '../cardDeleteEpic';
import { IBoard } from '../../../../../store/model/board/types';
import { sendRealTimeRestCard } from '../../../../../view/react_components/base/helpers/realTimeHelperTS';
import { getBoardByCardId } from '../../../../../store/model/selectors/getBoardByCardId';
import { getList } from '../../../../../store/model/list/selectors/getList';
import { IList } from '../../../../../store/model/list/types';
import { getCardSubcards } from '../../../../../store/model/selectors/getCardSubcards';
import { IColor, TStatus } from '../../../../../types/model';
import { getCardRelatedCardsActive } from '../../../../../store/model/selectors/getCardRelatedCardsActive';
import { cardSaveColorTagsBatch } from '../cardSaveColorTags';
import { sendUserProductivityStatsTS } from '../../../../../helper/comet/stat_helper_ts';
import {
    EUserProductivityEventStatus
} from '../../../../../view/react_components/reports/UserProductivity/UserProductivityReport/rest/types';
import { getRest } from '../api/getRest';
import { IRestCard } from '../../../../../types/rest/IRestCard';
import { cardToggleGanttBatch } from '../cardToggleGantt';
import { cardSetAssigneesBatch } from '../cardSetAssignees';
import { cardAssigneesActionSetAction } from '../../../../../store/model/actions/cardAssigneesActionSetAction';
import { cardAssigneeUpdateAction } from '../../../../../store/model/cardAssignees/actions/cardAssigneeUpdateAction';
import { getAuthUser } from '../../../../../store/model/authUser/selectors/getAuthUser';
import { sendCardMovedNotification } from './sendCardMovedNotification';
import { getCardAssignee } from '../../../../../store/model/cardAssignees/selectors/getCardAssignee';
import { sendCardStat } from './sendCardStat';
import { cardAddMetaMoveToBoardBatch } from '../cardAddMetaMoveToBoard';
import { cardPatchRestBatch } from '../api/helper/cardPatchRestBatch';
import { ICard } from '../../../../../store/model/card/types';
import { getBoardCardCustomProperties } from '../../../../../store/model/board/selectors/getBoardCardCustomProperties';
import {
    ECustomPropertyType,
    IBoardCardCustomOption,
    IBoardCardCustomProperties,
    IBoardCardCustomProperty,
    ICardCustomProperties,
    ICardCustomProperty,
    ICardCustomPropertyData
} from '../../../../../store/customProperties/types';
import { getCardCustomProperties } from '../../../../../store/model/selectors/getCardCustomProperties';
import { cardUpdateMetaBatch } from '../cardUpdateMeta';
import { ICardBatch } from '../api/types';
import { getBoard } from '../../../../../store/model/selectors/getBoardById';

export const cardMove = (
    cardId: TCardId,
    destList: IList,
    destBoard: IBoard,
    orderNumber: number,
    showMovedNotification: boolean,
    saveSubcardsIds: number[] = null,
    saveEpic: boolean = false,
    saveCustomProperties: boolean = false,
    customPropertiesToAdd: IBoardCardCustomProperties
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const sourceBoard = getBoardByCardId(getState(), cardId);
        const sourceListId = getCard(getState(), cardId).listId;
        const sourceList = getList(getState(), sourceListId);
        return dispatch(deleteAllDependencies(cardId))
            .then(() => {
                return updateSubcards(cardId, dispatch, getState, saveSubcardsIds);
            })
            .then(() => {
                const card = getCard(getState(), cardId);
                sendUserProductivityStatsTS(destBoard, card, destList, EUserProductivityEventStatus.CARD_MOVED_OUT);
            })
            .then(() => {
                return updateCard(cardId, sourceBoard, destBoard, destList, orderNumber, saveEpic, saveCustomProperties, customPropertiesToAdd, dispatch, getState)
            })
            .then(() => { //IRestCard
                return updateRelatedCards(cardId, dispatch, getState);
            })
            .then(() => {
                return dispatch(getRest(cardId)).then((card: IRestCard) => {
                    sendUserProductivityStatsTS(destBoard, card, destList, EUserProductivityEventStatus.CARD_MOVED_HERE);

                    const cardToRealtime: IRestCard = {
                        ...card,
                        listId: destList.id
                    }
                    sendRealTimeRestCard(sourceBoard.id, sourceBoard.cometToken, cardToRealtime);
                    sendRealTimeRestCard(destBoard.id, destBoard.cometToken, cardToRealtime);

                    if (showMovedNotification) {
                        sendCardMovedNotification(card, destBoard, destList, sourceList, getAuthUser(getState()));
                    }

                    const cardAssignee = getCardAssignee(getState(), cardId);
                    if (cardAssignee) {
                        dispatch(cardAssigneesActionSetAction(cardAssigneeUpdateAction(cardId, {
                            listId: destList.id,
                            listName: destList.name,
                            dashboardId: destBoard.id,
                            boardName: destBoard.name
                        })));
                    }

                    return dispatch(sendCardStat(cardId, destList.id, sourceList, destBoard));
                });
            })
            .catch((e: any) => {
                console.error(e);
            });
    };
    return action;
};

const updateCard = (
    cardId: TCardId,
    sourceBoard: IBoard,
    destBoard: IBoard,
    destList: IList,
    orderNumber: number,
    saveEpic: boolean,
    saveCustomProperties: boolean,
    customPropertiesToAdd: IBoardCardCustomProperties,
    dispatch: Dispatch,
    getState: IGetState
): Promise<ICard> => {
    const card = getCard(getState(), cardId);
    const cardDestColorIds = getCardDestColorIds(card, sourceBoard, destBoard);
    const assignees = card.assignees.map((assignee) => {
        return {
            ...assignee,
            roleIds: []
        };
    });
    const cardBatch = cardSetAssigneesBatch(dispatch, cardId, assignees);
    if (!saveEpic) {
        cardDeleteEpicBatch(getState, dispatch, cardId, cardBatch);
    }
    cardSaveColorTagsBatch(getState, cardId, cardDestColorIds, cardBatch);
    cardSetListBatch(getState, cardId, destList.id, orderNumber, cardBatch);
    if (!card.ganttVisibility && destBoard.showOnGantt) {
        cardToggleGanttBatch(getState, cardId, cardBatch);
    }
    cardAddMetaMoveToBoardBatch(getState, cardId, sourceBoard.id, cardBatch);
    updateCustomProperties(card, getState, sourceBoard.id, destBoard, cardBatch, saveCustomProperties, customPropertiesToAdd);

    return cardPatchRestBatch(cardId, dispatch, cardBatch, false, destBoard.cardNumberEnabled);
};

const updateSubcards = (
    cardId: TCardId,
    dispatch: Dispatch,
    getState: IGetState,
    saveSubcardsIds: number[] = null
) => {
    const subcards = getCardSubcards(getState(), cardId);
    const deleteEpicPromises: PromiseAction<any>[] = [];
    subcards.forEach((card) => {
        if (!saveSubcardsIds || !saveSubcardsIds.includes(card.id)) {
            deleteEpicPromises.push(dispatch(cardDeleteEpic(card.id)));
        }
    });
    return Promise.all(deleteEpicPromises);
};

const updateRelatedCards = (
    cardId: TCardId,
    dispatch: Dispatch,
    getState: IGetState
) => {
    let relations = getCardRelatedCardsActive(getState(), cardId);
    if (!relations || relations.length === 0) return Promise.resolve();

    const inactiveRelations = relations.map(rel => {
        return {
            ...rel,
            status: TStatus.STATUS_DELETED
        };
    });
    const newRelations = relations.map((rel) => {
        return {
            ...rel,
            id: null
        };
    });
    return dispatch(cardUpdateRelatedCards(cardId, [...inactiveRelations, ...newRelations]));
};

const updateCustomProperties = (
    card: ICard,
    getState: IGetState,
    sourceBoardId: TBoardId,
    destBoard: IBoard,
    cardBatch: ICardBatch = {card: {}, callbacks: []},
    saveNewCustomProperties: boolean,
    customPropertiesToAdd: IBoardCardCustomProperties
) => {
    const state = getState();
    const srcBoardProps: IBoardCardCustomProperties = getBoardCardCustomProperties(state, sourceBoardId);
    const cardProps: ICardCustomProperties = getCardCustomProperties(state, card.id);
    const cardPropsDataList: ICardCustomPropertyData[] = [];
    for (const propId in cardProps) {
        if (srcBoardProps[propId]) {
            cardPropsDataList.push({
                name: srcBoardProps[propId].name,
                type: srcBoardProps[propId].type,
                value: cardProps[propId].value,
                pinned: cardProps[propId].pinned
            })
        }
    }

    const saveCustomProperties: ICardCustomProperties = {};
    const destBoardProps: IBoardCardCustomProperties = destBoard && destBoard.meta && destBoard.meta.cardCustomProperties;
    if (destBoardProps) {
        const srcBoardPropList = Object.values(srcBoardProps);
        const destBoardPropList = Object.values(destBoardProps);
        cardPropsDataList.forEach(cardPropData => {
            const srcBoardProp = srcBoardPropList.find(boardProp => {
                return boardProp.name.toLocaleLowerCase() === cardPropData.name.toLocaleLowerCase() &&
                    boardProp.type === cardPropData.type
            });
            const destBoardProp = destBoardPropList.find(boardProp => {
                return boardProp.name.toLocaleLowerCase() === cardPropData.name.toLocaleLowerCase() &&
                    boardProp.type === cardPropData.type
            });
            if (srcBoardProp && destBoardProp) {
                const saveCustProp: ICardCustomProperty = prepareCardCustomProperty(cardPropData, srcBoardProp, destBoardProp);
                if (saveCustProp) {
                    saveCustomProperties[destBoardProp.id] = saveCustProp;
                }
            }
        })
    }
    if (saveNewCustomProperties) {
        for (const id in cardProps) {
            let isSimilarProperty = false;
            const boardCustomProperty = srcBoardProps[id];
            for (let destId in destBoardProps) {
                const destBoardCustomProperty = destBoardProps[destId];
                if (
                    destBoardCustomProperty && boardCustomProperty &&
                    destBoardCustomProperty.name === boardCustomProperty.name &&
                    destBoardCustomProperty.type === boardCustomProperty.type
                ) {
                    isSimilarProperty = true;
                    break;
                }
            }
            if (!saveCustomProperties[id] && !isSimilarProperty) {
                saveCustomProperties[id] = cardProps[id];
                customPropertiesToAdd[id] = srcBoardProps[id];
            }
        }
    }

    cardUpdateMetaBatch({
        customProperties: saveCustomProperties
    }, cardBatch);
}

const prepareCardCustomProperty = (
    cardPropData: ICardCustomPropertyData,
    srcBoardProp: IBoardCardCustomProperty,
    destBoardProp: IBoardCardCustomProperty
): ICardCustomProperty => {
    if (cardPropData.type === ECustomPropertyType.SELECT) {
        const srcBoardOption: IBoardCardCustomOption = srcBoardProp.options.find(opt => opt.id === cardPropData.value);
        if (srcBoardOption) {
            const destBoardOption: IBoardCardCustomOption =
                destBoardProp.options.find(opt => opt.value.toLocaleLowerCase() === srcBoardOption.value.toLocaleLowerCase());
            if (destBoardOption) {
                return {
                    id: destBoardProp.id,
                    value: destBoardOption.id,
                    pinned: cardPropData.pinned
                }
            } else {
                return null;
            }
        }
    }
    return {
        id: destBoardProp.id,
        value: cardPropData.value,
        pinned: cardPropData.pinned
    }
}

const getCardDestColorIds = (
    card: ICard,
    sourceBoard: IBoard,
    destBoard: IBoard,
) => {
    const destCardColorIds: TColorId[] = [];
    const destBoardColors: IColor[] = (destBoard.colors || [])
        .filter(color => color.status === TStatus.STATUS_ACTIVE);
    (card.colorIds || []).forEach(cardColorId => {
        const srcBoardColor = (sourceBoard.colors || []).find(color => color.id === cardColorId);
        if (srcBoardColor) {
            const destBoardColor = destBoardColors.find(color => {
                return color.color === srcBoardColor.color && color.name === srcBoardColor.name;
            })
            if (destBoardColor) {
                destCardColorIds.push(destBoardColor.id);
            }
        }
    })
    return destCardColorIds;
}
