import { Dispatch, ThunkAction } from 'app/types/actions';
import { IGetState, TCardId } from 'app/types/types';
import { saveDescription } from '../effects/saveDescription';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';

export const onSave = (
    cardId: TCardId,
    description: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(saveDescription(cardId, description));
        dispatch(segmentTrackAction(SegmentCardEvent.SAVE_DESCRIPTION));
    };
    return action;
};
