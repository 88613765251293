import { IApplicationState } from '../../../../types/types';
import { ISubscription } from '../../../../view/react_components/subscriptionAdmin/store/subscription/types';
import { getAuthUser } from './getAuthUser';
import { getUserManagedSubscriptions } from './getUserManagedSubscriptions';

/**
 * Managed подписки, которые купил сам
 */

export const getUserOwnSubscriptions = (
    state: IApplicationState
): ISubscription[] => {
    const user = getAuthUser(state);
    const managedSubscriptions = getUserManagedSubscriptions(state);

    return managedSubscriptions.filter(subscription => subscription.authorId === user.id);
};
