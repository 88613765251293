import * as React from 'react';
import { Icon } from 'kui';
import { convertTimeNumberToString } from '../../../helpers/convertTimeNumberToString';
import { TabActivityContext } from '../../../../boardDetails/activity/TabActivity/components/TabActivity/constants';
import { ICardActivityEstimateProps } from './types';
import { TActivity } from '../../../../../../../store/activities/types';
import {
    ActivityItemBody
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemBody/ActivityItemBody';
import { ActivityHeader } from '../../../../boardDetails/activity/TabActivity/components/ActivityHeader/ActivityHeader';
import {
    ActivityItemName
} from '../../../../boardDetails/activity/TabActivity/components/ActivityItemName/ActivityItemName';
import { SMALL_ACTIVITY_MIN_ASIDE_WIDTH } from '../../../../boardDetails/activity/TabActivity/components/constants';

export function CardActivityEstimate({
    activity,
    asidePanelWidth,
    workHoursInDay
}: ICardActivityEstimateProps) {
    const { onOpenCard } = React.useContext(TabActivityContext);
    const { label, card } = activity as TActivity;
    const { oldValue, newValue } = activity;
    const content = <>
        {!!oldValue &&
            <ActivityItemName name={convertTimeNumberToString(Number(oldValue), false, workHoursInDay)} isRemove={!!newValue} />
        }
        {!!oldValue && !!newValue && <Icon xlink={'forward'} size={24}/>}
        {!!newValue &&
            <ActivityItemName name={convertTimeNumberToString(Number(newValue), false, workHoursInDay)} />
        }
    </>;
    return (
        <ActivityItemBody
            header={<ActivityHeader
                icon={'estimate'}
                label={label}
                content={asidePanelWidth <= SMALL_ACTIVITY_MIN_ASIDE_WIDTH && content}
            />}
            activity={activity}
            onClick={() => onOpenCard(card.id, activity.id, 'estimate')}
        >
            {asidePanelWidth > SMALL_ACTIVITY_MIN_ASIDE_WIDTH && content}
        </ActivityItemBody>
    );
};
