import { connect } from 'react-redux';
import { getNotificationSettingsByBoardId } from '../../../../../../../../store/model/selectors/getNotificationSettingsByBoardId';
import { IApplicationState } from '../../../../../../../../types/types';
import { EmailNotificationsPanelButton } from '../../components/EmailNotificationsPanelButton/EmailNotificationsPanelButton';
import { IEmailNotificationsPanelButtonEvents, IEmailNotificationsPanelButtonFields } from '../../components/EmailNotificationsPanelButton/types';
import { onClick } from './events/onClick';
import { IEmailNotificationsPanelButtonHOCProps } from './types';

const mapStateToProps = (
    state: IApplicationState,
    { boardId }: IEmailNotificationsPanelButtonHOCProps
): IEmailNotificationsPanelButtonFields => {
    const notification = getNotificationSettingsByBoardId(state, boardId);
    return {
        indicatorBoolean: notification && notification.enabled,
        tooltip: null,
    }
};

const mapDispatchToProps = (
    dispatch: any,
    {onClick: ownOnClick}: IEmailNotificationsPanelButtonHOCProps
): IEmailNotificationsPanelButtonEvents => {
    return {
        onClick: () => dispatch(onClick(ownOnClick))
    }
};

export const EmailNotificationsPanelButtonHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(EmailNotificationsPanelButton);

EmailNotificationsPanelButtonHOC.displayName = 'EmailNotificationsPanelButtonHOC';
