import * as React from 'react';
import './_MyWorkTopBarSearch.scss';
import { IMyWorkTopBarSearchProps } from './types';
import { Input } from 'kui';

export function MyWorkTopBarSearch({
    searchQuery,
    isMyWorkCollapsed,
    onSetSearchQuery,
    setSearch
}: IMyWorkTopBarSearchProps) {
    const className = 'top-bar-my-work__search';
    const classSearch = className + '-input';

    const onChange = (e: React.SyntheticEvent) => {
        const { value } = e.target as HTMLInputElement;
        onSetSearchQuery(value);
    };

    return (
        <div
            className={`
                ${className}
                ${isMyWorkCollapsed ? className + '--compact' : ''}
            `}
        >
            <Input
                autosize={false}
                className={classSearch}
                variant={'search'}
                onChange={onChange}
                value={searchQuery}
                onFocus={() => setSearch(true)}
                onBlur={() => setSearch(false)}
                isClearable
            />
        </div>
    );
}
