import { ThunkAction } from '../../../../../../types/actions';
import { getAuthUser } from '../../../../../../store/model/authUser/selectors/getAuthUser';
import { getWithBoardRest } from '../../../../../../rest/effects/card/card/api/getWithBoardRest';
import { inWorkCardActionSet } from '../../../../../../store/model/actions/inWorkCardActionSet';
import { IRestCardWithBoard } from '../../../../../../types/rest/IRestCardWithBoard';
import { authUserSetInWorkCardId } from '../../../../../../rest/effects/authUser/authUserSetInWorkCardId';
import { FAKE_CARD_ID_FOR_WIDGET_PRESENTATION } from 'app/const';

export const onInWorkCardUpdate = (): ThunkAction => {
    return (
        dispatch,
        getState
    ) => {
        const state = getState();
        const { inWorkCardId } = getAuthUser(state);
        if (!inWorkCardId) {
            dispatch(inWorkCardActionSet(null));
            return;
        }

        if (inWorkCardId === FAKE_CARD_ID_FOR_WIDGET_PRESENTATION) {
            if (!document.querySelector('.updates-tips')) { // нет советов на экране
                dispatch(inWorkCardActionSet(null));
            }
            return;
        }

        return dispatch(getWithBoardRest(inWorkCardId))
            .then((card: IRestCardWithBoard) => {
                dispatch(inWorkCardActionSet(card));
                if(card === null) {
                    return dispatch(authUserSetInWorkCardId(0));
                }
            })
    }
};
