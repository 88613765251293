import { Dispatch, ThunkAction } from '../../../types/actions';
import { IGetState, TActivityId, TPermissionId } from '../../../types/types';
import { fetchHandler } from '../../../util/fetchHandler';
import { REST_ACTIVITIES, REST_ACTIVITY_NOTIFY } from '../../constants';
import { getRestHeadersPost } from '../../helpers/getRestHeadersHelper';
import { Util } from '../../../util/util';
import { TRestActivity } from '../../../types/rest/activity/IRestActivities';
import { updateActivities } from './updateActivities';

export const notifyPeople = (
    activityId: TActivityId,
    permissionIds: TPermissionId[],
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return fetchHandler<TRestActivity>(
            Util.getApiUrl(REST_ACTIVITIES + activityId + REST_ACTIVITY_NOTIFY), {
                ...getRestHeadersPost(),
                body: JSON.stringify(permissionIds)
            }
        )
        .then((response) => {
            dispatch(updateActivities([response]));
            return response;
        });
    };
    return action;
};
