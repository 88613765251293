import { getMessages } from 'app/store/constants';
import { EUpdateSlideType, EUpdateType } from '../../types';

export const UPDATES_PANEL_TITLE = getMessages().getText('updates_panel.title');
export const UPDATES_PANEL_BUTTON_FEEDBACK = getMessages().getText('updates_panel.button_feedback');
export const UPDATES_PANEL_TEXT = getMessages().getText('updates_panel.text');

export const UPDATES_PANEL_TYPES = {
    [EUpdateType.ADD]: getMessages().getText('updates_panel.type.added'),
    [EUpdateType.FIX]: getMessages().getText('updates_panel.type.fixed'),
    [EUpdateType.IMPROVE]: getMessages().getText('updates_panel.type.improved'),
}

export const UPDATES_PANEL_SLIDE_TYPES = {
    [EUpdateSlideType.IMAGE]: getMessages().getText('updates_panel.button_more.image'),
    [EUpdateSlideType.TIPS]: getMessages().getText('updates_panel.button_more.tips'),
    [EUpdateSlideType.VIDEO]: getMessages().getText('updates_panel.button_more.video'),
}
