import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { IGetState, TActivityId, TCardId } from '../../../../../../../../../types/types';
import { cardOpen } from '../../../../../../../base/effects/cardOpen';
import { CLASS_CARD_DETAILS_SECTION } from '../../../../../../cardDetails';
import {
    lastClickedActivityIdSet
} from '../../../../../../cardDetails/TabActivity/store/reducers/actions/lastClickedActivityIdSet';

export const onOpenCard = (
    cardId: TCardId,
    activityId: TActivityId,
    scrollSection: string,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch((lastClickedActivityIdSet(activityId)));
        dispatch(cardOpen(cardId));
        setTimeout(() => {
            if (!scrollSection) return;
            const section = document.querySelector(`.${CLASS_CARD_DETAILS_SECTION}--${scrollSection}`) as HTMLElement;
            if (section) {
                section.scrollIntoView();
            }
        }, 300);
    };
    return action;
};
