import { ENotificationPeriod, ENotificationScope } from '../../../../../../../../types/rest/IRestNotificationSettings';
import { SegmentBoardEvent } from '../../../../../../../../middlewares/segment';

export const periodEventsMap = {
    [ENotificationPeriod.SOON]: SegmentBoardEvent.CLICKED_AS_SOON_AS_POSSIBLE,
    [ENotificationPeriod.HOUR]: SegmentBoardEvent.CLICKED_ONCE_AN_HOUR,
    [ENotificationPeriod.DAY]: SegmentBoardEvent.CLICKED_ONCE_A_DAY
}

export const scopeEventsMap = {
    [ENotificationScope.ALL]: SegmentBoardEvent.CLICKED_EMAIL_ALL_CARDS,
    [ENotificationScope.ASSIGN_TO_ME]: SegmentBoardEvent.CLICKED_EMAIL_ASSIGNED_TO_ME,
    [ENotificationScope.WATCH]: SegmentBoardEvent.CLICKED_EMAIL_WATCHED_CARDS
}
