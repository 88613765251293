import { IChecklistItem } from '../../../../../../../../store/model/checklists/checklist/types';
import { ECardChecklistOrderMode } from '../../../../../../../../types/rest/IUserMeta';

export const sortChecklistItems = (
    checklistItems: IChecklistItem[],
    orderMode: ECardChecklistOrderMode,
): IChecklistItem[] => {
    checklistItems.sort((item1, item2) => (item1.orderNumber - item2.orderNumber));
    if (orderMode === ECardChecklistOrderMode.MANUAL) return checklistItems;

    return checklistItems.sort((item1, item2) => {
        let compareResult = Number(item2.checked) - Number(item1.checked);
        if (orderMode === ECardChecklistOrderMode.DONE_LAST) compareResult = -compareResult;
        return compareResult;
    });
}
