import { IInWorkCardActionSetAction, AT_IN_WORK_CARD_ACTION_SET } from './types';
import { ICardWithBoard } from '../card/types';

export const inWorkCardActionSet = (
    inWorkCard: ICardWithBoard
): IInWorkCardActionSetAction => {
    return {
        type: AT_IN_WORK_CARD_ACTION_SET,
        inWorkCard
    }
};
