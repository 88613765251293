import * as React from 'react';
import { INotifySectionProps } from './types';
import './_NotifySection.scss';
import { CLASS_CARD_DETAILS } from '../../../constants';
import { SettingsSection } from '../../../../../base/components/SettingsSection/SettingsSection';
import { getPopupAttributes } from '../../../../../base/components/SettingsSection/constants';
import { BUTTON_HOW_IT_WORKS, BUTTON_HOW_IT_WORKS_LINK, NOTIFY_BUTTON_KEYS, NOTIFY_PREVIOUS, NOTIFY_PREVIOUS_ACTIVITY, NOTIFY_REJECTED_TEXT, NOTIFY_REJECTED_TITLE, NOTIFY_SUCCESS_TITLE, NOTIFY_TITLE_ALREADY_NOTIFIED, NOTIFY_TITLE_NOONE_NOTIFIED, NOTIFY_TITLE_OTHER, NOTIFY_TITLE_OTHER_NOONE } from './constants';
import { NotifyUsers } from '../NotifyUsers/NotifyUsers';
import { IAssignee } from '../../../../../../../store/model/card/types/IAssignee';
import { NotifySearchSelect } from '../NotifySearchSelect/NotifySearchSelect';
import { TPermissionId } from '../../../../../../../types/types';
import { ISearchSelectOption } from '../../../../../base/components/SearchSelect/types';
import { v4 as uuidv4 } from 'uuid';
import { Button, Icon, LoaderBlock } from 'kui';
import { getMessages } from '../../../../../../../store/constants';
import { getAsidePanelTooltip } from '../../../../asidePanel/helpers/getAsidePanelTooltip';
import { ISharedUser } from '../../../../../../../types/rest/ISharedUser';

export function NotifySection ({
    availableElements,
    label,
    usersNotified,
    onClickActivity,
    onClickHow,
    onClose,
    onDidMount,
    onNotify,
    onUserSelect,
    onWarning,
    onSuccess,
}: INotifySectionProps) {
    const [isOpened, setOpened] = React.useState(false);
    const [isSync, setSync] = React.useState(false);
    const [isLoading, setLoading] = React.useState(true);
    const [response, setResponse] = React.useState(null);
    const [usersAvailable, setUsersAvailable] = React.useState(availableElements);
    const [usersNotifiedState, setUsersNotifiedState] = React.useState(usersNotified);
    let [usersSelected, _setUsersSelected] = React.useState<ISharedUser[]>([]);
    const [usersSelectedKey, setUsersSelectedKey] = React.useState(uuidv4());
    const [usersSearchKey, setUsersSearchKey] = React.useState(uuidv4());
    const sectionRef = React.useRef(null);
    const usersRef = React.useRef(null);

    const className = CLASS_CARD_DETAILS + '__notify-section';
    const classNameResponse = className + '--response';
    const classNameTitleButtons = CLASS_CARD_DETAILS + '__notify-section-title-buttons';
    const classNameSubtitle = CLASS_CARD_DETAILS + '__notify-section-subtitle';
    const classNameBlock = CLASS_CARD_DETAILS + '__notify-block';
    const classNameBlockSelected = classNameBlock + '--selected';
    const classNameBlockPrev = classNameBlock + '--prev';
    const classNameH2 = CLASS_CARD_DETAILS + '__notify-section-h2';
    const classNameOtherNoone = CLASS_CARD_DETAILS + '__notify-other-noone';
    const classNameButton = CLASS_CARD_DETAILS + '__notify-section-button';
    const classNameResponseIcon = CLASS_CARD_DETAILS + '__notify-section-response-icon';
    const classNameResponseIconError = classNameResponseIcon + '--error';
    const classNameResponseIconSuccess = classNameResponseIcon + '--success';

    function setUsersSelected(users: ISharedUser[]) {
        usersSelected = [...users];
        _setUsersSelected(usersSelected);
    }

    function findUser (
        elements: ISearchSelectOption[],
        permissionId: TPermissionId,
    ): ISearchSelectOption {
        for (let i=0; i < elements.length; i++) {
            if (elements[i].value === permissionId) return elements[i];
            if (elements[i].options) {
                const sub = findUser(elements[i].options, permissionId);
                if (sub) return sub;
            }
        }
        return null;
    }

    function onToggle (permissionId: TPermissionId) {
        const user = findUser(usersAvailable, permissionId);
        if (!user) return;

        user.active = !user.active;
        const users = [...usersSelected];
        const index = usersSelected.findIndex(item => item.permissionId === permissionId);
        if (index > -1) {
            users.splice(index, 1);
        } else {
            users.push({
                permissionId,
                fullName: user.text,
                photoUrl: user.img
            });
            onUserSelect(user.description);
        }
        setUsersSelected(users);
        setUsersAvailable([...usersAvailable])
    }

    function onDelete (permissionId: TPermissionId) {
        const user = findUser(usersAvailable, permissionId);
        if (!user) return;

        user.active = !user.active;
        const users = [...usersSelected];
        const index = usersSelected.findIndex(item => item.permissionId === permissionId);
        if (index > -1) {
            users.splice(index, 1);
        } else {
            users.push({
                permissionId,
                fullName: user.text,
                photoUrl: user.img
            });
        }
        setUsersSelected(users);
        setUsersSearchKey(uuidv4());
    }

    function onSearchOpen () {
        setOpened(true);
        setUsersSelectedKey(uuidv4()); // свернуть юзеров, чтоб не прыгало при изменении
        setTimeout(() => {
            usersRef.current.scrollIntoViewIfNeeded();
        }, 100);
    }

    function onSearchClose () {
        setOpened(false);
        setUsersSearchKey(uuidv4());
    }

    function onNotifyClick () {
        setSync(true);
        onNotify(usersSelected.map(user => user.permissionId))
            .then(activity => {
                const rejected: ISharedUser[] = [];
                const usersPushNotified = activity.usersPushNotified && activity.usersPushNotified.length ? activity.usersPushNotified : [];
                usersSelected.forEach(user => {
                    if (!usersPushNotified.find(item => item.permissionId === user.permissionId)) {
                        rejected.push(user);
                    }
                });
                setResponse(rejected);
                if (rejected.length) {
                    onWarning();
                } else {
                    onSuccess();
                }
                requestAnimationFrame(() => {
                    if (sectionRef.current) sectionRef.current.scrollIntoViewIfNeeded();
                });
            })
            .finally(() => setSync(false));
    }

    React.useEffect(() => {
        setUsersAvailable(availableElements);
        setUsersSearchKey(uuidv4());
    }, [availableElements]);

    React.useEffect(() => {
        setUsersNotifiedState(usersNotified);
        setUsersSearchKey(uuidv4());
    }, [usersNotified]);

    React.useEffect(() => {
        if (onDidMount) {
            onDidMount()
                .finally(() => setLoading(false));
        } else {
            setLoading(false);
        }
    }, []);

    if (isLoading) return <LoaderBlock />;

    if (!!response) return (response.length
        ? <SettingsSection
            className={`${className} ${classNameResponse}`}
            title={ NOTIFY_REJECTED_TITLE }
            ref={sectionRef}
            onAddClosed={ onClose }
            {...getPopupAttributes()}
        >
            <div className={ classNameSubtitle }>
                {NOTIFY_REJECTED_TEXT}
            </div>
            {!!response.length &&
                <div className={ classNameBlock }>
                    <NotifyUsers
                        users={response}
                    />
                </div>
            }
            <Icon
                className={`${classNameResponseIcon} ${classNameResponseIconError}`}
                xlink={'error'}
                size={24}
            />
        </SettingsSection>
        : <SettingsSection
            className={`${className} ${classNameResponse}`}
            title={ NOTIFY_SUCCESS_TITLE }
            ref={sectionRef}
            onAddClosed={ onClose }
            {...getPopupAttributes()}
        >
            {!!usersNotifiedState.length &&
                <div className={ classNameBlock }>
                    <NotifyUsers
                        users={usersNotifiedState}
                    />
                </div>
            }
            <Icon
                className={`${classNameResponseIcon} ${classNameResponseIconSuccess}`}
                xlink={'progress'}
                size={16}
            />
        </SettingsSection>
    );

    return <>
        <SettingsSection
            className={ className }
            title={ label }
            titleButton={
                <div className={classNameTitleButtons}>
                    <Button
                        href={BUTTON_HOW_IT_WORKS_LINK}
                        target="_blank"
                        tooltip={getAsidePanelTooltip({ value: BUTTON_HOW_IT_WORKS })}
                        variant={'icon'}
                        onClick={onClickHow}
                    >
                        <Icon size={16} xlink={'help'} />
                    </Button>
                </div>
            }
            onAddClosed={ onClose }
            {...getPopupAttributes()}
        >
            {usersNotifiedState.length
                ? <div className={ classNameBlock }>
                    <div className={ classNameH2 }>
                        {NOTIFY_TITLE_ALREADY_NOTIFIED}
                    </div>
                    <NotifyUsers
                        users={usersNotifiedState}
                    />
                </div>
                : <div className={ classNameSubtitle }>
                    {NOTIFY_TITLE_NOONE_NOTIFIED}
                </div>
            }

            {!!usersAvailable.length && <>
                <div className={`${classNameBlock} ${classNameBlockSelected}`}
                    ref={usersRef}
                >
                    <div className={ classNameH2 }>
                        {NOTIFY_TITLE_OTHER}
                    </div>
                    {!!usersSelected.length
                        ? <NotifyUsers
                            key={usersSelectedKey}
                            users={usersSelected}
                            onDelete={ onDelete }
                        />
                        : isOpened
                            ? <div className={ classNameOtherNoone }>
                                {NOTIFY_TITLE_OTHER_NOONE}
                            </div>
                            : null
                    }
                    <NotifySearchSelect
                        availableElements={usersAvailable}
                        key={usersSearchKey}
                        onClosed={onSearchClose}
                        onOpened={onSearchOpen}
                        onSelect={onToggle}
                    />
                    <Button
                        className={classNameButton}
                        disabled={!usersSelected.length}
                        size={'large'}
                        onClick={onNotifyClick}
                    >
                        {getMessages().getPluralByKeys(
                            usersSelected.length,
                            NOTIFY_BUTTON_KEYS,
                            null,
                            usersSelected.length || ''
                        )}
                    </Button>
                </div>

                {!!onClickActivity &&
                    <div className={`
                        ${classNameBlock}
                        ${classNameBlockPrev}
                    `}>
                        {NOTIFY_PREVIOUS} <a
                            onClick={onClickActivity}
                        >
                            {NOTIFY_PREVIOUS_ACTIVITY}
                        </a>
                    </div>
                }
            </>}
        </SettingsSection>
        {isSync && <LoaderBlock />}
    </>
};
