import { segmentActionsRouter } from './segmentActionsRouter';
import { TSegmentAction } from '../types';
import { SEGMENT_ACTION, SEGMENT_TRACK } from '../constants';
import { trackRouter } from '../methods/track/trackRouter';
import { isCookiePerformanceAllowed } from '../../../view/react_components/dialogs/cookies';

/**
 event lifecycle
 segmentMiddleware -> segmentActionsRouter -> action -> entityRouter -> entityAction
 */

export const segmentMiddleware = () => (next: any) => (action: TSegmentAction) => {
    if (action.type === SEGMENT_ACTION) {
        const cookieAllowed = isCookiePerformanceAllowed();
        if (cookieAllowed) {
            segmentActionsRouter(action);
        } else if(action.case === SEGMENT_TRACK) {
            trackRouter(action.track, action.option, action.additionalOptions);
        }
    }
    return next(action);
};
