import { ICards } from '../store/model/cards/types';
import { ICard } from '../store/model/card/types';

export class BoardCardPrefixInfoHelper {
    totalCardsCount?: number;
    addedCardsCount: number = 0;
    cardsForRollback: ICards = {};
    cardPrefixRemoveCount: number = 0;

    addCardForRollback(card: ICard) {
        this.cardsForRollback[card.id] = card;
    }

    getCardsForRollback() {
        return this.cardsForRollback;
    }

    increaseCardPrefixRemoveCount() {
        this.cardPrefixRemoveCount += 1;
    }

    getCardPrefixRemoveCount() {
        return this.cardPrefixRemoveCount;
    }

    getAddedCardsPercentage() {
        return Math.round(this.addedCardsCount / this.totalCardsCount * 100);
    }

    resetBoarCardPrefixInfo() {
        this.totalCardsCount = null;
        this.addedCardsCount = 0;
        this.cardsForRollback = {};
    }
}
