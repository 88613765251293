import { Dispatch, ThunkAction } from '../../../types/actions';
import { IGetState } from '../../../types/types';
import { getAuthUser } from '../../../store/model/authUser/selectors/getAuthUser';
import { IAuthUser } from '../../../store/model/authUser/types';
import { authUserPatch } from './api/helper/authUserPatch';
import { getAuthUserQuickStartGuides } from '../../../store/model/authUser/selectors/getAuthUserQuickStartGuides';
import { IQuickStartGuide } from '../../../types/rest/IUserMeta';
import store, { dispatch } from '../../../store/configureStore';

export const authUserSetQuickStartGuides = (
    guide: IQuickStartGuide,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const authUser = getAuthUser(state);
        const quickStartGuides = { ...getAuthUserQuickStartGuides(state) };
        const userPatch: IAuthUser = {
            id: authUser.id,
            meta: {
                quickStartGuides: {
                    ...quickStartGuides,
                    [guide.key]: { ...guide }
                }
            }
        };
        return dispatch(authUserPatch(userPatch));
    };
    return action;
};
