import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../../../../types/types';
import { waitUntilCardIsOpenedAndLoaded } from '../../../../../../helpers/waitUntilCardIsOpenedAndLoaded';

export const onOpenLists = (
    cardId: TCardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const openCardLists = () => {
            const listsDropdown = document.querySelector('.card-details__list-select') as HTMLElement;
            if (listsDropdown) {
                listsDropdown.click();
                const input = listsDropdown.querySelector('.kui-input__item') as HTMLElement;
                if (input) input.focus();
            }
        };

        waitUntilCardIsOpenedAndLoaded(
            getState,
            cardId,
            openCardLists
        );
    };
    return action;
}
