import * as React from 'react';
import { CLASS_ACTIVITY_ITEM } from '../constants';
import './_ActivityItemMore.scss';
import { IActivityItemMoreProps } from './types';
import { Tooltip } from 'kui';

export function ActivityItemMore({
    count,
    tooltip
}: IActivityItemMoreProps) {
    const className = CLASS_ACTIVITY_ITEM + '__more';
    return (
        <Tooltip value={tooltip}>
            <div className={className}>+{count}</div>
        </Tooltip>
    );
};
