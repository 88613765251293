import { createRoot } from 'react-dom/client';
import {Provider} from "react-redux";
import React from "react";
import { LazyWrapper } from '../react_components/lazy/lazy_wrapper';
import { dispatch } from 'app/store/configureStore';

App.Views.OpenBoardsDialogView = App.Views.BaseView.extend({
    initialize: function (options) {
        this.root = createRoot(this.el);
        this.store = options.store;
    },

    initOpenBoardsStore: function() {
        return import(/* webpackChunkName: "openBoards" */ 'app/view/react_components/dialogs/openBoards/store/openBoardsReducer')
        .then((module) => {
            store.reducerManager.add('openBoardsDialog', module.openBoardsDialogReducer);
            dispatch({type: 'async-init'}); // fake action to init new reducers
        })
        .catch(e => console.error(e))
    },

    render: function (callback = null) {
        const ImportElement = React.lazy(() =>
            this.initOpenBoardsStore()
                .then(() => import(/* webpackChunkName: "openBoards" */ 'app/view/react_components/dialogs/openBoards/hocs/OpenBoardsHOC/OpenBoardsHOC'))
                .then(module => {
                    if (callback) callback();
                    return {default: module.OpenBoardsHOC}
                })
                .catch(e => console.error(e))
        );
        this.root.render(
            <LazyWrapper>
                <Provider store={store}>
                    <ImportElement />
                </Provider>
            </LazyWrapper>
        );
        return this;
    },

    show: function (tabKey = null) {
        import(/* webpackChunkName: "openBoards" */ 'app/view/react_components/dialogs/openBoards/effects/openDialog')
            .then(module => dispatch(module.openBoardsDialog(tabKey)))
            .catch(e => console.error(e))
    },

    remove: function () {
        this.root.unmount();
        Backbone.View.prototype.remove.call(this);
    }

});

export default App.Views.OpenBoardsDialogView;
