import { createRoot } from 'react-dom/client';
import {Provider} from "react-redux";
import * as React from "react";
import {dispatch} from "../../store/configureStore";
import {segmentTrackAction, SegmentPricingEvent} from "../../middlewares/segment";
import { LazyWrapper } from '../react_components/lazy/lazy_wrapper';
import { authUserSetPricingPoll } from "../../rest/effects/authUser/authUserSetPricingPoll";

App.Views.PriceDialogControllerView = App.Views.BaseView.extend({
    initialize: function(options){
        this.root = createRoot(this.el);
        this.store = options.store;
        this.eventOptions = options.eventOptions;
        this.noNavigateOnBack = options.noNavigateOnBack;
    },

    render: function () {
        dispatch(segmentTrackAction(SegmentPricingEvent.OPENED, this.eventOptions));

        const ImportElement = React.lazy(() =>
            import(/* webpackChunkName: "pricing" */ '../react_components/dialogs/price_page/hocs/PricingHOC/PricingHOC')
                .then(module => ({default: module.PricingHOC}))
                .catch(e => console.error(e))
        );

        this.root.render(
            <LazyWrapper>
                <Provider store={this.store}>
                    <ImportElement
                        noNavigateOnBack={this.noNavigateOnBack}
                    />
                </Provider>
            </LazyWrapper>
        );

        return this;

    },

    remove: function () {
        dispatch(authUserSetPricingPoll({ isTriggered: true, isSkipped: false, lastTimeShowed: 0 }));
        this.root.unmount();
        Backbone.View.prototype.remove.call(this);
    }
});
export default App.Views.PriceDialogControllerView;
