import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState, TCardId } from '../../../../types/types';
import { IPinnedSections } from '../../../../store/model/card/types/ICardMeta';
import { cardUpdateMeta } from './cardUpdateMeta';

export const cardSetPinnedSections = (
    cardId: TCardId,
    pinnedSections: IPinnedSections,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(cardUpdateMeta(cardId, {
            pinnedSections
        }));
    };
    return action;
};
