import { getCardsFlat } from './getCardsFlat';
import { TClickManagerCardsSelector } from '../../clickManager/store/types';

export const getCardIdsForClickManager: TClickManagerCardsSelector = (
    state,
    boardId
) => {
    const cardIds = getCardsFlat(state, boardId);
    return Object.keys(cardIds).map(Number);
};
