import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../../types/types';
import { IBoardLogoHOCProps } from './types';
import { IBoardLogoEvents, IBoardLogoFields } from '../../components/BoardLogo/types';
import { onFileSelect } from './events/onFileSelect';
import { onDelete } from './events/onDelete';
import { BoardLogo } from '../../components/BoardLogo/BoardLogo';
import { onClickDisallow } from './events/onClickDisallow';
import { getSectionType } from '../../../../selectors/getSectionType';
import { getBoard } from '../../../../../../../../store/model/selectors/getBoardById';
import {
    getShowProFeaturesTrial
} from '../../../../../../../../store/model/authUser/selectors/getShowProFeaturesTrial';
import { getBoardLogoColor } from '../../../../../../../../store/model/selectors/getBoardLogoColor';
import { getBoardLogoIcon } from '../../../../../../../../store/model/selectors/getBoardLogoIcon';
import { onLogoChange } from './events/onLogoChange';
import { onToggleShowIconAndColorLogo } from './events/onToggleShowIconAndColorLogo';
import { onDidUnmount } from './events/onDidUnmount';

const mapStateToProps = (
    state: IApplicationState,
    ownProps: IBoardLogoHOCProps
): IBoardLogoFields => {
    const { boardId } = ownProps;
    const board = getBoard(state, boardId);
    const sectionType = getSectionType(state, boardId, null, 'allowBranding', 'allowChangeLogo');
    return {
        isProFeature: getShowProFeaturesTrial(state),
        logo: {
            icon: getBoardLogoIcon(state, boardId),
            color: getBoardLogoColor(state, boardId)
        },
        url: board ? board.logo : '',
        sectionType
    }
};

const mapDispatchToProps = (
    dispatch: any,
    ownProps: IBoardLogoHOCProps
): IBoardLogoEvents => {
    const { boardId } = ownProps;
    return {
        onClickDisallow: () => dispatch(onClickDisallow()),
        onDelete: () => dispatch(onDelete(boardId)),
        onDidUnmount: () => dispatch(onDidUnmount(boardId)),
        onFileSelect: (file) => dispatch(onFileSelect(boardId, file)),
        onToggleShowIconAndColorLogo: (isHide) => dispatch(onToggleShowIconAndColorLogo(boardId, isHide)),
        onLogoChange: (logo) => dispatch(onLogoChange(boardId, logo))
    }
};

export const BoardLogoHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(BoardLogo);

BoardLogoHOC.displayName = 'BoardLogoHOC';
