import { Dispatch, ThunkAction } from '../../../types/actions';
import { IGetState, TBoardId } from '../../../types/types';
import { boardsRequestsStateSetAction } from '../actions/boardsRequestsStateSetAction';
import { getBoardActiveRequestsWithDelta } from '../selectors/getBoardActiveRequestsWithDelta';

export const boardActiveRequestsUpdate = (
    boardId: TBoardId,
    activeRequestsDelta: number
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        const activeRequests = getBoardActiveRequestsWithDelta(getState(), boardId, activeRequestsDelta);
        dispatch(boardsRequestsStateSetAction({
            [boardId]: {
                activeRequests
            }
        }));
    };
    return action;
};
