import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { IListHOCProps } from './types';
import { IListEvents, IListFields } from '../../components/List/types';
import { List } from '../../components/List/List';
import { getBoardCardTemplateIdsSelector } from '../../selectors/getBoardCardTemplateIds';
import { onCardSort } from './events/onCardSort';

const mapStateToProps = (
    state: IApplicationState,
    { boardId, isRecurring }: IListHOCProps
): IListFields => {
    return {
        cardIds: getBoardCardTemplateIdsSelector(state, { boardId, isRecurring }),
        isRecurring
    };
};

const mapDispatchToProps = (
    dispatch: any,
    { boardId, isRecurring }: IListHOCProps
): IListEvents => ({
    onCardSort: (id, position) => dispatch(onCardSort(id, position, boardId, isRecurring))
})

export const ListHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(List);

ListHOC.displayName = 'ListHOC';
