import { IApplicationState } from '../../../../types/types';
import { getAuthUser } from './getAuthUser';

export const getAuthUserIsHideDoneChecklistItems = (
    state: IApplicationState
): boolean => {
    const user = getAuthUser(state);
    if (!user.meta) return false;

    return user.meta.isHideDoneChecklistItems;
};
