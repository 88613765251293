import { getCard } from '../../../../../../../../store/model/selectors/getCard';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TBoardId, TCardId } from '../../../../../../../../types/types';
import { cardOpen } from '../../../../../../base/effects/cardOpen';
import {
    SegmentCardEvent,
    SegmentCardSection,
    segmentTrackAction
} from '../../../../../../../../middlewares/segment';
import { getSectionType } from '../../../../selectors/getSectionType';
import { ESettingsSectionType } from '../../../../../../base/components/SettingsSection/types';
import { disallowedSectionClickedEvent } from '../../../../effects/segmentEvents';
import { showSnoozeBlocker } from '../../../../../../base/effects/showSnoozeBlocker';
import { SegmentCardSourceValue } from 'app/middlewares/segment/trackEntities/cardEvents';

export const onClick = (
    boardId: TBoardId,
    cardId: TCardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const sectionType = getSectionType(state, boardId, cardId, null, 'allowSubCard');
        const isDisallow = sectionType === ESettingsSectionType.DISALLOW;

        if (isDisallow) {
            dispatch(showSnoozeBlocker({
                allow: 'allowSubCard'
            }));
            dispatch(disallowedSectionClickedEvent(SegmentCardSection.EPIC, SegmentCardSourceValue.DETAILS));
        } else {
            dispatch(segmentTrackAction(SegmentCardEvent.EPIC_LINK_CLICKED));
            const card = getCard(state, cardId);
            dispatch(cardOpen(card.epicId));
        }
    };
    return action;
};
