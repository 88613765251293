import {
    ACTIVE_SECTION_TIMER,
    CLASS_ASIDE_PANEL_ACTIONS_BUTTON_HIGHLIGHT
} from '../components/AsidePanelActionsButton/constants';
import { MutableRefObject } from 'react';
import Timeout = NodeJS.Timeout;

export const scrollPanelToSection = (
    sectionSelector: string,
    sectionElementSelector: string,
    activeSectionTimer: MutableRefObject<Timeout>,
    onFinish?: () => void
) => {
    const section = document.querySelector(sectionSelector) as HTMLElement;
    const sectionElement: any = sectionElementSelector
        ? document.querySelector(sectionElementSelector) as HTMLElement
        : section;
    if (sectionElementSelector) {
        const input = sectionElement.querySelector('.kui-input') as HTMLElement;
        if (input) input.focus();
    }
    const highlighted = document.querySelector('.' + CLASS_ASIDE_PANEL_ACTIONS_BUTTON_HIGHLIGHT);
    if (highlighted) highlighted.classList.remove(CLASS_ASIDE_PANEL_ACTIONS_BUTTON_HIGHLIGHT); // если ещё предыдущая секция подсвечивается
    if (section) section.classList.add(CLASS_ASIDE_PANEL_ACTIONS_BUTTON_HIGHLIGHT); // подсветить секцию
    if (sectionElement) sectionElement.scrollIntoViewIfNeeded(true) // плавный почему-то перестал работать, дёргается на пару пикселей
    // sectionElement.scrollIntoView({behavior: 'smooth'}); // плавный скрол к элементу
    if (activeSectionTimer.current) clearTimeout(activeSectionTimer.current);
    activeSectionTimer.current = setTimeout(() => {
        // sectionElement.scrollIntoView(); // если плавный скрол не сработал
        if (onFinish) onFinish();
        activeSectionTimer.current = setTimeout(() => {
            if (section) section.classList.remove(CLASS_ASIDE_PANEL_ACTIONS_BUTTON_HIGHLIGHT); // убрать подсветку
        }, ACTIVE_SECTION_TIMER);
    }, ACTIVE_SECTION_TIMER);
};
