import * as React from 'react';
import {INavigationPanelTabsProps} from './types';
import {NavigationPanelTabItemHOC} from '../../hocs/NavigationPanelTabItemHOC/NavigationPanelTabItemHOC';
import './_NavigationPanelTabs.scss';
import { RecentBoardsHOC } from '../../RecentBoards/hocs/RecentBoardsHOC/RecentBoardsHOC';
import { NAVIGATION_PANEL_BOARDS_CLASS } from '../../constants';

export const NavigationPanelTabs: React.FC<INavigationPanelTabsProps> = ({
    tab
}) => {
    return (
        <div className={NAVIGATION_PANEL_BOARDS_CLASS}>
            {!!tab &&
                <NavigationPanelTabItemHOC id={tab.id}/>
            }
            <div className={'navigation-panel__boards-scroll'}>
                <RecentBoardsHOC/>
            </div>
        </div>
    );
};
