import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { CardEstimateAndSpentTimeSection } from '../../components/CardEstimateAndSpentTimeSection/CardEstimateAndSpentTimeSection';
import {
    ICardEstimateAndSpentTimeEvents,
    ICardEstimateAndSpentTimeFields,
} from '../../components/CardEstimateAndSpentTimeSection/types';
import { ICardEstimateAndTimeOwnProps } from './types';
import { onEstimateChange } from './events/onEstimateChange';
import { getCardTotalSpentTimeStr } from '../../../../../../../store/model/selectors/getCardTotalSpentTimeStr';
import { getSectionType } from '../../../selectors/getSectionType';
import { getBoardWorkHoursInDay } from '../../../../../../../store/model/selectors/getBoardWorkHoursInDay';
import { getBoardIsCardDefaultProperty } from '../../../../../../../store/model/board/selectors/getBoardIsCardDefaultProperty';
import { EAsidePanelProperty } from '../../../../asidePanel/components/AsidePanel/types';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { onEstimateError } from './events/onEstimateError';
import { getCardEstimateStr } from '../../../../../../../store/model/selectors/getCardEstimateStr';
import {
    getBoardIsCardRequiredProperty
} from '../../../../../../../store/model/board/selectors/getBoardIsCardRequiredProperty';

const mapStateToProps = (
    state: IApplicationState,
    props: ICardEstimateAndTimeOwnProps,
): ICardEstimateAndSpentTimeFields => {
    const { boardId, cardId, sectionType: sectionTypeOwn } = props;
    const workHoursInDay = getBoardWorkHoursInDay(state, boardId);
    const estimateStr = getCardEstimateStr(state, boardId, cardId, false);
    const spentTime = getCardTotalSpentTimeStr(state, boardId, cardId);
    const sectionType = getSectionType(state, boardId, cardId, sectionTypeOwn);

    return {
        estimateStr,
        isDefaultProperty: getBoardIsCardDefaultProperty(state, boardId, EAsidePanelProperty.CARD_ESTIMATE),
        isRequired: getBoardIsCardRequiredProperty(state, boardId, EAsidePanelProperty.CARD_ESTIMATE),
        sectionType,
        spentTime,
        workHoursInDay,
    }
};

const mapDispatchToProps = (
    dispatch: any,
    { boardId, cardId }: ICardEstimateAndTimeOwnProps,
): ICardEstimateAndSpentTimeEvents => {
    return {
        onEstimateChange: (estimateInMinutes: number) => dispatch(onEstimateChange(boardId, cardId, estimateInMinutes)),
        onEstimateError: () => dispatch(onEstimateError())
    }
};

export const CardEstimateAndSpentTimeSectionHOC = connect(
    mapStateToProps,
    mapDispatchToProps,
)(CardEstimateAndSpentTimeSection);

CardEstimateAndSpentTimeSectionHOC.displayName = 'CardEstimateAndSpentTimeSectionHOC';
