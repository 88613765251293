import {Dispatch, ThunkAction} from '../../../../../../../../../types/actions';
import {IGetState, TBoardId} from '../../../../../../../../../types/types';
import {SegmentBoardEvent, segmentTrackAction} from '../../../../../../../../../middlewares/segment';
import {
    SegmentBoardOption,
    SegmentBoardOptionStatusValue
} from '../../../../../../../../../middlewares/segment/trackEntities/boardEvents';
import {hasAutoBackupsWarning} from '../../../../../../../../../store/model/board/selectors/hasAutoBackupsWarning';
import {removeAutoBackupsWarningMeta} from '../../../../../../../../../rest/effects/board/removeAutoBackupsWarningMeta';
import {getAutoBackupsByBoardIdAndExportType} from '../../../store/autoBackup/selectors/getAutoBackupsByBoardIdAndExportType';
import {EBackupExportType} from '../../../../../../../../../types/rest/IRestBackup';
import { editAutoBackup } from '../../../effects/editAutoBackup';

export const toggleEnabledAutoBackups = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const autoBackup = getAutoBackupsByBoardIdAndExportType(state, boardId, EBackupExportType.XML);
        dispatch(segmentTrackAction(SegmentBoardEvent.AUTO_BACKUP_TOGGLED, {
            name: SegmentBoardOption.STATUS,
            value: !autoBackup.enabled ? SegmentBoardOptionStatusValue.ON: SegmentBoardOptionStatusValue.OFF}));
        return dispatch(editAutoBackup(boardId, {
            ...autoBackup,
            enabled: !autoBackup.enabled,
            exportType: EBackupExportType.XML,
            isArchiveIncluded: true
        })).then(() => {
            if(hasAutoBackupsWarning(state, boardId)) {
                dispatch(removeAutoBackupsWarningMeta(boardId))
            }
        })
    }
    return action;
}
