import * as React from 'react';
import { ICookiesDialogProps } from './types';
import './_cookie.scss';
import { Button, Switch } from 'kui';
import {
    COOKIE_POPUP_BUTTON_AGREE,
    COOKIE_POPUP_BUTTON_SHOW_SETTINGS,
    COOKIE_POPUP_SETTINGS_NECESSARY_TEXT,
    COOKIE_POPUP_SETTINGS_NECESSARY_TITLE,
    COOKIE_POPUP_SETTINGS_PERFORMANCE_TEXT,
    COOKIE_POPUP_SETTINGS_PERFORMANCE_TITLE,
    COOKIE_POPUP_SETTINGS_TITLE,
    COOKIE_POPUP_TEXT,
    COOKIE_POPUP_TITLE
} from './constants';
import { BackButton } from '../../../../base/components/BackButton/BackButton';
import ReactHtmlParser from 'react-html-parser';

export const Cookies: React.FC<ICookiesDialogProps> = ({
    isShow,
    isPerformanceAllowed,
    isSettingsOpened,
    isUserSettings,
    onChangePerformance,
    onCookieSettingsClick,
    onAccept,
    onShow
}) => {
    let [isPerformanceAllowedHook, setPerformanceAllowedHook] = React.useState(isPerformanceAllowed);
    const nullChangeAction = () => {
    };

    const performanceCheckStateAction = () => {
        isPerformanceAllowedHook = !isPerformanceAllowedHook;
        setPerformanceAllowedHook(isPerformanceAllowedHook);
        if (isUserSettings) onChangePerformance(isPerformanceAllowedHook);
    };

    const onAcceptClick = () => {
        onAccept(isPerformanceAllowedHook);
    };

    React.useEffect(() => {
        if (isShow) onShow();
    }, [isShow]);

    React.useEffect(() => {
        isPerformanceAllowedHook = isPerformanceAllowed;
        setPerformanceAllowedHook(isPerformanceAllowedHook);
    }, [isPerformanceAllowed]);

    const items = (
        <>
            <div className={'cookie__item cookie__item--disabled'}>
                <span className={'cookie-item__header'}>
                    {COOKIE_POPUP_SETTINGS_NECESSARY_TITLE}
                    <Switch
                        checked={true}
                        disabled={true}
                        onChange={nullChangeAction}
                    />
                </span>
                <p className={'cookie-item__body'}>
                    {COOKIE_POPUP_SETTINGS_NECESSARY_TEXT}
                </p>
            </div>
            <div
                className={'cookie__item'}
            >
                <span className={'cookie-item__header'}>
                    {COOKIE_POPUP_SETTINGS_PERFORMANCE_TITLE}
                    <Switch
                        checked={isPerformanceAllowedHook}
                        onChange={performanceCheckStateAction}
                    />
                </span>
                <p className={'cookie-item__body'}>
                    {COOKIE_POPUP_SETTINGS_PERFORMANCE_TEXT}
                </p>
            </div>
        </>
    );

    return (
        <div
            className={`
                cookie
                ${isUserSettings ? 'cookie--user-settings' : ''}
                ${(!isUserSettings && isShow) ? 'cookie--shown' : ''}
            `}
        >
            {(isShow || isUserSettings) &&
                <div className={`cookie__content ${(!isUserSettings && isSettingsOpened) ? 'cookie__content--settings' : ''}`}>
                    {!isUserSettings &&
                        <div className={'cookie__header'}>
                            {(!isUserSettings && isSettingsOpened) && <BackButton onClick={onCookieSettingsClick}/>}
                            <span className={'cookie__title'}>
                                {isSettingsOpened ? COOKIE_POPUP_SETTINGS_TITLE : COOKIE_POPUP_TITLE}
                            </span>
                        </div>
                    }
                    {(!isUserSettings && isSettingsOpened) ? items
                        : <>
                            <span className={'cookie__text'}>{ReactHtmlParser(COOKIE_POPUP_TEXT)}</span>
                            {isUserSettings ? items
                                : <>
                                    <Button
                                        className={'cookie__agree'}
                                        onClick={onAcceptClick}
                                        size={'large'}
                                    >
                                        {COOKIE_POPUP_BUTTON_AGREE}
                                    </Button>
                                    <Button
                                        onClick={onCookieSettingsClick}
                                        variant={'secondary'}
                                        size={'large'}
                                    >
                                        {COOKIE_POPUP_BUTTON_SHOW_SETTINGS}
                                    </Button>
                                </>
                            }
                        </>
                    }
                </div>
            }
        </div>
    );
};
