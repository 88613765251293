import { TPermissionId } from '../types';

export interface ISharedUser {
    permissionId?: TPermissionId;
    fullName?: string;
    photoUrl?: string;
    role?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
}

export const getInitUserBackboneModel = (): ISharedUser => {
    return {
        permissionId: '',
        fullName: '',
        photoUrl: '',
        role: '',
        firstName: '',
        lastName: '',
        email: ''
    }
};
