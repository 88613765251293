import { ThunkAction } from '../../../../../../types/actions';
import { root } from '../../../../../../store/constants';
import { KEYBOARD_SHORTCUTS_CLASS } from '../../../components/keyboardShortcuts/constants';

export const onOpen = (): ThunkAction => {
    const action = () => {
        const shortcuts = document.querySelector('.' + KEYBOARD_SHORTCUTS_CLASS) as HTMLElement;
        if (!shortcuts) {
            root.App.controller.openKeyboardShortcuts();
        }
    }
    return action;
}
