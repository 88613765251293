import { ThunkAction } from '../../../../../../../types/actions';
import { completeRequest } from '../effects/completeRequest';
import { segmentTrackAction, SegmentUserEvent } from '../../../../../../../middlewares/segment';

export const onCancel = (): ThunkAction => {
    return (
        dispatch
    ) => {
        dispatch(segmentTrackAction(SegmentUserEvent.BROWSER_NOTIFICATIONS_NOT_NOW_CLICKED));
        dispatch(completeRequest());
    }
}
