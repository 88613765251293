import { IApplicationState } from '../../../../../../../../types/types';
import { connect } from 'react-redux';
import { ArchiveOnDeletePanelSection } from '../../components/ArchiveOnDeletePanelSection/ArchiveOnDeletePanelSection';
import { IArchiveOnDeleteSectionFields } from '../../components/ArchiveOnDeletePanelSection/types';
import { getIsUserBasicPlanOnly } from '../../../../../../../../store/model/authUser/selectors/getIsUserBasicPlanOnly';
import {
    getShowProFeaturesTrial
} from '../../../../../../../../store/model/authUser/selectors/getShowProFeaturesTrial';

const mapStateToProps = (
    state: IApplicationState,
): IArchiveOnDeleteSectionFields => {
    return {
        isBasicFeature: getIsUserBasicPlanOnly(state),
        isProFeature: getShowProFeaturesTrial(state),
    }
};

export const ArchiveOnDeletePanelSectionHOC = connect(
    mapStateToProps,
    null
)(ArchiveOnDeletePanelSection);

ArchiveOnDeletePanelSectionHOC.displayName = 'ArchiveOnDeletePanelSectionHOC';
