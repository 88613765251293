import { AT_BOARD_ACTION_SET, IBoardActionSetAction } from './types';
import { TBoardAction } from '../../board/actions/types';

export const boardActionSet = (
    boardId: number,
    boardAction: TBoardAction
): IBoardActionSetAction => ({
    type: AT_BOARD_ACTION_SET,
    boardId,
    boardAction,
});
