import { ThunkAction, Dispatch } from '../../../../types/actions';
import { hintsSetAction } from '../store/hints/actions/hintsSetAction';
import { fetchHandler } from '../../../../util/fetchHandler';
import Util from '../../../../util/util';
import { REST_HINTS } from '../constants';
import { IRestHints } from '../types';

export const hintsLoad = (
): ThunkAction => {
    const action = (dispatch: Dispatch) => {
        return fetchHandler<IRestHints>(
            Util.getApiUrl(REST_HINTS)
        )
            .then(
                (restHints) => {
                    dispatch(hintsSetAction(restHints.hintsMap));
                }
            );
    };
    return action;
};
