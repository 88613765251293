import { getAppState } from './../../../../../../../store/configureStore';
import { getCardCalendarEvents } from '../../../../../../../store/model/selectors/getCardCalendarEvents';
import * as _ from 'underscore';

const App = window.App;

const getEventUrl = (calendarId) => {
    if (!calendarId) {
        return {};
    }

    const state = getAppState();
    const selectedCardId = state.pushToGoogleCalendar.cardId;
    const selectedActionType = state.pushToGoogleCalendar.selectedAction;
    const pushed = getCardCalendarEvents(state, selectedCardId);
    const actionWithEvent = _.findWhere(pushed, {type: selectedActionType});

    if (actionWithEvent) {
        try {
            const htmlLink = actionWithEvent.googleEvents[0].htmlLink;
            const eid = htmlLink.split('eid=')[1];
            // let eid = btoa(`${eventId} ${calendarId}`).replace('==', '').replace('=', '');
            return {
                href: `${App.Util.getCalendarGoogleUrl()}/r/eventedit/${eid}`,
                target: "_blank",
                title: "Open Event"
            };
        } catch (e) {
            console.warn('eventLink missed missed, probably you have a outdated google calendar event');
        }
    }
    return {};
};

export default getEventUrl;
