'use strict';

App.Models.PaymentCheckout = Backbone.Model.extend({
    
    classKey: 'paymentCheckout',
    
    urlRoot: function() {
        return '/payment/checkout';
    },

    initialize: function(options) {
        this.options = options || {};
    },
    
    toViewJSON: function(options) {
        return this.toJSON();
    }
    
});
