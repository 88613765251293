import * as React from 'react';
import { CARD_CLASS } from '../../../../constants';
import './CardTags.scss';
import { ICardTagsProps } from './types';
import { TAG_CHARS_LIMIT } from './constants';
import { Tooltip } from 'kui';
import { clearMarkdownInline } from '../../../../../../base/helpers/clearMarkdownHelper';
import { markdown } from '../../../../../../../../helper/markdownHelper';
import { escape } from 'underscore';
import ReactHtmlParser from 'react-html-parser';

export function CardTags({
    tags
}: ICardTagsProps) {
    const classCardTags = CARD_CLASS + '__tags';
    const classCardTagTooltip = CARD_CLASS + '__tag-tooltip';

    function getTag (tag: string): JSX.Element {
        let tagTrim = null;
        if (tag.length > TAG_CHARS_LIMIT * 1.5) {
            tagTrim = escape(clearMarkdownInline(tag)).slice(0, TAG_CHARS_LIMIT) + '...';
        }
        const tagMarkdown = markdown(tag);
        const tagElement = (
            <span key={tag}>
                {ReactHtmlParser(`#${tagTrim || tagMarkdown}`)}
            </span>
        );
        const tagTooltip = tagTrim
            ? <Tooltip
                className={classCardTagTooltip}
                isNoEvents
                isNoWrap
                key={tag}
                value={tagMarkdown}
            >
                {tagElement}
            </Tooltip>
            : tagElement;
        return tagTooltip;
    }

    return (
        <div className={classCardTags}>
            {tags.map(tag => getTag(tag))}
        </div>
    );
}
