import * as React from 'react';
import './_CommentAttachments.scss';
import { ICommentAttachmentsProps } from './types';
import { CLASS_COMMENT } from '../../constants';
import { CommentAttachment } from '../CommentAttachment/CommentAttachment';

export function CommentAttachments({
    attachments,
    isFullScreen,
    isReadonly,
    onClick,
    onDblClick,
    onDelete,
    onReply
}: ICommentAttachmentsProps) {
    if (!attachments || !attachments.length) return null;

    return <div className={CLASS_COMMENT + '-attachments'} onDoubleClick={onDblClick}>
        {attachments.map(attachment => {
            return <CommentAttachment
                key={attachment.fileId}
                attachment={attachment}
                isActions={!isReadonly}
                isFullScreen={isFullScreen}
                onClick={onClick}
                onDelete={onDelete}
                onReply={onReply}
            />
        })}
    </div>;
}
