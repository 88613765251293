import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState, TCardId } from '../../../../types/types';
import { getCard } from '../../../../store/model/selectors/getCard';
import { ICardBatch } from './api/types';
import { cardPatchRestBatch } from './api/helper/cardPatchRestBatch';
import { getNextGanttOrderNumber } from '../../../../store/model/selectors/getNextGanttOrderNumber';
import { getTypeSwitcherActiveType } from '../../../../view/react_components/typeSwitcher/store/selectors/getTypeSwitcherActiveType';
import { EViewTypes } from '../../../../const';
import { getBoardIdByCardId } from '../../../../store/model/selectors/getBoardIdByCardId';
import { filterIgnoreCardIdAdd } from '../../../../view/react_components/aside_panel/filterPanelBoard/store/filterMatch';

export const cardToggleGanttBatch = (
    getState: IGetState,
    cardId: TCardId,
    cardBatch: ICardBatch = {card: {}, callbacks: []}
) => {
    const state = getState();
    const card = getCard(state, cardId);
    if (!card) return cardBatch;

    const isGantt = !card.ganttVisibility;
    cardBatch.card = {
        ...cardBatch.card,
        ganttVisibility: isGantt,
    }

    if (isGantt) {
        cardBatch.card.ganttOrderNumber = getNextGanttOrderNumber(state, cardId);
    }

    return cardBatch;
};

export const cardToggleGantt = (
    cardId: TCardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const boardId = getBoardIdByCardId(getState(), cardId);
        const view = getTypeSwitcherActiveType(getState(), boardId);
        if (view === EViewTypes.GANTT_VIEW_TYPE) { // на ганте игнорируем фильтры для только что-то добавленных карт
            dispatch(filterIgnoreCardIdAdd(cardId));
        }

        const cardBatch = cardToggleGanttBatch(getState, cardId);
        return cardPatchRestBatch(cardId, dispatch, cardBatch);
    };
    return action;
};
