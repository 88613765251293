import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { filterPanelActionSetAction } from '../../../../../store/filterPanelBoards/actions/filterPanelActionSetAction';
import { editedFilterSet } from '../../../../../store/filterPanel/actions/editedFilterSet';
import { IGetState } from '../../../../../../../../../types/types';
import { savedFilterSelector } from '../../../../../store/selectors/savedFilterSelector';
import { cloneFilterObject } from '../../../../../helpers/cloneFilterObject';
import { SegmentFilterEvent } from '../../../../../../../../../middlewares/segment/trackEntities/filterEvents';
import { filterSegmentTrackAction } from '../../../../../effects/filterSegmentTrackAction';
import { getAuthUser } from '../../../../../../../../../store/model/authUser/selectors/getAuthUser';
import { showSnoozeBlocker } from '../../../../../../../base/effects/showSnoozeBlocker';

export const onEditFilter: ActionCreator<ThunkAction> = (
    boardId: number,
    filterId: number
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const { allowSavedFilters } = getAuthUser(getState());
        if (!allowSavedFilters) {
            dispatch(showSnoozeBlocker({ allow: 'allowSavedFilters' }));
        } else {
            const filter = cloneFilterObject(savedFilterSelector(getState(), filterId));
            dispatch(filterPanelActionSetAction(boardId, editedFilterSet({...filter, isEnabled: true})));
            dispatch(filterSegmentTrackAction(SegmentFilterEvent.EDIT_SAVED_FILTER, boardId));
        }
    };
    return action;
};
