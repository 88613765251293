import {
    filterPanelActionSetAction
} from "../react_components/aside_panel/filterPanelBoard/store/filterPanelBoards/actions/filterPanelActionSetAction";
import FilterPanelView from '../aside_panel/dashboard_filter/dashboard_new_filter_form'
import BoardPanelView from './board_panel_view'
import MyWorkSettingsView from './my_work_settings_view';
import store, { dispatch } from '../../store/configureStore';
import { enabledSet } from "../react_components/aside_panel/filterPanelBoard/store/filterPanel/actions/enabledSet";
import { clickManagerActionSet, deselectCards, selectedCardIdsSet } from '../react_components/clickManager';
import ListSettings from './list_settings'
import MultiCardFormNew from './multi_select_card_view';
import RelatedCardsView from './related_cards';

import { SegmentCardEvent, SegmentCardOptions, segmentTrackAction } from "../../middlewares/segment";
import { reportAsideView } from "./reports_aside_view";
import { insightsAsideView } from "./insights_view";
import {
    SegmentCardOptionCardTypeValue,
    SegmentCardSourceValue
} from '../../middlewares/segment/trackEntities/cardEvents';
import { EBoardTab } from "../react_components/aside_panel/boardDetails/types";
import {
    panelOpenedSet
} from '../react_components/aside_panel/filterPanelBoard/store/filterPanel/actions/panelOpenedSet';
import {
    filterPanelBoardsSelector
} from '../react_components/aside_panel/filterPanelBoard/store/selectors/filterPanelBoardsSelector';
import { CLASS_BOARD_DETAILS_SECTION } from "../react_components/aside_panel/boardDetails/constants";
import { KanbanCardDetailsView } from '../dashboard/card_details/kanban_card_details';
import { NotificationCardDetailsView } from '../dashboard/card_details/notification_card_details';
import { AssignedToMeCardDetailsView } from '../dashboard/card_details/assigned_to_me_card_details';
import { getActiveBoardId } from '../../store/model/selectors/getActiveBoardId';
import {
    setFilterPanelIsInitial
} from "../react_components/aside_panel/filterPanelBoard/effects/setFilterPanelIsInitial";
import { LinkCardDetailsView } from "../dashboard/card_details/link_card_details";
import { getActiveRouteBoardId } from "../../store/router/selectors/getActiveRouteBoardId";
import { ETutorial } from "../../types/rest/IUserMeta";
import { EMyWorkTab } from "../react_components/main/myWorkView/myWorkSettingsPanel/types";
import { getIsAssignedToMeActive } from "../../store/router/selectors/getIsAssignedToMeActive";
import { root } from "../../store/constants";
import { getAuthUser } from "../../store/model/authUser/selectors/getAuthUser";
import { getProFeaturesTrialActive } from "../../store/model/authUser/selectors/getProFeaturesTrialActive";
import { getHintCanShow } from "../react_components/hints/store/hint/selectors/getHintCanShow";
import {
    getAuthUserIsShowGanttContextHints
} from "../../store/model/authUser/selectors/getAuthUserIsShowGanttContextHints";
import { EHintKey, EHintStatus } from "../react_components/hints";
import { hintActionSetAction } from "../react_components/hints/store/hints/actions/hintActionSetAction";
import { hintStatusSetAction } from "../react_components/hints/store/hint/actions/hintStatusSetAction";
import { hintCanShowSetAction } from "../react_components/hints/store/hint/actions/hintCanShowSetAction";
import { getHint } from "../react_components/hints/store/hints/selectors/getHint";

var App = window.App;

App.Views.AsidePanelRenderer = App.Views.BaseView.extend({
    className: 'aside-panel',

    events: {
        // 'click': 'onClick',
        // 'mousedown img': 'onImgMousedown',
        'click .aside-panel__hide': 'onCloseClick'
    },

    initialize: function(options) {
        this.listenTo(App.vent, App.vent['aside-panel:loaded'], this.showCurrentView);
        this.listenTo(App.vent, App.vent['aside-panel:hide'], this.onHide);
        this.listenTo(App.vent, App.vent['dashboard:close'], this.onHide);
        this.currentView = null;
        this.currentViewClass = '';
        this.portalViewClasses = ['updates'];
        this.parent = options.parent;

        this.store = options.store;
    },

    render: function() {
        return this;
    },

    show: function (view, callback) {
        this.clear();
        this.showLoader();
        this.currentView = view;
        this.testDataUpdate();
        this.showCurrentView(callback);
    },

    showCurrentView: function(callback) {
        this.el.querySelector('.aside-panel__hide').before(this.currentView.el);
        setTimeout(() => this.currentView.render(), 0); // повесить классы, потом рендерить реакт
        requestAnimationFrame(() => App.vent.trigger(App.vent['aside-panel:opened'])); // main-view onAsidePanelOpened
        if (this.currentView.afterDOMRendering) {
            this.currentView.afterDOMRendering();
        }
        if (callback) callback();
        if (this.currentViewClass) {
            this.el.classList.remove('aside-panel--' + this.currentViewClass);
        }
        this.currentViewClass = this.currentView.asideClassName;
        if (this.currentViewClass) {
            this.el.classList.add('aside-panel--' + this.currentViewClass);
            if (this.portalViewClasses.includes(this.currentViewClass)) {
                document.body.append(this.el);
            }
        }
    },

    showBoard: function(boardId, scrollElem = '') {
        const view = new BoardPanelView({
            boardId,
            parent: this,
            store: this.store,
        });
        this.show(view);
        if (scrollElem) {
            setTimeout(() => {
                const scrollElement = document.querySelector(`.${CLASS_BOARD_DETAILS_SECTION}--${scrollElem}`);
                if (scrollElement) scrollElement.scrollIntoView();
            }, 600);
        }
    },

    showBoardActivity: function(boardId) {
        this.show(new BoardPanelView({
            boardId,
            parent: this,
            store: this.store,
            tabKey: EBoardTab.ACTIVITY,
        }));
    },

    showBoardDesign: function(boardId) {
        this.show(new BoardPanelView({
            boardId,
            parent: this,
            store: this.store,
            tabKey: EBoardTab.BRANDING,
        }));
    },

    showBoardAttachments: function(boardId) {
        this.show(new BoardPanelView({
            boardId,
            parent: this,
            store: this.store,
            tabKey: EBoardTab.ATTACHMENTS,
        }));
    },

    showBoardBackups: function(boardId) {
        this.show(new BoardPanelView({
            boardId,
            parent: this,
            store: this.store,
            tabKey: EBoardTab.BACKUPS,
        }));
    },

    showBoardExport: function(boardId) {
        this.show(new BoardPanelView({
            boardId,
            parent: this,
            store: this.store,
            tabKey: EBoardTab.EXPORT,
        }));
    },

    showBoardFilterForm: function(boardId, scrollElem = null) {
        const state = store.getState();
        const { isEnabled, isOpened } = filterPanelBoardsSelector(state, boardId);
        if (!isEnabled) {
            store.dispatch(filterPanelActionSetAction(boardId, enabledSet(true)));
            store.dispatch(setFilterPanelIsInitial(boardId));
        }
        if (!isOpened) {
            store.dispatch(filterPanelActionSetAction(boardId, panelOpenedSet(true)));
        }

        this.show(new FilterPanelView({
            boardId,
            scrollElem,
            parent: this,
            store,
        }));

        App.controller.trackEvent(
            Messages.getText('ga.category.dashboard'),
            Messages.getText('ga.action.filter_opened')
        );

        const user = getAuthUser(state);
        if (
            !getIsAssignedToMeActive(state) ||
            user['allowMyWorkCustomFilters'] ||
            getProFeaturesTrialActive(state)
        ) {
            root.App.controller.showTutorial(ETutorial.FILTER_TUTORIAL);
        }
    },

    showRelatedCardsPanel: function(cardId, boardId) {
        this.show(new RelatedCardsView({ store, cardId, boardId, parent: this }))
    },

    showList: function(listId) {
        this.show(new ListSettings({
            listId,
            store,
        }));
        const boardId = getActiveBoardId(store.getState());
        if (boardId) {
            App.controller.lastActivity.setLastOpenedListId(boardId, listId);
            App.controller.lastActivity.setLastOpenedCardId(boardId, null);
        }
    },

    showReportsFilterAside: function(boardId){
        this.show(new reportAsideView({store: store, boardId}))
    },

    showReportsInsightsAside: function(boardId){
        this.show(new insightsAsideView({store: store, boardId}))
    },


    showCard: function(cardId, isTemplate) {
        this.showKanbanCardDetails(cardId, isTemplate);
        App.controller.showTutorial(ETutorial.CARD_DETAILS_TUTORIAL);
    },

    showCardTiming: function(cardId) {
        dispatch(segmentTrackAction(SegmentCardEvent.TAB_TIMING));
        this.showKanbanCardDetails(cardId);
    },

    showCardActivity: function(cardId) {
        dispatch(segmentTrackAction(SegmentCardEvent.TAB_ACTIVITY));
        this.showKanbanCardDetails(cardId);
    },

    showCardPrint: function(cardId) {
        this.showKanbanCardDetails(cardId);
    },

    showKanbanCardDetails: function(cardId, isTemplate) {
        this.show(new KanbanCardDetailsView({
            cardId,
            isTemplate,
            parent: this,
            store: this.store,
        }));
        const boardId = getActiveBoardId(store.getState());
        if (boardId) {
            App.controller.lastActivity.setLastOpenedCardId(boardId, cardId);
            App.controller.lastActivity.setLastOpenedListId(boardId, null);
        }
    },

    openAssignedToMeNotification: function(notificationId) {
        dispatch(segmentTrackAction(SegmentCardEvent.DETAILS, {
            name: SegmentCardOptions.TYPE,
            value: SegmentCardOptionCardTypeValue.GENERAL
        }, [{
            name: SegmentCardOptions.SOURCE,
            value: SegmentCardSourceValue.DASHBOARD
        }]));
        const view = new NotificationCardDetailsView({
            notificationId,
            parent: this,
            store: this.store
        });
        this.show(view);
    },

    openAssignedToMeCard: function(cardId) {
        const view = new AssignedToMeCardDetailsView({
            cardId,
            parent: this,
            store: this.store
        });
        this.show(view);
    },

    openLinkCard: function(boardId, cardId) {
        App.controller.mainView.showCardLink();

        const view = new LinkCardDetailsView({
            boardId,
            cardId,
            parent: this,
            store: this.store
        });
        this.show(view);
    },

    openAssignedToMeSettings: function() {
        this.show(new MyWorkSettingsView({
            parent: this,
            store: this.store,
            tabKey: EMyWorkTab.SETTINGS,
        }));
    },

    openAssignedToMeImport: function() {
        this.show(new MyWorkSettingsView({
            parent: this,
            store: this.store,
            tabKey: EMyWorkTab.IMPORT,
        }));
    },

    openAssignedToMeBackground: function() {
        this.show(new MyWorkSettingsView({
            parent: this,
            store: this.store,
            tabKey: EMyWorkTab.BACKGROUND,
        }));
    },

    showMultiCard: function(boardId) {
        const view = new MultiCardFormNew({
            boardId,
            parent: this,
            store: this.store,
        });
        this.show(view);
    },

    onCloseClick: function(e) {
        e.stopPropagation();
        this.hide();
        const boardId = getActiveBoardId(store.getState());
        if (boardId) {
            store.dispatch(deselectCards(boardId));
        }
        return false;
    },

    onImgMousedown: function(e) {
        // hack against dropping images (for example to right panel)
        e.stopPropagation();
        return false;
    },

    onClick: function(e) {
        App.vent.trigger(App.vent['aside-panel:clicked'], e);
    },

    onHide: function() {
        this.hide();
    },

    hide: function(navigate = true) {
        if (this.currentView && !App.controller.mainView.relatedOpened) {
            this.clear();
            const state = store.getState();
            const boardId = getActiveBoardId(state);
            if (boardId){
                dispatch(clickManagerActionSet(boardId, selectedCardIdsSet([])));
            }
            App.vent.trigger(App.vent['aside-panel:closed']);
            if (navigate) {
                App.router.navigate(App.router.getBaseUrl(), { trigger: true });
            }
            if ( // если юзер не прошел все шаги на карте, но ушел с детализации карточки - сбрасываем все по новой
                getAuthUserIsShowGanttContextHints(state) &&
                (
                    getHintCanShow(state, EHintKey.GANTT_CONTEXT_ADD_DATE) ||
                    getHintCanShow(state, EHintKey.GANTT_CONTEXT_SHOW_ON_GANTT)
                ) &&
                getHint(state, EHintKey.GANTT_CONTEXT_GANTT_TYPE_SWITCHER).status === EHintStatus.NOT_SHOWN
            ) {
                setTimeout(() => { // подождать скрытия хинта
                    dispatch(hintActionSetAction(EHintKey.GANTT_CONTEXT_SHOW_ON_GANTT, hintStatusSetAction(EHintStatus.NOT_SHOWN)));
                    dispatch(hintActionSetAction(EHintKey.GANTT_CONTEXT_SHOW_ON_GANTT, hintCanShowSetAction(false)));
                    dispatch(hintActionSetAction(EHintKey.GANTT_CONTEXT_ADD_DATE, hintStatusSetAction(EHintStatus.NOT_SHOWN)));
                    dispatch(hintActionSetAction(EHintKey.GANTT_CONTEXT_ADD_DATE, hintCanShowSetAction(false)));
                }, 200);
            }
            setTimeout(()=>{ // если ушли с доски, то не менять lastActivity
                const boardIdNew = getActiveRouteBoardId(store.getState()); // activeBoardId меняется после загрузки, а route уже поменялся
                if (!boardIdNew || boardIdNew === boardId) {
                    App.controller.lastActivity.setLastOpenedCardId(boardId, null);
                    App.controller.lastActivity.setLastOpenedListId(boardId, null);
                }
            }, 100);
        }
        if (App.controller.mainView.relatedOpened) {
            App.vent.trigger(App.vent['view:resized']); // to setPanelsWidth
        }
    },

    clear: function() {
        if (this.currentView) {
            this.currentView.remove();
            this.currentView = null;
        }
        if (this.currentViewClass) {
            this.el.classList.remove('aside-panel--' + this.currentViewClass);
            if (this.portalViewClasses.includes(this.currentViewClass)) {
                document.querySelector('main').after(this.el);
            }
            this.currentViewClass = '';
        }
    },

    showLoader: function() {

    },

    hideLoader: function() {

    },

    remove: function() {
        App.vent.off(null, null, this);
        return Backbone.View.prototype.remove.call(this);
    },

    testAttributes: function() {
        return {
            type: 'aside'
        }
    }

});
App.Views.AsidePanelRenderer.mixin(App.Mixins.Test);
export default App.Views.AsidePanelRenderer;
