'use strict';

import balloonCopyDashboardTemplate from './templates/balloonCopyDashboard.html';
import * as _ from 'underscore';

App.Views.CopyDashboardBalloon = App.Views.UndoBalloonBase.extend({
    tagName: 'div',
    
    className: 'baloon__content baloon__content--copytodrive',
    
    template: _.template( balloonCopyDashboardTemplate ),
    
    events: {
        'click .copy-dashboard': 'copyDashboard'
    },

    initialize: function(options) {
        this.boardId = options.boardId;
    },
    
    copyDashboard: function(e) {
        e.stopPropagation();
        App.controller.copyDashboard(this.boardId);
        return false;
    },
    
    deleteNode: function() {
    }
    
});
