import { QUALIFICATION_QUESTIONS } from '../constants/questions';
import { EQualificationAnswersType } from '../components/QualificationDialog/types';

export  const getNextQuestionKey = (
    questionKey: string,
    answerKey: string
): string => {
    const question = QUALIFICATION_QUESTIONS[questionKey];
    if (question.answersType === EQualificationAnswersType.CHECKBOXES) {
        return question.nextQuestion;
    }
    const answer = question.answers[answerKey];
    if (!answer) {
        return undefined;
    }
    if (answer.nextQuestion !== undefined)
        return answer.nextQuestion; // check empty string
    return question.nextQuestion;
}
