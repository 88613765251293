import { MONTH_LIST_MIDDLE } from '../constants';

export const getIndexFromDate = (
    initialDate: Date,
    date: Date
): number => {
    const initialYear = initialDate.getFullYear();
    const year = date.getFullYear();
    const initialMonth = initialDate.getMonth();
    const month = date.getMonth();
    const monthDifference = ((year - initialYear) * 12) + (month - initialMonth);
    return MONTH_LIST_MIDDLE + monthDifference;
}
