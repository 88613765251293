import { IABTestCurrentRest } from '../rest/types';
import { IABTestState } from '../store/types';
import { abTestsValueSet } from '../store/actions/abTestsValueSet';
import { Dispatch, ThunkAction } from '../../types/actions';

// отправляем данные в стор
export const dispatchValues = (
    testValues: IABTestCurrentRest[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        const convertedValues: IABTestState = testValues.reduce((
            memo: IABTestState,
            testValue: IABTestCurrentRest
        ) => {
            memo[testValue.name] = {
                value: testValue.property,
                init: true
            };
            return memo;
        }, {});
        dispatch(abTestsValueSet(convertedValues));
    }
    return action;
};
