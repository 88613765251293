import { IGetState, TCardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { getCard } from '../../../../store/model/selectors/getCard';
import { ICardBatch } from './api/types';
import { cardPatchRestBatch } from './api/helper/cardPatchRestBatch';
import { getNextCardOrderNumber } from '../../../../store/model/list/selectors/getNextCardOrderNumber';

/**
 * @param orderNumber если пусто, положить в начало листа
 */

export const cardSetListBatch = (
    getState: IGetState,
    cardId: TCardId,
    listId: number,
    orderNumber?: number,
    cardBatch: ICardBatch = {card: {}, callbacks: []}
) => {
    const state = getState();
    const card = getCard(state, cardId);
    if (!card) return cardBatch;

    if (card.listId === listId && orderNumber === undefined) return cardBatch;

    if (orderNumber === undefined) {
        orderNumber = getNextCardOrderNumber(state, listId, true);
    }

    cardBatch.card = {
        ...cardBatch.card,
        listId,
        orderNumber,
    };
    return cardBatch;
};

export const cardSetList = (
    cardId: TCardId,
    listId: number,
    orderNumber?: number,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const cardBatch = cardSetListBatch(getState, cardId, listId, orderNumber);
        return cardPatchRestBatch(cardId, dispatch, cardBatch)
    };
    return action;
};
