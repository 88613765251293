import { EProgressColor, THeaderProgress } from './header/components/Header/types';
import { getMessages } from '../../../store/constants';
import { EProcessingStatus } from '../../../types/rest/IUserMeta';

export const STATS_CARDS_TOOLTIP = 'All cards';
export const STATS_ESTIMATE_TOOLTIP = 'Total estimated';

export const UNASSIGNED_CARDS = getMessages().getText('team.workload.header.unassigned');
export const NO_COLOR_TAGS_CARDS = getMessages().getText('team.workload.header.no_color_tags');
export const NO_COLOR_CARDS = getMessages().getText('team.workload.header.no_color');
export const NO_TAGS_CARDS = getMessages().getText('team.workload.header.no_tags');

export const LIST_SUMMARY_IS_OPEN_HEIGHT = 256;
export const LIST_SUMMARY_DEFAULT_HEIGHT = 66;
export const ADD_CARD_INPUT_HEIGHT = 54;
export const ADDITIONAL_OFFSET = 55;

export const MAX_COLUMNS_FOR_EMPTY_LISTS = 30;
export const EMPTY_LIST_ID = 'empty-list';

export const CLASS_TEAM_WORKLOAD = 'team-workload';
export const CLASS_TEAM_WORKLOAD_LIST = CLASS_TEAM_WORKLOAD + '__list';
export const CLASS_TEAM_WORKLOAD_CARDS_SCROLL = CLASS_TEAM_WORKLOAD + '__cards-scroll';
export const CLASS_TEAM_WORKLOAD_LIST_SUMMARY = CLASS_TEAM_WORKLOAD_LIST + '-summary';

export const getHeaderTemplateProgressData = (): Record<string, THeaderProgress> => {
    const userProgressData = {
        inProgress: {
            title: getMessages().getText('team.workload.in_progress'),
            tooltip: getMessages().getText('team.workload.tooltip.in_progress'),
            color: EProgressColor.purple,
            number: 0
        },
        upcoming: {
            title: getMessages().getText('team.workload.upcoming'),
            tooltip: getMessages().getText('team.workload.tooltip.upcoming'),
            color: EProgressColor.yellow,
            number: 0
        },
        readyToGo: {
            processingStatus: EProcessingStatus.readyToGo,
            title: getMessages().getText('team.workload.readyToGo'),
            tooltip: getMessages().getText('team.workload.tooltip.readyToGo'),
            color: EProgressColor.orange,
            number: 0
        },
        done: {
            title: getMessages().getText('team.workload.done'),
            tooltip: getMessages().getText('team.workload.tooltip.done'),
            color: EProgressColor.green,
            number: 0
        },
        overdue: {
            title: getMessages().getText('team.workload.overdue'),
            tooltip: getMessages().getText('team.workload.tooltip.overdue'),
            color: EProgressColor.red,
            number: 0
        },
        noDates: {
            title: getMessages().getText('team.workload.noDates'),
            tooltip: getMessages().getText('team.workload.tooltip.noDates'),
            color: EProgressColor.grey,
            number: 0
        }
    };
    return userProgressData;
};
