import * as React from 'react';
import { IUserpicStorageProps } from './types';
import { Userpic } from 'kui';
import { msUserPhotoStorage } from 'app/helper/authorisation/microsoft/storage/userPhoto';

export const UserpicStorage = ({
    src,
    userId,
    ...props
}: IUserpicStorageProps) => {

    const [srcState, setSrcState] = React.useState(src);
    React.useEffect(() => {
        if (!src && msUserPhotoStorage.is()) {
            msUserPhotoStorage.getPhoto(userId)
                .then(photo => {
                    setSrcState(photo);
                });
        } else {
            setSrcState(src);
        }
    }, [src]);

    return (
        <Userpic
            {...props}
            src={srcState}
        />
    );
};

UserpicStorage.displayName = 'Userpic'; // для SelectListItem
