import { IGetState, TCardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { authUserResetInWorkCard } from '../../../../../../../../rest/effects/authUser/inWorkCard/authUserResetInWorkCard';
import { authUserSetInWorkCard } from '../../../../../../../../rest/effects/authUser/inWorkCard/authUserSetInWorkCard';
import { SegmentCardEvent, SegmentCardOptions, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { SegmentCardOptionStatusValue } from '../../../../../../../../middlewares/segment/trackEntities/cardEvents';
import { getAuthUser } from '../../../../../../../../store/model/authUser/selectors/getAuthUser';

export const toggleUserSetInWorkCard = (
    cardId: TCardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const user = getAuthUser(state);
        if (user.inWorkCardId === cardId) {
            dispatch(segmentTrackAction(SegmentCardEvent.START_WORKING_CLICKED, {
                name: SegmentCardOptions.STATUS,
                value: SegmentCardOptionStatusValue.OFF}));
            return dispatch(authUserResetInWorkCard());
        } else {
            dispatch(segmentTrackAction(SegmentCardEvent.START_WORKING_CLICKED, {
                name: SegmentCardOptions.STATUS,
                value: SegmentCardOptionStatusValue.ON}));
            return dispatch(authUserSetInWorkCard(cardId));
        }
    };
    return action;
};
