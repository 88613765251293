import { IGetState, TBoardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { exportSpreadsheetWithCheckPerm } from '../effects/exportSpreadsheetWithCheckPerm';
import { IExportListener } from '../../../../../../../../helper/export/types';
import { getBoardExportSettings } from '../../../../../../../../store/model/board/selectors/getBoardExportSettings';

export const onExportSpredsheet = (
    boardId: TBoardId,
    listener: IExportListener
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const settings = getBoardExportSettings(getState(), boardId);
        const withArchive = settings && settings.isIncludeArchive || false;
        dispatch(exportSpreadsheetWithCheckPerm(boardId, withArchive, listener));
    };
    return action;
};
