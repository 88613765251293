'use strict';
import * as _ from 'underscore';
App.Views.SystemExportNotification = App.Views.SystemNotification.extend({
    className: 'notifire notifire--system notifire--export notifire--state-progress',

    initialize: function(options) {
        App.Views.SystemNotification.prototype.initialize.call(this, options);
        this.listenTo(this.model, 'change:process', this.onProgressChange, this);
    },

    onProgressChange: function() {
        this.$el.toggleClass('notifire--state-progress', this.model.get('process') != 'done');
        this.trigger('change', this);
    },

    getSettings: function() {
        return {
            timeout: false
        };
    },

    getActions: function() {        
        var done = '';
        var icon = 'hourglass';
        if (this.model.get('process') == 'done') {
            done = '_done';
            icon = 'mail';
        }        
        return _.extend(
            App.Views.SystemNotification.prototype.getActions.call(this),
            {
                icon: icon,
                system: 'export' + done,                
                process: this.model.get('process')
            }
        )
    },


    onClick: function(e, notification) {
        if (this.model.get('process') == 'done') {
            if($(e.target).closest('.noty_close').length == 0) {
                window.open(this.model.get('docUrl'), '_blank');
            }
            this.closeNotification(notification);
        }
    },

    onContextMenu: function(e, notification) {
        e.preventDefault();
        if (this.model.get('process') == 'done') {
            this.closeNotification(notification);
        }
    },
});
