import { IModelState } from '../../types/types';
import {
    ACTIVE_BOARD_ID_ACTION_SET,
    AT_AUTH_USER_ACTION_SET,
    AT_IN_WORK_CARD_ACTION_SET,
    BACKUPS_ACTION_SET,
    BOARD_USER_ACTIVITY_TIMES_ACTION_SET,
    BOARDS_ACTION_SET,
    CARD_ASSIGNEES_ACTION_SET,
    CARDS_ACTION_SET,
    CHECKLISTS_ACTION_SET,
    DEPENDENCIES_ACTION_SET,
    DRIVE_DOCS_ACTION_SET,
    LISTS_ACTION_SET,
    MODEL_SET,
    MODEL_UPDATE,
    NOTIFICATIONS_SETTINGS_ACTION_SET,
    PUSH_NOTIFICATIONS_SETTINGS_ACTION_SET,
    TABS_ACTION_SET,
    THEMES_ACTION_SET,
    TModelAction,
    TModelUpdate
} from './actions/types';
import { listsReducer } from './lists/reducers/lists';
import { cardsReducer } from './cards/reducers/cards';
import { dependenciesReducer } from './dependencies/dependencies/reducers/dependenciesReducer';
import { boardsReducer } from './boards/reducers/boardsReducer';
import { authUserReducer } from './authUser/reducers/authUserReducer';
import { driveDocsReducer } from './driveDocs/reducers/driveDocsReducer';
import { checklistsReducer } from './checklists/checklists/reducers/checklistsReducer';
import { notificationsSettingsReducer } from './notificationsSettings/reducers/notificationsSettingsReducer';
import {
    pushNotificationsSettingsReducer
} from './pushNotificationsSettings/reducers/pushNotificationsSettingsReducer';
import { libThemesReducer } from './libThemes/reducers/libThemesReducer';
import { backupsReducer } from './backups/reducers/backupsReducer';
import { cardAssigneesReducer } from './cardAssignees/reducers/cardAssignees';
import { tabsReducer } from './tabs/reducers/tabsReducer';
import { ICards } from './cards/types';
import { reformatPinedChecklist } from './card/reducers/card';
import { boardUserActivityTimesReducer } from './boardUserActivityTimes/reducers/userBoardActivityTimes';

const modelInitialState: IModelState = {
    activeDashboardId: 0,
    authUser: null,
    cards: {},
    checklists: {},
    dashboards: {},
    dependencies: {},
    driveDocs: {},
    lists: {},
    tabs: [],
    notificationsSettings: {},
    pushNotificationsSettings: {},
    libThemes: {},
    backups: {},
    cardAssignees: {},
    boardUserActivityTimes: {},
};

export const _modelReducer =
(
    state = modelInitialState,
    action: TModelAction
): IModelState => {
    switch (action.type) {
        case CARDS_ACTION_SET: {
            return {
                ...state,
                cards: cardsReducer(state.cards, action.cardsAction)
            }
        }
        case LISTS_ACTION_SET: {
            return {
                ...state,
                lists: listsReducer(state.lists, action.listsAction)
            }
        }
        case BOARDS_ACTION_SET: {
            return {
                ...state,
                dashboards: boardsReducer(state.dashboards, action.boardsAction)
            }
        }
        case NOTIFICATIONS_SETTINGS_ACTION_SET: {
            return {
                ...state,
                notificationsSettings: notificationsSettingsReducer(state.notificationsSettings, action.notificationsAction)
            }
        }
        case PUSH_NOTIFICATIONS_SETTINGS_ACTION_SET: {
            return {
                ...state,
                pushNotificationsSettings: pushNotificationsSettingsReducer(state.pushNotificationsSettings, action.pushNotificationsAction)
            }
        }
        case BACKUPS_ACTION_SET: {
            return {
                ...state,
                backups: backupsReducer(state.backups, action.backupsAction)
            }
        }
        case THEMES_ACTION_SET: {
            return {
                ...state,
                libThemes: libThemesReducer(state.libThemes, action.themesAction)
            }
        }
        case DEPENDENCIES_ACTION_SET: {
            return {
                ...state,
                dependencies: dependenciesReducer(state.dependencies, action.dependenciesAction)
            }
        }

        case AT_AUTH_USER_ACTION_SET: {
            return {
                ...state,
                authUser: authUserReducer(state.authUser, action.authUserAction)
            }
        }

        case DRIVE_DOCS_ACTION_SET: {
            return {
                ...state,
                driveDocs: driveDocsReducer(state.driveDocs, action.driveDocsAction)
            }
        }

        case CHECKLISTS_ACTION_SET: {
            return {
                ...state,
                checklists: checklistsReducer(state.checklists, action.checklistsAction)
            }
        }

        case CARD_ASSIGNEES_ACTION_SET: {
            return {
                ...state,
                cardAssignees: cardAssigneesReducer(state.cardAssignees, action.cardAssigneesAction)
            }
        }

        case ACTIVE_BOARD_ID_ACTION_SET: {
            return {
                ...state,
                activeDashboardId: action.activeBoardId
            }
        }

        case TABS_ACTION_SET: {
            return {
                ...state,
                tabs: tabsReducer(state.tabs, action.tabsAction)
            }
        }

        case AT_IN_WORK_CARD_ACTION_SET: {
            return {
                ...state,
                inWorkCard: action.inWorkCard
            }
        }

        case BOARD_USER_ACTIVITY_TIMES_ACTION_SET: {
            return {
                ...state,
                boardUserActivityTimes: boardUserActivityTimesReducer(state.boardUserActivityTimes, action.action)
            }
        }

        case MODEL_UPDATE: {
            let newState: IModelState = { ...state };
            reformatState(action.model);
            for (let key in action.model) {
                const modelKey = key as keyof TModelUpdate; // modelKey - dashboards | lists | cards etc.
                const objects = {
                    ...newState[modelKey]
                };
                for (let objectId in action.model[modelKey]) { // objectId - key (id) of object
                    objects[objectId] = {
                        ...(objects[objectId] || {}),
                        ...action.model[modelKey][objectId]
                    }
                }
                newState = {
                    ...newState,
                    [modelKey]: objects
                }
            }
            return newState;
        }

        case MODEL_SET: {
            return {
                ...state,
                ... action.model
            };
        }
        default: return state;
    }
};

const reformatPinedChecklistInCards = (
    cards: ICards
) => {
    for (let cardId in cards) {
        if (cards[cardId].meta) {
            cards[cardId].meta = reformatPinedChecklist(cards[cardId].meta);
        }
    }
    return cards;
}

const reformatState = (
    model: TModelUpdate
) => {
    if (model.cards) {
        reformatPinedChecklistInCards(model.cards);
    }
}

export const modelReducer =
(
    state = modelInitialState,
    action: TModelAction
): IModelState  => {
    // @ts-ignore
    // if (window.Settings.development) {
    //     // @ts-ignore
    //     return deepFreeze(_modelReducer(state, action));
    // } else {
        return _modelReducer(state, action);
    // }
};
