import * as React from 'react';
import { IAsidePanelFooterProps } from './types';
import './_AsidePanelFooter.scss';
import { CLASS_ASIDE_PANEL_FOOTER } from './constants';

export function AsidePanelFooter ({
    footer
}: IAsidePanelFooterProps) {
    return (
        <div className={CLASS_ASIDE_PANEL_FOOTER}>
            {footer}
        </div>
    );
};
