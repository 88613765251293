import { root } from 'app/store/constants';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../../types/types';
import { ISearchSelectOption } from '../../../../../../../base/components/SearchSelect/types';
import { getDrivesByTabKey } from '../../../../../../../dialogs/openBoards/store/drives/selectors/getDrivesByTabKey';
import { TTabKey } from '../../../../../../../dialogs/openBoards/store/types';
import { isAllowCreateBoard } from '../../../../../../../dialogs/openBoards/store/drives/selectors/isAllowCreateBoard';

export const onLoadSharedDrives = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return new Promise((resolve, reject) => {
            root.App.controller.mainView.renderOpenBoardsDialog(() => {
                import(/* webpackChunkName: "openBoards" */ 'app/view/react_components/dialogs/openBoards/effects/loadDrives')
                    .then(module => dispatch(module.loadDrivesFromBack()))
                    .then(() => {
                        let options: ISearchSelectOption[] = [];
                        const drives = getDrivesByTabKey(getState(), TTabKey.sharedDrives);
                        drives.map((drive) => {
                            const { name, id } = drive;
                            if (isAllowCreateBoard(getState(), drive)) {
                                options.push({
                                    text: name,
                                    value: id
                                });
                            }
                        })
                        return resolve(options);
                    })
                    .catch(e => console.error(e))
            });
        });
    };
    return action;
}
