import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../types/types';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';

export const onAddOpen = (
    value: boolean,
    onAddOpen: (value: boolean) => void
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        onAddOpen(value);
        if (value) {
            dispatch(segmentTrackAction(SegmentCardEvent.CHECKLIST_ITEM_ADD));
        }
    };
    return action;
};
