import { connect } from 'react-redux';
import { onClick } from './events/onClick';
import { HelpPanelInviteLinkComponent } from '../../components/HelpPanelInviteLink/HelpPanelInviteLinkComponent';
import { IHelpPanelInviteLinkEvents } from '../../components/HelpPanelInviteLink/types';

const mapDispatchToProps = (
    dispatch: any
): IHelpPanelInviteLinkEvents => {
    return {
        onClick: () => dispatch(onClick())
    }
};

export const HelpPanelInviteLinkHOC = connect(
    null,
    mapDispatchToProps
)(HelpPanelInviteLinkComponent);

HelpPanelInviteLinkHOC.displayName = 'HelpPanelInviteLinkHOC';
