import { Store } from 'redux';
import { IApplicationState, TCardId } from 'app/types/types';
import { CARDS_ACTION_SET, MODEL_UPDATE, TModelAction } from '../../actions/types';
import { AT_CARDS_ADD, AT_CARDS_DELETE, AT_CARDS_UPDATE } from '../../cards/actions/constants';
import { getCard } from '../../selectors/getCard';
import { EMapUpdateType, TIdsMap } from '../types';
import { ICards } from '../../cards/types';
import { Dispatch } from 'app/types/actions';
import { isObjectEmpty } from 'app/view/react_components/helpers/isObjectEmpty';
import { cardsUpdate } from 'app/store/model/actionCreators/cardsUpdate';

const updateSubCardIdsMap = (
    state: IApplicationState,
    cardId: TCardId,
    epicId: TCardId,
    idsMap: TIdsMap,
    addOrRemove: EMapUpdateType
): TIdsMap => {
    if (epicId) {
        const epic = getCard(state, epicId);
        if (epic) {
            if (!idsMap[epicId]) {
                const { subCardIds = [] } = epic;
                idsMap[epicId] = [...subCardIds];
            }
            const index = idsMap[epicId].indexOf(cardId);
            if (addOrRemove === EMapUpdateType.ADD && index === -1) {
                idsMap[epicId].push(cardId);
            }
            if (addOrRemove === EMapUpdateType.REMOVE && index !== -1) {
                idsMap[epicId].splice(index, 1);
            }
        }
    }
    return idsMap;
}

const updateCards = (
    store: Store<IApplicationState>,
    idsMap: TIdsMap
) => {
    if (isObjectEmpty(idsMap))
        return;
    const cards: ICards = {};
    for (let epicId in idsMap) {
        cards[epicId] = {
            subCardIds: idsMap[epicId]
        }
    }
    const dispatch = store.dispatch as Dispatch;
    dispatch(cardsUpdate(cards));
}

export const subCardIdsMiddleware  = (store: Store<IApplicationState>) => (next: any) => (action: TModelAction) => {
    let result = null, nextExecuted = false;
    let idsMap: TIdsMap = {};
    if (action.type === CARDS_ACTION_SET){
        const subAction = action.cardsAction;
        switch (subAction.type) {
            case AT_CARDS_ADD: {
                result = next(action);
                nextExecuted = true;
                for (let cardId in subAction.cards) {
                    const { epicId } = subAction.cards[cardId];
                    if (epicId) {
                        idsMap = updateSubCardIdsMap(store.getState(), Number(cardId), epicId, idsMap, EMapUpdateType.ADD);
                    }
                }
                break;
            }
            case AT_CARDS_DELETE: {
                for (let cardId in subAction.cards) {
                    let { epicId } = subAction.cards[cardId];
                    if (!epicId) {
                        const card = getCard(store.getState(), Number(cardId));
                        epicId = card && card.epicId;
                    }
                    if (epicId) {
                        idsMap = updateSubCardIdsMap(store.getState(), Number(cardId), epicId, idsMap, EMapUpdateType.REMOVE);
                    }
                }
                break;
            }
            case AT_CARDS_UPDATE: {
                for (let cardId in subAction.cards) {
                    const card = subAction.cards[cardId];
                    if ('epicId' in card) {
                        const storeCard = getCard(store.getState(), Number(cardId)) || {};
                        if (storeCard.epicId !== card.epicId) { // поменялся epic
                            // добавляем в новый epic
                            idsMap = updateSubCardIdsMap(store.getState(), Number(cardId), card.epicId, idsMap, EMapUpdateType.ADD);

                            // удаляем из старого epic
                            idsMap = updateSubCardIdsMap(store.getState(), Number(cardId), storeCard.epicId, idsMap, EMapUpdateType.REMOVE);
                        }
                    }
                }
                break;
            }
        }
    } else if (action.type === MODEL_UPDATE) {
        result = next(action);
        nextExecuted = true;
        for (let cardId in action.model.cards) {
            const { epicId } = action.model.cards[cardId];
            if (epicId) {
                idsMap = updateSubCardIdsMap(store.getState(), Number(cardId), epicId, idsMap, EMapUpdateType.ADD);
            }
        }
    }
    updateCards(store, idsMap);
    if (!nextExecuted) {
        result = next(action);
    }
    return result;
}
