export const AUTO_BACKUPS_TITLE =  'Automatic backups';
export const TEXT = `Configure automatic backups of your board.
Set time to create a backup of this board every day.
Please note, the board will be unavailable for collaborators during the backup process.
Usually, it takes no longer than 10 minutes.`;

export const AUTO_BACKUP_BOARD_BLOCKER_TITLE = 'Backup is in progress';
export const AUTO_BACKUP_BOARD_BLOCKER_TEXT = 'Please wait, it may take a few minutes';

export const AUTOBACKUPS_WARNING_DESCRIPTION = 'Automatiс backups were turned off because a user who enabled them previously ' +
    'deleted their account/revoked their permissions from Kanbanchi. Please, enable automatic backups once again.';
