import { Dispatch, ThunkAction } from '../../types/actions';
import { IGetState } from '../../types/types';
import { ICards } from '../../store/model/cards/types';
import { TStatus } from '../../types/model';
import { onChangeProcessingStatus } from '../../rest/effects/statEffects/onChangeProcessingStatus';
import { onDueDateChanged } from '../../rest/effects/statEffects/onDueDateChanged';
import { onDeleteCard } from '../../rest/effects/statEffects/onDeleteCard';

export function sendCardStats (updatedCards: ICards, oldCards: ICards): ThunkAction {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        for(let cardId in updatedCards) {
            if(updatedCards[cardId].processingStatus !== undefined){
                dispatch(onChangeProcessingStatus(parseInt(cardId), updatedCards[cardId], oldCards[cardId]));
            }
            if(updatedCards[cardId].dueDate !== undefined) {
                dispatch(onDueDateChanged(parseInt(cardId), updatedCards[cardId], oldCards[cardId]));
            }
            if(updatedCards[cardId].status !== undefined) {
                if (
                    updatedCards[cardId].status === TStatus.STATUS_DELETED && (
                        !oldCards[cardId] || !oldCards[cardId].status ||
                        oldCards[cardId].status !== TStatus.STATUS_ARCHIVE // при удалении из архива статистику не отправлять
                    )
                ) {
                    updatedCards[cardId].listId = oldCards[cardId].listId; //todo remove when remove backbone KNB-1172 - remove related link -> remove card from store
                    updatedCards[cardId].processingStatus = oldCards[cardId].processingStatus; //todo remove when remove backbone KNB-1172 - remove related link -> remove card from store
                    dispatch(onDeleteCard(parseInt(cardId), updatedCards[cardId]))
                }
            }

        }
    };
    return action;
}
