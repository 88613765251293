import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { EFilterButtonsType } from '../../../store/topBar/types';
import { TBoardId } from '../../../../../../../types/types';
import { topBarSetAction } from '../../../store/topBarBoard/actions/topBarSetAction';
import { lastFiltersSet } from '../../../store/topBar/actions/lastFiltersSet';

export const saveLastUsedFilters = (
    boardId: TBoardId,
    filterType: EFilterButtonsType
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        dispatch(topBarSetAction(boardId, lastFiltersSet(filterType)));
    };
    return action;
};
