import { Dispatch } from '../../../../../../../types/actions';
import { TimeTrackerHelper } from '../../../helpers/TimeTrackerHelper';
import { getTimeTrackerState } from '../../../../../aside_panel/cardDetails/TabTimingSection/TimeTracker/store/selectors/getTimeTrackerState';
import { timeTrackerSetAction } from '../../../../../aside_panel/cardDetails/TabTimingSection/TimeTracker/store/actions/timeTrackerSetAction';
import { IGetState } from '../../../../../../../types/types';
import { isTimeTrackerTracking } from '../../../../../aside_panel/cardDetails/TabTimingSection/TimeTracker/store/selectors/isTimeTrackerTracking';
import { getBoardWorkHoursInDay } from '../../../../../../../store/model/selectors/getBoardWorkHoursInDay';

export const onChangeTime = (timeString: string) => {
    return (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const isTracking = isTimeTrackerTracking(state);
        if (!isTracking) return;

        const {cardId, boardId, cardName, start} = getTimeTrackerState(state);
        const workHoursInDay = getBoardWorkHoursInDay(state, boardId);
        const secondsInString = TimeTrackerHelper.formatStringToSeconds(timeString, workHoursInDay);
        const stopTime = start + secondsInString * 1000;

        dispatch(timeTrackerSetAction({
            cardId,
            cardName,
            stopTime,
            boardId
        }));
    };
};
