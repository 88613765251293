import { PatternQueue } from './PatternQueue';
import { IFetchArgs } from '../../fetchHandler';
import { ERestMethod } from '../../../rest/types';

export class QueueManager {
    constructor () {
        PATTERNS.forEach((patterns) => {
            const patternQueue = new PatternQueue(patterns);
            this.queueList.push(patternQueue);
        })
    }

    queueList: PatternQueue[] = [];

    add = (request: IFetchArgs) => {
        let promise: Promise<Response>;
        let result;
        for (let patternQueue of this.queueList) {
            promise = patternQueue.checkAndAdd(request);
            if(!result) {
                result = promise;
            }
            else if(result && promise) {
                console.error('two pattern queue match with url', request.input instanceof Request ? request.input.url : request.input, patternQueue.patterns)
            }
        }
        return result;
    };
}

// в шаблоне всегда должны быть группа (.*), иначе надо править getGroupMatchRequestPattern
export const PATTERNS: IRequestPattern[][] = [
    [//user
        {
            urlRegExp: /\/rest\/users\/?(.*)\/?/,
            methods: [ERestMethod.POST, ERestMethod.PATCH]
        }
    ],
    [//cards
        {
            urlRegExp:  /\/rest\/dashboards\/lists\/cards\/?(\d*)\/?/,
            methods: [ERestMethod.POST, ERestMethod.PATCH]
        }
    ],
    [
        {
            urlRegExp: /\/rest\/userBoards\/synchronize\/finish.*dashboardId=(\d*)/,
            methods: [ERestMethod.POST, ERestMethod.PATCH]
        },
        {
            urlRegExp: /\/rest\/dashboards\/(\d*)/,
            methods: [ERestMethod.POST, ERestMethod.PATCH]
        },
    ]
  /*  [ //lists
        {
            urlRegExp: new RegExp('/rest/dashboards/lists/(?=cards)'),
            methods: [ERestMethod.POST, ERestMethod.PATCH]
        }
    ]*/
];

export interface IRequestPattern {
    urlRegExp: RegExp,
    methods: ERestMethod[]
}
