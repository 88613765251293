import { Dispatch, ThunkAction } from 'app/types/actions';
import { IGetState, TCardId, TChecklistId, TChecklistItemId } from 'app/types/types';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import { checklistItemUpdate } from 'app/rest/effects/card/checklist/checklistItemUpdate';
import { getChecklistItem } from 'app/store/model/checklists/checklist/selectors/getChecklistItem';
import { cardSetDueDateWithEpics } from 'app/rest/effects/card/card/cardSetDueDateWithEpics';
import { getCard } from 'app/store/model/selectors/getCard';
import {
    getBoardMetaDefaultDueDateTime
} from 'app/store/model/selectors/getBoardMetaDefaultDueDateTime';
import { getBoardIdByCardId } from 'app/store/model/selectors/getBoardIdByCardId';

export const onChangeDueDate = (
    cardId: TCardId,
    checklistId: TChecklistId,
    itemId: TChecklistItemId,
    date: number,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if(date === 0) {
            date = null
        }
        if (date) {
            const state = getState();
            const boardId = getBoardIdByCardId(state, cardId);
            const dueHours = getBoardMetaDefaultDueDateTime(state, boardId).getHours();
            date += dueHours * 3600;
            const { dueDate = null } = getChecklistItem(state, cardId, checklistId, itemId) || {};
            if (dueDate) {
                dispatch(segmentTrackAction(SegmentCardEvent.CHECKLIST_ITEM_DUE_DATE_CHANGED));
            } else {
                dispatch(segmentTrackAction(SegmentCardEvent.CHECKLIST_ITEM_DUE_DATE_ADDED));
            }
        } else {
            dispatch(segmentTrackAction(SegmentCardEvent.CHECKLIST_ITEM_DUE_DATE_REMOVED));
        }

        return dispatch(checklistItemUpdate(cardId, checklistId, itemId, {
            dueDate: date,
        }))
            .then(() => {
                if (date) {
                    const {
                        dueDate = null,
                        isMilestone = false,
                        startDate = null,
                    } = getCard(getState(), cardId) || {};
                    if (
                        isMilestone ||
                        dueDate && dueDate >= date ||
                        startDate && startDate >= date
                    ) return;

                    return dispatch(cardSetDueDateWithEpics(cardId, date));
                }
            });
    };
    return action;
};
