import { Dispatch, ThunkAction } from 'app/types/actions';
import { isAsideOpenSetAction } from 'app/view/react_components/main/navigationPanel/store/navigationPanel/actions/isAsideOpenSetAction';
import { IGetState } from '../../../../../../types/types';
import { addFeatureTrigger } from '../../../../polls/featurePoll/effects/addFeatureTrigger';
import { ERateFeature } from '../../../../../../types/rest/IUserMeta';
import {
    inAppNotificationsEscButtonPressedSegmentEvent,
    inAppNotificationsIconClickedSegmentEvent
} from 'app/view/react_components/main/topBar/effects/segmentEvents';

export function onClose (
    isClick: boolean
): ThunkAction {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        dispatch(addFeatureTrigger(ERateFeature.NOTIFICATIONS));
        dispatch(isAsideOpenSetAction(false));
        if (isClick) {
            dispatch(inAppNotificationsIconClickedSegmentEvent());
        } else {
            dispatch(inAppNotificationsEscButtonPressedSegmentEvent());
        }
        // @ts-ignore
        window.App.controller.notificationsView.hideSeen();
    };
    return action
};
