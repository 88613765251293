import { IApplicationState } from '../../../../types/types'
import { getCard } from '../../selectors/getCard';
import { getCardFullNameHelper } from '../helpers/getCardFullNameHelper';

export const getCardFullName = (
    state: IApplicationState,
    cardId: number
): string => {
    const card = getCard(state, cardId);
    return getCardFullNameHelper(card);
};
