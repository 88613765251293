import { Dispatch, ThunkAction } from '../../../types/actions';
import { TBoardId } from '../../../types/types';
import { boardUpdateMeta } from './boardUpdateMeta';
import { ICardAging } from '../../../types/rest/ICardAging';

export const boardSetCardAgingMeta = (
    boardId: TBoardId,
    cardAging: ICardAging
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        return dispatch(boardUpdateMeta(boardId, { cardAging }));
    };
    return action;
};
