import { TBoardBackupsAction } from '../../boardBackups/actions/types';

export const AT_BOARD_BACKUPS_ACTION_SET = 'BACKUPS_BOARD_BACKUPS_ACTION_SET';

export interface IBoardBackupsActionSetAction {
    type: typeof AT_BOARD_BACKUPS_ACTION_SET,
    boardId: number,
    boardBackupsAction: TBoardBackupsAction
}

export type TBackupsAction = (IBoardBackupsActionSetAction);
