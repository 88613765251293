import { IGetState, TBoardId } from '../../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { EAsidePanelProperty } from '../../../../../../asidePanel/components/AsidePanel/types';
import { toggleCardRequiredProperty } from '../effects/toggleCardRequiredProperty';
import { showSnoozeBlocker } from '../../../../../../../base/effects/showSnoozeBlocker';
import { getAuthUser } from '../../../../../../../../../store/model/authUser/selectors/getAuthUser';

export const onRequiredToggle = (
    boardId: TBoardId,
    cardProperty: EAsidePanelProperty,
    option?: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const isDisallow = !getAuthUser(getState()).allowRequiredProperties;

        if (isDisallow) {
            dispatch(showSnoozeBlocker({
                allow: 'allowRequiredProperties'
            }));
        } else {
            dispatch(toggleCardRequiredProperty(boardId, cardProperty, option));
        }
    };
    return action;
};
