export enum EAffiliateDialogSource {
    ONLOAD = 'ONLOAD',
    HELP = 'HELP'
}

export interface IAffiliateDialogFields {
    source: EAffiliateDialogSource;
    affiliateId: string;
}

export interface IAffiliateDialogEvents {
    onDidMount: () => void;
    onClose: () => void;
    onCopy: (link: string) => void;
    onFaqClicked: () => void;
}

export interface IAffiliateDialogProps extends
    IAffiliateDialogFields,
    IAffiliateDialogEvents
{}
