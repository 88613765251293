import { Dispatch, ThunkAction } from '../../../../types/actions';
import { fetchHandler } from '../../../../util/fetchHandler';
import Util from '../../../../util/util';
import { getRestHeadersGet } from '../../../helpers/getRestHeadersHelper';
import { REST_AUTH_USER } from '../../../constants';
import { IRestAuthUser } from '../../../../types/rest/IRestAuthUser';

export const getRest = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        return fetchHandler<IRestAuthUser>(
            Util.getApiUrl(REST_AUTH_USER + '0'),
            getRestHeadersGet()
        );
    };
    return action;
};
