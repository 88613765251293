import * as React from 'react';
import { IMyWorkBoardsAddProps } from './types';
import { SelectListItem } from 'kui';

export function MyWorkBoardsAdd({
    text,
    value,
    textSub,
    className,
}: IMyWorkBoardsAddProps) {
    return (
        <SelectListItem
            key={value}
            value={value}
            className={className}
            list={textSub}
        >
            {text}
        </SelectListItem>
    );
};
