import { IABTest } from '../../types';
import { IApplicationState } from '../../../types/types';
import { getAuthUser } from '../../../store/model/authUser/selectors/getAuthUser';

export const AB_TEST_UPGRADE_BUTTON_COLOR = 'AB_TEST_UPGRADE_BUTTON_COLOR';

enum ABTestValues {
    RED = 'red',
    PURPLE = 'purple',
    BLUE = 'blue',
    GREEN = 'green'
}

const isRelevantChecker = (
    state: IApplicationState
): boolean => {
    const user = getAuthUser(state);
    const currentDateTimeStamp = new Date().getTime() / 1000;
    // аккаунт создан сегодня
    return (currentDateTimeStamp - user.createDateTimeStamp) <= 24 * 60 * 60;
};

export const getUpgradeButtonColorConfig = (): IABTest => {
    return {
        name: AB_TEST_UPGRADE_BUTTON_COLOR,
        defaultValue: ABTestValues.PURPLE,
        size: 1000,
        groups: [
            {
                weight: 100,
                value: ABTestValues.PURPLE
            },
            {
                weight: 100,
                value: ABTestValues.RED
            },
            {
                weight: 100,
                value: ABTestValues.BLUE
            },
            {
                weight: 100,
                value: ABTestValues.GREEN
            }
        ],
        isRelevant: isRelevantChecker,
        executeOnLoad: true
    }
};
