import { TChecklistId } from '../../../../../../../../types/types';
import { IApplicationState, TCardId } from '../../../../../../../../types/types';
import { createDeepEqualSelector } from '../../../../../../helpers/memoizeHelper';
import { IChecklist } from '../../../../../../../../store/model/checklists/checklist/types';
import { getChecklist } from '../../../../../../../../store/model/checklists/checklists/selectors/getChecklist';

type TgetChecklistSection = (
    state: IApplicationState,
    cardId: TCardId,
    checklistId: TChecklistId,
) => IChecklist;

const getChecklistSectionSelector = (
    checklist: IChecklist,
): IChecklist => {
    return {
        ...checklist,
        checkItems: null,
    };
};

export const getChecklistSection = (
): TgetChecklistSection => createDeepEqualSelector(
    getChecklist,
    getChecklistSectionSelector,
);
