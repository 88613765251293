import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState } from '../../../../../../../types/types';
import { segmentTrackAction, SegmentUserEvent } from '../../../../../../../middlewares/segment';
import { SegmentQuestionEvent } from '../../../../../../../middlewares/segment/trackEntities/questionEvents';

export const onAddOpinion = (): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(SegmentUserEvent.PRICING_POLL_OWN_OPINION_CLICK));
    };
    return action;
};
