import { IUserBoardActivityTimes } from '../types';
import { AT_SET_ACTION } from '../actions/constants';
import { IUserBoardActivityTimesAction } from '../actions/types';

const initialState: IUserBoardActivityTimes = {};

export const boardUserActivityTimesReducer = (
    state = initialState,
    action: IUserBoardActivityTimesAction
): IUserBoardActivityTimes  => {
    switch (action.type) {
        case AT_SET_ACTION: {
            const { items } = action;
            return {
                ...state,
                ...items
            }
        }
        default: {
            return state;
        }
    }
};
