import { E12HoursType } from '../../DatepickerItem/types';
import { TWELVE_HOURS } from '../../constants';
import { TIME_OPTIONS_MINUTES_STEP } from '../constants';
import { getTwoDigitValue } from '../../../helpers/getTwoDigitValue';
import { ITimeOption } from '../types';

export const getTimeOptions = (
    date: Date,
    hoursType: E12HoursType,
    minutes?: boolean
): ITimeOption[] => {
    const timeOptions: ITimeOption[] = [];
    const timeOptionsLength = hoursType ? 12 : 24;

    let initialHours = date.getHours();
    const initialMinutes = date.getMinutes();
    if (hoursType && hoursType === E12HoursType.PM) {
        initialHours -= TWELVE_HOURS;
    }
    let isSelectedAdded = false;

    for (let i = 0; i < timeOptionsLength; i++) {
        for (let j = 0; j < 4; j++) {
            const newDate = new Date(date);
            newDate.setHours(i, TIME_OPTIONS_MINUTES_STEP * j);
            const newHours = newDate.getHours();
            const newMinutes = newDate.getMinutes();
            if (j && (!minutes || newHours !== initialHours)) {
                if (
                    !isSelectedAdded &&
                    !minutes &&
                    initialHours === i &&
                    initialMinutes !== newMinutes
                ) {
                    timeOptions.push({
                        value: date,
                        text: getTwoDigitValue(initialHours) + ':' + getTwoDigitValue(initialMinutes)
                    });
                    isSelectedAdded = true;
                }
                continue;
            }
            if (
                initialHours !== newHours ||
                (initialHours === newHours && initialMinutes !== newMinutes)
            ) {
                timeOptions.push({
                    value: newDate,
                    text: getTwoDigitValue(newHours) + ':' + getTwoDigitValue(newDate.getMinutes())
                });
            }
            if (
                minutes &&
                initialHours === i &&
                initialMinutes >= TIME_OPTIONS_MINUTES_STEP * j &&
                initialMinutes < TIME_OPTIONS_MINUTES_STEP * (j + 1)
            ) {
                timeOptions.push({
                    value: date,
                    text: getTwoDigitValue(initialHours) + ':' + getTwoDigitValue(initialMinutes)
                });
            }
        }
    }

    return timeOptions;
}
