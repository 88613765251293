import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { IGetState, TBoardId } from '../../../../../../../../../types/types';
import { getCardNumberByBoardId } from '../../../store/cardNumbers/selectors/getCardNumberByBoardId';
import { SegmentBoardOptionCardPrefixesTypeValue } from '../../../../../../../../../middlewares/segment/trackEntities/boardEvents';
import { saveClickedSegmentEvent, valueChangedSegmentEvent } from '../../effects/segmentEvents';

export const sendOnSaveSegmentEvents = (
    boardId: TBoardId
): ThunkAction => {
    const action = (dispatch: Dispatch, getState: IGetState) => {
        const { prefix, nextValue, initialValue } = getCardNumberByBoardId(getState(), boardId);
        dispatch(saveClickedSegmentEvent());
        if (initialValue && initialValue.prefix !== prefix) {
            dispatch(valueChangedSegmentEvent(SegmentBoardOptionCardPrefixesTypeValue.PREFIX))
        }
        if (initialValue && initialValue.nextValue !== nextValue) {
            dispatch(valueChangedSegmentEvent(SegmentBoardOptionCardPrefixesTypeValue.NUMBER))
        }
    }
    return action;
}
