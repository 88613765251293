import './_CardDescription.scss';
import { ICardDescriptionProps } from './types';
import { Tooltip } from 'kui';
import { blockMarkdown } from '../../../../../../helper/markdownHelper/index';
import * as React from 'react';
import { CARD_CLASS } from '../../constants';
import ReactHtmlParser from 'react-html-parser';
import { msFileThumbnailStorage } from '../../../../../../helper/authorisation/microsoft/storage/msThumbnail';

export const CardDescription = ({
    description
}: ICardDescriptionProps) => {
    const className = CARD_CLASS + '__description';
    const classTooltip = className + '-tooltip';

    const [markdown, setMarkdown] = React.useState('');
    const [isTruncated, setTruncated] = React.useState(false);
    const ref = React.useRef(null);

    React.useEffect(() => {
        setMarkdown(blockMarkdown(description));

        const el = ref.current as HTMLElement;
        if (el && el.offsetHeight < el.scrollHeight) {
            setTruncated(true);
        }

        if (description && msFileThumbnailStorage.is()) {
            msFileThumbnailStorage.getSrcThumbnails(description)
                .then((text) => {
                    setMarkdown(blockMarkdown(text));
                })
                .catch(() => {})
        }
    }, [description]);

    return (
        <Tooltip
            className={classTooltip}
            value={isTruncated && markdown}
            direction={'down'}
        >
            <div
                ref={ref}
                className={className}
            >
                {ReactHtmlParser(markdown)}
            </div>
        </Tooltip>
    );
}
