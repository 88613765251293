'use strict';

App.Views.CardDeleteNotification = App.Views.BaseNotification.extend({

    getActions: function() {
        return {
            action: Messages.getText('noty.card_delete'),
            actionData: {
                icon: 'delete'
            }
        };
    },

    onClick: function(e, notification) {
        this.closeNotification(notification);
        e.stopPropagation();
    },

    plateClick: function(e) {
        this.setSeen();
        e.stopPropagation();
    }
});
