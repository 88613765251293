import * as React from 'react';
import './_CardMoveToButton.scss';
import { SettingsSection } from '../../../../../base/components/SettingsSection/SettingsSection';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { AsidePanelActionsButton } from '../../../..';
import { ICardMoveToButtonProps } from './types';
import { CLASS_CARD_DETAILS } from '../../../constants';
import { CardMoveToHOC } from '../../hocs/CardMoveToHOC/CardMoveToHOC';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { CLASS_ASIDE_PANEL_ACTIONS_BUTTON, CLASS_ASIDE_PANEL_ACTIONS_POPUP } from '../../../../asidePanel/components/AsidePanelActionsButton/constants';
import { EAsidePanelProperty } from '../../../../asidePanel/components/AsidePanel/types';
import { MOVE_TO_BUTTON_TITLE, MOVE_TO_SECTION_TITLE } from './constants';
import { AnalyzeRender } from '../../../../../helpers/memoizeHelper';
import { getAsidePanelActionsButtonTooltip } from 'app/view/react_components/aside_panel/asidePanel/helpers/getAsidePanelActionsButtonTooltip';

//@ts-ignore
const analyzeRender = window.Settings.development ? new AnalyzeRender(`CardMoveToButton`) : null;

export function CardMoveToButton({
    isShow,
    tooltip,
    className,
    onOpened
}: ICardMoveToButtonProps) {
    if (analyzeRender) analyzeRender.call(`CardMoveToButton`);

    if (!isShow) return null;
    const classNameButtonMoveTo = CLASS_ASIDE_PANEL_ACTIONS_BUTTON + '--move-to';
    const { cardId, boardId } = React.useContext(AsidePanelContext);
    const [isOpenedHook, setIsOpened] = React.useState(null);

    const onOpen = () => {
        setIsOpened(true);
        if (!isOpenedHook) {
            onOpened();
        }
    }
    const onClose = () => setIsOpened(false);

    const classNameMoveTo = CLASS_CARD_DETAILS + '__move-to';

    return (
        <AsidePanelActionsButton
            dropdownClassName={`${CLASS_ASIDE_PANEL_ACTIONS_POPUP}--moveto`}
            className={`
                ${classNameButtonMoveTo}
                ${className}
            `}
            title={MOVE_TO_BUTTON_TITLE}
            tooltip={tooltip && getAsidePanelActionsButtonTooltip({ value: tooltip, direction: 'down' })}
            icon={'move-to'}
            isOpened={isOpenedHook}
            openedProperty={EAsidePanelProperty.CARD_MOVETO}
            onClose={onClose}
            onOpen={onOpen}
        >
            <SettingsSection
                className={classNameMoveTo}
                title={MOVE_TO_SECTION_TITLE}
                sectionType={ESettingsSectionType.POPUP}
                isOpened={true}
                onAddClosed={onClose}
            >
                <CardMoveToHOC
                    cardId={cardId}
                    boardId = {boardId}
                    onClose={onClose}
                />
            </SettingsSection>
        </AsidePanelActionsButton>
    )
}
