import { getRestHeadersPatch } from './../../../../rest/helpers/getRestHeadersHelper';
import { ThunkAction, Dispatch } from '../../../../types/actions';
import { fetchHandler } from '../../../../util/fetchHandler';
import Util from '../../../../util/util';
import { REST_HINTS, getHintConfig } from '../constants';
import { THintKey, IRestHint } from '../types';
import { IGetState } from '../../../../types/types';
import { getHint } from '../store/hints/selectors/getHint';

export const hintSave = (
    key: THintKey
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const hintConf = getHintConfig(key);
        if (!hintConf) return Promise.reject();

        const body: IRestHint = {
            ...getHint(state, key),
            key
        };
        if (hintConf.to) {
            body.ttl = hintConf.to;
        }

        return fetchHandler(
            Util.getApiUrl(REST_HINTS), {
                ...getRestHeadersPatch(),
                body: JSON.stringify([body])
            }
        );
    };
    return action;
};
