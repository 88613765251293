import { IGetState, TCardId, TPermissionId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { cardsActionSetAction } from '../../../../store/model/actions/cardsActionSetAction';
import { cardActionSetAction } from '../../../../store/model/cards/actions/cardActionSetAction';
import { inWorkUsersUpdateAction } from '../../../../store/model/card/actions/inWorkUsersUpdateAction';
import { sendRealTimeStoreActionByCardId } from '../../../../view/react_components/base/helpers/realTimeHelperTS';

export const cardSetInWorkUser = (
    permissionId: TPermissionId,
    cardId: TCardId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const action = cardsActionSetAction(cardActionSetAction(cardId, inWorkUsersUpdateAction(permissionId, true)));
        dispatch(action);
        dispatch(sendRealTimeStoreActionByCardId(cardId, action));
    };
    return action;
};
