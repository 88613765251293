import {IGetState, TBoardId} from '../../../../../../../../../types/types';
import {Dispatch, ThunkAction} from '../../../../../../../../../types/actions';
import {EPwcImportTypes} from '../types';
import {importPwc} from '../effects/importPwc';
import {onTemplatePwcImportSelect} from 'app/view/react_components/aside_panel/boardDetails/details/PwcImport/hocs/BoardPwcImportHOC/events/onTemplatePwcImportSelect';

export const onSelect = (
    boardId: TBoardId,
    importType: EPwcImportTypes
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if(importType === EPwcImportTypes.TEMPLATES_CHECKLISTS) {
            dispatch(onTemplatePwcImportSelect(boardId));
        } else {
            dispatch(importPwc(boardId, importType));
        }
    };
    return action;
};
