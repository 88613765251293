import * as React from 'react';
import { CLASS_MY_WORK_CARD, CLASS_MY_WORK_CARD_CONTROL } from '../../../constants';
import './_CardBoard.scss';
import { clearMarkdown } from '../../../../../base/helpers/clearMarkdownHelper';
import { Button, Tooltip } from 'kui';
import { escape } from 'underscore';
import { TOOLTIP_BOARD } from '../constants';
import { ICardBoardProps } from './types';
import ReactHtmlParser from 'react-html-parser';

export function CardBoard({
    boardName,
    onBoardClick
}: ICardBoardProps) {
    const className = CLASS_MY_WORK_CARD + '__board-name';

    const [name, setName] = React.useState('');
    React.useEffect(() => {
        setName(escape(clearMarkdown(boardName)));
    }, [boardName]);

    return (
        <div className={className}>
            <Tooltip
                value={TOOLTIP_BOARD + name}
                isNoEvents
                delayClose={1}
            >
                <Button
                    className={`${className + '-link'} ${CLASS_MY_WORK_CARD_CONTROL}`}
                    variant={'icon-text'}
                    onClick={onBoardClick}
                >
                    {ReactHtmlParser(name)}
                </Button>
            </Tooltip>
        </div>
    )
}
