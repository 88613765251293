'use strict';

import * as React from 'react';
import { renderToStaticMarkup } from "react-dom/server";
import { getAppState } from '../../store/configureStore';
import { getUserTimeFormat12 } from '../../store/model/authUser/selectors/getUserTimeFormat12';
import { CLASS_CARD_ACTIVITY_ITEM_ADD_VALUE, CLASS_CARD_ACTIVITY_ITEM_REMOVE_VALUE } from '../react_components/aside_panel/boardDetails/activity/TabActivity/components/ActivityItemBody/constants';
import { getActivityItem } from "../react_components/aside_panel/boardDetails/activity/TabActivity/helpers/aсtivityHelper";
import { Icon } from 'kui';
import { ERestActivityPropertyName } from '../../types/rest/activity/IRestActivityBase';
import { getDateFormatted } from '../react_components/helpers/dateService';

App.Views.CardUpdateDateNotification = App.Views.BaseNotification.extend({

    getActions: function() {
        const item = getActivityItem(this.notificationModel);
        const { oldValue, newValue, propertyName } = this.notificationModel;
        const icon = propertyName === ERestActivityPropertyName.START_DATE
            ? 'start-date' : 'due-date';
        const isUserTimeFormat12 = getUserTimeFormat12(getAppState());
        return {
            bodyRaw: renderToStaticMarkup(<>
                {!!oldValue &&
                    <span className={CLASS_CARD_ACTIVITY_ITEM_REMOVE_VALUE}>
                        <Icon xlink={ icon } size={ 16 }/>
                        { getDateFormatted(parseInt(oldValue), isUserTimeFormat12) }
                    </span>
                }
                {!!newValue &&
                    <span className={CLASS_CARD_ACTIVITY_ITEM_ADD_VALUE}>
                        <Icon xlink={ icon } size={ 16 }/>
                        { getDateFormatted(parseInt(newValue), isUserTimeFormat12) }
                    </span>
                }
            </>),
            action: item.label,
        };
    },

    onClick: function(e, notification) {
        var dashboardId = this.notificationModel.dashboardId;
        var cardId = this.notificationModel.card.id;
        App.controller.cleverOpenCard(dashboardId, cardId);
        this.closeNotification(notification);
        e.stopPropagation();
    }
});
