export enum ECardProgressSize {
    BASE = 'base',
    LARGE = 'large'
}

export interface ICardProgressFields {
    backgroundColor?: string;
    canDone?: boolean;
    isLightBgStroke?: boolean;
    isReadonly?: boolean;
    progress: number;
    size: ECardProgressSize;
    tooltip: string;
}

export interface ICardProgressEvents {
    onClick?: () => void;
}

export interface ICardProgressProps extends
    ICardProgressFields,
    ICardProgressEvents
{}
