import { IGetState, TCardId } from '../../../types/types';
import { getCardIsLoading } from '../../../store/requestsState/selectors/getCardIsLoading';
import { removeAsidePanelPortal } from '../../../helper/removeAsidePanelPortal';

export const waitUntilCardIsOpenedAndLoaded = (
    getState: IGetState,
    cardId: TCardId,
    func: () => void
) => {
    const openCard = () => {
        const isLoading = getCardIsLoading(getState(), cardId);

        if (!isLoading) {
           func();
        } else {
            setTimeout(() => openCard(), 0);
        }
    }

    setTimeout(() => openCard(), 0); // wait until asidePanel is closed
}
