import { IGetState, TBoardId } from '../../../types/types';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { boardPatchRest } from './api/helpers/boardPatchRest';
import { IDashboardStatusLists } from '../../../types/rest/IRestDashboard';

export const boardSetStatusLists = (
    boardId: TBoardId,
    statusLists: IDashboardStatusLists
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(boardPatchRest(boardId, {statusLists}));
    };
    return action;
}
