import { IApplicationState } from '../../../../../../../types/types';
import { getUserRateFeaturePoll } from '../../../../../../../store/model/authUser/selectors/getUserRateFeaturePoll';
import { getAuthUserFeaturePoll } from '../../../../../../../store/model/authUser/selectors/getAuthUserFeaturePoll';

export const getShowFeaturePoll = (state: IApplicationState): boolean => {
    const { answeredFeaturePolls = [], triggeredFeatureId, skipDate } = getUserRateFeaturePoll(state);
    const currentFeaturePoll = getAuthUserFeaturePoll(state);
    let isShow = null;

    // проверяем включен ли опрос
    const isFeaturePollEnabled = currentFeaturePoll && currentFeaturePoll.isEnabled && // включенно в адимин консоле
        currentFeaturePoll.requiredUsersCount > currentFeaturePoll.actualUsersCount; // еще нужны юзеры
    if (!isFeaturePollEnabled) return isShow;

    // проверяем показывать ли именно этому юзеру опрос
    isShow = triggeredFeatureId && triggeredFeatureId === currentFeaturePoll.id && // текущий опрос совпадает с тригером
        !answeredFeaturePolls.includes(triggeredFeatureId); // юзер еще не проходил его
    if (!isShow) return isShow;

    const oneDay = 1000 * 60 * 60 * 24;
    // проверяем не скипал ли юзер опрос
    isShow = !skipDate || skipDate && (Date.now() - skipDate > oneDay * 2);

    return isShow;
}
