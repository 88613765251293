import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { IGetState, TBoardId } from '../../../../../../../../../types/types';
import { EMyWorkCardFetchOption } from '../../../../../../../../../types/rest/IUserMeta';
import { updateMyWorkMeta } from '../../../../../../../../../rest/effects/authUser/myWork/updateMyWorkMeta';
import { getMyWorkBoardsFetchCardsOptions } from '../../../../../selectors/getMyWorkBoardsFetchCardsOptions';

export const onFetchChange = (
    boardId: TBoardId,
    option: EMyWorkCardFetchOption
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const boardsFetchCards = {
            ...getMyWorkBoardsFetchCardsOptions(getState()),
            [boardId]: option
        };
        dispatch(updateMyWorkMeta({ boardsFetchCards }));
    };
    return action;
};
