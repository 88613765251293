import * as React from 'react';
import { Icon } from 'kui';
import {
    HELP_PANEL_LINKS_VIDEO,
} from '../../constants';
import { IHelpPanelVideoLinkProps } from './types';

export const HelpPanelVideoLinkComponent: React.FC<IHelpPanelVideoLinkProps> = (props) => {

    const onClick = (e: React.SyntheticEvent) => {
        e.preventDefault();
        props.onClick();
    };

    return (
        <span className={'help-panel__link'}><a onClick={onClick} href={'https://www.youtube.com/user/kanbanchi'} target="_blank"><Icon xlink="youtube" size={24} /> {HELP_PANEL_LINKS_VIDEO}</a></span>
    );
};
