import { getBoardCards } from '../../../../../../store/model/selectors/getBoardCards';
import { getBoardIdByCardId } from '../../../../../../store/model/selectors/getBoardIdByCardId';
import { getCardListName } from '../../../../../../store/model/selectors/getCardListName';
import { getCardIsEpic } from '../../../../../../store/model/selectors/getCardIsEpic';
import { IApplicationState, TCardId } from '../../../../../../types/types';
import { ICard } from '../../../../../../store/model/card/types';
import { IDependencyAddProps } from '../components/DependencyAdd/types';
import { getCardIcon } from '../../../../helpers/getCardIcon';
import { isGivenCardIsPredecessorForMe } from './isGivenCardIsPredecessorForMe';
import { getCardFullNameHelper } from '../../../../../../store/model/card/helpers/getCardFullNameHelper';
import { ISubCardsItem } from '../../SubcardsSection/hocs/CardSubcardsSectionHOC/types';
import { createSelector } from 'reselect';
import { markdown } from '../../../../../../helper/markdownHelper';
import { stripHtml } from '../../../../base/helpers/stripHtmlHelper';

type TgetAvailableDependencies = (
    state: IApplicationState,
    cardId: TCardId,
) => ISubCardsItem[];

const getBoardCardsSelector = (
    state: IApplicationState,
    cardId: TCardId,
): ICard[] => {
    const boardId = getBoardIdByCardId(state, cardId);
    if (!boardId) return [];

    const cards = getBoardCards(state, boardId); // только активные
    return cards;
}

const getAvailableDependenciesSelector = (
    cards: ICard[],
    state: IApplicationState,
    cardId: TCardId,
): IDependencyAddProps[] => {
    const filteredCards = cards.filter((targetCard) => {
        const isTheSameCard = cardId === targetCard.id;
        const isGivenCardIsPredecessorForCurrentCard = isGivenCardIsPredecessorForMe(state, cardId, targetCard.id);
        const result = (
            !isTheSameCard &&
            !isGivenCardIsPredecessorForCurrentCard
        );
        return result;
    });

    return filteredCards.map((card) => {
        const isEpic = getCardIsEpic(state, card.id);
        const icon = getCardIcon(card.status, isEpic);
        const cardFullName = getCardFullNameHelper(card);
        const listName = getCardListName(state, card.id);
        return {
            value: String(card.id),
            text: stripHtml(markdown(cardFullName)),
            textSub: stripHtml(markdown(listName)),
            img: icon
        };
    });
};

export const getAvailableDependenciesCreateSelector = (
): TgetAvailableDependencies => createSelector([
        getBoardCardsSelector,
        (state: IApplicationState, cardId: TCardId) => state,
        (state: IApplicationState, cardId: TCardId) => cardId,
    ],
    getAvailableDependenciesSelector,
);

export const getAvailableDependencies: TgetAvailableDependencies = getAvailableDependenciesCreateSelector();
