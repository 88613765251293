import { IApplicationState, IGetState, TCardId } from '../../../../types/types';
import { ICardCopyRestOptions, ICardCopyRestOrderNumbersMap, ICardIdMap, ICardsCopyRestResult } from './api/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { ICard } from '../../../../store/model/card/types';
import { getCard } from '../../../../store/model/selectors/getCard';
import { getCardPredecessorsDependencies } from '../../../../store/model/dependencies/dependencies/selectors/getCardPredecessorsDependencies';
import { IDependency } from '../../../../store/model/dependencies/dependency/types';
import { ICards } from '../../../../store/model/cards/types';
import { cardsAdd } from '../../../../store/model/actionCreators/cardsAdd';
import { copyCardsRest } from './api/copyCardsRest';
import { sendCardStatWithIncrement } from '../../../../helper/comet/stat_helper_ts';
import { IRestPredecessor } from '../../../../types/rest/IRestPredecessor';
import { addPredecessorList } from '../dependence/addPredecessorList';
import { getCardSubcardsTree } from '../../../../store/model/selectors/getCardSubcardsTree';
import { getList } from '../../../../store/model/list/selectors/getList';
import { sendRealTimeStoreActionByCardId } from '../../../../view/react_components/base/helpers/realTimeHelperTS';
import { getNextCardOrderNumber } from '../../../../store/model/list/selectors/getNextCardOrderNumber';
import { ORDER_STEP } from '../../../../const';
import { listUpdateMinMaxOrderNumber } from '../../list/listUpdateMinMaxOrderNumber';
import { getCardSubcardsTreeWithOrder } from '../../../../store/model/selectors/getCardSubcardsTreeWithOrder';

const HANDLE_CARDS_PER_REQUEST = 30;

export const cardsCopy = (
    cardId: TCardId,
    sendStatistic: boolean = true,
    options: ICardCopyRestOptions = {}
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const cardsCopyQueue = getCardSubcardsTreeWithOrder(state, cardId);
        cardsCopyQueue.unshift(getCard(state, cardId));
        return copyCards(dispatch, state, cardsCopyQueue, sendStatistic, options).then((oldNewIdCardMap: ICardIdMap) => {
            return copyDependences(dispatch, getState(), cardsCopyQueue, oldNewIdCardMap).then(() => {
                return oldNewIdCardMap[cardId];
            })
        });
    };
    return action;
};

const copyCards = (
    dispatch: Dispatch,
    state: IApplicationState,
    cards: ICard[],
    sendStatistic: boolean,
    options: ICardCopyRestOptions
) => {
    let cardsIdMap: ICardIdMap = {};
    const orderNumbersMap: ICardCopyRestOrderNumbersMap = {};
    const listMaxOrderNumbers: {[listId: number]: number} = {};
    cards.forEach((card) => {
        const listId = card.listId;
        if (listId in listMaxOrderNumbers) {
            listMaxOrderNumbers[listId] += ORDER_STEP;
        } else {
            listMaxOrderNumbers[listId] = getNextCardOrderNumber(state, listId, false);
        }
        orderNumbersMap[card.id] = listMaxOrderNumbers[listId];
    })
    const cardIdList: TCardId[] = cards.map((card) => card.id);
    const statListIdIncMap = new Map<number, number>();
    return callRequestByParts<TCardId>(cardIdList, HANDLE_CARDS_PER_REQUEST, (cardIdList: TCardId[]) => {
        return copyCardsPart(dispatch, cardIdList, cardsIdMap, statListIdIncMap, orderNumbersMap, options).then((respCardsIdMap: ICardIdMap) => {
            cardsIdMap = {...cardsIdMap, ...respCardsIdMap};
        })
    }).then(() => {
        if (sendStatistic) {
            sendCardsStatistic(dispatch, state, statListIdIncMap);
        }
        for (let listId in listMaxOrderNumbers) {
            dispatch(listUpdateMinMaxOrderNumber(Number(listId), [listMaxOrderNumbers[listId]]));
        }
        return Promise.resolve(cardsIdMap);
    })
}

const sendCardsStatistic = (
    dispatch: Dispatch,
    state: IApplicationState,
    statListIdIncMap: Map<number, number>
) => {
    for(const listId of statListIdIncMap.keys()) {
        const count = statListIdIncMap.get(listId);
        const list = getList(state, listId);
        if (list) {
            dispatch(sendCardStatWithIncrement(list, count, 0));
        }
    }
}

const copyCardsPart = (
    dispatch: Dispatch,
    cardIdList: TCardId[],
    cardsIdMap: ICardIdMap,
    statListIdIncMap: Map<number, number>,
    orderNumbersMap: ICardCopyRestOrderNumbersMap,
    options: ICardCopyRestOptions
) => {
    return dispatch(copyCardsRest(cardIdList, cardsIdMap, orderNumbersMap, options)).then((result: ICardsCopyRestResult) => {
        const cards: ICards = {};
        result.cards.forEach((card) => {
            cards[card.id] = card;
            if (card.listId && !statListIdIncMap.has(card.listId)) {
                statListIdIncMap.set(card.listId, 0);
            }
            statListIdIncMap.set(card.listId, statListIdIncMap.get(card.listId) + 1);
        });
        dispatch(cardsAdd(cards));
        dispatch(sendRealTimeStoreActionByCardId(cardIdList[0], cardsAdd(cards)));
        return result.cardsIdMap;
    });
}

const copyDependences = (
    dispatch: Dispatch,
    state: IApplicationState,
    cards: ICard[],
    oldNewIdCardMap: ICardIdMap
) => {
    const requests: IRestPredecessor[] = getCardCopyDependencesRequests(state, cards, oldNewIdCardMap);
    return callRequestByParts<IRestPredecessor>(requests, HANDLE_CARDS_PER_REQUEST, (predecessors: IRestPredecessor[]) => {
        return dispatch(addPredecessorList(predecessors));
    });
}

const getCardCopyDependencesRequests = (
    state: IApplicationState,
    cards: ICard[],
    oldNewIdCardMap: ICardIdMap
): IRestPredecessor[] => {
    const result: IRestPredecessor[] = [];
    cards.forEach((card) => {
        const copyCardId = oldNewIdCardMap[card.id];
        if (!copyCardId) return;
        const depList: IDependency[] = getCardPredecessorsDependencies(state, card.id);
        if (depList) {
            depList.forEach((dep) => {
                const copyPredecessorId = oldNewIdCardMap[dep.predecessorId];
                if (copyPredecessorId) {
                    result.push({
                        cardId: copyCardId,
                        predecessorId: copyPredecessorId
                    });
                }
            })
        }
    })
    return result;
}

const callRequestByParts = <T>(
    dataList: T[],
    partSize: number,
    onCallPart: (part: T[]) => Promise<any>
) => {
    let pos = 0;
    const requestParts: T[][] = [];
    while(pos < dataList.length) {
        requestParts.push(dataList.slice(pos, pos + partSize));
        pos += partSize;
    }
    return new Promise<void>((resolve, reject) => {
        let lastPromise: Promise<any> = null;
        requestParts.reduce((p, part) => {
                lastPromise = p.then(() => {
                    return onCallPart(part);
                });
                return lastPromise;
            }, Promise.resolve()
        )
        if (lastPromise) {
            lastPromise.then(() => {
                resolve();
            })
        } else {
            resolve();
        }
    });
}
