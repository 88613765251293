import {REST_CARD, REST_CARDS} from '../../../../constants';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { IGetState } from '../../../../../types/types';
import { ICard } from '../../../../../store/model/card/types';
import { getRestHeadersPatch } from '../../../../helpers/getRestHeadersHelper';
import { PartUploader } from '../../../../../util/part_uploader';
import { ICards } from '../../../../../store/model/cards/types';
import { PORTION_SIZE } from '../../../../../const';

export const cardsPatchRest = (
    cards: ICards,
    generateCardNumber: boolean = true,
    disableHistory: boolean = true
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const cardsToSend: ICard[] = [];
        for (let cardId in cards) {
            const card = {
                id: Number(cardId),
                ...cards[Number(cardId)]
            }
            cardsToSend.push(card);
        }
        let url = REST_CARDS;
        if(generateCardNumber) {
            url = REST_CARDS + '?generateCardNumber=' + generateCardNumber + '&disableHistory=' + disableHistory;
        } else {
            url = url + '?disableHistory=' + disableHistory;
        }
        const partUploader = new PartUploader(
            url,
            reformatPinedChecklist(cardsToSend),
            PORTION_SIZE,
            getRestHeadersPatch()
        );
        return partUploader.uploadPart();
    };
    return action;
};

const reformatPinedChecklist = (
    cards: ICard[]
) => {
    return cards.map(card => {
        if (card.meta && card.meta.pinnedSections && card.meta.pinnedSections.checklists) {
            // @ts-ignore Да, мы знаем что тут несоответствие типов, но на бек надо слать строки а не намберы
            card.meta.pinnedSections.checklists = card.meta.pinnedSections.checklists.map(id => id.toString())
        }
        return card;
    })
}
