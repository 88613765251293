import { getActiveCardId } from '../../../../../../store/router/selectors/getActiveCardId';
import { Dispatch, ThunkAction } from '../../../../../../types/actions';
import { IGetState } from '../../../../../../types/types';
import { activityIdSetAction } from '../../../asidePanel/store/actions/activityIdSetAction';

export const showForceNotifications = (
    response: any
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (!response || !response.headers || !response.headers.get('card-model-histories')) return;

        const cardId = getActiveCardId(getState());
        if (!cardId) return; // card panel is not opened

        const cards = JSON.parse(response.headers.get('card-model-histories'));
        const activities = cards[cardId];
        if (!activities || !activities.length) return;

        dispatch(activityIdSetAction(activities[activities.length - 1]));
    };
    return action;
};
