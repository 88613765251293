import { Dispatch, ThunkAction } from '../../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../../types/types';
import { ICards } from '../../../../../../store/model/cards/types';
import { IRestCard } from '../../../../../../types/rest/IRestCard';
import { CardsLoader } from '../../../../../../helper/loader/cardsLoader';
import { cardsUpdate } from 'app/store/model/actionCreators/cardsUpdate';

export const cardsGetRest = (
    ids: Array<TCardId>
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (!ids) Promise.reject();

        return new CardsLoader(ids).load()
            .then((cardsFromResponses: Array<IRestCard>) => {
                const cards = cardsFromResponses.reduce((map: ICards, card) =>{
                    map[card.id] = card;
                    return map;
                }, {});
                dispatch(cardsUpdate(cards));
                return cards;
            });
    };
    return action;
};
