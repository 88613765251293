import { errorHandler, graphClient } from '../graph/Graph';

type TUserPhotos = {
    [id: string]: Promise<string>;
};

class MSUserPhoto {
    photos: TUserPhotos;

    init (
    ) {
        this.photos = {};
    }

    is (
    ) {
        return !!this.photos;
    }

    getPhoto (
        id: string,
    ): Promise<string> {
        if (!id) return Promise.reject();
        if (id in this.photos) return this.photos[id];

        try {
            let request = graphClient.api(`/users/${id}/photos/64x64/$value`);

            this.photos[id] = new Promise<string>((resolve) => {

                request.get()
                .then((blob) => {
                    let reader = new FileReader();
                    reader.readAsDataURL(blob);
                        reader.onloadend = () => {
                            resolve(String(reader.result));
                    }
                    })
                .catch(() => {
                        resolve('');
                });

            });

            return this.photos[id];

        } catch (e) {
            return errorHandler(e);
        }
    }
}

export const msUserPhotoStorage = new MSUserPhoto();
