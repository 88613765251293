import { TBoardId } from '../../../../types/types';
import { getRestHeadersGet } from '../../../helpers/getRestHeadersHelper';
import { ThunkAction } from '../../../../types/actions';
import { fetchHandler } from '../../../../util/fetchHandler';
import { IRestCardAssigneeInfo } from '../../../../types/rest/IRestCardAssigneeInfo';
import { REST_CARDS_ASSIGNED_TO_ME } from '../../../constants';
import { Util } from '../../../../util/util';

export const checkCardAssignInfoExistForBoard = (
    boardId: TBoardId
): ThunkAction => {
    const action = () => {
        return fetchHandler<IRestCardAssigneeInfo>(
            Util.getApiUrl(REST_CARDS_ASSIGNED_TO_ME + '/' + boardId + '/checkCardAssignInfoExistForBoard'), {
                ...getRestHeadersGet()
            }
        );
    };
    return action;
};
