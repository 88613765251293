import { IApplicationState, TCardId } from '../../../types/types';
import { ICardCustomProperties } from '../../customProperties/types';
import { getCardMeta } from './getCardMeta';
import { getCard } from './getCard';

export const getCardCustomProperties = (
    state: IApplicationState,
    cardId: TCardId
): ICardCustomProperties => {
    const meta = getCardMeta(getCard(state, cardId));
    if (
        !meta ||
        !meta.customProperties
    ) return {};

    return meta.customProperties;
}
