import { IApplicationState } from '../../../../../../../../types/types';
import { connect } from 'react-redux';
import { IBoardCardAgingTogglerHOCProps } from './types';
import { SectionToggler } from '../../../../../../base/components/SectionToggler/SectionToggler';
import { ISectionTogglerFields } from '../../../../../../base/components/SectionToggler/types';
import { getPanelReadonly } from '../../../../selectors/getPanelReadonly';
import { getBoardCardAgingEnabled } from '../../../../../../../../store/model/board/selectors/getBoardCardAgingEnabled';

const mapStateToProps = (
    state: IApplicationState,
    { boardId }: IBoardCardAgingTogglerHOCProps
): ISectionTogglerFields => {
    return {
        checked: getBoardCardAgingEnabled(state, boardId),
        isDisabled: getPanelReadonly(state, boardId)
    };
};

export const CardAgingTogglerHOC = connect(
    mapStateToProps,
    null
)(SectionToggler);

CardAgingTogglerHOC.displayName = 'CardAgingTogglerHOC';
