import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/types';
import { ICardChecklistElementsHOCProps } from './types';
import { getSectionType } from '../../../selectors/getSectionType';
import { ICardChecklistItemEvents, ICardChecklistItemFields } from '../../components/CardChecklistItem/types';
import { CardChecklistItem } from '../../components/CardChecklistItem/CardChecklistItem';
import { onToggle } from './events/onToggle';
import { onItemSave } from './events/onItemSave';
import {
    getAuthUserShowChecklistItemsAuthor
} from '../../../../../../../store/model/authUser/selectors/getAuthUserShowChecklistItemsAuthor';
import { getBoardUsers } from '../../../../../../../store/model/selectors/getBoardUsers';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { getChecklistItemsSection } from '../selectors/getChecklistItemsSection';
import { getAsidePanelEntityPanelWidth } from '../../../../asidePanel/store/selectors/getAsidePanelEntityPanelWidth';
import { getChecklistItemCreateSelector } from '../selectors/getChecklistItemSelector';

// const analyze = new Analyze('CardChecklistItemHOC');

const mapStateToProps = () => {
    const getChecklistItems = getChecklistItemsSection();
    const getChecklistItem = getChecklistItemCreateSelector();
    const _mapStateToProps = (
        state: IApplicationState,
        { boardId, cardId, checklistId, itemId, draggableProvided, isDrag, isHideDoneChecklistItems }: ICardChecklistElementsHOCProps
    ): ICardChecklistItemFields => {
        // analyze.start();
        const sectionType = getSectionType(state, boardId, cardId);
        const checklistItems = getChecklistItems(state, cardId, checklistId);

        const isReadonly = sectionType === ESettingsSectionType.READONLY;
        let isShowWeights = isReadonly;
        if (!isReadonly && checklistItems) {
            checklistItems.map((item) => {
                if (item.weight > 1) {
                    isShowWeights = true;
                }
            });
        }

        const ret = {
            asidePanelWidth: getAsidePanelEntityPanelWidth(state),
            checklistId,
            draggableProvided,
            isDrag,
            isShowAuthor:  getAuthUserShowChecklistItemsAuthor(state) && getBoardUsers(state, boardId).length > 1,
            isShowWeights,
            item: getChecklistItem(state, cardId, checklistId, itemId),
            isHideDoneChecklistItems,
            sectionType,
        };
        // analyze.finish();
        return ret;
    };

    return _mapStateToProps;
};

const mapDispatchToProps = (
    dispatch: any,
    { cardId, checklistId }: ICardChecklistElementsHOCProps
): ICardChecklistItemEvents => {
    return {
        onToggle: (itemId) => dispatch(onToggle(cardId, checklistId, itemId)),
        onItemSave: (item) => dispatch(onItemSave(cardId, checklistId, item)),
    };
};

export const CardChecklistItemHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardChecklistItem);

CardChecklistItemHOC.displayName = 'CardChecklistItemHOC';
