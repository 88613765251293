import { EQuickStartGuideType, NAVIGATION_PANEL_CLASS } from '../../constants';
import { getMessages } from '../../../../../../store/constants';
import { IQuickStartGuidesData } from './types';
import { IApplicationState } from '../../../../../../types/types';
import { getAuthUserPlatformType } from '../../../../../../store/model/authUser/selectors/getAuthUserPlatformType';
import { EAuthUserPlatformType } from '../../../../../../types/rest/IRestAuthUser';

export const CLASS_NAV_PANEL_QUICK_START_GUIDE = NAVIGATION_PANEL_CLASS + '-quick-start-guide';

export const NAV_PANEL_QUICK_START_GUIDE_COMPLETE = getMessages().getText('quick_start_guides.complete');
export const NAV_PANEL_QUICK_START_GUIDE_OR = getMessages().getText('quick_start_guides.or');
export const NAV_PANEL_QUICK_START_GUIDE_BOOK_TITLE = getMessages().getText('quick_start_guides.book');
export const NAV_PANEL_QUICK_START_GUIDE_BOOK_SUBTITLE = getMessages().getText('quick_start_guides.book.subtitle');
export const NAV_PANEL_QUICK_START_GUIDE_SHOW = getMessages().getText('quick_start_guides.show');
export const NAV_PANEL_QUICK_START_GUIDE_SHOW_LEARN = getMessages().getText('quick_start_guides.show.learn');
export const NAV_PANEL_QUICK_START_GUIDE_STEP_LATER = getMessages().getText('quick_start_guides.step.later');
export const NAV_PANEL_QUICK_START_GUIDE_STEP_OK = getMessages().getText('quick_start_guides.step.ok');
export const NAV_PANEL_QUICK_START_GUIDE_FINISH_BUTTON = getMessages().getText('quick_start_guides.finish.button');

export const NAV_PANEL_QUICK_START_GUIDE_FINISH_TIMEOUT = 3000;

export const NAV_PANEL_QUICK_START_GUIDE_SPANISH_SUPPORT_COUNTRIES = ['ES', 'BR', 'MX', 'CO', 'AR', 'CL', 'PE'];
export const NAV_PANEL_QUICK_START_GUIDE_ENGLISH_SUPPORT_COUNTRIES = ['UK', 'CA', 'IN', 'FR', 'DE', 'RU', 'IT', 'IR', 'AT', 'DK', 'SE', 'CZ', 'BE'];

export const NAV_PANEL_QUICK_START_GUIDE_SUPPORT_PHOTO_URL = require('!file-loader!./olga.png').default;
export const NAV_PANEL_QUICK_START_GUIDE_SPANISH_SUPPORT_PHOTO_URL = require('!file-loader!./guilherme.png').default;
export const NAV_PANEL_QUICK_START_GUIDE_ENGLISH_SUPPORT_PHOTO_URL = require('!file-loader!./luba.png').default;
export const NAV_PANEL_QUICK_START_GUIDE_EDU_SUPPORT_PHOTO_URL = require('!file-loader!./yana.png').default;

export const NAV_PANEL_QUICK_START_GUIDE_SUPPORT_URL = 'https://calendly.com/kanbanchi/kanbanchi-introduction?month={date}';
export const NAV_PANEL_QUICK_START_GUIDE_SPANISH_SUPPORT_URL = 'https://calendly.com/kanbanchi/agenda-kanbanchi-demo?month={date}';
export const NAV_PANEL_QUICK_START_GUIDE_ENGLISH_SUPPORT_URL = 'https://calendly.com/kanbanchi/quick-guide?month={date}';
export const NAV_PANEL_QUICK_START_GUIDE_EDU_SUPPORT_URL = 'https://calendly.com/kanbanchi/kanbanchi-introduction-for-edu?month={date}';

export const getQuickStartGuides = (
    state: IApplicationState
): IQuickStartGuidesData => {
    const platformType = getAuthUserPlatformType(state);
    switch (platformType) {
        case EAuthUserPlatformType.MICROSOFT:
            return QUICK_START_GUIDES_MICROSOFT;
        default:
            return QUICK_START_GUIDES_GOOGLE;
    }
};

const EFFICIENCY_JOURNEY_GUIDE = {
    name: getMessages().getText('quick_start_guides.efficiency_journey_guide.name'),
    steps: [
        {
            key: 0,
            name: getMessages().getText('quick_start_guides.efficiency_journey_guide.step.1'),
            icon: 'user',
            video: 'https://www.youtube-nocookie.com/embed/IfaHFKz3K8A',
            text: '',
            completed: true,
            isNoEvents: true,
        },
        {
            key: 1,
            name: getMessages().getText('quick_start_guides.efficiency_journey_guide.step.2'),
            icon: 'kanban-default',
            video: 'https://www.youtube-nocookie.com/embed/IfaHFKz3K8A',
            text: getMessages().getText('quick_start_guides.efficiency_journey_guide.step.2.text')
        },
        {
            key: 2,
            name: getMessages().getText('quick_start_guides.efficiency_journey_guide.step.3'),
            icon: 'gantt-chart',
            video: 'https://www.youtube-nocookie.com/embed/TSx8t34YO00',
            text: getMessages().getText('quick_start_guides.efficiency_journey_guide.step.3.text')
        },
        {
            key: 3,
            name: getMessages().getText('quick_start_guides.efficiency_journey_guide.step.4'),
            icon: 'predecessor',
            video: 'https://www.youtube-nocookie.com/embed/TSx8t34YO00',
            text: getMessages().getText('quick_start_guides.efficiency_journey_guide.step.4.text')
        },
        {
            key: 4,
            name: getMessages().getText('quick_start_guides.efficiency_journey_guide.step.5'),
            icon: 'card-assigned',
            video: 'https://www.youtube-nocookie.com/embed/Yis9fzlI49c',
            text: getMessages().getText('quick_start_guides.efficiency_journey_guide.step.5.text')
        },
        {
            key: 5,
            name: getMessages().getText('quick_start_guides.efficiency_journey_guide.step.6'),
            icon: 'share-link',
            video: 'https://www.youtube-nocookie.com/embed/ejWAVfovwaw',
            text: getMessages().getText('quick_start_guides.efficiency_journey_guide.step.6.text')
        },
    ]
};

const EXPLORE_KANBANCHI = {
    name: getMessages().getText('quick_start_guides.explore_kanbanchi.name'),
    steps: [
        {
            key: 0,
            name: getMessages().getText('quick_start_guides.explore_kanbanchi.step.1'),
            icon: 'subcard',
            video: 'https://www.youtube-nocookie.com/embed/ALLCCPdf1cE',
            text: getMessages().getText('quick_start_guides.explore_kanbanchi.step.1.text')
        },
        {
            key: 1,
            name: getMessages().getText('quick_start_guides.explore_kanbanchi.step.2'),
            icon: 'custom-fields',
            video: 'https://www.youtube-nocookie.com/embed/17k2cYxQJk0',
            text: getMessages().getText('quick_start_guides.explore_kanbanchi.step.2.text')
        },
        {
            key: 2,
            name: getMessages().getText('quick_start_guides.explore_kanbanchi.step.3'),
            icon: 'card',
            video: 'https://www.youtube-nocookie.com/embed/nVCpg6aNCgw',
            text: getMessages().getText('quick_start_guides.explore_kanbanchi.step.3.text')
        },
        {
            key: 3,
            name: getMessages().getText('quick_start_guides.explore_kanbanchi.step.4'),
            icon: 'activity',
            video: 'https://www.youtube-nocookie.com/embed/19wIw7aPRgQ',
            text: getMessages().getText('quick_start_guides.explore_kanbanchi.step.4.text'),
        },
        {
            key: 4,
            name: getMessages().getText('quick_start_guides.explore_kanbanchi.step.5'),
            icon: 'gmail',
            video: 'https://www.youtube-nocookie.com/embed/DadFRnG6q5Y',
            text: getMessages().getText('quick_start_guides.explore_kanbanchi.step.5.text')
        },
    ]
};

const EXPLORE_KANBANCHI_MICROSOFT = {
    name: getMessages().getText('quick_start_guides.explore_kanbanchi.name'),
    steps: [
        {
            key: 0,
            name: getMessages().getText('quick_start_guides.explore_kanbanchi.step.1'),
            icon: 'subcard',
            video: 'https://www.youtube-nocookie.com/embed/ALLCCPdf1cE',
            text: getMessages().getText('quick_start_guides.explore_kanbanchi.step.1.text')
        },
        {
            key: 1,
            name: getMessages().getText('quick_start_guides.explore_kanbanchi.step.2'),
            icon: 'custom-fields',
            video: 'https://www.youtube-nocookie.com/embed/17k2cYxQJk0',
            text: getMessages().getText('quick_start_guides.explore_kanbanchi.step.2.text')
        },
        {
            key: 2,
            name: getMessages().getText('quick_start_guides.explore_kanbanchi.step.3'),
            icon: 'card',
            video: 'https://www.youtube-nocookie.com/embed/nVCpg6aNCgw',
            text: getMessages().getText('quick_start_guides.explore_kanbanchi.step.3.text')
        },
        {
            key: 4,
            name: getMessages().getText('quick_start_guides.explore_kanbanchi.step.5'),
            icon: 'gmail',
            video: 'https://www.youtube-nocookie.com/embed/DadFRnG6q5Y',
            text: getMessages().getText('quick_start_guides.explore_kanbanchi.step.5.text')
        },
    ]
};

const KANBANCHI_CHAMPION = {
    name: getMessages().getText('quick_start_guides.kanbanchi_champion.name'),
    steps: [
        {
            key: 0,
            name: getMessages().getText('quick_start_guides.kanbanchi_champion.step.1'),
            icon: 'add-notification',
            video: 'https://www.youtube-nocookie.com/embed/aDIhDW3fj7E',
            text: getMessages().getText('quick_start_guides.kanbanchi_champion.step.1.text')
        },
        {
            key: 1,
            name: getMessages().getText('quick_start_guides.kanbanchi_champion.step.2'),
            icon: 'prefix',
            video: 'https://www.youtube-nocookie.com/embed/iSRFwwIp8gs',
            text: getMessages().getText('quick_start_guides.kanbanchi_champion.step.2.text')
        },
        {
            key: 2,
            name: getMessages().getText('quick_start_guides.kanbanchi_champion.step.3'),
            icon: 'filters',
            video: 'https://www.youtube-nocookie.com/embed/z5BoW9VEuw8',
            text: getMessages().getText('quick_start_guides.kanbanchi_champion.step.3.text')
        },
        {
            key: 3,
            name: getMessages().getText('quick_start_guides.kanbanchi_champion.step.4'),
            icon: 'swimlanes',
            video: 'https://www.youtube-nocookie.com/embed/p6SFH_K5l6s',
            text: getMessages().getText('quick_start_guides.kanbanchi_champion.step.4.text')
        },
        {
            key: 4,
            name: getMessages().getText('quick_start_guides.kanbanchi_champion.step.5'),
            icon: 'list-view',
            video: 'https://www.youtube-nocookie.com/embed/HThSRhYpg10',
            text: getMessages().getText('quick_start_guides.kanbanchi_champion.step.5.text')
        },
    ]
};

const KANBANCHI_GURU = {
    name: getMessages().getText('quick_start_guides.kanbanchi_guru.name'),
    steps: [
        {
            key: 0,
            name: getMessages().getText('quick_start_guides.kanbanchi_guru.step.1'),
            icon: 'spent-time',
            video: 'https://www.youtube-nocookie.com/embed/Ogy_SZ-J0go',
            text: getMessages().getText('quick_start_guides.kanbanchi_guru.step.1.text')
        },
        {
            key: 1,
            name: getMessages().getText('quick_start_guides.kanbanchi_guru.step.2'),
            icon: 'board',
            video: 'https://www.youtube-nocookie.com/embed/WMfD_S_T4l8',
            text: getMessages().getText('quick_start_guides.kanbanchi_guru.step.2.text')
        },
        {
            key: 2,
            name: getMessages().getText('quick_start_guides.kanbanchi_guru.step.3'),
            icon: 'kanbanchi-logo',
            video: 'https://www.youtube-nocookie.com/embed/Y9ZQBD0LMrI',
            text: getMessages().getText('quick_start_guides.kanbanchi_guru.step.3.text')
        },
        {
            key: 3,
            name: getMessages().getText('quick_start_guides.kanbanchi_guru.step.4'),
            icon: 'chart',
            video: 'https://www.youtube-nocookie.com/embed/wpPZ97-iTGo',
            text: getMessages().getText('quick_start_guides.kanbanchi_guru.step.4.text')
        },
        {
            key: 4,
            name: getMessages().getText('quick_start_guides.kanbanchi_guru.step.5'),
            icon: 'archive',
            video: 'https://www.youtube-nocookie.com/embed/oQ_1i2aoz6o',
            text: getMessages().getText('quick_start_guides.kanbanchi_guru.step.5.text')
        },
    ]
};

const KANBANCHI_GURU_MICROSOFT = {
    name: getMessages().getText('quick_start_guides.kanbanchi_guru.name'),
    steps: [
        {
            key: 0,
            name: getMessages().getText('quick_start_guides.kanbanchi_guru.step.1'),
            icon: 'spent-time',
            video: 'https://www.youtube-nocookie.com/embed/Ogy_SZ-J0go',
            text: getMessages().getText('quick_start_guides.kanbanchi_guru.step.1.text')
        },
        {
            key: 2,
            name: getMessages().getText('quick_start_guides.kanbanchi_guru.step.3'),
            icon: 'kanbanchi-logo',
            video: 'https://www.youtube-nocookie.com/embed/Y9ZQBD0LMrI',
            text: getMessages().getText('quick_start_guides.kanbanchi_guru.step.3.text')
        },
        {
            key: 3,
            name: getMessages().getText('quick_start_guides.kanbanchi_guru.step.4'),
            icon: 'chart',
            video: 'https://www.youtube-nocookie.com/embed/wpPZ97-iTGo',
            text: getMessages().getText('quick_start_guides.kanbanchi_guru.step.4.text')
        },
        {
            key: 4,
            name: getMessages().getText('quick_start_guides.kanbanchi_guru.step.5'),
            icon: 'archive',
            video: 'https://www.youtube-nocookie.com/embed/oQ_1i2aoz6o',
            text: getMessages().getText('quick_start_guides.kanbanchi_guru.step.5.text')
        },
    ]
};

export const QUICK_START_GUIDES_GOOGLE: IQuickStartGuidesData = {
    [EQuickStartGuideType.EFFICIENCY_JOURNEY_GUIDE]: EFFICIENCY_JOURNEY_GUIDE,
    [EQuickStartGuideType.EXPLORE_KANBANCHI]: EXPLORE_KANBANCHI,
    [EQuickStartGuideType.KANBANCHI_CHAMPION]: KANBANCHI_CHAMPION,
    [EQuickStartGuideType.KANBANCHI_GURU]: KANBANCHI_GURU,
};

export const QUICK_START_GUIDES_MICROSOFT: IQuickStartGuidesData = {
    [EQuickStartGuideType.EFFICIENCY_JOURNEY_GUIDE]: EFFICIENCY_JOURNEY_GUIDE,
    [EQuickStartGuideType.EXPLORE_KANBANCHI]: EXPLORE_KANBANCHI_MICROSOFT,
    [EQuickStartGuideType.KANBANCHI_CHAMPION]: KANBANCHI_CHAMPION,
    [EQuickStartGuideType.KANBANCHI_GURU]: KANBANCHI_GURU_MICROSOFT,
};
