import { IABTest } from '../../types';
import { IApplicationState } from '../../../types/types';
import { getAuthUser } from '../../../store/model/authUser/selectors/getAuthUser';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { EUserSettingsVideoVoice } from '../../../types/rest/IRestAuthUser';
import { authUserSetVideoVoice } from '../../../rest/effects/authUser/authUserSetVideoVoice';

export const AB_TEST_VIDEO_VOICE = 'AB_TEST_VIDEO_VOICE';

const isRelevantChecker = (
    state: IApplicationState
): boolean => {
    const user = getAuthUser(state);
    const currentDateTimeStamp = new Date().getTime() / 1000;
    // аккаунт создан сегодня
    return (currentDateTimeStamp - user.createDateTimeStamp) <= 24 * 60 * 60;
}

const postProcessor = (
    value: EUserSettingsVideoVoice
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        const videoVoice = value === EUserSettingsVideoVoice.MALE ?
            EUserSettingsVideoVoice.MALE :
            EUserSettingsVideoVoice.FEMALE;
        dispatch(authUserSetVideoVoice(videoVoice));
    }
    return action;
}

export const getVideoVoiceConfig = (): IABTest => {
    return {
        name: AB_TEST_VIDEO_VOICE,
        defaultValue: EUserSettingsVideoVoice.FEMALE,
        size: 5000,
        groups: [
            {
                weight: 100,
                value: EUserSettingsVideoVoice.FEMALE
            },
            {
                weight: 100,
                value: EUserSettingsVideoVoice.MALE
            }
        ],
        isRelevant: isRelevantChecker,
        executeOnLoad: true,
        postProcessor
    }
}
