import { IApplicationState, TCardId } from '../../../types/types';
import { getCard } from './getCard';
import { ORDER_STEP } from '../../../const';
import { getBoardNextGanttOrderNumber } from './getBoardNextGanttOrderNumber';

export const getNextGanttOrderNumber = ( // logic from App.Collections.Cards
    state: IApplicationState,
    cardId: TCardId
): number => {
    const card = getCard(state, cardId);
    if (!card) return ORDER_STEP;

    const list = state.model.lists[card.listId];
    if (!list) return ORDER_STEP;

    return getBoardNextGanttOrderNumber(state, list.dashboardId);
}
