import { IABTest } from '../../types';
import { IApplicationState } from '../../../types/types';
import { getAuthUser } from '../../../store/model/authUser/selectors/getAuthUser';

export const AB_TEST_FIRST_BOARD_TYPE = 'AB_TEST_FIRST_BOARD_TYPE';

export const FIRST_BOARD_VALUE = 'First board';
export const ABILITIES_BOARD_VALUE = 'Kanbanchi abilities board';

const isRelevantChecker = (
    state: IApplicationState
): boolean => {
    const user = getAuthUser(state);
    const currentDateTimeStamp = new Date().getTime() / 1000;
    // аккаунт создан сегодня
    return (currentDateTimeStamp - user.createDateTimeStamp) <= 24 * 60 * 60;
};

export const getFirstBoardTypeConfig = (): IABTest => {
    return {
        name: AB_TEST_FIRST_BOARD_TYPE,
        defaultValue: FIRST_BOARD_VALUE,
        size: 5000,
        groups: [
            {
                weight: 100,
                value: FIRST_BOARD_VALUE
            },
            {
                weight: 100,
                value: ABILITIES_BOARD_VALUE
            }
        ],
        isRelevant: isRelevantChecker,
        executeOnLoad: false
    }
};
