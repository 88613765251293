import { IAuthorRest } from '../model';
import { TCardId } from '../types';

export type TDriveDocId = number;

export interface IRestDriveDoc {
    id?: TDriveDocId;
    authorDetails?: IAuthorRest;
    bigThumbUrl?: string;
    created?: number;
    dashboardId?: number;
    fileId?: string;
    iconUrl?: string;
    isImage?: boolean;
    main?: boolean;
    mimeType?: string;
    orderNumber?: number;
    thumbHeight?: number;
    thumbUrl?: string;
    thumbWidth?: number;
    title?: string;
    url?: string;
    cardId?: TCardId;
    newFile?: boolean;
    type?: ERestDriveDocType;
    driveId?: string; //for microsoft boards
    platformType?: string;

    /***
     * only to send to backend
     */
    imgWidth?: number;
    imgHeight?: number;
}

export enum ERestDriveDocType {
    CARD = 'card',
    COMMENT = 'comment'
}
