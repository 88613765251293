import {Dispatch, ThunkAction} from '../../../../../../../types/actions';
import { IGetState, TBoardId } from '../../../../../../../types/types';
import { getBoardParentFolderInfo } from 'app/store/model/selectors/getBoardParentFolderInfo';
import { EFilePermissionRole } from 'app/helper/authorisation/microsoft/graph/api/getFilePermissions';
import { addFilePermissions } from 'app/helper/authorisation/microsoft/graph/api/addFilePermissions';
import { SNACKBAR_ID_BOARD_ERROR } from 'app/view/react_components/snackbarsStack/effects/constants';
import { snackbarErrorDefault } from 'app/view/react_components/snackbarsStack';
import { getMessages } from 'app/store/constants';

export const onAdd = (
    boardId: TBoardId,
    emails: string[],
    role: EFilePermissionRole,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        if (!emails || !emails.length) return;

        const parentFolderInfo = getBoardParentFolderInfo(getState(), boardId);
        if (!parentFolderInfo) return;

        return addFilePermissions(parentFolderInfo.driveId, parentFolderInfo.folderId, emails, role)
            .catch((error) => {
                let code = error && error.innerError && error.innerError.code;
                let message = code && getMessages().getText1(`snackbar.error_code.${code}`);

                dispatch(snackbarErrorDefault({
                    id: SNACKBAR_ID_BOARD_ERROR,
                    text: message || error && error.message,
                }));
            });
    };
    return action;
};
