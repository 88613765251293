export const isDateInBetween = (
    date: Date,
    intervalStart: Date,
    intervalEnd: Date
): boolean => {
    if (!intervalStart || !intervalEnd || !date) return false;
    const timestamp = date.getTime();
    return (intervalEnd.getTime() >= timestamp && intervalStart.getTime() <= timestamp) ||
        (intervalEnd.getTime() <= timestamp && intervalStart.getTime() >= timestamp);
}
