import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { root } from '../../../../../../../store/constants';
import { tabKeySetAction } from '../../../store/actions/tabKeySetAction';
import { routeSetAction } from '../../../../../../../store/router/actions/routeSetAction';
import { isEditDashboardWithScroll } from '../../../../../../../store/router/selectors/isEditDashboardWithScroll';
import { IGetState } from '../../../../../../../types/types';
import { EBoardTab } from '../../../../boardDetails/types';
import { BOARD_DETAILS_TABS } from '../selectors/getBoardDetailsTabs';

export const onTabActive = (
    activeTabIndex: number
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const tabs = BOARD_DETAILS_TABS;
        const tab = tabs[activeTabIndex];
        if (!tab) return;

        dispatch(tabKeySetAction(tab.key));

        if (
            tab.key === EBoardTab.DETAILS &&
            isEditDashboardWithScroll(getState())
        ) return; // уже правильный урл

        const tabUrlSuffix = tab.url || '';
        root.App.router.navigate(root.App.router.getUrl(tabUrlSuffix), { trigger: false });
        if (tab.route) dispatch(routeSetAction(tab.route));
    };
    return action;
};
