import {INavigationPanelIsAsideOpenSetAction} from './types';
import {AT_NAVIGATION_PANEL_IS_ASIDE_OPEN_SET} from './constants';

export const isAsideOpenSetAction = (
    isAsideOpened: boolean
): INavigationPanelIsAsideOpenSetAction => ({
        type: AT_NAVIGATION_PANEL_IS_ASIDE_OPEN_SET,
        isAsideOpened
    }
);
