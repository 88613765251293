import { IPromoCode } from 'app/types/rest/IUserMeta';
import { IApplicationState } from '../../../../types/types';
import { getAuthUser } from './getAuthUser';

export const getUserPromoCode = (
    state: IApplicationState
): IPromoCode => {
    const user = getAuthUser(state);
    return user && user.meta && user.meta.promoCode;
};
