import {
    IBoardsRequestsState,
    ICardsRequestsState,
    IDependenciesRequestsState
} from '../reducers/types';

export const AT_BOARDS_REQUESTS_STATE_SET = 'REQUESTS_STATE_BOARDS_SET';
export const AT_CARDS_REQUESTS_STATE_SET = 'REQUESTS_STATE_CARDS_SET';
export const AT_DEPENDENCIES_REQUESTS_STATE_SET = 'REQUESTS_STATE_DEPENDENCIES_SET';

export interface IBoardsRequestsStateSetAction {
    type: typeof AT_BOARDS_REQUESTS_STATE_SET;
    boards: IBoardsRequestsState;
}

export interface ICardsRequestsStateSetAction {
    type: typeof AT_CARDS_REQUESTS_STATE_SET;
    cards: ICardsRequestsState;
}

export interface IDependenciesRequestsStateSetAction {
    type: typeof AT_DEPENDENCIES_REQUESTS_STATE_SET;
    dependencies: IDependenciesRequestsState;
}

export type TRequestsStateAction = (
    IBoardsRequestsStateSetAction |
    ICardsRequestsStateSetAction |
    IDependenciesRequestsStateSetAction
);
