import { IApplicationState } from '../../../../../../../types/types';
import { TTabKey } from '../../types';
import { IDrivesListItem } from '../../../containers/DrivesList/types';
import { getDrivesByTabKey } from './getDrivesByTabKey';
import { getBoardsIds } from '../../boards/selectors/getBoardsIds';
import { DRIVE_ID_SHARED_WITH_ME } from '../constants';
import { isAllowCreateBoard } from './isAllowCreateBoard';

export const getDrivesWithBoardsByTabKey = (
    state: IApplicationState,
    tabKey: TTabKey,
    enableSearch: boolean = true
): IDrivesListItem[] => {
    const drivesByTabKey = getDrivesByTabKey(state, tabKey);
    const drivesWithBoards = drivesByTabKey.reduce((
        drivesFiltered,
        drive
    ) => {
        const boardsCount = getBoardsIds(state, tabKey, drive.id, enableSearch).length;
        if (
            boardsCount ||
            drive.id !== DRIVE_ID_SHARED_WITH_ME
        ) {
            drive = JSON.parse(JSON.stringify(drive));
            drive.allowedAction.allowCreateBoard = isAllowCreateBoard(state, drive);
            drivesFiltered.push({
                ...drive,
                boardsCount
            });
        }
        return drivesFiltered;
    }, []);
    return drivesWithBoards;
}
