import { IRequestItem } from '../../fetchHandler';
import * as moment from 'moment';
import { FetchQueue } from 'app/util/fetchUtils/FetchQueue';

enum EQueueStatusWork  {
    sleep = 0,
    working = 1
}

export class Queue {
    queue: IRequestItem[] = [];
    status = EQueueStatusWork.sleep;

    add = (request: IRequestItem) => {
        this.queue.push(request);
        this.queueWorker();
    };

    queueWorker = () => {
        if (this.status === EQueueStatusWork.working) {
            return;
        }
        const request = this.queue.shift();
        if (!request)
            return;
        this.status = EQueueStatusWork.working;
        FetchQueue.getInstance().add({input: request.input, init: request.init}).then((response) => {
                this.status = EQueueStatusWork.sleep;
                try {
                    request.resolveCallback(response);
                } catch (e) {
                    console.log(e)
                }
                this.queueWorker();
        }).catch((e) => {
                this.status = EQueueStatusWork.sleep;
                try {
                    request.rejectCallback(e);
                } catch (e1) {
                    console.log(e1);
                }
            });
    };
}
