'use strict';

import { blockMarkdown } from '../../helper/markdownHelper';
import { clearMarkdown } from "../react_components/base/helpers/clearMarkdownHelper";

App.Views.CardAddReactionNotification = App.Views.BaseNotification.extend({

    getActions: function() {
        var action = {
            action: Messages.getText('noty.card_reaction'),
            actionData: {
                icon: 'like'
            },
            body: clearMarkdown(this.notificationModel.commentText),
            bodyRaw: blockMarkdown(this.notificationModel.commentText)
        };

        if (!this.notificationModel.commentText && this.notificationModel.driveDocs && this.notificationModel.driveDocs[0]) {
            action.badge = {};
            if (this.notificationModel.driveDocs.length > 1) {
                action.badge.text = this.notificationModel.driveDocs.length + ' ' + Messages.getText('noty.files');
                action.badge.icon = 'attach';
            } else {
                action.badge = this.notificationModel.driveDocs[0];
                action.badge.text = action.badge.title;
            }
        }

        if (this.isTimeTracker()) {
            action.body = action.bodyRaw.replace(/<[^>]+>/g, '');
            action.bodyRaw = this.findTimeTracker(action.body);
        }

        return action;
    },

    findTimeTracker: function (src) {
        var marked = src.split('').map(function (chr) {
            if (chr === '[') return '<span class="markdown_ttracker">';
            if (chr === ']') return '</span>';
            return chr;
        });
        return marked.join('');
    },

    isTimeTracker: function () {
        return (this.notificationModel.commentText && this.notificationModel.commentText.indexOf('[') > -1);
    },

    onClick: function(e, notification) {
        var dashboardId = this.notificationModel.dashboardId;
        var cardId = this.notificationModel.card.id;
        App.controller.cleverOpenCard(dashboardId, cardId);
        this.closeNotification(notification);
        e.stopPropagation();
    }
});
