import { IApplicationState } from '../../../../../../types/types';
import { getAsidePanel } from './getAsidePanel';
import { getIsLinkCardActive } from '../../../../../../store/router/selectors/getIsLinkCardActive';
import { ASIDE_DEFAULT_WIDTH } from '../../../../../../const';

export const getAsidePanelEntityPanelWidth = (
    state: IApplicationState
): number => {
    const isLinkCard = getIsLinkCardActive(state);
    return isLinkCard ? ASIDE_DEFAULT_WIDTH : getAsidePanel(state).entityPanelWidth;
};
