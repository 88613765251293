import {ActionCreator} from 'redux';
import {Dispatch, ThunkAction} from '../../../../../../../types/actions';
import {IGetState} from '../../../../../../../types/types';
import { boardAddClickedSegmentEvent } from '../../../effects/segmentEvents';
import { addFeatureTrigger } from '../../../../../polls/featurePoll/effects/addFeatureTrigger';
import { ERateFeature } from '../../../../../../../types/rest/IUserMeta';
import { showSnoozeBlocker } from '../../../../../base/effects/showSnoozeBlocker';

export const onClickDisallow: ActionCreator<ThunkAction> = (
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(showSnoozeBlocker({ allow: 'allowSharedDrive' }));
    };
    return action;
};
