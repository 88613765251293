import {fetchHandler} from 'app/util/fetchHandler';
import {getRestHeadersPatch, getRestHeadersPost} from 'app/rest/helpers/getRestHeadersHelper';
import {TBoardId} from 'app/types/types';
import {IBoard} from 'app/store/model/board/types';
import {IRestDashboard} from 'app/types/rest/IRestDashboard';
import {dispatch} from 'app/store/configureStore';
import {boardsActionSet} from 'app/store/model/actions/boardsActionSet';
import {boardActionSet} from 'app/store/model/boards/actions/boardActionSet';
import {updateAction} from 'app/store/model/board/actions/updateAction';
import {boardFetchPermissions} from 'app/rest/effects/board/boardFetchPermissions';
import { boardFetchUsers, storeUsers } from 'app/rest/effects/board/boardFetchUsers';
import Util from 'app/util/util';
import {REST_BOARD} from 'app/rest/constants';

export class PermissionSynchronizer {
    private readonly boardId: number;
    private readonly updateTime: number;
    private readonly board: IBoard;

    constructor(board: IBoard) {
        this.updateTime = new Date().getTime();
        this.boardId = board.id;
        this.board = board;
    }

    synchronize() {
        if (this.board.lastUpdatePermissionTime < this.updateTime || this.board.created < this.updateTime) {
            this.synchronizeUserBoards()
                .then((context: IPermissionSynchronizingContext) => {
                    if(context.status !== EStatus.OK) {
                        dispatch(boardFetchPermissions(this.board.id))
                        dispatch(boardFetchUsers(this.board.id))
                        return;
                    }
                    const groupSynchronizePromises = context.groups.map(group => {
                        return this.synchronizeGroup(group);
                    })
                    Promise.all(groupSynchronizePromises)
                        .then(() => {
                            return this.finishSynchronizing()
                        })
                        .then((board: IRestDashboard) => {
                            dispatch(storeUsers(board.id, board.users));
                            return this.deleteNotRelevantBoardUsers()
                        })
                })
        }
    }

    private synchronizeUserBoards() {
        return fetchHandler(`/rest/userBoards/synchronize/users?dashboardId=${this.boardId}&updateTime=${this.updateTime}` )
    }

    private synchronizeGroup(group: IGroupSynchronize){
        return this.synchronizeGroupRest(group, this.updateTime);
    }

    private synchronizeGroupRest(group: IGroupSynchronize, updateTime: number, cursor: string = ''): Promise<any> {
        return fetchHandler('/rest/userBoards/synchronize/groups', {
            ...getRestHeadersPost(),
            body: JSON.stringify({
                ...group,
                dashboardId: this.boardId,
                updateTime: updateTime,
                cursor
            })
        }).then((result: {cursor: string} ) => {
            if(result.cursor) {
                return this.synchronizeGroupRest(group, updateTime, result.cursor)
            }
        })
    }

    private deleteNotRelevantBoardUsers(cursor: string = ''): Promise<any> {
        return fetchHandler(
            `/rest/userBoards/synchronize/deleteNotRelevantBoardUsers?dashboardId=${this.boardId}&updateTime=${this.updateTime}&cursor=${cursor}`)
            .then((result: { cursor: string }) => {
                if(result.cursor) {
                    return this.deleteNotRelevantBoardUsers(result.cursor)
                }
            })
    }

    private finishSynchronizing() {
        return fetchHandler(`/rest/userBoards/synchronize/finish?dashboardId=${this.boardId}&updateTime=${this.updateTime}` , {
            method: 'POST'
        })
    }

}

interface IPermissionSynchronizingContext {
    status: EStatus,
    groups: IGroupSynchronize[]
}

enum EStatus {
    NO_PERMISSIONS = 'NO_PERMISSIONS', READ_ONLY = 'READ_ONLY', OK = 'OK',
}
interface IGroupSynchronize {
    displayName: string,
    id: number,
    dashboardId: TBoardId,
    updateTime: number,
    role: string
}
