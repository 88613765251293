import { isObjectEmpty } from './../../../view/react_components/helpers/isObjectEmpty';
import { IApplicationState } from '../../../types/types';
import { ICards } from '../cards/types';
import { getCard } from './getCard';
import { ICard } from '../card/types';

/***
 * Выдает коллекцию карт из state, НО только с полями которые присутствуют в patchCards. Т.е. если мы меняем title, то будет {57265266265652: {title: 'old value'} }
 * @param state
 * @param patchCards
 */
export const getCardsForRollback = (
    state: IApplicationState,
    patchCards: ICards
): ICards => {
    if (
        !patchCards ||
        isObjectEmpty(patchCards)
    ) return;

    const cardsForRollback: ICards = {};

    for (let cardId in patchCards) {
        const card = getCard(state, Number(cardId));
        if (!card) return;

        cardsForRollback[cardId] = {};
        for (const field in patchCards[cardId]) {
            const fieldKey = field as keyof ICard;
            cardsForRollback[cardId] = {
                ...cardsForRollback[cardId],
                [fieldKey]: card[fieldKey]
            };
            cardsForRollback[cardId].listId = card.listId; //todo remove when remove backbone KNB-1172 - remove related link -> remove card from store
            cardsForRollback[cardId].processingStatus = card.processingStatus; //todo remove when remove backbone KNB-1172 - remove related link -> remove card from store
        }
    }

    return JSON.parse(JSON.stringify(cardsForRollback));
}
