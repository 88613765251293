import { IGetState, TCardId, TChecklistId, TChecklistItemId, TPermissionId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { toggleChecklistItemUser } from '../effects/toggleChecklistItemUser';

export const onChangeAssignees = (
    boardId: number,
    cardId: TCardId,
    checklistId: TChecklistId,
    itemId: TChecklistItemId,
    permissionId: TPermissionId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        return dispatch(toggleChecklistItemUser(boardId, cardId, checklistId, itemId, permissionId));
    };
    return action;
};
