import { IGetState, TCardId } from '../../../../../../types/types';
import { IDriveDoc } from '../../../../../../store/model/card/types/IDriveDoc';
import { Dispatch, ThunkAction } from '../../../../../../types/actions';
import { IDriveDocs } from '../../../../../../store/model/driveDocs/types';
import { driveDocsActionSetAction } from '../../../../../../store/model/actions/driveDocsActionSetAction';
import { driveDocsSet } from '../../../../../../store/model/driveDocs/actions/driveDocsSet';
import { driveDocsDelete } from '../../../../../../store/model/driveDocs/actions/driveDocsDelete';
import { postRest } from '../postRest';
import { sendRealTimeStoreActionByCardId } from '../../../../../../view/react_components/base/helpers/realTimeHelperTS';
import { driveDocUpdate } from '../../../../../../store/model/driveDocs/actions/driveDocUpdate';

export const postRestHelper = (
    cardId: TCardId,
    driveDocs: IDriveDoc[],
    isOptimistic: boolean = true,
    isRealtime: boolean = true // send event to socket
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (!driveDocs) Promise.reject();

        const newDriveDocsMap: IDriveDocs = {};
        const newDriveDocs = driveDocs;
        let fakeId = Date.now();
        newDriveDocs.forEach(doc => {
            fakeId++;
            newDriveDocsMap[fakeId] = {
                id:  fakeId,
                ...doc
            };
        });
        if (!driveDocs.length) Promise.reject();

        if (isOptimistic) {
            dispatch(driveDocsActionSetAction(driveDocsSet(newDriveDocsMap)));
        }
        return dispatch(postRest(cardId, driveDocs))
            .then((responseDocs: IDriveDoc[]) => {
                const driveDocsMapResponse: IDriveDocs = {};
                responseDocs.forEach((doc) => {
                    driveDocsMapResponse[doc.id] = doc;
                });
                dispatch(driveDocsActionSetAction(driveDocsSet(driveDocsMapResponse)));
                if (isRealtime) {
                    dispatch(sendRealTimeStoreActionByCardId(cardId, driveDocsActionSetAction(driveDocsSet(driveDocsMapResponse))))
                }
                return responseDocs;
            })
            .catch((e: any) => {
                console.error(e);
            })
            .finally( () =>{
                if (isOptimistic) {
                    dispatch(driveDocsActionSetAction(driveDocsDelete(newDriveDocsMap)));
                }
            });
    };
    return action;
};
