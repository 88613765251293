import { IAuthUser } from '../types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { authUserActionSet } from '../../actions/authUserActionSet';
import { updateAction } from '../actions/updateAction';
import { IGetState } from '../../../../types/types';
import { validateLogoImage } from 'app/util/validateLogoImage';
import { getAuthUser } from '../selectors/getAuthUser';

export const updateUser = (
    authUser: IAuthUser
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        let logo = authUser.logo;
        const user = getAuthUser(getState());
        const neddLogoValidate = logo && user.logo !== logo;
        if (neddLogoValidate) {
            authUser.logo = null; // 1. сначала спрятать лого, чтоб скорее сохранить юзера
        } else {
            logo = null;
        }
        // if (!authUser.firstVisit) {
        //     const currentAuthUser = getAuthUser(getState());
        //     if (authUser.id === currentAuthUser.id) {
        //         authUser.firstVisit = getAuthUser(getState()).firstVisit; // не обновлять firstVisit
        //     }
        // }

        dispatch(authUserActionSet(updateAction(authUser)));

        if (logo) {
            validateLogoImage(logo) // 2. потом проверить лого
                .then((logoX: number) => {
                    dispatch(authUserActionSet(updateAction({
                        logo,
                        logoX,
                    })));
                });
        }
    }
    return action;
};
