import { Dispatch } from '../../../../../../../../../types/actions';
import { removeNotificationByIdEffect } from '../../NotificationEditingPopUp/effects/editingStateEffects';

export const onRemoveNotificationById = (
    cardId: number,
    notificationId: number,
) => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(removeNotificationByIdEffect(cardId, notificationId));
    };
    return action;
};
