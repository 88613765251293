import { ITimeTrackerSetAction } from './types';
import { AT_SET } from './constants';
import { ITimeTrackerState } from '../types';

export const timeTrackerSetAction =
    (
        data: ITimeTrackerState
    ): ITimeTrackerSetAction => ({
        type: AT_SET,
        data
    });
