import * as React from 'react';
import { IAsidePanelTitleProps } from './types';
import './_AsidePanelTitle.scss';
import { CLASS_ASIDE_PANEL_TITLE } from './constants';

export function AsidePanelTitle ({
    title
}: IAsidePanelTitleProps) {
    return (
        <div className={CLASS_ASIDE_PANEL_TITLE}>
            {title}
        </div>
    );
};
