import { TUserSettings } from '../../../../types/rest/IRestAuthUser';
import { IApplicationState } from '../../../../types/types';
import { getAuthUser } from './getAuthUser';

/**
 * don't use this, only for chain selectors
 */
export const getAuthUserSettings = (state: IApplicationState): TUserSettings => {
    return getAuthUser(state).settings;
}
