const category = 'Feedback';

export const SegmentFeedbackEvent = {
    FB_SHOW: { category, event: 'Feedback window opened' },
    OPEN_CATEGORY: { category, event: 'Feedback category dropdown opened' },
    CHANGE_CATEGORY: { category, event: 'Feedback category changed' },
    ADD_FILES: { category, event: 'File(s) attached' },
    REMOVE_FILE: { category, event: 'File removed' },
    CANCEL: { category, event: 'Cancel button clicked in Feedback form' },
    SUBMIT: { category, event: 'Feedback submitted' },
    GOT_IT: { category, event: 'Got it button clicked' },
    QUICK_FEEDBACK_BUTTON_CLICKED: { category, event: 'Quick feedback button clicked'},
    MORE_PROPERTIES_BUTTON_CLICKED: { category, event: 'More properties button clicked'},
};

export enum SegmentFeedbackOption {
    CATEGORY = 'category',
    TARGET = 'target'
}

export enum SegmentFeedbackTargetValue {
    MORE_PROPERTIES = 'more properties',
    FEEDBACK = 'feedback'
}
