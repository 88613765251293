import { ActionCreator } from 'redux';
import { ThunkAction } from '../../../../../../../types/actions';
import { root } from '../../../../../../../store/constants';

export const onClick: ActionCreator<ThunkAction> = () => {
    const action = () => {
        root.App.controller.showTutorialVideos();
    };
    return action;
};
