import { connect } from 'react-redux';
import { onClickButtonUpgradeMyPlan } from './events/onClick';
import { ButtonLarge } from '../../components/ButtonLarge/ButtonLarge';
import { IButtonLargeDispatchProps, IButtonLargeStateProps } from '../../components/ButtonLarge/types';
import { BUTTON_TEXT_UPGRADE_MY_PLAN } from './constants';

const mapStateToProps =
(): IButtonLargeStateProps => {
    return {
        text: BUTTON_TEXT_UPGRADE_MY_PLAN
    }
};

const mapDispatchToProps = (
    dispatch: any
): IButtonLargeDispatchProps => {
    return {
        onClick: () => dispatch(onClickButtonUpgradeMyPlan())
    }
};

export const ButtonUpgradeMyPlanHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(ButtonLarge);

ButtonUpgradeMyPlanHOC.displayName = 'ButtonUpgradeMyPlanHOC';
