import { IGetState, TCardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { SegmentArchiveEvent, SegmentCardEvent, segmentTrackAction } from '../../../../middlewares/segment';
import { cardDelete } from '../../../../rest/effects/card/card/cardDelete';
import { cardsUpdate } from '../../../../store/model/actionCreators/cardsUpdate';
import { getCard } from '../../../../store/model/selectors/getCard';
import { snackbarUndoWithAlternativeAction } from '../../snackbarsStack/effects/variantUndo/snackbarUndoWithAlternativeAction';
import { SNACKBAR_CARD_ARCHIVE_ID } from '../../aside_panel/cardDetails/DeleteButtonSection/hocs/DeleteButtonHOC/constants';
import { getMessages } from '../../../../store/constants';
import { SNACKBAR_BUTTON_DELETE } from '../../snackbarsStack/effects/constants';
import { SNACKBAR_CARDS_ARCHIVED_TEXT } from '../../aside_panel/multipleCards/Actions/ActionsSection/hocs/ActionsHOC/constants';
import { ICards } from '../../../../store/model/cards/types';
import { TStatus } from '../../../../types/model';
import { cardsArchive } from '../../../../rest/effects/card/card/cardsArchive';
import { ESnackbarPosition } from '../../snackbarsStack/store/types';

export const cardsArchiveWithPrompts = (
    cardIds: TCardId[] = [],
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        const state = getState();
        return new Promise<void>((resolve, reject) => {
            const cardForUndo: ICards = {};
            const cardForUpdate: ICards = {};
            cardIds.forEach(cardId => {
                const card = getCard(state, cardId);
                if (!card) throw new Error('Unknown cardId');
                cardForUndo[cardId] = {
                    status: card.status
                };
                cardForUpdate[cardId] = {
                    status: TStatus.STATUS_ARCHIVE
                }
            })

            dispatch(cardsUpdate(cardForUpdate));

            const actionApply = () => {
                try {
                    dispatch(cardsArchive(cardIds));
                    dispatch(segmentTrackAction(SegmentCardEvent.ARCHIVE_CARD_PROCEED));
                } catch (e) {
                    return reject(e);
                }
                resolve();
            };

            const actionAlternative = () => {
                try {
                    cardIds.forEach((cardId) => {
                        dispatch(cardDelete(cardId));
                        dispatch(segmentTrackAction(SegmentCardEvent.DELETE_ON_ARCHIVE_CARD_PROCEED));
                    })
                } catch (e) {
                    return reject(e);
                }
                resolve();
            };

            const actionUndo = () => {
                dispatch(cardsUpdate(cardForUndo));
                dispatch(segmentTrackAction(SegmentCardEvent.ARCHIVE_CARD_CANCELED));
            }

            dispatch(segmentTrackAction(SegmentArchiveEvent.CARD_ARCHIVED));
            dispatch(snackbarUndoWithAlternativeAction({
                id: SNACKBAR_CARD_ARCHIVE_ID,
                text: getMessages().getText(SNACKBAR_CARDS_ARCHIVED_TEXT, null, cardIds.length),
                actionApply,
                actionUndo,
                actionAlternative,
                alternativeButton: SNACKBAR_BUTTON_DELETE
            }, true));
        });
    }
    return action;
};
