import * as React from 'react';
import { Switch } from 'kui';
import { ISectionTogglerProps } from './types';
import './_SectionToggler.scss';

export const SectionToggler = ({
    checked,
    isDisabled,
    isDisallowed,
    onLoad,
    onChange,
    onClickDisallow,
}: ISectionTogglerProps) => {
    const className = 'section-toggler';
    const classDisallow = className + '__disallow';
    React.useEffect(() => {
        if (onLoad)
            onLoad();
    }, []);
    return (
        <div className={className}>
            <Switch
                checked={checked}
                disabled={isDisabled}
                onChange={onChange}
            />
            {isDisallowed && onClickDisallow &&
                <div
                    className={classDisallow}
                    onClick={onClickDisallow}
                />
            }
        </div>
    );
};
