import { segmentTrackAction, SegmentNotificationsEvent } from '../../../../../../../../middlewares/segment';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';

export const onSuccess = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(segmentTrackAction(SegmentNotificationsEvent.FORCE_SUCCESS));
    };
    return action;
};
