import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState, TBoardId } from '../../../../../../../types/types';
import { ILogo } from '../../../../../base/components/Logo/components/Logo/types';
import { boardPatchRest } from '../../../../../../../rest/effects/board/api/helpers/boardPatchRest';
import { refreshRecentOpenBoard } from '../../../../../../../rest/effects/authUser/recentBoards/refreshRecentOpenBoard';

export const onLogoChange = (
    boardId: TBoardId,
    logo: ILogo
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(boardPatchRest(boardId, { logoIcon: logo.icon, logoColor: logo.color })).then(() => {
            dispatch(refreshRecentOpenBoard(boardId));
        });
    };
    return action;
};
