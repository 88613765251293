import { TCardId } from '../../types/types';

export const getCardIdFromUrl = (
    url: string
): TCardId => {
    let card = url.match(/\/c-\d{16}/);
    let cardId = card && Number(card[0].slice(3));

    if (!card || !cardId) { // LinkCard
        card = url.match(/\/cl-\d{16}/);
        cardId = card && Number(card[0].slice(4));
    }

    if (!card || !cardId) { // MyWork
        card = url.match(/\/atm-\d{16}/);
        cardId = card && Number(card[0].slice(5));
    }
    return cardId;
}
