export const stripHtml = (
    html: string,
    options?: {
        exeptBr?: boolean,
    },
): string => {
    if (!html) return html;
    const regex = options && options.exeptBr ? /<(?!br\s*\/?)[^>]+>/g : /<[^>]*>/g;
    let htmlFree = html.replace(regex, ' ');
    return convertHTMLEntity(htmlFree);
};

export const convertHTMLEntity = (
    text: string
): string => {
    const span = document.createElement('span');
    return text
    .replace(/&[#A-Za-z0-9]+;/gi, (entity, position, text) => {
        span.innerHTML = entity;
        return span.innerText;
    });
}
