import { IUpdateAction } from './types';
import { ICard } from '../types';
import { AT_UPDATE } from './constants';

export const updateAction = (
    card: ICard
): IUpdateAction => {
    return {
        type: AT_UPDATE,
        card
    }
};
