import { connect } from 'react-redux';
import { EMPTY_ARRAY, IApplicationState } from '../../../../../../../types/types';
import { ICardChecklistHOCProps } from './types';
import { ICardChecklistEvents, ICardChecklistFields } from '../../components/CardChecklist/types';
import { CardChecklist } from '../../components/CardChecklist/CardChecklist';
import { getSectionType } from '../../../selectors/getSectionType';
import { getChecklistSection } from './selectors/getChecklistSection';
import {
    getBoardIsCardDefaultProperty
} from '../../../../../../../store/model/board/selectors/getBoardIsCardDefaultProperty';
import { EAsidePanelProperty } from '../../../../asidePanel/components/AsidePanel/types';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { CHECKLIST_DEFAULT } from '../../../../../../../store/model/checklists/checklists/constants';
import { getChecklistItemsSection } from '../selectors/getChecklistItemsSection';
import { onNameChange } from './events/onNameChange';
import {
    getAuthUserIsHideDoneChecklistItems
} from '../../../../../../../store/model/authUser/selectors/getAuthUserIsHideDoneChecklistItems';
import { TStatus } from '../../../../../../../types/model';
import { getCard } from '../../../../../../../store/model/selectors/getCard';

const mapStateToProps = () => {
    const getChecklist = getChecklistSection();
    const getChecklistItems = getChecklistItemsSection();

    return (
        state: IApplicationState,
        ownProps: ICardChecklistHOCProps
    ): ICardChecklistFields => {
        const { boardId, cardId, checklistId, isSingle, isOpened } = ownProps;
        const sectionType = getSectionType(state, boardId, cardId);
        let checklist = getChecklist(state, cardId, checklistId);
        if (!checklist && !checklistId) {
            checklist = {
                ...CHECKLIST_DEFAULT,
                cardId,
            };
        }
        const { status } = getCard(state, cardId);
        const checklistItems = getChecklistItems(state, cardId, checklistId);

        return {
            checklist,
            checklistItems: checklistItems && checklistItems.length
                ? checklistItems
                : sectionType !== ESettingsSectionType.READONLY &&
                getBoardIsCardDefaultProperty(state, boardId, EAsidePanelProperty.CARD_CHECKLIST)
                    ? EMPTY_ARRAY
                    :  null,
            isArchive: status === TStatus.STATUS_ARCHIVE,
            isHideDoneChecklistItems: getAuthUserIsHideDoneChecklistItems(state),
            isSingle,
            isOpened,
            sectionType
        }
    };
};

const mapDispatchToProps = (
    dispatch: any,
    { onAddOpen, onAddClose, cardId, checklistId, onDelete }: ICardChecklistHOCProps
): ICardChecklistEvents => {
    return {
        onAddOpen,
        onAddClose,
        onDelete,
        onNameChange: (name) => dispatch(onNameChange(cardId, checklistId, name))
    };
};

export const CardChecklistHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardChecklist);

CardChecklistHOC.displayName = 'CardChecklistHOC';
