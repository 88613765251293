import * as React from 'react';
import { IArchiveProps } from './types';
import './_Archive.scss';
import { Icon, Tooltip } from 'kui';
import { CLASS_BOARD_DETAILS } from '../../../../constants';
import { BOARD_ICON_ARCHIVED } from './constants';

export function Archive ({
    isShow,
}: IArchiveProps) {
    if (!isShow) return null;

    const className = CLASS_BOARD_DETAILS + '__archive';
    const classIcon = CLASS_BOARD_DETAILS + '__archive-icon';

    return (
        <Tooltip
            value={BOARD_ICON_ARCHIVED}
        >
            <div className={className}>
                <Icon
                    className={classIcon}
                    size={24}
                    xlink={'archive'}
                />
            </div>
        </Tooltip>
    );
};
