import { IGetState, TCardId } from 'app/types/types';
import { Dispatch, ThunkAction } from 'app/types/actions';
import { msFilePickerInstance } from 'app/helper/authorisation/microsoft/filePicker';
import { getFile } from 'app/helper/authorisation/microsoft/graph/api/getFile';
import { IDriveDoc } from 'app/store/model/card/types/IDriveDoc';
import { getDriveDocsByCardId } from 'app/store/model/driveDocs/selectors/getDriveDocsByCardId';
import { DriveItem } from '@microsoft/microsoft-graph-types';
import { ERestDriveDocType } from 'app/types/rest/IRestDriveDoc';
import { EAuthUserPlatformType } from 'app/types/rest/IRestAuthUser';
import { msAuthManagerInstance } from 'app/helper/authorisation/microsoft/oauth/MSAuthManager';
import {
    SegmentUserEvent,
    SegmentUserOption,
    SegmentUserOptionValue
} from 'app/middlewares/segment/trackEntities/userEvents';
import { segmentTrackAction } from 'app/middlewares/segment';
import { getBoardByCardId } from 'app/store/model/selectors/getBoardByCardId';
import { getIsBoardOnMyDrive } from 'app/store/model/board/selectors/getIsBoardOnMyDrive';
import { snackbarErrorDefault } from 'app/view/react_components/snackbarsStack';
import { getMessages } from 'app/store/constants';

export const selectFiles = (
    cardId: TCardId,
    type: ERestDriveDocType = ERestDriveDocType.COMMENT,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const board = getBoardByCardId(getState(), cardId);
        if (!getIsBoardOnMyDrive(getState(), board.id)) {

            dispatch(snackbarErrorDefault({
                id: 'SNACKBAR_ID_MS_FILE_PICKER_SHARED_DRIVE_ERROR',
                text: getMessages().getText('snackbar.ms_file_picker.shared_drive_error'),
            }));

            return Promise.reject();
        }

        return dispatch(checkPermissions())
            .then(() => {
                return msFilePickerInstance.init();
            })
            .then((result: DriveItem[]) => {
                if (!result || !result.length) return null;

                const driveDocs: IDriveDoc[] = getDriveDocsByCardId(getState(), cardId);
                let orderNumber = driveDocs ? driveDocs.length : 0;

                let filePromises = result.map(item => {
                    return getFile(item.parentReference.driveId, item.id);
                })

                return Promise.all(filePromises)
                    .then((files: DriveItem[]) => {
                        const driveDocs = files.map(file => createDriveDoc(file, cardId, orderNumber++, type));
                        return driveDocs;
                    })
            });
    };
    return action;
};

const checkPermissions = (): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        return new Promise((resolve, reject) => {
            const eventOpt = {name: SegmentUserOption.TAGRET, value: SegmentUserOptionValue.ATTACHMENTS};
            const onShow = () => {
                dispatch(segmentTrackAction(SegmentUserEvent.PERMISSION_DRIVE_SCOPE_SHOWN, eventOpt));
            };
            const onRequest = () => {
                dispatch(segmentTrackAction(SegmentUserEvent.PERMISSION_DRIVE_SCOPE_REQUESTED, eventOpt));
            };
            const onCancel = () => {
                dispatch(segmentTrackAction(SegmentUserEvent.PERMISSION_DRIVE_SCOPE_CANCELED, eventOpt));
                reject();
            };
            const onApprove = () => {
                dispatch(segmentTrackAction(SegmentUserEvent.PERMISSION_DRIVE_SCOPE_GRANTED, eventOpt));
                resolve(true);
            };
            msAuthManagerInstance.checkAndRequestFilePickerDrive(onApprove, onShow, onCancel, onRequest);
        });
    };
    return action;
};

const createDriveDoc = (
    file: DriveItem,
    cardId: TCardId,
    orderNumber: number,
    type: ERestDriveDocType,
): IDriveDoc => {
    const mimeType = file.file && file.file.mimeType;
    const isImage = !!file.image || mimeType.includes('image/');

    const driveDoc: IDriveDoc = {
        cardId,
        orderNumber,
        type,

        driveId: file.parentReference && file.parentReference.driveId,
        fileId: file.id,
        title: file.name,
        isImage,
        mimeType,
        platformType: EAuthUserPlatformType.MICROSOFT,
        url: file.webUrl,
        //iconUrl,
    };
    return driveDoc;
}
