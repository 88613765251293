import React from "react";
import { Provider } from "react-redux";
import store from "../../store/configureStore";
import { ListGroupingDashboardView } from './team_workload_dashboard';

export const TagsDashboardView = ListGroupingDashboardView.extend({
    onImportFail: function() {
        dispatch(snackbarErrorReload(false));
        dispatch(segmentTrackAction(SegmentErrorEvent.LAZY_IMPORT_ERROR, {
            name: SegmentErrorOptions.SOURCE,
            value: 'ListGroupTags'
        }));
    },

    render: function() {
        const ImportElement = React.lazy(() =>
            import(/* webpackChunkName: "kanban" */ '../react_components/teamWorkload/teamWorkloadView/hocs/TagsViewHOC/TagsViewHOC')
                .then(module => ({default: module.TagsViewHOC}))
                .catch(() => this.onImportFail())
        );
        this.root.render(
            <Provider store={store}>
                <ImportElement boardId={this.boardId} />
            </Provider>
        );
        return this;
    },

});
