import { IActivities } from '../types';
import { AT_ACTIVITIES_UPDATE } from './constants';
import { IActivitiesUpdateAction } from './types';

export const activitiesUpdateAction = (
    activities: IActivities
): IActivitiesUpdateAction => ({
        type: AT_ACTIVITIES_UPDATE,
        activities
    }
);
