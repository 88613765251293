import * as React from 'react';
import { Button, ButtonTitle, Checkbox, Icon } from 'kui';
import { ISearchSelectOptionProps } from './types';
import { CLASS_SEARCH_SELECT_ITEM } from './constants';

export function SearchSelectOption ({
    className: classNameOwn,
    multiple,
    isMultipleGroupToggle,
    checkboxDirection,
    option,
    ...attributes
}: ISearchSelectOptionProps) {

    const {
        active,
        disabled,
        isCollapsed,
        isIndeterminate,
        level,
        options,
        text,
        value,
        onLevelToggle
    } = attributes;
    const className = CLASS_SEARCH_SELECT_ITEM;
    const classNameActive = active ? `${className}--active` : '';
    const classNameDisabled = disabled ? `disabled` : '';
    const classNameLevels = level !== undefined ? `${className}--levels` : '';
    const classNameLevel = level ? `${className}--level_${level}` : '';
    const classNameGroupHeader = className + '__group-header';
    const classNameNoGroupCheckbox = !isMultipleGroupToggle && !!options ? className + '--no-checkbox' : '';

    const classNames = [className, classNameActive, classNameDisabled, classNameLevels, classNameLevel]
        .filter(className => !!className)
        .join(' ');

    const optionDefault = ({
        text,
        value,
        disabled,
        className
    }: ISearchSelectOptionProps) => {
        const classNameDisabled = disabled ? 'disabled' : '';
        return (
            <li
                key={value}
                value={value}
                className={`
                    ${className ? className : ''}
                    ${classNameDisabled}
                    ${classNameOwn || ''}
                `}
            >
                {text}
            </li>
        );
    };

    option = option || optionDefault;

    return (
        multiple
            ? <Checkbox
                checked={active}
                className={`
                    ${classNames}
                    ${classNameNoGroupCheckbox}
                    ${classNameOwn || ''}
                `}
                color={'light'}
                direction={checkboxDirection}
                disabled={disabled}
                isIndeterminate={isIndeterminate}
                isStateless={!!options}
                key={value}
                value={value}
                onChange={attributes.onChange || (() => {})}
            >
                {
                    !!options
                        ? <Button
                            variant={'icon-text'}
                            onClick={() => onLevelToggle(value)}
                        >
                            <ButtonTitle>{text}</ButtonTitle>
                            <Icon
                                xlink={isCollapsed ? 'arrow-s-drop-open' : 'arrow-s-drop-close'}
                                size={ 24 }
                            />
                        </Button>
                        : option(attributes)
                }
            </Checkbox>
            : (
                !!options
                    ? <li
                        className={`
                            ${classNameLevels}
                            ${classNameLevel}
                            ${classNameGroupHeader}
                            ${classNameGroupHeader}--${value}
                            disabled
                        `}
                        key={value}
                    >{text}</li>
                    : option({ ...attributes, className: classNames })
            )
    );
};
