import { IGetState, TBoardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { fetchHandler } from '../../../../util/fetchHandler';
import { Util } from '../../../../util/util';
import { REST_BOARD, REST_BOARD_PERMISSIONS } from '../../../constants';
import { getRestHeadersGet } from '../../../helpers/getRestHeadersHelper';
import { IPermissions } from '../../../../types/model';

export const fetchPermissionsRest = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return fetchHandler<IPermissions>(
            Util.getApiUrl(REST_BOARD + boardId + REST_BOARD_PERMISSIONS),
            getRestHeadersGet()
        );
    };
    return action;
};
