import { getCardCommentRestUrl } from '../../../../constants';
import { TCardId } from '../../../../../types/types';
import { ThunkAction } from '../../../../../types/actions';
import { fetchHandler } from '../../../../../util/fetchHandler';
import { Util } from '../../../../../util/util';
import { getRestHeadersPost } from '../../../../helpers/getRestHeadersHelper';
import { IComment } from '../../../../../store/model/card/types/IComment';

export const postRest = (
    comment: IComment,
): ThunkAction => {
    const action = (
    ): Promise<IComment> => {
        const cardId: TCardId = comment.cardId;
        return fetchHandler<IComment>(
            Util.getApiUrl(getCardCommentRestUrl(cardId)), {
                ...getRestHeadersPost(),
                body: JSON.stringify(comment)
            }
        );
    };
    return action;
};
