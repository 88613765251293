import { IGetState, TBoardId } from '../../../types/types';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { boardPatchRest } from './api/helpers/boardPatchRest';
import { IBoardMeta } from '../../../store/model/board/types';

export const boardUpdateMeta = (
    boardId: TBoardId,
    meta: Partial<IBoardMeta>,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(boardPatchRest(boardId, { meta }));
    };
    return action;
}
