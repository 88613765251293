import { REST_PREDECESSOR } from '../../../../constants';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { fetchHandler } from '../../../../../util/fetchHandler';
import { IRestCard } from '../../../../../types/rest/IRestCard';
import Util from '../../../../../util/util';
import { getRestHeadersPatch } from '../../../../helpers/getRestHeadersHelper';
import { IRestPredecessor } from '../../../../../types/rest/IRestPredecessor';
import { IDependency } from '../../../../../store/model/dependencies/dependency/types';
import { IGetState, TBoardId } from '../../../../../types/types';

export const patchRest = (
    dependency: IDependency
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const body = dependencyToRestPredecessor(dependency);

        return fetchHandler<IRestCard>(
            Util.getApiUrl(REST_PREDECESSOR + '/' + dependency.id), {
                ...getRestHeadersPatch(),
                body: JSON.stringify(body)
            }
        );
    };
    return action;
};

const dependencyToRestPredecessor = (
    dependency: IDependency
) => {
    const restPredecessor: IRestPredecessor = {};
    if (dependency.id !== undefined){
        restPredecessor.id = dependency.id;
    }
    if (dependency.type !== undefined){
        restPredecessor.type = dependency.type;
    }
    return restPredecessor;
};
