import { IApplicationState, TBoardId, TCardId } from '../../../types/types';
import { getCard } from './getCard';
import { getList } from '../list/selectors/getList';

export const getBoardIdByCardId = (
    state: IApplicationState,
    cardId: TCardId
): TBoardId => {
    const card = getCard(state, cardId);
    if (!card) return null;

    const list = getList(state, card.listId);
    if (!list) return null;

    return list.dashboardId;
};
