import { IBoardExportSettingsColumn } from '../../../components/BoardExportSettings/types';
import { ThunkAction } from '../../../../../../../../types/actions';
import { boardSetExportSettingsColumns } from '../../../../../../../../rest/effects/board/boardSetExportSettingsColumns';
import { TBoardId } from '../../../../../../../../types/types';

export const saveColumns = (
    boardId: TBoardId,
    columns: IBoardExportSettingsColumn[]
): ThunkAction => {
    return (
        dispatch
    ) => {
        return dispatch(boardSetExportSettingsColumns(boardId, columns.map(column => ({
            id: column.id,
            enabled: column.enabled
        }))));
    }
}
