/**
 * При изменении цветов, также поменять цвета в ./_CardProgress.scss
 */

export const CARD_PROGRESS_COLOR_SUCCESS = '#4CAF50' // $color-green;
export const CARD_PROGRESS_COLOR_BG = 'rgba(38, 45, 56, 0.3)' // $color-base 30%
export const CARD_PROGRESS_LIGHT_COLOR_BG = 'rgba(38, 45, 56, 0.1)' // $color-base 10%
export const CARD_PROGRESS_COLOR_BG_HOVER = 'rgba(38, 45, 56, 0.6)' // $color-base 60%
export const CARD_PROGRESS_LIGHT_COLOR_BG_HOVER = 'rgba(38, 45, 56, 0.5)' // $color-base 50%
export const CARD_PROGRESS_COLOR_SUCCESS_LIGHT = 'rgba(255, 255, 255, 0.9)' // $color-white 90%;
export const CARD_PROGRESS_COLOR_SUCCESS_DARK = 'rgba(38, 45, 56, 0.9)' // $color-base 90%;
export const CARD_PROGRESS_COLOR_BG_LIGHT = 'rgba(255, 255, 255, 0.2)' // $color-white 20%
export const CARD_PROGRESS_COLOR_BG_LIGHT_HOVER = 'rgba(255, 255, 255, 0.4)' // $color-white 40%

export const CARD_PROGRESS_DARK_BACKGROUND_COLORS: ReadonlyArray<string> = Object.freeze([
    '#ad1457',
    '#304ffe',
    '#4caf50',
    '#5E1172',
    '#AD82EA',
    '#2196f3',
    '#aa00ff',
    '#9A9CFF',
    '#00bcd4',
    '#546e7a',
    '#9e9d24',
]);

export const CARD_PROGRESS_LIGHT_BACKGROUND_COLORS: ReadonlyArray<string> = Object.freeze([
    '#FFAB80',
    '#90caf9',
    '#98DE6F',
    '#ff9800',
    '#FAD165',
    '#F08577',
    '#FEB3B3',
    '#C6DEFB',
    '#51E898',
    '#00e676',
    '#F5BE7A',
    '#fdd835',
    '#F691B2',
    '#FF9280',
    '#9FE1E7',
    '#76ff03',
    '#69D1A2',
    '#90B3EB',
    '#B99AFF',
    '#FBE983',
    '#d7ccc8',
    '#D09E8D',
    '#00e5ff',
    '#A4EEED',
    '#C8F59A',
    '#91E6C0',
    '#E190F8',
    '#D1BFC2',
    '#eeff41',
    '#64ffda',
    '#d4e157',
    '#C0C6CF',
    '#BFC1B0',
]);
