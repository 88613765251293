import { INavigationPanelTabItemProps } from './types';
import { Button, ButtonDropdown, Icon, Input, Loader, SelectList, SelectListItem, Tooltip } from 'kui';
import * as React from 'react';
import { getParentsClasses } from 'app/view/react_components/helpers/getParentsClasses';
import {
    ENavigationPanelTypes,
    NAVIGATION_PANEL_CLOSE_MARKER,
    NAVIGATION_PANEL_CONTAINER_CLASS,
    NAVIGATION_PANEL_DROPDOWN_CLASS,
} from '../../constants';
import './_NavigationPanelTabItem.scss';
import {
    NAVIGATION_PANEL_DASHBOARD_ACTION_CLASS,
    NAVIGATION_PANEL_HINT,
    TOOLTIP_BOARD_SWITCH,
    TOOLTIP_CURRENT_BOARD
} from './constants';
import {
    TOPBAR_BOARD_ACTIVITY,
    TOPBAR_BOARD_ADD_TO_STARRED,
    TOPBAR_BOARD_CHANGE_LOGO,
    TOPBAR_BOARD_CLOSE,
    TOPBAR_BOARD_NAME,
    TOPBAR_BOARD_REMOVE_FROM_STARRED,
    TOPBAR_BOARD_RENAME,
    TOPBAR_BOARD_SETTINGS,
    TOPBAR_CARD_TEMPLATES,
    TOPBAR_RECURRING_TASKS
} from '../../../topBar/components/TopBarDashboardName/constants';
import { EViewTypes } from 'app/const';
import { EHintKey, EHintStatus, HintHOC } from 'app/view/react_components/hints';
import { LIST_CONTAINER_CLASS } from 'app/view/react_components/main/kanbanView';
import { BasicFeatureIndicator } from '../../../../base/components/BasicFeatureIndicator/BasicFeatureIndicator';
import { CLASS_LOGO } from '../../../../base/components/Logo/components/constants';
import { Logo } from '../../../../base/components/Logo/components/Logo/Logo';
import { ProFeatureIndicator } from '../../../../base/components/ProFeatureIndicator/ProFeatureIndicator';
import { markdown } from 'app/helper/markdownHelper';
import { ScrollText } from 'app/view/react_components/base/components/ScrollText/ScrollText';
import { IScrollTarget } from '../../../../base/components/ScrollText/types';

export const NavigationPanelTabItem: React.FC<INavigationPanelTabItemProps> = ({
    boardScrollHintStatus,
    isActive,
    isBasicFeature,
    isBlocked,
    isProFeature,
    isNewUser,
    name,
    navigationPanelType,
    recurringCount,
    starred,
    templatesCount,
    onBoardActivityClicked,
    onBoardSettingsClicked,
    onBoardMenuClose,
    onBoardMenuIconClicked,
    isReadOnly,
    isLong,
    logo,
    logoColor,
    logoIcon,
    onClickCardTemplates,
    onClickRecurringTasks,
    onClickReports,
    onClickStarred,
    onClickChangeLogo,
    onCloseHint,
    onNameChanged,
    onLogoChange,
    onHintShow,
    viewType
}) => {
    const [markdownName, setMarkdownName] = React.useState('');
    const [title, setTitle] = React.useState('');
    const [editName, setEditName] = React.useState(name);
    const [isEditing, setEditing] = React.useState(false);
    const [isSyncing, setSyncing] = React.useState(false);
    const [isSynced, setSynced] = React.useState(null);
    const [showHint, setShowHint] = React.useState(null);
    let [isOpen, _setOpen] = React.useState(false);
    const [scrollTarget, setScrollTarget] = React.useState({} as IScrollTarget);

    const inputRef = React.useRef(null);
    const buttonRef = React.useRef(null);
    const doubleClick = React.useRef(null);

    const className = 'navigation-panel__dashboard';
    const classContainer = className + '-container';
    const classActive = isActive ? className + '--active' : '';
    const classLogo = className + '-logo';
    const classHint =  className + '-hint';

    const isMobile = navigationPanelType === ENavigationPanelTypes.NP_MOBILE_TYPE;

    const setOpen = (val: boolean) => {
        isOpen = val;
        _setOpen(val);
    }
    const onClose = () => setTimeout(() => setOpen(false), 200);
    const onOpen = () => {
        setOpen(true);
        setTimeout(() => {
            const el = document.querySelector('.navigation-panel__dashboard-menu-button') as HTMLElement;
            if (el) el.focus();
        }, 100);
    };

    const onClickHandler = (e: any) => {
        if (isEditing || e.target.closest(`.navigation-panel__dashboard-menu-button`)) return;
        const element = viewType === EViewTypes.KANBAN_VIEW_TYPE ? window : document.querySelector('.board-view');
        if (element) {
            if (doubleClick.current) clearTimeout(doubleClick.current);
            doubleClick.current = setTimeout(() => {
                element.scroll({
                    left: 0,
                    behavior: 'smooth',
                });
            }, 200)
        }
    };

    const onKeyDown = (e: React.KeyboardEvent) => {
        if (isEditing) return;
        if (!e) return;
        if (e.key === 'Enter') {
            onClickHandler(e as any);
        }
    }

    const onClickRename = () => {
        setEditing(true);
        const input = inputRef.current.getInput();
        if (input) {
            input.focus();
            input.select();
        }
    }

    function syncName (name: string) {
        setEditing(false);
        setSyncing(true);
        onNameChanged(name)
            .then(() => {
                setSynced(true);
                setTimeout(() => {
                    setSynced(false);
                    setSyncing(false);
                }, 1000);
            })
            .catch(() => setSyncing(false));
    }

    const handleInputKeydown = (e: React.KeyboardEvent) => {
        if (e.key === 'Escape') {
            setEditName(name);
            syncName(name);
        }
        if (e.key === 'Enter') {
            buttonRef.current.focus();
        }
    };

    function handleInputBlur (e: React.FocusEvent) {
        if (e.relatedTarget) {
            const parentClasses = getParentsClasses(e.relatedTarget as HTMLElement, [CLASS_LOGO]);
            if (parentClasses.includes(CLASS_LOGO)) return;
        }
        syncName(editName);
    };

    const onIconBlur = (e: React.FocusEvent) => {
        if (e.relatedTarget) {
            const targetClass = className + '-input';
            const parentClasses = getParentsClasses(e.relatedTarget as HTMLElement, [className]);
            if (parentClasses.includes(targetClass)) return;
        }
        syncName(editName);
    }

    function handleInputChange (e: React.SyntheticEvent) {
        const target = e.target as HTMLInputElement;
        setEditName(target.value);
    }

    function handleDblClick (e: React.MouseEvent<HTMLElement>) {
        if (isEditing) return;

        const element = e.target as HTMLElement;
        const path = getParentsClasses(element, [
            NAVIGATION_PANEL_DASHBOARD_ACTION_CLASS,
            className
        ]);
        if (path.includes(NAVIGATION_PANEL_DASHBOARD_ACTION_CLASS)) {
            return;
        }

        if (doubleClick.current) clearTimeout(doubleClick.current);
        onClickRename();
    }

    React.useEffect(() => {
        const markdownName = markdown(name);
        setMarkdownName(markdownName);
        setTitle(isMobile ? null : isActive
            ? TOOLTIP_CURRENT_BOARD
            : TOOLTIP_BOARD_SWITCH + ' ' + markdownName
        );
    }, [name, isActive, isMobile]);

    React.useEffect(() => {
        setEditName(name);
    }, [name]);

    React.useEffect(() => {
        if (boardScrollHintStatus === EHintStatus.NOT_SHOWN) {
            let scrollX: number;
            const onScroll = () => {
                const lists = document.querySelectorAll(`.${LIST_CONTAINER_CLASS}`);
                const lastList = lists[lists.length - 1];
                const lastListRect = lastList.getBoundingClientRect();
                if (lastListRect.right < document.documentElement.clientWidth && scrollX > window.scrollX) {
                    setShowHint(true)
                }
                scrollX = window.scrollX
            }
            document.addEventListener('scroll', onScroll)
            return () => {
                document.removeEventListener('scroll', onScroll);
            }
        }
    }, [])

    return (<div className={classContainer}>
        <div
            className={`
                navigation-panel__row
                ${className}
                ${classActive}
                ${isEditing ? className + '--editing' : ''}
                ${isSyncing ? className + '--syncing' : ''}
                ${isSynced ? className + '--synced' : ''}
            `}
            aria-selected={isActive}
            aria-label={title}
            role={'button'}
            tabIndex={0}
            onClick={onClickHandler}
            onDoubleClick={handleDblClick}
            onKeyDown={onKeyDown}
            ref={buttonRef}
        >
            <div className="navigation-panel__dashboard-button">
                <Tooltip
                    className={`
                        navigation-panel__tooltip
                        navigation-panel__tooltip--tab
                        ${isLong ? 'navigation-panel__tooltip-tab--long' : ''}
                    `}
                    direction={'right'}
                    portalId={'navigation-panel-portal'}
                    portalSelector={'.' + NAVIGATION_PANEL_CONTAINER_CLASS}
                    value={title}
                >
                    <span className={classLogo + '-wrapper'}>
                        {logo
                            ? <div
                                className={`${classLogo} ${classLogo}--custom ${NAVIGATION_PANEL_CLOSE_MARKER}`}
                                style={{backgroundImage: `url("${logo}")`}}
                            />
                            : <Logo
                                name={markdownName}
                                logo={{ icon: logoIcon, color: logoColor }}
                                className={NAVIGATION_PANEL_CLOSE_MARKER}
                                isSelect={isEditing}
                                onBlur={onIconBlur}
                                onChange={onLogoChange}
                            />
                        }
                        <div
                            className={ 'navigation-panel__dashboard-name ' + NAVIGATION_PANEL_CLOSE_MARKER}
                        >
                            <ScrollText
                                text={markdownName}
                                scrollTarget={scrollTarget}
                            />
                        </div>
                    </span>
                </Tooltip>
                <Input
                    ref={inputRef}
                    className={`${className}-input`}
                    value={editName}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    onKeyDown={handleInputKeydown}
                    autosize={false}
                    disabled={isReadOnly || isBlocked || isSyncing}
                    label={TOPBAR_BOARD_NAME}
                />
                <Icon xlink={'done'} size={16} className={`${className}-done`} />
                <div className={`${className}-loader`}>
                    <Loader />
                </div>
                <ButtonDropdown
                    className={NAVIGATION_PANEL_DASHBOARD_ACTION_CLASS + ' navigation-panel__dashboard-menu'}
                    directionHorizontal={'right'}
                    directionVertical={'down'}
                    dropdownClassName={`${NAVIGATION_PANEL_DROPDOWN_CLASS} navigation-panel__board-dd handle-click-ignore`}
                    isFitWindow
                    isMoveToFit
                    isScaleAnimation
                    opened={isOpen}
                    portal
                    onClick={onBoardMenuIconClicked}
                    onClose={onClose}
                    onOpen={onOpen}
                >
                    <Button
                        className={'navigation-panel__dashboard-menu-button'}
                        variant={'icon'}
                        onMouseOver={() => setScrollTarget({scrollToEnd: true})}
                        onMouseOut={(e) => {setScrollTarget({scrollToEnd: false, relatedTarget: e.relatedTarget})}}
                    >
                        <Icon size={24} xlink={'more'} />
                    </Button>
                    <SelectList
                        fixActive={false}
                        className={className + '-list'}
                    >
                        <SelectListItem
                            className={NAVIGATION_PANEL_DASHBOARD_ACTION_CLASS + ' ' + NAVIGATION_PANEL_CLOSE_MARKER}
                            icon={'settings'}
                            iconSize={24}
                            onClick={onBoardSettingsClicked}
                        >
                            <span>
                                {TOPBAR_BOARD_SETTINGS}
                            </span>
                        </SelectListItem>
                        <SelectListItem
                            className={NAVIGATION_PANEL_DASHBOARD_ACTION_CLASS + ' ' + NAVIGATION_PANEL_CLOSE_MARKER + ' divider'}
                            icon={'activity'}
                            iconSize={24}
                            onClick={onBoardActivityClicked}
                        >
                            <>
                                {TOPBAR_BOARD_ACTIVITY}
                                {isBasicFeature && <BasicFeatureIndicator />}
                                {isProFeature && <ProFeatureIndicator />}
                            </>
                        </SelectListItem>

                        {(!isReadOnly && !isBlocked) &&
                            <SelectListItem
                                icon={'rename'}
                                iconSize={24}
                                onClick={onClickRename}
                            >
                                {TOPBAR_BOARD_RENAME}
                            </SelectListItem>
                        }
                        {(!isReadOnly && !isBlocked) &&
                            <SelectListItem
                                icon={'kanbanchi-logo'}
                                iconSize={24}
                                onClick={onClickChangeLogo}
                            >
                                <>
                                    {TOPBAR_BOARD_CHANGE_LOGO}
                                    {isBasicFeature && <BasicFeatureIndicator />}
                                    {isProFeature && <ProFeatureIndicator />}
                                </>
                            </SelectListItem>
                        }
                        <SelectListItem
                            icon={'star'}
                            iconSize={24}
                            className={`${NAVIGATION_PANEL_DASHBOARD_ACTION_CLASS} divider`}
                            onClick={onClickStarred}
                        >
                            {starred ? TOPBAR_BOARD_REMOVE_FROM_STARRED : TOPBAR_BOARD_ADD_TO_STARRED}
                        </SelectListItem>

                        {!isReadOnly && !isBlocked &&
                            <SelectListItem
                                className={NAVIGATION_PANEL_DASHBOARD_ACTION_CLASS + ' ' + NAVIGATION_PANEL_CLOSE_MARKER}
                                icon={'board-template'}
                                iconSize={24}
                                list={!isBasicFeature && String(templatesCount)}
                                onClick={onClickCardTemplates}
                            >
                                <>
                                    {TOPBAR_CARD_TEMPLATES}
                                    {isBasicFeature && <BasicFeatureIndicator />}
                                </>
                            </SelectListItem>
                        }
                        {!isReadOnly && !isBlocked &&
                            <SelectListItem
                                className={NAVIGATION_PANEL_DASHBOARD_ACTION_CLASS + ' ' + NAVIGATION_PANEL_CLOSE_MARKER}
                                icon={'arrow-circle'}
                                iconSize={24}
                                list={!isBasicFeature && String(recurringCount)}
                                onClick={onClickRecurringTasks}
                            >
                                <>
                                    {TOPBAR_RECURRING_TASKS}
                                    {isBasicFeature && <BasicFeatureIndicator />}
                                </>
                            </SelectListItem>
                        }
                        <SelectListItem
                                className={NAVIGATION_PANEL_DASHBOARD_ACTION_CLASS + ' ' + NAVIGATION_PANEL_CLOSE_MARKER + ' reports-navigation divider'}
                                icon={'chart'}
                                iconSize={24}
                                onClick={onClickReports}
                            >
                                <>
                                    Reports
                                    {isBasicFeature && <BasicFeatureIndicator />}
                                </>
                        </SelectListItem>
                        <SelectListItem
                            className={NAVIGATION_PANEL_DASHBOARD_ACTION_CLASS}
                            icon={'close'}
                            iconSize={24}
                            onClick={onBoardMenuClose}
                        >
                            {TOPBAR_BOARD_CLOSE}
                        </SelectListItem>
                    </SelectList>
                </ButtonDropdown>
            </div>
        </div>
        {showHint && !isNewUser &&
            <HintHOC
                className={classHint}
                key={EHintKey.BOARD_SCROLL}
                hintKey={EHintKey.BOARD_SCROLL}
                show
                arrow={'down'}
                direction={'up'}
                value={NAVIGATION_PANEL_HINT}
                selector={`.${className}`}
                isPortal
                onShow={onHintShow}
                footer={
                    <Button
                        variant={'icon'}
                    >
                        <Icon
                            size={16}
                            xlink={'close'}
                            onClick={onCloseHint}
                        />
                    </Button>
                }
            />
        }
    </div>);
};
