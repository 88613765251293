import { ESnackbarPosition, ISnackbarControlModel, ISnackbarModel, SnackbarVariant } from '../../../store/types';
import { ISnackbarButtonProps, ISnackbarInheritedProps } from 'kui';
import { SNACKBAR_TIMER_DELETE, SNACKBAR_TIMER_INFO, SNACKBAR_TIMER_REFRESH, SNACKBAR_TIMER_UPGRADE } from '../constants';

const getSnackbarButtons = (
    snackbarModelControls: ISnackbarControlModel[]
): ISnackbarButtonProps[] => {
    const buttons: ISnackbarButtonProps[] =
    snackbarModelControls.map((button): ISnackbarButtonProps => {
        return {
            isPrimary: button.isPrimary,
            progress: button.progress,
            text: button.title,
            onClick: button.action,
            icon: button.icon
        };
    });
    return buttons;
};

const extendDefaultSnackbar = (
    snackbarModel: ISnackbarModel,
    extra: any
): ISnackbarInheritedProps => {
    return {
        className: snackbarModel.className,
        buttons: getSnackbarButtons(snackbarModel.controls),
        key: snackbarModel.id,
        text: snackbarModel.text,
        title: snackbarModel.title,
        variant: 'info',
        position: snackbarModel.position || ESnackbarPosition.center,
        ...extra
    };
};

const getErrorSnackbar = (
    snackbarModel: ISnackbarModel
): ISnackbarInheritedProps => {
    let options: Partial<ISnackbarInheritedProps> = {
        variant: 'error',
    }
    if (snackbarModel.timer && snackbarModel.timer > 0) {
        options.timer = snackbarModel.timer;
    }
    return extendDefaultSnackbar(
        snackbarModel,
        options,
    );
};

const getInfoSnackbar = (
    snackbarModel: ISnackbarModel
): ISnackbarInheritedProps => {
    const extra = snackbarModel.timer ?
        snackbarModel.timer > 0 ? { timer: snackbarModel.timer } : null
        : { timer: SNACKBAR_TIMER_INFO }
    return extendDefaultSnackbar(
        snackbarModel,
        extra
    );
};

const getProgressSnackbar = (
    snackbarModel: ISnackbarModel
): ISnackbarInheritedProps => {
    return extendDefaultSnackbar(
        snackbarModel,
        {
            variant: 'info'
        }
    );
};

const getPromtSnackbar = (
    snackbarModel: ISnackbarModel
): ISnackbarInheritedProps => {
    return extendDefaultSnackbar(
        snackbarModel,
        {
            variant: 'promt'
        }
    );
};

const getRefreshSnackbar = (
    snackbarModel: ISnackbarModel
): ISnackbarInheritedProps => {
    return extendDefaultSnackbar(
        snackbarModel,
        {
            icon: 'arrow-circle',
            timer: SNACKBAR_TIMER_REFRESH,
            variant: 'timer'
        }
    );
};

const getReloadSnackbar = (
    snackbarModel: ISnackbarModel
): ISnackbarInheritedProps => {
    return extendDefaultSnackbar(
        snackbarModel,
        {
            variant: 'error'
        }
    );
};

const getSuccessSnackbar = (
    snackbarModel: ISnackbarModel
): ISnackbarInheritedProps => {
    return extendDefaultSnackbar(
        snackbarModel,
        {
            timer: SNACKBAR_TIMER_INFO,
            variant: 'success'
        }
    );
};

const getTimerSnackbar = (
    snackbarModel: ISnackbarModel
): ISnackbarInheritedProps => {
    return extendDefaultSnackbar(
        snackbarModel,
        {
            timer: SNACKBAR_TIMER_DELETE,
            variant: 'timer'
        }
    );
};

const getUndoSnackbar = (
    snackbarModel: ISnackbarModel
): ISnackbarInheritedProps => {
    return extendDefaultSnackbar(
        snackbarModel,
        {
            timer: snackbarModel.timer ? snackbarModel.timer : SNACKBAR_TIMER_DELETE,
            variant: 'undo'
        }
    );
};

const getUpgradeSnackbar = (
    snackbarModel: ISnackbarModel
): ISnackbarInheritedProps => {
    return extendDefaultSnackbar(
        snackbarModel,
        {
            timer: SNACKBAR_TIMER_UPGRADE,
        }
    );
};

export const getSnackbarInheretedPropsByModel = (
    snackbarModel: ISnackbarModel
): ISnackbarInheritedProps => {
    switch (snackbarModel.variant) {
        case SnackbarVariant.ERROR:
            return getErrorSnackbar(snackbarModel);
        case SnackbarVariant.INFO:
            return getInfoSnackbar(snackbarModel);
        case SnackbarVariant.PROGRESS:
            return getProgressSnackbar(snackbarModel);
        case SnackbarVariant.PROMT:
            return getPromtSnackbar(snackbarModel);
        case SnackbarVariant.REFRESH:
            return getRefreshSnackbar(snackbarModel);
        case SnackbarVariant.RELOAD:
            return getReloadSnackbar(snackbarModel);
        case SnackbarVariant.SUCCESS:
            return getSuccessSnackbar(snackbarModel);
        case SnackbarVariant.TIMER:
            return getTimerSnackbar(snackbarModel);
        case SnackbarVariant.UPGRADE:
            return getUpgradeSnackbar(snackbarModel);
        case SnackbarVariant.UNDO:
            return getUndoSnackbar(snackbarModel);
        default:
            return getInfoSnackbar(snackbarModel);
    }
}
