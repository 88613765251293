import { IApplicationState, TBoardId } from '../../../../../../types/types';
import { ISharedUser } from '../../../../../../types/rest/ISharedUser';
import { ICommentMention } from '../../../../../../store/model/card/types/IComment';
import { getAppState } from '../../../../../../store/configureStore';
import { getCommentValue } from '../selectors/getCommentValue';
import { getBoardUsers } from '../../../../../../store/model/selectors/getBoardUsers';
import { root } from 'app/store/constants';
import { getInitials } from 'app/view/react_components/helpers/userPhotoHelper';

export const makeMentionByName = (
    name: string,
): string => {
    return '@' + name.replace(/ /ig, '.');
};

export const getMentions = (
    state: IApplicationState,
    boardId: TBoardId,
    commentText: string
): string[] => {
    // const userMentions = getBoardMentions(state, boardId); // тут какой-то замкнутый круг, по идее надо взять юзеров доски и сматчить с текстом
    const users = getBoardUsers(state, boardId).filter(user => !!user.firstName || !!user.lastName);
    const userMentions = users.map(user => userToMention(user));
    const matchUsers = commentText.match(/(\s|^)(@[\S]*[^\s.,]+)/gmi);

    if (Array.isArray(matchUsers)) {
        const mentions = new Set<string>();
        matchUsers.forEach((matchUser: string) => {
            const userFinds = userMentions.find((user) => {
                return user && user.mention === matchUser.trim();
            });
            if (userFinds) {
                mentions.add(userFinds.permissionId);
            }
        });
        return Array.from(mentions);
    }
    return [];
};

export const userToMention = (
    user: ISharedUser
): ICommentMention => {
    return {
        initials: getInitials(user),
        name: user.fullName,
        mention: makeMentionByName(user.fullName),
        permissionId: user.permissionId,
        photoUrl: user.photoUrl
    };
};

export const findEditedMention = (cursorPosition: number): string => {
    if (cursorPosition === null || cursorPosition === undefined) { // html
        const selection = root.getSelection();
        if (!selection || !selection.anchorNode || !selection.anchorNode.nodeValue) {
            return '';
        }
        cursorPosition = selection.anchorOffset;
        const strBeforeCursor = selection.anchorNode.nodeValue.substring(0, cursorPosition);

        const mentionStart = strBeforeCursor.lastIndexOf('@'); // find last mention before cursor
        const mention = strBeforeCursor.substring(mentionStart, cursorPosition);

        if (
            mentionStart >= 0 &&
            !mention.includes(' ') // to skip previous mention
        ) {
            if (mentionStart > 0 &&
                !/[^\w]+/.test(strBeforeCursor[mentionStart - 1]) // предыдущий символ не пустой - значит это email
            ) {
                return '';
            }
            return strBeforeCursor.substring(mentionStart, cursorPosition);
        }
        return '';
    }
    const state = getAppState();
    const text = getCommentValue(state);

    if (!text[cursorPosition - 1]) {
        return '';
    }

    const strBeforeCursor = text.substring(0, cursorPosition);

    const mentionStart = strBeforeCursor.lastIndexOf('@'); // find last mention before cursor
    const mention = strBeforeCursor.substring(mentionStart, cursorPosition);

    if (
        mentionStart >= 0 &&
        !mention.includes(' ') // to skip previous mention
    ) {
        if (mentionStart > 0 &&
            !/[^\w]+/.test(strBeforeCursor[mentionStart - 1]) // предыдущий символ не пустой - значит это email
        ) {
            return '';
        }
        return strBeforeCursor.substring(mentionStart, cursorPosition);
    }

    return '';
};
