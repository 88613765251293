import { TTabsModelState } from '../types';
import { AT_REMOVE_TABS_ACTION, AT_SET_ACTION } from '../actions/constants';
import { TTabsModelAction } from '../actions/types';
import { isObjectEmpty } from '../../../../view/react_components/helpers/isObjectEmpty';

const initialState: TTabsModelState = {};

export const tabsReducer = (
    state = initialState,
    action: TTabsModelAction
): TTabsModelState  => {
    switch (action.type) {
        case AT_SET_ACTION: {
            const { tabs } = action;
            return {
                ...tabs
            }
        }
        case AT_REMOVE_TABS_ACTION: {
            const { tabIds } = action;
            if (isObjectEmpty(tabIds)) return state;
            const newState = { ...state };
            tabIds.forEach(tabId => {
                delete newState[tabId];
            })
            return newState;
        }
        default: {
            return state;
        }
    }
};
