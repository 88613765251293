import * as React from 'react';
import './_CardChecklistDateDatepicker.scss';
import { CLASS_CARD_DETAILS } from '../../../constants';
import { ICardChecklistDateDatepickerProps } from './types';
import { EDateType } from '../../../../../../../const';
import {
    EDatepickerDropdownDirectionX
} from '../../../../../base/components/Datepicker/components/DatepickerItem/types';
import { DatepickerHOC } from '../../../../../base/components/Datepicker/hocs/DatepickerHOC/DatepickerHOC';
import { LABEL_DUE_DATE, LABEL_START_DATE } from './constants';
import { getParentsClasses } from '../../../../../helpers/getParentsClasses';
import { CLASS_DATEPICKER } from '../../../../../base/components/Datepicker/components/constants';

export function CardChecklistDateDatepicker ({
    dateType,
    startDate,
    dueDate,
    onChangeStartDate,
    onChangeDueDate,
    setOpen,
    onClickDueDate,
    onClickStartDate
}: ICardChecklistDateDatepickerProps) {
    if (!dateType) return;
    const date = dateType === EDateType.DUE_DATE ? dueDate : startDate;
    const dateObj = date ? new Date(date * 1000) : null;
    if (dateObj) dateObj.setHours(0, 0, 0, 0);

    const className = CLASS_CARD_DETAILS + '__checklist-item-date-datepicker';

    const timerRef = React.useRef(null);

    const onChangeDateHandler = (
        date: Date,
        dateType: EDateType
    ) => {
        if (date) {
            date.setHours(0, 0, 0, 0);
        }
        if (dateType === EDateType.DUE_DATE) {
            onChangeDueDate(date ? Math.round(date.getTime() / 1000) : 0);
        } else {
            onChangeStartDate(date ? Math.round(date.getTime() / 1000) : 0);
        }
        if (date) {
            if (timerRef.current) clearTimeout(timerRef.current);
            timerRef.current = setTimeout(() => setOpen(null) ,200);
        }
    };

    const onDueClick = (e: React.SyntheticEvent) => {
        if (timerRef.current) clearTimeout(timerRef.current);
        const className = CLASS_DATEPICKER + '__item-clear';
        const parentClasses = getParentsClasses(e.target as HTMLElement, [
            className
        ]);
        if (!parentClasses.includes(className)) {
            onClickDueDate();
        }
    };

    const onStartClick = (e: React.SyntheticEvent) => {
        if (timerRef.current) clearTimeout(timerRef.current);
        const className = CLASS_DATEPICKER + '__item-clear';
        const parentClasses = getParentsClasses(e.target as HTMLElement, [
            className
        ]);
        if (!parentClasses.includes(className)) {
            onClickStartDate();
        }
    };

    return (
        <div className={className}>
            <DatepickerHOC
                fromPlaceholder={LABEL_START_DATE}
                toPlaceholder={LABEL_DUE_DATE}
                maxFromDate={startDate ? new Date(startDate * 1000) : null}
                minToDate={dueDate ? new Date(dueDate * 1000) : null}
                selectedFromDate={startDate ? new Date(startDate * 1000) : null}
                selectedToDate={dueDate ? new Date(dueDate * 1000) : null}
                onChange={onChangeDateHandler}
                dropdownDirectionX={EDatepickerDropdownDirectionX.RIGHT}
                isFromOpened={dateType === EDateType.START_DATE}
                isToOpened={dateType === EDateType.DUE_DATE}
                onToClick={onDueClick}
                onFromClick={onStartClick}
                isNoDropdown
            />
        </div>
    );
};
