import * as React from 'react';
import {AsidePanelActionsButtonsGroup} from '../../../../asidePanel/components/AsidePanelActionsButtonsGroup/AsidePanelActionsButtonsGroup';
import {CardMoveToButtonHOC} from '../../../MoveToSection/hocs/CardMoveToButtonHOC/CardMoveToButtonHOC';
import {CopyCardHOC} from '../../../CopyCard/hocs/CopyCardHOC';
import {SetInWorkButtonHOC} from '../../../SetInWorkButton/hocs/SetInWorkButtonHOC/SetInWorkButtonHOC';
import {GetLinkButtonHOC} from '../../../GetLinkButton/hocs/GetLinkButtonHOC/GetLinkButtonHOC';
import {DeleteButtonHOC} from '../../../DeleteButtonSection/hocs/DeleteButtonHOC/DeleteButtonHOC';
import {IActionsInHeadSectionProps} from './types';
import './_ActionsInHeadSection.scss';
import {CardActionsTopMoreHOC} from '../../hocs/CardActionsTopMoreHOC/CardActionsTopMoreHOC';
import {ArchiveButtonsInHeadHOC} from '../../../ArchiveButton/hocs/ArchiveButtonHOC/ArchiveButtonHOC';
import { CLASS_ASIDE_PANEL_ACTIONS_HEAD } from '../../../../asidePanel/components/AsidePanelActions/constants';
import { AddToMyWorkButtonHOC } from '../../../AddToMyWorkButton/hocs/AddToMyWorkButtonHOC/AddToMyWorkButtonHOC';
import { EHintKey, HintHOC } from 'app/view/react_components/hints';
import { CARD_LINK } from 'app/view/react_components/aside_panel/cardDetails/ActionsInHeadSection/components/ActionsInHeadSection/constants';
import { GOOGLE_SPACING } from 'app/const';

export function ActionsInHeadSection({
    boardId,
    cardId,
    isBasicFeature,
    isShow,
}: IActionsInHeadSectionProps) {
    if (!isShow) {
        return null;
    }

    const className = CLASS_ASIDE_PANEL_ACTIONS_HEAD;
    const classNameButtonHideTitle = 'head-actions__button-hide-title';
    const classNameButtonsRight = 'head-actions__buttons-right';
    const classNameCardLinkHint = className + '--card-link-hint';
    const classNameButtonCopy = 'head-actions__button--hint';

    return (<>
        <div className={className}>
            <AsidePanelActionsButtonsGroup>
                <CopyCardHOC
                    cardId={cardId}
                    boardId={boardId}
                />

                <CardMoveToButtonHOC
                    boardId={boardId}
                    cardId={cardId}
                />

                {!isBasicFeature && <SetInWorkButtonHOC
                    boardId={boardId}
                    cardId={cardId}
                />}

                <AddToMyWorkButtonHOC
                    boardId={boardId}
                    cardId={cardId}
                />

                <GetLinkButtonHOC
                    boardId={boardId}
                    cardId={cardId}
                    isShowTooltip={true}
                    className={`${classNameButtonHideTitle} ${classNameButtonCopy}`}
                />
                    <HintHOC
                        className={classNameCardLinkHint}
                        key={EHintKey.CARD_LINK}
                        hintKey={EHintKey.CARD_LINK}
                        show
                        arrow={'up'}
                        direction={'down'}
                        value={CARD_LINK}
                        translate={{ top: -GOOGLE_SPACING }}
                        selector={`.${classNameButtonCopy}`}
                    />

                <div className={classNameButtonsRight}>
                    {!isBasicFeature && <ArchiveButtonsInHeadHOC
                        boardId={boardId}
                        cardId={cardId}
                    />}

                    <DeleteButtonHOC
                        boardId={boardId}
                        cardId={cardId}
                        isShowTooltip={true}
                        className={classNameButtonHideTitle}
                    />

                    <CardActionsTopMoreHOC
                        boardId={boardId}
                        cardId={cardId}
                    />
                </div>
            </AsidePanelActionsButtonsGroup>
        </div>
    </>);
}
