import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TBoardId, TCardId, TListId } from '../../../../../../../../types/types';
import { IBoard } from '../../../../../../../../store/model/board/types';
import { cardMoveToAnotherBoard } from '../effects/cardMoveToAnotherBoard';
import { cardMoveOnCurrentBoard } from '../effects/cardMoveOnCurrentBoard';
import { root } from '../../../../../../../../store/constants';
import { getLinkCardId } from '../../../../../../../../store/router/selectors/getLinkCardId';

export const onCardSelected = (
    currentCardId: TCardId,
    board: IBoard,
    currentBoardId: TBoardId,
    listId: TListId,
    cardIndex: number,
    onClose: () => void
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (board.id !== currentBoardId) {
            dispatch(cardMoveToAnotherBoard(currentCardId, board, listId, cardIndex)).then(() => {
                root.App.controller.lastActivity.setLastOpenedCardId(currentBoardId, null);
                const linkCardId = getLinkCardId(getState().router);
                if (linkCardId) {
                    root.App.router.navigate(root.App.router.getLinkCardUrl(board.id, linkCardId, ''), { trigger: true });
                }
            });
        } else {
            dispatch(cardMoveOnCurrentBoard(currentCardId, board, listId, cardIndex, onClose));
        }
    };
    return action;
};
