import { TBoardId } from '../../../../../../../../types/types';

export enum TBoardAttachmentsTab {
    ALL = 'all',
    MEDIA = 'media',
    DOCUMENTS = 'documents'
}

export interface ITabAttachmentsFields {
    boardId: TBoardId;
    isGridView: boolean;
}

export interface ITabAttachmentsEvents {
    onDidMount: () => void;
    onLoad: () => Promise<void>;
}

export interface ITabAttachmentsProps extends
    ITabAttachmentsFields,
    ITabAttachmentsEvents
{}
