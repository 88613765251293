import './_Shortcut.scss';
import { IShortcutProps } from './types';
import * as React from 'react';
import { KEYBOARD_SHORTCUTS_CLASS } from '../keyboardShortcuts/constants';

export const Shortcut = ({
    name,
    buttons
}: IShortcutProps) => {
    const classShortcut = KEYBOARD_SHORTCUTS_CLASS + '__shortcut';
    const classButtons = KEYBOARD_SHORTCUTS_CLASS + '-shortcut__buttons';
    const classKey = KEYBOARD_SHORTCUTS_CLASS + '-shortcut__key';
    const classButton = KEYBOARD_SHORTCUTS_CLASS + '-shortcut__button';

    return (
        <div className={classShortcut}>
                {name}
            <span className={classButtons}>
                {
                    buttons.map((button, index) => {
                        const { value, before = '' } = button;
                        return (
                            <div
                                className={classKey}
                                key={index}
                            >
                                {!!before.length &&
                                    before
                                }
                                <div className={classButton}>
                                    {value}
                                </div>
                            </div>
                        );
                    })
                }
            </span>
        </div>
    );
}
