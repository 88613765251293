import { segmentTrackAction, SegmentNotificationsEvent, SegmentNotificationsOptions, SegmentNotificationsOptionSource } from '../../../../../../../../middlewares/segment';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TActivityId } from '../../../../../../../../types/types';

export const onClick = (
    activityId: TActivityId,
    ownOnClick?: () => void
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (ownOnClick) ownOnClick();
        dispatch(segmentTrackAction(SegmentNotificationsEvent.FORCE_CLICKED, {
            name: SegmentNotificationsOptions.SOURCE,
            value: activityId ? SegmentNotificationsOptionSource.ACTIVITY : SegmentNotificationsOptionSource.CARD_DETAILS
        }));
    };
    return action;
};
