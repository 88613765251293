import { getMessages } from '../../../../../store/constants';

export const KEYBOARD_SHORTCUTS_CLASS = 'keyboard-shortcuts';

export const KEYBOARD_SHORTCUTS_TITLE = getMessages().getText('ga.action.keyboard_shortcuts.title');
export const KEYBOARD_SHORTCUTS_CARDS_AND_LISTS = getMessages().getText('ga.action.keyboard_shortcuts.card_and_lists.title');
export const KEYBOARD_SHORTCUTS_TEXT_INPUTS = getMessages().getText('ga.action.keyboard_shortcuts.text_inputs.title');
export const KEYBOARD_SHORTCUTS_GLOBAL = getMessages().getText('ga.action.keyboard_shortcuts.global.title');
export const KEYBOARD_SHORTCUTS_TEXT = getMessages().getText('ga.action.keyboard_shortcuts.text_formatting.title');

const isMac = navigator.platform.indexOf('Mac') > -1;

const metaKey = isMac ? '⌘' : 'Ctrl';

export const KEYBOARD_SHORTCUTS_SHORTCUT = [{value: metaKey}, {value: '/'}];

export const KEYBOARD_SHORTCUTS_CARDS_AND_LISTS_SHORTCUTS = [
    // {
    //     name: getMessages().getText('ga.action.keyboard_shortcuts.card_and_lists.create_card'),
    //     buttons: [{value: metaKey}, {value: 'N'}]
    // },
    // {
    //     name: getMessages().getText('ga.action.keyboard_shortcuts.card_and_lists.copy_card'),
    //     buttons: [{value: metaKey}, {value: 'C'}]
    // },
    // {
    //     name: getMessages().getText('ga.action.keyboard_shortcuts.card_and_lists.paste_card'),
    //     buttons: [{value: metaKey}, {value: 'V'}]
    // },
    // {
    //     name: getMessages().getText('ga.action.keyboard_shortcuts.card_and_lists.create_list'),
    //     buttons: [{value: metaKey}, {value: '⇧'}, {value: 'V'}]
    // },
    {
        name: getMessages().getText('ga.action.keyboard_shortcuts.card_and_lists.multiselect'),
        buttons: [
            {value: metaKey, before: 'Hold'}, {value: '⇧', before: '/'}]
    },
    // {
    //     name: getMessages().getText('ga.action.keyboard_shortcuts.card_and_lists.navigate_in'),
    //     buttons: [{value: '↑'}, {value: '↓'}]
    // },
    // {
    //     name: getMessages().getText('ga.action.keyboard_shortcuts.card_and_lists.navigate_between'),
    //     buttons: [{value: '←'}, {value: '→'}]
    // },
    {
        name: getMessages().getText('ga.action.keyboard_shortcuts.card_and_lists.delete_card'),
        buttons: [{value: isMac ? metaKey : '⇧'}, {value: 'Delete'}]
    },
    {
        name: getMessages().getText('ga.action.keyboard_shortcuts.card_and_lists.print_card'),
        buttons: [{value: metaKey}, {value: 'P'}]
    },
    // {
    //     name: getMessages().getText('ga.action.keyboard_shortcuts.card_and_lists.move_up'),
    //     buttons: [{value: '⇧'}, {value: '↑'}]
    // },
    // {
    //     name: getMessages().getText('ga.action.keyboard_shortcuts.card_and_lists.move_down'),
    //     buttons: [{value: '⇧'}, {value: '↓'}]
    // }
];

export const KEYBOARD_SHORTCUTS_TEXT_INPUTS_SHORTCUTS = [
    {
        name: getMessages().getText('ga.action.keyboard_shortcuts.text_inputs.confirm'),
        buttons: [{value: metaKey}, {value: 'Enter'}]
    },
    {
        name: getMessages().getText('ga.action.keyboard_shortcuts.text_inputs.exit'),
        buttons: [{value: 'Esc'}]
    }
];

export const KEYBOARD_SHORTCUTS_GLOBAL_SHORTCUTS = [
    {
        name: getMessages().getText('ga.action.keyboard_shortcuts.global.focus'),
        buttons: [{value: metaKey}, {value: 'F'}]
    },
    // {
    //     name: getMessages().getText('ga.action.keyboard_shortcuts.global.open'),
    //     buttons: [{value: metaKey}, {value: '⇧'}, {value: 'F'}]
    // },
    // {
    //     name: getMessages().getText('ga.action.keyboard_shortcuts.global.close'),
    //     buttons: [{value: 'Esc'}]
    // },
    // {
    //     name: getMessages().getText('ga.action.keyboard_shortcuts.global.collapse'),
    //     buttons: [{value: '⇧'}, {value: 'S'}]
    // },
    // {
    //     name: getMessages().getText('ga.action.keyboard_shortcuts.global.share'),
    //     buttons: [{value: metaKey}, {value: '⇧'}, {value: 'S'}]
    // }
];

// export const KEYBOARD_SHORTCUTS_TEXT_SHORTCUTS = [
//     {
//         name: getMessages().getText('ga.action.keyboard_shortcuts.text_formatting.bold'),
//         buttons: [{value: metaKey}, {value: 'B'}]
//     },
//     {
//         name: getMessages().getText('ga.action.keyboard_shortcuts.text_formatting.italics'),
//         buttons: [{value: metaKey}, {value: 'I'}]
//     },
//     {
//         name: getMessages().getText('ga.action.keyboard_shortcuts.text_formatting.underline'),
//         buttons: [{value: metaKey}, {value: 'U'}]
//     },
//     {
//         name: getMessages().getText('ga.action.keyboard_shortcuts.text_formatting.strikethrough'),
//         buttons: [{value: metaKey}, {value: '⇧'}, {value: 'X'}]
//     },
//     {
//         name: getMessages().getText('ga.action.keyboard_shortcuts.text_formatting.monospace'),
//         buttons: [{value: metaKey}, {value: '⇧'}, {value: 'M'}]
//     },
//     {
//         name: getMessages().getText('ga.action.keyboard_shortcuts.text_formatting.hyperlink'),
//         buttons: [{value: metaKey}, {value: 'K'}]
//     }
// ];
