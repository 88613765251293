import { Dispatch, ThunkAction } from '../../../../types/actions';
import { clickManagerInitialStateActionSet } from '../store/clickManager/actions/clickManagerInitialStateActionSet';

export const resetSelectedCards = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        dispatch(clickManagerInitialStateActionSet());
    };
    return action;
};
