import { createDeepEqualSelector } from './../../../view/react_components/helpers/memoizeHelper';
import { IApplicationState, TBoardId } from '../../../types/types';
import { getBoardCardsActiveAndArchive } from './getBoardCardsActiveAndArchive';
import { getCardBackgroundColors } from '../../../const';
import { ICard } from '../card/types';

type TgetBoardCardColors = (
    state: IApplicationState,
    boardId: TBoardId,
) => string[];

const getBoardCardColorsSelector = (
    cards: ICard[]
): string[] => {
    const colors = cards
        .reduce((arr, card) => {
            if (card.backgroundColor) {
                arr.push(card.backgroundColor);
            }
            return arr;
        }, []);

    const allColors = getCardBackgroundColors(false, true);
    const colorsSorted = allColors // board colors отсоритровать как all colors
        .filter(color => colors.includes(color));

    return colorsSorted;
}

export const getBoardCardColorsCreateSelector = (
): TgetBoardCardColors => createDeepEqualSelector(
    getBoardCardsActiveAndArchive, // нужны ли тут архивные?
    getBoardCardColorsSelector,
);

export const getBoardCardColors: TgetBoardCardColors = getBoardCardColorsCreateSelector();
