import {Dispatch, ThunkAction} from '../../../../../../../../types/actions';
import {openBoardSettings} from '../../../../../../base/effects/openBoardSettings';
import {SegmentCardEvent, segmentTrackAction} from '../../../../../../../../middlewares/segment';
import { SegmentBoardSettingsSourceValue } from '../../../../../../../../middlewares/segment/trackEntities/boardEvents';

export const onClickIcon = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(openBoardSettings('notifications', SegmentBoardSettingsSourceValue.CARD_WATCH_ICON));
        dispatch(segmentTrackAction(SegmentCardEvent.WATCH_CARD_NOTIFICATION_ICON_CLICKED));
    };
    return action;
};
