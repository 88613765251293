import * as React from 'react';
import { ICardAttachmentsElementsProps } from './type';
import './_CardAttachmentsElements.scss';
import { CLASS_CARD_DETAILS } from '../../../constants';
import { ESettingsSectionType } from '../../../../../base/components/SettingsSection/types';
import { CardAttachment } from '../CardAttachment/CardAttachment';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { IDriveDoc } from 'app/store/model/card/types/IDriveDoc';
import { CLASS_LIST_ITEM_CONTAINER } from '../../../../../base/components/ListItem/constants';
import { ListManager } from 'react-beautiful-dnd-grid';

export function CardAttachmentsElements ({
    attachments,
    activeRequests,
    sectionType,
    userHash,
    isAttachmentViewTypeGrid,
    onDelete,
    onDownload,
    onLinkClick,
    onLinkCopy,
    onTogglePin,
    onSort,
}: ICardAttachmentsElementsProps) {
    const { cardId } = React.useContext(AsidePanelContext);
    const isReadonly = sectionType === ESettingsSectionType.READONLY;

    const CLASS_CARD_ATTACHMENTS = CLASS_CARD_DETAILS + '__attachments';
    const classNameReaonly = isReadonly ? CLASS_CARD_ATTACHMENTS + '--readonly' : '';
    const classNamePreviewType = isAttachmentViewTypeGrid ? CLASS_CARD_ATTACHMENTS + '--grid' : '';

    function onDragEnd(result: DropResult) {
        if (
            !result.destination ||
            result.destination.index === result.source.index
        ) {
            // do nothing
        } else {
            onSort(Number(result.draggableId), result.destination.index, attachments.map(attachment => attachment.id));
        }
    }

    function onGridDragEnd(srcIdx: number, destIdx: number) {
        if (destIdx === srcIdx) {
            // do nothing
        } else {
            onSort(attachments[srcIdx].id, destIdx, attachments.map(attachment => attachment.id));
        }
    }

    return (
        <div className={classNamePreviewType}>
            {isAttachmentViewTypeGrid ?
                <ListManager
                    items={attachments}
                    direction={'horizontal'}
                    maxItems={2}
                    render={item => <div key={item.id} className={CLASS_LIST_ITEM_CONTAINER}>
                        <CardAttachment
                            attachment={item}
                            activeRequests={activeRequests}
                            isReadonly={isReadonly}
                            isAttachmentViewTypeGrid={isAttachmentViewTypeGrid}
                            userHash={userHash}
                            onDelete={onDelete}
                            onDownload={onDownload}
                            onLinkClick={onLinkClick}
                            onTogglePin={onTogglePin}
                            onLinkCopy={onLinkCopy}
                        />
                    </div>}
                    onDragEnd={onGridDragEnd}
                />
                : <DragDropContext
                    onDragEnd={onDragEnd}
                >
                    <Droppable
                        droppableId={String(cardId)}
                    >
                        {(provided, snapshot) => (
                            <div
                                className={`
                                    ${CLASS_CARD_ATTACHMENTS}
                                    ${classNameReaonly}
                                `}
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {attachments.map((attachment: IDriveDoc, index) => {
                                    return (
                                        <Draggable
                                            draggableId={String(attachment.id)}
                                            index={index}
                                            isDragDisabled={isReadonly}
                                            key={String(attachment.id)}
                                        >
                                            {(provided, snapshot) => {
                                                let isDrag= snapshot.isDragging;
                                                return (
                                                    <div
                                                        className={`${CLASS_LIST_ITEM_CONTAINER}
                                                                    ${isDrag ? CLASS_LIST_ITEM_CONTAINER + '--drag' : ''}
                                                                    `}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        ref={provided.innerRef}
                                                    >
                                                        <CardAttachment
                                                            attachment={attachment}
                                                            activeRequests={activeRequests}
                                                            isReadonly={isReadonly}
                                                            isAttachmentViewTypeGrid={isAttachmentViewTypeGrid}
                                                            userHash={userHash}
                                                            onDelete={onDelete}
                                                            onDownload={onDownload}
                                                            onLinkClick={onLinkClick}
                                                            onTogglePin={onTogglePin}
                                                            onLinkCopy={onLinkCopy}
                                                        />
                                                    </div>
                                                );
                                            }}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            }
        </div>
    );
}
