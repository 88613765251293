import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { root } from '../../../../../../../store/constants';

export const onClick = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
    ) => {
        root.App.controller.mainView.showQualificationDialog();
    };
    return action;
};
