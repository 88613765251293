import { Dispatch } from '../../../../../types/actions';
import { suspendSnackbarsStack as suspendStackAction } from '../../store/actions/suspendStack';

export const suspendSnackbarsStack = (
) => {
    return (dispatch: Dispatch) => {
        return new Promise<void>((resolve) => {
            dispatch(suspendStackAction());
            resolve();
        });
    }
};
