import { getFile } from 'app/helper/authorisation/microsoft/graph/api/getFile';
import { msBuildUrl } from 'app/helper/file_helper';
import { TDriveDocId } from 'app/types/rest/IRestDriveDoc';
import { getDriveDoc } from 'app/store/model/driveDocs/selectors/getDriveDoc';
import { EAuthUserPlatformType } from 'app/types/rest/IRestAuthUser';
import { boardOpen } from 'app/view/react_components/base/effects/boardOpen';
import { Dispatch, ThunkAction } from 'app/types/actions';
import { IGetState } from 'app/types/types';

export const onLinkClick = (
    event: React.SyntheticEvent,
    driveDocId: TDriveDocId,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const attachment = getDriveDoc(state, driveDocId);
        if (attachment.dashboardId) {
            event.preventDefault(); // не открывать ссылку
            return dispatch(boardOpen(attachment.dashboardId));
        }
        if (
            attachment.platformType === EAuthUserPlatformType.MICROSOFT &&
            !attachment.url.includes('sourcedoc=') // ссылка на доки правильная, т.к. не содержит имя папки
        ) {
            event.preventDefault(); // не открывать ссылку
            getFile(attachment.driveId, attachment.fileId)
                .then(item => {
                    return msBuildUrl(item);
                })
                .then(url => {
                    window.open(url, '_blank');
                });
        }
    };
    return action;
};
