import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState, TCardId } from '../../../../../../../types/types';
import { asidePanelActionButtonClick } from '../../../../../helpers/asidePanelActionButtonClick';
import { waitUntilCardIsOpenedAndLoaded } from '../../../../../helpers/waitUntilCardIsOpenedAndLoaded';
import { EDateType } from '../../../../../../../const';
import { cardSetDueDateWithEpics } from '../../../../../../../rest/effects/card/card/cardSetDueDateWithEpics';
import { cardSetStartDateWithEpics } from '../../../../../../../rest/effects/card/card/cardSetStartDateWithEpics';

export const onDateChange = (
    cardId: TCardId,
    date: Date,
    dateType: EDateType,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const newDate = date
            ? Math.round(date.getTime() / 1000)
            : null;
        return dateType === EDateType.DUE_DATE ?
            dispatch(cardSetDueDateWithEpics(cardId, newDate))
            : dispatch(cardSetStartDateWithEpics(cardId, newDate));
    };
    return action;
}
