import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState, TCardId } from '../../../../types/types';
import { sendUserProductivityStatsByCardId } from '../../../../helper/comet/stat_helper_ts';
import { EUserProductivityEventStatus } from '../../../../view/react_components/reports/UserProductivity/UserProductivityReport/rest/types';
import { ICardBatch } from './api/types';
import { cardPatchRestBatch } from './api/helper/cardPatchRestBatch';
import { IAssigneeRest } from '../../../../types/rest/IAssigneeRest';

export const cardSetAssigneesBatch = (
    dispatch: Dispatch,
    cardId: TCardId,
    assignees: IAssigneeRest[],
    cardBatch: ICardBatch = {card: {}, callbacks: []}
) => {
    cardBatch.card = {
        ...cardBatch.card,
        assignees
    };
    cardBatch.callbacks.push(() => {
        dispatch(sendUserProductivityStatsByCardId(cardId, EUserProductivityEventStatus.ASSIGNED_TO_CHANGED))
    });
    return cardBatch;
};

export const cardSetAssignees = (
    cardId: TCardId,
    assignees: IAssigneeRest[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const cardBatch = cardSetAssigneesBatch(dispatch, cardId, assignees);
        return cardPatchRestBatch(cardId, dispatch, cardBatch);
    };
    return action;
};
