import { TCardId } from '../../../../types/types';
import { checklistsActionSetAction } from '../../actions/checklistsActionSetAction';
import { checklistsUpdateAction } from '../checklists/actions/checklistsUpdateAction';
import { ICardChecklists } from '../checklists/types';

export const checklistsUpdateStore = (
    cardId: TCardId,
    checklists: ICardChecklists
) => {
    return checklistsActionSetAction(checklistsUpdateAction(cardId, checklists));
};
