import { TColorId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { fetchHandler } from '../../../../util/fetchHandler';
import { Util } from '../../../../util/util';
import { REST_COLOR } from '../../../constants';
import { getRestHeadersPut } from '../../../helpers/getRestHeadersHelper';
import { IColor } from '../../../../types/model';

export const patchRest = (
    colorId: TColorId,
    color: IColor
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        return fetchHandler<IColor>(
            Util.getApiUrl(REST_COLOR + colorId), {
                ...getRestHeadersPut(),
                body: JSON.stringify(color)
            }
        );
    };
    return action;
};
