import { Thumbnail } from '@microsoft/microsoft-graph-types';
import { errorHandler, graphClient } from 'app/helper/authorisation/microsoft/graph/Graph';

/**
 *
 * @param driveId
 * @param fileId
 * @param thumbSizes c100x100,c400x300,...
 * @returns
 */
export const getMSFileThumbnails = async (
    driveId: string,
    fileId: string,
    thumbSizes: string,
) => {
    try {
        let request = graphClient.api(`/drives/${driveId}/items/${fileId}/thumbnails?select=${thumbSizes}`);
        let response = await request.get() as any;
        return response && response.value && response.value.length && response.value[0] as IMSFileThumbnails;
    } catch (e) {
        return errorHandler(e);
    }
}

export interface IMSFileThumbnails {
    [size: string]: Thumbnail;
}
