import { Dispatch, ThunkAction } from '../../../types/actions';
import { IGetState } from '../../../types/types';
import { IAuthUser } from '../../../store/model/authUser/types';
import { authUserSetProFeaturesUsage } from './authUserSetProFeaturesUsage';
import { getUserProFeaturesUsage } from '../../../store/model/authUser/selectors/getUserProFeaturesUsage';

export const authUserSetUsedProFeatures = (
    newUsedFeature: keyof IAuthUser
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const proFeaturesUsage = getUserProFeaturesUsage(getState());
        if (proFeaturesUsage.usedFeatures.includes(newUsedFeature)) return;
        const usedFeatures = [
            ...proFeaturesUsage.usedFeatures,
            newUsedFeature
        ];
        dispatch(authUserSetProFeaturesUsage({
            ...proFeaturesUsage,
            usedFeatures
        }));
    };
    return action;
};
