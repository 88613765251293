import {ActionCreator} from 'redux';
import {Dispatch, ThunkAction} from '../../../../../../../types/actions';
import {IGetState} from '../../../../../../../types/types';
import { boardNameClickedSegmentEvent } from '../../../effects/segmentEvents';
import { ESegmentClickType } from '../../../../../../../middlewares/segment/constants';

export const onClickName: ActionCreator<ThunkAction> = () => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(boardNameClickedSegmentEvent(ESegmentClickType.single));
    };
    return action;
};
