import { IGetState, TBoardId, TCardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { EFileType } from 'app/view/react_components/aside_panel/cardDetails/AttachmentsSection/components/CardAttachmentGoogleFiles/constants';
import { createGoogleFile } from 'app/view/react_components/aside_panel/cardDetails/AttachmentsSection/hocs/CardAttachmentGoogleFilesHOC/effects/createGoogleFile';
import { isGoogleFileCreatingSetAction } from 'app/view/react_components/aside_panel/asidePanel/store/actions/isGoogleFileCreatingSetAction';
import { SegmentCardEvent, SegmentCardOptions, segmentTrackAction } from 'app/middlewares/segment';

export const onCreate = (
    boardId: TBoardId,
    cardId: TCardId,
    fileType: EFileType,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(createGoogleFile(boardId, cardId, fileType))
        dispatch(isGoogleFileCreatingSetAction(true));
        dispatch(segmentTrackAction(SegmentCardEvent.CREATE_GOOGLE_FILE_CLICKED, {
            name: SegmentCardOptions.TYPE,
            value: fileType
        }));
    };
    return action;
};
