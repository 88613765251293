import { Dispatch, ThunkAction } from '../../../types/actions';
import { boardPatchRest } from './api/helpers/boardPatchRest';
import { TBoardId } from '../../../types/types';

export const boardSetMetaWarnings = (
    boardId: TBoardId,
    warnings: string[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        const meta = {
            warnings
        };
        dispatch(boardPatchRest(boardId, {meta}));
    };
    return action;
};
