import {NP_LOCAL_STORAGE_FIELD} from '../../../constants';
import {ActionCreator} from 'redux';
import {Dispatch, ThunkAction} from '../../../../../../../types/actions';
import {IGetState} from '../../../../../../../types/types';

export const saveCollapseStatusToLS: ActionCreator<ThunkAction> = (
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        if (localStorage) {
            const encoded = btoa(JSON.stringify({panelType: state.navigationPanel.panelType}));
            localStorage.setItem(NP_LOCAL_STORAGE_FIELD, encoded);
        }
    };
    return action;
};
