import { TChecklistId } from '../../../../../../../../../types/types';
import { ICardChecklists } from '../../../../../../../../../store/model/checklists/checklists/types';
import { ICardChecklist } from '../../../components/CardChecklists/types';
import { IChecklistStats } from '../../../../../../../../../types/model';
import { IChecklistItem } from '../../../../../../../../../store/model/checklists/checklist/types';
import { ECardChecklistOrderMode } from '../../../../../../../../../types/rest/IUserMeta';
import { root } from '../../../../../../../../../store/constants';
import { defaultMemoize } from 'reselect';
import {
    sortChecklistItems
} from '../../../../../../../aside_panel/cardDetails/ChecklistSection/hocs/CardChecklistHOC/helpers/sortChecklistItems';

const sortChecklists = (
    a: ICardChecklist,
    b: ICardChecklist
) => {
    if (a.name === b.name) {
        return b.id - a.id;
    }
    return a.name.localeCompare(b.name);
}

export const getPinnedCheckLists = (
    checklists: ICardChecklists,
    pinnedChecklistIds: TChecklistId[],
    isHideDoneChecklistItems: boolean,
    checklistOrderMode: ECardChecklistOrderMode
)   => {
    if (root._debug.selector) {
        console.log('getPinnedCheckLists');
    }
    const pinnedChecklists: ICardChecklist[] = [];
    for (let checklistId in checklists) {
        if (pinnedChecklistIds.includes(Number(checklistId))) {
            const stats: IChecklistStats = {
                checkedCount: 0,
                checkedPercent: 0,
                totalCount: 0,
                estimate: 0,
            };
            const checkItems: IChecklistItem[] = [];
            let isShowWeights = false;
            if (checklists[checklistId].checkItems) {
                const items = Object.values(checklists[checklistId].checkItems);
                stats.totalCount = items.length;
                let weightTotal = 0;
                let weightChecked = 0;
                items.forEach(item => {
                    weightTotal += item.weight;
                    if (item.checked) {
                        stats.checkedCount++;
                        weightChecked += item.weight;
                    }
                    if (!isHideDoneChecklistItems || !item.checked) {
                        checkItems.push({...item});
                    }
                    stats.estimate += item.estimate || 0;
                });
                if (weightTotal) stats.checkedPercent = Math.round(weightChecked * 100 / weightTotal);
                isShowWeights = weightTotal > stats.totalCount; // есть вес > 1
            }
            pinnedChecklists.push({
                ...checklists[checklistId],
                checkItems: sortChecklistItems(checkItems, checklistOrderMode),
                isShowWeights,
                stats,
            });
        }
    }
    return pinnedChecklists.sort(sortChecklists);
};

export const getPinnedCheckListsMemo = () => defaultMemoize(getPinnedCheckLists);
