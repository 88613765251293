import { IGetState, TBoardId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { fetchHandler } from '../../../../util/fetchHandler';
import { Util } from '../../../../util/util';
import { REST_THEME_FILE } from '../../../constants';
import { getRestHeadersPost } from '../../../helpers/getRestHeadersHelper';
import { EThemeBackgroundOption } from '../../../../types/rest/IRestTheme';
import { IBoardTheme } from '../../../../store/model/board/types';

export const postFile = (
    boardId: TBoardId,
    backgroundOption: EThemeBackgroundOption,
    file: File
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const queryStr = ['name=' + encodeURI(file.name), 'mimeType=' + file.type, 'backgroundOption=' + backgroundOption].join('&');
        return fetchHandler<IBoardTheme>(
            Util.getApiUrl(REST_THEME_FILE + '?' + queryStr), {
                ...getRestHeadersPost(),
                body: file
            }
        );
    };
    return action;
};
