import { IApplicationState } from '../../../../types/types';
import { ISubscription } from '../../../../view/react_components/subscriptionAdmin/store/subscription/types';
import { getUserOwnSubscriptions } from './getUserOwnSubscriptions';
import { getUserPaidSubscriptions } from './getUserPaidSubscriptions';

/**
 * если несколько активных подписок, где-то админ, где-то овнер, то тут нужно отдать первую овн
 */

export const getUserPaidSubscriptionFirst = (
    state: IApplicationState
): ISubscription => {
    const paids = getUserPaidSubscriptions(state);
    if (!paids.length) return null;

    const owns = getUserOwnSubscriptions(state);
    if (!owns.length) return paids[0];

    for (let i = 0; i < paids.length; i++) {
        const own = owns.find(subscription => subscription.id === paids[i].id);
        if (own) return own;
    }

    return paids[0];
};
