import { NAVIGATION_PANEL_BOARD_SHORT_NAME } from '../main/navigationPanel/constants';

export const getVisibleBoardTooltipTitle = (
    name: string
): string => {
    if (name && name.length && name.length > NAVIGATION_PANEL_BOARD_SHORT_NAME) {
        return name.slice(0, NAVIGATION_PANEL_BOARD_SHORT_NAME) + '...';
    } else {
        return name;
    }
}
