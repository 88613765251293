import { Button, ButtonDropdown, ButtonTitle, Icon } from 'kui';
import { IProfileProps } from './types';
import * as React from 'react';
import './_Profile.scss'
import {
    EQuickStartGuideType,
    NAVIGATION_PANEL_ASIDE_CLASS,
    NAVIGATION_PANEL_CONTAINER_CLASS
} from '../../../main/navigationPanel/constants';
import { CLASS_PROFILE_DROPDOWN, EProfileUserWhere } from '../../constants';
import { ProfileUserHOC } from '../../hocs/ProfileUserHOC/ProfileUserHOC';
import { ProfileSubscriptionHOC } from '../../hocs/ProfileSubscriptionHOC/ProfileSubscriptionHOC';
import FocusLock from 'react-focus-lock';
import { GOOGLE_SPACING, GSUITE_APP_URL, MS_APP_URL } from 'app/const';
import { NewFeatureIndicator } from 'app/view/react_components/base/components/NewFeatureIndicator/NewFeatureIndicator';
import { EHintKey, HintHOC } from '../../../hints';
import { NAV_PANEL_QUICK_START_GUIDE_HINT } from './constants';
import { getMessages } from '../../../../../store/constants';

export function Profile ({
    activeManagedSubscriptionsCount,
    discount,
    isMicrosoft,
    isShowGuides,
    isShownManagementButton,
    isReturnFocus,
    isNewFeatureRelease,
    isNewFeatureSettings,
    profileUserWhere,
    tooltipDirection,
    onClickGuide,
    onClickHelp,
    onClickMarketplace,
    onClickPricing,
    onClickRelease,
    onClickSettings,
    onClickShortcuts,
    onClickSignOut,
    onClickSubscriptions,
    onClickSwitch,
    onOpen,
    onClose,
}: IProfileProps) {
    const [isOpened, setIsOpened] = React.useState(null);
    const onOpened = () => {
        setIsOpened(true);
        onOpen();
    }
    const onClosed = () => {
        setIsOpened(false);
        onClose();
    }

    const className = CLASS_PROFILE_DROPDOWN;
    const classButtonDropdown = className + '__button-dd';
    const classButton = className + '__button';
    const classDropdown = className + '__dropdown';
    const classTop = className + '__top';
    const classBottom = className + '__bottom';
    const classHeader = className + '__header';
    const classBody = className + '__body';
    const classFooter = className + '__footer';
    const classMenu = className + '__menu';
    const classMenuItem = className + '__menu-item';
    const classMenuButton = className + '__menu-button';
    const classMenuSVGIcon = className + '__menu-icon icon';
    const classSocialLink = className + '__social-link';

    const RocketIcon = require('!!@svgr/webpack!../../../main/navigationPanel/components/NavigationPanelQuickStartGuide/rocket.svg').default;

    return <>
        <ButtonDropdown
            className={classButtonDropdown}
            directionVertical={'down'}
            directionHorizontal={profileUserWhere === EProfileUserWhere.PROFILE ? 'right' : 'left'}
            dropdownClassName={`${classDropdown} ${profileUserWhere === EProfileUserWhere.NAVPANEL ? NAVIGATION_PANEL_ASIDE_CLASS : ''}`}
            opened={isOpened}
            portal
            portalId={profileUserWhere === EProfileUserWhere.NAVPANEL ? 'navigation-aside-portal' : null}
            portalSelector={profileUserWhere === EProfileUserWhere.NAVPANEL ? '.' + NAVIGATION_PANEL_CONTAINER_CLASS : null}
            onOpen={onOpened}
            onClose={onClosed}
        >
            <Button
                className={classButton}
                variant={'icon'}
            >
                <ProfileUserHOC where={profileUserWhere} tooltipDirection={tooltipDirection} />
            </Button>

            <FocusLock
                returnFocus={isReturnFocus}
            >
                <div
                    className={className}
                    tabIndex={0} // сюда FocusLock поставит фокус при открытии
                >
                    <div className={classHeader}>
                        <ProfileUserHOC tooltipDirection={tooltipDirection} />
                        <ProfileSubscriptionHOC />
                        <Button
                            className={`${className}__close`}
                            variant={'icon'}
                            aria-label={'Close'}
                            onClick={onClosed}
                        >
                            <Icon
                                size={24}
                                xlink="close"
                            />
                        </Button>
                    </div>
                    <div className={classBody}>
                        <div className={classTop}>
                            <ul className={`${classMenu} ${classMenu}--primary`}>
                                {isShownManagementButton &&
                                    <li className={classMenuItem}>
                                        <Button
                                            className={classMenuButton}
                                            variant={'icon-text'}
                                            onClick={onClickSubscriptions}
                                        >
                                            <span className={classMenuSVGIcon}><svg className="svg-icon"><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svgicon--board-settings"></use></svg></span>
                                            <ButtonTitle>Manage subscriptions</ButtonTitle>
                                            {!!activeManagedSubscriptionsCount &&
                                                <span className={`${className}__active-subscription-text`}>{activeManagedSubscriptionsCount} active</span>
                                            }
                                        </Button>
                                    </li>
                                }
                                <li className={classMenuItem}>
                                    <Button
                                        className={classMenuButton}
                                        variant={'icon-text'}
                                        onClick={onClickPricing}
                                    >
                                        <span className={classMenuSVGIcon}>
                                            <Icon xlink={'credit-card'} size={24} className={'svg-icon'} />
                                        </span>
                                        <ButtonTitle>
                                            Pricing
                                            {discount &&
                                                <span className={className + '__discount'}>
                                                    {getMessages().getText('profile.discount', null, discount)}
                                                </span>
                                            }
                                        </ButtonTitle>
                                    </Button>
                                </li>
                                <li className={classMenuItem}>
                                    <Button
                                        className={classMenuButton}
                                        variant={'icon-text'}
                                        onClick={onClickSettings}
                                    >
                                        <span className={classMenuSVGIcon}>
                                            <Icon xlink={'account-settings'} size={24} className={'svg-icon'} />
                                        </span>
                                        <ButtonTitle>
                                            Settings  {isNewFeatureSettings && <NewFeatureIndicator />}
                                        </ButtonTitle>
                                    </Button>
                                </li>
                                <li className={classMenuItem}>
                                    <Button
                                        className={classMenuButton}
                                        variant={'icon-text'}
                                        onClick={onClickShortcuts}
                                    >
                                        <span className={classMenuSVGIcon}>
                                            <Icon xlink={'keyboard-shortcuts'} size={24} className={'svg-icon'} />
                                        </span>
                                        <ButtonTitle>Keyboard Shortcuts</ButtonTitle>
                                    </Button>
                                </li>
                                <li className={classMenuItem}>
                                    <Button
                                        className={classMenuButton}
                                        variant={'icon-text'}
                                        onClick={onClickSwitch}
                                    >
                                        <span className={classMenuSVGIcon}>
                                            <Icon xlink={'chage-account'} size={24} className={'svg-icon'} />
                                        </span>
                                        <ButtonTitle>Switch account</ButtonTitle>
                                    </Button>
                                </li>
                                <li className={classMenuItem}>
                                    <Button
                                        className={classMenuButton}
                                        variant={'icon-text'}
                                        onClick={onClickSignOut}
                                    >
                                        <span className={classMenuSVGIcon}>
                                            <Icon xlink={'sign-out'} size={24} className={'svg-icon'} />
                                        </span>
                                        <ButtonTitle>Sign out</ButtonTitle>
                                    </Button>
                                </li>
                            </ul>
                        </div>
                        <div className={classBottom}>
                            <ul className={`${classMenu} ${classMenu}--secondary`}>
                                <li className={classMenuItem}>
                                    <Button
                                        className={`${classMenuButton} ${classMenuButton}--secondary`}
                                        variant={'icon-text'}
                                        onClick={() => onClickHelp(onClosed)}
                                    >
                                        <Icon xlink={'help'} size={24} />
                                        Help and Support
                                    </Button>
                                </li>
                                <li className={classMenuItem}>
                                    <Button
                                        className={`${classMenuButton} ${classMenuButton}--secondary`}
                                        variant={'icon-text'}
                                        onClick={() => onClickRelease(onClosed)}
                                    >
                                        <Icon xlink={'sparkles'} size={24} />
                                        Release notes {isNewFeatureRelease && <NewFeatureIndicator />}
                                    </Button>
                                </li>
                                {isShowGuides &&
                                    <>
                                        <li className={`${classMenuItem} ${classMenuItem}--guide`}>
                                            <Button
                                                className={`${classMenuButton} ${classMenuButton}--secondary`}
                                                variant={'icon-text'}
                                                onClick={() => onClickGuide(EQuickStartGuideType.EFFICIENCY_JOURNEY_GUIDE, onClosed)}
                                            >
                                                <RocketIcon className={classMenuButton + '-rocket'} />
                                                Efficiency Journey Guide
                                            </Button>
                                        </li>
                                        <li className={`${classMenuItem} ${classMenuItem}--guide`}>
                                            <Button
                                                className={`${classMenuButton} ${classMenuButton}--secondary`}
                                                variant={'icon-text'}
                                                onClick={() => onClickGuide(EQuickStartGuideType.EXPLORE_KANBANCHI, onClosed)}
                                            >
                                                <Icon xlink={'kanbanchi-logo'} size={24} />
                                                Explore Kanbanchi Guide
                                            </Button>
                                        </li>
                                    </>
                                }
                            </ul>
                            <div className={classFooter}>
                                {isMicrosoft
                                    ? <Button
                                        className="profile-dropdown__stars"
                                        href={MS_APP_URL}
                                        target="_blank"
                                        variant={'icon'}
                                        onClick={onClickMarketplace}
                                    >
                                        <Icon size={24} xlink={'microsoft'} />
                                        <span className="profile-dropdown__stars-label">Give us 5 stars on <span className="profile-dropdown__stars-link">AppSource!</span></span>
                                    </Button>
                                    : <Button
                                        className="profile-dropdown__stars"
                                        href={GSUITE_APP_URL}
                                        target="_blank"
                                        variant={'icon'}
                                        onClick={onClickMarketplace}
                                    >
                                        <Icon size={24} xlink={'google-color'} />
                                        <span className="profile-dropdown__stars-label">Give us 5 stars on <span className="profile-dropdown__stars-link">Marketplace!</span></span>
                                    </Button>
                                }
                                <div className="profile-dropdown__socials">
                                    <Button
                                        className={classSocialLink}
                                        title="facebook"
                                        href="https://www.facebook.com/kanbanchiapp"
                                        target="_blank"
                                        variant={'icon'}
                                    >
                                        <Icon size={24} xlink={'facebook'} />
                                    </Button>
                                    <Button
                                        className={classSocialLink}
                                        title="twitter"
                                        href="https://twitter.com/Kanbanchi"
                                        target="_blank"
                                        variant={'icon'}
                                    >
                                        <Icon size={24} xlink={'twitter'} />
                                    </Button>
                                    <Button
                                        className={classSocialLink}
                                        title="linkedIn"
                                        href="https://www.linkedin.com/company/kanbanchi"
                                        target="_blank"
                                        variant={'icon'}
                                    >
                                        <Icon size={24} xlink={'linkedin'} />
                                    </Button>
                                    <Button
                                        className={classSocialLink}
                                        title="youtube"
                                        href="https://www.youtube.com/user/kanbanchi"
                                        target="_blank"
                                        variant={'icon'}
                                    >
                                        <Icon size={24} xlink={'youtube'} />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </FocusLock>

        </ButtonDropdown>
        {isShowGuides &&
            <HintHOC
                className={className + '__hint'}
                key={EHintKey.QUICK_START_GUIDE}
                hintKey={EHintKey.QUICK_START_GUIDE}
                translate={{ left: GOOGLE_SPACING * 1.5 }}
                show
                arrow={'down'}
                direction={'up'}
                value={NAV_PANEL_QUICK_START_GUIDE_HINT}
                selector={`.${classButtonDropdown}`}
            />
        }
    </>
};
