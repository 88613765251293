import { SegmentBoardEvent, segmentTrackAction } from '../../../../../../../middlewares/segment';
import { Dispatch, ThunkAction } from '../../../../../../../types/actions';

export const onTabActivityActive = (
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(segmentTrackAction(SegmentBoardEvent.BOARD_ACTIVITY_TAB_OPENED));
    };
    return action;
};
