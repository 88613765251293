import { TBoardId } from '../../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { loadNotificationSettings } from '../../../../../../../../../rest/effects/notificationSettings/api/helpers/loadNotificationSettings';

export const onLoad = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        dispatch(loadNotificationSettings(boardId));
    };
    return action;
};
