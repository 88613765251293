import { IGetState, TBoardId } from '../../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { getAuthUser } from '../../../../../../../../store/model/authUser/selectors/getAuthUser';
import { boardCreateTemplate } from '../../../../../../../../rest/effects/board/boardCreateTemplate';
import { showSnoozeBlocker } from '../../../../../../base/effects/showSnoozeBlocker';

export const onClick = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const { allowCreateBoard, allowBoardTemplate } = getAuthUser(getState());

        if (!allowBoardTemplate || !allowCreateBoard) {
            dispatch(showSnoozeBlocker({
                allow: !allowCreateBoard ? 'allowCreateBoard' : 'allowBoardTemplate'
            }));
        } else {
            dispatch(boardCreateTemplate(boardId));
        }
    };
    return action;
};
