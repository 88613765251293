import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState, TBoardId } from '../../../../types/types';
import { getInWorkCard } from '../../../../store/model/selectors/getInWorkCard';
import { inWorkCardActionSet } from '../../../../store/model/actions/inWorkCardActionSet';

export const checkInWorkCardOnDeletedBoard: ActionCreator<ThunkAction> = (
    boardId: TBoardId
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const inWorkCard = getInWorkCard(state);
        if (inWorkCard && inWorkCard.boardId === boardId) {
            dispatch(inWorkCardActionSet(null));
        }
    };
    return action;
}
