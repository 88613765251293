import { INavigationPanelQuickStartGuideProps, IQuickStartModalProps } from './types';
import * as React from 'react';
import './_NavigationPanelQuickStartGuide.scss'
import {
    CLASS_NAV_PANEL_QUICK_START_GUIDE,
    NAV_PANEL_QUICK_START_GUIDE_BOOK_SUBTITLE,
    NAV_PANEL_QUICK_START_GUIDE_BOOK_TITLE,
    NAV_PANEL_QUICK_START_GUIDE_COMPLETE,
    NAV_PANEL_QUICK_START_GUIDE_FINISH_BUTTON,
    NAV_PANEL_QUICK_START_GUIDE_FINISH_TIMEOUT,
    NAV_PANEL_QUICK_START_GUIDE_OR,
    NAV_PANEL_QUICK_START_GUIDE_SHOW, NAV_PANEL_QUICK_START_GUIDE_SHOW_LEARN,
    NAV_PANEL_QUICK_START_GUIDE_STEP_LATER,
    NAV_PANEL_QUICK_START_GUIDE_STEP_OK
} from './constants';
import { Button, ButtonDropdown, Icon, Modal, Userpic } from 'kui';
import { EQuickStartGuideType, NAVIGATION_PANEL_ASIDE_CLASS, NAVIGATION_PANEL_CONTAINER_CLASS } from '../../constants';
import FocusLock from 'react-focus-lock';
import { Portal } from '../../../../helpers/portal';
import { getMessages, root } from 'app/store/constants';

export const NavigationPanelQuickStartGuide = ({
    guideKey,
    isCompleted,
    isLast,
    isOpened: _isOpened,
    isNew,
    isReturnFocus,
    isShow,
    name,
    steps,
    supportPhotoUrl,
    onBook,
    onClick,
    onClose,
    onDone,
    onFinish,
    onLater,
    onModalClose,
    onOpen,
    onStepClick,
}: INavigationPanelQuickStartGuideProps) => {
    if (!isShow) return null;

    const className = CLASS_NAV_PANEL_QUICK_START_GUIDE;
    const classButton = className + '__button';
    const classTitle = className + '__title';
    const classProgress = className + '__progress';
    const classContact = className + '__contact';
    const classContactText = classContact + '-text';
    const classStep = className + '__step';
    const classModal = className + '__modal';
    const classCelebration = className + '__celebration';
    const classFinish = className + '__finish';

    const RocketIcon = require('!!@svgr/webpack!./rocket.svg').default;

    const [isOpened, setOpened] = React.useState(_isOpened);
    const [isFinished, setFinished] = React.useState(null);
    const [modal, setModal] = React.useState<IQuickStartModalProps>(null);

    function whiteList (node: HTMLElement) {
        const portal = document.querySelector('.' + className + '-portal') as HTMLElement;
        const isModal = portal && portal.contains(node);
        return !isModal;
    }

    React.useEffect(() => {
        setOpened(_isOpened);
    }, [_isOpened]);

    const isEfficiencyGuide = guideKey === EQuickStartGuideType.EFFICIENCY_JOURNEY_GUIDE;

    const [isLoaded, setLoaded] = React.useState(null);
    React.useEffect(() => {
        if (!isEfficiencyGuide) return;
        root.App.controller.mainView.doAfterLoader(() => {
            setTimeout(() => setLoaded(true), 1000);
        });
    }, []);

    const [isAnimation, setAnimation] = React.useState(null);
    React.useEffect(() => {
        if (!isLoaded) return;
        setAnimation(true);
    }, [isLoaded]);

    React.useEffect(() => {
        if (!isAnimation || isNew) return;
        setAnimation(false);
    }, [isNew]);

    const completedCount = steps.filter(step => step.completed).length;
    const totalCount = steps.length;
    const isGuideCompleted = !isCompleted && completedCount === totalCount;

    return <>
        <ButtonDropdown
            className={`${className} ${isCompleted ? className + '--completed' : ''}`}
            dropdownClassName={`${className + '__dropdown'} ${NAVIGATION_PANEL_ASIDE_CLASS}`}
            portal
            portalId={'navigation-aside-portal'}
            portalSelector={'.' + NAVIGATION_PANEL_CONTAINER_CLASS}
            notBlurClasses={[className + '__portal', 'cookies-dialog']}
            opened={isOpened}
            onOpen={() => {
                setOpened(true);
                onOpen();
            }}
            onClose={() => {
                setOpened(false);
                onClose();
            }}
            onClick={ !isOpened ? () => onClick(guideKey) : null}
        >
            <Button
                className={`${classButton} ${isAnimation ? classButton + '--animation' : ''}`}
                variant={'icon-text'}
                text={name}
            >
                {isEfficiencyGuide
                    ? <RocketIcon className={classButton + '-rocket'} />
                    : <Icon className={classButton + '-icon'} xlink={'kanbanchi-logo'} size={24} />
                }
                <div className={classButton + '-counter'}>
                    {completedCount +  '/' + totalCount}
                </div>
            </Button>
            <FocusLock
                returnFocus={isReturnFocus}
                whiteList={whiteList}
            >
                <div
                    className={className + '__wrapper'}
                    tabIndex={0} // сюда FocusLock поставит фокус при открытии
                >
                    {!isGuideCompleted &&
                        <Button
                            className={className + '__close'}
                            variant={'icon'}
                            aria-label={'Close'}
                            onClick={() => setOpened(false)}
                        >
                            <Icon
                                size={24}
                                xlink={'close'}
                            />
                        </Button>
                    }
                    <div className={className + '__header'}>
                        <div className={classTitle}>
                            <span className={classTitle + '-name'}>{name}</span>
                            <span className={classTitle + '-counter'}>
                                {Math.round(completedCount / totalCount * 100)}{NAV_PANEL_QUICK_START_GUIDE_COMPLETE}
                            </span>
                        </div>
                        <div className={classProgress}>
                            <div
                                className={classProgress + '-inner'}
                                style={{ width: `${completedCount / totalCount * 100}%` }}
                            />
                        </div>
                    </div>
                    {isGuideCompleted ?
                        <div className={classFinish}>
                            <span className={classFinish + '-text'}>
                                {getMessages().getText('quick_start_guides.finish.text', null, isEfficiencyGuide ? name : name + getMessages().getText('quick_start_guides.finish.text.guide'))}
                            </span>
                            <Button
                                className={classFinish + '-button'}
                                variant={'primary'}
                                onClick={() => {
                                    setFinished(true);
                                    setOpened(false);
                                    setTimeout(() => {// ждем феерверки
                                        setFinished(false);
                                        onFinish(guideKey);
                                        if (!isLast) setOpened(true);
                                    }, NAV_PANEL_QUICK_START_GUIDE_FINISH_TIMEOUT);
                                }}
                            >
                                {NAV_PANEL_QUICK_START_GUIDE_FINISH_BUTTON}
                            </Button>
                        </div>
                        : <>
                            <div className={className + '__body'}>
                                {steps.map(step => (
                                    <Button
                                        key={step.key}
                                        className={`
                                            ${classStep}
                                            ${step.completed ? classStep + '--completed' : ''}
                                            ${modal && modal.title === step.name ? classStep + '--opened' : ''}
                                        `}
                                        disabled={step.isNoEvents}
                                        onClick={() => {
                                            onStepClick(step.name);
                                            setModal({
                                                text: step.text,
                                                title: step.name,
                                                icon: step.icon,
                                                video: step.video,
                                                onClose: () => onModalClose(step.name),
                                                buttons: [
                                                    {
                                                        label: NAV_PANEL_QUICK_START_GUIDE_STEP_LATER,
                                                        onClick: () => {
                                                            setModal(null);
                                                            onLater(step.name);
                                                        }
                                                    },
                                                    {
                                                        label: NAV_PANEL_QUICK_START_GUIDE_STEP_OK,
                                                        variant: 'primary',
                                                        onClick: () => {
                                                            setModal(null);
                                                            onDone(guideKey, step.key, step.name);
                                                        }
                                                    },
                                                ]
                                            });
                                        }}
                                    >
                                        <Icon xlink={step.completed ? 'done' : step.icon} size={step.completed ? 16 : 24}/>
                                        <div className={classStep + '-label'}>
                                            <span className={classStep + '-text'}>{step.name}</span>
                                            <span className={classStep + '-show'}>
                                                {isEfficiencyGuide ? NAV_PANEL_QUICK_START_GUIDE_SHOW : NAV_PANEL_QUICK_START_GUIDE_SHOW_LEARN}
                                            </span>
                                        </div>
                                    </Button>
                                ))}
                            </div>
                            <div className={className + '__footer'}>
                                <div className={className + '__or'}>{NAV_PANEL_QUICK_START_GUIDE_OR}</div>
                                <Button
                                    className={classContact}
                                    onClick={onBook}
                                >
                                    <Userpic src={supportPhotoUrl} />
                                    <div className={classContactText}>
                            <span className={classContactText + '-title'}>
                                {NAV_PANEL_QUICK_START_GUIDE_BOOK_TITLE}
                            </span>
                                <span className={classContactText + '-subtitle'}>
                                {NAV_PANEL_QUICK_START_GUIDE_BOOK_SUBTITLE}
                            </span>
                                    </div>
                                </Button>
                            </div>
                        </>
                    }
                </div>
            </FocusLock>
        </ButtonDropdown>
        <Portal selector={'body'} className={className + '__portal'}>
            {modal &&
                <Modal
                    title={''}
                    onClose={() => {
                        if (modal.onClose) modal.onClose();
                        setModal(null);
                    }}
                    className={classModal}
                >
                    <Button
                        className={classModal + '-close'}
                        variant={'icon'}
                        onClick={() => {
                            if (modal.onClose) modal.onClose();
                            setModal(null);
                        }}
                    >
                        <Icon xlink={'close'} size={24} />
                    </Button>
                    <div className={classModal + '-media'}>
                        <iframe
                            width={600}
                            height={338}
                            src={`${modal.video}?cc_load_policy=1`}
                            title={'YouTube video player'}
                            allow={'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'}
                            allowFullScreen
                        />
                    </div>
                    <div className={classModal + '-content'}>
                        <div className={classModal + '-text'}>
                            <div className={classModal + '-title'}>
                                <Icon xlink={modal.icon} size={24} />
                                {modal.title}
                            </div>
                            <div className={classModal + '-subtitle'}>{modal.text}</div>
                        </div>
                        <div className={classModal + '-actions'}>
                            {modal.buttons.map(button => (
                                <Button
                                    key={button.label}
                                    onClick={button.onClick}
                                    variant={button.variant || 'secondary'}
                                >
                                    {button.label}
                                </Button>
                            ))}
                        </div>
                    </div>
                </Modal>
            }
            {isFinished &&
                <div className={classCelebration}>
                    <div className={classCelebration + '-before'} />
                    <div className={classCelebration + '-after'} />
                </div>
            }
        </Portal>
        </>;
};
