import { Button, Icon, Userpic } from 'kui';
import * as React from 'react';
import { ICommentLikesProps } from './types';
import './_CommentLikes.scss';
import { CLASS_COMMENT } from '../../constants';
import { renderToStaticMarkup } from 'react-dom/server';
import { COMMENT_TOOLTIP_OTHERS, COMMENT_TOOLTIP_USER_LIKE, COMMENT_TOOLTIP_USER_LIKE_AND_OTHERS } from './constants';
import { AnalyzeRender } from '../../../../../helpers/memoizeHelper';

//@ts-ignore
const analyzeRender = window.Settings.development ? new AnalyzeRender(`CommentLikes`) : null;

export function CommentLikes({
    authUserPermissionId,
    commentId,
    likes,
    isReadonly,
    onClick
}: ICommentLikesProps) {
    if (analyzeRender) analyzeRender.call(`CommentLikes ${commentId}`);

    const count = likes.length;

    if (isReadonly && !count) return;

    const isMyLike = likes.find(user => user && user.permissionId === authUserPermissionId);

    const className = CLASS_COMMENT + '-likes';
    const classTooltip = className + '__tooltip';

    let tooltip = !!count && COMMENT_TOOLTIP_USER_LIKE;

    if (count > 1 || !isMyLike && count) {
        const title = `<p class="${classTooltip + '-title'}">
           ${isMyLike ? COMMENT_TOOLTIP_USER_LIKE_AND_OTHERS : COMMENT_TOOLTIP_OTHERS}
        </p>`;

        tooltip = title + likes.reduce((tooltip, user) => {
            const { permissionId, fullName, photoUrl } = user;
            if (permissionId === authUserPermissionId) return tooltip;
            return tooltip + (`
                <span class="${classTooltip + '-body'}">
                    ${renderToStaticMarkup(<Userpic src={photoUrl} />)}
                    <div class="${classTooltip + '-body-name'}">
                        ${fullName}
                    </div>
                </span>
            `)
        }, '');
    }

    return (
        <Button
            className={`
                ${className}
                ${isMyLike ? className + '--active' : ''}
            `}
            onClick={onClick}
            variant={count ? 'icon-text' : 'icon'}
            text={!!count && String(count)}
            tooltip={{ value: tooltip, isNoWrap: true }}
            disabled={isReadonly}
        >
            <Icon xlink={'like'} size={24} />
        </Button>
    );
}
