import * as React from 'react';
import { IDatesProps } from './types';
import { DATES_AUTO_TEXT, DATES_AUTO_TITLE, DATES_DUE_LABEL, DATES_START_LABEL, DATES_TEXT } from '../../constants';
import { CLASS_BOARD_DETAILS } from '../../../../constants';
import './_Dates.scss';
import { EDateType } from '../../../../../../../../const';
import { AsidePanelTimeSelect } from '../../../../../asidePanel/components/AsidePanelTimeSelect/AsidePanelTimeSelect';
import { Switch } from 'kui';

export function Dates ({
    dueDate,
    isAutoStartDate,
    isReadonly,
    onAutoStartDateToggle,
    startDate,
    onDateChange
}: IDatesProps){
    const className = CLASS_BOARD_DETAILS + '__dates';
    const classTitle = className + '-title';
    const classText = className + '-text';
    const classInputsGroup = className + '-input-group';
    const classInput = className + '-input';
    const classSwitch = className + '-switch';

    const [startTime, setStartHours] = React.useState(startDate);
    const [dueTime, setDueHours] = React.useState(dueDate);

    const onTimeChange = (
        value: Date,
        type: EDateType
    ) => {
        if (type === EDateType.START_DATE) {
            setStartHours(value);
            onDateChange(value, type);
        } else {
            setDueHours(value);
            onDateChange(value, type);
        }
    }

    return (
        <div className={className}>
            <span className={classSwitch}>
                {DATES_AUTO_TITLE}
                <Switch
                    checked={isAutoStartDate}
                    disabled={isReadonly}
                    onChange={onAutoStartDateToggle}
                />
            </span>
            <div className={classText}>
                {DATES_AUTO_TEXT}
            </div>
            <div className={classTitle}>
                {DATES_TEXT}
            </div>
            <div className={classInputsGroup}>
                <AsidePanelTimeSelect
                    className={classInput}
                    date={startTime}
                    label={DATES_START_LABEL}
                    onChange={(value) => onTimeChange(value, EDateType.START_DATE)}
                    isReadonly={isReadonly}
                />
                <AsidePanelTimeSelect
                    className={classInput}
                    date={dueTime}
                    label={DATES_DUE_LABEL}
                    onChange={(value) => onTimeChange(value, EDateType.DUE_DATE)}
                    isReadonly={isReadonly}
                />
            </div>
        </div>
    );
}
