import { IApplicationState, TBoardId } from '../../../../types/types';
import { IBoardExportSettings } from '../../../../types/rest/IRestUserBoardMeta';
import { getBoard } from '../../selectors/getBoardById';

export const getBoardExportSettings = (
    state: IApplicationState,
    boardId: TBoardId
): IBoardExportSettings => {
    const { userMeta } = getBoard(state, boardId);

    return userMeta && userMeta.exportSettings;
};
