import { IApplicationState } from '../../../../types/types';
import { getAuthUser } from './getAuthUser';
import { EUserDateformat } from '../../../../types/rest/IRestAuthUser';

export const getUserDateFormat = (
    state: IApplicationState
): EUserDateformat => {
    const { meta, country } = getAuthUser(state);
    return meta && meta.dateFormat || (country === 'US' ? EUserDateformat.FORMAT_AMERICAN : EUserDateformat.FORMAT_EUROPEAN);
};
