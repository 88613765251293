import { ERestMethod } from '../types';
import { getRestHeaders } from '../constants';

export const getRestHeadersDelete = (
): RequestInit => {
    return getRestHeaders(ERestMethod.DELETE);
};

export const getRestHeadersGet = (
): RequestInit => {
    return getRestHeaders(ERestMethod.GET);
};

export const getRestHeadersPatch = (
): RequestInit => {
    return getRestHeaders(ERestMethod.PATCH);
};

export const getRestHeadersPost = (
): RequestInit => {
    return getRestHeaders(ERestMethod.POST);
};

export const getRestHeadersPut = (
): RequestInit => {
    return getRestHeaders(ERestMethod.PUT);
};
