//todo O_O move it to selector and add state, because getBoardWorkHoursInDay selector

/**
 * @param timeNumber (Number) - Ваше время
 * @param seconds (Boolean) - Время передано в секундах
 * @param hoursInDay (Number) - Сколько часов в дне
 * @param includeDayDigit (Boolean) - Переводить ли результат в Дни
 */
export const convertTimeNumberToString = (
    timeNumber: number,
    seconds: boolean,
    hoursInDay: number = 24,
    includeDayDigit: boolean = true
): string => {
    let res = '';
    if (!timeNumber) {
        return res;
    }
    let minutes = seconds ? Math.floor(timeNumber / 60) : timeNumber;
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / hoursInDay);
    minutes = minutes - hours * 60;
    if (includeDayDigit) {
        hours = hours - days * hoursInDay;
    }

    if (days > 0 && includeDayDigit) {
        if (res.length > 0) {
            res += ' ';
        }
        res += days + 'd';
    }

    if (hours > 0) {
        if (res.length > 0) {
            res += ' ';
        }
        res += hours + 'h';
    }
    if (minutes > 0) {
        if (res.length > 0) {
            res += ' ';
        }
        res += minutes + 'm';
    }
    return res;
};
