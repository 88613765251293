import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { TBoardId } from '../../../../../../../../../types/types';
import { generatePrefixes } from '../effects/generatePrefixes';

export const onGenerate = (
    boardId: TBoardId,
    abortController: AbortController
): ThunkAction => {
    const action = (
        dispatch: Dispatch
    ) => {
        return dispatch(generatePrefixes(boardId, abortController))
    };
    return action;
};
