import { Dispatch, ThunkAction } from 'app/types/actions';
import { IGetState } from '../../../../../../types/types';
import { IUpdatesItemSlide } from '../../../types';
import { root } from 'app/store/constants';
import { ASSIGNED_TO_ME_BACK_BOARD_LOCALSTORAGE } from '../../../../../../const';
import { getIsAssignedToMeActive } from '../../../../../../store/router/selectors/getIsAssignedToMeActive';

export function onShowTips (
    slides: IUpdatesItemSlide[],
): ThunkAction {
    const action = (
        dispatch: Dispatch,
        getState: IGetState,
    ) => {
        if (!slides || !slides.length) return;
        const isMyWork = getIsAssignedToMeActive(getState());
        if (isMyWork) {
            const boardId = localStorage.getItem(ASSIGNED_TO_ME_BACK_BOARD_LOCALSTORAGE);
            root.App.router.setCurrentDashboard(boardId, false);
            root.App.router.navigate(root.App.router.getUrl(null), {trigger: true});
        }
        root.App.controller.showUpdatesTips(slides);
    };
    return action
};
