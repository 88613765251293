import {IGetState, TBoardId} from '../../../../../../../../types/types';
import {Dispatch, ThunkAction} from '../../../../../../../../types/actions';
import {EBackupExportType} from '../../../../../../../../types/rest/IRestBackup';
import {getAutoBackupsByBoardIdAndExportType} from '../../../../backups/AutoBackups/store/autoBackup/selectors/getAutoBackupsByBoardIdAndExportType';
import {SegmentBoardEvent, segmentTrackAction} from '../../../../../../../../middlewares/segment';
import {
    SegmentBoardOption,
    SegmentBoardOptionStatusValue
} from '../../../../../../../../middlewares/segment/trackEntities/boardEvents';
import { editAutoBackup } from '../../../../backups/AutoBackups/effects/editAutoBackup';

export const exportAutomaticToggle = (
    boardId: TBoardId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const autoBackup = getAutoBackupsByBoardIdAndExportType(state, boardId, EBackupExportType.GOOGLE_SHEET);
        dispatch(segmentTrackAction(SegmentBoardEvent.AUTO_EXPORT_TOGGLED, {
            name: SegmentBoardOption.STATUS,
            value: !autoBackup.enabled ? SegmentBoardOptionStatusValue.ON: SegmentBoardOptionStatusValue.OFF}));
        return dispatch(editAutoBackup(boardId, {
            ...autoBackup,
            enabled: !autoBackup.enabled,
            exportType: EBackupExportType.GOOGLE_SHEET,
            isArchiveIncluded: false,
            isLocking: false
        }))
    };
    return action;
};
