import * as React from 'react';
import { useEffect } from 'react';
import { ICardWatchProps } from './types';
import './_CardWatch.scss';
import { Button, ButtonDropdown, ButtonTitle, Icon } from 'kui';
import { CLASS_CARD_DETAILS, CLASS_SECTION_WATCH } from '../../../constants';
import {
    CHANGE_SETTINGS_TOOLTIP,
    CHANGE_SETTINGS_TOOLTIP_LINK,
    WATCH_STOP_TOOLTIP,
    WATCH_TEXT,
    WATCH_TEXT_ACTIVE,
    WATCH_TOOLTIP,
    WATCH_TOOLTIP_NOTIFICATIONS_OFF,
} from './constants';
import { AsidePanelContext } from '../../../../asidePanel/components/AsidePanel/constants';
import { getAsidePanelTooltip } from '../../../../asidePanel/helpers/getAsidePanelTooltip';
import { getParentsClasses } from '../../../../../helpers/getParentsClasses';

export function CardWatch({
    isInAppCardNotificationsOn,
    isWatch,
    isAllCardsWatched,
    onClickIcon,
    onDidMount,
    onToggle,
    onOpenBoardSettings
}: ICardWatchProps) {
    const className = CLASS_CARD_DETAILS + '__watch';
    const classNameActive = isWatch || isAllCardsWatched ? className + '--active' : '';
    const classNameIcon = CLASS_CARD_DETAILS + '__watch-icon';
    const classNameText = CLASS_CARD_DETAILS + '__watch-text';
    const classNameNoNotifications = className + '--no-notifications';
    const classNameIconSeparated = classNameIcon + '--separated';
    const classNameInfo = CLASS_SECTION_WATCH + '__info-icon';
    const classNameInfoOpened = classNameInfo + '--opened';
    const classNameDropdown = CLASS_SECTION_WATCH + '__dropdown';
    const classNameTooltip = CLASS_SECTION_WATCH + '__tooltip';
    const classNameClose = classNameTooltip + '-close';
    const classNameSubscribedToAll = className + '--subscribed-all';

    const { isMobile } = React.useContext(AsidePanelContext);
    const dropdown = React.useRef(null);
    const [opened, setOpened] = React.useState(false);

    useEffect(() => {
        onDidMount();
    }, []);

    const onClickHandler = (e: React.MouseEvent<HTMLElement>) => {
        const classes = getParentsClasses(
            e.target as HTMLElement,
            [classNameIconSeparated, classNameInfo, classNameDropdown, className]
        );
        if (classes.includes(classNameIconSeparated)) {
            onClickIcon();
            setOpened(false);
        }
        else if (classes.includes(classNameDropdown) || classes.includes(classNameInfo)) {
            return;
        } else {
            if (isAllCardsWatched) {
                setOpened(!opened);
            } else {
                onToggle();
                setOpened(false);
            }
        }
    }

    const tooltipValue = isAllCardsWatched ? null
        : isInAppCardNotificationsOn ?
            isWatch  ? WATCH_STOP_TOOLTIP : WATCH_TOOLTIP
            : WATCH_TOOLTIP_NOTIFICATIONS_OFF;

    const subscribedToAllTooltip = (
        <p>
            {CHANGE_SETTINGS_TOOLTIP}
            <a onClick={onOpenBoardSettings}>
                {CHANGE_SETTINGS_TOOLTIP_LINK}
            </a>
        </p>
    );

    return (
        <>
            <Button
                className={`
                        ${className}
                        ${classNameActive}
                        ${isAllCardsWatched ? classNameSubscribedToAll : ''}
                        ${!isInAppCardNotificationsOn ? classNameNoNotifications : ''}
                    `}
                tooltip={!opened && getAsidePanelTooltip({
                    isNoEvents: true,
                    direction: 'left',
                    value: tooltipValue
                })}
                variant={'icon-text'}
                role={'button'} // for aria-pressed
                aria-pressed={isWatch}
                onClick={onClickHandler}
            >
                {!isInAppCardNotificationsOn && !isAllCardsWatched ?
                    <Icon
                        size={24}
                        xlink={'bell-no'}
                        className={classNameIconSeparated}
                    />
                    :
                    <Icon
                        className={classNameIcon}
                        size={16}
                        xlink={isMobile ? 'eye' : isWatch ? 'done' : 'bell'}
                    />
                }
                <ButtonTitle
                    className={classNameText}
                >
                    {isWatch || isAllCardsWatched ? WATCH_TEXT_ACTIVE : WATCH_TEXT}
                </ButtonTitle>
            </Button>
            {
                isAllCardsWatched &&
                <ButtonDropdown
                    className={classNameDropdown}
                    opened={opened}
                    onOpen={() => setOpened(true)}
                    onClose={() => setOpened(false)}
                    directionHorizontal={'right'}
                >
                    <Button
                        className={`
                                    ${classNameInfo}
                                    ${opened ? classNameInfoOpened : ''}
                                `}
                        variant={'icon'}
                    >
                        <Icon xlink={'info'} size={16}/>
                    </Button>
                    <div
                        className={classNameTooltip}
                        ref={dropdown}
                        // tabIndex={0}
                    >
                        {subscribedToAllTooltip}
                        <Button
                            className={classNameClose}
                            variant={'icon'}
                            onClick={() => setOpened(false)}
                        >
                            <Icon xlink={'close'} size={16}/>
                        </Button>
                    </div>
                </ButtonDropdown>
            }
        </>
    );
}
