import { IApplicationState, TBoardId } from '../../../types/types';
import { getCard } from './getCard';
import { getBoardWorkHoursInDay } from './getBoardWorkHoursInDay';
import { getTimeTrackerTotalSeconds } from './getTimeTrackerTotalSeconds';
import { TimeTrackerHelper } from '../../../view/react_components/dialogs/timeTrackerDialog/helpers/TimeTrackerHelper';

/**
 * @param state
 * @param boardId
 * @param cardId
 * @param isTrim при больших значениях обрезать секунды, минуты
 * @returns
 */
export const getCardTotalSpentTimeStr = (
    state: IApplicationState,
    boardId: TBoardId,
    cardId: number,
    isTrim?: boolean,
): string => {
    const card = getCard(state, cardId);
    if (!card || !card.totalSpentTime) {
        return null;
    }
    const workHoursInDay = getBoardWorkHoursInDay(state, boardId);
    const seconds = getTimeTrackerTotalSeconds(state, cardId, workHoursInDay);
    const totalSpentTime = TimeTrackerHelper.formatSecondsToString(seconds, workHoursInDay, isTrim, true);
    return totalSpentTime;
};
