import { connect } from 'react-redux';
import { CopyLinkDialog } from '../../components/CopyLinkDialog/CopyLinkDialog';
import { ICopyLinkDialogEvents, ICopyLinkDialogFields } from '../../components/CopyLinkDialog/types';
import { onClose } from './events/onClose';
import { onCopy } from './events/onCopy';

const mapStateProps = (): ICopyLinkDialogFields => {
    return {};
};

const mapDispatchToProps = (dispatch: any): ICopyLinkDialogEvents => ({
    onClose: () => dispatch(onClose()),
    onCopy: (link) => dispatch(onCopy(link)),
});

export const CopyLinkDialogHOC = connect(mapStateProps, mapDispatchToProps)(CopyLinkDialog);
