import { IPushNotificationSettings } from '../types';

export const AT_UPDATE = 'PUSH_NOTIFICATION_SETTINGS_UPDATE';

export interface IUpdateAction {
    type: typeof AT_UPDATE;
    pushNotification: IPushNotificationSettings;
}

export type TPushNotificationSettingsAction = (IUpdateAction);
