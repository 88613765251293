import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TCardId, TPermissionId } from '../../../../../../../../types/types';
import { getBoardIdByCardId } from '../../../../../../../../store/model/selectors/getBoardIdByCardId';
import {
    ASSIGNEES_SHARE_ID
} from '../../../../../../aside_panel/cardDetails/AssigneesSection/components/AssigneesSearchSelect/constants';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';
import {
    getAuthUserPlatformType
} from '../../../../../../../../store/model/authUser/selectors/getAuthUserPlatformType';
import { EAuthUserPlatformType } from '../../../../../../../../types/rest/IRestAuthUser';
import { root } from '../../../../../../../../store/constants';
import { shareClick } from '../../../../../topBar/hocs/sharing/google/TopBarSharingHOC/effect/shareClick';
import {
    cardToggleAssignee,
    EToggleAssigneeType
} from '../../../../../../aside_panel/cardDetails/AssigneesSection/hocs/AssigneesSectionHOC/effects/cardToggleAssignee';
import { getCardAssignee } from '../../../../../../../../store/model/cardAssignees/selectors/getCardAssignee';

export const onToggle = (
    cardId: TCardId,
    permissionId: TPermissionId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const boardId = getBoardIdByCardId(state, cardId);

        if (permissionId === ASSIGNEES_SHARE_ID) {
            dispatch(segmentTrackAction(SegmentCardEvent.ASSIGNEES_SHARE_CLICKED));
            const platformType = getAuthUserPlatformType(getState());
            switch (platformType) {
                case EAuthUserPlatformType.MICROSOFT:
                    return root.App.controller.mainView.showSharingDialog(boardId);
                default:
                    return dispatch(shareClick(boardId));
            }
        } else {
            const card = getCardAssignee(state, cardId);
            const assignees = [...card.assignees || []];
            const index = assignees.findIndex(assignee => assignee.sharedUser.permissionId === permissionId);
            return dispatch(cardToggleAssignee(boardId, cardId, permissionId, index >= 0 ? EToggleAssigneeType.DELETE : EToggleAssigneeType.ADD));
        }
    };
    return action;
}
