import {TCardId} from '../../../../../../../../types/types';
import {
    SegmentCardEvent, SegmentCardOptions,
    SegmentCardSourceValue
} from '../../../../../../../../middlewares/segment/trackEntities/cardEvents';
import {Dispatch, ThunkAction} from '../../../../../../../../types/actions';
import {segmentTrackAction} from '../../../../../../../../middlewares/segment';
import {openPrintCardTab} from '../../../../../../base/effects/openPrintCardTab';

export const onCardPrint = (
    cardId: TCardId,
    source: SegmentCardSourceValue
): ThunkAction => {
    return (dispatch: Dispatch) => {
        dispatch(segmentTrackAction(SegmentCardEvent.PRINT, {
            name: SegmentCardOptions.SOURCE,
            value: source
        }));
        dispatch(openPrintCardTab(cardId, true));
    };
};
