import * as React from 'react';
import './_BoardCustomTheme.scss';
import { IBoardCustomThemeProps } from './types';
import { Button, Icon, Loader, Radio } from 'kui';
import { ChangeEvent } from 'react';
import { EThemeBackgroundOption } from 'app/types/rest/IRestTheme';
import {
    THEME_CHANGE_BACKGROUND,
    THEME_USE_YOUR_OWN_TEXT,
    THEME_WRONG_FILE_SIZE_TEXT,
    ThemeBackgroundOptionList,
} from './constants';
import { CLASS_BOARD_BRANDING, CLASS_BOARD_DETAILS_SECTION } from '../../../../../../aside_panel/boardDetails/constants';
import { SettingsSection } from '../../../../SettingsSection/SettingsSection';
import { SectionToggler } from '../../../../SectionToggler/SectionToggler';
import { ESettingsSectionType } from '../../../../SettingsSection/types';
import { BOARD_LOGO_EMPTY } from '../../../../../../aside_panel/boardDetails/branding/Logo/components/BoardLogo/constants';

export function BoardCustomTheme ({
    backgroundImage,
    backgroundOption,
    sectionType,
    themeId,
    onBackgroundOptionChange,
    onClickDisallow,
    onFileSelect,
    onChangeSwitch,
    onOptionChange
}: IBoardCustomThemeProps) {
    const isReadonly = sectionType === ESettingsSectionType.READONLY;
    const [isEnabled, setEnabled] = React.useState(!!backgroundImage);
    const [bgOption, setBgOption] = React.useState(backgroundOption);
    const [isLoading, setLoading] = React.useState(false);
    const fileInputRef = React.useRef(null);

    const className = CLASS_BOARD_DETAILS_SECTION + ' ' + CLASS_BOARD_DETAILS_SECTION + '--custom-theme';
    const classCustom = CLASS_BOARD_BRANDING + '__custom-theme';
    const classImg = CLASS_BOARD_BRANDING + '__custom-img';
    const classImgContainer = CLASS_BOARD_BRANDING + '__custom-img-container';
    const classImgLoading = isLoading ? classImg + '--loading' : '';
    const classImgButtons = CLASS_BOARD_BRANDING + '__custom-img-buttons';
    const classImgNote = CLASS_BOARD_BRANDING + '__custom-img-note';
    const classImgEmpty = CLASS_BOARD_BRANDING + '__custom-empty';

    const onChangeBackgroundClick = () => {
        fileInputRef.current.click();
    }
    const onFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files.length > 0) {
            setLoading(true);
            const file: File = e.target.files[0];
            onFileSelect(file, bgOption)
                .finally(() => setLoading(false));
        }
    }
    const onChangeOption = (option: EThemeBackgroundOption) => {
        onOptionChange(option);
        setBgOption(option);
        if (backgroundImage) {
            onBackgroundOptionChange(themeId, option);
        }
    }
    const onChangeSwitchHandle = () => {
        setEnabled(!isEnabled);
        onChangeSwitch(!isEnabled);
    }
    const optionActiveIndex = ThemeBackgroundOptionList.findIndex((item) => item.key === bgOption);

    React.useEffect(() => {
        setEnabled(!!backgroundImage);
    }, [backgroundImage]);

    React.useEffect(() => {
        setBgOption(backgroundOption);
    }, [backgroundOption]);

    return <SettingsSection
            className={className}
            sectionType={sectionType}
            title={THEME_USE_YOUR_OWN_TEXT}
            titleButton={<SectionToggler
                checked={isEnabled}
                isDisabled={isReadonly || !!backgroundImage}
                onChange={onChangeSwitchHandle}
            />}
            onClickDisallow={onClickDisallow}
        >
            {isEnabled
                ? <div className={classCustom}>
                    <div className={classImgContainer}>
                        <div
                            className={`
                                ${classImg}
                                ${classImgEmpty}
                                ${classImgLoading}
                            `}
                            style={backgroundImage ? { backgroundImage: 'url(\'' + backgroundImage + '\')' } : {}}
                        >
                            {!backgroundImage && <div className={classImgEmpty}>{BOARD_LOGO_EMPTY}</div>}
                            {isLoading && <Loader />}
                        </div>
                        <div className={classImgButtons}>
                            <Button
                                text={THEME_CHANGE_BACKGROUND}
                                variant={'icon-text'}
                                onClick={onChangeBackgroundClick}
                            >
                                <Icon
                                    size={24}
                                    xlink={'cloud-download'}
                                />
                            </Button>
                            <div className={classImgNote}>{THEME_WRONG_FILE_SIZE_TEXT}</div>
                        </div>
                    </div>
                    <Radio
                        onChange={ e => onChangeOption(ThemeBackgroundOptionList[ e.index ].key) }
                        active={optionActiveIndex}>
                        {
                            ThemeBackgroundOptionList.map(item => {
                                const { key, value } = item;
                                return (
                                    <span key={key}>{value}</span>
                                );
                            })
                        }
                    </Radio>
                    <input
                        className="hidden"
                        ref={fileInputRef}
                        type="file"
                        onChange={onFileInputChange}
                    />
                </div>
                : <div/>
            }
    </SettingsSection>
};
