import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState, TFilterId } from '../../../../../../../types/types';
import { MY_WORK_FILTER_BOARD_ID } from '../../../../../aside_panel/filterPanelBoard/constants';
import {
    filterPanelActionSetAction
} from '../../../../../aside_panel/filterPanelBoard/store/filterPanelBoards/actions/filterPanelActionSetAction';
import { enabledSet } from '../../../../../aside_panel/filterPanelBoard/store/filterPanel/actions/enabledSet';
import {
    getShowOnBarSortedSavedFilterIds
} from '../../../../../aside_panel/filterPanelBoard/components/SavedSection/hocs/selectors/getShowOnBarSortedSavedFilterIds';
import { savedFilterSelector } from '../../../../../aside_panel/filterPanelBoard/store/selectors/savedFilterSelector';
import {
    savedFilterActionsSet
} from '../../../../../aside_panel/filterPanelBoard/store/savedFilters/actions/savedFilterActionsSet';
import {
    enabledSet as filterEnabledSet
} from '../../../../../aside_panel/filterPanelBoard/store/filter/actions/enabledSet';
import { getShowOnBarEnabledFilterCount } from '../FilterBarHOC/selector/getShowOnBarEnabledFilterCount';
import { onFilterClose } from '../../../../../main/topBar/hocs/MyWorkTopBarSearchHOC/events/onFilterClose';
import { getAllFiltersUnselected } from '../FilterHOC/selectors/getAllFiltersUnselected';
import { updateMyWorkMeta } from '../../../../../../../rest/effects/authUser/myWork/updateMyWorkMeta';

export const onToggleAll = (
    filterId?: TFilterId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const filterIds = getShowOnBarSortedSavedFilterIds(state);
        const isAllFiltersEnabled = filterIds.length === getShowOnBarEnabledFilterCount(state);
        if (isAllFiltersEnabled) {
            dispatch(onFilterClose());
        } else if (!filterId) {
            filterIds.forEach(id => {
                dispatch(savedFilterActionsSet(id, filterEnabledSet(true)));
            });
            dispatch(updateMyWorkMeta({ enabledFilters: filterIds }));
        } else {
            const isAllFiltersUnselected = getAllFiltersUnselected(state);
            const { isEnabled } = savedFilterSelector(state, filterId);
            filterIds.forEach(id => {
                if (!isAllFiltersUnselected && id === filterId) return;
                dispatch(savedFilterActionsSet(id, filterEnabledSet(isAllFiltersUnselected ? !isEnabled : isEnabled)));
            });
            dispatch(updateMyWorkMeta({ enabledFilters: isAllFiltersUnselected ? filterIds : [] }));
        }
        dispatch(filterPanelActionSetAction(MY_WORK_FILTER_BOARD_ID, enabledSet(true)));
    }
    return action;
}
