import { ISnackbarTimer } from './types';
import { Dispatch } from '../../../../../types/actions';
import { SnackbarVariant } from '../../store/types';
import { SNACKBAR_BUTTON_CANCEL, SNACKBAR_BUTTON_DELETE } from '../constants';
import { getDefaultActionEvent } from '../helpers/getDefaultActionEvent';
import { addUpdateSnackbarWithBubble } from '../addUpdateSnackbarWithBubble/addUpdateSnackbarWithBubble';

export const snackbarTimerDefault = (
    options: ISnackbarTimer
) => {
    return (dispatch: Dispatch) => {
        const shouldUseControlsDefault = options.controls === undefined;
        if (shouldUseControlsDefault) {
            options.controls = [
                {
                    action: () => {
                        dispatch(getDefaultActionEvent(options.id, options.actionCancel));
                    },
                    title: SNACKBAR_BUTTON_CANCEL
                },
                {
                    action: () => {
                        dispatch(getDefaultActionEvent(options.id, options.actionApply));
                    },
                    isPrimary: true,
                    title: SNACKBAR_BUTTON_DELETE
                },
            ];
        }
        dispatch(addUpdateSnackbarWithBubble({
            id: options.id,
            controls: options.controls,
            text: options.text,
            title: null,
            variant: SnackbarVariant.TIMER,
            onTimer: () => {
                dispatch(getDefaultActionEvent(options.id, options.actionApply));
            }
        }));
    }
};
