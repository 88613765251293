import { EDependencyTypes, IDependency } from '../../../../../store/model/dependencies/dependency/types';
import { IGetState } from '../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { dependencyToRestPredecessor } from './helper/dependencyToRestPredecessor';
import { fetchHandler } from '../../../../../util/fetchHandler';
import { IRestPredecessor } from '../../../../../types/rest/IRestPredecessor';
import Util from '../../../../../util/util';
import { REST_PREDECESSOR_LIST } from '../../../../constants';
import { getRestHeadersPost } from '../../../../helpers/getRestHeadersHelper';

export const postListRest = (
    dependencies: IDependency[]
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const body = dependencies.map(dep => dependencyToRestPredecessor(dep));
        return fetchHandler<IRestPredecessor[]>(
            Util.getApiUrl(REST_PREDECESSOR_LIST), {
                ...getRestHeadersPost(),
                body: JSON.stringify(body)
            }
        ).then((predecessors: IRestPredecessor[]) => {
            return predecessors.map(predecessor => restPredecessorToDependency(predecessor));
        });
    };
    return action;
};

const restPredecessorToDependency = (
    response: IRestPredecessor
) => {
    const dependency: IDependency = {
        id: response.id,
        successorId: response.cardId,
        type: EDependencyTypes.FINISH_TO_START,
        predecessorId: response.predecessorId
    }
    return dependency;

}
