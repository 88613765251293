import { IGetState, TCardId, TChecklistId, TChecklistItemId } from '../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IRestChecklistItem } from '../../../../types/rest/IRestChecklistItem';
import { itemRestPatch } from './api/itemRestPatch';
import { checklistItemsUpdateStore } from '../../../../store/model/checklists/actionCreators/checklistItemsUpdate';
import { getChecklistItems } from '../../../../store/model/checklists/checklist/selectors/getChecklistItems';
import { sendRealTimeStoreActionByCardId } from '../../../../view/react_components/base/helpers/realTimeHelperTS';
import { cardChecklistStatsUpdate } from '../../../../store/model/effects/cardChecklistStatsUpdate';
import { ICards } from '../../../../store/model/cards/types';
import { cardsActiveRequestsUpdate } from '../../../../store/requestsState/effects/cardsActiveRequestsUpdate';
import { ICardBatches } from '../card/api/types';
import { getBoardByCardId } from '../../../../store/model/selectors/getBoardByCardId';
import { STATUS_LIST_ARCHIVE_LIST_ID } from '../../../../const';
import { cardsArchiveOrDelete } from '../../../../view/react_components/base/effects/cardsArchiveOrDelete';

export const checklistItemUpdate = (
    cardId: TCardId,
    checklistId: TChecklistId,
    itemId: TChecklistItemId,
    item: IRestChecklistItem,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const checklistItems = getChecklistItems(state, cardId, checklistId);
        if (!checklistItems) return Promise.reject();

        const itemsForRollback = {...checklistItems};
        const itemsForUpdate = {
            ...checklistItems,
            [itemId]: {
                ...checklistItems[itemId],
                ...item,
            }
        };

        const action = checklistItemsUpdateStore(cardId, checklistId, itemsForUpdate);
        dispatch(action);

        const cards: ICards = {[cardId]: null};
        dispatch(cardsActiveRequestsUpdate(cards, 1));

        return dispatch(itemRestPatch(cardId, itemId, item))
            .then(() => {
                dispatch(sendRealTimeStoreActionByCardId(cardId, action));
                dispatch(cardChecklistStatsUpdate(cardId))
                    .then((cardBatches: ICardBatches) => { // переместил then сюда, чтобы finaly не зависел от cardChecklistStatsUpdate
                        if (cardBatches) {
                            const { card } = cardBatches[cardId];
                            if (card && card.doneDate) { // карта стала Done
                                const { statusLists } = getBoardByCardId(getState(), cardId);
                                const isArchiveOnDone = statusLists && statusLists.doneId === STATUS_LIST_ARCHIVE_LIST_ID;
                                if (isArchiveOnDone) {
                                    let cardIds: TCardId[] = [];
                                    for (let cardId in cardBatches) {
                                        const { card } = cardBatches[cardId];
                                        if (card.doneDate) {
                                            cardIds.push(Number(cardId));
                                        };
                                    }
                                    const isEpic = cardIds.length > 1;
                                    dispatch(cardsArchiveOrDelete(cardIds, true, true, isEpic));
                                }
                            }
                        }
                    })
            })
            .catch((e: any) => {
                console.error(e);
                dispatch(checklistItemsUpdateStore(cardId, checklistId, itemsForRollback));
            })
            .finally(() => {
                dispatch(cardsActiveRequestsUpdate(cards, -1));
            });
    };
    return action;
};
