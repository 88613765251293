import { IApplicationState, TCardId } from '../../../../../../../../types/types';
import { getCardAssignee } from '../../../../../../../../store/model/cardAssignees/selectors/getCardAssignee';
import { TStatus } from '../../../../../../../../types/model';

export const getIsCardInMyWork = (
    state: IApplicationState,
    cardId: TCardId
): boolean => {
    const cardAssignee = getCardAssignee(state, cardId)
    return cardAssignee && (cardAssignee.status !== TStatus.STATUS_DELETED && cardAssignee.status !== TStatus.STATUS_FREEZED);
}
