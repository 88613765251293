import { TCardId } from '../../../../types/types';
import { checklistItemAddAction } from '../checklist/actions/checklistItemAddAction';
import { checklistsActionSetAction } from '../../actions/checklistsActionSetAction';
import { IChecklistItem } from '../checklist/types';
import { checklistActionSetAction } from '../checklists/actions/checklistActionSetAction';

export const checklistItemAddStore = (
    cardId: TCardId,
    item: IChecklistItem
) => {
    return checklistsActionSetAction(checklistActionSetAction(cardId, item.checklistId, checklistItemAddAction(item)));
};
