import { Dispatch } from '../../../../../types/actions';
import { SNACKBAR_BUTTON_CLOSE, SNACKBAR_BUTTON_UNDO, } from '../constants';
import { getDefaultActionEvent } from '../helpers/getDefaultActionEvent';
import { ISnackbarWithAlternativeActionUndo } from './types';
import { snackbarUndoDefault } from '../variantUndo/snackbarUndoDefault'

export const snackbarUndoWithAlternativeAction = (
    options: ISnackbarWithAlternativeActionUndo,
    positionLeft: boolean
) => {
    return (dispatch: Dispatch) => {
        options.controls = [
            {
                action: () => {
                    dispatch(getDefaultActionEvent(options.id, options.actionAlternative));
                },
                isPrimary: true,
                title: options.alternativeButton
            },
            {
                action: () => {
                    dispatch(getDefaultActionEvent(options.id, options.actionUndo));
                },
                title: SNACKBAR_BUTTON_UNDO,
                isPrimary: true
            },
            {
                action: () => {
                    dispatch(getDefaultActionEvent(options.id, options.actionApply));
                },
                title: SNACKBAR_BUTTON_CLOSE,
                icon: 'close'
            },
        ];
        dispatch(snackbarUndoDefault(options, positionLeft));
    }
};
