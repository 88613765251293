import * as React from 'react';
import { Button, Icon } from 'kui';
import { ITopBarStatisticItem } from './types';
import { CLASS_TOPBAR_STAT_ITEM, CLASS_TOPBAR_STAT_ITEM_CONTAINER } from './constants';
import './_TopBarStatisticItem.scss';
import { UserpicStorage } from 'app/view/react_components/base/components/UserpicStorage/UserpicStorage';

export const TopBarStatisticItem: React.FC<ITopBarStatisticItem> = ({
    active,
    value,
    icon,
    initials,
    photoUrl,
    userId,
    title,
    type = 'icon',
    onClick
}) => {
    const className = CLASS_TOPBAR_STAT_ITEM;
    const classIcon = className + '--' + icon;
    const classActive = active ? className + '--active' : '';
    const classEmpty = !value ? 'top-bar__stat-item--empty' : '';

    let classNameArrow = 'top-bar__stat-item-arrow';
    classNameArrow += ` ${classNameArrow}--${icon}`;
    const classNameArrowIcon = 'top-bar__stat-item-arrow-icon';

    let classNameValue = 'top-bar__stat-name' + (active ? ' top-bar__stat-name--active' : '');

    return (
        <div className={CLASS_TOPBAR_STAT_ITEM_CONTAINER + ' ' + classEmpty}>
            <Button
                className={`
                    ${className}
                    ${classIcon}
                    ${classActive}
                `}
                aria-selected={active}
                aria-label={`${title}: ${value}`}
                tooltip={{
                    direction: 'down',
                    isNoWrap: true,
                    value: title
                }}
                variant={'icon-text'}
                onClick={onClick}
            >
                {type === 'icon'
                    ? <Icon xlink={icon} size={24}/>
                    : <UserpicStorage
                        initials={initials}
                        src={photoUrl}
                        userId={userId}
                    />
                }
                <span className={classNameValue}>{value}</span>
            </Button>
        </div>
    );
};
