import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { extendTrialSubmittedSegmentEvent } from '../../../../../../../middlewares/segment/helpers/blockersSegmentEvents';
import { ESegmentBlockerType } from '../../../../../../../middlewares/segment/trackEntities/blockersEvents';
import { extendSecondTrial } from '../../../effects/extendSecondTrial';
import { Intercom } from '../../../../../../../helper/intercom';

export const onSubmitTrialExtend: ActionCreator<ThunkAction> = (
    feedback: string
) => {
    const action = (
        dispatch: Dispatch
    ) => {
        Intercom.sendProperties({extended_trial: feedback});
        dispatch(extendSecondTrial(feedback));
        dispatch(extendTrialSubmittedSegmentEvent(ESegmentBlockerType.AFTER_FIRST_TRIAL));
    };
    return action;
};
