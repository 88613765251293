import { Dispatch, ThunkAction } from '../../../../types/actions';
import { IGetState } from '../../../../types/types';
import { getTypeSwitcherActiveType } from '../../../../view/react_components/typeSwitcher/store/selectors/getTypeSwitcherActiveType';
import { IRestCard } from '../../../../types/rest/IRestCard';
import { cardsDelete } from '../../../../store/model/effects/cardsDelete';
import { ICard, INewCard } from '../../../../store/model/card/types';
import { getList } from '../../../../store/model/list/selectors/getList';
import { ICards } from '../../../../store/model/cards/types';
import { postRest } from './api/postRest';
import { getBoard } from '../../../../store/model/selectors/getBoardById';
import { getBoardNextGanttOrderNumber } from '../../../../store/model/selectors/getBoardNextGanttOrderNumber';
import { cardsActiveRequestsUpdate } from '../../../../store/requestsState/effects/cardsActiveRequestsUpdate';
import { getCardInitial } from '../../../../store/model/card/constants';
import { TPostRestOptions } from './api/types';
import { EViewTypes } from '../../../../const';
import { filterIgnoreCardIdAdd } from '../../../../view/react_components/aside_panel/filterPanelBoard/store/filterMatch';
import { cardsAdd } from '../../../../store/model/actionCreators/cardsAdd';
import { sendRealTimeStoreActionByBoardId } from '../../../../view/react_components/base/helpers/realTimeHelperTS';
import { listUpdateMinMaxOrderNumber } from '../../list/listUpdateMinMaxOrderNumber';

export const cardAdd = (
    card: INewCard,
    options: TPostRestOptions = {}
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const cardId = Date.now();
        const list = getList(state, card.listId);
        const board = getBoard(state, list.dashboardId);

        if (card.ganttVisibility === undefined) {
            const view = getTypeSwitcherActiveType(state, board.id);
            if (
                view === EViewTypes.GANTT_VIEW_TYPE || // на гантте создаём карты для гантта
                board.showOnGantt
            ) {
                card.ganttVisibility = true;
                card.ganttOrderNumber = getBoardNextGanttOrderNumber(state, board.id);
            }
        }

        const cardNew: ICard = {
            ...getCardInitial(),
            ...card,
            id: cardId,
            isNew: true,
            created: Date.now() / 1000
        };

        const cards: ICards = {
            [cardId]: cardNew
        };

        dispatch(filterIgnoreCardIdAdd(cardId));
        dispatch(cardsAdd(cards)); // optimistic set
        dispatch(cardsActiveRequestsUpdate(cards, 1)); // TODO mb use isLoading?

        return dispatch(postRest(card, options))
            .then((response: IRestCard) => {
                const cardsResponse: ICards = {
                    [response.id]: response
                };
                dispatch(filterIgnoreCardIdAdd(response.id));
                dispatch(cardsAdd(cardsResponse));
                dispatch(sendRealTimeStoreActionByBoardId(board.id, cardsAdd(cardsResponse)))
                dispatch(listUpdateMinMaxOrderNumber(response.listId, [response.orderNumber]));

                return response;
            })
            .finally(() => {
                dispatch(cardsDelete(cards));
            });
    };
    return action;
};
