import * as React from 'react';
import { Action, applyMiddleware, combineReducers, createStore, Reducer } from 'redux';
import thunk from 'redux-thunk';

import { TApplicationStore } from '../types/types';

import { getEnhancers, root } from './constants';
import { snackbarsStackReducer } from '../view/react_components/snackbarsStack';
import { segmentMiddleware } from '../middlewares/segment/';
import { routerReducer } from './router/reducers/routerReducer';
import { filtersReducer } from '../view/react_components/aside_panel/filterPanelBoard/store/filtersReducer';
import { Dispatch } from '../types/actions';
import { NavigationPanelReducer } from '../view/react_components/main/navigationPanel/store/navigationPanel/reducers/NavigationPanelReducer';
import { modelReducer } from './model/modelReducer';
import { clickManagerReducer } from '../view/react_components/clickManager';
import { topBarBoardReducer } from '../view/react_components/main/topBar/store/topBarBoard/reducers/topBarBoardReducer';
import { cookiesDialogReducer } from '../view/react_components/dialogs/cookies/store/reducers/cookieDialogReducer';
import { hintsReducer } from '../view/react_components/hints';
import { signInReducer } from '../view/react_components/dialogs/signIn';
import { abTestingReducer } from '../abtesting/store/reducer/abTestingReducer';
import { asidePanelReducer } from '../view/react_components/aside_panel/asidePanel/store/reducers/asidePanelReducer';
import { cardPanelReducer } from '../view/react_components/aside_panel/cardDetails/store/reducers/cardPanelReducer';
import { cardIdsMiddleware } from './model/middlewares/list/cardIdsMiddleware';
import { subCardIdsMiddleware } from './model/middlewares/card/subCardIdsMiddleware';
import { kanbanViewReducer } from '../view/react_components/main/kanbanView/store/reducers/kanbanViewReducer';
import { modelsCollaboratorsReducer } from './collaborators/modelsCollaborators/reducers/modelsCollaboratorsReducer';
import { requestsStateReducer } from './requestsState/reducers/requestsStateReducer';
import { listIdsMiddleware } from './model/middlewares/dashboard/listIdsMiddleware';
import { filterMiddleware } from './model/middlewares/filters/filterMiddleware';
import { timeTrackerReducer } from '../view/react_components/aside_panel/cardDetails/TabTimingSection/TimeTracker/store/reducers/timeTrackerReducer';
import { notificationBeforeDueDateEditsStateReducer } from '../view/react_components/aside_panel/cardDetails/TimingSection/notificationsBeforeDueDate/state/notificationBeforeDueDateEditsState/reducers/notificationBeforeDueDateEditsStateReducer';
import { autoBackupsBoardReducer } from '../view/react_components/aside_panel/boardDetails/backups/AutoBackups/store/autoBackupsBoard/reducers/autoBackupsBoardReducer';
import { myWorkReducer } from '../view/react_components/main/myWorkView/store/reducers/myWorkReducer';
import { accountDeleteReducer } from '../view/react_components/accountDelete/store/reducers/accountDeleteReducer';
import { cardCollaboratorsMiddleware } from './model/middlewares/card/cardCollaboratorsMiddleware';
import { realtimeMiddleware } from '../view/react_components/subscriptionAdmin/store/subscriptionAdmin/middlewares/realtimeMiddleware';
import { activitiesReducer } from './activities/reducers/activities';
import { myTasksFilterMiddleware } from './model/middlewares/filters/myTasksFilterMiddleware';
import { driveDocIdsMiddleware } from './model/middlewares/driveDoc/driveDocIdsMiddleware';
import { ganttViewReducer } from '../view/react_components/gantt/store/ganttView/reducers/ganttView';
import { typeSwitcherReducer } from '../view/react_components/typeSwitcher/store/reducers/TypeSwitcherReducer';

const staticReducers = {
    snackbarsStack: snackbarsStackReducer,
    router: routerReducer,
    model: modelReducer,
    navigationPanel: NavigationPanelReducer,
    typeSwitcher: typeSwitcherReducer,
    clickManager: clickManagerReducer,
    filters: filtersReducer,
    topBar: topBarBoardReducer,
    cookieDialog: cookiesDialogReducer,
    hints: hintsReducer,
    signIn: signInReducer,
    abTesting: abTestingReducer,
    cardPanel: cardPanelReducer,
    asidePanel: asidePanelReducer,
    requestsState: requestsStateReducer,
    kanbanView: kanbanViewReducer,
    modelsCollaborators: modelsCollaboratorsReducer,
    timeTracker: timeTrackerReducer,
    myWork: myWorkReducer,
    activities: activitiesReducer,
};

// https://redux.js.org/recipes/code-splitting#using-a-reducer-manager
const createReducerManager = (initialReducers: Record<string, Reducer>): any => {
    // Create an object which maps keys to reducers
    const reducers = { ...initialReducers }

    // Create the initial combinedReducer
    let combinedReducer = combineReducers(reducers);

    // An array which is used to delete state keys when reducers are removed
    let keysToRemove: string[] = []

    return {
        getReducerMap: () => reducers,

        // The root reducer function exposed by this object
        // This will be passed to the store
        reduce: (state: any, action: Action): any => {
            // If any reducers have been removed, clean up their state first
            if (keysToRemove.length > 0) {
                state = { ...state }
                for (let key of keysToRemove) {
                    delete state[key]
                }
                keysToRemove = []
            }

            // Delegate to the combined reducer
            return combinedReducer(state, action)
        },

        // Adds a new reducer with the specified key
        add: (key: string, reducer: Reducer) => {
            if (!key || reducers[key]) {
                return
            }

            // Add the reducer to the reducer mapping
            reducers[key] = reducer

            // Generate a new combined reducer
            combinedReducer = combineReducers(reducers)
        },

        // Removes a reducer with the specified key
        remove: (key: string) => {
            if (!key || !reducers[key]) {
                return
            }

            // Remove it from the reducer mapping
            delete reducers[key]

            // Add the key to the list of keys to clean up
            keysToRemove.push(key)

            // Generate a new combined reducer
            combinedReducer = combineReducers(reducers)
        }
    }
}

const reducerManager = createReducerManager(staticReducers);
const store: TApplicationStore = createStore(
    reducerManager.reduce,
    getEnhancers()(applyMiddleware(
        thunk,
        segmentMiddleware,
        cardIdsMiddleware,
        subCardIdsMiddleware,
        listIdsMiddleware,
        filterMiddleware,
        cardCollaboratorsMiddleware,
        myTasksFilterMiddleware,
        driveDocIdsMiddleware,
    ))
);
store.reducerManager = reducerManager;

root.store = store;
export default store;

export const getAppState = () => store.getState();
export const dispatch: Dispatch = store.dispatch;

const subscriptionAdminStoreEnhancers = getEnhancers({ instanceId: 'SubscriptionAdminStore' });

export const SubscriptionAdminContext = React.createContext(null);
export const SubscriptionAdminStore = createStore(
    ()=>({}), // Редюсер, который возвращает пустой стейт. Потом заменяется реальным через React.lazy
    subscriptionAdminStoreEnhancers(applyMiddleware(
        thunk,
        segmentMiddleware,
        realtimeMiddleware
    )
));
