import { IApplicationState } from '../../../types/types';
import { ICardWithBoard } from '../card/types';
import { getAuthUser } from '../authUser/selectors/getAuthUser';
import { getBoardIdByCardId } from './getBoardIdByCardId';
import { createSelector } from 'reselect';
import { getCard } from './getCard';

/**
 * state.model.inWorkCard синхронизируется один раз при маунте виджета, поэтому card актуальнее
 * но в card нет boardName
 * Хотя если карта в сторе, то и доска её по идее тоже. Возможно boardName лучше брать из доски.
 */

export const getInWorkCard = (
    state: IApplicationState
): ICardWithBoard => {
    const { inWorkCardId } = getAuthUser(state);
    if (inWorkCardId) {
        const card = getInWorkCardFromCardModel(state, inWorkCardId);
        if (card) {
            return {
                boardName: state.model.dashboards[card.boardId].name,
                ...card // в card нет boardName
            };
        }
    }
    return state.model.inWorkCard;
}

const getInWorkCardFromCardModel = createSelector(
    getCard,
    getBoardIdByCardId,
    (card, boardId) => {
        if (card && boardId) {
            return {
                id: card.id,
                name: card.name,
                cardNumber: card.cardNumber,
                boardId
            }
        }
        return null;
    }
)
