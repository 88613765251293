import { IApplicationState } from '../../../../types/types';
import { getAuthUserWatchedTutorials } from './getAuthUserWatchedTutorials';
import { ETutorial } from '../../../../types/rest/IUserMeta';

export const getAuthUserTutorialWatched = (
    state: IApplicationState,
    tutorial: ETutorial
): boolean => {
    const tutorials = getAuthUserWatchedTutorials(state);
    return tutorials.includes(tutorial);
};
