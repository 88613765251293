import * as React from 'react';
import { ISubcardsSearchSelectProps } from './types';
import { CARD_SUBCARD_CREATE } from './constants';
import { CardSubcardsAdd } from '../CardSubcardsAdd/CardSubcardsAdd';
import { SearchSelect } from '../../../../../base/components/SearchSelect/SearchSelect';

export function SubcardsSearchSelect({
    availableElements,
    isOpened,
    isPopup,
    onAdd,
    onAddClosed,
    onCreate,
}: ISubcardsSearchSelectProps ) {
    return (
        <SearchSelect
            create={CARD_SUBCARD_CREATE}
            isCloseOnClick={false}
            isCloseOnEnter={false}
            isFitWindow={isPopup}
            isDropdownUplifted={!isPopup}
            opened={isOpened}
            options={availableElements}
            option={CardSubcardsAdd}
            onBlur={onAddClosed}
            onClosed={ onAddClosed }
            onSelect={(cardId) => onAdd(Number(cardId))}
            onCreate={onCreate}
        />
    );
};
