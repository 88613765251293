import { formatCommentToSeconds } from './../../helpers/formatCommentToSeconds';
import { IApplicationState, TCardId } from '../../../../../../types/types';
import { IComment } from '../../../../../../store/model/card/types/IComment';
import { getCard } from '../../../../../../store/model/selectors/getCard';

export const getCardSpentTime = (
    state: IApplicationState,
    cardId: TCardId,
    workHoursInDay: number
): number => {
    const card = getCard(state, cardId);
    const spentTime = card.comments.reduce((timeSpent: number, comment: IComment) => {
        if (comment.timeTracker) {
            const commentSpentTime = formatCommentToSeconds(comment.text, workHoursInDay);
            timeSpent += commentSpentTime;
        }
        return timeSpent;
    }, 0);

    return spentTime;
};
