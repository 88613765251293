import { IRestProduct } from './IRestProduct';
import { IUserMeta } from './IUserMeta';
import { IRestSubscription } from './IRestSubscription';
import { IAuthorRest } from '../model';
import { IFolderInfo } from 'app/types/rest/IRestDashboard';

export type TUserId = string;

export type TUserSettings = {
    commentSort?: EUserSettingsCommentSort;
    commentPanelIsPinned?: boolean;
    videoVoice?: EUserSettingsVideoVoice;
}

export enum EUserSettingsCommentSort {
    ASC = 1,
    DESC = -1
}

export enum EUserSettingsVideoVoice {
    FEMALE = 'FEMALE',
    MALE = 'MALE'
}

export enum EAuthUserAccountTypeNames {
    INDIVIDUAL_ACCOUNT_TYPE = 'Individual',
    DOMAIN_ACCOUNT_TYPE = 'Domain',
    EDU_ACCOUNT_TYPE = 'Edu',
    EDU_2023 = 'Edu2023'
}

export enum EAuthUserAccountType {
    INDIVIDUAL_ACCOUNT_TYPE = 1,
    DOMAIN_ACCOUNT_TYPE = 2,
    EDU_ACCOUNT_TYPE = 3
}

export enum EAuthUserTutorialStatus {
    NOT_SHOWN = 'NotShown',
    SHOWN = 'Shown',
    WATCHED = 'Watched',
    CANCELED = 'Canceled'
}

export interface IChargebeeCoupon {
    id: string;
    planIds: string[];
    discountType: string;
    discountPercentage: number;
    discountAmount: number;
}

export interface IDomainInfo {
    industry: string;
    employeeCount: string;
}

export enum EUserTimeformat {
    FORMAT_12 = '12',
    FORMAT_24 = '24'
}

export enum EUserDateformat {
    FORMAT_EUROPEAN = 'EUROPEAN',
    FORMAT_AMERICAN = 'AMERICAN'
}

export enum EUserDeletionTaskTypes {
    TYPE_FIRST_MAIL_SEND = 1,
    TYPE_SECOND_MAIL_SEND = 2,
    TYPE_PREPARE_DELETE = 3,
    TYPE_DELETE = 4,
}

export interface IAuthToken{
    access_token?: string;
    expires_at?: number;
    scopes?: Array<string>;
    authuser?: string;
    ip?: string;
}

export enum EAuthUserPlatformType {
    GOOGLE = 'google',
    MICROSOFT = 'microsoft'
}

export interface IRestAuthUser {
    id?: TUserId;
    accountType?: EAuthUserAccountType;
    platformType?: EAuthUserPlatformType,
    admin?: boolean;
    appFolderInfo?: IFolderInfo;
    allowActivity?: boolean;
    allowArchive?: boolean;
    allowAttacheTeamDriveFile?: boolean;
    allowBackupXml?: boolean;
    allowAutoBackupXml?: boolean;
    allowBranding?: boolean;
    allowCardColorCount?: number;
    allowCardAgeing?: boolean;
    allowCardFromEmail?: boolean;
    allowCardCustomProperties?: boolean;
    allowCardTagCount?: number;
    allowChecklistCount?: number;
    allowChecklistItemCount?: number;
    allowCreateBoard?: boolean;
    allowColorTagCount?: number;
    allowBoardTemplate?: boolean;
    allowRequiredProperties?: boolean;
    allowChatSupport?: boolean;
    allowDashboardBackground?: boolean;
    allowGantt?: boolean;
    allowGoogleSheetExport?: boolean;
    allowMyWorkCustomFilters?: boolean;
    allowMyWorkTaskGrouping?: boolean;
    allowListView?: boolean;
    allowCardNumber?: boolean;
    allowPriorityAutoSorting?: boolean;
    allowPublicFilter?: boolean;
    allowReports?: boolean;
    allowRoles?: boolean;
    allowSavedFilters?: boolean;
    allowSharedDrive?: boolean;
    allowStatusMapping?: boolean;
    allowSwimlanes?: boolean;
    allowSubCard?: boolean;
    allowSubCardCount?: number;
    allowTimeTracker?: boolean;
    allowFreeVersion?: boolean;
    allowRecurringTask?: boolean;
    allowTeamWorkload?: boolean;
    allowedCookies?: string;
    allowCardsAssignedToMe?: boolean;
    allowNotificationBeforeDueDates?: boolean
    allowDependencies?: boolean;
    allowTimeTrackerExport?: boolean;
    anonym?: boolean;
    city?: string;
    acceptLanguge?: string;
    country?: string;
    createDate?: string;
    createDateTimeStamp?: number;
    domainWide?: boolean;
    domainWideProfessional?: boolean;
    domainWidePremium?: boolean;
    driveId?: string;
    driveDomain?: string;
    email?: string;
    firstName?: string;
    firstVisit?: boolean;
    fullName?: string;
    gsuiteAdmin?: boolean;
    hash?: string;
    initials?: string;
    ipChanged?: boolean;
    lastName?: string;
    logo?: string;
    needLogin?: boolean;
    tryRememberMe?: boolean;
    notificationEmail?: string;
    oAuthScopes?: string[];
    requiredScopes?: string[];
    online?: boolean;
    permissionId?: string;
    photoUrl?: string;
    refreshTokenExeption?: boolean;
    region?: string;
    timeFormat?: EUserTimeformat;
    timezone?: string;
    tenantName?: string;
    firstTrialCode?: string;
    secondTrialCode?: string;
    tutorialStatus?: EAuthUserTutorialStatus;
    unseenNotificationCount?: number;
    markAllReadLastDate?: number;
    settings?: TUserSettings,

    affiliateId?: string;
    availableChargeBeeCoupons?: IChargebeeCoupon[],
    domainWideEndDate?: number

    activeSubscription?: IRestSubscription[];
    ownSubscription?: IRestSubscription[];
    managedSubscription?: IRestSubscription[];
    lastManager?: IAuthorRest;
    products?: IRestProduct[];
    meta?: IUserMeta;
    inWorkCardId?: number;
    domainInfo?: IDomainInfo;
    cometToken?: string;
    cometTokenToSystemRoom?: string;
    hasAssignedCards?: boolean;
    segmentAlias?: boolean;
    isEmailChanged?: boolean;
    newCredentialReceived?: boolean;
    oldCredentialInvalid?: boolean;
    eduV2?: boolean;
    testMode?: boolean;
    userHaveDeletionTask?: boolean;
    isDeletedByCron?: boolean;
    token?: IAuthToken;
    tokenHash?: string;
    usersInDomainCount?: number;
    featurePoll?: IFeaturePoll;

    supportExport?: boolean;
    supportCalendarPush?: boolean;
    supportBackup?: boolean;
    supportBranding?: boolean;
    supportBoardArchive?: boolean;
    supportStarred?: boolean;

    supportSharedDrive?: boolean;
}

export interface IFeaturePoll {
    actualUsersCount: number;
    id: number;
    isEnabled: boolean;
    question: string;
    requiredUsersCount: number;
}
