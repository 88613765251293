import * as React from 'react';
import './_BoardRoles.scss';
import { CLASS_BOARD_DETAILS } from '../../../../constants';
import { COLOR_ROLES } from '../../constants';
import { BoardRoleHOC } from '../../hocs/BoardRoleHOC/BoardRoleHOC';
import { AsidePanelContext } from '../../../../../asidePanel/components/AsidePanel/constants';

export function BoardRoles () {
    const className = CLASS_BOARD_DETAILS + '__roles';
    const { boardId } = React.useContext(AsidePanelContext);
    return (
        <div className={className}>
            {COLOR_ROLES.map((color) =>
                <BoardRoleHOC
                    boardId={boardId}
                    color={color}
                    key={color}
                />)}
        </div>
    );
};
