import { getMessages } from '../../../../../../../store/constants';

export const BOARD_ATTACHMENTS_EMPTY_ALL_TITLE = getMessages().getText('board_settings.attachments.empty.all.title');
export const BOARD_ATTACHMENTS_EMPTY_DOCUMENTS_TITLE = getMessages().getText('board_settings.attachments.empty.documents.title');
export const BOARD_ATTACHMENTS_EMPTY_MEDIA_TITLE = getMessages().getText('board_settings.attachments.empty.media.title');
export const BOARD_ATTACHMENTS_EMPTY_TITLE_SEARCH = getMessages().getText('board_settings.attachments.empty.title.search');
export const BOARD_ATTACHMENTS_EMPTY_TEXT_FILES = getMessages().getText('board_settings.attachments.empty.text.files');
export const BOARD_ATTACHMENTS_EMPTY_TEXT_DOCUMENTS = getMessages().getText('board_settings.attachments.empty.text.documents');
export const BOARD_ATTACHMENTS_EMPTY_TEXT_SEARCH = getMessages().getText('board_settings.attachments.empty.text.search');

export const ATTACHMENT_GRID_HEIGHT = 196;
export const ATTACHMENT_HEIGHT = 48;
export const ATTACHMENT_TITLE_HEIGHT = 21;
