import * as React from 'react';
import './_BoardName.scss';
import { IBoardNameProps } from './types';
import { Input } from 'kui';
import { BOARD_NAME_DEFAULT, CLASS_BOARD_DETAILS } from 'app/view/react_components/aside_panel/boardDetails/constants';

export function BoardName ({
    asidePanelWidth,
    name,
    onChange,
    onEsc,
    isReadonly
}: IBoardNameProps) {
    const [boardName, setBoardName] = React.useState(name);
    const className = CLASS_BOARD_DETAILS + '__name';
    const classInput = CLASS_BOARD_DETAILS + '__name-input';
    const componentRef = React.useRef(null);
    React.useEffect(() => {
        if (boardName === BOARD_NAME_DEFAULT && !isReadonly) {
            setTimeout(() => {
                const input = componentRef.current.querySelector('textarea');
                if (input) {
                    input.focus();
                    input.select();
                }
            }, 300);
        }
    }, []);

    const handleInputBlur = (
        e: React.SyntheticEvent
    ) => {
        let { value } = e.target as HTMLInputElement;
        saveName(value);
    };

    const handleInputChange = (
        e: React.SyntheticEvent
    ) => {
        const { value } = e.target as HTMLInputElement;
        setBoardName(value);
    };

    const handleKeyDown = (
        e: React.KeyboardEvent
    ) => {
        if (e.key === 'Escape') {
            e.stopPropagation();
            setBoardName(name);
            componentRef.current.focus();
            onEsc();
        }

        if (e.key === 'Enter') {
            e.preventDefault();
            let { value } = e.target as HTMLInputElement;
            saveName(value);
        }
    };

    const saveName = (value: string) => {
        value = value.replace(/(^|\r\n|\n)([^*]|$)/g, '$2');
        if (!value){
            value = BOARD_NAME_DEFAULT;
        }
        setBoardName(value);
        if (value !== name){
            onChange(value);
        }
    };

    React.useEffect(() => {
        setBoardName(name);
    }, [name]);

    return (
        <div
            className={className}
            ref={componentRef}
            tabIndex={-1}
            key={asidePanelWidth} // rerender on width change
        >
            <Input
                className={classInput}
                value={boardName}
                onBlur={handleInputBlur}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
            />
        </div>
    );
}
