import { AT_NBD_NOTIFICATIONS_SET, INotificationSetAction } from './types';
import { INotificationBeforeDueDate } from '../../../../../../../../../store/model/notificationBeforeDueDate/types';

export const notificationSetAction = (
    notifications: INotificationBeforeDueDate[]
): INotificationSetAction => {
    return {
        type: AT_NBD_NOTIFICATIONS_SET,
        notifications
    }
};
