import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState, TBoardId } from '../../../../../../../../types/types';
import { createTemplate } from '../../../../../../../../rest/effects/card/template/createTemplate';
import { IRestCard } from '../../../../../../../../types/rest/IRestCard';
import { updateSelectedCards } from '../../../../../../clickManager';
import { cardOpen } from '../../../../../../base/effects/cardOpen';
import { createRecurring } from '../../../../../../../../rest/effects/card/recurringTask/createRecurring';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../../middlewares/segment';

export const onAddClick = (
    boardId: TBoardId,
    isRecurring: boolean
): ThunkAction => {
    return (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(segmentTrackAction(isRecurring
            ? SegmentCardEvent.ADD_NEW_RECURRING_BUTTON_CLICKED
            : SegmentCardEvent.ADD_NEW_TEMPLATE_BUTTON_CLICKED
        ));
        const promise = isRecurring
            ? dispatch(createRecurring(boardId))
            : dispatch(createTemplate(boardId));
        promise.then((cardTemplate: IRestCard) => {
            dispatch(updateSelectedCards(boardId, [cardTemplate.id]));
            dispatch(cardOpen(cardTemplate.id));
        });
    };
};
