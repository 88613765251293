import { TSubscriptionId } from '../../../../../../types/rest/IRestSubscription';
import { ISubscription } from '../types';
import { getSubscriptions } from '../../subscriptions/selectors/getSubscriptions';
import { ISubscriptionAdminState } from '../../subscriptionAdmin/types';

export const getSubscription = (
    state: ISubscriptionAdminState,
    subscriptionId: TSubscriptionId,
): ISubscription => {
    const subscriptions = getSubscriptions(state);
    return subscriptions[subscriptionId];
}
