import { Dispatch, ThunkAction } from '../../../../../../../../types/actions';
import { IGetState } from '../../../../../../../../types/types';
import { snackbarErrorDefault } from '../../../../../../snackbarsStack';
import { ESnackbarPosition } from '../../../../../../snackbarsStack/store/types';
import { getMessages } from '../../../../../../../../store/constants';
import { SNACKBAR_TIMER_INFO } from '../../../../../../snackbarsStack/hocs/SnackbarHOC/constants';

export const onShowSnackbar = (): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        dispatch(snackbarErrorDefault({
            id: 'SNACKBAR_ID_CARD_COLOR_ERROR',
            text: getMessages().getText('card_form_edit_color_error'),
            position: ESnackbarPosition.center,
            timer: SNACKBAR_TIMER_INFO
        }));
    };
    return action;
};
