import { IGetState, TCardId, TChecklistId, TChecklistItemId } from '../../../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { getCard } from '../../../../../../../store/model/selectors/getCard';
import { checklistItemDelete } from '../../../../../../../rest/effects/card/checklist/checklistItemDelete';
import { isCardChecklistUserExist } from '../../../../../../../store/model/selectors/isCardChecklistUserExist';
import { getChecklistItem } from '../../../../../../../store/model/checklists/checklist/selectors/getChecklistItem';
import { SegmentCardEvent, segmentTrackAction } from '../../../../../../../middlewares/segment';
import { getCardAssignees } from '../../../../../../../store/model/selectors/getCardAssignees';
import { cardSetAssignees } from '../../../../../../../rest/effects/card/card/cardSetAssignees';
import { IAssignee } from '../../../../../../../store/model/card/types/IAssignee';

export const deleteChecklistItem = (
    cardId: TCardId,
    checklistId: TChecklistId,
    itemId: TChecklistItemId
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const card = getCard(state, cardId);
        const item = getChecklistItem(state, cardId, checklistId, itemId);
        if (!card || !item) return Promise.reject();

        const assignUsers: IAssignee[] = item.assignees || [];
        let promiseList: Promise<any>[] = [];
        assignUsers.forEach(user => {
            if (!isCardChecklistUserExist(state, cardId, user.sharedUser.permissionId, [itemId])) {
                dispatch(segmentTrackAction(SegmentCardEvent.UNASSIGNED_USER));
                const assignees = [...getCardAssignees(state, cardId)];
                const dashboardPermissionId = user.sharedUser.permissionId;
                const index = assignees.findIndex((user) => user.sharedUser.permissionId === dashboardPermissionId);
                assignees.splice(index, 1);
                promiseList.push(dispatch(cardSetAssignees(cardId, assignees)));
            }
        })
        promiseList.push(dispatch(checklistItemDelete(cardId, checklistId, itemId)));
        return Promise.all(promiseList);
    };
    return action;
};
