import { connect } from 'react-redux';
import { IApplicationState } from '../../../../../../../../types/types';
import {
    INotificationListItemDispatchProps,
    INotificationListItemStateProps,
} from '../../components/NotificationListItem/types';
import { INotificationListItemHOCOwnProps } from './types';
import { NotificationListItem } from '../../components/NotificationListItem/NotificationListItem'
import { onOpenEditingPopup } from './events/onOpenEditingPopup';
import { onRemoveNotificationById } from './events/onRemoveNotificationById';
import { getCardNotificationBeforeDueDate } from '../../../../../../../../store/model/selectors/getCardNotificationBeforeDueDate';

const mapStateToProps = (
    state: IApplicationState,
    { id, isPopupOpen, cardId}: INotificationListItemHOCOwnProps,
): INotificationListItemStateProps => {
    const { interval, unit } = getCardNotificationBeforeDueDate(state, cardId, id);
    return {
        interval,
        unit,
        isPopupOpen
    };
};

const mapDispatchToProps = (
    dispatch: any,
    { cardId, id }: INotificationListItemHOCOwnProps,
): INotificationListItemDispatchProps => {
    return {
        onOpenEditingPopup: () => dispatch(onOpenEditingPopup(cardId, id)),
        onRemoveNotificationById: () => dispatch(onRemoveNotificationById(cardId, id)),
        onIntervalChange: (interval) => {},
        onUnitChange: (unit) => {},
    }
};

export const NotificationListItemHOC = connect(
    mapStateToProps,
    mapDispatchToProps,
)(NotificationListItem);

NotificationListItemHOC.displayName = 'NotificationListItemHOC';
