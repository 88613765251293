import { TStatus } from '../../../../../types/model';
import { ICard } from '../../../card/types';

export const statusFilter = (
    status: TStatus
) => (
    card: ICard
): boolean => {
    return card.status === status;
}
