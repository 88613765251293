import { Dispatch, ThunkAction } from '../../../types/actions';
import { IGetState, TBoardId } from '../../../types/types';
import { IRestUserBoardMeta } from '../../../types/rest/IRestUserBoardMeta';
import { boardUserMetaPatchRest } from './api/helpers/boardUserMetaPatchRest';

export const boardSetMeta = (
    boardId: TBoardId,
    userMeta: IRestUserBoardMeta
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(boardUserMetaPatchRest(boardId, userMeta));
    };
    return action;
};
