import * as deepFreeze from 'deep-freeze';
import { ILibThemes } from '../types';
import { AT_LIB_THEMES_LIST_ACTION_SET, TLibThemesAction } from '../actions/types';

const initialState: ILibThemes = {
    list: []
};

const _libThemesReducer = (
    state: ILibThemes = initialState,
    action: TLibThemesAction
): ILibThemes  => {
    switch (action.type) {
        case AT_LIB_THEMES_LIST_ACTION_SET:{
            const { list } = action;
            return {
                ...state,
                list
            }
        }
        default: {
            return state;
        }
    }
};

export const libThemesReducer = (
    state: ILibThemes = initialState,
    action: TLibThemesAction
): ILibThemes  => {
    // @ts-ignore
    if(window.Settings.development) {
        // @ts-ignore
        return deepFreeze(_libThemesReducer(state, action));
    } else {
        return _libThemesReducer(state, action);
    }
};
