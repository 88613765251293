const category = 'Error';

export const SegmentErrorEvent = {
    CARD_NAME_SELECTION: { category, event: 'Card name selection error' },
    LAZY_IMPORT_ERROR: {category, event: 'Unfortunately something went wrong snackbar shown'},
};

export enum SegmentErrorOptions {
    DATA = 'data',
    SOURCE = 'source'
}
