import { IGetState, TBoardId } from '../../../types/types';
import { Dispatch, ThunkAction } from '../../../types/actions';
import { boardUpdateMeta } from './boardUpdateMeta';
import { IBoardCardCustomProperties } from '../../../store/customProperties/types';

export const boardSetCardCustomProperties = (
    boardId: TBoardId,
    cardCustomProperties: IBoardCardCustomProperties,
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        return dispatch(boardUpdateMeta(boardId, { cardCustomProperties }));
    };
    return action;
}
