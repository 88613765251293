import { Dispatch, ThunkAction } from '../../../../../../../types/actions';
import { IGetState } from '../../../../../../../types/types';
import { IQuestion } from '../../../../../base/components/Poll/components/Poll/types';
import { authUserPostCsat } from '../../../../../../../rest/effects/csat/authUserPostCsat';
import { POLL_DELAY_AFTER } from '../../../../../base/components/Poll/components/constants';
import { authUserSetRateFeaturePoll } from '../../../../../../../rest/effects/authUser/authUserSetRateFeaturePoll';
import {
    getUserRateFeaturePoll,
} from '../../../../../../../store/model/authUser/selectors/getUserRateFeaturePoll';
import {
    getAuthUserFeaturePoll
} from '../../../../../../../store/model/authUser/selectors/getAuthUserFeaturePoll';
import { segmentTrackAction, SegmentUserEvent } from '../../../../../../../middlewares/segment';
import { SegmentUserOption } from '../../../../../../../middlewares/segment/trackEntities/userEvents';

export const onSubmit = (
    results: IQuestion[],
    feature: string
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const { answeredFeaturePolls = [] } = getUserRateFeaturePoll(state);
        const currentFeaturePoll = getAuthUserFeaturePoll(state);
        if (!currentFeaturePoll) { // во время ответа опрос может закончится и не будет айдишника текущего опроса
            return;
        }
        dispatch(segmentTrackAction(SegmentUserEvent.FEATURE_POLL_RATED, {
            name: SegmentUserOption.FEATURE,
            value: feature
        }, [
            {
                name: SegmentUserOption.RATING,
                value: results[0].answer as number + 1
            },
            {
                name: SegmentUserOption.TEXT,
                value: results[1].answer as string
            }
        ]));
        setTimeout(() => {
            dispatch(authUserSetRateFeaturePoll({
                triggeredFeatureId: null,
                answeredFeaturePolls: [...answeredFeaturePolls, currentFeaturePoll.id],
                skipDate: Date.now() // поставить скип, чтобы не доставать юзера частыми опросами
            }));
            dispatch(authUserPostCsat( {
                pollId: currentFeaturePoll.id,
                date: Date.now(),
                rate: results[0].answer as number,
                answer: results[1].answer as string,
            }))
        }, POLL_DELAY_AFTER) // только что ответил, показать Thank you
    };
    return action;
};
