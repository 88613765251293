import { IApplicationState, TCardId } from '../../../../../types/types';
import { IChecklistItem } from '../../checklist/types';
import { getCardChecklists } from './getCardChecklists';

export const getCardChecklistItems = (
    state: IApplicationState,
    cardId: TCardId
): IChecklistItem[] => {
    const result: IChecklistItem[] = [];
    const checklists = getCardChecklists(state, cardId);

    for (let id in checklists) {
        const checkItems = checklists[id].checkItems;
        for (let item in checkItems) {
            result.push(checkItems[item]);
        }
    }

    return result;
}
