import { ActionCreator } from 'redux';
import { Dispatch, ThunkAction } from '../../../../types/actions';
import { TBoardId } from '../../../../types/types';
import { IGetState } from '../../../../types/types';
import { clickManagerActionSet } from '../store/clickManager/actions/clickManagerActionSet';
import { getSelectedCards } from '../selectors/getSelectedCards';
import { clickManagerModelInitialStateSet } from '../store/clickManagerModel/actions/clickManagerModelInitialStateSet';

export const deselectCards: ActionCreator<ThunkAction> = (
    boardId: TBoardId
) => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const selectedCards = getSelectedCards(state, boardId);
        if (selectedCards && selectedCards.length) {
            dispatch(clickManagerActionSet(boardId, clickManagerModelInitialStateSet()));
        }
    };
    return action;
};
