import { IGetState, TListId } from '../../../../../types/types';
import { Dispatch, ThunkAction } from '../../../../../types/actions';
import { patchRest } from '../patchRest';
import { IList } from '../../../../../store/model/list/types';
import { listsActionSet } from '../../../../../store/model';
import { listActionSet } from '../../../../../store/model/lists/actions/listActionSet';
import { listUpdate as listUpdateAction } from '../../../../../store/model/list/actions/listUpdate';
import { getListForRollback } from '../../../../../store/model/selectors/getListForRollback';
import { sendRealTimeStoreAction } from '../../../../../view/react_components/base/helpers/realTimeHelperTS';
import { getBoardByListId } from '../../../../../store/model/selectors/getBoardByListId';

export const listPatchRest = (
    listId: TListId,
    list: IList,
    isRealtime: boolean = true, // send event to socket
    ): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const listForRollback = getListForRollback(getState(), listId, list);
        const action = listsActionSet(listActionSet(listId, listUpdateAction(list)));
        dispatch(action);
        return dispatch(patchRest(listId, list))
            .then(() => {
                if (isRealtime) {
                    const board = getBoardByListId(getState(), listId);
                    sendRealTimeStoreAction(board.id, board.cometToken, action);
                }
                return list;
            })
            .catch((e: any) => {
                console.error(e);
                dispatch(listsActionSet(listActionSet(listId, listUpdateAction(listForRollback))));
            });
    };
    return action;
};
