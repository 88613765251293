import {getActiveBoardLngs} from "../../store/model/selectors/getActiveBoardLngs";
import {getAppState} from "../../store/configureStore";
import template from "./template/notification-system--checklist-overdue.html";


App.Views.CheckListItemOverdueNotification = App.Views.SystemNotification.extend({

    initialize: function (options) {
        App.Views.SystemNotification.prototype.initialize.call(this, options);
        var lngs = getActiveBoardLngs(getAppState());
        this.template = App.MLTemplate.getTemplate('#notification-system--checklist_overdue', lngs, template);
    },

    getActions: function() {
        var actions = {
            path: true,
            action: Messages.getText('noty.checklist_item_overDue'),
            actionData: {
                icon: 'deadline',
                checkListItemName: this.notificationModel.checkListItemName,
                due: App.Util.formatShortDate(this.notificationModel.newValue),
            },
            icon: 'datetime',
            system: 'overdue'
        };

        actions.actionData.title = actions.action + ' (' + actions.actionData.due + ')';
        return actions;
    },

    onClick: function(e, notification) {
        App.Views.SystemNotification.prototype.onClick.call(this, e, notification);
        var dashboardId = this.notificationModel.dashboardId;
        var cardId = this.notificationModel.card.id;
        App.controller.cleverOpenCard(dashboardId, cardId);
    }
});