import { IApplicationState, IGetState, TBoardId } from '../../../../../../../../../types/types';
import { IBackupListener } from '../../../components/BoardBackups/types';
import { Dispatch, ThunkAction } from '../../../../../../../../../types/actions';
import { IBoardBackupResult } from '../types';
import { backupCreate } from '../../../../../../../../../rest/effects/backup/backupCreate';
import { IBackup } from '../../../../../../../../../store/model/boardBackups/types';
import { EBackupExportType, EBackupType } from '../../../../../../../../../types/rest/IRestBackup';
import { root } from '../../../../../../../../../store/constants';
import { getBoard } from '../../../../../../../../../store/model/selectors/getBoardById';
import {Util} from 'app/util/util';
import { EAuthUserPlatformType } from 'app/types/rest/IRestAuthUser';

export const boardBackupCreate = (
    boardId: TBoardId,
    listener: IBackupListener
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const manager = new App.Classes.DashboardXmlExportManager({boardId});
        manager.on('onSuccess', (result: IBoardBackupResult) => {
            listener.onSuccess();
            saveBackup(dispatch, state, boardId, result);
        });
        manager.on('onError', (error: string) => {
            console.log('error', error);
            listener.onError(error);
        });
        manager.on('onProgress', (result: IBoardBackupResult) => {
            listener.onProgress(result.percentProgress)
        });
        manager.on('onCancel', () => {
            listener.onCancel();
        });
        const board = getBoard(state, boardId);
        let format ='yyyy-MM-DD HH:mm:ss';
        if (board.platformType === EAuthUserPlatformType.MICROSOFT){
            format ='yyyy-MM-DD HH-mm-ss';
        }
        const exportFileName = board.name + ' ' + Util.formatDateMoment(new Date(), format);
        manager.startExport({
            exportFileName
        });
    };
    return action;
};

const saveBackup = (
    dispatch: Dispatch,
    state: IApplicationState,
    boardId: TBoardId,
    result: IBoardBackupResult) => {
    const board = getBoard(state, boardId);
    const time = new Date().getTime();
    const backup: IBackup = {
        title: board.name + ' (' + root.App.Util.formatLongDateWithTime(new Date()) + ')',
        fileId: result.exportFileId,
        driveId: result.exportDriveId,
        platformType: result.platformType,
        type: EBackupType.MANUALLY,
        duration: time - result.workContext.startTime,
        created: time,
        exportType: EBackupExportType.XML
    }
    dispatch(backupCreate(boardId, backup));
}
