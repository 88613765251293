import { IApplicationState, TBoardId } from '../../../../types/types';
import { getBoardCardAgingSettings } from './getBoardCardAgingSettings';
import { ECardAgingIndicatorType } from '../../../../types/rest/ICardAging';

export const getBoardCardAgingIndicatorType = (
    state: IApplicationState,
    boardId: TBoardId
): ECardAgingIndicatorType => {
    const cardAgingSettings = getBoardCardAgingSettings(state, boardId);

    return cardAgingSettings && cardAgingSettings.indicator && cardAgingSettings.indicator.indicatorType || ECardAgingIndicatorType.BY_ACTIVITY;
}
