import {Dispatch, ThunkAction} from '../../../../../../../../types/actions';
import {IGetState, TBoardId} from '../../../../../../../../types/types';
import {IExportForm} from '../../../components/ExportDate/types';
import {EBackupExportType} from '../../../../../../../../types/rest/IRestBackup';
import {backupScheduleUpdate} from '../../../../../../../../rest/effects/backupSchedule/backupScheduleUpdate';
import {mapFormRecurringToRestBackup} from '../../../helpers/mapFormRecurringToRestBackup';
import {getAutoBackupsByBoardIdAndExportType} from '../../../../backups/AutoBackups/store/autoBackup/selectors/getAutoBackupsByBoardIdAndExportType';

export const onUpdate = (
    boardId: TBoardId,
    form: IExportForm
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        const state = getState();
        const autoBackup = getAutoBackupsByBoardIdAndExportType(state, boardId, EBackupExportType.GOOGLE_SHEET)
        console.log(form)
        dispatch(backupScheduleUpdate(boardId, autoBackup.id, mapFormRecurringToRestBackup(form)));
    };
    return action;
};
