import * as React from 'react';
import { EPanelRoute, ERoutes } from '../../../../../store/router/constants';
import { getMessages } from '../../../../../store/constants';
import { IAsidePanelTab } from '../../../aside_panel/asidePanel/types';
import {
    onTabSettingsActive
} from '../../../aside_panel/asidePanel/hocs/AsidePanelMyWorkSettingsHOC/events/onTabSettingsActive';
import {
    onTabImportActive
} from '../../../aside_panel/asidePanel/hocs/AsidePanelMyWorkSettingsHOC/events/onTabImportActive';
import {
    onTabBackgroundActive
} from '../../../aside_panel/asidePanel/hocs/AsidePanelMyWorkSettingsHOC/events/onTabBackgroundActive';
import { EMyWorkTab } from './types';
import { MyWorkBackgroundTab } from './MyWorkBackgroundTab/MyWorkBackgroundTab';
import { MyWorkSettingsTab } from './MyWorkSettingsTab/components/MyWorkSettingsTab/MyWorkSettingsTab';
import { MyWorkImportTab } from './MyWorkImportTab/components/MyWorkImportTab/MyWorkImportTab';

export const CLASS_ASIDE_MY_WORK_SETTINGS = 'aside-panel--my-work-settings';

export const MY_WORK_SETTINGS_TABS: ReadonlyArray<Readonly<IAsidePanelTab>> = Object.freeze([
    Object.freeze({
        body: <MyWorkImportTab />,
        key: EMyWorkTab.IMPORT,
        name: getMessages().getText('aside_panel.tab.atm.import'),
        route: ERoutes.ASSIGNED_TO_ME_IMPORT,
        url: EPanelRoute.IMPORT_CARDS,
        onActive: onTabImportActive
    }),
    Object.freeze({
        body: <MyWorkSettingsTab />,
        key: EMyWorkTab.SETTINGS,
        name: getMessages().getText('aside_panel.tab.atm.settings'),
        route: ERoutes.ASSIGNED_TO_ME_SETTINGS,
        url: EPanelRoute.VIEW_SETTINGS,
        onActive: onTabSettingsActive
    }),
    Object.freeze({
        body: <MyWorkBackgroundTab />,
        key: EMyWorkTab.BACKGROUND,
        name: getMessages().getText('aside_panel.tab.atm.background'),
        route: ERoutes.ASSIGNED_TO_ME_BACKGROUND,
        url: EPanelRoute.BACKGROUND,
        onActive: onTabBackgroundActive
    }),
]);
