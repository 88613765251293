import {
    ERestActivityActionType,
    ERestActivityModelType,
    ERestActivityPropertyActionType,
    ERestActivityPropertyName,
    ERestActivityType,
    IRestActivityBase
} from '../../../../../../../types/rest/activity/IRestActivityBase';
import { IActivityProps } from '../types';
import { ListActivityCreate } from '../components/ListActivityCreate/ListActivityCreate';
import { ListActivityName } from '../components/ListActivityName/ListActivityName';
import { ListActivityMaxCardNumber } from '../components/ListActivityMaxCardNumber/ListActivityMaxCardNumber';
import { ListActivityDescription } from '../components/ListActivityDescription/ListActivityDescription';
import { ListActivityArchive } from '../components/ListActivityArchive/ListActivityArchive';
import { ListActivityRestore } from '../components/ListActivityRestore/ListActivityRestore';
import { ListActivityCopy } from '../components/ListActivityCopy/ListActivityCopy';
import { ListActivityDelete } from '../components/ListActivityDelete/ListActivityDelete';
import { BoardActivityCreate } from '../components/BoardActivityCreate/BoardActivityCreate';
import { BoardActivityCopy } from '../components/BoardActivityCopy/BoardActivityCopy';
import { BoardActivityArchive } from '../components/BoardActivityArchive/BoardActivityArchive';
import { BoardActivityRestore } from '../components/BoardActivityRestore/BoardActivityRestore';
import { BoardActivityName } from '../components/BoardActivityName/BoardActivityName';
import { BoardActivityDescription } from '../components/BoardActivityDescription/BoardActivityDescription';
import { TRestActivity } from '../../../../../../../types/rest/activity/IRestActivities';
import { CardActivityTags } from '../../../../cardDetails/TabActivity/components/CardActivityTags/CardActivityTags';
import { CardActivityDate } from '../../../../cardDetails/TabActivity/components/CardActivityDate/CardActivityDate';
import { CardActivityList } from '../../../../cardDetails/TabActivity/components/CardActivityList/CardActivityList';
import {
    CardActivityAssignedTo
} from '../../../../cardDetails/TabActivity/components/CardActivityAssignedTo/CardActivityAssignedTo';
import {
    CardActivityComment
} from '../../../../cardDetails/TabActivity/components/CardActivityComment/CardActivityComment';
import { CardActivityColor } from '../../../../cardDetails/TabActivity/components/CardActivityColor/CardActivityColor';
import {
    CardActivityEstimateHOC
} from '../../../../cardDetails/TabActivity/hocs/CardActivityEstimateHOC/CardActivityEstimateHOC';
import { CardActivityName } from '../../../../cardDetails/TabActivity/components/CardActivityName/CardActivityName';
import {
    CardActivityDescription
} from '../../../../cardDetails/TabActivity/components/CardActivityDescription/CardActivityDescription';
import {
    CardActivityProcessingStatus
} from '../../../../cardDetails/TabActivity/components/CardActivityProcessingStatus/CardActivityProcessingStatus';
import {
    CardActivityPriority
} from '../../../../cardDetails/TabActivity/components/CardActivityPriority/CardActivityPriority';
import {
    CardActivityDriveDoc
} from '../../../../cardDetails/TabActivity/components/CardActivityDriveDoc/CardActivityDriveDoc';
import {
    CardActivityCheckList
} from '../../../../cardDetails/TabActivity/components/CardActivityCheckList/CardActivityCheckList';
import {
    CardActivityCreate
} from '../../../../cardDetails/TabActivity/components/CardActivityCreate/CardActivityCreate';
import { CardActivityCopy } from '../../../../cardDetails/TabActivity/components/CardActivityCopy/CardActivityCopy';
import {
    CardActivityArchive
} from '../../../../cardDetails/TabActivity/components/CardActivityArchive/CardActivityArchive';
import {
    CardActivityRestore
} from '../../../../cardDetails/TabActivity/components/CardActivityRestore/CardActivityRestore';
import {
    CardActivityDueDatePassed
} from '../../../../cardDetails/TabActivity/components/CardActivityDueDatePassed/CardActivityDueDatePassed';
import {
    CardActivityTemplate
} from '../../../../cardDetails/TabActivity/components/CardActivityTemplate/CardActivityTemplate';
import {
    CardActivityRecurring
} from '../../../../cardDetails/TabActivity/components/CardActivityRecurring/CardActivityRecurring';
import {
    CardActivityDelete
} from '../../../../cardDetails/TabActivity/components/CardActivityDelete/CardActivityDelete';
import { IRestBoardCommonActivity } from '../../../../../../../types/rest/activity/IRestBoardCommonActivity';
import { TStatus } from '../../../../../../../types/model';
import {
    CardActivityCustomPropertyCommon
} from '../../../../cardDetails/TabActivity/components/CardActivityCustomPropertyCommon/CardActivityCustomPropertyCommon';
import { getMessages } from '../../../../../../../store/constants';
import { IRestCardTagActivity } from '../../../../../../../types/rest/activity/IRestCardTagActivity';
import { IRestCardCommonActivity } from '../../../../../../../types/rest/activity/IRestCardCommonActivity';
import { IRestCardAssignUserActivity } from '../../../../../../../types/rest/activity/IRestCardAssignUserActivity';
import { IRestCardColorActivity } from '../../../../../../../types/rest/activity/IRestCardColorActivity';
import { IRestCardDriveDocActivity } from '../../../../../../../types/rest/activity/IRestCardDriveDocActivity';
import { ERestDriveDocType } from '../../../../../../../types/rest/IRestDriveDoc';
import { IRestCardCheckListActivity } from '../../../../../../../types/rest/activity/IRestCardCheckListActivity';
import {
    IRestCardCustomPropertyActivity
} from '../../../../../../../types/rest/activity/IRestCardCustomPropertyActivity';
import { IRestListCommonActivity } from '../../../../../../../types/rest/activity/IRestListCommonActivity';
import {
    CardActivityCheckListDueDatePassed
} from '../../../../cardDetails/TabActivity/components/CardActivityCheckListDueDatePassed/CardActivityCheckListDueDatePassed';
import {
    CardActivityCheckListGroup
} from '../../../../cardDetails/TabActivity/components/CardActivityCheckListGroup/CardActivityCheckListGroup';
import {
    IRestCardCheckListGroupActivity
} from '../../../../../../../types/rest/activity/IRestCardCheckListGroupActivity';
import { IRestCardRelatedActivity } from 'app/types/rest/activity/IRestCardRelatedActivity';
import {
    CardActivityRelated
} from 'app/view/react_components/aside_panel/cardDetails/TabActivity/components/CardActivityRelated/CardActivityRelated';
import {
    CardActivityCommentReaction
} from '../../../../cardDetails/TabActivity/components/CardActivityCommentReaction/CardActivityCommentReaction';

export interface IActivityItem {
    Component: React.FC<IActivityProps>;
    label?: string | any;
}

const MESSAGE_CARD_CREATED = getMessages().getText('activity_item__card_created');
const MESSAGE_CARD_COPIED = getMessages().getText('activity_item__card_copied');
const MESSAGE_CARD_ARCHIVED = getMessages().getText('activity_item__card_archived');

const MESSAGE_CARD_UNARCHIVED = getMessages().getText('activity_item__card_unarchived');
const MESSAGE_CARD_RESTORED = getMessages().getText('activity_item__card_restore');
const getLabelCardRestore = (
    activity: IRestCardCommonActivity
): string => {
    return activity.oldValue === '1' ? MESSAGE_CARD_RESTORED : MESSAGE_CARD_UNARCHIVED;
};

const MESSAGE_DUE_DATE_PASSED = getMessages().getText('activity_item_card__due_date_passed');
const MESSAGE_CHECKLIST_DUE_DATE_PASSED = getMessages().getText('activity_item_card__checklist_due_date_passed');
const MESSAGE_CREATED_FROM_TEMPLATE = getMessages().getText('activity_item__created_from_template');
const MESSAGE_CREATED_FROM_RECURRING = getMessages().getText('activity_item__created_from_recurring');
const MESSAGE_CARD_DETELED = getMessages().getText('activity_item__card_deleted');

const MESSAGE_KEYS_CARD_TAG_ADDED = [
    'activity_item_card__tag_added',
    'activity_item_card__tags_added'
];
const MESSAGE_KEYS_CARD_TAG_REMOVED = [
    'activity_item_card__tag_removed',
    'activity_item_card__tags_removed'
];
const getLabelCardTags = (
    activity: IRestCardTagActivity
): string => {
    const messageKeys = activity.propertyActionType === ERestActivityPropertyActionType.ADD ?
        MESSAGE_KEYS_CARD_TAG_ADDED :
        MESSAGE_KEYS_CARD_TAG_REMOVED;
    return getMessages().getPluralByKeys(activity.tags.length, messageKeys, null, activity.tags.length);
};

const MESSAGE_CARD_START_DATE = {
    [ERestActivityPropertyActionType.ADD]: getMessages().getText('activity_item_card__start_date_added'),
    [ERestActivityPropertyActionType.UPDATE]: getMessages().getText('activity_item_card__start_date_edited'),
    [ERestActivityPropertyActionType.DELETE]: getMessages().getText('activity_item_card__start_date_removed')
};
const MESSAGE_CARD_DUE_DATE = {
    [ERestActivityPropertyActionType.ADD]: getMessages().getText('activity_item_card__due_date_added'),
    [ERestActivityPropertyActionType.UPDATE]: getMessages().getText('activity_item_card__due_date_edited'),
    [ERestActivityPropertyActionType.DELETE]: getMessages().getText('activity_item_card__due_date_removed')
};
const getLabelCardDate = (
    activity: IRestCardCommonActivity
): string => {
    return activity.propertyName === ERestActivityPropertyName.START_DATE
        ? MESSAGE_CARD_START_DATE[activity.propertyActionType]
        : MESSAGE_CARD_DUE_DATE[activity.propertyActionType];
};

const MESSAGE_CARD_LIST_UPDATED = getMessages().getText('activity_item_card_list__location');

const MESSAGE_CARD_ASSIGNER_ADDED = getMessages().getText('activity_item_card__assigner_added');
const MESSAGE_CARD_ASSIGNER_USER_ADDED = getMessages().getText('activity_item_card__assigner_user_added');
const MESSAGE_CARD_ASSIGNERS_USER_OTHER_ADDED = getMessages().getText('activity_item_card__assigner_user_others_added');
const MESSAGE_CARD_ASSIGNER_REMOVED = getMessages().getText('activity_item_card__assigner_removed');
const MESSAGE_CARD_ASSIGNER_USER_REMOVED = getMessages().getText('activity_item_card__assigner_user_removed');
const MESSAGE_CARD_ASSIGNERS_USER_OTHER_REMOVED = getMessages().getText('activity_item_card__assigner_user_others_removed');

const getLabelCardAssinged = (
    activity: IRestCardAssignUserActivity,
    isUser: boolean
): string => {
    const isAdded = activity.propertyActionType === ERestActivityPropertyActionType.ADD;
    let label = isAdded ? MESSAGE_CARD_ASSIGNER_ADDED : MESSAGE_CARD_ASSIGNER_REMOVED;
    if (isUser) {
        if (activity.users.length > 1) {
            label = isAdded ? MESSAGE_CARD_ASSIGNERS_USER_OTHER_ADDED : MESSAGE_CARD_ASSIGNERS_USER_OTHER_REMOVED;
        } else {
            label = isAdded ? MESSAGE_CARD_ASSIGNER_USER_ADDED : MESSAGE_CARD_ASSIGNER_USER_REMOVED;
        }
    }
    return label;
};

const MESSAGE_CARD_COMMENT = {
    [ERestActivityPropertyActionType.ADD]: getMessages().getText('activity_item_card__comment_added'),
    [ERestActivityPropertyActionType.UPDATE]: getMessages().getText('activity_item_card__comment_edited'),
    [ERestActivityPropertyActionType.DELETE]: getMessages().getText('activity_item_card__comment_removed')
}
const getLabelCardComment = (
    activity: IRestCardCommonActivity
): string => {
    return MESSAGE_CARD_COMMENT[activity.propertyActionType];
};

const getLabelCardCommentReaction = (
    activity: IRestCardCommonActivity
): string => {
    return activity.propertyActionType;
};

const MESSAGE_KEYS_CARD_COLOUR_TAG_ADDED = [
    'activity_item_card__color_added',
    'activity_item_card__colors_added'
];
const MESSAGE_KEYS_CARD_COLOUR_TAG_EDITED = [
    'activity_item_card__color_edited',
    'activity_item_card__colors_edited'
];
const MESSAGE_KEYS_CARD_COLOUR_TAG_REMOVED = [
    'activity_item_card__color_removed',
    'activity_item_card__colors_removed'
];
const getLabelCardColor = (
    activity: IRestCardColorActivity
): string => {
    let messageKeys = MESSAGE_KEYS_CARD_COLOUR_TAG_EDITED;
    const { newColors, oldColors } = activity;
    if (newColors.length && !oldColors.length) {
        messageKeys = MESSAGE_KEYS_CARD_COLOUR_TAG_ADDED;
    } else if (!newColors.length && oldColors.length) {
        messageKeys = MESSAGE_KEYS_CARD_COLOUR_TAG_REMOVED;
    }
    const newColorsCount = newColors.length;
    const oldColorsCount = oldColors.length;
    const count = newColorsCount > oldColorsCount ? newColorsCount - oldColorsCount : oldColorsCount - newColorsCount;
    return getMessages().getPluralByKeys(count, messageKeys, null, count);
};

const MESSAGE_CARD_ESTIMATE = {
    [ERestActivityPropertyActionType.ADD]: getMessages().getText('activity_item_card__estimate_added'),
    [ERestActivityPropertyActionType.UPDATE]: getMessages().getText('activity_item_card__estimate_edited'),
    [ERestActivityPropertyActionType.DELETE]: getMessages().getText('activity_item_card__estimate_removed')
};
const getLabelCardEstimate = (
    activity: IRestCardCommonActivity
): string => {
    return MESSAGE_CARD_ESTIMATE[activity.propertyActionType];
};

const MESSAGE_CARD_RENAMED = getMessages().getText('activity_item__card_name');

const MESSAGE_CARD_DESCRIPTION = {
    [ERestActivityPropertyActionType.ADD]: getMessages().getText('activity_item__card_description_added'),
    [ERestActivityPropertyActionType.UPDATE]: getMessages().getText('activity_item__card_description_edited'),
    [ERestActivityPropertyActionType.DELETE]: getMessages().getText('activity_item__card_description_removed')
};
const getLabelCardDescription = (
    activity: IRestCardCommonActivity
): string => {
    return MESSAGE_CARD_DESCRIPTION[activity.propertyActionType];
};

const MESSAGE_CARD_STATUS = {
    [ERestActivityPropertyActionType.ADD]: getMessages().getText('activity_item_card_processing_status__added'),
    [ERestActivityPropertyActionType.UPDATE]: getMessages().getText('activity_item_card_processing_status__edited'),
    [ERestActivityPropertyActionType.DELETE]: getMessages().getText('activity_item_card_processing_status__removed')
};
const getLabelCardStatus = (
    activity: IRestCardCommonActivity
): string => {
    return MESSAGE_CARD_STATUS[activity.propertyActionType];
};

const MESSAGE_CARD_PRIORITY = {
    [ERestActivityPropertyActionType.ADD]: getMessages().getText('activity_item__card_priority_added'),
    [ERestActivityPropertyActionType.UPDATE]: getMessages().getText('activity_item__card_priority_edited'),
    [ERestActivityPropertyActionType.DELETE]: getMessages().getText('activity_item__card_priority_removed')
};
const getLabelCardPriority = (
    activity: IRestCardCommonActivity
): string => {
    return MESSAGE_CARD_PRIORITY[activity.propertyActionType];
};

const MESSAGE_KEYS_CARD_ATTACHMENT_ADDED = [
    'activity_item_card__attachment_added',
    'activity_item_card__attachments_added'
];
const MESSAGE_KEYS_CARD_ATTACHMENT_REMOVED = [
    'activity_item_card__attachment_removed',
    'activity_item_card__attachments_removed'
];
const MESSAGE_CARD_COMMENT_ATTACHMENT_ADDED = getMessages().getText('activity_item_card__comment_added');
const MESSAGE_CARD_COMMENT_ATTACHMENT_REMOVED = getMessages().getText('activity_item_card__comment_removed');
const getLabelCardAttachment = (
    activity: IRestCardDriveDocActivity
): string => {
    if (activity.driveDocType === ERestDriveDocType.COMMENT) {
        return activity.propertyActionType === ERestActivityPropertyActionType.ADD
            ? MESSAGE_CARD_COMMENT_ATTACHMENT_ADDED
            : MESSAGE_CARD_COMMENT_ATTACHMENT_REMOVED;
    } else {
        const messageKeys = activity.propertyActionType === ERestActivityPropertyActionType.ADD
            ? MESSAGE_KEYS_CARD_ATTACHMENT_ADDED
            : MESSAGE_KEYS_CARD_ATTACHMENT_REMOVED;
            return getMessages().getPluralByKeys(activity.driveDocs.length, messageKeys, null, activity.driveDocs.length);
    }
};

const MESSAGE_CARD_CHECKLIST_ADDED = getMessages().getText('activity_item_card__checklist_added');
const MESSAGE_CARD_CHECKLIST_EDITED = getMessages().getText('activity_item_card__checklist_edited');
const MESSAGE_CARD_CHECKLIST_DELETED = getMessages().getText('activity_item_card__checklist_deleted');
const MESSAGE_CARD_CHECKLIST_WEIGHT_CHANGE = getMessages().getText('activity_item_card__checklist_weight_change');
const MESSAGE_CARD_CHECKLIST_DUE_DATE_CHANGE = getMessages().getText('activity_item_card__checklist_duedate_change');
const MESSAGE_CARD_CHECKLIST_DUE_DATE_REMOVE = getMessages().getText('activity_item_card__checklist_duedate_remove');

const MESSAGE_CARD_CHECKLIST_MARK_DONE = getMessages().getText('activity_item_card__checklist_mark_done');
const MESSAGE_CARD_CHECKLIST_REMOVE_DONE = getMessages().getText('activity_item_card__checklist_remove_done');
const getLabelCardChecklist = (
    activity: IRestCardCheckListActivity
): string => {
    if (activity.propertyActionType === ERestActivityPropertyActionType.ADD) {
        return MESSAGE_CARD_CHECKLIST_ADDED;
    } else if (activity.propertyActionType === ERestActivityPropertyActionType.DELETE) {
        return MESSAGE_CARD_CHECKLIST_DELETED;
    } else if (activity.propertyActionType === ERestActivityPropertyActionType.UPDATE) {
        if (activity.checkListProperty === 'name') {
            return MESSAGE_CARD_CHECKLIST_EDITED;
        } else if (activity.checkListProperty === 'weight') {
            return MESSAGE_CARD_CHECKLIST_WEIGHT_CHANGE;
        } else if (activity.checkListProperty === 'dueDate') {
            return !!activity.newValue ?
                MESSAGE_CARD_CHECKLIST_DUE_DATE_CHANGE
                : MESSAGE_CARD_CHECKLIST_DUE_DATE_REMOVE;
        } else if (activity.checkListProperty === 'checked') {
            return activity.newValue === 'true'
                ? MESSAGE_CARD_CHECKLIST_MARK_DONE
                : MESSAGE_CARD_CHECKLIST_REMOVE_DONE;
        }
    }
};

const MESSAGE_CARD_CHECKLIST_GROUP_ADDED = getMessages().getText('activity_item_card__checklist_group_added');
const MESSAGE_CARD_CHECKLIST_GROUP_EDITED = getMessages().getText('activity_item_card__checklist_group_edited');
const MESSAGE_CARD_CHECKLIST_GROUP_DELETED = getMessages().getText('activity_item_card__checklist_group_deleted');
const getLabelCardChecklistGroup = (
    activity: IRestCardCheckListGroupActivity
): string => {
    if (activity.propertyActionType === ERestActivityPropertyActionType.ADD) {
        return MESSAGE_CARD_CHECKLIST_GROUP_ADDED;
    } else if (activity.propertyActionType === ERestActivityPropertyActionType.DELETE) {
        return MESSAGE_CARD_CHECKLIST_GROUP_DELETED;
    } else if (activity.propertyActionType === ERestActivityPropertyActionType.UPDATE) {
        if (activity.checkListGroupProperty === 'name') {
            return MESSAGE_CARD_CHECKLIST_GROUP_EDITED;
        }
    }
};

const MESSAGE_CARD_CUSTOM = {
    [ERestActivityPropertyActionType.ADD]: getMessages().getText('activity_item_card__custom_property_added'),
    [ERestActivityPropertyActionType.UPDATE]: getMessages().getText('activity_item_card__custom_property_edited'),
    [ERestActivityPropertyActionType.DELETE]: getMessages().getText('activity_item_card__custom_property_removed')
}
const getLabelCardCustom = (
    activity: IRestCardCustomPropertyActivity
): string => {
    let extra = activity.customProperty.name;
    if (activity.propertyActionType === ERestActivityPropertyActionType.ADD) {
        extra = activity.customProperty.type.toLowerCase();
    }
    return MESSAGE_CARD_CUSTOM[activity.propertyActionType || ERestActivityPropertyActionType.DELETE] + extra;
};

const MESSAGE_CARD_RELATED = {
    [ERestActivityPropertyActionType.ADD]: [
        'activity_item_card__related_card_added',
        'activity_item_card__related_cards_added'
    ],
    [ERestActivityPropertyActionType.UPDATE]: [
        'activity_item_card__related_card_edited',
        'activity_item_card__related_cards_edited'
    ],
    [ERestActivityPropertyActionType.DELETE]: [
        'activity_item_card__related_card_removed',
        'activity_item_card__related_cards_removed'
    ],
};
const getLabelCardRelated = (
    activity: IRestCardRelatedActivity
): string => {
    return getMessages().getPluralByKeys(
        activity.relatedCards.length,
        MESSAGE_CARD_RELATED[activity.propertyActionType],
    );
};

const MESSAGE_LIST_CREATED = getMessages().getText('activity_item_list__created');
const MESSAGE_LIST_ARCHIVED = getMessages().getText('activity_item__list_archived');
const MESSAGE_LIST_RESTORED = getMessages().getText('activity_item__list_unarchived');
const MESSAGE_LIST_COPIED = getMessages().getText('activity_item_list__copied');
const MESSAGE_LIST_DETELED = getMessages().getText('activity_item_list__deleted');
const MESSAGE_LIST_RENAMED = getMessages().getText('activity_item_list__name');

const MESSAGE_LIST_MAX_CARD_NUMBER = {
    [ERestActivityPropertyActionType.ADD]: getMessages().getText('activity_item_list__max_card_number_added'),
    [ERestActivityPropertyActionType.UPDATE]: getMessages().getText('activity_item_list__max_card_number_edited'),
    [ERestActivityPropertyActionType.DELETE]: getMessages().getText('activity_item_list__max_card_number_removed')
};
const getLabelListNumber = (
    activity: IRestListCommonActivity
): string => {
    return MESSAGE_LIST_MAX_CARD_NUMBER[activity.propertyActionType];
};

const MESSAGE_LIST_DESCRIPTION = {
    [ERestActivityPropertyActionType.ADD]: getMessages().getText('activity_item_list__description_added'),
    [ERestActivityPropertyActionType.DELETE]: getMessages().getText('activity_item_list__description_removed'),
    [ERestActivityPropertyActionType.UPDATE]: getMessages().getText('activity_item_list__description_edited')
};
const getLabelListDescription = (
    activity: IRestListCommonActivity
): string => {
    return MESSAGE_LIST_DESCRIPTION[activity.propertyActionType];
};

const MESSAGE_BOARD_CREATED = getMessages().getText('activity_item_board__created');
const MESSAGE_BOARD_COPIED = getMessages().getText('activity_item_board__copied');
const MESSAGE_BOARD_RENAMED = getMessages().getText('activity_item_board__name');

const MESSAGE_BOARD_DESCRIPTION = {
    [ERestActivityPropertyActionType.ADD]: getMessages().getText('activity_item_board__description_added'),
    [ERestActivityPropertyActionType.DELETE]: getMessages().getText('activity_item_board__description_removed'),
    [ERestActivityPropertyActionType.UPDATE]: getMessages().getText('activity_item_board__description_edited')
};
const getLabelBoardDescription = (
    activity: IRestBoardCommonActivity
): string => {
    return MESSAGE_BOARD_DESCRIPTION[activity.propertyActionType];
};

const MESSAGE_BOARD_ARCHIVED = getMessages().getText('activity_item__board_archived');
const MESSAGE_BOARD_RESTORED = getMessages().getText('activity_item__board_unarchived');

const ACTIVITY_ITEMS: Record<string, IActivityItem> = {
// CARD
    [ERestActivityModelType.CARD + ERestActivityActionType.CREATE]: {
        Component: CardActivityCreate,
        label: MESSAGE_CARD_CREATED,
    },
    [ERestActivityModelType.CARD + ERestActivityActionType.COPY]: {
        Component: CardActivityCopy,
        label: MESSAGE_CARD_COPIED,
    },
    [ERestActivityModelType.CARD + ERestActivityActionType.ARCHIVE]: {
        Component: CardActivityArchive,
        label: MESSAGE_CARD_ARCHIVED,
    },
    [ERestActivityModelType.CARD + ERestActivityActionType.RESTORE]: {
        Component: CardActivityRestore,
        label: getLabelCardRestore,
    },
    [ERestActivityModelType.CARD + ERestActivityActionType.DATE_PASSED]: {
        Component: CardActivityDueDatePassed,
        label: MESSAGE_DUE_DATE_PASSED,
    },
    [ERestActivityModelType.CARD + ERestActivityActionType.CHECK_LIST_DATE_PASSED]: {
        Component: CardActivityCheckListDueDatePassed,
        label: MESSAGE_CHECKLIST_DUE_DATE_PASSED,
    },
    [ERestActivityModelType.CARD + ERestActivityActionType.CREATED_FROM_TEMPLATE]: {
        Component: CardActivityTemplate,
        label: MESSAGE_CARD_CREATED,
    },
    [ERestActivityModelType.CARD + ERestActivityActionType.CREATED_FROM_RECURRING]: {
        Component: CardActivityRecurring,
        label: MESSAGE_CREATED_FROM_RECURRING,
    },
    [ERestActivityModelType.CARD + ERestActivityActionType.DELETE]: {
        Component: CardActivityDelete,
        label: MESSAGE_CARD_DETELED,
    },
// CARD UPDATE
    [ERestActivityModelType.CARD + ERestActivityPropertyName.TAGS]: {
        Component: CardActivityTags,
        label: getLabelCardTags,
        // add
        // delete
    },
    [ERestActivityModelType.CARD + ERestActivityPropertyName.START_DATE]: {
        Component: CardActivityDate,
        label: getLabelCardDate,
        // add start/due
        // update start/due
        // delete start/due
    },
    [ERestActivityModelType.CARD + ERestActivityPropertyName.LIST]: {
        Component: CardActivityList,
        label: MESSAGE_CARD_LIST_UPDATED,
    },
    [ERestActivityModelType.CARD + ERestActivityPropertyName.ASSIGNED_TO]: {
        Component: CardActivityAssignedTo,
        label: getLabelCardAssinged,
        // add 1/many
        // delete 1/many
    },
    [ERestActivityModelType.CARD + ERestActivityPropertyName.ASSIGNEES]: {
        Component: CardActivityAssignedTo,
        label: getLabelCardAssinged,
        // add 1/many
        // delete 1/many
    },
    [ERestActivityModelType.CARD + ERestActivityPropertyName.COMMENT]: {
        Component: CardActivityComment,
        label: getLabelCardComment,
        // add
        // update
        // delete
    },
    [ERestActivityModelType.CARD + ERestActivityPropertyName.COMMENT_REACTION]: {
        Component: CardActivityCommentReaction,
        label: getLabelCardCommentReaction,
        // add
        // delete
    },
    [ERestActivityModelType.CARD + ERestActivityPropertyName.COLOR_ID]: {
        Component: CardActivityColor,
        label: getLabelCardColor,
        // add
        // update
        // delete
    },
    [ERestActivityModelType.CARD + ERestActivityPropertyName.ESTIMATE]: {
        Component: CardActivityEstimateHOC as React.FC<IActivityProps>,
        label: getLabelCardEstimate,
        // add
        // update
        // delete
    },
    [ERestActivityModelType.CARD + ERestActivityPropertyName.NAME]: {
        Component: CardActivityName,
        label: MESSAGE_CARD_RENAMED,
    },
    [ERestActivityModelType.CARD + ERestActivityPropertyName.DESCRIPTION]: {
        Component: CardActivityDescription,
        label: getLabelCardDescription,
        // add
        // update
        // delete
    },
    [ERestActivityModelType.CARD + ERestActivityPropertyName.PROCESSING_STATUS]: {
        Component: CardActivityProcessingStatus,
        label: getLabelCardStatus,
    },
    [ERestActivityModelType.CARD + ERestActivityPropertyName.PRIORITY]: {
        Component: CardActivityPriority,
        label: getLabelCardPriority,
        // add
        // update
        // delete
    },
    [ERestActivityModelType.CARD + ERestActivityPropertyName.DRIVE_DOC]: {
        Component: CardActivityDriveDoc,
        label: getLabelCardAttachment,
        // add 1/many attachmnets
        // delete 1/many attachmnets
        // add file in comment
        // delete file in comment
    },
    [ERestActivityModelType.CARD + ERestActivityPropertyName.CHECK_LIST]: {
        Component: CardActivityCheckList,
        label: getLabelCardChecklist,
        // add
        // edit
        // delete
        // weight_change
        // mark_done
        // remove_done
    },
    [ERestActivityModelType.CARD + ERestActivityPropertyName.CHECK_LIST_GROUP]: {
        Component: CardActivityCheckListGroup,
        label: getLabelCardChecklistGroup,
        // add
        // edit
        // delete
        // weight_change
        // mark_done
        // remove_done
    },
    [ERestActivityModelType.CARD + 'type' + ERestActivityType.TYPE_CARD_CUSTOM_PROPERTIES]: {
        Component: CardActivityCustomPropertyCommon,
        label: getLabelCardCustom,
        // add
        // update
        // delete = clear value
    },
    [ERestActivityModelType.CARD + ERestActivityPropertyName.RELATED_CARDS]: {
        Component: CardActivityRelated,
        label: getLabelCardRelated,
        // add 1/many related
        // delete 1/many related
    },

// LIST
    [ERestActivityModelType.LIST + ERestActivityActionType.CREATE]: {
        Component: ListActivityCreate,
        label: MESSAGE_LIST_CREATED,
    },
    [ERestActivityModelType.LIST + ERestActivityActionType.ARCHIVE]: {
        Component: ListActivityArchive,
        label: MESSAGE_LIST_ARCHIVED,
    },
    [ERestActivityModelType.LIST + ERestActivityActionType.RESTORE]: {
        Component: ListActivityRestore,
        label: MESSAGE_LIST_RESTORED,
    },
    [ERestActivityModelType.LIST + ERestActivityActionType.COPY]: {
        Component: ListActivityCopy,
        label: MESSAGE_LIST_COPIED,
    },
    [ERestActivityModelType.LIST + ERestActivityActionType.DELETE]: {
        Component: ListActivityDelete,
        label: MESSAGE_LIST_DETELED,
    },
// LIST UPDATE
    [ERestActivityModelType.LIST + ERestActivityPropertyName.NAME]: {
        Component: ListActivityName,
        label: MESSAGE_LIST_RENAMED,
    },
    [ERestActivityModelType.LIST + ERestActivityPropertyName.MAX_CARD_NUMBER]: {
        Component: ListActivityMaxCardNumber,
        label: getLabelListNumber,
    },
    [ERestActivityModelType.LIST + ERestActivityPropertyName.DESCRIPTION]: {
        Component: ListActivityDescription,
        label: getLabelListDescription,
    },

// BOARD
    [ERestActivityModelType.BOARD + ERestActivityActionType.CREATE]: {
        Component: BoardActivityCreate,
        label: MESSAGE_BOARD_CREATED,
    },
    [ERestActivityModelType.BOARD + ERestActivityActionType.COPY]: {
        Component: BoardActivityCopy,
        label: MESSAGE_BOARD_COPIED,
    },
// BOARD UPDATE
    [ERestActivityModelType.BOARD + ERestActivityPropertyName.NAME]: {
        Component: BoardActivityName,
        label: MESSAGE_BOARD_RENAMED,
    },
    [ERestActivityModelType.BOARD + ERestActivityPropertyName.DESCRIPTION]: {
        Component: BoardActivityDescription,
        label: getLabelBoardDescription,
    },
    [ERestActivityModelType.BOARD + ERestActivityPropertyName.STATUS + TStatus.STATUS_ARCHIVE]: {
        Component: BoardActivityArchive,
        label: MESSAGE_BOARD_ARCHIVED,
    },
    [ERestActivityModelType.BOARD + ERestActivityPropertyName.STATUS + TStatus.STATUS_ACTIVE]: {
        Component: BoardActivityRestore,
        label: MESSAGE_BOARD_RESTORED,
    },
};

export const getActivityItem = (
    activity: IRestActivityBase,
    isUser: boolean
): IActivityItem => {
    let key = '0';
    switch (activity.modelType) {
        case ERestActivityModelType.CARD:
            key = ERestActivityModelType.CARD + getCardActivityKey(activity);
            break;
        case ERestActivityModelType.LIST:
            key = ERestActivityModelType.LIST + getListActivityKey(activity);
            break;
        case ERestActivityModelType.BOARD:
            key = ERestActivityModelType.BOARD + getBoardActivityKey(activity);
            break;
    }

    if (!key || !ACTIVITY_ITEMS[key]) return null;

    const item = {...ACTIVITY_ITEMS[key]};
    if (typeof item.label === 'function') {
        item.label = item.label(activity, isUser);
    }
    if (item.label && item.label[0]) item.label = item.label[0].toUpperCase() + item.label.slice(1);
    return item;
};

const getCardActivityKey = (
    activity: TRestActivity
): string => {
    switch (activity.actionType) {
        case ERestActivityActionType.UPDATE:
            return getCardUpdateActivityKey(activity);
        default:
            return activity.actionType;
    }
};

const getCardUpdateActivityKey = (
    activity: TRestActivity
): string => {
    if (activity.type === ERestActivityType.TYPE_CARD_CUSTOM_PROPERTIES)
        return 'type' + activity.type;

    switch (activity.propertyName) {
        case ERestActivityPropertyName.DUE_DATE:
            return ERestActivityPropertyName.START_DATE;
        default:
            return activity.propertyName;
    }
};

const getListActivityKey = (
    activity: IRestActivityBase
): string => {
    switch (activity.actionType) {
        case ERestActivityActionType.UPDATE:
            return getListUpdateActivityKey(activity);
        default:
            return activity.actionType;
    }
};

const getListUpdateActivityKey = (
    activity: IRestActivityBase
): string => {
    return activity.propertyName;
};

const getBoardActivityKey = (
    activity: IRestActivityBase
): string => {
    switch (activity.actionType) {
        case ERestActivityActionType.UPDATE:
            return getBoardUpdateActivityKey(activity);
        default:
            return activity.actionType;
    }
};

const getBoardUpdateActivityKey = (
    activity: IRestActivityBase
): string => {
    switch (activity.propertyName) {
        case ERestActivityPropertyName.STATUS: {
            const commonActivity = activity as IRestBoardCommonActivity;
            if (commonActivity.newValue === TStatus.STATUS_ARCHIVE.toString())
                return ERestActivityPropertyName.STATUS + TStatus.STATUS_ARCHIVE;
            if (
                commonActivity.newValue === TStatus.STATUS_ACTIVE.toString() &&
                commonActivity.oldValue === TStatus.STATUS_ARCHIVE.toString()
            ) return ERestActivityPropertyName.STATUS + TStatus.STATUS_ACTIVE;
        }
        default:
            return activity.propertyName;
    }
};
