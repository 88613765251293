import { connect } from 'react-redux';
import {
    ITimeTrackerActionsDialogEvents,
    ITimeTrackerActionsDialogFields
} from '../../components/TimeTrackerActionsDialog/types';
import { TimeTrackerActionsDialog } from '../../components/TimeTrackerActionsDialog/TimeTrackerActionsDialog';
import { onDiscard } from './events/onDiscard';
import { onOpen } from './events/onOpen';
import { IApplicationState } from '../../../../../../types/types';
import { ITimeTrackerActionsDialogHOCProps } from './types';
import { getTimeTrackerState } from '../../../../aside_panel/cardDetails/TabTimingSection/TimeTracker/store/selectors/getTimeTrackerState';
import { onClose } from './events/onClose';

const mapStateToProps = (
    state: IApplicationState
): ITimeTrackerActionsDialogFields => {
    const {boardName = null, cardName = null} = getTimeTrackerState(state) || {};
    return {
        boardName,
        cardName
    };
};

const mapDispatchToProps = (
    dispatch: any,
    {targetCardId}: ITimeTrackerActionsDialogHOCProps
): ITimeTrackerActionsDialogEvents => ({
    onClose: () => dispatch(onClose()),
    onDiscard: () => dispatch(onDiscard(targetCardId)),
    onOpen: () => dispatch(onOpen(targetCardId))
});

export const TimeTrackerActionsDialogHOC = connect(
    mapStateToProps,
    mapDispatchToProps
)(TimeTrackerActionsDialog);

TimeTrackerActionsDialogHOC.displayName = 'TimeTrackerActionsDialogHOC';
