import { Dispatch, ThunkAction } from '../../../../types/actions';
import { getMessages, root } from '../../../../store/constants';
import { authUserUpdateMeta } from '../../../../rest/effects/authUser/authUserUpdateMeta';
import { dispatch } from '../../../../store/configureStore';
import { IBlockerOptions } from '../../../../helper/blocker_helper';
import {
    ESegmentBlockerOptions,
    ESegmentBlockerType,
    SegmentBlockersEvent
} from '../../../../middlewares/segment/trackEntities/blockersEvents';
import { TSegmentTrackPayload } from '../../../../middlewares/segment/trackEntities/types';
import { segmentTrackAction } from '../../../../middlewares/segment';
import { updateUser } from '../../../../store/model/authUser/effects/updateUser';
import { snoozeBlockerClosedSet } from '../../main/kanbanView/store/actions/snoozeBlockerClosedSet';
import { getBoardIsOnSharedDrive } from '../../../../store/model/selectors/getBoardIsOnSharedDrive';
import { getActiveBoardId } from '../../../../store/model/selectors/getActiveBoardId';
import { EViewTypes, VIEW_TYPES_OTHER, VIEW_TYPES_SWIM } from '../../../../const';
import { getActiveCardId } from '../../../../store/router/selectors/getActiveCardId';
import { routeSetAction } from '../../../../store/router/actions/routeSetAction';
import { EPanelRoute, ERoutes } from '../../../../store/router/constants';
import {
    getAuthUser,
    SNOOZE_BLOCKER_ALLOWS_BASIC_FEATURES_WITH_COUNTER,
    SNOOZE_BLOCKER_ALLOWS_PRO_FEATURES_NOT_AVAILABLE, SNOOZE_BLOCKER_LEGACY_BLOCKER,
    SNOOZE_BLOCKER_MINUTES,
    SNOOZE_BLOCKER_PRO_FEATURES
} from '../../../../store/model/authUser/selectors/getAuthUser';
import { getIsUserStarterPlanOnly } from '../../../../store/model/authUser/selectors/getIsUserStarterPlanOnly';
import { getUserProFeaturesUsage } from '../../../../store/model/authUser/selectors/getUserProFeaturesUsage';
import { authUserSetProFeaturesUsage } from '../../../../rest/effects/authUser/authUserSetProFeaturesUsage';
import { ONE_HOUR } from '../../main/kanbanView/components/CardAgingIndicator/constants';
import { getPanelTab } from '../../../../store/router/selectors/getPanelTab';
import { IApplicationState } from '../../../../types/types';
import { isArray } from 'underscore';
import { authUserSetUsedProFeatures } from '../../../../rest/effects/authUser/authUserSetUsedProFeatures';
import { ONE_DAY_LENGTH } from '../../aside_panel/filterPanelBoard/constants';
import { EUserSettingsVideoVoice } from '../../../../types/rest/IRestAuthUser';
import { changeBackboneViewType } from '../../typeSwitcher/effects/changeBackboneViewType';
import { getTypeSwitcherActiveType } from '../../typeSwitcher/store/selectors/getTypeSwitcherActiveType';
import { getIsUserBasicPlanOnly } from '../../../../store/model/authUser/selectors/getIsUserBasicPlanOnly';
import { getIsAssignedToMeActive } from '../../../../store/router/selectors/getIsAssignedToMeActive';

interface ISnoozeBlockerProps extends IBlockerOptions {
    blockerType?: ESegmentBlockerType
    subAllow?: string; // для искусственного разделения фич (пример: allowGantt используется и для вида ганта и для майлстона)
}

export const showSnoozeBlocker = ({
    blockerType,
    onClose: onCloseOwn,
    subAllow,
    ...options
}: ISnoozeBlockerProps): ThunkAction => {
    return (
        dispatch,
        getState
    ) => {
        const state = getState();
        const { allow } = options;
        const boardId = getActiveBoardId(state);
        const user = getAuthUser(state);
        const isStarterOnly = getIsUserStarterPlanOnly(state);
        const isBasicOnly = getIsUserBasicPlanOnly(state);
        const isLegacy = SNOOZE_BLOCKER_LEGACY_BLOCKER.includes(allow);
        const snoozeBlockerTime = user.meta && user.meta.snoozeBlockerTime;
        const canTryToday = isBasicOnly ? false : snoozeBlockerTime ? (getTimeStartOfDay(Date.now()) - getTimeStartOfDay(snoozeBlockerTime)) >= ONE_DAY_LENGTH
            : true;

        if (!canTryToday) {
            // обновляем стор пустым юзером, что бы заставить переренриться все хоки и сделать кнопки задисейбленными
            dispatch(updateUser({}));
        }

        const onUpgradeClick = () => {
            sendSegmentEvent(dispatch, SegmentBlockersEvent.SNOOZE_BLOCKER_UPGRADE_CLICKED, options.allow, blockerType);
            root.App.controller.showPayment(undefined, true);
        };

        const closeBlocker = (disallow: boolean = true) => {
            root.App.vent.trigger(root.App.vent['secondPanel:close']);
            if (!disallow) return;
            const notAllowedViews: EViewTypes[] = [];
            const user = getAuthUser(getState());
            if (!user.allowListView) {
                notAllowedViews.push(EViewTypes.LIST_VIEW_TYPE);
            }
            if (!user.allowArchive) {
                notAllowedViews.push(EViewTypes.ARCHIVE_VIEW_TYPE);
            }
            if (!user.allowGantt) {
                notAllowedViews.push(EViewTypes.GANTT_VIEW_TYPE);
            }
            if (!user.allowTeamWorkload) {
                notAllowedViews.push(...VIEW_TYPES_OTHER);
            }
            if (!user.allowSwimlanes) {
                notAllowedViews.push(...VIEW_TYPES_SWIM);
            }
            if (!user.allowReports) {
                notAllowedViews.push(EViewTypes.REPORTS_VIEW_TYPE);
            }
            const currentView = getTypeSwitcherActiveType(state, boardId);
            if (notAllowedViews.includes(currentView)) {
                const viewManager = root.App.controller.getDashboardViewManager();
                const prevView = viewManager && viewManager.getViewTypePrevious();
                const viewType = prevView && !notAllowedViews.includes(prevView) ? prevView : EViewTypes.KANBAN_VIEW_TYPE;
                dispatch(changeBackboneViewType(viewType, false));
            }
            if (!user.allowTimeTracker && getPanelTab(state) === EPanelRoute.TIMING) {
                const cardId = getActiveCardId(state);
                const url = root.App.router.getUrl('c-' + cardId);
                root.App.router.navigate(url, { trigger: false });
                dispatch(routeSetAction(ERoutes.EDIT_CARD));
            }
            if (onCloseOwn) onCloseOwn();
        };

        const onSnoozeClicked = () => {
            sendSegmentEvent(dispatch, SegmentBlockersEvent.SNOOZE_BLOCKER_TRY_CLICKED, options.allow, blockerType);
            dispatch(authUserUpdateMeta({ snoozeBlockerTime: Date.now() }));
            closeBlocker(false);
            if (isStarterOnly) {
                const proFeaturesUsage = getUserProFeaturesUsage(state);
                setTimeout(() => { //паралелльные запрос c authUserUpdateMeta({ snoozeBlockerTime: Date.now() })
                    dispatch(authUserSetProFeaturesUsage({
                        ...proFeaturesUsage,
                        time: proFeaturesUsage.time + SNOOZE_BLOCKER_MINUTES * 60 * 1000
                    }));
                }, 1000);
                switch (allow) { // если перешли сюда через view
                    case 'allowTeamWorkload':
                    case 'allowReports':
                    case 'allowMyWorkTaskGrouping':
                    case 'allowSwimlanes': {
                        dispatch(authUserSetUsedProFeatures(allow));
                        break;
                    }
                    case 'allowGantt': {
                        const currentView = getTypeSwitcherActiveType(state, boardId);
                        if (currentView === EViewTypes.GANTT_VIEW_TYPE) {
                            dispatch(authUserSetUsedProFeatures(allow));
                        }
                        break;
                    }
                    case 'allowArchive': {
                        const currentView = getTypeSwitcherActiveType(state, boardId);
                        if (currentView === EViewTypes.ARCHIVE_VIEW_TYPE) {
                            dispatch(authUserSetUsedProFeatures(allow));
                        }
                        break;
                    }
                    default: {
                        break;
                    }
                }
            }
        };

        let title, btnBack, planName, text, button, buttonSecondary, modifier, video, videoHeight, videoWidth,
            subtitle, titleIcon, extraText, img, features, onClickButton, additionalLinkButton,
            onClickAdditionalLinkButton, onClickButtonSecondary;

        if (
            isStarterOnly &&
            !isLegacy &&
            SNOOZE_BLOCKER_PRO_FEATURES.includes(allow)
        ) {
            modifier = 'second-panel--pro-features-trial';
            videoWidth = 452;
            videoHeight = 256;
            if (!canTryToday && !SNOOZE_BLOCKER_ALLOWS_PRO_FEATURES_NOT_AVAILABLE.includes(allow)) {
                modifier += ' second-panel--pro-features-trial-end';
                title = getMessages().getText('dialog.blocker-snooze.pro-trial.end.title.start');
                subtitle = getMessages().getText('dialog.blocker-snooze.pro-trial.end.title.end');
                const { time } = getUserProFeaturesUsage(getState());
                const timeStr = getTimeString(time);
                text = timeStr ? getMessages().getText('dialog.blocker-snooze.pro-trial.end.subtitle') : null;
                extraText = timeStr ? timeStr : null
                button = getMessages().getText('dialog.blocker-snooze.pro-trial.end.button');
                additionalLinkButton = getMessages().getText('dialog.blocker-snooze.pro-trial.end.back');
                features = getFeatures(state);
                onClickAdditionalLinkButton = closeBlocker;
            } else {
                const feature = PRO_FEATURES_DATA[allow];
                text = feature.text;
                titleIcon = feature.titleIcon;
                video = feature.video;
                img = feature.image;
                title = feature.featureName + getMessages().getText('dialog.blocker-snooze.pro-trial.title.extra');
                button = getMessages().getText('dialog.blocker-snooze.pro-trial.button');
                if (SNOOZE_BLOCKER_ALLOWS_PRO_FEATURES_NOT_AVAILABLE.includes(allow)) {
                    button = getMessages().getText('dialog.blocker-snooze.button-upgrade');
                    onClickButton = onUpgradeClick;
                } else {
                    button = getMessages().getText('dialog.blocker-snooze.pro-trial.button');
                    onClickButton = onSnoozeClicked;
                }
            }
        } else if (isBasicOnly && !isLegacy) {
            videoWidth = 600;
            videoHeight = 338;
            const feature = BASIC_FEATURES_DATA[allow];
            const isAlternative = feature.isAlternative && feature.isAlternative(state, subAllow);
            const isWithCounter = SNOOZE_BLOCKER_ALLOWS_BASIC_FEATURES_WITH_COUNTER.includes(allow);
            modifier = `second-panel--basic-features ${isWithCounter ? 'second-panel--basic-features-small' : ''}`;
            if (isWithCounter) {
                text = feature.textWithCount(user[allow] as number, isAlternative);
            } else if (isAlternative && feature.textAlternative) {
                text = feature.textAlternative;
            } else {
                text = feature.text;
            }
            title = isAlternative && feature.titleAlternative ? feature.titleAlternative : feature.title;
            titleIcon = isAlternative && feature.titleIconAlternative ? feature.titleIconAlternative : feature.titleIcon;
            if (!isWithCounter) {
                video = isAlternative && feature.videoAlternative ? feature.videoAlternative : feature.video;
            }
            button = getMessages().getText('dialog.blocker-snooze.basic.button');
            onClickButton = onUpgradeClick;
        } else {
            const onCancel = () => {
                sendSegmentEvent(dispatch, SegmentBlockersEvent.SNOOZE_BLOCKER_BACK_CLICKED, options.allow, blockerType);
                closeBlocker();
                dispatch(snoozeBlockerClosedSet(true));
            };
            const isOnSharedDrive = getBoardIsOnSharedDrive(state,  getActiveBoardId(state));
            title =  getMessages().getText(isOnSharedDrive ? 'dialog.blocker-snooze.shared-drives.title' : 'dialog.blocker-snooze.title');
            btnBack = getMessages().getText(isOnSharedDrive ? 'dialog.blocker-snooze.shared-drives.button-back' : 'dialog.blocker-snooze.button-back');
            planName = getMessages().getText(isOnSharedDrive ? 'dialog.blocker-snooze.shared-drives.plan' : 'dialog.blocker-snooze.plan');
            text = getMessages().getText(isLegacy
                ? isBasicOnly ? 'dialog.blocker-snooze.upgrade.text.legacy' : 'dialog.blocker-snooze.upgrade.text.legacy.try'
                : canTryToday ? 'dialog.blocker-snooze.upgrade.text' : 'dialog.blocker-snooze.used.text', null, planName);
            button = getMessages().getText('dialog.blocker-snooze.button-upgrade');
            buttonSecondary = canTryToday ? getMessages().getText('dialog.blocker-snooze.button-try', null, planName) : btnBack;
            additionalLinkButton = canTryToday ? btnBack : null;
            onClickButtonSecondary = canTryToday ? onSnoozeClicked : onCancel;
            onClickAdditionalLinkButton = canTryToday ? onCancel : null;
            onClickButton = onUpgradeClick;
        }

        if (options.allow) {
            sendSegmentEvent(dispatch, SegmentBlockersEvent.SNOOZE_BLOCKER_SHOWN, options.allow, blockerType);
        }
        root.App.controller.showBlocker({
            ...options,
            close: !isLegacy && (isStarterOnly || isBasicOnly),
            closeIcon: 'kui-close',
            modifier,
            onCloseClick: closeBlocker,
            title,
            img,
            titleIcon,
            video,
            videoWidth,
            videoHeight,
            extraText,
            subtitle,
            features,
            text,
            button,
            buttonSecondary,
            additionalLinkButton,
            onClickButtonSecondary,
            onClickAdditionalLinkButton,
            onClickButton,
        }, true);
    }
}

const sendSegmentEvent = (
    dispatch: Dispatch,
    event: TSegmentTrackPayload,
    allow: string,
    blockerType: ESegmentBlockerType,
) => {
    const extra: { name: string, value: ESegmentBlockerType | EUserSettingsVideoVoice }[] = [{
        name: ESegmentBlockerOptions.type,
        value: blockerType
    }];
    dispatch(segmentTrackAction(event, {
        name: ESegmentBlockerOptions.allow,
        value: allow
    }, extra));
}

const getTimeStartOfDay = (time: number): number => {
    const date = new Date(time);
    date.setHours(0, 0, 0, 0);
    return date.getTime();
}

interface IProFeatureData {
    featureName: string;
    text: string;
    titleIcon: string;
    video?: string;
    image?: string;
}

const PRO_FEATURES_DATA: { [key: string]: IProFeatureData } = {
    allowTeamWorkload: {
        featureName: getMessages().getText('dialog.blocker-snooze.pro-trial.title.team'),
        text: getMessages().getText('dialog.blocker-snooze.pro-trial.text.team'),
        titleIcon: 'kui-share-specific',
        video: 'https://www.youtube-nocookie.com/embed/IfaHFKz3K8A'
    },
    allowReports: {
        featureName: getMessages().getText('dialog.blocker-snooze.pro-trial.title.reports'),
        text: getMessages().getText('dialog.blocker-snooze.pro-trial.text.reports'),
        titleIcon: 'kui-chart',
        video: 'https://www.youtube-nocookie.com/embed/wpPZ97-iTGo'
    },
    allowGantt: {
        featureName: getMessages().getText('dialog.blocker-snooze.pro-trial.title.gantt'),
        text: getMessages().getText('dialog.blocker-snooze.pro-trial.text.gantt'),
        titleIcon: 'kui-gantt-chart',
        video: 'https://www.youtube-nocookie.com/embed/TSx8t34YO00'
    },
    allowBranding: {
        featureName: getMessages().getText('dialog.blocker-snooze.pro-trial.title.logo'),
        text: getMessages().getText('dialog.blocker-snooze.pro-trial.text.logo'),
        titleIcon: 'kanbanchi-logo',
        video: 'https://www.youtube-nocookie.com/embed/Y9ZQBD0LMrI'
    },
    allowTimeTracker: {
        featureName: getMessages().getText('dialog.blocker-snooze.pro-trial.title.time-tracker'),
        text: getMessages().getText('dialog.blocker-snooze.pro-trial.text.time-tracker'),
        titleIcon: 'notification-estimate',
        video: 'https://www.youtube-nocookie.com/embed/Ogy_SZ-J0go'
    },
    allowArchive: {
        featureName: getMessages().getText('dialog.blocker-snooze.pro-trial.title.archive'),
        text: getMessages().getText('dialog.blocker-snooze.pro-trial.text.archive'),
        titleIcon: 'notification-archive',
        video: 'https://www.youtube-nocookie.com/embed/oQ_1i2aoz6o'
    },
    allowAutoBackupXml: {
        featureName: getMessages().getText('dialog.blocker-snooze.pro-trial.title.backup'),
        text: getMessages().getText('dialog.blocker-snooze.pro-trial.text.backup'),
        titleIcon: 'repeat',
        video: 'https://www.youtube-nocookie.com/embed/19wIw7aPRgQ'
    },
    allowTimeTrackerExport: {
        featureName: getMessages().getText('dialog.blocker-snooze.pro-trial.title.time-tracker-export'),
        text: getMessages().getText('dialog.blocker-snooze.pro-trial.text.time-tracker-export'),
        titleIcon: 'notification-estimate',
        video: 'https://www.youtube-nocookie.com/embed/Ogy_SZ-J0go'
    },
    allowNotificationBeforeDueDates: {
        featureName: getMessages().getText('dialog.blocker-snooze.pro-trial.title.notifications'),
        text: getMessages().getText('dialog.blocker-snooze.pro-trial.text.notifications'),
        titleIcon: 'add-notifications',
        video: 'https://www.youtube-nocookie.com/embed/aDIhDW3fj7E'
    },
    allowSwimlanes: {
        featureName: getMessages().getText('dialog.blocker-snooze.pro-trial.title.swimlanes'),
        text: getMessages().getText('dialog.blocker-snooze.pro-trial.text.swimlanes'),
        titleIcon: 'kui-swimlanes',
        video: 'https://www.youtube-nocookie.com/embed/p6SFH_K5l6s'
    },
    allowDependencies: {
        featureName: getMessages().getText('dialog.blocker-snooze.pro-trial.title.dependencies'),
        text: getMessages().getText('dialog.blocker-snooze.pro-trial.text.dependencies'),
        titleIcon: 'kui-dependency',
        video: 'https://www.youtube-nocookie.com/embed/TSx8t34YO00'
    },
    allowCardCustomProperties: {
        featureName: getMessages().getText('dialog.blocker-snooze.pro-trial.title.custom-properties'),
        text: getMessages().getText('dialog.blocker-snooze.pro-trial.text.custom-properties'),
        titleIcon: 'plus',
        video: 'https://www.youtube-nocookie.com/embed/17k2cYxQJk0'
    },
    allowMyWorkCustomFilters: {
        featureName: getMessages().getText('dialog.blocker-snooze.pro-trial.title.my-work-filters'),
        text: getMessages().getText('dialog.blocker-snooze.pro-trial.text.my-work-filters'),
        titleIcon: 'kui-filter',
        image: '/dist/img/pro-features-my-work-custom-filters.png'
    },
    allowMyWorkTaskGrouping: {
        featureName: getMessages().getText('dialog.blocker-snooze.pro-trial.title.my-work-grouping'),
        text: getMessages().getText('dialog.blocker-snooze.pro-trial.text.my-work-grouping'),
        titleIcon: 'kui-list',
        image: '/dist/img/pro-features-my-work-list-grouping.png'
    },
};

const PRO_FEATURES_DATA_SHOWN= [
    {
        key: 'allowGantt',
        name: getMessages().getText('dialog.blocker-snooze.pro-trial.end.gantt.title'),
        icon: 'kui-gantt-chart',
        tooltip: getMessages().getText('dialog.blocker-snooze.pro-trial.text.gantt')
    },
    {
        key: 'allowDependencies',
        name: getMessages().getText('dialog.blocker-snooze.pro-trial.end.dependencies.title'),
        icon: 'kui-dependency',
        tooltip: getMessages().getText('dialog.blocker-snooze.pro-trial.text.dependencies')
    },
    {
        key: 'allowNotificationBeforeDueDates',
        name: getMessages().getText('dialog.blocker-snooze.pro-trial.end.notifications.title'),
        icon: 'add-notifications',
        tooltip: getMessages().getText('dialog.blocker-snooze.pro-trial.text.notifications')
    },
    {
        key: 'allowArchive',
        name: getMessages().getText('dialog.blocker-snooze.pro-trial.end.archive.title'),
        icon: 'notification-archive',
        tooltip: getMessages().getText('dialog.blocker-snooze.pro-trial.text.archive')
    },
    {
        key: 'allowReports',
        name: getMessages().getText('dialog.blocker-snooze.pro-trial.end.reports.title'),
        icon: 'kui-chart',
        tooltip: getMessages().getText('dialog.blocker-snooze.pro-trial.text.reports')
    },
    {
        key: 'allowTeamWorkload',
        name: getMessages().getText('dialog.blocker-snooze.pro-trial.end.team.title'),
        icon: 'kui-share-specific',
        tooltip: getMessages().getText('dialog.blocker-snooze.pro-trial.text.team')
    },
    {
        key: 'allowSwimlanes',
        name: getMessages().getText('dialog.blocker-snooze.pro-trial.end.swimlanes.title'),
        icon: 'kui-swimlanes',
        tooltip: getMessages().getText('dialog.blocker-snooze.pro-trial.text.swimlanes')
    },
    {
        key: ['allowTimeTracker', 'allowTimeTrackerExport'],
        name: getMessages().getText('dialog.blocker-snooze.pro-trial.end.time-tracker.title'),
        icon: 'notification-estimate',
        tooltip: getMessages().getText('dialog.blocker-snooze.pro-trial.text.time-tracker-export')
    },
    {
        key: 'allowCardCustomProperties',
        name: getMessages().getText('dialog.blocker-snooze.pro-trial.end.custom-properties.title'),
        icon: 'plus',
        tooltip: getMessages().getText('dialog.blocker-snooze.pro-trial.text.custom-properties')
    },
    {
        key: 'allowBranding',
        name: getMessages().getText('dialog.blocker-snooze.pro-trial.end.logo.title'),
        icon: 'kanbanchi-logo',
        tooltip: getMessages().getText('dialog.blocker-snooze.pro-trial.text.logo'),
        isUnavailable: true
    },
    {
        key: 'allowAutoBackupXml',
        name: getMessages().getText('dialog.blocker-snooze.pro-trial.end.backup.title'),
        icon: 'repeat',
        tooltip: getMessages().getText('dialog.blocker-snooze.pro-trial.text.backup'),
        isUnavailable: true
    },
    {
        key: 'allowMyWorkTaskGrouping',
        name: getMessages().getText('dialog.blocker-snooze.pro-trial.end.my-work-grouping.title'),
        icon: 'kui-list',
        tooltip: getMessages().getText('dialog.blocker-snooze.pro-trial.text.my-work-grouping.tooltip'),
    },
    {
        key: 'allowMyWorkCustomFilters',
        name: getMessages().getText('dialog.blocker-snooze.pro-trial.end.my-work-filters.title'),
        icon: 'kui-filter',
        tooltip: getMessages().getText('dialog.blocker-snooze.pro-trial.text.my-work-filters.tooltip'),
    },
];

interface IBasicFeatureData {
    text?: string;
    textAlternative?: string;
    textWithCount?: (count: number, isAlternative: boolean) => string;
    title: string;
    titleAlternative?: string;
    titleIcon: string;
    titleIconAlternative?: string;
    isAlternative?: (state?: IApplicationState, subAllow?: string) => boolean;
    video?: string;
    videoAlternative?: string;
}

const BASIC_FEATURES_DATA: { [key: string]: IBasicFeatureData } = {
    allowMyWorkCustomFilters: {
        text: getMessages().getText('dialog.blocker-snooze.basic.custom-filter.text'),
        title: getMessages().getText('dialog.blocker-snooze.basic.custom-filter.title'),
        titleIcon: 'card-assigned',
        video: 'https://www.youtube-nocookie.com/embed/Yis9fzlI49c'
    },
    allowSavedFilters: {
        text: getMessages().getText('dialog.blocker-snooze.basic.saved-filter.text'),
        textAlternative: getMessages().getText('dialog.blocker-snooze.basic.saved-filter.text.alternative'),
        title: getMessages().getText('dialog.blocker-snooze.basic.saved-filter.title'),
        titleAlternative: getMessages().getText('dialog.blocker-snooze.basic.saved-filter.title.alternative'),
        titleIcon: 'kui-filter',
        titleIconAlternative: 'card-assigned',
        isAlternative: getIsAssignedToMeActive,
        video: 'https://www.youtube-nocookie.com/embed/CFMpc0iXd-I',
        videoAlternative: 'https://www.youtube-nocookie.com/embed/Yis9fzlI49c'
    },
    allowMyWorkTaskGrouping: {
        text: getMessages().getText('dialog.blocker-snooze.basic.task-grouping.text'),
        title: getMessages().getText('dialog.blocker-snooze.basic.task-grouping.title'),
        titleIcon: 'card-assigned',
        video: 'https://www.youtube-nocookie.com/embed/Yis9fzlI49c'
    },
    allowGantt: {
        text: getMessages().getText('dialog.blocker-snooze.basic.gantt.text'),
        textAlternative: getMessages().getText('dialog.blocker-snooze.basic.milestone.text'),
        title: getMessages().getText('dialog.blocker-snooze.basic.gantt.title'),
        titleAlternative: getMessages().getText('dialog.blocker-snooze.basic.milestone.title'),
        titleIcon: 'kui-gantt-chart',
        titleIconAlternative: 'kui-milestone',
        isAlternative: (state, subAllow) => subAllow === 'allowMilestone',
        video: 'https://www.youtube-nocookie.com/embed/TSx8t34YO00',
    },
    allowSwimlanes: {
        text: getMessages().getText('dialog.blocker-snooze.basic.swimlanes.text'),
        title: getMessages().getText('dialog.blocker-snooze.basic.swimlanes.title'),
        titleIcon: 'kui-swimlanes',
        video: 'https://www.youtube-nocookie.com/embed/p6SFH_K5l6s'
    },
    allowTeamWorkload:  {
        text: getMessages().getText('dialog.blocker-snooze.basic.team.text'),
        title: getMessages().getText('dialog.blocker-snooze.basic.team.title'),
        titleIcon: 'kui-list',
        video: 'https://www.youtube-nocookie.com/embed/IfaHFKz3K8A'
    },
    allowArchive:  {
        text: getMessages().getText('dialog.blocker-snooze.basic.archive.text'),
        title: getMessages().getText('dialog.blocker-snooze.basic.archive.title'),
        titleIcon: 'notification-archive',
        video: 'https://www.youtube-nocookie.com/embed/oQ_1i2aoz6o'
    },
    allowListView:  {
        text: getMessages().getText('dialog.blocker-snooze.basic.list.text'),
        title: getMessages().getText('dialog.blocker-snooze.basic.list.title'),
        titleIcon: 'kui-list',
        video: 'https://www.youtube-nocookie.com/embed/HThSRhYpg10',
    },
    allowActivity:  {
        text: getMessages().getText('dialog.blocker-snooze.basic.activity.text'),
        textAlternative: getMessages().getText('dialog.blocker-snooze.basic.activity.text.alternative'),
        title: getMessages().getText('dialog.blocker-snooze.basic.activity.title'),
        titleAlternative: getMessages().getText('dialog.blocker-snooze.basic.activity.title.alternative'),
        titleIcon: 'activity',
        isAlternative: (state, subAllow) => !!getActiveCardId(state) && subAllow !== 'allowBoardActivity',
        video: 'https://www.youtube-nocookie.com/embed/bX1l4EbCSU0',
    },
    allowBranding:  {
        text: getMessages().getText('dialog.blocker-snooze.basic.branding.text'),
        title: getMessages().getText('dialog.blocker-snooze.basic.branding.title'),
        titleIcon: 'kanbanchi-logo',
        video: 'https://www.youtube-nocookie.com/embed/Y9ZQBD0LMrI'
    },
    allowAutoBackupXml:  {
        text: getMessages().getText('dialog.blocker-snooze.basic.backup.text'),
        title: getMessages().getText('dialog.blocker-snooze.basic.backup.title'),
        titleIcon: 'repeat',
        video: 'https://www.youtube-nocookie.com/embed/2wlI0PZjrFo',
    },
    allowGoogleSheetExport: {
        text: getMessages().getText('dialog.blocker-snooze.basic.export.text'),
        title: getMessages().getText('dialog.blocker-snooze.basic.export.title'),
        titleIcon: 'cloud-download',
        video: 'https://www.youtube-nocookie.com/embed/nmTHMSi9MzI',
    },
    allowCardCustomProperties: {
        text: getMessages().getText('dialog.blocker-snooze.basic.custom.text'),
        title: getMessages().getText('dialog.blocker-snooze.basic.custom.title'),
        titleIcon: 'custom-property',
        video: 'https://www.youtube-nocookie.com/embed/17k2cYxQJk0'
    },
    allowCardAgeing: {
        text: getMessages().getText('dialog.blocker-snooze.basic.ageing.text'),
        title: getMessages().getText('dialog.blocker-snooze.basic.ageing.title'),
        titleIcon: 'ageing',
        video: 'https://www.youtube-nocookie.com/embed/Rpf71qznn70',
    },
    allowCardNumber: {
        text: getMessages().getText('dialog.blocker-snooze.basic.number.text'),
        title: getMessages().getText('dialog.blocker-snooze.basic.number.title'),
        titleIcon: 'card-number',
        video: 'https://www.youtube-nocookie.com/embed/iSRFwwIp8gs',
    },
    allowRoles: {
        text: getMessages().getText('dialog.blocker-snooze.basic.roles.text'),
        title: getMessages().getText('dialog.blocker-snooze.basic.roles.title'),
        titleIcon: 'user',
        video: 'https://www.youtube-nocookie.com/embed/4P327Scg6VY',
    },
    allowStatusMapping: {
        text: getMessages().getText('dialog.blocker-snooze.basic.status.text'),
        title: getMessages().getText('dialog.blocker-snooze.basic.status.title'),
        titleIcon: 'pulse',
        video: 'https://www.youtube-nocookie.com/embed/jw10uLrbAqs',
    },
    allowPriorityAutoSorting: {
        text: getMessages().getText('dialog.blocker-snooze.basic.sorting.text'),
        title: getMessages().getText('dialog.blocker-snooze.basic.sorting.title'),
        titleIcon: 'kui-sort',
        video: 'https://www.youtube-nocookie.com/embed/XTZSsAYpcRo',
    },
    allowReports: {
        text: getMessages().getText('dialog.blocker-snooze.basic.reports.text'),
        title: getMessages().getText('dialog.blocker-snooze.basic.reports.title'),
        titleIcon: 'kui-chart',
        video: 'https://www.youtube-nocookie.com/embed/wpPZ97-iTGo'
    },
    allowSharedDrive: {
        text: getMessages().getText('dialog.blocker-snooze.basic.shared.text'),
        title: getMessages().getText('dialog.blocker-snooze.basic.shared.title'),
        textAlternative: getMessages().getText('dialog.blocker-snooze.basic.shared.text.browse-boards'),
        titleIcon: 'kui-share-specific',
        isAlternative: (state, subAllow) => subAllow === 'allowSharedDriveBrowseBoards',
        video: 'https://www.youtube-nocookie.com/embed/LalnupRAkGA',
    },
    allowRecurringTask: {
        text: getMessages().getText('dialog.blocker-snooze.basic.recurring.text'),
        textAlternative: getMessages().getText('dialog.blocker-snooze.basic.template.text'),
        title: getMessages().getText('dialog.blocker-snooze.basic.recurring.title'),
        titleAlternative: getMessages().getText('dialog.blocker-snooze.basic.template.title'),
        titleIcon: 'repeat',
        titleIconAlternative: 'kui-template',
        isAlternative: (state, subAllow) => subAllow === 'allowTemplates',
        video: 'https://www.youtube-nocookie.com/embed/nVCpg6aNCgw',
    },
    allowDependencies: {
        text: getMessages().getText('dialog.blocker-snooze.basic.dependencies.text'),
        title: getMessages().getText('dialog.blocker-snooze.basic.dependencies.title'),
        titleIcon: 'kui-dependency',
        video: 'https://www.youtube-nocookie.com/embed/TSx8t34YO00'
    },
    allowTimeTracker: {
        text: getMessages().getText('dialog.blocker-snooze.basic.time.text'),
        title: getMessages().getText('dialog.blocker-snooze.basic.time.title'),
        titleIcon: 'estimate',
        video: 'https://www.youtube-nocookie.com/embed/Ogy_SZ-J0go'
    },
    allowNotificationBeforeDueDates: {
        text: getMessages().getText('dialog.blocker-snooze.basic.notifications.text'),
        title: getMessages().getText('dialog.blocker-snooze.basic.notifications.title'),
        titleIcon: 'add-notifications',
        video: 'https://www.youtube-nocookie.com/embed/aDIhDW3fj7E'
    },
    allowColorTagCount: {
        textWithCount: (count, isAlternative) => isAlternative ? getMessages().getText('dialog.blocker-snooze.basic.color-tag.title.alternative', null, count) : getMessages().getText('dialog.blocker-snooze.basic.color-tag.text', null, count),
        title: getMessages().getText('dialog.blocker-snooze.basic.color-tag.title'),
        titleIcon: 'kui-color-tag',
        isAlternative: (state, subAllow) => subAllow === 'allowBoardColorTagCount',
    },
    allowCardTagCount: {
        textWithCount: (count) => getMessages().getText('dialog.blocker-snooze.basic.tag.text', null, count),
        title: getMessages().getText('dialog.blocker-snooze.basic.tag.title'),
        titleIcon: 'notification-tag',
    },
    allowSubCard: {
        text: getMessages().getText('dialog.blocker-snooze.basic.subcard.text'),
        title: getMessages().getText('dialog.blocker-snooze.basic.subcard.title'),
        titleIcon: 'subcard',
        video: 'https://www.youtube-nocookie.com/embed/ALLCCPdf1cE',
    },
    allowCardColorCount: {
        textWithCount: (count, isAlternative) => isAlternative ? getMessages().getText('dialog.blocker-snooze.basic.color.text.list', null, count) : getMessages().getText('dialog.blocker-snooze.basic.color.text', null, count),
        title: getMessages().getText('dialog.blocker-snooze.basic.color.title'),
        titleAlternative: getMessages().getText('dialog.blocker-snooze.basic.color.title.list'),
        titleIcon: 'palette',
        isAlternative: (state, subAllow) => subAllow === 'allowListColorCount'
    },
    allowChecklistCount: {
        textWithCount: (count) => getMessages().getText('dialog.blocker-snooze.basic.checklist.text', null, count),
        title: getMessages().getText('dialog.blocker-snooze.basic.checklist.title'),
        titleIcon: 'notification-checklist',
    },
    allowChecklistItemCount: {
        textWithCount: (count) => getMessages().getText('dialog.blocker-snooze.basic.checklist-item.text', null, count),
        title: getMessages().getText('dialog.blocker-snooze.basic.checklist-item.title'),
        titleIcon: 'notification-checklist',
    },
    allowBoardTemplate: {
        text: getMessages().getText('dialog.blocker-snooze.basic.board-template.text'),
        title: getMessages().getText('dialog.blocker-snooze.basic.board-template.title'),
        titleIcon: 'repeat',
        video: 'https://www.youtube-nocookie.com/embed/WMfD_S_T4l8',
    }
};

const getFeatures = (
    state: IApplicationState
) => {
    const { usedFeatures } = getUserProFeaturesUsage(state);
    const features: any = {
        used: {
            title: getMessages().getText('dialog.blocker-snooze.pro-trial.end.used.title'),
            elements: []
        },
        unused: {
            title: getMessages().getText('dialog.blocker-snooze.pro-trial.end.unused.title'),
            elements: []
        },
        unavailable: {
            title: getMessages().getText('dialog.blocker-snooze.pro-trial.end.unavailable.title'),
            elements: []
        }
    };

    PRO_FEATURES_DATA_SHOWN.forEach(feature => {
        if (feature.isUnavailable) {
            features.unavailable.elements.push(feature);
        } else {
            const isUsed = !!usedFeatures.find(usedFeature => {
                if (isArray(feature.key)) {
                    return feature.key.includes(usedFeature);
                }
                return usedFeature === feature.key;
            });
            if (isUsed) {
                features.used.elements.push(feature);
            } else {
                features.unused.elements.push(feature);
            }
        }
    });

    return features;
}

const getTimeString = (time: number): string => {
    let result = '';
    const hours = Math.floor(time / ONE_HOUR);
    if (hours > 0) {
        result += hours + 'h';
        time -= hours * ONE_HOUR;
    }
    const minutes = Math.floor(time / (60 * 1000));
    if (!hours && minutes > 0) {
        return null;
    }
    return result;
}

    // TODO for testing
// @ts-ignore
window.resetSnoozeBlockerTime = () => {
    dispatch(authUserUpdateMeta({
        snoozeBlockerTime: null
    }))
}
