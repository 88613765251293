import { isObjectEmpty } from '../../../../../../view/react_components/helpers/isObjectEmpty';
import { Dispatch, ThunkAction } from '../../../../../../types/actions';
import { IApplicationState, IGetState } from '../../../../../../types/types';
import { IDependencies } from '../../../../../../store/model/dependencies/dependencies/types';
import { getCardDependencesForRollback } from '../../../../../../store/model/selectors/getCardDependencesForRollback';
import { dependenciesActionSetAction } from '../../../../../../store/model/actions/dependenciesActionSetAction';
import { dependencySetAction } from '../../../../../../store/model/dependencies/dependencies/actions/dependencySetAction';
import { dependenciesUpdateAction } from '../../../../../../store/model/dependencies/dependencies/actions/dependenciesUpdateAction';
import { patchRest } from '../patchRest';
import { IDependency } from '../../../../../../store/model/dependencies/dependency/types';
import { getCardDependency } from '../../../../../../store/model/dependencies/dependencies/selectors/getCardDependency';
import { getBoardIdByCardId } from '../../../../../../store/model/selectors/getBoardIdByCardId';
import { dependenciesActiveRequestsUpdate } from '../../../../../../store/requestsState/effects/dependenciesActiveRequestsUpdate';
import { sendRealTimeStoreActionByBoardId } from '../../../../../../view/react_components/base/helpers/realTimeHelperTS';
import { getNumberKeys } from '../../../../../../helper/getNumberKeys';

export const dependenciesPatch = (
    dependencies: IDependencies,
    isOptimistic: boolean = false, // true = store -> rest -> rollback on error
    isRealtime: boolean = true, // send event to socket
): ThunkAction => {
    const action = (
        dispatch: Dispatch,
        getState: IGetState
    ) => {
        if (
            !dependencies ||
            isObjectEmpty(dependencies)
        ) Promise.reject();

        const state = getState();
        let depsForRollback: IDependencies;
        if (isOptimistic) {
            depsForRollback = getCardDependencesForRollback(state, dependencies);
            updateDependencies(dispatch, dependencies);
        }
        dispatch(dependenciesActiveRequestsUpdate(dependencies, 1));

        const depPatchPromises: Promise<any>[] = [];
        for (let depId in dependencies) {
            depPatchPromises.push(dispatch(patchRest(dependencies[depId])));
        }

        return Promise.all(depPatchPromises)
            .then(() => {
                if (!isOptimistic) {
                    updateDependencies(dispatch, dependencies);
                }
                if (isRealtime) {
                    sendToRealtimeDependencies(getState(), dispatch, dependencies)
                }
            })
            .catch((e: any) => {
                console.error(e);
                if (isOptimistic) {
                    restoreDependences(dispatch, depsForRollback)
                }
            })
            .finally(() => {
                dispatch(dependenciesActiveRequestsUpdate(dependencies, -1));
            });
    };
    return action;
};

const sendToRealtimeDependencies = (
    state: IApplicationState,
    dispatch: Dispatch,
    dependencies: IDependencies
) => {
    const dependency: IDependency = getCardDependency(state, getNumberKeys(dependencies)[0]);
    const boardId = getBoardIdByCardId(state, dependency.successorId);
    dispatch(sendRealTimeStoreActionByBoardId(boardId, dependenciesActionSetAction(dependenciesUpdateAction(dependencies))))
}

const updateDependencies = (
    dispatch: Dispatch,
    dependencies: IDependencies
) => {
    dispatch(dependenciesActionSetAction(dependenciesUpdateAction(dependencies)));
}

const restoreDependences = (
    dispatch: Dispatch,
    dependencies: IDependencies
) => {
    for (let id in dependencies) {
        dispatch(dependenciesActionSetAction(dependencySetAction(Number(id), dependencies[id])));
    }
}
