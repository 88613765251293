import { IApplicationState, TCardId } from '../../../types/types';
import { getBoard } from './getBoardById';
import { IBoard } from '../board/types';
import { getBoardIdByCardId } from './getBoardIdByCardId';

export const getBoardByCardId = (
    state: IApplicationState,
    cardId: TCardId
): IBoard => {
    const boardId = getBoardIdByCardId(state, cardId);
    if (!boardId) return null;

    return getBoard(state, boardId);
};
