import { IApplicationState } from '../../../../types/types';
import { getAuthUser } from './getAuthUser';
import { IOneTimePoll } from '../../../../types/rest/IUserMeta';
import { DEFAULT_POLL } from '../../../../const';

export const getUserPricingPoll = (
    state: IApplicationState
): IOneTimePoll => {
    const user = getAuthUser(state);
    return user.meta && user.meta.pricingPoll || DEFAULT_POLL;
};
