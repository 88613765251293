import { Dispatch } from '../../../../../types/actions';
import { IGetState } from '../../../../../types/types';
import { getCookieScopesFromUser } from '../selectors/getCookieScopesFromUser';
import { reboot } from './reboot';
import { saveUserCookies } from './saveUserCookies';
import { getNewPerformanceAllowedValueSelector } from '../selectors/getNewPerformanceAllowedValueSelector';
import { newValuesPersist } from '../store/actions/newValuesPersist';
import { isInsertPerformance } from '../selectors/isInsertPerformance';
import { clearPerformanceScripts } from './clearPerformanceScripts';

export const processPerformanceAllowForAuthUser = (
) => {
    const action = (dispatch: Dispatch, getState: IGetState) => {
        const state = getState();

        const userCookieSettings = getCookieScopesFromUser(state);
        const newUser = !userCookieSettings;
        const dialogPerformanceAllowed = getNewPerformanceAllowedValueSelector(state);

        const insertPerformance = isInsertPerformance();

        if (!dialogPerformanceAllowed && insertPerformance) {
            dispatch(clearPerformanceScripts());
        }

        dispatch(saveUserCookies());
        dispatch(newValuesPersist());

        // перезагрузим тока если чел снял галку. Еслы поставил, то не будем мучить перезагрузкой. Новых пользователей не пугаем перезагрузкой
        if (!newUser && insertPerformance && !dialogPerformanceAllowed) {
            dispatch(reboot());
        }
    };
    return action;
};
